import React from "react";

export default class Information extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <div>
                {this.renderSwitch(this.props.location)}
            </div>
        );
    }

    renderSwitch(location) {
        switch (location) {
            case 'MITHLOND' :
                return ('Mithlond, or "Grey havens" was the largest of the three harbors in Lindon, despite being the primary haven for the Elves, it was not heavily populated. In the second age, this was the primary destination for Númenóreans who sailed to the mainland. In later times, many of the elves who went into the West departed here. ')
            case 'FORLOND' :
                return ('Forlond and Harlond were two minor ports of the Elves in Lindon.')
            case 'HARLOND' :
                return ('Forlond and Harlond were two minor ports of the Elves in Lindon.')
            case 'EDHELLOND' :
                return ('Edhellond was a small harbor founded by Elven refugees from Beleriand. In TA 1981, Amroth drowned after departing from this haven. Amroth\'s ship was the last to depart from here. Thereafter it was deserted, but the people of Gondor always remembered it.')
            case 'RIVENDELL' :
                return ('Rivendell was the "house of Elrond". Elrond founded it after the fall of Eregion in the second age. The council of Elrond and the forming of the Fellowship took place here. After Isildurs death, his heirs were fostered here in their youth. The last of these was Aragorn II.')
            case 'TOWER HILLS' :
                return ('A series of high hills. This was the site of three great towers built by Gil-Galad. Elanor Gardner founded the town of Undertowers here in the Fourth Age.')
            case 'HOBBITON' :
                return ('The hometown of Bilbo, Frodo and Sam.')
            case 'FORNOST' :
                return ('The capital city of Arthedain. In 1974 of the Third Age. A combined army of Gondor, Lindon andRivendell defeated the armies of the Witch King here. From TA 861, the Palantir of Annúminas was kept here.')
            case 'ANNUMINAS' :
                return ('The capital of Arnor. It held the Sceptre of Annúminas as well as a Palantir.')
            case 'BREE' :
                return ('A village inhabited by both Men and Hobbits. Aragorn met Frodo and his companions in its Inn, the Prancing Pony. Hobbits from the shire, especially Bradybucks, sometimes visited the town.')
            case 'THARBAD' :
                return ('Tharbad was a fortified city along the Greyflood. It was built in the Second Age by the Númenóreans to protect their activities in the forests around it. It\'s bridge presented an important way of crossing the Greyflood folling the North-South Road. After its ruin, the fords here, although quite dangerous, still made for a crossing location. Boromir crossed here on his way to the Council of Elrond. The Ringwraiths crossed here on their search for the Shire.')
            case 'LOND DAER' :
                return ('First called Vinyalondë. It was founded by Aldarion in the Second Age. It was a harbor city which served as a base for the deforestation activities of the Númenóreans. It was attacked and rebuilt several times in its early years. After the fall of Númenor, the center of control of the region shifted to Tharbad and the haven was slowly abandoned.')
            case 'ISENGARD' :
                return ('This fortress was built by men of Númenor in the Second Age, and held the tower of Orthanc. It held a Palantír. In the Third Age, Gondor granted control over the tower to Saruman after he offered to guard it. During the War of the Ring, Saruman used the fortress as a base for his armies and his invasion of Rohan.')
            case 'DOL AMROTH' :
                return ('A principality of Gondor. The seat of the Prince of Dol Amroth. The princes of Dol Amroth were descendants of Elendil and apparantly bore some elvish blood. Besides, many of Númenórean blood lived in the city. This gave the people of Dol Amroth a faint Elvish quality. In TA 1981, Amroth drowned on the shores of the hill after his failure in finding his wife, the beautiful Nimrodel. The region was named after him following his death. In the War of the Ring, Imrahil (twenty-second Prince of Dol Amroth), and a small army from Dol Amroth came to the rescue of Faramir after arriving to defend Minas Tirith. Along many other great deeds during the Battle of the Pelenor fields, Imrahil aided Aragorn to gain the throne of Gondor. After the death of Denethor, the title of the Prince of Dol Amroth made Imrahil one of the main authorities in Minas Tirith.')
            case 'TO UMBAR' :
                return ('Umbar was a great haven established by the Númenóreans in the Second Age. Its control shifted many times through many attacks. After the fall of Númenor, it was often occupied by Black Númenóreans and was a centre of control over the Haradrim. In the latter half of the Third Age, the people of Umbar were known as the "Corsairs of Umbar" and always presented a great threat to Gondor (notibly, Aragorn, disguised as Throngil, led a Gondorian army and destroyed the fleet of Umbar in TA 2980). In the War of the Ring, the Corsairs of Umbar attacked the coast of Gondor and prevented large regions of Gondor to come to aid Minas Tirith until Aragorn captured the fleet in Pelargir.')
            case 'LINHIR' :
                return ('One of the larger cities of Lebennin. During the War of the Ring, Aragorn and his company passed through here.')
            case 'HOBBITON' :
                return ('The hometown of Bilbo, Frodo and Sam.')
            case 'MICHEL DELVING' :
                return ('The largest town in the Shire, and the seat of the Mayor of the Shire.')
            case 'BYWATER' :
                return ('The home of the Green Dragon Inn and the site of the Battle of Bywater.')
            case 'LONGBOTTOM' :
                return ('A valley in the Shire. Tobold Hornblower introduced pipe-weed in this location. Here lies the origin of the famous Longbottom Leaf.')
            case 'BUCKLEBURY' :
                return ('The biggest town in Buckland. Next to the Brandywine river, the bucklebury Ferry was the best way of crossing.')
            case 'FROGMORTON' :
                return ('A village on the Great East Road in the Shire.')
            case 'UNDERTOWERS' :
                return ('A town founded in the Fourth Age by Elanor Gardner, daughter of Samwise Gamgee.')
            case 'CARAS GALADHON' :
                return ('Caras Galadhon was the main city of Lothlórien and the seat of Celeborn and Galadriel. It was built in a forest of Mallorn trees and surrounded by a wall of green earth, essentially serving as a fortress.')
            case 'CALEMBEL' :
                return ('The chief city of Gondor\'s province Lamedon. Aragorn camped here on his way to Pelargir.')
            case 'LINHIR' :
                return ('One of the larger cities of Lebennin. During the War of the Ring, Aragorn and his company passed through here.')
            case 'PELARGIR' :
                return ('The greatest port in Middle Earth built by the Númenóreans. It was the home of the Gondorian navy and was attacked many times by the Corsairs of Umbar (a plan devised by Sauron to hinder Gondor). In the War of the Ring, the Corsairs captured the city until Aragorn met them here with his company. After defeating the corsairs here, he made his way on the river towards Minas Tirith.')
            case 'MINAS TIRITH' :
                return ('First called Minas Anor, sister city of  Minas Ithil. After the fall of Osgiliath, it was Gondor\'s seat of power. During the end of the Third age, it was surrounded by a long wall to protect the fields, the Rammas Echor. The battle of the Pellenor fields was fought before its walls. It held a Palantír.')
            case 'OSGILIATH' :
                return ('The Original capital of Gondor. At its center was a bridge crossing the Anduin and also the Dome of Stars (Seat of the Kings) which held a Palantír. It fell to ruin during the Third Age because of the Kinstrife and the Great Plague.')
            case 'EDORAS' :
                return ('The capital of Rohan.')
            case 'HORNBURG' :
                return ('One of the two fortresses built by Gondor to protect the Gap of Rohan. It was later given to the men of Rohan. It was very hard to besiege and protected the entrance to the Glittering Caverns. During the War of the Ring, Theoden made his stand against Saruman here.')
            case 'CAIR ANDROS' :
                return ('A fortified island on the Anduín, built and used by Gondor to protect the crossing of the Anduín against Saurons armies.')
            case 'DALE' :
                return ('A city of Northmen. It prospered due to its location in front of the doors of Erebor. It was destroyed by the dragon Smaug in TA 2770. After the Battle of the Five Armies, Bard the Bowman founded the Kingdom of Dale and re-established the city. During the War of the Ring, Dale was besieged prompting king Brand to order a retreat to Erebor in which he and Dáin Ironfoot were killed.')
            case 'ESGAROTH UPON THE LONG LAKE' :
                return ('A trading town entirebuilt of wood which rested upon the water of the Long Lake. It was here that Bard the Bowman shot and killed the dragon Smaug.')
            case 'WEATHERTOP' :
                return ('The Highest of the Weatherhills. It held the Watchtower of Amon Sûl. It held a great strategical location, prompting Elendil to place a Palantír in the tower. Because of its value, it was a centre point of unrest between the three divided states of Arnor. Gandalf and later Aragorn and the Hobbits fought the Ringwraights here. Frodo was stabbed here by the Witch King of Angmar.')
            case 'CARN DUM' :
                return ('The capital of Angmar and the seat of the Witch King during the Third Age. It was destroyed by the Dúnedain after the battle of Fornost.')
            case 'BARAD DUR' :
                return ('The seat of power of Mordor and Sauron\'s base of operations. It was built with the power of the Ring and could therefore only partly be destroyed, which is was after it was besieged for seven years by the Last Alliance. It was finally completely destroyed when Frodo threw the One Ring into Mount Doom.')
            case 'MOUNT GUNDABAD' :
                return ('Originally a Dwarven sanctum. It was the birthplace of Durin the Deathless and the Longbeards. It was invaded by Orcs and retaken by the Dwarves three times. The Orcs would use it as a strategic base and this is the base of the Gundabad Orcs during the Battle of the Five Armies.')
            case 'DOL GULDUR' :
                return ('A stronghold of Sauron and his base of Operations for much of the Third Age. Thráin was imprisoned here in the third age and Gandalf found him here and recieved the map and the key to Erebor. During the War of the Ring, the Orcs of Dol Guldur attacked Lothlórien and Thranduil three times. But Galadriel got angry and (with Thranduils help) destroyed the fortress completely.')
            case 'MORANNON' :
                return ('A gate set in a huge wall, protecting the gap between the Ash Mountains and the Mountains of Shadow. It was protected by the castle Durthang.')
            case 'DURTHANG' :
                return ('A fortress in the mountains of Mordor. It was originally built by Gondor to keep watch but after it got depopulated it was used by Sauron and the Orcs. ')
            case 'TOWER OF CIRITH UNGOL' :
                return ('Old fortress of Mordor used to protect the pass of Cirith Ungol. It was protected by two statues, the watchers. The statues created an invisible barrier before the gates by their sheer will alone (and appeared to have been somewhat alive).')
            case 'MINAS MORGUL' :
                return ('First called Minas Ithil , a fortress of Gondor to protect the eastern border, In appearance much like Minas Anor. It was taken by the forces of Mordor and the Nazgûl in the Third Age, and used as a base of attack to Finally overrun Osgiliath and Ithilien. While it used to be an utterly beautiful city, after it was taken by Sauron it became corrupted and earned its name; Minas Morgul.')
            case 'MORIA' :
                return ('Founded by Durin I. According to the Dwarven Legendarium, Durin found himself small valley with a long, oval shaped lake. When he saw his reflection in the lake, the stars formed a crown around his head. He took this as a sign and named the lake the "Mirrormere", in the mountains around it, Moria was built. Moria grew to fame under Durins rule and in later years it prospered greatly due to the delving of Mithril, a very precious metal unique to Moria. In the Second age, the friendship of the neighbouring Eregion enriched the realm culturally as well and they benefited greatly from trading with this elven realm. Finally in the latter half of the Third Age, the dwarves of Khazad-Dûm awakened a Balrog in the deeps of the mountains. It completely overran the dwarves and killed the kinh Durin VI, earning its name: "Durin\'s Bane". It was then long abandoned save by Orcs and Goblins, save for the Battle of Azanulbizar when the exciled dwarves of the Lonely Mountain attempted to reclaim Moria and find a new home, but it was unsuccesful. In TA 2989, Balin led an expedition to reclaim Moria for the dwarves and was succeeded to drive back the orcs, but when the Fellowship entered the realm in 3019, the dwarves of Balin\'s expedition were long dead. The Balrog, still occupying the kingdom, drove the Fellowship out of Moria until he was finally stopped by Gandalf who defeated him after a long battle, forfeiting his own life. And so, it was in foul Moria where happened the first act of the breaking of the Fellowship.')
            case 'BELEGOST' :
                return ('An Ancient and long lost city of the Dwarves, the Broadbeams (who supposively founded it) who lived there were some of the best craftsmen in their time. The dwarves of this city played a great role in the battle against Morgoth in the First Age and earned great fame. The city was ruined in the War of Wrath.')
            case 'EREBOR' :
                return ('A colony of the Dwarves since the Second Age. After the fall of Moria by the Balrog, King Thráin I established a kingdom in this great mountain. It prospered untill the dwarves left it for the Grey Mountains in TA 2210, but they were forced out of the Grey Mountains by the Dragons who lived there and part of the dwarves returned to Erebor under king Thrór. In TA 2770, Erebor was attacked by Smaug who forced the dwarves to flee from the mountain. Almost two hundred years later, Thorin Oakenshield returned to the mountain with a small company (including Bilbo) and retook it after Bard the Bowman shot down and killed Smaug. Because Thorin perished in the Battle of the Five, Dáin Ironfoot became King under the Mountain. During the War of the Ring, Sauron sent Easterlings to take Dale and the men were forced to flee to the mountain, losing Dáin and King Brand in the process. But the Mountain itself stood strong and the people of Erebor did not yield, eventually providing what proved to be a very important victory in the War of the Ring.')
            case 'ARTHEDAIN' :
                return ('One of the three Dúnedain kingdoms. Its capital was Fornost. It was the longest surviving realm of the three but it was finally overrun by Angmar. Its king Arvedui attempted to claim the throne of Gondor and reunite the North and the South but failed. Gondor\'s aid came too late because it was troubled by the Wainriders but eventually Angmar was defeated at the Battle of Fornost and the Witch-King was forced to flee. In TA 1601 some of its farmland were granted to Hobbits to form the Shire.')
            case 'RHUDAUR' :
                return ('One of the three Dúnedain kingdoms. It was the smallest of the three and was always hostile to the other two. The Dúnedain were never dominant here and the line of Isildur failed here first. Its kings then became servants of Angmar and the realm became a vassal of Angmar. It was annexed by Angmar in TA 1409.')
            case 'CARDOLAN' :
                return ('One of the three Dúnedain kingdoms. It allied with Arthedain but was overrun by Angmar. It heavily weakened by the Great Plague. and it later years, only Tharbad was populated.')
            case 'ITHILIEN' :
                return ('A region of Gondor which bordered Mordor. A prosperous region until Minas Ithil was taken by the Witch King. After this it was raided by Orcs continuously and eventually the population of the region diminished to nothing and left Faramir and others to fight a sort of guerilla war in the years before the War of the Ring. Here, Frodo and Sam met Faramir and they were led across it by Gollumn.')
            case 'SOUTH GONDOR' :
                return ('A region contested between Gondor and Harad.')
            case 'ANORIEN' :
                return ('A region of Gondor primarily defended by Cair Andros. It held no cities but through it ran the Great West Road.')
            case 'ANFALAS' :
                return ('A coastern region of Gondor. It was attacked by the Corsair in the War of the Ring.')
            case 'LEBENNIN' :
                return ('A region of Gondor famed for its five Rivers. During the War of the Ring it was attacked by the Corsairs but Aragorn and his company defeated them and left the people of Lebennin free to come to the aid of Minas Tirith.')
            case 'ANDRAST' :
                return ('A region populated by Drúedain.')
            case 'LAMEDON' :
                return ('A series of Valleys in Gondor. Aragorn camped here on his was through Gondor.')
            case 'LOSSARNACH' :
                return ('Vale of Flowers. A region of Gondor.')
            case 'DOR EN ERNIL' :
                return ('Land of the Prince, a region of Gondor ruled by the Princes of Dol Amroth.')
            case 'EASTFOLD' :
                return ('A region of Rohan, holding the original capital: The Aldburg.')
            case 'WESTFOLD' :
                return ('A region of Rohan containing the Hornburg.')
            case 'EAST EMNET' :
                return ('A region of Rohan. Eorl the Young died in this region.')
            case 'WEST EMNET' :
                return ('A region of Rohan.')
            case 'THE WOLD' :
                return ('A region of Rohan and the site of many important battles. Eorl the Young defeated a host of Orcs here.')
            case 'WESTMARCH' :
                return ('A secluded region of Rohan. Held fortified against the Dunlendings. It had a seperate lord. During the War of the Ring, first Theodred and later Erkenbrand.')
            case 'HARAD' :
                return ('Divided in 2 regions, Near Harad and Far Harad. Populated by many different tribes (and Mûmakil). Its population was often swayed to different sides, being controlled by Númenoreans, Black Númenoreans, Gondor and finally Sauron himself. The Haradrim were used in the Battle of the Pellenor Fields.')
            case 'DUNLAND' :
                return ('A land occupied by Dunlendings who were largely hostile to the Rohirrim. In the Third Age, the Fortress of Isengard was infested with Dunlendings and became hostile to Rohan and it was Saruman who offered to control the Gap of Rohan from then on, gaining access to Orthanc. During the War of the Ring, Saruman used the Dunlendings to mount an attack on Rohan which almost worked.')
            case 'PLATEAU OF GORGOROTH' :
                return ('The central plain of Mordor, it held most of Saurons armies while they were idle. Mount Doom looked over the plain and made it uninhabitable with its eruptions and general heat.')
            case 'NURN' :
                return ('A region of Mordor containing the Sea of Núrnen. It held huge farms and, because of its fertile features, generated enough food for the whole of Mordor\'s armies. It was worked by Men of the west who were enslaved by Sauron.')
            case 'UDUN' :
                return ('A large valley between mountains between the Black Gates and the Isenmouthe. It held many Orc strongholds (including Durthang) and must be traversed to get deeper into Mordor when one would go through the Morannon.')
            case 'ANGMAR' :
                return ('A kingdom founded by the Witch King. He established the realm (around TA 1300) to form a base op operation and he attempted to destroy the kingdoms of Arnor from here. Within a few centuries of its creation, Angmar had annexed Rhudaur and completely destroyed Cardolan. Only Arthedain remained vigilant with the help of the Elves. A long war between Arthedain and Angmar followed untill finally in TA 1974, Arthedain was defeated, quickly followed by the fall of Angmar itself after the Battle of Fornost in TA 1975.')
            case 'MINHIRIATH' :
                return ('Originally populated by a group of highly developed men, they waged war with Númenor after the latter started destroying huge chunk of Minhiriath\'s forests. The inhabitants all died or were forced to flee. It later became part of Arnor and Cardelan before that as well, was destroyed. The great forests that characterized the region did not fully regrow.')
            case 'ENEDWAITH' :
                return ('This region was originally heavily forested but fell victim of Númenor\'s deforestation practises. It later became  part of Gondor but the only city was Tharbad but after the Great Flood in TA 2912, it was abandoned. It was then populated by the Dunlendings and some wandering fishermen.')
            case 'DRUWAITH LAUR' :
                return ('Assumed to have been one of the places where the Drúedain lived.')
            case 'RHOVANION' :
                return ('A huge region which has held many kingdoms. Noteably: Erebor, Lórien and Northern Greenwood, Dale and Éothéod. It was enslaved TA 1856 by the Wainriders and freed again by Gondor in TA 1899.')
            case 'DAGORLAD' :
                return ('The Battle Plain, it lay before the gates of Mordor and it was the here where the Last Alliance made their stand in the Battle of Dagorlad. After this, Gondor fought many battles with the Eastlerings here, resulting in an infested wasteland.')
            case 'THE BROWN LAND' :
                return ('Originally a prosperous land and location of the gardens of the Entwives. However, Sauron laid waste to the land to defend against the Last Alliance, and the Entwives were driven away.')
            case 'FORODWAITH' :
                return ('Translates to "Northern Waste". Its proximity to Ilmen and Morgoth\'s influence made it a barren cold region. Its inhabitants were men, unrelated to the Edain, who were called snowmen and could apparantly skate on ice.')
            case 'WITHERED HEATH' :
                return ('A burned wasteland. It was known as the breeding ground for dragons. They infested the Grey Mountains at some point and drove away the Dwarves of Durin\'s Folk who lived their. Smaug came from this region when he invaded Erebor.')
            case 'ERYN VORN' :
                return ('A remnant of the large forest in Minhiriath.')
            case 'ETTENMOORS' :
                return ('A dangerous highland area which was infested with evil creatures. The Witch-King fled here after the Battle of Fornost.')
            case 'TROLLSHAWS' :
                return ('During the days of Arnor, the region was protected by castles, by after its fall, it was haunted by Trolls. Tom, Bert and WIlliam, the Trolls who waylaid the company, are famous examples.')
            case 'FIELD OF CELEBRANT' :
                return ('A large flat area.')
            case 'GREENWAY' :
                return ('A road with the purpose of connection Arnor and Gondor. It crossed the Gwathló at Tharbad.')
            case 'NORTH SOUTH ROAD' :
                return ('Part of the road connection Arnor and Gondor. It led from the Fords of Isen to Fornost.')
            case 'HARAD ROAD' :
                return ('A road used by the Haradrim to come to Mordor.')
            case 'HIGH PASS' :
                return ('An important way to cross the Misty Mountains. It was protected by Rivendell. It was created by the Vala Oromë to allow the Eldar to cross the Misty Mountains. It was used by the Last Alliance on their way to mordor. In the later years of the Third Age, it was endangered by Goblins.')
            case 'OLD FOREST ROAD' :
                return ('Originally part of the Dwarf Road which ran from Moria to the Iron Hills. Its remnant was used in the Third Age to traverse through Mirkwood as safely as possible.')
            case 'ERED LUIN' :
                return ('A mountain range which survived from the War of Wrath, although broken. After the Dwarves were driven out of Erebor by Smaug, Thráin and Thorin established a nice settlement here.')
            case 'ERED MITHRIN' :
                return ('Originally part of the Iron mountains, but broken off after the War of Wrath. Durin I awoke in Mount Gundabad, a mountain of this range. In TA 2210 Thorin I led the Dwarves here and they prospered untill they were forced to move due to the dragons.')
            case 'HITHAEGLIR' :
                return ('Ranging from its peaks, Mount Gundabad (where Durin I awoke) to Methedras, the Misty Mountains were a huge geological obstacle essentially dividing middle earth in two. Khazad-Dûm was located around the centre, underneath the Caradhras, the Celebdil and the Faniudhol. The mountains were created by Melkor during the Time of the Trees to hinder Oromë who was hunting Melkor\'s abominations around Middle Earth.')
            case 'IRON HILLS' :
                return ('A range of hills which was colonized by the Dwarves of Khazad-Dûm in the FIrst Age. In later years, many Dwarves driven out from other Dwarven settlements came here and the Iron Hills prospered.')
            case 'ERED NIMRAIS' :
                return ('The mountain range split Gondor in two and had no passes, making it frustrating geological feature. Minas Tirith was carved into its eastern end. The Drúedain were natives to the mountains. But they moved away, leavind the Púkel-men. They swore allegience to Isildur, but betrayed him and became the Army of the Dead.')
            case 'ERED LITHUI' :
                return ('A mountain range which acted as the natural border of Mordor.')
            case 'EPHEL DUATH' :
                return ('A mountain range which acted as the natural border of Mordor. It contained Minas Morgul and Durthang, and it could be traversed by taking the Pass of Cirith Ungol, which was terrorized by Shelob.')
            case 'MOUNTAINS OF MIRKWOOD' :
                return ('A range of small mountains. It was home to Goblins and the Giant Spiders when Greenwood the Great became Mirkwood under the power of Dol Guldur.')
            case 'MOUNTAINS OF ANGMAR' :
                return ('Given its name by the Witch-King of Anmar, who settled here. It contained Carn Dûm.')
            case 'THE LONELY MOUNTAIN' :
                return ('A single mountain, colonized by the Dwarves of Khazad-Dûm. In the later years of the Third Age, it held the kingdom of Erebor, which was then the greatest Dwarven kingdom in Middle Earth.')
            case 'BARROW DOWNS' :
                return ('A region of low hills which was utilized as a resting place for the Dúnedain untill it was infected by the Barrow-wights. The Hobbits encountered one on their way to Rivendell but they were saved by Tom Bombadil. They all took a Dúnedain knife from the barrow which Merry used to kill the Witch-king of Angmar.')
            case 'EMYN UIAL' :
                return ('A series of hills which became part of Arthedain.')
            case 'NORTH DOWNS' :
                return ('A region of hills which contained Fornost. Arvedui escaped after the Angmar\'s final attack and fled through the North Downs.')
            case 'WEATHER HILLS' :
                return ('A series of hills marking the border between Arthedain and Rhudaur. The region was fortfied by Argeleb I untill Angmar destroyed it in TA 1409.')
            case 'SOUTH DOWNS' :
                return ('A series of hills.')
            case 'FAR DOWNS' :
                return ('A series of hills.')
            case 'WHITE DOWNS' :
                return ('A series of hills which were the original border of the Shire.')
            case 'TOWER HILLS' :
                return ('A region of steep hill upon which the White Towers were built. They formed the border between Lindon and Arnor. In the Fourth Age Elanor Gardner settled the town of Undertowers in these hills. The hills then became the new border of the Shire.')
            case 'EMYN MUIL' :
                return ('A great region of stone and cliffes which is almost impossible to traverse. Éomund was slain here when persuing a group of orcs and Frodo and Sam were lost in the region untill Gollum finally found them and let them out.')
            case 'PINNATH GELIN' :
                return ('A region of hills in Gondor. It was a loyal province and they came with 300 well-prepared soldiers to the Battle of the Pelennor Fields.')
            case 'MORGAI' :
                return ('A mountain ridge which acted as a inner defence in Mordor.')
            case 'EMYN ARNEN' :
                return ('A series of hills in Ithilien which was home to the descendants of the Stewards of Gondor. Faramir was granted lordship of Emyn Arner and it became the home of his descendants.')
            case 'OLD FOREST' :
                return ('A dark forest. On its southern edge lived Tom Bombadil. It was originally part of a humongous forest spanning all of Eriador. But in the Third age, only it and Fangorn Forest were left. It became a dangerous place in the Third Age and the trees were believed to be alive in some way. The Hobbits encountered Old Man Willow here and had to be rescued by Tom Bombadil. At some point, the Forest moved all the way to the border of the Shire, which promted the Hobbits to burn down a large part of it. The live trees in the forest turned out to be Huorns and, while not being tended by any Ents, they became hostile.')
            case 'FANGORN' :
                return ('A remnant of the huge forest that spanned Eriador. It was the last home of the Ents and it was named after Fangorn (Treebeard), the oldest of the Ents. After talking to Merry and Pippin, the Ents held and Entmoot and decided to attack Isengard and utterly defeated Saruman. Meanwhile, the Huorns of the forest assisted in the Battle of the Hornburg.')
            case 'MIRKWOOD' :
                return ('Originally known as Greenwood the Great. On the Great Journey, the Elves from Cuiviénen passed through this forest and they rested here before traversing the Misty Mountains. Some of the elves who broke off from the group established the Woodland realm here.  The forest was corrupted by Sauron in Dol Goldur and became Mirkwood. During the Quest of Erebor, the company was attacked by Giant Spiders and captured by the Woodland Elves, who had become secluded and defensive after long years of struggles with "The Necromancer". After Galadriel destroyed Dol Goldur and lifted the darkness, Mirkwood became a normal forest again and was called Wood of Green Leaves by Thranduil.')
            case 'CHETWOOD' :
                return ('A small forest in Bree.')
            case 'ANDUIN' :
                return ('The greatest river of Middle Earth in the Third Age. On its course alongside the Misty Mountains, it formed the great Anduin Vale. Along its stream were many notable locations like: The Undeeps, Sarn Gebir, the Argonath, the Falls of Rauros, Cair Andros and Osgiliath. It held some great harbours like Harland and Pelargir. It was hard to cross and its most notable crossing location were Ford of Carrock, the Undeeps, Cair Andros and Osgiliath (untill the Great Bridge was destroyed). Because of this it was an important geological feature.')
            case 'LUNE' :
                return ('A river which ended in the Gulf of Lhûn.')
            case 'BARANDUIN' :
                return ('A river in the Shire. In the Shire, it could only be crossed with the Brandywine Bridge or the Bucklybury Ferry. Frodo\'s parents apparantly drowned in the river.')
            case 'GWATHLO' :
                return ('A very wide river which flowed through huge forests. It was therefore used by the Númenóreans to support their deforestation. It formed the border between Arnor and Gondor and could only be crossed at Tharbad.')
            case 'GLANDUIN' :
                return ('A river ranging from the Misty Mountains to the Gwathló, which it met in the Swanfleet.')
            case 'MITHEITHEL' :
                return ('A river ranging from the Misty Mountains to the Gwathló, which it met in the Swanfleet. It could be crossed at the Last Bridge.')
            case 'BRUINEN' :
                return ('A tributary of the Mitheitel. It could only be passed at a ford near Riverndell. At these fords Frodo stood against the Witch-King of Angmar, Elrond then called a great flood on the river which killed the horser of the Nazgûl.')
            case 'ANGREN' :
                return ('A river which formed a border of Rohan. Sarumand dammed the river at Isengards northern wall, and when the Ents broke the dam, the river drowned Isengard.')
            case 'ADORN' :
                return ('A river which ran from the White Mountains to the Isen.')
            case 'LANGWELL' :
                return ('A tributary of the Anduin.')
            case 'GREYLIN' :
                return ('A tributary of the Anduin.')
            case 'SIR NINGLOR' :
                return ('A tributary of the Anduin. After the War of the Last Alliance, Isildur was attacked by Orcs and after he died, the One Ring was lost in the river. Later, Déagol found the ring in the river and was killed by Sméagol, whi held it for long years and became Gollum.')
            case 'CELEBRANT' :
                return ('A tributary of the Anduin.')
            case 'NIMRODEL' :
                return ('A tributary of the Anduin. Nimrodel the Elf used to watch the river and when she and her love Amroth were lost, the river was named after her.')
            case 'LIMLIGHT' :
                return ('A tributary of the Anduin.')
            case 'ONODLO ENTWASH' :
                return ('A river named for the Ents of Fangorn, where it originated. It ened in the Marshes Nindalf and the Dead Marshes.')
            case 'SNOWBOURN' :
                return ('A river which met the Entwash.')
            case 'MERING STREAM' :
                return ('A river which marked the border between Rohan and Gondor.')
            case 'LEFNUI' :
                return ('One of the Seven Rivers of Gondor.')
            case 'MORTHOND' :
                return ('One of the Seven Rivers of Gondor.')
            case 'CIRIL' :
                return ('One of the Seven Rivers of Gondor.')
            case 'RINGLO' :
                return ('One of the Seven Rivers of Gondor.')
            case 'GILRAIN' :
                return ('A tributary of the Serni. And one of the Seven Rivers of Gondor.')
            case 'SERNI' :
                return ('One of the Seven Rivers of Gondor.')
            case 'ERUI' :
                return ('A river which originated in the White Mountains.')
            case 'CELOS' :
                return ('A tributary of the Sirith.')
            case 'SIRITH' :
                return ('A river which met the Anduin at Pelargir.')
            case 'POROS' :
                return ('A river which acted as the border between Harondor and Ithilien.')
            case 'HARNEN' :
                return ('A river which marked the southern border of Gondor\'s influence (although contested).')
            case 'FOREST RIVER' :
                return ('A river which ran through Mirkwood.')
            case 'ENCHANTED RIVER' :
                return ('A river which ran close to the Elvenking\'s Halls. Every one who drank or touched the water fell into a deep sleep. Bombur was knocked into it by a deer and fell asleep for six days.')
            case 'CELDUIN' :
                return ('A river which ran from the Lonely Mountain towards the Sea of Rhûn.')
            case 'CARNEN' :
                return ('A river which ran from the Iron Hills towards the Sea of Rhûn.')
            case 'GULF OF LHUN' :
                return ('The Gulf of Lhûn broke the Blue Mountains in two after the War of Wrath. Where it lies now, Nogrod the Dwarven city used to stand within the mountains.')
            case 'NENUIAL' :
                return ('On the southern shore of this lake lay Annúminas.')
            case 'SEA OF RHUN' :
                return ('A sea which the Wainriders used as a base. They built their settlements around their a few times and attacked Gondor.')
            case 'SEA OF NURNEN' :
                return ('A sea of "dark waters" which was probably used to irrigate the many farmlands of Núrn.')
            case 'ETHIR ANDUIN' :
                return ('The mouths of the Anduin were formed in the aftermath of the Fall of Númenor, when the great storms produced reformed the coastline. Gondor controlled the region with its navy.')
            case 'SARN GEBIR' :
                return ('These were fast flowing rapids on the Anduin. It was so hard to traverse them with boats that a road was built next to it so travelers could carry their boat around the rapids.')
            case 'BAY OF BELFALAS' :
                return ('The remainder of the Great Gulf which had split the South from Middle Earth in the First Age.')
            case 'FALLS OF RAUROS' :
                return ('These were great waterfalls in the Anduin, in their middle stood the island of Tol Brandir. Their was a stair built by Gondorians to traverse the falls.')
            case 'NORTH UNDEEP' :
                return ('The undeeps were two great undeep regions on the Anduin which allowed easy crossing. They were fortified by Gondor to defend against the easterlings but the fortifications ultimately failed.')
            case 'SOUTH UNDEEP' :
                return ('The undeeps were two great undeep regions on the Anduin which allowed easy crossing. They were fortified by Gondor to defend against the easterlings but the fortifications ultimately failed.')
            case 'DEAD MARSHES' :
                return ('These used to be normal marshes, but after the Battle of the Last Alliance, they swallowed up the Dargorlad and became the Dead Marshes. The dead bodies on the battlefield and were swallowed up. Apparantly, the bodies weren\'t physically there but they could be seen as visions or illusions. The Marshes were apparantly very dangerous and anyone who was lured into the waters would join the dead there. Aragorn captured Gollum here and later Frodo and Sam passed the Marshes with Gollum.')
            case 'NINDOLF WETWANG' :
                return ('A dangerous swampland on the Anduin. The Dead Marshes were an extension of these.')
            case 'NIN IN EILPH' :
                return ('A marshland created by the Glanduin joining the Greyflood. Many Stoors settled here for a time after they crossed the Misty Mountains. The Glanduin could be crossed here.')
            case 'MIDGEWATER' :
                return ('A swamp. Aragorn and the hobbits had to travel through it to reach Rivendell.')
            case 'TOLFOLAS' :
                return ('A rocky island which was nearly destroyed during the Fall of Númenor.')
            case 'HIMLING' :
                return ('In the First Age, this was Himring: A hill upon which Meadhros built his Fortress. It was occupied by him and his brother Maglor and from here they were a big part in maintaining the Siege of Angband and survived the Dagor Bragollach. After the War of Wrath, the tip of the hill was all that remained and it was now an island: Himling.')
            case 'SARN FORD' :
                return ('A stone ford used to cross the Brandywine. It was protected by the Rangers of the North and on September 22 of TA 3018 they made a long stand here against the Ringwraiths untill the Rangers were finally forced to withdraw.')
            case 'FORD OF BRUINEN' :
                return ('A way of crossing the Bruinen to Rivendell. Frodo made his stand here against the Nazgûl, and when those were lured into the fords, Elrond summoned a flood on the river and killed their horses.')
            case 'FORDS OF ISEN' :
                return ('The only way of crossing the Isen and was defended by Rohirrim, untill Saruman built a bridge in secrecy and helped the Orcs cross the river. In the First Battle of the Fords of Isen, Saruman surprised the Rohirrim by attacking from both sides of the River and Théodred was killed in this battle.')
            case 'OLD FORD' :
                return ('A ford where the Old Forest Road crossed the Anduin. There used to be a Dwarven-built bridge here but it dissapeared after the Dwarves stopped maintaining the road and it was gone in the Third Age.')
            case 'LAST BRIDGE' :
                return ('The only way to cross the Hoarwell. Four Nazgûl were waiting to intercept Frodo and the Hobbits here but they were driven away by Glorfindel.')
            case 'ORODRUIN' :
                return ('A great volcano in Mordor which seared the Plateau of Gorgoroth. Sauron forged the One Ring in its Cracks of Doom and here was the only place where it could be destroyed. Sauron had some measure of control over the activity of the volcano as it erupted when Sauron attacked Gondor in the Second Age and was dormant when Sauron was not in Mordor only to come to life again when Sauron returned there in TA 2954.')
            case 'LOEG NINGLORON' :
                return ('A marshland around the Anduin. Isildur was attacked here by Orcs and died, losing the One Ring in the river. Centuries later, Stoors settled around the marshland and Déagol found the One Ring here before Golum killed him and took the Ring.')
            case 'EAST BIGHT' :
                return ('A large clearing of Mirkwood which was the result of Woodmen cutting trees. It created a shallow area of the forest.')
            case 'CARROCK' :
                return ('A small Island in the Anduin which was climbed by Beorn sometimes. He apparantly built a stair in the rocked and he climbed in the form of a bear to look at the moon or the Misty Mountains. The eagles dropped the Company here when they had saved them and housed them here for a night before the Company went to see Beorn himself.')
            case 'GAP OF ROHAN' :
                return ('The space between the Misty Mountains and the Ered Nimrais and the easiest way to cross from West to East Middle Earth. The North South Road ran through the Gap and it was protected by Isengard. During the War of the Ring, the Fellowship avoided the Gap because they were afraid of Saruman who was ousted as a traitor then.')
            case 'HELMS DEEP' :
                return ('Named for Helm Hammerhand. It was a valley in the Ered Nimrais and it was fortified by the Hornburg. Behind it were the Glittering Caverns. Helm Hammerhand was trapped here during the Long Winter. When the great Horn of the Hornburg blew, a fierce and brutal Helm would break through the ranks of Dunlendings besieging the fortress and kill many of them, often with his bare hands. Eventually the Dunlendings became mortally afraid of Helm and the Horn. He made raids on the Dunlendings to provide food inside the Hornburg untill at last he died on one of these missions. The deep was thereafter named Helm\'s Deep. During the War of the Ring, Theoden made his stand against Saruman here.')
            case 'DIMRILL DALE' :
                return ('A valley below the East-Gate of Moria. The battle of Azanulbizar was fought here when the Dwarves attempted to take back Moria. Thorin II was in that battle and Azog was killed here by Dáin Ironfoot.')
            case 'ICEBAY OF FOROCHEL' :
                return ('A huge bay. Around its shores lived the Forodwaith. Arvedui fled here after he was defeated by the Witch-King of Angmar and was sheltered by the descendants of the Forodwaith. Against their advice he attempted to sail the Bay of Forochel but his ship could not stand the ice of the Bay and he drowned.')
            case 'ERECH' :
                return ('A hill in Gondor. Isildur placed upon it a three meter tall stone as a symbol of Gondor. The King of the Mountains swore his oath to Isildur upon this stone, but he broke it and he and his followers became the Army of the Dead which helped Aragorn fight the Corsairs.')
            case 'ISENMOUTHE' :
                return ('A pass in Mordor which protected the crossing from Udûn to Gorgoroth. It was heavily fortified with Orc fortresses and a large ditch which could be crossed by one bridge.')
            case 'GONDOR' :
                return ('The Southern kingdom of the Faithfull from Númenor. It was founded by Isildur and Anárion. It was founded after the Downfall of Númenor and reached the height of its power around TA 1000 when the ship kings dominated the south of Middle Earth through military might. However, after this, Gondor was hit by multiple disasters which eventually led to its decline. The first was the Kin-Strife in which Castamir started a civil war over a blood dispute and overthrew King Eldacar, only to be defeated by the latter again ten years later. The whole dispute caused many leading Gondorians to die and Osgiliath was heavily damaged during the fights, the Dome of Stars was burned and its Palantír was lost. After this, Gondor (like almost all realms in Middle Earth) was hit by the Great Plague, a plague which killed an overwhelming part of the kingdoms citizens and prompted King Tarandor to abandon the capital, Osgiliath, and move it to Minas Anor (now Minas Tirith). The depopulation also caused the abandonment on the watch on Mordor. The third disaster struck when the Wainriders devastaded Gondor in TA 1851, they killed the king and forced Gondor to give up many eastern regions. By the time of the War of the Ring, Gondor was ruled by a line of stewards, they held the throne because the last king Eärnur had vanished when accepting the Witch-king\'s challenge in Minas Morgul. No one had the support to claim the throne and so the Stewards kept the throne for the return of the king thereafter. Near the end of the Third Age, Gondor was left heavily weakened by all these calamities and was a mere shadow of its former glory.')
            case 'MORDOR' :
                return ('A barren wasteland. The mountains around it made it almost impossible to besiege. Sauron settled here at some point in the second age and built his fortress Barad-Dûr. He ruled untill the end of the Second Age when the Last Alliance defeated his armies and destroyed Barad-Dûr. Sauron himself was defeated when Isildur cut the One Ring from his finger. After this, Gondor built multiple strongholds on the slopes of Mordor\'s mountains and kept a watch over the land to ensure evil would not return there. However, after the Great Plague, the watch was abandoned and the Nazgûl were able to re-occupy the land and prepare it for Sauron\'s return, during this time they also captured Minas Ithil and corrupted it. Sauron did eventually return in TA 2942 when he was driven from Dol Guldur and led his efforts in the War of the Ring from the rebuilt Barad-Dûr.')
            case 'ERIADOR' :
                return ('A heavily forested region untill the deforestation of the Númenóreans and Saurons war. Arnor was partly found in the region.')
            case 'THE LOST REALM OF ARNOR' :
                return ('The northern kingdom of the Faithful from Númenor. It was founded by Elendil after the Dowfall of Númenor. Because of colonization, a lot of Númenóreans were already living in the region by that time. It thrived in the early Third Age but after king Eärendur died his three sons divided the kingdom in three seperate realms: Arthedain, Cardolan and Rhudaur. The three kingdoms kept fighting internal wars over border disputes and power, and so were eventually all destroyed by the Witch-King of Angmar.')
            case 'ROHAN' :
                return ('Originally known as Calenardhon and a great flat region of Gondor. In TA 2509, Eorl and Éothéods, who were long time allies of Gondor, came to the rescue of Gondorians armies. Lord Cirion was fighting a losing battle against the Easterlings and was at the point of being overrun by a seperate army of Orcs when Eorl showed up with seven thousand of his horseriders. As a token of gratitude, Cirion then gave Eorl lordship over Calenardhon and Eorl swore his oath: To give everlasting friendship to Gondor and to aid them in war. The Éothéod then called themselves the Eorlings and Calenardhon was called Rohan. The kingdom was often plagued by the Dunlendings because they were driven out by the Eorlings early on. Before the War of the Ring however, the kingdom was thriving under the rule of Thengel and later Theoden, untill the latter was corrupted by Saruman (through Grima Wormtongue).')
            case 'LINDON' :
                return ('An elven kingdom founded by Gil-Galad at the beginning of the Second Age. There was tension between the different groups of elves which left the kingdom culturally divided. When Sauron overran Eriador, Lindon acted as a safe haven for the fleeing elves and opposed Sauron until the Númanóreans came to the aid of the Elves. The Downfall of Númenor left Lindon greatly diminished because of the great floods of its aftermath. After the War of the Last Alliance, and the death of Gil-Galad, Círdan ruled the kingdom and maintained the ports so Elves could depart to Valinor. Lindon was then the home of the majority of the high Elves in Middle Earth.')
            case 'FORLINDON' :
                return ('A region of Lindon in which many of the Nõldor lived.')
            case 'CROSSINGS OF POROS' :
                return ('The only place to cross the Poros. The battle of the Crossing of Poros was fought here between Gondor and the Haradrim.')
            case 'NEAR HARAD' :
                return ('A desert region of Harad.')
            case 'RHUN' :
                return ('A large region which was populated by many groups that squabled often, but they were united by Sauron under a common goal of fighting the western lands of Middle Earth. Notably the Wainriders and the Balcoth came from this region.')
            case 'LORIEN' :
                return ('A foresty Elven realm under the rule of Celeborn and Galadriel. It was (according to Legolas) the fairest of the Elven realms in the Third Age. The power of Nenya and Galadriel kept enemy forces at bay and it could only have been defeated it Sauron assaulted it himself. After Amroth went to search for his love, Nimrodel, the lines of kings of Lórien stopped and Celeborn and Galadriel ruled the realm thereafter. In its forests were the Mallorn trees of great beauty and height. Its capital was the Caras Caladhon.')
            case 'EREGION' :
                return ('A destroyed Nõldorin kingdom with a friendship to the Dwarves of Moria, which was unique for the Elves. In the Second Age the kingdom was ruled by Celembrimbor, the last descendant of Fëanor. He befriended Sauron, disguised as Annatar, and together they forged the Nine and the Seven Rings of Power. Celembrimbor however forged Vilya, Narya and Nenya on his own. When Sauron forged the One Ring, Celembrimbor saw his plot and tried to save the Rings of Power, but could only save the Three as Sauron attacked and destroyed Eregion afterwards.')
            case 'THE SHIRE' :
                return ('The homeland of the Hobbits. The hobbits were allowed to settle here by Argeleb II. It was mostly peaceful throughout the Third Age except for a few battles. Notably the Battle of Greenfields in which Brandobas Took apparantly invented the game of Golf. Its negligable strategic importance allowed it be largely unknown to the powers of the world and left out of many of the great events in the Third Age. However, at the end of the Third Age, Saruman came to know the Shire because of his affinity with Old Toby\'s and tried to control it through Lothar Sackville-Baggins. After the War of the Ring, the Hobbits of the Fellowship returned to the Shire and liberated it from Saruman. It was ruled by the Mayor of Michael Delving which was its capital.')
            case 'BRANDYWINE BRIDGE' :
                return ('One of the two crossings of the Brandywine. The hobbits were allowed to settle in the Shire as long as they kept the bridge in good condition for Arthedain\'s messengers.')
            case 'MORIA GATE' :
                return ('A gate built by Celembrimbor and the Dwarf Narvi. It was built to further elevate the trade between Moria and Eregion and it was inlaid with Ithildin. After Eregion was destroyed by Sauron, the doors were sealed and could only be opened by a password, which was forgotten by the time the Fellowship came to this door. The lake in front of the door was haunted by the Watcher in the Water, who kiled Óin when Balin came to this door to recolonize Moria and almost killed Frodo when the Fellowship tried to take the Moria route.')
            case 'WOODLAND REALM' :
                return ('A Silvan kingdom. Oropher, a Sindarin Elf, came to the kingdom in the Second age and quickly became its king. He established the capital on Amon Lanc. Oropher was killed in the War of the Last Alliance and his son Thranduil was king in the Third Age. When Sauron came to Dol Goldur, he slowly forced the Elves to the North and they became an isolated realm in the caves beneath Amon Lanc. Thranduil joined the Battle of the Five armies to claim the treasure of Erebor (after having imprisoned the Company when they traveled through Mirkwood). The dispute however watered down because of Gandalf and the arrival of two armies of Orcs. Aragorn later asked the elves of the Woodland Realm to hold Gollum there but they were raided by Orcs and Gollum escaped. In the War of the Ring, the Woodland Realm was attacked by Dol Goldur, but it was victorious and the Elves destroyed the fortress together with the Elves of Lothlórien.')
            case '' :
                return ('')
            case '' :
                return ('')

            default:
                return ('niks!')
        }
    }
}