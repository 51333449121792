import svgPanZoom from "svg-pan-zoom";

function enableLayersController(zoomScale) {
    if (zoomScale > 1.9) {
        let elements_one = document.getElementsByClassName('level_one');
        let elements_two = document.getElementsByClassName('level_two');

        for (let location of elements_one)
            location.classList.add('fade');
        for (let location of elements_two)
            location.classList.remove('fade')
    } else if (zoomScale < 1.9) {
        let elements_one = document.getElementsByClassName('level_one');
        let elements_two = document.getElementsByClassName('level_two');

        for (let location of elements_one)
            location.classList.remove('fade');
        for (let location of elements_two)
            location.classList.add('fade')
    }
}

export default function zoomAndPanning() {
    var eventsHandler;
    // var Hammer = require('react-hammerjs');

    // eventsHandler = {
    //     haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel']
    //   , init: function(options) {
    //       var instance = options.instance
    //         , initialScale = 1
    //         , pannedX = 0
    //         , pannedY = 0

    //       // Init Hammer
    //       // Listen only for pointer and touch events
    //       this.hammer = Hammer(options.svgElement, {
    //         inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
    //       })

    //       // Enable pinch
    //       this.hammer.get('pinch').set({enable: true})

    //       // Handle double tap
    //       this.hammer.on('doubletap', function(ev){
    //         instance.zoomIn()
    //       })

    //       // Handle pan
    //       this.hammer.on('panstart panmove', function(ev){
    //         // On pan start reset panned variables
    //         if (ev.type === 'panstart') {
    //           pannedX = 0
    //           pannedY = 0
    //         }

    //         // Pan only the difference
    //         instance.panBy({x: ev.deltaX - pannedX, y: ev.deltaY - pannedY})
    //         pannedX = ev.deltaX
    //         pannedY = ev.deltaY
    //       })

    //       // Handle pinch
    //       this.hammer.on('pinchstart pinchmove', function(ev){
    //         // On pinch start remember initial zoom
    //         if (ev.type === 'pinchstart') {
    //           initialScale = instance.getZoom()
    //           instance.zoomAtPoint(initialScale * ev.scale, {x: ev.center.x, y: ev.center.y})
    //         }

    //         instance.zoomAtPoint(initialScale * ev.scale, {x: ev.center.x, y: ev.center.y})
    //       })

    //       // Prevent moving the page on some devices when panning over SVG
    //       options.svgElement.addEventListener('touchmove', function(e){ e.preventDefault(); });
    //     }

    //   , destroy: function(){
    //       this.hammer.destroy()
    //     }
    //   }
      
    window.zoom = svgPanZoom('#svg_frame', {
        viewportSelector: '#svg_frame'
        , panEnabled: true
        , controlIconsEnabled: true
        , zoomEnabled: true
        , dblClickZoomEnabled: false
        , mouseWheelZoomEnabled: true
        , preventMouseEventsDefault: true
        , zoomScaleSensitivity: 0.2
        , minZoom: 0.5
        , maxZoom: 10
        , fit: true
        , contain: false
        , center: true
        , refreshRate: 'auto'
        , beforeZoom: function(){}
        , onZoom: function(zoom_scale){
            enableLayersController(zoom_scale);
        }
        , beforePan: function(){}
        , onPan: function(){}
        , onUpdatedCTM: function(){}
        , eventsListenerElement: null
        , customEventsHandler: eventsHandler,
    });
}