import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import React from "react";
import { FaClock, FaChurch, FaLayerGroup, FaHeart, FaAngleDoubleRight, FaAngleDoubleLeft, FaRoad } from "react-icons/fa";
import "./sidebar.scss";

export default class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sidebarState: "",
			displayedIcon: <FaAngleDoubleLeft className="FaAngleDoubleLeft" size="30px" onClick={this.toggleSidebarState} />,
			sidebarHeader: "sidebarHeaderOpen",
			textDisplayed: <div className="displayedText">TOGGLES</div>,
			layerOne: "checked",
			layerTwo: "checked",
			layerThree: "checked",
		};
	}

	handleChangeChk(layer) {
		var elementArray = document.getElementsByClassName("level_" + layer);
		Array.prototype.forEach.call(elementArray, (child) => {
			child.classList.toggle("fade");
		});
	}

	toggleSidebarState = () => {
		var state = this.state.sidebarState === "collapsed";

		this.setState({
			sidebarState: state ? "" : "collapsed",
			displayedIcon: state ? (
				<FaAngleDoubleLeft className="FaAngleDoubleLeft" size="30px" onClick={this.toggleSidebarState} />
				) : (
					<FaAngleDoubleRight className="FaAngleDoubleRight" size="30px" onClick={this.toggleSidebarState} />
					),
					sidebarHeader: state ? "sidebarHeaderOpen" : "sidebarHeader",
					textDisplayed: state ? <div className="displayedText">TOGGLES</div> : "",
		});
	};

	render() {
		return (
			<ProSidebar className={this.state.sidebarState}>
				<SidebarHeader className={this.state.sidebarHeader}>
					{this.state.textDisplayed}
					{this.state.displayedIcon}
				</SidebarHeader>
				<SidebarContent>
					<Menu iconShape="round">
						<SubMenu title="Base Layers" icon={<FaLayerGroup />} >
							<div className="toggleLayer">
								<div className="toggleLayerDescription">
									<p>Top layer</p>
								</div>
								<div className="onoffswitch">
									<input
										type="checkbox"
										name="onoffswitch"
										className="onoffswitch-checkbox"
										id="layerOneToggle"
										tabIndex="0"
										defaultChecked="checked"
										onClick={() => this.handleChangeChk("one")}></input>
									<label className="onoffswitch-label" htmlFor="layerOneToggle">
										<span className="onoffswitch-inner"></span>
										<span className="onoffswitch-switch"></span>
									</label>
								</div>
							</div>
							<div className="toggleLayer">
								<div className="toggleLayerDescription">
									<p>Middle Layer</p>
								</div>
								<div className="onoffswitch">
									<input
										type="checkbox"
										name="onoffswitch"
										className="onoffswitch-checkbox"
										id="layerTwoToggle"
										tabIndex="0"
										defaultChecked="checked"
										onClick={() => this.handleChangeChk("two")}></input>
									<label className="onoffswitch-label" htmlFor="layerTwoToggle">
										<span className="onoffswitch-inner"></span>
										<span className="onoffswitch-switch"></span>
									</label>
								</div>
							</div>
							<div className="toggleLayer">
								<div className="toggleLayerDescription">
									<p>Bottom layer</p>
								</div>
								<div className="onoffswitch">
									<input
										type="checkbox"
										name="onoffswitch"
										className="onoffswitch-checkbox"
										id="layerThreeToggle"
										tabIndex="0"
										defaultChecked="checked"
										onClick={() => this.handleChangeChk("three")}></input>
									<label className="onoffswitch-label" htmlFor="layerThreeToggle">
										<span className="onoffswitch-inner"></span>
										<span className="onoffswitch-switch"></span>
									</label>
								</div>
							</div>
						</SubMenu>
						{/* <SubMenu title="Routes and Events" icon={<FaRoad />}>
							<MenuItem icon={<FaClock />}>Coming soon!</MenuItem>
						</SubMenu>
						<SubMenu title="Regions/Politics" icon={<FaChurch />}>
							<MenuItem icon={<FaClock />}>Coming soon!</MenuItem>
						</SubMenu>
						<SubMenu title="Ethnicity" icon={<FaHeart />}>
							<MenuItem icon={<FaClock />}>Coming soon!</MenuItem>
						</SubMenu> */}
					</Menu>
				</SidebarContent>
			</ProSidebar>
		);
	}
}
