import React from "react";
import "./svg.scss";

export default class SVG_map extends React.Component {

	componentDidMount() {
		var locations = document.getElementsByClassName("location");

		for (let i = 0; i < locations.length; i++) {
			locations[i].addEventListener("click", (e) => {
				this.props.handleClickLocation(i, locations);
			});
		}
	}

	render() {
		return (
			<svg version="1.1" id="svg_frame" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3600 2400" width="100%" height="100%" xmlSpace="preserve">
				<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Gabriola"></link>
				<g id="coastline">
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="4"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M1733.2,2057.5
								c3.5-3.6,7.4-3.2,11.7-5c3.8-1.6,7.1-4.9,10.5-7.2c6-4.1,11-8.3,12.1-16.1c1.2-8.5-0.6-18.3-1.7-26.8c-0.9-6.3-1.3-13.5-4.3-19.2
								c-4.2-8.1-13-17.3-21.6-20.8c-6.8-2.8-14.4-3.7-21.3-6.2c-7.5-2.6-14.6-6.4-17.2-14.4c-2.2-6.9,1.6-13.1,2.7-19.8
								c0.3-2.2,2.3-8.1,0.2-9.8c-2-1.7-9.3,0.3-11.4,1.2c-7.4,3-8.7,15.6-9.1,22.5c-0.3,4.1-2.4,6.7-6,8.7c-4.6,2.5-8.3,2.4-12.5-0.6
								c-2.4-1.7-7.6-4-10.2-1.4c-1.3,1.3-1,3-1.2,4.7c-0.4,2.8-1.3,5.5-2.9,7.9c-3.4,5.2-10.6,6-11.4,12.7c-0.9,7.9,1.4,15.1-1.6,22.7
								c-1.3,3.2-3.1,5.9-4.9,8.7c-2.6,4-1.3,8.2-3,12.4c-2.9,7.4-4.2,15.4-12.3,19c-9.2,4.1-18.5-0.9-24.2-8.2
								c-6.5-8.3-8.2-17.7-11.7-27.3c-1.3-3.6-3.8-7.2-7-9.3c-3.8-2.5-8-1.7-12.2-1.1c-2.3,0.3-4.3,1.1-6.6,0.3c-3.8-1.3-8.2-5.3-10.7-8.3
								c-4-4.8-2.1-11.9-3.9-17.5c-1.3-4.2-4.9-7.3-7.8-10.5c-2.6-2.8-2.6-5.6-3-9.2c-0.8-7.1-3.3-12.9-6.9-19c-1.7-2.9-4.7-6.6-5.2-10.1
								c-0.6-4.4,3.6-8.3,5.5-11.9c0.5-1,2.5-3.8,1.2-4.6c-1-0.6-5.7,2.1-6.7,2.5c-4.8,1.8-10.1,2.3-14.5-0.9c-7.2-5.2-6.9-15.5-6.4-23.3
								c0.3-4.9,0.3-9.9,2.4-14.4c1-2.1,2-3.9,2.1-6.3c0-2.3-0.9-4.4-1-6.7c-0.2-10.6,7.8-12.8,16.4-14.7c7.7-1.7,15-0.9,20.2,5.8
								c2.7,3.4,4.5,7.9,7.5,1.8c1.1-2.2,1.2-4.8,2.5-6.9c0.9-1.5,2.3-2.4,2.9-4.1c1.3-3.7-1-7.8-0.1-11.7c0.7-3,6.3-9.5,1.8-12
								c-1.4-0.8-4.2-0.2-5.9-0.3c-4.7-0.2-8-0.8-12.2-2.6c-8.5-3.6-16.3-2.3-24.9,0.1c-8.2,2.3-14.4,3.2-23,1.4c-5.2-1.1-10.4-2.8-14.7-6
								c-3.5-2.6-5.9-5.6-10.1-7.4c-4.8-2-9.9-2.3-15-2.3c-4.5,0-9.4-0.3-12.9,2.9c-2.5,2.3-4,5-6.9,6.9c-4.7,3.1-10.1,4.5-15.7,4.4
								c-4-0.1-7.9-1.8-11.8-1.5c-4.5,0.3-7.6,2.8-10.9,5.4c-7.5,5.9-17.2-0.1-24.5,6.2c-2.2,1.9-3.2,4.1-6.3,4.3
								c-4.4,0.3-10.9-1.6-14.6-4c-3-2-5.6-5.6-6-9.2c-0.3-2.4,0.6-4.9,1.7-7c0.2-0.4,2.3-3,2.2-3.2c-0.8-1.3-10.5,3.9-11.4,4.5
								c-2.4,1.4-4.1,3-6,5c-2.8,2.9-5.8,6.5-9.2,8.7c-4.1,2.5-9.3,2.1-13.9,1.9c-4.4-0.2-8.6,1.4-12.5,3.4c-2.1,1.1-3.9,2.7-6.3,3.2
								c-6.4,1.3-13.3-0.9-19.7-1.1c-3.8-0.1-8.3-0.2-11.5,2c-3,2-5.3,5.2-8,7.5c-3.5,2.9-7.3,4.7-11.8,5.4c-3.2,0.5-7.5-1-10.4-0.1
								c-4,1.2-4.5,7.6-5.2,11c-0.8,4.1-1.1,8.2-1.9,12.2c-1,4.8-2.8,9.4-4.9,13.9c-1.8,4-3.5,8.3-6.9,11.3c-3.4,3.1-7.8,5-12,6.7
								c-4.5,1.8-8.4,2.5-10.3,7.4c-3.8,9.5-13.9,10.7-22.8,7.8c-1.5-0.5-2.8-1.3-4.3-1.8c-2.6-0.8-3.3-0.1-5.6,0.9
								c-4.5,1.8-9.2-0.6-12-4.2c-2.4-3-4-6.8-5.7-10.3c-1.1-2.2-4.8-13.2-7.5-8.9c-1.8,2.8-1.4,6.7-1.2,9.8c0.2,4.6,0.3,9.2,0.3,13.8
								c0,7.8-4,17.8-11.6,21c-3.8,1.6-7.5-0.5-10-3.4c-1.1-1.3-1.6-3-2.7-4.3c-1.6-1.9-4.2-2.4-6.6-2.5c-3.9-0.2-4.5,0.3-6.3-3.7
								c-1.6-3.5-4.7-6.4-8.8-5.7c-4.4,0.8-8.1,3.5-12.7,3.4c-5.2-0.1-11-2.2-15.7-4.1c-4.2-1.6-6.6-6.9-10.9-7.5c-4.1-0.6-7,3.3-10.8,2
								c-2.7-1-4.2-2.3-7.4-1.7c-2.6,0.5-4.4,2.1-6.8,2.8c-4,1.3-9-1-11.6-4.1c-3.2-3.8-6.4-6.9-10.1-10.3c-2.1-1.9-4.3-4.3-5.2-7.1
								c-0.2-0.8,0.1-4.9-0.3-5.1c-0.7-0.3-8,9-9.5,10c-4.6,3.2-8.2,1.9-13.2,1.8c-5.4-0.2-10.5,3.4-14,7.2c-3.8,4.1-7.2,7.9-11.9,10.9
								c-8.4,5.3-17.9,8.3-27.6,9.9c-3.2,0.6-6.6,0.6-9.6,2c-4.3,2.1-6.5,5.9-9.7,9.1c-3.5,3.4-7.9,4.9-12.7,4.4c-5.5-0.6-6.4,2.6-9.5,6.6
								c-1.3,1.7-1.8,1.8-3.9,2.3c-2.6,0.6-4.2,1.7-5.6,4.1c-1,1.7-1.2,3.7-2.9,5c-2.2,1.7-5.8,1.9-8.4,2c-12.2,0.6-24.1-3.7-36.3-0.4
								c-10.1,2.8-22.6,4.3-30.4-4.3c-6.1-6.6-8.7-17-7.2-25.7c1.8-10.8,9.6-18.3,20.3-20.5c5-1,9.2-1.4,13.7-4.2c3.5-2.2,6.3-5.4,9.9-7.4
								c4.7-2.6,10.6-1.4,15.6-3.4c4-1.6,8.1-4.1,11.4-6.9c7.4-6.4,12.8-15.7,18.1-23.8c3.3-5,6.4-10.1,10.2-14.8
								c6.3-8,13.4-15.4,20.2-22.9c5.8-6.4,9.5-11.2,10.9-19.9c0.6-3.4,0.9-6.8,0.7-10.2c-0.4-5.9-0.5-9.8,4.7-13.6c4.3-3.1,8-2.8,8.9-8.9
								c0.3-1.8-0.1-3.8,0.7-5.5c0.9-1.9,2.8-2.8,4.2-4.3c7.7-8,5.2-18.9,2.1-28.5c-1.9-5.9-3.9-11.8-5-17.8c-1.1-6-0.7-11.6-0.4-17.6
								c0.3-5.6-1.5-10.3-2.7-15.6c-0.9-4.1-1-8.3-0.9-12.5c0.2-8.6,0.8-17.1,0.7-25.7c0-1.6-0.1-3.2-0.4-4.8c-0.4-2.3-1.1-4.5-1.4-6.8
								c-0.3-2.5,0.5-5.4-0.1-7.7c-0.9-3.7-6.8-4.1-9.7-5.9c-3.9-2.4-6.5-7.7-4.6-12.1c1.6-3.7,5.9-8.3,9.2-10.5c1.5-1.1,2.5-0.9,4.3-1.3
								c3.2-0.7,1.4-2.5,0.2-5.1c-4.5-9-6.8-18.7-6.7-28.7c0.1-4.6,0.7-8.3-2.4-12c-2.9-3.4-10.1-4.6-9.8-9.6c0.3-4.7,3.3-9.5,7.4-11.7
								c2.1-1.1,4-2,5.5-3.9c1.3-1.6,1-2.8,1.6-4.5c1.3-3.3,6.8-3.6,9.6-4.5c4.4-1.4,2.5-4.5,5-7.7c2.4-3.1,6.8-4.4,8.5-7.9
								c3.5-7.3-6.7-4.4-9.9-2.5c-8.7,5-12,17.2-23.4,17.8c-2.2,0.1-4.9-0.4-6.8-1.7c-1.8-1.3-1.6-4-3.8-3.8c-1.3,0.2-2.9,2.2-4.4,2.8
								c-2.4,0.8-5.2,0.9-7.1-0.8c-2.9-2.7-3.6-8.5-3.5-12.2c0-5,1.9-9,3.1-13.7c1.3-5.3,2.4-10.7,0.4-15.9c-1.7-4.5-3.4-8.6-3.6-13.6
								c-0.2-5.3,0.2-10.8,1.3-15.9c0.8-3.7,1.5-7.1,1.4-10.9c-0.2-9.9-4.6-19.8,0.7-29.3c6.8-12.4,28.2-17.8,24.6-35.3
								c-1-4.9-4.2-9.3-6-13.9c-2.1-5.5-3.6-11.1-4.4-16.9c-1.3-8.7-3.4-18.4,1.5-26.3c2.7-4.5,5.3-9,6.5-14.2c0.5-2,0.2-4,0.5-6.1
								c0.1-0.5,2.2-6,2.8-5.5c1,0.8-11.7,12-13.1,13.4c-2,2-3.5,4.4-5.5,6.4c-2.5,2.5-5.4,4.2-8.5,5.9c-6.4,3.6-13.3,6-19.7,9.6
								c-5.4,3.1-10.7,6.6-15.5,10.4c-11,8.7-18,20.8-32.7,23.7c-17.1,3.3-24.7-10.9-33-22.8c-2.6-3.7-6.9-6.2-11.2-7.5
								c-3.6-1.1-7.3-1.2-11-0.4c-2.7,0.6-4.8,1.4-7.3-0.3c-5.3-3.7-5-12.4-3.6-17.8c1.3-5.2,1.6-8.8,0.1-13.9c-1.9-6.7-4.7-13.3-7.2-19.8
								c-2-5.2-4.8-10.6-5.7-16.2c-1.1-6.7-0.3-14.1,3.3-19.9c3.6-5.7,8.7-8.8,8.5-16.3c-0.1-5.9-5.7-9.8-4.9-15.6
								c0.9-6.5,3.7-12.8,5.4-19.2c1.2-4.6,1.3-10-0.7-14.4c-1.8-4-5.3-5.4-7.8-8.7c-1.3-1.7-1.9-3.8-2.1-5.9c0-0.2,0.1-3.9-0.2-3.9
								c-1.4-0.2-5.4,22.6-6.2,25.4c-1,3.6-3.3,6.7-4.7,10.1c-1.9,4.7-2.6,9.9-4.3,14.7c-0.7,2-1.3,4.5-2.7,6.2c-1.7,2.1-4.4,2.4-6.6,3.8
								c-4.9,3.2-2.5,9.9-5.9,14c-7.7,9.4-19.8,13.8-31.8,11.2c-5.2-1.1-11.5-3.9-14.8-8.2c-2-2.5-2.1-5.6-3.9-8.2c-1.8-2.6-5-3.4-7-5.8
								c-2.8-3.2-5.9-10.8-2.5-14.4c2.2-2.4,5.1-2.1,6-6c0.6-2.9-0.3-5.8-1.5-8.3c-2.7-5.3-3.2-9.4-3-15.4c0.1-4,0.5-8,1.2-12
								c2.3-12.9,7.4-25.2,12.7-37.2c4.8-10.8,7.7-21.1,5.3-33c-1.2-5.8-4.1,0.4-6.2,2.7c-3,3.4-6.9,5.8-9.7,9.5c-2.6,3.6-4.9,7.7-8,10.8
								c-4.3,4.4-11.5,5.2-17.2,6.6c-9.7,2.4-19,4.5-29.1,2.5c-5.4-1.1-13.3-3.2-17.2-7.4c-1.6-1.8-2.7-4.3-5.5-4.1c-3,0.2-4.4,2-7.7,0.8
								c-4.1-1.5-7.2-5.3-7.5-9.7c-0.1-1.5,0.1-3,0.8-4.3c0.4-0.7,1.4-1.6,1.6-2.3c0.6-2.4-2.4-3.6-4-4.7c-2.6-1.8-4.6-3.8-6.1-6.5
								c-1.4-2.4-2.6-4-5.2-5.3c-5.1-2.4-11.7-2.1-17.1-1.4c-5,0.7-9.8,0.9-14.6-0.5c-5.1-1.5-10.1-1.7-15.4-2.1c-5-0.3-12.1-0.9-15.5-5.1
								c-1.4-1.8-1.3-4.3-4-3.8c-2.7,0.6-4.7,3.1-7.9,2c-3.7-1.3-3.7-8.9-8-6.2c-4.3,2.8-5.8,7.1-11.5,6.3c-4.5-0.7-8.8-2.8-13.3-3.6
								c-9.4-1.8-19-5.2-27.9-8.7c-2.9-1.1-5.1-2.9-7.7-4.5c-4.9-3-10.4-1.5-15.8-2.3c-12.7-1.9-18.1-13.4-18.3-25.2
								c-0.1-7.6,1.7-15.2,0.8-22.8c-0.6-4.9-2.8-9.9-5.8-13.9c-3.1-4.2-7.3-6.1-8.2-11.7c-0.5-3.4,0-6.5,1.3-9.6c1.1-2.7,1.5-4.6,0.1-7.4
								c-2-4-6.4-8.9-11.2-7.2c-4.5,1.6-8.5,4.7-13.3,5.3c-4.4,0.5-9,0-13.4,0.3c-5.1,0.3-8.5-0.1-12.3-3.9c-2.9-2.8-3.3-2.3-6.7-0.5
								c-2.1,1.1-4.8,1.7-7.1,2.2c-5.4,1-11.1,0.2-16.4-0.4c-4.1-0.5-9-0.8-12.2-3.8c-3.8-3.5-4.7-10-5.5-14.8c-1-6.2-1-12.4-1.5-18.6
								c-0.5-5.8-3.6-9.5-9.1-11.6c-1.8-0.7-3.9-0.7-5.4-2.1c-1.8-1.7-2-4.7-1.6-7c0.7-5.1,5.4-9.2,9.1-12.4c4.1-3.5,6.5-7.6,7.8-12.9
								c1.4-5.7,1.8-12.2-1.1-17.5c-1.1-2.1-2.8-3.8-3.6-6.1c-1.3-3.4-1.3-7.3-0.8-10.8c0.4-2.7,0.9-5.4,1.8-7.9c0.6-1.8,1.6-3.6,1.4-5.6
								c-0.2-2.3-1.6-4.5-2.6-6.5c-2.7-5.5-5.9-9.4-1-14.5c4.3-4.5,9.1-8.9,11.4-14.9c2-5.3,1.9-11.2,1.7-16.7c-0.2-4.5-0.9-11.4,1.5-15.5
								c2.2-3.7,8.1-3.4,11.8-3.3c5.5,0.2,12.2,1.4,17.1,4c9.2,5,5.3,18.9,13.6,24.3c8.5,5.4,20.6,1.7,29.8,0.9c4.1-0.4,7.9-1.1,11.5-3.1
								c2.9-1.6,4.5-3.6,5.7-6.6c1-2.4,2-3.4,4.6-4c9.9-2.3,21.8-2.2,30.1-8.9c3.9-3.1,9.2-4.7,13.9-2c2.1,1.2,3.6,3.3,4.4,5.5
								c0.3,0.7,1.3,6.2,0.1,6.3c-1.5,0.1-2-9.5-2-10.1c-0.1-4.7,0.3-9.5,2.5-13.7c4.1-7.7,12.3-11.7,20.8-11.4c12.7,0.4,24,11,36.9,8
								c9.1-2.1,17.8-8.9,25-14.6c6.1-4.8,11.8-10.1,17.6-15.1c9.1-7.9,18.3-15.7,27.2-23.8c0.7-0.6,17.9-15.6,17.3-16.4
								c-0.5-0.7-23.2,15.8-25.4,17.3c-7.8,5.2-15.5,8.6-24.3,11.6c-11.5,3.9-23,8.3-35.1,10c-8.8,1.2-17.7,1.7-26.5,1.8
								c-6.4,0.1-13,0.2-19.5-0.4c-4.4-0.4-11.3-1-14.6-4.2c-2.2-2.2-1.8-4.6-4.8-2.3c-1.9,1.5-2.9,2.7-5.3,3.7
								c-6.6,2.7-12.3,0.2-18.2-3.1c-8.8-5-17.6-9.8-26.5-14.4c-2.5-1.3-4.8-2.4-6.9-4.3c-3.3-3-6.5-5.7-10.6-7.4c-2.5-1.1-5.2-2-8-1.8
								c-3.2,0.2-5.1,2.1-7.8,3.4c-5.1,2.4-11.4-0.8-16.3-2.4c-5.9-1.9-10.2-4-14.5-8.5c-3.1-3.2-8.8-5.1-12.3-1.2
								c-3.8,4.2-2.4,12.3-1.1,17.2c1.6,5.9,4.7,10.9,6.9,16.5c2.5,6.2,4.1,12.6,4,19.4c-0.1,13-9.5,23.6-23,18.6c-2.8-1-5.1-3-8.2-3.3
								c-2.5-0.3-5,0.2-7.5,0.2c-7.2-0.1-10.8-3.3-14.3-9.6c-2.5-4.5-4.2-11-8.9-13.9c-2.3-1.4-4.9-0.7-7.3-1.5c-2.1-0.7-3.5-2.3-5.1-3.7
								c-2.9-2.7-6.1-6.7-10.2-7.5c-5.9-1-12.4-0.2-18.5-0.8c-4.7-0.5-11-0.3-15.3-2.5c-4.1-2.1-5.9-8-7.7-11.8c-1.1-2.3-2.7-4.9-2.9-7.5
								c-0.1-2.2,1-3.8,1.8-5.7c2.4-5.8,2.2-10.1-4.4-10.9c-4.9-0.6-9.8-0.3-14.6-0.8c-1.4-0.1-2.5-0.4-3.7-1.2c-6.8-4.2-9.3-15-11.1-22.1
								c-4.9-20-3.7-41.7-2.2-62c0.8-10.4-0.9-20.6-0.8-31c0-6,1.5-11.6,2.5-17.5c1-5.7,0.3-11.1-1.6-16.5c-2.1-6-1.3-11.7-0.5-17.8
								c0.8-6.6-1.7-11.2-3.4-17.3c-2.3-8-2.2-17.6,1.9-24.9c2.5-4.4,6.6-8.3,11.4-9.9c4.6-1.5,8.4-0.1,12.7,1.6c10.1,4,20.9,8.1,31,2.4
								c11-6.2,20.6-16.8,27.8-27c4.8-6.9,8.6-13,16-17.4c5.7-3.4,11.7-6.7,18.2-8.4c6.2-1.6,14.5,0,19.9-3.8c5.5-3.8,4.9-11.2,11.5-14.2
								c5.4-2.5,11.4-2.2,17.2-2.5c5.2-0.3,7.6-3.3,11.4-6.2c5.7-4.5,13.1-6.4,19.9-8.7c4.4-1.5,11-5.6,8.4-11.5
								c-2.5-5.6-9.3-3.3-13.7-2.4c-8,1.6-14.6,0.1-21.7-3.8c-1.7-0.9-3.3-2.2-5.1-2.6c-2.6-0.6-4.6,0.5-7,1.3c-5.4,1.6-11.3-0.2-16.6-1.4
								c-2.9-0.6-7.7-1.1-9.9-3.5c-2-2.1-2.7-6.3-3.8-8.9c-0.8-2-1.9-3.9-2.3-6c-0.5-2.4,0.4-4.7-0.1-7c-0.5-2.4-2.3-3.9-3.7-5.8
								c-2-2.6-4-5.5-5-8.7c-1.8-5.7,1.1-11.4,3.8-16.3c2.7-5.1,6.1-9.8,10-14.1c2.8-3,6.5-5.4,8.7-8.8c3.1-4.9,2.4-11,6-15.6
								c3.1-3.9,8.1-6.5,13-7.3c4.8-0.8,9.6,1.9,14.2-0.7c3.7-2.1,6.2-6.6,9.2-9.5c4.7-4.4,9.9-9.4,16.6-10.2c6-0.7,10,0.5,13.4-5.6
								c3.3-6,7.4-9.8,13.7-12.5c3.6-1.6,7.2-3.1,10.6-5c10.5-5.8,20.6-12.4,30.7-18.9c7.6-4.9,14.6-10.6,22.1-15.6c7.5-5,18.4-5.8,27-7
								c7.5-1.1,15.4-1.5,22.8-3.2c6.5-1.6,11.3-7.5,17.8-8.3c5.5-0.7,9.7,2.3,15.3,0.7c10-3,19.7-8.3,29-12.9c4.8-2.4,9.2-4.9,13-8.7
								c4.4-4.3,9.9-10.4,16.3-11.2c6.1-0.8,7.1,7.1,12.5,6.5c2.7-0.3,4.4-2.7,6.4-4.2c1.4-1,2.9-1.7,4.4-2.4c11.6-5.3,23.9-8.8,35.6-13.8
								c5.1-2.2,11.3-4.6,14.9-9.1c3.5-4.4,2.7-12.2,9.3-13.3c8.5-1.4,15.1,5.4,23.4,4.1c9.1-1.5,18.5-1.9,27.4-3.8c4.7-1,8.9-3.2,13.5-1
								c2.4,1.1,4.5,3,5.4,5.6c0.9,2.6,0.4,5.5,1.4,8.2c1,2.7,3.2,4.2,4.6,6.7c1.6,2.8,1.8,5.9,1.6,9c-0.4,6.8-1.1,14.7-5.2,20.4
								c-2,2.8-4.2,5-7.4,6.1c-2.7,0.9-4.1,1.1-4.3,4.2c-0.2,4,0.1,9.5-4.2,11.4c-4.4,2-10.1,2.5-14.9,3.5c-1.9,0.4-5.4,0.1-6.6,1.2
								c-1.4,1.4,0,3.9-0.1,5.6c-0.1,2.8-1.2,5.4-3.3,7.2c-1.2,1-3.6,1.9-3.7,3.7c-0.2,2.4,3.8,4.1,5.5,4.9c5.4,2.4,11.6,1.7,17,4.2
								c4.8,2.2,9.1,7.4,7.4,13c-0.7,2.4-2.8,4.6-1.9,7.3c0.6,1.9,2.6,3.3,4,4.7c3,3,5.6,6.9,9.1,9.4c9.9,7.1,21.2,1.1,30.8-3
								c6.7-2.9,13.4-6.3,20.5-8.1c12-2.9,23.9-2.3,35.9,0.8c6.3,1.6,12.6,3.2,18.6,5.6c6,2.4,11.5,5.8,18.2,4.4c2.8-0.6,5.6-1.9,7.2-4.4
								c1.7-2.7,1.9-5.7,5.2-7.2c2.6-1.2,5.3-1.2,7.8-2.8c2.6-1.7,4.6-4.1,6.2-6.8c2.3-3.9,5.7-10.7,0.4-13.6c-4.5-2.5-10.5-0.4-14.7-3.1
								c-1.7-1.1-2.8-2.9-4-4.4c-2.2-2.8-4.6-5.6-7.1-8.1c-4.3-4.3-8.1-4.5-13.8-2.8c-10.3,3-22.3,11.3-31.5,0.9
								c-8.7-9.8-1.2-21.7-0.1-32.6c0.4-4.1-1-8.6,2.3-11.8c4-3.8,12-5.8,17.2-7c5.6-1.3,10.9,1.5,15.1,5.1c1.4,1.2,2.9,3.8,4.7,4.4
								c2.7,0.9,4.2-1.7,6-3.2c10.3-8.4,25.1-1.4,36.3,0.5c8.6,1.5,17.2-1.2,25.9-0.3c7.1,0.8,13.9,2.8,21,3.8c7.1,0.9,14.2,0.3,21.2-1.1
								c5-1,10.9-1.6,15-4.9c3-2.4,4.8-8.4,9.2-5.1c6.7,5.1,10.2,13.7,16.8,18.9c8.8,7,22.2,6.2,28-4.2c1.2-2.2,1.1-5.9,3-7.6
								c1.8-1.6,5.6,0.3,5.2-2.5c-0.3-2-3-4.8-3.7-6.8c-1-2.6-2.2-6.3-5.4-6.8c-2.9-0.4-4.1,1.1-6.3-1.6c-1.9-2.4-2.8-5.1-5.9-6.4
								c-2.5-1-5.3-1.1-7.8-2.3c-2.6-1.3-4.8-3.2-6.9-5.2c-9.1-8.6-16.1-19-20.2-30.8"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="4"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M1667.5,2373.7
								c1.3-3.3,3.4-7.4,6.7-11.5c3.5-4.3,5.2-4.6,10.8-10.7c4.7-5,7-7.5,8.5-10.7c2.2-4.9,1.5-7.3,4.7-11c2.3-2.7,5.1-4.2,6.2-4.7
								c4.1-1.9,5.9-1,9.2-2.5c0.5-0.2,4.9-2.5,6.8-7.2c1.6-3.8-0.1-5.1,1-11.5c0.6-3.5,1.1-6.3,3.2-8.8c2.6-3.1,6.9-5.2,11-5
								c2.5,0.1,5.5,1.5,11.5,4.2c3.2,1.5,4.4,2.2,6.3,2c2.5-0.2,3-1.6,6.7-3.3c2.5-1.2,5.3-2.5,8.3-2c2.2,0.3,2.2,1.2,8.2,4.5
								c3,1.7,4.5,2.5,5.8,2.5c2.3,0,4-1.2,7.5-3.7c2.4-1.7,3.5-2.5,3.7-3.3c0.4-3-6.1-6.9-11.5-8.5c-3.2-1-6.1-1.1-11.8-1.3
								c-6.7-0.3-6.5,0.3-9.7-0.2c-5.8-0.9-5.8-2.8-12.8-4.5c-4.4-1-5.4-0.6-9-2c-2.5-1-5.1-2.1-6.8-4.7c-1.9-2.7-1.8-5.7-1.7-8.5
								c0.2-4.9,1.6-7.4,3.5-13.3c2-6.2,2.9-9.2,3.2-13c0.4-6.1-1.2-11.1-3.3-17.7c-2.2-6.8-3.2-7.1-3.7-11.2c-0.2-1.8-0.8-7.4,1.8-13.3
								c2.6-5.9,5.5-5.5,9.8-12.2c1.6-2.5,4.6-7.3,5.2-13.7c0.4-5.4-1.2-9.7-4.5-18.2c-2.9-7.4-3.6-6.7-4.8-11.2
								c-1.8-6.4-1.5-11.8-1.2-19.2c0.4-9.1,1.6-10.6,0.2-14.8c-0.7-1.9-2.3-5.6-4.3-9.2c-1.8-3.2-2.7-4.2-3-6.3c-0.5-3.3,1-3.6,0.8-7.7
								c-0.1-2.3-0.3-5.7-2.5-8.3c-0.8-1-1.8-1.6-2-3c-0.2-1.4,0.4-2.6,1-3.5"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M1653.9,2373.5
								c3.8-6.4,7.5-11.3,10.1-14.6c9.3-11.6,13.9-17.4,17.4-20c1.5-1.1,4.3-3.1,5.4-6.6c0.9-3-0.2-4.5,0.4-7c1-4,5.3-6,9-7.8
								c5.7-2.8,7.7-1.8,11.4-4.9c1.2-1.1,3.3-2.9,4-5.8c0.8-3.3-1.1-4.4-1-8.1c0.1-5,3.6-8.8,6-11.2c2.5-2.6,4.7-4.9,8.1-5.6
								c2.8-0.6,5.9,0.4,12.1,2.2c2.9,0.9,4,1.1,6.2,1.7c0.5,0.1,1.2,0.3,2.1,0.5c0,0,1.2,0.3,2.3,0.4c2.9,0.5,9.4,0.3,9.4,0
								c0-0.2-2.7-0.1-7.2-0.5c-8.9-0.8-15.2-1.4-20.9-5.3c-1.9-1.3-5.3-3.7-7.1-8.1c-1.2-2.9-1.1-5.6-1-8.3c0.1-4.4,1.1-7.3,1.9-10.4
								c1.6-6.4,2-11.2,2.6-17.9c0.7-7.6,1-11.3-0.2-15.5c-1-3.5-2-4.4-3.3-9.3c-0.8-2.9-1.9-6.7-1.8-10.7c0-7.3,3.6-12.9,8-19.7
								c3.9-6,5.6-6.8,7.3-11.7c1.1-2.9,2.4-6.7,1.5-11.2c-0.8-4-2.6-4.4-5.7-11.5c-0.7-1.7-1.9-4.4-2.7-7.8c-0.7-2.8-1-5.6-1-11.3
								c0-11,1.1-12.3,0.3-19.3c-0.5-4.5-1.3-7.4-3-10.2c-1.4-2.2-1.9-2-3-4.2c-2.6-4.9-2.2-10.3-2.2-10.8c0.3-3,1.1-4.2,0.3-6.3
								c-0.6-1.7-1.8-2.8-2.7-3.5"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M1733.8,2042.9
								c4.8-0.9,8.5-2.4,10.9-3.6c3.3-1.7,6.8-3.4,9.9-7.2c1.2-1.6,4-5,4.5-9.9c0.4-3.7-0.9-4.2-2.8-12.5c-1.1-5.1-1.6-9.6-1.9-11.8
								c-0.7-6.5-0.3-6.7-1-9.2c-1.4-4.8-4.3-7.9-6.6-10.5c-2.1-2.3-6.3-6.7-13-9c-3.4-1.2-3.4-0.4-9.4-1.8c-2.8-0.6-7.9-1.8-13.6-4.5
								c-4.4-2.1-9-4.2-13.2-9c-2.5-2.9-5.9-6.6-6-11.8c-0.1-2.8,0.9-3.4,1.9-9.2c0.9-5.5,0.7-8.9,0.8-8.9c0.1,0-1.9,16.3-3.5,21.9
								c-0.1,0.4-0.3,1.1-0.8,1.9c-1,2-2.6,3.2-4.9,4.6c-3.6,2.3-5.4,3.4-7.2,3.9c-1.1,0.3-5.5,1.4-10-0.4c-1.5-0.6-2.6-1.3-4.1-1
								c-2,0.4-3.3,2.3-3.5,2.6c-1,1.4-0.7,2.2-1.4,3.8c-0.9,2-2.4,2.7-3.8,3.9c-4.6,3.8-6,9.5-6.1,10.2c-0.5,2.3-0.4,4.4-0.1,8.6
								c0.2,4,0.7,5.3-0.1,7.4c-0.9,2.2-2,2.1-3.6,4.8c-1.4,2.2-1.1,3.1-2.2,6.8c-1.9,5.9-4.2,8.2-3.4,9c0.8,0.8,4-1.4,6-2.7
								c3.6-2.4,4.5-3.8,8-6.7c4.2-3.5,4.5-2.7,8.3-6c2.6-2.2,4.8-4.1,6.8-7.3c2.8-4.5,1.2-5.4,3.5-8.3c3.3-4.3,6.7-2.6,11.7-7.8
								c1.8-1.9,2-2.8,4.2-4.5c3-2.4,6.3-3.6,6.5-3.7c2.1-0.8,3.9-1,3.8-1.3c0-0.4-3.3,0.6-5.5-1.2c-1.5-1.2-1.3-2.7-2.5-3
								c-1.1-0.3-2,1-3.5,2.2c-1.9,1.4-4.1,1.8-8.5,2.7c-4.9,0.9-5,0.2-8.2,1.2c-2,0.6-4.5,1.4-6.5,3.5c-1.6,1.7-2.1,3.5-3,6.8
								c-0.7,2.5-1.8,6.5-3.3,11.8"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M1728.1,2037.5
								c0.5-1,1.2-2.3,2.6-3.5c2.1-1.7,3.8-1.6,6.2-2.5c0.4-0.1,5.2-2,8-6.4c2.5-3.9,2.2-8.2,2.1-9.8c-0.2-2.9-0.9-2.7-3.5-10.8
								c-1.9-6.1-2.2-8.3-2.2-9.1c-0.4-3.9,0.4-4.2-0.2-6.5c-1.1-3.9-4.3-6.3-6.9-8.1c-3-2.2-5.3-3.9-8.5-3.9c-1.7,0-3.1,0.5-5.1-0.4
								c-1.9-0.8-2.8-2.2-3-2c-0.3,0.2,1.1,2.6,3.1,5.1c3.6,4.3,5.2,4,8.1,7.6c1.6,2,3.9,4.8,3.9,8.8c0,3.5-1.9,6.3-2.5,7.1
								c-1.8,2.6-2.8,2.2-4,4.5c-1.3,2.4-1.3,4.8-1.2,7.8c0,3.5,0.7,3.5,0.6,6.9c-0.1,2.5-0.1,4.4-1,6.6c-0.4,1.1-1,2.1-1.5,4
								c-0.3,1.2-0.4,2.2-0.5,2.9"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M1733.5,2023.2
								c0.8-0.8,2-2.3,2.6-4.5c0.3-1,0.8-2.8,0.4-5c-0.4-1.8-1.2-3.1-1.8-3.8"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M1706.7,2044.8
								c-0.7-2.8-0.4-4.7,0-6c0.5-1.6,1.2-2.1,3.7-5.7c1.8-2.7,2.8-4,3.2-5c1.1-2.8,1.1-5.5,1-8.2c-0.1-4-0.9-4.4-0.3-6.5
								c0.2-0.9,0.6-1.9,3.5-5.7c4-5.2,4.3-4.7,5-6.3c1.4-3.4,0.7-7.6-1.2-10.5c-1.7-2.6-2.8-1.8-6-5.2c-1.6-1.7-1.6-2.2-5.8-8.5
								c-3-4.5-3.5-5-4.3-5.3c-3.1-1.5-6.7,0.2-9.3,1.3c-2.5,1.1-3,1.9-9.2,6.7c-6.4,5-7,5.1-8.2,6.8c-2.4,3.5-1.3,4.8-3.8,8.7
								c-0.7,1-2.1,2.9-7,6.7c-4.9,3.7-7.1,4.5-10.8,7c-5.8,3.9-11,7.2-13.8,13c-2.4,4.8-0.1,4.8-3.2,15c-3.1,10.2-3.8,12.2-3.8,12.2
								c-0.9,2.4-1.8,4.5-1.7,7.5c0.1,2.8,1,5,1.8,6.5"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M1647,2048.3
								c1.3-3.9,2-4,2.3-6c0.5-2.9-0.7-3.3-0.7-7c0-1.1,0.1-4.5,1.8-7.2c1.8-2.8,4-2.6,8.2-5c2.8-1.6,2.2-1.9,10-9.2
								c6.1-5.7,6.5-5.5,9.2-8.5c3.7-4.2,5.5-6.3,6.5-9.5c0.7-2.2,0.7-3.8,2.2-5.5c1.4-1.6,2.3-1.3,5.2-3.2c3.9-2.6,4.1-4.3,6.3-4.7
								c2.3-0.3,4.2,1.1,5.7,2.2c3.1,2.3,2.5,4,6.5,8.7c2,2.4,2.9,2.8,3,4.2c0.1,2.1-1.8,2.8-3.8,5.8c-3,4.5-3.3,9.5-3.3,11.2
								c-0.2,3.9,0.9,4,0.3,7.3c-0.2,1.3-0.8,5.1-3.7,8c-2,2-3,1.6-4.2,3.5c-1.2,1.9-1.2,3.9-1.2,6.7c0,3.9,0.9,4.4,0.3,6.5
								c-0.8,3.3-3.6,5.1-4.2,5.5c-1.4,1-2.9,1.4-9.8,1.8c-6.6,0.4-6.2,0.1-9.7,0.5c-5,0.6-5.3,1.2-10,1.5c-4.3,0.3-4.6-0.2-6.5,0.5
								c-3.3,1.2-3.8,3-7,3.7c-0.4,0.1-4.2,0.8-5.5-1c-0.2-0.3-0.5-0.9,0.8-6.5C1646.3,2050.6,1646.6,2049.6,1647,2048.3z"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M1642.6,2373
								c0.8-2.1,2.1-5.2,4.2-8.8c3.2-5.2,4.9-6.1,10.6-13.5c5.5-7.2,4.7-7.5,8-10.6c3.4-3.3,5.2-3.8,7.4-7.2c1.1-1.8,2.9-4.6,3.2-8.5
								c0.2-2.3-0.3-3.3,0.4-5.1c0.7-2,2.1-3.3,4.4-5c4-3,6.7-4.1,11.6-7c4.3-2.5,6.5-3.7,7.2-4.9c1.8-2.5,2-5.7,2.6-12
								c0.4-4.5,0.1-5.7,1.4-7.5c0.9-1.3,1.6-1.4,4.5-4.5c2.2-2.4,2.4-2.9,2.5-3.4c0.4-2-1.3-3.2-2.4-5.9c-0.7-1.7-0.6-3.6-0.4-7.4
								c0.4-6.9,1.7-12.1,2-13.2c1.5-5.8,2.1-5,2.9-8.8c1.2-5.8,0.3-10.6-0.5-15c-1.3-7-2.4-6.4-3.5-12.5c-1.5-8.6-0.3-15.3,0-16.6
								c1.1-5.4,3.1-9.4,5.2-13.8c2.8-5.7,4.7-7.7,7.1-13.9c1.5-3.8,2.2-5.7,2.2-7.9c0.1-5.3-2.7-7.5-5.6-14.5c-0.5-1.1-2.5-6.1-3.4-12.4
								c-1.4-9.6,0-19.8,0-19.8c0.4-3.1,1-5.9-0.4-9.1c-0.9-2.1-1.6-1.9-3.9-5.6c-1.6-2.7-3-5-3.2-8.1c-0.2-1.8,0.2-2.1,0-4.2
								c-0.2-2.6-0.9-4.7-1.5-6.1"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="4"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M1802.4,2290.9
								c-1.9-0.1-4-0.2-6.1-0.4c-0.7-0.1-1.5-0.2-2.2-0.2"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="4"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M1820.4,2293.7
								c2.1,0.9,4.5,1.8,7.1,2.7c3.1,1,6.1,1.6,8.7,2.1"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M1622.6,2061.8
								c0.6-2.4,1.7-6.1,3.2-10.5c2.2-6.3,2.8-6.2,3.8-10.1c1.3-5.2,0.6-6.2,2.2-12.1c0.2-0.6,0.6-2.1,0.4-2.2c-0.4-0.2-1.8,3.3-4.8,6.9
								c-0.9,1.1-2.7,3.3-5.6,5.2c-1.4,0.9-4.4,3-8.9,3.8c-4.5,0.8-8-0.1-9.6-0.5c-6.4-1.7-10.5-5.5-12.9-7.8
								c-1.8-1.8-10.8-10.4-12.9-22.9c-0.3-1.7-0.9-6.1-3.2-10.9c-0.4-0.8-1.9-3.8-5-5.9c-1.7-1.1-2.8-1.4-3.6-1.4c-1.3,0-2,0.4-3.1,0.8
								c-1.6,0.5-2.5,0.3-6.1,0.1c-7-0.4-6.7,0.1-8-0.4c-2.3-0.7-3.7-2.2-6.2-5c-2.2-2.4-5.4-5.9-7.4-11.5c-1.6-4.4-1.9-8.5-2-10.1
								c-0.2-3.2,0.2-3.9-0.4-5.9c-0.6-2-1.4-2.8-5.2-7.6c-4.4-5.5-4.8-6.2-5.1-7c-1-2.3-0.5-2.7-1.6-7.9c-0.7-3.3-1.3-6.1-2.6-9.2
								c-0.5-1.1-1.8-3.5-4.6-8.2c-3.2-5.6-3.8-6.3-3.9-8.1c-0.1-1.9,0.5-3.4-0.5-5c-0.9-1.5-2.6-1.9-2.8-2c-1.7-0.5-2.6,0.3-4.5,0.5
								c0,0-2.9,0.3-5.8-1.5c-2-1.2-3-3.2-4.6-9.6c-1.6-6.2-2.4-9.4-2.9-13.6c-0.4-3.6-0.9-8.6,0.1-14.9c0.6-3.8,1.1-4,2.4-10.8
								c0.8-4.3,1.2-6.5,1.2-9.5c0.1-5.4-1-5.8-0.4-8.8c0.9-4.6,4.4-7.6,6.8-9.6c4.4-3.8,8.9-5.1,12.6-6.1c2.2-0.6,6.6-1.9,12.4-1.6
								c5.3,0.2,8,0.3,10.9,1.8c1.4,0.7,2.9,1.7,4.8,1.2c1.3-0.3,2.8-1.3,3.2-3c0.4-1.8-0.7-3.3-0.9-3.5c-0.9-1.1-2.1-1.5-3.4-1.8
								c-3.6-0.8-6.6-0.6-8-0.5c-5.5,0.3-4.4-0.3-9.9,0c-3.4,0.2-6.3,0.4-10,1.2c-2.5,0.6-5.8,1.6-9.4,3.5"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M1540,1815.6
								c2.8-1.7,1.5-4.9-0.9-6.2c-2.9-1.5-7.4-1-10.6-1.2c-4.9-0.2-9.8-1.5-14.6-0.5c-5.9,1.2-11.2,4.1-17.3,4.3c-7,0.3-14.1-2.2-20.4-5
								c-9.3-4.2-15.8-10.4-26.6-10.5c-6.6-0.1-10.9,2.7-16,6.2c-4.5,3.1-9,6.3-14.7,5.8c-7-0.6-14.8-1.6-21.5,1.2
								c-3.1,1.3-4.2,4.1-6.8,5.7c-2.6,1.6-6.1,0.3-9,0.2c-6.3,0-12.2,2.6-17,6.5c-3.1,2.5-5.9,2.8-9.9,2.5c-8.6-0.6-21.5-0.8-24.6-10.8
								c-0.4-1.4,0.1-5.1-1.8-5.2c-1.9-0.2-5.2,4.2-6.3,5.4c-3.9,4.2-8.8,5.2-14.4,6.3c-3.3,0.7-5.8,0.2-9.1-0.2c-2.9-0.4-4.9,0.8-7.4,2.3
								c-4.8,2.8-7.3,4.7-13,4.2c-4.6-0.4-10.3-2.8-14.9-1.9c-5.2,1-10,5.9-14.1,9c-5.6,4.1-10.4,6.5-17.2,7.3c-7.5,0.8-8,8.7-9.7,14.8
								c-1.5,5.2-2.8,10.7-5.2,15.6c-3.3,6.7-8.5,12.6-13.7,17.8c-2.1,2.1-4.4,3.7-7.3,4.6c-3,0.9-6.2,0.9-8.4,3.4
								c-1.9,2.1-2.5,5.1-4.6,7.2c-2.5,2.7-5.7,4.3-9.1,5.5c-3.2,1.2-6.6,2.3-10.1,2.3c-4.2,0.1-6.8-2.1-10.5-3.2c-2.9-0.9-4.8,0.1-7.4,1
								c-3,1-6.7,1-9.7-0.2c-2.8-1.2-4.8-3.4-6.3-6c-0.3-0.5-1.8-4.4-2.7-4.1c-1.1,0.4-0.5,6.4-0.5,7.1c-0.2,2.9-0.7,5.7-1.5,8.5
								c-2,7.1-5.2,14.7-13.2,16.3c-6,1.2-13.1-0.2-16.5-5.6c-1.7-2.7-2.4-3.2-5.4-2.8c-3.4,0.4-6.8,0.4-9-2.6c-1.7-2.4-2.6-5.1-5.2-6.9
								c-3.6-2.4-4.6,0.1-7.8,1.4c-5.8,2.4-13.6-1.1-19.1-3.2c-2.9-1.1-5.9-2.1-8.6-3.4c-2.7-1.3-5.1-4.1-8.2-4.2
								c-4.5-0.1-8.6,4.1-12.9,1.2c-3.8-2.6-5.8-1.4-10.2-0.8c-4.1,0.6-8.6-0.3-12.2-2.3c-3.1-1.7-5.3-4.4-7.5-7.1
								c-1.9-2.3-4.3-7.4-7.8-7.5c-2.1-0.1-4.8,1.6-6.7,2.3c-2.3,0.9-4.3,1.2-6.8,1.1c-3.5-0.1-7.5,0.2-10.6,2c-3.2,1.8-4.5,5.2-6.9,7.8
								c-4,4.3-10.2,7.4-15.4,10c-6.3,3.2-12.6,4.2-19.3,5.8c-6.9,1.7-13.4,6.1-19,10.3c-5,3.8-10.7,8.1-17.1,9.1
								c-2.7,0.4-4.8,0.5-6.9,2.6c-2.3,2.4-4,4.4-7,6.1c-4.2,2.4-6.3,7.4-11,8.8c-5.4,1.7-11.6,1.2-17.2,0.7c-6.1-0.6-12.3-1.4-18.4-0.9
								c-7.1,0.6-13.4,4.5-20.7,5c-11.7,0.8-24.4-2.9-31.8-12.5c-3.6-4.7-4.3-10.4-5.1-16.1c-0.9-6.3-3.7-13-2-19.3
								c3.1-11.5,13.2-20.8,24.9-22.3c6.2-0.8,11.6-4,16.5-7.8c2.1-1.6,3.9-3.6,6.3-4.7c2.9-1.4,6.1-1.5,9.2-2.2c6.4-1.3,13.2-3.7,18-8.3
								c5.3-5,9.8-11.8,13.2-18.2c5.2-9.6,11.9-16.8,18.8-25.1c4.5-5.4,8.8-10.9,12.9-16.7c3.6-5.1,8.1-10.6,9.8-16.7
								c1.3-4.7,0.7-9.9,1.4-14.7c0.9-6.2,3.2-13.4,8.6-17.2c2.3-1.7,6-1.9,7-4.8c0.5-1.4,0-3.2,0.4-4.6c0.4-1.5,1-2.9,1.8-4.2
								c1.8-3.1,4.3-4.9,4.7-8.7c0.4-3.7-0.6-8-1.7-11.5c-1.8-5.8-4.5-11.2-5.9-17.2c-1.8-7.8-1-14.6,0.3-22.2c1-6.1-2.8-10.3-4-16
								c-1.4-6.7,0.1-12.5,1.7-18.8c1.4-5.2-1.3-8.9-2.4-13.7c-1.5-6.8,0.2-16.3-6.5-20.8c-2.3-1.6-4.9-1.9-6.5-4.5
								c-1.6-2.5-1.8-5.7-1.6-8.6c0.3-4,1.4-8.2,3.2-11.8c1.3-2.5,3.1-4.8,5.4-6.5c3.1-2.2,6.8-2.8,4.1-6.9c-3.2-5.1-3.6-10.2-3.6-16.2
								c0-4.7,1.1-13.7-3.2-16.8c-2.2-1.6-5.1-1.3-7.4-3.1c-1.9-1.5-3.7-3.9-4-6.3c-0.5-4,2-9.2,3.3-12.9c0.6-1.8,1.2-4,2.5-5.5
								c0.8-0.9,5.5-2.6,5.4-3c0,0.1-9.2-3.2-10.3-3.3c-4.5-0.6-8.5,1.9-12.7-0.6c-5.7-3.4-9.2-10.4-8.4-17c1-7.8,1.6-16.1,3.5-23.8
								c2.1-8.7-2-14.7-4.5-22.7c-1.6-5.1,0.2-10.8,1-16c0.5-3.7,1.2-7.6,1.3-11.4c0.1-7.1-0.7-14.2-0.7-21.3c0-9.2,3.7-15.5,10.4-21.7
								c7-6.5,15.8-10,15.4-21.2c-0.1-4.1-2.1-7.8-4-11.3c-2.6-4.9-5.4-9.8-7.7-14.9c-1.7-3.8-3.2-8-3.4-12.2c-0.1-2.6,0.6-5.1,1.8-7.4
								c0.2-0.3,2.7-3.8,2.4-4.1c-0.1-0.1-13.9,8.5-15.2,9.1c-5.8,2.5-11.2,4.2-16.1,8.5c-6.2,5.4-11,12.2-16.3,18.5
								c-5.4,6.4-12.4,11-20.9,11.1c-12.4,0.1-25.8-1.8-34.9-10.7c-4.5-4.4-7.6-9.8-11.4-14.7c-3.5-4.6-8.4-5.6-13.8-6.5
								c-4-0.7-8.7-0.4-12.6-1.6c-5.5-1.7-9.2-7.7-10.6-12.9c-1.2-4.3-0.2-7.4,0.6-11.7c1.2-6.2,2.2-13.6,0.5-19.8
								c-1.6-5.7-5.9-10.7-8.6-15.9c-3.4-6.8-5.2-14-4.2-21.6c0.7-5.5,2.6-10.7,5.1-15.7c1.6-3.1,2.9-6.5,4.8-9.5c1.8-2.8,4.3-4.7,3.1-8.2
								c-1.1-3.3-3.5-6-4.2-9.6c-0.9-4.1,0.6-7.4,2.1-11.1c0.3-0.8,4.8-14.8,4.8-14.8c1.4,0.4-4.4,17.8-4.9,19.1
								c-2.8,7.4-7.1,12-12.5,17.5c-2.3,2.3-1.9,4.5-1.8,7.5c0.1,3.1-1.1,5.6-3.1,7.9c-4,4.7-9.6,8.6-15.2,11c-9.2,4-19.3,4.6-29.1,3.1
								c-4.9-0.8-9.9-2-14.1-4.6c-4.9-3-7.4-7.5-10.1-12.4c-2.8-5.1-5.5-11-10.1-14.8c-4.1-3.5-6.5-7.1-5-12.8c1.4-5,8.2-8.5,8.1-13.6
								c-0.1-4.6-3.2-9.2-4.1-13.7c-1.9-10,2.2-22.3,5.6-31.7c1.3-3.5,16-34.8,15.2-35.1c1.4,0.6-6.9,10.8-7.6,11.3
								c-4.1,3.2-9.6,4.8-14.3,6.9c-7.4,3.4-13.7,6.2-22,7c-6.9,0.7-14.8,1-21.4-1.2c-6.1-2.1-10.8-7.2-16.4-10.4
								c-5.7-3.2-11.5,0-16.9-3.6c-5-3.4-12-8.7-12.6-15.2c-0.3-3.1,0.6-5.5-1.1-8.4c-1.6-2.6-4.4-4.5-7.2-5.6c-3.4-1.4-6.9-1.4-10.5-1.4
								c-4.5,0-9-0.4-13.5-0.7c-7-0.6-14.1-0.7-21-1.6c-2.9-0.4-4.6-1.1-6.9-2.7c-2.4-1.6-5.7-4.2-8.5-4.9c-3.5-0.9-5.4,2.8-9.1,1.7
								c-1.6-0.5-2.5-1.5-3.4-2.8c-0.5-0.8-0.5-2.1-1.6-2.5c-1.7-0.6-4.8,2.8-6.8,3.2c-6.6,1.3-15.1-1.7-21.4-3.5
								c-5.3-1.5-10.5-3.3-15.5-5.5c-7.7-3.5-14.1-9.2-22.5-11.1c-10.8-2.4-22.2-0.1-29.6-9.9c-8.8-11.6-8.8-25.9-7.8-39.7
								c0.4-6.1,0.6-11.5-3.2-16.6c-3.4-4.6-7.6-8.8-10.7-13.6c-2.1-3.3-2.5-7.7-2.5-11.5c0-2,0.7-4.3-1.7-4.7c-1.8-0.3-4.2,1.1-6,1.7
								c-5.7,1.8-11.6,3.7-17.7,3.4c-3.4-0.2-7.3-0.9-10.4-2.4c-2.4-1.2-4.1-4.8-7-4.6c-1.4,0.1-2.5,1.2-3.8,1.7c-2.3,1-4.9,1.8-7.3,2.4
								c-6.1,1.7-13,1.1-19-0.9c-10.2-3.4-17.9-11.2-21.8-21.1c-1.2-3.1-2.1-6.4-2.2-9.7c-0.1-3.5,1.4-6.8,1.1-10.2
								c-0.3-3.1-1.9-6-4.3-7.9c-2.4-1.8-5.3-1.9-7.9-3.2c-6.6-3.3-9.8-10.5-7-17.4c2-4.9,5.5-7.8,8.8-11.7c4.8-5.5,9-10.8,9.3-18.4
								c0.2-5.8-1.8-9.6-4.5-14.5c-3.1-5.5-2.8-12.9-1.5-18.9c1.2-5.2,3-8-0.2-12.6c-3.4-4.9-7.7-10.5-2.8-15.8c4.7-5.1,10-9.8,13-16.3
								c4.9-10.5,0.9-22,6.4-32.4c1.8-3.4,2.5-4.9-1.9-5.8c-0.9-0.2-1.8-0.3-2.8-0.5c-4.1-1-8.4-2.3-12.3-4c-4.6-2-9-5.1-11.9-9.2
								c-3-4.2-2.7-10.8-8-12.9c-5.4-2.1-10.6-4-15.6-7.1c-1.8-1.2-3.5-2.7-5.5-3.5c-3.1-1.3-6.4-0.8-9.7-0.7
								c-11.6,0.5-22.3-2.2-30.8-10.2c-4.7-4.4-9.6-9.3-9.4-16.1c0.1-4.7,2.7-14.6-4.2-14.4c-2.9,0.1-5.2,0.7-8-0.5
								c-3.1-1.2-5.7-3.4-8-5.7c-3.6-3.5-7-7.4-9.2-12c-5.2-10.4-6.6-23-6.7-34.5c-0.1-14.3,0.8-28.6,0.9-42.9c0.1-15.5-0.5-30.9,0.8-46.3
								c0.6-6.7,0.4-13.6-1-20.2c-1.2-5.7-2.3-11.2-1.8-17.1c0.4-6,1-11.8,0.2-17.8c-0.5-3.8-1.4-7.2-1-11.1c1.1-11.6,8.7-27.4,20.8-30.8
								c7.1-2,15.1,0,21.8,2.6c5.5,2.1,9.3,4.3,15.4,2.4c12.5-3.9,18.6-17.3,26.2-26.8c7.7-9.7,17.1-17.2,28.2-22.5
								c6.9-3.3,13.5-5.3,21-6.3c6.9-0.9,9.4-4,13.4-9.1c2.9-3.7,6.7-8.2,11.5-9.5c4.9-1.3,10.4-1.1,15.4-1.7c5-0.6,8.4-1.4,12.2-4.7
								c2.4-2.1,4.8-3.5,7.9-4.3c0.3-0.1,5.9-1.1,6-0.5c0.1,0.7-5.8,1.4-6.3,1.4c-4.2,0.3-7.8-0.6-11.4-2.6c-5.6-3.2-10.7-1.6-16.8-1.5
								c-6.4,0.1-13.4-0.6-19.4-3c-7.8-3.1-12.5-9.5-15-17.4c-2.2-6.9-2.4-14.3-6.4-20.5c-3.6-5.8-6.9-10.1-6.2-17.5
								c1-10.9,7.3-17.9,13.3-26.1c4.7-6.4,9.3-13.2,12.3-20.7c1.4-3.6,2.7-6.5,5.5-9.2c5-4.9,10.9-7.1,17.8-7.1c5.2,0,8.6-2.5,12.3-5.7
								c5.7-4.8,11.6-9.9,18.1-13.6c5.6-3.2,11.9-2.8,17-7.3c5.4-4.8,8.8-10.3,15.7-13.2c4-1.7,7.7-3.6,11.3-6
								c13.1-8.6,25.9-17.6,39.3-25.9c8.7-5.4,17.5-11,27.7-13c13.4-2.6,27.4-2.9,40.7-6.1c5-1.2,8.6-4.8,13.2-6.8
								c6.2-2.7,13.4-1.1,20-1.4c6.1-0.3,9.5-2.5,14-6.4c4.2-3.7,9-5.9,14.2-7.9c6.1-2.5,13.3-5.2,17.8-10.3c1.7-2,2.8-4.1,5.2-5.3
								c3.2-1.6,7.4-1.8,10.8-0.7c2.6,0.8,4.4,3.1,7.2,3.5c4.2,0.6,7.8-2.4,11.2-4.3c4-2.2,8.4-2.8,12.7-4.4c7.2-2.6,14.3-6.1,20.7-10.2
								c6.7-4.4,11.7-9.8,16.9-15.7c4.4-5,11.8-6.6,18.3-6.8c5.8-0.2,10.6,1.4,16.2,2.6c11.1,2.4,23.4-2,34.3-3.6c5-0.7,11.1-1.8,15.5,1.3
								c5.9,4.1,5.4,9,7,15.1c2.3,8.6,13,16.2,10.7,25.9c-1.2,4.9-3.7,9.8-5.8,14.4c-2.3,5.2-4,11-8.9,14.2c-4,2.6-3.3,4.3-3.7,8.5
								c-0.3,3.3-2.8,5.5-5,7.8c-2.1,2.1-4.3,4.9-7.4,5.6c-3.2,0.8-6-0.5-9,1.4c-1.9,1.1-5.5,4.2-4.8,6.8c0.7,2.6,5.6,4.3,7.7,5.4
								c4.7,2.4,9.7,4.2,13.5,8c5.3,5.4-0.4,11.8,3.4,17.9c3.6,5.7,11.1,6.2,16.9,4.2c8.3-3,16.3-6.7,24.6-9.7c7.4-2.7,15.5-3.6,23.4-3.4
								c14.3,0.4,27.6,6,41.5,8.9c3.9,0.8,8.3,2.4,12.3,1.4c3.1-0.8,4.5-3.2,6.9-5c3.3-2.5,13.6-5.1,10-10.5c-1.6-2.4-4.1-3.5-6.7-4.3
								c-3.8-1.2-3.8-3-5.1-6.2c-1.1-2.7-3.6-5.9-6.8-5.4c-2.9,0.5-5.6,3.4-8.5,4.4c-13,4.5-28.3,5.7-37.6-6.3c-3.3-4.3-5.6-9.6-6.3-14.9
								c-0.9-6.8,2.1-12.4,3-18.9c1-7.2,0.4-13.4,3.8-20.2c2.6-5.3,7-11.5,12.2-14.4c6.6-3.7,15.5-1.6,21.3,2.6c2.8,2,4.8,5.1,8.7,4.6
								c4-0.5,7.2-3.6,10.2-5.9c5.5-4.1,10.4,0.8,15.8,2.2c3.9,1,8.1,0.5,12.1,0.4c7.6-0.1,15,0.3,22.5,1.3c13.4,1.7,26.9,3.8,40.4,4.9
								c7.5,0.6,10.9-0.8,17.6-4.7c4.5-2.6,8.7-5.7,13.1-8.5c6.4-4,12.6-0.5,17.1,4.5c6.5,7.2,10.4,24.9,23.5,22.3
								c4.4-0.9,2.7-3.5,3.8-6.7c1.1-3,4.1-3.9,1.4-7.3c-1.7-2.1-4.2-3-6.7-3.5c-4.2-0.8-6-2.7-8.8-5.8c-6.5-7.5-18-10-23.8-18.2
								c-1.1-1.6-1.2-3-1.4-4.9c-0.2-3.9-1.9-6-5.1-8.2c-5.7-3.8-8.6-8.6-9.7-15.5"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M737.8,108.9
								c-0.5-0.2-1.2,2.1-3.9,4.4c-2.3,1.9-3.3,1.5-4.6,3.1c-1.5,1.7-0.7,2.7-2,6.5c-0.4,1.1-1.5,4.5-4.1,7.8c-2.2,2.7-3.6,3.2-3.6,4.9
								c0,1.8,1.4,3.6,3.1,4.2c1.7,0.7,2.5-0.3,7.4-1.4c4.3-1,5.8-0.7,7.1-2.2c1-1.2,1.2-2.7,1.2-3.1c0.3-2.5-1.2-3.6-2.1-6.1
								c-0.7-2-0.3-4.7,0.5-10.1C737.5,111.9,738.3,109.1,737.8,108.9z"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="2"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M900.5,26
								c1.3,2.2,3.4,5.4,6.5,8.8c4.1,4.4,6.8,5.6,7,8.5c0.2,3-2.5,5.9-5,7.2c-4.6,2.5-9.9,0.6-13.2-0.5c-0.2-0.1-12.9-1.9-38.2-5.5
								c-19.2-2.7-13.6-1-21.2-2.5c-8.1-1.6-12.9-3.2-18.2-0.8c-3.5,1.6-3.9,3.4-8,4.5c-4,1.1-7.5,0.5-11.8-0.2c-4.5-0.8-4.9-1.5-10.5-2.8
								c-4.9-1.1-7.9-1.8-11.2-1.8c-7.3,0.1-12.7,3.4-15.2,5c-8.2,5.1-11.9,12.4-13.2,15c-5.4,10.8-4.3,21-5.5,21c-0.8,0-0.4-5.1-3-12.5
								c-1.5-4.4-3.7-8.3-6-12.5c-3.3-6-4.6-7.2-4.8-10.2c-0.2-4,1.8-5.1,1.8-8.8c0-2.6-1-6.3-5.8-10.8"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="2"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M578.3,27
								c-1.8,2-4.5,4.7-8.5,7c-1.4,0.8-5.6,3.1-14.7,4.8c-9.8,1.8-9.9,0-18.5,1.8C530.4,42,525.4,43,521,47c-2.6,2.3-2.9,3.7-6,5.7
								c-4,2.5-5.6,1.4-10.5,3.7c-3,1.3-5.3,3.3-10,7.2c-6.3,5.2-5.1,5.3-7.8,7c-5,3.2-10.2,3.8-17.5,4.7c-5.9,0.7-7.5,0.2-11.3,1.7
								c-3.9,1.5-5.4,3.2-8.2,4.8c-6,3.6-11.4,3.2-18.3,3.7c-2.9,0.2-10.5,1.8-25.8,5c-15,3.1-25.8,8.8-41.2,16.8
								c-29.1,15.2-42.9,29-59,37.8c-2.5,1.4-6.9,3.6-12,8c-4.1,3.5-4.7,5.1-8.2,7.7c-4.2,3.2-7.7,4.2-13.8,6.3c-4.9,1.7-14.7,5-22.2,8.3
								c-8.3,3.7-13.8,6.1-19.7,11.3c-8.3,7.4-13.3,16.8-15,19.8c-2.4,4.5-2.9,6.5-6,11.2c-3.6,5.5-5.7,7-9,11.7c-2.1,3-4.8,7.6-7,14"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M186,251.2
								c-0.5-1.9-2-1.7-3.5-4c-1.4-2.1-0.7-2.9-2.2-7.5c-0.9-2.6-1.3-4-2.5-5c-1.5-1.3-4-2.2-5.8-1.3c-1.7,0.8-1.3,2.4-3.2,3.5
								c-1.4,0.8-3.4,1.1-5,0.3c-2-1-1.8-3.2-3.2-3.3c-1-0.1-1.9,1-3.5,3c-1.3,1.7-2,2.5-1.8,3.3c0.3,1.8,3.1,1.6,3.8,3.7
								c0.5,1.4-0.3,2.9-1,4.2c-1.2,2.2-2.2,2.3-2.5,3.8c-0.3,2,1.2,3.7,1.3,3.8c1.9,2.1,5.1,1.8,6.3,1.7c2-0.2,1.9-0.8,4.2-1.2
								c3.3-0.5,6.2,0.3,7.2,0.7c2.8,1,3,2,4.8,2c2.6,0,4.5-2.1,4.8-2.5C184.6,256,186.6,253.6,186,251.2z"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M919.5,27.2
								c1.4,3.2,3.2,7,5.8,11c3.9,5.9,6.7,6,8.7,10.8c0.8,2.1,1.4,4.4,3.7,6.3c1.4,1.2,2.8,1.8,2.7,2.5c-0.1,0.7-1.7,0.6-3.8,1.7
								c-1.3,0.7-1.5,1.2-3.8,3.5c-0.5,0.5-2.5,2.5-4.3,4c-2,1.7-3.7,3.1-5.7,3.8c-3.2,1.2-5.6,0.2-9.7-0.5c-3.8-0.6-4-0.1-13.2-0.2
								c-9,0-16-0.6-19.8-0.8c-5-0.4-14.2-1.1-25.8-3.2c-13.4-2.4-20.1-3.6-21.5-4c-0.7-0.2-9.4-2.5-15.3-0.5c-1.3,0.4-3.2,1.1-5,2.7
								c-1.9,1.7-1.9,2.8-3.2,3.5c-2.2,1.1-4.9-1-8.3-2.7c-5.3-2.7-10.8-3.7-14.3-3.8c-6.7-0.2-11.9,2.9-14,4.2c-2.7,1.6-8.9,5.3-11.2,12
								c-0.9,2.6-0.8,4.8-2.8,8.7c-1,1.8-1.7,2.6-2.5,4.8c-0.3,0.8-1.1,3.1-1.5,6.3c-0.5,4.4,0.5,5.2,0,8c-0.6,3.3-2.5,4.6-3.8,6.8
								c-3.4,5.6-1.2,12.5,2.7,24.7c2.4,7.6,3.7,11.5,6.8,13.2c0.8,0.4,3.8,1.9,8,4.3c2.2,1.3,2.9,1.8,2.8,2.2c-0.2,0.8-4.7-0.2-8.7-0.7
								c-3.2-0.4-12.8-1.5-22.7,0.8c-7.8,1.9-14.2,5.3-14.2,5.3c-2.1,1.2-4.6,2.7-8.7,4c-3.8,1.2-6.9,2.2-9,0.7c-1.5-1-1.9-3.2-2.8-7.5
								c-1-4.8-0.3-5.2-1.3-7.3c-1.8-3.9-5.6-5.8-6.5-6.3c-4-2.1-7.4-1.6-7.5-2.5c-0.1-0.9,3.2-1.1,6.8-3.3c3.7-2.3,5.7-5.3,7-7.2
								c1.3-1.9,4-5.9,4.5-11.3c0.3-3-0.2-4.6,1-6.8c1.2-2.3,3.1-3.2,4.5-4.2c3.4-2.4,4.8-6,7.7-13.3c2.9-7.4,4.5-11.6,4.3-17.3
								c-0.2-7.6-3.4-13.7-5.2-17c-3.2-6.1-5.7-7.6-6.5-13c-0.6-4.1,0.5-5.4-0.8-8.5c-1.7-3.9-5.3-5.8-8.5-7.5c-2.1-1.1-8.6-4.5-16.3-3
								c-3.2,0.6-2.6,1.3-7,2.5c-3.9,1-6.4,1-14.3,1.3c-9.5,0.4-8.1,0.5-10,0.5c-9.1-0.2-10.7-2.8-19.7-3.8c-4.9-0.6-11.4-1.4-18.3,1.3
								c-2.8,1.1-3.6,1.9-13.3,9.8c-8.8,7.1-13.3,10.8-16.8,13.2c-4.4,3-9.4,6.4-16.7,9.3c-5.5,2.2-9,3.7-14,4c-7,0.5-8.2-1.7-16.2-1
								c-3.7,0.3-7.7,0.7-11.8,3.2c-3.2,1.9-6.9,5.3-8,6.3c-2.8,2.6-3.7,3.9-6,4.7c-2.7,0.9-3.5-0.3-6.2,0.5c-2.5,0.7-3.6,2.2-6.7,5.3
								c-2.7,2.7-5.1,5.2-7.5,6.8c-6.5,4.5-14.4,4.5-15.8,4.5c-5,0-7.2-1.4-11.7,0c-2.5,0.8-5.1,2.3-5.5,2.5c-3,1.7-3.7,2.8-5.3,3.8
								c-3.2,2.1-5,0.9-12.7,1.7c-5.3,0.5-4.7,1.1-13.2,2.3c-5.2,0.7-5.7,0.5-9.5,1.2c0,0-5.8,0.9-12.5,3c-9.3,2.9-19.2,7.7-32.5,15.3
								c-11.2,6.4-8.3,5.5-19.7,12c-19.5,11.3-29.2,16.9-29.8,17.2c-5.8,2.6-11.1,4.5-13.3,9.5c-0.7,1.6-1.5,4.2-4,5.8
								c-1.8,1.1-2.9,0.8-5.8,1.8c-2.3,0.8-3.8,1.8-6.2,3.3c-3.7,2.5-5.6,4.4-8.5,6.8c-3.2,2.8-6.3,5.4-10.3,7.8c-2.3,1.4-7.8,4.6-15.3,6
								c-5.3,1-6.1,0.1-10,1.5c-4.6,1.7-7.6,4.5-9.7,6.5c-1,1-4,3.9-7,9c-3.6,6.2-2.4,7.5-5.3,13.2c-1.2,2.3-1.4,2.3-12,15.2
								c-4.9,6-6.2,7.6-7.7,10.3c-2.4,4.4-3.7,8.4-4.3,11.2"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M811.7,155.2
								c1.5,0.7,1.1,2.2,3.2,4.1c1.4,1.2,2.5,1.4,2.4,2c-0.1,0.7-1.5,0.7-3.8,2.1c-1.9,1.2-1.8,1.8-2.9,2.1c-1.5,0.3-2.6-0.7-4.8-1.9
								c-1.6-0.8-3-1.3-6-2.2c-5.7-1.7-10.5-1.3-10.5-1.4c0-0.1,4.8,0.9,10.2-0.9c1.8-0.6,5.3-2,6.8-2.6
								C809.5,155.2,810.5,154.7,811.7,155.2z"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M217.4,309.5
								c3.4,11,11.3,19.4,21.1,25.1c5.8,3.4,12.3,5,19,4.8c0.6,0,12.4-1,12.3-2.5c0,0-21,4-22.4,4.2c-5.9,1.1-4.2,4.1-6.9,8.3
								c-2.3,3.6-7.1,3-10.6,4.5c-4.4,2-8.3,3.7-13.1,4.5c-4.9,0.8-9.6,1.9-14.1,4c-8.1,3.8-15.4,9.5-21.6,16c-8.3,8.8-17,29-31.3,27.4
								c-3.8-0.4-7.5-2-11.3-2.8c-4.2-0.8-8.5-1.3-12.7-1.1c-8.4,0.4-15.9,4.1-21.9,9.9c-9.5,9-14.3,22.5-13.9,35.5c0.2,6,0.8,11.9,0.7,18
								c-0.2,8.6-0.7,16.4,2.5,24.6c2.9,7.3,0.2,14.4,0.2,21.9c0,6.4,0.4,12.7,0.3,19.1c-0.3,16.6-2.2,32.9-3.4,49.4
								c-1.1,15.4,0.4,31.7,4.8,46.5c2.5,8.4,5.7,19.7,12.1,26c3.5,3.5,9.1,5.4,13.8,7.2c4,1.5,7,2.6,8.1,7.3c1,4.3,1.2,8.3,3.1,12.4
								c2.8,6.1,7.2,12.1,12.6,16.3c6.7,5.1,17.1,9.9,25.7,9.6c3-0.1,5.8-0.6,8.7,0.5c3.5,1.3,6.6,3.2,10.2,4.2c6.6,2,12.9,3,16.9,9
								c2.4,3.6,4.8,7,7.7,10.1c3.5,3.8,7.1,5.2,11.8,7.1c2.8,1.2,5.3,3.1,6.9,5.7c2.9,4.7,1.4,9.8,0.3,14.9c-1.1,4.9-2.5,9.9-5.8,13.8
								c-4.1,5-9.2,9.5-12.5,15.1c-1.9,3.3-3.3,8.7-1,12.2c1.7,2.6,4.7,2.9,5.3,6.3c1.1,6.9-0.6,14.6-1,21.6c-0.3,5,3.4,8.8,5.4,13.1
								c3.4,7.2,1.5,14.2-2.7,20.6c-4.3,6.5-11.6,13-13.7,20.6c-2.5,8.9,4.9,17.3,10.8,22.9c1.9,1.8,4.4,3.3,5.8,5.5
								c2.1,3.2,1.6,7.5,2,11.1c1.6,15.8,9.9,31.1,24.7,38.1c6,2.8,12.8,4.3,19.4,4.4c3.7,0.1,7.9,0.2,11.5-1c1.6-0.6,3-1.6,4.6-2.1
								c3-0.8,5.1,0.6,7.8,1.9c8.7,4,16.3,2.5,25.4,1.2c6-0.9,6.7,5.2,10.1,8.9c2.3,2.5,4.9,4.8,6.9,7.5c2.7,3.7,1.6,7.1,1.2,11.2
								c-0.8,7.5-0.6,16.1,1.5,23.4c2.3,7.9,6.3,16.4,10.7,23.3c4.2,6.6,10.4,12.3,17.5,15.6c4.5,2.1,8.1,1.4,12.8,1.3
								c4.4-0.1,8.1,1.8,11.9,3.8c5.5,2.9,11.3,5.2,17,7.5c9.5,3.8,18.8,8.1,29.1,9.4c3.9,0.5,6.9,0.6,10.4-1.2c4.8-2.4,5.1,2.6,9.6,3.1
								c4,0.4,5.4-4,9.3-0.3c3,2.9,4.8,5.2,9,6.6c7.7,2.5,16.3,1.9,24.3,1.4c7.7-0.4,17.1-1.1,22.9,5.1c2.6,2.8,2.7,6.7,4.1,10
								c1.8,4.2,5.1,7.6,8.8,10.3c4.9,3.5,11.1,6.7,17.2,7.2c3.7,0.3,6.7-0.1,9.5,2.9c2.2,2.4,3.6,4.2,6.8,5.6c7.3,3.3,15.8,5.8,23.7,6.5
								c5.5,0.5,11.1,0.1,16.4-1.6c1.5-0.5,9.5-4.9,10.8-3.7c1.3,1.1-2.4,5.9-2.9,7.1c-1.9,4.2-3,8.8-3.1,13.3c-0.3,8.1,4.5,16.9,1.9,24.7
								c-1.7,5.2-5.5,10.1-6,15.7c-0.4,4.6,0.9,9.8,3.6,13.5c2.1,2.9,4.7,5.4,6.9,8.2c2.7,3.4,4.3,7,6.1,10.8c4.2,8.4,11.5,15.6,19.9,19.8
								c6.1,3.1,13.2,4.9,20,5.3c10,0.6,18.5-2.7,27.2-7.3c1.5-0.8,4.2-2.8,6-2c2,0.8,1.5,3.4,1.6,5.2c0.3,8,0.4,15.2,5.5,21.6
								c5.5,7,7,17,6.6,25.7c-0.3,9,0.9,18.1,4.7,26.3c4.2,8.9,11.5,13.9,21.3,15.2c3.4,0.5,6.8-0.1,10.1,1.1c2.6,1,4.4,2.9,6.3,4.8
								c11.5,11.4,23.8,28.5,41.4,28.8c8,0.2,17.2-1.7,24.6-4.8c7.3-3.1,11.1-8.2,15.7-14.1c2.8-3.5,5.7-7.2,9-10.2
								c2.7-2.5,6.2-3.3,9.1-5.5c1.1-0.8,2.9-4.6,4.1-4.8c1.6-0.3,1.1,2.5,1.2,3.6c0.5,3.9,2.4,6,4.6,9c5.4,7.1,9.3,13.7,3.4,22
								c-2.3,3.2-5.8,5.4-8.5,8.2c-3.1,3.2-6.1,6.8-7.8,11c-2.5,6.2-0.5,13.7-0.5,20.1c0,9.9-1.2,19.8-2.6,29.5
								c-1.6,10.5,1.9,19.9,3.6,30.2c1.1,6.8-0.8,13.7-1.4,20.5c-0.6,7.8-1.1,16.3,0.3,24.1c1.5,8.6,6.2,16.4,16,16.4
								c3.7,0,7.1-2.8,4.3,2.3c-2.3,4-4.5,8.3-3.9,13.1c0.4,3.5,2.5,6.6,5,9c3.4,3.1,7.3,4.1,8.9,8.9c1.3,4,0.7,7.5,1,11.5
								c0.3,3.8,3.9,5.9,4.3,9.4c0.4,3.4-3.2,6.9-4.9,9.5c-2.6,4.1-4.8,8.3-6.1,13c-2.6,9.7,0.9,18.1,7.6,25.2c5,5.3,5.3,11.3,5.8,18.4
								c0.5,7.6,0.7,14.8,0.1,22.3c-0.2,2.4-0.1,4.7,1.1,6.8c1,1.7,2.7,2.7,3.5,4.5c2,4.2-1,8.8-1.5,13c-1,8.9,2.1,18.5,4.4,27
								c1.4,5.4,3.2,10.1,2.3,15.7c-0.8,5.3-1.2,11-2.7,16.1c-2,7.1-10.3,8.5-14,14.3c-1.3,2-1.6,3.7-1.7,6c-0.3,4.6-1.2,9.1-2.3,13.6
								c-1.9,7.5-5.7,12.4-10.8,18.1c-6.3,7-12.9,14.1-17.8,22.1c-3.3,5.3-5.7,11.1-8.5,16.8c-2.7,5.5-5.2,13.1-10.2,17.1
								c-9.9,8-24.8,5.7-33.5,15.5c-4.6,5.1-10,7.8-16.3,10.5c-5.5,2.3-11.7,3.2-17,5.9c-6.8,3.3-12,9.6-15.2,16.3
								c-4,8.5-3.5,17.5-2.7,26.6c0.9,9.6,3.4,18.9,9.7,26.4c5.5,6.5,12.9,10.9,21,13.3c7.3,2.2,15.4,4,23,2.9c8.4-1.2,15-8.2,23.8-7
								c6.4,0.9,12.4,2.2,19,2.1c6.1-0.1,16.8,0.1,21.6-4.6c2.3-2.3,2.1-5.4,5.8-6.3c3.9-0.9,4.7-2,7.6-5c2.9-3.1,4.1-2.2,8.3-1.9
								c1.1,0.1,2-0.1,3.1-0.5c7-2.3,13.2-8,18.9-12.4c5.6-4.3,12.2-5.2,18.9-6.8c8.5-2.1,17.6-4.6,23.1-12c2.1-2.8,3.6-5.8,6.6-7.9
								c3.8-2.7,8.4-3,12.5-5c3.3-1.6,7-6.7,10.7-3.5c1.5,1.3,2.3,3.5,3.5,5.1c1.4,1.9,3.2,3.5,5.1,4.8c6.4,4.5,12.5,5.8,20.1,6.3
								c10.3,0.7,19.9-0.2,29.7,3.4c4,1.5,8,3,11.9,4.5c2.4,0.9,4.9,2.1,7.5,2.1c3.1,0,5.8-2.1,8.9-2c2.4,0.1,4,1.9,5.8,3.3
								c2.6,2,5.1,4.3,8.5,4.8c4.3,0.6,7.3,0.3,10.8,3.5c6.6,6.1,14.6,7.6,23.2,5.7c6.3-1.4,17-6.9,18.9-13.8c0.6-2.2-0.8-4.1-0.4-6.1
								c0.3-1.9,0.2-1.2,1.9-0.6c4.1,1.5,7.3,2.8,11.7,1c4.7-1.9,6.9-2.4,12-0.8c5.3,1.7,10.6,1.7,16.1,0.9c4.5-0.7,9.5-1.6,13.4-4.2
								c1.1-0.7,2.6-1.7,3-3.1c0.5-2.1-1.5-3.3-0.6-5.4c1.3-3,8-4.7,10.8-5.2c3.9-0.8,6.6-2.1,9.4-5c6.7-7,12.2-16.6,16.6-25.1
								c1.5-2.9,2.8-6,2.7-9.4c-0.1-4.1-0.8-7.9,1.9-11.5c2.6-3.4,5.8-2.7,9.6-3.2c4-0.5,7.9-2.4,10.9-5c2.6-2.3,3.8-6,7-7.5
								c3.4-1.6,7.6-1,11.2-0.6c3.2,0.4,6.9,1.1,10,0.3c2.1-0.5,3.2-2,4.9-3.1c2.6-1.8,5.5-2,8.6-1.6c3.3,0.4,6.3,2.2,9.5,2.4
								c4.4,0.3,8.9-1.7,12.5-4.1c2.2-1.5,3.7-3.9,6-5.2c3.7-2.1,7.1,1.4,10,3.2c6.3,3.9,13.8,6.5,21.3,6.6c4.5,0.1,8.8-0.9,12.4-3.7
								c3-2.5,5.2-6.3,9.7-5.5c3.2,0.6,5.5,1.6,8.9,1.5c4.3-0.2,7.6-1.6,11.1-3.8c3.4-2.1,7.2-3.6,11.2-4.1c4-0.5,7.5,0.8,11.4,1
								c4.9,0.2,9.4-1.7,13.6-4.1c3.4-1.9,6.3-4.8,10-6.1c8.8-3.1,16.4,1,24.2,5c2.1,1.1,4.2,2.4,6.5,3.1c1.8,0.6,3.8,1.1,5.7,1.4
								c1.9,0.4,4.4,0,6.2,0.6c5.3,1.7-3.5,12.9-4.5,16.6c-0.9,3.2,0.7,6.1,0.8,9.3c0.2,5.3-2.6,10-3.9,15c-2.1,8-1.6,16.8,0.1,24.9
								c1.7,7.8,3.1,17.2,7.1,24.2c2.9,5,9.4,4.1,13.5,8c5.9,5.7,9.5,14.2,11.6,22c0.8,2.8,1,5.8,1.8,8.6c1.1,3.8,3.5,6.3,6.4,8.8
								c6.4,5.6,4.5,12.2,6,19.7c1.5,7.4,4.2,14.4,10.3,19.1c3.3,2.6,7.2,4.4,11.3,5.1c4,0.6,8.2-0.7,12,1c6,2.6,9.9,9.8,12.3,15.5
								c6.4,15.2,14.6,33.5,33.8,33.8c2.3,0,6.7-0.9,7.9,1.9c1,2.3-0.7,7.1-0.9,9.5c-0.7,6.1-2.3,14.2,5.5,15.9"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="2"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M1613.3,2372.3
								c2.9-4.9,6.1-9.9,9.7-15c2.3-3.4,6.5-8.7,20-25.7c3.3-4.1,6.7-8.4,7.3-14.7c0.4-4.1-0.8-4.8,0-7.7c1.6-5.7,7.1-6.1,16-13
								c6.8-5.3,11.9-9.2,12.7-15.3c0.5-3.8-1-6.3,1-10.3c1.5-3,3.1-3.4,4-6.3c1-3.1,0-5.4-0.7-8.7c-0.6-3.2-1-7.9,0.3-14"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="2"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M1687.3,2232
								c0.9-7,0.3-12.4-0.3-16c-1.1-6.2-2.7-8-3.3-14c-0.1-1.2-0.7-7.1,1-14.3c2.2-9.5,5.7-10.4,8-20.3c1.1-4.8,1.9-8.3,1-12.7
								c-1.4-6.8-5.2-7.3-8-15.3c-1.7-5-1.8-9.4-2-14.3c-0.3-8.3,1.1-10.4-0.7-14.7c-0.4-1-2.8-6.4-8-8.3c-4.4-1.6-7.7,0.3-15.3,2.7
								c-9.4,2.9-17.6,4-22,4.3c-7.9,0.6-13.6-0.8-21.7-2.7c-12.4-2.9-21.6-5.1-28-12.7c-2.5-3-5.2-6.2-6.3-11c-0.7-2.8-0.5-4.8-2-8.3
								c-0.7-1.6-1.8-4.3-4-6.3c-3-2.8-6.3-2.8-9.3-3.7c-9.2-2.6-14.5-12.8-16.7-17c-4.6-8.8-2.5-13.2-7.7-17.3c-3.6-2.9-5.8-1.8-11.3-3.7
								c-12.8-4.4-19.5-16.9-21.3-20.3c-4.9-9-6.6-19.8-7-22.3c-1-5.9-0.9-9.7-4-14c-2.3-3.1-4.2-3.6-7.7-8.3c-2.9-3.9-4.3-7.2-4.7-8
								c-3-7-1.2-9.3-3.3-14c-2.3-5.3-5.9-5.3-12.3-11c-7.5-6.6-11.2-14-13-17.7c-5.3-10.6-6-20.2-6.3-25.3c-0.7-9.9-1-15.4,1.3-22.3
								c1.6-4.6,3.9-8.6,2-12c-1.2-2.2-3.7-3.3-7-4.7c-3.1-1.3-5.7-2.4-8.7-1.7c-3,0.7-2.7,2.5-6.3,4c-3.5,1.5-6.9,1.2-9.3,1
								c-4.3-0.4-4.7-1.5-8.3-1.3c-1.7,0.1-4.2,0.2-6.7,1.7c-3,1.9-2.4,3.8-5.3,5.7c-2.4,1.6-4.9,1.6-8,1.7c-2,0-5-0.1-8.7-1.3"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="2"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M1372.3,1860
								c-2.2,0.8-5.7,1.9-10,2c-1.7,0.1-4.7,0-14.3-2.7c-9.2-2.6-11-3.8-17.7-5c-4.7-0.8-7.1-1.2-10-0.7c-5.4,1-5.9,3.6-12,5.7
								c-3.2,1.1-2.7,0.2-13,1.7c-10,1.4-10.1,2.1-13.3,1.7c-3.9-0.6-5.8-1.9-9.3-1c-3.1,0.8-5.3,2.7-5.3,2.7c-2.4,2-2.5,3.6-4.3,4
								c-2,0.4-2.9-1.5-4.7-1c-2.1,0.6-2.9,3.8-3.3,5.3c-0.9,3.5-0.4,6-0.3,7.7c0.3,5-2.8,7.2-8,15.3c-7.9,12.5-5.1,14.4-10,18
								c-5.6,4.1-9.4,1.9-15.3,7.3c-2.6,2.4-2.1,3.1-6.3,7.7c-2.6,2.9-5.9,6.5-10,9c-4.9,3-9.2,3.4-17.7,4.3c-11.5,1.2-20.3,0.7-20.3,0.7
								c-5.4-0.3-7.7-0.9-10.7,0.7c-4.1,2.2-4.7,6-7.3,10c-4.7,7.2-12.5,9.9-18.7,12c-11.9,4.1-22.3,1.8-30.3,0
								c-11.2-2.6-10.3-4.9-26-10.3c-8.5-2.9-16.3-4.6-32-8c-11.4-2.5-17.1-3.7-22.3-4.3c-6.8-0.8-10.7-0.8-17.7-2.7
								c-5.9-1.6-11.7-3.7-11.7-3.7c-6-2.2-6.1-2.7-9-3.3c-2.3-0.5-8.4-1.8-14,1c-4.6,2.3-3.7,4.8-9.7,9.3c-2.7,2.1-5.7,3.4-11.7,6
								c-7.7,3.4-11.6,5-15.3,4c-2.9-0.8-3.3-2.2-5.7-2c-2.6,0.2-3.9,2.1-8.3,6.3c-3.8,3.6-5.7,5.4-7.3,6.3c-5.8,3.3-8.4,0.7-12.3,4
								c-3.3,2.7-2.9,5.7-6.3,7c-2.5,0.9-3.3-0.3-5.7,0.7c-3.2,1.3-3.6,4.1-6.3,7.3c-2.4,2.9-5,4.1-9,6c-1.7,0.8-6.9,3.1-19.3,3.7
								c-11.5,0.5-12-1.3-20-0.3c-7.5,0.9-9.4,2.8-17,4.7c-10.3,2.6-18.5,2.1-25.3,1.7c-13.3-0.8-31.7-1.9-46.3-15
								c-15.3-13.7-18.1-33.1-19.3-41.3c-1.1-7.3-0.5-16,0.7-33.3c0.5-7.2,1-11.7,3-17c0.6-1.6,3.7-9.7,11.7-17.7
								c6.6-6.7,16.9-13.5,27.7-17c3.1-1,6.7-1.9,10.3-5c4.1-3.5,4.1-6.2,8-9.3c3.7-2.9,6-2.3,12-4.3c0,0,9.9-3.4,17.3-10.7
								c5.2-5.1,4.1-7.4,10-18c7.2-12.8,16.9-23.7,21.3-28.7c4.1-4.6,7.6-8.1,10.3-14.3c0.8-1.9,1.9-4.8,4-10.7c2.6-7.1,2.7-8,4-10
								c3.2-4.9,5.7-4.4,8.7-8.3c4.6-6,3.8-14,3.3-18c-1.1-10.1-5.6-10.2-5.3-18.3c0.2-7.2,3.7-9.7,2-16c-1.1-4.1-2.7-3.5-4.3-8
								c-1.5-4.2-0.7-6.5,0-18c0.4-6.6,0.6-9.9,0.3-12c-0.7-5.9-2.7-8.3-6.3-16c-4.8-10.3-7.6-16.3-8-24c-0.3-5.8,0.8-10.5,2.3-17
								c2.5-10.7,4.8-11.8,4-17c-1.1-7.5-7.1-14-8.3-15.3c-3.5-3.6-5.4-3.9-6.3-7c-1.1-3.4,0.3-5.7-1.3-10.7c-0.6-1.8-1.3-3-1.7-3.7
								c-2.9-5-6.7-6.9-8.7-8.7c-2.3-2.1-3.5-5.3-2.7-22c0.8-16.2,2.1-19.5,2.3-31.7c0.1-8-0.4-14.1-0.7-18c-1.3-16-3.3-17.1-4.7-32
								c-0.3-3.7-1.7-19.4,1.3-30.7c1.8-6.8,4-11,4-11c1.9-3.7,3.8-6,3.4-6.4c-0.6-0.5-4.2,4.2-9.5,7.2c-1.9,1.1-6.3,3.3-21.4,3
								c-11.8-0.2-17.8-0.3-25.8-2.9c-2.2-0.7-12.8-4.2-22.7-12.3c-7.3-6-10.3-9.3-10.3-9.3c-3.7-4-4.8-6.1-8-7.7c-4.2-2.1-7.6-1.2-11-1.3
								c-7.7-0.3-13.2-6.1-20-13.3c-13.2-14-26.1-39-21-52.7c0.6-1.6,2.9-6.9,0.7-11.3c-0.2-0.4-1.7-3.3-4.7-4.3c-3.6-1.3-6.6,0.9-10.3,2
								c-4.5,1.3-8.4,0.2-16.3-2c-9.1-2.5-19-5.2-27.3-13.3c-8.9-8.7-8.7-16.5-18-25c-0.9-0.8-3.8-3.4-7.3-7.3c0,0-3.6-4-6.3-8
								c-3.6-5.2-12.3-17.7-11.7-33.3c0.3-6.5,0.5-11.9,3.7-17.7c1.7-3,3.8-5.5,3.3-9c-0.4-2.9-2.4-5.6-5-7c-3.4-1.8-5.6-0.2-11-0.7
								c-5.4-0.4-9.3-2.6-11.3-3.7c-7.1-3.8-8.9-8.5-14.7-8.7c-0.9,0-1,0.1-3.2,0.1c0,0-4.8,0.1-9.1-0.5c-7.7-1-18.8-12-25.6-21
								c-2-2.7-4.1-5.7-8.1-7.9c-3.1-1.7-5.9-2.1-10.4-2.8c-8.7-1.2-9.3,0.4-15.6-1c-5.4-1.2-8.7-1.9-11.6-4.8c-1.9-1.8-3.1-4-6-4.8
								c-0.8-0.2-2.8-0.7-4.6,0.2c-1.5,0.8-1.6,2-2.8,2c-0.7,0-1.6-0.7-3.2-2.2c-1.5-1.4-1.5-1.6-2-1.8c-1.5-0.5-2.9,0.9-4.9,1.6
								c-1.8,0.7-3.5,0.5-7,0c-7.9-1-11.8-1.6-12.1-1.6c-7.6-1.5-12.4-2.5-18.1-5.1c-6.8-3.1-7.6-5.2-15-7.8c-4-1.4-6.8-1.8-9.8-1.5
								c-4.1,0.4-6.1,2-8.3,2.7c-2.4,0.8-8.3,1.6-21-9c-4.9-4.1-23.4-19.5-31.7-45.7c-4.4-14.1-6.6-21.1-5-27.3c0.5-1.9,2.1-6.8-0.3-10
								c-2-2.7-5.7-3-6-3c-3.5-0.3-4.1,1.9-8.3,2.3c-0.7,0.1-5.1,0.5-8.7-2c-2.5-1.7-2.8-3.6-4.7-3.7c-1.8-0.1-2.9,1.6-4.7,3
								c-2.9,2.2-6,2.2-11,2.3c-21.7,0.4-34-7.7-34-7.7c-12.4-8.1-17.9-20.4-22.3-30.3c-5.4-12-8-19-8-19c-2.7-7.3-3.5-10.1-6.3-14
								c-4.7-6.4-7.6-5.5-11.3-10.7c-5.2-7.2-4.5-16.1-4-22.7c0.8-10.6,4.9-19.5,5.7-21c0.9-1.9,2.9-5.8,5-11.7c2.3-6.3,2.4-8.5,2-10.3
								c-0.7-3.7-2.7-3.6-4.3-8.3c-1.8-5.1-1.1-9.9-1-10.7c0.6-4.2,2-4.9,1.7-8c-0.2-2.1-1-3.1-3-6.7c-1.7-3.1-3.6-6.5-4.3-8.7
								c-0.7-2-1-4.5,5-19.7c2.2-5.7,4-11.5,6.7-17c2.2-4.5,3.5-6.5,2.7-9c-1.2-3.6-5.1-2.7-9.7-8c-2.8-3.2-2.8-5.3-5.7-6.7
								c-1.3-0.6-1.5-0.3-6-1c-4.5-0.7-6.8-1.1-8-2.3c-1.9-1.9-1-4-2.7-5c-2-1.2-5.2,1.1-7,2c-6.5,3.1-15.3-2.6-24.7-8.7
								c-2-1.3-21-13.8-26-27.3c-0.2-0.4-1.8-4.9-4.3-10.7c-1.9-4.4-2.9-6.6-4-8c-4.2-5.3-11-5.4-15-6.5c-15.4-4.3-20.7-33.8-22.5-43.5
								c-1.6-8.6-2.2-17.1,0.5-51.5c3.7-46.4,6.4-50.3,4.5-69c-2-20-5.6-20.1-6-38.5c-0.2-8.7,0.2-27.9,8.5-50c4.9-12.9,8.4-22.2,18-29.5
								c11-8.3,24.6-9.9,25.5-10c8.2-0.9,12.4,0.8,23.5-1.5c9.3-1.9,12.6-4.3,13.5-5c5.9-4.5,4.3-8.2,10-15c4.8-5.7,12.5-11.2,21-13.5
								c2.4-0.7,8.1-2,15-5.5c3-1.5,5-2.8,5-4.5c0-1.9-2.5-2.7-5.8-5.5c-3.8-3.3-5.5-7-7.2-10.5c-2.2-4.5-3.3-8.9-3.8-12.5"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3.5"
						strokeMiterlimit="10"
						d="M285.2,728.5c4.6,1.6,6.8,2.4,8.2,3.5
								c3.1,2.3,2.6,4.6,7,14.5c2,4.5,3,6.8,4.2,7.8c1,0.8,3.3,2.4,18,1.2c10.1-0.8,12.1-1.9,13.2-3.5c1.8-2.6,0.4-4.7,2.2-7.2
								c2.1-2.9,5.8-2.7,12-3.5c10-1.2,15.1-1.9,20.5-4.8c5.6-2.9,8.4-6.6,13.5-6c1.7,0.2,3.1,0.8,4.5,0c2.2-1.4,1.2-4.8,2.2-8.5
								c1.8-6.8,9.1-10.3,11-11.2c2.1-1,5.5-2.3,17-1.5c13.7,0.9,15.4,3.1,24.5,3.2c9.9,0.2,17.5-2.3,21.2-3.5c13.5-4.5,24.4-12.8,23.8-14
								c-0.4-0.8-5.5,2.1-14.8,4.8c-18.9,5.3-21.9,0-47.2,3.8c-9.9,1.5-15.7,3.2-21.2-0.2c-2.5-1.6-5.4-4.5-9.8-4.2
								c-2.7,0.1-3.9,1.3-6.2,2.2c-3.6,1.4-7.7,1.2-15.5-1.2c-16.8-5.3-27.2-8.6-36.5-18.2c-4.6-4.8-7.6-9.7-13.8-10.5
								c-1.2-0.1-1.7,0-10,0.8c-7.1,0.7-10.7,1-11,1c-5.3-0.3-12.8-2.6-18.8-7.8c-2.8-2.4-4.4-4.8-6-4.2c-1.5,0.5-1.9,3.4-1.8,5.5
								c0.3,3.8,3,6.4,3.8,7.2c6,6.4,6.6,17.1,7.2,28.5c0.5,8.8,0.8,15-3.2,18.8c-2,1.9-5,3-4.8,4.5C279.2,726.7,281.2,727.1,285.2,728.5z
								"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3.5"
						strokeMiterlimit="10"
						d="M301,724.2c1.5,1.5,3.6,3.8,5.2,7.2
								c1.8,3.8,0.9,4.7,2.8,7.8c0.8,1.4,2.5,4.2,5.8,5.5c5.2,2.1,10.4-1,10.8-1.2c3.4-2.1,2.9-4.1,7-7.8c0.6-0.5,3.5-3,7.5-4.5
								c4.6-1.7,6.1-0.5,14.5-1c8-0.5,12-0.8,14.8-3.5c2.2-2.3,2.1-4.5,5.5-6.8c2.2-1.5,3.1-1.1,5.2-2.8c2.8-2.1,5.3-5.6,4.5-7
								c-0.9-1.4-4.7,0.4-9.2,0.8c-4.7,0.4-8.1-1.1-18.8-6.8C337,693.9,327.2,688.7,325,686c-1.8-2.1-5.1-6.7-9-6.2
								c-2.4,0.2-2.7,2.1-6.2,3.2c-3.9,1.3-7.5,0.4-9.5,0c-4.9-1.1-7.1-3.3-8-2.5c-0.9,0.8,0.8,3.6,2.5,7.8c2.1,5.2,4.4,10.8,4,16.5
								c-0.6,7.5-5.5,10.2-3.5,14.2C296.2,721,297.4,720.4,301,724.2z"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3.5"
						strokeMiterlimit="10"
						d="M308.3,696.8c0.2,3.7,1,4.3,1.2,6.5
								c0.3,5.9-5.5,8.9-4,12.2c0.1,0.1,0.9,0.9,2.7,2.5c3.8,3.5,3.7,3.4,3.8,3.5c2.6,3.2,0.4,6.1,2.7,9.2c1.2,1.6,3.3,2.9,5.5,3
								c3.3,0.1,4.6-2.6,11-7c2.9-2,4.4-3,6.2-3.7c5.4-2,7.6,0.3,16-0.7c3.8-0.4,8.4-1,9.8-4c0.4-0.9,0.7-2.4,0-3.3
								c-1.1-1.5-4-0.8-6.3-0.8c-4.3,0-6.6-2.4-11.8-5.8c-14.6-9.5-23.7-8.2-27.3-15c-0.4-0.8-1.5-3-3.5-3.5c-1.8-0.4-3.9,0.5-5,2
								C308.8,692.3,308.2,693.4,308.3,696.8z"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeMiterlimit="10"
						d="M1679.5,2374c1.7-5.2,4.2-8.3,6.2-10.2
								c2.6-2.4,3.7-2.3,7.5-5.8c1.7-1.6,4.2-3.9,6.5-7.5c2.2-3.6,3.6-7.4,3.8-7.8c0.9-2.6,1.2-4.2,2.8-6.5c0.9-1.3,1.7-2.5,3.2-3.2
								c2.2-1.1,3.3,0,6.8-0.2c1-0.1,4.4-0.4,7.5-2.5c2.3-1.6,3.6-3.5,4.5-5c2-3.1,2.6-5.9,2.8-6.8c0.5-2.4,0-2.8,0.2-8.2
								c0.2-5.3,0.7-6.4,1-7c0.5-0.9,2.1-3.5,5-4.2c2.3-0.6,4.3,0.4,7.2,1.8c3.5,1.7,3.5,2.6,6,3.2c3.3,0.9,6.2,0,7-0.2c3-1,3-2.3,5.8-3
								c0.8-0.2,3.9-1,6.5,0.5c2.1,1.2,1.7,2.7,4.5,6c1.7,2.1,3.2,3.8,5.5,4.2c2.9,0.6,5.4-1,7-2c3.2-2.1,2.8-3.4,6.2-6.2
								c3.1-2.5,6.2-3.6,8-4.2c2.1-0.7,4.2-1.5,7-1.5c4.4-0.1,7.8,1.6,10.2,2.8c3.4,1.6,3.1,2.3,5.8,3.2c3,1.1,4,0.6,9.2,1.2
								c3.4,0.4,6,1,10.2,2c3.2,0.7,3.9,1,8.2,2c1.8,0.4,5,1.1,9,0.8c3-0.3,3.2-0.8,3.2-1c0.2-0.6-0.7-1.4-5.5-4.8c-3.8-2.6-5.7-4-7.8-5
								c-2.2-1.1-1.9-0.8-5.5-2.5c-2.5-1.2-2.9-1.5-8.2-4.2c-3.9-2-5-2.5-5.5-2.8c-3.1-1.4-4.6-2.1-6.2-2.5c-2.9-0.7-3.6-0.1-8.8-0.2
								c-3.8-0.1-5.7-0.1-8.2-0.8c-4.1-1-7-2.8-9.2-4.2c-4.3-2.7-3.9-3.6-7.2-5.2c-1.7-0.8-4.1-1.4-8.8-2.5c-4.3-1-6.5-1.5-8.8-1.8
								c-3.5-0.4-3.7,0.1-9.5-0.2c-3.5-0.2-5.2-0.3-7-0.8c-3.3-0.9-3.6-1.7-7-2.5c-3.3-0.7-3.8-0.1-5.8-1c-0.5-0.2-4-1.8-4.5-4.8
								c-0.3-1.6,0.5-2.4,2.2-6c0.4-0.9,2-4.3,3-7.2c1-3.2,1.2-5.7,1.5-9.5c0.1-0.9,0.3-4.5,0-9.5c-0.3-4.6-0.5-7.2-1.5-10.5
								c-0.9-2.9-1.3-2.5-3.8-8.2c-2.7-6.3-2.9-8.5-3-9.2c-0.2-1.7-0.4-4.4,0.8-7.5c0.4-1.1,1-2.2,5.2-7c4.3-4.9,5.2-5.5,7.2-8.2
								c2-2.7,3.1-4.2,4-6.5c1.3-3.4,1.3-6.2,1.2-10c0-3.4-0.1-7-1.5-11.2c-0.6-1.7-2.1-4.5-5.2-10c-3-5.3-3.5-5.9-4-7.8
								c-0.7-2.5-0.3-3.2-0.2-9.5c0-7-0.5-6.7-0.2-11.8c0.2-4.2,0.7-9.2,0.8-9.8c0.6-5.5,0.9-6.4,0.2-8c-1.2-2.7-2.9-2.2-6-6.2
								c-1.6-2.1-2.8-3.8-2.8-6c0.1-1.9,1.1-2.4,1.2-5c0.1-1.9-0.3-3.5-0.5-4.2c-0.9-3.1-2.1-3.6-2-5.2c0.1-2,2-3.4,3.2-4.2
								c2.5-1.8,4.6-2,7.2-2.8c2.4-0.7,4.7-2.1,9.2-5c4.2-2.6,7.2-4.5,10.5-7.8c1.4-1.4,3.9-3.9,6.2-7.8c2.8-4.7,4.1-9.2,4.8-12.8"
					/>
					<path
						d="M1880.4,1775.8c-0.6,3-2.8,5.4-3.6,8.4c-0.6,2.4-0.2,5-0.8,7.4c-1.2,4.7-6,6-7.7,10.1c-0.9,2.1-0.2,4-1.9,6.1
								c-1.4,1.7-3.5,2.7-5.2,4.1c-4.5,3.7-6.1,8-9.1,12.8c-2.9,4.6-8.4,6.5-10.4,11.9c-0.8,2.2-0.5,4.1-0.8,6.3c-0.4,2.4-1.5,4-2.3,6.3
								c-3.1,9.1-8.1,19-15.3,25.7c-3.9,3.6-8.9,4.1-13.2,7c-3.9,2.5-5.6,5.3-10.6,5.6c-5.7,0.4-8.6-2.6-13.4-4.7c-4-1.8-11.2-2-15.4-0.8
								c-4,1.2-6.6,4.2-11,5c-5.7,1-11.8,0.6-17.2,2.8c-2.1,0.8-3.8,2.2-5.8,3c-2.6,1.1-5.7,0.8-8.1,2.2c-4.3,2.5-5.2,8.4-7.5,12.3
								c-4.7,7.8-12.4,25.4-5.5,33.8c3.6,4.3,9.8,4.8,15,5.6c2.1,0.3,10.8-0.2,11.6,0.9c0.5,0.7-0.1,4.2-0.1,5
								c-12.4-0.3-31.8-0.8-33.9-16.7c-0.8-6.3,1.1-12.7,3.1-18.6c2.1-6,5.6-11.1,7.8-16.8c-3.8,0.1-7.2,2.3-10.6,3.9
								c-4.5,2.2-8.3,2.3-13.2,3.1c-10.5,1.8-18.1,12.1-18.1,22.6c-0.1,13.4-10.3,10-19.3,6c-2.9-1.3-5.8-3.4-8.3-1.8
								c-3.3,2.2-3.4,10.3-3.9,13.9c-0.8,5.7-3,8.9-8.2,11.1c-4.7,2-7.1,2.2-6.6,7.7c0.4,4.3,1.6,8.1,1.4,12.5c-0.3,6.2-1.9,9.6-4.7,14.7
								c-5.1,9.3-1.9,21.7-11.2,29.1c-9.3,7.4-19.7-5.9-22.8-13.4c-3.9-9.5-4.4-22.4-12.4-29.6c-7.5-6.8-18.3,0.8-26.4-4.6
								c-4.5-3-4.7-9.1-5-14c-0.3-5.4-1.3-7.5-5.2-11.5c-3.9-4-5.1-6.3-6.4-11.8c-0.7-2.9-1.8-5.2-3.2-7.9c-3.8-7.3-10.9-17.2-7.2-25.7
								c1.9-4.4,5.5-9.1,8.6-12.7c1.3-1.5,8.7-10,1.8-10.1c-1.3,0-4.3,2.6-5.4,3.2c-2,1-4.2,1.8-6.3,2.7c-4.6,1.9-9.8,4.3-14.9,3.2
								c-11.6-2.6-9.7-21.3-5.7-29.1c2.9-5.7,2-8.3,2.2-14.6c0.2-4.9,2.7-7.9,7.4-9.6c5.7-2.1,11.7-1.2,16.5,2.5c2.8,2.1,5.2,4.9,7.3,7.7
								c0.7,0.9,2.1,4,3,4.6c3.3,2,5-4.5,6.3-7.5c2.4-5.4,7.2-10.5,8.6-16.1c2.7-10.6,2.6-21.1,5.1-31.7c-3.9-1-7.9-0.2-11.8-0.8
								c-4.9-0.7-9.8-2.9-14.6-4.1c-9.3-2.4-17.3-1.4-25.9,2.6c-8,3.7-18.2,1.1-26.5-1.2c-8.3-2.3-12.7-9.8-20.7-11.6
								c-5.5-1.3-11.1-2.8-16.8-3.6c-11.1-1.5-15.9,6.5-24.4,11.7c-4.4,2.7-8.5,4.3-13.8,3.9c-4.8-0.4-8.1-2.2-13-0.7
								c-4.1,1.3-7.2,4.1-10.8,6.2c-4.8,2.9-9.4,2.7-14.9,2.9c-2.1,0.1-4.8,0-6.8,0.9c-1.8,0.9-2.6,2.8-4.7,3.7
								c-3.9,1.6-10.9,0.4-12.9-3.7c-1.4-3,1.2-12.2,2.8-15.3c1.8-3.5,7.2-5.9,8.4-9.7c-5.7,1.8-10.6,5.2-16,7.7c-2.7,1.3-5.6,1.7-8.4,2.7
								c-3.2,1.1-5.9,3.1-9,4.3c-5.7,2.1-7.8,7.7-13.7,9.9c-4.6,1.7-9.8,0.8-14.4,2.4c-6.7,2.2-11.3,5.5-18.8,5.8
								c-6.9,0.2-13.5-3.2-20-0.6c-4.6,1.9-8.8,2.2-12.4,5.4c-5.5,4.8-12.3,8.8-19.9,9.1c-5.7,0.3-9.6,0.1-12.6,5.6
								c-3.9,7.2-5.8,15.3-7,23.4c-0.9,6.4-1.7,12.2-5.8,17.6c-4.6,6.1-10.5,6.7-17,9.7c-2.2,1.1-5.5,2.5-7.2,4.3c-2,2.1-2.2,4.9-3.9,7.3
								c-2.3,3.2-4.9,3.8-8.7,3.8c-2.2,0-4.6,0-6.7-0.8c-1.4-0.5-3.2-2.3-4.5-2.6c-4.9-0.8-9.5,3.4-12.5-2.9c-2.5-5.2-2.8-12.2-7.3-16.6
								c-5.3-5.1-12.8-5.3-14.4-14.3c-0.9-5,0.6-11.2,0.8-16.2c0.2-8-0.4-16-0.8-24.1c-0.3-6.3,1.7-13.1,0-19.3c-6,6.2-2.4,20.4-2.2,28.2
								c0.2,10.5-0.2,21.1-0.7,31.6c-0.4,9.4-0.3,19,0.1,28.4c0.3,7.1,2,19.3-5.1,23.7c-4.2,2.7-7.6,0.5-10.8-2.3
								c-1.3-1.1-2.6-3.1-4.2-3.8c-2.3-1-4,0.4-5.8-2.2c-2.5-3.5-0.4-7.2-5.9-8.3c-4.5-0.9-10,1.1-14.3,2.2c-5,1.3-9.5,1.5-14.5-0.2
								c-5.7-2-10-7.8-15.4-9.6c-3.6-1.1-6.6,1.6-10.5,1.7c-3.2,0.1-4.5-1.8-7.2-2.2c-1.8-0.3-3.4,0.6-5.3,1.2c-10.1,3.6-16-0.8-21.7-9.2
								c-2.5-3.7-4.5-5.4-4.1-10.1c0-0.4,0.9-4.3,0.6-4.6c-0.8-1.1-4.4-0.2-5.4,0c-9.9,2.4-12.7,13.3-23.4,12.3c-9.9-0.9-18.8,6-24.9,13.6
								c-5.6,7-15.6,10.7-24.2,13.2c-4.5,1.3-9,1.7-13.6,2.3c-5.7,0.8-7.5,1.1-11.1,5.5c-3.2,4-6.9,8.5-12.3,9.2c-7,1-9.4,0.5-13.8,6.7
								c-2.6,3.6-5.2,2.4-8.1,4.2c-4.5,3-3.8,8.7-10.3,8.8c-4.3,0.1-8.1-1.9-12.3-1.5c-2.4,0.3-4.2,1-6.8,0.7c-3.3-0.4-6.1-1.5-9.6-1.2
								c-9.3,0.6-16.2,4.7-25.8,0.8c-10.4-4.2-13.2-19.3-8.1-28.6c2.1-3.9,5.6-7.1,9.7-9c5.6-2.6,10.5-0.5,16-1.8c4.5-1,8.1-5.1,11.7-7.8
								c4.8-3.6,9.9-3.7,15.3-5.7c9.6-3.5,15.3-11.3,22.3-18.4c7.8-8,12.3-17.3,18.8-26.2c5.2-7.1,12.2-12.6,17.8-19.3
								c5.3-6.4,11.6-14.1,14.5-21.8c3.3-8.9-4-22.6,6.7-27.5c4.6-2.1,7.6-1.1,7.7-7.3c0-2.4-1-4.2,0.8-6.5c1.7-2.2,5.1-3.1,7.1-5.1
								c6.4-6,3.2-15.3,2.8-23.1c-0.2-3.5,0.3-7-1-10.4c-1.6-4.2-3.7-7.6-4.4-12.1c-1.8-11.1-1.4-22.1-2-33.3c-0.2-4-1.3-5.7-3.1-9.2
								c-2.7-5.2-2.6-10.6-1-16.1c2.7-9.1,0.8-17.9,0.1-27.2c-0.3-4.2-0.4-8.6-1.7-12.6c-0.4-1.4-0.9-3.2-1.8-4.4
								c-1.9-2.3-3.9-1.3-6.2-2.6c-4.7-2.6-4.4-9.3-0.3-12.2c3.7-2.7,8.9-0.8,12.3-3.3c3.6-2.7,1.5-6.3,0-9.5c-2.4-5.2-5.5-9.9-7-15.5
								c-2.2-7.9-0.5-19-4.4-26.1c-2.2-4-6.8-4.3-7.2-9.6c-0.5-5.8,3.6-8.8,7.4-12.1c1.6-1.5,2.7-3.4,4.2-4.8c1.9-1.8,3.7-1.8,5.8-2.8
								c5.4-2.5,5.2-8.2,9.7-11.8c4.1-3.3,5.7-4.8,7.3-10.1c0.5-1.8,5.1-11.2,3.3-12.8c-2.2-2.1-13.4,2.3-15.7,3.1
								c-5.4,1.8-9.9,3.3-13.8,7.6c-3.2,3.7-5.5,8.6-10.1,10.8c-4.6,2.2-9.1-0.3-10.2-5.2c-3.8,3.1-9.5,6.4-12.1,0.2c-2-4.6,1-9.2,3-13.3
								c4.9-10-1.6-17.9-4.3-27.5c-1.3-4.7,0.4-9.2,1-13.8c0.7-5.6,0.6-11.3,0.7-16.9c0.3-9.7-3.5-20.7,2.5-29.1c4.9-6.8,13.2-9.5,19-15.2
								c2.6-2.6,4.2-5.9,5.4-9.4c2.3-6.7,0.4-10.7-2.3-16.7c-4.1-9-7.6-19.6-9.1-29.4c-1.5-9.9,1.1-19.1,4.6-28.2
								c4.9-12.9,6.1-26.4,10.6-39.3c3.5-10.2,11.2-20.9,9.1-32c-6.4,8.1-6.4,20-10.9,29.2c-4.2,8.6-10.3,14.2-17.2,20.6
								c-6.2,5.7-9.4,15.4-17.1,19.2c-8.1,4-16.8,6.7-24.8,10.8c-8.9,4.5-16.3,10.1-22.7,17.9c-5.5,6.7-11.6,12.8-20.1,15.5
								c-17.8,5.8-27.6-20.4-39.9-28.3c-6.1-3.9-16.8,0.7-22.1-5.1c-4.5-5,2.3-13.7,3.1-19.2c1.5-9.7-7.7-17.4-11.5-25.3
								c-3.8-7.9-8-19.2-3.9-27.7c3.6-7.3,14.8-11.6,15.3-20.2c0.5-7.9-8.5-13-8.5-20.6c0-4.5,2-9.5,3.3-13.7c1.1-3.6,4.9-8.5,5.1-12
								c0.3-3.3-4-6.4-5.9-9c-2.9-3.9-5.2-8.3-7.5-12.6c-2.2-4.1-4.5-8.1-5-12.8c-0.2-1.5,0.4-5.1-0.8-5.7c-1.4-0.6-3.1,1.2-3.8,2.2
								c-2.9,3.9-3.4,10.8-4.5,15.4c-1,4.2-2,8.4-3.1,12.5c-2.7,9.6-7.3,18.5-10.5,27.9c-2.6,7.4-4.4,19.2-15.4,16.3
								c2,10.5-4.1,18.3-13.8,22.1c-9.9,4-18.5-3.6-23.4-11.2c-4-6.2-16.7-13.1-5.6-19.5c9.2-5.3,0.5-11.9-2-19.3
								c-7.1-20.7,8-45.4,14.8-64.2c3.6-10,5.5-19.9,2.6-30.3c-1-3.8-3.2-7.1-3-11.1c0.3-5.4,4-9.8,6-14.6c1.9-4.7,2-9.6,3.4-14.4
								c1.8-6.3,4.5-12.3,7.5-18.2c2-4,4.4-7.7,5.6-12.1c1-3.4,2.7-11.7-0.2-14.7c-4.3,1.2-0.9,12.3-1.2,15.2c-0.6,6.3-7.8,10.8-10.5,16.5
								c-3.1,6.6-2,13.7-5.6,20.1c-1.6,2.9-3.3,4.9-3.9,8.2c-0.8,4.8-1,8-4.4,11.9c-4.5,5.3-10.2,9-14.3,14.7c-4.3,6.1-8.3,12.2-13.9,17.3
								c-6,5.4-13.6,8-21.5,8.5c-8.5,0.6-19.7,1.5-27.7-1.9c-3.5-1.5-6.6-4.4-8.9-7.5c-1.9-2.5-1.6-3.7-4.3-3.6c-3.6,0.1-8.8,5-10.3-1
								c-0.9-3.5,2-7.6,4.1-10.2c-6.3-5.5-12-11.8-18.6-17c-6.3-4.9-15-4.3-22.7-4.2c-4.5,0.1-7.9-0.4-12.1-1.5c-5.2-1.4-10.4,0.9-15.8,0
								c-3.7-0.6-8.6-1.9-11.7-4.1c-4.7-3.2-2.5-6.7-3.8-11.2c-3.7,3.1-7.1,7.9-12.6,4.9c-3.6-2-4.4-6.4-4.4-10.2
								c-3.5-0.8-6.3,4.6-8.3,6.5c-2.7,2.7-5.2,4-9,3.9c-7.1-0.1-14.3-3.2-21.2-4.8c-8.8-2-16.3-7-25-9.2c-8.3-2.1-17.1,0.3-23.9-6
								c-6.4-5.9-5-14.1-4.5-22.1c0.6-8.8,2-17.5-2.1-25.8c-4.9-10.1-15.8-14.8-10.4-27.1c1.4-3.1,3.5-4.9,2.5-8.3c-1.5-4.8-6.4-4.6-9.8-7
								c-3.9-2.8-1.6-5.3-6.3-5c-4.5,0.3-8.9,3.5-13.2,4.7c-9,2.5-24.4,5.3-24.8-8.6c-3.4,0.4-5.9,2.8-8.6,4.5c-3.6,2.3-7.4,3.3-11.6,4.1
								c-7.6,1.5-17.7,1.3-23.3-5.2c-5.4-6.3-4.2-17.2-3.6-24.8c0.6-7.8,0.7-12-7.1-16.1c-4.1-2.2-7.2-3.4-6.3-8.9
								c0.6-4.1,3.6-7.7,6.2-10.7c5.2-5.9,9.2-12.5,9-20.8c-0.1-7.2-4.9-12.2-6.7-19c-2.2-7.9-0.7-14.3,1.7-21.8c0.6-1.8,2.6-5.6,2.2-7.3
								c-0.5-2.3-2.1-2.1-3.6-4c-6.4-8,4.7-12.2,7.1-18.2c2.5-6.3,2.5-14.1,2.2-20.8c-0.4-8.4,1.9-13.7,11.2-13.6c6,0.1,11.6,3.1,14.9,8.2
								c4.4,6.9,0.5,13.8,7.7,19.5c11,8.7,30.4,2.1,43-0.2c4-0.7,9.7-0.2,13.4-1.8c4.4-1.9,2-3.1,1.7-5.8c-1.2-9,7.9-7.8,14.3-7.2
								c4.5,0.4,8.4-0.6,12.3-2.8c3.2-1.8,7-5.1,10.9-5.1c7.5,0,5.1,12,3.6,16.6c7.4-3.7,15.2-4.6,13.9-14.6c-1-8.1-4.5-16.2,3-22.4
								c7.5-6.3,17.4-2.2,25.5,0.4c8.5,2.7,17.3,6.2,26.3,6c10.1-0.2,13.6-6.8,20.4-12.5c3.5-3,7.4-5.4,11-8.4
								c17.3-14.2,33.6-30,48.7-46.4c3.3-3.6,6-7.4,8.9-11.3c5.1-6.8,17.8-14.1,12.9-24.5c-8,4.8-11.2,16.2-19.5,19.5
								c-1.1,0.4-2,0.1-3.1,0.5c-1.4,0.5-2.9,1.8-4.2,2.6c-1.7,1-3.4,2-5.1,3.1c-5.6,3.6-10.6,8-16.1,11.9c-21.2,15-44.6,20.6-70.2,22.5
								c-11.8,0.8-24.6,1.5-36.3-1c-2.7-0.6-5.1-1.5-7.2-3.3c-1.7-1.4-2.7-3.7-4.8-4.5c-5.1-1.8-8.2,4.7-12.6,5.9
								c-9.4,2.4-17.1-4.9-24.4-9.4c-9.8-6.1-19.8-11.8-29.2-18.4c-5-3.5-8.8-5.8-15.2-5c-4.8,0.6-8.8,4-13.8,4c-6.4-0.1-13-4.6-17.8-8.5
								c-4.2-3.4-10.2-9.6-16.2-8c-10.3,2.7-8.6,22.9-6.5,30.4c1.3,4.6,2.4,9.3,3.4,14c1.2,5.8,3.7,10.4,5.6,15.9c1.7,4.9,2.6,11.1-2,14.7
								c-3.8,3-10.9,3.4-15.5,2.3c-2.7-0.7-4.3-2.4-6.7-3.3c-2.7-1-4.3,0.1-7-0.3c-6-0.9-7.9-8-11.1-12.2c-6.6-8.6-17.2-17.5-27.3-21.6
								c-5.3-2.2-10.8-3.1-16.6-3.1c-6.1-0.1-13.1,1.7-18.8-0.8c-4.4-1.9-7.5-5.8-8.3-10.5c-1.1-6.4,2.9-9.9,4.2-15.5
								c3.3-14-13.3-10.5-21.1-12c-11.7-2.3-15.1-14.9-16.7-25.2c-1.9-12-3.3-24-3.5-36.2c-0.2-11.6,1.6-22.8,0.6-34.4
								c-0.5-5.7-0.5-11.6,0.1-17.4c0.7-6.2,3.2-12.2,3.5-18.4c0.3-5.7-0.8-11.2-2.7-16.5c-1.9-5.3-1.6-9.1-0.7-14.5
								c1-6,0.4-12.2-1.2-18.1c-1.4-5.3-3.4-9.5-1.7-15.1c3.2-10.4,13.6-14.5,23.4-10.5c10,4.1,15.7,10.6,26.9,5.4
								c9-4.2,18.8-12.7,25.6-19.9c6.6-7,9.1-16.7,16.1-23.8c8.7-8.8,20.2-12.7,32.3-14.4c4.6-0.6,8.9-0.5,12.5-3.7
								c4.1-3.6,6.7-8.9,10.7-12.6c5-4.5,9.6-0.6,15.3-1.4c5.2-0.8,10.1-5.5,14-8.7c8.4-7,19.1-6.4,27.4-14.1c10.3-9.5-1.6-20.8-12.5-20.8
								c-4.6,0-9,2.4-13.6,1.9c-4.9-0.5-9.8-3.4-14.3-5.3c-3.4-1.5-5.8-2.9-9.7-2.4c-6.2,0.8-11.9,2.2-18-0.7c-5.6-2.7-6.3-6.9-7.4-12.3
								c-1.3-5.9-4.4-10.6-6.3-16.1c-1.9-5.3-3.3-11.2-0.6-16.5c2.6-4.9,7.2-9,10.6-13.4c2.3-3,4.4-6.1,6.8-8.9c4.3-5.1,6.3-7.2,7-13.6
								c0.7-6.6,5-6.9,10.9-7.5c6.2-0.6,12.7-1.3,18.2-4.5c4.3-2.5,6.2-6.4,9.6-9.8c4.6-4.8,9.4-7.2,16.1-7.5c5.3-0.2,6.5-0.7,10.1-4.8
								c3.9-4.3,7-9.7,12.1-12.6c4.5-2.5,9.4-3.4,13.8-6.1c5.3-3.4,10.3-7.1,15.6-10.4c7.4-4.6,14.8-9.2,22.1-14
								c10.4-6.8,21.7-14.3,34.3-15.5c5.6-0.5,11.2,0,16.9-0.5c5.9-0.6,10.4-2.6,15.6-5.3c4.8-2.4,9.9-3.5,15.4-2.9
								c5.8,0.6,9.9,3.5,15.8,1.6c11-3.5,19.6-12.2,29.4-18.1c4.3-2.6,8.1-5.4,12.1-8.4c3.1-2.4,8-7.4,12.1-5.2c2.2,1.2,4,8,6.1,8.4
								c1.2,0.2,7.8-5.9,9.5-6.9c7.8-4.5,15.7-8.2,24.1-11.6c11.2-4.5,24.9-8.2,33.6-17.1c3.1-3.2,4.6-7.8,7.6-10.9
								c4.7-4.9,10.1-0.1,15.5,0.9c13.4,2.7,24.7-2.3,37.7-4c6.1-0.8,9.6,2.5,11.6,8.1c0.5,1.5,0.6,3.4,1.4,4.7c1.4,2.5,4.3,3.5,5.6,6.1
								c4.6,8.8-3,22.5-10.8,26.4c-2.3,1.1-4.2,1.5-6.1,3.5c-3.2,3.5-1,3.2-0.4,6.5c1,6.1-2.9,6.9-8.5,8c-5.2,1-11.1,0.7-16,2.9
								c-1.3,0.6-4.1,1.9-4.4,3.5c-0.2,1.2,2.5,3.9,2.8,5.7c1,6.4-4.2,7.4-7.6,11.1c-5.6,6.1,4.1,8.9,8.7,10.2c9.9,2.9,26.4,3.7,22.5,18.4
								c-1,3.6-4.7,7.4-0.4,10.1c1.6,0.9,3.7,0.6,5.4,1.6c2.4,1.4,3.2,3.9,4.8,6c7.4,9.9,22.5,8.7,32.6,3.8c11.1-5.4,21.5-9.3,33.7-11.5
								c11.9-2.2,24.1-2,35.7,1.5c8.7,2.6,25.3,15.5,34.7,10.4c4.3-2.3,5.5-8.9,9.9-11.9c4.3-2.9,9.2-4.3,13.5-7.6
								c4.7-3.6,5.8-6.7,5.9-12.3c0.1-5.4,3.2-11.6,2.1-16.8c-1.2-5.8-3.7-2.5-8-1.4c-6.3,1.5-11.6,0.3-14.9-5.4
								c-5.9-9.9-13.5-16.8-26.1-12.7c-7.5,2.5-21.5,14-26.8,1.2c-1.6-3.9-0.5-10.1-0.7-14.2c-0.3-6.6,1.5-10.6,5-16
								c3.2-5,6.4-8.2,12.9-6.9c5.6,1.1,7.9,4.9,12.2,7.9c3.2,2.2,8.1,3.1,11.3,0.4c3.8-3.1,4.6-6,10.1-5.8c5.4,0.2,11,2.2,16.3,3.2
								c5.4,1,10.3,0.7,15.7,0.5c12.1-0.4,23.9,3.4,36.1,3.1c4.1-0.1,8.2-0.8,12.3-0.8c5.5,0,10.6,2,16,2.3c6,0.3,8.3-3.7,13-6.5
								c6.7-4.1,11.6-2.4,16.5,3.3c3,3.5,4.6,8.4,7.7,11.7c4.4,4.7,13.3,5.2,19.3,4.2c4.5-0.7,8.9-2.6,12.7-4.9c1.9-1.1,4.1-2.3,4.8-4.6
								c0.8-2.5-1.2-5.3,0-7.8c2.2-4.4,11.9-0.3,11.6-5.5c-0.1-1.3-3.5-3.8-4.3-5.1c-1.2-1.9-1.9-4.1-2.7-6.2c-1.3-3.4-2.3-10-5.2-10.9
								c-1.3-0.4-4,1.1-5.8,0.7c-1.4-0.4-2.6-1.4-3.8-2.3c-3.8-2.9-6.8-6.8-10.6-9.5c-3.9-2.7-8.8-2.2-12.4-4.8c-3.6-2.6-5.7-7.7-9.1-10.9
								c-4.4-4.1-6.3-9.3-6.5-15.2c1,0,3.9-0.7,4.6-0.2c0.9,0.6,1.5,6.8,2.5,8.4c2.4,4.1,6.9,7.2,9.7,11.1c3.3,4.7,9.3,4.1,14.2,7.5
								c3.9,2.8,7.4,8.8,11.9,10.5c3.4,1.3,6.4-2.1,9.8,0.7c3.6,3,4.4,10.9,6.2,15.1c1.8,4.5,8.4,8.6,6.5,13.8c-1.8,4.9-7.4,5.5-11.8,5
								c0.6,6.8-0.6,10.7-6.7,14.2c-4.9,2.9-10.5,5.1-16.2,5.8c-6,0.7-13.6,0-18.9-3.2c-6.3-3.9-7.4-11.9-13-16.6
								c-5.3-4.5-9.1,0.5-13.5,3.9c-5.3,4.1-10.8,3.1-16.9,1.9c-11-2.1-22.1,0.2-33.3-0.9c-7.3-0.7-14.4-2.6-21.8-2.6
								c-6,0-11.7,0.8-17.7-0.2c-5.4-0.9-12.1-3.8-17.6-3.4c-4.9,0.4-6,5.8-11,7.1c-6.1,1.6-11.5-1.4-15.8-5.3c-3.1-2.9-8.1-6.3-12.3-3.5
								c-3.3,2.3-6.8,9.8-7.1,13.7c-0.2,2.3,0.3,4.9,0.4,7.2c0.1,2-0.7,6.7,0.1,8.6c2.6,5.5,9.3,1.8,12.7-0.2c5.5-3.3,12-5.7,18.6-5.2
								c5.5,0.4,10.9,3.1,14.9,6.8c3.4,3.2,5.1,10.4,8.9,12.5c5.7,3.1,10.8-3.8,16.1-2c6.4,2.2,5.4,11.3,4.3,16.2
								c-1.1,5.2-0.3,11.2-1.9,16.2c-2,6.3-9.1,11-14.7,13.9c-5.2,2.7-8.2,4.2-11.5,9.3c-4.6,7.2-10.4,7.9-18.6,5.9
								c-6.6-1.6-12-5.1-18.2-7.7c-6.1-2.6-12.6-4.2-19.2-4.9c-14.6-1.6-31.3,1.6-44.6,7.6c-12.1,5.4-22,11.2-35.7,7.3
								c-3.6-1-7.1-2.6-9.8-5.3c-2.2-2.2-3.1-5.6-5.6-7.4c-4.1-2.9-10.8-2.1-10-9.8c0.5-4.7,5.6-9,2.2-13.7c-3.8-5.2-17-5.3-22.6-7.4
								c-6.2-2.4-12.6-6.4-8.9-13.9c1.4-2.9,3.4-3.7,5.7-5.7c0.7-0.6,1.4-0.7,1.9-1.5c2-3-0.2-3.1-1-5.4c-5.3-15.1,19.4-13.2,28-16.3
								c-5.2-6.4,1.6-12.6,7-15.6c6.7-3.7,10.7-8.2,11-16.4c0.1-2.9-0.2-3.7-2.2-5.9c-2.7-3-3.7-4.8-5-8.6c-1.2-3.5-2.2-5.8-6-6
								c-5.4-0.2-12,3.1-17.3,4.1c-6.6,1.2-13.3,1.2-20,0.4c-3.5-0.4-6.4-2-9.8-2.8c-3.9-0.9-3.1-0.6-5.4,3c-9.4,15-26,20-41.7,26.4
								c-4.8,1.9-9.7,3.9-14.3,6.3c-3.1,1.6-6.2,3.4-9.3,5.1c-2.9,1.7-5.1,4.3-7.9,6c-3.5,2-5.5,1.5-7.9-1.3c-0.9-1-3.1-6.3-4.2-6.4
								c-0.8-0.1-4.4,3.2-5.1,3.7c-3.7,2.7-7.1,5.7-11.1,8.1c-11.5,6.9-23.7,19.6-37.4,21.3c-7.3,0.9-13.5-3.8-21.1-2
								c-7.6,1.7-13.5,6.9-21.6,7.7c-7.8,0.9-15.9-0.4-23.6,1.7c-8.2,2.2-15.5,6.6-22.6,11.2c-11.1,7.1-22.5,14-33.4,21.3
								c-5.8,3.8-11,7.5-17.5,10c-8.4,3.2-11.4,9.5-17.5,15.6c-4.8,4.9-8.1,3.7-14.1,4.3c-8.9,0.8-12.1,10.4-18.4,15.1
								c-6.3,4.7-14.1,6-21.7,6.7c-2.2,0.2-6.3-0.3-7.5,1.7c-0.4,0.6,0.2,3.9,0.2,4.7c-0.3,2.6-1.3,4.6-2.9,6.8
								c-3.5,4.9-7.7,9.2-11.3,14.1c-3.5,4.7-10.1,9.8-11.6,15.6c-1.8,6.8,3.5,14,5.9,20c2.4,6,1.3,14.4,8.4,16.9
								c7.1,2.5,13.7-1.9,20.7,0.1c5.8,1.7,11,5.9,17,7.1c5.6,1.1,11.5-2.3,17.4-1.6c7.3,0.8,14.3,5,17.2,12c3.2,7.9-0.2,13.9-6.3,19
								c-6.3,5.2-11.9,6-19.2,8.4c-5.9,1.9-10.7,8.1-16,11.3c-3,1.8-5.8,3.1-9.4,3c-3.1-0.1-6.6-2.2-9.5-0.7c-4.5,2.4-7.5,9.9-11.5,13.4
								c-5.9,5.1-14,4.3-21.2,5.8c-15.8,3.4-26.2,12.6-33.3,26.7c-5.8,11.6-19.5,22.2-30.5,28.6c-5.6,3.2-12.7,5.2-19,3.2
								c-5.5-1.8-10-5.9-15.5-7.7c-6.6-2.1-13,0.5-15.4,7.2c-2.1,6.1,1.6,12.2,2.6,18.2c0.7,4.1,1.1,8.4,0.6,12.5
								c-0.4,3.3-1.7,6.4-1.3,9.8c0.8,7.2,4.4,13.4,4.4,21.1c0,6.8-2.7,12.9-3.5,19.6c-0.9,7.7-0.2,15.2,0.4,22.8
								c0.6,7.3-0.5,14.3-0.9,21.6c-0.5,8.5,0.1,17,0.9,25.5c1,11.2,0.9,35.3,14.5,38.8c7,1.8,14.3-1.2,20.6,3.4
								c7.6,5.5,5.5,13.3,2.5,20.4c-2.5,6-2.3,13.2,5.3,15.2c7.9,2,16.9-0.9,25.1,0.8c14.7,2.9,30.2,14.8,38.8,27.1c1.6,2.3,3.1,7,5.6,8.4
								c2.8,1.5,6.5-0.3,9.8,1.3c4.1,1.9,15.9,7,18.3-0.6c1-3.2-1.4-7.6-2.6-10.4c-1.7-3.9-3-7.4-3.9-11.6c-1.5-7-4-14.1-5.1-21.2
								c-1.2-7.8,0.2-16.8,3.9-23.8c2.2-4.2,1.2-5.2-0.2-9.9c-1.3-4.2-2.7-6.9-5.5-10.2c-3.8-4.5-7.7-9.6-4.6-15.9c1.4-2.9,3.9-4.7,4.8-8
								c1.2-4.4-1.1-6.2-2.7-10c-3-6.8-1-10.8,3.6-15.7c3-3.1,4.9-4.8,6.1-8.9c0.5-1.7-0.1-3,0.9-4.8c1.1-1.8,3.1-2.2,4.4-3.6
								c2.4-2.5,2.8-5.4,3.1-8.8c0.8-8.5,0.2-17.1,0.3-25.7c0.1-4.8,0.6-9.3,2.2-13.8c2.7-8,9.1-12,13.8-18.6c4.3-6-0.5-13.2,0.1-20
								c0.7-8.2,4.8-15.4,11.6-20c5.6-3.7,12.2-4.5,17.9-7.8c3.2-1.9,6.1-5,7.4-8.6c1.3-3.5,0.2-7.1,1.3-10.7c1.9-6.1,9.7-14.2,16-11.7
								c3.4,1.3,5.6,3.6,9.7,2.6c0.7-0.2,6.3-3.7,6.4-3.6c2.4,2.3-7.3,6.6-8.7,6.6c-5.8,0-8.7-5.2-14.5-0.2c-7,5.9-3.1,15.3-7,22.5
								c-4.3,8.1-18.1,8.5-25.2,13.6c-8.2,5.9-10.4,16.8-9.2,26.3c0.5,4.4,1.4,7.5-0.4,11.8c-2.2,5.2-6.4,8.3-10.2,12.1
								c-6.8,7-5.5,19.2-4.7,28.1c0.8,9.8-0.3,19.9-5.3,28.6c-1.1,1.9-2.5,3-3.8,4.6c-1.5,1.9-1.3,3.8-2.3,5.7c-2.2,3.7-6.8,4.9-8.9,9
								c-2,3.8-1.3,8.4,0.8,12c1.5,2.6,3.3,3.6,2.9,6.9c-0.4,3.4-3.3,5.7-4.9,8.4c-3.2,5.8-0.1,7,2.6,11.8c2.7,4.8,3.5,9.4,7.2,13.8
								c0.9,1,2.5,3.7,3.5,4.4c1.3,0.9,5.3,0.9,7.2,1.6c5.7,2,9.3,5.7,14,9.2c4.4,3.3,10.5,7.5,16.3,5.8c4.9-1.5,8.5-3.7,13.9-3.6
								c6.2,0,10,2.6,14.9,6.1c5.5,3.9,11.3,7.4,17.1,10.9c7.4,4.6,14.8,10.3,22.5,14.3c3.9,2,8.8,3.2,12.5,0.4c4.2-3,6.7-6.4,12.7-4.4
								c4.2,1.4,5.8,5.8,9.9,7.2c4.3,1.5,9.5,1.5,14,1.8c26.3,1.5,55-1.8,78.4-14.5c11.1-6,20.3-14.7,31-21.2c0.7-0.5,1.5-0.9,2.3-1.3
								c1.1-0.6,2.1-1.3,3.2-2c-0.2-0.6-0.5-1.2-0.7-1.8c0.5-2.7,0.2-4.3,1.9-6.7c2.8-4,8.3-7.2,12.2-2.2c2.3-2.9,5.4-5.1,7.6-7.9
								c2.4-3,4.3-6.7,6.6-9.7c5.5-7.3,9.4-15.9,17-21.5c6.7-5,14.3-6.3,20.1-12.7c5.3-5.8,13.2-10.1,17.8-16.3c2.4-3.3,2.9-7.8,4.6-11.4
								c2-4.1,4.9-7.5,7.5-11.2c2.9-4.2,1.1-6.5,1.5-11.1c0.5-5.5,5.1-7.3,7.4-11.6c1-2,0.3-4.1,0.7-6.3c0.4-2.2,1.7-3.9,2.4-5.9
								c2.1-5.6,0.3-11.3-1.9-16.6c-1.9-4.7-2-5.5,0.4-9.7c1-1.8,2.6-4.3,2-6.5c-0.6-2.2-2.8-2.8-4.1-4.6c-5.6-8,0.1-13.2,0.3-21
								c0.1-3.8-3.2-5.6-4.4-8.9c-1.6-4.5-0.3-9.3-1-13.9c-0.6-4-3.7-5.7-6-8.8c-3-4.1-3.7-8.1-3-13c0.5-3.6,2.4-7.5,2.5-11.1
								c0.1-4-3.2-6.9-4.4-10.6c-2.1-6.5-2.4-15.3-11.2-14.6c-3,0.2-6.4,2.6-9.3,1c-1.6-0.8-2.9-3.5-4.3-4.7c-1.7-1.4-3.7-2.5-5.7-3.5
								c-3.3-1.7-6.2-3.5-10-2.8c-4.4,0.9-7.9,5.2-12.2,5.9c-5.1,0.9-5.4-3.7-7.5-6.4c-2.8-3.7-5.9,0.2-8.7,2.6c-1.6,1.3-3.1,2.6-5.3,2.6
								c-4,0.1-2.9-1.7-5.2-3.7c-3.6-3.2-7.8-0.1-11.9,0.1c-2.2,0.1-4-0.8-6-1.5c-3.1-1.1-1.7-1.3-4.5-0.3c-2,0.8-3,3-5.6,2.7
								c-2.6-0.4-2.6-3.3-5.9-0.8c-3.3,2.4-2.7,8.2-5.6,11.3c-2.7,3-6.9,4.9-9.9,7.7c-2.8,2.5-5.4,5.6-8.7,7.5c-2,1.2-4.4,2.2-6.8,2.2
								c-1,0-7.8-1.3-7.1-3.5c-0.1,0.2,11.7-2.1,12.8-2.7c4.7-2.5,8.4-7.3,12.7-10.5c4.1-3,5.5-7,7.5-11.5c0.9-2.1,1.5-3.6,3.5-4.9
								c3.6-2.3,6.3,0.8,9.7-0.3c2.4-0.7,1.4-2.9,3.2-4.1c1.9-1.2,4.7,0.6,6.2,1.5c1.6,1,2.4,2.3,4.2,2.9c3.3,1,6.3-2,9.2-1.6
								c4.5,0.6,5.7,6.4,11.3,3.9c5.3-2.4,8-10.5,14.4-4.6c1.5,1.4,2.1,4.6,3.7,5.5c2.6,1.4,7.7-1.7,10.1-2.6c6.9-2.4,8.6-0.3,15.1,2
								c3,1.1,5.6-0.9,8.1-0.4c2.4,0.5,1.6,2.6,2.6,4.8c1.4,3,0.7,2.6,3.7,2c3.9-0.7,4.9-1.9,8.5,0.3c2.2,1.3,2.9,3.4,5.8,0.9
								c1.3-1.2,2.6-5.3,3.5-7c1-1.9,2.6-3.7,2.5-6c-0.1-2.3-1.7-3.3-1.4-5.7c0.6-4.4,5.6-7.2,5.6-11.9c0-2.6-1.5-4.2-0.7-6.9
								c0.9-2.8,3.8-5.6,5.3-8.1c3.4-5.6,6.7-13.1,5.4-19.8c-0.5-2.9-2.2-4.7-3.6-7.2c-1.9-3.2-2.5-6.3-3.2-9.9c-1.1-5.5-5.1-8.6-6.2-14.1
								c-0.5-2.6-0.5-3.4-2.3-5.2c-2.4-2.4-6.6-2.6-8-6.3c-1.8-4.9,0.7-12.1-6.8-13.3c-3.2-0.5-5.8,1-9.2-0.1c-4.9-1.6-5.2-4.3-8-7.9
								c-4.1-5.3-8.8-1.2-13.7-0.2c-6.9,1.5-11.9-4.6-19-4.2c-5.3,0.3-10.1,5.6-15,5.1c-7.1-0.6-7.6-9.2-6.8-14.5c1-6.5,5.4-11.8,6.4-18
								c0.5-2.9-0.6-6-2.3-8.4c-2.1-2.9-6.3-3.4-8.1-6.2c-1-1.6-1.5-5.3,0.5-5.6c0.1,0,5.8,4.7,6.4,5.1c6.8,4.3,8.1,10.8,6.5,18.4
								c-1.2,5.6-4.1,10.9-4.6,16.6c-0.8,8.8,4.9,8,10.4,4.8c5.5-3.2,11.7-2.5,17.4,0.4c2.8,1.5,4.8,3.6,8,2.4c4.2-1.5,5.9-4,10.8-3
								c3.3,0.6,6,2.5,8,5.2c1.1,1.5,1.5,3.8,2.9,4.9c4.7,3.5,11.9-1.5,16.4,3.7c3.4,4,1.7,10.4,5.9,13.8c2.3,1.8,5,1,7.1,2.7
								c2.5,2,1.6,5.4,1.7,8.1c0.2,3.3,1.5,3.4,3,5.9c1.5,2.5,1.1,4.1,1.7,6.8c1.2,6,5.9,10.3,7.6,16c2.2,7.5-0.7,23.1-5.6,29.3
								c-1.2,1.6-3.4,2.3-4.5,3.8c-1.6,2.1-0.5,4.2-0.5,6.6c0,5.3-2.3,10.7-3.1,15.9c-0.9,5.6-2.6,7.8-5.6,12.5
								c-3.3,5.2-3.9,13.8-1.2,19.4c2.9,6,5,10.3,3.8,17.3c-1.3,7.5-2.2,10.3,4,16.3c3.8,3.7,3.9,4.9,4,10.2c0.1,3.3,0.4,6.8,1.5,10
								c0.9,2.4,3.1,4.1,3.9,6.3c1.1,3.4-2.2,7.7-2.1,11.5c0.1,2.9,0.6,5.3,0.1,8.2c-0.6,3.6-1,3,1,5.7c1.7,2.2,4.5,3.7,4.1,6.9
								c-0.3,2.3-2.9,4.7-3.5,7.1c-0.7,3.3,0,4,1.6,6.7c2.2,3.9,2.5,6.6,1.9,11.1c-0.3,1.9-1.9,4.6,0.3,5c1.8,0.3,6.6-5.1,7.7-6.3
								c3-3.4,3.4-8,7.4-10.3c4.2-2.5,9.8-2.8,14.2-5c2.7-1.4,4.7-3.5,7.2-5.2c2.9-1.9,6.7-2.3,9.3-4.8c4.2-4,4-8.7,10.4-10.2
								c7.1-1.8,11.5-4.1,17.3-8.7c4.2-3.3,8.8-1.4,13.7-3.2c6.4-2.4,8.8,0.2,15.4,0.6c3,0.1,4.3-0.1,6.5-2c9.4-8.2,22.9-14.1,24.2-12.9
								c2.4,2.2-15,8.3-21.7,15c-3.9,3.8-14.8,1.3-20,2.3c-6.8,1.4-14.6,4.3-20.5,8.1c-1.7,1.1-3.3,2.6-5.2,3.4c-2.3,1-4.8,0.7-7.2,1.7
								c-5.7,2.4-7.5,8.7-11.9,12.5c-3.2,2.8-8.1,4.6-11.8,6.7c-2.9,1.7-5.8,3.3-8.9,4.4c-2.3,0.8-5.1,0.8-7.2,2
								c-4.3,2.4-5.7,7.9-8.5,11.6c-1.8,2.3-3.8,2.9-6,4.4c-3.1,2.1-3.8,4-5.3,7.1c-2.8,5.5-7.8,7.8-8.8,14.2c-0.6,4-1.1,6.6-2.7,10.4
								c-2.2,5.2-4.8,10.3-6.8,15.6c-1,2.6-1,5.7-2.1,8.1c-1.5,3.1-3.8,2.3-6,4.2c-2.2,1.9-2.1,4.5-4,6.6c-1.7,2-4.7,3.4-6.7,5.3
								c-2.7,2.7-4.6,4.5-8,6.3c-11.7,6.1-22.5,16.9-30.4,27.4c-1.9,2.5-4.7,5.8-5.2,9c-0.4,2.8,0.9,5.3,0.8,8.2c0,7.1-3.9,12.6-8.8,17.4
								c15,2.8-5.1,26.6-10,11.9c-12.7,15.3-27.8,29.1-42.6,42.4c-8.5,7.6-18.7,14.1-26.5,22.4c-13.3,14.3-29.6,9.1-46,3.9
								c-6.1-1.9-15-6.4-21.2-2.6c-7.4,4.4-2.7,13.6-2,20.1c1.2,11-6.8,13.6-15,17.4c-3.4,1.6-8.3,4.5-9.5-1c-0.9-4.2,2-9.5,1.6-14
								c-5.9,1.9-10.2,6.4-16.5,7.5c-5.2,0.9-11.6-2-16.3,0.4c10.4,13.6-20.6,14.9-26.9,15.9c-13,2.1-27.9,6.9-39.3-2.5
								c-5.9-4.9-5.8-9.3-6.8-16c-0.9-5.7-5.9-9.8-11.5-10.2c-7.5-0.5-7.2,2.7-6.9,8.7c0.4,7.3,0,15.5-2.5,22.5c-1,2.8-2.3,4.6-4.5,6.6
								c-1.8,1.6-4.9,3.1-3.7,6.1c0.7,1.7,3.4,2.3,4.4,4c1.7,3.2,0,6.8-0.9,9.9c-1.9,5.9-4.3,11.2-3.4,17.6c1,6.9,5.5,11.7,7.1,18.2
								c1.9,7.7,0,16.1-4,22.9c-2.5,4.2-10.4,9.9-10.5,15c0,4,7.2,5.5,10.2,8.8c5.1,5.5,3.3,11.5,2.9,18.3c-0.4,6-1.4,15.2,3.4,19.7
								c5.7,5.4,16.3,3.8,23,1.6c5.2-1.7,9-6.6,14.6-7.4c8.7-1.2,3.9,8.3,10,10.6c10.1,3.7,28.2-13.4,36-5.2c1.8,1.8,1.7,3.4,4.1,4.9
								c3,1.9,5.8,2.5,8.1,5.4c5.5,7.1,0.6,10.5-1.5,17.2c-2.1,6.8,4.9,11.1,8.5,16.4c4.2,6.3,6.3,13.5,6.1,21c-0.2,9.7-6.2,26.7,5.2,32.1
								c5.7,2.7,13.5,1.2,19.7,2.9c6.5,1.8,12.1,5.1,18.3,7.3c5.5,2,11.5,3.1,17.1,4.6c7.1,1.9,10.6,2.9,15.5-2.5c4.3-4.7,13-11,15.2-0.9
								c0.5,2.5,0.1,6.3,4,5.5c2.3-0.5,4-4.7,6.3-5.5c8.6-3.2,5.1,9.3,8.9,12.3c6.3,5,14.4,1.9,21.3,2.1c3.2,0.1,5.6,2,8.6,2.4
								c3,0.4,6.3-0.2,9.4-0.2c6.3,0.1,14.2-0.3,19.8,3.1c5.6,3.4,10.2,9,15,13.5c2.2,2.1,7.6,5.2,8.3,8.3c0.9,3.6-2.6,5.8-3.7,9
								c6.4-2.2,8.6-2.9,12.7,2.9c2.6,3.7,5.5,6.6,10.1,7.6c10,2.2,23.8,2.5,33.5-1.4c9.2-3.8,14.8-12.4,20.3-20.3
								c5.2-7.6,16.7-13.6,18.4-22.8c0.9-5,1.9-7.9,4.4-12.3c2.4-4.3,2.2-8,3-12.6c1-5.2,3.6-10.3,6.8-14.4c3.7-4.8,6.1-5.7,5.7-12.1
								c-0.8-10.1,1.6-18.4,10.4-24.5c5.2-3.6,10.8-3.6,16.9-3.3c5.1,0.3,10.3-1.2,15.3-0.7c4.8,0.5,8.3,3.8,13.3,3.9
								c7,0.2,14.1-0.9,21.1-0.5c11.4,0.5,21.7,5.3,32.9,6.9c8,1.1,23.2-1.2,26.8-9.9c2-4.8-1.2-7.3-3.5-11.3c-2.6-4.4-2.3-6.7-0.3-11.5
								c1.8-4.4,2.9-6,1.4-10.5c-1.4-4.2-4.3-8.7-3.7-13.3c1.1-8.2,11.5-19.4,18.7-23c3.7-1.8,7.4-2.8,10.9-5.1c4.6-3.1,8.4-7.9,13.1-10.6
								c3.5-2.1,8.2-1.3,12-2.6c5.7-1.9,5.7-5.3,9.4-9.6c3.5-4.1,8.1-5.6,13.1-6.9c5.9-1.5,9.6-4.7,14.1-8.7c5.5-4.9,10.7-10.4,16.6-14.8
								c5.3-3.9,10.4-6.3,16.7-7.9c5.9-1.5,9.4-4.3,14-8.3c4.2-3.7,8.4-7,10.6-12.2c1.3-2.9,1-4.2,4.1-4.3c0.4,0,7.3,2.1,7.4,2.5
								c0.1,1.2-8.2,5.5-8.8,6.1c-5.2,4.9-8,11.2-14.3,15.2c-11,6.9-23,9.9-33,19c-4.2,3.8-7.7,8.8-12.4,12.1c-4.7,3.4-10.5,4.2-15.6,6.6
								c-8.3,3.9-11.5,15.2-20.7,16.8c-5.8,1-11.2-0.8-15.2,4c-4.8,5.6-8.3,8.5-15.1,11.2c-6.1,2.4-11.2,7.3-15.5,12.1
								c-4.2,4.7-1.1,10.2-1.2,15.9c0,3,0.1,4.7,0.7,7.6c0.9,4.1,0.5,5-1.4,8c-1.7,2.7-3.4,4.4-1.5,7.8c0.7,1.3,3.3,2.1,4.2,3.3
								c1.2,1.4,1.8,3.1,2.2,4.8c3,13.8-9.8,20.7-21.3,22.2c-12.1,1.6-24.6-1.8-36.5-3.8c-7.5-1.3-15.3-2.3-23-2.2
								c-3.4,0.1-6.8,1.5-10.1,1.3c-4.1-0.3-5.6-4-9.6-4.7c-4.7-0.9-12.1-0.3-17,0.1c-3.9,0.3-14,1-15.4,6.1c-0.7,2.5,1.5,6.4,1.8,9
								c0.5,3.9,0.3,7.8-0.7,11.6c-1.6,6.6-5.6,12.4-8.4,18.5c-2.9,6.4-5.3,12.8-6.5,19.7c-0.6,3.6-1.5,6.7-3,10.1
								c-1.5,3.3-4.4,6.7-4.7,10.4c-0.5,5.1,3.4,11.3,4.1,16.5c1.2,9.1-0.7,17.6-3.7,26.1c-5.1,14.7-12.5,28.9-15.3,44.3
								c-1.4,7.7-1.3,15,2.5,21.9c3.1,5.6,7.2,12.2,2,18c-2.7,3.1-8.3,2.4-5.3,6.7c1.5,2.1,4.5,3.6,6.2,5.9c1.8,2.5,3,5.7,5,7.9
								c3.4,3.9,10.7,5.6,15.3,3.4c8.1-3.8,10-7.9,9.6-16.5c-0.5-9.9,8.4-2,12.6-8.1c2.1-3,2.7-9.2,3.9-12.6c4.4-12.1,9.2-23.5,12.3-36.1
								c1.4-5.8,2.2-14.1,5.9-19c4.9-6.5,12.8-4.5,13.4,3.4c0.6,7.5,4,13.2,7.8,19.8c3.1,5.3,12,12.5,10.6,18.9c-0.5,2.5-2.5,4.9-3.5,7.3
								c-1.4,3.4-2.3,6.9-3.3,10.4c-2.2,8-0.7,9,3.2,16.2c2.9,5.4,5,10.2,2.6,16.3c-2.9,7.3-10.6,10.1-14.3,16.8
								c-3.2,5.9-0.7,12.5,1.3,18.4c2.5,7.3,7.4,12.3,10.8,19c3.5,6.9,4.2,12.8,1.7,20.2c-0.6,1.9-3,6.3-2.7,8.4c0.4,2.6,5,2.6,7.6,2.9
								c6.3,0.7,10.9,0.2,15.8,4.7c6.5,6,12.3,12.8,18.5,19.1c2.5,2.5,5.5,6.3,9.1,7.2c7.6,1.9,16.6-6.6,21-11.6
								c10-11.5,19.5-19.1,33.5-25.4c5.7-2.6,11.8-4.6,17.5-7.4c6.1-3,8.9-10.1,13.2-15.2c4.4-5.1,10.1-8.6,14.2-14
								c4.8-6.4,7.8-13.9,9.7-21.6c3.8-15.3,13-27.8,17.9-42.7c1.9-5.7,3.2-10.8,6.2-16.1c3.4-5.9,4.9-12.3,7.7-18.5
								c5.6-12.4,20.9-20.2,33.6-23.7c6.7-1.8,13.1-1.8,19.3-5.2c6.1-3.4,10.9-8.5,14-14.8c2.9-6.1,2.9-12.4,4.5-18.7
								c2.1-8,9.8-13.6,16.3-18c4.7-3.2,10.2-5.2,14.4-8.9c4.9-4.3,8.1-10.5,10.1-16.6c2.3-6.7,3.2-14.4,9.3-19c2.2-1.7,4.8-2,7.3-3.1
								c4.8-2,6.4-4.5,9.5-8.3c3.6-4.6,9.7-5.6,14.5-8.7c6.3-4.1,8.6-9.4,13.1-15c4.8-6.1,9.4-8.1,16.4-10.3c6.7-2.1,12.5-6.6,18.7-9.7
								c4-2,7-4.4,10.6-7.1c5.2-4,4.7-4.7,9.2,0c-10,3.8-15.4,13.5-25.5,17.5c-4.3,1.7-8.9,1.7-13,3.9c-5.1,2.7-8.6,6.7-12.2,11
								c-3.6,4.4-7.2,8.8-11.5,12.5c-4.9,4.1-11.2,5.4-16.1,9.3c-4.4,3.5-4.3,7.6-10,9.2c-5.6,1.6-8.1,1.9-10.2,8.1
								c-2,5.9-2.8,12-5.3,17.8c-2.2,5.1-6,6.8-10.4,9.8c-7.8,5.3-17.3,11.7-23.4,18.9c-6,7.1-6.8,17.9-10.1,26.3
								c-3.8,9.6-15.7,18.6-25.4,21.8c-11.1,3.6-22.6,4.3-32,12c-7.9,6.5-11.6,14.5-15.3,23.8c-3.3,8.3-6,16.7-9.3,25
								c-1,2.4-2.3,4.6-3.4,6.9c-2.9,6.1-3.1,12.2-3.2,18.7c-0.3,11.9-6.9,22.1-10.6,33.4c-4.1,12.7-5.4,25.9-10.3,38.6
								c-4.7,12-5.2,22.6-1.5,34.9c1.7,5.6,3.3,11.4,5.6,16.8c2.9,6.9,5.8,11.5,3.7,19.4c-3.4,12.9-12.2,16.3-21.8,24
								c-5,4.1-7.1,8.8-6.3,15.2c0.8,6.4,0.7,12.6,0.5,19.1c-0.2,6.5-0.7,12.6-1.7,19c-1,6.5,1.9,10.2,3.9,16.1c2,5.9,3.7,12.5,1.3,18.4
								c-1.3,3.3-5.9,8.8-4,12.6c3-1.2,5.7-6.1,8.9-6.3c5.2-0.4,2,4.8,3.5,7c3.3,5,11.3-8.6,13.7-10.9c4.7-4.3,11-5.7,16.9-7.7
								c6.7-2.3,13.4-2.6,18.9-7.6c10-9.2,15.9-20.8,29.6-25.5c5.5-1.9,11-4.9,16.6-6.5c6.6-1.9,11.8,2.7,15.8-4.9
								c1.3-2.5,2.3-7.5,5.9-7.8c4.7-0.3,3.7,4.1,5.5,6c4.4,4.8,10.2-1.5,15.4-1.4c5.5,0.1,9.4,3.1,15,1.6c6.8-1.8,11.8-3.2,16.6,3.2
								c4,5.3,1.3,11.5,4.6,16.7c4.9,7.5,13.3,3,19.6,7.1c2.7,1.8,3,5.2,5.4,7c3.2,2.3,3.9-0.4,6.8-0.6c6.8-0.4,7.5,9.3,15.7,4.6
								c3.2-1.8,4.2-4,8.6-3.1c2.4,0.5,4.3,2,6.7,2.7c7.8,2.2,9.1-5.3,14.8-8.5c5.9-3.3,12.7-1,18.6-4.2c6.4-3.5,5.9-11,8.4-17
								c2.8-6.8,8.3-14.9,15.2-18.1c2.9-1.4,6-1.4,8.3-3.9c2.8-3,3.6-6.5,7.5-8.8c2.9-1.7,5.8-2.2,9-0.9c2.9,1.2,4.3,4.1,7.6,3.7
								c3-0.4,5.5-3.1,8.9-3.5c4.9-0.5,9,1.1,13.6,2.5c2.4,0.7,4.5,0.6,6.9,1c2.6,0.4,4.6,1.8,7.1,2.4c4.6,1.1,12.5,1.7,17.1,0.8
								c7-1.2,4.6-7,8.7-10.8c2-1.8,6.4-5.2,9.1-5.5c3-0.4,4.6,2.6,7.2,2.8c3,0.3,4.1-2.2,6.9-3.2c2.1-0.7,5.3-0.5,7.5-0.3
								c7.1,0.6,10.8,4.4,15.9,8.8c6.2,5.4,7.3,1.1,14.3,0.5c3.6-0.3,6,1.2,9.3,1.8c5.1,0.9,7.5-2.2,11.9-3.4c3.4-0.9,6.5,0.4,9.2,2.3
								c4.1,2.9,5.5,3.3,10.9,2.6c8.1-0.9,13.1-3.4,16.7-11.4c2-4.4,3.2-9.4,4.3-14.2c0.9-3.7,1.9-7.5,2.2-11.3c0.4-4.6-3-8.1-1.3-12.5
								c1.1-2.9,4.2-4.3,4.6-7.5c0.4-2.7-2-6.7-2.2-9.7c-0.4-5.2,1.4-7.2,3.9-11.3c1.7-2.6,1.4-3.1,0.2-5.9c-3.2-7.6-6.3-10.2-2.9-19
								c1-2.5,1.7-4.3,1.9-7.1c0.1-1.5-1-11,0.5-11.3c4.4-0.7-0.7,27,0,29.4c1.3,4.1,4.5,7.3,3.8,12c-0.7,5.1-4.9,8-4.2,13.6
								c0.5,4.5,3,7,1,11.3c-1,2.2-4.2,4.3-4.3,6.8c-0.1,2.3,2.1,4.6,2.5,6.9c0.6,3.8-1.7,8.4-2.6,12.1c-1.2,5.3-1.9,10.7-3.2,16
								c-2.8,11.1-10.4,17-21.5,17.3c-4,0.1-6.6,0-10-1.8c-6.3-3.3-7.6-4.4-13.6-0.3c-4.4,3-6.6,1.5-10.9-0.3c-6.9-3-9.5,2.4-15.8,2.1
								c-4.7-0.2-6.9-4.8-10-7.7c-3.3-3-6.2-2.9-10.4-4.1c-2.8-0.8-3.9-1.7-6.8-0.9c-1.9,0.6-3.2,2.5-4.9,3.1c-4.9,1.8-6.4-3.5-11.2-1.8
								c-6.3,2.3-5.2,6.6-8.3,11.4c-3.1,4.9-9.1,4.7-14.5,4.5c-3.9-0.1-7.6-1-11.3-2.1c-5.1-1.5-10.3-1.7-15.4-3.1
								c-5.4-1.5-9.3-3.4-15.1-1.3c-4.9,1.7-7.9,5-12.7,1.6c-2.7-1.9-2.7-3.4-6.6-1.4c-1.5,0.8-2.6,1.9-3.7,3c-2.8,2.8-4.3,6.3-7.9,8.2
								c-3.6,1.9-8.9,2.2-11.2,6c-1.3,2.2-1.3,3.7,1.3,4.7c3.5,1.4,5.2-0.8,8.3,2.5c1.5,1.6,1.8,4.2,4.4,4.6c2.6,0.4,2.1-1.3,3.9-1.6
								c1.8-0.3,2.1-0.2,4.1,0.2c4.5,0.9,7.6,0.9,12,0c2.7-0.5,5.3,0.1,8-0.2c1.9-0.2,3.5-1.7,5.3-1.8c2.4-0.2,3.5,1.8,5.2,3
								c4,2.8,4.5,0.4,8.5,0.6c2.6,0.2,4.4,2.5,6.8,3.6c2.7,1.2,5.8,1.6,8.7,2.1c6.4,1.1,12.9,1.2,19.2,2.7c5.9,1.4,12.3,3.5,17.6,6.7
								c5.2,3.2,7.9,8.3,14.6,8.2c4.8-0.1,9.2-3.7,13.7-0.8c2.9,1.9,3.9,5,5.2,8c1.8,3.9,2.2,5.5,6.4,6.7c4.5,1.3,10.2,0.6,14.5,2.6
								c2.6,1.2,4.1,3.8,6.2,5.7c4.6,4.2,9.9,4.1,14.9,7.3c2.7,1.7,5.1,4.3,8.1,5.4c4.6,1.7,9.9,0,14.7,0.5c1.5,0.1,6.4,0.7,6,2.5
								c-0.4,1.6-6.3,0.7-6.8,0.7c-4.2-0.1-8.9,1.6-12.9,0.8c-4.6-0.9-8.3-5-12.1-7.5c-3.6-2.4-7.6-1.5-10.8-3.6c-2.8-1.9-3.8-6.3-6.8-7.9
								c-4.6-2.4-11,0.7-16.1-1.6c-5.7-2.5-6.6-8.1-9.6-12.8c-3.5-5.5-7-4.2-12.1-2.9c-6.4,1.6-10.6-2.1-15.3-5.7
								c-4.9-3.8-11.2-5.8-17.2-7.4c-5.2-1.4-10.6-2.3-16-3c-5.2-0.7-11.4-0.4-15.9-3.4c-4.6-3.1-3.4-2.8-9.2-1.8c-5.7,1-8.2-4.5-13.8-4
								c-4.8,0.5-9.4,2.6-14.3,3c-5.4,0.4-11.3,1.4-16.2-1.4c-4.3-2.4-8.6-6.7-14.1-4.4c-5.8,2.6-5.4,10.1-8.6,14.5
								c-5.6,7.6-17.4,7-25.1,10.8c-4.1,2-6.9,6.4-11.4,7.9c-3.8,1.3-6.9-0.6-10.7-1.4c-6-1.2-8.7,3.8-14.8,2.9c-5.5-0.8-9.2-2.5-15-2.1
								c-5.2,0.4-4.3-1.5-7.5-5.5c-4.3-5.3-9.4-3.8-15.2-4.8c-10.4-1.8-10.8-19.4-16.5-26c-3.6-4.1-8.2-0.5-12,1.1
								c-4.7,2-7.6-1.2-12.6-1.5c-4.7-0.3-8.1,3.2-12.8,2.6c-2.4-0.3-3.8-1.4-5.7-2.6c-3.1-2-1.6-3.2-5-0.2c-3.7,3.4-6.6,5.5-11.8,5.9
								c-2.4,0.2-4.2-0.4-6.6,0.6c-2.3,0.9-4.3,2.7-6.6,3.7c-6.7,2.7-14.3,2.8-19.5,8.3c-7.2,7.6-15,17.2-19.5,26.7
								c-3.7,7.7-4.7,19.2-11.9,24.5c-1.9,1.4-2.9,1.6-4.2,3.5c-1,1.5-1.4,3.5-2.4,5c-3,4.6-7.2,4-10.9,7c-1.7,1.3-2.8,3.6-4.5,5.1
								c-1.4,1.2-3.2,2-4.3,3.7c-3.8,6,2.9,7,5.3,11.4c4.5,8.2,2.5,19.6,5.2,28.5c2.6,8.6,15.7,22.7,4.1,29.9c-2.1,1.3-13.6,1-12.7,5.1
								c0.5,2.2,2.4,1.2,4.3,1.9c3.4,1.2,4.9,3.9,6.2,7c3.7,9,3.4,20.6,4,30.2c0.7,10.3-4.8,19.4,0.1,28.9c4.6,8.8,2.4,19.9,3.1,29.6
								c0.4,5.5,0.6,11.5,2.2,16.7c1.6,5.2,4.3,8.7,4.5,14.4c0.3,7.4,2.5,16.2,1.2,23.3c-0.8,4.5-4.5,8.2-8.1,10.8
								c-1.1,0.8-3.5,1.4-4.1,2.6c-0.7,1.6,1,4.7,0.6,6.8c-1.8,7.8-8.5,5.1-12.5,9.5c-3.2,3.6,0,11.8-0.6,16.3
								c-1.2,9.9-7.7,17.7-13.4,25.5c-5,7-11.2,12.7-17,19c-6.9,7.6-10.6,17.2-17.2,25c-6.6,7.9-14.8,17.8-23.8,23
								c-5,2.9-9.9,3.7-15.2,5.3c-5.7,1.8-9.5,6.7-14.7,9.6c-4.8,2.7-8.6,1.3-13.6,1.7c-5.3,0.4-10.4,3.5-13,8.2
								c-2.5,4.6-2.4,12.4,0.1,17.1c2.7,4.9,9.4,6.3,14.6,6.3c4.7,0,8.8-2.3,13.5-2.7c5.8-0.5,10.9,1.5,16.5,0.6c4.5-0.7,10,2.5,14.1,1.4
								c2-0.5,3.3-3.4,4.3-4.9c2.3-3.3,2.9-3.5,6.5-4.9c1.7-0.7,1.1,0.6,2.4-0.8c2.2-2.3,2.4-4.7,5.4-6.5c4.8-2.9,10.3-1,14.8-3.5
								c4.3-2.4,6.1-7.7,9.7-10.6c4-3.2,10.1-3.1,14.9-3.6c5.4-0.7,10.4-1.9,15.4-4.1c3.9-1.7,9.3-3,12.6-5.8c4.7-3.9,7.7-9.3,12.9-12.9
								c4.5-3.1,10.3-6.8,16-6.5c4.5,0.2,7.1,1.6,10.5-2.1c3.9-4.4,7.3-7.2,13.3-9.1c3.6-1.2,10-1.9,12.2,2.3c1.6,3-1.2,6.7-0.3,9.7
								c0.6,2.3,4.4,6.6,6.1,8.5c4.4,5.2,8.3,4.8,14.2,2.8c2-0.7,4.1-1.7,6.2-1.4c2.6,0.4,4,2.4,6.7,2.4c2.6,0.1,4.5-1.6,6.9-2.1
								c3.5-0.8,5.7,0.6,8.5,2.6c4.4,3.1,8.6,6.7,14,8.1c5.4,1.4,9.7-0.6,14.9-1.7c5.1-1.1,11.6-2.3,16.2,0.8c1.8,1.2,3.3,3,4,5
								c1.7,4.3,0.4,2.5,4.3,4.2c3,1.3,5.8,7.6,9.3,6.7c4.8-1.2,3.9-12.3,3.8-16c-0.7-17.6-0.1-35,0.2-52.7c0.2-10.7-1.2-21.6-0.5-32.3
								c0.2-3.1,0.9-6.1,2.7-8.7c1.1-1.5,3-2.6,3.3-4.6c1-7.3-11.3-6-15.3-6.7c-4.3-0.7-4.3-3-7.8-5.4c-5-3.3-7.4-0.3-11.4,2.3
								c-5.4,3.5-5.9-0.1-10.1-2.8c-4.5-3-10.2-4-6.4-11c2.8-5.3,6.3-6.4,6.7-13c0.2-4.2-0.3-7.3,1.5-11.1c1.9-4,3.7-8.1,5.8-12
								c2.5-4.7,6.3-8.2,3.7-13.9c-1.3-3-5.5-6.7-1.6-9.5c3.3-2.5,10.1,0.5,9.6-5.5c-0.2-2.6-3.2-2.9-1.8-6.9c0.8-2.3,2.1-3.2,3.8-4.7
								c3.9-3.5,7.4-6.5,5.9-12.3c-1.4-5.5-4.1-8.5-0.6-14.2c3.1-5.2,8.5-8.2,12.1-13c3.6-4.7,5.7-4.9,11.2-2.9c3.4,1.2,2.8,1.8,6.8,0
								c2.5-1.1,2.2-1.8,5.1-1.1c8.5,2,17.6,3.9,20.1,13.2c1.1,4.2,2.5,5.4,5.6,8.7c4.2,4.4,2.6,10,7.1,14.1c3.1,2.9,7.6,4.3,11.8,4.9
								c9.4,1.3,2.2-5.2,3.7-10.1c1.4-4.5,5.1-4.9,2.8-10.1c-0.8-1.8-2.7-4.1-2.4-6.2c0.3-2,2.4-3.7,2.9-5.9c0.8-4.3-0.7-5.6,2.8-8.5
								c4-3.3,6.9-3.2,8.2-8.3c2.6-10.1,12.4-16.1,22.2-16.9c5.6-0.5,9.9-2.2,12.8-7.4c1.1-1.9,1.3-3.8,3.2-5.1c2.6-1.8,5.6-0.4,8.3-1.2
								c2.2-0.6,3.2-2.6,4.9-3.8c2.5-1.9,4.2-1.7,7.2-2.2c6.7-1.2,3.8-3.2,5.3-8.2c1.2-4,7-5.6,10.5-7.7c5.5-3.4,7.8-6.7,10.8-12.2
								c2.3-4.2,6.6-6.5,10.2-9.5c1.9-1.5,7.4-9.1,9.6-8.8c3.2,0.4-3.8,7.6-5.4,9.1c-2.5,2.3-5.9,3.7-8.2,6.1c-1.6,1.7-2.6,4.3-3.9,6.3
								c-2.4,3.8-4.4,7.4-8.2,9.7c-4.1,2.4-10.2,3.4-12.1,8.2c-1.6,3.9,0.1,7.6-5,9.4c-2.3,0.8-4.6,0-6.8,0.9c-2.5,1-3.5,3.7-5.6,5
								c-3.3,2.1-7.7,0.2-10.8,2.9c-1.8,1.6-2.4,4.8-4.1,6.7c-1.8,2.1-4.5,4.1-7.2,4.8c-9,2.5-18,0.3-22.5,10.9c-1.3,3.2-1.2,7.7-3.8,10.2
								c-2,1.9-5,1.5-6.9,3.8c-2.2,2.6-0.3,5-1.2,7.6c-0.9,2.5-3.3,2.3-2.9,5.5c0.3,2.9,3.2,4.4,3.3,7.6c0.1,2.7-1.6,4.2-2.4,6.5
								c-0.9,2.7-0.8,4.6-0.7,7.5c0.3,12.5-14.7,7.2-22,3.1c-5.7-3.2-4.1-6.1-5.8-11.5c-1.2-3.6-2.9-4.3-5.4-6.7c-2.3-2.3-2.2-4.5-3.3-7.3
								c-2.4-6.2-12-10.2-18.5-9.9c-2,0.1-2.5,1-4.3,1.8c-4.4,2-3.3,0.7-6.5-1.2c-5.4-3.2-8.8,2.9-11.7,6.3c-2.4,2.8-5.6,5.5-7.3,8.8
								c-3,6.1,2.7,11.7,0.2,18.1c-0.9,2.3-2.7,4.7-4.4,6.5c-1.2,1.3-4.2,2.9-4.8,4.4c-1.9,4.6,7,7.4,0.5,12c-3.3,2.3-10-0.2-7.9,5.8
								c0.9,2.4,2.8,3.5,2.9,6.3c0.1,2.6-1.1,4.9-2.3,7.1c-4.6,9-10.4,15.2-10.2,25.7c0.1,4.8-1,7.7-4,11.7c-1.1,1.5-3.5,3.8-3.2,6
								c0.6,4.2,3.2,2.4,5.6,3.8c5.1,2.9,1.9,3.9,8,0.4c4.2-2.4,10.2-2.7,14.1,0.7c2.5,2.2,2.7,4.1,6.4,4.8c2.7,0.6,5.7-0.2,8.4,0
								c3.7,0.3,6.9,1.5,9.1,4.7c5.7,8.5,2.3,20.3,2.7,29.7c0.6,10.5,1.3,21,0.6,31.6c-0.2,3-1.2,6.4-0.4,9.3c1.5,5.8,6.9,6.6,11.1,9.5
								c4.3,3.1,7.2,9.9,8.9,14.7c0.4,1.2,0.6,5.1,1.4,5.7c1.7,1.3,4.4-0.7,6.1-0.8c5.1-0.4,8.8,4.7,14.4,3.3c4.3-1.1,4.2-6.3,7-9.3
								c8-8.5,20.9-6.4,26.4-17.7c2-4.1,2.1-8.2,2.7-12.6c1.1-6.8,2.5-13.5,5.3-19.8c2.1-4.7,4.3-9.4,9.4-11.4c4.9-2,10-0.4,15-2.1
								c5.4-1.8,8.7-5.2,13.1-8.5c2.8-2.1,4.1-1.6,7.3-2.4c2.1-0.5,3.9-1.9,6-2.7c5.6-2.2,9.4,0.2,14.9,0.8c7,0.7,12.8-0.6,19-3.6
								c8.6-4.1,17.4-1.7,24.2-9.1c6.4-7.1,17.1-8.1,25.4-12.2c4.1-2,25.3-14.9,22.1-1.7c-1,4.2-5.1,6.1-7.5,9.3
								c-1.8,2.4-4.3,8.6-3.9,11.4c0.3,1.8,0.9,2.3,3.4,2.6c3.7,0.4,3.6-1.5,6.2-3.2c3.5-2.3,8.3-1.8,12.3-1.9c5.4-0.1,8-1,12.4-3.9
								c5-3.4,10.8-6.8,17.1-6c5.1,0.6,9.1,2.5,14.2,0.4c6.1-2.5,10-7.6,15.2-11.2c8.7-6,18.6-3,28-0.9c2.7,0.6,5.4,1.1,8.1,1.8
								c3.9,1.1,5.7,3.4,8.7,6c3.5,3.1,7.7,4.7,12.1,5.6c5.1,1,11.9,3.3,17.1,2.4c4.5-0.8,8.6-4,13.2-5.1c6.1-1.3,12.1-0.9,18.1,0.4
								c9,2.1,23.1,8.8,31.8,2.6c5.6-4,9.4-13.4,11.2-19.9c2.3-8.5,1.2-17.1-3.7-24.2c-5.4-7.8-11.6-22.2-23.1-20.9
								c-4.3,0.5-7.8,2.9-11-1.8c-0.6-0.9-1-4.5-1.9-5c-2.2-1.3-5,1.5-6.8,2.2c-4.4,1.9-11.7,1-15.6-2c-1.3-1-2.3-2.3-2.8-3.8
								c-1-2.7,1.2-4.9,0.5-7.1c-1.2-3.7-4.3-1.4-6.6-2.3c-2.7-1.1-2.7-3.2-2.9-5.7c-0.1-2.2,0.6-1.8-0.6-3.8c-1.2-2-4.7-2.3-5.8-4.1
								c-2.5-4.2,2.8-8.1,6.2-9.7c6.1-2.8,10.2-5.3,14.7-10.4c3.5-4,8.6-5.8,12.1-9.9c3.5-4.1,5.6-9.3,9.1-13.6c3.6-4.4,9.1-7.1,11.3-12.5
								c1.9-4.7,0.8-9.1,4.9-12.9c3.3-3,7.2-3.4,7.1-8.7c0-2.4-1.5-3.7-0.1-6.2c1.2-2.2,3.8-2.8,5.4-4.7c3.8-4.5-1.3-7-4.3-10.2
								c-2.6-2.8-4.1-6.3-6.6-9.2c-0.9-1-4.3-3.2-3.8-4.8c0.8-2.7,3.2,0.4,4.2,1.2c1.1,0.9,3.6,2.3,4.4,3.5c1.3,2,0.6,4,2.3,5.9
								c3.1,3.4,10.4,6.2,8.1,12.3c-0.9,2.4-3.7,3.5-5.3,5.4c-2.2,2.6-1.4,3.2-1.4,6.6c0,3.8-1,5.4-3.4,7.8c-1.8,1.8-4.3,3.2-5.7,5.3
								c-1.9,2.7-1.2,5.4-2.1,8.5c-1.5,5-5.1,7-8.6,10.5c-3.9,3.8-6.2,8.1-9,12.7c-2.2,3.5-5.6,9.2-8.7,11.7c-1.7,1.4-3.6,1.1-5.4,2.1
								c-2.3,1.2-3.1,4.3-4.6,6.2c-3.5,4.4-7.5,4.8-12.1,6.9c-8.4,3.7,1.3,4.7,2.5,8.9c0.5,1.8-1.3,3.6-0.1,5.5c1.6,2.5,3.5,0.8,5.8,1.6
								c6.2,2.4,0.2,9.5,5.1,12.8c4.2,2.8,8.7,1.9,13,0.3c6.6-2.5,7.2-0.8,10.8,4.5c3.3,4.9,10.2,1.1,15.8,2.6c3.7,1,5,3.3,7.3,6.1
								c3.1,3.8,6.9,7,9.9,10.8c2.5,3.1,3.4,7.1,5.2,10.6c0.9,1.8,2.3,3.6,2.7,5.6c0.4,2.1-1,5.4,0.3,7.3c2.2,3.1,8.3-3,12.6-2.4
								c5.1,0.7,8,2.2,13.1-0.4c5.4-2.8,9.6-6.8,15.7-8.3c7.4-1.8,18.6,0,23.3-7.6c2.9-4.6-0.2-8.5-1.1-13.1c-0.9-4.9,1.4-9.9,3-14.5
								c1.7-4.7,3.3-10.6,7-14.2c3.7-3.6,5.1-6.2,7.5-10.7c2.7-4.9,5.3-7.8,4.9-13.8c-0.2-3.9-1.4-7.9-3.2-11.4c-1.4-2.7-3.4-4.5-5.1-6.9
								c-2.3-3.1-2.2-4.6-2.8-8.1c-0.4-2.5-1-5.3-2.9-7.1c-0.4-0.4-6.2-2.9-6.2-2.7c0.1-3.2,9.5,1.9,10.4,3.3c1.2,1.9,0.8,3.6,1.2,5.8
								c0.7,3.5,2.5,6.3,4.6,9.2c6.6,9.1,7.7,19.4,3.4,29.7c-1.9,4.5-3,7.9-6.4,11.5c-3.2,3.5-5.3,5.7-6.9,10.2c-1.6,4.4-2.9,9.1-4.2,13.6
								c-1.5,5.3,1.3,7.6,1.6,12.8c0.2,3.1-2.1,7.7,1.5,9.5c3.6,1.8,7.5-3.7,10.6-4.7c3.3-1.1,8.4,0.9,11.4-0.9c2-1.2,2.3-3.8,4.3-5.1
								c2.2-1.4,5.3-1.3,7.7-2.2c7.3-2.6,11.8-12.1,16.7-17.7c2.7-3.1,6.1-5.7,8.8-8.7c2.1-2.3,1.4-3.8,2.3-6.5c1.2-3.4,3.9-5,6.4-7.3
								c7.3-6.5,13.4-14.4,20.8-20.7c3.4-2.9,7.4-5.1,10.3-8.6c2.6-3,1-4.4,2-7.6c0.3-1,3.1-5.4,4.8-4.1c1.3,1-1.5,3.9-1.8,4.7
								c-0.7,2-0.3,2.7-0.6,4.9c-0.7,5-4.7,7.1-8.6,9.8c-6.7,4.7-12.2,10-17.9,15.9c-2.9,3-5.7,6-8.4,9.2c-3.1,3.8-3.3,8.5-6.8,11.7
								c-7,6.7-12.4,14.5-18.4,22.1c-1.6,2-3.1,5.4-5.3,6.9c-2.2,1.6-4.6,0.7-7.1,1.4c-5.8,1.6-5.7,7.5-12.4,7.9c-5,0.4-6.5,0.1-10.9,2.6
								c-4.4,2.5-8.1,0.8-12.7,1.4c-4.1,0.5-7.9,1.6-11.5,3.3c-5,2.4-9.7,0.2-14.9,2.1c-9.3,3.5-13.5,13.3-24.8,10.3
								c-10.9-2.9-16.9,6-22.4,14.5c-5.5,8.5-12.8,18.4-13.5,28.8c-0.6,8.6-0.2,18.3-5,26c-2.6,4.2-5,8.2-7,12.7
								c-1.6,3.8-3.9,9.4-8.7,10.1c-4.3,0.6-5.8-3.5-7.8-6.4c-3-4.3-7.1-9.4-12.5-10.4c-3.2-0.6-9.1,0.2-10.6,3.7c-1.4,3.1,0.7,8,0.3,11.4
								c-0.6,5.3-4.9,9.3-5.5,14.8c-0.4,3.7-0.4,11.7,3.2,14c6.8,4.1,18.3-5.5,24.2-7.9c4-1.6,8.2,0,10,4.1c2.5,5.8-1.9,9.5-5.2,13.5
								c-6.6,7.8-10.3,13.6-5.7,23.4c3.4,7.2,7,13.1,8.9,20.7c1.1,4.2,3.6,5.9,6.6,9.1c3.8,4.3,4.6,7.3,4.9,12.8c0.2,3.6,0,8.4,3.5,10.5
								c4.5,2.7,11.7,0.1,16.7,0.5c11.3,1.1,16.4,12.6,19.2,22.2c2.5,8.4,4.2,20.8,13.5,24.6c9.8,4,11.1-11.1,12.3-17.4
								c1-5.2,3.3-8.8,5.6-13.4c2-3.9,2.2-9,1.8-13.3c-0.3-3.6-2-7.4-1.8-11c0.4-5.7,4.7-7.4,9.3-9.2c8.4-3.4,5.9-14.6,9.9-22
								c2.8-5.1,6.2-5.4,11.6-3.3c3.6,1.4,9.6,6.1,13.3,5.5c3.9-0.7,2.5-3.5,2.6-6.6c0.2-5.9,3.1-11.8,6.6-16.5c3-4,6.8-7.2,11.5-8.8
								c4.1-1.3,8.7-0.7,12.8-2c4-1.2,7.4-3.9,11.4-5.3c3.8-1.3,7.9-1.9,11.7-3.1c7.5-2.4,13.5-7.8,21.4-9.6c4.9-1.1,9.9-0.1,14.5-2.1
								c5.5-2.4,8.9-3,14.8-3.5c9.8-0.8,19.8-7,22.9-16.7c1.5-4.7,1.4-9.7,2.3-14.5c0.8-4.3,3.5-6.5,5.1-10.5c1.8-4.4-1.1-7.5,0.1-11.9
								c1.4-5.1,6.3-8.2,7.4-13.4c2.2-10,3.5-17.6,8.9-26.5c2.8-4.6,2.5-7.6,3.4-12.5c0.9-4.5,4.3-8,6.6-11.8c1.8-3,4.2-7.8,4.8-11.2
								c0.8-3.8-1.6-7.1-1.8-10.9c-0.3-5.2,3.3-7.9,4.9-12.3c1.5-4-0.8-6-1-9.6c-0.2-3.8,2.2-8.2,3.2-11.8c0.3-1.1,1.3-9.8,0.8-9.6
								c4.3-1.4-1,16.2-1.2,17.7c-0.3,2.4,0.4,4.2,0.8,6.5c0.8,4.9-1.1,7.5-2.9,11.7c-2.6,6,0,9.9,0.1,15.8c0.1,7.6-5.5,13.6-8.5,20
								c-2,4.2-0.8,8.5-2.7,12.9c-2.2,5-5.2,9.6-7.3,14.7c-3,7.1-2.6,16.4-6.3,22.9c-1.3,2.3-3.4,3.8-4.3,6.4c-1.6,4.2,0.7,7.5,0,11.6
								c-0.8,4.8-5,8.4-6.1,13.2c-1.5,6.1-0.5,13.4-3.6,19c-2,3.5-5.5,6-8.7,8.3c-1.7,1.3-6.6,3.3-5.3,5.4c0.6,1,5.7,2.4,7,2.9
								c6.8,2.4,9.8-2.8,15.7-5.2c4.8-1.9,9.3-5.3,12.8-9.3c4.1-4.6,7.5-11.1,10.2-16.6c3.1-6.4,2.2-13.7,6.3-19.4
								c3.2-4.4,7.6-7.8,10.2-12.5c1.1-2,1.9-3.9,3.5-5.7c2.5-2.9,6.6-4.9,8.6-8.1c1.8-2.8,1.8-6.1,4.2-8.7c2.3-2.5,4.7-3.2,6.2-6.4
								c1-2.2,3.1-19.6,7-18.2C1881,1773.4,1880.5,1775.5,1880.4,1775.8z"
					/>
				</g>
				<g id="bridges">
					<polyline
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						points="1365.2,617.1 
								1369.4,624 1383.5,625.3 1390.9,618.3 	"
					/>
					<polyline
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						points="1360.4,643.4 
								1367.2,636.8 1382,639.2 1384.8,647.3 	"
					/>

					<line fill="none" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeMiterlimit="10" x1="1513.6" y1="649" x2="1524.8" y2="651.2" />

					<line fill="none" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeMiterlimit="10" x1="1507.5" y1="658.8" x2="1520.1" y2="659.8" />

					<line fill="none" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeMiterlimit="10" x1="1756.9" y1="603.1" x2="1777.2" y2="598.6" />
					<polyline
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						points="1761.8,614.5 
								1768.3,610.4 1779.7,609.6 	"
					/>
					<polyline
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						points="893.8,639.7 
								896.8,643 914.2,641.3 917.5,635.8 	"
					/>
					<polyline
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						points="897.2,658.1 
								900.8,653.4 914,651.8 917.9,657.3 	"
					/>
					<polyline
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						points="962.7,795.4 
								966,797.2 973.2,800.3 978.6,800.3 	"
					/>

					<line fill="none" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeMiterlimit="10" x1="956.3" y1="807.2" x2="970.6" y2="812.1" />
					<polyline
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						points="1155.8,958.3 
								1156.9,962 1163.7,972 	"
					/>
					<polyline
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						points="1166.8,953.5 
								1170.6,961.9 1172.8,968.1 	"
					/>
					<polyline
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						points="1404.4,1367.5 
								1411,1367.9 1416.1,1370.2 	"
					/>
					<polyline
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						points="1403.3,1376.2 
								1410.3,1379.6 1415.9,1379.6 	"
					/>
					<polyline
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						points="2095,1945.9 
								2091.6,1937.9 2091.6,1931.4 	"
					/>
					<polyline
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						points="2080.8,1936.4 
								2082.4,1942.8 2082.4,1952 	"
					/>
					<polyline
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						points="2205.7,2278 
								2211.1,2288 2215.4,2293.7 	"
					/>
					<polyline
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						points="2202.4,2297 
								2198.9,2290 2192.5,2285 	"
					/>
				</g>
				<g id="legend">
					<rect x="163.9" y="2190.5" width="85.3" height="7" />
					<rect x="163.9" y="2204.5" width="85.3" height="7" />
					<rect x="249.2" y="2197.5" width="85.3" height="7" />
					<rect x="334.6" y="2190.5" width="85.3" height="7" />
					<rect x="334.6" y="2204.5" width="85.3" height="7" />
					<rect x="419.9" y="2197.5" width="85.3" height="7" />
					<rect x="505.2" y="2190.5" width="85.3" height="7" />
					<rect x="505.2" y="2204.5" width="85.3" height="7" />

					<line fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" x1="164.9" y1="2196.2" x2="164.9" y2="2185.9" />

					<line fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" x1="248.2" y1="2196.2" x2="248.2" y2="2185.9" />

					<line fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" x1="335.6" y1="2196.2" x2="335.6" y2="2185.9" />

					<line fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" x1="418.8" y1="2196.2" x2="418.8" y2="2185.9" />

					<line fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" x1="506.2" y1="2196.2" x2="506.2" y2="2185.9" />

					<line fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" x1="589.5" y1="2196.2" x2="589.5" y2="2185.9" />
					<rect x="235.3" y="2168" fill="none" width="50" height="26.7" />
					<text transform="matrix(1 0 0 1 235.2859 2182.8999)" fontFamily="'Mirza-Medium'" fontSize="29px">
						5
					</text>
					<text transform="matrix(1 0 0 1 249.4954 2182.8999)" fontFamily="'Mirza-Medium'" fontSize="29px">
						0
					</text>
					<rect x="314.1" y="2168" fill="none" width="50" height="26.7" />
					<text transform="matrix(1 0 0 1 314.0568 2182.8999)" fontFamily="'Mirza-Medium'" fontSize="29px">
						1
					</text>
					<text transform="matrix(1 0 0 1 324.2936 2182.8999)" fontFamily="'Mirza-Medium'" fontSize="29px">
						0
					</text>
					<text transform="matrix(1 0 0 1 341.4615 2182.8999)" fontFamily="'Mirza-Medium'" fontSize="29px">
						0
					</text>
					<rect x="402.1" y="2168" fill="none" width="50" height="26.7" />
					<text transform="matrix(1 0 0 1 402.0568 2182.8999)" fontFamily="'Mirza-Medium'" fontSize="29px">
						1
					</text>
					<text transform="matrix(1 0 0 1 412.2936 2182.8999)" fontFamily="'Mirza-Medium'" fontSize="29px">
						5
					</text>
					<text transform="matrix(1 0 0 1 426.5035 2182.8999)" fontFamily="'Mirza-Medium'" fontSize="29px">
						0
					</text>
					<rect x="483.7" y="2168" fill="none" width="50" height="26.7" />
					<text transform="matrix(1 0 0 1 483.7234 2182.8999)" fontFamily="'Mirza-Medium'" fontSize="29px">
						2
					</text>
					<text transform="matrix(1 0 0 1 498.7742 2182.8999)" fontFamily="'Mirza-Medium'" fontSize="29px">
						0
					</text>
					<text transform="matrix(1 0 0 1 515.9422 2182.8999)" fontFamily="'Mirza-Medium'" fontSize="29px">
						0
					</text>
					<rect x="339.4" y="2130.4" fill="none" width="126.8" height="36.3" />
					<text transform="matrix(1 0 0 1 339.4006 2151.7969)" fontFamily="'Mirza-Medium'" fontSize="41px">
						M
					</text>
					<text transform="matrix(1 0 0 1 368.5925 2151.7969)" fontFamily="'Mirza-Medium'" fontSize="41px">
						i
					</text>
					<text transform="matrix(1 0 0 1 378.5964 2151.7969)" fontFamily="'Mirza-Medium'" fontSize="41px">
						l
					</text>
					<text transform="matrix(1 0 0 1 388.5183 2151.7969)" fontFamily="'Mirza-Medium'" fontSize="41px">
						e
					</text>
					<text transform="matrix(1 0 0 1 407.4187 2151.7969)" fontFamily="'Mirza-Medium'" fontSize="41px">
						s
					</text>
					<circle fill="none" stroke="#000000" strokeWidth="9" strokeMiterlimit="10" cx="385.6" cy="1990.6" r="68.7" />
					<polygon points="477.9,1990.6 395.4,2000.4 385.6,2082.9 375.7,2000.4 293.2,1990.6 375.7,1980.7 385.6,1898.2 395.4,1980.7 	" />
					<polygon points="374,1967.9 373.1,1977.5 364.1,1978.4 338.1,1940.9 	" />
					<polygon points="408.4,1977.7 398.7,1977.2 397.6,1968.1 434.1,1941 	" />
					<polygon points="397.9,2013.5 398.9,2003.9 408,2003.2 433.4,2041 	" />
					<polygon points="363.9,2003.8 373.7,2004.2 374.9,2013.2 338.7,2040.8 	" />
					<rect x="363.2" y="1698.9" fill="none" width="91" height="64.5" />
					<g display="none">
						<text transform="matrix(1 0 0 1 363.2431 1746.3535)" display="inline" fontFamily="'PlayfairDisplay-Black'" fontSize="62px">
							N
						</text>
					</g>
					<path
						d="M423.9,1839.4c-0.7,4.4-4.8,7.3-5.7,6.8c0,0,1.2-2.8,0-5.2c-0.7-1.4-2.3-2.4-4-2.4c-1.9,0-3.2,1.5-3.8,2.2
								c-2.1,2.5-2.9,5.8-3,9.7c-0.2,5.3,0.1,8.6,0.3,12.8c0.5,7.6,2.1,10.6-0.1,13.3c-0.2,0.2-1.8,2.4-4.3,2.5c0,0,0.1-2.4,0.2-6.9
								c-2-3.1-6.3-8.9-13.2-14.4c-6.3-5-17.1-10.6-24.4-6.9c-3.8,1.9-6.1,5.3-6.1,5.3s1-6.1,5.4-9.7c5-4,11.1-3.6,12.8-3.5
								c6.9,0.5,11.8,4.2,14,5.9c4.5,3.4,7,5.6,11.3,10.2c0.1,0.1,0.1,0.1,0.2,0.2c0-2.4,0-5,0-7.8c0-1.9-0.1-5,1.3-8.8
								c0.7-1.9,2.5-6.3,6.9-8.9c0.7-0.4,6.7-3.9,10.2-1.1C424.6,1834.8,424,1838.9,423.9,1839.4z"
					/>
					<path
						d="M377.7,1846.9c0.9,6.7-0.2,11.9-1.1,14.8c-1.2,4.3-3.3,11.3-9.4,14.2c-1.2,0.6-5.8,2.5-8.7,0.5c-3.1-2.2-1.6-7.1-3.9-7.8
								c-1.2-0.4-7.5,7.6-7.5,7.6c0.5,4.1,8,6.7,12.6,6.8c16.1,0.5,27.8-27.7,29-30.6"
					/>
				</g>
				<g id="lakes">
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M2884,1014.2
								c-2.2-1.9-3.3-8.7-5-9.5c-1-0.5-15.6,3.6-19,3.2c-1.5-0.2-19.8-7.8-22-7.5c-0.6,0.4-3.6,8-4.5,9.2c-2,2.6-6.6,6.8-9.5,8.9
								c-2.4,1.7-15.2,6-12.3,10.2c2.8,4.1,20.7-3.4,24.6-3.9c12.3-1.6,24.7-0.6,36.5,3.5"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3.5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M2865,1037
								c-11.1-5.3-27.7-3.2-38.7,2c-9.7,4.6-10,10.8-19,5.2c-2.1-1.3-12.7-11.2-12.5-14.3c0.3-4.9,16.9-13.3,20.7-16.7
								c3.6-3.2,7.6-6.5,10.3-10.6c1.9-2.8,4.1-13.9,6.7-15.1c3.5-1.7,12.9,5.2,18.7,6.6c11.4,2.7,21.5,1.3,32.3,1.9
								c4.7,0.3,3.1-1.9,6.9-0.1c0.7,0.3,3.9,8.1,6.1,9.6"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M2912,994.8
								c-7.7-1.8-5.5-9.7-9.1-11.6c-3.2-1.7-1.5,0.3-5.1-0.1c-9.1-1-20.3,0.9-30.3-0.7c-8.2-1.3-14.8-5.3-22.7-6.8c-3.1-0.6-5.5-0.8-8.5-1
								c-0.5,0-10.7-1-11.2-0.8c-0.9,0.3-6.5,16.4-7.8,18.2c-9.3,13.1-22.7,20.4-34,31.3c-11.2,10.9-2.6,13.4,7.1,20.7
								c5.7,4.3,9.5,7.6,11.9,14.7c0.6,1.8,1.3,13.4,2,14.1c0.9-0.4,20.1-20.9,24.9-22.7c11.5-4.3,25.9-4.3,38.1-4.5"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M2868.2,1055
								c-11.4,0.7-24.5,0.8-35.1,5.5c-11.9,5.2-14.7,14.2-20.9,24.3c-2.8,4.5-13.1,23.3-18.8,15.2c-2.7-3.9,1.1-16.8,0-22.1
								c-1.4-6.3-4.5-13.1-8.3-18.3c-6.9-9.3-26.9-13.9-26.4-25.7c0.5-11.5,17.4-18.6,25-24.7c7.2-5.9,12.5-12.3,17.5-19.9
								c3.3-4.9,8-10.8,10.1-16.3c0.6-1.6,0.6-14.7,1.6-15.1c0.2-0.1,11.2,4,12.8,4c5.4,0.2,10.6-2.2,15.9-2c7.7,0.3,14.8,4.1,22.1,6.5
								c10.5,3.5,20.3,5.5,31.5,5c2.6-0.1,28.8-7.6,28-8.2c-1.4-1.2-6.1,18.4-4.8,22c2.6,6.9,10,11.8,17,13.4c9.8,2.4,22.7-5.3,17.8,10.8
								c-1.8,5.8-7.9,11.2-11.8,15.7c-5.5,6.4-5.2,10.4-7.9,18.2c-2.6,7.2-6.9,10.8-11.1,17.5c-3.3,5.3-4.1,16.2-11.2,8.4"
					/>
					<g>
						<path d="M2918.2,1053.8c2.6,0,2.6-4,0-4C2915.6,1049.8,2915.6,1053.8,2918.2,1053.8L2918.2,1053.8z" />
					</g>
					<g>
						<path d="M2922.6,1047.1c2.6,0,2.6-4,0-4C2920,1043.1,2920,1047.1,2922.6,1047.1L2922.6,1047.1z" />
					</g>
					<g>
						<path d="M2916.1,1046.6c2.6,0,2.6-4,0-4C2913.5,1042.6,2913.5,1046.6,2916.1,1046.6L2916.1,1046.6z" />
					</g>
					<g>
						<path d="M2913.2,1041.8c2.6,0,2.6-4,0-4C2910.7,1037.8,2910.7,1041.8,2913.2,1041.8L2913.2,1041.8z" />
					</g>
					<g>
						<path d="M2908.4,1046.8c2.6,0,2.6-4,0-4C2905.9,1042.8,2905.9,1046.8,2908.4,1046.8L2908.4,1046.8z" />
					</g>
					<g>
						<path d="M2911,1053.1c2.6,0,2.6-4,0-4C2908.4,1049.1,2908.4,1053.1,2911,1053.1L2911,1053.1z" />
					</g>
					<g>
						<path d="M2912.9,1061.2c2.6,0,2.6-4,0-4C2910.4,1057.2,2910.4,1061.2,2912.9,1061.2L2912.9,1061.2z" />
					</g>
					<g>
						<path d="M2905,1060.2c2.6,0,2.6-4,0-4C2902.4,1056.2,2902.4,1060.2,2905,1060.2L2905,1060.2z" />
					</g>
					<g>
						<path d="M2907.1,1065.3c2.6,0,2.6-4,0-4C2904.6,1061.3,2904.5,1065.3,2907.1,1065.3L2907.1,1065.3z" />
					</g>
					<g>
						<path d="M2896.9,1062.3c2.6,0,2.6-4,0-4C2894.3,1058.3,2894.3,1062.3,2896.9,1062.3L2896.9,1062.3z" />
					</g>
					<g>
						<path d="M2898.6,1055.9c2.6,0,2.6-4,0-4C2896.1,1051.9,2896,1055.9,2898.6,1055.9L2898.6,1055.9z" />
					</g>
					<g>
						<path d="M2893.3,1052.6c2.6,0,2.6-4,0-4C2890.7,1048.6,2890.7,1052.6,2893.3,1052.6L2893.3,1052.6z" />
					</g>
					<g>
						<path d="M2890.1,1059.6c2.6,0,2.6-4,0-4C2887.5,1055.6,2887.5,1059.6,2890.1,1059.6L2890.1,1059.6z" />
					</g>
					<g>
						<path d="M2887.6,1053.6c2.6,0,2.6-4,0-4C2885,1049.6,2885,1053.6,2887.6,1053.6L2887.6,1053.6z" />
					</g>
					<g>
						<path d="M2880.9,1054.9c2.6,0,2.6-4,0-4C2878.3,1050.9,2878.3,1054.9,2880.9,1054.9L2880.9,1054.9z" />
					</g>
					<g>
						<path d="M2877,1048.9c2.6,0,2.6-4,0-4C2874.4,1044.9,2874.4,1048.9,2877,1048.9L2877,1048.9z" />
					</g>
					<g>
						<path d="M2873.6,1052.5c2.6,0,2.6-4,0-4C2871,1048.5,2871,1052.5,2873.6,1052.5L2873.6,1052.5z" />
					</g>
					<g>
						<path d="M2889.6,1046.9c2.6,0,2.6-4,0-4C2887.1,1042.9,2887,1046.9,2889.6,1046.9L2889.6,1046.9z" />
					</g>
					<g>
						<path d="M2884.3,1047.4c2.6,0,2.6-4,0-4C2881.7,1043.4,2881.7,1047.4,2884.3,1047.4L2884.3,1047.4z" />
					</g>
					<g>
						<path d="M2879.1,1042.9c2.6,0,2.6-4,0-4C2876.6,1038.9,2876.5,1042.9,2879.1,1042.9L2879.1,1042.9z" />
					</g>
					<g>
						<path d="M2882.2,1037.8c2.6,0,2.6-4,0-4C2879.6,1033.8,2879.6,1037.8,2882.2,1037.8L2882.2,1037.8z" />
					</g>
					<g>
						<path d="M2887,1042.2c2.6,0,2.6-4,0-4C2884.4,1038.2,2884.4,1042.2,2887,1042.2L2887,1042.2z" />
					</g>
					<g>
						<path d="M2893,1040.9c2.6,0,2.6-4,0-4C2890.4,1036.9,2890.4,1040.9,2893,1040.9L2893,1040.9z" />
					</g>
					<g>
						<path d="M2891.8,1035.6c2.6,0,2.6-4,0-4C2889.2,1031.6,2889.2,1035.6,2891.8,1035.6L2891.8,1035.6z" />
					</g>
					<g>
						<path d="M2886.2,1032.8c2.6,0,2.6-4,0-4C2883.7,1028.8,2883.7,1032.8,2886.2,1032.8L2886.2,1032.8z" />
					</g>
					<g>
						<path d="M2897.8,1038c2.6,0,2.6-4,0-4C2895.2,1034,2895.2,1038,2897.8,1038L2897.8,1038z" />
					</g>
					<g>
						<path d="M2896.2,1047.4c2.6,0,2.6-4,0-4C2893.7,1043.4,2893.7,1047.4,2896.2,1047.4L2896.2,1047.4z" />
					</g>
					<g>
						<path d="M2901.4,1043.9c2.6,0,2.6-4,0-4C2898.9,1039.9,2898.9,1043.9,2901.4,1043.9L2901.4,1043.9z" />
					</g>
					<g>
						<path d="M2903.1,1034.6c2.6,0,2.6-4,0-4C2900.5,1030.6,2900.5,1034.6,2903.1,1034.6L2903.1,1034.6z" />
					</g>
					<g>
						<path d="M2907.4,1039.8c2.6,0,2.6-4,0-4C2904.9,1035.8,2904.9,1039.8,2907.4,1039.8L2907.4,1039.8z" />
					</g>
					<g>
						<path d="M2910.4,1034.7c2.6,0,2.6-4,0-4C2907.9,1030.7,2907.9,1034.7,2910.4,1034.7L2910.4,1034.7z" />
					</g>
					<g>
						<path d="M2902.6,1050.8c2.6,0,2.6-4,0-4C2900.1,1046.8,2900,1050.8,2902.6,1050.8L2902.6,1050.8z" />
					</g>
					<g>
						<path d="M2920.6,1038.2c2.6,0,2.6-4,0-4C2918,1034.2,2918,1038.2,2920.6,1038.2L2920.6,1038.2z" />
					</g>
					<g>
						<path d="M2927.2,1039c2.6,0,2.6-4,0-4C2924.7,1035,2924.7,1039,2927.2,1039L2927.2,1039z" />
					</g>
					<g>
						<path d="M2924.2,1033.3c2.6,0,2.6-4,0-4C2921.6,1029.3,2921.6,1033.3,2924.2,1033.3L2924.2,1033.3z" />
					</g>
					<g>
						<path d="M2920.7,1028.8c2.6,0,2.6-4,0-4C2918.1,1024.8,2918.1,1028.8,2920.7,1028.8L2920.7,1028.8z" />
					</g>
					<g>
						<path d="M2916.4,1032.9c2.6,0,2.6-4,0-4C2913.8,1028.9,2913.8,1032.9,2916.4,1032.9L2916.4,1032.9z" />
					</g>
					<g>
						<path d="M2929.8,1028.7c2.6,0,2.6-4,0-4C2927.2,1024.7,2927.2,1028.7,2929.8,1028.7L2929.8,1028.7z" />
					</g>
					<g>
						<path d="M2925.6,1021.3c2.6,0,2.6-4,0-4C2923,1017.3,2923,1021.3,2925.6,1021.3L2925.6,1021.3z" />
					</g>
					<g>
						<path d="M2907.2,1029.9c2.6,0,2.6-4,0-4C2904.6,1025.9,2904.6,1029.9,2907.2,1029.9L2907.2,1029.9z" />
					</g>
					<g>
						<path d="M2902.1,1026.7c2.6,0,2.6-4,0-4C2899.6,1022.7,2899.5,1026.7,2902.1,1026.7L2902.1,1026.7z" />
					</g>
					<g>
						<path d="M2895.1,1031.2c2.6,0,2.6-4,0-4C2892.6,1027.2,2892.5,1031.2,2895.1,1031.2L2895.1,1031.2z" />
					</g>
					<g>
						<path d="M2889.9,1027.1c2.6,0,2.6-4,0-4C2887.4,1023.1,2887.4,1027.1,2889.9,1027.1L2889.9,1027.1z" />
					</g>
					<g>
						<path d="M2895.1,1023.9c2.6,0,2.6-4,0-4C2892.5,1019.9,2892.5,1023.9,2895.1,1023.9L2895.1,1023.9z" />
					</g>
					<g>
						<path d="M2899.9,1019c2.6,0,2.6-4,0-4C2897.4,1015,2897.4,1019,2899.9,1019L2899.9,1019z" />
					</g>
					<g>
						<path d="M2904.9,1022.2c2.6,0,2.6-4,0-4C2902.3,1018.2,2902.3,1022.2,2904.9,1022.2L2904.9,1022.2z" />
					</g>
					<g>
						<path d="M2911.6,1025.1c2.6,0,2.6-4,0-4C2909.1,1021.1,2909,1025.1,2911.6,1025.1L2911.6,1025.1z" />
					</g>
					<g>
						<path d="M2917.7,1023.1c2.6,0,2.6-4,0-4C2915.1,1019.1,2915.1,1023.1,2917.7,1023.1L2917.7,1023.1z" />
					</g>
					<g>
						<path d="M2917.4,1017.3c2.6,0,2.6-4,0-4C2914.8,1013.3,2914.8,1017.3,2917.4,1017.3L2917.4,1017.3z" />
					</g>
					<g>
						<path d="M2911.1,1017.1c2.6,0,2.6-4,0-4C2908.5,1013.1,2908.5,1017.1,2911.1,1017.1L2911.1,1017.1z" />
					</g>
					<g>
						<path d="M2904.1,1014.4c2.6,0,2.6-4,0-4C2901.6,1010.4,2901.5,1014.4,2904.1,1014.4L2904.1,1014.4z" />
					</g>
					<g>
						<path d="M2908.2,1009.6c2.6,0,2.6-4,0-4C2905.7,1005.6,2905.7,1009.6,2908.2,1009.6L2908.2,1009.6z" />
					</g>
					<g>
						<path d="M2921.6,1007.6c2.6,0,2.6-4,0-4C2919.1,1003.6,2919,1007.6,2921.6,1007.6L2921.6,1007.6z" />
					</g>
					<g>
						<path d="M2915.4,1010.9c2.6,0,2.6-4,0-4C2912.8,1006.9,2912.8,1010.9,2915.4,1010.9L2915.4,1010.9z" />
					</g>
					<g>
						<path d="M2912.7,1005.6c2.6,0,2.6-4,0-4C2910.1,1001.6,2910.1,1005.6,2912.7,1005.6L2912.7,1005.6z" />
					</g>
					<g>
						<path d="M2923.8,1014.6c2.6,0,2.6-4,0-4C2921.2,1010.6,2921.2,1014.6,2923.8,1014.6L2923.8,1014.6z" />
					</g>
					<g>
						<path d="M2927.5,1010c2.6,0,2.6-4,0-4C2924.9,1006,2924.9,1010,2927.5,1010L2927.5,1010z" />
					</g>
					<g>
						<path d="M2931.1,1016.3c2.6,0,2.6-4,0-4C2928.6,1012.3,2928.5,1016.3,2931.1,1016.3L2931.1,1016.3z" />
					</g>
					<g>
						<path d="M2932.9,1022.2c2.6,0,2.6-4,0-4C2930.4,1018.2,2930.4,1022.2,2932.9,1022.2L2932.9,1022.2z" />
					</g>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M2936.4,1006
								c6.6,1.5-0.6,5.1,1.9,9.1"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3.5"
						strokeMiterlimit="10"
						d="M2855,945c-11-1.7-22,1.7-33,0.8
								c-2.9-0.3-15.8-5.6-18.3-4.1c-4.1,2.4-0.6,3.4-1.9,6.6c-4.3,10.3-4,21.1-10.6,31.4c-7.8,12.1-17.6,17.7-28.7,25.7
								c-8.6,6.2-18.5,19.5-20.2,30.3c-2.1,14.1,7.9,16.1,17.5,21c12.8,6.5,16.6,17.4,20,30.8c0.8,3.1,3.9,38.9,4.5,39.8
								c2.1-1.5,18.7-14.6,18.6-14.6c8.8-4.8,11.3-9.8,16.5-18.2c9.1-14.7,22.1-28.8,41.1-27.8c12.3,0.7,34.8,3.9,43.3,13
								c3.8,4.1,28.7,46.9,31,46.2c4.2-1.2-8.3-29.2-8.9-32.8c-3.1-18.2,10.7-21.9,17.6-34.9c3.2-6,0-7.9,0.8-13.2
								c1.2-8.3,6.5-13,12.6-18.1c8.4-7.2,3.2-6,7.4-13.4c2.3-4.1,13.1-5.8,13.5-7c0.2-1.4-9.1-12.8-10-14.6c-2.2-4.2,0.6-5.1-4.1-7.3
								c-3.9-1.8-11.3,2.7-15.6,2.9c-30.3,1-5.4-26.5,0.1-37.3c2-4,7.2-18.2,0.2-19.5c-2.7-0.5-18.2,15.2-22.1,17c-11,5.3-23.6,8.8-36,8.5
								C2876.9,955.9,2867.8,947,2855,945z"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3.5"
						strokeMiterlimit="10"
						d="M2874.5,938c-16-3.6-30.5-5.1-47-5.2
								c-7.5-0.1-15.4,0.6-22.8-1.3c-1.5-0.4-19.4-15.6-20-14c-1.2,3.2,2.7,35.6,2.6,36.5c-1.9,13.6-7.5,25.8-18.1,34.8
								c-16.9,14.3-47.2,28.1-40.6,56.3c3.2,13.4,16.1,16.6,26.1,23.8c10.6,7.6,10.7,13,12.2,24.7c0.2,1.5,4.9,53.8,5.3,57.4
								c0.1,0.9,15.4-13.5,16.4-14.4c3.5-3.2,5.9-9.1,9.9-11.7c4-2.6,8-1.6,12.3-3.4c18.7-7.7,18-34.2,38.9-41.8
								c22.5-8.2,39.5,1.5,52.6,18.8c5.4,7.1,11.1,14.2,16,21.8c3.8,5.8,9.6,24.9,16.1,28c18,8.4,12.2-32.3,8.8-40.3
								c-3.1-7.3-8.6-12.7-7.8-21.2c1.3-13,12.7-12.1,18.1-20.9c4.4-7.2,0-16,6-24.5c3.9-5.5,10.7-9.3,13.4-15.7c1-2.3-1.1-6.8,0.7-8.4
								c2.6-2.3,8.8,0.9,11.6-1.1c9.7-6.8-2.3-16.6-5.4-22.6c-6-11.6,2.9-20.6-14.2-19.1c-10.7,1-23.2,2.9-14.6-11.8
								c5-8.7,14-13.4,14.4-24.5c0.2-4-4.1-28.8-6.2-29c-0.6-0.1-14,8.8-16.1,10.2c-7,4.7-12.1,12.1-19.2,16.8
								C2908.3,946.1,2891.3,941.8,2874.5,938z"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="4"
						strokeMiterlimit="10"
						d="M2859.2,922.5c-21-3.5-42.2,2.4-62.2-8.1
								c-6.7-3.5-33.1-41.4-36.5-40.2c-4.9,1.7,11.4,32.6,12.5,35.5c5.7,15.3,5,35.7-0.5,51c-4.5,12.7-12.3,20.1-22.7,27.8
								c-14.3,10.6-21.3,18.4-29.5,34c-5.6,10.7-7.6,28.4,0,38.8c8.8,12.3,25.4,12.9,33,27.1c6.4,12.2,7.7,30.1,9,43.5
								c0.4,3.9-2,37-1.8,37.1c1.1-0.4,27.2-16.5,29.2-18c7.2-5.2,11.3-14,19.1-18.7c9.1-5.6,15.4-2.8,20.8-13.1
								c8.1-15.4,9.8-28.8,29.8-31.2c18.9-2.3,34.9,15.9,44.6,30.9c6.1,9.5,13.6,18.1,19.5,27.7c5.8,9.4,7.7,26.1,22.1,16.6
								c8.7-5.7,14.9-27,15.2-36.9c0.4-14.2-8.8-18.8-12.5-30.2c-4.2-13.1,13.3-19.6,16-32.3c1.2-5.9,0-9.7,3.1-15.5
								c3.1-5.8,9-9.2,12.9-14.2c2.9-3.7,0.2-5.6,4.8-8.2c0,0,4.4,8.7,10.6,2.4c2.6-2.7,2.5-10.7,4.5-14.4c3.7-6.8,7-2.8,3.3-9.7
								c-2.9-5.4-10.7-8.2-13.6-15.1c-2.7-6.2,0.6-13.5-1.9-18.8c-5.7-12.4-23.8-3.4-26-4.6c-0.5-0.7,14.9-17.5,15.3-22.2
								c1-10.5-6.1-21.3-5.8-32c0.4-14.1,15.1-30.4-7.7-17.7c-8.7,4.9-17.5,9-25.3,15.3c-8.8,7.2-16.4,16-27.6,19.8
								C2896.2,934.2,2874.7,925.1,2859.2,922.5z"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M2609.2,1884.2
								c11.5-0.3,22.3,1.9,34,0.1c3.2-0.5,29.8-9.3,29.5-9.7c0,0-17.2,21.4-19.2,22.3c-7,3.5-14.1-0.8-21.4-1.8
								c-8.8-1.2-19.4-1.6-28.2,0.6c-2.7,0.7-30.8,12.1-30.8,4.6C2573.2,1901.8,2606.1,1884.3,2609.2,1884.2z"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M2603.5,1876.5
								c14.6-4.3,29.8,3.1,45.1-1c13.2-3.5,23.8-13.5,35.4-18c6.1-2.3,2.3-4.5,7.7,2.7c5.8,7.7,4.5,4.9-0.4,10
								c-4.1,4.3-11.1,5.6-15.3,10.7c-4.4,5.4-6,11.8-10.8,17.1c-11.1,12.1-16.4,10.7-29.8,5.9c-10-3.6-22.7-2.9-32.8-0.2
								c-6.6,1.7-65.9,10-66.4,7.9c0.6,2.6,34.4-18.6,37.2-19.4c5.5-1.6,7.8-1,13-4.4C2592.5,1883.5,2595.9,1878.8,2603.5,1876.5z"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M2604.2,1866.3
								c15.5-4.1,33,3.6,49.3-1.3c6.9-2.1,13-5.7,18-10.7c0.6-0.6,11.1-16.3,12.2-15.9c0.9,0.3,4.9,5.4,6.3,6.5c5.3,4.1,3.8-1,8.8,5
								c2.4,2.9,1.7,7.6,4.4,10.9c1.1,1.4,8.5,5.2,8.4,7c-0.1,2.1-12.8,11.3-14.6,12.5c-6.9,4.8-13.3,6.9-18.9,13.7
								c-12.1,14.9-10.5,23.4-32.2,22.7c-2.9-0.1-6,2.1-8.9,1.3c-2.4-0.7-3.1-4.1-5.5-5c-5.2-2-13.5-3.8-19.1-2.8
								c-7.8,1.3-13.3,5.8-21.8,6.4c-7.3,0.5-14.7,0.1-22-0.1c-6.1-0.1-12.3-0.8-18.3,0.3c-5.5,1-13.9,7.6-18.7,6.6
								c-1.7-0.3-13.9-12.1-14-13.7c-0.3-5.2,11.1-5.7,15.1-6.9c14.2-4,24.5-13.5,36.9-19.7C2580.1,1878,2592.7,1869.3,2604.2,1866.3z"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M2605.2,1859.7
								c13.6-1.4,27.3,1.5,40.8-1.6c7.8-1.8,15.9-5,22.1-10.3c3.7-3.1,9.6-18.5,14.1-19.1c4-0.6,10.6,7.8,14.1,9.7
								c7.6,4.1,7.3,1.3,11.3,8.8c3,5.7,1.4,9.1,6.9,14c2.3,2,14.3,5.3,14.3,5.5c-0.1-2.4-16.5,13-16.9,13.3c-7.4,6.1-17.2,7.9-24.2,14.7
								c-11.6,11.2-11.1,28.9-29.4,30.9c-7.9,0.9-19.5,2.6-27.2-0.2c-5.5-2-3.5-5.9-11-7.2c-5.2-0.9-9.6,1.6-14.4,3
								c-15.6,4.7-31.6,1.1-47.9,2.9c-12.7,1.4-22.9,14.4-35,4.2c-3.2-2.7-3.9-7.2-6.7-10c-2.3-2.3-6.8-2.9-8.3-5.3
								c-0.3-0.4-1.1-11.9-1.4-11.5c2.3-3.8,19.1-3.3,24-5.3c10.2-4.1,16.7-12,26.4-16.4c8.3-3.8,15.3-5.3,23.1-10.5
								C2588,1864.1,2595.4,1860.7,2605.2,1859.7z"
					/>
					<g>
						<path d="M2494.6,1912.9" />
						<path
							d="M2896.3,1732.2c-8.5-14-31.5-15.4-41.7-28c-5.5-6.7-5.2-13.6-16.2-14c-5.5-0.2-9.3,5.9-14.3,1.3c-2.2-2,0.5-5.4-1.5-7.4
									c-1.5-1.4-5.8-2.8-6.9-4.1c-4.7-5.6-1.5-3.9-3.4-12.4c-2.1-9.2-10.1-12.1-17.2-16.8c-6.8-4.5-10.1-8.8-16-14.5
									c-7.8-7.5-14.2-18.1-24-23c-8.9-4.4-14,0.9-22.8-0.7c-12.5-2.3-7.7-9.9-11-18.5c-7.1-19-24.4-13.6-39.8-12.9
									c-11.9,0.6-22.4-3.9-30-13.2c-1-1.2-10.8-18.3-13.5-14.7c-0.8,1.1,13.7,20,15.2,21.4c6.6,6,15.6,9.8,24.4,10.4
									c20.1,1.4,31.4-10.1,39.6,14.6c7.9,24,19.2,8.8,35.9,16.9c14.8,7.2,21.1,26.2,35.1,34.7c5.1,3.1,14.9,4.8,18.3,10.4
									c4.5,7.2-0.2,14.3,6.1,21.7c1,1.1,4.7,0.1,5.9,1.2c2.8,3,0.2,7.3,4.3,10.1c5.8,4,12.2-2.6,18.6-0.4c8.4,2.9,11.2,14.5,19.3,19.3
									c5.5,3.3,11.3,5.9,16.7,9.2c17.3,10.5,17.9,17.6,6.6,34.9c-4.4,6.7-8.3,5.6-13.2,11.4c-5.7,6.9-1.3,7.8-10.2,11.5
									c-6,2.5-13.2,5.3-19.5,7.2c-11.6,3.6-15,5.2-23.9,13.6c-11.9,11.2-28.4,8.7-41.5,17.6c-15.2,10.3-11.2,34.6-30.7,39.9
									c-12,3.3-21.3-1.4-26.7-11.3c-4.3-7.9-4.2-10.2-11.6-15.2c-5.8-3.9-12.2-4.4-15.7-11.3c-5.6-11,5.8-22.7-4.4-32
									c-6-5.6-16.2-6-23.7-6.6c-8.8-0.7-17-3.4-25.8-4.1c-16.6-1.4-34.9,13.5-48.9-2.9c-20-23.5,19.8-26.5,19-44.2
									c-0.2-5.1-5.5-6.7-6.5-10.5c-0.7-2.6,1.4-5.7,0.4-7.9c-4.5-10.1-12.9-7.1-20.2-13.7c-8.5-7.7,0.3-10.3-1-19.1
									c-1.4-9.5-21.5-11-21-7.6c0.1,0.6,14.2,0.2,16.5,3.7c4.2,6.1-2.1,8.4-1.8,14.8c0.4,8.1,5.4,9.5,12,12.8c3.2,1.6,7.7,1.4,10.1,4.6
									c3.4,4.4,0.2,5.6,1.5,9.8c4,12.4,9.2,13.7-2.7,23.6c-11.7,9.6-20.5,22.1-8.7,36.4c12.9,15.6,25.2,6.1,40.4,3.6
									c12.1-2,27.3,3.3,40.1,4c41.3,2.3,6.3,49.5-13.5,59.4c-16.3,8.1-32.4,2.8-49.3,4.6c-16.7,1.8-33.2,14.9-48.3,21.9
									c-10.6,4.9-19.6,11.5-30.5,15.3c-5.6,1.9-29.9,6.8-33,12.2c-4,7,13.1,5.6,3.9,13.2c-2.6,2.1-6.3,1.2-9.3,1.3
									c-10.3,0.5-17.5,0-27.7,2.5c-26.8,6.5-49-6.1-75.6-8.4c-21.3-1.9-25.3,24.5-41.3,33.6c-14.1,8-28.9-0.9-43.8,6.3
									c-14.5,7-27.9,15.8-43.5,20.4c-0.6,0.2-44.3,4.7-42.1,0.3c-3.6,6.5,30.2,5.7,31.2,5.5c8.6-1.3,16.3-5.6,24.6-8.4
									c12.2-4.2,21.5-12.3,32.9-17c13-5.4,26.4,5.2,40.1-1.6c10.5-5.2,14.8-16.6,21.6-25.3c11.3-14.5,18.5-10.3,34.7-7.1
									c15.1,3,34,10,48.9,8.2c16.1-1.9,43.6-14.4,59.2-2c4.8,3.8,2.7,8.5,5.7,12.8c3.4,4.9,11.6,7.2,17.2,8.3
									c12.9,2.5,13.6-4.3,24.6-8.6c8.2-3.2,19.7-1.7,28.7-1.5c5.1,0.1,11.1,1.6,16.2,1.2c6.1-0.6,10.8-5.5,16.8-5.7
									c7.9-0.2,8.5,4.6,14.9,6.7c3.3,1.1,7.9,0.4,11.3,0.4c4.4,0.1,16-1.4,19.3,1.7c8.5,8-15,35-17.9,43.8c-1.9,5.9-1.4,12.8,2.7,17.2
									c1.1,1.2,36.3,11.5,36.6,10.9c2.6-4.2-27.5-8.6-27.9-8.8c-9.1-3.7-9.4-7.2-8.3-15.2c2.1-14.9,15-27,20-41.8
									c3.2-9.4-2-5.8,7.3-12.9c8.9-6.8,9.7-13.8,15.8-22.1c6.2-8.3,16.7-11.1,25-16.7c8.8-5.9,15.5-14.3,23.9-20.8
									c7.4-5.7,17.2-8.6,22.8-16.7c5.8-8.3,6-18.6,13.5-26.5c7.4-8,16.7-8.8,27.1-11.1c10.3-2.4,12.7-4.6,20.1-12.2
									c8.1-8.3,13.2-9.4,24.1-13.5c10.7-4,20-4.7,26.4-13c5.1-6.5,10-8,14.3-15.1C2889.7,1755.1,2900.9,1739.8,2896.3,1732.2z
									M2740.3,1863.8c-14.3,9.6-25,21.7-40,30.6c-10.3,6.2-12,10.5-17.4,19.6c-3.4,5.6-6.1,8.8-11.4,12.7c-3.6,2.6-7.9,2-9.3,7.2
									c-6-6.5-24.2-0.4-31.7-2.8c-3.9-1.2-5.4-5.2-9.4-6.3c-7.4-2.1-10,1.1-16.4,2.9c-8.4,2.4-15.6,1.3-24.5,1.2c-7-0.1-18.3-2-24.9,0
									c-6.5,1.9-10.6,8.9-16.8,10.8c-6.8,2-20.7-2-24.9-7.5c-5.7-7.5-2.6-15.5-15.6-17.6c6.8-5,2.9-11.6-4-13
									c12.3-5.2,25.5-8.2,37.7-13.2c16.3-6.8,34.2-17.5,49.6-26.3c19.9-11.3,33.1-5.9,54.8-8.7c25.7-3.3,36.2-21.4,49.9-40.7
									c0,19.5,17,16.5,26.2,27.8c4.7,5.8,2.9,14,9.8,18.8c3.2,2.2,7.7,2.3,11.4,3.1C2735.9,1862.7,2743.8,1861.5,2740.3,1863.8z"
						/>
					</g>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="4.5"
						strokeMiterlimit="10"
						d="M839.9,501c3.4-3.3,10.5-11.4,10.1-15.6
								c-0.6-7.1-6.3-4.4-7.8-12.1c-0.6-3,7-9.5-0.8-11.6c-2.4-0.7-7.1,7.5-8.2,9c-3.5,4.4-3.3,7.2-8.4,10.5c-4.3,2.8-9.8,2.1-14.1,4.5
								c-2.7,1.5-10.4,6.5-12.2,8.4c-3.7,3.8-2.1,5.2-4.3,10.4c-2.1,5-11.4,21.9-4.9,25.2c5.8,3,22.4-9.6,27.6-11
								c6.6-1.8,10.5,1.6,16.2-4.2C836.8,510.5,836.1,504.7,839.9,501z"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3.5"
						strokeMiterlimit="10"
						d="M818,490.5c5.9-2.4,14.5-7.5,18.2-5.9
								c8.4,3.7,1,6.7-2.8,12.3c-5.2,7.5-4.3,11.1-13.2,14.1c-2.2,0.7-26.5,6.8-24.5,8.8c0.1,0.1,6.5-16.8,7-17.5
								C806.1,496.8,812,493,818,490.5z"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="3"
						strokeMiterlimit="10"
						d="M826.4,495.6c-1.8-1.3-17.8,5.3-17.5,7.9
								C809.7,510.9,832.6,500,826.4,495.6z"
					/>
				</g>
				<g id="cities">
					<ellipse fill="none" stroke="#000000" strokeWidth="3" strokeMiterlimit="10" cx="1407.4" cy="1297.9" rx="8.4" ry="6.3" />
					<path
						d="M1409.5,1289.6c-0.6-3.6-1.4-7.1-2.7-10.5c-0.6-1.5-2.8-1.4-2.9,0.4c-0.3,3.7-1.1,7.2-2.1,10.6c-1.1-0.5-2.1-0.7-3.3-1
								c-1.4-0.4-2.4-0.8-3.2-1.4c0.2-0.1,0.4-0.3,0.5-0.5c1.3-2.5,5.1-2,6.3-5.1c0.6-1.4-1.5-2.5-2.5-1.5c-2.7,2.9-6.3,1.7-9.5,0.5
								c-0.7-0.3-1.3,0-1.6,0.5c-0.3,0-0.7,0-1.1-0.1c-0.4-0.1-0.8-0.1-1.3-0.2c-2.3-0.5-2-1-3.6-2.3c-1.8-1.4-6.7-2.3-6.4,1.4
								c0.3,3.2,4.6,3.5,7.8,3.8c0.9,0.3,1.9,0.4,3,0.4c3.5,1,3.9,4,6.6,6c2.2,1.6,5,1.2,7.3,2.4c0,0,0.1,0,0.1,0
								c-0.5,1.4-0.9,2.8-1.4,4.1c-0.3,0.8,0.3,1.6,1,1.8c1.7,0.7,4.7,1.6,6.7,0.9c1.9,0.1,3.5-0.4,3.7-2.4
								C1411,1295.2,1409.9,1292,1409.5,1289.6z"
					/>
					<polygon
						points="2120.6,1680.8 2122.2,1674.2 2124.4,1674.2 2125,1669.9 2127.2,1669.9 2127.7,1674.2 2129.8,1674.2 2131.2,1680.8 
									"
					/>
					<path
						d="M1854.7,1003.8c-1.1-0.2-3.4-0.5-3.8-1.8c-0.4-1.1,0.7-2.3,2-3.8c4.7-5.3,7.1-8,8.8-8.8c3.9-1.9,5.7-0.8,9-3.5
								c1-0.8,2-1.7,2.7-3.2c1.1-2.6-0.3-4.1,0.8-6.3c1.1-2.1,3.3-2.8,4-3c0.7-0.2,3.5-1.1,4.8,0.2c1,0.9,0.2,2,0.8,5
								c0.3,1.5,0.7,3.7,2.3,5.2c1.3,1.2,2.4,1,4,1.7c2.4,1,1.9,2.4,5.5,6.5c1.6,1.8,2.9,3,4.3,5.5c1.2,2.1,1,2.6,0.8,2.8
								c-0.5,0.6-2.2,0-3.3-0.7c-1.8-1.1-2.1-2.3-4.8-6.3c-1.9-2.8-2.6-3.4-3.5-3.7c-1.6-0.6-2.2,0.4-3.5-0.2c-1.6-0.7-2.3-2.9-3.7-6.8
								c-0.6-1.8-0.9-3-1.7-3.2c-0.7-0.1-1.5,0.5-1.8,1.2c-0.6,1.3,0.3,2.6,1.3,5c1.4,3.2,2.3,5.5,1.7,7.7c-0.6,2-2.6,3.7-4.5,4
								c-1.4,0.2-1.6-0.5-3.2-0.5c-2.7,0-3.3,2.3-7.3,4.5c-1.9,1-3.4,1.4-5.7,2C1857.1,1004.1,1855.4,1003.9,1854.7,1003.8z"
					/>
					<rect x="822.8" y="516.8" transform="matrix(0.9659 -0.2588 0.2588 0.9659 -106.6694 232.1347)" width="11.4" height="9.1" />
					<g>
						<path
							fill="none"
							d="M2050.1,1696.1c1.5-1.3-0.8-3.4-2-3.7c-0.7-0.2-2.1-0.2-2.5,0.4c-0.5,0.7,0,1.3,0.3,2.1
									c0.1,0.2,0.1,0.3,0.1,0.4c0,0,0.1,0.1,0.1,0.1C2046.9,1695.9,2049.1,1697,2050.1,1696.1z"
						/>
						<path
							d="M2051.6,1690.3c-1.6-1.2-3.5-1.2-5.4-1.1c-1,0-1.9,0-2.9,0.1c-0.1-1.8-0.2-3.5-1.7-4.7c-0.4-0.3-0.8-0.5-1.2-0.6
									c-0.2-0.3-0.6-0.4-0.9-0.4c-0.6-0.2-1.2,0-1.6,0.7c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.3,0.6-0.4,1c0,0.2,0,0.4,0,0.6
									c-0.1,0.7-0.1,1.5-0.1,2.3c-0.1,1.8-0.5,3.6-1.1,5.3c-0.2,0.5-0.1,0.9,0.1,1.2c0,0,0,0.1,0,0.1c-0.3,0.8,0.3,1.6,1,1.8
									c1.3,0.4,2.6-0.1,3.6-1c0.4,0.8,1,1.6,1.8,2.3c2.9,2.5,8.4,4.1,11.3,0.9C2056.4,1696.1,2054.2,1692.2,2051.6,1690.3z
									M2045.9,1695.3c0-0.1,0-0.3-0.1-0.4c-0.3-0.8-0.8-1.4-0.3-2.1c0.5-0.6,1.9-0.5,2.5-0.4c1.2,0.3,3.6,2.5,2,3.7
									c-1,0.8-3.2-0.2-4.1-0.8C2046,1695.4,2046,1695.3,2045.9,1695.3z"
						/>
					</g>
				</g>
				<g id="arrows">
					<path
						d="M897.6,120l9.5-13.7l4.6,16.7l-4.4-3.9c0,0-1.1,3.7-2.8,10.7c-1.8,7.5-2.2,7.8-3.8,14.2c-1.6,6.6,0.2,13.1,0.2,13.1
								l-4.3-3.8l0.3,11.4l-4.4-3.7l-0.6,10l-3.2-6.6l-3.6,4.1l-1.9-1.5l3.2-3.9h-8.2l8.5-4.2l-5.2-2.8l9.9-3.7l-3.1-2l4.9-2.4
								c1-1,2-1.5,3.5-5.6c2.1-5.9,2.8-9.5,4-13.4c2.5-7.8,2.3-10.3,2.3-10.3L897.6,120z"
					/>
					<polygon points="1465.2,929.8 1492.6,927.9 1492.3,924.5 1506.4,925.8 1493.1,933.3 1492.8,929.9 1465.8,935.6 	" />
					<polygon points="1869.1,1446.8 1875.2,1440 1869.1,1440 1884.9,1425 1884.4,1445.1 1880.1,1440.9 1872,1449.1 1869.8,1449.1 	" />
					<path
						d="M1663.3,589.9c-1.3-2.1-8.1,0-8.9,0.4c-4.9,2.2-10,7.6-10,7.6l-4.3-3.2l1.9,12.5l11.7-6.1l-5.5-1.6c0,0,1.8-4.1,4.6-4.5
								c1.4-0.2,2.7,0.2,5.1,1c3.9,1.3,4.7,2.3,5.8,1.8c1.4-0.7,0.6-4.5,0.6-4.5S1663.9,590.9,1663.3,589.9z"
					/>
					<path
						d="M2059.2,1598.2c0,0-0.3,3.9-0.7,5.2c-0.6,2.1-3.7,8.3-3.7,8.3l-1.1-2.5l-0.7,5.4l4.5-1l-1.9-0.8c0,0,2.8-3.8,3.6-5.3
								c1-2,2.8-8.4,2.8-8.4L2059.2,1598.2z"
					/>
					<path
						d="M1565.5,922.7l6,13l3.2-3.7c0,0,4.8,0.9,12.2,7.3c7.3,6.3,10.8,9.9,13.2,8.8c0.8-0.4,1.3-1.2,1.7-1.8
								c1.1-2,1.1-4.8-0.3-6.8c-0.2-0.3-1.1-1.5-2.8-2.2c-1.6-0.6-3-0.3-3.8-0.2c-1.7,0.2-2.8-0.3-9-3.2c-8.4-3.9-9.7-5.7-9.7-5.7l3.3-3
								L1565.5,922.7z"
					/>
				</g>
				<g id="rivers">
					<path
						d="M1969.9,1839.1c0.7-2.1,1.1-3.7,0.6-5.2c-0.4-1.1-1.4-2.4-2.4-2.3c-1.1,0.1-1.6,1.9-2,3.3c-0.8,2.7-0.3,4.8,0,6.2
								c0.3,1.1,0.5,2,1,2.1c0.9,0.2,2.1-2.3,2.2-2.6C1969.5,1840.2,1969.7,1839.7,1969.9,1839.1z"
					/>
					<path
						d="M1960.6,1812.3c-0.8-3.5,0.7-7.5-0.5-10.8c-1-2.9-3.8-4.4-5.1-7.2c-1.4-2.9-1-6-2.1-9c-1.9-4.8-3.8-5.9-3-11
								c0.8-5.1,2.6-10,4.7-14.6c4.1-8.7,6-13.5,4-23.1c-0.8-3.9-0.7-5.2,0.9-8.9c1-2.2,3-2.4,2.8-5.2c-0.1-1.7-1.9-2.6-2.2-4.1
								c-0.9-4.3,2.4-7,4.1-10.4c0.2-0.5,4.4-11,0.3-9.3c-0.8,0.3,0.2,5-0.2,6c-0.7,2-2.9,4-4.1,5.9c-1,1.5-3.7,5.5-3.6,7.1
								c0.3,4.6,4.1,3.1,1.9,8.8c-1.7,4.4-2.8,4.1-2.3,9.1c0.4,4,1.7,7.8,1,11.8c-0.8,4.9-3.8,7.5-6.1,11.6c-1.3,2.3-1.2,11-2.8,12.1
								c-2.3,1.6-4.7-3.3-6.9-5.1c-2.4-1.9-6.8-2.9-8.5-5.4c-1.8-2.5-0.4-5.8-1.4-8.6c-1.8-4.7-6.4-6-9.5-9.5c-4.3-4.9-0.7-10.9-5.3-15.1
								c-1.6-1.5-5-4.1-6.7-5.3c-0.7-0.5,0.3-2-0.4-2.5c-3.2-2.4-1.1,4.4-0.8,4.9c1.3,2.4,3.6,2.9,5.4,4.7c3.7,3.5,2.4,2.9,2.7,8.1
								c0.6,9,8.4,8.2,11.9,15.4c1.8,3.7-0.3,7.4,2.9,11c2.2,2.5,5.8,3.8,8.2,6.1c3.1,3.1,3.1,5.6,4.8,9.4c1.6,3.7,4.5,5.8,5.7,9.5
								c0.7,2.2-0.5,4.6,0.5,7c1,2.4,3.7,3.5,4.7,5.6c1.6,3.6-0.2,8,0.7,11.8c0.6,2.4,3.2,8.3,6.3,8.8
								C1968.3,1823,1961.1,1814.4,1960.6,1812.3z"
					/>
					<path
						d="M2005,1725.7c-0.7-1.2-1.8-1.3-4.2-2.5c-0.9-0.5-2.6-1.3-4.3-2.8c-1.1-0.9-2.1-1.8-2.8-3.2c-0.3-0.5-0.4-0.9-1-3.9
								c-0.7-3.5-0.7-3.7-1.1-5.1c-0.8-3.1-0.9-2.1-1.4-4.2c-0.2-0.9-0.4-1.9-1.2-2.5c-0.3-0.2-0.9-0.4-1.1-0.2c-0.3,0.2,0.1,0.9,0.6,2.3
								c0,0,0.3,0.9,0.8,2.7c0.4,1.5,0.6,2.7,0.7,3.3c0.3,2,0.1,2.1,0.4,4c0.2,1.2,0.3,2.4,0.9,3.9c0.7,2.1,1.8,3.5,2,3.8
								c1,1.3,2.2,2.2,4.7,4.1c1.8,1.3,2.9,2,4.4,2.6c1.1,0.4,2.4,0.8,2.9,0.2C2005.7,1727.6,2005.4,1726.4,2005,1725.7z"
					/>
					<path
						d="M984.3,763.5c-1.2-4.7-4.2-9-7.5-12.5c-3.4-3.6-9.4-4.2-12.2-7.2c-2.8-3-0.9-7.5-2.4-11.2c-0.8-1.8-2.5-2.3-3.1-4.3
								c-0.7-2.2,0.2-5.3-0.2-7.6c-1.2-8-10.3-13-17.7-15.2c-8.9-2.7-24.5,1.6-30.9-7c-4.5-6,2.8-15.6,8-18.3c5-2.6,6.2-2.5,8.5-7.5
								c1.5-3.3,2.8-4.9,5.4-7.8c1.4-1.5,6.6-5.7,5.7-7.7c-1.8-3.9-4.7,0.4-5.8,1.8c-2.1,2.5-3.9,9.5-8.3,7c-1.2-0.6-2.7-8.8-5.2-7.7
								c-1.5,0.7,2.2,9.4,2.6,10.7c0.9,3,3.3,3,0.7,5.7c-0.8,0.8-4.7,1.8-5.6,1.1c-2.5-1.9,0.8-10-0.5-13c-1-2.3-3.5-3.3-4.4-5.5
								c-0.6-1.7,0.6-4.6,0.4-5.2c-0.5-1.3-9.5,1-10,2.8c-0.3,1.1,1.3,3.7,1.6,4.6c0.9,3.2,0.6,5.7,0.8,8.9c0.2,5,1.5,7.2,0.5,12
								c-0.7,3.6-1.2,7.3-1,11c1.1,24.7,29.1,11.4,42.4,20.5c2.6,1.8,7.8,6.6,8.6,9.2c1,3.4-2.5,7-1.3,10.1c0.6,1.5,3.5,2,4.4,3.9
								c1.1,2.3-0.1,4.5,0.4,6.8c1,4.8,4,6.2,8,8.4c8.2,4.3,14.4,9,14.3,19.2c0,6.4-2.8,11.2-5.7,16.6c-0.9,1.7-1.5,4.5-2.7,6.1
								c-0.7,0.9-5.7,4.4-5.7,4.4c-0.2,1.1,6.5,4.6,7,3.8c0.2-0.4-0.7-3,0-4.2c1.7-3.2,4.4-5.7,6.2-8.8
								C983.3,780.4,986.4,771.4,984.3,763.5z M908.9,672.9c-0.5-2.6-3.9-8.8-1-11.2c2.3-1.8,3.3,0.4,4.2,2.5c0.9,2.1,0.3,20.1-3.2,18.9
								C907.7,682.7,909.2,674.3,908.9,672.9z"
					/>
					<path
						d="M903.5,641.2c-1.2-1.3,1.3-9.9,0.4-12.6c-1.6-4.6-5.5-6.9-8-10.8c-1.7-2.6-1.4-4.8-2.3-7.4c-1-2.9,0.1-1.8-2.5-3.9
								c-4.4-3.8-9.9-3.9-8.6-11.6c0.8-4.9,3.6-9.1,4-14.5c0.4-5,2.2-5.8,4.2-10.2c2.4-5.2,0.7-7.8,7-9.9c4.7-1.6,8.6-1.8,12-6
								c2.9-3.6,4.4-6.5,8.4-9.3c4-2.8,8.1-5.6,9.7-10.6c1.5-5-0.8-7.8-2.4-12.1c-1.4-3.9,1.5-8.4,0-11.8c-1.6-3.8-12.5-4.2-16.7-2.9
								c-4.2,1.2-7.9,5.8-12.4,6.2c-2.1,0.2-3.4-1-5.3-1.3c-2.7-0.4-5.1,0.2-7.8,0c-3-0.2-6.1-2.6-9-2.4c-4.2,0.3-7.2,5.2-11.4,5.4
								c-3.6,0.2-5.1-2.5-7.6-4.6c-1.8-1.5-4.1-3.7-6.3-4.5c-2.5-0.9-5.5,0.4-7.7-0.3c-6.2-2.2,1-4.3,4.6-4.3c5.7-0.1,7.3,1.8,11.1,6.1
								c7.2,8.1,11.2-2.9,20.3-1.2c8.7,1.7,17.3,5.1,25.6,0.3c5.5-3.2,16.8-7.2,23-2.9c4.2,2.9,1.9,6.6,2.1,10.4c0.2,4.8,3.1,8.6,3.9,13.4
								c2.3,14.6-11,14.3-16.1,23.7c-2.8,5.1-4,7.7-10,10.1c-2.1,0.9-6.7,0.9-8.2,2.4c-2,2-0.7,4.6-1.7,7c-1.2,3-3.9,4.7-4.9,8.1
								c-0.7,2.6-0.6,5.4-1.2,8c-0.8,3.5-4.3,9-1.9,12.5c2.8,4,8.4,1.6,10,7.8c0.6,2.1-0.2,3.9,0.8,6.1c1.1,2.3,4.2,4.1,5.7,6.1
								c3.1,4.1,2.8,7.2,3.5,12.1c0.2,1.7,1.8,5.5,1.1,7.2C908.3,640.6,905.3,643.1,903.5,641.2z"
					/>
					<path
						d="M2764.5,877.6c-2.5-5.5-3.3-11.4-8.7-15c-5.9-3.9-14.6-4.6-21.4-5.1c-6.9-0.5-13.7,0.1-20.6-0.1c-2.9-0.1-7.1,0-9.7-1.3
								c-3-1.5-3.9-4.5-6.5-6.5c-10.1-7.8-27.5,9.3-37.1,13.1c-12.3,4.8-24.6,2.7-37.1,5.8c-5.6,1.4-10.7,3.6-16.6,3.9
								c-7.3,0.3-14.3-2.3-21.4-3.5c-13.6-2.4-28-4.8-37.7-15.6c-19.5-21.7,0.9-50.4-10.6-75c-2.7-5.8-7.4-11.2-8.6-17.6
								c-0.4-2.3,0.1-4.6-0.4-6.9c-0.9-3.9-4.4-6.6-5.5-10.2c-1.7-5.6,2.5-7.2,5.6-11.8c2.5-3.7,2.5-6.9,3.8-11.2
								c2.2-7.2,8.1-10.1,12.5-15.8c4.1-5.3,5.1-12.4,5.9-18.9c0.6-4.9,0.3-8.8-1.8-13.3c-2.2-4.8-10.1-10.1-10.2-15.4
								c-0.1-5.3,5.1-7.3,2.2-13.1c-3-5.9-11.1-12.1-16.4-15.9c-10.8-7.5-18-16.5-18.3-30.6c-0.1-4.1,0.8-7.4,1.5-11.3
								c0.5-2.9-0.1-6.8,0.8-9.5c1.9-5.9,9.3-8,11.7-14c4.2-10.5,4.3-23.9-0.6-34c-2.2-4.5-4.6-11.5-8-15c-3.9-3.9-12.3-3.9-17.5-4.4
								c-10.4-1-21.3,4-31.6,0.9c-5.4-1.6-7.5-7.3-11.7-11c-6-5.2-9.5-7.6-12.9-14.7c-3.2-6.9-9-9.4-5.8-17.6c0.4-1.1,9.3-17.2,5-17.3
								c1.3,0-5.6,13.1-6.2,14.1c-3.7,6.3-3.7,10.7,0.2,17c3.6,5.7,6.5,12.4,11.9,16.7c5.2,4,8.9,9,13.9,13.1c9.9,7.9,25.1,1.2,36.7,1.7
								c7.2,0.3,13.5,2.2,17.3,8.6c3.4,5.6,8.2,12.5,8.8,19.1c0.5,5.7-0.2,13.1-2.1,18.4c-1.9,5.4-6.4,7.4-9.7,11.6
								c-5.7,7.2-5.7,26.8-4.9,35.6c1,12.4,15.2,25.6,24.3,32.8c3.4,2.7,10.6,5.6,10.7,10.6c0.1,3-3.2,5.2-3.2,8.6
								c-0.1,3.5,1.6,5.2,3.6,7.7c6.4,8.1,9.3,14.5,7,25.2c-1,4.4-2.1,8.9-5.3,12.3c-2.4,2.5-5.5,3.8-7.7,6.6c-4.4,5.8-3.1,12.7-6.1,18.7
								c-1.3,2.6-4,4.5-5.5,6.9c-0.7,1-2.3,8.2-2.3,8.2c-1.4,1-7.2-3.8-9-4.2c-4.3-1.2-9.3-0.1-13.7-0.1c-9.6,0-17.9-3.9-27.3-5
								c-3.4-0.4-7.2,0.3-10.6-0.5c-4.7-1-7.5-4.8-11.9-6.5c-8.6-3.3-18.6-0.5-27.5-0.2c-9.2,0.3-20.6-0.2-28.8-4.8c-4-2.3-7-5.7-11.2-7.6
								c-5.3-2.4-11.3-2.9-17-3.7c-10.2-1.3-14.6,0.6-23.3,5.4c-10.3,5.6-18.3,2.8-29.2,2.5c-8.8-0.2-13.6-2.3-20.4-8.5
								c-7.6-6.9-10.2-11.1-20.4-11.4c-9.3-0.2-18.2-3.8-21.1-13.4c-1.2-3.9-0.8-8.3-2.3-12.2c-1.6-4.3-4-5.8-7.1-8.8
								c-3.1-3-4.8-6.9-8-9.7c-2.9-2.5-7-4-10.4-5.8c-8.5-4.4-15.4-9.4-21.9-16.5c-6.9-7.5-15.7-12.5-22.6-20.3c-6.3-7.2-10-15.7-16.1-23
								c-5.3-6.4-6.3-14.4-10.9-21c-5.9-8.4-7.7-18.7-10.6-28.8c-2.7-9.7-5.2-19.6-5.8-29.8c-0.6-9.6,2.6-17,4.9-26.1
								c0.8-3.1,1.5-6,1.5-9.2c0-5.5-2.8-8.7-4.2-13.5c-3.1-10.7,9.9-18.1,5.4-28.8c-1.1-2.6-4.1-9.1-6-11c-2.7-2.8-7.8-4.2-7.6,0.8
								c0.2,4.4,6,5.3,8.5,7.7c3.4,3.4,3,6.4,1.7,10.7c-1.3,4.4-3.3,9-5,13.3c-1.3,3.4-3.6,5.9-4.9,9.2c-2.3,5.6-0.6,5.7-7.4,3.4
								c-3.9-1.3-8-2.6-12.1-3.1c-7.7-0.9-15.7-0.2-23.5-0.7c-10-0.6-19.9-2.3-29.9-2.6c-9.5-0.3-19.9,2.2-29-0.7
								c-8.2-2.6-16.3-4.8-24.8-6.6c-11.1-2.3-22-1.9-33.2-2.9c-9.5-0.8-17.8-5.2-27.2-5.9c-21-1.8-35.7-17.3-51.6-29.5
								c-6.6-5-11-11.4-18-15.8c-4.5-2.8-6.3-4.9-9.5-9c-3.5-4.4-7.8-6.5-11.8-10.2c-7.9-7.2-10.6-16.5-21.8-19.8
								c-4.8-1.4-6.2-0.9-9.3-5.3c-3.2-4.5-5.2-8.9-9.9-12.2c-8.4-6-15.4-11.1-22.8-18.3c-3.3-3.3-6.5-5.6-11.1-6.2
								c-0.1,0-14.5-0.6-13.1-1.9c-4.2,4.1,14.4,5,14.2,5c6.4,1.9,9.4,7.9,14,12.1c5.9,5.5,14.9,12.9,21.9,16.4c-0.6-0.3-1-0.7-1.6-1
								c4.1,2.2,4.5,5.9,6.8,9.3c3.8,5.5,12.3,6.2,17.6,10.5c7.4,5.9,16.6,13.7,22.5,21.1c4,5,5.2,8.4,10.7,11.7c5.5,3.2,8.6,6.8,13,11.2
								c4.1,4.1,9.6,6,13.8,9.8c4.7,4.3,9.5,8.4,14.4,12.7c7.8,6.8,14.8,9.4,24.6,11.3c12.8,2.6,25.3,6.4,38.3,8c15.2,2,30.5,0.4,45.1,5.9
								c3.9,1.5,13.4,3,13.3,6.3c-0.1,2.4-7,9.3-9.2,11.2c-10.7,9.1-31,20.7-30.3,37.4c0.3,8.4,7.5,14.6,14.3,18.4
								c1.4,0.8,15.8,11.5,14.6,12.2c5.7-3.4-15-15-16-15.8c-7-5.2-13.2-13.4-8.4-21.7c3-5.2,6.4-10.9,10.7-15.2
								c6.5-6.5,15-10.9,21.6-17.3c3.4-3.4,5.7-8.7,10-10.9c4.9-2.5,10.8-1,15.8-0.5c9,0.8,18,0.9,27,1.8c8.7,0.8,17.4,0.2,26,1.2
								c4.9,0.5,13,1,16.8,4.8c3.5,3.6,1.6,8.4,2.5,12.6c1.2,5.5,3.7,9.7,4.1,15.6c0.5,6.6,0.5,13.3,1,19.9c0.8,11.4,5,21.8,8,32.7
								c1.5,5.4,2.1,10.5,5.2,15.2c2.7,4,5.1,6.1,6.1,11c1.4,6.7,5.2,10.7,8.7,16.5c4.7,7.6,9.6,15.5,15.7,22.2c5.5,6,12.8,10,19,15.1
								c4.9,4,7.6,10.1,12.7,13.8c7.5,5.6,16,5.6,21.9,13c6.4,8.1,13.7,11.6,15.9,22.5c1.7,8.1,2.2,14.1,10.7,18.2c8.8,4.3,19.2,1,26.6,8
								c7,6.7,11.9,13,21.8,15.4c9.5,2.3,22.9,4.4,32.2,0.4c4.1-1.8,7.2-5,11.5-6.5c6-2,12.5-1.9,18.6-0.6c9.7,2.2,17.7,7.1,26.5,11.3
								c8.8,4.1,19.3,3.8,28.7,3.3c9.4-0.5,18.1-0.4,27.3,2.2c9.4,2.6,17.6,6.4,27.4,7.6c7.6,0.9,15.3,0.1,22.8,1.1c3.6,0.5,6.5,2,9.8,3.5
								c4.1,1.9,9.7,1.7,13.3,3.7c6.4,3.3,6.9,18.8,10,25.1c4.2,8.7,8.1,16.1,8.1,26.2c0,10-3.7,19.5-3.5,29.6
								c0.3,12.2,5.4,22.8,14.8,30.4c7.4,6,16,8.4,25,10.7c9.3,2.3,19.1,5.3,28.8,5c10-0.3,19.8-3,29.8-3.5c12.1-0.7,21.1-1.9,32.3-7.4
								c6.8-3.4,17.3-11.6,25.3-12.2c6.6-0.5,7.7,6,14,8c3.7,1.2,7.6,0.6,11.4,0.7c10.5,0,22.2-0.2,32.5,1.9c4.8,1,6.2,2.4,8.2,6.8
								c0.5,1.1,4,15.1,4.8,15c0.4-0.1,0.2-3.6,1.2-4c1.4-0.5,4,2.1,4.7,1.4C2767.1,882.1,2764.4,877.4,2764.5,877.6z M2130.1,487.8
								c-3.5,0-4.4-25.7,0-25.5C2134.2,462.4,2134,487.8,2130.1,487.8z"
					/>
					<path
						d="M1855.2,2311c3.4,2.2,10.4,0.3,14.7,0.7c7,0.6,14.2,3.3,21.3,4.2c17.5,2.1,25.8-5.5,41.7-9.4c13.6-3.3,27.9,0.6,38.3-10.6
								c4.3-4.6,5.4-10.8,12.7-10.2c4.6,0.4,7.1,6,11.5,6.8c5.3,1,11.8-4,16.9-5.3c5.7-1.4,11.8-2,17.6-1.1c11.9,1.8,23.9,4.3,34.6,8.4
								c7.1,2.8,11,3.3,17.9,8.4c5.2,3.8,9.4,6.9,16,8.3c11.9,2.6,26.4,3.4,38.6,3.6c11.5,0.1,22.4-4.5,33.1-8.7
								c3.9-1.5,29.9-11.5,29.7-14.5c-0.4-5.3-45.9,17.4-51,18.6c-7.9,1.8-18.2,0.2-26.2-0.8c-11.6-1.4-25.1,0-35-7.1
								c-8.7-6.2-17-12.2-27.4-15.5c-5.5-1.7-11.8-1-17.5-2.4c-7.7-1.9-13.9-3.7-22.2-3c-7.4,0.6-14.4,5.2-21.4,5.6
								c-6.7,0.3-10.6-5.9-17.9-5c-9.1,1.1-8.3,7.7-14.2,12.6c-5.7,4.7-12,3.5-19.2,4.2c-16.5,1.6-29.5,11.7-45.9,12.8
								c-4.2,0.3-44-8-42.9-10.1c-0.7,1.3,4.7,3.1,2.9,5.7c-1.3,1.8-7.5-0.4-8.1,1.9C1853.9,2310,1854.4,2310.6,1855.2,2311z"
					/>
					<path
						d="M2209.7,2284.4c0.2-2.6,11.3-5,13.7-5.8c6.5-2.3,12.6-5,17.2-10.4c10.4-12.3,2.8-22.4,1.9-36.2c-0.4-6.4,1.2-12.7,2.6-18.9
								c1.2-5.1,1.2-12.4,3.9-17c2.7-4.5,9.3-8.8,13-12.3c1.5-1.5,2.2-3,4.3-3.7c3.7-1.3,7.4,1.6,11-0.3c4.3-2.3,6.8-8.6,11.4-11.4
								c2.7-1.6,6.7-2.1,8.9-4.3c0.8-0.8,1.1-3.1,1.8-3.7c2.3-1.7,4.1-0.6,6.5-1.7c9.5-4.4,6.7-21.9,9.6-29.6c3.4-9.2,14.9-13.9,22.1-19.5
								c4.3-3.3,7-8.3,11.3-11.5c4.6-3.4,10.8-5.2,16-7.7c4-1.9,7.7-3.7,10.8-6.9c6.6-6.8,9.7-10.4,19.6-10c7.6,0.3,14.1-3.6,21-6.8
								c2-0.9,18.3-6.7,18-7c0.7,0.7-22.3,13.7-24.5,14.8c-5.7,2.8-10.7,3-16.8,2.5c-7.8-0.6-10.6,4.1-16.6,8.6
								c-10.6,7.8-22.5,11.5-31.3,21.5c-7.1,8.2-19.7,10.8-23.9,21.6c-3.5,9.1-0.3,23-9,30c-1.9,1.6-4.6,1.7-6.6,3.2
								c-2.2,1.7-3.2,5.4-5.2,6.8c-2.5,1.7-4.8,0.6-7.6,1.6c-3.2,1.1-5.3,4.3-7.2,6.7c-4.4,5.6-7.2,5.7-13.9,6.7c-6,0.8-10.5,6.1-14.9,10
								c-4.1,3.7-4.3,7-5.3,12.6c-1,5.3-3.7,10.1-4.4,15.3c-2.2,15.5,11.8,32.2-0.1,46.1c-5.1,6-12.9,11.4-20.4,13.6
								c-3.8,1.1-7.8,1.2-11.2,3.1C2213.4,2285.6,2209.3,2291.2,2209.7,2284.4z"
					/>
					<path
						d="M1169.6,957.9c11.8-12.4,15-27.6,25-41.2c4.6-6.3,11.8-7.9,17.3-13.7c5.4-5.7,8.5-11.1,15.5-15c5.7-3.2,9.2-3.2,14.2-8
								c4.3-4.1,7.9-10.3,13.3-13.2c2.9-1.6,5.9-1.4,8.8-2.7c6.7-3.1,7.1-6.3,11.7-11c5.3-5.5,12.3-5.7,19.8-8.7
								c11-4.4,21.4-10.1,31.7-16.1c11.1-6.5,19.6-12.4,24.3-25c3.6-9.6,1.2-18.6,3-28.2c1.8-9.6,8-15.7,7-25.6c-1.3-13.1-8-14.8,0-26.8
								c3.6-5.4,4.6-3.6,4-10.5c-0.6-6.6-5.8-5.8-3-14.2c3.1-9.2,2.7-15.9,3.5-25.7c0.6-7.8-2.5-39.7,10.2-34c-6.4,5.3-5.9,14.8-6.2,22.3
								c-0.3,8.1-0.8,16-1,24.2c-0.1,6.5-2.2,10-3.4,15.5c-1.2,5.8,0.8,4.3,2.8,10c4.2,12.5-8.3,14.7-7.2,27.2c0.4,4.7,3.6,8.1,3.9,12.9
								c0.3,5.4-3,12.4-4.3,17.5c-2.3,9.4-2.5,17.9-3.8,27.3c-0.9,6.2-7.2,15.6,1.8,14.5c0.3,0,11.5-7.7,11.7-7.9c3.1-2.4,4.5-4.4,7.6-8
								c5-5.9,12.6-8.6,18.8-13.2c3.6-2.7,8.3-5.8,11.2-9.3c3.1-3.6,3-8.1,7.6-10.7c2.8-1.6,6.4-0.5,9.1-1.9c3.8-1.9,5.5-6.6,9-9
								c3.7-2.5,7.8-4.4,10.8-6.5c1.8-1.3,6-5.1,8.1-7.1c6.5-6.1,14.3-11.6,20.1-18.4c6.5-7.7,11.9-11.8,19.1-18.4
								c6.3-5.8,12.2-16,14.7-24.2c1.4-4.6,3.1-17.7,9.7-15.5c-8.7,31.7-32.2,52.8-56.2,73.6c-3.6,3.1-8.5,6.6-11.5,10
								c-1.3,1.5,0.1,3.9-2,5.4c-3.2,2.4-7.6,2.1-11.2,4.6c-4,2.7-5.7,6.5-10.4,8.9c-3.5,1.7-5.4,0-8.8,2.8c-2.6,2.2-2.9,6.3-5.1,8.8
								c-3.1,3.6-8.7,7.8-12.5,10.6c-12.4,9.1-22.3,19.2-35,25.4c-12.3,6-24,14.5-35.6,21.9c-9.3,5.9-18.5,12.6-28.9,16.4
								c-5.8,2.1-13.3,2.1-18.1,6.2c-3,2.6-3.6,6.9-6.5,9.6c-4.3,3.9-9.9,3.5-14.7,6c-7.5,4-12.2,11.9-19.2,17.2
								c-3.7,2.8-7.5,5.5-11.5,7.8c-1.1,0.6-5.9,2.9-6.8,3.5c-6.1,4.7-12,10.9-19,16.4c-14.7,11.8-14.2,38.2-32.4,47.5
								C1170.2,960.6,1169.9,959.2,1169.6,957.9z"
					/>
					<path
						d="M1373.2,624.4c5.5-12.7,12.4-24.6,15.3-38.1c3-14.5,2.8-28.3,15.6-37.5c3.3-2.4,6.8-3.7,9.3-7.2c2.1-2.9,2.1-8,4.3-10.3
								c5.7-6,12.9-1.7,19.7-3.3c4.9-1.1,14.1-4.2,18.3-7.2c8-5.9,2.6-16.2,8.6-22.5c5-5.3,13.4-3.5,18.3-10.2c5.1-7,5.8-16.9,11.4-24.2
								c3.5-4.6,8.7-10.2,15-10.1c6.6,0.1,10.8,6.4,17.8,5.2c4.3-0.8,6.7-5.4,10.4-6.4c3.4-0.9,3.1,0.1,6.3,0.4c4.9,0.5,5.7,1,10.8-0.2
								c4.4-1,5.6-2.7,10.5-1.7c9,1.8,10.6,5.6,19.9,1.6c2.3-1,25.3-10,25.6-3c0,0.9-18.5,2.6-20.7,3.5c-7.4,3.1-11.5,5.9-20.2,3.4
								c-8.6-2.5-7.1-3.3-15.6-0.5c-7.5,2.5-8.2-2-14.4-1.2c-7.9,1-10.6,9.8-20.2,7.2c-5.4-1.5-7.2-5.5-13.4-4c-4.7,1.2-8.2,5.6-10.6,9.5
								c-4.6,7.4-5.1,16.8-10.5,23.6c-5.4,6.8-13.4,4.4-17.4,11.4c-3.2,5.5-1.5,12.7-5.8,17.8c-3.3,4-6.8,3.2-10.9,5.3
								c-2.8,1.4-5,4.2-8,5.3c-6.4,2.4-14.3-1.6-19.9,2.9c-3.8,3-1.8,7.3-4.2,10.1c-2.7,3-7.8,3.6-11.1,6.1
								c-14.9,11.3-11.1,28.7-16.1,44.4c-2.1,6.8-7,11.7-9.6,18.2C1379.4,618.7,1381.7,625.7,1373.2,624.4z"
					/>
					<path
						d="M1520.9,649.1c5.2-2.3,4.5-5.4,5.9-10c1.8-5.6,5-9.4,10.3-11.9c5.3-2.5,11.2-3.2,16.5-5.5c4.6-1.9,9.2-5.9,13.9-7.4
								c4.6-1.5,9.7-1,14.4-2.2c4-1,5.9-1.5,10.3-1.6c5.5,0,6.8,0.2,11.1-2.6c0.5-0.3,11.1-12.2,12.9-9.8c0.2,0.3-13.7,14.2-16.2,15
								c-4.2,1.3-7.7-0.8-11.8-0.5c-2.4,0.2-4.8,1.7-7.1,2.3c-7.7,2-12.4,1.3-19.6,5.8c-7.8,4.9-16.6,5.7-24.5,9.9
								c-4.6,2.4-12.2,10.8-7,13.6c1.7,0.9,8.2-0.5,10.8-0.2c3.3,0.4,6.6,1.8,9.9,1.9c4.2,0.1,8-2.2,12.1-2.8c5-0.6,7.2,1,11.4,2.7
								c2.2,0.9,19.4,0.2,19.2-0.3c1.6,4.8-19.1,3.2-20.6,2.7c-4.6-1.5-6.5-3.1-11.9-2.3c-3.5,0.5-6.7,2.1-10.3,2.5
								c-7.1,0.8-14.6-3.4-21.7-1.4C1525.6,648.3,1523.7,652.4,1520.9,649.1z"
					/>
					<path
						d="M1218,930.2c-2.4,3.6-5,5.9-9.6,6.3c-4.6,0.4-7.4-3-9.7-6.5c-1.4-2.2,2-4.2,3.5-2c1,1.5,2,3.1,3.5,3.9
								c-0.2-1.7-0.6-3.4-0.2-5.2c0.6-2.5,4.4-1.4,3.9,1.1c-0.3,1.4,0,3,0.3,4.5c2.2-0.6,3.5-2,4.9-4C1216,926,1219.4,928,1218,930.2z"
					/>
					<path
						d="M1249.4,934.1c-0.7,2.9-3.9,5.6-6.9,6.8c0,0,0,0,0,0c-0.4,0.2-0.8,0.3-1.2,0.4c-0.1,0-0.1,0-0.2,0c-4.2,0.9-7.4-1.6-10-4.8
								c-1.6-2,1.2-4.8,2.8-2.8c1.5,1.8,3.2,4,5.8,3.7c0.1-0.9-0.2-2-0.4-2.8c-0.3-1.4-0.4-2.6-0.1-4c0.5-2.5,4.3-1.5,3.9,1.1
								c-0.2,1.3,0.3,2.7,0.5,4c1-0.8,1.8-1.7,2-2.6C1246.2,930.5,1250,931.6,1249.4,934.1z"
					/>
					<path
						d="M1277.3,933.7c-0.7,3.9-4.4,7.5-8.5,7.4c-3.5,0-7.8-3.5-9-6.7c-0.9-2.4,3-3.5,3.9-1.1c0.5,1.2,2.7,2.9,4.2,3.5
								c0-0.7-0.2-1.4-0.4-2.1c-0.2-1-0.4-2.1-0.4-3.1c0-2.6,4-2.6,4,0c0,1.5,0.3,2.8,0.5,4.2c0.9-0.9,1.6-2.1,1.8-3.3
								C1274,930.1,1277.8,931.2,1277.3,933.7z"
					/>
					<path
						d="M1217.9,966.5c-1.3,1.4-2.6,2.7-3.7,4.3c-0.5,0.7-0.9,1.5-1.6,2c-1.3,0.9-2.4,0.5-3.3-0.2c-3.9,1.9-7.7-1.6-9.4-5.5
								c-1-2.3,2.4-4.4,3.4-2c0.6,1.3,2.2,4.8,4.1,4c-0.3-2.1,0.1-4.4,1.2-6.1c1.4-2.2,4.8-0.2,3.4,2c-0.5,0.8-0.8,2-0.8,3c0,0,0,0,0,0
								c1.1-1.5,2.5-2.8,3.7-4.2C1216.8,961.8,1219.6,964.6,1217.9,966.5z"
					/>
					<path
						d="M1243.4,980.5c-1.6,2-2.8,3.4-4.7,4.1c-0.5,0.9-1.6,1.5-2.7,0.8c-0.2-0.1-0.4-0.2-0.5-0.4c-3.2-0.1-5.8-0.8-8.2-3
								c-2-1.7,0.9-4.5,2.8-2.8c1.1,0.9,2.2,1.4,3.4,1.6c-0.1-2,0.5-4,1.5-5.7c1.3-2.2,4.8-0.2,3.4,2c-0.4,0.8-1.2,2.4-1,3.6
								c1.4-0.5,2.1-1.8,3.3-3.2C1242.2,975.7,1245.1,978.6,1243.4,980.5z"
					/>
					<path
						d="M1268.8,972.3c-1.4,1.5-2.1,3.6-3.7,5c-0.6,0.5-1.2,0.9-2,1c-0.8,0.1-1.2-0.3-1.7-0.6c-0.3,0.1-0.7,0.2-1.1,0.2
								c-0.3,0.4-1,0.8-1.4,1c-0.9,0.4-1.8,0.5-2.8,0.2c-2.2-0.6-4.1-2.6-5.2-4.5c-1.3-2.2,2.2-4.2,3.5-2c0.6,1,2,2.9,3.2,2.4
								c0.1,0,0.2-0.1,0.3-0.2c-0.2-0.7-0.2-1.4-0.1-2.2c0.2-1.4,0.8-2.7,1.3-4.1c0.8-2.4,4.7-1.4,3.9,1.1c-0.5,1.4-1.1,2.5-1.1,3.8
								c0.4,0.2,0.7,0.5,1.1,0.7c1.1-1,2.3-3.8,3-4.6C1267.7,967.6,1270.6,970.4,1268.8,972.3z"
					/>
					<path
						d="M1287.9,976.3c-2.3,2.2-3.5,5.1-6.8,6c-3.2,0.8-5.3-1.6-7.9-2.9c-2.3-1.2-0.3-4.6,2-3.5c0.8,0.4,1.7,1.2,2.7,1.8
								c-0.6-1.7-0.8-3.4-0.8-5.2c0-2.6,4-2.6,4,0c0,1.6,0.2,3.2,1,4.7c1.1-1.1,1.9-2.7,3-3.7C1286.9,971.7,1289.8,974.5,1287.9,976.3z"
					/>
					<path
						d="M1248.3,947c0.2,0.1,0.3,0.1,0.5,0.3c0.1,0,0.2-0.1,0.3-0.1c0.8-0.4,0.6-1.4,1.3-0.8c-1.9-1.8,0.9-4.6,2.8-2.8
								c2.7,2.5-0.5,6.9-3.4,7.6c-4.3,1-8.8-2.9-11.7-5.6c-1.9-1.8,1-4.6,2.8-2.8c0.8,0.7,1.6,1.4,2.5,2.1c0.4-1.3,1.1-2.5,1.7-3.6
								c1.4-2.2,4.8-0.2,3.4,2c-0.6,0.9-1.2,1.9-1.4,3c0,0.1,0,0.2,0,0.3C1247.4,946.6,1248,946.8,1248.3,947z"
					/>
					<path
						d="M1183.2,953.2c3.6-4.4,9.7-3.1,14.4-5.1c6.5-2.8,10.8-10,18.7-8.6c12.2,2.1,19.6,15.6,31.6,16.7c7.8,0.7,5.4-5.2,9.9-6.9
								c5.5-2.1,4.8,4.6,9.7,5.8c4.7,1.2,7-3.6,12.1,0.4c3.1,2.4,4.3,6.9,7.2,9.6c4,3.8,11.3,5.1,14.5,9.6c3.5,5.1-0.6,11.9,6.6,14.7
								c7.5,2.9,14-3,19.6-6.3c7.4-4.2,13-3.2,21.3-3.2c7,0,12.7-1.2,18.4-5.3c5.1-3.7,9-10.3,15.9-10.4c2.3-0.1,5,2.3,7.6,2.5
								c3.4,0.2,7.4-1.6,10.7-2.2c4.8-0.9,9.6-2.2,14.4-2.9c12-1.8,11.1,0.8,16.4,9c4.6,7.1,10.6,6.9,18.1,6.7c7.2-0.2,11.7,2.8,18.2,3.5
								c3.1,0.3,20.1-6.6,20.3-6.3c1.7,2.7-15.6,8.8-17,8.9c-6.9,0.5-12.3-3.8-19.6-3.6c-7.5,0.2-15.1,2.7-20.7-4
								c-5.1-6.1-1.1-12.2-10.9-12.5c-5.5-0.2-16,3.1-21.6,4.4c-3.3,0.8-6.9,2.7-10.3,2.3c-2.3-0.3-4.2-2.7-6.3-2.7
								c-6.1,0-11.8,8.9-16.7,11.9c-7.3,4.4-13.4,3-21.3,3c-12.1,0.1-20.7,11.5-32.8,10.9c-8.4-0.4-9.9-4.3-12.4-11.1
								c-2.6-7.2-4.1-7.7-10.8-11.7c-1.6-1-3.7-1.2-5.2-2.5c-2.8-2.5-2.7-7.1-5.1-9.4c-4.9-4.7-9-0.3-13.8,2.9c-3.7,2.5-37.9,18.2-39,11.5
								c-0.2-1.3,21.8-6,24.3-6.8c3.4-1.1,9.4-2.6,11.2-6.3c0.4-0.9,2.7-7.1-0.5-7.2c-2.7-0.1-5.4,8-11,7.8c-2.7-0.1-4.6-3.5-7.8-3.5
								c-3.1,0-3.6,1.9-6.3,3.1c-6.5,2.7-7.7,1.6-14.8,0.2c-2.2-0.4-17.2,0.8-17.3-0.8c-0.7-6.4,19.8-1.7,21.5-1.4c7,1.2,13.5-5,4.3-9.1
								c-4.2-1.9-15.2-0.2-19.5,1.6c-1.7,0.7-20,14.7-21.2,8.8c-0.3-1.4,15.6-9,17.6-10c3.8-2,17.4-0.9,13.1-5.6
								c-5.5-5.9-14.1,3.7-19.1,6.4c-2.9,1.6-8.4,5.2-11.7,5.1C1187.9,955.1,1181,955.7,1183.2,953.2z"
					/>
					<path
						d="M2149.4,1906.1c-1.1-1.8-6.8,2.7-7.8,3.3c-4.5,2.8-8.8,2.8-13.7,4.4c-10.9,3.5-14.2,16.3-25.3,19.7
								c-5.4,1.7-10.9,1.6-16.1,4.2c-6,3-10.7,7.5-17.4,9.1c-6.8,1.6-12,1.5-17.8,5.9c-3.9,3-5.8,3.5-10.7,4.7c-5.2,1.3-9.1,3.6-12,8.1
								c-3.9,5.9-4.3,4.9-11.1,5c-1.2,0-2.7,1.2-3.7,1.1c-3.1-0.2-2.8-1.9-5.4-2.7c-6.2-2-10.7,4.5-14.2,8.4c-7.2,8-18.4,16.6-29.7,11.8
								c-10.5-4.5-22.2-15.7-28.2-25.3c-2.7-4.3-5.3-7.9-8.8-11.5c-3.6-3.8-3.7-5,1.2-7.7c10-5.5,22.2-8.1,32.7-12.8
								c24.9-11,50.4-25.3,65.9-48.5c14-21,19.7-46.2,25.9-70.3c7.6-29.8,1.2-60.4-0.3-90.5c-0.4-9,0.7-13.1,6.3-20.1
								c2.1-2.7,4.4-5.4,6-8.4c1.3-2.2,1.9-5.5,4-6.7c2.1-1.2,4.5,0.2,6.8-1.8c2-1.8,3-4.9,2.2-7.5c-1.2-3.9-4.3-5-4.9-9.6
								c-0.5-3.5,0.3-7.1,0.3-10.6c0-6.7-1.2-13.6-2.2-20.2c-0.5-3.3-2.2-5.1-3.3-7.9c-1.1-2.8-0.9-6.5-1.8-9.4c-0.6-1.7-4.1-13.3-7.2-9.7
								c-2.2,2.6,2,5.8,3,7.8c1.7,3.2,1.4,6,1.8,9.4c-12.2-3.7-16.3-15.3-17.9-27.1c3.9,0.6,10.1,11.9,11.8,7.1c1.4-4.1-8.4-9.9-11.2-11.8
								c-5.9-3.9-9.9-5.1-17.2-5.2c-10.5-0.1-18.1-5.4-27.9-8.5c-8.8-2.8-17.1-4.7-24.4-10.6c-5.6-4.5-11.6-7.9-17-12.4
								c-5-4.1-8.5-6.2-10.8-12.3c-2.7-7.4-8.1-13-10.9-20.3c-2.2-5.7-5.6-9-8.8-14.3c-3-5.1-3.2-10.6-5.5-16c-2.1-5-6-9.2-7.7-14.2
								c-1.1-3.1-1.6-6.4-2.8-9.5c-1.3-3.5-1.4-4.9-0.8-8.8c2.5-17-8.6-26.2-18.1-38.3c-8.7-11-14.5-26.6-17.4-40.2
								c-3.4-16-5.5-31.2,0.7-46.8c2.3-5.9,5.5-11.4,9.2-16.5c4.9-6.8,6.3-10.3,6.3-18.7c-0.1-14.5-1-30.6-15.2-38.2
								c-5.2-2.8-11.4-3.4-16.1-6.9c-5.2-3.8-9.7-12.6-4.5-18.2c3.1-3.4,8.8-5.5,12.6-8.4c4.7-3.7,9.5-7.5,13.6-11.9
								c4.8-5.2,5.5-7.6,3.6-14.2c-1.6-5.4-3.6-13.6-7.7-17.9c-5-5.4-10.3-2.7-14.8,1.1c-5.7,4.7-12.1,7.8-19.4,9.3
								c-9.7,1.9-16.6,0.7-22.8-7.1c-5.5-6.9-6.8-13.7-3.2-21.8c4.6-10.3,14.1-19,15.4-30.8c1.7-14.7-12.5-11.3-21.4-14.7
								c-3.4-1.3-6-4.1-9.4-5.6c-6.6-2.9-13.2-4.9-19-9.5c-6-4.7-10.1-11.3-16.1-16c-3.8-3-7.9-5.2-12.2-7.3c-10.8-5-28.2-10.9-33.6-22.1
								c-2.7-5.5-0.7-10.7-2.2-16.2c-1.2-4.2-5.5-7.5-6.5-11.8c-0.6-2.5,0.6-4.2,0.6-6.6c0.1-2.8-1-4.9-1.6-7.5c-2-9.1,4.3-13.1,5.6-21.9
								c0.9-6,1.3-11.4,6.3-15.8c6.1-5.4,11.1-8.4,12.2-17.3c0.3-2.7-0.4-5,1.4-7.1c1.5-1.9,4.9-2.4,6.6-4.2c6.7-6.8-1.5-12.9,0.8-20.4
								c1.6-5.2,6.7-7.8,10.8-10.9c5.7-4.3,10.5-8.1,14.1-14.3c1.6-2.8,3.3-5.7,6-7.6c2.8-2,6-1.8,8.4-4.4c4.4-4.9,1.6-13.2,5.2-18.6
								c1.7-2.6,3.7-3.2,4.1-6.6c0.2-1.8-0.6-3.7-0.5-5.5c0.4-4.8,3.2-9.4,4.6-13.9c2.5-7.6,1.3-12.4,0.4-19.9c-0.9-7,3.8-11.4,4.9-17.7
								c0.7-4.2-1.2-9.3-1.8-13.3c-0.8-4.8-3.9-10.5-3.7-15.2c0.4-9.7,5.9-19.8,7.2-29.5c1.4-10.6-4.6-21.5-4-32.4c0.1-2.6,0.1-4.9,0-7.4
								c-0.3-4.6,0.8-6,2.3-10.2c2.2-6.1-0.7-9.4-3.7-14.3c-6.1-10.1-2.3-20.3-5-31c-1.7-6.7-6.4-11.5-7.8-18.3
								c-1.7-8.5-3.4-16.2-8.2-23.7c-1.6-2.5-4.9-9.6-7.8-9.8c-5.4-0.4,3.9,9,5.1,11c2.4,4,4.2,9.2,5.7,13.6c1.7,4.9,1.1,10.1,2.9,14.9
								c1.7,4.5,5.2,8.1,6.6,12.7c1.9,6.4,1.5,14.4,1.7,21c0.1,4-0.7,7.8-1,11.8c-0.6,8.3,5.1,13.6,5.8,21.4c0.5,6.2-1.1,12.4-0.8,18.6
								c0.3,5,2.3,10,3.2,14.9c0.9,5.4,1.3,8.5-0.4,13.9c-0.5,1.7-2.7,15.7-2.2,15.5c-1.1,0.3-4-5.7-5.3-6.5c-2.7-1.6-5.4-1.2-8.2-0.3
								c-4.9,1.7-8.3,6.1-13.3,1.7c-4.7-4.2-5.9-11.1-13.8-5.8c-2.3,1.6-2.9,4.4-5.8,4.9c-3.6,0.6-5.8-4.1-9.4-4.8
								c-3.6-0.6-6.9,1.9-10.7,0.7c-2.1-0.7-3.6-2.4-5.3-3.8c-4.7-3.8-8.9-5.9-15.1-5.2c-4.6,0.5-9.3,2.2-13.6,4
								c-5.6,2.4-12.4,1.8-17.7,4.8c-5.1,2.9-6.6,6-12.8,7.2c-3.9,0.7-9.7,1.1-13.3-0.6c-5.6-2.7-5.2-9.5-7.9-14.2
								c-1.4-2.5-3.3-4.8-5.8-6.2c-2.3-1.3-7.8-0.6-9.2-2.6c-1-1.5,2.3-6.3-1.2-6.4c-2.8-0.1-0.3,4.1-1.9,5.6c-4.1,3.8-3-3.7-3.8-4.3
								c-2.8-2-3.2,2.9-2.7,4.4c1.4,4.8,7,2,11.5,3.6c3.7,1.3,9,4.4,11.3,7.7c2.2,3.2,0.9,6.8,2.6,10c1,1.9,7.1,4.1,7.3,5.3
								c1.3,6.9-11.7,4-14.8,2.9c-8.9-3.3-14.1-2.5-19.3,5.5c-2.5,3.9-4.4,7.4-3.1,12c0.2,0.9,3.9,8.2,5.3,7.1c-0.1,0.1-3.7-8.7-3.7-10
								c0.1-4.4,3-7.9,6.1-10.8c7.6-7,13.8,2.2,22.3,1.6c4.6-0.4,6.3-2.9,9.9-4.9c3.5-2,6.9-1.3,10.8-1.9c4.7-0.7,6.8-2.4,10.3-4.9
								c4-2.8,8.5-3.9,13.1-5.3c5.2-1.6,10-4.1,15.3-5.3c9.7-2.1,10.9-1.8,18,3.9c2.4,2,5.1,3.8,8.2,4.4c3.6,0.7,7.8-1.3,11.3-0.1
								c4.2,1.5,4,6.5,9.5,4.5c3.9-1.4,5.2-7.5,10.4-6.6c4.7,0.9,3.6,6.5,8,8.4c3.3,1.4,6.6-0.1,9.6-1.3c3.4-1.3,6.7-3.2,10.3-1
								c4.3,2.6,2.7,6.3,2.4,10.4c-0.6,8.7,5,15,5.3,23.6c0.1,4-0.9,6.2-2.4,9.8c-2,4.7-2,8.8-1,13.8c1.4,6.9,2.1,12.4-1.2,19.1
								c-1.6,3.2-3.8,5.3-4.2,9.1c-0.5,4.6,1.4,7.7-1.2,11.7c-1.6,2.6-4,3.9-4.8,6.9c-0.9,3.2-0.1,6.9-1.3,10.3c-1.4,4.1-1.8,3.3-5.5,5
								c-4.4,2.1-7.4,5.7-10.1,9.8c-3.9,5.9-7.1,8.9-12.9,13.1c-6.5,4.7-14.9,10.6-13.3,19.7c0.7,3.9,4.1,7,2.3,11.1
								c-1.7,3.9-6.7,4.6-8.8,8.4c-3,5.4-1.1,11.2-5.4,16.4c-4.5,5.6-10.3,7.2-12.5,14.3c-1.9,5.9-3.1,10.9-5.6,16.5
								c-2,4.8-2.9,9.1-2.2,14.3c0.6,4.3,2.4,5.1-1.7,4.4c-3.6-0.6-6.3-3.6-10.4-3.6c-4.4,0-8.7,2.2-13,3c-8.2,1.4-13.6-3.2-20.1-7.5
								c-6.7-4.4-14.9-4.7-22.4-6.9c-7.5-2.2-11.7-7.6-17.3-12.6c-5.3-4.7-12.9-3.5-17.5-7.9c-1.9-1.8-5.1-5.2-6.5-7.5
								c-1.7-3-1.3-6.9-2.4-10.2c-1.4-4-4.7-6.2-6.2-9.8c-1.9-4.4-0.4-8.5-4.1-12.7c-4.1-4.8-10.3-6.5-15.3-9.9c-2.9-1.9-5-4.3-6.7-7.5
								c-2.6-4.9-1.3-7.9-1.4-12.9c-0.2-9-14.5-17.2-15.5-4.5c-0.4,4.7,2.2,7,5.5,9.6c3.7,2.9,5.9,5.2,8.5,9.3
								c4.7,7.1,10.1,10.6,17.1,15.2c4.9,3.2,7,3.7,8.2,9.7c1,4.6,2,7.5,4.4,11.5c1.9,3.1,2,3.7,2.5,7.2c0.8,5.7,3.2,7.3,6.2,11.9
								c3.2,4.8-0.3,5.1-5.9,5.5c-5.8,0.5-11.7,0.1-17.5,0.7c-6.2,0.6-12.3,2.1-18.5,2.8c-4.7,0.5-6,0.3-10.1-2.4
								c-0.8-0.5-9.2-6.8-10.3-4.3c-0.4,0.9,10.2,8.8,11.8,9.6c4.1,1.9,9.3,0.2,13.7-0.2c4.4-0.4,8.8-1.4,13.2-1.9
								c5.3-0.6,10.6,0.7,15.9-0.1c8.9-1.4,15.8-6.7,24.7-1.2c9.5,5.9,15.6,14,26.9,17.2c5.4,1.5,10.6,1,15.5,3.9
								c5.9,3.5,10.6,7.6,17.6,8.9c9.3,1.6,23.9-7.8,30.4,2.3c1.4,2.1,1.1,4.8,1.9,7.1c1.1,3.2,3.3,5.5,5,8.3c2.3,3.8,2.1,6.9,2.5,11.3
								c1.5,14.2,12.7,21.8,25.1,27.1c12.2,5.2,21.9,11.3,31.7,20.6c3.7,3.6,7.3,7.5,11.4,10.7c3.9,3.1,8.2,3.8,12.7,5.6
								c4.7,1.9,8.5,5.5,13,7.4c3.2,1.3,6.9,1.2,10.3,1.7c13.6,1.9,6.6,13.4,3,22.3c-1.5,3.8-3.3,6.8-5.7,10.2c-1.5,2.1-2.8,3.9-3.9,6.3
								c-1.6,3.5-0.7,7-1.8,10.1c-0.9,2.7-0.9,2.3-4.1,0.6c-2.8-1.5-4.9-4.2-7.4-6c-1.7-1.2-3.6-2.5-5.7-3.1c-3-0.8-5,0.9-7.4,0.4
								c-2.4-0.5-3-3.2-4.9-3.6c-2.4-0.6-3,1.4-5,1.9c-4,1.1-5.5-1.8-8.2-4.3c-4.2-3.9-8.5-7.6-12.4-12c-2.3-2.7-4.1-5.4-7.3-7.1
								c-3.3-1.8-4.3-1.9-6.6-5c-4.9-6.9-16.1-17.6-24.8-11.2c-1.5,1.1-2.8,3.5-4.9,3.6c-2.4,0.1-4.1-3-6.3-3.1c-5.4,0-5.5,8.1-9.2,9.8
								c-2.6,1.2-5.3-0.3-7.9,1.1c-2.9,1.5-3,4-7,3.4c-3-0.5-4.1-2.9-7.8-1.8c-3,1-3.8,4-6.2,5.6c-4.8,3-9.5,0.1-14.3-0.7
								c-2.5-0.4-20.1,3.8-17.2,7.7c0.9,1.3,7.4-3.8,8.6-4.3c3.6-1.3,7.7-0.2,11.2,0.4c3.8,0.6,6.8,1.3,10.6-0.2c3.7-1.5,6-4.8,9.9-5.3
								c3.6-0.4,6.1,1.9,9.9,0c1.6-0.8,2.8-2.5,4.7-3c1.5-0.4,3,0.3,4.4,0.1c3.1-0.6,3.7-1.2,5.4-3.9c0.8-1.3,1.1-4,2.5-4.9
								c3-2,3.5,0.5,6.2,0.7c7.1,0.5,11-5.9,19.2-1.9c3.9,1.9,7.3,4.9,10,8.4c0.9,1.2,1.6,3.2,2.7,4.3c2.2,2,5,2,7.3,3.7
								c2.8,2.1,4.4,6.2,6.9,8.8c3.4,3.4,7.3,6.2,10.7,9.6c2,2,4.2,5.5,7.5,5.6c2.3,0.1,3.2-2.5,4.9-2.4c2.8,0.1,3.3,2.5,5.3,3.2
								c2.6,0.9,4.8-0.8,7.7-0.3c4.1,0.7,6.8,4.5,9.9,6.9c2.3,1.9,7.4,2.8,9.1,4.6c1,1,0.5,2.9,1,4c0.9,2.2,2.3,3.6,4,5.2
								c12.5,12.6,28.6,12.6,42.6,2.1c5.4-4.1,12.9-9.1,17-1.1c1.7,3.3,3,7.6,4.5,11.1c1.3,3.1,2.4,5.6,0.5,8.8
								c-3.1,5.3-11.3,8.3-15.8,12.6c-5.8,5.6-15.4,8.9-17.6,17.6c-1.8,6.7,4.8,14.2,9.9,17.8c6.7,4.7,15.2,5.1,21.3,11
								c6.2,6,6.9,16.1,7.5,24.3c0.7,10-0.8,15.1-6.9,22.9c-12.2,15.6-14.5,36.8-11.4,55.8c1.4,8.4,4,16.5,6.8,24.5
								c1.2,3.5,2.3,7,3.9,10.4c1.8,3.7,4.8,6.8,6,10.8c2.7,8.2-0.9,18.3,0,26.9c1.2,11.2,4.9,15.3,14.1,21.4c8,5.4,9.5,10.3,4.3,18
								c-4.8,7.1-6.3,13.3-14.7,16.9c-5.6,2.4-10.5,6.6-15.8,8.9c-4.1,1.8-9,0.9-13.4,2.8c-3.6,1.6-7.5,3.8-10.4,6.6
								c-5.6,5.2-8.1,11-15.8,13.5c-8.1,2.6-18.2,5.7-26.7,6.1c-8.5,0.4-15.3-4.5-23.5-5.5c-4.3-0.5-6.9-0.9-10.4-3.7
								c-3.6-2.8-5.9-6.5-10.5-7.9c-4.7-1.5-8.7,0.6-13.4,0.6c-5,0-6.2-2.6-9.8-5.5c-3.5-2.8-7.5-3.9-11.7-5.1c-6.2-1.8-10.5-4.1-15.2-8.5
								c-2.5-2.3-4.7-4.5-5.9-7.7c-1.4-3.5-0.8-7.7-2.5-11.1c-4.9-10-10.9,2.4-17.9-0.6c-5.4-2.3-7.7-11.2-8.2-16.4
								c-0.8-8.4,7.8-15.2,4.5-23.4c-1.3-3.3-4.4-5.7-5.7-9.1c-2.2-5.7,0.8-10.1,3.2-15.1c4.3-8.8-0.2-15.9,2.2-24.5
								c0.6-2.1,2-3.9,1.5-6.2c-0.3-1.5-1.9-2.1-2.4-3.5c-1.4-4.3,1.9-5.7,2.9-9.4c1.3-5.2-2.1-5.9-3.3-10.4c-1.1-4,1.5-7.8,3.6-11
								c2.2-3.3,5.3-6.7,5.3-11c0-3.7-2.6-6.4-3.8-9.7c-1.9-5.2-2.3-10.1-5.4-15c-2.9-4.5-6.7-8.2-10.5-11.9c-6.5-6.3-12-11-20.8-13.6
								c-4.7-1.4-9.2-1.1-13.9-2c-4-0.8-7.4-2.9-11.2-4.5c-5.3-2.2-5.7-4.5-8.9-8.7c-1.2-1.5-3-3.4-5-4c-9.9-3.1,4.1,9.1,6.4,11
								c3.4,2.7,7.8,3.4,11.2,6.4c3.4,3,4.5,4.5,9.6,4.4c4.4-0.1,7.8-1.1,12.1,0.3c5.4,1.7,9.5,5.3,13.6,9.1c6.2,5.7,13.9,11.2,17.6,19
								c1.9,4,1.2,7.7,2.2,11.8c1.4,5.6,3.3,7.7,0.3,13.4c-1.9,3.4-5.5,7-6.3,11c-1,5.4,3.3,6.2,4.5,10.6c1.2,4.2-1.4,4.7-2.8,8.1
								c-2.2,5.4,1.4,7,1.4,12.1c0,3.2-2.6,5-2.9,8c-0.5,4.4,3.7,8.1,3.2,12.8c-1,10.1-11,14.3-5.9,25.4c1.7,3.7,5.3,6.8,5.5,11.1
								c0.2,4.2-2.5,5.5-4.4,8.8c-2.4,4.1-2.7,9.2-0.6,13.6c0.7,1.6,4.7,5,2.8,6.8c-2.2,2-7.1-2.1-9.2-2.7c-4.1-1.1-8.7-0.6-12.7,0.7
								c-5.2,1.7-9.2,4.5-14.9,3.8c-5.1-0.6-9.5-4.2-14.5-4.5c-4.4-0.2-8,2.7-12.6,1.8c-3.8-0.7-5.8-2.9-9.8-1.3c-2,0.8-3.2,2.9-5.2,3.6
								c-2.7,1-3.2-0.6-5.6-0.2c-3.9,0.6-6.3,1.7-10.3,1.5c-2.4-0.1-13.1-1.2-7.5,2.8c3.5,2.5,12.1-0.4,16.2-0.5c4.6,0,7,0.6,11.3-1.6
								c4.4-2.3,4.6-1.9,9.7-1.1c3.5,0.6,5.1-0.8,8.4-1.6c6.4-1.6,9.1,0.6,15,3.2c10,4.5,15.4-1.8,24.9-3.5c4-0.7,8.3-0.5,12.2,0.8
								c5.2,1.7,7,6.4,11.4,8.5c6.3,3.1,12.1-8.4,17.2-2.8c4,4.4,0.9,12.4,5.1,17.6c5.4,6.7,12.5,10.9,20.8,12.7c5,1.1,7.8,2.2,11.4,5.8
								c4.6,4.6,5.9,5.3,12.3,3.6c4.5-1.1,8.7-1.5,12.9,0.8c4.5,2.4,4.9,6.3,8.2,9.6c4.3,4.5,7.8,0.6,12.9,0.7c4.5,0.1,8.2,3.6,12.4,5.1
								c6.8,2.4,57.9,12.2,56.8,16.2c-0.6,2.1-8.2,3.7-10,5c-2.6,1.9-4,5-6.7,6.9c-4,2.8-7.5,3.5-11,7.3c-3.8,4.2-8,7.6-12.2,11.3
								c-6.1,5.4-14.6,11.7-18.5,18.9c-0.7,1.3-5.3,13.8-4.1,14.5c2.2,1.2,4.8-7.4,5.2-8.6c2.1-5.3,4.4-8.4,8.4-12.7
								c4.9-5.2,10.2-11.2,15.9-15.4c2.7-2,6.2-3.5,8.5-6.2c1.9-2.2,2-3.5,4.7-5c3.3-1.8,4.4-0.6,7-3.7c2.1-2.5,2-4.4,5.2-6.2
								c2.8-1.5,6.2-1.6,9-3.1c3.4-1.7,5.6-3.9,9.5-4.4c3.6-0.4,7,0.9,10.6,0.3c4.3-0.7,7.4-2.6,11.7-0.7c2.8,1.3,4.9,3.7,7.9,4.9
								c3.8,1.6,8.1,2.1,12.1,2.4c13.4,0.8,26.8,6.1,40.3,2.3c6-1.7,10-5.5,15.5-0.5c5.1,4.7,6.8,9.7,13.7,12.6c6.1,2.6,12.8,3.4,18.9,6.2
								c7.8,3.5,14.3,7.8,22.9,9.1c6.7,1,13.5,1.1,18.7,6.2c5.1,5,4.2,11.5,7.3,17.4c3.1,5.9,9.9,7.4,14.2,12.1c4.4,4.8,4.9,11.4,5.4,17.6
								c0.5,6.1,2.9,14.9-0.4,20.4c-1.1,1.9-3.7,4-5,5.9c0.1-0.2-4.5,5.3-4.4,3.6c-0.2,4.1,4.8,2.9,5.8,6.2c0.9,3-3.3,6.8-5.1,8.8
								c-6.9,7.4-12.4,11.9-13.2,22.8c-0.9,12.5,0.9,25.8,1.6,38.2c0.3,5,0.6,9.9,1.7,14.8c0.7,3.3,2.3,6.9,2.2,10.3
								c0,2.7,0.4,4.4-2.3,2.8c-1.3-0.8-1.9-4.5-2.8-5.8c-1.8-2.9-4.7-5.1-6.9-7.6c-3.2-3.5-8.7-8.7-9.2-13.6c-0.5-4.6,2.6-5.7-0.2-10.2
								c-0.8-1.3-5.7-7.3-7.8-6.1c-1.5,0.9,2,4.9,2.7,5.9c2.3,3.8,1.9,4.9,1.4,9.3c-0.7,6.4,2.2,7.7,6.4,11.9c8,8.2,13.9,18.8,15.5,30.3
								c1.9,13.6-3.4,24.6-7.1,37.3c-6.3,21.6-16.6,46.4-35.4,60.4c-2.9,2.2-8.6,6.5-12.4,4.6c-4.8-2.3,0-8-4.3-10.7
								c-6-3.7-15.5,7.2-16.2-4.9c-0.3-5.6,3-8.7,3.8-13.8c0.6-3.5-1.1-6.3-2.7-9.4c-0.9-1.7-3.1-7.3-5.2-7.8c-4-0.9-1.5,4.2-0.7,6.1
								c1.7,3.7,3.4,6.6,3.1,10.8c-0.4,6.2-3.2,11.7-3.1,18c0.2,11.7,3.2,19.8-8.7,26.3c-10.8,5.9-22.3,9.6-33.8,13.6
								c-12.6,4.3-24.9,9.2-38,11.8c-26.9,5.4-54.8,10.1-81.7,2.8c-12.4-3.4-25.3-7.5-38.3-4.8c-6.6,1.3-12.8,4.2-19.2,6.3
								c-4.2,1.4-7.6,2.4-5.7,7.9c3.3,9.7,17.9,11.7,24,19.1c7.6,9.3,8.3,22.9,9.3,34.4c0.8,9-0.6,19.3,1.3,28.1c2.4,11.2,8-0.9,10.4-5.4
								c11.7-21.4,33.2-38.8,54.1-50.6c11-6.2,22.7-11.2,34.9-14.4c5.5-1.4,11.1-2.5,16.5-4.3c5.4-1.8,11.3-4.4,17.2-3.7
								c11,1.4,22.8,13.4,31.3,20c9.6,7.3,17.8,19.3,31.1,18.7c12.9-0.6,20.2-10.3,29-18.1c4.6-4.1,7-2.4,12.3-1.2
								c2.5,0.5,4.5,0.1,6.8,0.3c2.6,0.3,5.1,1.6,7.6,0.6c3.9-1.7,5.4-8.2,8.5-10.9c4-3.4,9-3.1,13.6-5c4.3-1.8,6.2-5.6,9.9-8
								c4.9-3,11.2-1.3,16.5-3.3c5.3-2.1,9.5-6.2,14.7-8.5c5.8-2.6,12.6-1.9,18-5.5c4.3-2.9,7.3-7.6,11-11.2c4.2-4.2,8.4-6.1,14.1-7.9
								C2134.4,1915.3,2150.8,1908.6,2149.4,1906.1z M1801.6,706.5c-3.9,2.9-5.8-9.3-5.3-10.4C1798,691.7,1804.6,704.3,1801.6,706.5z
								M1562.1,919.9c-2.4,6.3-11.1-2.3-9.7-5.6C1554,910.3,1564,915.3,1562.1,919.9z M1909,1460.6c-0.4,5.1-0.8,6.7-6.4,5.1
								c-3.3-0.9-6.5-3.4-8.5-6.1c-4.4-6-3.4-17.1-1.9-23.8c2.3-9.8,8.2,1.6,10.8,5.9C1907.7,1449.3,1909.7,1452.1,1909,1460.6z
								M1943.6,1551c3.1,3.6-12.6,7.3-15.5,5.8C1921.5,1553.3,1941.8,1548.8,1943.6,1551z M1919.2,1504.9c0.7,0.1,5.4,10.9,6.2,11.8
								c4.8,5.6,3,6.6-3.6,7.2c-8.5,0.7-16.2,4.1-24.6,5.1c-2,0.2-10.3,1.3-11.2-0.5c-1.6-3,7.4-8.1,9-8.9c4.2-2.1,8.8-2.1,13.1-3.9
								C1910.1,1515,1919.2,1504.9,1919.2,1504.9z M1848.1,1532.2c0.6-5,13.9-12.6,18.1-13.7c3.8-1.1,7.7-0.4,11.3-2
								c3.6-1.6,5.6-5,9.2-6.6c8.2-3.4,13.6-3.5,17.8-12.3c2-4.3,5.5-17.7,10.2-9.1c3.6,6.6,4.2,14.8-1.4,20.3
								c-6.8,6.6-17.1,4.4-24.3,10.9c-2.9,2.6-4.7,6.8-8.2,8.6c-3.6,1.8-8.1,0.3-11.9,1C1866.3,1529.8,1847.3,1539.6,1848.1,1532.2z
								M1837.4,1539.8c3.5-3.6,9.3-1.7,13.6-1.6c4.7,0.1,8.9-1.6,13.2-3.2c8.4-3.1,16.9-1.7,25.8-2.1c10.3-0.4,19.4-4.9,29.5-5.9
								c4-0.4,9.9-0.4,13.5,2c2.7,1.8,5.8,6.7,2.4,9.6c-2.6,2.2-11.3-0.2-14.7-0.1c-4.7,0.2-8.2,1.6-12.3,3.7c-8.6,4.3-12.6,3.6-22.2,2
								c-10.2-1.7-20.4-2-30.7-0.7C1852.5,1543.8,1833.7,1543.6,1837.4,1539.8z M1860.4,1556.2c-14.7-2.1-28.7-5.8-42.8-10.2
								c2.2-7.7,17.6-1.7,22.6-0.4c8.3,2.2,16,0.1,24.2-0.4c10.1-0.6,19,2.8,28.8,3.7c8.9,0.8,14.2-4.8,22.2-6.9c4.6-1.2,22.8-3,24.9,2.8
								c1.7,4.6-17.5,8.2-21.7,9.3c-7.4,1.8-16.1,0.6-23.7,1.4c-5.1,0.5-9.7,0.1-14.8,0.2C1873.6,1555.8,1867,1557.2,1860.4,1556.2z
								M1953.2,1564c-11.1,3.6-20.8,2.3-32.1,0.7c-9.8-1.4-18-1-26.8-6.1c1.7-3.5,15.8-1.7,19.7-1.7c4.3,0,6.3,0.6,10.5,1.9
								c7.2,2.3,11.1-0.3,17.9-2.1C1947.7,1555.2,1953.4,1558,1953.2,1564z M1836.9,1951.9c5.5-1.7,9.2,1.4,2.9,4.3
								c-2.9,1.3-6.5,1.4-9.6,2.4c-8.7,2.7-17,6.6-25.7,9.2c-0.8,0.2-6.8,2.5-7.4,1.4c-1.2-2.2,6.5-5.1,7.6-5.6
								C1814.9,1958.9,1826.2,1955.2,1836.9,1951.9z M1747.3,1951.2c-1.3-7,39-15.2,41.5-8c1.7,4.7-15.9,4.3-19.1,5.1
								C1766.2,1949.1,1748.9,1957.6,1747.3,1951.2z M1758,1961c-10.1-3.9,3.8-5.6,7.3-6.4c11-2.5,22.6-6.7,34-7.1
								c2.5-0.1,13.8,0.4,13.5,3.8c-0.3,2.9-12.1,4.2-14.7,4.6C1786,1957.9,1770.1,1965.8,1758,1961z M1769.8,1972.2
								c-7.4-6.9,6.4-9.1,11-9.8c10.4-1.6,19.8-3.9,29.6-7.4c2.4-0.9,8.8-2,5.4,2.1c-2.1,2.5-10.9,3.9-13.8,5.4
								C1794.9,1965.9,1777.5,1979.3,1769.8,1972.2z M1774.2,1983.2c-3.3-8.4,18.6-13.3,19.5-11.3
								C1794.7,1973.9,1776.2,1988.4,1774.2,1983.2z M1816.8,1990c-4.6,4.6-10.5,7.3-15.1,12.2c-3.6,4-6.9,8.2-10.2,12.5
								c-3.4,4.5-14.1,15.1-11.4,1.5c1.6-8.3,11.2-13.7,17.3-18.6c4.3-3.4,8.5-7.7,13.6-9.9C1813.5,1986.6,1821.9,1985,1816.8,1990z
								M1820,1980.5c-7.3,4.5-15,8.4-22.2,13.1c-4.4,2.9-7.5,6.9-11.5,10.2c-2,1.7-6.8,5.8-8.3,1.4c-1.1-3.2,4.4-7,6.3-8.5
								c5.3-4.1,11.3-7.5,17.3-10.7c4.1-2.2,8.3-4.4,12.6-6.1C1815.8,1979.4,1822.2,1976.6,1820,1980.5z M1861.8,1958.3
								c-1.3,5.9-13.7,8.4-18.5,10.3c-11,4.3-22.4,6.8-33.5,10.6c-9.7,3.3-16.6,9.2-25,14.5c-3.6,2.3-12,4.4-8.5-2.4
								c2.3-4.6,9.9-8,14.1-10.5c6.3-3.8,12.4-8,19.1-11.1c10.1-4.7,21.1-7.9,31.8-10.9C1842.8,1958.4,1863,1953.3,1861.8,1958.3z"
					/>
					<path
						d="M1903.4,1454.9c-0.1-3.7,0.1-3.5-0.2-4.6c-0.5-1.7-1.4-2.9-1.8-3.4c-1.4-1.9-2.2-1.6-3.1-3.2c-0.8-1.4-0.8-2.6-1.4-2.7
								c-0.1,0-0.5,0-1.3,1c-1,1.2-1.5,2.5-1.9,4.1c-0.5,2-0.8,2.9-0.6,4.1c0.2,1.9,1.2,3.2,1.6,3.8c1.4,1.8,2.1,1.2,4.2,3.4
								c0.8,0.8,1.2,1.5,2.2,1.6c0,0,1.2,0.2,1.9-0.6C1903.6,1457.7,1903.5,1456.9,1903.4,1454.9z M1901.4,1454.4
								c-0.4,0.3-1.3-0.2-2.2-0.8c-1.6-0.9-2.4-1.4-2.8-2.4c-0.6-1.4-0.1-2.7,0.1-3.2c0.2-0.5,0.4-1.2,0.9-1.2c0.5-0.1,0.7,0.8,1.9,1.5
								c0.8,0.5,1,0.2,1.4,0.6c0.6,0.6,0.2,1.2,0.4,3C1901.3,1453.6,1901.7,1454.2,1901.4,1454.4z"
					/>
					<g>
						<path
							d="M1766.9,808c-2.1-0.9-4.2-2.1-6-3.5c-1.5-1.1-2.3-2.6-3.2-4.2c-0.5-0.9-3.3,0-2.9,0.8c0.9,1.7,1.8,3.3,3.4,4.5
									c2,1.5,4.2,2.7,6.4,3.7C1765.4,809.7,1768.1,808.5,1766.9,808L1766.9,808z"
						/>
					</g>
					<g>
						<path
							d="M1767.6,807.4c0.1-2.1,0.8-4.1,0.4-6.2c-0.3-2-1.3-3.7-1.4-5.7c0-0.7-2.9-0.2-2.9,0.8c0.1,2,1.1,3.8,1.4,5.7
									c0.4,2.1-0.3,4.1-0.4,6.2C1764.6,808.8,1767.5,808.4,1767.6,807.4L1767.6,807.4z"
						/>
					</g>
					<g>
						<path
							d="M1767,807.8c1.2-0.7,2.3-1.4,3.2-2.4c1-1.1,1.9-2.1,3-3c1.5-1.2-0.8-2.4-2-1.4c-2,1.6-3.4,3.7-5.6,5.1
									C1764,807,1765.5,808.7,1767,807.8L1767,807.8z"
						/>
					</g>
					<g>
						<path
							d="M1778.5,794c-0.7-1.2-1.7-2.2-2.5-3.2c-1.1-1.3-2.1-2.8-3-4.2c-0.5-0.8-3.2,0.3-2.9,0.8c0.9,1.3,1.7,2.5,2.6,3.7
									c0.9,1.3,2.1,2.3,2.9,3.7C1776.1,795.6,1778.8,794.6,1778.5,794L1778.5,794z"
						/>
					</g>
					<g>
						<path
							d="M1779.5,786.2c-0.7,1.7-0.8,3.6-0.6,5.4c0.1,1.4,0.3,3.4,2,3.6c3.2,0.4,6.1-3.4,6.9-6c0.3-1-2.6-0.6-2.9,0.4
									c-0.4,1.2-0.9,2.2-1.8,3.1c-0.4,0.4-0.8,0.6-1.1,0.9c0.6,0.2,0.7,0.1,0.3-0.4c-0.1-0.2-0.1-0.4-0.2-0.7c-0.5-2.2-0.5-4.5,0.4-6.6
									C1782.7,785,1779.9,785.3,1779.5,786.2L1779.5,786.2z"
						/>
					</g>
					<g>
						<path
							d="M1771.4,811c0.8,1.6,2.3,2.8,3.8,3.8c0.7,0.5,1.4,0.9,2.1,1.3c0.2,0.1,0.4,0.3,0.6,0.4c0.2,0.1,0.3,0.2,0.5,0.4
									c0.2,0.3,0.3,0.2,0.3-0.3c-0.5,0.5-0.2,1.3,0.4,1.5c0.7,0.3,1.6,0,2-0.5c1.3-1.4-0.8-2.7-1.8-3.3c-1.9-1.2-4.2-2.4-5.2-4.5
									C1773.5,808.4,1770.7,809.5,1771.4,811L1771.4,811z"
						/>
					</g>
					<g>
						<path
							d="M1782.7,814.3c0-3.4,0-6.3,1.2-9.5c0.6-1.6-2.3-1.6-2.8-0.4c-1.3,3.4-1.4,6.4-1.4,10.1
									C1779.8,816,1782.7,815.6,1782.7,814.3L1782.7,814.3z"
						/>
					</g>
					<g>
						<path
							d="M1783.9,818.4c2-0.8,3.7-2.1,5.2-3.6c0.9-0.9,1.7-1.9,2.4-2.9c0.6-0.8,1.2-2,2-2.6c-0.8,0-1.5,0-2.3-0.1
									c0.1,0.1,0.1,0.2,0.2,0.4c0.7,1.2,3.5,0,2.6-1.3c-0.1-0.1-0.1-0.2-0.2-0.4c-0.5-0.8-1.7-0.5-2.3-0.1c-1.5,1.1-2.4,3-3.6,4.5
									c-1.5,1.8-3.3,3.4-5.4,4.2C1780.8,817.3,1782.4,819,1783.9,818.4L1783.9,818.4z"
						/>
					</g>
					<g>
						<path
							d="M1766.8,818.3c-1.3,1-2.7,1.9-4,2.8c-1,0.7-2.1,1.4-2.7,2.5c-0.3,0.5,1,0.4,1.2,0.4c0.6-0.1,1.4-0.3,1.7-0.8
									c0.7-1.2,2.2-1.9,3.3-2.7c1.1-0.7,2.2-1.5,3.2-2.4c0.5-0.4-0.5-0.6-0.7-0.6C1768.1,817.6,1767.3,817.9,1766.8,818.3L1766.8,818.3z
									"
						/>
					</g>
					<g>
						<path
							d="M1751.9,820.2c1.7,2.3,4.1,4.5,6.9,2.3c1.3-1,2.1-2.7,3.2-3.9c1.1-1.2,2.3-2.2,2.3-3.9c0-1.3-2.9-0.8-2.9,0.3
									c0,1.9-2,3.2-3.1,4.6c-0.2,0.3-0.5,0.6-0.7,1c-0.2,0.3-0.4,0.5-0.6,0.7c-0.2,0.4-0.3,0.4-0.5,0c-0.7-0.3-1.5-1.7-1.9-2.3
									C1753.9,818.2,1751.1,819.2,1751.9,820.2L1751.9,820.2z"
						/>
					</g>
					<g>
						<path
							d="M1762.8,759.9c0.5,0.6,0.9,1.4,1.5,2c0.6,0.7,1.3,1.2,2.2,1.5c0.6,0.3,1.6-0.1,2-0.5c0.5-0.5,0.3-1-0.3-1.3
									c-1.2-0.5-1.8-2-2.6-3C1764.7,757.7,1761.9,758.8,1762.8,759.9L1762.8,759.9z"
						/>
					</g>
					<g>
						<path
							d="M1768.3,757.8c0.3,1.4,0.8,2.8,1.2,4.2c0.4,1.1,0.6,2.2,1.5,2.9c0.7,0.6,3.4-0.5,2.7-1.1c-0.9-0.8-1.1-2.1-1.5-3.1
									c-0.4-1.2-0.8-2.4-1.1-3.7C1771,756.1,1768.1,756.8,1768.3,757.8L1768.3,757.8z"
						/>
					</g>
					<g>
						<path
							d="M1795.4,757.8c-2.3,3.7-3,8-7.9,8.9c-5.6,1-8.9-4.1-11.7-8.1c-0.5-0.8,2.3-1.7,2.7-1c1.8,2.5,3.5,5.5,6.2,7.1
									c0.2,0.1,0.3,0.2,0.5,0.3c-0.3-0.6-0.4-1.3-0.6-1.9c-0.5-1.9-1.2-3.7-1.7-5.6c-0.2-0.6,2.7-1.3,2.9-0.8c0.5,1.8,1.1,3.5,1.6,5.2
									c0.3,0.9,0.4,2.1,1,3c1.8-1.3,2.8-4.7,4-6.5C1793,757.5,1795.7,757.3,1795.4,757.8z"
						/>
					</g>
					<g>
						<path
							d="M1661.1,643.4c2.2,4.8,8.3,5.9,12.1,9c2,1.7,4.8-1.2,2.8-2.8c-1.9-1.6-4.1-2.5-6.2-3.8c-1.9-1.2-4.3-2.2-5.3-4.4
									C1663.5,639,1660.1,641.1,1661.1,643.4L1661.1,643.4z"
						/>
					</g>
					<path
						d="M1766.8,591.4c-1.2-4.5-1-9-2.5-13.5c-1.9-5.8-1.3-11.2-2.9-16.8c-1.2-4.1-4.3-7.5-5.9-11.6c-2.1-5.3-4.5-10.8-5.2-16.5
								c-0.7-5.5-3.7-9.1-4.9-14.1c-1.2-5.3,1.8-10.6,1.6-15.8c-0.2-5.2-3.4-9.1-5.7-13.6c-2-3.8-1.7-5.2-1.4-9.4c0.6-7.6-5.9-9-7.8-16
								c-1.2-4.6,2.8-11.1,1-15c-2.2-4.7-9.9-6.2-12.4-11.5c-2.1-4.6,0.7-9.5,1.2-14.2c0.4-3.8,0.1-7.9-0.3-11.7c-0.5-4.9-3.3-7-5.7-11.3
								c-3.1-5.6-2.8-10.7-1.3-16.7c0.5-2,2.3-5.1,2.4-7c0.1-3-2.3-5.2-1.9-8.8c0.7-5.8,2.9-11,7.4-14.3c5.6-4.1,6.3-10.8,11.5-15.1
								c2.4-2,3.5-2.5,5.1-5.4c1.9-3.5,3.9-5.6,6.5-8.5c2-2.3,3.1-4.8,2.4-7.9c-0.3-1.2-5-7.8-6.4-5.6c-0.3,0.5,4.7,5.9,4.5,7.9
								c-0.3,3.6-5.8,7.9-8.2,10.4c-3.1,3.3-6.8,6-9.7,9.5c-2.8,3.4-2.4,7-4.8,10.1c-8.2,10.3-6.9-10.6-6.2-14.8c0.7-4.8-0.1-9.5-5-11.4
								c-2.3-0.9-4.3,0.1-5.1-2.8c-0.4-1.4,2.7-5.3,2.7-5.2c-0.4-0.7-3.6,1.1-4,1.3c-1.8,1.5-0.9,4.8-1.8,5.6c-5.2,4.6-2.5-7.8-1.8-6.9
								c-1.5-1.6-6.3,6.5-3.9,9.2c2.3,2.6,7.3-0.3,10.6,0.6c5.4,1.4,6.4,5.9,5.8,10.9c-0.7,5-1,9-0.6,14.1c0.5,5.9-0.7,11.6-1.4,17.4
								c-0.4,3.6,2.2,7.5,1.5,10.7c-0.4,1.6-2.4,3-3,4.6c-0.8,1.9-0.5,4.2-1.7,5.8c-3.1,4.1-7.2,0.5-10.5-1.3c-4-2.2-8-3.1-12.3-4.3
								c-8.1-2.2-16.2-6.4-23.9-9.7c-4.4-1.9-7-4.3-11.9-3.9c-4.8,0.4-7.4,1.1-11.9-1.2c-5.9-3-6.9-1.9-12.7-0.1
								c-1.2,0.4-12.3-0.4-11.8-1.4c-1.9,3.4,11.9,3.7,13,3.5c5.8-1.3,8.3-0.4,13.8,1.8c5,2,9.1,0,14.1,0.9c3.3,0.6,6.1,3.9,9.6,5.2
								c1.9,0.7,4.4,0.8,6.2,1.6c2.2,1,3,2.8,5.1,3.9c2.4,1.3,5.8,1.1,8.4,2c2.5,0.8,4.6,1.8,7.2,2.4c2.8,0.6,5.2,0.8,7.6,2.5
								c1.7,1.2,2.8,2.6,4.8,3.1c2.8,0.8,4.7-0.8,6.6,2.2c0.4,0.7,0,2.3,0.2,3.2c0.6,1.9,1.3,3.4,2.4,5.1c2.8,4.4,5.3,5.8,5,11.2
								c-0.3,5.5,0,11-0.8,16.4c-0.7,4.7-0.6,8,2.8,11.9c3.8,4.3,9.8,4.5,8.8,11c-0.3,1.8-1.4,3.6-1.5,5.5c-0.1,3.3,1.8,6.4,3.4,9.1
								c1.1,1.9,8.2,10.5,4.3,12.4c-3.5,1.7-10.7-7.1-13.9-8.6c-5.7-2.7-11.1-2.4-17.1-2.5c-7.5,0-10.5-1.4-11.7-8.6
								c-1-5.8-3.1-7.9-8.5-10.2c-2.9-1.3-7.4-1.5-10-3.3c-2.1-1.5-2.5-4.2-4.8-5.4c-1.5-0.8-18.7-0.9-18.7,0.2c0.1,1,14.6,0.7,16.1,1.3
								c3,1.2,3.7,4.4,6.2,6.3c2.6,2,6.5,2.8,9.5,4.3c5.9,2.8,11.4,9.2,1.5,11.1c-0.2,0-18,0.4-17.3-1.3c-0.6,1.4,7.3,3.3,8.2,3.4
								c4.2,0.5,9.2-2.1,13.1-1c5.1,1.4,7.5,7.1,13.5,7.6c4.9,0.4,11.8-1.2,16.4,0.1c4.6,1.3,11.4,7.1,14.9,10.6c6.2,6.1,3.4,12,3,19.5
								c-0.3,5.4,3.8,12.3,5.6,17.3c1.2,3.3,3.5,5.7,5.1,8.8c2,3.9,0.5,7.7,1.5,11.6c0.8,3.5,3.6,6,4,9.6c0.5,3.8-0.5,7.6,0.3,11.5
								c0.9,4.7,3.9,7.3,6.1,11.3c2.3,4.1,3.5,8.7,4.3,13.3c0.5,2.7,0.4,10.2,2.7,12.1c1.2,1,3.6,0.2,5.4,0
								C1768.9,597.4,1767.6,594.4,1766.8,591.4z M1741.5,509c-3.5,0.5-4.4-9.1-1.3-9.4C1743.6,499.3,1744.7,508.5,1741.5,509z
								M1757.9,564.8c-1.8,0.5-4.2-5.6-2.6-6.6C1757.5,556.6,1760.6,564.1,1757.9,564.8z"
					/>
				</g>
				<g id="streets">
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						strokeDasharray="0,9"
						d="
								M2346.8,1486.8c-3.5,0.4-8.6,0.8-14.8,0.8c-8.8-0.1-16.3-1.3-30-4.5c-16.1-3.8-16-4.6-34.8-9.2c-12.6-3.1-19-4.7-28.5-6.2
								c-10-1.6-12-1.3-21.8-3.2c-10-2-11.7-3-20.8-4.5c-11.4-1.9-17.1-2.8-21.2-1c-8.5,3.7-10.1,12.6-15.8,12c-2.7-0.3-2.9-2.5-7.2-4.8
								c-2-1.1-4.7-2-13.8-2.2c-10.3-0.3-15.4-0.4-20,1.2c-2.6,1-8.4,3.1-12.2,8.8c-2.4,3.5-2.9,6.6-4,14.8c-1.8,13-2.7,19.5-2.8,21.2
								c-1,17.3,0.3,33.4,1,41.5c2,25.4,3.1,38.9,4,55.8c0.1,2.1,1.1,22.1,1.2,44.5c0,7.3,0,13.6-0.2,20.5c-0.3,7.7-0.6,14.3-1,19.2"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						strokeDasharray="0,9"
						d="
								M2102,1772c-4.3,9.8-6.4,18.3-7.5,24.5c-1.4,8-2.7,15.6-1,25c1.9,10.6,5.3,10.4,7,20c1.7,9.7-0.3,17.4-3.5,29.5
								c-3.9,14.8-6.3,14.4-8.5,25.5c-1.4,6.9-1.3,14.6-1,29.5c0.4,21.5,1.7,24.3,0,34c-1.4,7.6-2.8,15.8-9,23c-6.4,7.5-11.6,6.7-21.5,13
								c-14.8,9.5-21.9,23.4-25.5,30.5c-7.9,15.7-9.4,30.1-9.5,38.5c-0.1,5,0,23.5,10.5,43.5c8.2,15.7,12.9,13.3,20,27.5
								c7.1,14.2,7.8,27.2,9,49c1.2,21.9-1.4,24,1.5,38c2.4,11.6,5,24.2,15,33.5c9.4,8.8,20.5,10.2,38,12.5c21.6,2.8,25.6-1.1,49,2
								c16.8,2.3,25.3,3.5,33,10c7.7,6.5,5.8,10.6,18,29c10.8,16.3,13,14.2,17.5,24.5c3.3,7.5,3.5,11.8,9.5,28c1.8,4.9,3.4,8.9,4.5,11.5"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						strokeDasharray="0,9"
						d="
								M2080,1929.7c-8.9-2.4-22.9-6-40.3-9c-23.2-4-24.8-2.4-40.7-6c-27.3-6.2-29.3-12.7-48.3-13.3c-7.5-0.3-15.5,0.9-31.3,3.3
								c-16,2.4-20.5,4.2-34,6c-7.6,1-18.6,2.5-32.7,2.3c-12.2-0.1-33.4-0.3-51.3-9.3c-4.2-2.1-8.1-4.7-16-10c-6.9-4.6-8.9-6.3-10.3-9
								c-3.1-5.7-1.9-11.8-1.3-15c0.3-2.1-0.8-14.5-3-39.3c-1.6-18.2-2.6-28.5-6.3-41.3c-2.8-9.6-9.6-31.8-28.7-51
								c-9.6-9.7-19.9-16.2-23.3-18.3c-8.1-5-11.2-5.8-27.3-14.7c-18.3-10-21-12.8-23-15.3c-6.8-8.7-7.7-18.9-7.7-25.3"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						strokeDasharray="0,9"
						d="
								M1991,1876c4-11.1,7.6-20.1,10-26c8-19.5,12-26.4,15-39c2.5-10.5,3-19.4,3-25"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						strokeDasharray="0,9"
						d="
								M2026.8,1745.3c4.2-10.9,8.4-21.9,12.7-32.8"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						strokeDasharray="0,9"
						d="
								M2116.5,1676.5c-11.2,1.3-22.3,2.7-33.5,4"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						strokeDasharray="0,9"
						d="
								M2056.6,1683.8c-2.1,1.2-5.1,2.5-8.1,1.6c-3.8-1.1-4-4.8-7.6-8.8c-2.7-2.9-4-2.5-18.9-8.4c-9.2-3.6-9.5-4.1-13.5-5.2
								c-6.9-2-11.2-2.1-15.1-2.6c-10.4-1.5-15-5.7-26.1-11.2c-9.8-4.9-18.8-7.5-36.8-12.6c-12.2-3.5-22.8-6.5-37.5-8.5
								c-10-1.3-9.7-0.5-28-2c-18.1-1.5-18.1-2.2-41.5-4.5c-24.3-2.4-36.5-3.5-38.5-3.5c-7.7,0.2-13.9,0.9-21.5-2.5
								c-5.8-2.6-6.2-4.9-15-10c-5-2.9-9.2-4.6-17.5-8c-13.6-5.5-15.2-5-20-8.5c-6.6-4.8-5.2-6.9-11-10.5c-6.6-4.1-11.3-3-21-5
								c0,0-9.8-2-21.5-8c-15.4-7.9-24.5-20.3-33.5-32.5c-8.1-11-10.2-16.8-19.5-24.5c-5.7-4.7-11.2-7.7-15-9.5"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						strokeDasharray="0,9"
						d="
								M1567.5,1489c-3.2-4.2-7.6-9.1-13.5-14c-7.5-6.2-14.9-10.1-20.5-12.5"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						strokeDasharray="0,9"
						d="
								M1494.3,1423c-10.1-3.6-17.6-7.6-22.7-10.7c-8.2-4.9-11-8-18.3-9.7c-3.9-0.9-6.3-0.8-9-3c-2.9-2.3-2.9-4.6-6.3-11.7
								c-2.8-5.8-4.3-8.7-6.3-10.3c-3.6-2.9-6.5-1.8-15.7-3c-9.9-1.3-9.2-2.9-18-4c-3.9-0.5-4.6-0.2-19,0c-11.3,0.2-16.9,0.3-20.3,0
								c-4.3-0.3-13.6-1-22-4.3c-5.3-2.1-12.9-6.2-24.7-21c-20.3-25.3-29.3-52.4-33.3-64.7c-1.9-6-3.7-12.2-8-24.7c-4-11.6-5-13.6-9-25.3
								c-2.7-7.8-5.5-16.1-8.3-26.3c-3.7-13.3-2.8-13.2-6.3-26.7c-2.5-9.6-6-23-12.3-39.3c-4-10.4-6.4-14.6-7.7-23.3
								c-1.1-7.2-0.2-9.4-2-16c-2.3-8.7-4.9-9.2-8.7-18.7c-3.8-9.7-1.7-10.6-5.7-23c-3.5-10.8-5.2-10.3-7.7-19
								c-3.7-12.8-0.4-14.9-3.3-25.3c-2.8-10.1-8.4-16.8-16-26c-10.3-12.4-14.7-12.8-18.7-22c-3.2-7.3-1.5-9.6-3.7-19.7
								c-3.1-14.7-9.8-24.8-15-32.7c-8-12.1-9.4-9.5-25.7-29.7c-10.8-13.4-11.3-15.9-17.7-20.7c-8.3-6.2-10.9-4.4-26.7-12
								c-12.2-5.9-20.5-10-27.3-18.3c-2-2.4-4-5.4-10.3-21.7c-9.1-23.4-13.6-35.1-13.7-43.7c0-7.7,2.3-16.4,7-33.7
								c2.5-9.2,4.3-14.1,6-23.3c1.8-9.7,2.2-17.8,2.3-23"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						strokeDasharray="0,9"
						d="
								M1036.7,822c-8.1-2.4-16.7-4.8-25.7-7c-12.3-3-15.2-2.5-24.7-5.3c-12.7-3.8-12.4-5.6-21.7-7.7c-3.7-0.8-6.1-1-26-1.7
								c-23.2-0.7-24.1-0.5-27.3-1.7c-8.4-2.9-7.5-5.9-23.3-16c-13.1-8.4-14.7-7-20-12.3c-3.6-3.7-4-5.5-13-23.3
								c-9.1-18.1-11.1-20.9-13.7-23.7c-5-5.3-8.3-5.3-20.3-12.3c-1.4-0.8-11.9-5.3-20-13.3c-3.3-3.2-7.8-8.7-11.3-17.3"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						strokeDasharray="0,9"
						d="
								M782,676c6.5-0.1,13.2-0.1,20,0c4.2,0.1,8.3,0.2,12.3,0.3"
					/>
					<g>
						<line fill="none" stroke="#000000" strokeWidth="5" strokeLinecap="round" strokeMiterlimit="10" x1="840" y1="671.7" x2="840" y2="671.7" />

						<path
							fill="none"
							stroke="#000000"
							strokeWidth="5"
							strokeLinecap="round"
							strokeMiterlimit="10"
							strokeDasharray="0,8.5011"
							d="
									M848.2,669.3c4.3-1.3,8.8-2.9,13.5-4.7c10.6-4.1,9.7-13.6,45.4-17.5"
						/>

						<line fill="none" stroke="#000000" strokeWidth="5" strokeLinecap="round" strokeMiterlimit="10" x1="911.3" y1="646.8" x2="911.3" y2="646.8" />
					</g>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						strokeDasharray="0,9"
						d="
								M921.3,644.7c6.4-0.8,14.7-1.8,24.3-2.7c6.7-0.6,12.2-1,23-1.7c25-1.6,27.9-1.4,32-0.3c4.7,1.2,13.2,3.6,20,11.3
								c5.4,6.1,7.3,12.7,8,16.3"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						strokeDasharray="0,9"
						d="
								M1040.2,663.2c-0.2-3.4-0.6-8.8-1-15.5c-1-16.4-0.9-17.5-1.5-23c-1.1-11.1-1.9-11.2-2.5-20.2c-0.7-9.8,0.2-10.7-0.8-16.5
								c-1.1-6.8-3.1-11-6.8-19.2c-4-9-7.8-18-11.8-27c-3.2-7.2-7.6-17.9-12.2-31.5"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						strokeDasharray="0,9"
						d="
								M1054.7,668c6.4-1,16.3-2.4,28.7-3c9.7-0.5,17-0.3,44.7,1c34.3,1.6,33.4,1.8,38.7,1.7c22.3-0.5,39.1-3.9,44.3-5
								c11.3-2.4,22.6-6.4,44.7-14.3c35.5-12.7,35.5-14.4,48-17c20-4.1,36.4-3,49.7-2c19.2,1.4,20,3.9,55.7,9.7
								c11.4,1.9,23.7,3.5,48.3,6.7c42,5.5,39.7,4.4,45,6c4.9,1.5,12.3,4.2,22.3,3.3c7.9-0.7,19.3-0.8,23.3-15.9"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						strokeDasharray="0,9"
						d="
								M1659.2,614.8c9.9,0.3,17.9,0.3,23.2,0.2c13.6-0.3,16-1,25.8-0.7c13,0.4,12.5,1.7,21.3,1.3c2.4-0.1,12.4-0.6,25.2-4.5
								c9.9-3,9.3-4.4,16.7-6.5c10.4-3,16.1-1.5,32.7-1.3c5.1,0.1,0.8-0.1,30.8-1.2c18.1-0.6,27.8-1,38.9-1.2c28.5-0.5,27.8,0.6,51.2,0
								c21.5-0.5,32.2-0.8,45.5-2.8c14-2.1,18.4-3.9,32.2-5c5.6-0.4,8.5-0.4,31.5,0c36.2,0.6,37.8,0.8,44.2,0.5c6.9-0.3,16.2-1.2,34.8-3
								c13.2-1.3,24.1-2.4,31.5-3.2"
					/>
					<path
						fill="none"
						stroke="#000000"
						strokeWidth="5"
						strokeLinecap="round"
						strokeMiterlimit="10"
						strokeDasharray="0,9"
						d="
								M1465.9,1435c2.9-2.8,5.9-6,9-9.8c3-3.6,5.5-7.1,7.6-10.4"
					/>
				</g>
				<g id="mountains_and_forests">
					<path
						d="M1701.3,2014.1c-0.8-1.1-1.4-0.9-3.1-2.2c-1.5-1.2-1.4-1.6-2.4-2c-1.7-0.7-3.4-0.2-3.6-0.1c-1.1,0.4-1,0.8-3.3,2.4
								c-1.6,1-2,1.1-2.3,1.1c-0.9-0.1-1-0.8-2.3-1.4c-0.4-0.2-1.5-0.7-2.5-0.5c-1.1,0.2-1.7,1.2-2.6,2.3c0,0-1.1,1.5-4.2,4.8
								c-1.4,1.5-2.5,2.4-4.2,3.9c-1.5,1.3-2.7,2.2-4.3,3.4c-2.4,1.8-3.6,2.7-4.1,3c-1.4,0.7-2.3,0.8-2.9,1.8c-0.5,0.8-0.7,2.1-0.1,2.7
								c0.8,0.9,2.5-0.5,4.4,0.4c0.6,0.3,1.6,1,1.6,1.8c0,0.7-0.9,1-2.7,2.1c-0.9,0.6-0.1,0.1-4.6,3.6c-1.5,1.2-2.1,1.5-2.8,2.4
								c-0.7,0.9-1.7,2.1-1.4,3.1c0.4,1.1,2.5,1.2,3.4,1.2c0.7,0,3.3,0.1,8.4-2.6c3.1-1.6,4.7-2.4,5.2-3.8c0.8-1.8,0-3.1,1.2-5.4
								c0.4-0.7,1.1-1.9,2-1.9c0.7-0.1,1.4,0.6,3.6,3.6c2.1,2.9,2.5,3.5,3.6,4.6c1.2,1.1,2.6,2.4,4.1,2.1c1-0.2,2-1.3,1.9-2.1
								c-0.2-0.9-1.6-0.7-3.2-2.1c-0.2-0.2-2.3-1.9-2-3.7c0.2-1.3,1.5-1,3.1-3.4c0.7-1,0.9-1.7,1.9-2.6c0.7-0.6,1.1-1,1.6-1
								c1.3-0.1,1.7,1.6,3.4,1.8c0.8,0.1,1.8-0.1,2.1-0.7c0.5-1-1-2.6-1.4-3c-1.6-1.7-3.5-2.1-3.9-2.1c-0.6-0.1-2.4-0.4-4.2,0.5
								c-1.3,0.7-1.2,1.3-3.2,3.2c-1.5,1.4-2.3,2.1-3.4,2.3c-1.4,0.2-3.4-0.2-3.8-1.6c-0.5-1.3,0.7-2.9,1.9-3.6c1.6-0.9,2.7,0.1,4.3-1
								c1.2-0.8,1.6-2,1.7-2.2c0.4-1.3-0.1-1.8,0.3-3.4c0.2-0.9,0.5-1.9,1.2-2.2c1.3-0.6,3.4,1.3,4.5,2.6c1.6,1.9,1.6,3.3,2.9,3.8
								c0.8,0.3,2,0.1,2.5-0.6c0.6-0.8-0.1-1.7-0.2-4.2c0-1.5-0.1-2.4,0.5-3.1c0.8-1.1,2.5-1.6,3.6-1.1c0.9,0.4,0.8,1.2,1.9,1.7
								c0.7,0.4,1.9,0.6,2.3,0.1C1702.4,2016.1,1701.9,2014.8,1701.3,2014.1z"
					/>
					<path
						d="M2117,410.3c0.7-0.8,2.9-3.5,7.3-9c1.6-2,3.2-3.6,4.6-5.8c0.1-0.1,0.5-0.6,0.8-0.6c0.7-0.1,1.4,1.8,2.2,3.7
								c0,0,0.7,1.6,3,5.2c1.9,3.1,4,4.9,6.8,7.3c3.2,2.8,4.7,4.1,7.1,5.1c4.6,1.8,8.2,0.7,8.4,1.6c0.2,0.8-2.3,2.4-5,2.7
								c-4.3,0.5-8.2-2.6-10.8-4.7c-5.5-4.5-6.3-5.1-6.3-5.1c-1.1-0.9-2-1.8-2.3-1.6c-0.6,0.4,1.3,4.1,1.5,4.4c1,1.9,1.5,2.9,2.5,3.7
								c1.6,1.2,2.7,0.6,4.5,2c0.4,0.3,2.1,1.6,1.8,2.6c-0.3,1-2.4,1.3-3.6,1.5c-7.6,0.9-11.2-0.8-17.6-0.4c-5.5,0.3-9.1,1.1-10,0.1
								c-1.1-1.2-0.7-3.1-0.6-4.2C2111.8,415.8,2113.5,414.3,2117,410.3z"
					/>
					<path
						d="M634.4,121.2c-2.9-0.9-4.8-2.2-6-2.6c-4.6-1.4-7.9-4.5-10.1-6c-1.6-1.1-3.2-2.1-4.6-3.3c-0.2-0.2-0.8-0.7-1.7-1
								c-0.2-0.1-0.9-0.3-1.6-0.2c-1.4,0.1-2.4,1.2-2.8,1.5c-1.3,1.3-2.7,1.5-7.5,3.4c-6.1,2.5-5.5,3-11.2,5.3c-3.2,1.3-6,2.5-10.1,3.3
								c-6,1.3-9.3,1.6-9.3,1.6c-3,0.2-5.3,0.2-7.3,1.9c-1,0.8-2.1,2.2-1.8,3c0.4,0.9,2.7,0.7,4.4,0.5c1.1-0.1,1.8-0.3,2.4-0.4
								c-1.7,4.1-4.8,11.2-10.7,16.4c-2,1.7-3.4,2.5-7.4,6c-2.7,2.4-3.5,3.4-6,5.1c-1.5,1.1-2.5,1.8-3.2,2.3c-3.7-1.9-8.5-4.2-10.7-6.5
								c-0.2-0.2-0.6-0.6-1.2-0.7c-1.7-0.2-2.5,2.6-6,6.5c-2.5,2.9-3,2.5-5.9,5.6c-0.1,0.1-0.3,0.3-0.4,0.4c-1-0.1-2.2-0.2-3.6-1
								c-2.1-1.3-2.4-2.9-3.7-2.9c-1.2,0-2.1,1.5-2.6,2.2c-2.3,3.6-5.5,6.4-7.9,8.4c-2.6-2.2-4.3-5.3-6.7-7.6c-0.8-0.7-2.1-0.8-2.8,0
								c-2.3,2.6-5.3,4.8-7.7,7.4c-1.6-2.6-3-5.3-5.4-7.3c-1-0.9-2.4-0.8-3.2,0.4c-2.2,3.5-5.3,8-8.8,10.7c-1.1-1.2-1.8-2.2-3.4-2.7
								c-0.9-0.3-1.8,0.2-2.3,0.9c-2,2.8-3.2,5.7-5.8,8.2c-2.9,2.9-6.2,5.2-9.6,7.4c-7.9,5.2-15.8,8.7-25.1,10.3c-2.5,0.4-1.5,4.3,1.1,3.9
								c4.4-0.8,8.5-1.9,12.4-3.5c-1.9,3.4-3.6,7-6,10.1c-2.5,3.3-8.2,8-12.5,8.2c-0.2,0-0.5,0.1-0.7,0.1c-0.8-0.7-1.5-1.3-2.3-2
								c-1.7-1.5-3.4-5-5.8-5.4c-2.6-0.4-5.8,3-8.2,4.1c-0.7-0.7-1.3-1.4-2-1.9c-1-0.8-2.4-0.8-3.1,0.4c-3.4,5.2-7.4,9.9-13.3,12.2
								c-0.3,0.1-0.5,0.3-0.7,0.4c-2.3-1.9-4.3-4.1-6.4-6.2c-3.8-3.8-7.4-7.3-8.7-12.7c-0.3-1.4-2.4-2.1-3.3-0.9
								c-5.2,6.5-7.5,14.6-13.6,20.7c-3.5,3.5-7.4,5.7-12.1,7.1c-3.5,1-6.7,1.9-9.7,4.1c-1.6,1.2-0.8,4.5,1.5,3.7c3.4-1.2,7.3-2.1,11-2.2
								c4.5-0.2,8.5,0.4,12.9-1.3c7.6-3.1,14-9.5,18.1-16.7c0.3,0.4,0.7,0.7,1.1,1.1c4.5,4.5,8.6,8.9,14.6,11.3c1.6,0.6,2.6-0.8,2.4-2.2
								c1,0.1,2.3,0.3,4,0.5c8.8,1.1,12.6,2.1,15,0.1c0.3-0.2,1.5-1.3,1.2-2.1c-0.2-0.7-1.2-0.6-1.6-1.1c-0.8-1.3,3.4-6.9,5.3-9.2
								c1.2,1.2,2.4,2.3,3.6,3.3c6.4,5.2,13.4,8.6,21.6,9.8c2.5,0.4,3.6-3.5,1.1-3.8c-4.8-0.7-8.9-2.2-12.8-4.3c0.1-0.1,0.2-0.2,0.2-0.2
								c1.6-2.1,6.7,1,9.3-1c1.4-1.1,2-2.7,2-4.3c2.6-1.1,4.9-2.7,6.1-5c1.5-3,1.5-6.8,1.2-10.5c2.8,3,5.5,6,8,9.2
								c2.2,2.8,4.3,7.1,8.5,6.1c8.2-2,9.9-15.9,11.9-23.3c1.5,1.8,3,3.6,4.6,5.4c0.6,0.7,1.2,1.3,1.8,1.9c-0.9,1.1-1.6,2.3-2.5,3.6
								c-1.9,2.8-4.5,5.2-7,7.4c-4.8,4.2-9.6,8.7-15.9,10.5c-2.3,0.6-1.6,4,0.5,3.9c0.2,0.1,0.5,0.1,0.9,0.1c7.4-0.5,14.8-0.7,22-2.6
								c2.5-0.7,5.1-1.5,7-3.4c2.4-2.4,3.3-5.8,5.4-8.5c1.9,2,3.9,3.8,6.2,5.5c2.8,2.1,6.4,5.4,9.9,6.1c2.5,0.5,3.6-3.4,1.1-3.9
								c-0.7-0.1-1.4-0.4-2.2-0.7c0.2-0.2,0.3-0.5,0.4-0.8c1.5-5.4,8.1-9.1,7.7-15.3c-0.1-1.2-0.6-2.5-1.1-3.8c1.1,0.9,2.2,1.9,3.3,2.9
								c3.1,2.7,6.4,5.7,10.6,6.3c2.5,0.4,3.6-3.5,1.1-3.9c-4.1-0.6-7-3.7-10-6.3c-3.4-2.9-6.6-5.3-9.1-9.1c-0.7-1-2.3-1.4-3.1-0.4
								c-4.8,5.8-9,12.5-15.5,16.7c-0.4-0.5-0.9-1-1.3-1.5c-5.5-6-10.7-12.5-16.6-18.2c-0.8-0.8-2-0.8-2.8,0c-5.1,4.7-11.1,9.2-17.2,12.8
								c-0.8-0.9-1.6-1.7-2.4-2.5c3.5-1.2,6.8-2.6,9.6-5.2c3.9-3.5,7.6-7.2,10.6-11.4c1.8,1.5,3.5,3.1,5.4,4.5c3.6,2.9,7.5,6.8,11.9,8.2
								c2.5,0.8,3.5-3.1,1.1-3.9c-0.9-0.3-1.9-0.7-2.8-1.3c0.2-0.3,0.3-0.7,0.3-1c0-1.1-0.3-2.9-0.1-3.8c0.2-1.5,1.2-2.8,1.9-4.1
								c1,1,2.1,2,3.2,2.9c4.2,3.4,9,6.8,14.5,7.3c2.6,0.2,2.5-3.8,0-4c-3.2-0.3-6.5-2-9.5-4.2c0.4-0.3,0.6-0.8,0.7-1.4
								c0.1-1.6,0.1-3.1,0-4.7c-0.1-1.7,0.3-2.6,1.8-3.6c0.1,0,0.1-0.1,0.1-0.1c0.6,0.7,1.2,1.3,1.8,1.9c-0.2,0.2-0.3,0.4-0.2,0.5
								c0.1,0.1,0.2,0,0.4,0.1c0.7,0.4,0.8,1.6,0.7,2.5c-0.2,1.2-0.8,1.3-0.8,2.4c0,0.1,0,1.3,0.7,2.1c0.8,0.9,1.5,0.3,3.3,1.1
								c1.6,0.7,2.9,2.1,3.2,2.4c0.7,0.7,0.7,1,1.1,1.1c1,0.3,1.6-0.9,3.6-1.8c1.9-0.9,4-1,4.6-1c1.5,0,2,0.3,2.8-0.1
								c1-0.4,1.7-1.4,1.9-2.6c0.2-1.4-0.3-2.8-1.3-3.5c-0.9-0.6-1.6-0.3-2-0.9c-0.5-0.8,0.5-1.8,0-2.6c-0.4-0.7-1.3-0.2-2-1
								c-0.4-0.5-0.5-1.1-0.7-2.4c-0.2-1.3,0-1.6,0.1-1.7c0.4-0.5,1.1-0.6,1.6-0.6c4.9,2.5,9.8,9.7,17.9,10.6c1.7,0.2,1.7-0.1,5.3,0.1
								c2.6,0.2,4.2,0.4,5.6-0.6c0.5-0.3,1-0.7,1.3-1.4c0.7-2-1.9-3.4-3.2-7.4c-0.3-1.1-1-3.1-0.5-4.6c1.1,0.4,2.5,1,3.9,1.8
								c3.7,2.3,2.8,3.5,6.2,5.5c2.8,1.6,5.6,1.8,11.2,2.2c2.1,0.1,3.8,0.1,4.4-0.9c0.3-0.4,0.3-1.2,0-1.7c-0.7-1.2-2.8-0.1-4.8-1.2
								c-1.3-0.8-1.9-2.2-2.3-3.2c-0.8-1.9-0.1-2.3-0.8-4.5c-0.8-2.9-2.3-3.5-2-5.6c0-0.2,0.2-1.6,1-1.8c0.8-0.2,1.8,0.9,2.4,1.6
								c5.5,6.3,5.7,6.8,6.8,7.7c0.7,0.5,3.1,1.2,8,2.4c5.8,1.5,7.5,1.6,8.4,0.7c0.7-0.7,0.9-1.8,1.1-3.1c0.2-1.2,0.4-2-0.1-2.7
								c-0.7-1-1.8-0.5-2.6-1.4c-0.7-0.8-0.3-1.7-0.4-3.8c-0.1-1.4-0.2-2.9-0.8-4.2c-1.1-2.2-2.6-1.8-4-4.4c-0.5-0.9-1-2.3-1.1-2.6
								c-0.6-1.7-0.5-1.9-0.9-2.7c-0.8-1.4-1.5-1.2-2.1-2.5c-0.5-1.1-0.5-2.4,0-2.7c0.3-0.1,0.7,0.2,1.2,0.6c0.7,0.5,1.1,0.8,1.7,1.6
								c1.2,1.7,7.1,8.4,11.8,12.5c1.9,1.7,3.9,2.9,7.8,5.5c2,1.3,3,1.9,4.2,2.2c3.7,0.9,7.8-0.3,7.9-1.5c0.1-1.1-3.4-1.5-8.1-3.6
								c-1.1-0.5-2.2-1-3.2-1.6c-0.7-1.5-1.7-3.7-2.4-6.6c-0.5-1.9-0.3-3.8,0.1-7.7c0-0.2,0.1-0.6,0.3-0.8c1.1-0.7,3.8,3.2,9,5.6
								c1.4,0.6,4.7,2.1,6.2,0.7c0.6-0.5,1-1.4,0.7-2c-0.4-0.8-1.8-0.8-2.7-0.9c-2.2-0.2-3.8-1.4-6.9-3.6c-2.5-1.8-5.8-4-7.4-8
								c-0.1-0.2-0.1-0.4-0.2-0.5c1.2,0,2.6,0,4,0.1c2.5,0.2,3.8,0.5,6.1,0.8c4.7,0.7,4.9,0.3,20.1-0.2c0.6,0,2.1-0.1,2.3-0.8
								c0.2-0.4-0.3-0.9-1.2-2c-2.4-2.7-3.6-4-4.2-4.8c-0.9-1.3-1-1.9-1.8-2.2c-1.1-0.4-1.7,0.4-2.9-0.1c-0.9-0.4-1.4-1.2-1.8-1.8
								c-0.4-0.7-1.2-1.9-0.7-2.9c0.3-0.7,1.1-0.9,1.2-0.9c1.5-0.4,3.1,1,3.8,1.5c2.2,1.9,4.8,3.4,10.2,6.6c3.3,2,6.1,3.1,9.1,2.8
								c0.5,0,2.2-0.2,2.3-0.9C636.4,123,637.2,122.1,634.4,121.2z M323.2,238c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0,0.1,0,0.1
								C323.4,237.9,323.3,238,323.2,238z M389.4,222.4c-2.2,3.6-6,4.7-7.8,5.6c-0.7-0.3-1.4-0.7-2-1.1c5.3-2.5,9.2-6.5,12.6-11.3
								c0.2,0.2,0.3,0.3,0.5,0.4C391.9,217.7,390.7,220.2,389.4,222.4z M479.4,208.5c0.4-0.7,0.6-1.1,0.8-1.6c0.8,0.8,1.5,1.6,2.2,2.5
								c-1.1,0.8-3.6,2.3-4,1.9C477.6,210.5,479.2,209.1,479.4,208.5z M478.9,176.3c-1.3,2.2-3.2,2.8-5,0.5c-0.9-1.3-0.9-2.7-0.5-4.1
								c0.9-1,1.7-2.2,2.4-3.4c1,1.7,1.9,3.4,3,5.1c0.3,0.4,0.6,0.8,0.9,1.2C479.4,175.8,479.1,176,478.9,176.3z M580.3,134.3
								c-1.7,1.5-2.9,2.1-3.8,2.5c0,0-0.1-0.1-0.1-0.1c0,0-2.4-2.9-5.5-7.9c-0.3-0.4-0.5-0.8-0.7-1.2c2.2-0.2,4,0.1,5.7,0.3
								c2.8,0.4,2.7,0.8,4.8,1c2,0.2,2.6-0.2,5.3-0.5C584.2,131,581.7,133.1,580.3,134.3z"
					/>
					<path
						d="M456,252.5c-11.9-0.3-14.4-10.7-21.1-17.9c-0.8-0.8-2-0.7-2.8,0c-1.4,1.3-2.6,2.6-3.8,4c-4.6-0.5-7.2-2.8-10.6-5.7
								c-0.8-0.7-2.1-0.9-2.8,0c-1.6,1.9-3.2,3.8-4.8,5.8c-1.4,1.7-2.6,4.9-5.1,5.4c-2.3,0.4-4.3-1.1-6.7-0.2c-1,0.4-1.7,1.2-2.6,1.7
								c-2.3,1.5-4,1.9-6.8,1.9c-5.8,0-9.9,0.3-14.6,3.9c-0.2,0.1-0.4,0.3-0.6,0.4c-2.8-1.3-5-4.5-6.9-6.8c-2.6-3.2-4.9-6.1-6.7-9.8
								c-0.5-1.1-2.3-1.3-3.1-0.4c-1.6,1.9-2.7,4-4,6.1c-1.7,2.6-3.9,4.8-6,7.1c-2.4,2.7-4.1,4.7-7.6,5.9c-2.8,0.9-5.8,2.3-8.6,2.8
								c-2.5,0.4-1.5,4.3,1.1,3.9c1.1-0.2,2.3-0.5,3.5-0.9c0.2,0.1,0.3,0.1,0.5,0.1c6.9,0.7,14.1,2.4,19.9-2c4.1-3.1,5.5-7.6,6.2-12.5
								c0.7,0.9,1.4,1.7,2.1,2.6c1.8,2.2,3.8,4.9,6.2,6.6c-3.7,2.8-7.3,5.4-11.3,7.9c-2.2,1.4-0.2,4.8,2,3.5c0.9-0.6,1.8-1.2,2.8-1.7
								c0,0,0,0,0,0c5.4-0.4,10.4-0.1,14.3-4.4c1.6-1.8,2.2-3.3,4.6-4.2c1.9-0.8,2.7-0.4,4.6-0.6c2.3-0.3,4-1.4,5.6-2.9
								c0.2-0.2,0.3-0.4,0.4-0.6c0.8-0.2,1.6-0.4,2.4-0.7c1.8-0.8,3-1.7,4.4-2.2c0.9,4.2-4.3,6.3-7,8.3c-1.6,1.2-1,3.6,1,3.7
								c5,0.4,12.8-3,17-6.4c0.8,0.8,1.6,1.7,2.4,2.5c-0.3,0.4-0.6,0.8-0.8,1.2c-0.9,1.5,0.3,2.8,1.6,2.9c0.3,0.1,0.7,0.2,1.1,0.1l9.2-1.4
								c2-0.3,5-0.2,6.6-1.6c1.4-1.2,1.4-3.5,1.7-5.1c0.5-3.3,0.6-7,3.9-8.7c0,0,0.1,0,0.1-0.1c1.6,2.5,3.2,5.1,5.4,7.1
								c3.8,3.5,8.7,5.4,13.9,5.5C458.6,256.6,458.6,252.6,456,252.5z M422.6,247.2c-1.6,2.7-4.2,4.4-6.4,6.4c-0.8-0.9-1.6-1.7-2.4-2.6
								c2.6-3.6,4-8.2,4.4-12.6c2.3,1.7,4.7,3.1,7.6,3.8C424.7,243.8,423.7,245.5,422.6,247.2z"
					/>
					<path
						d="M455.5,295.3c-7.1-1.5-9.5-10.8-13.1-16.2c-3.5-5.2-9.9-5.8-12.8-11.4c-0.8-1.4-2.6-1.1-3.4,0c-1.4,1.9-2.6,3.9-3.7,5.9
								c-0.3-0.3-0.5-0.6-0.8-0.9c-2.9-3.2-5.2-6.6-7.7-10c-1-1.3-2.5-1.3-3.5,0c-3.7,4.9-6.2,10.4-10.1,15.2c-3.3,4.1-6.3,8.6-10.1,12.3
								c0,0,0,0,0,0c-0.3,0.3-0.6,0.6-0.9,0.9c-0.1,0.1-0.1,0.1-0.2,0.2c-4.7,4.3-10,8.5-15.1,12.3c-5.6,4.1-11.4,6.2-17.9,8.6
								c-2.4,0.9-1.4,4.7,1.1,3.9c0.7-0.3,1.4-0.5,2.1-0.8c0.3,0.2,0.7,0.3,1.2,0.3c2.9-0.1,5.8-0.1,8.7-0.3c2.7-0.2,5.6-2,8.2-1.7
								c2.1,0.2,3.5,1.8,5.6,2.2c2.2,0.4,4.4,0.1,6.5-0.4c1.7-0.4,2-2.9,0.5-3.7c-1.7-0.9,3.3-7.7,4.3-12.8c4.6,4.5,9.7,7.9,16.4,9.5
								c2.5,0.6,3.6-3.2,1.1-3.9c-2.3-0.6-4.4-1.3-6.3-2.3c4-0.9,8.3-0.1,12.2-1.6c1.2-0.5,2.3-1.1,3.3-2c0.6-0.5,1.1-1.5,1.7-2
								c1.6-1.1,3.5-0.6,5.1-0.8c0.9-0.1,1.6-0.2,2.3-0.4c0.6,2,1.1,4.1,1.4,6.1c0.4,2.5,4.3,1.5,3.9-1.1c-0.5-3-1.3-5.9-2.3-8.8
								c1.5-4.1,1.9-9.1,2.4-13.4c0-0.1,0-0.1,0-0.1c1.1,0.5,2.1,1.1,3.1,2.4c1.1,1.5,1.9,3.1,2.8,4.7c3.4,6,5.8,12.5,13.1,14
								C456.9,299.7,458,295.8,455.5,295.3z M399.4,297.6c4.2-2.1,7.7-8.3,10.1-12.1c2.4-3.9,5.2-8.1,6.9-12.4c1.3,1.5,2.6,2.9,3.9,4.4
								c-1.2,2.3-2.5,4.7-3.9,6.9c-3.4,5.4-8.5,13.1-15.2,14.9c-0.2,0.1-0.4,0.1-0.6,0.3c-0.6-0.5-1.3-1-1.9-1.5
								C399,297.8,399.2,297.7,399.4,297.6z"
					/>
					<path
						d="M451.4,325c-5.8-1.1-10.2-2.3-15.3-5.3c-4.7-2.7-9-5.4-12.9-9.1c-0.8-0.8-2-0.7-2.8,0c-1.4,1.3-3,2.2-4.6,3.1
								c-1.4-1.2-2.7-2.5-3.9-4c-0.7-0.9-2.2-1.5-3.1-0.4c-4.3,5.3-9.2,9.2-14.8,13.1c-6.2,4.3-12.6,6.2-19.8,8.1
								c-2.5,0.6-1.4,4.5,1.1,3.9c1.3-0.3,2.6-0.7,3.8-1c0.3,0.2,0.6,0.2,1,0.2c6.6-0.6,13.2-1.5,19.1-4.6c2.1-1.1,4.2-2.6,6.2-4.3
								c0.2,0.4,0.5,0.7,0.9,1c-1.9,1.5-3.9,2.8-6,4.1c-0.7,0.4-1.5,0.8-2.2,1.3c-0.3,0.2-0.7,0.4-1,0.6c-2.2,1.3-0.2,4.8,2,3.5
								c0.1-0.1,0.2-0.1,0.4-0.2c2.5-0.6,5.1-0.1,7.5-0.8c1.9-0.5,3-1.7,4.5-2.9c3.3-2.6,8.4-2.6,9.3-7.5c0.2-1.1,0.1-1.9-0.2-2.7
								c1.9,0,3.1-2.7,1.1-3.7c-0.7-0.3-1.5-0.7-2.2-1.1c0.8-0.5,1.5-1,2.2-1.5c3.8,3.4,7.9,5.9,12.4,8.5c5.5,3.2,10,4.5,16.3,5.7
								C452.9,329.3,453.9,325.5,451.4,325z"
					/>
					<path
						d="M427.5,410.5c-4.5-3-9.8-5.7-13.8-9.4c-0.3-0.3-0.6-0.5-0.9-0.8c0,0-0.1,0-0.1,0c-4.5,1.8-2.3,9.4-8,9.3
								c-2.3,0-3.9-2-5.9-2.8c-3.7-1.6-7.4-0.1-10.6,1.9c-0.7,0.4-1.3,0.4-1.9,0.1c-0.7,0.3-1.4,0.6-2.2,0.9c-2.4,0.9-3.5-3-1.1-3.9
								c5.5-2,9-6.6,12.4-11c2.2-2.8,4.3-7,7.2-9.1c0.5-0.4,1.5-0.3,2,0c3.7,2.3,6.5,7.1,9.4,10.3c4.3,4.6,10.2,7.6,15.4,11.1
								C431.6,408.5,429.6,411.9,427.5,410.5z"
					/>
					<g>
						<path
							d="M420.4,394.1c4.1-2.1,8.1-3.3,11.6-6.3c3.7-3.3,7.3-6.8,11-10.2c1.3-1.2,1.5-2.3,3.2-1.8c1.4,0.4,3.9,3,4.9,3.8
									c4,3.2,8.3,6.1,13,8.2c2.3,1,4.4-2.4,2-3.5c-5.7-2.5-10.5-6.3-15.3-10.1c-2.2-1.7-4.5-4.2-7.4-2.6c-1.2,0.7-2.1,2.1-3.1,3.1
									c-2.3,2.2-4.8,4.1-7.1,6.4c-2.1,2.1-4.1,4.3-6.6,5.9c-2.5,1.5-5.5,2.4-8.1,3.7C416.1,391.8,418.1,395.3,420.4,394.1L420.4,394.1z"
						/>
					</g>
					<path
						d="M450.6,374.7c-9.1-3.5-16.4,8-21.6,13.6c-0.4,0.4-0.5,0.8-0.6,1.2c-1.8,0.4-3.6,0.6-5.8,0.2c-2.5-0.4-3.6,3.5-1.1,3.9
								c1.9,0.3,3.8,0.5,5.8,0.3c1.8-0.2,3.9-1.4,5.6-1.5c3.3-0.1,4.7,3.1,8.3,1.4c1.2-0.6,2.2-1.8,3.3-2.6c1.1-0.8,2.9-1.4,3.7-2.6
								c2.3-3,0.6-7.3,3.3-10.5C452.5,376.8,452.1,375.2,450.6,374.7z"
					/>
					<g>
						<path
							d="M487,412.2c-5.3-0.1-10.5-1.3-15.2-3.9c-2.3-1.3-4.6-2.9-6.4-4.8c-0.8-0.9-1.6-2.2-2.5-3.1c-1.1,1-2,2.6-3,3.5
									c-0.1,0.1-0.2,0.2-0.3,0.3c-0.6,0.5-1.2,0.9-1.8,1.3c-1.7,1.1-3.5,1.8-5.6,2.3c-2.5,0.5-3.6-3.3-1.1-3.9c0.9-0.2,1.8-0.5,2.7-0.8
									c-1.2-1-2.4-2-3.6-3.2c-2.6-2.6-2.4-2.8-4.9,0.2c-3.4,4-6.9,7.6-12.1,9c-2.5,0.7-3.5-3.2-1.1-3.9c3.8-1.1,6.5-3.5,9-6.4
									c1.5-1.7,3-4.7,5.2-5.5c2-0.8,3.3,0.2,4.6,1.6c2.1,2.1,4,4.2,6.4,5.9c0.4-0.3,0.7-0.7,1.1-1c0.9-1,1.5-2.3,2.6-3.1
									c1.5-1.1,2.6-0.8,4,0.3c3,2.4,4.6,5.4,8.2,7.5c4.2,2.5,8.9,3.6,13.8,3.7C489.6,408.2,489.6,412.2,487,412.2z"
						/>
						<path d="M459.9,403.9c-0.2,0.9-1.1,1.7-2.1,1.6c0.6-0.4,1.2-0.8,1.8-1.3C459.7,404.1,459.8,404,459.9,403.9z" />
					</g>
					<path
						d="M475.9,430.8c-1.9-1.5-3.4-3.5-5.4-5c-2.5-1.9-5.3-3.1-8-4.6c-2.6-1.5-4.9-3.2-6.5-5.8c-1.1-1.8-1.6-3.7-2.9-5.4
								c-0.9-1.1-2.5-0.5-3.1,0.4c-3.4,4.4-5.4,8.2-10,11.6c-1,0.7-1.9,1.4-2.8,2.2h0c-2.7-1.7-5.2-3.4-7.7-5.5c-2.6-2.1-4.3-1.5-7,0.3
								c-2.7,1.8-5.4,3.5-8.1,5.1c-1.2-1.1-2.4-2.2-3.3-3.6c-1-1.5-1.7-3.1-3.8-2.6c-2.1,0.5-4.3,3.4-6,4.7c-3.3,2.7-6.6,5.3-9.6,8.3
								c-1.8,1.8,1,4.7,2.8,2.8c2.3-2.3,4.7-4.4,7.2-6.4c1.3-1,3.8-3.9,5.7-5.1c0.4,0.7,0.7,1.4,1.1,2c0.6,0.8,1.3,1.4,2,2
								c-0.3,0.2-0.5,0.3-0.8,0.4c-1.7,0.9-3.4,1.7-5,2.8c-1.9,1.4-3.9,4.5-6.4,4.7c-2.5,0.2-2.6,4.2,0,4c2.9-0.2,4.8-1.9,6.9-3.8
								c0.1-0.1,0.3-0.2,0.4-0.4c0.2,0.1,0.5,0.3,0.9,0.3c5.1,0.6,10-0.7,14.5-3.2c2.3-1.2,7.7-3.7,8.8-6.7c1.2,1,2.5,1.8,3.9,2.6
								c-1.6,1.3-3.2,2.6-4.9,3.8c-2,1.5,0,5,2,3.5c0.7-0.5,1.4-1,2-1.6c0.3,0.1,0.7,0.1,1.1,0c0.7-0.2,1.3-0.4,1.9-0.7
								c0.3,0.1,0.7,0.2,1.2,0.1c5.8-1.3,13.3-2.3,17.3-7.2c0.9-1.1,1.4-2.2,1.7-3.2c0.6,0.6,1.3,1.1,1.9,1.6c2.2,1.5,4.7,2.5,7,3.9
								c3.2,1.8,5.3,4.4,8.1,6.6C475.1,435.2,477.9,432.3,475.9,430.8z"
					/>
					<path
						d="M486.7,459.6c-2.9-1.2-5.9-1.5-8.6-3.3c-2.9-1.9-5.4-4.3-7.8-6.8c-2.5-2.5-5.5-8.2-9.1-9.3c-1.7-0.5-3,0.3-4.1,1.6
								c-2.5-3-3.8-7-7.1-9.3c-0.7-0.5-1.9-0.3-2.4,0.3c-4.8,5.1-9.8,10-15,14.5c-5.5-3.6-10.5-8.9-15.3-13c-0.8-0.7-2-0.8-2.8,0
								c-2.9,2.6-5.3,5.3-8.4,7.7c-3.5,2.7-7.3,5-10.5,8.1c-1.4,1.3-0.2,3.2,1.3,3.4c0.1,0.9,0.8,1.6,1.9,1.6c3.1,0.1,6.1,0.7,8.4-1.8
								c0.4-0.5,0.8-1,1.1-1.5c1.1-0.8,2.2-1.8,3-2.5c2.9-2.5,5.3-5.4,7.3-8.6c3.5,3.2,7.1,6.6,10.9,9.2c-1.1,0.9-2.3,1.9-3.5,2.8
								c-2,1.6,0.8,4.4,2.8,2.8c0.2-0.2,0.5-0.4,0.7-0.6c2,1.4,4.5,3.3,6.9,3.4c1.9,0.1,2.3-0.9,3.5-2.2c1.1-1.2,2.4-2,3.9-2.5
								c1-0.3,1.9-0.3,2.8-1c1.8-1.5,0.6-3.7,2.8-5.1c1.4-0.9,1.2-2.5,0-3.5c-2.4-1.8-1.5-3.9-0.9-6.5c0,0,0,0,0-0.1
								c0.2-0.2,0.4-0.4,0.6-0.6c1.5,1.8,2.6,4.3,3.9,6.1c0.6,0.8,1.1,1.5,1.7,2.1c-0.2,0.3-0.4,0.6-0.6,0.9c-4.4,5.7-9.6,9.9-15.3,14.2
								c-2,1.6,0,5,2,3.5c0.1-0.1,0.2-0.2,0.3-0.3c0.5,0.3,1.2,0.4,1.9-0.1c3.3-2.2,6.7-3.2,10.6-3c2,0.1,4,0.5,5.9-0.3
								c4.4-1.8,4.1-7.4,4.6-11.4c2.8,3.2,5.7,6.3,9.1,9c4,3.2,7.7,3.9,12.3,5.7C488,464.4,489.1,460.5,486.7,459.6z"
					/>
					<path
						d="M519,624.2c-6.9-1.4-12.5-12.2-16.1-17.5c-0.7-1-2.3-1.5-3.1-0.4c-1.2,1.5-2.5,3.1-3.6,4.6c-0.1-0.1-0.3-0.2-0.5-0.3
								c-2.4-1-3.6-3.1-6.1-3.9c-0.8-0.2-1.9,0.2-2.3,0.9c-2.5,4.6-4.1-0.4-7.5-0.8c-2.5-0.3-4,1.4-5.5,3.1c-1.3,1.5-2.4,3.1-3.6,4.7
								c-3.8,2.6-7.4,5.5-10.8,8.5c-6.7-4.1-10.7-11.7-17.1-16.2c0.3-0.2,0.7-0.3,1.1-0.4c2.3-0.6,4.5-0.2,6.8-1.5
								c1.3-0.7,2.4-1.6,3.2-2.7c0.6,0.2,1.2,0.5,1.9,0.7c5.6,1.7,11.6,2.8,17.4,2c2.5-0.3,2.5-4.3,0-4c-5.5,0.7-11.1-0.2-16.4-1.9
								c-0.7-0.2-1.4-0.5-2.1-0.8c-0.2-1.2-1-2.3-2.1-3c-0.8-0.5-2-0.4-2.8-0.9c-2,0-2.2-1.3-0.4-3.7c1.5-1.6,3.4-2.3,5.7-2.1
								c0.4,0,0.8-0.1,1.1-0.2c1.4,0.7,2.9,1.4,4.4,1.9c7.1,2.6,13.5,1.4,20.8,1.6c2.6,0.1,2.6-3.9,0-4c-7.2-0.2-13.5,1-20.5-1.7
								c-2.8-1.1-5.5-2.7-8.1-4.2c-1.9-1.2-3.6-2.9-5.7-3.6c-0.7-0.2-1.4,0.1-2,0.5c-1.6,1.5-2.9,3.3-4.7,4.6c-2.3,1.6-4.8,2.8-7.3,4.1
								c-2.1,1.1-4.2,2.1-6.2,3.3c-1.5,0.9-2.8,2.2-4.3,3.2c-0.3,0.1-0.5,0.3-0.7,0.5c-0.4,0.2-0.8,0.5-1.2,0.7c-2.4,1-0.3,4.5,2,3.5
								c0.4-0.2,0.8-0.4,1.1-0.6c0.7,0.1,1.4,0.2,2.1,0.2c0.3,1.1,0.3,2,0,2.8c-1.4,0.4-2.9,0.7-4.3,1.1c-0.8,0.2-1.3,0.8-1.4,1.5
								c-0.2,0.4-0.4,0.9-0.3,1.4c0,0.2,0,0.4,0.1,0.6c0,0.4,0.1,0.8,0.3,1.1c0.1,0.2,0.3,0.4,0.4,0.5c0.3,0.9,1.2,1.5,2.3,1.2
								c4-1.2,8.3-2.2,11.8-4.6c1.2-0.8,2.3-1.7,3.4-2.7c0.9,0.7,2.1,0.9,2,2.3c0,0,0,0.1,0,0.1c-2.9,1.9-4.5,5.1-5.2,8.8
								c-0.3,1.4-0.6,2.7-0.9,3.9c-1,1.3-2.1,2.5-3.2,3.8c-3,3.4-5.9,7.6-10.3,9.3c-1.2,0.5-1.5,1.7-1.2,2.7c-0.5,1.3,0.1,3,1.7,2.8
								c2.5-0.3,5-0.1,7.4-0.4c3-0.4,6.8-2.6,9.8-1.9c1.7,0.4,2.8,1.4,4.6,1.3c2.3-0.1,4.2-1.3,6.3-2.2c2.2-0.9,0.5-4.1-1.5-3.7
								c-2.3,0.5-2-5.8-2.7-7.1c-1.5-2.8-4.3-3.4-2.4-7.5c0.2-0.4,0.3-0.9,0.2-1.2c4.9,4.3,8.5,10.1,13.9,13.8c-0.1,0.1-0.2,0.1-0.4,0.2
								c-2.2,1.2-0.2,4.7,2,3.5c1.6-0.9,3.2-1.7,4.7-2.5c3.7,0.7,5.4,5.5,9.6,4.1c1.5-0.5,2.3-1.6,3.5-2.6c1.7-1.4,3.5-1.7,5.4-1.7
								c0.5,0.1,1.1,0.2,1.7,0.3c1.2,0.2,2.4,0.1,3.6,0.1c0.2,0,0.4,0,0.5,0c1.1,0,2.2,0,3.3,0.2c0.2,0.1,0.5,0.3,0.8,0.4
								c0.8,0.2,1.7,0.2,2.5,0c2.1-0.6,4.8-3.7,7-1.8c1.9,1.7,4.5-0.9,2.8-2.8c-1.1-1.3-3-1.9-3.9-3.3c-1.1-1.6-0.4-4.8-0.2-6.7
								c0-0.2,0-0.4,0-0.5c0.4-0.5,0.8-1.1,1.3-1.6c4.2,6.5,9.6,15.4,16.9,16.9C520.5,628.6,521.5,624.8,519,624.2z M481.7,613.3
								c0.1-0.1,0.3-0.1,0.4-0.2c0.8,0.5,1.8,1.1,3,1.2c2.1,0.2,3.7-1.3,4.8-3c1.3,0.8,2.5,2,3.9,2.8c-0.4,0.5-0.7,1-1.1,1.5
								c-1.9,2.7-4.2,4.9-6.3,7.5c-0.1,0-0.2,0-0.3-0.1c-0.7-0.8-1.4-1.6-2.1-2.4c0.3-0.1,0.5-0.2,0.7-0.4c0-0.1,0.7-0.9,0.8-1
								c0.4-0.6,0.3-1.4,0-2C485.1,616.4,481.3,613.4,481.7,613.3z"
					/>
					<path
						d="M456.1,369.8c-3.9-1.7-6.6-5-9.7-7.7c-1.5-1.3-2.7-1.9-4.6-1.1c-0.6,0.3-0.9,1-1.5,1.3c-2.1,0.9,0.2,1.4-2.1,0.2
								c-3.2-1.6-5-8.3-8.8-8.9c-3-0.4-6.3,6.9-8.2,9c-1.4,1.6-2.9,3.1-4.3,4.7c-0.5,0.5-1.7,2.2-2.5,2.5c-1.2,0.4-1.4-0.4-2.6-1
								c-1.9-1.1-3.5-1.6-5.4-0.1c-0.8,0.6-1.8,3-2.7,3.3c-1,0.3-4.4-2.9-5.4-3.7c-1.2-1-2.8-2.8-4.5-2.7c-1.6,0.1-2.3,1.5-3.3,2.5
								c-1.7,1.7-3.5,3.2-5.6,4.5c0-2.3-0.3-5-1.7-6.9c3.8-0.6,7.9-2.2,10.2-5.5c2-2.9,0.3-6.7,0.3-10.1c2.5,1.8,3.4,3.8,5.3,6.2
								c1.7,2.2,4,4,6.2,5.8c1.4,1.1,3.6,3.8,5.5,2.8c2.6-1.3,0.2-3.3-1.1-4.3c2.8-0.2,4.4,2.3,7.5,0c2.2-1.6,3.5-4.3,3.9-6.9
								c0.4-2.4,0.3-5.5-1.5-7.4c-0.7-0.7-2.3-1.3-2.5-2.3c-0.4-1.6,1.9-3.1,1.9-4.7c4.9,5.9,8.9,11.2,15.9,14.8c1.7,0.9,3.5,1.7,5.2,2.5
								c1.2,0.6,3.3,2.3,4.6,2.2c3.5-0.1,1.8-3.3,0-4.4c-6.1-3.5-12.2-5.4-17.1-10.8c-2.4-2.6-4.2-5.8-6.7-8.3c-3.1-3.1-4.7,0.3-7.5,2.3
								c-1,0.7-3.2,2.3-4.5,2.2c-1.5-0.2-2.6-2.3-3.9-2.8c-3.5-1.6-4.8,2.8-7,4.9c-0.7,0.7-3.3,3.2-4.3,3.2c-1.1-0.1-2.3-2.5-3.1-3
								c-3.1-1.7-5.3,2.9-7,5c-4.4,5.6-8.6,10.3-14.3,14.8c-0.7,0.6-1.4,2.4-2.6,2.1c-1-0.2-1.9-2.2-3.4-2.4c-2.6-0.4-5.2,1.8-6.5,3.9
								c-1.2-1.1-2.7-1.3-4.2-1c-0.8,0.2-1.6,0.5-2.4,0.9c-0.7,0.9-1.2,0.9-1.6,0c-2.9-1.1-3.7-2.9-6.3,0.3c-1.4,1.7-3.4,4.4-3.7,6.7
								c-0.4,2.9,2.5,6,5.5,5.3c-0.5,1.6-0.3,3.5,0.4,5c0.4,0.9,1.1,1.4,1.6,2.1c0.4,0.2,0.7,0.5,1.1,0.7c-0.2,0.6-0.3,1.3-0.3,2
								c0.5,2.7,2.1,7.6,5.6,7.7c-0.6,1.5-0.6,3.1,0,4.6c0.9,2.2,2.6,2,3.8,3.5c0.7,0.9-0.2,3.1,0.6,4.7c0.9,1.6,2.6,2.4,4.4,1.9
								c0.1,1.2,0.3,3.8,2.3,2.7c1.4-0.8,0.4-3.2,1-4.2c0.7-1.3,2.8-1.8,3.6-3.3c0.7-1.3,0-3.9,1.9-4.2c3.2-0.5,1.8,6.9,4.8,5.2
								c0.9-0.5,0.6-1.2,0.7-2c0.2-1.9-0.3-1.2,1-2.9c1.1-1.4,2.6-2.4,3.7-3.8c0.8,1.4,1.7,2.1,2.2,3.3c0.4,0.9-0.1,2.4,1.5,2.4
								c1.6,0,1.3-1.4,1.5-2.4c0.4-1.7,0-1.1,0.9-2.6c2-3.5,4.6-6.5,2-10.6c-1.2-1.9-2.4-1.4-3.9-2.2c-1.8-1-2.2-3.9-5-4.5
								c0.2-0.2,1.6-1.5,1.9-1.6c0.8-0.1,3.2,0.9,4.1,1c2.4,0.1,4.9-0.7,6.8-2.3c1.3-1,2-2.9,3.5-3.6c2.1-1.2,3.5-0.7,5.3,0.5
								c-1.6,1.3-7.3,3.8-7.6,5.8c-0.4,2.8,3,2.4,4.8,2.3c5-0.4,13.6-2.6,14.8-8.4c2.3,1.9,4.8,3.8,7.6,4.7c3.6,1.1,3.5-1.8,5-4.3
								c0.7-1.1,1.4-2.4,2.2-3.4c0.8-1,2.5-1.5,3.1-2.6c0.9-1.8-0.9-2.3-1.3-3.9c-0.4-1.8,0.9-2.5,1.6-3.9c1.4,1.8,3.1,3.4,4.7,5
								c-1.4,1.2-5.8,3.4-5.8,5.4c0,1.9,3.2,2.7,4.7,2.6c2.6-0.2,3.5-2.5,5.4-3.7c1-0.7,1.6,0.3,2.5-1c0.2-0.4-0.1-2-0.2-1.8
								c2.6-3.2,6.3,2.3,8.3,3.8c1.2,0.9,3.2,2.6,4.8,2C457.2,372.7,457.8,370.5,456.1,369.8z M401.5,347.8c1.1,1.2,1.4,2.8,1.8,4.3
								c-1.2-0.9-2.3-1.7-3.5-2.6C400.3,348.9,400.9,348.4,401.5,347.8z M381.4,391.7c1.1-0.7,1.9-3.2,3.3-3.2c2.2,0,1.8,3.3,1.4,4.5
								c-0.3,0.9-1.9,3.9-2.9,3.5C382.2,396.2,381.3,392.3,381.4,391.7z M371.7,372.9c0.4-0.4,0.9-0.8,1.4-1.1c0.2,1.5,3.2,5.3,0.9,6
								C371.9,378.6,370.5,374.3,371.7,372.9z M370.4,367.1c0.8,1.3-0.8,3.6-1.9,4.3C368.3,370.1,368.2,363.7,370.4,367.1z M363.9,375.6
								c3.3-2.6,6.9,5.7,2.8,5.5c-1.2-0.1-4-2.3-4.1-3.6C362.6,376.7,363.7,376.6,363.9,375.6z M360.5,367c2.6-3.3,4.1,1.1,2.2,3.2
								c-0.8,0.9-0.5,1.6-2,0.9C359,370.2,359.5,368.2,360.5,367z M359.6,384.7c0.7-0.6,1.4-1.2,2-2c0.3,1.6,2.5,1.4,3.4,2.4
								c1,1.1,1.2,3.7,0.1,4.8C362.3,392.5,358.8,387.3,359.6,384.7z M356.9,378.4c0.6,0.6,1.2,2-0.3,2.4
								C355.2,381.1,356.4,378.8,356.9,378.4z M350.3,370c1-0.6,2.2-1.9,3.5-1.6c1.1,0.3,2.1,3.5,1.8,4.6C354.8,377.2,346.7,374,350.3,370
								z M345.5,373.1c-1.2,1.2-3.7,2.7-4.1,0c-0.2-1.4,1.4-3.1,2.1-4.1c0.9-1.3,1.4-3.1,2.9-1.4C347.4,368.7,346.4,372.2,345.5,373.1z
								M347.7,382.3c-2.7-3.1,1.3-7.6,3.1-4C351.5,379.9,350,385,347.7,382.3z M352.4,392.2c-1.9-2.1-4.5-7.6,0.7-6.5
								C357.6,386.8,356.8,391.5,352.4,392.2z M358.7,400.3c-2,0.8-4-1.1-3.8-3.1c0.1-1.2,2.5-4,3.7-4.2
								C361.6,392.6,361.2,399.4,358.7,400.3z M363.9,405.1c-0.9,0.7-2.9,2.6-4.1,1.6c-1.1-0.9,0-2.5,0.6-3.2
								C362.5,401.3,368.1,402.1,363.9,405.1z M367,398.2c-2.5,1.2-2.9-3.7,0-3.5C368.5,394.7,368.1,397.6,367,398.2z M371.5,390.1
								c-0.7-0.2-1.5,0-1.9-0.6c-0.2-0.4,0.1-3.2,0.3-3.3C371.3,385,375.1,389.3,371.5,390.1z M374.8,397c-2.3,2-3.2-1.6-1.1-2.4
								C376.2,393.6,376.3,395.7,374.8,397z M379.5,384.9c3-0.4,0.7,5.7-2.3,4.8C374,388.7,377.5,385.2,379.5,384.9z M381.9,373.2
								c-0.3,1.7-2.7,2.2-3.8,1.1c-1-0.9-1-3.1-1.1-4.4c-0.1-1.4-0.5-1.8,0.9-2.5c0.7-0.4,2.3-0.3,2.9,0.2
								C381.8,368.5,382.1,372,381.9,373.2z"
					/>
					<path
						d="M392.6,412.3c-0.8-1.2-2-2.2-3.5-1.7c-1.7,0.6-3.3,2-4.5,3.3c0,0,0,0-0.1,0.1c0,0-0.1-0.1-0.1-0.1
								c-1.8-1.9-3.6-1.7-5.1-0.4c-0.6-1-1.9-1.5-3.1-1.9c-1.4-0.4-3.2-0.9-4.5-0.1c-1.4,0.9-2,2.9-2.3,4.4c-0.6,2.9-1,7.5,2.1,9.1
								c0,0,0.1,0,0.1,0c-0.1,0.3-0.2,0.6-0.1,1c0.3,1.3,0.5,2.7,0.8,4c0.4,1.9,3.3,1.1,2.9-0.8c-0.3-1.3-0.5-2.7-0.8-4c0,0,0,0,0-0.1
								c0.8-0.4,1.5-1,2.2-1.9c0.5,0.7,1.2,1.3,2,1.7c0,0.8,0,1.7,0.1,2.5c0.1,0.8,0.6,1.5,1.5,1.5c0.8,0,1.6-0.7,1.5-1.5
								c0-0.6-0.1-1.2-0.1-1.9c0.7-0.1,1.3-0.3,1.9-0.8c0.8-0.6,1.3-1.5,1.6-2.5c0.3,0.4,0.7,0.8,1.1,1.2c0.3,0.2,0.5,0.4,0.8,0.5
								c0,0,0,0.1,0,0.1c0.1,1.6,0.1,3.3-0.1,4.9c-0.1,0.8,0.8,1.5,1.5,1.5c0.9,0,1.4-0.7,1.5-1.5c0.2-1.5,0.2-3,0.1-4.5
								c1.6-0.3,3.1-1.3,3.8-2.8C395.1,418.9,394.2,414.7,392.6,412.3z M375.3,419.3c0,0,0,0.1,0,0.1c-0.3,0.7-0.7,1.4-1.1,2
								c-0.9,1.2-2,0.7-2.2-0.7c-0.2-1.5,0-3.3,0.4-4.7c0.2-0.5,0.4-1.8,0.9-2c0.5-0.2,2.1,0.3,2.4,0.6C377.2,415.4,375.7,418,375.3,419.3
								z M382.3,419.4c-0.1,0.8-0.1,2.1-0.6,2.8c-0.7,0.9-1.9,0.5-2.5-0.2c-1.1-1.2-0.2-3.3,0.5-4.5c0.3-0.5,0.7-1,1.1-1.4
								c0.9-0.9,0.6-1.1,1.4-0.2c0,0,0.1,0.1,0.1,0.1C382.4,417.1,382.4,418.3,382.3,419.4z M391,420.4c-2,3.7-6.1-1.9-4.2-4.5
								c0,0,0,0,0,0c0.8-0.8,1.8-1.8,3-2.3C391.2,415,391.9,418.8,391,420.4z"
					/>
					<path
						d="M504.4,549.2c-5.2-0.7-10.3-2.2-15.2-4.1c-0.1-0.3-0.4-0.6-0.7-0.8c-1.9-1.1-3.7-2.2-5.4-3.4c-0.9-0.9-1.8-1.8-2.7-2.8
								c-0.6-0.7-6.5-7.9-4.2-8.6c1.6-0.5,2-2.8,0.5-3.7c-4.8-2.7-9.6-5.6-11.6-10.7c0.7,0.5,1.3,1.1,1.9,1.5c1.3,0.8,2.7,1.4,4,2.1
								c7,3.6,18.3,12.4,26.2,6.9c2.1-1.5,0.1-4.9-2-3.5c-5.1,3.6-12.8-1.9-17.5-4.4c-2.5-1.3-5.1-2.5-7.6-3.9c-1.8-1-3.4-2.5-5.3-3.4
								c-0.2-0.1-0.4-0.2-0.6-0.2c-0.1-0.2-0.3-0.4-0.6-0.6c-2.7-2.1-2.2-4.2-3.4-6.9c-0.7-1.6-1.2-1.2-2.3-2.3c-1.5-1.4-2.2-2.9-2.6-4.6
								c0,0,0,0,0-0.1c0.1,0.1,0.1,0.1,0.2,0.2c1.2,1.1,2.4,2.1,3.6,3.1c2.6,2.1,5.3,4.1,8.3,5.6c5.7,3,11.3,4.4,17,0.7
								c1.3-0.9,1.1-2.5,0.2-3.3c0-0.2,0-0.4,0.1-0.6c0.2-1.6-1.6-0.6-0.7-3.2c1-0.8,2-1.9,2.8-2.7c0,0,0.1-0.1,0.1-0.1
								c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.3c3,2.1,6,4.1,9.3,5.8c2.3,1.2,4.3-2.3,2-3.5c-4.9-2.5-9.3-5.8-13.6-9.1
								c-4.2-3.1-7-7.3-10.6-11c-1-1-2.4-0.6-3.1,0.4c-1.9,2.6-3.2,5.6-5.2,8.2c-2,2.6-4.6,4.5-7.2,6.5c-0.1,0.1-0.2,0.2-0.3,0.3h0
								c-1.5-1.4-2.8-2.6-4.1-3.6c-1.7-1.3-3.2-1.4-4.7,0c-1.3,1.3-2.1,3.5-3.1,5.1c-1.6,2.4-3.3,4.7-5.4,6.6c-1.2-1.8-2.2-4-3.4-5.8
								c-0.3-0.5-0.6-0.9-1-1.4c1.9-1.2,3.6-2.7,4.8-4.6c1.7-2.7,2.2-5.8,1.7-8.9c-0.3-1.5-0.8-3.1-1.6-4.3c-0.8-1-4.1-3.1-3.1-4.6
								c0.1-0.2,0.2-0.4,0.3-0.6c2.1,1.3,4.1,2.7,6,4.2c2.4,1.9,4,4.3,6.1,6.5c1.1,1.2,2.6,0.5,3.1-0.6c0.9,0.5,1.7,1,2.3,1.5
								c1.3,1.2,1.9,2.9,4,3c1.5,0.1,2.9-1,3.1-2.5c0.3-2.4-2.2-3.6-3.6-5c-0.9-0.9-2.1-2-2.6-3.2c-0.8-1.9,0.1-2.4,1.5-3.1c0,0,0,0,0,0
								c2.5,1.9,5.2,3.1,8.2,4.1c2.5,0.8,3.5-3.1,1.1-3.9c-3.4-1.1-6.4-2.6-9-5.1c-1.5-1.4-2.5-3.1-4.2-4.2c-0.9-0.6-2.2-0.2-2.7,0.7
								c-1.6,2.9-3.6,5.5-6.5,7.1c-1.9-1.3-3.8-2.6-5.8-3.9c0.4-0.8,0.3-1.9-0.8-2.5c-4.9-2.6-9.6-5.2-14.2-8.1c-1.9-1.2-4.6-4.4-7.1-4.2
								c-2.3,0.2-3.3,3-4.7,4.5c-3.5,3.7-9,6.2-13.5,8.2c-2.3,1.1-0.3,4.5,2,3.5c0.4-0.2,0.7-0.3,1.1-0.5c0.2,0,0.4,0,0.6,0
								c2.4-0.5,4.7,0.1,7-0.3c1.6-0.3,3.1-1.2,4.4-2.1c2.2-1.4,6-3.6,6.9-6.3c0.9,0.6,1.9,1.1,2.8,1.7c3.6,2.2,7.1,4.2,10.8,6.2
								c-1.5,1.3-2.5,2.8-3.2,4.4c-3.2-3-6.6-7.1-10.8-4c-3.6,2.6-6.6,4.5-9.2,8.2c-2.7,3.8-4.9,8.7-8.7,11.6c-0.3,0.1-0.6,0.3-0.8,0.5
								c-0.1,0.1-0.2,0.1-0.2,0.2c-0.2,0.1-0.4,0.3-0.7,0.4c-2.3,1.2-0.2,4.7,2,3.5c0.6-0.3,1.1-0.7,1.6-1c2.7-1.3,5.9,0.7,8.9,0.3
								c1.9-0.3,3.5-1.2,4.8-2.6c4.4-4.6,3.6-11.6,8.8-15.9c1.1,1.1,2.1,2.4,2.8,2.9c0.1,0.1,0.2,0.1,0.2,0.2c-0.7,2.8-1,5.8-2,8.7
								c-3.1,8.9-11,15.6-17.9,21.7c-1.2,1.1-0.5,2.6,0.6,3.2c0.2,0.2,0.4,0.3,0.7,0.3c2.7,0.4,4.5,2.6,7.2,3c0.8,0.1,6-1.5,6.2-1.1
								c0.8,1.4,3.3,1.2,3.7-0.5c0.5-2.8,3.8-4.9,3.5-7.6c-0.1-0.7-0.3-1.4-1-1.7c-0.6-0.3-2.5-3.6-2.1-4.3c0.3-0.4,2.1-0.8,3.5-1.2
								c0.8,1,1.6,2.2,2.3,3.3c1.1,1.9,2.1,3.8,3.4,5.5c0.5,0.7,1.1,1.4,1.8,2c0.5,0.4,1,0.8,1.6,1.1c1.5,1.8,3.1,3.6,4.6,5.5
								c0.8,1,2.6,0.7,3.1-0.4c1.2-0.9,2.4-1.7,3.6-2.6c1.4,1.8,2.1,3.4,1.9,4.7c-0.7,1.4-1.5,2.8-2.5,4c0,0,0,0,0,0
								c-0.1,0.2-0.3,0.3-0.3,0.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.8,0.9-1.5,1.7-2.3,2.6c-1.4,1-2.8,1.9-4.3,2.9c-0.1,0.1-0.2,0.1-0.3,0.2
								c-0.8,0.3-1.6,0.7-2.1,1.5c-2.8-2.6-5.3-5.7-7.6-8.2c-2-2.1-4-6.3-7.5-5.5c-3.6,0.8-5.6,5.9-7.2,8.6c-1.5,2.5-3,5.1-4.7,7.5
								c-0.7-1.1-1.9-2-2.9-2.8c-0.3-0.2-0.6-0.4-0.8-0.7c0-0.3-0.2-0.6-0.4-0.9c1.3-1.3,2.3-3.2,2.6-4.6c1-3.9-2-7-5.1-8.8
								c-0.2-0.1-0.3-0.2-0.5-0.2c-0.2-0.9-0.5-1.8-0.9-2.8c0-0.1-0.1-0.2-0.1-0.2c0.4-1,0.6-2,0.6-3.1c0-0.1,0-0.2,0-0.3
								c0.3,0,0.6,0,0.9-0.1c3.3-0.5,5.4-4.3,6-7.3c0.5-2.9-0.8-5.8-4-6c-3.4-0.1-6.4,3.1-8,5.7c-0.3,0.4-0.3,0.8-0.3,1.2c0,0,0,0,0,0
								c-0.4,0-0.9,0-1.3,0.1c-0.3-3.8-4.7-7.2-7.8-8.6c-0.2-0.1-0.3-0.1-0.5-0.2c0-0.1,0-0.2,0-0.3c2.1-0.2,4.3-1.6,5.9-2.7
								c1.5-1,2.5-2.4,2.9-3.9c0.5,0,0.9,0,1.4-0.2c2.4-0.9,4.2-3.5,3.9-6.1c-0.2-2.7-1.9-5.1-4.8-5.3c-0.9-0.1-1.8,0.1-2.6,0.4
								c0-0.6,0-1.2-0.2-1.7c-0.8-3-5-3.9-7.6-2.9c-1.7,0.6-3.1,2.7-3.6,4.7c-1.7-1.3-4.4-1.5-6.3-0.9c-0.6,0.2-1.1,0.4-1.5,0.8
								c-2.6-2-5.5-3.4-8.7-1.6c-2.1,1.2-4,3-4.4,5.3c-0.3,0.1-0.6,0.2-0.9,0.5c-0.1,0.1-0.3,0.3-0.4,0.4c-0.3-0.4-0.6-0.9-1-1.2
								c-1.2-1-2.8-2.4-4.4-2.6c-1.9-0.2-3.5,1.2-4.7,2.4c-1,0.9-1.8,2-2.3,3.3c-4.5-0.9-10.6,4.1-8,8.7c0.5,0.8,1.1,1.6,1.8,2.2
								c-2.3,1.6-3.2,4.3-1.3,7c1,1.4,2.6,2.3,4.1,3c0.9,0.4,1.8,0.6,2.7,0.7c-0.4,1.6-0.5,3.2-0.3,4.8c0,0,0,0,0,0l0,0
								c0,0.2,0,0.4,0.1,0.5c0.3,2.1,3.2,1.7,3.8,0c0.2,0.7,0.5,1.4,0.6,2.2c0.1,1.1,0,2.1,0.3,3.2c0.5,1.7,2.3,2.6,4,2.1
								c1.2-0.4,1.7-1.2,2.3-2c0.4,1.4,1.2,2.6,2.7,3.3c-0.2,1.7-0.4,3.3-0.6,5c-0.1,1.1,1,2,2,2c1.1,0,1.9-0.9,2-2
								c0.1-1.3,0.2-2.6,0.4-3.9c0.5,0,1,0,1.4-0.1c0.6-0.2,1.2-0.5,1.7-0.9c0.6,0.6,1.3,1.2,2,1.7c-1.4,0.5-2.8,1.2-3.7,2.2
								c-1.3,1.5-1.4,3.5-1.2,5.3c0.1,0.5,0.3,0.9,0.5,1.2c0.7,1.2,2.2,2.1,3.6,2.5c-0.1,2.2-0.9,4.7-0.3,6.7c0.7,2.5,4.6,1.4,3.9-1.1
								c-0.3-0.9,0.1-2.2,0.2-3c0.1-0.6,0.1-1.2,0.2-1.7c1.5,0,3-0.1,4.1-1c0.7,1.2,1.6,2.3,2.7,3.1c-0.1,0.1-0.2,0.3-0.3,0.5
								c-3.2,0.3-6.1,2.6-6.4,6.2c-0.2,3.5,2.1,5.8,5,7.3c-2.4,0.8-4.4,2.8-5.2,5.1c-0.1,0.2-0.1,0.4-0.2,0.6c-3.1,0.6-6.4,3.5-6.9,6.7
								c-0.5,3.1,0.9,7.9,3.6,9.8c-0.1,0.2-0.1,0.4-0.1,0.7c0.1,2.1,0.1,4.2-0.2,6.3c-0.2,1.1,0.3,2.1,1.4,2.5c0.9,0.3,2.3-0.3,2.5-1.4
								c0.3-1.9,0.4-3.8,0.4-5.8c0.9,2.3,2.5,4.3,4.7,4.6c-0.4,0.6-0.7,1.2-0.9,1.8c-0.7,2-0.4,4,0.5,5.8c-0.1,0-0.2,0-0.3,0
								c-0.1,0-0.2,0-0.4,0c-1.4,0.2-2.8,0.9-4.2,0.6c-0.1,0-0.1,0-0.2-0.1c-0.2-0.2-0.5-0.3-0.7-0.3c-0.6-0.4-1.2-1-1.7-1.6
								c-0.1-0.2-0.3-0.4-0.4-0.5c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.4-0.4-0.7-0.7-1.1-1.1c-1.9-1.7-3.9-1.3-6-0.1
								c-3.6,2-5.3,5.1-4.7,8.5c-0.1,0.1-0.2,0.2-0.3,0.2c-0.7,0.2-1.4,0.6-2,1c-0.7-0.9-1.4-1.8-2.3-2.5c-0.9-0.8-1.9-0.6-2.6-0.1
								c-0.6,0.1-1,0.5-1.2,0.9c-1.5,0.8-2.6,2.1-3.3,3.7c-3.3-1.5-7,1-9.1,3.6c-2.7,3.5-2.4,7.9,1,10.9c0,0,0.1,0,0.1,0.1
								c0,0.1,0,0.1,0,0.2c0.2,0.5,0.2,1.1,0.3,1.6c-0.1,0.2-0.1,0.5-0.1,0.7c0.3,2.7,0.1,5.4-0.2,8.1c-0.3,2.6,3.7,2.5,4,0
								c0.2-1.7,0.4-3.5,0.4-5.2c0,0,0-0.1,0.1-0.1c0.4,1.6,1.2,3,2.6,3.9c0.2,0.1,0.4,0.2,0.6,0.4c0.2,2.4,0.3,4.8,0.2,7.2
								c-0.1,2.6,3.9,2.6,4,0c0.1-2.3,0-4.6-0.1-6.9c0.3-0.1,0.6-0.2,0.8-0.4c0.4-0.2,0.7-0.5,1-0.8c0.8,1.9,2.1,3.6,3.8,4.6
								c-0.7,2.8-0.9,5.7-1,8.7c-0.1,2.6,3.9,2.6,4,0c0.1-2.6,0.2-5.2,0.9-7.8c0.5,0,1-0.1,1.4-0.3c0,0.1,0,0.2,0.1,0.3
								c0.5,1.8,1.7,3.7,3.3,4.9c-0.2,0.3-0.3,0.6-0.2,1.1c0.2,2.1,0.3,4.1,0.5,6.2c0.2,2.6,4.2,2.6,4,0c-0.1-2-0.3-3.9-0.4-5.9
								c0.4,0,0.8-0.1,1.1-0.2c1.1-0.3,1.9-1,2.6-1.8c0.8,1.4,2,2.5,3.2,3.4c0.4,0.3,0.8,0.5,1.2,0.7c0.3,2.5-0.1,5.1,0.5,7.5
								c0.6,2.5,4.5,1.4,3.9-1.1c-0.3-1.3-0.1-2.7-0.2-4.1c0-0.4-0.1-0.9-0.1-1.3c1,0,1.9-0.1,2.6-0.6c0.4,0.5,1,0.9,1.6,1.2
								c0.2,0.1,0.4,0.2,0.6,0.3c0,0,0,0.1,0,0.1c-0.1,2.6-0.1,5.2-0.1,7.8c0.1,2.6,4.1,2.6,4,0c-0.1-2.4,0-4.9,0.1-7.3
								c0.9-0.2,1.7-0.7,2.4-1.4c0,0,0.1,0.1,0.1,0.1c1.2,0.8,2.7,1.1,4.2,1.2c0.3,3,0.3,5.9,0,8.9c-0.3,2.6,3.7,2.5,4,0
								c0.3-2.9,0.3-5.8,0-8.6c0.7,0.3,1.5,0.4,2.5,0.4c2.5-0.1,4.4-0.9,5.6-2.5c0.4,2.7,0.5,5.4,0.5,8.1c0,2.6,4,2.6,4,0
								c0-2.2-0.1-4.5-0.3-6.7c0.5,0,1,0,1.4-0.1c3.2-0.8,5.2-3.8,4.9-7c-0.3-2.9-2.4-4.9-4.9-5.7c0-0.2,0-0.3,0-0.5
								c-0.3-3.4-2.4-5.9-5-7.9c-0.3-0.6-0.9-1.1-1.7-1.1c-0.2-0.1-0.4-0.3-0.6-0.4c-0.6-0.3-1.2-0.3-1.7-0.1c-0.3-0.1-0.6-0.2-0.9-0.2
								c0.1-0.5,0.1-0.9,0-1.4c-0.1-0.6-0.2-1.1-0.4-1.6c3-0.7,5.4-2.7,5.4-6.2c0-2.2-0.9-4-2.2-5.5c0.4-0.1,0.7-0.3,1.1-0.6
								c2.2-1.5,3.3-4,3.1-6.5c0.2-0.2,0.5-0.4,0.7-0.5c0.2,0.1,0.4,0.2,0.6,0.3c2.3,0.6,3.8,2.5,5.8,3.7c2.1,1.2,4.4,0.9,6.5,1.8
								c1.2,0.5,3.2-0.1,3-1.7c-0.3-3.1-0.6-4.9,2.4-6.5c1.2-0.6,2.1-0.9,2.7-2.2c0.9-2.1-0.3-3.6-1.2-5.3c0.1-0.2,0.3-0.4,0.4-0.5
								c0.5,0.4,1,0.7,1.5,1c3.2,1.9,6.5,3.5,9.8,5c5.9,2.6,11.6,5.4,18.1,5.7c1,0,1.6-0.5,1.8-1.3c1.3-0.7,2-1.8,2.2-3.1
								c0,0,0.1-0.1,0.1-0.1c2.3-3.3-3.3-7-5.2-8.7c-1.4-1.2-2.7-2.5-4.6-2.9c-1.7-0.4-2.2,0.4-3.7-1.1c-0.2-0.4-0.4-0.8-0.5-1.2
								c-1.3-1.5-0.9-3,1.3-4.3c0.2,0.1,0.3,0.1,0.5,0.3c0.4,0.3,0.9,0.4,1.3,0.3c0.2,0.1,0.3,0.2,0.4,0.3c2.8,1.8,5.7,3.5,8.8,4.8
								c5.8,2.5,11.9,4.2,18.2,4.5c2.6,0.1,2.6-3.9,0-4c-6.3-0.3-12.6-2.2-18.3-4.9c-2.3-1.1-4.6-2.5-6.8-3.9c-1.7-1.1-2.9-2.8-4.5-3.8
								c-2.2-1.4-3.4-0.9-4.9,1.1c-0.6,0.8-1.2,1.6-1.9,2.2c-0.2-0.4-0.6-0.8-1.2-0.9c-4.6-1.2-7.8-4.1-10.7-7.5c6-0.2,10.8-0.1,15.9-3.7
								c1.8-1.2,3.2-3.1,4.9-4.3c0.6-0.9,0.9-1.3,1.1-1.2c0,0.3,0,0.6,0,0.9c0,0.1,0,0.2-0.1,0.3c0,0.9-0.2,1.8-0.5,2.7
								c-0.5,1.8-1.5,3.3-2.6,4.8c-1.2,1.6,1.1,3.8,2.7,2.7c3.7-2.5,7.2,2.2,10.3,4c3.5,2.1,6.6,2.8,10.7,2.3c3-0.4,9.5-2.9,5.3-6.9
								c-0.8-0.7-1.5-0.7-2.4-1.1c-2.7-1.1-2.8-1.3-3.9-3c6.9,3.5,14.4,5.9,21.9,6.9C505.8,553.5,506.9,549.6,504.4,549.2z M473.1,482
								c2.7,3.1,5.1,6.3,8.4,8.8c0.3,0.2,0.5,0.4,0.8,0.6c-0.7,0.5-1.4,1.1-2,1.8c0,0-0.1,0-0.1,0c-2.4-0.2-3.6-1.1-5.2-2.9
								c-1.4-1.6-2.8-3.4-3.8-5.3C471.9,484,472.5,483,473.1,482z M419.5,477.3c-0.1-0.1-0.3-0.3-0.4-0.4c0.2-0.2,0.5-0.5,0.9-0.8
								C419.8,476.4,419.7,476.9,419.5,477.3z M402.7,474.7c1,0,1.4,1.5,1.1,2.3c-0.2,0.5-0.6,0.7-0.9,1c-0.1-0.1-0.1-0.2-0.2-0.2
								c-0.6-0.8-1.2-1.5-1.9-2.1C401.3,475.1,401.9,474.7,402.7,474.7z M351.3,481.9c0.9-0.5,2.5-0.9,3.1,0c0.1,0.1,0.2,0.2,0.3,0.3
								c0.2,0.4,0.4,0.7,0.7,1c-0.1,0.2-0.2,0.4-0.3,0.7c-0.6,2.4-2.8,2.9-4.4,1.2C349.7,484,349.9,482.7,351.3,481.9z M355.5,495.4
								c-0.2,0-0.4-0.1-0.6-0.1c-1.1-0.2-2.7-0.6-3.4-1.3c-2.3-2.4,1-4.2,3.1-2.7C356.3,492.5,356.1,493.9,355.5,495.4z M358.3,478.7
								c0.3-0.7,2.1-3,2.8-2.9c0.4,0,1.3,0.8,1.8,1.2c0,0,0,0.1,0,0.1c0,1.5-1.4,2.8-2.7,3.4C358.6,481.4,357.6,480.2,358.3,478.7z
								M360,485.3c1-0.7,2.3-1,3.6-1.1c0.5,1,0.7,2.1,0,3.1c-0.3,0.4-1,0.8-1.7,0.9c-0.4,0-0.7,0.1-1.1,0.1c-0.5,0-1-0.1-1.4-0.2
								C358,487.3,359,485.9,360,485.3z M365,504.2c-0.9,0.9-2.1,1.1-2.9,2.2c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.9-0.4-1.7-0.7-2.6
								c-0.1-0.2-0.6-2.7-0.3-2.5c0.3,0.1,0.5,0.2,0.8,0.2c0.2,0.1,0.4,0.1,0.6,0.1C363.9,501.1,366.5,502.7,365,504.2z M365.9,494.1
								c-0.3,1.1-2.6,1.6-3.5,1.5c-1-0.1-1.8-0.7-1.9-1.8c-0.1-0.9,0.2-1.4,0.7-1.6c0.5,0,1-0.1,1.4-0.2c0.2,0,0.4,0,0.6,0.1
								c0.3,0,0.6,0,0.8,0C365,492.5,366.1,493.2,365.9,494.1z M381.4,557.9c-0.7,0.9-1.3,2-1.7,3.1c-0.4,0.7-0.9,1.1-1.9,0.2
								c-1.1-0.9-1.8-4.3-1.3-5.6c0.5-1.1,1.7-2.1,3-2.6c0.8,1.3,1.9,3.1,1.9,4.6C381.4,557.6,381.4,557.7,381.4,557.9z M350.3,595.4
								c-0.2,0.1-0.3,0.3-0.4,0.5c-0.7,1.1-1.1,2.4-2.7,1.9c-1.1-0.3-1.3-1.6-1.3-2.6c0-0.1,0-0.3,0-0.4c0.2-0.3,0.4-0.7,0.6-1
								c0.8-1,2.4-2.8,3.8-2.5C352.1,591.7,351.2,594,350.3,595.4z M357,604.5c-0.1,0.3-0.1,0.5-0.1,0.7c-0.7,1.7-1.7,4.1-3.4,2.9
								c-1.5-1.1-0.9-4.8-0.5-6.3c0.4-1.6,2.3-6.1,4.4-3C358.4,600.2,357.4,603.1,357,604.5z M359.8,593.8c-1.5-0.5-3.8-1.7-4-3
								c-0.2-1.5,1-2.8,2.2-3.5c0.5,0.6,1,1.3,1.4,2.1c0,0.3-0.1,0.6,0,0.9c0.1,0.9,0.4,1.7,0.8,2.4C360.1,593.1,360,593.5,359.8,593.8z
								M417.5,620.8c0.6-0.9,1.5-1.7,2.4-2.2c1.3,1.4,1.3,3.9-1.1,4.2C417.3,623.1,416.7,622,417.5,620.8z M412.7,532.7
								c0,0,0,0.1-0.1,0.1c-0.5,0.5-1,1.1-1.4,1.7c-0.3,0.2-0.7,0.3-1.1,0.3c-0.7,0.1-2.4-0.2-2.9-0.9c-0.7-1,0.1-2.1,0.6-2.9
								c0.7-1,1.5-1.9,2.3-2.7c0.6,0.5,1.1,0.9,1.7,1.4C412.9,530.7,413.3,530.9,412.7,532.7z M407.1,498.6c0.7-1.2,1.6-2.2,2.7-3
								c0.8-0.6,2.1-1.4,2,0.1c-0.1,1.2-0.8,2.6-1.5,3.5c-0.4,0.5-0.8,0.9-1.5,0.9c-0.7,0.1-1.7-0.3-1.7-1.1
								C407.1,499,407.1,498.8,407.1,498.6C407,498.6,407.1,498.6,407.1,498.6z M406,518.8c0.2-1.7,1.6-3,3.2-3.5c0.9,0.7,1.7,1.5,1.8,2.5
								c0.2,1-0.8,3.4-1.9,3.9c-0.7,0.3-1.7-0.5-2.2-0.9C406.1,520.1,405.8,519.9,406,518.8z M406.3,542.9c-0.6,1.5-2,3.2-3.8,2.9
								c-2-0.3-1.7-2.3-0.5-3.4c1.1-1.1,2.7-1.7,4-2.4C406.5,540.8,406.8,541.7,406.3,542.9z M390.8,596.8c2,0,3.5,1.1,3.1,3.5
								c-0.2,1.1-1.1,1.1-2.1,1.1c-0.6,0-1.2,0.1-1.8,0c-0.4-0.3-0.7-0.7-0.8-1.4C389.1,598.7,389.8,597.5,390.8,596.8z M389.1,579.3
								c-1.3-1.2-2.1-2.6-1.3-4.4c0.2-0.5,0.7-0.9,1.1-1.4c0.3,0.3,0.7,0.5,1.3,0.6c1.8,0.1,2.7,1.8,2.6,3.5c0,0.1,0,0.3-0.1,0.4
								c-1,0.2-1.6,1.1-1.6,2c0,0,0,0,0,0C390.5,580.2,389.8,579.9,389.1,579.3z M393.9,583.3c0.3-0.2,0.6-0.5,0.9-0.8
								c0.4,0.2,0.7,0.5,1,0.8c0.1,0.4,0.3,0.8,0.5,1.1c0,0.1,0,0.2,0,0.2c0,0.2,0,0.5-0.1,0.7c-0.2,0-0.3,0-0.5,0.1
								c-0.3-0.1-0.5-0.2-0.6-0.2c-0.4-0.2-0.7-0.5-0.9-0.9C394,584,393.9,583.6,393.9,583.3z M394.3,570.9c-2.3-3.4-0.2-9.7,3.9-7.6
								c0.4,1.4,0.5,2.9,0.3,4.4C398.2,569.5,396.6,574.2,394.3,570.9z M394.5,555.4c-2.2-1-3-2.7-2.6-4.8c0-0.1,0-0.1,0-0.2
								c0.1-0.3,0.1-0.6,0.3-0.8c0.6-1.5,1.6-3.2,3.6-2.8c0.4,0.1,0.8,0.3,1.2,0.4c0.1,0.2,0.2,0.3,0.4,0.5c1.8,1.4,3.2,3.4,2.9,5.8
								C400.1,556.9,396.9,556.5,394.5,555.4z M399.7,534.8c1.6,2,0,3.8-2,4.8c-0.5,0.3-0.7,0.7-1,0.3c0.3-0.9,0.4-2,0.3-3.1
								c0-0.7-0.2-1.4-0.4-2c0.1-0.7,0.2-1.4,0.4-2C398.2,533.2,399.3,534.2,399.7,534.8z M399.9,523.5c0.6-0.6,1.3-0.8,1.9-0.8
								c1.4,1,2.2,2.8,2.1,4.5c-0.1,0.8-0.4,2.1-1.2,2.6c-1.1,0.5-1.8-0.3-2.5-1.1C398.9,527.1,398.3,525,399.9,523.5z M402.3,574.4
								c1.5,2.8,1.8,10.8-1.3,9.7c-0.3-0.1-0.6-0.3-0.8-0.6c0-0.3-0.1-0.6-0.2-0.9c-0.2-0.5-0.4-0.9-0.7-1.4c-0.2-1.1-0.3-2.3-0.3-3
								C399,575.8,400.2,574.7,402.3,574.4z M402,501.9c2-0.7,2.8,0.9,2.8,2.6c0,1.8-0.7,3.2-2.5,3.6c-1.1,0.3-1.4,0.1-2.1-0.8
								c-0.5-0.7-1-1.6-1.4-2.4C399.7,503.7,400.8,502.4,402,501.9z M399,515.6c0.7-0.3,1-0.8,1-1.4c0.7-0.9,1.7-1.6,2.3-1
								c0.9,0.9-0.2,3.1-1,3.8c-0.9,0.7-3.3,1.5-4.4,1.1c-0.9-0.4-0.3-1.1,0.5-1.6C398,516.5,398.6,516.2,399,515.6z M394.3,493.9
								c1,0.7,1.9,1.6,2.7,2.5c1.9,2.2-0.1,4.2-1.8,5.7c-0.6,0.5-1.2,1.3-1.9,1.5c-2,0.7-2.7-1.3-2.8-3c-0.1-2.3,0.9-4.4,2.5-6
								c0.2-0.2,0.3-0.4,0.4-0.6C393.7,494.1,394,494,394.3,493.9z M393.3,471.5c0.1-0.4,0.3-1,0.7-1.3c0.6-0.4,2.3-0.2,2.5,0.3
								c0.3,0.7-0.2,1.7-0.7,2.3c-0.8-0.3-1.6-0.5-2.4-0.6c-0.1,0-0.2-0.1-0.2-0.1C393.2,471.9,393.2,471.7,393.3,471.5z M390.6,478.4
								c0.2-0.9,1.1-1.6,1.8-2.2c1,0.5,1.9,0.8,3,0.9c1,0.6,1.9,1.2,2.2,1.5c0.8,0.8,1.7,2.2,1.1,3.3c-0.4,0.8-1.7,1.3-2.5,1.7
								c-0.8,0.4-2.3,1.5-3.2,1.2c-0.6-0.3-1.3-2.2-1.5-2.8C390.9,480.9,390.3,479.6,390.6,478.4z M390.9,511.3c0.6,0,1.2-0.3,1.6-1
								c0.1-0.2,0.3-0.6,0.5-0.9c0.2-0.2,0.4-0.4,0.5-0.7c0.1-0.1,0.2-0.2,0.3-0.2c0.8-0.2,1.3,0.7,1.6,1.2c1.2,1.7,1.3,2.7-0.7,3.5
								c-1,0.4-3.5,1.1-4.2-0.3C390.2,512.4,390.5,511.9,390.9,511.3z M390.7,519.8c1.5,0.8,3,2.6,3,4.4c0.1,2.5-2.6,3.1-4.4,2.1
								c-1.4-0.8-2.5-2.2-2.7-3.9c-0.2-2.1,1.3-4,3.5-2.8C390.3,519.7,390.5,519.7,390.7,519.8z M383.5,474.4c0.4-0.4,0.8-0.5,1.3-0.5
								c0.2,0,1.8,0,1.9,0.3c0.1,0.3,0.2,0.6,0.4,0.8c0,0,0,0,0,0c-0.4,1.2-1.4,2.5-2.4,3.2c-0.5,0.4-1.1,0.6-1.5,0.5
								c-0.1-0.2-0.3-0.3-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.7C382.5,476.8,382.7,475.2,383.5,474.4z M382.9,484.4c0.2,0,0.4,0,0.6-0.1
								c2.1-0.5,4.1,0.6,5.4,2.2c2.2,2.7-0.4,5-3.1,5.3c-1.7,0.2-3.8-0.2-4.7-1.6c0-0.7-0.2-1.4-0.4-2
								C380.9,486.6,381.8,485.4,382.9,484.4z M379.6,497.1c0.9-1.3,1.9-1.6,3.2-1.4c0.3,0.3,0.7,0.4,1.3,0.4c0,0,0.1,0,0.1,0
								c0.3,0.1,0.6,0,0.8,0c0.6,0,1.2,0,1.3,0.2c0.2,0.5-0.6,1.3-0.9,1.7c-1,1.3-2.5,2.9-4.3,2.5C379.3,500.2,378.7,498.4,379.6,497.1z
								M374.4,473.1c1.9-1,3.3,0,4.7,1.2c-0.4,1.1-0.6,2.4-0.6,3.5c0,0.5,0,0.9,0.1,1.4c-1.2,0.5-2.5,0.8-3.5,0.3
								c-0.7-1.3-1.6-2.5-2.8-3.3C372.2,474.9,373.1,473.8,374.4,473.1z M369.3,479.1c0.7,1.1,1.6,2,2.6,2.9c0.1,0.1,0.1,0.3,0.2,0.4
								c0.3,0.8,0.3,2.4-0.5,2.6c0,0,0,0-0.1,0c-0.2,0-0.4,0-0.7-0.1C368.4,483.8,368.1,481.1,369.3,479.1z M372,509.7
								c-0.6,0.9-2.4,0.3-3.2-0.4c-0.9-0.8-0.2-2.3,0.5-3c0,0,0.1,0,0.1-0.1c0.3,0.3,0.7,0.5,1.3,0.5C372,506.8,372.6,508.8,372,509.7z
								M375.8,502.1c-0.6,0.6-3.7-1.1-4.6-1.5c-0.9-0.4-4-2.1-1.4-2.3c1.2-0.1,2.6,0.1,3.9,0.2C374.6,499.4,376.6,501.4,375.8,502.1z
								M372.4,494c-1.1-0.3-3.7-3.1-2.6-4.2c0.3,0,0.5-0.1,0.8-0.2c0.2-0.1,0.5-0.2,0.7-0.3c0.2,0,0.4,0,0.6,0c0.6-0.1,1.1-0.3,1.6-0.6
								c0.8-0.2,1.5-0.2,2.2-0.1c0.4,0.1,0.7,0.3,0.9,0.6c0,0.6,0.2,1.3,0.4,1.9c0,0.6-0.2,1.1-0.4,1.5C376,494.3,374.1,494.4,372.4,494z
								M381.3,522.7c-0.5,0.5-1.8,0.3-2.5,0.3c-0.2,0-0.3-0.1-0.5-0.1c-0.2-0.2-0.5-0.3-0.8-0.4c-0.3-0.2-0.5-0.4-0.7-0.8
								c-0.3-0.4-1-1.4-1-2c0.3-0.3,0.6-0.6,1.2-0.8c2.1-0.9,3.8-0.7,4.4,1.7C381.6,521.3,381.8,522.2,381.3,522.7z M385.8,511
								c-0.6,0.9-1.6,1.8-2.7,2.1c-1.4,0.4-2.3-0.2-3.5-0.9c-0.6-0.4-1.5-0.8-2.1-1.4c0.3-0.4,0.5-1,0.3-1.7
								C376.2,503.5,390.5,504.1,385.8,511z M383.9,537.2c-0.9-2.7,2-4,4.2-3.5c0.4,0.1,0.7,0.1,1,0c0.8,0.4,1.5,1,2,1.9
								c0.4,0.8,1.1,3.8,0,4.3c-0.3,0.2-0.5,0.4-0.7,0.6c-0.3,0-0.6-0.1-0.9-0.1C387.7,540,384.5,539,383.9,537.2z M383.5,549.1
								c0.6-1,1.8-2.1,2.9-2.4c2.1-0.5,2.1,1.3,1.7,2.8c-0.1,0.2-0.1,0.4-0.1,0.5c-0.1,0.5-0.1,1-0.2,1.5c-0.1,0.1-0.2,0.3-0.2,0.4
								c-0.6,1.5-1.8,2-3.1,0.9C383.4,552,382.7,550.5,383.5,549.1z M383.5,562.4c0.3-0.4,0.6-0.9,0.8-1.3c0.3-0.5,0.5-1,0.6-1.4
								c0.6-0.6,1.5-1,2.5-0.9c0,0,0,0,0,0c1.6,0.6,2.3,1.9,1.8,4.1c-0.3,1.3-1.7,4.7-3.3,5C383.8,568.3,382.9,565.1,383.5,562.4z
								M384.1,583.9c0.2,0,0.5,0,0.7,0c2.2,0.1,3.5,1.4,4.4,3c0.3,1.1,0.9,4,0.1,4.9c-1.3,1.4-3.4-1.7-4-2.6c-0.3-0.5-0.6-1.1-0.9-1.6
								C384.1,587,383.7,584.7,384.1,583.9z M379.2,593.9c0.5-0.6,2.3-0.3,3.4,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.3
								c0.3,0.5,1,4,0.2,4.5c-1,0.6-2.9-1.9-3.4-2.5c-0.2-0.2-0.3-0.3-0.4-0.5C378.9,595.1,378.8,594.3,379.2,593.9z M368.1,581.9
								c0.5-1.1,2.7-3.1,4.1-2.7c0.2,0,0.4,0.2,0.6,0.4c0.2,0.3,0.5,0.6,0.8,0.9c0.4,0.6,0.8,1.1,0.9,1.3c0.3,0.5,0.6,0.9,0.9,1.4
								c0.6,1.2,0.9,2.5,0.5,3.7c-0.4,1.3-1.3,1.9-2.6,1.3c-1.1-0.5-2.3-1.1-3.4-1.6c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2-0.1-0.2-0.1
								c-0.2-0.3-0.5-0.6-0.7-0.9C368.1,584.6,367.6,583.2,368.1,581.9z M367,611.9c-1.1,0.7-2.6,0.8-3.7,0c-1.3-1-2.5-3.1-1.9-4.7
								c0.4-1.1,1-1.5,1.8-1.5c0.2,0.5,0.6,0.9,1.3,1.1c1.5,0.3,3.3,0.7,3.7,1.9C367.7,609.5,367.3,610.7,367,611.9z M369.2,602.3
								c-0.4,0.6-0.6,0.9-1,0.9c-0.8-0.4-1.6-0.8-2.4-1c-0.2-0.1-0.5-0.3-0.7-0.4c-1.2-0.7-3.6-3.1-1.2-4c1.5-0.6,4,0.7,5,1.7
								c0.4,0.4,0.9,0.6,1.3,0.6C370,600.8,369.7,601.6,369.2,602.3z M369.9,593.2c-1.6-0.3-3.6-0.7-5-1.6c-0.5-0.3-0.8-0.5-1-0.7
								c0-0.3-0.1-0.7-0.2-1c0-0.1,0-0.2,0.1-0.3c0.2-0.6,0.6-0.9,1.1-1.1c0.9,0.6,1.8,1.1,2.6,1.7c0.9,0.8,1.9,1.5,2.9,2.1
								C371.1,592.9,371.4,593.4,369.9,593.2z M373.9,618.6c-1.8-0.1-2.9-2.7-3-4.3c0-0.1,0-0.3,0-0.4c0.5-0.6,0.9-1.2,1.1-2
								c0.1-0.3,0.2-0.6,0.2-1c1.5,0.1,2.8,2,3.3,3c0.1,0.3,0.3,0.5,0.5,0.6C376,616.3,375.4,618.6,373.9,618.6z M375.5,606.3
								c-0.4-0.7-0.7-1.6-0.6-2.4c0-0.8,0.1-1.3,0.3-1.4c1.4,0.7,3.1,1.2,4.5,1.8c1.2,1.4,1.8,2.8,0.5,3.7
								C378.5,609.1,376.3,607.8,375.5,606.3z M386.9,622.1c-0.6,0.3-2.5-0.7-3-1c-2-1.2-3.5-3.3-3.4-5.8c0.1-1.7,1-2.3,2.1-2.2
								c0,0.6,0.2,1.3,1,1.7c1.8,1,3.2,2.5,3.6,4.6C387.4,620,387.6,621.7,386.9,622.1z M388.2,611.3c-1.1-0.7-2.7-2.2-2.4-3.7
								c0.3-1.9,2.5-1.4,4-1.1c0.2,0.4,0.6,0.8,1.1,1c1.6,0.6,2.2,2.3,1.6,3.8C391.8,613.4,389.6,612.2,388.2,611.3z M393.6,623.4
								c-4.1-1.2-1.3-7.2,1.1-6.5c0.3,0.4,0.6,0.9,0.8,1.4c-0.3,1.2-0.1,2.4,0.2,3.5C395.5,622.9,394.8,623.8,393.6,623.4z M396.5,592.2
								c-0.7-0.7-1-0.8-0.4-1.6c0.2-0.3,0.6-0.6,1.1-0.9c0.1,0,0.3,0,0.4-0.1c1,0.2,2,0.4,2.6,1c0.5,0.5,0.6,0.9,0.6,1.6
								c0,0.9,0,1.3-0.9,1.5C398.5,594,397.3,593,396.5,592.2z M399.9,621.5c0.1-1,0.1-1.9-0.1-2.8c0.6-1,1.4-1.8,1.6-0.1
								C401.5,619.7,400.8,620.8,399.9,621.5z M405.6,613.5c-0.1,0.4-7.3-0.5-6.2-2.5c1.2-0.1,2.3-0.1,3.5,0
								C403.9,611.2,406,612,405.6,613.5z M402.8,605.6c-1.9-0.3-4.1-1-3.8-2.5c0.1-0.2,0.1-0.3,0.2-0.5c0.6-1.7,2.1-2.4,3.8-2.4
								c1.2,0.3,2.4,0.9,2.8,2C406.6,604.2,404.6,605.3,402.8,605.6z M412.4,608.7c0.8,0.6,1.6,1.3,2.2,2.1c0.8,1.1,2.5,6.2-0.7,4.9
								c-1.1-0.5-2.8-2.4-3.5-3.4C409.1,610.2,410.6,609.3,412.4,608.7z M411.1,620.4c0.3,0.7,0.5,2.3-0.1,2.9c-0.1,0.1-1,0.3-1.3,0.4
								c-1.8,0.4-3,0-3.2-1.9c-0.1-1.6,0.1-3,1.8-3.5c0.2-0.1,0.4-0.1,0.5-0.2C409.8,618.8,410.7,619.5,411.1,620.4z M409.4,596.8
								c-0.6,0.2-1.9,0.4-2.6,0.2c-1.6-0.4-1.8-2-2-3.4c0-0.4-0.1-0.8-0.1-1.3c0-0.1,0-0.2,0-0.3c0-1.3,0.4-2.5,1.9-2.4c0.2,0,0.3,0,0.4,0
								c1.1,0.5,2.1,1.2,2.9,2.2C411.1,593.3,411.6,596,409.4,596.8z M412.4,584.4c-1.3,1.8-3,0.9-3.4-0.9c-0.4-1.8-0.1-3.2,1.4-4.1
								c0.4,0.2,0.7,0.5,0.9,0.6c0.3,0.3,0.7,0.6,0.9,0.9c-0.3,0.8,0,1.7,0.6,2.2C412.9,583.6,412.7,584,412.4,584.4z M421.5,574.4
								c-2.1,1.4-4.2,2.4-6.2,3.8c-1.9-2.2-5.6-4.4-8.5-4.1c-0.5-1.3-1.2-2.5-2.2-3.3c0,0,0,0-0.1,0c-0.3-0.4-0.8-0.6-1.5-0.5
								c-0.4,0-0.7,0.1-1.1,0.2c0.9-2.7,0.8-5.6,0.1-8.3c0-0.5-0.1-0.9-0.4-1.3c-0.1-0.1-0.1-0.3-0.2-0.4c0.1-0.1,0.3-0.1,0.4-0.2
								c1.9-0.8,3.5-1.9,4-3.9c0.6-2.3,0.4-4.8-0.2-7.1c1.7-0.8,3.2-2.3,4-4.1c1.1-2.3,1-4.3,0.2-6.1c0.5,0,1,0,1.5-0.1
								c0.5,0.2,1,0.3,1.5,0.1c1.6,0.5,3.1,0,4.9-0.6c2-0.7,3-0.8,4.8-0.7c-0.9,1.6-1.6,3.4-2.4,5.1c-2.2,5.2-4.9,11.7-8.6,16.7
								c-1.9,1.7-3.9,3.2-5.9,4.5c-2.2,1.4-0.2,4.8,2,3.5c0.4-0.2,0.7-0.5,1.1-0.7c0.1,0,0.1,0,0.2,0.1c3.3,0.5,7.1,1.6,10.5,1.4
								c2.9-0.2,5.6-3.5,6.9,1.6c0,0.1,0,0.1,0.1,0.2C425,571.6,423.4,573.2,421.5,574.4z M448.5,559.7c-1.6,1.2-3.3,2.3-5.1,3.4
								c-3.5-0.3-6.7-3.1-7.8-6.3c-0.5-1.5-0.5-3-1.2-4.5c-1.5-3.5-3.9-6.7-5.9-9.9c0-0.1,0-0.1,0.1-0.2c0.1-0.3,0.3-0.7,0.4-1
								c2.8,3.3,5.2,7.2,8.1,10.3C440.3,555,443.9,558.3,448.5,559.7z M447,539c-3,1.9-6.3,3.3-9.2,5.3c-0.4,0.3-0.6,0.6-0.8,0.9
								c-1.6-2.1-3.1-4.3-4.7-6.2c2.9-1,7.3-5.3,6.9-8c-0.2-1.2-0.9-1.8-1.9-2.4c-1.1-0.7-1.7-0.8-2.5-1.8c-1.3-1.5-2.4-4.4-2.3-6.8
								c0.6,0.8,1.2,1.6,1.7,2.1c1.8,1.8,3.5,3.8,5.3,5.6c3.2,3.4,6.6,6.3,11,7.8c0.2,0.1,0.4,0.1,0.6,0.1C449.8,536.9,448.4,538,447,539z
								M465.8,537.9c0-0.2-0.1-0.4-0.2-0.6c0-1,0.1-1.9,0.1-2.9c0.7,0.8,1.2,1.4,1.4,1.6c0.6,0.6,1.3,1.2,2,1.8
								C468,537.6,466.8,537.6,465.8,537.9z"
					/>
					<path
						d="M720.6,1102.4c-0.8-2.3-2.8-3.2-3.2-3.4l0,0c-0.2-0.1-1.4-0.6-2.9-0.4c0.1-1.1,0-2.3-0.6-3.5c-1-1.7-2.9-2.8-4.8-2.9
								c-0.8,0-1.7,0.1-2.4,0.5c0-0.3,0-0.5,0-0.8c-0.3-2.4-1.8-3.8-3.6-5.6c-1.1-1-1.9-1.8-3.3-1.9c-1.6,0-2.6,1.1-4.6,3.5
								c-3.1,3.7-3.4,4.5-3.5,5.4c0,0,0,0,0,0.1c-0.3-0.1-0.5-0.2-0.8-0.3c-0.8-0.3-1.7-0.4-2.5-0.3c0.6-0.7,1.1-1.5,1.5-2.6
								c1.1-2.8,0.4-5.3,0.2-6.3c-0.3-1.4-1.5-2.9-4.1-6.3c-0.3-0.4-0.9-1.2-2-1.4c-1.7-0.3-3,0.9-4.3,2.4c-2.1,2.3-3.1,3.4-3.6,4.7
								c-1.5,3.9,0.1,7.5,0.8,9c0.5,1.1,1,2.1,1.8,2.9c-2.1-0.3-4,0.4-4.4,0.6c-0.7,0.3-4.4,2-5,5.8c-0.3,1.8,0.3,3.4,0.9,4.5
								c-0.1,0-0.2,0-0.3,0c-3.5,0.6-4.5,4.1-4.6,4.5c-0.1,0.3-0.8,3.2,1.2,5.6c0.2,0.2,0.6,0.7,1.2,1.2c-0.9,0.7-1.6,1.7-2,2.2
								c-2.6,3.6-2.4,6.6-1.8,8.4c0.1,0.4,0.3,0.8,0.6,1.3c-1.5,0.7-2.4,2-2.9,2.7c-0.7,1.1-1.5,2.8-1.4,5.3c0,0.9,0.1,1.9,0.6,2.8
								c-0.4,0.2-0.7,0.3-0.8,0.4c-1.5,0.9-2.6,2.2-3.3,4.1c-0.3,0.8-1.1,3.8,0.6,6.5c0.4,0.6,1.3,2,2.9,2.6c-1.8,1.8-2.3,3.7-2.4,5
								c-0.1,1.4-0.1,4.8,2.8,6.8c0.6,0.4,1.3,0.7,2,0.9c-0.8,1.3-1.2,2.7-1.2,3.8c0,1.4,0.3,2.5,1,4.4c0.5,1.3,1.1,3.3,2.8,5.4
								c0.4,0.5,0.7,0.9,1,1.3c-1,0-1.9,0.2-2.3,0.4c-2.2,0.8-3.2,2.7-3.7,3.7c-0.6,1.2-1.6,3.1-0.9,5.3c0.5,1.4,1.6,2.5,2.8,3.1
								c-0.1,1.1-0.1,2.3-0.1,3.4c0.1,1.8-0.9,4.3,0.5,5.3c0.8,0.6,3.5,0.2,3.9-0.7c0.8-1.4-0.4-3.1-0.5-4.7c0-0.8,0-1.6,0-2.5
								c2.1-0.2,4-1.3,5.1-3c0,0,0-0.1,0-0.1c0.8,0.6,1.7,1,2.5,1.2c0,0.7,0,1.5,0,2.2c0,2.2-1.5,5.2,0.1,6.8c0.7,0.7,3.4,0.6,4-0.2
								c1.3-1.8-0.1-4.4-0.1-6.6c0-0.7,0-1.4,0-2.1c0.2,0,0.4-0.1,0.5-0.1c0.5-0.1,1.3-0.3,2.3-0.8c0,0.6-0.4,1.3,0,1.7
								c0.7,0.7,3.3,0.9,4,0.2c0.9-1,0-2.9,0-4.5c0-0.7,0-1.4,0-2.1c0.2,0,0.3-0.1,0.4-0.1l0.3-0.1c1.1-0.2,4.7-1.3,6.5-4.8v0
								c0.5-0.9,1.4-2.7,0.6-4.6c-0.1-0.2-0.2-0.4-0.3-0.6c0.1,0,0.1-0.1,0.2-0.1c1.8-1,3.1-2.5,3.8-4.7l0,0c0.3-1,1.1-3.2-0.1-5.4
								c-0.3-0.6-0.7-1.1-1.2-1.5c0.6-0.3,1.1-0.6,1.6-0.9c2.7-1.8,3.6-4.7,3.9-5.8c0.5-1.6,1.1-3.8,0-6.1c-0.1-0.2-0.2-0.4-0.4-0.7
								c0.6-0.2,1.2-0.6,1.7-0.9c2.7-2.2,2.3-5.7,2.2-7.4c-0.1-1.1-0.3-2-0.6-2.9c1.5-0.3,2.5-1,3.1-1.5c1.2-1,2.1-2.3,2.5-3.9
								c0.3-1.1,0.3-2.1,0.1-3c0.5,0,1-0.2,1.4-0.4c2.6-1.4,2.2-5.1,2.1-6.2c-0.1-0.7-0.3-1.3-0.5-1.8c0.3-0.1,0.5-0.2,0.8-0.3
								c1.8-0.9,2.4-2.6,3.1-4.4c0.6-1.5,1.5-4.1,0.6-7.1c1.5-0.3,2.3-1.7,3.2-3.3C720.3,1107.1,721.5,1104.9,720.6,1102.4z M705.8,1097.4
								c0.4-0.6,1.5-2.3,3.1-2.3c0,0,0,0,0.1,0c0.9,0,1.9,0.6,2.3,1.4c0.9,1.6-0.6,4-1.2,5c-0.6,1-1.7,2.6-2.7,2.6c-0.4,0-0.7-0.2-1-0.4
								c-0.3-1.1-0.8-2-1.5-2.8C704.8,1099.5,705.3,1098.2,705.8,1097.4z M694.5,1093.8c0-0.3,0.6-1.2,2.9-4c0.5-0.6,1.9-2.3,2.3-2.5
								c0.2,0,0.8,0.6,1.2,1c1.7,1.7,2.6,2.5,2.7,3.8c0.2,1.7-1.1,3.2-1.6,3.8h0c-0.7,0.8-2.6,2.7-4.7,2.2c-1-0.2-1.7-1-2-1.4
								C695.3,1096.8,694.3,1095.5,694.5,1093.8z M703.6,1105.6c0,1.3-0.8,2.4-2.1,3.3c-0.1,0.1-2.4,1.7-4.6,0.6c-1.3-0.6-1.8-1.9-1.9-2
								c-0.1-0.3-0.4-1.2-0.1-2.3c0.4-1.7,2-2.6,2.8-2.9c0.6-0.3,1.4-0.6,2.3-0.6c0.6,0,1.2,0.1,1.7,0.5c0.1,0.1,0.2,0.1,0.3,0.2
								c0.2,0.6,0.4,1.3,0.8,1.9c0.1,0.2,0.4,0.6,0.8,1C703.6,1105.3,703.6,1105.5,703.6,1105.6z M695,1118.4c0.2-0.6,0.8-1.3,1.8-2.8
								c1.3-1.8,1.8-2.1,1.8-2.1c0.1,0,0.2,0,0.3,0c0.6,0,1.4,0.5,1.9,0.9c0.3,0.3,0.5,0.6,0.7,0.9c0.1,1.6,0.5,3.2,1.2,4.6
								c0,0.1,0,0.3-0.1,0.4c-0.9,0.9-1.5,2.2-1.8,3.4c0,0.1,0,0.1,0,0.2c-0.4,0.4-0.6,0.7-0.7,0.8c-1.1,1.1-1.9,1.8-2.8,1.7
								c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.5-0.5-0.9-0.9c-0.4-0.5-0.7-1.1-1-1.8C694.1,1121.9,694.2,1120.2,695,1118.4z M694.6,1128.2
								c0.4,0.5,0.9,1.5,0.7,2.5c-0.2,0.9-1,1.4-1.7,1.7c-0.7-0.2-1.4-0.3-2.1-0.4c-0.7,0-1.4,0-2.1,0.2c-1-0.8-1.8-2.2-1.4-3.5
								c0.2-0.6,0.6-1.1,1.1-1.4c0.1,0,0.3,0,0.4,0h0c0.5-0.1,1.3-0.2,2.2-0.6c0.4,0,0.7,0.1,1,0.1c0.4,0.1,0.7,0.3,1,0.5
								C693.9,1127.7,694.2,1128,694.6,1128.2z M688.4,1150.5c-0.5,0.4-1.1,0.9-1.5,1.6c-0.9,0.7-2,1-2.8,1.1c-0.5,0.1-0.9,0.1-1.4,0.3
								c-0.9,0.2-2.2,0.4-2.5,0c-0.3-0.4-0.5-0.8-0.6-1.4c0-0.5,0-1.1-0.1-1.7c0.1-1,0.4-2,1.1-2.8c0.8-0.5,1.4-1,2-1.6
								c0.8-0.4,1.6-0.6,2.4-0.6c0.7,0,1.4,0.2,2,0.5h0c0.4,0.2,1.7,1,2,2.3C689.1,1149.1,688.8,1149.9,688.4,1150.5z M688.5,1161.3
								c0,0.5-0.1,1.1-0.4,1.6c-0.8,1.3-2.5,1.9-3.8,1.8c-1.6-0.1-3-1.1-3.7-2.4c-0.1-1.1-0.3-2.3-0.5-3.3c0.1-0.6,0.5-1,1.1-1.5
								c0.3-0.2,1.4-0.6,2.5-0.9c0,0,0,0,0.1,0l0.5-0.1c0.1,0,0.2,0,0.4-0.1c0.4,0,0.9,0,1.2,0c0.7,0.2,1.1,0.5,1.4,0.8
								c0,0.1,0.1,0.2,0.1,0.3c0.4,1.3,0.7,2.1,0.9,2.7C688.3,1160.7,688.4,1161,688.5,1161.3z M685,1123c-0.2-0.4-0.1-0.9,0.1-1.4
								c0.7-0.6,1.4-1.2,2-1.9c0.7-0.3,1.5-0.3,2.2,0c0.5,0.2,1.4,0.6,1.8,1.6c0,0.1,0.5,1.3-0.2,2.2c-0.1,0.1-0.2,0.2-0.3,0.3
								c-0.8,0.1-1.7,0.3-2.4,0.6C687,1124.4,685.4,1124,685,1123z M685.1,1117.3c0,0,0,0.1-0.1,0.1c-0.5,0.3-1,0.7-1.4,1.1
								c-0.1,0.1-0.5,0.5-0.8,1.2c-1.1,0.9-2.2,1.7-3.7,1.7h0c-0.1,0-2.7,0-3.7-1.7c-0.7-1.1-0.4-2.4-0.2-3.6c0.2-1.2,0.7-3,2.4-4.4
								c0.5-0.4,1.9-1.6,3.4-1.6c0.2,0,0.5,0,0.7,0.1c1.5,0.4,2.2,1.9,2.8,2.9c0.1,0.1,0.1,0.2,0.2,0.4c0,0.1,0,0.3,0.1,0.4
								c0.1,0.7,0.3,1.3,0.7,1.9C685.3,1116.3,685.2,1116.8,685.1,1117.3z M676.3,1127.4l0-0.1l-0.1,0l0,0l0.1,0c0-0.1,0.1-0.3,0.1-0.4
								c0-0.1,0-0.1,0.1-0.2c0.3-0.5,0.5-0.9,0.8-1.1c1.8-1.1,4.7-0.8,6.1,0.8c0.6,0.6,0.8,1.3,1.1,2.9c0.3,1.1,1,4.2-0.3,5.3
								c-0.5,0.4-1.2,0.5-1.8,0.5c-0.1,0-0.1,0-0.2,0c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.3-3.1-2.6-6.4-1.9c-0.4,0.1-0.8,0.2-1.2,0.4
								c0.1-0.3,0.2-0.7,0.3-1.2c0-0.1,0.1-0.2,0.1-0.3C675.8,1130.4,676.1,1128.8,676.3,1127.4z M672.3,1148.6c0.9,0.3,1.9,0.5,2.9,0.5
								c0.5,0.2,0.9,0.6,1.1,0.9c0.1,0.2,0.2,0.5,0.3,0.7c0,0.5,0,1.1,0.1,1.6c0,0.1,0,0.3-0.1,0.4l0,0.1c-0.2,1-0.8,1.6-1.7,1.8
								c-0.6,0.2-1.3,0.3-1.8,0.3c-0.1,0-0.2,0-0.3,0c-0.9,0.1-1.6,0-1.9,0c-1.1-0.6-1.5-1.3-1.7-1.8c-0.5-1.7-0.5-2.8-0.1-3.5
								C669.5,1149,670.6,1148.6,672.3,1148.6z M670.6,1160.1c0.1-0.4,0.3-0.9,0.5-1.3c0.2-0.4,0.7-0.7,1.4-0.8c0,0,0.1,0,0.1,0
								c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c1.3,0,2.8,0.5,3.7,1.2c0,0.5,0,1.1,0.1,1.6c0.1,0.8,0.3,1.5,0.6,2.2c0,0.2,0.1,0.4,0.1,0.6
								c0.4,1.4,0.4,2.3,0,2.5c-0.5,0.3-1.1,0.2-1.9-0.1c0,0,0,0-0.1-0.1c-1.1-1.2-2.7-2.2-4.5-2.8c0-0.1-0.1-0.1-0.1-0.2
								c-0.4-0.6-0.6-1.2-0.6-1.8C670.6,1160.8,670.6,1160.5,670.6,1160.1z M678,1145.6c-0.7,0.3-1.5,0.4-2.3,0.5
								c-0.9-0.3-1.9-0.5-2.9-0.5c-0.4-0.2-0.8-0.4-1.1-0.7c-0.4-0.3-0.7-1.7-0.2-3.7c0.1-0.5,0.3-1,0.5-1.5c0.3-0.4,0.6-0.9,0.8-1.3
								c0-0.1,0-0.1,0.1-0.2c0.4-0.5,0.8-1,1.2-1.4c0.3,0,0.7-0.1,0.9-0.2c0.7-0.2,1.5-0.4,2.5-0.2c0.8,0.1,1.2,0.4,1.8,0.7
								c0.4,0.2,0.9,0.5,1.5,0.7c0.8,1.1,1.2,2.6,0.9,3.9c-0.2,0.8-0.5,1.5-1,2.1c-0.5,0.3-1,0.6-1.4,1c-0.2,0.2-0.4,0.4-0.6,0.6
								C678.4,1145.4,678.2,1145.5,678,1145.6z M694.8,1138.7c0,0,0,0.1,0,0.2c-1.2,1.1-2,2.5-2.5,4c-0.7,0.5-1.8,1-2.7,0.7
								c-1.4-0.5-1.9-2.8-2-3.6c-0.2-0.9-0.5-2.7,0.4-3.8c0.2-0.3,0.6-0.5,0.9-0.7c0.8,0.3,1.7,0.5,2.4,0.5c0.8,0,1.5-0.1,2-0.3
								c0.4,0.2,0.7,0.5,0.9,0.8C694.9,1137.2,694.8,1138.1,694.8,1138.7z M691.7,1116c-1.3,0.6-2.7-0.3-2.7-0.3c-0.3-0.2-0.6-0.5-0.8-0.8
								c-0.1-0.8-0.4-1.6-0.7-2.1c0-0.7,0.2-1.4,0.6-1.9c0,0,0,0,0,0c1-0.3,1.8-0.8,2.6-1.3c0.1,0,0.2,0,0.3,0c1.5,0.2,2.2,1.6,2.4,2.4
								C693.7,1113.4,693.1,1115.4,691.7,1116z M688.8,1095.8c0.4,0,0.7,0.1,1,0.2c1.6,0.5,2.3,2.2,2.6,3.5v0c0.4,1.7,0,3.6-1.2,5.4
								c0,0-0.6,0.8-1.7,1.7c-1.2,0.2-2.3,0.7-3.2,1.5c-0.2,0.1-0.4,0.1-0.6,0.1c-0.3,0-0.5,0.1-0.8,0c-0.7-0.2-1.1-1.1-1.3-1.5
								c-0.2-0.7-0.1-1.3,0.2-2.7l0-0.2c0.6-2.6,0.9-4.1,1.1-5.1c0.2-1.3,0.2-1.3,0.5-1.5C686.3,1096.4,687.6,1095.8,688.8,1095.8z
								M678.8,1084.5c0.3-0.8,1.3-1.8,3-3.8c0.9-1,1.3-1.3,1.5-1.4c0.1,0.1,0.1,0.2,0.2,0.2c2,2.7,3.4,4.4,3.6,5.2
								c0.2,0.8,0.7,2.5-0.1,4.5c-0.6,1.7-1.8,2.7-2.5,3.2c-0.6,0.4-2.3,1.6-3.5,1.1c-0.6-0.2-0.9-0.9-1.5-2.2
								C678.9,1090,677.7,1087.3,678.8,1084.5z M672.1,1102.3c0.3-2.3,2.7-3.3,3.2-3.5c0.1,0,1-0.4,2.2-0.4c0.7,0,1.4,0.1,2.1,0.5l0,0
								c0.3,0.2,0.5,0.3,0.6,0.5c1.1,1.2,0.6,3.9-0.5,5.7c-0.8,1.3-2.6,3.1-4.1,2.8c-0.9-0.2-1.6-1-2.1-1.7
								C673,1105.5,671.8,1104.1,672.1,1102.3z M668,1111.6c0-0.1,0.6-2,2.2-2.3c0.1,0,0.3,0,0.4,0c1,0,2.1,0.6,2.4,1.5
								c0.4,1.1-0.4,2.3-1.4,3.6c-0.5,0.7-0.9,1.1-1.3,1.2c-0.7,0.1-1.4-0.6-1.8-1C667.6,1113.3,668,1111.6,668,1111.6z M668,1121.5
								c1.3-1.8,2.1-2.1,2.4-2.1c0,0,0.1,0,0.1,0c0.3,0,0.9,0.8,1.7,1.7c1.1,1.4,1.7,2.1,1.8,3.2c0,0.4-0.1,0.8-0.2,1.2
								c-0.5,1.1-1,2.6-1.6,4.4c0,0-0.1,0-0.1,0.1c-0.7,0.4-1.5,0.4-2.5,0c-0.4-0.3-0.9-0.5-1.4-0.7c-0.9-0.7-1.4-1.7-1.6-2.3
								C666,1125.4,666.5,1123.6,668,1121.5z M664,1133.8c0.5-0.7,1.1-1.6,2.1-1.8c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0,0.5,0.1
								c0.2,0.1,0.4,0.2,0.6,0.4c0.2,0.1,0.4,0.2,0.6,0.3c0.4,0.3,0.6,0.6,0.8,0.8c0.1,0.1,1.5,1.8,1.1,3.5c-0.3,0.4-0.5,0.9-0.7,1.3
								c-0.5,0.5-1.1,0.8-1.3,0.9c-1.7,0.8-4,0.6-4.7-0.3c-0.2-0.3-0.3-0.8-0.3-1.6C663,1135.7,663.5,1134.5,664,1133.8z M659.7,1149.6
								c-1-1.6-0.5-3.4-0.3-4c0.2-0.4,0.6-1.7,1.9-2.4c0.1,0,1-0.6,2.2-0.6c0.6,0,1.2,0.1,1.7,0.5c1.1,0.7,1.7,2.2,1.4,3.4
								c-0.2,0.8-0.6,1.3-1.8,2.4c-1.2,1.1-2.5,2.4-3.6,2.1C660.5,1150.9,659.9,1150,659.7,1149.6z M662.2,1163.1c-1.6-1.1-1.6-3.2-1.5-4
								c0.1-1.2,0.6-2.2,1.6-3.2l0,0c1.2-1.2,2.2-1.8,3-1.6c1.4,0.3,2.2,2.8,2.3,4.4c0,0.3,0.1,0.7,0,1.1c-0.1,0.3-0.1,0.7-0.1,1
								c-0.1,0.7-0.5,1.5-1.1,2C665.2,1163.9,663.4,1163.9,662.2,1163.1z M668.4,1190.1c-0.7,1.1-2.1,1.7-3.4,1.6c-1.2-0.1-2.6-0.8-3-1.9
								c-0.3-0.9,0.1-1.8,0.7-3c0.4-0.8,1-1.9,2.1-2.3c0.1,0,0.6-0.2,1.2-0.2c0.3,0,0.6,0,0.9,0.2c0.5,0.2,1,0.7,1.4,1.3
								c0,0.6,0.1,1.7,0.6,2.9C668.7,1189.2,668.6,1189.7,668.4,1190.1z M669.9,1179.4c-0.3-0.1-1-0.9-1.7-1.8c-1.2-1.5-1.7-3-2.1-4.2
								c-0.6-1.7-0.8-2.3-0.8-3v0c0-0.2,0.1-2.2,2-3.2c0.5-0.3,1-0.4,1.6-0.4c1.6,0,3.2,1,4,1.9c1.5,1.6,1.6,2.6,1.7,2.8
								c0.1,0.7,0,1.3,0,1.9c-0.1,0.3-0.1,0.5-0.2,0.8c-0.3,1.1-0.8,1.8-1,2.2C671.7,1178.7,670.4,1179.5,669.9,1179.4z M677.4,1189.8
								c-0.6,0.1-3.5,0.7-5.1-1.1c-0.1-0.2-0.3-0.3-0.4-0.5c0-0.8-0.1-1.6-0.3-2.3c-0.1-0.3-0.2-0.6-0.3-0.8c0-0.6,0.1-1.3,0.5-2
								c1-0.4,2-1.1,2.9-2.1c0.3-0.1,0.5-0.1,0.5-0.1c0.2,0,0.4,0,0.6,0c0.6,0,1.4,0.1,2,0.4c-0.2,1.4-0.1,3.1,1,4.5
								c0.3,0.3,0.5,0.6,0.8,0.8c0.2,0.5,0.4,0.9,0.6,1.2c0,0,0,0,0,0.1c0,0.2,0,0.5,0,0.7C679.8,1189,678.7,1189.6,677.4,1189.8z
								M680.3,1176.8c-2,0.9-2.8,0.8-3,0.6c0,0,0,0,0,0c0.7-1.4,1.6-3.6,1.2-6.4c0,0,0,0,0,0c0.3-0.8,0.7-1.6,1.2-2.4
								c0.2-0.3,1.2-1.1,2.2-1.1c0.1,0,0.2,0,0.3,0c1.2,0.2,1.9,1.7,1.9,1.7c0.3,0.6,0.3,1.2,0.2,1.8c-0.1,0.4-0.2,0.8-0.3,1.3
								c-0.2,0.8-0.7,2.2-1.8,3.3C681.6,1175.8,681,1176.3,680.3,1176.8C680.4,1176.8,680.3,1176.8,680.3,1176.8z M687.9,1180.5
								c-1,2-3.4,2.6-3.8,2.7l-0.3,0.1c-1.3,0.3-1.5,0.2-1.8-0.1c-0.6-0.7,0.1-2.1,0.1-2.1c1.1-2.1,3.2-2.6,3.1-2.6
								c1.7-0.1,3.1,0.4,3.2,0.7C688.5,1179.4,688,1180.3,687.9,1180.5z M693,1171.4L693,1171.4c-0.5,1.4-1.3,2.4-2.3,3
								c-0.5,0.3-1.1,0.5-1.7,0.6c-0.6-0.2-1.2-0.3-1.8-0.4c-0.2-0.4-0.3-1-0.2-1.8c0.1-0.2,0.1-0.4,0.1-0.6l0,0c0-0.2,0.1-0.4,0.1-0.6
								c0.2-0.6,0.4-1.2,0.6-1.7c0.7-1.5,1.4-3,2.6-3.1c0.1,0,0.2,0,0.2,0c1,0,2,0.8,2.4,1.6C693.6,1169.4,693.2,1170.6,693,1171.4z
								M697.1,1157.9c-0.3,1-0.9,2.9-2.7,4.2c-1.1,0.7-2.2,1-2.8,1c0-0.3,0-0.8-0.1-1.5c0-0.3-0.1-0.5-0.1-0.8c-0.1-1.5-0.6-2.7-0.9-3.3
								c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.4-0.3-0.9-0.7-1.4c-0.4-1.3-0.5-1.6-0.4-1.8c0,0,0-0.1,0-0.1c0.2-0.2,0.4-0.4,0.6-0.6
								c0.2-0.2,0.3-0.4,0.5-0.6c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.2v0c0.9-0.6,1.8-0.7,2.4-0.7c0.5,0,0.9,0.1,0.9,0.1
								c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.3,0.1,0.5,0.2c0.9,0.4,1.5,1,1.9,1.8C697.9,1155.2,697.5,1156.5,697.1,1157.9z M699.4,1148.7
								c-0.7,0.6-1.9,0.9-2.9,0.7c-0.2-0.1-0.3-0.1-0.5-0.2c0,0,0,0-0.1,0c-0.9-0.6-1-1.9-1.1-2.6v0c-0.1-0.6,0-1.2,0.1-1.9
								c0.1-0.1,0.1-0.1,0.2-0.2l0,0c1.4-1.2,2-2.7,2.3-3.9c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.5-0.2,0.8-0.2c0.3,0.1,0.6,0.3,0.9,0.4
								c0.4,0.4,0.8,1.3,1,3.4C700.7,1145.7,700.8,1147.7,699.4,1148.7z M705.7,1134.7c-0.2,0.9-0.8,1.8-1.5,2.3c-0.4,0.3-1.4,1-2.7,0.9
								c-0.2,0-0.4,0-0.6-0.1c-0.3-0.2-0.6-0.4-0.9-0.5c-0.2-0.1-0.4-0.1-0.5-0.1c-0.8-0.5-1.5-1.3-1.7-2.2c-0.5-1.9,0.7-3.5,1.9-4.8
								c0.7-0.8,1.4-1.6,2.3-1.6c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.8,1.2,1.7,2,1.9,2.1c0.5,0.4,0.9,0.7,1.3,0.9
								C705.7,1132.4,706,1133.5,705.7,1134.7z M708.7,1129.4c-0.2,0.1-0.6,0.1-1.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-1.1-1.7-2.2-2.6-3.3-3
								c-0.2-0.4-0.3-0.8-0.3-1.2c0.5-0.7,0.9-1.4,1.3-2.3c0.9,0.7,2,1.2,3.2,1.5c0.4,0.6,0.8,1.3,1,2.2
								C709.4,1127.6,709.2,1129.1,708.7,1129.4z M712.7,1118.2c-0.6,1.6-0.9,2.4-1.7,2.8c-0.4,0.2-0.8,0.2-1.3,0.2
								c-0.2-0.2-0.4-0.4-0.5-0.5c-0.9-0.8-2-1.8-3.5-1.9c0-0.2,0-0.4,0-0.6c-0.1-1-0.5-1.9-0.6-2.4c-0.2-0.5-0.4-1-0.6-1.5
								c0-1.2,0.3-2.5,0.9-3.4l0,0c0.3-0.6,1-1.8,2.3-2.2c1.2-0.3,2.4,0.4,3,0.7c1.7,1,2.4,2.6,2.6,3.3
								C713.9,1114.9,713.2,1116.8,712.7,1118.2z M716.7,1107.4c-0.4,0.7-0.9,1.7-1.2,1.8c-0.1,0-0.5,0-1-0.4c-1-0.8-1.9-2.2-2-3.5
								c0-0.3-0.2-2.5,1.2-3.4c0.4-0.3,0.9-0.4,1.3-0.4c0.7,0,1.2,0.2,1.2,0.2c0.1,0,1.2,0.5,1.5,1.6
								C718.2,1104.5,717.6,1105.7,716.7,1107.4z"
					/>
					<g id="Berge">
						<path
							d="M3024.9,1835.6c-6.2,0-12.4-0.3-18.5-1.3c-4.5-0.8-12.6-5.2-17-4.2c-4,1-7.7,5.4-12.1,6.7c-6.1,1.9-12.6,2.7-19,2.4
									c-1.9-0.1-4.2-0.1-6-0.6c-2.3-0.7-4.6-3.4-7.2-2.7c-4.6,1.1-5.8,8.5-10,10.6c-8.7,4.3-16.2-15.5-24.5-12.2
									c-3.1,1.3-4.9,7.8-7.4,10.2c-2.4,2.2-1.9,1.9-4.5,1.9c-3,0.1-5.9-1.6-8.4-3c-3.8-2.2-8.1-8.3-11.8-9.8c-4.8-1.9-6.4,3.9-9.1,7.3
									c-3.8,4.8-2.3,0.9-6.6,0.1c-2.6-0.5-2.6,0.1-4.6,2c-4.8,4.5-8.4,7.9-14.3,11.1c-1.8,1-12.8,7.1-4.8,7.1c1.2,0,2.7-1.6,3.9-2
									c2.6-0.7,5.7-0.2,8.2,0.5c1.2,0.4,5.4,3.4,6.5,2.8c2.2-1.2-0.2-3.1,1-4.7c1.7-2.2,5.4-0.1,7.6-1.2c1.6-0.9,2-3.2,4.1-4
									c1.8-0.6,3.8,0.7,4.9,0.4c6.5-2-2.7-5.5-5.1-6.9c2.2-2.1,8,3.3,10.6,2.6c4.7-1.2,1-2.7-0.8-4.5c-0.8-0.8-3.2-1.7-2.8-3.4
									c0.6-2.7,3.1,0.6,3.5,0.8c5.4,2.7,9.1,7.3,15.5,8.4c-1.8,1.2-10.8,4.7-11.1,6.6c-0.8,5.2,7.3,0.1,8.7-0.6
									c6.8-3.1,14.9-3.7,21.9-5.8c8.5-2.6-5.2-5.6-4.3-10.1c0.7-3.5,2.7-0.4,4.3,0.5c4.9,2.8,8.7,7,13.7,9.6c-3.4,2.1,0.1,3.8,2.3,3.8
									c1.4,0,3.5-1.6,5.1-1.8c2.3-0.3,4.9,0.8,7.1,0.2c0.5-0.1,9.1-2.9,9.1-2.9c1.6-2.6-2.6-4.7-2.9-7.5c6.8,4.7,14.9,9.9,22.8,12.4
									c9.6,3,1.2-7.3,0.5-12c8.5-1.4,15.9,2.4,24.2,3.2c9.5,0.9,2.2-7.5-0.2-7.1c2.5-0.5,6.2,1,8.7,1.2c3.6,0.4,7,0.2,10.6-0.1
									c1.8-0.2,8.2,0.3,9.4-0.9C3027.4,1838,3026.8,1835.6,3024.9,1835.6z"
						/>
						<g>
							<path d="M2878.9,1883.4c-0.2-0.1-0.5-0.2-0.7-0.3C2878.5,1883.2,2878.7,1883.3,2878.9,1883.4z" />
						</g>
						<path
							d="M2927.3,1861.9c2,1.6,16.9,11.7,18.1,6.1c0.4-2-13.4-7.4-15.9-9.4c-3.6-3-4-5.8-8.1-3.4c-3.1,1.9-5.1,5.8-8.4,7.8
									c-4.5,2.7-5.6,1.2-9.9-0.3c-4-1.4-4.5-1.3-7.3,1.8c-3.3,3.7-3.5,3.2-8.1,0c-2.2-1.6-6.3-7.5-8.9-7.4c-3.9,0.1-10.1,13-13.8,16.1
									c-1.7,1.5-6.9,3.4-6.8,5.7c0.2,2.8,6.2,2.3,8.1,2c6.8-1,16.3-6.8,18.6-13.7c1.8,1.4,3.7,2.7,5.8,3.6c-1.7,1.8-12.6,8-13.1,9.6
									c-0.8,3.1,0.4,2.6,2.5,2.9c0.8,0.1,3.4-1.5,3.3-1.5c2.4-0.6,3.9-0.1,5.9,0c1.4,0,4.2,1.7,5.4-0.5c1.7-3.2-3.3-2.1-3.4-4.3
									c-0.1-3.3,11.6,3.5,12.6,2.9c1.1-0.7,0.6-3.9,0.9-5.1c0.9-4.2,2.3-2,0-6.5c4.9,3,10.6,4.8,16.1,6.3c3.3,0.9,19.4,4.8,21.9,2.4
									c4.2-4.1-4.1-4.1-6.8-4.7c-6.5-1.6-14.8-1.4-20.9-3.9c2.3-0.5,8.7,0.7,10.2-1.3C2927.3,1864.6,2923.7,1862.9,2927.3,1861.9z
									M2877.8,1865.1c0.5-1,0.6-2.3,1.1-3.2C2881.1,1863.8,2879.9,1863.8,2877.8,1865.1z"
						/>
						<g>
							<path d="M2840.3,1897.3c-0.6,0-1.1-0.3-1.4-0.8C2839.3,1896.8,2839.8,1897.1,2840.3,1897.3z" />
							<path d="M2856.3,1885.2c-0.5,1.1-1.2,1.3-1.9,0.5C2855,1885.5,2855.6,1885.3,2856.3,1885.2z" />
						</g>
						<g>
							<path d="M2878.9,1883.4c-0.2-0.1-0.5-0.2-0.7-0.3C2878.5,1883.2,2878.7,1883.3,2878.9,1883.4z" />
						</g>
						<path
							d="M2912.7,1891.7c-9-1.7-18.7-0.8-27.3-4.5c-7.1-3.1-6.9-6.2-12.7-0.2c-8,8-10.1-5.8-17.5-5.2c-2.9,0.3-5.8,5-8.1,6.7
									c-1,0.7-5.8,4.5-7,4.5c-1.8,0-3.6-3.5-5.5-3.8c-4-0.6-6,3.3-8.9,5.7c-3.3,2.6-7,4.5-10.8,6.1c-2.6,1-20.4,4.2-19.9,6.7
									c0.6,2.9,15.8,0.8,19.2,0.4c3.7-0.5,7.4-1.5,10.4-3.8c1-0.7,3-4,4-4.2c1.2-0.3,4.7,1.8,6.4,1.8c-2.9,6.7-12.3,14-19,16.3
									c-1.6,0.5-5.6,0.4-6,2.3c-0.6,3,2.2,2.3,3.8,2.4c11.4,1,29.5-5.1,31-18c4,2.8,9.1,4.4,13.5,6.5c2.4,1.2,12.4,7.4,14.8,6
									c3.6-2.1,0.6-2.5-0.9-4.2c-2.1-2.5-4.1-6.5-7-8.3c2.5-2.3,17.7,5.5,20.7,6.3c3.9,1.1,13.7,4.7,17.5,2.9c4.8-2.3-0.5-3.8-3.1-4.2
									c-6.1-1-12-2-17.9-3.9c-4.6-1.4-9.4-4.5-14-5.5c-4.9-1.1-4.7,1.9-8.4,4.2c-4.4,2.8-7.1-0.2-10.5-3.5c4.6-0.8,8.1,0.3,10.5-3.9
									c1.3-2.2,3.3-1.7,1.5-4.4c-1.2-1.9-6.2-2.5-8-4.6c3-3.3,3.1-0.9,6.4,1.4c3.7,2.5,8.2,4.7,12.4,6.2c3.3,1.1,4.2,0.7,7.3,0.2
									c-0.1,0,12.4-0.5,8.8-3.4c6,1.9,12.5,1.7,18.7,2.4c1.7,0.2,5.2,1.7,6.5,0C2914.2,1893.9,2914.6,1892.1,2912.7,1891.7z
									M2844.1,1895.1c1-0.7,2.8-0.1,2.9,1.2C2846,1895.9,2845.1,1895.5,2844.1,1895.1z M2883.7,1891.7c-1.2,0.2-2.3,0-3.3-0.8
									c2.8-0.2,4.2,0.3,6.7,1.2C2886,1892,2884.8,1891.8,2883.7,1891.7z"
						/>
						<g>
							<path d="M2847.1,1921.5c-0.4,0.6-0.8,1.2-1.2,1.8C2845.6,1922.6,2846.3,1921.8,2847.1,1921.5z" />
							<path d="M2852.2,1943.9c-1.3,0.9-2.9,0.5-3.2-1.2C2850,1943.1,2851.1,1943.5,2852.2,1943.9z" />
						</g>
						<g>
							<path
								d="M2907.6,1934.1c-7.2,0.8-14.5,0-21.5-1.8c-3.5-0.9-7-2.1-10.3-3.6c-2.2-1-5.5-3.7-8.1-3.4c-1.2,0.3-2.2,0.9-3,1.8
										c-1.3,2.6-2.8,2.6-4.5,0.1c-2.4-0.8-5.2-3.7-7-5.4c-1.3-1.2-4-6.1-6.1-5.8c-2.8,0.3-4.3,5-5.7,6.9c-1.7,2.3-4.5,6.2-7.3,7.3
										c-4.8,1.9-3.1-1.7-7.5-2.4c-5.4-0.9-6,7.5-9.8,9.9c-3.1,2-4-0.5-7.5,0.9c-2,0.8-3.4,3.5-4.8,5c-1.9,2-4,4.5-6.7,5.4
										c-1.3,0.4-4.8-0.5-5.5,0.5c-4,5.9,8.3,2.9,10.4,2.2c2.7-0.9,5.6-2.5,8.3-2.7c3.1-0.2,5.5,0.5,8.7,0c3.1-0.4,5.4-1,8.3-1.1
										c1.7-0.1,5.1,1.5,6.4,0c2.7-3.1-6.3-6.1-8.1-7.9c-0.8-0.8-4.8-3.7-2.1-4.7c3.3-1.2,5.1,3.5,7.1,5c4.2,3.1,10.4,4,15.2,5.7
										c1.9,0.7,15.7,5.2,9.6-0.9c-1.7-1.7-4.2-0.2-5.7-0.8c-2.2-0.8-5-3.1-7.3-4.4c3.7-1.9,4.5-2.6,3.4-6.4c-0.3-1.2-2-1.7-1.8-3.2
										c0.3-2,2.1-1.1,2.8-2.4c2.4-4.4-3.3-3-0.4-6.9c3.7,4.4,8.1,7.6,12.8,10.9c-1.1,1.1-11.2,8.4-11,9.6c0.4,3.4,6.8,1.4,9.5,1
										c4.6-0.6,7.4-2.4,10.8-5.5c4.2-3.8,1.8-1.5-0.5-5.7c-0.4-0.7,7,1.7,7.4,1.8c4.9,2,10.2,3.4,15.4,4.3c4.2,0.7,10.3,2,14.4,0.7
										C2907.6,1937.8,2912.1,1933.6,2907.6,1934.1z"
							/>
							<path d="M2847.5,1921.3" />
						</g>
						<g>
							<path d="M2852.2,1943.9c-1.3,0.9-2.9,0.5-3.2-1.2C2850,1943.1,2851.1,1943.5,2852.2,1943.9z" />
						</g>
						<g>
							<path d="M2746,1989.6" />
							<path d="M2749.2,1992.1" />
						</g>
						<g>
							<path d="M2746,1989.6" />
						</g>
						<path
							d="M2839.9,1973.1c-6.7-1.3-8.7-10-14.4-13.3c-3.1-1.8-5.8-2.4-8.2-5.2c-2.7-3.2-2.1-5.7-6.4-2.8c-3,2-4.8,6-7.7,8.4
									c-1,0.8-2.6,2.5-3.8,2.8c-2.3,0.6-1-0.5-3.6-0.7c-4.4-0.4-4.2,0.1-6.7,3.9c-2.6,4.1-6.1,7.4-8.8,11.4c-2.9-2.4-6.6-10.3-10.7-7.8
									c-2.8,1.7-4.9,10.6-8.8,10.5c-1.8-0.1-1.9-2.8-4.5-2.3c-1.8,0.3-3.8,3.2-4.9,4.4c-1.3,1.4-4.2,6.8-5.4,7.3
									c0.2-0.3-5.6-5.1-6.5-5.6c-3-1.5-4.1-2.3-7.1,0.2c-4.2,3.6-7.4,9.5-11.4,13.5c-2.9,2.9-3.3,5.2-7.3,4.3c-2.2-0.5-3.4-3.5-5.1-3.9
									c-4.5-0.9-10.3,10.1-11.6,14.3c-3-2.3-6.4-6.4-10-4.8c-3,1.3-7.2,9.7-9.3,12.4c-2.5,3.1-5.9,7.8-9.9,9.2c-2.1,0.8-7.1,0.1-9.1-0.5
									c-4.3-1.4-6.4-6.9-9.4-9.8c-4.5-4.5-5.6,0.5-8.8,4.3c-0.8,1.1-1.8,2.1-2.8,3c-0.7-0.5-1.4-1-2.1-1.5c-3.3,0.7-1.6-0.4-4.7,1.7
									c-3.1,2-9.2,6-11.1,9.1c-4.1-4.5-9-5.9-12.5-10.2c-1.6-2-3.3-6.7-6.3-5.4c-2.2,0.9-3.1,7.1-4.1,9.2c-1.7,3.5-2.8,6.7-6.5,5.4
									c-2.6-0.9-5-4.8-6.6-6.9c-3.4-4.3-8.1-12.4-12.8-15c-6.2-3.4-10.2,7.3-12.9,11.5c-2.6,4-2.2,4.8-6.9,4.9c-2.9,0.1-7.4-0.2-10.2-1
									c-1-0.3-1.3-0.4-2.9-1.4c-0.9-0.6-2.3-1.8-3.2-2.5c-2.7-2.2-4.6-4.1-6.5-7.1c-6.6-10.3-7.1,2.6-9.3,9.1c-3.9-2.4-6.7-6.2-10.6-3.3
									c-3.5,2.6-5.6,7.8-8.8,10.9c-2.3-3.3-5.3-7.1-8.3-9.8c-0.8-0.7-1.6-2.5-2.7-2.7c-2.6-0.6-2.1,1.2-3.8,1.2c-2,0-3.3-2.1-5.8-2.6
									c-3.5-0.8-4.5,1.7-6.4,4.3c-2.6,3.6-5.3,14.9-9.8,14.2c-2-0.3-4-3.4-6.2-4c-4.7-1.2-6.5,1.6-9.6,5.1c-0.7,0.8-2.9,4.4-3.8,4.6
									c-0.8,0.2-4.3-2.7-5.2-3.3c-2.8-1.7-4.4-3.2-7.5-1.3c-2.8,1.7-5.4,6.8-7.7,9.2c-1.7,1.8-7.2,5.3-6.5,7.8
									c1.1,3.9,14.1,0.9,15.9-1.4c1.8-2.4,1-6.7,3.6-8.6c2.9-2.2,3.3-0.3,6.5,1.6c-1.5,1.1-7.7,3.5-8,5.4c-0.6,3.8,3.3,1.4,4.5,1.5
									c1.2-1.2,2.4-1.2,3.7,0.1c1.2,0.3,2.1-0.2,2.8-1.3c1.6,0.1,2.7,1,4.1,0.9c1.7,0,5.1-0.8,7-0.9c1.8-0.2,2.8,1.2,4-1.4
									c1.2-2.7-3.3-4.5-4.1-6.4c-1.7-3.9-1.4-3.4,1.4-6.9c5.1,4.8,10.5,9.4,16.6,12.8c-5.7,6.1-7.2,6.9-15.4,5.1c-4.6-1-5.3-0.9-8.7,3
									c-3.8,4.2-7.2,8.5-12.1,11.4c-2.9,1.7-6.2,3-9.5,3.8c-2.6,0.6-6.6,0.1-8.9,1c-6.3,2.6-0.9,4.1,3.9,3.8c6.6-0.3,12.9-2.2,19.5-2.6
									c4-0.2,8-0.1,12,0.2c1.9,0.1,7.7,1.5,9,0.6c3.8-2.8-6.9-7.7-3.2-13.7c6.1,4.5,14.2,7.3,21.6,8.6c2.5,0.5,8.5,2.3,10.2,0.3
									c0.9-1,0.3-6.7,0-7.9c-0.5-2-3.2-4-3.4-5.9c-0.2-2.4,1.9-3.4,1.7-5.8c-0.1-1.5-2.4-1-2-3c0.2-1.1,0.9-1.2,1.4-2.1
									c0.5-0.8,2-0.5,2.5-2c-0.2,0.5-1.5-5.4-1.4-4.5c6.3,9.2,14.7,14.4,23.6,20.8c-1.2,0.9-4.7,2.6-5,4.2c-0.5,3.2,2.4,1.5,4.1,1.6
									c4.2,0.1,16.4,0.3,18.3-5.2c0.8-2.3-6.1-9.1-0.7-8.8c4.2,0.2,9.1,13,14.8,13.9c1,0.2,6.9-0.7,8-1.1c4.7-1.7,1.7-2.1-0.3-5.3
									c-4.3-7.2,2.2-7.9,7.2-3.8c3.3,2.7,9.6,8.8,12.1,1.2c1-3-1.3-4.6-2.6-7c-1.8-3.3-2-6.1-2.2-9.8c3.4,1.7,7,3.7,10.1,5.8
									c1.8,1.2,4,4,6.5,3.6c3.5-0.5,6.7-7.7,8.6,0.6c-2.8,0.3-5.1,2.9-7.2,4.6c-1.7,1.4-7.2,4-8.1,5.7c-4.2,7.5,8.3-1.3,9.7-2.3
									c2.2-1.7,4-3.3,6.7-2.7c2.5,0.5,5.9,5.6,9,4.9c7.1-1.6-4.6-6.3-6.3-8.1c2.7-0.5,9.7,2.6,11.2,1.4c3-2.4-4.2-3.6-3.4-6
									c0.8-2.2,13.7,8.1,13.3-2.7c-0.2-3.8-2.1-4.8-5.3-3.9c-1.8,0.5-2.2,5.5-4.6,0.9c1.3-0.7,7.8-5.2,3.7-6.3c-2-0.6-3.4,5-6.5,3.4
									c2.8-2.1,4.7-4.6,6.6-7.5c4.2,3.2,7.1,8.1,11.9,10.4c2.4,1.2,5,1.3,7.5,2.3c3.2,1.4,5.9,3.9,9.1,5.4c-1.7,1.9-14,9.9-11.5,12.5
									c2.4,2.4,10.6-3.7,12.9-4.6c2-0.8,4.2-1.7,6.4-1.6c2.9,0.1,4.8,3.1,6.9,3.2c7.2,0.5,0.1-5.2-2.4-7.3c5.9-1.3,11-4.6,10.5-10.4
									c-0.2-2.6-1-4.3-1.6-6.5c-0.9-3.6-1.9-3.7,1.8-2.7c6.5,1.7,11.6,9,18.3,10.9c1.9,0.5,5.4,2.2,4.8-1.4c-0.3-2-6.9-4.1-8.5-5.1
									c1.5,0.3,10.4,3,9.7-0.5c-0.5-2.9-7.3-1.4-3.5-6.1c1.6-2,6.4-0.6,7.4-2.1c1.7-2.6-2.5-3.7-3.8-6.2c4.9,2.4,0.1-3.9-0.4-4.6
									c9.2,4-0.6-5.5,0.7-7.3c4.3,6.2,9.2,11.4,15.8,15.3c3.2,1.9,6.9,3.8,10.6,4.4c3.5,0.6,4-0.6,8-1.9c3.7-1.2,5.1-0.1,8.8,0.7
									c3.9,0.9,4.3-0.1,7.7-1.7c1.9-0.9,3.7-1,5.2-2.6c2.6-2.8,1-1.6,1.6-4.1c0.3-1.6-1.9-1-2-1.6c-0.4-3.8,8.8,0.2,9.7,0.3
									c2.6,0.4,14.3,2.3,16,0.9c4.1-3.2-2.5-4-4.4-4.2c-5.8-0.6-11.3-1-16.8-3c-4.2-1.5-8.6-2.9-10.2-7.3c-0.5-1.4,0.1-3.7-0.9-4.9
									c-0.4-0.4-4.2-1.5-4.3-1.6c-1.6-3.2,4.4-2.4,5.7-4.6c1.4-2.5-2.2-4.7-0.8-7.7c8.3,4.4,8.5,14.2,16.7,19c3.6,2.1,8.4,3.7,12.6,3.4
									c5.5-0.4,5.1-3.8-0.4-4c10-4.5-1.8-4.2-6.4-4.6c5.3-2.8,11.7-3.7,5.2-8.2c0.5,0.3-3.9-3-3.5-2.8c-0.2-0.1-4.8-0.7-4.2,0
									c-0.9-0.5,1.3-5.8,1.5-6.5c2.5,2,1.7,0.6,3.9,0.1c0-0.5,0.1-1.1,0.1-1.6c1.1-0.1,2.2-0.1,3.3,0c3-1.6,1.7-2.7,3-6.3
									c5.3,4,11.2,6.6,16.7,10.4c1.8,1.3,15.2,10.8,15.4,5c0.1-2.6-5.2-3-6.7-4.1c-3.2-2.4-1.9-2.9-1.8-6.9c0.1-3,0.2-5.9,0.5-8.4
									c0.1-0.9,1.2-1.4,1.2-2.4c0.1-1.5-1.5-2.8-1.5-4.6c0.3,0,5.2,4.1,5.2,4c0.3,1.6-2.5,3-2.5,4.6c-0.1,3.2,2.2,3.5,3.7,5.7
									c0.4,0.5,2,4.6,2,4.6c1.5,1.2,10.1,1.7,12.3,0.2c6-4.2-3.2-6.9-4.3-11.2c1.8,0.3,7.8,2.2,8.7-0.2c1.4-3.5-4.9-2.7-6.2-3.4
									c-4.4-2.4,0.4-3.2,0.2-7c-0.3-4.8-5.6-2.1-0.3-7.8c3.6,6.7,11.8,7.9,16.4,14c2,2.7,4.6,6.9,8.4,7.3
									C2839.9,1976.8,2843.7,1973.8,2839.9,1973.1z M2480.3,2053.5c0.5-1.4,1.2-1.2,2.3,0.4C2481.9,2053.8,2481.1,2053.6,2480.3,2053.5z
									M2488.3,2034.8c-2.9,2.4-4.6,6-7,8.9c-0.8,1-2,2.8-3.1,3.4c-3,1.8-2.6,1.4-6-0.9c1.5-2.6,4.6-3.5,5.8-5.9
									c-0.3-0.9-0.8-1.7-1.5-2.4c0.4-0.6,0.9-1,1.6-1.2c1.1,1.2,2.1,1.1,3-0.3c0.1-0.9,0-1.7-0.3-2.6c1.2-2.4,3.3-4.4,5.2-6.7
									C2488.2,2029,2490.3,2033.1,2488.3,2034.8z M2499.6,2046.4c-2.9-3.2-1.4-5.7,2.1-2.8C2504.9,2046.5,2503.8,2048.8,2499.6,2046.4z
									M2519.1,2058.7c-3.7,4-7.3-4.1-5.3-7.7c1.9,1.8,4.9-2.3,6.9,0C2522.6,2053.2,2518,2056.5,2519.1,2058.7z M2515.4,2046.6
									c-5-2.1-0.4-6.9-0.6-9.9c-0.1-1.3-3.6-6.6-2.5-8.5c1.4-2.4,5.8,3.2,6.8,4.4c1.3,1.5,4,4,3.8,6.1
									C2522.6,2040.6,2516.8,2045.1,2515.4,2046.6z M2529,2052c0.3-0.4,0.5-0.8,0.8-1.2C2530.8,2051.7,2530.6,2052.1,2529,2052z
									M2528.1,2030.8c-8.2,1.3-4.2-7.5-3.2-11.4c1,1.9,3.3,3.9,4.2,5.7c0.6,0.5,1.2,1,1.8,1.5
									C2529.8,2027.9,2528.9,2029.3,2528.1,2030.8z M2551.2,2037.4c-1.7,1.7-10.3,6.6-7.6,9.3c3.3,3.2,6.2-5.7,8.6-4.4
									c5.3,2.8-12.4,14-12.4,15.6c-5-5.2,1.2-5,2.3-9.8c0.4-1.6-0.8-4.9-1.1-6.5c-0.3-2,0.8-2-0.3-4.1c-1-1.9-5.9-3.1-4.1-5.1
									c1.4-1.6,6.1,4,7.8,4c1.3-0.1,5-1.4,2.2-3.3c2.8,0.3,5.6,0.3,8.4,0.3C2553.8,2034.6,2552.5,2036,2551.2,2037.4z M2564,2047.3
									c-1,0.7-0.7,2.7-2.9,0.4C2559.8,2046.2,2564,2043.4,2564,2047.3z M2563.3,2037c-1.7,0.8-4.4,0-3.2-2.3
									C2560.8,2033.2,2565.8,2036,2563.3,2037z M2581.9,2045.4c-3-1.2-4.4-3.7-7-5.4c-1.2-0.8-6.8-2-7-2.7c2.2-0.2,5.7-1.6,5.2-4.4
									c-0.4-2.1-2.5-1.7-3.7-2.6c-2.5-1.8-3.4-0.7-3.3-4.2c0,0,2.5-5.9,2.4-5.8c2.6-2.8,5.5,2.1,8,5.2c3.9,4.7,7.3,10,12.6,13.2
									C2586.7,2040.9,2584.2,2043.1,2581.9,2045.4z M2614.1,2048.1c0.3-0.7,0.7-1.3,1-1.9c-0.1,0.9-0.5,3.3-2,3.2
									C2613.4,2049,2613.8,2048.6,2614.1,2048.1z M2602.2,2048c-0.9,1.9,0.5,3.7-1.7,3.7c0.3-0.5,0.8-6.2,2.9-5.2
									C2603,2047,2602.6,2047.5,2602.2,2048z M2601.2,2040.1c-1.4,0.9-2.8,1.8-4.2,2.8c-2.8-3.7,1.6-3.2,2.8-5.2c1-1.7,1.2-3.4-1.7-2.7
									c1.9-2.9,2.9-6.2,4.1-9.4c0.6,0.9,4.3,4.8,4.3,5.5C2606.5,2033.3,2601.4,2036.6,2601.2,2040.1z M2644.8,2048.5
									c-2.4,4.7-11.4-11.4-12.3-12.6c-1.1-1.4-2.6-2.5-2.1-4c1.2-3.9,2.9-0.7,4.3,0.2c4.9,3.1,8.9,8.4,13.7,11.8
									C2647,2045.3,2645.8,2046.8,2644.8,2048.5z M2652.5,2039.7c-1.3-0.8-7.5-14.3-7.3-14.6c1.9-3,10.3,6.7,12.3,7.8
									C2656,2035.3,2654.3,2037.6,2652.5,2039.7z M2718.8,2027.9c-3-4.2-3-9.4-5.2-13.2c-0.5-0.9-2.1-1.3-2.8-2.3c-0.6-1.1,0.5-2,0-3
									c-0.3-0.6-4.3-4.4-3.5-5c2.1-1.6,7.9,6.6,9.3,7.8c3.8,3.5,8.1,6.8,13.4,7.7C2726.5,2022.9,2722.9,2025.9,2718.8,2027.9z
									M2734.3,2015.3c-1.6,2-1.3,0.9-3.3,0.7C2732,2015.3,2733.1,2015,2734.3,2015.3z M2813.1,1981.5c0.5,0.7,0.5,1.1-0.2,1.1
									C2813.1,1982.2,2813.1,1981.8,2813.1,1981.5z"
						/>
						<path
							d="M2425.9,2045.1c-4.9-2.7-8.9-18.7-13.9-18c-2,0.3-2.4,2.8-3.9,3.7c-0.4,1.9-1.1,2.4-2,1.4c-0.7-0.5-1.5-1-2.2-1.5
									c-4.9,0.9-6.4,3-9.6,7.1c-1.6-3.7-3.5-6.9-4.5-10.8c-0.6-2.2-0.2-7.9-3.7-7.7c-2.5,0.2-6.8,9.3-8,11.2c-2.2,3.6-4.4,7.3-6.8,10.8
									c-2.9,4.5-3,6.5-8,3.1c-3.2-2.2-6-5-8.7-7.8c-6.6-6.8-9.6-10.6-15.5-0.7c-2.9-3.2-7.4-11.8-11.7-13.1c-2.9-0.9-2.4,0.1-4.9,2.3
									c-1.5,1.4-6.7,8.8-8.6,8.8c-2.9,0-5.6-6.7-8.4-7.3c-2.2-0.5-1.9-0.4-4.2,1c-3,1.9-10,7.1-11.7,10.1c-3.2-3.4-4.4-7.5-7.1-11.1
									c-3.5-4.6-4.7-1.8-6.8,2.5c-3.8,7.9-8.1,16.2-15,21.9c-2.6,2.1-19.1,9.4-17.8,12.4c1.2,2.8,5.6,1.1,7.4,0.3c1.6-0.6,4.1-3.4,6-3.3
									c1.3,0.1,1.9,2.1,3.1,2c2.6-0.2,3.7-1.3,6.4-2.1c2.7-0.7,6.4,0.9,8.2-1.3c1.8-2.2-3.6-1.3,0.2-3.1c1.7-0.8,4.7,1.4,5.9-0.5
									c0.5-0.7-0.7-5.1-0.5-6.3c0.2-0.9,1.8-1.2,1.7-2.7c-0.1-1.3-1.7-1.7-2.1-2.6c-0.4-0.8-2.2-1.2-1.9-2.5c0.4-1.5,2.7-0.2,3.3-0.8
									c0.4-0.4,2.8-4.1,1.9-5.1c7.9,8.1,17.5,20.5,29.8,22.1c0.9,0.3,1.5-0.1,1.9-1c1.1,0.1,2.1,0.5,2.9,1.3c3.2-0.1,2.7,0.5,5.6-1.7
									c1.6-1.2,4.1-3.2,3.6-5.4c-0.3-1.3-2.5-3.1-3.9-2.7c0,0,0.8-3.3,1.3-3.7c0.5-0.4,3.1,0.8,4,0.3c5.4-3-2.1-5.3-1-8.6
									c0.1-0.4,5.2-1.1,5.8-0.9c1.7,0.7,3.8,4.4,4.7,5.8c3.3,5,5.8,14.3,11.8,16.8c14,5.6,0.8-15.4,3.3-17.6c2.9,2.8,5.8,5.7,9.2,7.9
									c1,0.7,2,1.3,3,1.8c1.2,0.2,1.4,0.8,0.5,1.7c0.4,0.7,0.2,2.6,0.5,3.5c1.2,3.3,6.8,7,10.2,7.1c4.7,0.2,5.1-3,7.3-6.3
									c2.3-3.5,4.8-4.1,4.1-8.6c-0.4-2.6-3.2-6.1-1.8-9c2.5-5.6,3.8,1.9,5.7,3.3c2.8,2.1,3.1,1.3,5.6-1.1c1.4-1.4,3.3-6.7,6.3-5.3
									c2.7,1.3-4.3,8.8-5.7,10.4c-2.6,3.2-16.3,16.7-3.5,14.6c3.9-0.6,3.8-3.9,7-5.2c3.3-1.3,9.2,3.2,11.4,1.9c2.8-1.7-0.5-3.2-1-5.5
									c-0.9-4.5,2.1-9.3,4.2-13.1c1.4,3,3.4,6.9,6.2,8.9C2424.7,2049.2,2429.1,2047,2425.9,2045.1z M2305.3,2043.5
									c-0.7,0.1-1.4-0.2-2-0.9c-0.5-0.5,2.6-2.6,2.5-3c-0.1-0.9-1.5-3.1-1.4-3.4c0.8-1.7,3.9-1,4.7,0.3
									C2310.8,2039.1,2307,2041.5,2305.3,2043.5z M2348.2,2035.9c0.5,0.6,1,1.1,1.5,1.6C2348.8,2037.3,2348.3,2036.7,2348.2,2035.9z"
						/>
						<path
							d="M2240.8,2056.5c-4.9-2.9-9.3-6.5-14.1-9.6c-1.9-1.2-3.9-2.3-5.6-3.9c-1.5-1.3-2.5-4.3-3.9-5.3c-5-3.7-4.1,4.3-7.3,6.9
									c-2.6,2.1-2.4,1.6-4.8-0.7c-1.1-1-2.8-4.4-4.2-4.9c-4-1.5-8.2,5.4-11.1,7.8c-4.1,3.5-9,6.4-14,8.5c-1.6,0.7-10.6,1.8-8.7,5
									c1.6,2.6,10.9-0.3,13.5,0.1c5.7,0.9,5.6,0.8,10.9-1c3.1-1,5.7-0.3,8.9,0.1c3.8,0.5,7,3,10.4,3.4c1.6,0.2,3.3-0.8,5.1-0.7
									c1.7,0.2,3,1.6,4.5,1.8c5.8,0.8,1-2.7,0.4-6.3c-0.5-2.9,0.4-6.5,4.1-6.7c1.8-0.1,6.6,4.2,8.1,5.2c1.4,0.9,4.9,4.3,6.6,3.9
									C2240.8,2059.7,2243.3,2058,2240.8,2056.5z M2199.5,2048c1.2,0.9,2.3,1.7,3.5,2.6C2197.7,2049.2,2196.5,2048.4,2199.5,2048z"
						/>
						<path
							d="M2250.1,2026c-7.7-3.9-10.7-10.5-16.6-16c-3.8-3.5-3.2-1.7-6.9,1.1c-2.2,1.7-1.2,2.7-3.7,2.2c-1.7-0.4-2.9-3.5-4.9-3.2
									c-1.9,0.3-4.7,5.4-6,6.6c-2.6,2.3-5.6,4-8.7,5.6c-2.6,1.3-16.3,4.2-12.9,8.1c4.1,4.7,13.9-1.9,18.3-1.8c3,0.1,4.9,2.5,8,2.8
									c1.6,0.1,3.3-1,4.9-0.9c1.7,0,3.1,1.1,4.7,1.1c4.2,0.1,5.4-0.6,3.9-4.1c-1.1-2.5-3.9-0.2-1.9-3.7c0.7-1.2,2.7,1,3.5-0.7
									c0.7-1.5-0.8-1.9-0.9-3.1c-0.2-1.9-2.2-3.7,0.3-5.9c2.6,4.7,7.3,8.6,11.6,11.8c1.4,1,4.3,4.1,6.1,3.6
									C2250.2,2029.2,2252.7,2027.3,2250.1,2026z"
						/>
						<path
							d="M2188.9,2044.4c-3.3-1.5-5.6-6-8.9-7c-3.7-1.1-7.5,3.1-10.6,4.5c-2,0.9-9.9,2.1-10.4,4.5c-0.7,3.4,3.2,2.3,5.1,2.3
									c4.3-0.2,8.6,1.6,12.6,1.6c6.4-0.1,1.2-3.2,2.2-4.1c2-1.7,2.5,0.3,5.3,1.2C2186.1,2048,2193.1,2046.4,2188.9,2044.4z"
						/>
						<path
							d="M2215.9,1986.7c-10.1-2.1-17.8-9-25.6-15.3c-1.8-1.5-7.5-8.2-10.3-7.5c-3.1,0.8-6.4,8.3-8.8,10.8c-3.1,3.2-6.7,6-10.7,8.2
									c-1.3,0.7-9.1,2.8-6.8,5.7c1.4,1.9,8.3-1,11-0.4c3.8,0.8,7.4,0.7,11.5,1.2c-1.9,3.5-3,7.2-5.1,10.6c-1.2,1.9-6.9,6.8-6.9,8.9
									c-0.1,6.6,10,1,13.3,0.8c4.8-0.4,5.6,1.8,6.7-4.2c0.6-3.1-1.3-6.5-0.2-9.2c1.9,2.6,5.7,8.8,8.9,9.7c0.4,0.1,7.5-0.1,7.8-0.4
									c1.6-2-1.9-3.3-1.5-4.2c1.4-2.6,5.7,1.5,7.1,2.4c2.1,1.2,6.5,4.4,8.5,1.8c2.2-2.9-4.7-4.2-6-4.9c-3.8-2-6.5-5.4-10.1-7.5
									c-4.1-2.5-4-0.8-7.3,2c-3.4-4.6-5.9-9.8-9.1-14.5c-1.4-2.1-3-3.5-1.9-6.2c0.7-1.8,2.7-1.8,3.5-3.4c2.8,2.6,4,5.7,6.6,8.3
									c3,3,6.8,4.1,10.6,5.7c3.8,1.6,8.2,5,12.4,5.1C2215.2,1990.3,2220.2,1987.6,2215.9,1986.7z M2174,1984.4c-1.4,0-2.8,0.2-4.2,0.2
									c1-0.7,3.5-2,4.8-2.1C2175.2,1983.2,2175,1983.8,2174,1984.4z"
						/>
						<path
							d="M2217.8,1953.9c-5.7-1.4-11.2-4.8-16.4-7.5c-3.7-1.9-8.9-7.3-12.5-5.5c-2.2,1.1-4.6,7.5-6.2,9.6c-2.2,3-2.3,4.2-5.8,3.1
									c-4.2-1.4-6.4-8-11.1-7.6c-3.2,0.3-20.5,22.8-19.9,24c1,1.9,5.9-0.6,8.4,0.2c1.6,0.5,6.2,4.4,6.6-0.3c-0.3,3.3,6.6,2.9,8.2,1.2
									c4.2-4.4-1.7-4.7-1.8-5.4c0-0.1-1.7-0.7-0.4-2.3c1.3-1.6,3.7-0.2,4.2-0.8c1.8-2.4-1.6-4.1,0.9-7.6c3.3,2.4,8.9,7.5,13.1,7.4
									c1.8-0.1,1.7-1.7,3.6-0.9c0.1,0,2.1,3.2,2.8,3.6c2.2,1.4,7.4,1.7,10,1.5c6.6-0.5,0.6-1.2-0.1-4.5c-0.4-1.8,1.9-2.6,1.5-3.9
									c-0.6-2.2-3.7-3.2-5.3-4.5c-4.6-3.7-3.7-6.6,2.2-3.6c4.3,2.2,8.5,4.6,13,6.4c1.5,0.6,4.2,2.2,5.6,0.8
									C2219.4,1956.2,2219.6,1954.4,2217.8,1953.9z M2193.7,1961.4c-1.1-2,0.4-1.6,1.7-1.3c2.4,0.5,0.6,0.7,2,3
									C2195.8,1963,2194.2,1963.5,2193.7,1961.4z"
						/>
						<path
							d="M2223.2,1927.6c-7.4,0.6-12.9-5.5-18.1-9.9c-2.7-2.2-5.5-4.3-8.3-6.3c-1.3-0.9-3.5-1.8-4.4-3.1c-1-1.4,0.6-1.7-0.6-3.4
									c2.4,0.4,9.9,6.5,8.3-0.5c-0.3-1.5-5.4-3.5-2.6-4.8c2.1-1,7.3,3.5,8.8,4.5c2.4,1.5,9.8,7.7,12.8,7c7.4-1.7-9.5-9.8-12-11.4
									c-2.7-1.7-7.2-5.4-10.6-5.3c-4.2,0.1-5.9,5.6-9.7,6.4c-7.4,1.6-12.4-16.1-17.2-14c-2.9,1.3-3.6,7.5-6.2,9.4
									c-4.5,3.4-4.5-2.4-8.9-2.9c-3.1-0.3-7.9,4.8-10.5,6.7c-1.4,1-8.1,5.5-3,6.5c1.6,0.3,3.3-2,4.7-2.7c1.1-0.7,2.2-1.3,3.2-2
									c0.5,0.5,1,1,1.5,1.5c2.9,0.1,2.3,0.9,5.4,0c1.9-0.6,1.2-3.1,3.6-2.2c-3.4,4.2-6.5,8-10.8,11.4c-2.6,2-14.9,7.5-11.2,10.6
									c2.5,2.1,11.3-2.2,13.5-3.1c0.7-1.3,1.8-2,3.2-2.2c0.9,0.4,1.8,1,2.6,1.7c2.4,0.5,4.4,0.8,6.8,1.7c-1.1,1.3-3.3,4.2-4.9,4.9
									c-0.7,0.4-1.7-1.7-3.5-0.6c-3.4,2.1-6.3,7.2-9.2,9.9c-1.8,1.6-10.2,6.2-10.6,8.3c-0.8,4.6,5.7,0.7,6.9,0.7
									c6.9-0.4,17.1,6.4,15.1-4.7c-1.9-10.4,6.4-2.7,11.8-0.6c3.3,1.3,7.6,2.9,10.7,0c3.7-3.5-0.4-5.2-2.1-7.9c-1.6-2.5-3.8-5.8,0.7-6.6
									c3.7-0.7,5.9,5.8,8.1,7.5c4,3.2,17.5,3.9,14.7-4c-1.6-4.5-11.5-8.1-7.3-14c2.9,1.8,4.3,4.2,6.4,6.7c2.9,3.4,5.9,4.7,9.9,6.5
									c3.4,1.6,7.8,5,11.8,4.1C2223.7,1931.2,2227.4,1927.3,2223.2,1927.6z M2163.1,1914.8c0.7,0.8,1.4,1.5,2.1,2.3
									C2163.6,1916.7,2163.4,1917.3,2163.1,1914.8z M2186.5,1906.9c-3.2,1.9-5,9.6-9.1,10.3c-2.2,0.4-2.6-0.8-4.6-2.7
									c-0.1-0.6-0.3-1.2-0.4-1.7c-0.6-1.3-1.7-1.8-3.1-1.3c-1.3-0.8-3,1.4-3-1.6c0-1.6,2.6-2.6,3.4-3.8c0.8-1.3,3.2-11.6,2.7-11.4
									c2.3,3.6,6.5,7.5,9.5,9.8c1.1,0.9,0.5,1.1,2.3,2C2183.9,1906.4,2188.5,1905.7,2186.5,1906.9z"
						/>
						<path
							d="M2222.9,1859.6c-9.1-3.5-19.1-3.3-27.7-8.3c-5.2-3-5-3.3-8.2,1.8c-2.7,4.2-4,3-7.5,4.4c-1.6,0.6-2.9,1.5-4.4,2.5
									c-3.2,2.1-2.6,2.7-5.2,0.8c-4-3-3.5-7.4-7-0.9c-4.8,9-8.2,16.5-17.1,22.4c-2,1.3-12.2,5.1-12.7,6.9c-1.6,5.5,5.8,1.3,8,0.4
									c8.2-3.5,17.3-2.3,25.6-4.6c5-1.4,8.4,0.7,13.3,0.6c2.1,0,3.9-1.2,5.9-1.2c1.3,0,3.2,1.1,4.3,1c8.8-1.1-2.7-12.7-0.5-18.1
									c2.5,1.7,9.7,8.6,12.7,8c5.4-0.9-0.7-4.4-2.2-5.6c-2.3-1.8-14-10.4-9.8-13.6c2.3-1.8,15.8,4.8,18,5.4c3.9,1.1,8,1.9,12,1.5
									c1.3-0.1,2.4,1.5,3.5-0.2C2224.6,1861.6,2224.1,1860,2222.9,1859.6z M2174.8,1879.9c-1.9-2.4-2.8-6.4-4.2-9
									c-0.8-1.6-3.3-1.9-1.6-4.9c1.8,2.4,3.6,4.8,5.4,7.1C2177.7,1877.3,2175.2,1875.6,2174.8,1879.9z M2175.9,1866.4
									c1.7-3.8,8.4-2.3,6.5,1.5C2181.4,1870.2,2174.4,1869.8,2175.9,1866.4z M2183.8,1881.4c-1,0.4-7.3,0.5-4.2-1.3
									C2180.9,1879.4,2183.3,1881.6,2183.8,1881.4z"
						/>
						<path
							d="M2213.6,1845.1c-5-2.4-10-4.6-14.8-7.1c-1.6-0.8-4-2.7-6-2.2c-1.6,0.4-2.7,3-3.9,3.2c-3.3,0.5-8.2-5.6-10.7-7.8
									c-6.3-5.5-6.1-1.1-10.1,5.3c-2-1.3-6.6-6.3-8.9-6.1c-2.6,0.2-5.5,5.4-7.4,7.1c-2.4,2.2-16.6,7.5-16.7,9.4c-0.1,4.3,8.9,1,10.7,1
									c3.8-0.2,7.1,1.2,10.7-0.9c3.9-2.3,3.5-6.2,6.4-9.1c1,0.7,1.9,1.4,2.9,2c-2.3,3.3-5,6.5-8.3,8.9c-2,1.4-7.7,2.5-8.9,3.9
									c-4.6,5.6,13.7,2,16.1,1.4c3.2-0.9,7-1.7,8.5-4.8c1.2-2.4-1.4-2.3,1-4.3c1.8-1.5,5.1,0.8,5.6-2.7c0.1-1-3.7-5.1-1.7-5.4
									c2.1,1.9,4.5,3.2,6.8,4.8c-2.5,1.2-5.5,2.6-1.9,4.6c4.7,2.5,9.4-4.6,13.3-4.9c2.3-0.2,8.2,3.8,10.4,4.8c1.1,0.5,5,3,6.5,2.3
									C2214.6,1848,2215.4,1846,2213.6,1845.1z"
						/>
						<path
							d="M2219.9,1782.5c-6.4-3.2-10.9-8.8-15.9-13.8c-1.5-1.5-4.7-5.8-6.9-6c-2.7-0.2-3.3,2.9-5.3,4.1c-6.7,4-9.1-5.4-14.7-7.9
									c-3.8-1.7-3.9-0.2-6.3,3.2c-1.1,1.5-2.2,3-3.4,4.5c-2.5,3-1.8,3-5.7,1.9c-5.7-1.6-11.8-9.7-18-6.7c-5.1,2.4-9.3,10.9-13.2,14.9
									c-1.4,1.4-7.8,4.9-5.8,7.3c1.8,2.2,4.5-0.7,6.7-0.8c3.2-0.2,5.3,2.1,8.5,1.5c3.9-0.7,5.7-4,10.3-2.2c-5.4,3.6-10.6,7.2-16.3,10.5
									c-1.8,1-13.9,4.6-7.5,7.2c2.4,1,5.6-2,8-2.1c1.5,0,1.6,1,2.5,1c3.7,0.1,11.7-4.8,14.9-3.3c0.4,0.2-1.1,1.6-0.7,1.8
									c2.6,1.5,3.3,1.7,6.4,0.9c3.5-0.9,4.7-0.8,2.5-4.7c-1.7-3.1-5.5-4.5-7.3-7.6c4.5-1,8.6,6.2,12.6,7.4c4.2,1.3,2.2,0.3,4.6-1.4
									c-0.6,0.4,0.6-5.3,2.6-1.8c1.2,2.1-3.3,3,1.2,5.4c3.9,2.1,9.5-1.7,13-2.4c2-0.4,3.1,0.7,4.8,0.4c1.8-0.3,2.8-1.5,4.8-1.7
									c2.2-0.2,2.1,2.3,5,0.7c2.3-1.3,2-3.7,1.5-5.8c-0.8-3.2-4.8-7.2-3.6-10.9c1.5-4.5,4.4-2.4,6.9,0.3c2.1,2.3,3.1,5.7,5.4,7.8
									c2,1.8,4.1,2.5,6.7,1.7C2218.8,1785.7,2223.4,1784.3,2219.9,1782.5z M2135.5,1779.4c0.4-0.7,0.7-0.8,0.7-0.2
									C2136.4,1779.8,2136.2,1779.9,2135.5,1779.4z M2176.3,1785.7c-1.6-1.3-4.8-5.9-6.8-6.1c-2.7-0.2-4.7,4.3-6.7,4.2
									c-3.3-0.2-4.4-10.6-4.5-12.8C2162.1,1772.8,2183.4,1780,2176.3,1785.7z M2186.9,1778.3c-4.9-4.2-9.2-6.4-9.6-13.7
									c2.7,2.8,6.2,4.5,8.9,7.1C2189.3,1774.7,2187.9,1773.4,2186.9,1778.3z"
						/>
						<path
							d="M2170.1,1754.5c-6.3-0.7-9.9-4.7-15.5-6.8c-3.2-1.2-6.7-0.7-9.9-2.2c-1.6-0.7-3.5-3.2-5.3-3.2c-3,0-5.3,6.5-7.2,8.8
									c-2.5,3.1-6.4,6.1-1.7,8.4c3.5,1.7,10.2-0.3,13.4-1.3c1.6-0.5,3.2-0.2,4.7-0.6c2.1-0.6,4.5-2.9,6.5-2.8c2.9,0,3.3,2.6,5.4,3.4
									c2.2,0.9,3.1,0.8,5.5,0.7c0.4,0,5.1-0.3,5.4-0.7C2172.3,1756.9,2172.2,1754.8,2170.1,1754.5z M2145,1750.1c2.6,0.8,5.4,0.6,8,1.4
									c-3.6,0.9-8,4-9-1.7C2144.3,1749.9,2144.7,1750,2145,1750.1z"
						/>
						<path d="M2247.6,1787.5c0.3,0.5,0.8,0.9,1.3,1C2248.5,1788.2,2248.1,1787.9,2247.6,1787.5z" />
						<path
							d="M2334.4,1777c-8-0.3-14.4-5.9-19.8-11.3c-3-3.1-10-13.9-15-9.7c-2.5-3.7-3.2-5-6.4-1.6c-2.1,2.3-3.8,6.1-5.5,8.7
									c-3-3-5.1,0-7.1,2.1c-3.1,3.2-6,5.8-9.9,8.1c-2.7,1.6-7.3,4.9-10.1,5.3c-1.6,0.2-2.2-1.7-4.3-0.9c-1.9,0.7-2.8,3.8-4.7,4.7
									c-5,2.5-4.6-4.2-9.4-3.3c-1.8,0.3-6.1,6.1-7.5,7.5c-0.3,0.3-4.1,5.3-4.2,5.3c-1.3,0.4-4.8-2.3-6-2.8c-4.4-1.6-7-2-9.9,2.3
									c-7.1-8.5-8.2,5.4-14.8,7.6c-3.5,1.2-5.4-3.3-9.4-2.5c-2.8,0.6-5.9,4.8-8.1,6.6c-4.1,3.5-3.7,4.2-7.7,0c-2.1-2.2-3.6-6.7-6.1-8.3
									c-6-4-8.7,8-13.3,10.8c-2.6,1.6-5.2,1-7.8,3.9c-2.8,3.3-4.8,7-7.8,10.2c-1.8,1.9-7.4,5.2-6.5,7.9c0.9,2.5,5.3-0.2,6.8-0.5
									c7.1-1.5,15.2,1.9,22,0.5c2.3-0.5,3.6-2.4,6.7-2.8c0.7-0.4,1.4-0.8,2.2-1.2c0.6,0.4,1.2,0.8,1.9,1.3c2.8-0.2,3.5,0.1,5.9-1.9
									c0.2,1.1-0.2,3.3,0.7,4.2c1.5,1.3,1.9-0.8,3.1-0.5c3.8,0.9,6.8,3.3,10.9,2.6c2.6-0.5,9.2-4.3,5.3-7.5c-2.9-2.4-7,3.1-5.4-4
									c3.6,1.6,7.3,2.7,11,3.8c2.4,0.7,10.3,4.2,12.2,3c6.2-3.7-6.5-5.6-9-6.2c-3.8-1-20.1-4.5-21.3-9.2c-1-4.2,4.1-2.6,5.8-2.9
									c1.1-0.2,2.1-1.4,3.4-1.2c1.5,0.2,2,1.8,3.4,2.2c2.4,0.7,4.3,0.1,6.3-1.4c1-0.8,0.5-1.5,1.4-2.2c1-0.8,2.1-0.2,3-1.5
									c1.2-1.7-1.3-4.5,0.4-5.9c1.4-1.2,5.6,1.7,5.2,3.7c-0.3,1.6-4.4,2.4-5.2,4.2c-0.8,1.6-0.6,5.7,1.3,6.6c5.4,2.3,11.3-8.1,17.9-4.1
									c-1.2,0.1-3.3,2.2-3.2,3.5c0.1,2.7,10.8,1.6,12,0.7c5.3-4-15.2-12.4-9.3-13.8c1.8-0.4,5.9,3,7.4,4c3.4,2.2,6.5,3.6,10.2,5.1
									c2.7,1.1,8.5,4,6-1.5c-1.5-3.3-13.2-8.8-16.3-10.3c3.6-1.4,11,5.2,14.1,1.8c1.7-1.8-4-7.8-4.3-10.3c1.8,1.5,9.2,8.3,11.5,6.5
									c3.7-3-2.5-4.8-4.3-5.9c2.2-1.5,3.2-3.1,5-4.9c3.4,3,8.3,17.5,13.1,17.5c4.1,0,1.5-2.3,1-4.5c-1.1-4.6-1.7-6.1,2.7-8.3
									c2.6-1.3,7.1-2.9,10.1-2.4c3.8,0.6,5.5,3.9,9.5,3.7c2.8-0.1,5.4-3,8.2-2.8c1,0.1,1.8,1,2.6,1.1c0.7,0.1,4.1,0.4,4.4,0.5
									c1.4,0.5,4.1,3.8,5.3,0.7c0.8-2-3.6-5.4-5-6.8c-2.3-2.2-19.3-16.3-7.1-17.4c3.9-0.4,6.6,4.2,9.1,6.8c2.9,3,6.3,6,10.1,7.8
									c2.4,1.1,9.8,4.6,12.4,3.4C2336.1,1780.2,2337.5,1777.1,2334.4,1777z M2164.2,1822.1c-3.3-0.1-4.5-4.8-4.6-8.1
									c3.4,2.1,7.1,3.9,10.9,5.3C2168.9,1820.5,2166.4,1822.2,2164.2,1822.1z M2172.4,1813.7c-3.9-1.1-5.3-5.2-4.8-8.9
									c1.9,0.6,5.6,3,6.3,5.1C2174.2,1811.1,2175.4,1814.5,2172.4,1813.7z M2278.3,1778.5c-2.5,3-5.5,1.7-7.6-0.9
									c1.9-1.1,7.4-6.3,9.5-6.3C2280.7,1771.4,2278.6,1778.1,2278.3,1778.5z"
						/>
						<path
							d="M2184.1,1728.4c-0.8-2.8-7.3-6.9-9.6-8.3c-3.6-2.2-5.4-0.3-8.2-2.5c-2.6-2.1-2.2-5.6,1.5-5.6c2.5,0,7.2,6.3,8.5,1.5
									c0.8-2.9-8.5-5.8-10.2-7c-2.7-2-5.6-5.6-5.1-9.5c0.7-7.1,6,8.5,10.4,3.3c2.7-3.3-2.8-5.9-4.7-7.4c-2.6-2.1-7.2-9.5-10.7-8
									c-1.8,0.8-3.9,4.9-5,6.4c-2.6,3.6-1.1,2.9-4.7,2c-4.4-1.2-5.8-6.5-11-5.2c-3.1,0.8-9.3,4.4-11.8,6.2c-4.5,3.4-3.2,3.6,1.4,4.7
									c3.5,0.9,6.8,1.6,10.7,0.9c6.3-1.1,3-2,3.9-6.4c2,1.5,3.9,3,6.1,4.2c-1.3,1.3-6.8,5.7-4.3,7.9c2,1.7,5.2-3,7-3.6
									c5.8-2,12.2,1.3,7.6,7.5c-3.1,4.3-5.8,1.7-9.6-0.6c-1.6-0.9-7.6-6.5-9-6.4c-7.9,0.6,9.7,11.5,11.8,12.5c-1.6,0.9-5.6,2.9-3,5
									c1.1,0.9,3.9-0.4,5.7,0.3c3.7,1.3,3.5,2.3,7.5,2c1.1-0.1,1.4-1.4,2.8-0.9c1.7,0.6,0.7,1.9,2.1,2.9c2.2,1.6,6.5,4.9,9.2,4.9
									c3.3,0,2.3-1.9,5.9,0.1C2180.3,1729.5,2185.6,1733.4,2184.1,1728.4z"
						/>
						<path
							d="M2162.9,1597.6c-4.5-1-8.3-3.4-12.5-4.6c-4.4-1.3-3.5,0-6.1,3.7c-1.6,2.2-11.7,9.8-3.7,8.8c1.3-0.2,2.1-1.8,3.7-1.9
									c1.5-0.1,2.6,1.1,4,1.3c3.9,0.4,3.5-1,5.8-2.8c2.2-1.7-0.2-1.3,2.4-1.9c1.5-0.4,3.8,1.8,5.4,1.4
									C2163.4,1601,2166.1,1598.3,2162.9,1597.6z"
						/>
						<path
							d="M2159.1,1583.3c-5.4-3.5-7.5-6.6-12.6-3c-2.2,1.5-11.3,7.8-6.2,10.3c0.9,0.4,3.5-1,4.8-1.1c3.1-0.2,3.8,1.1,7.2-0.6
									c0.7-0.4,1.3-0.9,2-1.3c0.8-1.8,1.8-2.1,2.9-0.9C2157.6,1586.4,2162.7,1585.6,2159.1,1583.3z M2153,1584c-0.8,0.3-1.6,0.5-2.4,0.9
									C2150.9,1582.8,2151.7,1582.5,2153,1584z"
						/>
						<path
							d="M2159.9,1574.8c-2.5-2-8.2-9.3-11.4-8.1c-1.9,0.7-8.5,9.8-7,11.4c3.6,3.6,7.8-2.7,10.7-3.2c2.1-0.4,3.2,3.1,5.6,2.9
									C2159,1577.8,2162.3,1576.7,2159.9,1574.8z"
						/>
						<path
							d="M2175.8,1639.5c-4.7-2.9-8.1-6.8-11.7-10.9c-2.3-2.6-3.8-4.5-6.8-1.6c-1.2-2-1.4-3.9-3.2-5.4c-1.1-0.7-2.2-0.4-3.2,0.8
									c-1.5-1.4-3-2.8-4.3-4.4c3.9-2.9,10.8,1.7,9.1-6.9c2.1,1.3,6,7.4,8.6,7.1c5.4-0.8-2.3-6.8-3.2-7.6c-8.5-7.9-9.7-1.1-17.3,4.2
									c-5-7.2-6.5-4.1-8.7,2.9c-3.2-2.3-5.1-6.7-6.4-10.2c-2.1-5.6-0.8-8.7,0.1-14.3c2,1.8,5.8,8,8.2,7.9c4.9-0.3-1.9-7-2.5-8.1
									c-0.6-1-0.8-2.6-1.6-3.8c-0.6-1-2.5-1.5-2.9-2.4c-1.9-4.1,2.8-5.7,0.4-10c1.5,1.8,7.6,13.8,9.5,8c1.3-3.8-8.7-13.6-11.2-16.5
									c4.7-1.6,5.6-7.2,4.8-11.4c1.8,2.8,5.1,5.3,6.5,8.3c0.9,1.9-1.5,1.9,1.3,3.6c1,0.6,8.3,0.2,4.7-3.1c2.8-1.5,7.3-0.6,9.1-3.7
									c0.9,1.2,4,6.9,5.9,3.8c1.5-2.4-4.4-8.5-5.7-10.5c-6.3-9.8-7.1,2-12.3,7.4c-3.6-4.1-6.1-9.2-9.7-13.3c-3.6-4.1-5.3-1.6-8,2.2
									c-2.7,3.7-4.9,7.4-8.2,10.7c-1.9,1.9-10.8,7.1-9.3,9.8c2.8,5.1,14.9-5.5,7.4,5.3c-1.4,2-9.6,8.4-5.6,10.4c1.9,0.9,7.5-3.9,7.7-3.2
									c1,2.8-8.8,12-9.2,16c-0.2,1.8-0.3,3.4,0.9,5.1c3.3,4.6,3.9-0.1,7.3,0.6c-2.1,2.8-4.1,5.4-6.7,7.7c-2.9,2.6-4.2,2.5-1,4.9
									c4.2,3.2,13.1,3.1,14.7-3.5c1.9,1.5,7.6,7.4,10.3,5.9c-1.4,2.8-4.3,6.3-6.3,8.7c-1.9-2.4-2.6-4.3-5.6-2.8
									c-2.4,1.2-5.9,7.3-7.6,9.5c-1.3,1.7-4.5,4.6-0.7,5.7c1.4,0.4,4.3-2.7,5.5-3c6.7-1.5-0.4,3.1,0.2,5.9c0.7,3,2.5-0.2,3.4-0.6
									c2.7-1,2.6-2,5.9-0.7c-4.1,0.6-8.9,10.2-12,13.1c-1.3,1.2-12.2,8.6-4.4,8.3c2.3-0.1,4.3-3.8,6.6-4.1c2.7-0.3,4,2.4,7.9,1.2
									c3.8-1.2,6.5-5.4,9.8-7.4c3.8-2.3,8.2-3.5,12.1-5.6c3.3-1.7,21.1-6.5,18.5-11.5c1.3,1.1,5.4,5.5,7.3,5
									C2175.9,1642.8,2178.2,1641,2175.8,1639.5z M2124.1,1610.6c-0.4-0.5-0.8-1-1.2-1.4c2.5,1.6,4.6,3.5,6.3,6
									C2127.5,1613.9,2125.7,1612.3,2124.1,1610.6z M2134.8,1627.2c1.6-1.6,1.1,2.2,1,3.2C2135.6,1631.5,2132.7,1629.4,2134.8,1627.2z
									M2142,1648.1c-4.5,3.5-7.8-3.5-12.5-4c3.1-1.3-3.8-9.4,4.7-5.4c5,2.4,1.2,1.6,3.2,5.7C2138.2,1645.9,2143.3,1644.7,2142,1648.1z
									M2153,1638.8c-1.4,2.9-6.4,5.9-7.1,0.9c-0.5-3.6,3.2-3.5,4.5-6c-1.1,2.8,2.8,2.5,3.2,3.6
									C2153.5,1637.8,2153.3,1638.3,2153,1638.8z M2154.6,1629.6c-1.2,1.2-3.2,2.3-4,3.9c0.6-2-3-2.2-4.1-2.9c-3.4-2.4-4.2-5.6-3.6-9.5
									c2.1,1.5,3.3,7.2,5.1,8C2150.2,1629.9,2152.5,1625.4,2154.6,1629.6z M2161.2,1632.9c1-1.4,3.8,3,4.9,4
									C2164.3,1635.7,2162.9,1634.2,2161.2,1632.9z"
						/>
						<path
							d="M2182,1532.1c-1.5-6.2-8.7-4.7-13.4-6c-2.9-0.8-5.8-1.7-8.2-3.4c-1.5-1.1-3.7-4.9-5.5-5.2c-4.3-0.8-6.5,5.1-9.1,7.8
									c-1.6,1.6-12.6,8.2-9.9,10.7c1.3,1.1,8.2-2.3,10.3-2.2c2.6,0.1,5,1.4,7.7,1.5c2.2,0,4.3-0.4,6.5-0.1c4.6,0.4,8.8,2.8,12.9,0
									c1.6-1.1,1.4-2.9,4.4-3C2177.8,1532,2183,1536.2,2182,1532.1z M2164,1529c-1.3,0.8-5.2-0.8-5-2.4
									C2160.6,1527.6,2162.2,1528.4,2164,1529z"
						/>
						<path
							d="M2141.8,1542.6c-3.2-2-11.1-16-14.4-15c-0.3-1.3-0.2-2.5,0.5-3.5c1.4,1.2,7,5.8,8.6,2.8c1.3-2.5-3.8-4.4-5.2-5.6
									c-2-1.6-4.2-6.1-6.6-6.8c-3.7-1.1-6,3.3-8.1,5.7c-2,2.3-13.5,8.7-9.8,11.2c0.8,0.6,7.5-0.8,8.4-0.9c4-0.8,5.9-2.9,9.8-0.1
									c-2.4,3-4.8,6.1-7.5,8.9c-1.7,1.9-8,5.6-8.7,7.7c-2.2,5.9,3.7,2.2,6.8,0.9c5.8-2.6,10.6-1.8,16.6-1.8
									C2132.8,1545.9,2147.6,1546.4,2141.8,1542.6z"
						/>
						<path
							d="M2217.5,1474.4c-2.6-0.6-5.6-1.2-7.7-2.8c-1.2-0.9-2.2-3.7-3.6-4c-3-0.6-7.4,7.4-10.4,9.1c1-2.5-4.8-4.6-6.2-5.6
									c-2.5-1.7-4.4-5.2-6.9-6.6c-8.3-4.6-9.1,11.3-14.9,12.5c-2.9,0.6-6-5.8-9.2-5.7c-3,0.1-4.5,5.4-7.4,4.2c-3-1.3-3-7.6-6.4-8.2
									c-3.3-0.6-7.2,6.7-8.5,9.1c-6.2-9.4-7.1,2.5-9.6,8.5c-3-3.2-4-1.9-5.8,1.2c-1.2,2-3.8,5.5-4.4,7.7c-0.9,3.1,0.5,3.3,0,5.8
									c-0.6,3.2-4.6,7.6-6.4,10.1c-0.9,1.3-5.2,5.5-1.9,6.6c2.5,0.9,4.3-3.6,5.7-5.1c2.1-2.1,4.1-3.3,7.1-3.9c3.2-0.6,6.4,1.7,9,1.3
									c-7.3,6-3.4,6.6,3.4,7.7c1.6,0.2,1.6-0.8,3.8-0.1c1.3,0.4,2.3,2,3.5,2.5c1.8,0.7,5.9,1.4,7.7,0.8c7.8-2.5-3.5-7.7-4.6-11.4
									c-0.7-2.2,0.6-4.4,2.7-4.4c3.1-0.1,5.1,5.1,6.5,6.9c1.2,1.6,14.3,12,13.9,5.8c-0.2-2-3.3-2.5-4.6-3.5c-2.6-1.9-4.6-4.5-6.6-7.1
									c-2.2-2.9-7.5-15.1-11.1-14.4c-2.5,0.5-3.7,6-5.1,7.8c-1.7,2.2-3.7,4.2-6,6.3c-1.7-4.2-7.9-4.7-7.5-10.2c0.3-4.1,1-3.2,3.6-2.6
									c0.9,0.2,8.6,7.5,8.1,1.6c-0.1-0.9-6.6-6.3-1.5-7.1c1.3-0.2,2.5,2.6,3.7,2.9c2.1,0.4,2-0.5,3.8-1.1c2.9-0.9,3.9-1.5,6.8-3.4
									c3.2-2.2,0.6-1.2,3.2-1.6c1.8-0.3,2.2,1.8,4.6,0.8c4.3-1.7,1.2-4-1.6-5.6c3.7-2.7,5-0.6,8.2,1.2c-7.8,8.7,12.3,1.1,14.1,1.4
									c1.9,0.4,4.2,2.7,7,3.1c1,0.1,11.1-0.6,6.8-3.1c4.2-1.8,8.5,3.2,11.9,1.5c2.7-1.3,0-6.4,1.9-9.6c1.7,1.5,7.5,4.9,10,4.3
									C2218.1,1477.9,2220.7,1475.2,2217.5,1474.4z M2183.2,1472.4c0-0.4,0.1-0.7,0.1-1.1c1.3,1.4,2.7,2.6,4.4,3.5
									C2185.1,1478.3,2183.5,1475.8,2183.2,1472.4z"
						/>
						<path
							d="M2240.3,1490.4c-6.9-1.8-12.5-5.5-17.4-10.5c-2.9-2.9-2.8-4.3-6.4-2.3c-2.7,1.5-5.9,6.1-8.2,8.3c-3.4,3.3-5.6,6.2,0.9,6.8
									c2.6,0.3,8,1.3,10.3-0.1c2.7-1.7,2-5,2.8-7.7c3.8,3.6,8.5,6.7,13.4,8.2c1.5,0.5,4,2.1,5.3,0.7
									C2242,1492.8,2242,1490.9,2240.3,1490.4z"
						/>
						<path
							d="M2379.8,1546.3c-2.9-2.7-6.2-5-8.9-7.9c-1.3-1.4-3.2-5.4-5.1-5.7c-4-0.6-7.8,7.9-10.4,10.4c-2.3,2.3-10.6,5.5-11.4,8
									c-1.5,4.8,4.6,1.6,6.3,1.8c1.1,0.1,0.7,2.1,2.2,2.3c1.1,0.1,2.6-1.5,4.1-1.6c3.6-0.2,9.1,3.2,12.8-0.2c2.4-2.2-0.3-7.2,4-6.7
									c8.9,1-2.7,1.3,3.4,2.4C2378.3,1549.4,2382.5,1548.8,2379.8,1546.3z"
						/>
						<path
							d="M2412.1,1556.9c-2.5-1.4-4.6-3.8-7.3-4.9c-3.8-1.4-7.6-0.3-11.2-2.3c-2.6-1.5-3.4-5.2-5.5-6.4c-3-1.8-3.8,1.5-5.7,3.7
									c-4,4.9-9.9,9-16,11.1c-2.2,0.8-7.4,1-5,4.4c1.6,2.4,7.8,0.7,10,0.2c-3.2,4.8-7.7,8.6-12.7,11.3c-1.6,0.9-6.5,2-3.7,4.6
									c2,1.8,10.3-2.5,12.5-3.5c4.9-2.2,9.7-5.2,12.9-9.6c2.9,3,16,10.9,20.1,8.2c5.1-3.4-10.9-6.9-12.9-8c2-5.2,10.3,1.9,5.4-5.8
									c-0.8-1.3-5-5.4-3.1-6.7c1.1-0.7,12.2,2.5,13.6,3.1c2,0.8,4.7,4.1,6.6,4.1C2411.6,1560.3,2415.2,1558.6,2412.1,1556.9z"
						/>
						<path
							d="M2430.5,1580.8c-4.5-0.3-8.3-3.1-12.7-4.1c-4.3-0.9-8.6,0.2-12.9-0.1c-6.4-0.4-15.2-5.1-21.5-3.2c-3,0.9-4,3.6-6.4,5.4
									c-3.5,2.7-2.3,0.6-6.1,1.1c-3.7,0.6-6.7,3.5-9.8,5.4c-3,1.9-6.6,3.3-9.4,5.5c-0.3-5-5.3-17.7-7.9-7.2c-0.5,2.2-3,8.7-2.6,10.5
									c1,5.5,4.5-1,5.7-2.4c1.6,2.9,0.3,4.5,3.7,4.7c0.8,0,1.6-1.6,2.7-1.7c1.4-0.1,2.4,1,3.6,1.2c3.9,0.7,8.2,0.6,12.3,0.6
									c4.6,0,5.7-0.6,5.7-4.7c0-1.1-2.6-5.4,0.6-6.1c1.2-0.3,5.9,4.4,7.2,4.8c2,0.7,4.9,0.7,6.2-0.4c3.1-2.6-0.6-1.8,0.1-4.2
									c0.3-1,4.8,0.4,5.5-1.4c0.6-1.8-3.3-3.9-2.8-4.9c0,0,21.1,0.6,21.7,0.7c5.4,0.1,9.3,3.5,14.4,4c1.3,0.1,4.1,1.3,4.6-0.8
									C2432.6,1582.2,2431.9,1580.9,2430.5,1580.8z"
						/>
						<path
							d="M2548.3,1562.6c-5-1.7-9.3-5.7-13.8-8.5c-3.6-2.2-7.2-6.3-10.8-8.1c-7-3.4-14.5,8.1-21.6,10.4c-9,3-18.2-0.3-27.3,2.9
									c-1.5,0.5-12,3.9-6.9,6.7c1.9,1,9.4-2.4,11.4-2.7c4.8-0.7,9.7-0.7,14.6-1.3c3.9-0.5,8.1-2.1,11.8-2.3c5.4-0.4,10.9,0.8,16.2,0.9
									c1.2,0,2,0.6,3.3,0.4c1.7-0.3,2.9-2.5,4.5-2.7c2.1-0.2,4.1,1,6,2c2.6,1.4,6,4.2,9,5.2c1,0.3,3.7,2.1,4.6,0.2
									C2549.9,1564.5,2549.7,1563,2548.3,1562.6z"
						/>
						<path
							d="M2588.2,1526.5c-4.8,0-9.6-2-12.4-6c-0.7-1,0.7-2.1-0.3-3.2c-0.9-1-2.9-0.8-4.1-1.7c-2.9-2.3-3.8-5.6-7.1-7.5
									c-3.6-2.1-7.2-1.4-10.2-5.3c-0.4-0.5-4.5-7-4.4-7.4c0.8-4.4,6.4,3.3,7,3.9c1.5,1.5,6.5,7,8.8,4.6c2.3-2.4-6.3-8.3-8.1-10.1
									c-2.8-2.8-6.6-8.5-10.8-7.5c-2.3,0.6-4.1,5-6.2,6c-3.8,1.9-1.7,1.3-5.5-1.4c-4.2-2.9-5.7-1-10-0.1c-1.7,0.4-2.5-1.2-4.9,0
									c-1.8,0.9-3.1,3.5-4.2,5c-2.3,3.3-5.4,11.5-9.5,13c-3.7,1.4-10.2-3-12.5-5.5c-3.3-3.7-3.2-8.1-8.2-4.4c-4.4,3.2-9.5,8.8-13,12.9
									c-4.2-3.2-9-18.9-15.8-15.7c-3.6,1.7-1.4,5.8-6.1,3.3c-3.6-1.9-5.8-7.6-8.4-0.8c-4.2-4.5-6.7-3.4-9.6,1.6c-2.4,4-6.3,9.7-7.6,14
									c-5.4-4.3-5.8-3.8-11.6-1.7c-1.1,2.3-2.6,2.3-4.3,0c-0.9-0.6-1.8-1.1-2.7-1.7c-3.8-2.2-2.6-2.2-5.9-0.8c-3.9,1.7-2.5,2.7-6.6,1.4
									c-2.4-0.7-3.4-3.6-6.2-3.4c-4.3,0.3-7.2,6.7-10.6,9c-4-5.4-10.1-20.5-17.5-11.4c-7.1-7.9-10.2,2.5-12.5,8.8
									c-2.8-2.8-4-1.9-7.1-2.8c-0.5,0.2-0.9,0.4-1.2,0.8c-1-0.4-1.8-1.1-2.4-2c-3-1.4-2-3.8-5.4-1.3c-1.1,0.8-2.1,5.2-3,6.5
									c-0.9,1.4-3.3,5-5.2,5.1c-1.8,0.1-3.2-3.6-5.7-3.4c-2.7,0.3-2.6,4.9-5,4.8c-1.6-0.1-4.5-2.9-5.9-3.9c5.1-1.7-6-8.4-8.1-8.3
									c-3.7,0.2-5.4,4.8-7.5,7.2c0.9-3.2-7.1-6.3-9.2-8.1c-4-3.4-6.2-6.9-11.4-3c0.5-2.3-15.5-10.6-17.9-9.7c-3.4,1.2-5.2,6.6-9.7,6.9
									c-2.3,0.2-1.4-0.5-3.1-1.6c-1.2-0.8-3-2.4-4.8-3.5c-2.2-1.2-7.3-5.9-9.7-5.9c-2.3,0-11.5,6.3-12.6,8.3c-2.6,4.6,3.5,4,6.5,3.3
									c2.2-0.5,4.1-2,6.2-2.7c3.6-1.3,2.8-1.6,6.3,0c-6.2,5.4-12.5,10-18.4,15.7c-2.7,2.7-4.6,2.7-7.9,4.1c-2.8,1.2-5,3.3-8,4.3
									c-3,1.1-17.9,3.9-10.1,7.3c3,1.3,4.7-0.7,7.3-1.6c1.8-0.6,2.6-0.9,4.1-1c1.5-0.1,2.4,1.6,4.5,0.4c3.8-2.1,2-6.4,6.7-3.6
									c2.2,1.3,2.9,3.9,6.2,3.1c0.3-0.1,6.6-3.1,6.7-3.3c1.4-3.2-6.3-5.2,1-8.1c7.6-3,14.9,5,21.2,7.5c-1.8,1-16.4,5.3-9.8,7.9
									c2.2,0.8,6.5-1.2,9.4-0.4c3.8,1.1,7,4.7,10.6,5.5c8.6,2,2.4-6,4.1-10c2.1-4.8,8.8,2.9,12.3,4.4c-2.3,1.2-10.1,5.6-3.1,6
									c1.8,0.1,4.5-2.7,6.4-3c2.6-0.4,3.1,0.9,5.2,1.6c2.4,0.8,4.3,0.2,6.3,0.3c2,0.1,4.3,0.8,6.3,0.9c3,0.1,5.8-0.6,6.5-2.9
									c0-0.1-1.5-5-1.4-5.7c0-0.5,1.1-3.3,1.2-4.1c0.5-3.6-0.5-6.5,4.9-5.8c2.7,0.4,4.9,2.6,7.2,3.8c-2,1.8-9.3,5.5-10,8
									c-1.2,4.1,2.8,3.2,4.9,4c0.6,0.2,2.4,1.5,2.7,1.5c0.6,0.1,3.8-0.6,3.9-0.6c2,0.3,3,2.1,5.1,2.3c3.7,0.5,6.7-2.8,6.4-6.3
									c-0.2-1.9-4.4-5.2-3.5-6.8c1.5-2.7,12.9,3.9,14.8,4.5c-2.1,1.9-7.1,4.9-4.8,7.9c-0.1-0.1,4.4,0.8,4.9,0.9c1.8,0.4,3.5,1.4,5.3,1.6
									c0.8,0.1,6.8-1.1,7.1-0.9c7.5,6,9.2-11,15.1-12.9c4.5-1.4,9.5,7.3,10.2,0.5c0.7-6.7-7.7-6.3-8.9-11.6c1.9,2.1,5.9,5.3,6,0.6
									c0-1.7-10.5-8.7-6.3-10.5c2.9-1.2,10.3,12.6,12.3,14.8c7,7.9,15.7,14.9,24.7,20.5c4.4,2.7,9,5,13.7,6.9c3.2,1.3,10.8,5.3,14.4,4.4
									c9.6-2.2-11-6.8-11.3-7.7c-1.5-4.9,11.9-2.3,14.1-2c1.1,0.1,2.1,1.6,3.1,1.7c1.5,0.1,2.6-1.3,3.8-1.3c2.2,0,6.2,3.8,8.4,1.9
									c2.3-2.1-2.8-4.8-3.3-7.1c-0.4-1.8,1.6-2.8,0.9-4.7c-0.2-0.7-3.9-0.8-3.7-1c-0.1,0.1,4-2,3.7-2c3.1-0.3,6,3,8.7,4.8
									c2.7,1.8,4.4,3.6,7,2.6c0.7-0.3,4.2-3.9,4.5-4.7c1.1-3.4-1.7-3.3-1.7-5.6c-0.1-3.6,1-1.5,3.6-0.8c2.2,0.6,4.8,3.9,7.2,2.6
									c3.2-1.9-4.2-4.3-5-4.7c-2.1-1.1-7-5.7-9.1-5.9c-3.1-0.2-1.1,0.2-4,2.3c-1.4,1-6.1,6.7-7.9,6.6c-2.2-0.2-3.2-4.9-5.6-4.9
									c-4,0.1-10.5,8.7-14,10.7c-5.4,3.1-12,3.3-13.7,10.5c-4.4-1.9-8.7-4.2-12.8-6.8c-1.9-1.2-11.3-6.1-11.8-8.5
									c-1.3-6.6,6.8,1.6,7.8,2.1c3.5,1.7,18.3,5,13.6-2c-1.6-2.4-5.5-5.9-7.6-8.1c-3.6-3.8-3.3-0.9-8.3-2.4c2.3-2.2,2.8-1.5,5.5-2.4
									c1.2-0.4,0.2-1.9,2-1.9c3.9-0.2,7.8,8.5,10.1,10.8c3.5,3.5,11,9.3,16.5,5.9c5.4-3.2-1.8-5.8-5.3-8.5c-1.8-1.4-7.7-4.3-6.8-7
									c1.3-4,11.1,5.4,13.2,6.2c4.8,1.9,1.7,0.5,4.8,0.1c-0.3-1.4,0.4-2.1,2-2.2c1,0.6,2,1,3.1,1.2c2.9-2.3-0.7-7.2,0.3-10
									c1.5-1.6,2.5-1.6,3-0.2c0.3,0.9,0.9,1.3,1.9,1.5c2.3-1.3,0.3-2,1.8-3.2c1.6-1.3,1.9-4.9,4.8-1.8c4.1,4.4-10.1,14.4,1.5,11.5
									c4.1-1,4.4-1,6.3-4.7c1.4-2.8,1.3-4.5,4.4-3.5c0.8,0.3,10.9,10.4,11.5,3.9c3.9,4.1,4.3,0.1,8.8,1.6c0.8,0.3,1.8,2.2,3.2,2.7
									c1.7,0.5,3.7-0.6,5.7,0c-4.2,2.8-8.3,5.7-12.5,8.4c-1.6,1-12.1,5.7-6.9,8c3.2,1.4,9.5-5.7,12.9-6.4c3.1-0.6,7.4,0.8,10.4,1.1
									c2.9,0.2,6-0.3,8.6-0.3c1.8,0,2.8,0.9,4.4,1.1c1.8,0.2,1.9-0.4,3.9-0.5c2.3-0.1,3.1,0,5.4,0.4c0.7,0.1,0.7,1.3,2,1.4
									c2.1,0.1,2.9-2.2,4.5-2.3c1.6-0.1,3.1,2.1,4.9,2c-1.6,2.3-3,4.8-5,6.9c-0.9,0.9-5.8,3.9-6.2,5c-1.8,6.3,3.8,1,5.4,0.5
									c1.7-0.5,10.6,4.7,13,6.4c2.7,1.9,3.5,4.6,6.2,6.2c0.6,0.4,4.2,0.7,5,1c1.6,0.5,2.3,1.6,4.2,1.8c1,0.1,3.7,0.2,4.6-0.8
									c2.2-2.4,0.2-2.2-0.8-3.9c-2.2-3.8-7.1-3.8-11.3-5.3c-4.7-1.7-9.5-4-13.8-6.6c4.2-3.3,3.7,0.4,7.3,1.6c1.1,0.4,1.8-0.6,3-0.3
									c1.4,0.4,2.7,1.9,4,2.2c7.6,1.6-0.6-11.6-2.6-15.1c3.3,2.2,6.7,4.4,10.2,6.3c2.5,1.4,10.4,6.8,13.5,6.2c8.3-1.5-8.6-8-10.3-8.9
									c2.7,0.4,4.3,2.9,7.1,2.8c2.8-0.1,3.5-3.5,6.5-2.9c1.6,0.3,0.4,2.1,1.4,2.6c2.2,1.2,3,1,5.7,0.3c1-0.1,2-0.2,2.9-0.4
									c0.3-1.4,0.9-1.5,1.9-0.4c1.5-0.7,2.9,0.5,3-2.3c0.1-1.4-5.9-4.2-7-5.1c-2.5-2-4.5-4.4-6.5-6.9c4.2,2.3,6.8,6.7,12.4,6.6
									c1.3,0,4.1,0.8,4.4-1.3C2590.3,1527.8,2589.7,1526.5,2588.2,1526.5z M2248.3,1524.3c0.2-2.2-11.8-8.1-13.5-9.4
									c4.9,0.5,1.6-4.4,1.8-6.2c1,0.8,4.5,0.2,5.8,0.6c1.4,0.5,2.4,2.2,4.1,2.7c1.9,0.6,9.9,2,11.9,1.2c5.3-2,0.4-2.8,0.9-6.2
									c0.8-5.3,6.3,2.2,9.4,1C2262.1,1513.7,2255.6,1519.7,2248.3,1524.3z M2280.9,1527.5c-2.3,2.1-4.9,3.9-7.7,5.4
									c0.7-2.8-5-4.4-0.9-5.8c-1.5,0.5,6.2,1.1,4.1,1C2276.3,1528.2,2281.6,1526.8,2280.9,1527.5z M2284.6,1523.9
									c-3.2-2.3-8.8-8.9-8.3-12.8c3.7,1.8,7.9,10.4,12.8,8.1C2287.6,1520.8,2286.1,1522.3,2284.6,1523.9z M2344.9,1520.3
									c-1.8,3.5-4.5,6.4-7.3,9c-0.6-1.9-0.7-2.9-0.3-5c0.7-1,1.2-2,1.7-3.1c0.1-1,0-2-0.4-3C2340.2,1515.6,2347,1516.2,2344.9,1520.3z
									M2391.8,1517.9c-0.8-0.8-3.1-3.2-3-4.6c1.7,0.8,2.8,2.4,4.6,3.1C2392.8,1516.9,2392.3,1517.4,2391.8,1517.9z M2493.3,1510.2
									c-0.6,0.4-1.4,1.3-1.7,1.6c-0.7-1.5-0.6-2.9,0.3-4.3C2493,1508.7,2494.2,1508.5,2493.3,1510.2z M2567.8,1517
									c0.8,1.3,1.8,2.3,3.1,3.1C2569.2,1521.2,2567.7,1518.6,2567.8,1517z M2497.7,1517.3c-2.3-1.7-3.7-2.2-2.4-4.4
									c1.8-2.9,5-0.6,7.5-0.1C2501.3,1514.6,2499.6,1516,2497.7,1517.3z M2510.8,1524.4c-2.7,0.5-8.4,2-11,0.5c0.1,0.1,9.9-8.9,12.8-1.1
									C2512.4,1524.6,2511.7,1524.8,2510.8,1524.4z M2522.4,1507.1c-2,1.5-3.9,3.2-5.9,4.7c-2.9,2-3.3,3.3-6.2,0.6
									c3.6-1.3,6-3.3,8.7-6.1c1.5-1.5,2.4-2.2,3.7-4.2c1.1-1.7,1.1-3.6,2.8-5.2c2.7,2.8,12.1,10.3,11.8,2c3.8,2.5,5.7,3.9,8.2,7.9
									c1.3,2,0.5,3.1,3.2,4.1c3.2,1.2,6-1.7,9.1-1.6c-2.1,1.1-13.2,6.9-13.4,9C2537.9,1515.3,2529.6,1501.5,2522.4,1507.1z
									M2543.7,1526.7c-4.2-3.4-7.3-0.4-10,3.3c-0.9-2.5-2.9-5.5-3.9-8.4c-1.7-5-1.1-5,0-9.7c2.9,3.1,6.1,6.1,9.7,8.3
									c1.3,0.8,10.5,5.7,8.7,0.4c3.9-0.2,6,1.7,9.5,2.5c2.9,0.6,5.3-0.4,8.5,0c-3.7,2.4-6.6,5-10.7,6.7
									C2550.2,1532,2548.1,1530.3,2543.7,1526.7z"
						/>
						<path
							d="M2698.3,1492.5c-7.4-3.4-9.3-13.1-16.2-17.5c-4.8-3-5.1-1.8-7.6,3.2c-2,4-5.8,7.8-7.3,12c-7.2-4.7-12.4-11.3-16,0.6
									c-5.3-3.2-8.8-11.7-15.1-5.5c-1.7,1.6-2.7,5.7-5.5,6c-2.2,0.3-7.9-5.5-9.3-6.8c-5.5-5.2-10.1-6-15.7,0.1c-2.5,2.7-2.8,4-6.6,4.9
									c-4.3,1-6.1,0.3-8.1,5.3c-3.5-2.1-7.9-9.5-11.5-6.2c-1.7,1.5-2.1,6-3.7,8c-1.6,2-3.8,3.3-6.1,4.5c-1.5,0.8-7.1,1.9-5.6,4.7
									c1.2,2.3,3.3-0.1,4.8,0.1c2.8,0.3,3.5,2,5.7,2.8c3.3,1.2,1.2,1.5,4.4-0.9c1.2-0.9,1.9,0.3,2.6-1.7c1.1-3-1.2-5.7,2.2-8.4
									c0.8-0.7,4.6-2.9,5.5,0.7c0.5,1.9-5,7.2-5.9,8.7c-2.6,4-2.1,5.9,2.3,6.8c1.1,0.2,3.1-0.5,4.7-0.1c2.3,0.5,4.1,2,6.6,2.4
									c-1.1,1.7-4.5,7.7-2.4,9.7c1.1,1,6.8-1.6,8.2-2.4c1.4-0.7,7.8-4.5,7.8-5.9c0.1-2.7-7.2-2-4.3-5.4c1.2-0.3,19.8,12.1,14.8,3.3
									c-1-1.8-10.2-6.4-12.5-7.8c-5-3.2-6.4-0.4-9.4,4.8c-1.2-3.6,0.9-9.3,4.2-11.7c0.7-0.5,8.4-3.3,8.9-1.9c0.4,1.3-2.9,2.9,0.1,4.3
									c0.3,0.1,6.1-1.3,6.1-1.3c3.5-2.7-4-9.3,0.2-14.6c2.5,2.7,5.4,5.2,8.1,7.7c1.3,1.2,2.1,3,4,3.1c1.3,0.1,0.7-1.4,1.6-1.5
									c3.4-0.2,5.1-1,7.8,1.6c1.6,1.5,4.5,8.8,8,6.3c2.6-1.9-2.9-4.9,0.6-8c2.6-2.3,1.7-1.2,3.8-0.4c3.3,1.4,4.5,5.8,8.9,5.3
									c6-0.7,1.9-5.5,1.7-9.4c1.8,1.3,5.2,4.2,7.5,4.1c-0.2,3.6-5.1,8.9-7.8,11.2c-2.3,2-13.2,6.4-13.4,9.2c-0.3,5,5.6,0.1,7,0
									c1.7-0.1,3.1,1.4,4.7,1.4c2.1,0,2.9-1.3,4.6-1.6c0.9-0.2,1.3-1.6,2.4-1c1.8,1-0.3,3.8,0.4,4.7c2.2,3.2,2.6,0.3,6,0.3
									c1.3,0,0.1,2.5,2.4,1.9c0.9-0.2,2.7-3.3,3-4c1.4-3.5-2-7.2,0.3-10.3c2.9,1.4,7.9,6,10.8,6.4c5.6,0.8,2.4-3.2-0.4-5.2
									c-3.4-2.4-17.2-6.6-13.9-13.3c0.8-1.6,4.7-2.1,6-4.6c1.1-2-0.2-5.3,3.7-4.2c1.6,0.4,5,5.8,7.2,7c1.1,0.6,2.7,2.4,4.2,1.7
									C2699.2,1495.4,2700.1,1493.3,2698.3,1492.5z"
						/>
						<path
							d="M2932.2,1476.8c-4.1,0.9-8.7-2-12.7-2.3c-4.9-0.4-8.8-0.6-13.6-1.8c-4.1-1-8.5-1.2-12.5-2.6c-2.9-1-5.7-3.8-8.7-4.1
									c-4.7-0.5-4.3,2.4-7.6,4.5c-2.9,1.8-5.1-0.6-8.1-2.9c-2.9-2.3-4.8-5.2-8.7-4.4c-3.7,0.7-5.4,6.3-8.9,6.9c-5.5,1-7.6-12.6-9.5-16.4
									c-1.3-2.5-3-5.9-5.9-6.6c-3.3-0.7-5.3,2.6-8.4,2.3c-3.7-0.4-4.7-4.2-7.6,0.5c-1.7,2.8,0.6,8-2.6,9.9c-2.5,1.5-4.9-1-8.1,1.5
									c-3.3,2.6-3.7,7.7-6.7,10.3c0.3-0.2-4.9-5-5.9-5c-3.1,0.2-3.9,4.4-4.5,6.8c-2.4-3.4-6.7-4.8-8.7-0.1c-1.1-5.2-2.1-10.2-4.9-14.8
									c-3.2-5.4-4.3-4.6-6.5-0.1c-4.1,8.3-5.9,16.9-11.8,24.5c-3.5-3.2-7.1-7.2-11.9-3.8c-3.2,2.3-3.6,6.8-6.4,9.3
									c-3.3,3-3.9,1.5-7.4-0.8c-5.9-3.8-9.2-4.3-14.2,1.7c-3.2-3.5-5.4-7.5-10.4-4.5c-3.5,2-5.8,7.8-8.2,10.9c-1.7,2.3-3.5,4.6-5.6,6.6
									c-1.4,1.3-5.3,3-6,4.3c-2.6,5.2,2.9,2.4,5.5,2.5c2.3,0.1,3.3,1.5,5.2,2c2.8,0.6,4,0.2,7.1-0.3c0.8-0.2,6-0.3,6.7-1.2
									c1.4-2.1-1.3-1.9-1.6-2.9c-0.4-1.4-1.4-1.5-1.3-3.4c0.1-1.7,1.6-2.8,2.1-4.3c0.3-0.8,1.1-5.6,1.5-5.7c1.4-0.5,2.4,1.5,3.4,2
									c2.4,1.2,7,4.6,9.8,5c8,1.2,0.5-5.4,1.4-8.5c0.5-1.6,10.7,4.5,11.6,5c4.3,2.3,8.3,5.2,12.3,3.5c0.8-0.4,2.5,1.3,2.7-1.1
									c0.1-1.6-2.8-1.9-2.7-3.7c0.1-2.6,4.3-4.9,6.2-2.5c-3.1,3.4-0.3,2.7,1,4.5c1.1,1.4,2.4,4.2,4.2,4.7c1.1-0.4,2.1-0.9,3-1.7
									c1,0,1.7,0.5,2.1,1.5c1.5,0.6,2.4,2.3,4.2,2.7c1.6,0.4,5.8,0.6,6.1-2.1c0.3-3.3-2.6-1.2-3.7-3.3c-0.8-1.4-0.8-2.8,1.2-3.2
									c1.9-0.3,4.1,5.9,6.3,5.9c7.2,0-4-9.4-5.7-10.9c-3.6-3.1-8.3-4.9-7-9.7c0.1-0.4,2.2-3.4,2.5-3.8c1.4-2-0.7-3.2,2.4-2.2
									c2.7,0.9,6.6,11.3,9.2,2.9c3.1,3.3,6.2,11.9,10.9,8.3c-0.4,0.3,2.9-5.4,2.9-5.2c0.7-2.8-2.5-4.8-0.9-7.8
									c1.7,2.3,8.3,10.4,11.3,9.9c3.6-0.6,1.7-2.7,1.6-5.6c0-3.4,0.8-4.8,3.1-7.3c2,3.5,5,10.4,9.7,8c1.7-0.9,3.4-4.1,3.6-5.9
									c0.1-0.9-2.6-7.9-2.6-7.9c0.8-1,3.2,0.2,4.1-2.7c0.6-1.8-1.3-5.5-1.6-7.4c1.6,0.2,6.3-1.1,7.3-2.4c0.7,0.7,1,1.6,1,2.5
									c-0.4,0.7-3.4,0.3-4.2,1.5c-1.7,2.4,0.9,3.7,1.1,5.5c0.2,2-2.4,5.6-2.5,8.5c-0.2,5.3,3.9,6.4,8.8,6.1c4.4-0.2,4.2,0.3,3.1-3.5
									c-0.3-1.1-2.6-2.7-1.4-4.1c1.6-1.8,2.4,0.9,2.9,1.3c2.2,1.7,2.4,3.9,4.5,5.2c1.1-0.9,2-1,2.6-0.2c1.6,1.2,3.3,2.3,5,3.4
									c4.8,3.1,8.6,7.2,10.2,0.9c1.7-6.3,0.4-7.3,6.9-4.3c-3.2,3.4-8.2,6.3-1.6,8.1c0.9,0.3,3.7,0.3,4.5,0.5c2.8,0.5,9,1.9,12.2,0.7
									c6.4-2.4-1.7-6.4,1.9-10c2-1.9,5.5,2.4,8.1,2.9c4.3,0.8,8-0.6,12.4,0.7c5.9,1.7,10.6,2.4,16.8,2.6c1.7,0,5.4,1.4,6.7-0.5
									C2935.4,1478.5,2934,1476.4,2932.2,1476.8z M2707.6,1507.4c0.1-0.4,0.2-0.7,0.3-1.1C2708.8,1507.1,2708.7,1507.4,2707.6,1507.4z
									M2776.1,1465.5c0.1-0.8,0.2-1.6,0.3-2.4c1,2.4,1.6,5,2.2,7.5C2777.4,1469,2776.4,1467.4,2776.1,1465.5z"
						/>
						<path
							d="M2888.2,1495c-5.3-0.6-10.5-2.7-15.3-5.1c-6.1-3.1-8.6-6.3-13.6-1.2c-4.6,4.7-4.1,3.2-9,0.1c-4.1-2.6-9-1.7-13-4.3
									c-3.9-2.6-5.8-7.5-9.8-3.4c-3.8,3.9-4,9.3-10,11.6c-5.2,2-4.8-1.9-9.5-4.5c-5.5-3-8.9,1.5-13,5c-1.9,1.7-9.6,5.7-6.1,8.6
									c1.5,1.3,8.5-0.2,10.9,0.6c3.6,1.1,8.1,3,12.1,3.3c4.5,0.4,6.9-1.6,3.2-4.1c-0.2-0.1-11.1-4.8-3.9-5.4c1.2-0.1,4.3,2.8,5.5,3.4
									c2.5,1.2,5.4,2.3,8.2,2.4c1.7,0.1,5-1.9,4.9-1.9c1.1,0.1,1.8,1.1,2.7,1.4c3.7,1.1,2.7,3.2,5-1.6c1-2.2,0.9-4.7,1.6-6.9
									c1.1-3.1,1.2-3.4,5.3-2.3c5.3,1.4,8.3,6,13,8.5c4.1,2.2,9.9,3.7,14.5,3.6c1.4,0,5.1,0.4,5-1.9c-0.1-2.1-4.9-2.1-5.7-3.8
									c-1.4-2.6,4.9-1,5.3-1c3.1,0.4,6,2.2,9.3,2.3c1.4,0,3.1,1.1,4.1-0.3C2890.6,1497,2889.9,1495.2,2888.2,1495z"
						/>
						<path
							d="M2683.8,1531.2c-11.6-0.2-30.7-3.1-39.1-12.1c-3.8-4.1-5.4-10.3-11-4.6c-4,4.2-7.3,8.8-11.8,12.6c-5.9,5-6.2,4.6,0,7
									c2.4,0.9,3.8,2.8,6.3,2.3c1.9-0.3,3.4-3.3,5.7-3.6c3.1-0.5,9.7,2.8,9.3-3.9c-0.3-4.8-8.8-5.9-5.5-12.2c3.7,2,5.7,7.2,9.8,9.5
									c4.8,2.7,10.4,4.4,15.6,5.9c5.4,1.6,10.9,2.4,16.5,2.8c1.6,0.1,4.6,1,5.6-0.7C2686,1533.1,2685.5,1531.3,2683.8,1531.2z"
						/>
						<path
							d="M2659.9,1552.9c-2.7-5.5-6-4.1-10.5-6.3c-3.5-1.7-4.8-9.4-8.1-10.4c-2.6-0.8-5.2,5.1-6.8,6.6c-3.2,3-7.2,4.8-11.3,6.3
									c-2.1,0.8-8.8,1.5-9.4,3.8c-0.9,3.6,4,2.2,5.8,2.1c10.1-0.3,16.7-2,21.5-11.9c3.2,5.5,7,9.6,12.9,12c0.7,0.3,5.2,2.3,6.1,1.5
									C2661.2,1555.6,2661.9,1553.5,2659.9,1552.9z"
						/>
						<path
							d="M2673.3,1546.3c-4-1.5-8.2-2.8-11.8-5.2c-2.3-1.5-5.1-5.7-7.8-6c-2.8-0.3-13,8.5-8.5,10.4c1.5,0.6,2.4-1.9,3.4-1.9
									c1.1,0,2.3,0.8,3.5,0.7c3.7-0.4,4.3-0.8,7.5,0.4c3.1,1.2,9.1,5.9,12.6,5.4C2674,1549.9,2676.2,1547.4,2673.3,1546.3z"
						/>
						<path
							d="M2652,1579.5c-3-6.6-7.8-12.1-10.9-18.8c-3-6.5-4.1-7.8-8.6-1.1c-3.2,4.8-6.4,9.8-10.5,13.9c-1.8,1.8-13,8.3-6.7,9.7
									c1.7,0.4,3.8-2.3,5.2-2.9c3-1.3,4.8-1.2,7.7,0c3.4,1.4,9.5,8.1,10.6,1.6c0.4-2.3-2.5-9.1,4.3-6.8c2.5,0.8,3.1,5.9,5.6,6.7
									C2650,1582.2,2653.4,1582.5,2652,1579.5z"
						/>
						<path
							d="M2633.3,1594.3c-6.5-2.6-13.4-4.6-18.9-9c-4.1-3.4-8.5-9.7-13.3-3.8c-2,2.5-2.2,5.9-4.5,8.3c-1.2,1.2-2.5,2.2-3.9,3.1
									c-1.7,2-3,1.7-4-1c-2.8-1.4-3.4-3.2-6.6-1.2c-2.4,1.5-3.9,5.6-5.7,7.8c-2.7,3.3-8.7,12-12.7,11.9c-3.5-0.1-4.6-4-8.8-1.9
									c-1.9,1-3.4,4-5,5.4c-1.6,1.4-14.6,7.2-9.4,9.5c2.4,1.1,5.5-2.9,8.3-2.6c2,0.2,3.2,2.6,5.2,2.8c1.9,0.2,2.3-1.7,4.1-1.7
									c1.9,0,3.2,2.5,5.4,0.8c4.2-3.2-5.5-10,3.5-5.9c1.5,0.7,6.6,5.4,8.1,2.4c1.5-3.2-3.6-2.7-3.1-5c0.4-1.9,5.4,0,6,0.2
									c3.3,1.1,1.7,1.4,5.1,1.6c1.5,0.1,5.7-1.4,6.6-1.1c0.5,0.2,0.6,2.4,1.2,2.7c2.5,1.3,2.1,0.7,4.7,1c0.2,0,5.9,0.7,5.6,0.9
									c5.9-2.8-4.7-7.2-6.1-8.4c-2.7-2.2-6.9-9.2-6.6-13.1c4.2,6.6,11.5,10.1,18.6,12.6c4.4,1.5,20.3,11.3,24.4,8.6
									c4.8-3.1-12.1-7.9-14.3-9c-7.6-3.4-16.1-4.9-22.2-11.1c5.7-3.5,9.7-0.8,15.3,0.1c2.4,0.4,6.2,1,5.7-2.3c-0.5-3.1-5.2-3-6.7-5.1
									c-1.1-1.5-1.8-4.5,1.4-4.6c1.7-0.1,8.4,5.6,10.5,6.5c2.2,1,8.8,4.8,11.3,4.4C2634,1597.9,2636.2,1595.5,2633.3,1594.3z"
						/>
						<path
							d="M2615.3,1636.3c-3.2-1.9-6.7-2.1-10.1-3.3c-3.7-1.3-6.5-3.7-9.9-5.3c-3.3-1.5-5.6-1.9-9.2-0.8c-4.4,1.3-4.6,1.5-9.1,0.6
									c-2.2-0.4-4-0.7-6.1,0.4c-2.3,1.3-2.6,5.8-4.3,6.7c-1.5,0.8-5.3-0.7-7.7,0.7c-2.7,1.5-3.9,4.4-6.1,6.2c-3.3,2.7-6.1,3.4-9.2,7.5
									c-0.6,1.2-1.2,2.4-1.6,3.7c-1.2-0.5-2.5-0.8-3.9-0.7c-3.3,1.1-4.5,2.9-6.7,5.5c-3.1,3.5-2.5,2.9-6.6,1.8c-2.4-0.7-4.1-2-6.2-3.2
									c-7.1-4.1-9.5,3.6-13.7,8.7c-3.3,3.9-2.3,3.1-6.6,3.6c-3.7,0.4-7.6-1-11.3-0.7c-5.4,0.4-10,3-14.4,5.9c-2.2,1.4-14.3,6.8-14.9,8.7
									c-1.8,5.8,7.5,1.3,9.5,0.9c5-0.8,10.4-1.4,15.3-2.6c0.8-0.7,1.8-1.3,2.7-1.8c1.4-0.3,2.5,0.2,3.2,1.5c-1.1,0,5.8,0.4,3.7-0.3
									c2.6,0.9,5,7.2,9.1,4.5c3.8-2.5-3.7-9-4.8-12.2c5.4,0.5,10.2,2,15.2,4c3.3,1.3,9.8,4.5,13.2,2.6c6.4-3.6-3.6-12.9-0.6-13.9
									c1.6-0.6,7.9,5,10.2,5.6c3.6,0.9,7.8,0.9,11.5,1.6c2.8,0.5,6,2.1,6.1-1.7c0-1.8-7.4-4.7-8.2-7.4c-2.6-9,14.1,7.2,16.5,2.6
									c0.3-0.6-1-6.4-1.2-7.1c-0.5-2.6-3.6-5.8-3.7-8.3c2.7,2,18.4,11.4,20.7,7.5c2.6-4.3-7.7-7.7-9.3-10.6c-4.7-8.6,8.8-0.1,10.9,0.9
									c1.8,0.9,19.7,9.7,18.2,3.3c-0.7-2.8-15.9-3.5-10.6-6.3c2.1-1.1,3.1,1.2,4.4-1.4c0.3-0.6-0.9-3.8-0.5-5.1c0.2-0.7,1.9-4.3,3-4.8
									c1.3-0.6,6.2,1.1,8.5,1.8c3.6,1.1,7,2.6,10.7,3.3c0.8,0.2,2.7,1.8,3.6,1.2C2616,1639.2,2617,1637.3,2615.3,1636.3z M2587.6,1631.7
									c-1.8-0.6-1.6-1,0.6-1.2C2588.1,1630.9,2587.8,1631.3,2587.6,1631.7z"
						/>
						<path
							d="M2551.8,1690.5c-10,0.5-20.4-4.7-29.4-8.6c-9-3.9-15.9,3.5-23.9,7.3c-1.9,0.9-10.8,3-11.3,4.8c-1.1,4.3,4.3,1.5,5.9,1.7
									c3,0.3,6.2,2.1,9.4,2.4c0.7,0.1,1.1-1.3,1.6-1.2c1.6,0.4,1.9,1.3,3.3,2c1.8,0.9,8.7,4.2,9.5,0.4c0.5-2.3-6.1-6.1,1.6-5.3
									c4.5,0.5,6.8,5.7,12.2,4.9c5.9-0.8,3.6-2.6,0.4-3.9c-2.5-1-7.3-1.8-7.2-5.6c0.2-4.7,6.1-0.1,8.7,0.9c5.1,2,10.7,4.1,16.3,4.1
									c1.3,0,4.4,0.5,4.6-1.5C2553.8,1691.8,2553.3,1690.4,2551.8,1690.5z"
						/>
						<path
							d="M2290.7,1612.3c-7.8-3.5-12.5-7.4-16.3-15.2c-2-4.1-5.7-22.2-9.7-11.2c-3.2,8.6-3.7,13.5-11.7,18.7
									c-2.4,1.6-18.3,13.9-9.3,14.9c2.1,0.2,3.9-2.2,6.3-1.8c0.6,0.1,0.5,2.1,1.5,2.3c1.5,0.4,1.9-1.3,3-1.3c3.4,0,9.4,5,13.1,4.1
									c3.8-0.9,2.2-3.5,0.7-5.7c-1.7-2.5-1.9-1.3-2.2-4.4c0.8-0.7,1.3-1.5,1.5-2.5c0.4-0.8,0.1-1.4-0.8-1.8c-0.1-0.5-1-1.4-1.1-2
									c-0.7-3.1-0.1-8.1,0.9-10.9c3.1,7.1,8.6,12.9,15.2,16.9c1.5,0.9,5.1,3.5,6.9,3.4C2289.6,1615.6,2294.2,1613.8,2290.7,1612.3z"
						/>
						<path
							d="M2091.7,1718c-3.9-2.6-7.4-5.9-10.7-9.2c-2-2-4.9-6.8-7.8-7.7c-5.7-1.8-11,9.6-14.2,13c-1.3,1.4-7.2,4.9-5.4,7.1
									c1.9,2.4,5.2-1.4,7.3-2c4.5-1.2,6.3,0.1,9.5-4c2-2.5,3.2-4.2,5.6-6.3c4,4.4-18.7,13.6-10.9,16.6c1.3,0.5,7.2-1.8,9-1.9
									c8.3-0.5,4.2-2.8,9.4-7.1c1.2,1.1,5,5.2,6.8,4.8C2091.9,1721.3,2094,1719.6,2091.7,1718z"
						/>
						<path
							d="M1632.1,1950.6c-6,0.3-10-5.9-16.1-5.6c-3.7,0.1-20.1,7.7-18.5,11.9c0.9,2.3,10.1-2.1,13.1-1.4c2.5,0.6,5.8,3.9,8.1,0.8
									c1.1-1.4-0.8-7.1-2.2-6.5c3.4-1.5,8.9,3.6,12.1,4.2C1633.9,1955.1,1635.1,1950.5,1632.1,1950.6z"
						/>
						<path
							d="M1629.7,1938.8c-4.5-1.1-7.4-3.5-12.3-2.6c-4.1,0.7-8,3-12.1,4.2c-3,0.9-18.3,2.9-8.9,5.7c7.5,2.2,14.7-2.9,21.3-4.8
									c4.3-1.2,6.4,2.6,10.2,2.1C1630,1943.3,1633.3,1939.7,1629.7,1938.8z"
						/>
						<path
							d="M1641.2,1922.4c-5.9-0.1-9.8-4.9-15.3-5.6c-2.7-0.3-3.3,1.2-5.3,1.4c-1,2.1-2.2,2-3.6-0.3c-1.4-1.1-3-1.8-4.8-2.2
									c-4.8-0.4-9,4.5-12.6,7.1c-1.3,0.9-13.9,7.2-8.5,9.4c1.7,0.7,4.6-2.2,5.9-2.8c2.2-0.9,4.9-1.3,7.3-1.3c2.5,0,13.1,5.2,14,2.3
									c0.7-2-7.6-6.6-2.5-8.1c-0.1,0,12.3,8.9,9.4,2c1.2,1.5,5.5,6.3,7.7,6.3c5.4,0,1.2-3.8,0.3-5.8c1.9,0.7,7.5,2.8,9.3,1.1
									C1643.8,1924.8,1643.1,1922.4,1641.2,1922.4z"
						/>
						<path
							d="M1635,1905.3c-5.3-2.4-10.7-5-15.2-8.7c-4.6-3.8-6.9-6.9-10.3,0.4c-7.6-5.8-10.4,2.2-14.8,7.6c-5.3,6.4-7.2,8.6,1.8,7.9
									c2.6-0.2,5.4-1.6,7.8-1.7c2.9-0.1,4.5,1.9,7.4,2.4c2,0.3,5.4,0.4,5.1-2.2c-0.1-1.2-7.6-4.4-8.9-5.8c3.2-3.8,9.3,3.7,13.2,1.9
									c5.3-2.4-3.2-4.8-4.3-6.8c0.7,1.2,8.6,4.6,10.4,5.5c1.5,0.8,5,3.5,6.7,2.9C1635,1908.4,1637.7,1906.5,1635,1905.3z"
						/>
						<path
							d="M1700.5,1845.3c0.9-3.1-10.7-6.6-13.1-8c-6.9-4.3-8.6-2-15,2.1c-1.1,0.7-3.3,2.4-4.8,2.7c-4.5,1-1.6,0.7-4.5-1.3
									c-2.1-1.4-2.6-4.4-5.8-3.5c-4.9,1.4-5.7,11.6-8.8,15.2c-2.9,3.4-2.4,3.2-6.5,0.7c-1.9-1.2-5.8-4.2-8.3-3.8c-2.2,0.4-2.8,2.3-4,3.5
									c-1.5,1.4-3.2,2.1-4.6,4.2c-3,4.6-5.6,9.6-10.4,12.8c-0.8,0.5-2.4,1.5-2.7,1.6c-0.8,0.6-1.8,0-2.7,0.8c-2.4,1.9-3.3,5.6-5.2,8
									c-3.7,4.7-9.5,6.5-13.9,10.4c-1.8,1.6-4.5,3.5-0.9,4.9c1.2,0.5,5.6-2.8,6.7-3.2c1.7-0.6,4.7-2,6.3-2.1c2.2-0.1,3.2,1.5,5,1.6
									c2.5,0.2,4.2-2.4,7.2-1.2c0.6,0.3-0.8,1.8,1,2.3c1.3,0.4,1.8-0.7,2.6-0.6c4.6,0.6,7.6-0.6,12.5,0.6c6.2,1.5,6.4-0.2,4-4.9
									c-1.6-3.2-3.5-5.2,0.4-6.3c4.8-1.4,10.6,5.8,15.5,7c3.7,0.9,5.8-0.6,9.1-0.5c1.2,0,2.4,1.8,3.7,1.3c7.2-2.5-3.2-9.6-2.1-13.8
									c2-7.8,10.5,5.2,13.7,0.5c1.8-2.7-11.8-8.4-14.1-10.3c6.9,1.9,7.6-1.1,5.1-6.9c-0.8-1.8-7.5-11.3,0.3-8.2c1.4,0.5,2.4,3,3.5,4
									c2.3,2,6.2,5.1,9.3,5.7c1.8,0.4,3.4,1.4,4.8-0.4c2-2.6-3.1-3.2-4-3.7c3.4-1.5,3,0.5,5.8,1.3c2.9,0.8,3.3-0.3,6.2-0.8
									c1.9-0.4,5.4,0.8,4.8-2.3c-0.4-2.4-6.9-4.2-8.5-5.1c-2.8-1.6-4.3-1.8-2.8-4.4c1.6-2.7,2.7-1.7,4.9-0.9
									C1690.6,1844.4,1702.7,1851.3,1700.5,1845.3z M1616.9,1887.3c-1.9-1.3-2.9-4.1-1.3-6.1c1.9-2.2,2.3-0.7,5.3,0.5
									C1619.6,1883.6,1618.3,1885.4,1616.9,1887.3z M1641.7,1878.9c-1.4,1.1-10.2-5.9-13.7-5c-2.3,0.6-3.4,4.2-4.9,4.5
									c-1.4,0.3-5.7-2.8-6.8-3.6c5.2-3.6,14.1-3.3,19.8-3.3c3.5,0,11.5,3.3,11.4-1.7c0-2.5-3.8-5.5-5.4-7.2c-1.4-1.4-8.2-5.4-8.2-7.2
									c0.1-3.3,7.1,2.1,7.8,2.5c4,2.4,7.9,5,12.1,7C1650.3,1869.9,1646.7,1875.1,1641.7,1878.9z"
						/>
						<path
							d="M1639.2,1845.8c-5.8,0.9-11.6-0.6-16.8-3c-4.4-2-8.5-7.1-12.7-8.7c-4.5-1.7-6.4,2.6-9.1,5.6c-4.2,4.6-9.7,7.6-14,11.9
									c-1.2,1.2-3.5,2-1.4,3.8c2.2,1.9,5.2-1.9,7.1-2.7c3.2-1.3,1.6-1.5,5.5-1.1c2.4,0.2,4.6,1.5,7,1.6c4.2,0.2,7.9-2,12-2.4
									c1.3-0.1,2.6,1.6,3.7,0c1-1.4-1.9-4.5-2.6-5.4c5.6,3,11.9,4.8,18.2,4.7c1.6,0,4.9,0.5,5.5-1.5
									C1642,1847.1,1640.9,1845.6,1639.2,1845.8z"
						/>
						<path
							d="M1713.8,1829.6c-5.3-2.2-10.9-4.4-15.4-8.1c-1.6-1.3-4.2-5.2-6.4-5.1c-2.1,0.1-1.7,1.9-2.9,3.2c-2.5,2.7-6.8,5.3-8.7,8.4
									c-2.9,4.8-0.1,3.5,2.6,3.4c2.5-0.1,10.8,2.4,12.3,1.4c4.3-2.9-4.2-7,1.2-7.5c2.4-0.2,8.4,4.7,10.9,5.9c1.3,0.6,4.9,3,6.5,2.3
									C1715.6,1832.7,1715.4,1830.2,1713.8,1829.6z"
						/>
						<path
							d="M1751.9,1811.8c-7.9-2.6-16.2-3.9-24.2-6.3c-3.6-1.1-7.5-1.9-10.7-3.8c-2.5-1.5-5.1-5.7-7.9-4.7c-2,0.7-4.8,6-6.5,7.7
									c-3.7,3.5-4.7,5.3-7.8,0.8c-4.1-6-7-8-12.7-1.9c-3.5-5.1-6.7-5.9-10.3-0.5c-4.1,6-7.6,12.5-12.6,17.9c-3.9-3.4-5.1-4-8.8-0.4
									c-1.9,1.8-11,9.4-11.5,11.9c-1,5,2.3,2.1,4.9,1.6c0.7-0.1,1.6,1.3,2.4,1.3c1.4,0,3.3-0.5,4.6-0.8c1.5-0.3,2.5-0.9,4.1-0.9
									c1.7,0,2.6,2.2,4.7,1.3c4.2-1.9-2.5-6.4,0.6-7.5c2.4-0.9,6.6,5.5,8.9,5.7c1.5,0.1,8.9-4.5,7.4-7.2c-0.2-0.3-1.2,0.3-1.2,0
									c-0.1-2.8,4.3-4.5,5.4-6.9c0.1-0.3,0.4-8.1,1.8-7.2c1.7,1.1,0.4,4.1,3.7,3.7c4.7-0.6,1.9-5.6,2-7.5c0.2-1.8-1.8-1.3,0.3-2.7
									c1.7-1.2,3.6,3.5,4.4,4.7c5.1,7.5,12.4,10.4,20.4,14.2c1.7,0.8,8.3,5.2,9.8,2.3c1.6-3-3.5-3.7-5.2-4.4c1.7-1.5,6.9-0.2,7.2-3.1
									c0.1-1.3-2.3-2-2.8-2.7c-2.1-2.9-6.9-5.6-5.9-10c7.9,3.3,14.4,9.4,23.4,9.7c1.8,0.1,3.4-0.5,5.1-0.6c0.8,0,4.3,0.4,5.2,0.2
									C1750.6,1815.3,1756.8,1813.5,1751.9,1811.8z"
						/>
						<path
							d="M2045.4,1695.7c-11.3-3.3-21.7-8.1-29.2-17.5c-1.7-2.2-4.7-8.3-8-8c-3.5,0.4-6.6,9.3-8.3,11.7c-2.1-6.2-4.4-13.6-8.5-5
									c-4.8-3.6-6.8-13.9-13.5-9.4c-2.5,1.7-4.3,4.9-6.7,6.9c-2.8,2.4-6.8,3.7-9.5,6c-1.6,1.4-4.2,3.3-0.7,4.4c1.2,0.4,2.3-1.5,3.3-1.3
									c2,0.3,1.3,1.7,3,3c1.2,1,5.9,2.1,7.6,2.2c1.5,0.1,5.6-1.2,6.9,0.3c2.1,2.5-15,14.3-6.8,15.2c0.9,0.1,3.2-1.3,4.7-1.2
									c3.4,0.1,1,2.4,5.3,0.7c2-0.8,2.4-2.6,5.4-2.5c1.5,0.1,2.2,0,3.6,0.4c0.7,0.2,0.8,2.3,2,2.3c3.7,0,3-5.2,2.5-7.2
									c-1.2-4.4-2.5-6.8-1.6-11.6c1.9,5.7,3.6,11.6,6.4,16.9c2.5,4.7,7.9,5.7,4.5-0.1c4.1-1.3,8.9-2.4,9.9-7.4c0.9-4.9-4.2-7.9-3.6-12.7
									c4.6,6.3,11.8,10.5,18.8,13.5c2.5,1.1,9,4.8,11.5,4.1C2045.9,1699.1,2048.6,1696.6,2045.4,1695.7z M1984.7,1686.6
									c-1-1.4-5.3-11.4-4-12.8c2.1-2.1,4.7,2.6,5.6,3.6C1990.4,1681.9,1989,1681.4,1984.7,1686.6z M2009.5,1696.7
									c-1.2,0.6-2.3,1.4-3.3,2.3c-2.4-4.9,4.8-2.7,4.7-2.4C2010.4,1696.6,2009.9,1696.6,2009.5,1696.7z"
						/>
						<path
							d="M1945.1,1686.6c-3.4-2.1-6.5-4.6-9.2-7.5c-1.4-1.6-3.8-6.7-6.5-6.1c-4.9,1-1.4,8.4-7.6,7.5c-3.4-0.5-5.9-4.6-8.9-5.8
									c-4-1.7-4.6,0.5-8,1.8c-0.9,0.3-2.5,1.4-3,1.6c-0.4,0.2-1.4-1.3-2.5-0.9c-3.1,1.1-5.8,5.2-8.1,7.5c-3.2,3.3-6.7,6.2-10.4,8.8
									c-1.4,1-7.9,4.1-4.7,6.3c1.5,1.1,6.6-1.3,8.6-1.6c5.3-1,10.7-6,15.9-3.4c1.9,1,4.2,5.7,6.7,2.2c1-1.4,0-5.4,1.1-7.3
									c0.5-0.9,1.7-0.9,2.2-1.7c0.6-0.9,0.9-2.7,1.6-3.5c3.3-3.7,4.7-1.5,9.1,0.1c-2.4,2.7-5.3,4.9-7.8,7.4c-3.6,3.8-2.6,4.4,1.5,4
									c1.1-0.1,1.8-1.4,3.1-1.5c0.9-0.1,1.6,1.6,2.6,1.6c3.4,0.2,5.5-4,8.9-3.7c-4.9,2.6-8.8,8.3-12.9,12.1c-4.3,4-4.3,4.1-9.6,1.2
									c-2.6-1.5-5.2-3-7.7-4.7c-4.8-3.4-7.5-6.2-12.4-1.4c-4.6,4.6-7.2,10.9-12,15.5c-2.3,2.2-4.8,4.2-7.6,5.9c-1.4,0.9-7.2,2.6-7.9,4
									c-2.1,4.3,1.7,2.8,4.5,2.9c4.5,0.1,7,2.9,10.7-0.2c1.7-1.4-0.3-3.6,2.5-4.3c1.2-0.3,3.2,1.5,4.3,1.9c-2.9,2.2-17.1,10.5-16.6,13.5
									c0.6,3.9,5.1-0.3,6.6-1c3.1-1.5,6.2-1.7,9.6-1.4c4.3,0.4,11.5,2.9,13.1-1.3c0.9-2.4-1-5.3,0.6-7.7c3.5-5.6,4.7,2.3,8.7,0.2
									c4.4-2.4-6.2-7.7-7.9-8.4c-6-2.1-8.7,5.3-14.1,1.9c-7-4.4,1.8-3.5,5.3-3.9c8.9-0.8,11.9-1.5,15.2-9.7c1.4,1.4,5.1,3.6,7.1,4
									c-6.4,5.2,9.3,2.2,10.1,2.3c4.4,0.7,7.6,2.3,12.1,2.2c5.3-0.1,3.5-1.7,3.2-5.6c-0.2-2.4,1-3.1,0-5.6c-1-2.3-4.6-3.6-3.2-6.6
									c1.2,1.9,4.6,9.8,7.3,10.1c5.1,0.6,0.8-4.8-0.3-6.5c-1.7-2.4-4.6-6.4-4.5-8.6c0-0.9,1.7-2.4,2-3.3c0.6-2.1-0.6-3.2-0.3-5.2
									c1.3,1.1,6.1,6.1,8,5.5C1945.2,1689.8,1947.6,1688.2,1945.1,1686.6z"
						/>
						<path
							d="M1867.7,1689.9c-7.9-2.1-15.9-12.8-24.1-12.6c5.7-3.5,5.3-4.2,12.2-1.5c1.9,0.7,10.4,5,8.5-0.8
									c-0.5-1.7-10.8-4.3-12.9-4.9c-5.5-1.4-6.6-0.8-10.4-5c-1.8-2-0.3-2.7-3.2-2.8c-1.3,0-4.4,4.4-5.5,5.4c-2.1,1.8-11.7,6.4-11.4,9.7
									c0.3,3.1,7.8,0.5,9.5,0.8c0,0,1.9,2.2,2.7,2.5c1.1,0.3,4.1,0.8,5.2,0.6c-2.8,3.2-6.3,9.3-10.7,10.5c-4.1,1.1-6.6-0.8-10.9,1.5
									c-4.4,2.4-6.8,4.3-12.1,4.8c-3.9,0.3-10.7-0.6-13.8,2.7c-4.2,4.4,0.8,3.1,3.7,2.3c4.3-1.1,8.3-1.9,12.5-0.9
									c1.6,0.4,2.5,1.6,4.3,1.5c1.5-0.1,2.4-1.6,3.7-1.8c2.1-0.3,7.2,1,9,1.7c1.1,0.4,1.8,2,3,2.4c0.5,0.2,2.8-0.5,3.6-0.4
									c3.8,0.2,2.7,2.1,5-2c2.3-4.1,4.1-6.5,7.8-9.8c2.4-2.1,2.7-1.7,5.6-1.6c1,0,1.7,1.5,2.8,1.3c1.2-0.2,1.6-1.4,2.3-1.6
									c1-0.2,1.3-2,3-2.1c1.4-0.1,5.4,3,6.9,3.3c1.3,0.3,3.7,1.7,4.6-0.1C1869.3,1691.7,1869.1,1690.3,1867.7,1689.9z"
						/>
						<path
							d="M1830.7,1714.8c-4.6-2.8-13.7-10.9-19.4-9.3c-2.4,0.7-3.3,3.4-5.2,4.6c-3,1.9-3.8,1.4-7.1,1.1c-3.9-0.3-3.9-0.7-7.2,2.2
									c-2.8,2.5-5.3,5.4-8.3,7.7c-1.6,1.2-8.6,3.9-7.2,6.6c0.9,1.7,3.7,0.5,5.2,0.4c4.2-0.4,9,0,13.1-1.3c3.5-1.1,3.7-3.1,6.6-5.2
									c7.2-5.2,13.6,6.8,21.6,5.1c7.3-1.5-6-5-1.6-10.5c2.1-2.6,1.4-1.5,4.9,0.3c1,0.5,2.9,2.5,4.2,1.9
									C1831.6,1717.8,1832.2,1715.7,1830.7,1714.8z M1817.2,1722c0.4,0.4,0.7,0.7,1.1,1.1C1817.1,1723.1,1816.7,1722.7,1817.2,1722z"
						/>
						<path
							d="M1825.5,1734.4c-4.7,0.8-10.1-4.1-14.8-5.1c-2.5-0.5-5.5-1-8-0.8c-3.7,0.3-4.7,2.3-7.7,3.9c-2.9,1.6-3.6,0.6-6.7,1.2
									c-3.5,0.7-5.8,2.7-8.4,5c-4.2,3.6-7.5,6.1-12.5,8.5c-1.7,0.8-4.2,0.1-3.7,2.7c0.5,2.4,3.2,1.4,4.7,1.2c5.8-0.7,8.3-4.9,14.5-3
									c2.9,0.9,12.4,8.6,14.9,5.3c2.4-3.2-6.9-6-4.8-11.1c3.5,1.8,15,10.7,19.4,7.3c5.4-4.1-12.7-7.7-11.9-8.6c1.3-1.5,6.5-0.6,8.2-0.9
									c3.7-0.6,6.3-2.3,10.1-2.3c2,0,5.9,1.6,7.7,0.5C1828.3,1737.1,1828.3,1733.9,1825.5,1734.4z"
						/>
						<path
							d="M1805.2,1659c-4.8,0.1-8.3-2.5-11.9-5.2c-4.5-3.5-4.5-3.5-8.6,0.2c-3.5,3.2-8,5.3-11.5,8.4c-4,3.5-3.1,4.8,1.9,3.5
									c4-1,5.7-1.8,9.6-0.6c0.9,0.3,1.6,2.2,2.7,2.5c2.5,0.7,3.9-1.4,6.1-2.1c3.3-1.2,6.8-1.7,9.5-2.6c1.3-0.5,3.6,0.9,4.1-1.4
									C1807.3,1660.3,1806.6,1659,1805.2,1659z"
						/>
						<path
							d="M1971,1667.8c-5.3-3.7-6.9-15.7-12.8-16.2c-1-0.1-2.3,1.9-3.4,2c-2.4,0.2-3.1-0.6-4.9-1.9c-4.7-3.4-3.5-4.9-7.7,0.9
									c-1.3,1.8-6.1,8.7-8.6,8.6c-1.7-0.1-8.9-8.5-10.2-9.8c-2.5-2.5-4.9-7.8-8.6-6.8c-2.4,0.7-6.8,7.1-8.7,9c-0.9-1.2-1.7-4.6-3.1-5.3
									c-2.9-1.6-2.6,1.1-4.4,2.4c-2.7,1.9-2,2.1-5.8,0.2c-2.4-1.2-4.1-2.5-6-4.4c-2.8-2.9-4.7-8.6-9-7.7c-3.6,0.8-10.6,10.1-13,12.6
									c-3.2-4-6.2-13.2-11.1-14.6c-2.1-0.6-2,1.7-4.3,0.6c-1.4-0.7-2.7-3.3-3.5-4.5c-2.9-4.1-3.4-5.5-7.2-2.4c-5.3,4.3-8.8,12.9-13,18.3
									c-0.9,1.1-2.8,4.3-4.3,5c-3.7,1.7,2.3,1.8-3.6,0.1c-5-1.4-3.2-3-6.9,2.1c-2.4,3.4-14.7,11.2-13.9,14.9c0.9,4,3.9-0.7,5.5-1
									c0.2,0,5.2,0.8,5.4,0.9c2.2,0.4,4.4,0.9,6.2,1.1c0.6,0.1,2.5-0.8,3.4-0.8c1.5-0.1,2.9,1.2,4.5,1c8.9-1.2-3.4-6.6-1.1-11.1
									c1.6,1.1,3.4-0.3,3-2.1c2.8,1.6,4.2,1.5,7.1,0.9c1.1-0.2,2-1.3,3.2-1.4c1.4-0.2,3,1.3,4.1,0.8c3.7-1.4,4.1-12.9,7.3-16.5
									c1.5,3.3-3.8,8-3,11.9c-0.4-2.2,3.2,5.6,2.1,4.1c1.9,2.7,1.4,2.8,4.8,3.1c0.7-0.9,1.6-1.3,2.7-1.2c0.8,0.5,1.4,1.1,1.9,1.9
									c1.7,0.3,2.4,1.5,3.9-0.4c2.6-3.3-3.1-11.1-0.4-13.6c2.8,4.3,6.1,8.2,9.1,12.4c2.7,3.9,1.6,3.2,5.7,2.2c9.3-2.3,6.1-9.8,6.2-17.5
									c2.2,0.9,5.4,4.6,7.5,6c2.9,1.9,5.6,3.3,8.8,4.7c-1.5,2.1-3.3,4-4.7,6.2c-2.7-2.4-6.8-6.8-10.3-4.6c-2.3,1.4-4.9,9.4-6.5,11.9
									c-3,4.6-6.8,7.4-10.5,11.3c-3.2,3.4-3.1,4.8,1.4,4.1c2.6-0.4,4.4-1.3,7.4-1c2.9,0.3,6.7,2,9.6,0.7c2.7-1.2,3.5-4.7,4.3-7.2
									c1.1-3.3,2.3-7.1,4.3-10.2c1.6,1.5,16.5,14.5,12.8,5.6c-1.2-2.9-9.3-3.7-5-7.7c3.4-3.2,4,0.5,5.6,1.7c3,2.2,5.4,1.6,8.8,2.9
									c1.2,0.4,3.9,3.7,4.7,4c4,1.1,7-4,10.7-4c2-0.1,7.1,2.5,8.8,3.4c2.8,1.5,4.8,4.9,7.9,5.6c2.2,0.5,8.6,0.7,10.6,0.5
									c8.2-0.7,8.2-6.1,4.9-11.8c-1.5-2.6-2.9-6.1-5.9-5.1c-3.2,1.1-3.6,8.6-5.9,10c-2.8,1.7-3.7-0.1-2.2-4c1.8-4.5,2.9-6.9,3-12.2
									c3.2,2.8,6.7,1.9,9.9-0.1c2.7,4.3,3.3,9.2,7.3,12.8c1,0.9,2.9,3.3,4.5,2.8C1972,1671,1972.4,1668.7,1971,1667.8z M1905.8,1659.7
									c-0.7,4.6-6.1,1-2.6-3.5C1903.3,1656.5,1905.9,1659.2,1905.8,1659.7z M1923.5,1667.3c0.1,0.2-2.6-8.7-2.7-8.4
									c1.8-5.6,7.5,2,9.7,4.5C1928.2,1664.8,1925.8,1666.1,1923.5,1667.3z"
						/>
						<g>
							<path
								d="M1738,1615.9c3.1,1.4,11.9,13.3,11.1,5.4c-0.2-2.4-4.2-5.9-6.1-7.1c-1-0.7-3.2-2.5-4.6-1.8
										C1737.1,1613.1,1736.1,1615,1738,1615.9z"
							/>
						</g>
						<path
							d="M1647.9,1666.3c-3.3-5.5-14.2-3.2-18.7-8.7c-2.1-2.5-2.3-5.6-5.8-6.8c-2.7-0.9-7.6,0-10.2,0.9c-6.8,2.5-9.3,9.7-15.2,13.5
									c-1.7,1.1-15.3,7.6-10.7,10.9c1.9,1.3,9.7-6.7,13.6-5.5c2.6,0.8,0.7,2.4,4,2.6c2.5,0.1,5.2-1.4,7.2-1.4c0.8,0,2-0.2,3-0.1
									c1,0,1,1.6,2.7,1.2c2.2-0.4,4.8-4.8,5.8-6.9c2.7-5.7-4.5-6.3,0.1-9.9c2.8,3.7,3.4,6.7,8.2,8.4c-3,3-12.1,8.4-5.5,10.2
									c-1.8-0.5,8.6-0.7,3.5-0.7c3,0,5.7,2,8.6,2.1c9.5,0.3,0.1-4.3,1.8-7C1641.2,1667.9,1650.6,1670.8,1647.9,1666.3z"
						/>
						<path
							d="M1642.1,1643.3c-2-3.2-4.7-5.5-7.7-7.8c-2.4-1.8-7.4-7.1-10.2-7.8c-1.5-0.3-2.7,0.8-3.6,0.7c-2-0.2-1.7-0.2-3.8-2.5
									c-3.3-3.6-4.7-5-9.2-2.2c-2.8,1.8-18.8,12.1-18.3,14.4c0.7,2.9,5.4,0.6,7.4,0.3c3.6-0.7,9.9,0.8,13.2-0.6
									c-5.9,6-10.9,9.7-17.9,14.1c-1.4,0.9-4.5,2.2-1.6,4.1c2.4,1.6,8.4-2.8,10.7-3.7c5.1-2,12.8-2.4,18.6-2.8c1.5-0.1,8.5,0,9.7-1.5
									c2.7-3.5-3.4-4.9-5.6-6.2c3.6-2,7.9-2.1,11.5,0.2c1.2,0.8,3.4,4.2,4.6,4.1C1641.4,1646.2,1643.2,1645.1,1642.1,1643.3z
									M1613,1636.9c-0.7,0.1-1.4,0.2-2.2,0.2c1-1,3.9-5.4,4.2-6.9C1617.9,1633.2,1615.1,1633.8,1613,1636.9z"
						/>
						<path
							d="M1827.1,1642.9c-2.8-2.2-5-4.8-6.9-7.8c-1.1-1.7-2.3-6.2-3.7-7c-4.5-2.7-8.4,5-11.1,7.8c-1.7-3.2-2.6-7.7-6.6-5.7
									c-3,1.4-3.3,6.5-6.9,7.1c-6.9,1.2-10.8-12-15.5-11.1c-1.9,0.4-2,3.1-3.4,4.1c-1,0.7-4.2,1.6-5.2,1.8c-2.6,0.4-0.8,0.7-3.4-0.1
									c-4.8-1.5-14.8-13.5-18.6-9.2c-6.9-5.9-7.7-0.6-14.7,1.9c-4.9,1.7-9.8-0.6-13.3,4.3c-2.7-3.5,1.7-4.2,3.7-5.1c1.5-0.6,3.7,0,4.5-2
									c1.1-2.6-2-1.8-2.2-3.3c-0.3-2-0.1-2.4,1.1-4.2c2.2-3.1,1.3-2.1,4.7-1.6c1.6,0.2,13.2,6.3,13,2.1c-0.2-3.5-9.7-4.8-12.3-6.2
									c-3.8-2.1-5.1-5.2-8-8.1c-2.7-2.6-6.3-4.9-9.2-1.1c-1.2,1.6-1.3,4.4-2.2,6.2c-1.7,3.4-4.8,6.1-7.4,8.8c0.5-2.4-9-8.1-11-9.4
									c-4.4-2.9-5.1-3-9.1,0.6c-2.5,2.2-6,6.9-9.2,7.5c-4.5,0.8-8.4-2.9-13-0.6c-4.5,2.2-6.3,8.6-10.7,10.8c-3.6,1.8-4.6-0.9-7.6-3
									c-3.2-2.3-4.7-3.2-8-0.4c-2.1,1.9-7.3,7.3-8.1,9.6c-2,5.7,1.1,2.7,3.8,1.6c2.4-1,3.2-1.9,6.4-0.9c1,0.3,0.5,1.8,1.6,2
									c1.9,0.4,2.3-0.6,3.8-0.8c3.2-0.3,5.4,1.2,8.8,2.4c2.5,0.9,4.4,1.5,7.2,0.8c1-0.3,1.3-1.7,2.7-1.7c2.5-0.1,4.5,0.5,7.2,0.3
									c3.8-0.2,7.7-0.1,6.5-5.3c-0.8-3.4-6.3-5.6-6.1-9.3c0.5,2.9,3,1.2,4.4,0.3c0.2,2.6,7.8,7.8,10,9.3c-3.2,1.5-8.4,4.8-2.5,5.8
									c3,0.5,9-2.1,12.5-2c3.8,0.1,7.3,0.6,11,1c3.1,0.3,6.2,1.4,9.4,1.6c-2.3,2.2-18.7,13-9.4,12.7c0.6,0,3.5-1.7,4.5-1.9
									c3.1-0.6,7.8,1.4,10.5,0.6c2.4-0.7,3.2-4.1,6-3.7c1.8,0.3,1,4.7,3.8,2.6c1.3-0.9,1.7-7.2,2.8-9.1c2.1-3.5,3.6-5,8.1-4.9
									c-2.8,3.7-2.3,8.3-5.7,11.7c-2.6,2.6-5.8,3.7-1.4,6c3.3,1.7,8-0.2,11.4-0.4c3.9-0.2,7.5,1.7,11,1.3c4.5-0.6,5.9-2.5,10.9-0.5
									c4.4,1.8,6.8,3.2,11.8,2.7c4.7-0.5,6.9-1,7.2-6.1c0.4-5.5-1.6-6.9,6.1-5c-2.1,4.7,5.7,12.3,9.8,13.7c3.6,1.3,4.3-0.5,6.9-2.9
									c1.1-1,3-3.1,4.3-3.5c0.6-0.2,3.4,0.6,3.2,0.7c8.6-3.4-0.7-5.5-0.1-10.4c0.4-3.9,6.8,5,7.4,5.5c0.8,0.7,2.4,2.6,3.8,2.3
									C1827.2,1645.9,1828.6,1644.1,1827.1,1642.9z M1722.2,1607.7c0.6,0.8,1.3,1.5,2.1,2.2C1723.2,1610.7,1721.5,1608.2,1722.2,1607.7z
									M1691.1,1613c-1.9-0.8-0.5-2.1,0.6-3c0.4-0.3,2.5,1.5,2.9,1.8C1693.7,1613.1,1692.5,1613.5,1691.1,1613z M1699.8,1618.3
									c-2.8,2.9-3.3-0.2-6.6-1.6c0.2-0.1,3.3-3,3.9-3.1c1.6-0.2,2.5,3.3,4.8,2.5C1701.2,1616.8,1700.5,1617.6,1699.8,1618.3z
									M1742.7,1626.9c-0.4,0.5-0.8,1-1.1,1.5C1738.5,1624.8,1744.7,1624.1,1742.7,1626.9z M1755.7,1644.7c-2.7-2-3-0.6-3.9-4.5
									c4.9,3,6.5-3.8,6.2-7.2c1.9,1.1,3.6,2.4,5.4,3.6C1761,1639.5,1758.8,1642.6,1755.7,1644.7z"
						/>
						<path
							d="M1702.3,1596.6c-4.8-2.3-8.7-3.7-12.2-7.9c-4.1-4.9-5.2-4.6-9.9,0.3c-1.8-3.1-4.4-9.8-8.5-8.9c-2.8,0.6-6.5,10.1-8,12.4
									c-3.1-4-5.7-15.2-10.8-10c-2.6,2.6-4.4,6.9-6.4,10c-2.6,4.1-2.9,3.9-0.4,6.7c2.5,2.8,4.2,2.4,7.4,3.7c3.4,1.3,3.2,4.4,5.6,5.8
									c3.1,1.8,4.5-3,4.5-5.6c0-3.4-2.4-5.3-3.2-8.4c3.4,4.6,13.7,14.2,20,10.6c6.5-3.6,0.1-3.9-1-5.7c-1.7-2.7-1.6-0.3,0.3-3.9
									c2.3,3.1,3.1,4,6.4,2.9c1.8-0.6,3.5-3.2,4.8-3.2c2.2,0,5.6,3.8,8.2,4.2C1700.4,1599.7,1706.4,1598.5,1702.3,1596.6z
									M1677.6,1593.9c-3.8,2.3-2.4-3-2.6-5.6C1675.8,1589.8,1678.8,1593.2,1677.6,1593.9z"
						/>
						<path
							d="M1608.5,1586.1c-3.9-2.1-8.3-2.6-12.1-5c-2.5-1.6-6.8-8.1-9.3-8.4c-1.6-0.2-18.3,15.6-17.9,17.9c0.4,2.7,17.4,2.5,20,2.4
									c3-0.1,8.3-0.3,6.6-4.3c-0.4-0.9-2.6-1.6-3.2-2.5c-0.8-1-0.9-2.8-1.4-4c3.5,3,7.5,4.5,11.8,6c1.2,0.4,3.8,2.2,5.1,1.6
									C1609.5,1589.1,1610.2,1587,1608.5,1586.1z"
						/>
						<g>
							<path
								d="M1576.1,1519.1c2.7,2.5,5.1-0.7,8,0.5c2.2,0.9,4.7,5.1,6.9,6.6c2.6,1.7,7.1,3.9,10.3,2.6c2.7-1.1,3.8-2.2,0.9-4
										c-1.9-1.2-5.8,0-8.4-1.5c-2.9-1.7-4.7-4.8-7.3-6.9c-3.3-2.6-3.6-2-7.1-0.8C1578.7,1515.8,1573.3,1516.5,1576.1,1519.1z"
							/>
						</g>
						<path
							d="M1470,1588.6c-4.8-2.7-9.1-6.1-13-9.9c-3.5-3.4-4.4-6.3-8.4-1.9c-1.8,2-5.1,7.2-2.4,9.3c4.5,3.4,6.1-3.3,7.1-5.5
									c3.3,3.5,7.2,7,11.4,9.4c1.2,0.7,3.4,2.8,4.9,2.2C1471,1591.7,1471.6,1589.5,1470,1588.6z"
						/>
						<path
							d="M1566.5,1491.6c-5.1-1.2-8-3.8-12.2-6.7c-6.2-4.2-2.5,0.8-8,1.3c-1.7,0.2-2.6-1.6-4.3-1.5c-2.1,0.1-5.1,3.5-6.9,4.7
									c-2.5,1.7-11,5.5-11.5,9.2c-3.6-3.2-14.2-13.2-19.6-11c-3,1.2-5.6,7.2-7.5,9.5c-1.4,1.7-3,4-4.9,5.1c-3.5,2.1-3.3,1.3-6.9-1.1
									c-2.5-1.6-7.5-6.7-10.5-6.5c-2.5,0.1-7.9,8-9.6,9.8c-2.1,2.2-8,6.5-9.1,9.1c-1.7,4.2,1.7,4.2,4.5,1.5c1.2,2.4,4.4,2.6,6.7,2.4
									c1.4-0.5,2.9-0.9,4.4-1.2c1.5,0,2.4,0.7,2.6,2c3.4,0,2.5,0,5.5-1.7c2.6-1.5,5.8-2,8.6-3.2c3.7-1.6,5.8-2.3,9.9-2.4
									c3.3-0.1,7.6,1,10.6-1.1c3.9-2.6,3.5-9.9,4.6-14.2c4.1,3,7.9,6.1,11.6,9.5c1.8,1.7,1.9,3.1,4.5,2.3c1.1-0.3,0.4-2.7,0.1-3
									c3.8,0.7,8.4-0.1,11.8-2c4-2.1,4.6-5.7,7.6-8.5c1,1,3.1,2.6,4.5,2.5c4-0.2,2-2.1,3.4-4.6c1.9,1.2,7.4,4.6,9.6,3.6
									C1567.5,1494.8,1569.3,1492.2,1566.5,1491.6z M1480.7,1510.5c0.5-3-2.6-6.1-2.9-9.2c2.7,1.9,5.4,3.7,8.2,5.4
									C1484.3,1508,1482.5,1509.2,1480.7,1510.5z"
						/>
						<path
							d="M1530.8,1483.6c-7.8-1.7-15.8-4.9-21.8-10c-3-2.6-6-4.7-9.7-6.2c-1.4-0.6-10.2-1.5-8.1-3.3c2.3-1.8,20.8,2,24,2.9
									c2.8,0.7,8.2,4.8,11,4.2c5.3-1.1,0.8-3.6-1.3-4.6c-9-4.4-18-4.3-27.8-5.8c-4.3-0.6-8.4-3.6-12.3-4.1c-3.8-0.4-6,2.8-8.7,5.1
									c-4.3,3.5-5.2,1.5-9.7-0.1c-4.8-1.7-4.2-1-7.8,2.7c-3,3.1-6.3,6-10.1,8c-4.8,2.5-9.9,1.8-14.7,3.6c-5.1,2-9.1,9.7-14.5,8.2
									c-3-0.9-4.1-4.8-7.4-2.5c-2.9,2-2.9,10.3-5.2,13.4c-1.8,2.5-19.2,13.7-11.5,15.3c0.8,0.2,2.2-2.2,2.4-2.3c2.9-0.8,4.2,0,7,0.3
									c3.2,0.3,6.9,0,10.3-0.6c3.2-0.6,7.4-1.8,8.2-5.4c0.2-0.9-2.4-3.1-1.6-5.2c0.4-0.9,1.5-1.8,2.2-2.3c0.9-0.6,6.9-2.3,8.1-2.2
									c4.1,0.4,5.4,6.6,9.8,6.6c3,0,3.8-3.5,6.7-3.6c2.1-0.1,3,1.9,4.9,2.7c1.8,0.8,4.2,0.4,6.1,1.1c-0.6-0.2,3,1.9,3,1.9
									c3.8,0,3.1-1.6,2.7-5c-0.8-7.4-0.2-8.1,7.6-6.9c3.7,0.6,14.6,3.6,18.2,1.5c3.6-2.1-0.3-4.5,5.1-4.9c5-0.4,4.3,2,8.7-1.8
									c1.9-1.6,0.5-2.2,3.7-3c2.7-0.7,4.7,1.1,7.1,1.8c3.8,1.1,8.4,3,12.2,3.7c0.3,0.1,2.7,1.1,3.5,0.5
									C1532.3,1486.3,1532.9,1484,1530.8,1483.6z M1411.7,1499.5c0,0.7-0.4,1.1-1,1C1408.7,1502.6,1412.7,1498.5,1411.7,1499.5z
									M1451.9,1488c-2.3,0-3.8-2-5.9-2.5c-3.1-0.7-3.2,0.3-5.6,1.1c-2.7,1-1.6,2.3-5.3,1.3c-2.3-0.6-4.2-3-6.2-4.2
									c3.1-1.9,5.2-3.5,8.9-3.9c3.6-0.3,5.4,1.3,8.5,1.6c1.1,0.1,4-0.8,4.9-0.4C1453.9,1482.3,1453.4,1486.3,1451.9,1488z
									M1460.7,1496.2 M1499.8,1480.1c-7.2,4.8-14.3-2-21.4,1.8c-1.9,1-4,4-6.6,3.4c-1.4-0.3-4.8-4.2-2.7-5.6c0.7-0.5,2.9,1.1,3.8-0.1
									c1.9-2.5,0.1-4.1-1.1-6.1c-0.5-0.8-2.4-1-2.4-2.3c0-2,4.5-1.8,5.6-1.3c2.5,1.1,1.8,4.3,3.3,5.2c1.2,0.8,5.8,0.9,7.5,1.2
									C1488.6,1476.7,1507.4,1475,1499.8,1480.1z"
						/>
						<path
							d="M1381.5,1553c-3.2-2.2-5.5-6.9-9.5-7.9c-4.2-1-7.5,2.1-10.3,4.8c-3.2,3.1-6.2,7.4-11,6.8c-6.2-0.7-7.1-6.4-13.7-3
									c-2.3,1.2-3.7,1.5-6.6,2.1c-6.1,1.1-12.1,5.1-17.5,8c-2.7,1.5-10.1,6.1-3.4,6.4c1.8,0.1,2.9-2.6,4.9-2.3c2.1,0.4,2.6,3.3,4.6,3.8
									c0.8,0.2,8.6-1.2,9-1.7c3.4-3.6-3.4-3.8-0.1-7c2.4-2.3,8.4-1.8,11.2-2.5c3.2-0.8,5.8-1.4,9.2-0.5c2.9,0.8,7.5,4.5,11.3,4.1
									c4.8-0.5,4.9-4.4,6.3-7.9c1.6-3.8,4-6.8,7.7-5.2c1.9,0.8,3.6,5.3,5.8,5.5C1381.2,1556.6,1384.2,1554.9,1381.5,1553z"
						/>
						<path
							d="M1650.7,1570.1c-9.1-2.2-15.9-10-23.2-15.5c-4-3-8.2-5.7-12-8.9c-2.9-2.4-6-7.3-9.9-6.2c-6.1,1.6-2.9,8.3-10.7,4.8
									c-4.8-2.2-10.5-4.5-14.4-8c-4.6-4.2-10.3-11.3-8.1-17.9c3.6,5.4,9.7,17.7,18.1,16.6c8.5-1.1-6.1-6.7-8.1-9.1
									c-2.8-3.3-8.4-17.1-12.9-16.8c-4.1,0.3-12,15.4-13.6,18.5c-2.8-2.2-6.1-7.2-9.7-6.2c-2.4,0.6-7.9,10.5-9.5,12.5
									c-4.6-4.1-5.7-8-11.3-4.1c-2.8,1.9-7.1,7.5-11.1,5.8c-3.9-1.6-2.5-8.4-7.4-5.9c-1.7,0.9-5.7,11.2-8,9.5c4.1-2.3-2.9-4.3-3.9-4.8
									c-3.2-1.3-6-3.5-9.1-4.8c-3.3-1.4-5-0.6-7.9-1.7c-2.9-1.1-5-4.7-7.9-5.8c-5.1-1.9-5.8,2.6-9.4,4.7c-3.1,1.8-7.7,0.4-11.4-0.6
									c-4.1-1.1-8-2.7-11.6-4.8c-2.9-1.7-4-1.4-2-4.6c4.8-7.7,11.9,3,17.1,1.5c7.6-2.2-5.5-6.3-7.6-7.4c-4.2-2.1-12.8-11.1-16.8-9.8
									c-2.6,0.9-5.8,6-7.8,7.9c-3.2,3.2-7.1,6-9.9,9.5c-5-6.4-8.5,1.4-10.9,5.9c-2.9-5.6-3.6-10.9-8.2-3.3c-3.6,5.9-6.7,11.9-11.8,16.7
									c-5.3-5.2-11.7-13.9-19.5-15.4c-6.9-1.4-9.6,5-12.9,9.9c-2.3-1.5-8.5-8.3-11.5-7.3c-4.2,1.5-4.1,11.2-7.6,12.2
									c-3.7,1.1-8.9-8.2-12.3-8.9c-5-0.9-6.6,5.5-8.5,8.8c-1.3,2.2-4.9,8.8-8.2,8.5c-1.6-0.1-3-3.1-4.2-4c-2.2-1.7-3-2.2-5.6-2.7
									c-2.2-0.4-1.8,0.8-3.7-0.1c-2.1-1-1.4-3.7-4.3-3.7c-2.8,0-10.1,12.1-11.9,14.1c-2.5-2.1-4.2-7.9-7.8-7.9c-3.8,0-6.2,6.8-8.6,8.9
									c-2.1-2.5-4.5-9.2-8.4-8.2c-3.3,0.9-5.5,8.8-7.5,11.3c-4.6-4-6.2-8.6-8.1-14.2c-1-2.9-1.6-5.6-4.7-6.9c0.2,0.1-5.4-0.2-5.4-0.2
									c-5.9,0.6-5.8,7.7-9,11.4c-0.4,1-0.8,2-1.3,2.9c-1.6-1.2-3.3-1.3-5.1-0.3c-2.2,1.1-3.1,4.9-3.7,7.1c-2.4-2.3-4.9-5.9-7.4-1.9
									c-4.4-4-9.7,5.3-11.5,8.9c-3.3-2.7-5.8-7.3-8.9-9.6c-4.5-3.4-4.9,1.2-7.8,5.1c-3.3,4.4-5.3,5.5-10.4,6.3c-5.6,0.9-7,3.3-10.4,7.8
									c-3.2-2.3-8.5-8.2-12.6-8.4c-5-0.3-6.4,5.1-9.3,8.4c-1.5,1.7-3.3,3.4-4.9,5c-0.9,0.9-5.2,3.5-5.7,4.3c-3.2,4.7,1.4,3.3,5,2.8
									c6.6-0.9,9.8-0.1,14.1-6c2.5-3.5,2.5-5.3,6.6-2.5c3.9,2.7,3.6,5.3,9.2,5.1c-0.9,0,5.3-0.8,5-0.4c2.4-2.4,0.9-3.2-1.7-4.7
									c4.3-2,7.1,1.2,10.5,2.2c1.4,0.4,0.9-0.9,3.7,0c2.8,0.9,2.7,3.3,6.7,2.4c2.4-0.5,5.7-2.9,5.9-5.7c0.1-1.5-1.8-3.3-2.1-4.7
									c-0.4-2.1,1.3-1.7-0.2-4.3c-1.5-2.6-3.5-2.1-2.3-6.7c2.4,3.4,7.9,8.6,11.7,10.1c1.6,0.6,6.9,1.6,8.9,0.4c3.6-2.2,0.4-4.3,0.9-7
									c0,0.3,2.4-3.4,2.3-3.2c0.4-0.5-0.2-1.8,0.4-2.8c1.5,1.4,0.3,5.9,1,6.5c2.3,2,3.6-0.3,5.7-1.8c1.8-1.3,0-1.8,2.5-1.8
									c1.7,0,3.6,1.1,4.9,2.1c3.1,2.3,2.8,4.4,6.3,4.5c0.4,0,3.3-1,4-1.1c1.3-0.1,2.6,0.5,3.9,0.3c1.2-0.2,2.7-0.1,3.7-0.9
									c3.2-2.7,0-1.5,0.2-3.6c0-0.2-0.2-3.5-0.2-3.3c-0.1-1.3,1.2-1.2,0.3-3.1c-0.8-1.7-5-3.5-5.7-4.8c-0.3-0.6-1.5-6.4-0.7-7
									c3.5-2.3,3.8,3.3,4.6,5.1c1.5,3.3,4.6,6.1,6.5,9.1c2.2,3.4,1.7,4.1,5.2,4.4c1.6,0.1,1.3-1.2,2.2-1.2c1-0.1,5.1,1.4,6.1,1.7
									c2.3,0.7,5.7,2.8,7.9,2.8c1.5,0.1,3,0.5,3.9-1.1c0.8-1.5-1.2-2.7-1.1-2.9c0.9-3.3,5.1-6.9,6.5-10.3c0.8,1.7,2,3.2,3.5,4.4
									c-2.3,2.3-5.6,3.8-2.2,6.2c2.1,1.5,6.9,0.9,9.3,0.9c-1.6,0.6-1.6,2.8-0.4,3.7c1.2,0.9,3.6-0.7,4.6-1.1c4.9-2.4,5.1-5.5,6.1-10.4
									c0.5-2.8,1.1-7.1,4.3-8.2c3-1,5.7,2.8,7.5,4.9c5.1,5.8,2,15.1,12.8,9.6c3.6-1.9,4.3-2.7,5.1-6.5c1.2-5.5,2.6-11.3,3.5-16.8
									c2.6,2.3,5.2,4.4,8.3,5.9c-1.2,1.4-12.5,11.5-8.2,13.2c2.2,0.8,3.5-1.9,5.5-2.6c2-0.6,4.5-0.5,6.5,0.3c2.9,1.2,2.3,3.5,5.6,2.7
									c1.9-0.5,3.1-3.4,4.7-4.4c5.3-3.7,9.7,1.3,15.3-0.7c2.4-0.8,7.5-4.6,8.2-7.1c0.2-0.9-1-5.8-1.2-7c-0.1-0.6-1-4.6-0.8-5
									c2.4-4.1,9.9,5.3,12.5,7.8c1.7,1.7,2.5,4.3,4.1,5.6c1.5-0.7,2.8-0.2,3.8,1.4c1.6,1.1,3.3,1.9,5.1,2.6c-3.1,3.5-6.5,7.4-9.3,11.1
									c-3.2-4.5-4.3-2-7.2,1.2c-2.6,2.9-5,5.3-8.2,7.6c-2.3,1.6-6.1,3.4-7.2,6.2c-1.7,4.4,0.7,2.4,2.8,3.1c3.6,1.1,7.7,3.4,11.5,1.1
									c4.1-2.5,0.2-2.3,1.4-5c0.9-2.1,4.2-4.4,5.4-6.8c1.8,1,2.8,4.2,4.8,4.8c0.8,0.2,4.3,1,2.9-1.5c4.3,1.1,2.4-1.3,4.1-2.5
									c1.1-0.7,2.7-0.1,3.4-1.3c0.7-1-0.4-1.7,0-2.6c1.4-3.3,1.7-4.3,5.8-3.4c1.9,0.4,6.3,3.9,8.1,2.4c4.9-3.9-8.2-5.6-8.8-8.8
									c-0.8-4.3,6.2-2,2.2-9.3c-0.6-1.1-3.8-4.4-3.9-5.3c-0.2-1.6,1.2-2.7,3.1-1.5c1.3,0.9,1.9,5.4,2.7,6.9c1.4,2.7,2.8,4.2,5.8,5.2
									c1.9,0.7,6.3,1.7,7.4-0.9c0.8-1.9-1.3-3.2-1.4-4.6c-0.3-3.2,1.5-5.2,0.7-8.9c-0.7-3.3-2.4-6.3-2.7-9.7c4.1,4.4,8,9,12.2,13.4
									c1.4,1.4,5.7,6.6,7.9,6.5c2-0.1,3.4-1.4,1.8-3.6c2.2-0.1,6.6-1.3,5.8,2.3c-1.8-1-6.9,3.1-9.4,3.3c-5.7,0.4-8.2-0.4-13,3.9
									c-3.4,3-7.3,7.4-9.4,11.5c-2.5,4.8-1.2,4.9,3.4,3.8c4.5-1.1,15.6-1,7.8,5.5c-3.8,3.2-6.3,1.8-11.1,1c-3.9-0.6-8.3-5.8-11.3-5.9
									c-2.8-0.1-6.5,5.8-9.1,7.5c-4.1,2.6-8.6,3.7-12.5,6.8c-1.9,1.5-8.7,7.8-2.6,7.8c0.9,0,2-1.4,2.8-1.7c2.2-0.6,2.7-0.1,4.9,0.2
									c3.2,0.5,4.8-0.9,7.7-0.7c3.9,0.2,7,4.1,11.1,1c4.2-3.2-2.5-5,0.6-9.7c2-3.1,8.1-2.2,11.6-0.8c-2.7,2.2-12.7,8.7-5.6,9.9
									c2.5,0.4,3.1-1.2,5.1-1.4c0.9-0.9,2-1.2,3.4-0.8c1.1,0.6,2.2,1.3,3.2,2.1c4,0.5,7.4,0.2,8.9-2.8c1-2-1.5-3.3,1.4-5.9
									c0.9-0.7,2.1-1.7,3.2-2c1.4,2.3,4.1,12.2,8.7,8.7c1.5-1.1,1-4.1,1.3-5.9c0.5-2.9,1.2-4.3,1-7.4c1.7,2.1,4.7,8.5,7.5,7.9
									c4-0.8-3.8-9.5-4.7-10.7c-2.6-3.4-4.8-7.6-8.9-4c-1.3,1.1-1.8,5.2-3.8,5.7c-1,0.2-4.9-3.4-6.6-3.5c1.7-2.4,1.9-6.2,2.6-8.9
									c1-3.9,3.1-4.8,7.1-6.1c3.8-1.2,12.3,2.2,12.2,6.7c-0.1,3.9-4.8,4.5-1.1,7.6c0.5,0.4,8.4,3.1,9.3,3.2c3.2,0.3,3.9-1.4,4.1-4.7
									c0.1-1.3-0.1-2.8-0.5-4c-0.4-1-2.3-0.9-2.4-1.1c-0.4-1,2-4,2.2-5.3c6,4.4,10.1,11.4,17.9,13.2c9.2,2.1,4.3-6.1,10.4-7.5
									c5.7-1.3,11.6,7.6,16.6,2.3c2.4-2.6,0.3-4.1,4.2-5c1-0.2,2.6,0.8,4,0.5c1.2-0.3,3.3-2,4.1-2.6c1.8,5.1,9.8,3.7,13.3,6.7
									c0.7,0.6,2.7,4.3,3.8,4.5c3.4,0.6,2.8-2.7,5.5-3.7c3-1.1,8.9,1.6,11.4,2.2c0.9-0.8,1.9-1,2.9-0.5c1,0.5,1.8,1.2,2.6,2
									c1.6,0.7,2.3,2.8,4.3,0.9c-0.7,3.7,3.5,3.4,5.5,3.7c4.8,0.6,6,2.7,10.3,4.3c3.7,1.5,6.9-0.4,9.5-3.3c2.6-2.8,0.7-7,2.8-9.1
									c1.7,0.7,0.2,2.5,2.5,2.9c2,0.3,4.3-2.5,5.6-3.5c1.5,3.3,5.1,6.2,8.9,6.2c-1.6,1.8-4.6,4.1-2.5,6.3c1.5,1.6,1.9-1,3-0.6
									c1.4,0.5,3.1,2.4,4.3,2.7c3,0.8,5.9-0.6,9.1-0.8c-1.5,2.3-11.8,15-9,16.6c3.2,1.8,3.4-3.1,5.3-3.9c1.2-0.5,5.9,0.8,7.6,0.7
									c-1.9,2.2-6.6,5.3-2.5,7.4c-1.1-0.6,7.5-1.4,5.2-1.1c2-0.3,4-0.3,6-0.6c3.7-0.5,7.9-0.5,10.5-2.8c1.4-1.2,0.9-3.1,2.7-4.4
									c0.5-0.4,2.2,0.1,2.3-0.1c3.2-2.7,1.4-3.5-2.6-6.6c-3-2.4-6-4.8-8.7-7.5c-2.2-2.3-4.6-7.4-8.1-6.2c-2.6,0.9-3.3,8.4-6.1,7.4
									c5.2-4.4,1.3-20.7,12.9-11.1c3.8,3.1,7.6,6.3,12.1,8.3c1.1,0.5,4.7,2.5,6,1.5C1652.1,1572.8,1652.9,1570.6,1650.7,1570.1z
									M1334.4,1549.8c-1.3-2.4,1-4.5,3.2-3.3C1336.5,1547.7,1335.5,1548.8,1334.4,1549.8z M1339.8,1543.8c-1.9-3.8,1-7.5,5-7.3
									C1343.2,1539,1341.6,1541.5,1339.8,1543.8z M1423,1529.7c-1.6-1.2-13.1-9.7-7.4-9.1c2.3,0.2,3.1,3.2,5,4c2.4,1,4.4-0.1,6.9,0.2
									C1425.9,1526.3,1424.3,1527.9,1423,1529.7z M1395.1,1571.7c0-1.4,0.7-1,2.1,1.2C1396.1,1573.8,1395.4,1573.4,1395.1,1571.7z
									M1456.4,1531.4c-1,0.9-4.6,4.5-5.8,4.5c-2.3-0.1-5-5.3-5.5-7C1448.7,1530.1,1452.5,1530.9,1456.4,1531.4z M1445.2,1538.8
									c0.6-0.1,1.2-0.2,1.8-0.2c-4,3.3-0.7,1.5-3.3,0.7C1444.1,1539,1444.6,1538.8,1445.2,1538.8z M1452.3,1551.3
									c-0.6,0.6-1.3,1.1-1.9,1.7c0.5-2.4-6.9-5-8.6-6.4c1.6-2.8,3.8-2.8,6-2.1c1.7,0.5,2.5,2.1,4.5,2.4c1.9,0.3,4.3-1.9,5.6,0.6
									C1455.7,1548.2,1454,1550,1452.3,1551.3z M1490.3,1546.7c1.8-2.4,0.3-6.1,0.7-8.8c2.3,0.6,4.6,2.2,6.4,3.7
									C1495.7,1543.1,1490.9,1545.9,1490.3,1546.7z M1515.2,1547.3c2.3,1.1-2,3.5-2.8,3.8c-3.5,1.3-5.9-0.5-9.2-1.1
									c-6.1-1.1-9.1,4.8-14.6,3.4c-2.7-0.7-3.8-2.7-7.1-2.2c-2.8,0.4-5.1,2.4-7.4,3.8c-3.7,2.3-3.6,2.9-7,0.1c-2.5-2-3.9-5.7-6.6-7.3
									c1.4-1.7,5.9-1,7.9-0.8c2.3,0.2,5,2.3,6.6,2.3c9.6-0.1-1.6-11.9,2-15.1c0.5,1.1,1.3,1.5,2.4,1.3c-0.1,1.3-0.1,2.7,0,4
									c0.4,1.7,1.8,2.5,2.4,4.1c0.7,2-1.1,2.7,0.9,4.5c1.9,1.7,5.4,0.4,7-1c-3.4,4.8,5.8,1.3,6.2,1.2c2.3-0.5,4.8-0.4,7.2-0.2
									c2.3,0.2,5.7,2,7.7-0.6c1.5-2-1.2-7.1,0.4-10c1.8,1.8,3.9,3.3,6,4.7C1516.3,1543.1,1512.7,1546.1,1515.2,1547.3z M1538.6,1555.4
									c-1.3,1.1-2.6,2.3-3.7,3.5c0.5-2.1-5.7-6.1-6-8.9C1530.4,1551,1538.6,1555.4,1538.6,1555.4z M1547.7,1549.5
									c-1.9,1.6-4.2,2.7-6.4,4c0.7-4.4-9.3-3.3-9.5-6.7c-0.1-1.7,2.2-2,2.7-3.3c0.4-1-1.2-3.9,0.1-4.9c1.2-0.9,4.1,2.6,5.6,3.4
									C1543.5,1543.8,1548.6,1544,1547.7,1549.5z M1558.5,1536.4c-1.9-1.4-3.4,1.9-3.9,2.8c-3.5-3.3-4.8-7.2-5.1-12
									c4.1,4,8.1,7.6,14.3,7.9c-0.8,1.2-1.7,2.4-2.5,3.5C1560.3,1537.8,1559.4,1537.1,1558.5,1536.4z M1572,1552.9
									c-2.8,3.7-5.6,7.5-9.2,10.5c0.3-4.4,3.7-6.5,1.7-11c-1.5-3.4-4.3-4.6-4.3-8.9C1561.6,1544.6,1573.8,1550.4,1572,1552.9z
									M1589.8,1560.8c-0.6-2.3-7.3-4.1-9.3-5.2c18.2-3.6-5.7-9.5-4.4-18.2c4.3,4.8,11.4,7.6,17.1,10.4c2.8,1.3,5.6,2.4,4.8,5.2
									C1597.3,1555.9,1592.1,1559,1589.8,1560.8z M1628.4,1584c0.4-1.4,1.8-6,3.6-6.4c0.5-0.1,4.6,4.1,4.7,4.2
									C1635.7,1582.5,1628.3,1584.4,1628.4,1584z M1614.3,1557.9c-1.5-1.7-4.2-8.5-3-10.3c2.7,2.5,5.9,4.4,8.9,6.5
									C1618,1554.9,1616,1556.4,1614.3,1557.9z"
						/>
						<path
							d="M1160.3,1547.1c-6-2.4-9.1-5.7-13.4-10.1c-3.4-3.5-2.5-2.8-6-0.4c-0.9,0.6-4,4.9-5.3,4.7c-2.5-0.3-1.7-5-5-4.5
									c-1.7,0.3-2.9,3.8-3.7,5.1c-2.6,4.2-5.7,10.3-10.1,12.9c-2.2,1.3-8.7,1.3-5.8,5c2.3,2.9,14-2.6,17.1-2.5c3.5,0,10,3.8,11.3-1.7
									c1-4.3-5.7-5.5-4.8-9.9c2.2,1.9,17.6,16.9,19.1,11.2c0.1-0.5-8.1-12.4-5.8-12.5c1,0,1.7,2.4,2.5,3c2.3,1.6,4.3,3.4,7.4,3.3
									C1159.1,1550.6,1165.1,1549,1160.3,1547.1z"
						/>
						<path
							d="M1121.2,1589.2c-4.2,0-6.8-3-9.8-5.5c-3.8-3.2-7-4.4-12-4.9c-8.6-1-16.3-2.9-23.6,3c-1.8,1.5-3.3,4-5.2,5.4
									c-2.6,1.8-0.8,1.2-4.2,1c-4.6-0.3-6.6,0.2-10,3.9c-1.2,1.3-2,3.1-3.3,4.3c-2.3,2-0.3,1.4-3.2,1.4c-2.7,0-3.3-1.8-6.2-0.9
									c-1.8,0.6-3.1,2.6-4.4,3.9c-2.6,2.4-5.2,5.9-8.8,6.8c-2.8,0.7-2,0.7-4.7-0.8c-1.2-0.6-4-3.5-5.8-3.5c-3.8,0-4.6,3.1-6.8,5.6
									c-2.3,2.5-9.6,5-10.7,7.7c-2.5,5.9,6.2,0.4,7.4,0c3.5-1.2,6.1,0.6,9.2,0.7c2.1,0.1,4.6,0.1,6.7,0c5.8-0.3,2.1-0.2,1.3-4.9
									c1.4,0.8,11.2,4.9,9.5-0.2c4,0.3,10.9,2,13-2.7c1.9-4.3-1.4-8.5,6.5-5.4c1.9,0.8,3.2,2.1,5.4,2.6c2.9,0.6,5.9-0.2,8.8,0.2
									c1.5,0.2,14.1,3.2,10.1-1.8c-2.3-2.9-7.5-1.2-9.3-5.8c-2.9-7.5,3.4-3.4,6.8-2.5c4.4,1.1,12.1,2.4,16.4,0.6c3.5-1.4,3.5-4.6-1-3.9
									c-1.6-3.3,12.9-3.2,1.2-5.6c-7.8-1.6-2.5-5.2,3.4-5c6.4,0.2,9.8,4.1,14.8,7.4c1.7,1.1,6,4.1,8.3,3.1
									C1123,1592.6,1124.3,1589.3,1121.2,1589.2z M1064.9,1602.4c-0.3-0.2-0.7-0.4-1-0.6c2.6-1.8,3-1.6,4.5,1
									C1067.3,1602.9,1066.1,1602.8,1064.9,1602.4z"
						/>
						<path
							d="M1095.3,1636.3c-3.1-1.6-6.3-3.5-9.7-4.4c-3.1-0.8-9.1,0.7-10.8-0.3c-2.1-1.2-1.9-5.5-4.4-6.6c-3.5-1.6-4.1,1.3-5.8,4.3
									c-4-4.7-5.2-11.2-9.1-15.8c-5-6-6.5,3-8.6,7c-3.3,6.3-7.8,12.1-11.2,18.4c-0.8,1.5-3.3,3.8-1,5.3c1.8,1.2,3.4-1.6,4.5-1.8
									c2.5-0.5,9.7,0.5,12.5,1.1c3.1,0.6,8.2,4.4,10.6-0.3c2-4-5-8-6.9-10.8c-1.4-2-3.3-5-4-7.3c-1.1-3.7,0.1-4.5,1.5-8.1
									c3,5.2,5.8,10.3,9.1,15.4c3.8,6.1,0.7,0.3,2,3c0.9,2.1,0.7,4.6,3.1,5.7c2,1,3.7-0.3,5.7-0.1c2.1,0.2,3.8,1.6,5.8,1.7
									c7.7,0.6,2-4.5-0.6-7c4.8-1.6,8.8,1.1,12.9,2.8c1,0.4,2.7,2,4,1.4C1096.2,1639.3,1096.9,1637.1,1095.3,1636.3z"
						/>
						<g>
							<path d="M1018.6,1652.4C1018.6,1652.4,1018.6,1652.4,1018.6,1652.4C1018.6,1652.4,1018.6,1652.4,1018.6,1652.4z" />
							<path
								d="M1032.8,1641.4c1.5,1.2,3.2,0,3.5-1.5c0.3-1.2-3.5-4.3-4.2-5c-4.3-4-7.7-5.7-11.4,0.5c-2.6-3.9-5-9.9-9.3-5.6
										c-4,4-6,11.4-9.4,16.1c-0.6,0.8-4.6,6.8-5.7,6.9c-1.3,0.2-3.3-2.4-5.2-2.1c-3.9,0.6-7.1,6.7-9.8,9c-1.5,1.3-12.3,6-12.6,7.1
										c-1.3,4.7,4,2.5,6.4,2c5.4-1.2,7.4,0,12.5,1.3c1.3,0.3,5.4,1,6.6-0.3c2.2-2.4-0.7-3.2-2.6-4.8c1-0.4,3.3-0.3,4-1
										c2.3-1.9,0.7-3.1,1.3-5.8c3,2.2,10.6,8,14.3,7.9c6.6-0.1,0-3.7-0.3-5c2.1-0.7,5.5-0.9,6.7-2.5c-0.3,0.4,0.4-5,0.4-4.3
										c0-1.7-1.8-5.1-0.6-6.8c3.4-5.2,7,3.4,8.9,5c6.7,5.9,1.7-2.6-0.1-5.3c1.3,1.2,5.2,6.3,7.4,3.9c2.2-2.4-3.2-5.5-4.6-6.8
										c6.4-0.2-1.6-6.7-0.8-7.9C1029.9,1638.1,1031.1,1640,1032.8,1641.4z"
							/>
						</g>
						<path
							d="M1058.7,1661.9c1.9,1.4,10.5,7.9,12.8,5.3c2.4-2.7-6.1-5.9-7.6-7c-3.3-2.4-17.6-20-18.1-7.2c-7.5-2.4-8.9-5.2-14.7,1.8
									c-2.5,3-5,6.1-7.9,8.9c-1.3,1.2-2.5,2.3-3.9,3.4c-2.8,2.1-2.6,1.5-5,1c-4.6-0.9-2.3-2.6-7.2,1.5c-1.7,1.5-5.3,5.2-7.6,5.3
									c-4,0.2-4.2-4-8.9-0.5c-2.8,2-4.4,5.3-7.3,7.3c-1.9,1.4-8,3-9.1,4.8c-2,3.3,0.5,3.4,3.2,2.9c3.7-0.6,4.3-3.1,7.9-2.3
									c2.7,0.6,4.6,2.2,7.6,2.7c7.8,1.1,6,0.2,6.5-5.7c0.7-7.1,8.7-1,13.4,0.1c-2.7,2-4.5,4.9-7.3,6.8c-2.9,1.9-9.6,2.7-11.8,5
									c-6.2,6.5,9.3,0.9,10.5,1.5c7.6,3.9-4.6,6.6-6.6,7.6c-3.2,1.5-4.9,5.6-7.9,7c-3.4,1.6-2.7-1.3-7-2.2c-4.1-0.9-5.6,1.4-8,4.3
									c-2.7,3.3-4.4,3.7-8.5,5.3c-2.1,0.8-8.2,2.8-8.5,5.3c-0.5,4.2,4.4,1,5.8,0.5c3.3-1.3,3.4-1.6,5.4,0.5c1.8,1.9-1.2,2.4,2.7,3
									c7.7,1.1,9.6-9,11.8-14.2c5.1,2.7,3.2,7.5,8,10.6c4.5,2.8,9.9,0.9,14.6,1.1c4.4,0.2,9-0.7,4.9-5.9c-3.4-4.3-9.6-5-8.7-12.2
									c5.9,1,11.2,8.4,17,10.6c2.2,0.8,17.3,2.7,17.6-1.5c0.1-1.3-9.5-6.9-11.3-9.1c-1.7-2.1-5.8-8.5-0.9-9.1c3.7-0.4,19.9,18.7,22.9,12
									c1-2.3-11.2-9.4-13.5-11.2c-2.5-1.9-5.5-4.6-8.7-5.5c-2.9-0.8-6.4,0.8-5-3.6c2.9,2.5,8.3,6.3,12.1,6.9c1.6,0.3,3.8-1.3,3.7-1.3
									c2.2,0.3,3.1,2.1,5,2.9c3.1,1.2,7.8,1.3,8.5-3.1c0.7-4.5-4.6-5.7-3.1-11.2c2,1,18.4,9.6,17.2,3.5c-0.5-2.4-7.3-3.4-9.2-4.3
									c-4.4-1.8-8.6-4-12.7-6.6c4.5-0.5,5.1-4.1,3.7-7.6c-0.7-1.7-2.9-3.1-3.1-5.1c-0.2-1.7,1.2-2.4,1.1-4c-0.1-1.7-2.9-8.6,2.7-3
									c3.1,3.1,2.2,9.6,4.7,13.3c2.8,4.2,5.7,6.3,10.4,6.9c3.2,0.4,11.6,2.6,8-2.9c-0.8-1.2-4.6-2-5.7-2.8c-1.3-0.9-3.1-3-4.5-4.3
									c-3.9-3.7-6.2-8.8-9.8-12.7C1050.3,1654.5,1057.9,1661.3,1058.7,1661.9z M1026.5,1687.8c-0.3,0.6-0.6,1.3-0.8,1.9
									c-2.6-1.7-5.7-3.5-7.7-5.9c-1.3-1.5,0.5-2.8-2.8-3.1c1.6-2.4-2.1-3.9-1.7-4.9c0.9-3.1,4.6-0.2,6.5,0.6c2.7,1.2,7,3.5,10.1,2.8
									c3.6-0.7,3.4-2.3,7.8-1.6C1034,1681,1030.6,1684.7,1026.5,1687.8z"
						/>
						<path
							d="M1018.9,1766.1c-4.3-5.1-20-22.9-27.6-20.3c-3.5,1.2-7.1,7.5-10.1,10c-3.2,2.7-11.3,6.4-12.9,10.1
									c-2-2.9-6.7-10.4-10.9-7.3c-1.1,0.8-3.2,6.6-3.9,7.9c-1.4,2.6-3,5.1-4.5,7.6c-3.1,5.2-5.5,12.2-10.2,16.2
									c-2.2,1.9-10.9,3.9-10,6.9c1.2,3.7,9.2-1.7,10.9-3c4.9-3.6,8-4.2,14.3-2.6c3.2,0.8,10.6,3.6,11.1-1.8c0.3-3.7-5-7-0.5-10.7
									c3.2,2.4,2.8,7.7,7.3,9c1,0.3,3.5,1.2,3.8-0.7c0.4-2.1-2.1-1.4-3-2.4c-2.1-2.2-3.9-4.9-5.4-7.7c-1.9-3.5-2-6.4-3.4-10.2
									c3.9,6,9.6,12.2,15.3,16.5c2.2,1.7,15.4,9.5,17,4.6c0.9-2.8-11.2-5.3-13.6-7c-4.1-2.9-3.5-3.2-2-7.7c1.1-3.4,2-6.8,2.4-10.3
									c1.9,1.5,2.6,3.2,4.9,4c1.6,0.6,3.5,0,5.2,0.7c3.8,1.5,6.2,5.1,10.3,6.2C1006.6,1774.9,1022.8,1770.7,1018.9,1766.1z"
						/>
						<path
							d="M1040,1742.3c-6-2.7-11.6-5.6-16.4-10.2c-4-3.8-6.6-5.8-10.9-1c-3.6,3.9-5.8,6.7-10.2,9.9c-1.5,1.1-6.6,4.6-6.6,6.7
									c0,4.1,2.5,1.1,3.8,1.1c1-1.5,2.2-1.3,3.6,0.6c1.7,1,3.5,2.1,5.2,3.1c3.5,2.1,7,4.2,10.7,5.8c4.5,2,14.9,5.4,18-0.7
									c4.7-9.2-23.2-12.1-15.6-21.9c3.3,3.3,7.6,5.6,11.7,7.8c1.6,0.8,4.6,3.1,6.3,2.3C1040.9,1745.1,1041.8,1743.1,1040,1742.3z
									M1015.4,1738.4c-1.1-0.3-1.1-0.7,0-1.4C1015.4,1737.5,1015.4,1738,1015.4,1738.4z"
						/>
						<path
							d="M1007.5,1811.8c-2.7-1-5.7-1.6-8.1-3.4c-2.9-2.1-2.9-4.8-4.9-7.4c-4.8-6.5-5.7,2-11.4,2c-4.7,0-4.9-9.1-10.1-7.3
									c-4.4,1.5-9.5,14.2-11.7,17.9c-3.2-5.5-5.4-3.8-9.2-0.1c-3-3.9-4.2-12-9.3-8.2c-5.6,4.1-10.1,12.2-14.8,17.3
									c-2.9-4.2-5.9,0.3-8.4,2.8c-1.1,1.1-9.3,7.5-9.2,8.5c0.2,2.5,1.2,1.9,3,2.3c0.7-0.4,1.4-0.9,2.2-1.3c1.2,0.2,2.4,0.4,3.6,0.5
									c2,0,4,0.3,6.1-0.1c0.1,0,4.6-2,4.8-2c2.1,0.5,4.5,8.4,7.7,4.9c1.7-1.9-4.1-6.1-3.7-9c0.5-3.3,1.6-2.5,3.2-2.2
									c2.4,0.4-0.4,2.8,3.9,2.7c1.3,0,2.4-2,3.7-1.9c-0.3,0,3.8,3.1,4.4,3.3c3.1,1.2,6.6,2.3,9.7-0.1c3.4-2.6,1.3-3,0.2-6.2
									c-0.6-1.6-2.3-5.3,0.8-5.5c1,2,5,8.7,7.7,8.9c3.1,0.2,3.6-2.2,1.2-4.1c3.3-1.3,4.1-4.9,8.1-6c-2,2.2-5.8,6.4-1.5,7.2
									c1.2,0.2,2.9-2.4,4.8-2.3c1,0.1,2.2,2.3,3.8,2.5c10,1.2-1.7-8.5-0.8-10.8c1.7-4.2,4.8,2.2,6.8,2.5c5.4,0.9,2.3-3.2,5.1-4.8
									c2.5-1.4,6.5,1.9,9,2.4c1.3,0.3,3.5,2,4.4,0.2C1009.2,1813.9,1008.8,1812.3,1007.5,1811.8z"
						/>
						<path
							d="M940,1845.8c-7.3,2.4-13.8-1.1-21-1.4c-5.8-0.3-9.7,3-14.3,6c-2.7,1.8-4.7,3.2-7.9,3.6c-2.6,0.3-4.5-0.6-6.9,0.7
									c-2.9,1.6-4.6,5.4-7.2,7.4c-2.7,2.1-5.9,2.2-8.5,3.7c-1.3,0.8-4,0-3.2,2.7c0.7,2.4,4.3,1.2,5.9,0.8c1.3-0.3,2.6-0.7,3.9-1.4
									c1.6-1.6,3.3-1.8,5.2-0.6c3.6-0.1,6.2,0.6,9.8-0.6c6.2-2.1,9.6-4.6,16.4-4.9c1.9-0.1,6,0.1,6.1-2.7c0-1.3-0.9-3-2.3-2.9
									c6.2-3.1,8.7-5.5,16.4-5.7c2.2,0,7.9,0.6,9.4-1.4C942.5,1848.1,942.6,1844.9,940,1845.8z M896.5,1860.6c-1.1,0.1-1.9-0.3-2.5-1.2
									C901.7,1857.2,898.8,1862.2,896.5,1860.6z"
						/>
						<path
							d="M1490.3,1448.3c-4.3-2.3-7.5-5.2-10.4-9.1c-3.3-4.5-3.6-6.5-8.6-4.2c-4.9,2.3-5.5,6.7-9.8,1.6c-1.9-2.3-4-7.8-6.8-9.1
									c-3.6-1.7-6.3,0.8-8.7,3.3c-1.4,1.5-5.4,9.2-7.2,9.4c-3.4,0.4-3.1-3.4-4-5.4c-1.7-3.8-4.4-2.8-6.7-5.7c2.6,1.5,20.9,6.8,15.1,0.2
									c-1.7-2-10.2-2.6-13.3-4.1c-3.6-1.8-5.4-2.8-9.2-0.8c-1.6,0.9-3.2,3.5-4.8,4.1c-2.6,1-0.9-0.4-3.6-0.7c-4.4-0.5-5.6,0.6-9,3.9
									c-3,2.9-6,5.7-9.4,8.2c-2.1,1.5-11.1,5.7-10.6,8.2c0.8,4.5,6.7-1.3,8.3-1.7c3.4-0.8,9.1,4.4,12.3,2.3c3.3-2.1,0-4.3-0.1-6.8
									c0-2.4,4.3-8.7,6.7-9.1c6.2-1-0.6,5.1-0.7,6.9c-0.3,4.6,4.5,3,7.2,2.9c2.1,0,2.2-0.7,4.4-0.1c1.6,0.5,3.7,2,5.1,2.9
									c2.3,1.5,3.9,2.4,1.3,6.1c-5.6-3.7-9.6-7.3-14.3-1.3c-1.8,2.3-2.8,5.2-5.5,6.8c-4.2,2.6-3.4,2.1-7.8-0.5c-5.7-3.2-7.7,1.3-12.7,5
									c-1.3,0.9-12.4,9-6.8,9.4c2.5,0.2,2.8-3,4.9-3.2c-0.6,0.1,3.8,1.8,3.6,1.8c1.9,0.5,4-0.2,5.9-0.1c2.8,0.2,5.4,1.4,8.2,1.9
									c3.4,0.5,11.1,0.3,5.8-3.6c-2.1-1.6-7.4-0.7-6.7-5c3.3,1.9,8.1,5.2,11.9,5.8c1.5,0.2,6.7-0.8,2.7-3.1c3.9,1.4,16.5,6.8,15.2-1.7
									c-0.6-4.2-9.3-6.6-8.3-9.5c3.2,2.2,18.5,13.6,22.6,10.9c2.9-2-0.1-3.1-1.6-4c-4-2.4-8.5-4.2-12.6-6.7c0.8-0.9,1.5-1.9,2-3
									c0.8-2.3,1.9-2.6,3.1-0.8c0.5-0.1,4.5-0.3,4.7-0.2c1.3,0.3,2.5,1.9,3.6,2.1c2.1,0.3,4-0.9,6.6-0.4c3.9,0.8,5.3,3.6,9.3,4.3
									c2.1,0.4,4.4-0.5,6.1-0.2c1.6,0.3,2.2,1.9,3.8,2.2c0.4,0.1,2.8-1,3.3-1.1c2-0.2,2.7,1.8,4.2-0.9c2.3-4-4-7.1-4.8-9.8
									c-2.2-7.4,6-0.6,7.9,0.9c1,0.8,4.8,4.1,6.2,4.1C1489.8,1451.7,1493.3,1449.9,1490.3,1448.3z M1417.1,1439.6
									c-1.2,0.7-2.3,0.5-3.3-0.5C1417.7,1434.6,1414.4,1440.3,1417.1,1439.6z M1459.7,1449.6c-2.7,2.6-2.5-5.3-2.7-6.4
									c-0.4-2.7-3.3-7-2.3-9.5c2.3,4.2,5.6,7.4,9.2,10.5C1462.3,1445.8,1460.8,1447.6,1459.7,1449.6z"
						/>
						<path
							d="M1459,1414.8c-5-2.1-12-8-17.3-8.7c-0.4,0.2-0.8,0.4-1.2,0.6c-1,2.1-2.4,2.4-4.1,1c-2.3-0.3-4.3-2.3-6.7-2.7
									c-5.7-0.8-5.7,2.6-8.7,6.7c-3.4,4.5-7.9,8.3-13.1,10.6c-2.5,1.1-11.1,1.6-11.7,3.9c-1.4,5.2,11,0.7,12.4,0.1c5-2.1,9.4-5.7,14-8.4
									c2.2-1.3,4.1-2.7,6-4.3c3.1-2.7,2.8-3.4,6.7-1.9c-1.7,1.7-11.1,6.6-10.3,9.3c0.7,2.4,7.4-0.4,9.5-0.3c3.3,0.1,12.1,4.4,14.6,1.8
									c1.6-1.7-5.7-5.8-3.8-10.2c2.9,1.7,7.2,4.9,10.5,5.4C1457.1,1417.9,1463.1,1416.5,1459,1414.8z M1437.7,1417.3
									c0.4-0.3,0.4-0.1,0,0.7C1436.8,1417.9,1436.9,1417.7,1437.7,1417.3z"
						/>
						<path
							d="M1402.5,1349.8c-6-1.9-12.3-4.6-18-7.3c-2.8-1.3-8.4-8.3-11-8.4c-2.4-0.1-3.3,2-4.7,3.4c-1.3,1.4-2.6,2.6-4.1,3.8
									c-1.5,2.3-2.8,2.3-3.9-0.2c-2.3-0.4-1.6-2.2-4.4-1.3c-2.1,0.7-4.6,5.6-6,7.3c-4,4.8-7.5,6.2-12.8,8.7c-4.9,2.4-4.6,5,1.1,3.7
									c2.4-0.5,4.4-2,6.7-2.6c1.5-1.9,3.2-1.9,4.9-0.1c1.9,0.9,3.7,1.8,5.6,2.6c2.2,0.8,5,2.4,7.4,1c3-1.7,1.7-5.1,3.9-7.2
									c0.7,0.6,2.2,2.5,3.1,2.6c2.6,0.4,1.6-1.3,2.7-1.4c1.6-0.2,4.2,1.3,6.4,1.3c7.7,0.1-4.3-6.7-3.7-10.5c0.6-4,2.3-1.1,4.6-0.2
									c3.5,1.3,7.2,2.7,10.7,4.1c2.5,1,7.6,4.9,10.3,4.5C1403.2,1353.4,1405.6,1350.8,1402.5,1349.8z"
						/>
						<path
							d="M1383.2,1332.1c-3.5-3-6.1-6.9-9.6-10c-1.5-1.3-3.6-4.1-5.7-4.5c-3.2-0.6-3.3,1.5-6,2.3c-3.2,1-1.5,1-4.9-1.4
									c-1.7-1.2-3-2.2-5.2-1.9c-4.1,0.5-6.1,4.9-8.9,7.1c-3.8,2.9-6.3,0.8-10.7,2.1c-3.1,1-7.1,3.9-9.4,6.1c-2.9,2.8-3.9,4.2,1,4.8
									c3.4,0.4,8.7,0.4,12.2,0.3c3.3-0.1,4.4,0.2,7.7,0.7c2.6,0.4,9.8-1.8,11-4.4c1-2.1-3.1-4.7,0.3-6.6c2.8-1.6,1.9,1.7,2.7,3.4
									c2,4.2,2.4,3.1,6.3,2.7c1.2-0.1,2.6-0.7,3.9-0.6c1.2,0.2,1.2,1.7,2.1,1.9c2.8,0.5,5.9,0.2,8.8,0.6
									C1379.8,1334.8,1386.8,1335.3,1383.2,1332.1z"
						/>
						<g>
							<path
								d="M1375.8,1326.7c6.2-2.3,12,0.3,17.8,2.7c4.5,1.9,10.1,2.5,4.8-2.3c-3.5-3.1-12.5-5.3-17.1-5.3c-1.9,0-6.4-0.2-7.6,1.9
										C1372.9,1325.1,1373.9,1327.4,1375.8,1326.7z"
							/>
						</g>
						<path
							d="M1401.8,1313.8c-4.5-1.4-7.1-5.5-11.2-7.2c-4.3-1.7-5.7,1.3-8.6,4.1c-1.3,1.3-8.5,5.4-5,7.5c1.8,1.1,4.6-1.4,6.8-1.3
									c1.8,0.1,7,4,8.3,3.4c3.4-1.7-1.7-3.9-0.5-5.8c1.4-2.3,5,1.4,6.6,2C1401.7,1318.1,1405.4,1315,1401.8,1313.8z"
						/>
						<path
							d="M1396.5,1303.5c-1.7-3-3.9-6.4-6.7-8.4c-3.2-2.2-6.9-1.4-10.1-3.1c-2.4-1.3-3.6-5.9-6-6.4c-3.2-0.7-7.6,6.4-9.6,8.7
									c-2.3-2.5-3-7.2-7.1-7.4c-3.2-0.2-5.3,3.2-7.6,4.8c-7.3,5.3-16.3,5.9-22.3,13.4c-1.6,2-5.5,6.6-2.7,8.7c1.1,0.9,8.1-1.7,9.6-2.2
									c4-1.2,4.9-0.4,8.7-0.3c1,0,2.2-1.1,2.7-1c1.5,0.1,5.1,1.7,6.4,1.5c4.8-0.6,1.3-3.8,1.3-6.8c0-2.7,2.2-5.7,3-7.7
									c0.4-1,0.9-4.6,2.4-4.5c0.4,0.1,2.5,3.6,2.5,3.9c0.3,2.8-5.8,7.5-4.3,10.8c1.4,3.1,13.1,4.8,16.7,4.1c3.7-0.7,3.7-2.1,4.4-5.2
									c0.8-3.4,1-6.8,1.5-10.2c4.8,1.5,7.9,1.3,11.2,5.6c0.9,1.1,2.3,4.5,3.8,4.6C1395.8,1306.5,1397.4,1305.2,1396.5,1303.5z"
						/>
						<path
							d="M1448.5,1306.1c-4.2-0.7-7.5-5.5-11.4-5.8c-4.3-0.4-8.4,5.7-11.4,8.3c-1.7,1.5-10,5.9-5.9,9c1.3,1,11.4-2.8,13.5-2.7
									c-2.3,1.3-3.9,3.9-5.9,5.7c-1,0.8-5.5,3.2-6,4.3c-2.3,5,6.5,6.2,10.1,5.1c3.4-1,6.1-4.7,8.2-7.5c2.9-3.9,2.1-4.2-2-7
									c3.4,0.3-4.4-7.4,1-9.1c1.6-0.5,6.1,4.3,8.7,3.7C1449,1309.6,1451.7,1306.6,1448.5,1306.1z"
						/>
						<path
							d="M1465.3,1291.1c-2.7-0.7-3.2-4-5.5-5c-3.9-1.7-6.3,1.6-8.6,4.1c-1.6,1.7-7.5,6.7-7.2,9.4c0.2,1.6,5.4,4.2,6.9,4.5
									c9.4,1.6,4.4-7,7.7-10.2c2-2,2.8,0.8,5,0.8C1465,1294.6,1469.3,1292.1,1465.3,1291.1z"
						/>
						<path
							d="M1483.5,1264.3c-8.6-2.6-17.4-0.5-25.7-4.7c-3.8-1.9-5.7-4.7-9.7-2.2c-3,1.8-6.5,7.8-9,10.3c-1.9-3.3-5.5-8.6-9.4-4.5
									c-1.7-4.3-5.3-10-10.2-7.4c-3.8,2-6.7,7-9.6,10.1c-2,2.2-10.9,8.9-11,11.7c-0.1,5.3,4.2,0.5,6.2-0.7c2.7-1.6,5.6-1,8.5-1.1
									c3.4-0.1,7.4-1.7,10.5-0.6c3.5,1.3,4.9,3.7,9.3,4.1c-4.1,2.6-19.6,16.6-16.1,20.5c0.2,0.3,5.5,1,5.9,1c2.5,0.1,4-0.5,6.1-1.9
									c4.4-2.9,7-6,6.9-11.2c0.9,1.5,9.7,10.9,9.4,4.4c-0.1-1.6-4.1-4-4.9-5c-1.9-2.3-2.5-5.6-4.5-7.7c4.1,0.1,1.9-0.9,2.5-2.7
									c-1.2-0.8-1.5-1.8-0.8-3c0.7-0.4,1.1-1,1.4-1.8c1.9,4.3,4.4,4.6,8.4,5.6c4,1,7.3,3.7,11.4,4.2c2,0.2,9.4-0.2,7.8-3.5
									c-0.6-1.4-10.1,1.2-7.6-3.2c0.3-0.6,6.6,2.3,8.4-0.1c2.4-3.3-1.6-4.3-4-5c-4-1.3-12.2-1.3-9.7-7.4c6.8,4.4,13.5,3.6,21,4.1
									c1.5,0.1,7.4,2.5,8.5,1.6C1485,1267.1,1485.6,1265,1483.5,1264.3z M1424.8,1269.4c-0.5-1.9-2.6-5.7-0.4-7.3c0.6,1,1.7,3.5,3,3.9
									C1426.5,1267.1,1425.6,1268.2,1424.8,1269.4z"
						/>
						<path
							d="M1533.2,1185c-8.2-0.2-14.6-5.7-19.6-11.6c-1.9-2.2-4.2-6.1-7.6-4.7c-2.7,1.1-2.6,6.9-4.9,7.6c-3.6,1.1-8.7-6.6-13.4-5.5
									c-3.3,0.8-6.1,5.3-8.4,7.5c-1.7,1.6-8.1,5.5-7.6,8c0.7,3.4,6.4-0.5,8.3-1.2c-2.3,2.9-4.2,5.9-6.8,8.5c2-2.8-10.9-9.1-13-11.1
									c-1.7-1.5-4.5-5.4-7.2-4.8c-2.3,0.5-2.8,3.5-4.8,4c-1.5,0.4-6.3-1.2-8.2-1.3c-3.6-0.3-5.6-1-8.8-2.6c-6.6-3.4-9.1-0.3-13.2,5.1
									c-10.9-11-13.3,0.6-18.9,9.8c-10.6-7-15.1,0.2-21.2,8.5c-1.8-1.4-4.2-3.2-6.6-2.8c-2.5,0.4-2.5,4-4.9,4.4c-0.1,0-3.6-2.1-4.1-2.2
									c-2.2-0.3-4.4,1.1-6.3,2c-3.1,1.5-21.7,7.9-18.5,13.3c1.4,2.4,8.1-1.4,11.1-0.5c1.2,0.4,4.4,3.4,5.4,3.4c2.9,0,0.5-0.8,2.4-1.8
									c1.1-0.6,1-2.4,3.2-2.8c1.3-0.2,3.2,1.1,4.4,1.5c2.9,0.8,9.4,3.4,9.4-0.7c0-1.2-4.9-4.2-5.1-6.5c-0.3-2.6,2.6-5.7,5.3-5.4
									c2.7,0.3,17.7,13.7,20.5,8.1c1.1-2.2-3.1-4.2-2.2-6.9c1.4-4.1,4.7-0.8,3.8-5.3c-0.1-0.6-4.5-1.8-2.2-3.9c1.6-1.5,4.7,1.6,5.9,2.2
									c1.5,0.8,2.7,1.4,4.3,1.7c0.9,0.2,3.1-0.8,3.7-0.2c1.1,1-0.4,2.2,2.4,3.1c-5.4,5.8-8.4,9.2-15.9,12.8c-2.9,1.4-19.6,9.8-9.8,9.9
									c1.9,0,3.8-1.4,5.5-2.2c4-1.7,5.9-1.4,10-0.9c7.3,0.9,12.2-4.2,20.2-2c-5.4,3-11.1,5.6-16.5,8.4c-1.4,0.7-8,2.9-8.2,4.5
									c-0.4,2.3,2.4,1.2,2.7,2.3c1,3.8-4.2,7.1,2.9,5.2c5.6-1.5,10.5-6.9,14.8-10.4c0.8-0.7,2.3-3,3.6-3c4.5,0.2,1.2,2.9,0.7,3.8
									c-2.7,5.1-8.7,9.4-10.6,15.1c-1.1,3.2,0.1,9.7,4.8,8.9c4.7-0.8,4.1-7.6,5-10c2.2,1.7,8.1,6.9,6.3,0.7c-1-3.3-8.6-5.9-1.3-8.1
									c3.3-1,7.5,3.4,10.3,1c2.6-2.2,0-10.6-1.5-12.4c4.3-0.4,7.7,5.9,10.1,9.3c-1.9,0.2-4.4,5.7-5.7,7.2c-2.3,2.5-5,4.6-7.6,6.8
									c-3.6,3.1-6.2,7.9,0.8,6.1c4.9-1.3,8.8-1.6,13.9-2.1c3.1-0.3,4.3,3,4.2-2.3c-0.1-3.1-4-3.2-2.6-7.1c2.1,3,11.5,13.3,15.2,10.7
									c2.1-1.4-0.8-1.9-0.2-2.7c0.6-1,2-2.3,3.3-3c3.5-2,6.3-1.5,9.9-0.1c2.5,1,6.1,3.8,9,2.3c3.5-1.9,1.7-3.8-0.9-5.6
									c-7.2-5-9.9-5.5-11.2-14.9c3.5,2.7,10.3,10.8,15.6,10.1c8.2-1.1-3.9-5.9-6.1-7.2c3.9-2.1,6.3,0.2,8.3-4.3c1.6-3.5,1-7.7,2.5-11.3
									c2.5,2,11.8,11.1,15.3,7.8c4.1-3.8-5.8-5.3-8.1-6.8c-2.3-1.4-3.9-3.6-6-5.2c-3.1-2.2-3.3-1.2-6.6-1.4c-0.6,1.3-1.2,1.7-1.8,1.1
									c-1.5-1-3.1-1.9-4.8-2.7c-3.2-1.8-6.3-4.3-9.6-5.8c2.3-3.3,6.2-3,9.4-4.8c3.9-2.1,5.7-6.6,8-10.2c2.7,2.7,4.8,6.1,7.9,8.4
									c2.2,1.6,9.1,4.8,11.6,2.2c2.7-2.9-1.2-2.9-2.8-3.4c-5-1.8-7.1-2.2-11-6.5c2.8,1.6,6.1-0.4,9.6,0.7c1.9,0.6,11.7,4.9,12.1,0.1
									c0.1-1.6-3.3-3.7-3.8-6.3c-0.6-3.2-2-7.4-2.3-10.5c3.5,5.3,9.3,10.2,15.1,12.8c1.9,0.9,7.5,3.3,9.6,2.3
									C1534.7,1188.3,1536.5,1185.1,1533.2,1185z M1426,1218c-2.2-0.7-2.8-0.3-4.1-0.8c-1.2-0.5-3.1-2-4.1-2.8c-2.2-1.5-6.1-3.3-6-6.4
									c0.3-7.1,14.3,6.5,17.4,8C1428,1216.6,1427,1217.2,1426,1218z M1471.5,1210.3c3.2,2.2,6.9,3.6,10.2,5.6
									c-4.7,4.5-10.6,7.1-14.8,11.8c-4.6,5.1-9.7,10.2-14.1,15.5c-5-6.1-8.9-12.8-14.1-18.7c5-1.9,8.6-4,14-4.8c5.4-0.8,8.2,2,13,2.1
									c7.8,0.1,2.1-8.7,4-13C1470.3,1209.1,1470.9,1209.6,1471.5,1210.3z M1450.2,1216c-4,1.6-6.2,1.3-3.8-3.2
									C1447.4,1214.3,1448.7,1215.4,1450.2,1216z M1448.1,1209.5c1.8,1.7,3.7,3.1,5.9,4.2C1451.3,1213.7,1448.9,1212.1,1448.1,1209.5z
									M1463.3,1206.1c-1.4,1.4-0.6,5.1-4,5.6c-1.3,0.2-5.1-2.3-6.1-3c-3.8-2.7-5.9-8.2-10.9-4.1c-2.7,2.2-4.5,10-8.7,9.8
									c-4.2-0.2-12.4-8.4-15.6-11.2c5.3-3.1-1.8-4.3-3.8-7.1c-1.4-2-2.8-4.2-0.6-4.4c0.7-0.1-3.7-6.9,0.8-6.3
									c5.9,0.8,7.8,13.5,14.4,14.4c1.8,0.2,2-0.6,3.1-0.9c-0.1,0,2.7-0.6,2.9-0.7c1.9-1.5,3.2-3.2,2.9-6c-0.3-2.6-7.9-8-6.9-9.7
									c4.7,3.2,10.8,1,15.9,3c-1.7,2.2-9.7,10.6-8.6,13.7c1.7,4.7,7.9-0.8,9.7-1.3c0.5-0.4,0.9-0.9,1.4-1.3c1.2,0.2,2.3,0.7,3.2,1.6
									c2.1,0.1,3.4,1.5,5.2-0.9c2.2-3-1.5-8.3-0.4-11.8c2.1,2.1,12.4,11.1,15.4,8.7C1469.3,1197.9,1466.9,1202.5,1463.3,1206.1z
									M1492.3,1187.2c-0.1,0.6-0.1,1.1-0.2,1.7c-1.7-1.9-4.2-5.5-6.9-6.2c1.8-1.1,5-5.2,6.7-5.6c2.1-0.5,4.2,1.3,6.7,2.4
									C1496.5,1182.1,1494.1,1184.4,1492.3,1187.2z"
						/>
						<path
							d="M1478,1167.3c-5.8-2-13.6-0.4-17.9-4.3c-2.5-2.3-1.6-3.8-5.2-1.7c-2.8,1.7-5.3,6.6-7.6,9c-3.8-4.5-9.3-5.6-13.9-8.8
									c-3-2.1-5.8-9.7-9.3-9.6c-1.3,0-4.9,5.3-6.1,6.3c-1.9,1.6-4.1,2.8-6,4.3c-4.2,3.1-7.3,7.1-11.8,9.9c-3,1.8-6.3,3.3-0.7,4.3
									c2.8,0.5,3-0.8,5.5-1.6c3.4-1,5.3,0.7,8.5,0.6c3.1,0,12.1-3.3,13.6-5.6c0.7-1-0.6-1.7-0.3-2.7c0.5-2.3,0.8-3.1,0.8-5.7
									c4.1,4.5,12.4,5.2,15.7,10.1c1.5,2.2,0.7,5.9,4.6,5.7c4.7-0.2,1-5.3,8-4.6c2.4,0.2,6.8,4.5,9,1.2c1.7-2.5-1.1-3.2-2.3-4.7
									c3.9,0.5,9.3,1.5,13.1,1.5c1.1,0,2.6,1.3,3.4-0.3C1479.7,1169.3,1479.4,1167.8,1478,1167.3z"
						/>
						<path
							d="M1542.5,1154.8c-5.8-2.9-10.2-5.7-14.7-10.3c-2.7-2.8-8-9.6-12.3-8.1c-4.8,1.7-5.8,14.7-9.8,15.6
									c-4.3,0.9-7.9-9.6-12.8-7.8c-3.2,1.2-6.6,11.8-9.2,14.5c-1.7,1.8-12.8,9.4-6.9,10.8c0.7,0.2,2.9-1.5,3.6-1.7
									c2.8-1.2,6-1.3,8.8-2.6c5.7-2.5,7-8.6,8.9-13.9c1.6,1.4,3.2,2.9,5.1,4c-2,2.2-6,4.1-2.1,6.2c1.7,1,7.7-1,9.2-1.4
									c2.3-0.6,7.9-1.4,9.2-3.1c1.7-2.1,0.4-11,0.6-13.8c4.7,4.3,8.4,9.1,14.1,12.2c1.4,0.8,5.3,3.5,6.9,3
									C1542.5,1157.9,1545.2,1156.1,1542.5,1154.8z"
						/>
						<path
							d="M1523.8,1120.6c-6.8-2-12.7-6.7-16.8-12.3c-4.2-5.7-5.3-7.2-11.6-2.7c-5.7,4-11.7,7.6-16.9,12.2c-4.7,4.1-5.4,8,1,6.3
									c-3.4,2.1-7.8,9.3-8.9,13c-2.9-2.5-8.3-8.6-12.7-7.9c-4.2,0.7-5.3,5.7-7,8.9c-1,1.8-2.6,5.4-4.4,6.6c-0.9,2.6-2.2,2.7-3.9,0.5
									c-1.5-1.1-3-2.3-4.5-3.5c-3.1-2.5-10.9-11.5-15.3-8.7c-0.3-2.3-1.3-2.4-2.3-4.2c5.9-1.7,9.4,0.7,14.4-4.3c1.9-1.9,6-9.7,9.4-8
									c4.2,2.1-1.9,7.8-2.2,10.3c-0.5,3.7,6.1,6.3,9.4,3.8c1-0.8,6.8-10.6,6.9-11.6c0.1-2.8-1.9-3.1-4.1-4.5c-0.7-0.4-5.1-2.6-5.2-3.4
									c-0.1-0.5,2.1-3.3,2.7-3.3c0.9,0,3,3.6,3.6,4.3c2.6,2.7,5.6,4.7,8.9,6.7c1.2,0.7,7.9,5.5,7.9,1.4c0-2.8-4.7-4.1-6.8-5.3
									c4.4-2.5,16.5,5,8.6-3.1c-1.7-1.8-3.1-4.1-5-5.7c-1.2-1.1-5.3-2.2-5.4-4.2c5,3.4,10.3,7.9,16.4,8.9c7.2,1.2,5.1-2.5-0.2-4.3
									c-6-2.1-10.3-7.3-15.9-9.3c-5.3-2-7.7,2.2-9.7,6.9c-2.5-2.1-4.6,0.2-6.3,1.9c-0.4,0.6-0.8,1.1-1.3,1.7c-0.4,1.8-1.4,2.2-2.9,1.1
									c-5,1.8-5.7,4.5-9.8,8c-3.6,3-9.2,4.5-13.3,7.1c-2.4,1.5-4.9,3-7.4,4.4c-1.6,0.9-8.3,6.1-9.9,5.9c-4.8-0.8-2.3-4.7-7.4-1.1
									c-2.4,1.7-7.4,9.4-11,8.9c-3.3-0.5-4.1-7.3-8.5-7.6c-4-0.3-8.5,6.8-11,9.5c-3.1,3.4-5,7.6-9.4,9.1c-2.3,0.8-4.3,0.4-6.7,1.7
									c-2,1.1-4.1,3.1-5.9,4.5c-1.4,1-5.7,3.1-6.4,4.7c-1.5,3.5-0.1,2.5,2,3.5c0.1,0,4,1,4.8,1.2c2.7,0.7,4.1-0.1,6.7-0.8
									c2.1-0.6,4.6-0.6,6.7-1.3c2-0.7,3.4-2.6,5.3-3.2c4.7-1.7,5.4,2.3,9.7,2c5.6-0.3,4-6.8,5.1-10.7c0.6-2,1.9-2.8,2.1-5
									c0.3-2.7-1.8-4.3,0.2-7c3,3.3,11,11.3,16,10c5.5-1.5,6.2-12.3,4.3-16.3c2.1,2,3.7,4.6,6.2,6.1c1.5,0.9,4.6,1.1,5.6,2.1
									c-3.7,3.9-9.3,4.8-13.6,7.8c-4.2,3-7.8,6.9-12.1,9.9c-3,2.1-10.4,5.2-3.6,6.8c2.1,0.5,9.2-1.6,11.4-2.6c3.5-1.6,5.3-7.3,8.5-7.8
									c2.9-0.5,3.6,4,6.8,3.2c1.8-0.5,5.7-6.4,7-7.8c4-4.7,8-10.2,10.4-15.9c5,4.2,10.2,8.4,15.8,11.9c-2,2.1-12.6,9.4-5.2,9.8
									c1.5,0.1,5.5-3.4,7-4.1c2.4-1,4.9-1.1,7.4-1.2c3.4-0.1,13.4-0.9,11.5-7.2c-0.5-1.5-2.9-2-3.5-4c-0.3-1.3,2.6-4.8-0.3-5.1
									c1-0.5,0.5-3.4,0.3-4.3c3.9,1.7,7.5,5.3,10.6,8.1c1.9,1.7,7.6,9.2,10.3,8.7c1.7-0.3,1.5-1.5,1.6-2.6c-0.4-1.1-1.1-1.9-2.2-2.4
									c0.4-0.8,0.7-1.5,1.1-2.3c0.5-2.6,2.6-4.3,3.4-6.7c0.7-2.1-0.1-4.5,0.7-6.5c2,1.3,22.8,11.8,19.7,4.3c-0.5-1.1-9.6-3.5-11.1-4.3
									c-3.4-1.7-8.3-6.4-12.1-6.5c4.8-1.4,9.8-3.1,14.6-1c1.7,0.7,7.8,6.8,9.6,5.5c1.7-1.3-0.1-8.4-1-9.9c-2.2-3.9-7.2-5.3-3.1-10.6
									c4,5.3,8.3,10.6,14.1,14c1.3,0.8,6.9,4.3,8.4,3C1525.2,1123.4,1525.9,1121.2,1523.8,1120.6z M1418,1137c-0.6-0.1-1.1,0-1.6,0.4
									c-0.3,0,0.6-4.3,1.7-4.7C1421.8,1131.4,1418.9,1136.1,1418,1137z"
						/>
						<path
							d="M1455.8,1099.8c-4-2.4-6.2-10.4-11-10c-2.1,0.1-6.8,4.5-8.9,5.5c-2.3,1.2-4.7,2.2-7.1,3c-6.7,2.4-13,3.8-19,7.8
									c-4,2.7-7.6,5-1.8,6.3c2.2,0.5,6.1-0.7,8.5-0.8c3.6-0.1,7.1,0.4,10.7,0.5c6.3,0.2,11.3-2.2,14.1-8.2c1-2.2,0.8-5.2,2.1-7.2
									c1.9-2.9,1.1-2.5,3.8-0.7c2.1,1.5,3.5,7,6.7,7.2C1455.4,1103.3,1458.6,1101.5,1455.8,1099.8z M1420.5,1107.5
									c-0.6,0-1.1-0.1-1.7-0.1C1420.8,1106.2,1421.3,1106.2,1420.5,1107.5z"
						/>
						<path
							d="M1489.3,1087.3c-4.7-3.1-14.4-14.8-20.1-10.4c-1.4,1.1-2.4,4.2-3.9,5.7c-1.6,1.6-3.4,2.8-5.2,4c-2.1,1.5-8.4,4.8-7.2,7.8
									c1.1,2.9,5.7-1.2,7.6-1.7c4.5-1.4,6.9,1.6,11,1.1c4.7-0.6,4.8-3.8,4-6.3c-0.2-0.6-2.6,0.2-1.8-2.9c0.3-1.1,1.8-1.8,2.2-2.9
									c3.3,2.1,6.1,5.9,9.6,7.6c0.7,0.4,2.3,2.1,3.3,1.6C1490.1,1090.3,1490.8,1088.3,1489.3,1087.3z"
						/>
						<path
							d="M1543.8,1034.1c-5.8-1.1-9.9-3.3-14.1-7.5c-3.4-3.4-7.3-7.3-11-1.6c-1.8-2.2-4.1-4.7-7.1-5.1c0.8-1.1-0.2-1.6-0.1-2.7
									c0.2-1.2,2.1-1.9,2.4-3c0.7-2.3-1.9-5.3-1.1-6.8c1.5,2,12.6,13.7,14,7.9c0.5-2-5.7-4.9-7-6.1c-2.3-2-4.7-7.7-7.1-8.8
									c-4.6-2.1-4.3,3.6-7.7,5.6c-3.3,1.9-6.3-1.2-9-3.6c-5.9-5.5-8.1,0.5-12,5.6c-4,5.3-9.4,4-14.7,7.4c-7.1,4.6-6.6,7.7,1.5,6.1
									c6-1.2,17.3,0.6,22.3-3c1.6-1.2,3.2-3.7,3.1-5.9c-0.1-2.9-3.6-4.3-3.2-7.2c2,2.7,5.3,4.1,8.4,5.1c-2.1,2.5-12.9,12-4.8,11.3
									c0.9-1,1.8-1.9,2.6-2.9c1,0.6,2,0.5,3-0.1c3.9-0.7,3.8-0.1,7,1.5c-3,1.5-3.2,7.5-4.8,10.2c-4.2-2.9-9.7-6.9-14.8-3.6
									c-2.7,1.8-1.7,4.3-5.5,4.4c-3.9,0.1-4.7-4.7-7.8-5.5c-5.2-1.3-4.9,5.5-7.7,8.3c-2.1,2.2-5.3,2.2-7.6,4c-2,1.6-2.5,4.4-4.3,6.1
									c-4.5,4.2-11,2.1-16.2,5.8c-2.1,1.5-13.1,11.1-9.1,13c2.2,1,4.3-2.3,5.9-3.3c3.2-2,6.2-2,9.8-1.8c4.2,0.2,6.4,0.1,10.5-1
									c2.7-0.7,5-1.7,7.6,0c-2.4-0.4-2.9,3.8-4.2,5c-1.6,1.5-4.4,2.3-6.3,3.5c-6,3.8-11.1,9.1-16.8,13.4c-1.9,1.5-13.8,7.8-8.7,10
									c2.4,1,4.6-3,6.6-3.4c3.1-0.6,6.5,1.6,9.7,0.8c7.7-2,14-5.8,18.9-11.5c2.3-2.6,2.3-2,2-5.8c-0.1-1.6-1.2-5.6-0.5-7
									c6.6,7,12.2,14.5,20.4,20c3.2,2.2,9.2,7.3,11.1,2.1c0.1-0.3-1.6-1.6-1.6-1.7c-0.1-1.5,0.9-5.3,1.5-6.6c3.1-7.5,6.7-0.6,11.6,0.8
									c1.1,0.3,5,0.4,5.8-0.9c1.3-2.1-0.1-2.2-1.1-4c-2.5-4.5-7.8-7.4-6.2-13.5c2.9,2.5,6.4,3.9,9.7,5.9c3.6,2.1,6.7,5.1,10.4,7
									c1.9,1,7,3.4,8.4,0.8c1.9-3.4-3.3-3-4.9-3.6c-6.4-2.2-14-8.3-19.6-12.1c-2.6-1.8-6.6-6.3-10.1-4.2c-2.2,1.4-1.2,5.1-3.3,6.6
									c-2.5-4.3-4.8-8.7-3.7-13.9c1.3,1.3,6.2,7,8.6,6c3.9-1.6-1.7-5.2-2.8-6.4c5.9-2.5,8.6,7.3,14,4.5c6.5-3.4-3.1-15.5,2.1-20.4
									c2,2.3,3.7,4.8,5,7.5c2,4,1.2,3.9,0,8c-0.9,3-2.2,2.2,0.5,4c1.4,0.9,9.7-0.4,12-0.1c4.7,0.6,9.3,1.1,3.9-3.9
									c-4.2-3.9-9.7-7.3-10.4-13.7c3.4,3.4,8.2,6.7,13.1,7.3c1.3,0.2,3.9,1.5,4.7-0.5C1545.4,1035.8,1545.3,1034.4,1543.8,1034.1z
									M1501.1,1061.2c-0.1,1.7-1.4,4.8-4.2,2.6C1498.4,1063,1499.7,1062.1,1501.1,1061.2z M1481.2,1072.5c-2.4-2.2-5.2-4.3-7.3-6.8
									C1480.3,1060,1489.7,1064.5,1481.2,1072.5z M1496.8,1040.2c0.2-0.2-5.3-0.4-6.9,0.5c-2.9,1.6-5.7,5.9-7.9,8.4
									c-3.3,3.9-6.3,11.1-9.8,14c-2.3-3.2,0.8-3.4,2-6.4c1.1-2.5,0.2-3.9,0-7.1c-0.2-3.4,1.1-6.6,1-9.9c-0.1-2.1-2.5-6.8,0-8.5
									c2.1-1.4,7.6,6.4,12.9,3.3c2.3-1.3,2.9-4.1,5.7-4.7c3-0.6,6.2,2,8.4,3.6C1500.4,1035.8,1498.4,1037.9,1496.8,1040.2z"
						/>
						<path
							d="M1496.1,962.7c-5.5,0.9-9.1-1.5-13.9-3.5c-5.1-2.1-5.3-0.1-9.6,3.4c-3.7,3-7.8,6.2-12.4,7.6c-2.5,0.7-8.7,0.8-4.8,4.5
									c0.1,0.1,13-0.4,14.1-0.5c4-0.2,10.6-1.4,14.2-3.6c1.6-1,2.3-3.2,3.5-4c1.5-0.9,3.8,0.1,6.2,0c1.6,0,4.5,0.5,5.1-1.4
									C1498.9,964,1497.7,962.5,1496.1,962.7z"
						/>
						<path
							d="M1558,954.9c-3.8-2.7-8.5-8.8-13-10.3c-5.5-1.8-3.6,2.1-7,4.1c-4,2.4-7.2-4.3-10.1-6.3c-2.6-1.9-4.6-1-6.1-2.8
									c2.9-1.7,7.2,1.2,9-0.5c1.5-1.4,1.1-6.2,2.3-8.4c0.7-1.3,4.1-7.3,5.5-7.5c2.2-0.4,5.5,4.9,8.5,4.2c7-1.5-5.5-8.9-8.2-9
									c-3.1-0.1-2.7,3-4.9,3.4c-1.3,0.2-3.4-2.4-5-2.3c-4.8,0-4.3,5-6.1,8.3c-4.5-2.7-8.5-10-13.3-6c-3.2,2.7-3.6,7.9-7.1,10.4
									c-4,2.9-8.8-0.3-12.4,3.3c-3.8,3.8-2.6,6.7-8.9,8c-4.1,0.9-8.2,0.2-12.4,1.5c-3.4,1-16,5.5-18,8.2c-2.9,4,0.5,3.7,3.9,2.7
									c5.4-1.6,8.7-2.6,14.3-1.6c6.4,1.1,9.7-1.9,15.8-3.1c4.1-0.8,12.7,2.7,12.8-2.4c0.1-1.9-2.9-3.7-1.4-6.2c1.3-2.2,2.3-1.1,4.3,0.4
									c4.5,3.3,3.8,5.1,8.5,2c2.3-1.6,6.7-5.6,9.9-3.7c4.1,2.4-2,5.1-3.8,7.6c-1.1,1.4-1.9,3-2.6,4.6c-1.6-0.9-3.2-1-4.9-0.3
									c-1.9,0.7-3.3,2.8-4.3,4.4c-0.6,1-2.7,4.5-2.6,5.7c0.1,2.2,3.2,3.6,5,5c-0.4,0.5-0.7,0.9-1.1,1.4c-1.1,0-2.6-3.1-3.6-3.5
									c-3.2-1.2-3-0.1-5.1,2.8c-4.2,5.7-7.1,13-11.8,18.2c-4.4,4.9-9.8,3.5-15.3,5.6c-3.9,1.4-11.8,6-5.2,8c6.5,2,11-3.5,16.4-4.9
									c2.3-0.6,3.4,1.3,5.8,0.4c3.1-1.2,3.4-3.6,7.2-3.1c2.2,0.3,13,7,10.7-0.3c-0.8-2.5-5.1-2.2-3.3-6.6c0.8-1.9,2.5-2.4,3.6-4.1
									c-0.6-0.6-0.9-1.4-0.9-2.2c0.6-0.6,1.2-1.3,1.8-1.8c0.9-5.1,1.8-7.3,6.1-4.1c3.5,2.6,9.2,8.2,9.4,12.4c0.2,4.2-1.8,4.8-5.4,6.6
									c-1.4,0.7-7.3,1.8-7.8,3.3c-1,3.3,3.4,1.5,4.6,1.6c2.4,0.1,4.9,1.8,7.2,1.4c3.1-0.5,5.9-4.8,8.1-6.6c2.5-2,7.4-2.8,7.1-5.6
									c-0.5-3.8-9.1-5.8-11.3-7.9c1-0.4,5-1.4,5.6-2.3c0.9-1.3-0.7-3.1,0.5-4.7c3.6,2.5,15,10.5,19.8,8c5.5-2.9-2.4-4.1-4.8-4.8
									c-5.2-1.4-11.4-4.6-13.9-9.8c-2.5-5.1,0.7-7.2,5.6-5.7c4.5,1.3,8.1,7.6,13.2,7.1c7.5-0.8-1.7-5.8-2.8-8.9c-1.7-4.7,0-6,4.1-3.4
									c2,1.2,3.3,3.9,6,3.6C1557.5,958.3,1560.8,957,1558,954.9z M1516.3,935.9c-4.9,4.9-0.4-7.5-0.4-8c1.5,1.2,3.1,2.3,4.8,3.4
									C1519.3,932.9,1517.8,934.4,1516.3,935.9z M1509.9,968.3c-0.6-0.3-1.3-0.5-1.9-0.8c0.6-1.2,3.1-4,3.5-5.4c0.4-1.6-1.1-3-0.2-4.5
									c2.8,1.1,5.2,4,7.2,5.9C1515.5,964.9,1512.6,966.5,1509.9,968.3z M1524.2,972.6c-3.6,3.8-3.5,4.6-7.5,1.2c1.8-1.3,6.2-4.1,8.7-2.9
									C1525.5,971.6,1525.1,972.2,1524.2,972.6z M1528.1,956.9c-4.3-2-3.7-6.6-1.1-9.8C1530.7,950.8,1537,954.7,1528.1,956.9z"
						/>
						<path
							d="M1516.3,896.9c-5.7-3.5-8.4-7.2-15-2.6c-2.5,1.7-13.6,10.8-9.8,13.4c2.3,1.6,14.5-4.7,16.7-6.2c0.6-0.4,2.3-2.2,2.8-2.3
									c1.1-0.2,1.5,0.9,2.6,0.7C1514,899.9,1520.7,899.6,1516.3,896.9z M1505.1,899.8c1.2-0.8,2.5-1.7,3.5-2
									C1507.8,898.6,1506.5,899.3,1505.1,899.8z"
						/>
						<path
							d="M1611.4,919.6c-3.6-2.9-7.4-5.9-10.1-9.7c-2.2-3.1-3.1-7.8-6.2-10.2c-3.4-2.7-6.2-1.7-8.3-6.1c-1.6-3.3-1.8-11.6-7-6.2
									c-0.4-2.9-1-6.7-3.2-8.8c-4.7-4.3-5.3,1.7-6.2,5.5c-1.1,4.6-2.1,9.1-4.5,13.2c-1.4,2.3-7.3,7.5-6.1,10.2
									c1.6,3.5,17.1,3.2,20.3,1.7c3.9-1.8,3.7-2.1,2.5-7c-0.8-3.4-3.1-8.4-0.6-11.3c1.1,3.2,1.6,6.8,4.2,9.1c2.3,2,5.4,2,7.4,4.6
									c-2.8-0.1-11,13.1-12.7,15.8c-4.3,6.8,1.1,3.4,6.2,4c3.7,0.5,7.6,4.6,9.2-0.6c0.7-2.1-1.2-4.5-1.1-6.7c0.1-2.4,1.3-3.8,2.3-5.9
									c2.1,3.5,5.6,7,8.9,9.4c0.9,0.6,2.4,2.6,3.8,2.3C1611.5,922.6,1613,920.8,1611.4,919.6z"
						/>
						<path
							d="M1569.7,892.3c-5.7-5-7.4-12.9-11.1-19.3c-1.2-2.2-3-6-5.8-6.5c-4.1-0.8-4.9,3.9-6.7,7.1c-2-1.9-6.7-6.2-9.7-5.7
									c-4.6,0.7-5.6,11.2-7.9,14.6c-7.2-8.8-10.2,7.5-11.8,12.9c-1,3.5-2.6,6.7-4.7,9.8c-1.1,1.6-2.9,3.1-3.8,4.8
									c-0.8,1.4,0.7,2.5,0.3,3.2c-7.8-2.6-12.9-5.6-19.6,0.9c-1.8,1.8-12.3,12.1-11.4,13.8c1.1,1.9,3,0.4,4.3-0.2
									c3.2-1.6,3.1-2.9,6.7-3.2c4.4-0.4,6.1,0.4,9.5-3.7c2.6-3.1,2.1-3.4,1.4-6.8c5.5,1.1,10.3,4.1,16,4.9c2.1,0.3,4.6,0.9,6.5-0.1
									c1.3-0.6,0.3-3-0.7-3.4c3.5,0.5,5.4,1.9,5.6-1.8c0-0.7-1.7-1.4-1.7-2.2c0-2.8,0.9-1.3,2-3.5c2.5-4.8,1.2-5.4-1-10.1
									c-2.1-4.5-2.1-8.6-1.2-13.4c5,5.7,6.7,13.3,11.7,19.1c1.6,1.8,6.2,7.5,8.8,5.1c2.8-2.5-3.9-5.2-5.2-6.7c5.2-1.5,10.9,5.5,11.7-4
									c0.3-3.4,0-5.3,1.1-8.8c1.3-4.5,1.4-9.2,2.7-13.7c2.4,5,4.1,10.2,7.3,14.8c1,1.4,2.7,4.9,4.7,5
									C1569.1,895.4,1571.9,894.1,1569.7,892.3z M1539.5,883.8c-2,2.5-4,5.1-5.6,7.9c-1.9-3.9,1.1-6.1,3.7-9.5c1.7-2.2,3.9-5.8,6.6-5.2
									C1542.8,879.3,1541.2,881.6,1539.5,883.8z"
						/>
						<path
							d="M1620.8,836.1c-3.4-0.6-6.9-1.4-10.1-2.9c-2.4-1.1-4.4-3.5-6.7-4.4c-7-2.6-6.8,5-12.2,7.3c-5.9,2.5-8.7-6.9-14.1-6.3
									c-3.9,0.5-8.3,8.1-11,10.5c-1.3,1.1-5.4,5.4-6.9,5.6c-1.4,0.2-5.2-0.9-4.6-4.2c1-5.9,6.2,2.7,8.7-0.5c2-2.6-6.9-9.1-9.6-7.7
									c-1.7,0.8-4.9,6-6.3,7.6c-1.8,2-3.1,3.7-4.1,6.2c-5.5-7.8-11.1,2.1-15.3,6.4c-2.6,2.6-5.4,5.1-8.5,6.9c-1.6,0.9-7,2-7,4.3
									c0.1,4,14.1-0.1,16.7-0.6c2.5-0.5,8.3,0.2,10.1-1.9c1.7-2,1.2-8.4,2.1-11.2c2.7,3,6.4,7.1,10.4,5.9c0.5-0.1,3.1-1.2,3.2-1.8
									c0.3-2-1.8-1.9-2.2-2.8c-0.4-0.8-2.1,0.6-2.4-1c-0.5-2.4,2.4-3,4-2.8c2,0.3,4.4,3.2,6.4,4.4c3.7,2.2,7.9,1.8,12.4,3
									c1.5,0.4,6.3,2.7,7.2,2.6c5.5-0.2-0.1-5.5-1.2-7.6c-2-3.8-0.8-5.2-0.7-9.1c0.1-2,0.4-2.1-0.1-3.7c-0.2-0.7-1.8-0.7-1.9-1.4
									c-0.6-5.1,4.7,0.2,7,1.6c3.2,2,6.4,3.9,9.6,5.8c3.2,1.9,6.8,4.4,10.6,4.9c4.8,0.6,4-0.4,3.2-3c-0.4-1.4-2.2-1.5-2.3-3.6
									c0-1,1-1.7,1-2.5c0.1-1.3-0.1-3.4,0.2-4.6c2.6,1.4,10.1,5.3,13.3,4.5C1621.3,839.5,1624,836.7,1620.8,836.1z"
						/>
						<path
							d="M1525.8,815.8c-5.2-0.2-8.6-3.3-12.7-6c-4.8-3.2-6.6-2.6-10.9,1.3c-3.4,3.1-6.3,5.9-10.5,8c-3.5,1.7-10.5,1.7-13.2,3.7
									c-7.8,5.6,13.4,2.3,15.8,1.9c2.2-0.3,4.5-1.2,6.6-0.9c1.8,0.2,3.2,2,5,1.8c4-0.3,5.7-8.4,6.6-10.9c2.5,2.3,8.4,5.5,11.9,5
									C1526.5,819.3,1529.9,815.9,1525.8,815.8z"
						/>
						<path
							d="M1629.3,817.1c-5.2-1.4-7.4-6.2-9.9-10.4c-1.2-2-2.2-4.6-4.7-5.2c-1-0.2-3.2,1-3.6,0.9c-2.8-1.2-4.1-5.7-6.7-7.5
									c-5.7-4-6.1,0.9-9.3,4.9c-2.1-2.4-4.9-6.9-8.3-7.6c-2.1-0.4-2.6,0.9-4.1,0.8c-1.7,0-4-0.8-5.9-1.6c-7.2-2.9-14.7-9.1-21.2-2.1
									c-3.5,3.8-6.7,8.2-10,12.2c-1.3,1.6-2.4,3.8-3.9,5.1c-0.4,0.6-0.8,1.1-1.3,1.7c-0.5,1.1-1.2,1.1-2-0.1c-4.1,1.1-4.9,1.2-8.4,4.2
									c-3.5,3-7.2,5.5-10.5,8.7c-3.3,3.1-6.5,5.7-10.1,8.4c-2.2,1.6-5.3,4.9-0.2,4.7c3.9-0.1,6-4.5,10.9-3.9c2.9,0.3,5.2,2.3,8,2.6
									c1.7,0.2,11.5,0.9,12.2,0c1.2-1.5,0.1-1.3,0.2-2.5c-0.2,2.1-1.5-4.7-1.2-3.9c-0.2-0.6-0.2-2.5-0.3-3.3c-0.1-0.6-1.7,0.1-1.5-1.2
									c0.5-3.2,3.2-2.7,2.9-6.9c2.7,2.4,32.3,19.3,32.1,12.6c-0.1-2.4-11-4.4-13.5-5.7c-5.5-2.9-11.7-6-16.3-10.2
									c4.5-4.5,6.6-3.7,12.8-2.1c4,1,9.8,2.2,13.9,1.9c-2.2,2.5-3.6,4.7,0.2,5.2c0.7,0.1,3.2-2.2,2.5-2.1c0.9-0.1,2.3,2.2,3.4,2.2
									c7,0.1,9.9-1.9,11.6-8.5c0.5-1.8-2.1-8.1,1.7-8.7c8.5-1.2-2.4,14.8-3.7,16.7c-1.8,2.7-7.5,7.8-8.1,10.7c-0.9,3.9,0.9,2.1,3,2.7
									c5.7,1.4,11.7,1,17.4-1c2.7-0.9,5.2-3.6,8.3-3.3c2.2,0.2,2.8,1.9,4.6,2.5c12.5,3.7-0.4-9.7-1.2-8.5c2.1-3.2,4-0.4,4.4-4.2
									c0-0.5-1.9-1.1-1.9-1.7c-0.3-2.1,0.5-3.4,1.7-5.3c1.7,4.2,7.3,8.3,11,10.7c0.8,0.5,4.1,2.6,5.3,1.7
									C1630.9,819.9,1631.3,817.6,1629.3,817.1z M1577,800.9c-0.6,0.9-3.5,6.8-4.8,7c-1.6,0.2-5.1-3.2-5.8-4.4c-1.4-2.7,0.1-4.1,0-6.8
									c0-2.3-0.8-3.7-1.2-6c4.9,1.3,9.3,4.6,14.2,6.2C1578.7,798.2,1577.9,799.6,1577,800.9z M1605,808.4c-0.6-1.4,3,2,2.8,1.8
									C1606.9,809.7,1606,809.1,1605,808.4z"
						/>
						<path
							d="M1639.2,770c-4.5-0.3-7.1-4.9-11-6.7c-3.6-1.6-6.3-3-9.7-5.1c-4.4-2.7-5.5-2.7-8.5,1.2c-3.2,4.1-6.2,9.9-8.3,14.7
									c-3.5-3.5-4.2,0.4-5.8,3.2c-2,3.6-3.9,5.6-7.6,7.8c-2.1,1.2-10.9,3.8-11.6,5.7c-1.3,3.7,2.5,2.7,4.8,1.9c2.6-0.8,5.6-3.3,7.6-2.4
									c2,0.8,1.7,3.2,3,3.7c2.3,0.9,5.4-2.3,6.8-4c0.6-0.8,4-8.1,0.3-5.9c1.5-0.9,0.8-4.1,1-5.5c1.4,2.1,5.1,7.8,8.2,7.8
									c4.4,0,2.6-2.8,0.2-4.6c2-0.6,7.5,0.4,8.8-0.5c3-2.1-0.4-1.7,0-3.7c0.5-2.2,1.5-3,2.6-4.9c1.4-2.6,1.4-4.1,2.1-7.1
									c4.9,1.8,8,4.9,12.5,7.1c0.9,0.4,4.8,2.1,5.8,0.9C1641.6,772.4,1641.3,770.1,1639.2,770z"
						/>
						<path
							d="M1570.5,768.1c-4.6-1.3-7.8-4.7-11.1-7.9c-3.5-3.5-4.8-6.5-8.7-3.3c-3.1,2.6-4.9,8.5-7.9,11.6c-2.9,3-11.2,5.9-12.8,9.2
									c-2.9,6.1,3.3,1.9,5.4,1.3c2.1-0.6,3.3,0.5,5.6,0.7c2.4,0.1,4.3-1.2,6.5-1.4c5-0.5,9.6,3.5,10.2-3.6c0.3-3.6,0.6-7.1,0.1-10.6
									c2.5,2.5,6.6,7,10.4,7.4C1570.4,771.7,1574.5,769.2,1570.5,768.1z"
						/>
						<path
							d="M1667.8,710.3c-4.1-2-8.4-9.7-12.5-9.4c-2.3,0.2-7.4,5.8-9.2,7.3c-3,2.5-5.9,5.2-8.4,8.2c-2.3-2.8-5.4-4.8-7.7-7.7
									c-1.8-2.3-0.9-4.8-4.4-2.1c-3.5,2.7-2,9.8-7.5,7.3c-3.7-1.7-4.5-7.4-9.4-7.1c-3.2,0.2-5.7,3.9-7.6,6c-1.1,1.2-2,2.9-3.4,3.8
									c-3,1.8-0.8,1.7-4.6-0.4c-3.6-2-5.4-8.2-9.8-5.9c-2.7,1.4-4.6,8.6-6.3,11.2c-3.9,6.2-10.3,4.2-15.7,8.8c-2.6,2.2-12.6,6.8-5.8,8.6
									c2.4,0.6,6.5-2,9.1-2.1c4.6-0.2,7.9,0.4,12.2-2.1c3.2-1.8,5.6-4.3,8.8-6c3.4-1.9,6.6-3,7-7.5c1,0.9,8.6,4.8,8.8,5.4
									c1.4,5-3.8,2.1-4.9,2.4c-5.7,1.5-8.1,7.8-10.2,13.3c-7.2-5.2-10.9-2.6-17.2,2.1c-2.5,1.9-15.2,7.1-10.2,10.3
									c0.3,0.2,4-0.5,4.4-0.5c1.2,0.2,1.7,1.9,2.9,2.1c2.4,0.2,3.8-1.4,6.4-0.5c2.5,0.9,3.3,4.6,5.7,5.3c-5,4.3-7.8,9.7-13.4,13.5
									c-1.6,1.1-6.9,3.3-7.1,5.6c-0.4,4.6,4.4,0.8,5.5,0.2c5.6-2.7,8.5,0.8,14.7,0.3c1.5-0.1,5.8-0.2,6.4-0.4c1.8-0.6,3.2,0.3,2.4-2.8
									c-0.8-2.8-4.8-1.4-3.1-5c1.7-3.5,4.9-1.4,7.1-0.5c2.1,0.8,5.3,3.9,7.4,1.7c2.1-2.3-2.8-3.7-3.6-4.2c-3.2-1.7-6.1-3.7-9.1-5.8
									c-4.3-3-4.3-2.3-2.9-7c1.3-4.3-2.6-8.7,0.2-12.1c7.1,4.7,11.7,14.5,19.8,17.5c3.7,1.4,5.9,0.5,3.3-2.9c-2-2.6-10.3-3.8-10.7-8.1
									c-0.3-3.5,2.4-0.9,4.1-3.4c1.9-2.7-2.5-5.3,0.8-7c1.7-0.9,7.5,1.8,9.2,1.1c3.7-1.6,2.4-3-1.1-4.8c0.4-0.3,1.8-6.8,1.7-7.2
									c-0.6-2.7-2.2-1.6-4.4-2.6c2.9-0.6,1-2.7,3.9-1.2c1.1,0.6,2.2,2.6,3,3.4c2.2,2.4,5.5,10.5,8.2,11.2c-2,2.4-13.3,10.6-12.4,13.8
									c1,3.7,4.6-0.5,5.5-1.3c4.3-3.6,3.1-2.1,7.5-1.5c4.4,0.7,2.4-2,6.2-3.2c2.4-0.8,4,2.4,6,1c2.2-1.6-0.4-4.3-0.3-6.1
									c0.1-3.3,2-5.8,2.1-9.2c2.3,4.6,7.7,15.9,13.1,13.3c3.2-1.5,2.8-5.1,4.4-8.1c0.4-0.8,2.8-5.1,3.9-5.2c1.2-0.1,3.6,3.2,4.9,3.6
									c2.9,0.9,4.6,0.5,3-2.8c-1-2-7.3-5.9-9.1-7.6c-3.6-3.4-3-3.3-6.1,0.2c-2.7,3-5.3,6-8.3,8.8c-3.2-5.1-6-17.4-11.6-8.3
									c-2.9-2.9-1.5-2.4-0.9-5.3c0.1-0.5,1.4-1.8,1.6-2.4c0.9-2-0.8-3.5,0.9-5.4c3,2.7,5.5,7.7,9.4,9.2c3.5,1.4,7.9-1.3,11.7-1.3
									c2.5,0,3.1,2,4.9-0.5c0.8-1-0.8-4.8-0.9-5.9c-0.2-2.5-0.8-8,1-9.6c1.6,2.3,4.3,5.3,7.1,5.9
									C1666.3,713.6,1671.5,712.1,1667.8,710.3z M1613,721.8c-2.7-1.1-6.6,1.9-5.9-3c0.2-1.1,2.8-3.7,3.8-3.9c2.8-0.7,3.6,1.8,6.3,3.3
									C1615.8,719.5,1614.4,720.7,1613,721.8z M1625.5,733.8c-1.6,1.1-2.9,1-3.9-0.4C1621.6,733.4,1625.6,733.7,1625.5,733.8z
									M1652.7,730.3c0.6,0.6,1.2,1.3,1.8,1.9c-1.1,1-2.3,1.2-3.5,0.4C1651.6,731.9,1652.1,731.1,1652.7,730.3z"
						/>
						<path
							d="M1621.8,658.5c-3.5-2.4-6.5-5.6-10-7.8c-5.4-3.4-8-1.4-12.8,2.2c-4,3-8.1,5.7-12.6,7.9c-1.3,0.6-8.6,3.2-4.4,5.7
									c1.1,0.7,5.9-1.5,7.7-1.4c1.9,0.2,3.3,1.2,5.3,0.9c1.8-0.2,3.1-1.7,4.8-2c3.9-0.6,4,2.8,7.4,2c5.2-1.3,1.9-8.4,2.5-11.5
									c2.2,2.2,6,5.6,8.9,6.7C1620.3,661.8,1625.4,661,1621.8,658.5z"
						/>
						<path
							d="M1674.3,681.8c-7.1-2.1-13.6-6.4-18.4-12c9.7-1.9,1-6.5,3.9-13.2c2.6,2.2,10.2,9,13.5,8.8c6.6-0.4-0.5-4.9-2.9-6.3
									c-3.8-2.3-9.9-9.5-13.9-10.1c-4.1-0.6-5.5,6.9-7.5,9.9c-0.5,0.7-1,1.4-1.5,2.1c-1.1,2.5-2.5,2.6-4.2,0.3c-2.4-0.8-4.8-3.5-6.7-5
									c-3.8-3.1-5.5-6.7-9.7-2.9c-3.5,3.2-5,8.4-8.5,11.9c-3.6,3.6-6.7,5.1-7.8,9.5c-2.2-1.6-6.3-6.1-9.2-6.1c-3.1,0-4.9,3.1-7.1,4.8
									c-2.8,2.2-6.2,3.9-9.4,5.5c-1.6,0.8-7.7,2.3-8.4,3.8c-2.7,5.5,4.6,2.4,7.3,1.7c2.6-0.7,5.9-2.5,8.4-2.8c2.9-0.3,4.2,2.1,6.8,2.1
									c-4,4.1-8,8.2-12,12.3c-1.7,1.7-3.3,3.5-5.3,5c-1.4,1.1-5,2-6,3.2c-2.8,3.3,0.2,4.2,3.7,3c4.3-1.6,5.3-4.1,10.3-3.3
									c1.1,0.2,2.7,1.6,3.2,1.7c1.4,0,2.9-1.3,4.2-1.2c1.9,0.1,7.1,6.2,6.4-1.3c-0.2-2.1-2.1-3.9-2.8-6.7c-0.8-3.1-2.3-6.2,0.2-8.8
									c1.5,3.3,3.8,11,7.4,12.6c5.3,2.4,3-2.6,1.1-5.1c6.1-1.4,10.1-5.2,14.8-8.9c4.4,4.8-15.2,12.4-11.1,18c1.2,1.7,8.4-0.8,10-1.3
									c1.1-0.4,6.1-1.3,6.7-1.8c2.3-1.8,1.1-3.5,1.7-6.4c4.3,5.2,15.3,11.1,16.4,0.5c2.3,2,23,9.5,19.7,3.3c-0.9-1.6-9.3-2.2-11.4-3.1
									c-3.8-1.6-7.2-6.6-11.2-7.3c-4.5-0.7-5.3,4.4-8.7,5.1c-2.8,0.5-5.7-6.2-7.9-8.3c-3.9-3.6-4.8-2.8-9,0.1c-2.8,2-10.9,3.4-12.7,5.8
									c-1-1.8-2.5-8.7-5.5-8.4c0.8-1.1,1.8-3.3,3-4c3.3-2,2.4-0.4,6.4,1.5c4.4,2.1,8,0.1,2.6-3.2c4.8-2.2,8.8-2.7,13.8-1.7
									c-1.4,1.2-1.5,2.7,0.3,3.6c1.8,0.8,5.4-2,6.7-2.7c2-1.1,5.3-3.7,7.5-4.3c3.4-1,4.2,0.9,7.1,1.7c-3.5,3.1-11.4,6-13.6,10.1
									c-3.8,7.1,4.6,1.2,7.5,0.5c2.9-0.7,13,6.1,14.7,2.9c1.4-2.7-2.5-5.6-3.6-7.6c-1.8-3.4-2.9-2.1,0.1-5.3c4.1,4.4,9.1,8,14.7,10.4
									c0.7,0.3,5.1,2.5,6.1,1.7C1675.8,684.6,1676.4,682.4,1674.3,681.8z M1633.5,671.3c0.4-1.6,3.2-5.1,3.2-6.2
									c0.2-3.8-3.7-1.8-3.4-6.3c2.9,2.6,5.9,4.8,9.2,6.9C1639.7,667.8,1636.6,669.7,1633.5,671.3z"
						/>
						<path
							d="M1656.8,623.8c-4.6-2.7-10.1-7.9-13.4-12.1c-4.6-6-5.3-11.2-10-2.5c-1.9-3.3-4.4-10.6-7-13c-4.4-3.9-6.7,2-9.2,6
									c-3.1,5-5.8,10-9.6,14.5c-1.6,1.9-9.4,7.6-4.2,9.3c-0.4-0.1,5.2-1.7,4.1-1.5c2.4-0.5,4.8-0.2,7.2,0.1c3.2,0.4,12.4,3.1,14.6,0
									c1.4-2-1.1-7.9-1.8-10.1c-1.4-4.3-5.7-6.1-1.6-10c2.3,4.1,4.4,8.6,6,11.8c0.3,0.7,3,6.6,3.8,7c1.3,0.8,4.1,1.5,3.6-1.5
									c1.8,1.2,4.3,3.8,6.1,1.7c1.2-1.3-3.7-6.9-4.3-8.1c2.8,3.5,6.6,6.2,10,9c1.2,1,3.3,3.9,5.1,3.1
									C1657.7,626.8,1658.4,624.7,1656.8,623.8z"
						/>
						<path
							d="M1670.8,609.3c-6.8-2.1-8.8-12.2-15.5-4.3c-1.1,1.3-11.5,13.1-6.1,12.8c2.3-0.1,3.1-3.4,4.8-4.7c2.1-1.6,5.4-2.4,7.9-2.5
									c0.9-1.6,1.7-1.8,2.3-0.8c0.4,0.4,0.8,0.8,1.3,1.1c1.3,0.4,2.7,2.4,4.3,2.2C1671.6,612.9,1673.8,610.2,1670.8,609.3z"
						/>
						<path
							d="M1636.7,582.8c-2.8-2.2-5.1-9.3-8.9-9.8c-3.4-0.5-4.9,4.2-6.9,6.5c-3.1,3.6-6.9,6.8-11.4,8.3c-1.6,0.5-8.6,0.6-6.3,4.1
									c1.6,2.4,18.7-1.8,20.6-3.3c1.8-1.4,4-7.3,6.7-7.1c1.8,0.1,2,5,4.9,4.6C1636.9,585.9,1638.2,584.1,1636.7,582.8z"
						/>
						<path
							d="M1674.9,573.8c-5.4-4.5-5.8-13.2-12.2-4.2c-1.1,1.5-3.3,7.6-5.2,8.1c-1.6,0.4-3.2-3.6-4.2-4.4c-5.7-4.3-8.8,4.8-12.1,8.9
									c-1.6,2-11.3,10.2-10.1,13.2c1.4,3.2,4.2-0.5,6-1.4c1.3-0.9,2.8-1.4,4.3-1.4c1.2,0.5,2.4,0.7,3.6,0.7c3.7-2.2,3.3-8.3,5.6-10.9
									c4.2-4.7,5.1,3.3,9.2,2.9c5.7-0.5,7.2-9.4,8.3-13.6c0.9,1.5,2.6,4.9,4.6,5.1C1674.3,577.1,1677,575.6,1674.9,573.8z"
						/>
						<path
							d="M1642,553c-7-4.4-9.1-10.8-16.6-4.8c-2.7,2.2-5.4,3.6-8.5,5.3c-2.1,1.2-8.6,3.3-9.9,4.8c-1,1.1-2.4,2.2-0.3,3.6
									c2.8,1.9,8.1-3,10.4-3.9c6-2.2,11.8,0.7,15.6-7c1.3,1.4,5.3,5.3,7.3,5.4C1641.6,556.5,1644.9,554.8,1642,553z"
						/>
						<path
							d="M1684.5,562.1c-8.6-2.1-12.6-6.8-19.7-11.2c-3.5-2.1-5.1-4.5-8.2-7.6c-3.3-3.2-4-2.8-6.8,1.2c-2.4,3.4-10.1,13.5-5.5,15.3
									c6.8,2.7,9.6-6.2,13.3-8.9c2.4,2.8,2.9,2.4,1.5,6.1c-1.1,2.8-1.5,3.4-4,3.3c-3.4-0.1-3.6-2.1-7.4-0.1c-3.6,1.9-3.4,4.5-6.8,1.5
									c-1.9-1.7-2.6-6.2-5.8-6.1c-2.4,0.1-5.9,4.9-7.7,6.3c-1,0.8-18.3,13.1-11.3,13.6c1.6,0.1,5.7-4.6,7.2-5.5
									c3.1-2.1,6.3-4.4,10.1-4.2c4.6,0.1,7,4.3,11.5,3.1c3.6-1,5.4-3.3,9.8-3.4c6.6-0.2,8.4-2.4,11.9-8.3c3.3,3.9,8.5,6.4,13.4,7.7
									c1.5,0.4,4,1.9,5.3,0.5C1686.2,564.4,1686.3,562.5,1684.5,562.1z"
						/>
						<path
							d="M1700.7,523.6c-5.2-4.9-9.8-8.6-14.2-14.3c-6-8-5.8,1.4-10.3-0.6c-2.4-1.1-1.6-6.3-4.2-7.4c-3-1.2-4.6,3.3-5.9,5.5
									c-3.9,6.7-8.9,12.3-13.2,18.8c-1.5-1.4-3.2-4.3-5.5-4.5c8.2-7.4-2.9-19.6,4.1-25.5c1.1,1.9,9.8,15.2,12.6,10.4
									c1.3-2.2-7.4-9.5-8.8-11.8c-3.5-5.6-4.5-10.2-9.9-3.5c-4.1,5-9.3,11.6-11.8,17.5c-1.2-3-3.8-13.7-7.3-14.4
									c-3.1-0.6-4.9,8.1-5.9,10.1c-2.1,4.6-5,8.6-8.5,12.2c-1.7,1.8-10.1,6.7-5.7,9.2c1,0.6,2.6-1.5,3.4-1.4c1.7,0.2,3.1,1.1,4.6,1.3
									c2.4,0.3,4.5-0.1,6.8,0.7c2.3,0.7,4.5,3.6,7.5,2.5c4-1.5,1.9-3.9-1-5.5c1.9-1.4,1.4-13.9,1.1-16.5c1.6,3.9,7.7,25.9,12.2,16
									c0.4-0.9,0.4-6.2,1.4-6.3c2.7-0.4,2.9,5.4,2.8,5.9c-0.9,2.6-5.5,8-7.3,10.3c-3.3,4-0.9,1.4-4.4,0c-3.4-1.4-4.4-1.6-7.5,0.9
									c-1.5,1.1-5.7,4.7-6.6,6.1c-4.6,7.9,8.6,0.5,11.2-0.1c0.9-0.2,1.5-1.2,2.7-0.8c2.6,1,1,3.9,2.4,4.2c1.8,0.5,3-2.1,5.2-2.2
									c1.6-0.1,1.8,2.2,3.3,2.1c1.5-0.1,2.1-1.9,3.2-2.2c1.9-0.5,6.8-0.9,6.8-3.6c0-4.2-9-3.4-4.1-8.6c4.4,4.1,10.6,7.2,16.4,8.9
									c2.8,0.8,12.6,3.7,14.2,0.9c2.4-4.2-7.2-3.3-8.9-3.7c-1.5-0.4-5.3-1.2-5.9-3.3c-0.9-3.2,1.2-0.6,1.9-3.2c0.8,1.2,2.3,2.3,4,1.5
									c2.4-1,0.7-3.4,0.7-5.2c0-2.2,1-4.2,0.9-6.3c-0.1-3.4-3-4.7-1.3-8.8c0.9,2.1,2.5,4.7,5.2,4.4c0.2,4-4.2,5.3-1.8,9.7
									c1.7,3.2,4.3,4.3,5.8,1.5c-0.2,0.3,0.4-4.7,0.4-4.8c-0.1-0.7-1.4-2.3-1.4-2.4c0.2-2.4,2.3-4.1,3.4-6.1c3.3,4.5,6.3,8.3,10.7,11.8
									c1.3,1,3.8,4,5.5,3.7C1700.8,526.7,1702,524.9,1700.7,523.6z"
						/>
						<path
							d="M1672.7,487.8c-8.9-7.6-27-22.5-24.2-36c1.5,2.1,8.3,13.1,11.7,10.5c2.7-2.1-6.3-10-7.6-11.6c-2.7-3.2-5.1-7.7-9.1-4.8
									c-1.4,1.1-2.6,5-3.8,6.7c-2,2.8-4.4,5.3-6.5,8c-1.7-1.9-3.2-5.1-6.2-4.5c-4.3,0.7-2.6,5.1-4.1,8c-3.3-1.6-4.7-5.2-8.8-3.3
									c-2.1,1-3.3,4-4.8,5.6c-1.9,2-4,3.1-6.3,4.5c-2.6,1.7-8.4,6.5-1.6,5.7c0.9-0.1,4-2.3,4.4-2.4c1.4-0.5,4.6-0.9,6.4-1.4
									c-1.2,2-11.3,12.6-4.9,13.1c1.6,0.1,2.3-3.1,3.9-3.4c2.8-0.5,3.4,2.2,5.9,2.2c1.9,0,3.3-2.7,4.7-2.7c1.7,0,2.1,1.5,3.4,2
									c3,1,7.3,0.2,10.3-1.8c-3.5,3.3-7.5,6.2-12,7.9c-2.3,0.9-10,0.6-11.1,2.5c-2.3,4.1,4.9,3,6.7,2.8c5.3-0.9,9-4,13.8-5.9
									c2.2-0.8,7.2,0.3,8.5-0.6c0.9-0.6,1.4-2,1.5-2.1c3.1-2.6,1.2-2,5.6-1.1c1,0.2,1.6-0.6,3.2-0.3c1,0.2,3.2,2.6,4.4,2.1
									c2.6-1.2-1.9-11.3-2.4-13.3c3.2,4.3,7,8.1,10.9,11.8c1.2,1.2,4.2,5,5.9,4.9C1671.8,490.8,1675,489.8,1672.7,487.8z M1630.9,463.7
									c-0.4,0.5-0.7,1.1-0.9,1.7C1628.9,461.9,1629.2,461.3,1630.9,463.7z M1637.4,480.4c0.2-2.9-2.8-1.7-4.2-2.9
									c-0.2-0.2-0.7-2.5-1.1-3.1c-2.1-3-1.9-4.4-2-8.1c1,3,2.7-0.1,3.7,0.5c1.4,0.8,2.7,3.2,3.9,4.1c4.3,3.4,6.6,0.6,2.3-3.1
									c2.8,1,4.4,2.2,4,4.5C1643.4,474.8,1639.1,478.5,1637.4,480.4z"
						/>
						<path
							d="M1634.8,444.3c-5.8-3.8-9.3-14.1-16.1-6.3c-2.6,2.9-4.4,6.1-7.4,8.8c-1.8,1.6-8.1,4-7,6.8c1.5,3.8,6.9-2.5,8.5-2.8
									c3.2-0.7,9.9,1.5,13.2,2.2c14,3.3-2.4-5.5,0.2-11.1c1.3,1.4,4.7,5.7,6.7,5.8C1634.5,447.9,1637.5,446.1,1634.8,444.3z"
						/>
						<path
							d="M1658,434.3c-3.8-1.8-7-4.7-10.4-7.1c-3.8-2.6-6.8-3.3-11-4.7c-3.1-1.1-6.2-5.8-8.9-6.3c-3.3-0.6-6.7,5.7-8.6,7.8
									c-2.5-2.4-3.7-8.2-7.7-8.2c-4.2,0-8,9.4-10.9,12.1c-4.1-5.4-6.9-1.3-10.5,1.6c-2.2,1.8-13.2,5.8-11.8,9.4c1.1,3.1,9.6,1,11.5-0.6
									c1.9-1.5,2.5-3.6,3.8-5.4c2.3-3,2.4-2.6,4-1.9c-0.3-0.1,2.5,2.7,2.9,3.1c1.6,1.5,2.5,3.8,4.8,4.6c1.4,0.5,8.4,0.2,8.3-2.6
									c-0.1-3.5-5.1,1.2-4.8-2.5c0.3-2.9,2.1-1.6,3.5-2.7c1.1,0.2,2,0,2.8-0.8c-0.2-1.4,0.2-2.5,1.3-3.5c4.4,4.4,11,8.5,17.1,10.1
									c1.8,0.5,7.1,1.8,7.2-1.8c0-2.2-4-0.9-4.4-2.9c-1.3-5.7,10.2,0.3,11.8,0.9c1.8,0.7,6.2,4.9,8.2,4.7
									C1657.3,437.6,1661.3,435.9,1658,434.3z M1630.3,430.7c1.2-0.2,1.4,0.1,1.9,1.2C1631.6,431.5,1631,431.1,1630.3,430.7z"
						/>
						<path
							d="M1601.3,417.3c-7.4-4.2-7.5-11.4-15.6-3.8c-2.4,2.2-4.7,4.9-7.7,6.2c-1.8,0.8-6,0.5-6,3.1c0,2.9,3.8,1.7,5.5,1.3
									c1.4-0.4,4.2-2.7,5.3-2.7c2.1-0.1,2.7,2,4.3,2.4c3.5,0.7,3.6,0.5,4.1-2.7c0.2-1.7-1.4-2-0.5-4c0.4-0.9,1.3-1.3,1.8-2.1
									c1.7,1.5,3.8,4.5,6,5.2C1600.3,420.8,1604.6,419.2,1601.3,417.3z"
						/>
						<path
							d="M1639.7,405.1c-4.2-3.7-11-2.9-16.1-4.7c-3.7-1.3-10.6-7-14.7-5.5c-5.6,2-6.6,10.9-13.1,8.8c-4.5-1.5-7.7-6.9-9-11.1
									c3.5,0.1,5.3,2.7,8.3,2.9c2.7,0.2,9.2-1.3,11.2-3.2c1.4-1.3,1.6-4.8,2.8-5.6c0.7-0.5,2.5,0.3,3.7,0.2c4.3-0.4,9.3,0.3,13.2,2.1
									c2.2,1.1,5.6,4.8,7.9,2.7c2.4-2.3-4.7-5.4-5.8-6c-4-2-7.6-2.4-12-2.6c-5.7-0.3-9.3-2.2-14.5-3.7c-4.1-1.1-5.1,1-8.7,3.5
									c-0.9,0.6-4.4,3.8-5.5,3.5c-0.8-0.2-2.5-3.2-3.4-3.8c-4.3-3.1-6.8-0.9-10.9,1.4c-3.8,2.1-8.3,3.4-11.9,5.8c-8,5.4,3.9,5.5,8,4.6
									c6.3-1.4,8.5-14.5,14.8-6.2c-4.7,2.2-3.7,5.2-7.9,8.7c-4.1,3.5-9,5.9-13.6,8.6c-5.6,3.2-7.2,3-12.4-1c-4.9-3.8-5.4-5.7-10.1-0.6
									c-2.1,2.4-4.1,4.9-6.5,7c-1.4,1.3-5.6,3.1-6.3,4.4c-2.4,4.8,2.5,2.8,5.4,2c2.2-0.6,3.5-0.9,5.5-0.9c1.6,0,2.2,1.1,4.3,0.3
									c3.1-1.2,4.9-6.1,6-8.7c2.8,2.2,8.4,5.9,12.1,6.4c2.6,0.3,4.6-1.7,6.6-1.6c1.9,0.1,2.6,1.4,4,1.8c2.6,0.7,1.8,0,4.6-0.3
									c0.5,0,4.8-0.1,5.2-0.4c1.6-1.5,0.4-4.2,0.7-6.2c0.4-3.6,2.1-6.6,3.2-10c4.1,8.3,12.7,11.4,21,14c1.8,0.6,7.7,3.6,8.9,1.4
									c1.7-2.9-3-3.8-4.8-4.3c3,0.4,12.4,4.4,10.4-1.3c-0.4-1.2-7-4.4-3.6-5.9c0.4-0.2,7.1,3.2,7.7,3.4c2.4,0.7,4.5,0.6,6.9,1
									c1.9,0.2,4.2,1.7,5.8,1.8C1637.9,408,1642.5,407.6,1639.7,405.1z"
						/>
						<path
							d="M1614.5,378.8c-4.9-2.2-8.7-5.6-13.8-7.4c-2-0.7-4.2-1.1-6.1-2.2c-1.8-1-3.2-3.8-4.7-4.5c-4.6-2.2-5.8,4.3-9.1,6.6
									c-6.6,4.5-5.3-2.4-10.7-4.4c-3.9-1.4-5,1.4-7.8,4.5c-1.4,1.6-9.7,7.7-9.2,9.4c1.5,5.9,15.8-5.2,16.9-8c0.9,1.3,2.1,2.3,3.5,3.1
									c-1.5,0.7-5.1,2.4-3.6,4.7c1.7,2.6,5-1.7,7-1.8c4.3-0.3,8.5,4.4,12.4,0c2-2.2,0.3-5.3,3.8-5.7c2-0.2,7.7,2.6,9.7,3.4
									c2.9,1.3,5.6,4.5,8.6,5.3C1613.1,382,1618.3,380.5,1614.5,378.8z"
						/>
						<path
							d="M1597,342c-7.4-3.7-12.1-8.9-19.5-2.3c-3.4,3-14.1,11.9-4.4,11.3c2.5-0.2,7.2-0.2,9.3-1.6c3.2-2.2,0.6-5,2.3-7.2
									c2.5-3.4,5.2,1.2,8.6,2.3c0.8,0.3,2.3,1.7,3.4,1.1C1597.8,344.9,1598.8,342.9,1597,342z"
						/>
						<path
							d="M1602.8,357.8c-3.9-2-5.9-8-10.4-7.1c-2.6,0.5-4.5,5.2-7,6.2c1.1-0.4-7.3,0-4.6,0.1c-6.2-0.3-10.5-2.3-15.2-6.5
									c-3.5-3.1-5.5-6.7-10.3-3.9c-3.5,2-6.4,6.5-9.6,9.1c-2.9,2.2-10.9,7.5-3.3,7.8c1.3,0,3.1-1.2,4.6-1.4c2.3-0.2,3.5,0.5,5.9-0.3
									c3.1-1.1,9.1-5,10.4-8c4.3,4,10.3,7.2,16.2,7.2c-3,1.7-3.1,4.1,0.5,4.3c2,0.1,5.4-3.3,7.5-3.6c0.6-0.1,1.4,1.1,2.8,0.9
									c0.8-0.1,2.9-0.8,3.4-1.4c1.5-1.9-0.8-3.5,1.1-5.3c1.3,1.4,4.5,5.9,6.8,5.4C1602.9,361,1605.3,359.1,1602.8,357.8z"
						/>
						<path
							d="M1580.3,336.6c-3.7-1.1-7-4.9-10.6-5.6c-3.7-0.8-6.3,1.9-9.3,3.5c-1.7,0.9-21.9,7.2-13.1,9c1.9,0.4,9.2-2.2,11.1-2.7
									c3.1-0.8,7.8-4.4,10.5-4.6c2.7-0.2,5.6,3.8,9.1,3.9c1.1,0,2.6,1.1,3.4-0.4C1581.9,338.5,1581.7,337,1580.3,336.6z"
						/>
						<path
							d="M1619.3,316.1c-8.8-3.4-14.1-11.6-19.5-18.9c-1.4-1.9-5.8-9.3-8.5-9.4c-4.1-0.2-2.8,5.7-7.2,3.9c-1.4-0.6-1.6-3.1-3.5-3.1
									c-3,0-1.2,2.1-2.4,3.5c-2.3,2.5-4,0.6-7.3,2.7c-3.1,2.1-5.2,9.4-7.4,12.6c-3,4.2-6.5,7.6-10,11.4c-3.1,3.3-3.8,4.9,2.3,5.6
									c5.7,0.7,7.5,0,11.9-2.8c2.7-1.7,9.2-1.3,9.9-4.9c0.2-1.2-2-4.9-2.1-6.6c-0.1-10.6,6.6,1.9,9.5,3.5c3.5,1.9,10.4-0.8,10.2-5.2
									c-0.1-1.8-2.9-3.6-3.6-5.4c-1.1-3.1-0.5-6.1,0.7-9.1c5.4,6.1,9.5,13.2,15.4,18.9c2.3,2.2,6.1,6.1,9.5,6.6
									C1619,319.7,1623.4,317.6,1619.3,316.1z"
						/>
						<path
							d="M1662,308.3c-4-2.7-8.5-8-13.1-9.5c-4.9-1.7-6.1,1.5-8.1,5.2c-2,3.7-3.7,6.7-7.3,9.1c-1.6,1.1-7.9,3.4-4.1,5.9
									c1,0.7,7.5-0.8,8.3-1c4.3-1.2,5.7-2.8,10.5-2.2c2.5,0.3,9.4,3.8,9.6-0.4c0-1.1-4.2-2.7-4.9-3.4c-2-2.1-2.3-4.2-2.4-7
									c1.7,1.3,7.3,6.7,9.4,6.7C1661.5,311.7,1664.9,310.3,1662,308.3z M1649.9,313.3c-0.8-0.9-1.3-2-1.4-3.2
									C1648.7,310.6,1650.6,313.5,1649.9,313.3z"
						/>
						<path
							d="M1552.2,327.1c-2.6-2.1-5.9-3-8.6-5c-1-0.8-3.2-3.6-4.4-4.1c-2.3-1-4.4-0.1-5.7-1.5c-2.3-2.3-1.6-5.2,1.7-4.6
									c4.3,0.9,8.4,8.1,13.7,6.8c5.9-1.5-0.4-4-2.7-5.1c-4.5-2-9-7.1-14.2-6.5c-4.7,0.5-7.8,5.2-11.3,7.7c-2,1.4-9.3,3.4-5.5,6.6
									c0.7,0.6,5.6-1,6.1-1.1c3.6-0.6,7.5-1,11,0.1c-1.6,2.6-3.8,5.1-6.3,6.9c-2.5,1.8-6.4,1.6-8.4,3.1c-5.9,4.2,3.7,3.8,6.9,2.4
									c4.8-2.2,7.4-4.2,12.7-1.7c2.3,1.1,3,5.3,6.2,1.8c2.6-2.9-0.8-4.5-1.6-7.1c1.7,1,5.8,4.1,7.7,4.3
									C1550.5,330.1,1555.1,329.3,1552.2,327.1z"
						/>
						<path
							d="M1543.5,302c-5.6-2.9-11.4-5.6-16.6-9.2c-3.4-2.3-7.8-9.5-12.2-8.5c-4.3,1-6.4,8.7-11.4,9.2c-4.3,0.4-4.7-6.2-8.6-6.3
									c-3.4-0.1-11,8.2-14.5,9.8c-2.5,1.2-12.3,1.8-12.5,4.3c-0.6,7.8,25.8-5.6,28-7.9c0.8,1.1,1.8,2,2.9,2.9c-4.2,2.4-8.6,4.4-12.9,6.8
									c-4.4,2.5-4.5,3.9,0.1,4.3c5,0.5,11-4.3,16.2-4.9c1-0.1,0.7,1.2,1.9,1.3c1.3,0.1,2.1-1.1,3.4-0.6c2,0.8,1.5,2.9,4.3,3.3
									c2.1,0.3,7.5-2.5,8-4.7c0.5-2.2-0.9-2.1-1.5-4.3c-0.5-2.2,0-4.3,0-6.5c4.5,4.4,10.4,7.5,15.8,10.4c1.3,0.7,6.1,4,7.6,4
									C1543,305.5,1546.6,303.6,1543.5,302z M1515.2,301.8c-1.4,0.7-2.9,1.3-4.4,1.7c1.1-1,1.2-3.4,2.8-4
									C1515.5,298.7,1516.8,300.2,1515.2,301.8z"
						/>
						<path
							d="M1534,274.1c-8.8-2.8-13.4-11.1-18.5-18.1c-2.1-2.8-4.7-7.6-8.5-6.6c-3.5,0.9-9.5,7.3-12,9.6c-3.7,3.5-6.5,7.9-10.3,11.3
									c-1.8,1.6-9.6,5.4-3.8,7.1c2.6,0.8,4.1-3.3,6.1-3.8c2-0.4,2,0.2,4.5,0.8c5.2,1.3,7.3,1.1,12.6-0.8c-0.2,1.8-2.3,5.8-0.8,7.3
									c0.5,0.5,8,0,8.9,0c8.8,0.4,4.4-5.9,8.8-11c2.4,2.8,6.8,7.4,10.7,7.8C1533.7,277.7,1538.1,275.3,1534,274.1z M1510.6,267.3
									c-0.7,0.3-1.4,0.5-2.1,0.8c0-4.2,1.5-8.5,1.8-12.6C1514.2,260.2,1516.8,263.1,1510.6,267.3z"
						/>
						<path
							d="M1487.8,277c-6.6-3.8-11.8-8.9-18-13c-6.7-4.5-14.7,2.2-20.3,6.1c-5.6,3.9-9.9,9-16.2,11.8c-1.6,0.7-5.5,0.4-3.8,3.5
									c1.3,2.3,5.5-0.3,7.2-0.4c2.9-0.1,6.2,2.9,8.1,2.8c5-0.3-0.3-2.8,0.5-4.2c1-1.8,6.4-1,9.8-0.8c2.1,0.1,12.2,3.8,13.2,3
									c2-1.5-1-4.9-1.5-6.2c-1.7-4.3-2.1-7.9,0.9-11.9c4.6,3.2,8.1,7.2,13.1,10c1.5,0.9,4.8,3.7,6.5,2.9
									C1488.7,280,1489.4,278,1487.8,277z"
						/>
						<path
							d="M1442.8,379.5c-3.4-1.6-6.5-6.4-9.9-7.2c-4.7-1.2-5.1,3.2-8.4,5.1c-2.7,1.5-1.2,0.1-4.2-0.1c-1.7-0.1-2.6,0.2-4.2,0.4
									c-4.3,0.7,0.5,2.8-3.9,0.8c-1.4-0.7-2-2.6-3.7-3c3.8-3.6,5.2-11.3,5.6-16.3c2.2,2.2,11,13.9,14.3,13.3c5.9-1-6.1-10.8-7.2-11.8
									c-2.5-2.5-10.7-13.3-13.9-13.3c-2.4,0-6.8,9-8.6,11c-3.4,3.8-6.5,7.9-9.9,11.6c-1.5,1.7-10.1,6.3-10.2,8.1c-0.2,4.4,8,0.4,9.2,0.2
									c6.1-1,12.4,1.5,18.3-1.2c-2.2,6.7-7.3,7.7-12.8,11.6c-1,0.7-5.1,3.1-5,4.8c0.4,4.2,4,0.1,5.1,0.1c2.4-0.2,4.8,1.7,6.5,1.7
									c1.9,0.1,2.9-1.7,5-1.7c3.6,0,2.2,1.8,6,0.6c2.9-0.9,4.2-2.3,4.4-5.2c1.7,2.2,4.5,3.4,4.5-0.4c0.1-3.7-5.6-3.3-2.2-6.9
									c0.9,3.6,5.6,5.5,8.8,5.7c4.5,0.2,2.5,0.3,3.7-2.7c0.6-1.4-0.4-2.2,1.3-3.5c1.3-1,4.1-0.8,5.6-0.4c1.6,0.4,3.4,3.3,5.4,2.2
									C1443.6,382.4,1444.6,380.4,1442.8,379.5z"
						/>
						<path
							d="M1527.5,407.3c-6.3-3.3-13.8-2.4-20.3-6c-6.5-3.6-7.9-5.3-14.6-1.4c-5.9,3.4-7.8,2.1-12.9-2.8c-1.4-1.4-3.8-5.5-6.3-4.6
									c-2,0.7-4.3,6.6-5.8,8.3c-3.1-5.3-8.2-19.7-13.6-9.8c-2.2-1.7-4.7-5.6-7.1-6.7c-5.6-2.7-5.6,3.6-9.7,5.7c-3.3,1.7-2-0.6-5.8,0.1
									c-2,0.4-4.3,3.2-5.9,4.2c-5.8,3.7-12.5,2.3-18.2,5.2c-5.4,2.8-1.8,4.3,2.6,3.4c1.7-0.4,4.7-1.5,6.3-1.1c0.7,0.1,1.2,1.6,1.9,1.6
									c3.2,0.1,0.2,1,4.1-0.6c1.9-0.8,3.5-2.6,5.7-3.1c6-1.3,10.2,4.5,16,5.1c-3.4,3.7-6.5,8.7-0.7,10.6c3.9,1.3,8.5-1.3,12.6-1.5
									c-2,3-0.4,3.1,1.6,3.3c0.6,0.1,1.4-1.3,2.5-1.5c1.3-0.2,2.7,1.4,4.3,1.1c8.1-1.5-1.2-8.6,1.3-12.6c2.2,3.7,4.9,7.1,8.6,9.4
									c4.5,2.8,8.2,1.4,13.3,0.8c6.9-0.8,5.1-2.7-0.4-4.1c-4.8-1.3-9.3-3.5-8.8-9.2c6,6,15.4,10.6,22.5,14.6c3.6,2,6.5,4.8,11.2,3.7
									c5.8-1.4,1.8-4.2-1.4-6.9c-1.9-1.7-5.8-3.6-7.2-5.7c-2.1-3.1-2-3.2,1.2-2.7c4.7,0.7,9.3,4.3,14.4,5c1.5,0.2,6.4,2.1,7.5,1.6
									C1527.3,410.3,1530.3,408.7,1527.5,407.3z M1448.5,399.7c-3.2-1.5-7.1-3.2-3.6-7.2c0.8-0.9,0.9-1.8,2.3-2c0.2,0,4.7,4.1,4.5,3.6
									C1452.1,395.3,1449.3,398.6,1448.5,399.7z"
						/>
						<path
							d="M1414.8,248.3c-5.9-1.8-9-7.4-14.3-9.7c-6.1-2.7-10.3,3.5-15.3,6.3c-3.6,2.1-8.6,2.9-3.7,6c0.6,0.4,1.9,0,2.5,0.2
									c2.7,1,4.7,3.2,7.5,4c4,1,11.9,0.1,10.8-5.6c-0.8-4.1-2-0.6-4.4-3c-0.9-0.9-2.1-3-1.1-4.3c1.5-2,8.9,5.7,10.3,6.7
									c1.2,0.8,6.2,4.5,7.8,3.3C1416.3,251.2,1416.8,249,1414.8,248.3z"
						/>
						<path
							d="M1503.2,251.8c-5.1-5.1-7.7-10.2-11.4-16.1c-2.2-3.6-5.9-3.6-8.4-6.7c-2.6-3.2-3.9-11.1-9.5-11c-5.4,0.1-6,9.5-10.1,9.1
									c-2.1-0.3-3-3.4-5.2-3.6c-3.3-0.4-4.5,2.4-6,4.8c-1.7,2.6-3.2,5.2-5,7.7c-1.4,2-7.4,8.4-7.3,10.7c0.4,5.9,6.4-0.5,9.2-2.3
									c2.3-1.5,5.4-3.5,7-5.7c1.6-2.2,0.7-5.5,1.9-7.1c0.6,0.7,3.1,1.8,3.3,2.3c0.2,0.8-2.1,4.2-2.5,5c-0.8,1.6-1.6,3.6-2.7,4.9
									c-1.4,1.7-1.6,1.4-2.9,2.3c-2.2,1.6-9.5,6.6-7.2,10.7c-2.9-1.5-6-5.6-9-6.4c-3.6-1-4.8,1.2-7.2,3.3c-4.8,4-11.7,8.7-17.8,10.4
									c-1.8,0.5-4.6-0.6-4.7,2.2c-0.2,3.8,7.2,0.9,8.4,0.5c1.6-0.6,2.5-1.3,4.3-1.6c1.5-0.2,5,1.1,6.2,0.5c1.4-0.7,3-2.4,0.4-3.6
									c3,0.1,5.7,1.1,8.2-0.8c2.1-1.6,1-3.8,4.5-3.9c2.9-0.1,9.2,5.2,12.3,6.3c2.1,0.8,3.5,2.2,5.3,0.2c0.8-0.9-0.2-2.3-1-2.7
									c1.5-1.1,0.9-5.2-0.9-6c1.4,0.6,3,0.1,4.8,0.8c1.9,0.8,7.9,6.5,10.4,3.8c2.2-2.4-9.7-8.3-11.8-10.8c5.1-1,8,4.4,12.4,5.5
									c2.6,0.7,3.3-1.9,4.9-1.6c1.9,0.3-0.2,3.1,1.5,4.3c1.9,1.3,4-1.5,6.3-1.3c3.2,0.2,4.8,3.4,8.2,1.2c7.8-5-4.1-7.3-6.5-9.3
									c-5.4-4.4-6.3-10-8.9-16c-2.5-5.9-1.3-7.2,1.8-1.5c1.2,2.3,1.9,2.9,4.4,4.1c4.3,2,4.8,2.2,7.1,6.5c2.2,4,4.7,7.4,7.8,10.7
									c1,1,2.6,3.7,4.3,3.5C1503.3,255,1504.5,253.1,1503.2,251.8z"
						/>
						<path
							d="M1373,238.6c-7.3-2.2-16.5-6.7-23.2-2.4c-2.1,1.4-9.5,5.5-10.3,7.9c-1.6,5.5,3.3,1.8,5.3,0.6c3.8-2.2,4.5-0.6,8.2,0.2
									c3.5,0.8,4.7-0.5,7.9-2.2c4.1-2.1,4.7-0.5,9.5-0.6C1371.8,242.1,1377.8,240,1373,238.6z"
						/>
						<path
							d="M1446.3,234.6c-6-1.5-10.5-4.5-15.1-8.6c-5.3-4.7-7.7-5.1-13.4-0.3c-2.6-3.2-5-6.5-7.4-9.8c-3.1-4.3-3.6-5-7.4-1.7
									c-4.7,4.2-4.7,6.8-10.7,2.9c-3.8-2.5-7.2-5.6-10.7-8.3c-6.3-4.9-10.6-7.7-16.2-0.7c-2.7,3.4-5.6,6.9-8.1,10.5
									c-0.9,1.2-3.1,3.6-3.3,5.1c-0.4,2.7,1.2,1.8,1.3,3.9c-3.2-1.3-6.2-3.2-8.8-5.4c-1.5-1.3-3.8-5.1-5.4-5.6
									c-4.5-1.3-8.9,6.4-11.9,9.2c0-0.1-1.2-3.7-1.2-3.6c-0.4-0.9,0.5-2.3,0.1-3.2c-0.6-1.4-2.5-2.1-3.3-3.3c-0.6-0.8-0.4-2.3-0.8-3.1
									c-1.3-2.3-2.3-2.1-2.5-5.6c-0.2-3.3,0.9-6.4,1.3-9.7c3.9,5.8,9.5,14.3,16.2,15.2c2,0.3,9.1,0.9,9.6-1.3c0.5-2.2-7.8-4.3-2.1-6.1
									c3.6-1.1,7.7,7.6,11.3,7.2c5.8-0.7-1.8-5.8-3.4-6.9c-7.9-5.5-13.9-11.5-22.1-2.9c-4.3-4.5-7.1-9.5-10.6-14.5
									c-2.7-3.9-4.8-7.7-9-3.9c-3.8,3.4-5.6,10.3-7.4,14.9c-2.1,5.3-4.5,10.3-7.8,14.9c-1.4,2-10.8,10.4-10.4,12.8
									c0.7,4.9,9.7-1.6,11.8-2.2c6.7-2,6.6,1.9,12,3.5c4.9,1.5,11.1-1.4,16.4-0.4c-2.8,2.3-5.7,3.9-9.2,5c-1.6,0.5-3.9,0.1-4.5,2.1
									c-1.5,5,8.5,0.4,9.8,0c3.2-1.1,4.3-1.9,8-1.4c2.5,0.3,5.1,1.6,7.5,1.7c8.1,0.1,3.9-4.4,5.2-9.9c2.3,2,5.4,4.6,8.4,5.5
									c2.9,0.8,3-0.3,4.6,0c1.2,0.1,2.3,0.2,3.5,0.2c0.7,0.8,1.5,1.4,2.4,1.9c1.6-0.1,4.1-4.3,5.2-3.9c3,0.9,0.1,3.2,0.6,4
									c2.5,4.2,0.6,0.1,4.1-0.1c2.9-0.2,8.1,1,10.9,2.1c-3.7,5.2,6.3,1.3,8.4,0.9c6.8-1.2,8.5,0.2,14.8,2.3c2.6,0.9,6.2,3.9,8.5,1.6
									c3.7-3.6-1.8-6.7-3.7-8.2c-3.9-3.1-8.6-8.5-5.1-13.8c4.3,5.9,8.6,11.2,13,16.8c2.1,2.7,4.3,6.6,7.5,8.3c1.6,0.9,8.2,2.9,10,1.6
									c5.5-4.2-10.2-8.2-6-11.1c2.2-1.6,9.9,4.7,12.6,4.9c1.1,0.1,2.9,1.1,3.6-0.5C1447.9,236.4,1447.8,234.9,1446.3,234.6z M1325,224
									c-3.2-0.5-4.9-2.4-1.3-5.2C1325.2,220.4,1324.9,222.1,1325,224z M1375.8,209.2c1.3,0.9,2.6,1.9,3.9,2.9
									C1377.6,212.2,1375.5,211.7,1375.8,209.2z M1388.3,228.9c-1.1-1.3-2.8-1.8-3-3.8c0-0.4,1.6-1.4,1.4-2c-1.3-5.1-3.4-1.4-5.9-4.2
									c-2.4-2.7,0.3-4.3,2.5-3.9c2.4,0.4,8.7,6.3,11,7.7C1392.3,224.8,1390.3,226.8,1388.3,228.9z"
						/>
						<path
							d="M1276.2,161.2c-7.3-0.1-13.5-1.9-20.2-4.8c-5.7-2.5-10.2-4.1-16.3-1.7c-3,1.2-6.3,2.9-9,4.7c-1.6,0.9-3.1,1.9-4.4,3.2
									c-1.2-0.6-2.5-0.8-3.8-0.6c-5.7,2-10,7.3-14.9,10.8c-3.6,2.5-5.5,3.6-9.6,3.8c-2.8,0.2-4.9-0.2-7.7,0.4c-1.8,0.4-16.7,4.8-13.6,8
									c1.8,1.9,10.4-2.1,13.3-2.2c2.4,0,16.5,2.1,16.7,5.3c0.2,2.2-11.7,5.4-7,8.7c1.7,1.2,5.7-3.6,8.1-1.3c4.2,3.9-28.1,9.5-21.8,14.8
									c2.6,2.3,13.2-5.8,16.3-6c4.7-0.4,8.7,2.8,13.7,1.8c2.6-0.5,2.9-1.5,5-2.6c2.9-1.5,3.5-0.8,6.5-0.7c5.2,0.3,9.7-0.7,13.9-3.5
									c4.9-3.2,5.6-1,10.9-1.8c5-0.7,6.1-3.3,1-5.4c-6-2.5-12.1,0.7-18.2,1.5c-1.8,0.2-15,3.1-12.3-2.3c1.2-2.4,6-2.4,8.2-2.5
									c1.9-0.1,3.6,0.2,5.5,0.4c1.9,0.2,7.8,2,9.2,1.2c5.5-3.1-7.1-5.6-8.3-5.9c-5.5-1.5-13.1-7.8-18.7-6.4c0.5-1.3,0.7-2.7,1.8-3.6
									c1.1-0.8,3.8,0.1,4.5-0.9c2.5-3.1-3.3-4-1.2-6.8c1.7-2.3,8.5,3.8,10,4.6c5.1,2.8,10.7,4.8,16.3,6.1c4.9,1.2,10.3,2.4,15.3,2.4
									c1.4,0,11.3-0.6,8.2-4.3c-1.2-1.4-9.4,0-11.7-0.3c-5-0.5-10-1.3-14.8-2.7c5.2-1.7,8.7-1,5-5.5c-3.3-4-5.1-6.7,2.2-6
									c4.9,0.5,9.8,2.3,14.6,3.1c1.9,0.3,7,1.8,8.5,0.3C1278.7,163.6,1278.2,161.3,1276.2,161.2z M1221.5,182.2c1.7,0.6,3.3,1.5,4.7,2.6
									c-3.7,0.1-6.9,0.1-10.3-1.6C1217.7,182.4,1219.6,182.1,1221.5,182.2z"
						/>
						<path
							d="M1219.5,161.6c-9.9-3.4-20.6-4.8-30.2-8.5c-5.3-2.1-8.6-4.4-14.4-2.7c-5,1.4-10.1,6.2-15.2,6.7
									c-9.3,0.9-17.6-7.8-25.6-10.9c-4.5-1.7-8.2-1-12.2,1.6c-1.7,1.1-9.2,5.9-9.2,8.2c-1.8-1.4-6.6-6.8-9.1-6.4
									c-4.3,0.7-1.9,5.8-6.8,5.3c-3-0.3-4.7-2.5-8.2-2.2c-3.8,0.4-6.5,3.8-9.9,4.5c-6,1.2-10.3-5.6-16.1-4c-2.3,0.6-15.1,8.4-13.9,11.5
									c-5.1-1.6-12.4-0.5-17.7-0.6c-7-0.1-10.5,2.8-16.5,5.5c-2.4,1.1-4.9,1.8-7.5,2.1c-1.6,0.2-6.8-0.4-8.2,0.3c-5.3,2.8,1.5,4.1,4.3,4
									c5-0.1,9.8-1.9,14.6-0.9c5.3,1,10.2,0,15.8,0.5c2.7,0.2,3.4,0.5,5.8,1.2c1.8,0.6,3.2,1.9,5.4,1.6c7.5-1.2-1.2-5.8-0.6-10.4
									c4.5,0.1,13.7,7.2,17.5,5.1c5.6-2.9-8.9-6.9-10.2-7.5c2.6,0.4,17.5,6.4,19.4,3.1c1.5-2.7-8.5-7.9-0.6-9.3c2.2-0.4,5.6,2.3,7.3,2.5
									c2.3,0.2,5.1-0.5,7.7-0.3c1.6,0.1,6.6,1.5,7.2-1.1c0.5-2-1.2-2.6-2.8-2.6c2.7-0.9,4.2-0.2,6.7,1c-1.2,1.6-6.5,7.3-6.2,8.9
									c-14.8-7.1-22.7,6.1-36.6,9.4c-1.9,0.5-15.7,3.2-9.6,6.3c2.3,1.2,12.7-3.2,15.3-3.5c5.8-0.7,10.6-1.7,16.1-0.1
									c3,0.9,6.3,0.7,9.3,1.6c2.4,0.8,4.2,3.1,6.9,2.9c1.6-0.1,5.4-1.8,4.1-4c-0.8-1.4-3.6-0.4-4.9-1.2c-2.5-1.6-1.9-3.1-3.4-5
									c-1.3-1.7-4.2-2-3.8-4.7c10.6,2.7,21,11.3,32.7,9.1c7.6-1.4,4.1-4.8,0.3-8.4c-3.5-3.4-8.6-7.4-8.6-12.7
									c7.5,5.9,14.7,9.9,24.2,11.4c1.6,0.2,3.3,0.2,4.8,0.7c1.1,0.4,3.6,2.9,4.8,3c1.2,0.1,4.3-1.4,6-2.1c0.5,4.5,0.1,6.4-5.2,7.8
									c-4.7,1.2-6.2-1.5-9.7-3.6c-7.4-4.5-10.7,5.3-17.2,7.4c-2.5,0.8-6.9-0.7-8.2,2c-1.9,4,6.9,1.9,7.8,2.2c3.3,1,4.7,3,8.6,3.4
									c3,0.3,6.8-0.7,8.3,2.9c-5.4-1.4-10.3,0.5-15.3,2.6c-4.6,1.9-10.6,3.3-14.7,5.8c-4.9,3.1-2.7,4.6,2.1,3.4c2.8-0.7,5.8-1.5,8.7-1.7
									c3.5-0.3,4.4,1,7.2,1.5c4.1,0.8,9.3-0.9,13.6-0.5c2.6,0.2,10.6,3.6,12.6,0c1.7-3.3-6.1-2.6-7.4-5.5c2-0.3,1.9-1,3.6-0.9
									c1.5,0.1,3.1,1.6,4.7,1.9c3.2,0.6,6-0.2,9.3,0.5c2.5,0.5,4.7,1.9,7.3,0.9c0.4-0.2,1.5-2,2.6-2.5c4-1.9,7.8,0.8,11.1,2.5
									c2.3,1.2,10.4,5,12.7,2.4c1.1-1.3-0.3-4.2-0.7-5.3c-0.1-0.4,0.4-2.1,0-3.3c-0.6-1.5-4.4-3.4-5.6-4.5c-2.6-2.4-5.5-6.8-8.9-8.1
									c3.2-2.4,4.8-2.8,1.4-6.9c-1.6-1.9-4.5-2.3-5.9-3.6c-1.9-1.8,3.1-0.9,2.8-0.9c2.6-0.1,5.6,1.1,8.2,1.6c2.8,0.5,14.8,4.8,16.3,3.5
									c4.3-3.8-5.6-5.5-7.8-6c-6.9-1.8-13.6-2.4-20.3-5.3c1.8-1.1,7.3,0.7,9.1,0.9c3.7,0.5,6.8,2.9,10.5,2.3c4.2-0.7,5.7-2.5,2.3-4.8
									c-1.6-1.1-8-0.8-9.1-2.7c-2.1-3.6,2-2.1,3.2-2c5.3,0.1,11.1,1.4,16.2,2.5c4.8,1.1,9.1,2.9,13.8,4.3c0.9,0.3,4,2,4.9,0.2
									C1221.2,163.5,1220.9,162,1219.5,161.6z M1040.2,169.2c0.8,0.5,0.5-1.4,1.3,1.2C1041.1,170.1,1040.6,169.6,1040.2,169.2z
									M1124.9,150.7c3.3-2,6.1-1.5,9.2,0.1C1131,151.4,1128,151.3,1124.9,150.7z M1144.7,167.5c-0.3,0.5-0.7,0.9-1,1.4
									c-2.1-2.2-7.8-6-8.5-9.1c-1.1-4.8,1.4-3.8,4.4-1.9c3,1.9,4.3,4.9,8.4,5.1c1.6-0.1,3.2-0.5,4.7-1.3c0.3,0.4,0.6,0.7,0.8,1.1
									c2.3-0.2,4-0.4,6.2-0.1C1154.8,164.5,1149.5,165.1,1144.7,167.5z M1147.2,191.4c-6-2.7-7.1,3.9-12.4,1.9c3.2-2.3-4.6-7.7-4.8-9.3
									c-0.9-5.5,5.2-2.1,8.2-1.7c6.1,0.8,9.2-4.1,14-4.1c3.2,0,9.4,3.3,9.6,6.8C1155,182.9,1151,185.6,1147.2,191.4z"
						/>
						<path
							d="M1805.4,292.8c-4.6-5.1-13.2-10.5-17.7-2.8c-7.6-6.1-7,0.3-12.4,4.9c-2.7,2.3-1.9,2.1-5,1.3c-1.6-0.4-3.4-1.8-4.8-2.6
									c-2.2-1.2-5.1-3.4-7.7-3.3c-3.1,0.2-4,2.9-6.4,2.6c-3-0.4-4.1-4.9-7.5-4.8c-5.2,0.3-13.1,11.7-15.3,15.9c-2-2.2-4.8-6.6-7.4-2.8
									c-2.6-2.5-6.8-9.4-10.6-9.9c-3.8-0.5-7.5,7.2-10.1,9.7c-4.7-5.1-7.8-7.2-11.8-0.4c-1.8,3-3.1,6-5.9,8.2c-2.3,1.7-1.3,2.1-3.6,1.9
									c-1.2-0.1-2.9-2.4-4.3-2.9c-6.7-2.1-9.5,5-13.6,9c-2.3,2.2-5,4.3-7.9,5.6c-1.8,0.8-7.2,0.8-7.7,3.1c-1.5,5.9,12.3-1,14-1.3
									c2.8-0.4,5.3,1.1,7.9,1c2-0.1,3.5-1.3,5.5-1.3c1.6,0,5.2,2.5,7.1,2.2c5.7-0.7-3.8-9.9-5.1-13.5c2.9,2,5.2,5.8,8.4,7.3
									c2.1,0.9,2,0.2,3.5,0.3c0.2,0,3.2,0.3,4.1,0c1.8-0.5,4.7-1.7,4-4.2c-0.6-2.1-3.6,1-3.4-2.4c0.3-5.3,2.6-0.6,3.7-1.1
									c2.5-1.2,2.2-3.2,4-4.7c2.2-1.7,3.2,0.1,5.7,2.6c1.3,1.3,2.5,1.9,3.3,3.9c0.3,0.7-1.2,1.6-0.1,2.9c1,1.1,6.1,2.2,7.9,2.5
									c3.4,0.6,6.9,2.4,10.3,2.8c3.2,0.4,4.5-0.1,3.7-3.2c-0.6-2.5-7.4-9.7-1-8.7c1.9,0.3,3.8,3.8,6,3c2.1-0.8,1.3-3.2-0.1-4.2
									c1.9-1.8,4-1.3,5.5-3.3c-0.2,0.3,0.4-3,0.6-3.4c1.5-3.1,5-7.2,8.8-5.8c-1.5,1.6-7.9,5.8-7.2,8.6c0.6,2.7,6.7,2.5,8.6,2.5
									c2,0,3.8-1.1,5.8-0.7c1.3,0.2,2.5,1.5,3.7,1.8c3.9,0.7,4.6-0.6,3.8-4.1c-0.4-2.1-3.3-3.7-2.2-6.1c1.5-3.3,5.3,0.5,7.6,1.6
									c2.3,1.1,5.9,3.7,8.6,2.4c1.3-0.6,0.2-2.1,0.6-2.7c1.7-2.6,3.3-5.3,4.7-8.1c3.3,2.9-2,4.4,1.2,7.1c1.6,1.3,7.4,0.8,9.3-0.6
									c1.5-1,3.1-5.6,5.1-5.7c1.4-0.1,2.8,3.7,4.5,3.3C1805.5,295.9,1806.7,294.3,1805.4,292.8z M1713.6,309.2c-1.8,1.3,0-5.5,0-5.4
									c-0.1-2.3-2.4-4.4-1.9-6.8c1.3,1.1,6,4.8,6.3,6.5C1718.3,305.9,1716.1,307.3,1713.6,309.2z"
						/>
						<path
							d="M1801.8,311.5c-2.9-2-7.1-8.7-10.3-8.9c-2.3-0.1-3.6,3-5.6,3.7c-10.6,3.5,3.6,0.9-4.4-0.2c-4.2-0.6-5.6,0.7-9.2,3.1
									c-2,1.3-9.6,4.3-6.6,7.2c0.7,0.7,5.3-0.2,6.4-0.4c4.8-0.6,7.1,1.5,11.5,2.1c2.1,0.3,6.6,0,8.6-1.1c4.6-2.7,0.3-3.8,0.9-8.2
									c1.3,1.4,4.9,5.9,6.9,6.1C1801.5,315.1,1804.4,313.4,1801.8,311.5z"
						/>
						<path
							d="M1875,294.3c-9.2-5.2-14.2-15.6-22.8-21.5c-4.6-3.2-6.1-0.2-8.8,4c-2.7,4.2-5.9,8.3-8.4,12.7c-2.2-2.5-7.4-10.3-10.9-10.6
									c-4.5-0.4-4.7,6.6-7,8.3c-0.7,0.5-3.4,0.7-4.6,1.5c-1.8,1.1-3.8,3.1-5.3,4.5c-2,1.9-9.6,7.7-9.8,10.6c-0.4,5.4,4,0.2,5.9,0
									c3.8-0.4,4.9,3.6,8.2,3.3c1.2-0.1,2.3-2.3,3.9-2.4c1.2-0.1,2.4,0.9,2.9,1c0.6,0.2,1.5,1.3,2.6,1.4c1.4,0.1,2.7-1.2,4.2-1.2
									c2.1,0,7.2,2.8,9.1,1.4c4.2-3.1-4.8-6.8-5.9-9.5c-1-2.3-0.2-7,2.2-7c1.8,0,6.1,6.1,7.3,7.3c2.9,2.8,6.2,6.2,10.2,6.1
									c1.4,0,2.7-1.2,4.1-1.1c1.5,0,3.2,1.7,4.1,1.7c4.3-0.1,2.8-2.8,1.6-6.8c-0.9-3-1.2-8.6-4.8-9.6c3.2-2.4,3.6-3.8,7-2.1
									c2.3,1.2,4,4,5.9,5.7c1.5,1.4,5.2,5.6,7.4,5.6C1874.5,297.7,1878,296,1875,294.3z M1842.6,296c0.3-0.1,1.6,1.5,1.5,1.9
									C1843.5,297.3,1843,296.6,1842.6,296z"
						/>
						<path
							d="M1913.9,298.6c-3.1-3.2-7.4-5.2-10.7-8.3c-3.1-3-4.6-5.1-7.5,0c-2.6-3.6-3.5-7.7-8.1-6.2c-3.8,1.2-7.5,6.6-10.3,9.2
									c-1.7,1.5-9.1,6-8.8,8.6c0.5,3.7,7.1-0.2,9-0.3c4.1-0.2,7.4,2.2,11.6,1.6c3.6-0.6,5.8-2.4,9.7-2c1.5,0.1,4.7,1.2,5.8,0.2
									c3.3-3.2-7.7-5.4-5.1-9.3c2.8,3.5,5.8,5.5,9.6,7.9c0.8,0.5,2.4,2.5,3.5,2.3C1914,301.8,1915.2,299.9,1913.9,298.6z"
						/>
						<path
							d="M1963.8,295.8c-6.4-0.7-11.9-8.2-17.6-11c-4.1-2-3.7-1.8-6.9,1.2c-2.8,2.6-5.6,5.5-8.1,8.5c-3.1-3.4-5.9-9.5-10.2-6.7
									c-2.1,1.4-3,5.6-4.4,7.6c-1.6,2.3-3.6,4.2-5.7,6.1c0,0-3.6,3.7-4.1,3.8c-2,0.2-3.7-4.1-6.3-3.5c-3.2,0.8-7.7,9.9-10.1,12.2
									c-1-3-6-3.5-8.2-3.6c-0.8,2-1.7,2.4-2.7,1.2c-0.6-0.4-1.2-0.9-1.8-1.4c-1.4-0.6-2.1-2.5-3.8-2.4c-2.3,0.2-6.1,5.8-7.9,7.2
									c-3,2.4-6.3,4.5-10,5.6c-1.9,0.6-8.3,0.2-8.9,2.6c-1,3.7,5.1,2.4,6.7,2.1c3.4-0.6,6.4-2.4,10-2.5c4.4-0.1,7.9,2.5,12.1,3
									c4.2,0.5,7.3-3,10.4-3c2.3,0,5.3,1.8,7.5,2.2c1,0.2,2.8-0.5,3.9-0.4c3.4,0.1,0.9,2.6,4.3,0.8c1.3-0.7,2.7-4.2,3-5.6
									c0.6-2.7-1.8-6.1-0.2-7.8c2.5-2.8,5.8,1.1,7.7,2.5c1.4,1.1,6.8,5.7,6.1,0.4c-0.3-2.5-6.9-5.8-8.7-7.3c3.3-3.2,11.3,2.1,15.6-0.6
									c3.7-2.3-0.3-9.4,3.8-8.6c2.6,0.5,4,5.7,7.3,6.3c2.6,0.4,3.7-0.6,6-2.1c1.8-1.2,4.2-1.8,4.9-3.7c0.5-1.1-0.6-2.8-0.2-4.1
									c0.4-1.3,1.3-2.2,1.7-3.4c3.5,2.4,7.9,7.1,12.3,7.9C1965.1,299.9,1967.5,296.2,1963.8,295.8z M1882.3,321.1
									c-0.4,0.4-4.5,0.2-5.1-0.2c1.5-0.9,5.4-6.2,6.4-6.4c1.4-0.3,2.6,1.1,4.2,1.8C1885.9,317.8,1884,319.4,1882.3,321.1z"
						/>
						<path
							d="M2011.4,329.1c-3.4-3.8-7.5-11.1-13.9-9.3c-3.6,1-5.1,5.2-8.1,7c-3.8,2.3-5.5-0.4-9.7-2.2c-4.7-2-8.7-1.5-13.7-1.2
									c-4.5,0.2-6.9-1.9-11.2-3.1c-2-0.6-5.3,0.5-6.9-0.1c-1.8-0.7-2.7-3.5-4.5-4.3c-4.3-2.1-5.3,0.7-8.2,4.3c-1.9-2.1-5.2-5.7-8-6.5
									c-4.6-1.3-6.1,2.7-9,5.3c-2,1.8-9,3.8-8.3,7.1c0.3,1.7,1.9,1.2,2.8,1.5c0.9-0.8,1.9-1.2,2.9-1.2c1-0.3,1.9,0,2.7,1
									c3.6,0.8,7.3,2.4,10.7,2.3c-0.1,0,3.3,0,3.3,0.1c0.6,0.1,4.1,1.2,5.8,1.3c2.7,0.1,4.6-1.6,7.2-1.8c2.1-0.2,4.3,1.1,6.4,0.6
									c4.8-1.1,2.7-2.8-0.4-6.1c3.9,0,7,2.7,10.1,4.6c1.2,0.7,7.2,5.1,6.1,0.1c3.3,0.7,6,2.5,9.1,3.6c3,1.1,5,0.3,7.9,0.5
									c1.5,0.1,2.1,0.6,3.7,0.5c1.7-0.1,1.8-1.4,2.6-1.6c2.3-0.3,4.5,1,6.7,0.7c1.9-0.2,2.3-1.2,3.8-1.7c3.2-1.1,3.3-0.8,6.2-0.3
									C2007,329.9,2014.9,333,2011.4,329.1z M1932.6,323.2c-1.6-0.4-2.6-1.4-3.2-2.8C1929.5,320.4,1932.8,322.9,1932.6,323.2z"
						/>
						<path
							d="M2102.5,331.3c-2.4-1.4-4.6-3.9-7.3-4.7c-3.7-1.1-5,1.1-8.3,2c-5.9,1.6-10.3-6.5-14.3-0.7c-3.9-3.8-5.6-7.3-10.8-4
									c-5.4,3.4-5.8,6.3-11.3,0.3c-2.7-2.9-5.7-9.5-10.4-9.2c-4.4,0.3-8.2,8.2-9.9,11.7c-3.7-4.2-5.1-6.1-9.7-3
									c-2.1,1.5-9.7,8.9-6.9,11.8c2.3,2.3,3.5-1.6,5.3-2.2c2.6-0.8,2.3,0.2,4.8,0.5c2.3,0.2,5.2,1.3,6-2.2c3.8,3.8,8.8,2.7,13.5,2.3
									c3.4-0.3,5.3-0.8,3.1-4.5c-1.9-3.2-3.7-0.3-0.6-4.6c2.1,2.6,4.2,5.3,7.1,7.1c4.8,3,3.9,0.8,7.5-0.3c6.3-1.9,11.5,4.6,15.8-1.7
									c3,2,5.1,3.5,8.6,4.4c4.6,1.1,5.8-1,9.9-1.2c2.2-0.1,4.2,2.3,6.3,2C2102.5,334.8,2105,332.7,2102.5,331.3z"
						/>
						<path
							d="M2066.8,345.8c-4.5-1.1-6.8-5.6-10.8-7.5c-5-2.4-8.5,0-13.2,2.2c-1.2,0.6-14.1,4.5-11.1,7.5c1.1,1.1,8.4,0.1,10.4,0.4
									c2.5,0.3,9.4,3.5,11.4,2.1c3.4-2.4-1.4-5,0.1-7.1c1.9-2.8,6.2,3.3,8.1,4.5c1.4,0.9,4.2,3,5.8,1.4
									C2068.5,348.2,2068.5,346.2,2066.8,345.8z"
						/>
						<g>
							<path
								d="M2073.3,347.7c2.4-1.3,4.4-5.5,7.6-4.9c2.2,0.4,2.8,3.9,5,4.5c5.4,1.6,6.2-5.9,12.3-3.4c3.4,1.4,5.4,3.3,9.2,3.8
										c4.9,0.7,10.1,0.4,15,0.5c1.5,0.1,23.8,0.9,18.6-4c-1.7-1.6-9.7,0.2-12.2,0.2c-4.7-0.1-9.5-0.3-14.2-0.4c-4-0.1-8.1-0.2-11.9-1.8
										c-2.7-1.2-5.1-4-8.2-3.6c-3,0.4-4.5,4.6-7.4,4.1c-2.6-0.4-3.2-4.2-6.3-4.4c-2.8-0.2-6.3,2.9-8,4.9c-0.5,0.6-2.5,1.3-2.5,2.2
										C2070.3,347,2071.6,348.6,2073.3,347.7z"
							/>
						</g>
						<path
							d="M2228.8,336.5c-8.5,0.1-17.3-0.1-25.6-2.1c-5.5-1.3-14.4-8.3-19.9-7.3c-3.2,0.6-3.4,4.8-5.4,5.4c-0.2,0.1-5.1-1.5-6-1.5
									c-1.9-0.1-3.6,0.6-5.5,0.3c-3.2-0.6-5.3-3.9-8.5-4.2c-3.8-0.4-5.4,2.7-8.1,4.7c-5.3-6.4-8.1-12.6-15-3.7c-5.5-4.4-9.4-13.8-15.7-6
									c-2.3,2.9-4,5.7-7.1,7.9c-1.3,0.9-9.4,4.1-6.5,6.6c1.6,1.5,5.4-1.2,7.4-1.6c3.9-0.8,6.6,0.6,10,1c4.6,0.5,3.8-0.2,2.3-5.2
									c-1.1-3.5-0.3-3.5,0.2-6.8c2.5,4.7,8.3,8.4,12.6,11.2c1,0.7,4.5,3.6,6,3c3.8-1.5-0.6-4.3-1.9-5.1c1.2-2.8,7,2.9,8.2,3.2
									c3.7,0.9,4.7-1.7,8-2.1c3.8-0.4,8.4,2.3,12.5,2.2c2,0,8.7,1,9.7-0.8c3.4,4.5,5.3,0.8,9.8,0c4.8-0.8,9.7,2.3,14.4,3.1
									c6.3,1.1,12.7,1.4,19.1,1.4c1.7,0,5.4,1,6.3-0.9C2230.9,338.3,2230.6,336.5,2228.8,336.5z"
						/>
						<path
							d="M2116,277.1c-3.2-1.2-6.7-1.6-9.9-2.8c-2.4-0.9-4.9-2.8-7.4-3.3c-6.3-1.2-9.2,4.6-13.4,8c-1.8,1.5-11.9,6.7-6.2,9.8
									c0.7,0.4,4.7-1,5.6-1.2c4.8-1.1,4.7,0.7,9.7,1.4c1.1,0.2,5.4-0.1,6.6-0.7c3.2-1.5,2.7-1.5,2.7-4.1c0-2.5-2.8-3.7,0.2-6
									c0.7-0.5,4.8,1.1,5.9,1.4c1.4,0.4,5.2,2,6.3,1.3C2117.6,280,2118,277.8,2116,277.1z"
						/>
						<path
							d="M2017.5,275.2c-3.7-0.2-6.2-3.7-10.1-2.9c-4,0.8-9.7,6.6-13,8.9c-3.8,2.8-3.1,2.9-6.8,1.5c-2-0.8-3.2-3.7-5.8-2.8
									c-1.3,0.5-2.1,3.6-3,4.7c-1.6,1.9-3.6,3.2-5.8,4.3c-3.9,2-8.5,1.5-12.3,3.1c-3.5,1.5-3.8,3.3,0.4,4c5.7,0.9,11-3.6,16.2-4.2
									c2.2-0.2,4,1.6,5.7,0c1.7-1.6-0.7-4.5,1.7-6.6c5.1,4.8,13.6,6.9,20.3,3.3c3.8-2.1,5.9-8,4.7-12.1c2.1,0.3,5.6,3.5,7.8,2.7
									C2019.4,278.6,2020.5,275.4,2017.5,275.2z"
						/>
						<path
							d="M2096.9,266.1c-4.8-3.8-16.3-26.7-23-15c-1.8-2.5-4.8-8.5-8.2-7.9c-3.2,0.5-5.6,9-6,11.4c-4.9-2.7-7,2.2-9.5,5.6
									c-1.7,2.3-3.4,4.7-5.3,7c-2.6,3.2-2.5,3.9-6.5,3.2c-5.9-1-9.1-3.1-14.6,1.2c-3.9,3-7.1,7.4-10.3,11.2c-2.8,3.3-4.4,5.6,0.6,5.3
									c1.3-0.1,2.4-1.6,3.8-1.3c1.6,0.3,0.3,2.9,1.3,3.1c3.2,0.9,4.2-1.7,6.8-0.1c2.3,1.4,0.1,2.5,4.2,2.5c1.3,0,4-1.1,5.2-1.6
									c3.9-1.9-1.9-0.6,3.5-2.4c3.3-1.1,3.9,1.4,6.1,1.3c2.8-0.1,3.9-4.2,5.6-6.9c1.8-3,2.4-7.9,5.9-2.3c3.3,5.2-5.3,6.1-7.1,10.6
									c-3.1,7.6,5.4,0,7.2-1.1c5.1-3.1,2.4,0,2.5,3.5c0.1,3.8,0.4,3.3,4.8,2.9c3.6-0.3,10.9,1.1,9.9-4.7c1,1.4,3.3,1.7,4-0.2
									c0.6-1.7-2.8-5.6-3.6-7.1c-1.5-2.6-3-5.3-4.5-8c-2.3-4.3-1.3-2.6,0-6.6c3.5,4.1,12,14.1,16.8,4.8c2.7-5.2-8-17.1-4.3-19.4
									c2.4,3.1,5,6.3,7.5,9.3c1.1,1.3,3.2,4.7,5.2,4.7C2096.2,269.1,2099.2,267.9,2096.9,266.1z M2024.8,280.9c-0.4-0.6-0.7-0.5,3.8-2.8
									C2027,278.8,2025.9,280.2,2024.8,280.9z M2052.2,273.2c-3.1,0.9-5.7,4.4-8.2,6.4c-2.8,2.3-8.5,8.3-9.3,2.5c-0.2-1.4,1.1-4.6,2-5.5
									c2.3-2.4,0.8-1.3,4.2-0.7c0.1,0,9.4,1.8,4.9-2.3C2048,272,2052.3,273.1,2052.2,273.2z M2060.8,278.7c-0.2,0.3-4.5-4.2-5.5-4.9
									C2061.9,271.8,2058.5,273.4,2060.8,278.7z M2066.5,266.3c-5,1-7.3-1.3-6.1-6.2C2060.7,260.3,2066.5,266.3,2066.5,266.3z"
						/>
						<path
							d="M2080.8,549.6c-13.4-5.7-23.2-30.6-37.8-10.2c-1.9-1.7-4.2-4.9-7-4.3c-4.4,0.9-4.3,8.3-7.7,10.1c-0.5,0.3-3.3-0.2-4.2,0
									c-1.6,0.3-2.9,1.7-4.5,2c-3,0.6-2.6,1.1-5.6-1.4c-1.9-1.6-3.7-3.3-5.5-5c-7.6-7.1-10.1,3.3-16,8.1c-4.5,3.6-5.9,2.5-10.4-0.7
									c-3.8-2.6-5.5-5.3-9.6-2.4c-4.1,2.9-2.1,6.7-7.6,3.1c-2.5-1.6-5.8-4.9-9.1-4.2c-2.9,0.6-6.3,5.1-8.7,6.8
									c-2.5,1.8-11.1,5.7-11.9,8.6c-1.5,5.1,3.6,1.7,5.7,0.9c3.9-1.5,6.8-1.2,10.9-1c2.9,0.2,8.2,0.7,8.9-3.2c0.3-1.7-3.8-5.6-2.2-7
									c2.3,1.4,4.6,3.1,7,4.4c-5.7,4.3-3.7,5.6,2.1,3.8c4.6-1.4,13.6,3,14.9-4.8c3.3,2.1,4.3,2.8,7.5,2.3c3.7-0.6,5.7-2.3,9.8-2
									c2.8,0.2,7.7,2.2,9.2-1.5c1.1-2.8-3.4-4.9-2.8-8.2c2.7,2.5,5.1,5,8,7.2c-1.7,1.3-8.8,4-8.5,6.5c0.4,3.4,3.7,1,5.6,0.5
									c3.9-1,21.4,0.6,17.5-7.1c3.7,4,11,13.6,17.3,12.7c1.1-0.2,4.6-2.7,5.7-3.2c2.8-1.3,6.2-2.2,8.8-3.8c3.7-2.4,7.4-10.2,3.9-14.3
									c-2.7-3.2-9.3,0.1-7.2-6.8c6,2.2,9.4,7.4,13.7,11.8c1.6,1.6,5.4,6,7.9,5.9C2080.2,553.1,2083.9,551,2080.8,549.6z M2039.2,541.4
									c0.5-0.5,5.9,5.5,6.1,6c1.5,3.8-0.6,5.3-3.3,7.7C2042.7,549.9,2034.6,546.7,2039.2,541.4z"
						/>
						<path
							d="M2397,413c-5.6-3.1-10.8-6.1-14.8-11.2c-3.7-4.7-4.2-8.6-8.4-2.1c-1.7,2.6-4.7,11.3-8.6,11.9c-3.4,0.6-6.8-4.9-11.2-4
									c-4.2,0.8-7.4,4.8-11.5,5.9c-2.2,0.6-9.4-1.6-9.1,1.7c0.6,7.6,22.5,0.5,26.4,0.7c-5.7,3.6-11.9,5-18.3,7.2
									c-5.2,1.8-5.2,4.3,0.3,4.1c1.8-0.1,3.6-1,5.4-1c2.1,0,4.1,1.3,6.1,1.4c3.4,0,5.4-2.8,9-2.5c1.6,0.1,3.6,2,5.2,2.3
									c3.3,0.8,4.8-0.6,7.7-1.3c3.5-0.8,5.1-0.8,8.7-0.4c2,0.2,3.9,1.9,4.4-1.1c0.5-3.7-5.6-4.8-6.8-7.3c0.3,0.6,1.2-3.2,1.1-3.4
									c-0.5-2.8-2.5-4.4-2.4-7.7c3.2,3.4,7.2,5.9,11.1,8.2c1.1,0.7,3.8,2.9,5.2,2.2C2398,416,2398.6,413.9,2397,413z"
						/>
						<path
							d="M2416.7,428.6c-3.1-2.5-5.6-5.6-8.6-8.1c-4-3.3-3-2.8-6.3,0.1c-4.5,3.9-8.3,9.1-15.2,8.4c-2.8-0.3-4.3-3.2-7.1-3.6
									c-2.1-0.3-5.3,2.9-7,3.9c-2.8,1.7-5.7,3.5-8.8,4.5c-2.8,0.9-6.5-0.2-8.8,0.5c-7,2.3,3.2,4.8,6.6,4.2c2.9-0.5,4.6-1.5,7.5-1.3
									c2.3,0.2,4.7,1.9,6.9,1.6c4.5-0.5,3.3-2.6,3.6-5.2c0.3-2.1-4.5-4.8,1.3-2.1c2.1,1,4.5,4.4,7.1,4.5c1.4,0,4.7-1,2.4-3.2
									c4.9-0.8,10.2,3.3,15.1,0.6c3.9-2.1,0.7-4.1,1.8-8.2c1.3,1.3,5.5,6.7,7.5,6.4C2415.8,431.5,2419.1,430.5,2416.7,428.6z"
						/>
						<path
							d="M2442.7,437.3c-2.9-2.3-5.3-5-7.4-7.9c-1-1.3-3.2-5.9-4.6-6.8c-3.8-2.4-9.6,8.1-12.3,10.7c-3.6,3.4-8.4,7.9-13.4,8.9
									c-5.6,1.1-6.5-3.4-11.1-4.7c-4.8-1.4-9.7,3.3-13.4,5.8c-1.3,0.9-6.6,3.2-4.9,5.6c1.4,2,2.6,0,4.2-0.2c3.1-0.5,7.1,1.5,10.1,0.9
									c3.1-0.7,4-3.5,5.6-6c3.2,2.8,7.3,4.8,11.7,3.9c2.3-0.5,4-2.7,6.5-2.9c3.5-0.4,5.6,2,8.8,2.8c3.9,1,11.6,2.5,13.8-2.3
									c2.4-5.3-7.8-6.6-4.4-13.2c1.4,2,5.3,8.1,7.9,8.5C2441.1,440.4,2445.5,439.6,2442.7,437.3z"
						/>
						<path
							d="M2571.8,413.8c-5.7-2.7-10.5-4.6-15.4-8.5c-2.3-1.9-3.3-3.2-6.3-2.5c-2.1,0.4-3.8,3.7-5.8,3.7c-1.5,0-4-3.4-6.1-3.8
									c-3.5-0.6-5.8,1.5-8.4,3.5c-4.8,3.4-9.4,6.9-14.7,9.4c-2.1,1-10.8,2.5-6.6,6c2.1,1.7,10.1-3.4,13.2-3.4c5.2,0,10.5,3.8,15.8,2.6
									c5.4-1.2,4.2-5.3,5.4-9.8c2.9,0.1,2.8,5.1,6.2,4.2c2.5-0.7,2.6-4.5,6.7-4.3c3.2,0.2,7.8,4,11.2,5.2c1,0.4,3.2,2,4.4,1.3
									C2572.6,416.6,2573.5,414.6,2571.8,413.8z"
						/>
						<path
							d="M2592.5,410.3c-2.4-1-4.8-3.5-7.6-3.4c-3,0.1-4.6,2.6-7,3.9c-2.2,1.2-15.4,2.4-10.3,6.3c1.2,0.9,3.6-0.4,4.8-0.3
									c1.8,0.2,2.2,1,4,1.4c2.8,0.7,5.1,1,7.9-0.2c2.4-1,4.1-3.3,5.8-4.5C2590,413.6,2597,412.2,2592.5,410.3z"
						/>
						<path
							d="M2626,440.7c-4.5-0.8-5.7-6.8-10.3-6.7c-2.2,0-3.5,2.3-5.5,2.9c-3.6,1.2-2.6,1.3-5.8-0.8c-1.6-1.1-2.8-4.2-5-4.3
									c-2.9-0.1-3.2,2.6-5.3,3.8c-3.2,1.8-0.5,1.6-4.4-0.1c-1.9-0.8-3.7-3-5.8-3.3c-4.9-0.6-6.6,7.3-10.5,7.1
									c-3.4-0.2-9.6-10.3-11.7-12.7c-2.2-2.5-6.8-10.1-10.4-8.7c-4.2,1.7-3.9,10.7-7.1,12.4c-2.3,1.2-4.6-2-7.2,1.2
									c-1.7,2.1-0.9,7.3-5.2,7.6c-2.8,0.2-2.9-4.4-6-4.1c-2.8,0.2-6,6.6-7.9,8.4c-0.5-2.3-5.2-6-7.2-6.5c-4.3-1.1-4.3,1.7-7.4,3.4
									c-4,2.2-3,0-6.8-1.5c-4.9-2-5,1.6-9,2.6c-8,2-7.8-13.2-16.7-10.3c-2.5,0.8-2.4,2.4-4.4,1.9c-1.3-0.3-2.3-3.1-3.6-3.8
									c-4.2-2.3-6.1,0.4-8.5,3.4c-1.9,2.4-3.2,5.2-5.3,7.4c-2.2,2.3-7,3-8.6,4.9c-5.3,6,6.8,2.4,8.4,2.2c3.5-0.3,6.2,1.5,10.1,1
									c2.3-0.3,4.7,0.1,6.6,0c5.3-0.1,9.7-3.1,6.1-8.3c5.1-3.7,8.3,3.3,11.2,6.3c2.9,2.9,3.5,3.8,6.8,3.8c4.2,0.1,3-1.5,6.1-3.3
									c3.5-2,0.9-0.3,4,1.3c2.4,1.2,3.1,1.7,6.1,0.5c1.1-0.4,2.1-0.1,3.1-1c0.4-0.4-0.5-2.5-0.3-2.6c1.6-1.2,3.4,0.4,5.6,1.7
									c-7.7,5.9,1.5,5.7,6.7,5.6c4-0.1,4.9,0.6,5.8-2.7c0.1-0.5-3.5-5.3-0.8-6.5c0,0,11.1,8,12.4,8.7c1.7,0.9,5.8,3.4,7.5,1.6
									c2.2-2.3-0.9-3.2-2.6-3.8c3.3-4.8,4.3,0.2,7.4,0.6c2.9,0.4,0.7-1.1,3.5-1.4c1.3-0.1,3.4,0.9,4.4,0.7c6.3-1.5-3.7-11.6-4.6-16
									c-0.4-1.8-0.7-3.5,1-5c2.5-2.2,2.7-0.4,4.6,1.8c2.9,3.3,5.8,6.6,8.8,9.8c2.3,2.4,5.1,5.6,8.3,6.9c5.4,2.1,4.3-1.7,0.6-4.5
									c3.5-0.1,7.4-1,7.1-5.4c1.4,1.1,3.1,1.9,4.7,2.8c-1.9,0.9-5.9,2-2.7,4.5c1.3,1,4.2-0.6,5.4-0.2c2.5,0.8,2.7,5.6,5.7,3.7
									c3.7-2.3-1.1-5.3,0.5-8.2c2.1-3.9,5.1,0.6,7.3,2.3c1.4,1.1,3.2,3.1,5,3.5c2.7,0.7,2.1,0,4-0.9c1-0.4,3.6-3.4,4.7-3.4
									c1.5,0.1,3,3,4.9,3.2C2626.1,444.5,2629.6,441.4,2626,440.7z"
						/>
						<path
							d="M2717.6,430.4c-7.6,1.8-15.6,2.4-23.5,1.8c-7.6-0.5-14-3.4-21.3-4.8c-3.5-0.7-5.7-0.1-8.7,1.9c-3.7,2.5-3.7,4.3-8,3.3
									c-8-1.8-10.6-8.7-18.4-2.1c-4.3,3.6-8.6,8-12.6,11.8c-5,4.7,0.3,3.7,4.2,2.2c6.6-2.5,11.7,3.3,18.6,1.1c3.9-1.2,3.3-1.6,1.1-5.5
									c-1.6-2.8-3.6-4.3-3-7.9c5.7,1.9,9.8,5.4,15.1,7.2c7.6,2.5,15.8,7.7,24,7.8c7.4,0.1,3-3.3,0.4-5.6c-2.6-2.2-3.4-4.1-6.8-5.4
									c-2.7-1.1-6-0.6-6-4.5c10,2.5,18.9,4.9,29.4,4.6c4.4-0.1,11.1,0.2,15.1-1.8c0.9-0.5,3.1-0.4,2.9-2.1
									C2720,431.2,2719.1,430.1,2717.6,430.4z"
						/>
						<path
							d="M2703.2,417.2c-15.7,1.8-27.6-6.7-42.2-10.2c-6.3-1.5-10.5-5.2-16.3-8.6c-5.8-3.4-7.5-4.9-12.2,0.3
									c-2.8,3.1-2.9,4.3-6.8,3.8c-2.9-0.3-6.8-2.7-9.3-4.1c-5.6-3.1-6.9-4.3-11.4,1.1c-2.5,3-11,9.1-11.8,12.7c-1.5,7.1,5.4,0.1,7.8,0.4
									c-1.8,4.3,4.5,0.5,4.8,0.7c1,0.6,0.1,3.5,2.9,2.2c-2.6,1.9,0.7,3,1.7,3.5c2.9,1.5,2.9,0.1,6.5-1c4.2-1.3,4.3,1.1,8.1,1.6
									c3.3,0.4,7.1-1.2,10.8-0.5c-1.4,1.2-8.9,4.3-9.3,5.5c-1.5,3.7,2.3,2.7,4.2,2.5c4.2-0.5,8.2-2.2,12.6-2c4.7,0.3,7.1,3.2,11.2,4.3
									c3.4,1,2.9,0.1,5.5-1.9c0.4-0.3,3.6-2.5,3.6-2.4c0.8-1.4-0.2-2.4,0-3.2c-0.5,1.5-0.2-3.5-0.1-2.1c-0.1-0.7,1.3-1.2,0.7-2.6
									c-1.2-2.9-7.7-1.3-5.9-6.7c9.3,2.4,18,6.8,27.3,9.2c4.5,1.1,9.4,2.1,14,1.6c0.9-0.1,4.6,0.2,4.8-1.9
									C2705.1,418.3,2704.8,417,2703.2,417.2z M2609.6,415.2c5.2-1.6,1.1-7.2,3-10.6c2.8-4.6,8.1-0.1,12.4,1.6
									C2620.6,410.1,2615.3,413.5,2609.6,415.2z M2651,406.9c-3.4,1.3-4.9,4.6-8.3,1.3c-1.9-1.9-1-4.6-1.4-7.2
									C2644.6,402.9,2648,404.7,2651,406.9z"
						/>
						<g>
							<path
								d="M1134.5,747.6c3.9-2.2,6.9-5.4,11.3-6.7c4.6-1.3,9.4-0.7,14,0.1c3.6,0.6,8.5,2.5,12.1,1.4c3.1-0.9,5-2.3,1.8-4.3
										c-1.5-0.9-9.9-0.6-12.1-1c-4.7-0.8-9.8-1.5-14.5-0.6c-3.9,0.8-7.1,2.9-10.4,5c-1,0.6-5,2.4-5.2,3.8
										C1131.3,746.9,1132.9,748.4,1134.5,747.6z"
							/>
						</g>
						<path
							d="M1131.7,738.7c-3.4-0.8-7.1-3.2-10.5-3.5c-2.7-0.2-4,1.5-6.4,1.8c-2.8,0.3-2.7-0.2-5.5-1.5c-3.9-1.9-6.5-1.3-10.1,0.7
									c-1.5,0.8-2.8,2-4.3,2.8c-1.3,0.7-4.8,1.1-5.7,1.8c-2,1.4-2.8,2.7,0,4c3.1,1.5,10.1-2.6,13.7-2.3c4.2,0.3,8.2,3,12.4,2.7
									c2.1-0.1,5.4-0.7,1.7-3.7c4.6-2.4,8.5,2.2,13.2,1C1131,742.2,1135.9,739.8,1131.7,738.7z"
						/>
						<path
							d="M1101.9,732.1c-4.3-1.1-7.7-4.5-12-5.4c-5.7-1.1-7.7,2.6-12.4,4.2c-4.3,1.5-8.9-0.1-13.7,2.2c-2.5,1.2-12.4,5.5-12.8,8.1
									c-0.8,4.9,6.8,0.2,8,0.2c1.6,0.1,2.8,1.7,4.3,2.1c2.4,0.6,4.6-0.3,6.9-0.1c2.7,0.3,8,4.8,9.8,1.1c1.1-2.3-5.1-7.1-6.1-9.3
									c3.9,0.6,10.6,4.9,14.2,2.9c3.5-2,0.3-4.2,0.9-7.5c3.6,0.8,6.9,4.6,10.5,4.8C1101.6,735.7,1105.9,733.1,1101.9,732.1z
									M1072.6,739.7c-0.7,0-0.8-0.4-0.3-1.2C1073.6,739.9,1073.7,740.3,1072.6,739.7z"
						/>
						<path
							d="M1201.7,723.8c-7.5-0.3-14.5-6-22.1-3.8c-3.1,0.9-5.2,3.7-7.8,3.7c-4,0-5.5-3.6-10.2-1.8c-4.1,1.5-3.3,1.8-7.7,1.5
									c-4.7-0.4-9.5-0.5-14.2-0.9c-7.8-0.7-16.1-5.1-23.9-3.3c-4.4,1-18,6.1-20.3,10.1c-3.3,5.8,7.7,1.5,10.7,1.7
									c3.1,0.2,6.9,0.6,10,1.1c2.2,0.4,5.7,3.1,7.8,2.8c6.6-0.9-0.8-8-1-11.6c9.7,2.6,19.2,2.4,29,3.9c-2.5,1.2-13.7,2.3-14.3,4.8
									c-1.2,5,9.5,0.9,11.1,0.7c1.4-0.2,3.6-0.4,5.1-0.2c2.1,0.2,4.9,2.7,6.8,2.2c4.9-1.3,2.6-10.9,9.4-7.2c-1.3,1.5-4.2,3.8-1.8,5.6
									c1.7,1.3,4.1-1.2,5.8-1.2c2.8,0,4.9,2.6,7.8,2.6c4.4,0.1,4-1.3,3.3-4c-0.5-1.9-3.3-3.7-0.8-5.8c1.7-1.5,4.7,0.4,6.6,0.9
									c2.4,0.7,8.1,3.2,10.5,2.2C1203.3,727.1,1204.8,724,1201.7,723.8z"
						/>
						<path
							d="M1111.2,719.5c-7.3-0.1-13.3-4.1-19.7-6.8c-4.3-1.9-6.3-1.3-10.7-0.8c-1.7,1.4-3.5,1.5-5.3,0.4c-1.7-0.6-3.5-0.9-5.3-1
									c-2.5,0-17.3,4.4-14.3,8.8c1.2,1.7,12.7-5.1,16.6-4.4c-1.9,1-11.2,5.1-6.3,7.7c0.9,0.5,2.7-1.1,3.4-1.1c2.6-0.2,5.3-0.7,8-0.7
									c1.4,0,12.6,2.5,12.7-0.7c0.1-3.1-5.6-2.2-5.8-5.8c6.2-0.4,13.5,5.8,19.6,7.4c1.9,0.5,6.6,2.2,8.3,0.7
									C1113.7,721.9,1113.1,719.5,1111.2,719.5z"
						/>
						<path
							d="M1217.2,647.5c-9.9,0.5-15.5-11.5-25.9-9.5c-5.1,1-27.7,15.2-22.5,20.3c3.5,3.4,11.7-0.3,14.9-2.3c5-3,9.3-11.2,15.3-11.7
									c4.5-0.4,8.1,4.6,12.5,6.1c1.5,0.5,5.8,2.2,7.1,0.5C1219.4,649.8,1219.5,647.4,1217.2,647.5z"
						/>
						<path
							d="M1216.2,619.3c-10.1,2-18-8.6-28.2-6.1c-4.8,1.2-8.5,4.9-13,6.8c-2.6,1.1-10.1,0.9-7.6,5c1.8,2.9,12.2,1.1,14.9,0.3
									c5.1-1.5,9.3-5.7,14.8-5.9c5.1-0.2,9.3,3.4,14.6,4c1.5,0.2,5.6,0.8,6.7-0.8C1219.4,621,1218,619,1216.2,619.3z"
						/>
						<path
							d="M1208.3,625.3c-3.9-2.3-7.3-4.7-11.9-2.9c-3.3,1.2-5.4,3.7-9,4.5c-5.4,1.2-14.8,1-17.1,7.6c-2.8,8,8.2,0.2,12-0.1
									c7.4-0.5,17.3,1.3,21.1-7.4c0.9,0.5,3.3,2.6,4.5,2.1C1209.2,628.3,1209.9,626.3,1208.3,625.3z"
						/>
						<path
							d="M1241.2,600.5c-8.7-0.1-17-3.4-25.7-3.7c-7.9-0.3-12.3,1.9-19.9-1.3c-4.5-1.9-10.9-8.5-15.8-8.6c-3.6-0.1-5.6,2.7-8.1,4.7
									c-3.1,2.4-6.4,4.1-10.1,5.4c-2.2,0.8-16,2.1-9.2,5.8c0.6,0.3,6.1,0.8,7,0.9c4,0.5,4-0.8,7.3-2.5c3.2-1.7,4.3-0.6,7.7-0.7
									c4.6-0.2,4.5-3.3,8.1-5c3-1.4,2.8-0.6,6.1,1c3.5,1.8,7.4,3.5,11.2,4.6c-1.7,1.2-5.6,5.2-7.7,5.2c-1.8,0-4.3-3.4-6.7-3.5
									c-4.6-0.2-10.8,5.5-15.3,7.3c-2.3,0.9-13.8,2.2-14.3,4.8c-0.7,3.6,6.3,2.3,8.3,2.2c4.6,0,9.5-0.4,13.9-1.5c1.8-0.5,3.9-1,5.5-1.9
									c1-0.5,4.7-4.6,5.1-4.6c0.6-0.1,5.6,3.4,6.2,3.8c3,1.5,6.3,2.8,9.7,3.3c1.8,0.3,5.6,1,7.1-0.1c1.2-0.9,2.4-3.3-0.1-3.9
									c4.5-2,15.3-5,7.8-11.1c5.9,0.7,11.7,2.6,17.6,3.2c1.6,0.2,4.8,1.2,5.7-0.6C1243.5,602.3,1243,600.5,1241.2,600.5z M1178.7,612.3
									c1.7-0.8,3.3-1.6,5-2.3C1182.4,611,1180.6,611.7,1178.7,612.3z"
						/>
						<path
							d="M1170,590.5c-3.1-0.4-5.3-2.8-8.2-3.3c-3.9-0.7-7.2,2.5-10.6,4c-4.5,2.1-9.1,3-13.8,4.1c-3,0.7-6.4,1.7-2.5,4.2
									c4.7,3,20.4-3.9,25.2-5.7c1.6-0.6,2.3-1.6,3.9-1.5c1.7,0.1,3.1,2,4.9,1.9C1171.3,594.2,1173.8,591,1170,590.5z"
						/>
						<path
							d="M1211.3,581.3c-7.3-2.8-13.6-4.3-21.4-4.4c-7.6-0.1-11.8-6.6-19.1-6.7c-7,0-14.6,4.7-20.4,8.2c-1.7,1-8.3,4-8.4,6.2
									c-0.2,4,4.3,1.2,6,1c7.5-1.1,14-0.3,21.6-3.2c4.7-1.8,5.6-5,10.4-3.6c6.9,2,13.8,1.4,20.8,3.2c2,0.5,8.2,3.9,10,3.2
									C1212.3,584.6,1213.9,582.3,1211.3,581.3z"
						/>
						<path
							d="M1162,571.3c-5.8-1.8-9.5-5-16-4.7c-6.2,0.3-10.1,2.9-15.1,6.2c-2.1,1.4-12.5,5.8-10.8,9.3c1.6,3.3,7.4-3.1,9.4-3.4
									c3.8-0.5,7.6,3.4,11.8,1.1c2.9-1.6,2.6-4.8,5.1-6.9c3.6-3,6.2-1.5,9.9,0.2c1.6,0.7,4.6,3.3,6.3,1.7
									C1163.8,573.7,1163.7,571.8,1162,571.3z"
						/>
						<path
							d="M1129.4,571.3c-4.5-4.6-10.4-3.9-15.8-1.5c-3.3,1.5-11.5,5.6-4.6,7.2c2.9,0.7,10.4-1.1,13.3-1.8
									C1123.9,574.9,1132.5,574.5,1129.4,571.3z"
						/>
						<path
							d="M1161.2,560.5c-6.5-0.1-13-0.7-19.3-2.3c-4.1-1-9.8-5-13.9-5c4-2.8,5.1-1.2,9-1.2c2.2,0,5.2,0.1,5.4-3
									c1.1,1.1,4.7,6.8,6.3,2.6c0.9-2.3-4.9-6.9-6.5-7.7c-9.1-4.7-16.9-1.1-24.6,3.9c-2.7,1.7-18.8,6.1-14.4,10
									c2.9,2.6,16.1,0.3,19.3-1.3c-2.3,2.6-5.4,4.3-8.7,5.3c-2.1,0.6-8.6,0.1-9.4,2.5c-1.4,4.5,16.4,0.7,19.3,0.7
									c4.3,0,8.7,0.8,12.9-0.3c4.2-1.1,5.1-2.4,9.5-1.6c3.9,0.7,9.7,2.1,13.7,1.3c1-0.2,3,0.3,3.2-1.4
									C1163.3,561.9,1162.7,560.5,1161.2,560.5z M1140.3,547.6c-1.8,1.2-2.8,0.2-3.9-1.2C1137.7,546.7,1139.1,547,1140.3,547.6z"
						/>
						<path
							d="M1061.2,481.8c-9.8-0.9-19.8-5.7-29.6-5.4c-3.8,0.1-7.8,1.2-11.1,3.1c-1.2,0.7-3.3,3.2-4.5,3.4c-0.6,0.1-4.5-2.1-5.4-2.1
									c-2.9-0.2-16.9,8-13.3,11.3c2.1,1.8,2-0.7,3.6-0.6c2.2,0.1,3.9,1.3,6.4,1.1c2.3-0.2,8.6-1.9,7.8-5.3c5.6,3.3,12.4,6.9,19.1,7
									c3.5,0.1,3.7-0.7,6.5-3.1c3.8-3.1,4.2-1.9,1.2-5.2c-1.1-1.3-4.5-2.3-2.1-4.3c0.8-0.7,8.3,2,9.6,2.2c2.4,0.5,11,3.3,12.6,1.7
									C1062.8,484.7,1064.1,482.1,1061.2,481.8z"
						/>
						<path
							d="M1103.2,466.2c-9.3-3.4-17.5-5.8-27.5-5.4c1.9-0.2,8.3-1.7,5.3-4.8c-1.1-1.1-9.6,0.2-12.1-0.6c-4.4-1.4-8.8-4.2-13.5-4.2
									c-5.2,0-10.3,3.4-15,5.3c-4.8,1.9-10.1,2.9-14.7,5.1c-5.4,2.6-4.2,3.9,0.5,4.1c3,0.1,7,2,9.6,1.8c3.3-0.2,6-3.2,9.9-3.6
									c-2.9,1.7-12.9,4.8-14.4,7.5c-2.3,4.1,2.6,3.4,5.5,3.5c4.7,0.1,8.3-0.9,12.7,0.8c5.2,2.1,9.5,2.4,15.2,1.8c6.2-0.6,5.1-0.8,8.4-5
									c2.5-3.1,7.1-5.3,10.9-6.2c5.1-1.2,9.2,0.6,14,2.3c1.5,0.6,4.4,2.6,5.8,1.2C1104.9,468.7,1104.8,466.8,1103.2,466.2z
									M1064.6,461.1c-1.6-0.1-3.2-0.3-4.8-0.6c3.4-3.4,1.7-2.8,6.1-2c2.9,0.5,5.2,2.4,8.4,2.5C1071.1,461.1,1067.8,461.2,1064.6,461.1z
									"
						/>
						<path
							d="M1148.3,410.6c-19.3,2.8-34.5-19.3-53.2-9.8c-4,2-7.8,4.4-12,6c-3.8,1.5-11,1.4-14.1,3.4c-2.6,1.7-2.1,2.6,0,4
									c2.7,1.9,13.4-1.8,16-2.5c-5.3,3.9-9.3,7.7-15.7,10c-2.4,0.9-14.5,1.8-10,6.1c1.4,1.3,7.3-1,8.8-1.5c3.3-1,4.5-0.9,7.4-1.1
									c4.6-0.3,0.9,1.4,5.9-0.6c4-1.6,5.7-2.4,10.1-2.6c-3.5,1.9-6.6,4.5-10.2,6.1c-3.8,1.7-7.8,2.7-11.6,4.5c-1.5,0.7-7.1,3.1-5.2,5.7
									c1.1,1.5,2.1-0.1,3,0.2c1.2,0.4,2.4,0.6,3.9,1.5c-4.2,0.5-9.3,2.4-13.2,2c-4.7-0.6-6.3-3.1-11.2-0.9c-7.7,3.4-14.2,8.8-22.5,11
									c-1.6,0.4-9.3,0.3-8.1,3.7c1,2.8,7,0.6,8.9,0.2c8.4-1.5,18.5-6.8,26.5-6.2c0.8,0.1,1.7,0.9,2.9,0.9c2,0,3.3-1.6,5.1-2.1
									c2.9-0.8,6.2-0.5,9.1,0.5c6.3,2.2,16.8,7.1,23.5,4.9c1.8-0.6,4.1-2.8,5.8-3.1c0.9-0.2,2.1,0.6,3.2,0.6c1.9-0.1,7.4,0.5,4.6-3.3
									c-0.9-1.2-8.8-1.9-10.6-2.7c-4.1-1.9-7.4-5.3-12-6.1c4.2-5.7,5.9-4.4,12.7-3.7c4.2,0.4,8,0.9,11.9,1.6c3.5,0.6,8.7,2.2,8.5-2
									c-0.2-3.3-5.2-6,0-7.3c4.7-1.2,14.1,4.7,18.5,2c4.5-2.8-0.1-3.8-3.2-4.2c-6.5-0.8-12.9-1.7-19.3-2.7c-5.1-0.8-13-3.5-17.9-2.3
									c5.2-4.3,10.5-3.1,16.8-3c6,0,11.7-1,17.8-0.6c2.1,0.1,5.1,1.4,6.3-0.8c1.6-2.9-4.2-3-4.9-3.1c-2.6-0.3-10.3,1.1-12.4-0.3
									c-0.5-0.4-2.6-6.7-2.8-7.7c7.9,2.6,14.7,7.3,23,9c2.3,0.5,8.7,2,10.8,0.5C1150.9,413.3,1151.3,410.1,1148.3,410.6z M1073.6,436.9
									c1.7-0.6,6.4-3.3,6.8-1.1C1081,438.6,1074.8,437.5,1073.6,436.9z M1100.5,411c-2.3-0.4-4.6-1.2-6.9-1.6c6.1-1.1,9.4-0.8,14.3,2.7
									C1105.4,411.8,1102.9,411.4,1100.5,411z"
						/>
						<path
							d="M777.3,352.5c-7.7-3.9-9.9-8.4-18.6-3c-0.3-1.6-12-5.4-13.9-5.2c-3.5,0.4-6.5,3.6-9.4,5.3c-3.5,2-7.3,3.5-10.9,5.1
									c-4.9,2.3-11.7,5.3-13.4,11c-0.6,2.2-0.6,7.2,1.7,8.4c3.8,1.9,2.2-2.6,2.2-4.1c0-8.4,12.7-14.5,20.3-14.8c3.8-0.2,7.6,2,11,3.3
									c3.9,1.5,2.3,0.4,6-0.3c6.4-1.1,12.7,1.5,16.6-6.8c1.8,0.9,5.3,5,7.4,4.5C777.3,355.7,779.9,353.8,777.3,352.5z M748.1,356.3
									c-0.4-1.7-0.8-3.3,0.5-4.8c2.2-2.5,3.3-0.4,6.3,0.3C752.6,353.2,750.3,354.7,748.1,356.3z"
						/>
						<path
							d="M815.5,359.8c-8.1-2.5-11.2-10.5-18.3-14.1c-9.7-5-15.3,3.7-22,9.8c-1.8,1.6-6.6,6-1.1,5.9c1.6,0,2.9-2.6,4.1-3.3
									c2.7-1.4,0.9-0.5,4-0.1c6.6,1,14,2.7,18.7-4.1c2.7,3.7,6.4,7.6,11,8.8c1.3,0.3,3.7,2,4.7,0.1C817.2,361.7,816.9,360.3,815.5,359.8
									z M796.3,354.2c-1.1-1.1-1.8-1.8-1.3-3.6c1.4,0.6,2.3,1.4,3.2,2.4C797.4,353.3,796.8,353.7,796.3,354.2z"
						/>
						<path
							d="M763.9,369.6c-5-5.6-7.7-5.6-13.4-1.8c-2.3,1.5-9.4,3.2-8,6.3c0.9,2.1,4,0.2,5.4,0.1c2.9-0.1,5.5-0.2,8.4-1.4
									c0.7-1.1,1.8-1.7,3.1-1.8c0.9-0.3,1.5,0.2,1.8,1.4C762,372.4,766.6,372.6,763.9,369.6z"
						/>
						<path
							d="M813,372.8c-5.2-0.9-10.2-2.7-14.9-5c-3.6-1.8-7-5.2-10.9-6.3c-8-2.2-18.1,9.1-25,12.4c-2.1,1-9.7,4.5-3.8,5.9
									c1.3,0.3,3.1-0.8,4.4-1c3-0.4,5.9-0.2,8.8-0.1c5,0.2,10.8,1.3,14-3.4c2.2-3.1,0.6-5.7,2.3-8.9c5.4,3.5,10.8,6.5,17,8.5
									c1.9,0.6,6.5,2.8,8.4,1.6C814.6,375.6,815.1,373.2,813,372.8z M774,373.7 M783,370.8c1.2,0.9,1.4,1.7,0.5,2.5
									C783.4,372.4,783.2,371.6,783,370.8z"
						/>
						<path
							d="M830.5,398.8c-7.2-1.7-13.4-5.1-20.8-6.3c-7.2-1.1-12.3-3.3-18.1-7.5c-10.8-7.8-18.5,9.1-28.6,12.9
									c-1.8,0.7-14.9,1.5-10.9,5.5c1.2,1.2,11.3,1.3,12.8,1.5c7.4,0.8,10.3-4.4,16.8-6.1c2.5-0.6,6.3-0.2,8.1-2.7c1.8-2.6-0.9-5-0.4-7.7
									c8.1,6.7,17.1,6.6,26.7,9.3c4,1.1,8,4.4,12.1,4.6c1.1,0.1,2.9,1,3.5-0.5C832.1,400.6,832,399.2,830.5,398.8z"
						/>
						<path
							d="M814.2,400.5c-4.9-6.6-8.1-7.1-15.6-3.4c-2.1,1-20.1,7.6-14.5,10.2c1.8,0.8,5.4-2,7.3-2.1c3.2-0.2,5.6,1.5,8.9,0.8
									c3.3-0.7,6.4-2.3,9.7-3C811.2,402.7,816.6,403.7,814.2,400.5z"
						/>
						<path
							d="M828.2,424.5c-9.4,0.3-19-16.7-27.6-12.3c-1.1,0.9-2,1.8-2.8,2.9c-1.1,2.7-2.6,3.1-4.4,1.1c-3.4-0.6-5.9-3.7-9.6-3.8
									c-5.9-0.2-11,4.8-15.9,7.5c-2.5,1.4-13.5,3.2-14,5.6c-1,4.2,9.3,1.7,11.3,2.2c6.1,1.3,7.3,1.4,13.5-1.2c3.1-1.3,4.7-2,7.1-4.6
									c3.2-3.4,2.7-4.2,6.8-1.7c-1.9,1.7-12.3,6.9-7.6,9.7c0.3,0.2,5.5-1.3,4.9-1.2c3.5-0.2,5.3,0.7,8.6-0.2c2-0.6,4.1-1.2,6.1-1.7
									c2.1-0.5,3.7,1.1,4.3-2.2c0.6-3.5-5-3.7-2.6-7.5c4.9,3.2,9.5,7.7,15,10c1.5,0.6,6.9,2.8,8.3,1
									C830.4,426.8,830.4,424.4,828.2,424.5z"
						/>
						<path
							d="M821.2,447c-8.7-0.4-15.9-9.6-24.6-11.4c-4.8-1-6.8,0.6-10.3,3.6c-1.7,1.5-3.1,3.2-4.8,4.6c-1,0.8-6.2,3.2-6.8,4
									c-4,5.7,7.3,3.6,10.9,4c4.1,0.5,8.7,2.8,12.2-0.2c3.9-3.3-0.3-6.8,2.3-10.2c5.6,2.4,10.5,6.8,16.2,8.8c0.8,0.3,5.2,1.6,6.1,0.5
									C823.5,449.4,823.3,447.1,821.2,447z"
						/>
						<path
							d="M826,463.8c-11.6,6.1-19-10-29.4-8.2c-3.6,0.6-7.9,4.7-11.2,6.4c-4.5,2.3-9.3,5.2-14.4,5.8c-2.6,0.3-7.8-0.9-5.1,3.3
									c1.1,1.7,13.4,0.1,15.5,0c6.7-0.5,24.1,4.2,25.5-7.3c4.6,2.9,9.1,5.6,14.8,5.2c1.7-0.1,6.1-0.4,6.8-2.3
									C829,465.2,828.4,462.5,826,463.8z"
						/>
						<path
							d="M774.3,483.5c-4.6-2.5-8.4-9.7-13.9-8.3c-2.1,0.5-15,8.8-14.3,10.9c0.8,2.6,3,0.4,4.6,0.3c4-0.2,8.5,1.1,12.6,0.1
									c1-0.3,2.2-1.1,3.2-1.2c2.1-0.2,1.1-0.3,3.5,0.3c1.3,0.3,3,2.4,4.4,0.9C775.2,485.8,775.8,484.4,774.3,483.5z"
						/>
						<path
							d="M812.8,482.5c-6.5,0-13.2,0.5-19.5-1.1c-6.1-1.5-10.3-3.8-15.8,0c-2.3,1.5-4,3.5-6.4,4.9c-1.2,0.7-6,2-6.7,3.2
									c-3.2,5.5,4.4,2.8,6.7,2.3c5.2-1.1,9.3-0.3,14.4-0.3c5.7,0,3.8-2.7,7.9-5c3.6-2,9.9-0.1,13.8,0c1.5,0,5.7,0.8,6.9-0.4
									C815.2,484.9,814.7,482.5,812.8,482.5z"
						/>
						<path
							d="M786.2,503.5c-4.5-0.5-7.9-4.2-12-5.7c-6-2.2-11.1-1.1-16.4,2c-4.2,2.4-8.6,4.9-12.7,7.6c-2,1.3-10.1,7-3.5,7.4
									c1.3,0.1,3.2-1.9,5-2.2c2.2-0.4,4.5,0.5,6.7,0.3c3.6-0.3,6.7-0.8,10.4-0.8c2.3,0,8.1,0,9.4-2.5c1.4-2.7-1.6-4.6-0.1-7.9
									c3.5,1.5,8.1,6,12.1,5.8C787.4,507.1,790.2,503.9,786.2,503.5z"
						/>
						<path
							d="M783.5,527.5c-6.7-0.3-11.4-6.3-17.8-7.3c-1.8-0.3-3.9,0.8-5.5,0.6c-2.5-0.4-0.8-0.5-4.1-1.8c-3.4-1.4-6.4-2.2-10.1-1.6
									c-3.3,0.5-6.9,2.1-9.2,4.6c-1.6,1.8-3.6,5.5,0.5,5c1.2-0.1,1.5-2,2.6-2.4c2-0.6,2.6,0.6,4.5,1.1c4.2,1,6.3-1.5,10.5-1.8
									c-2.9,2.3-16.6,10.7-7.7,10.9c2.7,0.1,6.2-1.8,8.9-2.2c3.1-0.4,6.4,0.2,9.4-0.3c7.8-1.2,0.4-2.2,3.3-6.6c3.8,2.1,8.8,6,13.3,5.6
									C784.4,531.1,787.6,527.7,783.5,527.5z"
						/>
						<path
							d="M777,554c-5.7,0.1-10.6-3.9-14.6-7.5c-2.8-2.6-5.6-7.4-9.8-7.6c-4.4-0.2-8.7,5.1-11.7,7.8c-4,3.5-7.6,6.7-12.4,9.1
									c-2.7,1.3-8,5.2-2.1,5.4c1.4,0.1,4.8-2.6,6.3-3c2.7-0.7,5.2-0.1,7.9,0c4.2,0.1,8.5-1.7,12.5-3.2c7.1-2.7-1.5-5.2,3-9.3
									c3.7,4.5,9.3,9.1,14.8,11.1c1.4,0.5,6.1,2.4,7.3,0.7C779.2,556.3,779.2,554,777,554z"
						/>
						<path
							d="M762.8,570.2c-9.3,0.1-16.7-9.3-25.9-4.8c-3.3,1.6-18.7,12.8-9.3,12.5c1.4,0,3.3-1.5,5-1.5c1.7-0.1,3.4,0.5,5,0.6
									c5.3,0.2,5.9-2.2,10-4.7c3.2-2,1.3-1.4,5.1,0.3c2.4,1.1,4.7,1.6,7.3,1.6c1.3,0,4.3,0.5,4.6-1.4
									C764.8,571.6,764.3,570.2,762.8,570.2z"
						/>
						<path
							d="M876.8,540.3c-4.9-1.1-9.6-1.6-14.4-3.1c-4.2-1.3-7.9-2.7-12.4-1.3c-2.9,0.9-7.8,6.7-10.3,6.6c-3.6-0.1-7-5.6-11.6-5.6
									c-4,0-7.2,2.5-10.1,5.1c-2,1.8-5.7,7-8.7,7.1c-4.2,0.1-7.8-5-12.9-4.1c-4.3,0.8-6.3,4.2-9.6,6.5c-2.3,1.7-18,6.2-14.6,11.2
									c1.9,2.7,4.8-0.5,6.6-1.1c3.2-1.1,6.9-0.4,10.3-0.8c2.7-0.4,9.3-1.1,10.9-3.5c1.1-1.7-0.8-3.9,0.4-5.5c2.2-2.9,5.8,0,8,1.2
									c0.2,0.1,11.4,6,8.6,0.2c4.1,0.3,11.9-0.4,15.1-3.4c3.5-3.2-1.5-3.9,0.5-7.2c4.3,2.5,6.3,5.9,10.6,4.7c1.9-0.5,4.3-2.7,6.5-3.3
									c2.2-0.6,4.5-0.6,6.7-1.4c3.8-1.3,2.2-1.5,6.3-0.9c2.5,0.3,5.1,1.4,7.7,1.8c1,0.2,5.6,1.5,6.5,0.6
									C878.1,543,879.1,540.8,876.8,540.3z"
						/>
						<path
							d="M685.7,686.3c-11.1,3-18.8-6.4-26.8-12.3c-3.9-2.9-8.5-5.7-12.9-7.6c-6.2-2.6-8,0-12.7,3.7c-8.8,7-19.2,14.4-29.8,17.7
									c-2.7,0.8-6.6,1.3-2.4,4.1c2.8,1.9,13.8,0.4,16.8-0.4c3.7-0.9,6.9-4.1,10.6-4.5c1.5-0.1,1.2,1.6,3.4,0.7c0.8-0.3,1.4-3,2.5-3.7
									c1.4-0.8,3.2,0.2,4.5-0.6c2-1.2,1.3-2.7,2.1-3.7c2.5-3,2.7-1.6,6.8-2.9c6-2,4-3.2,10.2,1.6c3.7,2.9,7.2,6.1,11.3,8.5
									c3.9,2.3,8.1,3.9,12.7,3.8c1.1,0,5.4,0.1,6-1.5C688.4,688,687.8,685.8,685.7,686.3z"
						/>
						<path
							d="M677.8,696c-4.3-2-8.8-3.7-12.8-6.2c-3.1-2-5.7-5.3-9.1-6.8c-7-3.2-12.8,1.5-18.2,5.7c-2.1,1.6-5.1,4.7-7.8,5.1
									c-3.4,0.5-5.4-2-9.6-0.8c-2.2,0.6-16.7,8.4-14.9,10.5c1.7,2,5-0.2,7.1-0.4c3.5-0.4,6.9,0.7,10.4-0.3c1.4-0.4,3.6-2.4,4.8-2.7
									c1.3-0.3,2.4,1,4,0.5c1.5-0.4,0.5-2.5,1-2.8c1.5-1,5-1.3,6.9-2c4.5-1.6,8.7-3.8,13.1-5.6c4.6-1.8,4.3-1.1,8.1,1.7
									c3.7,2.7,8,5,12.3,6.6c1,0.4,3.1,1.8,4.3,1.2C678.6,698.9,679.6,696.8,677.8,696z"
						/>
						<path
							d="M751.3,697c-4.7-3.6-12.2-9.7-17.8-11.3c-2.7-0.8-5.4,0-8-1c-3.3-1.3-6-4.4-9.5-5.4c-7.3-2.2-13,4.6-18.7,8.3
									c-3,2-6.1,3.8-9.4,5.1c-1.9,0.8-8.5,1.3-9.3,3.1c-2.6,5.8,11.8,0.2,13.7-0.2c3.6-0.9,6.3-2.4,9.7-3.7c3.8-1.5,7.7-1.7,11.5-3.5
									c1.4-0.7,4.4-3.2,5.7-2.7c2.4,0.8,1.9,4.6,3.5,6.1c1.2,1.2,6.3,3,8.1,3.5c3.9,1.1,3.4,0.2,6.2-2.5c2.7,2.5,6.6,4.4,9.8,6.2
									c0.9,0.5,2.4,2.2,3.6,1.8C752.2,700.3,752.5,698,751.3,697z"
						/>
						<path
							d="M735.8,699.6c-7-1.4-12.7-6-19.5-7.5c-5.7-1.3-10.5,0.9-15.3,3.8c-5.5,3.3-11.2,5.5-16.8,8.6c-6.6,3.7-1.3,4.9,3.8,2.7
									c8.8-3.8,18.6-7.2,28-8.9c2.7-0.5,2.7-0.5,5.9,0.9c2.5,1.1,4.9,2.2,7.5,3c1.5,0.5,4.9,2.2,6.5,1.2
									C737.5,702.4,737.8,700,735.8,699.6z"
						/>
						<path
							d="M678.5,703.2c-8.2-0.3-14.2-5.5-21.5-8.3c-8.3-3.3-13.3,0.5-19.6,5.4c-6.2,4.8-13.8,6.7-20.9,9.7
									c-1.8,0.8-7.4,2.3-6.9,4.9c0.6,3.1,3.5,0.7,5.1,0.6c3.8-0.4,7.6,1.2,11.4,0.3c3.3-0.7,5.1-2.7,7.6-4.8c4.8-3.9,11.9-5.3,17.6-7.6
									c6.5-2.6,12.1,1,18.6,2.3c1.9,0.4,7.5,2.5,9.1,1.2C680.3,706.1,681.3,703.3,678.5,703.2z"
						/>
						<path
							d="M784.2,660.5c-7.8-0.5-14.9-6.3-22.6-4.9c-3.6,0.7-6.8,2.7-9.9,4.5c-2.5,1.4-9.2,3.7-10.8,5.6c-5.9,7.1,8.3-0.1,9.9,1.8
									c3.6,4.2-10,9.3-12.6,9.9c-1.9,0.5-7.2-0.1-7.1,2.8c0.1,2.8,4.7,1.8,6.7,1.9c4.4,0.2,7.8,1,12-1.1c3.4-1.7,6.7-4,10.1-5.8
									c4.9-2.6,4.5-3.9,9.2-2.1c1.8,0.7,5.9,5.6,7.9,4.6c5.9-3.1-12.1-11.1-15.7-10.6c3.7-7.3,10-4.8,16.1-3.5c1.4,0.3,7.1,1.9,8,0.5
									C786.4,662.8,786.4,660.6,784.2,660.5z"
						/>
						<path
							d="M784.8,684c-2.4-1.1-5-1.9-7.3-3.2c-1.6-0.9-3.7-3.5-5.5-3.9c-4-0.9-6.9,3.8-10.2,5.6c-3.9,2.1-8,4-12.1,5.8
									c-2,0.9-8,2.9-3.1,5c1.5,0.6,3.3-1.1,4.7-1.4c2.8-0.6,5.8-0.1,8.6-0.1c5.7,0,6-1.3,9.3-5.6c3.8-5,2.9-3.7,8.5-1
									c1.5,0.7,4.3,2.6,5.8,2.2C784.9,687.2,787.4,685.2,784.8,684z"
						/>
						<path
							d="M791.8,694.3c-4-0.9-7.1-3.7-11.3-4.1c-3.6-0.3-7.6,0.9-11,2.1c-2.5,0.9-9.8,3-11.1,5.4c-2.4,4.5,1.4,2.9,3.9,2.4
									c3.1-0.7,7.2-0.3,10.2-1.4c2.3-0.8,3.8-3.9,6.1-4.4c3.9-0.8,8.4,4.8,12,3.8C792.3,697.8,795,695,791.8,694.3z"
						/>
						<path
							d="M569.3,750.6c-3.4-2.5-5.6-8.5-9.4-10.1c-4.4-1.9-6,2.9-8.4,6.7c-4-4.6-7.4-9.6-11.1-14.5c-2.9-3.9-7.9-12.5-12.6-7.7
									c-4,4.2-5.7,11.2-9.6,15.8c-1.7,2.1-4.4,4.1-5.7,6.5c-1.6,2.9-0.6,2.3-1.1,4.4c-5.6-4.4-6.7-2.9-11,1.7c-3.6,3.7-7,7.6-11,10.9
									c-3.8,3.1-7.7,4.9-12.1,6.8c-3.8,1.7-5.5,4.2,0.1,4.1c5.2-0.1,9.3-5.1,14.1-6.1c2.3-0.5,4.7,0,7-0.4c1.9-0.3,4-1.7,5.9-1.7
									c3-0.1,10.2,5.2,12,2c0.2-0.4-3.7-9-4-10.6c2.1,1.9,9.4,8.6,12.5,7.3c3.3-1.4,1.2-3.4-1.2-4.4c3.2-1.4,4.3-4.7,7.8-5.9
									c3.4-1.2,1.8,1.1,3.5-1.9c0.7-1.1,1.1-3.5,0.7-4.7c-0.5-1.4-2.8-1.8-3.1-3.3c-0.4-1.9,1.7-2.7,1.1-4.6c-0.4-1.4-2.7-1.1-2.6-3.7
									c0.1-1.9,2.8-3.8,3.9-5c5.9,7.4,11.2,16.2,17.5,22.1c2.3,2.2,5.6,7.4,8.1,3.1c0.3-0.5-1.1-2.1-0.9-2.8c0.4-1.6,2-3.8,3.2-4.9
									c1,1.3,3.2,4.6,5,4.6C569.8,754.2,571.3,752.1,569.3,750.6z"
						/>
						<path
							d="M587.1,880c-3.5-6.5-8.2-14.8-11.5-3.6c-1-2.3-2.1-6.6-4.7-7.6c-4-1.5-3.2,1.9-6.5-2.5c-1.4-1.8-7.7-14.6-11.2-9.4
									c-3.3-4.5-6.1-10.3-9.8-14.3c-5.1-5.6-8.3,3.4-11.2,7.7c-3.7,5.5-7.7,10.8-11.4,16.3c-1.1,1.7-3.6,5.7-2.1,7.8
									c1.8,2.7,4.9,1.4,7.7,1.5c3,0.1,5.2-0.2,8.1-0.6c7.7-1.1-0.4-1,3.7-0.3c4.9,0.8,7.2,5.9,11.3,6.9c1.9,0.5,4.3-0.6,6.4-0.4
									c2.4,0.2,3.5,1.7,5.9,1c7-1.9,1.2-6,2.7-9.7c3.9,4.1,4.2-2.6,7.2,4c3.5,7.8,5.8,5.8,8.4-1.6c1.1,1.4,2.7,6.6,4.3,7.1
									C585.6,882.9,588.4,882.5,587.1,880z M544.3,852.1c2.1,3.4,6.8,6.8,6.3,10.2c-1.2,7.5-4.7,6.2-9.6,6.5c2.3-5.1,3.9-5,1.5-10.5
									C541.1,855.2,539.1,852.1,544.3,852.1z"
						/>
						<path
							d="M543.7,927.9c-6.5-3.6-11.3-8.5-16.8-13.3c-6.4-5.5-7.8,3.7-11.8,8.2c-4.1,4.6-9.6,7-14.6,10.3c-5,3.3-3.9,4.5,1.6,5.5
									c6.7,1.2,12.4,2.7,18.5,5.3c3.9,1.6,8.4,3.6,11.4-0.3c1.9-2.5,2.8-10.2,1.6-12.9c-1.6-3.8-6.7-5-6.3-10c3.5,3.3,7.5,6.9,11.8,9.2
									c0.9,0.5,2.9,2.3,4.2,1.7C544.6,930.9,545.3,928.8,543.7,927.9z"
						/>
						<path
							d="M581.8,978.8c-5.6-3-7.8-6.3-14.1-7.4c-0.8,0.2-1.7,0.4-2.5,0.5c-1.3-1.2-2.8-2.2-4.3-3.1c-2.1-1.1-4.4-2.1-6.8-1.5
									c-4.9,1.1-7.8,7.1-11.3,10.1c-1.9,1.7-11.9,5.7-10.5,8.8c1.4,3.1,7.5-2.4,10.4-1c1.4,0.7,2.4,4.1,3.9,3.9c1.9-0.2,1.2-1.9,2.6-2.7
									c1.1-0.6,3.1-1.5,4.3-1.3c1.3,0.3,1.6,2.6,2.2,2.7c4.6,0.8,9-6.2,9.3-10.8c2.3,1.9,7.5,1.1,4.4-1.6c3.9,0.4,5.7,5.1,9.3,6.1
									C580.2,982,585.6,980.8,581.8,978.8z"
						/>
						<path
							d="M599.5,945.5c-7.3-0.1-14.6-1.7-21-5.2c-5.2-2.8-8.8-7.3-13.9-1.4c1.2-2.9,4-4,4.1-7.2c0.1-3.3-3-4.1-0.4-8.2
									c2.4,2.5,13.8,12.5,17.6,10.6c5-2.5-9.8-9.1-11.6-11c-4.4-4.7-2.2-12.7-7.7-16.1c-2.3-1.4-6.3-0.5-6.9-4.1
									c-0.4-2.6,1.4-1.6,3.5-1.2c5.6,1.1,11.1,8.4,16.1,11.3c1.6,0.9,5.9,4.3,7.3,1.4c1.4-2.8-4.2-4.3-5.8-5.2
									c-6.9-4.2-22-24.1-29.7-13.7c-0.1-2.8-7.1-9.4-9.6-10.8c-5.3-2.9-7.1,1.4-9.6,5.6c-4.1-3.6-11.6-14-17.7-13.4
									c0.8-4.7,1.3-5.2,0-9.9c-1-3.8-0.9-5.5,1.6-8.9c1,1,7.3,9.7,8.5,5.2c0.6-2.4-4.7-7.6-6.8-9c-4.4-2.8-5.4,0-8.9,3.4
									c-4.7,4.4-9.5,8.7-14.1,13.2c-2.5-3.8-5.5-8.6-10.5-5.6c-0.8-2.9-2.4-5.2-2.8-8.3c-0.2-1.8,1-6.5-0.6-7.7c1.9-0.8,6.1,5.6,7.3,6.8
									c1.7,1.7,3.7,3.4,5.7,4.6c1.4,0.9,2.9,1.6,4.5,2c1.6,0.2,2.4-0.5,2.5-2.1c3.4-0.7,2.1,1.6,4.2-1.2c0,0,0.1-4.3,0.4-5
									c0.7-1.8,4.3-3,2.9-5.6c-1-1.8-4.1,1.6-4.7-1.1c-0.2-0.7,2.8-4.4,3.5-4.9c2.8-2,2.7-1.4,5,0.4c2.5,1.9,6.4,10.8,10.9,8.9
									c2.6-1.1,1.1-6.4,3.5-8.6c1.8-1.6,3.9-0.2,5.3-1.4c1.9-1.7-0.7-2.1-0.8-3.2c-0.2-1.6,3.4-1.3,4-1.9c1.4-1.3,1.5-1.9,2.1-3.9
									c0.7-2.2,0.3-2.8-0.1-4.6c-0.4-1.9-2.9-1.3-1.3-4.2c4.2,2.1,8.6,3.5,12.7,5.8c2.4,1.4,7.3,5.8,10.5,4.7c6.2-2.2-8.7-8-11-9.3
									c-5.9-3.4-15-9.5-21.4-5.6c-3.6,2.1-6.5,5.7-10,8.1c-3.8,2.6-3,3-6.1,0.7c-1.8-1.4-3.3-4.7-4.6-6.5c2.1,0.6,4.8,0.1,7,0.5
									c2.8,0.5,6.1,1.6,8.6-0.8c1.5-1.5,0.9-4.5,3.8-5.5c2.1-0.7,5.3,2.6,6.5-0.9c1-2.8-9.6-10.8-4.4-12.3c1.4-0.4,6.1,4.8,7.5,5.7
									c3,1.8,6.1,2.3,9.3,3.5c1.4,0.6,5.6,4.3,6.8,4.1c3.4-0.7,1.1-3.8-0.2-5.1c-4.2-4.1-11.9-4.2-16.7-8.1c-4.5-3.6-9.6-10.7-15.4-5.5
									c-2.2,2-3.6,6.9-6.1,8.1c-2.4,1.1-6.7-2-9.3-3.7c4.9-3,12.5-0.8,11.1-8.5c-1.1-6.1-7.7-7.9-8.4-14.9c4.9,3.2,9.6,6.7,14.5,9.9
									c2.8,1.8,12,9.5,15.5,9.2c6.6-0.6-0.3-4.7-3-6.2c-5.8-3.3-11.3-7-16.8-10.8c3.1,1.6,6.3-0.3,9.5-0.2c2.8,0.1,5.4,1.6,8.3,1.3
									c1.6-0.1,5.4-0.8,6.7-1.2c3.6-1.1,4.8-2.5,2.6-6.3c-1.3-2.2-6.2-3.1-6.6-5.7c-0.2-1.5,1.9-5.1,3.4-5.1c0.9,0,6.5,4.8,7.6,5.4
									c2,1.1,8.7,5.2,10.9,4.8c8.9-1.3-5.9-6.8-8.1-7.9c-4.8-2.4-10.5-9.8-16-9.7c-6.1,0.1-11,9.2-15.3,12.7c-4.1,3.5-11,5.4-5.1,10.5
									c-5.9-4.1-12.5-11.6-18.4-4.3c-5.1,6.3-8.2,14-14.1,19.8c-2.3,2.3-17.1,8-15.7,10.5c1.5,2.7,8.5-1.3,10.3-2
									c3.1-1.1,6.2-1.7,9.3-2.9c-3.9,4.5-7.9,9.2-12.6,12.9c-2.3,1.8-12.6,6.1-12.5,9.2c0.2,3.2,7.8,0.1,10,0.4c4.7,0.7,9.1,3,13.9,2.3
									c-1.9,4-3.7,8.1-6.4,11.5c-1.1,1.4-2.9,4.2-4.6,4.6c-2.6,0.7-0.2,0.7-2.8-0.5c-3.1-1.4-4.6-5.9-8.5-6.3c-3.9-0.4-4.9,2.1-6.2,5.2
									c-1.1,2.5-5.4,11.3-3.9,13.6c1.6,2.4,2.5,0.1,3.8-0.4c2.3-0.9,5-2,7.2-3c4.1-1.8,3.1-2.8,3.7-7.3c7.1,6.4,3.3,1.5,0.6,6.5
									c-2.2,4,1.6,4.5,5.1,4.2c-1.9,2.3-3.3,4.8-4.9,7.3c-1.2,1.9-6,6.3-6.4,8.1c-1.1,4.8,2.9,3,5.2,2.6c0,0-0.6-0.9,1.1-1
									c1.5-0.1,2.5,1.5,3.6,1.6c4.4,0.1,6.4-5.6,10.7-4.9c-4.7,3.8-8.2,8.8-12.5,13c-2.1,2-13,7.3-12.4,9.7c0.7,2.8,12.5-1.3,15.6-0.7
									c2.5,0.5,3.9,1.6,6.5,1.4c2.5-0.2,2.7-1.2,5.1-0.5c4.8,1.4,9.9,4.5,11.4-3.2c1.3-6.9-6.5-7.9-8.3-13.7c-3.2-10.3,5.5,0.5,7.6,2.8
									c2.5,2.7,7.5,10.8,11.2,11.2c-1.6,2.2-3.7,5-6.2,6.1c-2,0.9-4,0.6-5.9,1.7c-1.2,0.7-1.8,3-3,3.4c-1.3,0.3-5.5-2-6.9-2.1
									c-5.7-0.3-8.4,4.7-12.8,7.6c-2.2,1.4-10.4,3.7-6,6.8c2.3,1.6,6.4-3.2,9.1-0.1c4.3,4.8-8.1,6.4-7.5,11.1c0.5,3.9,22.8,1.9,26.6,2.8
									c3.6,0.9,5.7,1,9.3,1c1.2,0,5.9,0.9,6.9,0.4c3.4-1.6,0.3-2.5,1.2-4.8c0.5-1.3,3.2-4.3,3-5.6c-0.3-1.7-6.1-3.5-7.4-4.3
									c-1.8-1.1-4.7-2.8-4.4-5.2c0.7-5.8,10,2.6,11.7,3.4c1.8,0.9,5.8,3.4,7.7,2.8c3-0.9,0.7-1.3,2.4-2.6c5-3.7,11.6-2.8,16.9,0.6
									c-1.4,1.4-6.6,4-6.8,6.1c-0.3,2.9,1.8,1.9,3.2,2.6c2.1,1,6.5,1,8.9,1.5c3.8,0.9,5.9,1.5,9.3-0.9c-3.3,5.5-4.7,10.6-9.7,14.7
									c-1.2,1-12.1,10.7-7.8,12.4c3.1,1.2,2.2-2.4,4-2.7c3.3-0.5,4.9,3.1,7.5,2.8c1.7-0.2,2-2.7,4-3c1.9-0.2,3.6,1.6,4.8,2.7
									c-1.2,1.1-2.4,2-3.8,2.9c-1.4,1.7-2.5,1.6-3.3-0.3c-2-0.4-2.1-2-4.8-1.5c-2.2,0.4-2.9,2.9-4.6,3.7c-3.6,1.6-1.7-2-5.3-1.8
									c-3.8,0.2-7.8,7.9-9.8,10.7c-1.1,1.5-10.7,10.9-5.2,11.7c2.2,0.3,2.8-3.6,4.8-3.8c0.2,0,6,2.3,6.8,2.6c3.1,1.1,8.5,5,12,2.5
									c2.7-2,0-1.4,0.9-3.1c0.5-1,1.7-2.3,2.5-3.5c1.2-1.9,3.2-3.6-0.5-4.4c0.2-2.8,1.9-3.6,1.9-6.1c0-1.5-3.2-0.3-1.6-3.9
									c2.8,1.7,5,5.2,7.9,7.1c3.7,2.4,7.5,4.6,11.2,7c2.1,1.4,18.9,13.1,19,7.6c0-2.1-2.9-3.6-3.2-6.3c-0.3-3.1,1.9-5.6,1.7-8.7
									c-0.3-5.5-11.2-12.4-8.8-16.6c5.9,2.9,12,5.3,18.5,6.2c1.2,0.2,5.9,1.2,7.1,0.1C602,947.9,601.4,945.5,599.5,945.5z M511.3,837.2
									c-5-2.6-5.7,4.1-9,5.1c-2.8-5,2.2-7.6,1.1-12c-1.1-4.2-5.4-5.2-5-10.6C502.9,825.3,507,831.3,511.3,837.2z M500,805.6
									c2.8,1.7,5.7,3.3,8.8,4.4c-1.3,1.4-5.9,6.3-8,6c-2.3-0.3-3.3-6-6.4-5.7C495.9,808.4,497.9,806.8,500,805.6z M491,840.4
									c3-2.5,4.3-1.7,3.5,1.8c-0.7,3.1-4.4,4.4-6,6.8c-1.3-1.9-2.2-2.2-1.9-4.1C486.9,843.7,490.3,841,491,840.4z M484.3,904.4
									c-5.4-3.8-2.4-4.5,2.7-2.6C486.2,902.7,485.2,903.6,484.3,904.4z M523.8,900.5c-2-4.7-5.7-10.7-3.8-16c2.2,1.9,6.1,4.5,7.5,7.1
									c2.8,1.2,2.8,2.7,0.2,4.3C526.5,897.5,525.2,899,523.8,900.5z M548.5,899.9c-1.8,2.8-3.8,5.5-6.1,8c-0.8-5.9-0.4-12.2-1.6-18.1
									c1.2,1.4,7,8.5,9,7.7C549.4,898.4,548.9,899.1,548.5,899.9z"
						/>
						<g>
							<path
								d="M607.5,873c1.3-0.6,2.9-1.6,4.1-2.5c-0.6,0.4,1.8,3,3.5,1.8c2-1.4-0.1-4.5-1.7-5.3c-2-1-6.8,0.5-8,2.5
										C604.7,870.9,604.4,874.5,607.5,873z"
							/>
						</g>
						<path
							d="M764.2,866.3c-4.7,1.2-18.1,1.9-21.7-2.4c-1.8-2.2-1.6-6.8-3.3-9.4c-1.7-2.6-4.1-4.7-6.3-6.9c-1.3-1.3-4.3-6.2-6-6.5
									c-3-0.5-4.1,4-6.8,4.5c-4.7,0.7-9.4-10.2-10.6,1c-3-6.7-6.8-1.4-9.8,2.4c-3.7,4.7-7.6,10-13.4,12.4c-5.9,2.5-7.9-1.4-13.4-3.8
									c-6.5-2.9-7.8,2.2-11.3,6.6c-2-2.9-3.1-6.6-6.5-7.1c-1.4-0.2-2.6,1.3-4.3,1c-1.6-0.3-2.8-1.8-4-2.7c-2.3-1.8-3.1-4.1-6.1-2.4
									c-3.3,1.9-5.8,9.9-7.5,13.1c-4.2-4.1-5.8-7-11.3-3.1c-2.2,1.6-12,10.1-11.3,13.3c0.6,3,2,1,3.3,1.3c0.6-1.6,1.7-1.9,3.2-0.7
									c1.4,0.4,2.8,0.8,4.3,1c3.3-0.5,6.9-7.2,7.5-10.6c5.5,5.5-7.1,8-3.5,12.6c2.1,2.6,15.1,0.8,19.2,2.3c2.9,1.1,5.3,2.7,8.2,0.6
									c3.1-2.3,2.2-6.6,0.1-9.4c-1.1-1.5-3.6-2.3-4-4.1c0,0.1,1.2-2.6,1.2-3.2c-0.1-1.4-2.1-3.1-2.3-4.8c2.7,1.7,3.7,1.8,5.4,4.8
									c1.4,2.5,1.4,4.3,2.6,6.9c2.3,5,5,3.2,8.7,2.1c4-1.2,2-2.9,6.5-0.5c2.3,1.2,5,5.2,7.9,5.2c4.4,0,2-1.9,1.3-3.8
									c-0.2-0.5-1.7-1-1.9-1.5c-0.4-0.9,0.4-3.1-0.1-4.3c-1.6-3.6-7-2.8-6-8.2c2.7,1.5,5.1,3.1,8,4.2c3.2,1.2,7.3,0.9,10.3,2.2
									c2.1,0.9,3.3,3.5,5.6,4.5c2.3,1.1,6.2,1.4,8.7,1.7c6.2,0.9,8.5-2.4,7.1-8.5c-1.6-7.2,0.6-12.3,2.1-19.3c2.5,3.5,1.2,6.2,0.4,10.2
									c-1,5.1,0.4,6.9,1.8,11.6c1,3.5-0.5,6.4,4.1,6.2c1,0,2.2-1.7,3.2-2c2.2-0.7,4.1-0.5,6.3-0.1c2.4,0.4,7.5,3.7,8.8,1
									c1-2.2-5.8-7.3-6.4-9.4c-1.1-3.5,0.9-6.8-1.5-9.8c-1-1.2-2.2,0.6-3.6-1.4c-1.1-1.6-0.5-3.8,0.1-5.5c3.2,4.4,7.9,6.1,10,11.5
									c1.9,4.9,1,8.3,6.8,10.4c4.5,1.6,11.1,2.1,15.8,1.8c1.6-0.1,5.9,0.1,6.9-1.6C767.3,868,766.1,865.8,764.2,866.3z"
						/>
						<path
							d="M2723.5,1204.4c-5.4-1.3-8.9-3.8-12.5-8c-1.2-1.4-1.7-3.4-3.7-4.1c-2.3-0.8-3.1,1.2-4.8,1.3c-4.6,0.3-9.2-5.6-12.5-8.9
									c-1.7-1.7-3-3.8-5.4-4.4c-2-0.5-4.3,1.5-5.8,1.1c-1.7-0.4-2.9-4.2-5.1-4.8c-2.5-0.8-3.6,0-5.5,1.4c-2.9,2.1-5.6,7.7-7.6,10.7
									c-1,1.3-1.7,2.7-2.3,4.3c-0.8-0.2-1.7-0.4-2.5-0.5c-1.8,0.7-3.3,1-4.9,2.2c-2.2,1.5-5.7,8.1-8.5,8.2c-1.2,0-3.5-2.5-5-3
									c-2.6-0.8-4.2-0.7-6.8,0.3c-4.9,1.8-9.7,7.3-13.9,10.3c-1.9,1.4-11.9,6-10,9.1c1.5,2.5,5.9-1.8,8.3-2c3.8-0.3,6.2,3.1,9.7,2.5
									c1.6-0.3,2.7-2.2,4.7-2c1.9,0.2,2.7,2.3,4.6,2.5c1.4,0.2,6.5-1,7.1-2.1c2.2-4.1-5.3-6.7-1-12.3c3.9,3,14.4,14.4,20.3,12
									c5.7-2.3-4.1-8.5-5.8-10.3c4.1-2.2,4.9,2,8.8,0.9c1.9-0.5,2.2-3.1,4.7-2.8c1.4,0.2,3,3.5,4.7,2.1c1.5-1.2-0.7-2.6-0.1-4.1
									c0.8-1.9,3.9-3.8,5.9-3.8c1.7,0,4,2.8,5.2,2.3c2.4-1,0.6-3.5-0.5-4.8c-2.4-2.8-8.7-2.2-8.4-7.1c0.6-8.6,6.9,0.5,8.7,2.3
									c1.1,1.1,11.9,11.9,11.7,5.2c-0.1-1.6-4.7-4.2-5.6-4.9c-3-2.5-5.6-5.3-8.1-8.3c4.2-1,6.7,4.5,9.4,6.9c1.7,1.5,3.6,2.6,5.4,4
									c2.7,2.2,3,0.8,2.6,3.8c-0.6,5.7-6.2,6.6-10.3,8.1c-2,0.8-6.6,2.4-2.1,4.3c3.7,1.6,8.4-3.6,11.5-3.6c-0.1,0,3.2,2,3.3,2
									c1.5,0.1,2.7-0.4,4.2-0.4c2.1,0,7.7,2.5,8.7,0.8c1.8-3.1-4.2-5-3.6-8.2c2.4,2.2,7.2,5.4,10.6,5.5
									C2723,1207.9,2727.8,1205.4,2723.5,1204.4z"
						/>
						<path
							d="M2773.9,1206.1c-5.6-2-11.6-1.1-17.2-3c-4.2-1.4-6.9-4.7-11.4-4.6c-4.9,0.2-4.9,2.7-9.1-0.8c-4.2-3.4-3.4-4.2-8.1-0.2
									c-1.5,1.3-9.1,6.3-7.7,8.4c2,2.8,2.7-1.2,4.6-0.5c0.8,0.3,2.9,3.3,3.7,3.9c2.4,1.9,6.6,4.6,9.8,3.2c6.6-3-1.1-4-1.2-4.9
									c-0.3-4.1,5.3-0.6,6.2-0.6c3.2,0.2,3.9-0.9,5.5-3.8c5.8,4.1,11.1,4.2,17.8,5.3c0.9,0.1,6.3,2,7.4,1.4
									C2775.6,1209.1,2775.7,1206.7,2773.9,1206.1z"
						/>
						<path
							d="M2755.9,1186.4c-6.8-1.8-14.6-2.9-21-5.7c-6.5-2.9-10.4-10-16.7-3.6c-2,2.1-2.9,3.8-5.6,5.3c-1.9,1-6.7,1.3-8,3.1
									c-1.2,1.6-0.9,2.8,0.9,3.5c1.1,0.4,2.2-1,3.2-0.9c1.6,0.3,1.4,0.5,3.4,1.4c1,0.5,1.5,2.1,2.8,2.4c1.6,0.4,2.8-0.5,4.3-0.6
									c3.3-0.1,6.3,1.5,9.5,1.4c2-0.1,6.1-0.6,5-3.4c-0.7-1.8-6.5-0.6-6.9-3.8c-0.7-5.2,5.2-1.7,7.1-0.9c3.7,1.5,7.9,2.1,11.8,3.1
									c2,0.5,7.9,3.3,9.8,2.5C2756.8,1189.6,2758.6,1187.1,2755.9,1186.4z"
						/>
						<path
							d="M2722.3,1167.3c-6-2.8-13.8-13.5-20.6-7.8c-1.7,1.4-2.6,7.1-4.7,7.9c-1.5,0.6-4.5-1.5-6.8-0.5c-1.9,0.8-3,3-4.5,4.4
									c-1.7,1.7-4.6,2.8-6.1,4.4c-1,1.1-3.3,1.9-1.2,3.6c2,1.6,3-1.1,4.8-0.9c1.9,0.1,2.8,2.7,5.2,3.5c2,0.7,3.7,0,5.7,0.3
									c2.3,0.4,9.9,3.3,6-2.9c-1-1.5-3.4-1.5-4.6-3.1c-1.4-1.9-0.3-4-1.3-5.8c3.2,0.8,14.7,9.4,17.6,4.6c1.9-3.1-2.5-2.2-3.3-3.2
									c-0.6-0.7,0.1-3-0.3-4c-0.7-1.7-2.6-4.2-2.5-6.1c3.7,0.2,7.9,4.9,10.8,6.8c1.2,0.8,3.7,3,5.3,2.2
									C2723.3,1170.2,2724.1,1168.1,2722.3,1167.3z"
						/>
						<path
							d="M2720,1143.3c-6.2-2.8-11.9-4.4-17.5-8.5c-5-3.6-9.4-4.6-15.3-2.1c-2.9,1.3-5,4.2-7.7,5.5c-1.4,0.7-4.1,0.2-5.7,0.7
									c-5.6,1.7-10.3,6.7-15.4,9.5c-1.6,0.9-17.1,7.4-11.4,9.8c2,0.9,4.6-2.4,6.4-2.8c0.2,0,1.1,1.3,1.7,1.2c1-0.2,3.1-1.3,4-1.4
									c2.2,0,3.3,1.8,5.1,1.8c2,0,3.7-2.6,6.2-2.7c3.9-0.1,3.7,1.3,4.6,3.4c-6.5-3.6-12,1.3-15.5,6.6c-5.5-4.1-6.9-4.7-11.2,0.3
									c-3.2,3.8-7.1,4.4-11.4,6.9c-2.7,1.6-12.2,6.7-7,9.7c0.8,0.4,6.5-0.7,7.8-0.4c2.9,0.7,5.6,1.3,8.2,1c-0.4,0,3.9,0.8,3,0.8
									c1.6,0.1,4.1-1.4,6.2-1.3c4.5,0.3,8.6,1.1,5.9-5.1c-1.4-3.3-5.5-6.1-5.6-10c4.8,3.7,9,6.9,15.1,8.4c5.4,1.3,8.6-1.7,2-3.6
									c2.8-4,5.7,0.4,8.5-1.1c-1,0.5,2.2-4.2,2.1-3.5c0.1-2.3-0.8-2.8-2.7-4.5c3.3,0.1,4.6-0.7,7.3-2.2c1.4-0.7,1.6-2.5,3.6-2.3
									c1.2,0.1,9.6,8.5,6.5,0.4c-0.7-1.7-6.5-6.9-8.4-7.7c-5.6-2.5-8,3.7-13.3,1.8c-4.9-1.7-5.4-5.3-0.2-6.4c2.1-0.4,5.1,0.2,7.5-0.2
									c2.3-0.4,6.2-1.7,8.5-1.2c3.2,0.7,7,7.3,10.9,6.1c5.2-1.6-1.5-4.2-3.2-5.2c3.2-4.1,9.4,0.5,13.9,1.1c1.3,0.2,3.6,1,4.8,0.5
									C2719.8,1146.3,2722.9,1144.5,2720,1143.3z"
						/>
						<path
							d="M2686.9,1131.7c-9-1.4-14.8-6.8-22.3-11c-7.3-4.1-10.3,4.3-16,8c-2.9,1.9-19.6,5.3-19.5,8.8c0.2,3.3,15.9-1.7,19.3-1.7
									c3.8,0,6.8,0.4,10.3,1.7c1.9,0.7,7.2,3.5,8.9,1.1c1.1-1.7-0.8-3-1-4.5c-0.2-1.7,2-1.3,0.8-3c-0.9-1.3-2.8,0.4-3.7-0.3
									c-1-0.9-2.3-2.8,0.2-4.1c1.5-0.8,6.5,3.2,8.5,4.3c2.8,1.5,9.9,5.6,13.2,4.7C2687.4,1135.2,2690,1132.2,2686.9,1131.7z"
						/>
						<path
							d="M2728.4,1116.9c-4.8-3.7-10.3-3.9-16-5.1c-7.4-1.5-13.1-5.4-19.7-8.5c-5.3-2.4-9.2,0.4-13.3-0.2c-3.9-0.5-7.5-5.3-2.8-7.1
									c3.9-1.5,33.8,8.2,33.4,3c-0.2-3-14-3.9-16.7-4.4c-7.8-1.6-15.8-3.2-23.8-2.4c-5.8,0.6-11.5,2.2-17.1,3.6
									c-3.7,0.9-2.2,2.2-6.8-0.1c-3.5-1.7-6.9-3.5-10.1-5.5c-4.7-3-8.9-6.1-14.2-2.9c-5.4,3.2-9.5,8.3-15.1,11.5
									c-3.2,1.8-6.6,3.4-10.1,4.7c-1.9,0.7-6.4,1.2-7.8,2.5c-6.2,5.8,7.9,1.5,9.2,1.2c6.3-1.3,12,2.2,18.7,2.2c2.7,0,5.3-1.3,7.9-1.1
									c2.4,0.2,5.3,3.3,7.2,2c4.3-3-1-5.3-1.5-7c-0.1-0.4-0.8-0.6-1-1.1c-0.4-1.1,1.5-1.6,1.4-2.8c-0.1-0.8-1.2-2.5-1.6-3.4
									c0,0,0.4-1.6,0.4-1.9c-0.2-1-2.7-1.5-2-3c0.8-1.8,13.6,6.5,15.7,7.6c1.9,0.9,8.7,5.2,10.6,4.7c2.2-0.5,3-2.6,0.6-3.8
									c1.6-0.8,7.4,4,8.9,4.5c4.7,1.7,9.6-0.2,14.3,1.1c-2.2,1.4-6.7,6.2-9.2,6.5c-3.1,0.4-6.8-3.5-10.5-3.1c-2.8,0.2-5.3,3.1-7.8,3.2
									c-2.7,0.2-2.4-0.4-5.2-2.4c-6.8-4.9-8.3-0.1-14.1,4.2c-2.3,1.7-13.7,6-9,9.2c0.8,0.6,5.1-1.2,6.8-0.9c1.4,0.2,3.2,1.7,4.7,2
									c1,0.2,5.7,0.7,6.8,0.3c5-2,0.8-2.9,0.5-5.5c-0.6-5.6,2-5.3,6-2.7c1.7,1.1,5.5,5.1,7.9,4.1c3.2-1.4,0.2-3.5-1.3-4.4
									c5.8-6.2,17.5,1.4,24.1,2.5c5.8,1,11.5,1.2,17,3.2c5.4,2,10.1,5.5,15.3,8c1.5,0.7,10.1,4.2,7.4-0.9c-1-2-10.2-5.2-12.5-6.9
									c-5.5-3.9-14.9-8.4-15.7-15.9c7.7,1.4,14.1,8.2,22.1,9.9c3.9,0.8,8.1,0.6,11.8,2.2c1.4,0.6,3.6,2.8,5.1,2.4
									C2728.5,1119.8,2730,1118.2,2728.4,1116.9z"
						/>
						<path
							d="M2727.2,1085.7c-11.9-4-22.9-11.9-34.4-17.1c-6.5-2.9-11.6-5.4-18.8-5.2c-4.4,0.1-11.5-2.4-15.5-1.3
									c-4.2,1.2-7.6,6.8-10.5,9.7c-1.4,1.4-2.9,2.8-4.7,3.8c-1.2,0.7-6.6,1.7-7,2.7c-2.3,5.5,6.6,1.8,8.3,1.2c5.5-2,12.7-1.3,18.7-0.4
									c-1.4,1.6-9.1,7.5-4.9,9.3c1.8,0.7,3.1-2.5,4.2-2.9c1.4-0.5,2.5,0,3.8-0.1c4-0.2,8.1,3.9,11.5,3.4c2.9-0.4,1.5-2.2,5-2.2
									c3.2,0.1,5.7,1.3,8.7,1.4c3.7,0.1,6.7,0.1,10.2,1.7c4.6,2.2,6.8,1.5,2.4-3.2c-4-4.2-6.9-8.2-10.3-12.9c7.7,3,14.4,7.2,21.6,11.2
									c2.2,1.2,7.9,5.2,10.4,4.8C2727.8,1089.3,2730.2,1086.8,2727.2,1085.7z M2691.3,1073c-2.2,1.1-4.2,2.6-6.4,3.9
									c-3.8,2.3-3.3,2-7,0.9c-6.6-1.9-4.5-5.4-7.8-10.1c4.9-0.2,9.1-0.4,13.7,1.4C2685.5,1069.7,2691.9,1072.7,2691.3,1073z"
						/>
						<path
							d="M1372.3,1594.3c-10.3-5.9-16.3-13.2-23.5-22.4c-3.9-4.9-4.9-5.6-9.8-1.2c-3.8,3.5-6.8,7.7-9.8,11.9
									c-3.6-3.8-3.9-5-8.3-2.6c-3.4,1.9-6.3,5.1-9.1,7.7c-3.3,2.9-6.6,6-10.2,8.5c-1.8,1.3-8.5,3.6-8.9,5.7c-0.9,4.4,5.9,1.1,7.3,0.8
									c3.3-0.9,7.6-1.5,10.9-0.4c4.8,1.5,8.3,10.3,12.5,2.4c1.5-2.8-1.4-9.5,1.7-10.7c2.3-0.9,4.4,2.1,5.7,3.4c2.8,2.7,2,3.1,5.5,2.2
									c1.9-0.5,4-2.8,5.5-4c1.2-1,6.5-4.2,7-5.1c0.5-0.9-0.7-2.4-0.5-3.4c0.2-1,1.5-2.1,1.5-3.1c0-2.8-3.6-3.9-2.3-6.9
									c1.2,1.7,2.4,3.4,3.7,5c2.5,1.5,2.6,3,0.3,4.5c-0.9,2.8-3.9,6-6,8c-3,2.9-10.6,6.9-3,8.6c2.1,0.5,4.8-0.8,7-0.7
									c3.3,0,3.7,2.4,6.8-0.3c1.8-1.6,2.9-8.4,5-9.3c2.1,2,4.8,3.3,7.6,4.1C1370.6,1597.5,1376.2,1596.5,1372.3,1594.3z"
						/>
						<path
							d="M1350.2,1620.6c-4-3.9-11.1-15.2-15.1-6.7c-1.6-1.7-4.9-6.1-7.7-5.5c-2.4,0.5-2.2,5.6-3.3,7.3c-3.4,5.9-11.9,9.3-17.3,13
									c-5.2,3.6-10,7.7-15.6,10.8c-1.5,0.9-3.1,1.6-4.8,2.3c-1-0.8-2.2-1.5-3.4-1.9c-3.4,0.2-5.9,2.6-8.7,4.3c-3.3,2-6.5,4-9.9,5.9
									c-1.9,1.1-9.3,3.6-10.2,5.4c-3.3,6.6,6.8,1.1,9.2-0.2c4-2.1,4.5-2.7,8.5-0.8c3,1.4,5.4,2.8,8.9,2.7c3.5-0.1,13-3.7,14.2-7.3
									c2.3,1.9,2.2,3.5,5.7,2.5c-0.6,0.6-1.1,2.8-2,3.1c-0.6,0.2-1.2-1.3-1.7-1.2c-3.3,0.7-4.8,0.2-5.4,4.7c-4.9,0.5-1.9-4.5-7.7-1.3
									c-2.7,1.5-7.5,4.2-9.5,6.4c-6.3,6.8,5.9,1.4,8.9,1.5c0.9,0,4.7,0.3,5.4,0.6c2.3,0.7,2.9,2.6,4.1,3.3c3.7,2.1,11.3,3,15.8,2.7
									c7.4-0.6,12.5,2.1,19.5-1.1c4.6-2.1,5.9-1.7,10.7-1.5c2.5,0.1,6.2,0.1,4.1-3.4c-0.9-1.5-8.1-1.1-10.1-1.8
									c-4.2-1.4-6.8-4.3-10.6-6.3c-2-1.1-3.2-1.6-5.4-1.4c-1.3,0.1-4.3,2.5-5,2.4c-2-0.3-4.7-5.5-6.7-6.7c2.9,0.8,15.4,5.9,16.7,2.3
									c0.8-2.1-5.3-6.4,2.4-7c3.1-0.2,11.6,6.5,14.9,4.5c5-3.1-10-8.7-12.3-10.1c2.8-1.2,4.9,1,7.2,0.4c0.9-0.2,5.3-4,5.1-5.1
									c-0.5-2.8-11-4.6-3.2-8.1c2.1-0.9,9.4,3.1,10.6-1.1c1.4-5.4-10-7.9-7.3-13.3c2.2,2.3,4.7,6.1,7.4,7.5
									C1347.7,1623.2,1353.4,1623.8,1350.2,1620.6z M1319.5,1661.2c2.4,1.2,4.2,3.3,6.6,4.7c-2.9,5.2-8.4-1.9-11.3-4.1
									C1316.2,1660.5,1317.8,1660.3,1319.5,1661.2z M1327.9,1623.6c0.2-6.7,7.4-2.6,6.6,1.3C1333.8,1628.2,1327.8,1628,1327.9,1623.6z
									M1316,1647c-2.1,1.9-9-2.4-6.7-4.4C1311.5,1640.6,1319.3,1644.1,1316,1647z"
						/>
						<path
							d="M1420.2,1703.5c-14.3,0.9-21.6-22.3-35.9-11.7c-2.6,1.9-2.6,3.1-4.9,2.5c-1.4-0.3-2.5-2.8-4-3.3c-4-1.4-4.2,0.9-7,3.7
									c-6.1,6.1-12.2,5.1-19.5,7.8c-1.6,0.6-4.7,1-2.5,3.6c1.4,1.6,7.9-0.3,9.5-0.5c5.3-0.6,11,3.3,16,1.7c2.6-0.9,3.4-3.9,5.6-4.8
									c2.9-1.2,4.4,1.4,6.8,2.4c7.3,2.7,2.1-4.2-1.1-6.9c3.9-3.4,6.6-6.3,11.8-4.1c4.4,1.9,7.8,6.2,11.7,8.9c3,2.1,7,4.8,10.9,4.7
									c1.1,0,4,0.5,4.3-1.5C1422.3,1704.8,1421.8,1703.4,1420.2,1703.5z"
						/>
						<path
							d="M1427.8,1708.3c-9-1.7-18.2-1.9-27.3-1.9c-7,0-18.4-3.1-24.6-0.1c-2.7,1.3-14,7.5-7.3,9.4c1.6,0.5,5.6-1,7.6-1
									c2.8,0,6.5-0.6,9.1-0.1c2.7,0.4,13,5.3,15,3c2.6-2.9-6.6-3.6-6-5.9c0.8-3.2,17.7-1.1,20.4-0.9c2.5,0.2,10.8,2.5,12.7,1.4
									C1428.5,1711.5,1430.7,1708.9,1427.8,1708.3z"
						/>
						<path
							d="M1252.3,1756.1c-7.7-1.1-15.5-3.2-23.4-2.8c-8,0.4-15.8,2.7-23.9,3.1c-7.6,0.3-15,0-22.4-1.7c-6.6-1.5-13.3-3.6-20-0.9
									c-5.4,2.2-9.6,6.7-14.2,10.1c-2.3,1.7-4.7,3.4-7.4,4.4c-1.4,0.5-6.9,0.8-7.6,1.8c-4.3,6.6,10,1.1,11.6,0.5
									c2.7-0.9,14.6-7.6,16.8-6.2c0.7,0.4-9.6,5.5-6.6,7.7c2.9,2.2,10-7.6,13.1-6.2c0.2,0.1,1.1,3,1.6,3.2c1.7,1.1,2.9,1.1,4.8,1.7
									c1.1,0.3,3.1,1.1,4.1,1.1c1.7,0,1.9-0.5,3.4-0.8c0.8-0.2,8.8-0.5,9-0.8c1.8-3.1-4.8-2.8-4-4.5c2.8,0.6,9.4,1.8,5.3-2.2
									c-1.3-1.3-4.4-0.6-5.8-1.2c-1.7-0.8-2.9-2.3-4.2-3.6c7,1.4,14.1,1.5,21.2,1.2c2.9-0.1,6.7-1,9.5-0.1c2.3,0.7,4.2,2.5,6.4,3.5
									c2.5,1.1,14.7,5.5,14.8,1c0-3.4-8.1-4.3-9.8-6.6c7.2-1.3,13.6-0.2,20.7,1c1.2,0.2,5.7,1.7,7,0.7
									C1253.8,1758.8,1254.4,1756.4,1252.3,1756.1z"
						/>
						<path
							d="M1438.5,1739c-8.2-0.5-16.3,1.3-24.5,0.7c-8.4-0.6-16.7-1.9-24.9-3.8c-7-1.6-10.5-4.1-15.7,1.9c-2.3,2.6-3,4.6-6.3,4.9
									c-4.3,0.4-9.4-1.8-13.9-1.6c4.6-3.7-10.1-7.8,1.5-7.1c4.4,0.3,8.5,0.9,13,0.5c1.9-0.2,3.8-0.5,5.6-1.2c1.8-0.8,4.3-3.8,5.8-4.3
									c2.7-0.8,8.5,1.5,11.8,1.9c4.7,0.5,12.5,1.6,16.9-0.7c3-1.5,4-2.9,0.4-4.2c-1.5-0.5-5.4,1-7,1.2c-2.9,0.2-5.7,0.1-8.5-0.2
									c-10-1.1-18.5-6.8-27.9-1.5c-3.4,1.9-7.1,4.6-11,4.1c-5.2-0.7-7.4-4.8-13.1-3c-3.5,1.1-6,4-9.3,3.5c-2.3-0.3-4.9-3.2-7.3-4.1
									c-2.7-1-5.2-1.5-8.1-1.3c-10.3,1-18.9,10.1-28.3,13.9c0.6-5.2-23.1-4.5-26.7-3.4c-2.7,0.9-5.1,2.5-7.8,3.5
									c-5.7,2.1,0.1,1.5-5.5,0.4c-4.5-0.9-8.5-3.9-13.2-4.4c-6-0.6-9.4,2.6-14.7,4.2c-5.1,1.5-8.5-2.1-13.8-1.9
									c-5.2,0.1-10.3,4.3-14.6,7c-2.9,1.8-9,4.4-3.9,6.8c0.6,0.3,2.3-0.4,2.9-0.3c1.7,0.2,4.9,1.6,6.4,1.6c1.6-0.1,2.9-1.6,4.4-1.7
									c3.1-0.3,3,1.2,5.4,1.9c3,0.9,8.1,0.7,10.1-2.4c2.9-4.5-0.7-2.6-3.5-4.2c-1.1-0.6-5-1.2-1.7-2.9c1.7-0.9,12.9,3.8,15.4,4.5
									c3.1,0.9,11.1,5.4,13.8,3c2.3-2.1-1.4-7.4,0.1-8.3c1-0.6,9.5,3.8,11.6,3.9c2.1,0.2,3.5-0.9,5.8-1.1c2.7-0.3,5.2,1.7,7.4,1.4
									c6.4-0.7,0.1-3.5,1.1-5c2.2-3.6,9.8,0.2,12.9,0c-4.8,2.7,5.8,3.7,5.8,4.2c0.1,4.2-13.1,0.7-15.7,1.3c-3.9,0.9-16.2,5.4-18.2,9
									c-1.5,2.8-0.4,2.8,2,3.5c0.2,0,3.4-0.2,4-0.2c1.9,0.1,3.7,0.6,5.7,0.4c4.6-0.4,6.7-2.2,10.3-0.6c0.6,0.3,0.3,1.6,1.4,2
									c1.3,0.5,2.9-0.8,4.2-1.1c2-0.4,7.6,0.7,8.7,0.1c3.1-1.6-1.4-2.7-0.7-4.2c1-2,4.3,0.3,6.2,0.9c1.7,0.6,7.8,3.7,9.2,2.2
									c1.6-1.7,0.1-3.3-1.6-3.9c4.5-0.3,9.1-1.3,13.6-1.1c3.7,0.2,7.6,2.3,11.3,1.5c2.3-0.5,5.5-2.3,7.7-3.4c1.4-0.7,0.8-1.3,2.4-2
									c1.7-0.8,3.8-1.1,5.6-1.6c10.1-3.3,18-3.3,28.2-0.8c9.3,2.3,18,3.8,27.7,3.7c1.9,0,8.6,0.9,9.1-1.6c0.7-3.2-3-2-4.7-2.7
									c-1.9-0.7-3.4-2.4-5.2-3.3c-1.3-0.7-4,0.6-3.9-1.3c0.1-2.3,2.2-0.8,3.6-0.8c9,0,17.9,2.9,27.1,2.4c3.1-0.2,12.9,0.6,15.4-0.8
									C1439.9,1742.2,1441.8,1739.2,1438.5,1739z M1260.4,1756.5c0.8-0.7,1.2-0.8,1.3-0.1C1261.3,1756.5,1260.9,1756.5,1260.4,1756.5z
									M1278.8,1755c-1.7-0.1-2.4-3.1-2-4.4c2.3,0.5,4.4,1.4,6.5,2.4C1282.1,1754.2,1280.6,1754.8,1278.8,1755z M1344.3,1743
									c-9.3,3.2-16.2,4.7-26,3.1c1.7-1.4,3.7-2.7,5.9-3.2c1.4-0.4,5.1,0.4,5.7-0.8c1.9-3.5-4.7-2.9-5.6-4.6c-2.8-5.8,6.6,0,8.6,0.8
									c5,1.8,10,1.5,14.8,3.8C1346.6,1742.2,1345.4,1742.6,1344.3,1743z"
						/>
						<path
							d="M1891.3,1465.3c-5.9-3.2-7.4-11.3-12.6-14.3c-6.1-3.5-8,5.6-13.1,7.9c-3.2,1.4-5.7-0.1-9.5,2.8c-2.9,2.3-5.7,4.7-9,6.4
									c-1.9,1-6.1,1.6-6.4,4.2c-0.5,3.9,5.9,0.5,6.7,0.1c3.2-1.4,4.3-1.7,7.6-1c1,0.2,2,1.1,3,1.2c0.7,0.2,1.7-0.7,2.6-0.6
									c2.5,0.3,4,1.6,6.6,1.3c4.5-0.6,4-2.5,7.9-1.3c1.7,0.6,5.2,4.1,6.7,1.3c1.6-2.9-4.4-3.9-5.6-5.4c-1.7-2.1-1-10.5,1.2-12.4
									c2.8,3.6,5.1,8.1,8.8,10.9c1.1,0.8,3.2,3.1,4.8,2.4C1892.3,1468.3,1892.9,1466.2,1891.3,1465.3z"
						/>
						<path
							d="M1873.3,1427c-4.3-2.3-9.1-4.8-14-5.3c-5.7-0.6-8.8,2.1-13.4,5c-1.6,1-12.4,4.7-12.7,5.9c-1.7,5.7,6.3,1.6,8.5,0.9
									c3.6-1.1,5.6-1.1,9.1-0.8c0.6,0.4,1.1,0.8,1.6,1.3c0.8-0.2,1.5-0.6,2.1-1.2c1.4-0.2,3.7-1,5.2-1.3c3.5-0.7,6.8-1.1,10.5-1.2
									C1871.5,1430.2,1877.4,1429.2,1873.3,1427z"
						/>
						<path
							d="M1880.7,1415.8c-3.2,0.7-8.6-4.6-10.8-6.1c-5.2-3.6-5.2-2.8-9.9,0.7c-3.4,2.5-7.2,4.6-11,6.3c-1.6,0.7-10,2-7.8,5
									c1.9,2.6,6.6,0,9-0.6c5.6-1.4,9.7,0.5,15.2,0.8c4,0.2,9-0.5,12.8-1.4c0.8-0.2,4.4-0.1,4.9-1.8
									C1883.4,1417.2,1882.5,1415.4,1880.7,1415.8z"
						/>
						<path
							d="M1880.8,1403.6c-7.2-1.9-14.3-4.3-21.4-6.5c-8-2.4-11.6,0.1-18,4.8c-4.9,3.6-9.4,7.3-15,9.8c-4.3,1.9-5,3.7,0.6,3.9
									c5.5,0.3,11.3-2.9,16.9-3.3c6.3-0.5,9.9-2.4,15.2-5c5.7-2.7,8.7-0.4,14.7-0.1c0.8,0,6.9,0.5,7.3,0
									C1882.1,1406.3,1883,1404.2,1880.8,1403.6z"
						/>
						<path
							d="M1945.5,1415.5c-3.3-1.7-7.6-5.3-10.9-6.4c-5.1-1.7-9.1,2.6-13.5,4.5c-5.1,2.3-10.6,3.3-16,4.3c-2.1,0.4-10,1.2-5.8,4.8
									c1.3,1.1,10.7-1.2,12.3-1.5c4.9-0.9,12-1.4,16.7-3.1c5.8-2.1,3.6-5.5,9.3-2.2c1.4,0.8,5.2,3.5,6.7,3
									C1945.5,1418.6,1948.2,1416.9,1945.5,1415.5z"
						/>
						<path
							d="M1969.5,1419.8c-5.9-2.1-9.8-10.1-16.9-7.7c-5.9,2-9.5,10.5-17.2,9.4c-4.3-0.6-3.4-3.7-7.9-1c-3.9,2.4-6.8,5.2-11.2,6.9
									c-2.4,0.9-10.2,1.6-11.3,4c-1.7,3.4,2.1,2.6,3.8,2.6c7,0,17.4-2.5,23-6.3c1-0.7,1.1-2.2,2.2-2.7c-0.6,0.3,4.5,0.6,4,0.6
									c1,0,3.9-0.7,5.3-0.7c2.2,0,4.4,0.2,6.6,0.4c3.8,0.4,5.5,1.5,7.2-1.8c0.5-1-0.9-2,0.1-3.4c1.4-1.8,1.5-0.6,3.2-0.5
									c2.6,0.2,5,3.4,7.4,3.7C1969.5,1423.5,1973.5,1421.2,1969.5,1419.8z"
						/>
						<path
							d="M1964,1434c-2.6-2-4.7-5.7-8-6.8c-3.1-1-3.5,0.5-6.3,0.8c-2.4,0.2-3-1-5.3-1c-4.4-0.1-7.1,3.7-10.7,5.8
									c-2.2,1.3-12,3.7-12.5,6.3c-1,5.6,13.1-2.3,14.4-2.7c3-0.9,20.7,2.8,14.8-4.7c4.4-1.7,4.6,1.6,8.3,3.8c0.8,0.5,3.5,2.1,4,2
									C1964.2,1437.4,1966.2,1435.7,1964,1434z"
						/>
						<path
							d="M1952.3,1443c-3.3-1.6-7.9-4.3-11.4-5.1c-4.4-0.9-6.7,1.7-9.9,4.2c-2.5,2-9.7,5.5-11.2,8.4c-1.8,3.6,0.2,3,2.6,3.1
									c1.1,0,2.3-1.3,3.2-1.7c2.4-0.9,5.2-1.1,7.8-1.3c3.3-0.3,6.4,0.3,8.8-2.1c2.5-2.4,0.1-3.7,4-3.7c1.7,0,3.9,2.6,5.7,1.7
									C1953.1,1445.9,1954.1,1443.9,1952.3,1443z"
						/>
						<path
							d="M1977,1400.3c-7-1.7-14-4.3-20.4-7.5c-6-3-8.8-6.8-15.2-3.4c-5.3,2.7-7.5-0.5-13.4-0.7c-7.1-0.3-11.2,5.9-17.5,7.1
									c-1.6,0.3-4.3-0.9-6.5-0.8c-2.4,0.1-4.9,1.2-7,2.1c-1.9,0.7-11.8,4.6-10.8,8.1c1.1,3.7,11,0.3,13.4-1c6.1-3.3,9.5-3.4,15.9-0.2
									c-3.7,2.3-8.2,3.7-12.6,4.2c-2.7,0.3-7.1-1-9.5,0.4c-2.9,1.7-2.1,3.7,0.5,5.1c3.2,1.7,8.8,0,12.1-0.8c8.1-2,16-6.7,24.3-7.1
									c9-0.4,18.5,2.9,27.6,3.6c8.1,0.6,4.1-3.5-0.8-5c-4.8-1.5-20.3-3-12.1-9.7c4-3.2,1.5-2.5,7.1,0.4c3.5,1.9,7.2,3.3,10.9,4.8
									c2.7,1.1,9.9,4.9,13,4.3C1977.7,1403.8,1980.1,1401.1,1977,1400.3z"
						/>
						<path
							d="M1993.5,1381.3c-8.2-2.5-14.4-1.8-21.3-7.2c-3.7-2.8-3-2.4-6.7,0.3c-3.2,2.3-1,2.2-4.3,1.5c-1.4-0.3-2.6-1.9-4.1-2.3
									c-4.4-1-5.7,2.1-9.3,3.3c-2.5,0.8-1.1,0.6-3.7,0.1c-1.4-0.2-2.6-1.4-4.1-1.4c-3.5-0.1-18.1,9-15.4,11.2c1.5,1.3,7.5-0.6,9.5-0.6
									c4.2,0,8.4,0.2,12.6,0.2c3.7,0.1,13.7,3.9,13.1-1.3c-0.2-1.3-6-2.4-1.9-4.9c1.9-1.2,4,1,5.5,1.9c2.5,1.5,18.4,9.6,13.9,0.4
									c2.8,0.9,12.4,3.6,15.2,2.7C1994,1384.7,1996.8,1382.3,1993.5,1381.3z"
						/>
						<path
							d="M2036.8,1348.1c-8.2-2.2-15.1-2-22.5-6.9c-7-4.6-13-6.2-20.5-9.1c-8.7-3.3-5.9,2.8-12,5.8c-3.3,1.7-6.7,1.4-10.1,3.7
									c-2.5,1.7-5.2,5.7-8,6.8c-3,1.1-5.1-1.1-8.6-1.3c-4.2-0.2-7,2.1-10.4,4.4c-3.2,2.1-6.2,4.4-9.9,5.5c-2.3,0.7-6.6-0.1-7.1,2.8
									c-0.7,4.4,15.3-1.3,17.9-1.1c2.9,0.2,11.3-0.1,12.4,1.7c-3.3,1.4-7.2,3.9-10.8,4.4c-3.3,0.5-3.1,0.1-6.4-1.1c-2-0.7-3.3-1-5.4-0.4
									c-1.9,0.6-12.8,7.8-12.7,8.8c0,1.9,2,2,3.4,2c1.9,0.1,3.3-1.3,5.2-1.4c2-0.1,3.6,0.8,5.5,0.9c1.9,0.1,3.4-0.5,5.1-0.9
									c0.7-0.2,1.3-0.6,1.7-1.2c0.8-1.2,1.7-1,2.8,0.3c0.6,0,7.8,1.1,4.1-2.5c5.9-0.9,11.2,5,16.9,0.6c4.9-3.8,1.2-5.4,8.2-2
									c2.9,1.4,15,8.3,17.1,3.4c1.1-2.4-11.7-5.5-13.9-6.5c-3.1-1.5-6.8-2.7-9.6-4.6c-0.1-0.1-4.8-4.3-4.8-4.4
									c-0.5-3.2,13.4,1.9,15.5,2.8c3.2,1.4,13.8,9.1,17.4,6.1c3.8-3.2-9.8-6.5-11.6-7.5c1.4-0.5,2.3-2.2,3.4-3.1
									c1.1-0.8,2.4-0.6,3.4-1.5c1.6-1.5,1.2-4.9,4.7-4.6c2.2,0.2,6,4.5,8.1,5.6c2.6,1.3,11,5.5,13.9,3.9c4.6-2.6-3.3-6,5.3-6.6
									c3.2-0.2,6.4,0.9,9.4,0.8c1.1-0.1,3,1,3.6-0.6C2038.4,1349.9,2038.3,1348.5,2036.8,1348.1z M1983.8,1353.8
									c-0.2,0.3-3.1,1.2-2.7-0.1c0.4-1.3,3.4-1.8,4.5-1.7C1985,1352.6,1984.4,1353.2,1983.8,1353.8z M1988.5,1336.6
									c-0.3,0.6-0.6,1.1-0.9,1.7c0.4-0.9,0.8-1.8,1-2.7C1988.6,1336,1988.5,1336.3,1988.5,1336.6z M1998,1343.8
									c-2.6-0.7-5.4-0.1-7.6,1.5c-0.4-3.4,1.8-3.9,4.5-4.8c0.4-0.2,4.9-0.9,4.6-1C2003.2,1340.8,2002,1344.4,1998,1343.8z"
						/>
						<path
							d="M1010.8,694.6c3.4,0.8,6.9,1.1,10.3,1.6c2.7,0.4,9.4,2.9,11.9,1.7c7.2-3.5-7.5-4.9-9.5-5.3c-3.9-0.7-16.6-1.2-17.4-5.4
									c-1.3-6.8,14.9,0.9,18,1.6c1.7,0.4,6.5,2.1,7.3-0.3c1-2.8-3.3-2.9-4.7-3.2c-7.4-1.7-13.5-4.8-20.5-7.4c-3.2-1.2-4.4-1.4-7.2,0.2
									c-2.4,1.3-7,4.9-9,6.8c-5.4,5.5,4.5,3,8.5,4.5c-2.2,1.7-13.4,8.6-11.9,11.9c0.8,1.7,11,0.7,12.8,0.7c-2.6,2.3-14.4,6.1-12.1,9.3
									c2.1,2.8,14.7-0.8,17.9-0.7c-2.7,2.7-5.9,5.1-9.2,6.8c-1.9,0.9-8.6,1.8-8.4,4.7c0.2,3.1,5.4,1.8,7.2,1.5c4.9-0.7,7.2-0.7,12,0.9
									c-2.9-0.2-17.4,8-14,10.9c1.5,1.3,3.3-0.9,4.9-1.2c3.7-0.5,7.3,2,10.5,3.2c0.9,0.3,3.4,1.7,4.3,1.3c1.9-0.9,0.4-1.8,1.2-3.1
									c0.6-1,0.3-2.8,1.8-3.5c2.1-0.9,6.2,2.9,8.7,1.8c2.6-1.2,2.1-2.6,0-4c-1.3-0.8-6.4-1.1-8.1-1.7c-2-0.7-3.9-1.8-5.8-2.7
									c2.6,0.7,11.3,2.2,8.9-2.6c-1.9-3.9-6.6-4.5-6.8-10.2c3.2,1.7,15,10.5,18.2,7.6c3.4-3.1-12.9-8.8-15-10.1c7.3-0.8,1-2.7,0.7-6.1
									c3.2,0.9,13.3,4,16.4,1c3.8-3.7-1.1-3.2-3.4-3.3c-5.5-0.3-10.4-1.6-15.7-3C1010.7,698.1,1003.5,696.2,1010.8,694.6z"
						/>
						<path
							d="M1539.6,1576.5c2.4,2.2,10.9,13.2,13.4,11.4c3.1-2.2-7.7-11.7-9.4-13.3c-1.8-1.6-5.3-6.7-7.5-7.3c-3.7-1-5,2.6-7.2,5.2
									c-2.1,2.5-4.6,5.8-7.2,5.5c-3.2-0.2-6.4-4.8-7.8-7.2c-1.3-2.2-1.6-4.9-4.7-4.5c-2.3,0.3-4.9,5.3-6.3,6.9
									c-5,5.9-12.1,8.7-17.3,13.9c-7.1,7.1,7.6,0.6,9.9,0.8c3.2,0.3,5.1,2.7,8.1,3c2.1,0.2,6.3-0.7,8.3-1.2c-3.4,3.7,4.4,2.1,5.5,1.8
									c3.9-1,8-0.9,11.9-2.1c-3.6,4.9-4.6,6.1,2.2,5.5c3-0.3,12.3-0.5,12.6-5.1c1.6,1,6,5,7.9,4.7c3.5-0.7-1.7-4.3-2.3-4.8
									c-2.2-1.9-9.9-8.2-12.9-7.5C1536.8,1580,1537.6,1577.7,1539.6,1576.5z M1512.9,1588.8c0.7-1.7-2.9-4.3-3.2-6.3
									c-0.4-2.6,0.8-4.9,2.3-7c2,3,5,4.4,8,6.2C1517.7,1584.2,1515.4,1586.6,1512.9,1588.8z"
						/>
						<path
							d="M402.2,457.8c-4.7-6.1-10.7-12.9-16.5-17.9c-3.1-2.7-4.2-3.4-7.4-1c-1.1,0.9-1.6,2.8-2.8,3.8c-2.3,1.8-1.6,1.3-3.7,1.2
									c-1.4-0.1-1.4-2.1-3.5-1.3c-1.1,0.4-1.1,2.5-1.5,3.4c-1.6,3.5-2.4,6.7-5,9.8c-2.7,3.2-6.1,5.4-9.3,8c-1.5,1.2-7.4,4.6-7.6,6.3
									c-0.6,5.2,8.8-0.4,11.3-0.7c3.4-0.4,6.8,0.2,10.1-0.8c3.2-1,4.1-1.9,7.6-1.4c2.6,0.4,2.3,1.6,5.7-0.4c2.3-1.3,5.1-3.3,6.9-5.3
									c4.8-5.2,0.3-7.6,2.1-12.6c3,3,6,7.2,9.3,9.8c0.9,0.7,1.6,2.4,3.1,1.8C402,460.1,403.4,459.3,402.2,457.8z M382.8,448.6
									c-3.8,2.7-3.8,2.5-7.1-0.8c2.4-2,4.7-3.8,6.3-6.5C383.8,445.4,385,443.6,382.8,448.6z"
						/>
						<g>
							<path
								d="M2031.3,458.2c3.7-1.3,6.2-4.2,8.4-7.3c-0.8,0.1-1.6,0.2-2.4,0.3c1.7,1.6,1.8,4.2,3.7,5.8c1.5,1.2,3.6-0.9,2.1-2.1
										c-1.9-1.5-2-4.2-3.7-5.8c-0.8-0.7-1.8-0.5-2.4,0.3c-1.7,2.4-3.8,4.9-6.6,5.9C2028.7,455.9,2029.5,458.8,2031.3,458.2
										L2031.3,458.2z"
							/>
						</g>
						<g>
							<path
								d="M2042.7,456.6c2.8-1.4,5.4-3.2,7.5-5.5c-0.7,0-1.4,0-2.1,0c1.9,1.6,3.1,3.7,5.3,5.1c2,1.3,4,1.8,6.4,2
										c1.9,0.2,1.9-2.8,0-3c-2.5-0.3-4.6-1.1-6.4-2.8c-1.1-1-1.9-2.4-3.1-3.4c-0.6-0.5-1.5-0.6-2.1,0c-1.9,2.1-4.3,3.7-6.9,5
										C2039.5,454.8,2041,457.4,2042.7,456.6L2042.7,456.6z"
							/>
						</g>
					</g>
					<path
						d="M633.3,974.7c-0.5-2-2.5-3-4.6-3.2c0.3-0.2,0.6-0.6,0.7-1.1c0.2-1.9,0.5-4.2-0.5-5.9c-0.8-1.5-2.6-2.1-4.1-2.6
								c-1-0.3-1.9-0.4-2.8-0.5c0-0.2,0-0.4-0.1-0.6c0.8-2.9,1.2-6.3-1.6-8c-0.6-0.4-1.2-0.6-1.8-0.7c0.1-1.6-0.6-3.1-2.2-4
								c-0.2-0.1-0.5-0.2-0.7-0.2c0.3-0.2,0.6-0.5,0.9-0.8c1.8-2.1,1.5-6.3-0.5-8.1c0.1-0.4,0-0.9-0.2-1.2c0.3-0.4,0.6-0.9,0.7-1.4
								c0.7-2.2-0.4-4.3-2.3-5.2c-0.1-0.2-0.2-0.4-0.3-0.6c0.1-0.3,0.2-0.6,0.1-1c0-0.2-0.1-0.4-0.2-0.6c0-0.2-0.1-0.4-0.1-0.5
								c-0.7-2.3-2-4.7-4.2-5.8c1.5-2.9,0.3-6.1-1.9-8.5c-0.5-0.5-1-0.6-1.5-0.4c-1.8-0.3-3.8,1.4-5,2.9c-0.7-2-2.1-3.7-4.3-4.3
								c-2.7-0.8-4.4,0.9-5.1,3.2c-0.8-0.9-1.8-1.5-3.3-1.8c-3.4-0.7-7-0.2-8,3.6c-1.2,4.3,2.8,8.7,6.9,9.5c0,0,0,0.1-0.1,0.1
								c-1.1,2.5,0.4,5,2.4,6.4c0,0,0,0,0,0c-1.9,1.3-2.9,4.1-1.1,5.9c1.4,1.4,4.4,3,6.6,2.5c0.4,1.8,1.5,3.3,3.5,4.1
								c-0.2,0.4-0.3,0.8-0.4,1.2c-0.5,1.9,0.2,3.6,1.6,4.6c-0.6,0.5-1.1,1.1-1.4,1.8c-0.1,0.3-0.2,0.5-0.3,0.8c-0.2-0.1-0.4-0.1-0.7-0.1
								c-0.2-0.2-0.5-0.3-0.9-0.3c-1.9,0-3.9,0.7-5.4,2c-2.8-0.8-6.4-0.7-8.4,1.4c-1.8,1.9-2.3,5.2-0.6,7.3c-3.1,2.1-3,6.3-0.7,8.9
								c2.1,2.4,6.3,4.2,8.9,1.7c0.1-0.1,0.2-0.3,0.4-0.4c0.2,0.1,0.3,0.3,0.5,0.4c-0.7,0.7-1.2,1.5-1.6,2.5c-0.8,1.9-0.8,3.8-0.3,5.5
								c-2.1-0.2-4.3,0.2-5.9,1.4c-1.5-0.7-3.5-1.5-5.2-1.2c-1.3,0.3-2.4,1.7-3,2.8c-0.2,0.3-0.4,0.7-0.6,1.1c0,0,0-0.1-0.1-0.1
								c-1.3-1.5-3.3-1.6-5.1-1.9c-0.5-0.1-0.9,0.1-1.2,0.3c-2.3,0.3-4.1,1.4-5.1,4.1c-0.1,0.3-0.2,0.7-0.3,1c-0.6-0.9-1.5-1.6-2.6-2.1
								c0-0.6-0.3-1.3-1-1.6c-3-1.1-5.1,1.4-5.7,4.4c-1.2-1.2-2.9-1.9-4.7-2.5c-0.2-0.7-0.8-1.3-1.8-1c0,0-0.1,0-0.1,0
								c0.3-3.3-2.5-5.3-5.5-6.1c-0.6-0.2-1.1,0-1.4,0.4c-2,0.1-4.1,0.8-5,2.5c-0.7-0.2-1.5-0.3-2.3-0.2c-0.8-0.2-1.5,0-2.2,0.3
								c0.2-1.1,0.3-2.2,0.3-3.1c0-1.9,0.1-4.1-1.2-5.6c-1.4-1.7-3.7-1.9-5.7-1.9c-0.6,0-1,0.2-1.2,0.6c-2.7,1.3-4.6,5.6-4.5,8.2
								c0,0.1,0,0.2,0,0.3c-1.4-0.2-2.8,0.3-4,1.1c-0.3-0.7-0.7-1.4-1.1-2c3-0.1,6-1.5,6.3-4.8c0.3-3.6-2.1-6.7-5.2-8
								c0.6-1.1,1-2.5,1.4-3.9c0.7-2.3,0.3-4.6-0.9-6.4c2.1-0.2,3.9-1.4,4.8-3.8c1.2-3-0.3-7.2-3.8-7.5c-2.2-0.2-4.1,0.1-5.9,0.7
								c0-0.2,0-0.3,0-0.5c-0.6-3.7-3.5-7.2-7.3-7.6c-2.9-0.3-5.4,1.4-7,3.8c-0.3,0.1-0.6,0.5-0.7,1c0,0.1,0,0.2,0,0.3
								c-0.2,0.3-0.3,0.7-0.4,1c0,0,0,0-0.1,0c-1.5-0.4-3.2,0-4.8,0.7c0.1-1.5-0.4-3-1.5-4c0.8-0.5,1.4-1.3,1.8-2.2
								c0.1-0.1,0.1-0.3,0.2-0.4c2.8,0.6,5.2-2.8,5.6-5.2c0.5-2.8-0.7-5.9-3.5-7c-2.9-1.2-6.2,0.2-7.5,3c-0.1,0.2-0.1,0.4-0.1,0.5
								c-1.9,0.3-3.9,2-5.2,3.4c-0.8-1.2-2-2.2-3.6-2.8c-0.1,0-0.2-0.1-0.3-0.1c-0.3-0.1-0.6-0.2-1-0.1c-0.9,0.2-1.7,0.6-2.5,1
								c-0.1-1.6-0.8-3.6-1.9-4.8c-2.4-2.4-5.3-0.1-7.3,2c-0.2,0-0.3,0.1-0.5,0.2c-0.4,0.2-0.7,0.5-0.9,0.7c-0.1-0.2-0.2-0.4-0.4-0.6
								c-0.8-1.2-1.7-2.1-3.1-2.1c0.4-0.6,0.8-1.3,1.1-1.9c0.5-1,0.8-2,0.6-3.1c2.1-0.3,3.5-2.7,3.9-4.7c0.6-2.9-0.1-5.7-2.5-7.6
								c0.1-0.5,0.1-1,0-1.5c-0.2-1.4-0.6-2.8-1.3-4.2c2.9,0.3,6-1.5,6.9-4.3c1.2-3.6-1.5-6.5-4.6-7.7c-0.6-0.2-1-0.1-1.4,0.2
								c-1.4,0.1-2.8,0.6-3.9,1.5c0-0.1,0-0.2,0-0.2c-0.5-2.2-2.3-3.8-4.2-4.1c0.6-2.8-0.2-6.1-2.4-7.5c0.2-0.4,0.5-0.7,0.6-1.2
								c0.1,0,0.1,0.1,0.2,0.1c3.9,2,8.2,0.4,9.9-3.7c1.6-3.6-1.3-6.6-4.4-7.9c1.3-0.6,2.5-1.5,3.5-2.6c1.2-1.5,1.6-3.2,0.8-5
								c-0.9-2-2.2-5-3.8-6.4c-1.8-1.7-4.7-0.7-6.8,1c-0.2-1-0.4-2-0.5-3c-0.2-1.5-0.1-3.1-1.6-3.9c-1.7-0.9-3.1,0.6-4.3,1.6
								c-2.4,2-4.3,4-4.2,6.8c-0.8,0-1.6,0.1-2.7,0.5c-3.1,1.1-5.3,3.4-5.9,6.7c-0.1,0.7-0.1,1.3,0,1.9c-2.7,0.9-3.9,4.3-4.4,7.1
								c-0.2,1.4-0.4,3,0.3,4.3c-1.5,1.1-2.6,3.1-3.4,4.6c-1.4,2.5-3,7.7,0.2,9.5c0.6,0.3,1.3,0.5,1.9,0.5c0,0.1-0.1,0.2-0.1,0.3
								c-2,1.4-3.7,3.6-4,5.9c-0.2,2,1.1,3.6,2.8,4.5c-1.1,0.5-2.1,1-2.6,2.2c-0.5,1.3-0.1,2.5,0.6,3.6c0.7,1,1.5,2.4,2.4,3.3
								c1.5,3.1,2.3,6.3,3.1,9.7c0.3,1.3,0.7,2.4,1.3,3.4c0,0.1-0.1,0.3-0.1,0.4c-0.4,2.3,0.6,4.6,2.4,6c0,2.5,0.1,5,0.2,7.6
								c0.1,1.9,3.1,1.9,3,0c-0.1-2.1-0.2-4.3-0.2-6.4c0.1,0,0.2,0,0.3,0c0.4,1.9,2.8,3.8,5,4.3c-0.9,3.1-0.3,6.4-1.2,9.5
								c-0.5,1.9,2.4,2.7,2.9,0.8c0.9-3.2,0.3-6.7,1.3-9.9c0-0.1,0-0.2,0.1-0.3c-0.2,1.5-0.2,3,0.4,4.5c0.4,1.1,1.2,2.1,2.2,3
								c-0.1,0.2-0.1,0.5-0.1,0.8c0.5,3.4-0.2,6.8-0.8,10.1c-0.4,1.9,2.5,2.7,2.9,0.8c0.7-3.3,1.2-6.5,1-9.8c0.9,0.3,1.7,0.4,2.6,0.4
								c-0.1,0.4-0.2,0.8-0.2,1.2c0,2.1,1.7,3.9,3.7,4.8c-0.1,0.2-0.1,0.4-0.1,0.6c0,3.1,0,6.3,0.1,9.4c0,1.9,3,1.9,3,0l0-5
								c0.6,1.4,1.9,2.6,3.7,3.4c0,0.1,0,0.2,0,0.4c0,3.1,1,6.3,0.3,9.4c-0.4,1.9,2.5,2.7,2.9,0.8c0.7-3.3-0.1-6.4-0.2-9.7
								c0.3,0,0.6,0,0.9,0c0.7,1.7,2.5,2.6,4.3,2.5c-0.2,0.3-0.2,0.6-0.2,1c0.5,3.3-0.2,6.7-0.2,10c0,1.9,3,1.9,3,0c0-2.7,0.4-5.4,0.3-8.1
								c0.1,0.1,0.1,0.1,0.2,0.2c-0.1,0.3-0.1,0.7,0.2,1.1c0.6,1,0.4,2.6,0.4,3.7c0,1.2,0,2.5,0,3.7c-0.1,1.9,2.9,1.9,3,0
								c0.1-1.6,0-3.3,0-4.9c0-0.7-0.1-1.3-0.1-2c0.1,0,0.2,0,0.3,0.1c0,0.1,0,0.3-0.1,0.4c-0.1,2.2,1.7,3.8,3.7,4.6
								c0.3,2.6,0.3,5.3,0.2,7.9c-0.1,1.9,2.9,1.9,3,0c0.1-1.6,0.1-3.2,0-4.8c0.6,1,1.5,1.7,2.6,2.4c-0.3,0.9-0.2,1.7-0.1,2.7
								c0.1,1.4,0,2.9-0.2,4.3c-0.3,1.9,2.6,2.7,2.9,0.8c0.2-1.2,0.3-2.4,0.3-3.5c0-0.5,0-1.1,0-1.6c0-0.4-0.2-1.1-0.1-1.5
								c0.2,0,0.4,0,0.6-0.1c0.3,1.4,1.3,2.6,2.5,3.5c-0.1,0.2-0.1,0.4-0.1,0.6c0.1,3.3,0,6.6-0.3,9.9c-0.2,1.9,2.8,1.9,3,0
								c0.3-2.9,0.4-5.9,0.3-8.8c0.9,0.4,1.8-0.2,2.1-0.9c0.1-0.1,0.3-0.2,0.4-0.3c0.4,0.9,1.1,1.8,1.8,2.5c0,0.5,0.2,1.1,0.6,1.3
								c0,0.1,0,0.2,0,0.2c0.5,2.7-0.3,5.6-0.1,8.4c0.1,1.9,3.1,1.9,3,0c-0.2-2.5,0.3-5.1,0.2-7.6c1,1.9,2.8,3.6,4.9,4.3
								c0.1,1.9-0.3,3.8,0,5.8c0.1,0.8,1.1,1.2,1.8,1c0.9-0.2,1.2-1,1-1.8c-0.2-1.5,0.2-3.1,0.1-4.6c0.6-0.1,1.2-0.4,1.7-0.7
								c0.1,0.3,0.2,0.5,0.3,0.7c0,0.5,0.2,1,0.7,1.3c0,0,0.1,0,0.1,0.1c0.2,0.2,0.5,0.3,0.8,0.4c0.4,0.2,0.9,0.3,1.4,0.4
								c0.4,2.5,0.3,5.1,0.4,7.5c0.1,1.9,3.1,1.9,3,0c-0.1-2.4-0.1-4.9-0.4-7.3c1.4-0.2,2.6-0.7,3.6-1.7c0.1,0.8,0.3,1.6,0.7,2.2
								c0.4,0.7,1.1,1.4,1.9,1.9c0,0.1,0,0.2,0,0.4c0.1,1.2,0,2.4-0.2,3.5c-0.1,0.8,0.2,1.6,1,1.8c0.7,0.2,1.7-0.2,1.8-1
								c0.2-1.1,0.3-2.3,0.3-3.5c1.2,0.2,2.4,0.2,3.3-0.3c0.1-0.1,0.3-0.2,0.4-0.3c0.4,1.3,1.3,2,2.5,2.3c0,0.5,0,1.1,0,1.6
								c0.1,0.7,0.2,1.3,0,2c-0.6,1.8,2.3,2.6,2.9,0.8c0.2-0.7,0.3-1.5,0.2-2.3c-0.1-0.7-0.2-1.4-0.1-2.1c0-0.1,0-0.2,0-0.3
								c0.1-0.1,0.3-0.1,0.4-0.2c2.9-1.4,5.1-4.4,5.7-7.6c0.2-1.4,0-2.6-0.6-3.5c0.2,0,0.4-0.1,0.7-0.1c-0.1,0.9-0.1,1.8,0.3,2.8
								c0.5,1.3,1.5,2.3,2.6,3c-0.2,0.2-0.4,0.5-0.4,0.9c-0.2,2.5-0.5,5-0.8,7.5c-0.2,1.9,2.8,1.9,3,0c0.3-2.4,0.6-4.9,0.8-7.4
								c1.1,0.2,2.2,0.3,3.3,0c1.5-0.3,2.5-1.3,3-2.5c0.5,0.7,1.2,1.2,2,1.5c0.2,1.7,0.1,3.4-0.2,5.1c-0.3,1.9,2.6,2.7,2.9,0.8
								c0.3-2,0.4-3.9,0.3-5.9c1.8-0.7,3.1-2.7,3.6-4.6c0.1-0.3,0.1-0.6,0.2-0.9c0.9,1.5,2.6,2.6,4.5,2.9c0,2.2,0,4.4-0.1,6.6
								c-0.1,1.9,2.9,1.9,3,0c0.2-2.3,0.2-4.5,0.1-6.8c1.5-0.5,2.8-1.5,3.6-2.8c0.3,0.5,0.6,0.9,1,1.3c0.6,0.5,1.2,0.8,1.8,0.9
								c0,2.2,0.1,4.4,0.4,6.5c0.3,1.9,3.2,1.1,2.9-0.8c-0.3-2.2-0.4-4.3-0.3-6.5c0.8-0.6,1.5-1.4,1.9-2.4c0.2-0.3,0.3-0.7,0.5-1.1
								c0.8,1.1,1.9,1.9,3,2.5c0.6,0.3,1.2,0.5,1.8,0.7c0.2,1.7,0.4,3.5,0.6,5.2c0.2,1.9,3.2,1.9,3,0c-0.2-1.7-0.4-3.3-0.6-5
								c1.8-0.2,3.4-1.3,4.8-3.2c0.2-0.2,0.3-0.5,0.4-0.7c0.1,0,0.1,0.1,0.2,0.1c0.2,2.5,0.3,4.9,0.3,7.4c0,1.9,3,1.9,3,0
								c0-2.6-0.1-5.1-0.3-7.7c0.7-0.5,1-1.4,1.3-2.3c0.7,0.3,1.4,0.6,2.2,0.7c2.8,0.6,5.3-0.7,6-3.2c0.3,0.2,0.6,0.3,0.8,0.4
								c0.3,2.2,0.4,4.5,0.4,6.8c0,1.9,3,1.9,3,0c0-2.4-0.1-4.7-0.4-7c0.1,0,0.1-0.1,0.2-0.1c0.6-0.4,1.2-0.9,1.7-1.5
								c0.6,0.7,1.3,1.1,2.1,1.3c0.1,1.8,0.3,3.5,0.5,5.3c0.1,0.8,1.1,1.2,1.8,1c0.9-0.2,1.2-1,1-1.8c-0.2-1.6-0.4-3.1-0.5-4.7
								c0.7-0.3,1.4-0.7,1.9-1.3c0.6-0.7,1.2-1.5,1.8-2.5c0.4,0.7,0.9,1.2,1.6,1.7c0,0.1,0,0.2,0,0.3c0.4,2.8,0.7,5.6,1,8.4
								c0.1,1.9,3.1,1.9,3,0c-0.2-2.6-0.5-5.2-0.9-7.8c0.6-0.1,1.3-0.2,2-0.5c0.4-0.2,0.6-0.4,0.7-0.7C632.3,980.8,634,977.5,633.3,974.7z
								M584.4,972.1c-1.3-1.1-2.4-3.1-1.2-4.6c0.5-0.6,1.2-1,1.9-1.2c0.7,0.2,1.4,0.3,2.1,0.2c0.5,0.2,0.8,0.6,1.1,1.1
								c0.1,0.3,0.2,0.6,0.3,1c-0.1,0.4-0.2,0.8-0.2,1.3c0,0.7,0.1,1.4,0.4,2.1C588.1,973.9,585.8,973.2,584.4,972.1z M589,962.9
								c-0.3,0.3-0.8,0.4-1.3,0.5c-0.7-0.2-1.4-0.3-2.2-0.2c-0.6-0.2-1.1-0.6-1.3-1.1c-0.6-1.5,0.4-3,1.7-3.5c0.9-0.3,2-0.4,3-0.3
								c0.1,0.1,0.2,0.1,0.3,0.2c-0.4,1.2-0.1,2.5,0.5,3.5C589.5,962.4,589.2,962.7,589,962.9z M491.6,931.8c1.3,1,1.4,3,0.8,4.4
								c-0.3,0.6-1.2,2-2,1.5c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.4-0.2-0.7-0.3-1.1c-0.1-0.3-0.1-0.5-0.2-0.7c-0.3-1.3-0.7-2.5-1.3-3.6
								C489.2,931.1,490.4,930.9,491.6,931.8z M509.6,953.4c0.7-0.4,0.9-1,0.7-1.6c0.2-0.1,0.4-0.2,0.6-0.3c1.1-0.4,4-1,5-0.3
								c0.6,0.3,0.8,1.5,0.9,2c0.1,1.9-1.2,3.4-3.2,3.3C512.8,956.4,508.2,954.1,509.6,953.4z M510.2,959.5c2.1,0.9,3.1,2.8,2.6,5.1
								c-0.4,1.7-1,4.9-3.1,4.7C504.5,968.9,506.5,959.8,510.2,959.5z M500.7,944c2.3-0.9,4.1,0.6,5.1,2.5c0.1,0.2,1.1,2,0.9,2.5
								c-0.3,0.5-2.1,0.9-2.8,1c-2.6,0.5-6.3,0.1-6.2-3.1C498.5,945.6,499.5,944.4,500.7,944z M488.1,952.5c0.8-1.2,2.7-2.5,4.3-2.8
								c0.2,0.3,0.6,0.5,1.1,0.6c3.7,0.5-1.2,14-5.2,7.9C487.2,956.5,487,954.2,488.1,952.5z M482.6,937c0.1-0.2,0.2-0.4,0.2-0.6
								c0.5-0.4,1.1-0.8,1.7-0.9c1-0.2,1.7,0.5,2.2,1.5c0.1,0.5,0.3,0.9,0.4,1.4c0,0.2,0.1,0.4,0.1,0.6c0.1,2.3-2,3-4,2.8
								c-1.3-0.1-2-0.5-1.9-2C481.4,938.8,482.2,937.7,482.6,937z M462.9,934.9c0.1-0.1,0.3-0.2,0.4-0.3c1-1.1,2-2.1,3.1-3
								c1.2-0.9,1.5-0.3,2,0.6c0.7,1.2,0.5,2.4-0.3,3.5c-0.6,1-2.2,2.7-3.4,2.8C463.3,938.6,462.5,936.4,462.9,934.9z M459.9,915
								c0.5,0.8,0.7,1.9,0.6,2.8c-0.1,0.9-0.6,3.9-2.2,2.8c-0.7-0.5-1.5-2.9-1.1-3.6C457.6,916.2,458.9,915.4,459.9,915z M461.4,897.3
								c0.1,0,0.3,0,0.4,0c1.4,0.8,2.5,2.2,1.7,3.9c-0.7,1.5-2.5,2.3-4.1,1.9C455.2,901.9,458,897,461.4,897.3z M457,907.9
								c0.8,1.6,2,6.5-1.9,4.7c-1.3-0.6-2.5-2.2-3.2-3.4c-0.9-1.6-1.4-5.5,1.1-5.5C454.8,904.2,456.2,906.3,457,907.9z M453,895.1
								c0.2,0.2,0.4,0.5,0.5,0.8c0.3,0.9,0.2,2-0.3,2.9c-0.9,1.7-3.1,2.7-5,2.8c-1.3,0-2.9-0.7-2-2.2c0.5-0.8,1.6-1.3,2.4-1.8
								C450.1,896.6,451.5,895.8,453,895.1z M441.2,913.6c0.1-0.4,0.4-0.7,0.7-1.1c0.3-0.1,0.5-0.1,0.8-0.3c0.6-0.3,0.8-0.8,0.8-1.3
								c0.7-0.6,1.4-1.1,2.2-1.5c1.2,1.5,2.8,3.7,2.9,5.6c0.2,2.5-2.7,2.6-4.6,2c-0.8-0.3-2.2-0.9-2.6-1.7
								C441.5,914.7,441.4,914.2,441.2,913.6z M436.5,891.7c0.4,0.1,0.8,0.1,1.2,0.1c0.8,0.1,1.6,0.3,2.2,0.7c0.6,0.4,1.7,1.1,2.1,1.8
								c0.4,0.5,0.5,0.9,0.5,1.3c-0.2,0.1-0.5,0.2-0.7,0.4c-1.5,1.5-4,2.7-6.1,3c-1.3,0.2-2.6,0.1-3.4-0.8c-0.1-0.3-0.3-0.5-0.5-0.8
								c-0.1-0.2-0.1-0.3-0.2-0.5c-0.3-1.4,0-3.7,1.4-4.4C434.1,892,435.3,891.7,436.5,891.7z M436,884.2c0.7-0.8,1.3-1.8,2-2.7
								c0.2,0.5,0.5,0.7,1,0.8c1.2,2.5,1.8,5.9-0.7,6.4c-0.1,0-0.1,0-0.2,0c-0.4,0-0.7,0-1.1,0c-0.2,0-0.4-0.1-0.6-0.1
								C433.1,887.9,434.5,885.7,436,884.2z M435.2,904.2c0.3-0.4,1.8-0.8,2.3-1c0.9-0.4,1.7-0.8,2.6-1.1c1.6-0.7,2.6-0.7,2.9,1.3
								c0.3,2-0.8,4-1.9,5.6c-0.2,0.2-0.4,0.4-0.6,0.5c-1.3-0.6-3.3-2.6-3.8-3C436.1,906,434.7,905.1,435.2,904.2z M457.1,875.4
								c2.2,2.1-0.3,5.5-2.8,5.5c-0.6,0-1.1-0.1-1.6-0.3c-1-0.4-1.4-1-1.2-1.9c-0.2-0.8,0-1.8-0.1-2.6c0-0.2-0.1-0.4-0.1-0.5
								c0-0.2,0-0.4-0.1-0.6C450.2,872.4,456.7,875,457.1,875.4z M450.2,863.3c0.4-0.4,0.5-0.9,0.4-1.4c1.1-1.3,3-2.8,4.4-1.9
								c0.6,0.4,1.2,2.1,1.5,2.7c0.8,1.4,1.9,2.6,0.9,4.4c-1,1.8-4.3,2.7-6.1,2.1C448.6,868.4,448.5,865.2,450.2,863.3z M447.2,893.1
								c-1.5,0-2.8-1.7-3.2-3c-0.5-1.9,0.7-2.6,2.4-2.8c0.6-0.1,1.1-0.4,1.3-0.9C450.2,886.3,450.5,893.2,447.2,893.1z M444.5,883.7
								c-2.1-0.1-2.3-2.4-2.1-4.2c0.1-0.7,0.3-1.3,0.7-1.8c0.9-0.3,1.6-0.9,2.3-1.7c0.3-0.1,0.6-0.2,1-0.2
								C448.6,878.2,449.2,884,444.5,883.7z M443.5,856.9c0.4-0.5,1.7-1.9,2.3-2c0.7-0.1,0.4-0.2,0.5,0.9c0.1,0.8,0.3,1.5,0.4,2.3
								c0.4,1.7,0.9,3.8-0.4,5.3c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.3-0.4-0.8-0.5-1.4-0.4c0,0-0.1,0-0.1,0
								c-1-0.7-1.9-2.1-2.1-2.9C441.9,858.8,442.7,857.9,443.5,856.9z M443.3,867c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.2
								c1,0.1,1.8,0.7,1.7,2c0,0.8-0.5,1.6-0.8,2.3c-0.3,0.7-0.7,1.7-1.2,2.6c-0.8,0.4-1.5,0.8-2,1.4c-0.6-0.1-1.3-0.5-1.8-1
								c-0.2-0.6-0.5-1.2-0.9-1.8c0-0.4,0.1-0.9,0.3-1.3c0.2-0.4,0.5-0.9,1-1.5C441.1,868.6,442.1,867.7,443.3,867z M436.2,863.7
								c0.5-0.3,1.8-1,2.6-1c1.1,0.1,1.7,1,2.2,2c0,0,0,0,0,0c-1.3,0.6-2.6,1.8-3.6,3c-0.7,0.5-1.4,0.9-2.1,0.9
								C431.5,869,434.9,864.4,436.2,863.7z M429.3,875.9c0.3-1.9,1-4.8,3.5-4.2c1.2,0.3,2.3,0.9,3,1.7c0.2,0.7,0.6,1.4,1,2
								c0,0.1,0,0.1,0.1,0.2c-0.7,1.6-2.2,3-3.8,3.7c-0.9,0.4-2,0.5-2.9,0C428.7,878.6,429,877.3,429.3,875.9z M425.8,886.2
								c0.8-1.4,2.6-5.1,4.4-2.5c0.7,1,0.9,2.8,1,4.3c-0.2,0.1-0.3,0.2-0.5,0.3c-1.3,1.2-2.7,2.6-4.5,3.2
								C423.2,892.4,425.1,887.4,425.8,886.2z M424.9,899.8c0.6-1.1,1.5-1.9,2.4-2.5c0.8,0.4,1.6,1,2.1,1.8c0.1,0.3,0.3,0.6,0.5,0.8
								c0,0.1,0.1,0.1,0.1,0.2c0.3,0.8,0.6,1.9,0.1,2.6c-0.5,0.8-1.7,0.9-2.6,0.8c-0.9-0.1-2-0.4-2.6-1.1
								C424,901.4,424.4,900.7,424.9,899.8z M428.6,912.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.9,0.4-1.2,0.2-1.6-0.3c-0.1-0.1-0.1-0.2-0.2-0.3
								c-0.2-0.3-0.4-0.5-0.6-0.6c-0.4-0.4-0.9-1-1.2-1.5c-0.6-1.3,0-1.2,0.9-1.6c1-0.5,2.1-0.9,3.2-1.3c1-0.4,1.8-0.8,2.9,0.2
								c0.5,0.5,0.7,1.4,0.9,2.2c-0.8,0.7-1.7,1.5-2.6,2.2C429.5,911.5,429,911.8,428.6,912.1z M429.2,915.6c0.3-0.6,1.7-1.2,2.3-1.6
								c1.5-0.9,3.1-1.7,4.9-2c0.5,0.4,1.2,0.8,1.5,1.3c0,0,0,0,0,0.1c-0.2,0.8-0.1,1.5,0.2,2.2c-0.5,0.6-1.4,1-2.1,1.1
								c-0.3,0.1-0.6,0.1-0.9,0.1c-0.5,0-0.9-0.1-1.4-0.1c-0.1,0-0.3,0-0.4,0c-0.3,0-0.7,0-1,0.1c-0.6-0.1-1.2-0.1-1.7-0.2
								C429.8,916.6,428.6,916.7,429.2,915.6z M434.2,924.3c-1.2-0.3-3.4-1.5-3.8-2.9c-0.3-1-0.3-0.9,0.8-1.3c0.3-0.1,0.5-0.1,0.8-0.2
								c1,0.1,2.2,0.1,3.3,0c1.2,0.1,2.6,0.5,3.2,1.5c-0.6,0.9-1,1.8-1.2,2.8C436.1,924.6,434.9,924.5,434.2,924.3z M443.1,933.6
								c-0.5,1.2-1.5,3.3-3.1,2.9c-1.1-0.3-2.5-1.2-2.3-2.3c0.5-0.4,0.9-1,1.1-1.6c0.7-0.6,1.5-1,2.2-1.4c0.1-0.1,0.2-0.1,0.3-0.2
								C442.7,931.2,443.9,931.7,443.1,933.6z M442.9,928c-0.7-0.1-1.9-0.2-2.5-0.6c-0.5-0.4-0.6-0.8-0.6-1.2c1.2-0.9,2-2.2,1.9-4
								c0,0,0,0,0-0.1c0.5-0.4,1.1-0.6,1.9-0.6c0.3,0,0.5,0,0.7-0.1c1,0.6,1.7,1.7,2,2.7C447.2,926.9,445.5,928.3,442.9,928z M450.9,945.7
								c-2.5,0.9-4.8-1.1-5.3-3.5c-0.3-1.3,0-4.7,0.8-5.9c2.4-3.8,4.9,1.7,5.6,3.4c0.2,0.5,0.6,0.8,1.1,0.9
								C453.8,942.6,452.9,945,450.9,945.7z M451.5,930.9c-3.3,1-2.5-3.2-1.6-4.8c1-1.7,2.7-2.7,4.3-3.8c1.5,0.9,2.6,2.2,1.7,4
								C455,928,453.4,930.3,451.5,930.9z M456.7,952.9c-0.9-0.1-1.9-0.6-2.5-1.3c-0.7-1-0.3-1.7,0.2-2.7c0.4-0.7,0.9-1.4,1.4-2.1
								c0.6,0.8,1.2,1.7,1.8,2.5C458.6,950.7,459.6,953.1,456.7,952.9z M456.5,939.4c-0.3-0.2-0.6-0.4-0.9-0.4c0-0.1-0.1-0.1-0.1-0.2
								c0-0.2-0.1-0.4-0.2-0.6c-0.6-1.1-2-3.4-1-4.7c0.4-0.5,1.5-0.9,2.1-1.2c1-0.4,1.3-0.6,2,0.4c0.8,1.3,1.2,2.9,1.1,4.4
								C459.3,938.8,458.2,940.9,456.5,939.4z M460.9,943.1c0.5-0.5,1.6-1.4,2.4-1.6c0.2,0.2,0.6,0.4,1,0.3c2.5-0.2,5.3,0.1,4.7,3.4
								c-0.4,2-1.9,3.7-3.9,4.1c-2.1,0.5-4.8-0.5-5.4-2.8C459.3,945.2,459.9,944,460.9,943.1z M465,961.5C465,961.5,465,961.5,465,961.5
								c-1,0.4-2.2-0.2-3-0.6c-2.2-1-2.5-2.8-1.8-5c0.6-1.9,2.7-3,4.8-3.2c0.1,0.4,0.4,0.7,0.9,0.9c1.8,0.5,2.2,1.8,2,3.2
								c-0.7,1-1.4,2-2,3.1C465.5,960.4,465.2,961,465,961.5z M473.1,959.7c-0.2,1.6-1.5,3.6-3,4.2c-1.1,0.5-2.2,0.2-2.3-0.8
								c1-1,1.8-2.2,2.3-3.4c0.2-0.5,0.4-1,0.6-1.6c0.3-0.4,0.5-0.8,0.8-1.2C472.6,957.3,473.3,958.1,473.1,959.7z M471.5,949.5
								c0.7-1.8,2-2.9,1.8-5.1c-0.1-0.6-0.3-1.2-0.5-1.8c-0.3-0.6-0.6-1.1-0.9-1.6c-0.4-3,0.6-4.8,2.9-5.3c0,0,0.1,0,0.1,0.1
								c4.9,1.8,2.5,8,1,11.1c-0.4,0.8-0.6,1.6-0.9,2.4C474.1,951.4,469.7,954.3,471.5,949.5z M475.3,968.5c-1.6-0.8-2.1-1.9-1-3.4
								c0.6-0.8,1.2-1.6,1.7-2.4c1.3,0.8,2.2,2,2.2,3.6C478.1,968.3,477.2,969.4,475.3,968.5z M479,949.2c0.8-1.5,1.7-3.9,3.6-4.1
								c0.1,0,0.1,0,0.2,0c1.8,0.2,4.3,0.3,3.7,2.8c-0.5,1.9-2.9,3.4-4.7,3.9c-0.7,0.2-1.9,0.5-2.6,0.2C477.6,951.3,478.3,950.4,479,949.2
								z M482.1,974.2c-1.1,0.1-3.1-0.6-3-1.9c0.1-1.2,1.3-2.8,2.4-3.4c0.9,0.6,1.6,1.4,2,2.5C484,972.8,483.8,974.1,482.1,974.2z
								M482.3,964.2c-1.3-1.2-3.3-3.4-3.3-5.1c0-2.1,1.3-2.8,2.7-2.5c0.1,0.4,0.3,0.7,0.7,0.9c1.7,0.8,2.8,2.2,3.3,4
								C486.3,963.8,484.8,966.4,482.3,964.2z M487.8,979.3c-0.3,0-0.6,0.1-0.8,0.3c-1.1-0.6-1.9-1.4-1.7-2.7c0.3-1.4,1.7-2.4,2.8-3.1
								C491.4,971.7,491.4,979,487.8,979.3z M489.8,967.7c-0.2-0.2-0.4-0.3-0.6-0.3c0-0.2,0-0.4-0.1-0.6c0.3-0.1,0.7-0.3,1-0.4
								c1.6-1,2.9-2.6,4.6-0.7c1.1,1.2,2.1,3.9,0.5,5.2C493.6,972.1,490.8,968.6,489.8,967.7z M496.9,981.1c-0.2,1.5-0.9,3.6-2.5,4.1
								c-1-0.5-2-1.1-2.5-2c-0.8-1.7,0.8-3.8,2-4.9c1-1,2.5-1,2.8,0.6C497,979.6,497,980.3,496.9,981.1z M497.7,960.4
								c-0.1-2.6,1.4-4.3,3.1-6.1c0.6,0.1,1.2,0.3,1.7,0.5c1.6,0.5,2.2,0.9,2,2.7c-0.2,2.1-1.8,4.6-3.2,6.1
								C499.1,965.9,497.8,962.7,497.7,960.4z M499.1,973.3c0-1.7,1.5-3.6,3-4.4c1.6,0.4,2.9,1.5,3.5,3.1c1,2.5-0.2,3.8-2.3,4.7
								c-0.9,0.4-1.8,0.9-2.7,0.2C499.7,976.2,499,974.2,499.1,973.3z M503.4,985.1c-0.4,1-1.1,2.1-2.1,2.7c-1.2-0.5-2.3-2.3-1.7-3.4
								c0.5-1,1.9-2.5,3-2.7C504.3,981.2,503.8,984.1,503.4,985.1z M511.1,992.4c-0.2,0.5-0.5,0.9-0.8,1.1c-1.5,0.9-4.2-1.1-5.2-2.7
								c0.2-0.2,0.4-0.5,0.4-1c0.1-2.1,1.1-4.1,2.5-5.6c1.9-1.9,3.6,0.8,4,2.9C511.2,988.7,511,990.5,511.1,992.4z M512.1,979.5
								c-1.2-0.1-2.1-0.3-2.6-1.6c-0.4-0.9-0.5-1.9-0.5-2.8c0-3,2.9-2.4,4.9-1.9c0.2,0,0.3,0,0.5,0c1,0.7,1.8,1.8,2,3
								C517,979,514.4,979.7,512.1,979.5z M520.5,993.8c-0.8,2.4-3.3,2.7-5.4,1.8c-0.5-0.9-0.8-2-0.9-3.1c0.6-1.5,0.9-3.2,0.9-4.4
								c0-0.1,0-0.2,0-0.2c0.9-1.4,2.3-2.3,3.6-1.2C520.5,988.3,521.3,991.6,520.5,993.8z M523.2,986.3c-0.6-0.6-1.5-1.6-1.7-2.4
								c-0.3-1.4,0.9-6.7,3.2-6.5c0.3,0,0.6,0,0.8-0.1c0.9,0.1,1.9,0.5,2.3,1.4c0.2,0.6,0.1,2,0.1,2.7c0,1.6,0.1,3.9-1,5.2
								C525.7,988,524.4,987.5,523.2,986.3z M530.4,998.5c-0.5,1.1-1.4,1.4-2.6,1.1c-2.2-0.5-3-1.8-2.8-4c0.1-1.2,0.3-2.4,0.7-3.6
								c0,0,0,0,0,0c0.2,0.1,0.4,0.1,0.6,0.1c1.5,0.5,2.5,1.9,3.3,3.2C530.2,996.4,530.9,997.3,530.4,998.5z M533,993.3
								c-1.4-0.3-2.5-1.4-2.6-2.8c-0.1-1,0.5-2.8,1.6-2.9c0.2,0.1,0.4,0.1,0.6,0.1c0,0,0,0,0,0c0.5,0.2,0.9,0.1,1.2-0.1
								c0.2,0,0.5,0.1,0.7,0.3c0.2,1,0.7,1.8,1.3,2.5C536,992,535.2,993.7,533,993.3z M539.9,996.1c-0.1,0.7-0.4,1.4-0.7,2
								c-0.7,1.3-1.7,2.5-3,3.2c-1.4,0.8-2.1,0-1.8-1.4c0.1-0.6,0.7-1.3,1-1.9c0.9-1.5,1.9-2.8,3-4.1C539.4,994.1,540,994.7,539.9,996.1z
								M544,987.7c-1.1,0.1-1.6,1.3-1.2,2.1c-0.6,0.3-1.4,0.4-2.2,0.1c-0.5-0.2-1.2-0.5-1.8-1c-0.2-0.9-0.6-1.7-1.2-2.4
								c0.4-1.5,2.8-1.6,4-1.4c0.2,0,0.4,0,0.6,0c1,0.4,1.9,1.1,1.8,2.3C544,987.6,544,987.6,544,987.7z M551.9,996.2
								c-0.8,1.6-4.1,0.6-5.1-0.3c-1.4-1.2-1.2-3.3,0.1-4.5C549.8,992.2,553.2,993.6,551.9,996.2z M558,996.1c-2.3-0.2-2-4.2-0.9-5.8
								c0.1,0.1,0.3,0.2,0.4,0.2c1.4,0.4,3,0.9,2.8,2.6C560.2,994.3,559.3,996.3,558,996.1z M572.4,990.6c-0.4,1.5-1.7,2.7-3.2,2.8
								c-1.7,0.1-4.1-1.1-3.2-3.1c0.9-2,2.5-2.2,4.4-1.9c0.2,0,0.3,0,0.5,0C571.9,988.7,572.7,989.2,572.4,990.6z M579.8,990.3
								c-0.2,0.6-0.7,1.9-1.4,2.1c-0.9,0.2-1.3-0.4-1.4-1.2c-0.3-1.3,0.2-2.8,0.9-3.9c0.1-0.1,0.1-0.2,0.2-0.2c0.6,0.4,1.6,0.9,1.9,1.4
								C580.2,989.1,580,989.7,579.8,990.3z M593.6,959.2c0-0.3-0.1-0.6-0.2-0.8c0.1-0.2,0.1-0.3,0.2-0.5c0.7-0.5,1.6-0.9,2.4-1
								c0.2,0.2,0.6,0.3,1,0.3c0.4,0-0.3,2.9-0.4,3.4c-0.6,1.5-1.8,2-3.2,1.1C593.5,960.7,593.6,959.9,593.6,959.2z M594,967.6
								c0.2,0.1,0.5,0.1,0.8,0c1.7-0.4,2.6,1.2,3,2.7c0,0.1-0.1,0.1-0.1,0.2c-0.4,0.9-0.6,1.8-0.5,2.7c-0.2,0.1-0.3,0.2-0.5,0.3
								c-0.8-0.2-1.6-0.3-2.4-0.1c-0.9-0.3-1.8-1-2.3-1.8c0.1-0.8,0-1.6-0.1-2.5C592.2,968.4,593.1,967.9,594,967.6z M592.2,990.3
								c-1.5,1.6-2.8,1.6-4.8,0.8c-1.7-0.7-4.9-3.4-1.6-4.7c1-0.4,2.1-0.6,3.2-0.5c0.1,0.1,0.2,0.1,0.3,0.1c0.5,0.1,1.1,0.4,1.7,0.6
								c0.1,0.1,0.2,0.1,0.3,0.2c0.5,0.6,1,1.2,1.6,1.7c0,0,0,0,0,0C593,989.1,592.8,989.7,592.2,990.3z M596.9,982.5
								c-0.4,1.5-0.1,2.7,0.6,3.6c0,0.1,0,0.1,0,0.2c-0.2,0.8,0,1.5-1,1.4c-0.2,0-0.4-0.1-0.6-0.3c-0.4-1.1-1.4-2.1-2.6-2.8
								c-1.3-1.8-1.9-3.9-0.9-6.1c0.5-0.9,1.1-1.7,2-2c0.2,0,0.4,0.1,0.6,0.1c0.4,0,0.8,0,1.2,0c0,0,0.1,0,0.1,0c1.5,0.7,1.7,2,1.7,3.6
								C597.5,980.9,597.1,981.6,596.9,982.5z M617.3,955.1c0.1-0.1,0.1-0.1,0.1-0.2c0.5,0,1,0.1,1.5,0.5c1.1,1.1,0.6,3.4-0.1,5.1
								c-1.5,1.1-3.1,2.2-4.9,1.4c-2.2-1-1.1-3.6,0.1-5C615.3,956.6,616.5,956,617.3,955.1z M615.5,951.2c0.2,0.4,0.2,0.7,0,1.1
								c-1.1,0.4-2.1,1.1-2.9,1.9c-0.7,0-1.7-0.2-2.2,0c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.3c0.2-0.5,0.5-1,0.8-1.5
								c0.8-1.2,2.1-2.1,3.5-1.3C615,951.1,615.2,951.2,615.5,951.2z M613.9,945.3c-0.4,0.4-1.3,0.8-1.8,1c-1.3,0.6-1.9,0.4-2.5-0.8
								c-0.6-1.2-0.7-3.4,0.2-4.5c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c1,0,2.1-0.4,3.1-0.9c0.1,0,0.1,0,0.2,0c0,0.3,0.2,0.6,0.5,0.8
								C614.9,941.9,615,944.3,613.9,945.3z M611,933.9c0.2-0.1,0.5-0.3,0.7-0.4c0.2,0.2,0.5,0.3,0.8,0.3c1.9,0.1,1.2,2.1,0.3,2.9
								c-0.2,0.2-0.4,0.4-0.7,0.6c-0.9,0.1-1.8,0.3-2.6,0.7c0,0,0,0,0,0c-1.4-0.7,0.4-2.5,1-3.2C610.5,934.5,610.8,934.2,611,933.9z
								M608.4,925.1c-0.6,0.2,0.7,0.9,1,1.3c0.7,0.9,1.2,1.9,1.5,3c-0.1,0.5-0.2,1.1-0.5,1.5c-0.3,0.1-0.6,0.3-0.9,0.5
								c-0.4,0.1-0.9,0-1.3-0.1c-0.2-0.2-0.5-0.4-0.9-0.6c-0.3-0.1-0.6-0.2-0.8-0.1c-0.4-0.3-0.5-0.7-0.3-1.4c0.1-0.7,0.6-1.4,1-2
								C607.3,926.7,608,925.3,608.4,925.1z M601.8,953.9c1.2-1,2.8-1.5,4.3-1.5c0.5,0.5,0.9,1.1,1.1,1.7c-0.1,0.5,0,1,0.1,1.5
								c-0.1,0.6-0.4,0.9-0.8,1.1c-1.1-0.3-2.2-0.4-3.4-0.1C601.7,956.2,600.3,955.2,601.8,953.9z M601.1,947.8c0.2-1.3,1.3-1,2.4-0.9
								c0.5,0.1,0.9-0.1,1.2-0.3c0.2,0.1,0.4,0.2,0.7,0.2c1.2,0,1.2,1,0.6,1.7c-1-0.5-1.9,0.2-2.1,1.1c0,0,0,0,0,0
								c-0.3,0.1-0.6,0.1-0.9,0.1C601.9,949.6,600.9,949.1,601.1,947.8z M605.6,916.9c0.2,0.4,0.6,0.6,0.9,0.7c0.6,1.1,1,2.3,0.4,3.5
								c-0.6,1.3-2.7,2.7-4.1,3.1C598.9,925.3,604.9,915.7,605.6,916.9z M600.6,929.7c0.2-1.2,1.6-0.1,2.2,0.4c0.1,0,0.1,0.1,0.2,0.1
								c0.2,1.5,1.2,2.6,2.8,3.3c-0.3,0.4-1.2,0.9-1.2,0.9c-0.5,0.1-1.1-0.2-1.5-0.4c-0.8-0.4-1.4-1.1-1.8-1.8
								C600.9,931.6,600.5,930.5,600.6,929.7z M594.5,916.8c0.4-1.5,1.5-1.9,2.7-0.9c1.2,1,1.6,2.4,1.6,3.9c0,0.2,0.1,0.4,0.1,0.6
								c-0.4,0.2-0.7,0.6-0.7,1.2c0,0.3-0.1,0.6-0.1,1c-0.7,0.1-1.5,0.2-2.2,0.3c-0.3-0.1-0.6-1.1-0.7-1.3
								C594.5,920.1,594,918.4,594.5,916.8z M583.3,918.1c0.6-2.1,2.9-2,4.6-1.4c2.3,0.7,2.4,2.4,2.4,4.6c0,0.5,0.2,0.8,0.5,1.1
								c0,0-0.1,0.1-0.1,0.1C588.5,926.4,582.4,921.6,583.3,918.1z M594,938.7c-0.8,0-2.8-0.9-3.2-1.5c-0.7-0.9,0.8-1.8,1.6-2
								c1.1-0.3,2.2-0.1,3.3,0.1C595.7,936.4,595.5,938.6,594,938.7z M589.9,928.8c0-1.8,2.4-1.9,3.6-1.9c0.6,0,1.5-0.1,2.1,0.1
								c1.4,0.4,1.2,1.6,1,2.8c-0.1,0.5,0.1,1,0.4,1.3C594.8,932.3,589.9,931.7,589.9,928.8z M599.5,943c-1.8-0.9-1.8-2.5-1.2-4.2
								c0.3-1,0.5-1.9,1.6-2c0.8-0.1,1.7,0.2,2.4,0.4c1.1,0.2,2.6,0.4,3.1,1.6c0.3,0.7,0,1.8-0.2,2.6c-0.3,0.2-0.6,0.5-0.7,0.9
								C603.9,944.2,600.9,943.7,599.5,943z M602.5,985.8c-0.6-0.2-1.3-0.4-1.9-0.8c0.2-1.2,0.4-2.5,0.4-3.8c0,0,0.1-0.1,0.1-0.1
								c0.6-0.7,1-0.8,1.9-0.4c0.2,0.1,0.4,0.1,0.6,0.1c0.8,0.5,1.4,1.5,1.6,2.5C605.9,985.4,604.5,986.4,602.5,985.8z M605.2,974.5
								c-1,0.9-2.6,1.1-3.8,0.5c-0.5-0.2-0.8-0.5-1-0.9c0.5-0.9,0.7-2,0.6-3.2c0.9-1.2,2.1-1,3.6-0.7c0.2,0.2,0.4,0.4,0.8,0.5
								C607,971,606,973.7,605.2,974.5z M602.6,965.4c-0.8-0.2-1.8-0.5-2.4-1.2c-1.3-1.4-0.1-2.6,1.1-3.4c0.6-0.4,1.1-0.7,1.7-0.9
								c0.2,0.2,0.6,0.4,1,0.4c1.7,0.1,5.1,1,4,3.5C607.1,965.7,604.2,965.8,602.6,965.4z M612.7,980.8c-0.4,0.8-1,1.5-1.6,1.9
								c-1.3,0.9-2-0.1-2.8-1.1c-0.5-0.7-1.2-1.4-1-2.3c0.2-1.3,2.4-2.9,3.7-2.6c0.7,0.2,1.3,0.8,1.9,1.4
								C612.7,979,612.6,979.9,612.7,980.8z M611.6,972.6c-1.2-1-2.7-3.3-2.1-4.8c0.5-1.2,2-1.6,3.5-1.5c0,0,0,0,0,0
								c1.7,0.9,2.7,1.9,2.6,3.9C615.5,972.6,613.8,974.4,611.6,972.6z M615.8,981.1c0.3-0.9,0.5-1.9,0.5-2.9c0.1-0.3,0.1-0.5,0.1-0.8
								c0.1-0.1,0.1-0.2,0.2-0.2c0.5-0.5,2-1.7,3-1.9c0.1,0.3,0.1,0.6,0,0.9c0,0.9,0.6,1.4,1.3,1.4C621.1,980.2,616.8,985.1,615.8,981.1z
								M620.3,971.1c-1.4-1.4-1.6-3.9-0.7-5.6c1.1-1.9,3.4-1.2,5.1-0.5c1.8,0.7,1.9,2.6,1.7,4.3c-0.7-0.1-1.3,0.2-1.6,1
								C624.3,972.5,621.7,972.6,620.3,971.1z M627.8,980.7c-0.1,0.1-0.2,0.1-0.3,0.2c-3.2,1-4.3-5.4-0.7-6.3c1.6-0.4,3.7-0.1,3.5,1.9
								C630.2,978.1,629.1,979.8,627.8,980.7z"
					/>
					<path
						d="M986.4,688.6c0.3-3-3.1-4.6-5.5-5.3c-1.1-0.3-2.2-0.3-3.1,0.1c-1.4-2.1-4-2.9-6.6-2.7c-2.9,0.2-5.3,2.4-4.2,5.4
								c0,0,0,0,0,0c-1.4,0.7-2.6,2.2-3.3,3.7c-0.9,1.8-0.8,3.7,0,5.4c-0.6-0.1-1.2-0.2-1.9-0.2c0.2-1.4-0.1-2.8-1.2-4.2
								c-1.8-2.3-4.9-4.9-7.6-2.4c-0.5,0.5-1.1,1.1-1.5,1.9c-0.1-0.3-0.2-0.6-0.3-0.9c-0.6-1.3-2-2.1-3.3-2.7c-0.8-0.4-1.6-0.6-2.3-0.7
								c1.2-1,2.1-2.5,2.2-4.4c0.1-2.3-0.9-5-2.9-6.5c0-0.1,0-0.2,0-0.3c0-1.8,0-3.7-0.2-5.5c0-0.1,0-0.1,0-0.2c2.1-0.8,3.8-3.3,3.9-5.7
								c0.2,0.1,0.4,0.1,0.7,0.1c1.4-0.1,3.1,0,4.2-1.1c0.9-1,1.4-2.8,1.6-4.1c0.6-3.1-2.6-5.8-5.5-5.9c-0.6,0-1.2,0.1-1.7,0.3
								c0-0.6-0.1-1.2-0.3-1.8c-1-3.1-3.9-2.6-6.2-1.4c-0.1-0.3-0.2-0.5-0.3-0.8c-1.3-2.2-5.1-3.7-7.2-1.9c-0.7,0.6-1.3,1.4-1.8,2.3
								c-0.8-0.4-1.7-0.4-2.5-0.4c-2.4,0.1-5.5,1.2-6,3.8c-0.1,0.3-0.1,0.6-0.1,0.9c-2.1,0.6-3.6,3.6-3.9,5.5c-0.5,2.5,0.8,5.4,3.5,5.7
								c2.6,0.3,5.3-2.3,6.6-4.4c0.4-0.6,0.6-1.3,0.6-2.1c1.3-0.2,2.5-0.9,3.1-2.3c0.9,0.6,2.1,1,3.3,1.1c0,0.4,0.1,0.9,0.2,1.3
								c0.4,1.4,1.2,2.4,2.3,3c0,0,0,0,0,0c-0.4,0.9-0.5,2-0.3,3.1c-0.7-0.2-1.5-0.1-2.4,0.4c-2.7,1.3-3.6,5.2-2.4,7.8
								c0.3,0.7,0.7,1.3,1.2,1.8c-1.4-0.4-3.1-0.7-4.4,0c-1.5,0.8-2.2,2.7-3.1,4c-0.9,1.5-1.6,2.8-1.4,4.5c-1.4,0-2.8,0.5-3.8,1.3
								c-2.4,1.8-3.8,5-2.7,7.9c0.8,2.1,2.5,3.4,4.5,4.1c0,1.3,0,2.5-0.1,3.8c-0.1,0.8,0.7,1.5,1.5,1.5c0.9,0,1.4-0.7,1.5-1.5
								c0.1-1.1,0.1-2.1,0.1-3.2c0,0,0,0,0,0c0.4,0,0.7-0.1,1-0.3c0.7,0.1,1.4-0.1,1.7-0.9c0.9,0.8,2.1,1.4,3.3,1.7
								c-0.2,1.6-0.3,3.3-0.5,4.9c-0.2,1.9,2.8,1.9,3,0c0.2-1.6,0.3-3.2,0.5-4.8c0.1,0,0.1,0,0.2,0c0.3,0,0.5,0,0.8-0.2
								c1-0.5,1.8-1.3,2.5-2.1c0.4,0.6,1,1.1,1.8,1.5c0.5,0.3,1.1,0.5,1.6,0.6c-0.1,1.5,0,2.9,0.1,4.4c0.2,1.9,3.2,1.9,3,0
								c-0.1-1.6-0.2-3.1-0.1-4.7c0.9-0.3,1.7-0.9,2.3-1.6c0.2,0.8,1.1,1,1.8,0.8c0.1,0.3,0.3,0.6,0.5,0.8c0.4,0.6,1.1,1.1,1.8,1.6
								c0,0.1,0,0.1,0,0.2c0,2.3-0.2,4.6-0.7,6.9c-0.4,1.9,2.5,2.7,2.9,0.8c0.5-2.2,0.7-4.4,0.8-6.7c0.1,0,0.2,0,0.2,0
								c-0.8,3.2,0.4,7,3.6,8c0,0.9,0.7,1.8,1.7,1.6c-0.8,2.1-0.7,4.6,0.6,6.4c0.6,0.8,1.3,1.4,2.1,1.7c0.1,1.4-0.1,2.8-0.4,4.2
								c-0.4,1.9,2.5,2.7,2.9,0.8c0.3-1.5,0.5-3.1,0.5-4.6c1.3-0.2,2.6-0.9,3.5-2c0,0,0,0,0-0.1c1.2-1.3,2.3-2.8,2.8-4.5
								c0.5,1.1,1.4,1.9,2.5,2.5c-0.3,0.8-0.5,1.6-0.6,2.4c-0.1,0.8,0.2,1.6,1,1.8c0.7,0.2,1.7-0.2,1.8-1c0.1-0.8,0.4-1.6,0.6-2.3
								c2.6,0,4.4-2.7,4.4-5.3c0.5-2.5-0.9-4.8-3.5-5.7c-0.1,0-0.3-0.1-0.4-0.1c0.3-0.3,0.5-0.7,0.7-1.1c0.7,0,1.4-0.5,1.4-1.3
								c1.8,0.4,3.5,0.1,4.2-2.1c0.7-2.2-0.5-4.6-2.2-6.4C984.8,692.1,986.2,690.6,986.4,688.6z M947.5,657.6c0.3-1.6,1.5-2.5,3-1.7
								c0.4,0.2,1.3,0.6,1.5,1.1c0.3,0.6-0.1,1.5-0.3,2.1c-0.1,0.4-0.2,1.1-0.5,1.3c-0.3-0.3-0.8-0.4-1.3-0.3
								C948.5,660.4,947.3,658.8,947.5,657.6z M925.8,660.1c-0.7,0.7-2,2.2-3,1.5c-0.9-0.6-0.5-2.1-0.2-2.9c0.1-0.3,1.1-2.3,1.5-2.3
								c0.1,0,0.3,0,0.4,0c0.3,0.4,0.7,0.7,1.2,0.9c0.2,0.4,0.6,0.8,1.3,0.7C927.7,658,925.9,660,925.8,660.1z M928.7,655.4
								c-0.3-0.2-0.6-0.3-1-0.3c-0.9-0.5-1.6-1.4-1.2-2.1c0.5-0.7,1.9-1.1,2.7-1.1c0.3,0,0.6,0,0.9,0c0.1,0,0.1,0,0.2,0.1
								c0,0.5-0.2,1-0.2,1.4c-0.1,0.5,0.1,0.8,0.3,1.1C930.2,655.3,929.5,655.6,928.7,655.4z M938.4,651.1c-0.3,0.9-1.5,1.7-1.9,2.6
								c-0.1,0.1-0.1,0.2-0.1,0.3c-0.7-0.1-1.3-0.3-1.7-0.7c-1-1-0.1-3.3,1-3.9C936.5,648.9,938.7,650,938.4,651.1z M927.8,688.9
								c0,0.5,0,0.9-0.1,1.4c0,0.2-0.1,0.5-0.1,0.7c-0.1,0.9-0.3,1.9-0.5,2.8c0,0,0,0,0,0c-4.1-0.2-6.8-4.2-2.9-7.2
								c0.7-0.5,1.9-1.1,2.8-0.6C927.9,686.6,927.8,687.9,927.8,688.9z M945.6,663.6c-0.1,0.5-0.5,1.1-0.7,1.4c-0.7,1-1.6,2.1-2.6,0.8
								c-0.5-0.6-1-2.2-0.7-2.9c0.2-0.6,0-0.5,0.7-0.3c0.7,0.2,1.4,0.6,2.1,0.9C944.8,663.7,945.2,663.7,945.6,663.6z M939.7,656.8
								c1.3-0.3,1.3-1.8,0.6-2.5c0.5-0.7,1.2-1.2,1.8-1.7c0.7-0.5,1.8-1.4,2.5-0.1c0.3,0.5-0.2,1.6-0.3,2.2c-0.4,1.7-1.2,3.1-2.2,4.5
								c0,0,0,0,0,0c-1.3-0.1-2.5-0.7-2.6-2.3C939.5,656.9,939.6,656.9,939.7,656.8z M936.6,668.8c0.3-0.8,0.8-1.8,1.9-1.1
								c0.4,0.3,0.7,1.1,0.8,1.5c0.1,0.1,0.1,0.3,0.2,0.4c0,0.1,0,0.1,0.1,0.2c0.3,0.9,1.2,2.7,0.1,3.5c-1.1,0.7-2.3-0.5-2.9-1.3
								C936.2,670.9,936.2,669.9,936.6,668.8z M929.6,680.5c0.5-0.8,1.1-1.6,1.5-2.2c0.6-1,0.9-1.2,2.1-1.1c0.7,0.1,1.7,0.2,2.2,0.7
								c1.3,1.5-3.3,8.6-4.9,3.5C930.3,680.9,930,680.6,929.6,680.5z M936.6,693.9c-0.2,0.1-0.4,0.2-0.6,0.4c-1.1,1.1-4.6-0.7-5.2-2
								c-0.1-0.1-0.1-0.3-0.1-0.4c0.1-0.7,0.2-1.3,0.3-2c0.6-1.1,1.7-2,2.8-2.2c1.8-0.3,3.9,0.5,4.4,2.4
								C938.5,691.7,937.7,693,936.6,693.9z M940.4,684.1c-0.7-0.2-2.5-1.2-1.6-2.2c0.4-0.5,0.4-1,0.3-1.4c0.3-0.5,0.6-1,0.9-1.5
								c0.5-0.9,1.1-2,2.3-1.6c0.8,0.2,1.3,1,1.6,1.7c0.8,1.5,0.9,3.6-0.5,4.8C942.7,684.5,941.5,684.4,940.4,684.1z M946.4,693
								c-0.8,1.3-2.4,1.9-3.8,1c-1.8-1.2-0.7-3.2,0.5-4.4c1.3-1.2,3.3-0.5,4.6,0.5c0.6,0.6,0.8,1.5,0.8,2.4
								C947.8,692.1,946.9,692.2,946.4,693z M972.3,683.7c0.8,0,1.7,0.2,2.4,0.6c-0.1,0.2-0.1,0.5,0,0.8c0.3,1.3-0.3,2.5-1.7,2.1
								c-1-0.3-2.4-0.9-3-1.9C969,683.8,971.3,683.6,972.3,683.7z M966,691.8c0.2-0.5,0.7-1.8,1.4-2.5c0.8,0.9,1.9,1.7,2.4,2.8
								c0.7,1.3,0,3-1.5,3.3C966.7,695.7,965.7,693,966,691.8z M957.8,696.4c-0.1,0-0.3,0.1-0.4,0.2c-1,0.8-3.1-0.2-3.9-1
								c-1.2-1.2,0.1-3.5,1.2-4.6c1.3-1.2,2.7,0.6,3.5,1.6c1,1.3,0.7,2.4-0.1,3.6C957.9,696.2,957.8,696.3,957.8,696.4z M962,705.1
								c-3.7,0.5-4.5-3.7-2.8-5.8c0.1-0.1,0.3-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0.6-0.5,1.4-0.7,2.4-0.6c1.4,0.1,2.5,0.4,2.4,2.2
								c0,0.7-0.4,1.5-0.6,2.1c-0.3,1.1-0.7,2-1.4,2.8C962.5,705.1,962.2,705.1,962,705.1z M967.9,714c-1.5,1.6-4,1.3-4.3-1.2
								c-0.2-1.7,0.5-3.8,2-4.7c1.5-1,4.1-0.9,4.6,1.2C970.7,711.1,969.1,712.8,967.9,714z M973.6,703.8c-1.8,0.1-4.1-1.5-4.4-3.3
								c-0.5-3.6,5.7-1.9,8,0.2C976.6,702.1,975,703.7,973.6,703.8z M979.9,708c-0.2,0.3-0.2,0.7-0.1,1.1c0,0.1,0,0.1,0,0.2
								c-0.1,0.3-0.1,0.6,0,0.9c-0.1,0.3-0.3,0.7-0.5,0.9c-0.5,0.6-0.8,0.7-1.6,0.5c-1.1-0.3-2.1-1.2-2.1-2.3c0-1.4,1-3.1,2.6-2.7
								C978.8,706.8,979.6,707.3,979.9,708z M978.4,696.7c-0.7-0.5-4-3.1-3-4.3c0.7-0.8,4.2,1.7,5,2.2c0.1,0.1,0.2,0.1,0.3,0.1
								c0.7,0.7,1.2,1.5,1.5,2.3C983.4,700,979.3,697.4,978.4,696.7z M981.2,690.4c-0.1-0.7-0.6-1.3-1.4-1.4c-1.2-0.1-2.2-0.5-1.7-1.9
								c0.6-1.4,1.8-1,2.9-0.5c0.8,0.3,2.4,0.9,2.4,1.9C983.4,689.4,982.1,690.2,981.2,690.4z"
					/>
					<path
						d="M1076,643.1c-0.6-0.1-1.1,0-1.4,0.4c0,0,0,0,0,0c-0.2,0-0.4-0.1-0.6-0.1c0.3-0.6,0.5-1.3,0.6-1.9c0.4-3-0.6-6.7-4-7.4
								c-0.4-0.1-0.8,0-1.1,0.2c-1.2-0.2-2.6,0.2-3.4,1.4c-0.2,0.3-0.3,0.6-0.4,0.9c-0.5-0.2-1.1-0.3-1.7-0.1c0.2-0.9,0.2-1.8,0-2.7
								c-0.7-3.1-3.2-4.6-6.1-3.4c-2.4,1-4.2,3.2-3.5,5.7c-0.3,0-0.7,0.1-1,0.2c-0.4-0.8-1.2-0.9-1.9-0.5c-0.8-0.3-1.6-0.5-2.3-0.2
								c-1.4,0.5-2.6,2.1-3.5,3.1c-1.9,2.2-3.2,5.2,0,7c0.5,0.3,1.1,0.6,1.7,0.8c-0.8,0.8-1.4,1.7-1.8,2.8c-1.1,2.8-0.9,6.6,2.2,8
								c0.3,0.1,0.6,0.2,0.9,0.3c0,0.8-0.1,1.6-0.1,2.4c-0.1,1.9,2.9,1.9,3,0c0-1,0.1-2,0.1-3c1.3-0.7,2.4-1.7,3.1-2.7
								c0.2-0.3,0.4-0.6,0.6-0.9c0.4,0.4,0.9,0.7,1.3,0.9c0,1.9,0.1,3.8,0.3,5.7c0.2,1.9,3.2,1.9,3,0c-0.2-1.8-0.3-3.7-0.3-5.5
								c0.6-0.2,1.1-0.5,1.6-0.9c0.2,1.6,1.1,2.9,2.8,3.4v2.9c0,1.9,3,1.9,3,0v-3.1c1.2-0.5,2.3-1.4,2.9-2.6c0.5-0.1,0.9-0.4,1.1-1
								c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,2,0.1,3.9,0.1,5.9c-0.1,1.9,2.9,1.9,3,0c0.1-2,0-4-0.1-6.1c1.4-0.6,2.5-1.7,3.2-3.1
								C1078.6,647.6,1079.3,643.9,1076,643.1z M1046.6,642.4c-0.1-0.6,1.2-1.8,1.5-2.2c0.4-0.4,1.2-1.6,1.7-1.7c0.4-0.1,0.8,0.1,1.2,0.3
								c0,0.2,0.1,0.4,0.1,0.6c0,0.1-0.1,0.3-0.1,0.4c-0.2,1-0.5,2.1-0.3,3.2c-0.2,0.5-0.4,0.8-1,0.9c-0.5,0.1-1.5-0.4-1.9-0.6
								C1047.5,643.1,1046.7,642.9,1046.6,642.4z M1052.8,651.5c-0.3,1-1.8,2.5-2.9,2.8c-2.5,0.7-2.1-2.7-1.6-4c0.6-1.6,1.6-2.7,3.4-2.6
								c0.1,0,0.2,0,0.3,0C1052.7,648.8,1053.2,650.1,1052.8,651.5z M1054.6,642.8c-0.3,0-0.5,0-0.7,0c0.3-1,0.3-2,0.3-3
								c0-0.2,0.1-0.3,0.2-0.5c0.2,0.1,0.4,0.2,0.6,0.2c1.1,0.1,2.2,1.1,2,2.3C1056.7,642.8,1055.5,642.8,1054.6,642.8z M1058.4,651.5
								c-0.7,0-1.2-0.6-1.5-1.1c-0.4-0.7-0.5-1.1-0.1-1.8c0.5-1,1.2-2.1,2.2-2.5c0.8,0.7,1.8,1.7,1.7,2.9
								C1060.6,649.9,1059.4,651.5,1058.4,651.5z M1061.1,635.7c-0.1,0.3-0.2,0.5-0.4,0.7c-0.2,0-0.4,0-0.6,0.1c-1,0.4-2.2,0.2-2.8-0.8
								c-0.8-1.5,0.8-2.7,2.2-2.9C1060.9,632.7,1061.3,634.6,1061.1,635.7z M1061.9,643.4c-0.4-1.2,0.7-2.8,1.6-3.5
								c1.7-1.4,2.4,0.9,2.7,2.2c0.1,0.5,0.4,0.8,0.7,0.9c0,0,0,0,0,0.1c0.2,1.5-0.4,3.6-2.4,2.8C1063.5,645.4,1062.2,644.4,1061.9,643.4z
								M1068.3,651.3c-0.4,0.1-0.8,0.4-1,1c-0.3,0.9-1.3,2-2.4,1.6c-1.2-0.3-0.8-2.1-0.4-2.9c0.5-1.1,1.7-1.9,2.9-2c0.3,0,0.5,0,0.6,0
								c0,0.6,0.2,1.1,0.4,1.6C1068.4,650.9,1068.4,651.1,1068.3,651.3z M1070.6,640.9c-0.6-0.3-1.1-0.7-1.5-1.2c-0.4-0.5-0.7-1.1-0.7-1.7
								c0-0.5,0.6-1.4,0.8-0.5c0.3,1.2,1.5,1.3,2.3,0.8c0.1,0.3,0.2,0.6,0.2,0.9c0.1,0.6,0.1,1.6-0.1,2.3
								C1071.2,641.3,1070.8,641,1070.6,640.9z M1071.8,650.2c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.7-0.1-1.4-0.3-1.9c0,0,0-0.1,0-0.1
								c0.2-0.4,0.5-0.9,0.9-1.2c0.6-0.6,0.9-0.5,1.7-0.4c0.6,0.1,1-0.1,1.4-0.4c0,0,0,0,0,0C1075.9,646.1,1074.2,651.3,1071.8,650.2z"
					/>
					<path
						d="M1553.3,1870.4c-2.1-0.1-4.1-0.4-6.2-0.8c-0.2-0.1-0.4-0.2-0.7-0.2c-0.1,0-0.2,0-0.2,0c-3.2-0.7-6.3-1.7-9.5-2.7
								c-1.1-0.6-2.2-1.2-3.3-1.8c-0.4-3.4-0.7-6.9-1.1-10.3c0-0.1,0-0.2,0-0.3c-0.2-0.8-0.4-1.6-0.7-2.4c0-0.2-0.1-0.4-0.2-0.6
								c-0.9-2.3-2.3-4.5-3.7-6.6c-0.9-1.3-2.7-0.6-2.8,0.6c-0.1,0.2-0.2,0.3-0.3,0.5c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2-0.1,0.3
								c-0.8,1.5-1.4,3-2,4.6c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.2,0.3-0.2,0.5c0,0,0,0.1,0,0.1c-0.2,0.2-0.3,0.4-0.4,0.7
								c-0.6,3.2-0.4,6.7-0.4,10c-0.2,0-0.4,0-0.6,0.1c-5.5,0.9-10,7.4-11.9,12.1c-0.7,1.8,2.2,2.6,2.9,0.8c0.1-0.3,0.3-0.6,0.4-0.9
								c0.2,0,0.5,0,0.7-0.1c0.1,0.2,0.2,0.4,0.3,0.6c1.9,2.8,7.5,6.5,10.9,4.7c0.7-0.4,1.4-1.2,2-1.7c1.8-1.5,3.3-2.2,5.4-3.3
								c2.3-1.2,2-2.7,3.6-4.5c0.2-0.3,0.4-0.6,0.4-0.8c0.9,0.4,1.9,0.9,2.8,1.2c0.2,0.2,0.4,0.4,0.8,0.5c2.1,0.5,4.3,0.9,6.5,1
								c2.5,0.6,5,1,7.6,1.1C1555.3,1873.5,1555.3,1870.5,1553.3,1870.4z M1524.5,1870.5c-0.6,0-1.2,0.1-1.7,0c-0.5-0.1-0.9,0.2-1.2,0.6
								c-0.6,0.6-1.7,2.2-2.4,1.4c-0.5-0.5-0.8-1.3-0.7-2.1c0,0,0,0,0.1,0c0.2-0.2,0.4-0.3,0.6-0.5c1.2-0.6,2.6-0.9,3.9-0.7
								C1524.2,1869.4,1524.6,1869.9,1524.5,1870.5z M1525.3,1862.6c0.3-1.9,0.9-4,1.9-5.1c0.8,1.3,1.1,3.7,1.2,5.6
								C1527.3,1862.9,1526.3,1862.7,1525.3,1862.6z"
					/>
					<path
						d="M1124.7,644.9c0.4-0.7,0.2-1.7-0.5-2.1c-0.9-0.5-1.8-1-2.6-1.5c-0.3-0.2-0.6-0.2-0.9-0.2c-0.1-0.8-0.3-1.6-0.5-2.3
								c0.1,0,0.2,0.1,0.3,0.1c0.8,0.2,1.6-0.2,1.8-1c0.1-0.4,0.3-0.8,0.4-1.2c0.2-0.7-0.3-1.7-1-1.8c-0.8-0.2-1.6,0.2-1.8,1
								c-0.1,0.4-0.3,0.8-0.4,1.2c0,0,0,0.1,0,0.2c0,0,0,0,0-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c0.4-0.3,0.6-0.7,0.6-1.2c0-0.6,0.1-1.1,0.5-1.6
								c0.4-0.7,0.1-1.7-0.5-2.1c-0.7-0.4-1.6-0.1-2.1,0.5c-0.5,0.8-0.8,1.6-0.8,2.5c-0.7-0.3-1.4-0.5-2.1-0.7c0.1-0.2,0.2-0.5,0.2-0.7
								c0,0,0-0.3,0-0.3c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.2-0.3c0.6-0.6,0.6-1.5,0-2.1c-0.6-0.6-1.6-0.6-2.1,0
								c-0.8,0.8-1.2,1.9-1.2,3.1c0,0,0,0,0,0.1c-0.8-0.2-1.7-0.4-2.4-0.7c-0.2-0.1-0.3-0.2-0.5-0.2c0.7-1,1.4-1.9,2.1-2.8
								c0.5-0.6,0.6-1.5,0-2.1c-0.5-0.5-1.6-0.6-2.1,0c-0.9,1.1-1.7,2.2-2.5,3.3c-0.7-0.5-1.4-1.1-2.1-1.6c0.2-0.2,0.4-0.4,0.5-0.7
								c0.2-0.6,0.4-1.1,0.9-1.8c0.4-0.7,0.1-1.7-0.5-2.1c-0.7-0.4-1.6-0.1-2.1,0.5c-0.2,0.3-0.3,0.5-0.5,0.8c0.1-0.9,0.2-1.7,0.3-2.5
								c0.2-0.8-0.2-1.6-1-1.8c-0.7-0.2-1.7,0.2-1.8,1c-0.2,1.2-0.4,2.3-0.5,3.5c0,0.3,0.1,0.5,0.2,0.7c-0.5-0.1-1-0.1-1.5-0.1
								c0.1-0.2,0.1-0.5,0-0.7c-0.2-1.1-0.3-2.2-0.5-3.3c-0.1-0.8-1.1-1.2-1.8-1c-0.9,0.2-1.2,1-1,1.8c0.1,1,0.3,1.9,0.4,2.9
								c-0.4-0.5-0.9-1-1.3-1.5c-0.5-0.6-1.6-0.5-2.1,0c-0.6,0.6-0.5,1.5,0,2.1c0.6,0.7,1.2,1.4,1.8,2.2c-0.4,0.4-0.7,0.9-1.1,1.3
								c-0.4-0.5-0.7-1-0.8-1.6c-0.6-1.8-3.4-1.1-2.9,0.8c0.4,1.2,1,2.3,1.9,3.2c-0.3,0.4-0.6,0.8-0.9,1.2c-1.2-1-2.4-1.9-3.8-2.7
								c-1.6-1-3.1,1.6-1.5,2.6c1.1,0.7,2.1,1.4,3.1,2.2c-0.3,0.3-0.4,0.7-0.4,1.1c-0.3,0.5-0.5,1.1-0.7,1.6c0,0-0.1-0.1-0.1-0.1
								c-1.5-0.9-3.2-1.6-4.9-2c-1.9-0.5-2.7,2.4-0.8,2.9c1.5,0.4,2.8,0.9,4.1,1.7c0.4,0.2,0.8,0.3,1.2,0.1c0,1.5,0.3,3,0.9,4.3
								c-0.3,0-0.6,0-0.9,0.2c-1.4,1-2.8,1.9-4.1,2.9c-0.7,0.5-1,1.3-0.5,2.1c0.4,0.7,1.4,1,2.1,0.5c1.4-1,2.8-1.9,4.1-2.9
								c0.3-0.2,0.5-0.5,0.7-0.8c0.3,0.4,0.7,0.8,1.1,1.1c-0.5,0.1-0.9,0.3-1.2,0.7c-0.8,1.1-1.6,2.2-2.4,3.3c-0.5,0.7-0.1,1.7,0.5,2.1
								c0.8,0.4,1.6,0.1,2.1-0.5c0.8-1.1,1.6-2.2,2.4-3.3c0.3-0.3,0.3-0.8,0.1-1.2c0.4,0.2,0.8,0.4,1.3,0.6c0.1,1.4,0.3,2.8,0.4,4.2
								c0.1,0.8,0.6,1.5,1.5,1.5c0.7,0,1.6-0.7,1.5-1.5c-0.1-1.2-0.3-2.4-0.4-3.6c1,0,1.9-0.1,2.8-0.3c0,1.3,0.1,2.6,0.2,3.8
								c0.1,0.8,0.6,1.5,1.5,1.5c0.7,0,1.6-0.7,1.5-1.5c-0.2-1.4-0.3-2.9-0.2-4.3c0.5-0.1,0.9-0.1,1.4-0.2c0.3,1.3,0.4,2.6,0.4,4
								c-0.1,1.9,2.9,1.9,3,0c0-1.4-0.1-2.8-0.4-4.2c0.8-0.1,1.7-0.1,2.5-0.1c0.2,1.3,0.4,2.6,0.4,3.9c0.1,1.9,3.1,1.9,3,0
								c-0.1-1.4-0.2-2.7-0.4-4.1c0.7,0,1.5-0.1,2.2-0.2c-0.5,0.4-0.8,1-0.6,1.6c0.5,1.3,0.7,2.5,0.6,3.8c-0.1,1.9,2.9,1.9,3,0
								c0.1-1.6-0.1-3.2-0.7-4.6c-0.2-0.5-0.5-0.8-0.9-1c0.6-0.1,1.2-0.3,1.8-0.5c-0.2,0.4-0.1,0.8,0.1,1.2c0.7,1.1,1.2,2.3,1.5,3.5
								c0.5,1.9,3.4,1.1,2.9-0.8c-0.4-1.5-1-2.9-1.8-4.2c-0.2-0.4-0.6-0.6-0.9-0.7c0.5-0.4,0.9-0.8,1.3-1.4c1.1,0.8,1.8,2.1,2.5,3.2
								c0.9,1.7,3.5,0.2,2.6-1.5c-0.8-1.5-1.8-2.8-3.1-3.8c0.4,0.2,0.9,0.5,1.3,0.7C1123.3,645.8,1124.3,645.6,1124.7,644.9z
								M1096.4,632.2c0.3-0.3,0.4-0.7,0.4-1c2.1-0.8,4.2-0.1,5.9,1.2c0.1,0.1,0.2,0.1,0.2,0.2c-0.3,0.1-0.6,0.2-0.9,0.2
								c-1.2,0.2-2.5,0.4-3.7,0.5c-0.2,0-0.5,0.1-0.9,0.1c-0.5,0-1,0-1.5,0C1095.8,633.2,1095.9,632.9,1096.4,632.2z M1094.6,648.4
								c-5.1-0.7-7.4-6.1-4.3-10.3c0,0,0,0,0-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.2,0.2,0.4,0.3,0.6,0.4c-0.7,2.7,0,5.4,3,7.3
								c0.3,0.2,0.7,0.4,1.1,0.6c-0.7,0.8-0.3,2,0.7,2.4C1095.5,648.4,1095.1,648.4,1094.6,648.4z M1103.7,643.1c-1.6,1.7-5.7,1-7.6,0
								c-3-1.7-2.3-4.3-0.8-6.6c1,0.2,2.2,0.1,3.5-0.1c1.1,0.1,2.2,0.4,2.8,0.5c0.8,0.2,1.5,0.7,2,1.3c0.1,0.4,0.2,0.8,0.4,1.3
								c0.2,0.5,0.4,1.2,0.6,1.9C1104.5,642,1104.2,642.6,1103.7,643.1z M1107.7,636.2c0,0.1-0.1,0.2-0.1,0.3c-0.1-0.4-0.3-0.8-0.4-1.1
								c0.2,0.1,0.4,0.2,0.6,0.3C1107.7,635.8,1107.7,636,1107.7,636.2z M1117.4,643.7c-0.9,1.6-3.5,2.6-5.6,3c-0.1-0.1-0.2-0.1-0.3-0.1
								c-0.9-0.3-1.7-0.7-2.5-1c0.3-0.3,0.5-0.6,0.5-1c0.2-0.2,0.5-0.5,0.7-0.8c0.6-0.9,0.9-2,1-3.1c0-0.5-0.5-2.6-0.5-3.7
								c0.1-0.2,0.2-0.3,0.2-0.5c1.1,0.2,2.1,0.4,3,0.8C1115.9,638.2,1118.9,641.2,1117.4,643.7z"
					/>
					<path
						d="M1508.5,635.7c0.1-3.7-1.8-7.7-5.6-8.6c-0.2-0.1-0.4-0.1-0.6,0c0-0.4-0.1-0.9-0.2-1.3c-0.7-3.4-3-5.9-5.9-7.2
								c-0.2-0.6-0.6-1-1.4-1.1c-0.8-0.1-1.7,0-2.5,0.1c-0.3,0-0.6,0-0.8,0.2c-1.4,0.3-2.7,1-3.6,2c-0.8-0.1-1.7-0.1-2.5,0
								c0.4-1.3,0.2-2.7-0.8-4.2c0-0.1-0.1-0.1-0.1-0.2c0.2-0.7,0-1.6-1-1.7c-2.8-0.5-6.4-0.1-8.2,2.3c0.2-1.9-0.5-3.9-2.1-5.2
								c-0.6-0.5-1.3-0.4-1.8-0.1c-3-1-5.1,0.8-6.2,3.7c-0.3,0.9-0.6,2-0.7,3.2c-0.2-0.1-0.4-0.1-0.7-0.2c0.2-0.3,0.4-0.6,0.6-0.9
								c0.9-1.9,0.6-4,0.2-6c-0.1-0.7-0.6-1-1.1-1.1c0.1-0.4,0-0.8-0.5-1.2c-2.6-2.4-6.3-2-8.7,0.6c-0.4,0.4-0.7,0.9-1,1.4
								c-0.3-0.2-0.7-0.3-1.1-0.2c-1.6-0.3-3.3-0.5-4.9,0c-1.7,0.6-2.7,2-3.7,3.4c-0.6,0.9-1.5,2.2-2.2,3.6c-0.5-0.5-1.1-0.9-1.7-1.2
								c0-0.4-0.1-0.8-0.3-1.2c0.4-1.6-0.7-3.7-2.1-4.5c-2.1-1.1-4.6,0.3-6.5,1.5c-0.6-1.9-2.4-3.3-4.3-3.8c-0.4-0.1-0.7-0.1-1,0.1
								c-0.2,0-0.4,0-0.7,0c-2.4,0.6-5.1,2.4-5.1,5.1c-2.7-0.2-5.5,2.8-6.2,5.2c-0.2,0.7-0.3,1.4-0.2,2c-0.7,0.1-1.3,0.3-1.9,0.6
								c-2.5,1.4-3.4,4.8-1.5,7c0.5,0.6,1.2,1.2,1.9,1.6c-0.1,1.3-0.1,2.5-0.2,3.8c-0.1,1.9,2.9,1.9,3,0c0.1-1,0.1-1.9,0.2-2.9
								c0.6,0,1.3,0,1.9-0.2c0.5,1.7,1.9,3,3.6,3.5c0,1,0,1.9-0.2,2.9c-0.1,0.8,0.2,1.6,1,1.8c0.7,0.2,1.7-0.2,1.8-1
								c0.2-1.3,0.3-2.6,0.3-3.9c1-0.4,2.1-1.1,2.9-2c0.5,0.9,1.2,1.6,2.2,2.1c0,1.4-0.1,2.8-0.1,4.2c0,1.9,3,1.9,3,0c0-1.3,0.1-2.6,0.1-4
								c1.3-0.4,2.5-1.2,3.5-2.2c0.9,1.8,2.5,3.3,4.4,3.6c0.4,0.1,0.8,0.1,1.2,0.2c0,1.1,0,2.3-0.1,3.4c-0.1,0.8,0.8,1.5,1.5,1.5
								c0.9,0,1.4-0.7,1.5-1.5c0.1-1.2,0.2-2.4,0.1-3.6c1.3-0.3,2.6-0.8,3.5-1.6c0.5,1.5,2,2.5,3.8,2.9c0,1.3,0,2.6,0.1,3.9
								c0.1,1.9,3.1,1.9,3,0c-0.1-1.3-0.1-2.5-0.1-3.8c1.3-0.3,2.3-1,3.1-2c0.4,1.3,1.2,2.4,2.7,2.9c0.3,0.1,0.7,0.2,1,0.3
								c-0.1,1.2-0.1,2.4-0.1,3.6c0.1,1.9,3.1,1.9,3,0c-0.1-1.2,0-2.4,0.1-3.6c1.7-0.3,3.2-1.2,4-2.7c0.6-1.2,1-2.6,1-3.9
								c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.1-0.1,0.2c-1.6,3-0.2,6.3,2.9,7.5c0.2,0.1,0.4,0.1,0.6,0.2
								c0.2,1.7,0.4,3.3,0.7,5c0.4,1.9,3.3,1.1,2.9-0.8c-0.2-1.2-0.4-2.4-0.5-3.6c1.3,0.1,2.6-0.1,3.7-0.7c0.8,0.8,1.8,1.4,2.8,1.8v3.7
								c0,1.9,3,1.9,3,0v-3.2c1.9,0,3.9-0.6,5.4-1.8c0.1,0.1,0.1,0.1,0.2,0.2c0.7,0.8,1.4,1.3,2.3,1.5c0,1.4,0.1,2.9,0.2,4.3
								c0.1,0.8,0.6,1.5,1.5,1.5c0.7,0,1.6-0.7,1.5-1.5c-0.2-1.3-0.2-2.5-0.2-3.8c0.9,0.1,1.7,0.1,2.5-0.3c0.5-0.2,0.8-0.6,1.1-1
								c0.1,0.1,0.3,0.2,0.5,0.3c0,0.2,0.1,0.4,0.2,0.7c0.4,0.7,0.3,1.7,0.3,2.5c0.1,0.9,0.2,1.8,0.3,2.8c0.2,1.9,3.2,1.9,3,0
								c-0.1-1.2-0.3-2.5-0.4-3.7c-0.1-0.5-0.1-1-0.2-1.4C1506.3,641.4,1508.5,638.5,1508.5,635.7z M1432.9,614.3c0.3-0.3,0.4-0.6,0.5-0.9
								c0.3-0.2,0.7-0.4,1-0.5c0.3-0.1,0.9-0.4,1.3-0.3c0.3,0,0.5,0.2,0.6,0.5c0,0.2,0,0.3,0.1,0.4c-0.2,0.1-0.5,0.2-0.7,0.2
								c-0.5,0.2-1,0.3-1.5,0.4C1433.8,614.2,1433.3,614.2,1432.9,614.3C1432.9,614.3,1432.9,614.3,1432.9,614.3z M1425.7,610.6
								c0.1,0,0.1,0,0.1-0.1c1,0.3,2.1,0.8,2.3,1.9c0.2,1.4-0.9,1.7-2,1.7c-0.9,0-3.3,0-3.3-1.3C1422.8,611.6,1424.8,610.8,1425.7,610.6z
								M1415.7,627.1c-1.2,0.3-3.1-0.5-3.6-1.7c-0.4-1.2,0.8-2.7,2.1-2.4c1,0.3,1.5,1.6,2.3,2.2c0.2,0.1,0.3,0.2,0.5,0.2
								C1417.1,626.1,1416.8,626.8,1415.7,627.1z M1416.5,619.9c-0.3-1.3,0.7-2.4,1.6-3.2c0.5-0.4,1.1-1,1.7-0.9c1.1,0.1,1.2,1,0.8,1.8
								c-0.1,0.3-0.1,0.6-0.1,0.8c-0.3,0.7-0.6,1.4-1.1,2C1418.5,621.5,1416.8,621.6,1416.5,619.9z M1424.5,628.4
								c-0.5,1.1-2.2,2.4-3.5,2.1c-2.8-0.7-1.2-3.9,0.4-5.6c0.1,0.1,0.3,0.2,0.5,0.2C1423.6,625.3,1425.5,626.5,1424.5,628.4z M1424.7,620
								c-1-1.5,0.8-1.8,1.9-2.1c1.2-0.3,2.6-0.4,3.5,0.5c-0.1,0.2-0.3,0.5-0.3,0.8c-0.1,1.1-0.7,2.6-1.9,2.6
								C1426.7,621.8,1425.3,620.9,1424.7,620z M1434,626.8c0,0.3-0.1,0.6-0.1,0.9c-0.3,0.5-0.6,0.9-0.9,1.2c-1,0.9-2.7,2.7-4,1.4
								c-2.3-2.5,0.9-5.6,3.6-4.9c0.4,0.1,0.7,0.1,1,0c0.3,0.1,0.5,0.3,0.6,0.5C1434.2,626.1,1434.1,626.4,1434,626.8z M1435.3,622.3
								c-1.6-0.8-2.1-2.5-0.9-3.8c0.5-0.5,1-0.8,1.7-1.1c0.2,0.2,0.5,0.3,0.9,0.4c0.8,0.1,1.6,0.4,2.2,1c0.7,0.7,0.6,1.3,0.2,2.2
								C1438.7,622.7,1437,623.1,1435.3,622.3z M1442.6,632.1c-0.5-0.2-1.1-0.2-1.6,0.1c-0.3,0-0.5,0-0.8-0.1c-1.5-0.2-3-1.9-3.3-3.7
								c0.2-0.6,0.4-1.3,0.4-2c0.3-0.4,0.8-0.8,1.3-1c0.2-0.1,0.5-0.1,0.7-0.2c0.9-0.3,2-0.3,2.9-0.3c1.7,1.1,3.7,2.6,3.5,4.8
								C1445.6,631.2,1444.1,631.9,1442.6,632.1z M1444,620.5c-0.6,0.1-0.7-0.3-0.3-1c0.1-0.6,0.4-1.2,0.7-1.7c0.5-0.9,1.1-1.9,1.7-2.7
								c0.6-0.9,1.3-2.1,2.4-2.5c0.8-0.3,1.7-0.1,2.6,0c-0.2,1.8-1.8,3.6-2.8,4.8C1447.5,618.4,1445.3,621,1444,620.5z M1448,625.2
								c-1.8-0.1-0.6-2.4,0.2-3.2c1-1.1,2.5-1.6,4-2c0.6,1-0.2,2.5-0.6,3.5C1450.8,624.9,1449.8,625.3,1448,625.2z M1455.3,631.4
								c-0.7,1.5-2.8,2.9-4.5,1.8c-2.4-1.6,0.8-5.8,3.1-7c0.2,0.1,0.4,0.1,0.7,0.1c0.2,0,0.4,0,0.6,0c0.1,0.1,0.2,0.2,0.2,0.2
								c0.1,0.1,0.3,0.2,0.4,0.3C1456.5,628.1,1455.7,630.8,1455.3,631.4z M1456.7,618.5c-1,0.4-1.1,0.7-1.6-0.8
								c-0.9-2.8,1.4-11.4,5.6-7.6c0.3,0.2,0.5,0.3,0.8,0.4c0,0.2,0,0.4,0,0.6c0.5,2.9,0.1,4.4-2.4,6
								C1458.5,617.6,1457.6,618.2,1456.7,618.5z M1457.5,623.6c-0.3-1.7,1.7-3,3.2-3.4c1.1-0.3,2.2-0.2,3.1,0.1c-0.1,0.4,0,0.9,0.5,1.2
								c1.7,1.4-1.3,2.6-2.3,2.9c-0.7,0.2-1.4,0.3-2.2,0.4C1458.6,624.8,1457.8,624.9,1457.5,623.6z M1465,633.6c-0.7,1.1-3.4,1.7-4.6,0.8
								c-1.5-1.2-0.2-4,0.9-5c0.8-0.8,1.9-1.2,2.9-1.3c0.1,0.1,0.1,0.1,0.2,0.1C1466.3,629.1,1466,632,1465,633.6z M1478,617.2
								c1-0.8,2.4-1,3.7-0.9c0,0.2,0.1,0.4,0.2,0.6c0.5,0.8,1,1.3,0.5,2.2c-0.2,0.5-0.8,0.9-1.2,1.2c-1,0.9-3.1,2-4.1,0.4
								C1476.6,619.6,1477.1,617.9,1478,617.2z M1467.4,617.1c0.2-1.2,0.8-4.7,2.8-3.9c0.5,0.2,0.9,0.2,1.3,0c1.7,1.9,0.2,4.6-1.8,5.9
								c-0.7,0.5-1.3,0.9-2,0.3C1467.1,618.8,1467.3,617.9,1467.4,617.1z M1469.8,627.9c-2.3,0.2-2.6-1.8-1.5-3.3c1-1.2,2.6-2.3,4.2-2.3
								c0.3,0,0.5-0.1,0.7-0.2c0.9,0.8,1.2,2,0.8,3.1C1473.5,626.8,1471.3,627.8,1469.8,627.9z M1478,636c-0.2,0.2-0.6,0.4-1,0.4
								c-0.5,0.1-1.1,0.1-1.7,0c-0.5-0.7-1.4-0.9-2.1-0.5c-0.2-0.1-0.3-0.1-0.5-0.2c-1.5-0.9-1.5-2.8-0.2-3.9c1.3-1.2,3.1-0.9,4.6-0.4
								c0.2,0.2,0.4,0.3,0.7,0.4c0.5,0.1,0.7,0.3,0.9,0.6c-0.5,0.9-0.8,2-0.7,3.1C1478,635.6,1478,635.8,1478,636z M1479.6,627.9
								c-1.2-0.7-0.5-1.7,0.1-2.4c1.3-1.5,3.1-2.5,5-2.8c0.1,0.1,0.1,0.2,0.2,0.3c1.7,2-0.6,4.9-2.7,5.1
								C1481.7,628.1,1480,628.2,1479.6,627.9z M1489.4,636c-0.4,1-1.6,1.7-2.8,2c-1.4,0.3-3,0.1-4.2-0.7c-0.4-0.3-0.8-0.6-1-0.9
								c0.4-1,0.6-2.1,0.5-3.2c0.5-0.5,1.2-0.9,1.9-0.6c0.7,0.2,1.3-0.1,1.6-0.6c0.3,0.1,0.5,0.1,0.8,0.2c0.1,0.2,0.3,0.3,0.4,0.4
								c0.7,0.6,1.6,1,2.5,1.2c0.1,0.1,0.2,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.2,0.6C1489.5,635.2,1489.4,635.6,1489.4,636z M1490.4,630.8
								c-0.7-0.5-1.5-0.9-2.3-1.2c-0.3-0.8-0.1-1.7,0.3-2.5c0.6,0.6,1.2,1.2,1.8,1.7c0.4,0.3,0.8,0.7,1.2,1
								C1491.2,630.4,1490.8,630.7,1490.4,630.8z M1497,638.5c-0.3,0.2-1.5-0.2-1.9-0.2c-0.6-0.1-1.4-0.1-1.9-0.5
								c-0.5-0.4-0.7-0.8-0.8-1.2c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.3,0.1-0.6,0.1-0.9c0.1-0.2,0.3-0.5,0.6-0.7c0.7-0.6,1.2-0.8,2-0.2
								c0.6,0.5,1.2,1.1,1.7,1.7c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.2,0.4C1497.5,637.4,1497.5,638.2,1497,638.5z M1497.2,629.6
								c-0.8-0.1-1.6-0.6-2.3-1c-0.1,0-0.2-0.1-0.3-0.2c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.3-0.2-0.5-0.4-0.7c0.1-0.4,0-0.8-0.4-1.2
								c-1-1.2-2.4-2.1-3.9-2.3c-0.2-1.3,0.5-2.6,1.8-3.1c0.1,0,0.2,0,0.3-0.1c0.1,0,0.2,0.1,0.4,0.1c2.5,0.2,4.8,1.5,6.1,3.7
								c0.5,0.9,1.3,2.7,1,3.8C1498.6,628.3,1497.8,628.8,1497.2,629.6z M1502.7,638c-0.9-0.4-1.8-1.5-2.5-2.5c-0.2-1-0.5-1.9-0.8-2.6
								c0-0.1-0.1-0.2-0.2-0.3c0-0.1,0-0.2,0-0.3c0.9-0.3,1.7-1,2.2-1.8c0.5,0.1,1,0.2,1.5,0.4c0.4,0.1,0.7,0.1,0.9,0
								c0.7,0.7,1.2,1.8,1.4,2.9C1505.7,635.5,1505.4,639.3,1502.7,638z"
					/>
					<path
						d="M2040.3,568.2c-1.4-2-4-2.8-6.6-3c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.3,0-0.6,0-0.9c-0.4-3.1-3.7-5.4-6.8-4.4
								c-0.2,0.1-0.3,0.1-0.4,0.2c-2,0.4-3.9,1.5-5,3.2c-0.3-0.3-0.6-0.5-0.9-0.7c-0.6-1-1.5-1.6-2.8-1.7c-0.5,0-0.8,0.1-1,0.3
								c-1.8-0.1-3.6,0.3-5.2,1.3c-2.9,1.9-4.3,6.5-1,8.8c2.8,1.9,8.1,0.9,10.1-1.8c0.3-0.5,0.5-1,0.6-1.6c1,1.3,2.9,2.1,4.6,2.4
								c-0.4,0.6-0.6,1.2-0.4,2c0.7,4.2,7.2,2.8,9.9,2.1C2037.7,573.4,2042.8,571.8,2040.3,568.2z M2018,566.4c0,1.1-0.2,1.4-1.3,1.9
								c-1.3,0.6-4.6,1.5-5.3-0.4c-0.7-1.9,1.6-3.4,3.1-3.8c1.2-0.3,2.4-0.2,3.4,0.3C2018,565.2,2018,566.2,2018,566.4z M2027.7,567.1
								c-0.4,0.1-0.7,0.1-1,0.1c-0.7,0-1.9-0.4-2.5-0.8c-0.8-0.7-0.7-1-0.1-1.8c1-1.3,2.3-1.7,3.9-1.7c0.3,0,0.5-0.1,0.7-0.2
								c1,0.3,1.7,1.1,1.7,2.2c0,0.2,0,0.3-0.1,0.5c-0.6,0.1-1.1,0.1-1.6,0.2C2027.9,565.7,2027.6,566.4,2027.7,567.1z M2037.3,570.2
								c-0.3,0.4-2.5,0.8-3.1,1c-1.1,0.3-2.3,0.6-3.5,0.7c-1,0.1-2.4,0.6-1.9-1c0.1-0.4,1.4-1.3,1.8-1.6c0.7-0.5,1.3-0.9,2.1-1.1
								c1,0,2.1,0.2,3.1,0.5C2036.5,568.9,2038.1,569.2,2037.3,570.2z"
					/>
					<path
						d="M1657.6,1235.9c-1-1.5-2.5-2.5-4.2-3.2c2.8-3,3.2-6.8,2-10.6c-0.4-1.2-0.8-1-0.8-2.2c0-1,0.8-2,0.7-3.2
								c-0.1-1.9-1.2-3.5-2.7-4.7c3.2-2.7,3.4-6.3-0.5-8.5c5.4-0.4,6.9-7,2.6-10c2.9-2.1,2-7.7-0.8-9.4c4.8-2.4,5.2-6.8,0.7-10.1
								c-0.1-0.1-2.4-1.1-2.4-1.3c-0.3-0.6,0.5-2.6,0.3-3.6c-0.6-2.6-2.8-4.4-5.2-5.4c2.7-2.2,3.3-6.2,1.4-9c-2.1-3.1-3.9-3-7-2.2
								c0.2-1.3-0.2-2.7-1.1-3.7c5-2.7,5.5-12.6-0.1-15.1c-1.6-0.7-2.3,0.3-3.5,0.2c-1-0.1-2.6-1.7-4-2.3c-1.2-0.5-2.7-1-4-0.4
								c-0.8,0.4-1,1-1.6,1.6c-1,1-0.4,0.7-1.8,1.6c1.3-8.2-9.2-9.2-12.5-2.9c-0.5-1.7-2-5.1-3.7-6c-2.1-1.1-5.1,0.7-6.6,2.1
								c-1.1-2.8-4.4-4.2-7.1-3c-1.2,0.5-2,1.5-2.7,2.5c-0.4,0.6-0.7,1.3-1,2c-0.9-0.4-1.9-0.5-2.9-0.4c1-2.3-0.7-6.3-2.8-7.3
								c-3-1.5-8.3,0.4-10.6,2.6c0.1-2.6-1.6-4.8-4.3-5.1c-1.2-0.1-3.3,1.2-4.1,0.9c-0.9-0.3-1-1.6-1.6-2.3c-1.1-1.5-4.2-3.7-6.1-3.7
								c-1.3,0-1.7,0.5-3,0.2c-1-0.3-2-1.2-3.1-1.5c-1.3-0.3-1.8-0.1-2.6-0.8c-0.5-0.4-1.2-1.8-1.8-2.4c-2.5-2.3-4.4-3.1-7.7-2.2
								c-0.7,0.2-1.5,0.9-2.3,0.7c-0.5-0.2-1.8-2.1-2.4-2.4c-2.6-1.7-4.8-1-6.5,1.6c0-3.9-1.4-6.5-5.1-8c-3.6-1.5-4.9,0.2-6,3.6
								c-2.8-1.5-6.9,0.4-8.1,3.2c-1.5,3.5,1.4,5.2,3.6,7.1c1.1,0,1.5,0.5,1.2,1.6c0.1,0.9,0.2,1.7,0.3,2.6c0.5,1,0.9,1.9,2.2,1.3
								c1.6-0.7,0.2-2.9,0.4-4.1c0.3-2.2,1.2-1.8,2.3-3.8c1.8,1.5,4.9,3,7.2,1.7c-0.2,1.9,0.1,3.7,1.3,5.2c-6.8-0.4-10.1,11.3-2.2,12.5
								c-0.7,1.2-0.6,7,1,7.8c2.8,1.3,1.9-3.8,1.8-4.7c0.7,0.9,1.8,1.6,2.9,2c0.4,0.1,2.2,0.1,2.4,0.3c1.2,1.3-0.6,5.4,1.5,6.5
								c4.3,2.2,0.5-6.9,1.6-7.5c0.4,2.8,3.4,4,5.8,4.5c-4.9,2.7-3.7,10.6,1.4,12.4c-3,3.3-2.6,8.3,1.7,10.1c-2.3,1.1-2.8,2.7-4.3,4.5
								c-2.5,2.9,2.1-0.4-1.5,1c-0.6,0.2-3.1,0.8-3.6,0.7c-0.3-0.1-3-2.6-3-2.5c-0.3-0.6,0-2-0.4-2.9c-0.5-1-1.4-1.8-2.3-2.4
								c-3.4-2.1-9-1.9-11.3,1.7c-2.6,4,0.3,9.5,5,9.8c-1.8,3.6-0.3,8.7,4.3,8.8c-0.7,2-0.1,4.2,1.6,5.5c0.4,0.3,1.9,0.5,2.1,0.9
								c0.3,0.3,0.3,3.1,0.6,3.8c-2.4,0.3-5,1.4-6.6,3.2c-2.6-3.9-6.6-3.8-10.2-1.6c-1.5,0.9-3,2.6-3.3,4.4c-0.4,2.2,1.1,3.2-1.6,3.6
								c-1.8,0.3-3.7-0.3-5.7,0.4c-1.9,0.7-3.6,1.9-4.7,3.7c-2.4,3.9-0.8,7.3,3.6,8.4c-2.8,3.9,0.5,8.6,4.6,9.5c-2.7,0.8-4.2,3-3.8,5.7
								c0.4,2.6,0.3,1.7-1.4,3.7c-1.2,1.4-2,3.2-1.8,5.1c-4.5-2.1-8.2,6.3-6.6,9.5c-4,1.3-13.2,7.5-9,12.6c-4.4,2-2.4,7.8,0.4,10.3
								c-7.4,0.5-5,7.6-4.5,12.3c0.2,1.4-0.5,5.4,1.4,5.7c2.9,0.4,1.8-4.2,1.6-5.7c0.9,0.5,1.9,0.8,2.9,1c-0.2,1-0.2,2-0.1,3
								c0.2,1.2,0.7,2.2,1.6,3.1c0.5,0.5,1.1,1,1.8,1.3c1.1,0.1,1.5,0.7,1.2,1.8c0.4,1,0,2.9,1.1,3.6c3.1,1.7,1.6-3.1,1.9-3.9
								c0.4-1.4,1.4-0.8,2.8-1.8c1.3-0.9,2-2.2,2.4-3.7c0.5,0.3,2,0.5,2.3,0.8c0.4,0.4,0.2,1.9,0.4,2.5c0.3,1.1,0.5,2.6,2.1,2.2
								c1.4-0.4,1-1.5,0.9-2.6c-0.1-1.3-0.3-1.7,0.1-2.7c0.3-0.7,1.4-1.3,1.7-2.2c1,0.8,2.4,1.2,3.7,1.1c-0.1,1.1-1.3,8.2,2,5.5
								c2-1.6,0.1-5.9,2-7.9c1-1,1.1-1.6,2.7-0.3c1.2,0,1.5,0.6,0.9,1.8c-0.1,1-0.1,1.9-0.1,2.9c0.9,1.8,2.1,1.9,3,0
								c0.7-1.5-0.4-3,0.7-4.2c0.7-0.8,2.3-0.9,3.2-2c0.4-0.6,0.8-1.3,1-2c0.8,0.4,1.6,0.6,2.5,0.7c0.6,0.8-0.4,3.1,0,4c0.9,2,2.2,1.6,3,0
								c0.8-1.6-0.2-3.8,0.7-5.5c0.9-1.6,2.2-2.5,2.7-4.5c0.5,0.5,2.7,1.1,3.1,1.5c0.5,0.6,0,3.1,0,3.8c0,1.2-0.6,4.1,1.6,3.7
								c1.9-0.4,1.4-3.1,1.4-4.4c0-0.8,0-1.5,0-2.3c-0.2-0.7,0.1-1.1,0.9-1.1c0.6-0.7,1.6-1,2.1-1.9c0.8,0.8,1.9,1.3,2.9,1.6
								c2.2,0.7,1.6-0.3,2.5,1.8c0.6,1.4-0.4,5.3,2,5.3c2.2,0,1.3-3.1,1.1-4.4c-0.1-0.4-0.1-0.8-0.2-1.3c-0.6-1.1-0.3-1.6,0.9-1.6
								c0.8-0.7,1.9-0.9,2.7-1.7c0.9,1.3,2.1,1.3,2.9,2.4c0.7,0.8,0.8,1.2,0.9,2.6c0.1,1.4-0.3,5.1,1.8,5.4c2,0.4,1.5-1.8,1.4-3
								c-0.1-0.8-0.8-4.2-0.4-5c0.4-0.7,2.5-1.4,3.1-2.1c0.6,3.3,2.8,2.7,4.7,4.5c1.3,1.3,0.4,5.3,2.6,5.8c2.6,0.6,1.2-3,1.4-4.2
								c-0.7-1.1-0.5-1.7,0.7-1.6c0.3-0.1,0.6-0.3,0.9-0.4c0.9-1,1.8-1.6,2.3-3c0.7,0.9,1.6,1.6,2.6,2.1c2,1,1.7-0.1,2.5,2.2
								c0.5,1.3,0,4.6,2.2,4.1c2.2-0.5,0.7-3.5,0.7-4.8c0-2,0.2-1.6,1.6-3.2c0.8-1,1.5-2.2,1.9-3.4c1.2,2,3.4,3.6,5.7,4.1
								c-1.1,0.9-1.5,9.9,0.2,10.2c3,0.5,3.1-8.6,1.9-10c2-0.1,3.6-1.5,4.6-3.1c0.6,1.3,1.6,1.7,2.5,2.7c1.2,1.2,1.4,0.5,1.5,2.7
								c0,1.5-0.6,5.2,2,4.8c2.2-0.3,0.7-4.1,1-5.5c0.4-1.9,0.5-1.2,1.9-2.3c1.2-0.9,2.1-2.2,2.4-3.6c0.6,0.5,1.2,0.9,1.8,1.2
								c1.1,0.1,1.4,0.8,0.9,1.9c0.2,1.2-0.3,3.5,0.4,4.6c1.1,1.8,2.5,1,2.9-0.8c0.3-1.1-0.6-2.6-0.3-3.8c0.5-2,1.4-1.3,3.1-2.6
								c2.1-1.7,3.2-4.8,2.8-7.4c0.4,0.1,0.1,4.6,0.1,5.3c0.2,2.1,2,3.7,3,0.9c0.6-1.6-0.9-4.5,0-6c0.4-0.7,3-1.3,3.9-2
								c1.6-1.2,2.6-2.9,2.8-4.9c2.4,0.8,2.2,0,2.8,2c0.3,1.1-0.4,2.7,0.1,3.8c0.9,1.9,2.3,1.8,3,0c0.4-1.1-0.4-2.8-0.1-4
								c0.4-1.8,1.3-1.7,2.3-3.3c1.5-2.2,1.6-5.4,1.3-8c1.8,1.1,1.7,1.3,1.8,3.6c0.1,1.2-0.4,4.3,0.4,5.3c2.2,2.9,2.8-1.8,2.8-3.1
								c-0.1-1.2-1-4.5-0.5-5.5c0.5-0.9,3.3-2.2,4.2-3.3C1658.5,1242.1,1659.6,1238.8,1657.6,1235.9z M1518.4,1109.4c-1.5,2.5-6-0.4-4-3
								C1516.7,1103.5,1520.3,1106.4,1518.4,1109.4z M1527.3,1109.4c-3,2.9-7.6-5.3-4.6-7.3c1.2-0.8,3.1,0.4,3.9,1.5
								C1527.8,1105.5,1526.7,1107.5,1527.3,1109.4z M1653.2,1226.3c-0.3,2.4-3.3,7.7-5.3,3.4c-0.8-1.7,0.6-4.1,1.8-5.3
								C1651.9,1222.4,1653.6,1223,1653.2,1226.3z M1654.3,1198.1c0.4,2-2.7,3.5-3.4,1.2C1650.2,1196.7,1653.9,1195.7,1654.3,1198.1z
								M1647.1,1188.8c-0.5-4.4,6.1-3.5,6.3,0.1C1653.6,1192.8,1647.4,1191.5,1647.1,1188.8z M1652.2,1176.2c1.5-0.2,3.1,2.7,2.5,3.9
								c-0.5,1.1-3,2.2-4.1,2.4c-1.9,0.3-3.4-0.6-3.1-2.7C1647.8,1177.5,1650.3,1176.5,1652.2,1176.2z M1649.1,1168.8
								c2.7,4.4-4.4,6.3-6.5,2.1C1640.3,1166.5,1647,1165.4,1649.1,1168.8z M1639,1211c0.6,0.8,1.4,1.9,1.5,3c0.2,2-0.3,1.6-1.8,2.1
								c-2.2,0.7-4.3,0.1-5.3-2.3c-0.7-1.7-0.3-3.8,1.5-4.5c0.8-0.3,1.9-0.3,2.7,0C1639.5,1209.9,1638,1209.6,1639,1211z M1519.8,1226.2
								c-0.6-1.9,5.5-3,6.4-2.7c3,1,1.5,3.9,0.4,5.7c-1,1.8-2.5,4-5.1,2.7c-1-0.5-1.9-2.2-2.1-3.3
								C1519.3,1227.6,1520,1227.1,1519.8,1226.2z M1519.6,1247.2c2.6-1.3,9.4,2.4,5.5,5c-1.4,1-3.9,1.8-5.5,0.8
								C1516.9,1251.4,1518.8,1249.2,1519.6,1247.2z M1526.1,1243.2c-1.8-0.4-5.1-1.4-5.3-3.6c-0.2-2,2.2-3.4,3.8-3.7
								c1.8-0.3,3.8,0.4,4.3,2.4C1529.1,1239.4,1527.3,1243.5,1526.1,1243.2z M1528.2,1256.6c0.9-1.2,4.1-2.3,5.3-1c1,1.1,0.2,4.6-0.6,5.7
								C1530.1,1265,1525.6,1259.9,1528.2,1256.6z M1533.7,1252.3c-3-0.8-6-2.9-3.8-6.4c0.8-1.3,1.6-1.9,2.2-3.4c0.6-1.7,0.9-2.7,3.3-1.8
								c2.7,0.9,3.2,4.3,3.1,6.8c0,1.6-0.4,2.9-1.5,4.1C1535.3,1253.4,1535.6,1251.6,1533.7,1252.3z M1538,1235.6c1.3-1.4,1.7-3.6,3-4.8
								c1.9-2,2.7,0.1,2.8,1.9c0.1,1.8-0.5,3.7-2.1,4.8C1539.9,1238.6,1538,1238.3,1538,1235.6z M1546.9,1189.7c2-3.4,6,4.2,3.4,6.4
								c-1.4,1.2-3,0-3.7-1.3C1545.6,1192.7,1547.6,1191.5,1546.9,1189.7z M1559.2,1167.9c0.6-1.9,1.3-2.5,3.1-2.9c3.1-0.7,3,1.2,1.8,3.5
								c-0.6,1.3-1.8,3.4-3.4,3.5C1557.8,1172.4,1559.4,1169.7,1559.2,1167.9z M1582.1,1159c1,0.4,2.5,4.6,2.9,5.7c0.9,2.6-0.2,3.9-1.3,6
								c-1.9-1.8-3.1-3.6-3.6-6C1579.7,1163.2,1578.9,1157.7,1582.1,1159z M1581.8,1150.7c0.6-1.4,2.3-4.5,3.9-4.9
								c2.9-0.8,4.4,3.3,3.3,5.3c-0.9,1.6-4.2,4.7-5.9,3.7C1581.7,1154,1581.1,1152.1,1581.8,1150.7z M1601.9,1186.2
								c-0.1,0.5,0,8.1-1.9,4.3c-0.4-0.8,0.1-2.2,0-3c-0.2-1.3-0.9-2.4-1-3.7c-0.2-1.4,0.1-5.2,2.4-5.2
								C1604.4,1178.5,1601.8,1184.5,1601.9,1186.2z M1600.4,1198.9c0.2,4.3,3.4-1,3.6-1.1c0.6-0.3,3,0.4,3.6,1.1c1.5,1.7-2.1,5.1-4.1,5.6
								c-3.2,0.8-4.2-2.4-6.7-2.7c-2.5-0.3-1.6,3.2-3.6,4c-0.3,0.1-3-0.7-3.3-0.9c-1.3-0.6-2.3-2.4-3.7-2.7c-1.4-0.3-1.6,0.4-2.7,0.9
								c-1.8,0.8-3.1,0.6-4.9,0c-2-0.6-2,0-2.3-2.4c0-0.3-0.2-2.5,0.3-2.7c1.2-0.5,1,5.6,4.1,2c2.1-2.3,3.6-5.9,7.2-2.6
								c2.5,2.3,2.4,5.5,5.7,1.7C1596.1,1196.1,1597.7,1195.6,1600.4,1198.9z M1606.9,1179.6c2.7-2.5,7.3,7.8,4.8,10.7
								c-1.9,2.2-5.2,1.8-6.5-0.7c-0.8-1.5-0.5-3.3-0.2-4.9C1605.5,1183.2,1607.2,1181.1,1606.9,1179.6z M1629.9,1202.6
								c0.3,0.7,2.1,2,2.2,2.6c0.1,1-1.3,2-1.6,2.7c-1.9-1.8-4.1-2-6.2-1.2c-0.1,0.3-0.2,0.5-0.3,0.8c-0.8,0.1-1.5,0.3-2.2,0.6
								c-1,0.5-1.9,1.1-2.5,2c-0.7-2.3-1.7-4.6-3.2-6.5c-1.5-1.8-4.4-3-3.7-5.9c0.5-2,5.5-5.1,7.2-3.4c1.8,1.9-3.1,8.2,1.9,6.8
								c2.1-0.6,3-2.5,5.6-2.4C1630.7,1199,1628.9,1200.2,1629.9,1202.6z M1599.7,1217.6c2.1-0.2,4.8,0.1,6.8,0.2c1.4,0.1,3.9,0.5,4.9,1.6
								c2.3,2.5-0.7,5-3.3,5.2c-3.5,0.2-3.5-3.3-6.5-2.9c-0.9,0.1-2,1.5-3.1,0C1598,1220.8,1597.7,1217,1599.7,1217.6z M1598.2,1213.3
								c-1.4,0.1-2.1,1-3-0.5c-0.4-0.5-0.2-2,0.1-2.5c1-2,3.2-1.8,5.1-1.5c1.7,0.3,4.8,2.1,3,4.4
								C1602.2,1214.8,1600.1,1213.1,1598.2,1213.3z M1606.7,1209.1c0-1.5,1.1-3.6,2.2-4.5c1.8-1.3,2.9-0.6,4.3,0.9c1.8,2,3.6,5.3,3.9,8.1
								c0.1,1.1,0.3,2.7-0.9,3.4c-1.4,0.8-2.3-0.5-3.6-1.3C1609.9,1214.1,1606.8,1212.8,1606.7,1209.1z M1613.3,1174.3
								c0.8-2,3.3-3.8,5.5-2.6c1.8,1.1,0.9,1.8-0.1,3.4c-0.8,1.3-1.9,3.8-3.6,3.7C1612.6,1178.8,1612.7,1176,1613.3,1174.3z
								M1618.4,1187.4c-2.3-0.5-1.6-4.5-0.7-5.8c1.7-2.5,3.6-5,6.6-3.2c2.4,1.4,4.5,3.9,1.7,5.7C1625,1184.8,1619.7,1187.7,1618.4,1187.4
								z M1626.5,1176.4c-1-1.2,0.2-1.2,1-1c0.2,0,3,1.5,2.8,1.3c0.7,0.7,1.1,1.6-0.6,1.7C1628.6,1178.4,1627.4,1177,1626.5,1176.4z
								M1630.7,1169.4c-0.9-4.4,8-6,8.1-1C1638.8,1172,1631.5,1173.1,1630.7,1169.4z M1631.3,1163.3c-5.5,3.2-10.6-2.2-4.5-5.6
								C1631,1155.5,1636.7,1160.2,1631.3,1163.3z M1625,1168.3c3.2,1.6,0,4.2-2.3,2C1620.6,1168.2,1622.5,1167,1625,1168.3z
								M1620.7,1157.4c-0.2,0.5-0.5,0.9-0.7,1.4c-2.1-2.5-13.3-10.7-4.9-13.3c2.9-0.9,4.1,1.8,5.1,4c0.6,1.3,1.4,2.7,1.7,4.1
								C1622.2,1155.5,1621.8,1155.7,1620.7,1157.4z M1613,1157.2c2.8,0.6,2.1,2.3,3.6,4.1c1.4,1.6,3.8,3.8-0.2,4.8
								C1610.8,1167.4,1608.1,1156.1,1613,1157.2z M1586.5,1214.8c-1.9-0.9-2.4-3.4-1.6-5.2c1.3-2.8,3.2-2.1,5.3-1
								c1.1,0.6,1.5,0.3,1.8,1.4c0.2,0.7-0.6,2.1-0.9,2.6C1590.2,1214.3,1588.3,1215.7,1586.5,1214.8z M1573.1,1208.9
								c1.4-3.6,8.8-4.2,6.8,1.1c-0.5,1.4-1.7,3.8-3.2,3.8C1574.7,1213.8,1572.9,1210.5,1573.1,1208.9z M1571.8,1187.9
								c1.2-1.2,4-1.2,5.4-0.4c1.5,0.9,4.4,3.6,3.1,5.7C1578.7,1195.9,1569.1,1190.6,1571.8,1187.9z M1595.5,1190
								c-1.4,2.5-3.3,4.1-6.3,2.6c-2.2-1.1-1.6-2.3-0.3-4.7c0.7-1.2,2.8-4.1,4.5-4.1C1596.8,1183.8,1596.6,1188,1595.5,1190z
								M1590.2,1179.2c-2.9-1.3-5-3.4-3.2-6.7c1.1-2.1,4.1-3.9,6.2-3C1598.3,1171.6,1596.4,1182.1,1590.2,1179.2z M1586.2,1184.2
								c-0.1,0.6-1.3,5.7-1.9,5.6c-0.4,0-3.3-4.2-3.9-4.7c-1.5-1.5-3-2.7-1.8-4.9c0.6-1.1,3.5-3.1,4.9-2.6
								C1585.5,1178.3,1586.5,1182.6,1586.2,1184.2z M1579.8,1172.8c1.4,1.8-1.6,2.6-2.6,3.8c-0.6,0.7-1.4,3.1-2.3,3.3
								c-0.1-2-3.3-6.7-2.8-7.5C1573.6,1169.7,1578.2,1170.7,1579.8,1172.8z M1569.3,1168.9c-1.9-0.8-0.7-3.3,0.3-4.2
								c2.2-2.2,5,0.3,6.5-1.1C1577.7,1165.6,1571,1169.6,1569.3,1168.9z M1576.2,1157.4c-2.4,0.5-4,2.6-6.6,1.1c-2.8-1.5-2.1-4.6-0.2-6.5
								C1573.2,1148.2,1580.4,1152.7,1576.2,1157.4z M1568.2,1173.9c0.4,3,4.3,6,3,9c-1.3,3.1-5.1,2.3-7.5,1.1c-2.3-1.1-4.5-3.4-3.1-6.1
								C1561.6,1176.2,1566.8,1173.1,1568.2,1173.9z M1568.7,1204.2c0.8,2.2,2.5,1.5,0.8,3.5c-1.3,1.5-4.2,2.5-5.1,0.3
								c-0.9-2.2,1.8-3.1,1.6-5.7c-0.2-2.5-2.4-4.6-4.5-5.7c2.8-0.8,2.4-3,4-4.7c-0.3,0.3,2.2-0.9,2.1-0.9c0.8,0.1,1.6,1.4,2,1.9
								c1.2,1.8,1.5,3.9,0.8,5.9C1569.8,1200.6,1567.9,1202.1,1568.7,1204.2z M1552.3,1210.8c0.5-2.5,0.8-3.8,3.8-3.3c1.8,0.3,4,1.5,4,3.6
								c0,1.7-2.4,4.5-4.3,4.4C1553.8,1215.3,1552.5,1212.4,1552.3,1210.8z M1553.5,1223.5c-0.8,6.9-14.4,3.4-10.6-1.8
								c0.8-1.1,5.2-4.8,6.7-4.7C1551.8,1217.1,1553.5,1221.6,1553.5,1223.5z M1556.3,1202.6c-1.6-0.2-4.2,2.8-4.8-0.1
								c-0.6-2.9,3.7-4.3,5.7-4.1c2,0.2,6.3,1.5,5.4,4.3C1561.5,1206.2,1558.2,1202.8,1556.3,1202.6z M1560.9,1186.8
								c0.4,2,3.1,3.5,1.1,5.9c-1.5,1.9-4.6,1-6.3-0.2C1550.9,1189.2,1557.4,1183.6,1560.9,1186.8z M1552.7,1183.9c-1.3,0.3-1.4,1-3,0
								c-1.1-0.8-2.2-2.3-2-3.7c0.2-2.4,2.8-2.8,4.8-2.9C1558.7,1176.8,1558.1,1182.8,1552.7,1183.9z M1549.4,1208.4
								c-0.6,2.5,0.9,3.9-1.5,5.4c-2.7,1.7-5.4,0.6-6.7-2.3c-0.8-1.9-0.7-5.1,1.7-5.7C1544.5,1205.5,1548.9,1206.8,1549.4,1208.4z
								M1540.9,1215.9c1.8,4.3-6.5,10.4-8.8,5.7c-1-2.1,1.1-4.6,2.5-5.9C1537.5,1213.2,1538.4,1213.7,1540.9,1215.9z M1542.6,1244.9
								c0.9-3.4,2.9-7.3,6.4-4.2c2.2,1.9,2.9,4.5,2,7.2C1549,1254,1541,1250.8,1542.6,1244.9z M1546.9,1235.3c0.5-1.8,0-2.3,1.8-3.3
								c1.1-0.6,2.5-0.7,3.7-0.7c1.6,0,1.4-0.7,2.9,1c0.9,1,2.1,2.4,2,3.8C1556.9,1240.4,1548.4,1237.6,1546.9,1235.3z M1554.3,1249.2
								c0.3-2.3,1.5-4.7,3-6.4c1-1.2,1.5-2.4,2.8-1c0.7,0.7,0.6,2.3,0.5,3.1c-0.2,1.7-1.1,3.8-1.9,5.2c-0.3,0.6-0.7,1.5-1.3,1.9
								C1555.3,1252.9,1553.8,1251,1554.3,1249.2z M1556.5,1224.7c0.4-2.4,0.3-6.2,3.6-6.5c2.7-0.3,3.6,2.5,3.6,4.7c0,2.1-0.8,5.2-2.7,6.3
								C1557.8,1231.2,1556.6,1227.4,1556.5,1224.7z M1565.4,1231.4c1.2,1.6,2.5,2,2.6,4.2c0.1,2-0.8,4.5-3.1,3.9
								c-1.3-0.3-3.5-2.8-3.6-4.1C1561.2,1233.3,1563.7,1232.1,1565.4,1231.4z M1567.3,1222.3c-0.4-2.6-4.4-10.9,1.5-9.9
								c3.4,0.5,6,5.1,6.4,8.2c0.3,2.7-0.9,7-4.3,7.4C1567.7,1228.3,1567.6,1224.6,1567.3,1222.3z M1573.5,1230.2c2.4-0.9,5.4,1.2,2.2,2.8
								C1572.9,1234.3,1570.2,1231.4,1573.5,1230.2z M1575.3,1237.5c0.9,1.3,2.5,1.8,2.9,3.5c0.4,1.4,0.3,1.8-0.8,2.7
								c-1.3,1-0.7,0.8-2.1-0.1C1572.8,1242,1570.2,1237.3,1575.3,1237.5z M1578.5,1220.6c-0.4-9.7,11.7-0.6,5.4,5.1
								c-1.5,1.4-3.3,1.5-4.4-0.2C1578.8,1224.4,1578.5,1222,1578.5,1220.6z M1582.1,1230.5c1.9,0.3,3.6-0.8,5.7,0.2
								c2.7,1.3,3,3.7,1.5,6.2C1584.4,1244.7,1576.4,1232.8,1582.1,1230.5z M1588.4,1223.5c0.7-2.7-0.4-6.1,3.5-5.7c2,0.2,3.3,1.6,3.7,3.5
								C1596.8,1226.8,1589.6,1230,1588.4,1223.5z M1602.5,1227.3c-0.7,2.5,0.9,4.8-1.9,6.7c-1.7,1.1-4.6,1.2-5.4-1
								C1593.9,1229.7,1599.8,1226.1,1602.5,1227.3z M1599.2,1246.1c0.5-1.8,0.4-7.9,3.2-5.8c1.7,1.3,1.9,4.4,1.7,6.3
								c-0.2,1.4-0.6,3.8-2.2,4.4C1598.5,1252.3,1598.8,1248.1,1599.2,1246.1z M1603.7,1236.5c-0.5-0.1,1.7-4.5,3.1-4.5
								c1.7-0.1,3.4,2.9,4.3,4c1.6,2,2.8,4.6-0.7,4.6C1607.4,1240.5,1606,1237,1603.7,1236.5z M1611.7,1230.9c-0.9-2,2.2-5.9,4.2-6.3
								c2.1-0.4,5.2,1.8,5.4,3.9c0.1,1.5-2.1,5.4-3.6,6C1615.7,1235.2,1612.5,1232.5,1611.7,1230.9z M1621,1249.2c1-1.3,7,3.8,2.1,6.4
								c-1.9,1-4.2,0.9-4.9-1.4C1617.4,1251.5,1619.7,1250.9,1621,1249.2z M1621.2,1235.8c1.4-1.4,1.9-4.3,3.8-4.6
								c1.5-0.2,2.8,1.4,2.7,2.5C1627.5,1236.5,1620.1,1240.5,1621.2,1235.8z M1627.2,1227.1c-1.6,0.9-3.5-2.3-4.6-3.2
								c-2.1-1.5-4.3-3.7-0.3-4.8c2.5-0.7,5.1-0.1,6.1,2.4C1628.8,1222.9,1628.7,1226.2,1627.2,1227.1z M1625.7,1214.8
								c-1.7-0.3-6-0.9-3.4-3.7c1.2-1.3,3.8-0.9,5.2-0.3c1.3,0.5,1,0.3,1.8,1.3c0.6,0.8,0.9,2.3,1.4,3.2c0.4,0.7,2.3,4.7,1.7,4.5
								c-0.9-0.3-2-2.2-2.6-2.7C1628.7,1216,1627.4,1215.1,1625.7,1214.8z M1630.7,1228.7c1.6-5.6,7.8-1,5.6,3.1
								C1634.1,1236,1629.6,1232.6,1630.7,1228.7z M1638.2,1219.7c1.8,0,2.2-0.5,3.8,0.7c0.8,0.6,1.3,1.7,1.7,2.6c1,2.5,0.5,5.4-1.3,7.4
								c-1.3-1.8-2.1-3.5-3.6-5.2C1637.2,1223.6,1635.4,1221.3,1638.2,1219.7z M1633.3,1197.1c-1.3-0.5-2.1-2.4-3.6-2.9
								c-1.3-0.5-2.6-0.3-4-0.5c-3.2-0.4-4.6-3.1-1.6-5.5c3.6-2.9,8.8-0.6,10.2,3.1C1634.7,1192.7,1636.3,1198.1,1633.3,1197.1z
								M1634,1179.1c0.9-2.3,0.4-3.5,3.3-4.3c1.9-0.5,4.9-0.1,5.7,2C1644.6,1181.2,1635.3,1185.3,1634,1179.1z M1640.1,1185.7
								c1.5-1,3-1,3.5,1c0.4,1.5-1.2,2.9-2.3,3.6c-1.1,0.8-3.9,1.1-4.2-0.6C1637,1188.1,1639,1186.4,1640.1,1185.7z M1639,1193.8
								c1.9,0,3.6-0.7,5.5-0.7c0.9,0,0.8-1.1,1.8,0c0.8,0.9,0.2,2-0.6,2.7C1643.8,1197.5,1635.8,1197,1639,1193.8z M1644.7,1155.1
								c4,2.2,1.4,8.1-2.8,7.6c-2.8-0.3-3.6-2.4-2.6-4.6C1640.3,1155.8,1642.2,1155.4,1644.7,1155.1z M1637.6,1140.3
								c-0.1-1.4-0.3-2.9,1.2-3.2c2.1-0.3,2.6,3.6,2.5,5.2c-0.1,1.8-0.6,3.9-2.5,3.1C1636.3,1144.4,1637.8,1141.9,1637.6,1140.3z
								M1636.6,1150c2.5,1.3,2.4,2.9,0.7,5c-1.5,1.9-2.5,1.8-3.9-0.4c-0.8-1.3-1.1-2.6-0.6-4.2C1633.7,1147.6,1634.5,1148.9,1636.6,1150z
								M1629.7,1135c0.9-2.4,4.3,0.7,4.5,2.3c0,0-0.6,1.9-0.6,2.2c-0.1,0.8,0.1,1.7-0.1,2.6c-0.4,1.8-1.6,3-3,0.8
								C1629.3,1141.1,1629,1137.1,1629.7,1135z M1629.1,1148.4c1.1,1.7,0.6,3.3-0.9,4.5c-1.9,1.7-1.8,1.3-3-0.2c-1-1.1-1.9-3.4-0.7-4.6
								C1625.3,1147.3,1628.4,1147.2,1629.1,1148.4z M1623.2,1137.6c4.4,0.5,4.9,7.1,0.2,6.5c-1.3-0.2-2.9-1.1-3.2-2.4
								C1620,1140.8,1622.1,1137.5,1623.2,1137.6z M1615,1134.7c0.6-2,1.9-4.4,4.2-4.5c2.3-0.1,4.5,2.5,2.6,4.3c-0.3,0.3-1.5,0.5-1.9,0.8
								c-0.7,0.5-1.1,1.9-1.8,2.3c-1.4,0.8-0.4,0.7-1.8,0C1614.9,1136.9,1614.6,1136.1,1615,1134.7z M1612,1138.3c0.1,0.3,1.7,2.4,1.7,2.3
								c0,0.7-1.4,2.1-1.9,2.6c-1.3,1.2-3.3,2.2-5,0.9C1603.2,1141.2,1608.9,1137.2,1612,1138.3z M1603.4,1132.6c0.2-1.3,0.9-2.1,1.9-2.8
								c1.8-1.3,2.3-1.3,3.6,0.2c3.4,3.7-2.6,5.3-5.3,6.8C1603.3,1135.4,1603.2,1134,1603.4,1132.6z M1603.3,1155.2c0-1.6,0.4-4.6,2.3-5.2
								c2.1-0.7,2.7,1.3,2.7,3.2c0,1.6-0.7,5.3-2.6,5.8C1603.5,1159.5,1603.3,1156.8,1603.3,1155.2z M1604.3,1166.5
								c1.1-1.3,2.2-2.9,3.7-2.1c0.8,0.4,1.3,1.7,1.6,2.5c0.8,2.1,1,5-0.1,7c-1.2,2.2-3.6,2.7-5.8,1.6
								C1599.2,1173.3,1602.9,1169.4,1604.3,1166.5z M1602,1164.5c-1.2,1.3-1.5,2.9-3.5,2.9c-2.6,0-2.7-2.3-2.3-4.2c0.5-2.3,1-3.9,3.3-3.3
								C1601.8,1160.6,1602.9,1162.2,1602,1164.5z M1601.7,1143c1.5,1.8-1.6,9.2-2.4,11C1595.2,1149.6,1597.8,1146.2,1601.7,1143z
								M1596.1,1128.1c3.2-2.9,6.1,3.9,3.8,5.7c-0.4,0.3-4.4,0.1-4.9-0.4C1593.5,1132.1,1595,1129.1,1596.1,1128.1z M1593.1,1142.2
								c1-1.4,1.2-3.8,2.7-4.6c1.5-0.8,3.4-0.3,3.3,1.8c-0.2,2.6-4.3,4.5-5.9,6.3C1592.7,1144.7,1592.8,1143.3,1593.1,1142.2z
								M1594.9,1155.2c0.2,1.7-2,3.6-2.7,5.3c-0.4,0.9-1.3,4.7-2.3,4.8c0.1,0-2.2-2.7-2.4-3c-0.7-1.2-1.5-2.1-1.1-3.6
								c0.8-2.7,5.1-4.3,6.3-7.1C1593.6,1152.5,1594.7,1153.7,1594.9,1155.2z M1591.3,1132.5c0.2,2.4,1.4,2.4,0.4,4.9
								c-0.8,2.1-1.8,4.4-4.4,3.1C1582.2,1137.9,1588,1129.7,1591.3,1132.5z M1581.2,1124.8c2.8-0.9,5.4-0.7,4.8,3c-0.3,1.8-2.2,6.2-4,6.9
								c-2.3,1-4.5-2.1-5-4C1576,1127.3,1578.2,1125.7,1581.2,1124.8z M1577.9,1138.1c5.2-2.1,4.7,8-1,8.3
								C1570.2,1146.9,1574.2,1139.6,1577.9,1138.1z M1568.1,1123.1c3-2,6.1,0.2,3.4,3.2c-0.9,1.1-3.6,2.5-4.4,1.7
								C1565.7,1126.5,1566.8,1124,1568.1,1123.1z M1566.9,1135.4c1.1-2.2,3.4-4.6,5.8-3.6c2,0.9,2.5,2.7,1.4,4.3c-1.6,2.2-3,2.5-3.9,5.4
								c-0.1,0.4-1.8,5.7-1.7,5.8c-0.6-0.9-0.4-3.3-0.6-4.3C1567.3,1140.5,1565.6,1138.1,1566.9,1135.4z M1565.4,1159.2
								c0.3,0.8,0.7,1.6,0.9,2.5c-1.9-0.1-3.5-0.3-5.4,0c-1.8,0.3-3.5,0.9-2.8-1.8c0.4-1.4,2.2-3.7,3.6-3.9
								C1564.3,1155.4,1564.2,1157.9,1565.4,1159.2z M1565.2,1145.2c1.4,6.9-7.7,8.9-9.4,1.9c-0.6-2.4,0-6,2.8-7
								C1561.8,1138.9,1564.5,1142.4,1565.2,1145.2z M1553.4,1138.6c-2.4-1.8-1.1-7.4,0.9-9.3c1.9-1.7,7.9-1.8,9.4,0.8
								c1.2,2.1-1.6,3.3-3.4,4c-0.5,0.2-0.9,0.5-1.3,0.6c-0.4,0.1-0.9-0.5-1.1-0.3C1556,1135.2,1555.1,1137.5,1553.4,1138.6z
								M1558.3,1118.3c1.5-2,4.2,0.3,5.2,1.9c1.7,2.6-0.9,3.8-3.3,4.5c-0.9,0.3-5,1.5-5.8,0.8C1553.4,1124.3,1557.4,1119.6,1558.3,1118.3
								z M1547.9,1117.7c0.8-3,7.2-2.7,7.3-0.1c0,0.4-3.7,5.8-4.2,5.9C1548.9,1123.8,1547.6,1118.9,1547.9,1117.7z M1541,1112.3
								c0.7-1.3,1.1-1.8,2.6-1.9c0.7-0.1,2.4,0.2,2.9,0.9c0.9,1.2,0.4,1-0.5,2.4c-2.2,3.6-0.8,6.6-1.5,10.3c-1.6-0.7-3.6-3-4.2-4.6
								C1539.5,1117.1,1540.8,1114.7,1541,1112.3z M1532.6,1109.4c1.3-2.3,2.9-1.3,4.1,0.5c1.2,1.8,0.9,4.1-0.1,6.4
								c-0.9,2-3.1,1.5-4.5-0.3C1530.5,1114,1531.5,1111.4,1532.6,1109.4z M1530,1126.7c-3.6,2.3-5.2-2.3-2.9-4.9c1.1-1.2,3.6-2.1,5.1-0.7
								C1533.3,1122.2,1530.6,1125.1,1530,1126.7z M1539,1130.6c-3,2.8-8.3,2.4-6.1-2.6c1.1-2.4,2.9-6.3,6.1-4.7c1.1,0.6,2,2.4,2.1,3.6
								c0.8,0.5,0.7,1-0.3,1.7C1540.3,1129.3,1539.7,1130,1539,1130.6z M1541.5,1133.7c-0.4-4.3,6.6-6.1,9.6-5.9c0.1,1-1.5,2.5-1.9,3.6
								c-0.5,1.5-0.1,3.4-1.3,4.4C1546.6,1136.9,1541.7,1135.7,1541.5,1133.7z M1545.2,1147.4c-3.4-4.6,4.1-9.3,6.3-5.3
								c0.7,1.3,0.9,3.4,0.3,4.6C1550.7,1148.9,1546.7,1149.5,1545.2,1147.4z M1546.9,1156c0.2-2.5,3-4.6,5.5-4.5c2.8,0.1,5.4,2,3.9,4.6
								c-1.2,2.1-2.7,3.4-5.3,3.2C1549.1,1159.2,1546.7,1158.3,1546.9,1156z M1545.8,1167.9c0.7-2.8,2.9-3.8,5.4-4.4
								c1.8-0.5,2.3-0.6,3.5,1c1.2,1.5,1.4,2.5,1.2,4.4C1555.2,1174.7,1544.4,1174.1,1545.8,1167.9z M1546.2,1199c0.8,0.2,2,1.5,1.7,2.5
								c-0.5,1.6-2.9,1.6-4.3,1.4c-1.4-0.2-4-1.4-3.5-3.2C1540.8,1197.5,1544.8,1198.6,1546.2,1199z M1543.8,1189.1c-1.1,2.2-0.5,5-4,2.8
								c-2.1-1.3-3.2-4.3-2-6.5c1.2-2.1,2.9-4,5.2-2.5C1545.5,1184.4,1544.9,1186.8,1543.8,1189.1z M1538.1,1171.1c1-0.8,3.4-1.5,4.4-0.7
								c1.3,0.9,2.7,5.1,2,6.6c-1,1.8-3.5,1.4-5.1,0.2C1537,1175.5,1537.3,1173.5,1538.1,1171.1z M1524.3,1167.5c-4.1-3.9,3.1-7.5,6.5-5.1
								C1535.1,1165.5,1527.1,1170.2,1524.3,1167.5z M1532.7,1176.1c-1.6,1.2-3.6,1.9-4.3-0.8c-0.5-2,1.4-4.3,2.8-5.4c1.2-1,3.6-2.2,4-0.2
								C1535.6,1171.5,1531.5,1174.4,1532.7,1176.1z M1533.8,1183.2c-1.8-1.5,1-5.1,2.8-4.3C1538.6,1179.8,1535.5,1184.5,1533.8,1183.2z
								M1534.6,1193.3c2,0.6,1.7,2.9,1.9,4.5c0.2,2.2,1.2,4.3,1.2,6.5c0,1.5-0.8,5.4-2.4,6.2c-1,0.5-3.2-0.3-3.5-1.5
								c-0.3-1.1,1.4-2.2,1.9-3.3c0.9-1.9,0.5-4.2-0.6-5.9c-1.2-1.8-1.7-1.4-1.1-3.7C1532.3,1194.3,1532.2,1193.2,1534.6,1193.3z
								M1535.6,1227.2c2.4-0.2,3.3,0.2,2,2.3c-1,1.6-2.2,4.6-3.8,5c-1,0.2-3-0.5-3.7-1.2c-0.9-1.1-0.6-1-0.3-2.2
								C1530.4,1227.8,1532.6,1227.4,1535.6,1227.2z M1530.4,1215.7c-1.8,2.5-4.3,4.8-7.7,4.8c-1.5,0-1.4,0.8-2.1-0.8
								c-0.1-0.3-0.1-3.9,0-4.3c0.4-1.8,2.1-3.9,3.9-4.5C1527.4,1209.9,1531,1212.4,1530.4,1215.7z M1527.4,1200.8
								c0.7,0.7,2.1,0.2,2.3,0.4c0.9,0.7,0.6,2.1-0.3,2.7c-1,0.6-1.8,0.1-2.4-0.7C1525.9,1201.7,1526.3,1201.8,1527.4,1200.8z
								M1518.2,1195.9c1.8-2.9,10.1-2.4,8,1.5C1525.1,1199.4,1515.9,1199.5,1518.2,1195.9z M1517.7,1201.5c1.9,0.9,5.6,0,6.1,2.3
								c0.4,1.7-1.8,3.5-3.1,4.1C1515.9,1210.4,1512.6,1202.8,1517.7,1201.5z M1504.9,1208.6c-1.1-1.3,0.6-3.4,1.5-4.2
								c0.7-0.6,1.6-1.1,2.5-1.4c1.8-0.6,2-0.6,2.7,0.8C1513.3,1207.8,1508.1,1212.1,1504.9,1208.6z M1509.2,1217.8c-3.8-4.4,6-8.2,8-2.9
								c0.3,0.8,0.2,1.4,0.1,2.3c-0.2,1.8,0.4,1.5-1.2,2.1C1514.2,1220,1510.6,1219.3,1509.2,1217.8z M1511,1225.2
								c4.1-2.5,7.6,4.2,4.2,5.9C1513.3,1232.2,1507.7,1227.2,1511,1225.2z M1507.1,1235c1-2.7,3.1-2.5,5-1c2.1,1.6,1.7,2,0.1,3.9
								c-1.1,1.2-0.5,2.5-2.8,1.8C1507.6,1239.1,1506.4,1236.7,1507.1,1235z M1506.9,1255.9c1.8-2,1.3-3,4.3-3.2c1.4-0.1,3.9-0.1,3.8,2.4
								c-0.1,2.2-3.3,3.8-5.2,3.7C1508.8,1258.8,1505.5,1257.5,1506.9,1255.9z M1505.2,1249.5c1,1.8-1.2,6.8-3.2,3.6
								C1500.7,1250.8,1503.6,1246.5,1505.2,1249.5z M1500.3,1241.8c0.9-1.1,1.6-2.5,3.1-1.9c1.1,0.5,1.7,2.6,1.2,3.7
								C1503.3,1246.7,1497.6,1245.4,1500.3,1241.8z M1500.3,1260.4c5.1,0,2.4,5.2-0.7,4.3C1496.9,1264.1,1497.7,1260.4,1500.3,1260.4z
								M1490.8,1254.6c0.8-1.6,3.3-4.7,5.4-4.3c1.6,0.3,2.8,2.7,2.8,4.2C1498.9,1259.3,1489.1,1258.2,1490.8,1254.6z M1489.1,1261.7
								c2.9-2.7,6.2,2.6,4.9,4.9C1491.9,1269.9,1487.1,1263.5,1489.1,1261.7z M1492.3,1277.7c-1.5,1.1-0.1,1.6-2.2,1.6
								c-0.6,0-1.6-0.6-2-0.9c-1.5-1.1-2.6-3-1.7-4.8C1488.6,1269.6,1494.6,1273.3,1492.3,1277.7z M1498.8,1286.9
								c-1.6,1.1-3.9,0.7-5.1-0.8c-1.7-2.2-0.4-3.7,1.1-5.1c1-0.9,0.4-1.7,2.4-1.2c1.1,0.3,1.8,0.8,2.3,1.8
								C1500.4,1283.1,1500.3,1285.8,1498.8,1286.9z M1497.5,1273.8c-5-3,0.3-7.9,4.1-5.4C1505.4,1270.7,1501.7,1276.3,1497.5,1273.8z
								M1507.5,1282.8c-1.3,2.6-6.5-1.2-4.8-3.8C1504.6,1275.8,1509.1,1279.6,1507.5,1282.8z M1507.9,1272.1c-0.4-1.8-0.9-2.9-1.5-4.6
								c-0.4-1.2-0.7-2.4,0.3-3.4c1.5-1.7,5.7-1.5,7.3-0.1c1.9,1.8,1,4.9-0.4,6.7C1512.4,1272,1508.5,1275.3,1507.9,1272.1z
								M1513.1,1281.8c-3-0.7-3.5-6.5,0.2-6c1.1,0.1,1.8,1.6,2.2,2.9c0.4,1.1,1,1-0.1,2.3C1514.8,1281.6,1514,1282,1513.1,1281.8z
								M1516.6,1245.8c-0.5,0.6-0.8,1.3-1.1,2c-0.3,0.4-0.7,0.3-1.1-0.4c-0.5,0.2-2.1,0.5-2.7,0.5c-2.1-0.1-4.8-1.4-3.1-3.9
								C1510.5,1241.5,1520.3,1241.1,1516.6,1245.8z M1521.2,1256.6c4,0.2,3.7,9.7,0.1,10.2C1516.9,1267.5,1515.1,1256.3,1521.2,1256.6z
								M1526.6,1277.2c-0.7,2.2-2.1,2.7-4.1,2.3c-0.6-0.1-1.2-0.4-1.8-0.7c-1.1-0.2-1.5-0.9-1.1-2c-0.2-0.4-1.5-3.6-1.5-3.5
								c0.5-2,5.2-1.4,6.4-0.8c0.3,0.2,1.3,0.3,1.5,0.6C1526.2,1273.5,1526.7,1276.8,1526.6,1277.2z M1527.2,1269c-1-0.1-1.1-1.4-0.8-2.1
								c0.7-1.7,2-0.2,3-1.4C1530,1266.5,1527.7,1268.1,1527.2,1269z M1534.3,1274.8c-1,1.3-3.1,2.1-4.5,0.7c-1.5-1.6-0.4-3.6,0.5-4.7
								C1533.3,1266.9,1537.1,1271.4,1534.3,1274.8z M1535.4,1264.9c-0.9-2.5,2.6-6.4,3.9-8.1c-0.1,0.2,1.8-2.7,1.3-2.5
								c0.4-0.2,2.7,0.6,3.1,1.1C1545.4,1258,1537,1269.3,1535.4,1264.9z M1544.5,1271.5c-1.9,2.1-6.4-0.9-4-3.4
								C1542.9,1265.6,1546.6,1269.2,1544.5,1271.5z M1547.4,1262.8c-4.6-4.2,4.3-11.5,6.2-5.9C1554.6,1260.1,1550.6,1265.8,1547.4,1262.8
								z M1554.9,1271.6c-3.5,0.8-8.1-1.6-3.6-4.8C1556.5,1263.1,1560.7,1270.3,1554.9,1271.6z M1558.6,1260.8c0-2.3,1.6-5.3,3.7-6.2
								c1.2-0.5,4-0.6,4.9,0.9C1569.5,1259.3,1558.5,1264.2,1558.6,1260.8z M1567.5,1269.6c-1.3,4-6.8,2.7-6.3-1.5
								c0.2-1.5,1.8-3.8,3.6-3.8C1566.8,1264.3,1568.1,1267.7,1567.5,1269.6z M1567,1251.9c-3.9-1.6-4.7-8.8,1-7.8
								C1573,1245,1572.8,1253.9,1567,1251.9z M1579.1,1271.6c-0.6,4.1-7.4,3.6-7-0.3c0.1-1.2,2.8-3.7,3.9-3.9
								C1578.3,1267,1579.3,1269.9,1579.1,1271.6z M1580.7,1265.3c-2.1,1.1-2.5-2.6-4.2-2.7c-2-0.1-1.7,2.1-2.9,2.9
								c-3.5,2.4-1.7-5.1-1.5-6.5C1573.4,1246.6,1586.1,1262.7,1580.7,1265.3z M1579.3,1252.2c-2.3-0.6-3.4-2.5-1.5-4.5
								c0.6-0.7,2-1.3,2.8-2.1c0.6-0.5,1.9-2.7,3.2-2C1586.7,1245.1,1583.2,1253.2,1579.3,1252.2z M1585.9,1273.3
								c-4.5-1.3-2.5-9.5,1.3-10.4C1592.2,1261.7,1591.8,1275,1585.9,1273.3z M1588.8,1259.7c-3.6,0.7-6.3-6.2-2-5.1
								C1589,1255.1,1594.3,1258.6,1588.8,1259.7z M1592.8,1252.9c-3.4-1.6-4.5-5.3-4.3-8.8c0.1-2.8,3.9-7.6,6.9-4.7
								c2.8,2.6,0.6,5.5-0.6,8c-0.9,1.7-0.6,1.7-0.1,3.4c0.3,1.1,1.6,1.2,0.3,2.3C1594.2,1253.7,1593.5,1253.6,1592.8,1252.9z
								M1595.5,1258.4c1.5-1.7,4.4-4.1,6.6-2.1c2.8,2.5-1.1,4.6-2.9,5.9c-1.3,0.9-4.2,3.6-5.4,1.2
								C1593.2,1262.1,1594.6,1259.3,1595.5,1258.4z M1600.1,1272c-1.7-0.2-6.2-2.6-3.9-4.6c2.5-2.1,5.1,2.8,7.2,2.3
								C1603.1,1271.1,1601.5,1272.1,1600.1,1272z M1605.8,1265.6c-4.5-0.1-2.9-3.7,0.3-3.9C1609.5,1261.5,1609.7,1265.7,1605.8,1265.6z
								M1611.5,1272.5c-1.4-0.4-2.9-0.4-3.3-2.3c-0.3-1.6,0.9-2.8,2.5-2.9C1614.3,1266.9,1615.2,1271.8,1611.5,1272.5z M1615.1,1258.7
								c-1.6,1.4-2.5,1.6-4.6,0.8c-1.5-0.6-3.4-1.6-3.4-3.4C1606.8,1250.2,1616.7,1254.2,1615.1,1258.7z M1612.8,1248.8
								c-3.5,0.6-0.8-3.1,0.2-4c1-0.9,3.9-4.2,5.3-3.7c1.3,0.4,1.3,3.6,0.9,4.6C1618.4,1248.6,1615.6,1249,1612.8,1248.8z M1618.6,1268.1
								c-2.5-2.4-3.8-6.6,0.1-8.3c1.8-0.8,4,0.4,5.4,1.7C1628,1265.4,1623.1,1272.4,1618.6,1268.1z M1624.7,1242.1
								c0.8-1.2,3.6-3.6,5.2-2.6c0.1,0.1,1.7,6.9,1.7,7.3C1631.3,1252.3,1621.5,1246.9,1624.7,1242.1z M1635.3,1257.6
								c-1.3,4.5-10.2,4.3-8.4-1.3c0.5-1.7,2.3-2.9,4-3.1C1633,1252.9,1636,1255.2,1635.3,1257.6z M1634.7,1244.4c-0.1-3.6-2-6,2-8.3
								c1.4-0.8,4.2-1.4,5.7-0.6C1646.9,1238.2,1638.2,1246.3,1634.7,1244.4z M1644.9,1249.7c0,2.5-0.2,5-3.1,5c-1.4,0-5.9-1.4-5.9-3.3
								c0-1.6,4.1-4.1,5.3-4.6C1644.4,1245.4,1644.9,1246.5,1644.9,1249.7z M1644.1,1205c-1.5,1.5-4.6,1.5-6.5,1c-2.4-0.7-2.1-1.8-2.8-3.9
								c2.4,1.7,6.5-1.3,9.1-1.6c1-0.1,3.1-0.9,3.4,0.5C1647.3,1201.7,1644.5,1204.4,1644.1,1205z M1645.4,1208.8c0.7-2.5,5.2-5.3,6.6-1.6
								c0.9,2.6-2.4,2.7-3.7,2.9C1646.9,1210.4,1644.6,1211.8,1645.4,1208.8z M1646.9,1214.3c1.8,0.3,3.1-0.8,4.5,0.7
								c1.5,1.6,1,3.3-0.5,4.3C1648.1,1221.2,1644.6,1217.2,1646.9,1214.3z M1653,1243.9c-5,4.3-7.9-3.9-4.6-7.6c1.3-1.4,1.2-1.7,3.3-1
								c1,0.3,2.1,0.8,2.8,1.6C1656.8,1239.2,1655.1,1242,1653,1243.9z"
					/>
					<path
						d="M3068.3,926.4c-0.3-2.5-0.9-4.7-2.7-6.7c-1.4-1.5-4.5-4.4-6.7-4.3c-0.1,0-0.3,0-0.4,0c0.1-1.2-0.1-2.5-0.5-3.9
								c-1.2-3.7-5.6-5-9-3.1c-1.6,0.9-2,2.6-2,4.5c-0.3-0.2-0.7-0.4-1.1-0.5c0.2-0.9-0.2-1.9-1.4-1.8c-0.5,0-1,0.1-1.6,0.2
								c2.1-3.4,5.6-7.4,4.9-11.4c-0.5-2.9-2.8-4.8-5.4-5.8c0.5-0.2,0.9-0.4,1.3-0.6c6.2-4,4.6-15.7-2.5-16.2c0.5-0.5,1-0.9,1.4-1.4
								c0.7-0.7,1.4-1.4,1.9-2.2c0,0.5,0.1,0.9,0.1,1.4c0.1,1.9,3.1,1.9,3,0c-0.2-3-0.6-5.9-1.3-8.7c3-2,5.4-4.8,5.4-8.6
								c0-1.9-0.9-3.7-2.2-4.9c0.6-0.1,1.2-0.2,1.7-0.3c3.8-1,6.2-5.9,4.6-9.6c-1.1-2.4-3.1-3.7-5.3-4.2c2.1-0.7,3.7-1.8,3.9-4.1
								c0.3-3.2-2.8-5.7-5.8-7c1-0.2,2-0.6,3-1.3c3.8-2.6,3.4-8,1.6-11.7c-1.3-2.7-3.8-4.2-6.5-3.9c0-0.3-0.1-0.6-0.2-0.9
								c-1.7-4.3-8.7-1.3-10.8,1c-0.5,0.5-0.8,1-1.2,1.6c-1.5-2-3.9-3.3-6.6-3.3c-5.9,0.2-8.4,6.2-9.6,11.1c-0.1,0.3,0,0.6,0,0.8
								c-0.5-0.2-1-0.4-1.5-0.4c-2.4-0.3-5.9-0.7-8,0.8c-2.3,1.6-3.8,4.5-4.8,7.1c-0.3-0.4-0.6-0.9-0.8-1.3c-1.1-1.7-2.4-3.6-4.7-3.4
								c-4.4,0.4-7.9,6.7-8,10.9c-1.7-5.2-5.9-10.6-10.2-6.1c-1.1-1.4-3.3-2.2-5.6-2.8c-0.3-0.2-0.7-0.3-1.2-0.3c-0.1,0-0.1,0-0.2,0
								c-0.8-0.2-1.4,0.3-1.7,0.9c-2,1-4.3,2.2-5.6,3.8c-1.1,1.3-0.8,2.7-0.3,4.1c-2.4-0.8-5.1-0.9-7.5-0.1c-0.2-1.7-1.3-3.3-2.4-4.7
								c-1.4-1.8-3.1-4.1-5.6-3.9c-1.7,0.2-3.5,1.1-5.1,1.6c-1.3,0.4-2.8,0.8-4.1,1.5c-0.9,0.5-1.5,1.2-1.9,1.9c-0.6-0.3-1.3-0.4-2.1-0.5
								c-2.5-0.3-6-0.7-7.9,1.2c-1.8,1.8-2.7,5.2-3.2,7.8c-1.7-1.9-3.9-3.1-6.8-3.1c-1.5,0-1.8,1.8-1,2.6c-0.8,0.7-1.5,1.5-2.2,2.3
								c-0.2-0.1-0.4-0.2-0.7-0.3c-1.8-2.1-5.7-3-8.3-2.9c-3.8,0.2-6.1,3.4-6.3,6.8c-3.5-1-8.2-0.3-10.1,3c-2,3.6-0.2,7.9,2.9,10.2
								c0.2,0.1,0.3,0.2,0.5,0.4c0,0.1,0,0.2,0,0.3c0.3,3.2,0.5,6.4,0.8,9.7c0.2,1.9,3.2,1.9,3,0c-0.2-2.8-0.5-5.6-0.7-8.4
								c0.8,0.3,1.7,0.5,2.5,0.6c-0.3,1-0.5,2-0.4,3.1c0.2,2.3,2.3,3.7,4.1,4.7c0.9,0.5,1.8,0.8,2.8,0.9c-0.1,1.8-0.2,3.7-0.4,5.5
								c-0.1,1.9,2.9,1.9,3,0c0.1-1.9,0.3-3.7,0.4-5.6c2.2-0.5,4.3-1.8,5.6-3.9c0.8-1.2,1.2-2.7,1.4-4.2c0.3,0.2,0.6,0.2,1,0.1
								c-0.8,1.8-1,3.8,0.1,5.7c0.8,1.3,1.8,2.7,2.9,4.1c-1.7-0.4-3.5-0.6-4.7,1c-1.3,1.7-1.7,5.2-1.8,7.7c-0.2-0.2-0.5-0.4-0.8-0.6
								c-0.6-0.4-1.1-0.3-1.5,0c-0.4-0.3-0.9-0.3-1.5,0c-1.1,0.7-2.3,1.4-3.5,2.2c-0.1-0.4-0.2-0.8-0.3-1.2c-0.6-1.8-2.2-2.6-3.9-3.1
								c-2.2-0.7-5.1-1.6-7.5-1c-3.5,0.9-6.6,5.4-5.9,9.1c-1.8-0.1-3.6,0.2-5.2,1.1c-4.8,2.8-4.4,7.4-2.1,11.2c-2.8,0.3-5.2,3.3-5.8,5.8
								c-0.8,3.5,0.1,9.5,3.8,11.1c0,0.2,0,0.3,0,0.5c0.4,1.6,0.7,3.2,1,4.8c0.1,0.8,1.1,1.2,1.8,1c0.9-0.2,1.2-1,1-1.8
								c-0.2-1.4-0.5-2.9-0.9-4.3c0.8-0.2,1.5-0.6,2.1-1.1c0,0.7,0.1,1.5,0.2,2.2c0.6,2.5,2.4,4,4.6,4c0,1,0,2,0.1,3.1
								c0.1,0.8,0.6,1.5,1.5,1.5c0.7,0,1.6-0.7,1.5-1.5c-0.1-1.3-0.2-2.6-0.1-3.8c0-0.1,0-0.2,0-0.3c0,0,0,0,0.1,0c1.2-0.9,2.2-1.9,2.9-3
								c0.9,2,2.2,3.9,4.2,4.6c0.3,1.1,0.4,2.2,0.5,3.3c0.1,0.8,0.6,1.5,1.5,1.5c0.8,0,1.6-0.7,1.5-1.5c-0.1-1.1-0.3-2.1-0.5-3.2
								c1.8-0.8,3-3,3.7-5.1c0.7,0.9,1.6,1.7,2.7,2.2c0,0.2-0.1,0.4,0,0.7c0.3,1.4,0.5,2.7,0.7,4.1c0.2,1.9,3.2,1.9,3,0
								c-0.1-1.5-0.4-2.9-0.7-4.4c5.2-0.8,6.4-7.1,4.8-12.2c1.4,0.5,3,0.8,4.3,0.8c0,1.5,0,3.1-0.1,4.6c-0.1,1.9,2.9,1.9,3,0
								c0.1-1.7,0.2-3.5,0.1-5.2c2.1-1.1,3.2-3.6,3.2-6.1c0.8,0.5,1.8,0.8,2.9,0.9c0.1,1.2,0.2,2.3,0.3,3.5c0.2,1.9,3.2,1.9,3,0
								c-0.1-1.2-0.2-2.4-0.3-3.7c0.2,0,0.4-0.1,0.5-0.1c3-0.9,5.8-4.6,5.5-7.9c0.9,0.3,2,0.5,3,0.6c0.1,1,0.1,2.1,0.1,3.1
								c-0.1,1.9,2.9,1.9,3,0c0-1.2,0-2.3-0.1-3.5c1.5-0.5,2.8-1.4,3.6-3c0.4-0.7,0.6-1.7,0.8-2.6c1,0.4,2.1,0.6,3.1,0.5
								c0.2,1.4,0.2,2.8,0.1,4.2c-0.1,1.9,2.9,1.9,3,0c0.1-1.7,0-3.4-0.2-5c0.6-0.3,1.1-0.7,1.5-1.2c0.8-0.8,1.4-2,1.8-3.2
								c0.1,0.1,0.2,0.3,0.3,0.4c0.6,0.7,1.3,1,2.1,1v3c0,1.9,3,1.9,3,0v-4.2c1.6-1.3,3.2-3.4,4.2-5.7c0.1,0.1,0.2,0.2,0.3,0.3
								c0.8,0.8,1.8,1.3,2.7,1.5c0.1,1.4,0.2,2.8,0.1,4.3c0,1.9,3,1.9,3,0c0-1.5,0-3.1-0.1-4.6c0.9-0.4,1.6-1.1,2.2-2.1
								c0.6-0.9,0.9-1.8,1.1-2.7c0.4,0.8,0.9,1.6,1.6,2.3c1.8,1.7,4.4,2.4,6.7,1.9c-0.5,1.1-0.8,2.2-0.7,3.3c0.1,1.7,1.1,3,2.3,4.1
								c-3.4,1.3-6.3,5.3-6.4,8.7c-0.1,2.3,1.4,4.6,3.4,6.3c-0.9,0.1-1.6,0.5-2.4,1.5c-1.5,2-3,4.7-3.2,7.3c-0.3,3.5,2.2,8.6,6,9.4
								c-1.8,2.6-4.2,5-3.8,8.3c0.2,1.9,1.2,2.8,2.5,3.2c-1.4,1.4-2.5,3.5-2.8,5.4c-0.7,3.3,1,7.2,4.4,8c-1.7,3.3-3.2,7.9-1.7,11.5
								c1,2.5,4.4,4.7,7.2,4.9c-0.4,0.8-0.6,1.7-0.7,2.7c-0.3,2.8,1.7,4.3,4.1,4.9c0,0.2,0,0.4,0,0.6c0.6,2.3,0.3,4.8,0.1,7.2
								c-0.1,1.9,2.9,1.9,3,0c0.2-2.5,0.4-4.9-0.1-7.4c0.2,0,0.5,0,0.7,0c2.9-0.1,5.1-1.6,6-3.9c0.8,1.2,1.7,2.3,2.9,2.9
								c0.4,0.8,0.5,1.8,0.6,2.6c0.2,1.2,0.4,2.3,0.5,3.5c0.2,1.9,3.2,1.9,3,0c-0.1-1.4-0.3-2.9-0.6-4.3c-0.1-0.5-0.2-1-0.3-1.6
								c0.1,0,0.1,0,0.2,0c1.6-0.5,2.7-1.6,3.4-2.9c0.3,0.4,0.7,0.8,1.1,1.1c0.7,0.6,1.6,1.1,2.5,1.4c-0.1,2.3-0.1,4.6,0.2,7
								c0.3,1.9,3.2,1.1,2.9-0.8c-0.3-1.9-0.2-3.8-0.1-5.7c1.3-0.1,2.6-0.6,3.6-1.6c0.3-0.3,0.4-0.6,0.4-1c1,1.2,2.5,2.1,4.2,2.2
								c0.3,1.6,0.5,3.1,0.8,4.7c0.3,1.9,3.2,1.1,2.9-0.8c-0.3-1.5-0.5-3-0.8-4.5c1.9-0.9,3.3-2.7,3.6-4.8c0-0.2,0-0.4,0-0.6
								c0.8,0.5,1.7,0.8,2.7,1c-0.2,2.3-0.3,4.5-0.3,6.8c0,1.9,3,1.9,3,0c0-2.3,0.1-4.5,0.3-6.8c0.6-0.1,1.2-0.3,1.9-0.5
								c2.1-0.8,3.3-2.5,3.8-4.5c0.7,0,1.3,0,1.9-0.1c-0.2,2.6-0.3,5.1-0.3,7.7c0,1.9,3,1.9,3,0c0-3,0.1-6,0.3-9c0,0,0-0.1,0-0.1
								c0.8-0.6,1.5-1.4,2-2.2c1.1,1.4,2.6,2.3,4.5,2.4c0,0.9,0,1.9,0.1,2.8c0.2,1.9,3.2,1.9,3,0c-0.1-1.1-0.1-2.3-0.1-3.4
								c3.3-1.6,4.5-7.8,4.1-10.9c-0.2-2-1.1-3.7-2.3-5.1c0.1,0,0.2,0.1,0.3,0.1c1.9,0.5,3.7,0.3,4.5-1.7
								C3069.4,933.8,3068.6,929.5,3068.3,926.4z M2901.5,855.1c-0.4,0.4-0.9,0.8-1.4,1.2c-0.2,0.2-0.4,0.4-0.6,0.7
								c-0.9-0.2-2.1-0.9-2.7-1.2c-2.3-1.3-4.4-3.8-3.4-6.5c0.9-2.3,4-2.7,6.2-2.2c1.9,0.5,2.1,1.6,2.1,3.4c0.1,1.4-0.1,2.7-0.3,4
								C2901.4,854.7,2901.4,854.9,2901.5,855.1z M2912.5,863.2c-1.9,2.9-5.3,3.2-8.1,1.5c-3.3-2-1.2-5.8,1.2-7.5c1.7-1.1,3.6-1.4,5.6-1.1
								c0.1,0.2,0.3,0.3,0.5,0.4C2914.2,857.9,2913.8,861.2,2912.5,863.2z M2913.5,843.5c-0.1,0.3-0.1,0.6,0,0.8c-0.3,2-0.9,3.9-2,5.5
								c-0.8,1.1-2.9,3.3-4.4,1.8c-0.4-0.4-0.8-1.9-1.1-2.5c-1.3-2.1-3-4.6-1.3-7.1c1.2-1.8,3.2-1.8,5.1-1.4
								C2911,840.9,2914.2,841.4,2913.5,843.5z M3044.3,915c7.1,0.7-0.6,16.4-5,9.6c-0.5-0.7-0.8-1.4-1-2.2c0-0.2-0.1-0.4-0.2-0.6
								c-0.8-3.5,1.1-6.9,4.9-8C3043.1,914.4,3043.5,914.9,3044.3,915z M3043.5,904.1c-1,1.7-2.1,3.3-3,5c-0.9-1.6-1.8-3.2-2.6-4.8
								c-0.8-1.4-2.1-3.3-2.1-4.9c-0.1-2.6,2.1-4,4.4-3.4C3044.6,897.1,3045.7,900.3,3043.5,904.1z M3029.4,916c-0.3-0.2-0.7-0.3-1.2-0.1
								c-2.2,0.7-3.3-4.3-2.9-5.4c0.4-0.9,1.4-2.1,2-2.9c0.7-1.1,1.5-2.6,2.5-3.5c2.2-1.9,3.3-0.8,4.2,1.1c1.1,2.3,4,6.6,2.3,9.1
								C3035.2,916,3032,916.1,3029.4,916z M3035.4,923.8c0,0.3,0,0.7-0.1,1c-0.5,2.3-2.8,5.3-4.2,7.2c-0.4,0.8-1,1.5-1.6,2.1
								c-1.1-0.3-2.2-0.5-3.2-0.8c-0.7-0.6-0.7-3.9-0.9-4.8c-0.5-3.3,0.3-5.8,1.9-8.4c2.6-0.5,6.2-0.3,7.5,1.7
								C3035,922.4,3035.2,923.1,3035.4,923.8z M3024.9,866.8c-2.8,0.4-5.2-1.7-6.6-4.3c0-2-0.5-4.1-1.2-5.6c0,0,0-0.1-0.1-0.1
								c0.3-2.8,2.1-5,6.2-4.5C3031.5,853.2,3027.6,861.7,3024.9,866.8z M3028.3,880.6c0.5,0.5,1,0.8,1.6,1.1c0.3,0.2,0.7,0.5,1.1,0.8
								c4.3,3,3.9,7.3,1.5,11.4c-0.8,1.4-2.4,4.4-4.8,2.7c-0.7-0.5-0.9-3.1-1-4.7c0-0.3,0-0.5,0-0.8c0-0.5-0.1-1-0.2-1.5
								C3026.2,886.4,3026.2,883,3028.3,880.6z M3020.8,879.4c-3-1.8-0.1-6.4,2.3-7.8c0.1,0.2,0.3,0.4,0.6,0.5
								C3027.1,873.6,3024.3,881.4,3020.8,879.4z M3017.4,885.2c0.5-0.3,1.1-0.5,1.6-0.8c1.7,1.1,3.4,2.7,4.2,4.3c0.2,0.4,0.3,0.8,0.4,1.2
								c0,0.4,0.1,0.7,0.1,1.1c0,0.3,0.1,0.6,0.1,0.9c0,2.3-0.9,4.8-2.2,6.1c-3.1,2.9-6-0.2-7.4-3.2c-1.6-3.4-1-7.1,1.5-9.8
								C3016.1,885.4,3016.8,885.4,3017.4,885.2z M3016.2,880.2c-0.5,1.1-1.4,1.7-2.3,2.1c-1.9,0.2-4.1-0.6-3.8-2.9c0.2-1.2,1-2.5,2.1-3.5
								c0.1,0,0.2,0.1,0.4,0.1C3015,876.2,3017.6,877.3,3016.2,880.2z M3011.3,865.5c-1.3-0.3-3-1.8-4.2-2.5c-1.5-0.8-3-1.6-4.6-2
								c0.8-2.7,2.4-5.5,4.4-7.2c1.9-1.6,3.1-1.3,4.8,0.4c0.8,0.8,1.5,1.8,2.1,2.8c0,1.9,0.5,3.9,1.3,5.7
								C3014.5,863.9,3012.2,865.7,3011.3,865.5z M3007.2,869.5c1.2,0.9,2.9,1.7,2.6,3.4c-1.3,0.7-2.6,1.6-3.6,2.7c-2.2-0.1-4.2-2-4.8-4
								c-0.7-2.3-0.7-4,0.8-5.4C3003.9,867.2,3005.6,868.3,3007.2,869.5z M3002.7,880.5c-0.1,1.3-1.1,2.9-1.9,3.9
								c-0.1,0.1-0.2,0.3-0.3,0.4c-0.5,0.5-1,1.1-1.5,1.7c-0.2,0.2-0.3,0.3-0.5,0.4c-0.1,0.1-0.1,0.1-0.2,0.1c-1.2-1.1-3-1.7-5.1-2.1
								c-0.1,0-0.2,0-0.3,0c-1-0.8-2.7-1.8-3.2-2.8c-1-2-0.3-4.3,1.2-5.7c2.4-2.3,5.8-2.7,8.4-0.9c0,0.4,0.2,0.8,0.6,1.1
								C3001.6,877.8,3002.9,877.7,3002.7,880.5z M2998.4,905c1.8,1.1,3.6,2.1,5.4,3.2c0.8,2.5,1.6,5,2.4,7.6c0.5,1.6,2.2,4.7-0.9,5.6
								c-2.1,0.6-3.3-1.7-4.5-3c-1-1.1-3.6-2.6-4.3-3.7C2994.7,911.7,2996.8,907.9,2998.4,905z M2999.9,927.9c0.1-0.2,1.1-2,1.6-2.8
								c2.9,0.4,4.2,1.6,5.8,4.2c1,1.7,3.6,8.1-1.2,7.4c-1.7-0.3-4.7-2.6-5.8-3.8C2998.8,931.3,2999.2,929.6,2999.9,927.9z M3003.4,903.1
								c-2.5-1.8-3.5-6.4-3.3-9.7c0.1-0.7,0.2-1.3,0.2-1.9c0.3-1.3,0.7-2.4,1.3-3.3c0.4-0.4,0.8-0.9,1.1-1.3c0.7-0.7,1.6-1.3,2.6-1.7
								c2.4,2.2,4.2,5.8,4.9,8.9C3011.1,897.8,3009,907.3,3003.4,903.1z M3011.1,906.2c0.8-1.2,1.7-2.3,2.8-3.1c4.3,0.9,8.6,2.7,6.8,8.1
								c-0.4,1.3-1.5,3.1-3.2,2.8c-0.3-0.1-0.6-0.6-0.9-0.6c-0.7-0.1-1.2-0.4-2-0.1c-0.8,0.3-1,1.3-1.7,1.7c-2,1.2-2.4-1.9-2.7-3.5
								C3009.9,909.5,3010,908,3011.1,906.2z M3012.3,919.6c0.2,0.1,0.5,0.1,0.8,0c3.9-1.1,6.3,3.6,7.8,6.4c0.5,0.9,1.1,2,1.5,3
								c0.1,0.9,0.2,1.8,0.4,2.7c-0.3,2.5-3.1,2.5-5.1,1C3014.1,930.1,3006.8,922.5,3012.3,919.6z M3022.8,901.9c0.2-0.2,0.7-0.9,1-0.9
								c0.4-0.1,0.8,0.3,1.2,0.5c0.2,0.1,0.3,0.2,0.5,0.2c0.1,0.5,0,1.1-0.3,1.5c-0.5,0.8-1.1,0.8-1.9,0.4
								C3022.5,903.2,3022.2,902.7,3022.8,901.9z M3043,889.6c-3.3,3.5-4.7-0.9-4.9-3.6c-0.2-2.4,0.4-4.7,2.4-6.2
								C3045.2,879.4,3045.7,886.7,3043,889.6z M3039.9,874c-1.1,1.1-2.3,2.2-3.6,3.1c-1,0.7-2.8,2-4.1,2.1c-0.3,0-0.5,0-0.8,0
								c-0.5-0.4-1-0.8-1.4-1.3c0-0.1,0.1-0.2,0.1-0.4c0.2-3.2-0.4-7.6,2.4-9.7c1.3-1,3.4-2.1,5-2c2.7,0.1,4,1.9,4.3,4.2
								C3042.1,872.3,3041.5,872.4,3039.9,874z M3036.9,861.5c-0.8,0.1-1.6,0.3-2.4,0.4c-1.1-0.8-1.9-1.8-2.3-3c-0.2-0.3-0.3-0.6-0.4-1
								c0-0.2,0-0.4,0-0.7c-0.7-2.8-0.4-5.6,1.1-8c0.7-1.1,2-3.3,3.7-1.7c0.6,0.6,1,2.9,1.1,3.8c0.1,0.7,0.4,2.4,0.6,4.3
								c-0.5,0.9-0.9,1.9-1.2,2.9C3036.8,859.5,3036.6,860.5,3036.9,861.5z M3048.3,859.8c-0.6,1.2-1.4,2.1-2.4,2.9
								c-0.2-0.6-0.6-1.1-1.4-1.2c-1-0.1-2.7,0.3-3.6-0.1c0.4-1.5,0.4-3.3,0.4-5c1.2-1.7,2.9-3,5.1-2.5
								C3048.9,854.6,3049.3,857.6,3048.3,859.8z M3052.6,842.7c1.3,1.5,1.1,3.1,0.2,4.7c-1.1,1.8-2.9,2.1-4.8,2.1c-1.1,0-2.3,0-3.4-0.2
								c-1.9-0.3-2.6-0.3-2.8-2.4c-0.2-2.3,1.6-4.2,3.3-5.7C3047.6,840.7,3050.8,840.6,3052.6,842.7z M3051.6,833.4c0,1.5-2.6,2-3.6,2.3
								c-2.1,0.5-4.1,1-6,2c-0.2-0.2-0.4-0.4-0.8-0.5c-2.6-0.7-3.5-6.3-0.9-7.4c2.5-1.1,6-0.4,8.3,0.8
								C3049.5,831.1,3051.6,832.2,3051.6,833.4z M3044.5,815.3c0.3-0.4,0.7-0.9,1-1.3c1.4-1,3.2-1.2,4.7,0.6c1.5,1.8,2.2,5.8,1.1,7.8
								c-2,3.3-6.4,2-8.3-0.6c-0.2-0.3-0.5-0.4-0.7-0.5c0.1-0.8,0.2-1.7,0.3-2.5C3043.1,817.6,3043.7,816.4,3044.5,815.3z M3035.3,822.9
								c0.2-0.8,0.4-1.5,0.5-2.3c0-0.1,0-0.1,0-0.2c0.3-1,0.5-2.1,0.6-3.1c0-0.3,0-0.6,0-0.9c0.4-2,1.2-3.8,3.1-4.9c1.9-1.2,4-1.6,3.4,0.7
								c-1.7,1.5-2.6,3.5-3.1,5.6c-0.1,0.3-0.3,0.6-0.4,0.9c-0.8,2.2-1.2,4.4-1.2,6.6C3035.3,825.3,3034.3,824.5,3035.3,822.9z
								M3034.2,830.1c1.3,0.9,1.1,4.1,1.4,5.4c0.2,1,0.7,1.6,1.2,2.5c0.3,0.7,1.5,2.7,0.8,3.4c-0.2,0.2-1.1,0-1.5,0.1
								c-1.6,0.6-2.9,0.6-4.4,1.7c-0.9,0.7-1.5,1.6-2.3,2.4c-0.3,0.6-0.9,1.1-1.6,1.2c-1.3,1.2-1.7,0.8-1.1-1c-0.2-1,0.2-2.4,0.3-3.4
								c0.2-2.7,0.5-5.3,0.9-8c0.4-2.6,0-2.5,2.5-3.9C3032.4,829.4,3032.6,829.5,3034.2,830.1z M3026.2,812.3c3.7-1.9,7,1,7.1,4.5
								c0,0.2-0.1,0.4-0.1,0.6c-0.1,1-0.5,2.2-0.9,3.5c-0.8,1.7-1.9,3.4-3.2,4.6c-2,1.7-3.9,2-5.7-0.2c-0.9-1.1-1.1-3.8-1.4-5.2
								c0-0.2-0.1-0.4-0.2-0.6C3022.5,816.8,3023.5,813.7,3026.2,812.3z M3023.3,836.2c1.4,3.2-0.5,8.3-3.1,10.1c-1,0.7-2.1,1.2-3.1,2
								c-0.2,0.2-1.6,1.8-1.7,1.9c-3,0.7-1.8-9.3-1.4-11c0.4-1.1,0.9-2.2,2.1-3.2C3018,834.5,3021.9,833,3023.3,836.2z M3007.8,832.6
								c0.1-2.1,0.7-4.1,1.6-6c0.3-0.5,0.2-1.1-0.1-1.5c0.1-0.2,0.3-0.4,0.4-0.5c1.5-1.5,3.1-1.6,5-1.5c2.1,0,4,0.5,4.8,2.6
								c1.3,3.3-1.2,4.6-3.7,5.7c-2.3,1-3.4,2.4-5.2,4C3008.2,837.8,3007.7,834.9,3007.8,832.6z M3006.3,841.5c0.1,0,0.1,0,0.2-0.1
								c0.5-0.3,1.1-0.5,1.6-0.7c0,0,0,0,0,0c2.2-0.2,2.4,3.7,1.9,5.5c-0.5,1.7-1.5,2.5-2.7,3.4c-1,0.4-1.8,1.2-2.6,2.1
								c-0.7,0.6-1.3,1.2-1.9,1.9c-0.7,0.8-1.7,2.4-2.8,3.5c0-1.5,0.2-3.1,0.2-4.4c0-0.2,0-0.4,0-0.6c0.1-0.7,0-1.4-0.1-2
								c0-0.7,0-1.4,0.1-2.1C3000.8,844.6,3003.4,842.8,3006.3,841.5z M2993.8,834.9c-0.1-2.3,2.9-10,6.8-7c2,1.5,3.8,6.1,2.9,8.3
								c-0.8,2-4.1,5.2-6.3,5.6c-2.7,0.6-3.6-3.2-4.1-5.6C2993.6,835.9,2993.9,835.5,2993.8,834.9z M2993,845.6c1.6,1.1,3.5,2.3,4.1,4.1
								c0,1,0.1,2,0.1,2.9c0,0.1,0,0.2,0,0.4c-0.5,2.1-2,4-4.1,4.3c-1.3,0.2-2.9,0.2-3.8-0.9c-0.1-1.5-0.5-3-1.1-4.5
								C2988.3,849.2,2990.7,847.1,2993,845.6z M2980.5,833.5c0.4-0.6,0.6-1.2,0.8-1.8c0.3-0.1,0.6-0.3,0.8-0.6c3.7-5.2,6.6,7.1,6.6,8.7
								c0,2-0.5,6.1-4.2,5c-0.9-0.3-1.7-1.4-2.6-1.8c-1.9-0.8-3.5-0.6-4.9,0.2C2977.7,839.6,2978.6,836.8,2980.5,833.5z M2955.6,839.6
								c1.8-3.1,6-3.8,9.2-2.9c0.1,0.2,0.3,0.3,0.5,0.5c4.7,2.7,1.5,6.3-0.9,9.3c-1.2,1.5-2,2.8-3.8,3.5c-2.5,1-2.5,0.6-3-1.7
								c-0.1-0.5-0.2-0.9-0.3-1.4c-0.2-2.5-0.8-4.8-2.1-6.7C2955.4,840,2955.5,839.8,2955.6,839.6z M2944.6,830.4c1.6-0.6,3.2-1.2,4.8-1.6
								c2.2-0.5,3.9,0.9,5,3c0.6,1.2,0.7,3.5-0.3,4.7c-0.5,0.6-1.3,0.8-2.1,0.8c-1.3-0.6-2.5-0.6-3.6-0.4c-0.6-0.1-1.2-0.2-1.8,0
								c-1.1,0.2-2.3,0.9-2.9,1.8c-0.2,0.2-0.6,0.9-0.8,1c0.5,0.7,0.4,0.7,0,0.1c-0.3-0.3-0.5-0.7-0.7-1c0-0.4-0.1-0.8-0.1-1.2
								c0.3-1.3,0.3-2.7-0.1-4C2942.1,832.1,2942.8,831,2944.6,830.4z M2941.6,850.7c1,4.4-1.8,8.8-3.4,12.7c0,0.1-0.1,0.2-0.1,0.2
								c-1-2.2-2.7-4.3-4.2-5.6c-0.7-0.6-1.5-1.1-2-1.8c-2.2-2.7-1.3-6.8,1.6-8.4C2936.9,845.8,2940.7,846.5,2941.6,850.7z M2928.8,843.2
								c0.1-3.7,0.9-10,5.2-10.5c1.8-0.2,3.9,0.2,4.8,1.6c0.1,0.9,0.1,1.9,0.2,2.9c-0.4,1-1.1,1.9-1.7,2.4c-0.5,0.4-1,0.7-1.5,1
								c-0.5,0-0.9,0.2-1.3,0.6c0,0.1-0.1,0.1-0.1,0.2c-0.8,0.5-1.4,1.1-1.9,2c-0.1,0.2-0.1,0.3-0.1,0.5c-0.3,0.3-0.6,0.6-1,0.9
								c-0.5,0.4-1.4,1.1-2.1,0.9c-0.1-0.8-0.3-1.5-0.5-2.2C2928.8,843.4,2928.8,843.3,2928.8,843.2z M2916.5,848.5
								c0.5-4.4,2.1-6.8,5.7-9.2c1.7,0.8,2.9,2.4,3.6,4.2c-0.1,1.6,0,3.2,0.6,4.4c-0.4,3-3.2,5.7-6.1,6.7
								C2915.9,856.1,2916.1,851.6,2916.5,848.5z M2884.4,908.8c-0.1,1.6-2.2,3.3-3.8,2c-0.7-0.5-1-1.7-1.2-2.4c-0.6-2.2-1-5,0.6-6.9
								c0.8-1,2.1-2.2,3.5-0.5c0.4,0.5,0.4,1.5,0.6,2.1c0.4,1.7,0.6,3.3,0.7,5C2884.5,908.3,2884.4,908.5,2884.4,908.8z M2883.5,893.3
								c-0.8-3.4,1.8-5.9,5.1-5.8c3.9,0.1,6.7,3.1,5,7.1c-0.5,1.3-1.3,2.6-2.3,3.7c-0.3,0.1-0.5,0.3-0.7,0.7c-0.1,0.2-0.2,0.4-0.4,0.5
								c-0.3,0-0.6,0.1-0.8,0.4C2887.3,900.2,2883.8,894.5,2883.5,893.3z M2894.6,910c-0.1,1.2-0.4,2.2-1,3.2c-0.7,1.1-3.3,4.4-5,2.5
								c-1.3-1.5-0.2-5.1,0.3-6.7c0.5-1.7,1.3-3.3,2-4.9c2.2-0.2,3.5,0.8,3.7,3.1C2894.6,908.1,2894.6,909.1,2894.6,910z M2892.9,885.3
								c-1.7-1.6-0.6-3.8,0.6-5.1c1.4-1.6,2.8-1.9,4.8-1.6c2.2,0.4,5.4,0.7,5.3,3.4c-0.1,2.2-2.1,3.6-3.9,4.6c-0.1,0-0.3,0-0.4,0.1
								C2897.5,887.3,2894.3,886.7,2892.9,885.3z M2904.4,911.1c-0.2,2.1-1,6.6-3.8,5.4c-2.3-1-2.9-4.9-3-6.9c-0.1-3.5,1.8-5.1,4.5-5.8
								c0.2,0.1,0.3,0.2,0.6,0.2c1,0.2,1.4,1.2,1.7,2.5c-0.1,1.1-0.1,2.2,0.1,3.3C2904.4,910.4,2904.4,910.8,2904.4,911.1z M2902.7,898.4
								c-0.9,0.1-1.7,0.2-2.5,0.6c-0.4,0.2-0.8,0.5-1.2,0.8c-1.1,0.7-1.2,1.1-2.1-0.1c-0.9-1.3-0.1-1.8,0.3-3.2c0.7-2.1,0.6-5.8,3.1-6.5
								c0.1,0.1,0.2,0.1,0.3,0.2c2.1,1.1,4,3.5,4.4,5.8C2905.2,898,2904.3,898.2,2902.7,898.4z M2907.2,890.9c-2.7-0.4-2.3-4.3-0.9-5.7
								c1.5-1.5,3.7-2.7,5.5-3.9c0,0,0,0,0,0c0.9,0.6,1.2,1.8,1.2,3.1c-0.2,0.3-0.2,0.6-0.1,1.1c0,0.1,0,0.1,0,0.2
								c-0.1,0.8-0.4,1.6-0.7,2.2c-0.3,0.6-0.8,1.1-1.3,1.6c-0.5,0.2-1,0.5-1.5,0.9c-0.1,0.1-0.2,0.2-0.3,0.3
								C2908.5,890.8,2907.8,890.9,2907.2,890.9z M2910.1,914.1c-1.4-0.6-2.2-2.6-2.6-4.2c0.1-1.1,0-2.3-0.1-3.5c0.4-1.8,1.5-3.2,2.7-4.6
								c0-0.1,0.1-0.1,0.1-0.2C2916.6,902.5,2915.5,916.6,2910.1,914.1z M2915,896c-1.3,0.5-2.9,0.6-4.1,0.4c-0.6,0.2-1,0-1.2-0.5
								c-0.5-0.3-0.6-0.6-0.1-1c0.1-0.5,0.8-1.3,1.1-1.7c0.1-0.1,0.2-0.2,0.3-0.3c0.4-0.2,0.9-0.5,1.3-0.8c0.1,0,0.2-0.1,0.3-0.1
								c0.2,0.2,0.6,0.3,1,0.3c1.1-0.1,2.2-0.2,3,0.7c0.4,0.5,0.5,0.9,0.4,1.3C2916.3,894.7,2915.6,895.3,2915,896z M2923.1,902.7
								c-0.4,0-0.8,0.1-1.1,0.3c-1.8,0.2-4.8-0.7-5.4-2.1c-0.3-0.7-0.1-1.7,0.5-2.5c0.9-0.4,1.7-1,2.2-1.8c1-0.2,1.2-1.1,1-1.9
								c1.6,0.2,3.1,0.9,3.9,2.4C2925.1,898.8,2925,901.8,2923.1,902.7z M2925.6,886.3c-0.3,1.9-1.3,2.8-3,2.5c-1.4,0.7-2.2,0.4-2.4-1
								c-2-2-3.1-6.3-3-8.8c0.1-1.1,0.2-3,0.7-4c-0.2-1.2,0.3-1.7,1.3-1.4c0.8-0.2,1.7-0.1,2.5,0.3c2.8,0.9,3.7,3.1,4.1,5.7
								c-0.1,1,0,2,0.3,3C2926.1,883.9,2926,885.1,2925.6,886.3z M2924,869.7c-0.8-1-1.7-2-2.4-3c-1.1-1.6-2.3-3.3-1.2-5.1
								c0.8-1.4,2.3-2.4,3.9-2.8c3.2-0.7,6.1,1.2,7.8,3.8c0,0,0,0,0,0c1,2.3,1.5,3.6-0.5,5.8c-0.7,0.8-1.8,1.6-2.6,2.3
								C2926.9,872.4,2925.4,871.5,2924,869.7z M2932.8,897.3c-2.1,0.4-4.4-0.2-4-2.8c0.3-2.1,1.7-3.7,3.4-4.6c0,0,0.1,0,0.1-0.1
								c1.8,0.2,3.7,0.9,4.1,2.4C2936.9,894.4,2934.8,896.9,2932.8,897.3z M2936.3,884.1c-0.2,0.3-0.3,0.7-0.5,1c-0.7,0.6-1.5,1.1-2.5,1.1
								c-2.3,0-3.7-1.9-4.3-3.9c-0.1-1-0.1-2-0.2-3c0.4-2.4,2.3-4,4.6-4.8c1.5-0.5,3.2-0.9,4.4,0.7c0.9,1.2,0.5,3.3,0.3,4.6
								c-0.1,0.6,0.1,1,0.4,1.4c0,0,0,0-0.1,0.1c0,0,0,0,0,0.1C2937.6,882.1,2936.9,883,2936.3,884.1z M2947,885.5c-0.2,3.3-2.5,4.9-5.7,4
								c-0.8-0.2-2.7-0.7-3-1.7c-0.1-0.4,0-0.8,0.2-1.3c0.9-1,1.6-2.1,2.4-3.2c0.9-0.8,2-1.4,3.3-1.7c0.4,0.4,0.7,0.7,1.2,1.1
								C2946.5,883.5,2947.1,883.9,2947,885.5z M2944,876.1c-2-1.2-3.2-3.9-4.4-5.9c0.6-2,1.2-4.7,2.9-5.6c3.3-1.9,5.8,3.1,6.3,5.5
								C2949.5,872.7,2947.9,878.4,2944,876.1z M2947.5,859.6c-1-0.5-1.5-1.3-1.5-2.3c-0.6-1.6-0.6-3.8-0.7-5.5c-0.1-1.5-0.2-3.1-0.2-4.7
								c0.1-1.6,0.7-5.7,2.6-7.1c1-0.1,2.1,0.1,3.3,0.2c0.3,0.2,0.7,0.5,1,0.8c-0.1,1,0.1,1.9,0.5,2.8c0.8,1.5,1.6,2.6,2,4.1
								c0,2.1-0.3,4.3-0.8,5.8c-0.1,0.3-0.1,0.5,0,0.8c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.6,0.8-1.1,1.6-1.6,2.5
								c-0.7,0.9-1.5,1.7-2.3,2.2C2949,861.1,2948.3,861.1,2947.5,859.6z M2954.6,883.7c-0.5-0.1-1.2,0.1-1.5,0.5
								c-2.6,0.4-4.3-1.9-3.6-4.5c0.5-1.9,1.6-3.8,2.7-5.4c0.1-0.1,0.1-0.1,0.2-0.2c0.6-0.9,2.3-0.1,2.9,0.4c0,0,0,0,0,0
								c0.1,0.3,0.2,0.6,0.4,1c0.3,0.8,0.7,1.7,1.2,2.6C2956.8,880.2,2956.3,882.6,2954.6,883.7z M2952.9,865.3c-0.2-2.5,0.9-4.9,2.1-7
								c0.2-0.3,0.5-0.7,0.7-1c1.5-1.9,4.4-3.1,4.9,0.2c0,0,0,0,0,0c-0.1,0.7,0,1.4,0,2.1c-0.2,1.8-1,3.4-2.1,4.9
								c-0.3,0.4-0.4,0.8-0.2,1.2c0,0-0.1,0-0.1,0.1C2956.3,867.1,2953.2,869.1,2952.9,865.3z M2965.3,876.9c-0.6,0.9-1.3,1.8-2,2.5
								c-1.5,1.4-1.6,0.9-2.4-0.5c-0.3-0.6-0.7-1.1-1.1-1.7c-0.2-1.4-0.6-2.7-1.4-3.7c-0.1-0.2-0.3-0.3-0.5-0.5c-1-5.1,4.3-4.7,7.1-2.9
								c0.3,0.2,0.7,0.3,1,0.2c0.1,1,0.4,2.1,0.8,3C2966.4,874.8,2965.7,876.3,2965.3,876.9z M2968.8,862.9c-0.3,0-0.6,0.1-0.9,0.3
								c-1.6,1.2-2,2.3-3.3,0c-0.5-1-0.9-2-1.1-3.1c0.2-1.1,0.2-2.3,0-3.4c0.4-2.3,1.7-4.7,3.6-6c1.3-0.9,4.2-2,5.2,0.1
								c0.4,0.9-0.5,3.6-0.7,4.5C2971.1,858,2970.1,860.5,2968.8,862.9z M2975.3,872.6c-0.2,0.6-0.6,1.6-1,2.1c-1.1,1.4-2.6,0.6-3.5-0.5
								c-0.3-0.3-0.6-0.7-0.8-1.1c0.4-2,0.2-3.8-0.8-5c0.6-1.7,2.2-1.9,3.9-1.7C2974.4,868.2,2975.9,870.3,2975.3,872.6z M2973.7,844.2
								c-0.6-0.2-1.1-3.7-1.3-4.3c-0.5-1.3-1.2-2.4-1.8-3.6c-1.2-2.2-2-4,0.1-6c0.9-0.8,2.2-1.5,3.5-2c1.8,0.4,4.4,1,4.6,2.1
								c0.1,1.1-2,3.4-2.5,4.4C2975.6,835.8,2973,844,2973.7,844.2z M2977.1,863.1c-1.2-3.8-0.1-7.1,0.1-11c0.1-1.5,0.2-3.5,1.3-4.7
								c0.6-0.2,1.2-0.3,1.8-0.3c0.2,0.2,0.3,0.3,0.6,0.4c1.8,0.7,3.3,3.3,4.2,5.5c0.2,1.2,0.5,2.9,1,4.2c0,1.1-0.2,2.2-0.7,3.2
								c-0.4,0.5-0.8,1-1,1.6c-0.1,0.1-0.2,0.3-0.3,0.4c-1,1.1-2.4,2.1-3.6,3C2978.1,867.3,2978.2,866.4,2977.1,863.1z M2987.1,874
								c-1.5,1-3.8,0.6-5.1-0.5c-3.7-3.2,1.5-5.2,4.3-3.6c0.3,0.4,0.7,0.8,1,1.1C2988,872,2988.3,873.2,2987.1,874z M2988,866.7
								c-0.8-1.4-0.9-2.6-0.5-3.5c0.2-0.3,0.4-0.7,0.6-1c0.9-1,2.5-1.5,4.3-1.3c0.1,0,0.3,0,0.4,0c1.9,0.4,3.5,1.5,4.3,3.4
								c1,2.6-0.2,5.8-3.1,6.4c-1.7,0.3-3.2-0.6-4.4-1.9c-0.2-0.3-0.4-0.5-0.5-0.8c-0.1-0.2-0.3-0.3-0.4-0.4
								C2988.4,867.3,2988.2,867,2988,866.7z M2988.2,888.1C2988.2,888.1,2988.2,888.1,2988.2,888.1l4.3,0.5c1.4,1.2,3,2.4,4.6,2.4
								c0.1,0.3,0.1,0.6,0.1,0.9c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.2c0,0.3-0.1,0.6-0.2,0.9c-0.4,1.5-1,4.4-2.4,5.9c0,0,0,0-0.1,0
								c0,0,0,0.1-0.1,0.1c-0.6,0.6-1.3,1-2.1,0.9c-2.1-0.1-5.3-2.8-6.5-4.2C2984.1,893.9,2985.7,889.7,2988.2,888.1z M2982.6,909.6
								c-0.1-2.3,1.5-6.3,3.9-6.7c3.3-0.5,5,2.6,5,5.5c0,0.2,0,0.4,0.1,0.5c-0.7,1.8-1.8,4-3.2,5.2c-0.3,0.1-0.6,0.3-0.9,0.5
								C2985,915.7,2982.7,912,2982.6,909.6z M2986.2,922.3c1.2-1.8,2.5-3.4,3.5-5.3c0.8-0.6,1.6-1.4,2.2-2.4c1.7,2.5,3.6,5.2,3.7,8.3
								c0.1,1.4-0.1,3.6-1.4,3.8c-1.1,0.2-2.6-0.3-3.8-0.2c-1.2,0.1-2.9,0.8-4,0.4C2983.7,926.1,2985.3,923.7,2986.2,922.3z M2984.5,935.3
								c0.5-2.3,1.9-4.4,4.4-4.4c1,0,2.3,0,3.2,0.4c2.1,0.9,1.4,2.4,0.9,4.1c-0.1,0.3-0.1,0.6,0,0.9c-0.6,0.6-1.2,1.4-1.7,2.2
								c-0.8,0.6-1.8,1.1-2.7,1.4C2985.5,940.8,2983.9,938.1,2984.5,935.3z M2992.9,956c-1,0.6-2.8-0.1-3.7-0.6c-3.1-1.7-2.9-5.1-1.9-8.2
								c0.2,0.5,0.6,0.9,1.3,0.9c1.1,0.1,2.2,0.4,3.1,1c0.3,0.2,0.6,0.5,0.9,0.7c0.6,0.6,1,1.2,1.2,2.1C2994.1,953,2994,955.3,2992.9,956z
								M2997.3,964.3c-0.9-0.1-2.6-0.3-3.2-1c-0.6-0.7-0.4-1.4-0.1-2.3c0.8-2.4,2.8-3.9,5.2-4.4c0.2,0,0.3-0.1,0.4-0.2
								c1.2,0.6,2.2,1.7,2.5,3.2C3002.7,962.9,3000.3,964.6,2997.3,964.3z M3000,950.4c-2.3-1.4-6.8-3-7.5-5.6c-0.3-1.2,0.1-2.8,0.9-4.2
								c0.9-0.8,1.7-1.6,2.3-2.5c1-0.7,2-0.9,3-0.6c2.1,0.7,6.3,4.5,6.1,6.8c-0.1,0.9,0.6,1.4,1.3,1.4c0,0.7-0.1,1.4-0.2,2.1
								c0,0.1,0,0.1,0,0.2c-0.2,1-0.5,2.5-1.2,3.2C3002.8,952.8,3001.5,951.4,3000,950.4z M3011.2,962.9c-3.1,3.3-5.9-4.2-5.2-6.5
								c0.4-1.3,1.2-2.1,2.2-3c1.2-1,1.8-0.8,3.1,0.1c1.5,1.1,1.6,2.5,1.8,3.8C3012.9,959.4,3012.5,961.5,3011.2,962.9z M3014.5,951.6
								c-3.6-2.6-6.2-6.5-4.7-11.4c0.7-2.2,2.3-4.1,5.1-1.8c1,0.8,1.3,2.9,1.6,3.9C3017.4,945.4,3018.1,950.1,3014.5,951.6z M3022.3,963.1
								c-1.5,1.3-4,0.4-5.2-0.8c-0.6-0.6-0.9-1.3-1-2c0.6-0.4,0.9-1.1,0.4-1.9c-0.1-0.1-0.1-0.2-0.2-0.3c0.2-0.5,0.4-1,0.6-1.6
								c0.2-0.4,0.3-0.7,0.5-1.1c0.4-0.5,0.9-0.6,1.5-0.2c0.7,0.1,1.8,0.6,2.4,1c1.8,1.3,1,3.7,0.6,5.5
								C3021.7,962.3,3021.9,962.8,3022.3,963.1z M3021.3,950.1c-0.2-2.5,1.4-5.4,2.5-7.6c0.2-0.3,0.4-0.7,0.6-1c0.2-1.2,0.7-1.3,1.5-0.5
								c0.6,0.2,1.4,1.1,2,1.7c0.1,1.9,0.7,3.8,2.3,5.4c0,0.9-0.2,1.9-0.5,2.9c-0.1,0.1-0.2,0.2-0.2,0.4
								C3027.6,955.6,3021.6,954.7,3021.3,950.1z M3029.5,963.4c-2.5,0-3.3-2.6-2.3-4.5c0.7-1.3,2-2.2,3.3-3
								C3032.7,958.2,3033.9,963.4,3029.5,963.4z M3033.5,947c-0.2,0-0.3-0.1-0.5-0.2c-0.2-1.9-1-3.6-2.3-5.2c0-0.5,0.1-0.9,0.2-1.3
								c0.4-1.6,1.2-3.1,1.9-4.5c2.2-4.1,7.5-5.4,7.8,0.6c0.1,1.9-0.1,3.8-0.5,5.6C3038.8,943.8,3035.8,947.6,3033.5,947z M3042.9,957.7
								c-2.1,1.6-5.9,0.8-7.2-1.2c-1.7-2.5-0.7-5.7,2.3-6.5c2.6-0.7,5.7,1,4.6,3.9c-0.5,1.2,0.7,1.9,1.7,1.8
								C3044,956.5,3043.6,957.2,3042.9,957.7z M3052.3,949.6c-0.5,1.5-2.9,4.5-5,4.1c-0.1-1.1-0.3-2.2-0.8-3.2c-0.3-0.6-0.8-0.8-1.3-0.8
								c-0.1-1.4,0-2.9,0.2-3.7c0.5-2.4,2.7-3.1,4.8-3.8c0.1,0,0.1-0.1,0.2-0.1c0.1,0.2,0.3,0.4,0.6,0.6c0.7,0.3,1,1.1,1.3,2
								c-0.2,1.3-0.2,2.6,0,4.1c0,0,0,0,0,0.1C3052.4,949.1,3052.3,949.4,3052.3,949.6z M3053.2,936.2c-1.4,2.1-2.4,1.5-4.1,1.7
								c-1.1,0.2-1.5,0.6-2.5,1c-0.7,0.8-1.5,0.9-2.6,0.3c-0.1-0.3-0.2-0.5-0.2-0.8c0.1-1.1,0.1-2.2,0-3.3c0.1-0.8,0.4-1.6,0.7-2.3
								c0.5-1.4,1.4-3.2,2.3-4.3c1.9-2.2,3.2-1.8,5.3-0.3c0.3,0.2,0.5,0.3,0.8,0.3C3054.3,930.8,3054.5,934.2,3053.2,936.2z M3053.1,921.4
								c-0.5,0-0.9,0.2-1.3,0.6c0-0.1-0.1-0.2-0.1-0.4l-0.2-1.1c-0.2-1.1-0.4-2.1-0.6-3.2c-0.4-2.2-2.2-6.4,1.3-6.6
								c2.8-0.2,3.5,3.1,3.3,5.3C3055.3,917.8,3054.1,919.8,3053.1,921.4z M3060.6,951.3c-0.6-0.1-1.2,0.1-1.6,0.6
								c-1.4-0.1-2.9-1.5-3.3-2.6c-0.1-0.1-0.1-0.3-0.2-0.4c0.2-1.4,0.2-3.1-0.1-4.6c0.7-2,2.3-3.9,3.8-5.4c2.1,0.8,3.3,2.9,3.5,5.1
								C3062.8,945.9,3062.3,949.8,3060.6,951.3z M3065.6,934.2c-0.9,2.1-2.4,1.1-3.9,0.4c-2.7-1.3-3.8-2.5-4.4-5.3
								c-0.7-3.1-1.1-6,0.1-8.8c0.9-1.5,1.8-1.7,3.8-0.6c1.3,0.7,2.9,2.3,3.5,3.6C3065.6,925.4,3066.5,932.1,3065.6,934.2z"
					/>
					<g>
						<path
							d="M1703,1034.2c-0.5-3.9-3.7-5.8-7.5-4.9c-1.7,0.4-3.8,1.5-5.1,3.1c-0.2-1.4-0.8-2.6-2-3.3c-0.5-0.3-1.1-0.3-1.5,0
									c-2.1-0.6-4.7,0.3-6.5,1.7c-0.5-0.8-1.3-1.3-2.4-1.4c-1.5-0.8-3-1.2-4.9-0.7c-0.6,0.2-1.1,0.4-1.6,0.7c-0.3-0.4-0.7-0.8-1.1-1.1
									c-1.4-1.9-4.8-2.7-7.3-2c-0.2-1-0.8-1.9-1.8-2.5c0,0,0,0,0,0c-2.1-2-5.6-4.6-8.1-2.5c-0.1,0.1-0.3,0.3-0.4,0.4
									c-0.6-1.3-1.6-2.4-2.8-3c-1.6-0.8-3.3-0.5-4.9,0.1c-0.2,0-0.3,0.1-0.5,0.2c0,0-0.1,0-0.1,0c-0.2,0.1-0.3,0.1-0.5,0.2
									c0,0-0.1,0-0.1,0.1c-0.7-1.4-1.9-2.5-3.2-3.4c-0.4-0.3-0.8-0.3-1.1-0.2c-0.6-0.3-1.2-0.6-1.9-0.6c-0.4,0-0.8,0-1.1,0.2
									c0-0.3-0.1-0.6-0.1-0.9c-0.6-3-3.1-4.8-6.1-3.8c-1.5,0.5-2.9,1.5-4,2.8c-0.2-0.6-0.6-1.3-1.1-1.8c0-0.3-0.2-0.5-0.4-0.8
									c-2.6-2.9-6.1-4.3-9.1-1.1c-2.4,2.7-2.9,7.3-0.8,10.4c0.9,1.3,2,2,3.3,2.3c-0.1,1.8-0.2,3.5-0.2,5.2c0,1.9,3,1.9,3,0
									c0-1.8,0.2-3.6,0.2-5.4c1.2-0.3,2.3-0.9,3.2-1.7c0.1-0.1,0.2-0.2,0.3-0.3c1,2.6,3.9,4.4,6.7,4.7c0.4,0,0.8-0.1,1-0.3
									c0.6,1.2,1.7,2.2,2.8,2.9c0.6,0.4,1.4,0.9,2.2,1.3c0.3,1.2,0.5,2.4,0.4,3.6c-0.1,1.9,2.9,1.9,3,0c0.1-1.1-0.1-2.2-0.3-3.3
									c0.6-0.2,1.2-0.6,1.6-1.1c0.1,0.2,0.2,0.3,0.4,0.5c1,1.2,2.3,1.7,3.7,1.8c0.4,1.7,1,3.6,0.5,5.2c-0.5,1.9,2.4,2.7,2.9,0.8
									c0.6-2.2-0.1-4.2-0.5-6.4c0.7-0.2,1.2-0.5,1.8-0.9c0.1,0.7,0.4,1.3,0.7,1.8c0.7,1,1.6,1.8,2.6,2.3c0,1.1,0,2.2,0,3.2
									c0,0.5,0.1,1,0.1,1.5c0.1,0.6,0.3,1.1,0.4,1.6c0.2,1.9,3.2,1.9,3,0c-0.1-1.1-0.4-2.1-0.5-3.2c-0.1-0.8-0.1-1.6-0.1-2.4
									c0.6,0,1.2-0.1,1.7-0.2c0.3,1,0.9,1.9,2,2.7c0.9,0.6,2.2,1.2,3.5,1.6c0.1,0.5,0.1,1,0.2,1.4c0.1,1,0.1,1.9,0.5,2.9
									c0.3,0.8,1,1.3,1.8,1c0.7-0.2,1.4-1.1,1.1-1.8c-0.4-0.9-0.4-1.6-0.4-2.5c0-0.2,0-0.4-0.1-0.6c0.9-0.1,1.7-0.4,2.3-0.8
									c0.8,1.3,2,2.4,3.5,3.1c0.1,0.9,0.3,1.8,0.2,2.8c-0.1,0.8,0.7,1.5,1.5,1.5c0.9,0,1.4-0.7,1.5-1.5c0.1-0.7,0-1.4-0.1-2.1
									c1.3-0.1,2.4-0.8,3.2-1.8c0.3,0.4,0.6,0.8,1,1.1c0.7,0.7,1.5,1.1,2.3,1.3c0.2,1.3,0.5,2.6,0.2,4c-0.5,1.9,2.4,2.7,2.9,0.8
									c0.3-1.1,0.3-2.2,0.2-3.3c0-0.6-0.2-1.2-0.3-1.8c1.3-0.5,2.5-1.5,3.3-2.8c0.2-0.4,0.4-0.8,0.6-1.3c0.6,1.5,1.7,3,2.6,4.1
									c1,1.3,2.2,1.5,3.5,1.6c0.2,1,0.5,2,0.5,3.1c0,1.9,3,1.9,3,0c0-1.2-0.3-2.4-0.5-3.6c0,0,0,0,0,0
									C1702.2,1041,1703.5,1037.9,1703,1034.2z M1616.9,1017.3c-0.6-1.7-0.3-3.8,0.7-5.3c0.7-0.9,1.5-1.6,2.7-1.2
									c0.9,0.3,1.6,1.2,2.2,1.8c0,0.3,0.1,0.5,0.4,0.8C1626.2,1017.3,1618.7,1022.2,1616.9,1017.3z M1629,1020.9c-1.4-1-2.2-2.8-1.3-4.5
									c0.7-1.3,1.9-2.5,3.3-3c1.5-0.6,2.2,0.4,2.4,1.9C1633.8,1018.1,1631.2,1020,1629,1020.9z M1641.3,1022.1
									C1641.3,1022.1,1641.3,1022.1,1641.3,1022.1c-0.1,0.4-0.2,0.8-0.3,1.2c0,0,0,0,0,0c-0.1,0.3-0.2,0.5-0.2,0.8
									c-0.2,0.6-0.5,1.8-1.1,2.1c-0.8,0.4-1.9-0.5-2.5-0.9c-2-1.3-2.7-2.6-1.5-4.8c0.4-0.7,1.1-2.2,1.9-2.3c0.9-0.1,1.8,1.2,2.4,1.8
									c0.3,0.3,0.7,0.5,1.1,0.4c0.1,0.1,0.1,0.2,0.1,0.3C1641.3,1021.1,1641.4,1021.6,1641.3,1022.1z M1646.9,1027.4
									c-1.9,0.1-3.1-1.4-3-3.3c0-0.1,0-0.2,0-0.3c0.1-0.4,0.2-0.7,0.3-1c0.2-0.4,0.6-0.8,1-1c0.4-0.1,0.8-0.3,1.1-0.4
									c0.6-0.1,1.2-0.1,1.8,0c0.1,0,0.2,0,0.3,0c0.7,0.2,1.3,0.7,1.6,1.7C1651,1025.5,1649.2,1027.3,1646.9,1027.4z M1660,1028.8
									c-0.1,0.4-0.3,0.7-0.6,1.1c-0.5,0.7-1.2,1.1-2,1.2c-0.7,0-1.7-0.5-2.3-0.9c-1.2-0.8-1.4-1.6-1.2-3c0.1-1.3,0.3-4,2.2-3.6
									c0.7,0.2,1.6,0.9,2.4,1.5c0.1,0.3,0.3,0.6,0.7,0.7c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.1,0,0.1,0.1l0.3,0.3c0,0,0,0,0,0l0,0
									C1660.3,1027.1,1660.3,1028,1660,1028.8z M1668.9,1034.1c0,0.1-0.1,0.1-0.1,0.2c-0.5,0.8-1.2,1.1-1.9,1.1c-0.6,0-1.2-0.1-1.8-0.4
									c-0.5-0.2-1-0.4-1.5-0.6c-0.7-0.3-1.9-0.8-2-1.7c0-0.6,0.9-2.1,1.3-2.5c0.9-1.1,1.9-1.1,3.2-0.8c0.2,0,0.4,0.1,0.5,0.1
									c0.6,0.2,1.2,0.4,1.5,1c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0.2,0.4,0.4,0.6,0.6c0.2,0.3,0.4,0.6,0.5,0.9
									C1669.5,1032.6,1669.4,1033.3,1668.9,1034.1z M1678.2,1035.9c-0.3,1.3-1.2,2.5-2.5,2.4c-0.1,0-0.2,0-0.4-0.1
									c-0.3-0.1-0.6-0.1-0.8-0.3c-1.2-0.6-2.3-1.8-2.6-3.1c-0.1-0.3-0.1-0.6,0-0.9c0.1-0.6,0.3-1.1,0.6-1.4c0.4-0.5,1.1-0.8,1.8-0.9
									c0.6-0.1,1.2,0,1.7,0.2c0.2,0.4,0.6,0.7,1.2,0.7c0.2,0.1,0.4,0.3,0.7,0.4c0.1,0,0.1,0.1,0.2,0.1c0.1,0.2,0.2,0.5,0.3,0.9
									C1678.2,1034.5,1678.1,1035.2,1678.2,1035.9z M1686.7,1037.1c-0.5,0.9-1.1,1.5-1.9,1.8c-0.4-0.2-1-0.2-1.4,0c0,0,0,0,0,0
									c-0.4-0.1-0.7-0.3-1-0.7c-0.6-0.6-1-1.4-1.2-2.3c0.1-0.6,0.2-1.1,0.2-1.7c0.1-0.1,0.1-0.2,0.2-0.3c0.8-1.2,3.8-2.7,5.1-1.6
									c0.2,0.2,0.5,0.4,0.8,0.4C1687.7,1034.1,1687.1,1036.3,1686.7,1037.1z M1697.4,1040.1c-0.1,0-0.2,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1
									h0c-0.1,0-0.2,0-0.3,0c-1.2-0.1-1.9-0.5-2.6-1.6c-0.5-0.8-1.1-1.5-1.5-2.3c-0.7-1.5,0-2.1,1.1-2.9c0.8-0.6,1.6-1.1,2.6-1.3
									c2.7-0.6,3.9,1.1,3.8,3.7C1700.1,1037.9,1699.2,1039.2,1697.4,1040.1z"
						/>
					</g>
					<g>
						<path
							d="M1710.2,988.5c-0.9-3.1-4.1-6.2-7.6-5.3c-0.4,0.1-0.7,0.2-1,0.4c-0.5-1.4-1.6-2.8-3-3.9c-3.5-2.9-7.6-0.8-10.9,1.5
									c-0.1-0.2-0.3-0.4-0.5-0.6c-1.7-1.8-4.2-3.7-6.5-4.7c-0.8-0.4-1.6,0-2,0.6c-2.6,0.6-4.8,2.2-5.7,4.8c-0.5-2-1.3-3.8-2.6-5
									c-2.7-2.7-7.1-2.3-9.9,0c-0.6-1.3-1.3-2.5-2-3.6c-1-1.5-2.3-2.9-4.2-2c-0.9,0.4-1.2,1.3-1.9,2c-0.8,0.8-1.7,1.2-2.6,1.8
									c-0.2-0.6-0.5-1.3-0.8-1.9c-2.4-4.3-7.8-2.9-11.7-1.1c0-0.4,0-0.8-0.1-1.2c-0.1-0.6-0.4-1.1-0.9-1.3c-2.5-2.9-5.5-5.9-9.7-4.1
									c-3,1.3-4.5,5.8-3.5,8.8c0,0.1,0.1,0.2,0.1,0.3c-1.3,0.3-2.6,1.1-3.5,1.9c-3.7,3.3-3.8,8.9-0.2,12.2c1.4,1.3,3.4,2.5,5.4,3
									c0.1,0.8,0.1,1.6,0.1,2.4c0,0.8-0.2,1.5,0.1,2.3c0.6,1.8,3.5,1.1,2.9-0.8c-0.1-0.2-0.1-0.5,0-0.7c0.9,1.8,3.3,3.7,5.8,4.7
									c0,1.3,0.1,2.7,0.4,4c0.5,1.9,3.4,1.1,2.9-0.8c-0.2-0.9-0.3-1.8-0.3-2.6c0.6,0,1.1-0.1,1.6-0.3c0.7-0.3,1.2-0.7,1.6-1.3
									c0.5,0.9,1.3,1.6,2.3,2.1c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.3,0.2,0.6,0.2,0.9c0.2,0.9,0,1.9,0,2.8c0,1.9,3,1.9,3,0
									c0-1,0.2-1.9,0.1-2.8c-0.1-0.5-0.2-0.9-0.3-1.3c1-0.5,1.9-1.2,2.6-2.1c0.6,1.8,1.9,3.4,3.6,4.1c0,1.6,0.6,3.3,0.2,4.9
									c-0.4,1.9,2.5,2.7,2.9,0.8c0.2-1.1,0.2-2.2,0.1-3.3c-0.1-0.6-0.2-1.3-0.3-2c0.3,0,0.6-0.1,0.9-0.2c1.1-0.4,2-1.1,2.7-2
									c1,2.3,2.9,4.2,5.3,4.8c0,0.5,0.1,1.1,0.2,1.6c0.1,0.7,0.5,1.7,0.3,2.3c-0.5,1.9,2.3,2.7,2.9,0.8c0.4-1.5-0.3-3.1-0.4-4.6
									c1.6-0.3,3.4-1.2,4.8-2.5c-0.1,1.4,0.1,2.7,0.7,4.1c0.7,1.5,1.7,3,2.8,4c-0.2,2-0.2,4.1-0.2,6.1c0,1.9,3,1.9,3,0
									c0-1.6,0-3.2,0.1-4.8c1.2,0,2.6-0.5,3.9-2.1c0.1-0.1,0.1-0.1,0.2-0.2c0.6,1.4,1.5,2.7,2.7,3.3c0.3,1.5,0.2,2.9,0.1,4.4
									c-0.1,1.9,2.9,1.9,3,0c0.1-1.5,0.2-2.9,0-4.3c0.4-0.2,0.9-0.5,1.3-0.8c0.7-0.5,1.3-1.2,1.7-2c0.5,1.8,1.5,3.3,3.4,4
									c0,0.5,0.1,0.9,0.1,1.4c0,0.8-0.2,1.6-0.2,2.5c0,1.9,3,1.9,3,0c0-0.8,0.2-1.6,0.2-2.5c0-0.2,0-0.4,0-0.6c2.1-0.5,3.2-2.9,3.9-5.3
									c0.3,0.4,0.7,0.8,1.1,1c6.4,4.1,9-8.3,5.5-12.1c0.9-1.5,2.2-2.8,2.8-4.4C1710.9,992.2,1710.7,990.2,1710.2,988.5z M1628.8,967.3
									c2.6-0.2,4,2,5.4,3.8c0,1-0.1,2.3-0.5,3.4c-0.4,0.5-0.7,1-1,1.6c-0.4,0.4-0.9,0.5-1.6,0.4c-0.3-0.1-0.6-0.2-0.9-0.2
									c-1-0.9-2.2-1.6-3.3-2c-0.5-0.5-0.9-1.1-1-1.8C1625.4,970.4,1626.7,967.6,1628.8,967.3z M1624.9,987.8c-3.4-1.2-6.2-4.3-4.5-8
									c0.6-1.3,1.9-2.6,3.5-3c0.5-0.1,1.2,0,1.9,0.3c0.9,0.8,1.9,1.3,3,1.8c0.4,0.3,0.7,0.7,0.9,1c0.3,0.4,0.7,0.6,1.1,0.7
									C1630.5,984.2,1629.3,989.3,1624.9,987.8z M1637.5,995.8c-1,1.2-3-0.1-4.1-0.6c-1.8-0.9-3.4-1.9-3.1-4.3c0.4-2.8,3.2-3.9,4.9-5.7
									c0.8,0.9,1.6,1.8,2.6,2.5c0.3,1,0.4,2.1,0.4,2.9C1638.2,991.8,1638.4,994.8,1637.5,995.8z M1640.1,985.1c-0.9-1.9-2.3-3.5-4.4-4.2
									c0,0-0.1,0-0.1,0c-0.5-0.7-0.8-1.4-0.7-2.3c0-0.1,0-0.2,0.1-0.4c0.6-0.6,1-1.4,1.4-2.2c0.3-0.3,0.7-0.6,1-0.8
									c0.3-0.2,0.5-0.5,0.6-0.8c2.3-1.1,6.2-2.7,8.1-0.6c2.5,2.6,0.1,6.2-1.7,8.3c-0.8,0.9-1.8,2.3-2.9,2.9
									C1641,985.1,1640.5,985.2,1640.1,985.1z M1647.6,991.7c-0.4,2-1.6,4.3-3.5,5.3c0,0,0,0,0,0c-0.3-0.1-0.7-0.1-1,0
									c-2.8-2.1-0.5-10.1,3-10.3C1648.2,986.6,1647.8,990.1,1647.6,991.7z M1655.5,998.4c-2.2,2.5-4.8-0.6-4.9-3
									c-0.1-2.7,0.9-4.5,3.6-4.9c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.1,0.3-0.2c0.4,0.1,0.8,0.2,1.2,0.3c0.9,0.3,1.5,0.9,1.4,2.1
									C1657.6,994.3,1656.8,996.9,1655.5,998.4z M1659.2,981.8c-0.3,1.2-0.6,2.8-0.7,4.3c-0.5,0.7-1.3,1.1-2.2,1.3h0
									c-0.5-0.2-1.1-0.3-1.7-0.4c-0.4-0.1-0.8,0-1.1,0.2c-0.7-0.2-1.3-0.5-1.9-1c-3.2-2.3-0.8-5.9-0.3-9.1c1-0.9,2.2-1.3,3.2-2.2
									c1.2-1.2,1-1.5,2,0.2c0.8,1.3,1.6,2.6,2.1,4c0.1,0.2,0.2,0.4,0.2,0.6c0,0,0,0,0,0c0,0,0,0,0,0
									C1658.9,980.4,1659.1,981,1659.2,981.8L1659.2,981.8z M1661.5,987c0.7-1.7,0.9-3.4,0.7-5.1c0,0,0,0,0,0c-0.1-0.5-0.1-0.9-0.2-1.4
									c-0.1-0.4-0.2-0.8-0.3-1.2c0,0,0,0,0,0c2.2-2.8,6.5-2.3,7.9,1.1c0.7,1.9,1.3,5.6,0.4,7.5c-0.8,1.7-2.6,1.2-4,1.1
									c-0.1,0-0.3,0-0.4,0c-1,0-2.5,0.2-3.2-0.2C1661.8,988.4,1661.6,987.8,1661.5,987C1661.5,987,1661.5,987,1661.5,987z
									M1667.1,1001.6c-3,1.3-5.9-0.8-6.5-3.8c-0.3-1.6,0-3.7,1.5-4.6c1-0.6,2.4-0.6,3.4-1c0.1,0,0.3-0.1,0.4-0.2h0
									c1.7,0.6,3.4,1.9,4.3,3.4C1671.7,997.9,1669.3,1000.7,1667.1,1001.6z M1680.5,1001.3c0,0.7,0,1.4-0.1,2.1c-0.2,2-0.7,6.3-3.7,4.7
									c-1-0.6-1.9-1.9-2.3-3.1c-0.6-1.8-0.1-3.7,0.1-5.6c0.2-1.8-0.3-3.8,0-5.5c0.3-1.9,0.3-2.4,2.3-1.2c0.5,0.3,1.2,0.8,1.7,1.4
									c1.1,1.8,1.6,3.5,1.8,5.4c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2C1680.5,1000.3,1680.5,1000.8,1680.5,1001.3L1680.5,1001.3z
									M1682.9,989.5c-5.6,3.4-11.6-10.1-2.4-10.3c0,0,0.1,0,0.1,0c1.6,1,3.6,2.4,4.4,3.5C1686.4,984.5,1684.7,988.4,1682.9,989.5z
									M1688.8,1006.5c-0.3,0.7-0.7,1.4-1,2.1c-1.1,0-2.1,0-3.2,0c-0.8-0.7-0.7-3-0.7-3.9c-0.2-1.9-0.1-3.8-0.3-5.8
									c-0.2-2-1.2-5.7,1.6-5.6c1.5,0,1.6,0.7,2.7,1.6c0.7,0.6,1.4,1.1,2.2,1.3C1690,999.7,1689.9,1003.4,1688.8,1006.5z M1690.4,992.1
									c-0.8-0.4-1.5-2.5-1.7-3.3c-0.5-1.8-0.1-3.4,0.6-5.1c0,0,0,0,0,0c1.7-1.2,3.8-2.3,5.6-2.2c1.3,0.1,3,1.2,3.6,2.6
									c0.8,2-1,2.6-2.3,3.7C1694.6,989,1692.9,993.2,1690.4,992.1z M1699,1004c-0.2,0.8-1.4,6.8-2.6,7.9c-0.2-0.2-0.4-0.3-0.7-0.3
									c-4.7-1-2.5-7.6-2-10.5c0.2-1,0.7-7.5,2.6-6.9c0.4,0.1,1.8,2.7,1.9,3C1699.4,999.3,1699.5,1001.6,1699,1004z M1704.1,1008.2
									c-3.5-0.5-0.5-8.3,0.8-7.8c0.2,0.1,0.3,0.1,0.5,0.1C1706.3,1002.5,1706.2,1008.5,1704.1,1008.2z M1707.6,992.6
									c-0.4,1.5-1.6,2.8-2.6,4.1c-1.4-0.3-1.9-1-2.5-2.5c-0.5-1.1-1-2.3-1.1-3.5c-0.2-2.1,0.9-5.6,3.5-4.3
									C1707.1,987.3,1708.1,990.4,1707.6,992.6z"
						/>
					</g>
					<g id="mirkwood">
						<g>
							<path fill="none" d="M1814.1,515.1c3.7,1.1,8.2-2.1,4.2-6C1814,505,1809.2,513.7,1814.1,515.1z" />
							<path fill="none" d="M1837.6,528.9c3.8,1.8,7.2-14.6,0.1-14.9c-2.8-0.1-5,6.3-4.9,8.1C1833.3,527.4,1836.2,525.3,1837.6,528.9z" />
							<path fill="none" d="M1809,442.4c6.7,0.3-6-15.1-7.6-5.3C1800.9,440.9,1806,442.4,1809,442.4z" />
							<path fill="none" d="M1824.5,532.6c6.8-0.5,4.9-15.6,1-15.3C1820.4,517.5,1819.9,530.6,1824.5,532.6z" />
							<path fill="none" d="M1817.5,408.1c-5.5,4.9,4.2,17.3,7.8,5.5C1826.8,408.9,1823,403.2,1817.5,408.1z" />
							<path fill="none" d="M1800.2,472.8c-0.2,5.3,9.9,1.8,6.8-6.3C1803.4,457.1,1800.4,467.6,1800.2,472.8z" />
							<path fill="none" d="M1846,538.3c1.3-2,1.6-8-2-7.2C1838.9,532.2,1842.5,543.8,1846,538.3z" />
							<path fill="none" d="M1860.3,510.3c0-4.9-5.2-3.7-6.7-1C1851.1,513.7,1857.8,514.8,1860.3,510.3z" />
							<path fill="none" d="M1859.3,530.5c-1-1.3-2.7-1.9-4.3-1.8c-4.4,0.1-4.7,3-2.4,5.9C1855.9,539,1863.4,535.6,1859.3,530.5z" />
							<path fill="none" d="M1863.9,551.2c-1-2.6-5.9-5.6-6.7-0.9c-0.1,0.3,5,5.9,5.1,7.7C1864.4,556.2,1865,553.9,1863.9,551.2z" />
							<path
								fill="none"
								d="M1856.8,415.8c4.1-4.3-3.7-5.2-5.9-5.1c-2.8,0.2-9,3.4-8,6.9c1,3.7,3.6-0.2,5.1-0.1
										C1847.6,417.5,1856.9,415.7,1856.8,415.8z"
							/>
							<path fill="none" d="M1856.6,560.9c4.3-0.7,0.6-5.4-1.2-6.3C1849.5,551.5,1849.9,562,1856.6,560.9z" />
							<path fill="none" d="M1928.9,474c-5.8-0.8-5,9.7,0,10.6C1937.2,486.2,1933.9,474.7,1928.9,474z" />
							<path fill="none" d="M1962.7,490.1c-5.2,0-6.2,4-3.8,8.3c1.9-2.1,4.3-3.4,7.2-3.8C1964.3,491.7,1966.5,490.1,1962.7,490.1z" />
							<path fill="none" d="M1971.6,506.1c0.1-10.2-10.8-6.4-10.4-1.6C1961.5,508.7,1971.1,509.8,1971.6,506.1z" />
							<path fill="none" d="M1938.8,520.1c1.3-2.1,13.1-11.1,1.3-9.7C1935.1,511,1931.8,518.9,1938.8,520.1z" />
							<path fill="none" d="M1872.1,454.5c-3.9,0.9-7.9,9.6-2.8,10.3C1875.3,465.5,1878.5,453,1872.1,454.5z" />
							<path fill="none" d="M1950.9,510.8c7.3,6.3,10.4-3.1,5.7-5.5C1954.9,504.4,1948.3,508.5,1950.9,510.8z" />
							<path
								fill="none"
								d="M1952.9,492.6c-4.5-3.7-13.2,6.5-9.2,10.8c2.3,2.4,5.4,0.6,7.4-1.5C1953.6,499.1,1951.8,496.7,1952.9,492.6z
										"
							/>
							<path fill="none" d="M1904.2,470c-1.9,6.2,10.5,3,8.9-3.9C1910.8,456.3,1906.3,468.5,1904.2,470z" />
							<path fill="none" d="M1897.5,454.9c-0.3,3.3-5.3,12.1,0.7,13.4C1904.3,469.7,1907.3,453.1,1897.5,454.9z" />
							<path fill="none" d="M1975,480.8c-7.9-2.8-6.7,12-2.3,14.4c0.4-0.6,1.2-2.1,1.9-2.5C1982.1,488.1,1975.4,486.6,1975,480.8z" />
							<path fill="none" d="M1960.9,477.2c-2.9,0.6-6.1,7-6.7,9.5c4.2-0.2,12.4,1.1,11.3-4.8C1965.3,480,1962.9,476.9,1960.9,477.2z" />
							<path fill="none" d="M1863.5,506.8" />
							<path fill="none" d="M1937.2,489.7c-4.3-2.2-9.5,8.9-2.1,9.4C1940,499.3,1943,492.6,1937.2,489.7z" />
							<path fill="none" d="M1927.1,466.9c-1-4.4-6.9-5.7-8.6-0.7C1915.8,474.3,1928.4,472.8,1927.1,466.9z" />
							<path fill="none" d="M1920.4,454.1c-3.2-0.9-6.8,7-4,8C1917.9,462.7,1926.4,455.8,1920.4,454.1z" />
							<path fill="none" d="M1913.5,451.9c-4.9-4.6-8.3,6.6-2,5.3C1912.8,456.9,1914.4,452.7,1913.5,451.9z" />
							<path fill="none" d="M1940.9,484.9c3.2,6.5,9.6,0.6,9.6-4.4C1950.4,468.4,1937.3,477.5,1940.9,484.9z" />
							<path fill="none" d="M1934,452.7c-4.1,1.2-5,9.8-2.2,12.7C1941.6,475.6,1940.8,450.7,1934,452.7z" />
							<path fill="none" d="M1869.2,504.4" />
							<path fill="none" d="M1884.9,448c-2.1,1.8-5.7,6.8-4.4,9.9c5.5-4.4,10,2.5,12-4.5C1893.6,449.6,1889.6,443.9,1884.9,448z" />
							<path fill="none" d="M1885.8,434.5c-5.5-16.4-14.6,10.7-9.1,13.8C1880.9,442.4,1888.6,442.9,1885.8,434.5z" />
							<path fill="none" d="M1827.4,384.8c-1.4,0-3.1,4.6-2.4,5.7C1825.3,390.2,1832.1,384.8,1827.4,384.8z" />
							<path
								fill="none"
								d="M1832.7,391.5c-3.1,2.3-6.4,1.3-6.7,6.4c-0.2,2.6,2,7.2,5.2,7c-0.1,0,4.9-4.8,4.9-4.8
										C1836.9,397.7,1835.5,392.5,1832.7,391.5z"
							/>
							<path fill="none" d="M1855.6,420.4c-2.8-0.1-5-0.7-7.1,1.9C1840.4,432.6,1867.9,427.3,1855.6,420.4z" />
							<path fill="none" d="M1852.7,398.7c-4-2.4-12.1,3.3-7.1,6.8C1848.6,407.7,1858.4,402.1,1852.7,398.7z" />
							<path fill="none" d="M1939.8,471.8c-1.5-0.2-5,1.3-4,3.1C1938.6,476.6,1940.5,471.9,1939.8,471.8z" />
							<path fill="none" d="M1899.7,519.3c1.2,3.8,11-0.7,6.8-5.6C1902.2,508.7,1898.4,515,1899.7,519.3z" />
							<path fill="none" d="M1912.3,512c3.5,0.3,13.1-8.2,7-10.3C1915.6,500.4,1907.7,511.6,1912.3,512z" />
							<path fill="none" d="M1898.9,507.8c2.2-0.5,7.4-0.4,6.8-3.9c-1.1-6.6-11.5,0.5-10.3,4C1896.6,508.2,1897.7,508.2,1898.9,507.8z" />
							<path fill="none" d="M1958.3,466c-3.1-2.4-5.1,7.2-4,9.2C1957.8,470.7,1958.9,471.4,1958.3,466z" />
							<path fill="none" d="M1882.9,511.6c4.1,4.1,10.1-9.5,6.1-11.7C1883.4,496.6,1880.5,509.2,1882.9,511.6z" />
							<path fill="none" d="M1892.7,522.2c4.4-0.2,2.1-8.3-0.5-8.4C1887.9,513.6,1885.7,522.5,1892.7,522.2z" />
							<path fill="none" d="M1928.8,513.5c5.2-0.3,10.1-12.4,0.5-11.1C1923.2,503.2,1920.3,513.9,1928.8,513.5z" />
							<path fill="none" d="M1981.3,495c-5.1-2.8-7.4,9.7-3.4,11.2C1983.1,508.2,1985.3,497.2,1981.3,495z" />
							<path fill="none" d="M1975.7,468.8c-3.4-4.6-8.9,6.4-7.7,8.5C1970.9,474.5,1980.4,475.1,1975.7,468.8z" />
							<path fill="none" d="M1975,531.1c2.2-3.3-3-8.2-4.6-5C1968.9,529.2,1971.5,536.4,1975,531.1z" />
							<path fill="none" d="M1982.4,520c6.1-7.2-13.3-10.5-9.3-0.6C1974.3,522.4,1979.7,523.2,1982.4,520z" />
							<path fill="none" d="M1858.4,570c0.7-2.7-0.4-6.1-3.6-2.8C1850.5,571.8,1856.4,578,1858.4,570z" />
							<path fill="none" d="M1881.6,490.6c-0.3,9.9,15.7-10.9,3.5-11.5C1880.7,478.9,1881.8,486.8,1881.6,490.6z" />
							<path fill="none" d="M1821.9,501.4c0.8,0.8,11.8-4.3,5.7-8C1822.4,490,1819,498.5,1821.9,501.4z" />
							<path fill="none" d="M1855.5,474.5c-0.3-3.1-4.6-2.1-6.3-1.5c-4.9,1.7-7.2,11.2-1.6,11C1850.5,484,1855.7,477.1,1855.5,474.5z" />
							<path fill="none" d="M1900.1,475.2c-2-4.2-5.7,2.4-6.1,4.5C1892.2,489.5,1902.4,480,1900.1,475.2z" />
							<path fill="none" d="M1900.3,496.2c3.8-3,7.5-10.2-0.3-9.6C1890.4,487.4,1889.7,504.5,1900.3,496.2z" />
							<path fill="none" d="M1837.9,459.9" />
							<path fill="none" d="M1839.8,499.8c-4.1-9.2-10.2,1.2-2.9,5.1C1838.9,506,1842.2,505.3,1839.8,499.8z" />
							<path fill="none" d="M1843,470.3c0.3-4.4-2-10.7-6.9-5.3C1832.5,469,1838.1,479.9,1843,470.3z" />
							<path fill="none" d="M1844.3,453.5c-0.7-6.7-12.7-1.8-10.2,4.2C1835.7,461.5,1844.8,458.5,1844.3,453.5z" />
							<path fill="none" d="M1827.2,475.8c7.8,15,7.6-2.6,3-4.6C1830.3,470.8,1827.4,476.3,1827.2,475.8z" />
							<path fill="none" d="M1823,460.7c4,3,6.3,7.4,7.8,1.2c0.5-1.8-1.4-7.1-3.6-7.9C1822.6,452.3,1818.5,457.7,1823,460.7z" />
							<path fill="none" d="M1833.9,443.7c-4.5-4.5-13.5,1-9.4,4.8C1828.4,452,1835.8,448.7,1833.9,443.7z" />
							<path
								fill="none"
								d="M1855,445.6c-3.8,0.9-5.1,2.1-8.8,3c5.5,5.4-1.1,7.9-1.1,12.2c0,2.1,3.2,5.8,3.3,8.3
										c-1.2,0.5,2-9.9,4.3-12.8C1856.8,451,1857.5,453.2,1855,445.6z"
							/>
							<path fill="none" d="M1980.5,478.8c-2.1,0.9-1.6,2.4,1.4,4.7c1.3-0.5,1.9-1.7,2-3.6C1982.9,478.6,1981.8,478.2,1980.5,478.8z" />
							<path fill="none" d="M1812.8,470.1" />
							<path fill="none" d="M1822.4,393.7" />
							<path fill="none" d="M1822.3,402.6" />
							<path fill="none" d="M1878.2,466.2c1.7,6.2,10.3,12.5,12.5,3.6C1893.2,459.7,1882.7,459.6,1878.2,466.2z" />
							<path fill="none" d="M1800.6,456.6c6,0.8,9.3-10.9,2.8-10.5C1799.1,446.3,1797.4,456.2,1800.6,456.6z" />
							<path fill="none" d="M1852.2,499.2c1.1-2.6,0.7-12-4-10.7C1839.6,490.7,1848,509.6,1852.2,499.2z" />
							<path
								fill="none"
								d="M1830.2,427.4c0.2-3.8-2.7-3.5-3.8-7.4c-2.4,3.7-5.3,2.1-5.4,7c0,3.4,5,5.4,5.6,10.4c2.4-1.5,6,0,8-0.5
										c2.3-0.6,9.8-3.9,10.6-5.4c0,0.1-2.3-8.6-2-8.2c-1.3-2-2.7-0.8-3.8-4C1837.3,421.4,1829.5,439.8,1830.2,427.4z"
							/>
							<path fill="none" d="M1920.2,477.7c-4.5-2.2-20.4,0.2-12.4,5.9c0.5,0.4,8-0.8,8.7-1.1C1921.3,480.3,1919,482.6,1920.2,477.7z" />
							<path fill="none" d="M1939.3,505.7" />
							<path fill="none" d="M1798.1,428.1c-0.2,1.4-0.4,2.7-0.7,4.1C1810.4,426.5,1799.7,416.8,1798.1,428.1z" />
							<path fill="none" d="M1811.4,453.9c2.2-1.3,12.3-2.5,5.2-6.7C1811.9,444.5,1807.7,452,1811.4,453.9z" />
							<path fill="none" d="M1836.8,405.2c-2.6,2.2-10.5,14-4.6,16C1836.7,422.6,1845.8,403.3,1836.8,405.2z" />
							<path fill="none" d="M1810.9,464c3.1,5.6,6.8-0.9,5.4-4.3C1814.3,455.3,1808.4,459.4,1810.9,464z" />
							<path fill="none" d="M1854.6,432.5c-5.4-1.2-6,1.3-4.9,4.8C1853,447.9,1861.2,433.9,1854.6,432.5z" />
							<path fill="none" d="M1821.8,474.3c2.7-0.4,3.5-5.8,2.4-6.8c-1.9-1.6-6.4-0.7-6.2,2.6C1818.1,472.2,1820.8,472.1,1821.8,474.3z" />
							<path fill="none" d="M1818.4,503.8" />
							<path fill="none" d="M1909,498.9c5.7,0.3,12.5-6.9,5.8-10.2C1910,486.4,1902.4,498.6,1909,498.9z" />
							<path fill="none" d="M1909.7,503.4" />
							<path fill="none" d="M1877.1,506.2" />
							<path fill="none" d="M1877.7,487.3c2.8-6.3-10-7.3-11.1-0.5C1865.4,494.4,1875.1,493,1877.7,487.3z" />
							<path fill="none" d="M1832.1,490.2c6.9,8,8.9-3.7,6.6-8.9C1837.7,483,1830,487.7,1832.1,490.2z" />
							<path fill="none" d="M1847.4,546.9c3.2,4.1,11.2-2,7.1-5.8C1851.6,538.4,1844.6,543.3,1847.4,546.9z" />
							<path fill="none" d="M1858.2,501.8c5.2,1.6,11.5,0,7.3-5.8C1861.4,490.3,1856.3,497.5,1858.2,501.8z" />
							<path fill="none" d="M1846,525.9c1.9-0.3,12.7-5.2,5.4-8.3C1846.6,515.6,1842.9,521.8,1846,525.9z" />
							<path fill="none" d="M1810.2,423.4c-3.3,3.7-2.3,8.9,3.4,7.3C1822.7,428.1,1813.8,416.4,1810.2,423.4z" />
							<path fill="none" d="M1847.2,512.2c1.7,0,2-1.2,2.4-2.6C1851.9,500,1832.3,512.2,1847.2,512.2z" />
							<path fill="none" d="M1820.1,433.3c-3.8,0.2-6.2,5.4-3.5,8.3C1823,448.4,1824.1,433,1820.1,433.3z" />
							<path fill="none" d="M1819.3,486.2c5.1,4,10-1.3,5.3-5.9C1821.7,477.3,1818.2,482.1,1819.3,486.2z" />
							<path fill="none" d="M1826.1,511.8c1.3,0.8,2.8,1.5,3.6,2.9C1840.9,498.9,1816.2,505.4,1826.1,511.8z" />
							<path fill="none" d="M1859.3,458c-1.3-0.8-2,0-3.1,0.5c-9.2,4-0.2,8.1,2.2,11.6C1859.8,466.7,1864.4,461.1,1859.3,458z" />
							<path fill="none" d="M1847.2,440.9c-4.3-6.9-14.3-1-8.2,3.8C1841.4,446.5,1849,445,1847.2,440.9z" />
							<path fill="none" d="M1857.5,489.5c3.5-1.9,9.6-7.3,3.5-9.1C1853.7,478.3,1855.5,490.6,1857.5,489.5z" />
							<path fill="none" d="M1861.9,471.8c-0.1,1.2-0.3,2.4-0.4,3.6c3.1-1.4,1.7-3.4,3.6-6.1C1863.4,469.4,1862.3,470.2,1861.9,471.8z" />
							<path fill="none" d="M1867.5,476.7c0.3,1.1,10.5,1.8,9.4-3.3C1874.7,462.9,1868.6,475.7,1867.5,476.7z" />
							<path fill="none" d="M1914.8,709c-3.7,5,14,14.6,12.5,1C1926.6,703.2,1919.7,702.4,1914.8,709z" />
							<path fill="none" d="M2049.7,1035.9c-3.3-6.7-7.7,1.8-6.7,5.8C2045.4,1050.6,2053.4,1043.3,2049.7,1035.9z" />
							<path fill="none" d="M2035.7,1051.2" />
							<path fill="none" d="M2037.8,1041.4c-2.8-4.8-9.8,0.3-9.3,4.6C2029.6,1054.5,2042.9,1050.2,2037.8,1041.4z" />
							<path fill="none" d="M2055.4,1026c-1.4,3-1.9,12.6,3.6,11.8C2067.3,1036.6,2058.2,1020,2055.4,1026z" />
							<path fill="none" d="M2061.8,1021.8" />
							<path fill="none" d="M2067.6,989.8c1.7,6.5,10.3-2.1,3.8-5.2C2068.5,983.3,2067,987.7,2067.6,989.8z" />
							<path fill="none" d="M2070.6,1005.5c0-2.6-1.5-2.4-2.7-1.5c-0.5,0.4-0.2,7-0.3,7.4C2069.5,1010.1,2070.5,1007.9,2070.6,1005.5z" />
							<path fill="none" d="M1909.9,1051.3c1.2-5.8-5.6-11.3-9-4.5C1898,1052.6,1906.7,1057.1,1909.9,1051.3z" />
							<path fill="none" d="M2023.4,1052.3c3.1-2,1.3-9.2-2.9-8.5C2014.4,1044.7,2016.9,1056.4,2023.4,1052.3z" />
							<path fill="none" d="M1968.7,1070.3c4.1-1.3,2.1-14.7-1.5-15c-2.4-0.2-3.4,4.4-3.7,6C1962.6,1067.3,1965,1067.7,1968.7,1070.3z" />
							<path fill="none" d="M2010.6,1043.3c-4.1-6.5-9.4,15.1-1.4,13.9C2013.8,1056.4,2012.5,1046.3,2010.6,1043.3z" />
							<path fill="none" d="M2000.1,1061.3c3.6-3.7,0.5-19.7-3.7-14.2C1994.7,1049.3,1994.8,1066.6,2000.1,1061.3z" />
							<path fill="none" d="M2127.7,984.8c3.7,2,6.2-9.3,2-10.4C2124.4,973,2122.6,982.1,2127.7,984.8z" />
							<path fill="none" d="M2134,962c-4.8-1.4-5.1,7.6,0.1,8.2C2140.6,971,2139,963.5,2134,962z" />
							<path fill="none" d="M2117.1,971.1c3.1-2.5-5.2-3.8-5.2-0.9C2112,972.3,2116.3,971.7,2117.1,971.1z" />
							<path fill="none" d="M2120.5,953.1c4.5-2.7,13.4,2.4,8.9-6.5c-2.4-4.7-5.3-5.3-8.7-2.6C2118.5,945.7,2116.3,955.6,2120.5,953.1z" />
							<path fill="none" d="M2123.5,967.3c2.8,0.3,4.2-9.9,1.9-10.6C2121.2,955.4,2118.7,966.6,2123.5,967.3z" />
							<path fill="none" d="M2141.6,953.3c-0.5-3.9-12.8,2.6-4.8,4.6C2140,958.7,2142,956.8,2141.6,953.3z" />
							<path fill="none" d="M2154.2,906.5c-3.5-1-3.7,2.5-3.3,4.7C2152.9,921.5,2161.7,908.6,2154.2,906.5z" />
							<path fill="none" d="M2057.3,1010c0.2,2.1-0.4,7.4,2.4,7.8c1.6-2.1,3.6-9.7,2.1-12.4C2058.9,1000,2056.8,1005.7,2057.3,1010z" />
							<path
								fill="none"
								d="M2059.9,887.5c-3.4,1.5-4.3-7.1-7.8-1.3c-3.8,6.3,4.8,4.6,5.6,6.8c1.8,4.8-18,1.9-20.3-5.3
										c-1.9,12.5-3.5-3.5-6.8-3.2c-5.6,0.5,1.1,8.6-2.5,9.8c-2.7,0.9-2.2-2.4-3.5-3.5c-0.7-0.6-3.8-5-4.6-6.3c-0.7,1-1.6,1.7-2.8,2
										c-1.7,0.3-2.1,1.6-1.2,3.8c-0.8,1.4,1.2,7.6-3.2,5.9c-2.8-1,0.2-7.3-0.8-9.2c-0.7-1.5-6.1-2.5-7.5-4.7c-1,5.2-6.2,10.3-11.7,7.6
										c-5.1-2.5-2.5-7.1-3.4-11c2.6-1.8,3.1-3.2,1.3-4.4c-0.8-1.3-1.8-1.2-3,0.4c-2.1,0.6-3.8-0.2-5-2.3c-2.8,1.3-2.8,2.9-0.1,4.9
										c-0.8,6.6,5.4,12.9,8.8,18.9c2.5,4.4,12.3,12.1,7.7,17.5c1.2,0.5,2.4,1,3.6,1.5c0.9-1.7,2.2-2.7,4-3.1c3.5-0.5,6.1-1.3,9.3,1.4
										c1.5-7.5,13.5-7,16.6-1.1c2.2,4.1-2.6,17.3-6.5,11.1c-1.1-1.8,8.1-12-1.6-11.6c-11.2,0.5,2,8.1,1.1,12.4
										c-1.8,9.1-4.2-0.7-3.9-0.6c-1.6-0.9-1.5-2.7-4.3-2.5c-5.2,0.4-2.7,4.9-2.7,6.5c-0.1,5-1.5,12.9,1.9,17.5
										c4.1,5.5,8.8,4.6,5.6,14.1c3.3-0.1,5.2,1.9,7.4,3.8c0.6-4,4.5-4.2,6.1-6.3c1.5-2,0.3-2.3,2-5c2.5-3.9,2.8-11.1,10-8.6
										c-2.3-5.2,1.3-18.5,9.5-13c-1-3.2,0.6-7.9,2.5-10.3c1.7-2.1,6.6-2.9,7.5-4.1c0.3-0.5,2.3-10.5,2.4-10.9c2.8-1.9,3.2-3.4,1-4.5
										c-0.7-1.3-0.9-2.6-0.6-4C2064.2,899.2,2057.8,894.4,2059.9,887.5z"
							/>
							<path fill="none" d="M2068.1,885.5c-1.1-0.2-2.2,0.6-3,1.3c-1.5,1.3-2.6,4.5-0.4,6.1C2068.9,895.8,2074.1,886.5,2068.1,885.5z" />
							<path fill="none" d="M2062.5,998.7c0.9,1.9,3.7,1.7,5.1,0.5C2071.6,995.8,2059.9,993,2062.5,998.7z" />
							<path fill="none" d="M2145,938.7c3.6-0.3,3.8-4.4,2.7-6.5C2144.6,926.4,2138.9,939.2,2145,938.7z" />
							<path
								fill="none"
								d="M1899.6,1025.7c-0.5-3.2-3.6-5-6.7-4c-2.2,0.7-2.5,7.6-4.3,9.4c0.5,0.2,5.7-1.2,5.4-1.1
										C1898.2,1028.6,1896.1,1031.4,1899.6,1025.7z"
							/>
							<path fill="none" d="M1877.7,609.4c-3.3,2.5-2.5,9.2,1.4,10.5C1881.2,617.9,1881.2,606.8,1877.7,609.4z" />
							<path fill="none" d="M1848.5,636c4-4.3-2.6-5.3-5.5-2.1c-1.5,1.7-3.4,6,0.3,6.8C1845.1,641.2,1847.1,637.6,1848.5,636z" />
							<path fill="none" d="M1865.5,612.9c-0.8,4.3,3.2,15.3,6.3,6C1872.9,615.6,1866.5,604.2,1865.5,612.9z" />
							<path fill="none" d="M1856.7,611.5c-4.3,1.5-3,12.4,2.3,9.6C1862.1,619.4,1859.8,610.4,1856.7,611.5z" />
							<path fill="none" d="M1840.3,618.9c0,3.5,11.2,0.4,10-5C1848.6,605.7,1840.2,614.2,1840.3,618.9z" />
							<path fill="none" d="M1941.7,692c-4.1-0.6-9,6.8-6,10c-0.3-0.4,10.2,2,10.4,1.9C1946.6,703.6,1943.4,692.2,1941.7,692z" />
							<path fill="none" d="M1833.2,539.9c5.6-0.5,3.1-10.5-1.9-6.3C1827.7,536.6,1830.2,538,1833.2,539.9z" />
							<path fill="none" d="M2036.4,623.8c-3.1,5.5,4.5,5.1,7.1,8.2C2043.4,630.2,2043.6,610.9,2036.4,623.8z" />
							<path
								fill="none"
								d="M1952.1,702.8c0.4,0.4,0.8,0.9,1.2,1.3c1.7-1.9,3.2-4,4.9-5.8c0.9-1,3,1.1,3.8,0.2c2.5-2.7-3.8-7.2-6.1-7
										C1950.5,692.1,1949,699.3,1952.1,702.8z"
							/>
							<path fill="none" d="M2022.9,628.5c0.8,5.8,9.9,3.1,8.9-3.5C2030.3,615.1,2025.3,624.2,2022.9,628.5z" />
							<path fill="none" d="M1994.9,620.5c-7.3,1.9-3.2,6.6,3,6.1C2007.4,625.8,2001,618.9,1994.9,620.5z" />
							<path fill="none" d="M1978.3,673.6c5,2.8,10.5-2.4,7.6-8.1C1981.4,656.8,1973.6,671,1978.3,673.6z" />
							<path fill="none" d="M1841.3,952.8c0,3.4,5.6,3.5,8.5,5.1C1855.9,949.5,1841.3,940.2,1841.3,952.8z" />
							<path fill="none" d="M1805.9,405.7c-5.5,1.4-6.3,10.1-2.2,11.9C1814.1,422.2,1812.4,404.1,1805.9,405.7z" />
							<path fill="none" d="M1822.2,1004.1" />
							<path fill="none" d="M1819.1,987.2c-3.6-6.7-11.6,6.6-4.4,6.2C1817.4,993.2,1820.8,990.4,1819.1,987.2z" />
							<path fill="none" d="M1859,1025.1c-3.8-7-7.5,1.8-5.7,6C1855.8,1037.1,1861.9,1030.5,1859,1025.1z" />
							<path fill="none" d="M1844.6,1017.6c0.4-2.4,1.9-16-2.8-16.1C1835.2,1001.4,1841,1023.9,1844.6,1017.6z" />
							<path fill="none" d="M1863.6,1012.3c-1.1-3.3-4-0.5-4.8,1.2c-1.5,2.9,2,5.7,3,7.9C1863.3,1019.6,1864.2,1014.2,1863.6,1012.3z" />
							<path fill="none" d="M1915.8,896c0.8,2.2,12.9-6.2,5.2-10.6C1915.3,882,1914.5,892.1,1915.8,896z" />
							<path fill="none" d="M1839.2,961.3c-0.9-11.8-14.1,7.4-1.3,11.1C1837.2,970,1839.3,964.3,1839.2,961.3z" />
							<path fill="none" d="M1907.9,894.9c1.8-0.9,4.7-10.3,0.7-11.2C1901.4,882.2,1906.4,893.4,1907.9,894.9z" />
							<path fill="none" d="M1895.7,743.3c-3.1-5.9-11.3,3.5-7.4,7.1C1892.8,754.8,1898.1,748.1,1895.7,743.3z" />
							<path
								fill="none"
								d="M1866.6,937.7c4.8,1.4,18.1-2.1,11.2-8.4c-2.1-1.9-8.6-2.5-10.9-0.5C1863.2,932.2,1865.2,933.8,1866.6,937.7
										z"
							/>
							<path fill="none" d="M1835.7,1004.2c2.8-17.3-9.7,5.3-1.9,8.5C1835.7,1010.8,1835.1,1006.7,1835.7,1004.2z" />
							<path fill="none" d="M1997.8,475.7c-1.1-0.6-1.8,0.6-2.3,1.3C1991.8,482.9,2006.1,480.3,1997.8,475.7z" />
							<path fill="none" d="M2065.3,1028.7c0.7,2.8,3.1,6.4,4.6,1.2C2071.7,1023.7,2063.3,1020.3,2065.3,1028.7z" />
							<path fill="none" d="M2166.5,917.3c0.6-2,0.2-4.5-1.9-5.1C2155.8,909.7,2163.6,926.6,2166.5,917.3z" />
							<path fill="none" d="M2013.7,466.4c-2.8,1.6,2.2,3.4,2,3.1C2016.6,470.4,2018.5,463.6,2013.7,466.4z" />
							<path fill="none" d="M2004.6,468.8c-4.5,3.6-0.2,8.5,3.7,2.5C2010.4,468,2008,466,2004.6,468.8z" />
							<path fill="none" d="M1990.7,530.5c0,1,1.5,4.8,3.2,2.3C1994.8,531.4,1991.1,530.5,1990.7,530.5z" />
							<path fill="none" d="M2154.9,921.4c-2.3-2.8-9.3-0.9-6.8,3.2C2150.1,927.8,2157.6,924.6,2154.9,921.4z" />
							<path fill="none" d="M2109.1,963.8c3.8,2.6,11.3-3,4.8-5.2C2110.9,957.5,2108.2,961.4,2109.1,963.8z" />
							<path fill="none" d="M2055.8,936.6c0-8.3-11.6,5.6-1.9,8.5C2057.9,946.2,2055.8,940,2055.8,936.6z" />
							<path fill="none" d="M2082,902.5" />
							<path fill="none" d="M2080.4,951.2c-2-2.5-9.5,1.7-9.4,4.4C2071.4,964.1,2083.5,955.1,2080.4,951.2z" />
							<path fill="none" d="M2109.9,932.1c1.3-2.1,4-11.1-0.7-10.1c-4.1,0.9-3.1,9.6-1.8,12.2C2108.4,934.6,2109.4,932.7,2109.9,932.1z" />
							<path fill="none" d="M2076.8,903.4c-4,0-7.4,10.9-5.1,13C2077.1,921.1,2083.1,903.4,2076.8,903.4z" />
							<path fill="none" d="M1797.4,440.8c0.2-4.4-3.6-6.7-3.6-1.1C1793.8,444.4,1797.2,445.4,1797.4,440.8z" />
							<path fill="none" d="M1786.6,427.4c-3.5,10.1,12.7,0,5.5-4.6C1789.9,421.3,1786.9,426,1786.6,427.4z" />
							<path fill="none" d="M1790.7,413.1c-2.1,3-0.5,7.6,3.7,4.8C1798.9,415,1793.3,409.4,1790.7,413.1z" />
							<path fill="none" d="M1811.8,481.7c3.9,3.1,6.9-2,4.9-5.1C1812.4,469.7,1811.3,478.9,1811.8,481.7z" />
							<path fill="none" d="M1797.1,400.9c-4.3,5.7-1,9.8,3.3,3.6C1803.1,400.7,1801.5,395.1,1797.1,400.9z" />
							<path fill="none" d="M1811.4,494.4c-1.7,2.2-2.7,7.3,0,8.5C1818.5,505.9,1818.5,485,1811.4,494.4z" />
							<path fill="none" d="M2112.3,941.2c5.7,5.3,8.7-8.7,3.5-8.7C2113.4,932.5,2110.7,939.7,2112.3,941.2z" />
							<path fill="none" d="M1882.1,568.1c1-6-5.7-0.8-4.3-0.5C1878.7,567.8,1881.6,567.7,1882.1,568.1z" />
							<path fill="none" d="M1884.1,560.3c0.5,2,3.7-0.3,4.8-1.2C1892.9,555.2,1882.9,555.3,1884.1,560.3z" />
							<path fill="none" d="M1864.2,573.1c3.3,1.4,9.2,2.8,6.7-3.4C1868.8,564.2,1863.3,569.6,1864.2,573.1z" />
							<path fill="none" d="M1878.9,577c0.6-7.2-0.1-2.7-3.4-5.9C1874,574.7,1875.1,576.6,1878.9,577z" />
							<path fill="none" d="M1876.5,580.1c-4.3-3.7-11,2.5-5.7,4.1C1872.4,584.8,1879.7,582.9,1876.5,580.1z" />
							<path fill="none" d="M2124,935.8c7.5,6.5,10.2-9.9,5.1-10.4C2126.1,925,2120.9,933.1,2124,935.8z" />
							<path
								fill="none"
								d="M2144,926.9c2.5-1-5.9-8.5-9.2-1c-0.9,2.1,0.2,7.7-0.4,10.3c-0.8,3.5-4.7,3.6-2.3,6.9
										C2133.6,940.4,2142.2,927.6,2144,926.9z"
							/>
							<path fill="none" d="M2060.2,938.5c-0.1,8.9,11.2,2.6,9.9-3.1C2068.9,929.6,2060.3,934,2060.2,938.5z" />
							<path fill="none" d="M2106.8,913.3c0.7-5.3-5.1-8.7-9-5.5c-2.4,2-1.7,7.5-1.8,10.3C2100.9,916.1,2105.6,923.6,2106.8,913.3z" />
							<path fill="none" d="M2094.1,932.1c1.7-1.3,12.3-9.1,4.4-9.9C2092.1,921.6,2092.6,933.2,2094.1,932.1z" />
							<path
								fill="none"
								d="M2121.9,910.1c1.3,2.1,0.9,5,0,7.1c7.1,0.1,14.4,10.4,10.8-3.2c-0.7-2.8,0.9-7.6-4-8.4
										C2125.8,905.1,2124.3,909.3,2121.9,910.1z"
							/>
							<path fill="none" d="M2118.7,898.1c-3.3-2.3-10.6,5.2-8.1,8.7C2112.4,909.3,2129.2,905.5,2118.7,898.1z" />
							<path fill="none" d="M2115.6,910.5c-1.2,0.4-4.5,4-4.1,5.9C2112.4,921.7,2124.7,912.9,2115.6,910.5z" />
							<path fill="none" d="M2139.4,899.5c-4-1.1-9.6,3.4-4.1,6.3C2140.7,908.6,2146.8,901.5,2139.4,899.5z" />
							<path fill="none" d="M2140.8,942.5c-3-2.1-2.4-0.8-4.5,1.4C2132.6,947.6,2143.5,948.9,2140.8,942.5z" />
							<path fill="none" d="M2146.1,910.5c-2.4,1.1-10-0.6-7.8,5.2C2141.1,922.5,2149.5,914.8,2146.1,910.5z" />
							<path fill="none" d="M2118.9,922c-1.7,2.2-3.8,10.3,2.4,5.1C2124.6,924.3,2125.1,919,2118.9,922z" />
							<path fill="none" d="M2048.6,959.7c1.6-0.2,7.1-10,3.7-9.4C2050,950.7,2047.4,959.8,2048.6,959.7z" />
							<path fill="none" d="M2043.8,958.2c-0.6,0,3.9-10.1,2-11.2C2042.5,945.3,2038.4,958,2043.8,958.2z" />
							<path
								fill="none"
								d="M2078.8,888.7c-2.3,0.1-5.8,6.4-4.8,8.9c0.9,2.3,4.6,1.4,6.6,3.3c-1.8-3.3-1.4-8.4,0.4-11.6
										C2080.3,889.1,2079.6,888.9,2078.8,888.7z"
							/>
							<path fill="none" d="M2077.5,946.8c2.6-0.2,1.3-3.6,0-5.1c-2.5-3.1-7.3-1.7-6.8,3.3C2071.1,949.7,2074.5,947.1,2077.5,946.8z" />
							<path fill="none" d="M2067.5,919.9c-2.4,0-9,6-6.5,8.8C2066.7,931.1,2076.3,919.9,2067.5,919.9z" />
							<path fill="none" d="M2081.7,917.1c-3.5-2.1-7.1,8.2-7.4,9.2C2067.7,944.7,2091.1,922.6,2081.7,917.1z" />
							<path fill="none" d="M2085.8,897.9c4,0,7.3,3.4,7.3-4.5c0-9.6-12-3.3-9,4.8C2084.7,898.1,2085.2,898,2085.8,897.9z" />
							<path fill="none" d="M2054,967.5c2.9,3.2,8.5-4.5,3.5-7.1C2053.1,958.1,2051,964.2,2054,967.5z" />
							<path fill="none" d="M2098.8,892.9c-2.7,3.5-3.6,7.2,0.1,8.3C2108.7,904.1,2104.1,885.9,2098.8,892.9z" />
							<path
								fill="none"
								d="M2063.6,954.2c6.1-3.6,4.5-1.5,3.4-7.9c-2.7,1-3.4,2.5-6.9,0c-0.4,1.6-1.8,8.4-2.9,9.6
										C2063.6,957.8,2058.8,957.1,2063.6,954.2z"
							/>
							<path
								fill="none"
								d="M2032,636.5c-5.2,7.4,8.2,4.3,9.3,4.8c3.2,1.4,2.8,3.5-1,4.4c2.4,2.7,1.3,4.9,4.4,2.9
										c2.5-1.6,2.5-8.4,4.6-11.5c-4.6,0.3-2.6,4.7-6.7,4.2C2040.1,641,2038.2,627.8,2032,636.5z"
							/>
							<path fill="none" d="M2065.3,963.4c5,2.9,5.7-3.7,2.7-4.7C2065.5,957.9,2061.1,961,2065.3,963.4z" />
							<path fill="none" d="M2084.7,490.5c-3.3,3.3-3,11.7,2.2,9.6C2091.2,498.4,2091.9,485.6,2084.7,490.5z" />
							<path fill="none" d="M2084.1,472.6c-4.8-2.3-3.5,13.7-3.6,14.7C2082.9,485.7,2087.1,474,2084.1,472.6z" />
							<path fill="none" d="M2088.2,483.8c-0.1,4,5.1,3.5,5.7-0.5C2094.6,478.9,2088.3,479.9,2088.2,483.8z" />
							<path fill="none" d="M2113.3,512.8c2.1-7.5-5.8-5.6-5.4-2.2C2108.1,512.5,2112.4,515.9,2113.3,512.8z" />
							<path fill="none" d="M2073.6,508.4c-1.1,1.5,3.4,1.7,5,1.7C2088.2,509.6,2081.9,497.5,2073.6,508.4z" />
							<path fill="none" d="M2098.5,484.7c-6.8,1.4-1.7,9.4-0.1,11.5C2099.7,494.7,2107.3,482.9,2098.5,484.7z" />
							<path fill="none" d="M2069.7,515.3c-1.2-5.1-14.6-2.8-9.8,4.4c1.5,2.2,2.5,0.4,4,0.4C2068.6,520.3,2067.5,519.3,2069.7,515.3z" />
							<path fill="none" d="M2040,521.9" />
							<path fill="none" d="M2076.5,490c-0.5-10.5-10.4-3.3-8.1,4C2071.7,504.3,2077.1,498,2076.5,490z" />
							<path fill="none" d="M2055.1,517.1c-0.3-11.8-8.9,9.3-0.2,3C2054.1,519.2,2054,517.9,2055.1,517.1z" />
							<path fill="none" d="M2062.8,503.2c-0.9,2.7,0.6,4.4,4,3.8C2074.7,505.8,2066.2,493.8,2062.8,503.2z" />
							<path fill="none" d="M2106.8,527.9" />
							<path fill="none" d="M2076.2,521.6c3.5-0.3,5.9-6.6,1.5-6.7C2073.3,514.8,2071.1,520.9,2076.2,521.6z" />
							<path fill="none" d="M2122.5,530.4c0.5-5.1-7.7-3.6-7.4,0.9C2115.3,535.1,2122.2,533.8,2122.5,530.4z" />
							<path fill="none" d="M2105.2,495.1c-2.3,3.6-6.4,13.9,2.8,9.1C2113.2,501.3,2111.5,494.3,2105.2,495.1z" />
							<path fill="none" d="M2052.6,475.2c-3,4.8,5.6,6.5,8.3,4C2067.7,472.9,2056.9,468.5,2052.6,475.2z" />
							<path fill="none" d="M2067.4,481.5c0.1,0.1,15.6-7.8,7.2-10.8C2072.4,469.8,2065.2,479.4,2067.4,481.5z" />
							<path fill="none" d="M2119.1,514.6c-3.9,0-5.5,6.8-1.8,8.6C2122.2,525.5,2123.4,514.6,2119.1,514.6z" />
							<path fill="none" d="M2092.1,507.6c2.3-1.9,8.2-3.1,4.2-6.6c-4.5-4-8.1,8.1-8.2,8C2088.9,509.8,2091.8,507.9,2092.1,507.6z" />
							<path fill="none" d="M2118.5,501.2c-2.9,0.4-5.8,4.1-2,5.8C2122.3,509.5,2124,500.4,2118.5,501.2z" />
							<path fill="none" d="M2102.6,512.4c-1.9-0.7-13.2-3.5-8.5,2.4C2096.1,517.3,2104.3,517.5,2102.6,512.4z" />
							<path fill="none" d="M2099.7,527.3c2.8-1.7,0.3-6.2-2.9-3.9C2093.3,525.9,2095.9,529.7,2099.7,527.3z" />
							<path fill="none" d="M2035,522.3c-2.3-3.9-8.7-2.8-6,1.5C2030.4,526.1,2035.2,525.8,2035,522.3z" />
							<path fill="none" d="M2088.4,524.6c5-0.4,6-5.3,1.9-7.7C2084.6,513.4,2080.2,525.2,2088.4,524.6z" />
							<path fill="none" d="M1964.6,373.8c-1.5,0.6-5.5,7.6-3.7,8.5c1.3-1.2,12.2-5.5,8.9-8.6C1969.3,373.1,1965.5,373.4,1964.6,373.8z" />
							<path fill="none" d="M2056.6,499.8c-4.2-6.7-10.9,9.3-9.9,11.9C2046.8,511.9,2060.7,506.4,2056.6,499.8z" />
							<path fill="none" d="M2004.2,513.6c0.3-1.7,4.8-12.6-2.7-7.2C1998.4,508.7,2001.6,515,2004.2,513.6z" />
							<path fill="none" d="M1867.7,364.3c2.8,2.1,5.1,2.3,6.3-0.7C1876.6,357.2,1865.6,360.2,1867.7,364.3z" />
							<path fill="none" d="M1857.9,375.1c3.3,2.7,4.6-6.6,2.7-8.5C1859,367.8,1855.6,373.2,1857.9,375.1z" />
							<path fill="none" d="M2025.3,506.2c-0.7-3.7-1.7-3.9-4.1-3.2C2008.8,506.6,2027,516,2025.3,506.2z" />
							<path fill="none" d="M2019.5,527.2c4.8,2.5,5.5-8.6,4.3-9.8C2017.8,512.1,2014,524.4,2019.5,527.2z" />
							<path fill="none" d="M2029.1,515.5c4.5,1.9,2.2-2,2.5-2.7C2032,511.6,2023.6,513.3,2029.1,515.5z" />
							<path fill="none" d="M2016.1,499.7c2.2,3.1,3.5-2.6,2.9-4.7c-0.9-3.1-0.8-3.1-4.1-2.6C2009.3,493.3,2015.7,499.2,2016.1,499.7z" />
							<path fill="none" d="M1913.7,378c1.6,2.6,1.7,5.8,4.5,3.6C1924.9,376.6,1912.5,368,1913.7,378z" />
							<path fill="none" d="M1941.5,368.1c-2.2,6.5,14.2-2.4,6.1-5C1945.7,362.5,1941.9,366.9,1941.5,368.1z" />
							<path fill="none" d="M1889.7,360.6c-6,0.4-1.8,10.2,3,4C1895.1,361.5,1893.7,360.3,1889.7,360.6z" />
							<path fill="none" d="M1899.3,361.5c-4,4.9,3.4,4.8,6,2.5C1911.8,358.3,1903.7,356.2,1899.3,361.5z" />
							<path fill="none" d="M1876.8,371.1c0.2,1.4,0.1,2.8,0.2,4.2c1.3-0.5,6.1-7.4,6.1-8.1C1883.1,362.8,1876.5,366.7,1876.8,371.1z" />
							<path fill="none" d="M2029.4,504.9c2.2,3.5,9.4-4.8,3.6-7.8C2025.2,493,2029.2,503.4,2029.4,504.9z" />
							<path fill="none" d="M2064.2,490c-0.1-2.1-1-6.5-3.8-6.4C2048.7,484.2,2061.5,507.4,2064.2,490z" />
							<path fill="none" d="M2030,480.4c12.3,9.1,2.1-5.2,3.8-5.4C2032.5,475,2030.5,479.7,2030,480.4z" />
							<path fill="none" d="M2036.2,511.6c3,4.6,12.3-11.4,4.8-9.5C2038.2,502.8,2034.8,509.3,2036.2,511.6z" />
							<path fill="none" d="M2008.1,516.3c1.1,3.9,9.3-1.3,6.4-2.9C2011.7,511.9,2008.9,513.5,2008.1,516.3z" />
							<path fill="none" d="M2039.7,479.6c0.1,1.1,0.1,2.3,0.1,3.5c2.2,1,7.6-6.4,7.8-7.4C2050.1,465.8,2039.6,471.8,2039.7,479.6z" />
							<path fill="none" d="M2100,448.8c-3.8,3,0.2,4.3,0.5,4.8C2102,457,2106,444,2100,448.8z" />
							<path fill="none" d="M2009.5,501.3c-2.3,0.5,0.3,5.8,1.6,6.5c0.7,0.4,1.7-0.5,2-1C2014.8,503.2,2011.2,504.4,2009.5,501.3z" />
							<path fill="none" d="M2050.1,481.3c-2.7-1.1-6.2,6.4-5.3,8C2049.3,497.5,2055.2,483.7,2050.1,481.3z" />
							<path fill="none" d="M2047.7,496.8c-2-1.3-3.2-0.1-4.2,1.2C2046.2,498.6,2045.3,498.9,2047.7,496.8z" />
							<path fill="none" d="M2034.9,491.8c0,1.8,2.7,5.1,4.4,4.8C2047.2,495.1,2034.8,482.7,2034.9,491.8z" />
							<path fill="none" d="M2023.5,486.1c-4.2,1.2,0.5,6.8,0.1,10.2C2026.2,493.7,2032.4,483.4,2023.5,486.1z" />
							<path fill="none" d="M2105.3,567.3" />
							<path fill="none" d="M2035.8,580.7c3.2-0.5,0.3-6.1-4.7-3.8C2027,578.8,2034,581,2035.8,580.7z" />
							<path fill="none" d="M2083.9,567.9c5.4-0.3-3.2-6.6-6.9-3.1C2072.9,568.5,2083,567.9,2083.9,567.9z" />
							<path fill="none" d="M2056.1,566.8c-1.6-1.1-6.5,1.4-4.4,3.1C2053.1,571.2,2058.8,568.7,2056.1,566.8z" />
							<path fill="none" d="M2097.7,568.7c0.9-0.8,8-8.1,0.2-5.1C2094.6,564.8,2092.3,569.4,2097.7,568.7z" />
							<path fill="none" d="M2081.9,573.3c-1-0.4-2-0.7-3-1.1c0.4,2.3-0.1,4.2-1.6,5.9C2079,580.5,2084.7,576.2,2081.9,573.3z" />
							<path fill="none" d="M2025.7,575.2c-1.1-2.2-3.6-1.3-4.5-2.9C2012,579,2030.1,583.7,2025.7,575.2z" />
							<path fill="none" d="M2090.5,457.1" />
							<path fill="none" d="M2013.1,579c1.9-2.6-2.3-6.7-5.6,0.5C2008.7,580.3,2012.3,580.2,2013.1,579z" />
							<path fill="none" d="M2043.4,580.5c5.1,2.6,5.6-3.9,4.6-6.3C2044.5,566.5,2036.8,577.1,2043.4,580.5z" />
							<path fill="none" d="M2066.8,563.9c3.3-2-0.3-6.9-4-4.1C2057.7,563.5,2061.6,567,2066.8,563.9z" />
							<path fill="none" d="M2055.4,580.2c5.3,0.6,3.6-4.7,4.3-7.8C2057.2,572.1,2048.1,579.3,2055.4,580.2z" />
							<path fill="none" d="M2121.4,576.9c1.4,3.3,6.4,0.2,6.3-2.4C2127.6,570,2118.4,569.7,2121.4,576.9z" />
							<path fill="none" d="M2142.3,531.7c1.8,1,7,1.2,6-1.9C2146.7,524.4,2140.4,530.7,2142.3,531.7z" />
							<path fill="none" d="M2150.8,522.3c4.7-2.8-7.7-7.7-6.2-2.9C2145.4,522,2147.7,524.2,2150.8,522.3z" />
							<path fill="none" d="M2153.1,526.6" />
							<path fill="none" d="M2140.4,507.3c-4.7,0.3-3,8.3,1.3,8.2C2148.2,515.3,2145.4,507,2140.4,507.3z" />
							<path fill="none" d="M2008,569.8c0.9-2.2-1.4-1.5-1.9-0.1C2006.7,571,2007.3,571,2008,569.8z" />
							<path fill="none" d="M2116.2,573.7" />
							<path fill="none" d="M2118.6,566.3c-3.6-5.1-10,1-9.3,1.9C2111.4,570.7,2118.5,569.3,2118.6,566.3z" />
							<path fill="none" d="M2101.5,579c2.4-1.6,7.5-3.1,2.7-7.2C2099.2,567.6,2097.7,578.3,2101.5,579z" />
							<path fill="none" d="M2134.9,571.9c-1.9,0.4-3.2,1.2-2.2,3.7C2135.2,581.6,2144.5,569.8,2134.9,571.9z" />
							<path fill="none" d="M2122.8,565.7c1,2.4,8.9,2.7,9.8,1.5C2135.2,563.5,2125.5,559.4,2122.8,565.7z" />
							<path fill="none" d="M1847.9,584.8c4.4,4.1,4.8-2.3,3.8-4.6C1848.6,573.3,1844.2,581.4,1847.9,584.8z" />
							<path fill="none" d="M1936.1,576.2c-2.5-1.4-5.3,5.4-2.4,6.3C1935.3,583.1,1937.6,577.1,1936.1,576.2z" />
							<path fill="none" d="M1938.3,567.4c-6.3,0.5,3,10,4.4,4.1c0.4-1.5-0.1-2.6-1.3-3.4C1940.6,567.5,1939.3,567.3,1938.3,567.4z" />
							<path fill="none" d="M1914.6,585.9c2.1-0.8,6.7-7.7,2.8-6.6C1914.3,580.1,1911.1,587.4,1914.6,585.9z" />
							<path fill="none" d="M1930.7,659.5c-1.1-6-8.8,0.3-8.9,3.4C1921.5,670.3,1931.9,665.9,1930.7,659.5z" />
							<path fill="none" d="M1900.6,586.1c3.1,2.3,8.7-0.3,6.7-4C1905.7,579.1,1895.4,582.4,1900.6,586.1z" />
							<path fill="none" d="M2071.1,450.3c-1.5,2.2,2.1,2.7,3.3,3.4C2076.9,455.2,2074.2,445.8,2071.1,450.3z" />
							<path fill="none" d="M2081.1,450.9c-0.5,1-0.4,1.9,0.4,2.7c0.5-0.7,4.8-6.3,1.2-4.2C2083.4,449.3,2082.9,449.8,2081.1,450.9z" />
							<path fill="none" d="M1917.1,662.6c-0.8-0.3-2.6,2.9-0.2,3.2C1916.9,665.5,1918.2,663,1917.1,662.6z" />
							<path fill="none" d="M2088.5,449.7" />
							<path fill="none" d="M2000.5,579.8c3.2,0,3.7-7.3,1.3-8.1C1997.4,570.1,1995.1,579.7,2000.5,579.8z" />
							<path fill="none" d="M1913.1,576.9c3.1-4-3.7-2.2-4.4-2.6C1910.2,575.1,1908.6,580,1913.1,576.9z" />
							<path fill="none" d="M1912.1,546.9c-4.2,5.8,3.4,2.5,3.5,2.9C1915.3,548.8,1913.9,544.4,1912.1,546.9z" />
							<path fill="none" d="M1983.3,573.4" />
							<path fill="none" d="M1926.5,573c-4.7,0.7-5.3,9.3-0.1,6.6C1927.7,578.8,1931.2,572.2,1926.5,573z" />
							<path fill="none" d="M1978.6,575.5c-1.2,0-2.5,0-3.7,0c0.5,2.8-0.7,10.8,4.9,7.1C1982.4,580.7,1983.3,576.3,1978.6,575.5z" />
							<path fill="none" d="M1991.4,564.4c-3.5-2.3-6.4,3.2-5.1,6C1985.9,570.7,1999.1,569.5,1991.4,564.4z" />
							<path fill="none" d="M2003.1,562.7c-4.3-4.4-6.5,1.8-6.6,4.7C1999,567.8,2006.9,566.6,2003.1,562.7z" />
							<path fill="none" d="M1986.7,583c5.2,4,10.3-9.1,3.5-8.9C1987.2,574.2,1983.7,580.7,1986.7,583z" />
							<path fill="none" d="M1953.5,572.4c6.5-4.8-5.6-8.2-6.5-1.3C1946.4,575.9,1949.9,575,1953.5,572.4z" />
							<path fill="none" d="M1953.3,582.9c4.5,0.5,5.9-4.9,1.7-6C1951.9,576.2,1947,582.2,1953.3,582.9z" />
							<path fill="none" d="M1968.1,564.5c-3.3-1.2-7.1,2.1-7.7,5.3C1962.3,569.3,1976.6,567.6,1968.1,564.5z" />
							<path fill="none" d="M1975.6,567.3c-2.6,3.5,0.1-0.6-1.8,2.9C1983.3,576.6,1981.9,558.9,1975.6,567.3z" />
							<path fill="none" d="M1965.4,583.3c8.2,1.6,4.2-11.6-0.8-9.9C1961,574.6,1961.2,582.4,1965.4,583.3z" />
							<path fill="none" d="M2094.1,580c0.9-0.1,1.5-9.1-3-9.1C2084.9,571,2087.3,580.8,2094.1,580z" />
							<path
								fill="none"
								d="M1909.5,366.4c-3.9,4.1-5.7,1.1-6.6,7.4c0.2-0.1,1.5-0.8,1.2-1c-3.7-2.3,6.5-2.7,3.9-1
										C1908,371.9,1918.6,367.1,1909.5,366.4z"
							/>
							<path fill="none" d="M1938.1,381.8c1.6-9.6-14.2,3.5-4.3,8.4C1935.5,386.8,1938.4,385.8,1938.1,381.8z" />
							<path fill="none" d="M1920.9,362.6c-3,1.8,0.5,7.4,1.8,9.6c0.2,0,0.3-0.1,0.5-0.1C1923.1,371.7,1923.9,360.8,1920.9,362.6z" />
							<path fill="none" d="M1870.6,376.4c6.7-4.4-2.5-10.3-4.6-6C1864.8,373,1870.9,373.9,1870.6,376.4z" />
							<path fill="none" d="M1923.6,379.2c-0.5,3.2-0.7,13.1,3.4,6C1928.2,383.1,1927.5,373.4,1923.6,379.2z" />
							<path fill="none" d="M1932.9,363.5c-3.3-1.4-4.4,6-1.9,7.8C1935.8,374.7,1939.4,366.2,1932.9,363.5z" />
							<path
								fill="none"
								d="M1899,370.8c-0.8-1.5-2.2-1.4-3.4-3.2c-1.4,1.5-3.1,2.6-5.3,2.9c2.9,2.2,7.1,10.2,11.1,4.9
										C1898.8,375.9,1899,372.4,1899,370.8z"
							/>
							<path fill="none" d="M1880.8,383.3" />
							<path fill="none" d="M2046,438c-1.9,5.8,9.4-1.2,3.8-1.5C2048.3,436.4,2046.9,435.1,2046,438z" />
							<path fill="none" d="M1874.1,385.4c3.4,0.1,3.2-4.3,0.8-5C1871.3,379.4,1868.2,385.2,1874.1,385.4z" />
							<path fill="none" d="M1862.9,378c-0.1,1-0.1,5.2,1.8,4.9c3.4-0.5,0.4-5.3-0.2-6.7C1864,376.9,1863.5,377.4,1862.9,378z" />
							<path fill="none" d="M1851.5,368.9c3.7,0.2,4.1-4.3,1.2-5.2C1850.8,363,1847.5,368.7,1851.5,368.9z" />
							<path fill="none" d="M1920.5,393c2.7-3.3-4.1-8.3-7-6C1906.9,392.2,1916.2,394.7,1920.5,393z" />
							<path fill="none" d="M1903.1,397.2c5.1,2.8,6.5-4.6,2.2-6.5C1899.5,388.2,1898.6,394.7,1903.1,397.2z" />
							<path fill="none" d="M1977.5,366.7" />
							<path fill="none" d="M1910.3,381.4c-3.7-3-2.4-7.8-7.2-0.8c-0.5,0.7-2.4,5.6-2,6C1905.2,385.7,1910.7,387.8,1910.3,381.4z" />
							<path fill="none" d="M1958.6,364.7c-6.4-2.1-5.9,10.5-4.3,13.4C1956.6,377.6,1963.5,366.3,1958.6,364.7z" />
							<path fill="none" d="M1939.4,389.6c-0.9,0.9-3.8,4.6-3.2,6.3C1940.2,407.6,1950.8,386.2,1939.4,389.6z" />
							<path fill="none" d="M1931.5,402.2c3.3-4-1.7-10.1-6.2-8C1916.3,398.4,1929.5,404.7,1931.5,402.2z" />
							<path fill="none" d="M1937.8,412.2c3.4-1.1,6.7-5.9,0.5-8.7C1934.6,401.8,1933.2,410.5,1937.8,412.2z" />
							<path fill="none" d="M1926.9,410.5c7,0.3-3.2-5-3.5-5.2C1920.8,407.8,1924.6,410.4,1926.9,410.5z" />
							<path fill="none" d="M1807.8,483.4c-4.6-5.9-8.4,2-5.9,3.7C1804.7,489,1807.6,486.9,1807.8,483.4z" />
							<path fill="none" d="M1913.5,406.2c4,0.1,5.5-4.4,2.6-7C1912.4,396,1908.3,404.1,1913.5,406.2z" />
							<path fill="none" d="M1896.1,386.8c2.2,0,3.4-6.6-0.6-7.1C1890.4,378.9,1891.4,386.7,1896.1,386.8z" />
							<path fill="none" d="M1949.4,457.3c-5.3,0.3-6.4,10.6-1.8,11C1953.6,468.9,1955.6,457.1,1949.4,457.3z" />
							<path fill="none" d="M1964.5,531.1c2.8-3.6,0.5-9.6-4-6.6C1953.1,529.4,1961.7,534.6,1964.5,531.1z" />
							<path fill="none" d="M1955.8,521.6c2.2-10-14.4,2.4-13.9,2.3C1942.7,527.1,1955.3,523.6,1955.8,521.6z" />
							<path fill="none" d="M1949,528.6c-3.8,0.3-2,4.9,0.8,5.2C1954.1,534.3,1953,528.3,1949,528.6z" />
							<path fill="none" d="M1983.8,524.7c-7.3,0.8-2.9,10.5,1.4,8C1987.1,531.6,1987.8,524.3,1983.8,524.7z" />
							<path fill="none" d="M1968.7,516.2c-1.4-4.2-10-4.5-5.9,1.2C1965,520.5,1970.1,522.4,1968.7,516.2z" />
							<path fill="none" d="M1988.6,490.7c5.2-5.7,0.5-14.2-2.9-5.5C1984.9,487.4,1985.6,494,1988.6,490.7z" />
							<path fill="none" d="M1861.7,576.7c-0.5,0.3-1,0.7-1.5,1C1855.9,583.6,1867.4,585.4,1861.7,576.7z" />
							<path fill="none" d="M1939.1,538.1c3.3,0,4.2-2.6,3.9-5.1C1941.4,521.3,1930.3,536,1939.1,538.1z" />
							<path fill="none" d="M1855.6,578.1" />
							<path fill="none" d="M1922.5,498c6.2,0.4,5-7.9,2.8-9.9C1918.4,482.1,1921.3,498,1922.5,498z" />
							<path fill="none" d="M1882.1,521.3c0.2-3.9-1.3-8.8-5.2-6.3C1871.7,518.2,1881.6,530,1882.1,521.3z" />
							<path fill="none" d="M1859.1,442.8c1.1,1.2,12.2-7.9,9.2-12.4c-2.9-4.4-7.1,1.3-7.4,3.8C1861.9,436.5,1861,440.4,1859.1,442.8z" />
							<path fill="none" d="M1795.1,452.9c-0.1-4.5-4.8-1.9-4.1,2.2C1792.2,461.7,1795.3,456.9,1795.1,452.9z" />
							<path fill="none" d="M1861.5,419.8c1.1,5.9,10.7,2.8,7.6-2.7C1866.6,412.7,1860.3,414.8,1861.5,419.8z" />
							<path fill="none" d="M1838.4,384.7c-3.6,3.6,1.2,9.3,2.3,13.5C1846.1,394.8,1839.2,383.9,1838.4,384.7z" />
							<path fill="none" d="M1786.7,442.7c0.6,1.1,1.1,2.2,1.7,3.2c3.8-1.4,1-6.2-0.1-8.8C1786.9,438.5,1786.4,440.8,1786.7,442.7z" />
							<path fill="none" d="M1984.1,465.8c-5.6,5,0.5,12.7,6.5,8C1996.5,469.2,1989.6,460.9,1984.1,465.8z" />
							<path fill="none" d="M1810,400.1c1.9,7.7,12.2,1.5,9-4.1C1816.4,391.5,1809,396.1,1810,400.1z" />
							<path fill="none" d="M1999.4,369.1c-2.2,3.3,0.5,9.2,4.7,5.7C2007.7,371.8,2003.2,364.4,1999.4,369.1z" />
							<path fill="none" d="M1963.2,468.1c0.1,0.9-0.2,1.6,0.1,2.5c1.8-0.8,6.1-3.7,6.6-6.1C1971.2,457.6,1962.7,459.3,1963.2,468.1z" />
							<path fill="none" d="M1870.8,440.8c-2.3-1.5-10.7,9-10.7,9.4C1860.7,459.3,1878.2,445.6,1870.8,440.8z" />
							<path fill="none" d="M1890.8,443.1c1.7,0.5,6.4,3.2,6.4-0.3C1897.4,436.5,1888.2,439.7,1890.8,443.1z" />
							<path fill="none" d="M1901.2,445.6c-0.3,0.5-2.3,3.6-2.2,4C1906.3,456.1,1903.4,442,1901.2,445.6z" />
							<path fill="none" d="M1889.5,377.2c2.1-3.3-4.3-4.8-6.2-2C1879.8,380.2,1888.2,379.2,1889.5,377.2z" />
							<path fill="none" d="M2015.3,382.7" />
							<path fill="none" d="M1949.1,373.8" />
							<path fill="none" d="M1944.3,386.1c6.5,0.7,6.9-9.1,1.2-9.7C1940.7,375.9,1941,385.7,1944.3,386.1z" />
							<path fill="none" d="M1948.3,391c1.1,2.5-0.5,3.2,1.4,3.1C1950.1,391.5,1949.6,390.5,1948.3,391z" />
							<path fill="none" d="M1952.7,410.7c-2.6-0.6-8.4-1.2-6.7,3C1948.6,420.2,1956.1,411.5,1952.7,410.7z" />
							<path fill="none" d="M1950.2,398.2c-4.3-1.1-7.1,8.3-0.8,8.2C1953.3,406.4,1953.2,399,1950.2,398.2z" />
							<path fill="none" d="M2020.8,407.2c-3.5-4.3-8.6-5.7-8.6,2.9C2014.9,408.9,2023.5,410.5,2020.8,407.2z" />
							<path fill="none" d="M2026.9,392.7c-2.1,1.1-4.2,1.4-4.5,4c1-0.4,6.4-0.7,6.7-1.7C2029.6,393.1,2029.1,391.8,2026.9,392.7z" />
							<path fill="none" d="M1965.3,415.2c1-4.5-5.9-2.8-7.2-1.6C1954.4,417.2,1964.1,420.8,1965.3,415.2z" />
							<path fill="none" d="M2025.8,406.7" />
							<path fill="none" d="M2009.8,388c-1.2-0.2-2.5-0.1-3.6,0.3c-1.6,0.7-4.2,3.1-2,4.7C2006.9,394.9,2012.3,388.4,2009.8,388z" />
							<path fill="none" d="M2034,410.7c-0.8-2.8-7.9,1.8-8.1,3.4C2025.1,419.7,2035.2,415,2034,410.7z" />
							<path fill="none" d="M2002.9,418.5c4.7,0,8-7.5,2.7-9.6C2000.6,407.1,1996.6,418.5,2002.9,418.5z" />
							<path fill="none" d="M2014.1,368.4c-1,0.1-2.2,0.6-2.8,1.4c-1.7,2.3,1.6,4.2,3.6,4C2018.1,373.4,2017,368.1,2014.1,368.4z" />
							<path fill="none" d="M2023.6,381.3c1.4,0.7,4.8-7.1,4.6-7.9C2026.4,366.1,2020.8,375.7,2023.6,381.3z" />
							<path fill="none" d="M1996.8,385.1c-0.4,3.4-1.8,9.6,3.1,3.1c1.4-1.8,2.4-9.1-1.1-9.5C1996.1,378.3,1997.2,383.5,1996.8,385.1z" />
							<path fill="none" d="M1983.2,375.1c3.2,2.8,5.8,0.2,10.1-0.2c-1.2-3.7,2.5-6-3.4-6.9C1986.2,367.4,1982.7,371.4,1983.2,375.1z" />
							<path fill="none" d="M1970.9,383.4c1.6,2.2,12.1-0.7,9.8-4.3C1977.9,374.9,1968.9,380.5,1970.9,383.4z" />
							<path fill="none" d="M1987.4,419.7c-0.6-1.2-2.8-1.8-4-1.8c-0.7,0-1.4-0.1-2,0.2C1973,422.5,1990.9,425.9,1987.4,419.7z" />
							<path fill="none" d="M2013.3,393.9c-3.4,0-8.7,6.8-4.9,9.6C2009.7,401.6,2022.6,393.9,2013.3,393.9z" />
							<path fill="none" d="M2005.4,429.5" />
							<path fill="none" d="M2017.1,431.4c1.4-0.5-0.5-3.4-1.1-3.7c-2-1.1-9.4,6.2-9.6,7.7C2009.6,433,2012.5,430.9,2017.1,431.4z" />
							<path fill="none" d="M2012.2,443.7c3.7,2.5,7.9-1.7,6.6-5.4C2015.8,430.4,2007.8,440.8,2012.2,443.7z" />
							<path fill="none" d="M2026,428.6c6.3-4.7-11.4-11.5-3.5,3C2023.8,430.5,2024.7,429.6,2026,428.6z" />
							<path fill="none" d="M2009.2,377.9c-1.3,0.4-3.2,1.7-3.4,3.2C2004.6,387.4,2016.5,376,2009.2,377.9z" />
							<path fill="none" d="M2029.2,441.7" />
							<path fill="none" d="M2034.6,431.3c3,0.8-3.3-5.4-2.7-4.4C2033.2,428.9,2027.5,429.4,2034.6,431.3z" />
							<path fill="none" d="M1975.1,420.3c1.5-2,1.8-7.2-1.4-8C1970.6,411.4,1967.2,421.4,1975.1,420.3z" />
							<path fill="none" d="M1979.3,410.9c4.1,5.4,7.5-4.4,4.5-7C1979.6,400.2,1976.2,406.6,1979.3,410.9z" />
							<path fill="none" d="M1990.2,414.1c4.3,1.5,9.8-10,2.8-9.1C1990,405.3,1987.4,413.1,1990.2,414.1z" />
							<path fill="none" d="M1994.8,425.7c1.7-1.3,4.6-6.6,0.8-7.5C1992.3,417.4,1990.3,425.6,1994.8,425.7z" />
							<path fill="none" d="M2034.6,379.1c-4.3-2.7-9.5,7.5-4.4,8.5C2032.8,388.1,2038.4,381.4,2034.6,379.1z" />
							<path fill="none" d="M2039.4,439.4c2.3-2.7-0.3-4.5-0.3-4.9C2039.1,435.1,2035.8,440,2039.4,439.4z" />
							<path fill="none" d="M2017.6,387.8c-3,3.9,11.9,4.5,6.6-1.5C2022.5,384.4,2018.6,386.5,2017.6,387.8z" />
							<path fill="none" d="M2036.7,388.8c-3.5-2-4.3,4.2-2.2,5.2C2037.7,395.6,2040.4,390.9,2036.7,388.8z" />
							<path fill="none" d="M2042.7,424.6c-0.7-0.2-1.7,0.1-2.3,0.4C2032.5,429.1,2051.5,427.8,2042.7,424.6z" />
							<path fill="none" d="M1968,393.4c1.7-2.7-2.9-12.6-5.7-4.9C1961,392.2,1964.6,398.9,1968,393.4z" />
							<path fill="none" d="M1971.7,387.9" />
							<path fill="none" d="M2017.6,380.5c0.3-0.7,0.5-1.5,0.6-2.3c-1.8-0.3-2.6,2.7-2.9,4.1C2016.5,382.4,2017.3,381.6,2017.6,380.5z" />
							<path fill="none" d="M2039,403.8c1-2.8-2.6-6-4.8-3.7C2031.1,403.3,2037.4,408.3,2039,403.8z" />
							<path fill="none" d="M2036.7,418c-1.3-0.9-2.7,0.3-3.6,1.1C2027.2,424.5,2043.2,422.4,2036.7,418z" />
							<path fill="none" d="M1977.1,397.5c3.2,1.9,5-3.7,4.9-5.7C1981.6,380.7,1968.9,392.6,1977.1,397.5z" />
							<path fill="none" d="M1984.4,384.5c1.7,2.1,1.8,5.3,5.6,3.8c1.5-0.6,4.1-5.2,2.9-7.1C1990.8,377.8,1982.5,382.3,1984.4,384.5z" />
							<path fill="none" d="M1969.2,408.7c2.4-1.6,7.9-8.8,2.1-10.5c-2.7-0.8-5,10.2-4,10.9C1967.9,409,1968.5,408.8,1969.2,408.7z" />
							<path fill="none" d="M1955.4,393.8c0.3-0.5,2-12.6-0.8-11C1951.3,384.6,1952.2,392.9,1955.4,393.8z" />
							<path fill="none" d="M1955.7,406c0.3,4.5,7.2,1.4,8.1-1.6C1966.2,396.5,1955.3,395.8,1955.7,406z" />
							<path fill="none" d="M1988.7,400.8c2.9,0.9,5.9-3.1,2.9-5.9C1988,391.5,1980.3,398.3,1988.7,400.8z" />
							<path fill="none" d="M2020.2,414.7c-2.4-2.9-8.1,1-8.3,3.6C2010.9,427.5,2022.9,418,2020.2,414.7z" />
							<path fill="none" d="M1999.3,404.3c4.6,0.4,6.9-3.7,2.5-7c-1.8-1.3-3.1-1-4.7,0.8C1993.9,401.8,1997.5,401.6,1999.3,404.3z" />
							<path fill="none" d="M2032,1033.4c-3-19.1-10.4,1.1-6.7,6C2027.6,1037.5,2030.9,1036.4,2032,1033.4z" />
							<path
								fill="none"
								d="M1942.3,1036.6c5.1-4.4,7.7-4.4,6.3-11.6c-0.1-0.7-1.2-5.4-2.4-5.9
										C1941.6,1017.1,1938.6,1033.7,1942.3,1036.6z"
							/>
							<path fill="none" d="M1969.1,1037.1c8.1-6-19.8-7.2-10,1.4C1961.2,1040.3,1967.7,1038.1,1969.1,1037.1z" />
							<path fill="none" d="M1956.2,1029.9c2.1-1.6,0.6-5.9-2.9-3.9c0.1,0,0.2,7.9,0.3,8C1954,1032.3,1954.8,1031,1956.2,1029.9z" />
							<path fill="none" d="M1938,1012.4c-0.8-6.5-4-5-5.3-0.7C1930.1,1019.9,1939.5,1024.4,1938,1012.4z" />
							<path fill="none" d="M1970.5,1022.3c-1.9-4.4-6.6-7.8-9.7-0.6C1957.5,1029.7,1970.2,1027,1970.5,1022.3z" />
							<path fill="none" d="M1970.4,1008.5c-1.4-5.8-6.7-4.4-7.6,0.5C1961.5,1016,1971.8,1014.2,1970.4,1008.5z" />
							<path fill="none" d="M1981.7,1019.6c-4.8-4-10.3-1.9-5.3,2.2C1980.9,1025.5,1983.9,1024.7,1981.7,1019.6z" />
							<path fill="none" d="M2004.5,1034.9c0.3-5.1,0.8-11.5-4.9-8C1989.9,1032.8,2003.8,1047.5,2004.5,1034.9z" />
							<path fill="none" d="M1914.6,1031.4c2.2,1.7,2.3-4.8-0.6-3.6C1913.2,1028.4,1914.1,1031,1914.6,1031.4z" />
							<path fill="none" d="M2113.4,730c-3-1.2-10.1,3.8-11,7.2c2.5,0.6,5.5,2.9,8.1,2.6C2114.1,739.2,2119.9,732.5,2113.4,730z" />
							<path
								fill="none"
								d="M1926.9,1009.3c-3.2,0.6,0.2,6.6,0.7,8.4c1.2-2.9,0.6-5.7,1-8.6C1928.1,1009.2,1927.5,1009.2,1926.9,1009.3z
										"
							/>
							<path fill="none" d="M1956.2,1011.3c-2.2,4.3-3.9,4.8-2.2,9.9C1954.8,1020.3,1962.1,1010.6,1956.2,1011.3z" />
							<path fill="none" d="M2136.9,606.8" />
							<path fill="none" d="M2086.5,625.5c-7.6,1.2-10,16.5-1.4,16.1C2094.6,641.2,2094,624.4,2086.5,625.5z" />
							<path fill="none" d="M2115.4,719.9c-1-5.1-7.7-9.6-9.7-2.7C2103.3,726.1,2116.6,726,2115.4,719.9z" />
							<path fill="none" d="M2139,632.2c-2.3-2.1-8.1,1.4-7.1,4.3C2133.5,641.4,2145.9,638.6,2139,632.2z" />
							<path fill="none" d="M1953.1,1008.5c1.9-6.4-15.2-5.8-9.4,3.3C1946.6,1016.2,1949.6,1011.1,1953.1,1008.5z" />
							<path fill="none" d="M2066.3,637.9c-6.3,0.3-2.4,22.2,6.6,9.7C2076.7,642.4,2072.6,637.5,2066.3,637.9z" />
							<path fill="none" d="M1903.4,925.5" />
							<path fill="none" d="M1852.4,961.3" />
							<path fill="none" d="M1859,683.3c4.4,5,14.5-1.4,9.2-8.3C1863.6,669.1,1856.4,680.3,1859,683.3z" />
							<path fill="none" d="M2020.3,634.3c-4.7-0.9-4.7,6.6-1.6,8.4C2024.1,645.9,2028.2,635.8,2020.3,634.3z" />
							<path fill="none" d="M1904.5,929.9c-9-3.6-11.9,10-4.9,11.9C1906.1,943.6,1911.2,932.5,1904.5,929.9z" />
							<path fill="none" d="M1922.7,1024.3c2.1-1.7-0.1-14-2.7-14.6C1913.6,1008.3,1915.6,1029.9,1922.7,1024.3z" />
							<path fill="none" d="M2115.4,786.7c0.7-2.1-2-11.1-5.3-8.9c-1.1,0.7-2,8.5-3.7,11.2C2110.1,788.5,2113.6,791.9,2115.4,786.7z" />
							<path fill="none" d="M2128.1,689.6c3.4-3.7,1.7-8.6-4-5.5C2118.8,687,2123.6,694.5,2128.1,689.6z" />
							<path fill="none" d="M2141.8,644.3c-4.2-2.6-7.8,9.4-6.9,11.3C2139.1,664.2,2147.5,647.9,2141.8,644.3z" />
							<path fill="none" d="M2048.4,625.2c0.9,5.1-1.9,7.4,3.2,7.1c1.3-0.1,5.8-6.7,6.2-7.5C2062.1,615.2,2050.2,616.6,2048.4,625.2z" />
							<path fill="none" d="M2119.2,713.1c4.7-0.4,9.9-10.4,3.5-12.2C2118.6,699.7,2112.4,713.7,2119.2,713.1z" />
							<path fill="none" d="M2148.4,606.6c-2.7-2.8-10.4,1.8-7.1,5.7C2144.5,615.9,2152.1,610.5,2148.4,606.6z" />
							<path fill="none" d="M2132.8,678.8c4.7,1.7,11.3-10.9,5.6-12.4C2135.6,665.6,2128.9,677.4,2132.8,678.8z" />
							<path fill="none" d="M2036.5,960.6c-2.3-0.5-2.9,3.7-2.5,5.2C2036.1,967.1,2039.7,961.3,2036.5,960.6z" />
							<path fill="none" d="M2091.9,602.5c-6.4-0.3-7.2,15.1-1.2,14.2C2095.2,615.9,2097.2,602.7,2091.9,602.5z" />
							<path fill="none" d="M2135.5,598.8c-4,5.1,5.9,3.4,6.7,2.8C2148.8,597.1,2139.1,594.2,2135.5,598.8z" />
							<path fill="none" d="M2113.1,608.4c-1.7,2.6,0.4,5.5,1.9,7.4C2123.3,610,2115.4,604.8,2113.1,608.4z" />
							<path fill="none" d="M2110.7,616.8c-5.3-7.5-7.7-0.7-6.8,5.5C2105.6,634.8,2112.5,620.9,2110.7,616.8z" />
							<path fill="none" d="M2097.4,618c-6.1,0-1.8,12.5,1,11.4C2100.9,628.4,2099,618,2097.4,618z" />
							<path fill="none" d="M2043.1,793.5c2.4-3.9-4.7-7.4-7.6-5.7c-4.1,2.5-0.1,7.1-2.9,11.6C2040.1,798.4,2039.2,799.8,2043.1,793.5z" />
							<path fill="none" d="M2030.1,774.4c3.2,2.4,7.4-13.7,1.2-14.9C2023.7,758.1,2026.6,771.8,2030.1,774.4z" />
							<path fill="none" d="M1997.8,810.8c2.7-3,3.2-11.7-2.7-10.8C1988.6,801.2,1992,810.6,1997.8,810.8z" />
							<path fill="none" d="M1977.1,830.9c-0.1-3.5,0.4-7.5-2.9-6.7C1967.2,825.9,1977.4,846.9,1977.1,830.9z" />
							<path
								fill="none"
								d="M1982.1,814.7c1.2-0.8,7.6-0.6,7.8-1.6c0.1-0.4-5.9-8.3-5.4-8.1c-3.9-1.6-6.4,0.7-7.7,3.9
										c-0.6,1.5-0.6,5.9,0.1,7C1979.5,820.1,1979.1,816.6,1982.1,814.7z"
							/>
							<path fill="none" d="M2074,798.5c1.3,7.2,10,1.5,6.6-5.8C2076,783.1,2073,792.1,2074,798.5z" />
							<path fill="none" d="M2088.7,805.6c5.8,0.8,11.4-8.5,4.2-11.2C2085.6,791.5,2083.9,804.9,2088.7,805.6z" />
							<path fill="none" d="M2084.5,788.9c1.5,2.4,2.8-0.5,4.9-1.2C2086.8,785.1,2081.9,783.7,2084.5,788.9z" />
							<path fill="none" d="M2066,779.8c1.9,2.5,3.6,5.3,4.8,8.2c1.1-2.6,9.8-26.8-0.1-26.1C2064.6,762.3,2064.5,777,2066,779.8z" />
							<path fill="none" d="M2099.9,792c2.6,0.1,6.2-11.5,0.1-13C2091.3,776.7,2093.8,791.7,2099.9,792z" />
							<path fill="none" d="M2056.7,790.2c5.8-5.6,3.8-15.7-4.7-9.1C2042.1,789,2053.9,786.9,2056.7,790.2z" />
							<path fill="none" d="M1868.4,654.6c5,4.3,12.9-15.6,1.4-14.5C1864.2,640.5,1864.7,651.4,1868.4,654.6z" />
							<path fill="none" d="M2002.2,924.5c-3.7-10.9-11.5-3.3-7.7,2.7C1996,929.6,2004.3,930.6,2002.2,924.5z" />
							<path fill="none" d="M2116.3,761.5" />
							<path fill="none" d="M1907.6,699.4c-2.2,0.1,0.6,8.2,2.1,9.8C1911.4,705.5,1915.1,699.2,1907.6,699.4z" />
							<path fill="none" d="M2011.7,931.1c-0.5-1.9-6.9,0.1-0.7,4.4C2012,934.1,2012.2,932.7,2011.7,931.1z" />
							<path fill="none" d="M2018.3,951.2c-6.4-2-9.3,9.7-5.8,13.8C2013.2,963.1,2024.4,953,2018.3,951.2z" />
							<path fill="none" d="M2009.9,939.7c-4.2-1.1-8.1-4.1-9.9,2.3c-0.6,2.2,2.1,9.8,4.5,10.7C2010.4,954.9,2015,944.4,2009.9,939.7z" />
							<path fill="none" d="M2022.3,963.4c-7.5-0.1-5.6,6.4-2.7,10.8C2021.2,971.8,2032.4,963.6,2022.3,963.4z" />
							<path fill="none" d="M1990.4,794.7c-0.8-6.7-11.2-6.3-9.6,0.2C1981.5,797.9,1991.4,802.8,1990.4,794.7z" />
							<path fill="none" d="M1992.9,906c-4.9-9.9-11,4.8-3.3,11.2C1993.9,911.6,1996.2,912.6,1992.9,906z" />
							<path fill="none" d="M1930.1,732.6c-3.1,0-4.9,10-2.4,11.2C1933.1,746.4,1939.2,732.6,1930.1,732.6z" />
							<path fill="none" d="M1982.8,889.8c-5.1-3.1-10.1,7.6-2.9,10.4C1985.4,902.4,1989.4,893.8,1982.8,889.8z" />
							<path fill="none" d="M1974.4,874.1c-8.3-4.1-6.8,6-4.8,10C1975.6,895.9,1984.1,879,1974.4,874.1z" />
							<path fill="none" d="M2003.6,933.7" />
							<path fill="none" d="M2031.5,970.1c-8.2-2.9-5,14.6-1.1,18.7C2030.5,983.5,2036.8,974.4,2031.5,970.1z" />
							<path fill="none" d="M1937.2,896.1c-5.8,0-5.3,19.1,1.5,18.1C1945.8,913.2,1942.6,896.1,1937.2,896.1z" />
							<path fill="none" d="M1929,955c5.4,1.3,6.2-10,1.5-9.9C1927.5,945.1,1925.5,954.1,1929,955z" />
							<path fill="none" d="M1946.2,871.4c-6.6-2.8-4.7,15.1-4.5,17.9C1946.5,885.7,1956.4,875.8,1946.2,871.4z" />
							<path fill="none" d="M1949.9,867.1c0-1.4-0.8-0.2-1.3,0.3C1950,869.2,1950.4,869.1,1949.9,867.1z" />
							<path fill="none" d="M1935.8,966.5c1.5-1.6,8.8-2.1,6.1-6.8C1937.7,952.6,1926.7,965.3,1935.8,966.5z" />
							<path fill="none" d="M1994.8,981.9c2.6,0.9,7.5-1.3,7.7-4.1C2003.3,968.2,1987.6,979.3,1994.8,981.9z" />
							<path fill="none" d="M1983.2,971.4c8.8,6.8,15.8-5.5,6.9-8.4C1985.6,961.5,1978.9,968.1,1983.2,971.4z" />
							<path fill="none" d="M1974.2,960.9c2.4,3.9,5.5,1.3,5.9-2.4C1981.1,949.8,1968.1,951,1974.2,960.9z" />
							<path fill="none" d="M1960.2,962.2c4,2.3,10.4-3.6,6.1-6.8C1962.4,952.4,1956.7,960.3,1960.2,962.2z" />
							<path fill="none" d="M1969.3,950.6c3.9-2.7,0.7-12.5-4.4-10.8c-1.3,0.4-5.2,9.2-5,10.6C1963.9,947.8,1966.7,952.3,1969.3,950.6z" />
							<path fill="none" d="M2014.5,918c-6.6-11.7-11.4,9.2-5.8,7.7C2009.2,922.5,2011.6,919.4,2014.5,918z" />
							<path fill="none" d="M1982.4,1031.2c-1.1-5.2-10.8-7.2-9.1-0.2C1974.2,1034.8,1983.9,1038.1,1982.4,1031.2z" />
							<path
								fill="none"
								d="M2007.3,1022.9c0.2,0.9,0.6,3.8,1.7,4.1c2.9-4.6,11.8-3.9,7.4-9C2012.4,1013.7,2006.3,1017.4,2007.3,1022.9z
										"
							/>
							<path fill="none" d="M1891,725.5c1.6-2.5,10.1-22.5,0.1-19.5C1886.6,707.4,1890.2,722.2,1891,725.5z" />
							<path fill="none" d="M1929.9,912.4c-4.1-2.1-12,8.2-7.9,10C1922.5,919.8,1931.2,913.1,1929.9,912.4z" />
							<path fill="none" d="M2038.8,1019.8c1-4.5-1.8-8.4-4.8-3.2C2030.9,1021.8,2037.8,1024,2038.8,1019.8z" />
							<path fill="none" d="M1908.5,926c2.5,2.5,6.7,0.5,7.3-2.8C1917.2,915,1902.8,920.1,1908.5,926z" />
							<path fill="none" d="M1972.2,841.4c-2.2-1-0.4,0.6-0.7,1.5C1971.4,843.5,1975,842.7,1972.2,841.4z" />
							<path fill="none" d="M2014.1,776.6c2.5,0.2,9.8-7.7,8-9.6C2019,763.9,2011.8,776.6,2014.1,776.6z" />
							<path fill="none" d="M2089.8,782.5c0.3-2.7,2-5.7,4.4-7.1c-3.4-3.3-14.5,1.5-12.6,6.7C2084,780.4,2087.4,781.4,2089.8,782.5z" />
							<path fill="none" d="M2001.7,787.5c-2.5-7.3-9.2-3.7-8.5,2C1994.2,797.9,2004.7,796.1,2001.7,787.5z" />
							<path fill="none" d="M2015.7,765.3c5-2.5,2.1-5-2-4.1c-1.8,0.4-8.7,6.4-5.6,8.9C2009.3,771.1,2014.4,766.1,2015.7,765.3z" />
							<path fill="none" d="M1928.8,853.3c-0.5-1.6-0.7-3.5-0.5-5.2c-1.6,0-1.1,4.3-1.4,5.9C1927.5,853.6,1928.1,853.4,1928.8,853.3z" />
							<path fill="none" d="M1898.4,684.7c-1-5.8-8.4-2.3-8.4,1.9c0.1,0.1,9.7,7,8.9,7.2C1903.6,692.9,1898.7,686.7,1898.4,684.7z" />
							<path
								fill="none"
								d="M1879.4,684.9c1.1,1.1,2.2,2.2,3.3,3.3c1.9-1.9,11.3-13.7,3.1-14.6C1881.1,673.1,1876.2,681.5,1879.4,684.9z
										"
							/>
							<path fill="none" d="M1898.2,703.5c1.2,1.3,5.8,9.5,5.4,1.1C1903.3,698.5,1893.4,698,1898.2,703.5z" />
							<path fill="none" d="M1886.2,703.9c3.8-2.7,10.1-13.9,1.1-12.5C1882.3,692.1,1884.2,701.2,1886.2,703.9z" />
							<path
								fill="none"
								d="M1872.1,673.5c1.4,1.3,2.1,2.8,2.2,4.5c1-1.3,4.5-4.5,5.3-6.3c1.2-2.8,1.4-14.3-4.1-14.5
										C1867.8,656.9,1870.8,670.2,1872.1,673.5z"
							/>
							<path fill="none" d="M1927.7,834.3c0.6,3.8-3.1,9,1.9,8.6c5.7-0.4,4.9-20.4,1-23.7C1924.4,825,1926.5,827.5,1927.7,834.3z" />
							<path fill="none" d="M1923.4,836.4c0.9-2.9-2.8-3.6-3-0.9C1921.6,837,1922.6,837.3,1923.4,836.4z" />
							<path fill="none" d="M1904.5,724.4c2.8-3.2,7.8-8.5,2.6-11.3C1899.3,708.8,1900.6,720.2,1904.5,724.4z" />
							<path fill="none" d="M1854.4,661.7c3.7-1.6,6.8,1.6,7.9,4.8c3-2.1,4.3-12.2-1-12.2C1859.2,654.4,1854.5,661.8,1854.4,661.7z" />
							<path fill="none" d="M2081.7,619.7c-4.6-4.6-12.2-1.8-13,4.7C2066.6,643.3,2084.7,622.7,2081.7,619.7z" />
							<path fill="none" d="M2086.4,1018.9c-2.5,0.6-1.8,3.3-1.9,4.5C2084.5,1025.1,2092,1017.6,2086.4,1018.9z" />
							<path fill="none" d="M2016.1,877.5c-2.5-4.9-8.9-0.4-7.9,2.8C2009.7,884.7,2019.3,884,2016.1,877.5z" />
							<path fill="none" d="M1986.2,864.5c-1.7,4.9,0.8,8.5,2.8,2.4C1990.3,862.9,1988.9,857.5,1986.2,864.5z" />
							<path fill="none" d="M2005.9,874.6" />
							<path
								fill="none"
								d="M2036.6,998.3c-2.2,2.2-5,9.6,0.2,10.1c3.4,0.3,0.7-2,4-4.1c-0.5,0.3,6.8-5.1,6.1-3.3
										C2049.5,993.9,2039.5,997.4,2036.6,998.3z"
							/>
							<path fill="none" d="M2013.9,1039.1c5.8,6.3,7.2-12.8,0.6-11.8C2007.3,1028.3,2011.4,1036.4,2013.9,1039.1z" />
							<path fill="none" d="M2037,874.8c-2.6-2.8-6.2,8.7-1.2,7.1C2038.3,881.1,2038.5,876.4,2037,874.8z" />
							<path
								fill="none"
								d="M2041.8,1030.6c2.4-2.3,0.9-6.3-2.3-5.2c-1.8,0.6-5.4,8.1-2.6,9C2037.8,1034.7,2041.1,1031.3,2041.8,1030.6z
										"
							/>
							<path fill="none" d="M2041,885c-0.3,4.1,3.5,5.7,6.7,5.9c-0.6-2.2,0.2-1.8-0.1-3.8C2047.2,884.7,2041.3,879.1,2041,885z" />
							<path fill="none" d="M2026.2,874.4c-2.7-0.2-5.9,8.3-1.8,8.9C2029.2,884.1,2029.1,874.6,2026.2,874.4z" />
							<path fill="none" d="M2010.3,862.9c-3.3,0-6.7,11-4.4,11.4C2008.7,871.8,2021,862.7,2010.3,862.9z" />
							<path fill="none" d="M1998.1,862.9c-3.9-0.2-6.9,7.1-3.9,9C1999.1,875.2,2006.9,863.4,1998.1,862.9z" />
							<path fill="none" d="M1993.2,880.4c-1.3,6.6,5.5,8,7.3,1.8C2002.3,875.6,1994.1,875.8,1993.2,880.4z" />
							<path fill="none" d="M2053.4,1015.2c-3-8-12.4-14.2-8.5-1.2C2046.9,1020.6,2056.7,1023.9,2053.4,1015.2z" />
							<path fill="none" d="M1954.3,1070.8c3.2-1.6,6.8-11.1,0.4-10C1950.4,1061.5,1948.8,1070.5,1954.3,1070.8z" />
							<path
								fill="none"
								d="M1949.2,1052.6c0.7,1.8,1.3,3.5,1.7,5.4c1.1-0.9,2.3-1.4,3.7-1.5c-1.7-3-1-17.7-2.8-18.9
										c-2.6-1.6-6.6,2.1-7.1,4.5C1944.2,1044.6,1948.2,1050,1949.2,1052.6z"
							/>
							<path fill="none" d="M1995.8,1023.5c0.9-4.6-7.5,1.3-8.5,3.2C1993.2,1030.6,1991.4,1028.7,1995.8,1023.5z" />
							<path fill="none" d="M1932.6,1065.9c7.5-3.6,1.6-11.7-1.1-15.8C1927.2,1053.8,1922.6,1070.8,1932.6,1065.9z" />
							<path
								fill="none"
								d="M1923.1,1046.6c1.2,1.4,2,2.9,2.7,4.6c1.5-2,2.7-4.1,5.1-5c3.2-1.2,2.9,2.9,6.1,1c2.5-1.5,3-3.5,2.2-6.5
										c-1.5-5.5-6.9-3.1-9.2-5.8c-4.5-5.1,3.4-4.3,7.1-2.4c-0.2-2.6-0.1-5.4,0.4-7.9c-4.6,1.3-5.4-3.3-9.3-1.8c1-0.4-6.6,8.1-6.5,7
										c-0.1,1.2,5.7,3.8,6.6,6.3c0.5,1.3-0.5,12.2-4.1,9.2c-2.3-2,3.2-16.7-7.4-8.8C1907.1,1043.7,1920.1,1043.2,1923.1,1046.6z"
							/>
							<path fill="none" d="M1943.9,1071.7c4.6-2.6,4.6-20.6,0.2-21C1938,1050.1,1939.6,1070.2,1943.9,1071.7z" />
							<path fill="none" d="M1989,1065.3c3,0,2.9-2.6,1.5-5.4C1988.8,1057,1984.3,1065.3,1989,1065.3z" />
							<path
								fill="none"
								d="M1988.7,1055.3c0.8-0.1,1.6,0,2.4,0.3c2.2-2.5,1.6-22.1-2.9-21.5C1982.9,1034.8,1984.6,1053.4,1988.7,1055.3
										z"
							/>
							<path fill="none" d="M1976.7,1067.7c2.3-1.3,11.2-11.5,2.7-13.3C1973.6,1053.1,1972.9,1066.7,1976.7,1067.7z" />
							<path fill="none" d="M1966.6,1045.6c0-7.5-17,4.1-6.9,10.8C1961.3,1053.8,1966.6,1048.9,1966.6,1045.6z" />
							<path
								fill="none"
								d="M1974.3,1052.2c3.2-2.4,6-2.4,5.5-7.3c-0.3-2.6-0.9-4.9-3.9-5.1C1969.7,1039.4,1968.5,1053.4,1974.3,1052.2z
										"
							/>
							<path fill="none" d="M2093.8,1017.8c-2,5.8,4,3,4.2,1C2098.7,1013,2094.3,1016.3,2093.8,1017.8z" />
							<path fill="none" d="M2105.1,1009c-2.1-0.4-4,1.3-4.5,3.2C2098.3,1020.3,2112.9,1010.3,2105.1,1009z" />
							<path fill="none" d="M2110.2,1005.6c-2.3,2.7,2.5,5.4,3.6,2C2114.9,1004.3,2113.7,1001.5,2110.2,1005.6z" />
							<path fill="none" d="M2030.8,873.7c0.3-0.5,0.6-1,0.9-1.5C2030.2,872.3,2029.9,872.8,2030.8,873.7z" />
							<path fill="none" d="M2102.2,1020.1" />
							<path fill="none" d="M2123,1002.4c3.2-4.9-5.5-1.5-4.4-1C2118.2,1001.2,2121.2,1005.1,2123,1002.4z" />
							<path fill="none" d="M1934.6,854.7c4.1,1.3,4.3-8.2,0.4-9C1931.1,844.9,1931.7,853.8,1934.6,854.7z" />
							<path fill="none" d="M2157.7,565.3c-6.7,3.9,2.8,9.6,5.2,5.3C2164.3,568,2160.5,563.6,2157.7,565.3z" />
							<path fill="none" d="M2174.6,565.1c0.8-1.2-6.4-1.8-7.5-0.3C2166.7,564.6,2173.8,566.2,2174.6,565.1z" />
							<path fill="none" d="M2152.2,930.7" />
							<path fill="none" d="M2074.6,1026.7c1.8,4.8,5.4-2.4,3.8-1.7C2078,1025.2,2072.4,1021.2,2074.6,1026.7z" />
							<path fill="none" d="M2168.2,572.5c0.7,0,3.8-0.8,3.7-2.3C2171.5,565.7,2163.1,572.6,2168.2,572.5z" />
							<path fill="none" d="M1914.1,1056.9c1.8,12,11.7-1.4,6.5-6.5C1915.3,1045.1,1913.4,1052.2,1914.1,1056.9z" />
							<path fill="none" d="M2105.3,966.2c-3.1,2.9,0.1,6,0.4,5.1C2106,970.3,2106.7,964.8,2105.3,966.2z" />
							<path fill="none" d="M2008,625.4" />
							<path fill="none" d="M2105.6,982.1c2.2-1.2-0.2-4.2-1.7-4.8C2100,975.6,2099.7,985.4,2105.6,982.1z" />
							<path fill="none" d="M2021.7,864.5c-1.2,0.8-4.1,6.8-1.9,8.6c2.3-2.5,9.8-3.5,10.6-4.8C2033.6,863.4,2025.3,862,2021.7,864.5z" />
							<path fill="none" d="M1897.6,627.3c2.4,5.9,7.9-0.4,6.2-2.3C1902.1,622.9,1898.3,624.6,1897.6,627.3z" />
							<path fill="none" d="M1922.8,623.8c-3.6,0-1.1,3.6-1.3,4.9C1921.4,630.2,1929.3,624,1922.8,623.8z" />
							<path fill="none" d="M1968.1,625.8c2.7-2.2-2.6-7.1-4.5,0.4C1962.3,631.4,1967,626.5,1968.1,625.8z" />
							<path fill="none" d="M1910.4,625.2c-2.8,1.2-2.1,4.2,1.3,4.5C1914.8,630.1,1919.1,623.6,1910.4,625.2z" />
							<path fill="none" d="M1828,1006.2" />
							<path fill="none" d="M1954,649c-1.1-0.4-2,0.2-2.8,0.8C1942.8,655.6,1962.3,651.8,1954,649z" />
							<path fill="none" d="M1899.9,726.1c-3.5-4.7-6.3,5.7-6,7.5c0.5,3.1,2.3,3.3,3.9,5.7C1900.8,735.6,1903.2,730.5,1899.9,726.1z" />
							<path fill="none" d="M1887.3,626.8c-4.2,3.7,3.4,10.1,5.8,8.8C1896.9,633.5,1892.1,622.6,1887.3,626.8z" />
							<path fill="none" d="M1893.9,645.4c-4.1,0.8,3,13.2,3.3,14.9C1899,657.5,1900.5,644,1893.9,645.4z" />
							<path fill="none" d="M1875.4,698.7c6.2-0.4,5.9-8.3,0.3-8.6c-4.4-0.2-5.9,7.7-4.6,10.1C1872.3,699.2,1873.8,698.7,1875.4,698.7z" />
							<path fill="none" d="M1882,637.7c-2.6,4-1.7,14.1,0.7,18.2c1.3-3.9,7-10.9,6.4-14.9C1888.8,637.9,1885.4,634.7,1882,637.7z" />
							<path fill="none" d="M1912.2,690.9c-1.9-0.8-6.7,2.5-7,4.7C1906.5,695.2,1923.2,695.4,1912.2,690.9z" />
							<path fill="none" d="M1902.4,664.9c-2.6-0.5-6.6,1.2-8.1,3.5c-2.4,3.5-1.5,9,3.9,9.1C1902.3,677.7,1909.1,666.3,1902.4,664.9z" />
							<path fill="none" d="M1907.5,677.5c-4.1,0-6,6.6-3.3,9.3C1910.7,693.2,1912.3,677.5,1907.5,677.5z" />
							<path fill="none" d="M1861.9,626.6c-10.4-3.2-3.2,9.2,1.5,11.4C1863.7,634.8,1865.5,627.7,1861.9,626.6z" />
							<path fill="none" d="M1882,1044.5c2.7-1.3,4.1-11.8,0.9-13.2C1880.4,1030.2,1874.8,1043.7,1882,1044.5z" />
							<path fill="none" d="M1856.7,674.9c1.4-1.8,4-8.4-0.9-8.8C1851,665.8,1851.5,674.2,1856.7,674.9z" />
							<path
								fill="none"
								d="M1850.7,641.6c-0.8,0.8-1.5,1.6-2.2,2.5c2.6-0.4,10.6,2.9,10.8,2.7c1.7-1.2-0.1-4.8-0.8-6.2
										C1855.4,635,1854.4,637.8,1850.7,641.6z"
							/>
							<path fill="none" d="M1851.6,657.3c2.8-2.5,5.7-8-0.2-8.6C1844.5,648.1,1846.1,662.2,1851.6,657.3z" />
							<path fill="none" d="M1870.5,630.3c-1.7,6.2,5.1,8.9,8.6,4.5C1884.5,628.2,1873.6,618.9,1870.5,630.3z" />
							<path fill="none" d="M1986.7,627.3c-8-6.9-10.6,12.7-3.9,13.1C1987.7,640.6,1990.1,630.5,1986.7,627.3z" />
							<path fill="none" d="M1974.6,657.8c5.6,6.1,13.4-8.2,6.8-12C1974.8,641.9,1970.6,653.3,1974.6,657.8z" />
							<path fill="none" d="M2010.4,630.3c-4.8-3-9.7,9.5-8.6,12.9c3.8-3.8,8.7,1.5,10.9-0.8C2014.1,641,2011.8,631.2,2010.4,630.3z" />
							<path fill="none" d="M2019.5,620.8c-1.5-0.8-4.2-0.6-5.5,0.3c-3,2.1-1.4,8.4,2.6,8.2C2018.8,629.2,2022.1,622.3,2019.5,620.8z" />
							<path fill="none" d="M2062.8,626.5c-0.8,1.1-9.7,11.6-2.7,10.8C2063.6,636.9,2068,626.5,2062.8,626.5z" />
							<path fill="none" d="M1932.3,712.5c0.3,1.8,7.9,1.7,9,0.3C1945,707.4,1930.5,701.5,1932.3,712.5z" />
							<path fill="none" d="M1977.3,690.2c0.4,6.6,7.9,7.8,9.9,1.7C1990.4,682.1,1976.5,677,1977.3,690.2z" />
							<path fill="none" d="M1946.3,710c-0.9,3.3-2.9,14.5,3.8,6.9C1953.6,712.9,1948.1,703.9,1946.3,710z" />
							<path fill="none" d="M1930.1,722.3c-1.3,5.8,4.9,6.5,7.5,2.4C1942.2,717.2,1932.4,714.7,1930.1,722.3z" />
							<path fill="none" d="M1974.2,704.7c-1.5-0.5-2.9,0.9-3.9,1.8c-3.4,3-2.9,8.8,2.4,8C1979.5,713.6,1975.7,707.5,1974.2,704.7z" />
							<path fill="none" d="M1968.5,687.7c-5.2-1-3,11-1.3,12.4C1971.9,703.9,1976.7,689.2,1968.5,687.7z" />
							<path fill="none" d="M1865.7,695.1c-0.1,0,2.5-4.5,3.5-5.5C1864.3,684.2,1856.1,695.1,1865.7,695.1z" />
							<path fill="none" d="M1888.2,919.6c-8.3,2-7.7,14.6,1.3,12.6C1898.1,930.3,1893.6,918.3,1888.2,919.6z" />
							<path fill="none" d="M1860,938c-3.8-0.8-10.8,6.6-5.1,8.3C1856.8,946.9,1868.8,939.9,1860,938z" />
							<path fill="none" d="M1925.2,899.6c-8.6-3.2-7.5,7.1-4.6,11.7C1925.1,910.2,1935.1,903.2,1925.2,899.6z" />
							<path
								fill="none"
								d="M1824.5,969.8c-6.5,1.7-3.7,6.7-0.4,8.4c1.8,0.9,13.3,0.3,13.4,0.7C1836.5,976.2,1826.8,969.2,1824.5,969.8z
										"
							/>
							<path fill="none" d="M1901.2,911c-2.4-4.4-5.5-5.3-7.6,0.5c-2.3,6.4,3.9,5.9,5.2,12.1C1904,922.3,1903.6,914.3,1901.2,911z" />
							<path
								fill="none"
								d="M1849.1,1007c0.1,0.9,0.2,1.9,0.3,2.8c2.7-1.6,5.5-0.3,7.7-0.7c3.2-0.6,5.4-5.3,9-0.9c0.8-4.8,4-7.5,8.3-8.9
										c2.3,0.2,4.2,1.2,5.8,3c0.8-1.5,1.9-2.3,3.5-2.7c6.5,0.4,5.3,0.6,10.1,4.5c1.9,1.6,6.1,2.2,8,5.3c3.4-4.7,10.1-4,11.2,2.1
										c2.6-8.1,5.3-4.8,10-5c4.3-0.2,12.5-5.4,16.3-1.4c1.4-3.9,5.1-5,8.7-5c7.3,0.1,6.3,5,10.6,7.4c1.7-7.6,11.4-9.6,14.8-2
										c4.6-4.8,9.9-4.7,14-9.9c5.8-7.2,2.4,2.7,3.3-6.8c0.3-3.1,0.7-7.2-1.9-10c-1.4-1.5-5.7-3.1-8.5-4.6c-1.1-0.6-4.2-1.3-4.8-1.8
										c-1.2-1.3,0.2-3.4-0.6-4.4c-1.2-1.6-3.3-3.8-4.6-6c-1.4,2.4-3.8,3.6-5.2,5.3c-1,1.2,1.2,5.8-2.1,5.3c-4-0.6-0.3-3.7-1.9-5.4
										c-0.3-0.3-3.4-2.6-4.6-2.4c-4.1,0.6-0.7,2.9-2.8,5.3c-1,1.1-0.6,4.5-3.2,3.1c-0.4-0.2-0.4-0.6-0.6-0.9c-3.9-5.1,2.3-4.4-4.3-8.7
										c-1.3,3.4-4.5,3-6.3,5.9c-1.3,2.1,2.2,8.2-3,6.2c-1.3-0.5-0.2-4.3-0.8-5c-1.4-1.5-3.6-0.9-4.9-3.1c-1.9-3.2-0.4-6.4-1.5-8.7
										c-0.8-1.7-5.1-1.2-5.8-4.8c-2.1,2.3-3.4,9.6-5.1,8.9c-3.1-1.4-0.2-4.8-1.5-7.2c-0.9-1.6-5-1.6-6.4-4.6c-1.5,1.9-1.7,6.4-2.8,7.6
										c-4.6,5-1.4-0.1-4-1.9c-3.4-2.3-1.6-2.9-5.1-0.2c-0.1,0.1-4.8,6.1-2.9,5.3c-1.2,0.5-1.6,0.9-3,1c2.3-0.2-6.4-6.5-5-5.6
										c0,0.7-6.2,11.2-6.3,11.3c-2.5,1.8-1.8,0.2-3.4-1.1c1.9,1.5-2.3-5.8-2.6-6.1c0.3,3.7-1.4,10.6-3.5,12.9c-0.4,0.4,0.6,2.2-0.8,2.6
										c-3.5,1.2-2.2-3.8-3.6-3.8c-6.3-0.1-4.9,5.2-7.7,9.6c-2.4,3.8,1,4.1-3.9,1.6c0.8,0.4-3.5-3.9-3.4-3.8c-2.1-2.5-4-3.6-4.8-7
										c-0.1-0.5,2-18.7-4.5-11.1c-0.6,0.7-2,11.5-2,11.2c0.7,5.9,6.2,4.3,9,9.9c2.1,4.3,0.1,8.2-0.9,12.3c-2.9,11.6-2.7-3.2-2.3,4.9
										C1847,1002.6,1848.8,1005.1,1849.1,1007z"
							/>
							<path fill="none" d="M1869.8,1036.9c5-1.5,7.4-9.4,0.7-9.3C1865.3,1027.7,1863.3,1038.9,1869.8,1036.9z" />
							<path fill="none" d="M1878.8,1028.4" />
							<path fill="none" d="M1830.6,992.6c8-2.8,0.4-13.5-5-10.3C1821.4,984.9,1825.1,994.5,1830.6,992.6z" />
							<path
								fill="none"
								d="M1932.1,873.1c2.4,0.3,4.3,2.2,5.5,4.2c0.1-8.4,10.5-11.3,8.7-19.3c-2.9-12.9-6.4,4.7-7.4,8.1
										c-4.1,14.9-2.2-1.5-6.3-5.7C1925.3,852.8,1924,872,1932.1,873.1z"
							/>
							<path fill="none" d="M1853,1019.2c1.5-1,1.5-5,0.1-5.8c-2.9-1.8-6.1,6.4-4,8.7C1850.7,1021.6,1852,1020.6,1853,1019.2z" />
							<path fill="none" d="M1909.6,788.5c3.2,9,12.9,5.3,10.6-2.9C1917.6,776.8,1906.5,779.9,1909.6,788.5z" />
							<path fill="none" d="M1917.2,859c5.4,3.5,9.3-16.8,3.7-18.2C1915.7,839.6,1913.3,856.5,1917.2,859z" />
							<path fill="none" d="M1907,750.3c4-1.1,0-11.3-3.4-11.2C1899.8,739.2,1901.6,751.8,1907,750.3z" />
							<path fill="none" d="M1886.1,740.7c3.3-2,5.1-10.5-0.5-10.5C1880.4,730.2,1879.2,740.3,1886.1,740.7z" />
							<path fill="none" d="M1914.1,771.3c6.3,0.3,0-12.8-2.1-15.6C1909.9,759.9,1906.1,770.9,1914.1,771.3z" />
							<path fill="none" d="M1879.3,727.9c3.3,2.1,7.1-8.3,5.3-10.7C1879.2,709.9,1873.3,724.1,1879.3,727.9z" />
							<path fill="none" d="M2071.7,603c-0.8-0.4-1.6-0.7-2.3-1.1c-1.3,2.3-2.4,10.2-0.8,12.2C2074.8,615.1,2072.7,606.3,2071.7,603z" />
							<path fill="none" d="M1907.5,817.3c2-4.1,6.8-12.2-1.2-12.7C1898.6,804.2,1898.2,817,1907.5,817.3z" />
							<path fill="none" d="M1873.9,713.6c1.7,1.6,12.6-6,5.1-9.9C1873.1,700.7,1869.4,709.4,1873.9,713.6z" />
							<path fill="none" d="M1912.4,840.2c4.8-0.6,6-11.3,2.3-13.1C1906.3,823,1905.1,841.2,1912.4,840.2z" />
							<path fill="none" d="M1896.8,775.9c-10.2,4.3,3.4,8.5,7.1,5.1C1909,776.4,1901.6,773.9,1896.8,775.9z" />
							<path fill="none" d="M1897,793.2c1-1.2,8.7-5.7,7.7-7.5C1900.9,778.8,1890,792.9,1897,793.2z" />
							<path fill="none" d="M2121,768c-5.2-5.5-11.5,0.7-7.1,6C2117.7,778.6,2125,772.2,2121,768z" />
							<path fill="none" d="M2088.1,726.6c-1.6,0.8-1.5,1.2,0.3,1.3c0.9-0.7,1.5-1.7,1.8-3C2089.4,725.4,2088.8,726,2088.1,726.6z" />
							<path fill="none" d="M2073,712.5c-1.5-0.3-3.4,2.9-3,4.1C2073.3,716.9,2078,713.3,2073,712.5z" />
							<path fill="none" d="M2100.6,704.5" />
							<path fill="none" d="M2077.4,683.1c-1.6-1.8-7.3-1.9-8.3,0.5c-0.6,1.4,5.1,9.8,7.3,8.8C2080.3,690.8,2079.8,685.8,2077.4,683.1z" />
							<path fill="none" d="M2078.6,719.5c0.2,1.2,2.3,8.2,3.2,8.1c1.8-3.7,7.7-6.6,6.8-11.5C2087.3,708.4,2077.5,711.2,2078.6,719.5z" />
							<path fill="none" d="M2096,702.9c-3.6-8.3-8.5,3.6-10.2,4.5c2.9,0.8,4.2,2.8,6.4,4.6C2094.2,709.5,2097.6,706.4,2096,702.9z" />
							<path fill="none" d="M2081,675.8c-0.7,2.6,0.5,5.8,2.3,7.7c2-2.4,8.2-4.9,6.4-9.2C2087.9,669.9,2082.3,671.3,2081,675.8z" />
							<path fill="none" d="M2079.5,695.3c-19.3,15.5,5.5,15.6,6,3.8C2085.7,695.1,2086.2,692.9,2079.5,695.3z" />
							<path fill="none" d="M2097.7,666.9" />
							<path fill="none" d="M2093.7,720.1c3.8,1,1.1-2.8,0.9-2.5C2094.4,718.2,2091,719.4,2093.7,720.1z" />
							<path fill="none" d="M2062.9,688.8c-6.4,2.9-3.6,16.7,3.8,14.9C2073.9,701.9,2069.4,685.9,2062.9,688.8z" />
							<path fill="none" d="M2065,730.2c2.8-3.7,10.5,2.3,12-0.1c4.2-6.9-10.6-15.1-13.7,1.2C2063.9,731.4,2064.5,731,2065,730.2z" />
							<path fill="none" d="M2093.1,683.3c-2.8-0.2-7.2,3.5-7,6c0.3,4.1,9.4,7.8,12,8.3C2100.2,693.9,2098.8,683.8,2093.1,683.3z" />
							<path fill="none" d="M2006.9,720.4c-0.6-0.8-1.2-1.6-1.8-2.3c-1.4,1.7-2,1.2-2,3.6C2004.3,721,2005.5,720.6,2006.9,720.4z" />
							<path fill="none" d="M2041.1,714.4c-3.6,5.9,5.6,9.6,8.7,3.3C2052.8,711.6,2045,708,2041.1,714.4z" />
							<path fill="none" d="M2088.7,734.1c1.8-5.6-10.2,1.3-11.2,3c-4,6.9,0.5,7.5,6.2,10.6C2083.4,742.3,2085,738,2088.7,734.1z" />
							<path fill="none" d="M2019.6,740.8c1.5-2.9,9.3-23.1-0.5-19.3C2017,722.3,2017.7,739.3,2019.6,740.8z" />
							<path
								fill="none"
								d="M2020.9,700.6c0.9,2.6,2.4,4.9,2.9,7.7c1.8-2,6.4-3,7.4-5c1-2-2.6-13.3-2.6-13.3
										C2024.7,687.9,2019.6,696.6,2020.9,700.6z"
							/>
							<path fill="none" d="M2049.2,732c1.9-0.8,8.7-2.7,9-4.3C2060.3,718.3,2036.8,725.6,2049.2,732z" />
							<path fill="none" d="M2053.7,741.1c3.6,2.5,7.2-2.7,4.7-5.6C2054.6,731,2048.1,737.2,2053.7,741.1z" />
							<path fill="none" d="M2063.9,717c9.7-16-21.5-11-3.1,4.7C2061.9,720.2,2062.9,718.7,2063.9,717z" />
							<path
								fill="none"
								d="M2044.7,673.3c-2.8-2.3-6.4-1.2-8.2,1.6c-3.3,5.2,0.9,4.7,3.8,8.1c2,2.4,3.5,8.5,6.3,5
										C2047.8,686.4,2046.7,675,2044.7,673.3z"
							/>
							<path fill="none" d="M2095,673.2c-2.1,3.4,2.4,9.5,5.8,6.7C2105.8,675.8,2098,668.3,2095,673.2z" />
							<path fill="none" d="M2044.7,747.2c6,1.1,0.8-11.9-1.1-13C2039,731.4,2038.1,746,2044.7,747.2z" />
							<path fill="none" d="M2102.6,730.3c3.5-5.7-1-9.8-6.3-6.8c0,0-4.5,7.1-4.4,7C2095.3,731.6,2100.3,734.7,2102.6,730.3z" />
							<path fill="none" d="M2095.7,655.6c1,1.7,2.2,3.3,3.4,4.8c2.2-3.4,11.9-9.1,4-13.5C2098.7,644.6,2093.7,651.4,2095.7,655.6z" />
							<path fill="none" d="M2072.3,669.3c-6.4,0-11.3,7.6-2.9,9C2075.4,679.3,2080.2,669.3,2072.3,669.3z" />
							<path fill="none" d="M2086.1,660.4c-1.1,1.7-3.6,5.3-3.2,7.4c3.7-1.9,8.9,1,10.5-1.1C2096,663.1,2090.8,652.7,2086.1,660.4z" />
							<path fill="none" d="M2089.3,752c4.2,3,14-12.6,4.9-14.5C2088.7,736.3,2084.9,748.8,2089.3,752z" />
							<path fill="none" d="M2015.5,755.9c2.7-2.2,6.6-6.1,2.7-10.5c-0.3-0.4-0.7-1.3-1.3-1.4C2011,742.5,2007.1,756.2,2015.5,755.9z" />
							<path fill="none" d="M2050.9,672.6c0,0.6,11,3.1,7.6-6.7C2055.2,656.7,2050.9,668.1,2050.9,672.6z" />
							<path
								fill="none"
								d="M2062.9,764.8c1.8-4.2,5.7-13.4,0.4-17.4c-2.7-2-9.1-1-11.9,0.9c-2.5,1.6-3.2,2.5-3.4,4.7
										C2047.4,760.2,2058.7,757,2062.9,764.8z"
							/>
							<path fill="none" d="M2009.1,742.8c1.4,1.5,12.3-9.5,4.3-10.2C2010.2,732.4,2007.2,740.8,2009.1,742.8z" />
							<path fill="none" d="M2050,775.4c1.4,0,9-0.4,9.1-0.5c2.8-1.7,1.2-11.6-3.7-12.4C2052.2,762,2048.5,774.8,2050,775.4z" />
							<path fill="none" d="M2039.3,760.2" />
							<path fill="none" d="M2048.2,763.4c0.1-5.4-8.2,1.1-6.8,5.2C2043.7,775.9,2048.2,765.1,2048.2,763.4z" />
							<path fill="none" d="M2061.7,677.9c-1.4-1.2-2.8-0.9-4.4-0.7c-6.7,1.1-8.4,13.9-1.3,12C2058.1,688.7,2063.8,679.7,2061.7,677.9z" />
							<path fill="none" d="M2014.1,687.7c-3.6-2-13.2,11.6-12.2,14.5C2002.9,705.1,2027.8,695.4,2014.1,687.7z" />
							<path
								fill="none"
								d="M2038.4,730.4c2-2.2,3.5-7.5-0.6-8.8c-2.2-0.7-9.2,6.4-8.4,10c1.3,6.2-1-0.7,0.7,1.8
										C2033.8,738.6,2036.4,732.5,2038.4,730.4z"
							/>
							<path fill="none" d="M2014.9,703.6c-4.1,1.2-6.5,1.5-7.6,6.1c-0.7,2.9,0.9,8.5,4,9.2C2017.5,720.3,2022.5,705.5,2014.9,703.6z" />
							<path fill="none" d="M2005.9,733.6c2.7,0.3,6-6.8,4.1-8.8C2006.2,720.8,1997.6,732.6,2005.9,733.6z" />
							<path
								fill="none"
								d="M2030.7,678.6c-3.5-10.6-17.8,10.9-8.7,9.3c1.5-0.3,2.5-3.7,2.4-3.7c-1.7,0.6,4.4-3,3.4-1.8
										C2029,681,2031.2,684.7,2030.7,678.6z"
							/>
							<path fill="none" d="M1993.2,697.9c7.9,2.6,13.5-10.4,6.1-14.1C1991.8,680,1988.5,696.3,1993.2,697.9z" />
							<path fill="none" d="M2103.2,708.6c-0.7-0.6-1.8,1.1-2.2,1.4C2090.7,718.7,2108.4,712.9,2103.2,708.6z" />
							<path fill="none" d="M1999,717.6c5,0.2,3.9-13.8-1-11.3C1994.5,708,1994.3,717.4,1999,717.6z" />
							<path
								fill="none"
								d="M2110.6,670.6c2.7-7.6-7.7-13.3-8.7-6.1c-0.3,2.1,5.3,12.2,7.2,13.1C2109.3,675.2,2109.8,672.9,2110.6,670.6
										z"
							/>
							<path fill="none" d="M2047.1,693.4c-3.5,5.1-1.1,18.2,6.8,10.9C2058.8,699.6,2050.2,688.8,2047.1,693.4z" />
							<path fill="none" d="M1973.1,860.5c-2.5,4.7-7.8,10.8,2.2,9.7C1986.4,869,1977.2,857,1973.1,860.5z" />
							<path fill="none" d="M2028.5,783.6c3.4-13.9-16.1-5.2-12.1,1.3C2018.4,788.1,2027.7,787.2,2028.5,783.6z" />
							<path fill="none" d="M2038.3,803.2c-2.9-0.1-8.6-0.5-9.3,2.8C2026.8,816.9,2049.3,809.2,2038.3,803.2z" />
							<path fill="none" d="M2009.3,783.4c2-3.6-0.2-11.3-4.6-6.7c-3.2,3.2-0.9,6.1,0.8,9.4C2006.8,785.4,2008.6,784.7,2009.3,783.4z" />
							<path fill="none" d="M2032.5,829.9c3.4,2.7,7.9-1.5,4.4-2.7C2034.2,826.2,2032.3,828,2032.5,829.9z" />
							<path fill="none" d="M1878,1013.1c2.4,1.7,1.4-7.4-1.3-8.7c-3.5-1.7-6.2,1.4-6.5,5.1C1873.8,1009,1875.4,1011.2,1878,1013.1z" />
							<path fill="none" d="M2065.3,800.6c3.9-2.9,1.7-13-2.4-11.6C2058,790.8,2059.5,805,2065.3,800.6z" />
							<path fill="none" d="M2054.3,798.8c4.1-6.1-7.7-7.1-8.3,0.4C2045.4,807.2,2051.7,802.6,2054.3,798.8z" />
							<path
								fill="none"
								d="M2069,748.2c0.6,1.7,3.1-6.8,3-8.4c-0.1-3.3,2.1-5-2.1-6.3c-3-0.9-4.7,1.2-5.6,3.7
										C2062.3,742.7,2067.4,744,2069,748.2z"
							/>
							<path fill="none" d="M1889,1016.4c1.7,3,13.9-0.4,8.1-5.7C1893,1007,1886,1011,1889,1016.4z" />
							<path fill="none" d="M1886.6,1002.9c-3.6,0-3.3,5.6-1.3,5.7C1888,1008.7,1892.7,1002.9,1886.6,1002.9z" />
							<path fill="none" d="M1910.7,1034.3c0.4-8.4-9.5-12.6-9.8-1.4C1900.7,1038.1,1910.4,1040.2,1910.7,1034.3z" />
							<path fill="none" d="M2038.5,984.9c-2-8.9-5.5,5.8-3,9.1C2039.5,991.2,2039.6,989.6,2038.5,984.9z" />
							<path fill="none" d="M1989.5,818.6c-1.7-3.1-6.2-0.8-6.5,1.9C1982,827.5,1992.3,823.5,1989.5,818.6z" />
							<path fill="none" d="M2117.9,602.5c2.6,0.6,5.1,3.3,5.8,5.8C2135.4,597.8,2116.3,596.3,2117.9,602.5z" />
							<path fill="none" d="M2127.9,610.4" />
							<path fill="none" d="M2079.9,613.6c3.7,0.6,3.7-11.1,2.5-13.2C2080.1,602.2,2075.3,612.8,2079.9,613.6z" />
							<path fill="none" d="M2098.7,611.6c1.6,5.3,17.5-12.3,7-13.4C2102.6,598,2098.9,610.6,2098.7,611.6z" />
							<path
								fill="none"
								d="M2128.2,610.8c-2.3,6.1,7.9,16,0,20.4c0.3,0.3,0.5,0.6,0.7,0.9c1.1-1.4,3.9-2.4,4.7-3.7
										c0.3-0.5-1.2-2.7-0.8-3.6c2-4.6,1.3-6.6,6.4-9c-0.3-0.3-9.3-10.5-8.5-10.9C2130.4,605.1,2126.9,612.6,2128.2,610.8z"
							/>
							<path fill="none" d="M2125,775.4c-2.9,2.1-5.9,12.6-0.3,11.6C2128.1,786.4,2129.7,775.3,2125,775.4z" />
							<path fill="none" d="M1999.7,705.7" />
							<path fill="none" d="M2111.7,797.1c0.8-4.3-4.2-3.6-6.5-2.6C2095.2,798.9,2108.8,812.5,2111.7,797.1z" />
							<path fill="none" d="M2122.3,797.2c0-5.7-3.8-7.1-6.2-1.2C2113.4,802.9,2123.4,806.7,2122.3,797.2z" />
							<path fill="none" d="M2132,802.9c1.1-2.2,4.5-14.5,0.8-14.7C2127.4,787.8,2125.9,802.4,2132,802.9z" />
							<path fill="none" d="M2121.6,745.5c-1.4-6.2-9.3-1-4.3,3.4C2119.4,750.8,2122.4,749,2121.6,745.5z" />
							<path fill="none" d="M2145.3,619.1c-4.7-1.9-12.2,5.1-6.6,8.6C2142,629.6,2153.4,622.3,2145.3,619.1z" />
							<path fill="none" d="M1883.7,945.4c-0.1,0.8-0.2,1.6-0.3,2.4c2.3,0.7,9.9-6,6.8-9.4C1884.2,931.9,1883.8,942.8,1883.7,945.4z" />
							<path fill="none" d="M2123.2,613.2c-0.3,0.6-0.7,1.1-1,1.7C2125,615.2,2122.7,613.4,2123.2,613.2z" />
							<path fill="none" d="M1894.4,955.4c4.2-2.3,2.3-10.1-2.4-8.5C1885.8,949.1,1888.7,955.3,1894.4,955.4z" />
							<path fill="none" d="M1865.2,968.6c7.3,5,7.6-10.2,4.9-11.2C1867.3,958.8,1861.5,966.1,1865.2,968.6z" />
							<path fill="none" d="M1886.9,1021.8c-1-2.2-1.8-4.8-3.1-7c0,2.5-2.7,4.6-2.7,6.7C1881,1025.5,1886.2,1027.7,1886.9,1021.8z" />
							<path fill="none" d="M2112.5,626.6c0.4,1.9,4.4,3.1,5.2,5.7c3-3.6,14.7-5.5,6.4-11.8C2118.3,616.3,2115.8,623,2112.5,626.6z" />
							<path fill="none" d="M2108.7,683.5c0.3-2.9-5.1,0.6-5.5,1.2c-1.6,2.4,0.2,5.1,0.4,7.7C2104.1,689.3,2108.4,686,2108.7,683.5z" />
							<path fill="none" d="M2118.5,646.2c-3.5,3.7-12.2,15.7,0,14C2128.2,658.8,2122.9,641.5,2118.5,646.2z" />
							<path
								fill="none"
								d="M2034.1,748.7c3.8-6.9-3.9-14-9.3-8c-3.5,3.9-0.7,4-1.1,8.3c-0.5,4.5-2.7,8-1.5,11.1
										c1.2-2.2,3.9-4.7,6.2-5.6C2022.6,751.8,2033.1,750.6,2034.1,748.7z"
							/>
							<path fill="none" d="M2051.1,642.2c-2.3,5.6,3.6,18.3,8.2,8.7C2061.7,646,2054.6,633.8,2051.1,642.2z" />
							<path fill="none" d="M2078,783.9" />
							<path fill="none" d="M1870.4,942.5c-5.3,2.5-1.4,9.5,2.1,9.7C1880.5,952.7,1881.2,937.4,1870.4,942.5z" />
							<path fill="none" d="M1906.4,907.5c0.7,3.8,5.9,5.3,9.1,5.6c-2.1-4.1,0.3-9.9-3.9-12.4C1907.9,898.6,1905.7,903.2,1906.4,907.5z" />
							<path fill="none" d="M1877.4,956.9c0.7,2.5,4,3.7,5.8,1.5C1887.6,952.7,1875.3,949.2,1877.4,956.9z" />
							<path fill="none" d="M1928.7,893.7c4.8,5.1,8.6-9.4,6.3-12.8C1928.9,871.4,1926,890.8,1928.7,893.7z" />
							<path
								fill="none"
								d="M1911.5,1023.9c2.2-2-1.6-19.8-7.7-10c-4.1,6.5,0.7,5.1,4.6,7.7C1909.1,1022.1,1911.7,1023.7,1911.5,1023.9z
										"
							/>
							<path fill="none" d="M1875.6,1019.8c-2.4-11-7.9-0.5-8.8,4.7C1870.4,1021.9,1877.2,1027.1,1875.6,1019.8z" />
							<path fill="none" d="M1838.7,993.9c4,7.8,13.4-7.2,5.9-10.7C1839.9,981,1836.9,990.4,1838.7,993.9z" />
							<path fill="none" d="M1864.5,954.8c1.1-2.3-0.5-7-3.4-7.2C1853.2,946.9,1856.4,971.2,1864.5,954.8z" />
							<path fill="none" d="M1900.4,802.8c2.2-1.7,14.9-3.7,5.9-9.2C1901.3,790.6,1896.5,800.5,1900.4,802.8z" />
							<path fill="none" d="M1853.3,976c5.2,3.7,9.3-6.1,5.8-9.8C1853.7,960.8,1848.4,972.4,1853.3,976z" />
							<path fill="none" d="M1844,625.4c0.5,4.4,11.1,0.6,6.4-1.2C1849.3,623.9,1843.7,623.5,1844,625.4z" />
							<path fill="none" d="M2042.7,1021.1" />
							<path
								fill="none"
								d="M1970.7,894.3c-8.2-3.5-5.4,7.4-6.1,11.5c-0.6,3.2-6.5,10.7-1.5,13.1c1.1-2,4.7-5.7,5-6.5
										c0.3-0.7,0.5-7.1,0.6-7.5C1970,899.9,1973.8,900.1,1970.7,894.3z"
							/>
							<path fill="none" d="M1961.1,862.9c-8.5-5.6-5.8,13.7-5.9,17C1960.5,877.7,1969.9,868.7,1961.1,862.9z" />
							<path fill="none" d="M1959.6,827.8c1.4-4,1.8-17.3-4.4-9.8c-2.2,2.7-5.9,15.4-0.5,16.6C1955.1,831.7,1956.9,829,1959.6,827.8z" />
							<path fill="none" d="M1963.6,882.3c-4.2-4.5-13.3,12.8-3.3,14C1963.6,894.1,1966.3,885.1,1963.6,882.3z" />
							<path fill="none" d="M1953.2,838.3" />
							<path fill="none" d="M1963,831.1c-7.5-0.2-3.7,13.6,1.8,11.3C1969.1,840.6,1968.4,831.3,1963,831.1z" />
							<path fill="none" d="M1931.8,786.5c-6.7-1.9-6.2,10.1-5.3,13.2C1928.1,798.1,1943.6,789.8,1931.8,786.5z" />
							<path fill="none" d="M1951.7,912.7c6.2-0.4,12.9-12.2,4.7-12.9C1951.9,899.5,1945.8,913.1,1951.7,912.7z" />
							<path fill="none" d="M1949.7,800.2c-3.3-1.3-7.8,4.3-7,7.9c0.8,3.2,0.8,1.4,3.9,2.6C1956.7,814.5,1951.6,805.4,1949.7,800.2z" />
							<path fill="none" d="M1944.9,779.8c-5.8-3.7-6.6,5.2-6.8,8.1c-0.1,3.1-0.9,12.4-0.2,13.4C1941.1,796.7,1954,785.6,1944.9,779.8z" />
							<path fill="none" d="M1958.2,789.2c-5.4-3.8-9.2,5.3-5,8.6C1957.6,801.3,1962.3,792.1,1958.2,789.2z" />
							<path fill="none" d="M1963.3,798.4c-6,0-7.8,14.9-0.4,10.7C1965.5,807.6,1967.7,798.4,1963.3,798.4z" />
							<path fill="none" d="M1950.4,888.2c-6.1,1.4-4.4,8.7-3.2,13.1C1947.8,900.2,1956.2,886.9,1950.4,888.2z" />
							<path fill="none" d="M1951.6,861.3c8.1,3.3,9.8-19.4,1.8-18.2C1947.9,843.8,1951.2,858.3,1951.6,861.3z" />
							<path fill="none" d="M2015.7,978.1c-2.3-0.5-8.7,2.2-10.2,4.6c2,1.4,3.7,3.2,5.1,5.2C2013.3,986.3,2021.7,979.4,2015.7,978.1z" />
							<path fill="none" d="M1976,903c-6.5-2.1-3.8,17.2,0.3,16.6C1980.4,919.1,1980,904.3,1976,903z" />
							<path fill="none" d="M2011.6,971.1c-3-1.4-7.5,4-4.7,5.4C2008.1,977,2015.1,972.7,2011.6,971.1z" />
							<path fill="none" d="M1907.9,735.2c1.9,1.5,3.5,3.6,4.5,5.8c3-2.3,5.6-6.6,3.4-10.4C1911.7,723.8,1903.4,731.7,1907.9,735.2z" />
							<path fill="none" d="M1967.9,789.6c-6.7,1-0.2,6.2,1.6,7.8C1972.3,793.8,1977.1,788.2,1967.9,789.6z" />
							<path fill="none" d="M1926.9,816.6" />
							<path fill="none" d="M1985.5,957.3c-1.3,0.1-1.7,0.8-1.3,2C1986.8,959.3,1987.3,958.7,1985.5,957.3z" />
							<path fill="none" d="M1985.8,920.4c-5.1-7.9-8.5,9-4.4,12.4C1989.3,939.1,1988.5,924.5,1985.8,920.4z" />
							<path fill="none" d="M1987.4,947.7c-0.3,4.9,2,9.1,6.4,11.1C2000.3,949,1988.1,937.9,1987.4,947.7z" />
							<path fill="none" d="M1999.5,933.7c-0.5-2.5-13.1-0.3-8,5.2C1993.7,941.3,1999.9,935.6,1999.5,933.7z" />
							<path fill="none" d="M2003.3,960.1c-0.7-1.1-1.9-2.5-3.3-2.6c-4.2-0.1-2.4,16.4,4.1,12.1C2007.2,967.5,2005,962.5,2003.3,960.1z" />
							<path fill="none" d="M1922.2,761.1c0.2,0.6,0.5,1.2,0.7,1.8c5.8-3.4,8.9,2.6,9.3-5C1933,743.8,1920.3,751.9,1922.2,761.1z" />
							<path fill="none" d="M1969.9,858c1.8-3.6,1.7-12.6-3.4-10.4c-6.1,2.6-3.5,11.5-0.3,14.8C1967.3,860.7,1968.5,859.3,1969.9,858z" />
							<path fill="none" d="M2019.7,975.1" />
							<path fill="none" d="M1970.9,811.8c-2-8.8-9.8,13.1-4.1,14.9C1970.1,827.7,1970.7,811.1,1970.9,811.8z" />
							<path fill="none" d="M2008.3,1009.9c6.5,4.9,11.7-6.4,4.3-10.9C2005.3,994.5,2005.4,1004.1,2008.3,1009.9z" />
							<path
								fill="none"
								d="M1995.5,987.8c-2,2.9,4.6,10,5.3,13.1c1,0.3,3.2-7.4,3.2-8c0.2-3.2,3.5-1.5,0.1-5.5
										C2002.4,985.4,1996.9,985.8,1995.5,987.8z"
							/>
							<path fill="none" d="M1974.2,803.4c1.9-1.9,6.5-1.9,5.2-4.7c-1.7-3.5-10.7,4.2-7.9,6C1972.6,804.8,1973.5,804.4,1974.2,803.4z" />
							<path fill="none" d="M1921.2,799.5c-9.4-6.4-11,9.8-2.2,9.1C1922.6,808.3,1924.6,801.8,1921.2,799.5z" />
							<path fill="none" d="M1919.9,721.6c-2.5,1.4-0.1,14.7,2,14.9C1922.2,735.3,1930.3,715.7,1919.9,721.6z" />
							<path
								fill="none"
								d="M2073.1,758.7c1.5,0.8,2.8,1.7,4.1,2.8c1.5-3.5,4.7-6.7,8.6-4c2.4-2.9-6.9-8.3-8.2-8.4
										C2073,748.6,2067.8,755.6,2073.1,758.7z"
							/>
							<path
								fill="none"
								d="M1974.3,935.4c2.6-6.1-2.7-13.6-6.2-18c-1,3.6-2.8,9.5-1.9,13.1c1,3.9,4,4.3,6.5,7.3
										C1973.3,937,1973.8,936.2,1974.3,935.4z"
							/>
							<path fill="none" d="M1914.3,751.6c0.9,1.1,2,2.1,3.1,2.9c1.4-2.9,10.7-10,2.4-11.7C1915.2,741.8,1913.4,748.1,1914.3,751.6z" />
							<path
								fill="none"
								d="M1923.2,780.5c0.8,1.1,2.4,2.9,3.4,1.9c-2.7-1.6-5.4-2.7-1.7-4.9c4.2-2.6,4.2,6.5,6.3-2
										C1934.7,760.7,1916.9,771.9,1923.2,780.5z"
							/>
							<path fill="none" d="M1945.4,932c1.6-2.2,4.9-15.7-1.4-15.2C1937.8,917.4,1941.8,930,1945.4,932z" />
							<path fill="none" d="M1938.3,769.3c-3.7-5.7-2.2,6-1.8,8.2C1938.5,774.4,1940.8,773.2,1938.3,769.3z" />
							<path fill="none" d="M1959.5,933.8c0.6,0.5,1.2,1,1.7,1.4c2.7-0.7-2.6-17.2-6.4-17.5C1944.6,917.1,1959.7,934.2,1959.5,933.8z" />
							<path fill="none" d="M1977.5,944.8c2.3,1.1,2.8,2.8,4.7,3.9c2.2-1.7,2.8-8.3,1-9.9C1980,936.3,1974.5,941.7,1977.5,944.8z" />
							<path
								fill="none"
								d="M1931.3,940.3c4.4,1.2,4.7,2.8,7.6,5.9c1,1-1.8,5.4,0.7,5.8c5.4,0.8-0.1-5.8-0.1-6.2c0.4-5,4-6.5,0.9-10.5
										c-4.3-5.5-8.6-0.6-11.6,4.1C1929.6,939.7,1930.5,940,1931.3,940.3z"
							/>
							<path fill="none" d="M1949,821.4c0-2.4-1.4-5.5-4.1-5.6c-4.9-0.3-5,12.3-5,15.2C1944.4,828.6,1950.4,828.6,1949,821.4z" />
							<path fill="none" d="M1924.5,797.5" />
							<path fill="none" d="M1926.4,815.8c-1.5-1,9.8,0.3,8.5,0.7c2.2-0.7,2.5-0.8,2.3-5.4C1936.4,790.8,1918.9,810.7,1926.4,815.8z" />
							<path fill="none" d="M1932.1,917.8c-1.7,1.6-11.2,11-2.7,13.3C1935.6,932.7,1938.5,915.2,1932.1,917.8z" />
							<path fill="none" d="M2028.4,995.6c-2.3-5.5-5.6,5-5.1,6.5C2025,1007.5,2032,1002.4,2028.4,995.6z" />
							<path fill="none" d="M2018.5,996.8c6.3-2.9,4.2-19.7-2.7-8.1C2014.8,990.5,2014.4,998.7,2018.5,996.8z" />
							<path fill="none" d="M1913.5,722c-0.9,1.8,0.5,0.2,0.9-0.8C1915,720.2,1913.6,721.8,1913.5,722z" />
							<path fill="none" d="M1998.9,630.9c-6.1-1.7-8.6,5.8-1.3,7.5C1997.9,636.5,2001.9,631.7,1998.9,630.9z" />
							<path fill="none" d="M1910.9,820.4c-1.9,0.5,7.3,2.4,7.3,2.4c1.7-0.6,5.1-0.9,3-5.4C1917.1,808.5,1913.4,819.7,1910.9,820.4z" />
							<path fill="none" d="M2006.1,647.1c-1.4,0.1-5.7,5.4-6,6.6c-1.2,4.2,0.2,4.3,3.3,7.8C2004.7,660.2,2011.9,646.5,2006.1,647.1z" />
							<path fill="none" d="M1988.8,660.9c4.8,3,1.4-6.4,0.8-3.9C1989.2,658.2,1983.9,657.8,1988.8,660.9z" />
							<path fill="none" d="M2013.4,653.7c0.3,1.8,4.4,5,5.6,6.2c1.2-3.1,6-7.2,2.6-10.9C2019.1,646.2,2012.7,650.2,2013.4,653.7z" />
							<path fill="none" d="M2013.3,662.3c-3.1-7.6-8.6,3.6-7.8,6.8c0.9,3.4,3.6,5.4,7.3,3.2C2017.3,669.6,2014.6,665.6,2013.3,662.3z" />
							<path
								fill="none"
								d="M2025,659.1c-1.9,4.9-5.6,8.9-4.5,14.8c2.4-3.3,4.5-0.6,6.4-1.6c2.9-1.6,3.7,0.1,4.3-4.5
										C2031.5,665.3,2028.1,658.6,2025,659.1z"
							/>
							<path
								fill="none"
								d="M1985.4,678.3c0.6-0.3,4.4,4.5,4.7,5.1c4.5-8.3,12.5-5.1,16.4,2.3c1.9-2.2,5-3,7.9-2.2
										c0.3-7-4.6-5.2-9.3-7.4c-1.9,3.3-2.6,2.2-4-0.3c-1.3-2.5,0.2-6.9-1.3-10c-0.9-1.9-3.2-4.2-4.5-5.6c-1.3,3-4.2,4.3-5.1,6.5
										C1988.5,671.1,1990.8,675.3,1985.4,678.3z"
							/>
							<path
								fill="none"
								d="M2110.2,631.7c-2.9-0.6-9.7,1.1-11.1,3.8c-3.2,6.4,9.5,5.2,12.1,10.7c1.4-1.3,3.4-2.4,4.2-3.9
										C2111.8,644.6,2115.2,632.7,2110.2,631.7z"
							/>
							<path fill="none" d="M1986.7,701.5c0,3.3-4.1,4.7-4.4,6.9c-0.5,3.5,1.8,7.2,6.2,4.9C1992.6,711.1,1994.3,699.3,1986.7,701.5z" />
							<path fill="none" d="M1989.2,641.1c-2.4,1.6-0.8,10.1,1.9,11.2C2000,655.9,1997,635.8,1989.2,641.1z" />
							<path fill="none" d="M2045.5,777.1c-5.1-7.6-16,2.5-10.6,6.5C2038.4,786.3,2050,783.9,2045.5,777.1z" />
							<path fill="none" d="M2117.8,695.7c2.8-3.6-0.3-12.9-5.7-8.6C2103.5,694,2116.8,697,2117.8,695.7z" />
							<path fill="none" d="M2124.4,677.3c1.7-1.6-3.5-11.8-6.9-10.5C2112.1,668.7,2113.3,687.3,2124.4,677.3z" />
							<path fill="none" d="M2110.5,761.3c4.5-0.6,3.5-18.6-2.4-17.9C2099,744.5,2103.8,762.2,2110.5,761.3z" />
							<path fill="none" d="M2100,774.2c4.7,2,12.6-2.8,7.7-8.3C2103,760.8,2093.3,771.4,2100,774.2z" />
							<path fill="none" d="M1977.7,700.1c-1.2,1.7,0.8,2.7,1.6,3.9c0.7-1.4,1.6-2.6,2.6-3.8C1980.9,699.4,1978.6,698.7,1977.7,700.1z" />
							<path fill="none" d="M2110.3,698.4c-7.9-0.4-3.7,12.6,2,8.4C2115.4,704.5,2114.2,698.6,2110.3,698.4z" />
							<path fill="none" d="M2035.6,647.3c-6.4-4.4-11.2,2.3-5.8,7.5C2035.1,660.2,2039.1,649.8,2035.6,647.3z" />
							<path fill="none" d="M1946.6,834.5c-4-3.8-10.6,4.9-8.7,8.4c0.9,0.4,5.7,4.3,7.4,3.7C1947.8,845.8,1947.8,835.6,1946.6,834.5z" />
							<path fill="none" d="M2045.6,654.6c-3.9-2.9-11.7,6.1-9.9,10c1.4,2.8,6.2,2.9,8.2,4.2C2049.3,667.9,2050.4,658.1,2045.6,654.6z" />
							<path fill="none" d="M2061,658.2c-0.3,13.3,13.7,5.1,7-0.9C2066.3,655.8,2061.1,653.2,2061,658.2z" />
							<path fill="none" d="M2126.9,668.1c0.6,1,1.2,1.9,1.7,3c1.1-2,5.2-6.1,5-8.3C2133.1,654.5,2122.2,660.2,2126.9,668.1z" />
							<path
								fill="none"
								d="M2132,642.4c-6.2-0.8-6-9-8.7-9.3c-4.6-0.5-0.5,5.7-0.1,6.7c1.8,3.8,5.2,6.4,6.5,10.9c0.5-1.6,5.2-6.7,4.8-8
										C2133.7,642.6,2132.8,642.5,2132,642.4z"
							/>
							<path fill="none" d="M2077.2,648.6c-2,2.7-6,12.4-1,14.9C2085.6,668.3,2084.6,640.2,2077.2,648.6z" />
							<path fill="none" d="M2094.8,643.3c-2.4,0-10.7,4.5-6.8,7.5C2091.8,653.7,2093.7,643.3,2094.8,643.3z" />
							<path fill="none" d="M2080.6,773.2c6,1.8,8.5-13.3,2.3-12.3C2079.9,761.4,2079.6,772.9,2080.6,773.2z" />
							<path fill="none" d="M1947,959.4c3.1,5,8.3,2,8.5-2.8c0.2-4.5-2.2-4.8-4.6-8.1C1948.5,950.1,1945.6,957.1,1947,959.4z" />
							<path
								fill="none"
								d="M1955.6,935.8c-3.3-3.8-8.5,1.2-9.4,4.7c-1,3.8-0.2,1,0.9,5.2c4.9-5.7,5.3,3.2,8.3,1.2
										C1959.3,944.3,1958.2,938.8,1955.6,935.8z"
							/>
							<path fill="none" d="M1909.9,942.1" />
							<path fill="none" d="M1991.9,1004.1c2-2.2,6-7.6,0.1-6.5c-3.7,0.7-5.3,8-1.4,8C1991.1,1005.2,1991.5,1004.6,1991.9,1004.1z" />
							<path
								fill="none"
								d="M1924.6,938.6c1.8-3.6,2.7-11.1-3.6-12.5c-4-0.9-5.3,5.3-10.1,5.3c0.8,2.5,0.9,5.1,0.2,7.6
										C1914.3,932.6,1921.1,932.6,1924.6,938.6z"
							/>
							<path fill="none" d="M1904.2,952" />
							<path fill="none" d="M1915.2,951c7.4,4.7,10.4-9.4,5-11.8C1913.9,936.4,1910.5,948.1,1915.2,951z" />
							<path fill="none" d="M1907.5,916" />
							<path
								fill="none"
								d="M1987.2,1019.5c0.6,1.1,1.1,0.3,2.2-0.3c2.9-1.5,0.6-4.3,0.4-7.1C1983.2,1011.2,1986.3,1017.8,1987.2,1019.5
										z"
							/>
							<path fill="none" d="M1961,705.5c-1.7-0.2-3,0.8-4.1,1.9c-2.8,2.9-2.2,8.7,3,8.2C1963.1,715.3,1965.1,706,1961,705.5z" />
							<path
								fill="none"
								d="M1945.1,722.8c-0.8-0.3-1.7-0.5-2.5-0.8c-0.5,4.6-5,6.3-5.7,10c-0.3,1.7,2.3,4.9,2,7.4
										c-0.6,4.3-3.8,6.5-6.9,7.3c4.8,5.1,2,11.5,4.8,16.5c1.2,2.1,5.2,5.4,6.9,8.3c-1.4,3-0.6,4.5,2.3,4.4c1.5,0.9,2.7,2.1,3.6,3.5
										c1.5,1.6,2.5,3.5,3.1,5.6c1.8-0.8,3.6-0.9,5.3-0.3c3.1,1.3,4,2.5,6,2.6c5.2,0.3,9.4-5,13.8,2.5c2.1-3.6,5.6-3,8.7-4.5
										c2.2-1.1,7.7-4,10.6-6.3c1.6-1.3,4.9-3.3,6.2-6.4c1.9-4.9,0.7-9.5,6.5-13.5c-4.5-3.1-1.5-6.7-2.3-10.5c-0.5-2.2-1.4-4.8-2.6-7.3
										c0.2-1.2,0.3-2.4,0.5-3.6c-1.9-0.4-3.4-1.3-4.6-2.7c-2.6-4.6,0.2-7.8-1.6-12.1c-1.2-2.8-5.9-1.9-7.2-6.7
										c-1.8,1.5-1.1,5.3-2.9,5.9c0.4-0.1-9-7-9.3-7.5c-3.4,4.3-10,6.8-13.9,1.7c-4.1,5.4-5.9,1.7-10.4,2.7
										C1952.6,719.4,1949.7,723.3,1945.1,722.8z M1954.6,752.6c1.3-7.3,6.5-5.3,10.9-8.2c2.5-1.7,3.4-5.5,7-7.1
										c4.3-1.8,6.7-1.6,10.7,0.8c1.4,1.4,2.2,3.1,2.5,5.2c1.8-0.5,3.5-0.1,5.1,1.3c5.4,7.4-0.9,9.4-5.4,13.6c-0.8,0.7-1.6,4-3,4.6
										c-2.5,1.1-5.8-0.1-7.7,0.6c-1.5,0.6-5.9,7.5-8.3,2.9c-0.5-1,9-11-0.5-10.1c-10.4,0.9,2.1,11.7-2.7,12.3
										C1959.3,768.8,1954.1,755.9,1954.6,752.6z"
							/>
							<path fill="none" d="M1985,749c-0.9,1.4,0.5,3.3,1,4.6C1990.1,751.8,1988.4,743.8,1985,749z" />
							<path fill="none" d="M1980.8,753.7c-1.3-4.1-7.7,0.3-6.8,3.5C1975.5,762.5,1982.7,759.7,1980.8,753.7z" />
							<path fill="none" d="M1970.3,743.6c-4.7,5.6,7.4,8.4,10.5,2.9C1985,739,1974,739.3,1970.3,743.6z" />
							<path fill="none" d="M1959.2,751.4c-0.6,1-0.7,2-0.3,3.1c3.2-0.5,5.8-2.6,9.2-3C1965.6,748.8,1962.5,745.7,1959.2,751.4z" />
							<path fill="none" d="M2021.3,1025.6c5.2,1.7,14.9-20.9,1.5-16.2C2018.9,1010.7,2021.1,1025.5,2021.3,1025.6z" />
							<path
								fill="none"
								d="M2005.1,1012.5c-3.2-2.8-5.1-11.7-10.4-4.6c-0.9,1.2,0.1,8.6,0.9,9.4
										C1999.2,1020.8,2007.4,1018.2,2005.1,1012.5z"
							/>
							<path fill="none" d="M1982.4,1011.9c1-1.4,7.3-7.1,1.1-7.5C1977.9,1003.9,1971.8,1014.7,1982.4,1011.9z" />
							<path
								d="M2164,908.2c-5.9,0.3-2.4-2.6-7.8-5c-3-1.4-8.2-1-9.2,2.9c-0.7-0.5-0.9-6-2-7.4c-1.7-2.1-3.8-3-6.4-3.2
										c-6.3-0.5-6.4,5.5-10,5.7c-6.9,0.3-10.7-15.5-19.8-2.9c0.3-3.9-0.5-8.6-4.6-10.3c-2.6-1.1-4.7,1-6.7,0.6c-1.2-0.2-2.6-3-4.8-3.6
										c-5.3-1.2-4.7,1.2-9.3,1.4c-0.4,0-9.5-0.3-8.9-0.2c-4.8-1.4-7.5-9.3-13.5-0.9c-1.7-6.5-11-3.8-15.5-6.4c-13.7-8,9.9,10.6-4-4
										c-2.1-2.2-3.4-6.1-7.3-4.5c3.9-4.6,0.1-9-4.5-10.6c-2.9-0.9-5.7-0.8-8.4,0.4c-2.1,3.2-3.9,3.2-5.4,0.1c-2.1-1.4-4.3-1.9-6.8-1.5
										c-1.8,0.4-3.2,1.3-4.1,2.8c-4.3,0.1-6.5-4.9-11.7-0.8c-1.6-6.6-7.9-3.9-10,0.6c-2.2-4-13.6-8-9.6-14.2c1.3-2,3.5,1.2,5.5-1.4
										c0.2-0.3,0.2-6.4,0.3-7.1c0.5-3.7,1.8-7.4,1.6-11.6c1,1,3.3,2.2,3.7,2.3c0.7,0.4-0.9,3.1,0.6,3.5c5.7,1.3,1.3-1.4,3.4-4.4
										c1-1.4,3.4-2.6,4.1-4.5c0.8-2.1-0.7-10.1,1.9-9.5c-0.2,1.5-0.7,7,1.1,7.9c4.1,2.2,2.2-3.1,2.9-4.3c1.9-3.2,5.8-10.5,5.4-14.4
										c0.5,0.1,7.7,17.9,6,1.9c-0.8-7.5-4.5-3.1-5.5-6.9c-0.3-1,4.3-8,3.7-7.4c5-4.9,3.5-3.1,7,0.6c1.1,1.1-1.2,5,1,6
										c3.7,1.7,2.9-5.6,5.6-7.1c2-1.1,5.9-1.3,7.5,0.7c5.4,6.7-7,7.4-7.8,10.7c-1.5,6.6,8.3,9,10.4,12.9c0.8,1.5,2.8,4.3,1.4,7.4
										c-1.1,2.5-6.8,2.8-5.7,7.7c-4.3-4.2-12.8-3.2-14.4,3c-1.1,4.3,1.5,5.1,3.1,8.2c-2.7-5.2,8.8,6.2,3,4c5.5,2-0.4,1.3,4-2.2
										c3.7-2.9,8.8-3.4,6.3-10.1c2.5,2,5.5,7.6,7.1,7.5c-3.2,0.2,4.6-9.7,4.3-8.4c1.4-6.9-2.9-6.1-3.7-11.3c-1.2-7.9,7.2-5.7,7.2-13.9
										c4.8,1.9,2.1,7,7.1,6.1c-1.2,0.2,3.7-9.8,5.4-11.5c0.5,2.5,3,3.8,4,5.1c1.1,1.6-2,4.2,1.4,4.9c2.9,0.6,1.8-4,2.5-4.8
										c1.2-1.4,4.3-3.9,5.4-6.1c0.5,1.9,7.9,11.3,7.7,11.3c3.2-0.9,0.3-5.3,4.4-7.4c0.8,4.7,4.1,3.2,7.1,5c0.6,0.4-0.5,4.3,1.5,3.9
										c2-0.5,1.1-0.6,2.7-1.8c-0.1-1.1-0.1-2.2-0.2-3.3c1.6-0.9,3-2.1,4.3-3.5c1,1.9,6.1,5.4,6.8,5.8c1.8,1.1-1.4,4.3,3,3.4
										c2.5-0.5,0.4-8.3,5.9-9.1c8.3-1.2,3,9.7,7.4,9.9c2.7,0.1,1.3-3,2-4.1c0.7-1.1,2.4-6,3-7.6c1.1,1.7,2.9,8.6,3.8,9.2
										c5.3,3.4,2.3-0.5,4-4c1.4-2.8,10-29.2-2.4-24.8c2-5.6-5.4-20.2-12.2-21.9c1.1-1.5,1.4-3.2,0.9-5.1c-0.8-2.3,0-3.6,2.4-4
										c1.7-3.6,4.9-4.6,2.8-9.4c-0.7-1.7-1.9-3-3.5-3.8c-2.9,0.1-3.5-1-1.9-3.5c-1.4-3.2,1.1-7.1-3.1-9.3c3.2-1.3,4.9-4.2,6.1-6.3
										c0.2-0.3-0.5-1.2-0.3-1.6c1-1.4,3.3-4.6,4.2-6.5c2.6-5.2,1.4-6.9,2-12.6c0.5-4.4,3.2-7.3,5.2-10.9c2.3,4.2,5.2-7.6,5.9-9.2
										c1.7-3.6,10.3-16.3-0.7-17.3c14.9-2.8,3.8-20.9,5.4-28.2c0.3-1.4-1.6-2.1-0.9-3.4c0.8-1.5,5.1-2,6.3-3.4
										c3.5-3.9,3.5-8.2-1.2-11.1c4.6-2.2,4.9-5.7,4.2-10.1c-0.8-2.1-2.3-3.6-4.4-4.3c0.8-1.5,0.9-3.2,0.3-4.8c-2.8-4-6.3-6.2-11.3-4.5
										c-2.8,0.9-4.6,5.4-6.3,6c-1.8-4.4-6.9-5.8-11-4.4c-4.6,1.6-4.4,7.5-6.9,8.8c-2.5-10.8-10.1-9.1-15.3-1.1c-2.4-3.9-8-5.7-11.5-1.9
										c-2.2-7.6-8.4-1.4-10.4,2.7c-2.7-8.8-9.6-5.3-11.5,1.6c-1,3.9-0.1,7.4,0.2,11.2c0.2,3,0.6,3.1,0,7c-2.2-1.1-1.7-6.7-5.9-8.4
										c-4.5-1.8-9.7,0.8-12.2,4.6c-3.5-5.7-8.1,0.1-11.7,0.2c-1,0-2.5-2.6-4.1-2.6c-2.3,0.1-5.4,2.1-7,2.1c-4.4-0.1-15.8-5.2-15.9,5.4
										c-2.5-11-13.1-6.4-19.3-4.2c-2.8,1-5.4,2.5-9.4,3.5c-6.6,1.6,1.6,4.4-5.8,0.7c-2.1-1-1.6-5.6-5.7-6c-3.6-0.4-6.6,3.2-7.4,6.3
										c-1.3,4.9,1.7,7.8,1.7,12.7c2.6,3,2.3,4.8-1.1,5.5c-1.6,1.1-2.6,2.5-3.2,4.3c-3.9-4-11.5-0.3-11.8,5c-0.2,3.7,2.7,3.7,3.7,5.9
										c-1.4-2.8,9,8.7,1.7,5.3c5.8,2.7,1.3-0.8,4.1-4.8c1.1-1.6,2.9-1.8,3.3-4.3c5.7,5.9,2.8-4.2,2.5-3.6c1.3-2.9,1.1-3.1,3.5-5.2
										c1.5-1.4,3.9,2,5.1-1.1c1.6-3.9-5.3-4.8-3.4-10.2c3.2-9.2,11.5-1,9.4,6c-1.1,3.5-5.5,4.8-6.3,10.5c-0.7,4.7,0.5,11.6,6.2,12.5
										c-4.3,6.7-3.4,12.1,3,15.6c-2.8,1.5-4.8,5-5.2,8c-5.6-5.7-9.8,0.3-14,1.6c-4.9,1.5-7.6-0.8-11.6,4.8c-2.8-8.3-13.1-4.8-15.8,1.4
										c-1.6,3.8,1,7.7-1.3,10.6c-3.3-4-5.4-4.2-9.8-5.1c-5.8-1.2-3,3.7-4.9,0c0.9,1.8,2.5-7,2.5-6.5c-0.4-2.9-3.7-6.6-4.6-9.2
										c-1.6-4.8-3.9-8.6-5.4-12.6c-1.8-4.8,1.3-10.3-7.1-10.7c2.5-5.4,1.7-20.6-7.8-19.6c1.3-6.3,4.4-7.7,10-8.8
										c5.2-1.1,8.7,3.6,13.7-2c3.2,3.9,10.3,3,11.5-2.7c2.1-9.4-10.1-7-14.1-6.4c-4.4,0.6-10.6-2-14.2-1.1c-3,0.8-5.1,2.5-7.3,3
										c-3.6,0.7-7.4-3-10,2.4c-2.2-4.6,3.5-8.3,1.5-14.4c-2-6-8.6-8.5-11.9-1.9c-3.3-5.3-9.5-5.7-11,0.9c-2.4-3.3-6.8-4.1-9.2-0.3
										c-7.9-12.1-23.9,10.4-10.3,19.4c-4.2,3-6.9,7.7-3.9,12.6c1.8,3,5.7,2,6.5,5.5c0.8,3.4-3.7,6.1-1.8,10.4c1.7,4.1,6.7,3.6,7.9,6.6
										c0.5,1.2-2.7,7.8-1.5,11.2c0.7,0.6,1.5,1.3,2.2,1.9c3.2,0,4.1,1.3,2.6,3.9c4.2,6.2,1.5,11.7,7.5,17.1c3.6,3.3,2.6-1.2,5.6,3.4
										c2.2,3.5-0.6,4.8,0.3,8.9c0.8,3.9,4.7,4.2,5.8,7c-0.9,3-0.8,6,0.4,8.9c0.7,2.5,2.2,4.3,4.3,5.6c0.6,1.9-2,4.8-0.7,7.7
										c1.2,2.7,5.5,3,6.4,4.6c2.3,4.1-2.4,8.1,4.8,10.9c4.3,1.7,9.6-1,10.9-5c1.7,2.4,5.1,4.8,8.3,4.1c-3,5.5-5,16.5,1.9,19.9
										c-1.6,1.6-20.4-9-21.7,4.4c-0.3,3,3,5.6,3.5,7.5c0.6,2.4-0.3,5,0.7,8c1.2,3.6,4.2,5.2,4.7,10.3c0.6,6.2-0.9,8.5,2.9,14
										c3,4.5,3.5,0.3,4.5,6.5c1,5.8-3.3,20.1,8.2,19.3c-1.9,6.7-0.6,11.7,1.5,17.7c0.5,1.3,3.3,10.5,4.4,10.6c4.5,0.5,0.2-8.5,4.2-10.7
										c-0.8,5.5,3.1,8,3.6,12.1c0.4,2.7-0.9,5.2-1.4,9.1c-3.3-4.2-8-3.4-11.5,0.2c-3.8-9.3-14,0.3-12.8,7.4c0.5,2.9,4.1,4.7,4.5,7
										c0.2,1.2-2.7,5.7-2.9,7.5c-4.3-4.9-9.2-1.5-11.8,2.9c-3.5,5.9-1.3,4.8-5.4,8.3c-1.6,1.3-3.9,2.8-5.7,3.9c-1,0.6-1.6,1.4-1.7,2.5
										c-0.2,2.3-1.7,2.4-4.5,0.5c-2.6,0.7-5.5-0.1-8,1.4c-3,1.8-2.8,5.2-4.5,7c-1.7,1.8-7.1,4.5-10.4,6.8c-0.8,0.5-1,2.4-1.7,2.9
										c-1.5,1-3.8,0-5.5,1.2c-1.6,1.1-2.9,2-4.1,3.6c-1.3,1.6-1.8,3.4-1.5,5.6c-0.8,0-1.6,0-2.4,0c-3.5,4.9-6.5,6.7-5.7,13.9
										c-4.1-1.8-11.4,0.7-12.9,5.1c-1.4,3.8,1.8,7.5,2.4,11c-9-2.7-11.9,5.6-9.5,12c1,1.4,2.4,2.3,4,2.6c2.1,0.1,2.5,1.2,1.2,3.2
										c-0.9,1.5-0.9,2.8,0.1,3.9c0.4,1.3,1.3,1.5,2.7,0.6c2,3.1,3.1,2.9,4.3,6.3c0.2,0.5-0.9,9.7,2.9,6.5c2.2-1.8,0.2-5.5,1.1-7.7
										c0.4-1,2.2-1.8,2.4-2.6c-0.3,3.2,0.6,4.3,1.2,7c1.7,0.4,2.4,1.3,2.3,2.6c0.1,2.2,0,4.5-0.1,6.7c2.6,9.6,4.2-1.1,4.1-6.3
										c0.3,0,0.6,0,1-0.1c1.1,2.9,3.1,3.9,3.7,6.7c0.6,2.5-1.5,6.5,0,8.6c4.1,5.6,4.2-8.4,5-9.1c0.8,1.8,2.2,2.9,4.1,3.5
										c0,1.7,0,3.4-0.1,5c1.8,3.1,3.2,4.6,5.2,7.1c1.4,1.8-0.5,5.5,3,5.4c3.1-0.1,1-4.9,0.8-6.7c3.9-0.5,2,0,4.4,1
										c6.4,2.5,3.8,5.7,5.3,10.8c3.5,11.5,3.4-6,6.8-8.2c0.4,5,4,3.8,5,7c0.8,2.8-2,7.1,0.2,9.4c4.1,4.1,4.2-8.1,4.9-10
										c0.9,2.8,4,4.9,4.9,7.2c0.3,0.9,1,4.6,1.3,5.2c1.2,2.2,0.9,6.5,3.9,2.2c3.7-5.6-2-8.6-3.9-12.1c-1.9-3.6-3.9-19.3,5.1-12.1
										c5,4-2.3,8.3-1.9,11.9c0.3,3.1,6.9,7.3,8.2,10.8c0.8,2-0.1,11,4,5.1c0.8-1.2-0.6-3.8,0-5.1c1.6-3.8,0.1-1.1,3.4-3.8
										c0.4,3.8,2.3,5.6,3.4,8.6c-0.3-0.9,3.4,7.4,2.5,7c5.1,1.9,2.9-5.7,6.4-9c1.7,6.5,3.4,3.5,6.6,7.1c0.6,0.7-0.9,4.4,0.5,4.8
										c3.6,1,1.3-0.3,3.5-2c0-1.1,0-2.1,0-3.2c1.6-0.4,2.9-1.2,4-2.4c0.6,2.8,5.8,13,8.1,12.9c3.6-0.1-1.8-12.4,8.5-5.7
										c2.7,1.8-0.4,9,4.7,3.1c0.6-0.7-1.1-3.2-0.7-3.8c0.6-1.1,2.7-3.2,3.4-4.8c1,2,2.5,3.4,4.6,4.2c0,1.2,0,2.4,0,3.6
										c0.6,1.2-0.8,4,0.6,5.1c4.8,3.6,2.9-7.4,3.4-8.1c6.9-9.8,4.9,2.9,7.3,3.5c2.9,0.7,1.8-2,3-2.9c0-1.1,0-2.2,0-3.4
										c2.1-0.8,3.7-2.2,4.8-4.1c1.2,1.7,2.6,6.3,4.3,6.6c2.4,0.4,2.2-6.2,5.5-6.8c3.8-0.7,1.5,6.1,6.1,4c-1.5,0.7,5.1-12.4,4.8-11.4
										c2.4,1.8,2.3,7.7,4.5,7.9c0.6,0.1,5.5-11.2,5.8-12.1c1,0.9,3.8,1.2,4.5,2.1c1.1,1.4-2.5,4.7-0.3,6c4.6,2.8,2.6-3.4,4.5-6.1
										c2.3-3.3,5-5.5,9-2.5c3.3,2.6-2.3,9.3,3.9,2.3c1.8-2,2.5-8.5,6.4-7.6c3.3,0.8-0.2,5.3,2.2,6.1c2.5,0.8,1.6-0.3,3.4-1.5
										c-5.2,3.2,2.3-7.4,1.4-5.2c1.4-3.4,4.2-2.7,4.4-7.9c3.8,1.9-1,6.9,2.5,8.7c3.9,2.1,2.8-5.2,3.2-5.9c1.4-2.4,4.5-4.3,5.1-7.8
										c1.9,0.8,0.3,11.7,5,6.3c1.4-1.6-1-13,4.9-11.2c1.1,0.3-1.8,7.8,3.2,5.3c2.5-1.3,0.3-9.7,6-9c-0.5,9.9,6.1-2.4,7.3-2.8
										c1.3-0.4,1.7,2.9,4.8,2c-0.5,0.2,5.4-7,5.4-6.7c-0.1,0.3,0.1,5,1,4.6c3.3-1.5,1.2-1.8,3-3.9c0.2-0.3,3.5-7.3,4.7-7.3
										c-0.4,10.6,7.4-6.2,8.8-6.3c-0.2,1.7-1.5,6.9,1.6,6.8c2.9-0.1,1.5-4.1,2.3-5.6c2.9-5.9,5.5-13.9-5.9-11.4
										c-3.3,0.7-7.5,5.1-10.7,6.7c-3.8,1.9-6.5,4.3-9.9,6.7c-0.7,0.5-5.9,5.3-6.8,5.6c-3.9,1.3-8.5-2.7-10,5c-2.7-2-4.9-0.1-7.3-0.2
										c-2.2-0.1-5-2-7.9-1.1c0.3-0.8,0.1-1.5-0.4-2.2c0.3-1.4,1.4-1.9,3.3-1.4c2.5-3.1,6.5-4.4,6.8-9c0.5-3-0.5-5.3-2.9-7.1
										c-1.2-3.2-0.4-4.7,2.4-4.3c1.3-4.2,5.5-7.1,1.7-12c-6.8-8.9-10.2,1.7-12.3,6c-2.1,4.4-9.8,10-10.5,15.8c-3.1-4.1-0.5-6.7-4-11.4
										c-1.8-1.3-3.9-2-6.1-2.1c0.5-1.8,0.5-3.5,0.1-5.3c-2-5.5-5.3-16.3-2.1-21.2c3.9-6,4.1-3.3,5.9,0.6c0.5,1.2-1.4,10.3,1.5,10
										c3.7-0.3,2.4-3.3,2.5-6c3.6,4.2,1.6,9.5,6.8,6.5c-0.1-1-0.2-2.1-0.3-3.1c-0.6-2,0.6-3.3,3.5-3.7c1-0.8,1.9-1.7,2.4-2.9
										c0.9,0.5,1,5.9,1.9,5.7c2.6-0.6,3.7-1.5,3-4.7c2.2,2,4.9-2.4,5.2-3.7c0.5,8.2,4.5-0.3,5.9-2.6c4.1-6.8,5.7-10.3,2.9-17.8
										c-3.8-10.1-2.7-4.7,2.5-14.1c4.8-8.7-1.1-11.9-1.1-19.4c0-5.7,4-8.9,7.8-3.2c1.7,2.6,0.3,4.4,0.4,7.3c-1.5,1-2.1,2.5-1.9,4.2
										c2.8,1.2,3.1,2.6,1.2,4c-0.5,4.3-4.3,16.4-1.2,20.7c5.4,7.7,3.4-4.4,6.2-7.2c4.2-4.1,10.2-1.6,8.3,5.4c-1.4,5.2-6.9,3-8.3,5.3
										c-3.2,5.3,3.4,1.8,4.9,3.5c1.9,2.2,2.3,12.5,5.6,5.2c1.2-2.6-2.2-4.1-0.9-6.6c2.3-4.6,6.8-2.7,6.6,2c-0.2,4.5-7.8,9-10.1,13.1
										c-1.6,2.9-1.9,5.1-2.6,8c1.7,1.6,1.4,2.8-0.8,3.8c-1.6,1.6-2.3,3.6-2.2,5.8c0.1,1.8,4,5.3,4.7,6.2c2.4,2.9-1.4,4.3,3.5,4.1
										c-3.4,0.1,5.1-9.9,4.3-7.1c1.2-3.7,2.9-6.7-1.5-9.6c6.8-3.3,3.5,3.4,5.9,3.9c3.2,0.7,3.9-2.3,6.7-5c1.3,4-0.3,6.5,1.2,10.7
										c0.4,1.2,3.1,2.7,3.7,4.2c0.7,1.6-1.2,5.7,1,6.8c2.6,1.3,2.2-2.3,3-2.8c-1.8,1.3,2.9-4.9,2.1-3.5c2.6-4.3,3.2-6.2,5-10.9
										c-0.2-1.3-0.4-2.6-0.6-4c1.9-0.2,3.6-0.9,5-2.2c2.2-3.5,1.2-4.3,2.3-7.8c-2.2-1.9-1.9-3.2,0.8-4c0.9-1.1,1.4-2.2,1.6-3.5
										c0.1-2.2-1.6-7.1-1.2-8.2c0.8-2.6,2.5,1.2,4.2-2.3c0.6-1.3-0.2-3.3,0.4-4.6c0.2-0.5,4.7-6.3,4.7-6.3c1.4,0.6,2.3,0.3,2.5-0.9
										c0.9-1.5,1-3.1,0.5-5c1-2,3.3-3.8,3.4-6.6c2.7,1.6,1.3,12.4,5.9,6.5c0.6-0.8-0.8-3-0.3-3.9c0.1-0.2,2.4-3.9,2.4-3.9
										C2169,918,2173.7,907.7,2164,908.2z M2065.5,1023.8c5.1-4.1,6.5,10.9,1.2,8.7C2064.9,1031.7,2064.6,1024.5,2065.5,1023.8z
										M2162,919.4c-3.7,0.4-0.8-8.7,3.1-7.1C2170.5,914.4,2163.5,918.5,2162,919.4z M2155,924.3c-2.5,2.1-9.1,1.9-6.9-2.6
										C2151.1,915.7,2154.5,922.7,2155,924.3z M2109.3,960.8c3.9-2.5,10-0.4,5.2,3.1C2112.2,965.5,2107,964,2109.3,960.8z
										M2107.3,934.3c-1.6-3.2-0.1-16.1,4-11.2C2112.9,925,2110.5,935.2,2107.3,934.3z M2077.7,957.3c-2.9,4.8-8.6-1-4.3-4.9
										C2078.1,948.1,2082.2,951.5,2077.7,957.3z M2055.8,942c1.4,8.1-5.7,0.3-5.4-3.5c0.2-2.1,1.4-5.6,4.3-4.6
										C2056.6,934.5,2055.6,940.4,2055.8,942z M2082,902.5 M2077.3,903.5c11.2,3.7-13.3,24.5-4.8,5
										C2073.2,907,2074.7,902.6,2077.3,903.5z M2066.1,932.9c7.1-2.1,3.3,11-2.4,10.1C2057,942,2061.4,934.3,2066.1,932.9z
										M2071.4,922.7c-0.2,3.6-7.4,7.2-10.4,5.9C2056.6,923.7,2071.7,915.2,2071.4,922.7z M2076.3,946.9c-7.5,1.5-6.4-6.8-2.1-7.2
										c2.3-0.2,6.3,5.5,4.8,6.8C2078.4,946.9,2076.9,946.8,2076.3,946.9z M2079.8,892.3c-0.6,2.5-0.5,6.3,0.7,8.6
										c-3.5-3.3-5.8-0.1-6.4-4.8c-0.5-4.1,3.1-7.6,6.8-6.8C2080.6,890.3,2080.2,891.3,2079.8,892.3z M2041.5,952.9
										c0.2-4.1,3.4-7.9,5-4.3C2048,951.6,2040.8,965.3,2041.5,952.9z M2048.2,958.2c0.7-5.1,7.5-11.2,4.6-3.7
										C2052.5,955.4,2047.6,962.5,2048.2,958.2z M2052.9,961.6c0.9-2.8,3.9-1.9,5.5-0.6C2064.9,966,2049.2,972.7,2052.9,961.6z
										M2057.2,955.9c1-1,2.5-7.9,2.9-9.6c3.2,2.3,4.4,1,6.9,0c1,5.3,2.6,3.7-1.6,7.6C2062.3,956.8,2060.7,956.9,2057.2,955.9z
										M2064.7,958.5c2.4-2.3,5.4,1.5,4.7,3.7C2068.2,966.8,2061.7,961.4,2064.7,958.5z M2080.4,928.7c-5.5,6.4-8.9,5.9-6.2-2.5
										c0.6-1.4,2.1-7.1,4.4-8C2085.3,915.5,2083.6,925,2080.4,928.7z M2084.5,891.3c4.1-6.9,12.7-0.6,7.5,5.6c-3.8,4.7-2.6-0.8-8,1.3
										C2083.4,896.3,2083.5,893.1,2084.5,891.3z M2102.1,891.4c7.4,1.1,0.4,12.9-4.3,9.9C2094.5,899.3,2097.8,890.8,2102.1,891.4z
										M2092.6,929.6c-0.1-11.5,15.8-6.3,0.5,3.2C2092.8,931.7,2092.7,930.7,2092.6,929.6z M2100.9,918.7c-1.4-1.1-3.2-1.3-4.9-0.6
										c0.2-3.4-0.8-12.3,5.2-12C2110.1,906.6,2105.9,922.4,2100.9,918.7z M2125.1,907.6c4.7-7.7,8.1,5.5,8,8.7c-0.1,4.5,3,5.7-3.7,4.7
										c-3.1-0.5-4.1-3.7-7.5-3.8c0.8-1.8-0.5-6,0.1-7C2122.1,910.1,2125.4,907.1,2125.1,907.6z M2140.1,930.8c-2.7,4.8-5.2,7.6-8,12.2
										c-2-2.7,2.6-6.3,3.2-10.3c0.7-4.8-1.3-5,2.5-8.2c1.7-1.5,2-4.8,5.8-2C2148.2,925.9,2141.3,928.6,2140.1,930.8z M2122.7,932.3
										c1.1-4,6-9,8.6-4.3C2136.6,937.6,2120.8,939.2,2122.7,932.3z M2124.1,923.8c-1.4,1.5-5.9,7.6-7.5,2.8
										C2115.2,922.4,2124.6,918.8,2124.1,923.8z M2116.6,917.7c-4,2.5-5.5-2-3.5-4.9C2116.8,907.5,2121.1,914.9,2116.6,917.7z
										M2136.7,947c-2.6-0.7,0.1-5.3,1.9-5.6C2141.9,940.7,2142.6,948.6,2136.7,947z M2146.6,913.4c-2.8,11.8-14.2-1.8-3.7-2.7
										C2145.1,910.6,2147.5,909.8,2146.6,913.4z M2143,903.9c-0.4,3.1-8.7,3.7-9.6,0.1C2132,898.6,2143.7,897.9,2143,903.9z
										M2121.9,904.3c-0.3,2-13.1,6.8-11.2-1.3C2112.3,896.6,2123.2,895.5,2121.9,904.3z M2113.8,934.7c2-2.5,1-1.9,3-1.4
										C2128,936.1,2102.7,948.6,2113.8,934.7z M2111.9,970.2c0-3.7,4.9-0.6,5.9-0.7C2118.2,969.5,2112,974.7,2111.9,970.2z
										M2117.8,951.6c-2.6-9.6,12.8-13.2,12.6-2.4c0,4.5-0.3,2-2.4,2.8c0.3-0.1-8.2,0.3-7.1,0.5
										C2119.4,952.4,2118.9,955.6,2117.8,951.6z M2121.3,960c2.1-5.9,7-2.5,4.8,3.3C2123.2,970.7,2119.7,964.6,2121.3,960z
										M2123.8,980.2c0.8-3.9,4.1-7.6,7-4.3C2137.4,983.6,2122.2,988,2123.8,980.2z M2137.5,969.3c-5.5,4.6-10.7-6.4-4.6-8
										C2136,960.5,2141.1,966.2,2137.5,969.3z M2140.7,957.3c-1.6,2.6-5.6,0.2-5.9-1.9C2134.3,951.7,2144.8,950.6,2140.7,957.3z
										M2143.2,938.4c-3.8-2.2,3.6-12.3,5.3-5.1C2149,935.3,2146.5,940.3,2143.2,938.4z M2155.4,915c-3.2,1.7-6.2-4.8-4.5-7
										C2154.7,902.6,2160.1,912.5,2155.4,915z M2068.6,892.7c-5,0.8-7-4.1-2.4-6.6C2070.1,883.8,2072.9,890.3,2068.6,892.7z
										M2063.5,896.9c2,1.1,4.6,0.4,6.6-0.5c-0.3,1.4-0.1,2.8,0.6,4c2.2,1.1,1.8,2.6-1,4.5c-0.4,2.4-1.4,9.4-2.4,10.9
										c-0.5,0.7-6.3,2.6-7.5,4.1c-2,2.4-3.5,7.2-2.5,10.3c-8.2-5.4-11.8,7.8-9.5,13c-7.2-2.5-7.7,4.6-10,8.6c-0.6,1.1,0.6,3.7-0.1,4.6
										c-2.6,3.8-7-0.5-8,6.6c-2.2-2-4.1-4-7.4-3.8c0.5-1.6,2-4.6,1.7-6.2c-1.2-5.9-5.4-3.7-7.2-6.4c-3.1-4.7-2.2-13.6-2-18.9
										c0-1.7-3.5-6,2.7-6.5c3.2-0.3,2.4,1.6,4.3,2.5c-0.4-0.2,2.3,9.8,3.9,0.6c0.7-3.6-12.7-12.2-1.1-12.4c9.9-0.2,0.6,9.1,1.6,11.6
										c2.4,6.5,7.9-6.3,6.8-9.7c-2.2-7-15.2-8.6-16.9-0.2c-3-2.4-6-1.6-9.3-1.4c-5,0.3-1.4,2.2-7.6,1.6c4.5-5.3-5.2-13.1-7.7-17.5
										c-3.5-5.9-9.5-12.1-8.8-18.9c-2.7-2-2.7-3.6,0.1-4.9c1.2,2.1,2.9,2.9,5,2.3c0.7-0.4,1.4-0.9,2.1-1.3c2.6,1.4,2.5,3.1-0.4,5.2
										c0.6,3.9-1.6,8.6,3.4,11c5.5,2.7,10.7-2.4,11.7-7.6c1.2,1.8,7,3.7,7.5,4.7c0.8,1.6-1.7,8.3,0.8,9.2c4.1,1.6,2.5-4.6,3.2-5.9
										c1.5-3.1,1.6-2.7,3.9-5.7c0.7,1.1,7.2,10,7.4,10c4.7,0.4-2.3-8.8,3.2-10c3.1-0.6,4.8,16,6.8,3.2c2.4,7.3,8.3,6.4,13,7
										c6.5,0.9,8.3,2.6,5.5-2.2c-0.5-0.8-10.3-5.8-1.4-8.3C2059.5,882.9,2060.4,895.2,2063.5,896.9z M2058.5,1003
										c5.7,1.3,4,11,1.2,14.8C2057.3,1017.5,2055.7,1003.6,2058.5,1003z M2063.8,995.6c3.3-2.4,6.6,4.6,1.8,4.5
										C2063.4,1000,2060.2,998.2,2063.8,995.6z M2070.6,984.5c3.3,0,3.8,6.7,0.3,7.3C2066.4,992.7,2066.1,984.5,2070.6,984.5z
										M2069.8,1009.3c-0.9,0.3-1.4,1.6-2.2,2.2c0.2-1.3-0.5-7.2,0.6-8.1C2071.4,1000.8,2070.1,1008.8,2069.8,1009.3z M2061.8,1021.8
										M2057.4,1024.2c4.2,0,7.5,15.1,0.5,13.7C2053.7,1037.1,2052.7,1024.3,2057.4,1024.2z M2049.1,1045.5c-2.9-1.1-3.3,1.6-5.7-1.4
										c-1.1-1.3-0.5-7.5,0.4-8.4C2049.5,1029.3,2053.5,1041.9,2049.1,1045.5z M2035.7,1051.2 M2036.1,1050.2
										c-5.9,5.3-13.2-12.4-1.4-10.7C2039.8,1040.2,2039.4,1047.3,2036.1,1050.2z M2021.2,1053.6c-7.2-1-3.7-12.5,1.7-9.3
										C2028.5,1047.6,2023.3,1051.4,2021.2,1053.6z M2009.7,1057.2c-5,0-6.3-11.1-3.2-13.7C2013.6,1037.6,2013.6,1057.2,2009.7,1057.2z
										M1997.3,1062.5c-2.1,0.1-3-16.8,0-17.4c2.3-0.5,3.8,6,4,7.3C2002.4,1058,2001.1,1059.5,1997.3,1062.5z M1967,1070
										c-6-2-4-15.6,0.7-13.6C1973.5,1058.9,1971,1069,1967,1070z M1904.5,1054.1c-8,0-2.1-16,4.8-8.7
										C1912.8,1049.1,1908.8,1054.1,1904.5,1054.1z M1895.1,1030.4c-2.6-1.5-4.5,1.7-6.5,0.7c2.8-2.9,1.2-9,5.6-9.7
										C1902.4,1020.2,1898.7,1032.4,1895.1,1030.4z M1862.7,1019c-0.2,0.8-0.5,1.6-0.8,2.4C1856.3,1009.9,1865.8,1009.1,1862.7,1019z
										M1857.4,1033c-5.3,2.3-5.9-6.3-3.3-9.1C1859.2,1018.5,1862.6,1030.7,1857.4,1033z M1842.9,1019.2c-5.1-2.5-4.3-19.9-0.1-17
										C1846.5,1004.8,1847,1017.1,1842.9,1019.2z M1835.1,1010.1c-0.2,1-0.6,1.9-1.3,2.6C1829.9,1011.1,1834.2,982.1,1835.1,1010.1z
										M1819.7,1000.8c-2.4-4.4,2.6-7.3,5.7-4.2C1828.5,999.7,1823.4,1007.9,1819.7,1000.8z M1816.2,993.2c-1.9,1.2-4.5-1.5-4.4-3.6
										C1812.2,982.6,1825.5,987.3,1816.2,993.2z M1839.4,963.8c0.1,2-2,6.8-1.5,8.6c-4-1.2-7.8-13.1-3-15.4
										C1839.5,954.7,1838.3,961.8,1839.4,963.8z M1843,948c6.3-5.1,10.1,5.4,6.8,9.9C1846.6,956.1,1835.7,953.9,1843,948z
										M1864.9,931.1c3-6.9,21.3-2.5,12.1,4.7C1873.5,938.5,1861.4,939.3,1864.9,931.1z M1907.4,883.9c3.7-1.9,3.8,10,0.8,11.4
										C1904.9,892.5,1902.1,886.6,1907.4,883.9z M1918.8,884.7c1.2-0.3,1.6,0.3,2.5,0.9c6.7,4.6-2.4,9.1-5,11.6
										C1915.1,893.6,1912.9,886,1918.8,884.7z M1893.7,750.8c-2.9,4.2-9.3-3.6-3.4-7.6C1896.9,738.7,1896.3,747,1893.7,750.8z
										M1843.3,640.7c-6-3.1,2.4-12.4,5.8-8.3C1850.9,634.6,1844.8,641.5,1843.3,640.7z M1847.3,610.4c5.9,1.4,1.8,7.6-1,9
										C1835.4,624.5,1842.2,609.3,1847.3,610.4z M1860.4,617.9c0.2,5.5-6.2,3.5-6.3-1.2C1853.9,610.2,1860.1,610.5,1860.4,617.9z
										M1866.6,609.3c5-1.8,7.7,16,0.4,12.9C1865.3,621.5,1863.6,610.3,1866.6,609.3z M1881.2,613.4c0.5,2.6-0.2,4.7-2,6.6
										C1871.2,617.2,1879.5,603,1881.2,613.4z M1922.1,704c6.7-0.5,6.9,9.4,3.9,12C1918.2,722.9,1907.2,705,1922.1,704z M1944.6,699.2
										c-0.2-1.2,1.4,4.7,1.4,4.7c-2,1.1-10-1.4-10.7-3.7C1933.2,693.4,1942.9,687.8,1944.6,699.2z M1953.8,692.5
										c3.4-2.4,11.2,2.8,8.2,6.1c-0.8,0.8-2.8-1.2-3.8-0.2c-1.7,1.9-3.2,3.9-4.9,5.8C1949.6,700.2,1949.2,695.8,1953.8,692.5z
										M1977.1,670.8c-0.1-12.6,14.2-6.9,8.5,1.4C1983.8,674.8,1977.1,675.5,1977.1,670.8z M1998.4,620.4c8.5,1.9,3.9,8.3-3.3,6.3
										C1987.2,624.5,1992.8,619.2,1998.4,620.4z M2026.4,621.5c5.3-4.7,7.1,5.3,4.6,8.2C2026.6,635,2019.1,627.9,2026.4,621.5z
										M2043.7,622.6c0.2,3.2-0.5,6.2-0.2,9.5c-2.6-3.1-7.3-1.9-7.7-6.1C2035.5,622.6,2042.3,617.3,2043.7,622.6z M2038.1,634.3
										c4.8,1.6,1.8,6.3,6.6,7c-0.6-0.1,2.2-4.1,4.6-4.2c-2.1,3.1-1.9,10.3-4.6,11.5c-3.4,1.6-1.6,0.2-4.4-2.9c0.2,0,5.2-1.4,3.4-3.2
										c-3.9-3.9-0.1,1.5-5.2-0.5c-2.5-1-5.6,1.2-7.1-2.8C2030.2,636,2035.5,633.5,2038.1,634.3z M2053.6,618c11.1-4.2,2.5,12.4-1.4,14
										C2044.6,635.2,2048.7,619.8,2053.6,618z M2078.3,608.9c0.3-2.6,1.9-6.7,4.2-8.5C2086.5,607.5,2077.1,619,2078.3,608.9z
										M2102.6,602.3c1.2-3.2,2.1-3.7,4.4-2.6c8.1,4-5.6,11.6-7.5,13.4C2096.6,609.8,2100.7,604.8,2102.6,602.3z M2121,598.2
										c8.5-4.4,6,7.1,2.7,10.1C2122.4,604.1,2113.2,602.2,2121,598.2z M2127.9,610.4 M2130.2,606.5c0.8-2.6,7,7.5,9.1,9.3
										c-5.1,2.3-4.2,4.2-6.4,9c-0.4,0.8,0.9,2.6,0.6,3.2c-0.6,1.2-3.5,2.7-4.6,4.1c-0.2-0.3-0.4-0.7-0.7-0.9c4.4-2.5,2.4-6.3,1.7-10.1
										C2128.7,614.1,2128.1,613.3,2130.2,606.5z M2146.4,624.8c-2.7,1.7-12.7,6.2-8.9-2.6C2140.5,615.3,2152.7,621,2146.4,624.8z
										M2120.2,749.2c-2.7,1.6-3.8-0.8-4.2-3.8C2115.2,738.9,2125.8,745.8,2120.2,749.2z M2128,795.1c0.2-5.2,3.6-8.4,6.2-4.4
										c1.3,2-1.2,10.1-2.2,12.2C2128.5,802.6,2127.9,797.7,2128,795.1z M2127.9,780.7c-0.9,6.9-8,7.7-6.7-0.4
										C2122.2,774.7,2128.8,773.9,2127.9,780.7z M2121.7,802.7c-8.2,0-7.2-10.8-2-10.9C2123.6,791.7,2122.3,802.7,2121.7,802.7z
										M2109.8,802.5c-7.4,8.4-12-6.2-3.9-8.5C2111.4,792.6,2114,797.8,2109.8,802.5z M2062.4,801.3c-4.8-0.9-2.2-14,2.3-13
										c0.5,0.1,2.7,5.1,2.7,5.7C2068.2,799.3,2065.8,799.5,2062.4,801.3z M2052,801.3c-2.8,5.6-8.9,0.8-4.1-5.1
										C2053.4,789.4,2054.7,795.9,2052,801.3z M2031.7,829.1c-0.7-3.2,4.6-1.9,5.8-1.6C2040.6,828.4,2033.4,833.9,2031.7,829.1z
										M2039.9,809.4c-4.4,8.4-18.8-7.5-4.4-6.2C2038.6,803.4,2042.6,804.3,2039.9,809.4z M2026.5,785.6c-4.4,1.5-10.5,2.2-10.7-2.9
										c-0.1-4.1,8-7.6,10.2-6.2C2028.6,778.2,2030.2,784.3,2026.5,785.6z M2005.5,786c-1.2-2.2-5.4-12.8,2.4-11.1
										C2013.1,776,2008.1,784.8,2005.5,786z M1989.1,822.4c-3.9,5.7-9.1-1.8-4.1-4.7C1987.9,815.9,1991.2,819.3,1989.1,822.4z
										M1976.2,859.6c8.4,2.4,3.1,16.5-5.1,10.1C1968.4,867.5,1970.8,858,1976.2,859.6z M2039.4,989c0.1,2.8-1.2,4.4-3.8,5
										C2031.1,988.3,2039.1,973.9,2039.4,989z M1909.2,1037.2c-6.5,4.1-11.6-3.1-6-8.7C1910,1021.9,1912.7,1034.9,1909.2,1037.2z
										M1889.7,1005.2c-0.7,0.4-6.7,7-5.9,0.5C1884.3,1002.1,1890.1,1002.5,1889.7,1005.2z M1888.5,1012.5c1.5-0.9,13.8-7.1,10.1,1.9
										C1896.8,1018.7,1886.9,1019,1888.5,1012.5z M1875.3,1010.5c-0.6-0.5-4.4-1-5.1-0.9c1.4-15.4,13.1,3.2,8.9,4
										C1877.9,1013.8,1876,1011.2,1875.3,1010.5z M1880.8,1024.9c-2-1.4,3-7.8,3-10.1C1885.3,1017.5,1888.4,1030.2,1880.8,1024.9z
										M1875.1,1024c-1.8,1.3-5.8-1.3-8.3,0.5C1870.3,1005.6,1877.6,1022.2,1875.1,1024z M1910.4,1022.8c-2.4-3.8-14.8-2.3-5.8-10.2
										C1912.3,1005.9,1911.6,1024.7,1910.4,1022.8z M1838.9,987.5c3.7-12.1,12.6,2.1,6,7C1838.8,999.1,1837.5,992,1838.9,987.5z
										M1907.8,901.3c4.5-7.6,6.9,10.3,7.7,11.8C1909.1,912.4,1903.8,908,1907.8,901.3z M1928.5,891.9c-0.2-2.4-0.8-13.4,2.9-14.4
										c4.6-1.3,5,9.7,4.3,11.6C1934.3,892.8,1930.1,896.6,1928.5,891.9z M1899.4,797.8c1.1-4.9,9-7.3,9.2-0.5c0.1,4.5-5.4,3.2-8.3,5.5
										C1899.3,802.2,1899.3,798.7,1899.4,797.8z M1847.4,624c9.3-2.7-2.5,9.2-3.4,1.5C1843.9,624.7,1846.6,624.2,1847.4,624z
										M2042.7,1021.1 M1858.9,960.9c-5.4-1.6-2.4-14.1,2.8-13.3C1869,948.8,1861.9,959.4,1858.9,960.9z M1851.6,969.9
										c1.3-6.1,7.9-6.8,8.8,0C1861.6,978.2,1849.6,979.4,1851.6,969.9z M1877.8,953.7c4.1-1.9,9,2.5,4.7,5.3
										C1879.3,961.2,1875.8,956.9,1877.8,953.7z M1878.5,948.4c-4,1.2-10.1,8.7-10.6-1.8C1867.5,937,1883.5,943.6,1878.5,948.4z
										M1864.8,963.1c1.1-1.2,3.8-5,5.3-5.8c2.4,0.9,1.5,11.1-0.6,12.1C1866.1,971.3,1862.1,966.3,1864.8,963.1z M1888.3,950.1
										c2.2-1.5,4.4-5.6,7.1-3.2c1.3,1.2,1.5,4.1,0.9,5.6C1894.3,957,1887.1,955.2,1888.3,950.1z M1884.4,937.7c3.1-1.8,6.5,0.1,6.9,3.4
										c0.1,0.8-6.8,7-7.9,6.7C1884.2,944.5,1880.7,939.9,1884.4,937.7z M2123.5,615.2c-1.3-0.4-1.5-1.4,0-2
										C2123.5,613.8,2123.5,614.5,2123.5,615.2z M2115.5,622.3c3-4,11.1-4.1,11.2,2.3c0.1,5.3-6.1,4.3-8.9,7.8
										c-0.5-1.5-4.7-4.4-4.8-4.9C2112.6,625.4,2114.8,624.7,2115.5,622.3z M2054.4,638.8c6.3,0.4,7.9,17,0.1,15.4
										C2049.3,653.3,2048.5,638.5,2054.4,638.8z M2078,783.9 M2030,751c-3.1-1.8-4.5,2.1-1.6,3.5c-2.2,0.8-5,3.4-6.2,5.6
										c-0.7-2,0.6-9.2,1-12.9c0.4-3.2-0.3-7.3,4.3-8.8C2038.5,734.8,2035,753.9,2030,751z M2122.2,645.6c3.2,5.8,2.1,17-6.4,14.1
										C2104.1,655.6,2121.5,644.3,2122.2,645.6z M2106.9,685.7c-1.8,1.7-2.9,4.2-3.4,6.7c-0.3-4.3-2.2-5.8,1-9.3
										C2106.3,681.2,2110.4,682.3,2106.9,685.7z M1999.8,705.6 M2046.2,680.8c-1.4,4.2,2.7,10-2.7,7.4c0.4,0.2-8.1-9-8.1-9.1
										C2033.5,670.3,2049.8,669.9,2046.2,680.8z M2083.7,747.7c-2.8-1.5-7.4-2.4-8.1-5.4c-0.7-2.8,3.7-8.5,5.8-9.5
										C2093.8,727.2,2083.4,742.7,2083.7,747.7z M2019.3,734.9c-0.5-4.1-6.1-13.4,1.8-13.4c7.5,0,0.2,16-1.5,19.2
										C2019.5,740.7,2019.3,735,2019.3,734.9z M2003.1,720c2.8-1.6,0.5-3.1,3.8,0.4c-1.4,0.1-2.7,0.6-3.8,1.3
										C2003.1,721.2,2003.1,720.6,2003.1,720z M2049.8,712c5,4.7-4.3,10.3-7.4,8.2C2036.7,716.2,2045.5,707.9,2049.8,712z
										M2020.7,697.6c0.2-1.2,4.3-8.6,6.8-8.4c2.1,0.2,1.1,3.7,1.7,5c4.4,10,0.5,7.7-5.2,14.1C2023.2,705,2020.1,701.2,2020.7,697.6z
										M2025.2,713.7c0.5-2.8,5.9-6.6,8.3-4.7c4.4,3.4-2.8,10-4.6,12.4C2027.9,719.2,2024.7,716.3,2025.2,713.7z M2040.6,735.6
										c3.1-6.4,7.3,5.1,6.8,7.9C2045.6,752.3,2038.5,742.4,2040.6,735.6z M2046,730c-4.4-7.4,19.4-10,9.8-0.7
										C2053.9,731.2,2047.6,732.7,2046,730z M2051.5,737c0.3-5.2,9.5-3.7,7.4,2.5C2057.6,743.3,2051.2,741,2051.5,737z M2055.2,714.8
										c-2.1-11.5,19.9-11.2,5.5,6.9C2058.8,720.1,2055.8,717.6,2055.2,714.8z M2098.2,671.6c5.6,0.6,5.3,9.9,0.1,8.9
										C2094,679.7,2092.7,671.1,2098.2,671.6z M2092.7,718.9c0.9-3.1,2.8-0.1,2.8-0.1C2095.9,718.8,2091.9,721.7,2092.7,718.9z
										M2094.6,709c-1,1.1-1.5,2-2.4,3.1c-2.2-1.8-3.4-3.8-6.4-4.6c3-1.7,2-8.5,7.5-6.8C2097.2,701.9,2096.9,706.4,2094.6,709z
										M2079.1,715c2.2-6.5,8.8-3.7,9.5,1.4c0.7,4.8-5.1,7.6-6.8,11.2C2078.7,727.8,2079.3,716.6,2079.1,715z M2074.9,714.5
										c-0.8,3.2-1.9,2.4-4.9,2.1C2069,713.7,2073.3,710.9,2074.9,714.5z M2088.7,727.5c-0.5,0.5-1.1,0.5-1.8,0c1.1-0.9,2.2-1.8,3.2-2.7
										C2089.7,725.7,2089.2,726.6,2088.7,727.5z M2101.7,704.5 M2098.1,697.6c-5.2-1.1-17.5-8.2-8.4-12.9
										C2097,681,2101.4,691.7,2098.1,697.6z M2083.2,694c4.8,0.6,2.2,3.4,1.9,5.6c-0.5,3.6-8.9,11.6-12.1,8.3
										C2070.2,704.7,2077.8,693.3,2083.2,694z M2067.1,689.8c5.3,4.4,3.7,17.4-4.5,12.6C2056.2,698.7,2060.8,684.7,2067.1,689.8z
										M2063.4,730.9c4.8-18.6,17.1-4.4,13.3-0.2C2075.1,732.5,2064.7,725.8,2063.4,730.9z M2097.3,667.6 M2086.5,671.4
										c10,0-2.6,11.4-3.2,12.1C2079.8,680,2080,671.4,2086.5,671.4z M2079.2,689c-4.1,9.3-13.7-5.6-6.3-7
										C2076.4,681.4,2081.3,684.4,2079.2,689z M2076,674.8c-1.8,6.1-14.9,3.1-10.4-3.4C2068.6,667.1,2077.9,668.4,2076,674.8z
										M2039,688.6c2.2,2.5,2.6,5.5,2.7,8.7c0.1,5.1-2.5,6.5-3.3,10.4c-1.8-4.1-3.6-8.9-4.2-13.8C2033.9,690.9,2033.5,683,2039,688.6z
										M2030.8,683.2c-1.2,1.1-4.3-1.4-5.9-0.6c-0.9,0.4-0.4,2.4-0.5,2.4c-1.1,0.3-1.5,3.5-3.6,3.1c-5.6-1.2,0.7-9.4,3-10.4
										C2027,676.2,2035.9,678.2,2030.8,683.2z M2018.4,712c-1.2,9.7-11.8,8.3-11.3-1C2007.6,701.8,2019.7,701.3,2018.4,712z
										M2013.8,699.4c-1.3,0-11.1,5.3-11.9,2.8c-0.5-1.5,7.7-13.5,8.1-13.7C2016.5,685.2,2019.4,695.9,2013.8,699.4z M2034.3,735
										c-8-0.2-4.9-8.2-0.5-11.5C2044.6,715.2,2038.4,736.7,2034.3,735z M2102.9,712.4c-4,6-6.9,0-1.6-2.7
										C2103.2,708.7,2105.8,708,2102.9,712.4z M2108,677.1c-0.1-3.4-11.8-17-1.1-16C2116.1,662,2108.6,677.4,2108,677.1z M2052.9,693.8
										c3.1,4.2,4.2,13.9-4.1,12.1C2041.1,704.2,2048,687.2,2052.9,693.8z M1991.2,693.4c1.1-5.5,5.1-16,10.4-7.1
										C2007.1,695.5,1990.5,702.3,1991.2,693.4z M1996.8,708.1c0.5-1.1,0.8-1.7,1.8-2.2c2.7-1.3,3.3,6.6,3.3,6.9
										C2000.4,721.5,1993.6,715.1,1996.8,708.1z M2002.5,729.1c1.2-3.7,8.4-7.6,8.3-1.5C2010.7,733.1,2000.5,735.4,2002.5,729.1z
										M2011.5,733.8c1.1-1.5,1.6-1.1,3-0.6c6.5,2.4-4.1,8.7-5.1,9.7C2006.8,741.4,2010.5,735.1,2011.5,733.8z M2013.7,744.8
										c1.8-2.1,2.6-1,4.2,0.1c1.6,1.1,1.6,6.6,1.2,7.6C2016,760.5,2006.7,752.6,2013.7,744.8z M2088.4,749.7c-1.6-6.2,4.2-19,8.5-8.1
										C2099.2,747.8,2089.7,754.9,2088.4,749.7z M2101,732.2c-3.2,1.5-6.2-0.7-9.1-1.6c1.3-1.2,3.9-7.8,5.8-8.3
										C2105.1,720.5,2104.7,728.3,2101,732.2z M2100.5,645.8c1.1-0.4,2.3,1,3,1.6c6.5,5.6-1.7,8.7-4.5,13
										C2096.3,656.4,2093.2,648.4,2100.5,645.8z M2088.8,656.6c2.9-0.8,6.3,8.1,4.4,10.4c-1.6,1.8-6.7-0.9-10.3,0.9
										C2082.5,666.1,2086.6,657.2,2088.8,656.6z M2060.1,683.5c-2.5,1.9-5.8,9.9-8.7,3.1c-1.6-3.6,3.3-10.6,7.5-9.7
										C2062.5,677.7,2064.6,680.1,2060.1,683.5z M2053.8,663.8c2.5-5.3,8.4,6.5,2.3,9.5C2048,677.3,2052.3,667.3,2053.8,663.8z
										M2036.6,752.9c2.4-2.4,2.9-3.3,5.8-1.3c2.9,2.1-0.1,8.7-3.2,8.6C2037.2,760.1,2035,754.6,2036.6,752.9z M2044.9,770.8
										M2049.9,771.5c0.8-1.6,2.8-9.1,3.4-9.4c2.9-1.2,7.4,5.5,7.4,8.3c0.1,4.6-3.7,6.4-8.5,4.3
										C2050.9,774.1,2047.4,776.9,2049.9,771.5z M2053.6,757.6c-10.7,0-5.5-9.1,2.1-10.8c13.1-2.9,10.2,10.8,7.2,18
										C2061.1,761.4,2057.7,757.6,2053.6,757.6z M2064.3,741.7c-3.5-4.2,3.1-10.9,7.4-7.7c3.5,2.6-1.6,12.9-2.6,14.6
										C2068.3,746.2,2065.8,743.5,2064.3,741.7z M2072.5,751c3.8-7.1,15.5,3.9,13.3,6.5c-3.9-2.7-7,0.5-8.6,4
										C2073.2,757.7,2068.7,757.9,2072.5,751z M2080.5,770.1c0.3-2.2-0.8-9.2,2.8-9.2c6.4,0,2.8,13.9-2.6,12.3
										C2080,773,2080.4,770.2,2080.5,770.1z M2092.2,768.5c-1.3-3.2-0.6-16.3,5.2-13.6C2103.9,758,2093.4,766.4,2092.2,768.5z
										M2098.2,768.6c3.9-4.2,12.8-6.1,9.9,2.7C2105.7,778.6,2095,774.1,2098.2,768.6z M2106.9,758.1c-4.4-4.6-3.1-19.7,4.4-12.6
										C2114.6,748.7,2114.8,766.4,2106.9,758.1z M2114.1,702.9c-1.9,2.6-0.8,5.1-5.4,4c-3.3-0.8-4.2-4.2-2-6.8
										C2109.3,697,2114.3,698.3,2114.1,702.9z M2115.7,679.3c-1.6-2.5-1.6-13,2.9-13C2129.3,666.3,2122,688.9,2115.7,679.3z
										M2126.7,660.6c2.8-4.7,7-1.4,6.9,3.1c0,1.3-4.1,5.9-5,7.4C2126.9,667.6,2123.4,664.4,2126.7,660.6z M2130.8,648.2
										c-0.3,0.9-0.7,1.7-1,2.6c-1.2-3.9-13.5-18.7-5.3-17.3c0.8,0.1,1.1,5,1.6,5.7c3.4,4.2,5,1.5,8.5,3.6
										C2134.9,644,2131.3,647.3,2130.8,648.2z M2093.7,646c-0.6,0.6-3.5,5.6-3.5,5.6c-1.9,0.3-3.6-2.4-2.6-4c0.4-0.6,6.7-5.7,7.7-4.1
										C2095,644.6,2094.5,645.4,2093.7,646z M2082.1,648.3c3.5,5.3-1.7,20.3-7,12.8C2071.8,656.4,2078.6,643.1,2082.1,648.3z
										M2063.3,655.4c2.7-0.4,6.4,2.4,7,4.9c0.4,1.9-1.4,3.4-3.8,4.2c-4.1,1.5-2.2,3-4.6-1.5C2060.8,661.1,2060.2,655.9,2063.3,655.4z
										M2048.9,662.2c-0.6,3.2-2.5,6.1-4.9,6.6c-3.9-2.3-8.7-1.6-7.9-7.7C2037.2,652.8,2050.9,652.1,2048.9,662.2z M2036.5,652.1
										c-2.9,5-8.6,7.1-8.8-1.3C2027.5,641.8,2039.5,647,2036.5,652.1z M2030.8,670.8c-2,3.9-7.4-1-10.3,3.1c-0.7-3.6,0.2-10.7,3.3-12.9
										C2028,657.9,2033.4,665.7,2030.8,670.8z M2019.8,647.7c6.1,0,0.1,9.9-0.8,12.2C2015.6,656.6,2009.7,647.7,2019.8,647.7z
										M2014.5,663.4c4.2,10-10.6,13.5-8.4,2.7C2008.3,655,2010.8,662.1,2014.5,663.4z M2008,652.4c-0.9,3-2.2,6.8-4.5,9
										c-4.1-4.6-6.1-6.5-0.4-11.1C2005.7,648.1,2009.9,645.7,2008,652.4z M1997.6,638.4c-4.4-1-6.8-4.6-2.2-7.2
										C2002.4,627.2,1997.9,636.4,1997.6,638.4z M1987.6,658c0.6-0.7,1.7-1.1,2.6-1C1998.3,657.1,1982.1,664.6,1987.6,658z M1990,671.5
										c1.4-5.7-1-3.8,3-7.4c1-0.9,1.9-2.6,2.4-3.8c1.5,1.6,3.6,3.7,4.5,5.6c1.4,2.9,0.1,7.7,1.3,10c1.3,2.5,2.3,4.1,4,0.3
										c4.7,2.1,9.6,0.3,9.3,7.4c-2.8-0.8-5.9,0-7.9,2.2c-4-7.5-11.8-10.7-16.4-2.3c-0.6-1.2-4.8-4.3-4.9-5
										C1985.3,678.7,1989.7,672.8,1990,671.5z M1990.5,702.6c4.7,2.7-2.8,15.9-7.5,9.5C1980.1,708.3,1987,700.6,1990.5,702.6z
										M2044.2,783c-2,3-11.2,1.7-10.9-3.1C2033.9,770.6,2051.9,774.5,2044.2,783z M2113.4,694.6c-3-1.7-7.5-2.6-1.3-7.5
										c4.1-3.3,5.3-1.1,5.9,3.3C2119,696.6,2117.6,695.2,2113.4,694.6z M2112.6,639.7c-0.8,1.6,0.9,3.8,2.8,2.6
										c-0.8,1.6-2.7,2.6-4.2,3.9c-3-6.1-20.5-6-8.4-13.1C2108.3,629.9,2116.7,631.6,2112.6,639.7z M1996.9,648.5
										c-0.7,7.9-7.8,2.7-8.2-2.2C1988.2,637.7,1997.6,640.8,1996.9,648.5z M1979.5,699.3c0.9,0.1,1.6,0.3,2.3,0.9
										c-1,1.1-1.9,2.4-2.6,3.8C1978.3,702.6,1975.9,699.2,1979.5,699.3z M1963.2,707.1c4.7,13.6-15.3,6-5.8-0.3
										C1959,705.8,1962.2,704,1963.2,707.1z M1954.5,718.1c4.2,3.2,8.1,2,11.3-2.1c3.9,5.1,10.5,2.6,13.9-1.7c0.8,1.1,9.3,7.5,9.3,7.5
										c1.5-0.4,1.4-4.6,2.9-5.9c1.1,4.3,6.2,4.2,7.2,6.4c2,4.4-1.3,7.5,1.6,12.4c1.3,2.3,3.3,2.6,4.3,4.6c0.1,0.2,2.8,7,2.9,7.4
										c0.4,4.2-3.3,8.8,1.8,12.3c-5.7,4-3.6,7.5-5.4,12.5c-1.1,3-5.8,6.4-7.2,7.5c-2.5,1.9-7.6,5.5-9.7,6.6c-3.1,1.6-7.1,0-9.6,4.2
										c-3.5-6.1-7.6-2.9-12.8-3.3c-1.1,2-2,2.2-2.9,0.7c-1.1-0.8-2.3-1.5-3.4-2.3c-1.9-0.8-3.9-0.8-6,0.1c-0.6-2.1-1.7-4-3.1-5.6
										c-2.5-1.8-4.8-4.4-6-6.1c0.4-1.9-0.1-3.8-1.3-5.5c-0.8-1.9-2.1-3.2-3.9-3.8c-2.2-4.4-2.3-12.9-6.4-17.3c2.9-0.8,6.3-3.5,6.9-7.3
										c0.3-2.3-2.2-6.1-2-7.4c0.7-3.4,5.2-5.6,5.7-10C1946.6,724.4,1952.4,721.9,1954.5,718.1z M2021.2,1023.9
										c2.9-4.3-6.7-17.8,4.8-14.6c9.5,2.7-1.6,17.2-4.7,16.2C2021,1025,2020.9,1024.5,2021.2,1023.9z M2003.2,1018.4
										c-5.9,3.1-6.8-0.9-8-4.2c-1.7-4.5-0.3-10.8,5.9-8C2006.4,1008.5,2004.1,1015,2003.2,1018.4z M1975.8,1009.8
										c-0.1-1.3,6.2-6.2,8.4-5.5C1992.1,1006.5,1976.5,1018,1975.8,1009.8z M1984.9,1016.1c1.8-1,2.7-4.2,4.9-3.9
										c0.1,1.2,2.8,9.9-2.3,7.9C1986.5,1019.7,1984.8,1017.5,1984.9,1016.1z M1990.6,1005.7c-4.6,0-1.8-9.3,2.5-8.6
										C1998.4,997.8,1992.9,1004.3,1990.6,1005.7z M1948.7,952.2c0.1-0.1,1.8-3.4,2.3-3.7c2.3,3.2,7.8,6.1,3.7,11.3
										C1950.6,965.1,1944.1,958.4,1948.7,952.2z M1955.5,946.8c-2.2-2.1-5.8-4.2-8.3-1.2c-0.9-3.7-2.7-4.5,1-8.2
										C1955.3,930.3,1961.6,941.6,1955.5,946.8z M1913.4,945.4c0.6-7.7,8-10,9.3-0.9C1924,954,1912.8,953.6,1913.4,945.4z M1916.6,934
										c-2.6,0.9-4.4,2.5-5.5,5c0.7-2.6,0.7-5.1-0.2-7.6c4.8,0,6.1-6.2,10.1-5.3c6.2,1.4,5.3,9,3.6,12.5
										C1923,935.9,1920,933.1,1916.6,934z M1903.9,945.2c1.9-1.3,3.4,0.3,4,2.3C1909.7,954.1,1894.9,951.5,1903.9,945.2z M1907.5,916
										M1909.9,942.1 M1946.9,841.8c-2.8,7.5-3,3.1-9,1.1C1933.9,835.8,1951.8,828.6,1946.9,841.8z M1914.5,721.2
										c-0.3,0.6-0.6,1.2-0.8,1.8C1912.4,722.6,1914.9,720.2,1914.5,721.2z M1907.7,730.1c5.2-7.4,12.3,5,4.6,10.9
										C1910.4,736.8,1904.1,734.6,1907.7,730.1z M1972.1,795c-0.7,0.9-1.8,1.4-2.5,2.3C1957.6,786.8,1978.2,789.3,1972.1,795z
										M1971.9,899.8c-4.2,3.2-2.8,9-4.3,12.5c-0.6,1.4-3.4,4.3-4.7,6.5c-5-2.4,0.9-9.9,1.5-13.1c0.6-3-2-9.1,1.3-11.5
										C1970.3,891.2,1970.6,898,1971.9,899.8z M2016.6,982.3c-2.6,1.4-3.5,4.2-6,5.6c-1.4-2-3.1-3.8-5.1-5.2
										C2008.9,977.3,2022.8,978.8,2016.6,982.3z M2011.1,974.6c-2.5,1.9-5.7,3.1-4.3-0.9C2008.2,969.6,2015.4,970.7,2011.1,974.6z
										M2004.8,968.6c-5.9,6.7-10.7-12.1-3.9-10.9C2004.5,958.4,2006.4,966.8,2004.8,968.6z M1996.4,937.2c-1.7,1.3-8,4.8-5.4-2
										C1992.1,932.3,2004.6,930.8,1996.4,937.2z M1993.2,943.6c4.4,2.8,3.1,11.6,0.6,15.3C1987.4,955.8,1983.8,943.3,1993.2,943.6z
										M1984.2,957.9c1.1-1.1,1.9-0.8,2.4,0.7C1984.5,960,1983.7,959.8,1984.2,957.9z M1988,931c-2,9.9-16-6-6.3-11.7
										C1986.7,916.3,1988.9,926.4,1988,931z M1978.5,915.5c-0.4,1.6-1.2,2.9-2.3,4.1c-2.6,0.3-4.2-9.6-4-10.5
										C1973.7,896.9,1981,907.7,1978.5,915.5z M1950.5,851.8c-0.5-5,0.8-10.7,5.9-6.6c3.8,3,2.3,19-4.7,16.1
										C1951.2,858.1,1950.5,855,1950.5,851.8z M1967.9,836.1c-0.2,5.2-3.5,7.9-7,4.6C1952.2,832.6,1968.2,827.7,1967.9,836.1z
										M1949.5,908.9c1.1-6.5,12.2-13.9,10.6-2.6C1959.5,911.1,1948.2,916.5,1949.5,908.9z M1964.4,889.4c-0.6,1.2-2.9,6.1-4.1,6.8
										c-5-0.6-4.7-9.4-2.1-12C1962.3,880,1967.5,883.4,1964.4,889.4z M1963.3,874c-2.7,3.1-4.1,4.3-8.2,6c0.1-3.5-2.1-11.8-0.6-14.5
										C1960.2,855.7,1963.6,869.8,1963.3,874z M1955.4,831.9c-0.3,0.9-0.5,1.8-0.8,2.7c-5.3-1.1-0.8-18.4,2.2-19.1
										c3.9-0.8,4.3,5.1,4,7.4C1960.3,828.3,1957.5,827.4,1955.4,831.9z M1953.2,838.3 M1951.1,888.2c5.1,0.8-3.1,11.6-3.9,13.1
										C1946.4,898.2,1944.4,887.1,1951.1,888.2z M1965.4,800c2,3.3-2.4,12.7-6.5,9.1C1954.9,805.6,1962.3,794.9,1965.4,800z
										M1955.1,799.5 M1952.6,803.4c3.4,6.3-2.6,11.5-7.8,6.9c-2.1-1.9-3.3-1.5-0.7-6.4C1947.6,797.6,1948.4,800.1,1952.6,803.4z
										M1946.6,790.1c-2.9,3.7-6,7.3-8.6,11.2c-1.8-2.4,1-5.4,1.3-9.3c0.3-2.7-2.7-5.4-1.5-8.4C1941.6,774.4,1951.9,783.3,1946.6,790.1
										z M1934.8,795.1c-1.4,2.6-6.2,2.4-8.3,4.6C1921.9,783.7,1939.6,786.2,1934.8,795.1z M1937.8,775.8c-0.5,0.5-1,1-1.3,1.7
										C1933.7,760.1,1942,772,1937.8,775.8z M1924.5,797.5 M1947.6,827.9c-2.4,1.4-5.3,1.8-7.8,3c0-3,0.2-15.9,5.3-15.2
										C1951.1,816.6,1948.5,824.9,1947.6,827.9z M1940.8,924.1c2.2-16.4,9.9,0.7,4.6,7.9C1942.5,930.5,1940.4,927.5,1940.8,924.1z
										M1952.8,925.9c-4.7-21.8,15.7,7.4,8.4,9.4C1959.6,935.7,1952.9,927.4,1952.8,925.9z M1976.4,943.4c0.7-10.5,13.6-0.8,5.8,5.4
										C1980.9,948,1976.3,944.9,1976.4,943.4z M2017,996.7c-6.3-5.1,2.7-14.4,5.7-10.5C2023.5,987.3,2019.1,998.4,2017,996.7z
										M2029.6,1002.6c-1.9,6.2-11.4-2.4-3.9-7.1C2028.8,993.5,2031,997.8,2029.6,1002.6z M1928.4,749.3c1.2,0,6.3,11.4,4.2,12.8
										c-2.5,1.7-6.2-1.3-9.7,0.8C1920.2,759.8,1924.7,749.3,1928.4,749.3z M1924.8,809.6c3-5,5.3-11.1,10.6-4.8
										c1.9,2.2,3,10.5,0.6,12.2c0.7-0.5-7.8-2.3-7.5-2.2C1926.8,814,1923.3,816.9,1924.8,809.6z M1933.8,917.8
										c7.1,3.8-5.1,20.8-7.2,8.1C1926.2,923.5,1930.6,916,1933.8,917.8z M1934.6,933.1c1.6-1,7.1,1.5,7.9,4c0.8,2.6-3.4,2.9-3.6,5
										c-0.2,2,1.9,4.1,1.8,4.9c0.3-2.2,2.4,9.8-2.1,4c-1-1.3,0.5-5-1-7.1c-2.1-3-5.9-3-8.8-4.5C1930.1,937.2,1932.3,934.5,1934.6,933.1
										z M1970.1,935.3c-6-4.3-3.8-11.2-2-17.9c4.2,5.3,9.8,14,4.7,20.4C1971.9,937,1971,936.1,1970.1,935.3z M2005.7,989.8
										c0.1,0.7-4.8,11.2-5,11.1c-0.8-3.2-3.9-5.5-4.6-8.2C1994.1,985.3,2004.6,983.7,2005.7,989.8z M2006.3,1003.1
										c1.1-3.3,0.6-6.2,3.9-6.2c0.8,0,5.2,4.2,5.5,4.7C2021.4,1012,2002.8,1013.2,2006.3,1003.1z M2019.7,975.1 M1966.3,862.4
										c-3-3.1-6.3-13.5,0.7-15.2C1975,845.2,1967.9,859.6,1966.3,862.4z M1969.9,821c-0.2,1.1-1.9,6-3.1,5.7c-1-0.3-1.9-10.7-1.8-11.3
										C1967.4,803.4,1972,813.5,1969.9,821z M1971.5,804.7c-3.5-2.2,8-9.7,8.5-5.4C1980,799.6,1972.4,805.3,1971.5,804.7z
										M1925.3,730.3c-1,1.4-3,4.6-3.3,6.2c-0.5-0.1-3.8-10.4-3.7-10.9C1919.9,716.9,1928.5,724,1925.3,730.3z M1914,748.9
										c0.2-4,6.6-9.9,9-3.9c1.5,3.7-4.3,6.9-5.5,9.4C1915.9,752.2,1913.8,752.1,1914,748.9z M1921.9,775.8c4-16.4,13.5-2.7,9.1,2.1
										c-1.5,1.6-13.7-1-4.5,4.5C1924.6,784.5,1921.6,777.3,1921.9,775.8z M1922.8,802c0.7,4.9-2.1,8-6.5,5.9
										C1905.2,802.7,1921.5,793,1922.8,802z M1914.2,815.6c7.4-3.6,9,5.5,3.6,6.9c-0.8,0.2-7.4-2-7.4-2.1
										C1912.2,820.5,1913.5,817,1914.2,815.6z M1926.9,816.6 M1933,846.6c0.8-1,1.8-1,2.9-0.7c1.6,0.4,1.6,3.3,1.6,4.5
										C1937.7,859,1929.1,851.9,1933,846.6z M1948.8,867.3c1.6-0.4,0.8-1.7,1.2,1.4C1948.7,868.3,1948.3,867.8,1948.8,867.3z
										M1950.4,879.2c-1.1,4.1-5.4,7.7-8.7,10.1c-0.2-3.3-1.7-16.4,2.4-17.8C1947.8,870.2,1951.2,876.1,1950.4,879.2z M1939.9,899.2
										c3.1,4.9,3.8,18.5-4.2,13.7C1930.3,909.7,1934.3,890.4,1939.9,899.2z M1927.1,952.1c0.2-4.5,6.2-11.6,6.3-2.7
										C1933.5,953.8,1926.9,957.7,1927.1,952.1z M1932.6,961.3c2.4-5.1,11.7-3.8,8.7,2.4C1939.3,967.9,1930.2,966.6,1932.6,961.3z
										M1961.2,955.9c3.1-3.4,8.3,0,5.9,4.3C1964.2,965.1,1955.5,962,1961.2,955.9z M1964.4,949c-1.4-0.3-3.4,0.6-4.5,1.4
										c-0.4-4.5,5-11.8,8.7-9.7c2.3,1.3,3.5,7.1,1.4,8.9C1966.9,952.3,1967.9,949.7,1964.4,949z M1974,952.6c3.5-3.5,8.9,6.6,4.6,9.5
										C1973.9,965.2,1971.1,955.5,1974,952.6z M1983.3,965.8c1.5-3.4,4.7-4,7.6-2.5C2006.4,971.6,1975.1,978.4,1983.3,965.8z
										M1993.7,977.4c3.6-4.9,12.6-2.6,6.8,3.1C1998.1,982.9,1989.8,982.4,1993.7,977.4z M2032.4,980.4c-0.8,2.8-2,5.3-2.1,8.3
										c-7.2-7.8-4.2-12.2,0.5-19.7C2033.4,973.4,2033.9,975.3,2032.4,980.4z M2026.4,966.6c-2.1,2.6-5,4.9-6.8,7.7
										c-1.9-2.8-4.5-7.2-1.5-9.8C2019.7,963,2028.3,964.2,2026.4,966.6z M2019.2,955.7c-1.5,4-5.3,5.3-6.6,9.3
										C2002.9,953.7,2022.4,947.1,2019.2,955.7z M2012.1,947.6c-4,6.6-7.7,8.6-11.1-0.5C1995.1,931.6,2017.1,939.3,2012.1,947.6z
										M2011.1,935.5 M2008.7,925.8c-5.6,1.4-0.8-19.4,5.8-7.7C2011.6,919.4,2009.2,922.5,2008.7,925.8z M2003.6,933.7 M2002.2,929
										c-3.5,2.2-9.2-1.5-8.1-6.3C1997.1,909,2005.6,926.9,2002.2,929z M1994.2,912.7c-1.6,1.4-3.2,2.9-4.6,4.5
										c-3.2-2.7-8.1-13.6-1.1-15.2C1992,901.2,1997,910.3,1994.2,912.7z M1986.8,896.5c-5.5,9.4-14.5-0.7-8.8-5.5
										C1981.6,888,1989.3,892.2,1986.8,896.5z M1979,884.1c-3.6,12.4-14.7-2.7-10.9-8.6C1972.2,869,1980.7,878.4,1979,884.1z
										M1988.4,798.8c-1.4,0.6-7.5-2-7.8-4.3C1979.2,785.9,1996.6,791,1988.4,798.8z M1934.2,735c2.5,4.3-4,11.2-7.1,7.7
										C1924.3,739.5,1929.4,727,1934.2,735z M1912.5,703.9c-0.9,1.7-1.8,3.5-2.8,5.2c-3.2-3.3-1.1-5.8-3.4-9.6
										C1908.6,698.6,1914,700.6,1912.5,703.9z M1890.8,717.9c-0.9-2.7-4.8-11.8,1-12c9.6-0.3,1.2,16.5-0.8,19.6
										C1890.4,723.1,1891.5,720.3,1890.8,717.9z M1885,695.7c-1.6-9,15.4-2,1.3,8.1C1884.1,701.1,1885.5,698.9,1885,695.7z M1870.4,668
										c-0.4-5.6,2-14,8-8.1c6.9,6.8-0.3,13-4.1,18C1874.3,674.5,1870.7,671.7,1870.4,668z M1880.3,677.2c1.9-2.7,6-5.7,8.4-1.5
										c1.9,3.3-3.9,10.3-5.9,12.4C1879.4,684.5,1876.5,682.4,1880.3,677.2z M1900.5,692.5c-1.2,1.5-9.4-3.8-10.5-5.8
										c0-2.6,4.5-5.9,6.7-4.9C1898.4,682.4,1901.5,691.2,1900.5,692.5z M1896.9,699.9c3.4-4.1,8.9,5.6,6.1,8
										C1899.8,710.7,1896.1,700.8,1896.9,699.9z M1891.2,658.5c0,0-0.8,9.4-2.4,10c-3.1,1.1-5.4-15,0.7-15.8
										C1891,654.5,1891.3,656.2,1891.2,658.5z M1866.1,643.7c2.7,0.6,6.6-10,9.6,1.7C1878.9,658.5,1858.4,655.8,1866.1,643.7z
										M1856.1,659.2c1.7-1.3,3.5-4.9,5.8-4.5c4.9,1,3.3,9.8,0.4,11.9c-1.2-3.4-8.2-5.2-8.3-5.3
										C1854.7,660.6,1855.4,659.9,1856.1,659.2z M1900.6,714.2c0.7-1.9,17.1-4.8,3.9,10.2C1902.6,722.3,1899.4,717.3,1900.6,714.2z
										M1920.8,834.2c1.6-1.5,1.9,0.3,2.9,0.8C1925.8,836.1,1917.3,837.6,1920.8,834.2z M1928.2,822.2c0.4-0.5,1.7-2.3,2.4-2.9
										c4,3.3,4.6,24.5-2,24.6c-3.6,0.1-0.7-6.9-0.9-7.8C1927,830.2,1923.2,828.2,1928.2,822.2z M1927.4,848c2.3,0.5,1.1,4.9-0.5,6
										C1927.3,852,1927.5,850,1927.4,848z M1930.1,914.1c-3.2,1.4-7.4,4.6-8.1,8.2C1913.6,918.8,1933.2,909.5,1930.1,914.1z
										M1908,920.6c3.1-3.5,10-2,7.4,3.4C1911.8,931.2,1904.7,924.4,1908,920.6z M1979.3,1035.1c-2.8-1.1-10.9-7.3-3.5-8.9
										C1983.2,1024.6,1984.4,1037.1,1979.3,1035.1z M2009.6,1016.4c3.6-1.8,9.5,1,8.1,5.3c0.1-0.3-7.6,3.6-8.7,5.4
										C2003.9,1025.3,2009.3,1018,2009.6,1016.4z M2033.3,1018.4c1.2-2.7,3.8-7.5,5.4-2.2C2040.6,1021.9,2031.7,1023.9,2033.3,1018.4z
										M1973.2,842.4c-0.8,1-3,1.1-2.2-1.1C1971.4,840.7,1973.5,842,1973.2,842.4z M2001.2,793.7c-2.6,6.1-10-3.5-8.9-7
										C1995.1,778.3,2004.7,786.6,2001.2,793.7z M2008.9,770.3c-5.5-2.1,6.5-13.9,9.5-8.6C2019.7,764,2009.7,769.7,2008.9,770.3z
										M2014,774.3c0.8-0.7,5.4-9.8,8.1-6.8C2026.8,772.4,2010,777.6,2014,774.3z M2082.7,778.2c2.8-2.6,7.9-6.3,11.5-2.8
										c-2.4,1.4-4.2,4.4-4.4,7.1c-2.4-1.2-5.7-2.1-8.2-0.4C2081.2,780.6,2081.5,779.3,2082.7,778.2z M2115.9,760.3 M2115.5,736.3
										c-1.7,6.7-9.2,1.7-13.1,0.8C2104,731.2,2118.3,725.5,2115.5,736.3z M2108,724c-3-2.8-3.7-9.4,1.1-10.3
										C2119.8,711.7,2114.6,730.3,2108,724z M2140.3,638.6 M2136.9,606.8 M2087.5,625.5c13.1,1.9-1.9,25.7-7.6,12.9
										C2078,634.3,2082,624.7,2087.5,625.5z M2071.4,639.3c7.7,2.7-0.6,15.2-6.4,10.3C2059.7,645,2064.1,635.1,2071.4,639.3z
										M2024.8,639.5c-0.3,2.6-4.4,5.1-6.8,2.9C2012.4,637.3,2022.8,629,2024.8,639.5z M1858.5,681c0.8-6.4,12-12.5,11-1.7
										C1868.9,685,1857.8,686.5,1858.5,681z M1906.8,939.1c-4.7,2.2-15.6,3-10.5-6.5C1900.1,925.6,1911.3,931.8,1906.8,939.1z
										M1903.4,925.5 M1852.9,961.1 M1946.3,1013.5c-3.6-1.1-5.3-6.9-0.5-9.3C1953.3,1000.6,1956.2,1013,1946.3,1013.5z M1957.3,1017.6
										c-0.3,1.1-2.3,2.6-3.2,3.6c-1.8-5.1,0.1-5.9,2.2-9.9C1958.9,1011,1957.3,1017.5,1957.3,1017.6z M1953.6,1026.8
										c-0.4-1.5,4.2-3.5,3.7,2.3c-0.1,1.1-3.2,2.9-3.6,5C1952.3,1033.3,1954.2,1028.8,1953.6,1026.8z M1963.8,1026.4
										c-8,0.2-1.7-9.4,3.1-8.6C1973.7,1018.9,1969.8,1026.3,1963.8,1026.4z M1970,1012.8c-5.4,5.6-12-7.1-4-8
										C1969.3,1004.4,1972.4,1010.3,1970,1012.8z M1941,1028.2c0.2-4.9,1.9-10,5.6-8.4c1.2,0.5,2.3,5,2.4,6.1c0.9,7-2,6.7-6.6,10.6
										C1940.3,1035,1940.8,1030.3,1941,1028.2z M1966.1,1038.7c-3.1,0.9-7.9,0.6-8.2-3.3C1957.3,1026.1,1978.4,1035.2,1966.1,1038.7z
										M1936.9,1020.3c-4.7,2.4-5.6-9.2-4-11.2C1937.2,1003.6,1941.2,1018.1,1936.9,1020.3z M1928.9,1010.5c-0.3,2.4-0.3,4.9-1.2,7.1
										C1927,1015.3,1925.6,1004.7,1928.9,1010.5z M1916.2,1016.8c0.3-8.8,4.4-8.1,6.3-0.8C1925.1,1025.7,1915.9,1027.4,1916.2,1016.8z
										M1914,1027.8c3.7-1.6,1.5,5.2,0.9,3.7C1914.8,1031,1912.5,1028.8,1914,1027.8z M1982.7,1024.1c-2.8-2.5-7.3-1-8.3-4.4
										C1972,1011.7,1988.2,1022.5,1982.7,1024.1z M1997.9,1037.9c-2.4-3.6,0.8-15.9,5.8-11.9C2007.4,1029,2003.8,1046.8,1997.9,1037.9z
										M2024.1,1037.3c-1.2-3.2,1.7-14.6,6.1-12.4c7.5,3.7-2.3,12.4-4.9,14.6C2025,1038.7,2024.5,1038,2024.1,1037.3z M2037.3,963.4
										c-0.5,1.1-1.8,3.3-3.4,2.3C2033,962.1,2037.2,958.3,2037.3,963.4z M1973.6,836.7c-1.9-1.5-2.3-13.3,1.4-13.2
										C1979.4,823.6,1976.9,839.5,1973.6,836.7z M1979.4,818c-4.1,1.3-3.3-9.8-1.7-11.5c3.6-3.8,5.1-1.8,7.9,0.2
										c0.3,0.2,4.3,6.2,4.3,6.3C1989.6,814.4,1981,813.7,1979.4,818z M1993.8,800.8c0.7-0.8,1.5-0.8,2.5-0.7c4,0.2,4.7,6.8,2.3,9.1
										C1993.8,814,1989.5,805.6,1993.8,800.8z M2027,766.2c-2.5-11.7,15.4-3.6,4,8.7C2027.7,773.3,2027.7,769.3,2027,766.2z
										M2041.4,796c-2.3,4.3-3.4,2.7-8.8,3.4c3.1-5-1.6-9.8,3.8-11.6C2043.5,785.5,2043.4,792,2041.4,796z M2051.1,787.8
										c-6.5,1,1.3-9.1,5.5-8.8c7.7,0.7,3.3,8.1,0.1,11.2C2055.4,788.7,2053.2,787.5,2051.1,787.8z M2064.7,778.9
										c0.3,0.1,0.4-17.2,6.2-16.9c9.9,0.4,1.1,23-0.1,26C2069.9,785.7,2067.7,779.5,2064.7,778.9z M2075,788.8
										c3.3-3.5,9.8,9.3,4.8,12.4C2073.1,805.2,2072.1,791.9,2075,788.8z M2083.9,785.5c2.1-1.4,4.2,0.9,5.5,2.2
										c-0.9,0.3-3.5,2.9-4.3,2.3C2084.1,789.3,2082.7,786.3,2083.9,785.5z M2086.4,803.1c-1.1-5.9,5.8-14.8,9.5-6.2
										C2098.1,802.1,2087.6,809.4,2086.4,803.1z M2096.1,788.4c-1-3.6-4.8-8.7,1.8-9.8c1-0.2,3.1,0.5,3.9,1.2
										C2106.8,783.9,2098.6,797.5,2096.1,788.4z M2112.2,789.8c-1.5-1-4.1-1.1-5.8-0.8c1.4-2.3,2.9-11.5,4.4-11.6
										C2117.2,777,2115.5,791.9,2112.2,789.8z M2119.8,773.8c-3.4,2.3-2.8,3.4-5.6,0.4c-1.2-1.3-2.3-6.2-0.7-7.7
										C2117.9,762.2,2124,769.1,2119.8,773.8z M2117.9,704.7c2.5-2.9,6.9-6.6,7.9,0.2C2126.6,710.5,2114.6,719.7,2117.9,704.7z
										M2123.2,690.8c-6.1-5.7,6.5-10.5,7.1-5.2C2130.6,688.3,2125.7,693.2,2123.2,690.8z M2133.1,672.4c2-2.9,6.1-10.6,7.3-2.4
										C2141.6,678.4,2127,681.1,2133.1,672.4z M2139.5,657.9c-5.6,3.6-5.1-9.2-1.6-11.9C2145.2,640.4,2144.5,654.6,2139.5,657.9z
										M2144.4,613.4c-5.1,0.4-5.1-6.4,0.2-7.2C2150.7,605.3,2150.4,612.9,2144.4,613.4z M2144.9,599.9c-1,2.2-6.8,4.1-8.7,2.3
										C2129.8,596,2147.7,594,2144.9,599.9z M2116.6,607.1c4.5,3.7,2.8,5.6-1.6,8.6C2113.4,613.8,2111.8,603.2,2116.6,607.1z
										M2110.1,622.1c-4.8,6.6-8.3,6.5-6.3-3.2C2106.2,607.6,2113.3,617.7,2110.1,622.1z M2098.8,621c1.1,4.8-0.8,13.4-3.8,4.2
										C2093.7,621.2,2097.2,614.2,2098.8,621z M2094.4,608.8c-0.7,2.9,0.5,4.2-1.9,6.3c-5.5,4.7-6.9-3.7-5.1-8.1
										C2090.1,600.9,2096.1,601.6,2094.4,608.8z M2082.9,621.8c-2.6,2.3-5.2,7.5-7.4,9c-7.6,4.9-9.4-10-2.1-12.8
										C2076.6,616.7,2082,617.8,2082.9,621.8z M2072.6,612.7c-1.2,0.9-2.6,1.3-4,1.4c-1.6-1.9-0.4-9.8,0.8-12.2
										C2073.3,603.6,2073.1,608.6,2072.6,612.7z M2063.3,634.2c-10.1,6.1-4.8-6.3-1-7C2065.2,626.7,2066.7,632.1,2063.3,634.2z
										M2018.9,627.3c-3.9,6.2-11.3-7.5-1.6-7C2021.9,620.6,2019.3,623.5,2018.9,627.3z M2012.2,634.3c0.9,15.2-2.6,1.2-10.4,9
										C1999.9,637.1,2011.5,623.7,2012.2,634.3z M1988.5,631.5c0,5-5.1,12.5-8.9,6C1974.5,628.3,1988.6,620.9,1988.5,631.5z
										M1974.7,647c1.9-2.9,5.7-2,7.7-0.2C1993.6,657,1963.2,664.3,1974.7,647z M1981,681.4c6.2-2.2,10.1,14.4,2.3,14.9
										C1976.4,696.7,1975.1,683.5,1981,681.4z M1976.7,707.5c4.9,10.3-17.6,7.1-6.1-1.2C1973.8,703.9,1972.9,704.9,1976.7,707.5z
										M1969.3,700.5c-2.1,1.7-4.9-6.5-4.5-8.2C1966.6,682.1,1978.1,693.6,1969.3,700.5z M1948.9,707.8c6.6,0,0,17.3-4.4,9.6
										C1943.7,716,1946.8,707.8,1948.9,707.8z M1935.3,706.4c4.5-1.7,7.7,3.7,4.8,6.6c-0.8,0.8-6,0.5-7.5,1
										C1932,711.6,1932.5,707.4,1935.3,706.4z M1936,717.3c5.1-0.5,1.9,9.6-1,10.3C1928.1,729.3,1928.8,718,1936,717.3z M1914.8,695.9
										c-0.9,1.2-7.8-0.8-9.6-0.4C1906.2,688.3,1918,691.7,1914.8,695.9z M1910.1,685.7c-0.8,5.3-8.1,1.9-7.2-3.3
										C1904.4,673.7,1911.1,678.9,1910.1,685.7z M1904.8,672.5c-1.7,5.9-12.8,6.3-11.7-1.6c0.2-1.7,1.1-2.3,2.2-3.4
										C1900,662.7,1906.9,665,1904.8,672.5z M1897.2,660.3c-0.7-3.5-7.5-12.3-3.3-14.9C1899.3,642,1898.6,658.1,1897.2,660.3z
										M1893.4,628.1c-0.1,2.3,1.9,6.2-0.7,7.4c-0.1,0-6.3-3.1-6.2-3C1884.2,627.9,1889.8,623.5,1893.4,628.1z M1884.3,636.7
										c11,0.5,0.1,14.2-1.5,19.2C1881,652.9,1877.8,636.5,1884.3,636.7z M1877.4,624.7c5.1,2.3,3.2,12.5-2,12.5
										C1866.6,637.3,1871,621.9,1877.4,624.7z M1864.3,630.9c-0.6,2.7-0.6,4.5-0.9,7.1c-1.4-0.7-7.4-7-7.4-8.5
										C1856.1,624.6,1865.5,625.5,1864.3,630.9z M1855.7,636.7c1.5,0.3,4.4,5.9,4.1,7.4c-0.9,5.2,0.5,0.7-2.1,1.9
										c-0.3,0.1-6.9-2.3-9.3-1.9C1849.6,642.7,1854,636.4,1855.7,636.7z M1847.4,657.3c-2.7-4.4,3.1-13,7.1-7.8
										C1857.3,653,1850.2,661.8,1847.4,657.3z M1854.9,666.2c1.7-1.5,3.7,2.3,3.9,3.8C1860.2,677.4,1846.4,673.8,1854.9,666.2z
										M1861.7,693.8c-3.5-4.4,4.8-7.2,7.5-4.1C1867.2,691.6,1865.7,698.8,1861.7,693.8z M1872.2,692.4c0.9-1.1,2.6-2.4,4-2.3
										c2.1,0.1,3.7,3.8,3.7,5.4c-0.1,6.4-4.8,1.4-8.9,4.7C1869.5,697.2,1869.7,694.6,1872.2,692.4z M1876.1,703c7-1,6.5,7.6,1.1,9.7
										C1870.2,715.2,1869.9,703.8,1876.1,703z M1877.9,717.5c8.2-4.8,10.1,6.8,3.9,9.1C1877.2,728.4,1874.9,722.6,1877.9,717.5z
										M1881.1,735.7c1.5-5.3,7.1-8.4,7.9-0.9C1889.9,743.5,1879.6,741,1881.1,735.7z M1897.3,738.5c-2.3-2.6-7.7-11.6-0.2-13
										C1905.9,723.9,1898.9,740.3,1897.3,738.5z M1902.1,741.2c2.3-4.9,6.2,0.8,6.3,4.4C1908.5,754.6,1899.4,747,1902.1,741.2z
										M1911.4,757c1.7-3.5,5.4,8.2,5.2,9.9c-0.3,3.5-0.8,6.5-5,3.8C1907.3,768,1909.7,760.6,1911.4,757z M1913.4,779.8
										c6.2-1.6,11.2,12.2,2.8,13.9C1909.5,795,1905.8,781.8,1913.4,779.8z M1904,776c7.6,4.4-6.8,6.9-8.4,5.9
										C1888.7,777.8,1899.8,773.7,1904,776z M1895.3,787.6c2-4,10.7-5.3,7.2,1C1900.3,792.6,1891.6,795.1,1895.3,787.6z M1903.5,805.5
										c1.6-0.9,3.6-1.2,5.3-0.4c4.1,1.9,2.8,8.8-0.4,10.9C1902,819.9,1896.5,809.4,1903.5,805.5z M1907.6,832.4
										c0.6-7.9,9.2-7.3,8.9,0.8C1916.2,842,1906.9,841.6,1907.6,832.4z M1917.1,843.9c3.4-9.3,6.6,2.5,6.2,6.6
										c-0.3,2.8-1.6,10.3-6.1,8.5C1913.7,857.6,1916.2,846.4,1917.1,843.9z M1927.5,859.8c4.1-6.4,6.3,5.5,7.3,7.7
										c0.9,2-1.1,6.1,3.1,5.1c1.8-0.4,1.5-10.4,2-12.4c2.1-7.3,8.1-9.3,6.7,0.5c-0.9,6.6-8.9,8.8-9,16.6
										C1933.7,870.3,1922.4,871.8,1927.5,859.8z M1928.5,907.6c0,0.2-6.7,3.5-7.8,3.8C1912.9,898.8,1929.2,894.9,1928.5,907.6z
										M1902.8,918.4c-0.7,2.2-2,4-4,5.2c-0.6-2.9-12.4-12.9-2.7-16C1900.6,906.2,1904,914.9,1902.8,918.4z M1893,923.5
										c5.1,4.7-2.8,10.8-7.5,8.6C1876.2,927.9,1888.4,913,1893,923.5z M1861.5,943.1c-2,0.4-11.5,4.6-8.2-1.4
										C1857.8,933.5,1865.1,942.3,1861.5,943.1z M1834.3,976.6c0.2,0.2,3.1,1.9,3.2,2.3c-0.3-1-14.6,0.4-16.1-1.4
										c-1.5-1.7-1.1-4.3,0.1-5.9C1825.9,965.9,1831.5,973.9,1834.3,976.6z M1824.9,990.2c-3.5-4.6,0.7-10.7,6.3-6.8
										C1838.5,988.4,1829.8,996.7,1824.9,990.2z M1846.6,998.7c14.1-9.5-5-19.6-5.6-25.1c-0.2-1.6,1.7-11.6,4.2-12
										c3.5-0.5,2.7,2.3,3.2,4.5c1.4,5.8-1.9,7.3,2.6,13.3c0.4,0.6,6.9,7.2,6.9,7.2c4.1-0.3,0.6-2.5,1.7-4.6c2.6-4.9,2.3-8.9,7.8-8.6
										c1.3,0.1,0,4.9,3.6,3.5c1.4-0.5,0.2-2,0.6-2.5c2.1-2.4,3.8-9.4,3.5-12.9c0.7,1,1.7,1.7,2.9,2.1c-0.1,1.3-0.2,2.6-0.3,3.8
										c1.7,1.4,0.6,2.9,3.4,1.2c-0.3,0.2,6.3-11.9,6.3-11.3c-2.1-1.4,7.1,5.5,4.4,5.2c3,0.4,0.9,0.5,3.6-0.6c-6.3,2.7,6.6-8,2.9-5.3
										c3.8-2.8,1.6-2.2,5.1,0.2c3,2.1-1,7.2,4,1.9c1.1-1.2,1.2-5.6,2.8-7.6c1.4,3,5.5,2.6,6.4,4.1c1.3,2.2-1.7,6.3,1.5,7.7
										c1.8,0.8,3.3-6.9,5.1-8.9c0.9,4.4,3.9,2.2,5.2,4.7c0.6,1.2-0.6,4.8,0.6,7.2c-0.7-1.5,10.4,9.9,7.2,9.6c5.4,0.4,1-2.8,3.1-5.7
										c2.3-3.3,4.7-2.6,6.2-6.4c6.1,4,0.4,3.7,4.3,8.7c0.2,0.3,0.2,0.6,0.5,0.8c2.5,1.7,2.4-1.8,3.4-3c2.5-3-1.4-4.4,2.8-5.3
										c0.6-0.1,4.4,1.9,4.6,2c0.4,0.3-1.1,5.3,0.8,5.8c4.4,1.3,1.8-3.7,3.1-5.4c1.4-1.7,3.8-2.8,5.2-5.3c1.3,2.3,3.4,4.3,4.6,6
										c0.8,1.2-0.7,3.1,0.6,4.4c0,0,4.9,1.9,4.8,1.8c2.8,1.5,7.1,3.1,8.5,4.6c2.6,2.8,2.2,6.9,1.9,10c-0.6,6.4,1.1,1.5-3.3,6.8
										c-4.2,5-9.5,5.1-14,9.9c-3.3-7.4-13.1-5.9-14.8,2c-4.4-2.5-2.6-7.4-10.6-7.4c-3.6,0-7.3,0.9-8.7,5c-4-4.2-12,1-16.3,1.4
										c-4.7,0.5-7.2-4-10,5c-1.1-6.2-7.8-6.9-11.2-2.1c-1.9-3.1-6.1-3.8-8-5.3c-4.8-3.9-3.6-4.2-10.1-4.5c-1.5,0.3-2.7,1.2-3.5,2.7
										c-1.6-1.7-3.5-2.7-5.8-3c-4.3,1.4-7.4,4.1-8.3,8.9c-3.6-4.4-5.8,0.2-9,0.9c-2.2,0.4-5.1-0.9-7.7,0.7
										C1849.2,1007.5,1846.1,999,1846.6,998.7z M1848.4,1020c-0.2-12.8,12.6-2,0.7,2.1C1848.9,1021.4,1848.7,1020.7,1848.4,1020z
										M1867.9,1037.8c-5-2.2-0.9-11.8,4-10.1C1881.1,1030.7,1869.5,1036,1867.9,1037.8z M1878.8,1028.4 M1878.9,1037.6
										c0.9-5.5,2.7-7.9,5.2-3.3C1888.2,1042.1,1876.9,1049.3,1878.9,1037.6z M1913.7,1052.5c2-4.5,4.9-5.6,7.6-0.6
										C1925.7,1059.9,1911.9,1067.3,1913.7,1052.5z M1920.7,1044c-1.8-0.7-6.1,1-7.1-0.9c-1.4-2.6,3.4-8.1,5.8-8.3
										c8.3-0.8,2.8,12.6,6.8,10.7c9.5-4.5-4.4-14.7-4.5-16.5c0.1,2.6,8.1-6.7,6.4-6.2c4.1-1.3,4.3,3.1,9.3,1.8
										c-0.5,2.6-0.6,5.3-0.4,7.9c-2.7-1.4-11.4-2.8-7.9,2c2.4,3.2,8.4,0.1,10,6.2c0.4,1.5,0.6,4.3-0.6,5.5c-3.5,3.7-4.1-1.2-6.2-0.5
										c-3.2,1-4.7,2.9-6.6,5.4C1925.1,1049.5,1922.7,1044.8,1920.7,1044z M1928.2,1054.7c1-1.5,1.9-3.4,3.3-4.6
										c2.8,4.1,6.1,5.5,4.1,11.2C1931.8,1071.9,1922.5,1063.4,1928.2,1054.7z M1940.3,1059.2c-1.2-8.2,4.4-10.8,6.3-1.7
										C1949.7,1072.5,1939.8,1075,1940.3,1059.2z M1947.2,1048c-3.8-5.9,1.7-15,5.6-8c2.5,4.3-1.2,11.3,1.8,16.5
										c-1.4,0.1-2.6,0.6-3.7,1.5C1950.2,1054.8,1948.9,1050.7,1947.2,1048z M1951.7,1070.1c-4.4-4,4.6-14.8,6.5-6.4
										C1958.8,1066.7,1955,1073.2,1951.7,1070.1z M1959.7,1056.4c-5.7-3.8-0.4-17.2,5.5-13.3C1969.5,1046,1961.2,1053.9,1959.7,1056.4z
										M1970.7,1048.8c-0.3-4.2,3.2-12.8,8.2-7.4C1984.8,1047.7,1971.3,1057,1970.7,1048.8z M1975.3,1064.9c-0.1-3.2-1.3-12.5,4.9-9.9
										C1988.8,1058.7,1975.6,1073.5,1975.3,1064.9z M1985.5,1041.6c-0.1-6.9,2.4-9.4,5.7-3.1c1.1,2.1,1.8,15-0.2,17.2
										C1984.5,1053,1984.4,1049,1985.5,1041.6z M1988.3,1059.6 M1992.8,1029.2c-2.1,0.9-3.9-1.4-5.6-2.5
										C1990.9,1020.2,1998,1019.6,1992.8,1029.2z M2012,1037c-2.8-2.2-0.6-10.8,3.2-10.3C2017.9,1027,2023.6,1046,2012,1037z
										M2040.3,1031.6c-0.5,0.4-2.2,3.2-3.3,2.9c-2.9-1,0.9-10.5,4.3-9.5C2045.4,1026.1,2042,1030.2,2040.3,1031.6z M2054.2,1018.6
										c-0.6,7.7-15.5-6.1-9.8-12.2C2048.3,1002.2,2054.5,1014.6,2054.2,1018.6z M2045.3,996.6c6.7,3.1-1.2,4.2-2.1,5.2
										c-0.9,1-4.3,6.7-5,6.9C2030.3,1009.9,2035.2,992.1,2045.3,996.6z M1988.4,860.8c1.2-0.5,1.4,8-1,9.1
										C1983.9,871.7,1986.1,861.7,1988.4,860.8z M2005.9,874.6 M2016.7,881.6c-2.4,3.9-14.3-1-5.9-5.4
										C2013.7,874.7,2019.5,877.1,2016.7,881.6z M2011.6,869.7c-2.1,1-4,3-5.7,4.6c-2.1-0.4,0.5-8.6,2.1-9.7
										C2012.6,861.2,2018.4,866.5,2011.6,869.7z M1999.7,877.4c1.6,0,1.2,10.9-4.9,8.8C1989.7,884.6,1994.5,876.2,1999.7,877.4z
										M2001.7,864c4.1,3.6-8.8,14.4-8.8,5C1992.9,865,1998,860.8,2001.7,864z M2043.8,882.6c6.3,0,3.1,5.6,3.9,8.3
										C2043.5,890.7,2037.5,882.6,2043.8,882.6z M2028.1,881.3c-6.8,7.1-6.5-7.2-1.9-6.9C2028,874.6,2028.2,880.8,2028.1,881.3z
										M2037,880.7c-1.5,1.7-2,1.6-3.3,0C2027.9,873.5,2044.2,872.8,2037,880.7z M2030.8,873.7c-0.9-0.9-0.6-1.4,0.9-1.5
										C2031.4,872.8,2031.1,873.2,2030.8,873.7z M2031.5,866c0.3,2.1-9.5,4.6-11.8,7.1C2013.8,868.1,2030.3,856.7,2031.5,866z
										M2100.4,980.6c-0.7-3.2,4-4.5,5.4-1.5C2107.7,983.3,2101,983.2,2100.4,980.6z M2106.1,966.6 M2017.7,832.9
										c1.2-2,6.1-1.8,7.5,0.1C2031.3,841.1,2013.2,840.2,2017.7,832.9z M2008,625.4 M1965.6,627.7c-3.6,3-1.1-3.9,0.7-5.4
										C1969.6,619.7,1967.8,625.9,1965.6,627.7z M1950.3,653.8c-2.4-1.5,0.9-3.6,1.8-4.5C1955.1,646,1957.1,658.2,1950.3,653.8z
										M1899.8,624.7c2.9-1.2,6.6,1.3,3.2,3.7C1899.1,631.1,1895,626.7,1899.8,624.7z M1915.8,626.1c0.4,3.3-8.2,5.4-7.2,0.5
										C1909,624.9,1915.1,623.5,1915.8,626.1z M1925.1,626.6c-0.6,3.3-3.1,2-4.2,0.7C1918.6,624.3,1926,622.2,1925.1,626.6z
										M1828,1006.2 M2075,1023.9c2.7-0.7,2.6,1.2,3.9,2.4C2082,1029.3,2068.8,1025.6,2075,1023.9z M2088.2,1019.8
										c3.1,6.4-9.8,2.1-2.9-0.2C2086.3,1019.2,2087.2,1017.8,2088.2,1019.8z M2121,1003c-9.4-0.9,4.9-5.2,1.9-0.7
										C2122.8,1002.6,2121.4,1002.8,2121,1003z M2106.4,1011c5.2,8.1-14.2,2.2-4-1.5C2104.9,1008.7,2104.9,1008.7,2106.4,1011z
										M2115,1005.9c-0.3,3.4-7,3.6-5.6,1.1C2110.3,1005.4,2115.5,1000.9,2115,1005.9z M2095.2,1015.5c1.7-0.6,2.4,3.2,2.5,3.6
										C2098,1024.8,2088.1,1017.8,2095.2,1015.5z M2102.2,1020.1 M2152.2,930.7"
							/>
							<path
								d="M1957.3,758.4c-1.9,4.7,5.8,14.5,7,6.9c0.5-3.5-6.4-4-0.7-7.9c2.8-1.9,5.7-1.7,6.6,2.4c1.1,4.9-8.5,7,0.3,7.2
										c2,0.1,2.3-3.5,3.4-3.9c1.6-0.6,4.9,1.4,7.3,0.3c1.3-0.6,9.3-8.1,10.1-9.4c2.4-3.9,2.2-5.6-0.5-9.3c-1.2-0.5-2.3-1-3.5-1.5
										c-1.9,0.8-2.7-0.1-2.4-2.6c-9.2-6-10.6-3.7-19,2.8C1965.6,743.3,1945.3,755.4,1957.3,758.4z M1964.2,748.4
										c2.5-0.2,1.8,0.8,3.9,3.1c-3.3,0.4-6,2.6-9.2,3C1956.9,751.7,1962.1,748.6,1964.2,748.4z M1981.7,744c-0.3,4.7-11.5,9.7-12.1,1.9
										C1969.2,740.4,1982.1,737,1981.7,744z M1977.1,759.9c-5.9-0.5-2-9.3,2.1-7.9C1983,753.4,1981.3,760.3,1977.1,759.9z
										M1986.4,747.4c2.3-2.1,2.8,4.8-0.3,6.2C1985,750.9,1983.8,749.7,1986.4,747.4z"
							/>
							<path
								d="M1929,652.8c-2.9-0.6-3.7,1.3-5.9,2.3c-1.1,0.8-2.1,1.8-2.9,2.9c-1,1.9-2.8,2.3-5.2,1c-4.7,4.2-3.8,7.6,1.1,10.5
										C1928.7,676.8,1942.9,655.5,1929,652.8z M1927.9,665.8c-5.3,4.7-7.8-3.1-3.4-6.7C1930.9,653.9,1932.4,661.8,1927.9,665.8z
										M1917.4,664c-0.2,0.6-0.3,1.2-0.5,1.8C1914.1,665.4,1917.4,660.5,1917.4,664z"
							/>
							<path
								d="M1912.6,554.8c6.5,0,10.7-7.7,3.8-11.6C1907.8,538.4,1903,554.8,1912.6,554.8z M1915,547.2c3.3,4.6-3.4,2.4-3.9,2.8
										C1911.8,549.4,1912.4,543.6,1915,547.2z"
							/>
							<path
								d="M2120.3,562.3c-5.2-5-12.7-1.7-15,4.9c3.1-9.7-13.8-10.2-15.1-1.2c-1.3-4-6.7-7.1-10.8-6.6c-3.8,0.5-6.3,5.5-8.1,5.2
										c4.8-9.8-6.8-10.9-12.2-6.7c-1.4,1.1-1.8,4.1-2.8,4.8c-0.7,0.6-7,2.9-8.2,3.4c-4.8,1.9-13.3,8.7-18.8,6.9
										c-0.8-0.3-2.8-4.4-3.2-4.4c-4.6-0.7-7.9-1.4-9.9,4c-0.9-0.9-3.8-2.4-4.2-2.7c0.2,0.2-3.7-6-4.3-6.7c-2.5-3-3.4-6.5-8.4-5.9
										c-1,0.3-1.9,0.7-2.9,1c-1.4,3-3.3,3.6-5.7,1.7c-5.5,2-10.9,3.2-16.2,2.2c-5.3-1-11.5-4.7-16.5,3.1c-1.1-1.2-4.1-2.9-6-2.5
										c-2.7,0.1-5,1.1-7,3.1c-1.1-1.3-2.5-2-4.2-2.2c-3.4,0.4-5.7-0.8-8,2.8c-0.6,0.9-1.2,6.8-0.5,6.4c-4.2-11.4-11.5,2.1-14.6,2.2
										c0-4.1-5.1-6.3-8.6-5.4c-2.8,0.7-2.9,2.5-4.9,3.9c0,1.1-0.1,2.1-0.1,3.2c-1.4,0.2-2.7,0.4-4.1,0.5c-3,3.2-7.1,4.9-3.7,10.1
										c0.2,0.3,8.2,7.3,7.2,7.2c2.7,0.1,1.8-2.5,2.7-2.9c0.6-0.2,1.8-3.2,3.8-3.1c0.6,0,3.1,2.5,2.5,2.5c-0.2,0,1.1,2.1,2.2,2.1
										c1.8,0,1.1-1.7,1.7-2c-0.8,0.3,5.5-7.1,5.5-7.1c0.6,1.9,0.5,12.6,4.6,7.8c2.9-3.4-2.6-12.1,3.3-5.9c1.6,1.7,2.5,7,5.5,5.5
										c3.8-1.8-3.6-9.2,3.4-5.1c3.2,1.8,6,14.1,6.6,1.9c0.1-1.5,1.4-2,0.8-3.4c-0.7-1.4-4.8-1.3-4.9-1.7c-0.6-2.6,2.8-5.7,5.2-5.3
										c-0.5,1.2-0.8,3.7,0,5c0.3,0.4,5.6,7.3,5.1,7.1c4.5,2.2,2.6-2.1,6.3-4.2c2.5-1.4,4.3-0.8,7.4,1.3c-0.1-0.1-0.1,5.2,1,5.2
										c6.2-0.4,0-8.4,7.1-5.4c3.5,1.5,2,7.3,6.4,5c4.7-2.5-3.1-6.6,4.9-5.5c3.3,0.5,1.6,4,5,3.5c-0.6,0.1,6.8-7.9,6.3-7
										c-0.1-0.2,5.2,4.9,2.7,5c1.4-0.1,1.2,1.6,3.4,0.8c1.8-0.7-1.1-4.1,0.9-5.1c1.9-0.9,4.9,1.8,5.2,1.9c0.8,0.1,0.2,2.4,2,2.1
										c1.5-0.2,1.4-2.1,2-2.3c2.6-0.8,1.5-5.5,7.2-2c2,1.2,0.8,5.4,4,5.3c3.6-0.1-1.2-6.3,4.1-5.9c4.1,0.3,2.4,5.2,5.7,5.6
										c1.5,0.2,2.7-6.7,9-4c4.9,2.1-1.1,4.3,4.3,3c0.8-0.2,0-4.3,3.4-4.2c0.5,0,2.5,1.8,3.4,2c1.9,0.4-0.5,4,3.9,2.5
										c0.6-0.2,1.7-7.1,4.8-6.8c0,0,10.4,14.7,7.1,2.9c6.2,0.4-1.2-4.5-1-4.3c-1.4-2.9-3.3-0.9-3.2-5.5c0.1-2.4,0.5-6.7,5-5.5
										c5.4,1.5,1.1,6.7,2.3,11.5c-0.7-2.8,9.7,10.8,4.5,7.3c2.2,1.5,1.6,0.7,3.6-0.1c1.3-0.5-0.7-4.3,3.1-5.8c3.3-1.3-0.9-1,3.7,1.2
										c2.6,1.2,2.2,5,4.7,5.1c2.7,0.1,3-5.4,7.4-5.1c2.7,0.2,0.3,4.7,2.7,5c4.9,0.6,0.6-3.7,2.2-4.8c3-2.1,3-3.1,5.8-2
										c2.7,1,0.8,5.3,2.1,5.6c5.7,1.1,1.2-4.9,6.1-5.8c5.7-1,2.5,6.1,8.1,5.2c1.8-0.3-0.5-7.4,6.4-5.3c4,1.2,1.9,5,6.2,2.9
										c-0.8,0.4,4.3-8.9,4.2-8.1c0.5-2.7-1-3.8-2.1-5.9c-1.1-1.2-2.5-1.7-4.2-1.6c0-0.8,0-1.7,0-2.5
										C2132,560.4,2127.4,553.3,2120.3,562.3z M2129.4,562.2c5.8,1.9,3.5,5.9-1.2,5.3C2118.9,566.4,2125.2,560.9,2129.4,562.2z
										M2138.3,572c9,4-13.2,6.1-4.8,0.4C2134.7,571.6,2136.9,571.5,2138.3,572z M2120.5,572.9c3.1-6.2,12.9,4.6,3.9,5.1
										C2120.3,578.2,2122.2,575.9,2120.5,572.9z M2113.6,578.2c-2.9-0.3-2.7-3.3-1.1-4.5C2116.3,570.8,2119,577.6,2113.6,578.2z
										M2116.4,569.1c-0.8-0.4-6.2,0.1-7.1-1c-0.3-0.4,0.5-0.7,0.6-1C2111.5,559.7,2123.7,567.2,2116.4,569.1z M2099.2,572.9
										c3.4-4.5,7.5-0.1,6.3,4.1C2103.1,585,2099.3,573.3,2099.2,572.9z M2088.4,577.5c-3.2-1.3,0.3-6.6,2.7-6.6c4.7,0,2.2,6.5,4,8.7
										C2092.2,581.3,2090.2,578.3,2088.4,577.5z M2094.2,567.5c-0.2-3.7,7.3-5.9,7.3-2.4C2101.5,569.5,2096.5,568.4,2094.2,567.5z
										M2079.6,578.5c-0.7,0.2-1.5,0-2.2-0.4c1.5-1.7,2-3.7,1.6-5.9C2083.6,572,2083.6,577.8,2079.6,578.5z M2079.8,567.6
										c-6.1,0.5-2.4-4.3,1.9-3.9C2090.8,564.4,2083.5,567.3,2079.8,567.6z M2105.3,567.3 M2030.2,579.4c0.6-2.6,3.7-5,6.5-2.3
										C2040.1,580.5,2032,582.2,2030.2,579.4z M2056.6,568.3c-0.8,5.3-9,0.4-4-0.7C2053.4,567.5,2057.1,565.1,2056.6,568.3z
										M2053.1,576.2c1.8-0.9,4.8-4,6.6-3.8c-0.7,2.8,1.3,5.2-1.8,7C2055.9,580.5,2051.5,579.8,2053.1,576.2z M2061.5,566.1
										c-2.4-1.7,0.8-8.4,3.7-7.7C2072.5,560.1,2063.8,564.9,2061.5,566.1z M2040.9,575.1c1-6.1,8.6-3.4,7.6,1.7
										C2046.8,584.9,2042.8,579,2040.9,575.1z M2026,579.2c-3.2,4.1-10.6-2.6-4.8-6.9C2022.9,575.5,2025.3,573.2,2026,579.2z
										M2009.5,579.9c-0.7,0.1-1.3-0.1-2-0.4C2012.1,569.3,2014.7,579.9,2009.5,579.9z M2006.2,569.5 M1998.9,572.3
										c5.4-4.2,4.5,7,3.3,7.6C1998.3,581.8,1995.4,575,1998.9,572.3z M2003.4,566.5c-0.5,0.2-7,0.8-6.9,0.9
										C1996.8,558.2,2006.2,562.7,2003.4,566.5z M1992.1,568.9c0.9,1.5-5.2,0.8-5.9,1.4c-0.1-0.2-0.1-3.3,0-3.5
										C1989.7,558.9,1991.3,567.5,1992.1,568.9z M1986,578.8c0.6-2,2.6-5.3,5.1-4.8C1998.6,575.8,1986.6,591,1986,578.8z M1983.6,573.7
										M1981.7,581c-1.2,2.5-3.4,2.6-5.1,2c-3.6-1.2-1.2-4.8-1.7-7.5C1979.7,573.8,1981.4,577.4,1981.7,581z M1981.9,567.9
										c-0.2,2.3-6,3.7-8.1,2.3C1975.5,567.1,1978.9,562.1,1981.9,567.9z M1962.1,576c0.2-5.7,6.6-0.8,7.3,1.4
										C1971.9,585.1,1961.9,585.1,1962.1,576z M1970.1,567.2c-2.1,2.6-6.1,1.8-9.7,2.6C1962,561.1,1970.6,566.6,1970.1,567.2z
										M1947.4,574.3c-3.2-1.5,2.4-7.5,5.1-7C1959.8,568.5,1950.9,576,1947.4,574.3z M1950.7,579c1.2-1.8,3.4-2.7,5.3-1.7
										C1964.8,582.1,1946,586,1950.7,579z M1928.8,578c-0.6,3-6.3,2.7-6.4-0.8C1922.2,572.7,1930.3,571.1,1928.8,578z M1911,578.3
										c-4.7-1.1-2-6.3,1.2-4.5C1914.7,575.2,1913.6,577.6,1911,578.3z M1899.4,583.5c0.5-3.3,7.4-3.1,8.3-0.7
										C1909.6,587.5,1898.5,589.1,1899.4,583.5z M1913.3,582.4c2.2-4,6.8-4.5,4.4,1.1C1916.8,585.8,1910.1,588.4,1913.3,582.4z
										M1936.1,579.6c-1.9,5.4-4.8,0.2-3.3-0.9C1934.6,577.4,1938,574.2,1936.1,579.6z M1939.3,567.4c0.9,0.1,2.1,0.4,2.7,1.1
										c1.9,1.8,1,4.3-1.3,4.7C1935.6,573.9,1934.8,566.9,1939.3,567.4z"
							/>
							<path
								d="M2176.6,560.8c-1.6-1.1-5.4-1-7.3-1c-1.7,0-3.1,0.6-4.1,1.9c-1.2,1.9-2.5,1.7-4.1-0.4c-4.5,0.9-10.7,1.4-9.8,8.4
										c0.2,1.9,4.9,7.5,6,8.2c1.1,0.6-1,2.5,2.2,2.8c2.3,0.2,1.3-2.9,1.7-3.1c0,0-0.2-1.9-0.2-1.9c26.5-1.2-16.5-0.6,4.7,0.4
										c2,0.1,0.4,3.6,2.6,3.8c2,0.2,1.8-1.9,2.4-2.1C2173.3,576.6,2181.5,564.2,2176.6,560.8z M2162.5,567.6c2.5,4.5-6.1,5.9-7.1,2.3
										C2154,565,2160.2,563.5,2162.5,567.6z M2167.8,569.5c0.4-0.2,4-0.5,4.1,0.7C2172,571.4,2166.8,575.4,2167.8,569.5z M2171.7,565.4
										c-1.5-0.2-3-0.4-4.5-0.6c1.1-1.5,8.3-0.9,7.5,0.3C2174.2,565.7,2172.3,565.3,2171.7,565.4z"
							/>
							<path
								d="M2053,470c-0.5,0.3-6-4.9-10.9-2.5c-2.2,1.1-3.2,3.6-5.2,4.6c-1.6-1.5-3.2-1.8-4.7-0.9c-1.3,0.4-2.3,1.2-2.9,2.6
										c-2.3,1.6-3.7,6.3-5.5,8.1c-3,2.9-8.6,4.6-11.2,7.4c-4.3,4.6-4.8,8.6-9.9,12.4c-6.9,5.1-7.1,5.5-4.5,13.7c0.3,1,1.2,6.9,2.5,7.7
										c3.8,2.4,4.8,1.1,3.6-5.2c0.7,1,5.4,4,5.3,3.6c0.5,0.9-0.6,2.6-0.1,3.4c0.9,1.5,3.6,11.3,4.1,1.2c1,2.6,3.5,3.1,4.6,5
										c0.5,0.8-0.4,4.3,0.4,5c5.3,4.5,1.7-1.3,3.7-3.8c0.5-0.6,2.4-2.6,3.5-2.9c4.9-1.1,1.4,3.1,4.4,4c4.4,1.3,2.9-8,9.5-7.4
										c1.4,13.3,3.6-2.2,6.9-2.4c6.6-0.6,1,6.7,7.3,5.1c2.3-0.6-1.6-5.8,4.8-4.7c4.9,0.8,2.5,6.2,7.3,5.1c1.2-0.3-0.4-6.4,4.3-5.8
										c9.7,1.3-0.5,3.6,3.8,5.8c3.9,2,0.9-0.3,3.9-0.6c-1.2,0.1,0.2-3,1.1-3.3c5.4-1.9,4.3,9.5,5,10.7c3.8,6.7,3.5-4.9,4.2-5.7
										c2.5-2.5,1.3-1.5,4.6,1c8.3,6.3,1.2-0.8,3.3,2.5c1,1.6-1.3,4.2,0.9,5.6c3.9,2.5,2.9-7.7,4.4-8.4c6.6-3.3,3.6,6.3,4.6,8.4
										c3.2,6.4,4.2-2.7,4.1-5.7c9.3,0.3,3.8,11.7,10.7,6.6c-2,1.5,4.9-8.8,2.8-4c1.2-2.8,2-4.2,2.3-7.5c-0.1-1.6-0.8-3-2.2-4
										c0.5-1.2,1-2.3,1.5-3.5c-0.5-3.8,0.5-7.9-4-9.9c5.5-1.6,6.1-9.4,1.9-12.5c-2.9-2.2-5.4,0.3-7.8-0.7c0.9,0.4-6.6-7.1-6.2-6.6
										c-0.9-0.2-1.9-0.4-2.8-0.6c0.1-1.2-0.1-2.5-0.5-3.6c-1.5-1.8-2.2-3.9-4-5.7c-2.5-2.4-9.3-6.4-13.2-4.4c0.9-5.7-4-13.3-9.2-6.5
										c-2.8-7.7-9.6-2.3-13,1.9C2063.6,465.4,2058.1,467.1,2053,470z M2057,472.3c7.4-4.8,7.7,9.7-0.3,8.7
										C2051.1,480.3,2053.1,474.8,2057,472.3z M2070.5,474c3.8-3.7,2.8-4.3,5.5-1.4c4.9,5.3-10,7.5-8.6,8.9
										C2065.6,479.8,2069.5,474.9,2070.5,474z M2110.4,496.8c4.3,4.6-5.4,11.5-8.6,6.4C2099,498.9,2105.9,492.1,2110.4,496.8z
										M2118.7,533.1c-4,0.6-5-4.3-0.9-5.7C2122.8,525.8,2124,532.3,2118.7,533.1z M2120.7,522.4c-3.4,3.9-7.6-3-4-6.2
										C2120.9,512.4,2123.4,519.1,2120.7,522.4z M2121.6,506.2c-1.9,2.6-5.8,1.2-6.4-1.5C2114,499.2,2125.5,501,2121.6,506.2z
										M2084.4,522.8c-1.2-5.4,4.6-9.6,7.7-4.4C2095.8,524.4,2085.2,526.5,2084.4,522.8z M2075.5,521.6c-8.8-0.7,6.1-12.7,4.2-3.9
										C2079.2,520.5,2078.1,521.3,2075.5,521.6z M2090.1,505.4c1.6-2.2,9.7-9.6,7.4-1.2c-0.3,0.9-8.6,5.5-9.3,4.8
										C2088.3,507.7,2088.9,506.5,2090.1,505.4z M2095.3,525.7c0.9-1,6.4-5.6,5.6-0.5C2100.5,528.3,2094.2,529.1,2095.3,525.7z
										M2101.7,515.8c-3.1,2.4-10.3-0.4-7.1-4.5C2097.5,507.5,2105.9,512.5,2101.7,515.8z M2106.2,519.4c1.3-4.4,5,2.2,5.4,3.7
										C2113.7,531.9,2103.2,529.4,2106.2,519.4z M2111.6,513.8c-3.9,0-5.5-5.3-1.1-6C2113.8,507.2,2114.5,513.7,2111.6,513.8z
										M2102.2,492.1c-0.7,1.4-2.6,2.8-3.8,4.1C2089.4,483.9,2105.9,483.4,2102.2,492.1z M2094.1,480.9c2,1.6-1.9,5.8-3.9,5
										C2085.7,484.3,2090.8,478.2,2094.1,480.9z M2089.6,490.6c1.4,2.1-0.4,8.9-3.1,9.5C2078.9,502,2081.7,485.4,2089.6,490.6z
										M2084.7,476.4c-1,4.2-0.3,8.2-4.2,10.9c0.2-3.1-2-11.2,1.8-13.5C2084,472.8,2086,471.1,2084.7,476.4z M2083.2,503.5
										c7.9,7.3-8.7,8.3-9.9,5.9C2072.2,507.5,2080.7,501.2,2083.2,503.5z M2075.4,499.5c-3.1,3.1-8.4-7.8-6.5-11.6
										C2074.3,476.7,2080,494.9,2075.4,499.5z M2067.9,499.8c3.9,4.9-1.6,10.5-5.4,5.9C2060.6,503.5,2065.7,499.2,2067.9,499.8z
										M2065.6,520.4c-3.1,0.6-8.8,0-5.9-5.7C2063.3,507.6,2076.9,518.3,2065.6,520.4z M2054.6,519.7c-1.8,1.6-3.9,1.6-4.4-1.7
										c-0.3-1.9,3.5-6.2,5.2-3.3C2056.8,517.1,2053.2,517.6,2054.6,519.7z M2039.1,520.8c-3.7-2,2.6-9.4,4.8-4.3
										C2045.7,520.6,2042,522.4,2039.1,520.8z M2034,524.3c-3.5,3.4-7.3-1.3-3.5-4.8C2033,517.2,2036.4,521.9,2034,524.3z
										M2013.2,494.4c2.1-5.5,6.6-0.9,6.3,2.8C2019,503.1,2011.2,499.4,2013.2,494.4z M2032.2,476.9c0.3-0.5,0.8-1.9,1.5-2
										c1.6-0.2,3,8.1,1.8,9.1c-1.5,1.2-2.6-0.2-3.6-1.4C2029.5,479.6,2030.2,480.7,2032.2,476.9z M2045.9,469.8c6.4-1-1.9,15.1-6,13.2
										C2039.6,478.2,2039.4,470.9,2045.9,469.8z M2062.9,494.8c-5.9,7.9-11.9-11.7-2.3-11.2C2065,483.7,2064.8,492.2,2062.9,494.8z
										M2028,499.5c2.4-6.2,8.2-0.8,6.8,3.2C2032.3,509.5,2025.5,502.9,2028,499.5z M2035.5,510c2-1.7,3.4-11.1,8-7.4
										C2050.4,508.1,2032.2,512.9,2035.5,510z M2044.9,498.6c-2.9-1.5,1-2.9,2.8-1.8C2047.1,498.3,2046.2,498.8,2044.9,498.6z
										M2051,483.1c3.9,9.6-8.9,10.2-5.8,2.2C2046.3,482.5,2049.4,480.8,2051,483.1z M2041.3,491.7c0.4,2.7-1.2,4.8-3.1,4.7
										C2029.9,496.3,2039.8,482.2,2041.3,491.7z M2028.5,488.9c-0.2,2.6-3,5.5-4.9,7.3c0.4-3.8-3.4-7.3-0.9-9.6
										C2024.9,484.6,2028.7,485.9,2028.5,488.9z M2013.6,504.1c0.4,6.6-2.5,2.1-3.2,2.3c0.5-0.1-3.1-3.9-1.5-4.8
										C2010.9,500.4,2011.1,505,2013.6,504.1z M2010,513.3 M2017.2,519.1c2.2-6.5,7.4-2,7,2.4C2023.3,531.6,2014.7,526.3,2017.2,519.1z
										M2022.5,510.6c-7.1,0.9-5.7-6.7-0.7-8.1C2027.1,501,2023.6,509.3,2022.5,510.6z M2027.8,512.7c4-2.9,3.7,2.3,3.8,2.3
										C2032.2,515.4,2024.2,515.2,2027.8,512.7z M2055.5,505.9c-0.4,1-8.2,7.4-8.8,5.7c-0.3-0.7,2.1-8.9,2.8-9.8
										C2053.5,496.5,2059.2,499,2055.5,505.9z M2003,513.3c-1.6-2.5-5.6-1-2.4-6.2c1.1-1.9,4.2-2.6,5.2-0.3
										C2006.7,508.7,2005.3,515,2003,513.3z"
							/>
							<path
								d="M2071,457.1c1.1,8.2,4.4-0.9,7.3-1.2c1.2-0.1,1.9,2.1,1.6,2.2c0.1,1.2,0.8,1.9,2,2c1.2-0.1,1.9-0.8,2-2
										c0.9,0,1.7-1.7,4-1.5c2.7,0.1,1.5,2.8,2.3,3c2.4,0.6,3.5-1.5,3.8-1.5c-0.2,0,6.9,0,5.3,0.2c-0.3,0,0.7,2.6,2.1,2.5
										c2.1-0.2,1.7-2.4,2.7-3.4c2.6-2.6,5.1-6.4,2.8-10.1c-3.4-5.3-7.1-0.5-10.2-0.5c-3,0-6-1.2-9.5-0.8c-1-0.3-1.9-0.7-2.9-1
										c-2.4,0.1-4.4,1.2-5.9,3.1c-3,0.4-6.4-4.4-10.1-0.6C2065.3,450.5,2067.2,455.8,2071,457.1z M2075.9,451.8c-0.2,4.2-4.1,0.1-5,0
										C2070,451.8,2076.1,446,2075.9,451.8z M2082.7,449.3c3.6-2.1-0.5,3.3-1.2,4.2C2079.4,452,2082.1,450.3,2082.7,449.3z
										M2093.7,449.5c2.3,2-1.8,6.3-4.2,3.2C2087.3,449.9,2090.6,446.8,2093.7,449.5z M2090.5,457.1 M2103.7,450.3
										c0.2,3.9-3.3,3.6-4.7,2.9C2096.2,451.8,2103.3,444.5,2103.7,450.3z"
							/>
							<path
								d="M1811.8,518.6c3.7,1.5-2.1,11,2.9,11c4.5,0-0.2-9.2,4.1-10.3c-4.4,9.7,0.3,10.2,4,17c-0.2-0.3,2.8,13.5,3.7,4.4
										c1.1,1.3,2.2,2.1,3.3,3.2c0.6,0.7-0.7,3.8-0.1,4.5c5.2,4.6,2.5-2.6,5.6-4.9c5.9-4.4,7.4,2.4,9.4,6.5c0.9,1.2,2.1,2,3.7,2.3
										c-0.9,1.3-1.3,2.7-1.3,4.3c1,3.5,0.3,6.2,4.7,7.9c-3,3-0.9,6.4-2.7,9c-0.8,1.1-4,1.5-5.3,3.6c-3.4,5.3,0.2,9,3.3,12.8
										c2.6,3.2-0.8,4.5,4.3,3.9c0.9-0.1-0.4-4.1-0.2-4.3c1.9-2,3.8-0.5,5.5-3.3c1.2,1.3,2.7,9.8,4.8,8.7c3.5-1.7-0.3-13.8,8.5-6.4
										c3.6,3.1-2.3,8.4,4.2,3.5c0.2-1.4,0.1-2.8-0.2-4.2c1-0.5,2.1-0.7,3.2-0.8c1.8-2.8,7.2-1.5,3.6-7.6c2.4,0.3,4.1-8.2,5.5-10.8
										c-0.1-1-0.2-1.9-0.4-2.9c1.3-0.7,2.5-1.4,3.8-2.2c1.7-2.3,4.3-1.8,4.3-5.5c0.1-9-9.8-4.3-13.2-1c-1.7,1.7-1.1,3.2-3,5
										c-1.5,1.4-3.4,1.6-4.3,3.8c-3.5-5.1-7.6-2.4-10.5,1.1c-0.1-1.6-0.8-2.8-2-3.7c2.9-3.5,6.2-1.8,7.3-7.6c0.9-4.4-1.6-7.4-4.1-10.5
										c-3.4,0.3-5-1-4.7-4c1.3-0.7,2.3-1.7,3-3c1.9-6.6,0.5-6.3-2.1-11.6c-0.8-1.6-7.7-5.4,0-6.4c2.6-0.3,11.3,11.8,8.9,2.3
										c-1.2-5-10-5.6-4.2-11.4c1.5-1.6,8.2-3.2,9.6-0.3c0.8,1.7-4.2,5.3-4.3,7.8c0,0.5,7.8,15.1,7.9,15.1c2.9,0.4,1.1,0,3.3-1.5
										c-0.6,0.4,4.3-8.7,4.3-8.7c-0.7-0.9,4.8,6,2.9,4.8c1.5,1-1.5,4,1.6,4.6c2.6,0.5,2.1-7.3,7.1-8.2c5.6-1,3.8,5.5,7.9,4.6
										c1.9-0.4,0.2-4.2,0.7-4.9c2.4-3.3,5.1-2.3,5.4-7.4c2.3,0.6-0.3,6.4,4.2,5.2c2.7-0.7,0.1-10.8,6.5-6.2c3.4,2.5,0.2,8.3,5.1,7.2
										c2.1-0.4-0.2-4.2,3.2-4.9c-0.7,0.1,6,5.7,7.1,6c-0.1,3.8-7,5.8-5.1,12.7c0.6,2.1,3.4,4.7,4.3,6.1c0.9,1.5-1.3,4.5,0.9,5.8
										c2.2,1.2,3.8-6.5,6.2-8.2c6.2-4.4,2.6,2.3,6.1,1.8c3.7-0.5,0.9-2.7,3.3-4.3c8.3-5.9,3.3-0.1,6.7,4.9c3.5,5.1,3.5-4.8,3.5-4.9
										c2.3-3.3,2.9-3.6,7.3-1.1c2.4,1.3-1.5,5.7,3.5,4.3c2.2-0.6-1.3-7.3,3.6-7.2c4.4,0.1,2,6.6,6,6.6c2.5,0,0-6.3,4.5-5
										c3.1,0.9,0.4,5.8,4.5,5.7c1.3,0,4.9-9.5,4.6-10.5c-0.9-3.3-5.6-4.2-7.7-6.3c-2.9-3-1.6-4.1-2.8-6.5c-1.8-3.4-0.3-6.4-5.2-8.4
										c5.3-2.4,6.7-8.3,4.9-13.5c4.4,0.8,7.8-6.7,8.4-9.9c4.9,12.2,4.1-6.5,9-7.5c3.7-0.8,1.3,5.2,6,2.4c1-0.6-0.9-3.8,0.2-5.1
										c2.6-3.2,0.8-1,4.3-2.2c1-0.3,1.1,2.6,4.1,0c1.6-1.4,3.2-5.2,2.2-7.7c-2.1-5.5-8-3-11.9-2.2c-3,0.7-5,0.7-7.7,2.7
										c-2.4,1.9-0.9,5-4.2,5.2c2.4-11.2-13.9-15.6-18-5.3c-3.5-5.1-16.4-19-20.3-4.6c-4.2-0.9-8.7-16.7-16.4-4.4
										c-3-9.5-11.9-12.6-15.8-1.4c-0.6-2.9-2.5-4.4-4.8-5.9c0.1,0.1-7.5-2.2-8.6-2.7c-2.6,1.4-4.3,0.9-5-1.4c-0.9-1.8-2.2-3.2-4.1-4.1
										c5.5,5.4-11.8-7.9-4.8-4c-2-1.1-7.3-1.8-8.4-2.6c-1.9-1.4-1.9-5.4-3.8-7c-5-4.1-10.1-2.1-14,2.7c-0.2-2.1-1.1-3.8-2.8-5.1
										c6.1-2.8,4.3-11.7-0.9-14.3c-1.8-0.9-4.2,0-5.7-0.7c-1.8,1.6-2.9,1.4-3.2-0.7c-0.6-1.9-1.9-3.1-4-3.6c7.7-6.1-2.4-14.8-9.4-12.3
										c0-3.8-1.4-11.4-5.2-13.3c-6.8-3.4-5.4,3.1-8.8,5.9c0.7-10.8-15.2-4.7-11.3,5.3c-5.1-6.4-13.3,0.1-15,5.6c-3-5.1-9.8-3-12.7,0.7
										c-2.7,3.3-0.8,6.4-2.6,9.4c-0.7,1.3-3.3,1.7-4,3.5c-1,2.6-0.8,5.2-1.5,7.5c-0.2,0.6-1.4,3.3-1.7,4.6c-0.6,3-1.3,5-1.2,8.1
										c2.6,1.2,3.2,2.6,1.6,4.3c-1,1.4-1.5,3-1.6,4.8c-0.3,4.8,3.4,7.3,4.5,10.4c0.7,2.2,0.9,5.6,1.7,8c0-0.1,2.1,2.5,2.1,2.5
										c0.7,2.2-1.2,6.3,1.7,7.1c5.1,1.4-1.3-10.1,6.7-7.8c-1.6,2.4-2.9,7.2-2.9,10.1c-0.1,3.5,3,6.2,3,8.2c0.1,4.3-2,5.6-0.5,10.3
										c0.3,1,2.6,4.6,2.9,5.1c0.4,0.6-0.4,4.1,0.1,4.4c4.3,2.5,1.8-0.9,4-3.5c2-2.4-0.5-8,6.2-7.6c-0.5,2.4-3.3,2.8-4.6,5.3
										c-2,4-1.4,6.6-0.5,10.6c0.5,1.5,1.5,2.6,3.1,3.1c-0.4,0.7-0.8,1.4-1.2,2.1C1809.7,513,1805.4,516,1811.8,518.6z M1805,488.1
										c-1.9-1.1-6.8-4.2-2-7C1808.6,477.9,1808.3,485.4,1805,488.1z M1795.2,458.1c-3.3,2.8-4.7-5-3.4-7
										C1795.3,446,1795.2,458.5,1795.2,458.1z M1788,437.5c-0.5,0.6,5.5,6.6,0.4,8.5C1786,444.2,1786.2,439.4,1788,437.5z
										M1811.5,396.7c4-6.7,11.2,1.1,5.8,5.9C1812.7,406.6,1808.2,400.7,1811.5,396.7z M1839.7,385.1c2.4,2.5,4.9,10.6,0.9,13.1
										c0.6,2.6-3.4-13.7-3.9-9.2C1836.9,387.6,1837.8,383,1839.7,385.1z M1861.5,434.8c0-1.1,0.3-7.2,5.1-5.7
										c9.2,2.8-6.5,14.7-7.5,13.7C1860.8,440.7,1861.8,437.4,1861.5,434.8z M1861.6,416.2c4.4-5.8,13.3,5.5,4.7,6.5
										C1863.4,423,1858.8,420,1861.6,416.2z M1872.1,443.6c0.4,3.3-2,6.1-4.7,7.7c-2,1.2-4.1,3.9-6.4,0.9c-2.2-2.8,1.4-2.3,2.5-4.6
										C1865.1,444.4,1870.3,436.2,1872.1,443.6z M1891.9,440.4c1-4.7,8.6,3.5,3.7,4.1C1891.4,445,1891.3,442.9,1891.9,440.4z
										M1902.3,445.6c6.1,3.8-0.4,6.7-3.3,4.1C1898.6,448.5,1901.4,445,1902.3,445.6z M1953.3,463.6c-2.8,3.2-9.6,8.4-8.5-0.8
										C1945.7,456.1,1955.3,455.9,1953.3,463.6z M1964.8,460c4.8-3.3,5.3,3.7,4,6.3c-0.9,1.8-3.6,3.5-5.3,4.3
										C1962.1,467.4,1961.6,462.2,1964.8,460z M1992.2,466.3c3.5,4.8-3,10.8-7.6,8.5C1977.4,471.3,1987,459.2,1992.2,466.3z
										M1985.9,490.7c-1.3-2-0.2-11.1,4-9.7C1993.8,482.4,1988.9,494.7,1985.9,490.7z M1986.8,528.2c0,2.8-1.1,5.2-4.1,4.5
										C1973.8,530.6,1986.5,519.3,1986.8,528.2z M1968.7,520c-1.6,1.7-8-3.3-7.1-5.8C1964.6,507,1968.5,518.6,1968.7,520z
										M1961.8,532.1c-4.5-1.5-6.1-7.3-0.1-8.6C1966.5,522.4,1966.6,532,1961.8,532.1z M1952.7,532.4c-0.4,2.7-2.5,1.7-4.1,0.9
										C1942.6,530,1953.6,525.8,1952.7,532.4z M1954.1,523.6c-2.2,0.5-11.5,3.3-12.2,0.4c3.6-0.6,4.2-5,7-5.5
										C1951.7,517.8,1959.8,520,1954.1,523.6z M1937.8,528.7c2.5-2,6.1,3.8,5.5,6.1C1941.4,542.7,1931.3,533.9,1937.8,528.7z
										M1920.9,488.8c-1.4-2.7,5.2-0.9,6.2,0.2c1.6,1.9-1.4,9.2-4.6,9C1922,498,1921.2,489.4,1920.9,488.8z M1879.2,524.7
										c-2.4,0-6.8-12.1,0-10.5C1883,515.1,1883.3,524.7,1879.2,524.7z M1864.2,579.1c0.9,6-9.3,5.4-4-1.3
										C1861.9,575.2,1862.5,578.3,1864.2,579.1z M1855.6,578.1 M1847.3,578.6c2-3.3,6.4,2.4,6.1,4.1
										C1852.7,587.9,1843.6,584.9,1847.3,578.6z M1830.6,538.8c-4.1-2.8,3.9-11.4,5.6-4C1837.1,538.8,1834.1,541.3,1830.6,538.8z
										M1799.3,450.7c0.6-7.7,10.8-5,7,2.6C1801.4,463.4,1800.3,453.2,1799.3,450.7z M1822.1,402.9 M1983.9,479.9c-0.1,1.8-0.7,3-2,3.6
										C1979.9,477.9,1978.9,478.2,1983.9,479.9z M1853.7,573.8c-3.2-2.7,2.6-10.8,5.1-6.7C1860,569.2,1856.4,576.1,1853.7,573.8z
										M1812.8,470.1 M1799.6,423.8c6.3-5.1,4.4,5.6-2.2,8.4C1798.1,430,1797.6,425.3,1799.6,423.8z M1920,481.3
										c0.3,1.9-15.8,4.4-14.6-1.1c0.7-3.2,6.6-3.8,9.3-3.4C1920.5,477.6,1919.2,476.6,1920,481.3z M1810.9,448.4c1.6-3.3,7.3-1.6,8,1.5
										c0.7,3.2-4.5,4.7-6.5,3.8C1809.8,452.6,1811.2,451.7,1810.9,448.4z M1830.1,431.4c2.2,3.3,7.8-10.5,9.4-12.1
										c1.3,4.1,7.8,6.8,5.7,12.2c-1,2.7-6.5,3.6-9.7,4.2c-0.7,0.1-1.4,2.3-2,2.5c-3.1,0.7-2.9-3.2-6.9-0.8c-0.5-4.9-5.6-7.2-5.6-10.4
										c0.1-4.9,2.9-3.2,5.4-7c0.6,2.2,3.6,3.7,4,5.2C1831,427,1828.9,429.6,1830.1,431.4z M1857,453.3c-8,1.2-8.4,15.7-8.7,15.8
										c-0.1-2.4-3.3-6.2-3.3-8.3c0-4.3,6.6-6.8,1.1-12.2c3.5-0.8,4.6-5,8.8-3C1857.7,446.9,1855.9,451,1857,453.3z M1882.1,461.8
										c6.9-4.7,12.3,8.6,7,12.1c-3.4,2.2-9.1-1.9-10-5.3C1878.1,464.8,1879.1,465.3,1882.1,461.8z M1882.2,484.7
										c-0.8-4.4,0.9-7.5,4.8-4.2c2.8,2.4,2.5,8.8-0.2,11.2C1880.2,497.4,1882.8,488.1,1882.2,484.7z M1900.7,479.7
										c-6,14.5-12.3-7-2.6-5.9C1900.5,474.2,1901.6,477.7,1900.7,479.7z M1894.4,498.1c-9.6-0.9,9-19.4,10.3-9.2
										C1905.4,493.6,1897.7,498.4,1894.4,498.1z M1839,505.4c-2,0.2-5.4-2.6-5.4-5.9C1833.6,492,1842.5,499.3,1839,505.4z
										M1820.8,496.6c0.8-7.1,15.1-1.9,4.7,3.2C1818.5,503.2,1824.7,498.7,1820.8,496.6z M1855.2,476.9c-2.4,1.9-6,9-9.6,7.3
										c-4.5-2.2,1.4-11.9,5.5-11.7C1854.9,472.6,1854.7,473,1855.2,476.9z M1837.9,459.9 M1842.1,458c-4.7,1.2-13.7,0.9-5.9-5.9
										C1841.7,447.3,1847.8,454.5,1842.1,458z M1834.7,447.4c-2.4,3.1-11.9,2.6-11.8-1C1823.3,438.4,1838.5,442.4,1834.7,447.4z
										M1823.6,454c6-1.8,9.8,9.6,5.5,11.3C1827.1,466.2,1815.9,456.3,1823.6,454z M1829,473.8c0.4-0.9,0.8-1.7,1.2-2.6
										c2.8,1.2,6.1,12.4,0.6,11.3C1826.1,481.7,1827.7,475.6,1829,473.8z M1840.3,475c-4.9,1.6-8.4-12.8-0.1-11.8
										C1844.9,463.7,1844.1,473.7,1840.3,475z M1845.7,501.2c-1.1-3.6-1.1-12.8,4-12.5C1854.4,489.1,1854.6,504.1,1845.7,501.2z
										M1832.2,488.5c1.6-3,5-4.7,6.5-7.3c1,2.3,2.8,8.4,0.8,10.3C1838.2,492.9,1830.6,491.6,1832.2,488.5z M1822.7,437.5
										c2,11.8-14.5,1-4.3-3.2C1820.9,433.2,1821.8,432.6,1822.7,437.5z M1821.7,480.5c1.6-4.2,4.6,0.7,4.5,3.4
										C1826.1,488.3,1818.2,490,1821.7,480.5z M1823.7,507.3c1.2-6.6,12.7-2,6,7.5C1828.1,511.9,1822.9,511.4,1823.7,507.3z
										M1842.3,507.9c2.4-2.5,9.6-1.1,7,2.9C1847.3,513.9,1841.4,511.3,1842.3,507.9z M1845.4,520c1.2-4.4,7.4-3,7.8,1.1
										c0.2,2.3-1.3,2.9-3,4C1844.6,528.8,1845.7,523.1,1845.4,520z M1855.3,484.9c0.8-8,15.7-2.7,1.4,5
										C1855.5,488.5,1855,486.8,1855.3,484.9z M1870.4,470.2c3.2-0.9,7.9,2.1,6.7,5.8c-0.4,1.2-8.6,1.9-9.1,2.1
										C1866.7,475.8,1870.6,473,1870.4,470.2z M1863.3,474.7c-0.6,0.3-1.2,0.5-1.9,0.7c-0.3-2.2,0.6-6.2,3.6-6.1
										C1864,471,1863.4,472.8,1863.3,474.7z M1861.1,465.5c-0.8,1.6-1.7,3.1-2.7,4.5c-3.3-4.7-8.3-3-4.5-8.8c0.2-0.2,1.9-2.9,2.8-3.1
										C1860.5,457.2,1863,461.5,1861.1,465.5z M1847.3,443.3c-2,4.2-13,1.9-10-3.6C1840.6,433.5,1846.3,439.8,1847.3,443.3z
										M1817.1,423.4c2.8,7.1-8.2,10.6-8.8,4.1C1808,423.8,1814.8,417.7,1817.1,423.4z M1819.3,467.4c6.8-5.8,6.7,6.2,3.4,6.7
										C1820.2,474.5,1816.3,469.9,1819.3,467.4z M1818.4,503.8 M1906,497.5c0.3-7.1,12.5-13.9,10.8-3.2
										C1916,499.3,1910.4,499.7,1906,497.5z M1855.3,441c-1.8,1.4-6.8-2.9-6.5-5.5c0.3-2.3,0.2-2.3,2.9-3.2
										C1859.2,429.9,1856.2,437.9,1855.3,441z M1841.3,408.4c0.4,2.9-8.1,18.3-10.8,10.1C1828.6,413.2,1840.2,399.8,1841.3,408.4z
										M1812.3,458.1c4.2-0.5,6.1,6.5,2.2,8.2C1810.8,467.8,1808.5,459,1812.3,458.1z M1851.1,540.6c4.2-0.3,6.7,3.1,2.8,6.4
										C1847.9,552,1843.8,541.2,1851.1,540.6z M1857.3,500.8c-1.7-0.9,6.5-14,9.3-4C1868.5,503.8,1861.4,503.1,1857.3,500.8z
										M1869.5,492.4c-1.8-3.7-4-2.3-2.5-6.4c1.2-3.4,3.9-4.8,7.4-3.5C1881.9,485.1,1875.5,493.2,1869.5,492.4z M1876.5,495.8
										c3.4-0.9,4.2,1.9,3.8,5.1C1878.8,512.8,1866.6,498.3,1876.5,495.8z M1910.2,502.8 M1939.3,506.3c0-0.7,0-1.4-0.1-2.1
										C1940.6,505.8,1940.7,506.5,1939.3,506.3z M1958.9,498.5c-1.7-3-2.2-7.1,1.7-8.4c4.7-1.5,3.6,1.6,5.4,4.6
										C1963.2,495.1,1960.8,496.3,1958.9,498.5z M1952.8,500.4c0,0-11,9.7-9.8-1.2c0.3-2.8,4.1-7.4,7.1-7
										C1955.5,492.9,1950.2,495.8,1952.8,500.4z M1950,509.5c-0.2-2.8,8.2-8.1,8.4-0.2C1958.6,515.4,1950.2,512.8,1950,509.5z
										M1963.9,507.6c-6-1.8,0.4-9.5,4.2-8.7C1974.3,500.1,1972.4,510.2,1963.9,507.6z M1940,495.2c-2.2,3.4-1.7,5.8-6.2,3.3
										C1924.9,493.6,1941.5,484,1940,495.2z M1938.3,510.6c1.5-0.5,5.3-0.5,6.4,1.3c2.2,3.6-0.9,2.9-2.7,4.6c-2.3,2.1-2.7,4.6-5.8,2.5
										C1931.7,516,1936.9,514,1938.3,510.6z M1976.4,491.2c-1.5,1.2-2.7,2.4-3.7,4c-3-1.7-5.3-8.8-2.8-12.1
										C1974.6,476.7,1981.2,487.4,1976.4,491.2z M1964.6,480.2c6.1,6.4-7.2,6.3-10.3,6.5C1955.8,480.1,1959.9,475.3,1964.6,480.2z
										M1932.6,477.3c1.2,2.1,2.5,5.8,0.1,7.4c-5.4,3.7-6.6-3-6.7-5C1925.6,474.2,1928.1,471.9,1932.6,477.3z M1907.5,464.6
										c3.4-5.7,7.5,2.8,4.6,6.5c-0.7,0.9-5.5,3.3-6.8,2.5C1902,471.7,1906.8,467.3,1907.5,464.6z M1904,459.5c0.9,6.1-6.9,13.7-8.2,3.2
										C1895,457.4,1902.4,449.3,1904,459.5z M1875.3,460.5c-2.3,4.8-9.6,6.7-7.8-0.6C1869,453.5,1878.8,453.3,1875.3,460.5z
										M1854.1,415.8c-2.8-0.6-13.7,6.9-10.4-0.9c1-2.2,5.5-4.6,8.2-4.4C1856,410.9,1860.2,416,1854.1,415.8z M1824.6,407.6
										c4.6,2.7-1.3,11.9-4.9,10.9C1812,416.2,1816.5,404,1824.6,407.6z M1802.6,463.3c4.3-4.2,6.6,9.8,2.4,11.9
										C1797.8,478.8,1800.1,465.8,1802.6,463.3z M1806.2,441.9c-4.1-1-7.7-7.1-1.4-7.8C1809.3,433.6,1815.3,444.2,1806.2,441.9z
										M1812.3,511c2.8-4.4,8.2-4.1,6.8,2.2C1818.1,517.7,1809.6,515.2,1812.3,511z M1821.9,523c3.2-7.4,7.3-6.4,7.1,2.4
										C1828.6,536,1818.1,532,1821.9,523z M1836.3,527.7c-7-2.2-2.3-16.4,3.7-13.5C1844,516.2,1842.9,529.8,1836.3,527.7z M1842,538.7
										c-1.6-3.5,3.1-11.6,4.5-5.6C1846.9,534.9,1844.2,543.8,1842,538.7z M1852.2,553.8c1.4-1.6,5.2,1.3,5.7,2.9
										C1860.3,564.2,1846.6,560.4,1852.2,553.8z M1862.3,558.1c-0.3-3.5-6-7-4-9.3C1861.6,544.7,1867.6,554.5,1862.3,558.1z
										M1859.5,535c-3.1,7.2-14.8-7.5-3.9-6.3C1858.8,529,1861,531.5,1859.5,535z M1856.2,514c-4.5-0.4-2.2-5.4-0.5-6.6
										C1859.7,504.4,1863.5,514.6,1856.2,514z M1863.8,506.6 M1869.2,504.4 M1882.5,511.2c-4.6-5.7,9-19.5,7.6-5.7
										C1889.7,508.5,1885.5,514.9,1882.5,511.2z M1887.5,518.7c2.3-4.6,5.2-6,6.8-1.8C1897,524.2,1891.3,520.8,1887.5,518.7z
										M1895.4,507.9c-1.9-5.4,12.7-10.1,10.2-2.7C1904.8,507.4,1897.2,508.7,1895.4,507.9z M1898.8,514.8c4.2-6.7,13.3,2.9,5.3,5.6
										C1900.4,521.7,1898.2,518.4,1898.8,514.8z M1911.9,507.1c3.4-5.4,13.2-8.1,7.5,1.8C1916.6,513.7,1907.8,513.5,1911.9,507.1z
										M1923.8,508.5c1.8-5.5,11.1-9.5,11.2-1.5C1935.1,513.4,1921.2,516.4,1923.8,508.5z M1970.4,531.2c-0.1-2.2-1.6-6.6,2.3-6
										C1980.4,526.6,1970.6,536.5,1970.4,531.2z M1973.3,521.1c-0.7-3.9,0.8-10.3,6.6-7.8C1989.3,517.3,1978.2,522.1,1973.3,521.1z
										M1978.2,506.5c-2.4-1.9-3.7-9.7,0.1-11.3C1984.2,492.8,1985.2,505,1978.2,506.5z M1976.7,473.2c-1.5,2.6-6.5,2.1-8.6,4.1
										c-1-1.7,1.3-6.5,3.4-7.8c1.3-0.8,0.9-2.6,2.7-2.2C1975.9,467.6,1977.5,471.7,1976.7,473.2z M1957.5,471.7
										c-1.1,1.1-2.1,2.2-3.1,3.4C1949.4,466.2,1964.4,464.7,1957.5,471.7z M1943.7,476.4c2.6-6.3,7.8,3,5.9,7.2
										c-0.5,1.2-4.7,5.3-5.8,5.1C1937.6,487.3,1942.7,478.8,1943.7,476.4z M1940,472.9c-0.8,2-2.2,2.7-4.2,2
										C1934.6,472.8,1940.7,469.9,1940,472.9z M1939.4,464.6c-1.7,8.1-9.4-0.6-9.2-4.9C1930.7,448.1,1941.3,455.7,1939.4,464.6z
										M1921.3,472.3c-5.2,0.3-3-8.7,1.3-9.2C1929.1,462.3,1928.3,471.9,1921.3,472.3z M1921.9,458.3c-1.7,2.1-7.1,6.4-6.2,0.8
										C1917.3,449.5,1923.7,456.1,1921.9,458.3z M1912.7,455.5c-1.5,3.3-5.9,0.7-4.7-2.4C1909,450.3,1915.2,450.1,1912.7,455.5z
										M1891.3,447.8c3.4,2.5,1.5,7.9-1.1,9.3c-2.8,1.4-5.5-2.4-9.6,0.9C1877.8,451.2,1886.3,446.4,1891.3,447.8z M1886,441.1
										c-3.1,1.4-7.4,4.5-9.3,7.2c-0.4-0.2-0.8-5.7-0.9-6.9C1874.7,428.4,1886.5,427.3,1886,441.1z M1858,426.5
										c-3.2,5.5-16.5-0.3-7.8-5.7C1853.6,418.8,1860.9,421.6,1858,426.5z M1854.4,401.9c-0.5,3.6-9.2,5.8-10.5,1.8
										C1842.2,398.4,1855.3,395.3,1854.4,401.9z M1835.8,397.3c0,4.3-4,9.7-8.2,5.6C1820.2,395.9,1836,385.7,1835.8,397.3z M1828.7,387
										c-0.3,1.5-3,2.4-3.7,3.5C1822.6,387.3,1829.7,382.4,1828.7,387z M1822.4,393.7 M1809.2,407c5.1,6.2-1.9,16.1-7.7,10.4
										C1798.9,414.7,1803.8,403.3,1809.2,407z M1802.2,399.4c2.5,1.6-2.6,8.1-3.9,8.5C1790.8,410.3,1797.1,396.2,1802.2,399.4z
										M1796,413.5c0.5,2.4-2.5,7.1-5.7,4.5c-1.3-1.1-0.6-0.7-1-2.3C1788,410,1795.8,413.5,1796,413.5z M1790.5,422.9
										c1-0.2,1.2-0.2,2,0.3c2.1,1.3-0.7,8.5-2.2,8.8C1784.1,433,1786.3,423.8,1790.5,422.9z M1795.7,443.9c-1.4,0.5-3.2-7-1.3-7.7
										C1797.3,435.2,1798.7,442.8,1795.7,443.9z M1810.5,476.9c2.5-6.9,11.5,2.7,5,5.6C1813,483.7,1809.4,479.9,1810.5,476.9z
										M1814.6,492.3c7.4-0.9-4.1,19.9-4.6,6.4C1810,496.1,1811.6,492.7,1814.6,492.3z M1866.5,568.6c3.7-4.6,6.2,2.9,4.1,5.2
										C1866.5,578.2,1863.3,572.6,1866.5,568.6z M1875.5,571.1c3.2,3.1,3.8,0.1,3.4,5.9C1875.1,576.6,1874,574.7,1875.5,571.1z
										M1879.1,568.2c-4.8,0.9,4.3-8.1,3-0.1C1881.2,567.3,1880.2,567.4,1879.1,568.2z M1877.7,582.4c-0.3,1.4-8.3,1.9-8.8,0.7
										C1866.4,578.1,1878.6,577.5,1877.7,582.4z M1889.7,556.7c1.7,1.7-4.2,4.3-5.1,4.8C1882.6,558.8,1887.3,554.3,1889.7,556.7z
										M2000,477.5c-0.2,8.9-7.6-0.8-3.6-1.7C1998.2,475.4,1998.6,476,2000,477.5z M2009.2,467.9c-0.2,2.5-2.1,7.6-5,6.3
										C1998.7,471.7,2007.6,465.7,2009.2,467.9z M1993.8,532.8c-1.6,2.4-3.2-1.3-3.2-2.3C1991.1,530.5,1994.8,531.4,1993.8,532.8z
										M2017.4,467.4c1.1,4.6-5.7,0.4-4.1,0.2C2013.3,467.6,2016.5,463.7,2017.4,467.4z"
							/>
							<path
								d="M1849.5,372.5c1.3,4.1,2.7,3.1,3.9,4.7c0.3,0.4,2.2,3.8,3,4.8c1.6,1.9,2.5,0.6,4.2,2.9c1.4,2-0.4,5.7,2.6,6.5
										c3.2,0.8,1.7-4,2-4c5.6-0.9,2.9,1.2,5.6,4.4c2.5,2.9,0.2,3.9,4.1,1.7c-1.3,0.8,1.7-5.1,1.2-4.5c1.4-2,3.6-0.4,4.5-4
										c0,6.4,6.2-2.6,4.3-2.5c6.1-0.4,9.6,13.8,15.5,17.5c1.2,0.8,4.4,1.2,5.2,1.9c1.4,1.2,1,2.3,2.5,4.1c0.8,1,3,4.8,3.9,5.3
										c4.1,2.8,2.9,0.5,6.8-2.9c0.4,1.9,5.8,11.2,6.8,11.4c-2.8-0.5,6.7-7.8,6.5-7.6c1,1.6,3.6,2.8,4.2,4.1c0.6,1.2-0.9,3.9-0.4,4.8
										c3.3,5.7,0.4,3,4.3-0.8c3.4-3.3-1.2-6.9,5.8-1.5c1.8,1.4,0.9,4.7,4,4.7c2.7,0-0.2-4.1,2.6-4.5c5.3-0.8,3.2,3.1,4.9,4.4
										c1.2,0.9-0.8,2.2,1.6,2.8c2.9,0.7,1.3-3.9,2.6-4.3c4.4-1.2,6.4-0.1,8.5,2.9c1.6,2.4-2.1,8.2,3.4,4.6c3.3-2.1-1.5-8.5,5.7-3.5
										c2.5,1.7,0.4,5.5,3.7,6.3c2.8,0.7,0.6-6.4,5.6-6.4c5.5,0,2.7,12.9,6.6,10.2c2.7-1.8,0.8-6.3,3.6-8.6c0-0.2,3.8,8,3.8,7.9
										c0.5,0.6,4,4.3,3.8,4c1.3,2.7,3,7.1,5.6,9.6c2.9,2.7-1.5,3.6,3.8,2.3c-1.8,0.4,7.1-9,6.7-8.1c-0.5-1,6.2,8.7,3,6.5
										c1.2,0.9-1.1,2,2,2.3c0.5,0.1,6.8-9.9,7.2-10.9c-0.5-0.7,6.1,4.2,3.4,4.3c-0.2,1.8,0.7,2.6,2.5,2.4c1.6-1,2.1-2.6,1.5-4.6
										c5.6-1.5,1.4,3.5,5.1,4.1c1.8,0.3,3.5-2.7,4.4-4c1.9-2.7,4.7-2.9,3-7.5c-1.6-4.3-6.9-5-10.2-2.3c-0.1-0.5-0.3-0.9-0.6-1.3
										c4.2-1,4.3-6.1,2.8-9.4c-1.1-2.4-3-1.2-4-2.5c-1-1.2-1.7-3-2.8-5.4c-0.6-1.3-2.1-0.3-2.1-1.9c-0.1-4.4,1.5-2.8,2.6-5.1
										c1.9-4,5.4-7.8-0.1-11.7c4.8-2.7,1.5-5.8,1-9.3c-2.4-1-2.8-2.5-1.2-4.4c0.4-2.5-0.3-4.6-2-6.5c-1.6-1.5-3.4-2.1-5.4-1.8
										c-0.1-2.1-0.9-4-2.2-5.7c-3.7-2.4-6.2-4.5-9.8,0.1c-1.8-6-5.3-3.1-9.4-3.9c-1.9,2.9-3.5,2.9-4.8-0.2c-1.6-1.3-3.4-1.8-5.5-1.6
										c-1.8,0.4-3.2,1.5-4.2,3c-0.7,1-1.4,0.4-2.4-1.6c-4.5,0.4-6.9-2.3-11.2,1.8c-3.6-6.6-6.8-3.2-11.1-0.9c-4.4,2.4-1.5,3-8,4.3
										c1-6.3-5.5-13.6-11.5-7.2c-2.6-6.8-9.9-2.1-13.1,1.5c-2.9-5.2-9.7-7.1-12.5-1c-2.7-9.9-12.7-2.8-10,5.9c-4-0.1-3.8-10.6-5.6-12.4
										c-5.6-5.7-10.3,0.2-15.6,1.1c-2.7,0.5-6.1-1-9.4-0.1c-1.6,0.5-2.6,1.7-3.1,3.4c-3.4,2.2-5.1,1.9-5.2-0.9
										c-1.5-0.4-2.1-3.9-5.7-4.2c-4.2-0.3-7.8,2.9-8.4,7c-2.2-2.1-3.6,0.4-5.4,0.5c-1.2,0-2.1-3.8-4.5-3.8
										C1847.2,359.7,1841.3,369.8,1849.5,372.5z M1858.3,369.7c0.5-0.9,1.5-2.4,2.3-3C1867,372.9,1852.6,379.3,1858.3,369.7z
										M1870.1,360.2c4.4-1.7,5.3,3.9,2.7,5.4C1869.6,367.4,1864.2,362.5,1870.1,360.2z M1878.7,366.1c7.9-4.3,1.6,8-1.6,9.2
										C1876.8,372.5,1875.9,367.6,1878.7,366.1z M1890.7,360.6c7.8,0.2,0.5,6.7-2.7,5.6C1884.3,364.8,1887.7,360.5,1890.7,360.6z
										M1904.3,358.2c5.6-1.8,3.2,5-0.3,6.6C1895.9,368.5,1898.4,360.1,1904.3,358.2z M1917.1,373.2c4.4,0.4,2.7,9.9-0.8,9.6
										C1913,382.4,1912.4,372.7,1917.1,373.2z M1946.4,363.6c0.7,0,0.7-0.6,1.5-0.3c3.4,1.2-0.6,8.6-5.2,6.7
										C1938.7,368.3,1945,364.4,1946.4,363.6z M1966.9,373.5c7.5,2.7-2.5,5.6-5.9,8.8C1958.2,380.9,1963.9,373.7,1966.9,373.5z
										M1979.8,370.8c-0.2,1.6-5.5,2.1-6.2,1.2C1971.5,369.3,1980.8,362.8,1979.8,370.8z M1978.1,377.4c5.7,0,1.4,5.1-2,5.9
										C1967.5,385.3,1972.3,377.4,1978.1,377.4z M1988.4,367.9c6.8-1.8,3.9,4.2,4.9,7c-1.4,0.1-7.7,2.6-8.6,2.1
										C1980.4,374.7,1985.6,368.6,1988.4,367.9z M1996.9,381.3c0.7-10.5,9.3,12.4-0.2,9.6C1994.3,390.2,1997.5,384.1,1996.9,381.3z
										M2016.9,371.9c0.2,5.3-9.6-0.7-4.8-2.9C2014.5,368,2016.7,368.1,2016.9,371.9z M2024.5,370.5c7.5-4.2,0.1,9.9-0.5,11
										C2021.7,379.7,2021.6,372.1,2024.5,370.5z M2023.4,430.6c-1.5,2.6-4.3-8.8,0.9-9.1C2030.8,421.1,2023.6,430.3,2023.4,430.6z
										M2011.2,440c0-5.9,9.3-6.3,7.9,0.6C2018.1,445.4,2011.2,445.5,2011.2,440z M2008.6,433.2c-0.8,0.5-1.5,1.6-2.3,2.2
										c0.7-3.6,10.4-12.1,11.2-4.1C2015,432.8,2011.3,431.6,2008.6,433.2z M2003.5,429c-3.4-3.3,2.1-9.5,4.7-6
										C2009.7,425.1,2005.9,431.4,2003.5,429z M2000.1,417.5c-3.7-13.7,13-7.4,6.8,0C2005.6,419.1,2000.9,419.1,2000.1,417.5z
										M1986.3,421.9c-5.2,4.8-8.5-4.8-2.3-4C1986.3,418.2,1988.7,419.7,1986.3,421.9z M1962.5,418.1 M1952.6,414.7
										c-3.3,3.1-9.5-1.5-5.4-4.2C1954.2,405.8,1952.7,412.8,1952.6,414.7z M1951.6,405.6c-1.2,3.5-6.1-0.2-5.9-2.9
										c0.1-1.7,2.3-4.9,4.3-4.5C1953,398.8,1951.5,404.2,1951.6,405.6z M1942.6,379.2c-1.6-6.2,9.8-0.5,6.7,5.5
										C1944.7,393.4,1942.9,380.6,1942.6,379.2z M1948.9,394.2c-0.2-0.8-1.1-3-0.2-3.3C1950.6,393.2,1950.7,394.3,1948.9,394.2z
										M1949.2,373.9 M2015.3,382.7 M2010.1,390.5c-10.6,4.4-5.6-4.1,0.7-2.3C2010.6,389,2010.3,389.8,2010.1,390.5z M2024,402.5
										c2.7-2,8.1,0.3,4.5,2.7C2025.6,407.1,2022.3,403.8,2024,402.5z M2028.5,417.3c-5.7-0.1-1-5.9,2.1-6.5
										C2037.2,409.6,2031.6,415.3,2028.5,417.3z M2029.1,393c1.3,3.6-4.6,2.9-6.7,3.7C2022.5,395.6,2028.3,390.6,2029.1,393z
										M2021.9,408.7c-0.7,1.2-7.4,0.5-9.6,1.4C2012.2,403.1,2020.5,400,2021.9,408.7z M2015.3,397.4c-2.5,1.2-5.4,4-6.9,6.1
										c-3.7-2.8,1.2-8.7,4-9.5C2015,393.2,2019.3,395.4,2015.3,397.4z M2018.7,418.9c-7.1,6.9-9.2-2.2-2.7-4.7
										C2019.6,412.9,2022.4,415.4,2018.7,418.9z M2035.3,423.2c-4.5,0.8-3-5.5,0.1-5.5C2038.5,417.8,2037.1,421.9,2035.3,423.2z
										M2034,403.9c-0.9-2.9,1.5-7.1,4.2-3.4C2041,404.3,2035.1,407.4,2034,403.9z M2015.3,382.3c0.3-1.4,1.1-4.4,2.9-4.1
										C2018.1,379.8,2017.3,382.5,2015.3,382.3z M1971.2,387.8 M1975.5,394.7c-3.1-10.8,10.1-7.9,5.8,0.1
										C1979.8,397.8,1976.9,399.7,1975.5,394.7z M1985.5,381.4c3.5-0.3,8.4-3.6,7.4,2.8C1991.6,392.5,1984.6,387.6,1985.5,381.4z
										M1985.3,395.8c4.7-4.5,10.6,2.4,5.6,5.3C1988.2,402.6,1984.4,398.3,1985.3,395.8z M1961.9,393.9c0.3-1.9-0.8-7.4,1.8-8.4
										C1965.3,384.8,1974.1,396.4,1961.9,393.9z M1952.7,387.1c1.9-10.3,5.4,1.1,2.7,6.7C1952.7,393,1952.3,389.4,1952.7,387.1z
										M1956,401.8c2.6-10.3,14.3,2.8,5,6.1C1954.6,410.3,1956.2,404.8,1956,401.8z M1967.2,408.1c0.8-3.1,0.6-11.3,4.9-9.4
										C1979.3,401.9,1966.5,410.8,1967.2,408.1z M1971.3,413.4c2.5-3.4,5.2,1.7,5,3.8C1975.6,423.4,1967,419.4,1971.3,413.4z
										M1977.9,404.7c1.1-1.8,5.4-2,6.9-0.3C1991.8,412,1971.3,415.1,1977.9,404.7z M1989.4,411.5c-0.6-4.6,1.9-7.7,5.5-5.1
										C1999.7,409.8,1989.2,417.6,1989.4,411.5z M1992.3,421.3c1.9-4.2,2.9-3.9,4.5-0.9C2000.1,426.5,1989.8,426.4,1992.3,421.3z
										M1996,401.5c-1.2-4.8,4.7-5.8,7-2.7C2008.8,406.9,1996.9,401.9,1996,401.5z M2026.1,441.6c-0.4-0.7-0.2-8.7,2.2-8.1
										C2033.6,435.1,2028.2,445.2,2026.1,441.6z M2031.3,428.3c1.6-1.8,5.2-0.1,3.5,3.1C2033.3,431,2031.2,430.1,2031.3,428.3z
										M2038.1,435.2c0.3-0.4,1.1-1.1,1.7-0.9C2045.8,436.5,2032.3,443.5,2038.1,435.2z M2043.2,428.5 M2038.9,392.3
										c-0.7,3.3-3.9,1.3-5,0.5C2030.5,390.2,2040.2,386.1,2038.9,392.3z M2035.7,383.1c-0.6,1.8-5.1,5.2-6.9,3.9
										C2022.5,382.5,2038.8,373.8,2035.7,383.1z M2022.1,385.5c2.1,1.2,4.7,2,1.1,4.5C2017.4,394,2015.6,384.6,2022.1,385.5z
										M2010.2,377.8c7.4,0.5-10.2,8-3.2,1.4C2007.8,378.5,2009,377.8,2010.2,377.8z M2005,369.8c2.4,6.9-6.6,6.6-6.9,2.3
										C1997.8,368.6,2003.3,365.1,2005,369.8z M1959.2,371.9c-0.7,2.4-2.3,5.5-4.9,6.2C1948.3,366.6,1963.1,359.1,1959.2,371.9z
										M1944.5,392.3c3.3,6.5-10.5,8.9-7.5,0.7C1938.3,389.4,1942.4,388.1,1944.5,392.3z M1899.8,394.1c-1.6-8.1,13.7-1,7.3,3.4
										C1903.2,400,1902.3,394.9,1899.8,394.1z M1885.7,378.5c-4.7,0.6-4.1-4.3-0.2-5.2C1889.4,372.4,1891.4,377.9,1885.7,378.5z
										M1905.7,386.6c-1.5-0.4-3-0.4-4.6-0.1c-1.1-1,3.7-9.4,4.3-9.5C1911.1,375.6,1912.1,385.5,1905.7,386.6z M1891.8,382
										c0.7-1.8,4-3.1,5.7-1.8C1905,385.9,1888.7,389.3,1891.8,382z M1911.1,400.9c1.6-4.6,8.1-1.3,6.3,3
										C1915.4,408.5,1909.6,405,1911.1,400.9z M1913.1,393.6c-3.6-4.4,1.8-9.2,6.4-4.8C1924.8,393.8,1916.9,393.8,1913.1,393.6z
										M1922.9,406.1c0.5-1.3,7.8,0.8,5.6,3.6C1926.8,411.9,1921.6,409.2,1922.9,406.1z M1924.5,400.9c-4.5-0.3-0.6-7.6,3.5-7.2
										c1.9,0.2,3.8,2,4.3,3.8C1934.4,405.2,1927.7,401.1,1924.5,400.9z M1935.8,404.4c3-3,7.2,2.8,5.9,5.8
										C1939.3,415.8,1931.5,408.7,1935.8,404.4z M1937.1,386.3c-1.5,1-2.6,2.3-3.3,3.9c-5.5-2.7-1-15.4,3.3-11.9
										C1939,379.8,1938.3,384.5,1937.1,386.3z M1933.1,372.1c-1.4-0.1-2.1-0.8-2.9-1.9c-1.1-1.5-0.6-5.8,0.9-6.6
										C1934.8,361.8,1939.9,371.5,1933.1,372.1z M1927.1,378.1c1.3,1.4,0.1,9.5-2,9.7C1919.1,388.3,1923.8,374.7,1927.1,378.1z
										M1923.4,370.4c-0.1,0.6-0.1,1.1-0.2,1.7c-0.2,0.1-0.3,0.1-0.5,0.1c-1.4-2.2-4.8-7.8-1.8-9.6
										C1922.9,361.3,1924.2,369.2,1923.4,370.4z M1912.4,369.7c-0.6,4.9-4.9,0.6-5.5,0.8c-2.5,0.5-2.2,2-3.9,3.3
										c0.5-3.7-0.3-5.7,4.8-6.4C1909.6,367.2,1913,365.3,1912.4,369.7z M1901,375.4c1.5,0-0.8,1.4-1,1.5c-5.6,2.1-6.7-4.2-9.7-6.4
										c2.1-0.2,3.9-1.3,5.3-2.9C1896.2,368.6,1900.9,375.4,1901,375.4z M1869.4,374.4c-12.1-7.2,12.7-5.4,1.3,2
										C1870.5,375.5,1870.1,374.8,1869.4,374.4z M1871.1,381.9c1.5-2.9,2.5-1.1,4.2-1.1C1881.2,381,1866.8,390.1,1871.1,381.9z
										M1864.5,376.3c0.6,1.3,3.6,6.2,0.2,6.7C1860.5,383.6,1864.2,376.8,1864.5,376.3z M1850.7,364.8c0.7-0.6,1.7-1.3,2.6-1.2
										C1862.5,364.5,1841.5,372.1,1850.7,364.8z M1880.8,383.2 M2048.2,436.2c0.8-0.2,1.5,0.2,2.1,0.7
										C2056.3,442.4,2039.1,438,2048.2,436.2z"
							/>
							<path
								d="M2140.2,519.2c5.1,1.6-3,10.2-2,13.8c0,0,6.4,5.4,5.9,5.1c1,0.6-0.3,3.7,2.3,3.6c4.5-0.1-2-10,5.6-7
										c2.4,0.9,0,4.7,2.9,5.6c2.9,0.9,1.5-2.8,2.1-3.5c7.8-9.2-3.3-16.4-7.4-24.2c-2.2-4.2-4.5-13.5-12.7-8.3
										C2130.6,508.2,2134.3,517.4,2140.2,519.2z M2145.3,510.2c1,4.2-5.3,7.7-7.2,2.6C2134.8,504.1,2143.5,510.2,2145.3,510.2z
										M2142.3,529.5c3-3.6,8.6,0.2,4.9,2.6C2145.7,533.1,2140.2,532.2,2142.3,529.5z M2144.8,521c-2.5-7,8.5-1.9,6.7,0
										C2150.2,522.5,2146,524.4,2144.8,521z M2155.8,531.8c-1.7,1.7-3.6-3.7-2.7-5.1C2155.5,527.7,2156.3,529.4,2155.8,531.8z"
							/>
						</g>
					</g>
				</g>
				<g id="display_font">
					<g className="level_zero location" id="to_umbar">
						<rect x="1153.025" y="903.517" width="74.87" height="19.877" transform="matrix(-0.976706, -0.214584, 0.226352, -0.97412, 2726.835449, 3484.403076)"></rect>
						<text
							className="text_el"
							strokeWidth="0"
							x="1738.4032044410706"
							y="2351.643630504608"
							id="svg_3405"
							fontSize="30"
							style={{ fontFamily: "Architects Daughter" }}
							xmlSpace="preserve"
							fontWeight="normal"
							fontStyle="normal"
							transform="rotate(9.82134, 1812.91, 2340.98)">
							To Umbar
						</text>
					</g>
					<g className="level_one location" id="lindon">
						<rect x="2670.732" y="2421.483" width="173.413" height="53.275" transform="matrix(-0.074627, 0.997212, -0.996313, -0.086637, 2948.469482, -1972.46814)"></rect>
						<rect x="2670.734" y="2459.877" width="173.413" height="54.12" transform="matrix(-0.074627, 0.997212, -0.996313, -0.086637, 2970.706055, -1694.905029)"></rect>
						<text
							className="text_el"
							x="290.9612121582031"
							y="538.6905059814453"
							fontSize="55"
							style={{ fontFamily: "Comic Sans MS" }}
							xmlSpace="preserve"
							strokeDasharray="5,5"
							fontWeight="normal"
							fontStyle="normal"
							transform="rotate(95.0323, 307.736, 518.694)">
							L
						</text>
						<text
							className="text_el"
							x="271.3113098144531"
							y="822.3343434333801"
							fontSize="55"
							style={{ fontFamily: "Comic Sans MS" }}
							xmlSpace="preserve"
							strokeDasharray="5,5"
							fontWeight="normal"
							fontStyle="normal"
							transform="rotate(89.7731, 294.019, 802.337)">
							D
						</text>
						<text
							className="text_el"
							x="272.3113112449646"
							y="868.6698989868164"
							fontSize="55"
							style={{ fontFamily: "Comic Sans MS" }}
							xmlSpace="preserve"
							strokeDasharray="5,5"
							fontWeight="normal"
							fontStyle="normal"
							transform="rotate(87.0393, 295.574, 848.673)">
							O
						</text>
						<text
							className="text_el"
							x="275.3119215965271"
							y="916.0054540634155"
							fontSize="55"
							style={{ fontFamily: "Comic Sans MS" }}
							xmlSpace="preserve"
							strokeDasharray="5,5"
							fontWeight="normal"
							fontStyle="normal"
							transform="rotate(86.6092, 297.475, 896.009)">
							N
						</text>
						<text
							className="text_el"
							x="295.96606159210205"
							y="582.0224275588989"
							fontSize="55"
							style={{ fontFamily: "Comic Sans MS" }}
							xmlSpace="preserve"
							strokeDasharray="5,5"
							fontWeight="normal"
							fontStyle="normal"
							transform="rotate(93.0972, 305.287, 562.025)">
							I
						</text>
						<text
							className="text_el"
							x="278.3052453994751"
							y="636.0200042724609"
							fontSize="55"
							style={{ fontFamily: "Comic Sans MS" }}
							xmlSpace="preserve"
							strokeDasharray="5,5"
							fontWeight="normal"
							fontStyle="normal"
							transform="rotate(93.7224, 300.469, 616.023)">
							N
						</text>
					</g>
					<g className="level_zero location" id="mordor">
						<rect x="2865.339" y="2766.031" width="186.057" height="60.849" transform="matrix(0.888145, -0.459564, 0.448861, 0.893683, -1209.789307, 552.509949)"></rect>
						<rect x="3285.706" y="2937.33" width="213.353" height="64.617" transform="matrix(0.980935, -0.194334, 0.182513, 0.983277, -1375.679321, -506.42276)"></rect>
						<path
							d="M2437.6,1746.8c-0.7,0.3-1.1,0.8-1.3,1.6c-0.2,0.7-0.2,1.9,0,3.4l3,30.6c0.2,1.6,0.4,2.7,0.7,3.4c0.3,0.7,0.8,1.1,1.5,1.3
									c0.7,0.2,1.8,0.2,3.3,0.2l0.1,1.2c-1.9,0.1-4.5,0.3-7.9,0.6c-3.5,0.3-6.2,0.7-7.9,1l-0.1-1.2c1.5-0.2,2.5-0.5,3.2-0.8
									s1.1-0.8,1.3-1.6c0.2-0.7,0.2-1.9,0-3.4l-3.2-33.1l-11,41.4l-1,0.1l-19.5-38.6l3.2,32.6c0.2,1.6,0.4,2.8,0.8,3.5
									c0.3,0.8,0.9,1.3,1.7,1.5c0.8,0.3,2.1,0.4,3.7,0.3l0.1,1.2c-1.5,0-3.6,0.2-6.3,0.4c-2.5,0.2-4.4,0.5-5.7,0.7l-0.1-1.2
									c1.4-0.2,2.5-0.5,3.1-1c0.7-0.4,1.1-1,1.3-1.9c0.2-0.8,0.2-2,0-3.6l-2.9-30c-0.2-1.6-0.4-2.7-0.7-3.4c-0.3-0.7-0.8-1.1-1.5-1.3
									c-0.7-0.2-1.8-0.2-3.3-0.2l-0.1-1.2c1.4,0,3.3-0.1,5.8-0.4c2.1-0.2,3.9-0.4,5.2-0.7l17.1,34.4l9.8-36.9l2.9-0.3
									c3.3-0.3,5.9-0.6,7.8-1l0.1,1.2C2439.4,1746.2,2438.3,1746.5,2437.6,1746.8z"
						/>
						<path
							d="M2497.1,1737.1c3.2,1.2,6,3.3,8.2,6.3c2.2,3,3.8,6.8,4.6,11.4c0.8,4.5,0.7,8.6-0.3,12.4s-2.8,6.9-5.4,9.3
									c-2.6,2.5-5.8,4-9.5,4.7c-3.9,0.7-7.4,0.5-10.6-0.7c-3.2-1.2-6-3.3-8.2-6.3c-2.2-3-3.8-6.8-4.6-11.4c-0.8-4.5-0.7-8.6,0.3-12.4
									c1-3.8,2.8-6.9,5.4-9.3c2.6-2.5,5.8-4,9.5-4.7C2490.3,1735.6,2493.9,1735.9,2497.1,1737.1z M2480,1741.5c-1.6,2.2-2.7,5-3.2,8.4
									c-0.5,3.5-0.3,7.3,0.4,11.5c0.8,4.2,2.1,7.8,3.9,10.8c1.8,3,3.9,5.2,6.2,6.5c2.4,1.4,4.8,1.8,7.3,1.4c2.6-0.5,4.7-1.8,6.4-4
									c1.6-2.2,2.7-5,3.2-8.4s0.3-7.3-0.4-11.5c-0.8-4.2-2.1-7.8-3.9-10.8s-3.9-5.2-6.2-6.5c-2.4-1.4-4.8-1.8-7.3-1.4
									C2483.8,1738,2481.7,1739.3,2480,1741.5z"
						/>
						<path
							d="M2578.9,1758.3c-0.6,1.6-1.2,2.8-2,3.7c-0.8,0.8-1.9,1.5-3.4,1.8c-1.6,0.4-3.1,0.3-4.4-0.4c-1.3-0.7-2.5-2.2-3.7-4.4
									l-2.9-5.7c-1-2-1.9-3.4-2.6-4.5c-0.7-1-1.6-1.8-2.6-2.2s-2.3-0.5-3.8-0.1l-5.7,1.5l3.5,13.8c0.4,1.5,0.8,2.6,1.2,3.2
									c0.4,0.6,1,1,1.7,1c0.7,0.1,1.8,0,3.3-0.3l0.3,1.2c-1.9,0.4-4.4,0.9-7.7,1.8c-3.4,0.9-6,1.6-7.6,2.2l-0.3-1.2
									c1.4-0.4,2.4-0.9,3-1.3s0.9-1,1-1.7c0.1-0.7-0.1-1.9-0.5-3.4l-7.6-29.8c-0.4-1.5-0.8-2.6-1.2-3.2c-0.4-0.6-1-1-1.7-1
									c-0.7-0.1-1.8,0-3.3,0.3l-0.3-1.2c1.7-0.3,4.2-0.9,7.4-1.7l6.5-1.7c0.8-0.2,1.6-0.5,2.6-0.7c4.6-1.2,8.5-1.1,11.5,0.2
									c3,1.3,4.9,3.5,5.7,6.6c0.4,1.6,0.4,3.2,0.1,5c-0.3,1.8-1.2,3.5-2.6,5.2c-1.4,1.7-3.5,3.1-6.2,4.3l0.8-0.1
									c2.8-0.2,4.9,0.1,6.4,0.9c1.5,0.9,2.9,2.5,4.1,4.9l3.1,5.7c0.9,1.7,1.6,2.9,2.2,3.5c0.6,0.6,1.3,0.8,2.1,0.6
									c0.7-0.2,1.2-0.5,1.6-1s0.8-1.2,1.1-2.2L2578.9,1758.3z M2544.2,1726.8c-0.5,0.4-0.9,1.1-0.9,1.8s0.1,1.9,0.5,3.3l3.8,14.8l4-1
									c4-1,6.5-2.6,7.5-4.7c1-2.1,1.1-4.8,0.3-7.9c-0.8-3.3-2.3-5.6-4.3-6.9c-2-1.3-4.8-1.5-8.3-0.6
									C2545.6,1726,2544.7,1726.4,2544.2,1726.8z"
						/>
						<path
							d="M2635.2,1698.8c4.8,1.9,8.6,6,11.5,12.4c1.8,4.1,2.6,8.1,2.4,11.9c-0.3,3.8-1.5,7.3-3.9,10.3c-2.3,3-5.6,5.5-9.9,7.4
									l-3.3,1.4c-2.2,0.9-3.7,1.5-4.7,1.9c-3.1,1.4-5.4,2.5-7,3.3l-0.5-1.1c1.3-0.7,2.3-1.3,2.8-1.8s0.8-1.1,0.7-1.9
									c-0.1-0.7-0.4-1.8-1-3.3l-12.5-28.1c-0.6-1.4-1.2-2.4-1.7-3c-0.5-0.5-1.1-0.8-1.9-0.7s-1.8,0.3-3.2,0.9l-0.5-1.1
									c1.4-0.5,3.2-1.2,5.6-2.3l4.8-2.2c2.5-1.2,4.2-2,5.1-2.4C2624.6,1697.5,2630.4,1697,2635.2,1698.8z M2614.5,1703.7
									c-0.5,0.5-0.7,1.2-0.6,2c0.1,0.8,0.4,1.9,1,3.2l12.6,28.4c0.6,1.4,1.2,2.3,1.7,2.9c0.5,0.6,1.1,0.9,1.8,0.9c0.7,0,1.7-0.3,2.9-0.8
									c4-1.8,6.8-3.9,8.5-6.4c1.7-2.5,2.3-5.3,2-8.5c-0.3-3.2-1.4-7-3.4-11.3c-1.9-4.3-4-7.7-6.2-10c-2.2-2.3-4.8-3.6-7.7-4
									c-2.9-0.3-6.3,0.3-10.2,2.1C2615.8,1702.6,2615,1703.2,2614.5,1703.7z"
						/>
						<path
							d="M2680.8,1668.9c3.5,0,6.8,1,9.9,3c3.2,2,6,5,8.4,9c2.4,3.9,3.8,7.8,4.2,11.6c0.4,3.9-0.2,7.4-1.7,10.6
									c-1.5,3.2-3.9,5.8-7.2,7.8c-3.4,2.1-6.8,3.1-10.2,3.1c-3.5,0-6.8-1-9.9-3c-3.2-2-6-5-8.4-9c-2.4-3.9-3.8-7.8-4.2-11.6
									c-0.4-3.9,0.2-7.4,1.7-10.6s3.9-5.8,7.2-7.8C2673.9,1670,2677.3,1668.9,2680.8,1668.9z M2666.4,1679.1c-0.8,2.6-0.7,5.6,0.1,9
									s2.3,6.9,4.5,10.5c2.2,3.7,4.7,6.6,7.5,8.7c2.7,2.2,5.5,3.4,8.2,3.9c2.7,0.4,5.1,0,7.3-1.4c2.3-1.4,3.8-3.4,4.5-6
									c0.8-2.6,0.7-5.6-0.1-9c-0.8-3.4-2.3-6.9-4.5-10.5c-2.2-3.7-4.7-6.6-7.5-8.7c-2.7-2.1-5.5-3.4-8.2-3.9c-2.7-0.4-5.1,0-7.3,1.4
									C2668.7,1674.5,2667.2,1676.5,2666.4,1679.1z"
						/>
						<path
							d="M2757.3,1660.9c0.1,1.7,0,3.1-0.4,4.2c-0.4,1.1-1.2,2.1-2.4,3.1c-1.3,1-2.7,1.5-4.2,1.4s-3.2-1-5.2-2.6l-4.9-4
									c-1.7-1.4-3.1-2.4-4.2-3c-1.1-0.6-2.1-1-3.3-1s-2.3,0.5-3.5,1.4l-4.6,3.6l8.8,11.2c1,1.2,1.8,2.1,2.4,2.5c0.6,0.4,1.3,0.5,2,0.3
									c0.7-0.2,1.6-0.8,2.9-1.6l0.8,1c-1.6,1.1-3.7,2.7-6.3,4.7c-2.8,2.2-4.8,3.9-6.1,5.1l-0.8-1c1.1-1,1.9-1.8,2.3-2.4
									c0.4-0.6,0.5-1.3,0.2-2c-0.2-0.7-0.8-1.7-1.8-2.9l-19-24.2c-1-1.2-1.8-2.1-2.4-2.5c-0.6-0.4-1.3-0.5-2-0.3
									c-0.7,0.2-1.6,0.8-2.9,1.6l-0.8-1c1.4-1,3.5-2.5,6.1-4.5l5.2-4.2c0.6-0.5,1.3-1.1,2.1-1.7c3.8-3,7.3-4.5,10.6-4.5
									c3.3,0,5.9,1.2,7.9,3.7c1,1.3,1.7,2.8,2.1,4.5s0.3,3.7-0.3,5.8c-0.6,2.1-1.9,4.3-3.9,6.4l0.7-0.4c2.4-1.3,4.5-1.9,6.2-1.7
									c1.7,0.2,3.6,1.1,5.7,2.8l5.1,4c1.5,1.2,2.7,2,3.5,2.3c0.8,0.3,1.5,0.2,2.2-0.3c0.6-0.4,0.9-0.9,1-1.5c0.1-0.6,0.2-1.4,0.2-2.4
									L2757.3,1660.9z M2712.7,1646.1c-0.3,0.6-0.4,1.3-0.1,2c0.3,0.7,0.9,1.7,1.8,2.9l9.4,12l3.3-2.6c3.2-2.5,4.9-5,4.9-7.4
									c0.1-2.4-0.9-4.8-2.9-7.4c-2.1-2.7-4.3-4.2-6.7-4.5c-2.3-0.4-4.9,0.6-7.8,2.8C2713.7,1644.8,2713.1,1645.5,2712.7,1646.1z"
						/>
					</g>
					<g className="level_one location" id="near_harad">
						<rect x="7093.058" y="2937.331" width="460.579" height="64.617" transform="matrix(1, 0.000911, -0.012962, 0.999989, -4612.39502, -678.68634)"></rect>
						<path
							d="M2454.4,2319.7c0.4-5.6,0.6-13.4,0.6-23.5c0-9.6-0.3-15.6-0.9-18.1c1.6-0.3,3.1-0.5,4.6-0.5c1.3,0,3.1,0.2,5.2,0.5
									c1.7,3.2,4.3,8,8,14.3c3.6,6.3,7.1,12.1,10.3,17.5c0.1-5.9,0.2-10.5,0.2-13.8c0-9.8-0.3-15.8-0.9-17.9c1.1-0.3,2.1-0.5,3.2-0.5
									c1,0,2.2,0.2,3.5,0.5c-0.4,7-0.6,14.8-0.6,23.5c0,9.6,0.3,15.6,0.9,18.1c-1.3,0.3-2.8,0.5-4.5,0.5c-1.5,0-3.2-0.2-5.3-0.5
									c-1.9-3.5-4.7-8.4-8.3-14.8c-3.6-6.4-6.9-12-9.9-17c-0.2,4.5-0.2,9.1-0.2,13.8c0,9.5,0.3,15.5,0.9,17.9c-1,0.3-2.1,0.5-3.2,0.5
									C2457,2320.2,2455.8,2320,2454.4,2319.7z"
						/>
						<path
							d="M2504.2,2307.9c0.1-3.3,0.2-7.3,0.2-11.9c0-4.8-0.1-8.5-0.3-11.2s-0.5-4.9-1-6.7c2.8,0.4,7.1,0.6,13,0.6
									c7.1,0,12.6-0.2,16.6-0.6c0,1.6-0.2,3.3-0.5,5.1c-0.3,1.8-0.7,3-1.2,3.7c-1.3-0.7-3-1.2-5.1-1.6c-2.1-0.4-4.2-0.6-6.3-0.6
									c-2.4,0-4.7,0.2-7.1,0.6c-0.2,4.1-0.3,7.7-0.4,10.9c2.5,0.1,4.5,0.2,5.8,0.2c2.6,0,4.6-0.1,5.9-0.2c1.4-0.1,2.9-0.4,4.7-0.7
									c0.2,1.3,0.3,2.3,0.3,3.2c0,0.7-0.1,1.8-0.3,3.3c-6-0.4-10.6-0.6-13.9-0.6l-2.6,0.1c0,5.1,0.1,8.8,0.3,11.1
									c2.6,0.5,5.1,0.7,7.7,0.7c2.4,0,4.7-0.2,7.1-0.6c2.3-0.4,4.3-0.9,5.9-1.6c0,1.6-0.2,3.3-0.5,5.1c-0.3,1.8-0.7,3-1.2,3.7
									c-3.5-0.4-8.5-0.6-14.9-0.6c-5.8,0-10.1,0.2-13,0.6C2503.9,2315.3,2504.1,2311.2,2504.2,2307.9z"
						/>
						<path
							d="M2572.8,2319.6c-0.8-2.3-2-5.7-3.6-10c-2.7-0.2-5.8-0.2-9.2-0.2c-3.7,0-6.3,0.1-7.7,0.3c-1.5,4-2.7,7.3-3.6,10
									c-1.1,0.3-2.1,0.5-2.9,0.5c-1.2,0-2.3-0.2-3.4-0.5c1.5-2.6,4.1-8.8,7.8-18.6c3.7-9.8,6.5-17.5,8.4-23c1.6-0.3,2.9-0.4,4-0.4
									c1.2,0,2.4,0.1,3.7,0.4c1.8,5.5,4.6,13.2,8.4,23c3.7,9.8,6.3,16,7.8,18.6c-1.5,0.4-3.1,0.6-4.8,0.6
									C2576.2,2320.1,2574.6,2319.9,2572.8,2319.6z M2567.3,2304.2c-2.8-7.5-5-13.1-6.6-16.8c-1.5,3.4-3.7,9-6.5,16.7
									c2.6,0.2,5.5,0.2,8.7,0.2C2565,2304.3,2566.5,2304.3,2567.3,2304.2z"
						/>
						<path
							d="M2593.8,2319.7c0.2-2.6,0.4-6.2,0.6-10.9s0.3-8.9,0.3-12.7c0-8.8-0.4-14.3-1.3-16.4c1.6-0.6,3.8-1.1,6.6-1.5
									c2.8-0.4,5.6-0.6,8.5-0.6c5.4,0,9.5,1.2,12.4,3.6c2.9,2.4,4.3,5.8,4.3,10.3c0,2.6-0.7,5-2.1,7c-1.4,2.1-3.3,3.7-5.8,4.9
									c4,8,7.2,13,9.7,15c-1.6,0.6-3.2,1.1-4.9,1.3c-1.7,0.2-3.5,0.4-5.6,0.4c-1.2-3-3.6-7.9-7.3-14.8c-0.3,0.1-0.8,0.1-1.5,0.1
									c-1.8,0-3.7-0.2-5.5-0.5c0.1,7.5,0.5,12.4,1.3,14.7c-1.6,0.3-3.2,0.5-4.7,0.5C2597.4,2320.2,2595.7,2320,2593.8,2319.7z
									M2615.1,2297.4c1.1-1.6,1.7-3.3,1.7-5.2c0-6-3.3-8.9-9.9-8.9c-1.5,0-2.8,0.2-4,0.6c-0.3,3.7-0.4,8.8-0.6,15.3
									c3.7,1.2,6.5,1.8,8.3,1.8C2612.5,2300.1,2613.9,2298.9,2615.1,2297.4z"
						/>
						<path
							d="M2665.1,2319.7c0.3-2.7,0.5-6.3,0.6-10.8c0.2-4.5,0.2-8.8,0.2-12.7c0-9.5-0.4-15.6-1.3-18.1c1.6-0.3,3.2-0.5,4.9-0.5
									c1.4,0,3.1,0.2,5,0.5c-0.4,4.6-0.7,10.6-0.8,18c3.2,0.2,6.8,0.2,10.7,0.2c1.8,0,3.8-0.1,6-0.2c0-9.6-0.4-15.7-1.3-18.1
									c1.6-0.3,3.2-0.5,4.9-0.5c1.5,0,3.2,0.2,5.1,0.5c-0.3,2.7-0.5,6.3-0.6,10.8c-0.2,4.5-0.2,8.8-0.2,12.7c0,9.7,0.4,15.8,1.3,18.1
									c-1.6,0.3-3.2,0.5-4.8,0.5c-1.4,0-3.1-0.2-5.1-0.5c0.4-4.5,0.7-10.5,0.8-18.1c-4.1-0.1-7.3-0.2-9.7-0.2c-2.2,0-4.5,0.1-7,0.2
									c0,9.6,0.4,15.7,1.3,18.1c-1.6,0.3-3.2,0.5-4.7,0.5C2668.7,2320.2,2667,2320,2665.1,2319.7z"
						/>
						<path
							d="M2740.9,2319.6c-0.8-2.3-2-5.7-3.6-10c-2.7-0.2-5.8-0.2-9.2-0.2c-3.7,0-6.3,0.1-7.7,0.3c-1.5,4-2.7,7.3-3.6,10
									c-1.1,0.3-2.1,0.5-2.9,0.5c-1.2,0-2.3-0.2-3.4-0.5c1.5-2.6,4.1-8.8,7.8-18.6c3.7-9.8,6.5-17.5,8.4-23c1.6-0.3,2.9-0.4,4-0.4
									c1.2,0,2.4,0.1,3.7,0.4c1.8,5.5,4.6,13.2,8.4,23c3.7,9.8,6.3,16,7.8,18.6c-1.5,0.4-3.1,0.6-4.8,0.6
									C2744.3,2320.1,2742.7,2319.9,2740.9,2319.6z M2735.4,2304.2c-2.8-7.5-5-13.1-6.6-16.8c-1.5,3.4-3.7,9-6.5,16.7
									c2.6,0.2,5.5,0.2,8.7,0.2C2733.1,2304.3,2734.6,2304.3,2735.4,2304.2z"
						/>
						<path
							d="M2761.9,2319.7c0.2-2.6,0.4-6.2,0.6-10.9s0.3-8.9,0.3-12.7c0-8.8-0.4-14.3-1.3-16.4c1.6-0.6,3.8-1.1,6.6-1.5
									c2.8-0.4,5.6-0.6,8.5-0.6c5.4,0,9.5,1.2,12.4,3.6c2.9,2.4,4.3,5.8,4.3,10.3c0,2.6-0.7,5-2.1,7c-1.4,2.1-3.3,3.7-5.8,4.9
									c4,8,7.2,13,9.7,15c-1.6,0.6-3.2,1.1-4.9,1.3c-1.7,0.2-3.5,0.4-5.6,0.4c-1.2-3-3.6-7.9-7.3-14.8c-0.3,0.1-0.8,0.1-1.5,0.1
									c-1.8,0-3.7-0.2-5.5-0.5c0.1,7.5,0.5,12.4,1.3,14.7c-1.6,0.3-3.2,0.5-4.7,0.5C2765.5,2320.2,2763.8,2320,2761.9,2319.7z
									M2783.2,2297.4c1.1-1.6,1.7-3.3,1.7-5.2c0-6-3.3-8.9-9.9-8.9c-1.5,0-2.8,0.2-4,0.6c-0.3,3.7-0.4,8.8-0.6,15.3
									c3.7,1.2,6.5,1.8,8.3,1.8C2780.6,2300.1,2782,2298.9,2783.2,2297.4z"
						/>
						<path
							d="M2833.8,2319.6c-0.8-2.3-2-5.7-3.6-10c-2.7-0.2-5.8-0.2-9.2-0.2c-3.7,0-6.3,0.1-7.7,0.3c-1.5,4-2.7,7.3-3.6,10
									c-1.1,0.3-2.1,0.5-2.9,0.5c-1.2,0-2.3-0.2-3.4-0.5c1.5-2.6,4.1-8.8,7.8-18.6c3.7-9.8,6.5-17.5,8.4-23c1.6-0.3,2.9-0.4,4-0.4
									c1.2,0,2.4,0.1,3.7,0.4c1.8,5.5,4.6,13.2,8.4,23c3.7,9.8,6.3,16,7.8,18.6c-1.5,0.4-3.1,0.6-4.8,0.6
									C2837.2,2320.1,2835.6,2319.9,2833.8,2319.6z M2828.3,2304.2c-2.8-7.5-5-13.1-6.6-16.8c-1.5,3.4-3.7,9-6.5,16.7
									c2.6,0.2,5.5,0.2,8.7,0.2C2826,2304.3,2827.5,2304.3,2828.3,2304.2z"
						/>
						<path
							d="M2861.4,2319.8c-2.5-0.2-4.7-0.5-6.6-0.9c0.3-2.5,0.5-6,0.6-10.4c0.2-4.4,0.2-8.6,0.2-12.5c0-8.7-0.4-14.1-1.3-16.2
									c1.7-0.6,4.1-1.1,7-1.5c2.9-0.4,5.9-0.6,9-0.6c7.1,0,12.5,1.8,16.3,5.5c3.8,3.6,5.7,8.8,5.7,15.6c0,4.2-1,7.9-3,11.2
									c-2,3.2-4.8,5.8-8.3,7.6c-3.6,1.8-7.7,2.7-12.3,2.7C2866.4,2320.2,2864,2320.1,2861.4,2319.8z M2878.2,2312.8
									c1.7-1.6,3.1-3.5,4.1-5.8c1-2.3,1.5-4.6,1.5-7c0-11.2-5.1-16.8-15.2-16.8c-1.6,0-3.2,0.2-4.7,0.7c-0.4,5.9-0.6,11.8-0.6,17.8
									c0,4.8,0.1,8.9,0.4,12.3c3.8,1.1,6.7,1.6,8.7,1.6C2874.5,2315.4,2876.4,2314.4,2878.2,2312.8z"
						/>
					</g>
					<g className="level_one location" id="GONDOR">
						<rect x="2460.568" y="1997.929" width="159.771" height="43.955" transform="matrix(-0.887986, -0.45987, 0.470569, -0.882446, 2728.146729, 4611.14502)"></rect>
						<rect x="2460.567" y="1997.93" width="159.771" height="43.955" transform="matrix(-0.916839, -0.399258, 0.410305, -0.912028, 3043.786133, 4571.975586)"></rect>
						<rect x="325.428" y="1312.351" width="21.131" height="28.872" transform="matrix(-0.916839, -0.399258, 0.410305, -0.912028, 1385.754395, 3098.868652)"></rect>
						<rect x="325.428" y="1312.351" width="21.131" height="28.872" transform="matrix(-0.884191, -0.467125, 0.477778, -0.878563, 1308.458984, 3079.731934)"></rect>
						<rect x="325.428" y="1312.351" width="21.131" height="28.872" transform="matrix(-0.916839, -0.399258, 0.410305, -0.912028, 1435.535645, 3095.126709)"></rect>
						<rect x="1463.257" y="1997.934" width="95.013" height="43.955" transform="matrix(-0.947939, -0.318452, 0.329874, -0.944102, 2510.124512, 4176.434082)"></rect>
						<rect x="1221.713" y="2138.138" width="79.329" height="47.039" transform="matrix(-0.947939, -0.318452, 0.329874, -0.944102, 2338.989502, 4264.791992)"></rect>
						<rect x="949.898" y="2152.761" width="61.679" height="47.361" transform="matrix(-0.994027, -0.109135, 0.121113, -0.992712, 2676.916016, 4112.453613)"></rect>
						<rect x="949.026" y="949.748" width="61.623" height="20.894" transform="matrix(0.988406, 0.151836, -0.163745, 0.986576, 1105.199463, 727.49646)"></rect>
						<g>
							<path
								d="M1990.6,1864.1c-1.3,1-2.7,1.6-4.2,1.9c-1.5,0.3-3.2,0.4-5.2,0.2c-2.6-0.3-4.7-1-6.1-2c-1.4-1-2.2-2.6-2.5-4.8l-0.6-5.5
										c-0.3-2.7-0.8-4.6-1.5-6c-0.7-1.3-2-2.1-4-2.3l-4.4-0.5l-1.4,12.2c-0.2,1.3-0.1,2.3,0,3c0.2,0.6,0.6,1.1,1.3,1.4
										c0.7,0.3,1.8,0.6,3.3,0.8l-0.1,1.1c-2.4-0.4-5.9-0.8-10.2-1.3c-4.8-0.5-8.4-0.9-10.8-1l0.1-1.1c1.5,0.1,2.6,0.1,3.3-0.1
										c0.7-0.1,1.2-0.5,1.6-1.1c0.3-0.6,0.6-1.5,0.7-2.9l2.9-26.4c0.2-1.3,0.1-2.3,0-3s-0.6-1.1-1.3-1.4c-0.7-0.3-1.8-0.6-3.2-0.8
										l0.1-1.1c2.3,0.4,5.7,0.8,10.3,1.3l7.6,0.8c0.9,0.1,2,0.2,3.1,0.3c6.3,0.7,10.9,2,13.8,4c3,1.9,4.3,4.4,4,7.4
										c-0.3,2.5-1.7,4.6-4.3,6.3c-2.6,1.7-6.6,2.4-12.1,2.3c4,0.7,7,1.8,8.9,3.1s3,3.1,3.3,5.4l1,5.7c0.2,1.5,0.5,2.5,0.9,3.1
										s1,1,1.9,1.1c0.5,0,1,0,1.4-0.2c0.4-0.1,0.9-0.4,1.4-0.8L1990.6,1864.1z M1965.7,1827c-0.6,0.2-1,0.6-1.3,1.2s-0.5,1.6-0.7,2.8
										l-1.5,13.1l2.9,0.3c3.7,0.4,6.4-0.1,7.9-1.5c1.6-1.4,2.5-3.5,2.8-6.3c0.3-2.9-0.1-5.2-1.2-6.8c-1.1-1.6-3.2-2.6-6.2-3
										C1967.2,1826.8,1966.3,1826.8,1965.7,1827z"
							/>
						</g>
						<g>
							<path
								d="M1737.9,1773.1c2.5,4.2,2.7,9,0.6,14.3c-1.3,3.5-3.4,6.1-6.1,8c-2.8,1.9-6,2.9-9.9,3.1c-3.8,0.2-8-0.7-12.6-2.4l-3.4-1.4
										c-2.1-0.9-3.6-1.5-4.6-1.9c-3.8-1.5-6.6-2.5-8.5-3.2l0.4-1c1.2,0.4,2.2,0.6,2.8,0.7c0.6,0,1.2-0.2,1.6-0.6
										c0.4-0.5,0.8-1.3,1.3-2.5l9.1-23.6c0.5-1.2,0.7-2.1,0.7-2.7c0-0.6-0.2-1.1-0.7-1.5c-0.5-0.4-1.3-0.9-2.5-1.4l0.4-1
										c1.5,0.7,3.7,1.6,6.6,2.7l1.7,0.7l2.2,0.8c3,1.1,5.2,1.9,6.5,2.4C1730.6,1765.4,1735.4,1768.9,1737.9,1773.1z M1720.3,1762.8
										c-0.7,0-1.2,0.2-1.6,0.7c-0.4,0.5-0.9,1.3-1.3,2.4l-9.2,23.8c-0.4,1.1-0.7,2-0.7,2.7c0,0.7,0.2,1.2,0.7,1.6
										c0.5,0.4,1.3,0.9,2.5,1.3c4.4,1.7,8.1,1.6,11-0.3c2.9-2,5.4-5.6,7.5-11.1s2.7-9.8,1.7-13.1c-0.9-3.3-3.6-5.8-8-7.5
										C1721.9,1763,1721,1762.8,1720.3,1762.8z"
							/>
						</g>
						<g>
							<g>
								<path
									d="M1877.1,1817.8c-0.7-3.5-2.3-6.5-4.9-9.2c-0.8-0.8-1.6-1.5-2.5-2.2c-0.6,1.4-1.4,2.8-2.3,4c0.4,1.5,0.6,3,0.6,4.8
											c0,3.1-0.7,6.4-2.1,10c-1.4,3.5-3,6.5-5,8.9c-2,2.4-4.1,4-6.3,4.9c-2.3,0.9-4.5,0.9-6.7,0c-0.6-0.2-1.2-0.5-1.7-0.9
											c-0.3,0.3-0.5,0.6-0.8,0.9c0.6,0.3,1.2,0.5,1.8,0.8c4.1,1.6,8,2.2,11.9,1.8c3.8-0.4,7.2-1.7,10.1-3.9c2.9-2.2,5.1-5.2,6.6-9
											C1877.3,1824.9,1877.7,1821.2,1877.1,1817.8z"
								/>
							</g>
							<g>
								<path
									d="M1838,1834.1c1.1-1.8,2.3-3.6,3.5-5.4c-0.1-0.6-0.1-1.3-0.1-2c0-3.1,0.7-6.4,2.1-10c1.4-3.5,3-6.5,5-8.9
											c2-2.4,4.1-4,6.3-4.9c2.2-0.9,4.4-0.9,6.6-0.1c0.1-0.3,0.3-0.6,0.4-0.9c-4-1.5-7.8-2.1-11.6-1.7c-3.8,0.4-7.2,1.7-10.1,3.9
											c-2.9,2.2-5.1,5.2-6.6,9c-1.5,3.9-1.9,7.5-1.2,11c0.7,3.5,2.3,6.5,4.9,9.2C1837.4,1833.6,1837.7,1833.8,1838,1834.1z"
								/>
							</g>
						</g>
						<g>
							<path
								d="M1384.4,1620.1c1.1,1.2,2.1,2.5,2.9,4.1c0.2,0.5,0.5,0.7,0.8,0.9c0.3,0.1,0.7,0,1.1-0.4c0.4-0.4,0.9-1.1,1.4-2l1.1,0.5
										c-1.1,2.1-2.9,6-5.4,11.6l-1.1-0.5c0.7-2.3,1.2-4.2,1.3-5.8c0.1-1.6-0.2-3.1-0.9-4.5c-0.4-1.1-1.1-2.1-2-3
										c-0.9-0.9-2-1.6-3.1-2.2c-2.2-1-4.4-1-6.6-0.2c-2.2,0.9-4.1,2.4-6,4.6c-1.8,2.2-3.4,4.8-4.7,7.8c-5,11.2-4.5,18.1,1.6,20.8
										c1.4,0.6,2.7,0.8,3.8,0.5c0.5-0.1,1-0.3,1.3-0.6c0.3-0.3,0.7-0.8,0.9-1.4l2.1-4.7c0.7-1.5,1-2.7,1.1-3.5c0.1-0.8-0.2-1.5-0.7-2
										c-0.5-0.5-1.5-1.2-2.8-1.9l0.4-1c1.9,1,4.6,2.2,8,3.8c2.8,1.3,5,2.2,6.4,2.7l-0.4,1c-0.7-0.2-1.2-0.3-1.5-0.2
										c-0.4,0.1-0.7,0.3-1.1,0.8c-0.4,0.5-0.8,1.4-1.4,2.6l-4.3,9.6l-0.9-0.4c0.3-0.7,0.4-1.4,0.4-2.1c0-0.7-0.2-1.1-0.6-1.3
										c-0.5-0.2-1.5-0.3-3-0.1c-3.5,0.4-6.4,0-8.9-1.1c-5.5-2.4-9-5.8-10.5-10.2c-1.5-4.3-1.1-9.3,1.4-14.8c1.6-3.6,3.8-6.5,6.5-8.5
										s5.7-3.3,9-3.7c3.3-0.4,6.6,0.2,9.9,1.7C1381.8,1618,1383.3,1619,1384.4,1620.1z"
							/>
						</g>
						<g>
							<g>
								<path
									d="M1617.3,1726.5c-2.6-1-4.6-1.9-6.1-2.6l-0.4,1c1.6,0.8,2.7,1.6,3.3,2.4c0.6,0.7,0.8,1.6,0.7,2.7c-0.2,1-0.6,2.6-1.4,4.6
											l-6.5,16.3l-12.1-33.5c-1.4-0.4-3.1-1-4.9-1.8c-2.1-0.8-3.7-1.5-4.9-2.1l-0.4,1c1.2,0.6,2.1,1.1,2.6,1.5s0.7,1,0.7,1.7
											c0,0.7-0.3,1.7-0.8,3l-6.6,16.8c0.4,0.3,0.8,0.6,1.1,1.1l5.7-14.5l11.3,32.7c2.4-0.3,4.9,0,7.3,0.9l9.1-23
											c0.7-1.8,1.3-3.1,1.9-3.9c0.6-0.8,1.2-1.3,2-1.5c0.8-0.2,1.8-0.1,3.1,0.2l0.4-1C1621.1,1728,1619.4,1727.4,1617.3,1726.5z"
								/>
							</g>
						</g>
						<g>
							<path
								d="M1492.1,1676.8c2,2.9,3,6,3,9.3c0.1,3.3-0.9,6.6-2.9,9.8c-2,3.2-4.5,5.5-7.5,7.1c-3.1,1.5-6.4,2.1-10,1.8
										c-3.6-0.3-7.1-1.6-10.7-3.7c-3.6-2.2-6.4-4.8-8.4-7.7c-2-2.9-3-6-3-9.3c-0.1-3.3,0.9-6.6,2.9-9.8c2-3.2,4.5-5.5,7.5-7.1
										s6.4-2.1,10-1.8c3.6,0.3,7.1,1.6,10.7,3.7C1487.3,1671.3,1490.1,1673.8,1492.1,1676.8z M1476.8,1668.6c-2.2,0.4-4.4,1.5-6.6,3.4
										c-2.2,1.8-4.2,4.3-6,7.2c-1.9,3-3,5.9-3.6,8.8s-0.4,5.3,0.3,7.5c0.7,2.2,2,3.8,3.8,4.9c1.9,1.2,3.9,1.5,6.2,1.1
										c2.2-0.4,4.4-1.5,6.6-3.4c2.2-1.8,4.2-4.3,6-7.2c1.9-3,3-5.9,3.6-8.8c0.5-2.8,0.4-5.3-0.3-7.5c-0.7-2.2-2-3.8-3.8-4.9
										C1481.1,1668.5,1479.1,1668.2,1476.8,1668.6z"
							/>
						</g>
					</g>
					<g className="level_one location" id="RHOVANION">
						<rect x="1239.207" y="3029.426" width="80.465" height="66.644" transform="matrix(0.281423, 0.959584, -0.962974, 0.269864, 4492.748047, -1500.871338)"></rect>
						<rect x="5331.535" y="3029.427" width="346.19" height="66.644" transform="matrix(0.363396, 0.931635, -0.936012, 0.352173, 2854.401855, -5415.276367)"></rect>
						<rect x="5331.548" y="3359.692" width="346.19" height="73.909" transform="matrix(0.680107, 0.733113, -0.741306, 0.671275, 933.375122, -5241.495117)"></rect>
						<g>
							<path
								d="M1891.3,587.9c-2-0.9-3.6-2-4.8-3.5c-1.2-1.4-2.2-3.3-3.1-5.6c-1.1-3-1.4-5.7-0.9-7.8c0.5-2.2,2.1-4,4.6-5.5l6.5-3.9
										c3.2-1.9,5.4-3.6,6.7-5.2c1.3-1.6,1.5-3.5,0.7-5.7l-1.8-5.1l-16.2,5.8c-1.8,0.6-3,1.2-3.7,1.8c-0.7,0.6-1,1.3-1,2.2
										c0,0.9,0.3,2.2,0.8,4.1l-1.4,0.5c-0.9-2.9-2.2-6.9-4.1-12c-2-5.6-3.6-9.8-4.7-12.4l1.4-0.5c0.7,1.7,1.4,2.9,1.9,3.6
										s1.3,1.1,2.2,1.1c0.9,0,2.2-0.3,4-1l34.9-12.5c1.8-0.6,3-1.2,3.7-1.8c0.7-0.6,1-1.3,1-2.2c0-0.9-0.3-2.3-0.8-4l1.4-0.5
										c0.8,2.7,2.2,6.8,4.1,12.1l3.2,8.7c0.4,1,0.9,2.2,1.4,3.6c2.6,7.3,3.5,13.1,2.7,17.5c-0.8,4.4-3.2,7.3-7.1,8.7
										c-3.3,1.2-6.7,0.9-10.3-0.9c-3.5-1.8-6.7-5.8-9.7-11.9c1.3,4.9,1.7,8.7,1.1,11.6c-0.6,2.8-2.3,5.1-4.9,6.8l-6.6,4.4
										c-1.7,1.1-2.9,2.1-3.4,2.9c-0.6,0.8-0.6,1.7-0.3,2.7c0.3,0.6,0.6,1.1,1,1.5c0.4,0.4,1,0.7,1.7,1.1L1891.3,587.9z M1924.1,538.6
										c-0.6-0.5-1.3-0.8-2.3-0.7c-0.9,0-2.3,0.4-3.9,1l-17.3,6.2l1.2,3.4c1.5,4.3,3.6,6.9,6.2,7.8s5.8,0.6,9.5-0.7
										c3.8-1.4,6.5-3.2,7.9-5.4c1.4-2.2,1.5-5.1,0.3-8.6C1925.2,540.1,1924.7,539.1,1924.1,538.6z"
							/>
						</g>
						<g>
							<path
								d="M1970.5,677.1c-0.6-0.8-1.4-1.2-2.3-1.2c-0.9,0-2.3,0.3-4,1l-34.8,13.1c-1.8,0.7-3,1.3-3.7,1.9c-0.7,0.6-1,1.4-0.9,2.4
										c0,1,0.4,2.5,1.1,4.5l-1.4,0.5c-0.9-2.9-2.5-7.4-4.8-13.4c-2.2-5.9-4-10.5-5.4-13.7l1.4-0.5c0.8,1.9,1.5,3.2,2.2,4
										c0.6,0.8,1.4,1.2,2.3,1.2c0.9,0,2.3-0.3,4-1l16.8-6.3l-8.8-23.2l-16.8,6.3c-1.8,0.7-3,1.3-3.7,1.9c-0.7,0.6-1,1.4-0.9,2.4
										c0.1,1,0.4,2.5,1.1,4.5l-1.4,0.5c-1-3.2-2.6-7.7-4.8-13.3c-2.4-6.3-4.2-10.9-5.4-13.8l1.4-0.5c0.8,1.9,1.5,3.2,2.2,4
										c0.6,0.8,1.4,1.2,2.3,1.2c0.9,0,2.3-0.3,4-1l34.8-13.1c1.8-0.7,3-1.3,3.7-1.9c0.7-0.6,1-1.4,0.9-2.5s-0.4-2.5-1-4.5l1.4-0.5
										c1,3,2.7,7.7,5,13.9c2.1,5.7,3.9,10.1,5.2,13.2l-1.4,0.5c-0.8-2-1.6-3.3-2.2-4.1s-1.4-1.2-2.3-1.2c-0.9,0-2.3,0.3-4,1l-16.6,6.2
										l8.8,23.2l16.6-6.2c1.8-0.7,3-1.3,3.7-1.9c0.7-0.6,1-1.4,0.9-2.5s-0.4-2.5-1-4.5l1.4-0.5c1.1,3.3,2.8,7.9,5,13.9
										c2.3,6,4,10.4,5.2,13.3l-1.4,0.5C1971.8,679.3,1971.1,677.9,1970.5,677.1z"
							/>
						</g>
						<g>
							<path
								d="M2000.6,759c-0.3,5.3-1.9,9.9-4.8,13.8c-2.9,3.9-7,6.9-12.2,8.9c-5.1,1.9-10.2,2.4-15.1,1.3c-4.9-1-9.4-3.4-13.3-7.2
										c-3.9-3.7-7-8.5-9.2-14.3c-2.3-6-3.2-11.6-2.9-16.9c0.3-5.3,1.9-9.9,4.8-13.8c2.9-3.9,7-6.9,12.2-8.9c5.1-1.9,10.2-2.4,15.1-1.3
										c4.9,1,9.4,3.4,13.3,7.2c3.9,3.7,7,8.5,9.2,14.3C1999.9,748.1,2000.9,753.8,2000.6,759z M1990,735.2c-3-1.6-6.6-2.3-10.8-2.2
										c-4.2,0.1-8.7,1.1-13.6,2.9c-4.9,1.8-8.9,4.2-12,7c-3.2,2.8-5.3,5.9-6.4,9.1c-1.1,3.2-1.1,6.3,0.1,9.3c1.2,3.1,3.2,5.5,6.2,7.1
										c3,1.6,6.6,2.3,10.8,2.2c4.2-0.1,8.7-1.1,13.6-2.9c4.9-1.8,8.9-4.2,12-7c3.2-2.8,5.3-5.9,6.4-9.1c1.1-3.2,1.1-6.3-0.1-9.3
										C1995.1,739.1,1993,736.8,1990,735.2z"
							/>
						</g>
						<g>
							<path
								d="M2214.7,1108c2.3,4.8,3.2,9.5,2.7,14.2c-0.5,4.7-2.6,9-6.1,13c-3.5,3.9-7.6,6.4-12.3,7.5c-4.8,1.1-9.7,0.9-14.9-0.8
										c-5.2-1.7-10.2-4.6-15-8.9c-4.9-4.4-8.5-9-10.8-13.8c-2.3-4.8-3.2-9.5-2.7-14.2c0.5-4.7,2.6-9,6.1-13c3.5-3.9,7.6-6.4,12.3-7.5
										c4.8-1.1,9.7-0.9,14.9,0.8c5.2,1.7,10.2,4.6,15,8.9C2208.7,1098.6,2212.4,1103.2,2214.7,1108z M2193.9,1091.3
										c-3.3-0.2-6.8,0.7-10.4,2.6s-7,4.7-10.2,8.3c-3.3,3.7-5.6,7.4-6.9,11.3c-1.3,3.8-1.6,7.4-1,10.7c0.7,3.3,2.2,6.1,4.7,8.3
										c2.5,2.3,5.5,3.5,8.8,3.7s6.8-0.7,10.4-2.6c3.6-1.9,7-4.7,10.2-8.3c3.3-3.7,5.6-7.4,6.9-11.3s1.6-7.4,1-10.7
										c-0.7-3.3-2.2-6.1-4.7-8.3C2200.2,1092.7,2197.2,1091.5,2193.9,1091.3z"
							/>
						</g>
						<g>
							<path
								d="M2039.1,857.1l-1.4,0.6c-0.9-1.2-2.3-2.1-4.2-2.8c-1.8-0.7-4.2-1.1-6.9-1.2l-46-1c-0.2-0.6-0.5-1.5-1.1-2.8
										c-0.5-1.2-0.9-2.1-1.2-2.7l34.8-37.6c1.2-1.3,2-2.5,2.3-3.7c0.3-1.2,0.3-2.2,0-3.1l1.4-0.6c1.1,3.2,2.7,7.3,4.7,12.2
										c2.2,5.5,3.9,9.5,5.1,12l-1.4,0.6c-0.9-2-1.7-3.3-2.4-4c-0.7-0.7-1.5-0.9-2.4-0.5c-0.8,0.3-1.7,1.1-2.8,2.3l-25.1,26.4l26.7,0.6
										c4.1,0.1,7.2-0.3,9.3-1.1c1.8-0.7,2.9-1.8,3.3-3.3c0.4-1.5,0.2-3.3-0.6-5.5l1.4-0.6c1.3,3.7,2.6,7,3.8,10
										C2037.4,853.6,2038.3,855.6,2039.1,857.1z"
							/>
						</g>
						<g>
							<path
								d="M2032.7,951.7c-0.2,1.3-0.1,2.4,0.3,3.3l-1.3,0.6c-1.5-3.4-3.5-7.8-6.1-12.9c-2.8-5.7-5-10-6.5-12.7l1.3-0.6
										c1.1,2.1,2.1,3.5,2.9,4.2c0.8,0.7,1.6,0.9,2.4,0.5c0.8-0.4,1.7-1.2,2.7-2.6l8.4-10.4l-9-18.4l-5.7,0c-4,0-6.8,0.4-8.5,1.2
										c-1.6,0.8-2.5,2-2.5,3.6s0.5,3.6,1.6,6.1l-1.3,0.6c-1.7-3.9-3.4-7.4-4.9-10.6c-1.3-2.6-2.4-4.7-3.3-6.3l1.3-0.6
										c1.7,2.6,5.3,3.8,10.5,3.9l48.3,0.3c0.3,0.6,0.7,1.6,1.4,3c0.7,1.4,1.1,2.3,1.5,2.8l-31.6,40.8
										C2033.6,949.1,2032.9,950.4,2032.7,951.7z M2037.9,919.9l13.9-17.1l-22.2,0L2037.9,919.9z"
							/>
						</g>
						<g>
							<path
								d="M2122.7,995.6c-1.3-1.4-2.4-2.2-3.4-2.6c-1-0.4-2.1-0.3-3.4,0.2s-3,1.5-5.1,3l-35.2,24.7l-1.1-1.7l-1.4-1.8l13.8-54.1
										l-24.2,17c-2.5,1.7-4.2,3.2-5.2,4.2c-1,1.1-1.4,2.3-1.3,3.6s0.8,3,2,5.2l-1.3,0.9c-1.1-1.9-2.8-4.4-5-7.6
										c-1.8-2.5-3.2-4.5-4.4-5.8l1.3-0.9c1.3,1.4,2.4,2.2,3.4,2.6c1,0.4,2.1,0.3,3.4-0.2c1.3-0.5,3-1.5,5.1-3l28.4-19.9
										c1.6-1.1,2.7-2.1,3.2-2.8c0.5-0.8,0.7-1.6,0.4-2.5c-0.2-0.9-0.9-2.1-1.8-3.7l1.3-0.9c0.9,1.6,2.2,3.6,4,6.1
										c1.6,2.3,3.1,4.2,4.3,5.8l-11.4,47.7l19.7-13.8c2.5-1.7,4.2-3.2,5.2-4.2c1-1.1,1.4-2.3,1.3-3.6c-0.1-1.3-0.8-3-2-5.2l1.3-0.9
										c1.1,1.9,2.8,4.4,5,7.6c1.8,2.6,3.3,4.5,4.4,5.8L2122.7,995.6z"
							/>
						</g>
						<g>
							<path
								d="M2295.4,1180.7c-1.6-1.1-3-1.7-4-1.8c-1.1-0.1-2.2,0.2-3.3,1c-1.1,0.8-2.5,2.2-4.2,4.1l-27.8,32l-1.5-1.4l-1.8-1.5
										l-1.3-56.4l-19.1,22c-2,2.3-3.3,4-3.9,5.3c-0.6,1.3-0.7,2.5-0.3,3.8c0.5,1.2,1.6,2.8,3.4,4.7l-1,1.1c-1.6-1.6-3.9-3.7-7-6.4
										c-2.4-2.1-4.4-3.7-5.9-4.8l1-1.1c1.6,1.1,3,1.7,4,1.8c1.1,0.1,2.2-0.2,3.3-1c1.1-0.8,2.5-2.2,4.2-4.1l22.4-25.8
										c1.3-1.5,2.1-2.6,2.4-3.5c0.3-0.9,0.2-1.7-0.2-2.5c-0.5-0.8-1.4-1.9-2.8-3.2l1-1.1c1.3,1.3,3.2,3.1,5.6,5.2
										c2.2,1.9,4.1,3.5,5.8,4.8l1.8,49.5l15.6-18c2-2.3,3.3-4,3.9-5.3c0.6-1.3,0.7-2.5,0.3-3.8s-1.6-2.8-3.4-4.7l1-1.1
										c1.6,1.6,3.9,3.7,7,6.4c2.5,2.1,4.4,3.7,5.9,4.8L2295.4,1180.7z"
							/>
						</g>
						<g>
							<path
								d="M2156.7,1042.9c-1-1.1-1.9-1.8-2.5-2.1c-0.7-0.3-1.4-0.3-2.2,0.1c-0.8,0.4-2,1.2-3.4,2.4l-28.9,24.3
										c-1.5,1.2-2.5,2.2-3,3c-0.5,0.7-0.7,1.5-0.5,2.2c0.2,0.7,0.7,1.6,1.6,2.8l-1.2,1c-1.4-1.9-3.5-4.5-6.2-7.7
										c-3-3.6-5.3-6.2-6.8-7.8l1.2-1c1,1,1.8,1.7,2.5,2c0.7,0.3,1.4,0.3,2.2-0.1c0.8-0.4,2-1.2,3.4-2.4l28.9-24.3c1.5-1.2,2.5-2.2,3-3
										c0.5-0.7,0.7-1.5,0.5-2.2c-0.2-0.7-0.7-1.7-1.6-2.8l1.2-1c1.3,1.8,3.5,4.5,6.5,8.1c2.7,3.2,4.9,5.7,6.5,7.4L2156.7,1042.9z"
							/>
						</g>
					</g>
					<g className="level_one location" id="RHUN">
						<rect x="7071.064" y="4405.286" width="459.145" height="96.911" transform="matrix(0.333382, 0.942792, -0.946808, 0.322025, 4773.341309, -7913.019531)"></rect>
						<rect x="7195.103" y="5137.047" width="467.199" height="113.009" transform="matrix(0.002488, 0.999997, -1.000027, -0.009558, 8245.233398, -6539.92627)"></rect>
						<g>
							<path
								d="M2912,267.5c-2.6-0.6-4.8-1.6-6.4-3c-1.7-1.4-3.1-3.3-4.3-5.8c-1.6-3.3-2-6.3-1.4-8.9c0.6-2.6,2.6-5.1,5.9-7.4l8.5-5.9
										c4.1-2.9,7-5.3,8.6-7.4c1.6-2.1,1.9-4.3,0.7-6.8l-2.6-5.6l-21.2,10c-2.3,1.1-4,2.1-4.8,2.9c-0.9,0.8-1.3,1.7-1.3,2.8
										c0,1,0.5,2.5,1.2,4.5l-1.8,0.9c-1.3-3.2-3.2-7.5-5.8-13.1c-2.9-6.1-5.1-10.6-6.7-13.4l1.8-0.9c1,1.8,1.9,3.1,2.7,3.8
										c0.8,0.7,1.8,1,3,0.8c1.2-0.2,3-0.8,5.3-1.9l45.7-21.6c2.3-1.1,3.9-2.1,4.8-2.9c0.9-0.8,1.3-1.7,1.3-2.8s-0.5-2.6-1.2-4.5
										l1.8-0.9c1.2,3,3.1,7.4,5.9,13.1l4.6,9.5c0.6,1.1,1.3,2.4,2,3.9c3.7,7.9,5.1,14.4,4.2,19.7c-0.9,5.3-3.9,9.1-9.1,11.6
										c-4.4,2.1-8.9,2.4-13.6,1c-4.7-1.4-9.1-5.3-13.2-11.8c1.9,5.4,2.5,9.8,1.8,13.2c-0.7,3.4-2.8,6.4-6.3,8.9l-8.6,6.5
										c-2.2,1.6-3.7,3-4.4,4c-0.7,1.1-0.8,2.1-0.3,3.2c0.4,0.7,0.8,1.2,1.4,1.5c0.5,0.3,1.3,0.6,2.4,0.9L2912,267.5z M2953.6,203.7
										c-0.8-0.5-1.8-0.6-3-0.4c-1.2,0.2-3,0.9-5.2,1.9l-22.7,10.7l1.7,3.7c2.2,4.7,5.1,7.3,8.6,7.7c3.5,0.5,7.7-0.4,12.5-2.7
										c5-2.4,8.5-5,10.3-7.9c1.8-2.9,1.8-6.2,0-10C2955.1,205.2,2954.4,204.2,2953.6,203.7z"
							/>
						</g>
						<g>
							<path
								d="M3064,518.1c-0.8-1.2-1.8-1.9-3-2c-1.2-0.1-2.9,0.2-5.3,0.9l-45.3,14.8c-2.3,0.8-3.9,1.5-4.8,2.4c-0.9,0.8-1.3,2-1.2,3.4
										c0.1,1.4,0.5,3.6,1.3,6.6l-1.8,0.6c-0.9-3.4-2.5-8.6-4.8-15.6c-2.1-6.5-3.9-11.6-5.3-15.3l1.8-0.6c1.1,2.9,2,4.9,2.8,6.1
										c0.8,1.2,1.8,1.9,3,2c1.2,0.1,2.9-0.2,5.3-0.9l21.9-7.1l-12.8-39.3l-21.9,7.1c-2.3,0.8-3.9,1.5-4.8,2.4c-0.9,0.8-1.3,2-1.2,3.4
										c0.1,1.4,0.5,3.6,1.3,6.6l-1.8,0.6c-1-3.8-2.6-8.9-4.7-15.4c-2.3-6.9-4-12.1-5.3-15.4l1.8-0.6c1.1,2.9,2,4.9,2.8,6.1
										c0.8,1.2,1.8,1.9,3,2s2.9-0.2,5.3-0.9l45.3-14.8c2.3-0.8,3.9-1.5,4.8-2.4c0.9-0.8,1.3-2,1.2-3.4s-0.5-3.6-1.3-6.6l1.8-0.6
										c0.9,3.4,2.5,8.6,4.8,15.6c2.1,6.4,3.9,11.5,5.3,15.3l-1.8,0.6c-1.1-2.9-2-4.9-2.8-6.1c-0.8-1.2-1.8-1.9-3-2
										c-1.2-0.1-2.9,0.2-5.3,0.9l-21.5,7l12.8,39.3l21.5-7c2.3-0.8,3.9-1.5,4.8-2.4c0.9-0.8,1.3-2,1.2-3.4c-0.1-1.4-0.5-3.6-1.3-6.6
										l1.8-0.6c1,3.8,2.6,8.9,4.7,15.4c2.3,6.9,4,12.1,5.3,15.4l-1.8,0.6C3065.7,521.4,3064.8,519.4,3064,518.1z"
							/>
						</g>
						<g>
							<path
								d="M3103.3,782.7c-0.3-2.6-0.8-4.5-1.4-5.7c-0.6-1.2-1.6-2-2.8-2.4c-1.3-0.4-3.1-0.5-5.5-0.3l-28.4,1.9
										c-4.5,0.3-8.4,0.2-11.7-0.3c-3.3-0.5-6.1-1.5-8.6-2.9c-2.8-1.7-5.2-4.3-7-7.7c-1.8-3.5-2.9-7.5-3.2-12
										c-0.6-8.2,0.7-14.5,3.8-19.1c2.4-3.4,5.1-5.8,8.3-7.2c3.2-1.3,8-2.2,14.5-2.7l29.6-2c2.4-0.2,4.1-0.5,5.1-1c1-0.5,1.7-1.4,2-2.7
										s0.4-3.2,0.4-5.9l1.9-0.1c0,3.1,0.2,7.9,0.7,14.3c0.4,6,0.8,10.7,1.2,14.1l-1.9,0.1c-0.3-2.7-0.7-4.6-1.2-5.8s-1.2-2-2.3-2.4
										c-1.1-0.4-2.8-0.5-5.2-0.3l-31.2,2.1c-5,0.3-9.2,1-12.5,2.1c-3.3,1-5.8,2.9-7.7,5.6s-2.6,6.5-2.3,11.5c0.5,7.9,3,13.3,7.5,16.2
										c4.5,2.9,10.6,4.1,18.3,3.6l29.4-2c3.4-0.2,5.7-1.1,6.8-2.6c1.2-1.5,1.7-4.2,1.5-8.1l1.9-0.1c0,2.7,0.2,6.5,0.5,11.4
										c0.3,4.5,0.6,7.9,1,10.3L3103.3,782.7z M3116.2,757.2c-2,3.8-3.5,8.2-4.6,13l-1.4-0.5c0.5-4.5,1.2-9,2.1-13.4
										c0.9-4.4,1.9-7.9,2.9-10.5c-1.3-2-2.7-4.7-4.2-8.2c-1.4-3.4-2.7-6.9-3.7-10.4l1.3-0.6c1.7,4.7,3.8,8.7,6.3,12.3
										c2.5,3.5,5,6.1,7.6,7.7l0.1,2C3120.3,750.5,3118.1,753.3,3116.2,757.2z"
							/>
						</g>
						<g>
							<path
								d="M3089.9,1065.9c-0.1-2.3-0.4-4-1-5.1c-0.6-1.1-1.8-2-3.4-2.6c-1.6-0.6-4.1-1.1-7.4-1.5l-54-6.6l0.4-2.4l0.2-2.7
										l58.6-37.5l-37.2-4.5c-3.8-0.5-6.6-0.6-8.4-0.4c-1.8,0.2-3.2,0.9-4.2,2.2c-1,1.3-1.8,3.4-2.5,6.4l-1.9-0.2
										c0.5-2.6,1.1-6.2,1.6-10.9c0.5-3.7,0.7-6.7,0.8-8.9l1.9,0.2c0,2.3,0.4,4,1,5.1c0.6,1.1,1.8,2,3.4,2.6c1.6,0.6,4.1,1.1,7.4,1.5
										l43.6,5.3c2.5,0.3,4.2,0.3,5.4,0.1c1.1-0.2,2-0.9,2.5-1.9c0.5-1,1-2.6,1.4-4.8l1.9,0.2c-0.5,2.1-0.9,5.1-1.4,8.8
										c-0.4,3.4-0.7,6.3-0.8,8.8l-51,33.6l30.3,3.7c3.8,0.5,6.6,0.6,8.4,0.4c1.8-0.2,3.2-0.9,4.2-2.2c1-1.3,1.8-3.4,2.5-6.4l1.9,0.2
										c-0.5,2.6-1.1,6.3-1.6,10.9c-0.5,3.8-0.7,6.7-0.8,8.9L3089.9,1065.9z"
							/>
						</g>
					</g>
					<g className="regions level_one location" id="ERIADOR">
						<rect x="989.856" y="2955.411" width="64.273" height="65.022" transform="matrix(0.581513, 0.813537, -0.82054, 0.571716, 2432.511719, -2068.009277)"></rect>
						<rect x="1134.113" y="2955.409" width="73.639" height="65.022" transform="matrix(0.668162, 0.744016, -0.752062, 0.659202, 2120.220215, -2272.180908)"></rect>
						<rect x="3100.016" y="2955.406" width="201.287" height="65.022" transform="matrix(0.668162, 0.744016, -0.752062, 0.659202, 896.991943, -3628.385986)"></rect>
						<rect x="1236.22" y="2955.408" width="80.269" height="65.022" transform="matrix(0.912863, 0.408267, -0.41926, 0.907946, 1074.140747, -2389.529053)"></rect>
						<rect x="3193.759" y="2303.768" width="207.372" height="50.686" transform="matrix(0.864606, -0.50245, 0.492038, 0.870657, -2780.341064, 442.688934)"></rect>
						<g>
							<path
								d="M661.3,609.4c-2,0-3.8-0.3-5.4-1.1c-1.5-0.7-3.1-1.9-4.6-3.5c-2-2.2-3.1-4.3-3.4-6.3c-0.3-2.1,0.4-4.2,2.2-6.5l4.4-5.9
										c2.1-2.9,3.5-5.2,4.1-7c0.6-1.8,0.1-3.5-1.4-5.1l-3.4-3.6l-12.2,11.3c-1.3,1.2-2.2,2.2-2.6,3c-0.4,0.8-0.5,1.5-0.2,2.3
										c0.3,0.8,1,1.8,2.1,3.1l-1.1,1c-1.8-2.1-4.3-5-7.7-8.6c-3.7-4-6.5-6.9-8.3-8.7l1.1-1c1.2,1.2,2.2,1.9,2.9,2.3
										c0.7,0.4,1.5,0.4,2.3,0c0.8-0.4,1.9-1.2,3.2-2.4l26.3-24.4c1.3-1.2,2.2-2.2,2.6-3c0.4-0.8,0.5-1.5,0.1-2.3
										c-0.3-0.8-1-1.8-2.1-3.1l1.1-1c1.7,2,4.2,4.9,7.7,8.7l5.8,6.2c0.7,0.7,1.5,1.6,2.4,2.5c4.8,5.1,7.5,9.8,8.4,13.8
										c0.8,4.1-0.3,7.5-3.3,10.2c-2.5,2.3-5.6,3.4-9.3,3.2c-3.7-0.2-7.9-2.3-12.5-6.3c2.8,3.6,4.4,6.8,4.9,9.4c0.4,2.7-0.2,5.2-2,7.8
										l-4.3,6.4c-1.1,1.6-1.8,2.9-2,3.8c-0.2,0.9,0,1.7,0.7,2.4c0.4,0.4,0.9,0.7,1.4,0.9c0.5,0.2,1.1,0.2,1.9,0.3L661.3,609.4z
										M673.3,554.5c-0.7-0.2-1.4-0.2-2.2,0.3c-0.8,0.4-1.9,1.2-3.1,2.4l-13.1,12.1l2.2,2.4c2.8,3.1,5.5,4.4,8.1,4.2
										c2.6-0.3,5.3-1.7,8.1-4.3c2.9-2.7,4.6-5.3,5.1-7.7c0.5-2.5-0.4-4.9-2.7-7.4C674.8,555.4,674,554.7,673.3,554.5z"
							/>
						</g>
						<g>
							<path
								d="M1315.9,770c-0.2,2.1-0.8,4-1.7,5.7c-0.9,1.7-2.3,3.4-4.2,5.1c-2.5,2.3-4.8,3.7-7,4.2c-2.2,0.5-4.4,0-6.6-1.6l-5.8-3.9
										c-2.8-1.9-5.1-3.1-7-3.5s-3.7,0.2-5.5,2l-4.2,3.9l10.6,11.4c1.2,1.3,2.1,2.1,2.9,2.4c0.8,0.3,1.6,0.3,2.4-0.1
										c0.8-0.4,2-1.2,3.5-2.5l0.9,1c-2.4,2-5.7,5-9.9,8.9c-4.6,4.3-7.9,7.4-10,9.6l-0.9-1c1.4-1.4,2.3-2.5,2.8-3.3
										c0.5-0.8,0.6-1.6,0.3-2.4c-0.3-0.8-1-1.8-2.2-3.1l-22.8-24.7c-1.2-1.3-2.1-2.1-2.9-2.4c-0.8-0.3-1.6-0.3-2.4,0.1
										c-0.9,0.4-2,1.2-3.5,2.5l-0.9-1c2.3-1.9,5.6-4.9,9.9-8.9l7.1-6.7c0.8-0.8,1.8-1.8,2.9-2.8c5.9-5.5,11.1-8.9,15.4-10.1
										c4.4-1.3,7.8-0.5,10.4,2.3c2.2,2.4,3,5.5,2.4,9.3c-0.6,3.9-3.2,8.4-7.9,13.6c4.1-3.3,7.6-5.3,10.4-6.1c2.8-0.7,5.5-0.3,8,1.2
										l6.2,3.8c1.6,1,2.8,1.6,3.8,1.7c0.9,0.1,1.8-0.2,2.6-1c0.5-0.5,0.8-1,1-1.5c0.2-0.5,0.4-1.2,0.5-2L1315.9,770z M1259.4,763.4
										c-0.3,0.7-0.3,1.5,0,2.3c0.3,0.8,1.1,1.8,2.2,3l11.3,12.2l2.8-2.6c3.5-3.3,5.3-6.2,5.2-8.9c0-2.7-1.2-5.3-3.7-7.9
										c-2.5-2.7-5-4.2-7.6-4.4c-2.5-0.2-5.2,1-8.1,3.6C1260.5,761.8,1259.7,762.7,1259.4,763.4z"
							/>
						</g>
						<g>
							<path
								d="M569.5,504.5c-2.3-3.4-6.3-9.1-11.9-16.9c-7.7-10.7-13.5-18.6-17.4-23.8l1.1-0.8c1.2,1.6,2.2,2.6,3,3.2
										c0.8,0.6,1.6,0.8,2.4,0.6c0.8-0.2,1.9-0.8,3.3-1.8l27.4-19.7c1.4-1,2.3-1.9,2.7-2.6c0.4-0.7,0.5-1.5,0.2-2.5
										c-0.3-0.9-1-2.2-2.1-3.9l1.1-0.8c3.7,5.4,9.4,13.4,17,24.1c5.2,7.2,8.9,12.3,11.2,15.2c-3.3,1.9-6.1,3.6-8.5,5.4
										c-1.6,1.2-2.8,2.1-3.6,2.9l-1.2-1.7c3.4-3.6,5.2-6.9,5.4-9.9c0.2-3-0.9-6.3-3.4-9.8l-2.2-3c-1.1-1.5-2-2.6-2.7-3.1
										c-0.7-0.6-1.4-0.8-2.1-0.6c-0.7,0.1-1.7,0.6-2.8,1.5l-13.7,9.8l1.7,2.3c1.9,2.6,4,4,6.4,4.4c2.4,0.3,4.8,0,7.2-0.9l1.2,1.7
										c-1.7,1-3.4,2.1-5.3,3.5l-2.7,1.9c-1.9,1.3-4.4,3.4-7.5,6.1l-1.2-1.7c4.1-4.5,4.4-9.3,0.8-14.2l-1.7-2.3l-13.9,10
										c-1.2,0.8-1.9,1.6-2.3,2.2c-0.4,0.6-0.4,1.4-0.1,2.2c0.3,0.8,1,2,2.1,3.5l2.6,3.6c2.5,3.5,5.4,5.6,8.7,6.5
										c3.3,0.8,7.4,0.2,12.3-2l1.2,1.7c-1.3,0.7-2.9,1.8-4.8,3.1C574.1,500.4,571.4,502.5,569.5,504.5z"
							/>
						</g>
						<g>
							<path
								d="M1166.4,816.5c5.3,0,9.9,1.2,13.9,3.6c4,2.5,7,6.1,9,11c2,4.7,2.5,9.5,1.4,14.2c-1,4.7-3.4,9.1-7.1,13
										c-3.7,3.9-8.5,7.1-14.4,9.6c-6,2.5-11.7,3.8-17,3.8c-5.3,0-9.9-1.2-13.9-3.6c-4-2.5-7-6.1-9-11c-2-4.7-2.5-9.5-1.4-14.2
										c1-4.7,3.4-9.1,7.1-13c3.7-3.9,8.5-7.1,14.4-9.6C1155.4,817.9,1161.1,816.6,1166.4,816.5z M1142.5,828c-1.6,2.9-2.3,6.4-2.1,10.3
										c0.2,4,1.2,8.2,3,12.6c1.9,4.5,4.2,8.1,7.1,10.9c2.9,2.8,5.9,4.6,9.2,5.4s6.4,0.6,9.4-0.7c3.1-1.3,5.5-3.4,7.1-6.3
										c1.6-2.9,2.3-6.4,2.1-10.3c-0.2-4-1.2-8.2-3-12.6c-1.9-4.5-4.2-8.1-7.1-10.9c-2.9-2.8-5.9-4.6-9.2-5.4c-3.2-0.8-6.4-0.6-9.4,0.7
										C1146.4,823,1144.1,825.1,1142.5,828z"
							/>
						</g>
						<g>
							<path
								d="M1003.7,834.9c3.4,5.6,3.6,11.7,0.8,18.3c-1.8,4.3-4.6,7.5-8.4,9.7c-3.8,2.2-8.3,3.2-13.5,3c-5.2-0.2-11-1.6-17.2-4.3
										l-4.6-2.1c-2.9-1.3-5-2.3-6.3-2.8c-5.2-2.2-9.1-3.8-11.7-4.8l0.5-1.2c1.7,0.6,3,1,3.9,1.1c0.9,0.1,1.6-0.1,2.2-0.7
										c0.6-0.5,1.2-1.6,1.8-3.1l12.6-29.2c0.6-1.5,1-2.6,1-3.4c0-0.8-0.3-1.5-1-2c-0.7-0.6-1.8-1.2-3.4-2l0.5-1.2c2.1,1,5.1,2.4,9,4.1
										l2.3,1l3,1.2c4.1,1.7,7.1,2.9,8.9,3.7C993.7,824.4,1000.3,829.3,1003.7,834.9z M979.7,820.1c-0.9-0.1-1.6,0.2-2.2,0.8
										c-0.6,0.6-1.2,1.6-1.8,3L963,853.3c-0.6,1.4-0.9,2.5-0.9,3.4c0,0.8,0.3,1.6,1,2.2c0.7,0.6,1.8,1.3,3.4,2
										c6.1,2.6,11.1,2.8,15.1,0.6c4-2.2,7.4-6.7,10.3-13.4c2.9-6.7,3.7-12.2,2.4-16.5c-1.3-4.3-4.9-7.7-10.9-10.3
										C981.8,820.5,980.6,820.2,979.7,820.1z"
							/>
						</g>
						<g>
							<path
								stroke="#000000"
								strokeMiterlimit="10"
								d="M841.6,795.3c0.4,1.3,1.1,2.3,1.9,3.1l-0.9,1c-3-2.9-6.9-6.5-11.7-10.8
										c-5.3-4.8-9.3-8.3-12-10.5l0.9-1c2,1.7,3.6,2.8,4.6,3.3c1,0.5,1.9,0.4,2.4-0.2c0.5-0.6,0.9-1.6,1.2-3.2l2.5-12.2l-17-15.2l-5,1.5
										c-3.6,1-5.9,2.2-7,3.4c-1.1,1.2-1.2,2.6-0.5,4.1c0.7,1.5,2.2,3.3,4.5,5.5l-0.9,1c-3.4-3.3-6.6-6.2-9.5-8.8
										c-2.4-2.1-4.4-3.9-6.1-5.2l0.9-1c2.8,2,6.6,2.3,11.3,0.9l43.3-12.5c0.5,0.5,1.4,1.4,2.7,2.5c1.2,1.1,2.1,1.9,2.7,2.3l-8.4,47.5
										C841.1,792.6,841.2,794,841.6,795.3z M830.8,763.5l4.1-20.1l-19.9,5.9L830.8,763.5z"
							/>
						</g>
						<g>
							<path
								d="M754,645.8c-1-0.9-1.7-1.5-2.3-1.7c-0.6-0.2-1.3-0.2-2,0.2c-0.7,0.4-1.7,1.1-3,2.3l-24.8,22.8c-1.3,1.2-2.1,2.1-2.5,2.8
										c-0.4,0.7-0.5,1.3-0.3,1.9c0.2,0.6,0.7,1.4,1.5,2.5l-1,0.9c-1.3-1.6-3.3-3.9-5.8-6.6c-2.8-3.1-5-5.3-6.4-6.7l1-0.9
										c0.9,0.9,1.7,1.5,2.3,1.7c0.6,0.3,1.3,0.2,2-0.2c0.7-0.4,1.7-1.1,3-2.3l24.8-22.8c1.3-1.2,2.1-2.1,2.5-2.8c0.4-0.7,0.5-1.3,0.3-2
										s-0.7-1.4-1.5-2.5l1-0.9c1.2,1.6,3.3,3.9,6.1,6.9c2.6,2.8,4.6,4.9,6.1,6.4L754,645.8z"
							/>
						</g>
					</g>
					<g className="level_one location" id="ROHAN">
						<rect
							className="opacityGone"
							x="1467.913"
							y="1317.864"
							width="360.522"
							height="45.861"
							transform="matrix(0.98972, -0.14302, 0.178467, 0.984597, -222.981293, 250.640182)"></rect>
						<g>
							<path
								d="M1520.6,1371.9c-1.2,1.6-2.5,2.8-4,3.7c-1.5,0.9-3.4,1.6-5.7,2c-3,0.6-5.6,0.6-7.5-0.1c-2-0.7-3.5-2.1-4.5-4.5l-2.7-5.9
										c-1.3-2.9-2.6-4.9-3.8-6.1c-1.3-1.2-3-1.6-5.3-1.2l-5.1,1l2.9,14.2c0.3,1.6,0.7,2.7,1.1,3.3c0.4,0.6,1.1,1,1.9,1.1
										c0.8,0.1,2.2,0,4-0.3l0.2,1.2c-2.9,0.5-6.8,1.2-11.9,2.2c-5.6,1.1-9.8,2-12.4,2.7l-0.2-1.2c1.7-0.4,3-0.9,3.7-1.3
										c0.8-0.4,1.2-1,1.4-1.8c0.1-0.8,0.1-1.9-0.3-3.5l-6.2-30.6c-0.3-1.6-0.7-2.7-1.1-3.3c-0.4-0.6-1.1-1-1.9-1.1
										c-0.9-0.1-2.2,0-3.9,0.3l-0.2-1.2c2.7-0.4,6.7-1.2,12-2.2l8.7-1.8c1-0.3,2.2-0.5,3.6-0.8c7.2-1.5,12.9-1.6,16.9-0.5
										c4,1.1,6.4,3.4,7.1,6.9c0.6,2.9-0.3,5.8-2.5,8.5c-2.3,2.8-6.5,5.1-12.7,6.9c4.8-0.6,8.5-0.5,11,0.3c2.6,0.8,4.5,2.4,5.7,4.8
										l3.1,6c0.8,1.6,1.5,2.6,2.2,3.2s1.5,0.7,2.5,0.5c0.6-0.2,1.1-0.4,1.5-0.7c0.4-0.3,0.8-0.7,1.3-1.4L1520.6,1371.9z M1479.4,1339.3
										c-0.6,0.4-1,1-1.1,1.8c-0.1,0.8,0,1.9,0.3,3.4l3.1,15.2l3.4-0.7c4.3-0.9,7.1-2.4,8.3-4.5c1.2-2.1,1.5-4.8,0.9-8
										c-0.7-3.4-2-5.8-3.8-7.2c-1.9-1.4-4.5-1.8-8-1.1C1481,1338.5,1480,1338.8,1479.4,1339.3z"
							/>
						</g>
						<g>
							<path
								d="M1823.1,1291c-1.6,0.4-2.8,0.8-3.5,1.3c-0.7,0.5-1.2,1.3-1.3,2.3c-0.2,1-0.2,2.4,0,4.3l3.2,31.1l-1.8,0.1l-2,0.3
										l-35.1-27.4l2.2,21.4c0.2,2.2,0.5,3.8,0.9,4.8c0.4,1,1.1,1.7,2.1,2c1,0.4,2.7,0.5,4.9,0.5l0.1,1.1c-1.9,0.1-4.6,0.3-8,0.6
										c-2.7,0.3-4.8,0.6-6.4,0.8l-0.1-1.1c1.6-0.4,2.8-0.8,3.5-1.3c0.7-0.5,1.2-1.3,1.3-2.3c0.2-1,0.2-2.4,0-4.3l-2.6-25.1
										c-0.1-1.4-0.4-2.4-0.7-3c-0.3-0.6-0.9-1-1.7-1.1s-2-0.2-3.6-0.1l-0.1-1.1c1.6,0,3.7-0.2,6.4-0.5c2.4-0.3,4.6-0.5,6.3-0.8
										l31.2,23.7l-1.8-17.4c-0.2-2.2-0.5-3.8-0.9-4.8s-1.1-1.7-2.1-2c-1-0.4-2.7-0.5-4.9-0.5l-0.1-1.1c1.9-0.1,4.6-0.3,8-0.6
										c2.7-0.3,4.9-0.6,6.4-0.8L1823.1,1291z"
							/>
						</g>
						<g>
							<path
								d="M1664.9,1313.9c-0.7,0.3-1.2,0.8-1.4,1.5c-0.2,0.7-0.2,1.7,0,3.1l3.2,27.6c0.2,1.4,0.4,2.4,0.8,3c0.3,0.6,0.9,1,1.7,1.1
										s2,0.1,3.7,0l0.1,1.1c-2.4,0.2-6.2,0.6-11.3,1.2c-5,0.6-8.8,1.1-11.6,1.5l-0.1-1.1c1.6-0.3,2.8-0.6,3.5-0.9
										c0.7-0.3,1.2-0.8,1.4-1.5c0.2-0.7,0.2-1.7,0-3.1l-1.6-13.3l-19.6,2.3l1.6,13.3c0.2,1.4,0.4,2.4,0.8,3c0.3,0.6,0.9,1,1.7,1.1
										c0.8,0.1,2,0.1,3.7,0l0.1,1.1c-2.7,0.2-6.4,0.6-11.2,1.1c-5.3,0.6-9.2,1.1-11.7,1.5l-0.1-1.1c1.6-0.3,2.8-0.6,3.5-0.9
										c0.7-0.3,1.2-0.8,1.4-1.5c0.2-0.7,0.2-1.7,0-3.1l-3.2-27.6c-0.2-1.4-0.4-2.4-0.8-3c-0.3-0.6-0.9-1-1.7-1.1c-0.8-0.1-2-0.1-3.6,0
										l-0.1-1.1c2.5-0.2,6.4-0.6,11.7-1.2c4.8-0.6,8.5-1.1,11.2-1.5l0.1,1.1c-1.7,0.3-2.9,0.6-3.6,0.9c-0.7,0.3-1.2,0.8-1.4,1.5
										c-0.2,0.7-0.2,1.7,0,3.1l1.5,13.1l19.6-2.3l-1.5-13.1c-0.2-1.4-0.4-2.4-0.8-3c-0.3-0.6-0.9-1-1.7-1.1c-0.8-0.1-2-0.1-3.6,0
										l-0.1-1.1c2.8-0.2,6.7-0.6,11.6-1.2c5.1-0.6,8.8-1.1,11.2-1.5l0.1,1.1C1666.8,1313.3,1665.6,1313.6,1664.9,1313.9z"
							/>
						</g>
						<g>
							<path
								d="M1759.7,1337.3c1.2,0.5,2.3,0.7,3.3,0.6l0.1,1.2c-3.9,0.3-8.9,0.7-14.8,1.3c-6.6,0.7-11.6,1.2-14.8,1.7l-0.1-1.2
										c2.5-0.3,4.2-0.7,5.2-1.1c1-0.4,1.5-1,1.4-1.8c-0.1-0.7-0.6-1.7-1.5-2.8l-6.9-9.4l-21.2,2.1l-2.1,4.5c-1.5,3.1-2.2,5.5-2,7
										c0.2,1.5,1,2.5,2.5,2.9c1.5,0.4,3.7,0.6,6.6,0.4l0.1,1.2c-4.4,0.3-8.5,0.7-12.1,1c-3,0.3-5.4,0.6-7.4,0.9l-0.1-1.2
										c3.1-0.7,5.7-3.1,7.7-7.2l18.6-37.9c0.7,0,1.9-0.1,3.4-0.3c1.6-0.2,2.7-0.3,3.3-0.4l27.5,35.7
										C1757.4,1335.9,1758.5,1336.8,1759.7,1337.3z M1730.9,1324.7l-11.3-15.5l-8.5,17.5L1730.9,1324.7z"
							/>
						</g>
						<g>
							<path
								d="M1573.5,1325.6c3.9,0.9,7.1,2.7,9.8,5.4c2.6,2.7,4.4,6.2,5.4,10.6c0.9,4.2,0.8,8.2-0.5,11.9s-3.5,6.8-6.6,9.4
										c-3.2,2.6-7,4.4-11.6,5.4c-4.7,1-9,1.1-12.8,0.2s-7.1-2.7-9.8-5.4c-2.6-2.7-4.4-6.2-5.4-10.6c-0.9-4.2-0.8-8.2,0.5-11.9
										c1.3-3.7,3.5-6.8,6.6-9.4c3.2-2.6,7-4.4,11.6-5.4C1565.3,1324.8,1569.6,1324.8,1573.5,1325.6z M1554.8,1331
										c-1.5,2.1-2.4,4.8-2.7,8.1s0,6.9,0.8,10.9c0.9,4,2.2,7.4,4,10.2c1.8,2.8,3.8,4.8,6.1,6c2.3,1.2,4.6,1.6,6.9,1.1
										c2.4-0.5,4.4-1.9,5.9-4c1.5-2.1,2.4-4.8,2.7-8.1s0-6.9-0.8-10.9c-0.9-4-2.2-7.4-4-10.2c-1.8-2.8-3.8-4.8-6.1-6
										c-2.3-1.2-4.6-1.6-6.9-1.1C1558.2,1327.5,1556.2,1328.8,1554.8,1331z"
							/>
						</g>
					</g>
					<g className="regions level_one location" id="forodwaith">
						<rect
							x="3668.6"
							y="2596.124"
							width="238.214"
							height="57.111"
							transform="matrix(0.954153, 0.299321, -0.310813, 0.950547, -1119.596558, -3488.561279)"
							data-bx-origin="0.019 0.153"></rect>
						<rect
							x="3668.609"
							y="2596.129"
							width="238.215"
							height="57.111"
							transform="matrix(0.999968, 0.008022, -0.020066, 0.999871, -1828.044312, -2480.570068)"
							data-bx-origin="0.019 0.153"></rect>
						<path
							d="M1603.8,101.5c-0.8,1.9-1.3,3.5-1.7,4.8l-1.4-0.6c0.9-3.1,1.3-5.4,1.4-7.1s-0.4-3-1.3-4.2c-1-1.2-2.8-2.3-5.3-3.3
									l-3.9-1.6c-1.5-0.6-2.6-0.9-3.4-1c-0.8-0.1-1.4,0.2-1.9,0.7c-0.5,0.6-1.1,1.6-1.6,3l-5.7,14.1l4.6,1.8c1.5,0.6,2.7,0.7,3.8,0.4
									c1-0.3,1.9-0.8,2.5-1.6c0.6-0.8,1.3-1.7,2-3l0.7-1.1l1.4,0.6c-0.8,1.6-1.6,3.3-2.4,5.3l-1.1,2.8c-0.8,2-1.8,4.7-2.9,8.3l-1.4-0.6
									c0.1-0.3,0.3-1.3,0.7-2.9c0.4-1.7,0.3-3.1-0.3-4.3s-1.8-2.1-3.4-2.8l-4.6-1.8l-5.3,13.2c-0.6,1.5-0.8,2.6-0.8,3.4
									c0.1,0.8,0.5,1.5,1.2,2c0.7,0.6,2,1.3,3.9,2.1l-0.5,1.1c-2.1-1-5.2-2.3-9.1-3.9c-3.6-1.4-6.3-2.5-8.1-3.1l0.5-1.1
									c1.5,0.5,2.7,0.8,3.5,0.9c0.8,0,1.4-0.2,1.9-0.8c0.5-0.5,1.1-1.6,1.6-3l11.5-28.5c0.6-1.5,0.9-2.6,0.9-3.3c0-0.8-0.3-1.4-0.9-1.9
									c-0.6-0.5-1.6-1.1-3.1-1.8l0.5-1.1c4,1.8,10,4.3,18,7.5c6.1,2.5,10.5,4.2,13.1,5.1C1605.8,96.9,1604.6,99.3,1603.8,101.5z"
						/>
						<path
							d="M1642.7,109.3c2.4,2.8,3.9,6,4.4,9.8c0.5,3.8,0,7.8-1.6,12.2c-1.5,4.3-3.8,7.7-6.7,10.4c-2.9,2.7-6.2,4.4-9.9,5.1
									c-3.7,0.7-7.5,0.4-11.4-1c-4-1.4-7.2-3.5-9.6-6.3c-2.4-2.8-3.9-6-4.4-9.8c-0.5-3.8,0-7.8,1.6-12.2c1.5-4.3,3.8-7.7,6.7-10.4
									c2.9-2.7,6.2-4.4,9.9-5.1c3.7-0.7,7.5-0.4,11.4,1C1637.1,104.4,1640.3,106.5,1642.7,109.3z M1624.4,103.9c-2.7,1-5.1,2.8-7.4,5.5
									c-2.2,2.7-4.1,6.1-5.5,10.1c-1.5,4-2.1,7.8-2,11.3s1,6.5,2.5,8.9c1.5,2.4,3.6,4.1,6.2,5.1c2.7,1,5.4,1,8.1,0
									c2.7-1,5.1-2.8,7.4-5.5c2.2-2.7,4.1-6.1,5.5-10.1c1.5-4,2.1-7.8,2-11.3c-0.1-3.5-1-6.5-2.5-8.9c-1.5-2.4-3.6-4.1-6.2-5.1
									C1629.8,102.9,1627.1,103,1624.4,103.9z"
						/>
						<path
							d="M1686.8,165.1c-1.4,1-2.7,1.6-3.9,1.9c-1.2,0.3-2.6,0.1-4.2-0.4c-1.7-0.6-3-1.5-3.9-2.8c-0.8-1.3-1.1-3.2-0.9-5.8l0.5-6.3
									c0.2-2.2,0.2-3.9,0.1-5.2c-0.1-1.2-0.5-2.3-1.1-3.3c-0.7-1-1.8-1.7-3.4-2.2l-6.1-2l-4.4,13.5c-0.5,1.5-0.7,2.6-0.7,3.4
									c0,0.8,0.4,1.4,1,1.8s1.7,1,3.2,1.6l-0.4,1.2c-1.9-0.8-4.6-1.7-8.1-2.8c-3.7-1.2-6.4-2-8.2-2.5l0.4-1.2c1.6,0.4,2.7,0.6,3.5,0.6
									c0.8,0,1.4-0.3,1.9-0.9c0.5-0.6,1-1.6,1.4-3.1l9.6-29.2c0.5-1.5,0.7-2.6,0.7-3.4c0-0.8-0.4-1.4-1-1.8c-0.6-0.5-1.7-1-3.2-1.6
									l0.4-1.2c1.8,0.7,4.3,1.6,7.7,2.7l6.9,2.2c0.8,0.2,1.7,0.5,2.8,0.8c4.9,1.6,8.4,3.8,10.5,6.6c2,2.8,2.6,5.7,1.6,8.7
									c-0.5,1.5-1.4,2.9-2.7,4.2c-1.3,1.3-3.1,2.2-5.3,2.9c-2.3,0.6-5,0.7-8.1,0.1l0.7,0.4c2.7,1.4,4.5,2.8,5.4,4.4
									c0.9,1.6,1.2,3.7,1,6.3l-0.4,6.5c-0.2,1.9-0.1,3.3,0.1,4.2c0.2,0.9,0.8,1.4,1.6,1.7c0.7,0.2,1.4,0.3,2,0.1
									c0.6-0.2,1.4-0.6,2.3-1.1L1686.8,165.1z M1672.4,119.3c-0.8,0.1-1.4,0.4-1.9,1c-0.5,0.6-1,1.6-1.4,3l-4.8,14.5l4.3,1.4
									c4.2,1.4,7.4,1.5,9.5,0.3c2.1-1.2,3.7-3.3,4.7-6.4c1.1-3.2,1-5.9-0.1-8.1c-1.1-2.2-3.6-3.9-7.3-5.1
									C1674.2,119.4,1673.2,119.2,1672.4,119.3z"
						/>
						<path
							d="M1735.1,138c2.6,2.5,4.4,5.7,5.2,9.4c0.8,3.7,0.7,7.8-0.5,12.3c-1.2,4.4-3.1,8-5.7,11c-2.7,2.9-5.8,4.9-9.5,5.9
									c-3.6,1-7.5,1-11.5,0c-4.1-1.1-7.5-2.9-10.1-5.5c-2.6-2.5-4.4-5.7-5.2-9.4c-0.8-3.7-0.7-7.8,0.5-12.3c1.2-4.4,3.1-8,5.7-11
									c2.7-2.9,5.8-4.9,9.5-5.9c3.6-1,7.5-1,11.5,0C1729,133.6,1732.4,135.4,1735.1,138z M1716.4,134.2c-2.6,1.2-4.9,3.3-6.9,6.1
									c-2,2.9-3.5,6.4-4.6,10.5c-1.1,4.1-1.4,8-1,11.5c0.4,3.5,1.5,6.4,3.2,8.7c1.7,2.3,3.9,3.8,6.6,4.5c2.8,0.7,5.5,0.5,8-0.7
									c2.6-1.2,4.9-3.3,6.9-6.1c2-2.9,3.5-6.4,4.6-10.5c1.1-4.1,1.4-8,1-11.5c-0.4-3.5-1.5-6.4-3.2-8.7c-1.7-2.3-3.9-3.8-6.6-4.5
									C1721.6,132.8,1718.9,133,1716.4,134.2z"
						/>
						<path
							d="M1787.5,152.7c3.2,4.3,4.2,9.9,2.9,16.8c-0.8,4.4-2.5,8.1-5,11.1c-2.5,3-5.8,5-9.7,6.1c-3.9,1.1-8.4,1.2-13.4,0.3
									l-3.8-0.8c-2.5-0.5-4.3-0.9-5.4-1.1c-3.6-0.7-6.4-1.1-8.2-1.4l0.2-1.2c1.6,0.2,2.8,0.3,3.6,0.2s1.4-0.5,1.8-1.1
									c0.4-0.6,0.7-1.7,1-3.3l5.7-30.2c0.3-1.5,0.4-2.7,0.2-3.4c-0.1-0.7-0.5-1.3-1.2-1.7c-0.7-0.4-1.8-0.8-3.4-1.1l0.2-1.2
									c1.5,0.4,3.6,0.9,6.4,1.4l5.6,1c3,0.5,5,0.8,6,1C1778.8,145.5,1784.3,148.4,1787.5,152.7z M1766,144.6c-0.7,0.2-1.3,0.6-1.7,1.3
									c-0.4,0.7-0.7,1.7-1,3.2l-5.8,30.4c-0.3,1.5-0.4,2.6-0.2,3.4s0.5,1.4,1.1,1.8c0.6,0.4,1.6,0.8,3.1,1c4.7,0.9,8.5,0.8,11.5-0.2
									c3-1,5.3-3,7-5.8c1.6-2.8,2.9-6.5,3.8-11.2c0.9-4.7,1-8.6,0.4-11.7c-0.6-3.2-2.1-5.7-4.5-7.7c-2.4-2-5.9-3.4-10.4-4.2
									C1767.7,144.5,1766.7,144.5,1766,144.6z"
						/>
						<path
							d="M1859.5,156l-0.1,1.2c-2.6,0.3-4.5,1.9-5.9,4.8l-16.7,35.9l-0.5-0.1l-0.5-0.1l-8.2-32.9l-14.1,30.5l-0.5-0.1l-0.5-0.1
									l-10-40.2c-0.7-2.6-2.1-4.1-4.2-4.4l0.1-1.2c1.8,0.3,4.2,0.6,7.1,1c3.2,0.3,5.6,0.5,7.2,0.6l-0.1,1.2c-1.2,0-2.1,0.1-2.6,0.3
									c-0.5,0.3-0.8,0.8-0.9,1.5c-0.1,0.7,0.1,1.7,0.4,3.1l6.8,28.5l10.7-22.9l-1.3-5.1c-0.6-2.6-1.8-4.1-3.5-4.4l0.1-1.2
									c1.6,0.3,3.8,0.6,6.5,0.9c3.8,0.4,6.7,0.7,8.6,0.7l-0.1,1.2c-1.7-0.1-2.9-0.1-3.6,0.1c-0.7,0.2-1.1,0.7-1.2,1.6
									c-0.1,0.7,0,1.7,0.4,3l6.7,28.4l10.8-22.9c0.9-1.9,1.5-3.6,1.6-4.9c0.1-1.4-0.2-2.4-1-3c-0.8-0.6-2.3-1.1-4.3-1.4l0.1-1.2
									c2.5,0.4,5.1,0.7,7.7,1C1856.5,155.9,1858.2,156,1859.5,156z"
						/>
						<path
							d="M1898.7,199.8l-0.1,1.2c-1.8-0.2-4.2-0.4-7.2-0.5c-3.9-0.2-6.7-0.2-8.6-0.2l0.1-1.2c1.7,0,2.9-0.2,3.7-0.4
									s1.1-0.8,1.2-1.7c0-0.7-0.2-1.7-0.7-2.9l-3.7-10.3l-16.3-0.7l-3,6.4c-1,2.2-1.5,3.9-1.6,5c-0.1,1.3,0.4,2.2,1.3,2.8
									c0.9,0.6,2.4,1,4.4,1.1l-0.1,1.2c-2.6-0.2-5.1-0.4-7.7-0.5c-2-0.1-3.7-0.1-5.1,0l0.1-1.2c2.3-0.4,4.1-2.1,5.5-5.2l17.4-36.8l1.1,0
									l14.8,39.6C1895.1,198.3,1896.6,199.7,1898.7,199.8z M1882.9,182.6l-6.7-18.8l-8.5,18.2L1882.9,182.6z"
						/>
						<path
							d="M1920.8,158.8c-1.6,0.1-2.8,0.2-3.5,0.4c-0.7,0.2-1.3,0.7-1.5,1.4c-0.3,0.7-0.4,1.8-0.5,3.4l-0.5,30.7
									c0,1.6,0.1,2.7,0.3,3.4c0.3,0.7,0.8,1.2,1.5,1.4s1.9,0.4,3.5,0.6l0,1.2c-2.1-0.2-4.9-0.3-8.5-0.3c-3.9-0.1-6.7,0-8.6,0l0-1.2
									c1.6-0.1,2.8-0.2,3.5-0.4c0.7-0.2,1.3-0.7,1.5-1.4c0.3-0.7,0.4-1.8,0.5-3.4l0.5-30.7c0-1.6-0.1-2.7-0.3-3.4
									c-0.3-0.7-0.8-1.2-1.5-1.4s-1.9-0.4-3.5-0.6l0-1.2c1.9,0.2,4.8,0.3,8.6,0.3c3.6,0.1,6.4,0,8.6,0L1920.8,158.8z"
						/>
						<path
							d="M1965.7,164.8c0,2.1,0.1,3.8,0.3,5.1l-1.5,0c-0.4-3.2-0.9-5.5-1.5-7.1c-0.6-1.5-1.5-2.7-2.9-3.3c-1.4-0.7-3.4-1-6.2-1
									l-3.6,0.1l0.7,35.2c0,1.7,0.2,3,0.6,3.8c0.4,0.8,1,1.3,1.9,1.6c0.9,0.3,2.4,0.4,4.4,0.5l0,1.2c-2.3-0.1-5.6-0.1-9.9,0
									c-4.5,0.1-7.8,0.2-10,0.4l0-1.2c2-0.1,3.5-0.3,4.4-0.6c0.9-0.3,1.5-0.9,1.9-1.6c0.3-0.8,0.5-2.1,0.5-3.8l-0.7-35.2l-3.6,0.1
									c-2.8,0.1-4.8,0.5-6.2,1.2c-1.3,0.7-2.2,1.9-2.8,3.5c-0.5,1.6-0.9,3.9-1.2,7.1l-1.5,0c0.1-1.3,0.1-3,0.1-5.1c0-2.4-0.3-5-0.6-7.9
									c3.7,0,10.1,0,19-0.2c8.9-0.2,15.2-0.4,18.9-0.6C1965.8,159.8,1965.7,162.5,1965.7,164.8z"
						/>
						<path
							d="M2014.9,155.4c-0.7,0.3-1.2,0.8-1.4,1.5c-0.2,0.7-0.3,1.9-0.2,3.4l2.2,30.6c0.1,1.6,0.3,2.7,0.7,3.4
									c0.3,0.7,0.9,1.1,1.6,1.3c0.8,0.2,1.9,0.3,3.6,0.2l0.1,1.2c-1.9,0-4.8,0.2-8.6,0.4c-3.6,0.3-6.5,0.5-8.5,0.8l-0.1-1.2
									c1.6-0.2,2.8-0.5,3.5-0.8s1.2-0.8,1.4-1.5c0.2-0.7,0.3-1.9,0.2-3.4l-1.1-14.8l-21.8,1.6l1.1,14.8c0.1,1.6,0.3,2.7,0.7,3.4
									c0.3,0.7,0.9,1.1,1.6,1.3c0.8,0.2,1.9,0.3,3.6,0.2l0.1,1.2c-2.1,0-4.9,0.2-8.5,0.4c-3.8,0.3-6.7,0.6-8.6,0.8l-0.1-1.2
									c1.6-0.2,2.8-0.5,3.5-0.8s1.2-0.8,1.4-1.5c0.2-0.7,0.3-1.9,0.2-3.4l-2.2-30.6c-0.1-1.6-0.3-2.7-0.7-3.4c-0.3-0.7-0.9-1.1-1.6-1.3
									s-1.9-0.3-3.6-0.2l-0.1-1.2c1.9,0,4.8-0.2,8.6-0.4c3.6-0.3,6.4-0.5,8.5-0.8l0.1,1.2c-1.6,0.2-2.8,0.5-3.5,0.8s-1.2,0.8-1.4,1.5
									c-0.2,0.7-0.3,1.9-0.2,3.4l1.1,14.6l21.8-1.6l-1.1-14.6c-0.1-1.6-0.3-2.7-0.7-3.4c-0.3-0.7-0.9-1.1-1.6-1.3s-1.9-0.3-3.6-0.2
									l-0.1-1.2c2.1,0,4.9-0.2,8.5-0.4c3.8-0.3,6.7-0.6,8.6-0.8l0.1,1.2C2016.7,154.8,2015.6,155.1,2014.9,155.4z"
						/>
					</g>
				</g>
				<g id="other_font">
					<g className="level_two fade location " id="old_forest_road">
						<rect x="2695.57" y="1251.789" width="175.031" height="27.538" transform="matrix(0.998992, -0.044894, 0.03286, 0.999533, -846.421448, -532.43866)"></rect>
						<path
							d="M1892.1,618.5c-0.9-0.6-1.5-1.4-2-2.5s-0.7-2.3-0.7-3.7c0-1.5,0.3-2.9,0.9-4.1c0.6-1.2,1.4-2.2,2.4-2.8c1-0.7,2.2-1,3.5-1
									c1.2,0,2.2,0.3,3.1,0.9c0.9,0.6,1.6,1.5,2,2.6c0.5,1.1,0.7,2.4,0.7,3.8c0,1.5-0.3,2.9-0.9,4.1c-0.6,1.2-1.4,2.1-2.5,2.8
									c-1.1,0.7-2.2,1-3.6,1C1893.9,619.4,1892.9,619.1,1892.1,618.5z M1898.4,616.4c0.7-1,1-2.2,1-3.8c0-1.5-0.3-2.7-0.9-3.7
									c-0.6-1-1.4-1.6-2.5-1.9c-0.8,0-1.5,0.2-2.1,0.7c-0.6,0.5-1.1,1.1-1.5,1.9c-0.3,0.8-0.5,1.8-0.5,2.8c0,1.6,0.3,2.9,1,4
									c0.7,1,1.5,1.5,2.6,1.5C1896.7,617.8,1897.7,617.4,1898.4,616.4z"
						/>
						<path
							d="M1906.7,618.8c-0.2-0.3-0.3-0.9-0.3-1.8c0-0.6,0-1.5,0-2.7c0-1.4,0-2.5,0-3.4c0-3-0.2-5.1-0.5-6.3c0.5-0.2,1-0.3,1.4-0.4
									c0.4-0.1,1-0.1,1.5-0.1c-0.2,1.9-0.3,5.2-0.2,9.8c0,1,0,1.8,0.1,2.3c0.1,0.5,0.1,1,0.3,1.3c0.1,0.3,0.3,0.6,0.6,1
									c-0.3,0.4-0.6,0.6-0.9,0.8c-0.3,0.2-0.6,0.2-0.9,0.2C1907.2,619.3,1906.9,619.1,1906.7,618.8z"
						/>
						<path
							d="M1914.2,617.9c-0.7-1-1-2.4-1.1-4.3c0-2,0.3-3.5,0.9-4.5c0.7-1,1.6-1.6,3-1.6c1,0,1.9,0.3,2.8,1c-0.1-1.7-0.2-3.1-0.5-4
									c0.8-0.4,1.8-0.6,2.9-0.6c-0.2,2.2-0.2,6-0.1,11.2c0,0.7,0.1,1.3,0.2,1.7s0.4,0.8,0.7,1.3c-0.3,0.3-0.6,0.5-1,0.7
									c-0.4,0.2-0.8,0.3-1.1,0.4c-0.4-0.4-0.8-0.9-0.9-1.5c-0.4,0.5-0.9,0.9-1.4,1.1c-0.5,0.3-1,0.4-1.5,0.4
									C1915.9,619.3,1914.9,618.8,1914.2,617.9z M1919.9,616.6c0-0.6,0-1.5,0-2.8c0-1.4,0-2.5,0-3.3c-0.5-0.4-1.2-0.6-1.9-0.5
									c-0.8,0-1.4,0.3-1.8,1c-0.4,0.6-0.6,1.6-0.6,2.8c0,1.1,0.2,2.1,0.6,2.8s0.9,1.1,1.6,1.3C1918.5,617.6,1919.2,617.3,1919.9,616.6z"
						/>
						<path
							d="M1934.9,618.6c0.1-0.8,0.1-2,0.1-3.4c0-1.4,0-2.8,0-4.1c-0.1-1.9-0.1-3.3-0.2-4.4c-0.1-1.1-0.2-1.9-0.4-2.6
									c0.9,0.1,2.2,0.1,3.9,0.1c1.9-0.1,3.4-0.2,4.5-0.3c0,0.6,0,1.1-0.1,1.8c-0.1,0.6-0.2,1.1-0.3,1.3c-0.4-0.2-0.8-0.4-1.3-0.5
									c-0.5-0.1-1.1-0.2-1.6-0.1c-0.7,0-1.4,0.1-2.1,0.3c0,1.4,0,2.7,0,3.8c0.6,0,1.1,0,1.5,0c0.7,0,1.3-0.1,1.7-0.1s0.8-0.1,1.2-0.3
									c0.1,0.5,0.1,0.9,0.1,1.1c0,0.2,0,0.6-0.1,1.2c-1.6-0.1-2.9-0.1-3.7-0.1l-0.7,0c0.1,3.5,0.3,5.6,0.6,6.3c-0.5,0.1-0.9,0.2-1.4,0.2
									C1936,618.7,1935.5,618.7,1934.9,618.6z"
						/>
						<path
							d="M1948.4,617.8c-0.7-0.4-1.2-1.1-1.6-1.9c-0.4-0.8-0.6-1.8-0.7-2.9c-0.1-1.2,0.1-2.3,0.5-3.3c0.4-1,1-1.7,1.8-2.3
									c0.8-0.6,1.7-0.9,2.7-0.9c1.4-0.1,2.5,0.4,3.4,1.4s1.4,2.3,1.4,4c0.1,1.2-0.1,2.3-0.6,3.3s-1,1.7-1.8,2.3s-1.7,0.9-2.8,0.9
									C1949.9,618.4,1949.1,618.2,1948.4,617.8z M1953.1,615.9c0.4-0.7,0.6-1.6,0.6-2.8c0-1-0.3-1.9-0.7-2.7s-1.1-1.2-1.8-1.5
									c-0.9,0-1.6,0.4-2,1.1s-0.7,1.6-0.6,2.8c0.1,1.3,0.3,2.4,0.8,3.1c0.5,0.8,1.1,1.1,1.9,1.1C1952,617,1952.6,616.6,1953.1,615.9z"
						/>
						<path
							d="M1960.9,617.6c0-1.8,0-3.8-0.2-5.9c-0.1-1-0.1-1.8-0.2-2.3c-0.1-0.5-0.2-0.9-0.3-1.2c-0.1-0.3-0.3-0.6-0.6-0.9
									c0.2-0.4,0.5-0.6,0.8-0.8c0.3-0.2,0.6-0.3,0.9-0.3c1-0.1,1.5,0.6,1.7,1.9c0.7-1.2,1.6-2,2.7-2.4c0.1,0.3,0.3,0.8,0.4,1.4
									c0.1,0.6,0.2,1.2,0.2,1.7c-0.3,0-0.6-0.1-0.8,0c-0.5,0-0.9,0.1-1.4,0.3c-0.4,0.2-0.8,0.4-1.1,0.6c0,1.3,0.1,2.5,0.2,3.7
									c0.1,1.9,0.3,3.3,0.7,4.1c-0.4,0.1-0.9,0.2-1.4,0.2C1962,617.7,1961.5,617.7,1960.9,617.6z"
						/>
						<path
							d="M1970.5,615.9c-0.8-0.9-1.2-2.3-1.3-4c-0.1-1.2,0.1-2.3,0.4-3.3s0.9-1.8,1.6-2.3c0.7-0.6,1.6-0.9,2.6-1
									c1.3-0.1,2.3,0.3,3,1.3c0.7,0.9,1.2,2.4,1.3,4.3c-0.8,0.3-1.8,0.6-3,0.8c-1.2,0.3-2.3,0.4-3.4,0.5c0.2,1,0.5,1.6,0.9,2.1
									c0.4,0.4,1,0.6,1.8,0.5c0.7,0,1.3-0.2,1.8-0.4c0.6-0.2,1-0.5,1.4-0.9l0.3,0.9c-0.4,0.8-1.1,1.4-1.9,1.9c-0.8,0.5-1.6,0.8-2.5,0.8
									C1972.3,617.2,1971.3,616.8,1970.5,615.9z M1973.7,610.5c0.7-0.2,1.2-0.5,1.6-0.8c-0.1-0.9-0.4-1.6-0.8-2.2
									c-0.5-0.6-1-0.8-1.7-0.8c-0.4,0.4-0.8,0.9-1,1.7c-0.2,0.8-0.3,1.6-0.2,2.6C1972.3,610.9,1973,610.8,1973.7,610.5z"
						/>
						<path
							d="M1982.1,615.5c-0.1-0.7,0-1.4,0.2-2.2c0.3,0.4,0.8,0.7,1.3,1s1.1,0.3,1.7,0.3c1.4-0.1,2-0.6,1.9-1.5
									c0-0.4-0.2-0.7-0.5-0.9c-0.3-0.2-0.8-0.4-1.5-0.7c-0.7-0.3-1.3-0.6-1.8-0.8c-0.5-0.3-0.9-0.6-1.2-1.1c-0.4-0.5-0.6-1.1-0.6-1.8
									c-0.1-0.9,0.2-1.7,0.9-2.3c0.6-0.6,1.5-1,2.6-1.1c1.4-0.1,2.6,0,3.7,0.4c0,0.3,0,0.8-0.1,1.4c-0.1,0.6-0.2,1.1-0.4,1.5
									c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1-0.4-1.4-0.4c-0.3,0.1-0.6,0.3-0.8,0.5c-0.2,0.2-0.3,0.5-0.2,0.7c0,0.4,0.2,0.8,0.6,1
									c0.4,0.2,0.9,0.5,1.6,0.8c0.7,0.3,1.2,0.5,1.7,0.8c0.4,0.3,0.8,0.6,1.2,1c0.3,0.4,0.5,1,0.6,1.7c0.1,1.1-0.2,1.9-0.9,2.6
									s-1.7,1-3,1.1C1984.3,616.3,1983,616.1,1982.1,615.5z"
						/>
						<path
							d="M1994.9,614.8c-0.3-0.3-0.5-0.9-0.6-1.8c-0.1-0.5-0.1-1.4-0.2-2.7c-0.1-1.4-0.2-2.5-0.3-3.4c0-0.4-0.1-0.7-0.2-1.1
									c-0.6,0.1-1.1,0.1-1.3,0c-0.2-0.1-0.4-0.3-0.4-0.7c1-0.8,1.9-1.8,2.8-3.1h0.8c0.1,0.6,0.2,1.2,0.3,1.9c1-0.1,1.8-0.3,2.3-0.5
									c0,0.4,0,0.8,0,1.1s-0.1,0.7-0.2,1c-0.5-0.2-1.1-0.2-2-0.1c0,0.8,0.1,1.6,0.2,2.4c0.2,2.1,0.5,3.7,0.8,4.9
									c0.6-0.1,1.2-0.2,1.8-0.4l0.2,0.8c-0.2,0.5-0.6,1-1.1,1.4s-1,0.6-1.6,0.6C1995.7,615.2,1995.2,615.1,1994.9,614.8z"
						/>
						<path
							d="M2010.5,613.5c0-0.9,0-2.2-0.1-3.8c-0.1-1.6-0.1-3.1-0.2-4.4c-0.2-3.1-0.5-5-0.8-5.7c0.5-0.3,1.1-0.5,1.9-0.7
									c0.8-0.2,1.7-0.3,2.5-0.4c1.6-0.1,2.9,0.2,3.8,1s1.4,1.9,1.5,3.5c0.1,0.9-0.1,1.7-0.4,2.5s-0.9,1.4-1.6,1.8c1.4,2.7,2.5,4.4,3.3,5
									c-0.5,0.3-0.9,0.4-1.4,0.6c-0.5,0.1-1,0.2-1.7,0.2c-0.4-1-1.3-2.7-2.5-5c-0.1,0-0.2,0-0.4,0.1c-0.5,0-1.1,0-1.6,0
									c0.2,2.6,0.4,4.3,0.7,5.1c-0.5,0.1-0.9,0.2-1.4,0.3C2011.6,613.5,2011.1,613.5,2010.5,613.5z M2016.3,605.2
									c0.3-0.6,0.4-1.2,0.4-1.8c-0.1-2.1-1.2-3-3.2-2.9c-0.4,0-0.8,0.1-1.2,0.3c0,1.3,0.1,3.1,0.2,5.3c1.1,0.3,2,0.5,2.5,0.5
									C2015.6,606.3,2016,605.8,2016.3,605.2z"
						/>
						<path
							d="M2025.7,612.1c-0.7-0.4-1.2-1.1-1.6-1.9c-0.4-0.8-0.6-1.8-0.7-2.9c0-1.2,0.1-2.3,0.5-3.3c0.4-1,1-1.7,1.8-2.3
									c0.8-0.6,1.7-0.8,2.7-0.9c1.4-0.1,2.5,0.4,3.4,1.4c0.9,1,1.3,2.3,1.4,4c0,1.2-0.1,2.3-0.6,3.3c-0.4,1-1,1.7-1.8,2.3
									s-1.7,0.8-2.8,0.9C2027.2,612.8,2026.4,612.6,2025.7,612.1z M2030.4,610.3c0.4-0.7,0.6-1.6,0.6-2.8c0-1-0.3-1.9-0.7-2.7
									c-0.5-0.8-1.1-1.3-1.8-1.5c-0.9,0-1.6,0.4-2,1.1c-0.5,0.7-0.7,1.6-0.7,2.8c0,1.3,0.3,2.4,0.8,3.1c0.5,0.8,1.1,1.1,1.9,1.1
									C2029.3,611.4,2029.9,611,2030.4,610.3z"
						/>
						<path
							d="M2037.6,611.7c-0.4-0.5-0.6-1.1-0.6-1.9c0-1.1,0.4-2,1.3-2.7c0.9-0.6,2.3-1,4.2-1.2c0-1-0.2-1.7-0.5-2.1
									c-0.3-0.4-0.8-0.6-1.6-0.6c-0.5,0-1,0.1-1.5,0.3c-0.5,0.2-1,0.4-1.4,0.8l-0.2-0.9c0.4-0.8,1-1.5,1.8-2s1.5-0.8,2.3-0.8
									c1.1,0,1.9,0.3,2.5,0.9c0.6,0.6,0.9,1.5,0.9,2.7c0,0.7,0,1.4-0.1,2.2c-0.1,0.9-0.1,1.5-0.1,2c0,0.7,0.1,1.2,0.2,1.6
									c0.1,0.4,0.4,0.8,0.7,1.3c-0.6,0.6-1.3,0.9-2.1,1.1c-0.4-0.3-0.7-0.8-0.9-1.5c-0.6,0.5-1.1,0.8-1.7,1.1s-1.2,0.4-1.7,0.4
									C2038.5,612.4,2038,612.2,2037.6,611.7z M2041.3,610.6c0.4-0.2,0.8-0.4,1.1-0.7l0-0.2c0-0.5,0-1.1,0.1-1.6l0.1-1.1
									c-1,0.1-1.7,0.3-2.3,0.7c-0.5,0.4-0.8,1-0.8,1.6c0,0.3,0.1,0.6,0.2,0.9s0.3,0.5,0.5,0.7C2040.5,610.9,2040.9,610.8,2041.3,610.6z"
						/>
						<path
							d="M2050.2,611c-0.6-1-0.9-2.4-0.9-4.3c0-2,0.4-3.5,1.1-4.5c0.7-1,1.7-1.5,3-1.5c1,0,1.9,0.4,2.8,1.1c0-1.7-0.1-3.1-0.3-4.1
									c0.8-0.3,1.8-0.5,2.9-0.5c-0.2,2.2-0.4,6-0.5,11.2c0,0.7,0,1.3,0.2,1.7c0.1,0.4,0.4,0.9,0.7,1.3c-0.3,0.3-0.6,0.5-1,0.7
									c-0.4,0.2-0.8,0.3-1.2,0.3c-0.4-0.4-0.7-0.9-0.9-1.5c-0.4,0.5-0.9,0.8-1.4,1.1c-0.5,0.3-1,0.4-1.5,0.4
									C2051.8,612.5,2050.8,612,2050.2,611z M2055.9,609.9c0-0.6,0-1.5,0.1-2.8c0.1-1.4,0.1-2.5,0.1-3.3c-0.5-0.4-1.2-0.6-1.9-0.6
									c-0.8,0-1.4,0.3-1.8,0.9c-0.4,0.6-0.7,1.6-0.7,2.8c0,1.1,0.1,2.1,0.5,2.8c0.4,0.7,0.9,1.2,1.5,1.3
									C2054.5,610.9,2055.2,610.6,2055.9,609.9z"
						/>
					</g>
					<g className="level_two fade location" id="esgaroth_upon_the_long_lake">
						<rect x="2898.856" y="2256.879" width="188.231" height="49.649" transform="matrix(0.977084, 0.212855, -0.224625, 0.97452, -185.260635, -2370.150391)"></rect>
						<path
							d="M2150.7,498.4c-0.2-0.5-0.3-1.2-0.1-2.2c0.1-0.6,0.3-1.7,0.5-3.2c0.3-1.6,0.5-2.9,0.6-4c0.1-0.4,0.1-0.9,0.1-1.3
									c-0.8-0.1-1.3-0.2-1.5-0.4c-0.2-0.2-0.3-0.5-0.3-0.9c1.4-0.6,2.7-1.5,4.1-2.7l0.9,0.2c0,0.7-0.1,1.5-0.2,2.3
									c1.2,0.2,2.2,0.2,2.8,0.1c-0.1,0.5-0.2,0.9-0.3,1.3c-0.1,0.4-0.3,0.7-0.5,1.1c-0.5-0.3-1.2-0.6-2.2-0.7c-0.2,0.9-0.4,1.8-0.5,2.8
									c-0.4,2.5-0.5,4.4-0.5,5.9c0.7,0.1,1.5,0.1,2.1,0l0,1c-0.4,0.6-1,1-1.7,1.2c-0.7,0.3-1.3,0.4-2,0.3
									C2151.5,499.2,2151,498.9,2150.7,498.4z"
						/>
						<path
							d="M2159.2,500c0.5-2.7,1-5.5,1.4-8.2c0.6-3.8,0.9-6.7,1-8.9c1.1-0.3,2.2-0.3,3.5-0.1c-0.4,1.4-0.8,3.5-1.3,6.2
									c0.6-0.6,1.2-1,2-1.3s1.4-0.4,2.1-0.3c2.2,0.3,3,2,2.6,5l-0.4,2l-0.4,2.2c-0.3,2.3-0.4,3.9-0.2,4.9c-0.5,0.1-1.1,0-1.8-0.1
									c-0.5-0.1-1.1-0.2-1.7-0.4c0.5-2.8,1-5.5,1.4-8c0.1-0.9,0.1-1.5-0.2-1.9c-0.2-0.4-0.7-0.7-1.3-0.8c-0.9-0.1-1.7,0-2.4,0.4
									l-0.4,2.4c-0.6,3.9-0.7,6.3-0.5,7.4c-0.6,0-1.2,0-1.7-0.1C2160.5,500.4,2159.9,500.2,2159.2,500z"
						/>
						<path
							d="M2175,501c-0.7-1.2-0.8-2.9-0.5-5c0.2-1.4,0.7-2.7,1.3-3.7s1.5-1.8,2.5-2.3c1-0.5,2.1-0.6,3.2-0.5c1.5,0.2,2.6,1,3.2,2.2
									c0.6,1.3,0.8,3,0.4,5.3c-1,0.1-2.3,0.2-3.7,0.2c-1.4,0-2.8-0.1-4.1-0.3c0,1.2,0.2,2,0.6,2.6c0.4,0.6,1.1,1,2,1.1
									c0.8,0.1,1.5,0.1,2.2,0c0.7-0.1,1.3-0.3,1.9-0.7l0.1,1.1c-0.7,0.8-1.6,1.4-2.6,1.7c-1.1,0.4-2.1,0.5-3.1,0.3
									C2176.8,503,2175.7,502.2,2175,501z M2180,495.5c0.9-0.1,1.6-0.3,2.1-0.5c0.1-1.1,0-2-0.4-2.8c-0.4-0.8-1-1.2-1.8-1.3
									c-0.6,0.3-1.1,0.9-1.6,1.7c-0.5,0.8-0.8,1.8-0.9,3C2178.3,495.7,2179.2,495.6,2180,495.5z"
						/>
						<path
							d="M2197.5,500.5c0.2-1.2,0.5-2.7,0.7-4.6c0.6-4,0.8-6.5,0.7-7.5c0.6,0,1.1,0,1.7,0.1c0.5,0.1,1.1,0.2,1.7,0.5
									c-0.3,1.1-0.6,2.6-0.9,4.5c-0.3,1.9-0.6,3.6-0.9,5.2c-0.3,2.1-0.5,3.6-0.6,4.5c0.7,0.3,1.6,0.5,2.7,0.7c0.7,0.1,1.3,0.1,2,0.1
									s1.3-0.2,1.8-0.4c-0.1,0.7-0.3,1.4-0.5,2s-0.4,1.2-0.6,1.4c-1.3-0.3-2.7-0.6-4.3-0.9c-2-0.3-3.6-0.5-4.6-0.5
									C2196.9,503.4,2197.3,501.7,2197.5,500.5z"
						/>
						<path
							d="M2211.2,507.3c-0.7-0.7-1.2-1.5-1.5-2.6s-0.3-2.2-0.1-3.5c0.2-1.4,0.7-2.7,1.4-3.7c0.7-1,1.6-1.8,2.6-2.3s2.2-0.6,3.4-0.4
									c1.6,0.2,2.8,1.1,3.6,2.4c0.8,1.4,1.1,3,0.8,5c-0.2,1.4-0.7,2.7-1.4,3.7c-0.7,1-1.6,1.8-2.7,2.2c-1.1,0.5-2.2,0.6-3.4,0.4
									C2212.8,508.4,2211.9,508,2211.2,507.3z M2217.1,506.2c0.7-0.7,1.1-1.8,1.3-3.2c0.2-1.2,0.1-2.3-0.3-3.3c-0.4-1-1-1.7-1.8-2.2
									c-1-0.2-1.9,0.1-2.6,0.8c-0.7,0.7-1.2,1.8-1.4,3.1c-0.2,1.6-0.1,2.8,0.3,3.8c0.4,1,1.1,1.6,2,1.7
									C2215.5,507.2,2216.4,507,2217.1,506.2z"
						/>
						<path
							d="M2225,509.9c0.5-2.1,0.9-4.4,1.3-6.9c0.2-1.2,0.3-2.1,0.3-2.7c0-0.6,0-1.1-0.1-1.5c-0.1-0.4-0.2-0.8-0.5-1.2
									c0.4-0.4,0.8-0.6,1.2-0.7c0.4-0.1,0.8-0.2,1.1-0.1c1,0.2,1.5,0.9,1.4,2.2c0.6-0.6,1.2-1,2-1.2c0.7-0.3,1.4-0.3,2.1-0.2
									c2.2,0.3,3,2,2.5,5l-0.4,2l-0.4,2.2c-0.4,2.3-0.4,3.9-0.2,4.9c-0.5,0-1.1,0-1.8-0.1c-0.5-0.1-1.1-0.2-1.7-0.5c0.5-2.4,1-5.1,1.5-8
									c0.1-0.9,0.1-1.5-0.1-1.9c-0.2-0.4-0.7-0.7-1.3-0.8c-0.9-0.1-1.7,0-2.4,0.3l-0.2,0.9c-0.3,1.5-0.5,2.8-0.7,4
									c-0.4,2.3-0.4,3.9-0.2,4.9c-0.6,0-1.1,0-1.7-0.1C2226.3,510.3,2225.7,510.2,2225,509.9z"
						/>
						<path
							d="M2238.4,516.6c0.1-0.8,0.4-1.5,0.7-2.2c0.4-0.7,0.8-1.3,1.4-1.8c-0.3-0.2-0.5-0.5-0.6-0.9c-0.1-0.3-0.1-0.7-0.1-1.1
									c0.1-0.6,0.4-1.2,0.8-1.6s1-0.8,1.7-1c-0.5-0.5-0.9-1.1-1.1-1.8c-0.2-0.7-0.2-1.5-0.1-2.4c0.2-1,0.5-1.8,1.1-2.5
									c0.6-0.7,1.2-1.2,2.1-1.5c0.8-0.3,1.7-0.4,2.7-0.2c1,0.2,1.8,0.5,2.4,1.1c0.9-0.1,1.8-0.6,2.9-1.4c0.1,0.5,0.2,1,0.3,1.5
									c0,0.6,0,1,0,1.4c-0.6,0.3-1.3,0.4-2.1,0.3c0.2,0.7,0.2,1.5,0.1,2.4c-0.2,1-0.5,1.8-1.1,2.5c-0.6,0.7-1.3,1.2-2.1,1.5
									s-1.8,0.4-2.7,0.2c-0.8-0.1-1.4-0.1-1.9,0.1c-0.5,0.2-0.8,0.5-0.9,1l5.1,1.2c1,0.3,1.8,0.8,2.2,1.4c0.5,0.6,0.6,1.4,0.4,2.4
									c-0.1,0.9-0.5,1.6-1.2,2.2c-0.6,0.6-1.4,1-2.4,1.3c-1,0.2-2,0.3-3.1,0.1C2240.9,518.4,2239.4,517.7,2238.4,516.6z M2246.2,516.6
									c0.6-0.2,1-0.6,1.1-1.1c0.1-0.4,0-0.7-0.2-0.9c-0.2-0.2-0.7-0.4-1.3-0.5l-4-1c-0.3,0.5-0.5,1-0.7,1.5c0.5,1.2,1.5,1.8,2.9,2.1
									C2244.8,516.8,2245.6,516.8,2246.2,516.6z M2247,507.3c0.5-0.4,0.8-1,0.9-1.8c0.1-0.7,0-1.4-0.2-1.9c-0.3-0.6-0.7-1-1.3-1.3
									c-0.7-0.1-1.3,0.1-1.8,0.5c-0.5,0.5-0.9,1.1-1,1.9c-0.1,0.8,0,1.5,0.3,2c0.3,0.5,0.8,0.9,1.4,1
									C2245.9,507.8,2246.5,507.6,2247,507.3z"
						/>
						<path
							d="M2262.8,511.2c0.3-1.2,0.7-2.7,1.1-4.5c0.9-4,1.3-6.5,1.2-7.4c0.6,0,1.1,0.1,1.7,0.2c0.5,0.1,1.1,0.3,1.7,0.6
									c-0.3,1.1-0.7,2.6-1.2,4.4c-0.5,1.8-0.9,3.5-1.2,5.1c-0.5,2.1-0.8,3.6-0.9,4.5c0.7,0.4,1.6,0.7,2.6,0.9c0.7,0.1,1.3,0.2,2,0.2
									c0.7,0,1.3-0.1,1.8-0.2c-0.1,0.7-0.4,1.3-0.6,2c-0.3,0.7-0.5,1.1-0.7,1.3c-1.2-0.4-2.7-0.8-4.3-1.2c-2-0.5-3.6-0.7-4.6-0.8
									C2262,514.1,2262.5,512.4,2262.8,511.2z"
						/>
						<path
							d="M2274,518.6c-0.3-0.7-0.3-1.4-0.1-2.3c0.4-1.3,1.2-2.2,2.4-2.6s3-0.5,5.1,0c0.3-1.2,0.3-2,0.1-2.6c-0.2-0.6-0.7-1-1.6-1.2
									c-0.6-0.2-1.2-0.2-1.8-0.2c-0.6,0.1-1.2,0.2-1.8,0.4l0.1-1.1c0.8-0.8,1.7-1.4,2.7-1.7c1-0.3,2-0.4,2.8-0.2c1.2,0.3,2.1,1,2.5,1.9
									c0.5,0.9,0.5,2,0.1,3.4c-0.2,0.8-0.5,1.6-0.8,2.5c-0.3,1-0.6,1.7-0.7,2.2c-0.2,0.8-0.3,1.4-0.3,1.9c0,0.5,0.2,1,0.4,1.7
									c-0.9,0.5-1.8,0.7-2.8,0.5c-0.4-0.5-0.6-1.2-0.6-2c-0.8,0.4-1.6,0.6-2.3,0.7c-0.8,0.1-1.4,0.1-2-0.1
									C2274.7,519.7,2274.2,519.3,2274,518.6z M2278.6,518.5c0.5-0.1,1-0.3,1.5-0.5l0.1-0.2c0.2-0.6,0.4-1.2,0.6-1.8l0.4-1.2
									c-1.1-0.2-2.1-0.2-2.8,0.1s-1.2,0.9-1.4,1.6c-0.1,0.3-0.1,0.7,0,1.1c0.1,0.4,0.2,0.7,0.4,1C2277.6,518.6,2278,518.6,2278.6,518.5z
									"
						/>
						<path
							d="M2286.7,522.7c1-2.6,1.8-5.3,2.7-7.9c1.1-3.6,1.9-6.5,2.4-8.6c1.1-0.1,2.3,0,3.5,0.4c-0.5,1.1-1,2.5-1.7,4.4
									c-0.7,1.9-1.3,3.7-1.8,5.4l-0.2,0.6c2.4-1.6,4-2.7,4.8-3.4c0.8-0.6,1.3-1.1,1.4-1.5c1,0.1,2,0.2,3,0.6c-1.5,0.8-3.3,2-5.5,3.5
									c0.8,5,1.7,8.1,2.4,9.3c-1,0.2-2.3,0-3.7-0.4c-0.1-1.2-0.6-3.6-1.3-7.1l-1.7,1.2c-0.6,2.2-0.9,3.7-0.8,4.5c-0.6,0-1.1-0.1-1.7-0.3
									C2287.9,523.3,2287.3,523,2286.7,522.7z"
						/>
						<path
							d="M2300.9,525.8c-0.4-1.3-0.3-3,0.4-5.1c0.5-1.4,1.1-2.5,2-3.4c0.9-0.9,1.8-1.5,2.9-1.8c1.1-0.3,2.1-0.2,3.3,0.2
									c1.4,0.5,2.3,1.4,2.7,2.8c0.4,1.4,0.2,3.1-0.6,5.3c-1-0.1-2.3-0.2-3.6-0.5s-2.7-0.6-4-1c-0.2,1.1-0.2,2,0.1,2.7
									c0.3,0.7,0.9,1.1,1.7,1.4c0.7,0.3,1.5,0.4,2.2,0.4c0.7,0,1.4-0.1,2-0.3l-0.1,1.1c-0.8,0.6-1.8,1-2.9,1.2c-1.1,0.2-2.2,0.1-3.1-0.2
									C2302.2,528.1,2301.3,527.2,2300.9,525.8z M2306.8,521.4c0.9,0.1,1.6,0,2.2-0.1c0.3-1,0.4-2,0.1-2.8c-0.2-0.8-0.7-1.4-1.5-1.7
									c-0.6,0.2-1.3,0.7-1.9,1.4c-0.6,0.7-1.1,1.7-1.5,2.7C2305.1,521.2,2306,521.3,2306.8,521.4z"
						/>
						<path
							d="M2139.9,471.8c-0.1-1.4-0.3-3-0.5-4.9c-0.2-2-0.4-3.5-0.6-4.6c-0.2-1.1-0.4-2-0.6-2.7c1,0,2.5,0,4.6-0.3
									c2.5-0.3,4.4-0.5,5.8-0.9c0.1,0.7,0.1,1.4,0.1,2.1c0,0.8-0.1,1.3-0.3,1.6c-0.5-0.2-1.1-0.4-1.9-0.5c-0.7-0.1-1.5-0.1-2.3,0
									c-0.8,0.1-1.7,0.3-2.5,0.5c0.1,1.7,0.2,3.2,0.3,4.5c0.9-0.1,1.6-0.1,2.1-0.2c0.9-0.1,1.6-0.2,2.1-0.3s1-0.3,1.6-0.5
									c0.1,0.5,0.2,1,0.3,1.3c0,0.3,0,0.8,0,1.4c-2.1,0.1-3.8,0.1-4.9,0.3l-0.9,0.1c0.2,2.1,0.4,3.6,0.6,4.6c0.9,0.1,1.8,0.1,2.7,0
									c0.8-0.1,1.7-0.3,2.5-0.5s1.5-0.5,2-0.9c0.1,0.7,0.1,1.4,0.1,2.1c0,0.8-0.1,1.3-0.3,1.6c-1.3,0-3,0.1-5.3,0.3
									c-2,0.2-3.6,0.5-4.6,0.7C2140.1,474.9,2140,473.2,2139.9,471.8z"
						/>
						<path
							d="M2154.3,474.6c0-0.9,0.1-1.7,0.4-2.6c0.4,0.5,0.9,0.9,1.5,1.2c0.6,0.3,1.3,0.4,2,0.4c1.6,0,2.4-0.6,2.4-1.6
									c0-0.4-0.2-0.8-0.6-1.1c-0.4-0.3-1-0.6-1.8-0.9c-0.9-0.4-1.5-0.8-2.1-1.1c-0.5-0.3-1-0.8-1.4-1.4c-0.4-0.6-0.6-1.3-0.6-2.2
									c0-1.1,0.4-2,1.2-2.7c0.8-0.7,1.9-1.1,3.2-1.1c1.7,0,3.1,0.2,4.3,0.7c0,0.4-0.1,0.9-0.2,1.6c-0.2,0.7-0.3,1.3-0.6,1.7
									c-0.7-0.7-1.4-1.2-2.1-1.6c-0.7-0.4-1.2-0.6-1.7-0.6c-0.4,0.1-0.7,0.3-0.9,0.5c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.5,0.2,0.9,0.6,1.3
									s1,0.7,1.9,1.1c0.8,0.4,1.4,0.7,2,1.1c0.5,0.3,1,0.8,1.3,1.3c0.4,0.5,0.6,1.2,0.6,2c0,1.3-0.4,2.3-1.2,3s-2.1,1.1-3.6,1.1
									C2157,475.7,2155.5,475.3,2154.3,474.6z"
						/>
						<path
							d="M2166.9,479.6c0-0.8,0.2-1.5,0.5-2.2c0.3-0.7,0.7-1.4,1.2-1.9c-0.3-0.2-0.5-0.5-0.7-0.8c-0.2-0.3-0.2-0.7-0.2-1.1
									c0-0.6,0.3-1.2,0.7-1.7c0.4-0.5,0.9-0.9,1.6-1.1c-0.6-0.4-1-1-1.3-1.7s-0.4-1.5-0.4-2.4c0.1-1,0.3-1.9,0.8-2.6
									c0.5-0.8,1.1-1.3,1.9-1.7c0.8-0.4,1.7-0.6,2.7-0.5c1,0.1,1.8,0.4,2.5,0.9c0.8-0.2,1.8-0.8,2.8-1.7c0.2,0.4,0.3,0.9,0.4,1.5
									c0.1,0.6,0.1,1,0.1,1.4c-0.6,0.3-1.2,0.5-2.1,0.5c0.3,0.7,0.4,1.5,0.3,2.4c-0.1,1-0.3,1.9-0.8,2.6c-0.5,0.8-1.1,1.3-2,1.7
									c-0.8,0.4-1.7,0.5-2.7,0.5c-0.8,0-1.4,0-1.9,0.3c-0.5,0.2-0.7,0.6-0.8,1.1l5.2,0.7c1.1,0.2,1.8,0.6,2.4,1.1
									c0.5,0.6,0.8,1.4,0.7,2.4c-0.1,0.9-0.4,1.6-0.9,2.3c-0.6,0.7-1.3,1.2-2.2,1.5c-0.9,0.3-2,0.5-3.1,0.4
									C2169.5,481.2,2168,480.6,2166.9,479.6z M2174.7,478.8c0.6-0.3,0.9-0.7,0.9-1.2c0-0.4-0.1-0.7-0.3-0.9c-0.3-0.2-0.7-0.3-1.3-0.4
									l-4.1-0.5c-0.2,0.5-0.4,1-0.6,1.5c0.6,1.1,1.7,1.7,3.1,1.8C2173.3,479.1,2174.1,479,2174.7,478.8z M2174.4,469.4
									c0.4-0.4,0.7-1.1,0.7-1.9c0-0.7-0.1-1.4-0.4-1.9s-0.8-0.9-1.4-1.2c-0.7,0-1.3,0.2-1.8,0.7c-0.5,0.5-0.8,1.2-0.8,2
									c0,0.8,0.1,1.5,0.5,2s0.9,0.8,1.5,0.8C2173.4,470,2174,469.8,2174.4,469.4z"
						/>
						<path
							d="M2182.2,475.9c-0.4-0.6-0.5-1.4-0.4-2.3c0.2-1.3,0.9-2.3,2.1-2.9c1.2-0.6,2.9-0.8,5.1-0.7c0.2-1.2,0.1-2.1-0.2-2.6
									c-0.3-0.5-0.9-0.9-1.7-1c-0.6-0.1-1.2-0.1-1.8,0.1s-1.2,0.3-1.7,0.6l-0.1-1.1c0.7-0.9,1.5-1.5,2.5-2c1-0.5,1.9-0.6,2.8-0.5
									c1.3,0.2,2.2,0.7,2.7,1.5c0.6,0.8,0.7,2,0.5,3.4c-0.1,0.8-0.3,1.6-0.5,2.6c-0.2,1-0.4,1.8-0.4,2.3c-0.1,0.8-0.1,1.5-0.1,1.9
									c0.1,0.5,0.3,1,0.6,1.6c-0.8,0.6-1.7,0.9-2.7,0.9c-0.5-0.5-0.7-1.1-0.8-1.9c-0.7,0.4-1.5,0.8-2.2,1c-0.8,0.2-1.4,0.3-2,0.2
									C2183.1,476.9,2182.6,476.5,2182.2,475.9z M2186.7,475.2c0.5-0.2,1-0.4,1.4-0.7l0-0.2c0.1-0.6,0.2-1.2,0.4-1.8l0.3-1.3
									c-1.2-0.1-2.1,0.1-2.8,0.5c-0.7,0.4-1.1,1-1.2,1.8c0,0.3,0,0.7,0.1,1.1c0.1,0.4,0.3,0.7,0.5,0.9
									C2185.8,475.4,2186.2,475.4,2186.7,475.2z"
						/>
						<path
							d="M2196.9,478.7c0.5-2.1,1-4.4,1.4-6.8c0.2-1.2,0.3-2.1,0.4-2.7c0-0.6,0-1.1,0-1.5c-0.1-0.4-0.2-0.8-0.5-1.3
									c0.4-0.4,0.8-0.6,1.2-0.7c0.4-0.1,0.8-0.1,1.1-0.1c1.1,0.2,1.6,1.1,1.4,2.7c1.2-1.2,2.4-1.9,3.8-2.1c0.1,0.4,0.1,1,0,1.8
									c0,0.7-0.1,1.4-0.2,2c-0.3-0.1-0.6-0.2-0.9-0.3c-0.6-0.1-1.1-0.1-1.7-0.1c-0.5,0.1-1,0.2-1.4,0.4c-0.3,1.5-0.6,3-0.8,4.3
									c-0.4,2.3-0.5,3.9-0.3,4.9c-0.6,0-1.1,0-1.7-0.1C2198.1,479.1,2197.5,479,2196.9,478.7z"
						/>
						<path
							d="M2209.5,480.6c-0.7-0.7-1.1-1.6-1.4-2.7c-0.2-1.1-0.2-2.2,0.1-3.5c0.3-1.4,0.8-2.6,1.6-3.6c0.7-1,1.7-1.7,2.7-2.1
									c1.1-0.4,2.2-0.5,3.4-0.3c1.6,0.3,2.8,1.2,3.5,2.6c0.7,1.4,0.9,3.1,0.5,5c-0.3,1.4-0.8,2.6-1.6,3.6c-0.8,1-1.7,1.7-2.8,2.1
									c-1.1,0.4-2.2,0.5-3.5,0.2C2211,481.8,2210.1,481.3,2209.5,480.6z M2215.4,479.9c0.7-0.7,1.2-1.7,1.5-3.1c0.2-1.2,0.2-2.3-0.1-3.3
									c-0.3-1-0.9-1.8-1.7-2.3c-1-0.2-1.9,0-2.7,0.7c-0.8,0.7-1.3,1.7-1.6,3.1c-0.3,1.5-0.3,2.8,0.1,3.8c0.4,1,1,1.6,1.9,1.8
									C2213.8,480.8,2214.7,480.5,2215.4,479.9z"
						/>
						<path
							d="M2224.5,484c-0.2-0.5-0.2-1.2,0-2.2c0.1-0.6,0.4-1.7,0.7-3.1c0.4-1.6,0.7-2.9,0.9-3.9c0.1-0.4,0.2-0.9,0.2-1.3
									c-0.7-0.2-1.2-0.3-1.4-0.5c-0.2-0.2-0.3-0.5-0.2-0.9c1.4-0.5,2.8-1.3,4.3-2.5l0.9,0.3c-0.1,0.7-0.2,1.5-0.4,2.3
									c1.2,0.2,2.1,0.4,2.7,0.3c-0.1,0.5-0.2,0.9-0.4,1.3c-0.2,0.4-0.4,0.7-0.6,1.1c-0.5-0.4-1.2-0.6-2.2-0.9c-0.3,0.9-0.5,1.8-0.7,2.8
									c-0.5,2.4-0.8,4.4-0.9,5.8c0.7,0.1,1.4,0.2,2.1,0.2l0,1c-0.5,0.5-1.1,0.9-1.8,1.1c-0.7,0.2-1.4,0.3-2,0.1
									C2225.2,484.8,2224.8,484.4,2224.5,484z"
						/>
						<path
							d="M2233.7,486.3c0.7-2.7,1.4-5.4,2-8.1c0.8-3.7,1.4-6.6,1.7-8.8c1.1-0.2,2.3-0.2,3.5,0.1c-0.5,1.4-1.1,3.4-1.8,6.1
									c0.6-0.5,1.3-0.9,2-1.1c0.7-0.2,1.4-0.2,2.1-0.1c2.1,0.5,2.9,2.2,2.2,5.1l-0.5,2l-0.5,2.1c-0.5,2.2-0.7,3.9-0.5,4.9
									c-0.5,0-1.1-0.1-1.8-0.2c-0.5-0.1-1-0.3-1.6-0.6c0.8-2.7,1.4-5.4,2-7.9c0.2-0.9,0.2-1.5,0-1.9c-0.2-0.4-0.6-0.7-1.3-0.9
									c-0.9-0.2-1.7-0.1-2.5,0.2l-0.6,2.4c-0.9,3.8-1.2,6.3-1.1,7.3c-0.6,0-1.2-0.1-1.7-0.2C2234.9,486.8,2234.3,486.6,2233.7,486.3z"
						/>
						<path
							d="M2259.1,487.9l0.5-2l0.6-2.1c0.5-2.1,0.7-3.6,0.5-4.6c1.1-0.2,2.3-0.1,3.5,0.2c-0.6,1.8-1.3,4.7-2.2,8.6
									c-0.2,0.8-0.2,1.5,0,1.9c0.2,0.4,0.6,0.7,1.2,0.9c0.9,0.2,1.7,0.2,2.4-0.1c0.3-1.3,0.6-2.5,0.9-3.5l0.4-1.6
									c0.5-2.1,0.7-3.6,0.5-4.6c1.1-0.2,2.3-0.1,3.5,0.2c-0.4,1.4-0.9,3-1.3,4.7c-0.5,1.8-0.9,3.1-1.1,4.1c-0.2,0.8-0.3,1.5-0.2,2
									c0,0.5,0.2,1.1,0.5,1.7c-0.4,0.3-0.9,0.4-1.4,0.6c-0.5,0.1-1,0.2-1.4,0.1c-0.4-0.6-0.6-1.3-0.6-2.2c-0.6,0.5-1.3,0.8-2,1
									c-0.7,0.2-1.4,0.2-2.1,0C2259.1,492.5,2258.4,490.8,2259.1,487.9z"
						/>
						<path
							d="M2273.8,496.2c0.6-2.3,1.3-4.7,1.9-7.2c0.3-1.2,0.5-2.1,0.6-2.7c0.1-0.6,0.1-1.1,0.1-1.5c0-0.4-0.2-0.8-0.4-1.3
									c0.4-0.3,0.8-0.5,1.2-0.6c0.4-0.1,0.8-0.1,1.1,0c0.9,0.2,1.3,0.9,1.2,2.1c0.6-0.4,1.3-0.6,1.9-0.8c0.7-0.1,1.3-0.1,1.9,0
									c1.4,0.4,2.4,1.2,2.9,2.4c0.5,1.3,0.5,3,0,5.1c-0.5,2.2-1.5,3.8-2.8,4.8c-1.3,1-2.9,1.3-4.7,0.8c-0.8-0.2-1.5-0.6-2-1
									c-0.5,2.4-0.8,4.2-0.9,5.4c-1.1,0.2-2.3,0.1-3.5-0.2C2272.6,500.2,2273.2,498.4,2273.8,496.2z M2281.4,495c0.7-0.6,1.2-1.6,1.6-3
									c0.3-1.2,0.4-2.3,0.2-3.3c-0.2-1-0.6-1.7-1.3-2.2c-0.4-0.1-0.9-0.2-1.4-0.1s-1,0.1-1.4,0.2c-0.1,0.3-0.3,0.9-0.5,1.8
									c-0.7,2.5-1.1,4-1.2,4.4l-0.3,1c0.4,1,1,1.5,1.8,1.7C2279.9,495.8,2280.7,495.6,2281.4,495z"
						/>
						<path
							d="M2291.3,499.6c-0.6-0.7-1-1.7-1.2-2.7c-0.2-1.1-0.1-2.3,0.2-3.5c0.4-1.4,1-2.6,1.8-3.5c0.8-1,1.7-1.6,2.8-2
									s2.2-0.4,3.4-0.1c1.6,0.4,2.7,1.3,3.4,2.8c0.7,1.4,0.7,3.1,0.2,5c-0.4,1.4-1,2.6-1.8,3.5c-0.8,0.9-1.8,1.6-2.9,2
									c-1.1,0.4-2.3,0.4-3.5,0.1C2292.7,500.9,2291.9,500.4,2291.3,499.6z M2297.2,499.2c0.7-0.6,1.3-1.6,1.7-3c0.3-1.2,0.3-2.3,0.1-3.3
									s-0.8-1.8-1.6-2.3c-1-0.3-1.9-0.1-2.7,0.5c-0.8,0.6-1.4,1.6-1.7,3c-0.4,1.5-0.4,2.8-0.1,3.8c0.3,1,0.9,1.7,1.8,1.9
									C2295.6,500,2296.5,499.8,2297.2,499.2z"
						/>
						<path
							d="M2305.4,503.9c0.7-2.1,1.4-4.3,2.1-6.7c0.3-1.2,0.6-2,0.7-2.7c0.1-0.6,0.2-1.1,0.1-1.5c0-0.4-0.1-0.8-0.3-1.3
									c0.4-0.3,0.9-0.5,1.3-0.6c0.4-0.1,0.8-0.1,1.1,0c1,0.3,1.4,1.1,1.2,2.4c0.6-0.5,1.3-0.8,2.1-1c0.7-0.2,1.5-0.2,2.1,0
									c2.1,0.6,2.7,2.3,1.9,5.2l-0.6,2l-0.7,2.1c-0.6,2.2-0.9,3.8-0.8,4.9c-0.5,0-1.1-0.1-1.8-0.3c-0.5-0.1-1.1-0.4-1.6-0.7
									c0.8-2.4,1.6-4.9,2.4-7.8c0.2-0.8,0.3-1.5,0.1-1.9c-0.2-0.4-0.6-0.8-1.2-0.9c-0.9-0.3-1.7-0.2-2.4,0l-0.3,0.9
									c-0.5,1.5-0.8,2.7-1.2,3.9c-0.6,2.2-0.9,3.8-0.8,4.9c-0.6,0-1.1-0.1-1.7-0.3C2306.6,504.4,2306,504.2,2305.4,503.9z"
						/>
					</g>
					<g className="level_two fade location " id="mountains_of_mirkwood">
						<rect x="4243.99" y="1459.169" width="275.575" height="32.1" transform="matrix(0.999976, 0.006935, -0.01898, 0.999892, -2140.048828, -955.976929)"></rect>
						<path
							d="M2086.4,557c0.1-1.7,0.1-4.1,0-7.3c0-1.5-0.1-2.7-0.2-3.5s-0.2-1.5-0.3-2c0.4-0.1,0.9-0.2,1.4-0.2c0.4,0,0.9,0,1.5,0.1
									c0.6,1.2,1.3,2.8,2.1,4.6s1.6,3.3,2.2,4.4c0.6-1.3,1.2-2.9,2-4.8c0.8-1.9,1.4-3.4,1.8-4.4c0.4-0.1,0.8-0.2,1.4-0.2
									c0.4,0,1,0,1.6,0.1c-0.1,0.8-0.1,1.9-0.1,3.3c0,1.4,0,2.7,0,4c0.1,3,0.3,4.8,0.5,5.5c-0.5,0.1-1,0.2-1.5,0.2c-0.4,0-0.9,0-1.5-0.1
									c0.1-0.8,0.1-1.9,0.1-3.3c0-1.4,0-2.7,0-4c0-0.7-0.1-1.5-0.1-2.5c-1.2,2.8-2.6,6.1-4.1,9.9c-0.2,0.1-0.4,0.1-0.6,0.1
									c-0.2,0-0.3,0-0.5-0.1c-1.5-3.3-2.9-6.2-4.2-9c0,1.6,0,2.7,0,3.5c0.1,2.9,0.2,4.8,0.4,5.5c-0.3,0.1-0.7,0.2-1,0.2
									C2087.2,557.1,2086.8,557.1,2086.4,557z"
						/>
						<path
							d="M2108.6,556.1c-0.7-0.4-1.2-1-1.6-1.7c-0.4-0.7-0.6-1.6-0.6-2.6c0-1.1,0.2-2,0.6-2.9c0.4-0.8,1-1.5,1.8-2
									c0.8-0.5,1.7-0.7,2.7-0.8c1.4,0,2.5,0.4,3.4,1.3s1.3,2.1,1.3,3.6c0,1.1-0.2,2-0.6,2.9c-0.4,0.8-1.1,1.5-1.9,2
									c-0.8,0.5-1.7,0.7-2.8,0.8C2110,556.6,2109.3,556.5,2108.6,556.1z M2113.3,554.5c0.5-0.6,0.7-1.4,0.7-2.5c0-0.9-0.2-1.7-0.7-2.4
									c-0.4-0.7-1-1.1-1.8-1.4c-0.9,0-1.6,0.3-2.1,0.9c-0.5,0.6-0.7,1.4-0.7,2.5c0,1.2,0.3,2.1,0.8,2.8s1.1,1,1.9,1
									C2112.2,555.4,2112.8,555.1,2113.3,554.5z"
						/>
						<path
							d="M2122.7,553l0-1.5l0-1.6c0-1.6-0.2-2.7-0.5-3.4c0.8-0.3,1.8-0.5,2.9-0.5c-0.1,1.4-0.1,3.6,0,6.6c0,0.6,0.1,1.1,0.4,1.4
									c0.2,0.3,0.6,0.4,1.2,0.4c0.7,0,1.4-0.2,1.9-0.5c0-1,0-1.9,0-2.7l0-1.2c0-1.6-0.2-2.7-0.5-3.4c0.8-0.3,1.8-0.5,2.9-0.5
									c0,1.1-0.1,2.3-0.1,3.6c0,1.4,0,2.4-0.1,3.2c0,0.6,0.1,1.1,0.2,1.5c0.1,0.3,0.4,0.7,0.7,1.1c-0.3,0.3-0.7,0.5-1,0.7
									c-0.4,0.2-0.8,0.3-1.1,0.3c-0.5-0.3-0.8-0.8-0.9-1.4c-0.4,0.5-0.9,0.8-1.5,1.1s-1.1,0.4-1.7,0.4
									C2123.6,556.4,2122.7,555.3,2122.7,553z"
						/>
						<path
							d="M2144.6,555.8c0.1-1.6,0.1-3.4,0.1-5.2c0-0.9,0-1.6-0.1-2c-0.1-0.5-0.2-0.8-0.3-1.1c-0.1-0.3-0.3-0.6-0.6-0.8
									c0.3-0.3,0.6-0.5,0.9-0.7c0.3-0.1,0.6-0.2,0.9-0.2c0.9,0,1.4,0.5,1.5,1.4c0.4-0.5,0.9-0.9,1.4-1.2s1.1-0.4,1.7-0.4
									c1.8,0,2.8,1.1,2.8,3.3l0,1.5l0,1.6c0,1.7,0.2,2.9,0.5,3.6c-0.4,0.1-0.9,0.2-1.5,0.2c-0.4,0-0.9,0-1.5-0.1c0.1-1.9,0.1-3.9,0.1-6
									c0-0.6-0.1-1.1-0.4-1.4c-0.2-0.3-0.6-0.4-1.2-0.4c-0.8,0-1.4,0.2-1.9,0.5l0,0.7c0,1.1,0,2.1,0,3c0,1.7,0.2,2.9,0.5,3.6
									c-0.5,0.1-0.9,0.2-1.4,0.2C2145.7,555.9,2145.2,555.9,2144.6,555.8z"
						/>
						<path
							d="M2158.3,555.4c-0.3-0.3-0.4-0.8-0.4-1.6c0-0.5,0-1.3,0-2.4c0-1.2,0-2.2,0-3c0-0.3,0-0.6-0.1-1c-0.6,0-1.1,0-1.3-0.1
									c-0.2-0.1-0.3-0.3-0.3-0.6c1.1-0.6,2.1-1.4,3-2.5l0.8,0.1c0.1,0.6,0.1,1.1,0.1,1.7c1.1,0,1.8-0.1,2.3-0.2c0,0.4,0,0.7-0.1,1
									s-0.2,0.6-0.3,0.9c-0.5-0.2-1.1-0.3-2-0.3c0,0.7-0.1,1.4-0.1,2.1c0,1.9,0.1,3.3,0.3,4.4c0.6,0,1.2-0.1,1.8-0.2l0.1,0.7
									c-0.3,0.5-0.7,0.8-1.2,1.1s-1.1,0.4-1.6,0.4C2159,555.9,2158.5,555.7,2158.3,555.4z"
						/>
						<path
							d="M2165.4,555.2c-0.4-0.4-0.6-1-0.6-1.6c0-1,0.4-1.8,1.4-2.4c0.9-0.6,2.3-0.9,4.2-1.1c0-0.9-0.2-1.5-0.5-1.9
									c-0.3-0.4-0.8-0.6-1.6-0.6c-0.5,0-1,0.1-1.5,0.2s-1,0.4-1.4,0.7l-0.2-0.8c0.5-0.7,1-1.3,1.8-1.8c0.7-0.4,1.5-0.7,2.3-0.7
									c1.1,0,1.9,0.3,2.5,0.8c0.6,0.6,0.9,1.4,0.9,2.4c0,0.6,0,1.2-0.1,2c-0.1,0.8-0.1,1.4-0.1,1.7c0,0.6,0.1,1.1,0.2,1.4
									s0.4,0.7,0.7,1.1c-0.6,0.5-1.3,0.8-2.1,0.9c-0.4-0.3-0.7-0.7-0.9-1.3c-0.6,0.4-1.1,0.7-1.7,1s-1.2,0.3-1.7,0.3
									C2166.3,555.9,2165.8,555.7,2165.4,555.2z M2169.1,554.2c0.4-0.2,0.8-0.4,1.1-0.6v-0.2c0-0.5,0-0.9,0.1-1.4l0.1-1
									c-1,0.1-1.7,0.3-2.3,0.7s-0.8,0.9-0.8,1.5c0,0.3,0.1,0.5,0.2,0.8s0.3,0.5,0.5,0.6C2168.4,554.5,2168.7,554.4,2169.1,554.2z"
						/>
						<path
							d="M2176.4,542.4c0.5-0.6,1-1.2,1.6-1.7c0.5,0.6,1,1.2,1.6,1.7c-0.5,0.6-1,1.2-1.6,1.7C2177.6,543.4,2177,542.9,2176.4,542.4
									z M2177.2,555.3c-0.2-0.3-0.3-0.8-0.3-1.6c0-0.6,0-1.5,0-2.6c0-1,0-1.8,0-2.3c0-0.6,0-1.3-0.1-1.8s-0.2-1-0.3-1.2
									c0.5-0.2,1-0.3,1.4-0.4c0.4-0.1,0.9-0.1,1.5-0.1c-0.1,0.5-0.1,1.2-0.2,2.2c-0.1,1-0.1,2.1-0.1,3.3c0,0.9,0,1.6,0.1,2
									s0.1,0.8,0.3,1.1s0.3,0.6,0.6,0.9c-0.3,0.3-0.6,0.5-0.9,0.7c-0.3,0.1-0.6,0.2-0.9,0.2C2177.8,555.8,2177.4,555.6,2177.2,555.3z"
						/>
						<path
							d="M2184,555.6c0.1-1.6,0.2-3.4,0.2-5.2c0-0.9,0-1.6-0.1-2c-0.1-0.5-0.1-0.8-0.3-1.1s-0.3-0.6-0.6-0.9
									c0.3-0.3,0.6-0.5,0.9-0.7c0.3-0.1,0.6-0.2,0.9-0.2c0.9,0,1.4,0.5,1.5,1.5c0.4-0.5,0.9-0.9,1.5-1.1c0.6-0.3,1.1-0.4,1.7-0.4
									c1.8,0,2.8,1.1,2.7,3.4l0,1.5l0,1.6c0,1.7,0.2,2.9,0.5,3.7c-0.4,0.1-0.9,0.1-1.5,0.1c-0.4,0-0.9,0-1.5-0.1c0.1-1.9,0.2-3.9,0.2-6
									c0-0.6-0.1-1.1-0.4-1.4c-0.2-0.3-0.6-0.4-1.2-0.4c-0.8,0-1.4,0.2-1.9,0.5l0,0.7c0,1.1,0,2.1-0.1,3c0,1.7,0.2,2.9,0.5,3.7
									c-0.5,0.1-0.9,0.1-1.4,0.1C2185,555.7,2184.5,555.7,2184,555.6z"
						/>
						<path
							d="M2195.8,555c0-0.6,0.1-1.3,0.4-1.9c0.3,0.4,0.7,0.7,1.2,0.9c0.5,0.2,1.1,0.4,1.7,0.4c1.4,0,2-0.4,2.1-1.1
									c0-0.3-0.2-0.6-0.5-0.8c-0.3-0.2-0.8-0.5-1.5-0.7c-0.7-0.3-1.3-0.6-1.7-0.9c-0.4-0.3-0.8-0.6-1.1-1.1s-0.5-1-0.5-1.6
									c0-0.8,0.4-1.4,1.1-1.9c0.7-0.5,1.6-0.7,2.7-0.7c1.4,0,2.6,0.2,3.6,0.6c0,0.3-0.1,0.7-0.2,1.2c-0.2,0.5-0.3,0.9-0.5,1.3
									c-0.6-0.5-1.2-0.9-1.7-1.2c-0.5-0.3-1-0.5-1.4-0.5c-0.3,0.1-0.6,0.2-0.8,0.4c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.4,0.2,0.7,0.5,1
									s0.9,0.5,1.6,0.8c0.7,0.3,1.2,0.6,1.6,0.8c0.4,0.3,0.8,0.6,1.1,1s0.4,0.9,0.4,1.5c0,0.9-0.4,1.7-1.1,2.2c-0.7,0.5-1.8,0.8-3.1,0.8
									C2198,555.9,2196.8,555.6,2195.8,555z"
						/>
						<path
							d="M2215,555.3c-0.7-0.4-1.2-1-1.5-1.8s-0.5-1.6-0.5-2.6c0-1.1,0.2-2,0.7-2.9c0.5-0.8,1.1-1.5,1.9-1.9
									c0.8-0.5,1.7-0.7,2.7-0.7c1.4,0,2.5,0.5,3.3,1.4c0.8,0.9,1.2,2.1,1.2,3.6c0,1.1-0.2,2-0.7,2.9c-0.5,0.8-1.1,1.5-1.9,1.9
									c-0.8,0.5-1.8,0.7-2.8,0.7C2216.5,556,2215.7,555.8,2215,555.3z M2219.7,553.9c0.5-0.6,0.7-1.4,0.7-2.5c0-0.9-0.2-1.7-0.6-2.4
									s-1-1.2-1.8-1.4c-0.9,0-1.6,0.3-2.1,0.9c-0.5,0.6-0.8,1.4-0.8,2.5c0,1.2,0.2,2.1,0.7,2.8c0.5,0.7,1.1,1,1.8,1.1
									C2218.6,554.8,2219.3,554.5,2219.7,553.9z"
						/>
						<path
							d="M2225.7,559.3c0.3-0.4,0.6-0.9,0.7-1.2c0.2-0.4,0.3-0.9,0.4-1.5c0.1-0.6,0.1-1.5,0.1-2.6c0-1.2,0-3.3-0.1-6.3
									c-0.6,0-1-0.1-1.2-0.2c-0.2-0.1-0.3-0.3-0.3-0.6c0.4-0.2,0.9-0.5,1.4-0.9c0.2-1.3,0.7-2.4,1.5-3.2c0.8-0.8,1.7-1.2,2.8-1.2
									c0.9,0,1.7,0.1,2.3,0.4c0,0.3-0.1,0.7-0.2,1.2c-0.1,0.5-0.3,0.9-0.5,1.3c-0.5-0.5-1-0.8-1.5-1.1c-0.5-0.3-0.9-0.4-1.2-0.4
									c-0.3,0.2-0.4,0.5-0.6,1c-0.1,0.5-0.2,1.2-0.2,2.3c1.2,0,2-0.1,2.4-0.2c0,0.6-0.1,1.2-0.4,1.8c-0.5-0.2-1.2-0.3-2.1-0.3l0,1.5
									c0,1.1,0,2.2,0.1,3.5c0,0.7,0.1,1.3,0.1,1.7c0,0.8-0.1,1.5-0.3,2c-0.2,0.6-0.5,1.1-0.9,1.6c-0.4,0.5-1.1,1.1-1.9,1.8L2225.7,559.3
									z"
						/>
						<path
							d="M2241.1,555.9c0.1-1.7,0.2-4.1,0.2-7.3c0-1.5,0-2.7-0.1-3.5c0-0.9-0.1-1.5-0.2-2c0.4-0.1,0.9-0.1,1.4-0.1
									c0.4,0,0.9,0,1.5,0.1c0.5,1.3,1.2,2.8,2,4.7c0.8,1.9,1.5,3.4,2,4.5c0.6-1.3,1.3-2.9,2.1-4.8c0.8-1.9,1.5-3.3,1.9-4.4
									c0.4-0.1,0.8-0.1,1.4-0.1c0.4,0,1,0,1.6,0.1c-0.1,0.8-0.1,1.9-0.2,3.3s-0.1,2.7-0.1,4c0,3,0.1,4.8,0.4,5.5c-0.5,0.1-1,0.1-1.5,0.1
									c-0.4,0-0.9,0-1.5-0.1c0.1-0.8,0.1-1.9,0.2-3.3s0.1-2.7,0.1-4c0-0.7,0-1.5,0-2.5c-1.3,2.8-2.8,6.1-4.3,9.8
									c-0.2,0.1-0.4,0.1-0.6,0.1c-0.2,0-0.3,0-0.5-0.1c-1.4-3.3-2.7-6.3-4-9.1c0,1.6,0,2.7,0,3.5c0,2.9,0.1,4.8,0.3,5.5
									c-0.3,0.1-0.7,0.1-1,0.1C2241.8,556,2241.5,556,2241.1,555.9z"
						/>
						<path
							d="M2259.4,542.6c0.5-0.6,1-1.2,1.6-1.7c0.5,0.6,1,1.1,1.6,1.6c-0.5,0.6-1,1.2-1.6,1.7C2260.5,543.7,2260,543.1,2259.4,542.6
									z M2260.2,555.6c-0.2-0.3-0.3-0.8-0.3-1.6c0-0.6,0-1.5,0-2.6c0-1,0-1.8,0-2.3c0-0.6,0-1.3-0.1-1.8c-0.1-0.6-0.2-1-0.3-1.2
									c0.5-0.2,1-0.3,1.4-0.4s0.9-0.1,1.5-0.1c-0.1,0.5-0.1,1.2-0.2,2.2c0,1-0.1,2.1-0.1,3.3c0,0.9,0,1.6,0.1,2c0.1,0.5,0.1,0.8,0.3,1.1
									s0.3,0.6,0.6,0.8c-0.3,0.3-0.6,0.5-0.9,0.7s-0.6,0.2-0.9,0.2C2260.8,556,2260.4,555.9,2260.2,555.6z"
						/>
						<path
							d="M2267.8,555.8c0.1-1.6,0.1-3.4,0.1-5.2c0-0.9,0-1.6-0.1-2c-0.1-0.5-0.1-0.8-0.3-1.1c-0.1-0.3-0.3-0.6-0.6-0.8
									c0.3-0.3,0.6-0.5,0.9-0.7c0.3-0.1,0.6-0.2,0.9-0.2c1,0,1.5,0.6,1.6,1.8c0.8-1,1.7-1.7,2.8-2c0.1,0.3,0.2,0.7,0.3,1.3
									c0.1,0.5,0.1,1.1,0.1,1.5c-0.3,0-0.6-0.1-0.8-0.1c-0.5,0-0.9,0.1-1.4,0.2c-0.4,0.1-0.8,0.3-1.1,0.5c0,1.2,0,2.3,0,3.3
									c0,1.7,0.2,2.9,0.5,3.6c-0.5,0.1-0.9,0.2-1.5,0.2C2268.8,555.9,2268.3,555.9,2267.8,555.8z"
						/>
						<path
							d="M2276.9,555.7c0.1-2.1,0.1-4.2,0.1-6.2c0-2.8-0.1-5-0.3-6.6c0.8-0.3,1.8-0.5,2.9-0.5c-0.1,0.9-0.2,2-0.2,3.5
									s-0.1,2.9,0,4.3l0,0.4c1.5-1.7,2.5-2.9,2.9-3.5c0.5-0.6,0.7-1.1,0.7-1.4c0.8-0.2,1.6-0.3,2.5-0.3c-0.9,0.9-2.1,2.2-3.5,3.8
									c2,3.3,3.4,5.3,4.4,6c-0.8,0.4-1.8,0.5-3.1,0.6c-0.4-0.8-1.4-2.4-2.9-4.7l-1,1.2c0.1,1.7,0.3,2.8,0.5,3.4
									c-0.5,0.1-0.9,0.2-1.4,0.2C2278,555.8,2277.5,555.8,2276.9,555.7z"
						/>
						<path
							d="M2290.8,553.7c-0.4-1.2-0.8-2.5-1.2-3.6c-0.1-0.3-0.2-0.7-0.4-1.2c-0.2-0.7-0.5-1.3-0.7-1.8s-0.5-0.9-0.7-1.1
									c0.8-0.4,1.8-0.5,3-0.6c0.1,0.7,0.5,2.1,1.1,4.2c0.6,2.1,1.1,3.4,1.4,4c1-3.3,1.6-5.3,1.8-6.2c-0.3-0.7-0.6-1.2-0.9-1.5
									c0.8-0.4,1.8-0.5,3-0.6c0.1,0.7,0.5,2.1,1.1,4.1c0.6,2,1.1,3.4,1.4,4.1c0.7-2.4,1.2-4.2,1.5-5.2c0.3-1,0.4-1.7,0.4-2.2
									c0-0.3,0-0.5-0.1-0.6c0.7-0.2,1.5-0.3,2.3-0.3c-0.9,2.1-1.9,5.3-3,9.6l-1.5,0.8c-0.9,0-1.6-0.6-2-2c-0.4-1.2-0.8-2.3-1-3.3
									l-0.2-0.8c-0.5,1.7-1,3.5-1.5,5.4l-1.5,0.8C2291.8,555.7,2291.2,555,2290.8,553.7z"
						/>
						<path
							d="M2308.7,554.8c-0.7-0.4-1.2-1-1.6-1.7c-0.4-0.7-0.6-1.6-0.6-2.6c0-1.1,0.2-2,0.6-2.9c0.4-0.8,1-1.5,1.8-2s1.7-0.7,2.7-0.8
									c1.4,0,2.5,0.4,3.4,1.3c0.9,0.9,1.3,2.1,1.3,3.5c0,1.1-0.2,2-0.6,2.9c-0.4,0.8-1.1,1.5-1.9,2c-0.8,0.5-1.7,0.7-2.8,0.8
									C2310.2,555.4,2309.4,555.2,2308.7,554.8z M2313.4,553.2c0.5-0.6,0.7-1.4,0.6-2.5c0-0.9-0.3-1.7-0.7-2.4c-0.4-0.7-1-1.1-1.8-1.3
									c-0.9,0-1.6,0.3-2.1,1c-0.5,0.6-0.7,1.4-0.7,2.5c0,1.2,0.3,2.1,0.8,2.8s1.1,1,1.9,1C2312.3,554.1,2313,553.8,2313.4,553.2z"
						/>
						<path
							d="M2322.1,554.4c-0.7-0.4-1.2-1-1.6-1.7s-0.6-1.6-0.6-2.6c0-1.1,0.2-2,0.6-2.9c0.4-0.8,1-1.5,1.8-2s1.7-0.7,2.7-0.8
									c1.4,0,2.5,0.4,3.4,1.3c0.9,0.9,1.3,2.1,1.4,3.5c0,1.1-0.2,2-0.6,2.9c-0.4,0.8-1.1,1.5-1.9,2s-1.7,0.7-2.8,0.8
									C2323.6,555,2322.8,554.8,2322.1,554.4z M2326.8,552.8c0.5-0.6,0.7-1.4,0.6-2.5c0-0.9-0.3-1.7-0.7-2.4c-0.4-0.7-1-1.1-1.8-1.3
									c-0.9,0-1.6,0.3-2.1,1s-0.7,1.4-0.7,2.5c0,1.2,0.3,2.1,0.8,2.8c0.5,0.7,1.1,1,1.9,1C2325.7,553.8,2326.4,553.5,2326.8,552.8z"
						/>
						<path
							d="M2334.5,553.4c-0.7-0.8-1.1-2.1-1.1-3.8c-0.1-1.8,0.2-3.1,0.9-4c0.6-0.9,1.6-1.4,2.9-1.4c1,0,2,0.2,2.8,0.8
									c-0.1-1.5-0.3-2.7-0.5-3.6c0.8-0.4,1.8-0.5,2.9-0.6c-0.1,2-0.1,5.3,0,9.9c0,0.6,0.1,1.2,0.2,1.5c0.1,0.4,0.4,0.7,0.8,1.1
									c-0.3,0.3-0.6,0.5-1,0.7c-0.4,0.2-0.8,0.3-1.1,0.4c-0.5-0.3-0.8-0.7-0.9-1.3c-0.4,0.4-0.9,0.8-1.4,1c-0.5,0.3-1,0.4-1.5,0.4
									C2336.2,554.7,2335.2,554.3,2334.5,553.4z M2340.2,552.2c0-0.5,0-1.3,0-2.5c0-1.3,0-2.2,0-2.9c-0.5-0.3-1.2-0.5-1.9-0.4
									c-0.8,0-1.4,0.3-1.8,0.9c-0.4,0.6-0.6,1.4-0.5,2.5c0,1,0.2,1.8,0.6,2.4c0.4,0.6,0.9,1,1.6,1.1
									C2338.8,553.1,2339.5,552.8,2340.2,552.2z"
						/>
					</g>
					<g className="level_zero location" id="erebor">
						<rect x="1411.39" y="1312.005" width="91.646" height="28.863" transform="matrix(0.999983, -0.005831, -0.006215, 1.000053, 729.690796, -927.223755)"></rect>
						<rect x="2119.998" y="2172.76" width="137.658" height="47.799" transform="matrix(0.999983, -0.005831, -0.006215, 1.000053, 53.66951, -1761.331665)"></rect>
						<path
							d="M2139.7,395.8c0-1.5,0-3.3,0-5.4c-0.1-2.2-0.1-3.9-0.2-5.1c-0.1-1.2-0.3-2.2-0.5-3c1.2,0.1,3,0.2,5.5,0.1
									c3-0.1,5.3-0.2,7-0.4c0,0.7,0,1.5-0.1,2.3c-0.1,0.8-0.3,1.4-0.5,1.7c-0.6-0.3-1.3-0.5-2.2-0.7s-1.8-0.2-2.7-0.2c-1,0-2,0.1-3,0.3
									c0,1.9-0.1,3.5-0.1,5c1.1,0,1.9,0,2.5,0c1.1,0,1.9-0.1,2.5-0.1c0.6-0.1,1.2-0.2,2-0.4c0.1,0.6,0.2,1.1,0.2,1.5
									c0,0.3,0,0.8-0.1,1.5c-2.5-0.1-4.5-0.2-5.9-0.2l-1.1,0.1c0.1,2.3,0.1,4,0.3,5.1c1.1,0.2,2.2,0.3,3.3,0.2c1,0,2-0.1,3-0.3
									c1-0.2,1.8-0.4,2.5-0.8c0,0.7,0,1.5-0.1,2.3c-0.1,0.8-0.3,1.4-0.5,1.7c-1.5-0.1-3.6-0.2-6.3-0.1c-2.5,0.1-4.3,0.2-5.5,0.4
									C2139.7,399.1,2139.7,397.3,2139.7,395.8z"
						/>
						<path
							d="M2154.9,400.8c0.1-2.4,0.1-5,0.1-7.7c0-1.3-0.1-2.3-0.2-3c-0.1-0.7-0.2-1.2-0.4-1.6c-0.2-0.4-0.5-0.8-0.8-1.2
									c0.4-0.5,0.8-0.8,1.2-1c0.5-0.2,0.9-0.3,1.2-0.3c1.4,0,2.1,0.9,2.3,2.6c1.1-1.6,2.4-2.6,4-3.1c0.2,0.5,0.3,1.1,0.5,1.9
									c0.1,0.8,0.2,1.5,0.2,2.2c-0.4-0.1-0.8-0.1-1.2-0.1c-0.7,0-1.3,0.1-2,0.3c-0.6,0.2-1.1,0.4-1.5,0.7c0,1.7,0,3.3,0,4.8
									c0,2.5,0.3,4.3,0.8,5.4c-0.6,0.2-1.3,0.2-2.1,0.3C2156.5,401,2155.8,400.9,2154.9,400.8z"
						/>
						<path
							d="M2164.9,399.2c-1-1.2-1.5-3-1.6-5.4c0-1.6,0.3-3,0.9-4.3c0.6-1.3,1.4-2.2,2.5-2.9c1.1-0.7,2.3-1.1,3.7-1.1
									c1.8,0,3.2,0.6,4.2,1.9c1,1.3,1.5,3.2,1.5,5.6c-1.2,0.3-2.6,0.6-4.3,0.9c-1.7,0.2-3.3,0.4-4.9,0.4c0.2,1.3,0.6,2.2,1.2,2.8
									c0.6,0.6,1.4,0.8,2.6,0.8c0.9,0,1.8-0.1,2.6-0.4c0.8-0.2,1.5-0.6,2.1-1l0.3,1.2c-0.7,1-1.6,1.8-2.8,2.4c-1.2,0.6-2.4,0.9-3.6,0.9
									C2167.3,401,2165.9,400.4,2164.9,399.2z M2169.8,392.4c1-0.3,1.8-0.6,2.4-0.9c-0.1-1.2-0.4-2.1-1-2.9c-0.6-0.8-1.4-1.2-2.3-1.1
									c-0.6,0.4-1.2,1.2-1.5,2.2c-0.4,1-0.6,2.1-0.5,3.4C2167.8,392.8,2168.8,392.6,2169.8,392.4z"
						/>
						<path
							d="M2182.1,400.3c-0.7-0.3-1.4-0.8-1.8-1.3l-0.8,1.5c-0.3,0-0.8-0.1-1.3-0.2c0.1-1.1,0.1-2.9,0.2-5.4c0-2.5,0.1-4.6,0.1-6.4
									c0-3.3-0.2-5.7-0.6-7c1.2-0.5,2.6-0.7,4.1-0.7c-0.1,1.4-0.3,3.6-0.3,6.5c1.3-1.1,2.7-1.6,4.2-1.6c1.7,0,3,0.6,3.9,1.9
									c1,1.3,1.5,3,1.5,5.2c0,2.6-0.6,4.5-1.7,5.9c-1.1,1.4-2.8,2.1-4.9,2.1C2183.6,400.8,2182.8,400.6,2182.1,400.3z M2186.7,397.6
									c0.6-0.8,0.9-2,0.9-3.5c0-1.5-0.3-2.7-0.8-3.6c-0.5-0.9-1.2-1.6-2.1-2c-1.1,0-2.2,0.3-3.2,0.9l0,4.2c0,1.9,0.1,3.3,0.3,4.1
									c0.7,0.7,1.5,1.1,2.3,1.1C2185.2,398.8,2186.1,398.4,2186.7,397.6z"
						/>
						<path
							d="M2195.3,399.9c-1-0.6-1.7-1.5-2.2-2.6c-0.5-1.1-0.8-2.4-0.8-3.8c0-1.6,0.3-3,1-4.2c0.6-1.2,1.5-2.2,2.7-2.9
									c1.1-0.7,2.4-1,3.9-1c2,0,3.5,0.7,4.7,2c1.2,1.3,1.8,3.1,1.8,5.3c0,1.6-0.3,3-1,4.2c-0.7,1.2-1.6,2.2-2.7,2.9c-1.2,0.7-2.5,1-4,1
									C2197.4,400.8,2196.3,400.5,2195.3,399.9z M2202,397.8c0.7-0.9,1-2.1,1-3.7c0-1.4-0.3-2.6-0.9-3.6c-0.6-1-1.4-1.7-2.5-2.1
									c-1.2,0-2.2,0.4-3,1.3c-0.7,0.9-1.1,2.1-1.1,3.6c0,1.7,0.3,3.1,1,4.1c0.7,1,1.6,1.5,2.6,1.5C2200.4,399.1,2201.4,398.7,2202,397.8
									z"
						/>
						<path
							d="M2209.2,400.5c0.2-2.4,0.3-4.9,0.3-7.7c0-1.3,0-2.3-0.1-3c-0.1-0.7-0.2-1.2-0.4-1.7c-0.2-0.4-0.4-0.8-0.8-1.3
									c0.4-0.5,0.8-0.8,1.3-1c0.5-0.2,0.9-0.3,1.3-0.3c1.4,0,2.1,0.9,2.2,2.7c1.1-1.5,2.5-2.5,4-2.9c0.2,0.5,0.3,1.1,0.4,1.9
									c0.1,0.8,0.2,1.6,0.1,2.2c-0.4-0.1-0.8-0.1-1.2-0.1c-0.7,0-1.3,0.1-2,0.3c-0.6,0.2-1.1,0.4-1.6,0.7c-0.1,1.7-0.1,3.3-0.1,4.8
									c0,2.5,0.2,4.3,0.6,5.4c-0.6,0.1-1.3,0.2-2.1,0.2C2210.7,400.7,2210,400.6,2209.2,400.5z"
						/>
						<path
							d="M2169.3,441.9c0.2-2.1,0.3-5.1,0.4-9c0-1.9,0-3.3,0-4.4c0-1.1-0.1-1.9-0.2-2.5c0.5-0.1,1.1-0.2,1.7-0.1
									c0.5,0,1.2,0.1,1.9,0.2c0.6,1.6,1.4,3.5,2.4,5.8c1,2.3,1.8,4.2,2.4,5.6c0.8-1.6,1.7-3.5,2.8-5.8c1.1-2.3,1.9-4.1,2.4-5.4
									c0.4-0.1,1-0.2,1.8-0.1c0.5,0,1.2,0.1,1.9,0.2c-0.1,1-0.2,2.4-0.3,4.1c-0.1,1.7-0.2,3.4-0.2,4.9c-0.1,3.7,0,6,0.3,6.9
									c-0.6,0.1-1.2,0.2-1.8,0.1c-0.5,0-1.1-0.1-1.8-0.2c0.1-1,0.2-2.4,0.3-4.1s0.2-3.4,0.2-4.9c0-0.8,0-1.9,0-3.1
									c-1.7,3.4-3.6,7.4-5.6,12c-0.3,0.1-0.5,0.1-0.7,0.1c-0.2,0-0.4-0.1-0.6-0.2c-1.6-4.1-3.2-7.9-4.7-11.3c-0.1,1.9-0.1,3.4-0.1,4.4
									c-0.1,3.6,0,5.9,0.2,6.9c-0.4,0.1-0.8,0.2-1.3,0.2C2170.2,442.1,2169.8,442,2169.3,441.9z"
						/>
						<path
							d="M2193.7,441.8c-0.8-0.5-1.5-1.2-1.9-2.2c-0.4-0.9-0.7-2-0.7-3.2c0-1.3,0.3-2.5,0.9-3.5s1.3-1.8,2.3-2.4
									c1-0.6,2.1-0.8,3.4-0.8c1.7,0,3.1,0.6,4.1,1.7c1,1.1,1.5,2.6,1.5,4.4c0,1.3-0.3,2.5-0.9,3.5c-0.6,1-1.4,1.8-2.4,2.4
									c-1,0.6-2.2,0.8-3.5,0.8C2195.4,442.6,2194.5,442.3,2193.7,441.8z M2199.5,440.1c0.6-0.7,0.9-1.8,0.9-3.1c0-1.1-0.2-2.1-0.8-3
									s-1.2-1.4-2.2-1.7c-1.1,0-1.9,0.4-2.6,1.1c-0.6,0.7-1,1.8-1,3c0,1.4,0.3,2.6,0.9,3.5c0.6,0.9,1.3,1.3,2.3,1.3
									C2198.1,441.2,2198.9,440.8,2199.5,440.1z"
						/>
						<path
							d="M2208.1,438.5l0.1-1.9l0.1-2c0-2-0.2-3.4-0.6-4.2c1-0.4,2.3-0.6,3.6-0.6c-0.1,1.8-0.2,4.5-0.2,8.1c0,0.8,0.1,1.3,0.4,1.7
									s0.8,0.5,1.4,0.5c0.9,0,1.7-0.2,2.4-0.6c0-1.3,0-2.4,0.1-3.3l0-1.5c0-2-0.2-3.4-0.6-4.2c1-0.4,2.3-0.6,3.6-0.6
									c-0.1,1.3-0.1,2.8-0.2,4.5c-0.1,1.7-0.1,3-0.1,3.9c0,0.8,0.1,1.4,0.2,1.8c0.2,0.4,0.5,0.9,0.9,1.4c-0.4,0.3-0.8,0.6-1.3,0.8
									s-0.9,0.3-1.4,0.4c-0.6-0.4-1-1-1.1-1.8c-0.5,0.6-1.1,1-1.8,1.3c-0.7,0.3-1.4,0.5-2.1,0.5C2209.3,442.7,2208.1,441.3,2208.1,438.5
									z"
						/>
						<path
							d="M2224.7,442.3c0.1-2,0.2-4.2,0.1-6.4c0-1.1-0.1-1.9-0.1-2.5c-0.1-0.6-0.2-1-0.3-1.4c-0.2-0.3-0.4-0.7-0.7-1
									c0.3-0.4,0.7-0.7,1.1-0.8c0.4-0.2,0.8-0.3,1.1-0.3c1.1,0,1.7,0.6,1.9,1.8c0.5-0.6,1.1-1.1,1.8-1.4c0.7-0.3,1.4-0.5,2.1-0.5
									c2.3,0,3.4,1.3,3.5,4.1l0,1.9l0,2c0,2.1,0.2,3.6,0.6,4.5c-0.5,0.1-1.2,0.2-1.8,0.2c-0.5,0-1.2,0-1.8-0.2c0.1-2.3,0.1-4.8,0.1-7.5
									c0-0.8-0.2-1.4-0.5-1.7c-0.3-0.3-0.8-0.5-1.5-0.5c-1,0-1.8,0.2-2.4,0.7l0,0.9c0,1.4,0,2.6,0,3.7c0,2.1,0.2,3.6,0.6,4.5
									c-0.6,0.1-1.1,0.2-1.7,0.2S2225.3,442.4,2224.7,442.3z"
						/>
						<path
							d="M2242.1,441.7c-0.3-0.4-0.5-1-0.5-2c0-0.6,0-1.6,0-3c0-1.5,0-2.7,0-3.7c0-0.4,0-0.8-0.1-1.2c-0.8,0-1.3,0-1.6-0.1
									c-0.3-0.1-0.4-0.4-0.4-0.7c1.3-0.7,2.5-1.8,3.7-3.1l1,0.1c0.1,0.7,0.2,1.4,0.2,2.1c1.3,0,2.2-0.1,2.8-0.3c0,0.5,0,0.9-0.1,1.2
									c-0.1,0.4-0.2,0.7-0.4,1.1c-0.6-0.2-1.4-0.3-2.4-0.3c0,0.8-0.1,1.7,0,2.7c0,2.3,0.2,4.1,0.5,5.4c0.8,0,1.5-0.1,2.2-0.3l0.2,0.9
									c-0.4,0.6-0.9,1-1.5,1.4c-0.6,0.4-1.3,0.5-2,0.6C2243,442.3,2242.4,442.1,2242.1,441.7z"
						/>
						<path
							d="M2251.5,441.4c-0.5-0.5-0.7-1.2-0.8-2c0-1.2,0.5-2.2,1.6-3c1.1-0.7,2.8-1.2,5.1-1.4c0-1.1-0.3-1.9-0.6-2.3
									c-0.4-0.5-1-0.7-1.9-0.6c-0.6,0-1.2,0.1-1.8,0.4c-0.6,0.2-1.2,0.5-1.7,0.9l-0.3-1c0.5-0.9,1.3-1.6,2.2-2.2
									c0.9-0.6,1.8-0.9,2.8-0.9c1.3,0,2.3,0.3,3.1,1c0.7,0.7,1.1,1.7,1.1,3c0,0.7,0,1.5-0.1,2.4c-0.1,1-0.1,1.7-0.1,2.1
									c0,0.8,0.1,1.4,0.3,1.8c0.2,0.4,0.5,0.9,0.9,1.4c-0.8,0.7-1.6,1.1-2.6,1.2c-0.6-0.4-0.9-0.9-1.1-1.6c-0.7,0.5-1.4,0.9-2.1,1.2
									c-0.7,0.3-1.4,0.5-2,0.5C2252.6,442.1,2252,441.9,2251.5,441.4z M2256,440c0.5-0.2,0.9-0.5,1.3-0.8l0-0.2c0-0.6,0-1.2,0-1.7
									l0.1-1.2c-1.2,0.1-2.1,0.4-2.8,0.9c-0.6,0.5-1,1.1-0.9,1.8c0,0.3,0.1,0.6,0.3,1s0.4,0.6,0.7,0.7
									C2255.1,440.4,2255.5,440.3,2256,440z"
						/>
						<path
							d="M2265.1,425.1c0.6-0.8,1.2-1.5,1.9-2.1c0.6,0.7,1.3,1.4,2,2c-0.6,0.8-1.2,1.5-1.9,2.1
									C2266.6,426.4,2265.9,425.7,2265.1,425.1z M2266.6,441.1c-0.3-0.4-0.4-1-0.5-1.9c0-0.7,0-1.8-0.1-3.2c0-1.3,0-2.2,0-2.8
									c0-0.8-0.1-1.6-0.2-2.3c-0.1-0.7-0.3-1.2-0.5-1.5c0.6-0.2,1.2-0.4,1.7-0.5c0.5-0.1,1.2-0.2,1.8-0.2c-0.1,0.6-0.1,1.5-0.2,2.7
									s0,2.6,0,4.1c0,1.1,0.1,1.9,0.2,2.5s0.2,1,0.4,1.4c0.2,0.3,0.4,0.7,0.7,1c-0.3,0.4-0.7,0.7-1.1,0.9c-0.4,0.2-0.8,0.3-1.1,0.3
									C2267.4,441.6,2266.9,441.5,2266.6,441.1z"
						/>
						<path
							d="M2275.5,441.1c0.1-2,0-4.2-0.1-6.4c0-1.1-0.1-1.9-0.2-2.5c-0.1-0.6-0.2-1-0.4-1.4c-0.2-0.3-0.4-0.7-0.8-1
									c0.3-0.4,0.7-0.7,1.1-0.9c0.4-0.2,0.8-0.3,1.1-0.3c1.1,0,1.7,0.5,1.9,1.7c0.5-0.6,1.1-1.1,1.7-1.5c0.7-0.4,1.4-0.6,2.1-0.6
									c2.3-0.1,3.5,1.2,3.6,4l0,1.9l0,2c0.1,2.1,0.3,3.6,0.8,4.5c-0.5,0.1-1.1,0.2-1.8,0.3c-0.5,0-1.2,0-1.8-0.1c0-2.3,0-4.8-0.1-7.5
									c0-0.8-0.2-1.4-0.5-1.7c-0.3-0.3-0.8-0.5-1.5-0.5c-1,0-1.8,0.3-2.4,0.7l0,0.9c0,1.4,0.1,2.6,0.1,3.7c0.1,2.1,0.3,3.6,0.8,4.5
									c-0.6,0.1-1.1,0.2-1.7,0.3C2276.8,441.2,2276.2,441.2,2275.5,441.1z"
						/>
						<path
							d="M2170,422.5c0.1-1.2,0.2-2.9,0.2-4.9c0.1-2.1,0.1-4,0.1-5.8c0-2-0.1-3.6-0.2-5c-2.7,0-4.4,0.2-5.2,0.5
									c-0.1-0.7-0.2-1.3-0.2-1.8c0-0.5,0-1,0.2-1.8c0.9,0.1,2.1,0.2,3.6,0.2c1.5,0.1,3,0.1,4.4,0.1c3.3,0,5.3-0.2,6.1-0.5
									c0.1,0.6,0.2,1.2,0.2,1.7c0,0.5-0.1,1.1-0.2,1.8c-1.3-0.1-3-0.2-5.2-0.3c-0.1,3-0.2,5.6-0.2,7.6c0,4.4,0.2,7.1,0.6,8.2
									c-0.6,0.1-1.3,0.2-2,0.2C2171.5,422.7,2170.8,422.6,2170,422.5z"
						/>
						<path
							d="M2180.2,422.4c0.1-3.1,0.2-6.1,0.2-9.2c0-4.2-0.2-7.4-0.4-9.8c1.2-0.5,2.6-0.7,4.1-0.7c-0.2,1.6-0.3,3.9-0.4,6.9
									c0.6-0.7,1.3-1.3,2.1-1.7c0.8-0.4,1.6-0.6,2.4-0.6c2.6,0,3.9,1.6,3.9,4.9l-0.1,2.3l-0.1,2.4c0,2.5,0.3,4.3,0.7,5.4
									c-0.6,0.1-1.3,0.2-2.1,0.2c-0.6,0-1.3-0.1-2.1-0.2c0.1-3.1,0.2-6.1,0.2-8.9c0-1-0.2-1.6-0.5-2.1c-0.4-0.4-0.9-0.6-1.7-0.6
									c-1.1,0-2,0.3-2.8,0.9l0,2.7c0,4.3,0.3,7,0.8,8.1c-0.7,0.1-1.4,0.2-2.1,0.2C2181.7,422.6,2181,422.6,2180.2,422.4z"
						/>
						<path
							d="M2196.1,420.8c-1-1.2-1.5-3-1.6-5.4c0-1.6,0.3-3,0.9-4.3c0.6-1.3,1.4-2.2,2.5-2.9c1.1-0.7,2.3-1,3.7-1.1
									c1.8,0,3.2,0.6,4.2,1.9c1,1.3,1.4,3.2,1.5,5.7c-1.2,0.3-2.6,0.6-4.3,0.8c-1.7,0.2-3.3,0.4-4.9,0.4c0.2,1.3,0.6,2.2,1.2,2.8
									c0.6,0.6,1.4,0.9,2.6,0.8c0.9,0,1.8-0.1,2.6-0.4c0.8-0.2,1.5-0.6,2.1-1l0.3,1.2c-0.7,1-1.6,1.7-2.8,2.3c-1.2,0.6-2.4,0.9-3.6,0.9
									C2198.5,422.7,2197.1,422.1,2196.1,420.8z M2201,414.1c1-0.3,1.8-0.5,2.4-0.9c-0.1-1.2-0.4-2.1-1-2.9c-0.6-0.8-1.4-1.2-2.3-1.1
									c-0.6,0.4-1.2,1.2-1.5,2.2c-0.4,1-0.6,2.1-0.6,3.4C2199,414.5,2200,414.3,2201,414.1z"
						/>
						<path
							d="M2216.7,416.5c0-1.3,0-3,0-5.1c0-4.5-0.3-7.2-0.6-8.2c0.7-0.2,1.3-0.2,2-0.2c0.6,0,1.3,0.1,2.1,0.2
									c-0.1,1.2-0.2,2.9-0.2,5c0,2.1-0.1,4,0,5.8c0,2.3,0.1,4,0.2,5c0.9,0.2,2,0.3,3.3,0.3c0.8,0,1.6-0.1,2.4-0.3s1.5-0.4,2.1-0.7
									c0,0.7-0.1,1.5-0.2,2.3c-0.1,0.8-0.3,1.3-0.5,1.6c-1.6-0.2-3.3-0.2-5.3-0.2c-2.5,0-4.3,0.1-5.6,0.3
									C2216.6,419.7,2216.7,417.8,2216.7,416.5z"
						/>
						<path
							d="M2231.3,421.4c-1-0.6-1.7-1.4-2.3-2.5c-0.5-1.1-0.8-2.4-0.8-3.8c0-1.6,0.3-3,0.9-4.2c0.6-1.2,1.5-2.2,2.6-2.9
									c1.1-0.7,2.4-1.1,3.9-1.1c2,0,3.6,0.6,4.8,2c1.2,1.3,1.8,3.1,1.9,5.3c0,1.6-0.3,3-0.9,4.2c-0.6,1.2-1.5,2.2-2.7,2.9
									c-1.2,0.7-2.5,1.1-4,1.1C2233.4,422.3,2232.3,422,2231.3,421.4z M2238,419.2c0.7-0.9,1-2.1,1-3.7c0-1.4-0.3-2.5-0.9-3.5
									c-0.6-1-1.5-1.7-2.5-2c-1.2,0-2.2,0.5-2.9,1.4c-0.7,0.9-1.1,2.1-1,3.6c0,1.7,0.4,3.1,1.1,4.1c0.7,1,1.6,1.5,2.6,1.5
									C2236.4,420.6,2237.3,420.1,2238,419.2z"
						/>
						<path
							d="M2245.2,421.8c0.1-2.4,0.2-5,0.1-7.7c0-1.3-0.1-2.3-0.2-3c-0.1-0.7-0.2-1.2-0.4-1.6s-0.5-0.8-0.8-1.2
									c0.4-0.5,0.8-0.8,1.3-1c0.5-0.2,0.9-0.3,1.3-0.3c1.2,0,1.9,0.7,2.1,2.1c0.6-0.7,1.3-1.3,2.1-1.7c0.8-0.4,1.6-0.6,2.4-0.6
									c2.6,0,3.9,1.6,4,4.9l0,2.3l0,2.4c0,2.5,0.3,4.3,0.7,5.4c-0.6,0.2-1.3,0.2-2.1,0.2c-0.6,0-1.3-0.1-2.1-0.2
									c0.1-2.7,0.2-5.7,0.1-8.9c0-1-0.2-1.6-0.5-2.1c-0.4-0.4-0.9-0.6-1.7-0.6c-1.1,0-2,0.3-2.7,0.8l0,1c0,1.7,0,3.2,0,4.4
									c0,2.5,0.3,4.3,0.7,5.4c-0.6,0.2-1.3,0.2-2,0.2S2246,421.9,2245.2,421.8z"
						/>
						<path
							d="M2261.1,420.1c-1-1.2-1.6-3-1.6-5.4c0-1.6,0.3-3,0.8-4.3c0.6-1.3,1.4-2.2,2.5-2.9c1.1-0.7,2.3-1.1,3.7-1.1
									c1.8,0,3.2,0.6,4.2,1.9c1,1.3,1.5,3.1,1.5,5.6c-1.2,0.3-2.6,0.6-4.3,0.9c-1.7,0.3-3.3,0.4-4.9,0.5c0.2,1.3,0.6,2.2,1.2,2.8
									c0.6,0.6,1.4,0.8,2.6,0.8c0.9,0,1.8-0.1,2.6-0.4c0.8-0.2,1.5-0.6,2.1-1.1l0.3,1.2c-0.7,1-1.6,1.8-2.8,2.4
									c-1.2,0.6-2.4,0.9-3.6,0.9C2263.5,421.9,2262.1,421.3,2261.1,420.1z M2265.9,413.3c1-0.3,1.8-0.6,2.4-0.9c-0.1-1.2-0.4-2.1-1-2.9
									c-0.6-0.8-1.4-1.1-2.3-1.1c-0.6,0.4-1.1,1.2-1.5,2.2c-0.4,1-0.6,2.1-0.5,3.4C2263.9,413.7,2264.9,413.5,2265.9,413.3z"
						/>
						<path
							d="M2275.3,421c-0.3-0.4-0.5-1.2-0.5-2.3c0-0.7,0-1.9,0-3.6c0-1.8,0-3.3,0-4.5c-0.1-3.9-0.3-6.6-0.8-8.3
									c0.7-0.3,1.3-0.5,2-0.6c0.6-0.1,1.4-0.2,2.2-0.2c-0.2,2.5-0.3,6.8-0.2,12.8c0,1.3,0.1,2.3,0.2,3c0.1,0.7,0.2,1.2,0.4,1.6
									c0.2,0.4,0.5,0.8,0.8,1.2c-0.4,0.5-0.8,0.8-1.3,1s-0.9,0.3-1.2,0.3C2276.1,421.6,2275.6,421.4,2275.3,421z"
						/>
						<path
							d="M2283.4,427.2c-1-0.3-1.8-0.6-2.5-1.1c0-1,0.2-1.9,0.6-3c0.5-1,1.1-2,1.9-2.8c-1.2-0.7-1.7-2.1-1.8-4.3l0-1.9l0-2.2
									c0-2.3-0.3-4-0.7-4.9c1.2-0.5,2.5-0.8,4.1-0.8c-0.1,2.1-0.1,5.1-0.1,8.9c0,1,0.2,1.6,0.6,2.1c0.4,0.4,0.9,0.6,1.7,0.6
									c1.2,0,2.1-0.3,2.9-1l-0.1-3.7c0-0.4,0-0.8-0.1-1.2c0-1.1-0.1-2.1-0.2-3c-0.1-0.9-0.3-1.6-0.5-2c1.2-0.5,2.6-0.8,4.1-0.8
									c-0.1,1.9-0.1,4.9-0.1,9c0,1.1,0.1,2.7,0.2,4.9c0,0.4,0,0.7,0.1,1.1c0,2.1-0.6,3.8-1.9,4.9c-1.3,1.1-3,1.7-5.2,1.7
									C2285.5,427.6,2284.4,427.5,2283.4,427.2z M2289.5,424.2c0.6-0.8,0.9-2.1,0.9-3.9l0-1.9c-0.6,0.8-1.3,1.3-2.1,1.7
									c-0.8,0.4-1.6,0.6-2.5,0.6c-0.3,0-0.6,0-0.8,0c-0.8,1.1-1.2,2.2-1.4,3.2c0.4,0.4,0.9,0.8,1.5,1c0.6,0.3,1.2,0.4,1.8,0.4
									C2288,425.4,2288.9,425,2289.5,424.2z"
						/>
					</g>
					<g className="level_two fade location" id="woodland_realm">
						<rect x="1192.207" y="1382.089" width="77.414" height="30.405" transform="matrix(0.937421, -0.348197, 0.336905, 0.941616, 455.798645, -487.669281)"></rect>
						<text fontFamily="Arbutus Slab" fontSize="14px" textAnchor="middle" transform="matrix(0.937876, -0.34697, 0.34697, 0.937876, 10.003153, 739.599243)" x="2060.664" y="397.527">Woodland</text>
						<text fontFamily="Arbutus Slab" fontSize="14px" textAnchor="middle" transform="matrix(0.930721, -0.365729, 0.365729, 0.930721, 23.434767, 794.002808)" x="2060.664" y="397.527">Realm</text>
					</g>
					<g className="level_two fade location " id="forest_river">
						<rect x="2026.942" y="1416.963" width="131.614" height="31.171" transform="matrix(0.936273, 0.351273, -0.272981, 0.965647, 369.897156, -1689.718384)"></rect>
						<path
							d="M1878.5,405.1c0.5-0.5,1.2-1.3,2-2.3c0.8-1,1.6-1.9,2.3-2.8c1-1.3,1.8-2.4,2.3-3.2c0.5-0.8,0.9-1.5,1.2-2.1
									c0.7,0.6,1.7,1.5,3.1,2.6c1.6,1.3,2.9,2.2,3.8,2.7c-0.3,0.4-0.7,0.8-1.1,1.2c-0.4,0.4-0.8,0.6-1,0.7c-0.2-0.4-0.4-0.8-0.8-1.2
									c-0.4-0.4-0.8-0.8-1.3-1.2c-0.6-0.4-1.2-0.9-1.9-1.2c-0.8,1-1.6,1.8-2.2,2.6c0.5,0.4,0.9,0.8,1.2,1c0.6,0.5,1.1,0.8,1.4,1
									c0.4,0.2,0.7,0.4,1.2,0.6c-0.2,0.4-0.4,0.7-0.5,0.9c-0.1,0.2-0.4,0.4-0.7,0.8c-1.3-1.2-2.3-2-3-2.6l-0.6-0.5
									c-1.9,2.5-2.9,4-3.2,4.7c-0.5-0.2-0.9-0.5-1.3-0.8C1879.3,405.9,1878.9,405.5,1878.5,405.1z"
						/>
						<path
							d="M1889.5,413.2c-0.3-0.8-0.4-1.6-0.2-2.4c0.2-0.8,0.5-1.6,1.1-2.4c0.7-0.9,1.4-1.5,2.3-1.9c0.9-0.4,1.8-0.5,2.8-0.4
									c1,0.1,1.9,0.5,2.7,1.2c1.2,0.9,1.8,2,1.9,3.2s-0.2,2.5-1.2,3.7c-0.7,0.9-1.4,1.5-2.3,1.8c-0.9,0.4-1.8,0.5-2.8,0.3
									s-1.9-0.6-2.8-1.2C1890.4,414.6,1889.8,413.9,1889.5,413.2z M1894.4,415.1c0.8-0.2,1.5-0.7,2.1-1.5c0.6-0.7,0.9-1.5,1-2.3
									c0.1-0.8-0.1-1.6-0.6-2.2c-0.7-0.6-1.5-0.8-2.3-0.6c-0.8,0.2-1.5,0.6-2.2,1.5c-0.7,0.9-1.1,1.8-1.1,2.7s0.3,1.5,0.9,2
									C1892.9,415,1893.6,415.2,1894.4,415.1z"
						/>
						<path
							d="M1899.3,421.1c1.1-1.2,2.2-2.6,3.3-4.1c0.5-0.7,0.9-1.3,1.2-1.7c0.2-0.4,0.4-0.8,0.5-1.1c0.1-0.3,0.1-0.6,0-1.1
									c0.4-0.1,0.8-0.1,1.2,0c0.4,0.1,0.6,0.2,0.9,0.4c0.8,0.6,0.9,1.4,0.2,2.5c1.3-0.3,2.5-0.3,3.6,0.2c-0.1,0.3-0.3,0.7-0.5,1.2
									c-0.3,0.5-0.5,0.9-0.8,1.3c-0.2-0.2-0.4-0.4-0.6-0.6c-0.4-0.3-0.8-0.6-1.3-0.7c-0.4-0.2-0.8-0.3-1.2-0.3c-0.7,0.9-1.4,1.8-2,2.6
									c-1,1.4-1.7,2.4-1.9,3.2c-0.4-0.2-0.9-0.5-1.3-0.8C1900.1,421.9,1899.7,421.6,1899.3,421.1z"
						/>
						<path
							d="M1907.7,426.3c-0.1-1.1,0.3-2.3,1.3-3.6c0.6-0.9,1.4-1.5,2.2-2c0.9-0.4,1.8-0.6,2.7-0.5c0.9,0.1,1.8,0.4,2.7,1.1
									c1.1,0.8,1.7,1.8,1.7,2.9s-0.4,2.4-1.4,3.8c-0.8-0.3-1.8-0.8-2.9-1.4c-1.1-0.6-2.1-1.2-3.1-1.9c-0.4,0.8-0.5,1.5-0.4,2
									c0.1,0.6,0.5,1.1,1.2,1.6c0.6,0.4,1.1,0.7,1.7,1c0.6,0.2,1.1,0.3,1.7,0.4l-0.3,0.8c-0.8,0.2-1.7,0.3-2.6,0.1
									c-0.9-0.2-1.8-0.5-2.5-1.1C1908.4,428.4,1907.8,427.5,1907.7,426.3z M1913.4,424.8c0.7,0.3,1.3,0.5,1.8,0.6
									c0.4-0.7,0.6-1.4,0.6-2.1c-0.1-0.7-0.4-1.3-0.9-1.7c-0.6,0-1.2,0.1-1.8,0.5c-0.6,0.4-1.2,0.9-1.7,1.6
									C1912,424.1,1912.7,424.5,1913.4,424.8z"
						/>
						<path
							d="M1918.4,434.3c0.3-0.6,0.7-1.1,1.2-1.6c0.1,0.5,0.4,1,0.8,1.4c0.4,0.4,0.9,0.8,1.4,1.1c1.3,0.6,2.1,0.6,2.4-0.1
									c0.1-0.3,0.1-0.6-0.1-0.9c-0.2-0.3-0.6-0.8-1.1-1.3c-0.5-0.6-1-1.1-1.3-1.5c-0.3-0.4-0.5-0.9-0.6-1.5c-0.1-0.6,0-1.1,0.2-1.7
									c0.3-0.7,1-1.2,1.8-1.3s1.9,0,2.9,0.5c1.4,0.6,2.4,1.4,3.2,2.2c-0.1,0.3-0.4,0.6-0.7,1c-0.4,0.4-0.7,0.7-1,0.9
									c-0.3-0.7-0.7-1.4-1.1-1.9s-0.8-0.9-1.1-1c-0.3-0.1-0.6-0.1-0.9,0c-0.3,0.1-0.4,0.2-0.5,0.4c-0.2,0.4-0.1,0.7,0.1,1.1
									c0.2,0.4,0.6,0.9,1.1,1.5c0.5,0.6,0.9,1.1,1.2,1.5s0.5,0.9,0.6,1.4c0.1,0.5,0,1-0.2,1.6c-0.4,0.9-1.1,1.4-2,1.5
									c-0.9,0.2-2-0.1-3.3-0.7C1920.1,436,1919,435.2,1918.4,434.3z"
						/>
						<path
							d="M1932,440c-0.2-0.4-0.2-0.9,0-1.7c0.1-0.5,0.3-1.3,0.6-2.3c0.3-1.2,0.6-2.1,0.8-2.9c0.1-0.3,0.1-0.6,0.2-1
									c-0.7-0.2-1.1-0.3-1.3-0.5s-0.2-0.4-0.2-0.7c1.2-0.3,2.5-0.8,3.7-1.6l0.8,0.3c-0.1,0.6-0.2,1.1-0.3,1.7c1.1,0.3,1.9,0.4,2.4,0.4
									c-0.1,0.4-0.2,0.7-0.3,0.9c-0.1,0.3-0.3,0.5-0.5,0.8c-0.4-0.3-1.1-0.6-1.9-0.8c-0.2,0.7-0.4,1.3-0.6,2.1c-0.5,1.8-0.7,3.3-0.8,4.3
									c0.6,0.1,1.3,0.2,1.9,0.3l0,0.7c-0.4,0.4-0.9,0.6-1.5,0.8s-1.2,0.1-1.8,0C1932.6,440.6,1932.2,440.3,1932,440z"
						/>
						<path
							d="M1947.9,443.2c0.2-0.8,0.4-1.9,0.7-3.3c0.3-1.4,0.5-2.7,0.6-3.9c0.4-2.7,0.5-4.4,0.3-5.1c0.5-0.1,1.2-0.2,2.1-0.2
									c0.9,0,1.8,0.1,2.7,0.2c1.7,0.2,2.9,0.8,3.7,1.7c0.8,0.9,1.1,2,0.9,3.4c-0.1,0.8-0.4,1.5-1,2.1c-0.5,0.6-1.2,1-2,1.2
									c0.9,2.6,1.7,4.3,2.4,5.1c-0.5,0.1-1,0.2-1.6,0.2c-0.5,0-1.1,0-1.8-0.1c-0.2-1-0.8-2.6-1.6-4.9c-0.1,0-0.2,0-0.5,0
									c-0.6-0.1-1.1-0.2-1.7-0.4c-0.3,2.3-0.4,3.8-0.3,4.6c-0.5,0-1,0-1.5-0.1C1949,443.5,1948.5,443.4,1947.9,443.2z M1955.6,437.3
									c0.4-0.4,0.7-0.9,0.8-1.5c0.3-1.8-0.6-2.9-2.7-3.2c-0.5-0.1-0.9-0.1-1.3,0c-0.2,1.1-0.5,2.7-0.9,4.7c1.1,0.5,1.9,0.9,2.5,0.9
									C1954.6,438,1955.1,437.7,1955.6,437.3z"
						/>
						<path
							d="M1962.3,445c-0.2-0.3-0.2-0.9-0.1-1.6c0.1-0.6,0.2-1.5,0.4-2.6c0.2-1,0.3-1.8,0.4-2.3c0.1-0.6,0.1-1.3,0.1-1.8
									s-0.1-1-0.2-1.3c0.5-0.1,1-0.2,1.5-0.2s1,0,1.6,0.1c-0.1,0.5-0.3,1.2-0.5,2.2c-0.2,1-0.4,2.1-0.5,3.3c-0.1,0.9-0.2,1.6-0.2,2.1
									c0,0.5,0,0.9,0.1,1.2s0.2,0.6,0.5,0.9c-0.3,0.3-0.7,0.5-1,0.5c-0.4,0.1-0.7,0.1-1,0.1C1962.9,445.5,1962.5,445.3,1962.3,445z
									M1963.3,431.9c0.6-0.5,1.2-1,1.9-1.4c0.4,0.7,0.9,1.3,1.4,1.9c-0.6,0.5-1.2,1-1.9,1.4C1964.3,433.1,1963.8,432.5,1963.3,431.9z"
						/>
						<path
							d="M1971.7,446.1c-0.3-0.4-0.5-1-0.7-1.6c-0.2-0.6-0.3-1.5-0.5-2.6c-0.2-1.3-0.4-2.4-0.7-3.2c-0.2-0.9-0.5-1.6-1-2.3
									c0.9-0.2,2-0.3,3.2-0.1c0,1,0.2,2.5,0.6,4.5c0.4,2,0.7,3.5,1.1,4.3c1.1-2.4,1.9-4,2.3-5s0.7-1.7,0.7-2.2c0-0.2,0-0.5,0-0.7
									c0.7-0.1,1.5-0.1,2.4,0c-1.2,2.1-2.7,5.1-4.3,9l-2,0.6C1972.3,446.8,1972,446.6,1971.7,446.1z"
						/>
						<path
							d="M1981.4,446.8c-0.6-0.9-0.9-2.2-0.7-3.8c0.1-1.1,0.5-2,1-2.8c0.5-0.8,1.3-1.4,2.1-1.7c0.9-0.4,1.8-0.5,2.8-0.4
									c1.3,0.2,2.3,0.7,2.9,1.7c0.6,1,0.8,2.3,0.6,4c-0.9,0.1-2,0.2-3.2,0.2c-1.2,0-2.5-0.1-3.7-0.2c0,0.9,0.2,1.5,0.6,2
									c0.4,0.4,1,0.7,1.8,0.8c0.7,0.1,1.3,0.1,2,0c0.6-0.1,1.2-0.2,1.7-0.5l0.1,0.8c-0.6,0.6-1.3,1-2.3,1.3s-1.8,0.4-2.7,0.3
									C1983,448.2,1982,447.7,1981.4,446.8z M1985.6,442.6c0.8-0.1,1.4-0.2,1.8-0.4c0.1-0.8-0.1-1.5-0.5-2.1c-0.4-0.6-0.9-0.9-1.6-1
									c-0.5,0.2-1,0.7-1.3,1.3c-0.4,0.6-0.6,1.4-0.7,2.2C1984.1,442.7,1984.8,442.7,1985.6,442.6z"
						/>
						<path
							d="M1994,449.3c0.3-1.6,0.6-3.3,0.8-5.2c0.1-0.9,0.2-1.6,0.2-2.1c0-0.5,0-0.9-0.1-1.2c-0.1-0.3-0.3-0.6-0.5-0.9
									c0.3-0.3,0.7-0.5,1-0.6s0.7-0.1,1-0.1c1,0.1,1.5,0.8,1.4,2c0.9-0.9,2-1.5,3.2-1.7c0.1,0.3,0.2,0.8,0.2,1.3c0,0.6,0,1.1-0.1,1.5
									c-0.3-0.1-0.6-0.1-0.9-0.2c-0.5-0.1-1-0.1-1.5,0c-0.5,0.1-0.9,0.2-1.2,0.3c-0.2,1.2-0.3,2.3-0.4,3.3c-0.2,1.7-0.2,3,0.1,3.7
									c-0.5,0-1,0-1.5,0C1995.1,449.6,1994.6,449.5,1994,449.3z"
						/>
					</g>
					<g className="level_one location" id="eregion">
						<rect x="1284.112" y="1109.985" width="83.38" height="24.417" transform="matrix(0.777662, -0.628682, 0.619315, 0.785235, -258.301025, 804.083374)"></rect>
						<rect x="1223.169" y="1173.275" width="79.423" height="25.809" transform="matrix(0.777662, -0.628682, 0.619315, 0.785235, -223.767029, 725.215393)"></rect>
						<path
							d="M1461.2,877.4l0.4,0c-0.1,1.8,0.2,3.5,0.9,5.1c0.6,1.6,1.7,3.3,3.1,5c1.4,1.7,2.8,3.1,4.2,4c1.4,1,3,1.6,4.8,1.9l-0.1,0.4
									c-2-0.1-3.9-0.6-5.7-1.5c-1.8-0.9-3.3-2.1-4.6-3.8c-1.3-1.7-2.2-3.4-2.7-5.3C1460.9,881.3,1460.8,879.4,1461.2,877.4z"
						/>
						<path
							d="M1474,889.2c-0.5-0.8-1.3-1.8-2.2-3.1s-1.9-2.5-2.7-3.5c-2.1-2.6-3.5-4.2-4.3-4.7c0.4-0.4,0.8-0.8,1.3-1.2
									c0.4-0.3,0.9-0.6,1.5-1c0.9,1.4,2.1,3.1,3.7,5.1c0.9-0.7,1.9-1.4,3-2.3c0.5-0.4,1-0.9,1.6-1.4c-2.1-2.6-3.6-4.2-4.3-4.7
									c0.4-0.4,0.8-0.8,1.3-1.2c0.4-0.3,0.9-0.7,1.5-1c0.5,0.8,1.3,1.8,2.2,3.1c1,1.3,1.9,2.5,2.7,3.5c2.1,2.7,3.6,4.2,4.3,4.7
									c-0.4,0.4-0.8,0.8-1.2,1.2c-0.4,0.3-0.9,0.7-1.5,1c-0.9-1.3-2.1-3-3.8-5.1c-1.2,0.9-2.1,1.6-2.7,2.1c-0.6,0.5-1.2,1-1.9,1.6
									c2.1,2.6,3.6,4.2,4.3,4.7c-0.4,0.4-0.8,0.8-1.2,1.2C1475.1,888.5,1474.6,888.9,1474,889.2z"
						/>
						<path
							d="M1487.5,877.9c-0.9,0.1-1.8,0-2.7-0.4c-0.9-0.4-1.7-1-2.4-1.9c-0.8-1-1.2-2-1.4-3.1s-0.1-2.1,0.4-3.1s1.1-1.9,2.1-2.6
									c1.3-1,2.6-1.4,4.1-1.3c1.4,0.2,2.7,0.9,3.7,2.3c0.8,1,1.2,2,1.4,3.1c0.2,1.1,0,2.1-0.4,3.2c-0.4,1-1.1,1.9-2.1,2.7
									C1489.3,877.4,1488.4,877.8,1487.5,877.9z M1490.8,873.1c0-0.9-0.4-1.8-1.1-2.7c-0.7-0.8-1.4-1.4-2.3-1.7s-1.8-0.3-2.6,0.1
									c-0.8,0.7-1.3,1.4-1.3,2.4c0,0.9,0.3,1.8,1,2.8c0.8,1,1.7,1.7,2.7,2c0.9,0.3,1.8,0.1,2.5-0.4
									C1490.4,874.7,1490.8,874,1490.8,873.1z"
						/>
						<path
							d="M1497.6,870c-0.4-0.1-0.9-0.5-1.4-1.2c-0.4-0.5-0.9-1.2-1.7-2.2c-0.8-1.1-1.6-2-2.1-2.8c-1.9-2.3-3.3-3.9-4.4-4.7
									c0.3-0.5,0.7-1,1-1.4c0.4-0.4,0.8-0.8,1.3-1.2c1,1.7,3,4.3,5.9,7.9c0.6,0.8,1.1,1.4,1.5,1.8c0.4,0.4,0.7,0.7,1,0.8
									c0.3,0.2,0.7,0.3,1.1,0.3c0,0.5-0.2,0.9-0.4,1.3c-0.2,0.4-0.4,0.6-0.7,0.8C1498.5,870,1498,870.1,1497.6,870z"
						/>
						<path
							d="M1503.1,865.7c-0.4-0.1-0.9-0.5-1.4-1.2c-0.4-0.5-0.9-1.2-1.7-2.2c-0.8-1.1-1.5-2-2.1-2.8c-1.9-2.3-3.3-3.9-4.4-4.7
									c0.3-0.5,0.7-1,1-1.4c0.4-0.4,0.8-0.8,1.3-1.2c1,1.7,3,4.3,5.9,8c0.6,0.8,1.1,1.4,1.5,1.8s0.7,0.7,1,0.8c0.3,0.2,0.7,0.3,1.1,0.3
									c0,0.5-0.2,0.9-0.4,1.3s-0.4,0.6-0.7,0.8C1503.9,865.7,1503.5,865.8,1503.1,865.7z"
						/>
						<path
							d="M1498.7,850.2c0-0.9,0.1-1.8,0.3-2.7c0.9,0.2,1.8,0.3,2.7,0.3c0,0.9-0.1,1.8-0.3,2.7
									C1500.5,850.4,1499.6,850.3,1498.7,850.2z M1508.6,861.3c-0.4-0.1-0.9-0.5-1.4-1.2c-0.4-0.5-1-1.3-1.8-2.3c-0.7-1-1.2-1.7-1.6-2.1
									c-0.5-0.6-0.9-1.1-1.4-1.6c-0.5-0.5-0.9-0.7-1.2-0.8c0.3-0.5,0.7-1,1-1.4c0.4-0.4,0.8-0.8,1.3-1.2c0.3,0.5,0.7,1.2,1.4,2.1
									c0.6,0.9,1.4,1.9,2.3,3c0.6,0.8,1.1,1.4,1.5,1.8c0.4,0.4,0.7,0.7,1,0.8c0.3,0.2,0.7,0.3,1.1,0.3c0,0.5-0.1,0.9-0.4,1.3
									c-0.2,0.4-0.4,0.6-0.7,0.8C1509.5,861.3,1509,861.4,1508.6,861.3z"
						/>
						<path
							d="M1514.3,857.1c-1-1.5-2.2-3.1-3.5-4.8c-0.6-0.8-1.1-1.4-1.5-1.8c-0.4-0.4-0.7-0.7-1-0.8s-0.7-0.3-1.1-0.3
									c0-0.5,0.2-0.9,0.4-1.3c0.2-0.4,0.4-0.6,0.7-0.8c0.8-0.6,1.6-0.6,2.4,0.2c0-0.7,0.2-1.4,0.6-2.1c0.3-0.7,0.8-1.2,1.3-1.6
									c1.7-1.4,3.4-1,5,1l1,1.4l1.1,1.5c1.2,1.5,2.2,2.5,3,2.9c-0.3,0.4-0.8,0.8-1.3,1.2c-0.4,0.3-0.9,0.6-1.5,1
									c-1.2-1.7-2.6-3.6-4.1-5.5c-0.5-0.6-0.9-0.9-1.3-1c-0.4-0.1-0.9,0.1-1.4,0.5c-0.7,0.6-1.2,1.2-1.5,1.9l0.5,0.7
									c0.8,1,1.5,2,2.1,2.7c1.2,1.5,2.2,2.5,3,2.9c-0.4,0.4-0.8,0.8-1.2,1.2C1515.4,856.5,1514.9,856.8,1514.3,857.1z"
						/>
						<path
							d="M1519.6,837.1c1.7,0.9,3.3,2.2,4.6,3.9s2.2,3.5,2.6,5.4c0.4,1.9,0.5,3.9,0.1,5.9l-0.4,0c0.1-1.8-0.2-3.5-0.8-5.1
									s-1.6-3.3-3-5c-1.4-1.8-2.8-3.1-4.2-4.1c-1.4-1-3-1.7-4.7-2l0.1-0.4C1515.9,835.7,1517.8,836.2,1519.6,837.1z"
						/>
						<path
							d="M1441.6,878.2c-0.8-1-1.8-2.1-2.9-3.4c-1.2-1.4-2.2-2.4-2.9-3.1c-0.7-0.7-1.4-1.3-2-1.6c0.9-0.6,2.2-1.7,3.9-3.2
									c2-1.8,3.5-3.3,4.6-4.4c0.4,0.4,0.8,1,1.1,1.6s0.6,1,0.6,1.4c-0.5,0.1-1.2,0.4-1.8,0.8s-1.3,0.9-1.9,1.5c-0.7,0.6-1.3,1.3-1.9,2
									c1,1.2,1.9,2.3,2.6,3.2c0.7-0.6,1.3-1.1,1.7-1.4c0.7-0.7,1.3-1.2,1.6-1.6c0.4-0.4,0.7-0.8,1.2-1.4c0.4,0.3,0.7,0.6,0.9,0.8
									c0.2,0.2,0.4,0.5,0.7,1c-1.8,1.4-3.2,2.5-4.1,3.4l-0.7,0.7c1.3,1.4,2.3,2.5,2.9,3.1c0.9-0.5,1.6-1.1,2.4-1.8
									c0.7-0.6,1.3-1.3,1.9-2c0.6-0.7,1-1.4,1.3-1.9c0.4,0.4,0.8,1,1.1,1.6s0.6,1,0.6,1.4c-1.1,0.8-2.6,2-4.4,3.6
									c-1.7,1.5-2.8,2.6-3.6,3.5C1443.4,880.4,1442.4,879.2,1441.6,878.2z"
						/>
						<path
							d="M1454.2,873c-1.2-1.6-2.5-3.3-4-5c-0.7-0.8-1.3-1.4-1.7-1.8c-0.4-0.4-0.8-0.7-1.2-0.8c-0.3-0.2-0.8-0.3-1.2-0.3
									c0-0.5,0.1-1,0.3-1.4c0.2-0.4,0.4-0.7,0.7-0.9c0.9-0.8,1.9-0.7,3,0.3c-0.1-1.6,0.3-3,1.1-4.3c0.4,0.2,0.8,0.5,1.3,0.9
									c0.5,0.4,1,0.9,1.3,1.3c-0.3,0.2-0.6,0.4-0.9,0.6c-0.5,0.4-0.9,0.9-1.2,1.3c-0.3,0.5-0.5,0.9-0.7,1.4c0.9,1.1,1.8,2.1,2.6,3.1
									c1.4,1.6,2.5,2.6,3.4,3c-0.4,0.5-0.8,0.9-1.3,1.4C1455.3,872.3,1454.8,872.6,1454.2,873z"
						/>
						<path
							d="M1459.6,866.7c-1.4-0.2-2.7-1.1-3.9-2.5c-0.9-1-1.4-2.1-1.7-3.2c-0.3-1.1-0.2-2.2,0.2-3.3c0.4-1.1,1.1-2,2-2.8
									c1.3-1.1,2.6-1.5,3.9-1.2c1.3,0.3,2.7,1.2,4,2.8c-0.7,0.9-1.5,1.9-2.5,3c-1,1.1-2.1,2.2-3.2,3.1c0.8,0.7,1.6,1.1,2.3,1.1
									c0.7,0,1.4-0.3,2.2-0.9c0.6-0.5,1.2-1.1,1.6-1.8c0.4-0.6,0.7-1.3,0.9-1.9l0.8,0.6c0,1-0.2,2.1-0.7,3.1c-0.5,1.1-1.2,2-2,2.7
									C1462.3,866.5,1460.9,866.9,1459.6,866.7z M1459.4,859.5c0.6-0.7,1-1.4,1.2-1.9c-0.7-0.7-1.4-1.1-2.3-1.3
									c-0.8-0.1-1.6,0.1-2.2,0.6c-0.2,0.7-0.2,1.4,0.1,2.3c0.3,0.9,0.7,1.7,1.4,2.5C1458.2,861,1458.8,860.3,1459.4,859.5z"
						/>
						<path
							d="M1470.9,864.1c-0.4-0.5-0.8-1.2-1-1.8c-0.2-0.7-0.3-1.4-0.3-2.1c-0.4,0.1-0.7,0.1-1,0c-0.3-0.1-0.6-0.3-0.9-0.6
									c-0.4-0.4-0.6-1-0.5-1.6s0.2-1.3,0.6-1.9c-0.7,0.2-1.4,0.1-2.1-0.1c-0.7-0.3-1.3-0.7-1.8-1.3c-0.6-0.7-0.9-1.5-1-2.3
									c-0.1-0.8,0-1.7,0.4-2.5c0.4-0.8,1-1.6,1.8-2.3c0.8-0.7,1.7-1.1,2.5-1.2c0.5-0.8,0.9-1.8,1.1-3.2c0.4,0.2,0.9,0.4,1.3,0.7
									c0.4,0.3,0.8,0.6,1,0.8c-0.2,0.6-0.7,1.2-1.3,1.8c0.7,0.3,1.2,0.7,1.8,1.3c0.6,0.7,0.9,1.5,1,2.3c0.1,0.9-0.1,1.7-0.5,2.6
									c-0.4,0.9-1,1.6-1.8,2.3c-0.7,0.6-1.1,1.1-1.3,1.6c-0.2,0.5-0.2,0.9,0.1,1.3l4.6-3.4c1-0.7,1.8-1,2.6-1c0.8,0,1.5,0.4,2,1.1
									c0.5,0.6,0.8,1.4,0.7,2.2c0,0.9-0.3,1.7-0.8,2.7c-0.5,0.9-1.3,1.8-2.2,2.5C1474,863.2,1472.4,863.9,1470.9,864.1z M1470.5,851.8
									c0.1-0.6-0.1-1.2-0.6-1.8c-0.4-0.5-0.9-0.8-1.5-0.9c-0.6-0.1-1.2,0-1.9,0.3c-0.6,0.5-0.9,1.1-1,1.8c-0.1,0.7,0.1,1.3,0.6,1.9
									c0.5,0.6,1,0.9,1.6,1c0.6,0.1,1.2-0.1,1.7-0.6C1470.1,853,1470.4,852.4,1470.5,851.8z M1476.6,857.8c0.3-0.6,0.3-1.1,0-1.5
									c-0.2-0.3-0.5-0.4-0.8-0.3s-0.8,0.3-1.3,0.7l-3.6,2.6c0.1,0.5,0.3,1,0.5,1.4c1.2,0.3,2.4-0.1,3.6-1.1
									C1475.8,859.1,1476.3,858.4,1476.6,857.8z"
						/>
						<path
							d="M1467.5,840.6c0-1,0.1-1.9,0.3-2.9c1,0.2,1.9,0.3,2.9,0.3c0,1-0.1,1.9-0.3,2.9C1469.4,840.7,1468.4,840.6,1467.5,840.6z
									M1478.3,852.4c-0.4-0.1-0.9-0.5-1.5-1.2c-0.5-0.6-1.1-1.4-1.9-2.5c-0.8-1-1.3-1.8-1.7-2.2c-0.5-0.6-1-1.2-1.5-1.7
									c-0.5-0.5-0.9-0.8-1.3-0.9c0.3-0.6,0.7-1.1,1.1-1.5c0.4-0.4,0.9-0.9,1.4-1.3c0.3,0.5,0.8,1.3,1.5,2.2c0.7,1,1.5,2.1,2.5,3.2
									c0.7,0.9,1.2,1.5,1.7,1.9c0.4,0.4,0.8,0.7,1.1,0.9c0.3,0.2,0.7,0.3,1.2,0.4c0,0.5-0.1,1-0.4,1.4c-0.2,0.4-0.5,0.7-0.7,0.9
									C1479.2,852.4,1478.7,852.5,1478.3,852.4z"
						/>
						<path
							d="M1483.3,848.1c-1,0.1-2,0-2.9-0.4c-0.9-0.4-1.8-1.1-2.5-2.1c-0.8-1-1.3-2.1-1.5-3.3s-0.1-2.3,0.4-3.4
									c0.5-1.1,1.2-2,2.2-2.8c1.4-1.1,2.9-1.5,4.4-1.3c1.5,0.2,2.9,1,4,2.5c0.8,1,1.3,2.1,1.5,3.3c0.2,1.2,0,2.3-0.5,3.4
									c-0.5,1.1-1.2,2.1-2.3,2.9C1485.3,847.6,1484.3,848,1483.3,848.1z M1487,842.9c0-1-0.4-1.9-1.2-3c-0.7-0.9-1.5-1.5-2.5-1.8
									s-1.9-0.3-2.8,0.1c-0.9,0.7-1.4,1.5-1.4,2.5c-0.1,1,0.3,2,1.1,3c0.9,1.1,1.8,1.8,2.8,2.1c1,0.3,1.9,0.1,2.7-0.5
									C1486.6,844.7,1487,843.9,1487,842.9z"
						/>
						<path
							d="M1492.8,841.2c-1.1-1.7-2.3-3.4-3.7-5.2c-0.7-0.9-1.2-1.5-1.6-1.9c-0.4-0.4-0.8-0.7-1.1-0.9c-0.3-0.2-0.7-0.3-1.2-0.4
									c0.1-0.5,0.2-1,0.4-1.4c0.2-0.4,0.5-0.7,0.8-0.9c0.9-0.7,1.7-0.6,2.6,0.3c0.1-0.8,0.3-1.5,0.6-2.3c0.4-0.7,0.9-1.3,1.4-1.7
									c1.9-1.4,3.6-1.1,5.3,1.1l1.1,1.5l1.2,1.6c1.3,1.7,2.3,2.7,3.2,3.2c-0.4,0.4-0.8,0.9-1.4,1.3c-0.4,0.3-1,0.7-1.6,1
									c-1.3-1.9-2.7-3.9-4.3-6c-0.5-0.6-1-1-1.4-1.1c-0.5-0.1-1,0.1-1.5,0.5c-0.8,0.6-1.3,1.3-1.6,2l0.5,0.7c0.8,1.1,1.5,2.1,2.2,3
									c1.3,1.7,2.3,2.7,3.2,3.2c-0.4,0.5-0.8,0.9-1.3,1.2C1494,840.5,1493.5,840.9,1492.8,841.2z"
						/>
					</g>
					<g className="level_two fade location" id="moria_gate">
						<rect x="1284.112" y="1109.985" width="83.38" height="24.417" transform="matrix(0.947353, -0.32019, 0.308779, 0.95121, -166.607437, 276.899872)"></rect>
						<rect x="983.712" y="909.592" width="63.874" height="20.009" transform="matrix(0.947353, -0.32019, 0.308779, 0.95121, 189.089645, 392.880585)"></rect>
						<path
							d="M1412.2,956c-1.6-0.7-2.9-2.2-3.9-4.4c-0.7-1.6-0.9-3.1-0.8-4.6c0.1-1.5,0.6-2.8,1.5-4c0.9-1.2,2-2.1,3.5-2.7
									c1.7-0.7,3.3-1.2,4.6-1.2c0.2,0.5,0.3,1.1,0.4,1.7c0.1,0.7,0,1.2-0.1,1.6c-1-0.2-2.1-0.3-3.2-0.3c-1.1,0-1.9,0.1-2.4,0.4
									c-0.5,0.4-0.9,1-1.2,1.8c-0.3,0.8-0.4,1.6-0.3,2.6c0.1,0.9,0.3,1.8,0.6,2.7c0.8,1.9,1.8,3.2,2.9,3.8s2.4,0.5,4-0.2
									c0.6-0.3,1.2-0.6,1.8-1c-1.2-2.7-2.1-4.4-2.7-5c0.5-0.3,0.9-0.6,1.5-0.8c0.5-0.2,1.1-0.4,1.9-0.6c0.5,1.5,1.2,3.4,2.1,5.7
									c-0.4,0.8-1,1.7-1.9,2.4s-1.9,1.4-2.9,1.9C1415.6,956.7,1413.8,956.8,1412.2,956z"
						/>
						<path
							d="M1427.8,950.8c-0.6-0.3-1.1-0.9-1.4-1.7c-0.4-1.2-0.2-2.3,0.7-3.3c0.9-1,2.4-2,4.6-3c-0.4-1-0.8-1.7-1.4-2
									c-0.5-0.3-1.2-0.3-2.1,0c-0.6,0.2-1.2,0.5-1.7,0.9c-0.5,0.4-1,0.9-1.4,1.4l-0.6-0.8c0.3-1,0.7-2,1.5-2.8c0.7-0.8,1.5-1.4,2.5-1.8
									c1.3-0.5,2.4-0.5,3.3-0.1c0.9,0.4,1.6,1.2,2,2.4c0.2,0.7,0.5,1.5,0.7,2.3c0.2,0.9,0.5,1.6,0.6,2c0.3,0.7,0.5,1.2,0.8,1.6
									s0.7,0.7,1.3,1c-0.5,0.9-1.3,1.5-2.2,2c-0.7-0.2-1.2-0.5-1.6-1.1c-0.5,0.7-1.1,1.3-1.7,1.9c-0.6,0.5-1.3,0.9-1.9,1.1
									C1429.1,951.2,1428.4,951.1,1427.8,950.8z M1431.9,948c0.4-0.4,0.7-0.8,1-1.2l-0.1-0.2c-0.2-0.5-0.4-1.1-0.5-1.6l-0.3-1.2
									c-1.1,0.5-2,1.1-2.5,1.7c-0.5,0.7-0.6,1.3-0.4,2c0.1,0.3,0.3,0.6,0.6,0.8c0.3,0.2,0.6,0.4,0.9,0.5
									C1431.1,948.7,1431.5,948.4,1431.9,948z"
						/>
						<path
							d="M1443.1,945.7c-0.4-0.3-0.8-0.8-1.1-1.7c-0.2-0.6-0.4-1.5-0.8-2.8c-0.4-1.4-0.7-2.6-1-3.5c-0.1-0.4-0.3-0.8-0.4-1.1
									c-0.8,0.2-1.3,0.3-1.6,0.3c-0.3,0-0.5-0.2-0.6-0.6c1.1-1.1,2.1-2.4,2.9-4l1-0.2c0.3,0.6,0.5,1.3,0.8,2c1.3-0.4,2.2-0.8,2.8-1.1
									c0.1,0.4,0.2,0.8,0.2,1.2c0,0.4,0,0.7-0.1,1.1c-0.6,0-1.5,0.1-2.5,0.4c0.2,0.8,0.4,1.7,0.7,2.5c0.7,2.2,1.3,3.8,1.9,5
									c0.8-0.3,1.5-0.6,2.1-0.9l0.4,0.8c-0.2,0.6-0.6,1.2-1.1,1.8c-0.5,0.5-1.2,0.9-1.8,1.1C1444.2,946,1443.6,946,1443.1,945.7z"
						/>
						<path
							d="M1452.6,941.9c-1.1-0.8-1.9-2.1-2.4-4c-0.3-1.3-0.4-2.5-0.1-3.6s0.8-2.1,1.6-2.9c0.8-0.8,1.8-1.3,3.1-1.7
									c1.6-0.4,3-0.2,4,0.6c1.1,0.8,1.9,2.2,2.4,4.3c-1,0.5-2.2,1.1-3.6,1.6c-1.4,0.6-2.8,1-4.2,1.4c0.4,1,0.9,1.6,1.6,2
									s1.4,0.4,2.4,0.1c0.8-0.2,1.6-0.5,2.2-0.9c0.7-0.4,1.2-0.8,1.7-1.3l0.5,0.9c-0.4,0.9-1.1,1.8-2,2.5s-1.9,1.2-3,1.5
									C1455.1,942.9,1453.8,942.7,1452.6,941.9z M1455.6,935.4c0.8-0.4,1.5-0.8,1.9-1.2c-0.3-0.9-0.8-1.7-1.5-2.1
									c-0.7-0.5-1.5-0.6-2.3-0.4c-0.5,0.5-0.8,1.2-0.9,2.1s-0.1,1.8,0.2,2.8C1453.9,936.2,1454.8,935.8,1455.6,935.4z"
						/>
						<path
							d="M1406.8,938.2c-0.6-2-1.6-4.8-3-8.4c-0.7-1.7-1.2-3.1-1.6-4c-0.4-1-0.8-1.7-1.2-2.2c0.5-0.3,1-0.6,1.6-0.8
									c0.5-0.2,1.1-0.4,1.9-0.5c1.2,1.2,2.7,2.7,4.5,4.4c1.8,1.7,3.3,3.2,4.5,4.2c0.2-1.8,0.3-3.9,0.5-6.5c0.2-2.5,0.3-4.5,0.3-5.9
									c0.4-0.3,0.9-0.6,1.6-0.8c0.5-0.2,1.2-0.4,1.9-0.6c0.3,1,0.7,2.3,1.2,3.9c0.6,1.6,1.1,3.2,1.7,4.6c1.3,3.4,2.3,5.5,2.9,6.2
									c-0.5,0.3-1.1,0.6-1.7,0.9c-0.5,0.2-1.1,0.4-1.8,0.5c-0.3-1-0.7-2.3-1.2-3.9c-0.6-1.6-1.1-3.2-1.7-4.6c-0.3-0.8-0.7-1.7-1.2-2.8
									c-0.3,3.8-0.6,8.2-0.9,13.3c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.1-0.7,0.1c-3.1-3.1-6.1-6-8.8-8.5c0.7,1.8,1.2,3.2,1.5,4.1
									c1.3,3.4,2.2,5.4,2.8,6.2c-0.4,0.3-0.7,0.5-1.1,0.6C1407.7,938.1,1407.3,938.2,1406.8,938.2z"
						/>
						<path
							d="M1430.1,929c-1-0.2-1.9-0.7-2.6-1.4c-0.7-0.7-1.3-1.6-1.7-2.8c-0.4-1.2-0.5-2.4-0.3-3.6s0.7-2.2,1.5-3.1s1.8-1.5,3-2
									c1.7-0.6,3.2-0.5,4.6,0.2c1.4,0.7,2.4,1.9,3,3.6c0.4,1.2,0.5,2.5,0.3,3.6s-0.7,2.2-1.5,3.1s-1.8,1.5-3.1,2
									C1432.1,929.1,1431.1,929.2,1430.1,929z M1435.3,925.3c0.3-0.9,0.3-2-0.1-3.2c-0.4-1.1-1-1.9-1.7-2.5c-0.8-0.6-1.7-0.9-2.7-0.9
									c-1.1,0.4-1.8,1-2.2,1.9c-0.4,0.9-0.4,2,0.1,3.2c0.5,1.4,1.1,2.3,2,2.9c0.9,0.6,1.7,0.7,2.7,0.4
									C1434.3,926.8,1434.9,926.2,1435.3,925.3z"
						/>
						<path
							d="M1444.7,924.5c-0.5-1.9-1.1-4-1.7-6.1c-0.3-1-0.6-1.8-0.9-2.4c-0.2-0.5-0.5-0.9-0.7-1.2c-0.2-0.3-0.6-0.5-1-0.8
									c0.2-0.5,0.5-0.8,0.8-1.1c0.3-0.3,0.7-0.5,1-0.6c1.2-0.4,2.1,0.1,2.6,1.5c0.6-1.5,1.5-2.7,2.7-3.5c0.3,0.3,0.6,0.8,0.9,1.4
									c0.3,0.6,0.5,1.2,0.7,1.7c-0.4,0.1-0.7,0.1-1,0.2c-0.6,0.2-1.1,0.4-1.6,0.8c-0.5,0.3-0.9,0.6-1.2,1c0.4,1.4,0.8,2.7,1.2,3.8
									c0.6,2,1.3,3.4,1.9,4.1c-0.5,0.3-1.1,0.5-1.7,0.7C1446.1,924.2,1445.5,924.4,1444.7,924.5z"
						/>
						<path
							d="M1450.5,905.7c0.4-0.9,0.8-1.7,1.4-2.5c0.8,0.5,1.7,1,2.6,1.4c-0.4,0.9-0.8,1.7-1.4,2.5
									C1452.2,906.5,1451.4,906,1450.5,905.7z M1456,920.7c-0.4-0.3-0.7-0.8-0.9-1.7c-0.2-0.7-0.5-1.7-0.8-3c-0.3-1.2-0.6-2.1-0.8-2.7
									c-0.2-0.8-0.5-1.5-0.8-2.1c-0.3-0.6-0.6-1.1-0.8-1.3c0.5-0.4,1.1-0.7,1.6-1c0.5-0.3,1.1-0.5,1.8-0.7c0.1,0.6,0.2,1.5,0.5,2.6
									c0.3,1.2,0.6,2.5,1.1,3.9c0.3,1.1,0.6,1.8,0.8,2.4c0.2,0.5,0.5,0.9,0.7,1.2c0.2,0.3,0.6,0.5,1,0.8c-0.2,0.5-0.5,0.8-0.8,1.1
									c-0.3,0.3-0.7,0.5-1,0.6C1456.9,921,1456.4,920.9,1456,920.7z"
						/>
						<path
							d="M1463.9,918.1c-0.6-0.4-1-1-1.3-1.7c-0.3-1.2,0-2.3,0.9-3.3c0.9-1,2.5-1.9,4.8-2.7c-0.3-1.1-0.7-1.7-1.2-2.1
									c-0.5-0.3-1.2-0.4-2.1-0.1c-0.6,0.2-1.2,0.4-1.8,0.8c-0.6,0.4-1.1,0.8-1.5,1.3l-0.5-0.9c0.3-1,0.9-1.9,1.7-2.7
									c0.8-0.8,1.6-1.3,2.6-1.6c1.3-0.4,2.4-0.3,3.3,0.1c0.9,0.5,1.5,1.3,1.9,2.6c0.2,0.7,0.4,1.5,0.5,2.4c0.2,0.9,0.3,1.6,0.5,2.1
									c0.2,0.7,0.4,1.3,0.7,1.6c0.3,0.4,0.7,0.7,1.3,1.1c-0.6,0.8-1.4,1.4-2.3,1.8c-0.6-0.2-1.2-0.6-1.5-1.2c-0.6,0.7-1.2,1.3-1.9,1.7
									c-0.7,0.5-1.3,0.8-1.9,1C1465.2,918.6,1464.5,918.5,1463.9,918.1z M1468.1,915.7c0.4-0.3,0.8-0.7,1.1-1.1l-0.1-0.2
									c-0.2-0.6-0.3-1.1-0.4-1.7l-0.2-1.2c-1.2,0.4-2,0.9-2.6,1.5s-0.7,1.3-0.5,2c0.1,0.3,0.3,0.6,0.5,0.9c0.3,0.3,0.5,0.4,0.9,0.5
									C1467.3,916.3,1467.7,916,1468.1,915.7z"
						/>
					</g>
					<g className="level_two fade location " id="glanduin">
						<rect x="1658.994" y="1264.167" width="107.721" height="27.809" transform="matrix(0.991515, -0.129993, 0.11805, 0.993081, -463.506714, -55.008945)"></rect>
						<path
							d="M1338.4,1006.5c-1.5-0.9-2.7-2.5-3.4-4.8c-0.5-1.6-0.6-3.2-0.3-4.6c0.3-1.5,0.9-2.8,1.9-3.8c1-1.1,2.2-1.8,3.8-2.3
									c1.8-0.6,3.4-0.8,4.7-0.7c0.2,0.5,0.2,1.1,0.2,1.8c0,0.7-0.1,1.2-0.2,1.6c-1-0.3-2-0.6-3.1-0.7c-1.1-0.1-1.9-0.1-2.4,0.1
									c-0.6,0.3-1,0.9-1.4,1.6c-0.3,0.8-0.5,1.6-0.6,2.5c-0.1,0.9,0.1,1.9,0.3,2.7c0.6,2,1.4,3.4,2.5,4.1c1,0.7,2.4,0.8,4,0.3
									c0.6-0.2,1.3-0.5,1.9-0.8c-0.9-2.8-1.6-4.6-2.1-5.2c0.5-0.3,1-0.5,1.6-0.7c0.5-0.2,1.2-0.3,1.9-0.4c0.3,1.6,0.8,3.5,1.5,5.9
									c-0.5,0.8-1.2,1.5-2.1,2.2c-1,0.7-2,1.2-3.1,1.6C1341.8,1007.5,1339.9,1007.3,1338.4,1006.5z"
						/>
						<path
							d="M1353.6,1003.3c-0.4-0.3-0.7-0.9-0.9-1.7c-0.2-0.6-0.4-1.6-0.7-2.9c-0.4-1.5-0.7-2.7-1-3.6c-0.9-3.1-1.7-5.3-2.4-6.5
									c0.5-0.4,1.1-0.7,1.6-0.9c0.5-0.2,1.1-0.5,1.8-0.7c0.3,2.1,1.2,5.5,2.5,10.3c0.3,1.1,0.6,1.9,0.8,2.4c0.2,0.5,0.5,1,0.7,1.2
									c0.2,0.3,0.6,0.6,1,0.8c-0.2,0.5-0.5,0.8-0.9,1.1c-0.4,0.3-0.7,0.5-1,0.6C1354.5,1003.6,1354,1003.6,1353.6,1003.3z"
						/>
						<path
							d="M1359.9,1001.4c-0.6-0.4-1-1-1.2-1.8c-0.3-1.2,0-2.3,1-3.2c1-1,2.6-1.8,4.9-2.6c-0.3-1.1-0.7-1.8-1.2-2.1
									c-0.5-0.3-1.2-0.4-2.1-0.2c-0.6,0.2-1.2,0.4-1.8,0.8c-0.6,0.4-1.1,0.8-1.5,1.2l-0.5-0.9c0.3-1,0.9-1.9,1.7-2.7
									c0.8-0.8,1.7-1.3,2.6-1.5c1.3-0.3,2.4-0.3,3.3,0.2c0.9,0.5,1.5,1.3,1.8,2.6c0.2,0.7,0.3,1.5,0.5,2.4c0.2,0.9,0.3,1.6,0.4,2.1
									c0.2,0.7,0.4,1.3,0.7,1.6c0.3,0.4,0.7,0.7,1.3,1.1c-0.6,0.8-1.4,1.4-2.4,1.8c-0.6-0.2-1.1-0.6-1.5-1.2c-0.6,0.7-1.2,1.2-1.9,1.7
									c-0.7,0.5-1.3,0.8-2,0.9C1361.2,1001.8,1360.5,1001.7,1359.9,1001.4z M1364.2,999c0.4-0.3,0.8-0.7,1.1-1.1l-0.1-0.2
									c-0.1-0.6-0.3-1.1-0.3-1.7l-0.2-1.2c-1.2,0.4-2.1,0.9-2.6,1.5c-0.5,0.6-0.7,1.3-0.5,2c0.1,0.3,0.2,0.6,0.5,0.9
									c0.2,0.3,0.5,0.5,0.8,0.6C1363.3,999.6,1363.7,999.3,1364.2,999z"
						/>
						<path
							d="M1373.4,998.3c-0.3-2-0.8-4.1-1.3-6.3c-0.3-1.1-0.5-1.9-0.7-2.4c-0.2-0.5-0.4-1-0.6-1.3c-0.2-0.3-0.5-0.6-1-0.8
									c0.3-0.5,0.6-0.8,1-1.1c0.4-0.3,0.7-0.4,1.1-0.5c1.1-0.3,1.8,0.2,2.3,1.3c0.4-0.7,0.9-1.3,1.5-1.8c0.6-0.5,1.3-0.8,2-1
									c2.3-0.6,3.8,0.5,4.4,3.2l0.4,1.8l0.4,2c0.5,2,1,3.4,1.6,4.2c-0.5,0.2-1.1,0.5-1.8,0.6c-0.5,0.1-1.2,0.2-1.9,0.3
									c-0.4-2.2-0.9-4.7-1.5-7.3c-0.2-0.8-0.5-1.3-0.9-1.6c-0.4-0.3-0.9-0.3-1.6-0.1c-1,0.2-1.7,0.6-2.3,1.2l0.2,0.9
									c0.3,1.4,0.6,2.6,0.8,3.6c0.5,2,1,3.4,1.6,4.2c-0.5,0.3-1.1,0.5-1.7,0.6C1374.8,998.2,1374.1,998.3,1373.4,998.3z"
						/>
						<path
							d="M1388.1,993.5c-1-0.9-1.7-2.4-2.1-4.4c-0.4-2.1-0.2-3.8,0.4-5.1c0.7-1.2,1.8-2,3.5-2.3c1.3-0.2,2.5-0.1,3.7,0.5
									c-0.4-1.9-0.8-3.3-1.3-4.2c1-0.6,2.2-1,3.6-1.2c0.2,2.4,0.8,6.5,1.7,12.1c0.1,0.8,0.3,1.4,0.6,1.8c0.2,0.4,0.6,0.8,1.2,1.2
									c-0.3,0.4-0.7,0.7-1.1,1c-0.4,0.3-0.9,0.5-1.4,0.7c-0.6-0.3-1.1-0.8-1.4-1.4c-0.4,0.6-1,1.1-1.6,1.5c-0.6,0.4-1.2,0.7-1.9,0.8
									C1390.4,994.7,1389.1,994.4,1388.1,993.5z M1395.1,991c-0.1-0.6-0.3-1.6-0.5-3c-0.2-1.5-0.4-2.7-0.6-3.6c-0.7-0.3-1.6-0.4-2.6-0.2
									c-1,0.2-1.7,0.7-2.1,1.4c-0.4,0.8-0.5,1.8-0.3,3.1c0.2,1.2,0.6,2.2,1.2,2.9c0.6,0.7,1.4,1,2.2,1
									C1393.5,992.4,1394.3,991.8,1395.1,991z"
						/>
						<path
							d="M1402.1,988.7l0-1.9l0-2c-0.1-2-0.4-3.3-0.8-4.2c1.1-0.5,2.3-0.7,3.7-0.8c0,1.8,0,4.5,0.2,8.1c0,0.8,0.2,1.3,0.5,1.7
									c0.3,0.3,0.8,0.5,1.5,0.4c0.9,0,1.8-0.3,2.5-0.8c-0.1-1.3-0.1-2.4-0.1-3.3l0-1.5c-0.1-2-0.4-3.3-0.8-4.2c1.1-0.5,2.3-0.7,3.7-0.8
									c0,1.3,0,2.8,0,4.5c0,1.7,0,3,0.1,3.9c0,0.8,0.1,1.4,0.3,1.8c0.2,0.4,0.5,0.9,1,1.4c-0.4,0.3-0.8,0.6-1.3,0.9
									c-0.5,0.2-1,0.4-1.5,0.5c-0.6-0.4-1-1-1.3-1.7c-0.5,0.6-1.1,1.1-1.8,1.4s-1.4,0.5-2.2,0.6C1403.5,992.8,1402.2,991.5,1402.1,988.7
									z"
						/>
						<path
							d="M1417.2,991.9c-0.3-0.4-0.4-1-0.3-1.9c0-0.7,0.1-1.8,0.2-3.1c0.1-1.3,0.1-2.2,0.2-2.8c0-0.8,0-1.5-0.1-2.3
									c-0.1-0.7-0.2-1.2-0.4-1.5c0.6-0.2,1.2-0.3,1.8-0.4c0.6-0.1,1.2-0.1,1.9-0.1c-0.1,0.6-0.2,1.5-0.4,2.7c-0.1,1.2-0.2,2.6-0.3,4.1
									c0,1.1,0,1.9,0,2.5c0,0.6,0.1,1,0.3,1.4s0.4,0.7,0.7,1.1c-0.4,0.4-0.7,0.6-1.2,0.8s-0.8,0.2-1.1,0.2
									C1417.9,992.5,1417.5,992.3,1417.2,991.9z M1416.9,975.9c0.7-0.7,1.4-1.4,2.1-2c0.6,0.8,1.3,1.5,2,2.1c-0.7,0.7-1.4,1.4-2.1,2
									C1418.2,977.3,1417.6,976.6,1416.9,975.9z"
						/>
						<path
							d="M1424.3,992.5c0.4-2,0.7-4.1,1-6.3c0.1-1.1,0.2-1.9,0.2-2.5c0-0.6,0-1.1-0.2-1.4c-0.1-0.4-0.3-0.7-0.6-1.1
									c0.4-0.3,0.8-0.6,1.3-0.7c0.4-0.1,0.8-0.2,1.2-0.1c1.1,0.1,1.7,0.8,1.7,2c0.6-0.5,1.3-0.9,2-1.2c0.8-0.2,1.5-0.3,2.3-0.2
									c2.3,0.3,3.3,1.8,3,4.5l-0.3,1.9l-0.3,2c-0.3,2.1-0.3,3.6,0,4.5c-0.6,0-1.2,0-1.9-0.1c-0.6-0.1-1.2-0.2-1.9-0.4
									c0.4-2.2,0.8-4.7,1.1-7.3c0.1-0.8,0-1.4-0.2-1.8c-0.3-0.4-0.7-0.6-1.4-0.7c-1-0.1-1.8,0-2.5,0.3l-0.1,0.9
									c-0.2,1.4-0.4,2.6-0.5,3.6c-0.3,2.1-0.3,3.6,0,4.5c-0.6,0-1.2,0-1.8,0C1425.6,992.8,1424.9,992.7,1424.3,992.5z"
						/>
					</g>
					<g className="level_two fade location " id="east_bight">
						<rect x="1271.327" y="2322.001" width="82.551" height="51.082" transform="matrix(0.999629, 0.02723, -0.039272, 0.999301, 882.006531, -1530.433105)"></rect>
						<path
							d="M2084.2,872.2c-1.1-0.1-2-0.2-2.8-0.4c0-0.9,0-2.2-0.1-3.9c-0.1-1.7-0.2-3.3-0.3-4.9c-0.3-3.2-0.6-5.2-0.9-5.8
									c0.7-0.3,1.6-0.6,2.7-0.8c1.1-0.2,2.2-0.4,3.3-0.5c1.9-0.2,3.5,0.1,4.6,0.7s1.7,1.6,1.8,3c0.1,0.7-0.1,1.4-0.5,2
									c-0.4,0.6-1,1.2-1.7,1.6c1,0.3,1.9,0.8,2.5,1.4c0.6,0.7,1,1.4,1,2.3c0.1,1.5-0.4,2.7-1.5,3.6s-2.7,1.4-4.9,1.6
									C2086.4,872.2,2085.3,872.2,2084.2,872.2z M2086.7,863c0.5,0,0.8-0.1,1.1-0.1c1.1-0.8,1.6-1.7,1.5-2.7c-0.1-1.6-1.2-2.3-3.3-2.2
									c-0.8,0.1-1.5,0.2-2.1,0.5c0,1.4,0.1,3.1,0.2,4.9C2084.8,863.2,2085.7,863,2086.7,863z M2090.6,867.2c-0.1-0.7-0.3-1.3-0.8-1.8
									c-0.5-0.5-1.1-0.9-1.9-1.1c-0.4,0-1,0.1-1.6,0.3c-0.7,0.2-1.4,0.4-2.1,0.7c0.2,2.2,0.4,3.9,0.6,5.1c0.7,0.2,1.5,0.3,2.3,0.2
									C2089.6,870.4,2090.7,869.3,2090.6,867.2z"
						/>
						<path
							d="M2096.1,855c0.6-0.8,1.3-1.5,2-2.1c0.7,0.7,1.4,1.4,2.1,2c-0.6,0.8-1.3,1.5-2,2.1C2097.6,856.2,2096.9,855.5,2096.1,855z
									M2097.7,870.9c-0.3-0.3-0.4-1-0.5-1.9c0-0.7-0.1-1.8-0.1-3.1c0-1.3,0-2.2-0.1-2.8c0-0.8-0.1-1.5-0.2-2.2
									c-0.1-0.7-0.3-1.2-0.5-1.5c0.6-0.2,1.2-0.4,1.8-0.5c0.6-0.1,1.2-0.2,1.9-0.2c-0.1,0.6-0.1,1.5-0.2,2.7c0,1.2,0,2.6,0.1,4.1
									c0,1.1,0.1,1.9,0.2,2.5c0.1,0.6,0.2,1,0.4,1.4c0.2,0.3,0.4,0.7,0.8,1c-0.3,0.4-0.7,0.7-1.1,0.9s-0.8,0.3-1.1,0.3
									C2098.5,871.4,2098,871.2,2097.7,870.9z"
						/>
						<path
							d="M2103.3,875c0-0.7,0.1-1.4,0.4-2.1s0.6-1.3,1.1-1.8c-0.3-0.2-0.6-0.4-0.8-0.7c-0.2-0.3-0.3-0.6-0.3-1
									c0-0.6,0.2-1.1,0.6-1.6c0.4-0.5,0.9-0.8,1.6-1.1c-0.7-0.3-1.2-0.8-1.5-1.4c-0.4-0.6-0.6-1.4-0.6-2.2c0-0.9,0.2-1.7,0.7-2.5
									c0.5-0.7,1.1-1.3,1.9-1.7c0.8-0.4,1.8-0.6,2.8-0.6c1,0,2,0.2,2.8,0.6c0.9-0.3,1.8-0.8,2.9-1.8c0.2,0.4,0.4,0.8,0.6,1.3
									c0.2,0.5,0.2,0.9,0.2,1.2c-0.6,0.3-1.3,0.5-2.2,0.6c0.3,0.6,0.5,1.3,0.5,2.1c0,0.9-0.2,1.7-0.7,2.5c-0.5,0.7-1.1,1.3-2,1.7
									c-0.9,0.4-1.8,0.6-2.9,0.6c-0.9,0-1.6,0.1-2.1,0.4c-0.5,0.3-0.8,0.6-0.8,1l5.7,0.3c1.1,0.1,2,0.4,2.6,0.9c0.6,0.5,0.9,1.2,0.9,2.1
									c0,0.8-0.3,1.5-0.8,2.2c-0.6,0.7-1.3,1.2-2.3,1.5c-1,0.4-2.1,0.6-3.3,0.6C2106.3,876.3,2104.6,875.9,2103.3,875z M2111.7,873.7
									c0.6-0.3,0.9-0.7,0.9-1.1c0-0.4-0.1-0.6-0.4-0.8c-0.3-0.1-0.8-0.2-1.5-0.3l-4.4-0.2c-0.2,0.5-0.4,0.9-0.5,1.4
									c0.8,1,1.9,1.4,3.5,1.4C2110.2,874.2,2111.1,874,2111.7,873.7z M2110.8,865.2c0.4-0.4,0.6-1,0.6-1.7c0-0.7-0.2-1.2-0.6-1.7
									c-0.4-0.5-0.9-0.8-1.6-1c-0.7,0-1.4,0.3-1.9,0.8c-0.5,0.5-0.7,1.1-0.7,1.9c0,0.7,0.2,1.3,0.7,1.8c0.4,0.4,1,0.7,1.7,0.7
									C2109.7,865.9,2110.3,865.6,2110.8,865.2z"
						/>
						<path
							d="M2117.3,870.9c0.2-2.5,0.4-5.1,0.5-7.6c0.1-3.5,0.2-6.2,0-8.2c1.1-0.4,2.4-0.5,3.8-0.4c-0.2,1.3-0.5,3.2-0.7,5.8
									c0.6-0.6,1.2-1,1.9-1.3c0.7-0.3,1.5-0.4,2.2-0.4c2.4,0.1,3.5,1.5,3.4,4.3l-0.1,1.9l-0.1,2c-0.1,2.1,0,3.6,0.4,4.5
									c-0.6,0.1-1.2,0.1-1.9,0.1c-0.5,0-1.2-0.1-1.9-0.3c0.3-2.6,0.4-5.1,0.5-7.4c0-0.8-0.1-1.4-0.4-1.7c-0.3-0.4-0.8-0.6-1.5-0.6
									c-1,0-1.8,0.2-2.6,0.6l-0.1,2.2c-0.2,3.6,0,5.8,0.3,6.8c-0.6,0.1-1.3,0.1-1.9,0.1C2118.7,871.1,2118,871,2117.3,870.9z"
						/>
						<path
							d="M2133.5,871.5c-0.3-0.4-0.4-1.1-0.3-2c0-0.6,0.2-1.6,0.3-2.9c0.2-1.5,0.3-2.7,0.4-3.7c0-0.4,0-0.8,0-1.2
									c-0.8-0.1-1.4-0.2-1.6-0.3c-0.3-0.1-0.4-0.4-0.3-0.8c1.4-0.6,2.8-1.5,4.2-2.7l1,0.2c0,0.7,0,1.4,0,2.1c1.3,0.1,2.3,0.1,3,0
									c0,0.5-0.1,0.9-0.2,1.2c-0.1,0.3-0.3,0.7-0.5,1c-0.6-0.3-1.4-0.5-2.5-0.6c-0.1,0.8-0.2,1.7-0.3,2.6c-0.2,2.3-0.2,4.1-0.1,5.4
									c0.8,0,1.6,0,2.3-0.1l0.1,0.9c-0.4,0.5-1,0.9-1.7,1.2c-0.7,0.3-1.4,0.4-2.1,0.3C2134.3,872.1,2133.8,871.9,2133.5,871.5z"
						/>
						<path
							d="M2066.4,841.4c0-1.3,0-2.8,0-4.5c0-1.8-0.1-3.2-0.2-4.2c-0.1-1-0.2-1.9-0.4-2.5c1.1,0.1,2.7,0.1,5,0.1
									c2.7-0.1,4.8-0.2,6.3-0.4c0,0.6,0,1.2-0.1,1.9s-0.2,1.2-0.4,1.4c-0.5-0.2-1.2-0.4-2-0.5c-0.8-0.1-1.6-0.2-2.4-0.1
									c-0.9,0-1.8,0.1-2.7,0.3c0,1.6,0,2.9,0,4.1c1,0,1.7,0,2.2,0c1,0,1.7-0.1,2.3-0.1c0.5-0.1,1.1-0.2,1.8-0.3c0.1,0.5,0.1,0.9,0.2,1.2
									c0,0.3,0,0.7-0.1,1.3c-2.3-0.1-4.1-0.1-5.3-0.1l-1,0.1c0.1,1.9,0.1,3.3,0.2,4.2c1,0.2,2,0.2,2.9,0.2c0.9,0,1.8-0.1,2.7-0.3
									c0.9-0.2,1.6-0.4,2.2-0.7c0,0.6,0,1.2-0.1,1.9c-0.1,0.7-0.2,1.2-0.4,1.4c-1.4-0.1-3.2-0.1-5.7-0.1c-2.2,0.1-3.9,0.2-5,0.3
									C2066.3,844.2,2066.3,842.6,2066.4,841.4z"
						/>
						<path
							d="M2080.4,845c-0.5-0.5-0.8-1.2-0.8-2c0-1.2,0.5-2.2,1.7-2.9c1.1-0.7,2.9-1.2,5.3-1.4c-0.1-1.1-0.3-1.9-0.7-2.3
									s-1.1-0.7-2-0.6c-0.6,0-1.3,0.1-1.9,0.4c-0.6,0.2-1.2,0.5-1.7,0.9l-0.3-1c0.6-0.9,1.3-1.6,2.3-2.2c0.9-0.6,1.9-0.9,2.9-0.9
									c1.4,0,2.4,0.3,3.2,0.9s1.2,1.7,1.2,2.9c0,0.7,0,1.5-0.1,2.4c-0.1,1-0.1,1.7-0.1,2.1c0,0.8,0.1,1.3,0.3,1.8s0.5,0.9,1,1.4
									c-0.8,0.7-1.7,1.1-2.7,1.2c-0.6-0.4-1-0.9-1.2-1.6c-0.7,0.5-1.5,0.9-2.2,1.2c-0.8,0.3-1.5,0.5-2.1,0.5
									C2081.6,845.8,2080.9,845.5,2080.4,845z M2085.1,843.6c0.5-0.2,1-0.5,1.4-0.8l0-0.2c0-0.6,0-1.1,0-1.7l0.1-1.2
									c-1.2,0.1-2.2,0.4-2.9,0.9s-1,1.1-1,1.8c0,0.3,0.1,0.6,0.3,1c0.2,0.3,0.4,0.6,0.7,0.7C2084.2,844,2084.7,843.9,2085.1,843.6z"
						/>
						<path
							d="M2092.7,844.4c0-0.8,0.1-1.6,0.5-2.4c0.4,0.5,1,0.8,1.6,1.1c0.7,0.3,1.4,0.4,2.2,0.4c1.8,0,2.6-0.5,2.6-1.5
									c0-0.4-0.2-0.7-0.6-1c-0.4-0.2-1-0.5-1.9-0.8c-0.9-0.4-1.7-0.7-2.2-1c-0.6-0.3-1.1-0.7-1.5-1.3c-0.4-0.5-0.7-1.2-0.7-2
									c0-1,0.4-1.8,1.3-2.4c0.9-0.6,2-1,3.5-1c1.8-0.1,3.4,0.1,4.7,0.6c0,0.4-0.1,0.9-0.2,1.5c-0.2,0.6-0.4,1.1-0.6,1.6
									c-0.8-0.6-1.5-1.1-2.2-1.5c-0.7-0.3-1.3-0.5-1.8-0.5c-0.4,0.1-0.8,0.3-1,0.5c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.5,0.2,0.9,0.7,1.1
									s1.1,0.6,2,1c0.9,0.3,1.6,0.7,2.1,1c0.6,0.3,1,0.7,1.4,1.2c0.4,0.5,0.6,1.1,0.6,1.9c0,1.2-0.4,2.1-1.3,2.7c-0.9,0.7-2.2,1-3.9,1.1
									C2095.6,845.4,2094,845,2092.7,844.4z"
						/>
						<path
							d="M2107,844.4c-0.3-0.4-0.5-1-0.6-2c0-0.6,0-1.6,0-2.9c0-1.5,0-2.7,0-3.7c0-0.4,0-0.8-0.1-1.2c-0.8,0-1.4,0-1.7-0.1
									s-0.4-0.4-0.4-0.7c1.4-0.8,2.6-1.8,3.8-3.1l1,0.1c0.1,0.7,0.2,1.4,0.2,2.1c1.3-0.1,2.3-0.2,3-0.3c0,0.5,0,0.9-0.1,1.2
									c-0.1,0.4-0.2,0.7-0.4,1.1c-0.6-0.2-1.4-0.3-2.5-0.3c0,0.8,0,1.7,0,2.6c0.1,2.3,0.2,4.1,0.5,5.3c0.8,0,1.6-0.2,2.3-0.3l0.2,0.9
									c-0.4,0.6-0.9,1-1.5,1.4c-0.7,0.4-1.4,0.5-2.1,0.6C2107.9,845,2107.3,844.8,2107,844.4z"
						/>
					</g>
					<g className="level_zero location " id="anduin">
						<rect x="2907.13" y="1578.939" width="188.767" height="34.734" transform="matrix(0.442658, -0.89669, 0.891359, 0.453459, -973.499207, 2891.819824)"></rect>
						<rect x="2480.325" y="1946.567" width="161.053" height="42.821" transform="matrix(0.745213, 0.666826, -0.675802, 0.737182, 1193.788696, -2093.374512)"></rect>
						<path
							d="M1804.8,852c1.1-0.2,2.7-0.6,4.8-1.1c0.2-1.2,0.4-2.6,0.6-4.1c0.2-1.6,0.3-2.8,0.2-3.5c-1.8-0.9-3.2-1.6-4.4-2.1
									c-0.1-0.5-0.1-0.9-0.1-1.3c0.1-0.5,0.2-1,0.4-1.5c1.1,0.8,3.8,2.3,8.1,4.4c4.3,2.2,7.7,3.8,10.1,4.9c0,0.7,0,1.3,0,1.8
									c-0.1,0.5-0.2,1.1-0.4,1.6c-2.6,0.5-6.3,1.4-10.9,2.6c-4.7,1.2-7.6,2-8.9,2.5c-0.1-0.7-0.1-1.4,0-2.2
									C1804.4,853.5,1804.5,852.7,1804.8,852z M1812.1,850.3c3.6-0.9,6.2-1.6,8-2.1c-1.5-0.9-3.9-2.1-7.3-3.7c-0.2,1.2-0.4,2.4-0.5,3.9
									C1812.2,849.3,1812.1,849.9,1812.1,850.3z"
						/>
						<path
							d="M1802.7,862.7c2.3,0.8,4.7,1.7,7.4,2.4c1.3,0.4,2.3,0.6,3,0.7c0.7,0.1,1.3,0.2,1.7,0.1s0.9-0.2,1.5-0.5
									c0.3,0.5,0.5,1.1,0.6,1.6c0.1,0.5,0,1-0.1,1.4c-0.4,1.2-1.3,1.7-2.7,1.5c0.5,0.8,0.9,1.7,1,2.6c0.2,0.9,0.1,1.8-0.1,2.7
									c-0.8,2.6-2.8,3.5-6,2.5l-2.2-0.7l-2.3-0.8c-2.4-0.7-4.3-1-5.4-0.9c0-0.7,0.2-1.4,0.4-2.2c0.2-0.6,0.5-1.3,0.8-2.1
									c2.6,0.9,5.5,1.9,8.6,2.8c0.9,0.3,1.7,0.3,2.2,0.1c0.5-0.2,0.9-0.7,1.1-1.5c0.3-1.1,0.4-2.1,0.1-3l-1-0.3c-1.6-0.5-3-1-4.3-1.3
									c-2.4-0.7-4.3-1-5.4-0.9c0.1-0.7,0.2-1.4,0.4-2.1C1802,864.2,1802.3,863.5,1802.7,862.7z"
						/>
						<path
							d="M1797.2,883.8c1.6-0.3,3.5,0.2,5.7,1.4c2.3,1.3,3.8,2.7,4.5,4.2c0.7,1.5,0.6,3.2-0.4,4.9c-0.7,1.3-1.8,2.4-3.2,3
									c2.1,1,3.7,1.7,5,2c-0.2,1.3-0.6,2.7-1.4,4.2c-2.5-1.7-6.8-4.1-12.9-7.4c-0.9-0.5-1.6-0.7-2.1-0.8c-0.6-0.1-1.3,0-2,0.1
									c-0.1-0.6-0.2-1.2-0.1-1.8c0-0.6,0.2-1.2,0.4-1.8c0.7-0.4,1.5-0.4,2.4-0.2c-0.3-0.9-0.3-1.7-0.3-2.6c0.1-0.8,0.3-1.6,0.6-2.3
									C1794.3,885,1795.6,884,1797.2,883.8z M1794.5,892.1c0.6,0.3,1.7,1,3.2,1.8c1.6,0.9,2.9,1.7,3.8,2.1c0.8-0.5,1.5-1.2,2.1-2.2
									c0.6-1.1,0.6-2.1,0.2-3c-0.4-1-1.4-1.8-2.8-2.6c-1.3-0.7-2.5-1-3.6-1c-1.1,0-2,0.5-2.6,1.3
									C1794.3,889.5,1794.3,890.7,1794.5,892.1z"
						/>
						<path
							d="M1788.7,903.4l1.8,1.4l2,1.5c1.9,1.4,3.5,2.2,4.6,2.4c-0.3,1.3-1,2.7-1.9,4c-1.7-1.3-4.3-3.3-7.9-5.8
									c-0.8-0.5-1.4-0.8-2-0.7c-0.5,0.1-1,0.4-1.5,1.1c-0.6,0.9-1,1.9-1.1,2.8c1.2,0.9,2.3,1.7,3.2,2.4l1.5,1.1c1.9,1.4,3.5,2.2,4.6,2.4
									c-0.3,1.3-1,2.7-1.9,4c-1.3-1-2.7-2.1-4.3-3.3c-1.6-1.2-2.9-2.2-3.8-2.9c-0.8-0.5-1.4-0.9-2-1c-0.5-0.1-1.2-0.2-2-0.1
									c0-0.6,0-1.2,0.1-1.8c0.1-0.6,0.3-1.2,0.6-1.7c0.8-0.3,1.7-0.2,2.6,0.1c-0.2-0.9-0.2-1.8,0-2.7c0.2-0.9,0.5-1.7,1-2.4
									C1783.8,901.6,1786,901.5,1788.7,903.4z"
						/>
						<path
							d="M1772.6,919.1c0.5,0,1.3,0.3,2.2,0.9c0.7,0.5,1.8,1.2,3.1,2.2c1.3,0.9,2.2,1.6,2.8,2c0.8,0.5,1.6,1,2.4,1.4
									c0.8,0.4,1.4,0.6,1.8,0.6c-0.2,0.8-0.4,1.4-0.7,2.1s-0.7,1.3-1.1,1.9c-0.5-0.5-1.4-1.2-2.5-2.1c-1.2-0.9-2.5-1.9-4-2.9
									c-1.1-0.8-2-1.3-2.6-1.6c-0.6-0.3-1.2-0.5-1.6-0.6c-0.4-0.1-1-0.1-1.5,0c-0.2-0.6-0.2-1.1-0.1-1.7c0.1-0.5,0.3-0.9,0.5-1.3
									C1771.6,919.4,1772,919.1,1772.6,919.1z M1789.3,929c0.3,1.1,0.6,2.2,0.7,3.4c-1.2,0.1-2.3,0.3-3.4,0.6c-0.3-1.1-0.6-2.2-0.7-3.4
									C1787,929.5,1788.1,929.3,1789.3,929z"
						/>
						<path
							d="M1765.1,929.5c1.9,1.5,4,3,6.3,4.5c1.1,0.7,2,1.3,2.6,1.6c0.6,0.3,1.2,0.5,1.6,0.6c0.4,0.1,1,0.1,1.5,0
									c0.1,0.6,0.2,1.2,0.1,1.7c-0.1,0.5-0.3,1-0.5,1.3c-0.7,1.1-1.7,1.3-3.1,0.6c0.3,0.9,0.3,1.8,0.2,2.8c-0.1,0.9-0.4,1.8-0.9,2.5
									c-1.5,2.3-3.7,2.5-6.5,0.6l-1.9-1.3l-2-1.4c-2.1-1.4-3.8-2.2-4.9-2.4c0.2-0.6,0.6-1.3,1.1-2c0.4-0.5,0.8-1.1,1.4-1.7
									c2.2,1.7,4.7,3.4,7.4,5.2c0.8,0.5,1.5,0.8,2,0.7c0.6-0.1,1.1-0.4,1.5-1.1c0.7-1,1-1.9,1-2.8l-0.9-0.6c-1.4-1-2.6-1.8-3.7-2.5
									c-2.1-1.4-3.8-2.2-4.9-2.4c0.3-0.6,0.6-1.3,1-1.9C1764,930.8,1764.5,930.1,1765.1,929.5z"
						/>
						<path
							d="M1768.2,974.4l-0.6,0.2c-1-2.6-2.4-4.8-4.2-6.7c-1.8-1.9-4.3-3.7-7.3-5.4c-3-1.7-5.8-2.9-8.4-3.5
									c-2.6-0.6-5.2-0.7-7.9-0.2l-0.2-0.6c3-0.9,5.9-1.2,8.9-0.9c3,0.3,5.9,1.2,8.8,2.9s5.2,3.7,7,6.1
									C1766.3,968.7,1767.5,971.4,1768.2,974.4z"
						/>
						<path
							d="M1742.3,966.5c0.9,0.5,2.1,1.2,3.6,2s3,1.5,4.4,2.2c1.5,0.7,2.8,1.2,3.9,1.6c1-2.2,1.6-3.7,1.7-4.4c0.5,0.1,1,0.3,1.4,0.5
									c0.3,0.2,0.8,0.4,1.3,0.8c-0.4,0.7-0.9,1.7-1.6,2.9c-0.6,1.2-1.2,2.4-1.7,3.5c-1.3,2.7-1.9,4.4-2,5.1c-0.5-0.1-1-0.3-1.4-0.5
									c-0.4-0.2-0.8-0.4-1.3-0.8c0.6-1,1.3-2.4,2.2-4.1c-2.2-1.2-4.1-2.1-5.7-2.9c-3.3-1.6-5.4-2.4-6.4-2.5c0.1-0.5,0.3-1.1,0.6-1.7
									C1741.6,967.7,1741.9,967.1,1742.3,966.5z"
						/>
						<path
							d="M1737.6,977c2.3,1,4.7,2,7.1,2.9c3.3,1.2,5.8,2.1,7.7,2.6c0,1.2-0.3,2.4-0.8,3.7c-1.2-0.6-2.9-1.5-5.3-2.4
									c0.4,0.7,0.6,1.5,0.6,2.3c0.1,0.8,0,1.5-0.3,2.2c-0.8,2.2-2.5,2.8-5.1,1.8l-1.7-0.7l-1.9-0.8c-2-0.7-3.4-1.1-4.4-1
									c0.1-0.6,0.3-1.2,0.5-1.8c0.2-0.5,0.5-1.1,0.8-1.7c2.4,1,4.7,2,6.9,2.8c0.7,0.3,1.3,0.3,1.8,0.2c0.4-0.2,0.8-0.6,1-1.3
									c0.3-0.9,0.4-1.8,0.2-2.6l-2.1-0.8c-3.4-1.3-5.5-1.9-6.5-1.8c0.1-0.6,0.3-1.2,0.5-1.8C1737,978.2,1737.3,977.6,1737.6,977z"
						/>
						<path
							d="M1732.9,993.8c1.2-0.7,2.8-0.8,4.7-0.4c1.3,0.3,2.4,0.8,3.3,1.6s1.5,1.7,1.9,2.8c0.3,1.1,0.4,2.2,0.1,3.5
									c-0.4,1.6-1.2,2.7-2.4,3.3c-1.2,0.6-2.9,0.7-4.9,0.2c0-1.1,0-2.4,0.2-3.9s0.4-3,0.7-4.4c-1.1-0.1-1.9,0.1-2.5,0.5
									c-0.6,0.4-1,1.1-1.2,2.1c-0.2,0.8-0.3,1.6-0.2,2.4c0,0.8,0.2,1.4,0.4,2.1l-1,0.1c-0.6-0.8-1.1-1.8-1.3-2.9c-0.2-1.1-0.2-2.3,0-3.3
									C1730.9,995.6,1731.7,994.5,1732.9,993.8z M1737.4,999.4c0,0.9,0.1,1.7,0.2,2.3c1,0.2,1.8,0.1,2.6-0.3c0.8-0.4,1.2-1,1.4-1.8
									c-0.2-0.6-0.7-1.2-1.4-1.8c-0.7-0.5-1.6-0.9-2.6-1.1C1737.5,997.6,1737.4,998.5,1737.4,999.4z"
						/>
						<path
							d="M1730.5,1020c1.1-1.4,2.9-2.2,5.3-2.5c1.7-0.2,3.2,0,4.6,0.5c1.4,0.5,2.6,1.4,3.4,2.5c0.9,1.1,1.4,2.5,1.6,4.1
									c0.2,1.9,0.2,3.5-0.1,4.8c-0.5,0.1-1.1,0-1.8-0.1c-0.7-0.1-1.2-0.3-1.6-0.5c0.5-0.9,0.9-1.9,1.2-3s0.4-1.8,0.3-2.4
									c-0.2-0.6-0.7-1.2-1.4-1.6c-0.7-0.5-1.5-0.8-2.4-1c-0.9-0.2-1.8-0.3-2.8-0.1c-2.1,0.3-3.6,0.8-4.4,1.7c-0.9,0.9-1.2,2.2-1,3.9
									c0.1,0.7,0.2,1.3,0.5,2c3-0.4,4.8-0.8,5.5-1.2c0.2,0.5,0.3,1.1,0.4,1.7c0.1,0.5,0.1,1.2,0.1,2c-1.6,0-3.6,0.2-6.1,0.5
									c-0.7-0.6-1.3-1.4-1.8-2.5c-0.5-1.1-0.8-2.2-1-3.3C1728.9,1023.1,1729.3,1021.3,1730.5,1020z"
						/>
						<path
							d="M1732.2,1038.5c1.9-0.5,4-1.1,6.1-1.8c1-0.3,1.8-0.6,2.4-0.9c0.5-0.3,0.9-0.5,1.2-0.8c0.3-0.3,0.5-0.6,0.8-1
									c0.5,0.2,0.8,0.5,1.1,0.8c0.3,0.3,0.5,0.7,0.6,1c0.4,1.2-0.1,2.1-1.5,2.6c1.5,0.6,2.7,1.4,3.5,2.7c-0.3,0.3-0.8,0.6-1.4,0.9
									c-0.6,0.3-1.2,0.6-1.7,0.7c-0.1-0.4-0.1-0.7-0.2-1c-0.2-0.6-0.5-1.1-0.8-1.6c-0.3-0.5-0.7-0.9-1-1.1c-1.4,0.4-2.7,0.8-3.8,1.2
									c-2,0.7-3.3,1.3-4.1,2c-0.3-0.5-0.6-1.1-0.8-1.7C1732.5,1039.9,1732.3,1039.2,1732.2,1038.5z"
						/>
						<path
							d="M1738,1050.1c0.5-1.3,1.5-2.4,3.2-3.4c1.1-0.7,2.3-1,3.5-1c1.2,0,2.2,0.2,3.2,0.8c1,0.6,1.8,1.4,2.4,2.5
									c0.8,1.4,1,2.8,0.5,4.1c-0.5,1.3-1.7,2.4-3.5,3.5c-0.8-0.8-1.6-1.8-2.5-3s-1.7-2.4-2.5-3.7c-0.8,0.7-1.3,1.3-1.5,2s0,1.5,0.5,2.4
									c0.4,0.7,0.9,1.4,1.4,1.9c0.5,0.5,1.1,1,1.7,1.2l-0.7,0.7c-1-0.1-2-0.6-2.9-1.2c-1-0.7-1.7-1.5-2.3-2.5
									C1737.7,1052.8,1737.5,1051.4,1738,1050.1z M1745.1,1051.2c0.6,0.7,1.2,1.2,1.7,1.5c0.8-0.5,1.4-1.2,1.7-2
									c0.3-0.8,0.2-1.6-0.2-2.3c-0.6-0.3-1.4-0.4-2.2-0.3c-0.9,0.1-1.8,0.4-2.7,0.9C1743.9,1049.8,1744.5,1050.6,1745.1,1051.2z"
						/>
						<path
							d="M1746.4,1064.6c0-0.7,0.4-1.4,1-1.9c0.9-0.9,2-1.1,3.3-0.7c1.3,0.4,2.8,1.4,4.6,3c0.8-0.8,1.2-1.5,1.3-2.1
									s-0.2-1.2-0.9-1.9c-0.4-0.5-0.9-0.8-1.5-1.2s-1.2-0.6-1.8-0.7l0.5-0.9c1-0.2,2.1-0.1,3.1,0.2c1.1,0.3,1.9,0.8,2.6,1.5
									c0.9,1,1.4,2,1.4,3c0,1-0.5,2-1.4,2.9c-0.5,0.5-1.1,1-1.9,1.6c-0.8,0.6-1.3,1.1-1.6,1.4c-0.5,0.5-0.9,1-1.1,1.4
									c-0.2,0.4-0.3,0.9-0.4,1.6c-1-0.1-1.9-0.5-2.7-1.2c-0.1-0.7,0-1.3,0.4-1.9c-0.9-0.2-1.7-0.5-2.4-0.8c-0.7-0.4-1.3-0.8-1.8-1.3
									C1746.6,1066,1746.4,1065.3,1746.4,1064.6z M1750.6,1067.2c0.5,0.2,1,0.4,1.5,0.5l0.2-0.1c0.4-0.4,0.9-0.8,1.3-1.1l0.9-0.8
									c-0.9-0.8-1.8-1.4-2.6-1.6c-0.8-0.2-1.5,0-2,0.5c-0.2,0.2-0.4,0.5-0.5,0.8s-0.1,0.7-0.1,1
									C1749.6,1066.8,1750.1,1067,1750.6,1067.2z"
						/>
						<path
							d="M1758.9,1076.4c0-0.5,0.2-1.1,0.8-1.9c0.4-0.5,0.9-1.3,1.8-2.3c0.9-1.2,1.6-2.2,2.2-2.9c0.2-0.3,0.5-0.7,0.7-1
									c-0.7-0.5-1.1-0.9-1.2-1.1c-0.1-0.3-0.1-0.5,0.1-0.8c1.5,0.2,3.2,0.2,5-0.1l0.8,0.7c-0.3,0.6-0.7,1.2-1.1,1.8
									c1.1,0.8,1.9,1.3,2.5,1.6c-0.3,0.4-0.5,0.7-0.8,0.9c-0.3,0.2-0.6,0.4-0.9,0.6c-0.3-0.5-0.9-1.1-1.8-1.8c-0.5,0.6-1.1,1.3-1.6,2.1
									c-1.3,1.8-2.3,3.4-2.8,4.6c0.7,0.5,1.3,0.8,2,1.1l-0.4,0.8c-0.6,0.2-1.3,0.3-2.1,0.2c-0.7-0.1-1.4-0.4-2-0.8
									C1759.3,1077.4,1759,1076.9,1758.9,1076.4z"
						/>
						<path
							d="M1772.6,1085.6c0.6-0.8,1.4-1.9,2.4-3.4c1-1.5,1.9-2.8,2.6-4c1.8-2.9,2.7-4.7,2.8-5.5c0.7,0.1,1.5,0.4,2.5,0.8
									c1,0.4,2,0.9,2.9,1.5c1.8,1.1,2.9,2.3,3.3,3.7c0.5,1.4,0.3,2.8-0.6,4.2c-0.5,0.9-1.2,1.5-2.1,1.8c-0.9,0.4-1.8,0.5-2.9,0.4
									c-0.3,3.4-0.2,5.7,0.2,6.8c-0.6-0.1-1.3-0.3-1.8-0.6c-0.6-0.3-1.2-0.6-1.9-1c0.2-1.2,0.4-3.3,0.6-6.2c-0.1,0-0.3-0.1-0.5-0.3
									c-0.6-0.4-1.2-0.8-1.7-1.2c-1.5,2.4-2.3,4.1-2.5,5c-0.6-0.2-1.1-0.5-1.6-0.8C1773.7,1086.5,1773.2,1086.1,1772.6,1085.6z
									M1784,1082.7c0.7-0.3,1.2-0.7,1.6-1.3c1.2-1.9,0.7-3.5-1.4-4.9c-0.5-0.3-0.9-0.5-1.4-0.6c-0.8,1.2-1.9,2.8-3.2,4.8
									c1,1.1,1.8,1.9,2.3,2.3C1782.6,1083,1783.3,1082.9,1784,1082.7z"
						/>
						<path
							d="M1786.3,1093.8c0-0.4,0.2-1.1,0.7-1.8c0.4-0.6,1-1.5,1.7-2.6c0.7-1.1,1.2-1.8,1.6-2.3c0.4-0.7,0.8-1.3,1.1-2
									c0.3-0.6,0.4-1.1,0.4-1.5c0.6,0.1,1.2,0.3,1.8,0.6s1.1,0.5,1.7,0.9c-0.4,0.4-0.9,1.1-1.6,2.1c-0.7,1-1.5,2.1-2.3,3.4
									c-0.6,0.9-1,1.7-1.3,2.2c-0.2,0.5-0.4,1-0.5,1.3c-0.1,0.4,0,0.8,0.1,1.3c-0.5,0.1-1,0.2-1.4,0.1c-0.4-0.1-0.8-0.2-1.1-0.4
									C1786.6,1094.6,1786.3,1094.2,1786.3,1093.8z M1794,1079.7c0.9-0.3,1.9-0.5,2.8-0.6c0.1,1,0.4,1.9,0.7,2.8
									c-0.9,0.3-1.9,0.5-2.8,0.6C1794.5,1081.6,1794.3,1080.7,1794,1079.7z"
						/>
						<path
							d="M1794.7,1098.9c-0.1-0.6-0.1-1.4,0.1-2.1c0.2-0.8,0.4-1.8,0.8-3.1c0.4-1.5,0.7-2.8,0.9-3.9s0.2-2.1,0.1-3
									c1.1,0.2,2.3,0.7,3.6,1.6c-0.5,1.1-1,2.8-1.6,5.3c-0.6,2.4-1,4.2-1,5.3c2.4-2,4.1-3.5,5.1-4.3c1-0.9,1.6-1.5,1.9-2
									c0.2-0.3,0.3-0.5,0.3-0.8c0.8,0.3,1.7,0.7,2.6,1.3c-2.5,1.7-5.6,4.3-9.4,7.7l-2.5-0.3C1795.1,1100,1794.8,1099.5,1794.7,1098.9z"
						/>
						<path
							d="M1803.6,1103.7c-0.2-1.4,0.3-2.9,1.3-4.5c0.7-1.1,1.6-1.9,2.6-2.5s2.1-0.8,3.2-0.8c1.1,0.1,2.2,0.4,3.3,1.1
									c1.4,0.9,2.1,2,2.3,3.4s-0.4,2.9-1.5,4.6c-1.1-0.4-2.3-0.9-3.6-1.6c-1.4-0.7-2.7-1.4-3.9-2.2c-0.4,1-0.6,1.8-0.4,2.5
									c0.2,0.7,0.7,1.3,1.5,1.9c0.7,0.5,1.4,0.8,2.1,1.1c0.7,0.2,1.4,0.4,2.1,0.3l-0.3,1c-1,0.3-2,0.4-3.2,0.2c-1.2-0.2-2.2-0.6-3.1-1.2
									C1804.6,1106.2,1803.8,1105.1,1803.6,1103.7z M1810.5,1101.5c0.9,0.3,1.6,0.5,2.2,0.6c0.5-0.8,0.7-1.7,0.6-2.5
									c-0.1-0.8-0.5-1.5-1.2-2c-0.7,0-1.4,0.2-2.2,0.7c-0.8,0.5-1.4,1.2-2,2.1C1808.8,1100.8,1809.6,1101.2,1810.5,1101.5z"
						/>
						<path
							d="M1814.7,1112.5c1.2-1.6,2.5-3.3,3.7-5.2c0.6-0.9,1-1.6,1.3-2.2c0.3-0.5,0.4-1,0.5-1.3c0.1-0.4,0.1-0.8,0-1.3
									c0.5-0.1,1-0.1,1.4-0.1c0.4,0.1,0.8,0.2,1.1,0.4c1,0.7,1.2,1.7,0.5,3c1.5-0.5,3-0.5,4.4,0c-0.1,0.4-0.3,0.9-0.5,1.5
									s-0.6,1.2-0.9,1.6c-0.3-0.3-0.5-0.5-0.8-0.7c-0.5-0.3-1.1-0.6-1.6-0.8c-0.5-0.2-1-0.3-1.5-0.3c-0.8,1.2-1.6,2.3-2.3,3.3
									c-1.2,1.7-1.8,3.1-2,4.1c-0.6-0.2-1.1-0.5-1.7-0.9C1815.7,1113.4,1815.2,1113,1814.7,1112.5z"
						/>
						<path
							d="M1837,1104.1c-0.5,3-1.7,5.8-3.6,8.6c-1.9,2.7-4.1,4.9-6.6,6.5s-5.4,2.6-8.4,3.1l-0.1-0.6c2.6-0.8,5-2,7-3.7
									c2-1.7,4-4,6-6.8s3.3-5.5,4.1-8.1c0.8-2.5,1.1-5.1,0.8-7.9l0.6-0.1C1837.5,1098.1,1837.5,1101.1,1837,1104.1z"
						/>
					</g>
					<g className="regions level_one location" id="dagorlad">
						<rect x="4701.4" y="1528.246" width="305.276" height="33.62" transform="matrix(0.999984, -0.005575, -0.006472, 1.000052, -2602.805176, -124.368195)"></rect>
						<path
							d="M2095.5,1403.1c-1.2-0.1-2.2-0.2-3.1-0.3c0.1-1.1,0.1-2.7,0.1-4.7c0-2,0-3.8-0.1-5.6c-0.1-3.9-0.4-6.3-0.8-7.2
									c0.8-0.3,1.9-0.6,3.3-0.8c1.4-0.2,2.8-0.4,4.2-0.4c3.3-0.1,5.9,0.6,7.8,2.2c1.9,1.6,2.8,3.8,2.9,6.9c0.1,1.9-0.4,3.6-1.2,5
									c-0.9,1.5-2.2,2.7-3.8,3.5c-1.7,0.9-3.6,1.3-5.8,1.4C2097.9,1403.2,2096.7,1403.1,2095.5,1403.1z M2103.3,1399.7
									c0.8-0.7,1.4-1.6,1.9-2.6c0.5-1,0.7-2.1,0.6-3.1c-0.2-5-2.6-7.4-7.4-7.3c-0.8,0-1.5,0.2-2.2,0.4c-0.1,2.6-0.1,5.3,0,7.9
									c0.1,2.1,0.2,4,0.4,5.5c1.8,0.4,3.2,0.6,4.1,0.6C2101.6,1400.9,2102.5,1400.4,2103.3,1399.7z"
						/>
						<path
							d="M2114.6,1401.9c-0.6-0.6-0.9-1.4-1-2.4c0-1.5,0.7-2.6,2.1-3.5c1.4-0.8,3.6-1.4,6.6-1.7c-0.1-1.3-0.3-2.2-0.8-2.7
									c-0.5-0.5-1.3-0.8-2.5-0.8c-0.8,0-1.6,0.2-2.4,0.4s-1.5,0.6-2.2,1l-0.3-1.2c0.7-1.1,1.6-1.9,2.8-2.6c1.2-0.7,2.4-1,3.6-1.1
									c1.7,0,3,0.3,3.9,1.1c0.9,0.8,1.4,1.9,1.4,3.5c0,0.8,0,1.8-0.1,2.9c-0.1,1.1-0.1,2-0.1,2.5c0,0.9,0.1,1.6,0.3,2.1
									c0.2,0.5,0.6,1,1.2,1.6c-1,0.8-2.1,1.3-3.4,1.4c-0.7-0.4-1.2-1-1.5-1.8c-0.9,0.6-1.8,1.1-2.7,1.4c-0.9,0.4-1.8,0.5-2.6,0.6
									C2116,1402.8,2115.2,1402.5,2114.6,1401.9z M2120.4,1400.3c0.6-0.3,1.2-0.6,1.7-1l0-0.2c0-0.7,0-1.4,0.1-2l0.1-1.4
									c-1.5,0.1-2.7,0.5-3.6,1c-0.8,0.5-1.2,1.3-1.2,2.1c0,0.4,0.1,0.8,0.3,1.1c0.2,0.4,0.5,0.7,0.9,0.9
									C2119.2,1400.7,2119.8,1400.6,2120.4,1400.3z"
						/>
						<path
							d="M2130.6,1406.9c0-0.8,0.1-1.6,0.4-2.4s0.8-1.5,1.3-2.2c-0.4-0.2-0.8-0.4-1-0.8c-0.2-0.3-0.4-0.7-0.4-1.2
									c0-0.7,0.2-1.3,0.7-1.9c0.5-0.6,1.1-1,2-1.4c-0.8-0.4-1.5-0.9-1.9-1.7c-0.5-0.7-0.7-1.6-0.7-2.5c0-1.1,0.3-2.1,0.8-2.9
									s1.3-1.5,2.4-2c1-0.5,2.2-0.7,3.5-0.8c1.3,0,2.4,0.2,3.4,0.7c1.1-0.3,2.3-1,3.5-2.1c0.3,0.5,0.5,1,0.7,1.6s0.3,1.1,0.3,1.4
									c-0.7,0.4-1.6,0.7-2.7,0.8c0.4,0.7,0.7,1.6,0.7,2.5c0,1.1-0.3,2.1-0.8,2.9c-0.6,0.9-1.4,1.5-2.4,2c-1,0.5-2.2,0.7-3.5,0.8
									c-1.1,0-1.9,0.2-2.5,0.5c-0.6,0.3-0.9,0.7-0.9,1.2l7,0.2c1.4,0.1,2.5,0.4,3.3,1s1.1,1.4,1.2,2.5c0,0.9-0.3,1.8-1,2.6
									c-0.7,0.8-1.6,1.4-2.8,1.8c-1.2,0.5-2.6,0.7-4.1,0.7C2134.3,1408.3,2132.2,1407.9,2130.6,1406.9z M2140.9,1405.3
									c0.8-0.4,1.1-0.8,1.1-1.3c0-0.4-0.2-0.8-0.5-0.9c-0.4-0.2-1-0.3-1.8-0.3l-5.5-0.2c-0.3,0.5-0.5,1.1-0.6,1.7c1,1.1,2.4,1.7,4.3,1.6
									C2139.1,1405.8,2140.1,1405.6,2140.9,1405.3z M2139.6,1395.2c0.5-0.5,0.8-1.2,0.8-2.1c0-0.8-0.3-1.4-0.8-2s-1.2-0.9-2-1.1
									c-0.9,0-1.7,0.3-2.3,0.9c-0.6,0.6-0.9,1.3-0.9,2.2c0,0.9,0.3,1.6,0.8,2.1s1.2,0.8,2.1,0.7
									C2138.4,1396,2139.1,1395.7,2139.6,1395.2z"
						/>
						<path
							d="M2151.4,1401.2c-1.1-0.6-1.9-1.4-2.5-2.5c-0.6-1.1-0.9-2.3-0.9-3.7c0-1.6,0.3-2.9,1-4.2c0.7-1.2,1.7-2.2,2.9-2.8
									c1.3-0.7,2.7-1,4.3-1c2.2,0,4,0.6,5.3,1.9c1.3,1.3,2,3,2,5.2c0,1.6-0.3,2.9-1.1,4.2c-0.7,1.2-1.7,2.2-3,2.9s-2.8,1-4.4,1
									C2153.7,1402.1,2152.5,1401.8,2151.4,1401.2z M2158.9,1399.1c0.7-0.9,1.1-2.1,1.1-3.6c0-1.3-0.4-2.5-1-3.5c-0.7-1-1.6-1.6-2.8-2
									c-1.4,0-2.5,0.5-3.3,1.3c-0.8,0.9-1.2,2.1-1.2,3.6c0,1.7,0.4,3,1.2,4c0.8,1,1.7,1.5,2.9,1.5
									C2157.1,1400.4,2158.2,1399.9,2158.9,1399.1z"
						/>
						<path
							d="M2169.1,1401.6c0.2-2.4,0.3-4.9,0.3-7.5c0-1.3,0-2.3-0.1-3c-0.1-0.7-0.2-1.2-0.4-1.6s-0.5-0.8-0.9-1.2
									c0.4-0.4,0.9-0.8,1.4-1s1-0.3,1.4-0.3c1.5,0,2.4,0.9,2.5,2.6c1.2-1.5,2.7-2.5,4.5-2.9c0.2,0.4,0.4,1.1,0.5,1.9
									c0.1,0.8,0.2,1.5,0.2,2.2c-0.4-0.1-0.9-0.1-1.3-0.1c-0.8,0-1.5,0.1-2.2,0.3s-1.3,0.4-1.7,0.7c0,1.7-0.1,3.3-0.1,4.7
									c0,2.5,0.2,4.2,0.7,5.3c-0.7,0.1-1.5,0.2-2.3,0.2C2170.8,1401.8,2170,1401.8,2169.1,1401.6z"
						/>
						<path
							d="M2182.5,1401.3c-0.3-0.4-0.5-1.2-0.5-2.3c0-0.7,0-1.9,0.1-3.5c0.1-1.8,0.1-3.3,0.1-4.4c0-3.8-0.2-6.5-0.7-8.1
									c0.8-0.3,1.5-0.4,2.2-0.5s1.5-0.2,2.4-0.1c-0.3,2.5-0.5,6.7-0.5,12.5c0,1.3,0,2.3,0.1,3c0.1,0.7,0.2,1.2,0.4,1.6
									c0.2,0.4,0.5,0.8,0.9,1.2c-0.5,0.4-0.9,0.8-1.4,1c-0.5,0.2-1,0.3-1.4,0.3C2183.4,1402,2182.8,1401.7,2182.5,1401.3z"
						/>
						<path
							d="M2191.5,1401.2c-0.6-0.6-0.9-1.4-0.9-2.4c0-1.5,0.8-2.6,2.2-3.4c1.5-0.8,3.7-1.3,6.6-1.4c0-1.3-0.2-2.2-0.7-2.8
									c-0.5-0.5-1.3-0.8-2.5-0.8c-0.8,0-1.6,0.1-2.4,0.3c-0.8,0.2-1.5,0.5-2.2,0.9l-0.3-1.2c0.7-1,1.7-1.9,2.9-2.5
									c1.2-0.6,2.4-0.9,3.6-0.9c1.7,0,3,0.4,3.9,1.3c0.9,0.8,1.3,2,1.3,3.5c0,0.8-0.1,1.8-0.2,2.9c-0.1,1.1-0.2,2-0.2,2.5
									c0,0.9,0.1,1.6,0.3,2.1s0.6,1,1.1,1.6c-1,0.7-2.1,1.2-3.4,1.3c-0.7-0.5-1.2-1.1-1.4-1.9c-0.9,0.6-1.8,1-2.8,1.3
									c-1,0.3-1.8,0.5-2.6,0.5C2192.9,1402.1,2192.1,1401.8,2191.5,1401.2z M2197.4,1399.8c0.6-0.3,1.2-0.5,1.7-0.9l0-0.2
									c0-0.7,0.1-1.4,0.1-2l0.1-1.4c-1.5,0.1-2.7,0.4-3.6,0.9c-0.9,0.5-1.3,1.2-1.3,2.1c0,0.4,0.1,0.8,0.3,1.1c0.2,0.4,0.5,0.7,0.8,0.9
									C2196.2,1400.2,2196.7,1400,2197.4,1399.8z"
						/>
						<path
							d="M2209.4,1400.5c-1-1.3-1.5-3.1-1.4-5.6c0.1-2.6,0.6-4.5,1.7-5.8s2.7-1.9,4.8-1.9c1.6,0,3.1,0.5,4.4,1.4
									c-0.1-2.2-0.2-4-0.6-5.2c1.3-0.4,2.9-0.6,4.6-0.6c-0.4,2.9-0.6,7.7-0.8,14.4c0,0.9,0.1,1.7,0.3,2.2c0.2,0.5,0.6,1.1,1.1,1.7
									c-0.5,0.4-1,0.7-1.6,0.9c-0.6,0.2-1.2,0.4-1.8,0.4c-0.7-0.5-1.1-1.1-1.4-1.9c-0.7,0.6-1.4,1.1-2.2,1.4c-0.8,0.3-1.6,0.5-2.5,0.5
									C2211.9,1402.4,2210.4,1401.8,2209.4,1400.5z M2218.5,1399.2c0-0.7,0.1-1.9,0.2-3.6c0.1-1.8,0.1-3.2,0.2-4.2
									c-0.8-0.5-1.8-0.8-3-0.8c-1.3,0-2.2,0.4-2.9,1.2c-0.7,0.8-1,2-1.1,3.6c0,1.5,0.2,2.7,0.8,3.6s1.4,1.5,2.5,1.7
									C2216.2,1400.4,2217.3,1400,2218.5,1399.2z"
						/>
						<path
							d="M2243.6,1377.8l0.4,0.4c-1.8,1.9-3.1,4.1-4,6.4s-1.3,5.2-1.4,8.4c-0.1,3.2,0.2,6,0.9,8.4c0.7,2.4,1.9,4.6,3.6,6.6
									l-0.5,0.4c-2.2-2-4-4.4-5.1-7c-1.2-2.6-1.7-5.4-1.7-8.5c0.1-3.1,0.8-5.9,2.1-8.4C2239.4,1381.9,2241.3,1379.7,2243.6,1377.8z"
						/>
						<path
							d="M2246.9,1403c-1.1-0.2-2-0.4-2.7-0.7c0.1-0.9,0.2-2.2,0.3-4s0.2-3.4,0.2-5.1c0.1-3.3,0-5.4-0.3-6.1
									c0.8-0.2,1.7-0.4,2.8-0.5c1.1-0.1,2.2-0.2,3.3-0.1c1.9,0,3.4,0.5,4.5,1.2c1,0.8,1.5,1.9,1.5,3.3c0,0.7-0.3,1.4-0.7,2
									c-0.4,0.6-1.1,1.1-1.9,1.5c1,0.4,1.8,1,2.3,1.7c0.6,0.7,0.8,1.6,0.8,2.5c0,1.6-0.6,2.7-1.8,3.5c-1.2,0.8-2.8,1.2-5,1.1
									C2249.1,1403.3,2248,1403.2,2246.9,1403z M2253.8,1398.6c0-0.7-0.2-1.4-0.6-1.9c-0.4-0.6-1-1-1.8-1.3c-0.4,0-1,0-1.7,0.2
									c-0.7,0.1-1.4,0.3-2.2,0.5c-0.1,2.3,0,4.1,0,5.3c0.7,0.3,1.4,0.4,2.3,0.5C2252.4,1401.8,2253.7,1400.8,2253.8,1398.6z
									M2250.3,1393.8c0.5,0,0.8,0,1.1,0.1c1.2-0.8,1.8-1.6,1.8-2.6c0-1.7-1-2.5-3.1-2.6c-0.8,0-1.5,0.1-2.1,0.3
									c-0.1,1.5-0.2,3.2-0.3,5.1C2248.4,1393.9,2249.3,1393.8,2250.3,1393.8z"
						/>
						<path
							d="M2260.5,1402.7c-0.5-0.6-0.7-1.3-0.7-2.1c0-1.3,0.6-2.3,1.8-3c1.2-0.7,3-1.1,5.4-1.2c0-1.1-0.2-1.9-0.6-2.4
									c-0.4-0.5-1-0.7-2-0.8c-0.6,0-1.3,0.1-1.9,0.3c-0.7,0.2-1.3,0.5-1.8,0.8l-0.2-1c0.6-0.9,1.4-1.6,2.4-2.2c1-0.5,1.9-0.8,2.9-0.8
									c1.4,0,2.4,0.4,3.1,1.1c0.7,0.7,1.1,1.8,1,3.1c0,0.7-0.1,1.6-0.2,2.5c-0.1,1-0.2,1.7-0.2,2.2c0,0.8,0.1,1.4,0.2,1.8
									c0.2,0.4,0.5,0.9,0.9,1.4c-0.8,0.6-1.7,1-2.8,1.1c-0.6-0.4-0.9-1-1.1-1.7c-0.7,0.5-1.5,0.9-2.3,1.2c-0.8,0.3-1.5,0.4-2.1,0.4
									C2261.6,1403.6,2261,1403.3,2260.5,1402.7z M2265.3,1401.6c0.5-0.2,1-0.5,1.4-0.8l0-0.2c0-0.6,0.1-1.2,0.1-1.8l0.1-1.2
									c-1.2,0.1-2.2,0.3-2.9,0.8s-1,1.1-1.1,1.8c0,0.3,0.1,0.7,0.2,1c0.2,0.3,0.4,0.6,0.7,0.8
									C2264.3,1401.9,2264.8,1401.8,2265.3,1401.6z"
						/>
						<path
							d="M2275.7,1403.2c-0.3-0.4-0.5-1.1-0.5-2.1c0-0.6,0-1.6,0.1-3c0.1-1.5,0.1-2.8,0.1-3.8c0-0.4,0-0.8,0-1.2
									c-0.8,0-1.4-0.1-1.6-0.2c-0.3-0.1-0.4-0.4-0.4-0.8c1.4-0.7,2.7-1.7,4-3.1l1,0.1c0.1,0.7,0.1,1.4,0.1,2.2c1.3,0,2.3-0.1,3-0.2
									c0,0.5-0.1,0.9-0.1,1.3c-0.1,0.4-0.2,0.7-0.4,1.1c-0.6-0.2-1.4-0.4-2.5-0.4c-0.1,0.9-0.1,1.8-0.1,2.7c0,2.4,0.1,4.2,0.3,5.6
									c0.8,0,1.6-0.1,2.3-0.2l0.2,0.9c-0.4,0.6-0.9,1-1.6,1.4c-0.7,0.3-1.4,0.5-2.1,0.5C2276.6,1403.8,2276.1,1403.6,2275.7,1403.2z"
						/>
						<path
							d="M2285.8,1403.4c-0.3-0.4-0.5-1.1-0.5-2.1c0-0.6,0-1.6,0.1-3c0.1-1.5,0.1-2.8,0.1-3.8c0-0.4,0-0.8,0-1.2
									c-0.8,0-1.4-0.1-1.6-0.2c-0.3-0.1-0.4-0.4-0.4-0.8c1.4-0.7,2.7-1.8,4-3.1l1,0.1c0.1,0.7,0.1,1.4,0.1,2.2c1.3,0,2.3-0.1,3-0.2
									c0,0.5-0.1,0.9-0.1,1.3c-0.1,0.4-0.2,0.7-0.4,1.1c-0.6-0.2-1.4-0.4-2.5-0.4c-0.1,0.9-0.1,1.8-0.1,2.7c0,2.4,0.1,4.2,0.3,5.6
									c0.8,0,1.6-0.1,2.3-0.3l0.2,0.9c-0.4,0.6-0.9,1-1.6,1.4c-0.7,0.3-1.4,0.5-2.1,0.5C2286.7,1404,2286.1,1403.8,2285.8,1403.4z"
						/>
						<path
							d="M2295.1,1403.5c-0.3-0.4-0.4-1-0.4-2c0-0.6,0-1.7,0.1-3.1c0.1-1.6,0.1-2.9,0.1-3.9c0-3.3-0.1-5.7-0.5-7.1
									c0.6-0.2,1.2-0.4,1.8-0.5c0.6-0.1,1.2-0.1,1.9-0.1c-0.3,2.2-0.4,5.9-0.4,11c0,1.1,0,2,0.1,2.6c0.1,0.6,0.2,1.1,0.3,1.4
									c0.1,0.4,0.4,0.7,0.7,1.1c-0.4,0.4-0.8,0.7-1.2,0.8c-0.4,0.2-0.8,0.3-1.1,0.3C2295.8,1404,2295.3,1403.9,2295.1,1403.5z"
						/>
						<path
							d="M2302.9,1402.5c-0.9-1.1-1.4-2.6-1.3-4.7c0-1.4,0.3-2.6,0.8-3.7c0.5-1.1,1.3-1.9,2.3-2.5c1-0.6,2.1-0.9,3.4-0.9
									c1.6,0,2.9,0.6,3.8,1.7c0.9,1.1,1.3,2.7,1.3,4.9c-1.1,0.3-2.4,0.5-3.9,0.7c-1.5,0.2-3,0.3-4.4,0.3c0.2,1.1,0.5,1.9,1,2.4
									c0.5,0.5,1.3,0.7,2.3,0.8c0.8,0,1.6-0.1,2.4-0.3c0.7-0.2,1.4-0.5,1.9-0.9l0.3,1c-0.6,0.8-1.5,1.5-2.5,2c-1.1,0.5-2.2,0.8-3.3,0.8
									C2305.1,1404.2,2303.8,1403.6,2302.9,1402.5z M2307.4,1396.7c0.9-0.2,1.6-0.5,2.2-0.7c0-1-0.3-1.9-0.9-2.5c-0.6-0.7-1.2-1-2.1-1
									c-0.6,0.4-1.1,1-1.4,1.8c-0.4,0.9-0.5,1.8-0.5,2.9C2305.6,1397.1,2306.5,1396.9,2307.4,1396.7z"
						/>
						<path
							d="M2324.8,1403.8c0.1-1,0.2-2.4,0.2-4.2s0.1-3.5,0-5c0-3.5-0.2-5.6-0.5-6.4c0.6-0.3,1.5-0.5,2.5-0.6
									c1.1-0.2,2.1-0.3,3.3-0.3c2.1,0,3.6,0.4,4.7,1.4c1.1,0.9,1.7,2.3,1.7,4c0,1.2-0.3,2.2-0.8,3.2c-0.6,0.9-1.3,1.7-2.3,2.2
									c-1,0.5-2.2,0.8-3.5,0.8c-0.7,0-1.4-0.1-2-0.2c0.1,2.7,0.2,4.4,0.5,5.1c-0.6,0.1-1.2,0.2-1.8,0.2
									C2326.2,1404,2325.6,1403.9,2324.8,1403.8z M2332.9,1395.4c0.4-0.7,0.7-1.6,0.6-2.5c0-2.3-1.3-3.5-3.8-3.5c-0.6,0-1.1,0.1-1.5,0.2
									c-0.1,2.2-0.2,4.4-0.2,6.7c1.4,0.4,2.4,0.7,3.2,0.7C2331.9,1396.7,2332.5,1396.1,2332.9,1395.4z"
						/>
						<path
							d="M2340.8,1403.4c-0.3-0.4-0.4-1-0.4-2c0-0.6,0-1.7,0-3.1c0-1.6,0-2.9,0-3.9c0-3.3-0.3-5.7-0.7-7.1c0.6-0.2,1.2-0.4,1.8-0.5
									c0.6-0.1,1.2-0.2,1.9-0.2c-0.2,2.2-0.3,5.9-0.2,11c0,1.1,0.1,2,0.1,2.6c0.1,0.6,0.2,1.1,0.4,1.4c0.2,0.3,0.4,0.7,0.8,1.1
									c-0.4,0.4-0.7,0.7-1.1,0.9c-0.4,0.2-0.8,0.3-1.1,0.3C2341.5,1403.9,2341.1,1403.7,2340.8,1403.4z"
						/>
						<path
							d="M2348,1403.1c-0.5-0.5-0.8-1.2-0.8-2.1c0-1.3,0.5-2.3,1.7-3c1.2-0.7,2.9-1.2,5.3-1.4c0-1.1-0.3-1.9-0.6-2.4
									c-0.4-0.5-1.1-0.7-2-0.7c-0.6,0-1.3,0.1-1.9,0.3s-1.2,0.5-1.7,0.9l-0.3-1c0.6-0.9,1.3-1.7,2.3-2.3c0.9-0.6,1.9-0.9,2.9-0.9
									c1.4,0,2.4,0.3,3.2,1c0.7,0.7,1.1,1.7,1.2,3.1c0,0.7,0,1.6-0.1,2.5c-0.1,1-0.1,1.7-0.1,2.2c0,0.8,0.1,1.4,0.3,1.8s0.5,0.9,1,1.4
									c-0.8,0.7-1.7,1.1-2.7,1.2c-0.6-0.4-1-0.9-1.2-1.6c-0.7,0.5-1.5,0.9-2.2,1.3s-1.5,0.5-2.1,0.5
									C2349.2,1403.9,2348.5,1403.6,2348,1403.1z M2352.7,1401.7c0.5-0.2,1-0.5,1.4-0.8l0-0.2c0-0.6,0-1.2,0.1-1.8l0.1-1.2
									c-1.2,0.1-2.2,0.4-2.9,0.9c-0.7,0.5-1,1.1-1,1.9c0,0.3,0.1,0.7,0.3,1c0.2,0.3,0.4,0.6,0.7,0.8
									C2351.8,1402.1,2352.2,1402,2352.7,1401.7z"
						/>
						<path
							d="M2361.2,1386.5c0.6-0.8,1.3-1.5,2-2.2c0.6,0.8,1.3,1.4,2.1,2.1c-0.6,0.8-1.3,1.5-2,2.2
									C2362.6,1387.8,2361.9,1387.1,2361.2,1386.5z M2362.5,1403c-0.3-0.4-0.4-1-0.5-2c0-0.8,0-1.9,0-3.3c0-1.3,0-2.3,0-2.9
									c0-0.8-0.1-1.6-0.2-2.3s-0.3-1.2-0.5-1.5c0.6-0.2,1.2-0.4,1.8-0.5c0.6-0.1,1.2-0.2,1.9-0.2c-0.1,0.6-0.2,1.5-0.2,2.8s0,2.7,0,4.2
									c0,1.1,0.1,2,0.2,2.6c0.1,0.6,0.2,1.1,0.4,1.4c0.2,0.3,0.4,0.7,0.8,1.1c-0.3,0.4-0.7,0.7-1.1,0.9c-0.4,0.2-0.8,0.3-1.1,0.3
									C2363.3,1403.5,2362.8,1403.3,2362.5,1403z"
						/>
						<path
							d="M2370.6,1403.1c0.1-2.1,0.1-4.3,0-6.6c0-1.1-0.1-2-0.2-2.6c-0.1-0.6-0.2-1.1-0.4-1.4c-0.2-0.3-0.4-0.7-0.8-1.1
									c0.4-0.4,0.7-0.7,1.2-0.9c0.4-0.2,0.8-0.3,1.1-0.3c1.1,0,1.8,0.6,2,1.8c0.5-0.6,1.1-1.1,1.8-1.5c0.7-0.4,1.4-0.6,2.2-0.6
									c2.4-0.1,3.6,1.3,3.6,4.2l0,2l0,2.1c0.1,2.2,0.3,3.7,0.7,4.6c-0.6,0.1-1.2,0.2-1.9,0.2c-0.6,0-1.2,0-1.9-0.1
									c0.1-2.4,0.1-4.9,0-7.7c0-0.8-0.2-1.4-0.5-1.8c-0.3-0.4-0.8-0.5-1.5-0.5c-1,0-1.8,0.3-2.5,0.7l0,0.9c0,1.4,0,2.7,0,3.8
									c0.1,2.2,0.3,3.7,0.7,4.6c-0.6,0.1-1.2,0.2-1.8,0.2C2372,1403.2,2371.3,1403.2,2370.6,1403.1z"
						/>
						<path
							d="M2389.8,1384.3c1.3,2.5,2.1,5.3,2.2,8.4c0.1,3.1-0.4,6-1.6,8.6c-1.2,2.6-2.9,4.9-5.1,7l-0.5-0.4c1.7-2,2.9-4.2,3.6-6.6
									c0.7-2.4,1-5.2,0.8-8.4c-0.1-3.2-0.6-6-1.4-8.4c-0.8-2.4-2.2-4.5-4-6.4l0.4-0.4C2386.6,1379.5,2388.5,1381.8,2389.8,1384.3z"
						/>
					</g>
					<g className="level_zero location" id="morannon">
						<rect x="1228.621" y="1161.623" width="79.778" height="25.555" transform="matrix(0.999984, -0.005575, -0.006472, 1.000052, 976.595764, 266.15625)"></rect>
						<rect x="958.761" y="1161.624" width="62.255" height="25.555" transform="matrix(0.934357, -0.356338, 0.345082, 0.93865, 846.657776, 694.802063)"></rect>
						<path
							d="M2155.8,1460.8c-0.8-1.6-2.1-3.9-3.8-6.7c-0.8-1.4-1.5-2.4-2-3.2c-0.5-0.7-0.9-1.3-1.3-1.7c0.4-0.3,0.8-0.7,1.3-0.9
									c0.4-0.3,1-0.5,1.6-0.8c1.2,0.8,2.7,1.8,4.5,3s3.3,2.1,4.5,2.9c-0.1-1.5-0.3-3.4-0.5-5.6s-0.4-3.9-0.5-5.1c0.3-0.3,0.7-0.6,1.3-1
									c0.4-0.3,1-0.5,1.6-0.8c0.4,0.8,0.9,1.8,1.6,3.1c0.7,1.3,1.4,2.5,2.1,3.6c1.6,2.7,2.7,4.3,3.4,4.8c-0.4,0.4-0.9,0.7-1.4,1
									c-0.4,0.3-0.9,0.5-1.5,0.7c-0.4-0.8-0.9-1.8-1.6-3.1c-0.7-1.3-1.4-2.5-2.1-3.6c-0.4-0.6-0.8-1.4-1.4-2.2c0.2,3.3,0.6,7.1,1,11.4
									c-0.2,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.3,0.2-0.6,0.2c-3.2-2.2-6.1-4.1-8.9-5.8c0.8,1.4,1.4,2.5,1.9,3.2c1.6,2.6,2.7,4.3,3.3,4.8
									c-0.3,0.3-0.6,0.5-0.9,0.7C2156.5,1460.5,2156.2,1460.7,2155.8,1460.8z"
						/>
						<path
							d="M2175.3,1449.6c-0.9-0.1-1.7-0.4-2.4-1c-0.7-0.6-1.2-1.3-1.6-2.3c-0.4-1-0.6-2.1-0.4-3.1s0.5-1.9,1.2-2.7
									c0.7-0.8,1.5-1.4,2.6-1.9c1.5-0.6,2.8-0.7,4.1-0.1c1.3,0.5,2.2,1.5,2.8,2.9c0.4,1,0.6,2.1,0.4,3.1c-0.2,1-0.6,1.9-1.3,2.7
									c-0.7,0.8-1.6,1.5-2.7,1.9C2177.1,1449.6,2176.2,1449.7,2175.3,1449.6z M2179.7,1446.1c0.3-0.8,0.2-1.7-0.3-2.7
									c-0.4-0.9-0.9-1.6-1.7-2.1c-0.7-0.5-1.5-0.7-2.4-0.6c-0.9,0.4-1.6,1-1.8,1.8c-0.3,0.8-0.2,1.7,0.2,2.7c0.5,1.1,1.1,1.9,1.9,2.4
									s1.6,0.5,2.4,0.2C2178.9,1447.5,2179.5,1446.9,2179.7,1446.1z"
						/>
						<path
							d="M2188.3,1445.1c-0.4-1.7-0.8-3.5-1.3-5.3c-0.3-0.9-0.5-1.6-0.7-2c-0.2-0.5-0.4-0.8-0.6-1.1c-0.2-0.2-0.5-0.5-0.9-0.7
									c0.2-0.4,0.5-0.7,0.8-1c0.3-0.2,0.6-0.4,0.9-0.5c1.1-0.3,1.8,0.1,2.3,1.3c0.6-1.3,1.4-2.3,2.5-2.9c0.2,0.3,0.5,0.7,0.7,1.2
									c0.2,0.5,0.4,1,0.6,1.5c-0.3,0-0.6,0.1-0.9,0.2c-0.5,0.2-1,0.4-1.5,0.6c-0.4,0.3-0.8,0.5-1.1,0.8c0.3,1.2,0.6,2.3,0.9,3.3
									c0.5,1.7,1,2.9,1.6,3.6c-0.5,0.2-1,0.4-1.6,0.6C2189.6,1444.9,2189,1445,2188.3,1445.1z"
						/>
						<path
							d="M2198,1442.1c-0.5-0.4-0.8-0.9-1-1.6c-0.2-1,0.2-2,1.1-2.7c0.9-0.8,2.4-1.4,4.5-1.9c-0.2-0.9-0.5-1.5-0.9-1.9
									c-0.4-0.3-1-0.4-1.9-0.3c-0.6,0.1-1.1,0.3-1.6,0.6s-1,0.6-1.4,1l-0.4-0.8c0.4-0.8,1-1.6,1.7-2.2c0.8-0.6,1.6-1,2.4-1.2
									c1.2-0.2,2.2-0.1,2.9,0.4c0.8,0.5,1.2,1.2,1.4,2.3c0.1,0.6,0.2,1.3,0.2,2.1c0.1,0.8,0.1,1.4,0.2,1.8c0.1,0.6,0.3,1.1,0.5,1.5
									c0.2,0.3,0.6,0.7,1,1c-0.6,0.7-1.4,1.1-2.3,1.4c-0.6-0.2-1-0.6-1.3-1.2c-0.6,0.5-1.2,1-1.8,1.3c-0.6,0.4-1.2,0.6-1.8,0.7
									C2199.1,1442.6,2198.5,1442.5,2198,1442.1z M2202,1440.3c0.4-0.3,0.8-0.6,1.1-0.9l0-0.2c-0.1-0.5-0.1-1-0.2-1.5l-0.1-1
									c-1.1,0.3-1.9,0.6-2.4,1.1c-0.5,0.5-0.7,1-0.6,1.7c0,0.3,0.2,0.5,0.4,0.8c0.2,0.2,0.4,0.4,0.7,0.5
									C2201.2,1440.8,2201.6,1440.6,2202,1440.3z"
						/>
						<path
							d="M2212,1440.2c0-1.7-0.2-3.5-0.3-5.5c-0.1-0.9-0.2-1.6-0.3-2.1c-0.1-0.5-0.2-0.9-0.4-1.1c-0.2-0.3-0.4-0.6-0.8-0.8
									c0.3-0.4,0.6-0.6,1-0.8c0.4-0.2,0.7-0.3,1-0.3c1-0.1,1.6,0.4,1.9,1.4c0.4-0.6,0.9-1,1.6-1.4c0.6-0.4,1.3-0.6,1.9-0.6
									c2.1-0.2,3.3,0.9,3.5,3.2l0.1,1.6l0.1,1.7c0.2,1.8,0.5,3,0.9,3.8c-0.5,0.2-1.1,0.3-1.7,0.3c-0.5,0-1.1,0.1-1.7,0
									c-0.1-2-0.2-4.1-0.4-6.4c-0.1-0.7-0.3-1.2-0.6-1.4s-0.8-0.4-1.4-0.3c-0.9,0.1-1.6,0.3-2.2,0.8l0,0.7c0.1,1.2,0.2,2.2,0.3,3.1
									c0.2,1.8,0.5,3,0.9,3.8c-0.5,0.2-1,0.3-1.6,0.3C2213.2,1440.3,2212.6,1440.3,2212,1440.2z"
						/>
						<path
							d="M2227.7,1438.9c0-1.7,0-3.5-0.1-5.5c0-0.9-0.1-1.7-0.2-2.2c-0.1-0.5-0.2-0.9-0.4-1.2c-0.1-0.3-0.4-0.6-0.7-0.9
									c0.3-0.3,0.7-0.6,1-0.8c0.4-0.2,0.7-0.3,1-0.3c1,0,1.6,0.4,1.8,1.5c0.4-0.5,1-1,1.6-1.3c0.6-0.3,1.3-0.5,2-0.5
									c2.1-0.1,3.2,1,3.3,3.4l0,1.6l0,1.7c0.1,1.8,0.3,3.1,0.7,3.8c-0.5,0.1-1.1,0.2-1.7,0.2c-0.5,0-1.1,0-1.7-0.1c0-2,0-4.1-0.1-6.4
									c0-0.7-0.2-1.2-0.5-1.5c-0.3-0.3-0.8-0.4-1.4-0.4c-0.9,0-1.6,0.3-2.2,0.6l0,0.7c0,1.2,0,2.3,0.1,3.2c0.1,1.8,0.3,3.1,0.7,3.8
									c-0.5,0.1-1.1,0.2-1.6,0.2C2228.9,1439,2228.3,1438.9,2227.7,1438.9z"
						/>
						<path
							d="M2244.5,1438c-0.8-0.4-1.4-1.1-1.8-1.8c-0.4-0.8-0.6-1.7-0.6-2.7c0-1.1,0.3-2.1,0.8-3c0.5-0.9,1.2-1.6,2.2-2
									c0.9-0.5,2-0.7,3.1-0.7c1.6,0,2.9,0.5,3.8,1.4c1,1,1.4,2.2,1.4,3.8c0,1.1-0.3,2.1-0.8,3c-0.5,0.9-1.3,1.6-2.2,2
									c-0.9,0.5-2,0.7-3.2,0.7C2246.2,1438.6,2245.3,1438.4,2244.5,1438z M2250,1436.4c0.5-0.6,0.8-1.5,0.8-2.6c0-1-0.2-1.8-0.7-2.5
									c-0.5-0.7-1.2-1.2-2-1.5c-1,0-1.8,0.3-2.4,0.9c-0.6,0.6-0.9,1.5-0.9,2.6c0,1.2,0.3,2.2,0.8,2.9c0.5,0.7,1.3,1.1,2.1,1.1
									C2248.7,1437.4,2249.4,1437.1,2250,1436.4z"
						/>
						<path
							d="M2258.5,1438.5c0.2-1.7,0.4-3.5,0.4-5.5c0-0.9,0-1.7,0-2.2c0-0.5-0.1-0.9-0.2-1.2c-0.1-0.3-0.3-0.6-0.6-0.9
									c0.3-0.3,0.7-0.5,1.1-0.7c0.4-0.1,0.7-0.2,1-0.2c1,0,1.5,0.6,1.6,1.6c0.5-0.5,1.1-0.9,1.7-1.1c0.7-0.3,1.3-0.4,2-0.3
									c2.1,0.1,3.1,1.3,3,3.7l-0.1,1.6l-0.1,1.7c-0.1,1.8,0,3.1,0.4,3.9c-0.5,0.1-1.1,0.1-1.7,0.1c-0.5,0-1.1-0.1-1.7-0.2
									c0.2-1.9,0.4-4.1,0.5-6.4c0-0.7-0.1-1.2-0.4-1.5c-0.3-0.3-0.7-0.5-1.3-0.5c-0.9,0-1.7,0.1-2.2,0.4l-0.1,0.7
									c-0.1,1.2-0.2,2.2-0.2,3.2c-0.1,1.8,0,3.1,0.4,3.9c-0.5,0.1-1.1,0.1-1.6,0.1C2259.8,1438.7,2259.2,1438.6,2258.5,1438.5z"
						/>
					</g>
					<g className="level_zero location" id="minas_morgul">
						<rect x="1290.314" y="1161.627" width="83.783" height="25.555" transform="matrix(0.961589, -0.274492, 0.262907, 0.964896, 567.832153, 891.552795)"></rect>
						<rect x="1279.677" y="1161.631" width="83.092" height="25.555" transform="matrix(0.967536, -0.252731, 0.241074, 0.970581, 612.740173, 864.329773)"></rect>
						<path
							d="M2140.1,1685.7c-0.2-1.7-0.7-4.1-1.4-7.2c-0.3-1.5-0.6-2.7-0.8-3.5c-0.2-0.8-0.4-1.5-0.7-2c0.4-0.2,0.9-0.3,1.4-0.4
									c0.4-0.1,1-0.2,1.6-0.2c0.8,1.1,1.8,2.5,3.1,4.1c1.2,1.6,2.3,3,3.1,4c0.3-1.4,0.7-3.1,1.2-5.2c0.4-2,0.8-3.6,1-4.8
									c0.3-0.2,0.8-0.3,1.4-0.5c0.4-0.1,1-0.2,1.6-0.2c0.1,0.8,0.3,1.9,0.5,3.3s0.5,2.7,0.8,3.9c0.6,2.9,1.2,4.7,1.6,5.4
									c-0.5,0.2-1,0.4-1.5,0.5c-0.4,0.1-0.9,0.2-1.5,0.2c-0.1-0.8-0.3-1.9-0.5-3.3c-0.3-1.4-0.5-2.7-0.8-3.9c-0.1-0.7-0.3-1.5-0.6-2.4
									c-0.7,3.1-1.5,6.6-2.3,10.6c-0.2,0.1-0.4,0.2-0.6,0.2c-0.2,0-0.3,0-0.6,0c-2.1-2.9-4.1-5.6-6-8.1c0.3,1.5,0.5,2.7,0.7,3.5
									c0.6,2.9,1.1,4.7,1.5,5.4c-0.3,0.2-0.7,0.3-1,0.4C2140.9,1685.7,2140.5,1685.7,2140.1,1685.7z"
						/>
						<path
							d="M2159,1681c-1-0.3-1.9-0.9-2.6-1.7s-1.2-1.8-1.5-3c-0.3-1.3-0.3-2.6,0-3.8c0.3-1.2,0.9-2.2,1.8-3.1c0.9-0.8,2-1.4,3.3-1.7
									c1.2-0.3,2.3-0.3,3.3,0c1,0.3,1.9,0.9,2.6,1.7s1.2,1.9,1.5,3.1c0.3,1.3,0.3,2.6,0,3.7c-0.3,1.2-1,2.2-1.9,3
									c-0.9,0.8-2.1,1.4-3.4,1.8C2161.1,1681.3,2160.1,1681.3,2159,1681z M2165,1677.6c0.5-1,0.6-2.2,0.2-3.6c-0.3-1.3-0.9-2.3-1.7-3
									s-1.8-1.1-2.9-1c-0.8,0.2-1.4,0.6-2,1.1c-0.5,0.6-0.9,1.2-1.1,2c-0.2,0.8-0.2,1.7,0.1,2.6c0.3,1.4,1,2.5,1.9,3.2
									c0.9,0.7,1.9,0.9,3,0.6C2163.6,1679.2,2164.5,1678.6,2165,1677.6z"
						/>
						<path
							d="M2171.3,1678.4c-0.1-0.8-0.4-1.9-0.7-3.3c-0.3-1.4-0.6-2.7-0.9-3.8c-0.7-2.7-1.3-4.3-1.7-4.8c0.4-0.3,1.1-0.7,1.9-1
									c0.8-0.3,1.7-0.6,2.5-0.9c1.6-0.4,3-0.4,4,0.1c1.1,0.5,1.8,1.4,2.1,2.8c0.2,0.8,0.2,1.6-0.1,2.3c-0.3,0.7-0.7,1.4-1.4,1.9
									c1.8,2.1,3.2,3.3,4.1,3.7c-0.4,0.3-0.9,0.6-1.4,0.8c-0.5,0.2-1,0.4-1.7,0.6c-0.6-0.8-1.7-2.1-3.4-3.9c-0.1,0-0.2,0.1-0.4,0.1
									c-0.6,0.1-1.1,0.2-1.7,0.3c0.6,2.2,1.1,3.7,1.6,4.3c-0.5,0.2-0.9,0.4-1.4,0.5C2172.5,1678.3,2171.9,1678.4,2171.3,1678.4z
									M2176,1670c0.2-0.6,0.2-1.1,0.1-1.7c-0.5-1.8-1.7-2.4-3.7-1.9c-0.4,0.1-0.8,0.3-1.1,0.5c0.2,1.1,0.6,2.7,1.1,4.7
									c1.2,0.1,2.1,0,2.6-0.1C2175.4,1671,2175.8,1670.6,2176,1670z"
						/>
						<path
							d="M2183.6,1673.7c-1.2-0.8-2.1-2.1-2.7-4c-0.4-1.3-0.4-2.6-0.2-3.8c0.3-1.2,0.8-2.2,1.6-3.1c0.8-0.9,1.8-1.5,3.1-1.8
									c1.5-0.4,2.8-0.6,3.9-0.5c0.1,0.4,0.2,0.9,0.1,1.5c0,0.6-0.1,1-0.2,1.3c-0.8-0.3-1.7-0.5-2.5-0.6c-0.9-0.1-1.5-0.1-2,0
									c-0.5,0.3-0.8,0.7-1.1,1.3c-0.3,0.6-0.5,1.3-0.5,2.1c-0.1,0.8,0,1.5,0.2,2.3c0.5,1.7,1.1,2.8,2,3.4c0.8,0.6,1.9,0.7,3.3,0.3
									c0.5-0.1,1-0.4,1.5-0.6c-0.7-2.4-1.2-3.8-1.6-4.3c0.4-0.2,0.8-0.4,1.3-0.5c0.4-0.1,1-0.2,1.6-0.3c0.2,1.3,0.6,2.9,1.1,4.9
									c-0.4,0.6-1,1.2-1.8,1.8c-0.8,0.5-1.6,1-2.6,1.2C2186.4,1674.6,2184.9,1674.5,2183.6,1673.7z"
						/>
						<path
							d="M2196.4,1670.2c-1.1-0.6-1.8-1.7-2.3-3.2c-0.5-1.6-1-3.2-1.5-4.7c-0.5-1.5-0.9-2.5-1.2-2.9c0.4-0.2,0.9-0.4,1.4-0.6
									c0.4-0.1,0.9-0.2,1.5-0.3c0.6,2.3,1.3,4.8,2.2,7.7c0.6,2.1,1.9,2.9,3.7,2.3c1.9-0.6,2.6-1.8,2-3.7c-1.3-4.4-2.2-7-2.6-7.9
									c0.3-0.2,0.6-0.3,0.9-0.4c0.3-0.1,0.6-0.1,1.1-0.2c0.2,0.9,0.6,2.1,1,3.6c0.4,1.5,0.8,2.9,1.2,4c0.4,1.4,0.4,2.7-0.1,3.9
									c-0.5,1.2-1.6,2-3.4,2.6C2198.7,1670.9,2197.4,1670.8,2196.4,1670.2z"
						/>
						<path
							d="M2206.7,1664.2c-0.3-0.9-0.6-2-1-3.3c-0.9-2.9-1.7-4.7-2.1-5.2c0.4-0.2,0.9-0.4,1.4-0.6c0.4-0.1,1-0.3,1.5-0.3
									c0.2,0.8,0.5,1.9,0.9,3.3c0.4,1.4,0.8,2.6,1.2,3.8c0.5,1.5,0.9,2.6,1.2,3.2c0.7-0.1,1.5-0.3,2.4-0.5c0.6-0.2,1.1-0.4,1.6-0.7
									c0.5-0.3,1-0.6,1.3-0.9c0.2,0.5,0.3,1,0.3,1.5c0.1,0.5,0.1,0.9,0,1.2c-1.1,0.2-2.4,0.6-3.8,1c-1.7,0.6-3,1-3.8,1.4
									C2207.3,1666.4,2207,1665.1,2206.7,1664.2z"
						/>
						<path
							d="M2135.3,1670.7c-0.4-1.7-1-4.1-1.9-7.1c-0.4-1.5-0.8-2.6-1.1-3.4c-0.3-0.8-0.6-1.4-0.8-1.9c0.4-0.2,0.8-0.4,1.3-0.6
									c0.4-0.1,1-0.2,1.5-0.3c0.9,1,2,2.3,3.4,3.9c1.4,1.5,2.5,2.8,3.4,3.7c0.2-1.4,0.5-3.2,0.8-5.3s0.5-3.7,0.6-4.8
									c0.3-0.2,0.8-0.4,1.4-0.6c0.4-0.1,1-0.2,1.6-0.3c0.2,0.8,0.4,1.9,0.8,3.3c0.4,1.4,0.7,2.7,1.1,3.9c0.9,2.9,1.5,4.6,2,5.2
									c-0.5,0.2-0.9,0.4-1.4,0.6c-0.4,0.1-0.9,0.2-1.5,0.3c-0.2-0.8-0.4-1.9-0.8-3.3c-0.4-1.4-0.7-2.7-1.1-3.9c-0.2-0.7-0.5-1.5-0.8-2.4
									c-0.5,3.1-1,6.7-1.5,10.8c-0.2,0.1-0.4,0.2-0.5,0.3c-0.2,0-0.3,0.1-0.6,0c-2.4-2.8-4.6-5.3-6.7-7.5c0.4,1.5,0.8,2.7,1,3.4
									c0.9,2.8,1.5,4.6,1.9,5.3c-0.3,0.2-0.6,0.3-1,0.4C2136.1,1670.6,2135.8,1670.7,2135.3,1670.7z"
						/>
						<path
							d="M2153.3,1665.3c-0.2-0.9-0.5-2-0.8-3.4c-0.3-1.4-0.7-2.6-1-3.7c-0.8-2.8-1.5-4.6-2-5.3c0.4-0.2,0.9-0.4,1.4-0.6
									c0.4-0.1,0.9-0.2,1.5-0.3c0.2,0.8,0.4,1.9,0.8,3.3c0.4,1.4,0.7,2.7,1.1,3.9c0.9,2.9,1.5,4.6,2,5.2c-0.4,0.2-0.9,0.4-1.4,0.6
									C2154.4,1665.1,2153.9,1665.2,2153.3,1665.3z"
						/>
						<path
							d="M2160.1,1663.3c-0.4-1.7-1-4.1-1.9-7.1c-0.8-2.9-1.4-4.6-1.8-5.3c0.4-0.2,0.9-0.4,1.3-0.5c0.4-0.1,0.9-0.2,1.6-0.3
									c0.8,0.8,2,2,3.6,3.6s3.2,3,4.6,4.3c-0.5-1.8-0.9-3.2-1.2-4.2c-0.8-2.9-1.5-4.7-1.8-5.3c0.3-0.2,0.6-0.3,0.9-0.4
									c0.3-0.1,0.7-0.1,1.1-0.2c0.5,2.1,1.1,4.5,1.9,7.1c0.8,2.9,1.4,4.6,1.8,5.3c-0.4,0.2-0.8,0.4-1.3,0.5c-0.4,0.1-1,0.2-1.6,0.3
									c-0.9-0.9-2.1-2.1-3.8-3.7s-3.1-3-4.4-4.2c0.3,1.4,0.7,2.7,1.1,4.2c0.8,2.8,1.4,4.6,1.8,5.3c-0.3,0.2-0.6,0.3-0.9,0.4
									C2160.9,1663.2,2160.5,1663.3,2160.1,1663.3z"
						/>
						<path
							d="M2181.9,1657c-0.4-0.6-1.1-1.5-1.9-2.7c-0.8,0.2-1.8,0.4-2.8,0.7c-1.1,0.3-1.9,0.6-2.3,0.7c-0.1,1.3-0.2,2.4-0.2,3.3
									c-0.3,0.2-0.6,0.3-0.8,0.4c-0.3,0.1-0.7,0.1-1.1,0.1c0.2-0.9,0.5-3,0.8-6.2c0.3-3.3,0.5-5.8,0.6-7.6c0.5-0.2,0.8-0.4,1.2-0.5
									c0.3-0.1,0.7-0.2,1.1-0.2c1,1.5,2.5,3.6,4.4,6.2c1.9,2.6,3.3,4.3,3.9,4.9c-0.4,0.2-0.9,0.4-1.4,0.6
									C2183,1656.9,2182.5,1657,2181.9,1657z M2179,1652.9c-1.5-2-2.6-3.5-3.4-4.5c-0.2,1.2-0.3,3-0.5,5.5c0.8-0.2,1.7-0.4,2.6-0.7
									C2178.3,1653.1,2178.7,1652.9,2179,1652.9z"
						/>
						<path
							d="M2186.7,1655.1c-0.1-0.4-0.2-0.7-0.2-1s0-0.6,0.1-0.9c0.5,0.3,1.1,0.4,1.8,0.5c0.7,0,1.5-0.1,2.2-0.3
									c0.8-0.2,1.4-0.5,1.7-1c0.4-0.4,0.5-0.9,0.3-1.4c-0.1-0.5-0.4-0.8-1-1c-0.5-0.2-1.3-0.4-2.3-0.6c-0.9-0.2-1.7-0.3-2.3-0.5
									c-0.6-0.2-1.1-0.5-1.6-0.9c-0.5-0.4-0.8-0.9-1-1.6c-0.3-1,0-1.9,0.7-2.7s1.8-1.5,3.3-1.9c1.4-0.4,2.7-0.5,3.7-0.4
									c0.1,0.4,0.2,0.9,0.1,1.4s-0.1,1-0.2,1.4c-0.8-0.3-1.6-0.5-2.4-0.7c-0.8-0.1-1.4-0.1-1.9,0c-0.3,0.2-0.5,0.4-0.7,0.7
									c-0.2,0.3-0.2,0.7-0.1,1.1c0.1,0.5,0.5,0.9,1,1.1c0.5,0.2,1.3,0.4,2.4,0.6c0.9,0.2,1.7,0.3,2.2,0.5c0.6,0.2,1.1,0.5,1.6,0.9
									s0.8,0.9,1,1.6c0.3,1.1,0.1,2.1-0.5,3s-1.8,1.5-3.4,1.9C2189.6,1655.2,2188.1,1655.3,2186.7,1655.1z"
						/>
					</g>
					<g className="level_zero location " id="sea_of_nurnen">
						<rect x="1993.327" y="1551.712" width="129.434" height="34.136" transform="matrix(0.996835, -0.079499, 0.067487, 0.997793, 439.246887, 556.451477)"></rect>
						<rect x="2174.153" y="1551.714" width="141.175" height="34.136" transform="matrix(0.816615, -0.577182, 0.567342, 0.82357, -7.826867, 1918.840576)"></rect>
						<path
							d="M2679.8,1955.6c-1.3-2.6-3.4-6-6.1-10.4c-2.6-4.2-4.4-6.7-5.3-7.7c0.6-0.6,1.2-1.1,1.9-1.5c0.6-0.4,1.4-0.8,2.4-1.2
									c1.6,0.9,4.1,2.3,7.4,4c3.3,1.8,6.4,3.4,9.3,4.8c-1.6-2.6-2.8-4.6-3.7-6.1c-2.7-4.3-4.4-6.8-5.3-7.6c0.4-0.4,0.8-0.8,1.3-1.1
									c0.4-0.3,1-0.5,1.6-0.7c1.7,3.1,3.8,6.6,6.1,10.4c2.6,4.2,4.4,6.7,5.3,7.7c-0.5,0.5-1.1,1-1.8,1.4c-0.6,0.4-1.5,0.8-2.4,1.2
									c-1.8-1-4.3-2.4-7.6-4.2c-3.3-1.8-6.3-3.4-8.9-4.7c1.2,2,2.4,4,3.7,6.1c2.6,4.1,4.3,6.7,5.3,7.6c-0.4,0.4-0.8,0.8-1.3,1.1
									C2681,1955.1,2680.4,1955.4,2679.8,1955.6z"
						/>
						<path
							d="M2696.9,1938.6l-1.4-2.2l-1.5-2.3c-1.5-2.2-2.8-3.6-3.8-4.3c0.9-1.3,2.1-2.4,3.7-3.5c1.2,2.1,3.2,5.1,6,9.2
									c0.6,0.9,1.2,1.4,1.8,1.5c0.6,0.2,1.3,0,2-0.6c1.1-0.7,1.8-1.6,2.3-2.6c-1-1.4-1.8-2.7-2.4-3.8l-1.1-1.7c-1.5-2.2-2.8-3.6-3.8-4.3
									c0.9-1.3,2.1-2.4,3.7-3.5c0.9,1.5,2,3.3,3.2,5.2c1.2,1.9,2.1,3.4,2.8,4.5c0.6,0.9,1.1,1.5,1.7,1.8c0.5,0.4,1.2,0.7,2.1,0.9
									c-0.2,0.7-0.5,1.3-0.9,1.9c-0.4,0.6-0.8,1.1-1.3,1.6c-1,0-1.9-0.4-2.7-1.1c-0.2,1-0.5,2-1.1,2.9c-0.6,0.9-1.2,1.6-2.1,2.2
									C2701.4,1942.3,2699.1,1941.7,2696.9,1938.6z M2691.7,1923.8c0.6-1,1-2,1.2-3.2c0.2-1.2,0.1-2.1-0.3-2.9c1.7-1.2,3.3-1.5,4.6-0.8
									c-0.2,1.2-0.7,2.5-1.5,3.8c-0.8,1.3-1.9,2.6-3.2,3.7L2691.7,1923.8z"
						/>
						<path
							d="M2717.8,1930.4c-1.4-2.3-3.1-4.7-4.9-7.2c-0.9-1.2-1.6-2.1-2.1-2.7c-0.5-0.6-1-1-1.5-1.2c-0.4-0.3-1-0.4-1.6-0.6
									c0.1-0.7,0.3-1.3,0.6-1.8c0.3-0.5,0.7-0.9,1-1.2c1.4-1,2.7-0.7,4,0.8c0.1-2.2,0.7-4.1,1.9-5.6c0.5,0.3,1,0.8,1.7,1.4
									c0.6,0.7,1.2,1.3,1.7,1.9c-0.4,0.2-0.8,0.5-1.2,0.7c-0.7,0.5-1.3,1-1.7,1.6c-0.5,0.6-0.8,1.2-1.1,1.8c1.1,1.6,2.2,3.1,3.1,4.4
									c1.7,2.3,3.1,3.8,4.3,4.4c-0.5,0.6-1.2,1.2-1.9,1.7C2719.4,1929.5,2718.7,1930,2717.8,1930.4z"
						/>
						<path
							d="M2729.9,1921.5c-1.5-2.2-3.2-4.6-5.1-7c-0.9-1.2-1.7-2-2.2-2.6s-1.1-1-1.5-1.2c-0.4-0.2-1-0.4-1.7-0.5
									c0.1-0.7,0.3-1.3,0.6-1.9s0.6-0.9,1-1.2c1.2-0.9,2.3-0.8,3.5,0.3c0.1-1.1,0.3-2.1,0.8-3.1c0.5-1,1.1-1.8,1.9-2.4
									c2.5-2,4.9-1.5,7.2,1.5l1.5,2.1l1.6,2.2c1.8,2.2,3.2,3.7,4.4,4.3c-0.5,0.6-1.1,1.2-1.9,1.8c-0.6,0.5-1.3,0.9-2.2,1.4
									c-1.8-2.5-3.8-5.3-6-8.1c-0.7-0.9-1.3-1.3-1.9-1.5c-0.6-0.1-1.3,0.1-2.1,0.7c-1.1,0.8-1.8,1.7-2.1,2.7l0.7,1c1.1,1.5,2.1,2.9,3,4
									c1.8,2.2,3.2,3.7,4.4,4.3c-0.5,0.6-1.1,1.2-1.8,1.7C2731.5,1920.5,2730.8,1921,2729.9,1921.5z"
						/>
						<path
							d="M2746.1,1906.5c-1.8-0.3-3.6-1.4-5.3-3.5c-1.2-1.4-1.9-2.8-2.3-4.4s-0.3-3,0.2-4.5c0.5-1.4,1.4-2.7,2.7-3.8
									c1.7-1.4,3.5-2,5.3-1.6c1.8,0.4,3.6,1.6,5.5,3.8c-0.9,1.2-2,2.6-3.4,4.1c-1.4,1.5-2.8,2.9-4.3,4.2c1.1,1,2.1,1.5,3.1,1.5
									c1,0,2-0.4,3-1.3c0.9-0.7,1.6-1.5,2.2-2.4c0.6-0.8,1-1.7,1.2-2.6l1.1,0.8c0.1,1.4-0.2,2.8-0.9,4.2c-0.7,1.4-1.6,2.6-2.7,3.6
									C2749.7,1906.1,2748,1906.7,2746.1,1906.5z M2745.8,1896.7c0.7-1,1.3-1.9,1.6-2.6c-0.9-1-1.9-1.6-3.1-1.8c-1.1-0.2-2.1,0.1-3,0.8
									c-0.3,0.9-0.2,1.9,0.1,3.1c0.4,1.2,1,2.3,1.9,3.4C2744.3,1898.7,2745.1,1897.7,2745.8,1896.7z"
						/>
						<path
							d="M2762.5,1894.6c-1.7-2.1-3.6-4.3-5.6-6.6c-1-1.1-1.8-1.9-2.4-2.4c-0.6-0.5-1.1-0.9-1.6-1.1c-0.5-0.2-1-0.3-1.7-0.4
									c0-0.7,0.2-1.3,0.4-1.9c0.3-0.6,0.6-1,0.9-1.3c1.1-1,2.3-1,3.5,0.1c0-1.1,0.2-2.1,0.6-3.1c0.4-1,1-1.8,1.7-2.5
									c2.3-2.1,4.8-1.8,7.3,0.9l1.7,1.9l1.8,2.1c1.9,2.1,3.5,3.4,4.7,4c-0.5,0.6-1,1.3-1.7,1.9c-0.6,0.5-1.2,1-2.1,1.5
									c-1.9-2.4-4.1-5-6.6-7.6c-0.7-0.8-1.4-1.2-2-1.3c-0.6-0.1-1.3,0.2-2,0.9c-1,0.9-1.6,1.9-1.9,2.9l0.8,0.9c1.2,1.4,2.3,2.7,3.3,3.7
									c1.9,2.1,3.5,3.4,4.7,4c-0.5,0.7-1,1.3-1.6,1.8C2764,1893.5,2763.3,1894.1,2762.5,1894.6z"
						/>
						<path
							d="M2536,1973.7c0-0.8,0-1.4,0.1-2c0.1-0.6,0.3-1.2,0.5-1.8c0.8,0.8,1.8,1.3,3.1,1.7c1.3,0.4,2.7,0.5,4.1,0.5
									c1.5-0.1,2.7-0.5,3.5-1.1c0.8-0.7,1.2-1.6,1.1-2.7c0-1-0.5-1.8-1.3-2.4c-0.8-0.6-2.2-1.4-4-2.2c-1.6-0.7-2.9-1.4-3.9-2
									c-1-0.6-1.9-1.4-2.6-2.4c-0.8-1-1.2-2.2-1.2-3.7c-0.1-2,0.7-3.7,2.3-5c1.7-1.3,3.9-2.1,6.8-2.2c2.7-0.1,5.1,0.1,7,0.8
									c0,0.8-0.1,1.8-0.3,2.8c-0.3,1.1-0.6,1.9-0.9,2.6c-1.4-1-2.8-1.8-4.2-2.4c-1.4-0.6-2.6-0.9-3.5-0.9c-0.6,0.2-1.1,0.5-1.6,1.1
									c-0.5,0.6-0.7,1.3-0.7,2.1c0.1,1.1,0.5,2,1.4,2.6c0.9,0.7,2.2,1.4,4.1,2.2c1.6,0.7,2.9,1.4,3.9,2c1,0.6,1.8,1.4,2.6,2.4
									s1.1,2.2,1.2,3.6c0.1,2.4-0.6,4.2-2.1,5.6c-1.5,1.4-3.9,2.1-7,2.3C2541.2,1975.2,2538.4,1974.8,2536,1973.7z"
						/>
						<path
							d="M2558.9,1971.6c-1.5-1.6-2.5-4-2.8-7.2c-0.2-2.2,0-4.1,0.7-5.9c0.7-1.8,1.7-3.2,3.1-4.3c1.4-1.1,3.1-1.7,5-1.9
									c2.5-0.2,4.5,0.5,5.9,2.1c1.5,1.6,2.4,4.1,2.7,7.5c-1.6,0.6-3.5,1.1-5.8,1.7c-2.2,0.5-4.5,0.9-6.7,1.2c0.4,1.7,1,2.9,1.9,3.6
									c0.9,0.7,2.1,1,3.6,0.8c1.3-0.1,2.5-0.4,3.6-0.8c1.1-0.4,2-1,2.8-1.7l0.6,1.6c-0.8,1.4-2,2.6-3.5,3.5c-1.5,1-3.2,1.5-4.8,1.7
									C2562.5,1973.9,2560.4,1973.2,2558.9,1971.6z M2564.9,1961.8c1.3-0.5,2.4-1,3.2-1.5c-0.2-1.6-0.8-2.9-1.7-3.9
									c-0.9-1-2.1-1.4-3.3-1.3c-0.8,0.7-1.4,1.7-1.9,3.1s-0.5,3-0.4,4.7C2562.1,1962.7,2563.5,1962.3,2564.9,1961.8z"
						/>
						<path
							d="M2579.1,1970.9c-0.9-0.8-1.4-1.8-1.6-3.1c-0.3-2,0.4-3.7,2-5.1s4.2-2.4,7.8-3.2c-0.3-1.8-0.8-3-1.4-3.7
									c-0.7-0.7-1.7-0.9-3.2-0.7c-0.9,0.1-1.9,0.4-2.8,0.9c-0.9,0.5-1.8,1-2.5,1.7l-0.6-1.6c0.7-1.6,1.7-2.9,3-4c1.3-1.1,2.7-1.7,4.2-2
									c2.1-0.3,3.7,0.1,5,1c1.3,1,2,2.5,2.3,4.6c0.2,1.2,0.3,2.5,0.3,4c0.1,1.6,0.2,2.7,0.3,3.5c0.2,1.2,0.4,2.2,0.8,2.8
									c0.3,0.6,0.9,1.3,1.7,2c-1.1,1.2-2.4,2-3.9,2.4c-0.9-0.5-1.6-1.3-2.1-2.3c-1,1-2,1.8-3.1,2.4c-1.1,0.6-2.1,1-3.1,1.1
									C2581,1971.9,2579.9,1971.6,2579.1,1970.9z M2586,1967.9c0.7-0.5,1.3-1,1.9-1.6l0-0.3c-0.1-0.9-0.2-1.9-0.2-2.8l-0.1-2
									c-1.9,0.4-3.3,1-4.2,1.9s-1.3,1.9-1.1,3.1c0.1,0.5,0.3,1,0.6,1.5c0.3,0.5,0.7,0.8,1.2,1.1
									C2584.6,1968.7,2585.3,1968.4,2586,1967.9z"
						/>
						<path
							d="M2618.1,1964.6c-1-0.4-1.9-1-2.6-1.9c-0.7-0.9-1.2-2-1.5-3.4c-0.3-1.5-0.3-2.8,0-4.1c0.3-1.3,1-2.4,1.9-3.2
									s2.1-1.5,3.4-1.7c1.8-0.4,3.4-0.1,4.8,0.9c1.4,1,2.3,2.5,2.7,4.5c0.3,1.5,0.3,2.8-0.1,4.1s-1,2.4-2,3.2s-2.1,1.5-3.5,1.8
									C2620.2,1965,2619.1,1965,2618.1,1964.6z M2623.9,1961.2c0.4-1,0.5-2.2,0.2-3.6c-0.3-1.3-0.8-2.3-1.6-3.1
									c-0.8-0.8-1.7-1.3-2.8-1.4c-1.2,0.3-2,0.9-2.5,1.8c-0.5,1-0.6,2.2-0.3,3.6c0.4,1.6,0.9,2.8,1.8,3.6c0.8,0.8,1.8,1.1,2.8,0.9
									C2622.7,1962.8,2623.5,1962.2,2623.9,1961.2z"
						/>
						<path
							d="M2632.1,1967c0.3-0.7,0.4-1.4,0.5-1.9c0.1-0.6,0.1-1.3,0-2.2c-0.1-0.9-0.3-2.1-0.7-3.5c-0.4-1.7-1.2-4.5-2.4-8.6
									c-0.8,0.2-1.3,0.2-1.6,0.1c-0.3-0.1-0.5-0.3-0.6-0.7c0.5-0.4,1-1,1.6-1.7c-0.2-1.9,0.1-3.5,0.8-4.9c0.7-1.3,1.8-2.2,3.3-2.5
									c1.2-0.3,2.3-0.4,3.2-0.3c0.1,0.4,0.1,1,0.1,1.7c0,0.7-0.1,1.4-0.2,1.9c-0.8-0.5-1.6-0.8-2.3-1c-0.7-0.2-1.3-0.2-1.7-0.2
									c-0.3,0.3-0.4,0.8-0.4,1.5s0.2,1.7,0.5,3.2c1.5-0.4,2.6-0.8,3.2-1.1c0.2,0.9,0.2,1.8,0.1,2.7c-0.8-0.1-1.7,0-2.9,0.3l0.5,2.1
									c0.4,1.5,0.8,3,1.3,4.8c0.3,0.9,0.5,1.7,0.7,2.3c0.3,1.1,0.4,2,0.3,2.9c0,0.8-0.3,1.7-0.7,2.5c-0.4,0.8-1.1,1.9-1.9,3L2632.1,1967
									z"
						/>
					</g>
					<g className="level_two fade location " id="harad_road">
						<rect x="2166.579" y="2074.809" width="140.683" height="45.645" transform="matrix(-0.924589, -0.380967, 0.773868, -0.762698, 2570.888916, 4688.126953)"></rect>
						<path
							d="M2069.5,2205.9c1-0.4,2.4-1,4-1.7c1.7-0.7,3.2-1.5,4.7-2.2c3.5-1.7,5.6-2.9,6.4-3.6c0.4,0.4,0.7,0.9,0.9,1.4
									c0.2,0.4,0.4,1,0.6,1.6c-1.7,0.7-4,1.6-6.7,2.9c0.4,1,0.9,2.1,1.5,3.4c0.3,0.6,0.6,1.2,1,1.8c3.5-1.7,5.7-2.9,6.4-3.6
									c0.4,0.4,0.7,0.9,0.9,1.4c0.2,0.5,0.4,1,0.6,1.7c-1,0.4-2.4,1-4,1.7c-1.7,0.7-3.2,1.5-4.7,2.2c-3.6,1.7-5.7,2.9-6.4,3.6
									c-0.4-0.4-0.6-0.9-0.9-1.4c-0.2-0.4-0.4-1-0.6-1.7c1.7-0.7,3.9-1.6,6.7-2.9c-0.6-1.3-1-2.3-1.4-3c-0.3-0.7-0.7-1.4-1.1-2.2
									c-3.5,1.7-5.7,2.9-6.4,3.6c-0.4-0.4-0.6-0.9-0.9-1.4C2069.9,2207.1,2069.7,2206.5,2069.5,2205.9z"
						/>
						<path
							d="M2077.9,2221.1c0.3-0.7,0.8-1.2,1.5-1.7c1.1-0.7,2.3-0.8,3.5-0.2s2.4,1.7,3.7,3.4c1-0.6,1.6-1.2,1.9-1.8s0.1-1.2-0.3-1.9
									c-0.3-0.5-0.7-0.9-1.2-1.3s-1-0.7-1.6-0.9l0.8-0.7c1.1,0,2.1,0.2,3,0.6c0.9,0.4,1.7,1,2.1,1.8c0.6,1.1,0.8,2.1,0.5,3
									c-0.3,0.9-1,1.8-2.2,2.5c-0.7,0.4-1.4,0.8-2.3,1.2c-0.9,0.5-1.6,0.8-2,1.1c-0.7,0.4-1.2,0.8-1.5,1.2c-0.3,0.4-0.6,0.9-0.8,1.5
									c-1-0.3-1.7-0.8-2.3-1.5c0.1-0.6,0.4-1.2,0.9-1.8c-0.8-0.3-1.5-0.6-2.1-1.1c-0.6-0.4-1.1-0.9-1.4-1.4
									C2077.7,2222.4,2077.6,2221.7,2077.9,2221.1z M2081.2,2224c0.4,0.3,0.9,0.5,1.4,0.6l0.2-0.1c0.5-0.3,1.1-0.6,1.6-0.9l1.1-0.6
									c-0.7-0.9-1.3-1.5-2.1-1.8c-0.7-0.3-1.4-0.2-2.1,0.2c-0.3,0.2-0.5,0.4-0.8,0.7c-0.2,0.3-0.3,0.6-0.4,0.9
									C2080.5,2223.5,2080.8,2223.8,2081.2,2224z"
						/>
						<path
							d="M2085.4,2234.1c1.8-1.2,3.6-2.5,5.5-4c0.9-0.7,1.6-1.3,2.1-1.7c0.5-0.4,0.8-0.8,1-1.1c0.2-0.3,0.4-0.7,0.5-1.2
									c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.6,0.4,0.8,0.6c0.7,0.9,0.4,1.9-0.8,2.9c1.6-0.1,3,0.2,4.1,1c-0.2,0.4-0.6,0.8-1.1,1.3
									c-0.5,0.5-1,1-1.5,1.3c-0.1-0.3-0.3-0.6-0.5-0.8c-0.3-0.4-0.7-0.8-1.2-1.1c-0.4-0.3-0.9-0.5-1.3-0.6c-1.2,0.9-2.4,1.8-3.4,2.6
									c-1.8,1.4-2.9,2.5-3.5,3.4c-0.4-0.3-0.8-0.7-1.2-1.2C2086,2235.2,2085.7,2234.7,2085.4,2234.1z"
						/>
						<path
							d="M2095.8,2244.5c-0.1-0.7,0.1-1.4,0.5-2.2c0.7-1.2,1.6-1.8,2.9-1.9c1.3-0.1,2.9,0.3,4.9,1.2c0.6-1,0.8-1.8,0.8-2.4
									c-0.1-0.6-0.5-1.1-1.2-1.5c-0.5-0.3-1-0.5-1.7-0.6c-0.6-0.1-1.2-0.1-1.8,0l0.3-1c0.9-0.6,1.9-0.9,2.9-1c1-0.1,1.9,0.1,2.7,0.5
									c1.1,0.6,1.7,1.4,1.9,2.4s0,2.1-0.7,3.3c-0.4,0.7-0.8,1.4-1.4,2.2c-0.6,0.9-1,1.5-1.2,1.9c-0.4,0.7-0.6,1.3-0.7,1.7s-0.1,1,0,1.7
									c-1,0.2-1.9,0.2-2.8-0.2c-0.2-0.6-0.3-1.3-0.1-2c-0.8,0.1-1.6,0.2-2.4,0.1c-0.8-0.1-1.4-0.3-1.9-0.6
									C2096.3,2245.7,2095.9,2245.2,2095.8,2244.5z M2100.2,2245.5c0.5,0,1,0,1.5-0.1l0.1-0.2c0.3-0.5,0.6-1.1,1-1.5l0.7-1.1
									c-1-0.5-1.9-0.7-2.7-0.6c-0.8,0.1-1.4,0.5-1.7,1.2c-0.2,0.3-0.3,0.6-0.3,1c0,0.4,0,0.7,0.1,1
									C2099.2,2245.4,2099.6,2245.5,2100.2,2245.5z"
						/>
						<path
							d="M2112.3,2250.4c-0.5-1.3-0.6-3-0.2-5.1c0.4-2.3,1.1-3.9,2.1-5s2.2-1.4,3.7-1.1c1.2,0.2,2.1,0.8,2.9,1.8
									c0.3-2,0.4-3.6,0.4-4.7c1-0.2,2.2-0.3,3.4,0c-0.7,2.5-1.6,6.8-2.7,12.8c-0.2,0.8-0.2,1.5-0.1,2c0.1,0.5,0.2,1,0.6,1.6
									c-0.4,0.3-0.8,0.5-1.3,0.6c-0.5,0.1-0.9,0.2-1.4,0.2c-0.4-0.5-0.7-1.1-0.7-1.9c-0.6,0.5-1.2,0.8-1.8,1c-0.6,0.2-1.2,0.2-1.8,0.1
									C2113.8,2252.4,2112.8,2251.7,2112.3,2250.4z M2119,2250.2c0.1-0.6,0.3-1.7,0.6-3.2c0.3-1.6,0.6-2.9,0.7-3.8
									c-0.5-0.6-1.2-0.9-2.1-1.1c-0.9-0.2-1.7,0.1-2.3,0.7c-0.6,0.6-1,1.7-1.3,3.1c-0.2,1.3-0.2,2.4,0.1,3.3s0.8,1.5,1.5,1.8
									C2117.2,2251.1,2118.1,2250.8,2119,2250.2z"
						/>
						<path
							d="M2134.6,2255.2c0.2-1,0.4-2.5,0.6-4.4c0.2-1.9,0.4-3.6,0.6-5.1c0.3-3.6,0.4-5.8,0.2-6.6c0.6-0.2,1.4-0.3,2.3-0.4
									c1-0.1,2-0.1,2.9,0c1.8,0.2,3.2,0.8,4.1,1.8s1.3,2.5,1.1,4.3c-0.1,1.1-0.4,2-1,2.8s-1.3,1.4-2.2,1.8c1.1,3.4,2,5.5,2.8,6.4
									c-0.6,0.2-1.1,0.3-1.7,0.4c-0.6,0-1.2,0-2,0c-0.3-1.2-0.9-3.3-1.9-6.2c-0.1,0-0.3,0-0.5,0c-0.6-0.1-1.2-0.2-1.9-0.4
									c-0.3,3-0.3,5-0.1,6c-0.6,0.1-1.1,0.1-1.6,0C2135.8,2255.5,2135.3,2255.4,2134.6,2255.2z M2142.8,2246.9c0.4-0.6,0.7-1.3,0.8-2
									c0.2-2.4-0.8-3.7-3.1-3.9c-0.5,0-1,0-1.4,0.1c-0.2,1.5-0.5,3.6-0.8,6.2c1.2,0.6,2.2,0.9,2.8,1
									C2141.8,2247.9,2142.3,2247.5,2142.8,2246.9z"
						/>
						<path
							d="M2151,2256.1c-0.7-0.7-1.2-1.5-1.4-2.5c-0.3-1-0.3-2.2-0.1-3.5c0.2-1.4,0.7-2.6,1.4-3.6c0.7-1,1.5-1.7,2.5-2.2
									c1-0.5,2.1-0.6,3.3-0.4c1.6,0.3,2.7,1,3.5,2.4c0.8,1.3,1,3,0.7,4.9c-0.2,1.4-0.7,2.6-1.4,3.6s-1.6,1.7-2.6,2.2s-2.1,0.6-3.3,0.4
									C2152.5,2257.2,2151.6,2256.7,2151,2256.1z M2156.7,2255.1c0.7-0.7,1.1-1.7,1.3-3.1c0.2-1.2,0.1-2.3-0.2-3.2
									c-0.3-1-0.9-1.7-1.7-2.1c-1-0.2-1.9,0.1-2.6,0.8c-0.7,0.7-1.2,1.7-1.4,3.1c-0.2,1.5-0.2,2.8,0.2,3.7c0.4,1,1,1.5,1.9,1.7
									C2155.2,2256,2156,2255.8,2156.7,2255.1z"
						/>
						<path
							d="M2162.9,2258.3c-0.3-0.7-0.3-1.4,0-2.3c0.4-1.3,1.2-2.1,2.4-2.5c1.2-0.4,2.9-0.4,5,0.1c0.3-1.1,0.4-2,0.2-2.5
									c-0.2-0.6-0.7-1-1.5-1.2c-0.5-0.2-1.1-0.2-1.7-0.2s-1.2,0.1-1.8,0.4l0.1-1.1c0.8-0.7,1.7-1.3,2.7-1.6s1.9-0.3,2.8-0.1
									c1.2,0.4,2,1,2.4,1.9c0.4,0.9,0.4,2,0,3.3c-0.2,0.7-0.5,1.5-0.9,2.4c-0.4,1-0.6,1.7-0.8,2.1c-0.2,0.8-0.4,1.4-0.3,1.9
									s0.1,1,0.4,1.7c-0.9,0.4-1.8,0.6-2.7,0.4c-0.4-0.5-0.5-1.2-0.5-1.9c-0.8,0.3-1.5,0.5-2.3,0.6c-0.8,0.1-1.4,0-2-0.1
									C2163.7,2259.4,2163.2,2259,2162.9,2258.3z M2167.4,2258.3c0.5-0.1,1-0.2,1.4-0.4l0.1-0.2c0.2-0.6,0.4-1.2,0.6-1.7l0.5-1.2
									c-1.1-0.2-2-0.2-2.7,0c-0.7,0.3-1.2,0.8-1.4,1.5c-0.1,0.3-0.1,0.7-0.1,1c0,0.4,0.2,0.7,0.3,0.9
									C2166.5,2258.4,2166.9,2258.4,2167.4,2258.3z"
						/>
						<path
							d="M2175.7,2261.7c-0.2-1.4,0.1-3,1.1-5c1-2.1,2.1-3.5,3.3-4.3c1.2-0.7,2.5-0.8,3.9-0.2c1.1,0.5,1.9,1.3,2.4,2.4
									c0.8-1.9,1.3-3.4,1.5-4.5c1.1,0,2.2,0.3,3.3,0.8c-1.3,2.3-3.2,6.2-5.8,11.7c-0.4,0.8-0.6,1.4-0.6,1.9c-0.1,0.5,0,1.1,0.1,1.7
									c-0.5,0.2-0.9,0.3-1.4,0.3c-0.5,0-1,0-1.4-0.2c-0.3-0.6-0.4-1.3-0.2-2c-0.7,0.3-1.3,0.5-2,0.5c-0.7,0-1.3-0.1-1.8-0.3
									C2176.7,2264,2175.9,2263.1,2175.7,2261.7z M2182.3,2263.2c0.3-0.6,0.7-1.6,1.4-2.9c0.7-1.5,1.3-2.7,1.7-3.5
									c-0.4-0.7-1-1.2-1.7-1.5c-0.8-0.4-1.6-0.3-2.4,0.1c-0.7,0.5-1.4,1.4-2,2.7c-0.6,1.2-0.8,2.3-0.8,3.2c0,0.9,0.4,1.6,1,2.1
									C2180.2,2263.6,2181.2,2263.6,2182.3,2263.2z"
						/>
					</g>
					<g className="level_two fade location " id="harnen">
						<rect x="1547.293" y="1309.563" width="100.471" height="28.81" transform="matrix(-0.957334, 0.288983, -0.277448, -0.960816, 3837.242188, 3093.799072)"></rect>
						<path
							d="M1903.4,2305.2c-0.3-1.1-0.7-2.4-1.3-4.2c-0.6-1.7-1.2-3.4-1.7-4.9c-1.4-3.6-2.3-5.9-3-6.7c0.5-0.3,1-0.6,1.5-0.8
									c0.5-0.2,1-0.3,1.7-0.4c0.5,1.8,1.3,4.1,2.3,6.9c1.1-0.3,2.2-0.7,3.5-1.2c0.6-0.2,1.2-0.5,1.9-0.8c-1.4-3.7-2.4-5.9-3-6.7
									c0.5-0.3,1-0.6,1.5-0.8c0.5-0.2,1-0.3,1.7-0.4c0.3,1.1,0.7,2.4,1.3,4.2c0.6,1.7,1.2,3.4,1.7,4.9c1.4,3.7,2.4,5.9,3,6.7
									c-0.5,0.3-1,0.6-1.5,0.8c-0.5,0.2-1,0.3-1.7,0.4c-0.5-1.7-1.3-4.1-2.3-7c-1.3,0.5-2.4,0.8-3.2,1.1c-0.7,0.3-1.4,0.6-2.2,0.9
									c1.4,3.7,2.4,5.9,3,6.7c-0.5,0.3-1,0.6-1.5,0.8C1904.6,2304.9,1904,2305.1,1903.4,2305.2z"
						/>
						<path
							d="M1919.6,2298.7c-0.6-0.4-1.1-1-1.3-1.8c-0.4-1.2-0.3-2.4,0.5-3.4c0.7-1,2.1-2,4.1-2.9c-0.4-1.1-0.9-1.8-1.3-2.2
									c-0.5-0.3-1.1-0.4-1.9-0.1c-0.5,0.2-1,0.5-1.5,0.9s-0.9,0.8-1.2,1.3l-0.6-0.9c0.2-1.1,0.6-2,1.2-2.8c0.6-0.8,1.3-1.4,2.2-1.7
									c1.2-0.4,2.2-0.4,3,0.1c0.9,0.5,1.5,1.4,2,2.7c0.3,0.7,0.5,1.6,0.7,2.5c0.3,1,0.5,1.7,0.7,2.2c0.3,0.8,0.5,1.3,0.8,1.7
									c0.3,0.4,0.7,0.7,1.3,1.1c-0.5,0.9-1.1,1.5-1.9,2c-0.6-0.2-1.1-0.6-1.5-1.3c-0.4,0.7-0.9,1.3-1.5,1.8c-0.6,0.5-1.1,0.9-1.7,1.1
									C1920.8,2299.1,1920.2,2299.1,1919.6,2298.7z M1923.2,2296c0.3-0.4,0.7-0.8,0.9-1.2l-0.1-0.2c-0.2-0.6-0.4-1.2-0.5-1.7l-0.3-1.2
									c-1,0.5-1.8,1-2.2,1.7c-0.4,0.7-0.5,1.4-0.2,2.1c0.1,0.3,0.3,0.6,0.6,0.9s0.5,0.5,0.8,0.6
									C1922.5,2296.7,1922.9,2296.4,1923.2,2296z"
						/>
						<path
							d="M1934,2294.2c-0.6-2.1-1.2-4.2-2-6.5c-0.4-1.1-0.7-2-0.9-2.5c-0.3-0.6-0.5-1-0.7-1.3c-0.2-0.3-0.6-0.6-1-0.9
									c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.5,0.9-0.6c1.1-0.4,1.9,0.2,2.5,1.7c0.4-1.6,1.2-2.8,2.3-3.6c0.3,0.3,0.6,0.8,0.9,1.5
									s0.6,1.3,0.8,1.9c-0.3,0-0.6,0.1-0.9,0.2c-0.5,0.2-1,0.4-1.4,0.7c-0.4,0.3-0.8,0.6-1,1c0.5,1.5,0.9,2.8,1.3,4.1
									c0.7,2.1,1.4,3.6,2,4.4c-0.5,0.3-1,0.5-1.5,0.7C1935.2,2294,1934.6,2294.1,1934,2294.2z"
						/>
						<path
							d="M1945.1,2290.6c-0.5-2.1-1.1-4.3-1.9-6.6c-0.3-1.1-0.6-2-0.9-2.5s-0.5-1-0.7-1.3s-0.6-0.6-1-0.9c0.2-0.5,0.4-0.9,0.8-1.2
									c0.3-0.3,0.6-0.5,0.9-0.6c1-0.3,1.7,0.1,2.2,1.3c0.3-0.8,0.6-1.4,1.2-1.9c0.5-0.5,1.1-0.9,1.7-1.1c2-0.6,3.5,0.5,4.4,3.3l0.5,1.9
									l0.6,2.1c0.7,2.1,1.3,3.6,2,4.4c-0.4,0.3-1,0.5-1.6,0.7c-0.5,0.2-1,0.3-1.7,0.3c-0.6-2.4-1.3-4.9-2.2-7.6c-0.3-0.8-0.6-1.4-1-1.6
									c-0.4-0.3-0.9-0.3-1.5-0.1c-0.9,0.3-1.5,0.7-1.9,1.3l0.3,0.9c0.4,1.4,0.8,2.7,1.1,3.8c0.7,2.1,1.3,3.6,2,4.4
									c-0.5,0.3-1,0.5-1.5,0.7C1946.3,2290.4,1945.7,2290.5,1945.1,2290.6z"
						/>
						<path
							d="M1960.3,2284.5c-1.1-0.8-1.9-2.3-2.5-4.3c-0.4-1.4-0.5-2.6-0.3-3.8c0.2-1.2,0.6-2.2,1.3-3s1.6-1.4,2.7-1.7
									c1.4-0.4,2.7-0.2,3.7,0.7c1.1,0.9,1.9,2.4,2.5,4.5c-0.9,0.5-1.9,1.1-3.2,1.7c-1.2,0.6-2.5,1.1-3.8,1.4c0.5,1,1,1.7,1.6,2.1
									c0.6,0.4,1.3,0.4,2.2,0.2c0.7-0.2,1.4-0.5,2-0.9s1-0.8,1.4-1.4l0.5,0.9c-0.3,1-0.8,1.8-1.6,2.6c-0.8,0.8-1.6,1.3-2.6,1.6
									C1962.7,2285.5,1961.4,2285.3,1960.3,2284.5z M1962.6,2277.6c0.7-0.4,1.3-0.9,1.7-1.3c-0.3-1-0.8-1.8-1.5-2.3
									c-0.7-0.5-1.4-0.7-2.1-0.5c-0.4,0.5-0.6,1.2-0.7,2.2s0.1,1.9,0.4,3C1961.1,2278.4,1961.8,2278,1962.6,2277.6z"
						/>
						<path
							d="M1975.2,2281.6c-0.4-2.1-1-4.3-1.6-6.6c-0.3-1.1-0.6-2-0.8-2.6s-0.4-1-0.7-1.3c-0.2-0.3-0.5-0.6-0.9-0.9
									c0.2-0.5,0.5-0.9,0.8-1.1c0.3-0.3,0.6-0.5,0.9-0.5c1-0.3,1.7,0.2,2.2,1.4c0.3-0.7,0.7-1.4,1.2-1.9c0.5-0.5,1.1-0.9,1.8-1
									c2-0.5,3.5,0.6,4.2,3.4l0.5,2l0.5,2.1c0.6,2.2,1.2,3.7,1.8,4.5c-0.5,0.3-1,0.5-1.6,0.6c-0.5,0.1-1.1,0.2-1.7,0.3
									c-0.5-2.4-1.1-5-1.9-7.7c-0.2-0.8-0.5-1.4-0.9-1.7c-0.4-0.3-0.9-0.3-1.5-0.2c-0.9,0.2-1.5,0.6-2,1.2l0.2,0.9
									c0.4,1.5,0.7,2.7,1,3.8c0.6,2.2,1.2,3.7,1.8,4.5c-0.5,0.3-1,0.5-1.5,0.6C1976.4,2281.5,1975.8,2281.6,1975.2,2281.6z"
						/>
					</g>
					<g className="level_two fade location" id="cair_andros">
						<rect x="1152.669" y="2001.555" width="74.847" height="44.031" transform="matrix(0.966063, 0.258307, -0.258307, 0.966063, 1410.904663, -690.161133)"></rect>
						<path
							d="M2008.9,1580.4c-0.1-0.9-0.1-2.2-0.3-3.8c-0.8-0.2-1.8-0.5-2.8-0.7c-1.1-0.3-1.9-0.4-2.4-0.4c-0.8,1.3-1.4,2.4-1.9,3.3
									c-0.4,0-0.7,0-0.9,0c-0.4-0.1-0.7-0.2-1-0.4c0.7-0.8,2-2.9,3.9-6.1c1.9-3.3,3.4-5.8,4.4-7.7c0.5,0,0.9,0.1,1.3,0.1
									c0.4,0.1,0.7,0.2,1.1,0.4c0.1,2.1,0.4,5,0.7,8.8c0.4,3.8,0.6,6.2,0.9,7.2c-0.5,0-1,0-1.5-0.1
									C2009.9,1580.8,2009.5,1580.7,2008.9,1580.4z M2008.5,1574.6c-0.2-2.9-0.5-5-0.6-6.5c-0.7,1.1-1.8,3-3.3,5.5
									c0.8,0.2,1.7,0.5,2.6,0.7C2007.8,1574.4,2008.3,1574.5,2008.5,1574.6z"
						/>
						<path
							d="M2012.7,1581.3c0.6-2,1.4-4.7,2.4-8.3c0.9-3.4,1.4-5.6,1.4-6.5c0.5,0,1,0.1,1.4,0.2c0.4,0.1,0.9,0.3,1.5,0.6
									c0.2,1.3,0.6,3.2,1.1,5.7c0.5,2.5,1,4.9,1.5,7c0.6-2.1,1-3.7,1.3-4.9c0.9-3.5,1.4-5.6,1.4-6.4c0.3,0,0.7,0,1,0.1
									c0.3,0.1,0.6,0.2,1,0.4c-0.8,2.4-1.6,5.2-2.4,8.3c-0.9,3.4-1.4,5.6-1.4,6.5c-0.4,0-0.9,0-1.4-0.2c-0.4-0.1-1-0.3-1.6-0.6
									c-0.2-1.4-0.6-3.4-1.1-5.9c-0.5-2.5-1-4.8-1.4-6.8c-0.5,1.6-0.9,3.2-1.4,4.9c-0.9,3.4-1.3,5.5-1.4,6.4c-0.3,0-0.7,0-1-0.1
									C2013.4,1581.7,2013,1581.6,2012.7,1581.3z"
						/>
						<path
							d="M2027,1585.2c-0.7-0.3-1.4-0.6-1.9-0.9c0.3-0.9,0.8-2.1,1.3-3.6c0.5-1.5,1-3,1.4-4.4c0.9-3.1,1.4-5,1.3-5.8
									c0.6-0.1,1.3,0,2.3,0.1c0.9,0.1,1.8,0.3,2.7,0.6c2.1,0.6,3.5,1.8,4.3,3.4c0.8,1.6,0.8,3.6,0.1,6c-0.4,1.5-1.1,2.7-2.1,3.7
									c-0.9,1-2,1.6-3.3,1.9c-1.3,0.3-2.6,0.3-4-0.1C2028.5,1585.8,2027.7,1585.5,2027,1585.2z M2032.8,1584.3c0.7-0.4,1.3-1,1.8-1.7
									c0.5-0.7,0.9-1.5,1.2-2.3c1.2-3.9,0.3-6.4-2.8-7.3c-0.5-0.1-1-0.2-1.5-0.2c-0.7,2-1.4,4.1-2,6.2c-0.5,1.7-0.9,3.1-1.2,4.4
									c1,0.7,1.8,1.2,2.4,1.3C2031.4,1584.8,2032.1,1584.7,2032.8,1584.3z"
						/>
						<path
							d="M2038,1588.6c0.4-0.9,0.9-2.1,1.5-3.7s1.1-3.1,1.6-4.4c1-3.1,1.6-5,1.6-5.8c0.6-0.1,1.3,0,2.1,0.1
									c0.9,0.1,1.7,0.4,2.6,0.7c1.6,0.5,2.7,1.4,3.2,2.5c0.6,1.1,0.6,2.5,0.1,4c-0.3,0.9-0.8,1.7-1.4,2.2c-0.7,0.6-1.4,0.9-2.3,1.1
									c0.2,3.2,0.6,5.2,1.1,6.2c-0.5,0.1-1.1,0-1.6,0c-0.5-0.1-1.1-0.2-1.7-0.4c0-1.2-0.1-3.1-0.4-5.9c-0.1,0-0.2,0-0.5-0.1
									c-0.5-0.2-1.1-0.4-1.6-0.7c-0.9,2.6-1.3,4.3-1.4,5.2c-0.5,0-1-0.2-1.5-0.3C2039,1589.1,2038.5,1588.9,2038,1588.6z M2046.9,1582.9
									c0.5-0.4,0.9-1,1.1-1.6c0.7-2.1,0.1-3.4-1.9-4.1c-0.4-0.1-0.8-0.2-1.2-0.2c-0.5,1.3-1.2,3-2,5.3c1,0.8,1.7,1.3,2.2,1.5
									C2045.8,1583.6,2046.4,1583.4,2046.9,1582.9z"
						/>
						<path
							d="M2051.5,1592.7c-0.6-0.9-1-2-1-3.2s0.2-2.5,0.7-3.9c0.6-1.5,1.4-2.8,2.4-3.8s2.2-1.6,3.4-1.9s2.5-0.2,3.8,0.3
									c1.1,0.4,2,1.1,2.7,2c0.6,0.9,1,2,1.1,3.3c0.1,1.3-0.2,2.6-0.7,4c-0.6,1.5-1.4,2.7-2.4,3.7c-1,0.9-2.2,1.6-3.5,1.8
									c-1.3,0.3-2.6,0.2-3.9-0.3C2053,1594.2,2052.1,1593.6,2051.5,1592.7z M2058.4,1592.9c1-0.7,1.9-1.8,2.5-3.4c0.6-1.5,0.7-2.8,0.5-4
									s-0.8-2.1-1.7-2.8c-0.8-0.3-1.5-0.3-2.3-0.1c-0.8,0.2-1.5,0.7-2.1,1.4c-0.6,0.7-1.2,1.5-1.6,2.6c-0.6,1.6-0.8,3-0.5,4.3
									c0.3,1.2,1,2.1,2,2.5C2056.3,1593.7,2057.4,1593.6,2058.4,1592.9z"
						/>
						<path
							d="M2062.3,1596.6c0.2-0.4,0.4-0.8,0.5-1c0.2-0.3,0.4-0.6,0.7-0.8c0.2,0.6,0.5,1.1,1.1,1.6c0.5,0.5,1.2,0.9,1.9,1.2
									c0.8,0.3,1.4,0.4,2,0.2c0.6-0.2,1-0.6,1.2-1.2c0.2-0.5,0.2-1.1,0-1.6c-0.2-0.5-0.7-1.2-1.4-2.1c-0.6-0.8-1.1-1.4-1.4-2
									c-0.3-0.6-0.5-1.2-0.7-1.9s0-1.5,0.3-2.2c0.5-1.1,1.3-1.8,2.4-2.1s2.4-0.2,3.9,0.4c1.3,0.6,2.4,1.3,3.2,2.1
									c-0.2,0.4-0.5,0.9-0.9,1.4c-0.4,0.5-0.8,0.9-1.1,1.1c-0.4-0.8-0.9-1.6-1.4-2.3c-0.5-0.7-1-1.1-1.5-1.3c-0.3-0.1-0.7,0-1.1,0.2
									c-0.4,0.2-0.7,0.5-0.9,1c-0.2,0.6-0.2,1.2,0,1.7c0.3,0.6,0.7,1.3,1.4,2.1c0.6,0.8,1,1.4,1.4,2c0.3,0.5,0.5,1.2,0.6,1.9
									c0.1,0.7,0,1.4-0.3,2.2c-0.5,1.3-1.3,2.1-2.4,2.5c-1.1,0.4-2.4,0.2-3.9-0.4C2064.3,1598.7,2063.1,1597.8,2062.3,1596.6z"
						/>
						<path
							d="M2010,1559.2c-0.6-1.6-0.6-3.6,0.1-6c0.5-1.7,1.2-3.1,2.2-4.3s2.1-1.9,3.4-2.4c1.3-0.4,2.6-0.4,3.9-0.1
									c1.5,0.4,2.6,1,3.5,1.8c-0.2,0.5-0.4,1.1-0.8,1.7c-0.4,0.6-0.7,1-1,1.3c-0.5-0.8-1.1-1.5-1.8-2.2s-1.2-1-1.7-1.2
									c-0.6,0-1.2,0.2-1.8,0.7c-0.6,0.5-1.2,1.1-1.7,1.9c-0.5,0.8-0.9,1.7-1.2,2.6c-0.6,1.9-0.6,3.5-0.2,4.6c0.4,1.1,1.3,1.9,2.5,2.3
									c0.8,0.2,1.5,0.3,2.3,0.4c0.8,0,1.5-0.1,2.1-0.3l-0.1,1.2c-0.8,0.6-1.7,1-2.8,1.3c-1.1,0.2-2.1,0.2-3.1-0.1
									C2011.9,1561.9,2010.6,1560.8,2010,1559.2z"
						/>
						<path
							d="M2032.7,1567.2c0-0.9-0.1-2.3-0.1-4.1c-0.9-0.3-1.9-0.6-3-0.9c-1.2-0.3-2-0.5-2.5-0.5c-0.9,1.4-1.6,2.5-2.1,3.5
									c-0.4,0-0.7,0-1-0.1c-0.4-0.1-0.7-0.3-1-0.5c0.7-0.9,2.2-3,4.4-6.4s3.9-6.1,5-8c0.5,0,1,0.1,1.3,0.2c0.4,0.1,0.8,0.3,1.2,0.5
									c0,2.3,0.2,5.4,0.4,9.4s0.4,6.6,0.6,7.7c-0.5,0-1-0.1-1.6-0.2C2033.7,1567.7,2033.2,1567.5,2032.7,1567.2z M2032.5,1560.9
									c-0.1-3.1-0.3-5.4-0.4-6.9c-0.8,1.2-2.1,3.1-3.8,5.8c0.8,0.3,1.8,0.6,2.8,0.8C2031.7,1560.8,2032.2,1560.9,2032.5,1560.9z"
						/>
						<path
							d="M2040.9,1569.3c0.3-1.1,0.8-2.5,1.2-4.2s0.9-3.3,1.2-4.7c0.9-3.6,1.3-6,1.3-7c0.5,0,1.1,0.1,1.6,0.2
									c0.5,0.1,1,0.3,1.6,0.6c-0.3,1-0.7,2.4-1.2,4.1s-0.9,3.3-1.3,4.9c-0.9,3.7-1.3,6-1.3,6.9c-0.5,0-1.1-0.1-1.6-0.2
									C2042,1569.8,2041.4,1569.6,2040.9,1569.3z"
						/>
						<path
							d="M2050.5,1571.6c0.3-1,0.7-2.3,1.1-4.1c0.5-1.8,0.9-3.4,1.2-4.8c0.8-3.4,1.1-5.5,1-6.4c0.6-0.1,1.3-0.2,2.3-0.1
									c0.9,0.1,1.9,0.2,2.8,0.4c1.7,0.4,3,1.2,3.7,2.3c0.7,1.1,0.9,2.6,0.5,4.3c-0.2,1-0.7,1.9-1.3,2.5c-0.6,0.7-1.4,1.2-2.3,1.5
									c0.6,3.4,1.2,5.5,1.9,6.5c-0.6,0.1-1.1,0.2-1.7,0.1c-0.6,0-1.2-0.1-1.9-0.3c-0.1-1.2-0.5-3.3-1.1-6.2c-0.1,0-0.3,0-0.5-0.1
									c-0.6-0.1-1.2-0.3-1.7-0.6c-0.6,2.9-0.9,4.8-0.8,5.7c-0.5,0-1.1,0-1.6-0.2C2051.6,1572.1,2051.1,1571.9,2050.5,1571.6z
									M2059.3,1564.7c0.5-0.5,0.8-1.1,1-1.9c0.5-2.3-0.3-3.7-2.4-4.1c-0.5-0.1-0.9-0.1-1.3-0.1c-0.4,1.4-0.9,3.4-1.5,5.8
									c1.1,0.7,2,1.2,2.5,1.3C2058.2,1565.5,2058.8,1565.2,2059.3,1564.7z"
						/>
					</g>
					<g className="level_two fade location " id="nindolf_wetwang">
						<rect x="1263.244" y="1320.697" width="82.027" height="29.054" transform="matrix(0.997287, -0.073615, 0.073615, 0.997287, 584.290771, 270.795624)"></rect>
						<rect x="1600.196" y="1389.288" width="103.906" height="30.563" transform="matrix(0.997287, -0.073615, 0.073615, 0.997287, 257.699951, 245.699768)"></rect>
						<path
							d="M1960.9,1515.8l0.3,0.3c-1,1.5-1.7,3.1-2.1,4.9c-0.4,1.8-0.6,3.9-0.5,6.2c0.1,2.4,0.3,4.5,0.8,6.2
									c0.5,1.8,1.3,3.3,2.4,4.8l-0.3,0.3c-1.4-1.4-2.5-3.1-3.3-5s-1.2-4-1.3-6.3c-0.1-2.3,0.3-4.4,1-6.3
									C1958.5,1519,1959.5,1517.3,1960.9,1515.8z"
						/>
						<path
							d="M1966.7,1533.6c-0.4-0.5-0.7-1.3-1-2.1s-0.7-2.1-1.1-3.8c-0.5-1.8-0.9-3.3-1.3-4.5c-0.4-1.2-0.8-2.5-1.4-3.7
									c1-0.4,2.1-0.7,3.4-0.7c0.1,0.9,0.3,2.1,0.7,3.7c0.3,1.5,0.7,2.9,1,4.2c0.6,2.1,1.1,3.6,1.5,4.5c1.2-4.5,1.9-7.8,2.2-9.9l-0.8-2.1
									c1-0.4,2.1-0.7,3.3-0.7c0.1,0.9,0.4,2.1,0.7,3.6c0.3,1.5,0.7,2.9,1,4.2c0.6,2,1.1,3.5,1.5,4.5c0.9-3.6,1.5-6.3,1.9-8.1
									c0.4-1.8,0.6-3.1,0.5-3.7c0-0.2,0-0.3-0.1-0.5c0.7-0.2,1.5-0.4,2.4-0.4c-0.5,1.7-1.1,4-1.9,6.9c-0.7,2.9-1.4,5.5-1.9,7.8l-1.4,1.1
									c-0.5,0-1-0.3-1.3-0.8c-0.4-0.5-0.7-1.3-1-2.1s-0.7-2.1-1.1-3.8c-0.4-1.3-0.7-2.4-0.9-3.2c-1,4-1.8,7-2.2,9.1l-1.4,1.1
									C1967.6,1534.4,1967.1,1534.1,1966.7,1533.6z"
						/>
						<path
							d="M1984.1,1532.4c-0.8-1-1.2-2.4-1.3-4.3c0-1.3,0.1-2.4,0.5-3.4c0.4-1,1-1.8,1.8-2.4c0.8-0.6,1.7-0.9,2.7-0.9
									c1.3,0,2.4,0.4,3.1,1.4c0.7,1,1.1,2.5,1.2,4.5c-0.9,0.3-1.9,0.5-3.1,0.8c-1.2,0.2-2.4,0.4-3.6,0.4c0.2,1,0.5,1.8,0.9,2.2
									c0.4,0.4,1.1,0.7,1.9,0.6c0.7,0,1.3-0.1,1.9-0.3c0.6-0.2,1.1-0.5,1.5-0.9l0.3,0.9c-0.5,0.8-1.2,1.4-2,1.9s-1.7,0.8-2.6,0.8
									C1986,1533.9,1984.9,1533.4,1984.1,1532.4z M1987.6,1526.9c0.7-0.2,1.3-0.5,1.8-0.7c-0.1-0.9-0.3-1.7-0.8-2.3
									c-0.5-0.6-1.1-0.9-1.7-0.9c-0.5,0.4-0.8,1-1.1,1.8c-0.3,0.8-0.4,1.7-0.3,2.7C1986.1,1527.3,1986.9,1527.1,1987.6,1526.9z"
						/>
						<path
							d="M1996.6,1532.9c-0.3-0.4-0.4-1-0.5-1.9c0-0.6,0-1.5-0.1-2.8c0-1.4-0.1-2.6-0.1-3.6c0-0.4,0-0.8-0.1-1.2
									c-0.7,0-1.1,0-1.3-0.1c-0.2-0.1-0.3-0.3-0.4-0.7c1.1-0.7,2.1-1.8,3.1-3.1l0.8,0.1c0.1,0.7,0.2,1.3,0.2,2c1.1-0.1,1.9-0.2,2.4-0.3
									c0,0.4,0,0.8-0.1,1.2s-0.1,0.7-0.3,1c-0.5-0.2-1.2-0.3-2.1-0.3c0,0.8,0,1.7,0,2.6c0.1,2.2,0.3,3.9,0.5,5.2c0.7,0,1.3-0.2,1.9-0.3
									l0.2,0.8c-0.3,0.6-0.7,1-1.2,1.4c-0.5,0.3-1.1,0.5-1.7,0.6C1997.4,1533.4,1996.9,1533.2,1996.6,1532.9z"
						/>
						<path
							d="M2005,1530.7c-0.5-1.5-0.9-2.9-1.3-4.3c-0.1-0.3-0.2-0.8-0.4-1.4c-0.3-0.8-0.5-1.5-0.8-2.1c-0.3-0.6-0.6-1-0.8-1.3
									c0.8-0.4,1.9-0.7,3.1-0.7c0.1,0.8,0.6,2.5,1.3,5c0.7,2.5,1.3,4.1,1.5,4.7c0.9-3.9,1.5-6.4,1.7-7.5c-0.3-0.8-0.7-1.4-1-1.8
									c0.8-0.4,1.9-0.7,3.1-0.7c0.2,0.8,0.6,2.4,1.3,4.8s1.2,4,1.6,4.8c0.7-2.9,1.2-5,1.4-6.2c0.2-1.2,0.4-2.1,0.3-2.6
									c0-0.3-0.1-0.5-0.1-0.7c0.7-0.2,1.5-0.4,2.4-0.4c-0.9,2.6-1.8,6.4-2.9,11.5l-1.6,1c-0.9,0-1.7-0.7-2.1-2.3
									c-0.5-1.5-0.9-2.8-1.2-3.9l-0.3-0.9c-0.5,2-1,4.2-1.4,6.4l-1.6,1C2006.2,1533,2005.5,1532.3,2005,1530.7z"
						/>
						<path
							d="M2020.8,1531.7c-0.4-0.5-0.7-1.1-0.7-1.9c-0.1-1.2,0.4-2.2,1.3-2.9c0.9-0.7,2.4-1.2,4.3-1.4c-0.1-1.1-0.3-1.8-0.6-2.2
									c-0.3-0.4-0.9-0.6-1.7-0.6c-0.5,0-1,0.1-1.5,0.4c-0.5,0.2-1,0.5-1.4,0.9l-0.2-0.9c0.4-0.9,1-1.6,1.8-2.2c0.8-0.6,1.5-0.9,2.3-0.9
									c1.1-0.1,2,0.2,2.6,0.9c0.6,0.6,1,1.6,1,2.8c0,0.7,0,1.5,0,2.4c0,0.9,0,1.6,0,2.1c0,0.7,0.1,1.3,0.3,1.7c0.2,0.4,0.4,0.8,0.8,1.3
									c-0.6,0.6-1.4,1.1-2.2,1.2c-0.5-0.3-0.8-0.8-1-1.5c-0.6,0.5-1.2,0.9-1.8,1.2c-0.6,0.3-1.2,0.5-1.7,0.5
									C2021.8,1532.4,2021.2,1532.2,2020.8,1531.7z M2024.6,1530.3c0.4-0.2,0.8-0.5,1.1-0.8l0-0.2c0-0.6,0-1.1,0-1.7l0-1.2
									c-1,0.1-1.8,0.4-2.3,0.9c-0.5,0.5-0.8,1-0.8,1.8c0,0.3,0.1,0.6,0.3,0.9c0.2,0.3,0.4,0.5,0.6,0.7
									C2023.9,1530.7,2024.2,1530.6,2024.6,1530.3z"
						/>
						<path
							d="M2032.6,1531.6c0-1.9,0-4-0.2-6.2c-0.1-1.1-0.1-1.9-0.2-2.4c-0.1-0.6-0.2-1-0.3-1.3c-0.1-0.3-0.4-0.6-0.7-1
									c0.3-0.4,0.6-0.7,0.9-0.8c0.3-0.2,0.6-0.3,0.9-0.3c0.9,0,1.4,0.5,1.6,1.7c0.4-0.6,0.9-1.1,1.5-1.4c0.6-0.4,1.2-0.6,1.8-0.6
									c1.9-0.1,3,1.2,3.1,3.8l0,1.8l0.1,1.9c0.1,2,0.3,3.5,0.7,4.3c-0.5,0.1-1,0.2-1.5,0.3c-0.5,0-1,0-1.6-0.1c0-2.2-0.1-4.6-0.2-7.2
									c0-0.8-0.2-1.3-0.5-1.6c-0.3-0.3-0.7-0.5-1.3-0.4c-0.8,0-1.5,0.3-2,0.7l0,0.8c0,1.4,0.1,2.5,0.1,3.6c0.1,2,0.3,3.5,0.7,4.3
									c-0.5,0.1-1,0.2-1.5,0.3C2033.8,1531.7,2033.2,1531.6,2032.6,1531.6z"
						/>
						<path
							d="M2044.7,1534.9c0-0.7,0-1.3,0.2-2c0.2-0.7,0.4-1.3,0.8-1.8c-0.3-0.1-0.5-0.3-0.7-0.6c-0.2-0.3-0.3-0.6-0.3-1
									c0-0.6,0.1-1.1,0.4-1.5s0.7-0.9,1.3-1.2c-0.6-0.3-1-0.7-1.3-1.3c-0.3-0.6-0.5-1.3-0.6-2.1c-0.1-0.9,0.1-1.7,0.4-2.4
									c0.3-0.7,0.8-1.3,1.5-1.7c0.7-0.4,1.4-0.7,2.3-0.7c0.9,0,1.6,0.1,2.3,0.5c0.7-0.3,1.5-0.9,2.2-1.8c0.2,0.4,0.4,0.8,0.5,1.3
									s0.2,0.9,0.3,1.2c-0.5,0.4-1,0.6-1.7,0.7c0.3,0.6,0.5,1.3,0.5,2c0.1,0.9-0.1,1.7-0.4,2.4c-0.3,0.7-0.9,1.3-1.5,1.7
									c-0.7,0.4-1.4,0.7-2.3,0.7c-0.7,0-1.3,0.2-1.7,0.5c-0.4,0.3-0.6,0.6-0.6,1l4.6,0c0.9,0,1.7,0.3,2.2,0.8c0.5,0.5,0.8,1.1,0.9,2
									c0,0.8-0.1,1.5-0.6,2.1c-0.4,0.7-1,1.2-1.8,1.6s-1.7,0.6-2.7,0.7C2047.2,1536,2045.7,1535.7,2044.7,1534.9z M2051.4,1533.3
									c0.5-0.3,0.7-0.7,0.7-1.1c0-0.4-0.2-0.6-0.4-0.7s-0.6-0.2-1.2-0.2l-3.6,0c-0.2,0.5-0.3,0.9-0.3,1.4c0.7,0.9,1.6,1.3,2.9,1.2
									C2050.3,1533.8,2050.9,1533.6,2051.4,1533.3z M2050.2,1525.1c0.3-0.4,0.5-1,0.4-1.7c0-0.6-0.2-1.2-0.6-1.6
									c-0.3-0.4-0.8-0.7-1.4-0.9c-0.6,0-1.1,0.3-1.5,0.8c-0.4,0.5-0.5,1.1-0.5,1.9c0,0.7,0.2,1.3,0.6,1.7s0.9,0.6,1.4,0.6
									C2049.4,1525.8,2049.9,1525.6,2050.2,1525.1z"
						/>
						<path
							d="M2057.9,1516.6c0.9,1.9,1.4,3.9,1.5,6.2c0.1,2.3-0.1,4.4-0.7,6.4c-0.6,1.9-1.6,3.7-2.9,5.2l-0.3-0.3c1-1.5,1.6-3.2,2-5
									s0.4-3.9,0.3-6.3c-0.1-2.4-0.5-4.4-1-6.2c-0.6-1.7-1.4-3.3-2.5-4.7l0.3-0.3C2055.9,1513.2,2057.1,1514.8,2057.9,1516.6z"
						/>
						<path
							d="M1945.3,1516.9c0-2.2-0.1-5.3-0.3-9.2c-0.2-3.8-0.4-6.1-0.6-7.1c0.5-0.2,1-0.2,1.5-0.3c0.4,0,1,0,1.7,0.1
									c0.6,1.2,1.6,3,2.9,5.5c1.3,2.4,2.6,4.6,3.8,6.7c-0.1-2.3-0.2-4.1-0.2-5.4c-0.2-3.8-0.4-6.2-0.6-7c0.3-0.1,0.7-0.2,1.1-0.2
									c0.3,0,0.7,0,1.2,0.1c0,2.7,0.1,5.8,0.3,9.2c0.2,3.8,0.4,6.1,0.6,7.1c-0.4,0.1-0.9,0.2-1.5,0.3c-0.5,0-1.1,0-1.8-0.1
									c-0.7-1.3-1.7-3.2-3.1-5.7c-1.3-2.4-2.5-4.6-3.6-6.5c0,1.8,0.1,3.6,0.2,5.4c0.2,3.7,0.4,6.1,0.6,7c-0.3,0.1-0.7,0.2-1.1,0.2
									C1946.1,1517,1945.7,1517,1945.3,1516.9z"
						/>
						<path
							d="M1958.9,1499.3c0.5-0.8,1.1-1.5,1.7-2.2c0.6,0.7,1.2,1.4,1.9,2c-0.5,0.8-1.1,1.5-1.7,2.2
									C1960.2,1500.6,1959.6,1499.9,1958.9,1499.3z M1960.7,1515.8c-0.3-0.4-0.4-1-0.5-2c0-0.8-0.1-1.8-0.1-3.3c0-1.3-0.1-2.3-0.1-2.9
									c0-0.8-0.1-1.6-0.3-2.3s-0.3-1.2-0.5-1.5c0.5-0.3,1-0.4,1.5-0.6c0.5-0.1,1-0.2,1.7-0.2c-0.1,0.6-0.1,1.5-0.1,2.8s0.1,2.7,0.1,4.2
									c0.1,1.1,0.1,2,0.2,2.6s0.2,1.1,0.4,1.4c0.2,0.3,0.4,0.7,0.7,1c-0.3,0.4-0.6,0.7-0.9,0.9c-0.3,0.2-0.7,0.3-1,0.3
									C1961.4,1516.3,1960.9,1516.1,1960.7,1515.8z"
						/>
						<path
							d="M1967.6,1515.7c0-2.1-0.1-4.3-0.2-6.6c-0.1-1.1-0.2-2-0.3-2.6c-0.1-0.6-0.2-1.1-0.4-1.4c-0.2-0.3-0.4-0.7-0.7-1
									c0.3-0.4,0.6-0.7,1-0.9c0.4-0.2,0.7-0.3,1-0.3c1-0.1,1.6,0.5,1.8,1.8c0.4-0.6,0.9-1.2,1.5-1.6c0.6-0.4,1.2-0.6,1.9-0.6
									c2.1-0.1,3.2,1.2,3.3,4.1l0.1,2l0.1,2.1c0.1,2.2,0.4,3.7,0.8,4.6c-0.5,0.2-1,0.2-1.6,0.3c-0.5,0-1,0-1.7-0.1
									c0-2.4-0.1-4.9-0.3-7.7c-0.1-0.8-0.2-1.4-0.5-1.8c-0.3-0.3-0.7-0.5-1.4-0.5c-0.9,0.1-1.6,0.3-2.1,0.8l0,0.9
									c0.1,1.4,0.1,2.7,0.2,3.8c0.1,2.2,0.4,3.7,0.8,4.6c-0.5,0.2-1,0.2-1.6,0.3C1968.8,1515.8,1968.2,1515.8,1967.6,1515.7z"
						/>
						<path
							d="M1981.5,1513.5c-0.8-1.1-1.3-2.6-1.4-4.8c-0.2-2.2,0.1-4,0.8-5.2c0.7-1.2,1.8-1.8,3.2-1.9c1.1-0.1,2.2,0.2,3.2,1
									c-0.2-2-0.5-3.5-0.8-4.5c0.9-0.5,2-0.8,3.2-0.8c0,2.5,0.1,6.7,0.5,12.6c0.1,0.8,0.2,1.5,0.3,1.9s0.5,0.9,0.9,1.4
									c-0.3,0.4-0.7,0.7-1.1,0.9s-0.8,0.4-1.3,0.5c-0.5-0.4-0.9-0.9-1.1-1.6c-0.4,0.6-0.9,1-1.5,1.4c-0.5,0.3-1.1,0.5-1.7,0.6
									C1983.4,1515,1982.3,1514.6,1981.5,1513.5z M1987.8,1511.7c0-0.6-0.1-1.7-0.2-3.2c-0.1-1.6-0.1-2.8-0.2-3.7
									c-0.6-0.4-1.4-0.6-2.2-0.5c-0.9,0.1-1.5,0.5-2,1.2c-0.4,0.8-0.6,1.8-0.5,3.2c0.1,1.3,0.4,2.3,0.8,3.1s1.1,1.2,1.9,1.3
									C1986.2,1513,1987,1512.5,1987.8,1511.7z"
						/>
						<path
							d="M1996.2,1513.3c-0.8-0.5-1.4-1.2-1.9-2.1s-0.8-2-0.9-3.2c-0.1-1.4,0.1-2.6,0.5-3.7c0.4-1.1,1.1-2,1.9-2.6
									c0.8-0.7,1.8-1,3-1.1c1.5-0.1,2.8,0.4,3.8,1.5c1,1.1,1.6,2.6,1.7,4.4c0.1,1.4-0.1,2.6-0.5,3.7c-0.4,1.1-1.1,2-2,2.6
									c-0.9,0.7-1.9,1-3.1,1.1C1997.9,1514,1997,1513.8,1996.2,1513.3z M2001.4,1511.1c0.5-0.8,0.7-1.9,0.6-3.2c-0.1-1.2-0.4-2.2-0.9-3
									c-0.5-0.8-1.2-1.4-2.1-1.6c-1,0.1-1.7,0.5-2.2,1.3c-0.5,0.8-0.7,1.9-0.6,3.2c0.1,1.5,0.5,2.6,1.1,3.5c0.6,0.8,1.3,1.2,2.2,1.2
									C2000.2,1512.3,2000.9,1511.9,2001.4,1511.1z"
						/>
						<path
							d="M2008.5,1512.5c-0.3-0.4-0.4-1-0.5-1.9c-0.1-0.6-0.1-1.7-0.2-3.1c-0.1-1.6-0.2-2.9-0.3-3.9c-0.3-3.3-0.6-5.7-1.1-7.1
									c0.5-0.3,1-0.5,1.5-0.6c0.5-0.1,1.1-0.2,1.7-0.3c0,2.2,0.2,5.9,0.6,11c0.1,1.1,0.2,2,0.3,2.6c0.1,0.6,0.2,1.1,0.4,1.4
									c0.2,0.3,0.4,0.7,0.7,1c-0.3,0.4-0.6,0.7-0.9,0.9c-0.3,0.2-0.7,0.3-1,0.3C2009.2,1513,2008.8,1512.9,2008.5,1512.5z"
						/>
						<path
							d="M2014.7,1516.8c0.3-0.6,0.5-1.1,0.7-1.7c0.1-0.5,0.2-1.2,0.2-2c0-0.8,0-1.9-0.1-3.3c-0.1-1.6-0.4-4.2-0.9-8
									c-0.7,0-1.1,0-1.3-0.1s-0.3-0.3-0.4-0.7c0.5-0.3,1-0.7,1.5-1.2c0.1-1.7,0.5-3.1,1.3-4.2c0.8-1.1,1.7-1.7,3-1.8
									c1-0.1,1.9,0,2.7,0.2c0,0.4,0,0.9-0.1,1.5s-0.2,1.2-0.4,1.6c-0.6-0.5-1.2-0.9-1.8-1.2c-0.6-0.3-1-0.4-1.4-0.4
									c-0.3,0.2-0.4,0.7-0.5,1.3c-0.1,0.6-0.1,1.6,0,2.9c1.3-0.2,2.2-0.3,2.7-0.5c0.1,0.8,0,1.6-0.2,2.4c-0.6-0.2-1.4-0.2-2.4-0.2
									l0.2,1.9c0.1,1.4,0.3,2.8,0.5,4.4c0.1,0.9,0.2,1.6,0.3,2.2c0.1,1,0.1,1.9-0.1,2.6s-0.4,1.4-0.9,2.1c-0.4,0.7-1.1,1.5-2,2.4
									L2014.7,1516.8z"
						/>
					</g>
					<g className="level_one location " id="dead_marshes">
						<rect x="1402.178" y="1834" width="91.048" height="40.346" transform="matrix(0.842333, 0.538957, -0.538957, 0.842333, 1828.232056, -885.082275)"></rect>
						<path
							d="M2017.1,1435.3c-0.6-0.5-1.2-1-1.6-1.4c0.6-0.8,1.3-1.8,2.3-3.2c0.9-1.4,1.8-2.7,2.6-4c1.8-2.8,2.8-4.6,2.9-5.4
									c0.6,0.1,1.3,0.3,2.2,0.7c0.9,0.4,1.7,0.8,2.6,1.4c1.9,1.2,3,2.7,3.3,4.6c0.3,1.8-0.2,3.8-1.6,6c-0.9,1.4-1.9,2.4-3.1,3.1
									c-1.2,0.7-2.5,1-3.8,1c-1.3,0-2.6-0.4-3.9-1.2C2018.4,1436.3,2017.7,1435.8,2017.1,1435.3z M2023.1,1435.9
									c0.8-0.2,1.6-0.6,2.3-1.1c0.7-0.5,1.3-1.2,1.8-2c2.3-3.6,2-6.3-0.7-8c-0.4-0.3-0.9-0.5-1.4-0.6c-1.3,1.8-2.5,3.7-3.8,5.6
									c-1,1.5-1.8,2.9-2.4,4c0.8,1,1.5,1.7,2.1,2C2021.6,1436.1,2022.3,1436.1,2023.1,1435.9z"
						/>
						<path
							d="M2029.1,1441.4c-0.1-1.3,0.4-2.8,1.4-4.4c0.7-1.1,1.5-2,2.5-2.6c0.9-0.6,1.9-1,2.9-1c1,0,2,0.2,2.9,0.8
									c1.2,0.7,1.8,1.8,1.8,3c0,1.3-0.5,2.8-1.6,4.6c-0.9-0.3-2-0.7-3.1-1.2c-1.2-0.5-2.3-1.1-3.4-1.7c-0.4,1-0.6,1.8-0.5,2.4
									c0.1,0.6,0.5,1.2,1.3,1.6c0.6,0.4,1.2,0.7,1.8,0.8s1.2,0.2,1.8,0.1l-0.3,1c-0.9,0.4-1.8,0.6-2.8,0.5c-1-0.1-1.9-0.3-2.7-0.8
									C2029.9,1443.7,2029.2,1442.7,2029.1,1441.4z M2035.3,1438.6c0.8,0.2,1.4,0.4,1.9,0.4c0.5-0.8,0.7-1.7,0.7-2.5
									c-0.1-0.8-0.4-1.4-1-1.8c-0.6,0-1.3,0.3-2,0.9c-0.7,0.5-1.3,1.3-1.9,2.1C2033.8,1438.1,2034.6,1438.4,2035.3,1438.6z"
						/>
						<path
							d="M2038.6,1448.2c-0.1-0.7,0.1-1.4,0.5-2c0.7-1.1,1.6-1.6,2.8-1.7s2.7,0.4,4.5,1.3c0.6-0.9,0.8-1.7,0.8-2.3
									c0-0.6-0.4-1.1-1.1-1.5c-0.4-0.3-1-0.5-1.5-0.6c-0.6-0.1-1.1-0.2-1.7-0.1l0.3-0.9c0.9-0.5,1.8-0.8,2.8-0.8c1,0,1.8,0.2,2.5,0.6
									c1,0.6,1.6,1.4,1.7,2.3c0.2,0.9-0.1,1.9-0.8,3c-0.4,0.6-0.8,1.3-1.4,2c-0.6,0.8-1,1.4-1.2,1.8c-0.4,0.6-0.7,1.2-0.8,1.6
									c-0.1,0.4-0.1,1-0.1,1.6c-0.9,0.2-1.8,0.1-2.6-0.3c-0.2-0.6-0.2-1.2,0-1.9c-0.8,0.1-1.5,0.1-2.2,0c-0.7-0.1-1.3-0.3-1.7-0.6
									C2039,1449.3,2038.6,1448.8,2038.6,1448.2z M2042.6,1449.2c0.5,0,0.9,0,1.4,0l0.1-0.2c0.3-0.5,0.6-1,1-1.4l0.7-1
									c-0.9-0.5-1.8-0.7-2.5-0.6c-0.7,0.1-1.3,0.4-1.7,1.1c-0.2,0.3-0.3,0.6-0.3,0.9c0,0.3,0,0.7,0.1,0.9
									C2041.7,1449.1,2042.1,1449.2,2042.6,1449.2z"
						/>
						<path
							d="M2049.3,1453.9c0-1.3,0.5-2.8,1.5-4.6c1.1-1.9,2.3-3.1,3.5-3.6c1.2-0.6,2.4-0.5,3.6,0.3c0.9,0.6,1.6,1.4,2,2.5
									c0.9-1.7,1.6-3,1.9-4c1,0.1,2,0.5,3,1.1c-1.5,2-3.7,5.4-6.7,10.3c-0.4,0.7-0.7,1.2-0.8,1.7c-0.1,0.5-0.1,1-0.1,1.6
									c-0.4,0.1-0.9,0.1-1.4,0.1c-0.5,0-0.9-0.1-1.3-0.3c-0.2-0.6-0.2-1.2,0-1.9c-0.7,0.2-1.3,0.3-1.9,0.2c-0.6,0-1.2-0.2-1.7-0.5
									C2049.9,1456.2,2049.3,1455.2,2049.3,1453.9z M2055.2,1456c0.3-0.5,0.9-1.4,1.6-2.6c0.8-1.3,1.5-2.3,1.9-3
									c-0.3-0.7-0.8-1.2-1.5-1.6c-0.7-0.5-1.5-0.5-2.2-0.1c-0.8,0.4-1.5,1.1-2.2,2.3c-0.6,1.1-1,2-1.1,2.9c-0.1,0.9,0.2,1.6,0.7,2.1
									C2053.3,1456.2,2054.2,1456.2,2055.2,1456z"
						/>
						<path
							d="M2004.1,1449.5c1.2-1.7,2.8-4,4.8-7.2c1-1.5,1.7-2.7,2.2-3.6c0.5-0.9,0.9-1.6,1.1-2.2c0.4,0.1,0.9,0.3,1.3,0.6
									c0.4,0.2,0.8,0.6,1.2,1c-0.3,1.6-0.7,3.5-1.2,5.8c-0.5,2.3-0.8,4.2-1.1,5.7c1.4-1,3-2.1,5-3.6c1.9-1.4,3.4-2.5,4.5-3.3
									c0.4,0.1,0.8,0.3,1.3,0.6c0.4,0.2,0.8,0.6,1.3,1c-0.6,0.8-1.4,1.8-2.3,3.2c-0.9,1.4-1.8,2.7-2.6,4c-1.9,3-3,4.9-3.2,5.8
									c-0.5-0.2-1-0.4-1.4-0.7c-0.4-0.2-0.8-0.6-1.2-1c0.6-0.8,1.4-1.8,2.3-3.2c0.9-1.4,1.8-2.7,2.6-4c0.4-0.7,1-1.5,1.6-2.5
									c-3,2.1-6.3,4.5-10.1,7.4c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.1-0.3-0.2-0.4-0.4c0.9-4.1,1.7-7.9,2.3-11.3c-1,1.5-1.8,2.7-2.3,3.5
									c-1.9,2.9-3,4.8-3.3,5.7c-0.4-0.1-0.7-0.2-1-0.4C2004.7,1450.1,2004.4,1449.8,2004.1,1449.5z"
						/>
						<path
							d="M2020.1,1459.2c-0.1-0.6,0.1-1.3,0.5-2c0.6-1,1.5-1.5,2.7-1.6c1.2-0.1,2.6,0.4,4.3,1.3c0.6-0.9,0.8-1.6,0.8-2.2
									s-0.4-1-1-1.4c-0.4-0.3-0.9-0.5-1.5-0.6c-0.6-0.1-1.1-0.2-1.6-0.1l0.3-0.9c0.9-0.5,1.8-0.7,2.7-0.7c0.9,0,1.8,0.2,2.4,0.6
									c0.9,0.6,1.5,1.3,1.6,2.2c0.1,0.9-0.1,1.9-0.8,2.9c-0.4,0.6-0.8,1.2-1.4,1.9c-0.6,0.7-1,1.3-1.2,1.7c-0.4,0.6-0.6,1.1-0.8,1.5
									s-0.1,0.9-0.1,1.5c-0.9,0.2-1.7,0.1-2.5-0.3c-0.2-0.6-0.2-1.2,0-1.8c-0.8,0.1-1.5,0.1-2.2,0c-0.7-0.1-1.2-0.3-1.7-0.6
									C2020.5,1460.3,2020.1,1459.8,2020.1,1459.2z M2024,1460.3c0.5,0,0.9,0,1.4,0l0.1-0.2c0.3-0.5,0.6-0.9,1-1.4l0.7-0.9
									c-0.9-0.5-1.7-0.7-2.4-0.6c-0.7,0.1-1.2,0.4-1.6,1c-0.2,0.3-0.3,0.6-0.3,0.9c0,0.3,0,0.6,0.1,0.9
									C2023.1,1460.1,2023.5,1460.2,2024,1460.3z"
						/>
						<path
							d="M2030.4,1466.3c1.1-1.6,2.3-3.3,3.5-5.1c0.6-0.9,1-1.6,1.2-2.1s0.4-0.9,0.5-1.3s0.1-0.7,0-1.2c0.4-0.2,0.8-0.2,1.2-0.2
									s0.7,0.1,0.9,0.3c0.9,0.5,0.9,1.4,0.2,2.7c1.4-0.6,2.6-0.8,3.8-0.4c-0.1,0.4-0.3,0.9-0.5,1.5s-0.6,1.1-0.9,1.6
									c-0.2-0.2-0.4-0.4-0.7-0.5c-0.4-0.3-0.9-0.5-1.3-0.6c-0.5-0.1-0.9-0.2-1.3-0.1c-0.8,1.2-1.5,2.2-2.1,3.3c-1.1,1.7-1.7,3-1.9,3.9
									c-0.5-0.2-0.9-0.4-1.4-0.7C2031.2,1467,2030.8,1466.7,2030.4,1466.3z"
						/>
						<path
							d="M2037.6,1470c0.4-0.6,0.9-1.2,1.6-1.7c0,0.6,0.2,1.1,0.5,1.6c0.3,0.5,0.7,1,1.3,1.3c1.2,0.8,2.1,0.8,2.5,0
									c0.2-0.3,0.2-0.7,0.1-1.1s-0.4-0.9-0.8-1.6c-0.4-0.7-0.8-1.3-1-1.8c-0.2-0.5-0.3-1.1-0.3-1.7c0-0.6,0.2-1.3,0.6-1.9
									c0.5-0.8,1.2-1.3,2.2-1.4s1.9,0.1,2.9,0.8c1.3,0.8,2.2,1.7,2.8,2.6c-0.2,0.3-0.5,0.7-1,1.1c-0.5,0.4-0.9,0.8-1.3,1
									c-0.2-0.9-0.4-1.6-0.7-2.2c-0.3-0.6-0.6-1-0.9-1.2c-0.3-0.1-0.6-0.1-0.9-0.1s-0.5,0.2-0.6,0.4c-0.2,0.4-0.3,0.8-0.2,1.2
									c0.1,0.4,0.4,1,0.9,1.7c0.4,0.7,0.7,1.2,0.9,1.7s0.3,1,0.3,1.6c0,0.6-0.2,1.2-0.6,1.8c-0.6,0.9-1.4,1.5-2.4,1.6s-2-0.2-3.2-0.9
									C2039,1472.1,2038.1,1471.1,2037.6,1470z"
						/>
						<path
							d="M2046.9,1476.8c1.4-2,2.8-4.1,4.1-6.2c1.8-2.8,3.1-5.1,4-6.8c1,0.1,1.9,0.5,2.9,1.1c-0.8,1-1.9,2.5-3.3,4.5
									c0.7-0.3,1.4-0.4,2-0.3c0.7,0,1.3,0.2,1.8,0.5c1.6,1,1.7,2.7,0.3,4.9l-1,1.5l-1.1,1.6c-1.1,1.7-1.7,3-1.9,3.9
									c-0.5-0.1-0.9-0.4-1.4-0.7c-0.4-0.2-0.8-0.6-1.2-1c1.4-2.1,2.8-4.1,4-6c0.4-0.7,0.6-1.2,0.6-1.6c0-0.4-0.3-0.8-0.8-1.1
									c-0.7-0.4-1.4-0.6-2.1-0.5l-1.2,1.8c-1.9,2.9-2.9,4.9-3.1,5.8c-0.5-0.2-1-0.4-1.4-0.7C2047.8,1477.5,2047.4,1477.2,2046.9,1476.8z
									"
						/>
						<path
							d="M2059.2,1483.1c-0.1-1.2,0.4-2.7,1.4-4.3c0.7-1.1,1.5-1.9,2.4-2.5c0.9-0.6,1.9-0.9,2.8-1c1,0,1.9,0.2,2.8,0.8
									c1.1,0.7,1.7,1.7,1.8,3c0,1.2-0.5,2.7-1.6,4.4c-0.9-0.3-1.9-0.6-3-1.1c-1.1-0.5-2.2-1.1-3.2-1.7c-0.4,0.9-0.6,1.7-0.5,2.3
									c0.1,0.6,0.5,1.1,1.2,1.6c0.6,0.4,1.2,0.6,1.8,0.8c0.6,0.2,1.2,0.2,1.8,0.1l-0.3,0.9c-0.8,0.4-1.8,0.5-2.8,0.5
									c-1-0.1-1.9-0.3-2.6-0.8C2059.9,1485.3,2059.3,1484.4,2059.2,1483.1z M2065.2,1480.5c0.7,0.2,1.4,0.3,1.9,0.4
									c0.5-0.8,0.7-1.6,0.6-2.4c0-0.8-0.4-1.3-0.9-1.7c-0.6,0-1.2,0.3-1.9,0.8c-0.7,0.5-1.3,1.2-1.8,2.1
									C2063.7,1480,2064.5,1480.3,2065.2,1480.5z"
						/>
						<path
							d="M2068.4,1489.5c0.4-0.6,0.9-1.2,1.6-1.7c0,0.6,0.2,1.1,0.5,1.6s0.7,1,1.3,1.3c1.2,0.8,2.1,0.8,2.5,0
									c0.2-0.3,0.2-0.7,0.1-1.1s-0.4-0.9-0.8-1.6c-0.4-0.7-0.8-1.3-1-1.8c-0.2-0.5-0.3-1.1-0.3-1.7c0-0.6,0.2-1.3,0.6-1.9
									c0.5-0.8,1.2-1.3,2.2-1.4c0.9-0.1,1.9,0.1,2.9,0.8c1.3,0.8,2.2,1.7,2.8,2.6c-0.2,0.3-0.5,0.7-1,1.1c-0.5,0.4-0.9,0.8-1.3,1
									c-0.2-0.9-0.4-1.6-0.7-2.2c-0.3-0.6-0.6-1-0.9-1.2c-0.3-0.1-0.6-0.1-0.9-0.1s-0.5,0.2-0.6,0.4c-0.2,0.4-0.3,0.8-0.2,1.2
									s0.4,1,0.9,1.7c0.4,0.7,0.7,1.2,0.9,1.7s0.3,1,0.3,1.6c0,0.6-0.2,1.2-0.6,1.8c-0.6,0.9-1.4,1.5-2.4,1.6c-1,0.1-2-0.2-3.2-0.9
									C2069.8,1491.6,2068.9,1490.6,2068.4,1489.5z"
						/>
					</g>
					<g id="marshes">
						<g>
							<path
								d="M2085.6,1476.9c-3.3,1.2-6.5,1.3-9.8,0c-0.6-0.2-0.7-0.6-0.5-1c-0.5-0.2-1-0.3-1.4-0.5c0,0,0,0,0,0
										c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1-0.1-0.2-0.1-0.2-0.2c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2,0
										c-4.2-0.6-2.5-7-1.8-9.8c0.2-0.9,2.6-1.9,2.9-0.5c0.4,1.9,0.7,3.8,1.1,5.6c0.6-1,1.1-2.1,1.6-3.2c0.4-0.9,2.9-1.4,2.8,0
										c-0.1,1.9-0.6,3.5-1.6,5c0.8-0.5,1.6-1.2,2.2-2.1c0.6-0.8,3.3-0.9,2.6,0.5c-0.9,1.8-2.3,3.1-3.9,3.9c2.1,0.5,4.2,0.8,6.4,0.6
										C2086.4,1474.9,2086.9,1476.4,2085.6,1476.9z"
							/>
						</g>
						<g>
							<path
								d="M2073.1,1475.1c0.1,0.1,0.1,0.1,0.2,0.2C2073.2,1475.2,2073.1,1475.1,2073.1,1475.1
										C2073,1475.1,2073.1,1475.1,2073.1,1475.1z"
							/>
						</g>
						<g>
							<path d="M2073.8,1475.4c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.1,0C2073.5,1475.3,2073.6,1475.4,2073.8,1475.4z" />
						</g>
					</g>
					<g id="marshes">
						<g>
							<path
								d="M2071,1457.7c-1.5-2.2-1.8-4.6-1-7.1c-1,0.2-1.9,0.3-2.9,0.5c0.6,7.8,9.3,16.2,17.1,11.3c0.9-0.5,0.5-1.6-0.5-1.7
										c-2.5-0.2-5.8,0.1-8.2-0.7c-3.5-1.1-1.6-3.2-0.5-4.9c0.8-1.2,1.6-2.3,2.4-3.5c-0.9-0.1-1.8-0.2-2.7-0.3c-0.6,2.1-1.1,4.2-1.7,6.4
										c-0.3,0.9,1.2,1,1.7,0.9c2.7-0.6,5.1-1.7,7.3-3.4c-0.7-0.4-1.4-0.7-2.2-1.1c-1.8,2.3-4.1,3.8-6.9,4.5c-1.8,0.4-1.3,2.4,0.5,2
										c3.6-0.9,6.6-2.7,8.8-5.6c1.1-1.4-1.3-1.8-2.2-1.1c-1.8,1.4-3.7,2.3-5.9,2.7c0.6,0.3,1.2,0.6,1.7,0.9c0.6-2.1,1.1-4.2,1.7-6.4
										c0.4-1.3-2.2-1-2.7-0.3c-1.4,2.2-6.4,7.2-4,9.9c0.9,1,2.2,1.3,3.5,1.4c2.7,0.3,5.5,0.5,8.2,0.7c-0.2-0.6-0.3-1.1-0.5-1.7
										c-3.2,2-7.6-1.1-9.5-3.5c-1.7-2.1-2.6-4.6-2.8-7.3c-0.1-1.4-2.7-0.4-2.9,0.5c-0.8,2.9-0.3,5.7,1.3,8.2
										C2069.1,1460,2071.9,1458.9,2071,1457.7L2071,1457.7z"
							/>
						</g>
					</g>
					<g id="marshes">
						<g>
							<path
								d="M2072,1431.9c-0.8,2.3-1.4,7.8,2.2,7.8c3.3,0,6.4-4.1,8.4-6.2c-0.9,0.1-1.8,0.2-2.8,0.3c-0.8,2.5-2.5,4.6-5.2,5.1
										c-0.3,0-2.5,0.9-1.4,1.2c3.8,1.2,7.6,1.1,11.5,0.2c-0.3-0.3-0.7-0.5-1-0.8c-2,1.2-4.5,2.3-6.6,0.7c-0.9,0.3-1.8,0.7-2.7,1
										c3.5,1.9,7,3.3,10.9,2c-0.5-0.1-1-0.2-1.6-0.3c-0.6,0.2-1.2,0.4-1.8,0.6c-0.5-0.2-1-0.5-1.5-0.8c-0.6-0.4-1.2-0.8-1.7-1.2
										c-1.1-0.9-2.1-1.9-3.1-3c-0.5-0.6-3.2,0.4-2.7,1c2,2.3,5,5.3,8.3,5.5c2,0.1,4.4-0.7,5.3-2.7c0.3-0.6-1.4-0.3-1.6-0.3
										c-2.8,0.9-5.6-0.7-7.9-2c-0.6-0.3-3.3,0.6-2.7,1c3.4,2.6,7.8,0.7,11-1.2c1.4-0.8-0.2-1-1-0.8c-3,0.8-5.9,0.6-8.8-0.3
										c-0.5,0.4-1,0.8-1.4,1.2c3.9-0.6,7.4-2.8,8.6-6.7c0.3-0.9-2.5,0-2.8,0.3c-0.3,0.3-4.7,5-5.1,4.8c-0.1-0.1-0.5-2.6-0.5-2.8
										c-0.2-1.4,0.1-2.8,0.5-4.1C2075.1,1430.8,2072.3,1431.1,2072,1431.9L2072,1431.9z"
							/>
						</g>
					</g>
					<g id="marshes">
						<g>
							<path
								d="M2039,1419.7c0.2,2.2-0.4,7.6,2.9,7.4c3.2-0.2,4.5-4.6,5.7-6.9c-0.9,0-1.8-0.1-2.7-0.1c-1.1,2.1-2.2,4.1-3.4,6.2
										c-0.2,0.4,0,0.8,0.5,0.9c4.5,0.7,8.9-0.5,12.2-3.6c1.3-1.2-1.5-1-2.1-0.7c-2.6,1.4-5.2,2.8-8.2,3.3c-0.5,0.1-2.4,0.9-1.3,1.5
										c3.4,2.1,7.1,2.9,11.1,2.6c-0.1-0.5-0.3-1-0.4-1.5c-3.5,1.7-7.4,0.5-9.9-2.3c-0.7-0.8-3.5,0.3-2.7,1.1c3.5,3.9,9.2,4.6,13.8,2.4
										c1.2-0.6,1-1.5-0.4-1.5c-3.3,0.2-6.3-0.7-9.1-2.4c-0.4,0.5-0.9,1-1.3,1.5c3.8-0.6,7-2.2,10.4-4c-0.7-0.2-1.4-0.5-2.1-0.7
										c-2.3,2.1-5,3-8.1,2.5c0.2,0.3,0.3,0.6,0.5,0.9c1.1-2.1,2.2-4.1,3.4-6.2c0.7-1.2-2.2-0.9-2.7-0.1c-1,1.8-2,4.6-3.6,5.9
										c0.6-0.2,1.1-0.4,1.7-0.6c-0.8,0-1-5.8-1.1-6.5C2041.8,1417.9,2038.9,1418.6,2039,1419.7L2039,1419.7z"
							/>
						</g>
					</g>
					<g id="marshes">
						<g>
							<path
								d="M2051.4,1492.9c1.5,1.9,3.1,3.7,4.6,5.6c0.5,0.6,2.3,0,2.7-0.6c1.4-2,2.8-4,4.2-6.1c-0.9,0.1-1.9,0.1-2.8,0.2
										c-0.6,2.2-1.7,3.9-3.5,5.4c-0.4,0.4-0.4,0.9,0.3,0.9c2.9,0.3,5.5-0.2,8.1-1.7c-0.7-0.2-1.4-0.4-2-0.6c-1.6,1.2-3.4,1.8-5.4,1.6
										c-0.5,0-3.3,0.9-2.1,1.4c3.6,1.5,7.3,2.3,11.2,2.5c0.1-0.5,0.3-1,0.4-1.4c-4,1.1-8-0.4-10.8-3.3c-0.7-0.7-3.4,0.4-2.7,1.1
										c3.7,3.8,8.8,4.9,13.8,3.5c0.9-0.2,2-1.4,0.4-1.4c-3.5-0.2-6.8-1-10.1-2.3c-0.7,0.5-1.4,0.9-2.1,1.4c3.5,0.3,6.7-0.5,9.5-2.7
										c1.3-1-1.5-0.9-2-0.6c-1.4,0.9-2.9,1.2-4.5,1c0.1,0.3,0.2,0.6,0.3,0.9c2-1.7,3.3-3.7,4.1-6.2c0.3-1.1-2.4-0.3-2.8,0.2
										c-1.4,2-2.8,4-4.2,6.1c0.9-0.2,1.8-0.4,2.7-0.6c-1.5-1.9-3.1-3.7-4.6-5.6C2053.5,1491.1,2050.8,1492.1,2051.4,1492.9
										L2051.4,1492.9z"
							/>
						</g>
					</g>
					<g id="marshes">
						<g>
							<path
								d="M2028.3,1484.9c0.5,3,2.1,5.4,4.6,7.1c0.8,0.5,2.3,0,2.6-0.9c0.8-2.9,1.6-5.7,2.3-8.6c-1,0.1-1.9,0.2-2.9,0.3
										c-0.2,3.1-1.1,5.8-2.7,8.5c-0.3,0.6,0.3,1.1,0.8,1c3.6,0,6.8-1.5,8.9-4.4c0.9-1.2-2-1-2.6-0.4c-1.6,1.6-3.4,2.7-5.5,3.6
										c-0.6,0.2-1.2,1-0.5,1.5c3.2,2.4,7.4,2.2,10.6,0c-0.6-0.4-1.2-0.7-1.9-1.1c-3.1,2.5-6.7,1.1-8.5-2c-0.6-1-3.4,0-2.7,1.2
										c2.7,4.8,9,5.3,13,1.9c1.5-1.3-0.8-1.8-1.9-1.1c-2.2,1.5-4.5,1.1-6.5-0.5c-0.2,0.5-0.3,1-0.5,1.5c2.6-1,4.8-2.4,6.7-4.3
										c-0.9-0.1-1.7-0.3-2.6-0.4c-1.2,1.7-3,2.7-5.1,2.8c0.3,0.3,0.6,0.7,0.8,1c1.7-2.8,2.6-5.7,2.9-9c0.1-1.4-2.6-0.8-2.9,0.3
										c-0.8,2.9-1.6,5.7-2.3,8.6c0.9-0.3,1.7-0.6,2.6-0.9c-2.2-1.5-3.5-3.7-3.9-6.3C2031,1482.9,2028.1,1483.6,2028.3,1484.9
										L2028.3,1484.9z"
							/>
						</g>
					</g>
					<g id="marshes">
						<g>
							<path
								d="M2023.7,1472.1c-0.9,1.9-2.6,7.3,1,7.5c1.3,0.1,2.3-0.7,3.1-1.6c1.6-1.7,3.2-3.7,4.7-5.5c-0.9-0.2-1.7-0.4-2.6-0.6
										c-1.4,2.3-3.2,4.2-5.7,5.4c-0.9,0.5-1.2,1.8,0.1,1.9c3.4,0.3,6.8-0.1,10-1.3c-0.4-0.5-0.8-1.1-1.2-1.6c-1.5,1.2-7.3,3.8-7.7,0.2
										c-0.1-1.3-3-0.6-2.9,0.8c0.3,2.5,3.1,3.1,5.2,3c2.7,0,5.2-1,7.3-2.7c1.1-0.9,0-2-1.2-1.6c-2.8,1-5.5,1.4-8.5,1.2
										c0,0.6,0.1,1.2,0.1,1.9c3-1.5,5.4-3.8,7.1-6.8c0.8-1.4-2-1.3-2.6-0.6c-0.9,1-1.7,2-2.6,3c-0.3,0.4-2,2.7-2.3,2.7
										c0.3,0.1,0.5,0.3,0.8,0.4c-1.2-1.2-0.1-4.2,0.5-5.4C2027.2,1471,2024.3,1471,2023.7,1472.1L2023.7,1472.1z"
							/>
						</g>
					</g>
					<g id="marshes">
						<g>
							<path
								d="M2004.2,1461.3c0.8,2.7,1.6,5.4,2.4,8.1c0.2,0.7,2.5-0.1,2.8-0.5c2-2.6,3.9-5.2,5.9-7.7c-0.9,0.1-1.8,0.2-2.7,0.3
										c-1.1,2.7-2.9,4.9-5.3,6.6c-0.2,0.2-0.5,0.6-0.1,0.7c4.6,1,9.3-0.3,12.8-3.4c0.7-0.6-2-0.3-2.4,0c-1.7,1.3-8.7,6.1-8.9,1.7
										c0-0.6-2.9-0.1-2.9,0.8c0.3,6.7,12.1-0.9,14-2.3c-0.8,0-1.6,0-2.4,0c-2.2,2-4.8,2.7-7.8,2.1c0,0.2,0,0.4-0.1,0.7
										c2.7-1.8,4.7-4.2,5.9-7.2c0.3-0.7-2.4-0.1-2.7,0.3c-2,2.6-3.9,5.2-5.9,7.7c0.9-0.2,1.8-0.3,2.8-0.5c-0.8-2.7-1.6-5.4-2.4-8.1
										C2006.9,1459.8,2004,1460.6,2004.2,1461.3L2004.2,1461.3z"
							/>
						</g>
					</g>
					<g id="marshes">
						<g>
							<path
								d="M2019.7,1540.3c1.6,1.1,3.1,2.1,4.7,3.2c0.6,0.4,2.5,0,2.6-0.9c0.2-2.5-0.1-4.9-0.8-7.3c-1,0.3-1.9,0.5-2.9,0.8
										c0.7,2.1,1.5,4.2,2.2,6.3c0.4,1,2.5,0.5,2.8-0.3c1-2,1.9-4.1,2.9-6.1c-0.9,0-1.9,0-2.8,0.1c-0.2,2.6-1,4.9-2.5,7
										c-0.4,0.6,0.3,1,0.8,1c4.6,0,8.6-2.4,10.2-6.8c0.4-1.2-2.2-0.6-2.6-0.2c-1.6,2-3.6,3.2-6.2,3.6c-1.5,0.2-2.3,2-0.3,1.8
										c3.6-0.4,6.6-2,8.9-4.7c-0.9-0.1-1.8-0.1-2.6-0.2c-0.9,2.7-3.2,4.7-6.2,4.7c0.3,0.3,0.5,0.7,0.8,1c1.6-2.3,2.5-4.7,2.7-7.5
										c0.1-1.3-2.5-0.7-2.8,0.1c-1,2-1.9,4.1-2.9,6.1c0.9-0.1,1.9-0.2,2.8-0.3c-0.7-2.1-1.5-4.2-2.2-6.3c-0.4-1.1-3.3-0.3-2.9,0.8
										c0.7,2.3,1,4.4,0.8,6.8c0.9-0.3,1.7-0.6,2.6-0.9c-1.6-1.1-3.1-2.1-4.7-3.2C2021.1,1538.2,2018.4,1539.4,2019.7,1540.3
										L2019.7,1540.3z"
							/>
						</g>
					</g>
					<g id="marshes">
						<g>
							<path
								d="M1990.5,1547.4c2.1,0.9,3.5,2.3,4.6,4.3c0.5,1,2.9,0.4,2.8-0.7c-0.2-3.1-0.4-6.2-0.5-9.3c-1,0.2-1.9,0.3-2.9,0.5
										c0.8,2.7,1.6,5.5,2.5,8.2c0.3,1,1.8,0.8,2.4,0.3c2.3-1.9,4.1-4,5.4-6.6c0.8-1.5-2.1-1.5-2.8-0.4c-1.3,2.2-2.6,4.4-3.9,6.6
										c-0.5,0.8,0.7,1.3,1.3,1.3c4.3-0.3,7.8-2.6,9.8-6.4c0.8-1.5-2.2-1.6-2.8-0.4c-2,3.7-5.7,5.9-9.9,5.5c-1.5-0.1-2.9,2-1,2.2
										c5.7,0.4,10.9-2.3,13.7-7.4c-0.9-0.1-1.9-0.3-2.8-0.4c-1.4,2.7-3.8,4.3-6.8,4.6c0.4,0.4,0.9,0.9,1.3,1.3c1.3-2.2,2.6-4.4,3.9-6.6
										c-0.9-0.1-1.9-0.3-2.8-0.4c-1.2,2.2-2.6,4-4.6,5.6c0.8,0.1,1.6,0.2,2.4,0.3c-0.8-2.7-1.6-5.5-2.5-8.2c-0.4-1.2-3-0.8-2.9,0.5
										c0.2,3.1,0.4,6.2,0.5,9.3c0.9-0.2,1.9-0.5,2.8-0.7c-1.3-2.4-3.1-3.9-5.6-5C1990.9,1544.8,1988.8,1546.6,1990.5,1547.4
										L1990.5,1547.4z"
							/>
						</g>
					</g>
					<g id="marshes">
						<g>
							<path
								d="M1929.6,1490.4c3.1,2.3,6.2,4.7,9.2,7c0.5,0.4,2.6-0.1,2.8-0.7c1-4,2-8,3-12.1c-1,0.2-1.9,0.4-2.9,0.6
										c-0.4,3.3-0.9,6.5-1.3,9.8c-0.1,0.6,2.6-0.2,2.9-0.6c2.1-3.3,4.1-6.5,6.2-9.8c-1,0.2-1.9,0.3-2.9,0.5c-1.3,4.3-3.6,7.8-7,10.7
										c-0.7,0.6,2.1,0.2,2.4-0.1c3.8-2.3,7.5-4.6,11.3-6.9c-0.8,0-1.6,0-2.4,0.1c-1.3,1.5-11.4,10.2-11.9,5c-0.8,0.3-1.6,0.7-2.4,1
										c1.6,0.1,3.1,0.3,4.7,0.4c0.5,0,3-0.9,2.1-1.2c-2.7-0.6-1-2.5-0.1-4.2c0.2-0.4-2.4-0.2-2.9,0.5c-0.3,0.5-0.6,1-1,1.6
										c1-0.2,1.9-0.4,2.9-0.6c0-1.1,0.1-2.3,0.1-3.4c-1,0.3-1.9,0.5-2.9,0.8c0.4,1-0.6,3.6-2,3.5c-0.6,0-1.6-1.1-2.1-1.5
										c-1.1-0.8-2-1.4-3.2-1.8c-0.7-0.2-3.2,0.8-2.4,1.1c3.4,1.1,5.1,4.5,9.1,3.6c2.8-0.7,4.6-2.7,3.5-5.7c-0.2-0.7-2.9,0.1-2.9,0.8
										c0,1.1-0.1,2.3-0.1,3.4c0,0.7,2.6-0.2,2.9-0.6c0.3-0.5,0.6-1,1-1.6c-1,0.2-1.9,0.3-2.9,0.5c-0.7,1.3-1.5,2.6-2.2,3.9
										c-0.1,0.2,0.1,0.3,0.2,0.3c0.8,0.2,1.7,0.4,2.5,0.6c0.7-0.4,1.4-0.8,2.1-1.2c-1.6-0.1-3.1-0.3-4.7-0.4c-0.5,0-2.5,0.3-2.4,1
										c0.3,3.4,4.5,2.2,6.6,1.4c4.2-1.5,7.8-4.1,10.8-7.5c0.7-0.8-2.1-0.1-2.4,0.1c-3.8,2.3-7.5,4.6-11.3,6.9c0.8,0,1.6,0,2.4-0.1
										c3.5-3,5.9-6.6,7.3-11c0.2-0.7-2.5,0-2.9,0.5c-2.1,3.3-4.1,6.5-6.2,9.8c1-0.2,1.9-0.4,2.9-0.6c0.4-3.3,0.9-6.5,1.3-9.8
										c0.1-0.4-2.7,0-2.9,0.6c-1,4-2,8-3,12.1c0.9-0.2,1.9-0.5,2.8-0.7c-3.1-2.3-6.2-4.7-9.2-7
										C1931.7,1489,1929.2,1490.1,1929.6,1490.4L1929.6,1490.4z"
							/>
						</g>
					</g>
					<g id="marshes">
						<g>
							<path
								d="M1960.8,1484c3,1.8,5.9,3.6,8.9,5.4c0.5,0.3,2.9-0.2,2.6-1.1c-1-3.4-2-6.8-2.9-10.2c-1,0.3-1.9,0.5-2.9,0.8
										c1.3,3.2,2.6,6.4,3.9,9.6c0.4,1,2.6,0.1,2.9-0.6c1.6-3.6,3.2-7.1,4.8-10.7c-1,0.1-1.9,0.3-2.9,0.4c-1.4,3.4-2.7,6.9-4.1,10.3
										c-0.4,1,2.3,0.2,2.6,0c2.5-2.1,5-4.2,7.5-6.2c-0.8-0.1-1.7-0.1-2.5-0.2c-0.5,0.9-1,1.9-1.6,2.7c-1.2,1.7-1.8,2.2-4,2.5
										c-2.4,0.4-2,0.4-3.8-0.6c-1.1-0.6-2.1-1.2-3.2-1.7c-0.8-0.4-3.4,0.7-2.3,1.3c3.2,1.8,6.1,4,9.9,3c3.8-1,5.9-4.1,7.7-7.3
										c0.7-1.2-2.1-0.5-2.5-0.2c-2.5,2.1-5,4.2-7.5,6.2c0.9,0,1.7,0,2.6,0c1.4-3.4,2.7-6.9,4.1-10.3c0.3-0.9-2.5-0.4-2.9,0.4
										c-1.6,3.6-3.2,7.1-4.8,10.7c1-0.2,1.9-0.4,2.9-0.6c-1.3-3.2-2.6-6.4-3.9-9.6c-0.4-0.9-3.2-0.1-2.9,0.8c1,3.4,2,6.8,2.9,10.2
										c0.9-0.4,1.7-0.7,2.6-1.1c-3-1.8-5.9-3.6-8.9-5.4C1962.4,1482.2,1959.7,1483.3,1960.8,1484L1960.8,1484z"
							/>
						</g>
					</g>
					<g id="marshes">
						<g>
							<path
								d="M1988.5,1486.2c2.1,1.8,4.3,3.6,6.4,5.4c0.7,0.6,2.6,0.2,2.8-0.7c0.5-2.6,1.1-5.1,1.6-7.7c-1,0.1-1.9,0.2-2.9,0.4
										c-0.2,2.5-0.4,5-0.5,7.4c-0.1,1.2,2.2,0.4,2.6,0.1c2.2-1.7,4.5-3.5,6.7-5.2c1.3-1-1.6-1.1-2.3-0.5c-2.2,1.7-4.5,3.5-6.7,5.2
										c0.9,0,1.7,0,2.6,0.1c0.2-2.5,0.4-5,0.5-7.4c0.1-1.2-2.7-0.6-2.9,0.4c-0.5,2.6-1.1,5.1-1.6,7.7c0.9-0.2,1.9-0.5,2.8-0.7
										c-2.1-1.8-4.3-3.6-6.4-5.4C1990.4,1484.4,1987.7,1485.5,1988.5,1486.2L1988.5,1486.2z"
							/>
						</g>
					</g>
					<g className="level_two fade location " id="falls_of_rauros">
						<rect x="985.154" y="1287.626" width="63.969" height="28.326" transform="matrix(0.946038, -0.324055, 0.324055, 0.946038, 560.764099, 558.486633)"></rect>
						<rect x="560.698" y="1287.629" width="36.408" height="28.326" transform="matrix(0.631029, -0.775759, 0.775759, 0.631029, 615.311707, 1057.860107)"></rect>
						<rect x="1465.218" y="1287.626" width="95.141" height="28.326" transform="matrix(0.768406, -0.639962, 0.639962, 0.768406, 39.861656, 1353.882324)"></rect>
						<path
							d="M1919.5,1478c-0.1-1-0.2-2.3-0.4-4c-0.2-1.7-0.4-3.2-0.7-4.7c-0.4-2.1-0.7-3.8-0.9-5c-0.3-1.2-0.6-2.2-0.9-2.9
									c1.2,0,2.9-0.3,5.2-0.6c2.6-0.4,4.6-0.8,6-1.2c0.1,0.6,0.2,1.3,0.1,2.1c0,0.7-0.1,1.3-0.2,1.5c-0.5-0.2-1.1-0.3-1.8-0.3
									c-0.7,0-1.5,0-2.2,0.1c-0.9,0.2-1.9,0.4-2.8,0.7c0.2,1.7,0.4,3.1,0.6,4.4c0.8-0.1,1.5-0.2,2-0.3c1-0.2,1.7-0.3,2.2-0.4
									c0.5-0.1,1.1-0.3,1.6-0.5c0.2,0.6,0.3,1,0.3,1.3c0,0.3,0.1,0.7,0.1,1.3c-2.2,0.2-3.9,0.4-5,0.6l-1,0.2c0.7,4,1.2,6.4,1.7,7.2
									c-0.6,0.2-1.2,0.4-1.9,0.5C1921,1477.9,1920.3,1478,1919.5,1478z"
						/>
						<path
							d="M1935.3,1474.4c-0.7-0.4-1.1-1-1.4-1.8c-0.4-1.3-0.2-2.4,0.8-3.5s2.6-2.1,5-3.1c-0.4-1.1-0.9-1.8-1.4-2.2
									c-0.5-0.3-1.3-0.4-2.2,0c-0.6,0.2-1.2,0.5-1.8,0.9c-0.6,0.4-1.1,0.9-1.5,1.4l-0.6-0.9c0.3-1.1,0.8-2.1,1.6-2.9
									c0.8-0.9,1.7-1.5,2.7-1.8c1.4-0.5,2.6-0.5,3.5,0c1,0.5,1.7,1.3,2.1,2.7c0.2,0.7,0.5,1.6,0.7,2.5c0.2,1,0.4,1.7,0.6,2.2
									c0.3,0.8,0.5,1.3,0.8,1.7c0.3,0.4,0.8,0.7,1.4,1.1c-0.6,0.9-1.4,1.6-2.4,2.1c-0.7-0.2-1.3-0.6-1.7-1.2c-0.6,0.7-1.2,1.4-1.9,1.9
									s-1.4,0.9-2,1.1C1936.7,1474.8,1936,1474.8,1935.3,1474.4z M1939.7,1471.6c0.4-0.4,0.8-0.8,1.1-1.3l-0.1-0.2
									c-0.2-0.6-0.4-1.2-0.5-1.8l-0.3-1.2c-1.2,0.5-2.1,1.1-2.7,1.8c-0.5,0.7-0.7,1.4-0.4,2.1c0.1,0.3,0.3,0.6,0.6,0.9
									c0.3,0.3,0.6,0.4,0.9,0.5C1938.8,1472.3,1939.3,1472,1939.7,1471.6z"
						/>
						<path
							d="M1951.2,1468.7c-0.4-0.2-0.9-0.8-1.3-1.7c-0.3-0.6-0.7-1.6-1.3-2.9c-0.7-1.5-1.2-2.7-1.7-3.6c-1.5-3.1-2.8-5.2-3.8-6.4
									c0.5-0.5,1-0.9,1.5-1.3c0.5-0.4,1.1-0.7,1.8-1c0.8,2.1,2.3,5.6,4.6,10.4c0.5,1.1,0.9,1.8,1.3,2.4c0.3,0.5,0.7,0.9,1,1.2
									c0.3,0.3,0.7,0.5,1.2,0.7c-0.2,0.5-0.4,1-0.7,1.3c-0.3,0.4-0.6,0.6-1,0.8C1952.1,1468.9,1951.6,1468.9,1951.2,1468.7z"
						/>
						<path
							d="M1959.5,1464.3c-0.4-0.2-0.9-0.7-1.4-1.5c-0.3-0.6-0.9-1.5-1.6-2.7c-0.8-1.4-1.5-2.6-2-3.5c-1.8-2.9-3.2-4.9-4.3-6
									c0.4-0.5,0.9-1,1.4-1.4c0.5-0.4,1-0.8,1.7-1.2c1,2.1,2.8,5.4,5.6,9.9c0.6,1,1.1,1.8,1.5,2.2s0.7,0.8,1.1,1.1
									c0.3,0.2,0.7,0.4,1.3,0.5c-0.1,0.5-0.3,1-0.6,1.4c-0.3,0.4-0.6,0.7-0.9,0.9C1960.5,1464.3,1959.9,1464.4,1959.5,1464.3z"
						/>
						<path
							d="M1965.8,1459.7c-0.5-0.7-0.8-1.5-1-2.4c0.6,0.2,1.3,0.2,2.1,0c0.8-0.2,1.5-0.5,2.1-1c1.5-1.1,1.9-2.1,1.4-2.9
									c-0.3-0.3-0.6-0.5-1.1-0.5c-0.5,0-1.2,0.2-2.2,0.4c-1,0.2-1.9,0.4-2.5,0.5c-0.7,0.1-1.4,0-2.1-0.2c-0.7-0.2-1.3-0.7-1.8-1.4
									c-0.6-0.8-0.7-1.8-0.4-2.9c0.4-1.1,1.2-2.1,2.4-3c1.6-1.2,3-1.9,4.4-2.3c0.2,0.3,0.5,0.8,0.7,1.4c0.2,0.7,0.4,1.2,0.4,1.7
									c-1.1-0.1-2,0-2.8,0.1c-0.8,0.1-1.4,0.3-1.9,0.6c-0.3,0.3-0.5,0.7-0.6,1c-0.1,0.3,0,0.6,0.2,0.9c0.3,0.4,0.7,0.6,1.3,0.6
									c0.6,0,1.3-0.1,2.4-0.4c0.9-0.2,1.8-0.4,2.4-0.4c0.7-0.1,1.3,0,2,0.2s1.2,0.6,1.7,1.2c0.7,1,0.9,2.1,0.5,3.2
									c-0.4,1.1-1.3,2.2-2.8,3.3C1968.8,1458.9,1967.3,1459.6,1965.8,1459.7z"
						/>
						<path
							d="M1986.1,1444c-1,0.2-2.1,0.1-3.1-0.3c-1-0.4-2-1.1-2.8-2c-0.9-1-1.6-2.2-1.8-3.4c-0.3-1.2-0.2-2.4,0.2-3.6
									c0.4-1.2,1.1-2.2,2.1-3.2c1.4-1.3,2.9-1.8,4.6-1.7s3.1,0.9,4.4,2.3c0.9,1,1.5,2.2,1.8,3.4c0.3,1.2,0.2,2.5-0.2,3.6
									c-0.4,1.2-1.2,2.3-2.2,3.2C1988.1,1443.3,1987.1,1443.8,1986.1,1444z M1989.6,1438.3c-0.1-1-0.5-2-1.5-3.1
									c-0.8-0.9-1.7-1.5-2.8-1.8c-1-0.3-2-0.2-3,0.2c-0.9,0.8-1.3,1.7-1.3,2.8c0,1.1,0.5,2.1,1.4,3.1c1,1.1,2.1,1.8,3.2,2.1
									c1.1,0.2,2,0,2.8-0.7C1989.2,1440.2,1989.6,1439.3,1989.6,1438.3z"
						/>
						<path
							d="M2000.2,1437.8c-0.1-0.7-0.3-1.3-0.5-1.9c-0.2-0.5-0.6-1.1-1.1-1.8c-0.5-0.7-1.3-1.5-2.3-2.5c-1.1-1.1-3.2-3-6.1-5.7
									c-0.6,0.6-1,0.9-1.3,1c-0.3,0.1-0.5,0-0.8-0.3c0.2-0.6,0.4-1.4,0.6-2.2c-1.1-1.4-1.6-2.9-1.6-4.4c0-1.5,0.5-2.7,1.6-3.8
									c0.9-0.9,1.7-1.5,2.6-1.9c0.3,0.3,0.6,0.7,0.9,1.3s0.6,1.2,0.7,1.6c-0.9,0.1-1.7,0.2-2.4,0.4c-0.7,0.2-1.2,0.5-1.5,0.8
									c-0.1,0.4,0,0.9,0.4,1.4c0.3,0.6,1,1.3,1.9,2.3c1.1-1.2,1.8-2,2.2-2.5c0.6,0.6,1,1.3,1.3,2.1c-0.7,0.4-1.5,0.9-2.3,1.7l1.4,1.4
									c1,1,2.1,2.1,3.4,3.2c0.7,0.6,1.2,1.1,1.7,1.5c0.7,0.7,1.3,1.5,1.6,2.1c0.3,0.7,0.5,1.5,0.6,2.4c0.1,0.9,0,2.1-0.2,3.5
									L2000.2,1437.8z"
						/>
						<path
							d="M2010.8,1420c-0.7-0.8-1.8-1.8-3.1-3.1s-2.6-2.5-3.8-3.5c-2.7-2.4-4.4-3.7-5.3-4c0.2-0.7,0.7-1.5,1.3-2.4s1.3-1.9,2.1-2.7
									c1.4-1.6,2.9-2.6,4.4-2.8c1.5-0.2,2.9,0.3,4.3,1.5c0.8,0.7,1.3,1.6,1.6,2.5c0.2,1,0.2,2-0.1,3.1c3.5,0.9,5.9,1.3,7.2,1.1
									c-0.2,0.6-0.5,1.3-0.9,1.8c-0.4,0.6-0.8,1.2-1.4,1.8c-1.2-0.5-3.4-1.1-6.4-1.8c-0.1,0.1-0.2,0.3-0.4,0.5c-0.5,0.6-1,1.1-1.6,1.5
									c2.3,2,3.9,3.2,4.8,3.6c-0.3,0.6-0.7,1.1-1.1,1.6C2011.9,1419.1,2011.4,1419.5,2010.8,1420z M2009.7,1407.6
									c-0.2-0.8-0.5-1.4-1.1-1.9c-1.8-1.6-3.6-1.4-5.4,0.6c-0.4,0.4-0.7,0.9-0.9,1.3c1.1,1.1,2.6,2.5,4.5,4.3c1.4-0.8,2.3-1.5,2.8-2
									C2009.9,1409.1,2009.9,1408.3,2009.7,1407.6z"
						/>
						<path
							d="M2021.4,1407.4c-0.8,0-1.5-0.3-2.1-0.9c-1-0.9-1.3-2.1-0.9-3.5c0.3-1.4,1.3-3.1,2.9-5c-0.9-0.8-1.6-1.2-2.2-1.3
									c-0.6,0-1.3,0.3-2,1c-0.5,0.5-0.8,1-1.2,1.7c-0.3,0.6-0.5,1.3-0.7,1.9l-0.9-0.5c-0.2-1.1-0.2-2.2,0-3.4c0.3-1.1,0.8-2.1,1.5-2.8
									c1-1,2-1.6,3.1-1.7s2.1,0.4,3.1,1.4c0.6,0.5,1.1,1.2,1.8,1.9c0.7,0.8,1.2,1.3,1.6,1.7c0.6,0.6,1.1,0.9,1.5,1.1s1,0.3,1.7,0.3
									c-0.1,1.1-0.5,2.1-1.1,2.9c-0.7,0.2-1.4,0.1-2.1-0.3c-0.1,0.9-0.4,1.8-0.7,2.6c-0.4,0.8-0.8,1.4-1.2,1.9
									C2022.9,1407.1,2022.2,1407.4,2021.4,1407.4z M2024,1402.8c0.2-0.5,0.3-1.1,0.4-1.6l-0.2-0.2c-0.4-0.4-0.9-0.9-1.3-1.3l-0.9-1
									c-0.9,1-1.4,1.9-1.5,2.8s0.1,1.6,0.6,2.1c0.2,0.2,0.6,0.4,0.9,0.5c0.4,0.1,0.7,0.1,1.1,0C2023.5,1403.8,2023.8,1403.4,2024,1402.8
									z"
						/>
						<path
							d="M2029.1,1394.3l-1.3-1.5l-1.4-1.6c-1.4-1.5-2.6-2.5-3.5-2.8c0.5-1.1,1.4-2.2,2.4-3.2c1.2,1.5,3.1,3.6,5.8,6.4
									c0.6,0.6,1.1,0.9,1.6,1c0.5,0,1-0.2,1.5-0.7c0.7-0.7,1.2-1.5,1.5-2.3c-0.9-1-1.7-1.9-2.4-2.6l-1.1-1.2c-1.4-1.5-2.6-2.5-3.5-2.8
									c0.5-1.1,1.4-2.2,2.4-3.2c0.9,1.1,1.9,2.3,3.1,3.6c1.2,1.3,2.1,2.4,2.8,3.1c0.6,0.6,1.1,1,1.5,1.2s1,0.4,1.8,0.4
									c-0.1,0.5-0.2,1.1-0.4,1.6s-0.5,1-0.8,1.4c-0.8,0.1-1.5-0.1-2.2-0.5c0,0.8-0.2,1.6-0.5,2.4c-0.3,0.8-0.8,1.4-1.3,2
									C2033.1,1396.7,2031.2,1396.5,2029.1,1394.3z"
						/>
						<path
							d="M2044.1,1386c-1.3-1.7-2.7-3.5-4.3-5.3c-0.8-0.9-1.4-1.5-1.8-2c-0.5-0.4-0.9-0.7-1.2-0.9c-0.4-0.2-0.8-0.3-1.3-0.3
									c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.5-0.8,0.7-1c1-0.9,2.1-0.7,3.2,0.4c-0.1-1.7,0.3-3.2,1.2-4.5c0.4,0.2,0.9,0.5,1.4,1
									c0.5,0.5,1,0.9,1.4,1.4c-0.3,0.2-0.6,0.4-0.9,0.7c-0.5,0.4-0.9,0.9-1.3,1.4c-0.3,0.5-0.6,1-0.7,1.4c1,1.2,1.9,2.3,2.8,3.3
									c1.5,1.7,2.7,2.8,3.6,3.2c-0.4,0.5-0.8,1-1.4,1.4C2045.3,1385.2,2044.8,1385.6,2044.1,1386z"
						/>
						<path
							d="M2053.3,1377.5c-1.1,0.1-2.1,0-3.1-0.5c-1-0.5-1.9-1.2-2.7-2.2c-0.9-1.1-1.4-2.3-1.6-3.5c-0.2-1.2-0.1-2.4,0.4-3.6
									s1.2-2.2,2.3-3c1.5-1.2,3-1.6,4.7-1.4s3.1,1.1,4.3,2.6c0.9,1.1,1.4,2.3,1.6,3.5c0.2,1.3,0,2.5-0.4,3.6c-0.5,1.2-1.3,2.2-2.4,3.1
									C2055.4,1376.9,2054.4,1377.4,2053.3,1377.5z M2057.2,1372c0-1-0.4-2.1-1.3-3.1c-0.8-1-1.6-1.6-2.7-1.9c-1-0.3-2-0.3-3,0.1
									c-0.9,0.7-1.4,1.6-1.5,2.7c0,1,0.4,2.1,1.2,3.2c1,1.2,2,2,3.1,2.3c1.1,0.3,2,0.1,2.8-0.5C2056.7,1373.9,2057.2,1373,2057.2,1372z"
						/>
						<path
							d="M2063.5,1369.1c-0.5-0.7-0.8-1.5-1-2.4c0.6,0.2,1.3,0.2,2.1,0c0.8-0.2,1.5-0.5,2.1-1c1.5-1.1,2-2,1.4-2.9
									c-0.3-0.3-0.6-0.5-1.1-0.5s-1.2,0.2-2.2,0.4c-1,0.2-1.9,0.4-2.5,0.5c-0.7,0.1-1.4,0-2.1-0.2c-0.7-0.2-1.3-0.7-1.8-1.4
									c-0.6-0.8-0.7-1.8-0.4-2.9c0.4-1.1,1.2-2.1,2.4-3c1.6-1.1,3-1.9,4.4-2.3c0.2,0.3,0.5,0.8,0.7,1.4c0.2,0.7,0.4,1.2,0.4,1.8
									c-1.1-0.1-2-0.1-2.8,0.1c-0.8,0.1-1.4,0.3-1.9,0.6c-0.3,0.3-0.5,0.7-0.6,1c-0.1,0.3,0,0.6,0.1,0.9c0.3,0.4,0.7,0.6,1.3,0.6
									s1.3-0.1,2.4-0.4c0.9-0.2,1.8-0.4,2.4-0.4c0.7-0.1,1.3,0,2,0.2c0.6,0.2,1.2,0.6,1.7,1.3c0.7,1,0.9,2.1,0.5,3.2
									c-0.4,1.1-1.3,2.2-2.8,3.3C2066.5,1368.3,2065,1369,2063.5,1369.1z"
						/>
					</g>
					<g className="level_one location " id="emyn_muil">
						<rect x="2279.735" y="1428.506" width="148.03" height="31.425" transform="matrix(0.729586, -0.683889, 0.683889, 0.729586, -767.668274, 1896.292114)"></rect>
						<path
							d="M1894.7,1393.7c-1-0.6-2.2-1.3-3.6-2.1c-1.5-0.8-2.7-1.4-3.5-1.8c-0.9-0.4-1.6-0.7-2.3-0.8c0.6-0.9,1.5-2.3,2.6-4.2
									c1.3-2.3,2.3-4.2,2.9-5.6c0.5,0.3,1,0.6,1.5,1s0.8,0.8,0.9,1c-0.5,0.3-0.9,0.8-1.4,1.4c-0.5,0.6-0.9,1.3-1.3,2
									c-0.4,0.8-0.8,1.6-1.1,2.5c1.2,0.8,2.3,1.4,3.3,2c0.5-0.8,0.9-1.5,1.1-1.9c0.5-0.9,0.8-1.5,1-2s0.4-1,0.7-1.7
									c0.4,0.1,0.8,0.3,1.1,0.5c0.2,0.1,0.6,0.4,1,0.7c-1.2,1.9-2.2,3.4-2.8,4.5l-0.5,0.9c1.6,0.9,2.8,1.5,3.5,1.8
									c0.6-0.8,1.2-1.6,1.6-2.4c0.4-0.8,0.8-1.6,1.1-2.4c0.3-0.8,0.5-1.6,0.6-2.2c0.5,0.3,1,0.6,1.5,1c0.5,0.4,0.8,0.8,0.9,1
									c-0.8,1.1-1.7,2.7-2.9,4.8c-1.1,1.9-1.8,3.4-2.2,4.4C1896.9,1395.1,1895.7,1394.3,1894.7,1393.7z"
						/>
						<path
							d="M1905.8,1382.5c-1.5-1.2-3.8-2.9-6.8-4.9c-1.4-1-2.6-1.7-3.4-2.2s-1.5-0.9-2.1-1.1c0.2-0.5,0.5-1,0.8-1.5
									c0.3-0.5,0.7-1,1.2-1.5c1.6,0.2,3.5,0.5,5.9,0.9c2.3,0.3,4.3,0.6,5.7,0.8c-0.8-1.5-1.8-3.3-3-5.3c-1.2-2.1-2.1-3.7-2.8-4.8
									c0.2-0.4,0.5-1,0.9-1.6c0.3-0.5,0.7-1,1.3-1.5c0.7,0.6,1.7,1.4,3,2.4c1.3,1,2.5,1.8,3.7,2.7c2.8,1.9,4.7,3,5.5,3.2
									c-0.3,0.6-0.6,1.1-0.9,1.7c-0.3,0.4-0.7,0.9-1.2,1.5c-0.7-0.6-1.7-1.4-3-2.4c-1.3-1-2.5-1.8-3.7-2.7c-0.6-0.4-1.4-1-2.4-1.6
									c1.7,3.2,3.8,6.8,6.2,10.9c-0.1,0.3-0.2,0.5-0.3,0.7c-0.1,0.2-0.3,0.3-0.5,0.5c-4.1-0.7-7.9-1.2-11.5-1.7c1.5,1,2.6,1.8,3.3,2.3
									c2.8,1.9,4.6,3,5.4,3.3c-0.1,0.4-0.3,0.8-0.6,1.2C1906.4,1381.8,1906.1,1382.2,1905.8,1382.5z"
						/>
						<path
							d="M1921.1,1361.3c-0.9-1-2.2-2.1-3.8-3.6c-2.1-0.5-4.2-0.9-6.4-1.2c-2.2-0.3-3.6-0.4-4.2-0.3c0.4-1.1,1.1-2.3,2.2-3.5
									c0.4,0.2,1.4,0.5,3,0.9c1.5,0.4,3.2,0.7,4.9,1c-0.7-1.9-1.4-3.4-2-4.6c-0.6-1.1-1.1-1.9-1.5-2.3c0.6-0.9,1.2-1.8,2-2.7
									c0.6,2.6,2,6.1,4,10.4c2.2,1.8,3.6,2.8,4.4,3c-0.3,0.5-0.6,1-1,1.5C1922.1,1360.3,1921.6,1360.8,1921.1,1361.3z"
						/>
						<path
							d="M1928.7,1352.2c-1.3-1.5-3.3-3.5-5.8-6c-2.4-2.4-4.1-3.8-4.8-4.2c0.3-0.5,0.7-1,1.1-1.4c0.3-0.4,0.8-0.8,1.5-1.3
									c1.3,0.3,3.2,0.8,5.7,1.3c2.6,0.6,4.9,1.1,7.2,1.5c-1.5-1.5-2.6-2.6-3.5-3.5c-2.5-2.4-4.1-3.8-4.8-4.2c0.2-0.4,0.4-0.7,0.7-1
									c0.3-0.3,0.6-0.5,1-0.8c1.7,1.8,3.6,3.8,5.8,6c2.4,2.4,4.1,3.8,4.8,4.2c-0.3,0.4-0.6,0.9-1.1,1.3c-0.4,0.4-0.9,0.8-1.5,1.3
									c-1.4-0.3-3.4-0.8-6-1.4c-2.6-0.6-4.9-1.1-6.9-1.5c1.1,1.1,2.3,2.3,3.5,3.5c2.4,2.3,4,3.7,4.8,4.2c-0.2,0.4-0.4,0.7-0.7,1
									C1929.5,1351.7,1929.2,1351.9,1928.7,1352.2z"
						/>
						<path
							d="M1947.6,1333.8c-1.1-1.7-2.8-3.9-5-6.7c-1.1-1.4-1.9-2.4-2.6-3.2c-0.6-0.7-1.2-1.3-1.7-1.7c0.4-0.4,0.8-0.8,1.3-1.2
									c0.4-0.3,1-0.7,1.6-1.1c1.4,0.7,3.2,1.6,5.3,2.6s3.9,1.9,5.3,2.5c-0.3-1.7-0.7-3.7-1.2-6c-0.5-2.3-0.9-4.2-1.2-5.5
									c0.3-0.4,0.7-0.8,1.3-1.2c0.4-0.3,1-0.7,1.7-1.1c0.5,0.8,1.2,1.8,2.2,3.2c1,1.3,1.9,2.5,2.8,3.7c2.1,2.7,3.5,4.3,4.3,4.7
									c-0.4,0.5-0.9,0.9-1.4,1.3c-0.4,0.3-1,0.7-1.6,1c-0.5-0.8-1.2-1.8-2.2-3.2c-1-1.3-1.9-2.5-2.8-3.7c-0.5-0.6-1.1-1.4-1.8-2.2
									c0.7,3.6,1.5,7.7,2.6,12.3c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.1-0.4,0.2-0.6,0.3c-3.7-1.9-7.2-3.6-10.4-5c1.1,1.4,1.9,2.5,2.5,3.2
									c2.1,2.6,3.5,4.2,4.2,4.8c-0.3,0.3-0.6,0.7-0.9,0.9C1948.4,1333.4,1948.1,1333.6,1947.6,1333.8z"
						/>
						<path
							d="M1966.2,1318.1c-1.4-0.3-2.6-1.2-3.6-2.7c-1-1.6-2.1-3.2-3.1-4.6c-1-1.5-1.8-2.4-2.2-2.8c0.4-0.4,0.9-0.8,1.5-1.1
									c0.5-0.3,1-0.6,1.7-0.9c1.3,2.3,2.9,4.9,4.8,7.7c1.3,2.1,3,2.5,4.9,1.2c2.1-1.3,2.5-2.9,1.3-4.8c-2.8-4.4-4.6-7-5.3-7.8
									c0.3-0.3,0.6-0.6,1-0.8c0.3-0.2,0.7-0.4,1.2-0.6c0.5,0.9,1.3,2.2,2.2,3.7s1.8,2.9,2.5,4c0.9,1.4,1.3,2.9,1.1,4.3
									c-0.2,1.5-1.3,2.8-3.2,4C1969.1,1318,1967.6,1318.4,1966.2,1318.1z"
						/>
						<path
							d="M1980.3,1311c-0.4-1-1-2.1-1.7-3.6s-1.4-2.7-2.1-3.9c-1.7-2.9-2.8-4.7-3.6-5.4c0.5-0.4,1-0.7,1.5-1.1
									c0.5-0.3,1-0.5,1.7-0.8c0.4,0.9,0.9,2,1.7,3.5s1.5,2.8,2.1,4c1.7,3,2.8,4.7,3.5,5.3c-0.5,0.4-1,0.7-1.5,1
									C1981.5,1310.5,1981,1310.8,1980.3,1311z"
						/>
						<path
							d="M1986.2,1302.7c-0.4-1-1-2.1-1.7-3.6c-1.5-3.1-2.6-5-3.3-5.5c0.5-0.4,1-0.7,1.6-1c0.5-0.2,1.1-0.5,1.8-0.7
									c0.3,0.9,0.8,2.1,1.5,3.6c0.7,1.5,1.3,2.8,1.9,4.1c0.8,1.6,1.4,2.8,1.8,3.4c0.8-0.2,1.7-0.6,2.7-1.1c0.6-0.3,1.3-0.7,1.9-1.2
									c0.6-0.4,1.1-0.9,1.4-1.3c0.3,0.5,0.5,1.1,0.6,1.7c0.2,0.6,0.2,1,0.2,1.3c-1.3,0.5-2.8,1.1-4.3,1.9c-2,1-3.4,1.8-4.4,2.4
									C1987.3,1305.1,1986.7,1303.7,1986.2,1302.7z"
						/>
					</g>
					<g className="level_two fade location" id="bywater">
						<text fontFamily="Fjord One" fontSize="27.0382px" fontWeight="700" transform="matrix(0.464674, 0.324397, -0.386299, 0.553345, 727.803894, 87.101372)" x="829.727" y="613.872">Bywater</text>
					</g>
					<g className="regions level_two location " id="celebrant">
						<rect x="1377.038" y="973.452" width="89.414" height="21.414" transform="matrix(0.617644, 0.786458, -0.793897, 0.608171, 1469.421021, -758.877625)"></rect>
						<path
							d="M1541.6,935c0.1-1.4,0.8-2.7,2.1-4.2c0.9-1,1.9-1.7,3-2.2c1.1-0.4,2.2-0.6,3.2-0.4c1.1,0.2,2,0.6,2.9,1.4
									c0.9,0.9,1.6,1.7,1.9,2.6c-0.3,0.3-0.7,0.6-1.1,0.9c-0.5,0.3-0.9,0.5-1.2,0.5c-0.1-0.8-0.3-1.5-0.5-2.2c-0.3-0.7-0.5-1.2-0.8-1.4
									c-0.4-0.2-0.9-0.3-1.6-0.2c-0.6,0.1-1.3,0.3-1.9,0.7c-0.6,0.4-1.2,0.8-1.7,1.4c-1.1,1.1-1.6,2.2-1.7,3.2c-0.1,1,0.3,1.8,1,2.5
									c0.5,0.4,1,0.8,1.6,1.1c0.6,0.3,1.1,0.5,1.6,0.6l-0.4,0.8c-0.8,0.1-1.6,0.1-2.5-0.2c-0.9-0.3-1.6-0.7-2.2-1.2
									C1542.1,937.6,1541.6,936.4,1541.6,935z"
						/>
						<path
							d="M1548.5,941.9c0.1-1,0.8-2.1,1.9-3.2c0.8-0.7,1.6-1.3,2.4-1.6c0.9-0.3,1.7-0.4,2.5-0.2c0.8,0.2,1.5,0.6,2.2,1.2
									c0.8,0.8,1.1,1.8,1,2.8c-0.2,1-0.9,2.1-2.1,3.3c-0.7-0.4-1.5-0.9-2.3-1.6c-0.9-0.7-1.7-1.4-2.4-2.1c-0.5,0.7-0.8,1.3-0.8,1.8
									c0,0.5,0.2,1.1,0.7,1.6c0.4,0.4,0.9,0.8,1.3,1.1c0.5,0.3,1,0.4,1.4,0.5l-0.4,0.7c-0.8,0.1-1.6,0.1-2.4-0.2c-0.8-0.3-1.5-0.7-2-1.3
									C1548.7,943.9,1548.3,942.9,1548.5,941.9z M1553.9,941c0.6,0.3,1.1,0.6,1.5,0.7c0.5-0.6,0.9-1.2,1-1.8c0.1-0.6-0.1-1.2-0.5-1.6
									c-0.5-0.1-1.1,0-1.7,0.3c-0.7,0.3-1.3,0.7-1.9,1.3C1552.8,940.3,1553.4,940.7,1553.9,941z"
						/>
						<path
							d="M1554.4,949.2c0.1-0.3,0.4-0.8,0.9-1.2c0.4-0.3,1-0.9,1.8-1.6c0.9-0.8,1.7-1.5,2.3-2c1.9-1.7,3.2-3.1,3.8-4
									c0.4,0.2,0.8,0.4,1.1,0.7c0.3,0.3,0.7,0.6,1,1c-1.4,1-3.5,2.9-6.5,5.5c-0.7,0.6-1.1,1.1-1.4,1.4c-0.3,0.3-0.5,0.7-0.7,0.9
									c-0.1,0.3-0.2,0.6-0.3,1c-0.4,0-0.7,0-1-0.2c-0.3-0.1-0.5-0.3-0.7-0.5C1554.4,949.8,1554.3,949.5,1554.4,949.2z"
						/>
						<path
							d="M1558,952c0.2-1,0.9-2.1,2.1-3.1c0.8-0.7,1.7-1.1,2.5-1.4c0.9-0.2,1.7-0.2,2.5,0s1.5,0.7,2.1,1.4c0.8,0.9,1,1.9,0.7,2.9
									c-0.3,1-1,2.1-2.3,3.1c-0.7-0.5-1.4-1.1-2.2-1.8c-0.8-0.7-1.6-1.5-2.2-2.3c-0.6,0.6-0.9,1.2-0.9,1.8s0.2,1.1,0.6,1.6
									c0.4,0.5,0.8,0.8,1.3,1.2c0.5,0.3,0.9,0.5,1.4,0.6l-0.5,0.7c-0.8,0.1-1.6-0.1-2.4-0.4s-1.4-0.8-1.9-1.4
									C1558.1,954.1,1557.8,953.1,1558,952z M1563.5,951.6c0.5,0.4,1,0.7,1.4,0.8c0.6-0.5,0.9-1.1,1.1-1.7c0.1-0.6,0-1.2-0.4-1.7
									c-0.5-0.1-1.1-0.1-1.7,0.1c-0.7,0.2-1.3,0.6-2,1.2C1562.5,950.8,1563,951.2,1563.5,951.6z"
						/>
						<path
							d="M1564.5,961.4c-0.1-0.5-0.1-1,0-1.5l-1.1,0.2c-0.1-0.2-0.3-0.4-0.4-0.8c0.6-0.4,1.6-1.1,2.9-2.1c1.3-1,2.5-1.8,3.4-2.5
									c1.8-1.3,2.9-2.4,3.5-3.1c0.7,0.4,1.4,1,2,1.8c-0.8,0.5-2,1.3-3.6,2.5c1.1,0.2,1.9,0.7,2.5,1.5c0.6,0.9,0.8,1.8,0.5,2.8
									c-0.3,1-1,2-2.2,2.8c-1.4,1-2.6,1.5-3.8,1.5c-1.2,0-2.2-0.6-3-1.7C1564.8,962.3,1564.6,961.9,1564.5,961.4z M1567.7,962.7
									c0.7,0,1.4-0.3,2.2-0.9c0.8-0.6,1.3-1.2,1.6-1.9s0.4-1.3,0.2-1.9c-0.4-0.6-1-1-1.7-1.3l-2.2,1.6c-1,0.8-1.7,1.4-2.1,1.8
									c-0.1,0.6,0,1.2,0.3,1.7C1566.5,962.4,1567,962.7,1567.7,962.7z"
						/>
						<path
							d="M1569,967.8c1.4-0.8,2.8-1.8,4.3-2.8c0.7-0.5,1.3-0.9,1.6-1.2c0.4-0.3,0.6-0.6,0.8-0.8c0.2-0.2,0.3-0.5,0.4-0.9
									c0.4,0,0.7,0.1,1,0.3c0.3,0.2,0.5,0.3,0.6,0.6c0.5,0.7,0.3,1.5-0.6,2.2c1.3,0,2.3,0.3,3.1,1c-0.2,0.3-0.5,0.6-0.9,0.9
									s-0.8,0.7-1.2,0.9c-0.1-0.2-0.2-0.5-0.4-0.7c-0.3-0.4-0.5-0.7-0.9-0.9c-0.3-0.3-0.6-0.4-1-0.6c-1,0.6-1.9,1.2-2.7,1.8
									c-1.4,1-2.3,1.8-2.7,2.4c-0.3-0.3-0.6-0.6-0.9-1C1569.5,968.6,1569.3,968.2,1569,967.8z"
						/>
						<path
							d="M1572.8,972.6c0.2-0.5,0.5-1,1.1-1.3c0.8-0.5,1.7-0.6,2.6-0.2c0.9,0.4,1.9,1.3,2.9,2.7c0.7-0.5,1.2-0.9,1.4-1.4
									c0.2-0.4,0.1-0.9-0.3-1.5c-0.2-0.4-0.6-0.7-0.9-1c-0.4-0.3-0.8-0.6-1.2-0.7l0.6-0.6c0.8,0,1.6,0.1,2.3,0.5
									c0.7,0.3,1.3,0.8,1.7,1.4c0.5,0.8,0.7,1.6,0.5,2.4c-0.2,0.7-0.7,1.4-1.6,1.9c-0.5,0.3-1.1,0.6-1.7,0.9c-0.7,0.4-1.2,0.6-1.5,0.8
									c-0.5,0.3-0.9,0.6-1.1,0.9s-0.4,0.7-0.6,1.2c-0.7-0.2-1.3-0.6-1.8-1.2c0-0.5,0.3-1,0.6-1.4c-0.6-0.2-1.2-0.5-1.7-0.9
									c-0.5-0.3-0.9-0.7-1.1-1.1C1572.7,973.7,1572.7,973.2,1572.8,972.6z M1575.5,975c0.3,0.2,0.7,0.4,1.1,0.5l0.1-0.1
									c0.4-0.2,0.8-0.5,1.2-0.7l0.8-0.5c-0.5-0.7-1.1-1.2-1.6-1.4c-0.6-0.2-1.1-0.2-1.6,0.1c-0.2,0.1-0.4,0.3-0.6,0.6
									c-0.2,0.2-0.2,0.5-0.2,0.7C1574.9,974.6,1575.1,974.8,1575.5,975z"
						/>
						<path
							d="M1577.4,980.8c1.4-0.7,3-1.5,4.5-2.4c0.8-0.4,1.3-0.8,1.7-1.1c0.4-0.3,0.7-0.5,0.8-0.7c0.2-0.2,0.3-0.5,0.5-0.9
									c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.4,0.6,0.6c0.4,0.7,0.2,1.3-0.6,1.9c0.6,0.1,1.1,0.3,1.6,0.6c0.5,0.3,0.9,0.7,1.1,1.2
									c0.8,1.5,0.3,2.7-1.6,3.8l-1.3,0.7l-1.4,0.8c-1.5,0.8-2.4,1.5-2.9,2.1c-0.3-0.3-0.5-0.7-0.8-1.1c-0.2-0.4-0.4-0.8-0.5-1.3
									c1.6-0.8,3.4-1.7,5.3-2.8c0.6-0.3,0.9-0.6,1-1c0.1-0.3,0.1-0.7-0.2-1.2c-0.4-0.6-0.8-1.1-1.3-1.3l-0.6,0.3c-1,0.5-1.9,1-2.6,1.4
									c-1.5,0.8-2.4,1.5-2.9,2.1c-0.3-0.3-0.5-0.7-0.8-1.1C1577.8,981.7,1577.6,981.2,1577.4,980.8z"
						/>
						<path
							d="M1582.9,990.3c0.2-0.3,0.6-0.7,1.3-1c0.4-0.2,1.1-0.6,2.1-1c1.1-0.5,2-0.9,2.7-1.3c0.3-0.1,0.6-0.3,0.8-0.5
									c-0.3-0.5-0.4-0.9-0.4-1.1s0.1-0.4,0.4-0.5c1,0.6,2.1,1.1,3.4,1.4l0.3,0.7c-0.5,0.3-0.9,0.6-1.5,0.9c0.4,0.9,0.8,1.5,1.1,1.8
									c-0.3,0.2-0.6,0.3-0.9,0.4c-0.3,0.1-0.6,0.1-0.9,0.1c0-0.5-0.2-1-0.6-1.7c-0.6,0.3-1.3,0.6-1.9,0.9c-1.6,0.8-2.9,1.6-3.7,2.2
									c0.3,0.5,0.6,1,0.9,1.4l-0.6,0.4c-0.5,0-1-0.2-1.5-0.5c-0.5-0.3-0.8-0.7-1-1.1C1582.8,991.1,1582.8,990.7,1582.9,990.3z"
						/>
					</g>
					<g className="level_two fade location " id="nimrodel">
						<rect x="1377.038" y="973.452" width="89.414" height="21.414" transform="matrix(0.998189, 0.060155, -0.072178, 0.997464, 219.465088, -59.104591)"></rect>
						<path
							d="M1527,1008.6c0.8-1.4,1.7-3.3,2.9-5.9c1.1-2.4,1.8-4,1.9-4.7c0.4,0.1,0.8,0.2,1.2,0.4c0.3,0.2,0.7,0.4,1.2,0.7
									c0,1,0.1,2.5,0.3,4.5c0.2,2,0.3,3.9,0.5,5.6c0.7-1.5,1.3-2.6,1.7-3.5c1.2-2.5,1.8-4,1.9-4.6c0.3,0,0.6,0.1,0.9,0.3
									c0.2,0.1,0.5,0.3,0.8,0.5c-0.9,1.7-1.9,3.7-2.9,5.9c-1.1,2.4-1.8,4-1.9,4.7c-0.4-0.1-0.7-0.2-1.2-0.4c-0.4-0.2-0.8-0.4-1.3-0.7
									c-0.1-1.1-0.2-2.7-0.3-4.7c-0.2-2-0.3-3.8-0.5-5.4c-0.6,1.1-1.1,2.3-1.7,3.5c-1.1,2.4-1.8,3.9-1.9,4.6c-0.3,0-0.6-0.1-0.9-0.3
									C1527.6,1009,1527.3,1008.9,1527,1008.6z"
						/>
						<path
							d="M1540,1013.8c-0.1-0.3-0.1-0.8,0.1-1.4c0.2-0.5,0.4-1.3,0.7-2.2c0.3-0.9,0.5-1.5,0.6-2c0.2-0.6,0.3-1.1,0.4-1.6
									c0.1-0.5,0.1-0.9,0-1.1c0.5,0,0.9,0,1.3,0.1c0.4,0.1,0.9,0.2,1.4,0.3c-0.2,0.4-0.4,1-0.8,1.8c-0.3,0.8-0.6,1.8-0.9,2.8
									c-0.2,0.8-0.4,1.4-0.5,1.8s-0.1,0.8-0.1,1c0,0.3,0.1,0.6,0.3,0.9c-0.3,0.2-0.6,0.3-1,0.3c-0.3,0-0.6,0-0.8-0.1
									C1540.4,1014.3,1540.1,1014.1,1540,1013.8z M1542.7,1002.4c0.6-0.4,1.2-0.7,1.9-1c0.3,0.7,0.6,1.3,1,1.9c-0.6,0.4-1.2,0.7-1.9,1
									C1543.4,1003.6,1543.1,1003,1542.7,1002.4z"
						/>
						<path
							d="M1547.3,1016.2c0.2-1.5,0.4-3,0.5-4.7c0.1-0.8,0.1-1.4,0.1-1.8c0-0.4-0.1-0.8-0.2-1c-0.1-0.3-0.2-0.5-0.5-0.8
									c0.3-0.3,0.6-0.4,0.9-0.5c0.3-0.1,0.6-0.1,0.8-0.1c0.8,0.1,1.2,0.5,1.3,1.4c0.4-0.4,0.9-0.7,1.4-0.9c0.5-0.2,1-0.3,1.5-0.3
									c1.3,0.1,2,0.7,2.2,1.8c0.4-0.5,0.9-0.8,1.4-1.1c0.5-0.2,1.1-0.3,1.6-0.3c1.7,0.1,2.5,1.2,2.4,3.2l-0.1,1.4l-0.2,1.5
									c-0.1,1.5-0.1,2.6,0.2,3.3c-0.4,0.1-0.9,0.1-1.4,0c-0.4,0-0.9-0.1-1.4-0.2c0.2-1.7,0.4-3.5,0.6-5.4c0-0.6,0-1-0.2-1.3
									c-0.2-0.3-0.6-0.4-1.1-0.5c-0.7-0.1-1.3,0.1-1.7,0.3c0,0.4-0.1,1.1-0.2,1.9l-0.1,1.4c-0.1,1.5-0.1,2.6,0.2,3.3
									c-0.4,0.1-0.9,0.1-1.4,0c-0.4,0-0.9-0.1-1.4-0.2c0.2-1.7,0.4-3.5,0.6-5.4c0-0.6,0-1-0.2-1.3c-0.2-0.3-0.6-0.4-1.1-0.5
									c-0.7,0-1.2,0.1-1.7,0.3l-0.1,0.6c-0.1,1-0.2,1.9-0.2,2.7c-0.1,1.5-0.1,2.6,0.2,3.3c-0.4,0.1-0.9,0.1-1.3,0
									C1548.3,1016.4,1547.8,1016.3,1547.3,1016.2z"
						/>
						<path
							d="M1565,1016.9c0.1-1.5,0.1-3,0.1-4.7c0-0.8-0.1-1.4-0.1-1.8c-0.1-0.4-0.1-0.8-0.3-1c-0.1-0.2-0.3-0.5-0.5-0.8
									c0.2-0.3,0.5-0.5,0.8-0.6s0.6-0.2,0.8-0.2c0.9,0,1.4,0.5,1.5,1.6c0.7-1,1.6-1.6,2.6-1.9c0.1,0.3,0.2,0.7,0.3,1.1
									c0.1,0.5,0.1,0.9,0.1,1.4c-0.3,0-0.5-0.1-0.8,0c-0.5,0-0.9,0.1-1.3,0.2s-0.7,0.3-1,0.5c0,1.1,0,2,0,2.9c0,1.5,0.2,2.6,0.5,3.3
									c-0.4,0.1-0.9,0.2-1.3,0.2C1566,1017,1565.6,1017,1565,1016.9z"
						/>
						<path
							d="M1573.9,1016.3c-0.6-0.3-1.2-0.8-1.5-1.5c-0.4-0.7-0.6-1.4-0.6-2.3c0-1,0.1-1.8,0.5-2.6c0.4-0.8,0.9-1.4,1.6-1.8
									s1.5-0.7,2.5-0.8c1.3-0.1,2.3,0.3,3.1,1.1c0.8,0.8,1.3,1.8,1.3,3.2c0,1-0.1,1.8-0.5,2.6s-1,1.4-1.7,1.8s-1.6,0.7-2.6,0.8
									C1575.2,1016.8,1574.5,1016.6,1573.9,1016.3z M1578.2,1014.8c0.4-0.6,0.6-1.3,0.5-2.3c0-0.8-0.3-1.6-0.7-2.1c-0.4-0.6-1-1-1.7-1.2
									c-0.8,0-1.4,0.3-1.9,0.9c-0.4,0.6-0.6,1.3-0.6,2.3c0.1,1.1,0.3,1.9,0.8,2.5c0.5,0.6,1.1,0.9,1.8,0.8
									C1577.2,1015.6,1577.8,1015.3,1578.2,1014.8z"
						/>
						<path
							d="M1583.9,1015.2c-0.7-0.7-1-1.9-1.2-3.4c-0.1-1.6,0.1-2.8,0.7-3.7s1.5-1.3,2.7-1.4c0.9-0.1,1.8,0.2,2.6,0.7
									c-0.2-1.4-0.4-2.5-0.6-3.2c0.7-0.3,1.6-0.6,2.6-0.6c0,1.8,0.1,4.8,0.4,9c0,0.6,0.1,1,0.3,1.4c0.1,0.3,0.4,0.7,0.7,1
									c-0.3,0.3-0.6,0.5-0.9,0.6c-0.3,0.2-0.7,0.3-1,0.4c-0.4-0.3-0.7-0.6-0.9-1.1c-0.4,0.4-0.8,0.7-1.2,1s-0.9,0.4-1.4,0.4
									C1585.5,1016.2,1584.6,1015.9,1583.9,1015.2z M1589.1,1013.8c0-0.4-0.1-1.2-0.1-2.2c-0.1-1.1-0.1-2-0.1-2.6
									c-0.5-0.3-1.1-0.4-1.8-0.3c-0.7,0.1-1.3,0.3-1.6,0.9c-0.3,0.5-0.5,1.3-0.4,2.3c0.1,0.9,0.3,1.6,0.7,2.2c0.4,0.5,0.9,0.9,1.5,0.9
									C1587.9,1014.8,1588.5,1014.4,1589.1,1013.8z"
						/>
						<path
							d="M1595,1014.4c-0.7-0.7-1.1-1.8-1.3-3.2c-0.1-1,0-1.8,0.4-2.6c0.3-0.8,0.8-1.4,1.5-1.9c0.7-0.5,1.5-0.8,2.4-0.8
									c1.2-0.1,2.1,0.2,2.8,0.9s1.1,1.8,1.2,3.4c-0.8,0.3-1.7,0.5-2.7,0.7s-2.1,0.4-3.2,0.5c0.2,0.8,0.5,1.3,0.9,1.6
									c0.4,0.3,1,0.4,1.7,0.4c0.6-0.1,1.2-0.2,1.7-0.4c0.5-0.2,1-0.4,1.3-0.7l0.3,0.7c-0.4,0.6-1,1.2-1.7,1.6c-0.7,0.4-1.5,0.7-2.3,0.7
									C1596.7,1015.3,1595.7,1015.1,1595,1014.4z M1597.9,1010c0.6-0.2,1.1-0.4,1.5-0.7c-0.1-0.7-0.4-1.3-0.8-1.7
									c-0.4-0.4-1-0.6-1.6-0.6c-0.4,0.3-0.7,0.8-0.9,1.4c-0.2,0.6-0.3,1.3-0.2,2.1C1596.6,1010.3,1597.2,1010.2,1597.9,1010z"
						/>
						<path
							d="M1605.4,1014.1c-0.2-0.2-0.4-0.7-0.4-1.4c0-0.5-0.1-1.2-0.2-2.2c-0.1-1.1-0.2-2-0.2-2.7c-0.2-2.3-0.6-4-0.9-5
									c0.4-0.2,0.8-0.4,1.3-0.5c0.4-0.1,0.9-0.2,1.4-0.2c0,1.6,0.2,4.2,0.5,7.8c0.1,0.8,0.2,1.4,0.3,1.8c0.1,0.4,0.2,0.7,0.3,1
									c0.1,0.2,0.3,0.5,0.6,0.7c-0.2,0.3-0.5,0.5-0.8,0.7c-0.3,0.2-0.5,0.2-0.8,0.3C1606,1014.4,1605.6,1014.3,1605.4,1014.1z"
						/>
					</g>
					<g className="level_zero location" id="lorien">
						<rect x="2004.072" y="1578.934" width="130.129" height="34.734" transform="matrix(0.960615, 0.277882, -0.289453, 0.957268, 122.010422, -1050.60498)"></rect>
						<path
							d="M1592.8,1039.7c0.7-1.5,1.6-3.4,2.6-5.6c2.2-5,3.4-8.2,3.5-9.5c0.8,0.2,1.6,0.4,2.4,0.8c0.7,0.3,1.4,0.7,2.3,1.3
									c-0.7,1.3-1.7,3.2-2.8,5.5c-1.1,2.3-2.1,4.4-3,6.4c-1.2,2.6-1.9,4.5-2.3,5.7c0.9,0.7,2.1,1.4,3.5,2c0.9,0.4,1.8,0.7,2.8,0.9
									s1.9,0.3,2.6,0.2c-0.4,0.8-0.8,1.7-1.4,2.5c-0.6,0.8-1,1.3-1.3,1.6c-1.6-1-3.5-1.9-5.7-2.9c-2.7-1.2-4.8-2-6.3-2.4
									C1591,1043.3,1592.1,1041.2,1592.8,1039.7z"
						/>
						<path
							d="M1610.4,1053.9c-1.1-1.4-1.7-3-1.8-4.9s0.2-3.9,1-6c0.9-2.3,2.1-4.2,3.7-5.7c1.6-1.5,3.4-2.4,5.5-2.8
									c2-0.4,4.1-0.2,6.2,0.6c1.9,0.7,3.3,1.8,4.4,3.2c1.1,1.4,1.7,3.1,1.9,5.1c0.2,1.9-0.2,4-1,6.1c-0.9,2.3-2.1,4.1-3.7,5.5
									c-1.6,1.4-3.5,2.3-5.5,2.7c-2.1,0.4-4.2,0.1-6.3-0.7C1612.9,1056.3,1611.4,1055.3,1610.4,1053.9z M1621.7,1054.5
									c1.6-1,2.9-2.7,3.8-5.1c0.8-2.2,1-4.3,0.6-6.2c-0.4-1.9-1.4-3.3-3-4.3c-1.2-0.5-2.5-0.5-3.7-0.2c-1.3,0.3-2.4,1-3.4,2
									c-1,1-1.8,2.3-2.4,3.9c-0.9,2.4-1.1,4.6-0.6,6.5c0.5,1.9,1.6,3.2,3.4,3.9C1618.3,1055.6,1620.1,1055.5,1621.7,1054.5z
									M1622.2,1030.1c1.1-0.1,2.2-0.4,3.3-1c1.1-0.6,1.8-1.3,2.3-2.1c1.9,0.7,3,1.9,3.3,3.4c-1.1,0.6-2.5,1-4,1.2
									c-1.6,0.2-3.2,0-4.9-0.3L1622.2,1030.1z"
						/>
						<path
							d="M1631.6,1062.4c0.5-1.3,1.2-3.2,2.1-5.7c0.9-2.5,1.6-4.7,2.2-6.7c1.4-4.7,2.1-7.7,2-8.9c0.9-0.1,2.1,0,3.5,0.2
									c1.4,0.2,2.9,0.5,4.3,1c2.6,0.8,4.5,2.1,5.5,3.8c1,1.7,1.2,3.8,0.4,6.1c-0.4,1.4-1.2,2.5-2.2,3.4c-1,0.9-2.2,1.5-3.6,1.7
									c0.7,4.9,1.4,8,2.3,9.5c-0.9,0.1-1.7,0.1-2.6,0s-1.8-0.3-2.8-0.7c-0.1-1.8-0.5-4.8-1.1-9c-0.1,0-0.4-0.1-0.7-0.2
									c-0.9-0.3-1.8-0.6-2.6-1.1c-1.2,4-1.8,6.7-1.8,8c-0.8-0.1-1.6-0.2-2.4-0.5C1633.3,1063.2,1632.5,1062.9,1631.6,1062.4z
									M1645.7,1053.8c0.8-0.7,1.4-1.5,1.7-2.5c1-3.2-0.2-5.2-3.4-6.2c-0.7-0.2-1.4-0.3-2-0.3c-0.7,2-1.7,4.6-2.8,8.1
									c1.6,1.2,2.9,1.9,3.8,2.2C1643.9,1054.8,1644.9,1054.4,1645.7,1053.8z"
						/>
						<path
							d="M1652.7,1068.7c0.5-1.6,1.1-3.5,1.9-5.9c0.7-2.4,1.3-4.6,1.9-6.6c1.3-5.1,2-8.4,1.9-9.9c0.8,0,1.7,0.2,2.5,0.4
									c0.7,0.2,1.5,0.5,2.4,0.9c-0.5,1.4-1.1,3.3-1.8,5.8c-0.7,2.4-1.4,4.7-1.9,6.9c-1.4,5.2-2,8.5-1.9,9.8c-0.8,0-1.6-0.2-2.4-0.4
									C1654.5,1069.4,1653.6,1069.1,1652.7,1068.7z"
						/>
						<path
							d="M1665.5,1065.1c0.5-1.8,1-4,1.5-6.5c0.6-2.6,1-4.7,1.2-6.1c0.2-1.5,0.3-2.7,0.3-3.8c1.4,0.5,3.5,1.1,6.5,1.7
									c3.5,0.8,6.3,1.3,8.4,1.5c-0.2,0.9-0.5,1.8-0.8,2.7c-0.4,0.9-0.7,1.6-1,1.9c-0.6-0.5-1.4-1-2.3-1.4c-1-0.4-2-0.8-3.1-1
									c-1.2-0.3-2.4-0.4-3.6-0.5c-0.6,2.2-1.1,4.2-1.5,5.9c1.3,0.3,2.2,0.6,2.9,0.7c1.3,0.3,2.3,0.5,3,0.5c0.7,0.1,1.5,0.1,2.4,0.1
									c0,0.7-0.1,1.3-0.2,1.8c-0.1,0.4-0.3,1-0.5,1.8c-3-0.9-5.3-1.5-6.9-1.9l-1.3-0.2c-0.6,2.8-1,4.8-1.2,6.1c1.2,0.5,2.5,0.9,3.8,1.2
									c1.2,0.3,2.4,0.4,3.6,0.5c1.2,0.1,2.3,0,3.1-0.2c-0.2,0.9-0.5,1.8-0.8,2.7c-0.4,0.9-0.7,1.6-1,1.9c-1.7-0.6-4.2-1.2-7.4-1.9
									c-2.9-0.6-5.1-1-6.6-1.1C1664.4,1069.1,1665,1066.9,1665.5,1065.1z"
						/>
						<path
							d="M1683.9,1075.7c0.7-3,1.5-7.3,2.4-12.9c0.8-5.3,1.2-8.6,1.1-10c0.8,0,1.6,0,2.4,0.1c0.7,0.1,1.5,0.3,2.6,0.7
									c0.6,1.9,1.5,4.7,2.8,8.5c1.3,3.8,2.5,7.2,3.7,10.4c0.6-3.2,1-5.8,1.3-7.6c0.9-5.4,1.2-8.7,1.1-9.9c0.6-0.1,1.1-0.1,1.7,0
									c0.5,0.1,1.1,0.3,1.7,0.5c-0.8,3.8-1.6,8.1-2.4,12.9c-0.8,5.3-1.2,8.6-1.1,10c-0.7,0.1-1.4,0-2.3-0.1c-0.7-0.1-1.6-0.3-2.6-0.7
									c-0.7-2.1-1.6-5-2.9-8.8c-1.3-3.8-2.5-7.2-3.5-10.1c-0.5,2.5-0.9,5-1.3,7.6c-0.8,5.2-1.2,8.5-1.1,9.9c-0.5,0.1-1.1,0.1-1.7,0
									C1685.1,1076.2,1684.6,1076,1683.9,1075.7z"
						/>
					</g>
					<g className="level_two fade location" id="field_of_celebrant">
						<rect x="1859.236" y="2196.303" width="120.724" height="48.315" transform="matrix(0.824912, 0.565262, -0.575198, 0.818103, 1449.60791, -1778.857788)"></rect>
						<path
							d="M1702,1109.7c-0.7-1.7-0.5-3.7,0.4-6.1c0.6-1.6,1.6-3,2.7-4s2.5-1.6,4-1.9c1.5-0.2,3-0.1,4.5,0.5c1.7,0.7,3,1.4,4,2.4
									c-0.2,0.5-0.5,1-1,1.6c-0.4,0.5-0.9,0.9-1.2,1.2c-0.5-0.9-1.2-1.7-1.9-2.5s-1.4-1.2-1.9-1.4c-0.7-0.1-1.4,0.1-2.1,0.5
									s-1.5,1-2.1,1.7c-0.6,0.7-1.1,1.5-1.5,2.4c-0.7,1.9-0.9,3.4-0.4,4.6c0.4,1.2,1.4,2.1,2.7,2.7c0.9,0.3,1.8,0.6,2.7,0.7
									c0.9,0.1,1.7,0.1,2.4,0l-0.1,1.2c-1,0.5-2.1,0.8-3.3,0.8c-1.3,0.1-2.4-0.1-3.6-0.6C1704,1112.7,1702.6,1111.5,1702,1109.7z"
						/>
						<path
							d="M1714.6,1115.4c-0.4-1.4-0.1-3,0.8-4.8c0.6-1.2,1.4-2.2,2.4-2.9c1-0.7,2-1.1,3.2-1.2c1.2-0.1,2.3,0.2,3.5,0.7
									c1.5,0.8,2.4,1.8,2.7,3.2c0.3,1.4,0,3-1,5c-1.1-0.3-2.4-0.6-3.9-1.1c-1.5-0.5-2.9-1.1-4.2-1.7c-0.3,1.1-0.4,1.9-0.1,2.6
									c0.3,0.7,0.9,1.3,1.8,1.7c0.8,0.4,1.5,0.7,2.3,0.8c0.8,0.2,1.5,0.2,2.2,0.1l-0.2,1c-0.9,0.5-2,0.7-3.2,0.6c-1.2,0-2.3-0.3-3.3-0.8
									C1715.9,1117.8,1714.9,1116.7,1714.6,1115.4z M1721.3,1112.2c0.9,0.2,1.7,0.3,2.3,0.3c0.4-0.9,0.5-1.8,0.3-2.7
									c-0.2-0.9-0.7-1.5-1.5-1.9c-0.7,0.1-1.4,0.4-2.1,1c-0.7,0.6-1.3,1.4-1.8,2.4C1719.5,1111.7,1720.4,1112,1721.3,1112.2z"
						/>
						<path
							d="M1726,1122.4c-0.1-0.5,0.2-1.1,0.6-1.9c0.3-0.6,0.9-1.4,1.6-2.6c0.8-1.3,1.5-2.4,2-3.3c1.7-2.9,2.7-5,3.1-6.5
									c0.7,0.1,1.3,0.3,1.8,0.5c0.6,0.2,1.2,0.5,1.8,0.9c-1.3,1.8-3.3,4.9-5.8,9.3c-0.6,1-1,1.8-1.2,2.3s-0.4,1-0.4,1.4
									c0,0.4,0,0.8,0.1,1.3c-0.5,0.2-1,0.2-1.5,0.1c-0.5-0.1-0.8-0.2-1.1-0.4C1726.4,1123.3,1726.1,1122.9,1726,1122.4z"
						/>
						<path
							d="M1732.1,1124.8c-0.2-1.4,0.3-3,1.5-4.6c0.8-1.1,1.7-2,2.7-2.6s2.2-0.8,3.3-0.7s2.3,0.5,3.4,1.2c1.4,1,2.2,2.1,2.3,3.5
									s-0.4,3-1.7,4.8c-1.1-0.4-2.3-1-3.7-1.7s-2.7-1.5-4-2.3c-0.5,1-0.6,1.9-0.5,2.6c0.2,0.7,0.7,1.4,1.5,1.9c0.7,0.5,1.4,0.9,2.2,1.1
									c0.7,0.3,1.4,0.4,2.1,0.4l-0.3,1c-1,0.3-2.1,0.4-3.3,0.2c-1.2-0.2-2.3-0.6-3.2-1.3C1733.1,1127.4,1732.3,1126.3,1732.1,1124.8z
									M1739.2,1122.6c0.9,0.3,1.6,0.6,2.3,0.7c0.5-0.9,0.8-1.7,0.7-2.6c-0.1-0.9-0.5-1.6-1.2-2c-0.7,0-1.4,0.2-2.2,0.7
									c-0.8,0.5-1.5,1.2-2.1,2.1C1737.5,1121.9,1738.3,1122.3,1739.2,1122.6z"
						/>
						<path
							d="M1744.5,1135.2c-0.3-0.7-0.5-1.3-0.6-2l-1.4,0.5c-0.2-0.2-0.5-0.5-0.9-0.9c0.7-0.7,1.7-1.8,3.1-3.4c1.4-1.6,2.6-3,3.6-4.2
									c1.8-2.2,3-3.9,3.4-5c1.1,0.4,2.2,1.1,3.3,2c-0.9,0.9-2.2,2.2-3.9,4.1c1.5,0.1,2.8,0.5,3.9,1.4c1.2,1,1.8,2.2,1.7,3.6
									c0,1.4-0.6,2.8-1.9,4.3c-1.4,1.7-2.9,2.7-4.5,2.9c-1.6,0.2-3.1-0.3-4.6-1.6C1745.3,1136.4,1744.8,1135.8,1744.5,1135.2z
									M1749.3,1136.2c0.9-0.2,1.7-0.7,2.6-1.8c0.8-1,1.3-1.9,1.5-2.9c0.2-0.9,0-1.8-0.4-2.6c-0.8-0.7-1.7-1.1-2.8-1.3l-2.4,2.7
									c-1.1,1.3-1.8,2.2-2.1,2.9c0.1,0.9,0.4,1.6,1.1,2.1C1747.5,1136,1748.4,1136.3,1749.3,1136.2z"
						/>
						<path
							d="M1753,1142.9c1.5-1.5,2.9-3.1,4.5-4.9c0.7-0.9,1.3-1.5,1.6-2c0.3-0.5,0.6-0.9,0.7-1.3c0.1-0.4,0.2-0.8,0.1-1.3
									c0.5-0.1,1,0,1.4,0.1s0.8,0.3,1.1,0.6c1,0.8,1,1.9,0.1,3.1c1.6-0.3,3.1-0.2,4.5,0.5c-0.1,0.4-0.4,0.9-0.8,1.5
									c-0.4,0.6-0.8,1.1-1.1,1.6c-0.2-0.3-0.5-0.5-0.8-0.8c-0.5-0.4-1-0.8-1.5-1c-0.5-0.3-1-0.4-1.5-0.5c-1,1.1-1.9,2.1-2.8,3.1
									c-1.4,1.6-2.3,3-2.6,3.9c-0.5-0.3-1.1-0.7-1.6-1.1C1753.9,1143.9,1753.5,1143.4,1753,1142.9z"
						/>
						<path
							d="M1761.1,1149.2c-0.1-0.7,0.1-1.5,0.6-2.2c0.7-1.1,1.8-1.5,3.2-1.4s3.1,0.8,5.3,2c0.6-0.9,0.9-1.7,0.9-2.3
									c-0.1-0.6-0.5-1.2-1.3-1.8c-0.5-0.4-1.1-0.7-1.8-0.9c-0.7-0.2-1.3-0.3-2-0.3l0.4-1c1-0.4,2.1-0.6,3.2-0.5c1.1,0.1,2.1,0.4,2.9,1
									c1.2,0.8,1.8,1.7,2.1,2.7c0.2,1-0.1,2.1-0.8,3.2c-0.4,0.6-0.9,1.3-1.6,2c-0.6,0.8-1.1,1.3-1.4,1.7c-0.4,0.6-0.7,1.2-0.8,1.6
									c-0.1,0.5-0.1,1,0,1.7c-1.1,0.1-2.1-0.1-3-0.6c-0.3-0.7-0.3-1.3,0-2c-0.9,0-1.8-0.1-2.6-0.3c-0.8-0.2-1.5-0.5-2-0.9
									C1761.6,1150.6,1761.2,1150,1761.1,1149.2z M1765.8,1151c0.6,0.1,1.1,0.2,1.6,0.1l0.1-0.2c0.3-0.5,0.7-1,1.1-1.4l0.8-1
									c-1.1-0.7-2.1-1-2.9-1c-0.8,0-1.5,0.3-1.9,0.9c-0.2,0.3-0.3,0.6-0.3,1s0,0.7,0.1,1C1764.8,1150.7,1765.2,1150.9,1765.8,1151z"
						/>
						<path
							d="M1774,1158.2c1-1.8,2-3.8,3-5.9c0.5-1,0.8-1.8,1-2.4c0.2-0.6,0.3-1.1,0.3-1.4c0-0.4-0.1-0.8-0.2-1.3
									c0.5-0.2,1-0.3,1.5-0.3c0.5,0,0.9,0.1,1.2,0.3c1,0.5,1.4,1.3,1,2.5c0.7-0.3,1.5-0.5,2.4-0.5c0.8,0,1.6,0.2,2.3,0.5
									c2.2,1,2.7,2.8,1.5,5.4l-0.9,1.7l-0.9,1.9c-0.9,2-1.4,3.5-1.4,4.5c-0.6-0.1-1.2-0.4-1.8-0.7c-0.5-0.2-1.1-0.6-1.7-1
									c1.1-2.1,2.3-4.4,3.4-6.9c0.3-0.7,0.5-1.4,0.3-1.8s-0.5-0.8-1.2-1.2c-0.9-0.4-1.8-0.6-2.6-0.5l-0.4,0.8c-0.6,1.3-1.2,2.4-1.7,3.4
									c-0.9,2-1.4,3.5-1.4,4.5c-0.6-0.1-1.2-0.3-1.8-0.6C1775.2,1158.9,1774.6,1158.6,1774,1158.2z"
						/>
						<path
							d="M1790.5,1164.6c-0.2-0.5-0.2-1.2,0.1-2.1c0.2-0.6,0.5-1.6,1-2.9c0.5-1.4,0.9-2.7,1.2-3.6c0.1-0.4,0.2-0.8,0.3-1.2
									c-0.8-0.2-1.3-0.5-1.6-0.7c-0.2-0.2-0.3-0.5-0.2-0.9c1.6-0.3,3.2-0.9,4.8-1.8l1,0.4c-0.1,0.7-0.3,1.4-0.5,2.1
									c1.3,0.4,2.3,0.6,3,0.7c-0.1,0.5-0.3,0.8-0.5,1.2s-0.4,0.6-0.7,0.9c-0.5-0.4-1.3-0.8-2.3-1.1c-0.3,0.8-0.6,1.7-0.9,2.6
									c-0.7,2.3-1.1,4.1-1.2,5.4c0.8,0.2,1.6,0.4,2.3,0.4l-0.1,0.9c-0.5,0.4-1.2,0.7-2,0.9c-0.8,0.1-1.5,0.1-2.2-0.1
									C1791.2,1165.5,1790.7,1165.1,1790.5,1164.6z"
						/>
						<path
							d="M1721.6,1099.1c0.6-0.8,1.4-1.8,2.3-3.2c0.9-1.4,1.8-2.7,2.6-4c1.2-1.8,2-3.3,2.6-4.4c0.6-1.1,1-2,1.3-2.8
									c0.9,0.8,2.4,1.8,4.4,3c2.2,1.4,4,2.4,5.3,3c-0.3,0.5-0.8,1.1-1.3,1.6c-0.5,0.6-0.9,0.9-1.2,1c-0.3-0.5-0.6-1-1.2-1.5
									s-1.1-1-1.8-1.4c-0.8-0.5-1.7-1-2.6-1.4c-1,1.4-1.8,2.6-2.5,3.7c0.7,0.5,1.3,0.9,1.7,1.1c0.8,0.5,1.5,0.9,2,1.1
									c0.5,0.2,1,0.5,1.6,0.7c-0.2,0.6-0.4,0.9-0.6,1.2c-0.1,0.2-0.4,0.6-0.8,1.1c-1.8-1.3-3.2-2.3-4.1-2.9l-0.9-0.5
									c-2.1,3.4-3.3,5.6-3.5,6.5c-0.6-0.2-1.2-0.5-1.7-0.9C1722.7,1100,1722.2,1099.6,1721.6,1099.1z"
						/>
						<path
							d="M1733.8,1106.4c0-0.5,0.2-1.1,0.7-2c0.4-0.7,1-1.6,1.9-2.8c0.8-1.1,1.3-2,1.7-2.5c0.5-0.7,0.8-1.4,1.1-2.1
									c0.3-0.7,0.5-1.2,0.5-1.6c0.7,0.2,1.3,0.4,1.9,0.6c0.6,0.2,1.2,0.6,1.8,1c-0.4,0.5-1,1.2-1.8,2.3c-0.8,1.1-1.6,2.3-2.4,3.6
									c-0.6,1-1.1,1.8-1.3,2.3c-0.3,0.6-0.4,1-0.5,1.4s0,0.8,0.1,1.4c-0.5,0.1-1,0.2-1.5,0.1c-0.5-0.1-0.9-0.2-1.2-0.4
									C1734.1,1107.3,1733.8,1106.9,1733.8,1106.4z M1742,1091.4c1-0.3,2-0.5,3-0.7c0.1,1,0.4,2,0.7,3c-1,0.3-2,0.5-3,0.7
									C1742.5,1093.4,1742.3,1092.4,1742,1091.4z"
						/>
						<path
							d="M1740.5,1109.5c-0.2-1.5,0.2-3.1,1.4-4.8c0.8-1.2,1.7-2.1,2.8-2.7c1.1-0.6,2.2-0.9,3.4-0.9c1.2,0,2.4,0.4,3.5,1.1
									c1.5,0.9,2.3,2.1,2.4,3.6c0.2,1.4-0.4,3.1-1.6,5c-1.1-0.4-2.4-0.9-3.9-1.6c-1.5-0.7-2.8-1.4-4.2-2.3c-0.5,1-0.6,1.9-0.4,2.7
									c0.2,0.7,0.7,1.4,1.6,2c0.8,0.5,1.5,0.8,2.3,1.1s1.5,0.4,2.2,0.3l-0.3,1c-1,0.4-2.1,0.5-3.4,0.3c-1.2-0.2-2.3-0.6-3.3-1.2
									C1741.6,1112.1,1740.8,1110.9,1740.5,1109.5z M1747.8,1107c0.9,0.3,1.7,0.5,2.3,0.6c0.5-0.9,0.7-1.8,0.6-2.7
									c-0.1-0.9-0.6-1.6-1.3-2.1c-0.7,0-1.5,0.3-2.3,0.8s-1.5,1.3-2.1,2.2C1745.9,1106.3,1746.9,1106.6,1747.8,1107z"
						/>
						<path
							d="M1752.2,1118.1c0-0.5,0.2-1.1,0.7-2c0.4-0.6,0.9-1.4,1.8-2.6c0.9-1.3,1.6-2.5,2.2-3.3c1.9-2.9,3-5.1,3.5-6.5
									c0.7,0.2,1.3,0.4,1.9,0.6s1.2,0.6,1.8,1c-1.4,1.8-3.6,4.9-6.5,9.4c-0.6,1-1.1,1.8-1.4,2.3c-0.3,0.6-0.4,1-0.5,1.4
									c-0.1,0.4,0,0.8,0.1,1.4c-0.5,0.1-1,0.2-1.5,0.1s-0.9-0.2-1.2-0.4C1752.5,1119,1752.2,1118.6,1752.2,1118.1z"
						/>
						<path
							d="M1759,1121.2c-0.1-1.4,0.4-3.1,1.6-4.9c1.3-2,2.6-3.2,4-3.7c1.4-0.5,2.9-0.3,4.4,0.7c1.2,0.8,2,1.8,2.5,3.1
									c1-1.7,1.7-3.2,2.1-4.3c1.2,0.3,2.4,0.8,3.7,1.6c-1.6,2-4.1,5.6-7.4,10.8c-0.5,0.7-0.8,1.3-0.9,1.8c-0.1,0.5-0.1,1.1,0,1.8
									c-0.5,0.1-1.1,0.1-1.6,0s-1.1-0.3-1.6-0.5c-0.3-0.7-0.3-1.4-0.1-2.1c-0.8,0.2-1.6,0.2-2.3,0.1c-0.8-0.1-1.4-0.4-2-0.7
									C1759.9,1123.9,1759.1,1122.7,1759,1121.2z M1766.3,1124.3c0.3-0.5,1-1.4,1.8-2.7c0.9-1.4,1.6-2.4,2.1-3.2c-0.4-0.8-1-1.4-1.8-2
									c-0.9-0.6-1.8-0.7-2.7-0.4s-1.7,1.1-2.5,2.3c-0.7,1.1-1.1,2.2-1.1,3.1s0.3,1.8,1,2.4C1764,1124.3,1765.1,1124.4,1766.3,1124.3z"
						/>
						<path
							d="M1779.1,1135.3c-0.5-1-0.6-2-0.5-3.1s0.5-2.2,1.2-3.3c0.8-1.2,1.7-2.1,2.8-2.6c1.1-0.6,2.3-0.8,3.5-0.8
									c1.2,0.1,2.4,0.5,3.6,1.3c1.6,1,2.5,2.4,2.8,4c0.3,1.6-0.1,3.2-1.1,4.9c-0.8,1.2-1.7,2.1-2.8,2.6c-1.1,0.6-2.3,0.8-3.6,0.7
									s-2.5-0.5-3.7-1.3C1780.3,1137,1779.5,1136.2,1779.1,1135.3z M1785.5,1137.2c1-0.3,1.8-1,2.6-2.2c0.7-1,1-2.1,1-3.1
									c0-1.1-0.3-2-1-2.8c-1-0.7-2-0.8-3-0.6c-1,0.3-1.9,1-2.6,2.1c-0.8,1.3-1.2,2.5-1.2,3.6c0,1.1,0.5,2,1.4,2.5
									C1783.6,1137.3,1784.5,1137.5,1785.5,1137.2z"
						/>
						<path
							d="M1787.1,1146.5c0.7-0.2,1.2-0.5,1.7-0.8s1-0.8,1.5-1.4c0.5-0.6,1.2-1.5,2-2.7c0.9-1.4,2.3-3.7,4.4-7.1
									c-0.7-0.5-1.1-0.8-1.2-1.1c-0.1-0.2-0.1-0.5,0.1-0.8c0.7,0.1,1.4,0.1,2.3,0.1c1.2-1.3,2.5-2.1,4-2.5c1.4-0.3,2.8-0.1,4,0.8
									c1,0.7,1.8,1.4,2.4,2.2c-0.2,0.3-0.6,0.7-1.1,1.2c-0.5,0.5-1,0.8-1.4,1c-0.3-0.9-0.6-1.6-0.9-2.3c-0.4-0.6-0.7-1.1-1.1-1.3
									c-0.4,0-0.9,0.2-1.3,0.7s-1.1,1.2-1.9,2.3c1.4,0.8,2.3,1.4,2.9,1.6c-0.5,0.7-1.1,1.3-1.8,1.7c-0.5-0.6-1.2-1.2-2.2-1.9l-1.1,1.7
									c-0.8,1.2-1.6,2.5-2.4,3.9c-0.4,0.8-0.8,1.4-1.2,2c-0.6,0.9-1.2,1.5-1.8,2c-0.6,0.5-1.3,0.8-2.2,1.1c-0.9,0.2-2,0.4-3.5,0.5
									L1787.1,1146.5z"
						/>
					</g>
					<g className="level_two fade location " id="north_undeep">
						<rect x="1355.446" y="2004.467" width="88.012" height="44.095" transform="matrix(0.891518, -0.452985, 0.442247, 0.896974, -281.48349, -22.432714)"></rect>
						<path
							d="M1833.3,1190.9c-1.1-0.4-2-1.2-2.7-2.5c-0.7-1.4-1.5-2.7-2.2-4c-0.7-1.3-1.3-2.1-1.6-2.5c0.4-0.3,0.8-0.6,1.2-0.8
									c0.4-0.2,0.8-0.4,1.3-0.5c0.9,2,2,4.2,3.3,6.7c0.9,1.8,2.2,2.3,3.8,1.5c1.7-0.9,2.1-2.1,1.2-3.8c-2-3.8-3.2-6-3.7-6.8
									c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.1,0.6-0.2,0.9-0.3c0.4,0.8,0.9,1.9,1.5,3.2s1.2,2.5,1.7,3.5c0.7,1.3,0.9,2.5,0.6,3.6
									c-0.2,1.1-1.1,2.1-2.7,2.9C1835.5,1191.1,1834.3,1191.2,1833.3,1190.9z"
						/>
						<path
							d="M1845,1186.2c-0.5-1.5-1.4-3.7-2.6-6.4c-1.1-2.6-1.9-4.1-2.3-4.7c0.4-0.3,0.8-0.5,1.2-0.7c0.4-0.2,0.8-0.3,1.4-0.5
									c0.8,0.7,2.1,1.6,3.8,2.9c1.7,1.3,3.3,2.4,4.8,3.5c-0.7-1.6-1.2-2.8-1.6-3.7c-1.1-2.6-1.9-4.2-2.3-4.7c0.2-0.2,0.5-0.4,0.8-0.5
									c0.3-0.1,0.6-0.2,1-0.3c0.7,1.9,1.6,4,2.6,6.4c1.1,2.6,1.9,4.1,2.3,4.7c-0.3,0.2-0.7,0.4-1.1,0.7c-0.4,0.2-0.9,0.3-1.5,0.5
									c-0.9-0.7-2.2-1.7-3.9-3c-1.7-1.3-3.3-2.4-4.6-3.4c0.5,1.2,1,2.5,1.5,3.7c1.1,2.5,1.9,4.1,2.3,4.7c-0.2,0.2-0.5,0.4-0.8,0.5
									C1845.8,1186,1845.4,1186.1,1845,1186.2z"
						/>
						<path
							d="M1860.1,1179.9c-0.7,0.2-1.4,0.3-1.9,0.4c-0.2-0.7-0.5-1.7-0.8-2.9c-0.4-1.2-0.8-2.4-1.2-3.5c-0.9-2.4-1.5-3.8-1.9-4.3
									c0.4-0.3,1-0.7,1.8-1.1c0.8-0.4,1.6-0.7,2.4-1c1.9-0.7,3.6-0.7,5-0.1s2.4,1.9,3.1,3.7c0.4,1.2,0.5,2.3,0.3,3.4
									c-0.2,1.1-0.8,2-1.5,2.9c-0.8,0.8-1.8,1.5-3.1,1.9C1861.5,1179.6,1860.8,1179.8,1860.1,1179.9z M1864,1176.4
									c0.3-0.6,0.5-1.3,0.6-2s0-1.4-0.3-2.1c-1.1-3.1-3-4.1-5.8-3.1c-0.4,0.2-0.9,0.4-1.2,0.7c0.5,1.7,1,3.3,1.6,4.9
									c0.5,1.3,0.9,2.4,1.3,3.3c1.1-0.1,2-0.2,2.5-0.4C1863.2,1177.4,1863.6,1177,1864,1176.4z"
						/>
						<path
							d="M1871.9,1172.3c-0.3-0.9-0.7-2-1.1-3.3c-0.5-1.3-0.9-2.3-1.2-3s-0.6-1.3-0.9-1.7c0.8-0.2,2-0.6,3.6-1.2
									c1.9-0.7,3.4-1.3,4.5-1.8c0.2,0.4,0.3,0.9,0.4,1.4c0.1,0.5,0.1,0.9,0,1.1c-0.4-0.1-0.9,0-1.5,0.1c-0.6,0.1-1.2,0.3-1.8,0.5
									c-0.6,0.2-1.3,0.5-1.9,0.9c0.4,1.1,0.7,2.1,1,3c0.7-0.2,1.2-0.4,1.6-0.5c0.7-0.3,1.2-0.5,1.6-0.6c0.4-0.2,0.8-0.4,1.2-0.7
									c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.2,0.2,0.5,0.2,0.9c-1.7,0.5-3,0.9-3.9,1.2l-0.7,0.3c0.5,1.4,0.9,2.4,1.2,3
									c0.8-0.1,1.5-0.3,2.2-0.6c0.6-0.2,1.3-0.5,1.9-0.9c0.6-0.3,1.1-0.7,1.5-1c0.2,0.4,0.3,0.9,0.4,1.4s0.1,0.9,0,1.1
									c-1,0.3-2.4,0.7-4.1,1.3c-1.6,0.6-2.8,1.1-3.5,1.5C1872.5,1174.4,1872.2,1173.3,1871.9,1172.3z"
						/>
						<path
							d="M1884.6,1167.2c-0.4-0.9-1-1.9-1.6-3.1c-0.7-1.2-1.2-2.2-1.6-2.8c-0.4-0.7-0.8-1.2-1.2-1.6c0.8-0.3,1.9-0.8,3.4-1.7
									c1.8-1,3.2-1.8,4.2-2.4c0.2,0.4,0.4,0.9,0.6,1.4c0.2,0.5,0.2,0.9,0.2,1.1c-0.4,0-0.9,0.1-1.5,0.3c-0.6,0.2-1.1,0.4-1.7,0.7
									c-0.6,0.3-1.2,0.7-1.7,1.1c0.5,1.1,1,2,1.4,2.8c0.7-0.3,1.2-0.6,1.5-0.8c0.7-0.4,1.2-0.6,1.5-0.9c0.3-0.2,0.7-0.5,1.1-0.8
									c0.2,0.3,0.4,0.6,0.5,0.8c0.1,0.2,0.2,0.5,0.4,0.9c-1.6,0.7-2.8,1.3-3.7,1.8l-0.7,0.4c0.7,1.3,1.2,2.2,1.6,2.8
									c0.7-0.2,1.4-0.5,2.1-0.9c0.6-0.3,1.2-0.7,1.7-1.1c0.5-0.4,1-0.8,1.3-1.2c0.2,0.4,0.4,0.9,0.6,1.4c0.2,0.5,0.2,0.9,0.2,1.1
									c-1,0.4-2.2,1-3.9,1.9c-1.5,0.8-2.6,1.4-3.3,1.9C1885.5,1169.1,1885,1168.1,1884.6,1167.2z"
						/>
						<path
							d="M1898.3,1163.3c-0.4-0.7-1-1.5-1.7-2.6c-0.7-1.1-1.5-2.1-2.1-3c-1.5-2.1-2.6-3.3-3.1-3.6c0.3-0.4,0.7-0.9,1.3-1.5
									s1.2-1.1,1.9-1.6c1.3-0.9,2.4-1.4,3.5-1.3s2,0.6,2.8,1.7c0.5,0.7,0.8,1.5,0.9,2.3c0.1,0.8-0.1,1.6-0.5,2.4c-0.4,0.8-1,1.4-1.8,2
									c-0.4,0.3-0.9,0.6-1.3,0.8c1.2,1.6,2,2.5,2.5,2.9c-0.3,0.3-0.7,0.7-1,0.9C1899.2,1162.8,1898.8,1163,1898.3,1163.3z
									M1899.6,1154.6c0-0.6-0.3-1.2-0.7-1.8c-1-1.4-2.3-1.5-3.9-0.4c-0.3,0.3-0.6,0.5-0.8,0.8c0.9,1.4,1.8,2.8,2.8,4.1
									c1.1-0.4,1.8-0.7,2.2-1C1899.6,1155.8,1899.7,1155.3,1899.6,1154.6z"
						/>
						<path
							d="M1832.1,1173c-0.7-1.5-1.7-3.5-3.1-6.1c-1.3-2.5-2.2-4-2.7-4.5c0.4-0.3,0.7-0.5,1.1-0.8c0.3-0.2,0.8-0.4,1.4-0.6
									c0.9,0.6,2.2,1.4,4,2.6c1.8,1.1,3.5,2.1,5,3.1c-0.8-1.5-1.4-2.7-1.9-3.6c-1.3-2.5-2.2-4-2.7-4.5c0.2-0.2,0.5-0.4,0.8-0.6
									c0.3-0.1,0.6-0.3,1-0.4c0.8,1.8,1.9,3.9,3.1,6.1c1.3,2.5,2.2,4,2.7,4.5c-0.3,0.3-0.6,0.5-1.1,0.7c-0.4,0.2-0.9,0.4-1.4,0.6
									c-1-0.6-2.4-1.5-4.2-2.6c-1.8-1.1-3.4-2.1-4.9-3c0.6,1.2,1.2,2.4,1.8,3.6c1.3,2.4,2.2,3.9,2.7,4.5c-0.2,0.2-0.5,0.4-0.8,0.6
									C1832.8,1172.8,1832.5,1172.9,1832.1,1173z"
						/>
						<path
							d="M1846.5,1165c-1-0.1-1.9-0.4-2.7-1c-0.8-0.6-1.5-1.4-2-2.5c-0.6-1.2-0.8-2.3-0.8-3.5c0.1-1.2,0.4-2.2,1-3.2
									c0.6-0.9,1.5-1.7,2.7-2.3c1-0.5,2-0.7,3-0.6s1.9,0.4,2.7,1c0.8,0.6,1.5,1.4,2,2.5c0.6,1.1,0.8,2.3,0.7,3.4
									c-0.1,1.1-0.5,2.2-1.1,3.1s-1.6,1.7-2.8,2.3C1848.5,1164.8,1847.5,1165,1846.5,1165z M1851.2,1160.6c0.3-1,0.1-2.1-0.5-3.3
									c-0.5-1.1-1.3-1.9-2.2-2.4c-0.9-0.5-1.9-0.6-2.9-0.3c-0.7,0.3-1.2,0.8-1.6,1.4c-0.4,0.6-0.6,1.3-0.6,2.1c0,0.8,0.2,1.5,0.6,2.3
									c0.6,1.2,1.4,2.1,2.3,2.5s1.9,0.5,2.9,0C1850.3,1162.4,1851,1161.6,1851.2,1160.6z"
						/>
						<path
							d="M1859.2,1159.3c-0.3-0.7-0.6-1.7-1.1-3c-0.5-1.3-1-2.4-1.4-3.4c-1.1-2.3-1.8-3.7-2.3-4.2c0.4-0.4,0.9-0.8,1.6-1.2
									s1.4-0.8,2.2-1.2c1.4-0.6,2.7-0.8,3.7-0.5c1.1,0.3,1.9,1,2.4,2.2c0.3,0.7,0.4,1.4,0.3,2.1s-0.4,1.4-1,2c2,1.6,3.5,2.6,4.4,2.8
									c-0.3,0.4-0.7,0.7-1.1,0.9c-0.4,0.3-0.9,0.5-1.5,0.8c-0.7-0.7-1.9-1.7-3.7-3c-0.1,0-0.2,0.1-0.4,0.2c-0.5,0.2-1,0.4-1.5,0.5
									c0.9,2,1.6,3.2,2.1,3.7c-0.4,0.3-0.8,0.5-1.2,0.7C1860.2,1159,1859.7,1159.2,1859.2,1159.3z M1862.2,1150.8
									c0.1-0.5,0.1-1.1-0.2-1.6c-0.7-1.6-1.9-2-3.7-1.2c-0.4,0.2-0.7,0.4-1,0.6c0.4,1,0.9,2.4,1.7,4.1c1.1-0.1,1.9-0.3,2.4-0.5
									C1861.8,1151.9,1862,1151.4,1862.2,1150.8z"
						/>
						<path
							d="M1873.7,1152.9c-0.2-0.8-0.6-1.7-1-3c-0.5-1.2-0.9-2.4-1.4-3.5c-0.5-1.2-0.9-2.1-1.3-2.9c-1.7,0.7-2.8,1.3-3.2,1.7
									c-0.2-0.4-0.4-0.7-0.5-1c-0.1-0.3-0.2-0.6-0.3-1.1c0.6-0.2,1.4-0.5,2.4-0.8c1-0.4,1.9-0.7,2.8-1.1c2.1-0.9,3.3-1.5,3.7-1.9
									c0.2,0.4,0.4,0.7,0.5,1c0.1,0.3,0.2,0.7,0.3,1.1c-0.8,0.3-2,0.7-3.4,1.2c0.7,1.8,1.2,3.3,1.8,4.6c1.1,2.6,1.9,4.1,2.4,4.7
									c-0.3,0.2-0.8,0.5-1.2,0.7C1874.7,1152.6,1874.3,1152.8,1873.7,1152.9z"
						/>
						<path
							d="M1882.8,1149.1c-0.2-0.8-0.5-1.8-1-3c-0.4-1.2-0.8-2.4-1.3-3.5c-1-2.6-1.7-4.2-2.2-4.8c0.4-0.2,0.8-0.5,1.3-0.6
									c0.4-0.1,0.9-0.3,1.4-0.4c0.4,1.3,0.9,2.9,1.7,5c0.9-0.3,1.9-0.6,2.9-1.1c0.5-0.2,1-0.4,1.6-0.7c-1-2.6-1.7-4.2-2.2-4.8
									c0.4-0.2,0.8-0.5,1.3-0.6c0.4-0.2,0.9-0.3,1.4-0.4c0.2,0.8,0.5,1.8,1,3c0.4,1.2,0.8,2.4,1.3,3.5c1,2.6,1.8,4.2,2.2,4.8
									c-0.4,0.2-0.8,0.5-1.3,0.6c-0.4,0.1-0.9,0.3-1.4,0.4c-0.4-1.3-0.9-2.9-1.7-5c-1.1,0.4-2,0.7-2.7,1c-0.6,0.2-1.2,0.5-1.9,0.8
									c1,2.6,1.7,4.2,2.2,4.8c-0.4,0.3-0.8,0.5-1.2,0.6C1883.9,1148.9,1883.4,1149,1882.8,1149.1z"
						/>
					</g>
					<g className="level_two fade location " id="limlight">
						<rect x="967.387" y="1204.596" width="62.814" height="26.499" transform="matrix(0.9502, -0.31164, 0.300195, 0.953954, 370.408356, 309.482422)"></rect>
						<rect x="967.387" y="1204.596" width="62.814" height="26.499" transform="matrix(0.898526, 0.43892, -0.449742, 0.89324, 1381.284302, -364.162933)"></rect>
						<path
							d="M1663.2,1170.7c-0.4-0.9-0.9-2.1-1.6-3.5c-1.5-3-2.5-4.8-3.1-5.4c0.4-0.3,0.9-0.6,1.4-0.9c0.5-0.2,1-0.4,1.6-0.6
									c0.3,0.9,0.8,2,1.4,3.5c0.6,1.4,1.2,2.8,1.8,4c0.8,1.6,1.3,2.7,1.7,3.3c0.8-0.2,1.6-0.5,2.5-0.9c0.6-0.3,1.1-0.6,1.7-1
									s1-0.8,1.3-1.2c0.2,0.5,0.4,1,0.6,1.6c0.2,0.6,0.2,1,0.2,1.3c-1.2,0.4-2.5,1-3.9,1.7c-1.8,0.9-3.1,1.6-4,2.1
									C1664.1,1173,1663.6,1171.6,1663.2,1170.7z"
						/>
						<path
							d="M1671,1155.8c0.3-0.8,0.7-1.6,1.1-2.4c0.8,0.4,1.5,0.8,2.4,1.1c-0.3,0.8-0.7,1.6-1.1,2.4
									C1672.6,1156.5,1671.8,1156.1,1671,1155.8z M1676.8,1169c-0.3-0.2-0.7-0.7-0.9-1.5c-0.2-0.6-0.5-1.5-0.9-2.7
									c-0.4-1.1-0.6-1.9-0.8-2.4c-0.2-0.7-0.5-1.3-0.8-1.9c-0.3-0.6-0.6-0.9-0.8-1.1c0.5-0.4,0.9-0.7,1.4-0.9c0.5-0.3,1-0.5,1.6-0.7
									c0.1,0.5,0.3,1.3,0.6,2.3c0.3,1,0.7,2.2,1.2,3.5c0.3,0.9,0.6,1.6,0.9,2.1c0.2,0.5,0.5,0.8,0.7,1.1c0.2,0.2,0.5,0.5,0.9,0.7
									c-0.2,0.4-0.4,0.8-0.7,1s-0.6,0.5-0.9,0.6C1677.6,1169.2,1677.1,1169.2,1676.8,1169z"
						/>
						<path
							d="M1684.3,1166.5c-0.3-1.8-0.6-3.7-1.1-5.7c-0.2-1-0.4-1.7-0.6-2.2c-0.2-0.5-0.4-0.9-0.6-1.1c-0.2-0.3-0.5-0.5-0.9-0.8
									c0.2-0.4,0.5-0.7,0.9-1c0.3-0.2,0.7-0.4,1-0.4c0.9-0.2,1.6,0.2,2,1.2c0.3-0.6,0.8-1.2,1.3-1.6c0.5-0.4,1.1-0.7,1.7-0.8
									c1.5-0.3,2.6,0.1,3.3,1.2c0.3-0.7,0.8-1.3,1.3-1.8c0.6-0.5,1.2-0.8,1.8-0.9c2.1-0.5,3.4,0.5,4,2.9l0.3,1.7l0.3,1.8
									c0.4,1.8,0.9,3.1,1.4,3.8c-0.5,0.2-1,0.4-1.6,0.5c-0.5,0.1-1.1,0.2-1.7,0.2c-0.3-2-0.8-4.2-1.3-6.6c-0.2-0.7-0.4-1.2-0.8-1.4
									c-0.3-0.2-0.8-0.3-1.4-0.2c-0.8,0.2-1.5,0.5-1.9,1c0.1,0.5,0.3,1.3,0.5,2.3l0.4,1.7c0.4,1.8,0.9,3.1,1.4,3.8
									c-0.5,0.2-1,0.4-1.6,0.5c-0.5,0.1-1.1,0.2-1.7,0.2c-0.3-2-0.8-4.2-1.3-6.6c-0.2-0.7-0.4-1.2-0.8-1.4c-0.3-0.2-0.8-0.3-1.4-0.2
									c-0.8,0.2-1.4,0.5-1.9,1l0.1,0.8c0.2,1.2,0.5,2.3,0.7,3.3c0.4,1.8,0.9,3.1,1.4,3.8c-0.5,0.2-1,0.4-1.5,0.5
									C1685.5,1166.4,1684.9,1166.5,1684.3,1166.5z"
						/>
						<path
							d="M1705.5,1162.3c-0.3-0.3-0.4-0.9-0.5-1.7c0-0.6-0.1-1.4-0.1-2.7c-0.1-1.4-0.1-2.5-0.2-3.4c-0.2-2.9-0.5-4.9-1-6.2
									c0.5-0.2,1.1-0.4,1.6-0.5c0.5-0.1,1.1-0.2,1.7-0.2c-0.1,1.9,0,5.1,0.4,9.6c0.1,1,0.2,1.7,0.3,2.3c0.1,0.5,0.2,0.9,0.4,1.2
									c0.2,0.3,0.4,0.6,0.7,0.9c-0.3,0.4-0.6,0.6-1,0.8s-0.7,0.3-1,0.3C1706.2,1162.8,1705.7,1162.6,1705.5,1162.3z"
						/>
						<path
							d="M1713.4,1162c-0.2-0.3-0.3-0.9-0.3-1.7c0-0.7,0.1-1.6,0.2-2.8c0.1-1.1,0.1-2,0.1-2.5c0-0.7,0-1.4-0.1-2
									c-0.1-0.6-0.2-1.1-0.3-1.4c0.6-0.2,1.1-0.3,1.6-0.4s1.1-0.1,1.7-0.1c-0.1,0.5-0.2,1.3-0.3,2.4c-0.1,1.1-0.2,2.3-0.2,3.7
									c0,1,0,1.7,0,2.3c0,0.5,0.1,0.9,0.3,1.3c0.1,0.3,0.3,0.6,0.6,1c-0.3,0.3-0.7,0.6-1,0.7c-0.4,0.1-0.7,0.2-1,0.2
									C1714.1,1162.6,1713.6,1162.4,1713.4,1162z M1713,1147.6c0.6-0.6,1.2-1.2,1.9-1.8c0.5,0.7,1.1,1.3,1.8,1.9
									c-0.6,0.6-1.2,1.2-1.9,1.8C1714.3,1148.8,1713.7,1148.2,1713,1147.6z"
						/>
						<path
							d="M1719.1,1166.2c0.1-0.6,0.3-1.2,0.7-1.8c0.3-0.6,0.8-1,1.3-1.4c-0.3-0.2-0.5-0.4-0.6-0.7c-0.1-0.3-0.1-0.6-0.1-0.9
									c0.1-0.5,0.4-0.9,0.8-1.3c0.4-0.3,1-0.6,1.6-0.7c-0.5-0.4-0.9-0.9-1.1-1.5c-0.2-0.6-0.2-1.3-0.1-2c0.2-0.8,0.5-1.5,1-2.1
									s1.2-0.9,2-1.2c0.8-0.2,1.7-0.2,2.6-0.1c0.9,0.2,1.7,0.5,2.3,1c0.8-0.1,1.8-0.4,2.8-1.1c0.1,0.4,0.2,0.8,0.3,1.3s0,0.8,0,1.1
									c-0.6,0.2-1.2,0.3-2,0.2c0.2,0.6,0.2,1.3,0.1,2c-0.2,0.8-0.5,1.5-1,2.1c-0.5,0.6-1.2,0.9-2,1.2c-0.8,0.2-1.7,0.2-2.6,0
									c-0.8-0.1-1.4-0.1-1.9,0c-0.5,0.1-0.8,0.4-0.8,0.8l4.9,1.2c1,0.3,1.7,0.7,2.2,1.2s0.6,1.2,0.4,2c-0.1,0.7-0.5,1.3-1.1,1.8
									c-0.6,0.5-1.4,0.8-2.3,1c-0.9,0.2-1.9,0.1-3-0.1C1721.4,1167.8,1720,1167.1,1719.1,1166.2z M1726.6,1166.5c0.6-0.2,0.9-0.4,1-0.8
									c0.1-0.3,0-0.6-0.2-0.8c-0.2-0.2-0.6-0.3-1.3-0.5l-3.9-1c-0.3,0.4-0.5,0.8-0.7,1.2c0.5,1,1.4,1.6,2.8,1.9
									C1725.2,1166.6,1726,1166.6,1726.6,1166.5z M1727.2,1158.8c0.5-0.3,0.7-0.8,0.9-1.4c0.1-0.6,0-1.1-0.2-1.6s-0.7-0.9-1.3-1.1
									c-0.7-0.1-1.2,0-1.8,0.4c-0.5,0.4-0.8,0.9-1,1.5c-0.1,0.7,0,1.2,0.3,1.7c0.3,0.5,0.8,0.8,1.4,0.9
									C1726.2,1159.2,1726.8,1159.1,1727.2,1158.8z"
						/>
						<path
							d="M1733.2,1165.5c1-2.1,1.9-4.2,2.8-6.3c1.2-2.9,2-5.2,2.5-6.9c1,0,2.1,0.3,3.3,0.8c-0.6,1.1-1.4,2.6-2.3,4.7
									c0.7-0.3,1.3-0.5,2-0.5c0.7,0,1.4,0.1,2,0.3c2,0.8,2.5,2.3,1.5,4.6l-0.7,1.6l-0.7,1.7c-0.7,1.8-1.1,3.1-1,4
									c-0.5-0.1-1.1-0.3-1.6-0.5c-0.5-0.2-0.9-0.4-1.5-0.8c1-2.1,1.9-4.2,2.7-6.1c0.3-0.7,0.3-1.2,0.2-1.6c-0.1-0.4-0.5-0.7-1.1-1
									c-0.8-0.3-1.6-0.4-2.3-0.3l-0.8,1.8c-1.2,3-1.8,4.9-1.8,5.8c-0.6-0.1-1.1-0.3-1.6-0.5C1734.2,1166.1,1733.7,1165.8,1733.2,1165.5z
									"
						/>
						<path
							d="M1747.5,1171.9c-0.1-0.4,0.1-1,0.5-1.8c0.3-0.5,0.8-1.2,1.4-2.2c0.7-1.1,1.3-2.1,1.8-2.8c0.2-0.3,0.4-0.6,0.5-1
									c-0.6-0.4-1-0.7-1.2-0.9c-0.2-0.2-0.1-0.5,0-0.8c1.4,0.1,2.9-0.1,4.4-0.5l0.7,0.5c-0.3,0.6-0.5,1.1-0.9,1.7c1,0.6,1.8,1,2.4,1.2
									c-0.2,0.4-0.4,0.6-0.7,0.9c-0.2,0.2-0.5,0.4-0.8,0.6c-0.3-0.4-0.9-0.9-1.7-1.4c-0.4,0.6-0.9,1.3-1.3,2c-1.1,1.8-1.8,3.2-2.2,4.3
									c0.6,0.4,1.3,0.6,1.9,0.9l-0.3,0.8c-0.6,0.3-1.2,0.4-1.8,0.3c-0.7-0.1-1.3-0.2-1.8-0.6C1748,1172.7,1747.6,1172.3,1747.5,1171.9z"
						/>
					</g>
					<g className="regions level_one location" id="the_brown_land">
						<rect x="2602.689" y="1328.497" width="168.997" height="29.225" transform="matrix(0.998785, -0.04929, 0.03726, 0.999378, -708.168335, -36.389072)"></rect>
						<rect x="1858.983" y="1282.962" width="120.707" height="28.223" transform="matrix(0.998785, -0.04929, 0.03726, 0.999378, 70.461609, -0.303325)"></rect>
						<path
							d="M1981.8,1206.5c0-1.4-0.1-3.1-0.3-5.2c-0.3-4.6-0.7-7.4-1.2-8.4c0.7-0.2,1.4-0.3,2.2-0.4c0.7,0,1.4,0,2.3,0.1
									c0,1.3,0,3,0.1,5.2c0.1,2.1,0.2,4.1,0.3,5.9c0.2,2.4,0.3,4.1,0.5,5.1c1,0.2,2.2,0.2,3.5,0.1c0.9-0.1,1.7-0.2,2.6-0.5
									c0.9-0.2,1.6-0.5,2.2-0.9c0.1,0.8,0,1.6-0.1,2.4c-0.1,0.8-0.2,1.4-0.4,1.7c-1.7-0.1-3.6,0-5.7,0.1c-2.6,0.2-4.6,0.4-5.9,0.7
									C1981.8,1209.9,1981.8,1207.9,1981.8,1206.5z"
						/>
						<path
							d="M2013.9,1210.3c-0.4-1.1-1-2.6-1.9-4.6c-1.2,0-2.6,0-4.2,0.1c-1.7,0.1-2.8,0.2-3.5,0.3c-0.6,1.9-1,3.5-1.4,4.7
									c-0.5,0.2-0.9,0.3-1.3,0.3c-0.5,0-1,0-1.6-0.1c0.6-1.3,1.6-4.2,3.1-8.9c1.5-4.7,2.5-8.3,3.2-11c0.7-0.2,1.3-0.3,1.8-0.3
									c0.5,0,1.1,0,1.7,0.1c1,2.5,2.4,6.1,4.4,10.6c1.9,4.5,3.3,7.3,4,8.5c-0.7,0.2-1.4,0.3-2.2,0.4
									C2015.4,1210.5,2014.7,1210.4,2013.9,1210.3z M2011,1203.3c-1.5-3.4-2.6-6-3.4-7.7c-0.6,1.6-1.5,4.3-2.5,8c1.2,0,2.5,0,3.9-0.1
									C2010,1203.4,2010.7,1203.3,2011,1203.3z"
						/>
						<path
							d="M2025.9,1209.8c0.1-2.6,0.1-6.3-0.1-11c-0.2-4.5-0.4-7.3-0.7-8.5c0.7-0.2,1.4-0.3,2.1-0.3c0.6,0,1.4,0,2.4,0.1
									c0.8,1.5,2.1,3.7,3.8,6.6c1.8,2.9,3.4,5.6,4.9,8c0-2.8-0.1-4.9-0.1-6.5c-0.2-4.6-0.4-7.4-0.7-8.4c0.5-0.2,1-0.3,1.5-0.3
									c0.5,0,1,0,1.6,0.2c-0.1,3.3-0.1,6.9,0.1,11c0.2,4.5,0.4,7.3,0.7,8.5c-0.6,0.2-1.2,0.3-2,0.3c-0.7,0-1.5,0-2.4-0.1
									c-0.9-1.6-2.2-3.9-4-6.8c-1.8-2.9-3.3-5.5-4.8-7.8c0,2.1,0,4.3,0.1,6.5c0.2,4.4,0.4,7.2,0.7,8.4c-0.4,0.2-0.9,0.3-1.5,0.3
									C2027,1210,2026.5,1210,2025.9,1209.8z"
						/>
						<path
							d="M2053.9,1209.1c-1.2-0.1-2.2-0.2-3-0.4c0.1-1.2,0.2-2.8,0.2-4.9c0-2.1,0-4,0-5.9c-0.1-4.1-0.3-6.6-0.7-7.6
									c0.8-0.3,1.8-0.6,3.2-0.8c1.3-0.2,2.7-0.3,4.1-0.3c3.2,0,5.7,0.8,7.4,2.4s2.7,4.1,2.7,7.2c0,2-0.4,3.7-1.3,5.3
									c-0.9,1.5-2.1,2.7-3.7,3.6c-1.6,0.9-3.5,1.3-5.6,1.4C2056.2,1209.2,2055.1,1209.2,2053.9,1209.1z M2061.5,1205.7
									c0.8-0.8,1.4-1.7,1.8-2.7c0.5-1.1,0.7-2.2,0.7-3.3c-0.1-5.3-2.4-7.8-7-7.8c-0.7,0-1.5,0.1-2.1,0.4c-0.1,2.8-0.2,5.5-0.1,8.3
									c0,2.2,0.1,4.2,0.3,5.8c1.7,0.5,3,0.7,4,0.7C2059.8,1206.9,2060.7,1206.5,2061.5,1205.7z"
						/>
						<path
							d="M2076,1207.9c0-0.6,0.1-1.1,0.1-1.5c0.1-0.4,0.3-0.9,0.5-1.3c0.6,0.6,1.4,1.1,2.3,1.4c1,0.3,2.1,0.5,3.2,0.5
									c1.2,0,2.1-0.2,2.8-0.7c0.7-0.5,1-1.1,1-2c0-0.7-0.3-1.4-0.9-1.9c-0.6-0.5-1.6-1.1-3-1.8c-1.2-0.6-2.2-1.2-3-1.7
									c-0.8-0.5-1.4-1.2-1.9-1.9c-0.5-0.8-0.8-1.7-0.8-2.8c0-1.5,0.7-2.8,2-3.7c1.3-0.9,3.2-1.4,5.4-1.4c2.1,0,3.9,0.3,5.4,0.9
									c0,0.6-0.1,1.3-0.4,2.1c-0.2,0.8-0.5,1.4-0.8,1.9c-1-0.8-2.1-1.5-3.2-2c-1.1-0.5-2-0.8-2.7-0.8c-0.5,0.1-0.9,0.4-1.3,0.8
									c-0.4,0.4-0.6,1-0.6,1.6c0,0.8,0.3,1.5,1,2c0.7,0.5,1.7,1.1,3.1,1.8c1.2,0.6,2.2,1.2,2.9,1.7c0.7,0.5,1.4,1.1,1.9,1.9
									c0.5,0.8,0.8,1.7,0.8,2.7c0,1.8-0.6,3.1-1.9,4.1c-1.2,1-3.1,1.4-5.6,1.4C2080,1209.2,2077.8,1208.8,2076,1207.9z"
						/>
						<path
							d="M1947.5,1186.3c0.1-1.3,0.1-3,0.1-5.2c0-2.2-0.1-4.3-0.1-6.2c-0.1-2.1-0.2-3.8-0.4-5.3c-2.9,0.1-4.7,0.4-5.5,0.7
									c-0.1-0.7-0.2-1.3-0.3-1.9c0-0.5,0-1.1,0.1-1.9c1,0.1,2.2,0.1,3.9,0.1c1.6,0,3.2,0,4.7-0.1c3.5-0.1,5.6-0.4,6.4-0.8
									c0.1,0.7,0.2,1.3,0.3,1.8c0,0.5,0,1.2-0.1,1.9c-1.4-0.1-3.2-0.1-5.5-0.1c0,3.2,0,5.9,0.1,8.1c0.2,4.6,0.5,7.5,0.9,8.6
									c-0.6,0.2-1.4,0.3-2.2,0.3C1949.1,1186.5,1948.4,1186.5,1947.5,1186.3z"
						/>
						<path
							d="M1960,1185.8c0-3.2,0-6.5-0.1-9.7c-0.2-4.4-0.4-7.8-0.8-10.3c1.3-0.6,2.7-0.9,4.4-0.9c-0.1,1.7-0.2,4.2-0.2,7.3
									c0.6-0.8,1.3-1.4,2.2-1.9c0.8-0.5,1.7-0.7,2.6-0.7c2.8-0.1,4.2,1.6,4.4,5.1l0,2.4l0,2.5c0.1,2.7,0.4,4.5,0.9,5.7
									c-0.7,0.2-1.4,0.3-2.2,0.3c-0.6,0-1.4,0-2.2-0.1c0-3.3,0-6.4-0.1-9.4c0-1-0.3-1.7-0.7-2.2c-0.4-0.4-1-0.6-1.8-0.6
									c-1.2,0-2.2,0.4-3,1l0.1,2.8c0.2,4.6,0.5,7.4,1.1,8.5c-0.7,0.2-1.5,0.3-2.2,0.3C1961.6,1186,1960.9,1185.9,1960,1185.8z"
						/>
						<path
							d="M1978.6,1183.6c-1.1-1.3-1.7-3.2-1.8-5.6c-0.1-1.7,0.2-3.2,0.8-4.5c0.6-1.3,1.5-2.4,2.6-3.2c1.1-0.8,2.4-1.2,3.9-1.2
									c2-0.1,3.5,0.6,4.5,1.9c1.1,1.3,1.6,3.3,1.7,5.9c-1.3,0.4-2.8,0.7-4.6,1c-1.8,0.3-3.5,0.5-5.3,0.6c0.2,1.3,0.7,2.3,1.3,2.9
									c0.6,0.6,1.6,0.9,2.7,0.8c1,0,1.9-0.2,2.8-0.5c0.9-0.3,1.6-0.7,2.2-1.2l0.4,1.2c-0.7,1-1.7,1.9-2.9,2.6c-1.2,0.7-2.5,1-3.8,1.1
									C1981.3,1185.5,1979.7,1184.9,1978.6,1183.6z M1983.7,1176.3c1.1-0.3,1.9-0.6,2.5-1c-0.1-1.2-0.5-2.3-1.2-3.1
									c-0.7-0.8-1.5-1.2-2.5-1.1c-0.7,0.5-1.2,1.3-1.6,2.3c-0.4,1.1-0.5,2.3-0.5,3.6C1981.5,1176.8,1982.6,1176.6,1983.7,1176.3z"
						/>
						<path
							d="M2001.9,1184.5c-1.4-0.1-2.6-0.4-3.6-0.6c0.1-1.1,0.1-2.7,0.1-4.9c0-2.1,0-4.2-0.1-6.3c-0.1-4.1-0.4-6.6-0.9-7.5
									c1-0.3,2.2-0.6,3.6-0.9c1.4-0.2,2.9-0.4,4.3-0.4c2.5-0.1,4.5,0.3,5.9,1.2c1.4,0.9,2.1,2.2,2.2,3.9c0,0.9-0.2,1.8-0.8,2.5
									c-0.5,0.8-1.3,1.4-2.3,1.9c1.3,0.4,2.4,1.1,3.1,2c0.8,0.9,1.2,1.9,1.2,3c0.1,1.9-0.6,3.4-2.1,4.5c-1.5,1.1-3.6,1.7-6.4,1.7
									C2004.8,1184.7,2003.3,1184.7,2001.9,1184.5z M2005.7,1173c0.6,0,1.1,0,1.4,0c1.5-1,2.2-2.1,2.1-3.3c-0.1-2.1-1.5-3-4.2-3
									c-1,0-2,0.2-2.8,0.5c0,1.8-0.1,3.9,0,6.3C2003.2,1173.2,2004.4,1173.1,2005.7,1173z M2010.4,1178.6c0-0.9-0.3-1.7-0.9-2.3
									c-0.6-0.7-1.4-1.2-2.4-1.5c-0.5,0-1.3,0.1-2.1,0.3c-0.9,0.2-1.8,0.5-2.8,0.8c0.1,2.8,0.2,5,0.4,6.4c0.9,0.3,1.9,0.4,3,0.4
									C2008.9,1182.6,2010.5,1181.3,2010.4,1178.6z"
						/>
						<path
							d="M2017.2,1183.9c0.1-1.2,0.1-3,0.2-5.2s0-4.3,0-6.1c-0.1-4.3-0.4-6.9-0.8-7.8c0.8-0.3,1.9-0.6,3.3-0.8
									c1.4-0.2,2.8-0.4,4.2-0.4c2.7-0.1,4.7,0.4,6.2,1.6c1.5,1.1,2.2,2.7,2.3,4.9c0,1.3-0.3,2.4-0.9,3.4s-1.6,1.8-2.8,2.4
									c2.1,3.8,3.8,6.2,5,7.1c-0.8,0.3-1.6,0.6-2.4,0.7s-1.8,0.2-2.8,0.2c-0.6-1.4-1.9-3.8-3.8-7c-0.1,0-0.4,0-0.7,0.1
									c-0.9,0-1.8,0-2.7-0.2c0.1,3.6,0.4,5.9,0.8,7c-0.8,0.2-1.6,0.3-2.3,0.3C2019,1184.1,2018.2,1184,2017.2,1183.9z M2027.5,1172.9
									c0.5-0.8,0.8-1.6,0.8-2.5c-0.1-2.9-1.7-4.2-5-4.2c-0.7,0-1.4,0.1-1.9,0.3c-0.1,1.8-0.1,4.3-0.1,7.4c1.9,0.5,3.2,0.8,4.1,0.8
									C2026.2,1174.2,2026.9,1173.6,2027.5,1172.9z"
						/>
						<path
							d="M2039.1,1182.6c-1.5-0.8-2.6-1.9-3.4-3.4c-0.8-1.5-1.2-3.2-1.3-5.1c0-2.1,0.4-4,1.3-5.8c0.9-1.7,2.2-3,3.9-4
									c1.7-1,3.6-1.5,5.8-1.5c1.9,0,3.6,0.3,5.1,1.1c1.5,0.8,2.7,1.9,3.5,3.4c0.8,1.5,1.3,3.2,1.3,5.2c0,2.1-0.4,4-1.3,5.6s-2.3,3-4,3.9
									s-3.7,1.5-5.9,1.5C2042.2,1183.7,2040.5,1183.4,2039.1,1182.6z M2049.5,1179.4c1.1-1.3,1.7-3.1,1.6-5.4c0-2.1-0.6-3.8-1.6-5.1
									c-1-1.4-2.5-2.2-4.2-2.5c-1.3,0-2.4,0.4-3.4,1c-1,0.7-1.8,1.6-2.3,2.7c-0.5,1.2-0.8,2.5-0.8,3.9c0.1,2.3,0.6,4.1,1.8,5.4
									c1.1,1.4,2.6,2,4.5,2C2046.8,1181.4,2048.3,1180.7,2049.5,1179.4z"
						/>
						<path
							d="M2062.7,1182c-0.6-0.7-1.1-1.7-1.6-2.8c-0.5-1.2-1-2.8-1.7-5c-0.7-2.3-1.4-4.3-2-5.9c-0.6-1.6-1.3-3.2-2.1-4.8
									c1.6-0.6,3.4-0.9,5.3-0.9c0.2,1.2,0.5,2.8,1,4.8c0.5,2,1,3.9,1.5,5.5c0.9,2.8,1.7,4.8,2.3,6c2-5.9,3.2-10.2,3.8-12.9l-1.3-2.7
									c1.6-0.6,3.4-0.9,5.3-0.9c0.2,1.2,0.5,2.8,1,4.8c0.5,2,1,3.8,1.5,5.6c0.9,2.6,1.7,4.6,2.3,6c1.5-4.7,2.5-8.2,3.2-10.5s1-4,0.9-4.9
									c0-0.2,0-0.4-0.1-0.6c1.1-0.3,2.4-0.5,3.8-0.5c-0.8,2.2-1.9,5.2-3.1,9.1c-1.2,3.8-2.3,7.3-3.2,10.3l-2.2,1.4
									c-0.8,0-1.5-0.3-2.1-1.1c-0.6-0.7-1.1-1.7-1.6-2.8c-0.5-1.2-1-2.8-1.7-4.9c-0.6-1.7-1-3.1-1.4-4.2c-1.7,5.3-2.9,9.2-3.7,11.8
									l-2.2,1.4C2064,1183.1,2063.3,1182.7,2062.7,1182z"
						/>
						<path
							d="M2087.2,1182.3c0.2-2.7,0.2-6.5,0.2-11.3c-0.1-4.6-0.2-7.5-0.5-8.7c0.8-0.2,1.5-0.2,2.3-0.3c0.7,0,1.5,0.1,2.6,0.2
									c0.9,1.5,2.2,3.8,4,6.8c1.8,3,3.6,5.8,5.2,8.3c0-2.8,0-5.1,0-6.7c-0.1-4.7-0.2-7.6-0.5-8.6c0.5-0.2,1.1-0.2,1.6-0.2
									c0.5,0,1.1,0.1,1.7,0.2c-0.2,3.3-0.2,7.1-0.2,11.3c0.1,4.6,0.2,7.5,0.5,8.7c-0.6,0.2-1.4,0.2-2.2,0.3c-0.7,0-1.6-0.1-2.6-0.2
									c-1-1.7-2.4-4-4.2-7s-3.5-5.7-5-8.1c0,2.2-0.1,4.4,0,6.7c0.1,4.6,0.2,7.4,0.5,8.6c-0.5,0.2-1,0.2-1.6,0.2
									C2088.4,1182.6,2087.8,1182.5,2087.2,1182.3z"
						/>
					</g>
					<g className="level_two fade location " id="south_undeep">
						<rect x="1498.064" y="2077.272" width="97.272" height="45.697" transform="matrix(0.891518, -0.452985, 0.442247, 0.896974, -395.666473, 54.093262)"></rect>
						<path
							d="M1888.2,1262.1c-1.2-0.6-2.1-1.8-2.7-3.5c-0.7-1.8-1.3-3.5-2-5.2c-0.7-1.7-1.2-2.8-1.5-3.2c0.5-0.3,0.9-0.5,1.5-0.7
									c0.4-0.2,1-0.3,1.6-0.4c0.8,2.5,1.8,5.4,3,8.6c0.9,2.3,2.2,3.1,4.2,2.4c2-0.7,2.7-2.2,1.9-4.3c-1.8-4.9-2.9-7.8-3.5-8.8
									c0.3-0.2,0.6-0.4,1-0.5c0.3-0.1,0.7-0.2,1.1-0.2c0.3,1,0.8,2.4,1.3,4.1c0.6,1.7,1.1,3.2,1.6,4.5c0.6,1.6,0.7,3.1,0.3,4.4
									s-1.6,2.3-3.5,3C1890.8,1262.8,1889.4,1262.8,1888.2,1262.1z"
						/>
						<path
							d="M1900.4,1259.1c-0.6-1.9-1.5-4.5-2.8-7.9c-1.2-3.2-2.1-5.1-2.6-5.9c0.4-0.3,0.9-0.5,1.4-0.7c0.4-0.2,1-0.3,1.7-0.5
									c0.9,0.9,2.4,2.1,4.3,3.7s3.8,3.2,5.4,4.5c-0.7-2-1.3-3.5-1.7-4.6c-1.3-3.2-2.1-5.2-2.6-5.8c0.3-0.2,0.6-0.4,0.9-0.5
									c0.3-0.1,0.7-0.2,1.1-0.3c0.8,2.3,1.7,5,2.8,7.9c1.2,3.2,2.1,5.1,2.6,5.9c-0.4,0.3-0.8,0.5-1.3,0.7c-0.5,0.2-1,0.3-1.7,0.5
									c-1-0.9-2.5-2.2-4.5-3.9c-1.9-1.7-3.7-3.1-5.2-4.4c0.5,1.5,1.1,3,1.7,4.6c1.2,3.1,2.1,5.1,2.6,5.8c-0.3,0.2-0.6,0.4-0.9,0.5
									C1901.2,1258.9,1900.8,1259,1900.4,1259.1z"
						/>
						<path
							d="M1915.9,1253c-0.8,0.2-1.5,0.4-2.2,0.5c-0.3-0.9-0.7-2-1.2-3.5c-0.5-1.5-1.1-2.8-1.6-4.1c-1.2-2.9-2-4.6-2.6-5.1
									c0.5-0.4,1.1-0.9,1.9-1.4c0.8-0.5,1.7-0.9,2.7-1.3c2.2-0.9,4.1-1,5.8-0.3s3,2.2,3.9,4.4c0.6,1.4,0.8,2.7,0.6,4
									c-0.2,1.3-0.7,2.5-1.5,3.5c-0.8,1-2,1.9-3.4,2.5C1917.5,1252.5,1916.7,1252.7,1915.9,1253z M1920.1,1248.6
									c0.3-0.7,0.5-1.5,0.5-2.4c0-0.9-0.1-1.7-0.5-2.5c-1.5-3.7-3.8-4.9-6.9-3.6c-0.5,0.2-1,0.5-1.4,0.8c0.7,2,1.4,3.9,2.2,5.9
									c0.6,1.6,1.2,2.9,1.8,4c1.3-0.1,2.3-0.3,2.9-0.6C1919.3,1249.9,1919.8,1249.3,1920.1,1248.6z"
						/>
						<path
							d="M1926.5,1244c-0.4-1.1-1-2.4-1.6-3.9c-0.7-1.6-1.2-2.8-1.7-3.6s-0.9-1.5-1.2-2.1c0.9-0.2,2.3-0.8,4.1-1.5
									c2.2-0.9,3.8-1.7,5-2.4c0.2,0.5,0.4,1.1,0.6,1.7s0.2,1.1,0.2,1.4c-0.5,0-1.1,0-1.8,0.2c-0.7,0.2-1.3,0.4-2,0.7
									c-0.7,0.3-1.4,0.7-2.1,1.1c0.5,1.4,1,2.6,1.4,3.6c0.8-0.3,1.4-0.5,1.8-0.7c0.8-0.3,1.4-0.6,1.8-0.9c0.4-0.2,0.8-0.5,1.3-0.8
									c0.2,0.4,0.4,0.7,0.6,1c0.1,0.2,0.2,0.6,0.4,1.1c-1.9,0.7-3.3,1.2-4.3,1.6l-0.8,0.4c0.7,1.7,1.3,2.9,1.7,3.6
									c0.9-0.2,1.7-0.5,2.4-0.8c0.7-0.3,1.4-0.7,2.1-1.1s1.2-0.9,1.6-1.3c0.2,0.5,0.4,1.1,0.6,1.7s0.2,1.1,0.2,1.4
									c-1.1,0.3-2.7,0.9-4.6,1.8c-1.8,0.8-3.1,1.4-3.9,1.9C1927.5,1246.4,1927,1245.1,1926.5,1244z"
						/>
						<path
							d="M1938,1239c-0.5-1.1-1-2.4-1.7-3.9c-0.7-1.5-1.3-2.7-1.7-3.6c-0.5-0.8-0.9-1.5-1.3-2c0.9-0.3,2.2-0.8,4-1.6
									c2.1-1,3.8-1.8,5-2.5c0.2,0.5,0.4,1.1,0.6,1.7c0.2,0.6,0.2,1.1,0.2,1.4c-0.5,0-1.1,0-1.8,0.2c-0.7,0.2-1.3,0.4-2,0.7
									c-0.7,0.3-1.4,0.7-2.1,1.2c0.5,1.4,1,2.5,1.5,3.6c0.8-0.3,1.4-0.6,1.8-0.8c0.8-0.4,1.4-0.6,1.8-0.9c0.4-0.2,0.8-0.5,1.3-0.9
									c0.2,0.4,0.4,0.7,0.6,1c0.1,0.2,0.2,0.6,0.4,1.1c-1.9,0.7-3.3,1.3-4.3,1.7l-0.8,0.4c0.7,1.6,1.3,2.8,1.7,3.6
									c0.9-0.2,1.7-0.5,2.4-0.8c0.7-0.3,1.4-0.7,2.1-1.2s1.2-0.9,1.6-1.3c0.2,0.5,0.4,1.1,0.6,1.7c0.2,0.6,0.2,1.1,0.2,1.4
									c-1.1,0.4-2.7,1-4.6,1.9c-1.8,0.8-3,1.5-3.9,2C1939,1241.4,1938.4,1240.1,1938,1239z"
						/>
						<path
							d="M1951,1237.6c-0.3-0.9-0.8-2.1-1.4-3.5c-0.6-1.5-1.3-2.8-1.9-4.1c-1.3-2.8-2.3-4.5-2.9-5.1c0.4-0.4,1-0.9,1.8-1.4
									s1.6-1,2.5-1.4c1.6-0.8,3-1,4.3-0.6c1.2,0.4,2.2,1.2,2.9,2.7c0.5,1,0.6,1.9,0.6,2.9c-0.1,1-0.4,1.8-1,2.6
									c-0.6,0.8-1.4,1.4-2.4,1.9c-0.6,0.3-1.1,0.5-1.7,0.6c1.1,2.2,1.8,3.5,2.4,4c-0.4,0.3-0.9,0.6-1.4,0.8
									C1952.1,1237.3,1951.6,1237.5,1951,1237.6z M1954.2,1227.8c0.1-0.8-0.1-1.5-0.4-2.3c-0.9-1.9-2.3-2.4-4.3-1.4
									c-0.4,0.2-0.8,0.5-1.1,0.7c0.7,1.9,1.6,3.7,2.4,5.5c1.3-0.1,2.2-0.4,2.8-0.6C1953.9,1229.2,1954.1,1228.6,1954.2,1227.8z"
						/>
						<path
							d="M1869.2,1254.3c-0.2-0.4-0.3-0.7-0.4-1s-0.1-0.7-0.1-1c0.5,0.2,1.2,0.3,1.9,0.2s1.5-0.3,2.2-0.6c0.8-0.4,1.3-0.8,1.6-1.3
									c0.3-0.5,0.3-1,0-1.6c-0.2-0.5-0.6-0.8-1.2-1c-0.6-0.1-1.4-0.2-2.5-0.3c-1,0-1.8-0.1-2.4-0.2c-0.6-0.1-1.3-0.3-1.8-0.7
									c-0.6-0.4-1-0.9-1.4-1.6c-0.5-1-0.4-2,0.2-3c0.6-1,1.6-1.9,3.1-2.5c1.4-0.6,2.6-1,3.8-1c0.2,0.4,0.3,0.9,0.4,1.5
									c0.1,0.6,0.1,1.1,0,1.5c-0.9-0.2-1.8-0.3-2.6-0.4c-0.8,0-1.5,0.1-2,0.3c-0.3,0.2-0.5,0.5-0.6,0.9s-0.1,0.8,0.1,1.2
									c0.2,0.5,0.7,0.9,1.3,1c0.6,0.2,1.4,0.2,2.5,0.3c1,0.1,1.8,0.1,2.4,0.2c0.6,0.1,1.2,0.3,1.8,0.7c0.6,0.4,1,0.9,1.3,1.6
									c0.5,1.2,0.6,2.3,0.1,3.3c-0.5,1-1.5,1.9-3.1,2.6C1872.1,1254,1870.6,1254.4,1869.2,1254.3z"
						/>
						<path
							d="M1882.7,1248.3c-1.1-0.1-2.1-0.6-3-1.3c-0.9-0.7-1.6-1.7-2.2-3c-0.6-1.4-0.9-2.7-0.9-4.1c0.1-1.3,0.4-2.6,1.2-3.6
									s1.7-1.9,3-2.5c1.1-0.5,2.3-0.7,3.4-0.6c1.1,0.1,2.1,0.6,3.1,1.3c0.9,0.8,1.7,1.8,2.3,3c0.6,1.4,0.9,2.7,0.8,4
									c-0.1,1.3-0.5,2.5-1.3,3.6s-1.8,1.9-3.1,2.5C1884.8,1248.3,1883.7,1248.5,1882.7,1248.3z M1887.9,1243.6c0.3-1.2,0.1-2.5-0.6-3.9
									c-0.6-1.3-1.4-2.3-2.4-2.9s-2.1-0.8-3.2-0.5c-0.8,0.3-1.3,0.9-1.8,1.6c-0.4,0.7-0.6,1.5-0.6,2.4c0,0.9,0.2,1.8,0.6,2.7
									c0.7,1.5,1.5,2.5,2.6,3.1c1.1,0.6,2.1,0.6,3.2,0.1C1886.9,1245.6,1887.6,1244.7,1887.9,1243.6z"
						/>
						<path
							d="M1895.7,1241.4c-1.2-0.5-2.2-1.6-2.9-3.2c-0.8-1.7-1.6-3.3-2.4-4.9c-0.8-1.6-1.4-2.5-1.7-3c0.4-0.3,0.9-0.6,1.4-0.8
									c0.4-0.2,0.9-0.4,1.5-0.5c1,2.4,2.2,5,3.6,8c1,2.2,2.4,2.8,4.2,2c1.9-0.9,2.4-2.3,1.5-4.3c-2.1-4.5-3.5-7.3-4-8.2
									c0.3-0.2,0.6-0.4,0.9-0.6c0.3-0.1,0.6-0.2,1.1-0.3c0.4,1,0.9,2.2,1.6,3.8s1.3,3,1.9,4.2c0.7,1.5,0.9,2.9,0.6,4.2
									c-0.3,1.3-1.3,2.4-3.1,3.2C1898.3,1241.8,1896.9,1241.9,1895.7,1241.4z"
						/>
						<path
							d="M1909.7,1236.3c-0.3-0.9-0.8-2-1.4-3.4c-0.6-1.4-1.2-2.8-1.8-4c-0.6-1.3-1.2-2.5-1.7-3.4c-1.8,0.9-3,1.5-3.4,2
									c-0.3-0.4-0.5-0.8-0.7-1.2c-0.1-0.3-0.3-0.7-0.4-1.3c0.6-0.2,1.5-0.6,2.6-1c1.1-0.5,2.1-0.9,3-1.3c2.2-1,3.6-1.8,4-2.3
									c0.3,0.4,0.5,0.8,0.7,1.1s0.3,0.8,0.5,1.3c-0.9,0.3-2.1,0.8-3.7,1.5c0.9,2.1,1.6,3.8,2.3,5.2c1.4,3,2.4,4.8,3,5.4
									c-0.4,0.3-0.8,0.6-1.3,0.8C1910.9,1236,1910.3,1236.1,1909.7,1236.3z"
						/>
						<path
							d="M1917.9,1232.5c-0.3-0.9-0.8-2-1.4-3.4s-1.2-2.7-1.8-4c-1.4-2.9-2.4-4.8-3-5.4c0.4-0.3,0.9-0.6,1.4-0.8
									c0.4-0.2,0.9-0.4,1.5-0.5c0.5,1.5,1.3,3.4,2.4,5.7c1-0.4,2-0.9,3.2-1.4c0.5-0.2,1.1-0.5,1.7-0.9c-1.4-3-2.4-4.8-3-5.4
									c0.4-0.3,0.9-0.6,1.4-0.8c0.4-0.2,0.9-0.4,1.5-0.5c0.3,0.9,0.8,2,1.4,3.4s1.2,2.7,1.8,4c1.4,3,2.4,4.8,3,5.4
									c-0.4,0.3-0.9,0.6-1.3,0.8c-0.4,0.2-0.9,0.4-1.6,0.6c-0.5-1.4-1.3-3.3-2.4-5.7c-1.2,0.5-2.2,1-2.8,1.3c-0.6,0.3-1.3,0.6-2,1
									c1.4,3,2.4,4.8,3,5.4c-0.4,0.3-0.8,0.6-1.3,0.8C1919,1232.1,1918.5,1232.3,1917.9,1232.5z"
						/>
					</g>
					<g className="level_two fade location" id="west_emnet">
						<rect x="1567.922" y="1371.703" width="84.184" height="47.898"></rect>
						<path
							d="M1574.9,1410.4c0-1.4,0-3-0.1-4.8c-0.1-1.9-0.2-3.5-0.3-4.5c-0.1-1.1-0.3-2-0.5-2.7c1.1,0.1,2.7,0.1,5,0
									c2.7-0.1,4.8-0.3,6.3-0.5c0,0.6,0,1.3-0.1,2.1c-0.1,0.7-0.2,1.2-0.4,1.5c-0.5-0.3-1.2-0.4-1.9-0.6c-0.8-0.1-1.6-0.2-2.4-0.1
									c-0.9,0-1.8,0.1-2.7,0.3c0,1.7,0,3.1,0,4.4c1,0,1.7,0,2.2,0c1,0,1.7-0.1,2.3-0.2c0.5-0.1,1.1-0.2,1.8-0.3c0.1,0.5,0.2,0.9,0.2,1.3
									c0,0.3,0,0.7-0.1,1.3c-2.3-0.1-4.1-0.1-5.3,0l-1,0.1c0.1,2.1,0.2,3.6,0.3,4.5c1,0.2,2,0.2,2.9,0.2c0.9,0,1.8-0.1,2.7-0.3
									c0.9-0.2,1.6-0.4,2.2-0.7c0,0.6,0,1.3-0.1,2.1c-0.1,0.7-0.2,1.2-0.4,1.5c-1.3-0.1-3.2-0.1-5.7,0c-2.2,0.1-3.9,0.2-5,0.4
									C1574.9,1413.4,1574.9,1411.7,1574.9,1410.4z"
						/>
						<path
							d="M1590,1414.6c0.1-2.1,0.1-4.4,0-6.8c0-1.2-0.1-2.1-0.2-2.7c-0.1-0.6-0.2-1.1-0.4-1.5c-0.2-0.4-0.4-0.7-0.8-1.1
									c0.3-0.4,0.7-0.7,1.1-0.9c0.4-0.2,0.8-0.3,1.1-0.3c1.1,0,1.7,0.6,1.9,1.8c0.5-0.7,1.1-1.2,1.7-1.5c0.7-0.4,1.3-0.6,2-0.6
									c1.7,0,2.9,0.7,3.3,2.2c0.5-0.7,1.1-1.3,1.8-1.7c0.7-0.4,1.4-0.6,2.2-0.7c2.4-0.1,3.6,1.4,3.7,4.3l0,2l0,2.1
									c0.1,2.2,0.3,3.8,0.7,4.8c-0.6,0.1-1.2,0.2-1.9,0.2c-0.6,0-1.2,0-1.9-0.1c0.1-2.4,0.1-5.1,0-7.9c0-0.9-0.2-1.5-0.5-1.8
									c-0.3-0.4-0.8-0.5-1.5-0.5c-1,0-1.7,0.3-2.3,0.7c0,0.6,0,1.5,0,2.8l0,2c0.1,2.2,0.3,3.8,0.7,4.8c-0.6,0.1-1.2,0.2-1.9,0.2
									c-0.6,0-1.2,0-1.9-0.1c0.1-2.4,0.1-5.1,0-7.9c0-0.9-0.2-1.5-0.5-1.8c-0.3-0.4-0.8-0.5-1.5-0.5c-0.9,0-1.7,0.3-2.3,0.7l0,0.9
									c0,1.5,0,2.8,0,3.9c0.1,2.2,0.3,3.8,0.7,4.8c-0.6,0.1-1.2,0.2-1.8,0.2C1591.4,1414.7,1590.7,1414.7,1590,1414.6z"
						/>
						<path
							d="M1613,1414c0.1-2.1,0.1-4.4,0.1-6.8c0-1.2-0.1-2.1-0.1-2.7c-0.1-0.6-0.2-1.1-0.4-1.5c-0.2-0.4-0.4-0.7-0.8-1.1
									c0.4-0.4,0.7-0.7,1.2-0.9c0.4-0.2,0.8-0.3,1.1-0.3c1.1,0,1.7,0.6,1.9,1.9c0.5-0.6,1.1-1.2,1.8-1.5s1.4-0.6,2.2-0.6
									c2.3,0,3.5,1.4,3.6,4.4l0,2l0,2.1c0,2.2,0.3,3.8,0.7,4.8c-0.6,0.1-1.2,0.2-1.9,0.2c-0.6,0-1.2,0-1.9-0.2c0.1-2.4,0.1-5.1,0.1-7.9
									c0-0.9-0.2-1.5-0.5-1.8c-0.3-0.4-0.8-0.5-1.5-0.5c-1,0-1.8,0.3-2.5,0.7l0,0.9c0,1.5,0,2.8,0,3.9c0,2.2,0.3,3.8,0.7,4.8
									c-0.6,0.1-1.2,0.2-1.8,0.2C1614.4,1414.2,1613.7,1414.1,1613,1414z"
						/>
						<path
							d="M1628.7,1412.5c-0.9-1.1-1.4-2.7-1.4-4.8c0-1.4,0.3-2.7,0.8-3.8c0.5-1.1,1.3-2,2.3-2.6s2.1-0.9,3.3-0.9
									c1.6,0,2.9,0.6,3.8,1.7s1.3,2.8,1.3,5c-1.1,0.3-2.4,0.5-3.9,0.7s-3,0.3-4.4,0.4c0.2,1.1,0.5,1.9,1,2.5c0.5,0.5,1.3,0.8,2.3,0.8
									c0.8,0,1.6-0.1,2.4-0.3c0.7-0.2,1.4-0.5,1.9-0.9l0.3,1.1c-0.6,0.9-1.5,1.5-2.5,2.1c-1.1,0.5-2.1,0.8-3.2,0.8
									C1630.9,1414.1,1629.6,1413.6,1628.7,1412.5z M1633.1,1406.5c0.9-0.2,1.6-0.5,2.2-0.8c0-1-0.3-1.9-0.9-2.6c-0.6-0.7-1.3-1-2.1-1
									c-0.6,0.4-1,1-1.4,1.9c-0.3,0.9-0.5,1.9-0.5,3C1631.3,1406.9,1632.2,1406.7,1633.1,1406.5z"
						/>
						<path
							d="M1643.6,1413.5c-0.3-0.4-0.5-1.1-0.5-2.1c0-0.6,0-1.7,0.1-3.1c0-1.6,0.1-2.9,0.1-3.9c0-0.4,0-0.9-0.1-1.3
									c-0.8,0-1.4-0.1-1.6-0.2c-0.3-0.1-0.4-0.4-0.4-0.8c1.4-0.8,2.7-1.8,3.9-3.2l1,0.1c0.1,0.7,0.1,1.5,0.1,2.2c1.3,0,2.3-0.1,2.9-0.2
									c0,0.5,0,0.9-0.1,1.3c-0.1,0.4-0.2,0.8-0.4,1.1c-0.6-0.2-1.4-0.4-2.5-0.4c-0.1,0.9-0.1,1.8-0.1,2.8c0,2.4,0.1,4.3,0.4,5.7
									c0.8,0,1.6-0.1,2.3-0.3l0.2,0.9c-0.4,0.6-0.9,1.1-1.6,1.4c-0.7,0.4-1.4,0.5-2.1,0.5C1644.5,1414.1,1643.9,1413.9,1643.6,1413.5z"
						/>
						<path
							d="M1583.5,1391.2c-0.5-0.6-0.9-1.4-1.2-2.4s-0.8-2.4-1.3-4.2c-0.6-1.9-1.1-3.6-1.6-5c-0.5-1.4-1-2.7-1.6-4.1
									c1.3-0.5,2.6-0.7,4.1-0.8c0.1,1,0.4,2.3,0.8,4c0.4,1.7,0.8,3.2,1.2,4.7c0.7,2.3,1.3,4,1.8,5c1.5-5,2.4-8.6,2.8-10.9l-1-2.3
									c1.2-0.5,2.6-0.7,4.1-0.8c0.2,1,0.4,2.3,0.8,4c0.4,1.7,0.8,3.2,1.2,4.7c0.7,2.2,1.3,3.9,1.8,5c1.1-3.9,1.9-6.9,2.4-8.9
									s0.7-3.4,0.7-4.1c0-0.2,0-0.3-0.1-0.5c0.9-0.3,1.8-0.4,2.9-0.4c-0.6,1.9-1.4,4.4-2.3,7.7c-0.9,3.2-1.7,6.1-2.4,8.7l-1.7,1.2
									c-0.6,0-1.2-0.3-1.6-0.9c-0.5-0.6-0.9-1.4-1.2-2.4s-0.8-2.4-1.3-4.2c-0.4-1.4-0.8-2.6-1.1-3.5c-1.3,4.4-2.2,7.8-2.8,10l-1.7,1.2
									C1584.5,1392.1,1583.9,1391.8,1583.5,1391.2z"
						/>
						<path
							d="M1604.5,1390.1c-0.9-1.1-1.4-2.7-1.4-4.8c0-1.4,0.2-2.7,0.8-3.8s1.3-2,2.3-2.6s2.1-0.9,3.3-0.9c1.6,0,2.9,0.5,3.8,1.7
									c0.9,1.1,1.3,2.8,1.3,5c-1.1,0.3-2.4,0.5-3.9,0.8c-1.5,0.2-3,0.3-4.4,0.4c0.2,1.1,0.5,1.9,1,2.5c0.5,0.5,1.3,0.8,2.3,0.8
									c0.8,0,1.6-0.1,2.4-0.3c0.7-0.2,1.4-0.5,1.9-0.9l0.3,1.1c-0.6,0.9-1.5,1.6-2.5,2.1c-1.1,0.5-2.1,0.8-3.2,0.8
									C1606.7,1391.7,1605.4,1391.2,1604.5,1390.1z M1608.9,1384c0.9-0.2,1.6-0.5,2.2-0.8c0-1-0.4-1.9-0.9-2.6c-0.6-0.7-1.3-1-2.1-1
									c-0.6,0.4-1,1-1.4,1.9c-0.3,0.9-0.5,1.9-0.5,3C1607.1,1384.4,1608,1384.3,1608.9,1384z"
						/>
						<path
							d="M1617.1,1390.5c0-0.8,0.2-1.7,0.6-2.5c0.4,0.5,0.9,0.9,1.6,1.2c0.7,0.3,1.4,0.5,2.1,0.5c1.7,0,2.6-0.5,2.6-1.5
									c0-0.4-0.2-0.8-0.6-1.1s-1-0.6-1.9-1c-0.9-0.4-1.6-0.8-2.2-1.1c-0.5-0.3-1-0.8-1.4-1.4c-0.4-0.6-0.6-1.3-0.6-2.2
									c0-1,0.5-1.9,1.3-2.6c0.9-0.7,2.1-1,3.5-1c1.8,0,3.4,0.3,4.6,0.8c0,0.4-0.1,0.9-0.3,1.6c-0.2,0.7-0.4,1.2-0.7,1.7
									c-0.8-0.7-1.5-1.2-2.2-1.6c-0.7-0.4-1.3-0.6-1.8-0.6c-0.4,0.1-0.8,0.2-1,0.5c-0.3,0.2-0.4,0.5-0.4,0.8c0,0.5,0.2,0.9,0.6,1.3
									c0.4,0.3,1.1,0.7,2,1.1c0.8,0.4,1.5,0.8,2.1,1.1c0.5,0.3,1,0.8,1.4,1.3c0.4,0.5,0.6,1.2,0.6,2c0,1.2-0.5,2.2-1.4,2.9
									c-0.9,0.7-2.2,1-3.9,1C1619.8,1391.7,1618.3,1391.3,1617.1,1390.5z"
						/>
						<path
							d="M1631.5,1391.1c-0.3-0.4-0.5-1.1-0.5-2.1c0-0.6,0-1.7,0.1-3.1c0.1-1.6,0.1-2.9,0.1-3.9c0-0.4,0-0.9,0-1.3
									c-0.8,0-1.4-0.1-1.6-0.2c-0.3-0.1-0.4-0.4-0.4-0.8c1.4-0.8,2.7-1.8,4-3.2l1,0.1c0.1,0.7,0.1,1.5,0.1,2.2c1.3,0,2.3-0.1,3-0.2
									c0,0.5-0.1,0.9-0.1,1.3s-0.2,0.7-0.4,1.1c-0.6-0.2-1.4-0.4-2.5-0.4c-0.1,0.9-0.1,1.8-0.1,2.8c0,2.4,0.1,4.3,0.3,5.7
									c0.8,0,1.6-0.1,2.3-0.3l0.2,0.9c-0.4,0.6-0.9,1.1-1.6,1.4c-0.7,0.4-1.4,0.5-2.1,0.5C1632.4,1391.7,1631.8,1391.5,1631.5,1391.1z"
						/>
					</g>
					<g className="level_two fade location" id="westfold">
						<rect x="1795.119" y="960.088" width="115.604" height="23.829" transform="matrix(0.989512, 0.14445, 0.017138, 1.013101, -344.755981, 147.953033)"></rect>
						<path
							d="M1450.4,1395.5c-0.2-0.6-0.3-1.4-0.4-2.2s0-2.1,0.1-3.7c0-1.7,0.1-3.2,0.1-4.4c0-1.2-0.1-2.5-0.2-3.7
									c1.1-0.1,2.3,0.1,3.5,0.5c-0.2,0.8-0.3,2-0.4,3.4c-0.1,1.5-0.2,2.8-0.3,4.1c0,2.1,0,3.6,0.1,4.5c2.5-3.6,4.2-6.3,5.2-8l-0.2-2.1
									c1.1-0.1,2.3,0.1,3.5,0.5c-0.1,0.8-0.3,2-0.4,3.4c-0.1,1.4-0.2,2.8-0.3,4.1c0,1.9,0,3.4,0.1,4.5c1.9-2.9,3.4-5,4.3-6.5
									s1.5-2.5,1.6-3.1c0-0.1,0.1-0.3,0.1-0.4c0.8,0,1.6,0.2,2.4,0.4c-1,1.3-2.3,3.2-3.9,5.5c-1.6,2.3-3,4.4-4.2,6.3l-1.7,0.5
									c-0.5-0.2-0.9-0.5-1.1-1.1s-0.3-1.4-0.4-2.2c0-0.9,0-2.1,0-3.7c0-1.3,0.1-2.3,0.1-3.1c-2.2,3.2-3.8,5.6-4.9,7.3l-1.7,0.5
									C1451,1396.5,1450.6,1396.1,1450.4,1395.5z"
						/>
						<path
							d="M1469.1,1397.4c0.3-1.1,0.7-2.4,1.1-3.9c0.4-1.6,0.7-2.8,0.9-3.7c0.2-0.9,0.3-1.7,0.3-2.3c0.8,0.4,2.2,0.8,4,1.3
									c2.2,0.6,3.9,1,5.2,1.2c-0.1,0.5-0.3,1.1-0.6,1.6c-0.3,0.6-0.5,0.9-0.7,1.1c-0.3-0.3-0.8-0.7-1.4-0.9c-0.6-0.3-1.2-0.5-1.9-0.7
									c-0.7-0.2-1.5-0.3-2.3-0.4c-0.4,1.3-0.8,2.5-1.1,3.6c0.8,0.2,1.4,0.4,1.8,0.5c0.8,0.2,1.4,0.4,1.9,0.4c0.4,0.1,0.9,0.1,1.5,0.2
									c0,0.4-0.1,0.8-0.2,1.1c-0.1,0.2-0.2,0.6-0.4,1.1c-1.8-0.6-3.2-1.1-4.3-1.4l-0.8-0.2c-0.5,1.7-0.8,2.9-0.9,3.7
									c0.8,0.4,1.5,0.7,2.3,0.9c0.7,0.2,1.5,0.3,2.2,0.4s1.4,0.1,2,0c-0.1,0.5-0.3,1.1-0.6,1.6c-0.3,0.6-0.5,0.9-0.7,1.1
									c-1.1-0.4-2.6-0.9-4.6-1.4c-1.8-0.5-3.2-0.8-4.1-0.9C1468.4,1399.8,1468.8,1398.5,1469.1,1397.4z"
						/>
						<path
							d="M1480.3,1403.8c0.1-0.4,0.2-0.7,0.4-1c0.1-0.3,0.3-0.6,0.5-0.9c0.3,0.5,0.8,1,1.4,1.4c0.6,0.4,1.3,0.7,2.1,0.9
									c0.8,0.2,1.5,0.2,2,0c0.5-0.2,0.9-0.6,1-1.2c0.1-0.5,0-1-0.3-1.5s-0.9-1.1-1.8-1.8c-0.7-0.6-1.3-1.2-1.8-1.7c-0.4-0.5-0.8-1-1-1.7
									c-0.2-0.6-0.3-1.3-0.1-2.1c0.3-1.1,0.9-1.8,2-2.3s2.4-0.5,4-0.1c1.5,0.4,2.7,0.9,3.6,1.5c-0.1,0.4-0.3,0.9-0.6,1.4
									c-0.3,0.5-0.6,0.9-0.9,1.2c-0.6-0.7-1.2-1.4-1.8-1.9c-0.7-0.5-1.2-0.9-1.7-1c-0.3,0-0.7,0.1-1,0.3c-0.3,0.2-0.6,0.6-0.7,1
									c-0.1,0.6,0,1.1,0.3,1.6s1,1.1,1.8,1.8c0.7,0.6,1.3,1.2,1.7,1.7c0.4,0.5,0.8,1,1,1.6c0.2,0.6,0.3,1.3,0.1,2.1
									c-0.3,1.3-1,2.1-2,2.6c-1,0.5-2.4,0.5-4.1,0.1C1482.8,1405.4,1481.4,1404.7,1480.3,1403.8z"
						/>
						<path
							d="M1496,1408.3c0.3-0.9,0.6-2.1,1-3.6c0.4-1.5,0.7-2.9,1.1-4.3c0.3-1.4,0.6-2.7,0.7-3.7c-2-0.4-3.3-0.6-3.9-0.5
									c0-0.5,0.1-1,0.2-1.3c0.1-0.3,0.2-0.8,0.4-1.3c0.6,0.2,1.5,0.5,2.6,0.8c1.1,0.3,2.2,0.6,3.2,0.8c2.4,0.6,3.9,0.8,4.6,0.7
									c0,0.5-0.1,0.9-0.2,1.3s-0.2,0.8-0.4,1.3c-0.9-0.3-2.2-0.7-3.8-1.1c-0.6,2.2-1.1,4-1.4,5.5c-0.7,3.2-1.1,5.2-1,6.1
									c-0.5,0-1-0.1-1.5-0.2C1497.1,1408.7,1496.5,1408.5,1496,1408.3z"
						/>
						<path
							d="M1506.4,1410.6c0.2-0.8,0.5-1.9,0.8-3.3c0.3-1.4,0.6-2.7,0.8-3.9c0.3-1.8,0.6-3.2,0.7-4.2c0.1-1,0.2-1.9,0.2-2.6
									c0.9,0.3,2.3,0.6,4.1,1c2.1,0.4,3.7,0.6,4.8,0.7c-0.1,0.5-0.3,1.1-0.5,1.7c-0.2,0.6-0.4,1-0.6,1.2c-0.3-0.3-0.7-0.6-1.3-0.8
									s-1.1-0.4-1.7-0.5c-0.7-0.1-1.5-0.2-2.3-0.2c-0.3,1.4-0.6,2.6-0.8,3.6c0.7,0.2,1.2,0.3,1.6,0.4c0.8,0.1,1.4,0.2,1.8,0.3
									c0.4,0,0.9,0,1.4,0c0,0.5-0.1,0.9-0.1,1.1c0,0.2-0.1,0.6-0.3,1.1c-1.7-0.5-3-0.8-3.9-1l-0.8-0.1c-0.6,3.3-0.9,5.4-0.8,6.2
									c-0.5,0-1,0-1.5-0.1C1507.5,1411,1507,1410.8,1506.4,1410.6z"
						/>
						<path
							d="M1521.8,1412.7c-0.9-0.7-1.5-1.6-1.9-2.7s-0.5-2.3-0.3-3.7c0.2-1.5,0.6-2.8,1.4-3.9c0.8-1.1,1.7-1.9,2.9-2.5
									c1.2-0.5,2.5-0.7,3.8-0.6c1.2,0.1,2.3,0.6,3.2,1.3c0.9,0.7,1.5,1.6,1.9,2.7c0.4,1.1,0.5,2.4,0.4,3.8c-0.2,1.5-0.6,2.8-1.4,3.8
									c-0.8,1.1-1.7,1.9-2.9,2.4c-1.2,0.5-2.5,0.7-3.9,0.5C1523.6,1413.8,1522.6,1413.4,1521.8,1412.7z M1528.7,1411.4
									c0.8-0.8,1.4-2.1,1.5-3.6c0.2-1.4,0-2.7-0.6-3.8c-0.5-1.1-1.4-1.8-2.5-2.1c-0.8-0.1-1.6,0-2.3,0.4s-1.3,0.9-1.8,1.7
									c-0.5,0.8-0.8,1.7-0.9,2.7c-0.2,1.6,0,2.9,0.6,4c0.6,1.1,1.5,1.7,2.7,1.8C1526.8,1412.6,1527.9,1412.2,1528.7,1411.4z"
						/>
						<path
							d="M1537.5,1410.5c0.1-1,0.2-2.3,0.2-3.8c0.2-3.4,0.1-5.4-0.1-6.2c0.5-0.1,1-0.1,1.6-0.1c0.5,0,1,0.1,1.6,0.2
									c-0.1,0.9-0.3,2.2-0.4,3.7c-0.1,1.6-0.2,3-0.3,4.3c-0.1,1.8-0.1,3-0.1,3.8c0.7,0.2,1.5,0.3,2.5,0.4c0.6,0,1.2,0,1.8-0.1
									c0.6-0.1,1.2-0.3,1.6-0.5c0,0.6-0.1,1.1-0.2,1.7c-0.1,0.6-0.3,1-0.4,1.2c-1.2-0.2-2.5-0.3-4-0.4c-1.9-0.1-3.3-0.1-4.2,0
									C1537.3,1412.9,1537.5,1411.5,1537.5,1410.5z"
						/>
						<path
							d="M1552,1415.2c-0.8-0.1-1.5-0.2-2.1-0.3c0.1-0.9,0.1-2,0.1-3.6c0-1.5,0-2.9,0-4.3c-0.1-3-0.2-4.8-0.5-5.5
									c0.6-0.2,1.3-0.4,2.2-0.6c0.9-0.2,1.9-0.2,2.9-0.3c2.3,0,4,0.5,5.3,1.8s1.9,3,1.9,5.3c0,1.4-0.3,2.7-0.9,3.8
									c-0.6,1.1-1.5,2-2.6,2.6c-1.1,0.6-2.4,1-3.9,1C1553.6,1415.3,1552.8,1415.2,1552,1415.2z M1557.4,1412.7c0.5-0.6,1-1.2,1.3-2
									c0.3-0.8,0.5-1.6,0.4-2.4c-0.1-3.8-1.7-5.7-5-5.7c-0.5,0-1,0.1-1.5,0.3c-0.1,2-0.1,4-0.1,6.1c0,1.6,0.1,3,0.2,4.2
									c1.2,0.3,2.2,0.5,2.8,0.5C1556.2,1413.6,1556.8,1413.2,1557.4,1412.7z"
						/>
					</g>
					<g className="level_two fade location " id="sarn_gebir">
						<rect x="2077.183" y="1443.006" width="134.878" height="31.744" transform="matrix(0.999253, -0.038638, 0.038638, 0.999253, -395.210236, 74.559822)"></rect>
						<path
							d="M1740.2,1460.3c0-0.6,0-1.1,0.1-1.5s0.2-0.9,0.4-1.4c0.6,0.6,1.4,1,2.4,1.3c1,0.3,2.1,0.4,3.2,0.4c1.2,0,2.1-0.3,2.7-0.8
									c0.6-0.5,0.9-1.2,0.9-2.1c0-0.8-0.4-1.4-1-1.9s-1.7-1.1-3.1-1.7c-1.3-0.6-2.3-1.1-3-1.6c-0.8-0.5-1.4-1.1-2-1.9
									c-0.6-0.8-0.9-1.7-0.9-2.8c-0.1-1.6,0.6-2.9,1.9-3.9c1.3-1,3.1-1.6,5.3-1.6c2.1-0.1,3.9,0.2,5.4,0.7c0,0.6-0.1,1.4-0.3,2.2
									c-0.2,0.8-0.5,1.5-0.8,2c-1.1-0.8-2.1-1.4-3.2-1.9c-1.1-0.5-2-0.7-2.7-0.7c-0.5,0.1-0.9,0.4-1.3,0.9c-0.4,0.5-0.6,1-0.5,1.6
									c0,0.8,0.4,1.5,1.1,2s1.7,1.1,3.1,1.7c1.2,0.6,2.2,1.1,3,1.6c0.8,0.5,1.4,1.1,2,1.8s0.8,1.7,0.9,2.8c0.1,1.8-0.5,3.2-1.7,4.3
									c-1.2,1-3,1.6-5.5,1.7C1744.1,1461.5,1742,1461.1,1740.2,1460.3z"
						/>
						<path
							d="M1755.6,1460.1c-0.6-0.7-0.9-1.5-1-2.5c-0.1-1.6,0.6-2.8,1.9-3.7c1.3-0.9,3.4-1.5,6.3-1.8c-0.1-1.4-0.3-2.4-0.8-2.9
									c-0.5-0.6-1.3-0.8-2.4-0.8c-0.7,0-1.5,0.2-2.3,0.5s-1.4,0.6-2.1,1.1l-0.3-1.2c0.7-1.1,1.5-2.1,2.6-2.8c1.1-0.7,2.2-1.1,3.4-1.2
									c1.6-0.1,2.9,0.3,3.8,1.2c0.9,0.8,1.4,2.1,1.4,3.7c0,0.9,0,1.9-0.1,3.1c-0.1,1.2-0.1,2.1,0,2.7c0,1,0.2,1.7,0.4,2.2
									s0.6,1.1,1.2,1.7c-0.9,0.8-2,1.4-3.2,1.6c-0.7-0.5-1.2-1.1-1.4-2c-0.8,0.7-1.7,1.2-2.6,1.6s-1.7,0.6-2.5,0.6
									C1757,1461,1756.2,1460.7,1755.6,1460.1z M1761.2,1458.3c0.6-0.3,1.1-0.6,1.6-1l0-0.3c0-0.7,0-1.5,0-2.2l0.1-1.5
									c-1.5,0.2-2.6,0.5-3.4,1.1c-0.8,0.6-1.2,1.4-1.1,2.3c0,0.4,0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.7,0.8,0.9
									C1760,1458.8,1760.6,1458.6,1761.2,1458.3z"
						/>
						<path
							d="M1770.4,1460.1c0.1-2.5,0.1-5.2,0-8.1c0-1.4-0.1-2.5-0.2-3.2s-0.3-1.3-0.5-1.7c-0.2-0.4-0.5-0.9-0.9-1.3
									c0.4-0.5,0.8-0.9,1.3-1.1s0.9-0.4,1.3-0.4c1.5,0,2.3,0.9,2.5,2.7c1.1-1.7,2.5-2.8,4.2-3.3c0.2,0.5,0.4,1.1,0.5,2
									c0.1,0.8,0.2,1.6,0.3,2.4c-0.4-0.1-0.8-0.1-1.3-0.1c-0.7,0-1.4,0.1-2.1,0.4s-1.2,0.5-1.6,0.8c0,1.8,0,3.5,0.1,5.1
									c0.1,2.7,0.4,4.5,0.9,5.7c-0.7,0.2-1.4,0.3-2.2,0.3C1772,1460.3,1771.3,1460.3,1770.4,1460.1z"
						/>
						<path
							d="M1780.9,1459.8c0.1-2.5,0.1-5.2,0-8.1c0-1.4-0.1-2.5-0.2-3.2c-0.1-0.7-0.3-1.3-0.4-1.7c-0.2-0.4-0.5-0.9-0.9-1.3
									c0.4-0.5,0.9-0.9,1.4-1.1c0.5-0.2,0.9-0.4,1.3-0.4c1.3,0,2.1,0.7,2.3,2.2c0.6-0.8,1.3-1.4,2.2-1.9c0.8-0.5,1.7-0.7,2.6-0.7
									c2.8-0.1,4.2,1.6,4.3,5.1l0,2.4l0,2.5c0.1,2.7,0.4,4.5,0.9,5.7c-0.7,0.2-1.4,0.3-2.2,0.3c-0.7,0-1.4,0-2.3-0.2
									c0.1-2.9,0.1-6,0-9.4c0-1-0.2-1.7-0.6-2.2c-0.4-0.4-1-0.6-1.8-0.6c-1.2,0-2.2,0.3-2.9,0.9l0,1.1c0,1.8,0,3.3,0.1,4.7
									c0.1,2.7,0.4,4.5,0.9,5.7c-0.7,0.2-1.4,0.3-2.1,0.3C1782.5,1460,1781.7,1459.9,1780.9,1459.8z"
						/>
						<path
							d="M1804.9,1457c-1.5-1.6-2.3-4-2.4-7c-0.1-2.2,0.3-4.1,1.1-5.8c0.8-1.7,1.9-3.1,3.3-4c1.4-1,3.1-1.5,5-1.6
									c2.2-0.1,4.1,0.2,5.6,0.7c0,0.7-0.1,1.4-0.3,2.2c-0.2,0.8-0.5,1.5-0.8,1.9c-1-0.8-2.2-1.4-3.3-1.9c-1.2-0.5-2.1-0.7-2.8-0.7
									c-0.8,0.2-1.4,0.7-2.1,1.5c-0.6,0.8-1.1,1.8-1.5,2.9c-0.3,1.1-0.5,2.3-0.5,3.5c0.1,2.7,0.6,4.6,1.6,5.8c1,1.2,2.5,1.8,4.5,1.7
									c0.8,0,1.6-0.1,2.4-0.4c-0.1-3.8-0.4-6.1-0.8-7.1c0.6-0.2,1.3-0.3,2-0.3c0.6,0,1.4,0,2.3,0.2c-0.1,2-0.2,4.6-0.1,7.8
									c-0.8,0.8-1.8,1.5-3.1,2c-1.3,0.5-2.7,0.8-4,0.8C1808.5,1459.4,1806.4,1458.6,1804.9,1457z"
						/>
						<path
							d="M1821.6,1457c-1.1-1.3-1.7-3.2-1.8-5.7c0-1.7,0.2-3.2,0.8-4.5c0.6-1.3,1.5-2.4,2.6-3.1s2.5-1.2,4-1.2
									c2-0.1,3.5,0.6,4.5,1.9c1,1.3,1.6,3.3,1.7,5.9c-1.3,0.4-2.8,0.7-4.6,1c-1.8,0.3-3.5,0.5-5.3,0.5c0.2,1.3,0.7,2.3,1.3,2.9
									c0.6,0.6,1.5,0.9,2.7,0.8c1,0,1.9-0.2,2.8-0.4c0.9-0.3,1.6-0.6,2.2-1.1l0.4,1.2c-0.7,1-1.7,1.9-2.9,2.5c-1.2,0.7-2.5,1-3.8,1
									C1824.3,1458.9,1822.7,1458.3,1821.6,1457z M1826.7,1449.7c1.1-0.3,1.9-0.6,2.5-1c-0.1-1.2-0.5-2.3-1.1-3.1s-1.5-1.2-2.5-1.2
									c-0.7,0.5-1.2,1.2-1.6,2.3c-0.4,1.1-0.6,2.3-0.5,3.6C1824.6,1450.3,1825.7,1450,1826.7,1449.7z"
						/>
						<path
							d="M1839.6,1457.9c-0.8-0.3-1.5-0.8-2-1.3l-0.9,1.6c-0.4,0-0.8,0-1.4-0.2c0.1-1.2,0.1-3.1,0.1-5.7c0-2.6,0-4.9-0.1-6.8
									c-0.1-3.5-0.4-6-0.8-7.4c1.3-0.5,2.7-0.8,4.4-0.9c-0.1,1.5-0.2,3.8-0.2,6.9c1.4-1.2,2.8-1.8,4.4-1.8c1.8,0,3.2,0.6,4.2,1.9
									s1.6,3.1,1.7,5.5c0.1,2.7-0.5,4.8-1.7,6.3s-2.9,2.2-5.2,2.3C1841.3,1458.4,1840.4,1458.2,1839.6,1457.9z M1844.5,1454.9
									c0.6-0.9,0.9-2.1,0.9-3.7c0-1.6-0.3-2.8-0.9-3.8c-0.6-1-1.3-1.7-2.3-2c-1.2,0-2.3,0.4-3.4,1l0,4.4c0.1,2,0.2,3.5,0.4,4.4
									c0.7,0.8,1.6,1.1,2.5,1.1C1843,1456.2,1843.9,1455.8,1844.5,1454.9z"
						/>
						<path
							d="M1850.3,1437.2c0.7-1,1.5-1.8,2.4-2.6c0.8,0.9,1.6,1.8,2.5,2.5c-0.7,1-1.5,1.8-2.4,2.6
									C1852,1438.8,1851.2,1438,1850.3,1437.2z M1852,1457.4c-0.3-0.4-0.5-1.3-0.5-2.4c0-0.9,0-2.3,0-4c0-1.6,0-2.8,0-3.6
									c0-1-0.1-2-0.2-2.8c-0.1-0.9-0.3-1.5-0.5-1.9c0.7-0.3,1.4-0.5,2.1-0.6s1.4-0.2,2.3-0.2c-0.1,0.7-0.2,1.9-0.2,3.4
									c0,1.5,0,3.3,0,5.2c0,1.4,0.1,2.5,0.2,3.2c0.1,0.7,0.2,1.3,0.4,1.7c0.2,0.4,0.5,0.9,0.9,1.3c-0.4,0.5-0.8,0.9-1.3,1.1
									c-0.5,0.2-0.9,0.3-1.3,0.4C1852.9,1458.1,1852.3,1457.9,1852,1457.4z"
						/>
						<path
							d="M1859,1457.6c0.1-2.5,0.1-5.2,0.1-8.1c0-1.4-0.1-2.5-0.2-3.2c-0.1-0.7-0.2-1.3-0.4-1.7c-0.2-0.4-0.5-0.9-0.9-1.3
									c0.4-0.5,0.8-0.8,1.3-1.1c0.5-0.2,0.9-0.3,1.3-0.4c1.5,0,2.3,0.9,2.5,2.8c1.2-1.6,2.6-2.7,4.2-3.3c0.2,0.5,0.4,1.1,0.5,2
									c0.1,0.8,0.2,1.6,0.2,2.4c-0.4-0.1-0.8-0.1-1.3-0.1c-0.7,0-1.4,0.1-2.1,0.3c-0.7,0.2-1.2,0.5-1.6,0.8c0,1.8,0,3.5,0.1,5.1
									c0.1,2.7,0.3,4.6,0.9,5.7c-0.7,0.2-1.4,0.3-2.2,0.3C1860.6,1457.8,1859.9,1457.7,1859,1457.6z"
						/>
					</g>
					<g className="level_two fade location " id="onodlo_entwash">
						<rect x="1156.464" y="1245.202" width="75.092" height="27.393" transform="matrix(-0.054124, 0.998534, -0.998534, -0.054124, 3023.238281, 265.346069)"></rect>
						<rect x="1535.145" y="1333.017" width="99.681" height="29.325" transform="matrix(0.584242, 0.811579, -0.811579, 0.584242, 1889.374878, -603.701538)"></rect>
						<path
							d="M1695.6,1356.6c0.7-1,1.7-1.7,2.9-2.1s2.6-0.6,4.1-0.5c1.7,0.1,3.2,0.6,4.4,1.3c1.3,0.8,2.2,1.8,2.9,3
									c0.7,1.2,0.9,2.6,0.8,4.1c-0.1,1.4-0.5,2.5-1.2,3.5c-0.7,1-1.7,1.7-2.9,2.2c-1.2,0.5-2.6,0.7-4.2,0.6c-1.6-0.1-3.1-0.6-4.3-1.3
									s-2.2-1.8-2.8-3.1c-0.6-1.3-0.9-2.7-0.8-4.2C1694.4,1358.7,1694.8,1357.6,1695.6,1356.6z M1697.2,1364.4c1,0.9,2.5,1.5,4.3,1.6
									c1.7,0.1,3.1-0.2,4.3-0.8c1.2-0.7,1.9-1.7,2.3-3c0.1-1-0.1-1.8-0.6-2.7c-0.5-0.8-1.2-1.5-2-2c-0.9-0.5-1.9-0.8-3.1-0.9
									c-1.8-0.1-3.4,0.2-4.6,1c-1.2,0.8-1.8,1.8-1.9,3.2C1695.8,1362.2,1696.2,1363.4,1697.2,1364.4z"
						/>
						<path
							d="M1694.1,1370.5c2,0.2,4.1,0.4,6.3,0.5c1.1,0.1,1.9,0.1,2.5,0s1-0.1,1.4-0.2s0.7-0.3,1.1-0.6c0.4,0.3,0.6,0.6,0.8,1
									c0.2,0.3,0.2,0.7,0.2,0.9c0,1-0.7,1.5-1.9,1.6c0.6,0.5,1,1.1,1.3,1.8c0.3,0.7,0.4,1.4,0.4,2c-0.1,2.2-1.5,3.2-4.3,3.1l-1.9-0.1
									c-0.7-0.1-1.4-0.2-2-0.2c-2-0.1-3.5,0-4.5,0.4c-0.1-0.5-0.1-1-0.1-1.5c0-0.5,0.1-1,0.3-1.7c2.4,0.2,5,0.4,7.8,0.6
									c0.8,0,1.4-0.1,1.7-0.4c0.4-0.3,0.6-0.8,0.6-1.4c0-1-0.1-1.9-0.6-2.6l-1.3-0.1c-1-0.1-2.2-0.2-3.6-0.3c-2.1-0.1-3.6,0-4.5,0.3
									c-0.1-0.6-0.1-1.1-0.1-1.6C1693.8,1371.7,1693.9,1371.1,1694.1,1370.5z"
						/>
						<path
							d="M1694.8,1383.5c1.1-0.9,2.6-1.3,4.4-1.3c1.3,0,2.5,0.3,3.5,0.9c1,0.5,1.8,1.3,2.3,2.2c0.5,0.9,0.8,2,0.8,3.2
									c0,1-0.3,1.9-0.8,2.7c-0.5,0.8-1.3,1.4-2.2,1.8s-2,0.6-3.2,0.6c-1.3,0-2.5-0.3-3.5-0.9s-1.8-1.3-2.3-2.3c-0.5-1-0.8-2-0.8-3.3
									C1693.1,1385.6,1693.7,1384.4,1694.8,1383.5z M1695.4,1390.2c0.7,0.6,1.8,0.9,3.2,1c1.2,0,2.3-0.2,3.2-0.7s1.5-1.2,1.9-2.2
									c0-1.1-0.3-1.9-1.1-2.6s-1.8-1-3.2-1.1c-1.6,0-2.8,0.2-3.7,0.8c-0.9,0.6-1.4,1.3-1.4,2.3
									C1694.3,1388.7,1694.6,1389.6,1695.4,1390.2z"
						/>
						<path
							d="M1694.4,1395.6c1.1-0.8,2.7-1.1,4.8-1.1c2.2,0,3.8,0.5,4.8,1.3c1.1,0.8,1.6,2,1.6,3.5c0,1.2-0.4,2.3-1.2,3.3
									c2-0.1,3.5-0.2,4.5-0.5c0.2,0.5,0.4,1,0.4,1.5s0.1,1,0.1,1.6c-2.5-0.3-6.7-0.4-12.6-0.5c-0.7,0-1.3,0.1-1.7,0.2
									c-0.4,0.1-0.9,0.4-1.3,0.8c-0.6-0.6-0.9-1.4-1-2.2c0.4-0.5,1-0.8,1.7-1c-0.5-0.5-0.9-1.1-1.2-1.7c-0.3-0.6-0.4-1.2-0.4-1.9
									C1692.8,1397.5,1693.3,1396.3,1694.4,1395.6z M1694.6,1401c0.2,0.5,0.5,0.9,0.8,1.4c0.6,0,1.7,0,3.1,0.1c1.6,0.1,2.9,0.1,3.9,0.1
									c0.5-0.7,0.8-1.6,0.8-2.5c0-1-0.3-1.7-1.1-2.2c-0.7-0.5-1.8-0.8-3.2-0.8c-1.4,0-2.5,0.2-3.3,0.7c-0.8,0.5-1.3,1.1-1.5,2
									C1694.3,1400.1,1694.4,1400.6,1694.6,1401z"
						/>
						<path
							d="M1693.3,1408.2c0.3-0.2,1-0.3,1.9-0.3c0.6,0,1.6,0,3,0.1c1.5,0,2.8,0.1,3.7,0.1c3.2,0,5.5-0.1,6.9-0.5
									c0.2,0.5,0.4,1,0.5,1.5c0.1,0.5,0.1,1,0.1,1.6c-2.5-0.2-6-0.4-10.6-0.4c-1.1,0-1.9,0-2.5,0.1c-0.6,0.1-1.1,0.2-1.4,0.3
									s-0.7,0.4-1.1,0.7c-0.4-0.3-0.6-0.6-0.8-1c-0.2-0.4-0.3-0.7-0.3-0.9C1692.7,1408.8,1692.9,1408.4,1693.3,1408.2z"
						/>
						<path
							d="M1694.3,1413.7c1.1-0.9,2.6-1.4,4.4-1.4c1.3,0,2.5,0.3,3.5,0.8c1,0.5,1.8,1.2,2.4,2.2s0.8,2,0.8,3.1c0,1-0.3,2-0.8,2.8
									s-1.2,1.4-2.1,1.8c-0.9,0.4-2,0.6-3.2,0.6c-1.3,0-2.5-0.3-3.5-0.8c-1-0.5-1.8-1.3-2.4-2.2c-0.6-0.9-0.8-2-0.8-3.2
									C1692.6,1415.8,1693.2,1414.6,1694.3,1413.7z M1695,1420.3c0.8,0.6,1.8,0.9,3.2,0.9c1.2,0,2.3-0.3,3.2-0.8
									c0.9-0.5,1.5-1.3,1.8-2.2c0-1.1-0.4-1.9-1.2-2.6c-0.8-0.6-1.8-1-3.2-1c-1.6,0-2.8,0.3-3.7,0.9c-0.9,0.6-1.4,1.4-1.4,2.3
									C1693.8,1418.9,1694.2,1419.7,1695,1420.3z M1708,1415.9c0.3,0.7,0.8,1.3,1.3,1.8c0.6,0.5,1.1,0.9,1.7,1c0,0.7-0.1,1.2-0.3,1.7
									s-0.5,0.7-1,1c-1.2-1.1-2-2.9-2.4-5.3L1708,1415.9z"
						/>
						<path
							d="M1714.6,1436l-0.3,0.4c-1.7-1.4-3.6-2.4-5.7-3c-2.1-0.6-4.5-0.8-7.3-0.7c-2.8,0.1-5.2,0.5-7.2,1.2c-2,0.7-3.9,1.8-5.5,3.4
									l-0.4-0.4c1.6-2,3.5-3.6,5.7-4.7c2.2-1.1,4.6-1.8,7.2-1.9c2.7-0.1,5.1,0.4,7.4,1.3C1710.8,1432.6,1712.8,1434.1,1714.6,1436z"
						/>
						<path
							d="M1697.8,1439.6c1-0.2,2.5-0.5,4.2-1c3.2-0.8,5.4-1.4,6.5-2c0.1,1,0.4,2.5,0.8,4.4c0.6,2.4,1.1,4.2,1.5,5.5
									c-0.5,0.1-1.1,0.2-1.7,0.2c-0.6,0-1.1,0-1.3-0.1c0.1-0.5,0.2-1.1,0.2-1.8c0-0.7-0.1-1.4-0.3-2.2c-0.2-1-0.5-1.8-0.9-2.6
									c-1.6,0.3-3.1,0.6-4.4,0.9l0.1,0.7c0.1,0.7,0.2,1.2,0.3,1.6c0.2,0.9,0.4,1.5,0.5,2c0.2,0.4,0.4,0.9,0.6,1.5
									c-0.4,0.2-0.7,0.3-1,0.4c-0.3,0.1-0.6,0.1-1.1,0.2c-0.3-2-0.6-3.6-0.9-4.7c-0.1-0.5-0.2-0.9-0.3-1.1c-2.1,0.5-3.6,0.9-4.4,1.2
									c0,0.9,0.1,1.9,0.4,2.9c0.2,0.8,0.4,1.6,0.8,2.3c0.3,0.8,0.7,1.4,1,1.8c-0.5,0.1-1.1,0.2-1.7,0.2c-0.6,0-1.1,0-1.3-0.1
									c-0.1-1.2-0.5-2.9-1-5c-0.5-1.9-0.9-3.4-1.2-4.3C1695.3,1440.1,1696.8,1439.8,1697.8,1439.6z"
						/>
						<path
							d="M1697.7,1455.2c1.8-0.8,3.8-1.7,5.8-2.7c1-0.5,1.7-0.9,2.2-1.2s0.9-0.6,1.1-0.9c0.2-0.3,0.4-0.6,0.6-1.1
									c0.5,0.1,0.9,0.3,1.2,0.5c0.3,0.2,0.5,0.5,0.6,0.7c0.4,0.9,0.2,1.7-0.8,2.3c0.8,0.2,1.4,0.5,2,0.9c0.6,0.4,1.1,1,1.4,1.6
									c1,1.9,0.2,3.5-2.2,4.8l-1.7,0.8c-0.7,0.3-1.3,0.6-1.8,0.8c-1.8,0.9-3.1,1.8-3.7,2.5c-0.3-0.4-0.6-0.8-0.8-1.2
									c-0.2-0.4-0.4-1-0.6-1.6c2.2-1,4.6-2.1,7.1-3.3c0.7-0.4,1.2-0.7,1.3-1.2c0.2-0.4,0.1-0.9-0.2-1.6c-0.5-0.9-1-1.6-1.8-2l-1.2,0.6
									c-0.9,0.4-2,0.9-3.3,1.5c-1.9,0.9-3.1,1.8-3.8,2.5c-0.4-0.4-0.6-0.9-0.9-1.3C1698.1,1456.4,1697.9,1455.9,1697.7,1455.2z"
						/>
						<path
							d="M1705.2,1468.2c0.1-0.4,0.6-0.9,1.3-1.3c0.5-0.3,1.4-0.9,2.6-1.6c1.3-0.8,2.3-1.5,3.2-2c0.4-0.3,0.8-0.5,1.1-0.8
									c-0.4-0.6-0.6-1.1-0.7-1.3s0-0.5,0.3-0.6c1.3,0.6,2.7,1,4.4,1.2l0.3,0.6c-0.5,0.4-1.1,0.9-1.7,1.3c0.7,1,1.3,1.7,1.7,2.2
									c-0.3,0.2-0.7,0.4-1,0.5c-0.3,0.1-0.6,0.2-1,0.2c-0.1-0.6-0.4-1.3-1-2.1c-0.8,0.4-1.6,0.9-2.4,1.5c-2,1.3-3.5,2.5-4.4,3.4
									c0.5,0.7,1,1.2,1.4,1.5l-0.5,0.5c-0.6,0-1.3-0.1-1.9-0.4c-0.6-0.3-1.1-0.7-1.4-1.2C1705.2,1469.1,1705.1,1468.6,1705.2,1468.2z"
						/>
						<path
							d="M1711.3,1473.9l2.3-3c0.6-0.7,1-1.3,1.4-1.9c0.5-0.7,0.9-1.4,1.3-2c0.3-0.6,0.5-1.1,0.6-1.5c0.5,0.2,0.9,0.5,1.3,0.9
									c0.4,0.3,0.8,0.7,1.2,1.3c-0.6,0.6-1.8,2.1-3.5,4.3c-1.7,2.3-2.7,3.7-3,4.2c4.3-1.6,7-2.6,8-3c0.4-0.7,0.7-1.3,0.7-1.7
									c0.9,0.5,1.7,1.2,2.5,2.1c-0.6,0.6-1.8,2.1-3.6,4.5c-1.8,2.4-2.8,3.7-2.9,4.1c3.1-1.1,5.3-1.9,6.6-2.4c1.2-0.5,2.1-0.9,2.5-1.3
									c0.1-0.1,0.3-0.3,0.5-0.5c0.6,0.4,1.2,1,1.8,1.8c-1.3,0.3-3,0.9-5.1,1.6c-2,0.7-4.2,1.5-6.6,2.4l-1.7-0.6
									c-0.3-0.4-0.5-0.9-0.4-1.5c0.1-0.5,0.4-1.2,0.9-1.9c1.6-2.2,2.8-3.7,3.4-4.5c-1.7,0.6-4,1.4-6.9,2.6l-1.7-0.6
									C1710.1,1476.4,1710.3,1475.3,1711.3,1473.9z"
						/>
						<path
							d="M1720.7,1488.2c0.1-0.7,0.4-1.3,1-1.9c1.7-1.7,4.2-1,7.5,1.9c0.9-0.9,1.5-1.7,1.6-2.3c0.1-0.6-0.1-1.3-0.8-1.9
									c-0.4-0.4-0.9-0.8-1.4-1.1c-0.5-0.3-1.1-0.5-1.7-0.6l0.3-0.6c1-0.2,2-0.2,2.9,0c1,0.2,1.8,0.7,2.4,1.3c0.8,0.9,1.2,1.8,1.2,2.7
									s-0.5,1.9-1.5,2.8c-0.5,0.5-1.1,1-1.8,1.6c-0.7,0.6-1.3,1.1-1.6,1.4c-0.5,0.5-0.9,1-1.1,1.4s-0.3,0.9-0.4,1.6
									c-0.9-0.1-1.7-0.3-2.4-0.8c-0.1-0.6,0.1-1.2,0.5-1.9c-0.8-0.1-1.6-0.4-2.3-0.7c-0.7-0.3-1.3-0.7-1.7-1.1
									C1720.8,1489.5,1720.6,1488.9,1720.7,1488.2z M1724.4,1490.6c0.5,0.2,1.1,0.3,1.6,0.4l0.1-0.1c0.5-0.4,0.9-0.8,1.4-1.2l1-0.8
									c-0.9-0.9-1.8-1.4-2.6-1.5s-1.5,0-2.1,0.6c-0.2,0.2-0.4,0.5-0.5,0.9c-0.1,0.3-0.2,0.7-0.1,1
									C1723.4,1490.1,1723.9,1490.4,1724.4,1490.6z"
						/>
						<path
							d="M1728.3,1495.7c0.3-0.3,0.5-0.5,0.8-0.7s0.6-0.4,1-0.6c-0.1,0.6,0,1.2,0.3,1.8c0.2,0.6,0.6,1.1,1.1,1.6
									c1.3,1.2,2.3,1.4,3,0.6c0.3-0.3,0.4-0.7,0.3-1.2c-0.1-0.5-0.3-1.1-0.7-2c-0.3-0.8-0.6-1.5-0.8-2.1c-0.2-0.6-0.2-1.2-0.1-1.8
									c0.1-0.6,0.4-1.3,0.9-1.8c0.6-0.7,1.4-1,2.4-0.9s1.9,0.5,2.8,1.4c1.2,1.1,2.1,2.2,2.7,3.4c-0.2,0.2-0.6,0.5-1,0.8
									c-0.5,0.3-0.9,0.5-1.4,0.6c-0.1-0.9-0.2-1.8-0.4-2.5c-0.2-0.7-0.5-1.2-0.8-1.5c-0.4-0.2-0.8-0.3-1.2-0.3s-0.7,0.1-0.9,0.4
									c-0.4,0.4-0.5,0.9-0.4,1.4c0.1,0.5,0.3,1.3,0.7,2.2c0.3,0.8,0.6,1.4,0.7,2c0.2,0.6,0.2,1.1,0.1,1.7c-0.1,0.6-0.4,1.2-0.8,1.7
									c-0.7,0.8-1.6,1.2-2.7,1.1c-1-0.1-2.1-0.7-3.2-1.7C1729.4,1498.2,1728.6,1497,1728.3,1495.7z"
						/>
						<path
							d="M1736.2,1503.7c1.7-1.9,3.3-3.9,4.8-5.9c2.1-2.7,3.7-4.9,4.8-6.6c1,0.2,1.9,0.7,2.8,1.4c-0.7,0.7-2,2.1-3.9,4.3
									c0.8-0.2,1.5-0.2,2.3,0s1.4,0.4,1.9,0.8c1.7,1.3,1.7,3.1,0,5.3l-1.2,1.5l-1.3,1.5c-1.3,1.6-2.1,2.9-2.3,3.9
									c-0.5-0.2-0.9-0.5-1.3-0.8c-0.4-0.3-0.7-0.7-1.2-1.2c1.8-2.2,3.5-4.2,5-6.1c0.5-0.6,0.7-1.2,0.7-1.6s-0.3-0.9-0.9-1.3
									c-0.8-0.6-1.6-0.9-2.5-1c-0.4,0.4-0.9,1.1-1.5,1.9c-2.3,2.9-3.5,4.9-3.7,5.8c-0.5-0.2-0.9-0.5-1.3-0.8
									C1737,1504.6,1736.6,1504.2,1736.2,1503.7z"
						/>
						<path
							d="M1758.3,1500.7c-0.4,2.4-1.3,4.7-2.8,6.9c-1.5,2.2-3.3,3.9-5.5,5.1c-2.1,1.2-4.5,2-7,2.3l-0.1-0.5
									c2.2-0.5,4.1-1.5,5.8-2.8c1.7-1.3,3.3-3.1,4.9-5.4c1.6-2.3,2.7-4.5,3.3-6.5s0.8-4.2,0.5-6.4l0.5-0.1
									C1758.6,1495.8,1758.7,1498.3,1758.3,1500.7z"
						/>
					</g>
					<g className="level_two fade location" id="east_emnet">
						<rect x="1342.591" y="2362.722" width="87.178" height="51.977" transform="matrix(0.999893, -0.014646, 0.014646, 0.999893, 356.815674, -998.58313)"></rect>
						<path
							d="M1743.7,1386.1c0-1.2,0-2.9,0-5.1c-0.1-3.9-0.3-6.5-0.7-7.9c1.2,0.1,2.9,0.2,5.3,0.1c2.9-0.1,5.1-0.2,6.7-0.4
									c0,0.6,0,1.3-0.2,2c-0.1,0.7-0.3,1.2-0.5,1.5c-0.5-0.3-1.2-0.5-2-0.7c-0.8-0.1-1.7-0.2-2.6-0.2c-1.2,0-2.2,0.1-3.2,0.3
									c0,2-0.1,3.7,0,5.3l0.8,0c0.8,0,1.4,0,1.9,0c1,0,1.8-0.1,2.4-0.1c0.6-0.1,1.2-0.2,1.8-0.3c0.1,0.5,0.1,0.9,0.2,1.3
									c0,0.3,0,0.8-0.1,1.3c-2.4-0.1-4.3-0.2-5.6-0.1c-0.6,0-1.1,0-1.3,0.1c0.1,2.5,0.1,4.3,0.2,5.4c1,0.2,2.2,0.3,3.4,0.3
									c1,0,1.9-0.1,2.9-0.3c1-0.2,1.7-0.4,2.3-0.8c0,0.6,0,1.3-0.2,2.1s-0.3,1.2-0.5,1.5c-1.4-0.1-3.4-0.2-6-0.1c-2.3,0-4.1,0.2-5.2,0.4
									C1743.7,1389.2,1743.7,1387.4,1743.7,1386.1z"
						/>
						<path
							d="M1758.3,1391.2c0.1-2.3,0.2-4.8,0.1-7.5c0-1.3-0.1-2.3-0.1-2.9c-0.1-0.7-0.2-1.2-0.4-1.6c-0.2-0.4-0.4-0.8-0.8-1.2
									c0.3-0.4,0.7-0.8,1.1-1c0.4-0.2,0.7-0.3,1.1-0.3c1.1,0,1.8,0.7,2,2c0.6-0.7,1.2-1.2,2-1.6c0.8-0.4,1.5-0.6,2.3-0.6
									c1.8,0,3,0.8,3.5,2.5c0.6-0.8,1.2-1.4,2-1.9c0.8-0.5,1.6-0.7,2.4-0.7c2.5,0,3.8,1.6,3.8,4.8l0,2.2c-0.1,0.9-0.1,1.6-0.1,2.3
									c0,2.4,0.3,4.2,0.7,5.2c-0.6,0.1-1.2,0.2-1.7,0.2c-0.5,0-1.2-0.1-2-0.2c0.1-2.8,0.2-5.9,0.1-9.2c0-0.9-0.2-1.6-0.5-2
									c-0.4-0.4-0.9-0.6-1.7-0.6c-1.1,0-2,0.3-2.9,0.8c0,0.9,0,2.1,0,3.9c0,0.5,0,1.1,0,1.9c0,2.4,0.3,4.2,0.7,5.2
									c-0.6,0.1-1.2,0.2-1.7,0.2c-0.5,0-1.2-0.1-2-0.2c0.1-2.8,0.2-5.9,0.1-9.2c0-0.9-0.2-1.6-0.5-2c-0.4-0.4-0.9-0.6-1.7-0.6
									c-1.1,0-2,0.3-2.8,0.8l0,1.5c-0.1,1.2-0.1,2.6,0,4.2c0,2.5,0.3,4.2,0.7,5.2c-0.7,0.1-1.3,0.2-1.9,0.2
									C1759.7,1391.4,1759.1,1391.4,1758.3,1391.2z"
						/>
						<path
							d="M1781.6,1391c0.1-2.3,0.2-4.8,0.2-7.5c0-1.3,0-2.3-0.1-2.9c-0.1-0.7-0.2-1.2-0.4-1.6c-0.2-0.4-0.4-0.8-0.8-1.2
									c0.3-0.4,0.7-0.8,1.1-1c0.4-0.2,0.8-0.3,1.1-0.3c1.2,0,1.8,0.7,2,2.1c0.6-0.7,1.2-1.2,2-1.6c0.8-0.4,1.6-0.6,2.4-0.6
									c2.5,0,3.8,1.6,3.8,4.8l-0.1,2.2c-0.1,0.9-0.1,1.6-0.1,2.3c0,2.4,0.2,4.2,0.7,5.3c-0.6,0.1-1.2,0.2-1.7,0.2c-0.6,0-1.2-0.1-2-0.2
									c0.1-2.8,0.2-5.9,0.2-9.2c0-0.9-0.2-1.6-0.5-2c-0.3-0.4-0.9-0.6-1.7-0.6c-1.2,0-2.2,0.3-3,0.8l0,1.5c-0.1,1.2-0.1,2.6-0.1,4.2
									c0,2.5,0.2,4.2,0.7,5.3c-0.7,0.1-1.3,0.2-1.9,0.2C1783,1391.2,1782.4,1391.1,1781.6,1391z"
						/>
						<path
							d="M1796.7,1389.5c-0.9-1.2-1.4-3-1.4-5.2c0-1.5,0.3-2.9,0.9-4.1c0.6-1.2,1.4-2.1,2.4-2.8c1-0.7,2.2-1,3.5-1
									c1.7,0,3.1,0.6,4,1.9c0.9,1.2,1.3,3,1.3,5.4c-1.2,0.3-2.5,0.6-4.2,0.8c-1.6,0.2-3.2,0.3-4.8,0.4c0.1,1.4,0.5,2.5,1.1,3.1
									c0.6,0.6,1.5,1,2.6,1c0.9,0,1.7-0.1,2.5-0.3c0.8-0.2,1.5-0.5,2-0.9l0.2,0.7c-0.7,0.9-1.6,1.7-2.7,2.2c-1.1,0.6-2.3,0.8-3.4,0.8
									C1799,1391.4,1797.6,1390.8,1796.7,1389.5z M1801.5,1383.1c1-0.3,1.9-0.6,2.5-0.9c0-1.2-0.3-2.3-1-3.1s-1.5-1.2-2.5-1.2
									c-0.7,0.4-1.2,1.1-1.6,2.2c-0.4,1-0.6,2.2-0.6,3.5C1799.4,1383.6,1800.4,1383.4,1801.5,1383.1z"
						/>
						<path
							d="M1810.9,1390.8c-0.3-0.4-0.4-1.1-0.4-2.2c0-0.7,0-1.9,0.1-3.5c0.1-1.8,0.1-3.2,0.1-4.4c0-0.6,0-1.1,0-1.6
									c-0.9,0-1.5-0.1-1.7-0.2c-0.3-0.1-0.4-0.4-0.4-0.7c1.4-0.8,2.8-2,4.1-3.5l0.8,0.1c0.1,0.8,0.1,1.6,0.1,2.5c1.5,0,2.5-0.1,3.2-0.2
									c0,0.5-0.1,0.9-0.1,1.3c-0.1,0.4-0.2,0.8-0.4,1.2c-0.6-0.3-1.5-0.4-2.7-0.5c-0.1,1-0.1,2.1-0.1,3.3c0,2.9,0.1,5.1,0.4,6.6
									c1-0.1,1.8-0.2,2.4-0.4l0.2,0.8c-0.4,0.6-1,1.2-1.7,1.6s-1.4,0.6-2.1,0.6C1811.7,1391.4,1811.2,1391.2,1810.9,1390.8z"
						/>
						<path
							d="M1754.1,1359.7c0.1-1.2,0.1-2.9,0.1-5.1c0-3.9-0.1-6.5-0.5-8c1.2,0.2,3,0.3,5.5,0.3c3,0,5.3,0,7-0.2c0,0.6-0.1,1.3-0.2,2
									c-0.1,0.7-0.3,1.2-0.5,1.5c-0.5-0.3-1.2-0.5-2.1-0.7c-0.9-0.2-1.7-0.3-2.6-0.3c-1.2,0-2.3,0.1-3.3,0.2c-0.1,2-0.2,3.7-0.2,5.3
									l0.8,0c0.9,0.1,1.5,0.1,1.9,0.1c1.1,0,1.9,0,2.5-0.1c0.6-0.1,1.2-0.1,1.9-0.3c0.1,0.5,0.1,0.9,0.1,1.3c0,0.3-0.1,0.8-0.2,1.3
									c-2.5-0.2-4.4-0.3-5.8-0.3c-0.7,0-1.1,0-1.4,0c0,2.5,0,4.3,0.1,5.4c1.1,0.2,2.3,0.4,3.6,0.4c1,0,2-0.1,3-0.2
									c1-0.2,1.8-0.4,2.5-0.7c0,0.6-0.1,1.3-0.2,2c-0.1,0.7-0.3,1.2-0.5,1.5c-1.4-0.2-3.5-0.3-6.3-0.3c-2.4,0-4.2,0-5.4,0.2
									C1753.9,1362.7,1754,1360.9,1754.1,1359.7z"
						/>
						<path
							d="M1768.2,1364.7c-0.5-0.6-0.8-1.4-0.8-2.4c0-2.8,2.7-4.4,8.1-4.7c0-1.5-0.2-2.6-0.6-3.2c-0.4-0.6-1.2-0.9-2.4-0.9
									c-0.7,0-1.4,0.1-2.1,0.3c-0.7,0.2-1.4,0.5-2,0.9l-0.3-0.7c0.6-1,1.5-1.8,2.5-2.5c1-0.6,2.1-0.9,3.2-0.9c1.5,0,2.6,0.4,3.4,1.2
									c0.8,0.8,1.2,2,1.2,3.5c0,0.8-0.1,1.8-0.2,2.8c-0.1,1.1-0.2,1.9-0.2,2.5c0,0.9,0.1,1.6,0.2,2.1c0.2,0.5,0.5,1,1,1.6
									c-0.8,0.7-1.8,1.2-2.8,1.3c-0.6-0.4-1-1.1-1.3-1.9c-0.8,0.6-1.7,1-2.6,1.4c-0.9,0.3-1.7,0.5-2.5,0.5
									C1769.5,1365.6,1768.8,1365.3,1768.2,1364.7z M1773.5,1363.5c0.6-0.3,1.2-0.6,1.7-1l0-0.2c0-0.7,0.1-1.5,0.1-2.2l0.1-1.5
									c-1.5,0.1-2.7,0.4-3.6,1c-0.8,0.6-1.3,1.3-1.3,2.3c0,0.4,0.1,0.8,0.3,1.2c0.2,0.4,0.5,0.7,0.8,0.9
									C1772.2,1363.9,1772.8,1363.8,1773.5,1363.5z"
						/>
						<path
							d="M1780.3,1364.5c0-0.4,0.1-0.9,0.2-1.3c0.1-0.4,0.3-0.8,0.5-1.3c0.4,0.6,1,1.1,1.7,1.4s1.5,0.5,2.3,0.5
									c2.2,0,3.2-0.6,3.3-1.8c0-0.5-0.2-0.9-0.7-1.3s-1.2-0.8-2.2-1.2c-1-0.5-1.8-0.9-2.4-1.3c-0.6-0.4-1.1-0.9-1.6-1.5
									c-0.4-0.6-0.7-1.4-0.7-2.3c0-1.1,0.5-2,1.4-2.6c0.9-0.7,2.2-1,3.7-1c2,0,3.7,0.3,5.2,0.9c0,0.4-0.1,0.9-0.3,1.5
									c-0.2,0.6-0.5,1.2-0.8,1.7c-0.8-0.8-1.6-1.4-2.4-1.9c-0.8-0.5-1.4-0.7-1.9-0.7c-0.5,0.1-1,0.3-1.3,0.6s-0.5,0.7-0.5,1
									c0,0.7,0.2,1.2,0.8,1.6s1.3,0.9,2.4,1.4c0.9,0.4,1.7,0.8,2.3,1.2s1.1,0.9,1.5,1.5c0.4,0.6,0.6,1.3,0.6,2.2c0,1.3-0.5,2.3-1.5,3
									c-1,0.7-2.5,1.1-4.3,1C1783.3,1365.8,1781.6,1365.3,1780.3,1364.5z"
						/>
						<path
							d="M1794.5,1365.2c-0.3-0.4-0.5-1.1-0.5-2.2c0-0.7,0-1.9,0.1-3.5c0.1-1.8,0.1-3.2,0.1-4.4c0-0.6,0-1.1,0-1.6
									c-0.9,0-1.5-0.1-1.8-0.2c-0.3-0.1-0.5-0.4-0.5-0.7c1.5-0.8,2.9-2,4.2-3.5l0.8,0.1c0.1,0.8,0.1,1.6,0.1,2.5c1.5,0,2.6-0.1,3.3-0.2
									c0,0.5-0.1,0.9-0.2,1.3s-0.2,0.8-0.5,1.2c-0.7-0.3-1.6-0.4-2.8-0.5c-0.1,1-0.1,2.1-0.1,3.3c0,2.9,0.1,5.1,0.4,6.6
									c1-0.1,1.9-0.2,2.5-0.4l0.2,0.8c-0.4,0.6-1,1.2-1.7,1.6c-0.7,0.4-1.5,0.6-2.2,0.6C1795.3,1365.8,1794.8,1365.6,1794.5,1365.2z"
						/>
					</g>
					<g className="level_two fade location " id="mering_stream">
						<rect x="1280.955" y="1980.254" width="83.177" height="43.563" transform="matrix(0.616506, -0.78735, 0.78735, 0.616506, -594.000244, 1390.958252)"></rect>
						<path
							d="M1789.8,1613.8c-0.3-0.2-0.5-0.4-0.7-0.6s-0.3-0.5-0.5-0.7c0.5,0,1.1-0.3,1.7-0.6c0.6-0.4,1.1-0.8,1.5-1.4
									c0.5-0.7,0.8-1.3,0.8-1.9c0-0.6-0.2-1.1-0.8-1.5c-0.4-0.3-0.9-0.4-1.5-0.3c-0.6,0.1-1.3,0.4-2.3,0.9c-0.9,0.4-1.6,0.7-2.1,0.9
									c-0.6,0.2-1.1,0.3-1.8,0.2c-0.6,0-1.2-0.3-1.8-0.7c-0.8-0.6-1.1-1.4-1-2.5c0.1-1.1,0.6-2.2,1.5-3.3c0.8-1,1.7-1.8,2.5-2.3
									c0.3,0.2,0.6,0.5,0.9,0.9c0.3,0.4,0.5,0.7,0.6,1c-0.8,0.2-1.6,0.5-2.3,0.9s-1.2,0.7-1.5,1.1c-0.2,0.3-0.2,0.7-0.1,1.1
									s0.3,0.8,0.6,1.1c0.5,0.4,1,0.5,1.6,0.3s1.4-0.4,2.3-0.9c0.8-0.4,1.5-0.7,2-0.8c0.5-0.2,1.1-0.3,1.7-0.2s1.2,0.3,1.7,0.7
									c0.9,0.7,1.4,1.6,1.4,2.6s-0.5,2.1-1.4,3.4C1792.1,1612.3,1791,1613.3,1789.8,1613.8z"
						/>
						<path
							d="M1798.3,1603.9c-0.4,0-0.8-0.3-1.4-0.7c-0.4-0.3-1-0.9-1.9-1.6c-0.9-0.8-1.7-1.5-2.3-2c-0.3-0.3-0.6-0.5-0.9-0.7
									c-0.4,0.5-0.7,0.8-0.9,0.9c-0.2,0.1-0.4,0.1-0.6-0.1c0.2-1.2,0.2-2.5,0-3.9l0.4-0.4c0.5,0.3,0.9,0.7,1.4,1
									c0.7-0.8,1.1-1.5,1.4-1.9c0.3,0.2,0.5,0.4,0.6,0.7c0.2,0.2,0.3,0.5,0.4,0.8c-0.4,0.2-0.9,0.7-1.5,1.3c0.5,0.5,1.1,1,1.7,1.6
									c1.5,1.3,2.8,2.2,3.8,2.7c0.4-0.6,0.8-1.1,0.9-1.5l0.5,0.3c0.2,0.5,0.2,1.1,0.1,1.6c-0.1,0.6-0.3,1.1-0.7,1.4
									C1799,1603.7,1798.7,1603.9,1798.3,1603.9z"
						/>
						<path
							d="M1803.3,1598.4c-1.1-1.2-2.4-2.4-3.8-3.7c-0.7-0.6-1.2-1.1-1.6-1.3c-0.4-0.3-0.7-0.5-1-0.6c-0.3-0.1-0.6-0.1-1-0.1
									c0-0.4,0-0.7,0.1-1.1c0.1-0.3,0.2-0.6,0.4-0.8c0.6-0.7,1.4-0.6,2.3,0.2c-0.1-0.7-0.1-1.3-0.1-1.9s0.2-1.1,0.5-1.7
									c0.3,0.1,0.6,0.3,1,0.5c0.4,0.3,0.8,0.5,1.1,0.8c-0.2,0.1-0.3,0.3-0.6,0.6c-0.3,0.4-0.6,0.8-0.8,1.2c-0.2,0.4-0.4,0.8-0.4,1.1
									l0.6,0.5c0.8,0.8,1.5,1.4,2.1,2c1.3,1.2,2.3,1.9,3.1,2.1c-0.2,0.4-0.5,0.8-0.8,1.1C1804.1,1597.8,1803.7,1598.1,1803.3,1598.4z"
						/>
						<path
							d="M1808.3,1591.5c-1.1-0.1-2.2-0.8-3.3-1.9c-0.8-0.8-1.3-1.6-1.6-2.5c-0.3-0.9-0.4-1.8-0.2-2.6c0.2-0.9,0.6-1.6,1.3-2.3
									c0.9-0.9,1.9-1.3,3-1.2c1.1,0.1,2.2,0.8,3.4,2c-0.4,0.8-1,1.6-1.8,2.6c-0.7,1-1.5,1.9-2.3,2.7c0.8,0.6,1.5,1,2.1,1
									c0.6,0,1.2-0.3,1.8-0.9c0.5-0.5,0.8-1,1.1-1.5c0.3-0.5,0.5-1,0.6-1.5l0.5,0.2c0.1,0.8,0,1.7-0.3,2.5s-0.7,1.6-1.4,2.2
									C1810.4,1591.3,1809.4,1591.7,1808.3,1591.5z M1807.5,1585.8c0.4-0.7,0.7-1.3,0.8-1.7c-0.6-0.6-1.3-0.9-2-1
									c-0.7-0.1-1.4,0.2-1.9,0.7c-0.1,0.6-0.1,1.2,0.3,1.9c0.3,0.7,0.8,1.4,1.5,2.1C1806.6,1587.2,1807.1,1586.5,1807.5,1585.8z"
						/>
						<path
							d="M1816.6,1584.2c-0.6-0.1-1.1-0.3-1.6-0.8c-1.3-1.5-0.7-3.6,1.9-6.3c-0.7-0.8-1.4-1.3-1.9-1.4c-0.5-0.1-1.1,0.1-1.7,0.7
									c-0.4,0.3-0.7,0.7-0.9,1.2c-0.3,0.5-0.4,0.9-0.6,1.4l-0.5-0.2c-0.2-0.8-0.1-1.7,0.1-2.5s0.6-1.5,1.2-2c0.8-0.7,1.5-1,2.3-1
									s1.6,0.5,2.3,1.2c0.4,0.4,0.8,1,1.3,1.6c0.5,0.6,0.9,1.1,1.1,1.4c0.4,0.5,0.8,0.8,1.1,0.9c0.3,0.2,0.8,0.3,1.3,0.3
									c-0.1,0.8-0.3,1.5-0.8,2c-0.5,0.1-1-0.1-1.6-0.4c-0.1,0.7-0.4,1.3-0.7,1.9c-0.3,0.6-0.6,1.1-1,1.4
									C1817.6,1584.1,1817.1,1584.3,1816.6,1584.2z M1818.7,1581.1c0.2-0.4,0.3-0.9,0.4-1.3l-0.1-0.1c-0.4-0.4-0.7-0.8-1-1.2l-0.7-0.8
									c-0.8,0.8-1.2,1.5-1.4,2.2c-0.2,0.7,0,1.3,0.4,1.8c0.2,0.2,0.4,0.4,0.7,0.5s0.6,0.1,0.9,0.1
									C1818.2,1581.9,1818.5,1581.5,1818.7,1581.1z"
						/>
						<path
							d="M1825.4,1576.6c-1-1.3-2.1-2.7-3.3-4.1c-0.6-0.7-1-1.2-1.4-1.5c-0.3-0.3-0.7-0.6-0.9-0.7c-0.3-0.1-0.6-0.2-1-0.3
									c0-0.4,0.1-0.8,0.2-1c0.1-0.3,0.3-0.5,0.5-0.7c0.6-0.5,1.3-0.5,2,0.2c0-0.6,0.1-1.2,0.4-1.8c0.2-0.6,0.6-1,1-1.4
									c1-0.9,2.1-1,3.1-0.3c0-0.7,0.1-1.4,0.3-2s0.6-1.1,1.1-1.5c1.4-1.2,2.9-0.9,4.3,0.8l1,1.2c0.4,0.5,0.7,0.9,1,1.3
									c1.1,1.3,2,2.1,2.8,2.5c-0.3,0.4-0.6,0.7-0.9,0.9c-0.3,0.3-0.7,0.5-1.2,0.8c-1.2-1.6-2.5-3.3-4-5.1c-0.4-0.5-0.8-0.8-1.2-0.8
									c-0.4-0.1-0.8,0.1-1.3,0.5c-0.6,0.5-1,1.1-1.3,1.8c0.4,0.5,1,1.2,1.7,2.1c0.2,0.3,0.5,0.6,0.8,1c1.1,1.3,2,2.2,2.7,2.5
									c-0.3,0.4-0.6,0.7-0.9,0.9c-0.3,0.3-0.7,0.5-1.2,0.8c-1.2-1.6-2.5-3.3-4-5.1c-0.4-0.5-0.8-0.8-1.2-0.8c-0.4-0.1-0.8,0.1-1.3,0.5
									c-0.6,0.5-1,1.1-1.2,1.8l0.7,0.8c0.5,0.7,1.1,1.5,1.9,2.3c1.1,1.3,2,2.2,2.8,2.5c-0.3,0.4-0.6,0.7-1,1
									C1826.3,1576.1,1825.9,1576.4,1825.4,1576.6z"
						/>
						
						<path
							d="M1773.7,1610.8c-1.6-0.7-3.9-1.7-6.9-2.9c-1.6-0.6-2.8-1-3.5-1.3s-1.4-0.4-1.8-0.4c0.1-0.4,0.2-0.9,0.3-1.3
									c0.1-0.3,0.3-0.7,0.6-1.2c1.3,0,3-0.1,5.2-0.2c2.2-0.1,4-0.2,5.4-0.3c-1-1-2.2-2.3-3.7-3.8c-1.5-1.5-2.8-2.7-3.8-3.7
									c0-0.3,0.1-0.8,0.3-1.3c0.1-0.4,0.4-0.8,0.6-1.2c0.7,0.3,1.7,0.8,3,1.4s2.6,1.1,3.8,1.5c2.9,1.1,4.7,1.7,5.4,1.7
									c-0.1,0.4-0.2,0.9-0.3,1.3c-0.1,0.4-0.4,0.8-0.6,1.3c-0.7-0.4-1.8-0.8-3.1-1.4c-1.3-0.6-2.6-1.1-3.7-1.5c-0.8-0.3-1.7-0.6-2.6-1
									c1.9,2.1,4.6,4.7,7.9,8c0,0.2,0,0.4-0.1,0.5c0,0.1-0.1,0.3-0.3,0.4c-1.1,0-2.8,0.1-4.8,0.2c-2.1,0.1-4,0.2-5.6,0.3
									c1.6,0.6,2.8,1.1,3.7,1.5c2.8,1.1,4.5,1.7,5.3,1.8c0,0.3-0.1,0.6-0.2,0.9C1774.1,1610.2,1773.9,1610.5,1773.7,1610.8z"
						/>
						<path
							d="M1780.1,1593.8c-1.1,0.1-2.3-0.3-3.6-1.2c-0.9-0.6-1.6-1.3-2-2.2c-0.5-0.8-0.7-1.7-0.7-2.6c0-0.9,0.3-1.7,0.9-2.5
									c0.7-1.1,1.6-1.6,2.7-1.7c1.1-0.1,2.3,0.4,3.7,1.3c-0.3,0.8-0.7,1.8-1.3,2.9c-0.5,1.1-1.1,2.1-1.8,3.1c0.9,0.5,1.6,0.7,2.3,0.6
									c0.6-0.1,1.2-0.5,1.7-1.2c0.4-0.5,0.7-1.1,0.9-1.7c0.2-0.6,0.3-1.1,0.3-1.6l0.5,0.1c0.3,0.8,0.3,1.6,0.2,2.5s-0.4,1.7-0.9,2.4
									C1782.1,1593.2,1781.2,1593.7,1780.1,1593.8z M1778.3,1588.4c0.3-0.7,0.4-1.4,0.5-1.9c-0.7-0.5-1.5-0.7-2.2-0.6s-1.3,0.4-1.7,1
									c0,0.6,0.2,1.2,0.6,1.8c0.4,0.7,1,1.2,1.8,1.8C1777.7,1589.8,1778,1589.1,1778.3,1588.4z"
						/>
						<path
							d="M1788.1,1584.8c-1.2-1.2-2.5-2.4-3.9-3.6c-0.7-0.6-1.2-1-1.6-1.3c-0.4-0.3-0.7-0.5-1-0.5c-0.3-0.1-0.6-0.1-1-0.1
									c-0.1-0.4,0-0.7,0-1.1c0.1-0.3,0.2-0.6,0.4-0.8c0.6-0.7,1.4-0.7,2.3,0.1c-0.1-0.7-0.2-1.3-0.1-1.9s0.2-1.2,0.5-1.7
									c0.3,0.1,0.6,0.3,1.1,0.5s0.8,0.5,1.1,0.8c-0.2,0.1-0.3,0.3-0.6,0.6c-0.3,0.4-0.6,0.8-0.8,1.2c-0.2,0.4-0.3,0.8-0.4,1.2l0.6,0.5
									c0.8,0.7,1.5,1.4,2.2,1.9c1.3,1.1,2.3,1.8,3.1,2.1c-0.2,0.4-0.5,0.8-0.8,1.1C1788.9,1584.1,1788.6,1584.5,1788.1,1584.8z"
						/>
						<path
							d="M1783.8,1569.5c-0.1-0.8-0.2-1.5-0.1-2.1c0.7,0.1,1.4,0,2.1,0c0.1,0.7,0.1,1.4,0.1,2.1
									C1785.3,1569.4,1784.6,1569.4,1783.8,1569.5z M1794,1578c-0.3-0.1-0.8-0.4-1.3-0.9c-0.4-0.4-1.1-1-1.9-1.8
									c-0.7-0.7-1.3-1.3-1.7-1.6c-0.5-0.5-1-0.9-1.4-1.2c-0.5-0.3-0.8-0.5-1.1-0.6c0.2-0.5,0.4-0.8,0.6-1.2s0.5-0.7,0.9-1.1
									c0.3,0.4,0.8,0.9,1.5,1.7c0.7,0.7,1.5,1.5,2.4,2.4c0.7,0.6,1.2,1.1,1.6,1.4c0.4,0.3,0.7,0.5,1,0.6s0.6,0.2,1,0.2
									c0,0.4,0,0.7-0.1,1.1c-0.1,0.3-0.2,0.6-0.4,0.7C1794.6,1577.9,1794.3,1578,1794,1578z"
						/>
						<path
							d="M1798,1574c-1.1-1.2-2.3-2.5-3.6-3.9c-0.6-0.6-1.1-1.1-1.5-1.4s-0.7-0.5-1-0.6c-0.3-0.1-0.6-0.2-1-0.2
									c0-0.4,0-0.8,0.1-1.1c0.1-0.3,0.2-0.5,0.4-0.7c0.6-0.6,1.3-0.6,2.1,0c-0.1-0.6,0-1.3,0.2-1.9c0.2-0.6,0.5-1.1,0.9-1.5
									c1.3-1.3,2.8-1.2,4.4,0.4l1.1,1.1c0.4,0.5,0.8,0.9,1.1,1.2c1.2,1.2,2.2,2,3,2.3c-0.2,0.4-0.5,0.7-0.8,1c-0.3,0.3-0.7,0.6-1.1,0.9
									c-1.3-1.5-2.8-3.1-4.4-4.7c-0.5-0.5-0.9-0.7-1.3-0.7c-0.4,0-0.8,0.2-1.2,0.6c-0.6,0.6-1,1.3-1.1,1.9l0.7,0.8
									c0.6,0.6,1.2,1.4,2.1,2.2c1.2,1.2,2.2,2,3,2.3c-0.3,0.4-0.6,0.8-0.9,1.1C1798.9,1573.4,1798.5,1573.7,1798,1574z"
						/>
						<path
							d="M1808,1568.9c-0.4-0.4-0.7-0.9-0.9-1.5c-0.2-0.6-0.3-1.2-0.3-1.8c-0.6,0.3-1.1,0.1-1.6-0.3c-0.3-0.3-0.5-0.8-0.5-1.3
									s0.1-1,0.4-1.6c-0.6,0.2-1.2,0.2-1.8,0c-0.6-0.2-1.1-0.5-1.6-1.1c-0.8-0.8-1.1-1.8-1-2.9c0.1-1.1,0.6-2,1.5-2.8
									c0.6-0.6,1.2-0.9,1.9-1c0.2-0.3,0.3-0.7,0.5-1.1c0.1-0.5,0.2-1,0.3-1.5c0.3,0.1,0.7,0.3,1,0.5c0.4,0.2,0.6,0.4,0.8,0.6
									c-0.2,0.6-0.5,1.1-1.1,1.6c0.6,0.2,1.2,0.6,1.6,1.1c0.5,0.6,0.8,1.2,1,1.9c0.1,0.7,0.1,1.4-0.2,2.1c-0.3,0.7-0.7,1.3-1.3,1.9
									c-0.5,0.5-0.8,0.9-1,1.4s-0.1,0.8,0.2,1.1l3.5-2.9c0.7-0.5,1.3-0.8,2-0.8c0.6,0,1.2,0.2,1.6,0.8c0.4,0.5,0.7,1.1,0.7,1.8
									c0,0.7-0.1,1.4-0.5,2.1c-0.4,0.7-0.9,1.4-1.6,2.1C1810.4,1568.1,1809.2,1568.8,1808,1568.9z M1807,1559.1c0-0.5-0.2-1-0.6-1.5
									c-0.4-0.4-0.9-0.7-1.4-0.8c-0.5-0.1-1.1,0-1.6,0.3c-0.5,0.4-0.7,1-0.7,1.6c0,0.6,0.2,1.2,0.7,1.7c0.4,0.5,0.9,0.7,1.5,0.8
									c0.6,0,1-0.2,1.5-0.6C1806.7,1560.1,1807,1559.6,1807,1559.1z M1812.2,1563.8c0.2-0.5,0.2-1-0.1-1.3c-0.2-0.2-0.5-0.3-0.7-0.3
									c-0.3,0-0.6,0.2-1,0.6l-2.8,2.3c0.1,0.5,0.3,1,0.5,1.3c1,0.2,2-0.1,2.9-0.9C1811.6,1564.9,1812,1564.3,1812.2,1563.8z"
						/>
					</g>
					<g className="level_one location" id="anorien">
						<rect x="2099.269" y="1395.551" width="136.313" height="30.7" transform="matrix(0.975913, 0.218161, -0.218161, 0.975913, 101.15033, -237.065201)"></rect>
						<path
							d="M1855,1608.2l-0.6-3.9c-0.9-0.2-1.9-0.4-3.2-0.7c-1.4-0.2-2.3-0.3-2.8-0.3c-0.6,1-1.2,2.2-1.9,3.4c-0.4,0.1-0.7,0.1-0.9,0
									c-0.4-0.1-0.7-0.2-1.1-0.4c0.7-0.9,2-2.9,3.9-6.2s3.4-5.9,4.3-7.8c0.5,0,0.9,0,1.2,0.1c0.3,0.1,0.7,0.2,1.1,0.3
									c0.3,2.1,0.8,5,1.5,8.8c0.7,3.7,1.2,6.1,1.6,7.2c-0.5,0.1-1,0-1.5-0.1C1856.1,1608.6,1855.5,1608.5,1855,1608.2z M1854,1602.5
									c-0.5-2.7-0.9-4.9-1.3-6.8c-0.9,1.5-2.1,3.4-3.4,5.9c0.9,0.2,2,0.4,3.2,0.6C1853.2,1602.4,1853.7,1602.5,1854,1602.5z"
						/>
						<path
							d="M1868.1,1610.6c0.5-1.9,0.9-3.9,1.3-6c0.2-1,0.3-1.8,0.3-2.4c0-0.6,0-1-0.1-1.4c-0.1-0.3-0.2-0.7-0.5-1.1
									c0.4-0.3,0.7-0.5,1.1-0.6c0.4-0.1,0.7-0.1,1-0.1c1,0.2,1.4,0.9,1.4,2c0.6-0.5,1.2-0.8,1.9-1c0.7-0.2,1.4-0.2,2.1-0.1
									c2.1,0.4,3,1.9,2.5,4.5l-0.4,1.8c-0.2,0.7-0.3,1.3-0.4,1.9c-0.4,1.9-0.4,3.4-0.2,4.4c-0.5,0-1,0-1.5-0.1c-0.5-0.1-1-0.2-1.6-0.5
									c0.6-2.3,1.1-4.7,1.6-7.4c0.1-0.8,0.1-1.3-0.1-1.7c-0.2-0.4-0.7-0.6-1.4-0.8c-1-0.2-1.9-0.1-2.6,0.2l-0.3,1.2
									c-0.2,1-0.5,2.1-0.7,3.4c-0.4,2-0.4,3.4-0.2,4.4c-0.6,0-1.1,0-1.6-0.1C1869.2,1611,1868.7,1610.9,1868.1,1610.6z"
						/>
						<path
							d="M1890,1613.5c-0.7-1.2-0.9-2.7-0.5-4.4c0.2-1.2,0.7-2.3,1.4-3.2c0.7-0.9,1.6-1.5,2.6-1.8s2.1-0.4,3.2-0.2
									c1,0.2,1.9,0.6,2.6,1.3c0.7,0.6,1.2,1.4,1.4,2.4c0.3,1,0.3,2,0,3.1c-0.2,1.2-0.7,2.3-1.4,3.2c-0.7,0.9-1.6,1.5-2.6,1.8
									c-1,0.4-2.2,0.4-3.3,0.2C1891.8,1615.5,1890.7,1614.7,1890,1613.5z M1896.7,1614.1c0.7-0.6,1.2-1.6,1.5-2.9
									c0.2-1.2,0.2-2.2-0.2-3.2c-0.4-1-1-1.7-1.9-2.2c-1.1-0.2-2,0-2.8,0.6c-0.8,0.6-1.3,1.6-1.6,2.8c-0.3,1.5-0.2,2.7,0.2,3.7
									c0.4,1,1.1,1.6,2,1.7C1895.1,1614.9,1896,1614.7,1896.7,1614.1z M1894.7,1600.9c0.7-0.2,1.4-0.5,2.1-0.9c0.6-0.4,1.1-0.9,1.3-1.4
									c0.7,0.1,1.2,0.3,1.6,0.6c0.4,0.3,0.6,0.6,0.8,1.1c-1.3,0.9-3.2,1.4-5.7,1.3L1894.7,1600.9z"
						/>
						<path
							d="M1910.6,1619c0.5-1.9,1-3.8,1.5-6c0.2-1,0.4-1.8,0.4-2.4c0.1-0.6,0-1,0-1.4c-0.1-0.3-0.2-0.7-0.5-1.1
									c0.4-0.3,0.7-0.5,1.1-0.6c0.4-0.1,0.7-0.1,1-0.1c1,0.2,1.4,1,1.2,2.4c0.6-0.5,1.2-0.9,1.8-1.2c0.6-0.3,1.3-0.4,2-0.5
									c0.1,0.3,0.1,0.8,0.1,1.4c0,0.6-0.1,1.1-0.2,1.6c-0.2-0.1-0.5-0.2-0.9-0.3c-0.6-0.1-1.2-0.2-1.7-0.1c-0.6,0-1,0.1-1.4,0.2
									l-0.2,0.9c-0.3,1.2-0.6,2.3-0.8,3.3c-0.4,2-0.5,3.4-0.4,4.3c-0.6,0-1.1-0.1-1.5-0.2C1911.7,1619.4,1911.2,1619.2,1910.6,1619z"
						/>
						<path
							d="M1927,1622.4c-0.1-0.4-0.1-1,0.1-1.9c0.2-0.7,0.4-1.7,0.8-2.9c0.3-1.2,0.5-2.1,0.7-2.6c0.2-0.8,0.3-1.5,0.4-2.2
									s0-1.2-0.1-1.5c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.1,1.6,0.2c-0.2,0.5-0.5,1.3-0.8,2.5c-0.3,1.1-0.7,2.4-1,3.8
									c-0.2,1-0.4,1.8-0.5,2.4c-0.1,0.6-0.1,1,0,1.4c0.1,0.4,0.2,0.7,0.4,1.1c-0.4,0.3-0.7,0.4-1.1,0.5s-0.7,0.1-1,0
									C1927.5,1623,1927.1,1622.8,1927,1622.4z M1929.7,1607.2c0.7-0.6,1.4-1,2.1-1.3c0.4,0.8,0.8,1.5,1.3,2.1c-0.7,0.5-1.4,1-2.1,1.3
									C1930.6,1608.6,1930.1,1607.9,1929.7,1607.2z"
						/>
						<path
							d="M1940.8,1624.9c-0.5-1.2-0.5-2.7,0-4.5c0.3-1.2,0.9-2.2,1.6-3.1c0.7-0.8,1.6-1.4,2.6-1.7s2-0.3,3.1,0
									c1.4,0.4,2.4,1.2,2.9,2.4c0.5,1.2,0.4,2.7-0.1,4.6c-1,0-2.2-0.1-3.6-0.4s-2.8-0.5-4.1-0.8c-0.2,1.2-0.1,2.1,0.2,2.7
									c0.3,0.6,1,1.1,2,1.4c0.7,0.2,1.5,0.3,2.2,0.3c0.7,0,1.3-0.1,1.9-0.3l0,0.6c-0.8,0.6-1.7,1-2.7,1.2s-2.1,0.1-3-0.1
									C1942.3,1626.9,1941.3,1626.1,1940.8,1624.9z M1946.2,1620.9c0.9,0,1.7,0,2.2-0.1c0.3-1,0.2-1.9-0.1-2.6c-0.4-0.8-0.9-1.3-1.8-1.5
									c-0.7,0.2-1.3,0.6-1.8,1.3c-0.6,0.7-1,1.6-1.3,2.7C1944.4,1620.8,1945.3,1620.9,1946.2,1620.9z"
						/>
						<path
							d="M1960.1,1631.9c0.7-1.8,1.4-3.7,2.1-5.8c0.3-1,0.6-1.8,0.7-2.3c0.1-0.5,0.2-1,0.1-1.4c0-0.4-0.1-0.7-0.3-1.2
									c0.4-0.3,0.8-0.4,1.2-0.4c0.4,0,0.7,0,1,0.1c0.9,0.3,1.3,1,1.1,2.2c0.6-0.4,1.3-0.6,2.1-0.7c0.7-0.1,1.4,0,2.1,0.2
									c2.1,0.7,2.7,2.3,1.8,4.8l-0.6,1.7c-0.3,0.7-0.5,1.3-0.7,1.8c-0.6,1.9-0.9,3.3-0.8,4.3c-0.5-0.1-1-0.2-1.5-0.3
									c-0.5-0.2-1-0.4-1.5-0.7c0.9-2.2,1.7-4.5,2.6-7.1c0.2-0.7,0.3-1.3,0.1-1.7s-0.6-0.7-1.2-1c-1-0.3-1.8-0.4-2.6-0.2l-0.4,1.2
									c-0.4,0.9-0.8,2-1.2,3.3c-0.6,1.9-0.9,3.3-0.8,4.3c-0.6-0.1-1.1-0.2-1.6-0.3C1961.2,1632.5,1960.7,1632.2,1960.1,1631.9z"
						/>
					</g>
					<g className="level_zero location" id="minas_tirith">
						<rect x="1199.134" y="2001.553" width="77.864" height="44.031" transform="matrix(0.908703, 0.417444, -0.417444, 0.908703, 1745.168335, -709.548584)"></rect>
						<path
							d="M1989.1,1649.9c0.4-1,1-2.5,1.7-4.4c0.7-1.9,1.3-3.7,1.9-5.5c0.6-2,1.1-3.6,1.4-5.1c-2.6-0.8-4.4-1.2-5.3-1.2
									c0-0.5,0.1-1,0.3-1.5c0.1-0.4,0.3-0.9,0.7-1.5c0.8,0.4,1.9,0.8,3.4,1.3c1.4,0.5,2.8,1,4.1,1.4c3.1,1,5.1,1.5,5.9,1.4
									c-0.1,0.5-0.2,1-0.3,1.5c-0.1,0.4-0.3,0.8-0.7,1.5c-1.2-0.5-2.9-1.2-5-1.9c-1,2.8-1.9,5.2-2.6,7.3c-1.3,4.1-1.9,6.6-1.9,7.7
									c-0.7-0.1-1.3-0.2-1.8-0.4C1990.4,1650.5,1989.8,1650.3,1989.1,1649.9z"
						/>
						<path
							d="M2001.7,1653.9c-0.1-0.4-0.1-1,0.2-1.9c0.2-0.7,0.6-1.6,1.1-2.8c0.5-1.1,0.8-2,1-2.5c0.3-0.7,0.5-1.4,0.6-2.1
									s0.1-1.2,0-1.5c0.7,0,1.3,0.1,1.9,0.2c0.6,0.1,1.2,0.3,1.9,0.5c-0.3,0.5-0.7,1.2-1.1,2.3s-0.9,2.3-1.4,3.6c-0.4,1-0.6,1.8-0.7,2.3
									c-0.1,0.5-0.1,1-0.1,1.4c0,0.4,0.2,0.8,0.4,1.2c-0.5,0.2-0.9,0.3-1.4,0.3c-0.5,0-0.9,0-1.2-0.2
									C2002.2,1654.6,2001.8,1654.3,2001.7,1653.9z M2005.8,1639.2c0.9-0.4,1.7-0.8,2.6-1c0.4,0.8,0.8,1.6,1.4,2.4
									c-0.8,0.4-1.7,0.7-2.6,0.9C2006.8,1640.8,2006.4,1640,2005.8,1639.2z"
						/>
						<path
							d="M2010.2,1657.3c0.9-1.7,1.7-3.6,2.5-5.6c0.4-1,0.6-1.7,0.8-2.3c0.1-0.5,0.2-1,0.1-1.4c0-0.4-0.2-0.8-0.4-1.2
									c0.5-0.2,0.9-0.3,1.4-0.3c0.5,0,0.9,0.1,1.2,0.2c1.2,0.5,1.6,1.4,1.1,2.7c0.8-0.3,1.5-0.6,2.3-0.7c0.8-0.1,1.6-0.1,2.5,0
									c0,0.3,0,0.8-0.1,1.4c-0.1,0.6-0.2,1.1-0.4,1.5c-0.2-0.1-0.6-0.3-1.1-0.5c-0.7-0.3-1.3-0.5-2-0.6c-0.7-0.1-1.2-0.1-1.7-0.1
									l-0.4,0.8c-0.5,1.2-0.9,2.2-1.3,3.1c-0.7,1.9-1.1,3.3-1,4.2c-0.7-0.1-1.3-0.3-1.8-0.5C2011.5,1658,2010.9,1657.7,2010.2,1657.3z"
						/>
						<path
							d="M2021.5,1661.7c-0.1-0.4,0-1,0.4-1.8c0.3-0.6,0.7-1.6,1.3-2.7c0.5-1.1,0.9-1.9,1.1-2.4c0.3-0.7,0.5-1.4,0.7-2
									s0.2-1.1,0.1-1.5c0.7,0.1,1.3,0.2,1.8,0.3c0.6,0.2,1.2,0.4,1.8,0.7c-0.3,0.5-0.8,1.2-1.3,2.2c-0.5,1-1.1,2.2-1.7,3.5
									c-0.4,1-0.7,1.7-0.8,2.2c-0.1,0.5-0.2,1-0.2,1.4c0,0.4,0.1,0.8,0.4,1.2c-0.5,0.2-0.9,0.2-1.4,0.2c-0.5,0-0.9-0.1-1.2-0.2
									C2022,1662.5,2021.6,1662.1,2021.5,1661.7z M2026.6,1647.3c0.9-0.4,1.8-0.6,2.6-0.8c0.3,0.8,0.7,1.7,1.2,2.5
									c-0.8,0.3-1.7,0.6-2.6,0.8C2027.5,1648.9,2027.1,1648.1,2026.6,1647.3z"
						/>
						<path
							d="M2030.4,1665.7c-0.1-0.4,0-1,0.3-1.8c0.3-0.5,0.7-1.4,1.3-2.6c0.7-1.3,1.2-2.4,1.6-3.2c0.2-0.4,0.4-0.8,0.5-1.2
									c-0.8-0.4-1.3-0.7-1.6-0.9c-0.2-0.2-0.3-0.5-0.2-0.7c1.6,0,3.3-0.3,5-0.8l0.7,0.4c-0.2,0.6-0.4,1.3-0.7,1.9c1.4,0.6,2.4,1,3.1,1.2
									c-0.2,0.4-0.4,0.7-0.6,0.9c-0.2,0.2-0.5,0.5-0.8,0.7c-0.5-0.5-1.3-1-2.4-1.5c-0.4,0.7-0.8,1.5-1.2,2.4c-1,2.1-1.6,3.8-1.8,5
									c1,0.4,1.8,0.7,2.4,0.8l-0.1,0.7c-0.6,0.3-1.3,0.5-2.1,0.4c-0.8,0-1.5-0.2-2.2-0.5C2031,1666.6,2030.6,1666.2,2030.4,1665.7z"
						/>
						<path
							d="M2039,1670.1c1.3-2.1,2.5-4.2,3.6-6.3c1.5-2.9,2.6-5.3,3.3-7c1.2,0.2,2.4,0.6,3.6,1.2c-0.5,0.7-1.5,2.3-3,4.7
									c0.8-0.2,1.7-0.3,2.6-0.2c0.9,0.1,1.7,0.3,2.4,0.7c2.3,1.2,2.9,3,1.7,5.3l-0.9,1.6l-0.9,1.7c-0.9,1.8-1.4,3.1-1.4,4.1
									c-0.6-0.2-1.2-0.4-1.7-0.7c-0.5-0.3-1-0.6-1.7-1.1c1.4-2.3,2.6-4.5,3.7-6.5c0.4-0.7,0.4-1.2,0.3-1.7c-0.2-0.5-0.6-0.9-1.4-1.3
									c-1.1-0.6-2.1-0.8-3.2-0.8c-0.3,0.5-0.7,1.1-1.1,2c-1.7,3.2-2.5,5.2-2.4,6.1c-0.6-0.2-1.2-0.4-1.7-0.7
									C2040.1,1670.9,2039.6,1670.5,2039,1670.1z"
						/>
						<path
							d="M1999.6,1629.5c0.9-1.8,2.2-4.5,3.6-8c0.8-1.8,1.3-3.2,1.6-4.1c0.3-0.9,0.5-1.6,0.6-2c0.5,0.1,1,0.2,1.5,0.5
									c0.4,0.2,0.9,0.4,1.5,0.8c0,1.5,0,3.6,0.1,6.2c0.1,2.6,0.2,4.7,0.3,6.3c1.3-1.1,2.8-2.5,4.6-4.2c1.8-1.7,3.4-3.1,4.6-4.3
									c0.4,0,0.9,0.2,1.6,0.5c0.4,0.2,0.9,0.5,1.4,0.8c-0.4,0.8-1,2-1.7,3.5c-0.7,1.5-1.3,3-2,4.4c-1.4,3.3-2.1,5.4-2.1,6.2
									c-0.5-0.1-1-0.2-1.5-0.5c-0.5-0.2-1-0.5-1.5-0.8c0.5-0.9,1.1-2,1.8-3.6s1.3-3,1.9-4.3c0.4-0.9,0.8-1.9,1.2-3.1
									c-2.5,2.2-5.8,5.1-9.8,8.9c-0.3,0-0.5,0-0.6-0.1c-0.1-0.1-0.3-0.2-0.5-0.4c0-1.3,0-3.2-0.1-5.7c-0.1-2.5-0.2-4.7-0.3-6.7
									c-0.8,1.8-1.4,3.3-1.9,4.3c-1.4,3.2-2.1,5.3-2.3,6.2c-0.4,0-0.7-0.1-1.1-0.3C2000.3,1630,1999.9,1629.8,1999.6,1629.5z"
						/>
						<path
							d="M2019.9,1638c-0.1-0.4,0.1-1,0.5-1.8c0.3-0.7,0.7-1.6,1.3-2.7c0.6-1.1,0.9-1.9,1.2-2.5c0.3-0.7,0.6-1.4,0.8-2
									c0.2-0.6,0.3-1.1,0.2-1.5c0.6,0,1.1,0.1,1.6,0.2c0.5,0.1,1,0.3,1.6,0.6c-0.3,0.5-0.8,1.2-1.3,2.3c-0.5,1-1.1,2.2-1.7,3.5
									c-0.4,1-0.8,1.7-0.9,2.3s-0.3,1-0.3,1.3c0,0.4,0.1,0.8,0.2,1.2c-0.4,0.2-0.8,0.3-1.2,0.3s-0.7-0.1-1-0.2
									C2020.2,1638.7,2020,1638.4,2019.9,1638z M2025.5,1623.5c0.8-0.4,1.6-0.7,2.4-0.9c0.2,0.8,0.5,1.6,0.9,2.4
									c-0.8,0.4-1.6,0.7-2.4,0.9C2026.1,1625.1,2025.8,1624.3,2025.5,1623.5z"
						/>
						<path
							d="M2027,1641.5c1-1.7,1.9-3.5,2.9-5.5c0.5-1,0.8-1.7,1-2.2c0.2-0.5,0.3-1,0.3-1.3c0-0.4-0.1-0.8-0.2-1.2
									c0.4-0.2,0.9-0.3,1.2-0.3s0.7,0.1,1,0.2c0.9,0.4,1.2,1.2,0.8,2.3c0.7-0.3,1.4-0.4,2.2-0.4c0.8,0,1.5,0.2,2.1,0.5
									c2,1,2.5,2.7,1.3,5.1l-0.9,1.6c-0.4,0.6-0.7,1.2-0.9,1.7c-0.9,1.8-1.3,3.2-1.4,4.2c-0.5-0.1-1-0.3-1.5-0.5
									c-0.5-0.2-0.9-0.5-1.5-0.9c1.1-2,2.3-4.3,3.5-6.7c0.3-0.7,0.4-1.3,0.3-1.7c-0.1-0.4-0.5-0.8-1.2-1.1c-1-0.5-1.8-0.7-2.7-0.6
									l-0.6,1.1c-0.5,0.9-1,1.9-1.6,3.1c-0.9,1.8-1.4,3.2-1.4,4.2c-0.6-0.2-1.1-0.3-1.6-0.6C2028,1642.2,2027.5,1642,2027,1641.5z"
						/>
						<path
							d="M2040.6,1647.8c-0.2-0.7-0.1-1.4,0.3-2.1c1.1-2.1,3.7-2.2,7.9-0.3c0.6-1.1,0.8-2,0.8-2.6c-0.1-0.6-0.6-1.2-1.4-1.6
									c-0.5-0.3-1.1-0.5-1.7-0.6c-0.6-0.1-1.2-0.1-1.8-0.1l0.1-0.6c0.9-0.5,1.8-0.8,2.8-0.9c1-0.1,2,0.1,2.8,0.6
									c1.1,0.6,1.8,1.3,2.1,2.2s0.1,1.9-0.4,3c-0.3,0.6-0.7,1.3-1.2,2c-0.5,0.8-0.9,1.4-1.1,1.8c-0.3,0.7-0.5,1.2-0.6,1.6
									c-0.1,0.4,0,1,0.2,1.6c-0.9,0.2-1.8,0.2-2.6-0.1c-0.3-0.6-0.4-1.2-0.2-1.9c-0.8,0.1-1.7,0.1-2.5,0c-0.8-0.1-1.5-0.3-2.1-0.6
									C2041.2,1649,2040.8,1648.5,2040.6,1647.8z M2045,1649c0.6,0,1.1,0,1.7-0.1l0.1-0.1c0.3-0.5,0.6-1.1,0.9-1.6l0.7-1.1
									c-1.2-0.5-2.2-0.8-3.1-0.7s-1.5,0.5-1.8,1.2c-0.2,0.3-0.2,0.6-0.2,1s0.1,0.7,0.3,1C2043.9,1648.8,2044.5,1648.9,2045,1649z"
						/>
						<path
							d="M2051.8,1653.4c0.2-0.3,0.4-0.6,0.6-0.9s0.5-0.5,0.9-0.7c0.1,0.6,0.3,1.2,0.7,1.7c0.4,0.5,0.9,1,1.5,1.3
									c1.6,0.9,2.7,0.9,3.2,0c0.2-0.4,0.2-0.8,0-1.2c-0.2-0.5-0.6-1.1-1.2-1.8c-0.6-0.8-1-1.4-1.3-1.9c-0.3-0.5-0.5-1.1-0.6-1.7
									s0.1-1.3,0.4-2c0.4-0.8,1.2-1.2,2.1-1.3c1-0.1,2,0.2,3.2,0.8c1.5,0.8,2.7,1.8,3.5,2.8c-0.2,0.3-0.4,0.6-0.8,0.9
									c-0.4,0.4-0.8,0.7-1.2,0.9c-0.3-0.9-0.6-1.7-1-2.3c-0.4-0.6-0.8-1.1-1.2-1.3c-0.5-0.1-0.9-0.2-1.2-0.1c-0.4,0.1-0.6,0.3-0.8,0.5
									c-0.3,0.5-0.3,1-0.1,1.5c0.2,0.5,0.6,1.2,1.3,2c0.5,0.7,0.9,1.3,1.2,1.8c0.3,0.5,0.5,1.1,0.6,1.7c0.1,0.6-0.1,1.2-0.4,1.8
									c-0.5,0.9-1.3,1.5-2.4,1.6s-2.3-0.2-3.6-1C2053.5,1655.6,2052.4,1654.5,2051.8,1653.4z"
						/>
					</g>
					<g className="level_two fade location" id="osgiliath">
						<rect x="1827.143" y="1329.315" width="118.64" height="29.244" transform="matrix(0.872404, 0.488785, -0.499296, 0.866515, 1150.232666, -367.417572)"></rect>
						<path
							d="M2075.2,1697.4c-0.5-1.3-0.7-2.6-0.4-4.1c0.2-1.5,0.9-2.9,1.9-4.3c1.1-1.6,2.4-2.8,3.9-3.6c1.5-0.8,3-1.2,4.6-1.1
									c1.6,0.1,3.1,0.6,4.6,1.6c1.3,0.9,2.2,2,2.7,3.3c0.5,1.3,0.7,2.7,0.5,4.2c-0.2,1.5-0.9,3-1.9,4.4c-1.1,1.6-2.4,2.7-3.9,3.5
									c-1.5,0.8-3,1.1-4.7,1c-1.6-0.1-3.2-0.7-4.7-1.7C2076.6,1699.7,2075.7,1698.7,2075.2,1697.4z M2083.8,1700.2
									c1.5-0.5,2.9-1.6,4.1-3.4c1.1-1.6,1.7-3.2,1.7-4.7s-0.5-2.9-1.7-4c-0.9-0.6-1.9-0.9-3-0.9c-1.1,0-2.1,0.3-3.1,0.9
									c-1,0.6-1.9,1.4-2.7,2.6c-1.2,1.8-1.8,3.4-1.7,5.1c0.1,1.6,0.7,2.9,2,3.8C2080.9,1700.5,2082.3,1700.7,2083.8,1700.2z"
						/>
						<path
							d="M2089.6,1706.9c0.2-0.4,0.5-0.7,0.8-1s0.6-0.6,1.1-0.8c0,0.7,0.3,1.4,0.7,2c0.4,0.6,0.9,1.1,1.6,1.5
									c1.7,1.1,2.9,1.1,3.5,0.1c0.3-0.4,0.3-0.9,0.1-1.4s-0.6-1.2-1.2-2.1c-0.6-0.9-1-1.6-1.3-2.2c-0.3-0.6-0.5-1.3-0.5-2
									c0-0.8,0.2-1.5,0.7-2.3c0.6-0.9,1.4-1.4,2.5-1.5s2.3,0.2,3.5,1c1.6,1,2.8,2.1,3.7,3.3c-0.2,0.3-0.5,0.7-1,1.1s-1,0.8-1.5,1
									c-0.3-1-0.6-2-0.9-2.7c-0.4-0.8-0.8-1.3-1.2-1.5c-0.5-0.2-1-0.2-1.4-0.1c-0.4,0.1-0.7,0.3-0.9,0.6c-0.3,0.5-0.4,1.1-0.2,1.7
									s0.6,1.4,1.2,2.4c0.5,0.8,0.9,1.5,1.2,2.1c0.3,0.6,0.4,1.3,0.5,2c0,0.7-0.2,1.4-0.6,2.1c-0.7,1.1-1.6,1.7-2.8,1.8
									c-1.2,0.1-2.5-0.3-4-1.2C2091.3,1709.4,2090.2,1708.2,2089.6,1706.9z"
						/>
						<path
							d="M2098.7,1719c0.4-0.7,0.9-1.3,1.6-1.9c0.6-0.5,1.4-1,2.1-1.2c-0.6-0.7-0.6-1.4-0.2-2.2c0.3-0.6,0.8-1,1.4-1.2
									s1.4-0.3,2.2-0.2c-0.5-0.7-0.7-1.5-0.7-2.3s0.3-1.7,0.8-2.6c0.8-1.4,1.9-2.3,3.3-2.6c1.4-0.4,2.8-0.1,4.2,0.7
									c0.9,0.5,1.6,1.2,2.1,2.1c0.4,0.1,1,0.1,1.6,0.1s1.3-0.1,2.1-0.3c0,0.5-0.1,1-0.2,1.5s-0.3,1-0.4,1.3c-0.8,0-1.6-0.2-2.5-0.6
									c0,0.9-0.3,1.8-0.7,2.6c-0.5,0.9-1.2,1.6-2,2.1c-0.8,0.5-1.7,0.7-2.7,0.7c-1,0-2-0.3-2.9-0.9c-0.8-0.5-1.5-0.7-2.1-0.7
									c-0.6,0-1.1,0.2-1.3,0.7l5.1,3.3c0.9,0.7,1.5,1.4,1.8,2.2s0.1,1.6-0.4,2.5c-0.5,0.8-1.1,1.4-2,1.7s-1.8,0.5-2.9,0.4
									s-2.2-0.5-3.3-1.1C2100.6,1721.8,2099.3,1720.5,2098.7,1719z M2106.9,1722.2c0.8,0,1.3-0.2,1.6-0.7c0.2-0.4,0.3-0.8,0.1-1.1
									s-0.5-0.7-1.2-1.1l-4-2.6c-0.6,0.4-1.1,0.8-1.5,1.3c0.1,1.4,0.9,2.6,2.3,3.4C2105.3,1721.9,2106.1,1722.1,2106.9,1722.2z
									M2110.9,1713.3c0.7-0.2,1.3-0.7,1.7-1.5c0.4-0.7,0.6-1.4,0.4-2.2c-0.1-0.7-0.5-1.4-1.1-2c-0.8-0.4-1.5-0.5-2.3-0.3
									c-0.8,0.3-1.4,0.8-1.9,1.6c-0.4,0.8-0.6,1.5-0.4,2.3c0.2,0.7,0.6,1.3,1.3,1.7C2109.5,1713.3,2110.2,1713.5,2110.9,1713.3z"
						/>
						<path
							d="M2116.1,1722.9c0-0.5,0.2-1.2,0.7-2.1c0.4-0.8,1-1.8,1.7-3.2c0.7-1.3,1.2-2.2,1.5-2.8c0.4-0.8,0.8-1.6,1.1-2.4
									c0.3-0.8,0.4-1.3,0.4-1.7c0.6,0.1,1.2,0.2,1.8,0.3s1.1,0.4,1.7,0.7c-0.4,0.5-1,1.4-1.7,2.6c-0.7,1.2-1.5,2.6-2.3,4.1
									c-0.6,1.1-1,2-1.2,2.6c-0.2,0.6-0.4,1.1-0.4,1.6c0,0.4,0,0.9,0.1,1.4c-0.5,0.2-1,0.3-1.4,0.3c-0.5,0-0.8-0.1-1.1-0.2
									C2116.5,1723.8,2116.2,1723.4,2116.1,1722.9z M2123.7,1706.2c1-0.5,1.9-0.8,2.7-1c0.2,1,0.4,1.9,0.8,2.8c-0.9,0.4-1.8,0.7-2.7,1
									C2124.3,1708,2124,1707.1,2123.7,1706.2z"
						/>
						<path
							d="M2123.9,1726.9c0-0.5,0.2-1.2,0.7-2.2c0.3-0.6,0.8-1.6,1.6-3c0.8-1.5,1.5-2.8,2-3.8c1.7-3.3,2.7-5.8,3-7.3
									c0.6,0,1.2,0.1,1.8,0.3c0.5,0.2,1.1,0.4,1.7,0.7c-1.5,2.4-3.4,6-5.8,10.8c-0.6,1.1-1,2-1.2,2.6c-0.2,0.6-0.4,1.2-0.4,1.6
									c0,0.4,0,0.9,0.2,1.4c-0.5,0.2-1,0.3-1.4,0.3c-0.5,0-0.8-0.1-1.1-0.2C2124.3,1727.7,2124,1727.4,2123.9,1726.9z"
						/>
						<path
							d="M2131.7,1730.7c0-0.5,0.2-1.2,0.6-2.1c0.4-0.8,0.9-1.8,1.7-3.2c0.7-1.3,1.2-2.2,1.5-2.9c0.4-0.8,0.8-1.6,1-2.4
									c0.3-0.8,0.4-1.3,0.3-1.7c0.6,0,1.2,0.1,1.8,0.3s1.1,0.4,1.7,0.7c-0.4,0.5-0.9,1.4-1.6,2.6s-1.4,2.6-2.2,4.1c-0.6,1.1-1,2-1.2,2.6
									c-0.2,0.6-0.4,1.2-0.4,1.6c0,0.4,0,0.9,0.2,1.4c-0.5,0.2-1,0.3-1.4,0.3c-0.5,0-0.8-0.1-1.1-0.2
									C2132,1731.6,2131.7,1731.2,2131.7,1730.7z M2139,1713.9c1-0.5,1.9-0.8,2.7-1c0.2,1,0.5,1.9,0.8,2.8c-0.9,0.4-1.8,0.8-2.7,1
									C2139.6,1715.7,2139.3,1714.8,2139,1713.9z"
						/>
						<path
							d="M2138.8,1734c-0.2-0.8,0-1.6,0.4-2.4c1.3-2.5,4.2-2.7,8.8-0.6c0.7-1.3,1-2.4,0.9-3.1c-0.1-0.7-0.6-1.3-1.6-1.8
									c-0.6-0.3-1.2-0.5-1.9-0.6c-0.7-0.1-1.4-0.1-2.1,0l0.1-0.7c1-0.6,2-1,3.2-1.1s2.2,0.1,3.1,0.6c1.2,0.6,2,1.5,2.3,2.5
									c0.3,1,0.1,2.2-0.6,3.5c-0.4,0.7-0.9,1.5-1.4,2.4c-0.6,0.9-1,1.6-1.2,2.1c-0.4,0.8-0.6,1.4-0.7,1.9c-0.1,0.5,0,1.1,0.1,1.9
									c-1,0.3-2,0.3-2.9,0c-0.3-0.6-0.4-1.4-0.2-2.2c-0.9,0.2-1.9,0.2-2.8,0.1c-0.9-0.1-1.7-0.3-2.3-0.6
									C2139.4,1735.4,2139,1734.8,2138.8,1734z M2143.7,1735.2c0.7,0,1.3,0,1.9-0.1l0.1-0.2c0.3-0.6,0.7-1.3,1.1-1.8l0.8-1.3
									c-1.3-0.6-2.5-0.8-3.4-0.7s-1.7,0.6-2.1,1.4c-0.2,0.3-0.3,0.7-0.3,1.2c0,0.4,0.1,0.8,0.3,1.1
									C2142.5,1735.1,2143,1735.2,2143.7,1735.2z"
						/>
						<path
							d="M2152.9,1741.9c-0.1-0.5,0.1-1.2,0.6-2.1c0.3-0.6,0.9-1.6,1.7-3c0.9-1.5,1.6-2.8,2.2-3.8c0.3-0.5,0.5-1,0.7-1.4
									c-0.7-0.4-1.2-0.7-1.4-1c-0.2-0.2-0.2-0.5,0-0.8c1.6-0.1,3.3-0.4,5.1-1.1l0.6,0.5c-0.3,0.7-0.7,1.5-1,2.2c1.3,0.7,2.2,1.1,2.9,1.3
									c-0.2,0.4-0.5,0.8-0.7,1c-0.3,0.3-0.6,0.5-0.9,0.8c-0.4-0.5-1.1-1.1-2.1-1.7c-0.6,0.9-1.1,1.8-1.7,2.8c-1.4,2.5-2.3,4.4-2.7,5.9
									c0.9,0.4,1.6,0.7,2.2,0.8l-0.2,0.8c-0.6,0.4-1.4,0.6-2.1,0.6c-0.8,0-1.5-0.2-2.1-0.5C2153.3,1742.8,2152.9,1742.4,2152.9,1741.9z"
						/>
						<path
							d="M2160.6,1746.5c1.6-2.5,3.2-4.9,4.7-7.4c2-3.4,3.5-6.2,4.5-8.2c1.1,0.1,2.3,0.5,3.4,1.2c-0.7,0.9-1.9,2.7-3.8,5.5
									c0.9-0.3,1.7-0.4,2.6-0.4c0.9,0,1.6,0.3,2.3,0.7c2.1,1.3,2.4,3.3,0.8,6l-1.2,1.8l-1.2,2c-1.2,2.1-1.9,3.7-2.1,4.8
									c-0.6-0.2-1.1-0.4-1.6-0.7c-0.4-0.3-0.9-0.6-1.5-1.1c1.8-2.7,3.4-5.3,4.8-7.6c0.5-0.8,0.7-1.4,0.6-2s-0.5-1-1.2-1.4
									c-1-0.6-2-0.8-3-0.8c-0.4,0.6-0.9,1.4-1.5,2.4c-2.2,3.7-3.3,6.1-3.4,7.1c-0.6-0.2-1.1-0.4-1.6-0.7
									C2161.7,1747.4,2161.2,1747,2160.6,1746.5z"
						/>
					</g>
					<g className="level_two fade location " id="fords_of_isen">
						<rect x="571.816" y="794.278" width="140.439" height="27.641" transform="matrix(0.991588, 0.129432, -0.428938, 0.952494, 1040.027832, 525.892029)"></rect>
						<path
							d="M1264.6,1374.6c0.3-0.8,0.7-1.9,1.1-3.3c0.5-1.4,0.9-2.8,1.2-4.1c1-3.5,1.5-6,1.6-7.3c0.9,0.4,2.2,0.8,4.1,1.3
									c2,0.6,3.6,0.9,4.9,1.1c-0.1,0.5-0.3,1-0.6,1.6c-0.3,0.5-0.5,0.9-0.7,1.1c-0.3-0.3-0.7-0.7-1.3-0.9c-0.5-0.3-1.1-0.5-1.6-0.7
									c-0.9-0.2-1.7-0.4-2.6-0.5c-0.6,1.7-1,3.1-1.3,4.1l0.6,0.2c0.5,0.2,0.9,0.3,1.2,0.4c0.7,0.2,1.3,0.3,1.7,0.4
									c0.4,0.1,0.9,0.1,1.5,0.2c0,0.4-0.1,0.7-0.2,1.1c-0.1,0.3-0.2,0.6-0.4,1l-1.1-0.4c-1-0.4-2-0.7-2.9-1c-0.4-0.1-0.7-0.2-0.9-0.2
									c-1,3.6-1.5,5.8-1.4,6.6c-0.5,0-1-0.1-1.5-0.2C1265.6,1375.1,1265.1,1374.9,1264.6,1374.6z"
						/>
						<path
							d="M1277.1,1376.7c-0.6-1.3-0.7-2.7-0.3-4.4c0.3-1.2,0.8-2.3,1.5-3.1c0.7-0.8,1.6-1.4,2.6-1.7s2-0.3,3.1-0.1
									c1,0.2,1.8,0.7,2.4,1.4c0.6,0.7,1,1.5,1.2,2.4c0.2,1,0.1,2-0.1,3.1c-0.3,1.2-0.8,2.3-1.6,3.1c-0.7,0.8-1.6,1.4-2.6,1.7
									c-1,0.3-2.1,0.3-3.2,0C1278.7,1378.7,1277.7,1377.9,1277.1,1376.7z M1283.5,1377.5c0.7-0.6,1.3-1.5,1.6-2.8c0.3-1.2,0.3-2.2,0-3.2
									c-0.3-1-0.8-1.7-1.7-2.2c-1-0.2-1.9-0.1-2.7,0.5c-0.8,0.6-1.3,1.5-1.6,2.8c-0.4,1.5-0.4,2.7,0,3.7c0.4,1,1,1.6,1.8,1.8
									C1281.9,1378.3,1282.8,1378.1,1283.5,1377.5z"
						/>
						<path
							d="M1290.1,1381.1c0.5-1.9,1-3.8,1.5-6c0.2-1,0.4-1.8,0.4-2.4c0.1-0.6,0.1-1,0-1.4c-0.1-0.3-0.2-0.7-0.4-1.1
									c0.4-0.3,0.7-0.5,1.1-0.6c0.4-0.1,0.7-0.1,0.9-0.1c1,0.2,1.4,1,1.1,2.4c0.6-0.5,1.2-0.9,1.8-1.2c0.6-0.3,1.3-0.4,2-0.5
									c0.1,0.3,0.1,0.8,0,1.4c0,0.6-0.1,1.1-0.2,1.6c-0.2-0.1-0.5-0.2-0.9-0.3c-0.6-0.1-1.1-0.2-1.6-0.1s-1,0.1-1.3,0.2l-0.2,0.9
									c-0.3,1.2-0.6,2.3-0.8,3.3c-0.4,2-0.6,3.4-0.4,4.3c-0.5,0-1-0.1-1.5-0.2C1291.1,1381.6,1290.6,1381.4,1290.1,1381.1z"
						/>
						<path
							d="M1299.4,1382c-0.5-1.2-0.6-2.7-0.2-4.7c0.4-2,1.1-3.5,2-4.4c0.9-0.9,2.2-1.2,3.6-0.9c1.2,0.2,2.1,0.8,3,1.8
									c0.3-1.9,0.4-3.3,0.3-4.3c0.5-0.1,1.1-0.2,1.5-0.2s1,0.1,1.5,0.2c-0.7,2.3-1.6,6.3-2.7,11.9c-0.1,0.7-0.2,1.2-0.1,1.6
									c0.1,0.4,0.3,0.9,0.6,1.4c-0.7,0.4-1.5,0.6-2.3,0.6c-0.4-0.5-0.6-1.1-0.7-1.8c-0.6,0.4-1.2,0.7-1.8,0.9c-0.6,0.2-1.3,0.2-1.8,0.1
									C1300.9,1383.8,1299.9,1383.1,1299.4,1382z M1304.6,1382.7c0.5-0.1,1-0.3,1.4-0.6c0.1-0.6,0.3-1.6,0.6-3c0.3-1.5,0.6-2.8,0.8-3.6
									c-0.6-0.6-1.3-1-2.3-1.2c-0.9-0.2-1.7,0-2.3,0.6c-0.6,0.6-1.1,1.6-1.3,2.9c-0.3,1.3-0.2,2.4,0.1,3.2c0.3,0.9,0.9,1.5,1.6,1.8
									C1303.7,1382.9,1304.1,1382.8,1304.6,1382.7z"
						/>
						<path
							d="M1311.5,1384.8c0.1-0.4,0.2-0.7,0.3-1c0.1-0.3,0.3-0.6,0.5-1c0.3,0.5,0.6,1,1.1,1.3c0.5,0.4,1.1,0.6,1.7,0.7
									c1.7,0.3,2.6-0.1,2.8-1.1c0.1-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.9-0.8-1.6-1.3c-0.7-0.5-1.3-1-1.7-1.3c-0.4-0.4-0.8-0.8-1-1.4
									c-0.3-0.6-0.3-1.2-0.2-2c0.2-0.9,0.6-1.6,1.5-2c0.8-0.4,1.8-0.5,3-0.3c1.6,0.3,2.9,0.7,3.9,1.4c-0.1,0.3-0.2,0.7-0.4,1.2
									s-0.5,0.9-0.8,1.3c-0.5-0.7-1.1-1.3-1.6-1.8c-0.5-0.5-1-0.7-1.4-0.8c-0.4,0-0.8,0.1-1.1,0.4c-0.3,0.2-0.5,0.5-0.5,0.8
									c-0.1,0.5,0,1,0.4,1.4c0.4,0.4,0.9,0.9,1.7,1.5c0.7,0.5,1.2,0.9,1.6,1.3s0.7,0.8,1,1.4c0.2,0.5,0.3,1.2,0.2,1.8
									c-0.2,1.1-0.7,1.8-1.6,2.3c-0.9,0.5-2,0.6-3.5,0.3C1313.7,1386.2,1312.4,1385.6,1311.5,1384.8z"
						/>
						<path
							d="M1331,1387.3c-0.8-1.2-1-2.6-0.8-4.4c0.2-1.3,0.5-2.4,1.1-3.3c0.6-0.9,1.4-1.6,2.3-2c0.9-0.4,2-0.6,3.1-0.5
									c1,0.1,1.8,0.5,2.5,1.1s1.2,1.3,1.5,2.3c0.3,0.9,0.4,2,0.3,3.1c-0.2,1.3-0.5,2.4-1.2,3.3s-1.4,1.6-2.4,2c-1,0.4-2,0.6-3.2,0.4
									C1332.8,1389.1,1331.7,1388.4,1331,1387.3z M1337.4,1387.3c0.7-0.7,1.1-1.7,1.2-3c0.1-1.2,0-2.2-0.4-3.2c-0.4-0.9-1-1.6-1.9-2
									c-1-0.1-1.9,0.2-2.6,0.8c-0.7,0.7-1.1,1.7-1.3,2.9c-0.2,1.5,0,2.7,0.4,3.6c0.5,0.9,1.1,1.5,2,1.6
									C1335.9,1388.3,1336.7,1388,1337.4,1387.3z"
						/>
						<path
							d="M1343,1394.3c0.4-0.5,0.7-0.9,1-1.3c0.2-0.4,0.4-1,0.6-1.7c0.2-0.7,0.3-1.8,0.4-3.1c0.1-1.5,0.3-4.1,0.4-7.9
									c-0.6-0.1-1-0.2-1.2-0.3c-0.2-0.1-0.3-0.3-0.3-0.6c0.7-0.3,1.3-0.6,1.6-0.9c0.3-1.6,1-2.8,1.9-3.7c0.9-0.9,2-1.3,3.2-1.2
									c0.9,0.1,1.7,0.3,2.5,0.6c0,0.3-0.1,0.7-0.3,1.2c-0.2,0.5-0.4,1-0.7,1.4c-0.5-0.6-1-1.1-1.5-1.4c-0.6-0.4-1-0.6-1.4-0.6
									c-0.3,0.2-0.6,0.6-0.8,1.2c-0.2,0.6-0.3,1.6-0.5,2.9c1.3,0.1,2.3,0.1,2.8,0c0,0.4-0.1,0.7-0.2,1c-0.1,0.3-0.2,0.6-0.4,0.9
									c-0.6-0.3-1.4-0.4-2.3-0.5l-0.2,2c-0.1,1.2-0.2,2.5-0.2,4c0,0.8,0,1.5-0.1,2.1c-0.1,1-0.2,1.8-0.5,2.4c-0.2,0.6-0.6,1.2-1.2,1.8
									s-1.3,1.2-2.3,1.9L1343,1394.3z"
						/>
						<path
							d="M1360.4,1391c0.1-0.9,0.2-2.3,0.2-3.9c0.1-1.7,0.1-3.3,0.1-4.8c0-3.5-0.1-5.7-0.4-6.5c0.5-0.1,1-0.2,1.4-0.2
									c0.5,0,1,0.1,1.6,0.2c-0.1,0.9-0.2,2.2-0.2,3.9s-0.1,3.2-0.1,4.7c0,3.5,0.1,5.7,0.4,6.6c-0.5,0.1-1,0.2-1.5,0.2
									C1361.4,1391.1,1360.9,1391.1,1360.4,1391z"
						/>
						<path
							d="M1367,1390.2c0-0.4,0-0.7,0-1c0-0.3,0.1-0.7,0.3-1.1c0.4,0.5,0.9,0.8,1.4,1c0.6,0.2,1.2,0.3,1.8,0.3
									c1.7-0.1,2.5-0.7,2.4-1.7c0-0.4-0.2-0.8-0.7-1c-0.4-0.3-1-0.5-1.8-0.8c-0.8-0.3-1.5-0.6-2-0.9c-0.5-0.3-1-0.6-1.3-1.1
									c-0.4-0.5-0.6-1.1-0.7-1.9c-0.1-0.9,0.2-1.7,0.9-2.3c0.7-0.6,1.6-1,2.8-1c1.6-0.1,3,0,4.1,0.4c0,0.3,0,0.7-0.1,1.2
									c-0.1,0.5-0.3,1-0.5,1.4c-0.7-0.6-1.3-1-2-1.4c-0.6-0.3-1.2-0.5-1.6-0.4c-0.4,0.1-0.8,0.3-1,0.6c-0.2,0.3-0.4,0.6-0.3,0.9
									c0,0.5,0.3,1,0.7,1.2c0.4,0.3,1.1,0.6,2,1c0.8,0.3,1.4,0.6,1.9,0.8c0.5,0.3,0.9,0.6,1.3,1.1c0.4,0.5,0.6,1,0.6,1.7
									c0.1,1.1-0.3,1.9-1,2.6c-0.7,0.7-1.9,1-3.3,1.1C1369.4,1391.1,1368,1390.8,1367,1390.2z"
						/>
						<path
							d="M1379.7,1388.6c-0.9-0.9-1.5-2.2-1.8-4.1c-0.2-1.3-0.2-2.4,0.1-3.5s0.8-1.9,1.5-2.6c0.7-0.7,1.6-1.1,2.7-1.3
									c1.4-0.2,2.6,0.1,3.5,1c0.9,0.9,1.5,2.3,1.8,4.2c-0.9,0.4-2,0.8-3.3,1.2c-1.3,0.4-2.6,0.7-3.9,1c0.3,1.1,0.7,1.9,1.3,2.4
									c0.6,0.4,1.3,0.6,2.3,0.4c0.7-0.1,1.4-0.3,2-0.6c0.6-0.3,1.1-0.6,1.5-1l0.3,0.5c-0.4,0.8-1,1.6-1.9,2.2c-0.8,0.6-1.7,1-2.7,1.2
									C1381.8,1389.8,1380.6,1389.4,1379.7,1388.6z M1382.7,1382.7c0.8-0.4,1.4-0.7,1.9-1.1c-0.2-1-0.6-1.8-1.2-2.3s-1.3-0.8-2.2-0.6
									c-0.5,0.4-0.8,1.1-1,2c-0.2,0.9-0.2,1.9,0,3C1381.1,1383.3,1381.9,1383,1382.7,1382.7z"
						/>
						<path
							d="M1393.5,1387.2c-0.4-1.9-0.8-3.9-1.4-6c-0.3-1-0.5-1.8-0.7-2.3c-0.2-0.5-0.4-0.9-0.6-1.2c-0.2-0.3-0.5-0.6-0.9-0.8
									c0.2-0.4,0.4-0.8,0.7-1c0.3-0.2,0.5-0.4,0.8-0.5c0.9-0.2,1.6,0.2,2,1.3c0.3-0.7,0.7-1.3,1.2-1.7c0.5-0.5,1.1-0.8,1.8-1
									c2-0.5,3.4,0.5,4,3.1l0.4,1.8c0.1,0.7,0.3,1.3,0.4,1.9c0.5,1.9,1,3.3,1.6,4c-0.5,0.2-0.9,0.4-1.3,0.5c-0.4,0.1-1,0.2-1.6,0.2
									c-0.5-2.3-1-4.7-1.7-7.4c-0.2-0.7-0.5-1.2-0.8-1.5c-0.4-0.3-0.9-0.3-1.5-0.1c-1,0.2-1.7,0.7-2.2,1.3l0.3,1.2
									c0.2,1,0.5,2.1,0.8,3.4c0.5,2,1,3.3,1.6,4.1c-0.5,0.2-1,0.4-1.4,0.6C1394.6,1387.1,1394.1,1387.2,1393.5,1387.2z"
						/>
					</g>
					<g className="level_two fade location " id="north_south_road">
						<rect x="648.871" y="1315.127" width="159.363" height="45.766" transform="matrix(0.383798, 0.923417, -1.039342, 0.104882, 2360.777832, 466.812836)"></rect>
						<path
							d="M1225,1211.2c2.2-0.7,5.2-1.9,9.1-3.5c3.5-1.4,5.7-2.5,6.6-3.1c0.4,0.5,0.6,1,0.8,1.3c0.2,0.4,0.3,1,0.5,1.7
									c-1.1,1.3-2.6,3.1-4.6,5.5c-1.9,2.3-3.6,4.4-5,6.2c2.4-0.9,4.2-1.7,5.6-2.2c3.7-1.5,5.9-2.5,6.6-3.1c0.3,0.3,0.5,0.7,0.6,1
									c0.1,0.3,0.2,0.7,0.3,1.2c-1.1,0.4-2.5,0.9-4.2,1.6c-1.8,0.7-3.4,1.3-4.9,1.9c-3.5,1.4-5.7,2.5-6.6,3.1c-0.2-0.2-0.5-0.7-0.8-1.3
									c-0.2-0.4-0.3-1-0.5-1.7c1.1-1.3,2.6-3.1,4.5-5.4c1.9-2.3,3.6-4.4,5-6.2c-1.8,0.7-3.6,1.4-5.5,2.2c-3.6,1.5-5.8,2.5-6.6,3.1
									c-0.3-0.3-0.5-0.6-0.6-1C1225.1,1212,1225.1,1211.6,1225,1211.2z"
						/>
						<path
							d="M1233.2,1227.3c0.7-1.4,2-2.4,3.8-3.2c1.3-0.5,2.6-0.8,3.8-0.7c1.2,0.1,2.3,0.5,3.3,1.2c0.9,0.7,1.6,1.6,2.1,2.8
									c0.4,1,0.5,2,0.4,3c-0.2,1-0.6,1.9-1.4,2.7c-0.7,0.8-1.7,1.5-2.9,1.9c-1.3,0.5-2.6,0.7-3.8,0.6s-2.3-0.5-3.3-1.2
									c-1-0.7-1.7-1.7-2.2-2.9C1232.4,1230.1,1232.4,1228.6,1233.2,1227.3z M1236.6,1233.6c1,0.3,2.2,0.1,3.6-0.4
									c1.2-0.5,2.2-1.2,2.9-2.1c0.7-0.9,1-1.9,0.9-3c-0.4-1.1-1.2-1.8-2.2-2.1c-1-0.3-2.2-0.2-3.6,0.3c-1.5,0.6-2.7,1.4-3.3,2.4
									s-0.8,1.9-0.4,2.8C1234.9,1232.6,1235.6,1233.3,1236.6,1233.6z"
						/>
						<path
							d="M1237.8,1242.4c2-0.7,4.2-1.5,6.4-2.4c1.1-0.4,1.9-0.8,2.5-1.1c0.5-0.3,1-0.6,1.2-0.9c0.3-0.3,0.5-0.6,0.8-1.1
									c0.5,0.1,0.9,0.4,1.2,0.6s0.5,0.6,0.7,0.8c0.4,1.1-0.1,1.9-1.5,2.6c0.8,0.2,1.6,0.5,2.2,0.9c0.6,0.4,1.2,0.9,1.7,1.5
									c-0.3,0.3-0.7,0.6-1.2,0.9s-1.1,0.6-1.6,0.8c-0.1-0.2-0.2-0.6-0.3-1c-0.2-0.6-0.5-1.1-0.9-1.6c-0.4-0.5-0.7-0.8-1.1-1.1l-0.9,0.3
									c-1.3,0.5-2.5,1-3.5,1.4c-2.1,0.8-3.5,1.6-4.2,2.4c-0.3-0.5-0.6-1-0.8-1.4S1237.9,1243.1,1237.8,1242.4z"
						/>
						<path
							d="M1242.4,1253c0.2-0.4,0.8-0.8,1.7-1.1c0.6-0.2,1.6-0.6,3-1.2c1.5-0.6,2.8-1.1,3.8-1.5c0.5-0.2,0.9-0.4,1.4-0.6
									c-0.3-0.7-0.4-1.3-0.4-1.5c0-0.3,0.2-0.5,0.4-0.6c1.2,0.9,2.7,1.6,4.4,2.2l0.2,0.7c-0.6,0.3-1.3,0.7-2,1c0.5,1.2,1,2.1,1.3,2.6
									c-0.4,0.2-0.8,0.3-1.1,0.3c-0.3,0.1-0.7,0.1-1.1,0c0-0.6-0.2-1.4-0.6-2.4c-0.9,0.3-1.9,0.7-2.8,1.1c-2.4,1-4.2,1.9-5.4,2.7
									c0.4,0.8,0.8,1.5,1.2,1.9l-0.6,0.4c-0.7-0.1-1.3-0.4-1.9-0.8c-0.6-0.5-1-1-1.2-1.6C1242.1,1253.9,1242.1,1253.4,1242.4,1253z"
						/>
						<path
							d="M1245.4,1261.3c2.6-0.9,5.2-1.9,7.7-2.9c3.5-1.4,6.1-2.6,8-3.6c0.8,0.7,1.4,1.7,1.8,2.8c-1,0.3-3,0.9-5.9,2
									c0.8,0.3,1.5,0.7,2.1,1.2c0.6,0.5,1.1,1.1,1.3,1.8c0.9,2.2-0.1,3.8-2.8,4.9l-1.9,0.7l-2,0.8c-2.1,0.8-3.5,1.6-4.3,2.4
									c-0.3-0.5-0.6-0.9-0.8-1.4c-0.2-0.4-0.3-1-0.5-1.7c2.9-1,5.5-2,7.9-3c0.8-0.3,1.3-0.7,1.5-1.2c0.2-0.4,0.2-1-0.1-1.7
									c-0.4-1-1-1.7-1.8-2.3c-0.6,0.2-1.4,0.5-2.5,0.9c-3.7,1.5-5.9,2.6-6.6,3.3c-0.3-0.5-0.6-0.9-0.8-1.5
									C1245.7,1262.5,1245.5,1261.9,1245.4,1261.3z"
						/>
						<path
							d="M1259.2,1274.8c0.4,1.3,0.9,2.7,1.5,4.2c0.3,0.8,0.7,1.5,1.1,2.3c0.4,0.7,0.8,1.3,1.1,1.6c-0.8,0.6-1.7,1.1-2.8,1.6
									c-0.4-1.3-0.9-2.7-1.5-4.2c-0.3-0.8-0.7-1.6-1.1-2.3c-0.4-0.7-0.8-1.2-1.1-1.5C1257.2,1275.8,1258.1,1275.2,1259.2,1274.8z"
						/>
						<path
							d="M1257.7,1289.5c0.4-0.2,0.8-0.3,1.1-0.3c0.3-0.1,0.7-0.1,1.1,0c-0.3,0.6-0.4,1.4-0.4,2.2c0,0.8,0.2,1.7,0.6,2.6
									c0.4,1,0.9,1.7,1.6,2s1.3,0.4,2.1,0c0.7-0.3,1.1-0.8,1.3-1.5c0.2-0.7,0.4-1.7,0.5-3c0.1-1.2,0.3-2.1,0.4-2.9
									c0.2-0.7,0.5-1.4,0.9-2.1c0.5-0.7,1.1-1.2,2-1.5c1.1-0.5,2.3-0.4,3.4,0.3c1.1,0.7,2,1.9,2.7,3.6c0.6,1.5,0.9,2.9,0.9,4.2
									c-0.4,0.2-1,0.3-1.6,0.4c-0.6,0.1-1.1,0.1-1.5,0c0.3-1,0.4-2,0.5-3c0.1-1,0-1.7-0.2-2.2c-0.2-0.4-0.6-0.6-1.2-0.8
									c-0.5-0.2-1.1-0.2-1.7,0c-0.7,0.3-1.2,0.8-1.4,1.5c-0.2,0.7-0.4,1.8-0.6,3.1c-0.1,1.1-0.2,2-0.4,2.8c-0.1,0.7-0.4,1.4-0.9,2
									s-1.1,1.1-1.9,1.5c-1.4,0.6-2.7,0.6-3.8,0c-1.1-0.6-2.1-1.8-2.8-3.6C1257.8,1292.9,1257.5,1291.2,1257.7,1289.5z"
						/>
						<path
							d="M1264.4,1303.9c0.7-1.4,2-2.5,3.7-3.3c1.3-0.6,2.6-0.8,3.8-0.8c1.2,0.1,2.3,0.4,3.3,1.1c1,0.7,1.7,1.6,2.2,2.7
									c0.4,1,0.6,2,0.4,3c-0.2,1-0.6,1.9-1.3,2.7c-0.7,0.8-1.7,1.5-2.9,2c-1.3,0.6-2.6,0.8-3.8,0.7c-1.2-0.1-2.3-0.5-3.3-1.2
									c-1-0.7-1.7-1.6-2.2-2.8C1263.7,1306.8,1263.7,1305.3,1264.4,1303.9z M1268,1310.2c1,0.3,2.2,0.1,3.6-0.5c1.2-0.5,2.2-1.3,2.8-2.2
									c0.7-0.9,0.9-1.9,0.9-3c-0.5-1.1-1.2-1.7-2.3-2c-1.1-0.3-2.2-0.2-3.6,0.4c-1.5,0.7-2.6,1.5-3.3,2.5c-0.6,1-0.8,1.9-0.4,2.8
									C1266.2,1309.3,1267,1309.9,1268,1310.2z"
						/>
						<path
							d="M1272.9,1316.7l1.9-0.8l2-0.8c1.9-0.9,3.2-1.7,3.9-2.4c0.8,0.7,1.4,1.6,1.9,2.7c-2.4,1-5.2,2.2-8.3,3.6
									c-0.8,0.4-1.3,0.8-1.5,1.2c-0.2,0.5-0.2,1.1,0.1,1.7c0.4,0.9,1,1.6,1.8,2.1c1.3-0.6,2.5-1.1,3.5-1.5l1.6-0.7
									c1.9-0.9,3.2-1.7,3.9-2.4c0.8,0.7,1.4,1.6,2,2.8c-1.4,0.5-3,1.2-4.9,2c-1.9,0.8-3.1,1.3-3.8,1.6c-0.7,0.3-1.2,0.6-1.6,1
									c-0.3,0.3-0.7,0.8-0.9,1.4c-0.9-0.4-1.6-1-2-1.7c0.2-0.7,0.6-1.3,1.3-1.9c-0.8-0.2-1.5-0.6-2.1-1.1s-1.1-1.1-1.4-1.8
									C1269.3,1319.6,1270.2,1318,1272.9,1316.7z"
						/>
						<path
							d="M1276.4,1333.5c0.2-0.4,0.8-0.8,1.6-1.2c0.6-0.3,1.6-0.7,3-1.3c1.5-0.7,2.7-1.2,3.7-1.7c0.5-0.2,0.9-0.4,1.3-0.7
									c-0.3-0.7-0.5-1.2-0.5-1.5c0-0.3,0.1-0.5,0.4-0.6c1.3,0.8,2.8,1.5,4.5,2l0.2,0.7c-0.6,0.4-1.3,0.7-2,1.1c0.6,1.2,1.1,2,1.5,2.5
									c-0.4,0.2-0.8,0.3-1.1,0.4c-0.3,0.1-0.7,0.1-1.1,0.1c0-0.6-0.3-1.4-0.7-2.4c-0.9,0.4-1.8,0.8-2.8,1.2c-2.4,1.1-4.1,2.1-5.3,3
									c0.4,0.8,0.9,1.4,1.3,1.9l-0.6,0.5c-0.7-0.1-1.3-0.3-1.9-0.7c-0.6-0.4-1-0.9-1.3-1.5C1276.2,1334.4,1276.1,1333.9,1276.4,1333.5z"
						/>
						<path
							d="M1279.8,1341.5c2.5-1.1,5-2.2,7.5-3.4c3.4-1.6,5.9-3,7.8-4.1c0.8,0.7,1.5,1.6,2,2.7c-1,0.3-2.9,1.1-5.8,2.3
									c0.8,0.2,1.6,0.6,2.2,1.1c0.6,0.5,1.1,1.1,1.5,1.7c1,2.1,0.2,3.8-2.5,5.1l-1.9,0.8l-2,0.9c-2,1-3.4,1.9-4.1,2.6
									c-0.4-0.4-0.6-0.9-0.9-1.3c-0.2-0.4-0.4-1-0.6-1.7c2.8-1.2,5.4-2.4,7.7-3.5c0.8-0.4,1.3-0.8,1.5-1.2s0.1-1-0.2-1.7
									c-0.5-1-1.1-1.7-2-2.2c-0.6,0.2-1.4,0.6-2.4,1.1c-3.6,1.7-5.7,3-6.4,3.8c-0.3-0.4-0.6-0.9-0.9-1.4
									C1280.2,1342.7,1280,1342.2,1279.8,1341.5z"
						/>
						<path
							d="M1221.5,1258.2c1-0.2,2.5-0.4,4.4-0.8c1.9-0.4,3.6-0.7,5.1-1.1c3.4-0.8,5.5-1.5,6.2-2c0.4,0.5,0.8,1.3,1.2,2.2
									c0.4,0.9,0.7,1.9,0.9,2.9c0.5,1.9,0.3,3.5-0.4,4.8c-0.7,1.3-1.9,2.1-3.7,2.5c-1.1,0.2-2.1,0.2-3-0.1c-0.9-0.3-1.8-0.9-2.5-1.7
									c-2.8,2.2-4.5,3.9-5.1,5c-0.4-0.5-0.7-1-0.9-1.5s-0.4-1.1-0.6-1.8c1-0.7,2.8-2,5.2-4.1c0-0.1-0.1-0.2-0.2-0.5
									c-0.1-0.4-0.2-0.8-0.2-1.3c-0.1-0.5-0.1-0.8-0.1-1.1c-3,0.7-4.9,1.3-5.8,1.8c-0.2-0.5-0.4-1-0.6-1.6
									C1221.6,1259.4,1221.5,1258.9,1221.5,1258.2z M1232,1263.9c0.8,0.3,1.5,0.4,2.4,0.2c2.5-0.6,3.4-2.2,2.8-4.8
									c-0.1-0.6-0.3-1.1-0.6-1.5c-1.5,0.3-3.7,0.7-6.4,1.3c-0.2,1.5-0.2,2.6,0,3.4C1230.7,1263.1,1231.3,1263.6,1232,1263.9z"
						/>
						<path
							d="M1227.2,1274.5c0.8-1.3,2.1-2.3,4-3c1.3-0.5,2.6-0.6,3.8-0.5c1.2,0.1,2.3,0.6,3.2,1.3s1.6,1.7,2,2.9
									c0.4,1.1,0.4,2.1,0.2,3.1c-0.2,1-0.7,1.9-1.5,2.6c-0.8,0.8-1.8,1.4-3,1.8c-1.3,0.5-2.6,0.6-3.9,0.5c-1.2-0.2-2.3-0.6-3.2-1.4
									c-0.9-0.8-1.6-1.7-2-3C1226.3,1277.3,1226.4,1275.9,1227.2,1274.5z M1230.3,1281c1,0.3,2.2,0.2,3.6-0.3c1.3-0.4,2.2-1.1,3-2
									c0.7-0.9,1.1-1.8,1.1-2.9c-0.4-1.1-1.1-1.8-2.1-2.2c-1-0.4-2.2-0.3-3.6,0.2c-1.6,0.6-2.7,1.3-3.4,2.2c-0.7,0.9-0.9,1.9-0.6,2.8
									C1228.7,1280,1229.3,1280.7,1230.3,1281z"
						/>
						<path
							d="M1231.7,1289.2c0.3-0.7,0.9-1.2,1.7-1.6c2.3-1.1,4.7,0.4,7,4.5c1.3-0.6,2.1-1.2,2.4-1.8c0.3-0.6,0.3-1.3-0.1-2.2
									c-0.3-0.6-0.6-1.1-1.1-1.6c-0.5-0.5-1-0.9-1.5-1.2l0.5-0.5c1.1,0.1,2.1,0.4,3,1c0.9,0.6,1.6,1.3,2,2.2c0.6,1.2,0.6,2.2,0.3,3.2
									c-0.4,0.9-1.2,1.7-2.5,2.3c-0.7,0.3-1.5,0.7-2.4,1c-1,0.4-1.7,0.7-2.1,0.9c-0.7,0.3-1.3,0.7-1.6,1c-0.3,0.3-0.7,0.8-0.9,1.5
									c-0.9-0.4-1.6-0.9-2.1-1.7c0.1-0.7,0.5-1.2,1.1-1.8c-0.8-0.4-1.5-0.9-2.1-1.5c-0.6-0.6-1.1-1.1-1.4-1.7
									C1231.4,1290.5,1231.4,1289.9,1231.7,1289.2z M1234.7,1292.9c0.5,0.4,1,0.7,1.5,1l0.1-0.1c0.6-0.3,1.2-0.5,1.8-0.8l1.3-0.5
									c-0.7-1.2-1.4-2-2.2-2.4c-0.8-0.4-1.6-0.5-2.4-0.1c-0.3,0.2-0.6,0.4-0.9,0.7s-0.4,0.6-0.4,1
									C1233.9,1292,1234.2,1292.5,1234.7,1292.9z"
						/>
						<path
							d="M1239.1,1302.3c0.6-1.3,1.8-2.5,3.7-3.7c2-1.2,3.7-1.7,5.2-1.6c1.4,0.1,2.6,0.9,3.5,2.3c0.7,1.1,0.9,2.4,0.8,3.8
									c1.8-1.2,3-2.2,3.8-3c0.5,0.4,0.9,0.8,1.3,1.1c0.3,0.4,0.7,0.8,1,1.4c-2.4,1.2-6.4,3.4-11.8,6.7c-0.6,0.4-1.1,0.8-1.4,1.1
									c-0.3,0.4-0.5,0.9-0.8,1.5c-0.9-0.2-1.6-0.7-2.2-1.5c0.1-0.7,0.4-1.3,1-1.9c-0.8-0.2-1.5-0.4-2.1-0.9s-1.1-0.9-1.4-1.5
									C1238.7,1304.9,1238.6,1303.6,1239.1,1302.3z M1242.4,1307.1c0.5,0.3,1,0.6,1.5,0.8c0.6-0.3,1.5-0.9,2.9-1.7
									c1.5-0.9,2.7-1.6,3.6-2.1c0.1-0.9-0.2-1.9-0.7-2.8c-0.5-0.9-1.3-1.4-2.3-1.4c-1-0.1-2.1,0.3-3.4,1.1c-1.3,0.8-2.1,1.6-2.6,2.5
									c-0.5,0.9-0.6,1.8-0.2,2.7C1241.6,1306.5,1241.9,1306.8,1242.4,1307.1z"
						/>
					</g>
					<g className="level_zero location" id="isengard">
						<rect x="1777.764" y="1276.302" width="115.433" height="28.075" transform="matrix(0.987731, -0.156163, 0.144266, 0.989612, -654.540649, 281.062256)"></rect>
						<path
							d="M1294.9,1287.8c-0.1-1.1-0.2-2.5-0.4-4.4c-0.2-1.9-0.4-3.6-0.7-5.3c-0.6-3.9-1.1-6.2-1.6-7.1c0.6-0.2,1.1-0.4,1.6-0.4
									c0.5-0.1,1.1-0.1,1.8-0.1c0.1,1.1,0.2,2.5,0.4,4.4c0.2,1.8,0.4,3.6,0.7,5.3c0.6,3.9,1.1,6.3,1.5,7.2c-0.5,0.2-1.1,0.3-1.6,0.4
									C1296.1,1287.8,1295.6,1287.8,1294.9,1287.8z"
						/>
						<path
							d="M1302,1285.9c-0.1-0.4-0.1-0.8,0-1.2c0-0.4,0.1-0.8,0.2-1.2c0.5,0.5,1,0.8,1.7,1s1.4,0.3,2.1,0.2c1.9-0.3,2.7-1,2.6-2.1
									c-0.1-0.5-0.3-0.8-0.8-1.1c-0.5-0.3-1.2-0.5-2.1-0.8c-0.9-0.3-1.7-0.6-2.3-0.8c-0.6-0.3-1.1-0.6-1.6-1.2c-0.5-0.5-0.8-1.2-0.9-2
									c-0.1-1,0.1-1.9,0.9-2.6c0.7-0.7,1.8-1.2,3.1-1.4c1.8-0.3,3.3-0.2,4.6,0.1c0,0.3,0,0.8-0.1,1.4c-0.1,0.6-0.2,1.1-0.4,1.6
									c-0.8-0.6-1.6-1.1-2.3-1.4c-0.7-0.3-1.3-0.4-1.8-0.4c-0.5,0.2-0.8,0.4-1.1,0.8c-0.3,0.3-0.4,0.7-0.3,1c0.1,0.6,0.4,1,0.9,1.3
									s1.3,0.6,2.3,1c0.9,0.3,1.6,0.5,2.1,0.8c0.6,0.3,1.1,0.6,1.5,1.1c0.4,0.5,0.7,1.1,0.8,1.9c0.2,1.2-0.1,2.2-0.9,3s-2,1.3-3.6,1.5
									C1304.8,1286.7,1303.2,1286.5,1302,1285.9z"
						/>
						<path
							d="M1315.9,1283.4c-1-1-1.6-2.5-1.9-4.6c-0.2-1.4-0.1-2.7,0.2-3.9c0.4-1.2,1-2.1,1.8-2.9c0.8-0.7,1.8-1.2,3-1.4
									c1.6-0.2,2.9,0.2,3.8,1.2c1,1,1.6,2.6,1.9,4.8c-1,0.4-2.2,0.8-3.7,1.3c-1.5,0.4-2.9,0.7-4.3,1c0.3,1.3,0.8,2.2,1.4,2.7
									c0.6,0.5,1.5,0.7,2.5,0.5c0.8-0.1,1.6-0.3,2.2-0.6c0.7-0.3,1.3-0.7,1.7-1.1l0.3,0.6c-0.5,0.9-1.2,1.7-2.2,2.4
									c-0.9,0.7-1.9,1.1-3,1.2C1318.2,1284.8,1316.9,1284.4,1315.9,1283.4z M1319.4,1276.9c0.9-0.4,1.6-0.8,2.1-1.1
									c-0.2-1.1-0.6-2-1.3-2.6c-0.7-0.6-1.5-0.9-2.4-0.8c-0.6,0.5-1,1.2-1.2,2.2c-0.2,1-0.3,2.1-0.1,3.3
									C1317.5,1277.6,1318.5,1277.3,1319.4,1276.9z"
						/>
						<path
							d="M1330.9,1282.6c-0.2-2.1-0.4-4.4-0.7-6.8c-0.2-1.2-0.3-2.1-0.5-2.7c-0.2-0.6-0.3-1.1-0.5-1.4c-0.2-0.3-0.5-0.7-0.9-1
									c0.3-0.4,0.6-0.8,0.9-1c0.3-0.2,0.6-0.4,0.9-0.4c1-0.1,1.7,0.4,2.1,1.7c0.4-0.7,1-1.3,1.6-1.8c0.7-0.5,1.3-0.7,2.1-0.8
									c2.3-0.3,3.7,1,4.1,3.9l0.2,2c0,0.8,0.1,1.5,0.2,2.1c0.3,2.2,0.7,3.8,1.3,4.7c-0.5,0.2-1,0.3-1.5,0.4c-0.5,0.1-1.1,0.1-1.8,0.1
									c-0.2-2.6-0.5-5.4-0.9-8.4c-0.1-0.8-0.4-1.4-0.7-1.8c-0.4-0.3-0.9-0.4-1.6-0.3c-1.1,0.1-1.9,0.5-2.6,1.1l0.2,1.4
									c0.1,1.1,0.2,2.4,0.4,3.9c0.3,2.2,0.7,3.8,1.3,4.7c-0.6,0.2-1.1,0.3-1.7,0.4C1332.2,1282.6,1331.7,1282.7,1330.9,1282.6z"
						/>
						<path
							d="M1346.3,1285c-0.1-0.8-0.1-1.5,0.1-2.3c0.2-0.8,0.5-1.5,0.9-2.1c-0.8-0.2-1.3-0.7-1.4-1.6c-0.1-0.6,0-1.2,0.4-1.7
									c0.3-0.5,0.8-1,1.5-1.4c-0.7-0.3-1.3-0.7-1.8-1.4c-0.5-0.6-0.8-1.4-0.9-2.3c-0.2-1.5,0.1-2.8,1-3.8c0.8-1.1,2-1.7,3.5-1.9
									c1-0.1,1.9,0,2.7,0.3c0.4-0.2,0.8-0.4,1.3-0.8c0.5-0.4,0.9-0.9,1.3-1.4c0.3,0.4,0.5,0.8,0.7,1.2c0.2,0.5,0.3,0.9,0.4,1.2
									c-0.6,0.4-1.3,0.8-2.3,1c0.5,0.7,0.8,1.5,0.9,2.4c0.1,1,0,1.9-0.3,2.7c-0.4,0.8-0.9,1.5-1.7,2c-0.8,0.5-1.7,0.9-2.7,1
									c-0.8,0.1-1.5,0.3-2,0.7c-0.5,0.4-0.6,0.8-0.6,1.3l5.6-0.4c1.1,0,1.9,0.2,2.6,0.6c0.6,0.4,1,1.1,1.1,2c0.1,0.9,0,1.7-0.5,2.4
									c-0.4,0.8-1.1,1.4-2,1.9c-0.9,0.5-1.9,0.9-3.1,1C1349.3,1286,1347.6,1285.7,1346.3,1285z M1352.2,1273.8c0.4-0.5,0.5-1.2,0.4-2.1
									c-0.1-0.8-0.4-1.4-0.9-1.9c-0.5-0.5-1.1-0.8-1.9-0.9c-0.8,0.1-1.4,0.5-1.9,1.1c-0.4,0.6-0.6,1.4-0.5,2.3c0.1,0.8,0.4,1.5,1,1.9
									c0.5,0.4,1.2,0.6,1.9,0.5C1351.2,1274.6,1351.8,1274.3,1352.2,1273.8z M1354.2,1282.7c0.6-0.4,0.9-0.9,0.8-1.5
									c-0.1-0.4-0.2-0.7-0.5-0.9c-0.3-0.2-0.8-0.2-1.5-0.1l-4.5,0.3c-0.2,0.7-0.4,1.3-0.4,1.8c0.9,1,2.1,1.4,3.6,1.2
									C1352.8,1283.4,1353.6,1283.1,1354.2,1282.7z"
						/>
						<path
							d="M1360.6,1278.1c-0.6-0.5-0.9-1.2-1-2.1c-0.3-2.6,1.8-4.3,6.4-5.3c-0.2-1.4-0.5-2.4-0.9-2.9c-0.4-0.5-1.2-0.7-2.2-0.6
									c-0.6,0.1-1.2,0.3-1.8,0.6c-0.6,0.3-1.1,0.7-1.6,1.1l-0.3-0.6c0.4-1,1-1.9,1.9-2.6c0.8-0.7,1.7-1.1,2.7-1.2
									c1.3-0.2,2.3,0.1,3.1,0.7c0.8,0.6,1.3,1.7,1.5,3c0.1,0.8,0.2,1.6,0.2,2.6c0.1,1,0.1,1.8,0.2,2.3c0.1,0.8,0.3,1.4,0.5,1.9
									c0.2,0.4,0.6,0.9,1.1,1.3c-0.6,0.8-1.4,1.3-2.2,1.5c-0.6-0.3-1-0.8-1.4-1.6c-0.6,0.6-1.3,1.1-2.1,1.6s-1.4,0.7-2.1,0.8
									C1361.8,1278.8,1361.2,1278.6,1360.6,1278.1z M1365,1276.4c0.5-0.3,1-0.7,1.4-1.1l0-0.2c-0.1-0.7-0.1-1.3-0.2-2l-0.1-1.4
									c-1.3,0.3-2.3,0.7-3,1.3c-0.7,0.6-0.9,1.4-0.8,2.2c0,0.4,0.2,0.7,0.4,1c0.2,0.3,0.5,0.6,0.8,0.7
									C1364,1276.9,1364.5,1276.7,1365,1276.4z"
						/>
						<path
							d="M1375.5,1276.7c-0.1-2.1-0.4-4.4-0.7-6.8c-0.2-1.2-0.3-2.1-0.4-2.7c-0.1-0.6-0.3-1.1-0.5-1.4c-0.2-0.3-0.5-0.7-0.9-1
									c0.3-0.4,0.6-0.8,0.9-1c0.4-0.2,0.7-0.4,1-0.4c1.1-0.1,1.8,0.6,2.1,2.1c0.4-0.7,0.9-1.4,1.4-1.9c0.5-0.5,1.2-0.9,1.9-1.2
									c0.2,0.3,0.4,0.8,0.6,1.4c0.2,0.6,0.3,1.2,0.4,1.7c-0.2,0-0.6,0-1,0.1c-0.6,0.1-1.2,0.2-1.8,0.5c-0.6,0.2-1,0.5-1.3,0.8l0.1,1
									c0.1,1.4,0.3,2.7,0.4,3.7c0.3,2.2,0.7,3.8,1.2,4.7c-0.6,0.2-1.1,0.3-1.6,0.4C1376.7,1276.7,1376.1,1276.7,1375.5,1276.7z"
						/>
						<path
							d="M1386.1,1273.9c-1-1-1.6-2.7-1.9-4.9c-0.3-2.3-0.1-4.1,0.6-5.4c0.7-1.3,1.9-2,3.5-2.2c1.3-0.2,2.6,0.1,3.7,0.8
									c-0.4-2.1-0.8-3.7-1.2-4.7c0.5-0.3,1.1-0.5,1.6-0.7c0.5-0.2,1-0.3,1.7-0.4c0.1,2.7,0.5,7.2,1.3,13.5c0.1,0.7,0.2,1.3,0.5,1.7
									c0.2,0.4,0.6,0.9,1.1,1.3c-0.6,0.7-1.3,1.2-2.2,1.4c-0.6-0.4-1-0.9-1.4-1.6c-0.5,0.6-1,1.2-1.6,1.6c-0.6,0.4-1.3,0.6-1.9,0.7
									C1388.3,1275.4,1387,1275,1386.1,1273.9z M1391.8,1272.9c0.5-0.3,0.9-0.7,1.3-1.1c-0.1-0.7-0.2-1.8-0.4-3.4
									c-0.2-1.8-0.3-3.1-0.5-4.1c-0.8-0.4-1.8-0.6-2.8-0.5c-1,0.1-1.8,0.6-2.2,1.5c-0.5,0.9-0.6,2-0.4,3.5c0.2,1.5,0.6,2.6,1.2,3.4
									c0.6,0.8,1.4,1.2,2.4,1.3C1390.9,1273.4,1391.4,1273.2,1391.8,1272.9z"
						/>
					</g>
					<g className="level_two fade location " id="lefnui">
						<rect x="1537.727" y="1524.347" width="99.847" height="33.534" transform="matrix(0.299055, -0.954236, 0.953982, 0.299865, -840.705566, 2765.837158)"></rect>
						<path
							d="M1095.2,1754c-0.9-0.2-2.3-0.6-4.2-1c-3.9-0.9-6.2-1.2-7.2-1.1c0-0.6,0.1-1.1,0.2-1.6c0.1-0.5,0.3-1,0.6-1.6
									c1.1,0.4,2.6,0.8,4.4,1.2s3.5,0.9,5,1.2c2.2,0.5,3.7,0.8,4.6,0.9c0.4-0.8,0.7-1.8,1-3c0.2-0.7,0.2-1.4,0.2-2.1
									c0-0.7-0.1-1.3-0.2-1.9c0.6,0.1,1.3,0.4,1.9,0.6c0.6,0.3,1,0.5,1.2,0.7c-0.4,1.2-0.8,2.7-1.2,4.4c-0.5,2.1-0.7,3.6-0.8,4.7
									C1097.9,1754.8,1096.1,1754.3,1095.2,1754z"
						/>
						<path
							d="M1102.5,1740.8c-1.3,0.5-3,0.5-5,0c-1.4-0.4-2.5-0.9-3.4-1.7s-1.6-1.7-1.9-2.8c-0.4-1.1-0.4-2.2-0.1-3.3
									c0.4-1.5,1.3-2.6,2.6-3.1s3-0.5,5.1,0.1c0,1.1-0.1,2.4-0.3,3.9c-0.2,1.5-0.5,2.9-0.8,4.4c1.3,0.2,2.3,0.2,3-0.2
									c0.7-0.4,1.2-1.1,1.5-2.1c0.2-0.8,0.3-1.6,0.3-2.3c0-0.8-0.1-1.4-0.3-2l0.6,0c0.7,0.8,1.1,1.8,1.4,2.9c0.2,1.1,0.2,2.2-0.1,3.2
									C1104.7,1739.3,1103.8,1740.3,1102.5,1740.8z M1097.9,1735.1c0-1-0.1-1.8-0.2-2.4c-1.1-0.3-2.1-0.2-2.9,0.1
									c-0.9,0.4-1.4,1-1.6,1.9c0.2,0.7,0.7,1.3,1.5,1.9c0.8,0.6,1.8,1.1,3,1.4C1097.8,1737.1,1097.9,1736.1,1097.9,1735.1z"
						/>
						<path
							d="M1112.3,1728.6c-0.4-0.6-0.8-1-1.2-1.4s-1-0.7-1.7-1c-0.8-0.3-1.8-0.7-3.3-1.2c-1.6-0.5-4.4-1.3-8.5-2.4
									c-0.2,0.6-0.4,1.1-0.6,1.2c-0.2,0.2-0.4,0.2-0.7,0.1c-0.2-0.9-0.4-1.5-0.5-2c-1.6-0.8-2.8-1.7-3.6-2.9s-0.9-2.5-0.5-3.7
									c0.3-0.9,0.7-1.8,1.2-2.6c0.3,0.1,0.7,0.3,1.2,0.7c0.5,0.3,0.9,0.7,1.3,1.1c-0.7,0.4-1.4,0.8-1.9,1.3c-0.6,0.5-0.9,1-1,1.3
									c0.1,0.4,0.5,0.8,1.1,1.1c0.6,0.3,1.6,0.8,3.1,1.2c0.4-1.4,0.6-2.5,0.7-3c0.4,0.1,0.8,0.3,1.1,0.5c0.3,0.2,0.6,0.4,0.9,0.7
									c-0.4,0.6-0.8,1.4-1.2,2.4l2.2,0.7c1.2,0.4,2.7,0.8,4.3,1.2c0.9,0.2,1.6,0.4,2.3,0.6c1,0.3,1.9,0.7,2.5,1.1c0.6,0.4,1.2,1,1.7,1.7
									c0.5,0.7,1,1.7,1.5,3L1112.3,1728.6z"
						/>
						<path
							d="M1111.6,1715.2c-2-0.9-4.1-1.7-6.4-2.5c-1.1-0.4-2-0.7-2.6-0.8c-0.6-0.1-1.1-0.2-1.5-0.2c-0.4,0-0.8,0.1-1.3,0.3
									c-0.3-0.4-0.4-0.9-0.5-1.3c-0.1-0.4,0-0.7,0.1-1c0.4-1,1.2-1.3,2.4-1.1c-0.4-0.7-0.7-1.5-0.8-2.2c-0.1-0.8,0-1.5,0.2-2.2
									c0.8-2.2,2.6-2.8,5.4-1.8l1.9,0.7c0.7,0.3,1.4,0.6,2,0.8c2.1,0.7,3.7,1.1,4.8,1c-0.1,0.6-0.2,1.1-0.4,1.6c-0.2,0.5-0.4,1-0.8,1.6
									c-2.4-1-5-2-7.9-3c-0.8-0.3-1.4-0.3-1.9-0.2c-0.5,0.2-0.8,0.6-1.1,1.3c-0.4,1-0.4,2-0.2,2.8l1.3,0.5c1,0.4,2.2,0.9,3.6,1.4
									c2.1,0.8,3.7,1.1,4.7,1.1c-0.1,0.6-0.2,1.2-0.4,1.7C1112.2,1714.1,1111.9,1714.6,1111.6,1715.2z"
						/>
						<path
							d="M1113.7,1697.8l-1.9-0.8l-2-0.9c-1.9-0.8-3.4-1.2-4.4-1.2c0-1.1,0.2-2.2,0.7-3.3c2.4,1.1,5.1,2.3,8.3,3.6
									c0.8,0.3,1.4,0.4,1.9,0.3c0.5-0.2,0.9-0.6,1.2-1.3c0.4-0.9,0.5-1.8,0.3-2.7c-1.3-0.6-2.5-1.1-3.5-1.6l-1.6-0.7
									c-1.9-0.8-3.4-1.2-4.4-1.2c0-1.1,0.2-2.2,0.7-3.3c1.3,0.6,2.9,1.4,4.8,2.2c1.8,0.9,3.1,1.4,3.7,1.7c0.7,0.3,1.3,0.5,1.8,0.5
									c0.5,0,1-0.1,1.7-0.3c0.3,0.9,0.4,1.8,0.2,2.7c-0.6,0.4-1.4,0.5-2.2,0.3c0.3,0.7,0.5,1.5,0.6,2.3c0,0.8-0.1,1.6-0.4,2.3
									C1118.2,1698.5,1116.4,1698.9,1113.7,1697.8z"
						/>
						<path
							d="M1107.9,1677.4c-0.5-0.9-0.8-1.7-1.1-2.5c0.9-0.2,1.8-0.5,2.6-0.9c0.4,0.8,0.8,1.7,1,2.5
									C1109.6,1676.7,1108.7,1677,1107.9,1677.4z M1123.9,1683.7c-0.5,0.1-1.1-0.1-2-0.5c-0.7-0.3-1.7-0.8-3-1.5c-1.2-0.6-2.1-1-2.7-1.3
									c-0.8-0.4-1.6-0.7-2.3-0.9c-0.7-0.2-1.3-0.3-1.6-0.3c0-0.6,0.1-1.2,0.2-1.7s0.3-1,0.6-1.6c0.5,0.3,1.4,0.8,2.5,1.4
									c1.2,0.6,2.5,1.2,3.9,1.9c1.1,0.5,1.9,0.8,2.5,1c0.6,0.2,1.1,0.3,1.5,0.3c0.4,0,0.8,0,1.3-0.2c0.2,0.5,0.3,0.9,0.4,1.3
									c0,0.4,0,0.8-0.2,1C1124.7,1683.3,1124.3,1683.6,1123.9,1683.7z"
						/>
					</g>
					<g className="regions level_two location" id="druwaith_laur">
						<rect x="2854.328" y="1524.346" width="185.337" height="33.534" transform="matrix(0.384851, -0.922979, 0.922652, 0.385635, -1584.492798, 3749.536865)"></rect>
						<path
							d="M949.7,1705.9c-0.3,0.9-0.6,1.6-0.8,2.2c-1-0.3-2.4-0.7-4.1-1.1c-1.7-0.4-3.4-0.8-5-1.1c-3.5-0.7-5.7-1-6.6-0.8
									c-0.1-0.7-0.2-1.6-0.1-2.6c0.1-1.1,0.2-2.2,0.4-3.2c0.5-2.6,1.6-4.4,3.4-5.5c1.7-1.1,3.9-1.4,6.6-0.8c1.7,0.3,3.1,1,4.2,2
									c1.1,1,2,2.2,2.4,3.7s0.5,3,0.2,4.7C950.2,1704.2,950,1705.1,949.7,1705.9z M948.4,1699.2c-0.5-0.8-1.2-1.5-2-2.1
									c-0.9-0.6-1.8-1-2.8-1.2c-4.6-0.9-7.3,0.6-8.1,4.5c-0.1,0.6-0.2,1.3-0.1,1.9c2.3,0.6,4.7,1.2,7.2,1.7c2,0.4,3.8,0.7,5.1,0.9
									c0.7-1.4,1.1-2.5,1.3-3.2C949.1,1700.8,948.9,1700,948.4,1699.2z"
						/>
						<path
							d="M953.2,1690.1c-2-0.7-4.2-1.4-6.6-2c-1.1-0.3-2-0.5-2.6-0.6c-0.6-0.1-1.1-0.1-1.5-0.1c-0.4,0-0.8,0.2-1.3,0.4
									c-0.3-0.4-0.5-0.8-0.6-1.2s-0.1-0.8,0-1.1c0.3-1.1,1.2-1.5,2.8-1.1c-0.5-0.7-0.9-1.4-1.2-2c-0.3-0.7-0.4-1.4-0.4-2.2
									c0.4,0,0.9,0,1.5,0c0.6,0.1,1.2,0.2,1.8,0.3c-0.1,0.2-0.2,0.5-0.3,1c-0.2,0.6-0.2,1.2-0.2,1.8c0,0.6,0.1,1.1,0.2,1.5l1,0.3
									c1.4,0.4,2.6,0.8,3.6,1c2.2,0.6,3.8,0.8,4.8,0.7c0,0.6-0.1,1.2-0.2,1.6C953.7,1688.9,953.5,1689.5,953.2,1690.1z"
						/>
						<path
							d="M941.6,1672c-0.1-0.8-0.3-1.6-0.7-2.3s-0.8-1.3-1.3-1.6c0.2-0.7,0.5-1.2,0.9-1.6c0.4-0.4,0.8-0.6,1.3-0.6
									c0.8,1.6,1,3.6,0.6,6.2L941.6,1672z M952.1,1678.8l-1.9-0.7l-2-0.8c-2-0.7-3.5-1-4.4-1c-0.1-1.1,0.1-2.2,0.5-3.3
									c2.4,1,5.2,2,8.4,3.1c0.8,0.3,1.5,0.3,1.9,0.1c0.5-0.2,0.9-0.6,1.1-1.4c0.3-0.9,0.4-1.8,0.2-2.8c-1.4-0.5-2.6-0.9-3.6-1.3
									l-1.7-0.6c-2-0.7-3.5-1-4.4-1c-0.1-1.1,0.1-2.2,0.5-3.3c1.4,0.6,3,1.2,4.9,2c1.9,0.7,3.2,1.2,3.8,1.5c0.7,0.3,1.3,0.4,1.8,0.4
									c0.5,0,1-0.1,1.7-0.4c0.4,0.9,0.5,1.8,0.3,2.6c-0.6,0.4-1.4,0.6-2.2,0.5c0.4,0.7,0.6,1.5,0.7,2.3c0.1,0.8,0,1.6-0.3,2.3
									C956.7,1679.2,954.9,1679.8,952.1,1678.8z"
						/>
						<path
							d="M959.7,1663.6l-4-0.4c-1-0.1-1.8-0.2-2.5-0.3c-0.9-0.1-1.8-0.1-2.6-0.1c-0.8,0-1.3,0.1-1.7,0.3c0-0.6,0-1.1,0.1-1.6
									c0.1-0.5,0.3-1.1,0.5-1.8c0.9,0.2,2.9,0.5,6,0.8c3,0.3,4.9,0.5,5.5,0.5c-3.8-3.1-6.3-5-7.2-5.7c-0.9,0-1.6,0.1-2,0.3
									c-0.1-1.1,0.1-2.2,0.6-3.4c0.9,0.2,3,0.5,6.2,0.9c3.2,0.4,5,0.5,5.4,0.5c-2.8-2.3-4.7-3.9-5.9-4.7c-1.2-0.9-2-1.4-2.5-1.6
									c-0.2-0.1-0.4-0.1-0.7-0.2c0-0.8,0.3-1.6,0.7-2.6c1,1,2.5,2.3,4.2,3.8c1.8,1.5,3.8,3.1,5.9,4.7l0.3,1.9c-0.2,0.6-0.6,0.9-1.2,1.1
									c-0.6,0.2-1.3,0.3-2.2,0.2c-2.9-0.3-4.9-0.5-6.1-0.7c1.5,1.3,3.6,2.9,6.2,4.9l0.3,1.9C962.6,1663.3,961.5,1663.8,959.7,1663.6z"
						/>
						<path
							d="M968.5,1646.2c-0.7,0.2-1.5,0.2-2.3-0.1c-2.4-0.9-2.9-3.7-1.5-8.2c-1.3-0.5-2.3-0.7-3-0.6c-0.7,0.1-1.2,0.7-1.5,1.6
									c-0.2,0.6-0.4,1.2-0.4,1.9c0,0.7,0,1.3,0.2,1.9l-0.7,0c-0.7-0.8-1.1-1.8-1.3-2.9s-0.1-2,0.2-2.9c0.5-1.2,1.2-2,2.1-2.4
									c0.9-0.4,2.1-0.3,3.4,0.2c0.7,0.3,1.5,0.6,2.4,1.1c0.9,0.5,1.6,0.8,2.1,0.9c0.8,0.3,1.4,0.5,1.9,0.5c0.5,0,1.1-0.1,1.7-0.3
									c0.4,0.9,0.5,1.8,0.3,2.7c-0.6,0.4-1.2,0.5-2.1,0.4c0.2,0.9,0.4,1.7,0.4,2.6c0,0.8-0.1,1.6-0.3,2.2
									C969.7,1645.5,969.2,1645.9,968.5,1646.2z M969.1,1641.5c0-0.6-0.1-1.2-0.3-1.7l-0.1-0.1c-0.6-0.2-1.2-0.5-1.8-0.8l-1.3-0.6
									c-0.4,1.3-0.5,2.4-0.3,3.3s0.7,1.5,1.6,1.8c0.3,0.1,0.7,0.2,1.1,0.1c0.4,0,0.7-0.2,1-0.4C969.1,1642.6,969.1,1642.1,969.1,1641.5z
									"
						/>
						<path
							d="M957.7,1627.2c-0.5-0.8-0.9-1.7-1.2-2.4c0.9-0.3,1.7-0.6,2.5-1c0.5,0.8,0.9,1.6,1.2,2.5
									C959.4,1626.5,958.6,1626.8,957.7,1627.2z M974,1632.6c-0.4,0.1-1.1-0.1-2-0.4c-0.7-0.3-1.8-0.7-3.1-1.3c-1.3-0.5-2.2-0.9-2.8-1.2
									c-0.8-0.3-1.6-0.6-2.3-0.7c-0.7-0.2-1.3-0.2-1.6-0.2c0-0.6,0-1.2,0.1-1.7c0.1-0.5,0.3-1.1,0.5-1.7c0.5,0.3,1.4,0.8,2.6,1.3
									s2.5,1.1,4,1.7c1.1,0.4,2,0.7,2.6,0.9s1.1,0.2,1.5,0.2c0.4,0,0.8-0.1,1.3-0.3c0.2,0.4,0.4,0.9,0.4,1.3s0,0.8-0.1,1.1
									C974.8,1632.2,974.5,1632.5,974,1632.6z"
						/>
						<path
							d="M977.3,1624.3c-0.5,0.1-1.1,0-2-0.3c-0.6-0.2-1.6-0.7-3-1.2c-1.5-0.6-2.8-1.1-3.8-1.5c-0.5-0.2-0.9-0.4-1.4-0.5
									c-0.3,0.7-0.5,1.2-0.7,1.4s-0.5,0.3-0.7,0.1c-0.3-1.5-0.8-3-1.7-4.6l0.4-0.6c0.7,0.2,1.4,0.4,2.2,0.7c0.5-1.2,0.7-2.2,0.8-2.8
									c0.4,0.2,0.8,0.3,1.1,0.5c0.3,0.2,0.6,0.5,0.8,0.8c-0.4,0.5-0.9,1.2-1.3,2.2c0.9,0.4,1.8,0.8,2.8,1.2c2.5,1,4.4,1.6,5.8,1.8
									c0.3-0.9,0.4-1.6,0.5-2.2l0.8,0.1c0.4,0.6,0.7,1.2,0.8,1.9c0.1,0.7,0,1.4-0.2,2C978.1,1623.8,977.8,1624.2,977.3,1624.3z"
						/>
						<path
							d="M980.8,1616.3c-2.5-1.1-5-2.2-7.6-3.2c-3.5-1.4-6.2-2.3-8.3-2.9c0-1.1,0.2-2.2,0.6-3.3c0.9,0.5,2.8,1.4,5.7,2.7
									c-0.4-0.8-0.6-1.5-0.7-2.3c-0.1-0.8,0-1.5,0.3-2.2c0.9-2.2,2.7-2.7,5.4-1.6l1.9,0.8l2,0.8c2.1,0.8,3.7,1.2,4.7,1.2
									c-0.1,0.6-0.2,1.1-0.4,1.5c-0.2,0.4-0.5,1-0.8,1.6c-2.8-1.2-5.4-2.3-7.8-3.3c-0.8-0.3-1.4-0.4-1.9-0.2s-0.8,0.6-1.1,1.3
									c-0.4,1-0.5,2-0.3,2.9c0.6,0.3,1.4,0.6,2.4,1c3.7,1.5,6.1,2.2,7.1,2.1c-0.1,0.5-0.2,1.1-0.4,1.6
									C981.4,1615.2,981.1,1615.7,980.8,1616.3z"
						/>
						<path
							d="M990.8,1590.8c-0.9-0.5-2.2-1.1-4-1.9c-1.7-0.8-3.3-1.5-4.9-2.2c-3.6-1.5-5.9-2.3-6.9-2.4c0.1-0.6,0.3-1.1,0.5-1.6
									c0.2-0.5,0.5-1,0.9-1.5c0.9,0.5,2.2,1.1,3.9,1.9c1.7,0.8,3.3,1.5,4.8,2.1c3.6,1.5,5.9,2.3,6.9,2.4c-0.1,0.5-0.2,1.1-0.5,1.6
									C991.4,1589.8,991.1,1590.3,990.8,1590.8z"
						/>
						<path
							d="M993.5,1583.3c-0.7,0.2-1.5,0.1-2.3-0.3c-2.3-1.1-2.6-3.9-0.9-8.3c-1.3-0.6-2.2-0.9-2.9-0.8s-1.2,0.6-1.7,1.5
									c-0.3,0.6-0.4,1.2-0.5,1.8c-0.1,0.7-0.1,1.3,0,1.9l-0.7,0c-0.6-0.9-1-1.9-1.1-3c-0.1-1.1,0-2.1,0.4-2.9c0.6-1.2,1.3-1.9,2.3-2.2
									c1-0.3,2.1-0.2,3.3,0.4c0.7,0.3,1.5,0.8,2.3,1.3c0.9,0.5,1.6,0.9,2,1.1c0.7,0.4,1.3,0.6,1.8,0.6s1.1,0,1.7-0.2
									c0.3,1,0.3,1.9,0.1,2.7c-0.6,0.3-1.3,0.4-2.1,0.3c0.2,0.9,0.2,1.7,0.2,2.6c0,0.8-0.2,1.6-0.5,2.1
									C994.7,1582.6,994.2,1583.1,993.5,1583.3z M994.4,1578.6c0-0.6-0.1-1.2-0.2-1.8l-0.1-0.1c-0.6-0.3-1.2-0.6-1.8-0.9l-1.2-0.7
									c-0.5,1.3-0.7,2.3-0.5,3.2c0.2,0.9,0.6,1.5,1.4,1.9c0.3,0.2,0.7,0.2,1.1,0.2c0.4,0,0.7-0.1,1.1-0.3
									C994.3,1579.8,994.4,1579.2,994.4,1578.6z"
						/>
						<path
							d="M996.6,1568.9l-1.7-1l-1.9-1.1c-1.8-1-3.2-1.6-4.2-1.7c0.1-1.1,0.4-2.1,1-3.2c2.2,1.4,4.8,2.9,7.8,4.5
									c0.8,0.4,1.4,0.6,1.9,0.5c0.5-0.1,1-0.5,1.3-1.1c0.5-0.9,0.7-1.7,0.6-2.7c-1.3-0.7-2.4-1.3-3.3-1.9l-1.5-0.9
									c-1.8-1-3.2-1.6-4.2-1.7c0.1-1.1,0.4-2.1,1-3.2c1.3,0.8,2.7,1.7,4.5,2.8c1.7,1.1,2.9,1.8,3.5,2.1c0.7,0.4,1.2,0.6,1.7,0.7
									s1,0,1.7-0.1c0.2,0.9,0.2,1.8-0.1,2.7c-0.7,0.3-1.4,0.3-2.3,0.1c0.3,0.8,0.4,1.5,0.3,2.3s-0.3,1.5-0.6,2.2
									C1001,1570,999.2,1570.3,996.6,1568.9z"
						/>
						<path
							d="M1008.2,1556.9c-1.7-1.3-3.6-2.5-5.7-3.8c-1-0.6-1.8-1.1-2.3-1.3s-1-0.4-1.4-0.5s-0.8-0.1-1.3,0c-0.2-0.5-0.2-0.9-0.2-1.3
									c0-0.4,0.1-0.8,0.3-1c0.6-1,1.6-1,3-0.3c-0.3-0.8-0.5-1.6-0.6-2.3s0-1.5,0.2-2.2c0.4,0.1,0.8,0.2,1.5,0.5s1.1,0.5,1.6,0.8
									c-0.2,0.2-0.4,0.5-0.6,0.8c-0.3,0.5-0.6,1.1-0.8,1.7c-0.2,0.6-0.2,1.1-0.2,1.5l0.8,0.6c1.2,0.8,2.2,1.5,3.2,2
									c1.9,1.2,3.4,1.9,4.4,2c-0.2,0.6-0.4,1.1-0.7,1.5C1009,1556,1008.6,1556.5,1008.2,1556.9z"
						/>
					</g>
					<g className="level_zero location" id="dol_guldur">
						<rect x="677.144" y="1257.732" width="43.969" height="27.669" transform="matrix(0.992857, -0.119308, 0.107348, 0.994294, 1078.693604, -208.067062)"></rect>
						<rect x="1236.6" y="1257.73" width="80.296" height="27.669" transform="matrix(0.982893, -0.18418, 0.17234, 0.985111, 467.971344, -27.588301)"></rect>
						<path
							d="M1906.3,1001.7c-1.4-1.2-2.3-3.1-2.6-5.7c-0.3-1.8-0.2-3.5,0.3-5c0.4-1.5,1.2-2.7,2.3-3.7c1.1-1,2.4-1.6,3.9-1.8
									c1.8-0.3,3.4-0.2,4.7,0.1c0.1,0.4,0.1,1,0,1.6c-0.1,0.6-0.2,1.1-0.3,1.5c-1-0.5-2-0.9-3.1-1.1c-1.1-0.3-1.9-0.4-2.5-0.3
									c-0.6,0.3-1.2,0.8-1.6,1.6c-0.5,0.8-0.8,1.7-1,2.7c-0.2,1-0.2,2.1,0,3.1c0.4,2.4,1,4.1,2,5c1,0.9,2.4,1.3,4.2,1
									c0.8-0.1,1.5-0.3,2.2-0.7c-0.5-3.5-1-5.6-1.4-6.3c0.5-0.2,1-0.3,1.4-0.4c0.5-0.1,1.1-0.1,1.7-0.1c0.1,2.2,0.4,4.4,0.7,6.7
									c-0.5,0.8-1.3,1.4-2.3,2s-2.1,0.9-3.2,1.1C1909.5,1003.3,1907.7,1002.9,1906.3,1001.7z"
						/>
						<path
							d="M1920.1,999.8c-1.2-1-1.9-2.5-2.2-4.6c-0.3-2.2-0.7-4.3-1.1-6.3c-0.4-2-0.7-3.4-1-4c0.5-0.2,1-0.3,1.5-0.4
									c0.5-0.1,1.1-0.1,1.8-0.1c0.4,3.1,0.8,6.6,1.4,10.5c0.4,2.9,1.9,4.2,4.2,3.8c2.5-0.4,3.5-1.9,3.1-4.5c-0.9-6-1.6-9.6-1.9-10.9
									c0.4-0.2,0.7-0.3,1.1-0.4c0.3-0.1,0.7,0,1.2,0c0.1,1.2,0.3,2.8,0.6,4.9c0.3,2,0.5,3.8,0.8,5.4c0.3,2,0.1,3.6-0.7,5
									c-0.8,1.4-2.3,2.3-4.5,2.6C1922.7,1001.1,1921.3,1000.8,1920.1,999.8z"
						/>
						<path
							d="M1931.4,994.1c-0.1-1-0.3-2.4-0.6-4.3c-0.6-3.9-1.1-6.3-1.6-7.1c0.5-0.2,1-0.4,1.6-0.4c0.5-0.1,1.1-0.1,1.7-0.1
									c0.1,1.2,0.2,2.7,0.5,4.5c0.2,1.9,0.5,3.5,0.7,5.1c0.3,2.2,0.6,3.7,0.8,4.6c0.9,0.1,2,0,3.1-0.2c0.7-0.1,1.4-0.3,2.1-0.6
									s1.2-0.6,1.7-0.9c0.1,0.6,0.1,1.3,0.1,2c0,0.7-0.1,1.1-0.2,1.4c-1.3,0.1-2.8,0.2-4.6,0.5c-2.1,0.3-3.6,0.6-4.7,1
									C1931.7,996.9,1931.5,995.1,1931.4,994.1z"
						/>
						<path
							d="M1944,997.7c-0.9,0-1.7,0-2.4,0c-0.1-1-0.2-2.4-0.4-4.2c-0.2-1.8-0.5-3.5-0.7-5.1c-0.6-3.5-1.1-5.7-1.5-6.4
									c0.6-0.4,1.4-0.7,2.4-1c1-0.3,2.1-0.6,3.2-0.7c2.6-0.4,4.7,0,6.4,1.2c1.6,1.2,2.7,3.2,3.1,5.9c0.3,1.7,0.1,3.3-0.4,4.7
									c-0.5,1.4-1.4,2.6-2.6,3.5c-1.2,0.9-2.7,1.5-4.4,1.8C1945.8,997.6,1945,997.7,1944,997.7z M1949.9,994.1c0.6-0.8,1-1.6,1.3-2.6
									c0.3-1,0.3-2,0.1-3c-0.7-4.6-3.1-6.7-7-6c-0.6,0.1-1.3,0.3-1.8,0.6c0.2,2.4,0.6,4.9,0.9,7.4c0.3,2.1,0.6,3.8,1,5.1
									c1.5,0.2,2.7,0.2,3.5,0.1C1948.6,995.3,1949.3,994.8,1949.9,994.1z"
						/>
						<path
							d="M1957.4,994c-1.2-1-1.9-2.5-2.2-4.6c-0.3-2.2-0.7-4.3-1.1-6.3c-0.4-2-0.7-3.4-1-4c0.5-0.2,1-0.4,1.5-0.4
									c0.5-0.1,1.1-0.1,1.8-0.1c0.4,3.1,0.9,6.6,1.5,10.5c0.5,2.9,1.9,4.2,4.2,3.8c2.5-0.4,3.5-1.9,3.1-4.5c-1-6-1.6-9.6-2-10.9
									c0.4-0.2,0.7-0.3,1.1-0.4c0.3-0.1,0.7-0.1,1.2,0c0.1,1.2,0.3,2.8,0.6,4.9c0.3,2,0.5,3.8,0.8,5.4c0.3,2,0.1,3.6-0.7,5
									c-0.7,1.4-2.2,2.3-4.5,2.6C1960,995.3,1958.6,994.9,1957.4,994z"
						/>
						<path
							d="M1969.3,993.6c-0.1-1.1-0.3-2.5-0.5-4.5c-0.2-1.9-0.5-3.7-0.7-5.2c-0.6-3.5-1.1-5.6-1.5-6.4c0.6-0.4,1.3-0.7,2.3-1
									c1-0.3,2-0.6,3-0.7c2-0.3,3.6-0.1,4.8,0.7c1.2,0.8,2,2.1,2.3,3.9c0.2,1.1,0.1,2.1-0.3,3c-0.4,0.9-1,1.7-1.9,2.3
									c2,3,3.6,4.8,4.6,5.4c-0.5,0.3-1,0.6-1.6,0.8c-0.5,0.2-1.2,0.3-1.8,0.4c-0.6-1.1-1.8-2.9-3.7-5.5c-0.1,0-0.3,0.1-0.5,0.1
									c-0.4,0.1-0.8,0.1-1.3,0.1c-0.5,0-0.8,0-1.1,0c0.5,3,1,5,1.4,5.9c-0.5,0.2-1.1,0.4-1.6,0.5C1970.5,993.7,1970,993.7,1969.3,993.6z
									M1975.7,983.5c0.4-0.7,0.5-1.5,0.3-2.3c-0.4-2.5-1.9-3.6-4.6-3.1c-0.6,0.1-1.1,0.3-1.6,0.5c0.2,1.6,0.4,3.7,0.8,6.5
									c1.5,0.3,2.6,0.4,3.3,0.3C1974.9,984.9,1975.4,984.3,1975.7,983.5z"
						/>
						<path
							d="M1895.5,982.7c-0.8,0-1.5,0-2.1,0c0-0.9-0.1-2.2-0.3-3.8c-0.2-1.6-0.3-3.1-0.5-4.5c-0.4-3.2-0.9-5.1-1.3-5.8
									c0.5-0.3,1.3-0.6,2.2-0.9c0.9-0.3,1.9-0.5,2.8-0.6c2.4-0.3,4.2,0.1,5.7,1.2c1.4,1.1,2.3,2.9,2.7,5.4c0.2,1.5,0.1,2.9-0.4,4.2
									c-0.5,1.3-1.3,2.3-2.4,3.1c-1.1,0.8-2.4,1.3-4,1.5C1897.1,982.6,1896.3,982.7,1895.5,982.7z M1900.8,979.6
									c0.5-0.7,0.9-1.4,1.2-2.3c0.2-0.9,0.3-1.8,0.2-2.7c-0.6-4.2-2.6-6-6.2-5.5c-0.6,0.1-1.1,0.2-1.7,0.5c0.2,2.2,0.4,4.4,0.7,6.6
									c0.3,1.8,0.5,3.4,0.8,4.6c1.4,0.2,2.4,0.2,3.1,0.2C1899.6,980.7,1900.3,980.2,1900.8,979.6z"
						/>
						<path
							d="M1908.5,980.4c-1-0.5-1.9-1.3-2.5-2.3c-0.7-1.1-1.1-2.3-1.2-3.8c-0.2-1.6,0-3.1,0.5-4.4s1.3-2.4,2.3-3.3s2.3-1.3,3.8-1.5
									c1.3-0.1,2.5,0,3.5,0.6c1.1,0.5,1.9,1.3,2.6,2.4c0.7,1.1,1.1,2.4,1.3,3.8c0.2,1.6,0,3-0.5,4.3s-1.3,2.4-2.4,3.2s-2.4,1.3-3.9,1.5
									C1910.6,981.1,1909.5,980.9,1908.5,980.4z M1915.5,977.4c0.7-1.1,1-2.6,0.7-4.4c-0.2-1.6-0.7-2.9-1.6-3.9c-0.8-1-1.9-1.5-3.3-1.6
									c-0.9,0.1-1.7,0.5-2.4,1s-1.2,1.4-1.5,2.3c-0.3,0.9-0.4,2-0.3,3.1c0.2,1.8,0.8,3.2,1.7,4.2c0.9,1,2.1,1.4,3.4,1.3
									C1913.7,979.2,1914.8,978.6,1915.5,977.4z"
						/>
						<path
							d="M1920,974.8c-0.1-0.9-0.2-2.2-0.3-3.8c-0.3-3.5-0.7-5.7-1.1-6.4c0.5-0.2,1-0.3,1.5-0.3c0.4,0,1,0,1.6,0
									c0,1,0.1,2.4,0.2,4.1s0.2,3.2,0.4,4.6c0.2,2,0.4,3.4,0.5,4.2c0.8,0.1,1.7,0.1,2.8,0c0.6-0.1,1.3-0.2,1.9-0.4
									c0.6-0.2,1.1-0.4,1.5-0.7c0.1,0.6,0,1.2,0,1.8c-0.1,0.6-0.2,1-0.3,1.2c-1.2,0-2.6,0-4.1,0.2c-1.9,0.2-3.3,0.4-4.2,0.6
									C1920.2,977.3,1920.1,975.7,1920,974.8z"
						/>
					</g>
					<g className="regions level_one location" id="enedwaith">
						<rect x="5477.595" y="1868.581" width="355.669" height="41.104" transform="matrix(0.827766, -0.561073, 0.551103, 0.834524, -4606.598145, 2794.193604)"></rect>
						<path
							d="M978.6,1301c-0.9-1.5-2.3-3.5-4-6c-3.1-4.5-5.6-7.3-7.3-8.6c2-1.1,4.9-3,8.8-5.7c4.7-3.3,8.3-6,10.8-8
									c0.5,0.7,1,1.6,1.3,2.6c0.4,1,0.5,1.8,0.4,2.3c-1.1,0.2-2.4,0.7-3.9,1.5s-2.9,1.6-4.4,2.6c-1.9,1.3-3.5,2.6-5,4
									c1.4,2.4,2.8,4.5,4,6.3l1.4-0.9c1.4-0.9,2.4-1.6,3.1-2c1.7-1.2,3-2.1,3.8-2.8c0.9-0.7,1.8-1.5,2.8-2.5c0.5,0.4,0.9,0.9,1.3,1.3
									c0.3,0.4,0.6,0.9,0.9,1.7c-4,2.5-7.2,4.6-9.4,6.1c-1,0.7-1.7,1.2-2.2,1.6c2.1,2.9,3.6,5,4.5,6c1.9-0.9,3.9-2.1,5.9-3.5
									c1.6-1.1,3.1-2.3,4.5-3.6c1.4-1.3,2.5-2.4,3.3-3.5c0.5,0.7,1,1.6,1.3,2.6c0.4,1,0.5,1.8,0.4,2.3c-2.4,1.4-5.7,3.6-10,6.6
									c-3.8,2.7-6.6,4.7-8.3,6.2C980.9,1304.7,979.6,1302.5,978.6,1301z"
						/>
						<path
							d="M1013.4,1286c-1.7-3.1-4.4-7.2-8.1-12.5c-3.4-4.8-5.7-7.7-7-8.7c1-0.9,1.8-1.6,2.5-2.1c0.8-0.6,1.9-1.2,3.3-1.9
									c2.7,0.9,6.5,2.2,11.5,3.8c4.9,1.6,9.2,2.9,13,4c-2.2-3.2-3.9-5.8-5.2-7.6c-3.5-5-5.9-7.9-7-8.8c0.6-0.7,1.2-1.2,1.9-1.7
									c0.6-0.4,1.4-0.9,2.3-1.2c0.9,1.5,2.1,3.4,3.7,5.9c1.6,2.4,3.1,4.6,4.5,6.7c3.4,4.8,5.7,7.7,7,8.7c-0.5,0.6-1.3,1.3-2.5,2.1
									c-0.8,0.5-1.9,1.2-3.3,2c-2.7-0.9-6.4-2.2-11.4-3.8c-4.9-1.6-9.3-2.9-13-4c1.6,2.4,3.2,5,5.1,7.6c3.4,4.9,5.7,7.8,7,8.8
									c-0.5,0.6-1.2,1.2-1.9,1.7C1015.1,1285.2,1014.4,1285.6,1013.4,1286z"
						/>
						<path
							d="M1044.4,1255c-0.9-1.5-2.3-3.5-4-6c-3.1-4.5-5.6-7.3-7.3-8.6c2-1.1,4.9-3,8.8-5.7c4.7-3.3,8.3-6,10.8-8
									c0.5,0.7,1,1.6,1.3,2.6c0.4,1,0.5,1.8,0.4,2.3c-1.1,0.2-2.4,0.7-3.9,1.5c-1.5,0.8-2.9,1.6-4.4,2.6c-1.9,1.3-3.5,2.6-5,4
									c1.4,2.4,2.8,4.5,4,6.3l1.4-0.9c1.4-0.9,2.4-1.6,3.1-2c1.7-1.2,3-2.1,3.8-2.8c0.9-0.7,1.8-1.5,2.8-2.4c0.5,0.4,0.9,0.9,1.3,1.3
									c0.3,0.4,0.6,0.9,0.9,1.7c-4,2.5-7.2,4.6-9.4,6.1c-1,0.7-1.7,1.2-2.2,1.6c2.1,2.9,3.6,5,4.5,6c1.9-0.9,3.9-2.1,5.9-3.5
									c1.6-1.1,3.1-2.3,4.5-3.6s2.5-2.4,3.3-3.5c0.5,0.7,1,1.6,1.3,2.6c0.4,1,0.5,1.8,0.4,2.3c-2.4,1.4-5.7,3.6-10,6.6
									c-3.8,2.7-6.6,4.7-8.3,6.2C1046.6,1258.7,1045.3,1256.5,1044.4,1255z"
						/>
						<path
							d="M1083.8,1236.8c-1.7,1-3.2,1.8-4.5,2.5c-0.7-1.4-1.8-3.3-3.3-5.6c-1.5-2.3-2.9-4.5-4.3-6.5c-3.2-4.5-5.4-7.1-6.7-7.7
									c0.9-1.1,2.2-2.4,4-4c1.8-1.5,3.6-3,5.6-4.3c4.7-3.3,9-4.9,12.8-4.8c3.8,0.1,6.9,1.9,9.4,5.3c1.5,2.2,2.2,4.5,2,7.1
									s-1.1,5.2-2.8,7.8c-1.7,2.6-4.1,5-7.2,7.1C1087.1,1234.8,1085.5,1235.8,1083.8,1236.8z M1092.8,1225.5c0.7-1.7,0.9-3.4,0.8-5.1
									c-0.1-1.7-0.6-3.2-1.5-4.5c-4.1-5.9-9.8-6.4-16.9-1.4c-1.2,0.8-2.2,1.7-3.2,2.7c1.9,3.2,3.9,6.4,6.2,9.6c1.8,2.6,3.4,4.7,4.9,6.4
									c3-1.3,5.3-2.5,6.6-3.4C1091.1,1228.7,1092.1,1227.2,1092.8,1225.5z"
						/>
						<path
							d="M1121.5,1209.1c-1.4-0.3-2.8-0.8-4.3-1.7c-1.5-0.9-3.5-2.1-6-3.9c-2.8-1.9-5.1-3.4-7-4.6c-1.9-1.2-3.9-2.2-6.1-3.1
									c1.6-2,3.6-3.8,6-5.4c1,1.1,2.5,2.4,4.6,4.1c2.1,1.7,4.2,3.3,6.3,4.8c3.2,2,5.8,3.5,7.9,4.5c-1.9-9.2-3.3-15.4-4.4-18.6l-3.1-1.4
									c1.6-1.9,3.5-3.7,6-5.4c1,1,2.6,2.4,4.7,4c2.1,1.7,4.2,3.3,6.2,4.8c3.4,2.2,6.1,3.7,7.9,4.6c-1.4-7-2.5-12.1-3.3-15.2
									c-0.8-3.1-1.5-5.1-2.1-6.1c-0.1-0.2-0.3-0.3-0.5-0.5c1.2-1.3,2.7-2.5,4.4-3.8c0.5,3.3,1.2,7.7,2.3,13.1c1.1,5.4,2,10,2.9,13.8
									l-1.2,3c-1.1,0.8-2.4,1.1-3.8,0.8c-1.4-0.2-2.8-0.8-4.3-1.6c-1.5-0.9-3.5-2.2-6-3.9c-1.9-1.4-3.8-2.6-5.6-3.8
									c0.8,4,2,9.8,3.6,17.6l-1.2,3C1124.1,1209.1,1122.9,1209.3,1121.5,1209.1z"
						/>
						<path
							d="M1180.5,1168.9l-6.1-3.5c-1.7,1.1-3.9,2.5-6.3,4.2c-2.6,1.8-4.4,3.2-5.2,4c0.3,2.1,0.7,4.4,1.2,6.9
									c-0.5,0.6-1.1,1.1-1.6,1.5c-0.7,0.5-1.5,0.9-2.4,1.3c0-2-0.4-6.5-1.4-13.2c-1-6.8-1.9-12-2.6-15.7c0.9-0.8,1.6-1.4,2.2-1.8
									c0.6-0.4,1.4-0.9,2.3-1.3c3.2,2,7.9,4.6,13.9,7.8c6,3.3,10,5.2,12,5.9c-0.8,0.8-1.6,1.6-2.6,2.3
									C1182.9,1167.7,1181.8,1168.3,1180.5,1168.9z M1171.5,1163.8c-4.3-2.4-7.9-4.3-10.9-5.8c0.3,3.1,0.9,7.2,1.7,12.1
									c1.9-1.2,3.9-2.6,6.2-4.2C1170,1164.9,1171,1164.2,1171.5,1163.8z"
						/>
						<path
							d="M1196.3,1158c-0.7-1.4-1.9-3.4-3.4-5.8c-1.5-2.4-3.1-4.7-4.5-6.8c-3.5-4.9-5.9-7.8-7.3-8.5c0.9-0.9,1.8-1.6,2.7-2.3
									c0.9-0.6,2-1.3,3.3-2c0.7,1.4,1.9,3.4,3.4,5.8c1.5,2.4,3,4.7,4.5,6.8c3.5,4.9,5.9,7.8,7.3,8.6c-0.8,0.8-1.8,1.6-2.8,2.3
									C1198.6,1156.8,1197.5,1157.4,1196.3,1158z"
						/>
						<path
							d="M1220.4,1141.1c-0.8-1.4-1.9-3.3-3.4-5.7c-1.5-2.4-3-4.7-4.6-6.9c-1.7-2.4-3.2-4.3-4.5-5.9c-4.3,3.1-7,5.2-8,6.4
									c-0.6-0.5-1.1-1-1.5-1.5c-0.3-0.5-0.7-1.2-1.1-2.1c1.5-0.9,3.4-2.1,5.8-3.7c2.4-1.6,4.7-3.2,6.9-4.7c5.1-3.6,8.1-5.9,9.1-7.2
									c0.6,0.5,1.1,1,1.5,1.6c0.3,0.4,0.6,1.1,1,2c-2.2,1.3-5.1,3.2-8.6,5.6c2.2,3.6,4.2,6.7,6,9.2c3.5,4.9,5.9,7.8,7.3,8.6
									c-1,0.9-1.9,1.7-2.8,2.3C1222.8,1139.9,1221.7,1140.5,1220.4,1141.1z"
						/>
						<path
							d="M1244.6,1124.2c-0.7-1.4-1.9-3.4-3.4-5.8s-3.1-4.7-4.5-6.8c-3.5-4.9-5.9-7.8-7.3-8.5c0.9-0.9,1.8-1.6,2.7-2.3
									c0.9-0.6,2-1.3,3.3-2c1.4,2.5,3.4,5.8,6,9.7c2-1.2,4.5-2.9,7.6-5c0.5-0.3,1.9-1.4,4.3-3.2c-3.5-5-6-7.9-7.4-8.8
									c0.8-0.8,1.7-1.6,2.7-2.3c0.9-0.6,2-1.3,3.3-1.9c0.7,1.4,1.9,3.4,3.4,5.8c1.5,2.4,3.1,4.7,4.5,6.8c3.4,4.9,5.8,7.7,7.2,8.5
									c-0.8,0.8-1.8,1.6-2.8,2.3c-0.9,0.6-2,1.2-3.2,1.9c-1.3-2.5-3.3-5.7-6-9.8c-2.3,1.5-4.6,3-7,4.7c-1.6,1.1-3.2,2.3-4.9,3.5
									c3.5,5,6,8,7.5,8.8c-0.8,0.8-1.8,1.6-2.8,2.3C1246.9,1123,1245.9,1123.6,1244.6,1124.2z"
						/>
					</g>
					<g className="regions level_one location" id="minhiriath">
						<rect x="4457.969" y="1784.74" width="289.464" height="39.26" transform="matrix(0.768575, -0.63976, 0.630502, 0.776281, -3772.897949, 2567.80542)"></rect>
						<path
							d="M802.4,1123.2c-1.9-2.7-4.8-6.4-8.7-11c-2-2.4-3.6-4.2-4.7-5.3c-1.1-1.1-2-1.8-2.6-2.2c0.7-0.8,1.5-1.6,2.3-2.3
									c0.7-0.6,1.6-1.3,2.9-2c2.3,0.9,5.6,2.1,9.8,3.5c4.2,1.5,7.6,2.6,10.2,3.3c-0.4-2.8-0.9-6.3-1.6-10.4c-0.7-4.1-1.3-7.6-1.9-10.4
									c0.5-0.7,1.3-1.5,2.4-2.4c0.8-0.6,1.7-1.3,2.8-2c0.9,1.3,2.1,2.9,3.7,5c1.6,2.1,3.2,4.1,4.8,6c3.7,4.4,6.2,6.9,7.5,7.4
									c-0.7,0.8-1.5,1.6-2.3,2.3c-0.8,0.7-1.8,1.4-2.9,2.1c-0.8-1.3-2.1-3-3.7-5.1c-1.6-2.1-3.2-4.1-4.8-5.9c-1-1.2-2.2-2.5-3.5-4
									c0.7,5.7,1.9,13,3.6,22.1c-0.3,0.5-0.6,0.8-0.9,1c-0.2,0.2-0.6,0.4-1.1,0.6c-2.1-0.8-5.1-1.9-9.1-3.3c-3.9-1.4-7.5-2.5-10.7-3.5
									c2,2.5,3.6,4.4,4.7,5.8c3.6,4.2,6,6.8,7.3,7.6c-0.4,0.6-0.9,1.2-1.5,1.7C803.9,1122.2,803.2,1122.7,802.4,1123.2z"
						/>
						<path
							d="M836.7,1094.2c-0.8-1.3-2.1-3-3.7-5.1c-1.7-2.1-3.3-4.1-4.8-6c-3.7-4.3-6.2-6.8-7.5-7.3c0.8-0.9,1.5-1.7,2.3-2.3
									c0.8-0.7,1.8-1.4,2.9-2.1c0.8,1.3,2.1,3,3.7,5.1c1.6,2.1,3.2,4.1,4.8,5.9c3.7,4.3,6.2,6.8,7.5,7.4c-0.7,0.8-1.5,1.6-2.4,2.4
									C838.8,1092.8,837.8,1093.5,836.7,1094.2z"
						/>
						<path
							d="M849,1083.8c-1.9-2.7-4.8-6.4-8.7-11c-3.6-4.2-6-6.7-7.2-7.5c0.8-0.9,1.6-1.7,2.2-2.2c0.7-0.6,1.7-1.3,2.9-2
									c2.6,0.7,6.3,1.5,11,2.6c4.7,1.1,8.8,2,12.4,2.7c-2.3-2.8-4.1-5.1-5.5-6.7c-3.7-4.4-6.1-6.9-7.3-7.6c0.5-0.7,1-1.2,1.6-1.7
									c0.6-0.5,1.2-0.9,2-1.3c0.9,1.3,2.3,3,3.9,5.2c1.7,2.1,3.3,4.1,4.7,5.9c3.6,4.2,6,6.7,7.2,7.5c-0.4,0.6-1.1,1.3-2.2,2.2
									c-0.7,0.6-1.6,1.3-2.9,2.1c-2.5-0.6-6.2-1.5-10.9-2.6c-4.7-1.1-8.9-2-12.4-2.7c1.7,2.2,3.5,4.4,5.4,6.7c3.6,4.2,6,6.8,7.3,7.6
									c-0.4,0.6-1,1.2-1.6,1.7C850.4,1082.9,849.8,1083.4,849,1083.8z"
						/>
						<path
							d="M875.4,1061.5c-0.8-1.3-2.1-3-3.7-5.1c-1.7-2.1-3.3-4.1-4.8-6c-3.7-4.3-6.2-6.8-7.5-7.3c0.8-0.9,1.5-1.7,2.3-2.3
									c0.8-0.7,1.8-1.4,2.9-2.1c1.5,2.3,3.6,5.1,6.4,8.5c1.8-1.3,4-3,6.6-5.3c0.4-0.3,1.6-1.4,3.7-3.3c-3.7-4.4-6.3-6.9-7.7-7.6
									c0.7-0.8,1.5-1.6,2.3-2.3c0.8-0.6,1.7-1.3,2.9-2c0.8,1.3,2.1,3,3.7,5.1c1.7,2.1,3.3,4.1,4.8,6c3.6,4.3,6.1,6.7,7.5,7.3
									c-0.7,0.8-1.5,1.6-2.4,2.4c-0.8,0.6-1.7,1.3-2.8,2c-1.4-2.2-3.6-5.1-6.5-8.6c-2,1.5-4,3.2-6,4.9c-1.4,1.2-2.8,2.4-4.2,3.7
									c3.7,4.4,6.3,7,7.7,7.6c-0.7,0.8-1.5,1.6-2.4,2.4C877.4,1060.2,876.5,1060.8,875.4,1061.5z"
						/>
						<path
							d="M902.2,1038.9c-0.8-1.3-2.1-3-3.7-5.1c-1.7-2.1-3.3-4.1-4.8-6c-3.7-4.3-6.2-6.8-7.5-7.3c0.8-0.9,1.5-1.7,2.3-2.3
									c0.8-0.7,1.8-1.4,2.9-2.1c0.8,1.3,2.1,3,3.7,5.1c1.6,2.1,3.2,4.1,4.8,5.9c3.7,4.3,6.2,6.8,7.5,7.4c-0.7,0.8-1.5,1.6-2.4,2.4
									C904.2,1037.5,903.3,1038.2,902.2,1038.9z"
						/>
						<path
							d="M914.7,1028.3c-0.9-1.3-2.1-3-3.8-5.2c-1.7-2.2-3.3-4.2-4.7-5.9c-3.3-3.9-5.6-6.1-6.9-6.5c0.7-1.1,1.8-2.4,3.2-3.9
									c1.4-1.5,2.9-2.9,4.5-4.3c3.1-2.6,5.9-4.1,8.5-4.4c2.6-0.3,4.7,0.5,6.4,2.5c1,1.2,1.5,2.6,1.5,4.2c0,1.6-0.6,3.3-1.6,5.2
									c5.4,1.6,9.1,2.2,11.3,1.9c-0.6,1-1.3,1.9-2.1,2.7c-0.8,0.8-1.7,1.7-2.8,2.6c-1.7-0.7-5-1.7-9.9-3c-0.1,0.1-0.4,0.4-0.8,0.7
									c-0.6,0.5-1.3,1.1-2.1,1.6c-0.8,0.6-1.4,1-1.9,1.2c2.9,3.4,5,5.4,6.4,6c-0.7,0.8-1.5,1.6-2.4,2.4
									C916.8,1026.9,915.8,1027.6,914.7,1028.3z M918.7,1008c0.1-1.4-0.2-2.5-1-3.4c-2.4-2.8-5.6-2.5-9.7,1c-0.9,0.8-1.7,1.5-2.3,2.4
									c1.3,1.9,3.2,4.4,5.8,7.5c2.7-1.2,4.6-2.3,5.8-3.4C918.1,1010.7,918.6,1009.4,918.7,1008z"
						/>
						<path
							d="M939.3,1007.5c-0.8-1.3-2.1-3-3.7-5.1c-1.7-2.1-3.3-4.1-4.8-6c-3.7-4.3-6.2-6.8-7.5-7.3c0.8-0.9,1.5-1.7,2.3-2.3
									c0.8-0.7,1.8-1.4,2.9-2.1c0.8,1.3,2.1,3,3.7,5.1c1.6,2.1,3.2,4.1,4.8,5.9c3.6,4.3,6.2,6.8,7.5,7.4c-0.7,0.8-1.5,1.6-2.4,2.4
									C941.4,1006.1,940.4,1006.8,939.3,1007.5z"
						/>
						<path
							d="M966.6,984.3l-6-2.8c-1.5,1.1-3.4,2.6-5.5,4.4c-2.3,1.9-3.8,3.3-4.5,4.1c0.5,1.9,1.1,4.1,1.7,6.4
									c-0.4,0.6-0.9,1.1-1.3,1.5c-0.6,0.5-1.3,1-2.1,1.4c-0.1-1.9-1-6-2.5-12.2c-1.5-6.2-2.8-11-3.8-14.4c0.7-0.8,1.4-1.4,1.9-1.9
									c0.6-0.5,1.2-0.9,2-1.4c3.2,1.6,7.7,3.6,13.6,6.2c5.9,2.5,9.8,4,11.6,4.5c-0.6,0.8-1.4,1.6-2.2,2.3
									C968.7,983,967.7,983.7,966.6,984.3z M957.8,980.2c-4.2-1.9-7.7-3.4-10.7-4.5c0.5,2.9,1.4,6.6,2.7,11.1c1.6-1.3,3.4-2.7,5.4-4.4
									C956.5,981.4,957.3,980.7,957.8,980.2z"
						/>
						<path
							d="M981.2,972.1c-0.8-1.2-2.1-2.9-3.7-5s-3.2-4.1-4.8-6c-1.8-2.1-3.3-3.8-4.7-5.1c-3.7,3.2-6,5.4-6.9,6.6
									c-0.6-0.4-1.1-0.8-1.5-1.3c-0.3-0.4-0.7-1-1.2-1.9c1.3-0.9,3-2.2,5.1-3.9c2.1-1.7,4.1-3.3,6-4.9c4.4-3.7,7-6.2,7.8-7.4
									c0.6,0.4,1.1,0.9,1.5,1.3c0.3,0.4,0.7,1,1.1,1.8c-1.9,1.4-4.4,3.4-7.5,5.9c2.4,3.2,4.5,5.9,6.4,8.1c3.7,4.3,6.2,6.8,7.5,7.4
									c-0.8,0.9-1.6,1.7-2.4,2.4C983.2,970.8,982.3,971.5,981.2,972.1z"
						/>
						<path
							d="M998.4,957.6c-0.8-1.3-2.1-3-3.7-5.1c-1.7-2.1-3.3-4.1-4.8-6c-3.7-4.3-6.2-6.8-7.5-7.3c0.8-0.9,1.5-1.7,2.3-2.3
									c0.8-0.7,1.8-1.4,2.9-2.1c1.5,2.3,3.6,5.1,6.4,8.5c1.8-1.3,4-3,6.6-5.3c0.4-0.3,1.6-1.4,3.7-3.3c-3.7-4.4-6.3-6.9-7.7-7.6
									c0.7-0.8,1.5-1.6,2.3-2.3c0.8-0.6,1.7-1.3,2.9-2c0.8,1.3,2.1,3,3.7,5.1c1.7,2.1,3.3,4.1,4.8,6c3.6,4.3,6.1,6.7,7.5,7.3
									c-0.7,0.8-1.5,1.6-2.4,2.4c-0.8,0.6-1.7,1.3-2.8,2c-1.4-2.2-3.6-5.1-6.5-8.6c-2,1.5-4,3.2-6,4.9c-1.4,1.2-2.8,2.4-4.2,3.7
									c3.7,4.4,6.3,7,7.7,7.6c-0.7,0.8-1.5,1.6-2.4,2.4C1000.4,956.3,999.5,956.9,998.4,957.6z"
						/>
					</g>
					<g className="level_zero location " id="fangorn">
						<rect className="" x="606.235" y="861.099" width="148.893" height="29.966" transform="matrix(0.98972, -0.14302, -0.220689, 1.042278, 1069.908447, 489.231659)"></rect>
						<path
							d="M1485.2,1329.3c-0.3-1.1-0.9-2.6-1.6-4.4c-0.7-1.9-1.4-3.6-2.2-5.2c-2-4.5-3.5-7.5-4.6-8.9c1.4-0.4,3.4-1.2,6.2-2.4
									c2.9-1.3,5.2-2.4,6.9-3.3c0.3,0.6,0.5,1.4,0.7,2.1c0.2,0.8,0.2,1.4,0.1,1.8c-0.7-0.1-1.5,0-2.4,0.2c-0.9,0.2-1.7,0.5-2.6,0.8
									c-1.3,0.6-2.5,1.2-3.5,1.9c0.9,2.3,1.6,4.1,2.1,5.4l0.9-0.3c0.8-0.3,1.4-0.5,1.8-0.7c1.1-0.5,1.9-0.9,2.4-1.2
									c0.5-0.3,1.2-0.7,1.9-1.2c0.3,0.4,0.5,0.8,0.7,1.2c0.2,0.4,0.3,0.8,0.4,1.4l-1.7,0.6c-1.6,0.6-3.1,1.1-4.4,1.7
									c-0.6,0.3-1,0.5-1.3,0.6c2,4.5,3.4,7.2,4.2,8c-0.6,0.4-1.3,0.8-2,1.1C1486.8,1328.9,1486.1,1329.1,1485.2,1329.3z"
						/>
						<path
							d="M1513,1318.7l-3.3-4.2c-1.3,0.3-2.8,0.7-4.6,1.3c-1.9,0.6-3.2,1.1-3.9,1.4c-0.1,1.6-0.2,3.4-0.3,5.3
									c-0.4,0.3-0.8,0.5-1.2,0.6c-0.5,0.2-1.1,0.3-1.7,0.3c0.3-1.5,0.7-4.8,1.1-9.9c0.4-5.2,0.6-9.2,0.7-12c0.7-0.3,1.2-0.6,1.7-0.7
									c0.5-0.1,1-0.3,1.6-0.3c1.7,2.3,4.3,5.4,7.6,9.4c3.3,4,5.5,6.5,6.6,7.5c-0.6,0.4-1.3,0.7-2,0.9
									C1514.6,1318.5,1513.9,1318.7,1513,1318.7z M1508.1,1312.6c-2.3-2.9-4.3-5.3-6-7.1c-0.3,2.3-0.5,5.4-0.7,9.2
									c1.4-0.4,2.9-0.8,4.5-1.3C1507,1313,1507.7,1312.7,1508.1,1312.6z"
						/>
						<path
							d="M1519,1317.1c-0.3-2.7-0.9-6.6-1.9-11.5c-0.9-4.5-1.6-7.3-2.1-8.5c0.8-0.3,1.5-0.5,2-0.6c0.6-0.1,1.4-0.2,2.4-0.2
									c1.4,1.6,3.4,3.8,5.9,6.6c2.5,2.8,4.8,5.2,6.7,7.2c-0.5-3-1-5.4-1.3-7.1c-0.9-4.7-1.6-7.6-2.1-8.5c0.5-0.3,1-0.4,1.5-0.5
									c0.5-0.1,1-0.1,1.7-0.1c0.2,1.3,0.4,3.1,0.8,5.3c0.4,2.2,0.7,4.3,1.1,6.2c0.9,4.5,1.6,7.3,2.1,8.5c-0.4,0.2-1.1,0.4-2,0.6
									c-0.6,0.1-1.4,0.2-2.4,0.2c-1.4-1.5-3.3-3.7-5.9-6.5c-2.5-2.8-4.8-5.2-6.8-7.3c0.4,2.2,0.8,4.6,1.2,7.1c0.9,4.6,1.6,7.4,2.1,8.5
									c-0.5,0.2-1,0.4-1.5,0.5C1520.2,1317.1,1519.6,1317.2,1519,1317.1z"
						/>
						<path
							d="M1541.1,1311.1c-1.8-1.5-2.8-3.9-3.1-7c-0.2-2.2,0.1-4.2,0.8-6c0.8-1.8,1.9-3.2,3.5-4.3c1.6-1.1,3.4-1.7,5.5-1.9
									c2.5-0.2,4.6-0.1,6.4,0.3c0,0.5,0,1.2-0.2,1.9c-0.2,0.7-0.4,1.3-0.6,1.8c-1.3-0.6-2.6-1.1-4.1-1.5c-1.4-0.4-2.5-0.5-3.4-0.5
									c-0.9,0.3-1.7,0.9-2.4,1.8s-1.2,2-1.6,3.2c-0.4,1.2-0.5,2.5-0.4,3.7c0.3,2.9,1,4.9,2.2,6.1c1.2,1.2,3.1,1.6,5.6,1.4
									c1.1-0.1,2.1-0.3,3-0.7c-0.4-4.2-0.8-6.8-1.4-7.7c0.7-0.2,1.4-0.4,2-0.4c0.7-0.1,1.5-0.1,2.3,0c0,2.6,0.1,5.3,0.4,8.1
									c-0.8,0.9-2,1.6-3.4,2.2c-1.4,0.6-2.9,1-4.4,1.1C1545.2,1313.3,1542.8,1312.7,1541.1,1311.1z"
						/>
						<path
							d="M1564.1,1311.1c-1.5-0.8-2.6-2-3.4-3.6c-0.8-1.5-1.2-3.3-1.1-5.2c0-2.2,0.6-4.1,1.6-5.8s2.4-3,4.2-3.9
									c1.8-0.9,3.8-1.4,6-1.3c2,0,3.7,0.5,5.2,1.4c1.5,0.9,2.7,2.1,3.5,3.6c0.8,1.5,1.2,3.3,1.2,5.3c0,2.1-0.6,4-1.6,5.7
									c-1,1.6-2.5,2.9-4.3,3.8s-3.9,1.3-6.2,1.3C1567.3,1312.4,1565.6,1311.9,1564.1,1311.1z M1575.3,1308.4c1.3-1.4,2-3.3,2-5.7
									c0-2.2-0.5-4-1.6-5.5c-1.1-1.5-2.6-2.4-4.6-2.7c-1.4,0-2.7,0.3-3.8,1c-1.1,0.7-2,1.6-2.7,2.8c-0.7,1.2-1,2.5-1,4.1
									c0,2.4,0.6,4.3,1.8,5.8c1.2,1.5,2.8,2.3,4.9,2.3C1572.3,1310.4,1574.1,1309.7,1575.3,1308.4z"
						/>
						<path
							d="M1585.2,1312.4c0.1-1.3,0.3-3.1,0.4-5.4c0.1-2.3,0.2-4.4,0.2-6.3c0.1-4.3-0.1-6.9-0.6-7.9c0.8-0.3,2-0.5,3.3-0.7
									c1.4-0.2,2.8-0.3,4.2-0.2c2.7,0,4.8,0.6,6.3,1.8c1.5,1.2,2.2,2.9,2.1,5.1c0,1.3-0.4,2.5-1.1,3.5c-0.7,1-1.8,1.8-3.1,2.4
									c2.1,3.9,3.7,6.4,4.9,7.3c-0.7,0.3-1.5,0.5-2.3,0.6c-0.8,0.1-1.6,0.2-2.6,0.1c-0.6-1.4-1.8-3.8-3.8-7.3c-0.1,0-0.4,0-0.7,0
									c-0.5,0-1.1,0-1.8-0.1c-0.6-0.1-1.1-0.1-1.5-0.3c0,3.7,0.2,6.1,0.6,7.3c-0.7,0.1-1.5,0.2-2.3,0.2
									C1586.8,1312.7,1586,1312.6,1585.2,1312.4z M1596.2,1301.8c0.7-0.8,1-1.7,1-2.7c0-3.1-1.8-4.6-5.4-4.7c-0.8,0-1.5,0.1-2.2,0.2
									c-0.2,1.9-0.3,4.5-0.4,7.9c1.9,0.7,3.4,1,4.5,1C1594.7,1303.2,1595.6,1302.6,1596.2,1301.8z"
						/>
						<path
							d="M1605,1312.6c0.2-2.7,0.2-6.6,0.2-11.7c-0.1-4.6-0.2-7.5-0.6-8.7c0.8-0.2,1.5-0.3,2.1-0.3c0.6,0,1.4,0.1,2.4,0.2
									c1.1,1.8,2.6,4.3,4.6,7.5c2,3.2,3.8,6,5.4,8.3c0-3,0-5.4,0-7.2c-0.1-4.8-0.3-7.7-0.6-8.8c0.5-0.2,1.1-0.2,1.6-0.3
									c0.5,0,1,0.1,1.6,0.2c-0.1,1.3-0.1,3.1-0.2,5.4c0,2.3,0,4.4,0,6.3c0.1,4.6,0.2,7.5,0.6,8.7c-0.4,0.2-1.1,0.2-2.1,0.3
									c-0.6,0-1.4-0.1-2.4-0.2c-1.1-1.8-2.6-4.3-4.6-7.5c-2-3.2-3.8-6-5.4-8.3c-0.1,2.3-0.1,4.7,0,7.2c0.1,4.6,0.2,7.6,0.6,8.8
									c-0.5,0.2-1,0.2-1.6,0.3C1606.2,1312.8,1605.6,1312.8,1605,1312.6z"
						/>
					</g>
					<g className="level_one location" id="dunland">
						<rect x="2644.492" y="1868.583" width="171.711" height="41.104" transform="matrix(0.386355, -0.92235, 0.917696, 0.397465, -1464.477905, 2939.823486)"></rect>
						<path
							d="M1311.5,1249.4c-0.4,1.4-0.8,2.6-1.2,3.6c-1.6-0.4-3.8-1-6.7-1.6c-2.8-0.6-5.5-1.1-8.1-1.6c-5.7-1-9.2-1.4-10.6-1.1
									c-0.2-1.1-0.3-2.5-0.3-4.2s0.2-3.4,0.5-5.1c0.7-4.1,2.5-7.1,5.2-8.9c2.7-1.8,6.3-2.3,10.7-1.5c2.7,0.5,5,1.5,6.9,3
									c1.9,1.5,3.3,3.4,4.1,5.7c0.8,2.3,1,4.8,0.5,7.5C1312.2,1246.7,1311.9,1248,1311.5,1249.4z M1309,1238.8c-0.9-1.3-2-2.4-3.4-3.3
									c-1.4-0.9-2.9-1.5-4.5-1.8c-7.5-1.3-11.8,1.2-12.9,7.4c-0.2,1-0.2,2-0.1,3.1c3.8,0.9,7.7,1.7,11.8,2.4c3.3,0.6,6.1,1,8.3,1.2
									c1.1-2.2,1.7-3.9,2-5.1C1310.2,1241.3,1309.8,1240,1309,1238.8z"
						/>
						<path
							d="M1309.8,1222.7l-3.1-0.9l-3.3-1c-3.3-0.8-5.7-1.2-7.3-0.9c-0.3-1.7-0.2-3.4,0.3-5.3c4,1.2,8.7,2.5,14,3.9
									c1.3,0.3,2.4,0.3,3.1,0c0.8-0.4,1.3-1.1,1.6-2.3c0.4-1.5,0.4-3-0.1-4.4c-2.2-0.6-4.2-1.1-6-1.7l-2.8-0.8c-3.3-0.8-5.7-1.2-7.3-0.9
									c-0.3-1.7-0.2-3.5,0.3-5.3c2.3,0.7,5,1.6,8.1,2.5c3.1,0.9,5.2,1.5,6.3,1.8c1.2,0.3,2.2,0.4,3,0.4s1.6-0.3,2.6-0.8
									c0.7,1.4,1,2.7,0.9,4.1c-0.9,0.7-2.1,1.1-3.5,1c0.7,1.1,1.2,2.2,1.5,3.5c0.2,1.3,0.2,2.5-0.1,3.7
									C1317.2,1222.7,1314.5,1223.9,1309.8,1222.7z"
						/>
						<path
							d="M1323.2,1199.3c-3.2-1.3-6.7-2.6-10.4-3.8c-1.8-0.6-3.2-1-4.2-1.2c-1-0.2-1.8-0.3-2.4-0.3c-0.6,0-1.3,0.2-2.1,0.5
									c-0.5-0.7-0.8-1.3-0.9-2c-0.1-0.6-0.1-1.2,0.1-1.6c0.5-1.6,1.8-2.2,3.9-1.8c-0.7-1.1-1.2-2.3-1.4-3.5c-0.2-1.2-0.1-2.4,0.2-3.5
									c1.2-3.5,4-4.5,8.5-3l3.1,1.1c1.2,0.5,2.3,0.9,3.3,1.2c3.4,1.1,6,1.6,7.7,1.5c-0.1,0.9-0.2,1.7-0.5,2.5c-0.3,0.8-0.7,1.6-1.2,2.6
									c-3.9-1.5-8.2-3.1-12.8-4.6c-1.3-0.4-2.3-0.5-3.1-0.2c-0.7,0.3-1.3,1-1.6,2.1c-0.5,1.6-0.6,3.1-0.2,4.5l2.1,0.7
									c1.6,0.6,3.6,1.3,5.9,2.1c3.5,1.1,6,1.7,7.7,1.5c-0.1,1-0.3,1.9-0.5,2.7C1324.1,1197.5,1323.7,1198.3,1323.2,1199.3z"
						/>
						<path
							d="M1331.4,1174c-0.7,0.1-1.8-0.1-3.3-0.6c-1-0.4-2.6-1-4.8-1.9c-2.4-1-4.5-1.8-6.1-2.4c-5.2-1.9-9.1-3.1-11.5-3.3
									c0-1,0-1.8,0.1-2.7s0.4-1.7,0.7-2.6c3.9,1.9,9.6,4.2,17.1,7c1.8,0.7,3.1,1.1,4.1,1.4c1,0.3,1.8,0.4,2.5,0.3c0.6,0,1.3-0.2,2.1-0.5
									c0.4,0.7,0.7,1.4,0.7,2s0,1.2-0.1,1.7C1332.6,1173.3,1332.1,1173.9,1331.4,1174z"
						/>
						<path
							d="M1335.2,1162.2c-1.2,0.3-2.4,0.2-3.7-0.4c-3.8-1.7-4.5-6-2-13.1c-2.1-0.9-3.7-1.3-4.7-1.1c-1.1,0.2-1.9,1-2.6,2.5
									c-0.4,0.9-0.6,1.9-0.7,2.9c-0.1,1,0,2.1,0.2,3.1l-1.1-0.1c-1.1-1.4-1.7-2.9-2-4.7c-0.3-1.7-0.1-3.3,0.5-4.6c0.8-1.9,2-3.1,3.5-3.6
									c1.5-0.5,3.3-0.3,5.4,0.6c1.1,0.5,2.4,1.1,3.8,1.9c1.5,0.8,2.6,1.3,3.3,1.7c1.2,0.5,2.2,0.8,3,0.9c0.8,0.1,1.7-0.1,2.8-0.4
									c0.5,1.5,0.6,2.9,0.3,4.3c-0.9,0.5-2,0.7-3.3,0.5c0.3,1.4,0.5,2.8,0.5,4.1s-0.2,2.5-0.7,3.4
									C1337.2,1161.2,1336.4,1161.9,1335.2,1162.2z M1336.5,1154.8c0-1-0.2-1.9-0.4-2.8l-0.2-0.1c-1-0.4-2-0.9-2.9-1.4l-2-1
									c-0.7,2-1,3.7-0.6,5.1c0.3,1.4,1.1,2.4,2.4,3c0.5,0.2,1.1,0.3,1.8,0.3c0.6,0,1.2-0.2,1.7-0.5
									C1336.4,1156.6,1336.5,1155.8,1336.5,1154.8z"
						/>
						<path
							d="M1345.7,1141.4c-2.9-2-6-4-9.3-6c-1.6-1-2.9-1.7-3.8-2.1c-0.9-0.4-1.7-0.7-2.3-0.8c-0.6-0.1-1.3-0.1-2.2,0.1
									c-0.3-0.8-0.4-1.5-0.4-2.1c0-0.6,0.2-1.2,0.4-1.6c0.9-1.4,2.2-1.7,4.2-0.9c-0.5-1.2-0.7-2.5-0.6-3.8c0.1-1.3,0.4-2.4,1-3.4
									c1.9-3.2,4.9-3.5,9-1.1l2.8,1.8c1.1,0.7,2,1.4,2.9,1.9c3.1,1.8,5.5,2.9,7.2,3.1c-0.3,0.9-0.6,1.6-1,2.3c-0.4,0.7-1,1.5-1.7,2.3
									c-3.5-2.3-7.3-4.8-11.5-7.3c-1.2-0.7-2.2-1-3-0.9c-0.8,0.1-1.5,0.7-2.1,1.7c-0.9,1.5-1.3,2.9-1.2,4.3l1.9,1.2
									c1.5,1,3.2,2.1,5.3,3.3c3.1,1.9,5.5,2.9,7.2,3.2c-0.3,0.9-0.7,1.7-1.1,2.5C1347,1139.8,1346.5,1140.5,1345.7,1141.4z"
						/>
						<path
							d="M1357.8,1118.4c-2.3-0.1-4.8-1.3-7.7-3.6c-2.9-2.3-4.7-4.6-5.4-6.8c-0.7-2.2-0.2-4.4,1.5-6.5c1.3-1.6,3-2.7,5.2-3.2
									c-2.8-2-5-3.4-6.6-4.1c0.3-1,0.6-1.8,1-2.5s0.9-1.5,1.5-2.2c3.2,3,8.8,7.7,16.8,14.1c0.9,0.8,1.8,1.3,2.5,1.5
									c0.7,0.2,1.6,0.3,2.7,0.3c0.1,1.5-0.2,2.9-0.9,4.1c-1.1,0.2-2.2,0.1-3.4-0.4c0.2,1.2,0.2,2.4-0.1,3.6c-0.3,1.2-0.7,2.1-1.3,2.9
									C1361.9,1117.6,1360,1118.5,1357.8,1118.4z M1363.1,1110.8c0.2-0.9,0.3-1.8,0.2-2.7c-0.8-0.7-2.2-1.8-4.2-3.5
									c-2.2-1.8-3.9-3.2-5.2-4.2c-1.4,0.4-2.7,1.3-3.7,2.6c-1,1.3-1.3,2.7-0.9,4.2c0.5,1.5,1.6,3,3.5,4.5c1.9,1.5,3.6,2.4,5.2,2.6
									c1.6,0.2,3-0.1,4.1-1.1C1362.6,1112.5,1362.9,1111.7,1363.1,1110.8z"
						/>
					</g>
					<g className="level_one location " id="ered_mithrin">
						<rect x="3408.506" y="1633.92" width="221.325" height="35.944" transform="matrix(0.996308, 0.085851, -0.097852, 0.995274, -1424.488281, -1605.572021)"></rect>
						<rect x="5010.076" y="1633.92" width="325.32" height="35.944" transform="matrix(0.999989, -0.00476, -0.007286, 1.000046, -3286.25293, -1371.696655)"></rect>
						<path
							d="M1719.8,261.9c0.1-1.6,0.1-3.8,0.1-6.5c0-4.9-0.3-8.3-0.8-10.1c1.7,0.2,4.4,0.3,8,0.3c4.4,0,7.8-0.1,10.2-0.3
									c0,0.8-0.1,1.7-0.3,2.6c-0.2,0.9-0.4,1.6-0.7,1.9c-0.8-0.4-1.8-0.7-3.1-0.9c-1.3-0.2-2.5-0.3-3.9-0.3c-1.8,0-3.4,0.1-4.9,0.4
									c-0.1,2.5-0.2,4.7-0.2,6.7l1.2,0c1.3,0.1,2.2,0.1,2.8,0.1c1.6,0,2.8,0,3.6-0.1c0.8-0.1,1.8-0.2,2.8-0.4c0.1,0.6,0.2,1.1,0.2,1.6
									c0,0.4-0.1,1-0.2,1.7c-3.6-0.2-6.5-0.3-8.5-0.3c-1,0-1.6,0-2,0c0,3.2,0.1,5.5,0.2,6.8c1.6,0.3,3.3,0.4,5.2,0.4
									c1.5,0,2.9-0.1,4.4-0.3c1.5-0.2,2.6-0.5,3.6-0.9c0,0.8-0.1,1.7-0.3,2.6c-0.2,0.9-0.4,1.6-0.7,1.9c-2.1-0.2-5.1-0.3-9.1-0.3
									c-3.5,0-6.2,0.1-7.9,0.3C1719.6,265.8,1719.7,263.5,1719.8,261.9z"
						/>
						<path
							d="M1745.3,268.8c0.1-1.5,0.3-3.5,0.4-6.2c0.1-2.7,0.2-5.1,0.2-7.2c0-4.9-0.3-7.9-0.8-9.1c1-0.4,2.4-0.6,4-0.9
									c1.7-0.2,3.4-0.3,5.1-0.3c3.3,0,5.8,0.7,7.6,2c1.8,1.4,2.7,3.3,2.7,5.8c0,1.5-0.4,2.9-1.3,4c-0.9,1.2-2.1,2.1-3.7,2.8
									c2.5,4.5,4.6,7.3,6.1,8.4c-0.9,0.4-1.8,0.6-2.7,0.7s-2,0.2-3.1,0.2c-0.7-1.6-2.2-4.4-4.7-8.3c-0.1,0-0.4,0-0.9,0
									c-0.7,0-1.4,0-2.2-0.1c-0.8-0.1-1.4-0.1-1.8-0.3c0,4.3,0.3,7.1,0.8,8.4c-0.9,0.2-1.8,0.3-2.8,0.3
									C1747.3,269.1,1746.4,269,1745.3,268.8z M1758.5,256.4c0.8-0.9,1.2-2,1.2-3.1c0-3.5-2.2-5.3-6.6-5.3c-1,0-1.8,0.1-2.7,0.3
									c-0.2,2.2-0.3,5.2-0.3,9.1c2.3,0.7,4.1,1.1,5.4,1.1C1756.7,258,1757.7,257.3,1758.5,256.4z"
						/>
						<path
							d="M1773,261.9c0.1-1.6,0.1-3.8,0.1-6.5c0-4.9-0.3-8.3-0.8-10.1c1.7,0.2,4.4,0.3,8,0.3c4.4,0,7.8-0.1,10.2-0.3
									c0,0.8-0.1,1.7-0.3,2.6c-0.2,0.9-0.4,1.6-0.7,1.9c-0.8-0.4-1.8-0.7-3.1-0.9c-1.3-0.2-2.5-0.3-3.9-0.3c-1.8,0-3.4,0.1-4.9,0.4
									c-0.1,2.5-0.2,4.7-0.2,6.7l1.2,0c1.3,0.1,2.2,0.1,2.8,0.1c1.6,0,2.8,0,3.6-0.1c0.8-0.1,1.8-0.2,2.8-0.4c0.1,0.6,0.2,1.1,0.2,1.6
									c0,0.4-0.1,1-0.2,1.7c-3.6-0.2-6.5-0.3-8.5-0.3c-1,0-1.6,0-2,0c0,3.2,0.1,5.5,0.2,6.8c1.6,0.3,3.3,0.4,5.2,0.4
									c1.5,0,2.9-0.1,4.4-0.3c1.5-0.2,2.6-0.5,3.6-0.9c0,0.8-0.1,1.7-0.3,2.6c-0.2,0.9-0.4,1.6-0.7,1.9c-2.1-0.2-5.1-0.3-9.1-0.3
									c-3.5,0-6.2,0.1-7.9,0.3C1772.8,265.8,1772.9,263.5,1773,261.9z"
						/>
						<path
							d="M1802.4,268.9c-1.5-0.1-2.8-0.3-3.9-0.5c0.2-1.4,0.3-3.4,0.4-5.9c0.1-2.5,0.1-4.8,0.1-7.1c0-5-0.3-8-0.8-9.1
									c1.1-0.4,2.5-0.6,4.2-0.9c1.8-0.2,3.6-0.3,5.4-0.3c4.4,0,7.8,1,10.1,3.1c2.3,2.1,3.5,5,3.5,8.8c0,2.4-0.6,4.5-1.8,6.3
									c-1.2,1.8-2.9,3.3-5.1,4.3c-2.2,1-4.8,1.5-7.6,1.5C1805.4,269.1,1803.9,269,1802.4,268.9z M1812.9,265.2c1.2-0.9,2.1-2,2.7-3.3
									c0.7-1.3,1-2.7,1-4.1c0-6.5-3.3-9.8-9.9-9.8c-1.1,0-2.1,0.1-3.2,0.4c-0.2,3.3-0.3,6.8-0.3,10.3c0,2.9,0.1,5.3,0.2,7.2
									c2.5,0.6,4.4,0.9,5.6,0.9C1810.5,266.6,1811.8,266.1,1812.9,265.2z"
						/>
						<path
							d="M1844.6,268.8c0.3-3.2,0.4-7.6,0.4-13.5c0-3-0.1-5.3-0.2-6.8c-0.1-1.5-0.3-2.6-0.6-3.2c1-0.2,2-0.3,3-0.3
									c0.8,0,1.9,0.1,3.2,0.3c1.1,2.1,2.7,5,4.8,8.7c2.1,3.7,3.8,6.6,5.2,8.8c1.4-2.3,3.1-5.2,5.1-8.7s3.6-6.4,4.9-8.8
									c0.7-0.2,1.8-0.3,3.1-0.3c0.9,0,2,0.1,3.1,0.3c-0.1,1.5-0.3,3.5-0.4,6s-0.2,5-0.2,7.4c0,5.6,0.3,8.9,0.9,10.1c-1,0.2-2,0.3-3,0.3
									c-1,0-2.1-0.1-3.3-0.3c0.2-1.5,0.3-3.5,0.4-6.1c0.1-2.6,0.2-5,0.2-7.3c0-1.5,0-3.2-0.1-5.1c-2.9,4.6-6.4,10.8-10.7,18.5
									c-0.5,0.1-0.9,0.2-1.2,0.2c-0.3,0-0.7-0.1-1.1-0.2c-1-1.9-2.5-4.6-4.5-8c-2-3.4-3.8-6.5-5.5-9.3c-0.1,3.1-0.1,5.5-0.1,7.2
									c0,5.4,0.2,8.7,0.6,10.1c-0.7,0.2-1.4,0.3-2.1,0.3C1846.2,269.1,1845.5,269,1844.6,268.8z"
						/>
						<path
							d="M1887.2,268.8c0.2-1.5,0.3-3.5,0.4-6.1c0.1-2.6,0.2-5.1,0.2-7.4c0-5.4-0.3-8.8-1-10c1.1-0.2,2.1-0.3,3-0.3
									c1,0,2.1,0.1,3.3,0.3c-0.2,1.5-0.3,3.5-0.4,6c-0.1,2.6-0.2,5-0.2,7.3c0,5.4,0.3,8.8,0.9,10.1c-1,0.2-2,0.3-3.1,0.3
									C1889.4,269.1,1888.4,269,1887.2,268.8z"
						/>
						<path
							d="M1909,268.8c0.1-1.4,0.3-3.4,0.4-6c0.1-2.5,0.2-5,0.2-7.4c0-2.6-0.1-4.8-0.3-6.7c-4.5,0-7.5,0.2-8.8,0.6
									c-0.2-0.7-0.3-1.3-0.3-1.9c0-0.5,0.1-1.2,0.3-2.1c1.5,0.1,3.4,0.2,5.9,0.3c2.5,0.1,4.9,0.1,7.2,0.1c5.3,0,8.6-0.2,9.9-0.6
									c0.2,0.7,0.3,1.3,0.3,1.9c0,0.5-0.1,1.2-0.3,2.1c-2.2-0.1-5.1-0.3-8.8-0.4c-0.2,3.8-0.3,7.1-0.3,9.9c0,5.4,0.3,8.8,0.9,10.1
									c-1.1,0.2-2.1,0.3-3.1,0.3S1910.2,269,1909,268.8z"
						/>
						<path
							d="M1930.8,268.8c0.2-1.5,0.3-3.5,0.4-6.1c0.1-2.6,0.2-5.1,0.2-7.4c0-5.4-0.3-8.8-1-10c1.1-0.2,2.1-0.3,3-0.3
									c1,0,2.1,0.1,3.3,0.3c-0.3,2.6-0.5,6-0.6,10.3c2,0.1,4.6,0.2,7.8,0.2c0.5,0,2,0,4.6-0.1c0-5.5-0.3-9-0.9-10.3c1-0.2,2-0.3,3-0.3
									c0.9,0,2,0.1,3.2,0.3c-0.2,1.5-0.3,3.5-0.4,6.1c-0.1,2.6-0.2,5.1-0.2,7.4c0,5.4,0.3,8.7,0.9,10c-1,0.2-2,0.3-3.1,0.3
									c-0.9,0-2-0.1-3.2-0.3c0.3-2.5,0.5-6,0.6-10.3c-2.3-0.1-4.7-0.1-7.2-0.1c-1.7,0-3.4,0-5.2,0.1c0,5.6,0.3,9,0.9,10.4
									c-1,0.2-2,0.3-3.1,0.3C1933.1,269.1,1932,269,1930.8,268.8z"
						/>
						<path
							d="M1964.3,268.8c0.1-1.5,0.3-3.5,0.4-6.2c0.1-2.7,0.2-5.1,0.2-7.2c0-4.9-0.3-7.9-1-9.1c1.2-0.4,2.7-0.6,4.6-0.9
									c1.9-0.2,3.8-0.3,5.7-0.3c3.7,0,6.6,0.7,8.6,2c2,1.4,3,3.3,3,5.8c0,1.5-0.5,2.9-1.5,4c-1,1.2-2.4,2.1-4.2,2.8
									c2.9,4.5,5.2,7.3,6.9,8.4c-1,0.4-2,0.6-3.1,0.7c-1.1,0.1-2.2,0.2-3.5,0.2c-0.8-1.6-2.5-4.4-5.2-8.3c-0.1,0-0.5,0-1,0
									c-0.7,0-1.6,0-2.4-0.1c-0.9-0.1-1.6-0.1-2-0.3c0,4.3,0.3,7.1,0.9,8.4c-1,0.2-2,0.3-3.1,0.3C1966.5,269.1,1965.5,269,1964.3,268.8z
									M1979.2,256.4c0.9-0.9,1.3-2,1.3-3.1c0-3.5-2.5-5.3-7.5-5.3c-1.1,0-2.1,0.1-3,0.3c-0.2,2.2-0.3,5.2-0.4,9.1
									c2.6,0.7,4.6,1.1,6.1,1.1C1977.2,258,1978.3,257.3,1979.2,256.4z"
						/>
						<path
							d="M1995.1,268.8c0.2-1.5,0.3-3.5,0.4-6.1c0.1-2.6,0.2-5.1,0.2-7.4c0-5.4-0.3-8.8-1-10c1.1-0.2,2.1-0.3,3-0.3
									c1,0,2.1,0.1,3.3,0.3c-0.2,1.5-0.3,3.5-0.4,6c-0.1,2.6-0.2,5-0.2,7.3c0,5.4,0.3,8.8,0.9,10.1c-1,0.2-2,0.3-3.1,0.3
									C1997.3,269.1,1996.3,269,1995.1,268.8z"
						/>
						<path
							d="M2010.9,268.8c0.3-3.2,0.4-7.6,0.4-13.5c0-5.3-0.2-8.6-0.6-10c1.2-0.2,2.1-0.3,2.8-0.3c0.9,0,1.9,0.1,3.2,0.3
									c1.5,2.1,3.5,5,6.2,8.7c2.7,3.7,5.1,6.9,7.1,9.7c0.1-3.5,0.1-6.3,0.1-8.3c0-5.5-0.2-8.9-0.6-10.1c0.7-0.2,1.5-0.3,2.2-0.3
									c0.7,0,1.4,0.1,2.2,0.3c-0.1,1.5-0.2,3.6-0.3,6.2c-0.1,2.6-0.1,5-0.1,7.2c0,5.3,0.2,8.6,0.6,10c-0.6,0.2-1.5,0.3-2.8,0.3
									c-0.8,0-1.9-0.1-3.3-0.3c-1.4-2.1-3.5-4.9-6.1-8.7c-2.7-3.7-5.1-7-7.2-9.7c-0.1,2.6-0.2,5.4-0.2,8.2c0,5.4,0.2,8.7,0.6,10.1
									c-0.7,0.2-1.4,0.3-2.2,0.3C2012.5,269.1,2011.8,269,2010.9,268.8z"
						/>
						<path
							d="M1821,318.3l0.3,0.4c-1.7,1.8-2.9,3.9-3.8,6.2c-0.9,2.3-1.5,5.1-1.8,8.4s-0.4,6.1,0,8.6c0.4,2.5,1.1,4.8,2.4,6.9l-0.4,0.4
									c-1.7-2.2-2.9-4.7-3.6-7.3c-0.7-2.7-0.9-5.6-0.6-8.7s1.2-5.9,2.5-8.4C1817.2,322.2,1818.9,320.1,1821,318.3z"
						/>
						<path
							d="M1823.9,342.3c-0.9-1.5-1.3-3.5-1-6c0.2-1.8,0.7-3.3,1.4-4.6c0.8-1.3,1.7-2.3,2.9-3s2.5-0.9,3.9-0.8
									c1.7,0.2,3,0.6,4.1,1.2c-0.1,0.5-0.2,1.1-0.5,1.8c-0.3,0.7-0.5,1.2-0.8,1.5c-0.7-0.7-1.5-1.4-2.3-1.9c-0.8-0.5-1.5-0.8-2-0.9
									c-0.6,0.1-1.2,0.5-1.7,1.1c-0.5,0.6-1,1.3-1.4,2.2s-0.6,1.8-0.7,2.8c-0.2,2.2,0,3.9,0.5,4.9c0.6,1.1,1.7,1.7,3.2,1.8
									c0.6,0.1,1.2,0,1.8-0.1c0.3-3.1,0.4-5.1,0.2-5.9c0.5-0.1,1-0.1,1.5,0c0.5,0,1.1,0.2,1.7,0.4c-0.3,1.6-0.6,3.8-0.9,6.4
									c-0.7,0.6-1.5,1-2.6,1.3s-2.1,0.4-3.1,0.3C1826.3,344.6,1824.9,343.8,1823.9,342.3z"
						/>
						<path
							d="M1839.8,345.7c0.3-2.1,0.6-4.3,0.8-6.6c0.1-1.1,0.2-2,0.2-2.6c0-0.6-0.1-1.1-0.2-1.5s-0.3-0.8-0.5-1.2
									c0.3-0.4,0.7-0.6,1.1-0.8c0.4-0.1,0.7-0.2,1-0.2c1.1,0.1,1.6,0.9,1.6,2.5c1-1.2,2.2-2,3.5-2.3c0.1,0.4,0.2,1,0.2,1.7
									c0,0.7,0,1.4-0.1,2c-0.3-0.1-0.6-0.2-0.9-0.2c-0.6-0.1-1.1,0-1.6,0.1c-0.5,0.1-0.9,0.3-1.3,0.5c-0.2,1.5-0.3,2.9-0.5,4.2
									c-0.2,2.2-0.2,3.8,0.1,4.7c-0.5,0.1-1.1,0.1-1.7,0C1841,346,1840.4,345.8,1839.8,345.7z"
						/>
						<path
							d="M1851.1,345.4c-0.7-1.2-1-2.8-0.8-4.8c0.1-1.4,0.5-2.6,1.1-3.6c0.6-1,1.3-1.8,2.3-2.3c0.9-0.5,1.9-0.7,3.1-0.6
									c1.5,0.1,2.5,0.8,3.2,2s0.9,2.9,0.7,5c-1,0.2-2.2,0.3-3.5,0.4c-1.3,0.1-2.7,0.1-4,0c0,1.1,0.3,2,0.7,2.5c0.4,0.5,1.1,0.9,2,0.9
									c0.7,0.1,1.5,0,2.1-0.1s1.3-0.4,1.8-0.7l0.1,1.1c-0.6,0.8-1.4,1.4-2.4,1.8s-2,0.6-3,0.5C1852.9,347.2,1851.8,346.6,1851.1,345.4z
									M1855.6,339.9c0.8-0.1,1.5-0.3,2-0.6c0.1-1-0.1-1.9-0.6-2.6c-0.4-0.7-1-1.1-1.8-1.2c-0.6,0.3-1,0.9-1.4,1.7
									c-0.4,0.8-0.6,1.8-0.7,2.9C1853.9,340.2,1854.8,340.1,1855.6,339.9z"
						/>
						<path
							d="M1865.9,353.4c-0.8-0.3-1.4-0.7-1.9-1.2c0.1-0.8,0.3-1.7,0.8-2.5s1-1.6,1.8-2.3c-0.9-0.7-1.2-2-1.1-3.9l0.2-1.7l0.2-1.9
									c0.2-2,0.1-3.5-0.2-4.4c1-0.3,2.1-0.5,3.4-0.4c-0.3,1.8-0.5,4.4-0.8,7.8c-0.1,0.8,0,1.4,0.3,1.8c0.3,0.4,0.7,0.6,1.3,0.7
									c0.9,0.1,1.7-0.1,2.4-0.6l0.2-3.3c0-0.3,0-0.7,0.1-1.1c0.1-1,0.1-1.9,0.1-2.6c0-0.8-0.1-1.4-0.2-1.8c1-0.3,2.1-0.5,3.4-0.4
									c-0.3,1.7-0.5,4.3-0.8,7.8c-0.1,0.9-0.2,2.4-0.3,4.2c0,0.3,0,0.6-0.1,1c-0.2,1.9-0.8,3.2-1.9,4.1s-2.6,1.2-4.3,1.1
									C1867.5,353.9,1866.7,353.7,1865.9,353.4z M1871.1,351.3c0.6-0.6,0.9-1.7,1-3.3l0.1-1.6c-0.6,0.6-1.2,1.1-1.8,1.3
									c-0.7,0.3-1.4,0.4-2.1,0.3c-0.3,0-0.5,0-0.6-0.1c-0.7,0.9-1.2,1.8-1.4,2.7c0.3,0.4,0.7,0.7,1.1,1c0.4,0.3,0.9,0.4,1.4,0.5
									C1869.8,352.2,1870.5,351.9,1871.1,351.3z"
						/>
						<path
							d="M1889.4,349.8c0.2-2.2,0.3-5.3,0.4-9.4c0-1.9,0-3.5,0-4.6c0-1.1-0.1-2-0.2-2.6c0.5-0.1,1-0.2,1.6-0.2
									c0.5,0,1.1,0.1,1.7,0.2c0.6,1.6,1.3,3.6,2.2,6c0.9,2.4,1.6,4.4,2.2,5.9c0.7-1.7,1.6-3.7,2.6-6.1c1-2.4,1.7-4.3,2.3-5.6
									c0.4-0.1,1-0.2,1.6-0.2c0.5,0,1.1,0.1,1.8,0.2c-0.1,1-0.2,2.5-0.3,4.3c-0.1,1.8-0.1,3.5-0.2,5.1c-0.1,3.8,0,6.2,0.3,7.1
									c-0.6,0.1-1.1,0.2-1.7,0.2c-0.5,0-1-0.1-1.7-0.2c0.1-1,0.2-2.5,0.3-4.3c0.1-1.8,0.1-3.5,0.2-5.1c0-0.9,0-1.9,0-3.2
									c-1.6,3.6-3.3,7.8-5.2,12.5c-0.2,0.1-0.5,0.1-0.6,0.1c-0.2,0-0.4-0.1-0.6-0.2c-1.5-4.3-3-8.2-4.4-11.8c-0.1,2-0.1,3.5-0.1,4.6
									c-0.1,3.8,0,6.2,0.2,7.1c-0.4,0.1-0.8,0.2-1.2,0.2C1890.2,350,1889.8,349.9,1889.4,349.8z"
						/>
						<path
							d="M1913,349.2c-0.8-0.5-1.4-1.2-1.8-2.2c-0.4-1-0.7-2.1-0.7-3.3c0-1.4,0.2-2.6,0.7-3.7c0.5-1.1,1.2-1.9,2.1-2.6
									c0.9-0.6,1.9-0.9,3.1-1c1.6,0,2.9,0.5,3.8,1.7c1,1.2,1.5,2.7,1.5,4.6c0,1.4-0.2,2.6-0.7,3.7s-1.2,1.9-2.1,2.6
									c-0.9,0.6-2,0.9-3.2,1C1914.7,350,1913.8,349.8,1913,349.2z M1918.4,347.2c0.5-0.8,0.8-1.9,0.7-3.2c0-1.2-0.3-2.2-0.8-3.1
									c-0.5-0.9-1.2-1.4-2.1-1.7c-1,0-1.8,0.4-2.3,1.2c-0.6,0.8-0.8,1.9-0.8,3.2c0,1.5,0.3,2.7,0.9,3.6c0.6,0.9,1.3,1.3,2.1,1.3
									C1917.1,348.4,1917.8,348,1918.4,347.2z"
						/>
						<path
							d="M1926.8,345.5l0.1-2l0.1-2.1c0.1-2.1,0-3.5-0.4-4.4c1-0.4,2.1-0.6,3.4-0.5c-0.2,1.8-0.3,4.7-0.5,8.5
									c0,0.8,0.1,1.4,0.3,1.8c0.3,0.4,0.7,0.6,1.3,0.6c0.8,0,1.6-0.2,2.3-0.6c0-1.3,0.1-2.5,0.2-3.5l0.1-1.6c0.1-2.1,0-3.5-0.4-4.4
									c1-0.4,2.1-0.6,3.4-0.5c-0.1,1.4-0.2,2.9-0.4,4.7c-0.1,1.7-0.2,3.1-0.3,4.1c0,0.8,0,1.5,0.1,1.9c0.1,0.5,0.4,1,0.8,1.5
									c-0.4,0.3-0.8,0.6-1.2,0.8s-0.9,0.3-1.3,0.4c-0.5-0.5-0.8-1.1-1-1.9c-0.5,0.6-1.1,1-1.7,1.3c-0.6,0.3-1.3,0.5-2,0.4
									C1927.7,349.9,1926.7,348.4,1926.8,345.5z"
						/>
						<path
							d="M1942.3,350.2c0.3-2.1,0.6-4.3,0.8-6.7c0.1-1.1,0.2-2,0.2-2.6c0-0.6-0.1-1.1-0.2-1.5c-0.1-0.4-0.3-0.8-0.6-1.2
									c0.4-0.4,0.7-0.6,1.1-0.8c0.4-0.1,0.7-0.2,1-0.2c1,0.1,1.5,0.8,1.5,2c0.5-0.6,1.1-1,1.8-1.3c0.7-0.3,1.3-0.4,2-0.4
									c2.1,0.2,3,1.7,2.7,4.6l-0.2,2l-0.2,2.1c-0.2,2.2-0.2,3.8,0.1,4.7c-0.5,0.1-1.1,0.1-1.7,0c-0.5,0-1.1-0.2-1.7-0.3
									c0.3-2.4,0.6-4.9,0.9-7.7c0.1-0.8,0-1.4-0.2-1.8c-0.2-0.4-0.7-0.6-1.3-0.7c-0.9-0.1-1.6,0.1-2.3,0.5l-0.1,0.9
									c-0.2,1.5-0.3,2.7-0.4,3.8c-0.2,2.2-0.2,3.8,0.1,4.7c-0.5,0.1-1.1,0.1-1.6,0C1943.5,350.5,1942.9,350.4,1942.3,350.2z"
						/>
						<path
							d="M1958.7,351.7c-0.2-0.4-0.3-1.1-0.1-2.1c0.1-0.6,0.2-1.6,0.5-3c0.3-1.5,0.5-2.8,0.6-3.8c0.1-0.4,0.1-0.8,0.1-1.3
									c-0.7-0.1-1.2-0.2-1.4-0.4s-0.3-0.4-0.2-0.8c1.3-0.6,2.6-1.5,3.9-2.7l0.9,0.2c0,0.7-0.1,1.4-0.2,2.2c1.2,0.1,2.1,0.2,2.6,0.1
									c-0.1,0.5-0.2,0.9-0.3,1.2c-0.1,0.4-0.3,0.7-0.5,1.1c-0.5-0.3-1.2-0.5-2.2-0.7c-0.2,0.9-0.3,1.8-0.5,2.7c-0.3,2.4-0.5,4.2-0.4,5.6
									c0.7,0.1,1.4,0.1,2.1,0l0,0.9c-0.4,0.5-1,0.9-1.6,1.2c-0.6,0.3-1.3,0.4-1.9,0.3C1959.4,352.4,1958.9,352.1,1958.7,351.7z"
						/>
						<path
							d="M1967.8,352.9c-0.4-0.6-0.5-1.3-0.4-2.2c0.2-1.3,0.8-2.2,2-2.8c1.1-0.6,2.8-0.8,4.9-0.7c0.1-1.1,0.1-2-0.2-2.5
									c-0.3-0.5-0.8-0.8-1.7-1c-0.6-0.1-1.1,0-1.7,0.1s-1.2,0.3-1.7,0.6l-0.1-1.1c0.6-0.8,1.4-1.5,2.3-1.9c0.9-0.5,1.8-0.6,2.7-0.5
									c1.2,0.2,2.1,0.7,2.6,1.5s0.7,1.9,0.5,3.2c-0.1,0.7-0.3,1.6-0.5,2.5c-0.2,1-0.3,1.7-0.4,2.2c-0.1,0.8-0.1,1.4,0,1.9
									c0.1,0.5,0.3,1,0.6,1.5c-0.8,0.6-1.7,0.8-2.6,0.8c-0.4-0.5-0.7-1.1-0.8-1.8c-0.7,0.4-1.4,0.7-2.2,0.9s-1.4,0.3-1.9,0.2
									C1968.7,353.8,1968.2,353.5,1967.8,352.9z M1972.2,352.2c0.5-0.2,0.9-0.4,1.3-0.6l0-0.2c0.1-0.6,0.2-1.2,0.3-1.8l0.3-1.2
									c-1.1-0.1-2,0.1-2.7,0.5s-1.1,1-1.2,1.7c0,0.3,0,0.7,0.1,1c0.1,0.4,0.3,0.6,0.5,0.9C1971.2,352.4,1971.7,352.4,1972.2,352.2z"
						/>
						<path
							d="M1982.4,355c-0.2-0.4-0.2-1.1-0.1-2c0.1-0.8,0.2-1.9,0.4-3.3c0.2-1.3,0.3-2.3,0.4-2.9c0.1-0.8,0.1-1.6,0.1-2.4
									c0-0.7-0.1-1.3-0.2-1.6c0.6-0.2,1.1-0.3,1.6-0.3c0.5,0,1.1,0,1.7,0.1c-0.2,0.6-0.3,1.5-0.6,2.8c-0.2,1.3-0.4,2.7-0.6,4.2
									c-0.1,1.1-0.2,2-0.2,2.6c0,0.6,0,1.1,0.1,1.5c0.1,0.4,0.3,0.8,0.5,1.2c-0.3,0.4-0.7,0.6-1.1,0.7c-0.4,0.1-0.7,0.2-1,0.1
									C1983,355.6,1982.6,355.4,1982.4,355z M1983.4,338.2c0.6-0.7,1.3-1.3,2.1-1.9c0.5,0.8,1,1.6,1.6,2.3c-0.6,0.7-1.3,1.3-2.1,1.9
									C1984.5,339.7,1984,339,1983.4,338.2z"
						/>
						<path
							d="M1991,356.2c0.3-2.1,0.6-4.3,0.8-6.6c0.1-1.1,0.2-2,0.2-2.6c0-0.6-0.1-1.1-0.2-1.5c-0.1-0.4-0.3-0.8-0.6-1.2
									c0.4-0.4,0.7-0.6,1.1-0.8c0.4-0.1,0.7-0.2,1-0.2c1,0.1,1.5,0.8,1.5,2c0.5-0.6,1.1-1,1.8-1.3c0.7-0.3,1.3-0.4,2-0.3
									c2.1,0.2,3,1.7,2.7,4.6l-0.2,2l-0.3,2.1c-0.2,2.2-0.2,3.8,0.1,4.7c-0.5,0.1-1.1,0.1-1.7,0c-0.5,0-1.1-0.2-1.7-0.3
									c0.4-2.4,0.7-4.9,0.9-7.7c0.1-0.8,0-1.4-0.2-1.8c-0.2-0.4-0.7-0.6-1.3-0.7c-0.9-0.1-1.6,0.1-2.3,0.4l-0.1,0.9
									c-0.2,1.5-0.3,2.7-0.4,3.8c-0.2,2.2-0.2,3.8,0.1,4.7c-0.5,0.1-1.1,0.1-1.6,0S1991.6,356.4,1991,356.2z"
						/>
						<path
							d="M2005.5,356.8c0.1-0.8,0.3-1.6,0.7-2.4c0.3,0.5,0.8,1,1.3,1.3c0.6,0.3,1.2,0.5,1.9,0.6c1.6,0.1,2.4-0.3,2.4-1.3
									c0-0.4-0.1-0.8-0.5-1.1c-0.3-0.3-0.9-0.7-1.6-1.1c-0.8-0.5-1.4-0.9-1.9-1.2c-0.5-0.4-0.9-0.9-1.2-1.5c-0.3-0.6-0.5-1.3-0.4-2.2
									c0.1-1,0.5-1.8,1.4-2.4c0.8-0.6,1.9-0.8,3.2-0.7c1.6,0.1,3,0.5,4.1,1.1c0,0.4-0.2,0.9-0.4,1.5c-0.2,0.6-0.5,1.2-0.7,1.6
									c-0.6-0.7-1.2-1.3-1.8-1.7c-0.6-0.4-1.1-0.7-1.5-0.7c-0.4,0.1-0.7,0.2-0.9,0.4c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.5,0.1,0.9,0.5,1.3
									c0.4,0.3,0.9,0.7,1.7,1.2c0.7,0.4,1.3,0.8,1.8,1.2c0.5,0.4,0.8,0.8,1.1,1.4c0.3,0.6,0.4,1.2,0.4,2c-0.1,1.2-0.6,2.1-1.4,2.7
									c-0.9,0.6-2.1,0.9-3.6,0.7C2007.9,358.1,2006.5,357.6,2005.5,356.8z"
						/>
						<path
							d="M2023.5,340.3c0.9,2.7,1.2,5.6,1.1,8.7s-0.9,6-2,8.5c-1.2,2.5-2.8,4.8-4.8,6.7l-0.4-0.4c1.6-1.9,2.7-4,3.5-6.4
									c0.8-2.4,1.2-5.2,1.4-8.5c0.2-3.3,0-6.1-0.4-8.6c-0.5-2.4-1.4-4.7-2.7-6.8l0.4-0.4C2021.3,335.3,2022.6,337.6,2023.5,340.3z"
						/>
					</g>
					<g className="level_one location" id="iron_hills">
						<rect x="4243.99" y="2166.823" width="275.575" height="47.668" transform="matrix(0.999976, 0.006935, -0.01898, 0.999892, -1788.925903, -1851.234985)"></rect>
						<path
							d="M2425,384.2c0.2-1.6,0.3-3.8,0.4-6.7c0.1-2.9,0.1-5.6,0.1-8.1c0-6-0.3-9.7-0.8-11.1c0.9-0.2,1.8-0.3,2.6-0.3
									c0.8,0,1.8,0.1,2.9,0.3c-0.2,1.6-0.3,3.8-0.4,6.7c-0.1,2.8-0.1,5.5-0.1,8.1c0,6,0.3,9.7,0.8,11.2c-0.9,0.2-1.8,0.3-2.7,0.3
									C2426.9,384.5,2426,384.4,2425,384.2z"
						/>
						<path
							d="M2442.8,384.2c0.1-1.6,0.3-3.9,0.4-6.9c0.1-3,0.2-5.6,0.2-8c0-5.4-0.3-8.7-0.8-10c1-0.4,2.3-0.7,4-1s3.3-0.4,5-0.4
									c3.2,0,5.7,0.7,7.5,2.2c1.7,1.5,2.6,3.6,2.6,6.4c0,1.7-0.4,3.2-1.3,4.4c-0.9,1.3-2.1,2.3-3.7,3.1c2.5,4.9,4.5,8,6,9.2
									c-0.9,0.4-1.8,0.7-2.7,0.8s-2,0.2-3.1,0.2c-0.7-1.8-2.2-4.8-4.6-9.2c-0.1,0-0.4,0-0.9,0c-0.6,0-1.4,0-2.1-0.1
									c-0.8-0.1-1.4-0.2-1.8-0.3c0,4.7,0.3,7.8,0.8,9.2c-0.9,0.2-1.8,0.3-2.7,0.3C2444.8,384.5,2443.8,384.4,2442.8,384.2z
									M2455.8,370.5c0.8-1,1.2-2.2,1.2-3.4c0-3.9-2.2-5.8-6.5-5.8c-0.9,0-1.8,0.1-2.6,0.3c-0.2,2.4-0.3,5.7-0.3,10
									c2.3,0.8,4,1.2,5.3,1.2C2454,372.3,2455,371.6,2455.8,370.5z"
						/>
						<path
							d="M2477.5,383.3c-1.8-1-3.1-2.5-4.1-4.4c-1-1.9-1.4-4.1-1.4-6.6c0-2.8,0.6-5.3,1.8-7.4s2.8-3.8,4.9-5.1
									c2.1-1.2,4.4-1.8,7.1-1.8c2.4,0,4.4,0.5,6.2,1.6c1.8,1.1,3.2,2.6,4.2,4.5c1,1.9,1.5,4.2,1.5,6.8c0,2.7-0.6,5.1-1.8,7.2
									c-1.2,2.1-2.9,3.8-5,5s-4.6,1.8-7.3,1.8C2481.3,384.8,2479.3,384.3,2477.5,383.3z M2490.8,379.6c1.5-1.8,2.3-4.2,2.3-7.3
									c0-2.8-0.7-5.1-2-6.9c-1.3-1.8-3.2-2.9-5.5-3.4c-1.7,0-3.2,0.4-4.5,1.3c-1.3,0.9-2.4,2.1-3.2,3.6c-0.8,1.5-1.1,3.3-1.1,5.2
									c0,3,0.7,5.5,2.2,7.4c1.5,1.9,3.4,2.8,5.8,2.8C2487.2,382.3,2489.2,381.4,2490.8,379.6z"
						/>
						<path
							d="M2508.8,384.2c0.3-3.5,0.4-8.4,0.4-14.8c0-5.8-0.2-9.5-0.5-11.1c1-0.2,1.8-0.3,2.5-0.3c0.7,0,1.7,0.1,2.8,0.3
									c1.3,2.3,3.1,5.5,5.4,9.6c2.3,4.1,4.4,7.6,6.2,10.7c0.1-3.9,0.1-6.9,0.1-9.1c0-6.1-0.2-9.8-0.5-11.2c0.6-0.2,1.3-0.3,1.9-0.3
									c0.6,0,1.2,0.1,1.9,0.3c-0.1,1.7-0.2,4-0.3,6.9c-0.1,2.9-0.1,5.5-0.1,8c0,5.8,0.2,9.5,0.5,11.1c-0.5,0.2-1.3,0.3-2.5,0.3
									c-0.7,0-1.7-0.1-2.9-0.3c-1.2-2.3-3-5.4-5.3-9.6c-2.3-4.1-4.4-7.7-6.2-10.7c-0.1,2.9-0.1,5.9-0.1,9.1c0,5.9,0.2,9.6,0.5,11.2
									c-0.6,0.2-1.2,0.3-1.9,0.3C2510.1,384.5,2509.5,384.4,2508.8,384.2z"
						/>
						<path
							d="M2559.7,384.2c0.2-1.6,0.3-3.8,0.4-6.7c0.1-2.9,0.1-5.6,0.1-8.1c0-6-0.3-9.7-0.8-11.1c0.9-0.2,1.8-0.3,2.6-0.3
									c0.8,0,1.8,0.1,2.9,0.3c-0.3,2.9-0.4,6.6-0.5,11.3c1.8,0.2,4,0.2,6.8,0.2c0.4,0,1.7,0,4-0.1c0-6.1-0.3-9.9-0.8-11.4
									c0.9-0.2,1.8-0.3,2.6-0.3c0.8,0,1.7,0.1,2.8,0.3c-0.2,1.6-0.3,3.8-0.4,6.7c-0.1,2.9-0.1,5.6-0.1,8.1c0,5.9,0.3,9.6,0.8,11.1
									c-0.9,0.2-1.8,0.3-2.7,0.3c-0.8,0-1.7-0.1-2.8-0.3c0.3-2.8,0.4-6.6,0.5-11.4c-2-0.1-4.1-0.1-6.3-0.1c-1.5,0-3,0-4.5,0.1
									c0,6.1,0.3,9.9,0.8,11.4c-0.9,0.2-1.8,0.3-2.7,0.3C2561.6,384.5,2560.7,384.4,2559.7,384.2z"
						/>
						<path
							d="M2592.5,384.2c0.2-1.6,0.3-3.8,0.4-6.7c0.1-2.9,0.1-5.6,0.1-8.1c0-6-0.3-9.7-0.8-11.1c0.9-0.2,1.8-0.3,2.6-0.3
									c0.8,0,1.8,0.1,2.9,0.3c-0.2,1.6-0.3,3.8-0.4,6.7c-0.1,2.8-0.1,5.5-0.1,8.1c0,6,0.3,9.7,0.8,11.2c-0.9,0.2-1.8,0.3-2.7,0.3
									C2594.5,384.5,2593.6,384.4,2592.5,384.2z"
						/>
						<path
							d="M2610.8,375.9c0-1.5,0.1-3.7,0.1-6.6c0-6-0.3-9.7-0.8-11.1c0.9-0.2,1.8-0.3,2.7-0.3c0.8,0,1.7,0.1,2.8,0.3
									c-0.2,1.8-0.3,4.1-0.4,6.9s-0.1,5.5-0.1,7.8c0,3.4,0.1,5.8,0.2,7.1c1.5,0.3,3.1,0.4,5.1,0.4c1.2,0,2.3-0.1,3.5-0.4
									c1.1-0.2,2.1-0.6,2.9-1c0,1-0.1,2-0.3,3c-0.2,1-0.5,1.7-0.7,2.1c-2.1-0.2-4.6-0.3-7.5-0.3c-3.4,0-6,0.1-7.7,0.3
									C2610.6,380.2,2610.8,377.4,2610.8,375.9z"
						/>
						<path
							d="M2637,375.9c0-1.5,0.1-3.7,0.1-6.6c0-6-0.3-9.7-0.8-11.1c0.9-0.2,1.8-0.3,2.7-0.3c0.8,0,1.7,0.1,2.8,0.3
									c-0.2,1.8-0.3,4.1-0.4,6.9s-0.1,5.5-0.1,7.8c0,3.4,0.1,5.8,0.2,7.1c1.5,0.3,3.1,0.4,5.1,0.4c1.2,0,2.3-0.1,3.5-0.4
									c1.1-0.2,2.1-0.6,2.9-1c0,1-0.1,2-0.3,3c-0.2,1-0.5,1.7-0.7,2.1c-2.1-0.2-4.6-0.3-7.5-0.3c-3.4,0-6,0.1-7.7,0.3
									C2636.8,380.2,2636.9,377.4,2637,375.9z"
						/>
						<path
							d="M2661.2,383c0-0.7,0.1-1.3,0.2-1.8c0.1-0.5,0.3-1.1,0.6-1.6c0.7,0.8,1.8,1.4,3.1,1.8c1.3,0.4,2.7,0.7,4.2,0.7
									c1.8,0,3.1-0.3,4-1c0.9-0.7,1.4-1.7,1.4-2.9c0-1.1-0.5-2-1.4-2.7c-0.9-0.7-2.3-1.6-4.2-2.5c-1.7-0.9-3-1.6-4-2.3
									c-1-0.6-1.8-1.5-2.6-2.5c-0.7-1-1.1-2.3-1.1-3.7c0-1.9,0.9-3.4,2.6-4.6c1.8-1.2,4.1-1.8,7.1-1.8c2.7,0,5,0.4,6.9,1.2
									c0,0.7-0.1,1.5-0.4,2.5c-0.3,0.9-0.6,1.6-0.9,2.2c-1.4-1-2.8-1.8-4.2-2.5c-1.4-0.7-2.6-1-3.5-1c-0.7,0.1-1.3,0.5-2,1.2
									c-0.6,0.7-1,1.4-1,2.4c0,1.2,0.5,2.1,1.4,2.9c0.9,0.8,2.4,1.6,4.3,2.6c1.6,0.8,2.9,1.5,3.9,2.2c1,0.6,1.8,1.4,2.5,2.4
									c0.7,1,1,2.1,1,3.5c0,2.3-0.8,4.1-2.4,5.4c-1.6,1.3-4,1.9-7.2,1.9C2666.4,384.7,2663.6,384.2,2661.2,383z"
						/>
					</g>
					<g className="level_zero location " id="mirkwood">
						<rect x="5331.54" y="3029.431" width="346.19" height="66.644" transform="matrix(0.026669, 0.999644, -0.999966, 0.014627, 5096.201172, -4722.384277)"></rect>
						<path
							d="M2161.2,666.4c4.5,1,11,2,19.4,3.1c4.4,0.6,7.7,0.9,9.9,1.1c2.2,0.1,3.8,0.1,4.8-0.1c0.1,1.3,0.1,2.5-0.1,3.8
									c-0.1,1-0.4,2.3-0.9,4c-3.2,1-7.7,2.4-13.3,4.3c-5.7,1.9-10.2,3.5-13.5,4.8c3.1,2.2,7,4.8,11.7,7.9c4.7,3.1,8.7,5.7,12,7.7
									c0.1,1,0.1,2.3-0.1,3.9c-0.2,1.2-0.4,2.4-0.9,3.8c-2.1-0.5-4.9-1-8.6-1.6s-7.2-1.1-10.6-1.6c-8-1-13-1.3-14.8-0.8
									c-0.1-1.3-0.1-2.5,0.1-3.8c0.2-1.2,0.5-2.5,0.9-4c2.1,0.5,5,1.1,8.7,1.7s7.2,1.1,10.5,1.6c2.1,0.3,4.6,0.6,7.4,0.8
									c-6.2-4.4-14.5-10-25-16.7c-0.1-0.6-0.2-1.1-0.1-1.6c0-0.4,0.2-0.8,0.5-1.3c2.9-0.9,7-2.2,12.3-4c5.3-1.8,10.1-3.5,14.3-5
									c-4.5-0.7-7.9-1.2-10.4-1.5c-7.7-1-12.6-1.4-14.7-1.1c-0.1-0.9-0.2-1.7-0.1-2.6C2160.6,668.3,2160.8,667.4,2161.2,666.4z"
						/>
						<path
							d="M2154.8,729.3c2.1,0.3,5.1,0.6,8.8,0.9c3.8,0.3,7.4,0.5,10.7,0.7c7.9,0.4,12.8,0.2,14.7-0.4c0.2,1.2,0.3,2.3,0.2,3.3
									c0,1-0.2,2.2-0.6,3.5c-2.1-0.3-5-0.6-8.8-0.9c-3.7-0.3-7.3-0.5-10.7-0.7c-7.9-0.4-12.8-0.3-14.8,0.3c-0.2-1.1-0.3-2.2-0.2-3.4
									C2154.3,731.7,2154.5,730.6,2154.8,729.3z"
						/>
						<path
							d="M2153.9,752.9c2.1,0.1,5.2,0.2,9.1,0.2c3.9,0.1,7.5,0,10.5,0c7.1-0.2,11.5-0.6,13.2-1.3c0.5,1.2,1,2.9,1.4,4.9
									c0.4,2,0.6,4.1,0.6,6.2c0.1,4-0.8,7.1-2.7,9.4c-1.9,2.2-4.7,3.4-8.4,3.4c-2.2,0-4.2-0.4-5.9-1.5c-1.7-1-3.1-2.5-4.2-4.5
									c-6.5,3.2-10.5,5.8-12,7.7c-0.5-1.1-0.9-2.2-1.1-3.3c-0.2-1.1-0.3-2.4-0.4-3.8c2.3-0.9,6.3-2.9,12-5.9c0-0.2-0.1-0.5-0.1-1.1
									c0-0.8,0-1.7,0.1-2.6c0.1-0.9,0.2-1.7,0.3-2.2c-6.2,0.2-10.3,0.6-12.2,1.2c-0.3-1.1-0.4-2.2-0.5-3.4
									C2153.6,755.3,2153.7,754.2,2153.9,752.9z M2172.3,768.6c1.4,0.9,2.9,1.4,4.6,1.3c5.2-0.1,7.7-2.9,7.6-8.3c0-1.2-0.2-2.2-0.5-3.2
									c-3.2-0.1-7.6-0.2-13.3-0.1c-1,2.8-1.5,5-1.5,6.6C2169.9,766.4,2171,767.6,2172.3,768.6z"
						/>
						<path
							d="M2155.8,793.4c2.1-0.1,5.1-0.3,8.9-0.7c3.8-0.4,7.3-0.8,10.7-1.3c7.9-1.1,12.6-2.1,14.3-3c0.4,1.1,0.7,2.2,0.8,3.2
									c0.1,1,0.2,2.2,0.1,3.6c-4.6,0.3-10.2,0.8-16.7,1.6c5.8,3.8,10,6.3,12.5,7.5c2.5,1.2,4.3,1.8,5.4,1.6c0.8,2.3,1.4,4.7,1.7,7.4
									c-3.4-2.5-8.7-6-15.8-10.4c-9.7,7.6-15.5,13-17.4,16.2c-0.7-1-1.2-2.1-1.5-3.2c-0.3-1.1-0.6-2.4-0.8-3.9c3.1-2.3,8-6.4,14.7-12.3
									l-1.6-1c-0.9-0.5-1.6-0.9-2.1-1.2c-6.6,1-10.6,1.9-12.2,2.6c-0.4-1-0.7-2.1-0.8-3.3C2155.7,795.8,2155.7,794.7,2155.8,793.4z"
						/>
						<path
							d="M2166,838.5c1.1-1.2,2.6-2.3,4.4-3.4c1.9-1.1,4.5-2.5,8-4.2c3.8-1.9,7-3.5,9.4-4.9c2.5-1.4,4.9-2.9,7.1-4.5
									c1.4,2.1,2.3,4.4,2.9,7c-1.8,0.6-4.3,1.6-7.5,2.9c-3.1,1.4-6.2,2.8-9.2,4.2c-4.2,2.2-7.5,4.2-9.9,5.8c11.7,1.1,19.8,1.6,24.2,1.6
									l3.4-2.3c1.3,2,2.3,4.3,2.9,7c-1.8,0.6-4.2,1.6-7.4,3c-3.2,1.4-6.2,2.7-9.2,4.1c-4.5,2.4-7.8,4.3-9.9,5.7c9,0.8,15.5,1.4,19.6,1.6
									c4.1,0.2,6.9,0.2,8.3-0.1c0.3-0.1,0.6-0.2,0.8-0.3c0.8,1.5,1.4,3.2,1.8,5.1c-4.1-0.6-9.6-1.2-16.5-1.9c-6.9-0.7-12.8-1.2-17.8-1.6
									l-2.8-2c-0.3-1.3,0.1-2.5,1.2-3.6c1.1-1.2,2.6-2.3,4.4-3.4c1.9-1.1,4.6-2.5,8.1-4.2c2.7-1.3,5.3-2.6,7.6-3.9
									c-5.1-0.5-12.6-1.2-22.5-2.1l-2.8-2C2164.4,840.9,2164.9,839.7,2166,838.5z"
						/>
						<path
							d="M2174.7,888.3c1.2-2,2.9-3.7,5.4-5c2.4-1.3,5.2-2.1,8.5-2.5c3.7-0.4,7-0.2,10,0.8c3,0.9,5.4,2.4,7.2,4.4
									c1.8,2,2.9,4.5,3.3,7.3c0.3,2.5-0.2,4.8-1.4,6.9c-1.2,2.1-3,3.8-5.5,5.1c-2.4,1.3-5.3,2.2-8.7,2.6c-3.6,0.4-6.8,0.1-9.7-0.8
									c-2.9-1-5.3-2.5-7.1-4.6c-1.8-2.1-2.9-4.6-3.2-7.5C2173,892.6,2173.5,890.3,2174.7,888.3z M2181.1,901.9c2.5,1.3,5.8,1.8,9.9,1.3
									c3.7-0.4,6.6-1.5,8.8-3.2c2.2-1.7,3.5-3.8,3.8-6.4c-0.2-1.8-1-3.4-2.3-4.7c-1.3-1.3-3-2.3-5.1-2.8c-2.1-0.6-4.4-0.7-7-0.4
									c-4,0.5-7.1,1.6-9.4,3.5c-2.3,1.9-3.3,4.1-3,6.6C2177.1,898.5,2178.5,900.6,2181.1,901.9z"
						/>
						<path
							d="M2177.5,925c1.5-1.8,3.5-3.2,6.1-4.1c2.6-0.9,5.5-1.3,8.8-1.2c3.7,0.2,6.9,0.9,9.7,2.3s5,3.2,6.5,5.5
									c1.5,2.3,2.2,4.9,2.1,7.7c-0.1,2.5-0.9,4.7-2.4,6.6c-1.5,1.9-3.6,3.3-6.2,4.2c-2.6,1-5.6,1.4-9,1.2c-3.6-0.1-6.8-0.9-9.5-2.3
									c-2.7-1.4-4.9-3.3-6.3-5.7c-1.5-2.4-2.2-5-2-7.9C2175.2,929,2176,926.8,2177.5,925z M2181.7,939.4c2.3,1.7,5.5,2.7,9.5,2.8
									c3.7,0.2,6.8-0.4,9.2-1.8c2.5-1.3,4-3.2,4.7-5.8c0.1-1.8-0.4-3.5-1.5-5s-2.6-2.7-4.6-3.6c-2-0.9-4.2-1.4-6.8-1.5
									c-4-0.2-7.3,0.5-9.8,2c-2.5,1.5-3.9,3.5-4,6.1C2178.3,935.5,2179.4,937.7,2181.7,939.4z"
						/>
						<path
							d="M2172.7,962c0.6-1.8,1.2-3.3,1.8-4.5c2,0.6,4.7,1.4,8.2,2.3s6.8,1.7,10,2.4c7.1,1.5,11.5,2.2,13.2,1.9
									c0.2,1.4,0.3,3.1,0.1,5.3c-0.1,2.2-0.5,4.3-0.9,6.5c-1.1,5.2-3.5,8.9-7,11.1c-3.5,2.1-8,2.6-13.4,1.4c-3.4-0.7-6.2-2.1-8.5-4.1
									c-2.3-2-3.9-4.5-4.8-7.5c-0.9-3-1-6.1-0.2-9.6C2171.7,965.4,2172.1,963.7,2172.7,962z M2175.2,975.7c1,1.7,2.3,3.1,4,4.3
									c1.7,1.2,3.6,2,5.5,2.5c9.3,2,14.8-0.9,16.5-8.8c0.3-1.3,0.4-2.6,0.3-3.9c-4.7-1.3-9.5-2.5-14.6-3.6c-4.1-0.9-7.6-1.6-10.3-2
									c-1.5,2.8-2.4,4.9-2.7,6.4C2173.8,972.3,2174.3,974,2175.2,975.7z"
						/>
					</g>
					<g className="level_two fade location " id="celduin">
						<rect x="1972.889" y="1808.381" width="128.105" height="39.783" transform="matrix(0.733323, 0.67988, -0.688714, 0.725133, 2000.812256, -2075.791992)"></rect>
						<rect x="3748.518" y="1808.382" width="243.401" height="39.783" transform="matrix(0.982662, 0.185404, -0.197241, 0.980429, -1048.675537, -1810.466675)"></rect>
						<path
							d="M2190,603.5c0.3-2.1,1.5-4.1,3.7-6.2c1.5-1.4,3.2-2.4,4.9-3c1.7-0.6,3.4-0.6,5-0.2s3,1.2,4.2,2.5c1.3,1.4,2.2,2.8,2.7,4.2
									c-0.4,0.4-0.9,0.7-1.5,1.1c-0.6,0.3-1.2,0.6-1.6,0.7c-0.1-1.2-0.4-2.3-0.8-3.5c-0.4-1.1-0.8-1.9-1.2-2.4c-0.7-0.4-1.5-0.6-2.5-0.5
									s-2.1,0.4-3.2,0.8c-1.1,0.5-2.1,1.2-3,2c-1.8,1.7-2.9,3.4-3.1,4.9c-0.2,1.6,0.3,3,1.4,4.2c1.5,1.7,3.1,2.7,4.7,3.2l-0.5,0.8
									c-1.2,0.1-2.5-0.1-3.8-0.6c-1.3-0.5-2.4-1.2-3.2-2.2C2190.5,607.5,2189.8,605.6,2190,603.5z"
						/>
						<path
							d="M2202.1,617.1c0.3-1.6,1.3-3.2,3-4.8c1.2-1.1,2.4-1.9,3.8-2.3c1.3-0.4,2.6-0.5,3.9-0.2c1.2,0.3,2.3,0.9,3.3,1.9
									c1.2,1.3,1.7,2.8,1.4,4.4c-0.3,1.6-1.3,3.2-3.2,4.9c-1.1-0.7-2.3-1.5-3.6-2.6s-2.6-2.2-3.8-3.4c-1,1.1-1.5,2.1-1.6,3.1
									s0.3,1.8,1.1,2.7c0.6,0.7,1.3,1.2,2.1,1.7s1.5,0.7,2.2,0.9l-0.3,0.7c-1.2,0.2-2.4,0-3.7-0.4s-2.3-1.1-3.1-2
									C2202.3,620.2,2201.8,618.7,2202.1,617.1z M2210.5,616.1c1,0.6,1.8,1,2.5,1.2c0.9-0.9,1.5-1.9,1.6-3c0.1-1.1-0.1-2-0.9-2.7
									c-0.8-0.2-1.7-0.1-2.8,0.3c-1.1,0.4-2.1,1.1-3.1,2.1C2208.6,614.8,2209.5,615.5,2210.5,616.1z"
						/>
						<path
							d="M2213.5,631c0.1-0.5,0.6-1.1,1.4-1.9c0.6-0.5,1.5-1.3,2.7-2.5c1.4-1.3,2.5-2.3,3.4-3.1c2.9-2.7,4.8-4.8,5.7-6.3
									c0.6,0.3,1.2,0.6,1.7,0.9c0.5,0.3,1,0.8,1.4,1.3c-2.4,1.9-5.7,4.8-9.8,8.7c-1,0.9-1.7,1.6-2.2,2.2c-0.5,0.6-0.8,1-1,1.5
									c-0.2,0.4-0.3,0.9-0.3,1.5c-0.6,0-1.1,0-1.5-0.2c-0.5-0.2-0.8-0.4-1-0.6C2213.6,632,2213.4,631.5,2213.5,631z"
						/>
						<path
							d="M2221.5,637.6c0.3-1.6,1.4-3.3,3.2-5.1c1.9-1.8,3.7-2.9,5.3-3.1c1.6-0.2,3.1,0.3,4.5,1.7c1,1.1,1.6,2.4,1.8,4
									c1.7-1.8,2.9-3.2,3.5-4.3c0.7,0.3,1.2,0.6,1.7,1c0.5,0.3,1,0.8,1.5,1.3c-2.4,1.9-6.3,5.4-11.5,10.5c-0.6,0.6-1.1,1.1-1.3,1.6
									c-0.2,0.5-0.4,1.1-0.5,1.9c-1.1-0.1-2-0.4-2.8-1.1c-0.1-0.8,0.1-1.6,0.6-2.4c-0.9,0-1.8-0.1-2.5-0.4s-1.5-0.7-2-1.3
									C2221.7,640.7,2221.2,639.2,2221.5,637.6z M2226.4,642.2c0.6,0.2,1.3,0.4,1.9,0.5c0.6-0.5,1.5-1.4,2.8-2.6
									c1.5-1.4,2.6-2.4,3.5-3.2c-0.2-1.1-0.7-2-1.5-2.9c-0.8-0.9-1.8-1.2-2.9-1c-1.1,0.2-2.3,0.8-3.5,2c-1.2,1.2-2,2.3-2.3,3.4
									s-0.2,2.1,0.4,3.1C2225.3,641.7,2225.8,642,2226.4,642.2z"
						/>
						<path
							d="M2237.1,649.4l1.6-1.6l1.7-1.7c1.7-1.7,2.7-3.1,3.1-4.2c1.1,0.4,2.2,1.1,3.2,2.1c-2.2,2.1-4.6,4.5-7.3,7.3
									c-0.7,0.7-1,1.4-1.1,2c0,0.6,0.3,1.2,0.9,1.8c0.8,0.8,1.7,1.3,2.8,1.5c1.1-1.2,2.2-2.2,3.1-3.1l1.4-1.4c1.7-1.7,2.7-3.1,3.1-4.2
									c1.1,0.4,2.2,1.1,3.3,2.1c-1.2,1.2-2.7,2.6-4.3,4.2c-1.7,1.6-2.8,2.7-3.3,3.3c-0.6,0.6-1,1.2-1.2,1.7s-0.3,1.1-0.4,1.9
									c-1.1,0-2.1-0.3-2.9-0.9c-0.1-0.8,0-1.7,0.6-2.5c-0.9,0.1-1.8,0-2.7-0.3c-0.9-0.3-1.6-0.7-2.3-1.3
									C2234.5,654,2234.7,651.9,2237.1,649.4z"
						/>
						<path
							d="M2250.5,666.7c0-0.5,0.4-1.2,1-2.2c0.5-0.8,1.3-1.8,2.2-3.2c0.9-1.3,1.6-2.2,2-2.8c0.6-0.8,1-1.6,1.4-2.4
									c0.4-0.8,0.6-1.4,0.6-1.8c0.7,0.1,1.3,0.3,1.8,0.6c0.5,0.2,1.1,0.6,1.7,1c-0.5,0.5-1.2,1.4-2.1,2.6c-0.9,1.2-1.9,2.5-2.9,4.1
									c-0.8,1.1-1.3,2-1.7,2.6c-0.3,0.6-0.6,1.2-0.6,1.6c-0.1,0.4-0.1,1,0,1.5c-0.6,0.2-1.1,0.2-1.5,0.1c-0.5-0.1-0.9-0.2-1.1-0.4
									C2250.7,667.7,2250.4,667.3,2250.5,666.7z M2260.7,649.9c1.1-0.4,2.1-0.6,3-0.7c0.1,1,0.2,2.1,0.5,3.1c-1,0.3-2,0.6-3,0.7
									C2261,652,2260.9,650.9,2260.7,649.9z"
						/>
						<path
							d="M2261.8,674c1.1-2.2,2.2-4.6,3.4-7.1c0.5-1.2,0.9-2.2,1.1-2.9c0.2-0.7,0.3-1.3,0.3-1.7c0-0.5-0.1-1-0.3-1.5
									c0.5-0.3,1-0.4,1.5-0.5c0.5,0,0.8,0,1.2,0.2c1.1,0.5,1.5,1.4,1,2.9c0.8-0.4,1.7-0.7,2.6-0.7c0.9-0.1,1.8,0.1,2.5,0.4
									c2.4,1.1,3,3.2,1.6,6.3l-1,2.1c-0.4,0.8-0.8,1.5-1.1,2.2c-1,2.3-1.5,4.1-1.6,5.4c-0.6-0.1-1.2-0.3-1.8-0.5c-0.5-0.2-1.1-0.6-1.8-1
									c1.3-2.7,2.7-5.6,4.1-8.8c0.4-0.9,0.5-1.6,0.3-2.2c-0.2-0.5-0.6-1-1.4-1.3c-1.1-0.5-2.2-0.7-3.2-0.5l-0.7,1.4
									c-0.6,1.1-1.2,2.5-1.9,4c-1,2.4-1.6,4.1-1.6,5.3c-0.7-0.1-1.3-0.3-1.9-0.6C2263,674.8,2262.4,674.5,2261.8,674z"
						/>
						<path
							d="M2296.9,670.5l0.6,0.3c-1,2.6-1.4,5.2-1.3,7.9s0.7,5.7,1.8,9.1s2.4,6.2,3.9,8.4c1.5,2.3,3.4,4.1,5.7,5.7l-0.3,0.6
									c-2.8-1.4-5.2-3.2-7.2-5.5c-2-2.3-3.5-5.1-4.6-8.3c-1-3.2-1.4-6.4-1.2-9.4C2294.7,676.1,2295.5,673.2,2296.9,670.5z"
						/>
						<path
							d="M2308,696c-0.1-1.2-0.2-2.9-0.5-5.1c-0.2-2.2-0.5-4.2-0.7-5.9c-0.6-4-1.1-6.5-1.6-7.3c0.7-0.4,1.5-0.8,2.7-1.1
									c1.1-0.3,2.3-0.6,3.4-0.8c2.3-0.3,4.1,0,5.5,0.9c1.4,0.9,2.2,2.4,2.5,4.5c0.2,1.2,0,2.4-0.4,3.4c-0.5,1-1.2,1.9-2.2,2.6
									c2.3,3.4,4,5.5,5.1,6.3c-0.6,0.4-1.2,0.7-1.8,0.9c-0.6,0.2-1.3,0.4-2.1,0.5c-0.7-1.2-2-3.4-4.2-6.4c-0.1,0-0.3,0.1-0.6,0.1
									c-0.5,0.1-0.9,0.1-1.5,0.1c-0.5,0-1,0-1.3,0c0.5,3.5,1,5.8,1.5,6.8c-0.6,0.2-1.2,0.4-1.9,0.5C2309.4,696,2308.8,696,2308,696z
									M2315.6,684.5c0.4-0.8,0.6-1.7,0.4-2.7c-0.4-2.9-2.1-4.1-5.2-3.7c-0.7,0.1-1.3,0.3-1.8,0.5c0.1,1.8,0.4,4.3,0.8,7.5
									c1.7,0.4,2.9,0.5,3.8,0.4C2314.6,686,2315.2,685.4,2315.6,684.5z"
						/>
						<path
							d="M2327,693.8c-0.3-0.5-0.4-1.2-0.3-2.4c0-0.9,0.1-2.2,0.2-3.9c0.1-1.6,0.2-2.7,0.2-3.5c0-1,0-1.9-0.1-2.8
									c-0.1-0.9-0.2-1.5-0.5-1.8c0.6-0.3,1.3-0.4,1.8-0.5c0.6-0.1,1.2-0.1,2-0.1c-0.1,0.7-0.3,1.8-0.4,3.3c-0.1,1.5-0.2,3.1-0.3,5
									c0,1.4,0,2.4,0,3.1c0.1,0.7,0.2,1.3,0.3,1.7c0.2,0.4,0.4,0.9,0.8,1.3c-0.4,0.4-0.8,0.8-1.2,1c-0.4,0.2-0.8,0.3-1.2,0.3
									C2327.8,694.5,2327.3,694.2,2327,693.8z M2326.5,674.1c0.7-0.9,1.4-1.6,2.2-2.2c0.6,0.9,1.3,1.6,2,2.3c-0.7,0.8-1.4,1.6-2.2,2.2
									C2328,675.6,2327.3,674.9,2326.5,674.1z"
						/>
						<path
							d="M2339.6,694.7c-0.4-0.6-0.7-1.4-0.9-2.4c-0.2-0.9-0.4-2.2-0.6-3.8c-0.3-2-0.6-3.6-0.8-4.9c-0.3-1.3-0.7-2.4-1.3-3.4
									c0.7-0.2,1.3-0.3,1.9-0.3c0.6,0,1.3,0,2,0.1c0,1.3,0.3,3.7,0.8,7c0.5,3.3,1,5.5,1.5,6.7c1.6-3.6,2.8-6.2,3.4-7.8
									c0.6-1.6,1-2.8,1.1-3.5c0.1-0.4,0-0.7,0-1c0.9-0.1,2-0.1,3.1,0c-1.8,3.2-3.8,7.7-6.2,13.5l-2.3,1
									C2340.5,695.7,2340,695.3,2339.6,694.7z"
						/>
						<path
							d="M2352.6,695.9c-0.7-1.5-0.7-3.4-0.2-5.7c0.4-1.6,1-2.9,1.8-4s1.9-1.9,3.1-2.3c1.2-0.5,2.5-0.5,3.8-0.2
									c1.8,0.4,3,1.3,3.6,2.8c0.6,1.5,0.7,3.4,0.1,5.9c-1.2,0-2.7,0-4.5-0.2c-1.7-0.2-3.4-0.4-5-0.7c-0.2,1.5-0.1,2.6,0.4,3.4
									c0.5,0.8,1.3,1.3,2.5,1.6c0.9,0.2,1.8,0.3,2.7,0.2c0.9,0,1.6-0.2,2.3-0.5l0.1,0.7c-0.9,0.8-2,1.4-3.3,1.7s-2.5,0.4-3.7,0.1
									C2354.5,698.3,2353.2,697.3,2352.6,695.9z M2359,690.4c1.1,0,2-0.1,2.7-0.3c0.3-1.3,0.2-2.4-0.3-3.4s-1.2-1.6-2.2-1.8
									c-0.8,0.3-1.5,0.9-2.2,1.9s-1.1,2.1-1.4,3.5C2356.7,690.3,2357.8,690.4,2359,690.4z"
						/>
						<path
							d="M2369.7,701.7c0.8-2.3,1.5-4.9,2.2-7.5c0.3-1.3,0.6-2.3,0.7-3c0.1-0.7,0.1-1.3,0.1-1.7c-0.1-0.4-0.2-0.9-0.5-1.5
									c0.5-0.3,0.9-0.6,1.4-0.7s0.9-0.1,1.2,0c1.3,0.3,1.7,1.4,1.3,3.2c0.8-0.6,1.5-1.1,2.3-1.4c0.8-0.3,1.6-0.5,2.5-0.5
									c0.1,0.4,0.1,1,0,1.7c-0.1,0.7-0.2,1.4-0.3,2c-0.3-0.1-0.6-0.2-1.1-0.4c-0.7-0.2-1.4-0.3-2.1-0.3s-1.3,0.1-1.7,0.3l-0.3,1.1
									c-0.4,1.6-0.8,2.9-1.1,4.2c-0.6,2.5-0.9,4.3-0.7,5.5c-0.7,0-1.3-0.1-1.9-0.3C2371,702.2,2370.4,702,2369.7,701.7z"
						/>
						<path
							d="M2395.4,707.8c0.3-1.2,0.7-2.8,1.2-5c0.5-2.2,0.9-4.1,1.2-5.9c0.7-4,1-6.5,0.7-7.5c0.7-0.2,1.7-0.2,2.9-0.2
									c1.2,0,2.3,0.1,3.5,0.3c2.2,0.4,3.9,1.3,4.9,2.6c1,1.3,1.3,3,1,5.1c-0.2,1.2-0.7,2.3-1.5,3.1c-0.8,0.8-1.7,1.5-2.9,1.8
									c1.1,4,2.1,6.5,2.9,7.6c-0.7,0.2-1.3,0.3-2,0.3c-0.7,0-1.4-0.1-2.2-0.2c-0.2-1.4-0.9-3.8-2-7.4c-0.1,0-0.3,0-0.6-0.1
									c-0.4-0.1-0.9-0.2-1.5-0.3c-0.5-0.1-0.9-0.3-1.2-0.4c-0.6,3.5-0.8,5.8-0.7,6.9c-0.6,0-1.3,0-1.9-0.1
									C2396.7,708.2,2396.1,708,2395.4,707.8z M2406.2,699.3c0.7-0.7,1.1-1.5,1.3-2.4c0.5-2.9-0.7-4.6-3.7-5.1c-0.7-0.1-1.3-0.1-1.9-0.1
									c-0.4,1.7-0.9,4.2-1.6,7.4c1.5,0.9,2.6,1.4,3.5,1.6C2404.7,700.4,2405.5,699.9,2406.2,699.3z"
						/>
						<path
							d="M2413.6,706.2l0.5-2.3l0.6-2.4c0.5-2.4,0.6-4.1,0.4-5.2c1.2-0.2,2.5-0.2,3.8,0.1c-0.8,2.9-1.5,6.3-2.3,10.1
									c-0.2,1-0.2,1.7,0.1,2.2c0.3,0.5,0.9,0.9,1.7,1.1c1.1,0.2,2.1,0.2,3.2-0.2c0.3-1.6,0.7-3,1-4.3l0.5-2c0.5-2.4,0.6-4.1,0.4-5.2
									c1.2-0.2,2.5-0.2,3.9,0.1c-0.4,1.6-0.9,3.6-1.5,5.8c-0.6,2.3-0.9,3.8-1.1,4.5c-0.2,0.9-0.2,1.6-0.2,2.1c0.1,0.5,0.3,1.2,0.7,1.8
									c-1,0.6-1.9,0.8-3,0.7c-0.6-0.6-0.8-1.5-0.8-2.4c-0.7,0.5-1.6,0.9-2.5,1.1s-1.8,0.2-2.6,0C2413.9,711.5,2412.9,709.6,2413.6,706.2
									z"
						/>
						<path
							d="M2429.8,714.5c0.7-2.3,1.5-4.9,2.1-7.6c0.3-1.3,0.5-2.3,0.6-3c0.1-0.7,0.1-1.3,0-1.7c-0.1-0.4-0.2-0.9-0.5-1.5
									c0.5-0.4,0.9-0.6,1.4-0.7c0.4-0.1,0.8-0.1,1.2,0c1.2,0.3,1.7,1.2,1.5,2.6c0.8-0.6,1.6-1,2.5-1.2c0.9-0.2,1.7-0.2,2.6,0
									c2.6,0.6,3.5,2.6,2.7,5.9l-0.6,2.2c-0.3,0.9-0.5,1.7-0.7,2.4c-0.6,2.5-0.8,4.3-0.6,5.5c-0.7,0-1.3-0.1-1.8-0.2
									c-0.6-0.1-1.2-0.4-1.9-0.7c0.9-2.8,1.7-6,2.6-9.3c0.2-1,0.2-1.7,0-2.2c-0.3-0.5-0.8-0.9-1.6-1.1c-1.2-0.3-2.3-0.3-3.2,0.1
									l-0.4,1.5c-0.4,1.2-0.8,2.6-1.2,4.3c-0.6,2.5-0.8,4.3-0.7,5.5c-0.7,0-1.3-0.1-1.9-0.3C2431.1,715.1,2430.5,714.8,2429.8,714.5z"
						/>
						<path
							d="M2447.1,719.1c0.8-2.3,1.5-4.8,2.2-7.5c0.3-1.3,0.6-2.3,0.7-3c0.1-0.7,0.1-1.3,0.1-1.7c-0.1-0.4-0.2-0.9-0.5-1.5
									c0.5-0.4,0.9-0.6,1.4-0.7c0.5-0.1,0.8-0.1,1.2,0c1.2,0.3,1.7,1.2,1.4,2.7c0.8-0.6,1.6-0.9,2.5-1.1s1.7-0.2,2.6,0
									c2.6,0.7,3.4,2.7,2.6,5.9l-0.7,2.2c-0.3,0.9-0.5,1.6-0.7,2.4c-0.7,2.4-0.9,4.3-0.7,5.5c-0.7,0-1.3-0.1-1.8-0.3
									c-0.6-0.2-1.2-0.4-1.9-0.7c0.9-2.8,1.8-5.9,2.7-9.3c0.3-0.9,0.3-1.7,0-2.2c-0.2-0.5-0.8-0.9-1.6-1.1c-1.2-0.3-2.3-0.3-3.2,0
									l-0.4,1.5c-0.4,1.2-0.8,2.6-1.2,4.3c-0.7,2.5-0.9,4.3-0.8,5.5c-0.7,0-1.3-0.1-1.9-0.3C2448.4,719.7,2447.8,719.4,2447.1,719.1z"
						/>
						<path
							d="M2464.9,723.2c-0.2-0.5-0.1-1.3,0.1-2.4c0.2-0.9,0.5-2.1,0.9-3.8c0.4-1.5,0.7-2.6,0.9-3.4c0.2-1,0.4-1.9,0.5-2.7
									s0.1-1.5-0.1-1.9c0.7-0.1,1.3-0.2,1.9-0.1c0.6,0,1.2,0.1,2,0.3c-0.3,0.7-0.6,1.7-1,3.1c-0.4,1.4-0.8,3-1.2,4.8
									c-0.3,1.3-0.5,2.3-0.6,3c-0.1,0.7-0.1,1.3,0,1.7c0.1,0.4,0.2,0.9,0.5,1.4c-0.5,0.3-0.9,0.6-1.4,0.7c-0.5,0.1-0.9,0.1-1.2,0.1
									C2465.4,724.1,2465,723.7,2464.9,723.2z M2468.2,703.9c0.9-0.7,1.7-1.3,2.5-1.8c0.4,1,0.9,1.9,1.5,2.7c-0.8,0.7-1.7,1.3-2.6,1.7
									C2469.3,705.6,2468.8,704.7,2468.2,703.9z"
						/>
						<path
							d="M2473.2,725.5c0.7-2.4,1.4-4.9,2-7.6c0.3-1.3,0.5-2.3,0.6-3c0.1-0.7,0.1-1.3,0-1.7c-0.1-0.4-0.3-0.9-0.5-1.4
									c0.5-0.4,0.9-0.6,1.4-0.7c0.4-0.1,0.8-0.1,1.2-0.1c1.2,0.3,1.7,1.1,1.5,2.6c0.7-0.6,1.6-1,2.4-1.2c0.9-0.2,1.7-0.2,2.6-0.1
									c2.6,0.6,3.5,2.5,2.8,5.8l-0.6,2.2c-0.3,0.9-0.5,1.7-0.6,2.4c-0.6,2.5-0.7,4.3-0.5,5.6c-0.7,0-1.3-0.1-1.8-0.2
									c-0.6-0.1-1.2-0.4-2-0.7c0.8-2.9,1.6-6,2.4-9.4c0.2-1,0.2-1.7-0.1-2.2c-0.3-0.5-0.8-0.8-1.6-1c-1.2-0.3-2.3-0.2-3.2,0.1l-0.4,1.5
									c-0.3,1.2-0.7,2.6-1.1,4.3c-0.6,2.5-0.8,4.4-0.6,5.5c-0.7,0-1.3-0.1-2-0.2C2474.6,726,2474,725.8,2473.2,725.5z"
						/>
						<path
							d="M2487.5,733.9c0.2-0.9,0.6-1.7,1.1-2.4c0.5-0.7,1.1-1.4,1.8-1.9c-0.8-0.6-1-1.3-0.8-2.3c0.2-0.7,0.5-1.2,1.1-1.6
									s1.3-0.7,2.2-0.8c-0.7-0.6-1.1-1.3-1.3-2.2c-0.2-0.9-0.2-1.8,0.1-2.9c0.4-1.7,1.3-2.9,2.7-3.7c1.3-0.8,2.9-0.9,4.6-0.5
									c1.1,0.3,2,0.8,2.8,1.5c0.5,0,1-0.1,1.7-0.3c0.7-0.2,1.3-0.5,2-0.9c0.1,0.5,0.2,1,0.2,1.6s0,1.1-0.1,1.4c-0.8,0.2-1.7,0.2-2.8,0.1
									c0.2,1,0.2,2,0,2.9c-0.3,1.1-0.8,2-1.5,2.7s-1.6,1.2-2.6,1.5c-1,0.2-2.1,0.2-3.3-0.1c-1-0.2-1.7-0.3-2.4-0.1
									c-0.6,0.2-1,0.5-1.2,1.1l6.1,1.9c1.2,0.4,2,1,2.5,1.7s0.6,1.6,0.3,2.7c-0.2,1-0.8,1.7-1.6,2.4c-0.8,0.6-1.8,1-2.9,1.2
									c-1.2,0.2-2.4,0.1-3.7-0.2C2490.3,736.3,2488.6,735.3,2487.5,733.9z M2496.9,734.9c0.8-0.2,1.3-0.6,1.5-1.2
									c0.1-0.5,0.1-0.9-0.2-1.2c-0.3-0.3-0.8-0.6-1.5-0.8l-4.9-1.5c-0.5,0.6-0.9,1.2-1.2,1.8c0.5,1.5,1.6,2.4,3.3,2.8
									C2495.1,735,2496.1,735.1,2496.9,734.9z M2498.5,724.5c0.7-0.4,1.1-1.1,1.3-2c0.2-0.9,0.2-1.7-0.2-2.4c-0.3-0.7-0.9-1.3-1.7-1.7
									c-0.9-0.2-1.7-0.1-2.5,0.4c-0.7,0.5-1.2,1.2-1.5,2.2c-0.2,0.9-0.2,1.7,0.2,2.5s1,1.2,1.8,1.4
									C2497.1,725,2497.9,724.9,2498.5,724.5z"
						/>
						<path
							d="M2511.4,717.9c0.4,2.5,0.2,5.1-0.6,7.8s-1.9,5.1-3.5,7c-1.6,2-3.5,3.6-5.7,4.9l-0.3-0.4c1.8-1.3,3.3-3,4.5-4.9
									c1.1-1.9,2.1-4.3,2.9-7.1s1.1-5.4,1.1-7.6c0-2.2-0.5-4.4-1.4-6.5l0.4-0.3C2510.2,713,2511,715.4,2511.4,717.9z"
						/>
					</g>
					<g className="level_zero location " id="gwathlo">
						<rect x="2101.305" y="1675.32" width="136.442" height="36.853" transform="matrix(0.749185, -0.662361, 0.626515, 0.780877, -1586.750488, 1151.379395)"></rect>
						<rect x="1835.226" y="1896.587" width="119.165" height="41.72" transform="matrix(0.718134, -0.695904, 0.661544, 0.751431, -1618.200439, 1006.077271)"></rect>
						<path
							d="M969.5,1165.1c-1.9-0.7-3.5-2.3-5-4.8c-1-1.8-1.7-3.5-1.9-5.2c-0.2-1.7,0-3.3,0.6-4.7c0.6-1.4,1.6-2.5,3-3.4
									c1.6-1,3.2-1.5,4.6-1.7c0.3,0.4,0.5,1,0.7,1.6c0.2,0.7,0.3,1.2,0.3,1.7c-1.2-0.1-2.3-0.1-3.5,0c-1.1,0.1-2,0.3-2.5,0.7
									c-0.5,0.5-0.8,1.3-0.9,2.2c-0.1,1,0,2,0.2,3.2s0.7,2.2,1.3,3.2c1.4,2.3,2.7,3.8,4.1,4.4s2.8,0.4,4.5-0.6c0.7-0.4,1.4-0.9,1.8-1.5
									c-2-3.4-3.4-5.3-4.1-6c0.4-0.4,0.8-0.7,1.2-1c0.4-0.3,1-0.5,1.6-0.7c1.1,2.2,2.3,4.4,3.6,6.6c-0.2,1-0.7,2-1.4,2.9
									c-0.7,1-1.6,1.8-2.6,2.3C973.2,1165.5,971.3,1165.8,969.5,1165.1z"
						/>
						<path
							d="M985.5,1157.4l-2.6-3.7c-0.6-0.9-1.1-1.7-1.6-2.3c-0.6-0.8-1.2-1.6-1.8-2.2c-0.6-0.6-1-1-1.4-1.2c0.4-0.4,0.9-0.8,1.3-1.1
									c0.5-0.3,1-0.6,1.7-0.8c0.5,0.9,1.7,2.8,3.7,5.6s3.2,4.5,3.6,5c-0.4-5.5-0.7-8.9-0.9-10.2c-0.7-0.8-1.2-1.2-1.6-1.5
									c0.8-0.8,1.8-1.5,3.1-2c0.5,0.9,1.7,2.9,3.8,5.8c2,3,3.2,4.6,3.6,4.9c-0.3-4-0.5-6.8-0.7-8.4c-0.2-1.6-0.4-2.7-0.6-3.3
									c-0.1-0.2-0.2-0.4-0.4-0.7c0.6-0.5,1.5-1,2.5-1.4c0,1.6,0,3.7,0.2,6.3c0.1,2.6,0.4,5.4,0.7,8.4l-1.2,1.6c-0.6,0.2-1.2,0.2-1.7-0.1
									c-0.6-0.3-1.1-0.9-1.7-1.7c-1.9-2.6-3.2-4.5-3.9-5.6c0.1,2.2,0.4,5.1,0.7,8.8l-1.2,1.6C987.8,1159.6,986.6,1159,985.5,1157.4z"
						/>
						<path
							d="M1009,1150.4c-0.8-0.2-1.4-0.8-2-1.6c-1.6-2.4-0.5-5.1,3.3-8.2c-0.9-1.3-1.7-2.1-2.4-2.4c-0.7-0.3-1.5-0.1-2.3,0.5
									c-0.5,0.4-1,0.8-1.4,1.4c-0.4,0.6-0.7,1.2-0.9,1.8l-0.6-0.4c-0.1-1.2,0-2.3,0.4-3.4c0.4-1.1,1-1.9,1.9-2.5c1.1-0.8,2.2-1,3.3-0.7
									c1.1,0.3,2,1.1,2.9,2.3c0.5,0.7,1,1.5,1.5,2.5c0.6,1,1,1.7,1.3,2.2c0.5,0.8,1,1.3,1.4,1.6c0.4,0.3,1,0.6,1.7,0.8
									c-0.2,1.1-0.6,1.9-1.3,2.6c-0.7,0-1.4-0.4-2.1-1c-0.3,0.9-0.7,1.8-1.1,2.5c-0.5,0.8-1,1.3-1.5,1.7
									C1010.5,1150.5,1009.8,1150.6,1009,1150.4z M1012.2,1146.6c0.3-0.6,0.6-1.1,0.7-1.7l-0.1-0.1c-0.4-0.6-0.8-1.3-1.2-1.9l-0.8-1.3
									c-1.1,0.9-1.8,1.8-2.1,2.7c-0.3,0.9-0.2,1.8,0.4,2.6c0.2,0.3,0.5,0.6,0.9,0.8c0.4,0.2,0.8,0.3,1.1,0.3
									C1011.6,1147.7,1011.9,1147.2,1012.2,1146.6z"
						/>
						<path
							d="M1025.2,1137.4c-0.5,0-1.2-0.4-2-1.1c-0.6-0.5-1.5-1.3-2.7-2.4c-1.4-1.2-2.5-2.2-3.4-3c-0.4-0.4-0.9-0.7-1.3-1
									c-0.5,0.6-0.9,1-1.2,1.1c-0.3,0.1-0.6,0.1-0.8-0.1c0.2-1.6,0.1-3.4-0.3-5.3l0.6-0.5c0.7,0.5,1.4,1,2,1.5c0.9-1.1,1.5-1.9,1.7-2.5
									c0.4,0.3,0.7,0.6,0.9,1c0.2,0.3,0.5,0.7,0.6,1.1c-0.6,0.3-1.3,0.9-2,1.7c0.8,0.7,1.6,1.5,2.5,2.2c2.3,1.9,4.1,3.2,5.5,4
									c0.6-0.8,1-1.4,1.2-2l0.8,0.4c0.3,0.7,0.4,1.5,0.2,2.2c-0.1,0.8-0.4,1.4-0.8,1.9C1026.2,1137.2,1025.7,1137.5,1025.2,1137.4z"
						/>
						<path
							d="M1032.2,1128.1c-2.5-1.8-5-3.5-7.6-5.2c-3.5-2.3-6.3-3.9-8.4-5c0.1-1.1,0.6-2.2,1.2-3.3c0.9,0.7,2.8,2.1,5.6,4.1
									c-0.3-0.9-0.4-1.7-0.4-2.6s0.3-1.6,0.7-2.3c1.3-2,3.4-2.2,6.1-0.4l1.9,1.3l2,1.4c2.1,1.4,3.7,2.2,4.9,2.4
									c-0.2,0.6-0.4,1.1-0.7,1.5c-0.3,0.4-0.7,0.9-1.2,1.4c-2.8-2-5.4-3.7-7.8-5.3c-0.8-0.5-1.5-0.7-2-0.7c-0.5,0.1-1,0.4-1.4,1.1
									c-0.6,0.9-0.9,1.9-0.8,3c0.6,0.4,1.4,1,2.4,1.7c3.8,2.4,6.2,3.7,7.3,3.9c-0.2,0.5-0.4,1.1-0.7,1.6
									C1033.1,1127.2,1032.7,1127.6,1032.2,1128.1z"
						/>
						<path
							d="M1041.6,1113.1c-0.5,0-1.2-0.3-2.1-1c-0.6-0.4-1.6-1.1-2.9-2c-1.5-1-2.7-1.9-3.7-2.5c-3.2-2.1-5.6-3.5-7.3-4
									c0.1-0.6,0.2-1.2,0.4-1.7c0.2-0.5,0.5-1,0.8-1.6c2.4,1.8,5.8,4.3,10.5,7.3c1.1,0.7,1.9,1.2,2.6,1.6c0.6,0.3,1.2,0.5,1.6,0.6
									c0.4,0.1,0.9,0.1,1.5,0c0.2,0.5,0.3,1,0.2,1.4c0,0.4-0.1,0.8-0.3,1.1C1042.5,1112.9,1042,1113.1,1041.6,1113.1z"
						/>
						<path
							d="M1033.2,1093.5c0.1-0.9,0.1-1.7-0.2-2.6c-0.2-0.9-0.6-1.6-1-2.1c0.4-0.6,0.9-1.1,1.3-1.3c0.5-0.3,1-0.3,1.6-0.3
									c0.5,1.9,0.2,4-0.9,6.5L1033.2,1093.5z M1045.5,1104.7c-1.7,0.1-3.4-0.5-5.3-1.7c-1.3-0.9-2.3-1.9-3-3.1c-0.7-1.2-1-2.4-1-3.6
									c0-1.2,0.4-2.4,1.1-3.5c0.7-1,1.5-1.6,2.5-2c1-0.4,2.1-0.5,3.3-0.3c1.2,0.2,2.4,0.7,3.6,1.5c1.3,0.9,2.3,1.9,3,3.1
									c0.7,1.2,1,2.4,1,3.7c0,1.3-0.4,2.4-1.2,3.6C1048.5,1103.9,1047.2,1104.6,1045.5,1104.7z M1048.9,1098.1c-0.4-1.1-1.3-2.1-2.7-3
									c-1.2-0.8-2.5-1.3-3.7-1.4c-1.2-0.1-2.3,0.2-3.2,0.8c-0.7,1-0.8,2.1-0.4,3.2c0.4,1.1,1.2,2.1,2.6,3c1.5,1.1,3,1.6,4.2,1.7
									c1.3,0.1,2.2-0.3,2.8-1.2C1049.2,1100.2,1049.3,1099.2,1048.9,1098.1z"
						/>
						<path
							d="M1047.8,1069.3l0.5-0.1c0.3,2.2,1.1,4.1,2.2,5.8c1.1,1.7,2.7,3.4,4.8,5c2.1,1.6,4.1,2.8,6,3.5c1.9,0.7,4,1,6.2,0.8l0,0.5
									c-2.5,0.5-4.9,0.5-7.2,0s-4.5-1.5-6.5-3.1s-3.5-3.4-4.5-5.6C1048.4,1074.1,1047.9,1071.8,1047.8,1069.3z"
						/>
						<path
							d="M1063.3,1079.8c-1.9-0.1-3.8-1-5.9-2.8c-1.5-1.3-2.5-2.6-3.3-4.1c-0.7-1.5-1-2.9-0.9-4.4c0.1-1.4,0.7-2.7,1.7-3.9
									c1.2-1.3,2.4-2.3,3.6-2.9c0.4,0.3,0.7,0.7,1.1,1.3c0.4,0.5,0.6,1,0.8,1.4c-1.1,0.2-2.1,0.6-3.1,1c-1,0.5-1.7,0.9-2.1,1.3
									c-0.3,0.6-0.3,1.4-0.1,2.3s0.6,1.8,1.2,2.8c0.6,0.9,1.3,1.8,2.1,2.5c1.9,1.7,3.6,2.6,5,2.7s2.6-0.5,3.8-1.9
									c0.5-0.6,0.9-1.3,1.2-1.9c-2.8-2.4-4.6-3.8-5.5-4.1c0.2-0.5,0.5-0.9,0.8-1.2c0.3-0.4,0.7-0.7,1.2-1.1c1.6,1.6,3.4,3.2,5.2,4.9
									c0.1,0.9,0,2-0.4,3.1c-0.4,1.1-0.9,2-1.6,2.9C1066.8,1079.1,1065.2,1079.9,1063.3,1079.8z"
						/>
						<path
							d="M1075.7,1068.8c-1.5-1.7-3.2-3.4-5.1-5.1c-0.9-0.9-1.6-1.5-2.1-1.9c-0.5-0.4-1-0.7-1.4-0.8s-0.8-0.3-1.4-0.3
									c-0.1-0.5-0.1-1,0-1.4c0.1-0.4,0.3-0.7,0.5-0.9c0.8-0.8,1.8-0.7,3,0.5c-0.2-0.9-0.2-1.7-0.2-2.4s0.3-1.5,0.6-2.1
									c0.4,0.2,0.8,0.4,1.4,0.8c0.6,0.4,1.1,0.8,1.5,1.2c-0.2,0.1-0.4,0.4-0.7,0.7c-0.4,0.5-0.8,0.9-1.1,1.5c-0.3,0.5-0.4,1-0.5,1.4
									l0.7,0.7c1.1,1.1,2,2,2.8,2.8c1.7,1.6,3.1,2.7,4.1,3.1c-0.3,0.5-0.6,1-1,1.3C1076.7,1068,1076.2,1068.4,1075.7,1068.8z"
						/>
						<path
							d="M1082.2,1060c-1.4-0.3-2.9-1.2-4.5-2.7c-1.1-1.1-1.8-2.2-2.3-3.4c-0.5-1.2-0.6-2.3-0.4-3.4c0.2-1.1,0.7-2.1,1.5-2.9
									c1.1-1.1,2.4-1.5,3.8-1.2c1.4,0.3,2.9,1.2,4.6,2.9c-0.5,0.9-1.2,2-2.1,3.2c-0.9,1.2-1.8,2.3-2.8,3.3c1.1,0.9,2,1.4,2.8,1.4
									c0.8,0.1,1.6-0.3,2.3-1c0.6-0.6,1-1.2,1.4-1.8c0.4-0.7,0.6-1.3,0.7-1.9l0.6,0.3c0.2,1.1,0.1,2.2-0.2,3.3c-0.3,1.1-0.9,2-1.6,2.8
									C1084.9,1059.9,1083.6,1060.3,1082.2,1060z M1080.8,1052.6c0.5-0.8,0.8-1.6,1-2.2c-0.9-0.8-1.8-1.3-2.7-1.5
									c-1-0.1-1.7,0.1-2.4,0.7c-0.1,0.7,0,1.6,0.5,2.5c0.4,1,1.1,1.9,2,2.8C1079.8,1054.2,1080.4,1053.4,1080.8,1052.6z"
						/>
						<path
							d="M1096.1,1054.9c-1.3-1.4-2-3.3-2-5.7c-1.2,0.2-2.6-0.5-4-2l-1.1-1.3l-1.3-1.5c-1.6-1.7-2.8-2.8-3.7-3.2
									c0.2-0.6,0.5-1,0.8-1.4s0.7-0.8,1.1-1.3c0.8,1,2.8,3.3,5.9,6.7c0.6,0.7,1.2,1,1.7,1.1c0.5,0.1,1-0.1,1.5-0.6
									c0.8-0.8,1.3-1.6,1.5-2.6l-2.7-2.9c-0.3-0.3-0.5-0.5-0.8-0.9c-0.7-0.8-1.4-1.4-2-2c-0.6-0.5-1.2-0.9-1.6-1.1c0.4-1,1-1.9,1.9-2.7
									c1.1,1.4,3,3.6,5.6,6.4c1,1.1,1.9,2.1,2.7,2.8c0.7,0.7,1.2,1.2,1.3,1.3c1.4,1.5,2,3,1.9,4.5s-0.9,2.9-2.2,4.2
									C1099.1,1054,1097.6,1054.8,1096.1,1054.9z M1100.6,1048.6c-0.1-0.9-0.8-2.1-2.1-3.5l-1.1-1.2c0,0.9-0.1,1.7-0.3,2.4
									c-0.3,0.7-0.7,1.4-1.3,1.9c-0.1,0.1-0.4,0.3-0.6,0.5c0.2,1.3,0.5,2.4,1.1,3.3c0.6,0.1,1.1,0,1.7-0.1c0.6-0.2,1.1-0.5,1.5-0.9
									C1100.3,1050.4,1100.7,1049.6,1100.6,1048.6z"
						/>
						<path
							d="M1107.5,1044.4c-0.1-0.8-0.2-1.5-0.4-2.1c-0.2-0.6-0.6-1.3-1.1-2.1c-0.6-0.8-1.4-1.9-2.5-3.2c-1.3-1.5-3.6-4.1-7-7.7
									c-0.6,0.5-1,0.7-1.3,0.8c-0.3,0.1-0.5,0-0.8-0.3c0.3-1,0.5-1.7,0.6-2.2c-1.3-1.8-1.9-3.5-2-5.1c-0.1-1.5,0.6-2.9,2.1-4.2
									c0.7-0.6,1.5-1.2,2.3-1.5c0.8-0.4,1.8-0.7,2.8-1c1.6,2.2,4.4,5.7,8.5,10.5c0.9,1.1,1.6,1.8,2.2,2.3c0.5,0.5,1,0.9,1.4,1.1
									s0.9,0.4,1.5,0.5c0,0.6,0,1.1-0.2,1.5c-0.2,0.4-0.4,0.8-0.6,1c-0.5,0.4-1,0.6-1.5,0.4c-0.5-0.2-1.1-0.7-1.9-1.6l-2.4-2.9
									c-1.2-1.5-2.2-2.7-3-3.6c-2.2-2.6-4-4.5-5.4-5.7c-1.9,0.2-3.3,0.8-4.2,1.6c-0.1,0.5,0.1,1.1,0.5,1.8c0.4,0.7,1.2,1.8,2.3,3.2
									c1.4-1.2,2.3-2.1,2.7-2.7c0.3,0.4,0.6,0.8,0.8,1.1c0.2,0.4,0.4,0.8,0.5,1.3c-0.8,0.3-1.7,0.9-2.8,1.8l1.7,2c1,1.2,2.2,2.5,3.6,3.9
									c0.7,0.8,1.4,1.4,1.9,2c0.8,1,1.4,1.8,1.8,2.6c0.4,0.8,0.6,1.7,0.7,2.7c0.1,1,0,2.3-0.2,3.8L1107.5,1044.4z"
						/>
						<path
							d="M1117.7,1026.7c-1.3-0.3-2.5-1-3.5-2.3c-0.7-0.9-1.2-1.9-1.4-3c-0.2-1-0.1-2,0.2-3c0.4-0.9,1-1.8,1.8-2.4
									c0.8-0.6,1.6-1,2.5-1.1c0.9-0.1,1.7,0,2.6,0.4c0.8,0.4,1.6,1,2.3,1.9c0.7,0.9,1.2,1.9,1.4,3c0.2,1,0.1,2-0.3,3s-1,1.8-1.9,2.5
									C1120.2,1026.6,1119,1027,1117.7,1026.7z M1122.1,1022.3c0-0.9-0.4-1.8-1.2-2.8c-0.7-0.9-1.5-1.5-2.4-1.8c-0.9-0.3-1.8-0.3-2.7,0
									c-0.8,0.6-1.2,1.4-1.2,2.3c0,0.9,0.3,1.9,1.1,2.8c0.9,1.1,1.8,1.8,2.8,2.1c1,0.3,1.8,0.2,2.5-0.4
									C1121.8,1023.9,1122.1,1023.2,1122.1,1022.3z"
						/>
						<path
							d="M1128.6,1018.1c-1.3-0.3-2.5-1.1-3.4-2.4c-0.7-1-1.2-2-1.3-3c-0.2-1-0.1-2,0.3-3s1-1.7,1.9-2.4c0.8-0.6,1.6-0.9,2.5-1
									c0.9-0.1,1.7,0.1,2.6,0.5c0.8,0.4,1.6,1.1,2.2,2c0.7,1,1.2,2,1.3,3c0.2,1,0,2-0.4,3s-1.1,1.8-2,2.4
									C1131.1,1018.1,1129.9,1018.4,1128.6,1018.1z M1133.2,1013.9c0-0.9-0.3-1.9-1.1-2.9c-0.7-0.9-1.5-1.5-2.4-1.9
									c-0.9-0.4-1.8-0.4-2.7-0.1c-0.8,0.6-1.2,1.4-1.3,2.3c0,0.9,0.3,1.9,1,2.9c0.9,1.1,1.8,1.9,2.7,2.2c0.9,0.3,1.8,0.2,2.5-0.3
									C1132.7,1015.5,1133.2,1014.8,1133.2,1013.9z"
						/>
						<path
							d="M1139.6,1010c-1.2-0.5-2.4-1.6-3.6-3.3c-1.2-1.8-1.9-3.3-1.9-4.6c0-1.3,0.6-2.4,1.7-3.2c0.9-0.7,2-0.9,3.3-0.8
									c-1.2-1.6-2.2-2.7-3-3.4c0.3-0.5,0.6-0.9,0.9-1.2s0.7-0.6,1.1-1c1.3,2.2,3.6,5.7,7,10.6c0.4,0.6,0.8,1,1.1,1.2
									c0.3,0.3,0.8,0.5,1.4,0.6c-0.1,0.8-0.5,1.5-1.1,2.1c-0.6-0.1-1.2-0.3-1.8-0.8c-0.1,0.7-0.3,1.4-0.6,1.9c-0.3,0.6-0.7,1-1.2,1.4
									C1142,1010.3,1140.8,1010.4,1139.6,1010z M1143.6,1006.8c0.2-0.4,0.4-0.9,0.5-1.4c-0.4-0.5-0.9-1.4-1.8-2.6
									c-0.9-1.4-1.6-2.4-2.2-3.2c-0.8,0-1.6,0.2-2.4,0.8c-0.7,0.5-1.1,1.2-1.1,2.1c0,0.9,0.4,1.9,1.2,3c0.8,1.1,1.6,1.9,2.4,2.3
									c0.8,0.4,1.6,0.5,2.4,0.1C1143.1,1007.6,1143.4,1007.2,1143.6,1006.8z"
						/>
						<path
							d="M1146.9,990.1c2,1.1,3.7,2.7,5.1,4.8s2.2,4.3,2.4,6.6c0.3,2.3,0,4.6-0.7,7l-0.5-0.1c0.4-2.1,0.3-4.2-0.2-6.1
									c-0.5-1.9-1.5-4-2.9-6.1c-1.4-2.2-2.9-3.9-4.5-5.1s-3.4-2.1-5.5-2.6l0.1-0.5C1142.6,988.3,1144.9,989,1146.9,990.1z"
						/>
					</g>
					<g className="level_two fade location" id="lond_daer">
						<rect className="level_zero location" x="928" y="1203.6" width="14.5" height="12.5" />
						<rect x="1311.835" y="2285.9" width="85.181" height="50.284" transform="matrix(0.9998, -0.019992, -0.027846, 1.000757, -321.495575, -1066.864502)"></rect>
						<path
							d="M948.5,1210.4c0-1,0-2.4,0-4.3c0-3.9-0.2-6.3-0.5-7.2c0.5-0.1,1.1-0.2,1.7-0.2c0.5,0,1.1,0.1,1.8,0.2
									c-0.1,1.1-0.2,2.6-0.2,4.5c-0.1,1.8-0.1,3.5-0.1,5.1c0,2.2,0,3.7,0.1,4.6c0.9,0.2,2,0.3,3.2,0.3c0.7,0,1.5-0.1,2.2-0.2
									c0.7-0.2,1.3-0.4,1.8-0.6c0,0.6-0.1,1.3-0.2,1.9s-0.3,1.1-0.5,1.3c-1.3-0.1-2.9-0.2-4.7-0.2c-2.2,0-3.8,0.1-4.8,0.2
									C948.4,1213.2,948.5,1211.4,948.5,1210.4z"
						/>
						<path
							d="M962.9,1214.4c-1-1.2-1.5-2.7-1.5-4.7c0-1.4,0.3-2.6,0.8-3.7c0.6-1.1,1.3-1.9,2.3-2.5s2.1-0.9,3.4-0.9
									c1.1,0,2.1,0.3,3,0.8c0.9,0.5,1.5,1.3,2,2.3c0.5,1,0.7,2.1,0.7,3.4c0,1.4-0.3,2.6-0.9,3.7c-0.6,1.1-1.4,1.9-2.4,2.5
									c-1,0.6-2.2,0.9-3.5,0.9C965.3,1216.2,963.9,1215.6,962.9,1214.4z M970.2,1213.6c0.6-0.8,1-2,1-3.5c0-1.3-0.3-2.5-0.9-3.4
									c-0.6-1-1.4-1.6-2.4-1.9c-1.2,0-2.1,0.4-2.8,1.3c-0.7,0.8-1.1,2-1.1,3.4c0,1.7,0.3,3,1,3.9c0.7,1,1.5,1.4,2.5,1.4
									C968.6,1214.8,969.5,1214.4,970.2,1213.6z"
						/>
						<path
							d="M979.3,1215.8c0.1-2.1,0.2-4.4,0.2-6.8c0-1.2,0-2.1-0.1-2.7c-0.1-0.6-0.2-1.1-0.3-1.5c-0.2-0.4-0.4-0.7-0.7-1.1
									c0.3-0.4,0.7-0.7,1-0.9c0.4-0.2,0.7-0.3,1-0.3c1.1,0,1.7,0.6,1.9,1.9c0.5-0.6,1.2-1.1,1.9-1.5c0.7-0.4,1.5-0.5,2.2-0.5
									c2.4,0,3.6,1.5,3.6,4.4l-0.1,2c-0.1,0.8-0.1,1.5-0.1,2.1c0,2.2,0.2,3.8,0.6,4.8c-0.6,0.1-1.1,0.2-1.6,0.2c-0.5,0-1.1-0.1-1.8-0.2
									c0.1-2.6,0.2-5.4,0.2-8.3c0-0.8-0.2-1.5-0.5-1.8c-0.3-0.4-0.9-0.6-1.6-0.6c-1.1,0-2,0.2-2.8,0.7l0,1.4c-0.1,1.1-0.1,2.4-0.1,3.9
									c0,2.2,0.2,3.8,0.6,4.8c-0.6,0.1-1.2,0.2-1.7,0.2C980.6,1216,980,1215.9,979.3,1215.8z"
						/>
						<path
							d="M996.4,1214.5c-0.8-1.1-1.3-2.8-1.3-5c0-2.3,0.4-4,1.3-5.2c0.9-1.2,2.1-1.8,3.9-1.8c1.3,0,2.6,0.4,3.7,1.3
									c-0.1-2.1-0.3-3.7-0.6-4.7c0.6-0.2,1.1-0.4,1.7-0.5c0.5-0.1,1.1-0.1,1.7-0.1c-0.2,2.7-0.4,7.1-0.4,13.4c0,0.7,0.1,1.3,0.2,1.8
									c0.2,0.4,0.5,0.9,0.9,1.4c-0.7,0.6-1.5,1-2.4,1.1c-0.5-0.4-0.9-1-1.2-1.8c-0.5,0.6-1.2,1-1.8,1.3s-1.4,0.5-2,0.5
									C998.5,1216.2,997.2,1215.6,996.4,1214.5z M1002.4,1214.2c0.5-0.3,1-0.6,1.5-0.9c0-0.7,0-1.8,0.1-3.3c0-1.7,0.1-3.1,0.1-4.1
									c-0.8-0.5-1.7-0.8-2.8-0.8c-1.1,0-1.9,0.4-2.4,1.2c-0.6,0.8-0.9,1.9-0.9,3.4c0,1.5,0.3,2.6,0.8,3.5c0.5,0.9,1.3,1.4,2.2,1.6
									C1001.4,1214.6,1001.8,1214.4,1002.4,1214.2z"
						/>
						<path
							d="M941.5,1240.9c-0.9-0.1-1.7-0.2-2.4-0.4c0.1-1,0.2-2.4,0.2-4.2c0.1-1.8,0.1-3.5,0.1-5c0-3.5-0.2-5.7-0.5-6.5
									c0.6-0.3,1.5-0.5,2.6-0.6s2.2-0.2,3.3-0.2c2.7,0,4.8,0.7,6.2,2.2c1.4,1.5,2.2,3.6,2.2,6.3c0,1.7-0.4,3.2-1.1,4.5
									c-0.8,1.3-1.8,2.3-3.2,3.1c-1.4,0.7-2.9,1.1-4.7,1.1C943.3,1241,942.4,1241,941.5,1240.9z M948,1238.2c0.7-0.6,1.3-1.4,1.7-2.4
									c0.4-0.9,0.6-1.9,0.6-2.9c0-4.7-2-7-6.1-7c-0.7,0-1.3,0.1-1.9,0.3c-0.1,2.4-0.2,4.8-0.2,7.4c0,2.1,0.1,3.8,0.2,5.2
									c1.5,0.4,2.7,0.6,3.5,0.6C946.5,1239.2,947.3,1238.9,948,1238.2z"
						/>
						<path
							d="M957.9,1240.3c-0.5-0.6-0.7-1.3-0.7-2.1c0-2.6,2.4-4,7.2-4.3c0-1.4-0.2-2.4-0.6-2.9c-0.4-0.6-1.1-0.8-2.1-0.8
									c-0.6,0-1.3,0.1-1.9,0.3c-0.6,0.2-1.2,0.5-1.8,0.9l-0.2-0.6c0.5-0.9,1.3-1.7,2.2-2.3c0.9-0.6,1.9-0.9,2.9-0.9c1.3,0,2.3,0.4,3,1.1
									c0.7,0.7,1.1,1.8,1.1,3.2c0,0.8-0.1,1.6-0.2,2.6c-0.1,1-0.1,1.8-0.1,2.3c0,0.8,0.1,1.4,0.2,1.9c0.2,0.4,0.5,0.9,0.9,1.5
									c-0.8,0.7-1.6,1.1-2.5,1.2c-0.5-0.4-0.9-1-1.2-1.8c-0.7,0.5-1.5,1-2.3,1.3c-0.8,0.3-1.5,0.5-2.2,0.5
									C959,1241.2,958.4,1240.9,957.9,1240.3z M962.5,1239.2c0.6-0.3,1.1-0.6,1.5-0.9v-0.2c0-0.7,0-1.3,0.1-2l0.1-1.4
									c-1.4,0.1-2.4,0.4-3.2,0.9s-1.1,1.2-1.1,2.1c0,0.4,0.1,0.7,0.3,1.1c0.2,0.3,0.4,0.6,0.7,0.8C961.4,1239.6,962,1239.4,962.5,1239.2
									z"
						/>
						<path
							d="M973.1,1239.5c-0.9-1.1-1.3-2.7-1.3-4.8c0-1.4,0.3-2.6,0.8-3.7c0.5-1.1,1.3-1.9,2.2-2.6c0.9-0.6,2-0.9,3.2-0.9
									c1.6,0,2.9,0.6,3.7,1.7c0.8,1.1,1.3,2.8,1.3,4.9c-1.1,0.3-2.4,0.5-3.9,0.7c-1.5,0.2-3,0.3-4.5,0.4c0.1,1.3,0.5,2.2,1,2.8
									c0.6,0.6,1.4,0.9,2.5,0.9c0.8,0,1.6-0.1,2.4-0.3c0.7-0.2,1.4-0.5,1.9-0.8l0.2,0.6c-0.6,0.8-1.5,1.5-2.5,2.1
									c-1,0.5-2.1,0.8-3.2,0.8C975.2,1241.2,974,1240.6,973.1,1239.5z M977.5,1233.6c1-0.2,1.7-0.5,2.3-0.8c0-1.1-0.3-2-0.9-2.8
									c-0.6-0.7-1.4-1.1-2.3-1.1c-0.6,0.4-1.1,1-1.5,2c-0.4,0.9-0.6,2-0.6,3.2C975.6,1234,976.6,1233.9,977.5,1233.6z"
						/>
						<path
							d="M988.7,1240.8c0.1-2.1,0.2-4.4,0.2-6.8c0-1.2,0-2.1-0.1-2.7c-0.1-0.6-0.2-1.1-0.3-1.5c-0.2-0.4-0.4-0.7-0.7-1.1
									c0.3-0.4,0.7-0.7,1.1-0.9c0.4-0.2,0.7-0.3,1-0.3c1.2,0,1.8,0.8,1.8,2.4c0.5-0.7,1.1-1.2,1.7-1.7c0.6-0.4,1.3-0.8,2.1-1
									c0.1,0.3,0.3,0.8,0.4,1.5c0.1,0.6,0.2,1.2,0.2,1.8c-0.2,0-0.6-0.1-1-0.1c-0.6,0-1.3,0.1-1.9,0.2c-0.6,0.2-1.1,0.4-1.4,0.6l0,1
									c0,1.4-0.1,2.6-0.1,3.7c0,2.2,0.2,3.8,0.6,4.8c-0.6,0.1-1.2,0.2-1.7,0.2C990,1241,989.4,1240.9,988.7,1240.8z"
						/>
					</g>
					<g className="regions level_one location" id="withered_heath">
						<rect x="3016.64" y="1382.482" width="195.88" height="30.413" transform="matrix(0.998058, -0.062294, 0.050271, 0.998808, -999.325012, -910.307678)"></rect>
						<path
							d="M2088.3,306.1c-0.5-0.6-0.9-1.3-1.3-2.2s-0.9-2.1-1.5-3.8c-0.7-1.8-1.2-3.3-1.7-4.5c-0.5-1.2-1.1-2.3-1.7-3.4
									c1-0.5,2.2-0.8,3.4-0.9c0.2,0.8,0.5,2,0.9,3.5c0.4,1.5,0.9,2.9,1.4,4.3c0.8,2,1.5,3.6,2.1,4.7c1.2-5.2,2-8.7,2.2-10.7l-0.9-1.7
									c1-0.5,2.2-0.8,3.4-0.9c0.2,0.8,0.5,2,0.9,3.5c0.4,1.5,0.9,2.9,1.4,4.3c0.9,2.1,1.6,3.7,2.1,4.7c0.9-4,1.6-6.8,1.9-8.6
									c0.4-1.8,0.5-3,0.5-3.7c0-0.1,0-0.3-0.1-0.4c0.7-0.3,1.6-0.5,2.5-0.5c-0.5,1.8-1.1,4.2-1.9,7.3c-0.7,3-1.3,5.7-1.8,7.8l-1.1,1.1
									c-0.6,0-1.2-0.2-1.6-0.7c-0.5-0.5-0.9-1.3-1.3-2.2s-0.9-2.2-1.5-3.8c-0.4-1.3-0.9-2.5-1.4-3.6c-0.5,2.2-1.3,5.5-2.3,9.9l-1.1,1.1
									C2089.3,306.9,2088.7,306.7,2088.3,306.1z"
						/>
						<path
							d="M2106.7,289.3c0.5-0.8,1-1.4,1.6-2c0.6,0.6,1.2,1.1,1.9,1.6c-0.5,0.7-1,1.4-1.6,2C2108.1,290.3,2107.4,289.8,2106.7,289.3
									z M2109.1,304.7c-0.3-0.3-0.4-0.9-0.5-1.8c-0.1-0.7-0.1-1.7-0.2-3c-0.1-1.2-0.1-2.2-0.2-2.7c-0.1-0.8-0.2-1.5-0.4-2.2
									c-0.2-0.7-0.3-1.1-0.6-1.4c0.5-0.3,1-0.5,1.5-0.6c0.5-0.1,1-0.2,1.6-0.3c0,0.6,0,1.4,0,2.6c0,1.2,0.1,2.5,0.3,3.9
									c0.1,1.1,0.2,1.9,0.3,2.4c0.1,0.6,0.3,1,0.4,1.3c0.2,0.3,0.4,0.6,0.8,0.9c-0.3,0.4-0.6,0.7-0.9,0.9c-0.3,0.2-0.7,0.3-0.9,0.4
									C2109.8,305.1,2109.4,305,2109.1,304.7z"
						/>
						<path
							d="M2118,303.8c-0.3-0.3-0.5-0.9-0.6-1.7c-0.1-0.6-0.1-1.6-0.2-2.9c-0.1-1.5-0.2-2.7-0.3-3.7c0-0.5-0.1-0.9-0.2-1.3
									c-0.8,0.1-1.3,0.1-1.5,0c-0.3-0.1-0.4-0.3-0.4-0.5c1.2-0.8,2.2-1.9,3.2-3.3l0.7,0c0.1,0.6,0.3,1.3,0.4,2c1.3-0.2,2.2-0.3,2.8-0.5
									c0,0.4,0,0.8,0,1.1c0,0.3-0.1,0.6-0.3,1c-0.6-0.2-1.4-0.2-2.4-0.1c0,0.9,0.1,1.8,0.2,2.7c0.2,2.4,0.6,4.2,1,5.4
									c0.9-0.1,1.6-0.3,2.1-0.5l0.2,0.6c-0.3,0.6-0.7,1.1-1.3,1.5c-0.6,0.4-1.2,0.6-1.8,0.7C2118.8,304.2,2118.3,304.1,2118,303.8z"
						/>
						<path
							d="M2126.7,303.1c-0.1-2.5-0.4-5-0.6-7.4c-0.4-3.4-0.8-6-1.2-7.8c0.9-0.5,1.9-0.8,3-0.9c0,0.9,0.1,2.8,0.2,5.7
									c0.5-0.6,1-1.2,1.7-1.6c0.6-0.4,1.3-0.7,2-0.7c2.2-0.2,3.5,1,3.8,3.6l0.1,1.8l0.2,1.9c0.2,2,0.6,3.5,1.1,4.3
									c-0.5,0.2-1,0.3-1.5,0.3c-0.5,0.1-1,0.1-1.7,0c-0.2-2.8-0.4-5.3-0.7-7.6c-0.1-0.8-0.3-1.3-0.7-1.6c-0.3-0.3-0.9-0.4-1.6-0.3
									c-1,0.1-1.9,0.5-2.6,1.1c0,0.6,0.1,1.4,0.2,2.4c0.4,3.6,0.9,5.8,1.3,6.6c-0.5,0.2-1,0.3-1.5,0.3
									C2127.9,303.2,2127.3,303.2,2126.7,303.1z"
						/>
						<path
							d="M2142.3,300.1c-0.9-0.9-1.5-2.3-1.7-4.2c-0.2-1.3,0-2.4,0.3-3.5c0.4-1.1,1-1.9,1.8-2.6c0.8-0.7,1.8-1.1,2.9-1.2
									c1.5-0.2,2.7,0.2,3.6,1.1c0.9,0.9,1.5,2.4,1.7,4.3c-1,0.4-2.1,0.7-3.5,1.1c-1.4,0.4-2.8,0.6-4.1,0.8c0.3,1.2,0.7,2,1.3,2.4
									c0.6,0.5,1.4,0.6,2.4,0.5c0.8-0.1,1.5-0.3,2.2-0.5c0.7-0.3,1.2-0.6,1.7-1l0.3,0.5c-0.5,0.8-1.2,1.6-2.1,2.1
									c-0.9,0.6-1.9,0.9-2.9,1.1C2144.5,301.4,2143.3,301,2142.3,300.1z M2145.8,294.3c0.9-0.3,1.6-0.7,2.1-1c-0.1-1-0.5-1.8-1.2-2.4
									s-1.4-0.8-2.3-0.7c-0.5,0.4-0.9,1.1-1.2,2s-0.3,1.9-0.2,3C2144,294.9,2144.9,294.6,2145.8,294.3z"
						/>
						<path
							d="M2157.1,299.6c-0.1-1.9-0.3-4-0.6-6.2c-0.1-1.1-0.3-1.9-0.4-2.4c-0.1-0.6-0.3-1-0.5-1.3c-0.2-0.3-0.5-0.6-0.8-0.9
									c0.3-0.4,0.6-0.7,0.9-0.9s0.6-0.3,0.9-0.4c1.1-0.1,1.7,0.5,2,1.9c0.4-0.7,0.9-1.2,1.4-1.7c0.5-0.5,1.1-0.8,1.8-1.1
									c0.2,0.3,0.3,0.7,0.5,1.3c0.2,0.6,0.3,1.1,0.4,1.6c-0.2,0-0.6,0-1,0.1c-0.6,0.1-1.2,0.2-1.7,0.4c-0.5,0.2-0.9,0.4-1.3,0.7l0.1,0.9
									c0.1,1.3,0.2,2.4,0.4,3.4c0.3,2,0.6,3.4,1.1,4.3c-0.6,0.2-1.1,0.3-1.5,0.4S2157.7,299.6,2157.1,299.6z"
						/>
						<path
							d="M2167.5,297c-0.9-0.9-1.5-2.3-1.8-4.2c-0.2-1.3-0.1-2.4,0.3-3.5c0.4-1.1,0.9-1.9,1.7-2.6c0.8-0.7,1.8-1.1,2.9-1.2
									c1.5-0.2,2.7,0.2,3.6,1.1c0.9,0.9,1.5,2.3,1.8,4.3c-1,0.4-2.1,0.8-3.5,1.1c-1.4,0.4-2.8,0.7-4.1,0.9c0.3,1.2,0.7,2,1.3,2.4
									c0.6,0.5,1.4,0.6,2.4,0.5c0.8-0.1,1.5-0.3,2.2-0.6c0.7-0.3,1.2-0.6,1.7-1l0.3,0.5c-0.5,0.8-1.2,1.6-2.1,2.2
									c-0.9,0.6-1.9,1-2.9,1.1C2169.6,298.3,2168.4,297.9,2167.5,297z M2170.9,291.2c0.9-0.3,1.6-0.7,2-1c-0.2-1-0.5-1.8-1.2-2.4
									c-0.6-0.6-1.4-0.8-2.3-0.7c-0.5,0.4-0.9,1.1-1.2,2c-0.2,0.9-0.3,1.9-0.1,3C2169.1,291.8,2170,291.5,2170.9,291.2z"
						/>
						<path
							d="M2181.8,295.1c-0.9-0.9-1.5-2.4-1.8-4.4c-0.3-2.1-0.1-3.7,0.6-4.9c0.7-1.2,1.8-1.9,3.4-2.1c1.2-0.2,2.4,0.1,3.6,0.7
									c-0.4-1.9-0.8-3.3-1.1-4.2c0.5-0.3,1-0.5,1.5-0.7s1-0.3,1.6-0.3c0.1,2.4,0.6,6.5,1.3,12.2c0.1,0.7,0.2,1.2,0.4,1.6
									c0.2,0.4,0.6,0.8,1.1,1.2c-0.6,0.7-1.3,1.1-2.1,1.3c-0.6-0.3-1-0.8-1.3-1.5c-0.4,0.6-0.9,1.1-1.5,1.4c-0.6,0.4-1.2,0.6-1.8,0.7
									C2184,296.4,2182.7,296,2181.8,295.1z M2187.3,294c0.4-0.3,0.9-0.6,1.3-1c-0.1-0.6-0.2-1.6-0.4-3c-0.2-1.6-0.3-2.8-0.5-3.7
									c-0.8-0.4-1.7-0.5-2.7-0.4c-1,0.1-1.7,0.6-2.1,1.4c-0.4,0.8-0.6,1.8-0.4,3.2c0.2,1.3,0.6,2.4,1.2,3.1c0.6,0.7,1.3,1.1,2.3,1.1
									C2186.4,294.5,2186.9,294.3,2187.3,294z"
						/>
						<path
							d="M2205,293.1c0.1-1,0.1-2.3,0.1-4c0-1.7-0.1-3.3-0.1-4.8c-0.2-3.5-0.4-5.7-0.8-6.5c0.5-0.1,1.1-0.2,1.5-0.2
									c0.5,0,1.1,0,1.7,0.1c-0.1,1.7-0.1,3.9,0,6.7c1,0.1,2.4,0,4,0c0.2,0,1-0.1,2.3-0.2c-0.2-3.6-0.4-5.9-0.8-6.7
									c0.5-0.1,1-0.2,1.5-0.2c0.5,0,1,0,1.7,0.1c-0.1,1-0.1,2.3-0.1,4c0,1.7,0.1,3.3,0.1,4.8c0.2,3.5,0.4,5.7,0.7,6.5
									c-0.5,0.1-1,0.2-1.6,0.2c-0.5,0-1,0-1.6-0.1c0.1-1.7,0.1-3.9,0-6.8c-1.2,0-2.4,0-3.7,0.1c-0.9,0-1.7,0.1-2.6,0.2
									c0.2,3.6,0.4,5.9,0.8,6.8c-0.5,0.1-1,0.2-1.6,0.2C2206.2,293.2,2205.6,293.2,2205,293.1z"
						/>
						<path
							d="M2222.3,291.6c-0.8-1.1-1.1-2.5-1-4.4c0.1-1.3,0.4-2.4,0.9-3.4s1.3-1.7,2.2-2.2c0.9-0.5,1.9-0.7,3.1-0.7
									c1.5,0.1,2.6,0.6,3.4,1.7c0.7,1.1,1,2.6,0.9,4.6c-1,0.2-2.2,0.4-3.7,0.5s-2.8,0.1-4.2,0.1c0.1,1.2,0.3,2.1,0.8,2.6
									c0.5,0.6,1.2,0.9,2.2,0.9c0.8,0,1.5,0,2.2-0.2c0.7-0.1,1.3-0.4,1.8-0.7l0.2,0.6c-0.6,0.7-1.4,1.3-2.4,1.8s-2,0.6-3,0.6
									C2224.2,293.2,2223,292.7,2222.3,291.6z M2226.7,286.5c0.9-0.2,1.7-0.4,2.2-0.6c0-1-0.2-1.9-0.7-2.6c-0.5-0.7-1.2-1.1-2.1-1.1
									c-0.6,0.3-1.1,0.9-1.5,1.7s-0.6,1.8-0.7,2.9C2224.8,286.8,2225.8,286.6,2226.7,286.5z"
						/>
						<path
							d="M2235.9,293.2c-0.4-0.6-0.6-1.2-0.5-2c0.3-2.3,2.6-3.4,7.1-3.2c0.1-1.3,0.1-2.2-0.2-2.7s-0.9-0.9-1.9-1
									c-0.6-0.1-1.2,0-1.8,0.1c-0.6,0.1-1.2,0.3-1.7,0.6l-0.2-0.6c0.6-0.8,1.4-1.4,2.3-1.8c0.9-0.4,1.8-0.6,2.8-0.5
									c1.2,0.1,2.1,0.6,2.7,1.3c0.6,0.7,0.8,1.7,0.7,3c-0.1,0.7-0.2,1.5-0.4,2.3c-0.2,0.9-0.3,1.6-0.3,2c-0.1,0.7-0.1,1.3,0,1.7
									c0.1,0.4,0.3,0.9,0.7,1.4c-0.8,0.5-1.6,0.8-2.4,0.8c-0.5-0.4-0.8-1-0.9-1.7c-0.7,0.4-1.5,0.7-2.3,0.9c-0.8,0.2-1.5,0.3-2.1,0.2
									C2236.8,294.1,2236.3,293.8,2235.9,293.2z M2240.4,292.7c0.6-0.2,1.1-0.4,1.5-0.7l0-0.1c0.1-0.6,0.2-1.2,0.3-1.8l0.2-1.2
									c-1.3-0.1-2.3,0.1-3.1,0.5c-0.8,0.4-1.2,1-1.3,1.8c0,0.3,0,0.7,0.1,1s0.3,0.6,0.6,0.8C2239.3,293,2239.8,292.9,2240.4,292.7z"
						/>
						<path
							d="M2250.7,295.3c-0.2-0.4-0.2-1-0.1-1.8c0.1-0.6,0.3-1.6,0.5-2.9c0.3-1.4,0.5-2.7,0.7-3.6c0.1-0.5,0.1-0.9,0.2-1.3
									c-0.7-0.1-1.2-0.3-1.5-0.4c-0.2-0.1-0.3-0.4-0.3-0.6c1.3-0.5,2.6-1.3,3.9-2.3l0.7,0.2c0,0.7-0.1,1.3-0.2,2
									c1.3,0.2,2.2,0.3,2.8,0.2c-0.1,0.4-0.2,0.8-0.3,1c-0.1,0.3-0.3,0.6-0.5,0.9c-0.5-0.3-1.3-0.5-2.3-0.7c-0.2,0.9-0.4,1.7-0.5,2.7
									c-0.4,2.4-0.5,4.2-0.5,5.4c0.9,0.1,1.6,0.1,2.1,0l0.1,0.7c-0.4,0.5-1,0.8-1.6,1.1c-0.7,0.2-1.3,0.3-1.9,0.2
									C2251.3,296,2250.9,295.7,2250.7,295.3z"
						/>
						<path
							d="M2259,297c0.7-2.4,1.3-4.8,1.8-7.2c0.7-3.3,1.2-5.9,1.4-7.8c1-0.2,2.1-0.1,3.2,0.1c-0.3,0.9-0.8,2.7-1.6,5.4
									c0.6-0.5,1.3-0.8,2.1-1c0.7-0.2,1.5-0.2,2.1-0.1c2.2,0.5,3,2,2.4,4.6l-0.4,1.8l-0.5,1.9c-0.4,2-0.6,3.5-0.4,4.4
									c-0.5,0-1-0.1-1.5-0.2c-0.4-0.1-1-0.3-1.6-0.5c0.7-2.7,1.3-5.1,1.8-7.4c0.2-0.8,0.1-1.3-0.1-1.7s-0.7-0.7-1.4-0.8
									c-1-0.2-1.9-0.2-2.8,0.2c-0.2,0.5-0.3,1.3-0.6,2.3c-0.8,3.5-1.1,5.8-0.8,6.6c-0.5,0-1-0.1-1.6-0.2
									C2260.2,297.5,2259.6,297.3,2259,297z"
						/>
					</g>
					<g className="level_two fade location " id="carnen">
						<rect x="4360.703" y="2305.548" width="283.153" height="50.72" transform="matrix(0.214803, 0.976657, -0.979245, 0.203038, 3861.415771, -4260.352051)"></rect>
						<path
							d="M2503.3,492.5c0-2,0.9-4.1,2.7-6.3c1.3-1.5,2.6-2.7,4.2-3.4c1.5-0.7,3.1-1,4.6-0.9c1.6,0.2,3,0.8,4.3,1.8
									c1.4,1.2,2.5,2.4,3.1,3.6c-0.3,0.4-0.8,0.8-1.3,1.2c-0.5,0.4-1,0.7-1.4,0.8c-0.3-1.1-0.7-2.2-1.2-3.2c-0.5-1-1-1.7-1.5-2.1
									c-0.7-0.3-1.5-0.4-2.4-0.2c-1,0.2-1.9,0.6-2.9,1.2c-1,0.6-1.8,1.3-2.5,2.2c-1.5,1.8-2.3,3.5-2.2,5c0,1.5,0.6,2.8,1.8,3.8
									c1.7,1.4,3.3,2.1,4.9,2.4l-0.4,0.8c-1.1,0.3-2.3,0.3-3.6-0.1c-1.3-0.3-2.4-0.8-3.3-1.6C2504.3,496.3,2503.4,494.5,2503.3,492.5z"
						/>
						<path
							d="M2514.4,504.1c0.1-0.8,0.5-1.5,1.3-2.1c2.1-1.8,5-0.9,8.6,2.7c1.2-1,1.8-1.9,2-2.6s-0.1-1.5-0.8-2.3
									c-0.4-0.5-1-0.9-1.6-1.3c-0.6-0.4-1.3-0.6-1.9-0.8l0.3-0.6c1.2-0.2,2.3-0.2,3.4,0.2s2,0.9,2.7,1.7c0.9,1.1,1.3,2.1,1.2,3.2
									s-0.8,2.1-1.9,3.1c-0.6,0.5-1.4,1.1-2.3,1.7c-0.9,0.7-1.6,1.2-2,1.5c-0.7,0.6-1.1,1.1-1.4,1.5c-0.3,0.4-0.4,1.1-0.6,1.8
									c-1.1-0.1-2-0.5-2.7-1.1c-0.1-0.7,0.2-1.4,0.7-2.2c-0.9-0.2-1.8-0.5-2.6-0.9s-1.5-0.9-1.9-1.4
									C2514.5,505.6,2514.3,504.9,2514.4,504.1z M2518.6,507.1c0.6,0.3,1.2,0.5,1.8,0.6l0.1-0.1c0.6-0.5,1.1-0.9,1.7-1.3l1.2-0.9
									c-1-1.1-2-1.7-3-1.9c-1-0.2-1.8,0-2.5,0.6c-0.3,0.3-0.5,0.6-0.7,1c-0.2,0.4-0.2,0.8-0.2,1.2C2517.5,506.5,2518,506.8,2518.6,507.1
									z"
						/>
						<path
							d="M2524.1,517.3c2.1-1.1,4.2-2.3,6.4-3.7c1.1-0.7,1.9-1.2,2.4-1.6c0.5-0.4,0.9-0.8,1.2-1.2c0.2-0.3,0.5-0.8,0.6-1.3
									c0.5,0.1,1,0.2,1.4,0.5c0.4,0.2,0.7,0.5,0.8,0.8c0.6,1,0.3,2.1-1.2,3c0.9,0.1,1.8,0.3,2.5,0.6c0.7,0.3,1.4,0.7,2.1,1.3
									c-0.2,0.3-0.6,0.7-1.2,1.2s-1,0.9-1.5,1.2c-0.1-0.2-0.3-0.6-0.5-1c-0.4-0.6-0.8-1.1-1.3-1.5c-0.5-0.4-0.9-0.8-1.4-1l-0.9,0.5
									c-1.3,0.8-2.5,1.5-3.5,2.1c-2.1,1.3-3.4,2.4-4.1,3.3c-0.5-0.5-0.8-0.9-1.1-1.4S2524.3,517.9,2524.1,517.3z"
						/>
						<path
							d="M2530.2,527.7c2.2-0.8,4.5-1.6,6.9-2.7c1.2-0.5,2.1-0.9,2.7-1.2c0.6-0.3,1-0.6,1.3-1c0.3-0.3,0.6-0.7,0.8-1.2
									c0.5,0.1,1,0.4,1.3,0.6c0.3,0.3,0.6,0.6,0.7,0.9c0.4,1.1,0.1,1.9-1.2,2.6c0.9,0.2,1.6,0.6,2.3,1.2c0.7,0.6,1.1,1.2,1.5,1.9
									c1,2.3,0,4.1-3,5.4l-2.1,0.8c-0.8,0.3-1.6,0.5-2.2,0.8c-2.2,0.9-3.7,1.8-4.6,2.7c-0.4-0.5-0.6-1-0.9-1.5c-0.2-0.5-0.4-1.1-0.6-1.9
									c2.7-0.9,5.5-2.1,8.5-3.3c0.9-0.4,1.4-0.8,1.7-1.3s0.2-1.1-0.1-1.8c-0.5-1.1-1.1-1.9-1.9-2.4l-1.4,0.6c-1.1,0.4-2.4,0.9-3.9,1.5
									c-2.3,0.9-3.8,1.8-4.6,2.6c-0.4-0.5-0.7-1.1-0.9-1.6C2530.5,529.1,2530.3,528.5,2530.2,527.7z"
						/>
						<path
							d="M2537.8,544c1-1.2,2.6-2,4.8-2.5c1.5-0.3,2.9-0.4,4.2-0.1c1.3,0.3,2.4,0.8,3.3,1.7s1.5,1.9,1.8,3.1
									c0.4,1.7,0.1,3.1-0.9,4.2c-1,1.1-2.6,2-5,2.5c-0.6-1-1.1-2.3-1.7-3.9c-0.6-1.5-1.1-3.1-1.5-4.6c-1.4,0.4-2.3,1-2.8,1.7
									c-0.5,0.7-0.6,1.6-0.4,2.8c0.2,0.9,0.5,1.7,0.9,2.4c0.4,0.7,0.8,1.3,1.4,1.8l-0.6,0.4c-1.1-0.4-2-1.1-2.8-2.1
									c-0.8-1-1.3-2-1.6-3.1C2536.5,546.6,2536.8,545.2,2537.8,544z M2545.1,547.2c0.5,1,1,1.7,1.4,2.2c1.2-0.3,2.1-0.8,2.7-1.6
									c0.6-0.8,0.8-1.7,0.6-2.6c-0.6-0.6-1.4-0.9-2.5-1.1c-1.1-0.2-2.3-0.1-3.6,0.2C2544.2,545.2,2544.6,546.2,2545.1,547.2z"
						/>
						<path
							d="M2539.5,560.3c2.3,0,4.8,0,7.4-0.2c1.3-0.1,2.3-0.2,2.9-0.3c0.7-0.1,1.2-0.3,1.6-0.4c0.4-0.2,0.8-0.5,1.2-0.9
									c0.5,0.3,0.8,0.7,1,1c0.2,0.4,0.3,0.7,0.4,1.1c0.1,1.1-0.6,1.8-2,2.1c0.7,0.5,1.3,1.2,1.7,1.9c0.4,0.7,0.7,1.5,0.7,2.3
									c0.1,2.5-1.4,3.9-4.6,4l-2.2,0.1c-0.9,0-1.6,0-2.3,0c-2.4,0.1-4.1,0.5-5.2,1c-0.2-0.6-0.3-1.2-0.3-1.7c0-0.6,0-1.2,0.1-2
									c2.8,0,5.9-0.1,9.1-0.3c0.9-0.1,1.6-0.3,2-0.6c0.4-0.4,0.6-1,0.5-1.7c-0.1-1.2-0.4-2.2-1-2.9l-1.5,0c-1.2,0-2.6,0-4.2,0.1
									c-2.4,0.1-4.2,0.4-5.2,0.9c-0.2-0.6-0.3-1.3-0.3-1.8C2539.4,561.7,2539.4,561.1,2539.5,560.3z"
						/>
						<path
							d="M2554.8,582.9l-0.1,0.7c-3-0.1-5.8,0.4-8.6,1.4s-5.7,2.7-8.7,5s-5.5,4.6-7.3,6.9s-3.1,4.9-3.9,7.8l-0.7-0.1
									c0.5-3.3,1.6-6.4,3.2-9.3c1.7-2.9,4-5.4,7-7.6s6.1-3.7,9.3-4.4C2548.2,582.5,2551.5,582.3,2554.8,582.9z"
						/>
						<path
							d="M2533.9,604.7c1.1-0.6,2.6-1.4,4.5-2.5c1.9-1.1,3.6-2.2,5.1-3.1c3.4-2.2,5.4-3.7,6-4.5c0.6,0.4,1.3,1.1,2.1,2
									c0.8,0.9,1.5,1.8,2.1,2.8c1.2,1.9,1.7,3.7,1.4,5.3c-0.3,1.6-1.3,3-3.1,4.1c-1.1,0.7-2.2,1-3.3,1c-1.1,0-2.3-0.3-3.3-0.9
									c-2.2,3.5-3.4,5.9-3.6,7.3c-0.6-0.4-1.1-0.8-1.5-1.3c-0.4-0.5-0.9-1.1-1.3-1.7c0.9-1.1,2.2-3.3,4.1-6.4c-0.1-0.1-0.2-0.2-0.4-0.5
									c-0.2-0.4-0.5-0.8-0.7-1.3c-0.2-0.5-0.4-0.9-0.5-1.2c-3,1.9-4.8,3.3-5.6,4.2c-0.5-0.4-0.9-0.9-1.2-1.5
									C2534.4,605.9,2534.1,605.3,2533.9,604.7z M2547.4,606.9c0.9,0.1,1.8-0.2,2.6-0.7c2.5-1.6,2.9-3.6,1.2-6.2c-0.4-0.6-0.8-1-1.2-1.4
									c-1.6,0.9-3.7,2.1-6.5,3.8c0.3,1.7,0.7,2.9,1.2,3.6C2545.6,606.5,2546.5,606.8,2547.4,606.9z"
						/>
						<path
							d="M2545.3,620.6c0.7-1.5,2.1-2.8,4.2-3.9c1.4-0.8,2.8-1.2,4.2-1.3c1.4-0.1,2.7,0.2,3.8,0.8c1.1,0.6,2,1.5,2.7,2.7
									c0.9,1.6,0.9,3.1,0.3,4.6c-0.7,1.4-2.1,2.8-4.4,3.9c-0.9-0.9-1.8-2.1-2.8-3.5c-1-1.4-1.9-2.8-2.8-4.3c-1.3,0.8-2,1.7-2.3,2.5
									c-0.3,0.9-0.2,1.8,0.4,2.9c0.4,0.8,1,1.5,1.6,2.2c0.6,0.6,1.2,1.1,1.9,1.4l-0.5,0.5c-1.2-0.2-2.3-0.6-3.4-1.4
									c-1.1-0.8-1.9-1.7-2.5-2.8C2544.7,623.6,2544.6,622,2545.3,620.6z M2553.7,621.8c0.8,0.8,1.5,1.4,2.1,1.8c1.1-0.6,1.9-1.4,2.3-2.4
									c0.4-1,0.4-1.9-0.1-2.9c-0.7-0.4-1.7-0.6-2.8-0.4c-1.2,0.1-2.3,0.5-3.6,1.2C2552.2,620.1,2552.9,620.9,2553.7,621.8z"
						/>
						<path
							d="M2553.5,636.2c0.8-1.4,2.4-2.6,4.7-3.7c2.4-1.1,4.4-1.5,6.1-1.1s2.8,1.4,3.6,3.1c0.6,1.4,0.7,2.8,0.4,4.4
									c2.2-1.1,3.8-2.1,4.7-2.9c0.5,0.5,0.9,1,1.3,1.5c0.3,0.5,0.7,1,0.9,1.7c-2.9,1-7.7,3-14.3,6c-0.8,0.4-1.4,0.7-1.8,1.1
									c-0.4,0.4-0.7,0.9-1.1,1.6c-1-0.4-1.8-1.1-2.3-1.9c0.2-0.8,0.6-1.4,1.3-2c-0.9-0.3-1.6-0.7-2.3-1.2c-0.7-0.5-1.1-1.2-1.4-1.8
									C2552.7,639.1,2552.7,637.6,2553.5,636.2z M2556.7,642.1c0.5,0.4,1.1,0.8,1.7,1.1c0.7-0.3,1.9-0.8,3.6-1.5
									c1.9-0.8,3.3-1.4,4.4-1.9c0.2-1.1,0-2.1-0.5-3.2c-0.5-1.1-1.3-1.7-2.4-1.9c-1.1-0.2-2.4,0-4,0.8c-1.5,0.7-2.7,1.5-3.3,2.5
									c-0.7,1-0.9,2-0.6,3C2555.8,641.3,2556.1,641.7,2556.7,642.1z"
						/>
						<path
							d="M2562.4,654l4-2.3c1-0.5,1.8-1,2.6-1.5c0.9-0.6,1.7-1.1,2.4-1.7c0.7-0.5,1.1-1,1.4-1.4c0.4,0.5,0.8,1,1,1.6
									c0.3,0.6,0.5,1.2,0.7,2c-1,0.4-3,1.5-6.1,3.3s-4.9,2.8-5.5,3.3c5.7,0.3,9.2,0.4,10.5,0.3c0.8-0.6,1.4-1.2,1.6-1.6
									c0.8,0.9,1.4,2.1,1.8,3.6c-1,0.4-3.1,1.5-6.3,3.3c-3.2,1.8-5,2.9-5.3,3.2c4.1,0.2,7,0.4,8.6,0.4c1.7,0,2.8-0.1,3.4-0.2
									c0.2-0.1,0.5-0.2,0.8-0.3c0.5,0.7,0.9,1.7,1.2,2.9c-1.7-0.2-3.8-0.5-6.5-0.6c-2.7-0.2-5.6-0.3-8.7-0.4l-1.6-1.5
									c-0.2-0.7-0.1-1.3,0.3-1.8c0.4-0.6,1-1.1,1.9-1.7c2.9-1.7,4.9-2.8,6.1-3.5c-2.3-0.2-5.3-0.3-9.1-0.4l-1.6-1.5
									C2559.8,656.2,2560.6,655,2562.4,654z"
						/>
						<path
							d="M2565.2,676.4c0.6-0.6,1.4-0.9,2.4-1c3-0.2,4.8,2.3,5.6,7.7c1.6-0.1,2.7-0.4,3.3-0.9c0.6-0.5,0.9-1.3,0.8-2.5
									c-0.1-0.7-0.2-1.4-0.5-2.1c-0.3-0.7-0.7-1.3-1.1-1.9l0.7-0.3c1.1,0.5,2.1,1.3,2.8,2.3c0.8,1,1.2,2,1.2,3.1c0.1,1.5-0.2,2.6-1,3.5
									c-0.8,0.9-2,1.3-3.6,1.5c-0.9,0.1-1.9,0.1-3,0c-1.2,0-2.1,0-2.6,0.1c-0.9,0.1-1.6,0.2-2.1,0.4c-0.5,0.2-1,0.6-1.6,1.2
									c-0.8-0.8-1.4-1.7-1.6-2.7c0.4-0.6,1-1.1,1.9-1.4c-0.7-0.8-1.2-1.6-1.7-2.4c-0.4-0.9-0.7-1.7-0.7-2.4
									C2564.3,677.7,2564.6,677,2565.2,676.4z M2566.9,681.5c0.3,0.6,0.7,1.2,1.2,1.6l0.2,0c0.8-0.1,1.5-0.1,2.3-0.1l1.6,0
									c-0.2-1.5-0.7-2.7-1.3-3.5c-0.7-0.8-1.5-1.2-2.5-1.1c-0.4,0-0.8,0.2-1.2,0.4c-0.4,0.2-0.7,0.5-0.9,0.9
									C2566.3,680.3,2566.5,680.9,2566.9,681.5z"
						/>
						<path
							d="M2565.7,694.5c0.4-0.3,1.2-0.5,2.3-0.4c0.8,0,2,0.1,3.7,0.2c1.9,0.1,3.4,0.1,4.6,0.2c0.6,0,1.2,0,1.7,0
									c0-0.9,0.1-1.5,0.2-1.8c0.1-0.3,0.4-0.5,0.7-0.4c0.9,1.5,2.1,2.9,3.6,4.3l-0.1,0.8c-0.8,0.1-1.7,0.1-2.6,0.1
									c0,1.5,0.1,2.6,0.2,3.4c-0.5,0-1-0.1-1.4-0.2c-0.4-0.1-0.8-0.3-1.2-0.5c0.3-0.7,0.5-1.6,0.5-2.8c-1.1-0.1-2.3-0.2-3.5-0.2
									c-3-0.1-5.3,0.1-6.9,0.4c0,1,0.2,1.9,0.3,2.5l-0.9,0.2c-0.7-0.4-1.2-1-1.6-1.7c-0.4-0.7-0.6-1.5-0.6-2.2
									C2565,695.4,2565.2,694.8,2565.7,694.5z"
						/>
						<path
							d="M2566.4,705.8c1.4-0.8,3.3-1.1,5.6-0.8c1.6,0.2,3,0.7,4.2,1.4s2.1,1.7,2.6,2.8c0.6,1.1,0.8,2.4,0.6,3.7
									c-0.2,1.8-1,3.1-2.4,3.9c-1.4,0.8-3.3,1-5.8,0.7c-0.2-1.2-0.3-2.7-0.3-4.4c0-1.7,0-3.4,0.2-5.1c-1.5,0-2.6,0.2-3.4,0.7
									c-0.7,0.5-1.2,1.4-1.3,2.6c-0.1,0.9-0.1,1.8,0,2.7c0.1,0.9,0.4,1.6,0.7,2.2l-0.7,0.2c-0.9-0.8-1.6-1.9-2-3.1
									c-0.5-1.2-0.6-2.5-0.5-3.7C2564.2,707.9,2565,706.6,2566.4,705.8z M2572.5,711.5c0.1,1.1,0.4,2,0.6,2.7c1.3,0.1,2.4-0.1,3.3-0.6
									c0.9-0.6,1.4-1.4,1.6-2.4c-0.4-0.8-1-1.4-2.1-2c-1-0.6-2.2-0.9-3.6-1.1C2572.3,709.3,2572.4,710.4,2572.5,711.5z"
						/>
						<path
							d="M2562,722.8c2.3,0.7,4.9,1.4,7.6,2.1c1.3,0.3,2.3,0.5,3,0.6c0.7,0.1,1.3,0.1,1.7,0c0.4-0.1,0.9-0.2,1.5-0.5
									c0.3,0.5,0.6,0.9,0.7,1.4c0.1,0.5,0.1,0.9,0,1.2c-0.3,1.3-1.4,1.7-3.1,1.3c0.6,0.8,1.1,1.5,1.4,2.3c0.3,0.8,0.5,1.6,0.5,2.5
									c-0.4,0.1-1,0.1-1.7,0c-0.7-0.1-1.4-0.2-2-0.3c0.1-0.3,0.2-0.6,0.3-1.1c0.2-0.7,0.3-1.4,0.2-2.1c0-0.7-0.1-1.3-0.3-1.7l-1.1-0.3
									c-1.6-0.4-3-0.8-4.2-1.1c-2.5-0.6-4.3-0.8-5.5-0.7c0-0.7,0.1-1.3,0.2-1.9C2561.5,724.1,2561.7,723.5,2562,722.8z"
						/>
						<path
							d="M2577.8,745.8c-3.3,0.2-6.7-0.3-10.2-1.5c-3.5-1.2-6.4-3-8.9-5.2c-2.4-2.2-4.4-4.9-5.8-7.9l0.6-0.3c1.6,2.5,3.6,4.6,6,6.3
									c2.4,1.7,5.4,3.2,9,4.4c3.6,1.3,6.9,2,9.8,2.2s5.8-0.2,8.6-1.2l0.3,0.6C2584.3,744.7,2581.2,745.5,2577.8,745.8z"
						/>
					</g>
					<g className="level_one location" id="south_gondor">
						<rect x="2330.55" y="5283.984" width="151.33" height="116.246" transform="matrix(-0.999838, 0.018023, -0.005976, -1.000055, 4333.675781, 7416.788574)"></rect>
						<path
							d="M1844.6,2089.8c0-0.7,0-1.3,0.1-1.8c0.1-0.5,0.3-1.1,0.5-1.6c0.6,0.8,1.4,1.4,2.3,1.8c1,0.4,2.1,0.7,3.2,0.7
									c1.3,0,2.4-0.3,3.1-1c0.7-0.7,1.1-1.7,1.1-2.9c0-1.1-0.3-2-1-2.7c-0.7-0.7-1.8-1.6-3.2-2.5c-1.3-0.9-2.3-1.6-3.1-2.3
									c-0.8-0.6-1.4-1.5-2-2.5c-0.5-1-0.8-2.2-0.8-3.7c0-1.9,0.7-3.4,2-4.6c1.4-1.2,3.2-1.8,5.5-1.8c2.1,0,3.8,0.4,5.3,1.2
									c0,0.7-0.1,1.5-0.3,2.5c-0.2,0.9-0.4,1.6-0.7,2.2c-1-1-2.1-1.8-3.2-2.5s-2-1-2.7-1c-0.5,0.1-1,0.5-1.5,1.2
									c-0.5,0.7-0.7,1.4-0.7,2.4c0,1.2,0.4,2.1,1.1,2.9c0.7,0.8,1.8,1.6,3.3,2.6c1.2,0.8,2.2,1.5,3,2.2s1.4,1.4,1.9,2.4
									c0.5,1,0.8,2.1,0.8,3.5c0,2.3-0.6,4.1-1.8,5.4c-1.2,1.3-3.1,1.9-5.5,1.9C1848.6,2091.6,1846.4,2091,1844.6,2089.8z"
						/>
						<path
							d="M1869.7,2088.8c-1.2-1.8-1.9-4.2-1.9-7.2c0-2.2,0.3-4.1,1-5.8c0.7-1.7,1.6-3,2.9-3.9c1.2-0.9,2.6-1.4,4.2-1.4
									c1.4,0,2.6,0.4,3.7,1.2s1.9,2,2.5,3.5c0.6,1.5,0.9,3.2,0.9,5.2c0,2.2-0.4,4.1-1.1,5.8c-0.7,1.7-1.7,3-3,3.9
									c-1.3,0.9-2.7,1.4-4.3,1.4C1872.6,2091.6,1870.9,2090.7,1869.7,2088.8z M1878.6,2087.6c0.8-1.3,1.2-3,1.2-5.3c0-2-0.4-3.8-1.1-5.2
									c-0.7-1.5-1.7-2.5-3-3c-1.4,0-2.6,0.6-3.4,1.9s-1.3,3-1.3,5.2c0,2.5,0.4,4.6,1.2,6.1c0.8,1.5,1.8,2.2,3.1,2.2
									C1876.7,2089.5,1877.8,2088.9,1878.6,2087.6z"
						/>
						<path
							d="M1893.8,2084.8l0.1-3.1l0.1-3.3c0-3.2-0.2-5.5-0.7-6.9c1.2-0.7,2.6-1,4.1-1c-0.1,4-0.2,8.6-0.2,13.7
									c0,1.3,0.2,2.3,0.6,2.9c0.4,0.6,1.1,0.9,2,0.9c1.2,0,2.3-0.4,3.3-1.1c0-2.2,0-4.1,0.1-5.8l0-2.7c0-3.2-0.2-5.5-0.7-6.9
									c1.2-0.7,2.6-1,4.1-1c-0.1,2.3-0.2,4.9-0.3,8c-0.1,3.1-0.1,5.2-0.1,6.2c0,1.2,0.1,2.1,0.3,2.8s0.6,1.4,1.2,2.2
									c-0.9,1-1.9,1.6-3,1.8c-0.7-0.7-1.2-1.7-1.5-2.9c-0.7,0.9-1.5,1.6-2.4,2.2s-1.8,0.8-2.8,0.8
									C1895.3,2091.6,1893.8,2089.3,1893.8,2084.8z"
						/>
						<path
							d="M1919.9,2090.6c-0.3-0.6-0.5-1.6-0.5-3c0-1,0-2.7,0.1-5c0-2.5,0.1-4.5,0.1-6.2c0-0.8,0-1.5-0.1-2.3c-1,0-1.7-0.1-2-0.3
									s-0.5-0.5-0.5-1c1.6-1.2,3.1-2.9,4.6-5l0.9,0.1c0.1,1.1,0.2,2.3,0.2,3.4c1.7,0,2.9-0.2,3.6-0.4c0,0.7,0,1.3-0.1,1.8
									c-0.1,0.5-0.3,1.1-0.5,1.6c-0.7-0.4-1.8-0.6-3.1-0.6c-0.1,1.5-0.1,3-0.1,4.6c0,4,0.2,7.1,0.6,9.2c1.1-0.1,2.1-0.3,2.8-0.5l0.2,1.1
									c-0.4,0.9-1.1,1.7-1.9,2.2c-0.8,0.6-1.6,0.8-2.4,0.8C1920.8,2091.5,1920.2,2091.2,1919.9,2090.6z"
						/>
						<path
							d="M1935.9,2091c0.2-4.2,0.3-8.3,0.3-12.5c0-5.7-0.1-10.1-0.4-13.3c1.2-0.7,2.6-1,4.1-1c-0.1,1.5-0.3,4.7-0.5,9.5
									c0.7-1,1.5-1.8,2.4-2.3c0.9-0.6,1.8-0.9,2.7-0.9c2.9,0,4.4,2.3,4.4,6.8l-0.1,3.1l-0.1,3.3c0,3.4,0.2,5.9,0.7,7.4
									c-0.7,0.2-1.4,0.3-2,0.3c-0.6,0-1.3-0.1-2.2-0.3c0.2-4.6,0.3-8.9,0.3-12.8c0-1.3-0.2-2.2-0.6-2.8c-0.4-0.6-1.1-0.9-2-0.9
									c-1.3,0-2.5,0.4-3.5,1.3c-0.1,0.9-0.1,2.3-0.1,4c0,6.1,0.3,9.9,0.8,11.2c-0.7,0.2-1.4,0.3-2,0.3
									C1937.4,2091.3,1936.7,2091.2,1935.9,2091z"
						/>
						<path
							d="M1826.9,2130.9c-1.5-2.1-2.2-5.2-2.2-9.2c0-2.8,0.4-5.3,1.2-7.4c0.8-2.2,2-3.9,3.5-5.1c1.5-1.2,3.2-1.8,5.2-1.8
									c2.3,0,4.2,0.4,5.8,1.2c0,0.7-0.1,1.5-0.3,2.4c-0.2,0.9-0.4,1.7-0.7,2.2c-1.1-0.9-2.3-1.7-3.6-2.4c-1.3-0.7-2.3-1-3-1
									c-0.8,0.3-1.6,0.9-2.3,2c-0.7,1.1-1.3,2.4-1.7,3.9c-0.4,1.5-0.6,3.1-0.6,4.7c0,3.7,0.5,6.3,1.5,8c1,1.6,2.7,2.4,5,2.4
									c1,0,2-0.2,2.8-0.5c0-5.3-0.2-8.6-0.6-9.9c0.6-0.2,1.3-0.3,1.9-0.3c0.6,0,1.3,0.1,2.1,0.3c-0.2,3.3-0.3,6.7-0.3,10.3
									c-0.8,1-1.9,1.8-3.3,2.5c-1.4,0.6-2.7,0.9-4.1,0.9C1830.5,2134.1,1828.4,2133,1826.9,2130.9z"
						/>
						<path
							d="M1854.1,2131.5c-1.2-1.7-1.9-3.9-1.9-6.6c0-2,0.3-3.7,1-5.3c0.7-1.5,1.6-2.7,2.9-3.6c1.2-0.9,2.6-1.3,4.2-1.3
									c1.4,0,2.6,0.4,3.7,1.1c1.1,0.8,1.9,1.8,2.5,3.2c0.6,1.4,0.9,3,0.9,4.8c0,2-0.4,3.7-1.1,5.3c-0.7,1.5-1.7,2.7-3,3.6
									c-1.3,0.9-2.7,1.3-4.3,1.3C1857,2134,1855.3,2133.2,1854.1,2131.5z M1863,2130.4c0.8-1.2,1.2-2.8,1.2-4.9c0-1.9-0.4-3.5-1.1-4.8
									c-0.7-1.3-1.7-2.3-3-2.7c-1.4,0-2.6,0.6-3.4,1.8s-1.3,2.8-1.3,4.8c0,2.3,0.4,4.2,1.2,5.5c0.8,1.4,1.8,2,3.1,2
									C1861.1,2132.1,1862.2,2131.6,1863,2130.4z"
						/>
						<path
							d="M1880.2,2133.5c0.2-3,0.3-6.2,0.3-9.6c0-1.6,0-2.9-0.1-3.8s-0.2-1.6-0.4-2.1c-0.2-0.5-0.5-1-0.9-1.6
									c0.4-0.6,0.8-1,1.3-1.2c0.4-0.3,0.9-0.4,1.2-0.4c1.3,0,2.1,0.9,2.3,2.7c0.6-0.9,1.4-1.6,2.3-2.1c0.9-0.5,1.8-0.8,2.7-0.8
									c2.9,0,4.4,2.1,4.4,6.2l-0.1,2.8c-0.1,1.1-0.1,2.1-0.1,3c0,3.1,0.3,5.3,0.8,6.7c-0.7,0.2-1.4,0.3-2,0.3c-0.6,0-1.4-0.1-2.2-0.3
									c0.2-3.6,0.3-7.5,0.3-11.7c0-1.2-0.2-2.1-0.6-2.6c-0.4-0.5-1.1-0.8-2-0.8c-1.4,0-2.5,0.3-3.4,1l0,1.9c-0.1,1.5-0.1,3.3-0.1,5.4
									c0,3.1,0.2,5.4,0.7,6.7c-0.7,0.2-1.5,0.3-2.1,0.3C1881.7,2133.8,1881,2133.7,1880.2,2133.5z"
						/>
						<path
							d="M1907,2131.7c-1-1.6-1.5-4-1.5-7.1c0-3.2,0.5-5.7,1.6-7.3c1.1-1.6,2.6-2.5,4.7-2.5c1.6,0,3.1,0.6,4.5,1.8
									c-0.1-3-0.4-5.2-0.7-6.7c0.7-0.3,1.4-0.6,2-0.7c0.6-0.1,1.3-0.2,2.1-0.2c-0.3,3.7-0.4,10-0.4,18.9c0,1,0.1,1.9,0.3,2.5
									c0.2,0.6,0.6,1.3,1.2,2c-0.8,0.9-1.8,1.4-3,1.6c-0.7-0.6-1.1-1.4-1.4-2.5c-0.7,0.8-1.4,1.4-2.3,1.9s-1.7,0.7-2.5,0.7
									C1909.6,2134.1,1908.1,2133.3,1907,2131.7z M1914.3,2131.2c0.6-0.4,1.2-0.8,1.8-1.3c0-0.9,0-2.5,0.1-4.7c0-2.5,0.1-4.4,0.1-5.8
									c-1-0.7-2.1-1.1-3.4-1.1c-1.3,0-2.3,0.6-3,1.7c-0.7,1.1-1,2.7-1,4.8c0,2.1,0.3,3.7,1,4.9c0.6,1.2,1.6,2,2.7,2.2
									C1913.1,2131.8,1913.7,2131.6,1914.3,2131.2z"
						/>
						<path
							d="M1933.5,2131.5c-1.2-1.7-1.9-3.9-1.9-6.6c0-2,0.3-3.7,1-5.3c0.7-1.5,1.6-2.7,2.9-3.6c1.2-0.9,2.6-1.3,4.2-1.3
									c1.4,0,2.6,0.4,3.7,1.1c1.1,0.8,1.9,1.8,2.5,3.2c0.6,1.4,0.9,3,0.9,4.8c0,2-0.4,3.7-1.1,5.3c-0.7,1.5-1.7,2.7-3,3.6
									c-1.3,0.9-2.7,1.3-4.3,1.3C1936.4,2134,1934.7,2133.2,1933.5,2131.5z M1942.4,2130.4c0.8-1.2,1.2-2.8,1.2-4.9
									c0-1.9-0.4-3.5-1.1-4.8c-0.7-1.3-1.7-2.3-3-2.7c-1.4,0-2.6,0.6-3.4,1.8s-1.3,2.8-1.3,4.8c0,2.3,0.4,4.2,1.2,5.5
									c0.8,1.4,1.8,2,3.1,2C1940.5,2132.1,1941.6,2131.6,1942.4,2130.4z"
						/>
						<path
							d="M1959.6,2133.5c0.2-3,0.3-6.2,0.3-9.6c0-1.6,0-2.9-0.1-3.8s-0.2-1.6-0.4-2.1c-0.2-0.5-0.5-1-0.9-1.6
									c0.4-0.5,0.8-0.9,1.3-1.2c0.5-0.3,0.9-0.4,1.3-0.4c1.4,0,2.2,1.1,2.2,3.3c0.6-1,1.3-1.7,2.1-2.4c0.7-0.6,1.6-1.1,2.5-1.4
									c0.2,0.5,0.3,1.2,0.5,2.1c0.1,0.9,0.2,1.7,0.2,2.5c-0.3-0.1-0.7-0.1-1.3-0.1c-0.8,0-1.6,0.1-2.3,0.3c-0.7,0.2-1.3,0.5-1.8,0.8
									l0,1.4c-0.1,2-0.1,3.7-0.1,5.2c0,3.1,0.2,5.4,0.7,6.7c-0.7,0.2-1.4,0.3-2,0.3C1961.1,2133.8,1960.4,2133.7,1959.6,2133.5z"
						/>
						<path
							d="M1840.7,2147.2l0.4,0.3c-1.4,1.5-2.3,3.2-2.9,5.1c-0.6,1.9-0.9,4-0.9,6.6c0,2.5,0.3,4.7,0.9,6.6c0.6,1.9,1.5,3.5,2.9,5.1
									l-0.4,0.3c-1.8-1.5-3.1-3.3-4.1-5.3c-1-2-1.4-4.2-1.4-6.6s0.5-4.6,1.4-6.6C1837.6,2150.6,1838.9,2148.8,1840.7,2147.2z"
						/>
						<path
							d="M1845.7,2166.7c0.1-1,0.2-2.4,0.2-4.2c0-1.8,0.1-3.5,0.1-5.1c0-3.8-0.1-6.1-0.4-6.9c0.5-0.1,0.9-0.2,1.4-0.2
									c0.4,0,0.9,0.1,1.5,0.2c-0.1,1.8-0.2,4.1-0.2,7.1c0.9,0.1,2.1,0.2,3.5,0.2c0.2,0,0.9,0,2.1-0.1c0-3.8-0.1-6.2-0.4-7.1
									c0.5-0.1,0.9-0.2,1.4-0.2c0.4,0,0.9,0.1,1.5,0.2c-0.1,1-0.2,2.4-0.2,4.2s-0.1,3.5-0.1,5.1c0,3.7,0.1,6,0.4,6.9
									c-0.5,0.1-0.9,0.2-1.4,0.2c-0.4,0-0.9-0.1-1.4-0.2c0.1-1.7,0.2-4.1,0.3-7.1c-1-0.1-2.1-0.1-3.2-0.1c-0.8,0-1.5,0-2.3,0.1
									c0,3.8,0.1,6.2,0.4,7.2c-0.5,0.1-0.9,0.2-1.4,0.2C1846.7,2166.9,1846.2,2166.9,1845.7,2166.7z"
						/>
						<path
							d="M1862,2166.3c-0.4-0.5-0.6-1.2-0.6-2.1c0-2.5,2-3.9,5.9-4.2c0-1.4-0.2-2.3-0.5-2.8c-0.3-0.5-0.9-0.8-1.8-0.8
									c-0.5,0-1,0.1-1.6,0.3c-0.5,0.2-1,0.5-1.4,0.8l-0.2-0.6c0.5-0.9,1.1-1.6,1.8-2.2c0.8-0.6,1.6-0.8,2.4-0.8c1.1,0,1.9,0.4,2.5,1.1
									c0.6,0.7,0.9,1.7,0.9,3.1c0,0.7,0,1.6-0.1,2.5c-0.1,1-0.1,1.7-0.1,2.2c0,0.8,0.1,1.4,0.2,1.8c0.1,0.4,0.4,0.9,0.8,1.4
									c-0.6,0.7-1.3,1-2,1.2c-0.5-0.4-0.8-0.9-1-1.7c-0.6,0.5-1.2,0.9-1.9,1.2c-0.7,0.3-1.3,0.5-1.8,0.5
									C1862.9,2167.1,1862.4,2166.8,1862,2166.3z M1865.9,2165.2c0.5-0.2,0.9-0.5,1.3-0.9v-0.2c0-0.7,0-1.3,0.1-1.9l0.1-1.3
									c-1.1,0.1-2,0.4-2.6,0.9c-0.6,0.5-0.9,1.2-0.9,2c0,0.3,0.1,0.7,0.2,1s0.4,0.6,0.6,0.8C1865,2165.6,1865.4,2165.4,1865.9,2165.2z"
						/>
						<path
							d="M1876.2,2166.7c0.1-2.1,0.2-4.2,0.2-6.6c0-1.1,0-2-0.1-2.6s-0.2-1.1-0.3-1.4c-0.1-0.3-0.3-0.7-0.6-1.1
									c0.3-0.4,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.3,0.9-0.3c1,0,1.5,0.8,1.5,2.3c0.4-0.7,0.9-1.2,1.4-1.6s1.1-0.7,1.7-0.9
									c0.1,0.3,0.2,0.8,0.3,1.4c0.1,0.6,0.1,1.2,0.1,1.7c-0.2,0-0.5-0.1-0.9-0.1c-0.5,0-1,0.1-1.5,0.2c-0.5,0.2-0.9,0.3-1.2,0.6l0,1
									c0,1.4,0,2.6,0,3.6c0,2.2,0.2,3.7,0.5,4.6c-0.5,0.1-1,0.2-1.4,0.2S1876.8,2166.9,1876.2,2166.7z"
						/>
						<path
							d="M1887.2,2165.4c-0.8-1.2-1.3-2.7-1.3-4.5c0-1.4,0.2-2.6,0.7-3.6c0.5-1.1,1.1-1.9,1.9-2.5c0.8-0.6,1.8-0.9,2.8-0.9
									c0.9,0,1.8,0.3,2.5,0.8c0.7,0.5,1.3,1.3,1.7,2.2c0.4,0.9,0.6,2,0.6,3.3c0,1.4-0.2,2.6-0.7,3.6s-1.1,1.9-2,2.5
									c-0.9,0.6-1.8,0.9-2.9,0.9C1889.1,2167.1,1888,2166.5,1887.2,2165.4z M1893.2,2164.6c0.5-0.8,0.8-1.9,0.8-3.3
									c0-1.3-0.2-2.4-0.7-3.3c-0.5-0.9-1.2-1.5-2-1.9c-1,0-1.7,0.4-2.3,1.2c-0.6,0.8-0.9,1.9-0.9,3.3c0,1.6,0.3,2.9,0.8,3.8
									c0.5,0.9,1.2,1.4,2.1,1.4C1891.9,2165.8,1892.6,2165.4,1893.2,2164.6z"
						/>
						<path
							d="M1902.1,2166.7c0.1-2.1,0.2-4.2,0.2-6.6c0-1.1,0-2-0.1-2.6s-0.2-1.1-0.3-1.4c-0.1-0.3-0.3-0.7-0.6-1.1
									c0.3-0.4,0.6-0.7,0.9-0.8s0.6-0.3,0.8-0.3c0.9,0,1.4,0.6,1.5,1.8c0.4-0.6,0.9-1.1,1.5-1.4c0.6-0.3,1.2-0.5,1.8-0.5
									c2,0,2.9,1.4,2.9,4.2l0,1.9c-0.1,0.8-0.1,1.4-0.1,2.1c0,2.1,0.2,3.7,0.5,4.6c-0.5,0.1-0.9,0.2-1.3,0.2c-0.4,0-0.9-0.1-1.5-0.2
									c0.1-2.5,0.2-5.2,0.2-8.1c0-0.8-0.1-1.4-0.4-1.8c-0.3-0.4-0.7-0.5-1.3-0.5c-0.9,0-1.7,0.2-2.3,0.7l0,1.3c0,1-0.1,2.3-0.1,3.7
									c0,2.2,0.2,3.7,0.5,4.6c-0.5,0.1-1,0.2-1.4,0.2C1903.1,2166.9,1902.7,2166.9,1902.1,2166.7z"
						/>
						<path
							d="M1917.5,2165.5c-0.7-1.1-1-2.7-1-4.9c0-2.2,0.4-3.9,1.1-5c0.7-1.1,1.8-1.7,3.2-1.7c1.1,0,2.1,0.4,3,1.2
									c-0.1-2.1-0.3-3.6-0.5-4.6c0.5-0.2,0.9-0.4,1.4-0.5c0.4-0.1,0.9-0.1,1.4-0.1c-0.2,2.6-0.3,6.9-0.3,13c0,0.7,0.1,1.3,0.2,1.7
									s0.4,0.9,0.8,1.4c-0.6,0.6-1.2,1-2,1.1c-0.5-0.4-0.8-1-1-1.7c-0.5,0.6-1,1-1.5,1.3c-0.6,0.3-1.1,0.5-1.7,0.5
									C1919.3,2167.1,1918.2,2166.6,1917.5,2165.5z M1922.5,2165.1c0.4-0.2,0.8-0.5,1.2-0.9c0-0.6,0-1.7,0-3.2c0-1.7,0.1-3,0.1-4
									c-0.7-0.5-1.4-0.8-2.3-0.8c-0.9,0-1.5,0.4-2,1.1c-0.5,0.8-0.7,1.9-0.7,3.3c0,1.4,0.2,2.5,0.7,3.4s1,1.3,1.8,1.5
									C1921.6,2165.5,1922,2165.4,1922.5,2165.1z"
						/>
						<path
							d="M1932.7,2165.4c-0.8-1.2-1.3-2.7-1.3-4.5c0-1.4,0.2-2.6,0.7-3.6c0.5-1.1,1.1-1.9,1.9-2.5c0.8-0.6,1.8-0.9,2.8-0.9
									c0.9,0,1.8,0.3,2.5,0.8c0.7,0.5,1.3,1.3,1.7,2.2c0.4,0.9,0.6,2,0.6,3.3c0,1.4-0.2,2.6-0.7,3.6s-1.1,1.9-2,2.5
									c-0.9,0.6-1.8,0.9-2.9,0.9C1934.7,2167.1,1933.6,2166.5,1932.7,2165.4z M1938.7,2164.6c0.5-0.8,0.8-1.9,0.8-3.3
									c0-1.3-0.2-2.4-0.7-3.3c-0.5-0.9-1.2-1.5-2-1.9c-1,0-1.7,0.4-2.3,1.2c-0.6,0.8-0.9,1.9-0.9,3.3c0,1.6,0.3,2.9,0.8,3.8
									c0.5,0.9,1.2,1.4,2.1,1.4C1937.4,2165.8,1938.2,2165.4,1938.7,2164.6z"
						/>
						<path
							d="M1947.6,2166.7c0.1-2.1,0.2-4.2,0.2-6.6c0-1.1,0-2-0.1-2.6s-0.2-1.1-0.3-1.4c-0.1-0.3-0.3-0.7-0.6-1.1
									c0.3-0.4,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.3,0.9-0.3c1,0,1.5,0.8,1.5,2.3c0.4-0.7,0.9-1.2,1.4-1.6s1.1-0.7,1.7-0.9
									c0.1,0.3,0.2,0.8,0.3,1.4c0.1,0.6,0.1,1.2,0.1,1.7c-0.2,0-0.5-0.1-0.9-0.1c-0.5,0-1,0.1-1.5,0.2c-0.5,0.2-0.9,0.3-1.2,0.6l0,1
									c0,1.4,0,2.6,0,3.6c0,2.2,0.2,3.7,0.5,4.6c-0.5,0.1-1,0.2-1.4,0.2S1948.2,2166.9,1947.6,2166.7z"
						/>
						<path
							d="M1961.1,2152.6c1,2,1.4,4.2,1.4,6.6s-0.5,4.6-1.4,6.6c-1,2-2.3,3.8-4.1,5.3l-0.4-0.3c1.4-1.5,2.3-3.2,2.9-5.1
									s0.9-4,0.9-6.6c0-2.5-0.3-4.7-0.9-6.6s-1.5-3.5-2.9-5.1l0.4-0.3C1958.8,2148.8,1960.1,2150.6,1961.1,2152.6z"
						/>
					</g>
					<g className="level_zero location " id="sea_of_rhun">
						<rect x="2203.1" y="4405.279" width="143.054" height="96.911" transform="matrix(0.999976, 0.006935, -0.01898, 0.999892, 499.511414, -3529.016357)"></rect>
						<path
							d="M2628.3,929.9c0-0.8,0.1-1.5,0.2-2.1c0.1-0.6,0.3-1.3,0.6-1.9c0.7,0.9,1.7,1.6,3,2.2s2.7,0.8,4.2,0.8c1.7,0,3-0.4,4-1.2
									s1.4-2,1.4-3.5c0-1.3-0.4-2.3-1.3-3.2c-0.9-0.8-2.3-1.8-4.2-2.9c-1.7-1-3-1.9-4-2.7c-1-0.8-1.8-1.7-2.5-2.9
									c-0.7-1.2-1.1-2.6-1.1-4.3c0-2.2,0.9-4,2.6-5.4s4.1-2.1,7-2.1c2.7,0,4.9,0.5,6.8,1.4c0,0.8-0.1,1.8-0.4,2.9
									c-0.3,1.1-0.6,1.9-0.9,2.5c-1.3-1.2-2.7-2.1-4.2-2.9c-1.4-0.8-2.6-1.2-3.5-1.2c-0.7,0.2-1.3,0.6-2,1.4c-0.6,0.8-1,1.7-1,2.8
									c0,1.4,0.5,2.5,1.4,3.4c0.9,0.9,2.4,1.9,4.3,3.1c1.6,1,2.9,1.8,3.8,2.5c1,0.7,1.8,1.7,2.5,2.8c0.7,1.2,1,2.5,1,4.1
									c0,2.7-0.8,4.8-2.4,6.3c-1.6,1.5-4,2.2-7.1,2.2C2633.5,931.9,2630.7,931.2,2628.3,929.9z"
						/>
						<path
							d="M2654.2,928.9c-1.4-2-2-4.9-2-8.6c0-2.5,0.4-4.8,1.2-6.8c0.8-2,2-3.5,3.5-4.7c1.5-1.1,3.2-1.7,5.1-1.7c2.6,0,4.5,1,5.8,3
									c1.3,2,2,5,2,8.9c-1.7,0.5-3.7,0.9-6.1,1.3c-2.4,0.4-4.8,0.6-7.1,0.7c0.2,2.4,0.7,4.1,1.6,5.1c0.9,1.1,2.2,1.6,3.9,1.6
									c1.3,0,2.6-0.2,3.7-0.5c1.2-0.4,2.2-0.9,3-1.5l0.3,1.1c-1,1.5-2.3,2.8-4,3.7c-1.6,0.9-3.3,1.4-5.1,1.4
									C2657.5,931.9,2655.5,930.9,2654.2,928.9z M2661.2,918.3c1.5-0.4,2.8-0.9,3.6-1.5c0-2-0.5-3.7-1.5-5c-0.9-1.3-2.2-2-3.6-2
									c-1,0.7-1.8,1.9-2.4,3.6c-0.6,1.7-0.9,3.6-0.9,5.8C2658.1,919,2659.6,918.7,2661.2,918.3z"
						/>
						<path
							d="M2677.4,930.4c-0.8-1-1.2-2.3-1.2-3.9c0-4.7,3.8-7.3,11.3-7.9c0-2.5-0.3-4.3-0.9-5.3c-0.6-1-1.7-1.5-3.4-1.5
									c-1,0-2,0.2-3,0.6c-1,0.4-1.9,0.9-2.8,1.6l-0.4-1.1c0.9-1.7,2-3.1,3.5-4.1c1.5-1.1,3-1.6,4.5-1.6c2.1,0,3.7,0.7,4.8,2
									c1.1,1.3,1.7,3.2,1.7,5.8c0,1.4-0.1,2.9-0.2,4.7c-0.1,1.9-0.2,3.2-0.2,4.1c0,1.5,0.1,2.6,0.4,3.4c0.3,0.8,0.8,1.7,1.5,2.7
									c-1.2,1.2-2.5,2-3.9,2.2c-0.9-0.7-1.5-1.8-1.8-3.2c-1.2,1-2.4,1.7-3.6,2.3c-1.2,0.6-2.4,0.9-3.5,0.9
									C2679.1,931.9,2678.2,931.4,2677.4,930.4z M2684.7,928.4c0.9-0.5,1.7-1,2.4-1.7v-0.3c0-1.2,0-2.4,0.1-3.6l0.1-2.5
									c-2.2,0.2-3.9,0.7-5,1.6c-1.2,0.9-1.8,2.2-1.8,3.8c0,0.7,0.1,1.3,0.4,1.9s0.7,1.1,1.2,1.5C2683,929.1,2683.9,928.8,2684.7,928.4z"
						/>
						<path
							d="M2716,928.7c-1.6-2.2-2.4-5-2.4-8.5c0-2.5,0.4-4.8,1.3-6.8c0.9-2,2.1-3.5,3.7-4.6s3.4-1.6,5.4-1.6c1.8,0,3.4,0.5,4.8,1.5
									c1.4,1,2.4,2.4,3.2,4.1c0.8,1.8,1.1,3.8,1.1,6.1c0,2.5-0.5,4.8-1.4,6.8c-0.9,2-2.2,3.5-3.8,4.6s-3.5,1.6-5.6,1.6
									C2719.7,931.9,2717.6,930.9,2716,928.7z M2727.4,927.3c1-1.5,1.5-3.6,1.5-6.3c0-2.4-0.5-4.5-1.4-6.2c-0.9-1.7-2.2-2.9-3.8-3.5
									c-1.9,0-3.3,0.8-4.4,2.3c-1.1,1.5-1.7,3.6-1.7,6.2c0,3,0.5,5.4,1.6,7.1c1,1.8,2.4,2.6,4,2.6C2725,929.5,2726.4,928.8,2727.4,927.3
									z"
						/>
						<path
							d="M2739.7,939.9c0.7-1,1.2-1.9,1.5-2.8c0.4-0.9,0.6-2.1,0.8-3.6c0.2-1.5,0.2-3.6,0.2-6.2c0-3-0.1-8.3-0.4-15.8
									c-1.1,0-1.8-0.2-2.2-0.3c-0.4-0.2-0.6-0.6-0.6-1.1c1.3-0.8,2.2-1.5,2.7-2c0.4-3.2,1.3-5.8,2.8-7.7c1.5-1.9,3.3-2.9,5.5-2.9
									c1.5,0,3,0.3,4.5,0.8c0,0.6-0.1,1.4-0.4,2.4c-0.3,1-0.6,2-1,2.8c-0.9-1.1-1.9-2-3-2.6c-1-0.7-1.9-1-2.5-1
									c-0.5,0.5-0.9,1.3-1.2,2.5c-0.2,1.2-0.4,3.2-0.4,6c2.4-0.1,4.1-0.2,5-0.5c0,0.8-0.1,1.5-0.2,2.1c-0.1,0.6-0.3,1.3-0.6,2
									c-1.1-0.4-2.5-0.7-4.3-0.7v4c0,2.3,0.1,5,0.2,8.1c0.1,1.6,0.1,3,0.1,4.2c0,1.9-0.2,3.5-0.5,4.9s-0.9,2.6-1.8,3.8
									c-0.9,1.2-2.2,2.6-3.9,4.2L2739.7,939.9z"
						/>
						<path
							d="M2630.2,979.3c0.1-1.9,0.3-4.6,0.4-8.1c0.1-3.5,0.2-6.6,0.2-9.4c0-6.4-0.3-10.3-0.9-11.7c1.1-0.5,2.6-0.8,4.4-1.1
									s3.7-0.4,5.6-0.4c3.6,0,6.4,0.9,8.4,2.6c2,1.8,2.9,4.3,2.9,7.5c0,2-0.5,3.7-1.4,5.2c-1,1.5-2.3,2.7-4.1,3.6
									c2.8,5.8,5,9.4,6.7,10.9c-1,0.5-2,0.8-3,1c-1,0.2-2.2,0.3-3.5,0.3c-0.8-2.1-2.5-5.7-5.1-10.8c-0.1,0-0.5,0.1-1,0.1
									c-0.7,0-1.5,0-2.4-0.1c-0.9-0.1-1.5-0.2-2-0.3c0,5.6,0.3,9.2,0.9,10.9c-1,0.2-2,0.3-3,0.3C2632.4,979.6,2631.4,979.5,2630.2,979.3
									z M2644.7,963.2c0.9-1.2,1.3-2.5,1.3-4c0-4.6-2.4-6.9-7.3-6.9c-1.1,0-2,0.1-2.9,0.4c-0.2,2.8-0.3,6.7-0.4,11.8
									c2.5,1,4.5,1.4,5.9,1.4C2642.7,965.3,2643.9,964.4,2644.7,963.2z"
						/>
						<path
							d="M2660.6,979.3c0.3-4.9,0.4-9.8,0.4-14.7c0-6.7-0.2-11.9-0.5-15.7c1.8-0.8,3.8-1.2,6-1.2c-0.2,1.8-0.5,5.6-0.8,11.2
									c1-1.2,2.2-2.1,3.5-2.7c1.3-0.7,2.6-1,4-1c4.2,0,6.4,2.7,6.4,8l-0.1,3.6l-0.1,3.9c0,4,0.4,6.9,1.1,8.7c-1,0.2-2,0.3-2.9,0.3
									c-0.9,0-1.9-0.1-3.2-0.3c0.3-5.5,0.4-10.5,0.4-15.1c0-1.5-0.3-2.6-0.9-3.3c-0.6-0.7-1.6-1-2.9-1c-1.9,0-3.6,0.5-5.1,1.5
									c-0.1,1.1-0.1,2.7-0.1,4.7c0,7.2,0.4,11.6,1.1,13.2c-1,0.2-2,0.3-3,0.3C2662.8,979.6,2661.8,979.5,2660.6,979.3z"
						/>
						<path
							d="M2691.2,972l0.1-3.6l0.1-3.9c0-3.8-0.3-6.5-1-8.1c1.8-0.8,3.8-1.2,6-1.2c-0.2,4.7-0.3,10.1-0.3,16.2
									c0,1.5,0.3,2.7,0.9,3.4c0.6,0.7,1.6,1.1,3,1.1c1.8,0,3.4-0.4,4.8-1.3c0-2.6,0.1-4.9,0.2-6.9l0.1-3.2c0-3.8-0.3-6.5-1-8.1
									c1.8-0.8,3.8-1.2,6-1.2c-0.1,2.7-0.3,5.8-0.4,9.5c-0.1,3.6-0.2,6.1-0.2,7.3c0,1.4,0.1,2.5,0.4,3.3c0.3,0.8,0.9,1.7,1.7,2.6
									c-1.3,1.2-2.8,1.9-4.4,2.1c-1.1-0.8-1.8-2-2.1-3.5c-1,1.1-2.1,1.9-3.5,2.5c-1.3,0.6-2.7,0.9-4.1,0.9
									C2693.3,979.9,2691.2,977.3,2691.2,972z M2693.6,951.4c1.9-2.5,3.5-4.8,4.9-6.9c1.3-0.5,3-0.8,4.9-0.8c1,1.9,1.8,3.2,2.4,4.2
									c0.6,0.9,1.4,2,2.4,3.2c-1.5,0.5-3.2,0.8-5,0.8l-3.2-5l-3,5C2695.8,951.8,2694.7,951.7,2693.6,951.4z"
						/>
						<path
							d="M2722.2,979.3c0.3-3.9,0.4-8,0.4-12.3c0-2.1-0.1-3.7-0.2-4.9c-0.1-1.1-0.3-2-0.6-2.7c-0.3-0.7-0.7-1.3-1.3-2
									c0.6-0.7,1.2-1.3,1.8-1.6c0.7-0.3,1.3-0.5,1.8-0.5c1.9,0,3,1.2,3.3,3.5c0.9-1.2,2.1-2.1,3.3-2.7c1.3-0.7,2.6-1,3.9-1
									c4.2,0,6.4,2.7,6.4,8l-0.1,3.6c-0.1,1.4-0.2,2.7-0.2,3.9c0,4,0.4,6.9,1.1,8.7c-1,0.2-2,0.3-2.9,0.3c-0.9,0-2-0.1-3.3-0.3
									c0.3-4.7,0.4-9.7,0.4-15.1c0-1.5-0.3-2.6-0.9-3.3c-0.6-0.7-1.5-1-2.9-1c-2,0-3.6,0.4-4.9,1.3l-0.1,2.5c-0.1,2-0.2,4.3-0.2,7
									c0,4,0.4,6.9,1.1,8.7c-1.1,0.2-2.1,0.3-3.1,0.3C2724.5,979.6,2723.4,979.5,2722.2,979.3z"
						/>
					</g>
					<g className="level_two fade location" id="edhellond">
						<rect className="level_zero location" x="1566.5" y="1773.8" transform="matrix(0.866 0.5 -0.5 0.866 1101.115 -549.667)" width="19.4" height="12.2" />
						<rect x="1715.278" y="1083.049" width="111.376" height="23.826" transform="matrix(0.987576, -0.157143, 0.157143, 0.987576, -278.024353, 974.248657)"></rect>
						<rect x="325.429" y="1607.591" width="21.131" height="35.367" transform="matrix(0.500607, -0.865675, 0.859642, 0.511038, 13.514164, 1241.469116)"></rect>
						<path
							d="M1590.2,1788.1c-0.1-1.1-0.2-2.6-0.3-4.5c-0.3-3.4-0.6-5.7-1-6.9c0.9,0.1,2.2,0,4-0.1c2.2-0.2,3.9-0.4,5.1-0.6
									c0,0.6,0,1.2,0,1.8c0,0.6-0.1,1.1-0.3,1.4c-0.4-0.2-1-0.4-1.6-0.5c-0.6-0.1-1.3-0.1-2-0.1c-0.9,0.1-1.7,0.2-2.4,0.4
									c0.1,1.7,0.2,3.3,0.2,4.6l0.6,0c0.6,0,1.1,0,1.4-0.1c0.8-0.1,1.4-0.1,1.8-0.2c0.4-0.1,0.9-0.2,1.4-0.4c0.1,0.4,0.2,0.8,0.2,1.1
									c0,0.3,0,0.7,0,1.2c-1.8,0-3.3,0-4.3,0.1c-0.5,0-0.8,0.1-1,0.1c0.2,2.2,0.3,3.8,0.5,4.7c0.8,0.1,1.7,0.2,2.6,0.1
									c0.7-0.1,1.5-0.2,2.2-0.4c0.7-0.2,1.3-0.5,1.8-0.8c0,0.6,0,1.2,0,1.8c0,0.7-0.1,1.1-0.3,1.3c-1.1-0.1-2.6,0-4.6,0.2
									c-1.8,0.1-3.1,0.3-4,0.5C1590.3,1790.8,1590.2,1789.2,1590.2,1788.1z"
						/>
						<path
							d="M1604,1790.5c-0.8-1-1.3-2.6-1.5-4.8c-0.2-2.2,0-3.9,0.6-5.1s1.6-1.9,3-2c1.1-0.1,2.1,0.2,3.1,0.9c-0.3-2-0.6-3.5-0.9-4.5
									c0.5-0.3,0.9-0.5,1.3-0.6c0.4-0.1,0.9-0.2,1.4-0.3c0,2.6,0.4,6.9,1,13c0.1,0.7,0.2,1.3,0.4,1.7c0.2,0.4,0.5,0.8,0.9,1.3
									c-0.5,0.7-1.1,1.1-1.9,1.3c-0.5-0.4-0.9-0.9-1.1-1.6c-0.4,0.6-0.9,1.1-1.4,1.4c-0.5,0.4-1.1,0.6-1.6,0.6
									C1605.8,1791.9,1604.8,1791.5,1604,1790.5z M1608.8,1789.7c0.4-0.3,0.8-0.6,1.1-1c-0.1-0.6-0.1-1.7-0.3-3.2c-0.1-1.7-0.2-3-0.3-4
									c-0.7-0.4-1.5-0.6-2.4-0.5c-0.9,0.1-1.5,0.5-1.9,1.3c-0.4,0.8-0.5,1.9-0.4,3.3c0.1,1.4,0.5,2.5,1,3.3c0.5,0.8,1.2,1.2,2,1.3
									C1608,1790.1,1608.4,1790,1608.8,1789.7z"
						/>
						<path
							d="M1617.4,1790.4c-0.2-2.6-0.4-5.2-0.7-7.8c-0.4-3.6-0.8-6.3-1.2-8.3c0.8-0.5,1.7-0.8,2.7-0.9c0,1,0.1,3,0.3,6
									c0.4-0.7,0.9-1.2,1.4-1.6c0.6-0.4,1.1-0.7,1.8-0.7c1.9-0.2,3.1,1.1,3.4,3.9l0.2,1.9l0.2,2.1c0.2,2.1,0.6,3.7,1,4.5
									c-0.5,0.2-0.9,0.3-1.3,0.3c-0.4,0-0.9,0-1.5,0c-0.2-2.9-0.4-5.6-0.7-8c-0.1-0.8-0.3-1.4-0.6-1.7c-0.3-0.3-0.8-0.5-1.4-0.4
									c-0.9,0.1-1.6,0.4-2.3,1.1c0,0.6,0.1,1.4,0.2,2.5c0.4,3.8,0.9,6.1,1.3,7c-0.4,0.2-0.9,0.3-1.4,0.3
									C1618.4,1790.5,1617.9,1790.5,1617.4,1790.4z"
						/>
						<path
							d="M1631.3,1787.5c-0.8-1-1.4-2.5-1.7-4.4c-0.2-1.3-0.1-2.6,0.2-3.7c0.3-1.1,0.8-2,1.5-2.7s1.5-1.1,2.5-1.2
									c1.3-0.2,2.4,0.2,3.2,1.2s1.4,2.5,1.7,4.6c-0.8,0.4-1.9,0.7-3.1,1.1c-1.2,0.4-2.4,0.6-3.6,0.8c0.3,1.2,0.7,2.1,1.2,2.6
									c0.5,0.5,1.2,0.7,2.1,0.6c0.7-0.1,1.3-0.3,1.9-0.5c0.6-0.3,1.1-0.6,1.4-1l0.2,0.6c-0.4,0.9-1,1.6-1.8,2.2c-0.8,0.6-1.6,1-2.5,1.1
									C1633.3,1788.9,1632.2,1788.5,1631.3,1787.5z M1634.2,1781.5c0.8-0.3,1.4-0.7,1.8-1c-0.2-1.1-0.5-1.9-1.1-2.6
									c-0.6-0.6-1.3-0.9-2-0.8c-0.5,0.4-0.8,1.1-1,2.1c-0.2,0.9-0.2,2-0.1,3.1C1632.7,1782.1,1633.5,1781.8,1634.2,1781.5z"
						/>
						<path
							d="M1644.7,1786.7c-0.3-0.3-0.5-1-0.6-1.9c-0.1-0.6-0.2-1.6-0.4-3c-0.2-1.5-0.4-2.8-0.5-3.8c-0.5-3.3-1-5.6-1.6-6.9
									c0.4-0.3,0.8-0.5,1.3-0.7c0.4-0.2,0.9-0.3,1.4-0.4c0.2,2.5,0.7,6.2,1.4,10.9c0.2,1.1,0.3,1.9,0.5,2.5c0.2,0.6,0.3,1,0.5,1.4
									c0.2,0.3,0.4,0.6,0.8,1c-0.2,0.4-0.5,0.7-0.7,1c-0.3,0.2-0.6,0.4-0.8,0.4C1645.3,1787.2,1644.9,1787,1644.7,1786.7z"
						/>
						<path
							d="M1652.3,1785.5c-0.3-0.3-0.5-1-0.6-1.9c-0.1-0.6-0.3-1.6-0.5-3c-0.2-1.5-0.4-2.8-0.6-3.8c-0.6-3.3-1.1-5.6-1.7-6.9
									c0.4-0.3,0.8-0.5,1.2-0.7c0.4-0.2,0.9-0.3,1.4-0.4c0.2,2.5,0.7,6.2,1.5,10.8c0.2,1.1,0.4,1.9,0.5,2.5c0.2,0.6,0.3,1,0.5,1.4
									s0.5,0.6,0.8,1c-0.2,0.4-0.4,0.7-0.7,1c-0.3,0.2-0.5,0.4-0.8,0.4C1653,1785.9,1652.6,1785.8,1652.3,1785.5z"
						/>
						<path
							d="M1660,1783c-1-1-1.8-2.4-2.1-4.2c-0.3-1.3-0.3-2.6-0.1-3.7c0.2-1.1,0.7-2.1,1.4-2.8c0.7-0.7,1.6-1.2,2.6-1.4
									c0.9-0.2,1.8-0.1,2.6,0.3c0.8,0.4,1.5,1,2.1,1.8c0.6,0.8,1,1.9,1.2,3.1c0.3,1.3,0.3,2.6,0,3.7s-0.7,2.1-1.5,2.8
									c-0.7,0.7-1.6,1.2-2.7,1.4C1662.3,1784.3,1661.1,1784,1660,1783z M1665.7,1781.1c0.4-0.9,0.4-2,0.1-3.4c-0.3-1.2-0.7-2.3-1.4-3.1
									c-0.7-0.8-1.4-1.3-2.3-1.4c-0.9,0.2-1.6,0.7-2,1.7c-0.4,0.9-0.5,2-0.2,3.4c0.3,1.6,0.8,2.8,1.6,3.6c0.7,0.8,1.5,1.1,2.3,1
									C1664.7,1782.5,1665.4,1782,1665.7,1781.1z"
						/>
						<path
							d="M1674.7,1781.2c-0.4-2-1-4.1-1.6-6.4c-0.3-1.1-0.6-1.9-0.8-2.5c-0.2-0.6-0.4-1-0.7-1.3c-0.2-0.3-0.5-0.6-0.9-0.9
									c0.2-0.4,0.3-0.8,0.6-1c0.2-0.2,0.5-0.4,0.7-0.5c0.9-0.2,1.5,0.2,2,1.4c0.2-0.7,0.6-1.3,1.1-1.8c0.5-0.5,1-0.8,1.6-1
									c1.9-0.5,3.2,0.6,4,3.3l0.5,1.9c0.2,0.7,0.3,1.4,0.5,2c0.6,2,1.2,3.5,1.8,4.3c-0.4,0.2-0.8,0.4-1.2,0.5c-0.4,0.1-0.9,0.2-1.5,0.2
									c-0.6-2.4-1.2-5-2-7.8c-0.2-0.8-0.5-1.3-0.9-1.6c-0.4-0.3-0.8-0.3-1.4-0.2c-0.9,0.3-1.6,0.7-2,1.3l0.3,1.3c0.2,1,0.6,2.2,0.9,3.6
									c0.6,2.1,1.2,3.5,1.7,4.3c-0.4,0.3-0.9,0.4-1.3,0.6C1675.7,1781.1,1675.2,1781.2,1674.7,1781.2z"
						/>
						<path
							d="M1688.8,1775.6c-1-0.8-1.9-2.2-2.7-4.2c-0.8-2.1-1-3.8-0.7-5.1s1.1-2.2,2.4-2.7c1-0.4,2.1-0.3,3.3,0.1
									c-0.8-1.9-1.5-3.3-2-4.1c0.4-0.4,0.7-0.7,1.1-0.9c0.4-0.2,0.8-0.4,1.3-0.6c0.7,2.5,2.1,6.6,4.2,12.3c0.2,0.7,0.5,1.2,0.8,1.5
									c0.3,0.3,0.7,0.7,1.2,1c-0.3,0.8-0.8,1.4-1.5,1.7c-0.6-0.2-1.1-0.7-1.5-1.3c-0.2,0.7-0.6,1.3-1,1.7c-0.4,0.5-0.9,0.8-1.4,1
									C1691,1776.5,1689.9,1776.4,1688.8,1775.6z M1693.3,1773.6c0.3-0.4,0.6-0.8,0.8-1.3c-0.2-0.6-0.6-1.6-1.1-3.1
									c-0.6-1.6-1-2.8-1.3-3.7c-0.8-0.3-1.6-0.2-2.4,0.1c-0.8,0.3-1.3,0.9-1.5,1.8s0,2,0.5,3.3c0.5,1.3,1.1,2.3,1.8,3
									c0.7,0.6,1.4,0.9,2.2,0.8C1692.7,1774.2,1693,1773.9,1693.3,1773.6z"
						/>
					</g>
					<g className="level_two fade location" id="dol_amroth">
						<rect x="1813.525" y="1143.253" width="117.758" height="25.151" transform="matrix(-0.865264, 0.501316, -0.49089, -0.871305, 3784.397949, 1901.756714)"></rect>
						<path
							d="M1558.6,1867.6c-0.6,0.3-1.1,0.6-1.5,0.8c-0.5-0.7-1.1-1.7-2-2.9c-0.9-1.2-1.7-2.3-2.5-3.4c-1.8-2.3-3-3.7-3.6-4.1
									c0.2-0.5,0.6-1,1.2-1.5c0.5-0.6,1.1-1.1,1.7-1.6c1.5-1.2,3.1-1.6,4.6-1.2c1.6,0.3,3,1.4,4.4,3.2c0.9,1.1,1.4,2.3,1.7,3.5
									c0.2,1.2,0.2,2.3-0.2,3.4s-1.1,2-2.1,2.8C1559.7,1866.9,1559.2,1867.2,1558.6,1867.6z M1560.9,1863c0.1-0.7,0-1.5-0.3-2.3
									c-0.2-0.8-0.6-1.5-1.1-2.2c-2.4-3.1-4.7-3.7-7-2c-0.4,0.3-0.7,0.6-1,1c1.1,1.6,2.3,3.3,3.6,5c1,1.4,2,2.5,2.7,3.4
									c1.1-0.4,1.8-0.7,2.3-1.1C1560.6,1864.3,1560.8,1863.8,1560.9,1863z"
						/>
						<path
							d="M1568.2,1858.8c-1.2-0.4-2.2-1.2-3.2-2.5c-0.7-0.9-1.2-1.9-1.4-2.9s-0.2-1.9,0.1-2.7c0.3-0.8,0.8-1.5,1.5-2.1
									c0.7-0.5,1.4-0.7,2.1-0.7s1.5,0.2,2.3,0.7c0.8,0.5,1.5,1.1,2.1,2c0.7,0.9,1.1,1.9,1.4,2.9c0.2,1,0.2,1.9-0.1,2.7
									c-0.3,0.8-0.8,1.5-1.6,2.1C1570.4,1859,1569.3,1859.1,1568.2,1858.8z M1571.9,1855.2c0-0.8-0.4-1.7-1.2-2.7
									c-0.7-0.9-1.4-1.5-2.2-1.9c-0.8-0.4-1.6-0.5-2.4-0.3c-0.7,0.5-1,1.2-1,2s0.4,1.8,1.1,2.7c0.8,1.1,1.7,1.9,2.5,2.2
									c0.9,0.4,1.6,0.3,2.2-0.1C1571.7,1856.6,1572,1856,1571.9,1855.2z"
						/>
						<path
							d="M1578.7,1852.3c-0.3-0.1-0.7-0.6-1.2-1.2c-0.3-0.4-0.8-1.2-1.5-2.2c-0.8-1.1-1.4-2-1.9-2.7c-1.7-2.3-3-3.9-3.9-4.7
									c0.2-0.4,0.4-0.7,0.7-1c0.3-0.3,0.6-0.6,0.9-0.8c1.1,1.9,2.9,4.5,5.3,7.8c0.6,0.8,1,1.4,1.4,1.8c0.3,0.4,0.7,0.7,0.9,0.9
									c0.3,0.2,0.6,0.3,1,0.4c0,0.4-0.1,0.7-0.2,1s-0.3,0.5-0.5,0.6C1579.4,1852.4,1579,1852.4,1578.7,1852.3z"
						/>
						<path
							d="M1595.7,1839.6l-2.9-2.1c-0.6,0.4-1.3,0.9-2,1.5c-0.8,0.7-1.4,1.2-1.6,1.5c0.4,1,0.9,2.1,1.4,3.3
									c-0.1,0.2-0.3,0.4-0.5,0.6c-0.2,0.2-0.5,0.3-0.8,0.4c-0.2-0.9-1-3-2.3-6.2s-2.3-5.7-3.1-7.5c0.2-0.3,0.5-0.5,0.7-0.7
									c0.2-0.2,0.5-0.3,0.8-0.4c1.6,1.1,3.8,2.7,6.6,4.6c2.9,1.9,4.7,3.1,5.6,3.6c-0.2,0.3-0.5,0.6-0.8,0.9
									C1596.4,1839.2,1596.1,1839.5,1595.7,1839.6z M1591.4,1836.6c-2-1.4-3.8-2.6-5.2-3.4c0.5,1.5,1.3,3.4,2.2,5.7
									c0.6-0.4,1.3-0.9,2-1.5C1590.9,1837,1591.2,1836.8,1591.4,1836.6z"
						/>
						<path
							d="M1600,1835.6c-0.9-1.6-1.8-3.2-2.9-4.9c-0.5-0.8-0.9-1.4-1.2-1.9c-0.3-0.4-0.6-0.7-0.8-0.9c-0.3-0.2-0.6-0.4-0.9-0.5
									c0-0.4,0.1-0.7,0.2-1c0.1-0.3,0.3-0.5,0.5-0.6c0.6-0.4,1.3-0.2,1.9,0.6c0-0.6,0.2-1.2,0.5-1.7c0.3-0.5,0.6-0.9,1-1.2
									c1-0.6,2-0.5,3,0.4c0-0.7,0.1-1.3,0.4-1.9c0.3-0.6,0.6-1,1.1-1.3c1.4-0.9,2.8-0.3,4.1,1.8l0.9,1.4c0.3,0.6,0.6,1.1,0.9,1.5
									c1,1.5,1.8,2.6,2.5,3.1c-0.3,0.3-0.6,0.6-0.9,0.7c-0.3,0.2-0.7,0.4-1.2,0.6c-1-1.9-2.2-3.9-3.6-6c-0.4-0.6-0.7-1-1.1-1.1
									c-0.4-0.1-0.8-0.1-1.2,0.2c-0.6,0.4-1,0.9-1.3,1.5c0.4,0.5,0.9,1.4,1.5,2.5c0.2,0.3,0.4,0.7,0.7,1.2c1,1.6,1.8,2.6,2.5,3.2
									c-0.3,0.3-0.6,0.5-0.9,0.7c-0.3,0.2-0.7,0.4-1.2,0.6c-1-1.9-2.2-3.9-3.6-6c-0.4-0.6-0.7-1-1.1-1.1c-0.4-0.1-0.8-0.1-1.2,0.2
									c-0.6,0.4-1,0.9-1.3,1.5l0.6,1c0.4,0.8,1,1.7,1.6,2.8c1,1.6,1.8,2.6,2.5,3.2c-0.3,0.3-0.6,0.6-1,0.8
									C1600.9,1835.3,1600.5,1835.5,1600,1835.6z"
						/>
						<path
							d="M1615.6,1827.2c-0.6-1.7-1.3-3.4-2.1-5.3c-0.4-0.9-0.7-1.6-1-2c-0.3-0.5-0.5-0.8-0.7-1s-0.5-0.4-0.9-0.6
									c0.1-0.4,0.2-0.7,0.4-1c0.2-0.3,0.4-0.4,0.6-0.5c0.8-0.3,1.4,0.1,2,1.3c0.1-0.7,0.3-1.3,0.5-1.8c0.2-0.5,0.6-0.9,1-1.3
									c0.2,0.2,0.5,0.6,0.7,1c0.3,0.5,0.5,0.9,0.7,1.3c-0.2,0-0.4,0.1-0.7,0.2c-0.4,0.2-0.8,0.4-1.1,0.7c-0.3,0.3-0.6,0.6-0.7,0.9
									l0.3,0.8c0.4,1.1,0.8,2,1.2,2.9c0.7,1.7,1.4,2.9,2,3.5c-0.4,0.3-0.7,0.5-1,0.6S1616,1827.1,1615.6,1827.2z"
						/>
						<path
							d="M1623.3,1822.7c-1-0.7-1.9-1.7-2.5-3.2c-0.4-1.1-0.6-2.1-0.6-3.1c0-1,0.3-1.9,0.8-2.6s1.1-1.3,2-1.6
									c0.8-0.3,1.5-0.4,2.2-0.2c0.7,0.2,1.4,0.6,2,1.2c0.6,0.6,1.1,1.4,1.5,2.4c0.4,1.1,0.6,2.1,0.6,3.1c0,1-0.3,1.9-0.8,2.6
									c-0.5,0.7-1.2,1.3-2,1.6C1625.4,1823.4,1624.4,1823.3,1623.3,1822.7z M1627.9,1820.1c0.2-0.8,0-1.8-0.4-2.9c-0.4-1-1-1.8-1.6-2.4
									s-1.4-0.9-2.2-0.9c-0.8,0.3-1.3,0.9-1.5,1.7s-0.1,1.8,0.4,2.9c0.5,1.3,1.1,2.2,1.9,2.8c0.7,0.6,1.4,0.7,2.1,0.5
									C1627.2,1821.5,1627.7,1821,1627.9,1820.1z"
						/>
						<path
							d="M1635.6,1818.9c-0.3-0.2-0.6-0.7-0.9-1.4c-0.2-0.5-0.5-1.4-0.9-2.5c-0.5-1.3-0.8-2.3-1.2-3.1c-0.2-0.4-0.3-0.8-0.5-1.1
									c-0.5,0.2-0.9,0.3-1.1,0.3s-0.4-0.1-0.5-0.4c0.6-0.9,1.2-2.1,1.5-3.5l0.5-0.1c0.3,0.5,0.5,1.1,0.8,1.7c0.9-0.4,1.5-0.7,1.9-1
									c0.1,0.3,0.2,0.7,0.3,1c0,0.3,0.1,0.6,0,0.9c-0.5,0-1.1,0.1-1.8,0.3c0.2,0.8,0.5,1.5,0.8,2.4c0.8,2,1.5,3.5,2.1,4.5
									c0.6-0.3,1.1-0.6,1.4-0.8l0.3,0.5c-0.1,0.6-0.3,1.1-0.6,1.5c-0.3,0.4-0.7,0.8-1.1,0.9C1636.2,1819.1,1635.9,1819.1,1635.6,1818.9z
									"
						/>
						<path
							d="M1642.5,1816.5c-0.7-2.2-1.4-4.3-2.2-6.4c-1-2.9-1.9-5.1-2.7-6.7c0.5-0.6,1.2-1,2.1-1.3c0.2,0.8,0.7,2.5,1.5,5
									c0.2-0.6,0.5-1.2,0.9-1.7c0.4-0.5,0.8-0.8,1.3-1c1.6-0.6,2.8,0.3,3.6,2.6l0.5,1.6l0.6,1.7c0.6,1.8,1.2,3,1.8,3.6
									c-0.3,0.2-0.7,0.4-1,0.5c-0.3,0.1-0.7,0.2-1.3,0.3c-0.8-2.4-1.5-4.6-2.2-6.6c-0.2-0.7-0.5-1.1-0.9-1.3c-0.3-0.2-0.7-0.2-1.2,0
									c-0.7,0.3-1.3,0.7-1.7,1.3c0.1,0.5,0.4,1.2,0.7,2.1c1.1,3.1,2,5,2.5,5.6c-0.3,0.2-0.7,0.4-1.1,0.6
									C1643.4,1816.4,1643,1816.5,1642.5,1816.5z"
						/>
					</g>
					<g className="level_one location" id="dor_en_ernil">
						<rect x="1986.347" y="1184.914" width="128.978" height="26.068" transform="matrix(-0.759836, 0.650114, -0.640958, -0.76767, 4023.751953, 1454.534058)"></rect>
						<path
							d="M1657.5,1912.2c-0.6,0.4-1.2,0.6-1.7,0.9c-0.5-0.8-1.2-1.9-2.1-3.2c-0.9-1.3-1.8-2.6-2.7-3.8c-1.9-2.6-3.2-4.2-3.9-4.6
									c0.3-0.5,0.7-1.1,1.3-1.7c0.6-0.6,1.3-1.2,2-1.7c1.7-1.3,3.4-1.7,5.1-1.3c1.7,0.4,3.3,1.6,4.8,3.7c0.9,1.3,1.5,2.6,1.8,3.9
									c0.2,1.3,0.1,2.6-0.3,3.7c-0.5,1.2-1.2,2.2-2.4,3C1658.7,1911.4,1658.1,1911.8,1657.5,1912.2z M1660.1,1907.2
									c0.1-0.8,0-1.7-0.2-2.6c-0.3-0.9-0.7-1.7-1.2-2.5c-2.5-3.5-5.1-4.2-7.7-2.3c-0.4,0.3-0.8,0.7-1.1,1.1c1.2,1.8,2.5,3.7,3.9,5.6
									c1.1,1.5,2.1,2.8,2.9,3.8c1.2-0.4,2-0.8,2.5-1.1C1659.8,1908.7,1660.1,1908,1660.1,1907.2z"
						/>
						<path
							d="M1667.5,1903c-1.3-0.4-2.5-1.3-3.6-2.7c-0.8-1-1.3-2.1-1.6-3.1s-0.3-2.1,0-3c0.3-0.9,0.8-1.7,1.6-2.3
									c0.7-0.5,1.5-0.8,2.3-0.8c0.8,0,1.7,0.2,2.5,0.7c0.8,0.5,1.6,1.2,2.3,2.1c0.8,1,1.3,2.1,1.6,3.2c0.3,1.1,0.2,2.1-0.1,3
									s-0.9,1.7-1.7,2.4C1670,1903.2,1668.8,1903.4,1667.5,1903z M1671.6,1899c-0.1-0.9-0.5-1.9-1.3-3c-0.7-1-1.6-1.7-2.5-2.1
									s-1.8-0.5-2.6-0.3c-0.7,0.6-1.1,1.3-1,2.3c0,1,0.5,1.9,1.3,3c0.9,1.2,1.9,2,2.8,2.4c1,0.4,1.7,0.3,2.4-0.1
									C1671.3,1900.6,1671.7,1899.9,1671.6,1899z"
						/>
						<path
							d="M1678.6,1896.1c-1.1-1.6-2.4-3.3-3.8-5c-0.7-0.8-1.2-1.5-1.6-1.9c-0.4-0.4-0.7-0.7-1-0.9c-0.3-0.2-0.7-0.3-1.1-0.4
									c0-0.4,0-0.8,0.2-1.1c0.1-0.3,0.3-0.6,0.5-0.7c0.7-0.6,1.5-0.3,2.5,0.8c-0.1-0.7,0-1.4,0.1-2c0.1-0.6,0.4-1.2,0.7-1.7
									c0.3,0.2,0.6,0.5,1.1,0.9c0.4,0.4,0.8,0.8,1.1,1.2c-0.2,0.1-0.4,0.2-0.7,0.5c-0.4,0.3-0.7,0.7-1,1.1c-0.3,0.4-0.5,0.8-0.5,1.1
									l0.5,0.7c0.8,1,1.5,1.9,2.1,2.7c1.3,1.6,2.3,2.7,3.1,3.2c-0.3,0.4-0.6,0.7-0.9,1C1679.5,1895.7,1679.1,1895.9,1678.6,1896.1z"
						/>
						<path
							d="M1680.6,1886c-0.1-0.9-0.1-1.8,0-2.8c0.7,0.2,1.6,0.4,2.7,0.6c0.1,1,0.1,1.9,0,2.7
									C1682.4,1886.3,1681.5,1886.1,1680.6,1886z"
						/>
						<path
							d="M1689.9,1885.4c-1.2-0.4-2.4-1.3-3.6-2.7c-0.8-1-1.4-2-1.7-3.1c-0.3-1-0.4-2-0.2-2.9c0.2-0.9,0.7-1.7,1.4-2.3
									c1-0.8,2.1-1,3.2-0.7s2.4,1.3,3.7,2.9c-0.5,0.7-1.1,1.6-1.9,2.5c-0.8,0.9-1.6,1.8-2.5,2.5c0.8,0.9,1.6,1.4,2.3,1.5
									c0.7,0.1,1.3-0.1,2-0.6c0.5-0.4,0.9-0.9,1.2-1.4s0.5-1,0.7-1.6l0.5,0.3c0.1,0.9,0,1.8-0.3,2.7c-0.3,0.9-0.8,1.6-1.5,2.2
									C1692.2,1885.6,1691.1,1885.8,1689.9,1885.4z M1689.2,1879c0.4-0.7,0.8-1.2,0.9-1.7c-0.7-0.8-1.4-1.3-2.2-1.5s-1.5-0.1-2,0.4
									c-0.2,0.6-0.1,1.3,0.3,2.2c0.3,0.9,0.8,1.7,1.6,2.6C1688.2,1880.3,1688.7,1879.7,1689.2,1879z"
						/>
						<path
							d="M1700.2,1878.4c-1.2-1.6-2.5-3.2-4-4.9c-0.7-0.8-1.3-1.4-1.7-1.8c-0.4-0.4-0.8-0.7-1.1-0.9c-0.3-0.2-0.7-0.3-1.1-0.4
									c0-0.4,0-0.8,0.1-1.1c0.1-0.3,0.3-0.5,0.4-0.7c0.6-0.6,1.4-0.4,2.3,0.4c-0.1-0.7,0-1.4,0.2-2s0.5-1.1,1-1.5c1.4-1.2,3-0.8,4.8,1.3
									l1.2,1.4c0.4,0.6,0.8,1.1,1.2,1.5c1.3,1.5,2.4,2.5,3.3,3c-0.3,0.4-0.5,0.7-0.8,1c-0.3,0.3-0.7,0.5-1.2,0.8c-1.5-1.9-3.1-3.9-4.9-6
									c-0.5-0.6-1-0.9-1.4-1c-0.4-0.1-0.9,0.1-1.3,0.4c-0.7,0.6-1.1,1.2-1.2,1.9l0.8,1c0.6,0.8,1.4,1.7,2.3,2.7c1.3,1.6,2.4,2.6,3.2,3
									c-0.3,0.4-0.6,0.7-0.9,1C1701.1,1877.9,1700.7,1878.2,1700.2,1878.4z"
						/>
						<path
							d="M1705.2,1865.4c-0.1-0.9-0.2-1.8-0.1-2.8c0.8,0.2,1.7,0.4,2.7,0.5c0.1,1,0.2,1.9,0.1,2.7
									C1707,1865.6,1706.1,1865.5,1705.2,1865.4z"
						/>
						<path
							d="M1712.1,1862.1c-0.7-0.8-1.6-1.9-2.8-3.2c-2.2-2.4-3.8-4-4.8-4.7c0.7-0.5,1.7-1.3,3-2.4c1.6-1.4,2.8-2.6,3.5-3.5
									c0.4,0.4,0.7,0.8,1,1.4c0.3,0.5,0.5,0.9,0.6,1.2c-0.5,0.1-1,0.3-1.5,0.6c-0.5,0.3-1.1,0.7-1.5,1.1c-0.6,0.6-1.2,1.1-1.6,1.7
									c1.1,1.3,2,2.4,2.9,3.4l0.5-0.4c0.5-0.4,0.8-0.7,1.1-0.9c0.6-0.5,1-0.9,1.3-1.2c0.3-0.3,0.5-0.7,0.8-1.1c0.3,0.2,0.6,0.5,0.8,0.7
									c0.2,0.2,0.4,0.5,0.7,0.9c-1.4,1.1-2.5,1.9-3.2,2.6c-0.3,0.3-0.6,0.5-0.7,0.7c1.4,1.6,2.5,2.7,3.1,3.3c0.7-0.4,1.4-0.9,2.1-1.5
									c0.5-0.5,1-1,1.4-1.6c0.4-0.6,0.7-1.1,0.9-1.6c0.4,0.4,0.7,0.9,1,1.4c0.3,0.5,0.5,0.9,0.6,1.2c-0.9,0.6-2,1.5-3.4,2.8
									c-1.3,1.1-2.2,2-2.7,2.7C1713.7,1864.1,1712.8,1862.9,1712.1,1862.1z"
						/>
						<path
							d="M1724.7,1856.7c-1.2-1.5-2.6-3.1-4.1-4.7c-0.7-0.8-1.3-1.4-1.7-1.8c-0.4-0.4-0.8-0.7-1.1-0.8c-0.3-0.2-0.7-0.3-1.1-0.4
									c-0.1-0.4,0-0.8,0.1-1.2c0.1-0.3,0.2-0.6,0.4-0.7c0.7-0.6,1.5-0.4,2.5,0.6c-0.1-0.7-0.1-1.4-0.1-2c0.1-0.6,0.3-1.2,0.6-1.8
									c0.3,0.2,0.7,0.4,1.1,0.8c0.5,0.4,0.9,0.7,1.2,1.1c-0.2,0.1-0.4,0.3-0.6,0.5c-0.4,0.3-0.7,0.7-0.9,1.2s-0.4,0.8-0.5,1.2l0.6,0.7
									c0.9,1,1.6,1.8,2.3,2.6c1.4,1.5,2.5,2.5,3.3,2.9c-0.3,0.4-0.6,0.8-0.8,1C1725.6,1856.2,1725.2,1856.4,1724.7,1856.7z"
						/>
						<path
							d="M1731.5,1850.4c-1.3-1.5-2.7-3.1-4.2-4.7c-0.7-0.8-1.3-1.4-1.8-1.7c-0.4-0.4-0.8-0.6-1.1-0.8s-0.7-0.3-1.1-0.3
									c-0.1-0.4-0.1-0.8,0-1.1s0.2-0.6,0.4-0.7c0.6-0.6,1.4-0.5,2.3,0.3c-0.1-0.7-0.1-1.4,0.1-2c0.2-0.6,0.5-1.2,0.9-1.6
									c1.4-1.3,3-0.9,4.8,1l1.2,1.4c0.5,0.6,0.9,1.1,1.3,1.5c1.4,1.5,2.5,2.4,3.4,2.9c-0.2,0.4-0.5,0.7-0.8,1c-0.3,0.3-0.7,0.6-1.2,0.9
									c-1.6-1.8-3.3-3.7-5.2-5.7c-0.5-0.6-1-0.9-1.4-1c-0.4-0.1-0.8,0.1-1.3,0.5c-0.6,0.6-1,1.3-1.1,2l0.9,0.9c0.7,0.8,1.5,1.6,2.4,2.6
									c1.4,1.5,2.5,2.5,3.4,2.9c-0.3,0.4-0.6,0.8-0.9,1.1C1732.4,1849.9,1732,1850.1,1731.5,1850.4z"
						/>
						<path
							d="M1729.7,1830.2c-0.2-0.9-0.2-1.6-0.2-2.3c0.8,0.2,1.6,0.3,2.3,0.3c0.1,0.8,0.2,1.6,0.2,2.3
									C1731.2,1830.3,1730.4,1830.2,1729.7,1830.2z M1741.1,1840.9c-0.4-0.1-0.9-0.5-1.5-1.2c-0.5-0.5-1.2-1.3-2.1-2.2
									c-0.9-0.9-1.5-1.6-1.9-2c-0.6-0.6-1.1-1.1-1.6-1.5c-0.5-0.4-1-0.7-1.3-0.8c0.2-0.5,0.4-0.9,0.6-1.2s0.5-0.7,0.9-1.1
									c0.3,0.5,0.9,1.1,1.7,2s1.7,1.9,2.7,2.9c0.8,0.8,1.3,1.3,1.8,1.7s0.8,0.6,1.1,0.8c0.3,0.2,0.7,0.3,1.1,0.3c0.1,0.4,0,0.8,0,1.2
									c-0.1,0.3-0.2,0.6-0.4,0.8C1741.9,1840.9,1741.5,1841,1741.1,1840.9z"
						/>
						<path
							d="M1746.2,1836c-0.4-0.1-0.9-0.5-1.5-1.2c-0.4-0.4-1.1-1.1-2-2.1c-1-1.1-1.9-2-2.5-2.7c-2.2-2.3-3.9-3.8-5.1-4.5
									c0.2-0.5,0.4-0.9,0.6-1.2c0.2-0.3,0.5-0.7,0.9-1c1.6,1.9,3.9,4.4,7.1,7.6c0.7,0.8,1.3,1.3,1.8,1.7c0.4,0.4,0.8,0.6,1.2,0.8
									c0.3,0.1,0.7,0.3,1.1,0.3c0.1,0.4,0.1,0.8,0,1.2s-0.2,0.6-0.4,0.8C1746.9,1836,1746.5,1836.1,1746.2,1836z"
						/>
					</g>
					<g className="level_two fade location " id="serni">
						<rect x="906.435" y="1092.292" width="58.857" height="24.03" transform="matrix(-0.961159, 0.275995, -0.264414, -0.964485, 2940.750244, 2706.734863)"></rect>
						<path
							d="M1727.5,1914.1c-0.1-0.4-0.2-0.7-0.3-1c0-0.3-0.1-0.6,0-1c0.5,0.3,1.1,0.5,1.7,0.5c0.7,0,1.3-0.1,2-0.3
									c0.8-0.3,1.3-0.7,1.6-1.2s0.3-1.1,0-1.8c-0.2-0.6-0.6-1-1.1-1.2c-0.5-0.2-1.3-0.5-2.4-0.7c-0.9-0.2-1.7-0.4-2.2-0.6
									c-0.6-0.2-1.1-0.5-1.6-0.9c-0.5-0.4-0.9-1-1.2-1.8c-0.4-1-0.3-2,0.3-2.9c0.5-0.9,1.5-1.6,2.8-2.1c1.2-0.4,2.3-0.6,3.2-0.5
									c0.1,0.4,0.2,0.8,0.3,1.4c0.1,0.5,0.1,1,0,1.3c-0.8-0.3-1.6-0.5-2.3-0.6c-0.8-0.1-1.3-0.1-1.7,0c-0.3,0.2-0.5,0.5-0.6,1
									s-0.1,0.9,0,1.4c0.2,0.6,0.6,1.1,1.2,1.3s1.4,0.5,2.4,0.7c0.9,0.2,1.6,0.3,2.1,0.5c0.6,0.2,1.1,0.5,1.6,0.9c0.5,0.4,0.9,1,1.1,1.7
									c0.5,1.2,0.5,2.3,0,3.3s-1.4,1.7-2.8,2.2C1730.1,1914.2,1728.7,1914.4,1727.5,1914.1z"
						/>
						<path
							d="M1738.9,1909.4c-1-0.7-1.7-1.9-2.4-3.6c-0.4-1.2-0.6-2.2-0.6-3.3c0-1,0.3-1.9,0.7-2.7c0.5-0.8,1.1-1.3,2-1.6
									c1.1-0.4,2.2-0.3,3.1,0.4c0.9,0.7,1.7,1.9,2.4,3.7c-0.7,0.5-1.5,1.1-2.5,1.6c-1,0.6-2,1.1-3,1.5c0.5,1,1,1.7,1.6,2
									c0.6,0.3,1.2,0.4,2,0.1c0.6-0.2,1.1-0.5,1.6-0.9c0.5-0.4,0.8-0.8,1.1-1.2l0.3,0.4c-0.2,0.9-0.6,1.7-1.1,2.4
									c-0.6,0.7-1.2,1.2-2,1.5C1740.9,1910.2,1739.9,1910,1738.9,1909.4z M1740.2,1903.4c0.6-0.5,1.1-0.9,1.4-1.3
									c-0.4-0.9-0.9-1.6-1.5-2c-0.6-0.4-1.3-0.5-1.9-0.3c-0.3,0.5-0.5,1.2-0.4,2c0,0.9,0.2,1.8,0.6,2.8
									C1739,1904.2,1739.6,1903.8,1740.2,1903.4z"
						/>
						<path
							d="M1750.3,1906.2c-0.5-1.8-1.1-3.7-1.8-5.7c-0.3-1-0.6-1.7-0.8-2.2c-0.2-0.5-0.4-0.9-0.7-1.1c-0.2-0.3-0.5-0.5-0.8-0.7
									c0.1-0.4,0.3-0.7,0.5-1s0.4-0.4,0.7-0.5c0.8-0.3,1.5,0.2,2,1.5c0.2-0.7,0.4-1.3,0.7-1.8c0.3-0.5,0.7-0.9,1.2-1.3
									c0.2,0.2,0.4,0.6,0.7,1.1c0.3,0.5,0.5,1,0.6,1.4c-0.2,0-0.4,0.1-0.8,0.2c-0.5,0.2-0.9,0.4-1.2,0.6c-0.4,0.3-0.7,0.6-0.8,0.8
									l0.3,0.8c0.4,1.2,0.7,2.2,1,3.1c0.6,1.9,1.2,3.1,1.8,3.8c-0.4,0.3-0.8,0.4-1.1,0.6C1751.2,1906.1,1750.8,1906.1,1750.3,1906.2z"
						/>
						<path
							d="M1758.3,1903.4c-0.4-1.8-0.8-3.8-1.3-5.8c-0.3-1-0.5-1.7-0.7-2.3s-0.4-0.9-0.6-1.2c-0.2-0.3-0.4-0.6-0.8-0.8
									c0.1-0.4,0.3-0.7,0.6-0.9s0.5-0.4,0.7-0.4c0.8-0.2,1.4,0.2,1.8,1.3c0.2-0.6,0.6-1.2,1-1.6c0.4-0.4,0.9-0.7,1.5-0.9
									c1.7-0.4,2.9,0.6,3.5,3.1l0.4,1.7c0.1,0.7,0.3,1.3,0.4,1.8c0.5,1.9,1,3.2,1.5,4c-0.4,0.2-0.8,0.4-1.1,0.5
									c-0.4,0.1-0.8,0.2-1.4,0.2c-0.4-2.2-1-4.6-1.6-7.2c-0.2-0.7-0.4-1.2-0.7-1.5c-0.3-0.3-0.7-0.3-1.3-0.2c-0.8,0.2-1.4,0.6-1.9,1.1
									l0.3,1.2c0.2,0.9,0.4,2,0.8,3.3c0.5,1.9,1,3.2,1.5,4c-0.4,0.2-0.8,0.4-1.2,0.5C1759.3,1903.3,1758.9,1903.4,1758.3,1903.4z"
						/>
						<path
							d="M1767.9,1885.7c0.4-0.7,0.8-1.4,1.2-1.9c0.5,0.6,1,1.1,1.6,1.5c-0.3,0.7-0.7,1.3-1.2,1.9
									C1769,1886.7,1768.5,1886.2,1767.9,1885.7z M1770.5,1900.4c-0.2-0.3-0.4-0.9-0.5-1.7c-0.1-0.7-0.2-1.7-0.3-2.9
									c-0.1-1.2-0.2-2.1-0.3-2.6c-0.1-0.8-0.2-1.5-0.4-2.1c-0.2-0.6-0.3-1.1-0.5-1.3c0.4-0.3,0.8-0.5,1.2-0.6c0.4-0.1,0.8-0.2,1.3-0.3
									c0,0.5,0,1.4,0.1,2.5c0.1,1.1,0.2,2.4,0.4,3.8c0.1,1,0.3,1.8,0.4,2.3s0.3,0.9,0.4,1.2c0.2,0.3,0.4,0.6,0.7,0.9
									c-0.2,0.4-0.4,0.6-0.7,0.9c-0.3,0.2-0.5,0.3-0.7,0.3C1771,1900.9,1770.7,1900.8,1770.5,1900.4z"
						/>
					</g>
					<g className="level_two fade location" id="lebennin">
						<rect x="1614.061" y="1233.155" width="104.806" height="27.129" transform="matrix(-0.929529, 0.368749, -0.357549, -0.933972, 3805.862305, 2454.774658)"></rect>
						<rect x="1581.906" y="1236.837" width="102.718" height="27.21" transform="matrix(-0.84879, 0.52873, -0.518503, -0.855161, 3920.543701, 2073.111328)"></rect>
						<path
							d="M1766.9,1925.5c-0.4-1-0.9-2.3-1.7-4.2c-1.6-3.8-2.7-6.1-3.3-6.8c0.4-0.3,0.8-0.6,1.3-0.8c0.4-0.2,0.9-0.3,1.5-0.4
									c0.4,1.2,0.9,2.6,1.6,4.5c0.7,1.8,1.4,3.5,2,5c0.9,2.1,1.6,3.6,2,4.5c0.8-0.1,1.7-0.4,2.7-0.8c0.6-0.3,1.2-0.6,1.7-1
									c0.5-0.4,0.9-0.8,1.2-1.2c0.3,0.6,0.5,1.3,0.6,2c0.2,0.7,0.2,1.2,0.2,1.5c-1.2,0.3-2.5,0.8-4,1.4c-1.8,0.7-3.1,1.4-3.9,1.9
									C1767.9,1928.3,1767.2,1926.5,1766.9,1925.5z"
						/>
						<path
							d="M1791.1,1920.1c-1.2-0.8-2.2-2.2-3-4.2c-0.6-1.4-0.8-2.7-0.8-3.9c0-1.2,0.3-2.3,0.8-3.3s1.3-1.6,2.3-2
									c1.4-0.6,2.6-0.4,3.7,0.4c1.1,0.8,2.1,2.3,3,4.4c-0.8,0.6-1.8,1.3-3,2c-1.2,0.7-2.4,1.3-3.6,1.9c0.6,1.2,1.3,2,2,2.4
									s1.5,0.4,2.4,0c0.7-0.3,1.3-0.6,1.8-1.1c0.5-0.4,1-0.9,1.2-1.5l0.4,0.5c-0.2,1-0.6,2-1.3,2.9c-0.7,0.9-1.4,1.5-2.4,1.9
									C1793.5,1921.1,1792.2,1920.9,1791.1,1920.1z M1792.4,1912.9c0.7-0.6,1.2-1.1,1.6-1.6c-0.5-1.1-1.1-1.9-1.9-2.4
									c-0.8-0.5-1.6-0.6-2.3-0.3c-0.4,0.6-0.5,1.4-0.5,2.4s0.3,2.2,0.8,3.3C1790.9,1914,1791.7,1913.5,1792.4,1912.9z"
						/>
						<path
							d="M1827.9,1906.7c-0.7-0.1-1.3-0.3-1.8-0.6l-0.1,1.6c-0.2,0.1-0.5,0.1-0.9,0.2c-0.3-1-0.9-2.6-1.7-4.8
									c-0.8-2.2-1.5-4-2.1-5.6c-1.1-2.9-2.1-4.9-2.8-5.9c0.7-0.7,1.5-1.3,2.6-1.7c0.4,1.4,1,3.3,2,5.8c0.6-1.4,1.5-2.2,2.6-2.7
									c1.2-0.5,2.4-0.3,3.6,0.5c1.2,0.8,2.1,2.2,2.9,4.1c0.9,2.2,1.1,4.1,0.7,5.6s-1.4,2.6-3,3.2
									C1829.2,1906.7,1828.6,1906.7,1827.9,1906.7z M1830.8,1903c0.2-0.9,0-2.1-0.5-3.5c-1.1-2.8-2.6-4.2-4.4-4.3
									c-0.9,0.3-1.6,0.9-2.3,1.8c0.8,2.1,1.3,3.4,1.5,4c0.7,1.7,1.2,2.9,1.6,3.6c0.9,0.5,1.6,0.5,2.3,0.3
									C1830,1904.5,1830.6,1903.9,1830.8,1903z"
						/>
						<path
							d="M1846.9,1897.9c-1.2-0.8-2.2-2.2-3.1-4.1c-0.6-1.4-0.9-2.7-0.9-3.9c0-1.2,0.2-2.3,0.7-3.3c0.5-0.9,1.3-1.6,2.3-2.1
									c1.3-0.6,2.6-0.5,3.8,0.3c1.2,0.8,2.2,2.2,3.1,4.3c-0.8,0.7-1.7,1.3-2.9,2.1s-2.4,1.4-3.6,2c0.6,1.2,1.3,2,2,2.4
									c0.7,0.4,1.5,0.4,2.4,0c0.7-0.3,1.3-0.7,1.8-1.1c0.5-0.5,0.9-1,1.2-1.5l0.4,0.5c-0.2,1-0.6,2-1.2,2.9c-0.6,0.9-1.4,1.5-2.3,1.9
									C1849.3,1898.8,1848.1,1898.7,1846.9,1897.9z M1848.1,1890.6c0.7-0.6,1.2-1.1,1.6-1.6c-0.5-1.1-1.1-1.9-1.9-2.3
									c-0.8-0.5-1.6-0.6-2.3-0.2c-0.4,0.6-0.5,1.4-0.4,2.5c0.1,1,0.4,2.1,0.9,3.3C1846.6,1891.7,1847.4,1891.2,1848.1,1890.6z"
						/>
						<path
							d="M1868.6,1889.6c-0.9-2.1-1.9-4.2-3-6.5c-0.5-1.1-1-1.9-1.3-2.5s-0.7-1-1-1.3c-0.3-0.3-0.7-0.5-1.1-0.8
									c0.1-0.5,0.2-0.9,0.4-1.2c0.2-0.3,0.4-0.5,0.7-0.7c0.9-0.4,1.7-0.1,2.4,1.1c0.1-0.8,0.4-1.5,0.8-2.2c0.4-0.6,0.9-1.1,1.5-1.4
									c1.9-0.9,3.6,0,4.9,2.7l0.9,1.9c0.3,0.8,0.6,1.5,0.9,2.1c1,2.1,1.9,3.5,2.8,4.3c-0.4,0.3-0.8,0.6-1.2,0.8
									c-0.4,0.2-0.9,0.4-1.6,0.5c-1.1-2.5-2.3-5.1-3.7-8c-0.4-0.8-0.8-1.3-1.2-1.5c-0.4-0.2-1-0.2-1.6,0.1c-0.9,0.4-1.5,1-1.9,1.8
									l0.6,1.3c0.5,1,1,2.3,1.7,3.7c1,2.1,2,3.5,2.7,4.3c-0.4,0.4-0.9,0.7-1.3,0.9C1869.7,1889.2,1869.2,1889.4,1868.6,1889.6z"
						/>
						<path
							d="M1890.6,1878.2c-1-2-2.1-4.1-3.4-6.3c-0.6-1.1-1.1-1.9-1.5-2.4c-0.4-0.5-0.7-0.9-1-1.2c-0.3-0.3-0.7-0.5-1.2-0.7
									c0-0.5,0.2-0.9,0.4-1.3c0.2-0.3,0.4-0.6,0.6-0.7c0.8-0.5,1.7-0.2,2.4,0.9c0.1-0.8,0.3-1.6,0.7-2.2c0.4-0.6,0.9-1.1,1.4-1.5
									c1.9-1,3.5-0.2,5.1,2.5l1,1.9c0.4,0.7,0.7,1.4,1,2c1.1,2,2.1,3.4,3,4.1c-0.4,0.4-0.8,0.7-1.2,0.9c-0.4,0.2-0.9,0.4-1.5,0.6
									c-1.2-2.4-2.6-5-4.2-7.8c-0.4-0.8-0.9-1.3-1.3-1.5c-0.4-0.2-1-0.1-1.6,0.2c-0.9,0.5-1.5,1.1-1.8,1.9l0.7,1.3
									c0.5,1,1.2,2.2,1.9,3.6c1.2,2,2.1,3.4,3,4.1c-0.4,0.4-0.8,0.7-1.3,0.9C1891.7,1877.8,1891.2,1878,1890.6,1878.2z"
						/>
						<path
							d="M1901.1,1850.8c0-1,0.1-1.9,0.3-2.7c0.8,0.4,1.7,0.6,2.6,0.8c0,0.9-0.1,1.8-0.3,2.7
									C1902.8,1851.3,1902,1851,1901.1,1850.8z M1911.6,1865.3c-0.4-0.2-0.9-0.8-1.5-1.6c-0.5-0.7-1.1-1.7-1.9-3
									c-0.8-1.2-1.3-2.1-1.7-2.7c-0.5-0.8-1-1.5-1.5-2.1c-0.5-0.6-0.9-1-1.3-1.2c0.3-0.5,0.6-0.9,0.9-1.2c0.3-0.3,0.7-0.7,1.2-1
									c0.3,0.6,0.8,1.5,1.4,2.6c0.7,1.2,1.5,2.5,2.4,3.9c0.7,1,1.2,1.8,1.6,2.3c0.4,0.5,0.8,0.9,1.1,1.1c0.3,0.2,0.7,0.5,1.2,0.6
									c0,0.5-0.1,0.9-0.3,1.3c-0.2,0.4-0.4,0.6-0.6,0.8C1912.5,1865.5,1912.1,1865.5,1911.6,1865.3z"
						/>
						<path
							d="M1926.2,1855.8c-1.2-1.9-2.6-3.8-4.1-5.9c-0.7-1-1.3-1.7-1.8-2.2c-0.4-0.5-0.8-0.8-1.2-1.1c-0.3-0.2-0.7-0.4-1.2-0.5
									c0-0.5,0-0.9,0.2-1.3c0.2-0.3,0.3-0.6,0.6-0.8c0.8-0.6,1.6-0.4,2.5,0.6c0-0.8,0.1-1.6,0.4-2.3c0.3-0.7,0.7-1.2,1.3-1.6
									c1.7-1.3,3.5-0.7,5.3,1.8l1.2,1.7c0.4,0.7,0.9,1.3,1.3,1.9c1.4,1.9,2.5,3.1,3.5,3.7c-0.3,0.4-0.7,0.8-1,1
									c-0.4,0.3-0.9,0.6-1.4,0.8c-1.5-2.3-3.2-4.7-5.1-7.2c-0.5-0.7-1-1.2-1.5-1.3c-0.5-0.1-1,0-1.5,0.4c-0.8,0.6-1.3,1.3-1.5,2.1
									l0.8,1.2c0.6,1,1.4,2.1,2.4,3.3c1.4,1.9,2.5,3.1,3.4,3.7c-0.4,0.4-0.7,0.8-1.1,1.1C1927.2,1855.3,1926.7,1855.5,1926.2,1855.8z"
						/>
					</g>
					<g className="level_two fade location " id="gilrain">
						<rect x="1335.954" y="1117.508" width="86.747" height="24.585" transform="matrix(-0.387451, 0.92189, -0.917222, -0.398559, 3375.121582, 980.654968)"></rect>
						<path
							d="M1797.9,1842.4c-1.5,0.6-3.5,0.6-5.9-0.1c-1.7-0.5-3.1-1.1-4.3-2c-1.2-0.9-2-1.9-2.5-3.1c-0.5-1.2-0.5-2.4-0.2-3.7
									c0.4-1.5,1-2.7,1.7-3.6c0.4,0.1,0.9,0.3,1.4,0.6c0.5,0.3,0.9,0.6,1.2,0.8c-0.8,0.6-1.5,1.2-2.1,1.9s-1,1.3-1.1,1.8
									c0,0.6,0.3,1.2,0.8,1.8c0.5,0.6,1.2,1.2,2.1,1.7c0.8,0.5,1.8,0.9,2.7,1.2c2.2,0.6,3.9,0.7,5.1,0.3c1.2-0.4,1.9-1.3,2.3-2.8
									c0.2-0.7,0.2-1.3,0.2-1.9c-3.2-0.9-5.3-1.3-6.1-1.2c0-0.5,0-0.9,0.1-1.3c0.1-0.4,0.3-0.8,0.5-1.3c2,0.7,4,1.3,6.2,1.9
									c0.5,0.7,0.8,1.5,0.9,2.5s0.1,1.9-0.2,2.8C1800.4,1840.6,1799.4,1841.8,1797.9,1842.4z"
						/>
						<path
							d="M1787.8,1822.7c-0.6-0.7-1-1.4-1.3-2c0.8-0.2,1.6-0.5,2.3-0.9c0.5,0.7,1,1.3,1.3,2.1
									C1789.3,1822,1788.6,1822.3,1787.8,1822.7z M1803.7,1827c-0.4,0.1-1.1,0-2-0.3c-0.7-0.2-1.7-0.6-3.1-1c-1.2-0.4-2.2-0.7-2.7-0.9
									c-0.8-0.3-1.6-0.5-2.3-0.6c-0.7-0.1-1.2-0.2-1.6-0.1c-0.1-0.5-0.1-1,0-1.4s0.1-0.9,0.3-1.4c0.5,0.3,1.4,0.6,2.6,1
									c1.2,0.4,2.5,0.9,3.9,1.4c1.1,0.3,1.9,0.6,2.5,0.7c0.6,0.1,1.1,0.2,1.4,0.2c0.4,0,0.8-0.1,1.2-0.3c0.3,0.4,0.4,0.7,0.5,1.1
									c0.1,0.4,0.1,0.7,0,0.9C1804.4,1826.6,1804.1,1826.9,1803.7,1827z"
						/>
						<path
							d="M1806.8,1817.9c-0.4,0.1-1.1-0.1-2-0.4c-0.6-0.2-1.5-0.6-2.9-1.1c-1.5-0.6-2.7-1-3.6-1.3c-3.1-1.1-5.4-1.8-6.8-1.9
									c-0.1-0.5-0.1-1,0-1.4s0.2-0.9,0.3-1.4c2.3,1,5.7,2.4,10.2,4c1,0.4,1.9,0.6,2.5,0.8c0.6,0.1,1.1,0.2,1.4,0.2
									c0.4,0,0.8-0.1,1.2-0.2c0.3,0.4,0.4,0.7,0.5,1.1c0.1,0.4,0.1,0.7,0,0.9C1807.5,1817.6,1807.2,1817.9,1806.8,1817.9z"
						/>
						<path
							d="M1810.4,1808.9c-1.9-0.8-3.9-1.6-6.1-2.5c-1.1-0.4-1.9-0.7-2.5-0.8c-0.6-0.2-1.1-0.2-1.4-0.2c-0.4,0-0.8,0.1-1.2,0.2
									c-0.3-0.4-0.4-0.8-0.5-1.1c-0.1-0.4,0-0.7,0-0.9c0.3-0.9,1.2-1.1,2.7-0.6c-0.5-0.6-0.8-1.3-1-1.9c-0.2-0.6-0.3-1.2-0.3-1.9
									c0.3,0,0.8,0.1,1.4,0.2c0.6,0.1,1.2,0.3,1.6,0.5c-0.1,0.2-0.2,0.4-0.4,0.8c-0.2,0.5-0.3,1-0.3,1.5c0,0.5,0,0.9,0.1,1.3l0.9,0.4
									c1.3,0.5,2.4,0.9,3.4,1.3c2,0.8,3.5,1.1,4.5,1.1c-0.1,0.5-0.2,1-0.3,1.4C1811,1808,1810.8,1808.4,1810.4,1808.9z"
						/>
						<path
							d="M1814,1798.1c-0.7,0.2-1.4,0.1-2.2-0.2c-2.3-0.9-2.9-3.2-1.8-7c-1.3-0.5-2.2-0.7-2.8-0.5c-0.6,0.1-1.1,0.6-1.4,1.4
									c-0.2,0.5-0.3,1-0.3,1.6c0,0.6,0.1,1.1,0.3,1.6l-0.6,0c-0.7-0.7-1.2-1.6-1.4-2.5c-0.3-0.9-0.2-1.8,0-2.5c0.4-1,1-1.7,1.9-2
									c0.9-0.3,1.9-0.2,3.2,0.3c0.7,0.3,1.5,0.6,2.3,1c0.9,0.4,1.6,0.7,2,0.9c0.7,0.3,1.3,0.4,1.8,0.4c0.5,0,1-0.1,1.6-0.2
									c0.4,0.8,0.5,1.6,0.4,2.3c-0.5,0.3-1.1,0.4-1.9,0.3c0.3,0.7,0.4,1.5,0.5,2.2c0.1,0.7,0,1.3-0.2,1.9
									C1815.1,1797.6,1814.6,1797.9,1814,1798.1z M1814.3,1794.1c-0.1-0.5-0.2-1-0.4-1.5l-0.1-0.1c-0.6-0.2-1.2-0.5-1.8-0.7l-1.2-0.5
									c-0.3,1.1-0.4,2-0.1,2.8c0.3,0.8,0.8,1.3,1.6,1.6c0.3,0.1,0.7,0.2,1,0.2c0.4,0,0.7-0.1,0.9-0.3
									C1814.3,1795.1,1814.4,1794.7,1814.3,1794.1z"
						/>
						<path
							d="M1803.7,1779.3c-0.5-0.7-1-1.4-1.3-2.1c0.8-0.2,1.6-0.5,2.3-0.8c0.5,0.7,0.9,1.4,1.3,2.1
									C1805.2,1778.7,1804.5,1778.9,1803.7,1779.3z M1819.4,1784.2c-0.4,0.1-1.1-0.1-2-0.4c-0.7-0.3-1.7-0.6-3-1.2
									c-1.2-0.5-2.1-0.8-2.7-1c-0.8-0.3-1.5-0.5-2.2-0.7c-0.7-0.2-1.2-0.2-1.6-0.2c-0.1-0.5,0-1,0-1.4c0.1-0.4,0.2-0.9,0.4-1.4
									c0.5,0.3,1.4,0.7,2.5,1.1s2.4,1,3.9,1.5c1.1,0.4,1.9,0.7,2.5,0.8c0.6,0.2,1.1,0.2,1.4,0.2c0.4,0,0.8-0.1,1.2-0.2
									c0.3,0.4,0.4,0.7,0.5,1.1c0.1,0.4,0.1,0.7,0,0.9C1820.1,1783.8,1819.8,1784.1,1819.4,1784.2z"
						/>
						<path
							d="M1823,1775.1c-1.9-0.8-3.9-1.6-6.1-2.3c-1.1-0.4-1.9-0.6-2.5-0.8c-0.6-0.1-1.1-0.2-1.4-0.2s-0.8,0.1-1.2,0.2
									c-0.3-0.4-0.5-0.7-0.5-1.1c-0.1-0.3-0.1-0.6,0-0.9c0.3-0.8,1-1.1,2.2-0.8c-0.4-0.6-0.7-1.3-0.9-1.9c-0.1-0.7-0.1-1.3,0.1-1.9
									c0.6-1.8,2.3-2.3,5-1.4l1.8,0.7c0.7,0.3,1.3,0.5,1.9,0.7c2,0.7,3.5,1,4.5,1c0,0.5-0.1,0.9-0.3,1.3c-0.1,0.4-0.4,0.9-0.7,1.4
									c-2.3-0.9-4.8-1.9-7.6-2.8c-0.8-0.3-1.4-0.3-1.8-0.2c-0.4,0.1-0.7,0.5-0.9,1.1c-0.3,0.9-0.3,1.7-0.1,2.4l1.2,0.5
									c1,0.4,2.1,0.8,3.5,1.3c2,0.7,3.5,1.1,4.5,1c0,0.5-0.1,1-0.3,1.4C1823.5,1774.2,1823.3,1774.6,1823,1775.1z"
						/>
					</g>
					<g className="level_zero location " id="belfalas">
						<rect x="1653.583" y="1195.021" width="107.372" height="26.29" transform="matrix(-0.555318, -0.831638, 0.838329, -0.545298, 1510.753662, 4025.036133)"></rect>
						<path
							d="M1543.3,1912c-0.5-0.9-0.8-1.7-1-2.3c0.7-0.4,1.7-1,3.1-1.9c1.3-0.9,2.6-1.7,3.8-2.6c2.4-1.7,3.8-2.8,4.1-3.5
									c0.6,0.4,1.2,1,1.9,1.8c0.7,0.8,1.3,1.5,1.9,2.3c1,1.5,1.6,2.8,1.6,4s-0.5,2.1-1.5,2.9c-0.6,0.4-1.2,0.6-1.9,0.6
									c-0.7,0-1.4-0.2-2.1-0.7c0.3,1,0.3,1.9,0.1,2.7c-0.2,0.8-0.6,1.5-1.3,1.9c-1.1,0.8-2.3,1-3.5,0.5c-1.2-0.4-2.4-1.5-3.6-3.1
									C1544.3,1913.7,1543.8,1912.9,1543.3,1912z M1550.4,1914.9c0.6-0.4,0.9-0.9,1.1-1.5c0.2-0.6,0.2-1.3,0-2.1
									c-0.5-0.7-1.5-1.6-2.9-2.8c-1.8,1.3-3.1,2.3-3.9,3c0.2,0.7,0.5,1.4,1,2.1C1547.1,1915.6,1548.7,1916,1550.4,1914.9z
									M1551.7,1909.6c0.3,0.5,0.5,0.8,0.6,1c1.3,0.5,2.3,0.5,3,0c1.3-0.9,1.4-2.3,0.1-4c-0.4-0.6-0.9-1.2-1.5-1.6
									c-1.2,0.7-2.5,1.6-4.1,2.7C1550.5,1908,1551.1,1908.7,1551.7,1909.6z"
						/>
						<path
							d="M1552.9,1923.3c0.3-1.2,1.2-2.3,2.7-3.4c1-0.7,2-1.1,3.1-1.3c1.1-0.1,2.1,0,3,0.4c0.9,0.4,1.7,1.1,2.4,2
									c0.9,1.2,1.1,2.5,0.8,3.7c-0.3,1.2-1.3,2.3-2.8,3.4c-0.8-0.7-1.6-1.6-2.6-2.6c-1-1.1-1.9-2.2-2.7-3.3c-0.9,0.7-1.3,1.5-1.5,2.2
									c-0.1,0.7,0.1,1.5,0.7,2.3c0.4,0.6,0.9,1.2,1.5,1.7c0.5,0.5,1.1,0.8,1.6,1l-0.3,0.5c-0.9-0.1-1.9-0.4-2.8-0.9s-1.7-1.2-2.3-2.1
									C1552.8,1925.8,1552.5,1924.5,1552.9,1923.3z M1559.4,1923.8c0.7,0.6,1.3,1.1,1.8,1.4c0.8-0.6,1.3-1.3,1.5-2.1
									c0.2-0.8,0-1.6-0.5-2.3c-0.6-0.3-1.4-0.3-2.2-0.2c-0.9,0.2-1.7,0.6-2.6,1.2C1558.1,1922.5,1558.7,1923.1,1559.4,1923.8z"
						/>
						<path
							d="M1560.9,1936.3c0.1-0.4,0.6-0.8,1.3-1.3c0.5-0.3,1.2-0.8,2.3-1.5c1.2-0.8,2.1-1.4,2.9-1.9c2.4-1.7,4.1-3.1,4.9-4.1
									c0.5,0.3,0.9,0.7,1.2,1c0.3,0.3,0.7,0.8,1,1.2c-2,1.1-4.8,2.9-8.3,5.4c-0.8,0.6-1.4,1-1.8,1.4c-0.4,0.4-0.7,0.7-0.9,1
									c-0.2,0.3-0.3,0.7-0.4,1.1c-0.4-0.1-0.8-0.2-1.2-0.4c-0.3-0.2-0.6-0.4-0.8-0.6C1560.8,1937.1,1560.7,1936.6,1560.9,1936.3z"
						/>
						<path
							d="M1561.8,1945.2c0.6,0,1.2,0,1.6,0c0.5-0.1,1-0.3,1.7-0.6s1.5-0.9,2.5-1.6c1.2-0.8,3.2-2.4,6.1-4.6
									c-0.4-0.5-0.6-0.9-0.6-1.2c-0.1-0.2,0-0.4,0.2-0.6c0.8,0.4,1.3,0.7,1.7,0.8c1.4-0.7,2.7-1,4-0.8c1.3,0.2,2.3,0.8,3,1.9
									c0.5,0.7,0.9,1.5,1.2,2.4c-0.2,0.2-0.6,0.3-1.1,0.5c-0.5,0.1-1,0.2-1.5,0.3c0.1-0.7,0.1-1.5,0-2.2c-0.1-0.7-0.3-1.2-0.5-1.5
									c-0.4-0.1-0.8-0.1-1.4,0.1c-0.6,0.2-1.4,0.7-2.5,1.4c0.9,1.1,1.5,1.9,1.9,2.3c-0.3,0.2-0.6,0.4-0.9,0.5c-0.3,0.1-0.6,0.2-1,0.2
									c-0.2-0.6-0.6-1.4-1.2-2.3l-1.6,1.1c-0.9,0.6-1.9,1.4-3.1,2.3c-0.6,0.5-1.1,0.9-1.6,1.2c-0.8,0.5-1.4,0.9-2.1,1.1
									c-0.6,0.2-1.3,0.3-2.1,0.2c-0.8-0.1-1.8-0.3-3-0.7L1561.8,1945.2z"
						/>
						<path
							d="M1572.2,1952c0.1-0.7,0.5-1.2,1.1-1.7c1.8-1.3,4.1-0.2,7,3.3c1-0.7,1.6-1.3,1.8-1.9s0-1.3-0.6-2c-0.3-0.5-0.8-0.9-1.3-1.3
									c-0.5-0.4-1-0.7-1.6-0.9l0.3-0.5c1,0,1.9,0.1,2.8,0.6c0.9,0.4,1.6,1,2.2,1.8c0.7,1,1,2,0.9,2.9c-0.1,0.9-0.7,1.7-1.7,2.4
									c-0.5,0.4-1.2,0.8-1.9,1.2c-0.8,0.4-1.3,0.8-1.7,1c-0.6,0.4-1,0.8-1.2,1.1c-0.2,0.3-0.4,0.8-0.5,1.5c-0.9-0.2-1.6-0.7-2.2-1.3
									c0-0.6,0.2-1.2,0.6-1.8c-0.8-0.3-1.5-0.7-2.1-1.1c-0.7-0.4-1.2-0.9-1.5-1.4C1572.2,1953.2,1572,1952.6,1572.2,1952z M1575.5,1955
									c0.5,0.3,1,0.5,1.5,0.7l0.1-0.1c0.5-0.3,1-0.6,1.5-0.9l1-0.6c-0.8-1-1.6-1.7-2.4-2c-0.8-0.3-1.5-0.3-2.1,0.2
									c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1C1574.6,1954.3,1575,1954.7,1575.5,1955z"
						/>
						<path
							d="M1580.7,1964.4c0.1-0.4,0.6-0.8,1.3-1.3c0.5-0.3,1.2-0.8,2.3-1.5c1.2-0.8,2.1-1.4,2.9-2c2.4-1.7,4.1-3.1,4.9-4.1
									c0.5,0.3,0.9,0.7,1.2,1c0.3,0.3,0.7,0.8,1,1.2c-2,1.1-4.8,2.9-8.3,5.4c-0.8,0.6-1.4,1-1.8,1.4c-0.4,0.4-0.7,0.7-0.9,1
									c-0.2,0.3-0.3,0.7-0.4,1.1c-0.4-0.1-0.8-0.2-1.2-0.4c-0.3-0.2-0.6-0.4-0.8-0.6C1580.7,1965.2,1580.6,1964.8,1580.7,1964.4z"
						/>
						<path
							d="M1585.7,1971.1c0.1-0.7,0.5-1.2,1.1-1.7c1.8-1.3,4.1-0.2,7,3.3c1-0.7,1.6-1.3,1.8-1.9s0-1.3-0.6-2
									c-0.3-0.5-0.8-0.9-1.3-1.3c-0.5-0.4-1-0.7-1.6-0.9l0.3-0.5c1-0.1,1.9,0.1,2.8,0.6c0.9,0.4,1.6,1,2.2,1.8c0.7,1,1,2,0.9,2.9
									c-0.1,0.9-0.7,1.7-1.7,2.4c-0.5,0.4-1.2,0.8-1.9,1.2c-0.8,0.4-1.3,0.8-1.7,1c-0.6,0.4-1,0.8-1.2,1.1c-0.2,0.3-0.4,0.8-0.5,1.5
									c-0.9-0.2-1.6-0.7-2.2-1.3c0-0.6,0.2-1.2,0.6-1.8c-0.8-0.3-1.5-0.7-2.1-1.1c-0.7-0.4-1.2-0.9-1.5-1.4
									C1585.6,1972.4,1585.5,1971.8,1585.7,1971.1z M1589,1974.1c0.5,0.3,1,0.5,1.5,0.7l0.1-0.1c0.5-0.3,1-0.6,1.5-0.9l1-0.6
									c-0.8-1-1.6-1.7-2.4-2c-0.8-0.3-1.5-0.3-2.1,0.2c-0.3,0.2-0.5,0.4-0.6,0.7c-0.2,0.3-0.2,0.6-0.2,1
									C1588.1,1973.5,1588.5,1973.8,1589,1974.1z"
						/>
						<path
							d="M1593.7,1982.1c0.3-0.2,0.6-0.4,0.9-0.5c0.3-0.1,0.7-0.2,1.1-0.3c-0.2,0.6-0.2,1.2-0.1,1.8c0.1,0.6,0.4,1.2,0.8,1.8
									c1,1.5,2,1.9,2.8,1.4c0.3-0.2,0.5-0.6,0.5-1.1c0-0.5-0.1-1.2-0.3-2.1c-0.2-0.9-0.3-1.6-0.4-2.2c-0.1-0.6,0-1.2,0.2-1.8
									c0.2-0.6,0.6-1.1,1.2-1.5c0.7-0.5,1.5-0.6,2.4-0.2c0.9,0.3,1.7,1,2.4,2.1c1,1.4,1.6,2.7,1.9,3.9c-0.2,0.2-0.6,0.3-1.1,0.5
									s-1,0.2-1.4,0.2c0.1-0.9,0.1-1.7,0-2.5c-0.1-0.7-0.2-1.3-0.5-1.6c-0.3-0.3-0.7-0.5-1.1-0.6s-0.7,0-0.9,0.1
									c-0.4,0.3-0.6,0.7-0.6,1.3s0.1,1.3,0.3,2.3c0.2,0.8,0.3,1.5,0.3,2.1c0.1,0.6,0,1.1-0.2,1.7s-0.5,1-1.1,1.4
									c-0.8,0.6-1.7,0.7-2.7,0.4c-1-0.4-1.9-1.2-2.8-2.5C1594.3,1984.7,1593.8,1983.3,1593.7,1982.1z"
						/>
					</g>
					<g className="level_two fade location " id="tolfolas">
						<rect x="1850.752" y="2166.948" width="120.175" height="47.672" transform="matrix(-0.967291, 0.253671, -0.242016, -0.970347, 4055.454102, 3708.830078)"></rect>
						<path
							d="M1624.8,2089.2c0.3-1.4,0.6-3.3,1-5.7s0.7-4.8,1-7.1c0.3-2.5,0.5-4.7,0.6-6.5c-3.6-0.4-5.9-0.5-7-0.3
									c-0.1-0.6-0.1-1.3,0-1.8c0.1-0.5,0.2-1.1,0.5-2c1.1,0.3,2.7,0.6,4.6,0.9c2,0.3,3.9,0.6,5.7,0.8c4.2,0.5,6.8,0.7,7.9,0.4
									c0.1,0.7,0.1,1.3,0,1.9c-0.1,0.5-0.2,1.1-0.5,1.9c-1.7-0.4-4-0.8-6.9-1.2c-0.6,3.6-1.1,6.8-1.5,9.5c-0.7,5.2-0.8,8.5-0.5,9.8
									c-0.9,0.1-1.7,0-2.5-0.1C1626.5,2089.6,1625.7,2089.5,1624.8,2089.2z"
						/>
						<path
							d="M1640.4,2088.8c-1.5-1.6-2.3-3.7-2.3-6.3c0-1.9,0.4-3.6,1.2-5.1c0.8-1.5,1.9-2.7,3.4-3.5c1.5-0.8,3.1-1.3,5-1.3
									c1.7,0,3.1,0.3,4.4,1.1c1.3,0.7,2.3,1.7,3,3.1s1.1,2.8,1.1,4.6c0,1.9-0.4,3.6-1.2,5.1c-0.8,1.5-2,2.7-3.5,3.5
									c-1.5,0.8-3.2,1.3-5.1,1.3C1643.9,2091.2,1641.9,2090.4,1640.4,2088.8z M1651,2087.6c0.9-1.1,1.4-2.7,1.4-4.7
									c0-1.8-0.5-3.3-1.3-4.6s-2.1-2.2-3.6-2.6c-1.7,0-3.1,0.6-4.1,1.8c-1,1.2-1.5,2.7-1.5,4.6c0,2.3,0.5,4,1.5,5.3
									c1,1.3,2.2,1.9,3.7,1.9C1648.8,2089.3,1650.1,2088.7,1651,2087.6z"
						/>
						<path
							d="M1660.7,2089.5c-0.4-0.5-0.7-1.4-0.8-2.7c-0.1-0.9-0.2-2.3-0.4-4.3c-0.2-2.2-0.3-4-0.5-5.4c-0.5-4.6-1.1-7.9-1.9-9.8
									c0.8-0.4,1.6-0.7,2.3-0.9c0.7-0.2,1.6-0.4,2.5-0.5c0,3.6,0.4,8.7,1.1,15.3c0.2,1.5,0.3,2.7,0.5,3.6c0.2,0.8,0.4,1.5,0.7,1.9
									c0.3,0.5,0.7,0.9,1.3,1.4c-0.4,0.6-0.9,1-1.4,1.3c-0.5,0.3-1,0.5-1.5,0.5C1661.8,2090.2,1661.1,2090,1660.7,2089.5z"
						/>
						<path
							d="M1669.2,2095.5c0.5-0.8,0.8-1.6,1-2.3c0.2-0.7,0.3-1.6,0.2-2.7c-0.1-1.1-0.3-2.7-0.6-4.6c-0.4-2.2-1.2-6.1-2.4-11.6
									c-1,0.2-1.7,0.2-2,0.1c-0.4-0.1-0.6-0.3-0.6-0.7c1.1-0.8,1.8-1.4,2.2-1.9c-0.1-2.4,0.4-4.5,1.5-6.1s2.6-2.7,4.6-3
									c1.4-0.3,2.8-0.3,4.2-0.2c0.1,0.4,0.1,1-0.1,1.9c-0.1,0.8-0.3,1.6-0.5,2.3c-1-0.6-2-1.1-3-1.5c-1-0.3-1.8-0.4-2.4-0.3
									c-0.4,0.4-0.7,1.1-0.7,2.1c-0.1,0.9,0.1,2.4,0.4,4.5c2.2-0.5,3.7-0.8,4.5-1.2c0.1,0.6,0.1,1.1,0.1,1.6c0,0.5-0.1,1-0.3,1.6
									c-1-0.1-2.3-0.1-4,0.2l0.5,3c0.3,1.7,0.7,3.7,1.2,5.9c0.3,1.2,0.5,2.2,0.7,3.1c0.3,1.4,0.3,2.6,0.2,3.7s-0.5,2-1.1,3.1
									c-0.6,1-1.6,2.3-3,3.7L1669.2,2095.5z"
						/>
						<path
							d="M1683.2,2084.1c-2-1-3.4-2.7-4.4-5.1c-0.7-1.8-0.9-3.5-0.7-5.2s0.9-3.2,2-4.5s2.5-2.3,4.2-2.9c1.5-0.6,3.1-0.8,4.5-0.5
									s2.7,0.8,3.9,1.8c1.1,1,2,2.3,2.6,3.9c0.7,1.8,0.9,3.5,0.6,5.2c-0.3,1.7-0.9,3.2-2.1,4.5c-1.1,1.3-2.6,2.3-4.4,3
									C1687.3,2085.2,1685.2,2085.1,1683.2,2084.1z M1692.8,2079.3c0.5-1.4,0.4-3-0.4-4.9c-0.6-1.7-1.6-3-2.9-3.9s-2.7-1.3-4.3-1.2
									c-1.6,0.6-2.7,1.6-3.2,3.1c-0.5,1.4-0.5,3,0.2,4.9c0.8,2.1,1.9,3.6,3.3,4.5s2.7,1.1,4.1,0.5
									C1691.3,2081.7,1692.3,2080.7,1692.8,2079.3z"
						/>
						<path
							d="M1704,2076.4c-0.6-0.2-1.2-0.9-1.9-2.1c-0.5-0.8-1.2-2-2.2-3.7c-1.1-1.9-2.1-3.4-2.8-4.6c-2.5-3.9-4.5-6.6-6-8
									c0.5-0.7,1.1-1.3,1.6-1.8c0.6-0.5,1.2-1,2-1.5c1.6,3.2,4.2,7.6,7.8,13.3c0.8,1.3,1.5,2.3,2.1,3c0.5,0.7,1,1.1,1.5,1.4
									c0.5,0.3,1,0.5,1.8,0.7c-0.1,0.7-0.4,1.3-0.7,1.8c-0.3,0.5-0.7,0.9-1.1,1.1C1705.2,2076.5,1704.5,2076.6,1704,2076.4z"
						/>
						<path
							d="M1711.7,2070.4c-1.1-0.1-2-0.5-2.8-1.4c-2.4-2.6-1.1-6.4,3.8-11.4c-1.3-1.4-2.4-2.2-3.3-2.4c-0.9-0.2-1.9,0.3-3.1,1.3
									c-0.7,0.6-1.3,1.4-1.8,2.2c-0.5,0.8-0.9,1.7-1.1,2.6l-0.8-0.4c-0.3-1.5-0.2-3,0.3-4.5c0.5-1.5,1.2-2.7,2.3-3.7
									c1.4-1.3,2.8-1.9,4.3-1.9c1.4,0,2.8,0.7,4.1,2.1c0.7,0.8,1.4,1.7,2.2,2.7c0.8,1.1,1.5,1.9,1.9,2.4c0.7,0.8,1.4,1.4,2,1.6
									c0.6,0.3,1.4,0.5,2.4,0.5c-0.2,1.4-0.7,2.6-1.5,3.6c-0.9,0.2-1.9,0-2.9-0.6c-0.3,1.3-0.7,2.4-1.3,3.5c-0.6,1.1-1.2,2-1.9,2.6
									C1713.7,2070.1,1712.8,2070.4,1711.7,2070.4z M1715.7,2064.6c0.4-0.8,0.6-1.6,0.8-2.4l-0.1-0.2c-0.6-0.7-1.2-1.4-1.7-2.1l-1.2-1.5
									c-1.4,1.4-2.3,2.8-2.6,4.1c-0.3,1.3-0.1,2.3,0.7,3.2c0.3,0.4,0.8,0.6,1.3,0.8s1,0.2,1.5,0.1
									C1714.9,2066.1,1715.3,2065.4,1715.7,2064.6z"
						/>
						<path
							d="M1725.3,2057.4c-0.5-0.3-0.8-0.7-1.2-1c-0.3-0.4-0.7-0.8-1-1.4c0.9-0.1,1.8-0.4,2.7-0.9c0.9-0.5,1.6-1.2,2.2-2.1
									c1.6-2.3,1.8-3.9,0.5-4.8c-0.5-0.4-1.1-0.4-1.9-0.1s-1.7,0.8-2.9,1.6c-1.2,0.7-2.2,1.3-3.1,1.7s-1.7,0.6-2.7,0.7
									c-1,0-1.9-0.2-2.9-0.9c-1.1-0.8-1.7-1.9-1.7-3.4c0-1.5,0.6-3,1.7-4.7c1.5-2.2,3.1-3.8,4.7-5c0.4,0.3,0.8,0.7,1.3,1.4
									c0.5,0.7,0.9,1.3,1.1,2c-1.4,0.3-2.6,0.8-3.6,1.3c-1,0.5-1.7,1-2.1,1.6c-0.3,0.7-0.4,1.3-0.3,1.9c0.1,0.6,0.3,1,0.7,1.3
									c0.7,0.5,1.4,0.5,2.2,0.3s1.9-0.8,3.2-1.7c1.1-0.7,2.1-1.3,2.9-1.6c0.8-0.4,1.7-0.6,2.6-0.6c0.9,0,1.8,0.2,2.7,0.8
									c1.3,0.9,2,2.2,2,3.8c0,1.6-0.7,3.4-2.1,5.4C1728.8,2055.1,1727.1,2056.6,1725.3,2057.4z"
						/>
					</g>
					<g className="level_two fade location " id="ethir_anduin">
						<rect x="2800.02" y="1690.653" width="181.813" height="37.194" transform="matrix(-0.764086, 0.645115, -0.635908, -0.771859, 5122.753418, 1483.259277)"></rect>
						<path
							d="M1766.9,2088c-0.7-0.9-1.6-2.2-2.8-3.7c-2.2-2.8-3.8-4.6-4.8-5.5c0.8-0.4,2-1.2,3.5-2.4c1.8-1.4,3.2-2.6,4.1-3.5
									c0.4,0.5,0.7,1,1,1.6c0.3,0.6,0.5,1,0.5,1.3c-0.5,0-1.1,0.2-1.7,0.5c-0.6,0.3-1.2,0.6-1.8,1.1c-0.7,0.6-1.4,1.2-1.9,1.8
									c1,1.5,2,2.8,2.8,3.9l0.5-0.4c0.5-0.4,1-0.7,1.2-0.9c0.7-0.5,1.2-0.9,1.5-1.2c0.3-0.3,0.7-0.7,1-1.1c0.3,0.3,0.6,0.6,0.8,0.9
									c0.2,0.2,0.4,0.6,0.7,1c-1.6,1-2.8,1.9-3.7,2.6c-0.4,0.3-0.7,0.5-0.8,0.7c1.4,1.9,2.5,3.1,3.1,3.8c0.8-0.3,1.6-0.8,2.4-1.4
									c0.6-0.5,1.2-1,1.7-1.6c0.5-0.6,0.9-1.1,1.1-1.7c0.4,0.5,0.7,1,1,1.6c0.3,0.6,0.5,1,0.5,1.3c-1,0.6-2.3,1.5-3.9,2.7
									c-1.5,1.1-2.5,2-3.2,2.7C1768.6,2090.3,1767.6,2089,1766.9,2088z"
						/>
						<path
							d="M1784,2080.1c-0.4-0.1-1-0.5-1.6-1.2c-0.4-0.5-1.2-1.3-2.1-2.4c-1.1-1.2-2-2.2-2.7-3c-0.3-0.4-0.7-0.7-1-1.1
									c-0.5,0.5-0.9,0.7-1.2,0.8c-0.3,0.1-0.5,0-0.7-0.2c0.3-1.3,0.4-2.9,0.2-4.6l0.5-0.3c0.5,0.5,1.1,1,1.6,1.6
									c0.8-0.8,1.4-1.4,1.7-1.9c0.3,0.3,0.5,0.7,0.7,1c0.2,0.3,0.3,0.6,0.5,1c-0.5,0.2-1.2,0.6-1.9,1.2c0.6,0.8,1.3,1.5,2,2.3
									c1.8,2,3.2,3.4,4.3,4.2c0.5-0.6,0.9-1.1,1.2-1.6l0.6,0.4c0.2,0.7,0.2,1.3,0,2c-0.2,0.6-0.5,1.2-0.9,1.5
									C1784.9,2080.1,1784.5,2080.3,1784,2080.1z"
						/>
						<path
							d="M1792.9,2072.1c-1.9-2-3.9-3.9-5.9-5.7c-2.7-2.5-4.9-4.5-6.6-5.8c0.3-0.9,0.8-1.8,1.5-2.5c0.7,0.8,2.1,2.3,4.3,4.5
									c-0.1-0.8-0.1-1.6,0-2.3s0.4-1.3,0.9-1.8c1.4-1.5,3.2-1.2,5.3,0.8l1.5,1.4l1.5,1.5c1.7,1.5,3,2.5,3.9,2.9
									c-0.2,0.4-0.5,0.8-0.8,1.1c-0.3,0.3-0.7,0.6-1.2,1c-2.2-2.2-4.2-4.1-6.1-5.9c-0.6-0.6-1.2-0.9-1.7-0.9c-0.5,0-0.9,0.2-1.4,0.6
									c-0.6,0.7-1,1.5-1.1,2.4c0.4,0.4,1.1,1.1,1.9,1.8c2.9,2.7,4.9,4.3,5.8,4.6c-0.2,0.4-0.5,0.8-0.8,1.2
									C1793.8,2071.5,1793.4,2071.8,1792.9,2072.1z"
						/>
						<path
							d="M1791.1,2047.9c-0.2-0.9-0.4-1.8-0.4-2.5c0.9,0.1,1.7,0.2,2.6,0.1c0.2,0.8,0.4,1.7,0.4,2.5
									C1792.8,2047.9,1792,2047.9,1791.1,2047.9z M1804.5,2058.6c-0.4-0.1-1-0.5-1.8-1.1c-0.6-0.5-1.4-1.3-2.5-2.3c-1-0.9-1.8-1.6-2.2-2
									c-0.7-0.6-1.3-1.1-1.9-1.5c-0.6-0.4-1.1-0.7-1.5-0.8c0.1-0.5,0.3-1,0.5-1.4c0.2-0.4,0.5-0.8,0.9-1.2c0.4,0.5,1.1,1.2,2,2.1
									c0.9,0.9,2,1.9,3.2,3c0.9,0.8,1.6,1.4,2.1,1.7c0.5,0.4,0.9,0.6,1.3,0.8c0.4,0.1,0.8,0.2,1.3,0.3c0.1,0.5,0.1,0.9,0,1.3
									c-0.1,0.4-0.2,0.7-0.4,0.9C1805.3,2058.6,1805,2058.7,1804.5,2058.6z"
						/>
						<path
							d="M1811.8,2051c-1.5-1.5-3.2-3.1-5-4.7c-0.9-0.8-1.6-1.4-2.1-1.7c-0.5-0.4-0.9-0.6-1.3-0.8c-0.4-0.1-0.8-0.2-1.3-0.3
									c-0.1-0.5-0.1-0.9,0-1.3s0.2-0.7,0.4-0.9c0.7-0.7,1.6-0.6,2.8,0.4c-0.2-0.8-0.3-1.5-0.3-2.2c0-0.7,0.2-1.3,0.5-2
									c0.3,0.1,0.8,0.4,1.3,0.8c0.5,0.4,1,0.7,1.4,1.1c-0.2,0.1-0.4,0.3-0.6,0.6c-0.4,0.4-0.7,0.9-0.9,1.3s-0.3,0.9-0.4,1.3l0.7,0.7
									c1,1,1.9,1.8,2.8,2.6c1.7,1.5,3,2.5,3.9,2.8c-0.3,0.5-0.5,0.9-0.8,1.2C1812.6,2050.3,1812.2,2050.6,1811.8,2051z"
						/>
						<path
							d="M1832.9,2028.2l-3.8-2.1c-0.6,0.5-1.4,1.2-2.2,2.1c-0.9,0.9-1.5,1.6-1.7,2c0.6,1.1,1.3,2.4,2.1,3.8
									c-0.1,0.3-0.3,0.6-0.5,0.8c-0.2,0.2-0.5,0.5-0.9,0.6c-0.4-1.1-1.6-3.5-3.6-7.2c-2-3.7-3.5-6.5-4.7-8.6c0.3-0.4,0.5-0.7,0.7-0.9
									c0.2-0.2,0.5-0.4,0.8-0.6c2,1.2,4.9,2.7,8.6,4.6c3.7,1.9,6.1,3.1,7.2,3.5c-0.2,0.4-0.5,0.8-0.8,1.1
									C1833.8,2027.7,1833.4,2028,1832.9,2028.2z M1827.3,2025.2c-2.6-1.4-4.9-2.6-6.7-3.4c0.8,1.7,2,3.9,3.5,6.6
									c0.7-0.6,1.4-1.3,2.2-2.1C1826.8,2025.8,1827.1,2025.4,1827.3,2025.2z"
						/>
						<path
							d="M1839.6,2021.8c-1.3-1.7-2.8-3.4-4.5-5.2c-0.8-0.9-1.4-1.5-1.9-1.9c-0.5-0.4-0.9-0.7-1.2-0.9s-0.8-0.3-1.2-0.4
									c-0.1-0.5,0-0.9,0.1-1.3c0.1-0.3,0.3-0.6,0.5-0.8c0.7-0.6,1.5-0.5,2.5,0.4c-0.1-0.8,0-1.5,0.2-2.2c0.2-0.7,0.6-1.2,1-1.7
									c1.5-1.4,3.3-0.9,5.2,1.3l1.3,1.5c0.5,0.6,1,1.2,1.4,1.7c1.5,1.6,2.7,2.7,3.6,3.2c-0.3,0.4-0.6,0.8-0.9,1.1
									c-0.3,0.3-0.8,0.6-1.3,0.9c-1.6-2-3.5-4.1-5.5-6.4c-0.6-0.6-1.1-1-1.6-1.1c-0.5-0.1-0.9,0.1-1.4,0.5c-0.7,0.6-1.1,1.4-1.3,2.1
									l0.9,1c0.7,0.8,1.5,1.8,2.6,2.9c1.5,1.7,2.7,2.8,3.6,3.2c-0.3,0.4-0.6,0.8-1,1.1C1840.6,2021.3,1840.2,2021.5,1839.6,2021.8z"
						/>
						<path
							d="M1851.7,2009.5c-1.3-0.5-2.6-1.6-4-3.4c-1.4-1.8-2.2-3.4-2.3-4.8c-0.1-1.4,0.4-2.5,1.5-3.4c0.9-0.7,2-1,3.3-0.9
									c-1.4-1.6-2.5-2.8-3.3-3.5c0.3-0.5,0.5-0.9,0.8-1.3s0.7-0.7,1.1-1c1.5,2.3,4.1,5.9,8,11c0.5,0.6,0.9,1,1.3,1.3
									c0.4,0.3,0.9,0.5,1.5,0.6c-0.1,0.9-0.4,1.6-0.9,2.2c-0.6-0.1-1.3-0.3-1.9-0.8c0,0.7-0.2,1.4-0.4,2s-0.6,1.1-1.1,1.4
									C1854.2,2009.8,1853,2010,1851.7,2009.5z M1855.6,2006.1c0.2-0.5,0.3-1,0.4-1.5c-0.4-0.5-1.1-1.4-2-2.7c-1-1.4-1.9-2.5-2.5-3.3
									c-0.9,0-1.7,0.3-2.4,0.8c-0.7,0.6-1,1.3-0.9,2.2c0.1,0.9,0.6,2,1.5,3.2c0.9,1.2,1.8,2,2.7,2.4c0.9,0.4,1.7,0.5,2.5,0.1
									C1855.1,2007,1855.4,2006.6,1855.6,2006.1z"
						/>
						<path
							d="M1863.6,1997.4l-1-1.7l-1.1-1.8c-1.1-1.8-2.1-3-2.8-3.5c0.5-0.8,1.2-1.5,2.1-2.1c1.3,2.3,2.9,4.8,4.7,7.7
									c0.5,0.7,0.9,1.2,1.4,1.4c0.5,0.2,1,0.1,1.5-0.3c0.7-0.5,1.2-1.1,1.6-1.9c-0.8-1.2-1.4-2.3-2-3.3l-0.9-1.5c-1.1-1.8-2.1-3-2.8-3.5
									c0.5-0.8,1.2-1.5,2.1-2.1c0.7,1.3,1.6,2.8,2.6,4.6c1,1.8,1.7,2.9,2.1,3.5c0.4,0.7,0.8,1.1,1.2,1.4s0.8,0.6,1.5,0.8
									c-0.2,0.9-0.6,1.6-1.1,2.1c-0.7-0.1-1.3-0.5-1.9-1.1c-0.1,0.8-0.3,1.5-0.6,2.1c-0.4,0.6-0.8,1.1-1.4,1.5
									C1866.8,2000.6,1865.2,1999.9,1863.6,1997.4z"
						/>
						<path
							d="M1871.3,1977.1c0.1-0.9,0.2-1.8,0.5-2.5c0.8,0.4,1.6,0.7,2.4,1c-0.1,0.9-0.2,1.7-0.5,2.5
									C1872.9,1977.7,1872.2,1977.4,1871.3,1977.1z M1880.2,1991.9c-0.4-0.2-0.8-0.8-1.2-1.7c-0.4-0.7-0.9-1.7-1.6-3
									c-0.6-1.2-1.1-2.1-1.4-2.7c-0.4-0.8-0.8-1.5-1.3-2.1c-0.4-0.6-0.8-1-1.1-1.2c0.3-0.4,0.6-0.8,1-1.1c0.3-0.3,0.8-0.6,1.3-0.8
									c0.2,0.6,0.6,1.5,1.2,2.6c0.6,1.2,1.2,2.5,2,3.9c0.6,1,1,1.8,1.4,2.3c0.3,0.5,0.7,0.9,1,1.2c0.3,0.3,0.7,0.5,1.1,0.7
									c-0.1,0.5-0.2,0.9-0.4,1.2c-0.2,0.3-0.4,0.6-0.6,0.7C1881,1992.1,1880.6,1992.1,1880.2,1991.9z"
						/>
						<path
							d="M1889.4,1987.4c-0.8-2-1.6-4.1-2.6-6.3c-0.5-1.1-0.9-1.9-1.2-2.4c-0.3-0.5-0.6-1-0.9-1.2c-0.3-0.3-0.6-0.5-1-0.8
									c0.1-0.5,0.2-0.9,0.5-1.2c0.2-0.3,0.4-0.5,0.7-0.6c0.8-0.4,1.6,0,2.2,1.1c0.2-0.8,0.4-1.4,0.9-2c0.4-0.6,0.9-1,1.5-1.3
									c1.9-0.8,3.4,0.1,4.6,2.8l0.8,1.9c0.3,0.7,0.5,1.4,0.8,2c0.9,2,1.7,3.4,2.5,4.2c-0.4,0.3-0.8,0.6-1.2,0.7
									c-0.4,0.2-0.9,0.3-1.5,0.5c-0.9-2.4-2-5-3.3-7.8c-0.3-0.8-0.7-1.3-1.1-1.5c-0.4-0.2-0.9-0.2-1.5,0c-0.9,0.4-1.5,0.9-1.9,1.6
									l0.5,1.3c0.4,1,0.9,2.2,1.5,3.6c0.9,2.1,1.7,3.5,2.4,4.2c-0.4,0.3-0.9,0.6-1.3,0.8C1890.5,1987.2,1890,1987.3,1889.4,1987.4z"
						/>
					</g>
					<g className="level_zero location " id="poros">
						<rect x="967.402" y="1095.884" width="62.817" height="24.11" transform="matrix(-0.936437, 0.350836, -0.339551, -0.940665, 3320.123291, 2682.181152)"></rect>
						<path
							d="M1988.9,2008.3c-0.5-0.9-1.1-2.1-2-3.6s-1.7-2.9-2.5-4.3c-1.7-2.8-2.9-4.5-3.5-5c0.3-0.5,0.8-1,1.4-1.5s1.3-1,2-1.5
									c1.4-0.8,2.7-1.1,3.9-0.8s2.3,1.2,3.2,2.7c0.6,1,0.9,2,1,2.9c0.1,1-0.1,1.9-0.5,2.8c-0.4,0.8-1.1,1.5-2,2.1
									c-0.6,0.3-1.1,0.6-1.6,0.7c1.4,2.2,2.3,3.6,2.9,4.1c-0.3,0.3-0.7,0.6-1.1,0.9C1989.8,2007.9,1989.4,2008.1,1988.9,2008.3z
									M1990.3,1998.2c-0.1-0.9-0.3-1.7-0.8-2.5c-1.2-2-2.8-2.5-4.6-1.4c-0.4,0.2-0.7,0.5-1,0.9c1,1.9,2.2,3.9,3.4,5.9
									c1.2-0.2,2.1-0.5,2.6-0.8C1990.2,1999.7,1990.4,1999,1990.3,1998.2z"
						/>
						<path
							d="M1998.9,2000.9c-1.2-0.6-2.2-1.8-3-3.4c-0.6-1.2-0.9-2.3-0.9-3.5s0.2-2.1,0.6-3s1.2-1.5,2.1-2c0.8-0.4,1.6-0.5,2.5-0.4
									c0.8,0.2,1.6,0.6,2.4,1.2c0.7,0.7,1.4,1.5,1.9,2.6c0.6,1.2,0.9,2.3,0.9,3.5s-0.2,2.1-0.7,3c-0.5,0.9-1.2,1.5-2.2,2
									C2001.3,2001.6,2000.1,2001.5,1998.9,2000.9z M2003.8,1997.7c0.1-0.9-0.1-2-0.7-3.3c-0.5-1.1-1.2-2-2-2.6
									c-0.8-0.6-1.7-0.9-2.5-0.8c-0.8,0.4-1.3,1.1-1.5,2.1c-0.2,1,0.1,2,0.7,3.2c0.7,1.4,1.5,2.4,2.3,3c0.9,0.6,1.7,0.7,2.4,0.3
									C2003.1,1999.3,2003.6,1998.6,2003.8,1997.7z"
						/>
						<path
							d="M2010.3,1997.1c-0.6-1.9-1.4-3.9-2.2-6c-0.4-1-0.7-1.8-1-2.3c-0.3-0.5-0.5-0.9-0.8-1.2c-0.2-0.3-0.6-0.5-0.9-0.8
									c0.1-0.4,0.3-0.8,0.5-1.1c0.2-0.3,0.4-0.5,0.7-0.6c0.9-0.3,1.6,0.2,2.2,1.5c0.2-0.7,0.4-1.4,0.7-2c0.3-0.6,0.7-1,1.2-1.5
									c0.2,0.3,0.5,0.6,0.8,1.2s0.5,1,0.7,1.5c-0.2,0-0.5,0.1-0.8,0.2c-0.5,0.2-0.9,0.4-1.3,0.8s-0.7,0.6-0.9,0.9l0.3,0.9
									c0.5,1.2,0.9,2.3,1.2,3.3c0.8,2,1.5,3.3,2.1,4c-0.4,0.3-0.8,0.5-1.2,0.7C2011.3,1996.9,2010.8,1997,2010.3,1997.1z"
						/>
						<path
							d="M2017.5,1992.9c-1.1-0.8-1.9-2.1-2.5-3.9c-0.4-1.3-0.5-2.4-0.4-3.6c0.1-1.1,0.5-2.1,1.1-2.8c0.6-0.8,1.4-1.3,2.4-1.6
									c0.9-0.3,1.7-0.3,2.5,0c0.8,0.3,1.5,0.8,2.2,1.6c0.6,0.8,1.1,1.7,1.5,2.9c0.4,1.3,0.5,2.4,0.4,3.6c-0.1,1.1-0.5,2.1-1.1,2.9
									c-0.6,0.8-1.4,1.3-2.4,1.6C2019.8,1993.9,2018.6,1993.7,2017.5,1992.9z M2022.8,1990.5c0.3-0.9,0.2-2-0.2-3.3
									c-0.4-1.2-0.9-2.1-1.6-2.9c-0.7-0.7-1.5-1.1-2.4-1.2c-0.9,0.3-1.5,0.9-1.8,1.8c-0.3,0.9-0.3,2,0.1,3.3c0.5,1.5,1.1,2.6,1.8,3.3
									c0.8,0.7,1.5,1,2.3,0.7C2022,1991.9,2022.6,1991.4,2022.8,1990.5z"
						/>
						<path
							d="M2027.8,1990.5c-0.1-0.4-0.1-0.7-0.1-1.1c0-0.3,0-0.7,0.1-1.2c0.4,0.4,0.9,0.7,1.5,0.9s1.1,0.2,1.7,0.1
									c1.5-0.3,2.1-1,1.9-2c-0.1-0.4-0.3-0.8-0.7-1c-0.4-0.2-1-0.4-1.8-0.7c-0.8-0.2-1.4-0.5-1.9-0.7s-1-0.5-1.4-1
									c-0.4-0.5-0.7-1.1-0.9-1.8c-0.2-0.9,0-1.7,0.5-2.4c0.5-0.7,1.3-1.2,2.4-1.4c1.4-0.3,2.7-0.3,3.8-0.1c0.1,0.3,0.1,0.7,0.1,1.3
									c0,0.5-0.1,1.1-0.2,1.5c-0.7-0.5-1.4-0.9-2-1.2s-1.1-0.4-1.5-0.3c-0.4,0.2-0.6,0.4-0.8,0.7c-0.2,0.3-0.2,0.6-0.2,0.9
									c0.1,0.5,0.4,0.9,0.8,1.2c0.4,0.3,1.1,0.5,2,0.8c0.7,0.2,1.3,0.4,1.8,0.6s0.9,0.5,1.3,1c0.4,0.4,0.7,1,0.8,1.7
									c0.2,1.1,0.1,2-0.5,2.8c-0.6,0.8-1.5,1.3-2.8,1.6C2030.2,1991,2028.9,1991,2027.8,1990.5z"
						/>
					</g>
					<g className="level_one location " id="ered_nimrais">
						<rect x="5180.131" y="1524.342" width="336.356" height="33.534" transform="matrix(0.996232, 0.086723, -0.087569, 0.996159, -3830.046143, -406.256805)"></rect>
						<path
							d="M1200.7,1580.8c0.1-1.2,0.3-2.8,0.4-4.8c0.3-3.7,0.3-6.2,0-7.6c1,0.2,2.6,0.4,4.8,0.6c2.7,0.2,4.7,0.3,6.2,0.2
									c0,0.6-0.1,1.2-0.3,1.9c-0.2,0.7-0.4,1.1-0.5,1.4c-0.5-0.3-1.1-0.6-1.8-0.8c-0.7-0.2-1.5-0.3-2.3-0.4c-1.1-0.1-2-0.1-3,0.1
									c-0.2,1.9-0.4,3.5-0.5,5l0.7,0.1c0.8,0.1,1.3,0.2,1.7,0.2c1,0.1,1.7,0.1,2.2,0.1s1.1-0.1,1.7-0.2c0,0.4,0.1,0.9,0,1.2
									c0,0.3-0.1,0.7-0.2,1.3c-2.2-0.3-3.9-0.5-5.1-0.6c-0.6,0-1-0.1-1.2-0.1c-0.2,2.4-0.3,4.1-0.2,5.1c0.9,0.3,2,0.5,3.1,0.6
									c0.9,0.1,1.8,0,2.6,0c0.9-0.1,1.6-0.3,2.2-0.5c0,0.6-0.1,1.2-0.3,1.9c-0.2,0.7-0.4,1.1-0.5,1.4c-1.3-0.2-3.1-0.5-5.5-0.6
									c-2.1-0.2-3.7-0.2-4.8-0.1C1200.4,1583.7,1200.6,1582,1200.7,1580.8z"
						/>
						<path
							d="M1227.5,1587.9c0.2-1.1,0.3-2.6,0.6-4.6c0.2-2,0.4-3.8,0.5-5.4c0.3-3.7,0.2-5.9,0-6.8c0.6-0.2,1.5-0.4,2.5-0.5
									c1-0.1,2-0.1,3.1,0c2,0.1,3.5,0.8,4.5,1.8s1.4,2.6,1.3,4.5c-0.1,1.1-0.4,2.1-1,2.9c-0.6,0.8-1.4,1.5-2.4,1.9
									c1.3,3.5,2.4,5.6,3.2,6.5c-0.6,0.2-1.1,0.4-1.7,0.4c-0.6,0.1-1.2,0.1-1.9,0c-0.3-1.2-1.1-3.4-2.4-6.4c-0.1,0-0.3,0-0.5,0
									c-0.4,0-0.8-0.1-1.3-0.2c-0.5-0.1-0.8-0.2-1.1-0.3c-0.2,3.2-0.2,5.3,0,6.3c-0.5,0.1-1.1,0.1-1.7,0.1
									C1228.7,1588.2,1228.2,1588,1227.5,1587.9z M1236.2,1579.2c0.5-0.7,0.8-1.4,0.9-2.3c0.2-2.6-1.1-4-3.7-4.2c-0.6,0-1.1,0-1.6,0.1
									c-0.2,1.6-0.4,3.9-0.7,6.8c1.4,0.7,2.4,1,3.2,1.1C1235,1580.3,1235.7,1579.9,1236.2,1579.2z"
						/>
						<path
							d="M1256.4,1584.8c0.1-1.2,0.3-2.8,0.4-4.8c0.3-3.7,0.3-6.2,0-7.6c1,0.2,2.6,0.4,4.8,0.6c2.7,0.2,4.7,0.3,6.2,0.2
									c0,0.6-0.1,1.2-0.3,1.9c-0.2,0.7-0.4,1.1-0.5,1.4c-0.5-0.3-1.1-0.6-1.8-0.8c-0.7-0.2-1.5-0.3-2.3-0.4c-1.1-0.1-2-0.1-3,0.1
									c-0.2,1.9-0.4,3.5-0.5,5l0.7,0.1c0.8,0.1,1.3,0.2,1.7,0.2c1,0.1,1.7,0.1,2.2,0.1c0.5,0,1.1-0.1,1.7-0.2c0,0.4,0.1,0.9,0,1.2
									c0,0.3-0.1,0.7-0.2,1.3c-2.2-0.3-3.9-0.5-5.1-0.6c-0.6,0-1-0.1-1.2-0.1c-0.2,2.4-0.3,4.1-0.2,5.1c0.9,0.3,2,0.5,3.1,0.6
									c0.9,0.1,1.8,0,2.6,0c0.9-0.1,1.6-0.3,2.2-0.5c0,0.6-0.1,1.2-0.3,1.9c-0.2,0.7-0.4,1.1-0.5,1.4c-1.3-0.2-3.1-0.5-5.5-0.6
									c-2.1-0.2-3.7-0.2-4.8-0.1C1256.1,1587.7,1256.3,1585.9,1256.4,1584.8z"
						/>
						<path
							d="M1285.6,1592c-0.9-0.2-1.7-0.3-2.3-0.5c0.2-1.1,0.4-2.5,0.5-4.4c0.2-1.8,0.3-3.6,0.5-5.3c0.3-3.7,0.3-6,0-6.8
									c0.7-0.2,1.5-0.4,2.6-0.5c1.1-0.1,2.2-0.1,3.3,0c2.7,0.2,4.6,1.1,5.9,2.7c1.3,1.6,1.9,3.9,1.7,6.7c-0.1,1.8-0.6,3.3-1.4,4.6
									c-0.8,1.3-1.9,2.3-3.3,3c-1.4,0.7-3,0.9-4.7,0.8C1287.4,1592.3,1286.5,1592.2,1285.6,1592z M1292.2,1589.7
									c0.7-0.6,1.4-1.4,1.8-2.4c0.5-1,0.8-2,0.8-3c0.3-4.8-1.5-7.4-5.5-7.7c-0.7,0-1.3,0-1.9,0.2c-0.3,2.5-0.6,5-0.8,7.7
									c-0.2,2.1-0.2,3.9-0.2,5.4c1.5,0.5,2.6,0.9,3.4,0.9C1290.6,1590.7,1291.4,1590.4,1292.2,1589.7z"
						/>
						<path
							d="M1378.7,1607.7c0.4-2.4,0.7-5.9,1-10.3c0.3-4.1,0.3-6.7,0.1-7.8c0.8-0.1,1.4-0.1,1.9-0.1c0.6,0,1.3,0.2,2.2,0.4
									c0.9,1.7,2.1,4,3.7,7c1.6,3,3,5.6,4.3,7.8c0.2-2.7,0.4-4.8,0.5-6.4c0.3-4.3,0.3-6.9,0.1-7.8c0.5-0.1,1-0.1,1.5-0.1
									c0.5,0,1,0.1,1.5,0.3c-0.2,1.2-0.3,2.8-0.5,4.8c-0.2,2-0.4,3.9-0.5,5.6c-0.3,4.1-0.3,6.7-0.1,7.8c-0.4,0.1-1.1,0.1-1.9,0.1
									c-0.6,0-1.3-0.2-2.2-0.4c-0.8-1.6-2.1-4-3.7-7c-1.6-3-3-5.6-4.3-7.8c-0.2,2-0.4,4.1-0.6,6.3c-0.3,4.1-0.3,6.7-0.1,7.8
									c-0.5,0.1-1,0.1-1.5,0.1C1379.7,1608,1379.3,1607.9,1378.7,1607.7z"
						/>
						<path
							d="M1404.4,1609.5c0.2-1.1,0.4-2.7,0.6-4.7c0.2-2,0.4-3.9,0.5-5.7c0.3-4.2,0.3-6.8-0.1-7.8c0.7-0.1,1.4-0.1,2.1-0.1
									c0.7,0,1.4,0.2,2.2,0.4c-0.2,1.1-0.4,2.7-0.6,4.6s-0.4,3.9-0.5,5.6c-0.3,4.2-0.3,6.8,0.1,7.8c-0.7,0.1-1.4,0.1-2.1,0.1
									C1405.9,1609.8,1405.2,1609.7,1404.4,1609.5z"
						/>
						<path
							d="M1418.7,1610.5c0.4-2.4,0.7-5.9,1-10.3c0.2-2.3,0.3-4.1,0.3-5.2c0-1.2-0.1-2-0.2-2.5c0.7-0.1,1.4-0.1,2.1-0.1
									c0.6,0,1.3,0.2,2.2,0.4c0.6,1.7,1.6,4,2.8,6.9c1.2,2.9,2.2,5.3,3,7c1.1-1.7,2.4-3.8,3.9-6.4c1.5-2.6,2.8-4.8,3.8-6.6
									c0.5-0.1,1.2-0.1,2.1-0.1c0.6,0,1.3,0.2,2.1,0.4c-0.2,1.1-0.4,2.6-0.6,4.6c-0.2,2-0.4,3.8-0.5,5.7c-0.3,4.3-0.3,6.9,0.1,7.8
									c-0.7,0.1-1.4,0.1-2.1,0.1c-0.7,0-1.4-0.2-2.2-0.4c0.2-1.1,0.4-2.7,0.6-4.7c0.2-2,0.4-3.8,0.5-5.6c0.1-1.1,0.1-2.5,0.2-3.9
									c-2.2,3.4-4.9,8-8.2,13.7c-0.3,0.1-0.6,0.1-0.8,0.1c-0.2,0-0.4-0.1-0.7-0.2c-0.6-1.5-1.4-3.6-2.6-6.4c-1.1-2.8-2.2-5.2-3.2-7.4
									c-0.2,2.4-0.4,4.2-0.5,5.5c-0.3,4.1-0.3,6.7-0.1,7.8c-0.5,0.1-0.9,0.1-1.4,0.1C1419.8,1610.8,1419.2,1610.7,1418.7,1610.5z"
						/>
						<path
							d="M1450.8,1612.8c0.2-1.1,0.4-2.7,0.6-4.8c0.2-2.1,0.4-3.9,0.5-5.6c0.3-3.8,0.2-6.1-0.1-7c0.8-0.2,1.8-0.4,3.1-0.5
									s2.6-0.1,3.9,0c2.5,0.2,4.4,0.8,5.7,2c1.3,1.1,1.9,2.7,1.7,4.6c-0.1,1.2-0.5,2.2-1.2,3c-0.7,0.9-1.7,1.5-3,1.9
									c1.7,3.6,3.1,5.9,4.2,6.8c-0.7,0.2-1.4,0.4-2.1,0.4c-0.7,0.1-1.5,0-2.4,0c-0.4-1.3-1.5-3.5-3.1-6.7c-0.1,0-0.3,0-0.7,0
									c-0.5,0-1-0.1-1.6-0.2c-0.6-0.1-1-0.2-1.4-0.3c-0.2,3.3-0.2,5.5,0.1,6.5c-0.7,0.1-1.4,0.1-2.1,0.1
									C1452.3,1613.1,1451.6,1613,1450.8,1612.8z M1461.6,1604c0.7-0.7,1-1.4,1.1-2.3c0.2-2.7-1.4-4.2-4.8-4.4c-0.7-0.1-1.4,0-2.1,0.1
									c-0.2,1.7-0.5,4-0.8,7c1.7,0.7,3.1,1.1,4.1,1.2C1460.1,1605.1,1460.9,1604.6,1461.6,1604z"
						/>
						<path
							d="M1487.1,1615.3l-1.4-4.6c-1.2-0.2-2.6-0.3-4.4-0.5c-1.9-0.1-3.1-0.2-3.8-0.1c-0.6,1.3-1.3,2.7-2,4.3
									c-0.5,0.1-0.9,0.1-1.3,0.1c-0.5,0-1-0.1-1.5-0.3c0.8-1.1,2.2-3.7,4.3-7.8c2.1-4.1,3.7-7.4,4.7-9.7c0.7-0.1,1.2-0.1,1.7-0.1
									c0.5,0,1,0.1,1.5,0.3c0.7,2.5,1.8,5.9,3.3,10.3c1.5,4.4,2.5,7.2,3.2,8.4c-0.6,0.1-1.3,0.1-2,0.1
									C1488.6,1615.7,1487.9,1615.5,1487.1,1615.3z M1485,1608.6c-1-3.2-1.9-5.8-2.7-7.9c-1,1.8-2.3,4.3-3.7,7.4
									c1.3,0.2,2.7,0.3,4.3,0.4C1483.9,1608.6,1484.6,1608.6,1485,1608.6z"
						/>
						<path
							d="M1499.2,1616.2c0.2-1.1,0.4-2.7,0.6-4.7c0.2-2,0.4-3.9,0.5-5.7c0.3-4.2,0.3-6.8-0.1-7.8c0.7-0.1,1.4-0.1,2.1-0.1
									c0.7,0,1.4,0.2,2.2,0.4c-0.2,1.1-0.4,2.7-0.6,4.6c-0.2,2-0.4,3.9-0.5,5.6c-0.3,4.2-0.3,6.8,0.1,7.8c-0.7,0.1-1.4,0.1-2.1,0.1
									C1500.7,1616.6,1500,1616.4,1499.2,1616.2z"
						/>
						<path
							d="M1512.5,1616.3c0-0.5,0.1-0.9,0.2-1.3c0.1-0.4,0.3-0.7,0.6-1.1c0.5,0.6,1.3,1.1,2.3,1.5c1,0.4,2.1,0.6,3.2,0.7
									c1.4,0.1,2.4-0.1,3.2-0.5c0.8-0.4,1.2-1.1,1.2-2c0.1-0.8-0.3-1.4-0.9-2c-0.7-0.6-1.7-1.2-3.2-2c-1.3-0.7-2.3-1.3-3-1.8
									c-0.7-0.5-1.4-1.1-1.9-1.9c-0.5-0.8-0.7-1.6-0.6-2.6c0.1-1.3,0.9-2.4,2.3-3.1c1.4-0.7,3.3-1,5.6-0.8c2.1,0.1,3.8,0.5,5.3,1.2
									c0,0.5-0.2,1.1-0.4,1.7c-0.2,0.6-0.5,1.1-0.8,1.5c-1-0.8-2.1-1.4-3.1-1.9c-1.1-0.5-2-0.8-2.7-0.9c-0.5,0.1-1.1,0.3-1.6,0.7
									c-0.5,0.4-0.8,1-0.9,1.6c-0.1,0.8,0.3,1.5,1,2.1c0.7,0.6,1.8,1.3,3.2,2.1c1.2,0.7,2.2,1.2,2.9,1.7c0.7,0.5,1.3,1.1,1.8,1.8
									c0.5,0.7,0.7,1.6,0.6,2.5c-0.1,1.6-0.8,2.8-2.1,3.6c-1.3,0.8-3.2,1.1-5.7,0.9C1516.4,1617.8,1514.3,1617.3,1512.5,1616.3z"
						/>
					</g>
					<g className="level_two fade location " id="pinnath_gelin">
						<rect x="2776.988" y="1622.616" width="180.314" height="35.696" transform="matrix(0.984365, -0.176139, 0.175303, 0.984515, -1855.950317, 603.684387)"></rect>
						<path
							d="M1172.9,1741c-0.3-1.2-0.8-2.9-1.5-5s-1.4-4-2-5.9c-1.4-3.9-2.5-6.2-3.2-7c0.5-0.5,1.3-1.1,2.3-1.6c1-0.6,2-1,3.1-1.4
									c2.1-0.8,3.9-0.8,5.4-0.1s2.6,2,3.4,4c0.5,1.3,0.6,2.7,0.4,3.9c-0.2,1.3-0.7,2.4-1.5,3.3c-0.8,1-1.9,1.7-3.2,2.2
									c-0.8,0.3-1.6,0.5-2.3,0.6c1.2,3.1,2,5,2.6,5.8c-0.5,0.3-1.1,0.6-1.7,0.9C1174.3,1740.7,1173.7,1740.9,1172.9,1741z
									M1177.9,1728.6c0.2-1.1,0.1-2.2-0.3-3.3c-1-2.8-2.9-3.7-5.7-2.7c-0.6,0.2-1.1,0.5-1.6,0.9c0.8,2.7,1.7,5.4,2.7,8.1
									c1.7,0,2.9-0.2,3.7-0.5C1177.3,1730.5,1177.7,1729.6,1177.9,1728.6z"
						/>
						<path
							d="M1182.7,1716.8c0.4-1,0.9-1.9,1.4-2.7c0.8,0.7,1.6,1.2,2.5,1.7c-0.4,1-0.9,1.8-1.4,2.7
									C1184.4,1717.9,1183.6,1717.3,1182.7,1716.8z M1188.4,1735.5c-0.4-0.4-0.7-1.1-0.9-2.2c-0.2-0.9-0.5-2.1-0.9-3.8
									c-0.3-1.5-0.6-2.6-0.8-3.4c-0.2-1-0.5-1.9-0.8-2.6s-0.6-1.3-0.9-1.6c0.5-0.4,1-0.7,1.5-1c0.5-0.2,1.1-0.4,1.8-0.6
									c0.1,0.7,0.2,1.8,0.5,3.2s0.6,3.1,1.1,4.8c0.3,1.3,0.6,2.3,0.8,3c0.2,0.7,0.5,1.2,0.7,1.6c0.3,0.4,0.6,0.7,1.1,1.1
									c-0.2,0.5-0.5,0.9-0.9,1.2c-0.3,0.3-0.7,0.5-1,0.6C1189.2,1736,1188.8,1735.9,1188.4,1735.5z"
						/>
						<path
							d="M1196.1,1734.1c-0.2-2.4-0.5-5-0.9-7.8c-0.2-1.3-0.4-2.3-0.5-3c-0.2-0.7-0.4-1.2-0.6-1.6c-0.2-0.4-0.5-0.8-1-1.2
									c0.3-0.5,0.6-0.9,0.9-1.2c0.4-0.3,0.7-0.4,1-0.5c1.1-0.2,1.9,0.5,2.3,1.9c0.5-0.8,1-1.5,1.7-2c0.7-0.5,1.4-0.8,2.2-0.9
									c2.5-0.4,4,1.1,4.5,4.5l0.3,2.3c0.1,0.9,0.1,1.7,0.2,2.4c0.4,2.5,0.8,4.3,1.4,5.3c-0.6,0.2-1.1,0.4-1.7,0.5
									c-0.6,0.1-1.2,0.1-2,0.1c-0.3-2.9-0.6-6.1-1.1-9.5c-0.1-1-0.4-1.6-0.8-2c-0.4-0.4-1-0.5-1.8-0.4c-1.2,0.2-2.1,0.6-2.8,1.2l0.2,1.6
									c0.1,1.2,0.3,2.7,0.5,4.4c0.4,2.5,0.8,4.3,1.4,5.4c-0.6,0.2-1.2,0.4-1.8,0.5C1197.5,1734.1,1196.9,1734.1,1196.1,1734.1z"
						/>
						<path
							d="M1212.9,1732.3c0-2.4-0.2-5-0.4-7.8c-0.1-1.3-0.2-2.4-0.4-3.1c-0.1-0.7-0.3-1.3-0.5-1.7c-0.2-0.4-0.5-0.8-0.9-1.2
									c0.3-0.5,0.6-0.9,1-1.1c0.4-0.2,0.7-0.4,1-0.4c1.1-0.1,1.9,0.6,2.1,2c0.5-0.8,1.1-1.4,1.8-1.9c0.7-0.5,1.5-0.7,2.3-0.8
									c2.5-0.2,3.9,1.4,4.2,4.7l0.1,2.3c0,0.9,0,1.7,0.1,2.5c0.2,2.5,0.6,4.3,1.1,5.4c-0.6,0.2-1.2,0.3-1.7,0.4c-0.6,0-1.2,0-2-0.1
									c-0.1-3-0.3-6.2-0.5-9.6c-0.1-1-0.3-1.7-0.7-2.1c-0.4-0.4-1-0.6-1.8-0.5c-1.2,0.1-2.1,0.4-2.9,1.1l0.1,1.6c0,1.2,0.1,2.7,0.2,4.4
									c0.2,2.6,0.6,4.4,1.1,5.4c-0.6,0.2-1.2,0.3-1.8,0.4C1214.3,1732.4,1213.7,1732.4,1212.9,1732.3z"
						/>
						<path
							d="M1229.1,1730.5c-0.6-0.6-0.9-1.4-1-2.4c-0.2-2.9,2.2-4.8,7.2-5.6c-0.1-1.6-0.4-2.7-0.9-3.3s-1.2-0.9-2.3-0.8
									c-0.7,0.1-1.3,0.2-2,0.5c-0.7,0.3-1.2,0.7-1.8,1.1l-0.3-0.7c0.5-1.1,1.2-2,2.1-2.8c0.9-0.7,1.9-1.2,3-1.2c1.4-0.1,2.5,0.2,3.3,1
									s1.3,2,1.4,3.5c0.1,0.9,0.1,1.9,0.1,3c0,1.2,0,2,0.1,2.6c0.1,0.9,0.2,1.6,0.4,2.1c0.2,0.5,0.6,1,1.1,1.6c-0.7,0.8-1.6,1.4-2.5,1.6
									c-0.6-0.4-1.1-1-1.4-1.9c-0.7,0.7-1.5,1.2-2.3,1.7c-0.8,0.4-1.6,0.7-2.3,0.7C1230.3,1731.4,1229.6,1731.1,1229.1,1730.5z
									M1233.9,1728.8c0.6-0.3,1.1-0.7,1.5-1.2l0-0.2c-0.1-0.8-0.1-1.5-0.1-2.3l0-1.6c-1.5,0.2-2.6,0.6-3.3,1.3c-0.7,0.7-1.1,1.5-1,2.5
									c0,0.4,0.2,0.8,0.4,1.2s0.5,0.7,0.8,0.9C1232.8,1729.4,1233.3,1729.2,1233.9,1728.8z"
						/>
						<path
							d="M1244.8,1729.5c-0.3-0.4-0.6-1.1-0.7-2.2c-0.1-0.8-0.2-2-0.3-3.7c-0.1-1.9-0.2-3.4-0.3-4.6c-0.1-0.6-0.1-1.1-0.2-1.7
									c-0.9,0.1-1.4,0.1-1.7,0c-0.3-0.1-0.5-0.3-0.5-0.7c1.3-1,2.5-2.4,3.6-4l0.8,0c0.2,0.8,0.3,1.7,0.4,2.5c1.4-0.2,2.5-0.3,3.1-0.6
									c0,0.5,0,1,0,1.4c-0.1,0.4-0.2,0.8-0.3,1.2c-0.6-0.2-1.6-0.3-2.7-0.2c0,1.1,0.1,2.2,0.2,3.4c0.3,3,0.6,5.3,1.1,6.8
									c1-0.2,1.8-0.4,2.3-0.6l0.3,0.8c-0.3,0.7-0.8,1.3-1.5,1.8c-0.7,0.5-1.3,0.7-2,0.8C1245.7,1730.1,1245.2,1729.9,1244.8,1729.5z"
						/>
						<path
							d="M1254.2,1729.1c-0.3-3.1-0.6-6.2-1-9.3c-0.6-4.2-1.1-7.5-1.7-9.8c1-0.6,2.1-1,3.4-1.2c0,1.2,0.2,3.5,0.5,7.1
									c0.5-0.8,1.1-1.5,1.8-2c0.7-0.5,1.5-0.8,2.3-1c2.5-0.3,4,1.2,4.4,4.5l0.2,2.3l0.3,2.4c0.3,2.5,0.8,4.3,1.4,5.4
									c-0.6,0.2-1.1,0.4-1.7,0.4c-0.5,0.1-1.2,0.1-1.9,0c-0.3-3.5-0.7-6.6-1.1-9.5c-0.1-1-0.4-1.6-0.8-2s-1-0.5-1.8-0.4
									c-1.1,0.2-2.1,0.6-2.9,1.4c0.1,0.7,0.2,1.7,0.3,3c0.6,4.5,1.2,7.3,1.8,8.2c-0.6,0.2-1.1,0.4-1.7,0.5
									C1255.6,1729.1,1254.9,1729.1,1254.2,1729.1z"
						/>
						<path
							d="M1281.2,1722.2c-1.7-1.3-2.9-3.4-3.6-6.4c-0.5-2.1-0.6-4-0.3-5.8c0.3-1.8,1-3.3,2.1-4.5c1.1-1.2,2.5-2,4.1-2.5
									c2-0.5,3.7-0.6,5.3-0.4c0.1,0.5,0.2,1.1,0.2,1.9c0,0.7-0.1,1.3-0.2,1.8c-1.1-0.5-2.3-0.8-3.5-1c-1.2-0.2-2.1-0.2-2.8-0.1
									c-0.7,0.4-1.2,1-1.6,2c-0.4,0.9-0.7,2-0.8,3.3s0,2.4,0.3,3.6c0.7,2.8,1.6,4.6,2.8,5.6s2.8,1.2,4.8,0.7c0.9-0.2,1.7-0.5,2.3-1
									c-1-4-1.8-6.4-2.3-7.2c0.5-0.3,1-0.5,1.6-0.6c0.5-0.1,1.2-0.2,1.9-0.2c0.4,2.5,1,5.1,1.6,7.8c-0.5,0.9-1.3,1.8-2.4,2.5
									c-1.1,0.8-2.2,1.3-3.4,1.6C1284.9,1723.8,1282.9,1723.5,1281.2,1722.2z"
						/>
						<path
							d="M1298.3,1718.4c-1.2-1-2.2-2.6-2.8-4.9c-0.4-1.6-0.5-3-0.3-4.4c0.2-1.4,0.7-2.5,1.5-3.5c0.8-1,1.8-1.6,3-1.9
									c1.7-0.5,3.1-0.2,4.3,0.8c1.2,1,2.1,2.7,2.8,5.1c-1,0.6-2.3,1.2-3.8,1.9s-3,1.2-4.5,1.7c0.5,1.4,1.2,2.3,1.9,2.8
									c0.8,0.5,1.7,0.6,2.8,0.3c0.9-0.2,1.6-0.6,2.3-1c0.7-0.4,1.3-0.9,1.7-1.5l0.4,0.6c-0.4,1.1-1,2.1-1.9,3c-0.9,0.9-1.9,1.5-3,1.8
									C1301,1719.6,1299.5,1719.4,1298.3,1718.4z M1301,1710.6c0.9-0.5,1.6-1.1,2.1-1.6c-0.4-1.2-1-2.2-1.8-2.8
									c-0.8-0.6-1.7-0.8-2.7-0.5c-0.5,0.6-0.8,1.5-0.9,2.6c-0.1,1.1,0,2.4,0.4,3.7C1299.1,1711.7,1300.1,1711.2,1301,1710.6z"
						/>
						<path
							d="M1313.7,1715.3c-0.4-0.3-0.7-1.1-1.1-2.1c-0.2-0.7-0.5-1.9-1-3.5c-0.5-1.8-0.9-3.3-1.3-4.4c-1.1-3.8-2.1-6.4-3-7.9
									c0.5-0.4,1-0.8,1.5-1c0.5-0.3,1.1-0.5,1.7-0.7c0.6,3,1.8,7.2,3.4,12.6c0.4,1.3,0.7,2.2,1,2.9c0.3,0.7,0.6,1.2,0.8,1.5
									c0.3,0.3,0.7,0.7,1.1,1c-0.2,0.5-0.5,0.9-0.8,1.3c-0.3,0.3-0.6,0.5-1,0.6C1314.5,1715.7,1314,1715.6,1313.7,1715.3z"
						/>
						<path
							d="M1314.8,1694.5c0.3-1.1,0.7-2,1.2-2.8c0.8,0.6,1.7,1.1,2.6,1.6c-0.3,1-0.7,1.9-1.2,2.8
									C1316.5,1695.4,1315.7,1694.9,1314.8,1694.5z M1321.7,1712.8c-0.4-0.3-0.7-1-1.1-2.1c-0.3-0.9-0.6-2.1-1.1-3.7
									c-0.4-1.5-0.8-2.6-1-3.3c-0.3-1-0.6-1.8-1-2.6c-0.4-0.8-0.7-1.3-1-1.6c0.5-0.4,1-0.8,1.5-1.1c0.5-0.3,1.1-0.5,1.7-0.7
									c0.1,0.7,0.4,1.8,0.7,3.2c0.4,1.4,0.8,3,1.4,4.8c0.4,1.3,0.8,2.2,1,2.9c0.3,0.7,0.6,1.2,0.9,1.5c0.3,0.3,0.7,0.7,1.1,1
									c-0.2,0.5-0.5,1-0.8,1.3c-0.3,0.3-0.6,0.5-0.9,0.6C1322.6,1713.2,1322.1,1713.1,1321.7,1712.8z"
						/>
						<path
							d="M1330.1,1710.4c-0.7-2.4-1.4-4.8-2.3-7.4c-0.4-1.3-0.8-2.2-1.1-2.9c-0.3-0.6-0.6-1.1-0.9-1.5c-0.3-0.3-0.7-0.7-1.2-1
									c0.2-0.6,0.4-1,0.7-1.3c0.3-0.3,0.6-0.5,0.9-0.6c1.1-0.4,1.9,0.1,2.6,1.4c0.3-0.9,0.7-1.6,1.3-2.3c0.6-0.6,1.3-1.1,2-1.4
									c2.4-0.8,4.1,0.4,5.2,3.5l0.7,2.2c0.2,0.9,0.5,1.7,0.7,2.4c0.8,2.4,1.6,4,2.4,5c-0.5,0.3-1,0.6-1.5,0.8c-0.5,0.2-1.2,0.3-1.9,0.4
									c-0.8-2.8-1.8-5.9-2.9-9.1c-0.3-0.9-0.7-1.5-1.2-1.8c-0.5-0.3-1.1-0.3-1.8,0c-1.1,0.4-2,1-2.5,1.8l0.5,1.5
									c0.3,1.2,0.8,2.6,1.4,4.2c0.8,2.4,1.6,4.1,2.4,5c-0.6,0.4-1.1,0.6-1.7,0.8C1331.5,1710.1,1330.9,1710.3,1330.1,1710.4z"
						/>
					</g>
					<g className="level_two fade location " id="ciril">
						<rect x="325.428" y="2327.811" width="21.131" height="51.213" transform="matrix(-0.974005, -0.226526, 0.238262, -0.971276, 1407.304688, 4081.155029)"></rect>
						<path
							d="M1644.5,1740.8c-1.1,1.1-2.8,1.7-5.1,1.9c-1.6,0.1-3-0.1-4.3-0.5s-2.3-1.2-3-2.2c-0.8-1-1.2-2.1-1.3-3.5
									c-0.1-1.5,0-2.7,0.4-3.8c0.4,0,0.9,0,1.4,0.1c0.5,0.1,0.9,0.2,1.3,0.4c-0.5,0.8-0.9,1.6-1.2,2.4c-0.3,0.8-0.4,1.5-0.4,2
									c0.2,0.6,0.6,1.1,1.2,1.5s1.4,0.8,2.3,1c0.9,0.2,1.8,0.3,2.7,0.2c1.9-0.1,3.3-0.6,4.1-1.5c0.9-0.8,1.3-1.9,1.2-3.1
									c-0.1-1.7-0.6-3.1-1.3-4.1l0.7-0.2c0.8,0.5,1.4,1.2,2,2.1c0.5,0.9,0.8,1.8,0.9,2.8C1646.1,1738.2,1645.6,1739.7,1644.5,1740.8z"
						/>
						<path
							d="M1630.9,1727.6c-0.6-0.6-1.2-1.1-1.6-1.7c0.7-0.4,1.3-0.9,1.8-1.5c0.6,0.5,1.1,1.1,1.6,1.7
									C1632.1,1726.6,1631.5,1727.1,1630.9,1727.6z M1645.7,1727.9c-0.3,0.2-0.9,0.2-1.8,0.2c-0.7,0-1.7-0.1-2.9-0.3
									c-1.2-0.1-2-0.2-2.6-0.2c-0.8-0.1-1.5-0.1-2.1,0c-0.6,0-1.1,0.1-1.4,0.3c-0.2-0.5-0.3-1-0.3-1.4s-0.1-0.9,0-1.5
									c0.5,0.1,1.4,0.3,2.5,0.4c1.1,0.1,2.4,0.3,3.7,0.4c1,0.1,1.8,0.1,2.3,0.1c0.5,0,1-0.1,1.3-0.2c0.3-0.1,0.7-0.3,1-0.6
									c0.3,0.3,0.5,0.6,0.7,1c0.1,0.3,0.2,0.6,0.2,0.9C1646.2,1727.4,1646.1,1727.7,1645.7,1727.9z"
						/>
						<path
							d="M1646.9,1721c-1.8-0.5-3.7-1-5.7-1.4c-1-0.2-1.8-0.3-2.3-0.4s-1,0-1.3,0c-0.3,0.1-0.7,0.2-1.1,0.4c-0.3-0.3-0.5-0.7-0.5-1
									s-0.1-0.7-0.1-0.9c0.2-1,1-1.3,2.3-1.1c-0.5-0.6-0.9-1.1-1.1-1.7c-0.3-0.6-0.4-1.2-0.5-1.9c0.3-0.1,0.8-0.1,1.3-0.1
									s1.1,0.1,1.5,0.2c-0.1,0.2-0.2,0.5-0.2,0.8c-0.1,0.5-0.2,1.1-0.1,1.6s0.1,0.9,0.3,1.3l0.8,0.2c1.2,0.3,2.2,0.5,3.2,0.7
									c1.9,0.4,3.3,0.5,4.2,0.4c0,0.5,0,1-0.1,1.4S1647.1,1720.5,1646.9,1721z"
						/>
						<path
							d="M1634.3,1707.8c-0.5-0.7-0.8-1.4-1.1-2.1c0.8-0.2,1.5-0.5,2.2-0.9c0.4,0.7,0.8,1.4,1.1,2.1
									C1635.7,1707.1,1635,1707.4,1634.3,1707.8z M1648.5,1712.1c-0.4,0.1-1,0-1.8-0.3c-0.6-0.2-1.5-0.6-2.7-1.1
									c-1.1-0.4-1.9-0.8-2.4-0.9c-0.7-0.3-1.4-0.5-2-0.6c-0.6-0.1-1.1-0.2-1.4-0.1c0-0.5,0-1,0.1-1.4c0.1-0.4,0.2-0.9,0.4-1.4
									c0.5,0.3,1.2,0.6,2.3,1.1c1,0.4,2.2,0.9,3.5,1.4c1,0.4,1.7,0.6,2.2,0.7c0.5,0.1,1,0.2,1.3,0.2s0.7-0.1,1.1-0.3
									c0.2,0.4,0.3,0.7,0.4,1.1c0,0.4,0,0.7-0.1,0.9C1649.2,1711.7,1648.9,1712,1648.5,1712.1z"
						/>
						<path
							d="M1651,1705.7c-0.4,0-1-0.2-1.7-0.6c-0.5-0.3-1.3-0.7-2.4-1.3c-1.2-0.7-2.2-1.3-3-1.7c-2.6-1.4-4.6-2.3-5.9-2.6
									c0-0.5,0.1-1,0.3-1.4c0.1-0.4,0.3-0.9,0.6-1.3c1.9,1.3,4.8,2.9,8.5,4.9c0.9,0.5,1.6,0.8,2.1,1s0.9,0.3,1.3,0.3
									c0.3,0,0.7,0,1.2-0.1c0.2,0.4,0.2,0.8,0.2,1.1s-0.1,0.7-0.2,0.9C1651.7,1705.4,1651.3,1705.6,1651,1705.7z"
						/>
					</g>
					<g className="level_two fade location " id="crossings_of_poros">
						<rect x="1783.363" y="1233.156" width="115.799" height="27.129" transform="matrix(-0.63806, -0.769987, 0.777675, -0.628782, 2337.893066, 4194.820313)"></rect>
						<rect x="1665.077" y="1233.156" width="108.118" height="27.129" transform="matrix(-0.63806, -0.769987, 0.777675, -0.628782, 2250.423584, 4132.397949)"></rect>
						<path
							d="M2095.2,1956.9c0.2-1.9,1.3-3.7,3.3-5.4c1.4-1.2,2.9-1.9,4.5-2.3c1.6-0.3,3.1-0.2,4.6,0.3c1.5,0.5,2.8,1.5,3.9,2.8
									c1.2,1.5,2.1,2.9,2.5,4.2c-0.4,0.3-0.8,0.6-1.4,0.8c-0.6,0.2-1.1,0.4-1.5,0.4c-0.1-1.1-0.4-2.2-0.7-3.3c-0.4-1.1-0.7-1.9-1.1-2.4
									c-0.6-0.5-1.4-0.7-2.3-0.8c-0.9,0-1.9,0.1-2.9,0.5c-1,0.3-1.9,0.9-2.7,1.5c-1.7,1.4-2.6,2.8-2.8,4.3c-0.2,1.5,0.3,2.8,1.3,4.1
									c1.4,1.7,2.9,2.8,4.4,3.4l-0.5,0.7c-1.1,0-2.3-0.3-3.5-0.9c-1.2-0.6-2.2-1.4-3-2.3C2095.6,1960.7,2094.9,1958.8,2095.2,1956.9z"
						/>
						<path
							d="M2103.3,1969.3c1.7-1.3,3.5-2.7,5.4-4.2c0.9-0.8,1.6-1.4,2-1.8c0.4-0.5,0.7-0.9,0.9-1.2c0.2-0.4,0.3-0.8,0.3-1.4
									c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.7,0.4,1,0.7c0.8,1,0.6,2-0.5,3.1c0.9,0,1.7,0.1,2.5,0.3c0.8,0.2,1.5,0.6,2.2,1.1
									c-0.2,0.3-0.5,0.8-0.9,1.3s-0.8,0.9-1.2,1.3c-0.1-0.2-0.4-0.5-0.7-0.9c-0.5-0.6-1-1-1.5-1.4s-1-0.7-1.5-0.8l-0.8,0.6
									c-1.1,0.9-2.1,1.7-2.9,2.4c-1.7,1.4-2.8,2.7-3.3,3.6c-0.5-0.4-1-0.9-1.3-1.3S2103.6,1969.9,2103.3,1969.3z"
						/>
						<path
							d="M2110.6,1975.8c0.2-1.6,1.1-3.1,2.5-4.3c1.1-0.9,2.3-1.5,3.5-1.7c1.2-0.2,2.5-0.1,3.6,0.3s2.2,1.2,3.1,2.3
									c0.8,1,1.3,2,1.5,3.1c0.2,1.1,0.1,2.2-0.3,3.2c-0.4,1-1.1,2-2.1,2.8c-1.1,0.9-2.3,1.5-3.5,1.7c-1.3,0.2-2.5,0.1-3.7-0.4
									c-1.2-0.5-2.3-1.3-3.2-2.4C2110.9,1979,2110.4,1977.4,2110.6,1975.8z M2116.4,1981.5c1.1,0,2.2-0.5,3.4-1.4c1-0.9,1.7-1.8,2-3
									s0.2-2.2-0.2-3.3c-0.8-1-1.8-1.5-3-1.6c-1.1,0-2.3,0.4-3.4,1.3c-1.3,1.1-2.1,2.2-2.3,3.4c-0.3,1.2-0.1,2.2,0.7,3.1
									C2114.3,1980.9,2115.3,1981.4,2116.4,1981.5z"
						/>
						<path
							d="M2118.5,1986.2c0.3-0.3,0.6-0.5,1-0.6c0.3-0.2,0.7-0.3,1.2-0.4c-0.1,0.7-0.1,1.4,0.1,2.1c0.2,0.7,0.6,1.4,1.1,2
									c1.4,1.6,2.5,2.1,3.4,1.4c0.4-0.3,0.5-0.7,0.4-1.3c-0.1-0.6-0.3-1.4-0.6-2.4c-0.3-1-0.5-1.9-0.7-2.6c-0.1-0.7-0.1-1.4,0-2.1
									c0.2-0.7,0.6-1.3,1.2-1.9c0.8-0.6,1.7-0.8,2.8-0.5c1.1,0.3,2.1,1,3.1,2.2c1.3,1.5,2.2,3,2.7,4.4c-0.3,0.2-0.7,0.4-1.2,0.6
									c-0.6,0.2-1.1,0.3-1.6,0.4c0-1.1,0-2-0.2-2.9c-0.2-0.9-0.4-1.5-0.7-1.9c-0.4-0.3-0.9-0.6-1.3-0.6c-0.4-0.1-0.8,0-1.1,0.2
									c-0.5,0.4-0.7,0.9-0.6,1.5s0.2,1.5,0.6,2.7c0.3,1,0.5,1.8,0.6,2.4s0.1,1.3,0,2c-0.2,0.7-0.5,1.3-1.1,1.7c-0.9,0.8-2,1-3.1,0.7
									c-1.2-0.3-2.3-1.2-3.5-2.6C2119.5,1989.3,2118.7,1987.7,2118.5,1986.2z"
						/>
						<path
							d="M2125.9,1995.1c0.3-0.3,0.6-0.5,1-0.6c0.3-0.2,0.7-0.3,1.2-0.4c-0.1,0.7-0.1,1.4,0.1,2.1c0.2,0.7,0.6,1.4,1.1,2
									c1.4,1.6,2.5,2.1,3.4,1.4c0.4-0.3,0.5-0.7,0.5-1.3c-0.1-0.6-0.3-1.4-0.6-2.4c-0.3-1-0.5-1.9-0.7-2.6c-0.1-0.7-0.1-1.4,0-2.1
									c0.2-0.7,0.6-1.3,1.2-1.9c0.8-0.6,1.7-0.8,2.8-0.5c1.1,0.3,2.1,1,3.1,2.2c1.3,1.5,2.2,3,2.7,4.4c-0.3,0.2-0.7,0.4-1.2,0.6
									c-0.6,0.2-1.1,0.3-1.6,0.4c0-1.1,0-2-0.2-2.9c-0.2-0.9-0.4-1.5-0.8-1.9c-0.4-0.3-0.9-0.6-1.3-0.6c-0.4-0.1-0.8,0-1.1,0.2
									c-0.5,0.4-0.7,0.9-0.6,1.5s0.2,1.5,0.6,2.7c0.3,1,0.5,1.8,0.6,2.4c0.1,0.7,0.1,1.3,0,2c-0.2,0.7-0.5,1.3-1.1,1.7
									c-0.9,0.8-2,1-3.1,0.6c-1.2-0.3-2.3-1.2-3.5-2.6C2126.9,1998.1,2126.1,1996.6,2125.9,1995.1z"
						/>
						<path
							d="M2134.1,2005.7c0.1-0.5,0.6-1,1.3-1.6c0.6-0.5,1.5-1.2,2.6-2.1c1.1-0.8,1.9-1.5,2.4-1.9c0.7-0.6,1.3-1.1,1.8-1.7
									c0.5-0.6,0.8-1,0.9-1.4c0.6,0.3,1.1,0.7,1.5,1.1c0.4,0.4,0.9,0.8,1.4,1.4c-0.5,0.3-1.3,0.9-2.4,1.6c-1,0.8-2.2,1.7-3.4,2.7
									c-0.9,0.8-1.6,1.4-2,1.8c-0.4,0.5-0.7,0.9-0.9,1.2c-0.2,0.4-0.3,0.8-0.3,1.4c-0.5,0-1-0.1-1.4-0.3c-0.4-0.2-0.7-0.4-1-0.7
									C2134.1,2006.6,2134,2006.1,2134.1,2005.7z M2146.6,1993.8c1,0,2,0.1,2.8,0.3c-0.2,0.9-0.2,1.9-0.2,2.9c-1,0-1.9-0.1-2.9-0.3
									C2146.5,1995.8,2146.6,1994.8,2146.6,1993.8z"
						/>
						<path
							d="M2138.3,2011.1c1.7-1.3,3.5-2.7,5.4-4.2c0.9-0.8,1.6-1.4,2-1.8c0.4-0.5,0.7-0.9,0.9-1.2c0.2-0.4,0.3-0.8,0.3-1.4
									c0.5,0,1,0.1,1.4,0.3c0.4,0.2,0.7,0.4,0.9,0.7c0.8,0.9,0.7,1.8-0.2,2.8c0.9,0,1.7,0.2,2.5,0.6c0.8,0.4,1.5,0.9,2,1.5
									c1.7,2,1.4,4-0.9,5.9l-1.6,1.2c-0.7,0.5-1.2,0.9-1.7,1.3c-1.7,1.4-2.8,2.6-3.2,3.6c-0.5-0.4-0.9-0.8-1.3-1.2
									c-0.4-0.4-0.8-1-1.1-1.7c2.1-1.5,4.3-3.3,6.6-5.2c0.7-0.6,1-1.1,1.1-1.6c0.1-0.5-0.2-1.1-0.7-1.7c-0.8-0.9-1.6-1.6-2.5-1.9
									l-1.1,0.9c-0.9,0.7-1.9,1.5-3.1,2.4c-1.7,1.4-2.8,2.7-3.3,3.6c-0.5-0.4-1-0.9-1.4-1.3C2139,2012.3,2138.7,2011.8,2138.3,2011.1z"
						/>
						<path
							d="M2143.9,2024.7c0.6-0.5,1.3-0.9,2-1.1s1.6-0.3,2.4-0.3c-0.3-0.9-0.2-1.6,0.5-2.1c0.5-0.4,1-0.6,1.7-0.5
									c0.7,0,1.4,0.3,2.1,0.7c-0.2-0.8-0.2-1.6,0.1-2.4c0.3-0.8,0.7-1.5,1.5-2.1c1.2-1,2.4-1.3,3.9-1.1s2.7,1,3.8,2.3
									c0.7,0.9,1.2,1.8,1.4,2.8c0.4,0.3,0.9,0.5,1.5,0.8c0.6,0.2,1.3,0.4,2,0.5c-0.1,0.4-0.3,0.9-0.6,1.3c-0.3,0.5-0.5,0.8-0.8,1
									c-0.7-0.3-1.5-0.9-2.2-1.6c-0.3,0.9-0.8,1.6-1.5,2.1c-0.8,0.6-1.6,1-2.5,1.1c-0.9,0.1-1.8-0.1-2.8-0.5c-0.9-0.4-1.8-1.1-2.5-2
									c-0.6-0.7-1.2-1.2-1.8-1.5c-0.6-0.2-1.1-0.2-1.4,0.1l3.8,5.1c0.7,1,1,1.9,1.1,2.7c0,0.8-0.3,1.5-1.1,2.1c-0.7,0.6-1.4,0.8-2.4,0.8
									c-0.9,0-1.9-0.3-2.9-0.9c-1-0.6-1.9-1.4-2.8-2.4C2144.9,2028.1,2144.1,2026.4,2143.9,2024.7z M2150.8,2031c0.7,0.3,1.3,0.3,1.7,0
									c0.3-0.3,0.5-0.6,0.4-1c0-0.4-0.3-0.9-0.8-1.5l-3-4c-0.7,0.1-1.3,0.3-1.8,0.6c-0.3,1.4,0.1,2.7,1.2,4
									C2149.3,2030,2150,2030.7,2150.8,2031z M2157.1,2024.5c0.7,0.1,1.4-0.1,2-0.7c0.6-0.5,0.9-1.1,1.1-1.8c0.1-0.7,0-1.5-0.4-2.2
									c-0.6-0.7-1.3-1.1-2.1-1.2c-0.8-0.1-1.6,0.1-2.2,0.7c-0.6,0.5-1,1.2-1,1.9c0,0.7,0.2,1.4,0.7,2.1
									C2155.8,2023.9,2156.4,2024.4,2157.1,2024.5z"
						/>
						<path
							d="M2156.5,2031.6c0.3-0.3,0.6-0.5,1-0.6c0.3-0.2,0.7-0.3,1.2-0.4c-0.1,0.7-0.1,1.4,0.1,2.1c0.2,0.7,0.6,1.4,1.1,2
									c1.4,1.6,2.5,2.1,3.4,1.4c0.4-0.3,0.5-0.7,0.4-1.3s-0.3-1.4-0.6-2.4c-0.3-1-0.5-1.9-0.7-2.6c-0.1-0.7-0.1-1.4,0-2.1
									c0.2-0.7,0.6-1.3,1.2-1.9c0.8-0.6,1.7-0.8,2.8-0.5c1.1,0.3,2.1,1,3.1,2.2c1.3,1.5,2.2,3,2.7,4.4c-0.3,0.2-0.7,0.4-1.2,0.6
									s-1.1,0.3-1.6,0.4c0-1.1,0-2-0.2-2.9c-0.2-0.9-0.4-1.5-0.7-1.9c-0.4-0.3-0.9-0.6-1.3-0.6c-0.4-0.1-0.8,0-1.1,0.2
									c-0.5,0.4-0.7,0.9-0.6,1.5s0.2,1.5,0.6,2.7c0.3,1,0.5,1.8,0.6,2.4c0.1,0.7,0.1,1.3,0,2c-0.2,0.7-0.5,1.3-1.1,1.7
									c-0.9,0.8-2,1-3.1,0.7c-1.2-0.3-2.3-1.2-3.5-2.6C2157.5,2034.6,2156.7,2033.1,2156.5,2031.6z"
						/>
						<path
							d="M2089.7,1992.8c0.3-1.5,1.2-2.9,2.7-4.2c1.1-0.9,2.2-1.5,3.4-1.8c1.2-0.3,2.3-0.3,3.5,0.1c1.1,0.4,2.1,1,2.9,2
									c0.7,0.9,1.1,1.8,1.3,2.8c0.1,1,0,2-0.5,3c-0.5,1-1.2,1.9-2.2,2.7c-1.1,0.9-2.2,1.5-3.4,1.8c-1.2,0.3-2.4,0.2-3.5-0.2
									c-1.1-0.4-2.1-1.1-2.9-2.1C2089.8,1995.8,2089.4,1994.4,2089.7,1992.8z M2094.8,1997.8c1,0,2.1-0.5,3.3-1.5c1-0.9,1.7-1.8,2.1-2.9
									c0.4-1.1,0.4-2.1,0-3.1c-0.7-0.9-1.7-1.3-2.7-1.3c-1.1,0-2.2,0.5-3.3,1.4c-1.3,1.1-2.1,2.2-2.4,3.3c-0.3,1.1-0.2,2.1,0.5,2.8
									C2092.9,1997.4,2093.8,1997.8,2094.8,1997.8z"
						/>
						<path
							d="M2093.2,2006.8c0.7,0,1.3-0.1,1.8-0.3c0.5-0.1,1.1-0.4,1.8-0.9c0.7-0.5,1.6-1.2,2.8-2.1c1.3-1.1,3.5-3,6.6-5.8
									c-0.4-0.5-0.7-0.9-0.7-1.2s0-0.5,0.2-0.7c0.9,0.3,1.5,0.5,1.9,0.6c1.5-1,3-1.4,4.4-1.4s2.6,0.6,3.4,1.6c0.6,0.7,1.1,1.5,1.5,2.4
									c-0.2,0.2-0.7,0.4-1.2,0.7c-0.6,0.2-1.1,0.4-1.6,0.5c0.1-0.8,0.1-1.6-0.1-2.3c-0.1-0.7-0.3-1.3-0.6-1.5c-0.4-0.1-0.9,0-1.6,0.3
									c-0.6,0.3-1.5,1-2.7,1.9c1,1.1,1.7,1.9,2.2,2.2c-0.3,0.3-0.7,0.5-1,0.7c-0.3,0.2-0.7,0.3-1.1,0.4c-0.2-0.7-0.7-1.4-1.4-2.3
									l-1.7,1.4c-1,0.8-2.1,1.8-3.4,3c-0.7,0.6-1.2,1.1-1.7,1.6c-0.8,0.7-1.6,1.2-2.3,1.5c-0.7,0.3-1.5,0.5-2.3,0.5
									c-0.9,0-2-0.1-3.4-0.3L2093.2,2006.8z"
						/>
						<path
							d="M2108.9,2018.1c0.9-0.6,2.1-1.5,3.5-2.6c1.5-1.2,2.8-2.3,4.1-3.3c2.7-2.3,4.3-3.8,4.7-4.6c0.6,0.3,1.3,0.8,2,1.5
									s1.5,1.4,2.1,2.2c1.3,1.5,1.9,3,1.8,4.5s-0.8,2.8-2.2,3.9c-0.9,0.8-2,1.3-3,1.5c-1.1,0.2-2.1,0.1-3.2-0.3s-2-1.1-2.8-2.1
									c-0.5-0.6-0.9-1.2-1.2-1.8c-2.1,1.8-3.4,3.1-3.8,3.8c-0.4-0.3-0.9-0.7-1.2-1.2C2109.5,2019.1,2109.2,2018.6,2108.9,2018.1z
									M2120.5,2018.7c0.9-0.1,1.8-0.5,2.5-1.2c2-1.6,2.1-3.5,0.4-5.5c-0.4-0.4-0.8-0.8-1.2-1.1c-1.9,1.4-3.8,2.9-5.7,4.5
									c0.6,1.4,1.1,2.4,1.5,3C2118.7,2018.8,2119.6,2018.9,2120.5,2018.7z"
						/>
						<path
							d="M2119.4,2028.4c0.3-1.5,1.2-2.9,2.7-4.2c1.1-0.9,2.2-1.5,3.4-1.8c1.2-0.3,2.3-0.3,3.5,0.1c1.1,0.4,2.1,1,2.9,2
									c0.7,0.9,1.1,1.8,1.3,2.8c0.1,1,0,2-0.5,3c-0.5,1-1.2,1.9-2.2,2.7c-1.1,0.9-2.2,1.5-3.4,1.8c-1.2,0.3-2.4,0.2-3.5-0.2
									c-1.1-0.4-2.1-1.1-2.9-2.1C2119.5,2031.3,2119.1,2029.9,2119.4,2028.4z M2124.6,2033.4c1,0,2.1-0.5,3.3-1.5c1-0.9,1.7-1.8,2.1-2.9
									c0.4-1.1,0.4-2.1,0-3.1c-0.7-0.9-1.7-1.3-2.7-1.3c-1.1,0-2.2,0.5-3.3,1.4c-1.3,1.1-2.1,2.2-2.4,3.3c-0.3,1.1-0.2,2.1,0.5,2.8
									C2122.7,2033,2123.6,2033.4,2124.6,2033.4z"
						/>
						<path
							d="M2127.5,2040.3c1.7-1.3,3.5-2.7,5.4-4.2c0.9-0.8,1.6-1.4,2-1.8c0.4-0.5,0.7-0.9,0.9-1.2c0.2-0.3,0.3-0.8,0.4-1.3
									c0.5,0,0.9,0.1,1.3,0.2s0.7,0.4,0.9,0.6c0.7,0.9,0.5,1.8-0.7,2.9c0.9,0,1.6,0,2.4,0.2c0.7,0.2,1.4,0.5,2,0.9
									c-0.2,0.3-0.5,0.7-0.9,1.2c-0.4,0.5-0.8,0.9-1.3,1.3c-0.1-0.2-0.3-0.5-0.6-0.8c-0.4-0.5-0.9-0.9-1.4-1.2c-0.5-0.3-0.9-0.6-1.4-0.7
									l-0.8,0.6c-1.1,0.9-2.1,1.7-2.9,2.4c-1.7,1.4-2.8,2.6-3.3,3.6c-0.5-0.4-0.9-0.8-1.2-1.1
									C2128.2,2041.4,2127.8,2040.9,2127.5,2040.3z"
						/>
						<path
							d="M2134.8,2046.7c0.3-1.5,1.2-2.9,2.7-4.2c1.1-0.9,2.2-1.5,3.4-1.8c1.2-0.3,2.3-0.3,3.5,0.1c1.1,0.4,2.1,1,2.9,2
									c0.7,0.9,1.1,1.8,1.3,2.8c0.1,1,0,2-0.5,3c-0.5,1-1.2,1.9-2.2,2.7c-1.1,0.9-2.2,1.5-3.4,1.8c-1.2,0.3-2.4,0.2-3.5-0.2
									c-1.1-0.4-2.1-1.1-2.9-2.1C2134.9,2049.7,2134.5,2048.3,2134.8,2046.7z M2140,2051.7c1,0,2.1-0.5,3.3-1.5c1-0.9,1.7-1.8,2.1-2.9
									c0.4-1.1,0.4-2.1,0-3.1c-0.7-0.9-1.7-1.3-2.7-1.3c-1.1,0-2.2,0.5-3.3,1.4c-1.3,1.1-2.1,2.2-2.4,3.3c-0.3,1.1-0.2,2.1,0.5,2.8
									C2138,2051.3,2138.9,2051.8,2140,2051.7z"
						/>
						<path
							d="M2142.3,2056.8c0.3-0.3,0.6-0.5,1-0.7c0.3-0.2,0.7-0.3,1.2-0.4c-0.2,0.6-0.2,1.3,0,2s0.5,1.3,0.9,1.8
									c1.2,1.5,2.3,1.8,3.1,1.1c0.4-0.3,0.5-0.7,0.5-1.3s-0.2-1.3-0.4-2.2c-0.2-0.9-0.4-1.7-0.5-2.4c-0.1-0.6,0-1.3,0.1-2
									c0.2-0.7,0.6-1.3,1.2-1.8c0.8-0.6,1.7-0.9,2.7-0.6c1,0.2,1.9,0.9,2.8,1.9c1.1,1.4,1.9,2.7,2.3,4c-0.3,0.2-0.7,0.4-1.2,0.7
									c-0.6,0.2-1.1,0.4-1.6,0.5c0.1-1,0.1-1.9,0-2.7c-0.1-0.8-0.3-1.4-0.6-1.7c-0.4-0.3-0.8-0.5-1.2-0.5c-0.4,0-0.8,0-1,0.3
									c-0.5,0.4-0.7,0.9-0.7,1.5s0.1,1.4,0.4,2.5c0.2,0.9,0.4,1.6,0.5,2.2c0.1,0.6,0,1.2-0.2,1.9c-0.2,0.6-0.6,1.2-1.2,1.7
									c-0.9,0.8-1.9,1-3,0.8c-1.1-0.3-2.1-1-3.2-2.3C2143.1,2059.6,2142.5,2058.1,2142.3,2056.8z"
						/>
					</g>
					<g className="level_two fade location" id="andrast">
						<rect x="4714.699" y="1622.618" width="306.132" height="35.696" transform="matrix(0.920186, -0.391482, 0.3907, 0.920518, -4181.061035, 2255.776611)"></rect>
						<path
							d="M819.2,1923.4l-3.1-3.8c-1.1,0.3-2.5,0.7-4.1,1.3c-1.7,0.6-2.9,1.1-3.5,1.4c-0.1,1.5-0.1,3.1-0.1,4.9
									c-0.4,0.3-0.7,0.5-1.1,0.6c-0.5,0.2-1,0.3-1.5,0.3c0.3-1.3,0.5-4.4,0.7-9.1c0.2-4.7,0.3-8.4,0.3-11.1c0.6-0.3,1.1-0.6,1.5-0.7
									c0.4-0.1,0.9-0.3,1.5-0.3c1.6,2.1,4,4.9,7.1,8.5c3.1,3.6,5.2,5.8,6.2,6.7c-0.5,0.4-1.1,0.7-1.8,0.9
									C820.6,1923.1,819.9,1923.3,819.2,1923.4z M814.5,1917.8c-2.2-2.6-4.1-4.7-5.7-6.4c-0.2,2.1-0.3,5-0.4,8.5
									c1.2-0.3,2.6-0.8,4.1-1.3C813.5,1918.2,814.2,1918,814.5,1917.8z"
						/>
						<path
							d="M828.7,1920c-0.7-2.3-1.5-4.7-2.5-7.2c-0.5-1.2-0.9-2.1-1.2-2.7c-0.3-0.6-0.6-1.1-1-1.4c-0.3-0.3-0.7-0.6-1.3-0.8
									c0.2-0.6,0.5-1,0.8-1.4c0.3-0.3,0.7-0.6,1-0.7c1.2-0.5,2.2-0.1,2.9,1.2c0.3-0.9,0.9-1.7,1.5-2.4c0.7-0.7,1.4-1.2,2.3-1.5
									c2.7-1,4.6,0,5.8,3l0.7,2.1c0.2,0.8,0.5,1.6,0.7,2.3c0.9,2.3,1.7,3.8,2.6,4.7c-0.6,0.4-1.2,0.7-1.8,0.9c-0.6,0.2-1.3,0.4-2.2,0.6
									c-0.9-2.7-1.9-5.7-3.1-8.8c-0.3-0.9-0.8-1.5-1.3-1.7c-0.5-0.2-1.2-0.2-2.1,0.1c-1.3,0.5-2.2,1.1-2.9,2l0.5,1.4
									c0.4,1.2,0.8,2.5,1.4,4.1c0.9,2.3,1.8,3.9,2.6,4.7c-0.6,0.4-1.3,0.7-1.9,1C830.2,1919.6,829.5,1919.8,828.7,1920z"
						/>
						<path
							d="M848,1910.9c-1.4-0.8-2.6-2.3-3.6-4.6c-1-2.4-1.2-4.3-0.8-6c0.5-1.6,1.7-2.8,3.6-3.6c1.5-0.6,3.1-0.8,4.7-0.4
									c-1-2.1-1.9-3.7-2.7-4.6c0.6-0.5,1.1-0.9,1.7-1.3c0.5-0.3,1.2-0.7,1.9-1c0.9,2.8,2.6,7.5,5.3,14c0.3,0.8,0.7,1.3,1,1.7
									c0.4,0.4,0.9,0.7,1.7,1c-0.5,1-1.3,1.8-2.3,2.3c-0.8-0.2-1.5-0.6-2.1-1.3c-0.4,0.8-0.9,1.6-1.5,2.2s-1.3,1.1-2.1,1.4
									C851.1,1911.6,849.4,1911.6,848,1910.9z M854.6,1907.7c0.5-0.5,0.9-1.1,1.3-1.6c-0.3-0.7-0.7-1.8-1.4-3.5
									c-0.7-1.8-1.3-3.2-1.7-4.3c-1.1-0.2-2.3,0-3.5,0.5c-1.2,0.5-1.9,1.3-2.2,2.4c-0.3,1.1-0.1,2.4,0.5,3.9c0.6,1.5,1.4,2.6,2.4,3.2
									c1,0.7,2,0.8,3.2,0.6C853.6,1908.6,854.1,1908.2,854.6,1907.7z"
						/>
						<path
							d="M868,1904c-0.8-2.2-1.8-4.6-2.8-7c-0.5-1.2-1-2.1-1.3-2.7c-0.4-0.6-0.7-1-1-1.3c-0.3-0.3-0.8-0.5-1.3-0.8
									c0.2-0.5,0.5-1,0.8-1.4c0.3-0.4,0.7-0.7,1-0.8c1.3-0.6,2.3-0.1,3.1,1.5c0.3-1,0.7-1.8,1.1-2.5c0.5-0.7,1.1-1.4,1.8-2
									c0.3,0.3,0.7,0.7,1.1,1.3c0.4,0.6,0.7,1.2,1,1.7c-0.3,0.1-0.7,0.2-1.2,0.4c-0.7,0.3-1.4,0.7-2,1.2s-1,0.9-1.3,1.3l0.4,1
									c0.6,1.4,1.1,2.7,1.6,3.8c1,2.3,2,3.8,2.8,4.6c-0.6,0.4-1.2,0.8-1.8,1C869.5,1903.5,868.8,1903.8,868,1904z"
						/>
						<path
							d="M880.4,1897.8c-0.8-0.3-1.4-0.9-1.9-1.8c-1.2-2.6,0.7-5.3,5.8-8.1c-0.7-1.4-1.4-2.3-2-2.6c-0.7-0.4-1.6-0.3-2.7,0.3
									c-0.7,0.3-1.3,0.8-1.9,1.3s-1.1,1.1-1.5,1.8l-0.6-0.5c0.2-1.2,0.6-2.4,1.4-3.5c0.7-1.1,1.7-1.9,2.7-2.4c1.5-0.7,2.7-0.9,3.9-0.5
									c1.1,0.4,2,1.2,2.7,2.6c0.4,0.8,0.7,1.7,1.1,2.7c0.4,1.1,0.7,1.8,0.9,2.3c0.4,0.8,0.8,1.4,1.2,1.8c0.4,0.4,1,0.7,1.7,1
									c-0.5,1.1-1.2,1.9-2.1,2.5c-0.8-0.1-1.5-0.5-2.1-1.2c-0.6,0.9-1.2,1.7-1.9,2.5s-1.4,1.3-2.2,1.6
									C882,1898.1,881.2,1898.1,880.4,1897.8z M885,1894.2c0.5-0.6,0.9-1.1,1.2-1.7l-0.1-0.2c-0.3-0.7-0.6-1.4-0.8-2l-0.6-1.4
									c-1.5,0.8-2.5,1.7-3.1,2.6c-0.6,0.9-0.7,1.8-0.2,2.7c0.2,0.4,0.4,0.7,0.8,0.9s0.8,0.4,1.2,0.4C884,1895.2,884.5,1894.8,885,1894.2
									z"
						/>
						<path
							d="M896.2,1889.8c-0.2-0.4-0.4-0.8-0.5-1.2c-0.1-0.4-0.1-0.9-0.1-1.4c0.7,0.3,1.5,0.4,2.3,0.4c0.8-0.1,1.7-0.3,2.5-0.7
									c2.1-1.1,2.8-2.2,2.3-3.3c-0.2-0.5-0.7-0.7-1.3-0.8s-1.5-0.1-2.7,0c-1.2,0.1-2.2,0.1-2.9,0.1c-0.8,0-1.5-0.2-2.2-0.5
									c-0.7-0.3-1.3-0.9-1.7-1.7c-0.5-1-0.5-2,0.1-3.1c0.6-1.1,1.6-2,3.1-2.8c2-1,3.8-1.6,5.4-1.8c0.2,0.3,0.3,0.8,0.4,1.5
									c0.1,0.7,0.1,1.3,0,1.9c-1.1-0.3-2.2-0.4-3.2-0.5c-1,0-1.7,0.1-2.2,0.4c-0.5,0.4-0.8,0.8-1,1.2s-0.2,0.9,0,1.2
									c0.3,0.6,0.8,0.9,1.5,1c0.7,0.1,1.7,0.1,3,0c1.1-0.1,2-0.1,2.8-0.1c0.8,0,1.5,0.2,2.2,0.5s1.2,0.9,1.6,1.6
									c0.6,1.2,0.6,2.3-0.1,3.5c-0.6,1.2-1.9,2.2-3.7,3.1C899.7,1889.4,897.9,1889.9,896.2,1889.8z"
						/>
						<path
							d="M913.9,1881.2c-0.5-0.2-1-0.8-1.5-1.7c-0.3-0.7-0.9-1.7-1.6-3.2c-0.8-1.6-1.5-3-2-4c-0.3-0.5-0.5-1-0.8-1.4
									c-0.9,0.5-1.5,0.7-1.9,0.7c-0.4,0-0.6-0.1-0.8-0.4c1-1.5,1.8-3.3,2.4-5.3l0.8-0.3c0.5,0.7,0.9,1.4,1.3,2.1
									c1.5-0.8,2.5-1.4,3.1-1.9c0.2,0.4,0.4,0.9,0.5,1.2c0.1,0.4,0.1,0.8,0.1,1.3c-0.8,0.1-1.7,0.4-2.9,1c0.4,1,0.9,2,1.4,3
									c1.3,2.6,2.5,4.5,3.6,5.6c1-0.6,1.7-1.1,2.3-1.6l0.6,0.6c-0.1,0.8-0.4,1.6-0.9,2.3c-0.5,0.7-1.1,1.3-1.8,1.6
									C915.1,1881.3,914.4,1881.4,913.9,1881.2z"
						/>
						<path
							d="M927,1852l0.5,0.1c-0.6,2.1-0.7,4.1-0.4,6.2s1,4.2,2.2,6.6c1.2,2.4,2.4,4.3,3.8,5.8c1.4,1.5,3.1,2.7,5.1,3.5l-0.2,0.5
									c-2.4-0.7-4.5-1.7-6.4-3.2s-3.3-3.3-4.4-5.6c-1.1-2.3-1.7-4.6-1.7-7C925.5,1856.6,926,1854.3,927,1852z"
						/>
						<path
							d="M939,1869.1c-0.4-1-0.9-2.4-1.7-4.2c-0.8-1.8-1.4-3.4-2.1-4.8c-1.5-3.2-2.6-5.1-3.3-5.7c0.5-0.5,1.3-1.1,2.2-1.7
									s2-1.2,3-1.7c2-0.9,3.8-1.2,5.3-0.8c1.5,0.4,2.6,1.4,3.4,3c0.5,1,0.6,2,0.4,3c-0.2,1-0.7,2-1.5,2.9c2.9,2.2,5,3.5,6.3,3.8
									c-0.4,0.5-0.9,0.9-1.5,1.3c-0.5,0.4-1.2,0.7-1.9,1c-0.9-0.9-2.7-2.2-5.4-4.1c-0.1,0.1-0.3,0.2-0.5,0.3c-0.4,0.2-0.9,0.4-1.4,0.6
									s-0.9,0.3-1.2,0.3c1.3,2.8,2.3,4.5,3,5.3c-0.5,0.4-1,0.7-1.6,1C940.3,1868.7,939.7,1868.9,939,1869.1z M943.4,1857.2
									c0.2-0.8,0.1-1.6-0.2-2.4c-1.1-2.3-3-2.8-5.7-1.6c-0.6,0.3-1.1,0.6-1.6,1c0.6,1.5,1.4,3.5,2.5,6.1c1.6-0.2,2.9-0.4,3.7-0.8
									C942.8,1858.7,943.2,1858,943.4,1857.2z"
						/>
						<path
							d="M954.6,1861.3c-0.7-0.3-1.3-0.9-1.6-1.7c-1-2.4,0.8-4.8,5.5-7.2c-0.6-1.3-1.2-2.1-1.8-2.5c-0.6-0.3-1.4-0.3-2.5,0.2
									c-0.6,0.3-1.2,0.7-1.8,1.1s-1,1-1.4,1.6l-0.5-0.5c0.2-1.1,0.6-2.1,1.3-3.1c0.7-1,1.6-1.6,2.5-2.1c1.3-0.6,2.5-0.7,3.5-0.3
									c1,0.4,1.8,1.2,2.4,2.5c0.3,0.7,0.6,1.5,0.9,2.5c0.3,1,0.6,1.7,0.8,2.1c0.3,0.7,0.7,1.3,1,1.6c0.3,0.3,0.9,0.7,1.5,0.9
									c-0.5,1-1.2,1.7-2,2.2c-0.7-0.1-1.3-0.5-1.9-1.1c-0.5,0.8-1.1,1.5-1.8,2.2s-1.3,1.1-2,1.4C956.1,1861.6,955.4,1861.6,954.6,1861.3
									z M958.9,1858.2c0.5-0.5,0.9-1,1.2-1.5l-0.1-0.1c-0.3-0.6-0.5-1.3-0.7-1.9l-0.5-1.3c-1.4,0.7-2.3,1.4-2.9,2.3s-0.6,1.6-0.3,2.4
									c0.1,0.3,0.4,0.6,0.7,0.9s0.7,0.4,1.1,0.4C957.9,1859.1,958.4,1858.7,958.9,1858.2z"
						/>
						<path
							d="M967.4,1855.4c-0.2-0.4-0.3-0.8-0.3-1.1c-0.1-0.4-0.1-0.8,0-1.3c0.6,0.3,1.3,0.5,2,0.5c0.8,0,1.5-0.1,2.3-0.4
									c2-0.8,2.7-1.7,2.3-2.8c-0.2-0.4-0.6-0.7-1.1-0.8c-0.6-0.1-1.4-0.2-2.5-0.2c-1.1,0-2-0.1-2.6-0.2s-1.3-0.3-2-0.7
									c-0.6-0.4-1.1-0.9-1.4-1.7c-0.4-0.9-0.3-1.9,0.4-2.8c0.6-0.9,1.6-1.7,3-2.2c1.8-0.8,3.5-1.2,5-1.2c0.1,0.3,0.2,0.8,0.2,1.4
									c0,0.6,0,1.2-0.1,1.7c-1-0.3-2-0.6-2.8-0.7c-0.9-0.1-1.5,0-2,0.1c-0.5,0.3-0.8,0.7-1,1c-0.2,0.4-0.2,0.8-0.1,1.1
									c0.2,0.6,0.6,0.9,1.3,1c0.6,0.1,1.5,0.2,2.7,0.3c1,0,1.8,0.1,2.5,0.1c0.7,0.1,1.3,0.3,1.9,0.6c0.6,0.3,1,0.9,1.3,1.6
									c0.5,1.1,0.3,2.2-0.3,3.2c-0.7,1-1.9,1.8-3.6,2.5C970.6,1855.4,968.9,1855.6,967.4,1855.4z"
						/>
						<path
							d="M984.7,1849.4c-0.7-2.2-1.8-5.2-3.3-9.1c-0.8-2-1.4-3.5-1.9-4.5c-0.5-1-0.9-1.7-1.2-2c0.5-0.3,1.1-0.6,1.6-0.9
									c0.5-0.2,1.1-0.4,1.9-0.5c1.2,1.2,2.9,2.8,5,4.8c2.2,2,3.9,3.6,5.3,4.7c0.2-1.9,0.4-4.2,0.5-6.9c0.2-2.8,0.3-5.1,0.4-7
									c0.4-0.3,0.9-0.6,1.7-0.9c0.5-0.2,1.1-0.4,1.8-0.5c0.3,1,0.8,2.4,1.4,4.1c0.6,1.7,1.2,3.4,1.9,5c1.5,3.7,2.6,5.9,3.2,6.6
									c-0.5,0.3-1.1,0.6-1.6,0.9c-0.5,0.2-1.2,0.4-1.9,0.6c-0.3-1-0.8-2.4-1.4-4.2c-0.6-1.7-1.2-3.4-1.9-4.9c-0.4-1-0.9-2.1-1.4-3.4
									c-0.4,3.7-0.7,8.6-1,14.8c-0.2,0.2-0.4,0.3-0.6,0.4c-0.2,0.1-0.4,0.1-0.7,0.1c-1.1-1.1-2.6-2.5-4.6-4.4s-3.9-3.5-5.5-5
									c0.8,2.1,1.4,3.7,1.9,4.8c1.4,3.6,2.4,5.8,3,6.7c-0.3,0.3-0.7,0.5-1.1,0.6C985.7,1849.2,985.3,1849.3,984.7,1849.4z"
						/>
						<path
							d="M1007.5,1838.6c-1.5-0.7-2.6-1.9-3.4-3.7c-0.6-1.3-0.8-2.6-0.7-3.8c0.1-1.3,0.6-2.4,1.4-3.4c0.8-1,1.8-1.8,3.1-2.3
									c1.2-0.5,2.3-0.7,3.4-0.6c1.1,0.1,2.1,0.5,3,1.2c0.9,0.7,1.6,1.6,2.1,2.8c0.6,1.3,0.8,2.6,0.6,3.9c-0.1,1.3-0.6,2.4-1.4,3.4
									c-0.8,1-1.9,1.8-3.2,2.4C1010.6,1839.2,1009,1839.2,1007.5,1838.6z M1014.6,1834.6c0.3-1,0.2-2.3-0.4-3.6
									c-0.5-1.2-1.3-2.1-2.3-2.8c-1-0.6-2.1-0.9-3.3-0.7c-1.2,0.5-2,1.3-2.3,2.4c-0.4,1.1-0.3,2.3,0.3,3.6c0.7,1.5,1.5,2.6,2.6,3.2
									c1.1,0.6,2.1,0.7,3.1,0.2C1013.5,1836.5,1014.2,1835.7,1014.6,1834.6z"
						/>
						<path
							d="M1023.7,1833c-0.7-2-1.6-4.1-2.6-6.3c-0.5-1.1-0.9-1.9-1.2-2.4c-0.3-0.5-0.6-0.9-1-1.2s-0.7-0.5-1.2-0.7
									c0.2-0.5,0.4-0.9,0.7-1.3c0.3-0.4,0.6-0.6,0.9-0.7c1.2-0.5,2.1-0.1,2.8,1.3c0.2-0.9,0.6-1.6,1-2.3c0.4-0.7,1-1.3,1.7-1.8
									c0.3,0.2,0.6,0.6,1,1.2c0.4,0.5,0.7,1,0.9,1.5c-0.3,0.1-0.6,0.2-1.1,0.4c-0.7,0.3-1.2,0.7-1.8,1.1c-0.5,0.4-0.9,0.8-1.2,1.2
									l0.4,0.9c0.5,1.3,1,2.5,1.5,3.5c0.9,2,1.8,3.4,2.6,4.1c-0.6,0.4-1.1,0.7-1.6,0.9S1024.4,1832.8,1023.7,1833z"
						/>
						<path
							d="M1034.8,1827.6c-0.4-0.2-0.9-0.7-1.3-1.6c-0.3-0.6-0.7-1.6-1.3-3c-0.7-1.5-1.2-2.7-1.7-3.7c-0.2-0.5-0.5-0.9-0.7-1.3
									c-0.8,0.4-1.4,0.6-1.7,0.6c-0.3,0-0.6-0.1-0.7-0.4c1-1.3,1.8-2.9,2.4-4.7l0.8-0.3c0.4,0.6,0.8,1.3,1.1,2c1.3-0.7,2.3-1.2,2.9-1.6
									c0.2,0.4,0.3,0.8,0.4,1.1c0.1,0.3,0.1,0.7,0.1,1.1c-0.7,0.1-1.6,0.3-2.7,0.8c0.4,0.9,0.8,1.8,1.2,2.8c1.1,2.4,2.1,4.1,3,5.2
									c0.9-0.5,1.6-1,2.1-1.4l0.5,0.6c-0.1,0.7-0.4,1.4-0.9,2c-0.5,0.6-1.1,1.1-1.7,1.4C1035.9,1827.7,1035.3,1827.8,1034.8,1827.6z"
						/>
						<path
							d="M1043.3,1823.9c-1.1-2.5-2.2-5-3.4-7.5c-1.7-3.3-3-5.9-4.2-7.7c0.8-0.9,1.8-1.6,3-2.2c0.3,1,1.1,2.9,2.3,5.8
									c0.3-0.9,0.7-1.6,1.3-2.3c0.6-0.7,1.2-1.2,2-1.6c2.4-1.2,4.2-0.4,5.5,2.2l0.8,1.8l0.9,2c1,2,1.9,3.4,2.7,4c-0.5,0.4-1,0.7-1.5,1
									c-0.5,0.2-1.1,0.5-1.9,0.7c-1.2-2.8-2.4-5.4-3.5-7.7c-0.4-0.8-0.8-1.2-1.3-1.4s-1.1-0.1-1.9,0.3c-1.1,0.5-1.9,1.2-2.5,2.2
									c0.2,0.6,0.6,1.4,1.1,2.4c1.8,3.6,3.1,5.7,3.9,6.3c-0.5,0.4-1,0.7-1.6,1C1044.6,1823.4,1044,1823.7,1043.3,1823.9z"
						/>
						<path
							d="M1050.3,1800.9c0.2-1,0.5-1.9,0.9-2.7c0.9,0.4,1.8,0.6,2.8,0.8c-0.2,0.9-0.5,1.8-0.9,2.7
									C1052.2,1801.4,1051.3,1801.1,1050.3,1800.9z M1059.1,1815.7c-0.4-0.2-0.8-0.8-1.3-1.6c-0.4-0.7-0.9-1.7-1.5-3
									c-0.6-1.2-1-2.2-1.3-2.7c-0.4-0.8-0.8-1.5-1.3-2.1c-0.4-0.6-0.8-1-1.2-1.2c0.5-0.5,0.9-0.9,1.4-1.3c0.5-0.3,1-0.7,1.7-1
									c0.2,0.6,0.5,1.5,1.1,2.7c0.5,1.2,1.2,2.5,1.9,3.9c0.5,1.1,1,1.8,1.3,2.4c0.4,0.5,0.7,0.9,1,1.1c0.3,0.2,0.7,0.5,1.2,0.6
									c-0.1,0.5-0.4,0.9-0.7,1.3c-0.3,0.4-0.6,0.6-0.9,0.8C1060,1815.8,1059.5,1815.9,1059.1,1815.7z"
						/>
						<path
							d="M1066.9,1811.6c-0.4-0.2-0.9-0.8-1.3-1.6c-0.3-0.6-0.8-1.5-1.4-2.9c-0.7-1.5-1.3-2.7-1.8-3.6c-1.6-3.1-2.9-5.1-4-6.3
									c0.4-0.5,0.9-0.9,1.4-1.3c0.5-0.4,1-0.7,1.6-1c1,2.5,2.7,5.9,5,10.3c0.5,1,1,1.8,1.4,2.3c0.4,0.5,0.7,0.9,1,1.1
									c0.3,0.2,0.8,0.4,1.3,0.6c-0.1,0.5-0.4,0.9-0.6,1.3c-0.3,0.4-0.6,0.6-0.9,0.8C1067.8,1811.8,1067.3,1811.9,1066.9,1811.6z"
						/>
						<path
							d="M1069.5,1793.6c1.9,1.4,3.5,3.2,4.7,5.4s1.9,4.5,2,6.9c0.1,2.3-0.3,4.7-1.2,7l-0.5-0.1c0.5-2.1,0.6-4.2,0.2-6.2
									s-1.2-4.2-2.5-6.5s-2.6-4.2-4.1-5.7c-1.5-1.4-3.2-2.5-5.3-3.3l0.2-0.5C1065.4,1791.3,1067.6,1792.3,1069.5,1793.6z"
						/>
					</g>
					<g className="level_one location" id="lamedon">
						<rect x="1171.457" y="1245.202" width="76.065" height="27.393" transform="matrix(0.941069, 0.338215, -0.338215, 0.941069, 968.191406, 63.848244)"></rect>
						<rect x="1171.457" y="1245.202" width="76.065" height="27.393" transform="matrix(0.99963, 0.027184, -0.027184, 0.99963, 612.5495, 390.535065)"></rect>
						<path
							d="M1647.1,1653.8c0.3-1,0.8-2.5,1.3-4.4c1.2-4.1,1.7-6.6,1.6-7.6c0.7,0,1.3,0.2,2,0.4c0.6,0.2,1.2,0.4,1.9,0.8
									c-0.5,1.2-1,2.7-1.6,4.6s-1.2,3.6-1.6,5.2c-0.7,2.3-1.1,3.9-1.3,4.8c1,0.5,2.1,0.9,3.5,1.3c0.8,0.2,1.7,0.4,2.5,0.5
									c0.9,0.1,1.6,0.1,2.2,0c-0.2,0.7-0.5,1.3-0.8,1.9c-0.4,0.6-0.7,1-0.9,1.2c-1.5-0.6-3.2-1.2-5.3-1.8c-2.4-0.7-4.3-1.2-5.6-1.4
									C1646.1,1656.6,1646.8,1654.8,1647.1,1653.8z"
						/>
						<path
							d="M1671.8,1666.3c-0.4-0.7-0.5-1.6-0.2-2.5c0.7-2.7,3.9-3.4,9.4-2.2c0.4-1.5,0.5-2.5,0.2-3.2c-0.3-0.7-1-1.2-2.2-1.5
									c-0.7-0.2-1.5-0.3-2.3-0.3c-0.8,0-1.5,0.1-2.2,0.3l-0.1-0.7c0.9-0.8,2-1.4,3.2-1.7s2.4-0.3,3.5,0c1.5,0.4,2.5,1.1,3.1,2.1
									c0.6,1,0.7,2.2,0.3,3.6c-0.2,0.8-0.5,1.7-0.9,2.6c-0.4,1-0.7,1.8-0.8,2.3c-0.2,0.8-0.3,1.5-0.3,2c0.1,0.5,0.3,1.1,0.6,1.8
									c-1.1,0.5-2.1,0.6-3.2,0.5c-0.5-0.6-0.8-1.3-0.8-2.2c-1,0.3-2,0.5-3,0.6s-1.9,0-2.6-0.2
									C1672.8,1667.6,1672.2,1667.1,1671.8,1666.3z M1677.5,1666.6c0.7-0.1,1.4-0.2,2-0.5l0-0.2c0.2-0.7,0.4-1.4,0.7-2l0.5-1.4
									c-1.6-0.3-2.9-0.4-3.9-0.1c-1,0.3-1.6,0.9-1.9,1.8c-0.1,0.4-0.1,0.8,0,1.2c0.1,0.4,0.3,0.8,0.6,1.1
									C1676.1,1666.7,1676.7,1666.7,1677.5,1666.6z"
						/>
						<path
							d="M1695.1,1673.4c0.8-2.2,1.5-4.5,2.2-7c0.3-1.2,0.5-2.2,0.6-2.8s0.1-1.2,0-1.6s-0.2-0.9-0.5-1.4c0.5-0.3,1-0.5,1.4-0.6
									c0.5-0.1,0.9-0.1,1.2,0c1.2,0.3,1.7,1.2,1.5,2.5c0.8-0.5,1.6-0.8,2.5-0.9c0.9-0.2,1.8-0.1,2.6,0.1c2,0.5,3,1.7,3.1,3.4
									c0.8-0.6,1.7-1,2.7-1.2c1-0.2,1.9-0.2,2.8,0.1c2.7,0.7,3.6,2.6,2.8,5.7l-0.6,2.1c-0.3,0.8-0.5,1.5-0.7,2.2c-0.6,2.3-0.8,4-0.6,5.2
									c-0.7,0-1.3-0.1-1.9-0.3c-0.6-0.2-1.3-0.4-2-0.8c0.9-2.6,1.8-5.5,2.6-8.6c0.2-0.9,0.2-1.6,0-2.1c-0.3-0.5-0.8-0.8-1.7-1.1
									c-1.2-0.3-2.2-0.3-3.3-0.1c-0.2,0.8-0.5,2-1,3.6c-0.2,0.5-0.3,1.1-0.5,1.8c-0.6,2.3-0.9,4-0.7,5.2c-0.7,0-1.3-0.1-1.9-0.3
									c-0.6-0.2-1.3-0.4-2-0.8c0.9-2.6,1.8-5.5,2.6-8.6c0.2-0.9,0.2-1.6,0-2.1c-0.3-0.5-0.8-0.8-1.7-1.1c-1.2-0.3-2.2-0.3-3.2-0.1
									l-0.4,1.4c-0.4,1.1-0.8,2.4-1.2,4c-0.6,2.3-0.9,4-0.7,5.2c-0.7-0.1-1.4-0.2-2-0.3C1696.5,1674,1695.9,1673.7,1695.1,1673.4z"
						/>
						<path
							d="M1750.9,1686c-0.8-1.4-1.1-3.2-0.7-5.5c0.3-1.5,0.8-2.9,1.6-4c0.8-1.1,1.9-1.9,3.1-2.4s2.6-0.6,4.1-0.4
									c2,0.3,3.3,1.2,4.1,2.6c0.8,1.4,1,3.3,0.6,5.7c-1.3,0.1-3,0.1-4.8,0c-1.9-0.1-3.7-0.2-5.5-0.5c-0.1,1.5,0.2,2.6,0.7,3.3
									c0.6,0.8,1.5,1.2,2.8,1.4c1,0.2,2,0.2,2.9,0.1c0.9-0.1,1.7-0.3,2.4-0.6l0.1,0.7c-0.9,0.8-2,1.4-3.4,1.8c-1.4,0.4-2.7,0.4-4,0.2
									C1753.1,1688.3,1751.7,1687.4,1750.9,1686z M1757.3,1680.4c1.2-0.1,2.2-0.2,2.9-0.4c0.2-1.2,0-2.3-0.6-3.2
									c-0.6-0.9-1.4-1.5-2.6-1.6c-0.8,0.3-1.5,0.9-2.2,1.9c-0.6,1-1,2.1-1.3,3.4C1754.8,1680.5,1756,1680.5,1757.3,1680.4z"
						/>
						<path
							d="M1769.4,1688.7c-0.9-1.3-1.3-3.3-1.1-5.7c0.2-2.5,0.9-4.4,2-5.6c1.2-1.2,2.8-1.7,4.8-1.6c1.6,0.1,3.1,0.7,4.4,1.8
									c0-2.4-0.1-4.1-0.3-5.3c0.7-0.2,1.4-0.3,2.1-0.4c0.6-0.1,1.3-0.1,2.1,0c-0.5,2.9-1.1,7.9-1.6,14.9c-0.1,0.8,0,1.5,0.1,2
									s0.5,1.1,1,1.7c-0.9,0.6-1.9,1-3.1,1c-0.6-0.5-1-1.2-1.2-2.1c-0.7,0.6-1.5,1-2.4,1.3c-0.9,0.3-1.7,0.4-2.5,0.3
									C1771.8,1690.8,1770.3,1690.1,1769.4,1688.7z M1776.7,1688.9c0.7-0.2,1.3-0.5,1.9-0.9c0.1-0.7,0.2-2,0.4-3.7
									c0.2-1.9,0.4-3.5,0.4-4.6c-0.9-0.7-2-1.1-3.3-1.2c-1.3-0.1-2.3,0.3-3.1,1.1c-0.8,0.8-1.2,2.1-1.3,3.7c-0.1,1.6,0.1,3,0.6,4
									c0.6,1,1.4,1.7,2.6,2C1775.4,1689.3,1776.1,1689.2,1776.7,1688.9z"
						/>
						<path
							d="M1789.7,1689.9c-1.2-1.3-1.9-3.1-1.8-5.2c0-1.6,0.3-3,1-4.2c0.7-1.2,1.6-2.2,2.9-2.8c1.2-0.7,2.6-1,4.2-1
									c1.4,0,2.6,0.3,3.7,0.9c1.1,0.6,1.9,1.5,2.5,2.5c0.6,1.1,0.9,2.3,0.9,3.8c0,1.6-0.4,3-1.1,4.2c-0.7,1.2-1.7,2.2-3,2.8
									c-1.3,0.7-2.7,1-4.3,1C1792.6,1691.9,1790.9,1691.2,1789.7,1689.9z M1798.6,1689c0.8-0.9,1.2-2.2,1.2-3.9c0-1.5-0.4-2.7-1.1-3.8
									c-0.7-1.1-1.7-1.8-3-2.2c-1.4,0-2.6,0.5-3.4,1.4c-0.9,0.9-1.3,2.2-1.3,3.8c0,1.8,0.4,3.3,1.2,4.4c0.8,1.1,1.8,1.6,3.1,1.6
									C1796.7,1690.4,1797.8,1689.9,1798.6,1689z"
						/>
						<path
							d="M1809.7,1691.2c0-2.4-0.1-4.9-0.3-7.6c-0.1-1.3-0.2-2.3-0.4-3c-0.1-0.7-0.3-1.2-0.5-1.6s-0.5-0.8-1-1.2
									c0.4-0.5,0.8-0.8,1.2-1.1c0.4-0.2,0.8-0.4,1.2-0.4c1.3-0.1,2.1,0.6,2.4,2c0.6-0.8,1.3-1.4,2.2-1.8c0.8-0.5,1.7-0.7,2.6-0.8
									c2.9-0.2,4.5,1.3,4.7,4.6l0.1,2.2c0,0.9,0,1.7,0.1,2.4c0.2,2.4,0.6,4.2,1.2,5.3c-0.7,0.2-1.3,0.3-1.9,0.4c-0.6,0-1.4,0-2.2,0
									c0-2.9-0.2-6-0.4-9.3c-0.1-0.9-0.3-1.6-0.7-2s-1.1-0.5-2-0.5c-1.4,0.1-2.5,0.5-3.3,1.1l0.1,1.5c0,1.2,0.1,2.6,0.2,4.3
									c0.2,2.5,0.6,4.2,1.1,5.3c-0.7,0.2-1.4,0.3-2.1,0.4C1811.3,1691.3,1810.6,1691.3,1809.7,1691.2z"
						/>
					</g>
					<g className="level_two fade location " id="ringlo">
						<rect x="1171.457" y="1088.963" width="76.065" height="23.956" transform="matrix(0.654143, -0.756371, 0.756371, 0.654143, 100.823753, 1873.619263)"></rect>
						<path
							d="M1708.1,1708.5c-0.6-0.5-1.5-1.3-2.6-2.2c-1.2-0.9-2.2-1.7-3.1-2.4c-2.2-1.6-3.6-2.5-4.3-2.6c0.2-0.5,0.4-1.2,0.8-2
									c0.4-0.8,0.9-1.5,1.4-2.2c1-1.4,2.1-2.2,3.2-2.5c1.1-0.3,2.3,0,3.4,0.8c0.7,0.5,1.1,1.1,1.4,1.9c0.3,0.7,0.3,1.6,0.1,2.5
									c2.8,0.4,4.6,0.5,5.6,0.2c-0.1,0.5-0.3,0.9-0.5,1.4s-0.5,0.9-0.9,1.4c-0.9-0.2-2.6-0.5-5.1-0.8c0,0.1-0.1,0.2-0.3,0.4
									c-0.2,0.3-0.4,0.6-0.7,0.9c-0.3,0.3-0.5,0.5-0.7,0.7c1.9,1.4,3.2,2.2,3.9,2.4c-0.2,0.4-0.4,0.8-0.7,1.2
									C1708.8,1707.7,1708.5,1708.1,1708.1,1708.5z M1706.6,1699c-0.2-0.6-0.5-1.1-1-1.5c-1.6-1.1-3-0.8-4.4,1c-0.3,0.4-0.5,0.8-0.7,1.2
									c0.9,0.8,2.2,1.8,3.9,3.1c1-0.7,1.7-1.3,2.1-1.9C1706.8,1700.2,1706.8,1699.6,1706.6,1699z"
						/>
						<path
							d="M1705.7,1689.5c-0.2-0.8-0.2-1.5-0.2-2.2c0.7,0,1.5-0.1,2.2-0.3c0.1,0.7,0.2,1.4,0.2,2.2
									C1707.1,1689.2,1706.4,1689.3,1705.7,1689.5z M1716.5,1696.8c-0.4,0-0.8-0.3-1.4-0.7c-0.5-0.4-1.1-0.9-2-1.6
									c-0.8-0.7-1.4-1.1-1.8-1.4c-0.5-0.4-1-0.8-1.5-1c-0.5-0.3-0.9-0.4-1.2-0.4c0.2-0.5,0.3-0.9,0.5-1.3c0.2-0.4,0.5-0.8,0.8-1.2
									c0.3,0.4,0.9,0.9,1.6,1.5c0.7,0.7,1.6,1.3,2.6,2.1c0.7,0.5,1.3,0.9,1.7,1.2c0.4,0.2,0.8,0.4,1.1,0.5c0.3,0.1,0.7,0.1,1.1,0
									c0.1,0.4,0.1,0.8,0,1.1c-0.1,0.3-0.2,0.6-0.4,0.8C1717.2,1696.7,1716.8,1696.9,1716.5,1696.8z"
						/>
						<path
							d="M1721.2,1691.2c-1.2-1.1-2.5-2.3-3.9-3.4c-0.7-0.6-1.2-1-1.6-1.2c-0.4-0.3-0.8-0.4-1-0.5s-0.6-0.1-1.1-0.1
									c-0.1-0.4-0.1-0.8,0-1.1s0.2-0.6,0.4-0.8c0.6-0.7,1.3-0.8,2.1-0.3c-0.1-0.7-0.1-1.3,0.1-1.9c0.2-0.6,0.5-1.2,0.9-1.7
									c1.3-1.5,2.8-1.6,4.5-0.2l1.2,1c0.4,0.4,0.8,0.8,1.2,1.1c1.3,1.1,2.4,1.7,3.2,1.9c-0.2,0.4-0.5,0.8-0.7,1.1
									c-0.3,0.3-0.6,0.7-1.1,1.1c-1.5-1.3-3.1-2.7-4.8-4.2c-0.5-0.4-1-0.6-1.3-0.6c-0.4,0-0.8,0.3-1.2,0.8c-0.6,0.7-0.9,1.4-1,2.2
									l0.8,0.7c0.6,0.6,1.4,1.2,2.2,1.9c1.3,1.1,2.4,1.7,3.2,1.9c-0.2,0.5-0.5,0.9-0.8,1.2C1722,1690.4,1721.6,1690.8,1721.2,1691.2z"
						/>
						<path
							d="M1731.8,1683.5c-0.4-0.4-0.8-0.9-1.1-1.4c-0.3-0.5-0.4-1.1-0.5-1.7c-0.6,0.4-1.2,0.3-1.6-0.1c-0.3-0.3-0.5-0.7-0.6-1.2
									c0-0.5,0.1-1.1,0.3-1.7c-0.6,0.3-1.2,0.3-1.8,0.2c-0.6-0.1-1.2-0.4-1.7-0.8c-0.9-0.7-1.3-1.7-1.3-2.8s0.4-2.1,1.3-3.1
									c0.6-0.7,1.2-1.1,1.9-1.4c0.2-0.3,0.3-0.7,0.4-1.2c0.1-0.5,0.2-1,0.2-1.6c0.3,0.1,0.7,0.2,1.1,0.3c0.4,0.2,0.7,0.3,0.8,0.5
									c-0.1,0.6-0.5,1.2-1,1.8c0.7,0.1,1.3,0.4,1.8,0.9c0.6,0.5,0.9,1.1,1.1,1.8c0.2,0.7,0.2,1.4-0.1,2.1c-0.2,0.7-0.6,1.5-1.2,2.1
									c-0.5,0.6-0.8,1.1-0.9,1.5c-0.1,0.5,0,0.8,0.3,1.1l3.3-3.5c0.7-0.6,1.3-1,1.9-1.1c0.6-0.1,1.2,0.1,1.7,0.5c0.5,0.4,0.8,1,0.9,1.7
									c0.1,0.7,0,1.4-0.3,2.3c-0.3,0.8-0.8,1.6-1.4,2.3C1734.2,1682.4,1733.1,1683.2,1731.8,1683.5z M1730.1,1673.7c0-0.5-0.3-1-0.8-1.5
									c-0.4-0.4-0.9-0.6-1.5-0.6s-1.1,0.2-1.6,0.6c-0.5,0.5-0.7,1.1-0.6,1.7c0,0.6,0.3,1.2,0.8,1.6c0.5,0.4,1,0.6,1.6,0.5
									s1.1-0.3,1.5-0.8C1729.8,1674.7,1730.1,1674.2,1730.1,1673.7z M1735.7,1677.6c0.2-0.6,0.1-1-0.2-1.3c-0.2-0.2-0.5-0.3-0.8-0.2
									c-0.3,0.1-0.6,0.3-1,0.8l-2.7,2.8c0.2,0.5,0.4,0.9,0.6,1.3c1.1,0.1,2-0.4,2.9-1.4C1735.2,1678.9,1735.6,1678.2,1735.7,1677.6z"
						/>
						<path
							d="M1738.2,1671c-0.3,0-0.8-0.3-1.4-0.8c-0.4-0.3-1-0.9-1.8-1.7c-0.9-0.9-1.6-1.6-2.2-2.1c-1.9-1.8-3.4-2.9-4.5-3.4
									c0.2-0.5,0.4-0.9,0.6-1.3c0.2-0.4,0.5-0.8,0.9-1.1c1.4,1.5,3.4,3.5,6.2,6.1c0.6,0.6,1.2,1,1.6,1.3c0.4,0.3,0.7,0.5,1,0.5
									c0.3,0.1,0.7,0.1,1.1,0.1c0,0.4,0,0.8-0.1,1.1s-0.3,0.6-0.4,0.8C1738.9,1670.8,1738.5,1671,1738.2,1671z"
						/>
						<path
							d="M1735.7,1655.8c0.2-0.6,0.4-1.3,0.4-2s-0.1-1.2-0.3-1.6c0.4-0.5,0.8-0.8,1.2-0.9c0.4-0.2,0.8-0.2,1.2-0.1
									c0,1.4-0.6,3.1-1.9,4.9L1735.7,1655.8z M1742.5,1665c-1.2,0-2.4-0.5-3.5-1.6c-0.8-0.7-1.3-1.6-1.6-2.5s-0.3-1.8,0-2.8
									s0.7-1.8,1.5-2.6c0.7-0.7,1.4-1.2,2.2-1.4s1.6-0.2,2.4,0s1.6,0.7,2.3,1.4c0.8,0.7,1.3,1.6,1.6,2.5c0.3,0.9,0.3,1.9,0,2.8
									s-0.8,1.8-1.6,2.6C1744.8,1664.5,1743.7,1665,1742.5,1665z M1746.3,1660.2c-0.1-0.8-0.5-1.6-1.3-2.4c-0.7-0.7-1.5-1.1-2.4-1.3
									c-0.9-0.2-1.7,0-2.5,0.4c-0.7,0.7-1,1.5-0.9,2.4c0.1,0.9,0.5,1.7,1.3,2.4c0.9,0.9,1.8,1.4,2.8,1.5s1.7-0.1,2.3-0.8
									C1746,1661.8,1746.3,1661,1746.3,1660.2z"
						/>
					</g>
					<g className="level_zero location" id="edoras">
						<rect className="level_one location" x="1568.9" y="1489.9" width="18.8" height="16.5" />
						<rect x="1305.964" y="1164.956" width="84.799" height="25.628" transform="matrix(0.959281, -0.282455, 0.282455, 0.959281, -99.076645, 766.359314)"></rect>
						<path
							d="M1494.2,1528.7c-0.3-1.2-0.8-2.8-1.5-4.8c-1.1-3.6-2.1-6-2.9-7.3c1.1-0.2,2.6-0.6,4.7-1.3c2.6-0.8,4.5-1.5,5.9-2.1
									c0.2,0.6,0.3,1.2,0.4,2c0.1,0.7,0.1,1.2,0,1.5c-0.6-0.1-1.2-0.2-2-0.1s-1.6,0.2-2.3,0.5c-1,0.3-1.9,0.7-2.8,1.2
									c0.5,1.9,1,3.5,1.4,5l0.7-0.2c0.7-0.2,1.3-0.3,1.7-0.5c0.9-0.3,1.6-0.5,2.1-0.8c0.5-0.2,1-0.5,1.5-0.8c0.2,0.4,0.4,0.8,0.5,1.2
									c0.1,0.3,0.2,0.7,0.3,1.3c-2.2,0.5-3.9,1-5.1,1.4c-0.6,0.2-1,0.3-1.2,0.4c0.8,2.4,1.3,4,1.7,5c1-0.1,2-0.3,3.1-0.6
									c0.8-0.3,1.7-0.6,2.5-1c0.8-0.4,1.4-0.9,1.9-1.3c0.2,0.6,0.3,1.2,0.4,2c0.1,0.7,0.1,1.2,0,1.5c-1.3,0.2-3.1,0.7-5.4,1.5
									c-2.1,0.7-3.6,1.2-4.6,1.7C1495,1531.6,1494.6,1529.9,1494.2,1528.7z"
						/>
						<path
							d="M1508.8,1528.1c-1.2-0.9-2.1-2.5-2.9-4.8c-0.8-2.4-0.9-4.3-0.5-5.7c0.4-1.5,1.5-2.5,3.1-3c1.3-0.4,2.6-0.3,3.9,0.2
									c-0.8-2.1-1.5-3.7-2.1-4.7c0.5-0.4,1-0.8,1.4-1s1-0.5,1.6-0.7c0.6,2.8,2,7.5,4.1,13.9c0.2,0.8,0.5,1.3,0.8,1.7
									c0.3,0.4,0.8,0.8,1.4,1.2c-0.4,0.9-1.1,1.5-1.9,1.9c-0.7-0.3-1.2-0.8-1.7-1.5c-0.3,0.8-0.8,1.4-1.3,1.9c-0.5,0.5-1.1,0.9-1.8,1.1
									C1511.3,1529.3,1509.9,1529.1,1508.8,1528.1z M1514.3,1526c0.4-0.4,0.8-0.9,1.1-1.4c-0.2-0.7-0.6-1.8-1-3.5
									c-0.5-1.8-1-3.2-1.3-4.2c-0.9-0.3-1.9-0.3-2.9,0c-1,0.3-1.6,1-1.9,2s-0.2,2.2,0.3,3.8c0.5,1.5,1.1,2.6,1.9,3.4
									c0.8,0.7,1.7,1,2.6,0.9C1513.5,1526.7,1513.9,1526.4,1514.3,1526z"
						/>
						<path
							d="M1522.6,1523.6c-1.4-0.9-2.3-2.4-3-4.4c-0.5-1.4-0.6-2.8-0.4-4.1c0.2-1.3,0.6-2.4,1.4-3.3c0.8-0.9,1.7-1.6,3-2
									c1.1-0.3,2.1-0.4,3.1-0.1c1,0.3,1.9,0.9,2.7,1.7s1.4,1.9,1.8,3.3c0.5,1.4,0.6,2.8,0.4,4.1c-0.2,1.3-0.7,2.4-1.5,3.4
									c-0.8,0.9-1.8,1.6-3.1,2C1525.4,1524.7,1523.9,1524.5,1522.6,1523.6z M1529.3,1520.6c0.3-1,0.3-2.3-0.2-3.9
									c-0.4-1.4-1.1-2.4-2-3.2c-0.9-0.8-1.9-1.2-3-1.3c-1.1,0.4-1.9,1.1-2.3,2.1c-0.4,1.1-0.4,2.3,0.1,3.8c0.5,1.7,1.3,3,2.2,3.7
									c0.9,0.8,1.9,1,2.9,0.7C1528.2,1522.3,1528.9,1521.6,1529.3,1520.6z"
						/>
						<path
							d="M1536.6,1520.8c-0.6-2.2-1.2-4.6-2-7c-0.4-1.2-0.7-2.1-1-2.7c-0.3-0.6-0.5-1.1-0.8-1.4c-0.3-0.3-0.6-0.6-1.1-0.9
									c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.5,0.9-0.6c1.1-0.3,1.9,0.3,2.5,1.9c0.3-0.9,0.6-1.6,1.1-2.2c0.4-0.6,1-1.2,1.6-1.6
									c0.2,0.3,0.5,0.8,0.8,1.4c0.3,0.6,0.6,1.2,0.7,1.8c-0.2,0-0.6,0.1-1,0.2c-0.6,0.2-1.2,0.5-1.7,0.8c-0.5,0.3-0.9,0.7-1.2,1l0.3,1
									c0.4,1.5,0.8,2.7,1.2,3.9c0.7,2.3,1.4,3.9,2.1,4.7c-0.5,0.3-1,0.6-1.5,0.7S1537.3,1520.7,1536.6,1520.8z"
						/>
						<path
							d="M1544.7,1517.7c-0.7-0.4-1.1-1.1-1.4-2c-0.8-2.6,1-4.9,5.4-6.6c-0.5-1.4-1-2.4-1.5-2.8c-0.5-0.5-1.3-0.5-2.3-0.2
									c-0.6,0.2-1.2,0.5-1.7,0.9c-0.5,0.4-1,0.9-1.4,1.4l-0.4-0.5c0.2-1.1,0.7-2.1,1.4-3c0.7-0.9,1.5-1.5,2.4-1.8
									c1.3-0.4,2.3-0.3,3.3,0.2c0.9,0.5,1.6,1.5,2.1,2.9c0.2,0.8,0.5,1.7,0.7,2.7c0.3,1.1,0.5,1.9,0.6,2.4c0.3,0.8,0.5,1.5,0.8,1.9
									c0.3,0.4,0.8,0.8,1.4,1.2c-0.5,0.9-1.1,1.6-1.9,2c-0.6-0.2-1.2-0.7-1.7-1.5c-0.5,0.8-1.1,1.4-1.8,2c-0.6,0.6-1.3,1-1.9,1.2
									C1546,1518.2,1545.3,1518.1,1544.7,1517.7z M1548.7,1515.1c0.5-0.4,0.8-0.9,1.1-1.4l-0.1-0.2c-0.2-0.7-0.4-1.4-0.6-2.1l-0.4-1.4
									c-1.3,0.5-2.2,1.1-2.7,1.9c-0.5,0.8-0.7,1.6-0.4,2.5c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.6,0.5,1,0.6
									C1547.8,1515.8,1548.3,1515.5,1548.7,1515.1z"
						/>
						<path
							d="M1555.5,1513.8c-0.1-0.4-0.2-0.8-0.3-1.2c0-0.4,0-0.8,0-1.3c0.5,0.4,1.2,0.7,1.9,0.8c0.7,0.1,1.4,0.1,2.1-0.2
									c1.8-0.6,2.6-1.5,2.2-2.6c-0.2-0.5-0.5-0.8-1-1c-0.5-0.2-1.3-0.4-2.3-0.5c-1-0.2-1.8-0.3-2.4-0.5c-0.6-0.2-1.2-0.5-1.8-1
									c-0.6-0.5-1-1.1-1.3-2c-0.3-1-0.2-2,0.4-2.9c0.6-0.9,1.5-1.5,2.8-2c1.7-0.5,3.3-0.8,4.7-0.6c0.1,0.3,0.2,0.8,0.2,1.5
									s0,1.2-0.1,1.8c-0.9-0.5-1.8-0.9-2.6-1.1c-0.8-0.2-1.4-0.3-1.9-0.1c-0.4,0.3-0.7,0.6-0.9,1c-0.2,0.4-0.2,0.8-0.1,1.1
									c0.2,0.6,0.6,1,1.1,1.3c0.6,0.2,1.4,0.4,2.5,0.6c0.9,0.1,1.7,0.3,2.3,0.5c0.6,0.2,1.2,0.5,1.7,0.9c0.5,0.4,0.9,1.1,1.2,1.8
									c0.4,1.2,0.3,2.3-0.4,3.3s-1.8,1.7-3.3,2.2C1558.5,1514.2,1556.9,1514.3,1555.5,1513.8z"
						/>
					</g>
					<g className="level_two fade location " id="adorn">
						<rect x="1188.069" y="1312.654" width="76.511" height="32.578" transform="matrix(0.949709, 0.313135, -0.313135, 0.949709, 567.278076, -207.301453)"></rect>
						<path
							d="M1290.9,1440l0-4.7c-0.9-0.4-2-0.8-3.3-1.2c-1.4-0.5-2.4-0.7-3-0.8c-0.8,1.1-1.7,2.4-2.7,3.8c-0.4,0-0.7,0-1-0.1
									c-0.4-0.1-0.8-0.3-1.1-0.6c0.9-0.9,2.7-3.2,5.3-6.8c2.7-3.6,4.7-6.5,6.1-8.6c0.6,0.1,1,0.1,1.3,0.2c0.3,0.1,0.7,0.3,1.1,0.5
									c-0.1,2.5,0,6,0,10.5s0.2,7.4,0.4,8.7c-0.5,0-1.1-0.1-1.6-0.3C1292,1440.6,1291.5,1440.4,1290.9,1440z M1291,1433.2
									c0-3.2-0.1-5.9-0.2-8.1c-1.3,1.6-2.9,3.8-4.8,6.5c1,0.4,2.1,0.8,3.3,1.2C1290.1,1433,1290.6,1433.1,1291,1433.2z"
						/>
						<path
							d="M1297.8,1440.4c-0.6-1.4-0.6-3.2-0.1-5.6c0.5-2.4,1.3-4.2,2.4-5.2c1.1-1.1,2.5-1.4,4.1-1.1c1.3,0.3,2.4,1,3.3,2.1
									c0.3-2.3,0.5-4,0.5-5.1c0.6-0.1,1.2-0.2,1.7-0.2c0.5,0,1.1,0.1,1.7,0.2c-0.8,2.8-1.9,7.5-3.3,14.1c-0.2,0.8-0.2,1.4-0.1,1.9
									c0.1,0.5,0.3,1.1,0.6,1.7c-0.8,0.5-1.7,0.8-2.6,0.7c-0.4-0.6-0.7-1.3-0.7-2.1c-0.7,0.5-1.4,0.8-2.1,1c-0.7,0.2-1.4,0.2-2.1,0.1
									C1299.5,1442.6,1298.4,1441.8,1297.8,1440.4z M1303.7,1441.2c0.6-0.2,1.1-0.4,1.6-0.7c0.1-0.7,0.4-1.9,0.8-3.5
									c0.4-1.8,0.7-3.3,1-4.3c-0.7-0.7-1.5-1.2-2.6-1.4c-1-0.2-1.9,0-2.6,0.8c-0.7,0.7-1.3,1.9-1.6,3.4c-0.3,1.5-0.3,2.8,0,3.9
									c0.3,1,0.9,1.7,1.8,2.1C1302.6,1441.5,1303.2,1441.4,1303.7,1441.2z"
						/>
						<path
							d="M1312.2,1442.5c-0.7-1.5-0.8-3.2-0.4-5.3c0.3-1.5,0.9-2.7,1.7-3.8c0.8-1,1.8-1.8,2.9-2.2c1.1-0.4,2.3-0.5,3.5-0.2
									c1.1,0.2,2,0.8,2.7,1.5c0.7,0.8,1.2,1.7,1.4,2.8s0.2,2.4-0.1,3.7c-0.3,1.5-0.9,2.7-1.7,3.8c-0.8,1-1.8,1.7-3,2.2
									c-1.2,0.4-2.4,0.5-3.7,0.2C1314,1444.9,1312.9,1444,1312.2,1442.5z M1319.4,1443.3c0.8-0.7,1.4-1.9,1.8-3.4c0.3-1.4,0.3-2.6,0-3.8
									c-0.3-1.1-1-2-1.9-2.6c-1.1-0.3-2.2,0-3,0.7c-0.9,0.7-1.5,1.8-1.8,3.3c-0.4,1.7-0.4,3.2,0,4.4c0.4,1.2,1.1,1.9,2.1,2.1
									C1317.6,1444.3,1318.6,1444,1319.4,1443.3z"
						/>
						<path
							d="M1325.1,1447.8c0.8-2.1,1.7-4.4,2.5-6.9c0.4-1.2,0.7-2.1,0.8-2.8c0.1-0.7,0.2-1.2,0.2-1.6c0-0.4-0.1-0.9-0.3-1.4
									c0.4-0.3,0.9-0.5,1.3-0.6c0.4-0.1,0.8-0.1,1.1,0c1.1,0.4,1.4,1.4,0.9,3c0.7-0.5,1.4-0.9,2.2-1.2c0.7-0.3,1.5-0.4,2.3-0.4
									c0,0.4,0,0.9-0.1,1.6c-0.1,0.7-0.2,1.3-0.4,1.9c-0.2-0.1-0.5-0.2-1-0.4c-0.6-0.2-1.2-0.3-1.8-0.3s-1.1,0-1.6,0.2l-0.4,1
									c-0.5,1.4-0.9,2.7-1.3,3.8c-0.7,2.3-1.1,4-1,5.1c-0.6-0.1-1.2-0.2-1.7-0.3C1326.2,1448.3,1325.6,1448.1,1325.1,1447.8z"
						/>
						<path
							d="M1333.7,1450.2c1.1-2,2.2-4.2,3.2-6.6c0.5-1.1,0.9-2,1.1-2.7c0.2-0.6,0.3-1.2,0.4-1.6c0-0.4,0-0.9-0.2-1.4
									c0.5-0.3,0.9-0.4,1.3-0.4s0.8,0,1,0.2c1,0.4,1.3,1.3,0.8,2.6c0.8-0.4,1.6-0.6,2.4-0.7c0.8-0.1,1.6,0.1,2.2,0.4
									c2.2,1,2.6,2.9,1.3,5.8l-1,1.9c-0.4,0.7-0.8,1.4-1,2.1c-1,2.1-1.5,3.8-1.6,5c-0.6-0.1-1.1-0.3-1.5-0.5c-0.5-0.2-1-0.5-1.6-0.9
									c1.3-2.5,2.6-5.2,3.9-8.1c0.4-0.8,0.5-1.5,0.4-2c-0.1-0.5-0.5-0.9-1.2-1.2c-1-0.5-2-0.6-2.8-0.4l-0.6,1.3c-0.5,1-1.1,2.3-1.8,3.7
									c-1,2.2-1.5,3.8-1.6,4.9c-0.6-0.1-1.2-0.3-1.7-0.5C1334.7,1450.9,1334.2,1450.6,1333.7,1450.2z"
						/>
					</g>
					<g className="level_one location " id="angren">
						<rect x="661.551" y="1008.631" width="162.479" height="35.101" transform="matrix(0.914059, -0.405581, 0.129493, 1.036563, 346.39035, 650.618835)"></rect>
						<path
							d="M1092.1,1443l-0.2-4.7c-0.9-0.3-2-0.7-3.4-1.1c-1.4-0.4-2.4-0.6-3-0.6c-0.8,1.2-1.6,2.5-2.5,3.9c-0.4,0-0.7,0-1-0.1
									c-0.4-0.1-0.8-0.3-1.1-0.5c0.8-1,2.5-3.3,5-7c2.5-3.7,4.4-6.7,5.7-8.8c0.6,0,1,0.1,1.3,0.2c0.4,0.1,0.7,0.3,1.1,0.5
									c0.1,2.5,0.2,6,0.5,10.5c0.3,4.5,0.5,7.4,0.8,8.6c-0.5,0-1.1,0-1.6-0.2C1093.2,1443.5,1092.7,1443.3,1092.1,1443z M1091.8,1436.1
									c-0.1-3.2-0.3-5.9-0.5-8.1c-1.2,1.6-2.7,3.9-4.5,6.7c1,0.3,2.1,0.7,3.3,1C1090.9,1435.9,1091.5,1436.1,1091.8,1436.1z"
						/>
						<path
							d="M1102.7,1444.7c0.2-2.3,0.3-4.7,0.4-7.3c0-1.3,0-2.2-0.1-2.9c-0.1-0.7-0.2-1.2-0.3-1.6c-0.1-0.4-0.4-0.8-0.7-1.2
									c0.3-0.4,0.7-0.7,1.1-0.9c0.4-0.2,0.7-0.3,1-0.3c1.1,0,1.7,0.7,1.8,2.1c0.5-0.7,1.2-1.2,1.9-1.6c0.7-0.4,1.5-0.6,2.2-0.5
									c2.4,0,3.5,1.7,3.5,4.8l-0.1,2.2c-0.1,0.8-0.1,1.6-0.1,2.3c0,2.4,0.1,4.1,0.5,5.2c-0.6,0.1-1.1,0.2-1.6,0.2
									c-0.5,0-1.1-0.1-1.8-0.2c0.2-2.8,0.3-5.8,0.4-9c0-0.9-0.1-1.6-0.4-2c-0.3-0.4-0.8-0.6-1.6-0.6c-1.1,0-2,0.2-2.8,0.7l-0.1,1.5
									c-0.1,1.2-0.1,2.6-0.2,4.2c0,2.4,0.1,4.1,0.5,5.2c-0.6,0.1-1.2,0.2-1.7,0.2C1104,1444.9,1103.4,1444.8,1102.7,1444.7z"
						/>
						<path
							d="M1121.5,1448.6c-0.2-0.8-0.2-1.6,0-2.5c0.1-0.8,0.4-1.6,0.8-2.3c-0.8-0.2-1.3-0.7-1.5-1.6c-0.1-0.6,0-1.2,0.3-1.8
									s0.8-1.1,1.5-1.5c-0.8-0.2-1.4-0.7-1.9-1.4c-0.5-0.7-0.9-1.5-1.1-2.4c-0.3-1.6-0.1-3,0.7-4.1c0.8-1.2,1.9-1.9,3.5-2.2
									c1-0.2,1.9-0.1,2.8,0.2c0.4-0.2,0.8-0.5,1.2-1c0.4-0.4,0.9-1,1.3-1.6c0.3,0.4,0.6,0.8,0.8,1.3c0.3,0.5,0.4,0.9,0.5,1.3
									c-0.6,0.5-1.3,0.9-2.2,1.1c0.6,0.8,0.9,1.6,1.1,2.5c0.2,1,0.2,2-0.1,2.9c-0.3,0.9-0.8,1.7-1.6,2.3c-0.7,0.6-1.6,1-2.7,1.2
									c-0.9,0.2-1.5,0.5-1.9,0.9c-0.4,0.4-0.6,0.9-0.5,1.4l5.7-0.8c1.1-0.1,2,0.1,2.7,0.5c0.7,0.4,1.1,1.1,1.3,2.1
									c0.2,0.9,0.1,1.8-0.3,2.6c-0.4,0.8-1,1.6-1.9,2.2c-0.9,0.6-1.9,1-3.1,1.2C1124.7,1449.5,1122.9,1449.4,1121.5,1448.6z
									M1126.7,1436.4c0.4-0.6,0.5-1.3,0.3-2.2c-0.2-0.8-0.5-1.5-1-2c-0.5-0.5-1.2-0.8-2-0.8c-0.8,0.2-1.4,0.6-1.8,1.3
									c-0.4,0.7-0.5,1.5-0.3,2.4c0.2,0.9,0.5,1.5,1.1,2c0.6,0.4,1.3,0.6,2,0.5C1125.8,1437.4,1126.4,1437,1126.7,1436.4z M1129.4,1445.8
									c0.6-0.5,0.8-1,0.7-1.6c-0.1-0.5-0.3-0.8-0.6-0.9c-0.3-0.2-0.8-0.2-1.5-0.1l-4.5,0.6c-0.2,0.7-0.3,1.4-0.3,1.9
									c1,1,2.2,1.4,3.8,1.1C1128,1446.6,1128.8,1446.3,1129.4,1445.8z"
						/>
						<path
							d="M1138.4,1439.7c-0.7-2.2-1.4-4.5-2.3-6.9c-0.4-1.2-0.8-2.1-1.1-2.7c-0.3-0.6-0.6-1.1-0.9-1.4s-0.6-0.6-1.1-0.9
									c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.5,0.9-0.6c1.1-0.4,1.9,0.2,2.6,1.8c0.2-0.9,0.6-1.6,1-2.3c0.4-0.6,0.9-1.2,1.6-1.7
									c0.3,0.3,0.6,0.7,0.9,1.4c0.3,0.6,0.6,1.2,0.8,1.7c-0.2,0-0.6,0.1-1,0.3c-0.6,0.2-1.2,0.5-1.6,0.9c-0.5,0.4-0.9,0.7-1.1,1.1l0.3,1
									c0.5,1.4,0.9,2.7,1.3,3.8c0.8,2.3,1.6,3.8,2.4,4.6c-0.5,0.3-1,0.6-1.5,0.8C1139.7,1439.4,1139.1,1439.6,1138.4,1439.7z"
						/>
						<path
							d="M1150.2,1433.4c-1.4-0.6-2.6-1.8-3.8-3.7c-0.8-1.3-1.3-2.6-1.4-3.9c-0.2-1.3,0-2.5,0.4-3.5c0.5-1.1,1.2-1.9,2.2-2.5
									c1.4-0.8,2.7-1,4.1-0.4s2.6,1.9,3.8,3.9c-0.8,0.8-1.7,1.7-2.9,2.7c-1.2,1-2.4,1.9-3.6,2.7c0.8,1.1,1.6,1.8,2.5,2.1
									c0.8,0.3,1.7,0.1,2.6-0.5c0.7-0.4,1.3-0.9,1.8-1.5c0.5-0.6,0.9-1.2,1.1-1.8l0.5,0.4c-0.1,1.1-0.4,2.2-1,3.2
									c-0.6,1-1.4,1.8-2.3,2.4C1152.9,1433.8,1151.6,1433.9,1150.2,1433.4z M1150.7,1425.6c0.7-0.7,1.2-1.4,1.5-1.9
									c-0.6-1-1.4-1.7-2.3-2.1c-0.9-0.4-1.8-0.3-2.6,0.2c-0.3,0.7-0.4,1.5-0.2,2.6c0.2,1.1,0.6,2.1,1.3,3.3
									C1149.2,1427.1,1150,1426.4,1150.7,1425.6z"
						/>
						<path
							d="M1165.3,1424.5c-1.4-1.8-3-3.7-4.7-5.7c-0.8-0.9-1.5-1.6-2-2.1c-0.5-0.5-0.9-0.8-1.3-1c-0.4-0.2-0.8-0.3-1.4-0.4
									c0-0.5,0-1,0.2-1.4c0.1-0.4,0.3-0.7,0.6-0.9c0.8-0.7,1.7-0.6,2.8,0.3c-0.1-0.9,0.1-1.7,0.3-2.4c0.3-0.8,0.7-1.4,1.3-1.9
									c1.8-1.6,3.7-1.2,5.8,1.2l1.4,1.7c0.5,0.7,1,1.3,1.5,1.8c1.6,1.8,2.9,2.9,3.9,3.5c-0.3,0.5-0.7,0.9-1.1,1.2
									c-0.4,0.3-0.9,0.7-1.5,1c-1.7-2.2-3.7-4.5-5.8-6.9c-0.6-0.7-1.2-1.1-1.7-1.2c-0.5-0.1-1,0.1-1.6,0.6c-0.8,0.7-1.4,1.5-1.6,2.4
									l1,1.1c0.7,0.9,1.6,2,2.7,3.2c1.6,1.8,2.9,3,3.9,3.5c-0.4,0.5-0.8,0.9-1.2,1.3C1166.4,1423.9,1165.9,1424.2,1165.3,1424.5z"
						/>
						<path
							d="M1173,1388.6l0.5,0.1c0.1,2.1,0.5,4.1,1.4,6.1c0.9,1.9,2.2,4,3.9,6.1s3.5,3.9,5.2,5.1c1.7,1.2,3.6,2.1,5.6,2.6l0,0.5
									c-2.4-0.3-4.6-1-6.7-2.2s-4-2.8-5.6-4.8c-1.7-2.1-2.9-4.2-3.5-6.5S1172.8,1390.9,1173,1388.6z"
						/>
						<path
							d="M1189,1403.4c-0.5-1-1.4-2.3-2.4-4c-1.1-1.7-2.1-3.3-3.1-4.8c-2.4-3.5-4-5.5-4.8-6.1c0.4-0.4,0.8-0.8,1.2-1.1
									c0.4-0.3,1-0.6,1.6-0.8c0.5,1,1.4,2.3,2.4,4c1.1,1.7,2.1,3.3,3.1,4.7c2.4,3.5,4,5.5,4.8,6.2c-0.4,0.4-0.8,0.8-1.3,1.1
									C1190.1,1402.9,1189.6,1403.2,1189,1403.4z"
						/>
						<path
							d="M1194.1,1398.9c-0.2-0.4-0.3-0.8-0.4-1.2c-0.1-0.4-0.2-0.8-0.2-1.3c0.6,0.3,1.3,0.5,2,0.5c0.7,0,1.4-0.1,2.1-0.5
									c1.7-0.8,2.3-1.8,1.8-2.9c-0.2-0.5-0.6-0.7-1.1-0.9c-0.5-0.1-1.3-0.2-2.3-0.2c-1,0-1.8-0.1-2.5-0.2c-0.6-0.1-1.3-0.3-1.9-0.7
									c-0.6-0.4-1.1-1-1.5-1.8c-0.5-1-0.5-1.9,0-2.9c0.5-1,1.3-1.7,2.5-2.3c1.6-0.8,3.1-1.2,4.5-1.3c0.2,0.3,0.3,0.8,0.4,1.4
									c0.1,0.6,0.1,1.2,0.1,1.8c-1-0.4-1.9-0.6-2.7-0.7c-0.8-0.1-1.4-0.1-1.9,0.2c-0.4,0.3-0.7,0.7-0.8,1.1c-0.1,0.4-0.1,0.8,0,1.1
									c0.3,0.6,0.7,0.9,1.3,1.1s1.4,0.2,2.6,0.3c0.9,0,1.7,0.1,2.3,0.1c0.6,0.1,1.3,0.3,1.8,0.7c0.6,0.4,1.1,0.9,1.4,1.7
									c0.6,1.2,0.6,2.3,0.1,3.3c-0.5,1-1.5,1.9-3,2.6C1197.1,1398.8,1195.5,1399.1,1194.1,1398.9z"
						/>
						<path
							d="M1206.2,1392.7c-1.1-1-1.9-2.5-2.5-4.7c-0.4-1.5-0.4-2.8-0.2-4.1c0.2-1.3,0.7-2.3,1.5-3.2c0.8-0.9,1.7-1.4,2.9-1.7
									c1.6-0.4,2.9-0.1,4,0.9c1.1,1,1.9,2.6,2.5,4.9c-1,0.5-2.2,1.1-3.6,1.7c-1.4,0.6-2.9,1-4.3,1.4c0.4,1.3,1,2.2,1.7,2.7
									s1.6,0.6,2.6,0.3c0.8-0.2,1.5-0.5,2.2-0.9c0.7-0.4,1.2-0.8,1.6-1.3l0.4,0.6c-0.4,1-1,2-1.9,2.7c-0.9,0.8-1.9,1.3-2.9,1.6
									C1208.7,1394,1207.4,1393.7,1206.2,1392.7z M1209.1,1385.5c0.9-0.5,1.6-1,2-1.4c-0.3-1.2-0.8-2.1-1.6-2.7
									c-0.8-0.6-1.6-0.8-2.5-0.6c-0.5,0.5-0.8,1.4-1,2.4c-0.1,1.1,0,2.2,0.3,3.5C1207.2,1386.4,1208.2,1386,1209.1,1385.5z"
						/>
						<path
							d="M1220.5,1391.5c0.1-2.3,0.2-4.7,0.2-7.3c0-1.3-0.1-2.2-0.1-2.9c-0.1-0.7-0.2-1.2-0.3-1.6c-0.2-0.4-0.4-0.8-0.8-1.2
									c0.3-0.4,0.7-0.8,1-1s0.7-0.3,1-0.3c1.1,0,1.7,0.7,1.9,2c0.5-0.7,1.1-1.2,1.9-1.6c0.7-0.4,1.4-0.6,2.2-0.6c2.4,0,3.6,1.6,3.6,4.7
									l0,2.2c-0.1,0.8-0.1,1.6-0.1,2.3c0,2.4,0.2,4.1,0.7,5.2c-0.6,0.1-1.1,0.2-1.6,0.2c-0.5,0-1.1-0.1-1.8-0.2c0.1-2.8,0.2-5.8,0.1-9
									c0-0.9-0.2-1.6-0.5-2c-0.3-0.4-0.9-0.6-1.6-0.6c-1.1,0-2,0.3-2.8,0.8l0,1.5c-0.1,1.2-0.1,2.6-0.1,4.2c0,2.4,0.2,4.1,0.7,5.2
									c-0.6,0.1-1.2,0.2-1.7,0.2C1221.7,1391.7,1221.2,1391.6,1220.5,1391.5z"
						/>
						<path
							d="M1240.2,1377.2c0.5,2.2,0.6,4.6,0.3,7.1s-1.1,4.8-2.3,6.7c-1.2,2-2.6,3.6-4.4,5l-0.3-0.4c1.4-1.4,2.6-3.1,3.4-4.9
									s1.4-4.1,1.8-6.7c0.4-2.6,0.5-4.9,0.2-7c-0.2-2-0.8-3.9-1.8-5.7l0.4-0.3C1238.7,1373,1239.6,1375,1240.2,1377.2z"
						/>
					</g>
					<g className="level_two fade location" id="anfalas">
						<rect x="2916.869" y="1622.617" width="189.397" height="35.696" transform="matrix(0.966581, -0.25636, 0.255539, 0.966799, -2072.614502, 971.961914)"></rect>
						<rect x="3122.117" y="1892.405" width="202.724" height="41.631" transform="matrix(0.999394, 0.034803, -0.035652, 0.999365, -1716.385132, -259.8013)"></rect>
						<path
							d="M1188.9,1816.7l-3.4-5c-1.3,0.2-2.8,0.6-4.7,1.1c-2,0.6-3.3,1-3.9,1.3c-0.1,1.8-0.2,3.8-0.3,6c-0.4,0.3-0.8,0.5-1.2,0.6
									c-0.5,0.1-1.1,0.2-1.7,0.2c0.3-1.6,0.7-5.4,1.1-11.2c0.4-5.8,0.6-10.4,0.7-13.6c0.7-0.3,1.2-0.6,1.7-0.7c0.5-0.1,1-0.2,1.6-0.2
									c1.8,2.7,4.3,6.5,7.7,11.3c3.4,4.8,5.6,7.8,6.8,9c-0.6,0.4-1.3,0.7-2,0.9C1190.6,1816.5,1189.8,1816.7,1188.9,1816.7z
									M1183.9,1809.3c-2.4-3.5-4.4-6.3-6.1-8.6c-0.3,2.6-0.5,6.1-0.7,10.4c1.4-0.3,2.9-0.7,4.6-1.2
									C1182.8,1809.7,1183.5,1809.5,1183.9,1809.3z"
						/>
						<path
							d="M1202.3,1813c-0.6-2.9-1.4-6-2.3-9.2c-0.4-1.6-0.8-2.7-1.2-3.5c-0.3-0.8-0.7-1.4-1-1.8s-0.8-0.8-1.4-1.2
									c0.3-0.7,0.6-1.2,1-1.6c0.4-0.4,0.8-0.6,1.2-0.7c1.4-0.4,2.4,0.2,3.1,1.9c0.4-1,1.1-2,1.9-2.7c0.8-0.8,1.7-1.3,2.7-1.5
									c3.1-0.9,5.2,0.6,6.3,4.6l0.7,2.7c0.2,1.1,0.5,2,0.7,2.9c0.8,2.9,1.7,5,2.7,6.2c-0.7,0.4-1.4,0.7-2,0.9c-0.7,0.2-1.5,0.3-2.5,0.4
									c-0.8-3.5-1.8-7.3-2.9-11.3c-0.3-1.1-0.8-1.9-1.3-2.3c-0.6-0.4-1.3-0.4-2.3-0.1c-1.5,0.4-2.6,1.1-3.3,2l0.5,1.8
									c0.3,1.5,0.8,3.2,1.4,5.2c0.9,3,1.7,5,2.6,6.2c-0.7,0.4-1.5,0.7-2.2,0.9C1204.1,1812.8,1203.3,1812.9,1202.3,1813z"
						/>
						<path
							d="M1228.2,1812.4c0.3-0.9,0.6-1.7,0.7-2.5c0.1-0.7,0.1-1.7-0.1-2.8c-0.2-1.1-0.6-2.7-1.1-4.6c-0.6-2.2-1.9-6.1-3.7-11.6
									c-0.9,0.2-1.5,0.3-1.9,0.3c-0.3-0.1-0.6-0.3-0.7-0.7c0.9-0.9,1.5-1.6,1.8-2.1c-0.4-2.5-0.1-4.6,0.7-6.3c0.8-1.8,2.1-2.9,3.9-3.4
									c1.3-0.4,2.6-0.5,3.9-0.5c0.1,0.4,0.2,1.1,0.2,1.9c0,0.8-0.1,1.6-0.2,2.3c-1-0.6-2-1-3-1.2c-1-0.2-1.8-0.3-2.3-0.1
									c-0.4,0.5-0.5,1.2-0.4,2.2c0.1,1,0.4,2.5,0.9,4.5c2-0.6,3.3-1.1,4-1.5c0.2,0.6,0.3,1.1,0.3,1.6c0,0.5,0,1-0.1,1.6
									c-1-0.1-2.2,0.1-3.7,0.5l0.9,3c0.5,1.7,1.1,3.7,1.9,5.9c0.4,1.2,0.8,2.2,1,3.1c0.4,1.4,0.6,2.7,0.6,3.7c0,1-0.2,2.1-0.7,3.2
									c-0.5,1.1-1.2,2.4-2.3,4L1228.2,1812.4z"
						/>
						<path
							d="M1244.4,1800.1c-0.9-0.6-1.5-1.4-1.8-2.6c-1-3.4,1.6-6.3,7.7-8.5c-0.5-1.9-1.2-3.1-1.9-3.7c-0.7-0.6-1.7-0.7-3.1-0.3
									c-0.8,0.2-1.6,0.6-2.3,1.1s-1.4,1.1-1.9,1.8l-0.6-0.7c0.3-1.5,1-2.8,2-3.9s2.1-1.9,3.4-2.3c1.7-0.5,3.2-0.4,4.4,0.3
									c1.2,0.7,2.1,2,2.6,3.8c0.3,1,0.6,2.2,0.8,3.5c0.3,1.4,0.5,2.4,0.7,3.1c0.3,1.1,0.7,1.9,1,2.4c0.4,0.5,1,1.1,1.8,1.6
									c-0.7,1.2-1.6,2-2.7,2.6c-0.9-0.3-1.6-0.9-2.2-1.9c-0.7,1-1.6,1.8-2.5,2.6c-0.9,0.7-1.8,1.2-2.7,1.5
									C1246.1,1800.9,1245.2,1800.7,1244.4,1800.1z M1250,1796.9c0.6-0.6,1.2-1.2,1.6-1.8l-0.1-0.2c-0.3-0.9-0.5-1.8-0.6-2.7l-0.4-1.9
									c-1.8,0.6-3,1.4-3.8,2.4c-0.8,1-1,2.1-0.7,3.2c0.1,0.5,0.4,0.9,0.8,1.3s0.8,0.7,1.3,0.8C1248.7,1797.8,1249.4,1797.4,1250,1796.9z
									"
						/>
						<path
							d="M1267.7,1793.7c-0.5-0.4-0.9-1.3-1.3-2.6c-0.3-0.9-0.6-2.3-1.2-4.2c-0.6-2.2-1.1-4-1.5-5.4c-1.3-4.6-2.5-7.8-3.6-9.6
									c0.6-0.5,1.3-1,1.9-1.3s1.4-0.6,2.2-0.8c0.7,3.6,2,8.7,3.9,15.3c0.4,1.5,0.8,2.7,1.2,3.5c0.3,0.8,0.7,1.4,1,1.8
									c0.4,0.4,0.8,0.8,1.4,1.2c-0.3,0.6-0.6,1.2-1,1.6c-0.4,0.4-0.8,0.7-1.2,0.8C1268.8,1794.2,1268.2,1794.1,1267.7,1793.7z"
						/>
						<path
							d="M1281.7,1789.3c-0.9-0.5-1.5-1.4-1.9-2.6c-1.1-3.4,1.4-6.3,7.5-8.7c-0.6-1.9-1.2-3.1-2-3.7c-0.7-0.6-1.8-0.7-3.1-0.2
									c-0.8,0.3-1.6,0.7-2.3,1.2c-0.7,0.5-1.4,1.2-1.9,1.9l-0.6-0.7c0.3-1.5,1-2.8,1.9-3.9c1-1.2,2.1-1.9,3.3-2.3
									c1.7-0.5,3.2-0.5,4.4,0.2c1.2,0.7,2.1,2,2.7,3.8c0.3,1,0.6,2.2,0.9,3.5c0.3,1.4,0.6,2.4,0.8,3.1c0.3,1.1,0.7,1.9,1.1,2.4
									c0.4,0.5,1,1.1,1.8,1.6c-0.7,1.2-1.6,2.1-2.7,2.6c-0.9-0.3-1.6-0.9-2.2-1.9c-0.7,1-1.5,1.9-2.4,2.6c-0.9,0.7-1.8,1.3-2.6,1.5
									C1283.5,1790,1282.6,1789.9,1281.7,1789.3z M1287.3,1786c0.6-0.6,1.2-1.2,1.6-1.8l-0.1-0.2c-0.3-0.9-0.5-1.8-0.7-2.7l-0.5-1.9
									c-1.7,0.7-3,1.5-3.8,2.5c-0.8,1-0.9,2.1-0.6,3.2c0.2,0.5,0.4,0.9,0.8,1.3c0.4,0.4,0.8,0.7,1.3,0.8
									C1286,1786.9,1286.6,1786.5,1287.3,1786z"
						/>
						<path
							d="M1302.7,1782c-0.2-0.5-0.3-1.1-0.3-1.6c-0.1-0.5,0-1.1,0-1.7c0.7,0.5,1.6,0.9,2.5,1c1,0.1,1.9,0,2.8-0.3
									c2.5-0.8,3.5-2,3-3.5c-0.2-0.6-0.7-1-1.4-1.3c-0.7-0.2-1.7-0.4-3-0.6c-1.3-0.2-2.4-0.4-3.3-0.6s-1.7-0.6-2.4-1.2
									c-0.8-0.6-1.3-1.4-1.7-2.5c-0.4-1.3-0.3-2.6,0.6-3.7c0.8-1.2,2.1-2.1,3.8-2.6c2.3-0.8,4.4-1.1,6.3-1c0.1,0.4,0.2,1.1,0.2,1.9
									c0,0.8-0.1,1.6-0.2,2.3c-1.2-0.6-2.4-1-3.5-1.3c-1.1-0.3-1.9-0.3-2.5-0.1c-0.6,0.3-1,0.8-1.3,1.3c-0.3,0.5-0.3,1-0.2,1.4
									c0.3,0.8,0.8,1.3,1.5,1.6c0.8,0.3,1.9,0.5,3.4,0.7c1.2,0.1,2.3,0.3,3.1,0.5s1.6,0.6,2.3,1.1c0.7,0.6,1.2,1.3,1.6,2.3
									c0.5,1.6,0.4,3-0.5,4.2c-0.9,1.3-2.4,2.2-4.5,3C1306.6,1782.4,1304.5,1782.5,1302.7,1782z"
						/>
						<path
							d="M1342.6,1746.2l0.5,0.4c-1.2,2.2-2,4.6-2.2,7.2c-0.3,2.5-0.2,5.5,0.3,8.8c0.5,3.3,1.3,6.1,2.3,8.5c1,2.3,2.4,4.4,4.3,6.2
									l-0.3,0.5c-2.3-1.7-4.2-3.8-5.6-6.3c-1.5-2.5-2.5-5.3-2.9-8.5c-0.5-3.2-0.4-6.2,0.3-9C1339.7,1751.1,1340.9,1748.5,1342.6,1746.2z
									"
						/>
						<path
							d="M1351.2,1764.2c-0.1-1.3-0.2-3.1-0.5-5.6c-0.5-5.2-1-8.3-1.5-9.4c0.7-0.2,1.3-0.4,2-0.4c0.6-0.1,1.3,0,2.2,0.1
									c0,1.5,0.1,3.5,0.2,5.9c0.1,2.4,0.3,4.7,0.5,6.7c0.3,2.9,0.5,4.9,0.7,6.1c1.1,0.2,2.4,0.2,3.9,0c0.9-0.1,1.8-0.3,2.6-0.6
									s1.6-0.6,2.1-1c0.1,0.8,0.1,1.7,0,2.6c-0.1,0.9-0.2,1.5-0.4,1.8c-1.6,0-3.6,0-5.7,0.2c-2.6,0.2-4.6,0.5-5.9,0.8
									C1351.4,1767.8,1351.3,1765.4,1351.2,1764.2z"
						/>
						<path
							d="M1368.7,1769.3c-0.6-0.7-1-1.6-1-2.8c-0.2-3.4,2.7-5.4,8.5-6.1c-0.1-1.9-0.4-3.1-0.9-3.8c-0.5-0.7-1.4-1-2.7-1
									c-0.8,0-1.5,0.2-2.3,0.5c-0.8,0.3-1.5,0.7-2.1,1.2l-0.3-0.8c0.6-1.3,1.5-2.3,2.6-3.1s2.3-1.3,3.4-1.3c1.6-0.1,2.9,0.4,3.8,1.3
									c0.9,0.9,1.4,2.3,1.5,4.1c0,1,0,2.2,0,3.4c0,1.4,0,2.4,0,3c0,1.1,0.2,1.9,0.4,2.5c0.2,0.6,0.6,1.2,1.2,1.9
									c-0.9,0.9-1.9,1.5-2.9,1.7c-0.7-0.5-1.2-1.2-1.5-2.3c-0.9,0.7-1.8,1.3-2.7,1.8c-0.9,0.5-1.8,0.7-2.6,0.8
									C1370.1,1770.4,1369.3,1770,1368.7,1769.3z M1374.3,1767.6c0.7-0.4,1.3-0.8,1.8-1.3l0-0.2c0-0.9,0-1.8,0-2.6l0-1.8
									c-1.7,0.2-3,0.7-3.9,1.4c-0.9,0.7-1.3,1.7-1.2,2.8c0,0.5,0.2,0.9,0.4,1.4s0.6,0.8,0.9,1C1373,1768.2,1373.6,1767.9,1374.3,1767.6z
									"
						/>
						<path
							d="M1387.9,1769.2c0.1-2.8,0.2-5.8,0.2-9c0-1.5-0.1-2.7-0.2-3.5c-0.1-0.8-0.2-1.5-0.4-1.9c-0.2-0.5-0.5-1-0.9-1.5
									c0.4-0.5,0.8-0.9,1.3-1.2c0.4-0.2,0.9-0.4,1.2-0.4c1.3,0,2.1,0.8,2.3,2.5c0.6-0.8,1.4-1.5,2.3-2c0.9-0.5,1.8-0.7,2.7-0.7
									c2.9,0,4.4,1.9,4.4,5.8l-0.1,2.7c-0.1,1-0.1,2-0.1,2.8c0,2.9,0.3,5,0.8,6.3c-0.7,0.2-1.4,0.3-2,0.3c-0.6,0-1.4-0.1-2.2-0.2
									c0.1-3.4,0.2-7.1,0.2-11.1c0-1.1-0.2-1.9-0.6-2.4c-0.4-0.5-1.1-0.7-2-0.7c-1.4,0-2.5,0.3-3.4,1l0,1.8c-0.1,1.4-0.1,3.1-0.1,5.1
									c0,3,0.3,5.1,0.8,6.3c-0.7,0.2-1.5,0.3-2.1,0.3C1389.5,1769.4,1388.8,1769.4,1387.9,1769.2z"
						/>
						<path
							d="M1407.1,1775c0-1,0.2-2,0.6-3c0.4-1,0.9-1.8,1.5-2.6c-1-0.4-1.4-1.2-1.4-2.3c0-0.8,0.3-1.5,0.8-2.1s1.2-1.1,2.1-1.5
									c-0.9-0.5-1.5-1.2-2-2.1c-0.5-0.9-0.7-2-0.7-3.2c0-2,0.7-3.6,1.9-4.8s2.8-1.8,4.7-1.8c1.3,0,2.4,0.3,3.3,1
									c0.5-0.1,1.1-0.4,1.7-0.8c0.7-0.4,1.3-0.9,1.9-1.6c0.3,0.5,0.5,1.1,0.7,1.8s0.3,1.2,0.3,1.6c-0.8,0.5-1.8,0.7-3,0.8
									c0.5,1,0.7,2.1,0.7,3.3c0,1.3-0.3,2.4-0.9,3.4c-0.6,1-1.4,1.8-2.4,2.3c-1,0.5-2.2,0.8-3.5,0.8c-1.1,0-1.9,0.2-2.5,0.5
									c-0.6,0.4-0.9,0.9-1,1.5l7,0.5c1.3,0.2,2.4,0.6,3.1,1.3c0.7,0.7,1,1.7,1,2.9c0,1.1-0.4,2.1-1,3c-0.7,0.9-1.6,1.6-2.8,2.1
									c-1.2,0.5-2.5,0.7-4,0.7C1410.6,1776.8,1408.6,1776.2,1407.1,1775z M1417.3,1773.5c0.8-0.4,1.2-1,1.2-1.8c0-0.6-0.2-1-0.5-1.3
									c-0.3-0.3-0.9-0.4-1.8-0.5l-5.6-0.4c-0.4,0.8-0.7,1.6-0.8,2.3c0.9,1.5,2.3,2.2,4.3,2.2C1415.4,1774.2,1416.5,1773.9,1417.3,1773.5
									z M1416.4,1761.6c0.6-0.6,0.9-1.5,0.9-2.6c0-1-0.2-1.9-0.8-2.6c-0.5-0.7-1.3-1.2-2.2-1.5c-1,0-1.9,0.3-2.5,1.1s-1,1.7-1,2.8
									c0,1.1,0.3,2,0.9,2.7c0.6,0.7,1.4,1,2.3,1C1415,1762.5,1415.8,1762.2,1416.4,1761.6z"
						/>
						<path
							d="M1425.7,1768.4c0-0.5,0.1-1,0.2-1.5c0.1-0.5,0.3-1,0.6-1.5c0.5,0.7,1.1,1.3,1.8,1.7c0.8,0.4,1.6,0.6,2.5,0.6
									c2.4,0.1,3.6-0.7,3.6-2.2c0-0.6-0.2-1.1-0.8-1.6c-0.5-0.4-1.3-0.9-2.4-1.5c-1.1-0.6-2-1.1-2.6-1.6c-0.7-0.5-1.2-1.1-1.7-1.8
									c-0.5-0.8-0.7-1.7-0.7-2.8c0-1.3,0.6-2.4,1.6-3.2c1-0.8,2.4-1.2,4.1-1.1c2.2,0,4.1,0.4,5.6,1.1c0,0.5-0.1,1-0.4,1.8
									c-0.2,0.7-0.5,1.4-0.9,2c-0.9-0.9-1.7-1.7-2.6-2.3c-0.8-0.6-1.5-0.9-2.1-0.9c-0.6,0.1-1.1,0.4-1.5,0.7c-0.4,0.4-0.6,0.8-0.6,1.2
									c0,0.8,0.3,1.4,0.8,1.9c0.6,0.5,1.4,1.1,2.6,1.7c1,0.5,1.8,1,2.5,1.5c0.6,0.5,1.2,1,1.6,1.8c0.4,0.7,0.7,1.6,0.6,2.6
									c0,1.6-0.6,2.8-1.7,3.6c-1.1,0.8-2.7,1.2-4.7,1.2C1428.9,1770,1427.1,1769.4,1425.7,1768.4z"
						/>
						<path
							d="M1445,1769.6c-0.3-0.5-0.5-1.4-0.4-2.6c0-0.9,0.1-2.3,0.2-4.3c0.1-2.1,0.2-3.9,0.3-5.3c0-0.7,0-1.3,0-1.9
									c-1,0-1.7-0.1-2-0.3c-0.3-0.2-0.5-0.4-0.5-0.8c1.7-1,3.2-2.3,4.7-4.1l0.9,0.2c0.1,1,0.1,1.9,0.1,3c1.7,0,2.9,0,3.7-0.2
									c0,0.6-0.1,1.1-0.2,1.6c-0.1,0.4-0.3,0.9-0.5,1.4c-0.7-0.3-1.7-0.5-3.1-0.6c-0.1,1.3-0.2,2.6-0.3,4c-0.1,3.5-0.1,6.1,0.2,7.9
									c1.1,0,2.1-0.2,2.8-0.4l0.2,1c-0.5,0.8-1.1,1.4-1.9,1.8c-0.8,0.4-1.6,0.7-2.4,0.6C1445.9,1770.3,1445.3,1770.1,1445,1769.6z"
						/>
						<path
							d="M1457.7,1770.5c0.4-2.8,0.6-5.8,0.9-8.9c0.1-1.5,0.1-2.7,0.1-3.5c0-0.8-0.1-1.5-0.3-2c-0.2-0.5-0.4-1-0.8-1.5
									c0.4-0.5,0.9-0.8,1.4-1c0.5-0.2,0.9-0.3,1.3-0.3c1.4,0.1,2.1,1.2,2,3.3c0.7-0.9,1.4-1.5,2.2-2.1c0.8-0.5,1.6-0.9,2.6-1.1
									c0.1,0.5,0.3,1.1,0.3,2c0.1,0.9,0.1,1.6,0,2.3c-0.3-0.1-0.7-0.1-1.3-0.2c-0.8-0.1-1.6,0-2.3,0.2s-1.3,0.4-1.8,0.7l-0.1,1.3
									c-0.2,1.8-0.3,3.5-0.4,4.9c-0.2,2.9-0.1,5.1,0.3,6.4c-0.8,0.1-1.4,0.2-2.1,0.1C1459.2,1770.9,1458.5,1770.7,1457.7,1770.5z"
						/>
						<path
							d="M1471.1,1770.9c-0.5-0.8-0.7-1.8-0.6-2.9c0.4-3.4,3.5-5,9.4-4.8c0.2-1.8,0.1-3.2-0.3-3.9c-0.4-0.8-1.2-1.2-2.5-1.4
									c-0.8-0.1-1.6,0-2.4,0.2c-0.8,0.2-1.6,0.5-2.3,0.9l-0.2-0.8c0.8-1.2,1.8-2.1,3-2.7c1.2-0.6,2.4-0.9,3.6-0.8
									c1.6,0.2,2.8,0.8,3.5,1.8c0.8,1.1,1,2.5,0.8,4.3c-0.1,1-0.3,2.1-0.6,3.4c-0.2,1.3-0.4,2.3-0.5,3c-0.1,1.1-0.1,1.9,0,2.5
									s0.4,1.3,0.9,2.1c-1,0.8-2.1,1.2-3.2,1.3c-0.6-0.6-1-1.4-1.2-2.5c-1,0.6-2,1.1-3,1.4c-1,0.3-1.9,0.4-2.7,0.3
									C1472.3,1772.1,1471.6,1771.7,1471.1,1770.9z M1476.9,1770c0.7-0.3,1.4-0.6,2-1l0-0.2c0.1-0.9,0.2-1.8,0.4-2.6l0.3-1.8
									c-1.7-0.1-3,0.2-4,0.8c-1,0.6-1.5,1.5-1.7,2.6c-0.1,0.5,0,1,0.2,1.4c0.2,0.5,0.4,0.9,0.8,1.2
									C1475.5,1770.4,1476.2,1770.2,1476.9,1770z"
						/>
						<path
							d="M1490,1773.8c0.6-2.8,1.2-5.7,1.7-8.8c0.2-1.5,0.4-2.7,0.4-3.5c0-0.8,0-1.5-0.1-2c-0.1-0.5-0.3-1-0.7-1.6
									c0.5-0.5,1-0.8,1.4-0.9s0.9-0.2,1.3-0.2c1.3,0.2,1.9,1.2,1.8,2.9c0.8-0.7,1.6-1.3,2.6-1.6c0.9-0.3,1.9-0.4,2.8-0.3
									c2.9,0.5,4,2.6,3.4,6.4l-0.5,2.6c-0.2,1-0.4,1.9-0.6,2.8c-0.5,2.9-0.5,5-0.2,6.4c-0.7,0.1-1.4,0-2-0.1c-0.6-0.1-1.4-0.3-2.2-0.6
									c0.7-3.3,1.4-7,2-10.9c0.2-1.1,0.1-1.9-0.2-2.5c-0.3-0.5-0.9-0.9-1.8-1c-1.3-0.2-2.5-0.1-3.5,0.4l-0.3,1.8c-0.3,1.4-0.6,3.1-0.9,5
									c-0.5,2.9-0.5,5-0.3,6.4c-0.8,0-1.5,0-2.1-0.1C1491.5,1774.2,1490.8,1774.1,1490,1773.8z"
						/>
						<path
							d="M1511.1,1775.5c-0.7-1.7-0.8-4-0.2-6.9c0.6-3,1.6-5.1,3-6.4c1.3-1.3,3-1.7,5.1-1.3c1.6,0.3,3,1.2,4.1,2.6
									c0.4-2.8,0.6-4.9,0.6-6.3c0.8-0.2,1.5-0.2,2.1-0.2c0.6,0,1.3,0.1,2.1,0.2c-1,3.4-2.4,9.2-4.1,17.3c-0.2,1-0.3,1.7-0.2,2.4
									c0.1,0.6,0.3,1.3,0.7,2.1c-1,0.7-2.1,0.9-3.2,0.9c-0.5-0.7-0.8-1.6-0.9-2.6c-0.8,0.6-1.7,1-2.6,1.3c-0.9,0.2-1.8,0.3-2.6,0.1
									C1513.1,1778.3,1511.8,1777.2,1511.1,1775.5z M1518.3,1776.6c0.7-0.2,1.4-0.5,2-0.8c0.2-0.9,0.5-2.3,1-4.3c0.5-2.2,0.9-4,1.2-5.3
									c-0.8-0.9-1.9-1.5-3.1-1.7c-1.3-0.3-2.3,0-3.2,0.9c-0.9,0.9-1.5,2.3-1.9,4.2c-0.4,1.9-0.4,3.5,0,4.7c0.4,1.3,1.1,2.1,2.2,2.6
									C1517,1776.9,1517.6,1776.8,1518.3,1776.6z"
						/>
						<path
							d="M1534.1,1761.7c0.5,3,0.3,6.2-0.5,9.6c-0.8,3.3-2.1,6.3-3.9,8.8c-1.8,2.5-4,4.6-6.5,6.2l-0.3-0.5c2.1-1.7,3.8-3.8,5.1-6.2
									c1.3-2.4,2.3-5.4,3.2-8.8c0.8-3.5,1.2-6.6,1.1-9.3c-0.1-2.7-0.7-5.3-1.8-7.8l0.5-0.3C1532.6,1755.9,1533.6,1758.7,1534.1,1761.7z"
						/>
					</g>
					<g className="level_two fade location " id="dimrill_dale">
						<rect x="2554.785" y="1330.028" width="165.889" height="29.259" transform="matrix(0.940686, -0.339277, 0.327946, 0.944773, -1235.711914, 541.688049)"></rect>
						<path
							d="M1617.4,952.8c-1,0.2-1.9,0.4-2.7,0.4c-0.3-1.2-0.7-2.7-1.2-4.7c-0.6-2-1.1-3.8-1.7-5.6c-1.2-3.9-2.2-6.2-2.8-7
									c0.6-0.5,1.5-1,2.5-1.6c1.1-0.5,2.2-1,3.4-1.4c2.9-0.9,5.3-0.8,7.4,0.3c2,1.1,3.5,3.2,4.5,6.1c0.6,1.9,0.7,3.6,0.4,5.3
									c-0.3,1.7-1.1,3.2-2.3,4.4c-1.2,1.3-2.7,2.2-4.6,2.8C1619.4,952.4,1618.4,952.6,1617.4,952.8z M1623.3,947.7c0.5-1,0.8-2,1-3.2
									c0.1-1.2,0-2.3-0.4-3.4c-1.6-5.1-4.6-6.9-9-5.6c-0.7,0.2-1.4,0.6-2,1c0.7,2.7,1.5,5.4,2.4,8.1c0.7,2.3,1.4,4.1,2,5.6
									c1.8-0.1,3.1-0.2,3.9-0.5C1622.1,949.4,1622.8,948.7,1623.3,947.7z"
						/>
						<path
							d="M1627.3,928.7c0.4-1.1,0.8-2,1.3-2.8c0.9,0.6,1.8,1.1,2.7,1.5c-0.3,1-0.8,1.9-1.3,2.8
									C1629.1,929.7,1628.2,929.2,1627.3,928.7z M1634.4,946.9c-0.4-0.3-0.8-1-1.1-2.1c-0.3-0.9-0.7-2.1-1.1-3.7c-0.4-1.5-0.8-2.6-1-3.3
									c-0.3-1-0.7-1.8-1-2.6c-0.4-0.8-0.7-1.3-1-1.6c0.5-0.5,1-0.8,1.6-1.1c0.5-0.3,1.1-0.5,1.8-0.8c0.1,0.7,0.4,1.8,0.7,3.2
									c0.4,1.4,0.9,3,1.4,4.8c0.4,1.3,0.8,2.2,1.1,2.9c0.3,0.6,0.6,1.1,0.9,1.5c0.3,0.3,0.7,0.7,1.2,1c-0.2,0.5-0.5,1-0.8,1.3
									c-0.3,0.3-0.7,0.6-1,0.7C1635.3,947.3,1634.8,947.3,1634.4,946.9z"
						/>
						<path
							d="M1643,944.5c-0.6-2.4-1.4-4.9-2.2-7.5c-0.4-1.3-0.8-2.2-1.1-2.9c-0.3-0.6-0.6-1.1-0.9-1.5c-0.3-0.3-0.7-0.7-1.2-1
									c0.2-0.6,0.5-1,0.8-1.3c0.3-0.3,0.6-0.5,1-0.7c1.1-0.4,2,0.1,2.6,1.4c0.3-0.9,0.8-1.6,1.4-2.3c0.6-0.6,1.3-1.1,2.1-1.3
									c1.8-0.6,3.3-0.2,4.3,1.3c0.3-1,0.8-1.8,1.4-2.5c0.6-0.7,1.4-1.2,2.2-1.5c2.5-0.8,4.3,0.3,5.3,3.5l0.7,2.2
									c0.2,0.9,0.4,1.7,0.7,2.4c0.8,2.4,1.6,4,2.4,5c-0.6,0.3-1.1,0.6-1.6,0.8c-0.5,0.2-1.2,0.3-2,0.4c-0.8-2.9-1.7-5.9-2.8-9.2
									c-0.3-0.9-0.7-1.5-1.2-1.8c-0.5-0.3-1.1-0.3-1.9,0c-1.1,0.4-1.9,0.9-2.6,1.7c0.3,0.9,0.7,2.1,1.2,3.8c0.1,0.5,0.3,1.1,0.6,1.9
									c0.8,2.4,1.6,4.1,2.4,5c-0.5,0.3-1.1,0.6-1.6,0.8c-0.5,0.2-1.2,0.3-2,0.4c-0.8-2.9-1.7-5.9-2.8-9.2c-0.3-0.9-0.7-1.5-1.2-1.8
									c-0.5-0.3-1.1-0.3-1.9,0c-1.1,0.4-1.9,0.9-2.5,1.7l0.5,1.5c0.3,1.2,0.8,2.6,1.3,4.2c0.8,2.4,1.6,4.1,2.4,5
									c-0.6,0.4-1.2,0.6-1.8,0.8C1644.4,944.2,1643.7,944.4,1643,944.5z"
						/>
						<path
							d="M1667.9,936.1c-0.6-2.4-1.4-4.8-2.3-7.5c-0.4-1.3-0.8-2.2-1.1-2.9c-0.3-0.6-0.6-1.1-0.9-1.5c-0.3-0.3-0.7-0.7-1.2-0.9
									c0.2-0.5,0.5-1,0.8-1.3c0.3-0.3,0.7-0.6,1-0.7c1.2-0.4,2.2,0.2,2.8,1.9c0.3-0.9,0.7-1.7,1.2-2.4c0.5-0.7,1.1-1.3,1.8-1.8
									c0.3,0.3,0.6,0.8,0.9,1.5s0.6,1.3,0.8,1.8c-0.3,0-0.6,0.1-1.1,0.3c-0.7,0.2-1.3,0.5-1.9,0.9c-0.6,0.4-1,0.8-1.3,1.2l0.3,1.1
									c0.5,1.5,0.9,2.9,1.3,4.1c0.8,2.4,1.6,4.1,2.4,5c-0.6,0.4-1.2,0.6-1.7,0.8C1669.3,935.8,1668.7,936,1667.9,936.1z"
						/>
						<path
							d="M1671.5,913.9c0.3-1.1,0.8-2,1.2-2.8c0.9,0.6,1.8,1.1,2.7,1.5c-0.3,1-0.7,1.9-1.2,2.8
									C1673.4,914.8,1672.5,914.3,1671.5,913.9z M1678.9,931.9c-0.4-0.3-0.8-1-1.2-2.1c-0.3-0.9-0.7-2.1-1.2-3.7
									c-0.5-1.5-0.8-2.6-1.1-3.3c-0.3-0.9-0.7-1.8-1.1-2.6c-0.4-0.8-0.7-1.3-1.1-1.5c0.5-0.5,1-0.8,1.5-1.1c0.5-0.3,1.1-0.6,1.8-0.8
									c0.1,0.7,0.4,1.8,0.8,3.2c0.4,1.4,0.9,3,1.5,4.7c0.4,1.3,0.8,2.2,1.1,2.9c0.3,0.6,0.6,1.1,0.9,1.5c0.3,0.3,0.7,0.6,1.2,0.9
									c-0.2,0.5-0.5,1-0.8,1.3s-0.7,0.6-1,0.7C1679.8,932.3,1679.3,932.3,1678.9,931.9z"
						/>
						<path
							d="M1687.2,929.1c-0.4-0.3-0.8-1-1.2-2.1c-0.2-0.7-0.6-1.9-1.1-3.4c-0.6-1.8-1.1-3.2-1.4-4.3c-1.3-3.7-2.4-6.3-3.4-7.7
									c0.5-0.5,1-0.8,1.5-1.1c0.5-0.3,1.1-0.6,1.8-0.8c0.7,2.9,2,7.1,3.9,12.4c0.4,1.2,0.8,2.2,1.1,2.8c0.3,0.7,0.6,1.1,0.9,1.5
									c0.3,0.3,0.7,0.6,1.2,0.9c-0.2,0.5-0.5,1-0.8,1.3c-0.3,0.3-0.7,0.6-1,0.7C1688.1,929.4,1687.6,929.4,1687.2,929.1z"
						/>
						<path
							d="M1695.5,926.2c-0.4-0.3-0.8-1-1.2-2.1c-0.3-0.7-0.6-1.9-1.1-3.4c-0.6-1.8-1.1-3.2-1.5-4.3c-1.3-3.7-2.5-6.3-3.4-7.7
									c0.5-0.5,1-0.8,1.5-1.1s1.1-0.6,1.8-0.8c0.8,2.9,2.1,7.1,4,12.4c0.4,1.2,0.8,2.2,1.1,2.8c0.3,0.7,0.6,1.1,0.9,1.5
									c0.3,0.3,0.7,0.6,1.2,0.9c-0.2,0.5-0.5,1-0.8,1.3c-0.3,0.4-0.7,0.6-1,0.7C1696.4,926.5,1695.9,926.5,1695.5,926.2z"
						/>
						<path
							d="M1714.2,919.9c-1,0.3-1.9,0.4-2.7,0.6c-0.3-1.1-0.8-2.7-1.4-4.6c-0.7-1.9-1.3-3.8-1.9-5.5c-1.4-3.8-2.5-6.1-3.2-6.8
									c0.6-0.5,1.4-1.1,2.5-1.7c1.1-0.6,2.2-1.1,3.4-1.6c2.8-1.1,5.3-1.1,7.4-0.1c2.1,1,3.7,3,4.8,5.9c0.7,1.8,0.9,3.6,0.6,5.3
									c-0.3,1.7-0.9,3.2-2.1,4.5c-1.1,1.3-2.6,2.3-4.5,3C1716.2,919.3,1715.2,919.6,1714.2,919.9z M1719.9,914.5c0.5-1,0.7-2.1,0.8-3.2
									c0.1-1.2-0.1-2.3-0.5-3.4c-1.9-5-5-6.7-9.2-5.1c-0.7,0.3-1.3,0.6-1.9,1.1c0.8,2.6,1.7,5.3,2.8,8c0.8,2.2,1.6,4,2.2,5.5
									c1.8-0.1,3.1-0.4,3.9-0.7C1718.8,916.2,1719.4,915.4,1719.9,914.5z"
						/>
						<path
							d="M1730.8,912.9c-0.8-0.4-1.4-1-1.8-1.9c-1.2-2.7,0.6-5.3,5.4-7.8c-0.6-1.5-1.3-2.4-1.9-2.8c-0.6-0.4-1.5-0.4-2.6,0.1
									c-0.6,0.3-1.2,0.7-1.8,1.2c-0.6,0.5-1,1.1-1.4,1.7l-0.5-0.5c0.1-1.2,0.5-2.4,1.2-3.4c0.7-1,1.5-1.8,2.5-2.2
									c1.3-0.6,2.6-0.7,3.6-0.2s1.9,1.4,2.6,2.9c0.4,0.8,0.7,1.7,1,2.8c0.4,1.1,0.7,1.9,0.9,2.4c0.4,0.9,0.7,1.5,1.1,1.9
									c0.4,0.4,0.9,0.8,1.6,1.1c-0.5,1.1-1.1,1.8-2,2.4c-0.7-0.2-1.4-0.6-2-1.3c-0.5,0.9-1.1,1.7-1.7,2.4s-1.3,1.2-2,1.5
									C1732.3,913.2,1731.5,913.2,1730.8,912.9z M1735,909.6c0.5-0.5,0.8-1.1,1.1-1.7l-0.1-0.2c-0.3-0.7-0.6-1.4-0.8-2.1l-0.5-1.5
									c-1.4,0.7-2.3,1.5-2.9,2.4c-0.5,0.9-0.6,1.8-0.2,2.7c0.2,0.4,0.4,0.7,0.8,1c0.3,0.3,0.7,0.5,1.1,0.5
									C1734.1,910.5,1734.6,910.1,1735,909.6z"
						/>
						<path
							d="M1746.6,905.8c-0.4-0.2-0.9-0.9-1.5-1.9c-0.4-0.7-0.9-1.7-1.7-3.2c-0.8-1.7-1.6-3-2.1-4.1c-1.9-3.5-3.4-5.8-4.6-7.1
									c0.4-0.5,0.9-1,1.3-1.4s1-0.7,1.6-1.1c1.2,2.8,3.2,6.7,5.9,11.6c0.6,1.2,1.2,2,1.6,2.6c0.4,0.6,0.8,1,1.2,1.3
									c0.4,0.3,0.8,0.5,1.4,0.7c-0.1,0.6-0.3,1-0.6,1.4c-0.3,0.4-0.6,0.7-0.9,0.8C1747.6,906,1747,906,1746.6,905.8z"
						/>
						<path
							d="M1754.5,899.8c-1.5-0.5-2.9-1.8-4.2-3.8c-0.9-1.3-1.4-2.7-1.6-4.1c-0.2-1.4-0.1-2.6,0.4-3.8c0.5-1.2,1.3-2.1,2.4-2.9
									c1.5-1,3-1.2,4.5-0.7s2.9,1.9,4.3,3.9c-0.8,0.9-1.9,2-3.2,3.1s-2.6,2.2-4,3.1c0.9,1.2,1.8,1.9,2.7,2.1c0.9,0.2,1.8,0,2.8-0.7
									c0.8-0.5,1.4-1.1,2-1.7c0.6-0.6,1-1.3,1.2-2l0.6,0.5c0,1.2-0.4,2.4-1,3.5c-0.6,1.1-1.5,2-2.5,2.7
									C1757.5,900.1,1756,900.4,1754.5,899.8z M1754.9,891.5c0.8-0.8,1.3-1.6,1.6-2.2c-0.7-1.1-1.6-1.8-2.6-2.1c-1-0.3-2-0.2-2.8,0.4
									c-0.3,0.8-0.4,1.7-0.2,2.8c0.2,1.1,0.7,2.3,1.5,3.4C1753.3,893.1,1754.1,892.3,1754.9,891.5z"
						/>
					</g>
					<g className="level_two fade location " id="erech">
						<rect className="level_two fade location " x="1573.6" y="1602.2" width="12.2" height="15.7" />
						<rect x="1383.284" y="1245.202" width="89.82" height="27.393" transform="matrix(0.999682, -0.025208, 0.025208, 0.999682, 152.991302, 386.493103)"></rect>
						<path
							d="M1588.7,1612.4c0-1.2,0.1-2.8,0.1-4.8c0-3.7-0.2-6.2-0.5-7.6c1.1,0.2,2.8,0.2,5,0.2c2.7,0,4.9-0.1,6.4-0.2
									c0,0.6-0.1,1.2-0.2,1.9c-0.1,0.7-0.3,1.2-0.5,1.4c-0.5-0.3-1.1-0.5-1.9-0.7c-0.8-0.2-1.6-0.2-2.4-0.2c-1.1,0-2.1,0.1-3,0.3
									c-0.1,1.9-0.1,3.5-0.2,5l0.8,0c0.8,0,1.4,0.1,1.8,0.1c1,0,1.7,0,2.3-0.1s1.1-0.2,1.7-0.3c0.1,0.4,0.1,0.9,0.1,1.2
									c0,0.3,0,0.7-0.1,1.3c-2.3-0.2-4-0.2-5.3-0.2c-0.6,0-1,0-1.3,0c0,2.4,0,4.1,0.1,5.1c1,0.2,2.1,0.3,3.2,0.3c0.9,0,1.8-0.1,2.7-0.2
									c0.9-0.2,1.6-0.4,2.2-0.7c0,0.6-0.1,1.2-0.2,1.9s-0.3,1.2-0.5,1.4c-1.3-0.2-3.2-0.2-5.7-0.2c-2.2,0-3.9,0.1-4.9,0.2
									C1588.6,1615.4,1588.7,1613.6,1588.7,1612.4z"
						/>
						<path
							d="M1604.4,1617.6c0.1-2.2,0.2-4.6,0.2-7.1c0-1.2,0-2.2-0.1-2.8c-0.1-0.6-0.2-1.2-0.3-1.5c-0.2-0.4-0.4-0.8-0.8-1.2
									c0.3-0.4,0.7-0.7,1.1-0.9c0.4-0.2,0.7-0.3,1.1-0.3c1.2,0,1.8,0.8,1.9,2.5c0.5-0.7,1.1-1.3,1.7-1.7c0.6-0.5,1.3-0.8,2.1-1
									c0.1,0.4,0.3,0.9,0.4,1.5s0.2,1.3,0.2,1.8c-0.2,0-0.6-0.1-1.1-0.1c-0.7,0-1.3,0.1-1.9,0.2c-0.6,0.2-1.1,0.4-1.5,0.6l0,1
									c0,1.5-0.1,2.8-0.1,3.9c0,2.3,0.2,4,0.6,5c-0.6,0.1-1.2,0.2-1.7,0.2C1605.7,1617.8,1605.1,1617.7,1604.4,1617.6z"
						/>
						<path
							d="M1615,1616.2c-0.9-1.2-1.3-2.8-1.3-5c0-1.5,0.3-2.8,0.8-3.9c0.5-1.1,1.3-2,2.2-2.7c1-0.6,2.1-1,3.3-1
									c1.6,0,2.9,0.6,3.8,1.7c0.9,1.2,1.3,2.9,1.3,5.1c-1.1,0.3-2.4,0.5-4,0.7c-1.5,0.2-3.1,0.3-4.6,0.4c0.1,1.4,0.5,2.3,1,2.9
									c0.6,0.6,1.4,0.9,2.5,0.9c0.8,0,1.6-0.1,2.4-0.3s1.4-0.5,1.9-0.9l0.2,0.6c-0.6,0.9-1.5,1.6-2.5,2.1c-1.1,0.5-2.1,0.8-3.3,0.8
									C1617.1,1617.9,1615.9,1617.4,1615,1616.2z M1619.5,1610.1c1-0.3,1.8-0.5,2.3-0.8c0-1.2-0.3-2.1-0.9-2.9c-0.6-0.8-1.4-1.1-2.3-1.1
									c-0.6,0.4-1.1,1.1-1.5,2.1c-0.4,1-0.6,2.1-0.6,3.4C1617.5,1610.5,1618.5,1610.3,1619.5,1610.1z"
						/>
						<path
							d="M1629.6,1616.2c-0.9-1.2-1.4-2.8-1.4-5c0-1.5,0.3-2.8,0.8-3.9c0.5-1.1,1.3-2,2.3-2.7c1-0.6,2.1-1,3.4-1
									c1.4,0,2.7,0.3,3.7,0.8c0,0.4-0.1,0.9-0.3,1.4c-0.2,0.6-0.4,1.1-0.7,1.5c-0.8-0.8-1.6-1.3-2.3-1.7c-0.7-0.4-1.4-0.6-1.9-0.6
									c-0.6,0.4-1.1,1-1.5,2s-0.6,2-0.6,3.2c0,1.9,0.3,3.3,0.8,4.1c0.6,0.8,1.5,1.2,2.8,1.2c1.5,0,2.8-0.4,3.8-1.2l0.2,0.6
									c-0.6,0.9-1.4,1.6-2.4,2.2c-1,0.6-2,0.8-3,0.8C1631.8,1617.9,1630.5,1617.4,1629.6,1616.2z"
						/>
						<path
							d="M1642.2,1617.6c0.1-2.8,0.2-5.7,0.2-8.5c0-3.9-0.1-6.9-0.3-9.1c1-0.4,2.1-0.7,3.4-0.7c-0.1,1-0.3,3.2-0.4,6.5
									c0.6-0.7,1.2-1.2,2-1.6c0.7-0.4,1.5-0.6,2.3-0.6c2.4,0,3.6,1.5,3.6,4.6l-0.1,2.1l-0.1,2.2c0,2.3,0.2,4,0.6,5
									c-0.6,0.1-1.1,0.2-1.6,0.2c-0.5,0-1.1-0.1-1.8-0.2c0.1-3.2,0.2-6.1,0.2-8.7c0-0.9-0.2-1.5-0.5-1.9c-0.3-0.4-0.9-0.6-1.7-0.6
									c-1.1,0-2.1,0.3-2.9,0.9c0,0.6-0.1,1.6-0.1,2.7c0,4.2,0.2,6.7,0.6,7.6c-0.6,0.1-1.1,0.2-1.7,0.2
									C1643.4,1617.8,1642.8,1617.7,1642.2,1617.6z"
						/>
					</g>
					<g className="level_two fade location " id="morthond">
						<rect x="1511.348" y="1140.592" width="98.135" height="25.092" transform="matrix(0.638512, -0.769612, 0.769612, 0.638512, -355.132019, 2102.809326)"></rect>
						<path
							d="M1503.3,1677.9c-0.9-1.4-2.3-3.3-4.2-5.7c-1-1.3-1.7-2.2-2.2-2.8c-0.5-0.6-0.9-1-1.2-1.2c0.3-0.3,0.5-0.6,0.8-0.8
									c0.3-0.2,0.6-0.4,1.1-0.7c1,0.6,2.4,1.4,4.3,2.5s3.3,1.8,4.4,2.4c-0.3-1.3-0.7-2.9-1.2-4.8s-0.9-3.5-1.3-4.8
									c0.2-0.2,0.5-0.5,0.9-0.8c0.3-0.2,0.6-0.4,1-0.6c0.4,0.6,1,1.5,1.8,2.6c0.8,1.1,1.5,2.1,2.3,3.1c1.8,2.3,2.9,3.6,3.5,4
									c-0.3,0.3-0.5,0.6-0.8,0.8c-0.3,0.2-0.7,0.5-1.1,0.7c-0.4-0.7-1-1.5-1.8-2.6c-0.8-1.1-1.5-2.1-2.3-3.1c-0.5-0.6-1-1.3-1.7-2.1
									c0.6,2.6,1.5,6,2.6,10.2c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.2,0.1-0.4,0.2c-0.9-0.6-2.2-1.3-3.9-2.3c-1.7-1-3.3-1.8-4.6-2.5
									c1,1.3,1.7,2.3,2.3,3c1.7,2.2,2.8,3.6,3.4,4c-0.1,0.2-0.3,0.4-0.5,0.6C1503.9,1677.7,1503.6,1677.8,1503.3,1677.9z"
						/>
						<path
							d="M1516.3,1666.1c-1.1-0.1-2.2-0.7-3.2-1.7c-0.7-0.7-1.2-1.5-1.5-2.4c-0.3-0.9-0.3-1.7-0.1-2.5c0.2-0.8,0.6-1.5,1.3-2.2
									c0.6-0.6,1.2-0.9,2-1.1c0.7-0.1,1.5-0.1,2.2,0.2c0.7,0.3,1.4,0.8,2.1,1.4c0.7,0.7,1.2,1.5,1.5,2.4c0.3,0.9,0.3,1.7,0.1,2.5
									c-0.2,0.8-0.7,1.6-1.3,2.2C1518.4,1665.9,1517.4,1666.2,1516.3,1666.1z M1519.6,1662.1c-0.1-0.8-0.5-1.5-1.3-2.3
									c-0.7-0.7-1.4-1.1-2.2-1.4c-0.8-0.2-1.5-0.2-2.2,0.2c-0.6,0.6-0.9,1.3-0.8,2.1c0.1,0.8,0.5,1.6,1.2,2.3c0.8,0.9,1.7,1.4,2.5,1.6
									c0.8,0.2,1.5,0,2-0.5C1519.4,1663.5,1519.7,1662.8,1519.6,1662.1z"
						/>
						<path
							d="M1526,1657.6c-1.1-1.1-2.3-2.2-3.7-3.3c-0.6-0.6-1.2-1-1.5-1.2c-0.4-0.3-0.7-0.4-1-0.5c-0.3-0.1-0.6-0.1-1-0.1
									c-0.1-0.4-0.1-0.7,0-1s0.2-0.5,0.3-0.7c0.5-0.6,1.3-0.6,2.2,0.1c-0.1-0.6-0.2-1.2-0.1-1.7c0-0.5,0.2-1.1,0.4-1.6
									c0.3,0.1,0.6,0.2,1,0.5c0.4,0.2,0.8,0.5,1.1,0.7c-0.1,0.1-0.3,0.3-0.5,0.5c-0.3,0.4-0.5,0.7-0.7,1.1c-0.2,0.4-0.3,0.7-0.3,1.1
									l0.5,0.5c0.8,0.7,1.4,1.3,2,1.8c1.2,1.1,2.2,1.7,2.9,1.9c-0.2,0.4-0.4,0.7-0.7,1C1526.7,1657,1526.4,1657.3,1526,1657.6z"
						/>
						<path
							d="M1531.9,1650.1c-0.3,0-0.8-0.2-1.3-0.6c-0.4-0.3-1-0.8-1.8-1.5c-0.9-0.7-1.7-1.4-2.3-1.8c-0.3-0.2-0.6-0.4-0.9-0.6
									c-0.4,0.5-0.6,0.7-0.8,0.8c-0.2,0.1-0.4,0.1-0.5,0c0.1-1.1,0-2.3-0.2-3.5l0.4-0.4c0.5,0.3,0.9,0.6,1.4,0.9c0.6-0.8,1-1.4,1.2-1.8
									c0.3,0.2,0.5,0.4,0.6,0.6c0.2,0.2,0.3,0.4,0.4,0.7c-0.4,0.2-0.8,0.6-1.3,1.2c0.5,0.5,1.1,0.9,1.7,1.4c1.5,1.2,2.7,2,3.6,2.4
									c0.4-0.6,0.6-1,0.8-1.4l0.5,0.2c0.2,0.5,0.2,1,0.2,1.5c-0.1,0.5-0.3,1-0.5,1.3C1532.6,1649.9,1532.2,1650.1,1531.9,1650.1z"
						/>
						<path
							d="M1536.4,1644.6c-1.5-1.3-3.1-2.5-4.7-3.7c-2.2-1.6-3.9-2.8-5.2-3.6c0.2-0.8,0.5-1.5,1.1-2.2c0.5,0.5,1.7,1.5,3.4,3
									c-0.1-0.6-0.1-1.2-0.1-1.8c0.1-0.6,0.3-1.1,0.6-1.5c1-1.4,2.4-1.4,4.1-0.1l1.1,0.9l1.2,1c1.3,1,2.3,1.6,3.1,1.8
									c-0.2,0.4-0.4,0.7-0.6,1c-0.2,0.3-0.5,0.6-0.9,0.9c-1.7-1.4-3.3-2.7-4.8-3.8c-0.5-0.4-0.9-0.5-1.3-0.5s-0.7,0.3-1,0.7
									c-0.5,0.6-0.7,1.3-0.7,2c0.3,0.3,0.8,0.7,1.5,1.2c2.3,1.7,3.8,2.7,4.5,2.8c-0.2,0.4-0.4,0.7-0.6,1
									C1537,1644,1536.7,1644.3,1536.4,1644.6z"
						/>
						<path
							d="M1543.2,1633.6c-1.1,0.1-2.3-0.2-3.4-1c-0.8-0.6-1.5-1.3-1.9-2s-0.6-1.6-0.6-2.4c0-0.8,0.3-1.6,0.8-2.4
									c0.5-0.7,1-1.1,1.7-1.4c0.7-0.3,1.4-0.4,2.2-0.3c0.8,0.1,1.6,0.4,2.3,1c0.8,0.6,1.5,1.3,1.9,2.1c0.4,0.8,0.6,1.6,0.6,2.5
									c0,0.9-0.3,1.7-0.9,2.4C1545.3,1632.9,1544.4,1633.5,1543.2,1633.6z M1545.7,1629c-0.2-0.7-0.8-1.4-1.7-2
									c-0.8-0.5-1.6-0.8-2.4-0.9c-0.8,0-1.5,0.2-2.1,0.6c-0.5,0.7-0.6,1.4-0.4,2.2c0.2,0.8,0.8,1.4,1.6,2c1,0.7,1.9,1,2.8,1
									c0.8,0,1.5-0.3,1.9-0.9C1545.8,1630.4,1545.9,1629.7,1545.7,1629z"
						/>
						<path
							d="M1551.1,1623.5c-1.3-0.9-2.7-1.8-4.2-2.7c-0.7-0.4-1.3-0.7-1.7-0.9c-0.4-0.2-0.8-0.3-1-0.3c-0.3,0-0.6,0-1,0
									c-0.1-0.3-0.2-0.7-0.2-1c0-0.3,0.1-0.5,0.2-0.7c0.4-0.7,1-0.8,1.9-0.4c-0.2-0.6-0.3-1.1-0.3-1.7s0.2-1.1,0.5-1.6
									c0.9-1.5,2.2-1.6,4.1-0.5l1.2,0.8c0.5,0.3,0.9,0.6,1.3,0.8c1.4,0.8,2.5,1.3,3.2,1.4c-0.1,0.4-0.3,0.7-0.5,1.1
									c-0.2,0.3-0.5,0.7-0.8,1c-1.6-1-3.3-2.1-5.2-3.2c-0.5-0.3-1-0.4-1.3-0.4c-0.4,0.1-0.7,0.3-0.9,0.8c-0.4,0.7-0.6,1.3-0.5,2l0.9,0.5
									c0.7,0.4,1.5,0.9,2.4,1.5c1.4,0.8,2.5,1.3,3.2,1.4c-0.1,0.4-0.3,0.8-0.5,1.1C1551.7,1622.8,1551.4,1623.2,1551.1,1623.5z"
						/>
						<path
							d="M1556.7,1611.7c-1,0.2-2.2-0.1-3.7-0.9c-1.5-0.8-2.5-1.6-3-2.5c-0.5-0.9-0.5-1.9,0.1-3c0.4-0.8,1.1-1.5,2-1.9
									c-1.4-0.6-2.5-1-3.3-1.2c0-0.5,0.1-0.9,0.2-1.2c0.1-0.4,0.3-0.7,0.5-1.1c1.7,1,4.5,2.6,8.7,4.7c0.5,0.2,0.9,0.4,1.2,0.4
									c0.3,0,0.7,0,1.2-0.1c0.2,0.6,0.2,1.3,0,1.9c-0.5,0.2-1,0.3-1.5,0.1c0.2,0.5,0.3,1.1,0.3,1.6c0,0.5-0.1,1-0.3,1.4
									C1558.5,1610.9,1557.7,1611.5,1556.7,1611.7z M1558.4,1607.8c0-0.4,0-0.8-0.1-1.2c-0.4-0.2-1.1-0.6-2.2-1.1
									c-1.1-0.6-2-1.1-2.7-1.4c-0.6,0.3-1.1,0.8-1.4,1.5c-0.3,0.7-0.3,1.3,0,1.9c0.3,0.6,1,1.2,1.9,1.7c1,0.5,1.8,0.7,2.5,0.7
									c0.7,0,1.3-0.3,1.7-0.9C1558.4,1608.6,1558.4,1608.2,1558.4,1607.8z"
						/>
					</g>
					<g className="level_one location " id="helms_deep">
						<rect x="1317.028" y="1731.567" width="84.816" height="42.977" transform="matrix(0.998043, -0.062534, 0.225514, 0.987831, -223.16127, -203.795532)"></rect>
						<path
							d="M1489.7,1444.9c-0.1-1.1-0.2-2.5-0.5-4.4s-0.5-3.6-0.8-5.3c-0.7-3.9-1.2-6.2-1.7-7.1c0.5-0.2,1.1-0.4,1.6-0.5
									c0.5-0.1,1.1-0.1,1.8-0.1c0.2,1.9,0.5,4.3,0.9,7.3c1.1-0.1,2.5-0.3,4.1-0.5c0.3,0,1.1-0.2,2.4-0.5c-0.7-3.9-1.2-6.4-1.7-7.3
									c0.5-0.2,1-0.4,1.6-0.5c0.5-0.1,1.1-0.1,1.7-0.1c0.1,1.1,0.2,2.5,0.5,4.4c0.3,1.9,0.5,3.6,0.8,5.3c0.6,3.8,1.2,6.2,1.7,7.1
									c-0.5,0.2-1,0.4-1.6,0.5c-0.5,0.1-1.1,0.1-1.7,0.1c-0.1-1.8-0.5-4.3-0.9-7.4c-1.2,0.1-2.5,0.3-3.8,0.5c-0.9,0.1-1.8,0.3-2.7,0.5
									c0.7,4,1.2,6.4,1.7,7.3c-0.5,0.2-1,0.4-1.6,0.5C1490.9,1444.9,1490.4,1444.9,1489.7,1444.9z"
						/>
						<path
							d="M1506.4,1440.8c-1-1-1.6-2.5-1.9-4.6c-0.2-1.4-0.1-2.7,0.3-3.9s1-2.1,1.8-2.8c0.8-0.7,1.8-1.2,3-1.3
									c1.6-0.2,2.9,0.2,3.8,1.2c1,1,1.6,2.6,1.9,4.8c-1,0.4-2.2,0.8-3.7,1.2c-1.5,0.4-2.9,0.7-4.3,0.9c0.3,1.3,0.7,2.2,1.4,2.7
									c0.6,0.5,1.5,0.7,2.5,0.6c0.8-0.1,1.6-0.3,2.3-0.6c0.7-0.3,1.3-0.7,1.7-1.1l0.3,0.6c-0.5,0.9-1.2,1.7-2.2,2.4
									c-0.9,0.7-2,1.1-3,1.2C1508.7,1442.2,1507.4,1441.8,1506.4,1440.8z M1509.9,1434.4c0.9-0.4,1.6-0.7,2.1-1.1
									c-0.2-1.1-0.6-2-1.3-2.7s-1.5-0.9-2.4-0.8c-0.6,0.5-1,1.2-1.2,2.2c-0.2,1-0.3,2.1-0.1,3.3
									C1508.1,1435.1,1509,1434.8,1509.9,1434.4z"
						/>
						<path
							d="M1520.5,1440.2c-0.3-0.3-0.5-1-0.6-2c-0.1-0.7-0.2-1.7-0.3-3.2c-0.1-1.6-0.2-3-0.4-4c-0.4-3.4-0.8-5.9-1.3-7.3
									c0.5-0.3,1-0.5,1.5-0.7s1-0.3,1.7-0.3c0,2.7,0.3,6.5,0.8,11.4c0.1,1.2,0.2,2,0.4,2.7c0.1,0.6,0.3,1.1,0.5,1.5
									c0.2,0.3,0.5,0.7,0.9,1c-0.3,0.4-0.6,0.7-1,1c-0.4,0.2-0.7,0.4-1,0.4C1521.2,1440.7,1520.7,1440.5,1520.5,1440.2z"
						/>
						<path
							d="M1528,1439.6c0-2.2-0.1-4.4-0.2-6.9c-0.1-1.2-0.2-2.1-0.3-2.7s-0.3-1.1-0.4-1.5s-0.4-0.7-0.8-1.1c0.3-0.4,0.6-0.7,1-1
									c0.3-0.2,0.7-0.3,1-0.3c1-0.1,1.7,0.5,1.9,1.8c0.5-0.7,1-1.2,1.7-1.6c0.7-0.4,1.4-0.6,2.1-0.7c1.7-0.1,2.8,0.6,3.3,2.1
									c0.5-0.8,1.1-1.4,1.8-1.8c0.7-0.5,1.4-0.7,2.2-0.8c2.3-0.2,3.6,1.3,3.7,4.2l0.1,2c0,0.8,0,1.5,0.1,2.2c0.1,2.2,0.5,3.8,0.9,4.8
									c-0.5,0.2-1.1,0.3-1.6,0.3c-0.5,0-1.1,0-1.8-0.1c0-2.6-0.1-5.4-0.3-8.4c-0.1-0.9-0.3-1.5-0.6-1.8c-0.3-0.4-0.9-0.5-1.6-0.5
									c-1,0.1-1.9,0.4-2.6,0.9c0.1,0.8,0.1,2,0.2,3.5c0,0.5,0,1,0.1,1.7c0.1,2.2,0.4,3.8,0.9,4.8c-0.5,0.2-1,0.3-1.5,0.3
									c-0.5,0-1.1,0-1.8-0.1c0-2.6-0.1-5.4-0.3-8.4c-0.1-0.9-0.3-1.5-0.6-1.8c-0.3-0.4-0.9-0.5-1.6-0.5c-1,0.1-1.9,0.4-2.5,0.9l0.1,1.4
									c0,1.1,0.1,2.4,0.2,3.9c0.1,2.2,0.5,3.8,0.9,4.8c-0.6,0.2-1.1,0.3-1.7,0.3C1529.3,1439.7,1528.7,1439.7,1528,1439.6z"
						/>
						<path
							d="M1548.7,1428.1c0.3-0.4,0.5-1,0.7-1.5s0.2-1.2,0.2-1.8c0-1.1-0.2-2-0.6-2.8c0.3-0.2,0.7-0.4,1.2-0.6
									c0.5-0.2,0.9-0.3,1.3-0.3l0.7,0.8c0,1.2-0.3,2.4-0.9,3.6c-0.6,1.1-1.3,2.2-2.3,3.1L1548.7,1428.1z"
						/>
						<path
							d="M1555.5,1437.4c0-0.4,0-0.8,0.1-1.2c0.1-0.4,0.2-0.8,0.4-1.2c0.4,0.5,0.9,0.9,1.5,1.2c0.6,0.3,1.3,0.4,2,0.4
									c1.9,0,2.8-0.6,2.8-1.7c0-0.5-0.2-0.9-0.7-1.2s-1.1-0.7-2-1.1c-0.9-0.4-1.6-0.8-2.1-1.1s-1-0.8-1.4-1.4c-0.4-0.6-0.6-1.3-0.6-2.1
									c0-1,0.4-1.8,1.2-2.5c0.8-0.6,1.9-0.9,3.2-1c1.8,0,3.3,0.2,4.5,0.7c0,0.3-0.1,0.8-0.3,1.4c-0.2,0.6-0.4,1.1-0.6,1.5
									c-0.7-0.7-1.4-1.3-2.1-1.7c-0.7-0.4-1.2-0.6-1.7-0.6c-0.5,0.1-0.9,0.3-1.2,0.6s-0.4,0.6-0.4,1c0,0.6,0.2,1.1,0.7,1.4
									c0.5,0.4,1.2,0.8,2.2,1.3c0.8,0.4,1.5,0.7,2,1.1c0.5,0.3,1,0.8,1.4,1.3c0.4,0.5,0.6,1.2,0.6,2c0,1.2-0.4,2.1-1.3,2.8
									c-0.9,0.7-2.1,1-3.8,1C1558.2,1438.6,1556.7,1438.2,1555.5,1437.4z"
						/>
						<path
							d="M1512.3,1462.6c-0.9,0.1-1.7,0.2-2.4,0.2c-0.2-1-0.4-2.4-0.8-4.2c-0.4-1.8-0.8-3.4-1.2-5c-0.9-3.5-1.6-5.6-2.1-6.3
									c0.6-0.4,1.3-0.8,2.3-1.2c1-0.4,2-0.8,3.1-1c2.6-0.7,4.7-0.4,6.5,0.6c1.7,1.1,2.9,3,3.6,5.6c0.4,1.7,0.4,3.2,0.1,4.7
									s-1.1,2.7-2.2,3.8c-1.1,1-2.5,1.8-4.2,2.2C1514.1,1462.3,1513.2,1462.5,1512.3,1462.6z M1517.8,1458.5c0.5-0.8,0.8-1.7,1-2.8
									c0.2-1,0.1-2-0.1-3c-1.2-4.6-3.7-6.3-7.6-5.3c-0.6,0.2-1.2,0.4-1.8,0.8c0.5,2.4,1,4.8,1.6,7.3c0.5,2,1,3.7,1.4,5
									c1.6,0,2.7,0,3.5-0.2C1516.6,1459.8,1517.3,1459.3,1517.8,1458.5z"
						/>
						<path
							d="M1527.1,1457.7c-1-1-1.7-2.5-2.1-4.5c-0.3-1.4-0.2-2.7,0.1-3.9c0.3-1.2,0.9-2.2,1.7-2.9c0.8-0.8,1.8-1.3,3-1.5
									c1.6-0.3,2.9,0,3.9,1c1,1,1.7,2.5,2.1,4.7c-1,0.5-2.2,0.9-3.6,1.4c-1.4,0.5-2.9,0.9-4.3,1.2c0.4,1.3,0.9,2.1,1.5,2.6
									c0.6,0.5,1.5,0.6,2.5,0.4c0.8-0.1,1.5-0.4,2.2-0.7c0.7-0.3,1.2-0.7,1.7-1.2l0.3,0.6c-0.5,1-1.1,1.8-2,2.5c-0.9,0.7-1.9,1.2-3,1.3
									C1529.5,1459,1528.2,1458.6,1527.1,1457.7z M1530.3,1451.1c0.9-0.4,1.6-0.8,2.1-1.2c-0.2-1.1-0.7-2-1.4-2.6
									c-0.7-0.6-1.5-0.8-2.4-0.7c-0.5,0.5-0.9,1.2-1.1,2.2c-0.2,1-0.2,2.1,0,3.3C1528.5,1451.8,1529.4,1451.5,1530.3,1451.1z"
						/>
						<path
							d="M1541,1455.3c-1-1-1.6-2.6-1.8-4.6c-0.2-1.4-0.1-2.7,0.3-3.8c0.4-1.2,1-2.1,1.8-2.8c0.8-0.7,1.9-1.2,3-1.3
									c1.6-0.2,2.9,0.2,3.8,1.2c1,1,1.6,2.6,1.8,4.8c-1,0.4-2.3,0.8-3.7,1.2c-1.5,0.4-2.9,0.7-4.3,0.9c0.3,1.3,0.7,2.2,1.3,2.7
									c0.6,0.5,1.4,0.7,2.5,0.6c0.8-0.1,1.6-0.3,2.3-0.6c0.7-0.3,1.3-0.6,1.8-1.1l0.3,0.6c-0.5,0.9-1.2,1.7-2.2,2.4s-2,1-3,1.2
									C1543.2,1456.7,1541.9,1456.3,1541,1455.3z M1544.6,1448.9c0.9-0.4,1.6-0.7,2.2-1.1c-0.2-1.1-0.6-2-1.2-2.7
									c-0.7-0.7-1.5-0.9-2.4-0.8c-0.6,0.5-1,1.2-1.2,2.2c-0.3,1-0.3,2.1-0.2,3.3C1542.7,1449.6,1543.6,1449.3,1544.6,1448.9z"
						/>
						<path
							d="M1555.1,1455.6c-0.1-2.3-0.2-4.7-0.3-7.3c-0.1-1.2-0.1-2.1-0.3-2.7c-0.1-0.6-0.2-1.1-0.4-1.5s-0.4-0.7-0.8-1.1
									c0.3-0.4,0.6-0.7,1-0.9c0.4-0.2,0.7-0.3,1-0.3c0.9,0,1.4,0.5,1.7,1.6c0.5-0.5,1.1-1,1.7-1.3c0.6-0.3,1.3-0.5,1.9-0.5
									c1.6-0.1,2.9,0.4,3.8,1.4c0.9,1,1.4,2.6,1.5,4.8c0.1,2.2-0.3,4-1.3,5.3c-1,1.3-2.5,2-4.4,2.1c-1,0.1-1.9-0.1-2.6-0.5
									c0.2,2.3,0.5,4.1,0.8,5.4c-1,0.5-2,0.8-3.3,0.8C1555.1,1459.7,1555.1,1457.9,1555.1,1455.6z M1562.4,1452.4c0.6-0.8,0.8-2,0.7-3.4
									c-0.1-1.3-0.4-2.5-0.9-3.4s-1.3-1.6-2.1-1.9c-0.5,0-1,0.1-1.5,0.3c-0.5,0.2-1,0.4-1.4,0.7c0,0.4,0,1,0,1.9
									c0.1,2.8,0.1,4.4,0.2,4.8l0.1,1.1c0.3,0.4,0.7,0.7,1.2,0.9c0.5,0.2,0.9,0.3,1.4,0.3C1561.1,1453.6,1561.9,1453.2,1562.4,1452.4z"
						/>
					</g>
					<g className="level_two fade location " id="snowbourn">
						<rect x="1575.904" y="991.78" width="102.327" height="21.818" transform="matrix(0.998864, -0.047652, 0.047652, 0.998864, -45.691139, 547.08252)"></rect>
						<path
							d="M1578,1480.1c-0.1-0.4-0.1-0.7-0.1-0.9c0-0.3,0.1-0.6,0.1-0.9c0.4,0.3,1,0.6,1.6,0.7c0.7,0.1,1.4,0.1,2.1,0
									c0.8-0.2,1.4-0.5,1.8-0.9c0.4-0.4,0.5-1,0.4-1.6c-0.1-0.6-0.4-1-0.9-1.3c-0.5-0.3-1.3-0.6-2.3-0.9c-0.9-0.3-1.6-0.6-2.1-0.8
									c-0.5-0.2-1-0.6-1.5-1c-0.4-0.5-0.7-1.1-0.9-1.8c-0.2-1,0.1-1.8,0.8-2.6c0.7-0.8,1.8-1.3,3.2-1.5c1.3-0.2,2.4-0.2,3.4,0
									c0.1,0.4,0.1,0.8,0.1,1.3s-0.1,0.9-0.2,1.2c-0.7-0.4-1.5-0.7-2.3-0.9c-0.8-0.2-1.3-0.3-1.8-0.2c-0.3,0.1-0.6,0.4-0.8,0.8
									c-0.2,0.4-0.3,0.8-0.2,1.3c0.1,0.6,0.4,1.1,1,1.4c0.5,0.3,1.3,0.6,2.3,0.9c0.9,0.3,1.5,0.5,2.1,0.8c0.5,0.2,1,0.6,1.4,1
									c0.4,0.4,0.7,1,0.8,1.7c0.2,1.2,0,2.2-0.6,3c-0.6,0.8-1.7,1.3-3.3,1.6C1580.7,1480.6,1579.3,1480.5,1578,1480.1z"
						/>
						<path
							d="M1590,1478.6c-0.1-1.6-0.3-3.2-0.6-4.9c-0.1-0.8-0.3-1.5-0.4-1.9c-0.1-0.4-0.3-0.8-0.4-1c-0.1-0.2-0.4-0.5-0.6-0.7
									c0.2-0.3,0.4-0.6,0.6-0.7c0.2-0.2,0.5-0.3,0.7-0.3c0.8-0.1,1.3,0.3,1.5,1.2c0.3-0.5,0.7-0.9,1.1-1.3c0.5-0.3,1-0.5,1.5-0.6
									c1.7-0.3,2.7,0.7,3,2.8l0.2,1.5c0,0.6,0.1,1.1,0.2,1.6c0.2,1.6,0.6,2.7,1,3.4c-0.4,0.2-0.8,0.3-1.1,0.3c-0.4,0.1-0.8,0.1-1.3,0.1
									c-0.2-1.9-0.4-3.9-0.8-6.1c-0.1-0.6-0.3-1-0.5-1.3s-0.7-0.3-1.2-0.2c-0.8,0.1-1.4,0.4-1.9,0.8l0.1,1c0.1,0.8,0.2,1.7,0.4,2.8
									c0.2,1.6,0.6,2.7,1,3.4c-0.4,0.2-0.8,0.3-1.2,0.3C1591,1478.6,1590.6,1478.6,1590,1478.6z"
						/>
						<path
							d="M1601,1475.9c-0.8-0.8-1.3-1.9-1.5-3.3c-0.1-1,0-1.9,0.3-2.8s0.8-1.5,1.4-2.1c0.7-0.5,1.4-0.8,2.3-1
									c0.8-0.1,1.5,0,2.2,0.3s1.2,0.8,1.6,1.5c0.4,0.7,0.7,1.5,0.8,2.4c0.1,1,0,1.9-0.3,2.8c-0.3,0.8-0.8,1.5-1.5,2.1
									c-0.7,0.5-1.5,0.8-2.4,1C1602.8,1477,1601.8,1476.7,1601,1475.9z M1606.1,1474.7c0.4-0.7,0.5-1.5,0.4-2.6c-0.1-1-0.4-1.8-0.9-2.4
									c-0.5-0.6-1.1-1-1.9-1.2c-0.8,0.1-1.5,0.5-1.9,1.2c-0.4,0.7-0.6,1.5-0.4,2.6c0.1,1.2,0.5,2.1,1,2.8c0.5,0.6,1.2,0.9,1.9,0.8
									C1605.1,1475.7,1605.7,1475.4,1606.1,1474.7z"
						/>
						<path
							d="M1612.1,1474l-1-2.8c-0.2-0.7-0.4-1.2-0.6-1.7c-0.3-0.6-0.5-1.2-0.8-1.7s-0.5-0.8-0.7-1c0.4-0.2,0.7-0.4,1.1-0.5
									c0.4-0.1,0.8-0.2,1.3-0.2c0.2,0.7,0.6,2.1,1.4,4.1c0.7,2.1,1.2,3.3,1.5,3.8c0.8-3.5,1.2-5.7,1.4-6.5c-0.3-0.6-0.6-1-0.8-1.2
									c0.7-0.4,1.5-0.6,2.4-0.7c0.1,0.7,0.6,2.1,1.4,4.3s1.3,3.4,1.4,3.7c0.6-2.5,1-4.3,1.2-5.3s0.3-1.8,0.2-2.2c0-0.1-0.1-0.3-0.1-0.5
									c0.5-0.2,1.2-0.4,1.9-0.4c-0.3,1-0.7,2.3-1.1,4s-0.8,3.4-1.2,5.4l-1.1,0.8c-0.4,0-0.8-0.1-1.1-0.4s-0.6-0.7-0.8-1.4
									c-0.7-2-1.2-3.4-1.5-4.2c-0.3,1.4-0.8,3.3-1.2,5.6l-1.1,0.8C1613.2,1475.8,1612.6,1475.2,1612.1,1474z"
						/>
						<path
							d="M1627.9,1474.3c-0.5-0.2-0.9-0.4-1.2-0.8l-0.5,1c-0.1,0-0.4,0-0.7-0.1c0-0.7,0-1.9-0.1-3.5c-0.1-1.6-0.1-3-0.2-4.1
									c-0.1-2.2-0.4-3.7-0.7-4.5c0.7-0.4,1.5-0.6,2.4-0.6c-0.1,1,0,2.4,0.1,4.3c0.8-0.8,1.7-1.2,2.6-1.2c1-0.1,1.9,0.3,2.6,1.1
									c0.7,0.8,1.1,1.9,1.2,3.3c0.1,1.7-0.2,3-0.8,3.9c-0.7,0.9-1.7,1.4-3,1.5C1628.9,1474.6,1628.4,1474.5,1627.9,1474.3z M1631,1472.5
									c0.4-0.6,0.5-1.4,0.5-2.4c-0.1-2.1-0.9-3.3-2.3-3.8c-0.7,0-1.4,0.3-2.1,0.7c0.1,1.5,0.1,2.5,0.2,3c0.1,1.3,0.2,2.2,0.3,2.7
									c0.5,0.5,1.1,0.7,1.7,0.7C1630,1473.3,1630.6,1473,1631,1472.5z"
						/>
						<path
							d="M1636.6,1472.8c-0.8-0.8-1.2-2-1.3-3.4c-0.1-1,0.1-1.9,0.5-2.8c0.4-0.8,0.9-1.5,1.6-1.9s1.5-0.7,2.4-0.8
									c0.8,0,1.5,0.1,2.2,0.5c0.6,0.4,1.1,0.9,1.5,1.6s0.6,1.5,0.6,2.4c0.1,1-0.1,1.9-0.5,2.8c-0.4,0.8-0.9,1.5-1.6,2
									c-0.7,0.5-1.5,0.7-2.5,0.8C1638.3,1474,1637.3,1473.7,1636.6,1472.8z M1641.7,1472c0.4-0.6,0.6-1.5,0.6-2.6c0-1-0.3-1.8-0.8-2.5
									c-0.5-0.7-1.1-1.1-1.8-1.3c-0.8,0-1.5,0.4-2,1c-0.5,0.6-0.7,1.5-0.6,2.5c0.1,1.2,0.3,2.2,0.8,2.8c0.5,0.7,1.1,1,1.8,1
									C1640.6,1473,1641.3,1472.6,1641.7,1472z"
						/>
						<path
							d="M1646.8,1470.4l0-1.5l0-1.6c0-1.5-0.2-2.6-0.4-3.3c0.7-0.3,1.5-0.5,2.4-0.5c-0.1,1.9-0.1,4.1-0.1,6.5
									c0,0.6,0.1,1.1,0.4,1.4c0.2,0.3,0.6,0.4,1.2,0.4c0.7,0,1.3-0.2,1.9-0.6c0-1,0-2,0-2.8l0-1.3c0-1.5-0.2-2.6-0.4-3.3
									c0.7-0.3,1.5-0.5,2.4-0.5c0,1.1-0.1,2.3-0.1,3.8s-0.1,2.5-0.1,2.9c0,0.6,0.1,1,0.2,1.3s0.3,0.7,0.7,1c-0.5,0.5-1.1,0.8-1.7,0.9
									c-0.4-0.3-0.7-0.8-0.9-1.4c-0.4,0.4-0.8,0.8-1.4,1c-0.5,0.3-1.1,0.4-1.6,0.4C1647.7,1473.6,1646.9,1472.6,1646.8,1470.4z"
						/>
						<path
							d="M1657.9,1473.4c0.2-1.5,0.3-3.2,0.3-5c0-0.9,0-1.5,0-2c0-0.5-0.1-0.8-0.2-1.1c-0.1-0.3-0.3-0.5-0.5-0.8
									c0.2-0.3,0.5-0.5,0.8-0.6c0.3-0.1,0.5-0.2,0.7-0.2c0.8,0,1.2,0.6,1.2,1.8c0.4-0.5,0.8-0.9,1.2-1.2c0.4-0.3,0.9-0.5,1.5-0.7
									c0.1,0.3,0.2,0.6,0.2,1.1c0.1,0.5,0.1,0.9,0.1,1.3c-0.2,0-0.4-0.1-0.7-0.1c-0.5,0-0.9,0-1.3,0.1c-0.4,0.1-0.8,0.2-1,0.4l0,0.7
									c-0.1,1-0.1,1.9-0.1,2.7c-0.1,1.6,0,2.8,0.3,3.5c-0.4,0.1-0.8,0.1-1.2,0.1C1658.8,1473.6,1658.3,1473.5,1657.9,1473.4z"
						/>
						<path
							d="M1665.1,1473.8c0.2-1.5,0.4-3.2,0.6-4.9c0.1-0.9,0.1-1.5,0.1-2c0-0.5-0.1-0.8-0.1-1.1c-0.1-0.3-0.2-0.6-0.5-0.9
									c0.3-0.3,0.5-0.5,0.8-0.6c0.3-0.1,0.5-0.2,0.7-0.1c0.8,0.1,1.2,0.6,1.2,1.5c0.4-0.4,0.9-0.8,1.4-1c0.5-0.2,1.1-0.3,1.6-0.3
									c1.7,0.1,2.4,1.3,2.2,3.4l-0.2,1.5c-0.1,0.6-0.2,1.1-0.2,1.5c-0.1,1.6-0.1,2.8,0.2,3.5c-0.4,0.1-0.8,0.1-1.2,0
									c-0.4,0-0.8-0.1-1.3-0.3c0.3-1.9,0.5-3.9,0.7-6.1c0.1-0.6,0-1.1-0.2-1.4c-0.2-0.3-0.6-0.5-1.1-0.5c-0.8-0.1-1.5,0.1-2,0.4l-0.1,1
									c-0.1,0.8-0.2,1.7-0.3,2.8c-0.1,1.6-0.1,2.8,0.1,3.5c-0.4,0.1-0.9,0.1-1.2,0C1666,1474,1665.6,1473.9,1665.1,1473.8z"
						/>
					</g>
					<g className="level_two fade location" id="eastfold">
						<rect x="2432.549" y="1245.201" width="157.951" height="27.393" transform="matrix(0.871135, 0.491044, -0.491044, 0.871135, 122.152061, -785.896545)"></rect>
						<path
							d="M1625.4,1508.2c0.8-1,1.9-2.4,3.3-4.2c2.5-3.2,4-5.5,4.6-6.9c0.8,0.9,2.2,2.1,4.2,3.6c2.4,1.8,4.3,3.2,5.7,4.1
									c-0.4,0.5-0.9,1-1.5,1.6s-1,0.8-1.4,0.9c-0.2-0.6-0.6-1.2-1.2-1.9c-0.6-0.7-1.2-1.3-1.9-1.8c-0.9-0.7-1.9-1.3-2.8-1.8
									c-1.3,1.6-2.5,3-3.5,4.2l0.6,0.5c0.6,0.6,1.1,1,1.5,1.2c0.9,0.7,1.5,1.2,2,1.5c0.5,0.3,1.1,0.6,1.7,0.9c-0.2,0.4-0.5,0.8-0.7,1.2
									c-0.2,0.3-0.5,0.6-1,1c-1.9-1.7-3.4-2.9-4.5-3.8c-0.5-0.4-0.9-0.7-1.1-0.8c-1.6,2.1-2.7,3.6-3.3,4.5c0.7,0.9,1.6,1.7,2.6,2.5
									c0.8,0.6,1.6,1.2,2.5,1.6c0.9,0.5,1.7,0.8,2.4,0.9c-0.4,0.5-0.9,1-1.5,1.6c-0.6,0.5-1,0.8-1.4,0.9c-1-1-2.6-2.4-4.8-4
									c-1.9-1.5-3.4-2.5-4.5-3.1C1623.3,1510.7,1624.6,1509.2,1625.4,1508.2z"
						/>
						<path
							d="M1647,1530.7l1.3-4.8c-0.8-0.7-1.9-1.4-3.2-2.3c-1.4-0.9-2.4-1.5-3-1.7c-1.2,0.9-2.5,1.9-3.9,3.1
									c-0.4-0.1-0.8-0.2-1.1-0.4c-0.4-0.3-0.7-0.6-1-1c1.2-0.7,3.7-2.4,7.6-5.3c3.9-2.9,6.8-5.2,8.9-6.9c0.6,0.2,1,0.5,1.3,0.7
									c0.3,0.2,0.7,0.5,1,0.9c-0.8,2.6-1.7,6.2-2.9,10.9c-1.2,4.7-1.8,7.7-2,9.1c-0.6-0.2-1.1-0.4-1.6-0.8
									C1648,1531.6,1647.5,1531.2,1647,1530.7z M1648.9,1523.6c0.9-3.3,1.6-6.2,2.1-8.4c-1.8,1.2-4.1,2.9-6.9,5.1c0.9,0.7,2,1.5,3.2,2.3
									C1648.1,1523.1,1648.6,1523.4,1648.9,1523.6z"
						/>
						<path
							d="M1655.2,1534.8c0.3-0.4,0.5-0.8,0.8-1.1c0.3-0.3,0.6-0.6,1-0.8c0.2,0.8,0.6,1.5,1.2,2.3s1.4,1.4,2.3,1.9
									c1.1,0.6,2,0.8,2.8,0.7c0.8-0.1,1.5-0.6,1.9-1.4c0.4-0.7,0.5-1.4,0.2-2.2c-0.3-0.8-0.8-1.8-1.6-3.1c-0.7-1.2-1.2-2.1-1.6-2.9
									c-0.4-0.8-0.6-1.6-0.6-2.5s0.2-1.8,0.7-2.7c0.7-1.2,1.8-1.9,3.3-2.1s3.2,0.3,5,1.3c1.6,0.9,2.9,2,3.7,3.1c-0.3,0.5-0.6,1-1.1,1.5
									c-0.5,0.5-0.9,0.9-1.4,1.1c-0.5-1.1-1-2.1-1.7-3s-1.2-1.5-1.8-1.9c-0.5-0.2-1-0.1-1.6,0.1c-0.6,0.2-1.1,0.6-1.5,1.2
									c-0.4,0.8-0.5,1.6-0.2,2.4c0.3,0.8,0.9,1.9,1.7,3.2c0.7,1.1,1.2,2,1.6,2.7s0.6,1.5,0.6,2.4c0.1,0.9-0.2,1.8-0.6,2.6
									c-0.9,1.5-2,2.4-3.4,2.7c-1.4,0.3-3.1-0.2-5.1-1.3C1657.8,1537.8,1656.3,1536.4,1655.2,1534.8z"
						/>
						<path
							d="M1675.7,1546.8c0.6-1,1.4-2.5,2.3-4.3c1-1.9,1.9-3.7,2.7-5.5c0.9-1.9,1.7-3.6,2.2-5c-2.5-1.2-4.2-1.8-5.1-1.9
									c0.1-0.5,0.3-1,0.5-1.5c0.2-0.4,0.5-0.9,0.9-1.5c0.8,0.5,1.8,1.1,3.1,1.8c1.3,0.7,2.6,1.4,3.9,2c2.9,1.4,4.8,2.1,5.7,2.2
									c-0.1,0.6-0.3,1.1-0.5,1.5c-0.2,0.4-0.5,0.8-0.9,1.4c-1.2-0.7-2.7-1.5-4.7-2.6c-1.5,2.8-2.7,5.2-3.7,7.2c-1.9,4-3,6.6-3.1,7.7
									c-0.7-0.2-1.3-0.4-1.8-0.6S1676.3,1547.3,1675.7,1546.8z"
						/>
						<path
							d="M1689.1,1553c0.6-0.9,1.3-2.2,2.1-3.9s1.7-3.3,2.4-4.8c2-4.2,3.1-7.1,3.5-8.8c1,0.7,2.6,1.5,4.8,2.6
									c2.4,1.1,4.3,1.9,5.8,2.4c-0.3,0.6-0.6,1.2-1.1,1.8c-0.4,0.6-0.8,1-1.1,1.2c-0.3-0.5-0.8-1-1.4-1.4c-0.6-0.5-1.2-0.9-1.9-1.2
									c-1.1-0.5-2.1-0.9-3.1-1.1c-1.1,2-1.9,3.7-2.5,4.9l0.7,0.4c0.6,0.4,1.1,0.6,1.4,0.8c0.9,0.4,1.6,0.7,2.1,0.9s1.1,0.3,1.8,0.5
									c-0.1,0.5-0.3,0.9-0.5,1.3c-0.2,0.4-0.4,0.8-0.7,1.2l-1.3-0.7c-1.2-0.7-2.4-1.3-3.5-1.8c-0.5-0.2-0.8-0.4-1.1-0.5
									c-2,4.2-3,6.9-3.1,8c-0.6-0.1-1.2-0.3-1.8-0.6C1690.2,1553.8,1689.7,1553.4,1689.1,1553z"
						/>
						<path
							d="M1707.7,1561.2c-0.7-1.3-1-2.7-0.9-4.3c0.1-1.6,0.6-3.2,1.4-4.8c1-1.8,2.2-3.3,3.7-4.3c1.5-1,3.1-1.6,4.8-1.7
									c1.7-0.1,3.4,0.3,5,1.1c1.5,0.8,2.6,1.8,3.3,3.1c0.7,1.3,1.1,2.8,1,4.4c-0.1,1.6-0.6,3.3-1.5,4.9c-1,1.8-2.2,3.2-3.7,4.2
									c-1.5,1-3.1,1.5-4.8,1.6c-1.7,0.1-3.4-0.3-5.1-1.2C1709.4,1563.5,1708.4,1562.4,1707.7,1561.2z M1717.1,1563.1c1.6-0.7,2.9-2,4-4
									c1-1.8,1.4-3.6,1.2-5.2c-0.2-1.6-0.9-3-2.2-4c-1-0.6-2.1-0.8-3.3-0.6c-1.1,0.1-2.2,0.6-3.2,1.3c-1,0.7-1.8,1.8-2.5,3
									c-1.1,2-1.5,3.9-1.2,5.6c0.3,1.7,1.1,3,2.6,3.8C1714,1563.7,1715.6,1563.8,1717.1,1563.1z"
						/>
						<path
							d="M1728.8,1566.6c0.6-0.9,1.5-2.3,2.6-4.2c2.3-3.8,3.6-6.3,3.8-7.3c0.6,0.2,1.2,0.5,1.7,0.8c0.5,0.3,1,0.7,1.6,1.2
									c-0.8,1.1-1.8,2.5-2.9,4.3c-1.2,1.8-2.2,3.4-3.1,4.9c-1.3,2.2-2.2,3.7-2.6,4.6c0.8,0.7,1.7,1.4,2.8,2.1c0.7,0.4,1.4,0.8,2.2,1
									c0.8,0.3,1.5,0.4,2.1,0.4c-0.4,0.6-0.8,1.2-1.4,1.8c-0.5,0.6-0.9,0.9-1.2,1c-1.2-0.9-2.6-1.9-4.3-2.9c-2.1-1.2-3.6-2.1-4.7-2.6
									C1727,1569.3,1728.2,1567.6,1728.8,1566.6z"
						/>
						<path
							d="M1741.7,1582.1c-0.8-0.7-1.5-1.3-2-1.9c0.8-0.9,1.7-2.2,2.9-3.8c1.2-1.6,2.3-3.2,3.4-4.7c2.3-3.4,3.5-5.5,3.8-6.5
									c0.8,0.2,1.7,0.5,2.8,1c1.1,0.5,2.2,1.1,3.2,1.8c2.5,1.7,3.9,3.7,4.3,6c0.4,2.3-0.3,4.7-2.1,7.3c-1.1,1.6-2.4,2.8-4,3.6
									s-3.2,1.1-4.9,0.9c-1.7-0.2-3.4-0.8-5-1.9C1743.3,1583.4,1742.5,1582.8,1741.7,1582.1z M1749.4,1583.7c1.1-0.2,2.1-0.6,3.1-1.2
									c1-0.6,1.8-1.4,2.5-2.4c3-4.4,2.6-7.9-1.1-10.5c-0.6-0.4-1.3-0.7-2-1c-1.7,2.2-3.3,4.5-5,6.9c-1.3,2-2.4,3.6-3.2,5
									c1.1,1.4,2.1,2.3,2.8,2.8C1747.4,1583.7,1748.4,1583.9,1749.4,1583.7z"
						/>
					</g>
					<g className="level_two fade location " id="celos">
						<rect x="949.025" y="949.748" width="61.623" height="20.894" transform="matrix(0.469061, 0.883166, -0.888817, 0.45842, 2309.157715, 449.995605)"></rect>
						<path
							d="M1898.2,1736.4c0.4-1.3,1.5-2.5,3.2-3.5c1.2-0.7,2.4-1.2,3.6-1.3c1.2-0.1,2.3,0,3.3,0.5c1,0.5,1.8,1.2,2.4,2.2
									c0.7,1.1,1.1,2.1,1.2,3.1c-0.3,0.2-0.7,0.3-1.1,0.5c-0.4,0.1-0.8,0.2-1.1,0.2c0.1-0.8,0.1-1.6,0-2.4c-0.1-0.8-0.3-1.4-0.5-1.7
									c-0.4-0.4-0.9-0.6-1.6-0.7c-0.7-0.1-1.4,0-2.2,0.1c-0.8,0.2-1.5,0.5-2.2,0.9c-1.4,0.9-2.3,1.8-2.6,2.8s-0.2,2,0.4,2.9
									c0.8,1.3,1.7,2.2,2.7,2.7l-0.4,0.5c-0.8-0.1-1.6-0.4-2.4-0.9c-0.8-0.5-1.4-1.1-1.8-1.8C1897.9,1739,1897.7,1737.7,1898.2,1736.4z"
						/>
						<path
							d="M1904.5,1747.3c0.4-1,1.3-1.9,2.6-2.7c0.9-0.6,1.8-0.9,2.8-1c0.9-0.1,1.8,0,2.5,0.4c0.8,0.4,1.4,0.9,1.9,1.7
									c0.6,1,0.7,2,0.3,3c-0.4,1-1.3,1.9-2.7,2.8c-0.6-0.6-1.3-1.3-2-2.2s-1.4-1.8-2-2.7c-0.8,0.6-1.3,1.2-1.5,1.8
									c-0.2,0.6,0,1.2,0.4,1.9c0.3,0.5,0.7,1,1.1,1.4c0.4,0.4,0.8,0.7,1.3,0.9l-0.3,0.4c-0.8-0.1-1.6-0.3-2.3-0.8
									c-0.7-0.5-1.3-1-1.8-1.7C1904.2,1749.3,1904.1,1748.3,1904.5,1747.3z M1910.1,1747.8c0.5,0.5,1,0.9,1.4,1.1c0.7-0.5,1.2-1,1.5-1.7
									c0.2-0.7,0.2-1.3-0.2-1.9c-0.5-0.2-1.1-0.3-1.9-0.2c-0.8,0.1-1.5,0.4-2.3,0.9C1909,1746.7,1909.5,1747.3,1910.1,1747.8z"
						/>
						<path
							d="M1910.3,1758.4c0.2-0.3,0.6-0.7,1.2-1c0.4-0.3,1.1-0.7,2.1-1.2c1.1-0.6,2-1.1,2.6-1.5c2.2-1.4,3.8-2.4,4.6-3.3
									c0.4,0.3,0.7,0.5,1,0.8c0.3,0.3,0.5,0.6,0.8,1c-1.8,0.9-4.3,2.3-7.6,4.3c-0.8,0.5-1.3,0.8-1.7,1.1s-0.7,0.6-0.8,0.8
									s-0.3,0.6-0.4,0.9c-0.4-0.1-0.7-0.2-1-0.3c-0.3-0.2-0.5-0.4-0.6-0.5C1910.2,1759.1,1910.2,1758.7,1910.3,1758.4z"
						/>
						<path
							d="M1915,1764.5c0.4-1.1,1.2-2.1,2.5-2.8c0.9-0.6,1.8-0.9,2.8-0.9c0.9-0.1,1.8,0.1,2.6,0.5c0.8,0.4,1.4,1,1.9,1.8
									c0.4,0.7,0.6,1.5,0.6,2.2c0,0.8-0.3,1.5-0.7,2.2c-0.5,0.7-1.1,1.3-1.9,1.8c-0.9,0.6-1.9,0.9-2.8,0.9s-1.8-0.1-2.6-0.5
									c-0.8-0.4-1.4-1.1-1.9-1.9C1914.7,1766.7,1914.6,1765.6,1915,1764.5z M1918.3,1768.8c0.8,0.1,1.7-0.2,2.6-0.7
									c0.9-0.5,1.5-1.2,1.9-1.9s0.5-1.5,0.3-2.3c-0.5-0.8-1.1-1.2-1.9-1.3c-0.8-0.1-1.7,0.1-2.6,0.7c-1.1,0.7-1.8,1.4-2.2,2.2
									c-0.4,0.8-0.4,1.5,0,2.2C1916.9,1768.3,1917.5,1768.7,1918.3,1768.8z"
						/>
						<path
							d="M1920.7,1774.9c0.3-0.2,0.5-0.3,0.8-0.4s0.6-0.2,0.9-0.2c-0.2,0.5-0.3,0.9-0.2,1.5s0.2,1,0.5,1.5c0.7,1.2,1.5,1.6,2.2,1.2
									c0.3-0.2,0.5-0.5,0.5-0.9c0-0.4,0-1,0-1.7c-0.1-0.7-0.1-1.3-0.1-1.8c0-0.5,0.1-1,0.3-1.4c0.2-0.5,0.6-0.9,1.2-1.2
									c0.7-0.4,1.3-0.4,2.1-0.1c0.7,0.3,1.3,0.9,1.9,1.8c0.7,1.2,1.1,2.2,1.3,3.3c-0.2,0.1-0.6,0.3-1,0.4c-0.4,0.1-0.9,0.2-1.3,0.2
									c0.2-0.7,0.3-1.4,0.3-2s-0.1-1.1-0.3-1.4c-0.3-0.3-0.5-0.4-0.8-0.5c-0.3-0.1-0.6-0.1-0.8,0.1c-0.4,0.2-0.6,0.6-0.7,1
									c-0.1,0.4-0.1,1.1,0,1.9c0.1,0.7,0.1,1.3,0.1,1.7c0,0.5-0.1,0.9-0.3,1.4c-0.2,0.5-0.6,0.8-1.1,1.1c-0.8,0.5-1.6,0.5-2.3,0.2
									s-1.5-1-2.1-2.1C1920.9,1777,1920.6,1775.9,1920.7,1774.9z"
						/>
					</g>
					<g className="level_two fade location " id="sirith">
						<rect x="965.262" y="1032.527" width="62.677" height="22.715" transform="matrix(-0.108352, 0.994113, -0.992807, -0.12033, 3096.819336, 845.083496)"></rect>
						<path
							d="M1949.9,1682.2c0.4,0.1,0.7,0.2,0.9,0.3s0.5,0.3,0.8,0.5c-0.5,0.3-0.9,0.7-1.2,1.3s-0.6,1.2-0.7,2c-0.2,0.8-0.1,1.5,0.2,2
									s0.7,0.8,1.4,0.9c0.6,0.1,1.1,0,1.5-0.4c0.5-0.4,1-1,1.7-1.8c0.6-0.7,1.1-1.3,1.5-1.7c0.4-0.4,0.9-0.7,1.5-1
									c0.6-0.2,1.2-0.3,2-0.2c1,0.2,1.7,0.8,2.1,1.7c0.4,1,0.5,2.2,0.3,3.6c-0.2,1.3-0.7,2.3-1.2,3.2c-0.4-0.1-0.8-0.2-1.2-0.4
									c-0.4-0.2-0.8-0.4-1-0.7c0.6-0.6,1.2-1.1,1.6-1.8c0.5-0.6,0.7-1.2,0.8-1.6c0-0.3-0.1-0.7-0.4-1.1c-0.3-0.4-0.6-0.6-1.1-0.7
									c-0.6-0.1-1.1,0-1.6,0.4c-0.5,0.4-1,1-1.7,1.8c-0.6,0.7-1.1,1.2-1.5,1.7c-0.4,0.4-0.9,0.7-1.5,1c-0.6,0.2-1.2,0.3-1.9,0.2
									c-1.2-0.2-2-0.8-2.5-1.7c-0.5-0.9-0.6-2.1-0.3-3.6C1948.5,1684.6,1949.1,1683.3,1949.9,1682.2z"
						/>
						<path
							d="M1947,1695.8c0.3-0.1,0.9-0.1,1.7,0c0.6,0.1,1.5,0.3,2.6,0.5c1.1,0.2,1.9,0.4,2.4,0.4c0.7,0.1,1.3,0.2,1.9,0.2
									c0.6,0,1,0,1.3-0.1c0.1,0.5,0.2,0.9,0.2,1.3c0,0.4,0,0.9-0.1,1.4c-0.5-0.2-1.2-0.3-2.2-0.6c-1-0.2-2.1-0.4-3.4-0.6
									c-0.9-0.2-1.6-0.2-2.1-0.3s-0.9,0-1.2,0.1c-0.3,0.1-0.6,0.2-1,0.4c-0.3-0.3-0.4-0.6-0.5-0.9c-0.1-0.3-0.1-0.6-0.1-0.8
									C1946.5,1696.2,1946.7,1695.9,1947,1695.8z M1960.6,1697.3c0.5,0.6,1,1.1,1.3,1.7c-0.6,0.3-1.2,0.7-1.8,1.2
									c-0.5-0.5-0.9-1.1-1.3-1.7C1959.4,1698.1,1960,1697.7,1960.6,1697.3z"
						/>
						<path
							d="M1945.6,1703.5c1.7,0.4,3.5,0.7,5.4,0.9c0.9,0.1,1.6,0.2,2.1,0.2c0.5,0,0.9,0,1.2-0.1c0.3-0.1,0.6-0.2,1-0.4
									c0.3,0.3,0.5,0.6,0.6,0.9c0.1,0.3,0.1,0.6,0.1,0.8c-0.1,0.9-0.8,1.3-2.1,1.1c0.5,0.5,0.9,1,1.1,1.5c0.3,0.5,0.5,1.1,0.5,1.7
									c-0.3,0.1-0.7,0.1-1.2,0.1c-0.5,0-1,0-1.4-0.1c0.1-0.2,0.1-0.4,0.2-0.8c0.1-0.5,0.1-1,0-1.5c-0.1-0.5-0.2-0.9-0.3-1.2l-0.8-0.1
									c-1.1-0.2-2.1-0.3-2.9-0.5c-1.8-0.3-3.1-0.3-3.9-0.1c0-0.5,0-0.9,0-1.3C1945.3,1704.5,1945.4,1704,1945.6,1703.5z"
						/>
						<path
							d="M1944.5,1713.3c0.3-0.2,0.9-0.2,1.7-0.1c0.6,0.1,1.5,0.2,2.7,0.4c1.1,0.2,1.9,0.3,2.4,0.3c0.7,0.1,1.3,0.1,1.9,0.1
									s1-0.1,1.3-0.2c0.1,0.5,0.2,0.9,0.2,1.3s0,0.9-0.1,1.4c-0.5-0.1-1.2-0.3-2.2-0.5c-1-0.2-2.2-0.3-3.4-0.5c-0.9-0.1-1.6-0.2-2.1-0.2
									c-0.5,0-0.9,0-1.2,0.1s-0.6,0.2-1,0.5c-0.3-0.3-0.5-0.6-0.6-0.9s-0.2-0.6-0.1-0.8C1944,1713.8,1944.2,1713.5,1944.5,1713.3z
									M1958.1,1714.2c0.6,0.5,1,1.1,1.4,1.6c-0.6,0.4-1.2,0.8-1.8,1.3c-0.5-0.5-1-1.1-1.4-1.7
									C1956.9,1715.1,1957.5,1714.7,1958.1,1714.2z"
						/>
						<path
							d="M1943.5,1721.5c0.3-0.2,0.9-0.2,1.6-0.2c0.5,0.1,1.4,0.2,2.6,0.3c1.3,0.2,2.3,0.3,3.2,0.4c0.4,0,0.8,0.1,1.2,0.1
									c0.1-0.6,0.2-1,0.3-1.2c0.1-0.2,0.3-0.3,0.5-0.3c0.5,1.1,1.3,2.1,2.3,3.2l-0.1,0.6c-0.6,0-1.2,0-1.8-0.1c-0.1,1.1-0.1,1.8,0,2.3
									c-0.4,0-0.7-0.1-0.9-0.2c-0.3-0.1-0.5-0.2-0.8-0.4c0.2-0.4,0.4-1.1,0.5-1.9c-0.8-0.1-1.6-0.2-2.4-0.3c-2.1-0.2-3.7-0.3-4.8-0.1
									c0,0.7,0,1.3,0.1,1.8l-0.6,0.1c-0.4-0.3-0.8-0.8-1-1.3c-0.2-0.5-0.3-1-0.3-1.6C1943,1722,1943.2,1721.7,1943.5,1721.5z"
						/>
						<path
							d="M1942.5,1729.6c2.2,0.3,4.3,0.5,6.5,0.7c3,0.2,5.3,0.3,6.9,0.3c0.3,0.8,0.4,1.7,0.3,2.6c-0.8-0.2-2.4-0.4-4.9-0.7
									c0.5,0.5,0.8,1,1.1,1.6c0.3,0.6,0.4,1.2,0.3,1.8c-0.1,1.8-1.4,2.7-3.7,2.5l-1.6-0.2l-1.7-0.2c-1.8-0.1-3.1-0.1-3.9,0.2
									c-0.1-0.4-0.1-0.9-0.1-1.3c0-0.4,0.1-0.8,0.3-1.4c2.4,0.3,4.6,0.5,6.7,0.7c0.7,0.1,1.2,0,1.5-0.3c0.3-0.2,0.5-0.6,0.5-1.2
									c0.1-0.8-0.1-1.6-0.5-2.3c-0.5-0.1-1.2-0.1-2.1-0.2c-3.2-0.2-5.1-0.2-5.9,0.1c-0.1-0.4-0.1-0.9-0.1-1.3
									C1942.3,1730.6,1942.4,1730.1,1942.5,1729.6z"
						/>
					</g>
					<g className="level_two fade location" id="lossarnach">
						<rect x="1970.397" y="1220.114" width="127.942" height="26.842" transform="matrix(0.519211, -0.854646, 0.84839, 0.529508, -103.672142, 2836.584717)"></rect>
						<path
							d="M1970.4,1804.5c-0.8-0.5-2.1-1.1-3.7-1.9c-3.4-1.7-5.6-2.6-6.5-2.7c0.1-0.5,0.3-1,0.6-1.5c0.2-0.4,0.5-0.9,0.9-1.5
									c1,0.6,2.2,1.3,3.8,2.2c1.6,0.9,3,1.6,4.4,2.3c1.9,1,3.3,1.6,4.1,1.9c0.6-0.7,1.1-1.6,1.6-2.6c0.3-0.6,0.6-1.3,0.8-2
									c0.2-0.7,0.3-1.3,0.3-1.9c0.6,0.3,1.1,0.6,1.6,1c0.5,0.4,0.8,0.7,1,1c-0.7,1.1-1.5,2.4-2.3,4c-1,1.9-1.6,3.3-1.9,4.3
									C1972.8,1805.9,1971.2,1805,1970.4,1804.5z"
						/>
						<path
							d="M1979.5,1795.2c-1.5,0.3-3.1,0-4.7-0.9c-1.2-0.7-2.1-1.5-2.8-2.5s-1-2.1-1.1-3.2c0-1.1,0.2-2.3,0.9-3.4
									c0.5-1,1.2-1.7,2.1-2.2s1.8-0.7,2.9-0.7c1.1,0.1,2.1,0.4,3.2,1c1.2,0.7,2.1,1.5,2.8,2.5s1,2.1,1,3.3c0,1.2-0.3,2.3-0.9,3.5
									C1982.2,1794,1981,1794.9,1979.5,1795.2z M1982.3,1788.6c-0.4-0.9-1.2-1.8-2.5-2.5c-1.1-0.6-2.2-0.9-3.3-0.9s-2,0.4-2.8,1.2
									c-0.6,1-0.6,2-0.3,3c0.4,1,1.2,1.8,2.4,2.5c1.4,0.8,2.7,1.1,3.8,1c1.1-0.1,1.9-0.6,2.4-1.5
									C1982.6,1790.5,1982.7,1789.6,1982.3,1788.6z"
						/>
						<path
							d="M1986.2,1784.6c-0.3-0.2-0.6-0.4-0.9-0.7c-0.3-0.2-0.5-0.6-0.8-1c0.6-0.1,1.2-0.3,1.8-0.7c0.6-0.4,1-0.9,1.4-1.5
									c1-1.6,1-2.7,0.1-3.3c-0.4-0.2-0.8-0.3-1.3,0c-0.5,0.2-1.1,0.6-1.9,1.1c-0.8,0.5-1.5,0.9-2,1.2c-0.6,0.3-1.2,0.5-1.9,0.5
									c-0.7,0-1.4-0.1-2.1-0.6c-0.8-0.5-1.3-1.3-1.4-2.3s0.2-2.1,0.9-3.2c0.9-1.5,1.9-2.7,3-3.5c0.3,0.2,0.6,0.5,1,0.9
									c0.4,0.4,0.7,0.9,0.9,1.3c-1,0.3-1.8,0.6-2.5,0.9c-0.7,0.4-1.2,0.7-1.4,1.1c-0.2,0.5-0.2,0.9-0.1,1.3c0.1,0.4,0.3,0.7,0.6,0.9
									c0.5,0.3,1,0.3,1.6,0.1c0.5-0.2,1.3-0.6,2.2-1.2c0.7-0.5,1.4-0.9,1.9-1.2s1.1-0.4,1.8-0.5c0.6,0,1.3,0.1,1.9,0.5
									c1,0.6,1.5,1.4,1.6,2.5s-0.3,2.3-1.1,3.7C1988.5,1782.9,1987.5,1784,1986.2,1784.6z"
						/>
						<path
							d="M1992.1,1775c-0.3-0.2-0.6-0.5-0.9-0.7c-0.3-0.3-0.5-0.6-0.7-1c0.6,0,1.3-0.2,1.8-0.6c0.6-0.4,1.1-0.8,1.5-1.4
									c1-1.6,1.1-2.7,0.2-3.3c-0.4-0.2-0.8-0.3-1.3-0.1c-0.5,0.2-1.1,0.5-2,1.1c-0.8,0.5-1.5,0.9-2.1,1.2c-0.6,0.3-1.2,0.4-1.9,0.4
									s-1.4-0.2-2-0.6c-0.8-0.5-1.2-1.3-1.3-2.3c-0.1-1,0.3-2.1,1-3.2c1-1.5,2-2.6,3.1-3.4c0.3,0.2,0.6,0.5,1,0.9
									c0.4,0.5,0.7,0.9,0.9,1.4c-1,0.2-1.8,0.5-2.5,0.9s-1.2,0.7-1.4,1.1c-0.2,0.5-0.2,0.9-0.2,1.3c0.1,0.4,0.2,0.7,0.5,0.9
									c0.5,0.3,1,0.4,1.5,0.2s1.3-0.6,2.2-1.1c0.8-0.5,1.4-0.8,2-1.1c0.6-0.3,1.2-0.4,1.8-0.4c0.6,0,1.3,0.2,1.9,0.6
									c1,0.6,1.5,1.5,1.5,2.6c0.1,1.1-0.4,2.3-1.3,3.7C1994.5,1773.4,1993.3,1774.5,1992.1,1775z"
						/>
						<path
							d="M1999.1,1765c-0.7,0.1-1.4-0.1-2.1-0.6c-2-1.4-1.8-4.2,0.6-8.2c-1.1-0.8-2-1.2-2.7-1.2c-0.7,0-1.3,0.4-1.9,1.2
									c-0.4,0.5-0.6,1.1-0.8,1.7c-0.2,0.6-0.3,1.3-0.3,1.9l-0.6-0.1c-0.4-1-0.6-2-0.6-3.1c0.1-1.1,0.4-2,0.9-2.8
									c0.7-1.1,1.6-1.7,2.6-1.8c1-0.2,2,0.2,3.1,0.9c0.6,0.4,1.3,1,2,1.6c0.8,0.6,1.3,1.1,1.7,1.4c0.6,0.5,1.2,0.7,1.6,0.9
									s1,0.1,1.7,0.1c0.1,1,0,1.9-0.4,2.7c-0.6,0.2-1.3,0.2-2-0.1c0,0.9-0.1,1.7-0.3,2.5c-0.2,0.8-0.5,1.5-0.9,2
									C2000.4,1764.6,1999.8,1764.9,1999.1,1765z M2000.8,1760.6c0.1-0.6,0.2-1.2,0.1-1.7l-0.1-0.1c-0.5-0.4-1-0.8-1.5-1.2l-1-0.8
									c-0.7,1.2-1.1,2.2-1.1,3.1c0,0.9,0.3,1.6,1,2.1c0.3,0.2,0.6,0.3,1,0.4c0.4,0.1,0.7,0,1.1-0.1
									C2000.5,1761.8,2000.7,1761.2,2000.8,1760.6z"
						/>
						<path
							d="M2007.4,1754.3c-1.6-1.3-3.3-2.7-5.2-4.1c-0.9-0.7-1.6-1.2-2.2-1.5c-0.5-0.3-1-0.5-1.3-0.6c-0.4-0.1-0.8-0.1-1.3,0
									c-0.1-0.5-0.1-0.9-0.1-1.3c0.1-0.4,0.2-0.7,0.4-1c0.7-0.9,1.6-0.9,2.9-0.1c-0.2-0.8-0.3-1.6-0.3-2.3c0-0.7,0.1-1.5,0.4-2.2
									c0.3,0.1,0.8,0.3,1.4,0.5c0.6,0.3,1.1,0.6,1.5,0.9c-0.2,0.2-0.4,0.4-0.6,0.8c-0.4,0.5-0.7,1-0.9,1.6c-0.2,0.6-0.3,1-0.4,1.5
									l0.8,0.6c1.1,0.8,2.1,1.6,2.9,2.2c1.8,1.3,3.1,2,4.1,2.3c-0.3,0.6-0.5,1-0.8,1.4S2007.9,1753.8,2007.4,1754.3z"
						/>
						<path
							d="M2013.3,1746.2c-1.6-1.3-3.4-2.6-5.3-3.9c-0.9-0.6-1.7-1.1-2.2-1.4c-0.5-0.3-1-0.5-1.4-0.5c-0.4-0.1-0.8-0.1-1.3,0
									c-0.1-0.5-0.2-0.9-0.1-1.3c0.1-0.4,0.2-0.7,0.3-1c0.6-0.9,1.5-1,2.6-0.4c-0.2-0.8-0.3-1.6-0.2-2.3c0.1-0.8,0.4-1.5,0.8-2.1
									c1.3-1.9,3.2-2.1,5.5-0.4l1.6,1.2c0.6,0.5,1.1,0.9,1.7,1.3c1.8,1.2,3.1,1.9,4.2,2.1c-0.2,0.5-0.5,1-0.7,1.4
									c-0.3,0.4-0.7,0.9-1.2,1.4c-2-1.5-4.2-3.1-6.6-4.8c-0.7-0.5-1.3-0.7-1.7-0.6c-0.5,0.1-0.9,0.4-1.3,1c-0.6,0.9-0.9,1.8-1,2.6
									l1.1,0.8c0.8,0.7,1.8,1.4,3,2.2c1.8,1.2,3.2,2,4.2,2.2c-0.2,0.6-0.5,1.1-0.8,1.5C2014.1,1745.3,2013.8,1745.7,2013.3,1746.2z"
						/>
						<path
							d="M2021.3,1733.6c-0.7,0.1-1.4-0.1-2.2-0.5c-2.1-1.4-2-4.1,0.3-8.2c-1.1-0.7-2-1.1-2.7-1.1c-0.7,0-1.3,0.5-1.8,1.3
									c-0.3,0.5-0.6,1.1-0.8,1.7c-0.2,0.6-0.2,1.3-0.2,1.9l-0.6-0.1c-0.5-0.9-0.7-2-0.7-3c0-1.1,0.3-2,0.8-2.8c0.7-1.1,1.5-1.7,2.5-1.9
									c1-0.2,2,0.1,3.2,0.8c0.6,0.4,1.3,0.9,2,1.5c0.8,0.6,1.4,1,1.8,1.3c0.7,0.4,1.2,0.7,1.7,0.8c0.5,0.1,1,0.1,1.7,0
									c0.2,1,0,1.9-0.3,2.7c-0.6,0.2-1.3,0.3-2.1,0c0,0.9,0,1.7-0.2,2.6c-0.2,0.8-0.4,1.5-0.8,2.1
									C2022.5,1733.2,2022,1733.5,2021.3,1733.6z M2022.8,1729.2c0.1-0.6,0.1-1.2,0.1-1.7l-0.1-0.1c-0.6-0.4-1.1-0.7-1.6-1.1l-1.1-0.8
									c-0.7,1.2-1,2.2-1,3.1c0,0.9,0.4,1.6,1.1,2c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7,0,1.1-0.2C2022.6,1730.3,2022.7,1729.8,2022.8,1729.2
									z"
						/>
						<path
							d="M2027.9,1721.7c-1.4,0.2-2.9-0.3-4.6-1.3c-1.2-0.7-2.1-1.6-2.7-2.6s-1-2.1-1-3.2c0-1.1,0.3-2.2,0.9-3.2
									c0.7-1.2,1.6-2.1,2.5-2.6c0.3,0.2,0.6,0.5,1,0.9c0.4,0.4,0.7,0.9,0.9,1.3c-1,0.3-1.9,0.7-2.6,1.1s-1.2,0.8-1.4,1.2
									c0,0.7,0.3,1.4,0.8,2.3c0.6,0.8,1.3,1.5,2.2,2.1c1.5,0.9,2.8,1.4,3.7,1.3c0.9-0.1,1.7-0.6,2.4-1.7c0.8-1.2,1.1-2.5,0.9-3.8
									l0.6,0.1c0.4,0.9,0.6,1.9,0.6,3c-0.1,1.1-0.3,2-0.8,2.9C2030.4,1720.8,2029.3,1721.5,2027.9,1721.7z"
						/>
						<path
							d="M2035.1,1712.5c-2.2-1.4-4.5-2.8-6.8-4.1c-3.1-1.8-5.6-3.1-7.5-4c0.1-1.1,0.5-2.1,1.1-3.2c0.8,0.6,2.5,1.7,5,3.4
									c-0.3-0.8-0.4-1.6-0.3-2.4c0-0.8,0.2-1.5,0.6-2.2c1.2-2,3-2.3,5.5-0.9l1.7,1l1.8,1.1c1.9,1.1,3.3,1.7,4.3,1.8
									c-0.2,0.5-0.4,1-0.6,1.5c-0.2,0.4-0.6,0.9-1,1.4c-2.5-1.6-4.8-3-7-4.2c-0.7-0.4-1.3-0.6-1.8-0.5c-0.5,0.1-0.9,0.5-1.3,1.1
									c-0.5,0.9-0.8,1.9-0.7,2.8c0.5,0.3,1.2,0.8,2.2,1.3c3.4,1.9,5.5,2.9,6.5,3c-0.2,0.5-0.4,1-0.7,1.5
									C2035.8,1711.5,2035.5,1712,2035.1,1712.5z"
						/>
					</g>
					<g className="level_two fade location" id="pelargir">
						<rect x="1431.439" y="1233.154" width="92.948" height="27.129" transform="matrix(-0.422732, 0.906255, -0.901161, -0.433652, 3763.161865, 1054.328491)"></rect>
						<path
							d="M2002.8,1895.9c-0.9-0.8-2.2-1.9-3.9-3.2c-1.7-1.3-3.2-2.6-4.8-3.7c-3.2-2.4-5.3-3.8-6.3-4c0.2-0.7,0.6-1.6,1.1-2.6
									c0.5-1,1.2-2,1.9-3c1.4-1.8,2.9-2.9,4.5-3.2s3.3,0.2,4.9,1.4c1.1,0.8,1.9,1.8,2.4,3c0.5,1.2,0.7,2.4,0.5,3.6
									c-0.2,1.3-0.7,2.5-1.6,3.6c-0.5,0.7-1.1,1.3-1.7,1.8c2.6,1.9,4.3,3,5.2,3.3c-0.2,0.6-0.6,1.1-1,1.7
									C2003.8,1894.9,2003.3,1895.4,2002.8,1895.9z M2000.5,1882.8c-0.4-1-1.1-1.8-2-2.5c-2.3-1.7-4.4-1.4-6.2,1
									c-0.4,0.5-0.7,1.1-0.9,1.6c2.1,1.7,4.2,3.5,6.5,5.2c1.4-1,2.4-1.8,2.9-2.5C2001,1884.8,2000.9,1883.8,2000.5,1882.8z"
						/>
						<path
							d="M2011.1,1882.3c-1.3,0.1-2.8-0.3-4.4-1.4c-1.1-0.7-2-1.5-2.6-2.5s-0.9-1.9-1-3s0.2-2,0.8-3c0.8-1.3,1.8-1.9,3.1-2
									c1.3-0.1,2.8,0.4,4.5,1.5c-0.3,1-0.7,2.1-1.3,3.4c-0.6,1.3-1.2,2.5-1.9,3.7c1.1,0.5,2,0.8,2.7,0.6c0.7-0.1,1.4-0.6,1.9-1.5
									c0.4-0.6,0.7-1.3,0.9-2c0.2-0.7,0.3-1.3,0.2-1.9l0.6,0.1c0.4,0.9,0.5,1.9,0.4,3c-0.1,1.1-0.4,2-0.9,2.9
									C2013.4,1881.5,2012.4,1882.2,2011.1,1882.3z M2008.5,1875.9c0.3-0.9,0.4-1.6,0.5-2.2c-0.9-0.5-1.8-0.8-2.7-0.7
									c-0.9,0.1-1.5,0.5-2,1.2c0,0.7,0.3,1.4,0.9,2.1c0.6,0.8,1.3,1.4,2.3,2C2007.9,1877.7,2008.3,1876.8,2008.5,1875.9z"
						/>
						<path
							d="M2019.4,1870.1c-0.4,0-1-0.2-1.9-0.6c-0.5-0.3-1.4-0.8-2.6-1.4c-1.3-0.7-2.4-1.3-3.3-1.8c-2.9-1.5-5-2.4-6.4-2.7
									c0-0.6,0.1-1.1,0.3-1.5s0.3-0.9,0.6-1.5c2.1,1.3,5.2,3.1,9.3,5.2c1,0.5,1.7,0.9,2.3,1.1c0.6,0.2,1,0.3,1.4,0.4
									c0.4,0,0.8,0,1.3-0.1c0.2,0.4,0.3,0.8,0.3,1.2c0,0.4-0.1,0.7-0.2,1C2020.1,1869.8,2019.8,1870.1,2019.4,1870.1z"
						/>
						<path
							d="M2022.7,1862.9c-0.7,0.2-1.4,0.1-2.1-0.2c-2.2-1-2.6-3.5-1.1-7.6c-1.2-0.5-2.1-0.7-2.7-0.6c-0.6,0.1-1.1,0.6-1.5,1.5
									c-0.2,0.5-0.4,1.1-0.4,1.7c0,0.6,0,1.2,0.1,1.8l-0.6,0c-0.6-0.8-1-1.7-1.2-2.7c-0.2-1-0.1-1.9,0.3-2.7c0.5-1.1,1.2-1.8,2.1-2.1
									c0.9-0.3,1.9-0.2,3.1,0.3c0.7,0.3,1.4,0.6,2.2,1.1c0.9,0.4,1.5,0.8,1.9,0.9c0.7,0.3,1.3,0.5,1.7,0.5c0.4,0,1-0.1,1.6-0.2
									c0.3,0.9,0.4,1.7,0.2,2.5c-0.5,0.3-1.2,0.4-1.9,0.3c0.2,0.8,0.3,1.6,0.3,2.4c0,0.8-0.1,1.5-0.4,2
									C2023.9,1862.3,2023.4,1862.7,2022.7,1862.9z M2023.5,1858.6c0-0.6-0.1-1.1-0.2-1.6l-0.1-0.1c-0.6-0.3-1.1-0.5-1.7-0.8l-1.2-0.6
									c-0.4,1.2-0.6,2.2-0.4,3s0.6,1.4,1.4,1.7c0.3,0.1,0.7,0.2,1,0.2c0.4,0,0.7-0.1,1-0.3C2023.4,1859.7,2023.5,1859.2,2023.5,1858.6z"
						/>
						<path
							d="M2028.7,1849.5c-1.8-0.8-3.8-1.5-6-2.3c-1-0.4-1.8-0.6-2.4-0.7c-0.6-0.1-1-0.2-1.4-0.2s-0.8,0.1-1.2,0.3
									c-0.2-0.4-0.4-0.8-0.4-1.2c-0.1-0.4,0-0.7,0.1-1c0.3-1,1.2-1.3,2.6-0.8c-0.4-0.7-0.8-1.3-1-2c-0.2-0.7-0.3-1.3-0.2-2.1
									c0.3,0,0.8,0,1.4,0.1c0.6,0.1,1.1,0.2,1.6,0.4c-0.1,0.2-0.2,0.5-0.4,0.9c-0.2,0.6-0.3,1.1-0.3,1.7c0,0.6,0,1,0.1,1.4l0.9,0.3
									c1.2,0.5,2.3,0.9,3.3,1.2c2,0.7,3.5,1,4.4,1c-0.1,0.6-0.2,1.1-0.3,1.5C2029.3,1848.4,2029,1848.9,2028.7,1849.5z"
						/>
						<path
							d="M2035.6,1841.9c-0.7-0.2-1.3-0.5-1.9-1c-0.6-0.4-1.1-1-1.5-1.6c-0.5,0.6-1.1,0.8-1.9,0.6c-0.5-0.2-1-0.5-1.3-1
									c-0.3-0.5-0.5-1.1-0.6-1.8c-0.5,0.5-1.1,0.9-1.8,1c-0.7,0.2-1.5,0.1-2.3-0.1c-1.3-0.4-2.3-1.1-2.9-2.3c-0.6-1.1-0.7-2.4-0.3-3.7
									c0.3-0.9,0.7-1.6,1.3-2.2c0-0.4-0.1-0.9-0.2-1.4c-0.2-0.5-0.4-1.1-0.7-1.7c0.4-0.1,0.8-0.1,1.3-0.1c0.5,0,0.9,0,1.2,0.1
									c0.2,0.7,0.1,1.4,0,2.3c0.8-0.1,1.6-0.1,2.4,0.1c0.9,0.3,1.6,0.7,2.2,1.3c0.6,0.6,0.9,1.3,1.1,2.2c0.2,0.9,0.1,1.7-0.2,2.7
									c-0.2,0.8-0.3,1.4-0.1,1.9s0.4,0.8,0.9,1l1.8-4.9c0.4-0.9,0.9-1.6,1.5-2c0.6-0.4,1.3-0.4,2.2-0.2c0.8,0.2,1.4,0.7,1.9,1.3
									c0.5,0.7,0.8,1.5,0.9,2.4c0.1,0.9,0,1.9-0.3,3C2037.6,1839.7,2036.7,1841,2035.6,1841.9z M2028.3,1832.6c-0.3-0.5-0.8-0.9-1.6-1.2
									c-0.7-0.2-1.3-0.2-1.9,0.1s-1.1,0.7-1.5,1.3c-0.2,0.7-0.1,1.4,0.2,2s0.9,1,1.7,1.3c0.7,0.2,1.4,0.2,2-0.1c0.6-0.3,1-0.8,1.2-1.5
									C2028.6,1833.8,2028.6,1833.1,2028.3,1832.6z M2036.6,1834.2c-0.1-0.7-0.5-1.1-1-1.2c-0.4-0.1-0.7-0.1-1,0.1
									c-0.3,0.2-0.5,0.6-0.7,1.2l-1.4,3.9c0.5,0.4,0.9,0.8,1.4,1c1.2-0.4,2-1.3,2.4-2.6C2036.7,1835.7,2036.8,1834.9,2036.6,1834.2z"
						/>
						<path
							d="M2019.7,1823.1c-0.6-0.7-1-1.4-1.4-2.1c0.8-0.3,1.5-0.7,2.2-1.2c0.5,0.7,1,1.4,1.4,2.1
									C2021.2,1822.2,2020.5,1822.6,2019.7,1823.1z M2035.4,1826.3c-0.4,0.1-1.1,0.1-1.9-0.2c-0.7-0.2-1.7-0.5-3-0.9
									c-1.2-0.4-2.1-0.6-2.7-0.8c-0.8-0.2-1.5-0.4-2.2-0.4c-0.7-0.1-1.2-0.1-1.5,0c-0.1-0.6-0.1-1.1-0.1-1.6c0-0.5,0.1-1,0.3-1.6
									c0.5,0.2,1.4,0.5,2.5,0.9c1.2,0.4,2.5,0.7,3.9,1.1c1.1,0.3,1.9,0.5,2.5,0.5c0.6,0.1,1,0.1,1.4,0.1c0.4,0,0.8-0.2,1.2-0.4
									c0.3,0.4,0.4,0.8,0.5,1.1c0.1,0.4,0.1,0.7,0,1C2036.1,1825.9,2035.8,1826.2,2035.4,1826.3z"
						/>
						<path
							d="M2037.7,1818.7c-1.9-0.6-4-1.2-6.2-1.7c-1.1-0.3-1.9-0.4-2.5-0.5c-0.6-0.1-1-0.1-1.4,0c-0.4,0.1-0.8,0.2-1.2,0.4
									c-0.3-0.4-0.5-0.8-0.6-1.1c-0.1-0.4-0.1-0.7,0-1c0.2-1,1.1-1.4,2.5-1.1c-0.5-0.6-0.9-1.2-1.2-1.9c-0.3-0.6-0.4-1.3-0.4-2
									c0.3-0.1,0.8-0.1,1.4,0s1.2,0.1,1.6,0.2c-0.1,0.2-0.2,0.5-0.3,0.9c-0.1,0.6-0.2,1.1-0.2,1.7s0.1,1,0.2,1.4l0.9,0.2
									c1.3,0.3,2.4,0.6,3.4,0.9c2,0.5,3.5,0.7,4.5,0.5c0,0.6-0.1,1.1-0.2,1.5C2038.1,1817.6,2037.9,1818.1,2037.7,1818.7z"
						/>
					</g>
					<g className="level_one location " id="ithilien">
						<rect x="1993.872" y="1186.966" width="129.469" height="26.111" transform="matrix(-0.035399, -0.999373, 0.999373, -0.035399, 962.681152, 3681.270996)"></rect>
						<rect x="803.986" y="1250.029" width="52.205" height="27.501" transform="matrix(-0.077465, 0.996995, -0.996062, -0.089479, 3396.511963, 1110.417236)"></rect>
						<rect x="803.986" y="1458.553" width="52.205" height="32.089" transform="matrix(-0.468095, 0.883678, -0.878037, -0.478744, 3744.364014, 1836.243896)"></rect>
						<rect x="803.986" y="1458.553" width="52.205" height="32.089" transform="matrix(-0.540071, 0.84162, -0.835111, -0.550213, 3714.557373, 2020.388428)"></rect>
						<rect x="1078.389" y="1484.571" width="70.024" height="32.662" transform="matrix(-0.873594, 0.486655, -0.476128, -0.879459, 3684.121094, 2720.209473)"></rect>
						<path
							d="M1973.4,1968.8c-0.3-0.6-0.5-1.1-0.6-1.6c-0.1-0.5-0.2-1-0.2-1.6c0.9,0.4,2,0.5,3.2,0.4c1.2-0.1,2.4-0.5,3.7-1.1
									c1.4-0.7,2.3-1.5,2.8-2.4c0.5-0.9,0.4-2-0.1-3.1c-0.4-0.9-1.2-1.5-2.2-1.8c-1-0.3-2.5-0.4-4.4-0.5c-1.7-0.1-3.1-0.2-4.2-0.4
									c-1.1-0.2-2.1-0.6-3.1-1.2c-1-0.6-1.8-1.5-2.4-2.7c-0.8-1.6-0.7-3.3,0.2-5s2.6-3.1,5-4.2c2.2-1,4.2-1.6,6-1.6
									c0.3,0.6,0.5,1.4,0.7,2.3c0.2,0.9,0.2,1.6,0.1,2.2c-1.5-0.3-3-0.5-4.4-0.5c-1.4,0-2.5,0.2-3.2,0.5c-0.5,0.4-0.9,1-1.1,1.8
									c-0.3,0.8-0.2,1.6,0.2,2.4c0.5,1,1.3,1.7,2.3,1.9c1.1,0.3,2.6,0.5,4.5,0.6c1.6,0.1,3,0.2,4,0.4c1,0.2,2,0.5,3,1.1
									c1,0.6,1.7,1.4,2.3,2.6c1,2,1,3.8,0.3,5.5s-2.4,3.2-5,4.4C1978.3,1968.4,1975.8,1968.9,1973.4,1968.8z"
						/>
						<path
							d="M1988.7,1959.6c0.2-1.5,0.5-3,1-4.5c1.2,0.5,2.6,1,4.4,1.4c-0.2,1.7-0.6,3.2-1.1,4.5
									C1991.6,1960.4,1990.2,1959.9,1988.7,1959.6z"
						/>
						<path
							d="M2003.7,1953.7c-0.7-1.3-1.8-3.2-3.3-5.5c-1.5-2.3-2.9-4.5-4.2-6.5c-3.2-4.7-5.4-7.5-6.5-8.3c0.6-0.6,1.2-1.1,1.8-1.5
									c0.6-0.4,1.4-0.8,2.3-1.2c0.7,1.3,1.8,3.1,3.3,5.4s2.8,4.4,4.2,6.4c3.2,4.7,5.4,7.5,6.5,8.4c-0.5,0.6-1.2,1.1-1.8,1.6
									C2005.3,1953,2004.6,1953.3,2003.7,1953.7z"
						/>
						<path
							d="M2018,1942.7c-0.7-0.2-1.4-0.8-2.3-1.8c-0.6-0.7-1.6-2-2.9-3.7c-1.5-1.9-2.7-3.4-3.7-4.6c-0.5-0.6-1-1.1-1.4-1.6
									c-0.9,0.7-1.5,1.2-1.9,1.3c-0.4,0.1-0.8,0-1-0.3c0.7-2.1,1.1-4.4,1.1-7l0.9-0.6c0.8,0.7,1.5,1.5,2.2,2.4c1.5-1.3,2.5-2.2,3-3
									c0.4,0.5,0.7,1,1,1.4c0.2,0.5,0.4,1,0.5,1.5c-0.9,0.3-1.9,0.9-3.1,1.9c0.8,1.1,1.7,2.3,2.7,3.5c2.4,2.9,4.4,5.1,6,6.3
									c1-0.9,1.7-1.7,2.1-2.4l0.9,0.7c0.2,1,0,2-0.3,3c-0.4,1-0.9,1.8-1.6,2.4C2019.4,1942.7,2018.6,1942.9,2018,1942.7z"
						/>
						<path
							d="M2042.3,1919.9c-3-2.6-6-5.2-9.1-7.6c-4.2-3.4-7.6-5.9-10.2-7.5c0.4-1.5,1.1-2.9,2.2-4.3c1,1,3.3,3.1,6.7,6.1
									c-0.2-1.2-0.2-2.4,0-3.5c0.2-1.2,0.7-2.1,1.3-3c2.1-2.6,4.8-2.6,8.2,0l2.2,1.9l2.4,2c2.6,2,4.6,3.3,6,3.7c-0.4,0.7-0.8,1.4-1.2,2
									c-0.4,0.5-1,1.2-1.8,1.8c-3.3-2.9-6.5-5.5-9.4-7.8c-1-0.8-1.8-1.1-2.5-1.1c-0.7,0-1.4,0.5-2.1,1.3c-1,1.2-1.5,2.5-1.6,3.9
									c0.7,0.6,1.7,1.4,2.9,2.4c4.6,3.6,7.5,5.6,8.9,5.9c-0.3,0.7-0.8,1.4-1.3,2C2043.6,1918.7,2043,1919.3,2042.3,1919.9z"
						/>
						<path
							d="M2037.5,1883.8c-0.5-1.3-0.9-2.6-1.1-3.8c1.3-0.1,2.6-0.4,3.9-0.8c0.5,1.2,0.8,2.5,1.1,3.8
									C2040.1,1883.2,2038.8,1883.4,2037.5,1883.8z M2059.5,1895.5c-0.7,0-1.6-0.4-2.8-1.1c-1-0.6-2.4-1.5-4.1-2.6
									c-1.7-1.1-2.9-1.9-3.7-2.3c-1.1-0.7-2.1-1.2-3.1-1.6s-1.8-0.6-2.3-0.6c0.1-0.9,0.3-1.7,0.6-2.4c0.3-0.7,0.6-1.4,1.1-2.2
									c0.7,0.6,1.8,1.4,3.4,2.5c1.6,1.1,3.3,2.2,5.3,3.4c1.5,0.9,2.6,1.5,3.4,1.9c0.8,0.4,1.5,0.6,2.1,0.7c0.6,0.1,1.2,0.1,1.9-0.1
									c0.2,0.7,0.3,1.3,0.3,1.9s-0.2,1.1-0.4,1.5C2060.7,1895.1,2060.2,1895.5,2059.5,1895.5z"
						/>
						<path
							d="M2068,1880c-0.6,0.1-1.6-0.2-2.9-0.8c-0.9-0.4-2.3-1.1-4.1-2c-2.1-1.1-3.9-1.9-5.2-2.6c-4.5-2.1-7.9-3.4-10.1-3.8
									c0-0.9,0.1-1.7,0.3-2.4c0.2-0.7,0.5-1.5,0.8-2.3c3.3,1.9,8.3,4.4,14.8,7.5c1.5,0.7,2.7,1.2,3.6,1.5c0.9,0.3,1.6,0.5,2.2,0.5
									c0.6,0,1.2-0.1,1.9-0.3c0.3,0.7,0.5,1.3,0.5,1.9c0,0.6-0.1,1.1-0.2,1.5C2069.2,1879.4,2068.7,1879.9,2068,1880z"
						/>
						<path
							d="M2050.8,1856.8c-0.8-1.2-1.4-2.3-1.9-3.4c1.3-0.4,2.5-0.9,3.6-1.6c0.7,1.1,1.3,2.3,1.8,3.5
									C2053.2,1855.7,2052,1856.2,2050.8,1856.8z M2074.8,1863.7c-0.6,0.1-1.6,0-3-0.5c-1.1-0.4-2.6-0.9-4.6-1.7
									c-1.9-0.7-3.2-1.2-4.1-1.5c-1.2-0.4-2.3-0.7-3.4-1c-1.1-0.2-1.9-0.3-2.4-0.2c-0.1-0.9,0-1.7,0.1-2.4c0.1-0.7,0.3-1.5,0.6-2.4
									c0.8,0.4,2.1,1,3.8,1.7c1.7,0.7,3.7,1.5,5.9,2.2c1.6,0.6,2.9,0.9,3.7,1.2c0.9,0.2,1.6,0.3,2.2,0.3c0.6,0,1.2-0.2,1.9-0.5
									c0.4,0.6,0.6,1.2,0.7,1.8c0.1,0.6,0.1,1.1-0.1,1.5C2075.9,1863.1,2075.4,1863.6,2074.8,1863.7z"
						/>
						<path
							d="M2078.6,1846.3c-1.9,0.9-4.2,1-7.2,0.4c-2-0.4-3.7-1.2-5.1-2.2c-1.4-1.1-2.4-2.3-3-3.8c-0.6-1.5-0.7-3.1-0.4-4.8
									c0.5-2.3,1.6-3.8,3.5-4.6s4.3-0.9,7.4-0.3c0.1,1.6,0,3.5-0.1,5.6c-0.2,2.2-0.4,4.3-0.8,6.4c1.9,0.2,3.3,0,4.3-0.6
									c1-0.6,1.7-1.6,2-3.1c0.2-1.2,0.3-2.3,0.3-3.4c-0.1-1.1-0.3-2.1-0.7-2.9l0.9-0.1c1,1.1,1.8,2.5,2.2,4.1s0.5,3.2,0.2,4.7
									C2081.6,1843.9,2080.4,1845.4,2078.6,1846.3z M2071.5,1838.4c-0.1-1.4-0.2-2.6-0.5-3.5c-1.6-0.3-3-0.2-4.2,0.5
									c-1.2,0.6-2,1.6-2.2,2.9c0.4,1,1.2,1.9,2.4,2.7c1.2,0.8,2.7,1.4,4.4,1.8C2071.5,1841.2,2071.5,1839.8,2071.5,1838.4z"
						/>
						<path
							d="M2085.1,1818.5c-3.1-0.3-6.4-0.6-9.9-0.7c-1.7-0.1-3-0.1-3.9,0c-0.9,0.1-1.6,0.2-2.2,0.4c-0.5,0.2-1.1,0.5-1.7,1
									c-0.6-0.5-1-1-1.2-1.5c-0.2-0.5-0.4-1-0.3-1.4c0.1-1.5,1-2.4,2.9-2.5c-0.9-0.8-1.6-1.7-2.1-2.8c-0.5-1-0.7-2.1-0.7-3.2
									c0.1-3.4,2.4-5,6.7-4.8l2.9,0.2c1.1,0.1,2.2,0.2,3.1,0.3c3.2,0.1,5.5-0.1,7-0.6c0.2,0.8,0.2,1.6,0.2,2.3c0,0.8-0.2,1.6-0.4,2.6
									c-3.7-0.4-7.8-0.6-12.2-0.8c-1.2-0.1-2.1,0.1-2.7,0.6c-0.6,0.4-0.9,1.2-0.9,2.3c-0.1,1.6,0.2,2.9,0.9,4l2,0.1
									c1.6,0.2,3.5,0.3,5.6,0.4c3.3,0.1,5.6,0,7-0.6c0.1,0.9,0.2,1.7,0.2,2.5C2085.5,1816.7,2085.3,1817.5,2085.1,1818.5z"
						/>
						<path
							d="M2098.4,1643.5c-2.3-0.1-5.4-0.2-9.5-0.2c-3.9,0-6.3,0.1-7.3,0.3c-0.1-0.5-0.2-1.1-0.2-1.6c0-0.5,0.1-1.1,0.2-1.8
									c1.3-0.6,3.2-1.5,5.8-2.8c2.5-1.3,4.9-2.4,7.1-3.6c-2.4,0-4.3,0-5.6,0c-4,0-6.4,0.1-7.3,0.3c-0.1-0.4-0.2-0.7-0.2-1.1
									c0-0.3,0.1-0.7,0.2-1.2c2.8,0.1,6,0.2,9.5,0.2c3.9,0,6.3-0.1,7.3-0.3c0.1,0.4,0.2,1,0.2,1.5c0,0.5-0.1,1.1-0.2,1.8
									c-1.4,0.7-3.4,1.6-6,2.9c-2.6,1.3-4.9,2.4-6.9,3.4c1.8,0,3.7,0.1,5.6,0.1c3.8,0,6.3-0.1,7.3-0.3c0.1,0.3,0.2,0.7,0.2,1.1
									C2098.6,1642.6,2098.5,1643,2098.4,1643.5z"
						/>
						<path
							d="M2096.2,1627.2c-0.8-0.5-1.7-1.2-2.5-2c0.9-0.7,1.7-1.4,2.4-2.2c0.9,0.6,1.8,1.3,2.5,2.1
									C2097.7,1625.7,2096.9,1626.4,2096.2,1627.2z"
						/>
						<path
							d="M2097.8,1609c-1.2,0-2.7,0-4.5,0s-3.5,0-5,0.1c-3.9,0.2-6.3,0.4-7.3,0.7c-0.1-0.5-0.2-1.1-0.3-1.7c0-0.5,0-1.1,0.1-1.7
									c1.1,0,2.6,0.1,4.4,0c1.8,0,3.6-0.1,5.2-0.1c3.9-0.2,6.3-0.4,7.2-0.7c0.1,0.5,0.2,1.1,0.3,1.6
									C2097.9,1607.8,2097.9,1608.4,2097.8,1609z"
						/>
						<path
							d="M2097,1598.6c-0.4,0.3-1.1,0.5-2.1,0.6c-0.6,0-1.7,0.1-3.1,0.1c-1.6,0-2.9,0.1-3.9,0.1c-0.4,0-0.9,0.1-1.3,0.1
									c0,0.7,0,1.2-0.1,1.5c-0.1,0.3-0.4,0.4-0.8,0.4c-0.8-1.2-2-2.3-3.4-3.4l0-0.9c0.7-0.1,1.5-0.2,2.2-0.2c-0.1-1.2-0.2-2.1-0.4-2.6
									c0.5,0,0.9,0,1.3,0c0.4,0.1,0.8,0.2,1.2,0.3c-0.2,0.5-0.3,1.3-0.3,2.3c0.9,0,1.8,0,2.8-0.1c2.4-0.1,4.3-0.3,5.7-0.6
									c-0.1-0.7-0.2-1.4-0.4-2l0.9-0.2c0.6,0.3,1.1,0.8,1.5,1.4c0.4,0.6,0.6,1.2,0.6,1.8C2097.6,1597.8,2097.4,1598.3,2097,1598.6z"
						/>
						<path
							d="M2096.8,1589.1c-2.7,0.1-5.5,0.2-8.2,0.3c-3.7,0.2-6.6,0.5-8.7,0.8c-0.5-0.9-0.8-2.1-0.9-3.3c1.4,0.1,3.5,0.1,6.2,0
									c-0.7-0.4-1.2-1-1.6-1.6c-0.4-0.6-0.6-1.3-0.7-1.9c-0.1-2.1,1.3-3.3,4.2-3.5l2-0.1l2.1-0.1c2.2-0.1,3.8-0.4,4.8-0.9
									c0.2,0.5,0.3,1.1,0.3,1.7c0,0.5,0,1.1-0.1,1.7c-2.8,0.1-5.4,0.2-7.9,0.3c-0.9,0.1-1.5,0.2-1.8,0.6s-0.5,0.8-0.5,1.4
									c0.1,0.9,0.4,1.6,0.9,2.2l2.4-0.1c3.8-0.2,6.2-0.6,7.1-1c0.2,0.6,0.3,1.1,0.3,1.7C2096.9,1587.9,2096.9,1588.5,2096.8,1589.1z"
						/>
						<path
							d="M2078.5,1574.7c-0.8-0.5-1.6-1.1-2.3-1.7c0.7-0.6,1.4-1.3,2-2c0.8,0.5,1.6,1.1,2.3,1.7
									C2079.7,1573.4,2079.1,1574,2078.5,1574.7z M2095.4,1572.7c-0.4,0.3-1,0.4-2,0.5c-0.8,0.1-1.9,0.1-3.4,0.2c-1.4,0.1-2.4,0.1-3,0.2
									c-0.9,0.1-1.6,0.2-2.4,0.3c-0.7,0.1-1.3,0.3-1.6,0.5c-0.3-0.5-0.5-1.1-0.6-1.6c-0.1-0.5-0.2-1.1-0.3-1.7c0.6,0,1.6,0.1,2.9,0
									c1.3,0,2.7-0.1,4.3-0.2c1.2-0.1,2.1-0.2,2.7-0.3c0.6-0.1,1.1-0.2,1.4-0.4c0.4-0.2,0.7-0.4,1.1-0.7c0.4,0.3,0.7,0.6,0.9,1
									c0.2,0.4,0.3,0.7,0.3,1C2095.9,1572,2095.8,1572.4,2095.4,1572.7z"
						/>
						<path
							d="M2094.8,1563.6c-0.4,0.3-1,0.4-2,0.5c-0.7,0-1.7,0.1-3.2,0.2c-1.6,0.1-3,0.1-4,0.2c-3.4,0.2-5.9,0.6-7.3,1
									c-0.3-0.5-0.5-1.1-0.6-1.6c-0.1-0.5-0.2-1.1-0.3-1.7c2.3,0.1,6.1-0.1,11.4-0.4c1.2-0.1,2.1-0.2,2.7-0.3c0.6-0.1,1.1-0.2,1.4-0.4
									c0.4-0.2,0.7-0.4,1.1-0.7c0.4,0.3,0.7,0.6,0.9,1c0.2,0.4,0.3,0.7,0.3,1C2095.3,1562.9,2095.2,1563.3,2094.8,1563.6z"
						/>
						<path
							d="M2077.2,1556.5c-0.8-0.5-1.6-1.1-2.3-1.7c0.8-0.6,1.4-1.3,2.1-2c0.8,0.5,1.6,1.1,2.3,1.7
									C2078.5,1555.1,2077.9,1555.8,2077.2,1556.5z M2094.2,1554.5c-0.4,0.3-1,0.4-2,0.5c-0.8,0-1.9,0.1-3.4,0.2c-1.4,0-2.4,0.1-3,0.1
									c-0.9,0.1-1.6,0.1-2.4,0.3c-0.7,0.1-1.3,0.3-1.6,0.5c-0.3-0.5-0.5-1.1-0.6-1.6c-0.1-0.5-0.2-1.1-0.2-1.7c0.6,0.1,1.6,0.1,2.9,0.1
									c1.3,0,2.7-0.1,4.3-0.2c1.2-0.1,2.1-0.2,2.7-0.3c0.6-0.1,1.1-0.2,1.5-0.4c0.4-0.2,0.7-0.4,1.1-0.7c0.4,0.3,0.7,0.6,0.9,1
									c0.2,0.4,0.3,0.7,0.3,1C2094.7,1553.8,2094.6,1554.3,2094.2,1554.5z"
						/>
						<path
							d="M2092.6,1545.6c-1.1,0.9-2.6,1.4-4.7,1.5c-1.4,0.1-2.7-0.1-3.8-0.5c-1.1-0.4-2-1.1-2.7-1.9c-0.7-0.8-1-1.8-1.1-3
									c-0.1-1.5,0.4-2.6,1.5-3.5c1.1-0.8,2.8-1.3,5-1.4c0.3,1,0.6,2.1,0.9,3.5c0.3,1.3,0.5,2.7,0.6,4c1.1-0.2,1.9-0.6,2.4-1.1
									s0.7-1.2,0.7-2.1c0-0.8-0.2-1.5-0.4-2.1c-0.2-0.7-0.6-1.2-1-1.7l1-0.3c0.9,0.5,1.6,1.2,2.2,2.2c0.6,0.9,0.9,1.9,0.9,2.9
									C2094.2,1543.5,2093.7,1544.7,2092.6,1545.6z M2086.4,1541.8c-0.3-0.8-0.6-1.4-0.9-1.9c-1,0.1-1.9,0.4-2.6,0.9
									c-0.7,0.5-1,1.2-0.9,1.9c0.4,0.5,1.1,0.9,2,1.2c0.9,0.3,1.9,0.4,3,0.3C2086.9,1543.5,2086.7,1542.7,2086.4,1541.8z"
						/>
						<path
							d="M2093.1,1530.4c-2.1,0-4.4,0-6.8,0.1c-1.2,0.1-2.1,0.1-2.7,0.2c-0.6,0.1-1.1,0.2-1.5,0.4c-0.4,0.2-0.7,0.4-1.1,0.7
									c-0.4-0.3-0.7-0.6-0.9-1c-0.2-0.4-0.3-0.7-0.3-1c0-1,0.6-1.6,1.8-1.8c-0.7-0.4-1.2-1-1.6-1.6s-0.6-1.3-0.6-2
									c-0.1-2.1,1.3-3.2,4.3-3.4l2,0l2.1,0c2.2-0.1,3.8-0.4,4.8-0.8c0.2,0.5,0.2,1.1,0.3,1.7c0,0.5,0,1.1-0.1,1.7
									c-2.4,0-5.1,0.1-7.9,0.2c-0.9,0-1.5,0.2-1.8,0.5c-0.4,0.3-0.5,0.8-0.5,1.4c0,0.9,0.3,1.6,0.8,2.2l0.9,0c1.5,0,2.8-0.1,3.9-0.1
									c2.2-0.1,3.8-0.4,4.8-0.8c0.2,0.5,0.2,1.1,0.3,1.6C2093.3,1529.1,2093.2,1529.7,2093.1,1530.4z"
						/>
					</g>
					<g className="level_two fade location " id="emyn_arnen">
						<rect x="967.833" y="1006.183" width="62.843" height="22.135" transform="matrix(0.999559, 0.029684, -0.041727, 0.999202, 1130.950195, 689.967346)"></rect>
						<rect x="1138.194" y="1006.183" width="73.905" height="22.135" transform="matrix(0.999559, 0.029684, -0.041727, 0.999202, 961.540771, 705.639526)"></rect>
						<path
							d="M2060.2,1737.7c0-1,0.1-2.4,0.1-4.2c0-3.2-0.2-5.4-0.5-6.6c0.9,0.1,2.4,0.2,4.3,0.2c2.4,0,4.2-0.1,5.5-0.2
									c0,0.5-0.1,1.1-0.2,1.7s-0.2,1-0.4,1.2c-0.4-0.2-1-0.4-1.7-0.6s-1.4-0.2-2.1-0.2c-0.9,0-1.8,0.1-2.6,0.2c-0.1,1.6-0.1,3.1-0.1,4.3
									l0.7,0c0.7,0,1.2,0.1,1.5,0.1c0.9,0,1.5,0,2-0.1s1-0.1,1.5-0.2c0.1,0.4,0.1,0.7,0.1,1.1c0,0.3,0,0.6-0.1,1.1
									c-2-0.1-3.5-0.2-4.6-0.2c-0.5,0-0.9,0-1.1,0c0,2.1,0,3.6,0.1,4.4c0.9,0.2,1.8,0.3,2.8,0.3c0.8,0,1.6-0.1,2.4-0.2
									c0.8-0.1,1.4-0.3,1.9-0.6c0,0.5-0.1,1.1-0.2,1.7c-0.1,0.6-0.2,1-0.4,1.2c-1.1-0.1-2.8-0.2-4.9-0.2c-1.9,0-3.3,0.1-4.3,0.2
									C2060.1,1740.2,2060.2,1738.7,2060.2,1737.7z"
						/>
						<path
							d="M2073.6,1742.1c0.1-1.9,0.2-4,0.2-6.1c0-1.1,0-1.9-0.1-2.4c-0.1-0.6-0.2-1-0.3-1.3c-0.1-0.3-0.4-0.7-0.7-1
									c0.3-0.4,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.2,0.9-0.2c0.9,0,1.5,0.6,1.6,1.7c0.5-0.6,1-1,1.6-1.3c0.6-0.3,1.2-0.5,1.9-0.5
									c1.5,0,2.5,0.7,2.9,2.1c0.5-0.7,1-1.2,1.7-1.5c0.7-0.4,1.3-0.5,2-0.5c2.1,0,3.1,1.3,3.1,4l-0.1,1.8c-0.1,0.7-0.1,1.4-0.1,1.9
									c0,2,0.2,3.4,0.6,4.3c-0.5,0.1-1,0.2-1.4,0.2s-1-0.1-1.6-0.2c0.1-2.3,0.2-4.8,0.2-7.6c0-0.8-0.1-1.3-0.4-1.7
									c-0.3-0.3-0.8-0.5-1.4-0.5c-0.9,0-1.7,0.2-2.4,0.7c0,0.7,0,1.8,0,3.2c0,0.4,0,0.9,0,1.6c0,2,0.2,3.4,0.5,4.3
									c-0.5,0.1-0.9,0.2-1.4,0.2c-0.4,0-1-0.1-1.6-0.2c0.1-2.3,0.2-4.8,0.2-7.6c0-0.8-0.1-1.3-0.4-1.7c-0.3-0.3-0.8-0.5-1.4-0.5
									c-0.9,0-1.7,0.2-2.3,0.7l0,1.2c-0.1,1-0.1,2.1-0.1,3.5c0,2,0.2,3.5,0.5,4.3c-0.5,0.1-1,0.2-1.5,0.2
									C2074.8,1742.3,2074.3,1742.3,2073.6,1742.1z"
						/>
						<path
							d="M2093.1,1746.1c0-1.7,0.7-3.2,2.2-4.7c-0.9-0.6-1.4-1.8-1.4-3.5l0.1-1.4l0.1-1.7c0-2-0.2-3.3-0.5-4.1
									c0.5-0.2,1-0.4,1.4-0.4c0.4-0.1,0.9-0.1,1.6-0.1c-0.1,1.1-0.2,3.6-0.2,7.6c0,0.8,0.1,1.3,0.4,1.7c0.3,0.3,0.8,0.5,1.4,0.5
									c1,0,1.9-0.2,2.6-0.8l-0.1-3.4c0-0.3,0-0.6,0-1c0-0.9,0-1.7-0.1-2.4c-0.1-0.7-0.2-1.3-0.4-1.6c0.9-0.4,1.9-0.6,3-0.6
									c-0.1,1.6-0.2,4-0.2,7.2c0,1.3,0,2.4,0.1,3.3c0,0.9,0.1,1.4,0.1,1.5c0,1.7-0.5,3-1.5,3.9c-1,0.9-2.4,1.3-4.1,1.3
									C2095.7,1747.3,2094.2,1746.9,2093.1,1746.1z M2100,1744.8c0.5-0.6,0.8-1.8,0.8-3.4v-1.4c-0.5,0.6-1.1,1-1.7,1.3
									c-0.6,0.3-1.3,0.5-2,0.5c-0.2,0-0.4,0-0.8-0.1c-0.7,0.9-1.2,1.8-1.3,2.7c0.3,0.4,0.7,0.7,1.2,0.9c0.5,0.2,1,0.3,1.5,0.3
									C2098.7,1745.8,2099.5,1745.5,2100,1744.8z"
						/>
						<path
							d="M2107.5,1742.1c0.1-1.9,0.2-4,0.2-6.1c0-1.1,0-1.9-0.1-2.4c-0.1-0.6-0.2-1-0.3-1.3c-0.1-0.3-0.4-0.7-0.7-1
									c0.3-0.4,0.6-0.6,0.9-0.8c0.3-0.2,0.6-0.2,0.9-0.2c0.9,0,1.5,0.6,1.6,1.7c0.5-0.6,1-1,1.6-1.4c0.6-0.3,1.3-0.5,1.9-0.5
									c2.1,0,3.1,1.3,3.1,4l-0.1,1.8c-0.1,0.7-0.1,1.4-0.1,1.9c0,2,0.2,3.4,0.6,4.3c-0.5,0.1-1,0.2-1.4,0.2c-0.5,0-1-0.1-1.6-0.2
									c0.1-2.3,0.2-4.8,0.2-7.6c0-0.8-0.1-1.3-0.4-1.7c-0.3-0.3-0.8-0.5-1.4-0.5c-1,0-1.8,0.2-2.4,0.7l0,1.2c-0.1,1-0.1,2.1-0.1,3.5
									c0,2,0.2,3.5,0.5,4.3c-0.5,0.1-1,0.2-1.5,0.2C2108.7,1742.3,2108.2,1742.3,2107.5,1742.1z"
						/>
						<path
							d="M2070.6,1765.1l-1.5-4.5c-1-0.1-2.2-0.1-3.7-0.1c-1.6,0-2.6,0.1-3.2,0.2c-0.5,1.3-1,2.8-1.5,4.4c-0.4,0.1-0.7,0.2-1.1,0.2
									c-0.4,0-0.9-0.1-1.3-0.2c0.6-1.1,1.6-3.9,3.2-8.1s2.6-7.6,3.4-10c0.6-0.1,1-0.2,1.4-0.2c0.4,0,0.8,0.1,1.3,0.2
									c0.7,2.4,1.9,5.7,3.4,10c1.5,4.3,2.6,7,3.2,8.1c-0.5,0.2-1.1,0.2-1.7,0.2C2071.9,1765.3,2071.2,1765.2,2070.6,1765.1z
									M2068.4,1758.5c-1-3.1-2-5.6-2.8-7.7c-0.8,1.9-1.7,4.4-2.7,7.6c1.1,0.1,2.3,0.1,3.7,0.1
									C2067.5,1758.6,2068.1,1758.6,2068.4,1758.5z"
						/>
						<path
							d="M2076.6,1765.1c0.1-2.3,0.2-4.7,0.2-7.3c0-1.3,0-2.2-0.1-2.9c-0.1-0.7-0.2-1.2-0.4-1.6c-0.2-0.4-0.4-0.8-0.8-1.2
									c0.3-0.4,0.7-0.7,1.1-0.9c0.4-0.2,0.8-0.3,1.1-0.3c1.2,0,1.9,0.8,1.9,2.5c0.6-0.7,1.1-1.3,1.8-1.8c0.6-0.5,1.3-0.8,2.2-1
									c0.1,0.4,0.3,0.9,0.4,1.6c0.1,0.7,0.2,1.3,0.2,1.9c-0.3,0-0.6-0.1-1.1-0.1c-0.7,0-1.3,0.1-1.9,0.3s-1.1,0.4-1.5,0.6l0,1.1
									c0,1.5-0.1,2.9-0.1,4c0,2.4,0.2,4.1,0.6,5.2c-0.6,0.1-1.2,0.2-1.8,0.2S2077.3,1765.3,2076.6,1765.1z"
						/>
						<path
							d="M2086.6,1765.1c0.1-2.3,0.2-4.7,0.2-7.3c0-1.3,0-2.2-0.1-2.9c-0.1-0.7-0.2-1.2-0.4-1.6c-0.2-0.4-0.4-0.8-0.8-1.2
									c0.3-0.4,0.7-0.8,1.1-0.9c0.4-0.2,0.7-0.3,1.1-0.3c1.1,0,1.8,0.7,1.9,2.1c0.6-0.7,1.2-1.2,2-1.6c0.8-0.4,1.5-0.6,2.3-0.6
									c2.5,0,3.7,1.6,3.7,4.7l-0.1,2.2c-0.1,0.8-0.1,1.6-0.1,2.3c0,2.4,0.2,4.1,0.7,5.2c-0.6,0.1-1.2,0.2-1.7,0.2
									c-0.6,0-1.2-0.1-1.9-0.2c0.1-2.8,0.2-5.8,0.2-9c0-0.9-0.2-1.6-0.5-2c-0.3-0.4-0.9-0.6-1.7-0.6c-1.2,0-2.1,0.3-2.9,0.8l0,1.5
									c-0.1,1.2-0.1,2.6-0.1,4.2c0,2.4,0.2,4.1,0.6,5.2c-0.6,0.1-1.2,0.2-1.8,0.2C2087.9,1765.3,2087.3,1765.3,2086.6,1765.1z"
						/>
						<path
							d="M2102.2,1763.7c-0.9-1.2-1.4-2.9-1.4-5.1c0-1.5,0.3-2.9,0.8-4s1.3-2.1,2.3-2.8c1-0.7,2.1-1,3.4-1c1.7,0,3,0.6,3.9,1.8
									c0.9,1.2,1.3,3,1.3,5.3c-1.1,0.3-2.5,0.6-4.1,0.8c-1.6,0.2-3.2,0.3-4.7,0.4c0.1,1.4,0.5,2.4,1.1,3c0.6,0.6,1.4,0.9,2.6,0.9
									c0.9,0,1.7-0.1,2.5-0.3s1.4-0.5,2-0.9l0.2,0.7c-0.7,0.9-1.5,1.7-2.6,2.2c-1.1,0.6-2.2,0.8-3.4,0.8
									C2104.4,1765.5,2103.1,1764.9,2102.2,1763.7z M2106.8,1757.4c1-0.3,1.8-0.6,2.4-0.9c0-1.2-0.3-2.2-1-3c-0.6-0.8-1.4-1.2-2.4-1.2
									c-0.7,0.4-1.2,1.1-1.6,2.1c-0.4,1-0.6,2.2-0.6,3.5C2104.8,1757.8,2105.8,1757.6,2106.8,1757.4z"
						/>
						<path
							d="M2116.4,1765.1c0.1-2.3,0.2-4.7,0.2-7.3c0-1.3,0-2.2-0.1-2.9c-0.1-0.7-0.2-1.2-0.4-1.6c-0.2-0.4-0.4-0.8-0.8-1.2
									c0.3-0.4,0.7-0.8,1.1-0.9c0.4-0.2,0.7-0.3,1.1-0.3c1.1,0,1.8,0.7,1.9,2.1c0.6-0.7,1.2-1.2,2-1.6c0.8-0.4,1.5-0.6,2.3-0.6
									c2.5,0,3.7,1.6,3.7,4.7l-0.1,2.2c-0.1,0.8-0.1,1.6-0.1,2.3c0,2.4,0.2,4.1,0.7,5.2c-0.6,0.1-1.2,0.2-1.7,0.2
									c-0.6,0-1.2-0.1-1.9-0.2c0.1-2.8,0.2-5.8,0.2-9c0-0.9-0.2-1.6-0.5-2c-0.3-0.4-0.9-0.6-1.7-0.6c-1.2,0-2.1,0.3-2.9,0.8l0,1.5
									c-0.1,1.2-0.1,2.6-0.1,4.2c0,2.4,0.2,4.1,0.6,5.2c-0.6,0.1-1.2,0.2-1.8,0.2C2117.7,1765.3,2117.1,1765.3,2116.4,1765.1z"
						/>
					</g>
					<g className="level_two fade location" id="the_wold">
						<rect x="803.433" y="1359.631" width="52.169" height="29.91" transform="matrix(0.999986, 0.005252, -0.017296, 0.999923, 972.511719, -146.871155)"></rect>
						<rect x="1083.853" y="1310.383" width="70.377" height="28.826" transform="matrix(0.999928, -0.011989, -0.000054, 1.000073, 647.633606, -54.257015)"></rect>
						<path
							d="M1763.4,1238c0.1-1.1,0.2-2.6,0.2-4.4c0.1-1.8,0.1-3.6,0.1-5.2c0-1.7,0-3.2-0.2-4.5c-2.4,0-4,0.2-4.7,0.4
									c-0.1-0.6-0.2-1.1-0.2-1.6c0-0.4,0-0.9,0.2-1.6c0.8,0.1,1.9,0.2,3.3,0.2c1.4,0.1,2.7,0.1,3.9,0.1c2.9,0,4.7-0.1,5.4-0.4
									c0.1,0.6,0.2,1.1,0.2,1.5s0,1-0.2,1.6c-1.1-0.1-2.7-0.2-4.7-0.3c-0.1,2.7-0.2,4.9-0.2,6.8c0,3.9,0.2,6.3,0.5,7.3
									c-0.5,0.1-1.2,0.2-1.8,0.2C1764.8,1238.2,1764.2,1238.1,1763.4,1238z"
						/>
						<path
							d="M1774,1238c0.1-2.7,0.2-5.5,0.2-8.2c0-3.7-0.1-6.6-0.3-8.7c1.1-0.4,2.3-0.6,3.7-0.6c-0.2,1.4-0.3,3.5-0.4,6.2
									c0.5-0.6,1.2-1.1,1.9-1.5c0.7-0.4,1.4-0.5,2.2-0.5c2.3,0,3.5,1.5,3.5,4.4l-0.1,2l-0.1,2.1c0,2.2,0.2,3.8,0.6,4.8
									c-0.6,0.1-1.2,0.2-1.9,0.2c-0.5,0-1.2-0.1-1.9-0.2c0.1-2.8,0.2-5.4,0.2-7.9c0-0.9-0.2-1.5-0.5-1.8c-0.3-0.4-0.8-0.6-1.5-0.6
									c-1,0-1.8,0.3-2.5,0.8l0,2.4c0,3.8,0.2,6.2,0.6,7.2c-0.6,0.1-1.2,0.2-1.9,0.2C1775.4,1238.2,1774.7,1238.1,1774,1238z"
						/>
						<path
							d="M1789.7,1236.7c-0.9-1.1-1.4-2.7-1.4-4.8c0-1.4,0.3-2.7,0.8-3.8c0.5-1.1,1.3-2,2.3-2.6c1-0.6,2.1-0.9,3.4-0.9
									c1.6,0,2.9,0.6,3.7,1.7c0.9,1.1,1.3,2.8,1.3,5c-1.1,0.3-2.4,0.5-3.9,0.7c-1.5,0.2-3,0.3-4.4,0.3c0.2,1.1,0.5,2,1,2.5
									c0.5,0.5,1.3,0.8,2.3,0.8c0.8,0,1.6-0.1,2.4-0.3c0.7-0.2,1.4-0.5,1.9-0.9l0.3,1.1c-0.6,0.9-1.5,1.5-2.5,2.1
									c-1.1,0.5-2.1,0.8-3.2,0.8C1791.9,1238.4,1790.6,1237.8,1789.7,1236.7z M1794.2,1230.7c0.9-0.2,1.6-0.5,2.2-0.8
									c0-1-0.3-1.9-0.9-2.6s-1.2-1-2.1-1c-0.6,0.4-1,1-1.4,1.9c-0.4,0.9-0.5,1.9-0.5,3C1792.4,1231.1,1793.3,1230.9,1794.2,1230.7z"
						/>
						<path
							d="M1741.2,1265.4c-0.5-0.6-0.8-1.4-1.2-2.4s-0.7-2.4-1.2-4.2c-0.5-2-1-3.6-1.5-5s-0.9-2.8-1.5-4.1c1.3-0.4,2.6-0.7,4.1-0.7
									c0.1,1,0.3,2.4,0.7,4c0.4,1.7,0.7,3.3,1.1,4.7c0.7,2.3,1.2,4,1.7,5.1c1.6-5,2.6-8.6,3.1-10.8l-0.9-2.3c1.2-0.4,2.6-0.7,4.1-0.7
									c0.1,1,0.4,2.3,0.7,4c0.3,1.7,0.7,3.3,1.1,4.7c0.6,2.2,1.2,3.9,1.7,5.1c1.2-3.9,2.1-6.8,2.6-8.8c0.5-2,0.8-3.3,0.8-4.1
									c0-0.2,0-0.3-0.1-0.5c0.9-0.2,1.8-0.4,2.9-0.4c-0.7,1.9-1.5,4.4-2.5,7.6c-1,3.2-1.9,6.1-2.6,8.6l-1.7,1.2c-0.6,0-1.2-0.3-1.6-0.9
									c-0.5-0.6-0.8-1.4-1.2-2.4c-0.3-1-0.7-2.4-1.2-4.2c-0.4-1.5-0.7-2.6-1-3.6c-1.4,4.4-2.4,7.7-3,9.9l-1.7,1.2
									C1742.2,1266.3,1741.7,1266,1741.2,1265.4z"
						/>
						<path
							d="M1763.7,1265.5c-0.9-0.5-1.5-1.3-2-2.3c-0.5-1-0.7-2.1-0.7-3.4c0-1.4,0.3-2.7,0.9-3.8c0.6-1.1,1.4-2,2.4-2.6
									c1-0.6,2.2-0.9,3.5-0.9c1.8,0,3.2,0.6,4.3,1.8c1.1,1.2,1.6,2.8,1.6,4.7c0,1.4-0.3,2.7-0.9,3.8c-0.6,1.1-1.4,2-2.5,2.6
									c-1,0.6-2.2,0.9-3.6,0.9C1765.5,1266.4,1764.5,1266.1,1763.7,1265.5z M1769.7,1263.6c0.6-0.8,0.9-1.9,0.9-3.3
									c0-1.2-0.3-2.3-0.8-3.2c-0.5-0.9-1.3-1.5-2.3-1.8c-1.1,0-2,0.4-2.7,1.2c-0.7,0.8-1,1.9-1,3.2c0,1.5,0.3,2.8,0.9,3.7
									c0.6,0.9,1.4,1.4,2.4,1.4C1768.3,1264.8,1769.1,1264.4,1769.7,1263.6z"
						/>
						<path
							d="M1777.5,1265.7c-0.3-0.4-0.4-1.1-0.4-2c0-0.7,0-1.7,0.1-3.2c0-1.6,0.1-3,0.1-4c0-3.4-0.2-5.9-0.6-7.4
									c0.6-0.2,1.2-0.4,1.8-0.5c0.6-0.1,1.2-0.1,1.9-0.1c-0.2,2.3-0.4,6-0.4,11.4c0,1.2,0,2.1,0.1,2.7c0.1,0.6,0.2,1.1,0.3,1.5
									c0.2,0.4,0.4,0.7,0.7,1.1c-0.4,0.4-0.7,0.7-1.2,0.9c-0.4,0.2-0.8,0.3-1.1,0.3C1778.2,1266.3,1777.8,1266.1,1777.5,1265.7z"
						/>
						<path
							d="M1785,1264.7c-0.8-1.1-1.3-2.8-1.3-5c0-2.3,0.4-4.1,1.3-5.3c0.9-1.2,2.1-1.8,3.8-1.8c1.3,0,2.5,0.4,3.5,1.2
									c-0.1-2-0.3-3.6-0.5-4.7c1.1-0.4,2.3-0.6,3.7-0.6c-0.2,2.6-0.4,7-0.4,13.1c0,0.9,0.1,1.5,0.2,2c0.2,0.5,0.5,1,0.9,1.5
									c-0.4,0.3-0.8,0.6-1.3,0.8s-1,0.4-1.5,0.4c-0.6-0.4-0.9-1-1.1-1.7c-0.5,0.6-1.1,1-1.8,1.3s-1.3,0.5-2,0.5
									C1787.1,1266.4,1785.9,1265.8,1785,1264.7z M1792.4,1263.3c0-0.6,0-1.7,0.1-3.3c0-1.7,0.1-2.9,0.1-3.8c-0.7-0.4-1.5-0.7-2.5-0.7
									c-1,0-1.8,0.4-2.3,1.1c-0.5,0.7-0.8,1.8-0.8,3.3c0,1.3,0.2,2.4,0.7,3.2s1.2,1.3,2,1.5C1790.5,1264.5,1791.4,1264.1,1792.4,1263.3z
									"
						/>
					</g>
					<g id="marshes">
						<g>
							<path
								d="M1964.8,1544.3c2.4,1.8,4.9,3.7,7.3,5.5c0.5,0.4,2.7,0,2.8-0.7c0.4-3.3,0.1-6.3-0.8-9.5c-0.2-0.8-2.8,0-2.9,0.8
										c-0.3,3.7,0.7,6.9,2.9,9.9c0.8-0.4,1.5-0.8,2.3-1.2c-2.8-0.3,1.2-4.2,1.5-4.6c1.1-1.2,2.2-2.4,3.3-3.6c-0.9,0.1-1.8,0.2-2.8,0.3
										c-2,3-4,6-6,9c-0.4,0.6,1.5,0.3,1.6,0.3c4.7-1.2,8.7-3.5,12.1-6.9c0.8-0.8-1.9-0.3-2.2-0.2c-3.5,1.6-7,3.1-10.5,4.7
										c-1.2,0.5-0.2,1,0.6,0.9c1.5-0.2,2.9-0.5,4.4-0.7c-0.2-0.3-0.4-0.6-0.6-0.9c-1.7,0.8-3.5,1.6-5.2,2.5c-0.9,0.4-0.6,0.9,0.2,0.9
										c0.4,0,0.9,0,1.3,0c0.9,0,3.1-1.4,1.2-1.3c-0.4,0-0.9,0-1.3,0c0.1,0.3,0.2,0.6,0.2,0.9c1.7-0.8,3.5-1.6,5.2-2.5
										c1.1-0.5,0.2-1-0.6-0.9c-1.5,0.2-2.9,0.5-4.4,0.7c0.2,0.3,0.4,0.6,0.6,0.9c3.5-1.6,7-3.1,10.5-4.7c-0.7-0.1-1.5-0.1-2.2-0.2
										c-2.8,2.8-5.9,4.7-9.7,5.7c0.5,0.1,1.1,0.2,1.6,0.3c2-3,4-6,6-9c0.4-0.5-2.4-0.1-2.8,0.3c-1.1,1.2-9.7,8.7-4,9.3
										c0.4,0,2.8-0.4,2.3-1.2c-2.1-2.9-2.9-6.1-2.7-9.7c-1,0.3-1.9,0.5-2.9,0.8c0.9,3.1,1.2,6,0.9,9.2c0.9-0.2,1.9-0.5,2.8-0.7
										c-2.4-1.8-4.9-3.7-7.3-5.5C1966.9,1542.8,1964.3,1543.9,1964.8,1544.3L1964.8,1544.3z"
							/>
						</g>
					</g>
					<g className="level_zero location " id="sir_ninglor">
						<rect x="1688.494" y="1330.026" width="109.639" height="29.259" transform="matrix(0.999652, -0.026373, 0.014331, 0.99997, -57.102329, -543.217346)"></rect>
						<rect x="2114.325" y="1330.027" width="137.289" height="29.259" transform="matrix(0.994253, -0.107057, 0.09508, 0.995543, -603.091797, -316.547394)"></rect>
						<path
							d="M1651.5,765.9c0-0.4,0.1-0.8,0.2-1.1c0.1-0.3,0.2-0.6,0.4-0.9c0.4,0.5,0.9,0.9,1.6,1.2s1.5,0.5,2.3,0.6
									c1,0.1,1.7-0.1,2.3-0.4c0.5-0.4,0.8-0.9,0.9-1.7c0.1-0.6-0.2-1.2-0.6-1.7c-0.5-0.5-1.2-1-2.2-1.7c-0.9-0.6-1.6-1.1-2.1-1.5
									s-0.9-1-1.3-1.6c-0.3-0.6-0.5-1.4-0.4-2.2c0.1-1.1,0.7-2,1.7-2.6s2.4-0.8,4-0.7c1.5,0.1,2.7,0.5,3.7,1c0,0.4-0.1,0.9-0.3,1.4
									c-0.2,0.5-0.4,0.9-0.6,1.2c-0.7-0.6-1.4-1.2-2.2-1.6c-0.8-0.5-1.4-0.7-1.9-0.7c-0.4,0-0.8,0.2-1.2,0.6c-0.4,0.4-0.6,0.8-0.7,1.3
									c-0.1,0.7,0.2,1.3,0.6,1.8c0.5,0.5,1.2,1.1,2.3,1.7c0.9,0.6,1.5,1,2,1.5c0.5,0.4,0.9,0.9,1.3,1.5c0.3,0.6,0.5,1.3,0.4,2.1
									c-0.1,1.4-0.6,2.4-1.6,3.1c-0.9,0.7-2.3,0.9-4.1,0.8C1654.3,767.2,1652.8,766.7,1651.5,765.9z"
						/>
						<path
							d="M1662.7,752.8c0.8-1.3,1.4-2.6,2-3.7c0.6-0.3,1.4-0.6,2.4-0.7c0.6,0.9,1.1,1.5,1.4,1.9s0.8,0.9,1.4,1.5
									c-0.7,0.4-1.5,0.6-2.4,0.7l-1.9-2.3l-1.2,2.7C1663.9,752.9,1663.3,752.9,1662.7,752.8z M1667.2,766.1c-0.3-0.3-0.4-0.9-0.5-1.8
									c-0.1-0.7-0.2-1.7-0.3-3c-0.1-1.2-0.2-2.1-0.3-2.7c-0.1-0.8-0.2-1.5-0.4-2.2c-0.2-0.7-0.4-1.1-0.6-1.4c0.5-0.3,0.9-0.5,1.4-0.6
									c0.4-0.1,1-0.2,1.5-0.3c0,0.6,0,1.4,0.1,2.6c0.1,1.2,0.2,2.5,0.4,3.9c0.1,1.1,0.3,1.9,0.4,2.4c0.1,0.5,0.3,1,0.5,1.3
									s0.4,0.6,0.8,0.9c-0.2,0.4-0.5,0.7-0.8,0.9c-0.3,0.2-0.6,0.3-0.9,0.4C1667.8,766.5,1667.4,766.4,1667.2,766.1z"
						/>
						<path
							d="M1674.8,765.1c-0.3-1.9-0.7-3.9-1.2-6.1c-0.2-1-0.5-1.8-0.6-2.4c-0.2-0.5-0.4-0.9-0.6-1.2c-0.2-0.3-0.5-0.6-0.9-0.8
									c0.2-0.4,0.5-0.7,0.7-1c0.3-0.2,0.6-0.4,0.8-0.5c1-0.2,1.7,0.3,2.1,1.7c0.3-0.7,0.7-1.3,1.1-1.8c0.4-0.5,1-0.9,1.6-1.3
									c0.2,0.3,0.4,0.7,0.6,1.2c0.2,0.5,0.4,1.1,0.5,1.5c-0.2,0-0.5,0.1-0.9,0.1c-0.6,0.1-1.1,0.3-1.6,0.6c-0.5,0.3-0.8,0.5-1.1,0.8
									l0.2,0.9c0.3,1.3,0.5,2.4,0.7,3.3c0.5,2,1,3.4,1.5,4.1c-0.5,0.2-1,0.4-1.4,0.5C1676,765,1675.4,765,1674.8,765.1z"
						/>
						<path
							d="M1689,761.3c-0.4-2-1-4.9-1.9-8.5c-0.8-3.3-1.5-5.4-1.9-6.3c0.5-0.2,0.9-0.4,1.3-0.5c0.4-0.1,0.9-0.2,1.6-0.2
									c1,1.2,2.4,2.8,4.3,4.8c1.8,2,3.5,3.8,4.9,5.3c-0.5-2.2-0.9-4-1.3-5.2c-0.9-3.5-1.5-5.6-1.9-6.3c0.3-0.2,0.6-0.3,1-0.4
									c0.3-0.1,0.7-0.1,1.1-0.1c0.2,1,0.5,2.3,0.8,4s0.7,3.2,1.1,4.6c0.8,3.3,1.5,5.4,1.9,6.3c-0.3,0.2-0.7,0.3-1.3,0.5
									c-0.4,0.1-0.9,0.2-1.6,0.2c-1-1.1-2.4-2.7-4.2-4.8c-1.8-2-3.5-3.8-4.9-5.3c0.4,1.7,0.8,3.4,1.2,5.2c0.8,3.4,1.5,5.5,1.9,6.3
									c-0.3,0.2-0.6,0.3-1,0.4C1689.8,761.3,1689.4,761.3,1689,761.3z"
						/>
						<path
							d="M1699.3,742.6c0.4-0.8,0.8-1.5,1.3-2.1c0.6,0.5,1.3,1,2,1.4c-0.4,0.7-0.8,1.4-1.3,2.1C1700.7,743.5,1700,743,1699.3,742.6
									z M1703.2,757.6c-0.3-0.3-0.5-0.9-0.7-1.8c-0.1-0.7-0.3-1.7-0.5-3c-0.2-1.2-0.4-2.1-0.5-2.7c-0.2-0.8-0.3-1.5-0.6-2.1
									c-0.2-0.6-0.4-1.1-0.7-1.3c0.5-0.3,0.9-0.5,1.3-0.7c0.4-0.2,0.9-0.3,1.5-0.4c0,0.6,0.1,1.4,0.3,2.6c0.2,1.2,0.4,2.5,0.7,3.9
									c0.2,1,0.4,1.8,0.6,2.4c0.2,0.5,0.4,1,0.6,1.2c0.2,0.3,0.5,0.6,0.8,0.9c-0.2,0.4-0.5,0.7-0.8,1c-0.3,0.2-0.6,0.4-0.8,0.4
									C1703.9,757.9,1703.5,757.9,1703.2,757.6z"
						/>
						<path
							d="M1709.1,756.7c-0.1-1.9-0.2-4-0.4-6.2c-0.1-1.1-0.2-1.9-0.3-2.4s-0.3-1-0.4-1.3c-0.2-0.3-0.4-0.6-0.8-0.9
									c0.2-0.4,0.5-0.7,0.8-0.9c0.3-0.2,0.6-0.3,0.9-0.3c0.9-0.1,1.6,0.4,1.8,1.6c0.4-0.6,0.9-1.1,1.5-1.5c0.6-0.4,1.2-0.6,1.9-0.7
									c2.1-0.2,3.3,1,3.5,3.7l0.1,1.8c0,0.7,0.1,1.4,0.1,1.9c0.2,2,0.5,3.4,1,4.3c-0.5,0.2-1,0.3-1.4,0.3c-0.5,0-1,0-1.6,0
									c-0.1-2.3-0.3-4.9-0.6-7.6c-0.1-0.8-0.3-1.3-0.6-1.6c-0.3-0.3-0.8-0.4-1.5-0.4c-1,0.1-1.8,0.4-2.4,0.9l0.1,1.2
									c0,1,0.1,2.2,0.3,3.5c0.2,2,0.5,3.4,1,4.3c-0.5,0.2-1,0.3-1.5,0.3C1710.2,756.7,1709.7,756.7,1709.1,756.7z"
						/>
						<path
							d="M1720.2,759.5c0-0.7,0.2-1.4,0.5-2c0.3-0.7,0.7-1.2,1.2-1.7c-0.7-0.3-1-0.9-1-1.6c0-0.5,0.2-1,0.6-1.4
									c0.4-0.4,0.9-0.7,1.6-0.9c-0.6-0.4-1.1-0.9-1.4-1.5c-0.3-0.6-0.4-1.4-0.4-2.2c0.1-1.4,0.6-2.4,1.5-3.2c0.9-0.8,2.1-1.2,3.5-1.1
									c0.9,0.1,1.7,0.3,2.4,0.8c0.4-0.1,0.8-0.2,1.3-0.5c0.5-0.3,1-0.6,1.4-1c0.2,0.4,0.3,0.8,0.4,1.2c0.1,0.5,0.2,0.8,0.1,1.1
									c-0.6,0.3-1.3,0.4-2.2,0.5c0.3,0.7,0.5,1.5,0.4,2.3c-0.1,0.9-0.3,1.7-0.8,2.3c-0.5,0.7-1.1,1.2-1.8,1.5c-0.8,0.3-1.6,0.5-2.6,0.4
									c-0.8,0-1.4,0.1-1.9,0.3c-0.5,0.2-0.7,0.6-0.7,1l5,0.6c1,0.2,1.7,0.5,2.2,1c0.5,0.5,0.7,1.2,0.7,2c0,0.8-0.3,1.5-0.8,2.1
									c-0.5,0.6-1.2,1.1-2.1,1.4s-1.8,0.4-2.9,0.4C1722.7,760.9,1721.3,760.4,1720.2,759.5z M1727.6,758.8c0.6-0.3,0.9-0.7,0.9-1.2
									c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.7-0.3-1.3-0.4l-4-0.5c-0.3,0.5-0.5,1.1-0.7,1.5c0.6,1,1.6,1.6,3,1.7
									C1726.2,759.2,1727,759.1,1727.6,758.8z M1727.3,750.6c0.4-0.4,0.7-1,0.7-1.8c0-0.7-0.1-1.3-0.5-1.8c-0.4-0.5-0.9-0.9-1.5-1.1
									c-0.7,0-1.4,0.2-1.9,0.7c-0.5,0.5-0.8,1.1-0.8,1.9c0,0.8,0.1,1.4,0.5,1.9c0.4,0.5,0.9,0.8,1.6,0.8
									C1726.3,751.2,1726.9,751,1727.3,750.6z"
						/>
						<path
							d="M1733,756.4c-0.2-0.4-0.2-1,0-1.9c0.1-0.6,0.3-1.5,0.5-2.8c0.3-1.4,0.5-2.6,0.6-3.6c0.5-3.1,0.7-5.3,0.5-6.7
									c0.5-0.1,1-0.2,1.5-0.2c0.5,0,1,0,1.5,0.1c-0.6,2.3-1.2,5.7-1.9,10.1c-0.2,1-0.3,1.8-0.3,2.4c0,0.6,0,1,0.1,1.4
									c0.1,0.3,0.3,0.7,0.5,1.1c-0.3,0.3-0.7,0.5-1.1,0.6c-0.4,0.1-0.7,0.2-0.9,0.1C1733.5,757,1733.1,756.8,1733,756.4z"
						/>
						<path
							d="M1739.1,756.5c-0.7-1.2-0.8-2.7-0.4-4.5c0.3-1.2,0.8-2.3,1.4-3.2c0.7-0.9,1.5-1.5,2.5-1.8s2-0.4,3.1-0.2
									c1,0.2,1.8,0.6,2.5,1.3c0.7,0.6,1.1,1.4,1.3,2.4s0.2,2,0,3.1c-0.3,1.2-0.8,2.3-1.5,3.2c-0.7,0.9-1.6,1.5-2.6,1.8
									c-1,0.3-2.1,0.4-3.2,0.1C1740.8,758.6,1739.8,757.8,1739.1,756.5z M1745.6,757.2c0.7-0.6,1.2-1.6,1.5-2.9c0.3-1.2,0.2-2.2-0.1-3.2
									c-0.3-1-0.9-1.7-1.7-2.2c-1-0.2-1.9,0-2.7,0.6s-1.3,1.6-1.6,2.8c-0.3,1.5-0.3,2.7,0.1,3.7c0.4,1,1,1.6,1.9,1.8
									C1744,758,1744.8,757.8,1745.6,757.2z"
						/>
						<path
							d="M1751.5,760.6c0.6-1.9,1.1-3.8,1.6-6c0.2-1,0.4-1.8,0.5-2.4c0.1-0.6,0.1-1,0-1.4c-0.1-0.4-0.2-0.7-0.4-1.1
									c0.4-0.3,0.7-0.5,1.1-0.5s0.7-0.1,1,0c1,0.2,1.4,1.1,1.1,2.5c0.6-0.5,1.2-0.9,1.8-1.1c0.6-0.3,1.3-0.4,2-0.5
									c0.1,0.3,0.1,0.8,0,1.4s-0.1,1.1-0.2,1.6c-0.2-0.1-0.5-0.2-0.9-0.3c-0.6-0.1-1.1-0.2-1.7-0.2s-1,0.1-1.4,0.2l-0.2,0.9
									c-0.3,1.2-0.6,2.3-0.8,3.3c-0.5,2-0.6,3.4-0.5,4.4c-0.6,0-1-0.1-1.5-0.2C1752.5,761,1752,760.9,1751.5,760.6z"
						/>
						<path
							d="M1635,790l0.2,0.4c-1.2,0.8-2.1,1.9-2.9,3.2s-1.3,2.8-1.6,4.4c-0.3,1.6-0.4,3.2-0.2,4.7c0.2,1.5,0.7,2.9,1.4,4.1l-0.3,0.3
									c-1.2-1.3-2.1-2.8-2.6-4.5c-0.5-1.6-0.6-3.3-0.3-4.9c0.3-1.6,1.1-3.1,2.2-4.4C1632,791.8,1633.4,790.8,1635,790z"
						/>
						<path
							d="M1636.7,805.5c-0.9-1.1-1.3-2.7-1.2-4.7c0-1.4,0.3-2.7,0.9-3.8c0.5-1.1,1.3-1.9,2.3-2.5c1-0.6,2-0.9,3.2-0.8
									c1.4,0,2.6,0.3,3.6,0.7c0,0.3-0.1,0.8-0.2,1.2c-0.1,0.5-0.3,0.8-0.5,1.1c-0.7-0.5-1.4-0.9-2.2-1.3c-0.8-0.4-1.4-0.5-1.9-0.6
									c-0.5,0.1-1,0.4-1.5,1s-0.8,1.2-1.1,1.9c-0.3,0.8-0.4,1.6-0.5,2.4c-0.1,1.9,0.2,3.2,0.8,4.1c0.6,0.8,1.6,1.3,3.1,1.3
									c0.7,0,1.2,0,1.8-0.2c0.1-2.7,0-4.4-0.2-5c0.4-0.1,0.8-0.1,1.2-0.1c0.4,0,0.8,0.1,1.3,0.2c-0.2,1.7-0.3,3.4-0.4,5.2
									c-0.5,0.5-1.2,0.9-2.1,1.2s-1.7,0.4-2.6,0.4C1638.9,807.2,1637.6,806.6,1636.7,805.5z"
						/>
						<path
							d="M1650.1,806.3c-0.2-0.3-0.4-0.8-0.5-1.6c-0.1-0.5-0.2-1.3-0.3-2.5c-0.1-1.3-0.2-2.3-0.4-3.1c-0.3-2.7-0.7-4.5-1.2-5.6
									c0.4-0.2,0.8-0.4,1.2-0.5c0.4-0.1,0.8-0.2,1.3-0.3c0.1,2.1,0.4,5,0.9,8.8c0.1,0.9,0.2,1.6,0.3,2.1c0.1,0.5,0.3,0.9,0.4,1.1
									c0.2,0.3,0.4,0.5,0.7,0.8c-0.2,0.3-0.4,0.6-0.7,0.8c-0.3,0.2-0.5,0.3-0.8,0.3C1650.7,806.6,1650.3,806.5,1650.1,806.3z"
						/>
						<path
							d="M1655.6,805.2c-0.5-0.4-0.8-0.9-0.9-1.5c-0.4-2,1.2-3.4,4.7-4.4c-0.2-1.1-0.5-1.8-0.9-2.2s-0.9-0.5-1.7-0.3
									c-0.5,0.1-0.9,0.3-1.4,0.5s-0.8,0.6-1.2,0.9l-0.3-0.4c0.3-0.8,0.7-1.5,1.3-2.1c0.6-0.6,1.3-1,2-1.1c1-0.2,1.8-0.1,2.4,0.4
									c0.6,0.5,1.1,1.2,1.3,2.3c0.1,0.6,0.2,1.3,0.3,2c0.1,0.8,0.2,1.4,0.3,1.8c0.1,0.6,0.3,1.1,0.5,1.4c0.2,0.3,0.5,0.6,0.9,1
									c-0.5,0.6-1,1.1-1.7,1.3c-0.5-0.2-0.8-0.6-1.1-1.2c-0.5,0.5-1,1-1.5,1.3c-0.5,0.4-1.1,0.6-1.6,0.7
									C1656.5,805.6,1656,805.5,1655.6,805.2z M1658.9,803.6c0.4-0.3,0.7-0.6,1-0.9l0-0.1c-0.1-0.5-0.2-1-0.2-1.5l-0.1-1.1
									c-1,0.3-1.8,0.7-2.3,1.2c-0.5,0.5-0.7,1.1-0.5,1.8c0.1,0.3,0.2,0.5,0.4,0.8c0.2,0.2,0.4,0.4,0.7,0.5
									C1658.1,804.1,1658.5,803.9,1658.9,803.6z"
						/>
						<path
							d="M1665.6,802.5c-0.8-0.7-1.4-2-1.8-3.7c-0.4-1.8-0.3-3.2,0.1-4.2s1.3-1.7,2.6-2c1-0.2,2-0.1,3,0.4
									c-0.4-1.6-0.8-2.8-1.2-3.5c0.4-0.3,0.8-0.5,1.2-0.6c0.4-0.2,0.8-0.3,1.3-0.4c0.3,2.1,0.9,5.5,1.9,10.4c0.1,0.6,0.3,1,0.5,1.3
									c0.2,0.3,0.5,0.6,0.9,0.9c-0.4,0.6-1,1-1.6,1.3c-0.5-0.2-0.9-0.6-1.2-1.2c-0.3,0.5-0.7,1-1.2,1.3c-0.5,0.4-0.9,0.6-1.4,0.7
									C1667.4,803.4,1666.4,803.2,1665.6,802.5z M1670,801.3c0.3-0.3,0.7-0.6,0.9-0.9c-0.1-0.5-0.3-1.4-0.5-2.6
									c-0.3-1.3-0.5-2.4-0.6-3.2c-0.7-0.3-1.4-0.3-2.2-0.2c-0.8,0.2-1.3,0.6-1.6,1.3c-0.3,0.7-0.3,1.6-0.1,2.8c0.2,1.1,0.6,2,1.2,2.6
									c0.5,0.6,1.2,0.9,1.9,0.8C1669.3,801.7,1669.6,801.5,1670,801.3z"
						/>
						<path
							d="M1677.3,800c-0.9-0.7-1.5-1.9-2-3.5c-0.5-1.7-0.6-3.1-0.2-4.2c0.4-1.1,1.2-1.8,2.5-2.1c1-0.3,2-0.2,3,0.2
									c-0.5-1.6-1-2.7-1.4-3.5c0.4-0.3,0.8-0.5,1.1-0.7s0.8-0.3,1.2-0.5c0.4,2.1,1.3,5.5,2.6,10.2c0.2,0.6,0.3,1,0.6,1.3
									c0.2,0.3,0.5,0.6,1,0.9c-0.4,0.6-0.9,1.1-1.5,1.4c-0.5-0.2-0.9-0.6-1.2-1.1c-0.3,0.5-0.6,1-1.1,1.4s-0.9,0.6-1.4,0.8
									C1679.2,800.8,1678.1,800.6,1677.3,800z M1681.6,798.5c0.3-0.3,0.6-0.6,0.9-1c-0.1-0.5-0.4-1.3-0.7-2.5c-0.3-1.3-0.6-2.4-0.8-3.1
									c-0.7-0.2-1.4-0.2-2.2,0c-0.8,0.2-1.3,0.7-1.5,1.4c-0.2,0.7-0.2,1.6,0.1,2.8c0.3,1.1,0.8,1.9,1.3,2.5c0.6,0.5,1.2,0.8,2,0.7
									C1680.9,799,1681.2,798.8,1681.6,798.5z"
						/>
						<path
							d="M1689.1,796.1c-0.9-0.5-1.7-1.6-2.4-3.1c-0.4-1-0.6-2-0.6-2.9c0-0.9,0.3-1.8,0.8-2.5c0.5-0.7,1.1-1.3,2-1.6
									c1.1-0.5,2.2-0.5,3.1,0.1c0.9,0.6,1.7,1.6,2.4,3.2c-0.7,0.5-1.5,1.1-2.5,1.7c-1,0.6-2,1.1-3.1,1.6c0.5,0.9,1,1.5,1.6,1.7
									c0.6,0.3,1.2,0.2,2-0.1c0.6-0.2,1.1-0.6,1.6-0.9c0.5-0.4,0.8-0.8,1.1-1.2l0.3,0.4c-0.2,0.8-0.6,1.5-1.1,2.2
									c-0.6,0.7-1.2,1.2-2,1.5C1691.1,796.7,1690.1,796.7,1689.1,796.1z M1690.4,790.6c0.6-0.5,1.1-0.9,1.4-1.3
									c-0.4-0.8-0.9-1.4-1.5-1.7c-0.6-0.3-1.3-0.4-1.9-0.1c-0.3,0.5-0.5,1.1-0.5,1.9c0,0.8,0.2,1.6,0.6,2.5
									C1689.2,791.5,1689.8,791.1,1690.4,790.6z"
						/>
						<path
							d="M1699,792.6c-0.5-1.6-1.1-3.3-1.7-5c-0.3-0.9-0.6-1.5-0.8-1.9c-0.2-0.4-0.4-0.8-0.6-1c-0.2-0.2-0.5-0.4-0.8-0.6
									c0.1-0.4,0.3-0.7,0.5-0.9c0.2-0.2,0.4-0.4,0.6-0.5c0.8-0.3,1.4,0,1.9,0.9c0.2-0.6,0.5-1.1,0.9-1.6c0.4-0.5,0.9-0.8,1.4-1
									c1.7-0.6,3,0.1,3.8,2.3l0.5,1.5c0.2,0.6,0.3,1.1,0.5,1.6c0.6,1.6,1.2,2.7,1.8,3.3c-0.4,0.2-0.7,0.4-1.1,0.6
									c-0.4,0.1-0.8,0.3-1.4,0.4c-0.6-1.9-1.3-4-2.2-6.2c-0.2-0.6-0.5-1-0.9-1.2c-0.3-0.2-0.8-0.2-1.3,0c-0.8,0.3-1.4,0.7-1.8,1.3l0.4,1
									c0.3,0.8,0.6,1.8,1,2.9c0.6,1.6,1.2,2.8,1.8,3.3c-0.4,0.3-0.8,0.5-1.2,0.6C1700,792.4,1699.6,792.5,1699,792.6z"
						/>
						<path
							d="M1714,787.8c0-0.8,0-2-0.1-3.5s-0.1-2.9-0.2-4c-0.2-2.7-0.5-4.4-0.8-5c0.5-0.2,1.1-0.4,1.8-0.6c0.8-0.2,1.6-0.3,2.4-0.4
									c1.5-0.1,2.8,0.2,3.7,0.9c0.9,0.7,1.4,1.7,1.5,3.2c0.1,0.8-0.1,1.6-0.4,2.3c-0.4,0.7-0.9,1.3-1.6,1.7c1.4,2.4,2.5,3.9,3.2,4.5
									c-0.4,0.2-0.8,0.4-1.3,0.5c-0.4,0.1-0.9,0.2-1.5,0.2c-0.4-0.9-1.2-2.4-2.5-4.5c-0.1,0-0.2,0-0.4,0.1c-0.3,0-0.6,0-1,0
									c-0.4,0-0.7,0-0.9-0.1c0.2,2.4,0.5,3.9,0.7,4.7c-0.4,0.1-0.8,0.2-1.3,0.3C1714.9,787.9,1714.5,787.9,1714,787.8z M1719.6,780.4
									c0.3-0.5,0.5-1.1,0.4-1.8c-0.2-2-1.3-2.9-3.3-2.7c-0.4,0-0.9,0.1-1.2,0.3c0,1.2,0.1,2.9,0.2,5.1c1.1,0.3,2,0.5,2.6,0.4
									C1718.8,781.4,1719.3,781,1719.6,780.4z"
						/>
						<path
							d="M1725,787.3c-0.2-0.3-0.2-0.9-0.1-1.6c0.1-0.6,0.2-1.5,0.3-2.6c0.1-1.1,0.3-1.8,0.3-2.3c0.1-0.7,0.1-1.3,0.1-1.9
									s-0.1-1-0.2-1.3c0.5-0.1,0.9-0.2,1.3-0.2c0.4,0,0.8,0,1.3,0c-0.1,0.5-0.3,1.2-0.4,2.2s-0.3,2.1-0.5,3.4c-0.1,0.9-0.2,1.6-0.2,2.1
									s0,0.9,0.1,1.2c0.1,0.3,0.2,0.6,0.5,0.9c-0.3,0.3-0.6,0.5-0.9,0.6c-0.3,0.1-0.6,0.2-0.8,0.1C1725.5,787.8,1725.2,787.6,1725,787.3
									z M1725.7,774c0.5-0.6,1.1-1,1.6-1.4c0.4,0.6,0.8,1.2,1.3,1.7c-0.5,0.5-1,1-1.6,1.4C1726.6,775.1,1726.2,774.5,1725.7,774z"
						/>
						<path
							d="M1731.6,788.2c-0.2-0.5-0.3-1-0.4-1.7s-0.1-1.5-0.1-2.6c0-1.4-0.1-2.5-0.2-3.4s-0.3-1.7-0.6-2.4c0.5-0.1,0.9-0.1,1.3-0.1
									c0.4,0,0.9,0.1,1.4,0.2c-0.1,0.9-0.1,2.5,0,4.8c0.1,2.3,0.2,3.8,0.4,4.6c1.4-2.3,2.4-3.9,2.9-4.9c0.6-1,0.9-1.8,1-2.3
									c0.1-0.2,0.1-0.5,0.1-0.7c0.6,0,1.3,0.1,2.1,0.3c-1.4,2-3.2,4.9-5.3,8.6l-1.6,0.5C1732.1,788.9,1731.8,788.6,1731.6,788.2z"
						/>
						<path
							d="M1738.5,789.9c-0.4-1-0.3-2.3,0.2-3.9c0.3-1,0.8-1.9,1.5-2.6c0.7-0.7,1.4-1.2,2.2-1.4c0.8-0.2,1.7-0.2,2.6,0.1
									c1.2,0.4,1.9,1.1,2.3,2.1c0.3,1,0.2,2.4-0.3,4c-0.8,0-1.8-0.2-3-0.4c-1.2-0.2-2.3-0.5-3.4-0.8c-0.2,1-0.2,1.8,0.1,2.3
									s0.8,1,1.6,1.2c0.6,0.2,1.2,0.3,1.8,0.3c0.6,0,1.1,0,1.6-0.2l0,0.5c-0.7,0.5-1.4,0.8-2.3,0.9c-0.9,0.1-1.7,0.1-2.5-0.2
									C1739.6,791.6,1738.9,790.9,1738.5,789.9z M1743.2,786.5c0.8,0,1.4,0,1.9-0.1c0.3-0.8,0.3-1.6,0-2.3c-0.3-0.7-0.7-1.1-1.4-1.4
									c-0.5,0.1-1.1,0.5-1.6,1.1s-0.9,1.4-1.2,2.3C1741.6,786.4,1742.4,786.5,1743.2,786.5z"
						/>
						<path
							d="M1748.9,794.2c0.5-1.6,1-3.3,1.5-5.1c0.2-0.9,0.4-1.6,0.5-2.1c0.1-0.5,0.1-0.9,0-1.2c0-0.3-0.1-0.6-0.3-1
									c0.3-0.2,0.6-0.4,1-0.4c0.3-0.1,0.6-0.1,0.8,0c0.9,0.2,1.1,0.9,0.9,2.1c0.5-0.4,1.1-0.7,1.6-0.9s1.1-0.3,1.7-0.3
									c0,0.3,0,0.7,0,1.2c0,0.5-0.1,1-0.2,1.4c-0.2-0.1-0.4-0.2-0.8-0.3c-0.5-0.1-1-0.2-1.4-0.2c-0.5,0-0.9,0.1-1.2,0.2l-0.2,0.7
									c-0.3,1.1-0.6,2-0.8,2.8c-0.4,1.7-0.6,2.9-0.5,3.7c-0.5,0-0.9-0.1-1.3-0.2C1749.8,794.6,1749.4,794.4,1748.9,794.2z"
						/>
						<path
							d="M1761.5,784.3c0.5,1.6,0.6,3.3,0.2,4.9c-0.3,1.6-1.1,3.1-2.2,4.4c-1.1,1.3-2.5,2.3-4.1,3.1l-0.2-0.4
									c1.2-0.8,2.1-1.9,2.9-3.2c0.8-1.3,1.3-2.7,1.7-4.4c0.3-1.6,0.4-3.2,0.2-4.7s-0.7-2.9-1.4-4.1l0.3-0.3
									C1760.2,781.1,1761,782.6,1761.5,784.3z"
						/>
					</g>
					<g className="level_two fade location " id="loeg_ningloron">
						<rect x="1611.004" y="3892.787" width="104.607" height="85.638" transform="matrix(0.949159, -0.314796, 0.303363, 0.952951, -918.979797, -2443.825195)"></rect>
						<path
							d="M1811.7,768.1c-0.3-0.8-0.9-2-1.6-3.5c-1.5-3.2-2.6-5.1-3.2-5.7c0.4-0.3,0.8-0.6,1.3-0.8c0.4-0.2,0.9-0.4,1.5-0.5
									c0.4,1,0.9,2.2,1.5,3.8c0.7,1.5,1.3,3,1.9,4.2c0.9,1.8,1.5,3.1,1.9,3.8c0.8-0.2,1.7-0.5,2.7-0.9c0.6-0.3,1.1-0.6,1.7-1
									c0.5-0.4,0.9-0.8,1.2-1.2c0.2,0.5,0.4,1.1,0.6,1.7c0.1,0.6,0.2,1,0.1,1.3c-1.1,0.4-2.4,0.9-3.9,1.6c-1.7,0.8-3,1.5-3.8,2
									C1812.6,770.4,1812,768.9,1811.7,768.1z"
						/>
						<path
							d="M1823.1,766.9c-1.2-0.7-2.1-1.9-2.7-3.6c-0.4-1.2-0.6-2.4-0.4-3.5c0.1-1.1,0.5-2.1,1.2-2.9c0.7-0.8,1.5-1.4,2.6-1.8
									c1-0.3,1.9-0.4,2.8-0.2c0.9,0.2,1.7,0.7,2.4,1.4c0.7,0.7,1.2,1.6,1.6,2.7c0.4,1.2,0.6,2.4,0.4,3.5c-0.2,1.1-0.6,2.1-1.3,2.9
									c-0.7,0.8-1.6,1.4-2.7,1.8C1825.6,767.8,1824.3,767.6,1823.1,766.9z M1829,764.1c0.3-0.9,0.2-2-0.2-3.3c-0.4-1.1-1-2-1.8-2.7
									c-0.8-0.6-1.7-1-2.6-1c-1,0.3-1.6,1-2,1.9c-0.3,0.9-0.3,2,0.1,3.2c0.5,1.4,1.2,2.5,2,3.1c0.8,0.6,1.7,0.8,2.5,0.5
									C1828,765.6,1828.7,765,1829,764.1z"
						/>
						<path
							d="M1835,763.2c-1-0.8-1.8-2.1-2.3-3.9c-0.3-1.2-0.4-2.4-0.2-3.5c0.2-1.1,0.6-2,1.3-2.8c0.7-0.8,1.5-1.3,2.5-1.6
									c1.4-0.4,2.6-0.2,3.6,0.6s1.7,2.1,2.3,4c-0.9,0.5-1.9,1-3.2,1.5c-1.3,0.5-2.5,1-3.8,1.4c0.4,1.1,0.9,1.9,1.6,2.2
									c0.6,0.4,1.4,0.4,2.3,0.2c0.7-0.2,1.4-0.5,1.9-0.8c0.6-0.4,1.1-0.7,1.4-1.2l0.3,0.5c-0.3,0.9-0.9,1.7-1.7,2.4s-1.6,1.2-2.6,1.4
									C1837.2,764.2,1836,764,1835,763.2z M1837.4,757.1c0.8-0.4,1.4-0.9,1.8-1.3c-0.3-1-0.8-1.7-1.5-2.2c-0.7-0.5-1.4-0.6-2.2-0.4
									c-0.4,0.5-0.7,1.2-0.8,2.1c-0.1,0.9,0,1.9,0.3,3C1835.8,757.9,1836.6,757.5,1837.4,757.1z"
						/>
						<path
							d="M1846.4,765.6c-0.2-0.7-0.2-1.4-0.1-2.1c0.1-0.7,0.3-1.4,0.6-2c-0.7-0.1-1.2-0.5-1.4-1.3c-0.1-0.5-0.1-1,0.2-1.6
									c0.3-0.5,0.7-1,1.2-1.4c-0.7-0.2-1.3-0.5-1.7-1.1c-0.5-0.5-0.8-1.2-1-2c-0.3-1.3-0.1-2.5,0.5-3.5c0.7-1,1.7-1.7,3-2
									c0.9-0.2,1.7-0.2,2.5,0.1c0.3-0.2,0.7-0.5,1.1-0.9c0.4-0.4,0.7-0.9,1.1-1.4c0.3,0.3,0.5,0.6,0.8,1.1s0.4,0.8,0.5,1
									c-0.5,0.5-1.1,0.8-1.9,1.1c0.5,0.6,0.9,1.3,1,2.1c0.2,0.9,0.2,1.7-0.1,2.5c-0.2,0.8-0.7,1.4-1.3,2c-0.6,0.5-1.4,0.9-2.3,1.1
									c-0.8,0.2-1.3,0.4-1.7,0.8c-0.4,0.4-0.5,0.8-0.4,1.2l5-0.9c1-0.1,1.8,0,2.4,0.3c0.6,0.3,1,0.9,1.2,1.7c0.2,0.8,0.1,1.5-0.2,2.2
									c-0.3,0.7-0.9,1.4-1.6,1.9c-0.7,0.5-1.6,0.9-2.7,1.2C1849.2,766.2,1847.7,766.1,1846.4,765.6z M1850.7,755
									c0.3-0.5,0.4-1.2,0.2-1.9c-0.2-0.7-0.5-1.2-1-1.6s-1.1-0.6-1.8-0.6c-0.7,0.2-1.2,0.6-1.6,1.2c-0.3,0.6-0.4,1.3-0.2,2.1
									c0.2,0.7,0.5,1.3,1,1.6s1.1,0.5,1.8,0.3C1849.9,755.9,1850.4,755.5,1850.7,755z M1853.4,762.8c0.5-0.4,0.7-0.9,0.6-1.4
									c-0.1-0.4-0.3-0.6-0.6-0.7c-0.3-0.1-0.7-0.1-1.3,0l-4,0.7c-0.2,0.6-0.2,1.2-0.2,1.7c0.9,0.8,2,1.1,3.4,0.8
									C1852.1,763.6,1852.9,763.3,1853.4,762.8z"
						/>
						<path
							d="M1808,793.1c-0.5-1.6-1.4-4-2.6-6.9c-1.1-2.7-1.8-4.4-2.3-5c0.4-0.3,0.7-0.4,1-0.6c0.3-0.1,0.7-0.2,1.3-0.3
									c1,0.9,2.3,2,4.1,3.5c1.8,1.5,3.3,2.8,4.6,3.9c-0.7-1.8-1.2-3.2-1.6-4.2c-1.1-2.8-1.9-4.5-2.3-5.1c0.2-0.2,0.5-0.4,0.8-0.5
									c0.2-0.1,0.5-0.2,0.9-0.2c0.3,0.8,0.7,1.9,1.1,3.2s1,2.6,1.4,3.7c1.1,2.7,1.8,4.4,2.3,5c-0.2,0.2-0.5,0.4-1,0.6
									c-0.3,0.1-0.7,0.2-1.3,0.4c-0.9-0.8-2.3-2-4.1-3.5c-1.8-1.5-3.3-2.8-4.6-3.9c0.5,1.3,1,2.8,1.6,4.2c1.1,2.7,1.8,4.4,2.3,5.1
									c-0.2,0.2-0.5,0.3-0.8,0.5C1808.6,793,1808.3,793.1,1808,793.1z"
						/>
						<path
							d="M1816.3,775.7c0.2-0.7,0.5-1.4,0.8-1.9c0.6,0.4,1.2,0.7,1.8,0.9c-0.2,0.7-0.5,1.3-0.8,1.9
									C1817.6,776.2,1816.9,775.9,1816.3,775.7z M1821.5,787.6c-0.3-0.2-0.5-0.7-0.8-1.4c-0.2-0.6-0.5-1.4-0.8-2.4
									c-0.3-1-0.6-1.7-0.8-2.2c-0.2-0.6-0.5-1.2-0.7-1.7c-0.3-0.5-0.5-0.8-0.7-1c0.3-0.3,0.7-0.6,1-0.8c0.3-0.2,0.7-0.4,1.2-0.5
									c0.1,0.5,0.3,1.2,0.6,2.1c0.3,0.9,0.6,2,1.1,3.1c0.3,0.8,0.6,1.5,0.8,1.9c0.2,0.4,0.4,0.7,0.6,1c0.2,0.2,0.5,0.4,0.8,0.6
									c-0.1,0.4-0.3,0.7-0.5,0.9c-0.2,0.2-0.4,0.4-0.6,0.5C1822.1,787.9,1821.7,787.8,1821.5,787.6z"
						/>
						<path
							d="M1828.6,785.3c-0.4-1.6-0.9-3.2-1.5-5c-0.3-0.8-0.5-1.5-0.7-1.9c-0.2-0.4-0.4-0.8-0.6-1c-0.2-0.2-0.5-0.4-0.8-0.6
									c0.1-0.4,0.3-0.7,0.5-0.9c0.2-0.2,0.4-0.4,0.6-0.4c0.8-0.3,1.3,0.1,1.8,1c0.2-0.6,0.5-1.1,0.9-1.5c0.4-0.4,0.9-0.7,1.4-0.9
									c1.7-0.6,2.8,0.2,3.6,2.4l0.4,1.5c0.2,0.6,0.3,1.1,0.5,1.6c0.5,1.6,1.1,2.7,1.6,3.3c-0.4,0.2-0.7,0.4-1.1,0.5
									c-0.4,0.1-0.8,0.2-1.3,0.3c-0.5-1.9-1.2-3.9-1.9-6.1c-0.2-0.6-0.5-1-0.8-1.2c-0.3-0.2-0.7-0.2-1.3,0c-0.8,0.3-1.4,0.7-1.8,1.2
									l0.3,1c0.2,0.8,0.5,1.7,0.9,2.8c0.5,1.6,1.1,2.7,1.6,3.3c-0.4,0.2-0.8,0.4-1.2,0.5C1829.6,785.1,1829.2,785.2,1828.6,785.3z"
						/>
						<path
							d="M1841.3,784.6c-0.2-0.6-0.2-1.1-0.2-1.7s0.2-1.2,0.4-1.7c-0.6,0-1-0.4-1.2-1c-0.1-0.4-0.1-0.9,0.1-1.3s0.5-0.8,1-1.2
									c-0.6-0.1-1.1-0.4-1.5-0.8c-0.4-0.4-0.7-1-1-1.6c-0.3-1.1-0.3-2.1,0.2-3c0.5-0.9,1.3-1.5,2.4-1.9c0.7-0.2,1.4-0.3,2.1-0.1
									c0.3-0.2,0.5-0.4,0.8-0.8c0.3-0.4,0.6-0.8,0.8-1.2c0.2,0.2,0.5,0.5,0.7,0.8s0.4,0.6,0.4,0.8c-0.4,0.4-0.9,0.7-1.5,1
									c0.5,0.5,0.8,1,1,1.7c0.2,0.7,0.3,1.4,0.1,2.1c-0.2,0.7-0.5,1.2-1,1.7c-0.5,0.5-1.1,0.9-1.9,1.1c-0.6,0.2-1.1,0.4-1.4,0.8
									c-0.3,0.3-0.4,0.7-0.3,1l4.1-1c0.8-0.2,1.5-0.1,2,0.1s0.9,0.7,1.1,1.4c0.2,0.6,0.2,1.2-0.1,1.9c-0.2,0.6-0.6,1.2-1.2,1.7
									s-1.3,0.9-2.1,1.1C1843.7,784.9,1842.4,785,1841.3,784.6z M1844.3,775.5c0.2-0.5,0.2-1,0.1-1.6c-0.2-0.6-0.5-1-0.9-1.3
									c-0.4-0.3-0.9-0.4-1.5-0.4c-0.6,0.2-1,0.5-1.3,1.1s-0.3,1.1-0.1,1.8c0.2,0.6,0.5,1,1,1.3c0.5,0.3,1,0.3,1.5,0.1
									C1843.6,776.2,1844.1,775.9,1844.3,775.5z M1846.9,781.9c0.4-0.4,0.5-0.8,0.4-1.2c-0.1-0.3-0.3-0.5-0.5-0.6s-0.6-0.1-1.1,0.1
									l-3.3,0.8c-0.1,0.5-0.1,1-0.1,1.4c0.8,0.6,1.7,0.8,2.8,0.4C1846,782.6,1846.5,782.3,1846.9,781.9z"
						/>
						<path
							d="M1852.8,777.4c-0.2-0.2-0.5-0.7-0.7-1.4c-0.1-0.5-0.3-1.3-0.6-2.3c-0.3-1.2-0.6-2.2-0.8-3c-0.7-2.5-1.3-4.3-1.9-5.3
									c0.4-0.3,0.7-0.5,1.1-0.7c0.3-0.2,0.7-0.3,1.2-0.4c0.4,2,1.1,4.8,2.1,8.4c0.2,0.8,0.4,1.5,0.6,1.9c0.2,0.4,0.4,0.8,0.6,1
									c0.2,0.2,0.4,0.5,0.8,0.7c-0.2,0.3-0.3,0.6-0.6,0.8s-0.5,0.4-0.7,0.4C1853.4,777.7,1853,777.6,1852.8,777.4z"
						/>
						<path
							d="M1859.8,774.6c-0.9-0.7-1.6-1.8-1.9-3.2c-0.2-1-0.3-2-0.1-2.9c0.2-0.9,0.6-1.7,1.2-2.3c0.6-0.6,1.4-1,2.3-1.3
									c0.8-0.2,1.6-0.2,2.3,0.1c0.7,0.3,1.3,0.7,1.9,1.4c0.5,0.6,0.9,1.4,1.1,2.4c0.2,1,0.3,2,0,2.9c-0.2,0.9-0.7,1.7-1.3,2.3
									c-0.6,0.6-1.4,1.1-2.4,1.3C1861.8,775.5,1860.7,775.3,1859.8,774.6z M1864.8,772.8c0.3-0.7,0.3-1.6,0.1-2.7
									c-0.2-1-0.6-1.8-1.2-2.4c-0.6-0.6-1.3-1-2.1-1c-0.8,0.2-1.4,0.7-1.8,1.4c-0.4,0.7-0.4,1.6-0.2,2.7c0.3,1.2,0.8,2.2,1.4,2.8
									c0.6,0.6,1.3,0.8,2.1,0.7C1863.9,774,1864.5,773.5,1864.8,772.8z"
						/>
						<path
							d="M1872.7,772.9c-0.2-1.6-0.5-3.3-0.8-5.1c-0.2-0.9-0.3-1.5-0.5-2s-0.3-0.8-0.5-1.1c-0.2-0.3-0.4-0.5-0.7-0.7
									c0.2-0.3,0.4-0.6,0.6-0.8s0.5-0.3,0.7-0.4c0.8-0.2,1.4,0.3,1.7,1.5c0.3-0.6,0.6-1.1,1-1.5c0.4-0.4,0.8-0.7,1.3-1
									c0.2,0.2,0.3,0.6,0.5,1.1c0.2,0.5,0.3,0.9,0.4,1.3c-0.2,0-0.4,0-0.8,0.1c-0.5,0.1-0.9,0.2-1.3,0.4c-0.4,0.2-0.7,0.4-1,0.6l0.1,0.7
									c0.2,1.1,0.3,2,0.5,2.8c0.3,1.7,0.7,2.9,1.1,3.5c-0.4,0.2-0.8,0.3-1.2,0.4C1873.6,772.8,1873.2,772.9,1872.7,772.9z"
						/>
						<path
							d="M1881.8,770.1c-0.9-0.8-1.4-1.9-1.6-3.4c-0.2-1.1-0.1-2,0.2-2.9c0.3-0.9,0.8-1.6,1.4-2.2s1.5-0.9,2.4-1
									c0.8-0.1,1.6,0,2.3,0.3s1.3,0.8,1.7,1.5c0.5,0.7,0.7,1.5,0.9,2.5c0.2,1.1,0.1,2-0.2,2.9c-0.3,0.9-0.8,1.6-1.5,2.2
									c-0.7,0.6-1.5,0.9-2.5,1.1C1883.7,771.2,1882.6,770.9,1881.8,770.1z M1887,768.8c0.4-0.7,0.5-1.6,0.3-2.7c-0.1-1-0.5-1.8-1-2.5
									c-0.5-0.7-1.2-1.1-2-1.2c-0.9,0.1-1.5,0.5-1.9,1.2c-0.4,0.7-0.6,1.6-0.4,2.7c0.2,1.3,0.6,2.2,1.1,2.9c0.6,0.7,1.2,0.9,2,0.8
									C1886,769.9,1886.6,769.5,1887,768.8z"
						/>
						<path
							d="M1894.7,769.5c0-1.6-0.1-3.4-0.3-5.2c-0.1-0.9-0.2-1.6-0.3-2c-0.1-0.5-0.2-0.8-0.3-1.1c-0.1-0.3-0.3-0.5-0.6-0.8
									c0.2-0.3,0.4-0.6,0.7-0.7c0.3-0.2,0.5-0.3,0.7-0.3c0.8-0.1,1.3,0.4,1.5,1.3c0.3-0.5,0.8-0.9,1.3-1.3s1-0.5,1.6-0.6
									c1.7-0.2,2.7,0.9,2.9,3.1l0.1,1.5c0,0.6,0,1.1,0.1,1.6c0.1,1.7,0.4,2.9,0.8,3.6c-0.4,0.1-0.8,0.2-1.2,0.3c-0.4,0-0.8,0-1.4,0
									c-0.1-2-0.2-4.1-0.4-6.4c-0.1-0.6-0.2-1.1-0.5-1.4c-0.3-0.3-0.7-0.4-1.2-0.3c-0.8,0.1-1.5,0.3-2,0.7l0.1,1c0,0.8,0.1,1.8,0.2,2.9
									c0.2,1.7,0.4,2.9,0.8,3.6c-0.4,0.1-0.9,0.2-1.3,0.3C1895.7,769.6,1895.3,769.6,1894.7,769.5z"
						/>
						<path
							d="M1805.9,798.4l0.4,0.2c-0.4,1.4-0.4,2.9-0.2,4.4c0.2,1.5,0.8,3,1.6,4.5c0.8,1.5,1.8,2.8,2.9,3.8c1.1,1,2.4,1.8,3.8,2.3
									l-0.1,0.4c-1.8-0.3-3.4-0.9-4.9-1.8s-2.6-2.2-3.4-3.7c-0.8-1.5-1.2-3.1-1.2-4.9C1804.7,801.8,1805.1,800.1,1805.9,798.4z"
						/>
						<path
							d="M1816.5,810.4c-1.3-0.7-2.3-1.9-3.1-3.9c-0.6-1.3-0.8-2.6-0.8-3.9c0-1.2,0.4-2.3,1-3.3c0.6-0.9,1.5-1.7,2.6-2.1
									c1.3-0.6,2.5-0.8,3.6-0.9c0.1,0.3,0.2,0.7,0.3,1.2c0.1,0.5,0.1,0.9,0,1.2c-0.8-0.2-1.7-0.3-2.6-0.3c-0.9,0-1.5,0.1-1.9,0.3
									c-0.4,0.3-0.7,0.8-0.9,1.5s-0.3,1.4-0.2,2.3c0.1,0.8,0.3,1.6,0.6,2.4c0.8,1.8,1.6,2.9,2.5,3.4c0.9,0.5,2,0.5,3.4-0.1
									c0.6-0.3,1.1-0.6,1.5-0.9c-1.1-2.6-1.9-4.1-2.4-4.6c0.3-0.3,0.7-0.5,1-0.6c0.4-0.2,0.8-0.3,1.3-0.4c0.5,1.6,1.2,3.3,1.9,5
									c-0.3,0.7-0.7,1.3-1.4,2c-0.7,0.6-1.4,1.1-2.2,1.5C1819.2,811,1817.7,811,1816.5,810.4z"
						/>
						<path
							d="M1828.5,806.9c-0.3-0.2-0.5-0.7-0.8-1.5c-0.2-0.5-0.4-1.3-0.7-2.4c-0.4-1.2-0.7-2.3-1-3c-0.9-2.6-1.6-4.4-2.3-5.4
									c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.2,0.8-0.4,1.2-0.5c0.5,2.1,1.3,5,2.6,8.7c0.3,0.9,0.5,1.5,0.7,2c0.2,0.5,0.4,0.8,0.6,1
									c0.2,0.2,0.5,0.5,0.8,0.7c-0.1,0.4-0.3,0.7-0.6,0.9s-0.5,0.4-0.7,0.5C1829.1,807.1,1828.8,807.1,1828.5,806.9z"
						/>
						<path
							d="M1833.6,805.1c-0.5-0.3-0.8-0.9-1-1.5c-0.5-2,1-3.6,4.5-4.7c-0.3-1.1-0.6-1.8-1-2.2c-0.4-0.4-1-0.4-1.8-0.3
									c-0.5,0.1-0.9,0.3-1.4,0.6c-0.4,0.3-0.8,0.6-1.1,1l-0.3-0.4c0.2-0.8,0.6-1.6,1.2-2.2c0.6-0.6,1.2-1,2-1.2c1-0.2,1.8-0.1,2.5,0.3
									c0.7,0.4,1.1,1.2,1.4,2.3c0.1,0.6,0.3,1.3,0.4,2c0.1,0.8,0.2,1.4,0.3,1.8c0.2,0.6,0.3,1.1,0.5,1.4s0.5,0.6,1,1
									c-0.4,0.7-1,1.1-1.6,1.4c-0.5-0.2-0.9-0.6-1.2-1.2c-0.4,0.5-0.9,1-1.5,1.4c-0.5,0.4-1,0.7-1.5,0.8
									C1834.6,805.5,1834.1,805.4,1833.6,805.1z M1836.9,803.3c0.4-0.3,0.7-0.6,1-1l0-0.1c-0.1-0.5-0.2-1-0.3-1.6l-0.2-1.1
									c-1,0.3-1.8,0.8-2.2,1.3c-0.5,0.5-0.6,1.2-0.4,1.8c0.1,0.3,0.2,0.5,0.4,0.8c0.2,0.2,0.4,0.4,0.7,0.5
									C1836.1,803.8,1836.5,803.6,1836.9,803.3z"
						/>
						<path
							d="M1844.2,802c-0.8-0.8-1.3-2.1-1.6-3.8c-0.3-1.8-0.2-3.2,0.3-4.3c0.5-1,1.4-1.7,2.7-1.9c1-0.2,2,0,3,0.5
									c-0.4-1.7-0.7-2.9-1.1-3.7c0.4-0.3,0.8-0.5,1.2-0.6c0.4-0.1,0.8-0.3,1.3-0.3c0.2,2.1,0.7,5.7,1.5,10.7c0.1,0.6,0.2,1,0.4,1.4
									s0.5,0.7,0.9,1c-0.4,0.6-1,1-1.7,1.2c-0.5-0.3-0.8-0.7-1.1-1.3c-0.3,0.5-0.8,1-1.2,1.3s-1,0.5-1.5,0.6
									C1846,803.1,1845,802.8,1844.2,802z M1848.7,801c0.4-0.3,0.7-0.6,1-0.9c-0.1-0.5-0.2-1.4-0.4-2.7c-0.2-1.4-0.4-2.5-0.5-3.3
									c-0.7-0.3-1.4-0.4-2.2-0.3c-0.8,0.1-1.4,0.5-1.7,1.2s-0.4,1.6-0.2,2.8c0.2,1.2,0.5,2,1.1,2.7c0.5,0.6,1.1,0.9,1.9,1
									C1848,801.5,1848.4,801.3,1848.7,801z"
						/>
						<path
							d="M1856.6,800.2c-0.8-0.8-1.4-2-1.8-3.8c-0.4-1.8-0.3-3.2,0.1-4.3c0.5-1.1,1.3-1.7,2.6-2c1-0.2,2-0.1,3,0.4
									c-0.4-1.6-0.8-2.9-1.2-3.6c0.4-0.3,0.8-0.5,1.2-0.6c0.4-0.2,0.8-0.3,1.3-0.4c0.3,2.1,0.9,5.7,1.9,10.6c0.1,0.6,0.3,1,0.5,1.3
									c0.2,0.3,0.5,0.6,0.9,1c-0.4,0.6-1,1-1.6,1.3c-0.5-0.2-0.9-0.7-1.2-1.2c-0.3,0.5-0.7,1-1.2,1.3c-0.5,0.4-0.9,0.6-1.4,0.7
									C1858.4,801.2,1857.4,800.9,1856.6,800.2z M1861,798.9c0.3-0.3,0.7-0.6,1-1c-0.1-0.5-0.3-1.4-0.5-2.6c-0.3-1.4-0.5-2.4-0.6-3.2
									c-0.7-0.3-1.4-0.4-2.2-0.2c-0.8,0.2-1.3,0.6-1.6,1.3c-0.3,0.7-0.3,1.6-0.1,2.8c0.2,1.1,0.6,2,1.2,2.6c0.5,0.6,1.2,0.9,1.9,0.9
									C1860.3,799.4,1860.7,799.2,1861,798.9z"
						/>
						<path
							d="M1869,797.3c-0.9-0.7-1.6-1.8-2.1-3.4c-0.3-1.1-0.4-2.1-0.3-3c0.1-1,0.5-1.8,1-2.5c0.6-0.7,1.3-1.2,2.2-1.4
									c1.2-0.4,2.2-0.2,3.1,0.5c0.9,0.7,1.6,1.8,2.1,3.5c-0.7,0.5-1.6,0.9-2.7,1.4s-2.2,0.9-3.2,1.3c0.4,1,0.9,1.6,1.4,1.9
									c0.5,0.3,1.2,0.4,2,0.1c0.6-0.2,1.2-0.4,1.7-0.8c0.5-0.3,0.9-0.7,1.2-1.1l0.3,0.4c-0.3,0.8-0.7,1.5-1.4,2.1
									c-0.7,0.6-1.4,1.1-2.2,1.3C1870.9,798.1,1869.9,798,1869,797.3z M1870.9,791.8c0.7-0.4,1.2-0.8,1.5-1.1c-0.3-0.9-0.7-1.5-1.3-1.9
									s-1.3-0.5-1.9-0.3c-0.4,0.4-0.6,1-0.7,1.8c-0.1,0.8,0,1.7,0.3,2.6C1869.6,792.6,1870.2,792.2,1870.9,791.8z"
						/>
						<path
							d="M1880.8,794.7c-0.5-1.6-1.1-3.3-1.8-5.1c-0.3-0.9-0.6-1.5-0.8-2s-0.4-0.8-0.7-1s-0.5-0.4-0.9-0.6c0.1-0.4,0.3-0.7,0.5-0.9
									c0.2-0.2,0.4-0.4,0.6-0.5c0.8-0.3,1.4,0,1.9,0.9c0.2-0.6,0.5-1.2,0.9-1.6c0.4-0.5,0.9-0.8,1.4-1c1.7-0.7,3,0.1,3.8,2.3l0.5,1.5
									c0.2,0.6,0.4,1.1,0.5,1.6c0.6,1.6,1.2,2.8,1.8,3.4c-0.4,0.3-0.7,0.4-1.1,0.6c-0.4,0.1-0.8,0.3-1.4,0.4c-0.6-2-1.4-4.1-2.3-6.3
									c-0.2-0.6-0.5-1-0.9-1.2c-0.3-0.2-0.8-0.2-1.3,0c-0.8,0.3-1.4,0.7-1.8,1.3l0.4,1c0.3,0.8,0.6,1.8,1,2.9c0.6,1.7,1.2,2.8,1.8,3.4
									c-0.4,0.3-0.8,0.5-1.2,0.6C1881.8,794.4,1881.4,794.6,1880.8,794.7z"
						/>
						<path
							d="M1821.9,832.3c-0.2-0.7-0.4-1.7-0.8-3c-0.4-1.3-0.7-2.5-1.1-3.6c-1-3.1-1.8-5.1-2.4-6.2c0.8-0.1,2-0.5,3.6-1
									c1.7-0.6,3.1-1.1,4.1-1.5c0.1,0.4,0.3,0.9,0.3,1.4c0.1,0.5,0.1,0.9,0,1.2c-0.4-0.1-0.9-0.1-1.4-0.1c-0.5,0-1,0.1-1.5,0.3
									c-0.8,0.3-1.5,0.6-2.1,0.9c0.4,1.5,0.8,2.8,1.1,3.7l0.5-0.1c0.5-0.1,0.8-0.2,1.1-0.3c0.6-0.2,1.1-0.4,1.4-0.5
									c0.3-0.1,0.7-0.4,1.1-0.6c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.3,0.2,0.6,0.2,1l-1,0.3c-0.9,0.2-1.8,0.5-2.6,0.7
									c-0.4,0.1-0.6,0.2-0.8,0.3c1,3.1,1.8,5,2.2,5.6c-0.4,0.2-0.8,0.4-1.2,0.6C1822.8,832.1,1822.4,832.2,1821.9,832.3z"
						/>
						<path
							d="M1828.4,815.7c0.3-0.7,0.6-1.4,0.9-1.9c0.6,0.4,1.2,0.8,1.8,1.1c-0.3,0.7-0.6,1.3-0.9,1.9
									C1829.7,816.4,1829,816,1828.4,815.7z M1833,828.5c-0.3-0.2-0.5-0.7-0.7-1.5c-0.2-0.6-0.4-1.5-0.7-2.6c-0.3-1.1-0.5-1.8-0.6-2.3
									c-0.2-0.7-0.4-1.3-0.7-1.8c-0.2-0.5-0.5-0.9-0.7-1.1c0.4-0.3,0.7-0.5,1.1-0.7c0.4-0.2,0.8-0.3,1.3-0.5c0.1,0.5,0.2,1.2,0.5,2.2
									c0.2,1,0.5,2.1,0.9,3.3c0.3,0.9,0.5,1.6,0.7,2c0.2,0.5,0.4,0.8,0.6,1.1c0.2,0.2,0.5,0.5,0.8,0.7c-0.2,0.4-0.4,0.7-0.6,0.9
									c-0.2,0.2-0.5,0.4-0.7,0.4C1833.6,828.8,1833.2,828.8,1833,828.5z"
						/>
						<path
							d="M1839.2,825.9c-0.9-0.7-1.5-1.8-1.9-3.4c-0.3-1.1-0.3-2.1-0.2-3.1c0.2-1,0.5-1.8,1.1-2.4c0.6-0.7,1.3-1.1,2.2-1.3
									c1.2-0.3,2.2-0.1,3.1,0.6c0.9,0.7,1.5,1.9,1.9,3.6c-0.7,0.4-1.7,0.9-2.8,1.3s-2.2,0.8-3.3,1.2c0.4,1,0.8,1.6,1.3,2
									c0.5,0.3,1.2,0.4,2,0.2c0.6-0.2,1.2-0.4,1.7-0.7c0.5-0.3,0.9-0.6,1.2-1l0.3,0.4c-0.3,0.8-0.8,1.5-1.5,2.1c-0.7,0.6-1.4,1-2.2,1.2
									C1841.1,826.8,1840,826.6,1839.2,825.9z M1841.3,820.5c0.7-0.4,1.2-0.7,1.6-1.1c-0.2-0.9-0.7-1.5-1.3-2c-0.6-0.4-1.2-0.6-1.9-0.4
									c-0.4,0.4-0.6,1-0.7,1.8c-0.1,0.8,0,1.7,0.2,2.6C1839.9,821.2,1840.6,820.9,1841.3,820.5z"
						/>
						<path
							d="M1851,823.8c-0.2-0.2-0.5-0.8-0.6-1.5c-0.1-0.5-0.3-1.3-0.5-2.5c-0.3-1.3-0.5-2.3-0.7-3.1c-0.6-2.7-1.2-4.5-1.7-5.6
									c0.4-0.3,0.8-0.5,1.1-0.7c0.4-0.2,0.8-0.3,1.3-0.4c0.3,2.1,0.9,5.1,1.8,8.9c0.2,0.9,0.4,1.6,0.6,2.1s0.3,0.8,0.5,1.1
									s0.4,0.5,0.8,0.7c-0.2,0.4-0.4,0.6-0.6,0.9c-0.3,0.2-0.5,0.3-0.7,0.4C1851.6,824.1,1851.2,824,1851,823.8z"
						/>
						<path
							d="M1857.4,821.5c-0.8-0.8-1.4-2-1.7-3.8c-0.4-1.8-0.3-3.2,0.2-4.3c0.5-1,1.4-1.7,2.6-1.9c1-0.2,2,0,3,0.5
									c-0.4-1.7-0.8-2.9-1.2-3.6c0.4-0.3,0.8-0.5,1.2-0.6c0.4-0.2,0.8-0.3,1.3-0.4c0.2,2.1,0.8,5.7,1.8,10.6c0.1,0.6,0.3,1,0.5,1.4
									c0.2,0.3,0.5,0.6,0.9,1c-0.4,0.6-1,1-1.7,1.3c-0.5-0.3-0.9-0.7-1.2-1.2c-0.3,0.5-0.7,1-1.2,1.3s-1,0.6-1.5,0.7
									C1859.2,822.5,1858.2,822.3,1857.4,821.5z M1861.9,820.3c0.4-0.3,0.7-0.6,1-0.9c-0.1-0.5-0.3-1.4-0.5-2.6
									c-0.2-1.4-0.4-2.5-0.6-3.2c-0.7-0.3-1.4-0.4-2.2-0.2c-0.8,0.2-1.4,0.6-1.7,1.3c-0.3,0.7-0.3,1.6-0.1,2.8c0.2,1.2,0.6,2,1.1,2.6
									c0.5,0.6,1.2,0.9,1.9,0.9C1861.2,820.8,1861.5,820.6,1861.9,820.3z"
						/>
						<path
							d="M1868.9,819.7c0-0.3-0.1-0.6-0.1-0.9c0-0.3,0.1-0.6,0.2-1c0.4,0.4,0.8,0.6,1.3,0.8c0.5,0.2,1.1,0.2,1.6,0.1
									c1.5-0.2,2.1-0.8,2-1.7c-0.1-0.4-0.3-0.7-0.6-0.9c-0.4-0.2-0.9-0.4-1.7-0.6c-0.7-0.2-1.3-0.4-1.8-0.6c-0.5-0.2-0.9-0.5-1.3-0.9
									c-0.4-0.4-0.6-0.9-0.7-1.6c-0.1-0.8,0.1-1.5,0.7-2.1c0.6-0.6,1.4-1,2.4-1.1c1.4-0.2,2.6-0.2,3.6,0c0,0.3,0,0.6,0,1.1
									c-0.1,0.5-0.2,0.9-0.3,1.3c-0.6-0.5-1.3-0.8-1.8-1.1c-0.6-0.2-1-0.3-1.4-0.3c-0.4,0.1-0.6,0.3-0.8,0.6c-0.2,0.3-0.3,0.5-0.2,0.8
									c0.1,0.5,0.3,0.8,0.7,1.1c0.4,0.2,1,0.5,1.8,0.7c0.7,0.2,1.2,0.4,1.7,0.6c0.5,0.2,0.9,0.5,1.2,0.9c0.4,0.4,0.6,0.9,0.7,1.5
									c0.1,0.9-0.1,1.7-0.7,2.4c-0.6,0.6-1.5,1-2.8,1.2C1871.1,820.3,1869.8,820.2,1868.9,819.7z"
						/>
						<path
							d="M1881,805.6c1,1.4,1.6,3,1.9,4.7c0.2,1.7,0,3.4-0.6,5c-0.6,1.6-1.6,3.1-2.9,4.4l-0.3-0.3c0.8-1.2,1.4-2.5,1.7-4
									c0.3-1.5,0.4-3.1,0.1-4.8s-0.7-3.2-1.3-4.6c-0.7-1.4-1.6-2.5-2.7-3.5l0.2-0.4C1878.7,803.1,1880,804.2,1881,805.6z"
						/>
					</g>
					<g className="regions level_two location" id="eryn_vorn">
						<rect x="1777.55" y="1592.876" width="115.42" height="35.039" transform="matrix(0.78701, 0.61694, -0.62642, 0.779579, 282.566803, -1302.79895)"></rect>
						<path
							d="M674.2,1062.3c0.2-1.1,0.4-2.6,0.7-4.5c0.5-3.4,0.6-5.7,0.5-7c1,0.3,2.5,0.6,4.6,0.9c2.5,0.4,4.5,0.6,5.9,0.6
									c-0.1,0.6-0.2,1.1-0.4,1.8c-0.2,0.6-0.4,1-0.6,1.3c-0.4-0.3-1-0.6-1.7-0.9c-0.7-0.2-1.4-0.4-2.2-0.5c-1-0.1-2-0.2-2.8-0.1
									c-0.3,1.7-0.6,3.2-0.8,4.6l0.7,0.1c0.7,0.1,1.3,0.2,1.6,0.3c0.9,0.1,1.6,0.2,2.1,0.2c0.5,0,1,0,1.6,0c0,0.4,0,0.8,0,1.2
									c0,0.3-0.1,0.7-0.3,1.2c-2.1-0.4-3.7-0.7-4.9-0.9c-0.6-0.1-0.9-0.1-1.2-0.1c-0.3,2.2-0.5,3.8-0.5,4.7c0.9,0.3,1.9,0.6,3,0.7
									c0.8,0.1,1.7,0.2,2.5,0.1c0.9,0,1.6-0.1,2.1-0.3c-0.1,0.6-0.2,1.1-0.4,1.8s-0.4,1-0.6,1.2c-1.2-0.3-2.9-0.6-5.2-0.9
									c-2-0.3-3.6-0.4-4.6-0.4C673.7,1065,674,1063.4,674.2,1062.3z"
						/>
						<path
							d="M686.5,1069.3c0.7-1.9,1.4-4,2-6.3c0.3-1.1,0.5-1.9,0.6-2.5c0.1-0.6,0.1-1.1,0.1-1.5c0-0.4-0.2-0.8-0.4-1.2
									c0.4-0.3,0.8-0.4,1.2-0.5c0.4-0.1,0.7-0.1,1,0c1.1,0.3,1.4,1.2,1,2.7c0.7-0.5,1.3-0.9,2-1.1c0.7-0.2,1.4-0.4,2.1-0.4
									c0,0.4,0,0.8-0.1,1.5c-0.1,0.6-0.2,1.2-0.3,1.7c-0.2-0.1-0.5-0.2-0.9-0.3c-0.6-0.2-1.2-0.3-1.8-0.3c-0.6,0-1.1,0.1-1.5,0.2
									l-0.3,0.9c-0.4,1.3-0.8,2.5-1.1,3.5c-0.6,2.1-0.9,3.6-0.7,4.6c-0.6,0-1.1-0.1-1.6-0.3S687.1,1069.6,686.5,1069.3z"
						/>
						<path
							d="M691.8,1075.3c0.8-1.6,2.2-2.8,4.3-3.6c-0.7-1-0.6-2.4,0.2-4.1l0.7-1.4l0.8-1.6c0.9-1.9,1.3-3.3,1.4-4.2
									c0.6,0,1.1,0.1,1.6,0.2c0.5,0.1,1,0.3,1.6,0.6c-0.6,1-1.8,3.5-3.6,7.3c-0.3,0.8-0.5,1.4-0.3,1.8c0.1,0.5,0.5,0.8,1.2,1.1
									c1,0.5,2,0.6,2.9,0.4l1.5-3.3c0.1-0.3,0.3-0.6,0.4-1c0.4-0.9,0.7-1.7,1-2.4c0.2-0.7,0.4-1.3,0.4-1.7c1,0,2.1,0.3,3.2,0.8
									c-0.8,1.5-2,3.8-3.4,7c-0.6,1.3-1.1,2.4-1.4,3.2c-0.4,0.9-0.6,1.4-0.6,1.5c-0.8,1.7-1.8,2.7-3.2,3.1c-1.4,0.4-2.9,0.2-4.6-0.5
									C693.8,1077.6,692.6,1076.6,691.8,1075.3z M699.2,1077.1c0.8-0.4,1.6-1.4,2.3-2.9l0.6-1.3c-0.8,0.3-1.5,0.5-2.3,0.5
									c-0.8,0-1.5-0.1-2.2-0.5c-0.2-0.1-0.4-0.2-0.7-0.4c-1.1,0.6-2,1.3-2.5,2.1c0.2,0.5,0.4,1,0.8,1.4c0.4,0.4,0.8,0.8,1.4,1
									C697.5,1077.4,698.4,1077.5,699.2,1077.1z"
						/>
						<path
							d="M706.3,1077.6c1.3-1.6,2.6-3.4,3.9-5.3c0.6-0.9,1.1-1.7,1.4-2.2c0.3-0.5,0.5-1,0.5-1.4c0.1-0.4,0.1-0.8,0-1.3
									c0.5-0.1,0.9-0.2,1.3-0.1c0.4,0.1,0.7,0.2,0.9,0.3c0.8,0.6,1,1.4,0.4,2.5c0.8-0.2,1.5-0.3,2.3-0.2c0.8,0.1,1.4,0.3,2,0.7
									c1.9,1.3,2,3.1,0.4,5.4l-1.2,1.6c-0.5,0.6-0.9,1.2-1.2,1.7c-1.2,1.8-1.9,3.1-2.1,4.2c-0.5-0.2-1-0.4-1.4-0.7
									c-0.4-0.3-0.9-0.7-1.3-1.1c1.5-2,3.1-4.2,4.8-6.6c0.5-0.7,0.7-1.3,0.6-1.7c0-0.5-0.4-0.9-1-1.3c-0.9-0.6-1.7-0.9-2.6-0.9l-0.8,1.1
									c-0.6,0.8-1.4,1.9-2.2,3c-1.2,1.8-1.9,3.2-2.2,4.2c-0.5-0.2-1-0.5-1.5-0.8C707.2,1078.5,706.8,1078.1,706.3,1077.6z"
						/>
						<path
							d="M725.3,1092c0.1-0.8,0.4-1.6,0.8-2.5c0.4-0.9,1.1-2.2,2-3.9c1-1.8,1.8-3.4,2.4-4.6c0.6-1.2,1.1-2.4,1.5-3.7
									c1.1,0.4,2,1.1,2.9,2c-0.6,0.7-1.3,1.8-2.1,3.2c-0.9,1.4-1.7,2.8-2.5,4.2c-1.4,2.6-2.2,4.5-2.6,5.7c6.9-3.6,10.9-5.9,12-7
									c0.2-0.2,0.3-0.3,0.4-0.4c0.7,0.4,1.4,0.9,2.1,1.6c-1.9,0.9-4.2,2.2-7.2,3.7c-2.9,1.6-5.4,2.9-7.3,4l-2.1-0.5
									C725.4,1093.3,725.2,1092.7,725.3,1092z"
						/>
						<path
							d="M732.8,1098.9c0.3-1.5,1.2-2.8,2.7-4c1.1-0.9,2.2-1.4,3.3-1.7c1.2-0.3,2.3-0.2,3.3,0.2c1.1,0.4,2,1,2.7,2
									c0.7,0.8,1.1,1.7,1.2,2.7c0.1,1-0.1,1.9-0.5,2.9c-0.5,0.9-1.2,1.8-2.1,2.6c-1.1,0.9-2.2,1.4-3.4,1.6s-2.3,0.2-3.4-0.2
									c-1.1-0.4-2-1.1-2.8-2.1C732.8,1101.8,732.5,1100.4,732.8,1098.9z M737.7,1103.8c1,0,2.1-0.5,3.2-1.4c1-0.8,1.7-1.7,2.1-2.7
									c0.4-1,0.4-2,0-3c-0.7-0.9-1.6-1.3-2.6-1.3c-1.1,0-2.1,0.4-3.2,1.3c-1.3,1-2.1,2-2.4,3.1c-0.3,1.1-0.2,2,0.4,2.7
									C735.8,1103.4,736.7,1103.8,737.7,1103.8z"
						/>
						<path
							d="M740,1110.6c1.8-1.1,3.6-2.3,5.5-3.7c0.9-0.7,1.6-1.2,2.1-1.6c0.4-0.4,0.8-0.8,1-1.1c0.2-0.3,0.4-0.7,0.5-1.2
									c0.5,0,0.9,0.1,1.3,0.3c0.4,0.2,0.6,0.4,0.8,0.6c0.6,0.9,0.4,1.8-0.9,2.7c0.8,0,1.6,0.1,2.3,0.4c0.7,0.2,1.3,0.6,1.9,1
									c-0.2,0.3-0.5,0.7-0.9,1.1c-0.4,0.4-0.9,0.8-1.3,1.1c-0.1-0.2-0.3-0.5-0.5-0.8c-0.4-0.5-0.8-0.9-1.2-1.3c-0.5-0.4-0.9-0.6-1.3-0.8
									l-0.8,0.5c-1.1,0.8-2.1,1.5-3,2.1c-1.8,1.3-2.9,2.3-3.4,3.2c-0.4-0.4-0.8-0.8-1.1-1.2C740.6,1111.6,740.3,1111.1,740,1110.6z"
						/>
						<path
							d="M745.3,1118c1.8-1,3.7-2.1,5.7-3.3c1-0.6,1.7-1.1,2.2-1.5c0.5-0.4,0.8-0.7,1-1c0.2-0.3,0.4-0.7,0.5-1.2
									c0.5,0.1,0.9,0.2,1.2,0.4s0.6,0.4,0.7,0.7c0.5,0.9,0.3,1.7-0.6,2.5c0.8,0.1,1.5,0.3,2.2,0.7c0.7,0.4,1.2,0.9,1.6,1.5
									c1.2,1.9,0.6,3.6-1.8,5.1l-1.7,1c-0.7,0.4-1.3,0.7-1.8,1c-1.8,1.1-3,2.1-3.6,3c-0.4-0.4-0.7-0.8-1-1.2c-0.3-0.4-0.5-0.9-0.8-1.6
									c2.2-1.2,4.5-2.6,7-4.1c0.7-0.4,1.1-0.9,1.3-1.3c0.1-0.5,0-1-0.4-1.6c-0.6-0.9-1.2-1.5-2-1.8l-1.1,0.7c-0.9,0.5-2,1.1-3.2,1.9
									c-1.8,1.2-3.1,2.1-3.6,3c-0.4-0.4-0.8-0.9-1-1.3C745.7,1119.1,745.5,1118.6,745.3,1118z"
						/>
					</g>
					<g className="level_zero location " id="baranduin">
						<rect x="2030.087" y="1592.878" width="131.818" height="35.039" transform="matrix(0.999179, 0.040516, -0.052551, 0.998691, -1245.232666, -698.815857)"></rect>
						<rect x="1704.898" y="1358.666" width="110.703" height="29.887" transform="matrix(0.252636, -0.967561, 0.964518, 0.26429, -928.016235, 2295.81665)"></rect>
						<rect x="1704.897" y="1358.666" width="110.703" height="29.887" transform="matrix(0.857819, -0.513951, 0.503619, 0.86401, -1309.390503, 602.468994)"></rect>
						<path
							d="M704.1,1000c-1.1-0.1-2.1-0.3-2.8-0.6c0-1,0.1-2.5,0-4.6c0-2-0.1-4-0.1-5.8c-0.1-3.7-0.4-5.9-0.8-6.7
									c0.8-0.3,1.7-0.6,2.9-0.8c1.2-0.2,2.3-0.4,3.4-0.4c2.1-0.1,3.7,0.3,4.8,1.1c1.1,0.8,1.7,2,1.8,3.6c0,0.9-0.2,1.7-0.6,2.4
									c-0.4,0.7-1.1,1.3-1.9,1.8c1.1,0.4,2,0.9,2.7,1.7s1,1.7,1.1,2.7c0.1,1.7-0.5,3.1-1.7,4.1c-1.2,1-2.9,1.5-5.2,1.6
									C706.4,1000.2,705.3,1000.2,704.1,1000z M707,989.5c0.6,0,1.1,0,1.4,0c1.3-1,1.9-2.1,1.8-3.2c-0.1-2-1.3-3-3.8-2.9
									c-0.9,0-1.7,0.2-2.4,0.4c0,1.7,0,3.8,0,6.1C704.8,989.7,705.8,989.6,707,989.5z M711.3,994.6c0-0.9-0.3-1.6-0.8-2.2
									c-0.5-0.6-1.1-1.1-2-1.4c-1,0-2.5,0.4-4.5,1.2c0.1,2.8,0.3,4.8,0.4,6.2c0.8,0.3,1.7,0.4,2.6,0.4
									C710,998.5,711.4,997.1,711.3,994.6z"
						/>
						<path
							d="M717.3,999c-0.5-0.6-0.8-1.4-0.7-2.4c0.1-2.8,2.7-4.3,7.8-4.5c0-1.5-0.1-2.6-0.5-3.2c-0.4-0.6-1.1-0.9-2.2-1
									c-0.7,0-1.4,0.1-2,0.3c-0.7,0.2-1.3,0.5-1.9,0.9l-0.2-0.7c0.6-1,1.4-1.8,2.5-2.4c1-0.6,2-0.9,3.1-0.9c1.4,0,2.5,0.5,3.2,1.3
									c0.7,0.8,1.1,2,1,3.5c0,0.8-0.1,1.8-0.2,2.8c-0.1,1.1-0.2,1.9-0.2,2.5c0,0.9,0,1.6,0.2,2.1c0.2,0.5,0.5,1,1,1.6
									c-0.8,0.7-1.7,1.1-2.7,1.2c-0.6-0.4-1-1.1-1.2-2c-0.8,0.6-1.6,1-2.5,1.3c-0.9,0.3-1.6,0.5-2.4,0.5
									C718.5,999.9,717.8,999.6,717.3,999z M722.3,997.9c0.6-0.3,1.2-0.6,1.7-1l0-0.2c0-0.7,0.1-1.5,0.2-2.2l0.1-1.5
									c-1.5,0-2.6,0.3-3.4,0.9c-0.8,0.5-1.2,1.3-1.3,2.3c0,0.4,0.1,0.8,0.3,1.2c0.2,0.4,0.4,0.7,0.8,0.9
									C721.2,998.3,721.7,998.1,722.3,997.9z"
						/>
						<path
							d="M731.7,1000.2c0.4-2.3,0.6-4.8,0.9-7.4c0.1-1.3,0.2-2.3,0.1-2.9c0-0.7-0.1-1.2-0.2-1.6c-0.1-0.4-0.4-0.8-0.7-1.3
									c0.4-0.4,0.8-0.7,1.2-0.8c0.4-0.2,0.8-0.2,1.1-0.2c1.2,0.1,1.8,1,1.7,2.7c0.6-0.7,1.3-1.3,2-1.7c0.7-0.4,1.4-0.7,2.3-0.9
									c0.1,0.4,0.2,0.9,0.3,1.6s0.1,1.4,0,1.9c-0.3-0.1-0.6-0.1-1.1-0.2c-0.7-0.1-1.4,0-2,0.1c-0.6,0.1-1.2,0.3-1.6,0.5l-0.1,1.1
									c-0.2,1.5-0.3,2.9-0.4,4.1c-0.2,2.4-0.1,4.2,0.2,5.3c-0.7,0.1-1.3,0.1-1.8,0.1C733,1000.5,732.4,1000.4,731.7,1000.2z"
						/>
						<path
							d="M741.2,1000.6c-0.5-0.7-0.6-1.5-0.5-2.4c0.3-2.8,3.1-4.1,8.2-3.8c0.2-1.5,0.1-2.6-0.2-3.3c-0.3-0.7-1.1-1-2.2-1.2
									c-0.7-0.1-1.4,0-2.1,0.1c-0.7,0.2-1.4,0.4-2,0.7l-0.2-0.7c0.7-1,1.6-1.7,2.7-2.2c1.1-0.5,2.1-0.7,3.2-0.6c1.4,0.2,2.4,0.7,3.1,1.6
									c0.7,0.9,0.9,2.1,0.7,3.6c-0.1,0.8-0.3,1.8-0.5,2.8c-0.2,1.1-0.4,1.9-0.4,2.5c-0.1,0.9-0.1,1.6,0,2.1c0.1,0.5,0.4,1.1,0.8,1.7
									c-0.9,0.6-1.8,1-2.8,1c-0.5-0.5-0.9-1.2-1-2.1c-0.8,0.5-1.7,0.9-2.6,1.1s-1.7,0.3-2.4,0.2C742.3,1001.6,741.6,1001.2,741.2,1000.6
									z M746.3,999.9c0.6-0.2,1.2-0.5,1.7-0.8l0-0.2c0.1-0.7,0.2-1.5,0.4-2.1l0.3-1.5c-1.5-0.1-2.6,0.1-3.5,0.6
									c-0.9,0.5-1.4,1.2-1.5,2.1c0,0.4,0,0.8,0.2,1.2c0.1,0.4,0.4,0.7,0.7,1C745.1,1000.2,745.7,1000.1,746.3,999.9z"
						/>
						<path
							d="M755.7,1002.9c0.4-2.3,0.8-4.7,1.2-7.4c0.2-1.3,0.3-2.2,0.3-2.9c0-0.7,0-1.2-0.1-1.6c-0.1-0.4-0.3-0.9-0.6-1.3
									c0.4-0.4,0.8-0.7,1.2-0.8c0.4-0.1,0.8-0.2,1.1-0.2c1.1,0.1,1.7,0.9,1.7,2.3c0.6-0.6,1.4-1.1,2.2-1.4c0.8-0.3,1.6-0.4,2.4-0.3
									c2.5,0.3,3.6,2.1,3.2,5.3l-0.3,2.2c-0.2,0.8-0.3,1.6-0.4,2.3c-0.3,2.4-0.3,4.1,0,5.3c-0.6,0.1-1.2,0.1-1.7,0
									c-0.6-0.1-1.2-0.2-1.9-0.5c0.5-2.8,1-5.8,1.4-9.1c0.1-0.9,0-1.6-0.3-2.1c-0.3-0.4-0.8-0.7-1.6-0.8c-1.2-0.2-2.2,0-3,0.4l-0.2,1.5
									c-0.2,1.2-0.4,2.6-0.6,4.2c-0.3,2.4-0.3,4.2,0,5.3c-0.7,0.1-1.3,0-1.9,0C757,1003.2,756.4,1003.1,755.7,1002.9z"
						/>
						<path
							d="M772,1003.6c-0.7-1.4-0.9-3.2-0.6-5.7c0.3-2.5,1-4.3,2.1-5.5c1.1-1.1,2.5-1.6,4.3-1.4c1.4,0.2,2.7,0.8,3.7,1.9
									c0.2-2.3,0.2-4.1,0.1-5.3c0.7-0.2,1.3-0.3,1.8-0.3c0.6,0,1.2,0,1.8,0.1c-0.6,2.9-1.4,7.7-2.4,14.6c-0.1,0.8-0.1,1.5,0,2
									c0.1,0.5,0.4,1.1,0.8,1.7c-0.8,0.6-1.7,0.9-2.7,0.9c-0.5-0.5-0.8-1.2-1-2.1c-0.7,0.5-1.4,0.9-2.1,1.2s-1.5,0.3-2.2,0.2
									C774,1005.7,772.7,1004.9,772,1003.6z M778.4,1004c0.6-0.2,1.2-0.5,1.7-0.8c0.1-0.7,0.3-1.9,0.6-3.6c0.3-1.9,0.5-3.4,0.7-4.5
									c-0.8-0.7-1.7-1.1-2.9-1.3c-1.1-0.2-2,0.2-2.8,0.9c-0.7,0.8-1.2,2-1.4,3.6c-0.2,1.6-0.1,2.9,0.3,3.9c0.4,1,1.1,1.7,2.1,2
									C777.3,1004.3,777.8,1004.2,778.4,1004z"
						/>
						<path
							d="M789.3,1002.9l0.1-2.2l0.1-2.3c0-2.3-0.2-3.9-0.6-4.9c1.1-0.5,2.3-0.7,3.6-0.7c-0.1,2.8-0.2,6.1-0.2,9.8
									c0,0.9,0.2,1.6,0.6,2c0.4,0.4,1,0.6,1.8,0.6c1.1,0,2-0.3,2.9-0.8c0-1.6,0-3,0.1-4.2l0-1.9c0-2.3-0.2-3.9-0.6-4.9
									c1.1-0.5,2.3-0.7,3.6-0.7c-0.1,1.6-0.2,3.5-0.2,5.7c-0.1,2.2-0.1,3.7-0.1,4.4c0,0.8,0.1,1.5,0.3,2c0.2,0.5,0.5,1,1,1.6
									c-0.8,0.7-1.6,1.1-2.6,1.3c-0.7-0.5-1.1-1.2-1.3-2.1c-0.6,0.7-1.3,1.2-2.1,1.5c-0.8,0.4-1.6,0.6-2.5,0.6
									C790.5,1007.7,789.3,1006.1,789.3,1002.9z"
						/>
						<path
							d="M803.6,987.7c0.5-1,1-1.8,1.6-2.5c0.7,0.7,1.5,1.3,2.4,1.8c-0.5,0.9-1,1.7-1.6,2.5C805.2,988.9,804.5,988.3,803.6,987.7z
									M808,1005.9c-0.3-0.4-0.6-1.1-0.8-2.1c-0.2-0.8-0.4-2.1-0.6-3.6c-0.2-1.5-0.4-2.6-0.5-3.2c-0.2-0.9-0.4-1.8-0.6-2.6
									c-0.3-0.8-0.5-1.3-0.8-1.6c0.6-0.4,1.1-0.6,1.6-0.8c0.5-0.2,1.1-0.4,1.8-0.5c0,0.7,0.1,1.7,0.3,3.1c0.2,1.4,0.4,3,0.7,4.7
									c0.2,1.3,0.4,2.2,0.6,2.9c0.2,0.7,0.4,1.2,0.6,1.5c0.2,0.4,0.6,0.7,1,1.1c-0.3,0.5-0.6,0.9-1,1.1c-0.4,0.3-0.7,0.5-1,0.5
									C808.8,1006.4,808.3,1006.3,808,1005.9z"
						/>
						<path
							d="M820,1003.5c-0.9-2.1-2-4.4-3.2-6.7c-0.6-1.1-1.1-2-1.4-2.6c-0.4-0.6-0.7-1-1.1-1.3c-0.3-0.3-0.7-0.5-1.3-0.7
									c0.1-0.6,0.3-1,0.5-1.4c0.3-0.4,0.5-0.6,0.8-0.8c1-0.5,1.9-0.2,2.7,1c0.2-0.9,0.5-1.7,1-2.4c0.5-0.7,1.1-1.2,1.8-1.6
									c2.3-1.2,4.1-0.3,5.6,2.6l0.9,2c0.3,0.8,0.7,1.5,1,2.1c1.1,2.1,2.1,3.6,3,4.4c-0.5,0.4-1,0.7-1.4,1c-0.5,0.3-1.1,0.5-1.8,0.7
									c-1.2-2.6-2.5-5.3-4-8.3c-0.4-0.8-0.9-1.3-1.4-1.6c-0.5-0.2-1.1-0.1-1.8,0.2c-1.1,0.5-1.8,1.2-2.3,2.1l0.7,1.4
									c0.5,1.1,1.1,2.4,1.8,3.8c1.1,2.2,2.1,3.6,3,4.4c-0.5,0.4-1,0.8-1.6,1C821.4,1003.1,820.8,1003.3,820,1003.5z"
						/>
						<path
							d="M827.2,966.5l0.6-0.1c0.7,1.9,1.8,3.6,3.2,5.1c1.5,1.5,3.2,2.8,5.3,3.8c2.1,1,4.1,1.7,6.2,2c2.1,0.3,4.1,0.1,6-0.4
									l0.2,0.5c-2.3,1.1-4.6,1.8-7,1.8c-2.4,0.1-4.7-0.4-6.8-1.5s-3.8-2.6-5.2-4.6C828.5,971.2,827.6,969,827.2,966.5z"
						/>
						<path
							d="M850.1,965.7c-0.3,1.1-0.6,2-1,2.7c-1-0.2-2.5-0.5-4.5-0.7c-2-0.3-3.9-0.5-5.8-0.8c-3.7-0.4-5.9-0.5-6.8-0.2
									c-0.2-0.8-0.3-1.8-0.3-3c0-1.2,0-2.3,0.1-3.4c0.2-2,0.8-3.6,1.8-4.6c1-1,2.3-1.4,3.8-1.2c0.9,0.1,1.6,0.4,2.2,1
									c0.6,0.6,1.1,1.3,1.5,2.2c0.5-1.1,1.2-1.8,2.1-2.4c0.9-0.5,1.8-0.7,2.9-0.6c1.7,0.2,3,1,3.8,2.3c0.8,1.3,1.1,3.1,0.8,5.4
									C850.6,963.5,850.4,964.6,850.1,965.7z M840.2,961.3c0.1-0.6,0.1-1.1,0.2-1.3c-0.8-1.4-1.8-2.2-2.9-2.3c-2-0.2-3.2,0.9-3.5,3.3
									c-0.1,0.9-0.1,1.7,0.1,2.5c1.7,0.3,3.7,0.6,6.1,0.9C840,963.5,840,962.5,840.2,961.3z M845.8,957.8c-0.9-0.1-1.6,0-2.3,0.4
									c-0.7,0.4-1.3,0.9-1.7,1.7c-0.1,1,0,2.5,0.4,4.6c2.8,0.3,4.8,0.5,6.2,0.5c0.4-0.7,0.6-1.6,0.8-2.5
									C849.5,959.7,848.4,958.1,845.8,957.8z"
						/>
						<path
							d="M851,951c-2.3-0.5-4.7-0.9-7.4-1.2c-1.3-0.2-2.2-0.3-2.9-0.3c-0.7,0-1.2,0-1.6,0.1s-0.9,0.3-1.3,0.6
									c-0.4-0.4-0.6-0.8-0.8-1.3c-0.2-0.4-0.2-0.8-0.2-1.1c0.2-1.2,1.1-1.8,2.8-1.6c-0.7-0.7-1.2-1.3-1.6-2c-0.4-0.7-0.6-1.5-0.8-2.3
									c0.4-0.1,0.9-0.2,1.6-0.2c0.7,0,1.4,0,1.9,0.1c-0.1,0.3-0.2,0.6-0.2,1.1c-0.1,0.7-0.1,1.4,0,2c0.1,0.6,0.2,1.2,0.4,1.6l1.1,0.2
									c1.5,0.2,2.9,0.4,4.1,0.6c2.4,0.3,4.2,0.3,5.3,0c0.1,0.7,0,1.3,0,1.8C851.4,949.7,851.2,950.3,851,951z"
						/>
						<path
							d="M851.5,942.1c-0.7,0.3-1.6,0.4-2.5,0.1c-2.7-0.7-3.6-3.6-2.6-8.7c-1.5-0.4-2.6-0.5-3.3-0.3c-0.7,0.2-1.2,0.9-1.5,2
									c-0.2,0.6-0.2,1.3-0.2,2.1c0,0.7,0.2,1.4,0.4,2.1l-0.7,0.1c-0.8-0.8-1.4-1.8-1.8-3c-0.4-1.1-0.4-2.2-0.1-3.2c0.4-1.4,1-2.3,2-2.8
									c1-0.5,2.2-0.6,3.7-0.2c0.8,0.2,1.7,0.5,2.7,0.9c1.1,0.4,1.8,0.6,2.4,0.8c0.9,0.2,1.5,0.3,2.1,0.3c0.5,0,1.1-0.2,1.8-0.5
									c0.5,1,0.7,1.9,0.6,2.9c-0.6,0.5-1.3,0.7-2.2,0.7c0.4,0.9,0.6,1.8,0.7,2.7c0.1,0.9,0.1,1.7-0.1,2.4
									C852.7,941.2,852.3,941.7,851.5,942.1z M851.6,936.9c-0.1-0.7-0.3-1.3-0.5-1.8l-0.2,0c-0.7-0.2-1.4-0.4-2.1-0.7l-1.4-0.5
									c-0.3,1.4-0.3,2.6,0.1,3.6c0.3,0.9,1,1.5,1.9,1.8c0.4,0.1,0.8,0.1,1.2,0c0.4-0.1,0.8-0.3,1.1-0.5
									C851.8,938.2,851.8,937.6,851.6,936.9z"
						/>
						<path
							d="M855.9,929.4c-2-1.2-4.1-2.5-6.4-3.7c-1.1-0.6-2-1-2.6-1.3c-0.6-0.3-1.2-0.4-1.6-0.5c-0.4,0-0.9,0-1.5,0.1
									c-0.2-0.5-0.3-1-0.3-1.4c0-0.4,0.1-0.8,0.2-1.1c0.5-1,1.5-1.3,2.8-0.7c-0.3-0.8-0.5-1.7-0.5-2.5c0-0.9,0.2-1.7,0.6-2.4
									c1.2-2.2,3.2-2.6,6.1-1.1l1.9,1.1c0.7,0.5,1.4,0.9,2,1.2c2.1,1.1,3.8,1.8,4.9,1.9c-0.2,0.6-0.4,1.1-0.6,1.6
									c-0.3,0.5-0.6,1-1.1,1.6c-2.4-1.5-5.1-3-8-4.6c-0.8-0.4-1.5-0.6-2-0.5c-0.5,0.1-1,0.5-1.4,1.2c-0.6,1-0.8,2-0.7,3l1.3,0.7
									c1,0.6,2.2,1.3,3.7,2.1c2.2,1.2,3.8,1.8,4.9,1.9c-0.2,0.6-0.4,1.2-0.7,1.7C856.7,928.3,856.3,928.8,855.9,929.4z"
						/>
						<path
							d="M862.4,915.7c-1.5-0.2-3.2-1.1-5-2.7c-1.9-1.7-3-3.3-3.3-4.9c-0.3-1.5,0.1-3,1.3-4.3c1-1.1,2.2-1.7,3.7-2
									c-1.8-1.5-3.2-2.5-4.3-3c0.2-0.6,0.5-1.2,0.8-1.7c0.3-0.5,0.7-1,1.1-1.5c2,2.2,5.5,5.6,10.7,10.2c0.6,0.5,1.1,0.9,1.6,1.1
									c0.5,0.2,1.1,0.3,1.8,0.3c0,1-0.2,1.9-0.8,2.8c-0.7,0.1-1.5,0-2.3-0.4c0.1,0.9,0,1.7-0.2,2.4c-0.2,0.8-0.6,1.4-1.1,2
									C865.3,915.3,863.9,915.8,862.4,915.7z M866.4,910.7c0.2-0.6,0.3-1.2,0.3-1.9c-0.5-0.5-1.4-1.3-2.7-2.5c-1.4-1.3-2.5-2.3-3.3-3.1
									c-1,0.2-1.9,0.8-2.7,1.6c-0.8,0.8-1,1.8-0.8,2.8c0.2,1,1,2.1,2.2,3.2c1.2,1.1,2.3,1.7,3.4,2c1.1,0.2,2,0,2.9-0.6
									C866,911.8,866.2,911.3,866.4,910.7z"
						/>
						<path
							d="M875.9,910.5c-1.3-1.6-1.7-3.6-1.4-6.1c-1.3,0.2-2.7-0.6-4-2.3l-1-1.4l-1.2-1.7c-1.5-1.9-2.6-3.1-3.5-3.6
									c0.3-0.6,0.7-1.1,1-1.5c0.3-0.4,0.8-0.8,1.4-1.3c0.7,1.1,2.6,3.6,5.5,7.4c0.6,0.7,1.1,1.2,1.7,1.3c0.5,0.1,1.1-0.1,1.7-0.6
									c1-0.8,1.6-1.7,2-2.7l-2.6-3.2c-0.2-0.3-0.5-0.6-0.8-0.9c-0.7-0.9-1.3-1.6-1.9-2.2c-0.6-0.6-1.1-1-1.6-1.3c0.5-1,1.4-1.9,2.4-2.8
									c1,1.6,2.8,3.9,5.2,7c1,1.2,1.8,2.3,2.5,3.1c0.7,0.8,1.1,1.3,1.2,1.4c1.3,1.6,1.8,3.3,1.4,4.9c-0.3,1.6-1.3,3-2.9,4.3
									C879.2,909.7,877.5,910.4,875.9,910.5z M881.4,904.1c0-1-0.6-2.3-1.8-3.8l-1-1.3c-0.1,0.9-0.3,1.8-0.7,2.6c-0.4,0.8-0.9,1.4-1.6,2
									c-0.2,0.1-0.4,0.3-0.8,0.5c0,1.4,0.3,2.6,0.8,3.6c0.6,0.1,1.2,0.1,1.8-0.1c0.6-0.2,1.2-0.4,1.7-0.8
									C880.9,906,881.4,905.1,881.4,904.1z"
						/>
						<path
							d="M885.1,894.4l-3.1-3.2c-0.7-0.8-1.4-1.4-1.9-2c-0.7-0.7-1.4-1.3-2.1-1.8c-0.7-0.5-1.2-0.8-1.6-0.9
									c0.4-0.5,0.8-0.9,1.2-1.3c0.4-0.4,1-0.8,1.7-1.1c0.6,0.8,2.1,2.4,4.4,4.9c2.3,2.4,3.7,3.8,4.3,4.3c-1-5.3-1.7-8.5-2.1-9.8
									c-0.8-0.6-1.4-1-1.9-1.1c0.7-0.9,1.7-1.8,2.9-2.4c0.6,0.8,2.1,2.5,4.5,5c2.4,2.5,3.8,3.9,4.2,4.1c-0.7-3.8-1.3-6.5-1.6-8.1
									c-0.4-1.5-0.7-2.6-1-3.1c-0.1-0.2-0.3-0.4-0.5-0.6c0.6-0.6,1.4-1.2,2.4-1.7c0.2,1.6,0.5,3.6,0.9,6.1c0.4,2.5,1,5.2,1.6,8.1l-1,1.8
									c-0.6,0.3-1.2,0.4-1.8,0.2s-1.3-0.7-2-1.4c-2.2-2.3-3.7-3.9-4.6-4.8c0.4,2.1,0.9,4.9,1.7,8.4l-1,1.8
									C887.7,896.3,886.5,895.9,885.1,894.4z"
						/>
						<path
							d="M894.8,870.9c0.2-1.1,0.4-2,0.8-2.8c0.9,0.4,1.8,0.8,2.8,1c-0.2,1-0.4,1.9-0.8,2.9C896.6,871.5,895.7,871.2,894.8,870.9z
									M904.2,887.1c-0.4-0.3-0.9-0.9-1.4-1.8c-0.4-0.8-0.9-1.9-1.6-3.3c-0.6-1.3-1.1-2.3-1.4-3c-0.4-0.9-0.9-1.6-1.3-2.3
									c-0.5-0.7-0.9-1.1-1.2-1.3c0.4-0.5,0.9-0.9,1.3-1.3s1-0.7,1.6-1c0.2,0.7,0.6,1.6,1.2,2.9c0.6,1.3,1.3,2.7,2.1,4.3
									c0.6,1.1,1.1,2,1.4,2.6c0.4,0.6,0.7,1,1.1,1.3c0.3,0.3,0.7,0.5,1.3,0.7c-0.1,0.5-0.3,1-0.6,1.4c-0.3,0.4-0.6,0.6-0.8,0.8
									C905.1,887.3,904.6,887.3,904.2,887.1z"
						/>
						<path
							d="M911.5,883.7c-1-2.1-2.1-4.3-3.4-6.7c-0.6-1.1-1.1-2-1.5-2.5c-0.4-0.6-0.8-1-1.1-1.2c-0.3-0.3-0.8-0.5-1.3-0.7
									c0.1-0.6,0.3-1,0.5-1.4c0.2-0.4,0.5-0.6,0.8-0.8c1-0.5,1.9-0.2,2.7,0.9c0.2-0.9,0.5-1.7,1-2.4c0.5-0.7,1.1-1.3,1.8-1.6
									c2.2-1.2,4.1-0.4,5.7,2.4l1,2c0.4,0.8,0.7,1.5,1,2.1c1.1,2.1,2.2,3.5,3.1,4.3c-0.5,0.4-0.9,0.7-1.4,1c-0.5,0.3-1.1,0.5-1.8,0.7
									c-1.2-2.6-2.6-5.3-4.2-8.2c-0.4-0.8-0.9-1.3-1.4-1.5c-0.5-0.2-1.1-0.1-1.8,0.3c-1,0.6-1.8,1.3-2.2,2.1l0.7,1.3
									c0.5,1.1,1.2,2.3,1.9,3.8c1.2,2.2,2.2,3.6,3.1,4.3c-0.5,0.4-1,0.8-1.5,1.1C912.8,883.2,912.2,883.4,911.5,883.7z"
						/>
						<path
							d="M925.4,874.4c-1.4-0.6-2.7-1.9-3.8-3.8c-0.8-1.3-1.2-2.7-1.3-4c-0.1-1.3,0.1-2.5,0.6-3.6c0.5-1.1,1.4-2,2.5-2.6
									c1.5-0.9,2.9-1,4.3-0.4c1.4,0.6,2.7,1.9,3.9,4c-0.8,0.8-1.9,1.8-3.2,2.8s-2.6,1.9-4,2.8c0.8,1.2,1.7,1.9,2.5,2.1
									c0.8,0.3,1.8,0.1,2.7-0.5c0.8-0.4,1.4-1,2-1.6c0.6-0.6,1-1.2,1.3-1.8l0.5,0.5c-0.1,1.1-0.5,2.2-1.2,3.3c-0.7,1.1-1.5,1.9-2.5,2.5
									C928.2,874.8,926.8,874.9,925.4,874.4z M926.2,866.4c0.8-0.8,1.3-1.4,1.7-2c-0.6-1.1-1.4-1.8-2.4-2.1s-1.9-0.3-2.7,0.2
									c-0.4,0.7-0.5,1.6-0.3,2.7c0.2,1.1,0.6,2.2,1.3,3.4C924.6,867.9,925.5,867.2,926.2,866.4z"
						/>
						<path
							d="M932.5,851.3c2.1,1.2,3.7,2.8,5,4.8c1.2,2,1.9,4.2,2,6.6c0.1,2.4-0.3,4.8-1.3,7.1l-0.5-0.2c0.4-2,0.4-4-0.1-6
									c-0.4-2.1-1.3-4.1-2.5-6.1c-1.2-2-2.6-3.6-4.3-5c-1.6-1.3-3.4-2.3-5.3-2.8l0.1-0.6C928.1,849.4,930.4,850.1,932.5,851.3z"
						/>
					</g>
					<g className="level_two fade location " id="sarn_ford">
						<rect x="1898.376" y="1434.203" width="123.265" height="31.552" transform="matrix(0.934836, -0.355079, 0.343819, 0.939113, -1442.604248, 166.007736)"></rect>
						<path
							d="M838.9,859.8c-0.2-0.4-0.4-0.9-0.5-1.2c-0.1-0.4-0.1-0.8-0.2-1.3c0.7,0.3,1.5,0.4,2.5,0.2c0.9-0.1,1.9-0.4,2.8-0.9
									c1.1-0.5,1.8-1.2,2.1-1.9c0.3-0.7,0.3-1.5-0.1-2.4c-0.4-0.7-0.9-1.2-1.7-1.3c-0.8-0.2-1.9-0.3-3.4-0.3c-1.3-0.1-2.4-0.1-3.2-0.2
									c-0.8-0.1-1.6-0.4-2.4-0.8c-0.8-0.5-1.4-1.1-1.9-2.1c-0.6-1.2-0.6-2.5,0.1-3.8c0.7-1.3,2-2.4,3.8-3.4c1.7-0.8,3.2-1.3,4.6-1.4
									c0.2,0.5,0.4,1,0.6,1.7c0.1,0.7,0.2,1.2,0.2,1.7c-1.2-0.2-2.3-0.3-3.4-0.3c-1.1,0-1.9,0.2-2.5,0.5c-0.4,0.3-0.7,0.8-0.8,1.4
									c-0.2,0.6-0.1,1.2,0.2,1.8c0.4,0.8,1,1.3,1.8,1.5c0.8,0.2,2,0.3,3.5,0.3c1.3,0,2.3,0.1,3.1,0.2c0.8,0.1,1.6,0.4,2.3,0.8
									c0.8,0.4,1.4,1.1,1.8,2c0.8,1.5,0.9,2.9,0.3,4.3c-0.6,1.3-1.8,2.5-3.8,3.5C842.7,859.3,840.8,859.8,838.9,859.8z"
						/>
						<path
							d="M854.5,852.3c-0.8-0.3-1.4-1-1.8-1.9c-1.2-2.6,0.5-5.2,5.1-7.8c-0.7-1.4-1.3-2.4-2-2.7c-0.6-0.4-1.5-0.3-2.5,0.1
									c-0.6,0.3-1.2,0.7-1.8,1.2c-0.5,0.5-1,1.1-1.3,1.7l-0.5-0.5c0.1-1.2,0.5-2.3,1.1-3.4c0.7-1,1.5-1.8,2.4-2.2
									c1.3-0.6,2.5-0.7,3.6-0.3c1.1,0.4,1.9,1.3,2.6,2.8c0.4,0.8,0.7,1.7,1.1,2.7c0.4,1.1,0.7,1.9,0.9,2.4c0.4,0.8,0.8,1.4,1.1,1.8
									c0.4,0.4,0.9,0.7,1.6,1.1c-0.4,1-1.1,1.8-1.9,2.4c-0.7-0.1-1.4-0.6-2-1.3c-0.5,0.9-1,1.7-1.7,2.4c-0.6,0.7-1.3,1.2-2,1.5
									C856,852.7,855.3,852.6,854.5,852.3z M858.7,849c0.4-0.5,0.8-1.1,1.1-1.6l-0.1-0.2c-0.3-0.7-0.6-1.4-0.8-2.1l-0.6-1.4
									c-1.3,0.7-2.3,1.5-2.8,2.4c-0.5,0.9-0.5,1.8-0.1,2.7c0.2,0.4,0.4,0.7,0.8,1c0.3,0.3,0.7,0.4,1.1,0.5
									C857.8,849.9,858.2,849.5,858.7,849z"
						/>
						<path
							d="M870.2,845.9c-0.8-2.3-1.7-4.7-2.7-7.2c-0.5-1.2-0.9-2.1-1.3-2.7c-0.3-0.6-0.7-1.1-1-1.4s-0.7-0.6-1.2-0.9
									c0.2-0.5,0.4-1,0.7-1.3c0.3-0.4,0.6-0.6,0.9-0.7c1.2-0.5,2.1,0.1,2.9,1.7c0.2-0.9,0.6-1.8,1-2.5c0.4-0.7,1-1.3,1.7-1.9
									c0.3,0.3,0.6,0.8,1,1.4c0.4,0.6,0.7,1.2,0.9,1.8c-0.3,0.1-0.6,0.2-1.1,0.4c-0.7,0.3-1.3,0.6-1.8,1c-0.5,0.4-0.9,0.8-1.2,1.2l0.4,1
									c0.6,1.5,1.1,2.8,1.5,3.9c1,2.3,1.9,3.9,2.7,4.7c-0.6,0.4-1.1,0.7-1.6,0.9C871.6,845.6,870.9,845.8,870.2,845.9z"
						/>
						<path
							d="M881.9,841.2c-0.7-2.3-1.5-4.7-2.4-7.3c-0.5-1.2-0.8-2.2-1.2-2.8c-0.3-0.6-0.6-1.1-0.9-1.4c-0.3-0.3-0.7-0.6-1.2-0.9
									c0.2-0.6,0.4-1,0.7-1.3c0.3-0.3,0.6-0.6,0.9-0.7c1.1-0.4,2,0,2.7,1.3c0.3-0.9,0.7-1.6,1.3-2.3c0.6-0.7,1.3-1.1,2.1-1.4
									c2.4-0.9,4.2,0.2,5.4,3.3l0.7,2.1c0.2,0.9,0.5,1.6,0.7,2.3c0.9,2.3,1.7,3.9,2.5,4.8c-0.5,0.4-1.1,0.6-1.6,0.8
									c-0.5,0.2-1.2,0.4-2,0.5c-0.9-2.8-1.9-5.8-3.1-8.9c-0.3-0.9-0.7-1.5-1.2-1.8c-0.5-0.3-1.1-0.3-1.9,0c-1.2,0.4-2,1-2.6,1.8l0.5,1.5
									c0.4,1.2,0.8,2.5,1.4,4.1c0.9,2.4,1.7,4,2.5,4.8c-0.6,0.4-1.1,0.7-1.7,0.9C883.3,840.9,882.7,841.1,881.9,841.2z"
						/>
						<path
							d="M901.1,834.3c-0.2-1-0.6-2.4-1.1-4.2c-0.5-1.8-1-3.5-1.6-5c-1.4-4.3-2.6-7.2-3.5-8.7c1.2-0.2,2.9-0.7,5.2-1.5
									c2.5-0.8,4.4-1.6,5.8-2.2c0.2,0.6,0.4,1.3,0.5,2c0.1,0.7,0.1,1.3,0,1.6c-0.6-0.1-1.2-0.2-2-0.1c-0.7,0.1-1.5,0.2-2.2,0.5
									c-1.1,0.4-2.1,0.8-3,1.3c0.6,2.2,1.1,3.9,1.5,5.2l0.7-0.2c0.7-0.2,1.2-0.3,1.5-0.4c0.9-0.3,1.6-0.6,2.1-0.8c0.5-0.2,1-0.5,1.6-0.9
									c0.2,0.4,0.4,0.8,0.5,1.2c0.1,0.4,0.2,0.8,0.3,1.3l-1.4,0.4c-1.4,0.3-2.6,0.7-3.7,1c-0.5,0.2-0.9,0.3-1.1,0.4
									c1.5,4.4,2.5,7,3.2,7.8c-0.5,0.3-1.1,0.6-1.7,0.8C902.4,834.1,901.8,834.2,901.1,834.3z"
						/>
						<path
							d="M912.7,828.7c-1.5-1-2.5-2.5-3.2-4.6c-0.5-1.5-0.6-2.9-0.4-4.3c0.2-1.4,0.8-2.5,1.6-3.5c0.9-1,1.9-1.7,3.3-2.1
									c1.2-0.4,2.3-0.4,3.4-0.1c1.1,0.3,2.1,0.9,2.9,1.8c0.8,0.9,1.5,2,1.9,3.4c0.5,1.5,0.6,3,0.3,4.3c-0.2,1.4-0.8,2.5-1.7,3.5
									c-0.9,1-2,1.7-3.4,2.1C915.8,829.9,914.2,829.7,912.7,828.7z M920.1,825.5c0.4-1.1,0.4-2.4-0.1-4c-0.4-1.4-1.1-2.6-2.1-3.4
									c-0.9-0.8-2-1.3-3.2-1.3c-1.2,0.4-2.1,1.1-2.5,2.3c-0.5,1.1-0.4,2.5,0,4c0.6,1.8,1.3,3.1,2.4,3.9c1,0.8,2.1,1.1,3.1,0.7
									C918.8,827.3,919.7,826.6,920.1,825.5z"
						/>
						<path
							d="M926.6,826.2c-0.5-2.3-1.2-4.8-1.9-7.4c-0.4-1.3-0.7-2.2-1-2.9c-0.3-0.6-0.5-1.1-0.8-1.5c-0.3-0.3-0.7-0.7-1.1-1
									c0.2-0.5,0.5-0.9,0.9-1.3c0.3-0.3,0.7-0.5,1-0.6c1.2-0.4,2.1,0.3,2.7,2c0.3-0.9,0.8-1.7,1.3-2.3c0.5-0.7,1.1-1.2,1.9-1.7
									c0.3,0.3,0.5,0.8,0.9,1.5c0.3,0.7,0.6,1.3,0.7,1.8c-0.3,0-0.6,0.1-1.1,0.3c-0.7,0.2-1.3,0.5-1.9,0.8c-0.6,0.4-1,0.7-1.3,1.1
									l0.3,1.1c0.4,1.5,0.8,2.9,1.1,4.1c0.7,2.4,1.4,4.1,2.2,5c-0.6,0.3-1.2,0.6-1.7,0.7C928,826,927.3,826.1,926.6,826.2z"
						/>
						<path
							d="M934.4,822.3c-1.2-1-2.2-2.7-2.9-5.1c-0.7-2.5-0.8-4.5-0.2-6c0.6-1.5,1.7-2.5,3.6-3c1.4-0.4,2.8-0.3,4.3,0.3
									c-0.8-2.3-1.4-3.9-2-5c0.6-0.4,1.1-0.8,1.6-1c0.5-0.3,1.1-0.5,1.8-0.7c0.5,3,1.8,7.9,3.7,14.8c0.2,0.8,0.5,1.4,0.8,1.9
									c0.3,0.4,0.8,0.8,1.4,1.3c-0.5,0.9-1.3,1.5-2.2,2c-0.7-0.3-1.3-0.8-1.8-1.6c-0.4,0.8-0.9,1.5-1.6,2c-0.6,0.5-1.3,0.9-2,1.1
									C937.1,823.6,935.6,823.3,934.4,822.3z M940.6,820.2c0.5-0.4,0.9-0.9,1.3-1.4c-0.2-0.7-0.5-1.9-0.9-3.7c-0.5-1.9-0.9-3.4-1.2-4.5
									c-1-0.3-2.1-0.4-3.2,0c-1.1,0.3-1.9,1-2.2,2c-0.4,1-0.3,2.3,0.1,4c0.4,1.6,1.1,2.8,1.9,3.6c0.8,0.8,1.8,1.1,2.8,1
									C939.7,821,940.1,820.6,940.6,820.2z"
						/>
					</g>
					<g className="level_two fade location" id="harlond">
						<rect x="1642.073" y="1448.688" width="106.623" height="31.87" transform="matrix(0.954147, -0.299337, 0.287848, 0.957752, -1622.875732, -124.178406)"></rect>
						<path
							d="M372,792.8c0-1.1,0-2.7-0.1-4.6c-0.1-2-0.2-3.9-0.3-5.6c-0.3-4.1-0.7-6.7-1.1-7.6c0.6-0.2,1.2-0.3,1.7-0.3
									c0.5,0,1.2,0,1.9,0.1c0,2,0.1,4.6,0.3,7.8c1.1,0,2.6,0,4.4-0.2c0.3,0,1.1-0.1,2.6-0.3c-0.3-4.2-0.7-6.8-1.1-7.8
									c0.6-0.2,1.1-0.3,1.7-0.3c0.5,0,1.1,0,1.8,0.1c0,1.1,0,2.7,0.1,4.6c0.1,2,0.2,3.9,0.3,5.6c0.3,4.1,0.7,6.6,1.1,7.6
									c-0.6,0.2-1.1,0.3-1.7,0.3c-0.5,0-1.1,0-1.8-0.1c0-1.9-0.1-4.6-0.3-7.9c-1.3,0-2.7,0.1-4.1,0.2c-0.9,0.1-1.9,0.2-2.9,0.3
									c0.3,4.2,0.7,6.8,1.1,7.9c-0.6,0.2-1.1,0.3-1.8,0.3C373.3,792.9,372.7,792.9,372,792.8z"
						/>
						<path
							d="M390.9,790.4c-0.6-0.5-1-1.2-1.2-2.1c-0.5-2.7,1.6-4.7,6.4-6c-0.3-1.5-0.7-2.5-1.2-3c-0.5-0.5-1.3-0.7-2.3-0.4
									c-0.6,0.1-1.3,0.4-1.9,0.7c-0.6,0.3-1.1,0.8-1.6,1.2l-0.4-0.6c0.4-1.1,1-2,1.8-2.8c0.8-0.8,1.7-1.3,2.7-1.5
									c1.3-0.3,2.5-0.1,3.3,0.6c0.9,0.6,1.4,1.7,1.7,3.1c0.2,0.8,0.3,1.7,0.4,2.7c0.1,1.1,0.2,1.9,0.3,2.4c0.2,0.8,0.4,1.5,0.6,1.9
									c0.3,0.4,0.7,0.9,1.3,1.3c-0.6,0.9-1.4,1.4-2.3,1.8c-0.6-0.3-1.1-0.8-1.5-1.6c-0.6,0.7-1.3,1.3-2.1,1.8c-0.7,0.5-1.5,0.8-2.1,0.9
									C392.2,791,391.5,790.8,390.9,790.4z M395.5,788.2c0.5-0.4,1-0.8,1.4-1.3l0-0.2c-0.1-0.7-0.2-1.4-0.3-2.1l-0.2-1.5
									c-1.4,0.4-2.4,0.9-3.1,1.6c-0.7,0.7-0.9,1.5-0.7,2.4c0.1,0.4,0.2,0.7,0.5,1.1c0.3,0.3,0.6,0.6,0.9,0.7
									C394.4,788.9,394.9,788.6,395.5,788.2z"
						/>
						<path
							d="M406.7,787.4c-0.5-2.2-1.1-4.6-1.8-7c-0.3-1.2-0.7-2.1-0.9-2.7c-0.3-0.6-0.5-1.1-0.8-1.4c-0.3-0.3-0.6-0.6-1.1-0.9
									c0.2-0.5,0.5-0.9,0.8-1.2c0.3-0.3,0.6-0.5,0.9-0.6c1.2-0.3,2,0.3,2.5,1.9c0.3-0.9,0.7-1.6,1.2-2.2c0.5-0.6,1.1-1.2,1.8-1.6
									c0.2,0.3,0.5,0.8,0.8,1.4c0.3,0.6,0.5,1.2,0.7,1.7c-0.3,0-0.6,0.1-1.1,0.2c-0.6,0.2-1.2,0.5-1.8,0.8c-0.5,0.3-1,0.7-1.3,1l0.3,1
									c0.4,1.4,0.7,2.7,1.1,3.8c0.7,2.3,1.3,3.9,2,4.7c-0.6,0.3-1.1,0.5-1.6,0.7C408,787.2,407.4,787.3,406.7,787.4z"
						/>
						<path
							d="M417.7,783.6c-0.4-0.3-0.7-0.9-1.1-1.9c-0.2-0.7-0.6-1.7-1.1-3.2c-0.6-1.6-1-3-1.4-4c-1.3-3.4-2.4-5.8-3.3-7.1
									c0.5-0.4,0.9-0.8,1.4-1.1c0.5-0.3,1-0.5,1.6-0.8c0.7,2.7,2,6.5,3.8,11.5c0.4,1.1,0.8,2,1.1,2.6s0.6,1.1,0.9,1.4
									c0.3,0.3,0.7,0.6,1.2,0.8c-0.2,0.5-0.4,0.9-0.7,1.2c-0.3,0.3-0.6,0.5-0.9,0.7C418.5,783.9,418,783.9,417.7,783.6z"
						/>
						<path
							d="M425.4,779.3c-1.5-0.7-2.7-2-3.5-3.9c-0.6-1.4-0.9-2.7-0.9-4c0-1.3,0.4-2.5,1.1-3.5c0.7-1,1.6-1.8,2.8-2.4
									c1.1-0.5,2.1-0.7,3.2-0.5c1.1,0.1,2,0.6,2.9,1.3c0.9,0.7,1.6,1.7,2.2,3c0.6,1.4,0.9,2.7,0.8,4c-0.1,1.3-0.4,2.5-1.2,3.5
									c-0.7,1-1.7,1.8-2.9,2.4C428.4,780,426.9,780,425.4,779.3z M431.9,775.4c0.2-1.1,0-2.3-0.6-3.8c-0.6-1.3-1.4-2.3-2.3-2.9
									c-1-0.7-2-1-3.2-0.8c-1.1,0.5-1.8,1.3-2.1,2.4s-0.1,2.4,0.5,3.7c0.7,1.6,1.6,2.7,2.7,3.4c1.1,0.7,2.1,0.8,3,0.3
									C431,777.2,431.7,776.4,431.9,775.4z"
						/>
						<path
							d="M440.9,773.6c-0.9-2.1-2-4.3-3.2-6.5c-0.6-1.1-1.1-1.9-1.4-2.5c-0.4-0.5-0.7-1-1-1.2c-0.3-0.3-0.7-0.5-1.2-0.7
									c0.1-0.5,0.3-1,0.5-1.3c0.2-0.3,0.5-0.6,0.8-0.7c1-0.5,1.9-0.2,2.7,0.9c0.2-0.9,0.5-1.6,1-2.3c0.5-0.7,1.1-1.2,1.8-1.6
									c2.2-1.1,4-0.3,5.5,2.4l0.9,1.9c0.3,0.8,0.7,1.5,1,2.1c1.1,2.1,2.1,3.5,3,4.2c-0.5,0.4-0.9,0.7-1.4,1c-0.5,0.3-1.1,0.5-1.8,0.7
									c-1.1-2.5-2.4-5.2-3.9-8c-0.4-0.8-0.9-1.3-1.4-1.5c-0.5-0.2-1.1-0.1-1.8,0.2c-1,0.5-1.8,1.2-2.2,2l0.6,1.3
									c0.5,1.1,1.1,2.3,1.8,3.7c1.1,2.1,2.1,3.5,2.9,4.2c-0.5,0.4-1,0.8-1.5,1C442.1,773.2,441.6,773.4,440.9,773.6z"
						/>
						<path
							d="M455.3,764.3c-1.4-0.6-2.7-1.9-3.9-4c-1.3-2.1-1.8-3.9-1.7-5.4c0.1-1.5,1-2.7,2.5-3.7c1.2-0.7,2.5-1,4-0.8
									c-1.3-1.9-2.3-3.2-3.1-4c0.4-0.5,0.8-1,1.2-1.3c0.4-0.4,0.9-0.7,1.5-1c1.2,2.6,3.6,6.7,7,12.5c0.4,0.7,0.8,1.2,1.2,1.5
									c0.4,0.3,0.9,0.6,1.6,0.8c-0.3,1-0.8,1.7-1.5,2.3c-0.7-0.1-1.4-0.4-2-1c-0.2,0.8-0.5,1.6-0.9,2.2c-0.4,0.7-0.9,1.2-1.5,1.5
									C458,764.8,456.6,764.9,455.3,764.3z M460.4,760.9c0.3-0.5,0.6-1.1,0.8-1.6c-0.4-0.6-1-1.6-1.8-3.1c-0.9-1.6-1.6-2.9-2.2-3.8
									c-1-0.1-2,0.2-2.9,0.8c-0.9,0.6-1.5,1.4-1.5,2.4c-0.1,1,0.3,2.2,1.1,3.6c0.8,1.3,1.7,2.3,2.6,2.8c0.9,0.5,1.9,0.6,2.8,0.2
									C459.7,761.8,460.1,761.4,460.4,760.9z"
						/>
					</g>
					<g className="level_two fade location" id="harlindon">
						<rect x="3290.849" y="2035.868" width="213.682" height="44.788" transform="matrix(0.693329, 0.720621, -0.72897, 0.684652, -438.634155, -2898.204102)"></rect>
						<path
							d="M338.5,888.2c1.4-0.8,3.3-2,5.8-3.6s4.7-3.2,6.8-4.7c5-3.5,7.9-5.9,8.7-7.2c0.7,0.6,1.2,1.2,1.7,1.9
									c0.5,0.7,0.9,1.5,1.3,2.4c-2.5,1.5-5.7,3.6-9.6,6.3c0.8,1.5,2,3.3,3.6,5.5c0.2,0.3,1,1.3,2.3,3c5.1-3.6,8.1-6,9-7.3
									c0.6,0.6,1.2,1.2,1.7,1.9c0.4,0.6,0.9,1.4,1.3,2.4c-1.4,0.8-3.3,2-5.8,3.6c-2.4,1.6-4.7,3.2-6.8,4.7c-4.9,3.5-7.8,5.9-8.7,7.1
									c-0.6-0.6-1.2-1.2-1.7-1.9c-0.4-0.6-0.9-1.4-1.3-2.3c2.5-1.4,5.7-3.6,9.7-6.3c-1-1.6-2.1-3.3-3.4-5c-0.8-1.1-1.7-2.3-2.6-3.5
									c-5.1,3.6-8.1,6.1-9.1,7.4c-0.6-0.6-1.2-1.2-1.8-1.9C339.3,889.9,338.9,889.1,338.5,888.2z"
						/>
						<path
							d="M356.2,911.6c0.3-1.1,1-2.1,2.1-2.9c3.3-2.4,7.2-0.7,11.9,4.9c1.8-1.3,2.9-2.4,3.2-3.4c0.4-1,0.1-2.1-0.9-3.4
									c-0.6-0.8-1.3-1.5-2.1-2.1c-0.8-0.6-1.7-1.1-2.7-1.4l0.6-0.9c1.7-0.2,3.3,0,4.9,0.7c1.6,0.6,2.8,1.5,3.7,2.7
									c1.2,1.6,1.6,3.2,1.3,4.8c-0.3,1.6-1.3,3-3.1,4.2c-1,0.7-2.1,1.4-3.4,2.2c-1.4,0.8-2.4,1.5-3,1.9c-1,0.7-1.8,1.4-2.2,2
									c-0.4,0.6-0.8,1.4-1,2.5c-1.5-0.3-2.8-1-3.7-1.9c0-1,0.4-2,1.2-3c-1.3-0.4-2.6-1-3.7-1.7s-2-1.4-2.6-2.3
									C356.1,913.8,355.9,912.7,356.2,911.6z M361.8,916.4c0.8,0.5,1.7,0.8,2.5,1l0.2-0.1c0.9-0.6,1.7-1.2,2.6-1.7l1.8-1.1
									c-1.3-1.6-2.7-2.7-4-3.1c-1.3-0.5-2.6-0.3-3.7,0.5c-0.5,0.3-0.8,0.8-1.1,1.3c-0.3,0.5-0.4,1.1-0.4,1.7
									C360.3,915.4,360.9,915.9,361.8,916.4z"
						/>
						<path
							d="M370.4,932.6c2.8-1.8,5.8-3.8,8.8-6c1.5-1.1,2.6-2,3.3-2.6c0.7-0.7,1.2-1.2,1.6-1.8c0.3-0.5,0.6-1.2,0.7-2
									c0.8,0,1.5,0.2,2.1,0.5c0.6,0.3,1,0.6,1.3,1c1,1.4,0.6,2.9-1.3,4.4c1.3,0,2.6,0.2,3.6,0.5c1.1,0.3,2.1,0.9,3.1,1.6
									c-0.3,0.5-0.8,1.1-1.5,1.8c-0.7,0.7-1.4,1.3-2.1,1.8c-0.2-0.3-0.4-0.8-0.9-1.3c-0.6-0.8-1.2-1.5-2-2.1c-0.7-0.6-1.4-1-2-1.2
									l-1.3,0.9c-1.8,1.2-3.4,2.4-4.8,3.4c-2.8,2.1-4.7,3.8-5.5,5.2c-0.7-0.6-1.3-1.3-1.8-1.9C371.3,934.3,370.9,933.6,370.4,932.6z"
						/>
						<path
							d="M382.1,947.8c0.2-0.6,1-1.4,2.2-2.4c0.8-0.6,2.2-1.6,4-2.9c2.1-1.5,3.8-2.7,5.1-3.7c4.3-3.2,7.2-5.7,8.7-7.6
									c0.8,0.5,1.5,1,2.1,1.5c0.6,0.5,1.2,1.2,1.8,2c-3.5,2.2-8.4,5.6-14.5,10.2c-1.4,1.1-2.5,2-3.2,2.6c-0.7,0.7-1.2,1.3-1.5,1.8
									c-0.3,0.5-0.5,1.2-0.7,2c-0.8,0-1.5-0.2-2.1-0.5c-0.6-0.3-1-0.6-1.3-1C382,949.2,381.9,948.5,382.1,947.8z"
						/>
						<path
							d="M391.8,960.4c0.2-0.7,0.9-1.5,2.1-2.4c1-0.8,2.3-1.9,4.1-3.2c1.7-1.3,2.9-2.3,3.7-2.9c1.1-0.9,2-1.7,2.8-2.6
									c0.8-0.8,1.3-1.5,1.5-2.1c0.8,0.4,1.6,0.9,2.2,1.4c0.6,0.5,1.2,1.2,1.9,1.9c-0.9,0.5-2.1,1.3-3.7,2.5c-1.6,1.2-3.4,2.6-5.4,4.1
									c-1.4,1.2-2.5,2.1-3.2,2.8c-0.7,0.7-1.2,1.3-1.5,1.8c-0.3,0.5-0.5,1.2-0.6,2c-0.8,0-1.5-0.1-2.1-0.4c-0.6-0.3-1.1-0.6-1.3-0.9
									C391.7,961.8,391.5,961.1,391.8,960.4z M411.6,942.5c1.5-0.1,2.9,0,4.2,0.3c-0.3,1.4-0.5,2.8-0.6,4.2c-1.4,0-2.8-0.1-4.2-0.3
									C411.3,945.3,411.5,944,411.6,942.5z"
						/>
						<path
							d="M403.3,974.5c2.5-2.3,5-4.8,7.6-7.5c1.3-1.3,2.2-2.4,2.8-3.2c0.6-0.8,1-1.4,1.2-2c0.2-0.6,0.3-1.3,0.4-2.1
									c0.8-0.1,1.5-0.1,2.1,0.1c0.6,0.2,1.1,0.4,1.4,0.8c1.2,1.1,1.2,2.5,0,4.1c1.3-0.2,2.5-0.1,3.7,0.3c1.2,0.3,2.2,0.9,3,1.7
									c2.6,2.5,2.4,5.4-0.8,8.7l-2.2,2.2c-0.9,0.8-1.7,1.6-2.4,2.3c-2.4,2.5-3.9,4.5-4.5,6.1c-0.8-0.5-1.4-0.9-2-1.5
									c-0.6-0.6-1.2-1.3-1.8-2.1c2.9-2.8,6-5.9,9.3-9.3c0.9-1,1.4-1.8,1.4-2.6c0-0.8-0.4-1.5-1.2-2.3c-1.2-1.2-2.5-1.9-3.9-2.1l-1.5,1.5
									c-1.2,1.2-2.7,2.6-4.3,4.3c-2.4,2.5-3.9,4.6-4.5,6.1c-0.8-0.5-1.5-1-2.1-1.6C404.5,976.1,403.9,975.4,403.3,974.5z"
						/>
						<path
							d="M427.2,993.1c0-2.2,1.1-4.7,3.2-7.5c2.1-2.9,4.3-4.7,6.4-5.4c2.1-0.7,4.3-0.3,6.4,1.3c1.6,1.2,2.8,2.9,3.3,5
									c1.8-2.8,3.1-5,3.7-6.5c0.9,0.2,1.8,0.5,2.5,0.9c0.7,0.3,1.5,0.8,2.2,1.4c-2.8,3.1-7.1,8.7-13,16.6c-0.7,0.9-1.1,1.8-1.3,2.5
									c-0.2,0.7-0.3,1.6-0.2,2.6c-1.4,0.2-2.8-0.1-4.1-0.8c-0.3-1-0.2-2.1,0.2-3.3c-1.2,0.2-2.4,0.2-3.5,0c-1.1-0.2-2.1-0.6-2.9-1.2
									C428.2,997.1,427.2,995.3,427.2,993.1z M434.8,998.1c0.9,0.1,1.8,0.2,2.7,0.2c0.6-0.8,1.7-2.2,3.2-4.2c1.7-2.2,3-3.9,3.9-5.1
									c-0.5-1.4-1.4-2.6-2.7-3.5c-1.3-1-2.7-1.2-4.1-0.7c-1.4,0.5-2.8,1.7-4.2,3.5c-1.4,1.9-2.1,3.6-2.3,5.1c-0.2,1.6,0.3,2.9,1.3,4
									C433.2,997.7,434,997.9,434.8,998.1z"
						/>
						<path
							d="M451.1,1009.5c-0.4-2.4,0.2-4.9,1.8-7.5c1.1-1.9,2.5-3.3,4.1-4.4c1.6-1,3.3-1.6,5.1-1.6c1.8,0,3.5,0.5,5.2,1.5
									c1.5,0.9,2.6,2,3.3,3.5s1,3,0.8,4.7c-0.2,1.7-0.8,3.4-1.8,5.1c-1.1,1.9-2.5,3.3-4.2,4.4c-1.6,1-3.4,1.5-5.2,1.5
									c-1.8,0-3.6-0.5-5.4-1.6C452.7,1013.8,451.5,1011.9,451.1,1009.5z M461.3,1014.2c1.5-0.6,2.9-1.9,4.1-3.9c1.1-1.8,1.6-3.5,1.6-5.3
									c0-1.7-0.5-3.3-1.6-4.5c-1.5-0.9-3.1-1.1-4.7-0.5c-1.6,0.6-3,1.8-4.1,3.7c-1.3,2.2-2,4.3-1.9,6.1c0.1,1.8,0.8,3.1,2.1,3.9
									C458.2,1014.6,459.7,1014.8,461.3,1014.2z"
						/>
						<path
							d="M475,1025.7c1.6-2.9,3.2-6.1,4.9-9.5c0.8-1.7,1.3-2.9,1.7-3.9c0.3-0.9,0.5-1.7,0.5-2.3c0-0.6-0.1-1.3-0.3-2.1
									c0.7-0.4,1.4-0.5,2-0.6c0.6,0,1.1,0.1,1.6,0.3c1.5,0.7,1.9,2,1.3,3.9c1.2-0.6,2.4-0.9,3.6-0.9c1.2,0,2.4,0.2,3.4,0.7
									c3.3,1.6,3.9,4.4,2,8.6l-1.4,2.8c-0.6,1.1-1.1,2.1-1.6,3c-1.5,3.1-2.2,5.5-2.3,7.2c-0.9-0.2-1.7-0.5-2.4-0.8
									c-0.7-0.3-1.5-0.8-2.4-1.5c1.9-3.6,3.9-7.5,5.9-11.7c0.6-1.2,0.8-2.2,0.6-2.9c-0.2-0.7-0.8-1.4-1.9-1.8c-1.5-0.7-3-1-4.3-0.8
									l-1,1.9c-0.8,1.5-1.7,3.3-2.7,5.4c-1.5,3.2-2.3,5.6-2.4,7.2c-0.9-0.2-1.8-0.5-2.5-0.9C476.6,1026.8,475.9,1026.4,475,1025.7z"
						/>
					</g>
					<g className="level_one location" id="arthedain">
						<rect
							x="1937.145"
							y="1824.805"
							width="125.783"
							height="40.144"
							transform="matrix(0.345752, -0.938326, 0.934162, 0.357052, -1764.262695, 1946.508423)"
							data-bx-origin="0.164 0.804"></rect>
						<rect
							x="1937.145"
							y="1824.807"
							width="125.783"
							height="40.144"
							transform="matrix(0.752957, -0.65807, 0.649002, 0.760882, -1990.520996, 543.511475)"
							data-bx-origin="0.164 0.804"></rect>
						<rect
							x="1937.144"
							y="1646.138"
							width="125.783"
							height="36.213"
							transform="matrix(0.944634, -0.328125, 0.316748, 0.948586, -1605.404297, -348.374329)"
							data-bx-origin="0.164 0.804"></rect>
						<text fontFamily="Arbutus" fontSize="36px" x="628.896" y="770.143" transform="matrix(0.355634, -0.934625, 0.934625, 0.355634, -296.690369, 1085.572632)">AR</text>
						<text fontFamily="Arbutus" fontSize="36px" letterSpacing="3.6px" transform="matrix(0.747888, -0.663825, 0.663825, 0.747888, -299.863403, 518.352539)" x="628.896" y="770.143">THE</text>
						<text fontFamily="Arbutus" fontSize="36px" letterSpacing="3.6px" transform="matrix(0.944037, -0.32984, 0.32984, 0.944037, -90.124741, 87.725006)" x="628.897" y="770.144">DAIN</text>
					</g>
					<g className="level_two fade location " id="far_downs">
						<rect x="589.462" y="911.028" width="38.275" height="20.042" transform="matrix(0.999911, -0.013318, 0.001276, 1.000072, 100.61924, -202.244492)"></rect>
						<rect x="942.044" y="911.028" width="61.169" height="20.042" transform="matrix(0.999911, -0.013318, 0.001276, 1.000072, -264.647186, -177.462112)"></rect>
						<path
							d="M696,718.1c0.1-0.7,0.1-1.8,0.1-3c0-1.3,0-2.5-0.1-3.7c-0.1-3.2-0.4-5.3-0.7-6.5c0.9,0.1,2.3,0.1,4.1,0
									c2-0.1,3.6-0.2,4.7-0.4c0,0.4,0,0.9-0.1,1.5s-0.2,0.9-0.4,1.1c-0.4-0.2-0.9-0.3-1.4-0.4c-0.6-0.1-1.1-0.1-1.7-0.1
									c-0.9,0-1.7,0.1-2.5,0.3c0,1.6,0,2.8,0,3.8l0.6,0c0.5,0,1,0,1.2,0c0.7,0,1.3-0.1,1.7-0.1c0.4-0.1,0.8-0.1,1.4-0.3
									c0.1,0.3,0.1,0.6,0.1,0.9c0,0.3,0,0.6-0.1,1l-1.1,0c-1.1,0-2,0-2.9,0c-0.4,0-0.7,0-0.9,0.1c0.1,3.2,0.3,5.1,0.6,5.8
									c-0.5,0.1-0.9,0.2-1.4,0.2C697,718.3,696.5,718.2,696,718.1z"
						/>
						<path
							d="M707.7,717.3c-0.4-0.4-0.7-1-0.7-1.7c-0.1-2,1.9-3.2,5.9-3.6c0-1.1-0.2-1.9-0.6-2.3c-0.3-0.4-0.9-0.6-1.8-0.6
									c-0.5,0-1.1,0.1-1.6,0.3c-0.5,0.2-1,0.4-1.4,0.7l-0.2-0.5c0.4-0.8,1-1.4,1.8-1.9c0.8-0.5,1.6-0.7,2.4-0.8c1.1,0,2,0.2,2.6,0.8
									c0.6,0.6,0.9,1.4,1,2.4c0,0.6,0,1.3,0,2c0,0.8,0,1.4,0,1.8c0,0.6,0.1,1.1,0.3,1.5s0.4,0.7,0.8,1.1c-0.6,0.6-1.3,0.9-2,1
									c-0.5-0.3-0.8-0.7-1-1.3c-0.6,0.4-1.2,0.8-1.9,1.1c-0.7,0.3-1.3,0.4-1.8,0.4C708.7,717.9,708.1,717.7,707.7,717.3z M711.6,716.2
									c0.5-0.2,0.9-0.5,1.2-0.8l0-0.1c0-0.5,0-1.1,0-1.6l0-1.1c-1.2,0.1-2,0.4-2.6,0.8c-0.6,0.4-0.9,1-0.9,1.7c0,0.3,0.1,0.6,0.3,0.8
									c0.2,0.3,0.4,0.5,0.6,0.6C710.7,716.6,711.1,716.5,711.6,716.2z"
						/>
						<path
							d="M720.4,717.2c0.1-1.7,0-3.4,0-5.3c0-0.9-0.1-1.6-0.2-2.1s-0.2-0.9-0.3-1.1c-0.1-0.3-0.4-0.6-0.7-0.8
									c0.3-0.3,0.5-0.5,0.9-0.7c0.3-0.2,0.6-0.2,0.9-0.3c1,0,1.5,0.6,1.6,1.8c0.4-0.6,0.9-1,1.4-1.4c0.5-0.4,1.1-0.6,1.7-0.8
									c0.1,0.3,0.3,0.6,0.4,1.1c0.1,0.5,0.2,1,0.2,1.4c-0.2,0-0.5,0-0.9,0c-0.5,0-1.1,0.1-1.5,0.2c-0.5,0.1-0.9,0.3-1.2,0.5l0,0.8
									c0,1.1,0,2.1,0.1,2.9c0.1,1.7,0.3,3,0.7,3.7c-0.5,0.1-1,0.2-1.4,0.2C721.4,717.3,720.9,717.3,720.4,717.2z"
						/>
						<path
							d="M682.4,737.6c-0.7,0-1.4-0.1-1.9-0.2c0-0.8,0-2-0.1-3.4c-0.1-1.4-0.1-2.8-0.2-4.1c-0.2-2.9-0.5-4.6-0.8-5.3
									c0.5-0.2,1.2-0.5,2-0.7s1.7-0.3,2.6-0.4c2.1-0.2,3.8,0.3,5,1.4c1.2,1.1,1.9,2.8,2.1,5c0.1,1.4-0.1,2.6-0.6,3.7
									c-0.5,1.1-1.3,2-2.3,2.7c-1,0.7-2.2,1.1-3.6,1.2C683.8,737.6,683.1,737.6,682.4,737.6z M687.3,735c0.5-0.6,0.9-1.3,1.2-2
									c0.3-0.8,0.4-1.6,0.3-2.4c-0.3-3.8-2-5.6-5.2-5.3c-0.5,0-1,0.2-1.5,0.3c0,1.9,0.1,3.9,0.3,6c0.1,1.7,0.3,3.1,0.4,4.2
									c1.2,0.3,2.1,0.4,2.8,0.3C686.2,736,686.8,735.6,687.3,735z"
						/>
						<path
							d="M693.5,735.5c-0.9-0.9-1.3-2.2-1.4-3.7c-0.1-1.1,0.1-2.2,0.5-3.1s1-1.6,1.7-2.2c0.8-0.5,1.6-0.8,2.6-0.9
									c0.9-0.1,1.7,0.1,2.4,0.5s1.3,1,1.7,1.8c0.4,0.8,0.7,1.7,0.7,2.7c0.1,1.1-0.1,2.2-0.5,3.1c-0.4,0.9-1,1.6-1.8,2.2
									c-0.8,0.5-1.7,0.8-2.7,0.9C695.4,736.9,694.3,736.5,693.5,735.5z M699.1,734.6c0.5-0.7,0.7-1.7,0.6-2.9c-0.1-1.1-0.4-2-0.9-2.7
									c-0.5-0.8-1.2-1.2-2-1.5c-0.9,0.1-1.6,0.4-2.1,1.2c-0.5,0.7-0.7,1.7-0.7,2.8c0.1,1.3,0.4,2.4,1,3.2c0.6,0.8,1.2,1.1,2,1.1
									C698,735.6,698.7,735.3,699.1,734.6z"
						/>
						<path
							d="M704.6,734.1l-1-3.1c-0.2-0.8-0.4-1.4-0.6-2c-0.3-0.7-0.5-1.4-0.8-1.9c-0.3-0.6-0.5-0.9-0.7-1.2c0.4-0.2,0.8-0.4,1.2-0.5
									c0.4-0.1,0.9-0.2,1.5-0.2c0.1,0.8,0.6,2.3,1.3,4.7c0.7,2.4,1.2,3.8,1.5,4.3c1-3.9,1.6-6.3,1.8-7.2c-0.3-0.7-0.6-1.1-0.9-1.4
									c0.8-0.4,1.7-0.6,2.7-0.7c0.1,0.7,0.6,2.4,1.4,4.8c0.8,2.5,1.3,3.9,1.4,4.1c0.8-2.8,1.3-4.8,1.5-5.9c0.3-1.1,0.4-1.9,0.4-2.4
									c0-0.2,0-0.4-0.1-0.6c0.6-0.2,1.3-0.3,2.1-0.4c-0.4,1.1-0.9,2.6-1.4,4.4c-0.5,1.8-1,3.8-1.5,5.9l-1.3,0.9c-0.5,0-0.9-0.1-1.2-0.5
									c-0.3-0.3-0.6-0.9-0.9-1.6c-0.7-2.2-1.2-3.8-1.5-4.7c-0.4,1.5-1,3.6-1.6,6.2l-1.3,0.9C705.7,736.2,705,735.6,704.6,734.1z"
						/>
						<path
							d="M718.9,735.4c0.1-1.7,0.1-3.6,0-5.5c0-1-0.1-1.7-0.1-2.2c-0.1-0.5-0.2-0.9-0.3-1.2c-0.1-0.3-0.3-0.6-0.6-0.9
									c0.2-0.3,0.5-0.6,0.8-0.7c0.3-0.2,0.5-0.2,0.8-0.2c0.8,0,1.3,0.5,1.5,1.5c0.4-0.5,0.9-1,1.4-1.3c0.6-0.3,1.1-0.5,1.7-0.5
									c1.9-0.1,2.8,1.1,2.9,3.5l0,1.6c0,0.6,0,1.2,0,1.7c0.1,1.8,0.3,3.1,0.6,3.9c-0.4,0.1-0.9,0.2-1.3,0.2c-0.4,0-0.9,0-1.4-0.1
									c0-2.1,0-4.4,0-6.8c0-0.7-0.2-1.2-0.4-1.5c-0.3-0.3-0.7-0.4-1.3-0.4c-0.9,0-1.6,0.2-2.2,0.7l0,1.1c0,0.9,0,1.9,0,3.1
									c0.1,1.8,0.3,3.1,0.6,3.9c-0.5,0.1-0.9,0.2-1.4,0.2C719.9,735.6,719.5,735.5,718.9,735.4z"
						/>
						<path
							d="M728.8,734.5c0-0.3,0-0.6,0.1-0.9c0.1-0.3,0.2-0.6,0.3-1c0.3,0.4,0.7,0.8,1.2,1c0.5,0.2,1,0.3,1.6,0.3
									c1.5,0,2.3-0.5,2.2-1.4c0-0.4-0.2-0.7-0.5-1c-0.3-0.3-0.9-0.5-1.6-0.9c-0.7-0.3-1.3-0.6-1.7-0.9c-0.4-0.3-0.8-0.6-1.1-1.1
									s-0.5-1-0.5-1.7c0-0.8,0.3-1.5,1-2c0.7-0.5,1.5-0.8,2.6-0.8c1.4,0,2.6,0.2,3.6,0.6c0,0.3-0.1,0.6-0.2,1.1
									c-0.1,0.5-0.3,0.9-0.5,1.2c-0.6-0.6-1.2-1-1.7-1.3c-0.5-0.3-1-0.5-1.4-0.5c-0.4,0.1-0.7,0.3-0.9,0.5c-0.2,0.2-0.4,0.5-0.3,0.8
									c0,0.5,0.2,0.9,0.6,1.2c0.4,0.3,1,0.6,1.7,1c0.7,0.3,1.2,0.6,1.6,0.8c0.4,0.3,0.8,0.6,1.1,1s0.5,1,0.5,1.6c0,1-0.3,1.7-1,2.3
									c-0.7,0.5-1.7,0.8-3,0.9C730.9,735.4,729.7,735.1,728.8,734.5z"
						/>
					</g>
					<g className="level_one location" id="tower_hills">
						<rect x="1675.075" y="1324.045" width="108.767" height="29.128" transform="matrix(0.760146, -0.649753, 0.640601, 0.767968, -1566.268311, 765.082275)"></rect>
						<path
							d="M577.1,705.6c-0.7-0.7-1.7-1.5-3-2.7c-1.3-1.1-2.6-2.2-3.9-3.2c-1.4-1.1-2.6-2-3.6-2.7c-1.4,1.8-2.2,3-2.4,3.7
									c-0.4-0.2-0.8-0.4-1.1-0.7c-0.3-0.2-0.6-0.5-1-1c0.5-0.5,1.2-1.2,2-2.2c0.8-0.9,1.6-1.8,2.3-2.7c1.6-2.1,2.6-3.4,2.8-4.1
									c0.4,0.2,0.8,0.4,1.1,0.7c0.2,0.2,0.6,0.5,1,1c-0.8,0.8-1.7,1.9-2.9,3.3c1.9,1.7,3.6,3.1,5.1,4.3c2.9,2.3,4.7,3.6,5.6,3.9
									c-0.3,0.5-0.5,0.9-0.8,1.3C577.9,704.9,577.5,705.2,577.1,705.6z"
						/>
						<path
							d="M581.4,698.1c-1.4,0-2.9-0.6-4.3-1.8c-1-0.9-1.7-1.8-2.2-2.9c-0.5-1.1-0.6-2.1-0.5-3.2c0.2-1.1,0.6-2,1.4-2.9
									c0.7-0.8,1.5-1.3,2.4-1.6c0.9-0.3,1.8-0.3,2.8,0c1,0.3,1.9,0.8,2.8,1.6c1,0.9,1.7,1.8,2.2,2.9c0.4,1.1,0.6,2.1,0.4,3.2
									s-0.6,2.1-1.4,3C584.1,697.5,582.9,698.1,581.4,698.1z M585.2,692.6c-0.2-0.9-0.8-1.9-1.9-2.8c-0.9-0.8-1.9-1.3-3-1.5
									c-1-0.2-2,0-2.8,0.5c-0.7,0.8-0.9,1.7-0.8,2.7s0.8,1.9,1.8,2.8c1.2,1,2.3,1.6,3.4,1.7c1.1,0.1,1.9-0.1,2.5-0.9
									C585.1,694.4,585.4,693.5,585.2,692.6z"
						/>
						<path
							d="M589.2,687.8l-3.4-1.7c-0.8-0.4-1.5-0.8-2.1-1.1c-0.8-0.4-1.5-0.7-2.2-0.9c-0.7-0.2-1.2-0.3-1.5-0.3
									c0.2-0.5,0.4-1,0.6-1.4c0.3-0.4,0.6-0.9,1-1.4c0.7,0.5,2.4,1.4,4.9,2.7s4.1,2.1,4.7,2.3c-2.3-4-3.8-6.4-4.4-7.3
									c-0.8-0.3-1.4-0.4-1.8-0.4c0.3-0.9,0.9-1.9,1.7-2.8c0.7,0.5,2.4,1.4,5.1,2.8s4.2,2.1,4.5,2.2c-1.7-2.9-2.8-4.9-3.6-6.1
									c-0.7-1.1-1.3-1.9-1.7-2.3c-0.1-0.1-0.3-0.2-0.6-0.4c0.3-0.7,0.8-1.3,1.4-2.1c0.6,1.2,1.4,2.8,2.4,4.7s2.3,3.9,3.6,6.1l-0.3,1.7
									c-0.4,0.4-0.8,0.6-1.4,0.6c-0.6,0-1.2-0.2-2-0.6c-2.4-1.2-4.1-2.1-5.1-2.6c0.9,1.6,2.1,3.7,3.8,6.3l-0.3,1.7
									C591.8,688.6,590.7,688.6,589.2,687.8z"
						/>
						<path
							d="M600.6,676.7c-1.3-0.2-2.6-1-4-2.4c-0.9-0.9-1.6-2-1.9-3c-0.4-1.1-0.5-2.1-0.2-3.1c0.2-1,0.7-1.9,1.5-2.7
									c1.1-1,2.2-1.4,3.5-1.2c1.3,0.2,2.6,1.1,4,2.5c-0.5,0.9-1.2,1.8-2.1,2.9c-0.9,1.1-1.8,2.1-2.7,3.1c0.9,0.8,1.8,1.2,2.5,1.3
									c0.7,0,1.5-0.3,2.2-1c0.5-0.5,1-1.1,1.3-1.7c0.3-0.6,0.6-1.2,0.7-1.8l0.5,0.3c0.1,1,0.1,2-0.3,3s-0.9,1.9-1.6,2.6
									C603.1,676.5,601.9,676.9,600.6,676.7z M599.7,669.9c0.5-0.8,0.8-1.4,1-2c-0.8-0.7-1.6-1.2-2.4-1.3c-0.9-0.1-1.6,0.1-2.2,0.7
									c-0.2,0.7-0.1,1.4,0.3,2.3c0.4,0.9,0.9,1.7,1.7,2.5C598.7,671.4,599.2,670.7,599.7,669.9z"
						/>
						<path
							d="M609.6,669.9c-1.2-1.6-2.5-3.3-4-5c-0.7-0.8-1.3-1.5-1.7-1.9c-0.4-0.4-0.8-0.7-1.1-0.9c-0.3-0.2-0.7-0.3-1.2-0.4
									c0-0.5,0.1-0.9,0.2-1.2c0.1-0.4,0.3-0.6,0.5-0.8c0.8-0.7,1.7-0.5,2.7,0.6c0-0.8,0-1.5,0.1-2.2c0.1-0.7,0.4-1.3,0.8-1.9
									c0.3,0.2,0.7,0.4,1.2,0.8s0.9,0.8,1.2,1.2c-0.2,0.1-0.4,0.3-0.8,0.6c-0.4,0.4-0.8,0.8-1.1,1.3c-0.3,0.5-0.5,0.9-0.6,1.3l0.6,0.7
									c0.8,1,1.6,1.9,2.2,2.7c1.4,1.6,2.5,2.6,3.3,3.1c-0.3,0.4-0.7,0.8-1,1.1C610.6,669.3,610.2,669.6,609.6,669.9z"
						/>
						<path
							d="M618.9,662.1c-0.4-0.9-1.1-2.1-2-3.6c-0.9-1.5-1.7-2.9-2.6-4.2c-1.9-3.1-3.3-4.9-4-5.5c0.4-0.4,0.8-0.7,1.2-0.9
									c0.4-0.3,0.9-0.5,1.5-0.7c0.8,1.6,1.9,3.5,3.4,6c0.9-0.4,2-1.1,3.4-1.9c0.2-0.1,0.8-0.6,1.9-1.3c-2-3.1-3.3-5-4.1-5.7
									c0.4-0.4,0.7-0.7,1.2-0.9c0.4-0.2,0.9-0.5,1.5-0.7c0.4,0.9,1.1,2.1,2,3.6c0.9,1.5,1.7,2.9,2.6,4.2c1.9,3.1,3.3,4.9,4,5.5
									c-0.4,0.4-0.8,0.7-1.2,1c-0.4,0.2-0.9,0.5-1.4,0.7c-0.8-1.5-1.9-3.5-3.5-6c-1,0.6-2,1.2-3.1,1.8c-0.7,0.4-1.4,0.9-2.1,1.4
									c2,3.2,3.4,5.1,4.1,5.7c-0.4,0.4-0.8,0.7-1.2,1C619.9,661.7,619.5,661.9,618.9,662.1z"
						/>
						<path
							d="M623.2,640.8c0.2-0.9,0.4-1.7,0.8-2.4c0.8,0.4,1.5,0.7,2.3,1c-0.2,0.8-0.4,1.6-0.8,2.4C624.8,641.4,624,641,623.2,640.8z
									M630.7,654.9c-0.4-0.2-0.7-0.8-1.1-1.6c-0.3-0.7-0.7-1.6-1.3-2.9c-0.5-1.2-0.9-2-1.1-2.6c-0.3-0.7-0.7-1.4-1.1-2
									c-0.4-0.6-0.7-1-1-1.2c0.4-0.4,0.8-0.8,1.1-1c0.4-0.3,0.9-0.5,1.4-0.8c0.2,0.6,0.5,1.4,0.9,2.5c0.4,1.1,1,2.4,1.6,3.7
									c0.5,1,0.8,1.8,1.1,2.3c0.3,0.5,0.6,0.9,0.9,1.1s0.6,0.5,1.1,0.7c-0.1,0.5-0.3,0.8-0.5,1.1c-0.2,0.3-0.5,0.5-0.7,0.6
									C631.4,655.2,631,655.2,630.7,654.9z"
						/>
						<path
							d="M635.2,652.9c-0.3-0.3-0.7-0.8-1-1.7c-0.2-0.6-0.5-1.5-1-2.8c-0.5-1.4-0.9-2.6-1.2-3.5c-1.1-3-2-5.1-2.8-6.3
									c0.4-0.4,0.8-0.7,1.2-0.9c0.4-0.2,0.9-0.5,1.4-0.7c0.6,2.4,1.7,5.8,3.3,10.1c0.4,1,0.7,1.8,0.9,2.3c0.3,0.5,0.5,0.9,0.8,1.2
									c0.3,0.3,0.6,0.5,1,0.8c-0.2,0.4-0.4,0.8-0.6,1.1c-0.3,0.3-0.5,0.5-0.8,0.6C635.9,653.2,635.5,653.2,635.2,652.9z"
						/>
						<path
							d="M639.9,651.4c-0.3-0.3-0.6-0.9-0.8-1.8c-0.2-0.6-0.4-1.6-0.7-2.9c-0.4-1.5-0.7-2.7-0.9-3.6c-0.8-3.1-1.6-5.3-2.3-6.5
									c0.4-0.3,0.9-0.6,1.3-0.8c0.4-0.2,0.9-0.4,1.5-0.5c0.4,2.5,1.3,5.9,2.5,10.4c0.3,1,0.5,1.8,0.8,2.4c0.2,0.6,0.4,1,0.7,1.3
									c0.2,0.3,0.5,0.6,0.9,0.8c-0.2,0.4-0.4,0.8-0.7,1s-0.6,0.4-0.8,0.5C640.6,651.7,640.2,651.7,639.9,651.4z"
						/>
						<path
							d="M643,649.9c-0.1-0.4-0.1-0.7-0.1-1.1c0-0.3,0.1-0.7,0.2-1.2c0.4,0.4,0.9,0.8,1.6,0.9c0.6,0.2,1.3,0.2,1.9,0.1
									c1.7-0.3,2.5-0.9,2.3-2c-0.1-0.4-0.3-0.8-0.8-1c-0.4-0.2-1.1-0.5-1.9-0.7c-0.9-0.3-1.6-0.5-2.1-0.7c-0.5-0.2-1-0.6-1.5-1.1
									s-0.7-1.1-0.9-1.9c-0.1-0.9,0.1-1.7,0.8-2.4c0.7-0.7,1.6-1.1,2.8-1.3c1.6-0.3,3-0.2,4.3,0c0.1,0.3,0,0.7,0,1.3
									c-0.1,0.5-0.2,1.1-0.4,1.5c-0.8-0.5-1.5-1-2.2-1.2c-0.7-0.3-1.2-0.4-1.6-0.3c-0.4,0.2-0.7,0.4-1,0.7c-0.2,0.3-0.3,0.6-0.3,0.9
									c0.1,0.6,0.4,1,0.8,1.2c0.5,0.3,1.2,0.6,2.2,0.9c0.8,0.2,1.5,0.5,2,0.7c0.5,0.2,1,0.6,1.4,1c0.4,0.4,0.7,1,0.8,1.7
									c0.2,1.1-0.1,2-0.8,2.8c-0.7,0.7-1.8,1.2-3.3,1.5C645.6,650.6,644.2,650.5,643,649.9z"
						/>
					</g>
					<g className="level_zero location " id="gulf_of_lhun">
						<rect x="1155.441" y="1448.693" width="75.025" height="31.87" transform="matrix(0.916035, 0.401098, -0.412128, 0.911206, -124.042465, -1163.557617)"></rect>
						<rect x="1718.532" y="1448.699" width="111.588" height="31.87" transform="matrix(0.995977, -0.089613, 0.077621, 0.997056, -1432.083008, -640.78479)"></rect>
						<path
							d="M332.7,644.4c-0.5-1.9-0.1-4.2,1.1-6.7c0.9-1.8,1.9-3.2,3.3-4.3c1.3-1.1,2.7-1.7,4.3-2c1.5-0.2,3.1,0,4.6,0.8
									c1.8,0.8,3.2,1.8,4.2,2.9c-0.2,0.4-0.5,0.9-1,1.4c-0.4,0.5-0.8,0.9-1.2,1.2c-0.6-1-1.3-2-2-2.9c-0.8-0.9-1.5-1.5-2-1.8
									c-0.7-0.1-1.5,0-2.4,0.4c-0.9,0.4-1.7,1-2.5,1.9c-0.8,0.8-1.4,1.7-1.9,2.8c-1.1,2.4-1.6,4.3-1.3,5.7s1.3,2.6,3.1,3.4
									c0.8,0.4,1.6,0.6,2.3,0.7c1.6-3.4,2.5-5.6,2.6-6.6c0.6,0.1,1.1,0.3,1.5,0.5c0.5,0.2,1,0.6,1.6,1c-1.2,2-2.3,4.2-3.4,6.5
									c-1,0.3-2.1,0.5-3.3,0.4c-1.2-0.1-2.4-0.4-3.5-0.9C334.5,647.8,333.2,646.3,332.7,644.4z"
						/>
						<path
							d="M350.6,649.9l0.9-2l0.9-2.2c0.8-2.1,1.3-3.7,1.2-4.8c1.1,0,2.3,0.2,3.5,0.7c-1.2,2.6-2.4,5.6-3.8,9
									c-0.3,0.9-0.4,1.6-0.3,2.1c0.2,0.5,0.6,1,1.4,1.3c1,0.4,2,0.5,3,0.3c0.6-1.5,1.1-2.7,1.6-3.8l0.7-1.8c0.8-2.1,1.3-3.7,1.2-4.8
									c1.1,0,2.3,0.2,3.6,0.7c-0.7,1.5-1.5,3.2-2.4,5.2c-0.9,2-1.5,3.4-1.8,4.1c-0.3,0.8-0.5,1.4-0.5,1.9c0,0.5,0.1,1.1,0.3,1.8
									c-1,0.4-1.9,0.4-2.9,0.2c-0.4-0.7-0.5-1.5-0.4-2.4c-0.8,0.4-1.6,0.6-2.5,0.7c-0.9,0.1-1.7-0.1-2.5-0.4
									C349.9,654.8,349.4,652.8,350.6,649.9z"
						/>
						<path
							d="M366.2,660.2c-0.1-0.5,0-1.2,0.4-2.3c0.2-0.7,0.6-1.8,1.2-3.3c0.6-1.7,1.1-3,1.5-4.1c1.2-3.6,1.9-6.2,2-7.8
									c0.7,0,1.3,0,1.8,0s1.1,0.2,1.8,0.4c-1.2,2.7-2.7,6.5-4.4,11.6c-0.4,1.2-0.7,2.1-0.8,2.8c-0.2,0.7-0.2,1.2-0.2,1.7
									c0,0.4,0.2,0.9,0.4,1.4c-0.5,0.3-0.9,0.5-1.4,0.5c-0.5,0.1-0.8,0-1.1-0.1C366.7,661,366.3,660.7,366.2,660.2z"
						/>
						<path
							d="M372.5,668.2c0.6-0.4,1.1-0.9,1.5-1.3c0.4-0.4,0.8-1.1,1.1-1.9c0.4-0.8,0.8-2,1.3-3.5c0.6-1.8,1.4-4.8,2.7-9.2
									c-0.7-0.2-1.1-0.5-1.3-0.6c-0.2-0.2-0.3-0.4-0.2-0.7c1-0.2,1.7-0.4,2.1-0.6c0.8-1.8,1.9-3.1,3.2-3.9c1.3-0.8,2.7-1,4-0.6
									c1,0.3,1.9,0.8,2.7,1.3c-0.1,0.3-0.3,0.8-0.7,1.3c-0.4,0.5-0.8,1-1.2,1.4c-0.4-0.8-0.9-1.5-1.4-2.1c-0.5-0.6-1-1-1.4-1.1
									c-0.4,0.2-0.8,0.6-1.2,1.2c-0.4,0.7-0.8,1.8-1.3,3.4c1.5,0.4,2.6,0.7,3.3,0.7c-0.1,0.4-0.3,0.8-0.5,1.2c-0.2,0.3-0.4,0.7-0.8,1
									c-0.6-0.5-1.5-0.9-2.6-1.3l-0.7,2.3c-0.4,1.3-0.9,2.9-1.4,4.7c-0.2,1-0.5,1.8-0.7,2.5c-0.4,1.1-0.8,2-1.2,2.7
									c-0.5,0.7-1.1,1.3-1.8,1.8c-0.8,0.5-1.9,1.1-3.3,1.6L372.5,668.2z"
						/>
						<path
							d="M397.6,668.3c-0.8-1.5-1-3.3-0.6-5.4c0.3-1.5,0.8-2.8,1.6-3.9c0.8-1.1,1.8-1.8,3-2.3s2.4-0.5,3.7-0.3
									c1.2,0.2,2.2,0.7,3,1.5s1.3,1.7,1.6,2.9c0.3,1.1,0.3,2.4,0,3.8c-0.3,1.5-0.9,2.8-1.7,3.9c-0.8,1.1-1.8,1.8-3,2.3
									c-1.2,0.4-2.5,0.5-3.9,0.3C399.7,670.7,398.4,669.7,397.6,668.3z M405.3,668.9c0.8-0.8,1.4-1.9,1.7-3.5c0.3-1.4,0.2-2.7-0.2-3.9
									c-0.4-1.1-1.1-2-2.1-2.6c-1.2-0.2-2.3,0-3.2,0.8c-0.9,0.8-1.5,1.9-1.8,3.4c-0.3,1.8-0.3,3.3,0.2,4.4c0.5,1.2,1.3,1.9,2.3,2.1
									C403.5,669.9,404.5,669.6,405.3,668.9z"
						/>
						<path
							d="M413.8,677.9c0.5-0.6,0.9-1.1,1.2-1.6c0.3-0.5,0.5-1.2,0.7-2.1c0.2-0.9,0.4-2.1,0.5-3.7c0.2-1.8,0.4-5,0.7-9.6
									c-0.7-0.1-1.2-0.2-1.4-0.4c-0.2-0.1-0.3-0.4-0.3-0.7c0.9-0.4,1.6-0.7,1.9-1c0.4-1.9,1.2-3.4,2.3-4.4c1.1-1.1,2.4-1.5,3.8-1.4
									c1,0.1,2,0.4,3,0.8c0,0.3-0.2,0.8-0.4,1.4c-0.3,0.6-0.5,1.2-0.8,1.6c-0.6-0.7-1.2-1.3-1.8-1.8c-0.7-0.5-1.2-0.7-1.6-0.7
									c-0.4,0.2-0.7,0.7-0.9,1.5c-0.2,0.7-0.4,1.9-0.6,3.6c1.6,0.1,2.7,0.1,3.4,0.1c0,0.5-0.1,0.9-0.3,1.2s-0.3,0.7-0.5,1.1
									c-0.7-0.3-1.6-0.6-2.8-0.7l-0.2,2.4c-0.1,1.4-0.3,3-0.4,4.9c0,1-0.1,1.8-0.2,2.6c-0.1,1.2-0.3,2.1-0.6,2.9
									c-0.3,0.8-0.8,1.5-1.4,2.1c-0.6,0.7-1.6,1.4-2.9,2.3L413.8,677.9z"
						/>
						<path
							d="M438.1,668.1c0-1.1,0-2.6-0.1-4.7c-0.2-4.3-0.4-6.9-0.8-7.9c0.6-0.2,1.2-0.3,1.8-0.3c0.5,0,1.2,0,1.9,0.1
									c-0.1,1.3-0.1,2.9-0.1,4.9c0,2,0,3.9,0.1,5.6c0.1,2.4,0.2,4.1,0.3,5.1c1,0.2,2.1,0.2,3.4,0.2c0.8,0,1.5-0.1,2.3-0.3
									c0.8-0.2,1.4-0.5,1.9-0.7c0,0.7,0,1.4-0.1,2.1c-0.1,0.7-0.3,1.2-0.4,1.5c-1.4-0.1-3.1-0.1-5-0.1c-2.3,0.1-4,0.2-5.1,0.4
									C438,671.1,438.1,669.1,438.1,668.1z"
						/>
						<path
							d="M454.1,673.1c-0.3-3-0.6-5.9-1-8.9c-0.5-4-1.1-7.2-1.6-9.4c1-0.6,2.1-1,3.4-1.2c0,1.1,0.2,3.4,0.5,6.8
									c0.5-0.8,1.1-1.4,1.8-1.9c0.7-0.5,1.5-0.8,2.2-0.9c2.5-0.3,4,1.1,4.4,4.3l0.2,2.2l0.3,2.3c0.3,2.4,0.8,4.1,1.3,5.1
									c-0.6,0.2-1.1,0.4-1.7,0.4c-0.5,0.1-1.1,0.1-1.9,0c-0.3-3.3-0.6-6.3-1-9.1c-0.1-0.9-0.4-1.6-0.8-1.9c-0.4-0.4-1-0.5-1.8-0.4
									c-1.1,0.2-2.1,0.6-2.9,1.3c0,0.7,0.2,1.6,0.3,2.9c0.6,4.3,1.2,6.9,1.7,7.9c-0.6,0.2-1.1,0.4-1.7,0.4
									C455.4,673.2,454.8,673.2,454.1,673.1z"
						/>
						<path
							d="M472.3,665.6l-0.5-2.1l-0.6-2.3c-0.6-2.2-1.2-3.7-1.9-4.6c0.9-0.7,2-1.3,3.2-1.6c0.6,2.8,1.4,5.9,2.4,9.5
									c0.2,0.9,0.6,1.5,1.1,1.8c0.5,0.3,1.1,0.4,1.9,0.2c1-0.3,1.9-0.8,2.6-1.5c-0.4-1.5-0.7-2.9-1-4l-0.5-1.9c-0.6-2.2-1.2-3.7-1.9-4.6
									c0.9-0.7,2-1.3,3.3-1.6c0.3,1.6,0.8,3.4,1.3,5.6s0.8,3.6,1,4.3c0.2,0.8,0.5,1.4,0.8,1.9s0.8,0.8,1.4,1.3c-0.6,0.9-1.3,1.5-2.2,1.9
									c-0.8-0.3-1.3-0.9-1.8-1.7c-0.4,0.8-0.9,1.5-1.6,2c-0.7,0.6-1.4,1-2.2,1.2C474.8,670,473.1,668.7,472.3,665.6z M470.3,653.3
									c0.7-1.7,1.2-3.3,1.7-4.8c0.7-0.5,1.6-0.9,2.7-1.2c0.9,0.9,1.5,1.6,2,2.1s1.1,1,1.9,1.5c-0.8,0.6-1.7,1-2.7,1.3l-2.6-2.4l-0.9,3.4
									C471.7,653.2,471,653.3,470.3,653.3z"
						/>
						<path
							d="M492.4,664.2c-0.8-2.2-1.6-4.5-2.6-7c-0.5-1.2-0.9-2.1-1.2-2.7c-0.3-0.6-0.6-1-0.9-1.4c-0.3-0.3-0.7-0.6-1.2-0.8
									c0.1-0.5,0.4-1,0.6-1.3c0.3-0.3,0.6-0.6,0.9-0.7c1.1-0.4,1.9,0,2.6,1.2c0.2-0.9,0.6-1.6,1.2-2.3c0.6-0.7,1.2-1.1,1.9-1.4
									c2.3-1,4.1,0.1,5.3,3l0.8,2.1c0.3,0.8,0.5,1.6,0.8,2.2c0.9,2.2,1.8,3.8,2.6,4.6c-0.5,0.4-1,0.6-1.5,0.8c-0.5,0.2-1.1,0.4-1.9,0.5
									c-0.9-2.7-2-5.5-3.3-8.6c-0.4-0.9-0.8-1.4-1.2-1.7c-0.5-0.2-1.1-0.2-1.8,0.1c-1.1,0.5-1.9,1.1-2.4,1.9l0.5,1.4
									c0.4,1.1,0.9,2.4,1.5,4c0.9,2.3,1.8,3.8,2.6,4.6c-0.5,0.4-1.1,0.7-1.6,0.9C493.7,663.9,493.1,664,492.4,664.2z"
						/>
					</g>
					<g className="level_one location" id="forlindon">
						<rect x="2429.962" y="1448.704" width="157.782" height="31.87" transform="matrix(0.129993, -0.991515, 0.98995, 0.141932, -1571.371338, 2850.558105)"></rect>
						<path
							d="M206.9,644c-1.3-0.2-3.1-0.4-5.3-0.5c-2.3-0.2-4.4-0.3-6.4-0.4c-5.6-0.2-9.4-0.1-11.4,0.3c0.3-1.4,0.4-3.6,0.5-6.5
									c0.1-3.2,0.1-5.7,0-7.5c0.8,0,1.6,0.1,2.5,0.3c0.9,0.2,1.5,0.4,1.9,0.7c-0.4,0.6-0.7,1.3-1,2.2c-0.2,0.9-0.4,1.7-0.4,2.6
									c-0.1,1.4,0,2.7,0.2,3.9c2.8,0.2,4.9,0.4,6.6,0.4l0.1-0.9c0.1-0.9,0.1-1.5,0.2-1.9c0-1.2,0-2,0-2.7c-0.1-0.6-0.1-1.3-0.3-2.2
									c0.6-0.1,1.1-0.1,1.6-0.1c0.5,0,1,0.1,1.7,0.2l-0.2,1.8c-0.2,1.7-0.3,3.2-0.4,4.6c0,0.6,0,1.1,0,1.4c5.6,0.2,9.1,0.1,10.3-0.3
									c0.1,0.7,0.2,1.5,0.2,2.3C207.2,642.4,207.1,643.1,206.9,644z"
						/>
						<path
							d="M205.7,625.9c-1.7,1.2-3.9,1.8-6.6,1.6c-1.9-0.1-3.6-0.6-5.1-1.5c-1.5-0.8-2.6-2-3.3-3.3c-0.7-1.4-1.1-2.9-1-4.6
									c0.1-1.5,0.6-2.8,1.4-3.9c0.8-1.1,1.9-1.9,3.3-2.5c1.4-0.5,3-0.8,4.7-0.6c1.9,0.1,3.6,0.6,5.1,1.5c1.5,0.9,2.6,2,3.3,3.4
									c0.7,1.4,1.1,3,0.9,4.8C208.3,622.9,207.4,624.6,205.7,625.9z M205.2,616.2c-1.1-0.9-2.6-1.5-4.7-1.6c-1.8-0.1-3.4,0.2-4.8,0.9
									c-1.4,0.7-2.3,1.7-2.9,3.1c-0.1,1.6,0.4,2.8,1.5,3.9c1.1,1,2.6,1.6,4.6,1.7c2.3,0.2,4.1-0.2,5.5-1c1.4-0.8,2.1-1.9,2.2-3.2
									C206.8,618.3,206.3,617.1,205.2,616.2z"
						/>
						<path
							d="M209.1,606.4c-2.9-0.5-6-0.8-9.3-1.1c-1.6-0.1-2.8-0.2-3.7-0.2c-0.9,0-1.5,0.1-2.1,0.3c-0.5,0.2-1.1,0.4-1.6,0.8
									c-0.5-0.5-0.8-1-1.1-1.5c-0.2-0.5-0.3-1-0.3-1.4c0.1-1.5,1.3-2.2,3.5-2.1c-0.9-0.8-1.6-1.6-2.1-2.4c-0.5-0.8-0.9-1.8-1.1-2.8
									c0.5-0.1,1.2-0.2,2.1-0.3c0.9-0.1,1.7-0.1,2.4,0c-0.1,0.3-0.2,0.8-0.2,1.4c-0.1,0.9,0,1.7,0.1,2.5c0.1,0.8,0.4,1.5,0.6,2l1.4,0.2
									c1.9,0.2,3.6,0.4,5.1,0.6c3.1,0.3,5.3,0.2,6.6-0.2c0.1,0.8,0.1,1.6,0.1,2.2C209.5,604.8,209.3,605.6,209.1,606.4z"
						/>
						<path
							d="M210,593.3c-0.5,0.3-1.5,0.3-2.9,0.2c-0.9-0.1-2.3-0.3-4.3-0.6c-2.2-0.3-4-0.5-5.5-0.7c-4.7-0.5-8.1-0.6-10.1-0.4
									c-0.2-0.8-0.4-1.5-0.4-2.2c-0.1-0.7,0-1.5,0.1-2.3c3.6,0.7,8.7,1.5,15.5,2.2c1.6,0.2,2.8,0.3,3.7,0.3c0.9,0,1.6-0.1,2.1-0.2
									c0.5-0.2,1.1-0.4,1.6-0.8c0.5,0.5,0.8,1,1,1.5c0.2,0.5,0.3,1,0.2,1.4C210.8,592.5,210.5,593.1,210,593.3z"
						/>
						<path
							d="M187.6,582.2c-1-0.9-1.8-1.9-2.4-2.8c1.1-0.6,2.1-1.3,3-2.1c0.9,0.9,1.7,1.8,2.4,2.8C189.6,580.6,188.6,581.4,187.6,582.2
									z M211.1,583.8c-0.6,0.3-1.5,0.3-2.9,0.1c-1.1-0.1-2.6-0.4-4.6-0.7c-1.9-0.3-3.2-0.5-4.1-0.6c-1.2-0.2-2.3-0.2-3.3-0.2
									c-1,0-1.8,0.1-2.2,0.3c-0.2-0.8-0.4-1.5-0.4-2.2c0-0.7,0-1.5,0.1-2.3c0.8,0.2,2.1,0.5,3.9,0.8c1.7,0.3,3.7,0.6,5.9,0.9
									c1.6,0.2,2.8,0.3,3.7,0.3s1.5,0,2.1-0.2s1.1-0.4,1.7-0.8c0.5,0.5,0.8,1,1,1.6c0.2,0.5,0.3,1,0.2,1.4
									C212,583,211.6,583.6,211.1,583.8z"
						/>
						<path
							d="M212.7,574.2c-2.9-0.6-5.9-1.2-9.2-1.7c-1.6-0.2-2.8-0.4-3.7-0.4c-0.9,0-1.5,0-2.1,0.1c-0.5,0.1-1.1,0.4-1.7,0.7
									c-0.5-0.5-0.8-1-1-1.5c-0.2-0.5-0.2-1-0.2-1.4c0.2-1.4,1.2-2.1,3-2c-0.8-0.8-1.3-1.7-1.7-2.8c-0.3-1-0.4-2-0.3-3
									c0.5-3.1,2.7-4.4,6.7-3.8l2.7,0.5c1.1,0.2,2,0.4,2.9,0.6c3,0.5,5.2,0.5,6.7,0.1c0.1,0.8,0,1.5-0.1,2.2c-0.1,0.7-0.3,1.5-0.6,2.4
									c-3.5-0.7-7.3-1.4-11.4-2c-1.2-0.2-2-0.1-2.6,0.3c-0.6,0.4-0.9,1-1.1,2c-0.2,1.5-0.1,2.7,0.4,3.8l1.9,0.3c1.5,0.3,3.2,0.6,5.2,0.9
									c3,0.5,5.3,0.5,6.6,0.2c0.1,0.8,0,1.6-0.1,2.3C213.2,572.6,213,573.3,212.7,574.2z"
						/>
						<path
							d="M214.1,553.7c-1.7,0.8-4.1,0.9-7.2,0.4c-3.1-0.6-5.4-1.6-6.7-3c-1.4-1.4-1.9-3.2-1.4-5.5c0.3-1.8,1.2-3.2,2.6-4.5
									c-2.9-0.4-5.1-0.5-6.6-0.4c-0.2-0.8-0.3-1.6-0.3-2.3c0-0.7,0.1-1.5,0.2-2.3c3.5,1,9.6,2.3,18.2,3.9c1,0.2,1.8,0.2,2.5,0.1
									c0.6-0.1,1.3-0.4,2.2-0.9c0.7,1.1,1,2.2,1,3.5c-0.7,0.6-1.6,1-2.7,1.1c0.6,0.9,1.1,1.8,1.4,2.7c0.3,1,0.3,1.9,0.2,2.8
									C216.9,551.4,215.8,552.9,214.1,553.7z M215.1,545.8c-0.2-0.7-0.5-1.5-0.9-2.2c-0.9-0.2-2.4-0.5-4.5-0.9c-2.4-0.5-4.2-0.9-5.6-1.1
									c-0.9,0.9-1.5,2.1-1.8,3.5c-0.3,1.4,0.1,2.5,1,3.5c0.9,0.9,2.4,1.6,4.4,2c2,0.4,3.6,0.3,5-0.2s2.2-1.3,2.7-2.5
									C215.4,547.2,215.3,546.6,215.1,545.8z"
						/>
						<path
							d="M217.7,534c-1.9,1-4.1,1.2-6.8,0.6c-1.9-0.4-3.5-1.1-4.8-2.2s-2.2-2.3-2.8-3.8c-0.5-1.5-0.6-3.1-0.3-4.7
									c0.3-1.5,1-2.7,1.9-3.7c1-1,2.2-1.6,3.6-2c1.5-0.3,3-0.3,4.8,0.1c1.9,0.4,3.5,1.2,4.8,2.2c1.3,1.1,2.2,2.4,2.8,3.9
									c0.5,1.5,0.6,3.1,0.2,4.9C220.8,531.5,219.6,533,217.7,534z M218.7,524.3c-0.9-1.1-2.4-1.8-4.4-2.3c-1.8-0.4-3.4-0.3-4.9,0.1
									c-1.5,0.5-2.6,1.3-3.3,2.6c-0.3,1.5,0,2.9,0.9,4c0.9,1.2,2.4,1.9,4.3,2.4c2.2,0.5,4.1,0.4,5.6-0.1c1.5-0.6,2.4-1.5,2.7-2.8
									C219.9,526.7,219.6,525.4,218.7,524.3z"
						/>
						<path
							d="M223.9,515.3c-2.8-0.9-5.8-1.8-9-2.6c-1.6-0.4-2.8-0.6-3.6-0.8c-0.8-0.1-1.5-0.1-2.1-0.1c-0.5,0.1-1.1,0.3-1.7,0.6
									c-0.4-0.6-0.7-1.1-0.8-1.6c-0.1-0.5-0.1-1,0-1.4c0.3-1.4,1.4-2,3.2-1.8c-0.7-0.9-1.1-1.9-1.4-2.9c-0.3-1.1-0.3-2.1,0-3
									c0.8-3.1,3.1-4.1,7-3.1l2.7,0.8c1,0.3,2,0.6,2.8,0.8c2.9,0.7,5.1,1,6.6,0.8c0,0.8-0.1,1.5-0.3,2.1c-0.2,0.7-0.5,1.4-0.8,2.3
									c-3.4-1-7.1-2.1-11.1-3.1c-1.1-0.3-2-0.3-2.6,0c-0.6,0.3-1,0.9-1.3,1.9c-0.4,1.4-0.3,2.7,0.1,3.8l1.8,0.5c1.4,0.4,3.1,0.9,5.1,1.4
									c3,0.8,5.2,1,6.6,0.8c0,0.8-0.1,1.6-0.3,2.3C224.6,513.7,224.3,514.4,223.9,515.3z"
						/>
					</g>
					<g className="level_two fade location" id="forlond">
						<rect x="1635.939" y="1732.284" width="106.224" height="38.109" transform="matrix(-0.049788, -0.99876, 0.999359, -0.037762, -1427.20166, 2402.035645)"></rect>
						<path
							d="M259.2,692.7c-1.2,0.4-2.8,1-4.8,1.7c-2,0.8-3.9,1.6-5.7,2.3c-4.9,2.1-8.1,3.8-9.7,4.9c-0.4-1.3-1.1-3.2-2.2-5.8
									c-1.2-2.8-2.2-4.9-3.1-6.5c0.7-0.3,1.5-0.6,2.3-0.8c0.9-0.2,1.5-0.3,1.9-0.2c-0.1,0.7-0.1,1.4,0.1,2.3c0.2,0.8,0.4,1.7,0.7,2.4
									c0.5,1.2,1.1,2.3,1.8,3.3c2.5-1,4.4-1.7,5.9-2.3l-0.3-0.8c-0.3-0.8-0.5-1.4-0.7-1.7c-0.4-1-0.8-1.8-1.1-2.3
									c-0.3-0.5-0.7-1.1-1.2-1.8c0.5-0.3,0.9-0.6,1.4-0.8c0.4-0.2,0.9-0.3,1.5-0.5l0.6,1.6c0.5,1.5,1,2.9,1.6,4.2c0.2,0.6,0.4,1,0.6,1.2
									c4.9-2.1,7.9-3.6,8.8-4.5c0.4,0.6,0.8,1.2,1.1,1.9C258.8,691.2,259,691.9,259.2,692.7z"
						/>
						<path
							d="M252,680.2c-1.1,1.7-2.9,2.9-5.4,3.6c-1.8,0.5-3.4,0.7-5,0.4c-1.6-0.3-2.9-0.9-4.1-1.9c-1.1-1-1.9-2.3-2.4-3.8
									c-0.4-1.4-0.4-2.7-0.1-4c0.4-1.3,1.1-2.4,2.1-3.3c1.1-1,2.4-1.7,4-2.2c1.8-0.5,3.5-0.7,5-0.4c1.6,0.3,3,0.9,4.1,2
									c1.1,1,2,2.3,2.4,3.9C253.3,676.7,253.1,678.5,252,680.2z M248.3,671.7c-1.3-0.5-2.8-0.4-4.7,0.1c-1.7,0.5-3,1.3-4,2.4
									c-1,1.1-1.5,2.3-1.6,3.7c0.4,1.4,1.3,2.4,2.6,2.9c1.3,0.5,2.9,0.5,4.7,0c2.1-0.6,3.6-1.5,4.6-2.7c1-1.2,1.3-2.4,0.9-3.6
									C250.4,673.1,249.6,672.1,248.3,671.7z"
						/>
						<path
							d="M249.6,664.4c-2.8,0.4-5.7,0.9-8.8,1.5c-1.5,0.3-2.7,0.6-3.4,0.8c-0.8,0.2-1.4,0.5-1.8,0.8c-0.4,0.3-0.8,0.7-1.3,1.2
									c-0.6-0.3-1-0.7-1.4-1.1c-0.3-0.4-0.6-0.8-0.6-1.2c-0.3-1.4,0.6-2.4,2.6-2.9c-1-0.5-1.9-1-2.6-1.7c-0.7-0.6-1.3-1.4-1.8-2.3
									c0.4-0.3,1-0.5,1.8-0.8s1.5-0.5,2.2-0.7c0,0.3,0.1,0.7,0.2,1.3c0.2,0.8,0.4,1.6,0.8,2.3c0.4,0.7,0.7,1.2,1.1,1.6l1.3-0.2
									c1.8-0.3,3.4-0.6,4.8-0.9c2.9-0.6,4.9-1.2,6-2c0.3,0.7,0.5,1.4,0.7,2C249.6,662.7,249.6,663.5,249.6,664.4z"
						/>
						<path
							d="M247.5,654.7c-0.4,0.4-1.3,0.7-2.6,0.8c-0.9,0.1-2.2,0.3-4.1,0.5c-2.1,0.2-3.9,0.5-5.2,0.7c-4.5,0.6-7.6,1.3-9.5,2.1
									c-0.4-0.7-0.7-1.3-0.9-2c-0.2-0.6-0.4-1.3-0.5-2.1c3.5-0.2,8.4-0.7,14.8-1.6c1.5-0.2,2.6-0.4,3.5-0.6c0.8-0.2,1.4-0.4,1.9-0.7
									c0.4-0.3,0.9-0.7,1.3-1.2c0.6,0.3,1,0.7,1.3,1.2c0.3,0.4,0.5,0.9,0.6,1.2C248.1,653.7,247.9,654.3,247.5,654.7z"
						/>
						<path
							d="M245.2,648.2c-1.4,1.4-3.4,2.2-6,2.5c-1.8,0.2-3.5,0-5-0.6s-2.7-1.5-3.6-2.7s-1.4-2.6-1.6-4.2c-0.1-1.4,0.1-2.7,0.7-3.9
									c0.6-1.2,1.5-2.1,2.8-2.8c1.2-0.7,2.7-1.2,4.4-1.3c1.8-0.2,3.5,0.1,5,0.7c1.5,0.6,2.7,1.5,3.6,2.8s1.4,2.7,1.6,4.4
									C247.3,645,246.6,646.8,245.2,648.2z M243.3,639.1c-1.1-0.7-2.7-1-4.6-0.8c-1.7,0.2-3.2,0.7-4.4,1.5c-1.2,0.9-1.9,2-2.3,3.3
									c0.1,1.5,0.8,2.6,2,3.4c1.2,0.8,2.7,1.1,4.6,0.9c2.2-0.2,3.9-0.8,5.1-1.7c1.2-0.9,1.7-2.1,1.6-3.3
									C245.1,640.9,244.5,639.8,243.3,639.1z"
						/>
						<path
							d="M245.7,631.8c-2.8,0-5.8,0.1-8.9,0.2c-1.5,0.1-2.7,0.2-3.5,0.3c-0.8,0.1-1.4,0.3-1.9,0.5c-0.5,0.2-0.9,0.6-1.4,1
									c-0.6-0.4-1-0.8-1.2-1.2c-0.3-0.4-0.4-0.9-0.4-1.3c-0.1-1.4,0.7-2.2,2.4-2.5c-0.9-0.6-1.6-1.4-2.1-2.3s-0.8-1.8-0.9-2.8
									c-0.2-3,1.7-4.6,5.5-4.8l2.6-0.1c1,0,2,0,2.8,0c2.9-0.2,5-0.6,6.2-1.2c0.2,0.7,0.3,1.4,0.4,2c0,0.7,0,1.4-0.1,2.3
									c-3.4,0-7.1,0.1-11,0.4c-1.1,0.1-1.9,0.3-2.4,0.8c-0.5,0.4-0.7,1.1-0.6,2.1c0.1,1.4,0.5,2.6,1.2,3.5l1.8-0.1
									c1.4,0,3.1-0.1,5.1-0.2c2.9-0.2,5-0.5,6.2-1.1c0.2,0.8,0.3,1.5,0.4,2.2C245.9,630.2,245.8,630.9,245.7,631.8z"
						/>
						<path
							d="M243,614.7c-1.5,1.1-3.7,1.7-6.6,1.8c-3,0.1-5.3-0.4-6.9-1.4c-1.6-1-2.4-2.7-2.5-4.8c-0.1-1.7,0.5-3.3,1.5-4.7
									c-2.8,0.2-4.9,0.6-6.2,0.9c-0.3-0.7-0.6-1.4-0.7-2.1c-0.1-0.7-0.2-1.4-0.3-2.2c3.5,0.2,9.4,0.2,17.7-0.1c1,0,1.8-0.2,2.3-0.4
									c0.6-0.2,1.2-0.6,1.8-1.3c0.9,0.8,1.4,1.9,1.6,3c-0.5,0.7-1.3,1.2-2.3,1.5c0.8,0.7,1.4,1.4,1.8,2.3c0.4,0.8,0.7,1.7,0.7,2.5
									C245.2,612,244.5,613.6,243,614.7z M242.4,607.1c-0.4-0.6-0.8-1.3-1.3-1.8c-0.9,0-2.3,0-4.4,0.1c-2.3,0-4.1,0.1-5.4,0.1
									c-0.7,1-1,2.2-0.9,3.6c0,1.3,0.6,2.4,1.7,3c1.1,0.7,2.6,1,4.5,0.9c1.9-0.1,3.5-0.4,4.6-1.1c1.1-0.7,1.8-1.7,2-2.9
									C242.9,608.4,242.7,607.8,242.4,607.1z"
						/>
					</g>
					<g className="level_two fade location " id="himling">
						<rect x="2093.713" y="2334.37" width="135.947" height="51.358" transform="matrix(0.936258, 0.351312, -0.362587, 0.932028, -1011.268433, -2687.163574)"></rect>
						<path
							d="M101.8,246.5c1.6-0.6,3.7-1.6,6.4-2.8c2.7-1.3,5.2-2.5,7.6-3.7c5.5-2.8,8.8-4.8,9.9-5.9c0.6,0.7,1.1,1.4,1.4,2.1
									c0.4,0.7,0.7,1.6,1,2.6c-2.7,1.1-6.3,2.8-10.7,4.9c0.6,1.6,1.5,3.6,2.8,6c0.2,0.4,0.8,1.5,1.9,3.4c5.6-2.9,9-4.9,10.2-6
									c0.6,0.7,1,1.4,1.4,2.1c0.4,0.7,0.7,1.6,1,2.6c-1.6,0.6-3.7,1.6-6.4,2.8c-2.7,1.3-5.2,2.5-7.6,3.7c-5.5,2.8-8.8,4.8-9.9,5.9
									c-0.6-0.7-1-1.4-1.4-2.2c-0.4-0.7-0.7-1.5-0.9-2.5c2.7-1.1,6.3-2.7,10.8-5c-0.8-1.8-1.7-3.6-2.6-5.5c-0.6-1.3-1.3-2.5-2.1-3.8
									c-5.7,2.9-9.1,4.9-10.2,6.1c-0.6-0.7-1.1-1.4-1.5-2.2C102.4,248.3,102.1,247.5,101.8,246.5z"
						/>
						<path
							d="M117.5,269.5c0.2-0.7,0.9-1.6,2-2.6c0.9-0.9,2.2-2.1,4-3.6c1.6-1.4,2.8-2.5,3.6-3.2c1-1,1.9-1.9,2.6-2.8
									c0.7-0.9,1.2-1.7,1.4-2.2c0.9,0.4,1.6,0.8,2.3,1.3c0.7,0.5,1.3,1.1,2,1.8c-0.8,0.6-2,1.5-3.6,2.8c-1.6,1.3-3.3,2.9-5.2,4.6
									c-1.4,1.3-2.4,2.3-3,3c-0.6,0.8-1.1,1.4-1.4,2c-0.3,0.6-0.4,1.3-0.5,2.1c-0.8,0.1-1.5,0-2.1-0.3c-0.6-0.2-1.1-0.5-1.4-0.9
									C117.5,270.8,117.3,270.2,117.5,269.5z M136.3,249.8c1.6-0.2,3-0.2,4.3-0.1c-0.2,1.4-0.4,2.9-0.3,4.3c-1.4,0.2-2.9,0.2-4.3,0
									C136.2,252.7,136.3,251.2,136.3,249.8z"
						/>
						<path
							d="M126.9,280.1c1.8-2.9,3.6-6.1,5.3-9.5c0.9-1.7,1.5-2.9,1.8-3.9c0.4-0.9,0.6-1.7,0.6-2.3c0.1-0.6,0-1.3-0.2-2.1
									c0.7-0.3,1.4-0.5,2.1-0.5c0.6,0,1.2,0.1,1.6,0.3c1.4,0.8,1.8,2.1,1.1,4c1.2-0.5,2.4-0.7,3.6-0.7c1.2,0,2.3,0.3,3.3,0.8
									c2.4,1.2,3.3,3.1,2.7,5.6c1.3-0.6,2.5-1,3.9-1c1.3,0,2.5,0.2,3.6,0.8c3.2,1.7,3.7,4.6,1.5,8.8l-1.6,2.8c-0.7,1.1-1.2,2.1-1.7,3
									c-1.6,3.1-2.5,5.5-2.7,7.2c-0.9-0.2-1.7-0.5-2.3-0.9c-0.7-0.4-1.5-0.9-2.3-1.6c2.1-3.5,4.3-7.4,6.5-11.7c0.6-1.2,0.9-2.2,0.7-2.9
									c-0.2-0.8-0.8-1.4-1.8-1.9c-1.4-0.7-2.8-1-4.2-0.9c-0.6,1.1-1.4,2.8-2.7,4.9c-0.4,0.6-0.8,1.4-1.3,2.4c-1.6,3.1-2.6,5.5-2.8,7.2
									c-0.8-0.2-1.6-0.5-2.3-0.9c-0.7-0.4-1.5-0.9-2.4-1.6c2.1-3.5,4.3-7.4,6.5-11.7c0.6-1.2,0.9-2.2,0.7-2.9c-0.2-0.8-0.8-1.4-1.8-1.9
									c-1.4-0.7-2.8-1-4.1-0.8l-1.1,1.9c-0.9,1.5-1.9,3.3-3,5.4c-1.7,3.2-2.6,5.6-2.7,7.2c-0.9-0.3-1.8-0.6-2.5-1
									C128.5,281.3,127.8,280.8,126.9,280.1z"
						/>
						<path
							d="M160.4,294c-0.2-0.7-0.2-1.8,0.2-3.3c0.2-1,0.6-2.7,1.2-4.9c0.6-2.5,1.1-4.6,1.5-6.2c1.2-5.3,1.7-9.2,1.6-11.6
									c0.9-0.2,1.8-0.3,2.6-0.2c0.8,0,1.7,0.1,2.6,0.3c-1.3,4-2.7,9.9-4.4,17.6c-0.4,1.8-0.6,3.2-0.8,4.2c-0.1,1-0.1,1.8,0,2.4
									c0.1,0.6,0.4,1.3,0.8,2c-0.6,0.5-1.3,0.8-1.9,1c-0.6,0.2-1.2,0.2-1.7,0.1C161.2,295.1,160.7,294.6,160.4,294z"
						/>
						<path
							d="M168.6,295.5c-0.3-0.7-0.3-1.8-0.1-3.3c0.2-1.2,0.4-3,0.8-5.3c0.3-2.2,0.6-3.7,0.7-4.7c0.2-1.4,0.3-2.7,0.2-3.8
									c0-1.2-0.1-2-0.4-2.6c0.9-0.3,1.8-0.4,2.6-0.5c0.8-0.1,1.7,0,2.7,0.1c-0.3,1-0.6,2.5-1,4.5c-0.4,2-0.7,4.3-1,6.8
									c-0.2,1.9-0.4,3.3-0.4,4.3c0,1,0.1,1.8,0.2,2.4c0.2,0.6,0.5,1.2,0.9,1.9c-0.6,0.5-1.2,0.9-1.8,1.2c-0.6,0.2-1.2,0.3-1.6,0.3
									C169.5,296.5,168.9,296.2,168.6,295.5z M170.5,268.3c1.1-1.1,2.2-2.1,3.2-2.8c0.7,1.3,1.6,2.4,2.5,3.5c-1,1-2.1,2-3.3,2.8
									C172.3,270.6,171.5,269.5,170.5,268.3z"
						/>
						<path
							d="M179.4,297.5c0-3.4-0.1-7-0.4-10.9c-0.1-1.9-0.3-3.3-0.4-4.3c-0.2-1-0.4-1.7-0.7-2.3c-0.3-0.6-0.7-1.1-1.3-1.7
									c0.5-0.7,1-1.2,1.5-1.5c0.5-0.3,1-0.5,1.5-0.5c1.7-0.1,2.7,0.9,3.1,2.9c0.7-1.1,1.7-1.9,2.7-2.6c1.1-0.6,2.2-1,3.3-1.1
									c3.7-0.2,5.6,2,5.9,6.7l0.1,3.2c0,1.3,0,2.4,0.1,3.4c0.2,3.5,0.7,6,1.5,7.6c-0.9,0.3-1.7,0.4-2.5,0.5c-0.8,0.1-1.8,0-2.8-0.1
									c0-4.1-0.2-8.6-0.5-13.4c-0.1-1.4-0.4-2.3-0.9-2.9c-0.5-0.6-1.4-0.8-2.5-0.7c-1.7,0.1-3.1,0.6-4.2,1.4l0.1,2.2
									c0,1.7,0.1,3.8,0.3,6.2c0.2,3.6,0.7,6.1,1.4,7.6c-0.9,0.3-1.8,0.4-2.6,0.5C181.3,297.7,180.5,297.6,179.4,297.5z"
						/>
						<path
							d="M203.7,302.1c-0.4-1.2-0.5-2.4-0.5-3.6c0.1-1.3,0.3-2.4,0.8-3.5c-1.3-0.1-2.2-0.8-2.6-2.1c-0.3-0.9-0.2-1.8,0.1-2.7
									c0.4-0.9,1-1.8,2-2.5c-1.2-0.2-2.3-0.8-3.2-1.6c-0.9-0.9-1.6-2-2-3.4c-0.7-2.3-0.6-4.3,0.4-6.2c1-1.9,2.7-3.2,4.9-3.9
									c1.5-0.5,3-0.6,4.3-0.2c0.5-0.4,1.1-0.9,1.7-1.7c0.6-0.8,1.2-1.6,1.7-2.6c0.5,0.5,1,1.1,1.5,1.8c0.5,0.7,0.8,1.3,0.9,1.8
									c-0.8,0.9-1.8,1.6-3.2,2.1c1,1,1.7,2.2,2.1,3.5c0.5,1.5,0.6,2.9,0.2,4.3c-0.3,1.4-1,2.6-2,3.6c-1,1-2.3,1.8-3.9,2.3
									c-1.3,0.4-2.2,1-2.8,1.6s-0.8,1.4-0.5,2.1l8.6-2.2c1.7-0.4,3-0.3,4.1,0.3c1.1,0.5,1.9,1.5,2.3,2.9c0.4,1.3,0.4,2.6-0.1,3.9
									c-0.5,1.3-1.3,2.5-2.5,3.5c-1.2,1.1-2.7,1.9-4.5,2.4C208.6,302.8,206,302.9,203.7,302.1z M209.7,283c0.5-1,0.5-2.1,0.1-3.4
									c-0.4-1.2-1-2.1-1.9-2.7c-0.9-0.6-2-0.9-3.2-0.8c-1.2,0.4-2.1,1.1-2.6,2.3c-0.5,1.1-0.6,2.3-0.1,3.7c0.4,1.3,1.1,2.2,2.1,2.7
									s2,0.6,3.1,0.3C208.3,284.7,209.2,284,209.7,283z M215.3,296.4c0.8-0.8,1.1-1.7,0.8-2.5c-0.2-0.6-0.6-1.1-1.1-1.2
									c-0.5-0.2-1.3-0.1-2.3,0.2l-6.8,1.8c-0.2,1.1-0.2,2.1-0.1,2.9c1.7,1.3,3.7,1.6,5.9,0.9C213.3,297.9,214.5,297.2,215.3,296.4z"
						/>
					</g>
					<g className="level_one location " id="ered_luin">
						<rect x="2349.002" y="1834.689" width="152.523" height="40.365" transform="matrix(0.008948, 0.99996, -1.000068, -0.003095, 2313.665283, -2096.915771)"></rect>
						<rect x="2424.188" y="1994.044" width="157.405" height="43.871" transform="matrix(0.317422, 0.948284, -0.952107, 0.306002, 1630.459229, -2523.370361)"></rect>
						<rect x="1718.536" y="1448.702" width="111.588" height="31.87" transform="matrix(-0.031468, 0.999505, -0.999126, -0.043502, 2103.909424, -917.374512)"></rect>
						<rect x="1718.539" y="1448.705" width="111.588" height="31.87" transform="matrix(0.508727, 0.860928, -0.867053, 0.498362, 973.552368, -1371.473389)"></rect>
						<rect x="1285.232" y="1448.707" width="83.452" height="31.87" transform="matrix(0.213667, 0.976907, -0.979479, 0.201905, 1794.239624, -622.026428)"></rect>
						<path
							d="M475.2,254.3c2.1,0.2,5,0.4,8.7,0.5c6.6,0.3,11.1,0.2,13.6-0.3c-0.4,1.9-0.7,4.9-0.8,8.9c-0.2,4.9-0.3,8.7-0.1,11.4
									c-1.1-0.1-2.2-0.2-3.5-0.5c-1.2-0.3-2.1-0.6-2.5-1c0.6-0.9,1-2,1.4-3.4c0.3-1.4,0.6-2.8,0.6-4.3c0.1-1.9,0-3.8-0.2-5.4
									c-3.3-0.3-6.3-0.5-9-0.7l-0.1,1.4c-0.1,1.4-0.2,2.4-0.3,3.1c-0.1,1.8-0.1,3.1,0,4.1c0.1,0.9,0.2,2,0.4,3.1
									c-0.8,0.1-1.5,0.1-2.2,0.1c-0.6,0-1.3-0.1-2.3-0.3c0.5-4,0.8-7.2,0.9-9.5c0.1-1.1,0.1-1.8,0-2.3c-4.4-0.2-7.4-0.3-9.2-0.2
									c-0.5,1.7-0.8,3.7-0.9,5.8c-0.1,1.6,0,3.2,0.2,4.9c0.2,1.6,0.5,3,1,4c-1.1-0.1-2.2-0.2-3.5-0.5c-1.2-0.3-2.1-0.6-2.5-0.9
									c0.4-2.3,0.7-5.7,0.9-10.1c0.2-3.9,0.2-6.9,0-8.8C469.9,253.8,473.1,254.1,475.2,254.3z"
						/>
						<path
							d="M464.6,279.8c1.9,0.3,4.7,0.6,8.4,0.9c3.6,0.3,6.9,0.6,9.7,0.8c6.6,0.4,10.7,0.3,12.2-0.2c0.4,1.2,0.7,2.7,0.9,4.6
									c0.2,1.9,0.2,3.8,0.1,5.7c-0.2,3.7-1.3,6.4-3.2,8.3c-1.9,1.9-4.6,2.7-8,2.5c-2-0.1-3.8-0.7-5.3-1.8c-1.5-1.1-2.7-2.5-3.5-4.4
									c-6.2,2.5-10.1,4.5-11.7,6.1c-0.4-1-0.7-2.1-0.8-3.1c-0.1-1.1-0.1-2.2-0.1-3.5c2.2-0.6,6-2.1,11.5-4.5c0-0.1,0-0.5,0-1
									c0-0.7,0.1-1.5,0.3-2.4c0.1-0.9,0.3-1.5,0.5-2c-5.8-0.3-9.5-0.2-11.3,0.2c-0.2-1-0.2-2-0.2-3.1C464.1,282,464.3,281,464.6,279.8z
									M480.3,295.5c1.2,1,2.6,1.5,4.1,1.6c4.8,0.3,7.3-2,7.6-7c0.1-1.1,0-2.1-0.2-3c-2.9-0.4-7-0.7-12.2-1.1c-1.2,2.5-1.8,4.5-1.9,5.9
									C478.3,293.4,479.1,294.6,480.3,295.5z"
						/>
						<path
							d="M471.6,309.6c2.1,0.1,5,0.2,8.7,0.2c6.6,0.1,11.1-0.2,13.6-0.8c-0.3,1.9-0.5,4.9-0.5,8.9c-0.1,4.9,0,8.7,0.3,11.4
									c-1.1,0-2.2-0.1-3.5-0.4c-1.2-0.2-2.1-0.5-2.6-0.9c0.5-0.9,0.9-2,1.2-3.4c0.3-1.4,0.5-2.8,0.5-4.3c0-2-0.1-3.8-0.4-5.4
									c-3.4-0.2-6.4-0.3-9-0.4l-0.1,1.4c-0.1,1.4-0.1,2.4-0.2,3.1c0,1.8,0,3.1,0.1,4.1c0.1,0.9,0.2,2,0.5,3.1c-0.8,0.1-1.5,0.2-2.2,0.2
									c-0.6,0-1.3-0.1-2.3-0.2c0.3-4,0.5-7.2,0.5-9.5c0-1.1,0-1.8,0-2.3c-4.4,0-7.4,0-9.2,0.1c-0.4,1.8-0.6,3.7-0.7,5.8
									c0,1.6,0.1,3.2,0.4,4.9c0.3,1.6,0.6,3,1.2,4c-1.1,0-2.2-0.1-3.5-0.4c-1.3-0.2-2.1-0.5-2.5-0.9c0.3-2.4,0.5-5.7,0.5-10.2
									c0-3.9-0.1-6.9-0.3-8.8C466.3,309.3,469.5,309.5,471.6,309.6z"
						/>
						<path
							d="M462.7,343.8c0-1.7,0-3.2,0.1-4.4c1.9-0.1,4.6-0.3,7.9-0.6c3.3-0.3,6.5-0.7,9.4-1.1c6.6-0.9,10.6-1.7,12.1-2.6
									c0.6,1.1,1.2,2.6,1.8,4.5c0.6,1.9,1,3.9,1.2,5.9c0.7,4.9-0.2,8.8-2.6,11.7c-2.4,3-6.1,4.8-11.2,5.5c-3.2,0.4-6.1,0.1-8.7-0.9
									c-2.6-1-4.8-2.7-6.5-4.9c-1.7-2.3-2.8-5-3.2-8.1C462.9,347.1,462.8,345.5,462.7,343.8z M469.2,354.8c1.4,1.1,3,1.9,4.9,2.4
									c1.9,0.5,3.7,0.6,5.6,0.4c8.7-1.2,12.5-5.4,11.5-12.8c-0.2-1.2-0.5-2.3-1-3.4c-4.5,0.3-9.1,0.8-13.8,1.5c-3.8,0.5-7,1-9.6,1.6
									c-0.4,2.8-0.5,5-0.3,6.4C466.9,352.3,467.8,353.6,469.2,354.8z"
						/>
						<path
							d="M488.1,427.2c1.8-0.4,4.4-1.1,7.7-2c7.1-2,11.3-3.5,12.7-4.6c0.5,1,0.9,2,1.2,3.1c0.3,1,0.5,2.1,0.6,3.5
									c-2.1,0.4-4.9,1-8.3,1.8s-6.5,1.6-9.2,2.4c-4,1.1-6.8,2-8.3,2.5c0.1,1.8,0.5,3.9,1.1,6.2c0.4,1.4,0.9,2.7,1.6,4
									c0.7,1.3,1.4,2.3,2.1,3.1c-1.2,0.3-2.4,0.5-3.6,0.6c-1.3,0.1-2.2,0-2.7-0.2c-0.4-2.6-1.1-5.6-2.1-9c-1.1-4.1-2.1-7.1-2.9-9.1
									C483,428.3,486.3,427.6,488.1,427.2z"
						/>
						<path
							d="M491.7,462.2c1.4-2.6,3.9-4.5,7.6-5.8c3.9-1.3,7.7-2.7,11.3-4.1c3.6-1.4,5.9-2.5,7-3.1c0.6,0.9,1,1.9,1.4,2.9
									c0.3,1,0.6,2.1,0.8,3.5c-5.6,1.7-11.9,3.7-18.9,6.1c-5.2,1.8-7,4.9-5.5,9.5c1.6,4.8,4.8,6.4,9.5,4.8c10.7-3.7,17.2-6,19.3-7.1
									c0.5,0.6,0.8,1.3,1.1,2c0.2,0.6,0.4,1.5,0.5,2.4c-2.2,0.6-5.1,1.5-8.8,2.7c-3.7,1.1-6.9,2.2-9.6,3.1c-3.5,1.2-6.7,1.3-9.5,0.2
									c-2.9-1.1-5-3.7-6.5-8.1C490.2,467.8,490.3,464.8,491.7,462.2z"
						/>
						<path
							d="M500.5,495.8c1.9-0.3,4.6-0.9,8-1.8c3.4-0.8,6.6-1.7,9.6-2.6c7-2,11.3-3.6,12.7-4.7c0.5,1,1,2.1,1.2,3
									c0.3,1,0.5,2.2,0.6,3.5c-1.9,0.3-4.6,0.9-7.9,1.8c-3.4,0.8-6.5,1.7-9.5,2.5c-7,2-11.3,3.6-12.8,4.7c-0.5-1-1-2-1.3-3.1
									C500.8,498.2,500.6,497,500.5,495.8z"
						/>
						<path
							d="M506.5,513.5c4.2-0.1,10.3-0.6,18.1-1.3c7.1-0.7,11.5-1.4,13.4-2c0.4,1.2,0.6,2.2,0.7,3c0.1,0.9,0.1,2.1,0,3.5
									c-2.7,1.8-6.3,4.4-11,7.8c-4.7,3.4-8.7,6.4-12.2,8.9c4.7-0.4,8.4-0.7,11.1-1c7.4-0.7,11.9-1.4,13.5-2c0.3,0.8,0.5,1.5,0.6,2.3
									c0.1,0.7,0,1.5-0.1,2.4c-2.1,0.1-4.9,0.3-8.4,0.5c-3.5,0.3-6.7,0.5-9.7,0.8c-7.1,0.7-11.5,1.4-13.4,2c-0.3-0.6-0.5-1.6-0.7-3
									c-0.1-0.9-0.1-2.1,0-3.6c2.6-1.8,6.2-4.4,10.9-7.7c4.7-3.4,8.8-6.4,12.2-9c-3.5,0.2-7.2,0.5-11.1,0.9c-7.2,0.7-11.7,1.4-13.5,2
									c-0.3-0.7-0.5-1.5-0.6-2.3C506.3,515.3,506.3,514.5,506.5,513.5z"
						/>
						<path
							d="M600,750.6l-0.6,0.3c-1.3-1.9-3-3.4-5.1-4.7c-2.1-1.2-4.4-2.1-7-2.7c-2.6-0.5-5.1-0.7-7.5-0.4c-2.4,0.3-4.6,1.1-6.5,2.2
									l-0.4-0.5c2.2-1.9,4.5-3.3,7.2-4.1c2.6-0.8,5.3-0.9,7.9-0.3c2.6,0.6,5,1.7,7.1,3.5C597.2,745.7,598.8,747.9,600,750.6z"
						/>
						<path
							d="M572.3,755.8c0.4-1.3,0.8-2.3,1.2-3.1c1.2,0.2,2.9,0.5,5.1,0.9c2.3,0.4,4.5,0.7,6.6,0.9c4.2,0.5,6.8,0.7,7.7,0.4
									c0.2,0.9,0.3,2.1,0.3,3.4c0,1.3,0,2.7-0.2,3.9c-0.3,2.3-1,4.1-2.1,5.2c-1.1,1.1-2.6,1.6-4.4,1.3c-1-0.1-1.8-0.5-2.6-1.2
									c-0.7-0.6-1.3-1.5-1.6-2.5c-0.6,1.2-1.4,2.1-2.4,2.7c-1,0.6-2.1,0.8-3.3,0.7c-2-0.3-3.4-1.1-4.3-2.7c-0.9-1.5-1.2-3.6-0.8-6.2
									C571.6,758.4,571.9,757.1,572.3,755.8z M577.1,765c1,0.1,1.9,0,2.7-0.4c0.8-0.4,1.5-1.1,2-2c0.1-1.1,0-2.9-0.4-5.3
									c-3.1-0.4-5.5-0.6-7.1-0.7c-0.5,0.8-0.8,1.8-0.9,2.9C572.9,762.8,574.1,764.6,577.1,765z M583.6,761.1c-0.1,0.7-0.2,1.2-0.3,1.5
									c0.9,1.6,2,2.5,3.3,2.7c2.3,0.3,3.6-0.9,4-3.7c0.1-1,0.1-1.9,0-2.8c-1.9-0.4-4.2-0.7-6.9-1.1C583.7,758.6,583.7,759.7,583.6,761.1
									z"
						/>
						<path
							d="M570.8,776.2c0.5-0.3,1.3-0.4,2.6-0.3c0.8,0,2.1,0.1,4,0.3c2,0.2,3.7,0.3,5,0.3c4.3,0.2,7.4,0.1,9.2-0.2
									c0.3,0.7,0.4,1.4,0.5,2c0.1,0.6,0.1,1.3,0.1,2.1c-3.3-0.5-8-0.8-14.2-1.2c-1.4-0.1-2.6-0.1-3.3,0c-0.8,0-1.4,0.2-1.9,0.3
									c-0.5,0.2-0.9,0.5-1.4,0.9c-0.5-0.4-0.8-0.9-1-1.4c-0.2-0.5-0.3-0.9-0.3-1.3C570,776.9,570.3,776.4,570.8,776.2z"
						/>
						<path
							d="M575.1,788l2.5,0l2.7,0c2.6,0,4.5-0.3,5.6-0.8c0.6,1.2,0.8,2.6,0.9,4.1c-3.3-0.1-7-0.1-11.2,0c-1.1,0-1.8,0.2-2.3,0.7
									c-0.5,0.4-0.7,1.1-0.7,2.1c0,1.2,0.3,2.3,1,3.3c1.8,0,3.4,0,4.8,0l2.2,0c2.6,0,4.5-0.3,5.6-0.8c0.6,1.2,0.8,2.6,0.9,4.1
									c-1.8-0.1-4-0.1-6.5-0.2c-2.5-0.1-4.2-0.1-5.1-0.1c-1,0-1.7,0.1-2.3,0.3s-1.1,0.6-1.8,1.2c-0.8-0.9-1.3-1.9-1.5-2.9
									c0.5-0.8,1.3-1.3,2.4-1.5c-0.8-0.7-1.4-1.4-1.8-2.3c-0.4-0.9-0.7-1.8-0.7-2.8C569.6,789.6,571.4,788.1,575.1,788z"
						/>
						<path
							d="M572.4,809.1c1.3-1.1,3.3-1.8,5.9-1.9c1.8-0.1,3.3,0.1,4.7,0.7c1.4,0.6,2.5,1.4,3.4,2.5c0.8,1.1,1.3,2.4,1.4,3.9
									c0.1,2-0.5,3.5-1.9,4.6c-1.3,1.1-3.4,1.7-6.1,1.9c-0.4-1.3-0.8-2.9-1.2-4.7c-0.4-1.8-0.6-3.7-0.8-5.5c-1.6,0.2-2.8,0.7-3.5,1.4
									c-0.7,0.7-1,1.7-0.9,3c0.1,1,0.2,2,0.5,2.9c0.3,0.9,0.7,1.6,1.2,2.3l-0.7,0.3c-1.1-0.7-2-1.7-2.7-2.9s-1.1-2.5-1.2-3.9
									C570.5,811.8,571.1,810.2,572.4,809.1z M580.1,814.1c0.4,1.2,0.8,2.1,1.2,2.8c1.4-0.1,2.5-0.5,3.4-1.3c0.9-0.8,1.3-1.7,1.2-2.9
									c-0.5-0.7-1.4-1.3-2.6-1.7c-1.2-0.4-2.6-0.6-4.1-0.5C579.4,811.7,579.7,812.9,580.1,814.1z"
						/>
						<path
							d="M573,843.8c2.7-0.7,6.6-1.9,11.6-3.5c2.6-0.9,4.5-1.5,5.8-2c1.3-0.5,2.1-0.9,2.6-1.3c0.4,0.6,0.6,1.2,0.9,1.8
									c0.2,0.5,0.3,1.2,0.5,2.1c-1.6,1.3-3.7,3.1-6.4,5.5c-2.7,2.4-4.8,4.3-6.4,5.8c2.2,0.2,5.1,0.5,8.4,0.8c3.4,0.3,6.2,0.5,8.5,0.6
									c0.3,0.4,0.6,1.1,0.9,1.9c0.2,0.6,0.3,1.3,0.4,2.1c-1.3,0.3-3,0.8-5.2,1.4c-2.2,0.6-4.3,1.3-6.3,2c-4.7,1.6-7.5,2.7-8.4,3.4
									c-0.4-0.6-0.6-1.2-0.9-1.8c-0.2-0.6-0.4-1.3-0.5-2.2c1.3-0.3,3.1-0.8,5.3-1.4c2.2-0.7,4.3-1.3,6.2-2c1.3-0.4,2.7-0.9,4.3-1.5
									c-4.5-0.5-10.5-1-18-1.5c-0.2-0.3-0.4-0.5-0.4-0.7c-0.1-0.2-0.1-0.4,0-0.8c1.4-1.2,3.4-2.9,5.9-5.1c2.5-2.2,4.8-4.2,6.7-6.1
									c-2.6,0.8-4.7,1.5-6.1,2c-4.6,1.5-7.4,2.6-8.5,3.2c-0.3-0.4-0.5-0.8-0.7-1.2C573.1,844.9,573,844.4,573,843.8z"
						/>
						<path
							d="M587.5,873.8c0.6-1.8,2-3.4,4-4.7c1.5-0.9,3-1.4,4.5-1.6c1.5-0.1,2.9,0.1,4.2,0.8s2.3,1.6,3.2,3c0.7,1.2,1.1,2.4,1.1,3.7
									c0,1.3-0.4,2.5-1.1,3.6c-0.7,1.1-1.8,2.1-3.2,3c-1.5,0.9-3,1.4-4.6,1.5c-1.5,0.1-3-0.2-4.3-0.9c-1.3-0.7-2.4-1.7-3.2-3.1
									C587.1,877.4,586.8,875.6,587.5,873.8z M592.9,880.9c1.3,0.1,2.7-0.3,4.3-1.2c1.4-0.9,2.4-1.9,3.1-3.2c0.6-1.2,0.8-2.5,0.5-3.8
									c-0.8-1.2-1.8-1.9-3.1-2.1c-1.3-0.2-2.8,0.2-4.3,1.1c-1.8,1.1-3,2.3-3.6,3.6c-0.6,1.3-0.6,2.5,0,3.6
									C590.6,880,591.7,880.7,592.9,880.9z"
						/>
						<path
							d="M601.8,890l2-1.5l2.2-1.6c2.1-1.6,3.4-3,4-4c1.2,0.6,2.2,1.5,3.2,2.7c-2.6,1.9-5.6,4.1-8.9,6.8c-0.8,0.7-1.3,1.3-1.4,1.9
									c-0.1,0.6,0.1,1.3,0.7,2.1c0.8,1,1.7,1.6,2.8,2c1.4-1.1,2.7-2.1,3.8-2.9l1.8-1.3c2.1-1.6,3.4-3,4-4c1.2,0.6,2.2,1.5,3.2,2.7
									c-1.5,1.1-3.3,2.3-5.3,3.8c-2,1.5-3.4,2.5-4.1,3c-0.8,0.6-1.3,1.1-1.6,1.6c-0.3,0.5-0.6,1.2-0.7,2c-1.2-0.2-2.2-0.7-3-1.4
									c0-0.9,0.3-1.8,1-2.6c-1-0.1-1.9-0.3-2.8-0.8c-0.9-0.4-1.6-1.1-2.2-1.8C598.3,894.6,598.9,892.3,601.8,890z"
						/>
						<path
							d="M610.9,909c2.3-1.3,4.8-2.8,7.3-4.4c1.2-0.8,2.1-1.4,2.8-1.9c0.6-0.5,1-0.9,1.3-1.3c0.3-0.4,0.5-0.9,0.7-1.5
									c0.6,0.1,1.2,0.2,1.6,0.5c0.4,0.3,0.7,0.5,1,0.8c0.7,1.1,0.4,2.2-0.8,3.2c1,0.1,2,0.4,2.8,0.9c0.9,0.5,1.5,1.1,2,1.9
									c1.6,2.4,0.8,4.7-2.3,6.6l-2.2,1.3c-0.9,0.5-1.6,0.9-2.3,1.4c-2.3,1.5-3.8,2.8-4.6,3.9c-0.5-0.5-0.9-1-1.3-1.5
									c-0.3-0.5-0.7-1.2-1-2c2.8-1.6,5.8-3.4,8.9-5.5c0.9-0.6,1.4-1.2,1.6-1.7c0.2-0.6,0-1.3-0.5-2c-0.7-1.1-1.6-1.9-2.6-2.3l-1.5,0.9
									c-1.2,0.7-2.6,1.5-4.1,2.5c-2.4,1.5-3.9,2.8-4.6,3.9c-0.5-0.5-1-1.1-1.4-1.7C611.5,910.4,611.2,909.8,610.9,909z"
						/>
						<path
							d="M621.7,927.3c0.3-0.5,0.9-1,2-1.5c0.7-0.3,2-0.9,3.7-1.6c1.9-0.8,3.4-1.5,4.6-2.1c0.6-0.3,1.1-0.6,1.6-0.8
									c-0.4-0.9-0.6-1.5-0.6-1.9c0-0.4,0.2-0.6,0.5-0.8c1.6,1,3.4,1.9,5.6,2.4l0.3,0.9c-0.8,0.5-1.6,0.9-2.5,1.4
									c0.7,1.5,1.3,2.6,1.8,3.2c-0.5,0.2-1,0.4-1.4,0.5c-0.4,0.1-0.9,0.1-1.4,0.1c0-0.8-0.3-1.8-0.9-3c-1.1,0.4-2.3,0.9-3.5,1.5
									c-3,1.4-5.2,2.6-6.5,3.7c0.6,1,1.1,1.8,1.6,2.3l-0.7,0.6c-0.9-0.1-1.7-0.4-2.4-0.9c-0.7-0.5-1.3-1.2-1.6-1.9
									C621.5,928.4,621.5,927.8,621.7,927.3z"
						/>
						<path
							d="M626.5,937.6c0.4-0.8,1.2-1.4,2.2-1.8c3-1.2,5.8,0.9,8.3,6.2c1.6-0.6,2.7-1.3,3.2-2c0.5-0.7,0.5-1.6,0-2.8
									c-0.3-0.7-0.7-1.4-1.2-2c-0.5-0.6-1.1-1.2-1.8-1.6l0.6-0.6c1.3,0.2,2.6,0.7,3.7,1.5c1.1,0.8,1.9,1.7,2.3,2.9
									c0.6,1.5,0.6,2.8,0.1,4c-0.5,1.1-1.6,2-3.2,2.7c-0.9,0.3-1.9,0.7-3.1,1c-1.2,0.4-2.1,0.7-2.7,0.9c-0.9,0.4-1.6,0.7-2.1,1.1
									c-0.4,0.4-0.9,1-1.3,1.7c-1.1-0.5-2-1.3-2.5-2.3c0.2-0.8,0.7-1.5,1.5-2.1c-0.9-0.6-1.8-1.3-2.5-2c-0.7-0.8-1.2-1.5-1.5-2.3
									C626,939.3,626.1,938.4,626.5,937.6z M629.9,942.4c0.6,0.5,1.1,1,1.8,1.3l0.2-0.1c0.8-0.3,1.6-0.6,2.4-0.8l1.6-0.5
									c-0.7-1.5-1.5-2.6-2.5-3.2c-0.9-0.6-1.9-0.7-2.9-0.3c-0.4,0.2-0.8,0.4-1.1,0.8c-0.3,0.4-0.5,0.8-0.6,1.2
									C628.9,941.3,629.4,941.9,629.9,942.4z"
						/>
						<path
							d="M633,955.5c0.4-0.4,1.1-0.9,2.3-1.3c0.9-0.3,2.3-0.8,4-1.3c1.6-0.5,2.8-0.9,3.6-1.2c1-0.4,2-0.8,2.8-1.2
									c0.8-0.4,1.4-0.8,1.7-1.2c0.5,0.6,0.9,1.1,1.2,1.7c0.3,0.6,0.6,1.2,0.9,2c-0.8,0.1-1.9,0.4-3.5,0.9c-1.6,0.5-3.3,1-5.2,1.7
									c-1.4,0.5-2.4,0.9-3.1,1.2c-0.7,0.3-1.2,0.7-1.6,1c-0.4,0.3-0.7,0.8-1,1.3c-0.6-0.2-1.1-0.5-1.4-0.9c-0.4-0.4-0.6-0.7-0.8-1.1
									C632.6,956.5,632.6,955.9,633,955.5z M652.7,947.3c1.2,0.4,2.2,0.8,3.1,1.3c-0.6,1-1.2,2-1.6,3c-1.1-0.4-2.1-0.8-3.1-1.4
									C651.7,949.3,652.3,948.3,652.7,947.3z"
						/>
						<path
							d="M636.4,965.4c2.6-0.4,5.4-0.9,8.4-1.5c1.4-0.3,2.5-0.6,3.3-0.8c0.7-0.2,1.3-0.5,1.7-0.8s0.8-0.7,1.2-1.2
									c0.6,0.3,1,0.6,1.3,1c0.3,0.4,0.5,0.8,0.6,1.1c0.3,1.3-0.4,2.2-1.9,2.7c0.9,0.5,1.7,1.1,2.3,1.9c0.6,0.8,1,1.6,1.2,2.5
									c0.6,2.8-0.9,4.6-4.5,5.4l-2.5,0.4c-1,0.1-1.9,0.3-2.6,0.4c-2.7,0.6-4.6,1.2-5.7,2c-0.3-0.6-0.5-1.3-0.6-1.9
									c-0.1-0.6-0.2-1.4-0.2-2.2c3.2-0.5,6.6-1.1,10.3-1.9c1-0.2,1.8-0.6,2.1-1c0.4-0.5,0.5-1.2,0.3-2.1c-0.3-1.3-0.8-2.4-1.6-3.1
									l-1.7,0.3c-1.3,0.2-2.9,0.5-4.8,0.9c-2.7,0.6-4.7,1.2-5.7,1.9c-0.3-0.7-0.5-1.4-0.7-2C636.4,967,636.4,966.3,636.4,965.4z"
						/>
						<path
							d="M640.4,982.7c0.5,0,1,0.1,1.4,0.3c0.4,0.1,0.9,0.3,1.4,0.6c-0.7,0.4-1.3,1-1.7,1.8c-0.4,0.7-0.7,1.6-0.8,2.5
									c-0.2,2.3,0.4,3.6,1.8,3.7c0.6,0,1.1-0.2,1.5-0.7c0.4-0.5,1-1.3,1.5-2.3c0.6-1,1.2-1.9,1.6-2.5c0.5-0.6,1.1-1.2,1.8-1.6
									s1.7-0.6,2.7-0.5c1.2,0.1,2.2,0.7,2.9,1.8c0.7,1.1,1,2.5,0.8,4.1c-0.2,2.2-0.6,4.1-1.4,5.6c-0.4,0-1-0.2-1.7-0.5
									c-0.7-0.3-1.3-0.6-1.8-1c0.9-0.8,1.7-1.6,2.3-2.4c0.6-0.8,0.9-1.5,0.9-2.1c-0.1-0.6-0.3-1.1-0.6-1.5c-0.3-0.4-0.7-0.6-1.1-0.6
									c-0.7-0.1-1.4,0.2-1.9,0.7c-0.5,0.5-1.1,1.4-1.8,2.5c-0.6,1-1.1,1.8-1.5,2.4c-0.5,0.6-1.1,1.1-1.8,1.5c-0.7,0.4-1.5,0.6-2.5,0.5
									c-1.5-0.1-2.6-0.8-3.3-1.9s-1-2.8-0.9-4.8C638.7,985.8,639.3,984,640.4,982.7z"
						/>
						<path
							d="M655.5,1008.8c-2.7,0.6-5.3,0.5-7.9-0.3c-2.6-0.8-4.8-2.1-6.8-4.1c-1.9-1.9-3.4-4.3-4.4-7l0.6-0.2c1.2,2,2.7,3.7,4.7,5.1
									c2,1.4,4.2,2.5,6.7,3.2c2.5,0.7,5,1.1,7.4,0.9c2.4-0.1,4.6-0.7,6.7-1.7l0.4,0.5C660.6,1007.1,658.1,1008.2,655.5,1008.8z"
						/>
					</g>
					<g className="level_zero location" id="mithlond">
						<rect x="1123.414" y="1448.7" width="72.946" height="31.87" transform="matrix(0.714756, -0.699374, 0.690769, 0.723176, -1276.036865, 351.618042)"></rect>
						<rect x="1474.89" y="2429.303" width="95.768" height="53.442" transform="matrix(0.714756, -0.699374, 0.690769, 0.723176, -2187.561035, -87.522682)"></rect>
						<text
							transform="rotate(-48.6865, 568.176, 593.339) matrix(1.046, 0, 0, 1.16162, -23.7633, -92.0415)"
							fontStyle="normal"
							fontWeight="bold"
							xmlSpace="preserve"
							textAnchor="start"
							fontFamily="Gabriola"
							fontSize="25"
							y="596.14565"
							x="530.58777">
							Mithlond
						</text>
						<path
							d="M567.2,614.8l0.6,0c0.3,2.7,1.1,5.3,2.4,7.6c1.3,2.4,3.2,4.8,5.6,7.4c2.5,2.5,4.8,4.5,7.2,5.9s4.8,2.3,7.6,2.6l0,0.6
									c-3.1,0-6-0.7-8.9-1.9c-2.8-1.2-5.4-3-7.8-5.5c-2.4-2.4-4.1-5.1-5.2-8C567.6,620.8,567.1,617.9,567.2,614.8z"
						/>
						<path
							d="M587.3,630.9c-1.9-0.2-3.8-1.3-5.7-3.3c-1.3-1.4-2.3-2.9-2.8-4.4c-0.5-1.6-0.7-3.1-0.3-4.6c0.3-1.5,1.1-2.8,2.2-3.9
									c1.4-1.3,2.7-2.3,4.1-2.8c0.3,0.3,0.7,0.8,1,1.4c0.3,0.6,0.5,1.1,0.6,1.5c-1.1,0.2-2.2,0.5-3.3,0.9c-1.1,0.4-1.8,0.8-2.3,1.3
									c-0.4,0.6-0.5,1.4-0.4,2.3c0.1,0.9,0.4,1.9,0.8,2.9s1.1,1.9,1.9,2.7c1.8,1.8,3.4,2.9,4.8,3.1c1.4,0.2,2.8-0.3,4.2-1.7
									c0.6-0.6,1.1-1.2,1.4-1.9c-2.6-2.7-4.3-4.2-5.1-4.6c0.3-0.5,0.6-0.9,1-1.2c0.4-0.4,0.8-0.7,1.4-1.1c1.5,1.8,3,3.6,4.8,5.3
									c0,1-0.3,2-0.8,3.1c-0.5,1.1-1.2,2.1-2,2.9C591,630.4,589.2,631.1,587.3,630.9z"
						/>
						<path
							d="M599.9,621.3c-1.5-1.7-3.1-3.5-4.9-5.3c-0.9-0.9-1.6-1.5-2.1-2c-0.5-0.4-1-0.7-1.3-0.9c-0.4-0.2-0.8-0.3-1.4-0.3
									c0-0.5,0-1,0.1-1.4c0.1-0.4,0.3-0.7,0.5-1c0.9-0.8,1.9-0.7,3.1,0.5c-0.1-0.9-0.1-1.7,0-2.5c0.1-0.8,0.4-1.5,0.8-2.2
									c0.4,0.2,0.8,0.4,1.4,0.8s1,0.8,1.4,1.2c-0.2,0.1-0.5,0.4-0.8,0.7c-0.5,0.5-0.9,1-1.2,1.5c-0.3,0.5-0.5,1-0.6,1.5l0.7,0.8
									c1,1.1,1.9,2.1,2.7,2.9c1.7,1.7,3,2.8,4,3.2c-0.4,0.5-0.7,1-1.1,1.3C600.9,620.5,600.4,620.9,599.9,621.3z"
						/>
						<path
							d="M605.7,613.7c-1.5-0.2-3-1.1-4.5-2.7c-1-1.1-1.8-2.2-2.2-3.4c-0.4-1.2-0.5-2.4-0.3-3.5c0.2-1.1,0.8-2.2,1.7-3
									c1.2-1.2,2.5-1.6,4-1.4c1.5,0.2,3,1.2,4.6,2.8c-0.6,1-1.4,2.1-2.3,3.3s-2,2.4-3,3.5c1.1,0.9,2,1.4,2.9,1.4c0.8,0,1.7-0.3,2.5-1.1
									c0.6-0.6,1.1-1.2,1.5-1.9c0.4-0.7,0.6-1.4,0.8-2l0.6,0.3c0.2,1.1,0.1,2.2-0.3,3.4c-0.4,1.1-1,2.1-1.8,2.9
									C608.5,613.4,607.1,613.9,605.7,613.7z M604.5,606c0.5-0.9,0.9-1.6,1.1-2.3c-0.9-0.8-1.8-1.3-2.8-1.4c-1-0.1-1.8,0.2-2.5,0.8
									c-0.2,0.7-0.1,1.6,0.4,2.6c0.4,1,1.1,1.9,2,2.9C603.4,607.7,604,606.9,604.5,606z"
						/>
						<path
							d="M618.9,609.2c-1.4-1.4-2.1-3.3-2.1-5.7c-1.3,0.3-2.6-0.3-4.1-1.8l-1.1-1.2l-1.4-1.5c-1.6-1.6-2.9-2.7-3.8-3
									c0.2-0.6,0.5-1.1,0.8-1.5c0.3-0.4,0.7-0.9,1.2-1.4c0.8,1,2.9,3.2,6.1,6.5c0.6,0.6,1.2,1,1.7,1c0.5,0,1-0.2,1.6-0.7
									c0.9-0.8,1.4-1.8,1.6-2.8l-2.8-2.8c-0.3-0.2-0.6-0.5-0.9-0.8c-0.7-0.8-1.5-1.4-2.1-1.9c-0.7-0.5-1.2-0.9-1.6-1c0.4-1,1.1-2,2-2.9
									c1.2,1.4,3.1,3.5,5.8,6.2c1.1,1.1,2,2,2.8,2.7c0.8,0.7,1.2,1.1,1.3,1.2c1.4,1.4,2.1,2.9,1.9,4.5c-0.1,1.6-0.9,3-2.3,4.4
									C622.1,608.1,620.5,609,618.9,609.2z M623.5,602.5c-0.1-1-0.8-2.1-2.1-3.5l-1.1-1.1c0,0.9-0.1,1.8-0.3,2.5c-0.3,0.8-0.7,1.5-1.3,2
									c-0.1,0.1-0.4,0.3-0.7,0.6c0.2,1.4,0.6,2.5,1.2,3.4c0.6,0,1.1,0,1.7-0.3c0.6-0.2,1.1-0.5,1.6-1
									C623.3,604.3,623.6,603.5,623.5,602.5z"
						/>
						<path
							d="M580.9,671.4c-0.7-1-1.8-2.2-3.3-3.9c-1.4-1.6-2.8-3.1-4.1-4.5c-3.1-3.3-5.1-5.2-6.1-5.7c0.4-0.6,0.8-1,1.2-1.4
									c0.4-0.4,1-0.8,1.6-1.2c1.3,1.7,3.2,3.8,5.6,6.4c1-0.8,2.2-1.8,3.6-3.1c0.2-0.2,0.9-0.9,2-2c-3.1-3.3-5.2-5.3-6.3-5.9
									c0.3-0.5,0.7-1,1.2-1.4c0.4-0.4,0.9-0.8,1.6-1.2c0.7,1,1.8,2.2,3.3,3.9c1.4,1.6,2.8,3.1,4.1,4.5c3.1,3.2,5.1,5.1,6.1,5.7
									c-0.3,0.5-0.7,1-1.2,1.4c-0.4,0.4-0.9,0.8-1.6,1.2c-1.3-1.7-3.2-3.8-5.6-6.5c-1.1,0.9-2.2,1.9-3.3,2.9c-0.7,0.7-1.5,1.4-2.3,2.2
									c3.2,3.4,5.3,5.3,6.3,5.9c-0.3,0.5-0.8,1-1.2,1.5C582.1,670.6,581.5,671,580.9,671.4z"
						/>
						<path
							d="M593.6,658.7c-0.9-0.1-1.6-0.5-2.3-1.3c-2-2.2-1.2-5.2,2.4-9.2c-1.1-1.2-2-1.9-2.8-2c-0.8-0.2-1.6,0.1-2.4,0.9
									c-0.5,0.5-0.9,1.1-1.3,1.7s-0.6,1.4-0.7,2.1l-0.7-0.3c-0.3-1.2-0.3-2.4,0-3.6c0.3-1.2,0.8-2.2,1.6-2.9c1.1-1,2.2-1.5,3.4-1.4
									c1.2,0.1,2.3,0.7,3.4,1.9c0.6,0.6,1.2,1.4,1.9,2.3c0.7,0.9,1.3,1.6,1.7,2c0.6,0.7,1.2,1.2,1.7,1.4c0.5,0.3,1.1,0.4,1.9,0.5
									c-0.1,1.2-0.4,2.1-1.1,2.9c-0.8,0.1-1.5-0.1-2.3-0.6c-0.2,1-0.5,2-0.9,2.8c-0.4,0.9-0.9,1.6-1.4,2.1
									C595.1,658.6,594.4,658.8,593.6,658.7z M596.5,654.2c0.3-0.7,0.4-1.3,0.5-1.9l-0.1-0.1c-0.5-0.6-1-1.2-1.5-1.7l-1-1.2
									c-1.1,1.1-1.7,2.2-1.9,3.2c-0.2,1,0,1.9,0.7,2.6c0.3,0.3,0.6,0.5,1.1,0.7c0.4,0.2,0.8,0.2,1.2,0.1
									C595.9,655.4,596.2,654.9,596.5,654.2z"
						/>
						<path
							d="M606.4,646.8c-0.8-0.1-1.5-0.4-2.4-0.8c-0.8-0.4-2-1.1-3.4-1.9c-1.7-1.1-3.2-1.9-4.3-2.5c-1.2-0.6-2.3-1-3.5-1.2
									c0.3-0.6,0.6-1.2,0.9-1.7c0.4-0.5,0.8-1,1.4-1.5c1.1,0.8,3.1,2.1,5.9,3.8c2.9,1.7,4.9,2.7,6.1,3.1c-1.8-3.5-3.1-6-3.9-7.5
									c-0.8-1.5-1.5-2.5-2-3.1c-0.3-0.3-0.5-0.5-0.8-0.6c0.5-0.8,1.1-1.6,1.9-2.4c1.4,3.4,3.6,7.8,6.6,13.4l-0.7,2.4
									C607.7,646.7,607.1,646.9,606.4,646.8z"
						/>
						<path
							d="M613.3,639c-1.6-0.3-3.2-1.3-4.8-3.1c-1.1-1.2-1.9-2.5-2.3-3.8c-0.4-1.3-0.5-2.6-0.2-3.9c0.3-1.2,0.9-2.3,1.9-3.2
									c1.3-1.2,2.8-1.7,4.4-1.4c1.6,0.3,3.2,1.4,4.9,3.2c-0.7,1.1-1.5,2.2-2.6,3.6c-1.1,1.3-2.2,2.6-3.4,3.7c1.1,1,2.1,1.6,3.1,1.6
									c0.9,0.1,1.8-0.3,2.7-1.1c0.7-0.6,1.2-1.3,1.7-2c0.4-0.7,0.7-1.5,0.9-2.2l0.7,0.4c0.2,1.2,0,2.4-0.4,3.7c-0.4,1.2-1.1,2.3-2,3.1
									C616.4,638.8,614.9,639.3,613.3,639z M612.3,630.6c0.6-0.9,1-1.8,1.3-2.4c-0.9-0.9-1.9-1.5-2.9-1.7c-1.1-0.2-2,0.1-2.7,0.8
									c-0.2,0.8-0.1,1.7,0.3,2.8c0.4,1.1,1.1,2.1,2.1,3.2C611,632.5,611.7,631.6,612.3,630.6z"
						/>
						<path
							d="M625.4,629.9c-1.6-1.9-3.3-3.9-5.2-6c-0.9-1-1.6-1.7-2.2-2.2c-0.5-0.5-1-0.8-1.4-1c-0.4-0.2-0.9-0.3-1.5-0.4
									c0-0.6,0-1.1,0.2-1.5c0.2-0.4,0.4-0.8,0.6-1c0.9-0.8,1.9-0.7,3,0.2c-0.1-0.9,0.1-1.8,0.4-2.7c0.3-0.9,0.8-1.6,1.4-2.1
									c2-1.8,4.1-1.4,6.4,1.1l1.5,1.8c0.6,0.7,1.1,1.3,1.6,1.9c1.7,1.9,3.1,3.1,4.3,3.6c-0.4,0.5-0.8,1-1.2,1.4c-0.4,0.4-1,0.8-1.7,1.2
									c-1.9-2.3-4-4.7-6.4-7.3c-0.7-0.7-1.3-1.1-1.8-1.2c-0.6-0.1-1.1,0.2-1.8,0.7c-0.9,0.8-1.5,1.8-1.7,2.7l1.1,1.2
									c0.8,1,1.8,2.1,3,3.4c1.8,1.9,3.2,3.1,4.3,3.6c-0.4,0.6-0.8,1.1-1.3,1.5C626.6,629.1,626.1,629.5,625.4,629.9z"
						/>
						<path
							d="M635.8,619.1c-0.3-0.3-0.6-0.7-0.8-1.1c-0.2-0.4-0.4-0.8-0.6-1.3c0.7,0.2,1.5,0.1,2.2-0.1s1.4-0.6,2-1.2
									c1.6-1.5,1.9-2.7,1.1-3.6c-0.4-0.4-0.9-0.6-1.5-0.5c-0.6,0.1-1.4,0.2-2.5,0.6c-1.1,0.3-2,0.5-2.7,0.7c-0.7,0.1-1.5,0.1-2.2-0.1
									c-0.8-0.2-1.5-0.6-2.1-1.3c-0.8-0.9-1.1-1.9-0.9-3c0.2-1.2,0.9-2.3,2-3.3c1.5-1.4,3-2.3,4.4-2.8c0.3,0.3,0.5,0.7,0.8,1.4
									c0.3,0.6,0.5,1.2,0.6,1.8c-1.2,0-2.2,0-3.1,0.2c-0.9,0.2-1.5,0.4-1.9,0.8c-0.3,0.5-0.5,0.9-0.5,1.4c0,0.5,0.1,0.9,0.4,1.1
									c0.5,0.5,1,0.7,1.7,0.7c0.7,0,1.6-0.2,2.8-0.6c1-0.3,1.8-0.5,2.5-0.6c0.7-0.1,1.4-0.1,2.2,0.1s1.4,0.6,2,1.3
									c0.9,1,1.3,2.2,1.1,3.4c-0.2,1.2-1,2.5-2.4,3.8C638.9,618.1,637.4,618.9,635.8,619.1z"
						/>
						<path
							d="M636.9,594.1c2.4,0.8,4.4,2.2,6.1,4c1.7,1.8,2.8,4,3.4,6.5c0.6,2.5,0.7,5,0.1,7.6l-0.6-0.1c0-2.1-0.4-4.2-1.3-6.2
									c-0.9-2-2.1-4-3.8-5.8c-1.6-1.8-3.4-3.2-5.4-4.3c-2-1.1-4-1.7-6.1-1.9l0-0.6C632,593,634.5,593.3,636.9,594.1z"
						/>
					</g>
					<g className="level_two fade location " id="lune">
						<rect x="2436.004" y="1542.368" width="158.172" height="33.934" transform="matrix(0.143225, 0.98969, -0.991415, 0.131306, 1810.325806, -2245.891113)"></rect>
						<path
							d="M631,522.5c-0.9-0.2-2.2-0.4-3.9-0.7c-3.6-0.6-5.9-0.7-6.7-0.5c0-0.6,0-1.1,0.1-1.7c0.1-0.5,0.2-1.1,0.4-1.7
									c1.1,0.3,2.4,0.6,4.1,0.9c1.7,0.3,3.3,0.6,4.7,0.8c2.1,0.3,3.5,0.5,4.3,0.6c0.3-0.9,0.6-1.9,0.7-3c0.1-0.7,0.1-1.4,0.1-2.2
									c0-0.7-0.1-1.3-0.3-1.9c0.6,0.1,1.2,0.3,1.8,0.5c0.6,0.2,1,0.4,1.2,0.6c-0.3,1.3-0.7,2.8-0.9,4.5c-0.3,2.1-0.5,3.7-0.5,4.7
									C633.6,523.1,631.9,522.7,631,522.5z"
						/>
						<path
							d="M638.3,505.9c-2.6-0.3-5.1-0.4-7.6-0.6c-3.5-0.2-6.2-0.2-8.2-0.1c-0.4-1-0.5-2.1-0.4-3.3c0.9,0.2,2.9,0.4,5.8,0.7
									c-0.6-0.6-1-1.2-1.3-2c-0.3-0.7-0.5-1.5-0.4-2.2c0.1-2.3,1.5-3.4,4.3-3.3l1.9,0.1l2,0.2c2.1,0.1,3.6,0,4.5-0.4
									c0.1,0.6,0.1,1.1,0.1,1.6c0,0.5-0.1,1.1-0.3,1.8c-2.8-0.3-5.4-0.5-7.8-0.6c-0.8,0-1.4,0.1-1.7,0.4c-0.4,0.3-0.6,0.8-0.6,1.6
									c0,1.1,0.2,2,0.6,2.9c0.6,0.1,1.4,0.1,2.5,0.2c3.7,0.2,6,0.1,6.9-0.3c0.1,0.5,0.1,1.1,0.1,1.7
									C638.6,504.7,638.5,505.3,638.3,505.9z"
						/>
						<path
							d="M624.1,485c-1.4-0.9-2.6-1.7-3.8-2.4c-0.3-0.7-0.6-1.6-0.6-2.7c0.9-0.6,1.6-1.1,2-1.5c0.5-0.4,1-0.9,1.6-1.5
									c0.3,0.8,0.6,1.7,0.6,2.7l-2.4,1.9l2.7,1.5C624.2,483.8,624.2,484.4,624.1,485z M634.8,485.4l-1.9,0.1l-2,0.1
									c-2,0.2-3.3,0.5-4.1,0.9c-0.5-0.9-0.8-2-0.9-3.2c2.4-0.1,5.2-0.2,8.4-0.5c0.8-0.1,1.4-0.3,1.7-0.7c0.3-0.4,0.5-0.9,0.4-1.7
									c-0.1-1-0.4-1.8-0.9-2.6c-1.3,0.1-2.5,0.2-3.6,0.2l-1.6,0.1c-2,0.2-3.3,0.5-4.1,0.9c-0.5-0.9-0.8-2-0.9-3.3c1.4,0,3-0.1,4.9-0.2
									c1.9-0.1,3.2-0.1,3.8-0.2c0.7-0.1,1.3-0.2,1.7-0.4c0.4-0.2,0.8-0.5,1.3-1c0.7,0.7,1.1,1.4,1.3,2.3c-0.4,0.6-0.9,1.1-1.7,1.3
									c0.6,0.5,1.1,1.1,1.5,1.8s0.6,1.4,0.7,2.2C638.9,483.9,637.6,485.2,634.8,485.4z"
						/>
						<path
							d="M636.2,464c-2,0.3-4.1,0.7-6.3,1.2c-1.1,0.2-1.9,0.5-2.4,0.7c-0.5,0.2-1,0.4-1.3,0.6c-0.3,0.2-0.6,0.5-0.9,0.9
									c-0.4-0.2-0.8-0.5-1-0.8c-0.2-0.3-0.4-0.6-0.5-0.9c-0.2-1,0.2-1.8,1.4-2.2c-0.7-0.4-1.3-0.9-1.8-1.5s-0.8-1.3-1-2
									c-0.5-2.3,0.6-3.7,3.3-4.3l1.9-0.4c0.7-0.1,1.4-0.2,2-0.4c2-0.5,3.4-1,4.3-1.6c0.2,0.5,0.4,1,0.5,1.5c0.1,0.5,0.2,1.1,0.2,1.8
									c-2.4,0.4-5,0.9-7.7,1.5c-0.8,0.2-1.3,0.5-1.6,0.8s-0.3,0.9-0.2,1.7c0.2,1.1,0.7,1.9,1.3,2.5l1.3-0.3c1-0.2,2.2-0.4,3.6-0.7
									c2-0.5,3.5-1,4.3-1.6c0.2,0.6,0.4,1.1,0.6,1.6C636.1,462.8,636.1,463.3,636.2,464z"
						/>
						<path
							d="M610.7,445.5l0.3-0.4c1.6,0.8,3.3,1.3,5.1,1.4c1.8,0.1,3.7-0.1,5.6-0.7s3.6-1.4,5-2.6c1.4-1.1,2.6-2.5,3.4-4l0.5,0.2
									c-0.7,2.2-1.7,4-3.1,5.6c-1.4,1.6-3.1,2.6-5,3.2s-3.9,0.7-6,0.2C614.3,447.8,612.4,446.9,610.7,445.5z"
						/>
						<path
							d="M623.3,436.8c-0.9,0.2-2.2,0.6-3.9,1.1c-3.5,1-5.7,1.8-6.4,2.3c-0.3-0.5-0.5-1-0.6-1.5c-0.1-0.5-0.2-1.1-0.3-1.7
									c1.1-0.2,2.4-0.5,4.1-0.9s3.2-0.8,4.6-1.2c2-0.6,3.4-1,4.2-1.3c-0.1-0.9-0.3-1.9-0.6-3.1c-0.2-0.7-0.5-1.4-0.8-2
									c-0.3-0.6-0.7-1.2-1.1-1.6c0.6-0.2,1.2-0.3,1.8-0.3c0.6,0,1.1,0,1.3,0.1c0.2,1.3,0.6,2.8,1.1,4.5c0.6,2,1.1,3.5,1.5,4.5
									C625.8,436.2,624.2,436.5,623.3,436.8z"
						/>
						<path
							d="M621,423.6l-1.9,0.3l-2,0.3c-1.9,0.4-3.3,0.8-4,1.4c-0.6-0.9-1-1.9-1.2-3.1c2.4-0.4,5.2-0.9,8.3-1.5
									c0.8-0.2,1.3-0.4,1.6-0.8s0.4-1,0.2-1.7c-0.2-1-0.6-1.8-1.2-2.5c-1.3,0.3-2.5,0.5-3.5,0.6l-1.6,0.3c-1.9,0.4-3.3,0.8-4,1.4
									c-0.6-0.9-1-1.9-1.2-3.1c1.4-0.2,3-0.4,4.9-0.7c1.9-0.3,3.1-0.5,3.7-0.6c0.7-0.1,1.2-0.3,1.6-0.6c0.4-0.2,0.8-0.6,1.1-1.2
									c0.7,0.6,1.2,1.3,1.5,2.1c-0.3,0.7-0.8,1.2-1.5,1.5c0.7,0.4,1.2,1,1.7,1.6c0.5,0.7,0.8,1.4,0.9,2.1
									C624.8,421.6,623.7,423,621,423.6z"
						/>
						<path
							d="M621.9,407.2c-2,0.1-4.1,0.2-6.4,0.5c-1.1,0.1-1.9,0.3-2.5,0.4c-0.6,0.1-1,0.3-1.3,0.5s-0.6,0.5-1,0.8
									c-0.4-0.3-0.7-0.6-0.9-0.9c-0.2-0.3-0.3-0.7-0.4-1c-0.1-1.1,0.4-1.7,1.6-2c-0.7-0.4-1.2-1-1.6-1.7c-0.4-0.7-0.7-1.4-0.7-2.1
									c-0.3-2.3,1-3.6,3.7-3.9l1.9-0.2c0.7,0,1.4-0.1,2-0.1c2-0.2,3.5-0.6,4.4-1.1c0.2,0.5,0.3,1.1,0.4,1.6c0.1,0.5,0.1,1.1,0,1.8
									c-2.4,0.1-5,0.4-7.8,0.7c-0.8,0.1-1.3,0.3-1.7,0.7c-0.3,0.4-0.4,0.9-0.3,1.6c0.1,1.1,0.5,2,1,2.6l1.3-0.1c1-0.1,2.2-0.2,3.6-0.3
									c2.1-0.2,3.6-0.6,4.4-1.1c0.2,0.6,0.3,1.1,0.4,1.7C621.9,405.9,621.9,406.5,621.9,407.2z"
						/>
						<path
							d="M619.1,391.4c-1,0.9-2.5,1.3-4.4,1.4c-1.3,0-2.5-0.2-3.5-0.7s-1.9-1.2-2.5-2.1c-0.6-0.9-0.9-2-1-3.2
									c0-1.6,0.4-2.8,1.5-3.7c1-0.9,2.6-1.3,4.6-1.4c0.3,1.1,0.6,2.3,0.8,3.8c0.2,1.5,0.4,3,0.5,4.4c1.2-0.2,2.1-0.5,2.6-1.1
									c0.5-0.6,0.8-1.4,0.8-2.4c0-0.8-0.1-1.6-0.4-2.3c-0.2-0.7-0.5-1.3-0.8-1.9l0.6-0.2c0.8,0.6,1.5,1.4,2,2.4c0.5,1,0.8,2.1,0.8,3.1
									C620.6,389.2,620.1,390.5,619.1,391.4z M613.5,387.2c-0.3-1-0.5-1.7-0.8-2.3c-1.1,0.1-1.9,0.4-2.6,1c-0.7,0.6-1,1.4-1,2.3
									c0.4,0.6,1,1.1,1.9,1.4c0.9,0.3,1.9,0.5,3,0.5C613.9,389.1,613.7,388.1,613.5,387.2z"
						/>
						<path
							d="M602.7,373.1c2.5-1.1,5.3-1.6,8.3-1.5c3,0.1,5.7,0.8,8.2,2c2.4,1.2,4.6,2.9,6.4,5.1l-0.4,0.4c-1.8-1.7-3.9-2.9-6.2-3.7
									c-2.3-0.8-5-1.2-8.1-1.3c-3.1-0.1-5.8,0.2-8.2,0.8c-2.3,0.6-4.5,1.7-6.4,3.3l-0.4-0.4C598,375.8,600.2,374.2,602.7,373.1z"
						/>
					</g>
					<g className="level_zero location " id="icebay_of_forochel">
						<rect x="1384.519" y="1686.874" width="89.9" height="37.113" transform="matrix(0.982477, 0.186384, -0.198215, 0.980233, -366.222961, -1725.219238)"></rect>
						<rect x="1480.124" y="1686.873" width="96.108" height="37.113" transform="matrix(0.990649, -0.136437, 0.124507, 0.992292, -942.6875, -1268.526611)"></rect>
						<rect x="988.179" y="1364.113" width="64.165" height="30.012" transform="matrix(0.785321, -0.619089, 0.609631, 0.792777, -792.934082, -265.996979)"></rect>
						<path
							d="M657,211c0.7-1,1.6-2.4,2.7-4.3c1.1-1.8,2.1-3.6,3.1-5.3c2.2-3.9,3.3-6.5,3.5-7.6c0.7,0.2,1.4,0.5,1.9,0.8
									c0.6,0.3,1.2,0.8,1.9,1.3c-0.7,1-1.6,2.4-2.7,4.2c-1.1,1.8-2.1,3.6-3.1,5.3c-2.2,3.9-3.4,6.5-3.5,7.6c-0.7-0.2-1.4-0.5-2-0.9
									C658.3,212,657.7,211.5,657,211z"
						/>
						<path
							d="M664.1,213.2c-0.5-1.6-0.3-3.4,0.7-5.4c0.6-1.4,1.5-2.5,2.6-3.4c1.1-0.8,2.3-1.3,3.7-1.4c1.4-0.1,2.7,0.1,4.1,0.7
									c1.6,0.7,2.8,1.6,3.6,2.6c-0.2,0.4-0.5,0.8-0.9,1.2c-0.5,0.5-0.9,0.8-1.4,1.1c-0.6-1.1-1.2-2.1-1.8-2.8c-0.6-0.8-1.2-1.3-1.7-1.5
									c-0.8,0.1-1.7,0.5-2.5,1.2c-0.9,0.7-1.5,1.6-2,2.7c-0.8,1.8-1.1,3.3-0.8,4.3c0.3,1.1,1.1,1.9,2.5,2.6c1.6,0.7,3.2,1,4.7,0.7l0,0.7
									c-1,0.6-2.2,0.9-3.5,0.9c-1.3,0-2.5-0.2-3.6-0.7C665.7,216,664.6,214.8,664.1,213.2z"
						/>
						<path
							d="M677.5,218.9c-0.7-1.5-0.7-3.3-0.1-5.5c0.4-1.5,1.1-2.7,2-3.7c0.9-1,2.1-1.6,3.3-2c1.3-0.3,2.6-0.3,4,0.1
									c1.9,0.5,3.2,1.5,3.8,3c0.6,1.5,0.6,3.3,0,5.6c-1.3-0.1-2.9-0.2-4.7-0.5c-1.8-0.3-3.6-0.7-5.3-1.1c-0.2,1.4-0.1,2.5,0.3,3.3
									c0.5,0.8,1.3,1.4,2.6,1.7c1,0.3,1.9,0.4,2.8,0.5c0.9,0,1.7-0.1,2.5-0.3l0.1,0.7c-1,0.7-2.2,1.1-3.5,1.3c-1.4,0.2-2.7,0.1-4-0.2
									C679.5,221.3,678.2,220.4,677.5,218.9z M684.4,214.2c1.2,0.1,2.2,0,2.9-0.1c0.3-1.2,0.2-2.3-0.2-3.2c-0.5-1-1.3-1.6-2.3-1.9
									c-0.8,0.2-1.6,0.7-2.3,1.6c-0.7,0.9-1.3,1.9-1.6,3.2C682,213.9,683.2,214.1,684.4,214.2z"
						/>
						<path
							d="M697,224.6c-0.8-0.4-1.4-0.9-1.8-1.5l-1.1,1.4c-0.2,0-0.6-0.1-1.2-0.3c0.2-1.1,0.4-2.8,0.7-5.2c0.3-2.4,0.5-4.5,0.7-6.2
									c0.3-3.2,0.3-5.5,0-6.8c1.2-0.4,2.6-0.5,4.1-0.3c-0.3,1.4-0.7,3.6-1,6.3c1.5-0.9,3.1-1.3,4.7-1.2c1.8,0.2,3.1,0.9,4.1,2.3
									c0.9,1.3,1.3,3.1,1.1,5.2c-0.2,2.5-1,4.3-2.4,5.5c-1.4,1.2-3.2,1.7-5.5,1.5C698.6,225.3,697.7,225,697,224.6z M702.6,222.6
									c0.8-0.7,1.2-1.9,1.4-3.4c0.3-3.1-0.7-5.1-2.9-6.1c-1.2-0.1-2.5,0.1-3.8,0.5c-0.3,2.3-0.4,3.8-0.5,4.5c-0.2,1.9-0.2,3.2-0.1,4.1
									c0.8,0.8,1.7,1.3,2.7,1.4C700.8,223.7,701.8,223.4,702.6,222.6z"
						/>
						<path
							d="M710.2,224.8c-0.6-0.6-1-1.4-1-2.3c-0.1-2.8,2.7-4.5,8.5-5.1c-0.1-1.5-0.3-2.6-0.8-3.2c-0.5-0.6-1.4-0.9-2.6-0.8
									c-0.8,0-1.5,0.2-2.3,0.4c-0.8,0.3-1.5,0.6-2.1,1l-0.3-0.7c0.6-1.1,1.5-1.9,2.6-2.6c1.1-0.7,2.2-1,3.4-1.1c1.6-0.1,2.8,0.3,3.7,1.1
									c0.9,0.8,1.4,1.9,1.4,3.4c0,0.8,0,1.8-0.1,2.8c-0.1,1.1-0.1,2-0.1,2.5c0,0.9,0.2,1.6,0.4,2.1s0.6,1,1.2,1.6
									c-0.9,0.8-1.9,1.3-2.9,1.4c-0.7-0.4-1.2-1-1.5-1.9c-0.9,0.6-1.8,1.1-2.7,1.5c-0.9,0.4-1.8,0.6-2.6,0.6
									C711.6,225.7,710.8,225.4,710.2,224.8z M715.8,223.4c0.7-0.3,1.3-0.7,1.8-1.1l0-0.2c0-0.7,0-1.5,0-2.2l0.1-1.5
									c-1.7,0.2-2.9,0.5-3.8,1.1c-0.9,0.6-1.3,1.4-1.3,2.3c0,0.4,0.1,0.8,0.4,1.2c0.2,0.4,0.5,0.7,0.9,0.9
									C714.5,223.9,715.1,223.7,715.8,223.4z"
						/>
						<path
							d="M723.5,229.7c-0.1-2,0.8-4,2.7-5.8c-1.3-0.6-2.1-2-2.2-4.2l0-1.7l0-2.1c-0.1-2.4-0.4-4-0.9-4.9c0.7-0.3,1.3-0.5,1.9-0.6
									s1.3-0.2,2.1-0.3c-0.1,1.4,0,4.4,0.2,9.2c0,0.9,0.3,1.6,0.7,2c0.4,0.4,1.1,0.6,2,0.5c1.4-0.1,2.6-0.4,3.6-1.1l-0.3-4.1
									c0-0.4-0.1-0.8-0.1-1.2c-0.1-1.1-0.2-2.1-0.3-2.9c-0.2-0.9-0.4-1.5-0.6-1.9c1.2-0.5,2.5-0.8,4-0.9c-0.1,1.9,0,4.8,0.2,8.8
									c0.1,1.6,0.2,2.9,0.3,4c0.1,1.1,0.2,1.7,0.2,1.8c0.1,2.1-0.5,3.7-1.9,4.8c-1.3,1.1-3.2,1.8-5.5,1.9
									C727.1,230.9,725.1,230.6,723.5,229.7z M732.8,227.6c0.7-0.8,1-2.2,0.9-4.1l-0.1-1.6c-0.7,0.7-1.4,1.3-2.3,1.7
									c-0.9,0.4-1.8,0.6-2.7,0.7c-0.2,0-0.6,0-1,0c-0.9,1.2-1.5,2.3-1.6,3.4c0.5,0.4,1,0.7,1.7,1c0.7,0.2,1.4,0.3,2.1,0.3
									C731.1,228.8,732.1,228.4,732.8,227.6z"
						/>
						<path
							d="M754.8,221.7c-1.3-1.4-2-3.2-2-5.4c0-1.6,0.3-3.1,1-4.4c0.7-1.3,1.7-2.3,2.9-3s2.7-1.1,4.4-1.1c1.4,0,2.7,0.3,3.9,0.9
									c1.1,0.6,2,1.5,2.6,2.6c0.6,1.1,1,2.4,1,3.9c0,1.6-0.3,3.1-1,4.4c-0.7,1.3-1.7,2.3-3,3c-1.3,0.7-2.8,1.1-4.5,1.1
									C757.8,223.7,756.1,223.1,754.8,221.7z M764,220.7c0.8-1,1.2-2.3,1.2-4c0-1.5-0.4-2.8-1.2-3.9c-0.8-1.1-1.8-1.8-3.1-2.2
									c-1.5,0-2.7,0.5-3.6,1.5c-0.9,1-1.3,2.3-1.3,4c0,1.9,0.5,3.4,1.3,4.5c0.9,1.1,1.9,1.6,3.2,1.6C762.1,222.1,763.2,221.6,764,220.7z
									"
						/>
						<path
							d="M768.4,228.8c0.5-0.6,0.9-1.3,1.2-1.8c0.3-0.6,0.5-1.3,0.6-2.3c0.1-1,0.2-2.3,0.2-4c0-1.9-0.1-5.3-0.4-10.1
									c-0.9,0-1.5-0.1-1.8-0.2c-0.3-0.1-0.4-0.4-0.5-0.7c1-0.5,1.8-1,2.2-1.3c0.3-2,1-3.7,2.2-4.9s2.7-1.9,4.4-1.9
									c1.2,0,2.5,0.1,3.7,0.4c0,0.4-0.1,0.9-0.3,1.6s-0.5,1.3-0.8,1.8c-0.8-0.7-1.6-1.2-2.4-1.7s-1.5-0.6-2-0.6
									c-0.4,0.3-0.8,0.8-0.9,1.6c-0.2,0.8-0.3,2.1-0.3,3.8c1.9-0.1,3.3-0.2,4-0.3c0,0.5,0,0.9-0.1,1.3c-0.1,0.4-0.3,0.8-0.5,1.3
									c-0.9-0.3-2-0.4-3.5-0.4l0,2.6c0,1.5,0.1,3.2,0.2,5.2c0.1,1,0.1,1.9,0.1,2.7c0,1.2-0.1,2.3-0.4,3.1c-0.3,0.8-0.7,1.6-1.4,2.4
									c-0.7,0.8-1.7,1.7-3.1,2.7L768.4,228.8z"
						/>
						<path
							d="M790.7,222.2c0-1.1,0-2.5-0.2-4.4c-0.1-1.9-0.3-3.6-0.5-5.3c-0.5-4.5-1-7.6-1.7-9.2c1.3,0,3.3-0.1,6.1-0.4
									c2.9-0.3,5.3-0.7,7-1c0.1,0.6,0.1,1.3,0,2.1c-0.1,0.8-0.2,1.3-0.4,1.6c-0.6-0.2-1.3-0.4-2.1-0.5c-0.8-0.1-1.7-0.1-2.5,0
									c-1.3,0.1-2.5,0.4-3.6,0.7c0.1,2.3,0.3,4,0.4,5.4l0.9-0.1c0.8,0,1.4-0.1,1.8-0.1c1.1-0.1,1.9-0.2,2.5-0.4c0.6-0.1,1.2-0.3,2-0.5
									c0.2,0.5,0.3,0.9,0.3,1.3c0,0.4,0,0.8,0,1.4L799,213c-1.6,0.1-3,0.1-4.3,0.3c-0.6,0.1-1,0.1-1.3,0.2c0.5,4.6,1,7.4,1.5,8.3
									c-0.7,0.2-1.4,0.4-2.1,0.4C792.3,222.3,791.6,222.3,790.7,222.2z"
						/>
						<path
							d="M803.2,219.4c-1.3-1-2.1-2.6-2.4-4.5c-0.2-1.4-0.1-2.8,0.4-4c0.5-1.2,1.2-2.2,2.2-3c1-0.8,2.3-1.3,3.7-1.5
									c1.3-0.2,2.5-0.1,3.5,0.3c1.1,0.4,1.9,1.1,2.6,2c0.7,0.9,1.1,2,1.3,3.3c0.2,1.4,0.1,2.8-0.4,4c-0.5,1.2-1.3,2.2-2.3,3
									c-1.1,0.8-2.3,1.3-3.8,1.5C806.2,220.8,804.6,220.4,803.2,219.4z M811.3,217.3c0.6-0.9,0.8-2.2,0.6-3.7c-0.2-1.3-0.7-2.5-1.5-3.3
									c-0.8-0.9-1.8-1.4-3-1.6c-1.3,0.2-2.3,0.8-3,1.8c-0.7,1-0.9,2.2-0.7,3.7c0.3,1.7,0.8,3,1.7,3.9c0.9,0.9,1.9,1.2,3.1,1.1
									C809.7,218.8,810.7,218.3,811.3,217.3z"
						/>
						<path
							d="M816.9,218.6c-0.3-2.2-0.7-4.5-1.2-6.9c-0.3-1.2-0.5-2.1-0.7-2.7c-0.2-0.6-0.4-1.1-0.7-1.4s-0.6-0.6-1.1-1
									c0.3-0.5,0.6-0.8,1-1.1c0.4-0.3,0.8-0.5,1.1-0.5c1.3-0.3,2.1,0.4,2.5,2c0.4-0.8,0.9-1.5,1.5-2.1c0.6-0.6,1.3-1.1,2.1-1.5
									c0.2,0.3,0.5,0.8,0.7,1.4c0.3,0.6,0.4,1.2,0.6,1.7c-0.3,0-0.7,0.1-1.2,0.2c-0.7,0.2-1.4,0.4-2,0.7c-0.6,0.3-1.1,0.6-1.5,0.9l0.2,1
									c0.3,1.4,0.5,2.7,0.7,3.8c0.5,2.3,1,3.8,1.7,4.7c-0.7,0.3-1.3,0.5-1.8,0.6S817.7,218.6,816.9,218.6z"
						/>
						<path
							d="M827.1,214.8c-1.5-0.8-2.5-2.2-3.1-4.1c-0.4-1.4-0.5-2.7-0.2-4c0.3-1.3,0.9-2.4,1.8-3.3s2.1-1.6,3.4-2.1
									c1.2-0.4,2.4-0.5,3.5-0.2c1.1,0.2,2.1,0.8,2.9,1.6c0.8,0.8,1.4,1.8,1.8,3.1c0.4,1.4,0.5,2.7,0.2,4c-0.3,1.3-0.9,2.4-1.8,3.3
									c-0.9,0.9-2.1,1.6-3.6,2.1C830.2,215.8,828.6,215.7,827.1,214.8z M834.8,211.6c0.5-1,0.5-2.3,0-3.7c-0.4-1.3-1.1-2.3-2-3.1
									c-0.9-0.7-2-1.1-3.2-1.1c-1.3,0.4-2.2,1.1-2.7,2.2c-0.5,1.1-0.5,2.3-0.1,3.7c0.5,1.6,1.3,2.8,2.3,3.6c1,0.7,2.1,0.9,3.2,0.6
									C833.5,213.3,834.3,212.6,834.8,211.6z"
						/>
						<path
							d="M840.3,210.6c-1.4-0.7-2.5-2-3.3-3.9c-0.6-1.3-0.8-2.6-0.7-3.9c0.1-1.3,0.6-2.4,1.3-3.5c0.8-1,1.8-1.8,3.1-2.4
									c1.5-0.6,2.9-0.9,4.1-0.9c0.1,0.3,0.2,0.8,0.3,1.4c0,0.6,0,1.2-0.1,1.7c-1.1-0.3-2.2-0.5-3.1-0.6c-0.9,0-1.6,0-2.2,0.3
									c-0.5,0.6-0.8,1.5-0.8,2.5c-0.1,1.1,0.1,2.1,0.6,3.2c0.7,1.7,1.6,2.9,2.5,3.4c0.9,0.5,2,0.5,3.4-0.1c1.6-0.7,2.7-1.6,3.5-2.8
									l0.5,0.4c-0.3,1.1-0.8,2.1-1.6,3c-0.8,0.9-1.7,1.6-2.8,2.1C843.3,211.3,841.7,211.3,840.3,210.6z"
						/>
						<path
							d="M850.8,207.6c-1.2-2.5-2.5-5-3.9-7.5c-1.9-3.3-3.4-5.9-4.7-7.7c0.8-0.9,1.8-1.7,3-2.4c0.4,1,1.3,2.9,2.7,5.8
									c0.2-0.9,0.6-1.7,1.2-2.4c0.5-0.7,1.2-1.3,1.9-1.7c2.4-1.3,4.3-0.6,5.8,2l1,1.9l1,2c1.1,2,2.1,3.3,3,4c-0.5,0.4-1,0.8-1.5,1.1
									c-0.5,0.3-1.1,0.5-1.9,0.8c-1.4-2.8-2.7-5.4-4-7.7c-0.4-0.8-0.9-1.2-1.4-1.4c-0.5-0.2-1.2,0-1.9,0.4c-1.1,0.6-1.9,1.4-2.4,2.3
									c0.3,0.6,0.7,1.4,1.3,2.4c2,3.6,3.4,5.7,4.3,6.2c-0.5,0.4-1,0.8-1.6,1.1C852.2,207.1,851.6,207.3,850.8,207.6z"
						/>
						<path
							d="M863.2,198.8c-1.5-0.4-2.8-1.4-4.1-3.1c-0.9-1.2-1.4-2.4-1.5-3.7s0-2.5,0.5-3.6c0.5-1.1,1.3-2.1,2.4-2.9
									c1.5-1.1,3-1.4,4.4-1.1c1.5,0.4,2.8,1.5,4.2,3.3c-0.8,1-1.9,2-3.2,3.2c-1.3,1.2-2.6,2.3-3.9,3.3c0.9,1,1.8,1.6,2.7,1.7
									c0.9,0.1,1.8-0.2,2.8-0.9c0.8-0.6,1.4-1.2,2-1.8c0.6-0.7,1-1.3,1.2-2l0.6,0.4c-0.1,1.1-0.4,2.3-1.1,3.4s-1.5,2.1-2.5,2.8
									C866.2,198.8,864.7,199.2,863.2,198.8z M863.8,191c0.7-0.9,1.3-1.6,1.6-2.2c-0.7-0.9-1.5-1.5-2.5-1.7c-1-0.2-1.9,0-2.8,0.6
									c-0.3,0.7-0.4,1.6-0.2,2.7c0.2,1,0.7,2.1,1.4,3.1C862.2,192.6,863,191.8,863.8,191z"
						/>
						<path
							d="M874.1,192c-0.5-0.1-1-0.6-1.7-1.4c-0.4-0.5-1.1-1.4-2.1-2.5c-1-1.3-1.9-2.4-2.6-3.2c-2.3-2.7-4.1-4.5-5.4-5.4
									c0.3-0.6,0.7-1.1,1.1-1.6c0.4-0.5,0.9-0.9,1.4-1.4c1.5,2.3,4,5.3,7.2,9.2c0.8,0.9,1.4,1.6,1.9,2c0.5,0.4,0.9,0.8,1.3,0.9
									c0.4,0.2,0.9,0.3,1.4,0.3c0,0.5-0.1,1-0.4,1.5s-0.4,0.8-0.7,1C875,192,874.5,192.1,874.1,192z"
						/>
					</g>
					<g className="level_one location" id="the_lost_realm_of_arnor">
						<rect x="2261.11" y="1872.45" width="146.818" height="41.196" transform="matrix(0.998992, -0.044895, 0.032865, 0.999532, -1610.317017, -1506.393799)"></rect>
						<rect x="3071.611" y="1887.51" width="199.445" height="41.527" transform="matrix(0.977095, 0.212804, -0.224571, 0.974532, -1721.282715, -2240.284912)"></rect>
						<rect x="3512.477" y="1674.22" width="228.071" height="36.834" transform="matrix(0.916005, 0.401168, -0.412199, 0.911174, -1474.841553, -2643.81543)"></rect>
						<rect x="1601.836" y="1829.162" width="104.01" height="40.243" transform="matrix(0.862299, 0.506399, -0.516783, 0.856201, 820.562988, -1996.54895)"></rect>
						<rect x="944.844" y="2955.409" width="61.35" height="65.022" transform="matrix(0.904479, -0.426518, 0.415626, 0.909615, -1447.657837, -1926.593506)"></rect>
						<rect x="2744.853" y="2955.41" width="178.227" height="65.022" transform="matrix(0.984064, 0.177813, 0.009948, 1.017991, -1888.818359, -3187.196289)"></rect>
						<rect x="687.729" y="2955.409" width="44.656" height="65.022" transform="matrix(0.984064, 0.177813, -0.532723, 0.919935, 1912.339722, -2500.826172)"></rect>
						<rect x="3442.58" y="2955.41" width="223.531" height="65.022" transform="matrix(0.828821, 0.559514, -0.555418, 0.831586, -75.209236, -3991.696289)"></rect>
						<text fontFamily="Arbutus Slab" fontSize="34.5px" x="750.007" y="294.258" transform="matrix(0.99761, -0.069092, 0.069092, 0.99761, -17.654676, 53.414978)">The</text>
						<text fontFamily="Arbutus Slab" fontSize="34.5px" x="750.007" y="294.258" transform="matrix(0.981561, 0.191148, -0.191148, 0.981561, 229.624268, -133.628311)">Lost</text>
						<text fontFamily="Arbutus Slab" fontSize="34.5px" x="750.007" y="294.258" transform="matrix(0.909634, 0.41541, -0.41541, 0.909634, 534.868042, -237.546234)">Realm</text>
						<text fontFamily="Arbutus Slab" fontSize="34.5px" x="750.007" y="294.258" transform="matrix(0.900256, 0.461329, -0.444167, 0.866766, 722.551941, -173.527466)">Of</text>
						<text fontFamily="Arbutus" fontSize="62px" x="652.585" y="390.447" transform="matrix(0.916243, -0.400623, 0.400623, 0.916243, -91.000397, 313.562622)">A</text>
						<text fontFamily="Arbutus" fontSize="62px" x="652.584" y="390.447" transform="matrix(0.99576, 0.091992, -0.091992, 0.99576, 244.601761, -79.908157)">R</text>
						<text fontFamily="Arbutus" fontSize="62px" x="652.584" y="390.447" transform="matrix(0.972594, 0.232509, -0.232509, 0.972594, 405.260864, -151.960098)">N</text>
						<text fontFamily="Arbutus" fontSize="62px" x="652.584" y="390.447" transform="matrix(0.853487, 0.521114, -0.521114, 0.853487, 781.251221, -215.60849)">O</text>
						<text fontFamily="Arbutus" fontSize="62px" x="652.584" y="390.447" transform="matrix(0.807499, 0.589869, -0.589869, 0.807499, 910.991028, -196.870926)">R</text>
					</g>
					<g className="level_two fade location " id="north_downs">
						<rect x="1276.793" y="1134.211" width="82.904" height="24.953" transform="matrix(0.805343, -0.592809, 0.583111, 0.812482, -723.965881, 284.736725)"></rect>
						<rect x="1656.12" y="1161.65" width="107.535" height="25.557" transform="matrix(0.948003, -0.318262, 0.306847, 0.951835, -893.962585, -180.105896)"></rect>
						<path
							d="M982,464.4c-1.4-2-3.5-4.6-6.4-8c-2.6-3.1-4.3-5-5.2-5.6c0.5-0.6,0.9-1,1.3-1.3c0.4-0.4,1-0.7,1.7-1.2
									c1.7,0.6,4.2,1.5,7.3,2.5c3.1,1.1,5.9,2,8.2,2.7c-1.7-2.1-3-3.7-4-4.9c-2.7-3.2-4.5-5.1-5.3-5.7c0.3-0.4,0.6-0.8,0.9-1.1
									c0.3-0.3,0.7-0.5,1.2-0.8c0.7,0.9,1.7,2.2,2.9,3.8c1.2,1.6,2.4,3,3.5,4.3c2.6,3.1,4.3,5,5.2,5.6c-0.2,0.3-0.6,0.8-1.3,1.3
									c-0.4,0.3-1,0.7-1.7,1.2c-1.7-0.6-4.1-1.5-7.3-2.5c-3.1-1.1-5.9-2-8.3-2.7c1.2,1.6,2.6,3.2,4,4.9c2.6,3.1,4.4,5,5.3,5.7
									c-0.2,0.4-0.6,0.7-0.9,1.1C982.9,463.9,982.5,464.1,982,464.4z"
						/>
						<path
							d="M996.5,450.4c-1.7-0.3-3.2-1.2-4.5-2.9c-0.9-1.2-1.5-2.4-1.7-3.8c-0.2-1.3,0-2.6,0.5-3.8c0.5-1.2,1.4-2.3,2.6-3.2
									c1.1-0.8,2.2-1.3,3.3-1.5c1.2-0.2,2.3,0,3.4,0.5c1.1,0.5,2,1.3,2.9,2.3c0.9,1.2,1.5,2.4,1.6,3.8c0.2,1.3,0,2.6-0.6,3.9
									c-0.6,1.3-1.5,2.3-2.7,3.3C999.8,450.2,998.2,450.7,996.5,450.4z M1002.7,444.5c0.1-1.2-0.4-2.4-1.3-3.6c-0.9-1.1-1.9-1.9-3-2.3
									c-1.2-0.4-2.3-0.4-3.5,0.1c-1.1,0.8-1.7,1.9-1.8,3.1c-0.1,1.2,0.3,2.4,1.2,3.6c1.1,1.4,2.2,2.3,3.5,2.6c1.2,0.3,2.3,0.2,3.3-0.6
									C1002.1,446.7,1002.6,445.7,1002.7,444.5z"
						/>
						<path
							d="M1011.3,441.1c-1.2-1.9-2.5-4-4-6c-0.7-1-1.3-1.8-1.8-2.3c-0.4-0.5-0.9-0.8-1.2-1c-0.4-0.2-0.8-0.4-1.4-0.5
									c0.1-0.6,0.2-1,0.5-1.5c0.2-0.4,0.5-0.7,0.8-1c1.1-0.8,2.2-0.5,3.2,0.8c0.1-0.9,0.3-1.8,0.6-2.6c0.3-0.8,0.8-1.5,1.4-2.2
									c0.3,0.2,0.8,0.5,1.3,1c0.5,0.5,0.9,0.9,1.2,1.4c-0.3,0.1-0.6,0.3-1,0.6c-0.6,0.4-1.2,0.9-1.6,1.5c-0.5,0.5-0.8,1-1,1.5l0.6,0.9
									c0.8,1.2,1.6,2.3,2.3,3.3c1.4,1.9,2.6,3.2,3.5,3.7c-0.5,0.5-1,1-1.5,1.3C1012.6,440.4,1012,440.8,1011.3,441.1z"
						/>
						<path
							d="M1021.4,433.7c-0.5-0.1-1-0.6-1.6-1.4c-0.4-0.6-1-1.5-1.9-2.9c-0.9-1.5-1.7-2.6-2.3-3.6c-0.3-0.5-0.6-0.9-0.9-1.3
									c-0.8,0.5-1.3,0.8-1.7,0.9c-0.3,0.1-0.6,0-0.8-0.3c0.8-1.6,1.4-3.3,1.7-5.3l0.8-0.4c0.5,0.6,1,1.2,1.5,1.9
									c1.3-0.9,2.2-1.6,2.7-2.2c0.3,0.4,0.5,0.8,0.6,1.1c0.1,0.4,0.2,0.8,0.2,1.2c-0.7,0.2-1.6,0.6-2.7,1.3c0.5,0.9,1.1,1.8,1.7,2.7
									c1.6,2.3,2.9,4,4,5c0.9-0.7,1.5-1.3,2-1.8l0.6,0.5c0,0.8-0.2,1.5-0.6,2.3c-0.4,0.7-0.9,1.3-1.6,1.8
									C1022.5,433.7,1021.9,433.8,1021.4,433.7z"
						/>
						<path
							d="M1029.1,428.8c-1.4-2.5-2.9-5-4.5-7.4c-2.1-3.3-3.9-5.8-5.3-7.6c0.7-1,1.7-1.9,2.9-2.7c0.5,1,1.5,2.9,3.2,5.8
									c0.2-0.9,0.5-1.8,1-2.6c0.5-0.8,1.1-1.4,1.9-1.9c2.3-1.5,4.4-0.9,6.1,1.7l1.1,1.8l1.2,2c1.3,2,2.4,3.3,3.4,3.9
									c-0.5,0.5-1,0.9-1.5,1.2c-0.5,0.3-1.1,0.6-1.9,1c-1.6-2.8-3.1-5.3-4.6-7.6c-0.5-0.8-1-1.2-1.6-1.3c-0.5-0.1-1.2,0.1-1.9,0.5
									c-1.1,0.7-1.8,1.5-2.3,2.6c0.3,0.6,0.8,1.4,1.5,2.4c2.3,3.6,3.9,5.6,4.8,6.1c-0.5,0.5-1,0.9-1.5,1.2
									C1030.5,428.2,1029.9,428.5,1029.1,428.8z"
						/>
						<path
							d="M1053.9,413.8c-1,0.4-1.9,0.7-2.7,0.9c-0.4-1-1-2.4-1.8-4.1c-0.8-1.7-1.6-3.3-2.4-4.8c-1.7-3.4-3-5.3-3.7-5.9
									c0.6-0.6,1.4-1.3,2.4-2s2.2-1.4,3.3-2c2.8-1.4,5.3-1.9,7.5-1.2c2.2,0.6,4,2.2,5.3,4.8c0.8,1.6,1.2,3.2,1,4.9
									c-0.1,1.6-0.7,3.2-1.8,4.6c-1.1,1.4-2.5,2.6-4.4,3.6C1055.9,412.9,1055,413.4,1053.9,413.8z M1059.4,407.8c0.4-1,0.6-2.1,0.6-3.2
									c0-1.1-0.3-2.2-0.8-3.1c-2.3-4.4-5.5-5.5-9.8-3.3c-0.7,0.4-1.3,0.8-1.9,1.3c1,2.3,2.1,4.7,3.4,7.1c1,2,1.9,3.6,2.7,4.8
									c1.8-0.4,3.1-0.8,3.9-1.3C1058.4,409.6,1059,408.8,1059.4,407.8z"
						/>
						<path
							d="M1069.5,404.3c-1.6-0.7-2.8-2-3.6-3.9c-0.6-1.4-0.8-2.7-0.6-4.1c0.2-1.3,0.6-2.5,1.5-3.6c0.8-1.1,1.9-1.9,3.3-2.4
									c1.2-0.5,2.4-0.7,3.6-0.6c1.2,0.1,2.2,0.6,3.1,1.3c0.9,0.7,1.6,1.7,2.2,3c0.6,1.4,0.8,2.7,0.6,4.1s-0.7,2.5-1.6,3.6
									c-0.9,1.1-2,1.9-3.4,2.5C1072.8,405,1071.1,405,1069.5,404.3z M1077.1,400.2c0.4-1.1,0.2-2.4-0.4-3.8c-0.5-1.3-1.3-2.3-2.4-3
									c-1-0.7-2.2-0.9-3.4-0.8c-1.3,0.5-2.1,1.4-2.5,2.5c-0.4,1.1-0.3,2.4,0.3,3.8c0.7,1.6,1.6,2.8,2.7,3.4c1.1,0.7,2.2,0.7,3.3,0.3
									C1075.9,402.1,1076.7,401.3,1077.1,400.2z"
						/>
						<path
							d="M1086.1,396.5l-2.5-3.5c-0.6-0.9-1.1-1.6-1.6-2.2c-0.6-0.8-1.2-1.5-1.8-2.1s-1.1-1-1.4-1.2c0.5-0.4,1-0.8,1.6-1.1
									c0.6-0.3,1.2-0.6,2-0.8c0.5,0.9,1.6,2.7,3.5,5.4c1.9,2.7,3,4.3,3.5,4.8c0.2-5.3,0.2-8.6,0.1-9.8c-0.7-0.7-1.2-1.2-1.7-1.4
									c1-0.8,2.2-1.5,3.7-2c0.4,0.9,1.6,2.7,3.6,5.6c2,2.8,3.1,4.4,3.4,4.7c0.1-3.8,0.2-6.5,0.2-8.1c0-1.5-0.1-2.6-0.3-3.2
									c-0.1-0.2-0.2-0.4-0.4-0.7c0.8-0.5,1.7-1,2.9-1.4c-0.2,1.6-0.4,3.6-0.5,6.1c-0.1,2.5-0.2,5.2-0.2,8.1l-1.5,1.6
									c-0.7,0.2-1.3,0.2-1.9-0.1c-0.6-0.3-1.2-0.8-1.8-1.6c-1.8-2.5-3-4.3-3.7-5.4c-0.1,2.1-0.2,4.9-0.2,8.5l-1.5,1.6
									C1088.4,398.7,1087.2,398.1,1086.1,396.5z"
						/>
						<path
							d="M1108.5,392.2c-0.4-2.2-0.8-4.6-1.4-7.1c-0.3-1.2-0.5-2.1-0.8-2.8c-0.2-0.6-0.5-1.1-0.7-1.4c-0.3-0.3-0.6-0.7-1.1-1
									c0.3-0.5,0.6-0.9,1-1.2c0.4-0.3,0.7-0.5,1.1-0.6c1.2-0.3,2.1,0.2,2.6,1.5c0.4-0.8,1-1.5,1.8-2.1c0.7-0.6,1.5-1,2.4-1.2
									c2.7-0.6,4.4,0.6,5.1,3.6l0.4,2.1c0.1,0.8,0.3,1.6,0.4,2.2c0.5,2.3,1.2,3.9,1.9,4.8c-0.6,0.3-1.2,0.5-1.8,0.6
									c-0.6,0.1-1.3,0.2-2.1,0.3c-0.5-2.7-1.1-5.6-1.8-8.7c-0.2-0.9-0.5-1.5-1-1.8c-0.5-0.3-1.1-0.3-2-0.1c-1.3,0.3-2.3,0.8-3,1.5
									l0.3,1.4c0.2,1.1,0.5,2.5,0.8,4c0.5,2.3,1.2,3.9,1.9,4.8c-0.7,0.3-1.3,0.5-1.9,0.7C1110,392.1,1109.3,392.2,1108.5,392.2z"
						/>
						<path
							d="M1124.1,387.8c-0.1-0.4-0.1-0.8-0.1-1.2c0-0.4,0.1-0.8,0.3-1.3c0.5,0.5,1.2,0.8,2,1c0.8,0.2,1.6,0.2,2.5,0.1
									c2.2-0.4,3.2-1.2,3-2.3c-0.1-0.5-0.4-0.9-1-1.1c-0.6-0.2-1.4-0.5-2.5-0.7c-1.1-0.3-2-0.5-2.7-0.8c-0.7-0.2-1.3-0.6-1.9-1.1
									c-0.6-0.5-0.9-1.2-1.1-2.1c-0.2-1,0.2-2,1-2.8s2.1-1.4,3.7-1.6c2.1-0.4,3.9-0.4,5.5-0.1c0.1,0.4,0,0.9-0.1,1.5
									c-0.1,0.6-0.3,1.2-0.5,1.7c-1-0.6-1.9-1-2.8-1.3s-1.6-0.4-2.1-0.3c-0.5,0.2-1,0.5-1.3,0.9s-0.4,0.7-0.4,1.1
									c0.1,0.6,0.5,1.1,1.1,1.4c0.6,0.3,1.5,0.6,2.8,0.9c1,0.2,1.9,0.5,2.6,0.7c0.7,0.2,1.3,0.6,1.8,1.1c0.5,0.5,0.9,1.1,1,1.9
									c0.2,1.3-0.1,2.3-1.1,3.2c-0.9,0.9-2.4,1.5-4.3,1.8C1127.4,388.5,1125.6,388.4,1124.1,387.8z"
						/>
					</g>
					<g className="level_zero location" id="hobbiton">
						<rect className="level_zero location" x="812.6" y="652" width="17.9" height="17.9" />
						<rect x="2162.176" y="1515.035" width="140.393" height="33.33" transform="matrix(0.569868, 0.821736, -0.8286, 0.559971, 848.735352, -1982.310425)"></rect>
						<path
							d="M818.3,681.2c1-0.5,2.3-1.3,3.9-2.3c1.6-1,3.2-2,4.6-3c3.3-2.3,5.3-3.8,5.8-4.6c0.5,0.5,0.9,1,1.2,1.4
									c0.3,0.5,0.7,1.1,1,1.8c-1.7,0.9-3.9,2.2-6.5,4c0.6,1.1,1.5,2.5,2.6,4.1c0.2,0.2,0.7,1,1.7,2.3c3.4-2.3,5.4-3.9,6-4.7
									c0.5,0.4,0.9,0.9,1.2,1.4c0.3,0.5,0.6,1.1,1,1.8c-1,0.5-2.3,1.3-3.9,2.3c-1.6,1-3.2,2-4.6,3c-3.3,2.2-5.2,3.8-5.8,4.6
									c-0.5-0.4-0.9-0.9-1.2-1.5c-0.3-0.5-0.6-1.1-0.9-1.7c1.7-0.9,3.8-2.2,6.5-4c-0.7-1.2-1.6-2.5-2.4-3.8c-0.6-0.9-1.2-1.7-1.9-2.6
									c-3.4,2.3-5.4,3.9-6.1,4.8c-0.5-0.4-0.9-0.9-1.2-1.5C818.9,682.5,818.6,681.9,818.3,681.2z"
						/>
						<path
							d="M830.2,696.1c0.3-1.7,1.3-3.1,2.9-4.3c1.2-0.9,2.4-1.4,3.7-1.5c1.3-0.2,2.5,0.1,3.7,0.6s2.2,1.4,3.1,2.6
									c0.8,1,1.2,2.1,1.3,3.3c0.1,1.1-0.1,2.2-0.6,3.3c-0.5,1-1.3,1.9-2.4,2.7c-1.2,0.9-2.4,1.3-3.7,1.5c-1.3,0.1-2.6-0.1-3.8-0.7
									s-2.2-1.5-3.1-2.7C830.3,699.4,829.9,697.8,830.2,696.1z M835.8,702.3c1.1,0.1,2.3-0.3,3.5-1.2c1.1-0.8,1.9-1.8,2.3-2.9
									c0.4-1.1,0.4-2.3,0-3.4c-0.8-1.1-1.8-1.7-2.9-1.8c-1.2-0.1-2.4,0.2-3.5,1.1c-1.4,1-2.3,2.1-2.6,3.3c-0.4,1.2-0.2,2.3,0.5,3.2
									C833.7,701.6,834.6,702.2,835.8,702.3z"
						/>
						<path
							d="M840.4,712c-0.2-0.8-0.3-1.5-0.2-2.2l-1.6,0.2c-0.1-0.2-0.3-0.5-0.5-1c0.9-0.6,2.2-1.5,4-2.9c1.8-1.3,3.4-2.5,4.7-3.5
									c2.4-1.9,4-3.3,4.7-4.4c1,0.6,2,1.5,2.9,2.6c-1.2,0.7-2.9,1.8-5,3.4c1.6,0.5,2.9,1.3,3.9,2.5c1,1.3,1.4,2.8,1.1,4.3
									c-0.3,1.5-1.3,2.9-2.8,4.1c-1.9,1.4-3.6,2.1-5.4,1.9c-1.7-0.2-3.2-1.1-4.6-2.8C841,713.6,840.6,712.8,840.4,712z M845.4,714.6
									c1,0.1,2.1-0.4,3.3-1.3c2.3-1.8,3.1-3.8,2.4-6c-0.7-0.9-1.7-1.7-2.8-2.3c-1.7,1.3-2.9,2.1-3.4,2.5c-1.4,1.1-2.4,1.9-2.9,2.6
									c-0.1,1.1,0.2,2,0.8,2.7C843.5,714,844.4,714.6,845.4,714.6z"
						/>
						<path
							d="M850.2,724.4c-0.2-0.8-0.3-1.5-0.2-2.2l-1.6,0.2c-0.1-0.2-0.3-0.5-0.6-1c0.9-0.6,2.2-1.6,4-3c1.8-1.4,3.3-2.6,4.6-3.7
									c2.4-2,3.9-3.5,4.6-4.5c1.1,0.6,2,1.4,3,2.5c-1.2,0.7-2.8,1.9-4.9,3.6c1.6,0.4,3,1.2,3.9,2.4c1.1,1.3,1.5,2.7,1.2,4.2
									c-0.3,1.5-1.2,2.9-2.7,4.2c-1.8,1.5-3.6,2.2-5.3,2.1s-3.3-1-4.7-2.7C850.9,725.9,850.5,725.2,850.2,724.4z M855.3,726.8
									c1,0,2.1-0.4,3.2-1.4c2.3-1.9,3-3.9,2.2-6c-0.8-0.9-1.7-1.6-2.9-2.2c-1.7,1.3-2.8,2.2-3.3,2.6c-1.4,1.1-2.3,2-2.8,2.7
									c0,1.1,0.2,2,0.8,2.7C853.4,726.3,854.3,726.8,855.3,726.8z"
						/>
						<path
							d="M858.6,734.1c0.1-0.5,0.6-1,1.3-1.7c0.6-0.5,1.5-1.3,2.7-2.2c1.1-0.9,1.9-1.6,2.4-2c0.7-0.6,1.3-1.2,1.8-1.8
									c0.5-0.6,0.8-1.1,0.9-1.5c0.6,0.3,1.1,0.7,1.6,1.1s0.9,0.8,1.4,1.4c-0.6,0.3-1.4,0.9-2.4,1.7c-1.1,0.8-2.2,1.8-3.5,2.8
									c-0.9,0.8-1.6,1.4-2,1.9c-0.4,0.5-0.7,0.9-0.9,1.3c-0.2,0.4-0.3,0.8-0.3,1.4c-0.5,0-1-0.1-1.5-0.3c-0.4-0.2-0.8-0.4-1-0.7
									C858.7,735.1,858.5,734.6,858.6,734.1z M871.1,721.7c1.1,0,2,0.1,2.9,0.3c-0.1,1-0.2,2-0.1,3c-1,0-2-0.1-3-0.3
									C871.1,723.7,871.2,722.7,871.1,721.7z"
						/>
						<path
							d="M863.9,740.2c0.1-0.5,0.5-1.1,1.2-1.7c0.5-0.5,1.3-1.2,2.5-2.2c1.3-1.1,2.3-2,3.1-2.7c0.4-0.4,0.8-0.7,1.1-1.1
									c-0.6-0.7-1-1.2-1.1-1.5c-0.1-0.3-0.1-0.6,0.2-0.8c1.6,0.6,3.4,0.9,5.3,0.9l0.5,0.7c-0.5,0.6-1,1.1-1.6,1.7
									c1.1,1.1,1.9,1.9,2.5,2.3c-0.3,0.3-0.7,0.5-1,0.7c-0.3,0.2-0.7,0.3-1.1,0.4c-0.3-0.7-0.8-1.5-1.6-2.4c-0.8,0.6-1.6,1.3-2.4,2
									c-2,1.8-3.4,3.3-4.2,4.5c0.8,0.7,1.4,1.3,2,1.7l-0.4,0.7c-0.7,0.1-1.5,0-2.3-0.3c-0.8-0.3-1.4-0.7-1.9-1.3
									C864.1,741.3,863.9,740.7,863.9,740.2z"
						/>
						<path
							d="M870.8,746c0.1-1.7,0.9-3.2,2.3-4.6c1.1-1,2.2-1.7,3.5-2c1.3-0.3,2.5-0.3,3.8,0.1c1.2,0.4,2.4,1.1,3.4,2.2
									c0.9,0.9,1.5,2,1.8,3.1c0.3,1.1,0.2,2.2-0.1,3.3c-0.4,1.1-1,2.1-2,3c-1.1,1-2.2,1.6-3.5,2c-1.3,0.3-2.5,0.2-3.8-0.2
									c-1.3-0.4-2.4-1.2-3.4-2.3C871.3,749.2,870.7,747.7,870.8,746z M877.1,751.4c1.1,0,2.2-0.6,3.4-1.7c1-0.9,1.6-2,1.9-3.2
									c0.3-1.2,0.1-2.3-0.4-3.4c-0.9-1-2-1.4-3.2-1.4c-1.2,0-2.3,0.5-3.4,1.6c-1.2,1.2-2,2.4-2.2,3.6c-0.2,1.2,0.1,2.3,0.9,3.1
									C875,751,876,751.5,877.1,751.4z"
						/>
						<path
							d="M881,758.7c1.7-1.5,3.4-3.1,5.1-4.8c0.8-0.9,1.5-1.5,1.9-2.1c0.4-0.5,0.7-1,0.8-1.3c0.1-0.4,0.2-0.9,0.2-1.4
									c0.6,0,1,0,1.5,0.2c0.4,0.2,0.8,0.4,1,0.6c0.9,0.9,0.9,1.8,0.1,2.9c0.9,0,1.8,0.1,2.6,0.4c0.8,0.3,1.6,0.8,2.2,1.4
									c1.9,1.9,1.8,3.9-0.3,6.1l-1.5,1.4c-0.6,0.5-1.2,1-1.6,1.5c-1.6,1.6-2.6,3-3,4c-0.6-0.4-1-0.7-1.4-1.1c-0.4-0.4-0.9-1-1.3-1.6
									c2-1.8,4.1-3.8,6.2-6c0.6-0.6,0.9-1.2,0.9-1.7c0-0.5-0.3-1.1-0.9-1.7c-0.9-0.9-1.8-1.4-2.8-1.7l-1,1c-0.8,0.8-1.8,1.7-2.9,2.8
									c-1.6,1.6-2.6,3-3,4c-0.6-0.4-1.1-0.8-1.5-1.2C881.9,759.9,881.4,759.4,881,758.7z"
						/>
					</g>
					<g className="level_one location" id="the_shire">
						<rect x="3121.216" y="1515.034" width="202.664" height="33.33" transform="matrix(0.490368, 0.871515, -0.877421, 0.479872, 644.58606, -2867.499023)"></rect>
						<path
							d="M874.1,686.2c0.5-0.2,0.9-0.4,1.3-0.4c0.4-0.1,0.9-0.1,1.4,0c-0.3,0.8-0.4,1.8-0.3,2.9c0.1,1.1,0.4,2.2,1,3.4
									c0.6,1.3,1.3,2.2,2.1,2.7c0.8,0.5,1.6,0.5,2.5,0.1c0.8-0.3,1.2-1,1.4-1.9c0.2-0.9,0.3-2.2,0.3-4c0.1-1.5,0.1-2.8,0.3-3.7
									c0.1-0.9,0.4-1.8,0.9-2.7c0.5-0.9,1.2-1.5,2.2-2c1.3-0.6,2.7-0.4,4.2,0.5c1.4,0.9,2.6,2.5,3.7,4.8c0.9,2,1.4,3.8,1.5,5.5
									c-0.5,0.2-1.1,0.4-1.9,0.5c-0.7,0.1-1.3,0.1-1.8,0c0.2-1.3,0.3-2.6,0.3-3.9c0-1.3-0.2-2.3-0.5-2.9c-0.3-0.5-0.8-0.8-1.5-1.1
									c-0.7-0.3-1.3-0.3-2,0c-0.8,0.4-1.4,1-1.6,2c-0.2,0.9-0.3,2.3-0.4,4.1c0,1.5-0.1,2.7-0.2,3.6c-0.1,0.9-0.4,1.8-0.9,2.6
									c-0.5,0.8-1.2,1.5-2.1,1.9c-1.6,0.7-3.2,0.7-4.6-0.1c-1.4-0.8-2.7-2.4-3.8-4.8C874.7,690.7,874.1,688.4,874.1,686.2z"
						/>
						<path
							d="M882.3,706c1.2-0.5,2.7-1.2,4.7-2.2c2-1,3.8-2,5.6-2.9c4.1-2.2,6.5-3.8,7.2-4.7c0.5,0.6,0.9,1.2,1.2,1.8
									c0.3,0.6,0.6,1.3,0.9,2.2c-2,0.9-4.7,2.2-7.9,3.8c0.6,1.3,1.4,3,2.5,5c0.2,0.3,0.7,1.2,1.7,2.8c4.1-2.3,6.6-3.9,7.4-4.8
									c0.5,0.6,0.9,1.2,1.2,1.8c0.3,0.6,0.6,1.3,0.9,2.1c-1.2,0.5-2.7,1.2-4.7,2.2s-3.8,2-5.6,2.9c-4,2.2-6.4,3.8-7.2,4.7
									c-0.5-0.6-0.9-1.2-1.3-1.8c-0.3-0.6-0.6-1.3-0.9-2.1c2-0.8,4.6-2.1,7.9-3.9c-0.7-1.5-1.5-3-2.4-4.6c-0.6-1.1-1.2-2.1-1.8-3.2
									c-4.2,2.3-6.6,3.9-7.5,4.8c-0.5-0.6-0.9-1.2-1.3-1.9C882.9,707.5,882.6,706.8,882.3,706z"
						/>
						<path
							d="M894.2,726.9c1.1-0.5,2.6-1.4,4.6-2.5c1.9-1.1,3.7-2.2,5.4-3.2c3.9-2.5,6.2-4.2,6.9-5.1c0.5,0.6,1,1.1,1.3,1.7
									c0.4,0.6,0.7,1.3,1.1,2.1c-1.1,0.5-2.6,1.4-4.5,2.5c-1.9,1.1-3.7,2.2-5.4,3.2c-3.9,2.5-6.2,4.2-7,5.1c-0.5-0.5-1-1.1-1.4-1.8
									C894.8,728.4,894.5,727.7,894.2,726.9z"
						/>
						<path
							d="M900.5,736.7c1.1-0.6,2.6-1.6,4.5-2.8c1.9-1.3,3.6-2.4,5.1-3.5c3.4-2.4,5.4-4.1,5.9-5c0.7,0.5,1.6,1.2,2.5,2.2
									c0.9,1,1.8,2,2.6,3.2c1.5,2.2,2.3,4.2,2.2,6c-0.1,1.8-1,3.4-2.8,4.6c-1.1,0.8-2.2,1.1-3.4,1.1c-1.2,0-2.5-0.4-3.7-1.1
									c-1.9,3.9-2.9,6.6-3,8.1c-0.7-0.4-1.3-0.9-1.8-1.4c-0.5-0.6-1.1-1.2-1.6-2c0.8-1.2,2-3.6,3.6-7.2c-0.1-0.1-0.2-0.3-0.4-0.6
									c-0.3-0.4-0.6-0.9-0.9-1.5c-0.3-0.5-0.5-1-0.7-1.3c-3,2.1-4.8,3.7-5.4,4.7c-0.5-0.5-1-1.1-1.5-1.7
									C901.2,738.2,900.8,737.5,900.5,736.7z M915.2,739.3c1,0.1,1.9-0.2,2.7-0.8c2.5-1.8,2.6-4.1,0.6-7c-0.4-0.6-0.9-1.2-1.5-1.6
									c-1.6,1-3.7,2.4-6.5,4.3c0.6,1.9,1.1,3.2,1.8,4.1C913.3,738.9,914.2,739.2,915.2,739.3z"
						/>
						<path
							d="M918.4,751.3c1.1-0.8,2.5-2,4.3-3.5c3.2-2.7,5.3-4.7,6.2-6c0.8,1.2,2.1,2.9,3.9,5.1c2.3,2.7,4.1,4.8,5.5,6.1
									c-0.5,0.4-1.1,0.8-1.8,1.2c-0.7,0.4-1.3,0.6-1.6,0.6c-0.2-0.7-0.5-1.5-1-2.4c-0.5-0.9-1.1-1.7-1.8-2.6c-0.9-1.1-1.8-2-2.7-2.8
									c-1.7,1.3-3.2,2.4-4.5,3.5l0.6,0.8c0.6,0.8,1.1,1.4,1.4,1.8c0.8,1,1.5,1.7,1.9,2.2s1,1,1.7,1.5c-0.3,0.4-0.6,0.7-1,1
									c-0.3,0.2-0.7,0.5-1.2,0.8c-1.7-2.4-3.1-4.2-4.2-5.4c-0.5-0.6-0.9-1-1.1-1.2c-2.1,1.8-3.6,3-4.4,3.8c0.6,1.1,1.4,2.3,2.4,3.5
									c0.7,0.9,1.6,1.7,2.5,2.5c0.9,0.8,1.7,1.4,2.4,1.7c-0.5,0.4-1.1,0.9-1.9,1.2c-0.7,0.4-1.3,0.6-1.6,0.6c-1-1.4-2.4-3.4-4.5-5.8
									c-1.8-2.2-3.2-3.8-4.3-4.7C915.7,753.3,917.4,752.1,918.4,751.3z"
						/>
						<path
							d="M826.9,598.9c1.2-0.4,3-1,5.1-1.9s4.3-1.7,6.3-2.6c2.2-1,4.1-1.8,5.6-2.6c-1.4-3.2-2.5-5.2-3.2-6c0.5-0.4,1-0.7,1.5-0.9
									c0.4-0.2,1-0.4,1.9-0.6c0.3,1.1,0.9,2.5,1.6,4.3c0.7,1.8,1.4,3.5,2.1,5.1c1.6,3.8,2.8,6,3.5,6.8c-0.5,0.4-1,0.7-1.5,0.9
									c-0.4,0.2-1,0.4-1.8,0.6c-0.5-1.6-1.3-3.7-2.4-6.4c-3.3,1.2-6.1,2.4-8.5,3.4c-4.6,2-7.3,3.4-8.3,4.3c-0.5-0.7-0.9-1.4-1.2-2.1
									C827.4,600.6,827.2,599.8,826.9,598.9z"
						/>
						<path
							d="M832.9,612.4c1.3-0.5,3-1.2,5.1-2.2c2.2-1,4.2-2,6.1-3c4.5-2.3,7.1-3.9,7.9-4.8c0.5,0.7,0.9,1.3,1.3,2
									c0.3,0.7,0.6,1.5,0.9,2.4c-2.2,0.9-5.1,2.2-8.6,3.9c0.6,1.4,1.4,3.3,2.5,5.5c0.2,0.3,0.7,1.4,1.7,3.1c4.5-2.3,7.3-3.9,8.2-4.9
									c0.5,0.6,0.9,1.3,1.3,2c0.3,0.6,0.6,1.4,0.9,2.3c-1.3,0.5-3,1.2-5.1,2.2c-2.2,1-4.2,2-6.1,3c-4.4,2.2-7,3.8-7.9,4.8
									c-0.5-0.6-0.9-1.3-1.3-2c-0.3-0.6-0.6-1.4-0.9-2.3c2.2-0.8,5.1-2.2,8.7-3.9c-0.7-1.6-1.5-3.3-2.4-5c-0.6-1.2-1.2-2.3-1.9-3.5
									c-4.6,2.3-7.3,4-8.2,4.9c-0.5-0.6-0.9-1.3-1.3-2C833.5,614,833.2,613.3,832.9,612.4z"
						/>
						<path
							d="M849.5,630.6c1.3-0.7,3-1.7,5.2-3c3.9-2.3,6.4-4.1,7.6-5.4c0.6,1.4,1.7,3.4,3.3,6.1c1.9,3.3,3.5,5.8,4.8,7.5
									c-0.6,0.4-1.4,0.7-2.2,1c-0.8,0.3-1.4,0.4-1.8,0.3c0-0.8-0.3-1.7-0.7-2.7c-0.4-1-0.9-2-1.5-3c-0.8-1.3-1.6-2.5-2.4-3.5
									c-2,1.1-3.8,2.1-5.4,2.9l0.5,0.9c0.5,1,0.9,1.7,1.2,2.2c0.7,1.2,1.3,2.1,1.7,2.7c0.4,0.6,0.9,1.2,1.5,1.9
									c-0.4,0.4-0.8,0.7-1.2,0.9c-0.3,0.2-0.8,0.4-1.4,0.6c-1.4-2.8-2.6-5-3.5-6.5c-0.4-0.7-0.7-1.2-0.9-1.5c-2.6,1.5-4.3,2.6-5.3,3.3
									c0.5,1.3,1.1,2.7,1.9,4.1c0.6,1.1,1.4,2.1,2.2,3.1c0.8,1,1.6,1.7,2.3,2.3c-0.6,0.4-1.4,0.7-2.2,1c-0.8,0.3-1.4,0.4-1.8,0.3
									c-0.8-1.7-2-4-3.8-7c-1.6-2.6-2.8-4.6-3.8-5.8C846.3,632.2,848.2,631.3,849.5,630.6z"
						/>
					</g>
					<g className="level_two fade location " id="white_downs">
						<rect x="1012.141" y="1736.801" width="65.72" height="38.208" transform="matrix(0.839037, -0.544075, 0.53397, 0.845589, -1022.416443, -283.524628)"></rect>
						<path
							d="M767.8,644.3c-0.6-0.2-1.3-0.5-2-1c-0.7-0.5-1.6-1.2-2.8-2.1c-1.3-1-2.4-1.9-3.3-2.5c-0.9-0.7-1.8-1.2-2.8-1.8
									c0.6-0.9,1.4-1.6,2.4-2.3c0.5,0.6,1.2,1.3,2.2,2.2c1,0.9,2,1.7,3,2.6c1.5,1.1,2.7,2,3.7,2.5c-1.2-4.4-2.2-7.4-2.8-9L764,632
									c0.6-0.8,1.4-1.6,2.4-2.3c0.5,0.5,1.2,1.2,2.2,2.1c1,0.9,2,1.8,2.9,2.6c1.6,1.2,2.8,2.1,3.7,2.5c-0.9-3.4-1.7-5.8-2.1-7.4
									c-0.5-1.5-0.9-2.5-1.2-3c-0.1-0.1-0.1-0.2-0.2-0.2c0.5-0.5,1.1-1.1,1.8-1.6c0.4,1.6,0.9,3.7,1.6,6.3c0.7,2.6,1.3,4.8,1.9,6.7
									l-0.4,1.4c-0.5,0.3-1,0.4-1.6,0.2c-0.6-0.2-1.3-0.5-2-1s-1.6-1.2-2.8-2.1c-0.9-0.8-1.8-1.4-2.6-2.1c0.5,1.9,1.3,4.7,2.4,8.5
									l-0.4,1.4C768.9,644.4,768.4,644.5,767.8,644.3z"
						/>
						<path
							d="M781,636c-1-1.9-2-3.8-3.1-5.6c-1.5-2.5-2.7-4.4-3.7-5.7c0.6-0.7,1.3-1.4,2.2-1.9c0.3,0.7,1,2.2,2.2,4.4
									c0.2-0.7,0.4-1.3,0.8-1.9c0.4-0.6,0.9-1,1.4-1.4c1.8-1,3.2-0.6,4.4,1.4l0.8,1.4l0.8,1.5c0.9,1.5,1.7,2.5,2.4,3
									c-0.4,0.3-0.7,0.6-1.1,0.8c-0.4,0.2-0.8,0.4-1.4,0.7c-1.1-2.1-2.2-4-3.2-5.7c-0.3-0.6-0.7-0.9-1.1-1s-0.9,0-1.4,0.3
									c-0.8,0.5-1.4,1.1-1.8,1.8c0.2,0.4,0.5,1,1,1.8c1.6,2.7,2.7,4.2,3.4,4.6c-0.4,0.3-0.7,0.6-1.2,0.9C782,635.6,781.5,635.8,781,636z
									"
						/>
						<path
							d="M784,618.6c0.2-0.8,0.4-1.5,0.7-2.1c0.7,0.3,1.4,0.5,2.2,0.6c-0.2,0.7-0.4,1.4-0.7,2.1C785.5,618.9,784.8,618.7,784,618.6
									z M791.1,629.9c-0.3-0.2-0.7-0.6-1-1.3c-0.3-0.5-0.7-1.3-1.2-2.3c-0.5-1-0.8-1.7-1.1-2.1c-0.3-0.6-0.7-1.1-1-1.6
									c-0.4-0.5-0.7-0.8-0.9-0.9c0.3-0.4,0.7-0.7,1-1c0.4-0.3,0.8-0.6,1.3-0.8c0.2,0.5,0.4,1.2,0.9,2.1c0.4,0.9,0.9,1.9,1.5,3
									c0.4,0.8,0.8,1.4,1.1,1.8c0.3,0.4,0.6,0.7,0.8,0.9c0.3,0.2,0.6,0.3,1,0.5c-0.1,0.4-0.3,0.7-0.5,1c-0.2,0.3-0.4,0.5-0.7,0.6
									C791.8,630,791.4,630,791.1,629.9z"
						/>
						<path
							d="M795.8,627.4c-0.4-0.1-0.7-0.6-1-1.2c-0.2-0.5-0.6-1.2-1.1-2.3c-0.5-1.1-1-2.1-1.4-2.8c-0.2-0.4-0.4-0.7-0.6-1
									c-0.6,0.3-1.1,0.5-1.3,0.5c-0.3,0-0.4-0.1-0.5-0.3c0.7-1.1,1.3-2.3,1.7-3.7l0.6-0.2c0.3,0.5,0.6,1,0.9,1.5c1-0.5,1.8-1,2.2-1.3
									c0.2,0.3,0.3,0.6,0.3,0.9c0.1,0.3,0.1,0.6,0.1,0.9c-0.5,0.1-1.2,0.3-2.1,0.7c0.3,0.7,0.6,1.4,1,2.1c0.9,1.8,1.8,3.2,2.5,4
									c0.7-0.4,1.2-0.8,1.6-1.1l0.4,0.4c-0.1,0.6-0.3,1.1-0.7,1.6c-0.4,0.5-0.8,0.9-1.3,1.1C796.6,627.5,796.1,627.6,795.8,627.4z"
						/>
						<path
							d="M800.4,624.2c-1-0.5-1.9-1.5-2.6-2.9c-0.5-1-0.7-2-0.6-3c0.1-1,0.4-1.8,0.9-2.6c0.5-0.8,1.3-1.4,2.2-1.8
									c1.3-0.6,2.4-0.6,3.4-0.1s1.9,1.5,2.6,3.1c-0.8,0.6-1.7,1.2-2.8,1.9c-1.1,0.7-2.3,1.3-3.4,1.9c0.5,0.9,1.1,1.4,1.7,1.6
									c0.6,0.2,1.4,0.1,2.2-0.3c0.7-0.3,1.2-0.7,1.7-1.1c0.5-0.4,0.9-0.8,1.2-1.3l0.4,0.4c-0.2,0.8-0.6,1.6-1.3,2.4
									c-0.6,0.7-1.4,1.3-2.3,1.7C802.6,624.6,801.4,624.7,800.4,624.2z M801.9,618.4c0.7-0.5,1.2-1,1.5-1.4c-0.4-0.8-0.9-1.3-1.6-1.6
									c-0.7-0.3-1.4-0.3-2.2,0.1c-0.4,0.5-0.5,1.1-0.5,2c0,0.8,0.2,1.6,0.6,2.5C800.5,619.4,801.2,618.9,801.9,618.4z"
						/>
						<path
							d="M779.6,659.7c-0.7,0.5-1.3,0.8-1.8,1.1c-0.4-0.7-1.1-1.6-1.9-2.7c-0.8-1.1-1.7-2.1-2.5-3.1c-1.8-2.2-3-3.4-3.6-3.7
									c0.3-0.5,0.8-1.1,1.4-1.8c0.6-0.7,1.3-1.3,2.1-2c1.8-1.5,3.5-2.2,5.2-2.1c1.7,0.1,3.2,1,4.6,2.6c0.8,1,1.3,2.1,1.5,3.4
									c0.1,1.2,0,2.4-0.6,3.6c-0.5,1.2-1.4,2.3-2.6,3.2C780.9,658.8,780.3,659.3,779.6,659.7z M782.6,654.6c0.2-0.8,0.1-1.6-0.1-2.4
									c-0.2-0.8-0.5-1.5-1-2.1c-2.3-2.8-4.8-3.1-7.6-0.9c-0.4,0.4-0.8,0.8-1.2,1.2c1.1,1.5,2.3,3,3.5,4.6c1,1.3,1.9,2.3,2.7,3
									c1.2-0.6,2.1-1.1,2.6-1.5C782.1,656,782.5,655.4,782.6,654.6z"
						/>
						<path
							d="M787.4,652c-1.3-0.3-2.3-1-3.2-2.2c-0.6-0.9-1-1.8-1.1-2.8c-0.1-1,0.1-1.9,0.5-2.8c0.4-0.9,1.1-1.7,2-2.3
									c0.8-0.6,1.6-0.9,2.5-1c0.9-0.1,1.7,0.1,2.5,0.4c0.8,0.4,1.5,1,2,1.8c0.6,0.9,1,1.8,1.1,2.8c0.1,1-0.1,1.9-0.5,2.8
									c-0.5,0.9-1.1,1.7-2.1,2.3C789.8,652,788.6,652.3,787.4,652z M792.1,647.9c0.1-0.8-0.2-1.7-0.9-2.7c-0.6-0.8-1.3-1.4-2.2-1.7
									c-0.8-0.3-1.7-0.3-2.6,0c-0.8,0.6-1.3,1.3-1.4,2.2c-0.1,0.9,0.2,1.8,0.8,2.7c0.8,1.1,1.6,1.7,2.5,2c0.9,0.3,1.7,0.2,2.4-0.3
									C791.6,649.4,792,648.7,792.1,647.9z"
						/>
						<path
							d="M795.9,645.1l-2.4-2.1c-0.6-0.5-1.1-1-1.5-1.3c-0.6-0.5-1.1-0.9-1.6-1.2s-0.9-0.5-1.2-0.6c0.3-0.4,0.6-0.7,1-1.1
									c0.4-0.3,0.8-0.6,1.3-0.9c0.5,0.6,1.6,1.7,3.4,3.3c1.8,1.6,2.9,2.6,3.3,2.8c-0.8-3.8-1.3-6.2-1.6-7c-0.6-0.4-1.1-0.6-1.4-0.7
									c0.6-0.7,1.3-1.4,2.3-2c0.5,0.6,1.6,1.7,3.5,3.4c1.9,1.7,3,2.6,3.2,2.8c-0.5-2.8-0.9-4.7-1.2-5.8c-0.3-1.1-0.5-1.8-0.8-2.2
									c-0.1-0.1-0.2-0.3-0.4-0.4c0.5-0.5,1.1-1,1.9-1.5c0.1,1.2,0.3,2.6,0.7,4.5s0.7,3.8,1.2,5.8l-0.8,1.4c-0.4,0.3-0.9,0.4-1.4,0.2
									c-0.5-0.1-1-0.4-1.5-0.9c-1.7-1.5-2.9-2.6-3.6-3.2c0.3,1.5,0.7,3.6,1.3,6.1l-0.8,1.4C797.9,646.3,796.9,646.1,795.9,645.1z"
						/>
						<path
							d="M809,639.5c-0.6-1.5-1.4-3.2-2.2-4.8c-0.4-0.8-0.8-1.4-1-1.8c-0.3-0.4-0.5-0.7-0.8-0.9s-0.6-0.4-1-0.5
									c0.1-0.4,0.3-0.7,0.5-1c0.2-0.3,0.4-0.5,0.7-0.6c0.8-0.4,1.5-0.2,2.1,0.6c0.2-0.7,0.5-1.2,0.9-1.8c0.4-0.5,0.9-0.9,1.5-1.2
									c1.8-0.9,3.3-0.4,4.3,1.7l0.7,1.4c0.2,0.6,0.5,1.1,0.7,1.5c0.8,1.5,1.5,2.6,2.2,3.1c-0.4,0.3-0.8,0.6-1.2,0.8
									c-0.4,0.2-0.9,0.4-1.5,0.6c-0.8-1.9-1.7-3.8-2.8-5.9c-0.3-0.6-0.6-1-1-1.1c-0.4-0.1-0.9-0.1-1.4,0.2c-0.9,0.4-1.5,1-1.9,1.6l0.5,1
									c0.3,0.8,0.8,1.7,1.3,2.7c0.8,1.6,1.5,2.6,2.2,3.1c-0.4,0.3-0.8,0.6-1.3,0.8C810.1,639.2,809.6,639.3,809,639.5z"
						/>
						<path
							d="M817.2,634.8c-0.1-0.3-0.2-0.6-0.3-0.9c0-0.3-0.1-0.6,0-1c0.5,0.2,1,0.4,1.6,0.4c0.6,0,1.2-0.1,1.8-0.4
									c1.5-0.7,2.1-1.4,1.8-2.2c-0.1-0.3-0.4-0.5-0.9-0.6s-1.1-0.1-1.9-0.1c-0.8,0-1.5,0-2.1-0.1c-0.5,0-1.1-0.2-1.6-0.5
									s-0.9-0.7-1.1-1.3c-0.3-0.7-0.2-1.4,0.2-2.2c0.5-0.7,1.2-1.3,2.3-1.8c1.4-0.6,2.7-1,3.9-1c0.1,0.2,0.2,0.6,0.2,1.1s0,0.9-0.1,1.3
									c-0.8-0.3-1.5-0.4-2.2-0.5s-1.2,0-1.6,0.1c-0.4,0.2-0.6,0.5-0.8,0.8c-0.2,0.3-0.2,0.6-0.1,0.8c0.2,0.4,0.5,0.7,1,0.8
									c0.5,0.1,1.2,0.2,2.1,0.2c0.8,0,1.4,0,2,0.1c0.5,0.1,1,0.2,1.5,0.5c0.5,0.3,0.8,0.7,1.1,1.2c0.4,0.9,0.3,1.7-0.2,2.5
									c-0.5,0.8-1.4,1.5-2.7,2C819.7,634.7,818.3,634.9,817.2,634.8z"
						/>
					</g>
					<g className="level_two fade location" id="annuminas">
						<rect x="1856.544" y="1191.354" width="120.547" height="26.21" transform="matrix(0.980254, -0.19774, 0.185936, 0.982636, -1279.613892, -272.305054)"></rect>
						<path
							d="M777.5,546.7L776,544c-0.7,0.1-1.6,0.2-2.7,0.3c-1.1,0.2-1.9,0.3-2.3,0.5c-0.2,0.9-0.4,1.9-0.6,3
									c-0.3,0.1-0.5,0.2-0.7,0.2c-0.3,0-0.6,0-1,0c0.3-0.8,0.8-2.6,1.5-5.5c0.7-2.9,1.2-5.1,1.5-6.7c0.4-0.1,0.7-0.2,1-0.3
									c0.3,0,0.6-0.1,0.9,0c0.8,1.4,1.9,3.4,3.4,6c1.5,2.5,2.5,4.1,3,4.8c-0.4,0.2-0.8,0.3-1.2,0.3C778.4,546.7,778,546.7,777.5,546.7z
									M775.3,542.8c-1-1.8-1.9-3.3-2.7-4.5c-0.4,1.3-0.8,3-1.2,5.1c0.8-0.1,1.7-0.2,2.6-0.3C774.6,542.9,775.1,542.8,775.3,542.8z"
						/>
						<path
							d="M783.6,545.7c-0.1-1.5-0.3-3-0.6-4.7c-0.1-0.8-0.3-1.4-0.4-1.8c-0.1-0.4-0.3-0.7-0.4-1s-0.4-0.5-0.7-0.7
									c0.2-0.3,0.4-0.6,0.7-0.7c0.3-0.2,0.5-0.3,0.7-0.3c0.8-0.1,1.3,0.2,1.6,1.1c0.3-0.5,0.7-0.9,1.2-1.2c0.5-0.3,1-0.5,1.6-0.6
									c1.8-0.3,2.8,0.6,3.2,2.6l0.2,1.4c0,0.5,0.1,1,0.2,1.5c0.2,1.5,0.6,2.6,1,3.2c-0.4,0.2-0.8,0.3-1.2,0.3c-0.4,0.1-0.9,0.1-1.4,0.1
									c-0.2-1.8-0.4-3.7-0.8-5.7c-0.1-0.6-0.3-1-0.6-1.2c-0.3-0.2-0.7-0.3-1.3-0.2c-0.8,0.1-1.5,0.4-2,0.8l0.1,0.9
									c0.1,0.7,0.2,1.6,0.4,2.6c0.2,1.5,0.6,2.6,1,3.2c-0.4,0.2-0.9,0.3-1.3,0.3C784.6,545.7,784.1,545.7,783.6,545.7z"
						/>
						<path
							d="M796.8,543.6c-0.1-1.5-0.3-3-0.6-4.6c-0.1-0.8-0.3-1.4-0.4-1.8c-0.1-0.4-0.3-0.7-0.4-1c-0.2-0.2-0.4-0.4-0.7-0.7
									c0.2-0.3,0.4-0.6,0.7-0.7c0.3-0.2,0.5-0.3,0.7-0.3c0.8-0.1,1.3,0.2,1.6,1.1c0.3-0.5,0.7-0.9,1.2-1.3c0.5-0.3,1-0.6,1.6-0.6
									c1.8-0.3,2.8,0.5,3.2,2.5l0.2,1.4c0,0.5,0.1,1,0.2,1.5c0.3,1.5,0.6,2.5,1,3.2c-0.4,0.2-0.8,0.3-1.2,0.3c-0.4,0.1-0.9,0.1-1.4,0.1
									c-0.2-1.8-0.5-3.7-0.8-5.7c-0.1-0.6-0.3-1-0.6-1.2c-0.3-0.2-0.7-0.3-1.3-0.2c-0.8,0.1-1.5,0.4-2,0.9l0.1,0.9
									c0.1,0.7,0.2,1.6,0.4,2.6c0.3,1.5,0.6,2.6,1,3.2c-0.4,0.2-0.9,0.3-1.3,0.4C797.8,543.6,797.3,543.6,796.8,543.6z"
						/>
						<path
							d="M809.1,538.6l-0.2-1.4l-0.2-1.5c-0.3-1.4-0.6-2.4-1-3c0.7-0.4,1.5-0.7,2.4-0.9c0.2,1.8,0.5,3.8,1,6.1
									c0.1,0.6,0.3,1,0.6,1.2s0.7,0.3,1.3,0.2c0.7-0.1,1.4-0.4,1.9-0.9c-0.2-1-0.3-1.8-0.4-2.6l-0.2-1.2c-0.3-1.4-0.6-2.4-1-3
									c0.7-0.4,1.5-0.7,2.4-0.9c0.1,1,0.3,2.2,0.5,3.6c0.2,1.4,0.3,2.3,0.4,2.8c0.1,0.5,0.2,0.9,0.4,1.2s0.5,0.6,0.9,0.8
									c-0.5,0.5-1,0.9-1.7,1.1c-0.5-0.2-0.9-0.6-1.1-1.1c-0.3,0.5-0.8,0.9-1.3,1.2c-0.5,0.3-1.1,0.5-1.7,0.7
									C810.5,541.5,809.4,540.6,809.1,538.6z M809.8,530.1c0.5-0.3,1-0.7,1.3-1.2c0.4-0.5,0.6-0.9,0.6-1.3c0.6-0.1,1-0.1,1.4,0
									c0.4,0.1,0.7,0.3,0.9,0.5c-0.8,1-2.1,1.9-4,2.5L809.8,530.1z"
						/>
						<path
							d="M822.5,539c-0.2-1.5-0.4-3-0.7-4.6c-0.1-0.8-0.3-1.4-0.4-1.8c-0.1-0.4-0.3-0.7-0.4-1c-0.2-0.2-0.4-0.4-0.7-0.7
									c0.2-0.3,0.4-0.6,0.7-0.7s0.5-0.3,0.7-0.3c0.8-0.1,1.3,0.2,1.6,1c0.3-0.5,0.7-0.9,1.2-1.2s1-0.6,1.5-0.7c1.3-0.2,2.2,0.1,2.7,1.1
									c0.3-0.6,0.7-1,1.2-1.4c0.5-0.4,1.1-0.6,1.6-0.7c1.8-0.3,2.8,0.5,3.2,2.5l0.2,1.4c0.1,0.5,0.1,1,0.2,1.5c0.3,1.5,0.6,2.5,1.1,3.2
									c-0.4,0.2-0.8,0.3-1.2,0.3c-0.4,0.1-0.8,0.1-1.4,0.1c-0.2-1.8-0.5-3.7-0.9-5.7c-0.1-0.6-0.3-1-0.6-1.2c-0.3-0.2-0.7-0.3-1.3-0.2
									c-0.8,0.1-1.4,0.4-1.9,0.9c0.1,0.5,0.2,1.3,0.4,2.4c0,0.3,0.1,0.7,0.2,1.2c0.3,1.5,0.6,2.6,1,3.2c-0.4,0.2-0.8,0.3-1.2,0.3
									c-0.4,0.1-0.8,0.1-1.4,0.1c-0.2-1.8-0.5-3.7-0.9-5.7c-0.1-0.6-0.3-1-0.6-1.2c-0.3-0.2-0.7-0.3-1.3-0.2c-0.8,0.1-1.4,0.4-1.9,0.9
									l0.1,0.9c0.1,0.7,0.2,1.6,0.4,2.6c0.3,1.5,0.6,2.6,1,3.2c-0.4,0.2-0.9,0.3-1.3,0.4C823.5,539,823.1,539,822.5,539z"
						/>
						<path
							d="M838.3,524.1c0.4-0.6,0.8-1.1,1.2-1.6c0.5,0.4,1.1,0.8,1.6,1.1c-0.3,0.6-0.7,1.1-1.2,1.6
									C839.4,524.8,838.9,524.4,838.3,524.1z M841,535.6c-0.2-0.2-0.4-0.7-0.5-1.3c-0.1-0.5-0.2-1.3-0.3-2.3c-0.1-0.9-0.2-1.6-0.3-2
									c-0.1-0.6-0.2-1.1-0.4-1.6c-0.2-0.5-0.3-0.8-0.5-1c0.4-0.2,0.8-0.4,1.2-0.5s0.8-0.2,1.3-0.3c0,0.4,0,1.1,0.1,2
									c0.1,0.9,0.2,1.9,0.4,3c0.1,0.8,0.3,1.4,0.4,1.8c0.1,0.4,0.3,0.7,0.4,1c0.2,0.2,0.4,0.4,0.7,0.7c-0.2,0.3-0.4,0.5-0.7,0.7
									c-0.3,0.2-0.5,0.3-0.7,0.3C841.5,535.9,841.2,535.8,841,535.6z"
						/>
						<path
							d="M848,534.6c-0.1-1.5-0.3-3-0.6-4.7c-0.1-0.8-0.2-1.4-0.4-1.8c-0.1-0.4-0.3-0.7-0.4-1c-0.2-0.2-0.4-0.5-0.7-0.7
									c0.2-0.3,0.4-0.6,0.7-0.7c0.3-0.2,0.5-0.3,0.7-0.3c0.8-0.1,1.3,0.2,1.6,1.1c0.3-0.5,0.7-0.9,1.2-1.2c0.5-0.3,1-0.5,1.6-0.6
									c1.8-0.3,2.8,0.6,3.1,2.6l0.2,1.4c0,0.5,0.1,1,0.2,1.5c0.2,1.5,0.6,2.6,1,3.2c-0.4,0.2-0.8,0.3-1.2,0.3c-0.4,0.1-0.9,0.1-1.4,0.1
									c-0.2-1.8-0.4-3.7-0.7-5.7c-0.1-0.6-0.3-1-0.6-1.2s-0.7-0.3-1.3-0.2c-0.8,0.1-1.5,0.4-2,0.8l0.1,0.9c0.1,0.7,0.2,1.6,0.3,2.6
									c0.2,1.5,0.6,2.6,1,3.2c-0.4,0.2-0.9,0.3-1.3,0.3C849,534.6,848.6,534.6,848,534.6z"
						/>
						<path
							d="M860.6,532.3c-0.4-0.3-0.7-0.8-0.8-1.4c-0.3-1.8,1.4-3,5-3.7c-0.1-1-0.4-1.6-0.7-1.9c-0.3-0.3-0.9-0.4-1.7-0.3
									c-0.5,0.1-0.9,0.2-1.4,0.4c-0.5,0.2-0.9,0.5-1.2,0.8l-0.2-0.4c0.3-0.7,0.8-1.3,1.4-1.8c0.6-0.5,1.3-0.8,2.1-0.9
									c1-0.1,1.8,0,2.4,0.4c0.6,0.4,1,1.1,1.1,2.1c0.1,0.5,0.1,1.1,0.1,1.8c0,0.7,0.1,1.2,0.1,1.6c0.1,0.6,0.2,1,0.4,1.3
									s0.5,0.6,0.9,0.9c-0.5,0.5-1.1,0.9-1.7,1.1c-0.4-0.2-0.8-0.6-1-1.1c-0.5,0.4-1,0.8-1.6,1.1s-1.1,0.5-1.6,0.6
									C861.5,532.8,861,532.7,860.6,532.3z M864,531c0.4-0.2,0.8-0.5,1.1-0.8l0-0.1c-0.1-0.5-0.1-0.9-0.1-1.4l-0.1-0.9
									c-1,0.2-1.8,0.5-2.3,1c-0.5,0.4-0.7,1-0.6,1.5c0,0.2,0.1,0.5,0.3,0.7s0.4,0.4,0.6,0.5C863.2,531.4,863.6,531.3,864,531z"
						/>
						<path
							d="M871.1,530.6c0-0.3,0-0.5,0-0.8c0-0.3,0.1-0.5,0.2-0.8c0.3,0.3,0.8,0.6,1.3,0.7c0.5,0.1,1,0.2,1.6,0.1
									c1.5-0.2,2.1-0.7,2-1.4c0-0.3-0.2-0.6-0.6-0.7c-0.4-0.2-0.9-0.4-1.6-0.5c-0.7-0.2-1.3-0.4-1.7-0.6c-0.4-0.2-0.8-0.4-1.2-0.8
									c-0.4-0.4-0.6-0.8-0.7-1.4c-0.1-0.7,0.2-1.3,0.7-1.8s1.4-0.8,2.4-1c1.4-0.2,2.5-0.2,3.6,0.1c0,0.2,0,0.6-0.1,1
									c-0.1,0.4-0.2,0.8-0.4,1.1c-0.6-0.4-1.2-0.7-1.8-0.9s-1-0.3-1.4-0.3c-0.4,0.1-0.6,0.3-0.8,0.5c-0.2,0.2-0.3,0.5-0.3,0.7
									c0.1,0.4,0.3,0.7,0.7,0.9s1,0.4,1.8,0.7c0.7,0.2,1.2,0.4,1.6,0.5c0.4,0.2,0.8,0.4,1.1,0.8c0.3,0.3,0.5,0.8,0.6,1.3
									c0.1,0.8-0.1,1.5-0.8,2c-0.6,0.5-1.6,0.9-2.8,1.1C873.2,531.1,872,531,871.1,530.6z"
						/>
					</g>
					<g className="level_two fade location " id="nenuial">
						<rect x="1726.875" y="3208.95" width="112.128" height="70.598" transform="matrix(0.892697, -0.450658, 0.439908, 0.898124, -2120.168701, -1648.550171)"></rect>
						<path
							d="M852.1,469.8c-1-2.4-2.5-5.7-4.6-9.9c-1.9-3.8-3.2-6.2-4-7.1c0.6-0.5,1.1-0.8,1.6-1c0.5-0.3,1.2-0.5,2-0.8
									c1.6,1.1,3.9,2.6,6.8,4.5c2.9,1.9,5.5,3.5,7.7,4.9c-1.2-2.6-2.2-4.6-2.9-6c-2-4-3.3-6.4-4-7.1c0.4-0.3,0.8-0.6,1.2-0.8
									c0.4-0.2,0.9-0.4,1.4-0.5c0.5,1.2,1.2,2.7,2,4.6s1.7,3.7,2.5,5.3c1.9,3.8,3.2,6.2,4,7.1c-0.3,0.3-0.8,0.6-1.6,1
									c-0.5,0.2-1.2,0.5-2,0.8c-1.6-1.1-3.8-2.6-6.7-4.5c-2.9-1.9-5.5-3.6-7.7-4.9c0.9,1.9,1.8,3.9,2.9,6c1.9,3.9,3.2,6.3,4,7.1
									c-0.3,0.3-0.7,0.6-1.2,0.8C853.1,469.6,852.7,469.7,852.1,469.8z"
						/>
						<path
							d="M868.6,460c-1.5-0.7-2.8-2-3.8-4.1c-0.7-1.4-1-2.8-1-4.2c0-1.4,0.4-2.7,1.1-3.8c0.7-1.1,1.7-2,3.1-2.7
									c1.8-0.9,3.4-1,4.9-0.3c1.5,0.7,2.7,2.1,3.8,4.3c-1,0.9-2.3,1.8-3.9,2.8c-1.6,1-3.1,1.9-4.7,2.8c0.8,1.2,1.6,2,2.5,2.3
									c0.9,0.3,1.9,0.2,3.1-0.4c0.9-0.4,1.7-1,2.4-1.6c0.7-0.6,1.3-1.2,1.6-1.9l0.5,0.5c-0.3,1.2-0.8,2.3-1.7,3.4
									c-0.9,1.1-1.9,1.9-3.1,2.5C871.8,460.5,870.1,460.6,868.6,460z M870.5,451.7c0.9-0.8,1.6-1.5,2.1-2.1c-0.6-1.1-1.4-1.9-2.4-2.3
									c-1-0.4-2-0.4-3,0.1c-0.5,0.7-0.7,1.7-0.7,2.8c0.1,1.1,0.4,2.3,1,3.6C868.6,453.1,869.6,452.4,870.5,451.7z"
						/>
						<path
							d="M883.5,454.3c-0.9-2.2-1.9-4.6-3.1-7c-0.6-1.2-1.1-2.1-1.4-2.7c-0.4-0.6-0.7-1-1.1-1.3c-0.3-0.3-0.8-0.5-1.4-0.7
									c0.2-0.6,0.4-1.1,0.7-1.4c0.3-0.4,0.6-0.6,1-0.8c1.2-0.6,2.2-0.3,3,1c0.3-0.9,0.7-1.8,1.3-2.5c0.6-0.8,1.3-1.3,2.1-1.7
									c2.6-1.3,4.6-0.4,6.1,2.6l0.9,2.1c0.3,0.8,0.6,1.6,1,2.2c1.1,2.2,2.1,3.7,3.1,4.5c-0.6,0.4-1.1,0.8-1.7,1.1
									c-0.6,0.3-1.3,0.5-2.1,0.8c-1.1-2.7-2.4-5.5-3.9-8.6c-0.4-0.9-0.9-1.4-1.4-1.6c-0.5-0.2-1.2-0.1-2,0.3c-1.2,0.6-2.1,1.3-2.7,2.2
									l0.6,1.4c0.5,1.1,1.1,2.5,1.8,4c1.1,2.3,2.1,3.8,3,4.5c-0.6,0.5-1.2,0.8-1.8,1.1C885,453.9,884.3,454.1,883.5,454.3z"
						/>
						<path
							d="M897,442.7l-0.9-2.1l-1-2.2c-1-2.1-2-3.5-2.8-4.2c0.9-1,2-1.8,3.3-2.4c1.1,2.7,2.5,5.8,4.2,9.2c0.4,0.9,0.9,1.4,1.5,1.6
									c0.6,0.2,1.3,0.1,2.1-0.3c1.1-0.5,1.9-1.3,2.6-2.2c-0.7-1.4-1.3-2.7-1.8-3.9l-0.8-1.8c-1-2.1-2-3.5-2.8-4.2c0.9-1,2-1.8,3.4-2.4
									c0.6,1.5,1.4,3.3,2.3,5.4c0.9,2.1,1.5,3.5,1.9,4.2c0.4,0.8,0.8,1.3,1.2,1.7c0.4,0.4,1,0.7,1.7,1c-0.5,1-1.2,1.9-2.1,2.5
									c-0.9-0.1-1.6-0.6-2.2-1.3c-0.3,0.9-0.8,1.7-1.4,2.4c-0.6,0.7-1.4,1.3-2.3,1.7C900.4,446.5,898.4,445.7,897,442.7z"
						/>
						<path
							d="M904.9,422.2c0.3-1.1,0.6-2.1,1.1-3c1,0.4,2,0.8,3,1c-0.3,1-0.6,2-1.1,3C906.9,422.8,905.9,422.5,904.9,422.2z M914.3,439
									c-0.5-0.3-0.9-0.9-1.4-1.9c-0.4-0.8-0.9-1.9-1.6-3.4c-0.6-1.4-1.1-2.4-1.4-3.1c-0.4-0.9-0.9-1.7-1.3-2.4c-0.5-0.7-0.9-1.1-1.3-1.4
									c0.5-0.5,1-1,1.5-1.4c0.5-0.4,1.1-0.7,1.8-1.1c0.2,0.7,0.6,1.7,1.1,3c0.6,1.3,1.2,2.8,2,4.4c0.6,1.2,1.1,2.1,1.4,2.7
									c0.4,0.6,0.7,1,1.1,1.3s0.8,0.5,1.4,0.7c-0.2,0.6-0.4,1-0.7,1.4c-0.3,0.4-0.7,0.7-1,0.8C915.3,439.2,914.7,439.3,914.3,439z"
						/>
						<path
							d="M920.2,435.9c-0.8-0.3-1.4-0.9-1.9-1.8c-1.3-2.6,0.6-5.3,5.7-8.2c-0.7-1.4-1.4-2.3-2.1-2.7c-0.7-0.4-1.6-0.3-2.7,0.3
									c-0.7,0.3-1.3,0.8-1.9,1.3c-0.6,0.6-1.1,1.2-1.5,1.8l-0.6-0.5c0.1-1.2,0.6-2.4,1.3-3.5c0.7-1.1,1.6-1.9,2.7-2.4
									c1.4-0.7,2.7-0.9,3.9-0.5c1.1,0.4,2,1.3,2.7,2.7c0.4,0.8,0.7,1.7,1.1,2.7c0.4,1.1,0.7,1.9,1,2.4c0.4,0.8,0.8,1.4,1.2,1.8
									c0.4,0.4,1,0.7,1.7,1c-0.5,1.1-1.2,1.9-2.1,2.5c-0.8-0.1-1.5-0.5-2.1-1.2c-0.5,0.9-1.2,1.8-1.9,2.5c-0.7,0.7-1.4,1.3-2.2,1.6
									C921.8,436.1,921,436.2,920.2,435.9z M924.8,432.3c0.5-0.6,0.9-1.1,1.2-1.7l-0.1-0.2c-0.3-0.7-0.6-1.4-0.9-2.1l-0.6-1.4
									c-1.5,0.8-2.5,1.7-3,2.6c-0.6,0.9-0.6,1.8-0.2,2.7c0.2,0.4,0.5,0.7,0.8,0.9c0.4,0.3,0.8,0.4,1.2,0.4
									C923.7,433.3,924.3,432.8,924.8,432.3z"
						/>
						<path
							d="M934.3,429.4c-0.4-0.3-0.9-0.9-1.4-1.9c-0.3-0.7-0.8-1.8-1.5-3.2c-0.7-1.7-1.4-3-1.9-4.1c-1.7-3.5-3.1-5.9-4.2-7.2
									c0.5-0.5,1-1,1.5-1.4c0.5-0.4,1.1-0.7,1.8-1c1,2.8,2.7,6.7,5.1,11.7c0.6,1.2,1,2.1,1.4,2.7c0.4,0.6,0.8,1,1.1,1.3
									c0.4,0.3,0.8,0.5,1.4,0.7c-0.2,0.6-0.4,1-0.7,1.4s-0.7,0.7-1,0.8C935.3,429.6,934.7,429.6,934.3,429.4z"
						/>
						<path
							d="M859.3,467.4l0.5,0.2c-0.5,1.8-0.5,3.6-0.2,5.5c0.3,1.9,1.1,3.7,2.1,5.4s2.4,3.2,3.9,4.3c1.5,1.2,3.2,1.9,5,2.3l-0.1,0.5
									c-2.4-0.1-4.6-0.6-6.5-1.6c-1.9-1-3.4-2.4-4.5-4.2c-1.1-1.8-1.6-3.8-1.7-5.9C857.8,471.7,858.3,469.6,859.3,467.4z"
						/>
						<path
							d="M871.3,477.9c-0.4-0.8-1.1-2-2-3.6c-1.9-3.3-3.2-5.2-4-5.8c0.4-0.4,0.9-0.7,1.4-1c0.5-0.3,1-0.5,1.7-0.8
									c0.5,1,1.1,2.3,2,3.9c0.8,1.6,1.6,3,2.4,4.3c1.1,1.9,1.9,3.1,2.4,3.8c0.9-0.3,1.9-0.8,3-1.4c0.7-0.4,1.3-0.8,1.8-1.3s1-1,1.3-1.5
									c0.3,0.5,0.6,1.1,0.8,1.7c0.2,0.6,0.3,1.1,0.2,1.4c-1.3,0.6-2.7,1.3-4.3,2.3c-1.9,1.1-3.4,2-4.3,2.7
									C872.5,480.3,871.7,478.7,871.3,477.9z"
						/>
						<path
							d="M885.4,475.3c-0.7-0.2-1.3-0.7-1.7-1.5c-1.2-2.2,0.3-4.6,4.6-7.3c-0.7-1.2-1.3-1.9-1.9-2.2c-0.6-0.3-1.4-0.2-2.3,0.4
									c-0.6,0.3-1.1,0.7-1.6,1.2c-0.5,0.5-0.9,1-1.2,1.6l-0.5-0.4c0.1-1.1,0.4-2.1,1-3.1c0.6-1,1.3-1.7,2.2-2.2c1.2-0.7,2.3-0.9,3.3-0.6
									c1,0.3,1.8,1,2.5,2.2c0.4,0.7,0.7,1.4,1.1,2.3c0.4,0.9,0.7,1.6,0.9,2c0.4,0.7,0.7,1.2,1.1,1.5c0.4,0.3,0.9,0.6,1.5,0.8
									c-0.4,1-0.9,1.7-1.7,2.3c-0.7-0.1-1.3-0.4-1.9-0.9c-0.4,0.8-0.9,1.6-1.5,2.2c-0.6,0.7-1.2,1.2-1.8,1.5
									C886.8,475.4,886.1,475.5,885.4,475.3z M889.2,472c0.4-0.5,0.7-1,1-1.6l-0.1-0.1c-0.3-0.6-0.6-1.2-0.8-1.7l-0.6-1.2
									c-1.2,0.8-2.1,1.6-2.5,2.4c-0.4,0.8-0.5,1.6-0.1,2.3c0.2,0.3,0.4,0.6,0.7,0.8c0.3,0.2,0.7,0.3,1,0.3
									C888.3,472.9,888.8,472.5,889.2,472z"
						/>
						<path
							d="M898.5,468.7c-1.1-2.4-2.2-4.8-3.4-7.1c-1.6-3.2-3-5.7-4.1-7.4c0.7-0.8,1.7-1.6,2.8-2.1c0.3,1,0.9,2.4,1.7,4.1
									c0.8,1.7,1.6,3.4,2.4,4.9l0.3,0.6c0.9-2.9,1.4-4.9,1.6-5.9c0.2-1,0.2-1.7,0.1-2c0.8-0.6,1.6-1.2,2.6-1.7c-0.6,1.6-1.3,3.8-2.1,6.4
									c4.2,2.6,7.1,4,8.7,4.3c-0.4,0.5-0.8,0.9-1.3,1.2s-1,0.7-1.7,1c-1-0.7-3.2-2-6.5-3.8l-0.6,2.1c1.1,2,2,3.2,2.6,3.7
									c-0.5,0.4-1,0.7-1.5,1C899.7,468.3,899.1,468.6,898.5,468.7z"
						/>
						<path
							d="M911.7,460.7c-1.3-0.6-2.3-1.8-3.2-3.6c-0.6-1.2-0.9-2.4-0.8-3.6c0-1.2,0.4-2.3,1-3.2s1.5-1.7,2.7-2.3
									c1.5-0.7,2.9-0.8,4.2-0.2c1.3,0.6,2.3,1.9,3.2,3.8c-0.9,0.7-2,1.5-3.4,2.4c-1.4,0.9-2.7,1.6-4.1,2.3c0.7,1.1,1.4,1.8,2.1,2
									c0.8,0.3,1.7,0.2,2.7-0.3c0.8-0.4,1.5-0.8,2.1-1.3c0.6-0.5,1.1-1,1.4-1.6l0.5,0.4c-0.2,1-0.7,2-1.5,2.9c-0.8,0.9-1.7,1.6-2.7,2.1
									C914.3,461.2,912.9,461.3,911.7,460.7z M913.4,453.5c0.8-0.7,1.4-1.2,1.8-1.8c-0.5-1-1.2-1.7-2-2c-0.9-0.4-1.7-0.3-2.6,0.1
									c-0.4,0.6-0.6,1.4-0.6,2.4c0,1,0.3,2,0.8,3.1C911.7,454.8,912.6,454.2,913.4,453.5z"
						/>
						<path
							d="M859,503.6c-0.4-0.9-1-2.1-1.8-3.7c-1.4-2.8-2.5-4.6-3.3-5.5c1-0.3,2.4-1,4.2-1.9c2.3-1.1,4-2.1,5.2-2.8
									c0.2,0.5,0.4,1,0.6,1.5s0.2,1,0.2,1.3c-0.5,0-1.1,0.1-1.8,0.3c-0.7,0.2-1.4,0.5-2.1,0.8c-0.9,0.5-1.7,0.9-2.4,1.5
									c0.6,1.4,1.2,2.7,1.7,3.8l0.7-0.3c0.7-0.3,1.2-0.5,1.5-0.7c0.8-0.4,1.4-0.7,1.9-1c0.4-0.3,0.9-0.6,1.3-0.9
									c0.2,0.3,0.4,0.6,0.6,0.9c0.1,0.2,0.2,0.6,0.4,1c-1.9,0.8-3.4,1.5-4.5,2c-0.5,0.2-0.8,0.4-1,0.5c0.9,1.8,1.6,3.1,2,3.8
									c0.9-0.2,1.8-0.6,2.8-1.1c0.8-0.4,1.5-0.8,2.2-1.3c0.7-0.5,1.2-1,1.6-1.4c0.2,0.5,0.4,1,0.6,1.5s0.2,1,0.1,1.3
									c-1.2,0.4-2.8,1.2-4.8,2.2c-1.8,0.9-3.2,1.7-4,2.2C860,505.9,859.4,504.5,859,503.6z"
						/>
						<path
							d="M876,499.9c-0.5-0.3-1.1-0.6-1.6-1.1c-0.5-0.5-1.2-1.2-2-2.2c-1-1.2-1.9-2.1-2.6-2.8c-0.7-0.7-1.5-1.3-2.3-1.7
									c0.4-0.4,0.8-0.8,1.2-1s0.9-0.5,1.5-0.8c0.6,0.9,1.7,2.2,3.4,4.2c1.7,1.9,2.9,3.1,3.7,3.7c-0.3-3-0.5-5.1-0.7-6.4
									c-0.2-1.3-0.4-2.2-0.6-2.7c-0.1-0.3-0.3-0.5-0.4-0.7c0.6-0.5,1.4-0.9,2.2-1.3c0,2.8,0.3,6.6,1,11.3l-1.3,1.6
									C877.1,500.1,876.5,500.1,876,499.9z"
						/>
						<path
							d="M885,495.3c-1.1-0.6-2-1.8-2.6-3.5c-0.4-1.2-0.6-2.3-0.4-3.3s0.5-2,1.2-2.8c0.6-0.8,1.5-1.4,2.6-1.8
									c1.4-0.5,2.7-0.5,3.8,0.2c1.1,0.6,2,1.9,2.6,3.6c-0.9,0.6-1.9,1.2-3.2,1.9c-1.3,0.7-2.6,1.3-3.9,1.8c0.5,1,1.1,1.7,1.8,2
									c0.7,0.3,1.5,0.3,2.4-0.1c0.7-0.3,1.4-0.6,2-1c0.6-0.4,1.1-0.8,1.4-1.3l0.4,0.4c-0.3,0.9-0.8,1.7-1.6,2.5
									c-0.8,0.8-1.6,1.3-2.6,1.7C887.4,496,886.1,495.9,885,495.3z M887.2,489c0.8-0.5,1.4-1,1.8-1.4c-0.4-0.9-0.9-1.6-1.7-2
									c-0.7-0.4-1.5-0.4-2.4-0.1c-0.4,0.5-0.7,1.2-0.7,2.1c0,0.9,0.1,1.8,0.5,2.8C885.6,490,886.4,489.5,887.2,489z"
						/>
						<path
							d="M898.7,491.8c-0.4-1.8-1-3.7-1.6-5.7c-0.3-1-0.6-1.7-0.8-2.2c-0.2-0.5-0.5-0.9-0.7-1.1c-0.2-0.3-0.6-0.5-1-0.7
									c0.2-0.4,0.4-0.8,0.7-1c0.3-0.3,0.6-0.4,0.8-0.5c1-0.3,1.7,0,2.2,1.1c0.3-0.7,0.7-1.3,1.3-1.8c0.6-0.5,1.2-0.9,1.8-1.1
									c2.1-0.7,3.6,0.2,4.4,2.7l0.5,1.7c0.2,0.7,0.3,1.3,0.5,1.8c0.6,1.8,1.2,3.1,1.9,3.8c-0.5,0.3-0.9,0.5-1.4,0.6
									c-0.5,0.2-1,0.3-1.7,0.4c-0.6-2.2-1.2-4.5-2-7c-0.2-0.7-0.5-1.2-0.9-1.4s-0.9-0.2-1.6,0c-1,0.3-1.8,0.8-2.3,1.4l0.3,1.2
									c0.2,0.9,0.6,2,1,3.3c0.6,1.9,1.2,3.1,1.8,3.8c-0.5,0.3-1,0.5-1.5,0.7C899.9,491.5,899.4,491.7,898.7,491.8z"
						/>
						<path
							d="M914.2,485.4c-1.1-0.6-2.1-1.7-3-3.5c-0.9-1.8-1.1-3.4-0.8-4.7c0.3-1.3,1.2-2.3,2.6-2.9c1.1-0.5,2.4-0.7,3.6-0.5
									c-0.9-1.6-1.6-2.8-2.3-3.5c0.4-0.4,0.8-0.8,1.2-1.1c0.4-0.3,0.9-0.6,1.4-0.8c0.8,2.2,2.3,5.8,4.7,10.8c0.3,0.6,0.6,1,0.9,1.3
									c0.3,0.3,0.7,0.5,1.3,0.8c-0.4,0.8-0.9,1.4-1.7,1.9c-0.6-0.1-1.2-0.4-1.7-1c-0.3,0.7-0.6,1.3-1.1,1.8s-1,0.9-1.6,1.2
									C916.6,486,915.4,486,914.2,485.4z M919.2,482.8c0.3-0.4,0.7-0.9,0.9-1.3c-0.2-0.5-0.6-1.4-1.2-2.7c-0.6-1.4-1.1-2.5-1.5-3.3
									c-0.9-0.1-1.8,0.1-2.7,0.5c-0.9,0.4-1.4,1.1-1.6,1.9c-0.2,0.9,0,1.9,0.5,3.1c0.5,1.2,1.2,2,2,2.5c0.8,0.5,1.6,0.6,2.5,0.4
									C918.5,483.5,918.9,483.2,919.2,482.8z"
						/>
						<path
							d="M920.6,466.6c0.1-0.9,0.4-1.7,0.6-2.4c0.8,0.3,1.6,0.5,2.4,0.6c-0.1,0.8-0.4,1.6-0.7,2.4
									C922.2,466.9,921.4,466.7,920.6,466.6z M928.9,479c-0.4-0.2-0.8-0.6-1.2-1.4c-0.4-0.6-0.8-1.4-1.4-2.6c-0.6-1-1-1.8-1.3-2.3
									c-0.4-0.7-0.8-1.2-1.2-1.7c-0.4-0.5-0.8-0.8-1.1-1c0.4-0.4,0.7-0.8,1.1-1.2c0.4-0.3,0.8-0.6,1.4-0.9c0.2,0.5,0.5,1.3,1.1,2.3
									c0.5,1,1.1,2.1,1.8,3.3c0.5,0.9,0.9,1.5,1.3,2c0.3,0.4,0.6,0.7,0.9,1c0.3,0.2,0.7,0.4,1.1,0.5c-0.1,0.4-0.3,0.8-0.5,1.2
									c-0.2,0.3-0.5,0.6-0.7,0.7C929.7,479.1,929.3,479.1,928.9,479z"
						/>
						<path
							d="M936.6,474.7c-1-1.6-2.1-3.3-3.3-5c-0.6-0.8-1.1-1.5-1.4-1.9c-0.4-0.4-0.7-0.7-1-0.9c-0.3-0.2-0.7-0.3-1.1-0.4
									c0-0.5,0.2-0.9,0.4-1.2c0.2-0.3,0.4-0.6,0.6-0.7c0.8-0.6,1.6-0.5,2.4,0.4c0.1-0.7,0.3-1.4,0.7-2.1c0.4-0.6,0.8-1.2,1.4-1.5
									c1.3-0.9,2.6-1,3.7-0.1c0-0.8,0.2-1.6,0.6-2.3c0.4-0.7,0.9-1.3,1.5-1.7c1.8-1.3,3.5-0.9,5,1.2l1,1.5c0.4,0.6,0.7,1.1,1,1.6
									c1.1,1.6,2.1,2.6,2.9,3.1c-0.4,0.4-0.8,0.7-1.1,1s-0.9,0.6-1.5,0.9c-1.2-1.9-2.6-4-4.1-6.1c-0.4-0.6-0.9-1-1.3-1
									c-0.4-0.1-0.9,0.1-1.5,0.5c-0.8,0.6-1.3,1.2-1.7,2c0.4,0.6,1,1.4,1.8,2.5c0.2,0.3,0.5,0.7,0.9,1.3c1.1,1.6,2.1,2.6,2.9,3.1
									c-0.4,0.4-0.7,0.7-1.1,1c-0.4,0.3-0.9,0.6-1.5,0.9c-1.2-1.9-2.6-4-4.1-6.1c-0.4-0.6-0.9-1-1.3-1c-0.4-0.1-0.9,0.1-1.5,0.5
									c-0.8,0.6-1.3,1.2-1.6,2l0.7,1c0.5,0.8,1.1,1.7,1.9,2.8c1.1,1.6,2.1,2.6,2.9,3.1c-0.4,0.4-0.8,0.8-1.2,1.1
									C937.7,474.1,937.2,474.4,936.6,474.7z"
						/>
						<path
							d="M948.6,448.2c1.8,0.7,3.4,1.8,4.5,3.2c1.2,1.5,1.9,3.2,2.2,5.1c0.3,2,0.1,4-0.6,6l-0.5-0.1c0.2-1.7,0-3.3-0.5-4.9
									c-0.5-1.6-1.4-3.2-2.5-4.6c-1.2-1.4-2.5-2.6-4-3.5c-1.5-0.9-3.1-1.4-4.7-1.5l0-0.5C944.7,447.3,946.7,447.6,948.6,448.2z"
						/>
					</g>
					<g className="level_two fade location " id="emyn_uial">
						<rect x="1110.475" y="1191.353" width="72.104" height="26.21" transform="matrix(0.397311, 0.917684, -0.922468, 0.38626, 1394.342285, -1126.709229)"></rect>
						<rect x="2496.871" y="1191.355" width="162.124" height="26.21" transform="matrix(-0.243159, 0.969986, -0.967058, -0.25484, 2524.543213, -1699.589478)"></rect>
						<rect x="1318.307" y="1191.352" width="85.599" height="26.21" transform="matrix(0.651122, 0.758973, -0.766814, 0.641982, 846.760681, -1470.987427)"></rect>
						<rect x="1183.839" y="1191.353" width="76.868" height="26.21" transform="matrix(-0.156176, 0.987729, -0.985848, -0.168071, 2219.211426, -591.109375)"></rect>
						<path
							d="M780.6,307.6c0.7-0.8,1.7-1.9,2.9-3.2c2.2-2.5,3.5-4.3,4.1-5.5c0.7,0.8,1.9,2,3.6,3.4c2,1.8,3.7,3.1,4.9,4
									c-0.4,0.4-0.8,0.8-1.3,1.2c-0.5,0.4-0.9,0.6-1.2,0.7c-0.2-0.5-0.5-1.1-1-1.7c-0.5-0.6-1-1.2-1.7-1.7c-0.8-0.7-1.6-1.3-2.4-1.8
									c-1.2,1.2-2.2,2.3-3.1,3.3l0.6,0.5c0.6,0.5,1,0.9,1.3,1.2c0.7,0.6,1.3,1.1,1.7,1.4c0.4,0.3,0.9,0.6,1.5,1
									c-0.2,0.4-0.4,0.7-0.6,0.9c-0.2,0.2-0.5,0.5-0.8,0.8c-1.6-1.6-2.9-2.8-3.8-3.7c-0.4-0.4-0.8-0.7-1-0.8c-1.4,1.6-2.4,2.8-2.9,3.5
									c0.6,0.8,1.3,1.6,2.2,2.4c0.7,0.6,1.4,1.1,2.2,1.6s1.5,0.8,2.1,1c-0.4,0.4-0.8,0.8-1.3,1.2s-0.9,0.6-1.2,0.7
									c-0.9-1-2.3-2.3-4.1-3.9c-1.6-1.4-2.9-2.5-3.8-3.1C778.7,309.5,779.8,308.4,780.6,307.6z"
						/>
						<path
							d="M791.5,323.7c1.7-1.3,3.9-3.3,6.8-6c1.5-1.4,2.6-2.5,3.3-3.2s1.2-1.3,1.4-1.7c0.5,0.3,0.9,0.7,1.3,1.1
									c0.3,0.3,0.7,0.8,1.1,1.4c-0.6,1.4-1.4,3.4-2.4,5.9c-1,2.5-1.8,4.6-2.3,6.1c1.7-0.5,3.7-1.1,6.2-1.9c2.4-0.8,4.5-1.5,6.2-2.1
									c0.4,0.2,0.8,0.6,1.3,1.1c0.4,0.4,0.7,0.8,1,1.4c-0.8,0.6-1.8,1.5-3.1,2.6c-1.3,1.1-2.5,2.2-3.7,3.3c-2.7,2.6-4.3,4.2-4.6,5
									c-0.5-0.3-0.9-0.7-1.3-1.1c-0.4-0.4-0.7-0.9-1.1-1.4c0.8-0.6,1.8-1.5,3.2-2.6c1.3-1.1,2.5-2.2,3.6-3.3c0.7-0.7,1.5-1.5,2.5-2.4
									c-3.3,1-7.7,2.4-13.1,4.2c-0.3-0.1-0.4-0.3-0.6-0.4c-0.1-0.1-0.2-0.3-0.3-0.6c0.6-1.3,1.3-3.1,2.3-5.5c0.9-2.4,1.8-4.5,2.5-6.5
									c-1.5,1.4-2.7,2.5-3.6,3.3c-2.6,2.5-4.2,4.1-4.7,4.9c-0.3-0.2-0.6-0.4-0.9-0.7C792,324.5,791.7,324.1,791.5,323.7z"
						/>
						<path
							d="M811.4,346c1.2-0.7,2.7-1.8,4.6-3.2c1-2,2-4.1,2.8-6.3c0.8-2.2,1.3-3.6,1.4-4.2c0.9,0.5,1.9,1.4,2.8,2.6
									c-0.3,0.4-0.9,1.4-1.7,3c-0.8,1.6-1.6,3.2-2.3,4.9c2.2-0.4,4-0.8,5.4-1.2c1.4-0.4,2.3-0.7,2.7-1.1c0.7,0.7,1.4,1.5,2.1,2.4
									c-2.8,0.1-6.7,0.8-11.6,1.9c-2.4,1.9-3.8,3.2-4.1,3.9c-0.4-0.4-0.8-0.8-1.2-1.2C812,347.1,811.7,346.6,811.4,346z"
						/>
						<path
							d="M820.1,358.1c1.9-0.9,4.6-2.3,8-4.2c3.1-1.7,5-2.9,5.7-3.6c0.4,0.5,0.7,0.9,0.9,1.3c0.2,0.4,0.5,1,0.7,1.6
									c-0.8,1.4-2,3.3-3.5,5.9c-1.5,2.5-2.7,4.7-3.7,6.6c2.1-1.1,3.7-2,4.9-2.7c3.2-1.8,5.2-3,5.8-3.6c0.3,0.3,0.6,0.6,0.7,1
									c0.2,0.3,0.3,0.7,0.4,1.2c-0.9,0.5-2.2,1.1-3.7,1.9c-1.6,0.8-3,1.6-4.3,2.3c-3.1,1.7-5,2.9-5.7,3.6c-0.3-0.2-0.6-0.7-0.9-1.3
									c-0.2-0.4-0.5-1-0.7-1.7c0.8-1.4,2-3.3,3.4-5.8c1.5-2.5,2.7-4.7,3.8-6.6c-1.6,0.8-3.2,1.7-4.9,2.6c-3.1,1.8-5.1,3-5.8,3.6
									c-0.3-0.3-0.5-0.6-0.7-1C820.4,359,820.3,358.6,820.1,358.1z"
						/>
						<path
							d="M835.5,389.4c0.9-1.2,2.3-1.9,4.2-2.2c2-0.3,4-0.7,5.9-1.1c1.9-0.4,3.1-0.7,3.7-1c0.2,0.5,0.4,1,0.4,1.6
									c0.1,0.5,0.1,1.1,0.1,1.8c-2.9,0.3-6.2,0.8-9.8,1.4c-2.7,0.4-3.9,1.9-3.5,4.3c0.4,2.6,1.9,3.7,4.3,3.3c5.6-0.9,9-1.5,10.1-1.9
									c0.2,0.4,0.3,0.7,0.4,1.1c0.1,0.3,0.1,0.8,0,1.3c-1.1,0.1-2.7,0.3-4.6,0.6s-3.6,0.5-5,0.7c-1.8,0.3-3.4,0.1-4.7-0.7
									c-1.3-0.8-2.2-2.3-2.5-4.6C834.3,392.1,834.6,390.6,835.5,389.4z"
						/>
						<path
							d="M835.7,408.4c1,0.2,2.3,0.3,4.1,0.5c1.7,0.2,3.4,0.3,5,0.3c3.7,0.2,5.9,0.1,6.8-0.2c0.1,0.6,0.1,1.2,0.1,1.7
									c0,0.5-0.1,1.1-0.3,1.8c-1-0.2-2.3-0.3-4-0.5c-1.7-0.2-3.4-0.3-4.9-0.3c-3.7-0.2-5.9-0.1-6.8,0.1c-0.1-0.6-0.1-1.1-0.1-1.7
									C835.4,409.6,835.5,409.1,835.7,408.4z"
						/>
						<path
							d="M833.2,430l4.1-0.8c0.2-0.9,0.4-2.1,0.6-3.5c0.2-1.5,0.3-2.5,0.3-3.1c-1.1-0.6-2.3-1.3-3.6-2c-0.1-0.4-0.1-0.7,0-1
									c0.1-0.4,0.2-0.8,0.4-1.2c0.9,0.7,3.1,2.1,6.6,4.1c3.5,2,6.2,3.5,8.2,4.5c0,0.6,0,1-0.1,1.4c-0.1,0.4-0.2,0.8-0.3,1.2
									c-2.2,0.4-5.2,1-9.1,1.9c-3.9,0.9-6.4,1.5-7.5,2c-0.1-0.5-0.1-1.1,0-1.6C832.8,431.2,833,430.7,833.2,430z M839.1,428.8
									c2.8-0.6,5.1-1.2,7-1.7c-1.5-1-3.6-2.2-6.2-3.6c-0.2,1-0.4,2.2-0.6,3.5C839.2,427.9,839.2,428.5,839.1,428.8z"
						/>
						<path
							d="M836.3,441.5c0.9,0.2,2.2,0.5,3.9,0.9c3.6,0.8,5.8,1.1,6.7,0.9c0,0.6-0.1,1.1-0.2,1.7c-0.1,0.5-0.3,1.1-0.6,1.7
									c-1-0.3-2.4-0.7-4.1-1.2c-1.7-0.4-3.2-0.8-4.6-1.1c-2-0.4-3.5-0.7-4.3-0.8c-0.4,0.9-0.7,1.9-1,3.1c-0.2,0.7-0.2,1.5-0.3,2.2
									s0,1.4,0.2,1.9c-0.6-0.1-1.2-0.3-1.7-0.6c-0.6-0.3-1-0.5-1.1-0.7c0.4-1.3,0.8-2.8,1.2-4.6c0.5-2.1,0.8-3.7,0.9-4.8
									C833.7,440.8,835.4,441.2,836.3,441.5z"
						/>
						<path
							d="M738.5,359.9l-0.2,0.5c-1.7-0.6-3.5-0.9-5.4-0.7c-1.9,0.2-3.7,0.7-5.5,1.6c-1.8,0.9-3.4,2-4.7,3.4
									c-1.3,1.4-2.2,2.9-2.8,4.7l-0.5-0.1c0.3-2.3,1.1-4.4,2.3-6.2c1.2-1.8,2.7-3.2,4.6-4.1c1.9-0.9,3.9-1.2,6-1.1
									C734.3,358,736.4,358.7,738.5,359.9z"
						/>
						<path
							d="M721.7,371.6c1-0.3,2.3-0.8,3.9-1.5c1.7-0.7,3.2-1.4,4.7-2c3.4-1.6,5.4-2.7,6.1-3.4c0.4,0.5,0.7,1,0.9,1.5
									c0.2,0.5,0.4,1.1,0.6,1.8c-1.7,0.6-3.9,1.5-6.6,2.6c0.4,1.1,0.9,2.4,1.7,4.1c0.1,0.2,0.5,1,1.2,2.3c3.5-1.6,5.6-2.7,6.3-3.4
									c0.3,0.5,0.6,1,0.9,1.5c0.2,0.5,0.4,1.1,0.6,1.7c-1,0.3-2.3,0.8-3.9,1.5c-1.7,0.7-3.2,1.4-4.7,2c-3.4,1.5-5.4,2.6-6.1,3.3
									c-0.3-0.5-0.6-1-0.9-1.5c-0.2-0.5-0.4-1-0.6-1.7c1.7-0.6,3.9-1.5,6.6-2.7c-0.5-1.2-1-2.5-1.6-3.7c-0.4-0.9-0.8-1.7-1.3-2.6
									c-3.5,1.6-5.6,2.7-6.3,3.4c-0.3-0.5-0.6-1-0.9-1.5C722,372.9,721.8,372.3,721.7,371.6z"
						/>
						<path
							d="M729.3,387.8c0.2-0.4,0.8-0.8,1.7-1.1c0.7-0.3,1.7-0.7,3-1.2c1.2-0.5,2.1-0.8,2.7-1.1c0.8-0.3,1.5-0.7,2.1-1.1
									s1-0.7,1.2-1c0.4,0.5,0.8,0.9,1.1,1.4c0.3,0.5,0.6,1,0.8,1.6c-0.6,0.1-1.5,0.4-2.6,0.8c-1.2,0.4-2.5,0.9-3.9,1.5
									c-1,0.4-1.8,0.8-2.3,1.1s-0.9,0.6-1.2,0.9c-0.3,0.3-0.5,0.7-0.7,1.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.3-0.3-0.6-0.6-0.7-0.9
									C729,388.7,729,388.2,729.3,387.8z M743.9,380.4c0.9,0.3,1.8,0.6,2.5,1c-0.4,0.8-0.7,1.7-1,2.5c-0.9-0.3-1.7-0.6-2.5-1
									C743.3,382.2,743.6,381.3,743.9,380.4z"
						/>
						<path
							d="M732.2,394.8c0.2-0.4,0.8-0.7,1.7-1.1c0.6-0.2,1.5-0.6,2.9-1.1c1.5-0.5,2.7-1,3.6-1.3c3.1-1.2,5.2-2.3,6.4-3.1
									c0.4,0.5,0.8,0.9,1.1,1.4c0.3,0.5,0.5,1,0.8,1.6c-2.5,0.7-5.9,1.9-10.3,3.6c-1,0.4-1.8,0.8-2.3,1c-0.5,0.3-0.9,0.6-1.2,0.9
									c-0.3,0.3-0.5,0.7-0.7,1.1c-0.5-0.2-0.9-0.4-1.2-0.7c-0.3-0.3-0.5-0.6-0.6-0.9C732,395.6,732,395.1,732.2,394.8z"
						/>
						<path
							d="M734.9,401.7c0.3-0.4,0.9-0.7,1.8-1c0.6-0.2,1.6-0.5,2.9-0.9c1.5-0.4,2.7-0.8,3.7-1.1c3.1-1,5.3-1.9,6.5-2.7
									c0.4,0.5,0.7,1,1,1.5c0.3,0.5,0.5,1,0.7,1.7c-2.5,0.5-6,1.5-10.5,3c-1.1,0.3-1.9,0.6-2.4,0.9c-0.6,0.3-1,0.5-1.2,0.8
									c-0.3,0.3-0.5,0.6-0.8,1.1c-0.5-0.2-0.8-0.5-1.1-0.8c-0.3-0.3-0.5-0.6-0.6-0.9C734.6,402.5,734.6,402.1,734.9,401.7z"
						/>
						<path
							d="M737.1,406.9c0.4-0.1,0.7-0.1,1.1-0.1c0.4,0,0.8,0.1,1.2,0.2c-0.4,0.5-0.7,1.1-0.8,1.8s-0.1,1.4,0,2.2
									c0.4,2,1.2,2.8,2.2,2.6c0.4-0.1,0.8-0.4,1-0.9c0.2-0.5,0.4-1.3,0.6-2.2c0.2-1,0.4-1.8,0.6-2.4c0.2-0.6,0.5-1.2,1-1.7
									c0.5-0.5,1.1-0.9,1.9-1c0.9-0.2,1.8,0.1,2.6,0.8c0.8,0.7,1.3,1.8,1.6,3.2c0.4,1.8,0.5,3.4,0.3,4.8c-0.3,0.1-0.8,0.1-1.3,0
									c-0.6-0.1-1.1-0.2-1.6-0.4c0.5-0.9,0.9-1.7,1.1-2.5c0.2-0.8,0.3-1.4,0.2-1.9c-0.2-0.5-0.5-0.8-0.8-1.1c-0.3-0.3-0.7-0.3-1-0.3
									c-0.6,0.1-1,0.4-1.2,1c-0.2,0.5-0.5,1.4-0.7,2.5c-0.2,0.9-0.4,1.7-0.6,2.3c-0.2,0.6-0.5,1.2-0.9,1.6c-0.4,0.5-1,0.8-1.7,1
									c-1.1,0.2-2.1,0-2.9-0.8c-0.8-0.8-1.4-2-1.8-3.7C736.6,409.9,736.7,408.3,737.1,406.9z"
						/>
						<path
							d="M740.8,428.1c1.1-1,2.7-1.6,4.5-1.6c1.4,0,2.6,0.3,3.6,0.9c1.1,0.6,1.9,1.4,2.5,2.4c0.6,1,0.9,2.2,0.9,3.5
									c0,1.2-0.3,2.2-0.8,3.1c-0.5,0.9-1.3,1.6-2.2,2.1c-0.9,0.5-2,0.7-3.3,0.7c-1.4,0-2.6-0.3-3.6-0.9c-1.1-0.6-1.9-1.4-2.5-2.5
									s-0.9-2.3-0.9-3.7C739,430.5,739.6,429.1,740.8,428.1z M741.5,435.6c0.8,0.7,1.9,1,3.3,1c1.3,0,2.4-0.3,3.3-0.9
									c0.9-0.6,1.5-1.4,1.9-2.5c0-1.2-0.4-2.2-1.2-2.9c-0.8-0.7-1.9-1.1-3.3-1.1c-1.6,0-2.9,0.3-3.8,1c-0.9,0.7-1.4,1.5-1.4,2.6
									C740.3,434,740.7,434.9,741.5,435.6z"
						/>
						<path
							d="M734.6,441.5c0.5,0.5,1,0.9,1.4,1.1c0.4,0.3,1.1,0.5,1.8,0.7c0.8,0.2,1.9,0.3,3.3,0.4c1.6,0.1,4.4,0.3,8.4,0.5
									c0.1-0.7,0.2-1.2,0.3-1.4c0.1-0.2,0.3-0.3,0.6-0.3c0.4,0.9,0.6,1.5,0.9,1.9c1.7,0.4,3,1.1,3.9,2.2c0.9,1.1,1.3,2.3,1.2,3.7
									c-0.1,1-0.3,2-0.7,2.9c-0.3,0-0.7-0.2-1.3-0.4s-1-0.5-1.4-0.8c0.6-0.6,1.1-1.2,1.6-1.8c0.4-0.6,0.6-1.2,0.6-1.6
									c-0.2-0.4-0.6-0.7-1.3-0.9s-1.7-0.4-3.1-0.5c-0.1,1.6-0.1,2.7,0,3.3c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.7-0.3-1-0.5
									c0.3-0.7,0.5-1.6,0.6-2.8l-2.1-0.2c-1.2-0.1-2.7-0.2-4.3-0.3c-0.9,0-1.6,0-2.2-0.1c-1-0.1-1.9-0.3-2.5-0.6
									c-0.7-0.3-1.3-0.7-1.9-1.4c-0.6-0.6-1.3-1.5-2-2.7L734.6,441.5z"
						/>
						<path
							d="M741.4,460.9c1.1,0.3,2.5,0.6,4.4,1c3.3,0.7,5.6,1,6.9,0.9c-0.4,1.1-0.8,2.7-1.3,4.9c-0.6,2.7-1,4.9-1.2,6.4
									c-0.5-0.1-1.1-0.3-1.7-0.6s-1-0.5-1.2-0.7c0.4-0.4,0.7-1,1-1.8c0.3-0.8,0.5-1.5,0.7-2.4c0.2-1.1,0.4-2.1,0.4-3.1
									c-1.7-0.4-3.2-0.8-4.5-1.1l-0.2,0.8c-0.2,0.8-0.4,1.4-0.4,1.7c-0.2,1-0.3,1.7-0.4,2.3s-0.1,1.1-0.1,1.8c-0.4,0-0.8,0-1.1-0.1
									c-0.3-0.1-0.7-0.2-1.1-0.4c0.6-2.2,1.1-4,1.3-5.3c0.1-0.6,0.2-1,0.2-1.3c-2.2-0.5-3.7-0.8-4.6-0.9c-0.4,0.9-0.7,2-1,3.2
									c-0.2,0.9-0.3,1.8-0.4,2.8s0,1.7,0.1,2.3c-0.5-0.1-1.1-0.3-1.7-0.6c-0.6-0.3-1-0.5-1.2-0.7c0.4-1.3,0.9-3.2,1.4-5.6
									c0.5-2.2,0.8-3.9,0.8-5C738.8,460.2,740.4,460.7,741.4,460.9z"
						/>
						<path
							d="M733.2,477.3c0.6-0.3,1.3-0.5,2.1-0.6c0.8-0.1,1.9-0.2,3.3-0.2c1.7-0.1,3.1-0.2,4.2-0.3c1.1-0.1,2.1-0.4,3-0.9
									c0.1,0.6,0.1,1.2,0.1,1.8c-0.1,0.6-0.2,1.2-0.3,1.8c-1.1-0.1-3.1,0-5.9,0.1s-4.7,0.4-5.7,0.7c2.8,1.8,4.8,3.1,6,3.8
									c1.2,0.7,2.2,1.2,2.8,1.3c0.3,0.1,0.6,0.1,0.9,0.1c0,0.9-0.1,1.8-0.4,2.8c-2.4-1.9-5.9-4.2-10.5-6.8l-0.6-2.2
									C732.3,478,732.6,477.6,733.2,477.3z"
						/>
						<path
							d="M731.3,487.3c1.3-0.5,2.9-0.5,4.8,0.1c1.3,0.4,2.4,1,3.2,1.8c0.9,0.8,1.4,1.8,1.7,2.9s0.2,2.3-0.1,3.5
									c-0.5,1.6-1.4,2.7-2.7,3.2c-1.3,0.5-2.9,0.5-4.9-0.1c0.1-1.1,0.2-2.5,0.5-4.1c0.3-1.6,0.6-3.1,1-4.6c-1.2-0.2-2.2-0.2-2.9,0.2
									c-0.7,0.4-1.2,1.1-1.5,2.2c-0.3,0.8-0.4,1.6-0.4,2.4c0,0.8,0,1.5,0.2,2.1l-0.6,0c-0.6-0.9-1-1.9-1.1-3.1c-0.2-1.2-0.1-2.3,0.2-3.4
									C729.1,488.9,730,487.9,731.3,487.3z M735.4,493.4c-0.1,1-0.1,1.9,0.1,2.5c1,0.3,2,0.2,2.8-0.1c0.8-0.4,1.4-1,1.7-2
									c-0.2-0.7-0.6-1.4-1.4-2c-0.7-0.6-1.7-1.1-2.8-1.5C735.6,491.3,735.5,492.3,735.4,493.4z"
						/>
						<path
							d="M725.8,500.8c1.9,0.8,3.9,1.6,6.1,2.4c1.1,0.4,1.9,0.6,2.5,0.7c0.6,0.1,1.1,0.2,1.4,0.1c0.4,0,0.8-0.2,1.3-0.4
									c0.3,0.4,0.4,0.9,0.4,1.3c0,0.4,0,0.8-0.1,1.1c-0.4,1.1-1.2,1.5-2.4,1.2c0.4,0.7,0.6,1.5,0.7,2.3c0.1,0.8,0,1.6-0.3,2.3
									c-0.8,2.3-2.6,3-5.2,2.1l-1.8-0.7c-0.7-0.3-1.3-0.6-1.9-0.8c-2-0.7-3.5-1-4.6-0.9c0.1-0.6,0.2-1.1,0.4-1.6
									c0.2-0.5,0.4-1.1,0.8-1.7c2.3,1,4.8,1.9,7.5,2.9c0.8,0.3,1.4,0.3,1.8,0.1c0.4-0.2,0.8-0.7,1.1-1.4c0.4-1.1,0.5-2.1,0.3-2.9
									l-1.2-0.5c-1-0.4-2.1-0.8-3.5-1.3c-2-0.7-3.5-1-4.6-0.9c0.1-0.6,0.2-1.2,0.4-1.8C725.2,502,725.4,501.5,725.8,500.8z"
						/>
						<path
							d="M721.5,516.4c1.3-0.5,3-0.3,5,0.4c2.1,0.7,3.5,1.7,4.3,2.9c0.8,1.2,0.9,2.7,0.3,4.3c-0.5,1.3-1.3,2.4-2.4,3.2
									c2,0.6,3.5,0.9,4.5,1c0,0.6,0,1.2-0.1,1.8s-0.2,1.1-0.5,1.7c-2.3-1.1-6.4-2.6-12.1-4.7c-0.7-0.2-1.2-0.3-1.7-0.3
									c-0.5,0-1,0.2-1.6,0.5c-0.3-0.9-0.4-1.8-0.2-2.7c0.6-0.4,1.2-0.6,2-0.6c-0.3-0.7-0.5-1.5-0.6-2.2c-0.1-0.8,0-1.5,0.3-2.1
									C719.2,517.9,720.1,516.9,721.5,516.4z M719.7,522.3c0.1,0.6,0.2,1.2,0.3,1.7c0.6,0.2,1.6,0.6,3,1.1c1.6,0.6,2.8,1.1,3.7,1.4
									c0.8-0.6,1.3-1.4,1.7-2.5c0.4-1,0.3-1.9-0.2-2.8c-0.5-0.8-1.5-1.4-2.8-1.9c-1.3-0.5-2.5-0.6-3.5-0.4c-1,0.2-1.7,0.8-2.2,1.7
									C719.7,521.2,719.7,521.8,719.7,522.3z"
						/>
						<path
							d="M715.3,531.5c0.4-0.1,1.1-0.1,2,0.2c0.7,0.2,1.8,0.5,3.1,1c1.2,0.4,2.2,0.7,2.8,0.9c0.8,0.2,1.6,0.4,2.3,0.5
									c0.7,0.1,1.2,0.1,1.6,0c0,0.6,0,1.2,0,1.8c-0.1,0.5-0.2,1.1-0.4,1.8c-0.5-0.3-1.4-0.6-2.6-1c-1.2-0.4-2.5-0.8-4-1.3
									c-1.1-0.3-1.9-0.5-2.5-0.6c-0.6-0.1-1.1-0.1-1.5-0.1c-0.4,0.1-0.8,0.2-1.2,0.4c-0.3-0.4-0.4-0.9-0.5-1.3c-0.1-0.4-0.1-0.8,0-1.1
									C714.6,532,714.9,531.7,715.3,531.5z M731.3,535.2c0.5,0.8,1,1.6,1.3,2.4c-0.8,0.4-1.6,0.8-2.4,1.3c-0.5-0.8-0.9-1.6-1.3-2.4
									C729.8,536.1,730.6,535.7,731.3,535.2z"
						/>
						<path
							d="M712.6,539.7c2,0.6,4.1,1.1,6.3,1.6c1.1,0.2,1.9,0.4,2.5,0.4c0.6,0.1,1.1,0,1.5,0c0.4-0.1,0.8-0.3,1.2-0.5
									c0.3,0.4,0.5,0.8,0.6,1.2s0.1,0.8,0,1.1c-0.2,1.1-1,1.6-2.2,1.5c0.5,0.7,0.8,1.4,1,2.2c0.2,0.8,0.2,1.5,0,2.3
									c-0.4,1.7-1.3,2.7-2.9,2.8c0.6,0.7,1,1.4,1.2,2.3c0.2,0.8,0.2,1.7,0.1,2.4c-0.5,2.4-2.2,3.3-4.9,2.7l-1.9-0.5
									c-0.7-0.2-1.4-0.4-2-0.5c-2.1-0.4-3.6-0.6-4.6-0.3c0-0.6,0.1-1.2,0.2-1.7c0.1-0.5,0.3-1.1,0.6-1.8c2.4,0.7,5,1.3,7.8,1.9
									c0.8,0.2,1.4,0.1,1.8-0.1c0.4-0.3,0.7-0.8,0.9-1.5c0.2-1,0.2-2-0.1-2.9c-0.7-0.1-1.8-0.4-3.3-0.7c-0.4-0.1-1-0.2-1.6-0.4
									c-2.1-0.5-3.6-0.6-4.6-0.4c0-0.6,0.1-1.1,0.2-1.6c0.1-0.5,0.3-1.1,0.6-1.8c2.4,0.7,5,1.3,7.8,1.9c0.8,0.2,1.4,0.1,1.8-0.1
									c0.4-0.3,0.7-0.8,0.9-1.5c0.2-1,0.2-2-0.1-2.8l-1.3-0.3c-1-0.3-2.2-0.6-3.6-0.9c-2.1-0.5-3.6-0.6-4.6-0.4c0-0.6,0.1-1.2,0.2-1.8
									C712.1,540.9,712.3,540.4,712.6,539.7z"
						/>
						<path
							d="M722.7,569.1c-2,0.8-4,1.1-6.1,0.8c-2-0.3-3.9-1.1-5.6-2.5c-1.7-1.4-3-3.1-4-5.3l0.4-0.3c1.1,1.5,2.4,2.7,4.1,3.6
									c1.6,0.9,3.5,1.5,5.5,1.8c2,0.3,3.9,0.2,5.8-0.2c1.8-0.4,3.5-1.2,4.9-2.3l0.3,0.4C726.5,566.9,724.7,568.2,722.7,569.1z"
						/>
					</g>
					<g className="level_zero location " id="hithaeglir">
						<rect x="3367.13" y="1497.859" width="218.634" height="32.953" transform="matrix(0.777543, 0.62883, -0.638195, 0.769969, -283.682129, -3026.137207)"></rect>
						<rect x="2638.327" y="1497.86" width="171.311" height="32.953" transform="matrix(0.361577, 0.932342, -0.936697, 0.350348, 2001.686523, -2603.968994)"></rect>
						<rect x="1007.683" y="1497.864" width="65.431" height="32.953" transform="matrix(-0.089039, 0.996028, -0.994956, -0.101036, 3294.088135, -232.560944)"></rect>
						<rect x="1007.683" y="1497.865" width="65.431" height="32.953" transform="matrix(-0.35663, 0.934246, -0.92995, -0.367883, 3460.419922, 291.660126)"></rect>
						<rect x="1504.846" y="1497.865" width="97.713" height="32.953" transform="matrix(-0.35663, 0.934246, -0.92995, -0.367883, 3589.333984, -36.504635)"></rect>
						<path
							d="M1365.6,267.6c0.8-0.9,1.8-2.1,3-3.7c1.2-1.6,2.4-3.2,3.5-4.6c2.5-3.5,3.9-5.7,4.1-6.8c0.6,0.3,1.2,0.6,1.7,1
									c0.5,0.4,1,0.8,1.6,1.4c-1.3,1.5-3,3.6-5,6.3c1,0.8,2.3,1.9,4,3.1c0.3,0.2,1.1,0.7,2.5,1.6c2.5-3.5,3.9-5.8,4.3-6.9
									c0.6,0.3,1.2,0.6,1.7,1c0.5,0.3,1,0.8,1.6,1.4c-0.8,0.9-1.8,2.1-3,3.7c-1.2,1.6-2.4,3.2-3.5,4.6c-2.5,3.4-3.8,5.7-4.1,6.7
									c-0.6-0.3-1.2-0.6-1.7-1c-0.5-0.3-1-0.8-1.5-1.4c1.3-1.5,3-3.6,5-6.4c-1.2-0.9-2.4-1.8-3.7-2.8c-0.9-0.6-1.8-1.3-2.8-1.9
									c-2.5,3.5-4,5.9-4.3,7c-0.6-0.3-1.2-0.6-1.8-1C1366.6,268.6,1366.1,268.1,1365.6,267.6z"
						/>
						<path
							d="M1399.6,292.4c0.8-0.8,1.8-2.1,3.1-3.7c1.3-1.6,2.5-3.1,3.6-4.6c2.6-3.4,4-5.6,4.2-6.7c0.6,0.3,1.2,0.6,1.7,1
									c0.5,0.4,1,0.9,1.6,1.4c-0.8,0.8-1.8,2.1-3.1,3.6c-1.3,1.6-2.4,3.1-3.5,4.5c-2.6,3.4-4,5.7-4.3,6.7c-0.6-0.3-1.2-0.6-1.7-1
									C1400.6,293.4,1400.1,292.9,1399.6,292.4z"
						/>
						<path
							d="M1427.2,313.3c0.8-0.8,1.8-2,3-3.5c1.3-1.5,2.5-3.1,3.6-4.6c1.3-1.6,2.3-3,3.1-4.3c-2.3-1.8-3.9-2.8-4.8-3.1
									c0.2-0.5,0.5-0.9,0.8-1.3c0.2-0.3,0.6-0.7,1.1-1.2c0.7,0.6,1.6,1.5,2.9,2.5c1.2,1,2.4,2,3.6,2.9c2.7,2.1,4.5,3.3,5.3,3.5
									c-0.2,0.5-0.5,0.9-0.8,1.3c-0.2,0.3-0.6,0.7-1.1,1.2c-1-1-2.5-2.2-4.3-3.7c-1.9,2.3-3.6,4.3-4.9,6c-2.6,3.4-4.1,5.6-4.4,6.6
									c-0.7-0.3-1.2-0.7-1.7-1S1427.7,313.9,1427.2,313.3z"
						/>
						<path
							d="M1454.4,334.6c0.8-0.8,1.9-2,3.3-3.5c1.4-1.5,2.6-3,3.8-4.4c2.7-3.3,4.2-5.5,4.5-6.5c0.6,0.3,1.2,0.7,1.6,1.1
									c0.5,0.4,1,0.9,1.5,1.5c-1.4,1.4-3.2,3.4-5.4,6c0.9,0.9,2.2,2,3.8,3.3c0.2,0.2,1,0.8,2.3,1.8c2.8-3.3,4.3-5.6,4.7-6.6
									c0.6,0.3,1.1,0.7,1.6,1.1c0.5,0.4,1,0.9,1.5,1.5c-0.8,0.8-1.9,2-3.3,3.5c-1.4,1.5-2.6,3-3.8,4.4c-2.7,3.3-4.2,5.4-4.6,6.4
									c-0.6-0.3-1.1-0.7-1.7-1.1c-0.5-0.4-0.9-0.9-1.4-1.5c1.4-1.4,3.2-3.4,5.4-6c-1.1-1-2.3-2-3.5-3c-0.8-0.7-1.7-1.4-2.6-2.1
									c-2.8,3.4-4.4,5.6-4.7,6.7c-0.6-0.3-1.1-0.7-1.7-1.1C1455.4,335.7,1454.9,335.2,1454.4,334.6z"
						/>
						<path
							d="M1494.6,369.9l1.9-4.5c-0.8-0.8-1.8-1.8-3-3c-1.3-1.2-2.2-2-2.8-2.3c-1.3,0.6-2.7,1.4-4.3,2.2c-0.4-0.2-0.7-0.4-1-0.7
									c-0.4-0.3-0.7-0.7-0.9-1.2c1.3-0.4,4-1.6,8.2-3.6c4.2-2,7.4-3.6,9.7-4.9c0.6,0.4,1,0.7,1.3,0.9c0.3,0.3,0.6,0.7,0.9,1.1
									c-1.1,2.4-2.4,5.7-4.2,10c-1.7,4.3-2.7,7.2-3,8.5c-0.5-0.3-1.1-0.7-1.6-1.1C1495.4,371,1495,370.5,1494.6,369.9z M1497.3,363.3
									c1.3-3.1,2.3-5.7,3-7.8c-2,0.8-4.5,2-7.5,3.6c0.8,0.9,1.8,1.8,2.9,2.9C1496.5,362.6,1497,363.1,1497.3,363.3z"
						/>
						<path
							d="M1519.2,386.5c1-0.8,2.2-1.9,3.8-3.4c2.8-2.6,4.7-4.5,5.5-5.8c0.7,1,2,2.5,3.7,4.4c2.1,2.4,3.9,4.1,5.2,5.3
									c-0.5,0.4-1,0.8-1.6,1.2c-0.6,0.4-1.1,0.6-1.5,0.6c-0.2-0.6-0.5-1.3-1-2.1c-0.5-0.8-1.1-1.5-1.7-2.2c-0.9-0.9-1.7-1.7-2.6-2.4
									c-1.5,1.2-2.8,2.4-4,3.4l0.6,0.7c0.6,0.7,1,1.2,1.3,1.6c0.8,0.8,1.4,1.5,1.8,1.9c0.5,0.4,1,0.8,1.6,1.3c-0.3,0.4-0.6,0.7-0.9,1
									c-0.2,0.2-0.6,0.5-1.1,0.8c-1.7-2.1-3-3.6-4-4.7c-0.5-0.5-0.8-0.9-1-1.1c-1.9,1.7-3.2,2.9-3.9,3.7c0.6,1,1.4,2,2.3,3
									c0.7,0.8,1.5,1.5,2.3,2.2c0.8,0.7,1.6,1.1,2.3,1.4c-0.5,0.4-1,0.8-1.7,1.2s-1.1,0.6-1.5,0.6c-0.9-1.2-2.3-2.9-4.3-5
									c-1.7-1.9-3.1-3.3-4-4.1C1516.9,388.5,1518.3,387.3,1519.2,386.5z"
						/>
						<path
							d="M1548.2,430.3c0.6-2,2.2-3.7,4.6-5.1c1.7-1,3.5-1.5,5.2-1.6c1.7-0.1,3.4,0.3,4.8,1.1c1.5,0.8,2.7,2.1,3.6,3.7
									c1.1,1.9,1.8,3.6,2.1,5.3c-0.4,0.2-1,0.4-1.6,0.6c-0.7,0.2-1.2,0.2-1.7,0.2c0.1-1.3,0-2.5-0.2-3.8c-0.2-1.3-0.5-2.2-0.8-2.9
									c-0.6-0.6-1.3-1-2.3-1.2c-1-0.2-2.1-0.2-3.2,0c-1.1,0.2-2.2,0.6-3.2,1.1c-2.3,1.3-3.7,2.7-4.2,4.1s-0.2,3.1,0.9,5
									c0.5,0.9,1,1.6,1.6,2.2c3.3-1.9,5.2-3.2,5.8-4c0.4,0.5,0.8,1,1.1,1.5c0.3,0.5,0.6,1.1,0.8,1.9c-2.2,1-4.3,2.1-6.5,3.4
									c-1-0.3-2-0.9-3.1-1.9c-1-0.9-1.9-1.9-2.5-3.1C1547.9,434.4,1547.6,432.2,1548.2,430.3z"
						/>
						<path
							d="M1569.9,464.8c1-0.4,2.5-0.9,4.4-1.6c4-1.6,6.4-2.7,7.1-3.4c0.4,0.6,0.7,1.1,0.9,1.8c0.2,0.6,0.4,1.2,0.6,2
									c-1.2,0.3-2.8,0.9-4.7,1.5c-1.9,0.7-3.7,1.3-5.2,1.9c-2.3,0.9-3.8,1.5-4.7,2c0.2,1.1,0.5,2.3,1.1,3.6c0.3,0.8,0.7,1.6,1.2,2.3
									s0.9,1.3,1.4,1.7c-0.7,0.3-1.3,0.4-2.1,0.5c-0.7,0.1-1.3,0.1-1.6,0c-0.4-1.5-1-3.3-1.8-5.2c-0.9-2.4-1.7-4.1-2.3-5.2
									C1567,465.8,1568.9,465.2,1569.9,464.8z"
						/>
						<path
							d="M1576.2,501.5c1.1-0.2,2.7-0.5,4.7-1c2-0.5,3.9-1,5.6-1.4c4.1-1.1,6.6-2,7.4-2.7c0.3,0.6,0.6,1.2,0.7,1.8
									c0.2,0.6,0.3,1.3,0.4,2.1c-1.1,0.2-2.7,0.5-4.6,1c-2,0.5-3.8,0.9-5.6,1.4c-4.1,1.1-6.6,2-7.5,2.7c-0.3-0.6-0.6-1.2-0.7-1.9
									C1576.4,502.9,1576.3,502.2,1576.2,501.5z"
						/>
						<path
							d="M1583.1,531.3c1.1-0.1,2.8-0.2,4.9-0.5c2.1-0.2,4-0.5,5.6-0.8c3.8-0.6,6.1-1.2,6.9-1.7c0.4,0.7,0.8,1.6,1.1,2.8
									c0.4,1.2,0.6,2.4,0.8,3.6c0.4,2.4,0.1,4.3-0.7,5.7c-0.9,1.4-2.3,2.3-4.2,2.6c-1.2,0.2-2.3,0-3.3-0.5c-1-0.5-1.9-1.3-2.6-2.3
									c-3.2,2.4-5.1,4.2-5.8,5.4c-0.4-0.6-0.7-1.2-0.9-1.9c-0.2-0.7-0.4-1.4-0.5-2.2c1.2-0.7,3.1-2.1,5.9-4.3c0-0.1-0.1-0.3-0.1-0.6
									c-0.1-0.5-0.1-1-0.2-1.6c0-0.6,0-1,0-1.3c-3.3,0.5-5.5,1.1-6.4,1.6c-0.2-0.6-0.4-1.3-0.5-2
									C1583.2,532.7,1583.1,532.1,1583.1,531.3z M1594.2,539.3c0.8,0.5,1.7,0.6,2.6,0.5c2.7-0.4,3.9-2.2,3.4-5.4
									c-0.1-0.7-0.3-1.3-0.5-1.9c-1.7,0.1-4.1,0.4-7.1,0.9c-0.3,1.7-0.4,3.1-0.3,4C1592.8,538.1,1593.4,538.8,1594.2,539.3z"
						/>
						<path
							d="M1708.2,627.1l-0.4,0.5c-1.8-1.1-3.9-1.8-6.1-2.1c-2.3-0.3-4.6-0.2-7,0.3c-2.4,0.5-4.7,1.3-6.6,2.4
									c-2,1.1-3.6,2.5-4.9,4.3l-0.6-0.3c1.2-2.4,2.8-4.4,4.8-6c2-1.6,4.2-2.7,6.7-3.2c2.5-0.5,5-0.4,7.4,0.4
									C1704,624,1706.2,625.3,1708.2,627.1z"
						/>
						<path
							d="M1685.4,641.4c2.7,0.1,6.5,0,11.4-0.2c2.6-0.1,4.5-0.2,5.7-0.4c1.3-0.1,2.2-0.3,2.7-0.5c0.2,0.6,0.3,1.2,0.3,1.8
									c0,0.5,0,1.2-0.1,2c-1.8,0.8-4.2,1.8-7.2,3.3c-3.1,1.4-5.5,2.6-7.3,3.5c2,0.8,4.5,1.7,7.5,2.8c3,1.1,5.5,2,7.6,2.7
									c0.2,0.4,0.3,1.1,0.3,1.9c0,0.6,0,1.2-0.1,1.9c-1.2,0-2.9,0-5.1,0c-2.2,0-4.2,0.1-6.2,0.2c-4.7,0.2-7.5,0.5-8.5,0.9
									c-0.2-0.6-0.3-1.2-0.3-1.8c0-0.6,0-1.3,0.1-2c1.3,0.1,3,0.1,5.2,0c2.2,0,4.2-0.1,6.2-0.2c1.3-0.1,2.7-0.1,4.3-0.3
									c-4-1.6-9.3-3.5-15.9-5.8c-0.1-0.3-0.2-0.5-0.2-0.7c0-0.2,0-0.4,0.2-0.7c1.6-0.7,3.8-1.7,6.7-3c2.9-1.3,5.4-2.6,7.7-3.7
									c-2.6,0.1-4.6,0.1-6.1,0.2c-4.5,0.2-7.4,0.4-8.5,0.8c-0.2-0.4-0.3-0.8-0.3-1.2C1685.3,642.4,1685.3,641.9,1685.4,641.4z"
						/>
						<path
							d="M1685.6,672.3c0.5-0.2,1.3-0.2,2.4-0.1c0.9,0.1,2.2,0.3,3.9,0.6c1.6,0.2,2.8,0.4,3.5,0.5c1,0.1,2,0.2,2.8,0.2
									c0.9,0,1.5-0.1,1.9-0.3c0.2,0.7,0.3,1.3,0.4,1.9c0,0.6,0,1.2-0.1,2c-0.7-0.2-1.8-0.4-3.3-0.7c-1.5-0.3-3.2-0.5-5.1-0.8
									c-1.4-0.2-2.4-0.3-3.2-0.3c-0.7,0-1.3,0-1.8,0.2s-0.9,0.3-1.4,0.7c-0.4-0.4-0.7-0.9-0.9-1.3c-0.2-0.5-0.2-0.9-0.2-1.2
									C1684.8,672.9,1685.1,672.5,1685.6,672.3z M1705.7,673.7c0.8,0.8,1.5,1.6,2.1,2.3c-0.9,0.5-1.8,1.1-2.6,1.8
									c-0.8-0.7-1.5-1.5-2.1-2.4C1704,675,1704.9,674.4,1705.7,673.7z"
						/>
						<path
							d="M1684.1,684.8c0.5,0.1,0.9,0.3,1.3,0.5c0.4,0.2,0.8,0.5,1.2,0.8c-0.7,0.3-1.3,0.7-1.9,1.3c-0.5,0.6-0.9,1.3-1.1,2.1
									c-0.5,2.1-0.2,3.3,1.1,3.6c0.5,0.1,1,0,1.5-0.4c0.5-0.4,1.1-1,1.8-1.8c0.7-0.8,1.4-1.5,1.9-2c0.5-0.5,1.2-0.9,2-1.1
									c0.8-0.3,1.6-0.3,2.6,0c1.1,0.3,2,1,2.4,2.1c0.5,1.1,0.5,2.4,0.1,3.8c-0.5,2-1.2,3.5-2.2,4.8c-0.4-0.1-0.9-0.3-1.5-0.7
									c-0.6-0.4-1.1-0.8-1.5-1.2c1-0.6,1.8-1.2,2.5-1.8c0.7-0.6,1.1-1.2,1.2-1.7c0-0.6-0.1-1-0.3-1.5c-0.2-0.4-0.6-0.7-1-0.8
									c-0.7-0.2-1.3-0.1-1.8,0.3c-0.5,0.4-1.2,1.1-2.1,2c-0.7,0.8-1.3,1.4-1.8,1.9c-0.5,0.5-1.2,0.8-1.9,1.1c-0.7,0.2-1.5,0.3-2.4,0
									c-1.4-0.4-2.3-1.1-2.8-2.3c-0.5-1.2-0.5-2.7-0.1-4.5C1682.1,687.4,1682.9,685.8,1684.1,684.8z"
						/>
						<path
							d="M1677.4,705.9c0.5-0.2,1.3-0.1,2.4,0.3c0.7,0.2,1.9,0.7,3.6,1.3c1.8,0.7,3.3,1.2,4.5,1.6c0.6,0.2,1.1,0.4,1.7,0.5
									c0.3-0.9,0.5-1.4,0.8-1.7c0.2-0.2,0.5-0.3,0.8-0.2c0.4,1.7,1.2,3.4,2.3,5.2l-0.4,0.7c-0.8-0.2-1.7-0.4-2.6-0.7
									c-0.5,1.4-0.7,2.5-0.8,3.3c-0.5-0.2-0.9-0.4-1.3-0.6s-0.7-0.5-1-0.8c0.5-0.5,0.9-1.4,1.3-2.5c-1.1-0.4-2.2-0.8-3.3-1.2
									c-2.9-1-5.2-1.6-6.9-1.8c-0.3,1-0.4,1.9-0.4,2.5l-0.9-0.1c-0.5-0.6-0.9-1.3-1.1-2.2c-0.2-0.8-0.1-1.6,0.1-2.3
									C1676.5,706.5,1676.8,706,1677.4,705.9z"
						/>
						<path
							d="M1667.2,718c2.1,0.8,3.6,2.4,4.8,4.8c1.1-0.9,2.8-0.9,5-0.1l1.7,0.7l2.1,0.8c2.4,0.9,4.1,1.3,5.2,1.3c0,0.7,0,1.3-0.1,1.9
									c-0.1,0.5-0.3,1.2-0.5,1.9c-1.3-0.6-4.4-1.9-9.2-3.7c-0.9-0.4-1.7-0.4-2.2-0.2c-0.5,0.2-1,0.7-1.2,1.5c-0.5,1.2-0.5,2.4-0.2,3.5
									l4.2,1.5c0.4,0.1,0.8,0.3,1.2,0.4c1.1,0.4,2.1,0.7,3,0.9c0.9,0.2,1.6,0.3,2.1,0.3c0.1,1.2-0.1,2.5-0.6,3.8c-1.9-0.9-4.8-2-8.8-3.5
									c-1.6-0.6-3-1.1-4.1-1.4c-1.1-0.4-1.7-0.6-1.9-0.6c-2.1-0.8-3.5-2-4.1-3.6c-0.6-1.6-0.6-3.4,0.2-5.4
									C1664.7,720.5,1665.8,718.9,1667.2,718z M1665.8,726.7c0.6,0.9,1.8,1.7,3.8,2.5l1.7,0.6c-0.5-0.9-0.8-1.8-0.9-2.7
									c-0.1-0.9,0-1.8,0.3-2.6c0.1-0.2,0.2-0.5,0.4-0.9c-0.8-1.3-1.7-2.2-2.8-2.8c-0.6,0.2-1.1,0.6-1.6,1c-0.5,0.5-0.9,1-1.1,1.7
									C1665.2,724.8,1665.3,725.8,1665.8,726.7z"
						/>
						<path
							d="M1635.7,811.6c2.4,1.2,5.9,2.7,10.4,4.6c2.4,1,4.2,1.7,5.4,2.1c1.2,0.4,2.1,0.7,2.7,0.7c-0.1,0.6-0.3,1.2-0.5,1.8
									c-0.2,0.5-0.5,1-1,1.7c-1.9-0.1-4.6-0.1-7.9-0.1c-3.4,0-6.1,0-8.1,0.1c1.5,1.5,3.3,3.4,5.6,5.7c2.3,2.2,4.2,4.1,5.7,5.6
									c0,0.5-0.2,1.1-0.5,1.8c-0.2,0.5-0.5,1.1-0.9,1.7c-1.1-0.6-2.6-1.3-4.6-2.2s-3.9-1.7-5.7-2.5c-4.3-1.8-7-2.7-8.1-2.8
									c0.1-0.6,0.3-1.2,0.5-1.8c0.2-0.5,0.6-1.1,1-1.8c1.1,0.6,2.7,1.3,4.6,2.2s3.9,1.7,5.7,2.5c1.2,0.5,2.5,1,4,1.6
									c-2.9-3.1-6.9-7.1-11.9-12c0-0.3,0-0.6,0.1-0.8c0.1-0.2,0.2-0.4,0.4-0.6c1.7,0.1,4.2,0.1,7.3,0.1c3.2,0,6,0,8.5-0.1
									c-2.4-1-4.3-1.8-5.6-2.4c-4.2-1.7-6.9-2.7-8.1-3c0-0.4,0.1-0.9,0.3-1.2C1635.1,812.4,1635.3,812,1635.7,811.6z"
						/>
						<path
							d="M1623.9,840.6c0.5-0.1,1.3,0,2.3,0.4c0.7,0.3,1.9,0.8,3.5,1.5c1.8,0.8,3.2,1.4,4.4,1.9c0.6,0.2,1.1,0.4,1.6,0.6
									c0.3-0.8,0.6-1.4,0.9-1.6c0.2-0.2,0.5-0.3,0.9-0.1c0.3,1.7,0.9,3.5,1.9,5.3l-0.4,0.7c-0.8-0.2-1.7-0.5-2.5-0.8
									c-0.6,1.4-0.9,2.5-1,3.2c-0.5-0.2-0.9-0.4-1.2-0.7c-0.3-0.2-0.7-0.5-1-0.9c0.5-0.5,1-1.3,1.5-2.4c-1-0.5-2.1-1-3.2-1.4
									c-2.9-1.2-5.1-1.9-6.7-2.2c-0.3,1-0.5,1.8-0.6,2.5l-0.9-0.1c-0.5-0.6-0.8-1.4-0.9-2.2c-0.1-0.8,0-1.6,0.2-2.3
									C1623,841.1,1623.4,840.7,1623.9,840.6z"
						/>
						<path
							d="M1617.7,855.1c2.3,1,4.8,1.9,7.5,2.9c1.3,0.5,2.3,0.8,3,0.9c0.7,0.2,1.3,0.2,1.8,0.2c0.5,0,1-0.1,1.5-0.3
									c0.3,0.5,0.5,1,0.6,1.4c0.1,0.5,0,0.8-0.1,1.2c-0.4,1.1-1.3,1.5-2.8,1.2c0.5,0.8,0.8,1.7,1,2.6c0.1,0.9,0.1,1.7-0.2,2.5
									c-0.9,2.5-3,3.2-6.2,2l-2.2-0.9c-0.9-0.4-1.6-0.7-2.4-0.9c-2.4-0.9-4.3-1.3-5.6-1.2c0.1-0.6,0.2-1.2,0.4-1.8
									c0.2-0.6,0.5-1.2,0.9-1.8c2.8,1.2,5.9,2.3,9.2,3.5c0.9,0.3,1.7,0.4,2.2,0.2c0.5-0.2,0.9-0.7,1.2-1.5c0.4-1.2,0.5-2.2,0.2-3.2
									l-1.5-0.6c-1.2-0.5-2.6-1-4.3-1.6c-2.5-0.9-4.3-1.3-5.6-1.2c0.1-0.7,0.2-1.3,0.4-1.9C1617,856.4,1617.3,855.8,1617.7,855.1z"
						/>
						<path
							d="M1611.7,877.3c0.5,0.1,0.9,0.2,1.3,0.4c0.4,0.2,0.8,0.4,1.3,0.8c-0.7,0.3-1.3,0.7-1.8,1.4c-0.5,0.6-0.8,1.3-1,2.1
									c-0.5,2.1,0,3.3,1.3,3.6c0.5,0.1,1,0,1.5-0.4c0.5-0.4,1.1-1,1.7-1.9c0.7-0.9,1.3-1.6,1.8-2.1c0.5-0.5,1.2-0.9,1.9-1.2
									c0.8-0.3,1.6-0.3,2.6-0.1c1.2,0.3,2,0.9,2.5,2c0.5,1.1,0.6,2.4,0.3,3.8c-0.4,2-1.1,3.6-2,4.9c-0.4-0.1-0.9-0.3-1.5-0.6
									c-0.6-0.3-1.1-0.7-1.6-1.1c1-0.6,1.8-1.3,2.4-1.9c0.6-0.6,1-1.2,1.1-1.7c0-0.6-0.1-1-0.4-1.4s-0.6-0.7-1-0.7
									c-0.7-0.2-1.3,0-1.8,0.4c-0.5,0.4-1.2,1.1-2,2.1c-0.6,0.8-1.2,1.5-1.7,2c-0.5,0.5-1.1,0.9-1.8,1.2c-0.7,0.3-1.5,0.3-2.4,0.1
									c-1.4-0.3-2.3-1-2.9-2.2c-0.5-1.2-0.6-2.6-0.2-4.5C1609.8,879.9,1610.6,878.3,1611.7,877.3z"
						/>
						<path
							d="M1609.1,896.6c0.9,0.8,1.7,1.8,2.4,2.8c-0.9,0.5-1.9,1.2-3.1,2c-1-1-1.7-1.9-2.3-2.8
									C1607.2,898.1,1608.2,897.4,1609.1,896.6z"
						/>
						<path
							d="M1623.7,916.6c-2.4,1-4.8,1.4-7.4,1.2c-2.5-0.2-4.9-1-7-2.4c-2.2-1.4-4-3.2-5.4-5.5l0.5-0.3c1.5,1.6,3.2,2.8,5.3,3.7
									c2.1,0.9,4.4,1.5,6.8,1.7c2.5,0.2,4.8,0.1,7-0.5c2.2-0.5,4.2-1.5,5.9-2.7l0.5,0.4C1628.2,914.1,1626.1,915.6,1623.7,916.6z"
						/>
					</g>
					<g className="level_two fade location" id="carn_dum">
						<rect x="2595.888" y="1479.939" width="168.556" height="32.56" transform="matrix(0.998992, -0.044895, 0.032865, 0.999532, -1522.911133, -1152.645386)"></rect>
						<path
							d="M1123,233.5c-1.3-1.8-1.8-4.2-1.6-7.4c0.2-2.2,0.7-4.1,1.6-5.8c0.9-1.7,2.1-2.9,3.6-3.8c1.5-0.9,3.2-1.2,5-1.1
									c2,0.1,3.7,0.6,5.1,1.3c0,0.6-0.2,1.2-0.4,1.9c-0.2,0.7-0.5,1.3-0.8,1.7c-0.9-0.8-2-1.5-3.1-2.1s-2-0.9-2.6-1
									c-0.8,0.2-1.6,0.6-2.3,1.4c-0.7,0.8-1.4,1.8-1.8,2.9c-0.5,1.2-0.8,2.4-0.9,3.7c-0.2,2.6,0.2,4.6,1.2,6c1,1.4,2.3,2.1,4,2.2
									c2.4,0.2,4.3-0.2,5.9-1l0.2,1c-0.9,1-2,1.7-3.3,2.3c-1.3,0.5-2.7,0.8-4,0.7C1126.3,236.3,1124.3,235.3,1123,233.5z"
						/>
						<path
							d="M1145.3,236.4c-0.6-0.7-0.8-1.6-0.8-2.6c0.1-3.1,3-4.8,8.6-5c0.1-1.7-0.1-2.9-0.6-3.6c-0.4-0.7-1.3-1.1-2.5-1.1
									c-0.7,0-1.5,0.1-2.2,0.3c-0.8,0.2-1.5,0.6-2.1,1l-0.3-0.7c0.7-1.1,1.6-2,2.7-2.7s2.2-1,3.4-1c1.5,0.1,2.7,0.5,3.5,1.5
									c0.8,0.9,1.2,2.2,1.1,3.9c0,0.9-0.1,2-0.3,3.1c-0.1,1.2-0.2,2.2-0.2,2.8c0,1,0,1.8,0.2,2.3c0.2,0.6,0.5,1.2,1,1.8
									c-0.9,0.8-1.9,1.3-2.9,1.4c-0.6-0.5-1.1-1.2-1.3-2.2c-0.9,0.6-1.8,1.1-2.7,1.5s-1.8,0.5-2.6,0.5
									C1146.5,237.5,1145.8,237.1,1145.3,236.4z M1150.8,235.2c0.7-0.3,1.3-0.6,1.8-1.1l0-0.2c0-0.8,0.1-1.6,0.2-2.4l0.2-1.7
									c-1.6,0.1-2.9,0.4-3.8,1c-0.9,0.6-1.4,1.4-1.4,2.5c0,0.4,0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.8,0.8,1
									C1149.5,235.7,1150.1,235.5,1150.8,235.2z"
						/>
						<path
							d="M1168,237.5c0.2-2.6,0.2-5.3,0.2-8.3c0-1.4,0-2.5-0.1-3.3c-0.1-0.8-0.2-1.3-0.4-1.8c-0.2-0.4-0.5-0.9-0.9-1.4
									c0.4-0.5,0.8-0.8,1.2-1.1c0.5-0.2,0.9-0.4,1.2-0.4c1.4,0,2.1,1,2.1,2.9c0.6-0.8,1.3-1.5,2-2c0.7-0.5,1.5-0.9,2.4-1.2
									c0.2,0.4,0.3,1,0.4,1.8s0.2,1.5,0.2,2.1c-0.3-0.1-0.7-0.1-1.2-0.1c-0.8,0-1.5,0.1-2.2,0.3c-0.7,0.2-1.2,0.4-1.7,0.7l0,1.2
									c0,1.7-0.1,3.2-0.1,4.5c0,2.7,0.2,4.7,0.7,5.8c-0.7,0.2-1.4,0.2-2,0.2C1169.5,237.7,1168.8,237.7,1168,237.5z"
						/>
						<path
							d="M1186.3,237.3c0.1-2.6,0-5.4-0.1-8.3c-0.1-1.4-0.1-2.5-0.2-3.3c-0.1-0.7-0.3-1.3-0.5-1.8c-0.2-0.4-0.5-0.9-0.9-1.3
									c0.4-0.5,0.8-0.9,1.2-1.1c0.4-0.2,0.8-0.4,1.2-0.4c1.3,0,2,0.7,2.3,2.2c0.6-0.8,1.3-1.4,2.1-1.9c0.8-0.5,1.7-0.7,2.6-0.8
									c2.8-0.1,4.2,1.6,4.4,5.2l0,2.4c0,1,0,1.8,0,2.6c0.1,2.7,0.4,4.6,1,5.8c-0.7,0.2-1.3,0.3-1.9,0.3c-0.6,0-1.3,0-2.1-0.2
									c0-3.1,0-6.5-0.1-10.2c0-1-0.3-1.8-0.7-2.2c-0.4-0.4-1-0.6-1.9-0.6c-1.3,0.1-2.4,0.4-3.2,1l0,1.7c0,1.3,0,2.9,0.1,4.7
									c0.1,2.7,0.4,4.6,0.9,5.8c-0.7,0.2-1.4,0.3-2,0.3C1187.8,237.5,1187.1,237.4,1186.3,237.3z"
						/>
						<path
							d="M1224.5,235c-1.1,0-2.1-0.1-2.9-0.2c0-1.2-0.1-3-0.2-5.1c-0.2-2.2-0.3-4.2-0.5-6.1c-0.4-4.3-0.9-6.9-1.4-7.9
									c0.7-0.4,1.7-0.7,3-1.1c1.2-0.3,2.5-0.6,3.8-0.7c3.1-0.3,5.7,0.3,7.5,1.9c1.9,1.6,3,4.1,3.3,7.4c0.2,2.1,0,3.9-0.7,5.6
									c-0.7,1.7-1.8,3-3.3,4.1c-1.5,1.1-3.3,1.7-5.3,1.9C1226.7,235,1225.6,235,1224.5,235z M1231.7,231c0.7-0.9,1.3-1.9,1.7-3.1
									c0.4-1.2,0.5-2.4,0.4-3.6c-0.6-5.6-3.3-8.2-8-7.7c-0.8,0.1-1.5,0.3-2.2,0.6c0.1,2.9,0.4,5.9,0.7,9c0.3,2.5,0.5,4.6,0.8,6.2
									c1.8,0.3,3.2,0.5,4.1,0.4C1230.1,232.5,1231,231.9,1231.7,231z"
						/>
						<path
							d="M1244.4,227.8l-0.3-2.4l-0.3-2.6c-0.4-2.5-0.8-4.3-1.4-5.3c1.1-0.7,2.3-1.1,3.8-1.3c0.3,3.1,0.7,6.7,1.3,10.8
									c0.1,1,0.5,1.7,0.9,2.2c0.5,0.4,1.1,0.6,2,0.4c1.2-0.2,2.2-0.6,3-1.3c-0.2-1.7-0.4-3.2-0.5-4.6l-0.3-2.1c-0.4-2.5-0.8-4.3-1.4-5.3
									c1.1-0.7,2.4-1.1,3.8-1.3c0.2,1.8,0.4,3.9,0.6,6.3c0.2,2.4,0.4,4.1,0.5,4.9c0.1,0.9,0.3,1.6,0.6,2.2c0.3,0.5,0.7,1,1.3,1.6
									c-0.7,0.9-1.6,1.5-2.6,1.8c-0.8-0.4-1.4-1.1-1.7-2.1c-0.5,0.8-1.2,1.5-2,2c-0.8,0.5-1.7,0.9-2.6,1
									C1246.5,232.9,1244.9,231.3,1244.4,227.8z M1244,213.9c1-1.8,1.8-3.5,2.5-5.1c0.8-0.5,1.9-0.8,3.1-1c0.8,1.1,1.5,2,1.9,2.6
									c0.5,0.6,1.1,1.2,1.9,1.9c-0.9,0.5-2,0.8-3.2,1l-2.5-3l-1.5,3.6C1245.5,214,1244.7,214,1244,213.9z"
						/>
						<path
							d="M1265.9,229.6c-0.3-2.6-0.7-5.3-1.3-8.2c-0.3-1.4-0.5-2.5-0.7-3.2c-0.2-0.7-0.5-1.3-0.7-1.7c-0.3-0.4-0.6-0.8-1.1-1.2
									c0.3-0.6,0.6-1,1-1.3c0.4-0.3,0.7-0.5,1.1-0.5c1.2-0.2,2.1,0.4,2.5,1.9c0.5-0.9,1.1-1.6,1.8-2.2c0.7-0.6,1.5-1,2.3-1.1
									c2-0.4,3.4,0.3,4.3,2.1c0.4-1,1.1-1.8,1.8-2.4c0.8-0.6,1.6-1,2.5-1.2c2.7-0.5,4.4,1,5.1,4.5l0.4,2.4c0.1,1,0.2,1.8,0.4,2.6
									c0.5,2.6,1.1,4.5,1.8,5.6c-0.6,0.3-1.2,0.5-1.8,0.6c-0.6,0.1-1.3,0.2-2.1,0.2c-0.4-3.1-0.9-6.5-1.6-10.1c-0.2-1-0.5-1.7-1-2.1
									c-0.5-0.4-1.1-0.5-2-0.3c-1.2,0.2-2.1,0.7-2.9,1.5c0.2,0.9,0.4,2.3,0.7,4.2c0.1,0.5,0.2,1.2,0.3,2.1c0.5,2.6,1.1,4.5,1.7,5.6
									c-0.6,0.3-1.2,0.5-1.8,0.6c-0.6,0.1-1.3,0.2-2.1,0.2c-0.4-3.1-0.9-6.5-1.6-10.1c-0.2-1-0.5-1.7-1-2.1c-0.5-0.4-1.1-0.5-2-0.3
									c-1.2,0.2-2.1,0.7-2.9,1.4l0.3,1.6c0.2,1.3,0.4,2.9,0.8,4.6c0.5,2.7,1.1,4.5,1.8,5.6c-0.7,0.3-1.3,0.5-2,0.6
									C1267.4,229.6,1266.8,229.6,1265.9,229.6z"
						/>
					</g>
					{/* <g className="level_two fade location" id="carn_dum">
								<path d="M1123.2,273.1c0.1-1.3,0.2-3,0.3-5.3c0.1-2.3,0.1-4.4,0.1-6.4c0-4.7-0.2-7.7-0.6-8.7c0.7-0.2,1.3-0.2,1.9-0.2
									c0.6,0,1.3,0.1,2.1,0.2c-0.2,2.3-0.3,5.2-0.4,8.9c1.3,0.1,3,0.2,5,0.2c0.3,0,1.3,0,2.9-0.1c0-4.8-0.2-7.8-0.6-9
									c0.6-0.2,1.3-0.2,1.9-0.2c0.6,0,1.3,0.1,2.1,0.2c-0.1,1.3-0.2,3-0.3,5.3c-0.1,2.3-0.1,4.4-0.1,6.4c0,4.7,0.2,7.6,0.6,8.7
									c-0.6,0.2-1.3,0.2-2,0.2c-0.6,0-1.3-0.1-2-0.2c0.2-2.2,0.3-5.2,0.4-9c-1.5-0.1-3-0.1-4.6-0.1c-1.1,0-2.2,0-3.3,0.1
									c0,4.8,0.2,7.9,0.6,9.1c-0.6,0.2-1.3,0.2-2,0.2C1124.7,273.3,1124,273.2,1123.2,273.1z"/>
								<path d="M1144.6,271.4c-1-1.4-1.5-3.3-1.5-5.8c0-1.7,0.3-3.2,0.9-4.6c0.6-1.3,1.5-2.4,2.6-3.1c1.1-0.8,2.4-1.1,3.8-1.1
									c1.9,0,3.3,0.7,4.3,2s1.5,3.4,1.5,6c-1.3,0.3-2.8,0.6-4.6,0.9c-1.8,0.2-3.5,0.4-5.3,0.4c0.1,1.6,0.5,2.7,1.2,3.4
									c0.7,0.7,1.6,1.1,2.9,1.1c1,0,1.9-0.1,2.8-0.4c0.9-0.2,1.6-0.6,2.2-1l0.2,0.7c-0.7,1-1.7,1.9-2.9,2.5c-1.2,0.6-2.5,1-3.8,1
									C1147.1,273.5,1145.6,272.8,1144.6,271.4z M1149.8,264.3c1.1-0.3,2-0.6,2.7-1c0-1.4-0.4-2.5-1.1-3.4c-0.7-0.9-1.6-1.3-2.7-1.3
									c-0.7,0.5-1.3,1.3-1.8,2.4c-0.4,1.1-0.7,2.4-0.7,3.9C1147.5,264.8,1148.7,264.6,1149.8,264.3z"/>
								<path d="M1162.5,273.1c0.2-2.6,0.2-5.3,0.2-8.3c0-1.4,0-2.5-0.1-3.3c-0.1-0.8-0.2-1.3-0.4-1.8c-0.2-0.4-0.5-0.9-0.9-1.4
									c0.4-0.5,0.8-0.8,1.2-1c0.5-0.2,0.9-0.3,1.2-0.3c1.4,0,2.1,1,2.1,2.9c0.6-0.8,1.3-1.5,2-2c0.7-0.5,1.5-0.9,2.4-1.2
									c0.2,0.4,0.3,1,0.4,1.8c0.1,0.8,0.2,1.5,0.2,2.1c-0.3-0.1-0.7-0.1-1.2-0.1c-0.8,0-1.5,0.1-2.2,0.3c-0.7,0.2-1.2,0.4-1.7,0.7l0,1.2
									c0,1.7-0.1,3.2-0.1,4.5c0,2.7,0.2,4.7,0.7,5.8c-0.7,0.2-1.4,0.2-2,0.2S1163.3,273.2,1162.5,273.1z"/>
								<path d="M1175.3,271.4c-1-1.4-1.5-3.3-1.5-5.8c0-1.7,0.3-3.2,0.9-4.6c0.6-1.3,1.5-2.4,2.6-3.1c1.1-0.8,2.4-1.1,3.8-1.1
									c1.9,0,3.3,0.7,4.3,2s1.5,3.4,1.5,6c-1.3,0.3-2.8,0.6-4.6,0.9c-1.8,0.2-3.5,0.4-5.3,0.4c0.1,1.6,0.5,2.7,1.2,3.4
									c0.7,0.7,1.6,1.1,2.9,1.1c1,0,1.9-0.1,2.8-0.4c0.9-0.2,1.6-0.6,2.2-1l0.2,0.7c-0.7,1-1.7,1.9-2.9,2.5c-1.2,0.6-2.5,1-3.8,1
									C1177.7,273.5,1176.3,272.8,1175.3,271.4z M1180.5,264.3c1.1-0.3,2-0.6,2.7-1c0-1.4-0.4-2.5-1.1-3.4c-0.7-0.9-1.6-1.3-2.7-1.3
									c-0.7,0.5-1.3,1.3-1.8,2.4c-0.4,1.1-0.7,2.4-0.7,3.9C1178.1,264.8,1179.3,264.6,1180.5,264.3z"/>
								<path d="M1204.2,270.2l-1.3-4.7c-0.3-1.1-0.5-2.1-0.8-3c-0.3-1.1-0.7-2.1-1.1-2.9c-0.4-0.8-0.7-1.4-1-1.8c0.6-0.3,1.2-0.5,1.9-0.6
									c0.6-0.1,1.4-0.2,2.2-0.2c0.2,1.1,0.7,3.5,1.7,7.1c0.9,3.6,1.6,5.7,1.9,6.4c1.8-5.7,2.8-9.2,3.1-10.6c-0.4-1-0.8-1.7-1.2-2.1
									c1.2-0.5,2.5-0.8,4.1-0.8c0.1,1.1,0.7,3.6,1.7,7.3c1,3.8,1.6,5.9,1.8,6.3c1.3-4.1,2.2-7,2.7-8.7c0.5-1.7,0.7-2.9,0.7-3.6
									c0-0.2,0-0.5-0.1-0.9c0.9-0.3,2-0.4,3.2-0.4c-0.7,1.6-1.5,3.8-2.4,6.4c-0.9,2.7-1.8,5.6-2.7,8.7l-1.9,1.2c-0.7,0-1.3-0.3-1.8-0.8
									c-0.5-0.5-0.9-1.3-1.2-2.4c-0.9-3.4-1.6-5.8-1.9-7.1c-0.8,2.3-1.7,5.3-2.8,9.1l-1.9,1.2C1205.8,273.4,1204.8,272.3,1204.2,270.2z"
									/>
								<path d="M1227.4,272.5c-0.6-0.7-0.9-1.6-0.9-2.6c0-3.1,2.8-4.9,8.4-5.3c0-1.7-0.2-2.9-0.7-3.6c-0.5-0.7-1.3-1-2.5-1
									c-0.7,0-1.5,0.1-2.2,0.4c-0.7,0.3-1.4,0.6-2.1,1l-0.3-0.7c0.6-1.1,1.5-2.1,2.6-2.8c1.1-0.7,2.2-1.1,3.4-1.1c1.5,0,2.7,0.4,3.6,1.3
									s1.2,2.2,1.2,3.9c0,0.9-0.1,2-0.2,3.1c-0.1,1.2-0.1,2.2-0.1,2.8c0,1,0.1,1.7,0.3,2.3s0.6,1.1,1.1,1.8c-0.9,0.8-1.8,1.3-2.9,1.5
									c-0.6-0.5-1.1-1.2-1.4-2.1c-0.9,0.6-1.7,1.2-2.7,1.6c-0.9,0.4-1.8,0.6-2.6,0.6C1228.7,273.5,1228,273.1,1227.4,272.5z
									M1232.9,271.1c0.7-0.3,1.3-0.7,1.8-1.1v-0.2c0-0.8,0-1.6,0.1-2.4l0.1-1.7c-1.6,0.1-2.9,0.5-3.7,1.1c-0.9,0.6-1.3,1.5-1.3,2.5
									c0,0.4,0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.8,0.9,1C1231.6,271.6,1232.2,271.4,1232.9,271.1z"/>
								<path d="M1243,272.1c0-0.5,0-1,0.1-1.4c0.1-0.4,0.3-0.9,0.5-1.4c0.5,0.6,1.1,1.2,1.8,1.5c0.8,0.4,1.6,0.5,2.4,0.5
									c2.3,0,3.4-0.7,3.4-2.1c0-0.6-0.3-1-0.8-1.4c-0.5-0.4-1.3-0.8-2.3-1.3c-1-0.5-1.9-1-2.5-1.4c-0.6-0.4-1.2-1-1.7-1.7
									c-0.5-0.7-0.7-1.6-0.7-2.6c0-1.2,0.5-2.2,1.5-3c1-0.8,2.3-1.1,3.9-1.1c2.1,0,3.9,0.3,5.4,0.9c0,0.4-0.1,1-0.3,1.7
									c-0.2,0.7-0.5,1.3-0.8,1.8c-0.9-0.9-1.7-1.5-2.5-2c-0.8-0.5-1.5-0.8-2-0.8c-0.6,0.1-1,0.4-1.4,0.7s-0.5,0.7-0.5,1.1
									c0,0.7,0.3,1.3,0.8,1.7c0.5,0.4,1.4,1,2.6,1.6c1,0.5,1.8,0.9,2.4,1.3c0.6,0.4,1.2,0.9,1.6,1.6c0.4,0.7,0.7,1.5,0.7,2.4
									c0,1.5-0.5,2.6-1.6,3.4c-1.1,0.8-2.6,1.2-4.5,1.2C1246.2,273.5,1244.4,273,1243,272.1z"/>
								<path d="M1270.2,271.3c-1.2-1.5-1.8-3.4-1.8-5.7c0-1.7,0.3-3.2,1-4.6c0.7-1.3,1.6-2.4,2.7-3.1c1.2-0.7,2.5-1.1,4-1.1
									c1.3,0,2.5,0.3,3.5,1c1,0.7,1.8,1.6,2.4,2.8c0.6,1.2,0.8,2.6,0.8,4.1c0,1.7-0.3,3.2-1,4.6c-0.7,1.3-1.6,2.4-2.8,3.1
									c-1.2,0.7-2.6,1.1-4.1,1.1C1273,273.5,1271.4,272.8,1270.2,271.3z M1278.8,270.4c0.8-1,1.1-2.4,1.1-4.2c0-1.6-0.3-3-1-4.2
									c-0.7-1.2-1.6-2-2.9-2.4c-1.4,0-2.5,0.5-3.3,1.5c-0.8,1-1.2,2.4-1.2,4.1c0,2,0.4,3.6,1.2,4.8c0.8,1.2,1.8,1.8,2.9,1.8
									C1277,271.9,1278,271.4,1278.8,270.4z"/>
								<path d="M1287.4,278.9c0.5-0.7,0.9-1.3,1.1-1.9c0.3-0.6,0.5-1.4,0.6-2.4s0.2-2.4,0.2-4.2c0-2-0.1-5.6-0.3-10.6
									c-0.8,0-1.4-0.1-1.6-0.2c-0.3-0.1-0.4-0.4-0.4-0.7c1-0.5,1.6-1,2-1.3c0.3-2.1,1-3.9,2.1-5.2c1.1-1.3,2.5-1.9,4.1-1.9
									c1.1,0,2.3,0.2,3.4,0.5c0,0.4-0.1,0.9-0.3,1.6c-0.2,0.7-0.5,1.3-0.7,1.9c-0.7-0.7-1.4-1.3-2.2-1.8c-0.8-0.5-1.4-0.7-1.9-0.6
									c-0.4,0.3-0.7,0.9-0.9,1.7c-0.2,0.8-0.3,2.2-0.3,4c1.8-0.1,3-0.2,3.7-0.3c0,0.5,0,1-0.1,1.4c-0.1,0.4-0.2,0.9-0.5,1.3
									c-0.8-0.3-1.8-0.4-3.2-0.5v2.7c0,1.6,0,3.4,0.1,5.4c0.1,1.1,0.1,2,0.1,2.8c0,1.3-0.1,2.4-0.4,3.3s-0.7,1.7-1.3,2.5
									c-0.6,0.8-1.6,1.7-2.9,2.8L1287.4,278.9z"/>
								<path d="M1311.5,271.3c-1.2-1.5-1.8-3.4-1.8-5.7c0-1.7,0.3-3.2,1-4.6c0.7-1.3,1.6-2.4,2.7-3.1c1.2-0.7,2.5-1.1,4-1.1
									c1.3,0,2.5,0.3,3.5,1c1,0.7,1.8,1.6,2.4,2.8c0.6,1.2,0.8,2.6,0.8,4.1c0,1.7-0.3,3.2-1,4.6c-0.7,1.3-1.6,2.4-2.8,3.1
									c-1.2,0.7-2.6,1.1-4.1,1.1C1314.3,273.5,1312.7,272.8,1311.5,271.3z M1320,270.4c0.8-1,1.1-2.4,1.1-4.2c0-1.6-0.3-3-1-4.2
									c-0.7-1.2-1.6-2-2.9-2.4c-1.4,0-2.5,0.5-3.3,1.5c-0.8,1-1.2,2.4-1.2,4.1c0,2,0.4,3.6,1.2,4.8c0.8,1.2,1.8,1.8,2.9,1.8
									C1318.2,271.9,1319.3,271.4,1320,270.4z"/>
								<path d="M1330.1,272.7c-0.3-0.5-0.4-1.3-0.4-2.5c0-0.8,0-2.1,0.1-3.8c0-2,0.1-3.6,0.1-4.9c0-4.2-0.2-7.1-0.7-8.9
									c0.7-0.3,1.3-0.5,1.9-0.6s1.3-0.2,2-0.2c-0.3,3.2-0.4,7.8-0.4,13.8c0,1.4,0,2.5,0.1,3.2c0.1,0.8,0.2,1.4,0.4,1.8
									c0.2,0.4,0.5,0.9,0.9,1.4c-0.4,0.5-0.8,0.8-1.2,1c-0.5,0.2-0.9,0.3-1.2,0.3C1330.9,273.4,1330.4,273.2,1330.1,272.7z"/>
								<path d="M1339.8,271.4c-1-1.4-1.5-3.4-1.5-6.2c0-2.8,0.5-4.9,1.5-6.3c1-1.4,2.5-2.1,4.5-2.1c1.6,0,3,0.5,4.3,1.6
									c-0.1-2.6-0.4-4.5-0.7-5.8c0.7-0.3,1.3-0.5,1.9-0.6s1.3-0.2,2-0.2c-0.3,3.2-0.4,8.7-0.4,16.4c0,0.9,0.1,1.6,0.3,2.2
									c0.2,0.5,0.6,1.1,1.1,1.7c-0.8,0.8-1.8,1.2-2.9,1.4c-0.6-0.5-1.1-1.2-1.4-2.2c-0.6,0.7-1.4,1.2-2.2,1.6c-0.8,0.4-1.6,0.6-2.4,0.6
									C1342.3,273.5,1340.8,272.8,1339.8,271.4z M1346.8,271c0.6-0.3,1.2-0.7,1.7-1.1c0-0.8,0-2.2,0.1-4.1c0-2.1,0.1-3.8,0.1-5
									c-0.9-0.6-2-1-3.3-1c-1.2,0-2.2,0.5-2.9,1.4c-0.7,1-1,2.3-1,4.2c0,1.8,0.3,3.2,0.9,4.3c0.6,1.1,1.5,1.7,2.6,1.9
									C1345.7,271.5,1346.2,271.4,1346.8,271z"/>
								<path d="M1119.4,304.7c-0.3-0.5-0.5-1.2-0.5-2.4c0-0.8,0-2.1,0.1-3.9c0-2,0.1-3.6,0.1-4.9c0-0.6,0-1.2-0.1-1.8
									c-1,0-1.6-0.1-1.9-0.2c-0.3-0.1-0.5-0.4-0.5-0.8c1.5-1,3-2.3,4.4-3.9l0.9,0.1c0.1,0.9,0.2,1.8,0.2,2.7c1.6,0,2.8-0.1,3.5-0.3
									c0,0.5,0,1-0.1,1.4c-0.1,0.4-0.2,0.8-0.5,1.3c-0.7-0.3-1.7-0.4-3-0.5c-0.1,1.2-0.1,2.4-0.1,3.7c0,3.2,0.2,5.6,0.5,7.3
									c1.1-0.1,2-0.2,2.6-0.4l0.2,0.9c-0.4,0.7-1,1.3-1.8,1.7c-0.8,0.4-1.5,0.7-2.3,0.7C1120.3,305.4,1119.7,305.2,1119.4,304.7z"/>
								<path d="M1129.8,305.1c0.2-3.3,0.2-6.6,0.2-9.9c0-4.5-0.1-8-0.4-10.6c1.2-0.5,2.5-0.8,3.9-0.8c-0.1,1.2-0.3,3.7-0.5,7.5
									c0.7-0.8,1.4-1.4,2.3-1.8c0.9-0.5,1.7-0.7,2.6-0.7c2.8,0,4.2,1.8,4.2,5.4l-0.1,2.4l-0.1,2.6c0,2.7,0.2,4.7,0.7,5.8
									c-0.7,0.2-1.3,0.2-1.9,0.2c-0.6,0-1.3-0.1-2.1-0.2c0.2-3.7,0.2-7.1,0.2-10.2c0-1-0.2-1.8-0.6-2.2c-0.4-0.5-1-0.7-1.9-0.7
									c-1.3,0-2.4,0.3-3.4,1c0,0.8-0.1,1.8-0.1,3.2c0,4.8,0.2,7.8,0.7,8.9c-0.6,0.2-1.3,0.2-2,0.2
									C1131.3,305.3,1130.6,305.2,1129.8,305.1z"/>
								<path d="M1149.2,303.4c-1-1.4-1.5-3.3-1.5-5.8c0-1.7,0.3-3.2,0.9-4.6c0.6-1.3,1.5-2.4,2.6-3.1c1.1-0.8,2.4-1.1,3.8-1.1
									c1.9,0,3.3,0.7,4.3,2s1.5,3.4,1.5,6c-1.3,0.3-2.8,0.6-4.6,0.9c-1.8,0.2-3.5,0.4-5.3,0.4c0.1,1.6,0.5,2.7,1.2,3.4
									c0.7,0.7,1.6,1.1,2.9,1.1c1,0,1.9-0.1,2.8-0.4c0.9-0.2,1.6-0.6,2.2-1l0.2,0.7c-0.7,1-1.7,1.9-2.9,2.5c-1.2,0.6-2.5,1-3.8,1
									C1151.7,305.5,1150.2,304.8,1149.2,303.4z M1154.4,296.3c1.1-0.3,2-0.6,2.7-1c0-1.4-0.4-2.5-1.1-3.4c-0.7-0.9-1.6-1.3-2.7-1.3
									c-0.7,0.5-1.3,1.3-1.8,2.4c-0.4,1.1-0.7,2.4-0.7,3.9C1152.1,296.8,1153.2,296.6,1154.4,296.3z"/>
								<path d="M1180.3,304.2c-0.5-0.8-1-1.8-1.4-3c-0.4-1.2-0.9-3-1.5-5.2c-0.6-2.5-1.2-4.5-1.7-6.1c-0.5-1.6-1.1-3.2-1.8-4.7
									c1.4-0.5,2.8-0.8,4.4-0.8c0.1,1.1,0.4,2.7,0.8,4.7c0.4,2,0.8,4,1.3,5.9c0.8,2.7,1.5,4.9,2.1,6.5c2-6.7,3.3-11.4,3.9-14l-0.9-2.3
									c1.3-0.5,2.8-0.8,4.4-0.8c0.1,1.1,0.4,2.7,0.8,4.7c0.4,2,0.8,4,1.3,5.9c0.9,3,1.6,5.1,2.1,6.5c1.6-5.2,2.7-8.9,3.3-11.3
									c0.6-2.4,0.9-4,0.9-4.9c0-0.2,0-0.4-0.1-0.5c1-0.3,2-0.4,3.2-0.4c-0.8,2.3-1.9,5.5-3.1,9.4c-1.2,4-2.2,7.4-3.1,10.2l-1.5,1.4
									c-0.8,0-1.4-0.4-2-1.2c-0.5-0.8-1-1.8-1.4-3c-0.4-1.2-0.9-3-1.5-5.2c-0.4-1.8-0.9-3.4-1.3-4.9c-0.9,2.9-2.2,7.2-3.9,12.9l-1.5,1.4
									C1181.5,305.4,1180.9,305,1180.3,304.2z"/>
								<path d="M1204.9,284c0.7-1,1.4-1.8,2.2-2.4c0.7,0.9,1.4,1.7,2.2,2.4c-0.7,0.9-1.4,1.7-2.2,2.4
									C1206.4,285.6,1205.7,284.8,1204.9,284z M1206,304.7c-0.3-0.5-0.4-1.3-0.4-2.5c0-1,0-2.3,0.1-4.1c0-1.7,0.1-2.9,0.1-3.7
									c0-1.1-0.1-2-0.2-2.9c-0.1-0.9-0.3-1.5-0.5-1.9c0.7-0.3,1.3-0.5,1.9-0.6c0.6-0.1,1.3-0.2,2.1-0.2c-0.1,0.8-0.2,1.9-0.3,3.5
									c-0.1,1.6-0.1,3.3-0.1,5.3c0,1.4,0,2.5,0.1,3.3c0.1,0.8,0.2,1.3,0.4,1.8c0.2,0.4,0.5,0.9,0.9,1.4c-0.4,0.5-0.8,0.8-1.2,1
									c-0.5,0.2-0.9,0.3-1.2,0.3C1206.8,305.4,1206.3,305.2,1206,304.7z"/>
								<path d="M1216.6,304.7c-0.3-0.5-0.5-1.2-0.5-2.4c0-0.8,0-2.1,0.1-3.9c0-2,0.1-3.6,0.1-4.9c0-0.6,0-1.2-0.1-1.8
									c-1,0-1.6-0.1-1.9-0.2c-0.3-0.1-0.5-0.4-0.5-0.8c1.5-1,3-2.3,4.4-3.9l0.9,0.1c0.1,0.9,0.2,1.8,0.2,2.7c1.6,0,2.8-0.1,3.5-0.3
									c0,0.5,0,1-0.1,1.4c-0.1,0.4-0.2,0.8-0.5,1.3c-0.7-0.3-1.7-0.4-3-0.5c-0.1,1.2-0.1,2.4-0.1,3.7c0,3.2,0.2,5.6,0.5,7.3
									c1.1-0.1,2-0.2,2.6-0.4l0.2,0.9c-0.4,0.7-1,1.3-1.8,1.7c-0.8,0.4-1.5,0.7-2.3,0.7C1217.6,305.4,1217,305.2,1216.6,304.7z"/>
								<path d="M1227.7,303.4c-1-1.4-1.6-3.3-1.6-5.8c0-1.7,0.3-3.2,0.9-4.6c0.6-1.3,1.5-2.4,2.7-3.1c1.1-0.8,2.4-1.1,3.9-1.1
									c1.7,0,3.1,0.3,4.2,0.9c0,0.4-0.1,1-0.3,1.7c-0.2,0.7-0.5,1.3-0.8,1.8c-1-0.9-1.9-1.6-2.7-2c-0.9-0.5-1.6-0.7-2.1-0.7
									c-0.7,0.4-1.3,1.2-1.8,2.4c-0.5,1.1-0.7,2.4-0.7,3.7c0,2.2,0.3,3.8,1,4.8c0.7,1,1.7,1.4,3.3,1.4c1.7,0,3.2-0.5,4.4-1.4l0.3,0.7
									c-0.7,1.1-1.6,1.9-2.7,2.6s-2.3,1-3.5,1C1230.3,305.5,1228.8,304.8,1227.7,303.4z"/>
								<path d="M1242.8,305.1c0.2-3.3,0.2-6.6,0.2-9.9c0-4.5-0.1-8-0.4-10.6c1.2-0.5,2.5-0.8,3.9-0.8c-0.1,1.2-0.3,3.7-0.5,7.5
									c0.7-0.8,1.4-1.4,2.3-1.8c0.9-0.5,1.7-0.7,2.6-0.7c2.8,0,4.2,1.8,4.2,5.4l-0.1,2.4l-0.1,2.6c0,2.7,0.2,4.7,0.7,5.8
									c-0.7,0.2-1.3,0.2-1.9,0.2c-0.6,0-1.3-0.1-2.1-0.2c0.2-3.7,0.2-7.1,0.2-10.2c0-1-0.2-1.8-0.6-2.2c-0.4-0.5-1-0.7-1.9-0.7
									c-1.3,0-2.4,0.3-3.4,1c0,0.8-0.1,1.8-0.1,3.2c0,4.8,0.2,7.8,0.7,8.9c-0.6,0.2-1.3,0.2-2,0.2
									C1244.3,305.3,1243.6,305.2,1242.8,305.1z"/>
								<path d="M1261.3,295.7c1.6,0.1,3.4,0.2,5.4,0.2c1,0,2-0.1,3-0.2c1-0.1,1.8-0.3,2.2-0.5c0.3,1.2,0.5,2.5,0.5,3.9
									c-1.6-0.1-3.3-0.2-5.3-0.2c-1,0-2.1,0.1-3.1,0.2c-1,0.1-1.7,0.3-2.2,0.5C1261.4,298.4,1261.3,297.1,1261.3,295.7z"/>
								<path d="M1278.9,305.1c0.2-2.6,0.2-5.3,0.2-8.3c0-1.4,0-2.5-0.1-3.3c-0.1-0.8-0.2-1.3-0.4-1.8c-0.2-0.4-0.5-0.9-0.9-1.4
									c0.4-0.5,0.8-0.8,1.2-1c0.5-0.2,0.9-0.3,1.2-0.3c1.4,0,2.1,1,2.1,2.9c0.6-0.8,1.3-1.5,2-2c0.7-0.5,1.5-0.9,2.4-1.2
									c0.2,0.4,0.3,1,0.4,1.8c0.1,0.8,0.2,1.5,0.2,2.1c-0.3-0.1-0.7-0.1-1.2-0.1c-0.8,0-1.5,0.1-2.2,0.3c-0.7,0.2-1.2,0.4-1.7,0.7l0,1.2
									c0,1.7-0.1,3.2-0.1,4.5c0,2.7,0.2,4.7,0.7,5.8c-0.7,0.2-1.4,0.2-2,0.2S1279.7,305.2,1278.9,305.1z"/>
								<path d="M1291.7,303.4c-1-1.4-1.5-3.3-1.5-5.8c0-1.7,0.3-3.2,0.9-4.6c0.6-1.3,1.5-2.4,2.6-3.1c1.1-0.8,2.4-1.1,3.8-1.1
									c1.9,0,3.3,0.7,4.3,2s1.5,3.4,1.5,6c-1.3,0.3-2.8,0.6-4.6,0.9c-1.8,0.2-3.5,0.4-5.3,0.4c0.1,1.6,0.5,2.7,1.2,3.4
									c0.7,0.7,1.6,1.1,2.9,1.1c1,0,1.9-0.1,2.8-0.4c0.9-0.2,1.6-0.6,2.2-1l0.2,0.7c-0.7,1-1.7,1.9-2.9,2.5c-1.2,0.6-2.5,1-3.8,1
									C1294.2,305.5,1292.7,304.8,1291.7,303.4z M1296.9,296.3c1.1-0.3,2-0.6,2.7-1c0-1.4-0.4-2.5-1.1-3.4c-0.7-0.9-1.6-1.3-2.7-1.3
									c-0.7,0.5-1.3,1.3-1.8,2.4c-0.4,1.1-0.7,2.4-0.7,3.9C1294.6,296.8,1295.8,296.6,1296.9,296.3z"/>
								<path d="M1308.5,304.5c-0.6-0.7-0.9-1.6-0.9-2.6c0-3.1,2.8-4.9,8.4-5.3c0-1.7-0.2-2.9-0.7-3.6c-0.5-0.7-1.3-1-2.5-1
									c-0.7,0-1.5,0.1-2.2,0.4c-0.7,0.3-1.4,0.6-2.1,1l-0.3-0.7c0.6-1.1,1.5-2.1,2.6-2.8c1.1-0.7,2.2-1.1,3.4-1.1c1.5,0,2.7,0.4,3.6,1.3
									s1.2,2.2,1.2,3.9c0,0.9-0.1,2-0.2,3.1c-0.1,1.2-0.1,2.2-0.1,2.8c0,1,0.1,1.7,0.3,2.3s0.6,1.1,1.1,1.8c-0.9,0.8-1.8,1.3-2.9,1.5
									c-0.6-0.5-1.1-1.2-1.4-2.1c-0.9,0.6-1.7,1.2-2.7,1.6c-0.9,0.4-1.8,0.6-2.6,0.6C1309.9,305.5,1309.1,305.1,1308.5,304.5z
									M1314,303.1c0.7-0.3,1.3-0.7,1.8-1.1v-0.2c0-0.8,0-1.6,0.1-2.4l0.1-1.7c-1.6,0.1-2.9,0.5-3.7,1.1c-0.9,0.6-1.3,1.5-1.3,2.5
									c0,0.4,0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.8,0.9,1C1312.7,303.6,1313.4,303.4,1314,303.1z"/>
								<path d="M1326.1,304.7c-0.3-0.5-0.4-1.3-0.4-2.5c0-0.8,0-2.1,0.1-3.8c0-2,0.1-3.6,0.1-4.9c0-4.2-0.2-7.1-0.7-8.9
									c0.7-0.3,1.3-0.5,1.9-0.6s1.3-0.2,2-0.2c-0.3,3.2-0.4,7.8-0.4,13.8c0,1.4,0,2.5,0.1,3.2c0.1,0.8,0.2,1.4,0.4,1.8
									c0.2,0.4,0.5,0.9,0.9,1.4c-0.4,0.5-0.8,0.8-1.2,1c-0.5,0.2-0.9,0.3-1.2,0.3C1326.9,305.4,1326.4,305.2,1326.1,304.7z"/>
								<path d="M1336.1,305.1c0.2-2.6,0.2-5.3,0.2-8.3c0-1.4,0-2.5-0.1-3.3c-0.1-0.8-0.2-1.3-0.4-1.8c-0.2-0.4-0.5-0.9-0.9-1.4
									c0.4-0.5,0.8-0.8,1.2-1.1c0.4-0.2,0.8-0.3,1.2-0.3c1.2,0,2,0.8,2.1,2.3c0.6-0.8,1.3-1.3,2.2-1.8c0.8-0.4,1.7-0.7,2.5-0.7
									c2,0,3.3,0.9,3.8,2.8c0.6-0.9,1.4-1.6,2.2-2.1c0.9-0.5,1.8-0.7,2.7-0.7c2.8,0,4.1,1.8,4.1,5.4l-0.1,2.4c-0.1,1-0.1,1.8-0.1,2.6
									c0,2.7,0.2,4.6,0.7,5.8c-0.7,0.2-1.3,0.2-1.9,0.2c-0.6,0-1.3-0.1-2.1-0.2c0.2-3.1,0.2-6.5,0.2-10.2c0-1-0.2-1.8-0.6-2.2
									c-0.4-0.5-1-0.7-1.9-0.7c-1.2,0-2.2,0.3-3.1,0.9c0,1,0,2.4,0,4.3c0,0.5,0,1.2,0,2.1c0,2.7,0.2,4.6,0.7,5.8
									c-0.6,0.2-1.3,0.2-1.9,0.2c-0.6,0-1.3-0.1-2.1-0.2c0.2-3.1,0.2-6.5,0.2-10.2c0-1-0.2-1.8-0.6-2.2c-0.4-0.5-1-0.7-1.9-0.7
									c-1.2,0-2.2,0.3-3.1,0.9l0,1.7c-0.1,1.3-0.1,2.9-0.1,4.7c0,2.7,0.2,4.7,0.7,5.8c-0.7,0.2-1.4,0.2-2,0.2
									C1337.6,305.3,1337,305.2,1336.1,305.1z"/>
								<path d="M1163.9,335.3c-1.2-1.5-1.8-3.4-1.8-5.7c0-1.7,0.3-3.2,1-4.6c0.7-1.3,1.6-2.4,2.7-3.1c1.2-0.7,2.5-1.1,4-1.1
									c1.3,0,2.5,0.3,3.5,1c1,0.7,1.8,1.6,2.4,2.8c0.6,1.2,0.8,2.6,0.8,4.1c0,1.7-0.3,3.2-1,4.6c-0.7,1.3-1.6,2.4-2.8,3.1
									c-1.2,0.7-2.6,1.1-4.1,1.1C1166.7,337.5,1165.1,336.8,1163.9,335.3z M1172.4,334.4c0.8-1,1.1-2.4,1.1-4.2c0-1.6-0.3-3-1-4.2
									c-0.7-1.2-1.6-2-2.9-2.4c-1.4,0-2.5,0.5-3.3,1.5c-0.8,1-1.2,2.4-1.2,4.1c0,2,0.4,3.6,1.2,4.8c0.8,1.2,1.8,1.8,2.9,1.8
									C1170.6,335.9,1171.7,335.4,1172.4,334.4z"/>
								<path d="M1181.1,342.9c0.5-0.7,0.9-1.3,1.1-1.9c0.3-0.6,0.5-1.4,0.6-2.4s0.2-2.4,0.2-4.2c0-2-0.1-5.6-0.3-10.6
									c-0.8,0-1.4-0.1-1.6-0.2c-0.3-0.1-0.4-0.4-0.4-0.7c1-0.5,1.6-1,2-1.3c0.3-2.1,1-3.9,2.1-5.2c1.1-1.3,2.5-1.9,4.1-1.9
									c1.1,0,2.3,0.2,3.4,0.5c0,0.4-0.1,0.9-0.3,1.6c-0.2,0.7-0.5,1.3-0.7,1.9c-0.7-0.7-1.4-1.3-2.2-1.8c-0.8-0.5-1.4-0.7-1.9-0.6
									c-0.4,0.3-0.7,0.9-0.9,1.7c-0.2,0.8-0.3,2.2-0.3,4c1.8-0.1,3-0.2,3.7-0.3c0,0.5,0,1-0.1,1.4c-0.1,0.4-0.2,0.9-0.5,1.3
									c-0.8-0.3-1.8-0.4-3.2-0.5v2.7c0,1.6,0,3.4,0.1,5.4c0.1,1.1,0.1,2,0.1,2.8c0,1.3-0.1,2.4-0.4,3.3s-0.7,1.7-1.3,2.5
									c-0.6,0.8-1.6,1.7-2.9,2.8L1181.1,342.9z"/>
								<path d="M1215.7,337l-1.6-5.1c-1.1-0.1-2.5-0.2-4.2-0.2c-1.8,0-3,0.1-3.6,0.2c-0.5,1.5-1.1,3.2-1.6,5c-0.4,0.2-0.8,0.2-1.2,0.2
									c-0.5,0-1-0.1-1.5-0.2c0.7-1.3,1.8-4.4,3.5-9.2c1.7-4.8,3-8.6,3.8-11.3c0.6-0.1,1.2-0.2,1.6-0.2c0.4,0,0.9,0.1,1.4,0.2
									c0.8,2.7,2.1,6.5,3.8,11.3s2.9,7.9,3.5,9.2c-0.6,0.2-1.2,0.3-1.9,0.3C1217.1,337.3,1216.5,337.2,1215.7,337z M1213.3,329.6
									c-1.2-3.5-2.2-6.4-3.1-8.7c-0.9,2.1-1.9,5-3,8.6c1.2,0.1,2.6,0.1,4.1,0.1C1212.2,329.6,1212.9,329.6,1213.3,329.6z"/>
								<path d="M1224.4,337.1c0.2-2.6,0.2-5.3,0.2-8.3c0-1.4,0-2.5-0.1-3.3c-0.1-0.8-0.2-1.3-0.4-1.8c-0.2-0.4-0.5-0.9-0.9-1.4
									c0.4-0.5,0.8-0.8,1.2-1.1c0.4-0.2,0.8-0.3,1.2-0.3c1.3,0,2,0.8,2.2,2.3c0.6-0.8,1.4-1.4,2.2-1.8s1.7-0.7,2.6-0.7
									c2.8,0,4.2,1.8,4.2,5.4l-0.1,2.4c-0.1,1-0.1,1.8-0.1,2.6c0,2.7,0.2,4.6,0.7,5.8c-0.7,0.2-1.3,0.2-1.9,0.2c-0.6,0-1.3-0.1-2.1-0.2
									c0.2-3.1,0.2-6.5,0.2-10.2c0-1-0.2-1.8-0.6-2.2c-0.4-0.5-1-0.7-1.9-0.7c-1.3,0-2.4,0.3-3.2,0.9l0,1.7c-0.1,1.3-0.1,2.9-0.1,4.7
									c0,2.7,0.2,4.7,0.7,5.8c-0.7,0.2-1.4,0.2-2,0.2C1225.9,337.3,1225.2,337.2,1224.4,337.1z"/>
								<path d="M1242.1,342.4c0-0.9,0.2-1.8,0.5-2.7c0.3-0.9,0.8-1.7,1.4-2.4c-0.9-0.4-1.4-1.1-1.4-2.1c0-0.7,0.2-1.4,0.7-2
									c0.5-0.6,1.2-1,2-1.4c-0.8-0.4-1.5-1.1-1.9-1.9c-0.5-0.8-0.7-1.8-0.7-2.9c0-1.8,0.6-3.3,1.7-4.4c1.2-1.1,2.7-1.7,4.5-1.7
									c1.2,0,2.3,0.3,3.2,0.9c0.5-0.1,1-0.4,1.6-0.8c0.6-0.4,1.2-0.9,1.8-1.5c0.3,0.5,0.5,1,0.7,1.6s0.3,1.1,0.3,1.5
									c-0.8,0.4-1.7,0.7-2.8,0.8c0.5,1,0.7,2,0.7,3c0,1.2-0.3,2.3-0.8,3.2c-0.5,0.9-1.3,1.7-2.3,2.2c-1,0.5-2.1,0.8-3.3,0.8
									c-1,0-1.8,0.2-2.4,0.5c-0.6,0.3-0.9,0.8-0.9,1.4l6.7,0.4c1.3,0.1,2.3,0.5,2.9,1.1c0.7,0.6,1,1.5,1,2.6c0,1-0.3,2-1,2.8
									c-0.6,0.8-1.5,1.5-2.7,2c-1.1,0.5-2.4,0.7-3.8,0.7C1245.5,344.1,1243.6,343.5,1242.1,342.4z M1251.8,340.9c0.8-0.4,1.2-1,1.2-1.6
									c0-0.5-0.2-0.9-0.5-1.1c-0.3-0.2-0.9-0.4-1.7-0.4l-5.4-0.3c-0.4,0.8-0.6,1.5-0.7,2.1c0.9,1.3,2.3,2,4.1,2
									C1250,341.5,1251.1,341.3,1251.8,340.9z M1250.8,329.9c0.6-0.6,0.8-1.4,0.8-2.4c0-0.9-0.3-1.7-0.8-2.4c-0.5-0.7-1.2-1.1-2.1-1.3
									c-1,0-1.8,0.3-2.4,1c-0.6,0.7-0.9,1.6-0.9,2.6c0,1,0.3,1.8,0.9,2.4c0.6,0.6,1.3,0.9,2.2,0.9
									C1249.5,330.8,1250.3,330.5,1250.8,329.9z"/>
								<path d="M1261.1,337.1c0.2-2.6,0.2-5.3,0.2-8.3c0-1.4,0-2.5-0.1-3.3c-0.1-0.8-0.2-1.3-0.4-1.8c-0.2-0.4-0.5-0.9-0.9-1.4
									c0.4-0.5,0.8-0.8,1.2-1.1c0.4-0.2,0.8-0.3,1.2-0.3c1.2,0,2,0.8,2.1,2.3c0.6-0.8,1.3-1.3,2.2-1.8c0.8-0.4,1.7-0.7,2.5-0.7
									c2,0,3.3,0.9,3.8,2.8c0.6-0.9,1.4-1.6,2.2-2.1c0.9-0.5,1.8-0.7,2.7-0.7c2.8,0,4.1,1.8,4.1,5.4l-0.1,2.4c-0.1,1-0.1,1.8-0.1,2.6
									c0,2.7,0.2,4.6,0.7,5.8c-0.7,0.2-1.3,0.2-1.9,0.2c-0.6,0-1.3-0.1-2.1-0.2c0.2-3.1,0.2-6.5,0.2-10.2c0-1-0.2-1.8-0.6-2.2
									c-0.4-0.5-1-0.7-1.9-0.7c-1.2,0-2.2,0.3-3.1,0.9c0,1,0,2.4,0,4.3c0,0.5,0,1.2,0,2.1c0,2.7,0.2,4.6,0.7,5.8
									c-0.6,0.2-1.3,0.2-1.9,0.2c-0.6,0-1.3-0.1-2.1-0.2c0.2-3.1,0.2-6.5,0.2-10.2c0-1-0.2-1.8-0.6-2.2c-0.4-0.5-1-0.7-1.9-0.7
									c-1.2,0-2.2,0.3-3.1,0.9l0,1.7c-0.1,1.3-0.1,2.9-0.1,4.7c0,2.7,0.2,4.7,0.7,5.8c-0.7,0.2-1.4,0.2-2,0.2
									C1262.6,337.3,1261.9,337.2,1261.1,337.1z"/>
								<path d="M1288.4,336.5c-0.6-0.7-0.9-1.6-0.9-2.6c0-3.1,2.8-4.9,8.4-5.3c0-1.7-0.2-2.9-0.7-3.6c-0.5-0.7-1.3-1-2.5-1
									c-0.7,0-1.5,0.1-2.2,0.4c-0.7,0.3-1.4,0.6-2.1,1l-0.3-0.7c0.6-1.1,1.5-2.1,2.6-2.8c1.1-0.7,2.2-1.1,3.4-1.1c1.5,0,2.7,0.4,3.6,1.3
									s1.2,2.2,1.2,3.9c0,0.9-0.1,2-0.2,3.1c-0.1,1.2-0.1,2.2-0.1,2.8c0,1,0.1,1.7,0.3,2.3s0.6,1.1,1.1,1.8c-0.9,0.8-1.8,1.3-2.9,1.5
									c-0.6-0.5-1.1-1.2-1.4-2.1c-0.9,0.6-1.7,1.2-2.7,1.6c-0.9,0.4-1.8,0.6-2.6,0.6C1289.7,337.5,1289,337.1,1288.4,336.5z
									M1293.9,335.1c0.7-0.3,1.3-0.7,1.8-1.1v-0.2c0-0.8,0-1.6,0.1-2.4l0.1-1.7c-1.6,0.1-2.9,0.5-3.7,1.1c-0.9,0.6-1.3,1.5-1.3,2.5
									c0,0.4,0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.8,0.9,1C1292.6,335.6,1293.2,335.4,1293.9,335.1z"/>
								<path d="M1306.1,337.1c0.2-2.6,0.2-5.3,0.2-8.3c0-1.4,0-2.5-0.1-3.3c-0.1-0.8-0.2-1.3-0.4-1.8c-0.2-0.4-0.5-0.9-0.9-1.4
									c0.4-0.5,0.8-0.8,1.2-1c0.5-0.2,0.9-0.3,1.2-0.3c1.4,0,2.1,1,2.1,2.9c0.6-0.8,1.3-1.5,2-2c0.7-0.5,1.5-0.9,2.4-1.2
									c0.2,0.4,0.3,1,0.4,1.8c0.1,0.8,0.2,1.5,0.2,2.1c-0.3-0.1-0.7-0.1-1.2-0.1c-0.8,0-1.5,0.1-2.2,0.3c-0.7,0.2-1.2,0.4-1.7,0.7l0,1.2
									c0,1.7-0.1,3.2-0.1,4.5c0,2.7,0.2,4.7,0.7,5.8c-0.7,0.2-1.4,0.2-2,0.2S1306.9,337.2,1306.1,337.1z"/>
							</g> */}
					<g className="level_one location" id="angmar">
						<rect x="1499.902" y="1022.769" width="97.391" height="22.501" transform="matrix(0.99966, 0.026067, -0.038108, 0.999346, -97.235992, -904.418518)"></rect>
						<path
							d="M1374.9,177.4l-1.9-4.3c-1,0-2.2,0.1-3.7,0.2c-1.6,0.1-2.6,0.3-3.2,0.5c-0.3,1.4-0.7,2.9-1,4.6c-0.4,0.2-0.7,0.3-1,0.3
									c-0.4,0-0.9,0-1.3-0.1c0.5-1.2,1.3-4,2.3-8.4c1.1-4.4,1.9-7.8,2.4-10.3c0.6-0.2,1-0.3,1.4-0.3c0.4,0,0.8,0,1.3,0.1
									c1,2.3,2.4,5.5,4.3,9.6c1.9,4.1,3.2,6.7,3.9,7.8c-0.5,0.2-1.1,0.3-1.6,0.4C1376.2,177.5,1375.6,177.5,1374.9,177.4z M1372.1,171.1
									c-1.3-3-2.5-5.4-3.5-7.4c-0.6,2-1.2,4.6-1.9,7.8c1.1,0,2.3-0.1,3.6-0.2C1371.2,171.2,1371.8,171.2,1372.1,171.1z"
						/>
						<path
							d="M1381.6,176.9c0-2.3-0.1-4.7-0.2-7.3c-0.1-1.3-0.2-2.2-0.3-2.9c-0.1-0.7-0.3-1.2-0.4-1.6c-0.2-0.4-0.5-0.8-0.8-1.2
									c0.3-0.5,0.7-0.8,1-1c0.4-0.2,0.7-0.3,1-0.4c1.1-0.1,1.8,0.6,2,1.9c0.5-0.7,1.1-1.3,1.8-1.7c0.7-0.4,1.5-0.7,2.3-0.7
									c2.5-0.1,3.8,1.4,4,4.5l0.1,2.2c0,0.9,0,1.6,0,2.3c0.1,2.4,0.5,4.1,1,5.1c-0.6,0.2-1.1,0.3-1.7,0.3c-0.5,0-1.2,0-1.9-0.1
									c0-2.8-0.1-5.8-0.3-9c-0.1-0.9-0.3-1.6-0.6-1.9c-0.4-0.4-0.9-0.5-1.7-0.5c-1.2,0.1-2.1,0.4-2.8,1l0.1,1.5c0,1.2,0.1,2.6,0.1,4.2
									c0.1,2.4,0.5,4.1,0.9,5.1c-0.6,0.2-1.2,0.3-1.8,0.3C1382.9,177.1,1382.3,177,1381.6,176.9z"
						/>
						<path
							d="M1396.4,180.9c0-0.8,0.1-1.6,0.4-2.4c0.3-0.8,0.7-1.5,1.2-2.1c-0.8-0.3-1.3-0.9-1.3-1.8c0-0.6,0.2-1.2,0.6-1.8
									c0.4-0.5,1-0.9,1.8-1.3c-0.7-0.4-1.3-0.9-1.7-1.7c-0.4-0.7-0.6-1.6-0.7-2.6c0-1.6,0.4-2.9,1.5-4c1-1,2.3-1.6,3.9-1.6
									c1.1,0,2,0.2,2.8,0.7c0.4-0.1,0.9-0.4,1.4-0.7c0.5-0.4,1.1-0.8,1.6-1.3c0.2,0.4,0.4,0.9,0.6,1.4c0.2,0.5,0.3,1,0.3,1.3
									c-0.7,0.4-1.5,0.7-2.5,0.7c0.4,0.8,0.7,1.7,0.7,2.6c0,1.1-0.2,2-0.7,2.8c-0.5,0.8-1.1,1.5-2,2c-0.9,0.5-1.8,0.7-2.9,0.8
									c-0.9,0-1.6,0.2-2.1,0.5c-0.5,0.3-0.8,0.7-0.8,1.3l6,0.2c1.1,0.1,2,0.4,2.6,1c0.6,0.5,0.9,1.3,1,2.3c0,0.9-0.2,1.8-0.8,2.5
									s-1.3,1.4-2.3,1.8c-1,0.4-2.1,0.7-3.4,0.7C1399.5,182.3,1397.7,181.8,1396.4,180.9z M1405,179.4c0.7-0.4,1-0.9,1-1.5
									c0-0.5-0.2-0.8-0.5-1c-0.3-0.2-0.8-0.3-1.5-0.3l-4.7-0.2c-0.3,0.7-0.5,1.3-0.6,1.9c0.8,1.2,2.1,1.7,3.7,1.7
									C1403.4,179.9,1404.3,179.7,1405,179.4z M1403.9,169.7c0.5-0.5,0.7-1.2,0.7-2.2c0-0.8-0.3-1.5-0.7-2.1c-0.5-0.6-1.1-1-1.9-1.1
									c-0.9,0-1.6,0.3-2.1,1c-0.5,0.6-0.8,1.4-0.8,2.3c0,0.9,0.3,1.6,0.8,2.1c0.5,0.5,1.2,0.8,2,0.8
									C1402.7,170.5,1403.4,170.2,1403.9,169.7z"
						/>
						<path
							d="M1412.1,175.8c0.2-2.3,0.3-4.7,0.4-7.3c0-1.3,0-2.2-0.1-2.9c-0.1-0.7-0.2-1.2-0.3-1.6c-0.1-0.4-0.4-0.8-0.7-1.2
									c0.3-0.4,0.7-0.7,1.1-0.9c0.4-0.2,0.7-0.3,1-0.3c1.1,0,1.7,0.7,1.9,2.1c0.6-0.7,1.2-1.2,2-1.5c0.7-0.4,1.5-0.6,2.2-0.5
									c1.8,0,2.9,0.9,3.3,2.5c0.6-0.8,1.2-1.4,2-1.8c0.8-0.4,1.6-0.6,2.4-0.6c2.4,0.1,3.6,1.7,3.6,4.8l-0.1,2.2
									c-0.1,0.8-0.1,1.6-0.1,2.3c0,2.4,0.1,4.1,0.6,5.2c-0.6,0.1-1.2,0.2-1.7,0.2c-0.5,0-1.2-0.1-1.9-0.2c0.2-2.8,0.3-5.8,0.4-9
									c0-0.9-0.1-1.6-0.5-2c-0.3-0.4-0.9-0.6-1.7-0.6c-1.1,0-2,0.2-2.8,0.7c0,0.8,0,2.1-0.1,3.8c0,0.5-0.1,1.1-0.1,1.9
									c0,2.4,0.1,4.1,0.5,5.2c-0.6,0.1-1.1,0.2-1.6,0.2c-0.5,0-1.2-0.1-1.9-0.2c0.2-2.8,0.3-5.8,0.4-9c0-0.9-0.1-1.6-0.5-2
									c-0.3-0.4-0.9-0.6-1.7-0.6c-1.1,0-2,0.2-2.7,0.7l-0.1,1.5c-0.1,1.2-0.1,2.6-0.2,4.2c0,2.4,0.1,4.1,0.5,5.2
									c-0.6,0.1-1.2,0.2-1.8,0.2C1413.4,176,1412.8,176,1412.1,175.8z"
						/>
						<path
							d="M1435.3,176c-0.5-0.6-0.7-1.4-0.6-2.4c0.2-2.8,2.8-4.2,7.7-4.2c0.1-1.5,0-2.6-0.4-3.2c-0.4-0.6-1.1-1-2.2-1
									c-0.7,0-1.3,0-2,0.2c-0.7,0.2-1.3,0.5-1.9,0.8l-0.2-0.7c0.6-1,1.5-1.7,2.5-2.3c1-0.6,2-0.8,3-0.8c1.4,0.1,2.4,0.6,3.1,1.4
									c0.7,0.8,1,2,0.9,3.5c-0.1,0.8-0.2,1.7-0.3,2.8c-0.2,1.1-0.2,1.9-0.3,2.4c-0.1,0.9,0,1.6,0.1,2c0.1,0.5,0.4,1,0.9,1.6
									c-0.8,0.7-1.7,1.1-2.6,1.1c-0.5-0.4-0.9-1.1-1.1-2c-0.8,0.5-1.6,0.9-2.5,1.2c-0.8,0.3-1.6,0.4-2.3,0.4
									C1436.4,176.9,1435.8,176.6,1435.3,176z M1440.2,175c0.6-0.2,1.2-0.5,1.6-0.9l0-0.2c0-0.7,0.1-1.4,0.2-2.1l0.2-1.5
									c-1.4,0-2.6,0.3-3.4,0.8c-0.8,0.5-1.3,1.2-1.3,2.2c0,0.4,0,0.8,0.2,1.2c0.2,0.4,0.4,0.7,0.7,0.9
									C1439.1,175.4,1439.6,175.3,1440.2,175z"
						/>
						<path
							d="M1450,177.5c0.4-2.3,0.7-4.7,0.9-7.3c0.1-1.3,0.2-2.2,0.2-2.9c0-0.7-0.1-1.2-0.2-1.6c-0.1-0.4-0.3-0.8-0.7-1.3
									c0.4-0.4,0.8-0.7,1.2-0.8c0.4-0.2,0.8-0.2,1.1-0.2c1.2,0.1,1.7,1,1.7,2.7c0.6-0.7,1.3-1.2,1.9-1.6c0.7-0.4,1.4-0.7,2.2-0.8
									c0.1,0.4,0.2,0.9,0.2,1.6s0.1,1.3,0,1.9c-0.2-0.1-0.6-0.1-1.1-0.2c-0.7-0.1-1.3,0-1.9,0.1c-0.6,0.1-1.1,0.3-1.5,0.5l-0.1,1.1
									c-0.2,1.5-0.3,2.8-0.4,4c-0.2,2.4-0.2,4.1,0.2,5.2c-0.6,0.1-1.2,0.1-1.8,0C1451.3,177.9,1450.7,177.7,1450,177.5z"
						/>
					</g>
					<g className="level_two fade location " id="mountains_of_angmar">
						<rect x="1499.905" y="1022.77" width="97.391" height="22.501" transform="matrix(0.950534, 0.31062, -0.322069, 0.946793, 241.357925, -1275.886841)"></rect>
						<rect x="1172.389" y="1022.77" width="76.125" height="22.501" transform="matrix(0.999181, 0.040464, -0.052499, 0.998694, 297.462799, -885.050842)"></rect>
						<rect x="1448.591" y="1143.249" width="94.059" height="25.152" transform="matrix(0.770064, 0.637967, -0.647242, 0.76238, 1115.556763, -1603.554321)"></rect>
						<path
							d="M1334.2,177.6c0.8-2.1,1.7-5.1,2.8-9c0.6-2,1-3.6,1.2-4.6c0.2-1,0.4-1.8,0.4-2.2c0.5,0,1,0.1,1.5,0.2
									c0.4,0.1,0.9,0.3,1.5,0.6c0.1,1.6,0.4,3.7,0.7,6.5c0.3,2.8,0.6,5,0.9,6.6c1.1-1.3,2.5-3,4.2-5.1c1.6-2,3-3.8,4.1-5.2
									c0.4,0,0.9,0.1,1.6,0.3c0.5,0.1,1,0.3,1.5,0.6c-0.4,1-0.8,2.3-1.4,4s-1.1,3.3-1.5,4.9c-1.1,3.7-1.6,6-1.5,6.9
									c-0.5,0-1-0.1-1.5-0.2c-0.5-0.1-1-0.4-1.6-0.6c0.4-1,0.8-2.3,1.4-4c0.6-1.7,1.1-3.3,1.5-4.9c0.3-1,0.6-2.1,0.9-3.4
									c-2.3,2.7-5.2,6.3-8.8,10.8c-0.3,0-0.5,0-0.6,0c-0.1,0-0.3-0.1-0.5-0.3c-0.1-1.4-0.3-3.4-0.6-6c-0.3-2.6-0.6-4.9-0.9-7
									c-0.6,2.1-1.1,3.7-1.4,4.8c-1,3.6-1.6,5.9-1.7,6.9c-0.4,0-0.7,0-1.1-0.1C1335,178,1334.6,177.8,1334.2,177.6z"
						/>
						<path
							d="M1354.5,182.8c-0.4-1.4-0.3-3,0.4-4.8c0.5-1.3,1.2-2.3,2.1-3.1c0.9-0.8,1.9-1.3,3-1.5c1.1-0.2,2.2-0.1,3.3,0.4
									c1,0.4,1.8,1,2.3,1.8c0.6,0.8,0.9,1.7,0.9,2.8c0.1,1.1-0.2,2.2-0.6,3.3c-0.5,1.3-1.2,2.3-2.1,3.1c-0.9,0.8-1.9,1.3-3,1.4
									c-1.1,0.2-2.3,0-3.4-0.4C1355.8,185.2,1354.9,184.2,1354.5,182.8z M1361.1,184.6c0.9-0.5,1.6-1.4,2.1-2.8c0.5-1.2,0.7-2.3,0.5-3.4
									c-0.2-1.1-0.6-1.9-1.4-2.6c-1-0.4-2-0.4-2.9,0.1c-0.9,0.5-1.6,1.4-2.2,2.7c-0.6,1.5-0.8,2.8-0.6,3.9c0.2,1.1,0.8,1.8,1.6,2.2
									C1359.3,185.2,1360.2,185.1,1361.1,184.6z"
						/>
						<path
							d="M1368.3,185.7l0.7-1.8l0.8-1.9c0.7-1.9,1.1-3.3,1-4.3c1-0.1,2.1,0.1,3.2,0.5c-1,2.3-2.1,5-3.2,8.1
									c-0.3,0.8-0.4,1.4-0.2,1.9c0.2,0.5,0.6,0.8,1.3,1.1c0.9,0.3,1.7,0.4,2.6,0.2c0.5-1.3,1-2.4,1.4-3.4l0.6-1.6c0.7-1.9,1.1-3.3,1-4.3
									c1-0.1,2.1,0.1,3.2,0.5c-0.6,1.3-1.2,2.9-2,4.7c-0.8,1.8-1.3,3-1.5,3.6c-0.3,0.7-0.4,1.3-0.4,1.7c0,0.5,0.1,1,0.3,1.6
									c-0.9,0.3-1.7,0.4-2.5,0.2c-0.4-0.6-0.5-1.3-0.4-2.1c-0.7,0.4-1.4,0.6-2.2,0.6c-0.8,0.1-1.5,0-2.2-0.3
									C1367.8,190.1,1367.3,188.4,1368.3,185.7z"
						/>
						<path
							d="M1381.9,194.8c0.7-2,1.4-4.1,2-6.3c0.3-1.1,0.5-1.9,0.6-2.5c0.1-0.6,0.1-1.1,0.1-1.5c0-0.4-0.2-0.8-0.4-1.2
									c0.4-0.3,0.8-0.5,1.2-0.6c0.4-0.1,0.7-0.1,1,0c1,0.3,1.4,1,1.2,2.3c0.6-0.5,1.3-0.8,2.1-0.9c0.7-0.2,1.5-0.1,2.1,0.1
									c2.1,0.6,2.8,2.3,2,5l-0.6,1.9c-0.3,0.7-0.5,1.4-0.7,2c-0.6,2.1-0.8,3.6-0.7,4.6c-0.5,0-1-0.1-1.5-0.2c-0.5-0.1-1-0.4-1.6-0.7
									c0.8-2.4,1.6-5,2.4-7.8c0.2-0.8,0.2-1.4,0.1-1.8c-0.2-0.4-0.6-0.7-1.3-0.9c-1-0.3-1.9-0.3-2.7,0l-0.4,1.3c-0.3,1-0.7,2.2-1.1,3.6
									c-0.6,2.1-0.8,3.6-0.7,4.6c-0.6,0-1.1-0.1-1.6-0.3C1383,195.3,1382.5,195.1,1381.9,194.8z"
						/>
						<path
							d="M1398.1,198.7c-0.2-0.4-0.2-1.1,0-2c0.1-0.6,0.3-1.7,0.7-3.1c0.3-1.5,0.6-2.8,0.8-3.8c0.1-0.5,0.2-1,0.2-1.4
									c-0.7-0.1-1.2-0.3-1.5-0.5c-0.2-0.2-0.3-0.4-0.3-0.7c1.4-0.5,2.7-1.3,4.1-2.4l0.7,0.2c-0.1,0.7-0.2,1.4-0.3,2.2
									c1.3,0.2,2.2,0.3,2.8,0.3c-0.1,0.4-0.2,0.8-0.3,1.1c-0.1,0.3-0.3,0.6-0.6,0.9c-0.5-0.3-1.2-0.6-2.3-0.8c-0.2,0.9-0.5,1.9-0.7,2.8
									c-0.5,2.5-0.7,4.4-0.7,5.8c0.9,0.1,1.6,0.1,2.1,0.1l0,0.7c-0.4,0.5-1,0.9-1.7,1.1c-0.7,0.2-1.3,0.3-1.9,0.2
									C1398.7,199.4,1398.3,199.1,1398.1,198.7z"
						/>
						<path
							d="M1406.6,200.1c-0.4-0.6-0.5-1.3-0.4-2.2c0.3-2.5,2.7-3.6,7.2-3.4c0.2-1.4,0.1-2.3-0.2-2.9c-0.3-0.6-0.9-0.9-1.9-1
									c-0.6-0.1-1.2,0-1.8,0.1c-0.6,0.1-1.2,0.3-1.7,0.6l-0.2-0.6c0.6-0.8,1.4-1.5,2.3-2c0.9-0.5,1.9-0.6,2.8-0.5
									c1.2,0.2,2.1,0.6,2.7,1.4c0.6,0.8,0.8,1.9,0.6,3.2c-0.1,0.7-0.2,1.6-0.4,2.5c-0.2,1-0.3,1.7-0.4,2.2c-0.1,0.8-0.1,1.4,0,1.8
									c0.1,0.5,0.3,1,0.7,1.5c-0.8,0.6-1.6,0.9-2.4,0.9c-0.5-0.4-0.8-1-0.9-1.8c-0.7,0.4-1.5,0.8-2.3,1c-0.8,0.2-1.5,0.3-2.1,0.2
									C1407.5,201,1407,200.7,1406.6,200.1z M1411.1,199.5c0.6-0.2,1.1-0.4,1.5-0.7l0-0.2c0.1-0.7,0.2-1.3,0.3-1.9l0.2-1.3
									c-1.3-0.1-2.3,0.1-3.1,0.5c-0.8,0.4-1.2,1-1.3,1.9c0,0.4,0,0.7,0.1,1.1s0.3,0.7,0.6,0.9C1410,199.8,1410.5,199.7,1411.1,199.5z"
						/>
						<path
							d="M1420.6,201.7c-0.2-0.4-0.3-1.1-0.2-2c0-0.8,0.1-1.9,0.2-3.3c0.1-1.3,0.2-2.3,0.2-2.9c0-0.8,0-1.6,0-2.4
									c-0.1-0.7-0.2-1.2-0.3-1.6c0.5-0.2,1.1-0.3,1.6-0.4c0.5-0.1,1-0.1,1.6,0c-0.1,0.6-0.3,1.5-0.4,2.8c-0.1,1.2-0.3,2.6-0.3,4.2
									c-0.1,1.1-0.1,2-0.1,2.6c0,0.6,0.1,1.1,0.2,1.4c0.1,0.4,0.3,0.7,0.6,1.1c-0.3,0.4-0.7,0.6-1,0.8c-0.4,0.2-0.7,0.2-1,0.2
									C1421.2,202.3,1420.8,202.1,1420.6,201.7z M1420.7,185.1c0.6-0.7,1.2-1.3,1.9-1.8c0.5,0.7,1,1.4,1.7,2c-0.6,0.7-1.2,1.3-1.9,1.8
									C1421.8,186.4,1421.3,185.8,1420.7,185.1z"
						/>
						<path
							d="M1429.7,202.3c0-2.1-0.1-4.3-0.3-6.6c-0.1-1.1-0.2-2-0.3-2.6c-0.1-0.6-0.3-1.1-0.4-1.4c-0.2-0.3-0.4-0.7-0.8-1
									c0.3-0.4,0.6-0.7,0.9-0.9c0.3-0.2,0.6-0.3,0.9-0.3c1-0.1,1.6,0.5,1.9,1.7c0.4-0.7,1-1.2,1.6-1.6c0.6-0.4,1.3-0.6,2-0.7
									c2.2-0.2,3.4,1.2,3.7,4l0.1,2c0,0.8,0,1.5,0.1,2.1c0.2,2.1,0.5,3.7,1,4.6c-0.5,0.2-1,0.3-1.5,0.3c-0.5,0-1.1,0-1.7-0.1
									c-0.1-2.5-0.2-5.2-0.4-8.1c-0.1-0.8-0.3-1.4-0.6-1.7c-0.3-0.3-0.8-0.5-1.6-0.4c-1,0.1-1.9,0.4-2.5,0.9l0.1,1.3
									c0,1.1,0.1,2.3,0.2,3.8c0.2,2.2,0.5,3.7,0.9,4.6c-0.6,0.2-1.1,0.3-1.6,0.3C1431,202.4,1430.4,202.4,1429.7,202.3z"
						/>
						<path
							d="M1443.1,200.4c0-0.4,0-0.8,0-1.1c0-0.4,0.2-0.7,0.3-1.2c0.4,0.5,0.9,0.9,1.5,1.1c0.6,0.2,1.3,0.3,2,0.3
									c1.8-0.1,2.7-0.8,2.6-1.8c0-0.5-0.3-0.8-0.7-1.1c-0.4-0.3-1.1-0.6-1.9-0.9c-0.9-0.3-1.6-0.7-2.1-0.9c-0.5-0.3-1-0.7-1.4-1.2
									c-0.4-0.5-0.7-1.2-0.7-2c-0.1-1,0.3-1.8,1-2.4c0.7-0.7,1.7-1,3-1.1c1.7-0.1,3.2,0,4.4,0.4c0,0.3,0,0.8-0.2,1.3
									c-0.1,0.6-0.3,1.1-0.5,1.5c-0.7-0.6-1.4-1.1-2.1-1.5c-0.7-0.4-1.2-0.5-1.7-0.5c-0.4,0.1-0.8,0.4-1.1,0.7c-0.3,0.3-0.4,0.6-0.4,0.9
									c0,0.6,0.3,1,0.8,1.3c0.5,0.3,1.2,0.7,2.1,1.1c0.8,0.3,1.5,0.6,2,0.9c0.5,0.3,1,0.7,1.4,1.2c0.4,0.5,0.6,1.1,0.7,1.9
									c0.1,1.2-0.3,2.1-1.1,2.8c-0.8,0.7-2,1.1-3.5,1.2C1445.7,201.3,1444.2,201.1,1443.1,200.4z"
						/>
						<path
							d="M1462.1,199.1c-0.6-1.4-0.7-3-0.2-4.8c0.4-1.3,0.9-2.4,1.7-3.3c0.8-0.9,1.7-1.5,2.8-1.8c1.1-0.3,2.2-0.3,3.3,0
									c1,0.3,1.9,0.8,2.5,1.5c0.7,0.7,1.1,1.6,1.3,2.6c0.2,1,0.1,2.2-0.2,3.4c-0.4,1.3-0.9,2.4-1.7,3.3c-0.8,0.9-1.7,1.5-2.8,1.8
									c-1.1,0.3-2.2,0.3-3.4,0C1463.8,201.4,1462.7,200.5,1462.1,199.1z M1468.9,200.2c0.8-0.6,1.4-1.6,1.8-3c0.3-1.2,0.4-2.4,0.1-3.4
									c-0.3-1-0.9-1.9-1.7-2.4c-1.1-0.3-2-0.1-2.9,0.5c-0.8,0.6-1.5,1.6-1.8,2.9c-0.4,1.6-0.5,2.9-0.1,3.9c0.4,1.1,1,1.7,1.9,2
									C1467.2,200.9,1468.1,200.8,1468.9,200.2z"
						/>
						<path
							d="M1470.6,208.2c0.6-0.3,1.1-0.6,1.5-0.9c0.4-0.3,0.9-0.8,1.3-1.4c0.5-0.6,1.1-1.6,1.8-2.8c0.8-1.4,2.1-3.9,4-7.5
									c-0.6-0.3-0.9-0.6-1-0.8c-0.1-0.2-0.1-0.4,0-0.7c0.9,0,1.5,0,1.9-0.1c1-1.4,2.2-2.3,3.5-2.8c1.3-0.5,2.5-0.4,3.6,0.3
									c0.8,0.4,1.5,1,2.1,1.7c-0.2,0.3-0.4,0.6-0.9,1c-0.4,0.4-0.8,0.7-1.3,1c-0.2-0.8-0.5-1.5-0.8-2.1c-0.4-0.6-0.7-1-1.1-1.2
									c-0.4,0.1-0.8,0.3-1.3,0.8c-0.5,0.5-1,1.4-1.8,2.7c1.3,0.7,2.2,1.1,2.7,1.2c-0.2,0.4-0.4,0.7-0.6,0.9c-0.2,0.3-0.5,0.5-0.8,0.7
									c-0.4-0.5-1.1-1-2-1.6l-1.1,1.9c-0.6,1.1-1.3,2.4-2,3.8c-0.4,0.8-0.7,1.5-1,2c-0.5,0.9-1,1.6-1.5,2.1s-1.2,0.9-1.9,1.2
									c-0.8,0.3-1.8,0.6-3.1,0.8L1470.6,208.2z"
						/>
						<path
							d="M1492.9,219.4l1.7-3.9c-0.6-0.7-1.4-1.4-2.4-2.3c-1.1-0.9-1.8-1.5-2.3-1.8c-1.1,0.6-2.3,1.4-3.6,2.2
									c-0.3-0.1-0.6-0.3-0.8-0.5c-0.3-0.2-0.5-0.6-0.8-0.9c1.1-0.4,3.4-1.7,7-3.7c3.6-2,6.3-3.6,8.2-4.8c0.5,0.3,0.8,0.5,1,0.7
									c0.3,0.2,0.5,0.5,0.8,0.9c-0.9,2.1-2.1,5-3.7,8.8c-1.5,3.8-2.4,6.3-2.7,7.4c-0.5-0.2-0.9-0.5-1.3-0.8
									C1493.6,220.3,1493.3,219.9,1492.9,219.4z M1495.3,213.7c1.1-2.7,2-5,2.7-6.9c-1.6,0.8-3.8,2-6.3,3.6c0.7,0.7,1.5,1.4,2.4,2.2
									C1494.7,213.2,1495.1,213.5,1495.3,213.7z"
						/>
						<path
							d="M1497.8,223.6c1.4-1.5,2.9-3.2,4.3-5c0.7-0.9,1.2-1.6,1.6-2.1c0.3-0.5,0.5-0.9,0.7-1.3c0.1-0.4,0.2-0.8,0.1-1.3
									c0.5-0.1,0.9-0.1,1.3,0c0.4,0.1,0.7,0.2,0.9,0.4c0.8,0.6,0.8,1.5,0.2,2.5c0.8-0.2,1.5-0.2,2.3,0c0.7,0.2,1.4,0.5,1.9,0.9
									c1.7,1.4,1.7,3.2-0.1,5.4l-1.3,1.5c-0.5,0.6-1,1.1-1.4,1.6c-1.4,1.7-2.2,3-2.5,4c-0.5-0.2-0.9-0.5-1.3-0.8
									c-0.4-0.3-0.8-0.7-1.2-1.2c1.7-1.9,3.5-3.9,5.3-6.2c0.5-0.6,0.8-1.2,0.8-1.7c0-0.5-0.3-0.9-0.8-1.4c-0.8-0.7-1.6-1-2.5-1.1l-0.9,1
									c-0.7,0.8-1.5,1.7-2.4,2.9c-1.4,1.7-2.2,3-2.5,4c-0.5-0.3-1-0.6-1.4-0.9C1498.6,224.5,1498.3,224.1,1497.8,223.6z"
						/>
						<path
							d="M1505.6,235.4c0.5-0.6,1-1.1,1.7-1.5c0.7-0.4,1.3-0.7,2.1-0.8c-0.4-0.7-0.3-1.4,0.2-2c0.4-0.5,0.8-0.7,1.4-0.9
									s1.2-0.1,2,0.1c-0.3-0.7-0.4-1.4-0.3-2.2c0.1-0.8,0.5-1.5,1-2.2c0.9-1.1,2-1.8,3.3-1.9c1.3-0.1,2.5,0.3,3.7,1.2
									c0.8,0.6,1.3,1.3,1.6,2.1c0.4,0.2,0.8,0.3,1.4,0.3c0.6,0.1,1.2,0.1,1.9,0c-0.1,0.4-0.2,0.9-0.4,1.3s-0.4,0.8-0.6,1.1
									c-0.7-0.1-1.4-0.4-2.2-0.9c-0.2,0.8-0.5,1.6-1,2.2c-0.6,0.7-1.3,1.3-2.1,1.6c-0.8,0.3-1.6,0.4-2.5,0.2c-0.9-0.2-1.7-0.6-2.5-1.2
									c-0.6-0.5-1.2-0.8-1.8-0.9c-0.5-0.1-1,0.1-1.3,0.4l4,3.6c0.7,0.7,1.2,1.4,1.3,2.2c0.1,0.7-0.1,1.5-0.7,2.1c-0.5,0.6-1.2,1.1-2,1.3
									c-0.8,0.2-1.7,0.2-2.7-0.1c-0.9-0.3-1.9-0.7-2.7-1.4C1506.9,238.1,1505.9,236.8,1505.6,235.4z M1512.4,239.3
									c0.7,0.1,1.2,0,1.5-0.4c0.3-0.3,0.3-0.6,0.3-1c-0.1-0.3-0.4-0.7-0.9-1.1l-3.2-2.9c-0.6,0.3-1.1,0.6-1.5,0.9
									c-0.1,1.3,0.4,2.4,1.6,3.3C1511,238.8,1511.7,239.2,1512.4,239.3z M1517.3,232c0.6-0.1,1.2-0.5,1.7-1.1c0.5-0.6,0.7-1.2,0.7-1.9
									c0-0.7-0.2-1.3-0.7-1.9c-0.6-0.5-1.3-0.7-2-0.6c-0.7,0.1-1.4,0.5-1.9,1.2c-0.5,0.6-0.7,1.3-0.7,2c0,0.7,0.4,1.2,0.9,1.7
									C1516,231.8,1516.6,232,1517.3,232z"
						/>
						<path
							d="M1519.5,241c1.4-1.6,2.8-3.3,4.2-5.1c0.7-0.9,1.2-1.6,1.5-2.1c0.3-0.5,0.5-1,0.6-1.3c0.1-0.4,0.1-0.8,0.1-1.3
									c0.5-0.1,0.9-0.1,1.3-0.1c0.4,0.1,0.7,0.2,0.9,0.4c0.8,0.6,0.9,1.4,0.2,2.5c0.8-0.2,1.5-0.2,2.3-0.1c0.7,0.1,1.4,0.4,1.9,0.8
									c1.3,1,1.6,2.2,1,3.6c0.8-0.3,1.6-0.3,2.4-0.2c0.8,0.1,1.5,0.4,2.1,0.9c1.7,1.4,1.7,3.2,0,5.4l-1.2,1.5c-0.5,0.6-1,1.1-1.3,1.6
									c-1.3,1.7-2.1,3-2.4,4c-0.5-0.2-0.9-0.5-1.3-0.8s-0.8-0.7-1.2-1.2c1.6-1.9,3.4-4,5.2-6.3c0.5-0.7,0.8-1.2,0.7-1.7
									c0-0.5-0.3-0.9-0.9-1.4c-0.8-0.6-1.6-0.9-2.4-1c-0.5,0.6-1.2,1.5-2.1,2.7c-0.3,0.3-0.6,0.8-1.1,1.3c-1.3,1.7-2.1,3-2.4,4
									c-0.5-0.2-0.9-0.5-1.3-0.8s-0.8-0.7-1.2-1.2c1.6-1.9,3.4-4,5.2-6.3c0.5-0.7,0.8-1.2,0.7-1.7c0-0.5-0.3-0.9-0.9-1.4
									c-0.8-0.6-1.6-0.9-2.4-0.9l-0.8,1c-0.7,0.8-1.5,1.8-2.4,2.9c-1.3,1.7-2.1,3.1-2.4,4c-0.5-0.3-1-0.5-1.4-0.9
									C1520.4,241.9,1520,241.6,1519.5,241z"
						/>
						<path
							d="M1536.5,253.6c0-0.7,0.2-1.4,0.7-2.1c1.5-2,4.2-1.7,7.9,0.8c0.8-1.1,1.3-1.9,1.3-2.6c0-0.6-0.3-1.3-1.1-1.9
									c-0.5-0.4-1-0.6-1.6-0.8c-0.6-0.2-1.2-0.3-1.8-0.3l0.2-0.6c1-0.4,2-0.6,3-0.5c1,0.1,1.9,0.4,2.6,1c1,0.8,1.5,1.6,1.6,2.6
									c0.1,1-0.3,2-1.1,3.1c-0.5,0.6-1,1.2-1.6,1.9c-0.7,0.7-1.1,1.3-1.4,1.7c-0.5,0.6-0.8,1.2-0.9,1.6c-0.1,0.4-0.2,1-0.2,1.7
									c-1,0.1-1.8-0.1-2.6-0.5c-0.2-0.6-0.1-1.3,0.2-2c-0.9,0-1.7-0.1-2.5-0.3c-0.8-0.2-1.4-0.5-1.9-0.9
									C1536.9,254.9,1536.6,254.4,1536.5,253.6z M1540.7,255.4c0.6,0.1,1.1,0.2,1.7,0.2l0.1-0.1c0.4-0.5,0.8-1,1.2-1.5l0.9-1
									c-1.1-0.7-2-1.1-2.9-1.1c-0.9,0-1.6,0.3-2.1,1c-0.2,0.3-0.4,0.6-0.4,1c-0.1,0.4-0.1,0.7,0.1,1
									C1539.6,255.1,1540.1,255.3,1540.7,255.4z"
						/>
						<path
							d="M1546.9,262.2c1.4-1.6,2.8-3.3,4.2-5.1c0.7-0.9,1.2-1.6,1.5-2.1c0.3-0.5,0.5-1,0.6-1.3c0.1-0.4,0.1-0.8,0.1-1.3
									c0.5-0.1,0.9-0.1,1.3-0.1c0.4,0.1,0.7,0.2,0.9,0.4c0.9,0.7,0.8,1.6,0,2.9c0.8-0.2,1.5-0.3,2.3-0.3c0.7,0,1.4,0.1,2.1,0.4
									c-0.1,0.3-0.3,0.8-0.6,1.4s-0.6,1-0.9,1.5c-0.2-0.2-0.4-0.4-0.7-0.6c-0.5-0.4-1-0.7-1.5-0.9c-0.5-0.2-1-0.3-1.4-0.4l-0.6,0.7
									c-0.9,1.1-1.6,2-2.3,2.9c-1.3,1.7-2.1,3.1-2.4,4c-0.5-0.2-1-0.5-1.4-0.8C1547.8,263.1,1547.4,262.7,1546.9,262.2z"
						/>
					</g>
					<g className="regions level_two location" id="ettenmoors">
						<rect x="2534.225" y="1497.858" width="164.552" height="32.953" transform="matrix(0.986772, 0.162115, -0.174, 0.984819, -867.518616, -1490.859253)"></rect>
						<path
							d="M1374.2,411.8c0.5-1.2,1.1-2.9,1.8-5c1.3-3.8,2.1-6.5,2.2-8c1.1,0.6,2.7,1.2,5,2.1c2.8,1,5,1.7,6.6,2.1
									c-0.2,0.6-0.5,1.3-0.9,1.9c-0.4,0.7-0.7,1.1-1,1.3c-0.4-0.5-1-0.9-1.7-1.4s-1.5-0.8-2.4-1.1c-1.1-0.4-2.2-0.7-3.2-0.8
									c-0.8,1.9-1.4,3.6-2,5.1l0.8,0.3c0.8,0.3,1.4,0.6,1.8,0.7c1,0.4,1.8,0.6,2.4,0.7c0.6,0.1,1.2,0.2,1.9,0.3
									c-0.1,0.5-0.2,0.9-0.3,1.3c-0.1,0.3-0.3,0.7-0.6,1.3c-2.3-1-4.1-1.7-5.4-2.2c-0.6-0.2-1.1-0.4-1.3-0.4c-0.9,2.5-1.5,4.3-1.7,5.3
									c0.9,0.6,2,1.1,3.2,1.5c0.9,0.3,1.9,0.6,2.9,0.7c1,0.2,1.8,0.2,2.5,0.1c-0.2,0.6-0.5,1.3-0.9,2c-0.4,0.7-0.7,1.1-1,1.3
									c-1.3-0.6-3.2-1.4-5.8-2.3c-2.3-0.8-4-1.3-5.2-1.6C1373,414.8,1373.8,413,1374.2,411.8z"
						/>
						<path
							d="M1389.6,422.8c-0.2-0.5-0.1-1.3,0.2-2.3c0.2-0.7,0.6-1.9,1.2-3.5c0.6-1.8,1.1-3.2,1.5-4.4c0.2-0.6,0.3-1.1,0.4-1.6
									c-0.9-0.3-1.4-0.5-1.7-0.7c-0.2-0.2-0.3-0.5-0.2-0.8c1.7-0.4,3.4-1.2,5.1-2.3l0.7,0.3c-0.2,0.8-0.4,1.6-0.6,2.5
									c1.4,0.4,2.5,0.7,3.2,0.7c-0.2,0.5-0.3,0.9-0.5,1.3c-0.2,0.3-0.5,0.7-0.8,1c-0.5-0.5-1.4-0.9-2.5-1.3c-0.4,1-0.8,2.1-1.1,3.3
									c-0.9,2.9-1.4,5.1-1.6,6.7c1,0.2,1.8,0.4,2.5,0.4l-0.1,0.9c-0.6,0.5-1.3,0.9-2.1,1.1c-0.8,0.2-1.6,0.2-2.2-0.1
									C1390.2,423.6,1389.8,423.3,1389.6,422.8z"
						/>
						<path
							d="M1400.3,426c-0.2-0.5-0.1-1.3,0.2-2.3c0.2-0.7,0.6-1.9,1.1-3.5c0.6-1.8,1-3.2,1.3-4.4c0.2-0.6,0.3-1.1,0.4-1.6
									c-0.9-0.2-1.4-0.5-1.7-0.7c-0.3-0.2-0.3-0.5-0.2-0.8c1.7-0.5,3.3-1.3,5-2.4l0.7,0.3c-0.1,0.8-0.3,1.7-0.6,2.5
									c1.5,0.4,2.5,0.6,3.2,0.6c-0.1,0.5-0.3,0.9-0.5,1.3c-0.2,0.4-0.4,0.7-0.8,1c-0.6-0.4-1.4-0.8-2.6-1.2c-0.4,1-0.7,2.1-1.1,3.3
									c-0.8,2.9-1.3,5.1-1.4,6.7c1,0.2,1.8,0.3,2.5,0.3l0,0.9c-0.6,0.5-1.3,0.9-2.1,1.1s-1.6,0.2-2.2,0
									C1400.9,426.9,1400.5,426.5,1400.3,426z"
						/>
						<path
							d="M1410.5,427.7c-0.6-1.5-0.6-3.4,0-5.7c0.4-1.6,1-2.9,1.9-4c0.9-1.1,1.9-1.8,3.1-2.3c1.2-0.4,2.4-0.5,3.7-0.1
									c1.7,0.4,2.9,1.4,3.5,2.9c0.6,1.5,0.6,3.4,0,5.8c-1.2,0-2.7-0.1-4.4-0.3c-1.7-0.2-3.3-0.5-4.9-0.8c-0.2,1.5-0.1,2.6,0.3,3.4
									c0.4,0.8,1.2,1.4,2.4,1.6c0.9,0.2,1.8,0.3,2.6,0.3c0.9,0,1.6-0.2,2.3-0.4l0.1,0.7c-0.9,0.8-2,1.3-3.3,1.6s-2.5,0.3-3.7,0
									C1412.2,430.1,1411.1,429.1,1410.5,427.7z M1416.9,422.3c1.1,0,2-0.1,2.7-0.3c0.3-1.3,0.2-2.4-0.2-3.3c-0.4-1-1.2-1.6-2.2-1.8
									c-0.8,0.3-1.5,0.9-2.2,1.8c-0.7,0.9-1.2,2.1-1.5,3.4C1414.7,422.3,1415.8,422.3,1416.9,422.3z"
						/>
						<path
							d="M1426.4,433.2c0.7-2.4,1.3-4.9,1.8-7.6c0.3-1.3,0.5-2.3,0.5-3c0.1-0.7,0.1-1.3,0-1.7c-0.1-0.4-0.3-0.9-0.5-1.4
									c0.4-0.4,0.9-0.6,1.3-0.7c0.4-0.1,0.8-0.1,1.2-0.1c1.2,0.2,1.7,1.1,1.6,2.6c0.7-0.6,1.5-1,2.4-1.3s1.7-0.3,2.5-0.1
									c2.6,0.5,3.5,2.5,2.8,5.8l-0.5,2.2c-0.3,0.9-0.5,1.7-0.6,2.4c-0.5,2.5-0.7,4.3-0.4,5.5c-0.6,0-1.2,0-1.8-0.2
									c-0.6-0.1-1.2-0.3-1.9-0.6c0.8-2.9,1.5-6,2.2-9.3c0.2-1,0.2-1.7-0.1-2.2c-0.3-0.5-0.8-0.8-1.6-1c-1.2-0.3-2.3-0.2-3.2,0.2
									l-0.4,1.5c-0.3,1.2-0.7,2.6-1,4.3c-0.5,2.5-0.7,4.3-0.5,5.5c-0.7,0-1.3-0.1-1.9-0.2C1427.8,433.7,1427.2,433.5,1426.4,433.2z"
						/>
						<path
							d="M1444.9,437c0.5-2.4,1-4.9,1.4-7.7c0.2-1.3,0.3-2.3,0.4-3.1c0-0.7,0-1.3-0.1-1.7c-0.1-0.4-0.3-0.9-0.6-1.4
									c0.4-0.4,0.9-0.7,1.3-0.8c0.4-0.1,0.8-0.2,1.1-0.1c1.2,0.2,1.7,1,1.7,2.4c0.7-0.6,1.4-1.1,2.3-1.3c0.8-0.3,1.6-0.4,2.4-0.3
									c1.9,0.3,2.9,1.3,3.1,3.2c0.7-0.7,1.5-1.3,2.4-1.6c0.9-0.3,1.8-0.4,2.6-0.3c2.6,0.4,3.6,2.3,3.1,5.6l-0.4,2.3
									c-0.2,0.9-0.4,1.7-0.5,2.4c-0.4,2.5-0.4,4.3-0.2,5.5c-0.6,0-1.2,0-1.8-0.1s-1.2-0.3-2-0.5c0.6-2.9,1.2-6,1.7-9.4
									c0.2-1,0.1-1.7-0.2-2.2c-0.3-0.5-0.8-0.8-1.7-0.9c-1.1-0.2-2.1,0-3.1,0.4c-0.1,0.9-0.3,2.2-0.7,4c-0.1,0.5-0.2,1.2-0.3,1.9
									c-0.4,2.5-0.5,4.4-0.2,5.5c-0.6,0.1-1.2,0-1.8-0.1s-1.2-0.3-2-0.5c0.6-2.9,1.2-6,1.7-9.4c0.2-1,0.1-1.7-0.2-2.2
									c-0.3-0.5-0.8-0.8-1.7-0.9c-1.1-0.2-2.1,0-3,0.4l-0.3,1.6c-0.3,1.2-0.5,2.7-0.8,4.4c-0.4,2.5-0.5,4.4-0.2,5.5
									c-0.7,0-1.3,0-1.9-0.1C1446.3,437.4,1445.7,437.2,1444.9,437z"
						/>
						<path
							d="M1471.6,439.2c-1-1.5-1.3-3.3-1.1-5.5c0.2-1.6,0.6-3,1.4-4.2c0.7-1.2,1.7-2.1,2.9-2.6c1.2-0.6,2.5-0.8,3.9-0.6
									c1.2,0.1,2.3,0.6,3.2,1.3c0.9,0.7,1.5,1.7,2,2.8s0.5,2.5,0.4,3.9c-0.2,1.6-0.6,3-1.4,4.2c-0.8,1.2-1.8,2.1-3,2.6s-2.5,0.8-4,0.6
									C1473.9,441.5,1472.5,440.6,1471.6,439.2z M1479.7,439.1c0.8-0.9,1.3-2.1,1.5-3.8c0.2-1.5,0-2.8-0.6-4c-0.5-1.2-1.3-2-2.4-2.5
									c-1.3-0.1-2.4,0.2-3.2,1.1c-0.9,0.9-1.4,2.1-1.6,3.8c-0.2,1.9,0,3.4,0.6,4.6c0.6,1.2,1.5,1.8,2.6,1.9
									C1477.8,440.3,1478.8,440,1479.7,439.1z"
						/>
						<path
							d="M1489,440.8c-1-1.4-1.5-3.3-1.3-5.5c0.1-1.6,0.5-3,1.2-4.2c0.7-1.2,1.6-2.1,2.8-2.8c1.2-0.6,2.4-0.9,3.8-0.8
									c1.3,0.1,2.3,0.5,3.3,1.1c0.9,0.7,1.6,1.6,2.1,2.8c0.5,1.2,0.6,2.5,0.5,3.9c-0.1,1.6-0.5,3-1.2,4.2c-0.7,1.2-1.7,2.1-2.9,2.8
									c-1.2,0.6-2.5,0.9-4,0.8C1491.5,443.1,1490,442.3,1489,440.8z M1497.1,440.5c0.8-0.9,1.2-2.2,1.3-3.9c0.1-1.5-0.1-2.8-0.7-4
									c-0.6-1.1-1.4-1.9-2.5-2.4c-1.3-0.1-2.4,0.3-3.2,1.2s-1.3,2.2-1.4,3.8c-0.1,1.9,0.1,3.4,0.8,4.6c0.7,1.2,1.5,1.8,2.7,1.8
									C1495.3,441.8,1496.3,441.4,1497.1,440.5z"
						/>
						<path
							d="M1506.5,443.4c0.2-2.4,0.4-5,0.5-7.8c0-1.3,0-2.4,0-3.1c-0.1-0.7-0.2-1.3-0.3-1.7c-0.2-0.4-0.4-0.9-0.8-1.3
									c0.4-0.4,0.8-0.7,1.2-1c0.4-0.2,0.8-0.3,1.2-0.3c1.3,0,1.9,1,1.9,2.8c0.6-0.8,1.2-1.4,1.9-1.9c0.7-0.5,1.4-0.8,2.3-1
									c0.1,0.4,0.3,1,0.4,1.7c0.1,0.7,0.1,1.4,0.1,2c-0.3-0.1-0.6-0.1-1.1-0.1c-0.7,0-1.4,0-2.1,0.2c-0.7,0.2-1.2,0.4-1.6,0.6l-0.1,1.1
									c-0.1,1.6-0.2,3-0.2,4.3c-0.1,2.6,0.1,4.4,0.5,5.5c-0.7,0.1-1.3,0.2-1.9,0.2C1507.8,443.7,1507.2,443.6,1506.5,443.4z"
						/>
						<path
							d="M1516.7,442.8c0-0.5,0-0.9,0.1-1.3s0.2-0.9,0.5-1.4c0.4,0.6,1,1.1,1.7,1.4c0.7,0.3,1.5,0.5,2.3,0.5c2.1,0,3.2-0.6,3.2-1.9
									c0-0.5-0.2-1-0.7-1.4c-0.5-0.4-1.2-0.8-2.2-1.2c-1-0.5-1.8-0.9-2.4-1.3c-0.6-0.4-1.1-0.9-1.6-1.6s-0.7-1.5-0.7-2.4
									c0-1.1,0.5-2.1,1.4-2.8c0.9-0.7,2.1-1.1,3.7-1.1c2,0,3.7,0.3,5.1,0.9c0,0.4-0.1,0.9-0.3,1.6c-0.2,0.6-0.5,1.2-0.7,1.7
									c-0.8-0.8-1.6-1.4-2.4-1.9c-0.8-0.5-1.4-0.7-1.9-0.7c-0.5,0.1-1,0.3-1.3,0.7c-0.3,0.3-0.5,0.7-0.5,1.1c0,0.7,0.3,1.2,0.8,1.6
									c0.5,0.4,1.3,0.9,2.4,1.5c0.9,0.4,1.7,0.9,2.3,1.2c0.6,0.4,1.1,0.9,1.5,1.5c0.4,0.6,0.6,1.4,0.6,2.3c0,1.4-0.5,2.4-1.5,3.2
									c-1,0.8-2.4,1.1-4.3,1.1C1519.7,444.1,1518,443.7,1516.7,442.8z"
						/>
					</g>
					<g className="level_two fade location" id="mount_gundabad">
						<rect x="1245.999" y="1251.787" width="80.907" height="27.538" transform="matrix(0.856043, -0.516905, 0.506593, 0.862269, -157.756165, -157.283813)"></rect>
						<rect x="1848.579" y="1251.789" width="120.035" height="27.538" transform="matrix(0.842667, -0.538435, 0.528285, 0.849153, -629.636902, 210.164902)"></rect>
						<path
							d="M1555.3,295.6c-1-1.9-2.6-4.4-4.7-7.8c-1.1-1.7-1.9-3-2.5-3.8c-0.6-0.8-1.1-1.4-1.4-1.7c0.4-0.4,0.7-0.7,1.2-1
									c0.3-0.2,0.8-0.5,1.4-0.7c1.2,0.9,2.9,2.1,5.1,3.6c2.2,1.5,4,2.7,5.3,3.6c-0.2-1.7-0.6-3.8-1-6.3c-0.4-2.5-0.8-4.6-1.1-6.3
									c0.2-0.3,0.6-0.6,1.2-1c0.4-0.2,0.9-0.5,1.4-0.7c0.5,0.9,1.1,2,2,3.5c0.9,1.5,1.7,2.9,2.6,4.2c2,3.2,3.3,5,4,5.5
									c-0.4,0.4-0.7,0.7-1.2,1c-0.4,0.3-0.9,0.5-1.5,0.7c-0.5-0.9-1.1-2.1-2-3.6s-1.7-2.9-2.6-4.2c-0.5-0.8-1.2-1.8-1.9-2.9
									c0.5,3.4,1.2,7.8,2.2,13.3c-0.1,0.2-0.3,0.4-0.4,0.5c-0.1,0.1-0.3,0.1-0.5,0.2c-1.1-0.8-2.7-1.9-4.8-3.4c-2.1-1.4-3.9-2.7-5.6-3.8
									c1.1,1.8,1.9,3.1,2.5,4.1c1.9,3,3.2,4.9,3.9,5.6c-0.2,0.3-0.5,0.5-0.8,0.7C1556,295.3,1555.7,295.5,1555.3,295.6z"
						/>
						<path
							d="M1573.4,282.5c-1.4-0.5-2.5-1.5-3.5-3.1c-0.7-1.1-1.1-2.3-1.2-3.4c-0.1-1.1,0.1-2.2,0.6-3.1c0.5-1,1.2-1.7,2.2-2.3
									c0.9-0.5,1.8-0.8,2.7-0.8c0.9,0,1.8,0.3,2.7,0.9c0.8,0.6,1.6,1.4,2.2,2.4c0.7,1.1,1.1,2.3,1.2,3.4s-0.1,2.2-0.6,3.2
									c-0.5,1-1.3,1.8-2.3,2.4C1576.1,282.9,1574.8,283,1573.4,282.5z M1578.7,278.5c0.1-1-0.2-2-0.9-3.2c-0.6-1.1-1.4-1.8-2.3-2.3
									c-0.9-0.5-1.9-0.6-2.8-0.4c-0.9,0.6-1.4,1.3-1.6,2.3c-0.1,1,0.1,2.1,0.8,3.2c0.8,1.3,1.7,2.2,2.7,2.7c1,0.5,1.9,0.5,2.6,0
									C1578.1,280.2,1578.6,279.5,1578.7,278.5z"
						/>
						<path
							d="M1585.2,272.6l-0.9-1.7l-1-1.8c-1-1.7-1.8-2.8-2.6-3.4c0.6-0.8,1.3-1.5,2.3-2c1.1,2.2,2.5,4.6,4.1,7.3
									c0.4,0.7,0.8,1.1,1.3,1.3c0.5,0.2,1,0.1,1.6-0.3c0.8-0.5,1.4-1.1,1.8-1.8c-0.7-1.2-1.2-2.2-1.7-3.1l-0.8-1.4
									c-1-1.7-1.8-2.8-2.6-3.4c0.6-0.8,1.4-1.5,2.3-2.1c0.6,1.2,1.4,2.7,2.3,4.3s1.5,2.8,1.8,3.3c0.4,0.6,0.7,1.1,1,1.4
									c0.3,0.3,0.8,0.5,1.4,0.7c-0.3,0.9-0.7,1.5-1.4,2c-0.7-0.1-1.3-0.4-1.8-1c-0.1,0.7-0.4,1.4-0.8,2c-0.4,0.6-0.9,1.1-1.6,1.5
									C1588.2,275.7,1586.6,275,1585.2,272.6z"
						/>
						<path
							d="M1601,267.9c-0.9-1.8-1.8-3.7-2.9-5.7c-0.5-1-1-1.7-1.3-2.2c-0.3-0.5-0.6-0.8-0.9-1.1c-0.3-0.2-0.7-0.4-1.1-0.6
									c0.1-0.5,0.2-0.9,0.4-1.2c0.2-0.3,0.4-0.5,0.7-0.7c0.8-0.5,1.6-0.2,2.3,0.8c0.1-0.8,0.4-1.4,0.8-2.1c0.4-0.6,0.9-1.1,1.5-1.4
									c1.9-1,3.5-0.4,4.8,2l0.9,1.7c0.3,0.7,0.6,1.3,0.9,1.8c1,1.8,1.9,3,2.7,3.6c-0.4,0.4-0.8,0.6-1.2,0.9c-0.4,0.2-0.9,0.4-1.5,0.6
									c-1.1-2.2-2.3-4.5-3.7-7c-0.4-0.7-0.8-1.1-1.2-1.3c-0.4-0.2-0.9-0.1-1.5,0.3c-0.9,0.5-1.5,1.1-1.9,1.8l0.6,1.1
									c0.4,0.9,1,2,1.7,3.2c1,1.8,1.9,3.1,2.7,3.7c-0.4,0.4-0.8,0.7-1.3,0.9C1602.1,267.5,1601.6,267.7,1601,267.9z"
						/>
						<path
							d="M1615.7,259.5c-0.4-0.2-0.8-0.7-1.2-1.4c-0.3-0.5-0.8-1.4-1.4-2.7c-0.7-1.4-1.3-2.5-1.7-3.4c-0.2-0.4-0.5-0.8-0.7-1.2
									c-0.6,0.3-1.1,0.5-1.4,0.6c-0.3,0-0.5-0.1-0.6-0.3c0.7-1.2,1.2-2.6,1.6-4.3l0.6-0.2c0.4,0.6,0.8,1.2,1.1,1.8
									c1.1-0.6,1.8-1.1,2.3-1.5c0.2,0.4,0.3,0.7,0.4,1c0.1,0.3,0.1,0.7,0.2,1c-0.6,0.1-1.3,0.3-2.2,0.8c0.4,0.8,0.8,1.7,1.3,2.5
									c1.2,2.2,2.2,3.7,3,4.7c0.7-0.5,1.3-0.9,1.6-1.3l0.5,0.5c0,0.6-0.2,1.3-0.6,1.8c-0.4,0.6-0.8,1-1.3,1.3
									C1616.5,259.7,1616,259.7,1615.7,259.5z"
						/>
						<path
							d="M1600.3,291.5c-1.6-0.6-3-1.9-4.2-4c-0.8-1.5-1.3-2.9-1.5-4.4c-0.1-1.5,0.1-2.8,0.7-4s1.5-2.2,2.7-2.9
									c1.4-0.8,2.8-1.3,4.1-1.5c0.2,0.4,0.4,0.8,0.5,1.4c0.2,0.6,0.2,1,0.2,1.4c-1-0.1-2-0.1-3,0c-1,0.1-1.7,0.3-2.2,0.6
									c-0.4,0.4-0.7,1.1-0.9,1.9c-0.1,0.8-0.1,1.7,0.1,2.7c0.2,1,0.5,1.9,1,2.7c1.1,2,2.3,3.2,3.4,3.7s2.4,0.3,3.9-0.6
									c0.7-0.4,1.2-0.8,1.6-1.3c-1.6-2.8-2.8-4.5-3.4-5c0.4-0.3,0.7-0.6,1.1-0.8c0.4-0.2,0.9-0.4,1.4-0.6c0.9,1.8,1.8,3.7,2.9,5.6
									c-0.2,0.8-0.7,1.7-1.3,2.5c-0.7,0.8-1.5,1.5-2.4,2C1603.5,291.8,1601.9,292,1600.3,291.5z"
						/>
						<path
							d="M1612.6,281.8l-0.9-1.7l-1-1.8c-1-1.7-1.8-2.8-2.5-3.4c0.6-0.8,1.4-1.5,2.3-2c1.1,2.2,2.5,4.6,4,7.4
									c0.4,0.7,0.8,1.1,1.3,1.3c0.5,0.2,1,0.1,1.6-0.3c0.8-0.4,1.4-1.1,1.8-1.8c-0.7-1.2-1.2-2.2-1.7-3.1l-0.8-1.4
									c-1-1.7-1.8-2.8-2.5-3.4c0.6-0.8,1.4-1.5,2.4-2c0.6,1.2,1.4,2.7,2.3,4.4s1.5,2.8,1.8,3.3c0.4,0.6,0.7,1.1,1,1.4
									c0.3,0.3,0.8,0.5,1.4,0.7c-0.3,0.8-0.7,1.5-1.4,2c-0.7-0.1-1.3-0.4-1.8-1c-0.2,0.7-0.4,1.4-0.9,2c-0.4,0.6-0.9,1.1-1.6,1.5
									C1615.6,284.9,1614,284.2,1612.6,281.8z"
						/>
						<path
							d="M1628.1,277.3c-0.9-1.8-1.9-3.7-3-5.6c-0.5-1-1-1.7-1.3-2.1c-0.3-0.5-0.7-0.8-0.9-1c-0.3-0.2-0.7-0.4-1.1-0.6
									c0.1-0.5,0.2-0.9,0.4-1.2c0.2-0.3,0.4-0.5,0.7-0.7c0.8-0.5,1.6-0.2,2.4,0.7c0.1-0.8,0.4-1.4,0.8-2.1c0.4-0.6,0.9-1.1,1.5-1.4
									c1.9-1.1,3.5-0.4,4.9,2l0.9,1.7c0.3,0.7,0.6,1.3,0.9,1.8c1,1.8,1.9,3,2.7,3.6c-0.4,0.4-0.8,0.7-1.2,0.9c-0.4,0.2-0.9,0.5-1.5,0.7
									c-1.1-2.2-2.3-4.5-3.7-6.9c-0.4-0.7-0.8-1.1-1.2-1.3c-0.4-0.2-0.9-0.1-1.5,0.3c-0.9,0.5-1.5,1.1-1.8,1.8l0.6,1.1
									c0.5,0.9,1,2,1.7,3.2c1,1.8,1.9,3,2.7,3.6c-0.4,0.4-0.8,0.7-1.3,0.9C1629.1,276.9,1628.7,277.1,1628.1,277.3z"
						/>
						<path
							d="M1641.1,268.4c-1.2-0.5-2.3-1.7-3.3-3.6c-1.1-1.9-1.5-3.5-1.4-4.8c0.1-1.3,0.9-2.4,2.2-3.2c1.1-0.6,2.2-0.8,3.5-0.6
									c-1.1-1.7-2-2.9-2.7-3.6c0.4-0.5,0.7-0.8,1.1-1.1c0.4-0.3,0.8-0.6,1.3-0.9c1,2.3,3,6,6,11.1c0.3,0.6,0.7,1.1,1,1.3
									c0.3,0.3,0.8,0.5,1.4,0.7c-0.2,0.8-0.7,1.5-1.4,2c-0.6-0.1-1.2-0.4-1.7-0.9c-0.2,0.7-0.4,1.4-0.8,1.9c-0.4,0.6-0.8,1-1.4,1.3
									C1643.5,268.9,1642.3,269,1641.1,268.4z M1645.6,265.5c0.3-0.4,0.5-0.9,0.7-1.4c-0.3-0.5-0.8-1.5-1.5-2.8
									c-0.8-1.4-1.4-2.6-1.9-3.4c-0.9-0.1-1.7,0.1-2.6,0.6c-0.8,0.5-1.3,1.2-1.4,2.1c-0.1,0.9,0.2,2,0.9,3.2c0.7,1.2,1.4,2,2.3,2.5
									c0.8,0.5,1.6,0.6,2.5,0.3C1645,266.3,1645.3,265.9,1645.6,265.5z"
						/>
						<path
							d="M1654.2,261.9c-0.7-0.2-1.2-0.7-1.6-1.4c-1.2-2.1,0-4.4,3.6-6.8c-0.7-1.1-1.3-1.8-1.8-2.1c-0.6-0.3-1.2-0.2-2.1,0.3
									c-0.5,0.3-0.9,0.7-1.3,1.1c-0.4,0.5-0.7,1-1,1.5l-0.5-0.4c0-1,0.2-2,0.7-2.9s1.1-1.6,1.8-2c1-0.6,2-0.8,2.9-0.5
									c0.9,0.3,1.7,1,2.3,2.1c0.4,0.6,0.7,1.3,1.1,2.2c0.4,0.9,0.7,1.5,1,1.9c0.4,0.7,0.7,1.1,1.1,1.4s0.8,0.5,1.4,0.8
									c-0.3,0.9-0.7,1.6-1.4,2.1c-0.6-0.1-1.2-0.4-1.7-0.9c-0.3,0.8-0.7,1.5-1.2,2.1c-0.5,0.6-1,1.1-1.5,1.4
									C1655.5,262,1654.9,262.1,1654.2,261.9z M1657.3,258.8c0.3-0.5,0.6-0.9,0.8-1.4l-0.1-0.1c-0.3-0.6-0.6-1.1-0.9-1.6l-0.6-1.2
									c-1,0.7-1.7,1.4-2.1,2.2c-0.3,0.8-0.3,1.5,0.1,2.2c0.2,0.3,0.4,0.5,0.7,0.7s0.6,0.3,1,0.3C1656.6,259.6,1657,259.3,1657.3,258.8z"
						/>
						<path
							d="M1668.9,253.5c-0.7,0.1-1.3,0-1.8-0.1l0.1,1.4c-0.2,0.1-0.4,0.2-0.8,0.4c-0.5-0.8-1.2-2.1-2.3-3.9c-1.1-1.7-2-3.3-2.9-4.5
									c-1.5-2.3-2.7-3.9-3.6-4.6c0.5-0.8,1.3-1.5,2.2-2.2c0.6,1.1,1.5,2.7,2.8,4.7c0.4-1.4,1.1-2.4,2.1-3c1.1-0.7,2.3-0.9,3.6-0.4
									c1.3,0.5,2.4,1.5,3.4,3c1.2,1.8,1.7,3.4,1.5,4.9c-0.2,1.5-1,2.7-2.4,3.6C1670.2,253.1,1669.5,253.4,1668.9,253.5z M1671.1,249.5
									c0.1-0.9-0.3-1.9-1-3c-1.4-2.2-3.1-3.2-4.9-2.8c-0.8,0.5-1.4,1.2-1.9,2.1c1,1.7,1.7,2.7,2,3.2c0.9,1.4,1.6,2.3,2.1,2.8
									c0.9,0.2,1.7,0.1,2.3-0.3C1670.6,251,1671,250.3,1671.1,249.5z"
						/>
						<path
							d="M1679.1,246.1c-0.7-0.1-1.3-0.6-1.8-1.2c-1.5-1.9-0.6-4.3,2.7-7.2c-0.8-1.1-1.5-1.7-2.1-1.9c-0.6-0.2-1.3,0-2,0.5
									c-0.5,0.3-0.8,0.8-1.2,1.3c-0.3,0.5-0.6,1-0.8,1.6l-0.5-0.3c-0.1-1,0-2,0.3-2.9c0.3-0.9,0.9-1.7,1.6-2.2c1-0.7,1.9-1,2.8-0.8
									s1.8,0.8,2.6,1.8c0.4,0.6,0.9,1.2,1.4,2c0.5,0.8,0.9,1.4,1.2,1.8c0.5,0.6,0.9,1,1.2,1.3c0.4,0.2,0.9,0.4,1.5,0.6
									c-0.2,0.9-0.5,1.7-1.1,2.3c-0.6,0-1.2-0.2-1.8-0.7c-0.2,0.8-0.5,1.5-0.9,2.2s-0.8,1.2-1.3,1.6
									C1680.4,246.1,1679.8,246.2,1679.1,246.1z M1681.9,242.7c0.3-0.5,0.5-1,0.6-1.5l-0.1-0.1c-0.4-0.5-0.7-1-1.1-1.5l-0.7-1.1
									c-0.9,0.8-1.5,1.6-1.8,2.4c-0.2,0.8-0.1,1.5,0.4,2.2c0.2,0.3,0.5,0.5,0.8,0.7c0.3,0.2,0.7,0.2,1,0.2
									C1681.3,243.6,1681.6,243.2,1681.9,242.7z"
						/>
						<path
							d="M1690.3,236.4c-1.3-0.3-2.6-1.3-4-2.9c-1.4-1.6-2.2-3.1-2.3-4.5c-0.1-1.3,0.4-2.5,1.6-3.5c0.9-0.8,2-1.2,3.3-1.3
									c-1.4-1.4-2.5-2.5-3.3-3c0.3-0.5,0.5-1,0.8-1.3c0.3-0.4,0.7-0.7,1.1-1.1c1.5,2,4.1,5.3,8,9.8c0.5,0.5,0.9,0.9,1.3,1.1
									c0.4,0.2,0.9,0.4,1.5,0.5c-0.1,0.9-0.4,1.6-1,2.3c-0.6,0-1.3-0.2-1.9-0.6c0,0.7-0.2,1.4-0.4,2c-0.3,0.6-0.6,1.1-1.1,1.5
									C1692.7,236.4,1691.5,236.7,1690.3,236.4z M1694.2,232.7c0.2-0.5,0.3-1,0.4-1.5c-0.4-0.5-1.1-1.3-2-2.4c-1-1.3-1.9-2.3-2.5-3
									c-0.9,0.1-1.7,0.5-2.4,1.1c-0.7,0.6-1,1.4-0.9,2.3c0.1,0.9,0.6,1.9,1.5,2.9c0.9,1,1.8,1.7,2.7,2c0.9,0.3,1.7,0.2,2.5-0.2
									C1693.7,233.6,1694,233.1,1694.2,232.7z"
						/>
					</g>
					<g className="level_two fade location " id="langwell">
						<rect x="1612.793" y="1251.793" width="104.724" height="27.538" transform="matrix(0.969835, 0.243763, -0.255445, 0.966899, 365.376251, -1272.940796)"></rect>
						<path
							d="M1610.6,348.2c0-1-0.1-2.4-0.1-4.3c-0.2-3.9-0.4-6.4-0.8-7.2c0.5-0.1,1.1-0.2,1.6-0.3c0.5,0,1.1,0,1.7,0.1
									c-0.1,1.2-0.1,2.7-0.1,4.5c0,1.9,0.1,3.6,0.1,5.1c0.1,2.2,0.2,3.8,0.3,4.7c0.9,0.2,1.9,0.2,3.1,0.2c0.7,0,1.4-0.1,2.1-0.3
									c0.7-0.2,1.3-0.4,1.7-0.7c0,0.6,0,1.3-0.1,2c-0.1,0.7-0.2,1.1-0.4,1.4c-1.3-0.1-2.8-0.1-4.6,0c-2.1,0.1-3.7,0.2-4.7,0.4
									C1610.6,351,1610.6,349.2,1610.6,348.2z"
						/>
						<path
							d="M1622.3,352.8c-0.4-0.6-0.6-1.3-0.6-2.2c0.2-2.6,2.6-3.9,7.2-3.9c0.1-1.4,0-2.4-0.4-3c-0.3-0.6-1-0.9-2-1
									c-0.6,0-1.2,0-1.9,0.2c-0.6,0.2-1.2,0.4-1.8,0.8l-0.2-0.6c0.6-0.9,1.4-1.6,2.3-2.2c0.9-0.5,1.9-0.8,2.8-0.7
									c1.3,0.1,2.2,0.5,2.9,1.3c0.6,0.8,0.9,1.9,0.8,3.3c0,0.8-0.2,1.6-0.3,2.6c-0.1,1-0.2,1.8-0.3,2.3c0,0.8,0,1.5,0.1,1.9
									c0.1,0.5,0.4,1,0.8,1.5c-0.8,0.6-1.6,1-2.5,1.1c-0.5-0.4-0.8-1-1-1.8c-0.7,0.5-1.5,0.9-2.3,1.1c-0.8,0.3-1.5,0.4-2.2,0.4
									C1623.3,353.7,1622.7,353.4,1622.3,352.8z M1626.9,351.9c0.6-0.2,1.1-0.5,1.5-0.8l0-0.2c0-0.7,0.1-1.3,0.2-2l0.2-1.4
									c-1.3,0-2.4,0.2-3.2,0.7c-0.8,0.5-1.2,1.2-1.2,2c0,0.4,0,0.7,0.2,1.1s0.4,0.7,0.7,0.9C1625.8,352.3,1626.3,352.2,1626.9,351.9z"
						/>
						<path
							d="M1635.4,354.3c0.5-2.1,1-4.3,1.4-6.7c0.2-1.2,0.3-2.1,0.4-2.7c0-0.6,0-1.1-0.1-1.5c-0.1-0.4-0.2-0.8-0.5-1.2
									c0.4-0.3,0.8-0.6,1.1-0.7c0.4-0.1,0.7-0.1,1-0.1c1,0.2,1.5,0.9,1.4,2.2c0.6-0.5,1.3-0.9,2.1-1.2c0.8-0.2,1.5-0.3,2.2-0.1
									c2.3,0.4,3.1,2.1,2.6,5l-0.4,2c-0.2,0.8-0.4,1.5-0.5,2.1c-0.4,2.2-0.5,3.8-0.3,4.9c-0.6,0-1.1,0-1.6-0.1c-0.5-0.1-1.1-0.3-1.7-0.5
									c0.6-2.5,1.2-5.3,1.7-8.3c0.2-0.8,0.1-1.5-0.1-1.9s-0.7-0.7-1.4-0.8c-1.1-0.2-2-0.1-2.8,0.2l-0.3,1.4c-0.3,1.1-0.5,2.3-0.8,3.8
									c-0.4,2.2-0.5,3.8-0.3,4.9c-0.6,0-1.2,0-1.7-0.1C1636.6,354.7,1636.1,354.6,1635.4,354.3z"
						/>
						<path
							d="M1647,361.1c0.2-0.7,0.6-1.4,1.1-2c0.5-0.6,1.1-1.1,1.7-1.5c-0.6-0.5-0.8-1.2-0.6-2c0.2-0.6,0.5-1,1.1-1.4
									c0.5-0.3,1.2-0.5,1.9-0.6c-0.5-0.6-0.9-1.2-1-2c-0.1-0.8-0.1-1.6,0.2-2.5c0.5-1.4,1.3-2.5,2.5-3.1c1.2-0.6,2.5-0.7,4-0.2
									c1,0.3,1.7,0.8,2.3,1.5c0.4,0,0.9-0.1,1.5-0.2c0.6-0.2,1.2-0.4,1.8-0.7c0.1,0.4,0.1,0.9,0.1,1.4c0,0.5-0.1,0.9-0.2,1.2
									c-0.7,0.2-1.5,0.1-2.4-0.1c0.1,0.9,0.1,1.7-0.2,2.5c-0.3,0.9-0.8,1.7-1.4,2.3c-0.7,0.6-1.5,1-2.4,1.1s-1.9,0.1-2.8-0.2
									c-0.8-0.3-1.5-0.3-2-0.2c-0.6,0.1-0.9,0.4-1.1,0.9l5.2,2c1,0.4,1.7,1,2,1.6c0.4,0.7,0.4,1.4,0.1,2.3c-0.3,0.8-0.8,1.5-1.5,2
									c-0.7,0.5-1.6,0.8-2.6,0.9c-1,0.1-2.1,0-3.2-0.4C1649.3,363.3,1647.9,362.4,1647,361.1z M1655.1,362.4c0.7-0.1,1.2-0.5,1.3-1
									c0.1-0.4,0.1-0.8-0.1-1c-0.2-0.3-0.6-0.5-1.3-0.8l-4.2-1.6c-0.5,0.5-0.9,1-1.1,1.5c0.4,1.3,1.3,2.2,2.7,2.6
									C1653.5,362.5,1654.4,362.5,1655.1,362.4z M1657.1,353.5c0.6-0.3,1-0.9,1.3-1.7c0.2-0.7,0.2-1.4,0-2.1c-0.2-0.7-0.7-1.2-1.3-1.6
									c-0.8-0.2-1.5-0.2-2.2,0.2c-0.7,0.4-1.1,1-1.4,1.8c-0.3,0.8-0.2,1.5,0.1,2.1c0.3,0.6,0.8,1.1,1.5,1.3
									C1655.8,353.9,1656.5,353.8,1657.1,353.5z"
						/>
						<path
							d="M1663.6,359.6l0.5-4c0.1-1,0.2-1.8,0.3-2.5c0.1-0.9,0.1-1.8,0.1-2.6c0-0.8-0.1-1.3-0.2-1.7c0.6,0,1.1,0,1.6,0.1
									c0.5,0.1,1.1,0.3,1.7,0.5c-0.2,0.9-0.5,2.9-0.9,6c-0.4,3-0.6,4.9-0.5,5.5c3.1-3.8,5-6.2,5.7-7.2c0-0.9-0.1-1.6-0.3-2
									c1.1-0.1,2.2,0.2,3.4,0.6c-0.2,0.9-0.5,3-0.9,6.2c-0.4,3.2-0.6,5-0.5,5.4c2.3-2.8,3.9-4.7,4.7-5.9c0.9-1.2,1.4-2,1.6-2.5
									c0.1-0.2,0.1-0.4,0.2-0.7c0.8,0.1,1.6,0.3,2.6,0.7c-1,1-2.3,2.5-3.8,4.2c-1.5,1.8-3.1,3.7-4.8,5.9l-1.9,0.3
									c-0.5-0.2-0.9-0.6-1.1-1.2c-0.2-0.6-0.3-1.3-0.2-2.2c0.3-2.9,0.6-4.9,0.7-6.1c-1.3,1.5-2.9,3.6-4.9,6.2l-1.9,0.3
									C1663.8,362.6,1663.4,361.4,1663.6,359.6z"
						/>
						<path
							d="M1680.5,367.6c-0.3-1.4-0.1-3,0.7-4.9c0.6-1.3,1.3-2.4,2.2-3.2c0.9-0.8,1.9-1.3,3-1.5c1.1-0.2,2.2-0.1,3.3,0.4
									c1.5,0.6,2.3,1.6,2.6,3c0.3,1.4,0,3-0.8,5.1c-1.1-0.2-2.3-0.4-3.8-0.8c-1.4-0.4-2.8-0.8-4.2-1.4c-0.4,1.3-0.5,2.3-0.2,3
									c0.3,0.8,0.9,1.3,1.8,1.7c0.7,0.3,1.5,0.5,2.2,0.6c0.7,0.1,1.4,0.1,2-0.1l0,0.6c-0.9,0.6-1.9,0.9-3.1,1s-2.2-0.1-3.2-0.5
									C1681.7,369.9,1680.8,368.9,1680.5,367.6z M1686.8,363.8c1,0.1,1.8,0.2,2.4,0.1c0.4-1.1,0.5-2,0.3-2.9c-0.3-0.9-0.8-1.5-1.6-1.9
									c-0.7,0.1-1.4,0.5-2.1,1.3c-0.7,0.7-1.3,1.7-1.8,2.8C1684.9,363.4,1685.8,363.6,1686.8,363.8z"
						/>
						<path
							d="M1692.5,373.9c-0.1-0.4,0.1-1.1,0.5-2c0.3-0.6,0.7-1.6,1.4-2.9c0.7-1.5,1.3-2.7,1.7-3.6c1.4-3.2,2.2-5.5,2.5-7
									c0.6,0,1.2,0.1,1.6,0.2s1,0.3,1.6,0.6c-1.3,2.3-3,5.8-5,10.3c-0.5,1.1-0.8,1.9-1,2.5c-0.2,0.6-0.3,1.1-0.3,1.5
									c0,0.4,0.1,0.8,0.2,1.3c-0.4,0.2-0.9,0.3-1.3,0.4c-0.4,0-0.8,0-1-0.1C1692.9,374.7,1692.6,374.3,1692.5,373.9z"
						/>
						<path
							d="M1698.9,376.8c-0.1-0.4,0.1-1.1,0.5-2c0.3-0.6,0.7-1.6,1.4-2.9c0.7-1.5,1.3-2.7,1.7-3.6c1.5-3.1,2.3-5.5,2.6-7
									c0.6,0,1.1,0.1,1.6,0.2s1,0.3,1.6,0.6c-1.3,2.3-3,5.7-5.1,10.2c-0.5,1.1-0.8,1.9-1,2.5c-0.2,0.6-0.3,1.1-0.3,1.5
									c0,0.4,0.1,0.8,0.2,1.3c-0.4,0.2-0.9,0.3-1.3,0.4c-0.4,0-0.8,0-1-0.2C1699.3,377.6,1699,377.2,1698.9,376.8z"
						/>
					</g>
					<g className="level_two fade location " id="greylin">
						<rect x="1245.999" y="1251.789" width="80.907" height="27.538" transform="matrix(0.563882, -0.825855, 0.819063, 0.57383, -19.396156, 688.870178)"></rect>
						<path
							d="M1726.6,383.1c-1.5,0.1-3.1-0.5-4.9-1.8c-1.2-0.9-2.1-1.9-2.7-3.1c-0.6-1.1-0.9-2.3-0.8-3.5c0.1-1.2,0.5-2.3,1.2-3.3
									c0.9-1.2,1.8-2.1,2.8-2.7c0.3,0.2,0.6,0.5,0.9,0.9c0.3,0.4,0.5,0.8,0.7,1.1c-0.9,0.3-1.7,0.6-2.4,1.1c-0.8,0.4-1.3,0.9-1.6,1.3
									c-0.2,0.5-0.2,1.1,0,1.9s0.5,1.4,1,2.2c0.5,0.7,1.1,1.3,1.8,1.9c1.6,1.2,3,1.8,4.1,1.8c1.1,0,2.1-0.6,3-1.8
									c0.4-0.5,0.7-1.1,0.9-1.6c-2.3-1.7-3.8-2.7-4.5-2.9c0.2-0.4,0.4-0.8,0.6-1.1c0.2-0.3,0.6-0.7,1-1c1.3,1.2,2.8,2.4,4.3,3.5
									c0.1,0.8,0,1.6-0.2,2.5c-0.3,0.9-0.7,1.7-1.2,2.5C1729.4,382.3,1728.1,383.1,1726.6,383.1z"
						/>
						<path
							d="M1735.7,373.1c-1.4-1.1-2.9-2.2-4.6-3.4c-0.8-0.6-1.4-1-1.9-1.2c-0.5-0.2-0.8-0.4-1.2-0.5c-0.3-0.1-0.7-0.1-1.1,0
									c-0.1-0.4-0.1-0.8-0.1-1.1s0.1-0.6,0.3-0.8c0.5-0.8,1.4-0.8,2.5-0.1c-0.2-0.7-0.3-1.3-0.4-1.9c0-0.6,0.1-1.2,0.3-1.8
									c0.3,0.1,0.7,0.2,1.2,0.4c0.5,0.2,0.9,0.5,1.3,0.7c-0.1,0.1-0.3,0.4-0.5,0.7c-0.3,0.4-0.5,0.9-0.7,1.3c-0.2,0.5-0.2,0.9-0.3,1.2
									l0.7,0.5c0.9,0.7,1.8,1.3,2.5,1.8c1.5,1.1,2.7,1.7,3.6,1.9c-0.2,0.5-0.4,0.9-0.6,1.2C1736.5,372.4,1736.1,372.7,1735.7,373.1z"
						/>
						<path
							d="M1739.7,365.4c-1.2,0.1-2.5-0.4-3.9-1.3c-1-0.6-1.7-1.4-2.3-2.2c-0.5-0.9-0.8-1.7-0.8-2.7c0-0.9,0.2-1.8,0.8-2.6
									c0.7-1.1,1.6-1.7,2.8-1.7c1.2-0.1,2.5,0.4,4,1.4c-0.3,0.9-0.7,1.8-1.2,3c-0.5,1.1-1.1,2.2-1.7,3.2c1,0.5,1.8,0.7,2.4,0.6
									c0.7-0.1,1.2-0.5,1.7-1.3c0.4-0.6,0.6-1.1,0.8-1.7c0.2-0.6,0.3-1.1,0.2-1.7l0.5,0.1c0.3,0.8,0.4,1.7,0.3,2.6
									c-0.1,0.9-0.4,1.8-0.9,2.5C1741.8,364.8,1740.9,365.3,1739.7,365.4z M1737.5,359.8c0.3-0.8,0.4-1.4,0.4-1.9
									c-0.8-0.5-1.6-0.7-2.4-0.6c-0.8,0.1-1.4,0.4-1.8,1.1c0,0.6,0.2,1.2,0.7,1.9c0.5,0.7,1.2,1.3,2,1.8
									C1736.9,361.3,1737.3,360.5,1737.5,359.8z"
						/>
						<path
							d="M1749.6,358.8c-1.3-0.8-2.2-2.1-2.7-4c-0.9,0.5-2.1,0.3-3.5-0.6l-1.1-0.7l-1.3-0.9c-1.5-0.9-2.7-1.5-3.5-1.6
									c0.1-0.5,0.2-0.9,0.3-1.3c0.1-0.4,0.3-0.8,0.6-1.3c0.8,0.6,2.8,1.9,5.9,3.8c0.6,0.4,1.1,0.5,1.5,0.5c0.4-0.1,0.8-0.4,1.1-0.9
									c0.5-0.8,0.7-1.6,0.7-2.4l-2.7-1.6c-0.3-0.1-0.5-0.3-0.8-0.5c-0.7-0.4-1.4-0.8-2-1.1c-0.6-0.3-1.1-0.4-1.5-0.5
									c0.1-0.9,0.4-1.7,1-2.6c1.2,0.8,3,2,5.6,3.6c1,0.6,1.9,1.1,2.6,1.5s1.1,0.6,1.2,0.7c1.4,0.8,2.1,1.8,2.3,3.1s-0.1,2.5-0.9,3.8
									C1751.7,357.4,1750.7,358.3,1749.6,358.8z M1751.8,352.8c-0.3-0.7-1-1.5-2.3-2.2l-1.1-0.7c0.2,0.7,0.3,1.3,0.2,2
									c-0.1,0.6-0.3,1.2-0.6,1.8c-0.1,0.1-0.2,0.3-0.4,0.6c0.4,1,0.9,1.8,1.5,2.3c0.4-0.1,0.9-0.3,1.3-0.5c0.4-0.3,0.7-0.6,1-1
									C1751.9,354.3,1752,353.5,1751.8,352.8z"
						/>
						<path
							d="M1753.1,345.5c-0.4,0-0.9-0.2-1.6-0.7c-0.5-0.3-1.2-0.8-2.2-1.4c-1.1-0.7-2.1-1.3-2.8-1.8c-2.4-1.5-4.3-2.4-5.5-2.8
									c0.1-0.5,0.2-0.9,0.3-1.3c0.2-0.4,0.4-0.8,0.6-1.2c1.8,1.3,4.4,3.1,7.9,5.2c0.8,0.5,1.5,0.9,1.9,1.1c0.5,0.2,0.9,0.4,1.2,0.4
									c0.3,0,0.7,0,1.1,0c0.1,0.4,0.2,0.8,0.2,1.1s-0.1,0.6-0.2,0.8C1753.7,345.3,1753.4,345.5,1753.1,345.5z"
						/>
						<path
							d="M1743.9,333.3c-0.3-0.8-0.5-1.5-0.6-2.1c0.8-0.1,1.5-0.2,2.2-0.4c0.3,0.7,0.5,1.4,0.6,2.2
									C1745.4,333,1744.6,333.1,1743.9,333.3z M1756.3,340.2c-0.4,0-0.9-0.2-1.6-0.7c-0.6-0.4-1.3-0.9-2.3-1.5c-0.9-0.6-1.6-1.1-2.1-1.4
									c-0.6-0.4-1.2-0.7-1.8-1c-0.6-0.3-1-0.4-1.3-0.4c0.1-0.5,0.2-0.9,0.4-1.3c0.2-0.4,0.4-0.8,0.7-1.3c0.4,0.3,1,0.8,1.9,1.4
									c0.9,0.6,1.9,1.3,3,2c0.8,0.5,1.5,0.9,1.9,1.1c0.5,0.2,0.9,0.4,1.2,0.4c0.3,0.1,0.7,0.1,1.1,0c0.1,0.4,0.2,0.8,0.1,1.1
									c0,0.3-0.1,0.6-0.2,0.8C1757,340,1756.7,340.2,1756.3,340.2z"
						/>
						<path
							d="M1759.9,335c-1.4-1.1-3-2.2-4.6-3.3c-0.8-0.5-1.5-0.9-1.9-1.2c-0.5-0.2-0.9-0.4-1.2-0.4c-0.3-0.1-0.7-0.1-1.1,0
									c-0.1-0.4-0.2-0.8-0.2-1.1c0-0.3,0.1-0.6,0.3-0.8c0.5-0.7,1.2-0.8,2.2-0.4c-0.2-0.6-0.3-1.3-0.2-1.9s0.3-1.2,0.6-1.7
									c1-1.6,2.6-1.7,4.6-0.4l1.4,1c0.5,0.4,1,0.8,1.5,1c1.5,1,2.7,1.6,3.6,1.8c-0.2,0.4-0.4,0.8-0.6,1.2c-0.2,0.4-0.5,0.7-0.9,1.1
									c-1.7-1.3-3.6-2.6-5.7-4c-0.6-0.4-1.1-0.6-1.5-0.5c-0.4,0-0.8,0.3-1.1,0.8c-0.5,0.8-0.7,1.5-0.7,2.2l0.9,0.7
									c0.7,0.5,1.6,1.2,2.7,1.8c1.6,1,2.8,1.6,3.6,1.8c-0.2,0.5-0.4,0.9-0.6,1.3C1760.6,334.2,1760.3,334.6,1759.9,335z"
						/>
					</g>
					<g className="level_two fade location " id="brandywine_bridge">
						<rect x="1009.995" y="1222.089" width="65.58" height="26.886" transform="matrix(0.742864, -0.669442, 0.660496, 0.750926, -659.04248, 371.149139)"></rect>
						<rect x="1009.995" y="1222.089" width="65.58" height="26.886" transform="matrix(0.893037, -0.449984, 0.439229, 0.898456, -490.177917, -75.65448)"></rect>
						<rect x="1153.089" y="1222.089" width="74.871" height="26.886" transform="matrix(0.799611, -0.600519, 0.590889, 0.806843, -715.832886, 322.998962)"></rect>
						<path
							d="M920.1,625.6c-0.9,0.6-1.6,1.1-2.3,1.4c-0.6-0.7-1.6-1.8-2.9-3.2c-1.3-1.4-2.6-2.7-3.9-4c-2.5-2.5-4.1-3.9-4.9-4.2
									c0.3-0.7,0.8-1.5,1.5-2.4c0.6-0.9,1.3-1.7,2.1-2.5c1.4-1.4,2.7-2.2,4-2.3c1.3-0.2,2.5,0.3,3.6,1.3c0.6,0.6,1,1.3,1.1,2
									c0.2,0.8,0.1,1.6-0.2,2.5c1-0.5,2-0.7,2.9-0.5c1,0.1,1.8,0.5,2.5,1.2c1.2,1.2,1.7,2.5,1.5,3.9c-0.2,1.5-1,2.9-2.5,4.5
									C921.8,624.3,921,625,920.1,625.6z M915.2,616.5c0.4-0.4,0.7-0.7,1-0.9c0.2-1.5-0.1-2.6-0.8-3.4c-1.4-1.4-2.9-1.2-4.5,0.4
									c-0.6,0.6-1,1.2-1.4,1.9c1.1,1.2,2.4,2.6,4,4.2C913.9,618,914.5,617.3,915.2,616.5z M921.5,617.2c-0.6-0.6-1.2-0.9-2-1.1
									c-0.7-0.1-1.5,0-2.3,0.3c-0.7,0.7-1.4,1.9-2.3,3.7c1.9,1.9,3.3,3.2,4.3,4c0.7-0.3,1.4-0.8,2-1.4
									C923.1,620.8,923.2,619,921.5,617.2z"
						/>
						<path
							d="M928.4,616.9c-1.5-1.6-3.1-3.3-5-5.1c-0.9-0.8-1.6-1.5-2.1-1.9c-0.5-0.4-1-0.7-1.3-0.8c-0.4-0.2-0.8-0.2-1.4-0.3
									c-0.1-0.5,0-1,0.1-1.4c0.1-0.4,0.3-0.7,0.5-1c0.8-0.8,1.8-0.7,3,0.4c-0.1-0.9-0.2-1.7-0.1-2.4c0.1-0.7,0.3-1.5,0.7-2.2
									c0.4,0.1,0.8,0.4,1.4,0.8c0.6,0.4,1,0.8,1.4,1.2c-0.2,0.1-0.5,0.4-0.8,0.7c-0.5,0.5-0.8,1-1.1,1.5s-0.5,1-0.6,1.5l0.7,0.7
									c1,1,1.9,2,2.8,2.8c1.7,1.6,3,2.6,4,3c-0.3,0.5-0.7,1-1,1.3S929,616.5,928.4,616.9z"
						/>
						<path
							d="M933.3,611.2c-0.8,0-1.5-0.4-2.1-1c-2-1.8-1.4-4.6,1.7-8.4c-1.1-1-1.9-1.6-2.6-1.7c-0.7-0.1-1.4,0.2-2.1,1
									c-0.4,0.5-0.8,1-1.1,1.6c-0.3,0.6-0.5,1.3-0.6,1.9l-0.6-0.3c-0.3-1.1-0.4-2.2-0.2-3.3c0.2-1.1,0.6-2,1.3-2.7
									c0.9-1,1.9-1.4,2.9-1.4c1,0,2.1,0.5,3.1,1.5c0.6,0.5,1.2,1.2,1.9,2c0.7,0.8,1.3,1.4,1.6,1.7c0.6,0.6,1.1,1,1.6,1.2
									c0.5,0.2,1,0.3,1.8,0.4c0,1-0.3,1.9-0.8,2.7c-0.7,0.1-1.4,0-2.1-0.4c-0.1,0.9-0.3,1.8-0.6,2.6c-0.3,0.8-0.7,1.5-1.1,1.9
									C934.7,611,934,611.2,933.3,611.2z M935.6,607c0.2-0.6,0.3-1.2,0.3-1.8l-0.1-0.1c-0.5-0.5-1-1-1.4-1.5l-1-1
									c-0.9,1.1-1.4,2.1-1.5,3c-0.1,0.9,0.1,1.7,0.8,2.3c0.3,0.3,0.6,0.4,1,0.6c0.4,0.1,0.8,0.1,1.1,0
									C935.2,608.1,935.4,607.6,935.6,607z"
						/>
						<path
							d="M942,602.6c-1.5-1.6-3.2-3.3-5-5c-0.9-0.8-1.6-1.4-2.1-1.8c-0.5-0.4-1-0.7-1.3-0.8c-0.4-0.1-0.8-0.2-1.4-0.3
									c-0.1-0.5-0.1-1,0-1.4c0.1-0.4,0.3-0.7,0.5-0.9c0.7-0.8,1.7-0.8,2.7,0c-0.1-0.8-0.1-1.7,0.1-2.4c0.2-0.8,0.6-1.5,1.1-2
									c1.6-1.7,3.6-1.6,5.8,0.5l1.5,1.5c0.6,0.6,1.1,1.1,1.6,1.6c1.7,1.6,3,2.6,4.1,3c-0.3,0.5-0.6,0.9-1,1.3c-0.4,0.4-0.8,0.8-1.4,1.2
									c-1.9-1.9-3.9-4-6.2-6.1c-0.6-0.6-1.2-0.9-1.7-1c-0.5,0-1,0.2-1.5,0.8c-0.8,0.8-1.2,1.7-1.4,2.6l1,1c0.8,0.8,1.7,1.8,2.9,2.8
									c1.7,1.6,3.1,2.6,4.1,3c-0.3,0.5-0.7,1-1,1.4C943,601.8,942.6,602.1,942,602.6z"
						/>
						<path
							d="M950.3,591.4c-1.4-0.3-2.9-1.3-4.5-3.1c-1.6-1.8-2.5-3.4-2.7-4.9c-0.2-1.5,0.4-2.8,1.6-4c1-0.9,2.2-1.4,3.6-1.5
									c-1.6-1.6-2.8-2.7-3.8-3.3c0.3-0.6,0.6-1.1,0.9-1.5s0.7-0.8,1.2-1.3c1.7,2.2,4.7,5.8,9.2,10.7c0.5,0.6,1,1,1.4,1.2
									c0.4,0.2,1,0.4,1.7,0.5c-0.1,1-0.4,1.8-1,2.5c-0.7,0-1.4-0.2-2.1-0.6c0,0.8-0.2,1.6-0.4,2.3c-0.3,0.7-0.7,1.3-1.2,1.7
									C953,591.3,951.7,591.7,950.3,591.4z M954.5,587.1c0.2-0.5,0.4-1.1,0.5-1.7c-0.5-0.5-1.2-1.4-2.3-2.6c-1.2-1.4-2.1-2.5-2.8-3.2
									c-1,0.1-1.8,0.5-2.6,1.3c-0.8,0.7-1.1,1.6-1,2.6c0.1,1,0.7,2.1,1.8,3.2c1,1.1,2,1.9,3,2.2c1,0.3,1.9,0.2,2.8-0.3
									C954,588.1,954.3,587.7,954.5,587.1z"
						/>
						<path
							d="M962.7,588.3c-1.1-1.6-1.4-3.6-0.9-5.9c-1.3,0-2.5-0.8-3.6-2.5l-0.9-1.4l-1-1.7c-1.3-1.9-2.3-3.1-3.1-3.6
									c0.3-0.5,0.7-1,1-1.3c0.4-0.4,0.8-0.7,1.4-1.1c0.6,1.1,2.2,3.6,4.7,7.4c0.5,0.7,1,1.2,1.5,1.3c0.5,0.1,1,0,1.7-0.4
									c1-0.7,1.7-1.4,2-2.4l-2.2-3.2c-0.2-0.3-0.4-0.6-0.7-1c-0.6-0.9-1.1-1.6-1.7-2.2c-0.5-0.6-1-1.1-1.4-1.3c0.6-0.9,1.4-1.7,2.5-2.4
									c0.9,1.6,2.4,3.9,4.4,7c0.8,1.3,1.6,2.3,2.2,3.1c0.6,0.8,0.9,1.3,1,1.4c1.1,1.6,1.4,3.2,1,4.7c-0.4,1.5-1.4,2.8-3,3.8
									C965.9,587.8,964.2,588.3,962.7,588.3z M968.4,582.6c0.1-1-0.4-2.2-1.4-3.7l-0.9-1.3c-0.1,0.9-0.4,1.7-0.8,2.4
									c-0.4,0.7-0.9,1.3-1.6,1.7c-0.2,0.1-0.4,0.3-0.8,0.4c-0.1,1.3,0.1,2.5,0.5,3.5c0.5,0.1,1.1,0.2,1.7,0.1c0.6-0.1,1.2-0.3,1.7-0.7
									C967.7,584.4,968.3,583.6,968.4,582.6z"
						/>
						<path
							d="M971.6,574.4l-2.7-3.2c-0.6-0.8-1.2-1.5-1.7-2c-0.7-0.7-1.3-1.3-1.9-1.8c-0.6-0.5-1.1-0.8-1.4-1c0.4-0.4,0.8-0.8,1.2-1.2
									c0.4-0.3,1-0.7,1.6-1c0.5,0.8,1.8,2.4,3.9,4.9c2,2.4,3.3,3.8,3.8,4.3c-0.7-5.1-1.2-8.2-1.5-9.4c-0.7-0.6-1.2-1-1.7-1.2
									c0.7-0.9,1.7-1.6,2.9-2.1c0.5,0.8,1.9,2.5,4,5c2.2,2.5,3.4,3.9,3.7,4.2c-0.5-3.7-0.9-6.3-1.1-7.7c-0.3-1.5-0.5-2.5-0.8-3
									c-0.1-0.2-0.2-0.4-0.4-0.6c0.6-0.5,1.4-1.1,2.3-1.5c0.1,1.5,0.3,3.5,0.5,5.9s0.7,5,1.1,7.8l-1,1.6c-0.6,0.3-1.1,0.3-1.7,0
									c-0.6-0.2-1.2-0.7-1.8-1.4c-2-2.3-3.3-3.9-4.1-4.8c0.2,2,0.6,4.7,1.2,8.1l-1,1.6C974,576.3,972.8,575.8,971.6,574.4z"
						/>
						<path
							d="M980.9,553c0.3-1,0.6-1.9,0.9-2.6c0.8,0.5,1.7,0.9,2.5,1.2c-0.2,0.9-0.6,1.8-1,2.6C982.7,553.7,981.8,553.3,980.9,553z
									M988.6,569c-0.4-0.3-0.8-0.9-1.2-1.8c-0.3-0.8-0.7-1.8-1.3-3.3c-0.5-1.3-0.9-2.3-1.1-2.9c-0.3-0.8-0.7-1.6-1.1-2.3
									s-0.7-1.1-1.1-1.3c0.4-0.4,0.9-0.8,1.3-1.1c0.4-0.3,1-0.6,1.6-0.8c0.2,0.6,0.4,1.6,0.9,2.9c0.4,1.3,1,2.7,1.6,4.2
									c0.5,1.1,0.9,2,1.2,2.5c0.3,0.6,0.6,1,0.9,1.3c0.3,0.3,0.7,0.6,1.1,0.8c-0.1,0.5-0.4,0.9-0.6,1.2c-0.3,0.3-0.6,0.6-0.8,0.7
									C989.4,569.3,989,569.3,988.6,569z"
						/>
						<path
							d="M993.9,567.1c-0.6-2.1-1.4-4.4-2.2-6.7c-0.4-1.1-0.8-2-1.1-2.6c-0.3-0.6-0.6-1-0.8-1.3c-0.3-0.3-0.6-0.6-1.1-0.8
									c0.2-0.5,0.4-0.9,0.7-1.2c0.3-0.3,0.6-0.5,0.9-0.6c1-0.4,1.8,0,2.4,1.2c0.3-0.8,0.7-1.5,1.2-2.1c0.5-0.6,1.2-1,1.9-1.3
									c2.2-0.8,3.9,0.2,4.9,3.1l0.7,2c0.2,0.8,0.5,1.5,0.7,2.1c0.8,2.1,1.6,3.6,2.3,4.5c-0.5,0.3-1,0.6-1.5,0.7
									c-0.5,0.2-1.1,0.3-1.8,0.4c-0.8-2.6-1.7-5.3-2.8-8.3c-0.3-0.8-0.7-1.4-1.1-1.6c-0.4-0.3-1-0.2-1.7,0c-1.1,0.4-1.8,0.9-2.3,1.7
									l0.5,1.4c0.3,1.1,0.8,2.4,1.3,3.8c0.8,2.2,1.6,3.7,2.3,4.5c-0.5,0.3-1.1,0.6-1.6,0.8C995.2,566.9,994.6,567,993.9,567.1z"
						/>
						<path
							d="M1006.3,561.2c-1.2-0.9-2.1-2.3-2.7-4.4c-0.4-1.4-0.6-2.7-0.4-4c0.2-1.2,0.6-2.3,1.3-3.2c0.7-0.9,1.7-1.5,2.8-1.9
									c1.6-0.5,2.9-0.3,4.1,0.6c1.1,0.9,2.1,2.4,2.7,4.6c-1,0.6-2.1,1.2-3.5,1.9s-2.8,1.2-4.2,1.7c0.5,1.3,1.1,2.1,1.8,2.5
									c0.7,0.4,1.6,0.5,2.6,0.2c0.8-0.2,1.5-0.6,2.2-1c0.7-0.4,1.2-0.9,1.6-1.4l0.4,0.5c-0.3,1-0.9,2-1.8,2.8c-0.8,0.8-1.8,1.4-2.8,1.7
									C1008.9,562.3,1007.5,562.1,1006.3,561.2z M1008.8,554.1c0.9-0.5,1.5-1,2-1.5c-0.4-1.1-0.9-2-1.7-2.5c-0.8-0.5-1.6-0.7-2.5-0.4
									c-0.5,0.6-0.8,1.4-0.8,2.4c-0.1,1,0.1,2.2,0.4,3.4C1007,555.1,1008,554.6,1008.8,554.1z"
						/>
						<path
							d="M946.8,631.9c-0.9,0.6-1.7,1-2.4,1.3c-0.6-0.8-1.5-1.9-2.8-3.3c-1.2-1.4-2.5-2.8-3.7-4.2c-2.4-2.6-3.9-4.1-4.7-4.4
									c0.4-0.7,0.9-1.5,1.6-2.3s1.4-1.6,2.2-2.4c1.4-1.3,2.8-2,4.1-2.1c1.3-0.1,2.5,0.4,3.5,1.5c0.6,0.6,0.9,1.3,1,2.1s0,1.6-0.3,2.5
									c1-0.4,2-0.5,3-0.4c1,0.2,1.8,0.6,2.4,1.4c1.1,1.2,1.6,2.6,1.3,4s-1.2,2.9-2.8,4.3C948.6,630.7,947.7,631.3,946.8,631.9z
									M942.5,622.5c0.4-0.4,0.8-0.7,1-0.8c0.3-1.5,0.1-2.6-0.7-3.5c-1.3-1.4-2.8-1.4-4.5,0.2c-0.6,0.6-1.1,1.2-1.5,1.8
									c1,1.3,2.3,2.8,3.8,4.4C941,624,941.6,623.3,942.5,622.5z M948.6,623.6c-0.6-0.6-1.2-1-1.9-1.2c-0.7-0.2-1.5-0.1-2.3,0.2
									c-0.7,0.6-1.5,1.8-2.5,3.5c1.8,2,3.1,3.4,4.1,4.3c0.7-0.3,1.4-0.7,2.1-1.3C950.1,627.3,950.3,625.4,948.6,623.6z"
						/>
						<path
							d="M956.5,623.1c-1.3-1.8-2.8-3.6-4.4-5.6c-0.8-0.9-1.4-1.6-1.9-2.1c-0.5-0.4-0.9-0.8-1.2-1c-0.4-0.2-0.8-0.3-1.3-0.4
									c0-0.5,0.1-1,0.2-1.4c0.2-0.4,0.4-0.7,0.6-0.9c0.9-0.8,1.9-0.5,3,0.7c-0.1-0.9,0-1.7,0.2-2.4c0.2-0.7,0.5-1.4,0.9-2.1
									c0.3,0.2,0.8,0.5,1.3,0.9c0.5,0.4,1,0.9,1.3,1.3c-0.2,0.1-0.5,0.3-0.8,0.6c-0.5,0.4-0.9,0.9-1.3,1.4c-0.3,0.5-0.6,1-0.7,1.4
									l0.6,0.8c0.9,1.1,1.7,2.2,2.5,3c1.5,1.8,2.7,2.9,3.7,3.4c-0.4,0.5-0.8,0.9-1.2,1.2S957.1,622.8,956.5,623.1z"
						/>
						<path
							d="M951.6,603.8c0-1,0-1.9,0.2-2.8c0.9,0.2,1.8,0.4,2.8,0.4c0,0.9,0,1.9-0.2,2.8C953.4,604,952.5,603.8,951.6,603.8z
									M963.5,617c-0.4-0.2-1-0.6-1.6-1.4c-0.5-0.6-1.2-1.6-2.1-2.8c-0.9-1.1-1.5-2-1.9-2.5c-0.6-0.7-1.1-1.3-1.7-1.9
									c-0.6-0.5-1-0.9-1.4-1c0.3-0.5,0.6-1,1-1.4s0.8-0.8,1.3-1.2c0.3,0.6,0.9,1.4,1.7,2.5c0.8,1.1,1.7,2.3,2.7,3.6
									c0.8,1,1.4,1.7,1.8,2.1c0.5,0.5,0.9,0.8,1.2,1c0.4,0.2,0.8,0.3,1.3,0.4c0,0.5-0.1,1-0.3,1.4c-0.2,0.4-0.4,0.7-0.6,0.9
									C964.4,617,963.9,617.1,963.5,617z"
						/>
						<path
							d="M967.9,612c-1.4-0.4-2.8-1.6-4.2-3.4c-1.4-1.9-2.2-3.6-2.2-5.1c0-1.5,0.6-2.8,2-3.8c1.1-0.8,2.3-1.2,3.8-1.2
									c-1.4-1.7-2.6-2.9-3.5-3.6c0.3-0.6,0.7-1,1-1.4s0.8-0.8,1.3-1.2c1.5,2.4,4.2,6.2,8.2,11.4c0.5,0.6,0.9,1.1,1.3,1.3
									c0.4,0.3,1,0.5,1.7,0.6c-0.1,1-0.6,1.8-1.2,2.4c-0.7,0-1.4-0.3-2.1-0.8c-0.1,0.8-0.3,1.6-0.6,2.2s-0.8,1.2-1.3,1.6
									C970.6,612.2,969.3,612.5,967.9,612z M972.5,608.1c0.3-0.5,0.5-1.1,0.6-1.7c-0.4-0.5-1.1-1.5-2.1-2.8c-1.1-1.5-1.9-2.6-2.5-3.5
									c-1,0-1.9,0.4-2.7,1c-0.8,0.6-1.2,1.5-1.2,2.5c0,1,0.5,2.1,1.5,3.4c0.9,1.2,1.9,2,2.8,2.4c1,0.4,1.9,0.4,2.8,0
									C971.9,609.1,972.2,608.6,972.5,608.1z"
						/>
						<path
							d="M981.5,609.2c-0.5-0.7-0.8-1.4-1-2.2c-0.2-0.8-0.3-1.6-0.2-2.4c-0.8,0.2-1.5-0.1-2-0.8c-0.4-0.5-0.5-1.1-0.5-1.7
									c0.1-0.6,0.3-1.3,0.7-2c-0.8,0.1-1.6,0-2.3-0.4c-0.7-0.4-1.4-0.9-1.9-1.7c-0.9-1.3-1.2-2.6-0.9-4c0.3-1.4,1-2.5,2.3-3.4
									c0.9-0.6,1.7-0.9,2.7-1c0.3-0.3,0.5-0.8,0.8-1.4c0.2-0.6,0.4-1.2,0.6-1.9c0.4,0.2,0.8,0.5,1.3,0.8c0.4,0.3,0.7,0.6,0.9,0.9
									c-0.3,0.7-0.9,1.3-1.6,1.9c0.8,0.4,1.5,1,2,1.7c0.6,0.8,0.9,1.7,1,2.6c0.1,0.9-0.1,1.8-0.5,2.7c-0.4,0.8-1.1,1.6-2,2.2
									c-0.7,0.5-1.2,1-1.4,1.6c-0.2,0.5-0.2,1,0.1,1.4l4.9-3c1-0.5,1.8-0.8,2.6-0.6c0.8,0.1,1.5,0.6,2,1.3c0.5,0.7,0.7,1.5,0.7,2.5
									c0,0.9-0.3,1.8-0.9,2.7c-0.6,0.9-1.3,1.7-2.3,2.4C984.8,608.7,983.1,609.2,981.5,609.2z M981.6,596.1c0.1-0.7-0.1-1.4-0.6-2.1
									c-0.5-0.7-1-1.1-1.7-1.3c-0.7-0.2-1.4-0.2-2.2,0.1c-0.7,0.5-1.1,1.1-1.2,1.9c-0.1,0.8,0.1,1.6,0.6,2.3c0.5,0.7,1.1,1.1,1.8,1.3
									c0.7,0.2,1.4,0,2-0.4C981.1,597.4,981.5,596.8,981.6,596.1z M987.7,603.3c0.3-0.7,0.3-1.2,0-1.7c-0.3-0.4-0.6-0.5-0.9-0.6
									c-0.4,0-0.8,0.2-1.4,0.6l-3.9,2.4c0.1,0.7,0.3,1.3,0.5,1.8c1.3,0.5,2.6,0.3,3.9-0.6C986.7,604.6,987.3,604,987.7,603.3z"
						/>
						<path
							d="M989.7,596.3c-1.4-0.5-2.6-1.7-3.8-3.5c-0.8-1.2-1.3-2.5-1.5-3.7c-0.2-1.2,0-2.4,0.4-3.5c0.4-1.1,1.2-1.9,2.2-2.6
									c1.4-0.9,2.7-1.1,4.1-0.6c1.3,0.5,2.6,1.7,3.9,3.6c-0.8,0.8-1.7,1.7-2.9,2.8c-1.2,1-2.4,1.9-3.6,2.8c0.8,1.1,1.7,1.7,2.5,1.9
									c0.8,0.2,1.7,0,2.6-0.6c0.7-0.5,1.3-1,1.8-1.6c0.5-0.6,0.9-1.2,1.1-1.8l0.5,0.4c0,1.1-0.4,2.1-1,3.2s-1.3,1.8-2.3,2.4
									C992.4,596.6,991.1,596.8,989.7,596.3z M990.1,588.7c0.7-0.7,1.2-1.4,1.5-2c-0.7-1-1.4-1.6-2.4-1.9c-0.9-0.3-1.8-0.2-2.6,0.3
									c-0.3,0.7-0.4,1.5-0.1,2.6c0.2,1,0.7,2.1,1.4,3.1C988.7,590.2,989.4,589.5,990.1,588.7z"
						/>
					</g>
					<g className="level_two fade location " id="old_forest">
						<rect x="671.439" y="1222.088" width="43.597" height="26.886" transform="matrix(0.997299, -0.073454, 0.061443, 0.998183, 214.1138, -530.813843)"></rect>
						<rect x="1007.118" y="1222.088" width="65.393" height="26.886" transform="matrix(0.997299, -0.073454, 0.061443, 0.998183, -126.34539, -486.924194)"></rect>
						<path
							d="M967.3,660.3c-1.1-0.6-1.9-1.5-2.6-2.7c-0.6-1.2-1-2.5-1.1-4.1c-0.1-1.8,0.1-3.3,0.7-4.7c0.6-1.4,1.5-2.5,2.7-3.4
									c1.2-0.8,2.5-1.3,4.1-1.4c1.4-0.1,2.6,0.2,3.7,0.8c1.1,0.6,2,1.5,2.6,2.7c0.7,1.2,1,2.6,1.1,4.2c0.1,1.7-0.2,3.3-0.8,4.6
									s-1.5,2.5-2.7,3.3c-1.2,0.8-2.6,1.3-4.2,1.4C969.6,661.2,968.4,660.9,967.3,660.3z M975,657.5c0.8-1.2,1.2-2.7,1-4.7
									c-0.1-1.8-0.6-3.2-1.4-4.3s-2-1.7-3.4-1.9c-1,0.1-1.9,0.4-2.6,1c-0.8,0.6-1.3,1.4-1.7,2.4s-0.5,2.1-0.5,3.3
									c0.1,1.9,0.6,3.4,1.6,4.6s2.1,1.6,3.5,1.6C973,659.3,974.1,658.7,975,657.5z"
						/>
						<path
							d="M982.5,659.6c-0.3-0.3-0.4-1-0.5-2c-0.1-0.6-0.1-1.7-0.2-3.1c-0.1-1.6-0.2-2.9-0.3-3.9c-0.3-3.3-0.7-5.7-1.2-7.1
									c0.5-0.3,1-0.5,1.5-0.6c0.5-0.1,1-0.2,1.6-0.3c0,2.6,0.2,6.3,0.6,11c0.1,1.1,0.2,2,0.3,2.6c0.1,0.6,0.3,1.1,0.5,1.4
									c0.2,0.3,0.5,0.7,0.8,1c-0.3,0.4-0.6,0.7-0.9,0.9c-0.3,0.2-0.7,0.3-0.9,0.4C983.2,660,982.8,659.9,982.5,659.6z"
						/>
						<path
							d="M988.9,658c-0.9-1-1.5-2.6-1.7-4.8c-0.2-2.2,0-3.9,0.6-5.2c0.7-1.2,1.8-1.9,3.4-2.1c1.2-0.1,2.4,0.1,3.6,0.9
									c-0.3-2-0.7-3.6-1.1-4.5c0.5-0.3,1-0.5,1.5-0.7c0.5-0.1,1-0.3,1.6-0.3c0.1,2.6,0.4,7,1.1,13.1c0.1,0.7,0.2,1.3,0.4,1.7
									c0.2,0.4,0.5,0.8,1,1.3c-0.6,0.7-1.3,1.1-2.1,1.4c-0.6-0.4-1-0.9-1.3-1.6c-0.4,0.6-1,1.1-1.6,1.5c-0.6,0.4-1.2,0.6-1.8,0.7
									C991,659.4,989.8,659,988.9,658z M994.4,657c0.5-0.3,0.9-0.7,1.3-1c-0.1-0.6-0.2-1.7-0.3-3.2c-0.2-1.7-0.3-3-0.4-4
									c-0.8-0.4-1.7-0.6-2.7-0.5c-1,0.1-1.7,0.6-2.1,1.4c-0.4,0.8-0.6,2-0.4,3.4c0.2,1.4,0.5,2.5,1.1,3.3c0.6,0.8,1.3,1.2,2.2,1.3
									C993.5,657.5,993.9,657.3,994.4,657z"
						/>
						<path
							d="M957,680.3c0-0.9-0.1-2.2-0.2-3.8c-0.1-1.6-0.3-3.1-0.4-4.5c-0.4-3.9-0.9-6.6-1.4-7.9c1,0,2.5-0.1,4.6-0.3
									c2.2-0.2,4-0.5,5.3-0.8c0.1,0.6,0.1,1.2,0,1.8c0,0.6-0.1,1.1-0.3,1.4c-0.4-0.2-1-0.4-1.6-0.4c-0.6-0.1-1.3-0.1-1.9,0
									c-1,0.1-1.9,0.3-2.7,0.6c0.1,1.9,0.3,3.5,0.4,4.6l0.7,0c0.6,0,1.1-0.1,1.4-0.1c0.8-0.1,1.4-0.2,1.9-0.3c0.4-0.1,0.9-0.2,1.5-0.4
									c0.1,0.4,0.2,0.8,0.2,1.1c0,0.3,0,0.7,0,1.2l-1.3,0.1c-1.2,0-2.3,0.1-3.3,0.2c-0.5,0.1-0.8,0.1-1,0.1c0.4,4,0.9,6.3,1.3,7.2
									c-0.5,0.2-1,0.3-1.6,0.4C958.1,680.4,957.6,680.4,957,680.3z"
						/>
						<path
							d="M968.7,677.6c-1.1-1-1.7-2.5-1.9-4.4c-0.2-1.4,0-2.6,0.4-3.7c0.4-1.1,1-2,1.9-2.7s1.9-1.1,3.1-1.2c1.1-0.1,2,0,2.9,0.5
									c0.9,0.4,1.6,1.1,2.1,2c0.5,0.9,0.9,2,1,3.2c0.2,1.4,0,2.6-0.4,3.7c-0.4,1.1-1.1,2-2,2.7c-0.9,0.7-2,1.1-3.2,1.2
									C971.1,679.1,969.8,678.7,968.7,677.6z M975.4,676.1c0.5-0.9,0.7-2,0.5-3.5c-0.1-1.3-0.5-2.3-1.2-3.2s-1.5-1.4-2.5-1.6
									c-1.1,0.1-1.9,0.6-2.5,1.5c-0.6,0.9-0.8,2-0.6,3.4c0.2,1.6,0.6,2.8,1.3,3.7c0.7,0.9,1.6,1.3,2.5,1.1
									C974.1,677.4,974.9,677,975.4,676.1z"
						/>
						<path
							d="M981.9,677.5c-0.1-2.1-0.3-4.3-0.5-6.6c-0.1-1.1-0.3-2-0.4-2.6c-0.1-0.6-0.3-1.1-0.5-1.4c-0.2-0.3-0.5-0.7-0.8-1
									c0.3-0.4,0.6-0.7,0.9-0.9c0.3-0.2,0.7-0.4,0.9-0.4c1.1-0.1,1.7,0.6,2,2.1c0.4-0.7,0.9-1.3,1.4-1.8c0.5-0.5,1.1-0.9,1.8-1.2
									c0.2,0.3,0.3,0.8,0.5,1.4s0.3,1.2,0.3,1.7c-0.2,0-0.6,0-1,0c-0.6,0.1-1.2,0.2-1.7,0.4s-1,0.5-1.3,0.7l0.1,1
									c0.1,1.4,0.2,2.6,0.3,3.6c0.2,2.2,0.6,3.7,1.1,4.6c-0.6,0.2-1.1,0.3-1.5,0.4C983.1,677.6,982.5,677.6,981.9,677.5z"
						/>
						<path
							d="M990,675.4c-0.9-1-1.5-2.5-1.7-4.5c-0.2-1.4,0-2.6,0.3-3.7c0.4-1.1,1-2,1.8-2.7s1.8-1.1,2.9-1.2c1.5-0.2,2.7,0.2,3.6,1.2
									c0.9,1,1.5,2.5,1.7,4.6c-1,0.4-2.2,0.7-3.6,1.1c-1.4,0.4-2.8,0.6-4.2,0.8c0.3,1.2,0.7,2.1,1.3,2.6c0.6,0.5,1.4,0.7,2.4,0.6
									c0.8-0.1,1.5-0.3,2.2-0.5c0.7-0.3,1.2-0.6,1.7-1l0.3,0.6c-0.5,0.9-1.2,1.6-2.1,2.3s-1.9,1-2.9,1.1
									C992.1,676.8,990.9,676.3,990,675.4z M993.5,669.2c0.9-0.3,1.6-0.7,2.1-1c-0.1-1.1-0.5-2-1.2-2.6c-0.6-0.6-1.4-0.9-2.3-0.8
									c-0.5,0.4-0.9,1.1-1.2,2.1c-0.2,0.9-0.3,2-0.2,3.2C991.7,669.8,992.6,669.5,993.5,669.2z"
						/>
						<path
							d="M1000.6,674.7c0-0.4,0-0.8,0-1.1c0-0.4,0.1-0.8,0.3-1.2c0.4,0.5,0.9,0.8,1.6,1c0.6,0.2,1.3,0.3,2,0.2
									c1.8-0.2,2.6-0.8,2.5-1.9c0-0.5-0.3-0.8-0.7-1.1c-0.4-0.3-1.1-0.5-2-0.8c-0.9-0.3-1.6-0.6-2.1-0.9c-0.5-0.3-1-0.7-1.5-1.2
									c-0.4-0.5-0.7-1.2-0.8-2c-0.1-1,0.2-1.8,0.9-2.5c0.7-0.7,1.7-1.1,3-1.2c1.7-0.2,3.1-0.1,4.4,0.3c0,0.3,0,0.8-0.1,1.3
									c-0.1,0.6-0.3,1.1-0.5,1.5c-0.8-0.6-1.5-1.1-2.2-1.4c-0.7-0.3-1.2-0.5-1.7-0.4c-0.4,0.2-0.8,0.4-1,0.7c-0.3,0.3-0.4,0.6-0.3,1
									c0.1,0.6,0.3,1,0.8,1.3c0.5,0.3,1.2,0.6,2.2,1c0.8,0.3,1.5,0.6,2,0.8c0.5,0.3,1,0.6,1.4,1.1c0.4,0.5,0.7,1.1,0.7,1.8
									c0.1,1.2-0.2,2.1-1,2.8s-1.9,1.2-3.5,1.4C1003.3,675.5,1001.8,675.3,1000.6,674.7z"
						/>
						<path
							d="M1012.9,673.9c-0.3-0.3-0.5-0.9-0.6-1.9c-0.1-0.6-0.2-1.7-0.3-3.1c-0.1-1.6-0.3-2.9-0.4-3.9c-0.1-0.5-0.1-1-0.2-1.4
									c-0.8,0.1-1.3,0.1-1.5,0s-0.4-0.3-0.4-0.6c1.1-0.9,2.2-2.1,3.1-3.5l0.7,0c0.2,0.7,0.3,1.4,0.4,2.1c1.3-0.2,2.2-0.3,2.8-0.6
									c0,0.4,0.1,0.8,0,1.2c0,0.3-0.1,0.7-0.3,1.1c-0.6-0.2-1.4-0.2-2.4-0.1c0,0.9,0.1,1.9,0.2,2.9c0.3,2.5,0.6,4.4,1.1,5.7
									c0.9-0.2,1.6-0.3,2.1-0.6l0.2,0.7c-0.3,0.6-0.7,1.1-1.3,1.5c-0.6,0.4-1.2,0.7-1.8,0.7C1013.7,674.3,1013.2,674.2,1012.9,673.9z"
						/>
					</g>
					<g className="level_one location " id="barrow_downs">
						<rect x="1168.264" y="1011.133" width="75.857" height="22.245" transform="matrix(0.568984, 0.822349, -0.829202, 0.559079, 1155.782593, -814.93457)"></rect>
						<rect x="1016.239" y="1011.133" width="65.986" height="22.245" transform="matrix(0.568984, 0.822349, -0.829202, 0.559079, 1253.234985, -647.439209)"></rect>
						<path
							d="M971,730.7c-0.6-0.7-1.1-1.3-1.5-1.8c0.6-0.6,1.4-1.6,2.4-2.9c1-1.3,2-2.5,3-3.8c1.8-2.4,2.9-4,3-4.7
									c0.6,0.2,1.4,0.5,2.2,1s1.6,1,2.4,1.5c1.3,1,2.1,2.1,2.4,3.2c0.3,1.1,0,2.2-0.8,3.2c-0.4,0.6-1,1-1.6,1.2
									c-0.7,0.2-1.4,0.3-2.2,0.1c0.5,0.8,0.8,1.6,0.8,2.5c0,0.8-0.3,1.6-0.8,2.3c-0.9,1.1-2,1.7-3.2,1.7c-1.3,0-2.6-0.5-4.1-1.7
									C972.4,732,971.7,731.4,971,730.7z M978.4,730.9c0.4-0.6,0.7-1.2,0.7-1.8c0-0.6-0.1-1.3-0.5-1.9c-0.6-0.5-1.8-1-3.4-1.6
									c-1.4,1.8-2.4,3.2-3,4.2c0.4,0.6,0.8,1.1,1.5,1.6C975.5,732.8,977.1,732.6,978.4,730.9z M978.3,725.5c0.4,0.3,0.7,0.6,0.8,0.7
									c1.3,0,2.3-0.3,2.8-1.1c1-1.3,0.7-2.6-0.9-3.8c-0.6-0.4-1.2-0.8-1.8-1c-0.9,1.1-2,2.4-3.2,3.9
									C976.9,724.5,977.6,724.9,978.3,725.5z"
						/>
						<path
							d="M979.2,736.7c0.1-0.7,0.4-1.3,1-1.8c1.7-1.6,4.1-1,7.2,1.8c0.9-0.9,1.4-1.6,1.6-2.2s-0.1-1.2-0.7-1.9
									c-0.4-0.4-0.8-0.7-1.4-1c-0.5-0.3-1.1-0.5-1.6-0.6l0.3-0.5c0.9-0.2,1.9-0.2,2.8,0c0.9,0.2,1.7,0.6,2.3,1.3
									c0.8,0.8,1.2,1.7,1.1,2.6c-0.1,0.9-0.5,1.8-1.4,2.7c-0.5,0.5-1.1,1-1.8,1.5c-0.7,0.6-1.2,1-1.6,1.3c-0.5,0.5-0.9,0.9-1.1,1.3
									c-0.2,0.4-0.3,0.9-0.4,1.5c-0.9,0-1.7-0.3-2.3-0.8c-0.1-0.6,0.1-1.2,0.5-1.8c-0.8-0.1-1.5-0.3-2.2-0.6c-0.7-0.3-1.2-0.7-1.6-1.1
									C979.3,738,979.1,737.4,979.2,736.7z M982.8,739c0.5,0.2,1,0.3,1.5,0.4l0.1-0.1c0.4-0.4,0.9-0.8,1.4-1.2l1-0.8
									c-0.9-0.8-1.7-1.3-2.5-1.5c-0.8-0.1-1.5,0-2,0.6c-0.2,0.2-0.4,0.5-0.5,0.8s-0.2,0.7-0.1,1C981.8,738.5,982.3,738.8,982.8,739z"
						/>
						<path
							d="M986.4,745.3c1.6-1.1,3.3-2.2,5-3.5c0.8-0.6,1.5-1.1,1.9-1.5c0.4-0.4,0.7-0.7,0.9-1c0.2-0.3,0.3-0.7,0.4-1.1
									c0.4,0,0.8,0.1,1.2,0.3c0.3,0.2,0.6,0.4,0.7,0.6c0.6,0.8,0.4,1.7-0.8,2.6c0.8,0,1.5,0.1,2.1,0.3c0.6,0.2,1.2,0.5,1.8,0.9
									c-0.2,0.3-0.5,0.6-0.9,1.1c-0.4,0.4-0.8,0.8-1.2,1.1c-0.1-0.2-0.3-0.4-0.5-0.8c-0.3-0.5-0.7-0.8-1.1-1.2c-0.4-0.3-0.8-0.5-1.2-0.7
									l-0.7,0.5c-1,0.7-1.9,1.4-2.7,2c-1.6,1.2-2.7,2.2-3.1,3c-0.4-0.4-0.7-0.7-1-1.1C986.9,746.3,986.7,745.8,986.4,745.3z"
						/>
						<path
							d="M991,751.8c1.7-0.9,3.5-2,5.3-3.1c0.9-0.6,1.6-1,2-1.4c0.4-0.3,0.8-0.7,1-1c0.2-0.3,0.4-0.6,0.5-1.1
									c0.4,0.1,0.8,0.2,1.2,0.4c0.3,0.2,0.6,0.4,0.7,0.6c0.5,0.9,0.2,1.7-1,2.5c0.8,0.1,1.5,0.2,2.1,0.4s1.2,0.6,1.7,1
									c-0.2,0.3-0.5,0.6-0.9,1c-0.4,0.4-0.9,0.7-1.3,1c-0.1-0.2-0.2-0.5-0.4-0.8c-0.3-0.5-0.6-0.9-1-1.3c-0.4-0.4-0.8-0.6-1.1-0.8
									l-0.8,0.5c-1.1,0.6-2.1,1.2-2.9,1.8c-1.7,1.1-2.8,2-3.4,2.8c-0.4-0.4-0.7-0.8-0.9-1.1C991.4,752.8,991.2,752.4,991,751.8z"
						/>
						<path
							d="M996.1,758c0.5-1.3,1.5-2.4,3.1-3.2c1.1-0.6,2.2-0.9,3.3-1c1.1,0,2.1,0.2,3,0.7c0.9,0.5,1.6,1.2,2.2,2.2
									c0.5,0.9,0.7,1.8,0.6,2.7c-0.1,0.9-0.4,1.7-1,2.5c-0.6,0.8-1.4,1.5-2.4,2c-1.1,0.6-2.2,0.9-3.3,1c-1.1,0-2.1-0.2-3-0.7
									c-0.9-0.5-1.6-1.3-2.2-2.3C995.7,760.6,995.6,759.3,996.1,758z M999.8,763.3c0.9,0.1,2-0.1,3.2-0.8c1-0.6,1.8-1.3,2.3-2.2
									s0.7-1.8,0.5-2.7c-0.5-0.9-1.2-1.4-2.2-1.6c-1-0.2-2,0.1-3.1,0.7c-1.3,0.7-2.2,1.5-2.7,2.5c-0.5,0.9-0.5,1.8-0.1,2.5
									C998.2,762.6,998.9,763.1,999.8,763.3z"
						/>
						<path
							d="M1003,770l2.4-2.7c0.6-0.6,1.1-1.2,1.5-1.7c0.5-0.6,1-1.3,1.4-1.8c0.4-0.6,0.6-1,0.7-1.3c0.4,0.3,0.8,0.6,1.1,0.9
									c0.3,0.3,0.7,0.8,1,1.3c-0.6,0.6-1.9,1.9-3.7,3.9c-1.8,2-2.9,3.3-3.3,3.8c4.3-1.2,6.9-2,7.9-2.3c0.5-0.7,0.7-1.2,0.8-1.6
									c0.8,0.5,1.5,1.2,2.2,2.2c-0.6,0.5-1.9,1.9-3.9,4s-3,3.4-3.2,3.7c3.1-0.8,5.3-1.5,6.5-1.8c1.2-0.4,2.1-0.7,2.5-1
									c0.1-0.1,0.3-0.2,0.5-0.5c0.5,0.4,1.1,1,1.6,1.8c-1.3,0.2-3,0.6-5,1.1c-2,0.5-4.2,1.1-6.5,1.9l-1.5-0.7c-0.3-0.4-0.4-0.9-0.2-1.4
									c0.1-0.5,0.5-1.1,1-1.7c1.7-1.9,2.9-3.3,3.7-4.1c-1.7,0.4-4,1.1-6.8,2l-1.5-0.7C1001.6,772.3,1001.9,771.2,1003,770z"
						/>
						<path
							d="M984.5,769.6c-0.2-0.6-0.4-1.2-0.5-1.7c0.7-0.2,1.7-0.6,2.9-1.1c1.2-0.5,2.3-1,3.4-1.5c2.4-1.1,3.8-1.9,4.2-2.3
									c0.4,0.3,0.8,0.8,1.2,1.5c0.4,0.6,0.8,1.3,1.1,2c0.8,1.7,0.9,3.3,0.4,4.6c-0.6,1.4-1.7,2.5-3.5,3.3c-1.1,0.5-2.2,0.7-3.3,0.7
									c-1.1-0.1-2.1-0.4-3-1.1c-0.9-0.6-1.6-1.5-2.1-2.7C985,770.8,984.7,770.2,984.5,769.6z M988.2,772.9c0.6,0.3,1.3,0.4,2.1,0.4
									c0.8,0,1.5-0.2,2.1-0.5c3.1-1.4,4-3.4,2.9-6c-0.2-0.4-0.4-0.8-0.8-1.2c-1.6,0.6-3.3,1.3-5,2.1c-1.4,0.6-2.5,1.2-3.4,1.7
									c0.2,1.1,0.4,1.9,0.6,2.4C987,772.3,987.5,772.6,988.2,772.9z"
						/>
						<path
							d="M990.4,779.3c0.4-1.1,1.2-1.9,2.4-2.6c0.9-0.5,1.8-0.8,2.7-0.9c0.9-0.1,1.7,0.1,2.4,0.5c0.7,0.4,1.3,1,1.8,1.8
									c0.4,0.7,0.6,1.4,0.5,2.1c0,0.7-0.3,1.4-0.7,2.1c-0.5,0.6-1.1,1.2-1.9,1.7c-0.9,0.5-1.8,0.8-2.7,0.8c-0.9,0-1.7-0.1-2.5-0.5
									c-0.7-0.4-1.4-1-1.8-1.8C990.1,781.4,990,780.4,990.4,779.3z M993.5,783.5c0.7,0.1,1.6-0.1,2.5-0.7c0.8-0.5,1.5-1.1,1.9-1.8
									c0.4-0.7,0.5-1.4,0.4-2.2c-0.4-0.7-1-1.1-1.8-1.3c-0.8-0.1-1.6,0.1-2.5,0.6c-1.1,0.6-1.8,1.3-2.1,2c-0.4,0.8-0.4,1.4,0,2.1
									C992.2,783,992.7,783.4,993.5,783.5z"
						/>
						<path
							d="M996.8,788.7l1.9-2.3c0.5-0.5,0.8-1,1.2-1.4c0.4-0.5,0.8-1.1,1-1.5c0.3-0.5,0.4-0.8,0.5-1.1c0.4,0.2,0.7,0.4,1,0.7
									c0.3,0.3,0.6,0.6,0.9,1c-0.5,0.5-1.4,1.6-2.9,3.3c-1.4,1.7-2.2,2.8-2.5,3.2c3.4-1.1,5.5-1.9,6.3-2.2c0.3-0.6,0.5-1,0.6-1.3
									c0.7,0.4,1.3,0.9,1.8,1.7c-0.5,0.5-1.5,1.6-3,3.4s-2.3,2.8-2.4,3.1c2.5-0.8,4.2-1.4,5.2-1.8c1-0.4,1.6-0.7,2-0.9
									c0.1-0.1,0.2-0.2,0.4-0.4c0.4,0.3,0.9,0.8,1.3,1.4c-1,0.2-2.4,0.6-4,1.1c-1.6,0.5-3.3,1.1-5.2,1.8l-1.3-0.5
									c-0.3-0.3-0.3-0.7-0.2-1.1s0.3-0.9,0.7-1.4c1.3-1.6,2.2-2.8,2.8-3.4c-1.4,0.4-3.2,1-5.4,1.9l-1.3-0.5
									C995.8,790.6,996,789.8,996.8,788.7z"
						/>
						<path
							d="M1004.1,801.1c1.2-1,2.5-2,3.8-3.2c0.6-0.6,1.1-1,1.4-1.4s0.5-0.6,0.6-0.9c0.1-0.3,0.2-0.6,0.3-0.9c0.4,0,0.7,0,1,0.1
									c0.3,0.1,0.5,0.2,0.6,0.4c0.5,0.6,0.5,1.2-0.2,1.9c0.6,0,1.2,0,1.7,0.2c0.5,0.2,1,0.5,1.3,0.9c1.1,1.2,0.9,2.6-0.7,4l-1.1,1
									c-0.5,0.4-0.9,0.7-1.2,1c-1.2,1.1-2,2-2.3,2.7c-0.3-0.2-0.6-0.5-0.9-0.8c-0.2-0.3-0.5-0.6-0.8-1c1.5-1.2,3-2.5,4.6-4
									c0.5-0.4,0.7-0.8,0.8-1.2c0-0.4-0.1-0.7-0.5-1.1c-0.5-0.6-1.1-0.9-1.7-1.1l-0.8,0.7c-0.6,0.5-1.3,1.1-2.1,1.8
									c-1.2,1.1-2,2-2.3,2.7c-0.4-0.3-0.7-0.5-0.9-0.8C1004.6,801.9,1004.4,801.5,1004.1,801.1z"
						/>
						<path
							d="M1011.3,808.1c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.2,0.5-0.3,0.8-0.4c-0.1,0.5,0,0.9,0.2,1.4c0.2,0.5,0.5,0.9,0.8,1.2
									c1,0.9,1.8,1.1,2.3,0.5c0.2-0.2,0.3-0.6,0.3-0.9c-0.1-0.4-0.2-0.9-0.5-1.5c-0.2-0.7-0.4-1.2-0.5-1.6c-0.1-0.4-0.1-0.9-0.1-1.4
									c0.1-0.5,0.3-1,0.7-1.4c0.5-0.5,1.1-0.8,1.9-0.7c0.7,0.1,1.5,0.4,2.2,1.1c0.9,0.9,1.6,1.8,2,2.6c-0.2,0.2-0.4,0.4-0.8,0.6
									c-0.4,0.2-0.7,0.4-1.1,0.5c0-0.7-0.1-1.4-0.2-1.9c-0.1-0.6-0.3-0.9-0.6-1.2c-0.3-0.2-0.6-0.3-0.9-0.3s-0.5,0.1-0.7,0.3
									c-0.3,0.3-0.4,0.7-0.4,1.1c0.1,0.4,0.2,1,0.5,1.7c0.2,0.6,0.4,1.1,0.5,1.6c0.1,0.4,0.1,0.9,0,1.4c-0.1,0.5-0.3,0.9-0.7,1.3
									c-0.6,0.6-1.3,0.9-2.1,0.8s-1.6-0.5-2.5-1.3C1012.1,810,1011.5,809.1,1011.3,808.1z"
						/>
					</g>
					<g className="level_two fade location " id="andreth_greenway">
						<rect x="1210.019" y="1017.019" width="78.568" height="22.375" transform="matrix(-0.149449, 0.988769, -0.98697, -0.161357, 2245.758789, -341.158264)"></rect>
						<rect x="950.064" y="1017.02" width="61.689" height="22.375" transform="matrix(0.338563, 0.940944, -0.945021, 0.327232, 1685.808105, -466.846222)"></rect>
						<rect x="950.064" y="1017.02" width="61.689" height="22.375" transform="matrix(0.712415, 0.701758, -0.710337, 0.703965, 1099.97522, -583.164856)"></rect>
						<path
							d="M1045.2,699.4l3.4-0.7c0.1-0.7,0.3-1.6,0.4-2.7c0.1-1.2,0.2-1.9,0.1-2.4c-0.9-0.4-2-0.9-3.2-1.4c-0.1-0.3-0.1-0.6-0.1-0.8
									c0-0.3,0.1-0.6,0.3-0.9c0.8,0.5,2.7,1.5,5.7,3s5.4,2.5,7.1,3.3c0,0.4,0,0.8,0,1c0,0.3-0.1,0.6-0.2,0.9c-1.8,0.3-4.4,0.9-7.7,1.7
									c-3.3,0.8-5.3,1.3-6.2,1.7c-0.1-0.4-0.1-0.8,0-1.3C1044.9,700.3,1045,699.8,1045.2,699.4z M1050.2,698.3c2.4-0.5,4.3-1,5.9-1.4
									c-1.3-0.7-3.1-1.6-5.4-2.6c-0.1,0.8-0.3,1.7-0.4,2.7C1050.2,697.6,1050.2,698.1,1050.2,698.3z"
						/>
						<path
							d="M1044.5,704.1c1.7,0.4,3.5,0.7,5.4,1c0.9,0.1,1.6,0.2,2.1,0.2c0.5,0,0.9,0,1.2-0.1c0.3-0.1,0.6-0.2,1-0.4
									c0.3,0.3,0.5,0.6,0.6,0.9c0.1,0.3,0.1,0.6,0.1,0.8c-0.1,0.8-0.7,1.2-1.7,1.2c0.4,0.5,0.8,1,1,1.6c0.2,0.6,0.3,1.2,0.2,1.7
									c-0.3,1.8-1.6,2.5-3.9,2.2l-1.6-0.3c-0.6-0.1-1.2-0.2-1.7-0.3c-1.7-0.3-3-0.3-3.9-0.1c0-0.4,0-0.9,0-1.3c0.1-0.4,0.2-0.9,0.4-1.4
									c2,0.4,4.2,0.8,6.6,1.1c0.7,0.1,1.2,0,1.5-0.2s0.5-0.6,0.6-1.2c0.1-0.9,0-1.6-0.3-2.2l-1.1-0.2c-0.9-0.2-1.9-0.3-3-0.5
									c-1.8-0.3-3.1-0.3-3.9-0.1c0-0.5,0-0.9,0-1.3C1044.2,705.1,1044.4,704.6,1044.5,704.1z"
						/>
						<path
							d="M1043.8,716.1c1-0.5,2.4-0.5,4.2-0.2c1.8,0.3,3.1,0.9,3.9,1.7s1.1,1.9,0.8,3.2c-0.2,1-0.7,1.9-1.5,2.6
									c1.7,0.2,3,0.3,3.8,0.3c0.1,0.5,0.2,0.9,0.2,1.3c0,0.4,0,0.8-0.1,1.3c-2.1-0.6-5.6-1.3-10.5-2.2c-0.6-0.1-1.1-0.1-1.4-0.1
									c-0.4,0.1-0.8,0.2-1.2,0.5c-0.4-0.6-0.6-1.3-0.6-2c0.4-0.4,0.9-0.6,1.6-0.6c-0.4-0.5-0.6-1-0.8-1.6c-0.2-0.6-0.2-1.1-0.1-1.6
									C1042.1,717.4,1042.8,716.5,1043.8,716.1z M1043.2,720.6c0.1,0.4,0.3,0.8,0.5,1.3c0.5,0.1,1.4,0.3,2.6,0.5
									c1.4,0.3,2.4,0.5,3.2,0.6c0.5-0.5,0.9-1.2,1-2c0.1-0.8,0-1.5-0.6-2c-0.5-0.5-1.4-0.9-2.6-1.1c-1.2-0.2-2.1-0.2-2.9,0.1
									c-0.8,0.3-1.3,0.8-1.5,1.5C1043,719.8,1043.1,720.2,1043.2,720.6z"
						/>
						<path
							d="M1040.5,727.4c1.6,0.5,3.4,0.9,5.3,1.3c0.9,0.2,1.6,0.3,2.1,0.4c0.5,0,0.9,0,1.2,0c0.3-0.1,0.6-0.2,1-0.4
									c0.2,0.3,0.4,0.6,0.5,0.9s0.1,0.6,0.1,0.8c-0.2,0.9-0.9,1.2-2.1,1c0.4,0.5,0.8,1,1,1.6c0.2,0.5,0.4,1.1,0.4,1.7
									c-0.3,0-0.7,0.1-1.2,0c-0.5,0-1-0.1-1.4-0.2c0.1-0.2,0.1-0.4,0.2-0.8c0.1-0.5,0.1-1,0.1-1.4c0-0.5-0.1-0.9-0.2-1.2l-0.8-0.2
									c-1.1-0.3-2.1-0.5-2.9-0.7c-1.7-0.4-3-0.5-3.8-0.3c0-0.5,0-0.9,0.1-1.3S1040.3,727.9,1040.5,727.4z"
						/>
						<path
							d="M1039.9,735.1c1-0.5,2.3-0.5,3.9-0.2c1.1,0.2,2,0.6,2.8,1.2c0.8,0.6,1.3,1.3,1.7,2.1s0.4,1.7,0.2,2.6
									c-0.3,1.2-0.9,2.1-1.9,2.5c-1,0.4-2.4,0.5-4.1,0.1c0-0.9,0-1.9,0.1-3.1c0.1-1.2,0.2-2.3,0.4-3.5c-1-0.1-1.8,0-2.4,0.3
									s-0.9,0.9-1.1,1.7c-0.1,0.6-0.2,1.2-0.1,1.8c0,0.6,0.2,1.1,0.4,1.6l-0.5,0.1c-0.6-0.6-1-1.4-1.2-2.2c-0.2-0.9-0.3-1.7-0.1-2.6
									C1038.2,736.4,1038.8,735.5,1039.9,735.1z M1043.7,739.4c0,0.8,0.1,1.4,0.3,1.9c0.9,0.2,1.7,0.1,2.3-0.2c0.7-0.3,1.1-0.8,1.2-1.5
									c-0.2-0.5-0.6-1-1.3-1.5c-0.7-0.4-1.5-0.8-2.4-1C1043.7,737.8,1043.7,738.6,1043.7,739.4z"
						/>
						<path
							d="M1036.7,746.5c0.3-0.2,0.9-0.2,1.6-0.1c0.5,0.1,1.4,0.2,2.5,0.5c1.3,0.2,2.3,0.4,3.2,0.6c0.4,0.1,0.8,0.1,1.2,0.1
									c0.1-0.6,0.2-1,0.3-1.2c0.1-0.2,0.3-0.3,0.5-0.2c0.5,1.1,1.2,2.2,2.1,3.3l-0.2,0.5c-0.6,0-1.2-0.1-1.8-0.2c-0.1,1-0.2,1.8-0.2,2.3
									c-0.4-0.1-0.7-0.1-0.9-0.2c-0.3-0.1-0.5-0.2-0.8-0.4c0.3-0.4,0.5-1,0.6-1.9c-0.7-0.2-1.5-0.3-2.4-0.4c-2.1-0.3-3.7-0.5-4.8-0.4
									c-0.1,0.7-0.1,1.3,0,1.8l-0.6,0c-0.4-0.4-0.7-0.8-0.9-1.3c-0.2-0.5-0.3-1.1-0.2-1.6C1036.1,747,1036.3,746.7,1036.7,746.5z"
						/>
						<path
							d="M1035.5,753.2c2.2,0.3,4.3,0.5,6.5,0.7c3,0.2,5.3,0.4,6.9,0.3c0.3,0.8,0.4,1.7,0.3,2.6c-0.8-0.2-2.4-0.4-4.9-0.7
									c0.5,0.5,0.8,1,1.1,1.6c0.3,0.6,0.4,1.2,0.3,1.7c-0.1,1.8-1.4,2.6-3.7,2.4l-1.6-0.2l-1.7-0.2c-1.8-0.1-3.1-0.1-3.9,0.2
									c-0.1-0.4-0.1-0.9-0.1-1.3c0-0.4,0.1-0.8,0.3-1.4c2.4,0.3,4.6,0.5,6.7,0.7c0.7,0.1,1.2,0,1.5-0.3c0.3-0.2,0.5-0.6,0.5-1.2
									c0.1-0.8-0.1-1.6-0.5-2.3c-0.5-0.1-1.2-0.1-2.1-0.2c-3.2-0.3-5.1-0.3-5.9,0c-0.1-0.4-0.1-0.9,0-1.3
									C1035.2,754.2,1035.3,753.7,1035.5,753.2z"
						/>
						<path
							d="M1035.9,773.1c1-1,2.6-1.6,4.6-1.8c1.4-0.1,2.7,0,3.9,0.4c1.2,0.4,2.1,1.1,2.8,1.9c0.7,0.9,1.1,1.9,1.2,3.1
									c0.1,1.4,0,2.7-0.3,3.7c-0.4,0-0.8,0-1.3-0.1c-0.5-0.1-0.9-0.2-1.2-0.3c0.4-0.7,0.8-1.5,1-2.3c0.3-0.8,0.4-1.5,0.3-1.9
									c-0.2-0.5-0.6-0.9-1.2-1.3c-0.6-0.4-1.3-0.7-2.1-0.9c-0.8-0.2-1.6-0.2-2.5-0.2c-1.9,0.2-3.3,0.6-4,1.3c-0.8,0.7-1.1,1.8-1,3.2
									c0.1,0.7,0.2,1.2,0.4,1.7c2.8-0.3,4.5-0.6,5.1-0.9c0.1,0.4,0.2,0.8,0.3,1.2c0,0.4,0,0.8,0,1.3c-1.7,0-3.5,0.1-5.4,0.3
									c-0.6-0.5-1.1-1.1-1.5-1.9c-0.4-0.8-0.6-1.7-0.7-2.5C1034.5,775.5,1034.9,774.2,1035.9,773.1z"
						/>
						<path
							d="M1036.5,787c1.7-0.3,3.4-0.7,5.3-1.2c0.9-0.2,1.6-0.4,2.1-0.6c0.5-0.2,0.8-0.4,1.1-0.5s0.5-0.4,0.7-0.8
									c0.4,0.2,0.6,0.4,0.9,0.6c0.2,0.2,0.4,0.5,0.4,0.7c0.2,0.9-0.3,1.5-1.5,1.8c0.6,0.3,1.2,0.6,1.6,0.9c0.5,0.4,0.8,0.8,1.1,1.3
									c-0.2,0.2-0.6,0.4-1.1,0.6c-0.5,0.2-0.9,0.4-1.3,0.5c0-0.2-0.1-0.5-0.1-0.8c-0.1-0.5-0.3-0.9-0.5-1.3c-0.2-0.4-0.5-0.7-0.7-1
									l-0.8,0.2c-1.1,0.2-2.1,0.5-2.9,0.7c-1.7,0.4-2.9,0.9-3.6,1.4c-0.2-0.4-0.4-0.8-0.5-1.2C1036.6,788,1036.6,787.5,1036.5,787z"
						/>
						<path
							d="M1039.9,795.1c0.6-0.9,1.7-1.7,3.2-2.3c1-0.4,2.1-0.6,3-0.5c1,0.1,1.8,0.3,2.5,0.8s1.3,1.2,1.6,2.1
									c0.4,1.2,0.4,2.2-0.2,3.1s-1.7,1.7-3.3,2.3c-0.5-0.7-1-1.6-1.6-2.6c-0.6-1-1.1-2.1-1.5-3.1c-0.9,0.5-1.5,1-1.8,1.5
									c-0.3,0.6-0.3,1.2,0,2c0.2,0.6,0.5,1.1,0.9,1.6c0.4,0.5,0.7,0.8,1.2,1.1l-0.4,0.3c-0.8-0.2-1.6-0.6-2.2-1.2
									c-0.7-0.6-1.2-1.3-1.5-2.1C1039.3,797.1,1039.3,796.1,1039.9,795.1z M1045.6,796.6c0.5,0.6,0.9,1.1,1.2,1.4
									c0.8-0.3,1.4-0.8,1.8-1.5c0.4-0.6,0.4-1.3,0.2-2c-0.5-0.3-1.1-0.5-1.9-0.5c-0.8,0-1.7,0.2-2.6,0.5
									C1044.7,795.3,1045.1,796,1045.6,796.6z"
						/>
						<path
							d="M1044.5,806.1c0.5-1,1.4-1.9,2.9-2.7c1-0.5,2-0.8,2.9-0.9c1-0.1,1.8,0.1,2.6,0.5c0.8,0.4,1.4,1,1.8,1.8
									c0.6,1.1,0.7,2.1,0.2,3.1c-0.5,1-1.5,1.9-3,2.7c-0.6-0.6-1.2-1.4-1.9-2.4c-0.7-1-1.3-1.9-1.9-2.9c-0.9,0.6-1.4,1.2-1.6,1.8
									c-0.2,0.6-0.1,1.3,0.3,2c0.3,0.6,0.7,1.1,1.1,1.5c0.4,0.4,0.8,0.7,1.3,1l-0.3,0.4c-0.8-0.1-1.6-0.4-2.4-0.9
									c-0.8-0.5-1.3-1.1-1.7-1.9C1044.1,808.2,1044.1,807.1,1044.5,806.1z M1050.3,806.9c0.5,0.6,1,1,1.4,1.3c0.8-0.4,1.3-1,1.6-1.7
									c0.3-0.7,0.3-1.3-0.1-2c-0.5-0.3-1.1-0.4-1.9-0.3c-0.8,0.1-1.6,0.4-2.5,0.8C1049.3,805.7,1049.8,806.3,1050.3,806.9z"
						/>
						<path
							d="M1049.8,817.3c1.4-0.9,2.9-2,4.5-3.1c0.8-0.6,1.3-1,1.7-1.3c0.4-0.3,0.6-0.6,0.8-0.9c0.2-0.3,0.3-0.6,0.4-1
									c0.4,0,0.8,0.1,1,0.2c0.3,0.1,0.5,0.3,0.6,0.5c0.5,0.7,0.4,1.4-0.4,2.1c0.7,0,1.3,0.2,1.8,0.4c0.6,0.3,1,0.6,1.4,1.1
									c1.1,1.5,0.7,2.9-1.2,4.3l-1.3,0.9c-0.5,0.3-1,0.7-1.4,1c-1.4,1-2.3,1.9-2.8,2.7c-0.3-0.3-0.6-0.6-0.9-0.9
									c-0.2-0.3-0.5-0.7-0.7-1.2c1.7-1.1,3.5-2.4,5.5-3.9c0.5-0.4,0.9-0.8,1-1.2s0-0.8-0.4-1.3c-0.5-0.7-1.1-1.1-1.7-1.4l-0.9,0.6
									c-0.7,0.5-1.6,1.1-2.5,1.8c-1.4,1.1-2.4,2-2.8,2.7c-0.4-0.3-0.7-0.6-0.9-1C1050.3,818.2,1050.1,817.8,1049.8,817.3z"
						/>
						<path
							d="M1060.2,827.4l1.5-2.8c0.4-0.7,0.7-1.3,1-1.8c0.3-0.7,0.6-1.3,0.8-1.9c0.2-0.6,0.3-1,0.3-1.3c0.4,0.1,0.8,0.3,1.2,0.5
									c0.4,0.2,0.7,0.5,1.1,0.9c-0.4,0.6-1.2,2-2.4,4.1c-1.2,2.1-1.8,3.4-2,3.9c3.4-1.9,5.5-3.1,6.3-3.7c0.3-0.7,0.4-1.2,0.4-1.5
									c0.8,0.3,1.6,0.8,2.3,1.5c-0.4,0.6-1.3,2-2.5,4.3c-1.2,2.2-1.9,3.5-2,3.8c2.5-1.4,4.2-2.3,5.2-2.9s1.6-1.1,1.9-1.4
									c0.1-0.1,0.2-0.3,0.3-0.5c0.5,0.3,1.1,0.7,1.7,1.2c-1.1,0.5-2.4,1.1-4,2c-1.6,0.9-3.4,1.9-5.2,3l-1.5-0.3
									c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.5,0.2-1,0.5-1.7c1.1-2,1.8-3.5,2.3-4.3c-1.4,0.7-3.2,1.8-5.4,3.1l-1.5-0.3
									C1059.5,829.6,1059.5,828.7,1060.2,827.4z"
						/>
						<path
							d="M1071.2,839c-0.1-0.6,0.1-1.2,0.5-1.7c1.2-1.7,3.3-1.6,6.5,0.3c0.6-0.9,1-1.7,1-2.2s-0.3-1-1-1.5
									c-0.4-0.3-0.9-0.5-1.4-0.6c-0.5-0.1-1-0.2-1.5-0.2l0.1-0.5c0.8-0.4,1.6-0.6,2.4-0.5c0.9,0,1.6,0.2,2.2,0.7
									c0.8,0.6,1.3,1.3,1.4,2.1c0.1,0.8-0.1,1.6-0.8,2.6c-0.3,0.5-0.8,1.1-1.3,1.6c-0.5,0.6-0.9,1.1-1.1,1.4c-0.4,0.5-0.6,1-0.7,1.3
									c-0.1,0.4-0.1,0.8-0.1,1.4c-0.8,0.1-1.5,0-2.1-0.3c-0.2-0.5-0.2-1,0.1-1.7c-0.7,0-1.4,0-2-0.2c-0.6-0.1-1.2-0.3-1.6-0.6
									C1071.6,840,1071.3,839.6,1071.2,839z M1074.7,840.3c0.5,0.1,0.9,0.1,1.4,0.1l0.1-0.1c0.3-0.4,0.6-0.9,1-1.3l0.7-0.9
									c-0.9-0.5-1.7-0.8-2.4-0.8c-0.7,0-1.3,0.3-1.7,0.9c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0,0.6,0.1,0.9
									C1073.8,840.1,1074.3,840.2,1074.7,840.3z"
						/>
						<path
							d="M1078.7,848.6c0.7-1.3,1.9-2.3,3.6-2.8c-0.5-0.8-0.4-2,0.3-3.4l0.6-1.1l0.7-1.3c0.8-1.5,1.2-2.7,1.3-3.4
									c0.5,0,0.9,0.1,1.3,0.2c0.4,0.1,0.8,0.3,1.3,0.5c-0.5,0.8-1.6,2.8-3.2,5.9c-0.3,0.6-0.4,1.1-0.3,1.5c0.1,0.4,0.4,0.7,0.9,1
									c0.8,0.4,1.6,0.6,2.3,0.5l1.3-2.7c0.1-0.2,0.2-0.5,0.4-0.8c0.4-0.7,0.7-1.4,0.9-2c0.2-0.6,0.3-1.1,0.4-1.4
									c0.8,0.1,1.7,0.3,2.5,0.7c-0.7,1.2-1.8,3-3.1,5.6c-0.5,1-1,1.9-1.3,2.6c-0.3,0.7-0.5,1.1-0.6,1.2c-0.7,1.3-1.6,2.2-2.8,2.4
									s-2.4,0.1-3.7-0.6C1080.2,850.6,1079.2,849.7,1078.7,848.6z M1084.6,850.3c0.7-0.3,1.3-1.1,2-2.3l0.6-1.1
									c-0.6,0.2-1.3,0.4-1.9,0.3s-1.2-0.2-1.8-0.5c-0.1-0.1-0.3-0.2-0.6-0.3c-0.9,0.4-1.6,1-2.1,1.6c0.1,0.4,0.3,0.8,0.6,1.2
									c0.3,0.4,0.6,0.7,1.1,0.9C1083.2,850.6,1083.9,850.6,1084.6,850.3z"
						/>
					</g>
					<g className="level_zero location" id="bree">
						<rect className="level_zero location" x="1029.8" y="666.7" transform="matrix(0.965 -0.2624 0.2624 0.965 -140.5126 296.9214)" width="23" height="15.7" />
						<rect x="948.295" y="1287.673" width="61.574" height="28.328" transform="matrix(0.966132, 0.25805, -0.236202, 0.971967, 451.946259, -827.431213)"></rect>
						<path
							d="M1064.2,694c-1.2-0.5-2.2-1.1-3-1.6c0.4-1.1,0.9-2.8,1.6-5.1c0.6-2.3,1.3-4.5,1.8-6.6c1.1-4.2,1.5-6.8,1.4-7.8
									c1-0.1,2.1-0.1,3.5,0.1c1.4,0.1,2.7,0.4,3.9,0.7c2.3,0.6,4,1.5,5,2.8c1,1.3,1.3,2.8,0.8,4.6c-0.3,1-0.8,1.8-1.5,2.5
									c-0.7,0.7-1.7,1.1-2.8,1.4c1.1,0.8,1.9,1.7,2.4,2.8s0.6,2.2,0.2,3.4c-0.5,2-1.6,3.3-3.3,4.1c-1.7,0.7-3.8,0.7-6.3,0.1
									C1066.7,695,1065.5,694.5,1064.2,694z M1074,690.3c0.3-1,0.2-1.9-0.1-2.8s-0.9-1.6-1.7-2.3c-1.1-0.3-2.9-0.4-5.4-0.2
									c-0.8,3.2-1.4,5.5-1.6,7.1c0.8,0.6,1.7,1,2.8,1.3C1071.3,694.2,1073.3,693.2,1074,690.3z M1071,683.2c0.7,0.2,1.2,0.3,1.5,0.5
									c1.7-0.7,2.8-1.7,3.1-3c0.6-2.3-0.5-3.8-3.2-4.5c-1-0.3-1.9-0.4-2.8-0.3c-0.6,1.9-1.3,4.2-2,6.9
									C1068.5,682.7,1069.6,682.8,1071,683.2z"
						/>
						<path
							d="M1078.9,697.5c0.8-2.6,1.5-5.4,2.3-8.5c0.3-1.5,0.6-2.6,0.7-3.4c0.1-0.8,0.1-1.4,0-1.9c-0.1-0.5-0.3-1-0.6-1.6
									c0.5-0.4,1-0.6,1.5-0.8c0.5-0.1,1-0.2,1.3-0.1c1.4,0.3,1.9,1.5,1.5,3.5c0.8-0.7,1.7-1.2,2.5-1.6c0.9-0.4,1.8-0.6,2.8-0.6
									c0.1,0.5,0.1,1.1,0,1.9c-0.1,0.8-0.2,1.6-0.3,2.2c-0.3-0.1-0.7-0.2-1.2-0.4c-0.8-0.2-1.6-0.3-2.3-0.2c-0.8,0-1.4,0.1-1.9,0.3
									l-0.3,1.2c-0.5,1.7-0.9,3.3-1.2,4.7c-0.7,2.8-0.9,4.9-0.7,6.2c-0.8,0-1.5-0.1-2.1-0.2C1080.4,698.1,1079.7,697.9,1078.9,697.5z"
						/>
						<path
							d="M1089,698.1c-0.7-1.6-0.8-3.7-0.3-6.3c0.4-1.8,1-3.3,2-4.5c0.9-1.2,2.1-2.1,3.4-2.7c1.3-0.5,2.7-0.6,4.2-0.3
									c2,0.4,3.3,1.4,4,3.1c0.7,1.6,0.8,3.8,0.2,6.5c-1.4,0.1-3,0-4.9-0.1c-1.9-0.1-3.8-0.4-5.6-0.7c-0.2,1.7,0,2.9,0.5,3.8
									c0.5,0.9,1.4,1.5,2.7,1.7c1,0.2,2,0.3,2.9,0.2c1-0.1,1.8-0.3,2.5-0.6l0.1,0.8c-1,0.9-2.2,1.6-3.6,1.9c-1.4,0.4-2.8,0.4-4.1,0.2
									C1091.1,700.8,1089.8,699.8,1089,698.1z M1096,691.9c1.2-0.1,2.3-0.2,3-0.4c0.3-1.4,0.2-2.7-0.4-3.7c-0.5-1.1-1.4-1.7-2.5-2
									c-0.9,0.3-1.7,1-2.4,2.1c-0.7,1.1-1.2,2.4-1.5,3.9C1093.5,691.9,1094.7,691.9,1096,691.9z"
						/>
						<path
							d="M1103.7,701.2c-0.8-1.6-0.9-3.7-0.4-6.3c0.3-1.8,1-3.3,1.9-4.6c0.9-1.3,2-2.2,3.3-2.7s2.7-0.7,4.2-0.4
									c2,0.4,3.3,1.4,4.1,3c0.8,1.6,0.9,3.8,0.4,6.5c-1.4,0.1-3,0.1-4.9,0c-1.9-0.1-3.8-0.3-5.6-0.6c-0.2,1.7,0,2.9,0.6,3.8
									c0.5,0.9,1.5,1.4,2.8,1.7c1,0.2,2,0.2,2.9,0.2c1-0.1,1.8-0.3,2.5-0.6l0.1,0.8c-1,0.9-2.2,1.6-3.5,2c-1.4,0.4-2.8,0.5-4.1,0.3
									C1105.9,703.8,1104.5,702.8,1103.7,701.2z M1110.5,694.8c1.2-0.1,2.2-0.3,3-0.5c0.2-1.4,0.1-2.7-0.5-3.7c-0.6-1.1-1.4-1.7-2.5-1.9
									c-0.9,0.3-1.6,1.1-2.3,2.2c-0.7,1.1-1.2,2.4-1.5,3.9C1108,694.9,1109.3,694.9,1110.5,694.8z"
						/>
					</g>
					<g className="level_two fade location " id="south_downs">
						<rect x="1211.464" y="1490.382" width="78.662" height="32.788" transform="matrix(0.99932, -0.036871, 0.059469, 0.998486, -242.344162, -699.17041)"></rect>
						<rect x="1443.025" y="1490.384" width="93.697" height="32.788" transform="matrix(0.940791, -0.338987, 0.360262, 0.933125, -768.414673, -155.615051)"></rect>
						<path
							d="M1061.1,768.5c0-0.6,0.1-1.1,0.2-1.5c0.1-0.4,0.3-0.9,0.5-1.3c0.6,0.6,1.3,1.1,2.3,1.5c1,0.4,2,0.6,3.2,0.6
									c1.3,0,2.3-0.3,3-0.8s1.1-1.4,1.1-2.4c0-0.9-0.3-1.6-1-2.2c-0.7-0.6-1.7-1.3-3.2-2.1c-1.3-0.7-2.3-1.4-3-1.9
									c-0.7-0.5-1.4-1.2-1.9-2.1c-0.5-0.8-0.8-1.8-0.8-3c0-1.5,0.7-2.8,2-3.7c1.3-0.9,3.1-1.4,5.4-1.4c2,0,3.8,0.3,5.2,1
									c0,0.6-0.1,1.3-0.3,2c-0.2,0.7-0.4,1.3-0.7,1.8c-1-0.8-2.1-1.5-3.2-2c-1.1-0.5-2-0.8-2.7-0.8c-0.5,0.1-1,0.4-1.5,0.9
									c-0.5,0.5-0.7,1.2-0.7,1.9c0,1,0.3,1.7,1,2.4c0.7,0.6,1.8,1.3,3.3,2.1c1.2,0.7,2.2,1.3,2.9,1.8c0.7,0.5,1.4,1.2,1.9,2
									c0.5,0.8,0.8,1.8,0.8,2.8c0,1.9-0.6,3.3-1.8,4.4c-1.2,1-3,1.5-5.5,1.5C1065,770,1062.9,769.5,1061.1,768.5z"
						/>
						<path
							d="M1077.8,767.8c-1.2-1.5-1.9-3.4-1.9-5.9c0-1.8,0.3-3.3,1-4.7c0.7-1.4,1.6-2.4,2.8-3.2c1.2-0.8,2.6-1.2,4.1-1.2
									c1.4,0,2.6,0.3,3.6,1c1.1,0.7,1.9,1.6,2.5,2.8c0.6,1.2,0.9,2.6,0.9,4.2c0,1.8-0.3,3.3-1,4.7c-0.7,1.4-1.7,2.4-2.9,3.2
									c-1.2,0.8-2.7,1.2-4.3,1.2C1080.6,770.1,1079,769.3,1077.8,767.8z M1086.6,766.8c0.8-1,1.2-2.5,1.1-4.3c0-1.7-0.4-3.1-1.1-4.3
									c-0.7-1.2-1.7-2-3-2.4c-1.4,0-2.5,0.5-3.4,1.6c-0.8,1.1-1.2,2.5-1.2,4.3c0,2.1,0.4,3.7,1.2,4.9c0.8,1.2,1.8,1.8,3,1.8
									C1084.7,768.3,1085.8,767.8,1086.6,766.8z"
						/>
						<path
							d="M1093.4,764.4l0-2.5l0-2.7c0-2.6-0.3-4.5-0.8-5.6c1.2-0.6,2.5-0.8,4-0.9c-0.1,3.3-0.1,7,0,11.2c0,1.1,0.2,1.8,0.7,2.3
									c0.4,0.5,1.1,0.7,2,0.7c1.2,0,2.3-0.3,3.3-1c0-1.8,0-3.4,0-4.8l0-2.2c0-2.6-0.3-4.5-0.8-5.6c1.2-0.6,2.5-0.8,4.1-0.9
									c-0.1,1.8-0.1,4-0.2,6.5c-0.1,2.5-0.1,4.2-0.1,5.1c0,1,0.1,1.7,0.3,2.3c0.2,0.6,0.6,1.1,1.2,1.8c-0.9,0.8-1.8,1.3-2.9,1.5
									c-0.7-0.5-1.2-1.3-1.5-2.4c-0.6,0.8-1.4,1.4-2.3,1.8c-0.9,0.4-1.8,0.7-2.8,0.7C1094.9,769.9,1093.5,768.1,1093.4,764.4z"
						/>
						<path
							d="M1111.3,768.8c-0.4-0.5-0.6-1.3-0.6-2.4c0-0.8-0.1-2.2-0.1-4c0-2-0.1-3.7-0.1-5c0-0.6-0.1-1.2-0.1-1.8c-1,0-1.6,0-2-0.1
									c-0.3-0.1-0.5-0.4-0.5-0.8c1.6-1,3-2.4,4.4-4.2l0.9,0.1c0.1,0.9,0.2,1.8,0.3,2.8c1.6-0.1,2.8-0.2,3.6-0.4c0,0.6,0,1.1-0.1,1.5
									c-0.1,0.4-0.2,0.9-0.4,1.3c-0.7-0.3-1.7-0.4-3.1-0.4c0,1.2,0,2.5,0,3.8c0.1,3.3,0.4,5.8,0.8,7.4c1.1-0.1,2-0.3,2.7-0.5l0.3,0.9
									c-0.4,0.8-1,1.4-1.8,1.9c-0.8,0.5-1.5,0.7-2.3,0.8C1112.2,769.4,1111.6,769.2,1111.3,768.8z"
						/>
						<path
							d="M1119.6,768.8c-0.1-3.4-0.2-6.8-0.4-10.2c-0.3-4.6-0.7-8.2-1.1-10.8c1.2-0.6,2.5-1,4-1.1c-0.1,1.3-0.1,3.8,0,7.8
									c0.6-0.8,1.4-1.5,2.2-2.1s1.7-0.8,2.6-0.9c2.9-0.2,4.4,1.5,4.7,5.2l0.1,2.5l0.1,2.7c0.2,2.8,0.6,4.8,1.1,5.9
									c-0.7,0.2-1.3,0.3-1.9,0.4c-0.6,0-1.3,0-2.2-0.1c-0.1-3.8-0.2-7.3-0.4-10.5c-0.1-1.1-0.3-1.8-0.8-2.3c-0.4-0.4-1.1-0.6-2-0.6
									c-1.3,0.1-2.4,0.5-3.4,1.3c0,0.8,0.1,1.9,0.1,3.3c0.3,5,0.8,8,1.4,9.1c-0.6,0.2-1.3,0.3-2,0.4
									C1121.2,769,1120.4,768.9,1119.6,768.8z"
						/>
						<path
							d="M1146.9,766.7c-1.1,0-2.1,0-2.9,0c-0.1-1.3-0.2-3-0.5-5.2c-0.2-2.2-0.5-4.3-0.8-6.3c-0.6-4.4-1.2-7.1-1.8-8
									c0.7-0.4,1.7-0.8,3-1.2c1.3-0.4,2.6-0.7,3.9-0.9c3.2-0.5,5.8,0.1,7.8,1.7c2,1.6,3.2,4,3.7,7.4c0.3,2.1,0.1,4-0.5,5.8
									c-0.7,1.8-1.7,3.2-3.2,4.3s-3.3,1.9-5.4,2.2C1149.2,766.5,1148.1,766.6,1146.9,766.7z M1154.2,762.3c0.7-0.9,1.3-2,1.6-3.3
									s0.4-2.5,0.2-3.7c-0.8-5.8-3.7-8.3-8.5-7.6c-0.8,0.1-1.6,0.3-2.3,0.7c0.3,3,0.6,6,1.1,9.2c0.4,2.6,0.7,4.7,1.1,6.4
									c1.9,0.3,3.3,0.3,4.3,0.2C1152.6,763.8,1153.5,763.2,1154.2,762.3z"
						/>
						<path
							d="M1163,762.3c-1.5-1.2-2.6-3-3.1-5.3c-0.4-1.7-0.4-3.3-0.1-4.8c0.4-1.5,1-2.7,2.1-3.7c1-1,2.3-1.7,3.8-2
									c1.3-0.3,2.6-0.2,3.8,0.2c1.2,0.4,2.2,1.2,3,2.2c0.8,1.1,1.4,2.4,1.8,3.9c0.4,1.7,0.4,3.3,0,4.8c-0.4,1.5-1.1,2.7-2.1,3.8
									c-1,1-2.4,1.7-3.9,2.1C1166.3,763.8,1164.5,763.5,1163,762.3z M1171.4,759.4c0.5-1.2,0.6-2.7,0.2-4.5c-0.4-1.6-1-2.9-2-3.9
									c-1-1-2.1-1.6-3.4-1.7c-1.4,0.3-2.4,1.1-3,2.3c-0.6,1.2-0.7,2.7-0.3,4.4c0.5,2,1.2,3.5,2.3,4.5c1,1,2.2,1.4,3.4,1.1
									C1169.9,761.3,1170.8,760.6,1171.4,759.4z"
						/>
						<path
							d="M1178.9,757.4l-2.8-4.2c-0.6-1-1.2-1.9-1.7-2.7c-0.7-1-1.3-1.8-2-2.5c-0.6-0.7-1.2-1.2-1.6-1.4c0.5-0.5,1.1-0.9,1.6-1.2
									c0.6-0.3,1.3-0.6,2.1-0.9c0.5,1.1,1.8,3.2,3.9,6.4c2.1,3.2,3.4,5.1,3.9,5.7c-0.1-6.1-0.2-9.9-0.3-11.4c-0.7-0.9-1.3-1.4-1.8-1.7
									c1-0.9,2.2-1.6,3.8-2.1c0.5,1,1.8,3.2,4,6.6c2.2,3.4,3.4,5.2,3.8,5.5c0-4.5-0.1-7.6-0.2-9.4c-0.1-1.8-0.2-3-0.5-3.7
									c-0.1-0.2-0.2-0.5-0.4-0.8c0.8-0.6,1.8-1,3-1.4c-0.2,1.8-0.2,4.2-0.3,7.1c0,2.9,0,6,0.2,9.4l-1.5,1.8c-0.7,0.2-1.4,0.2-2-0.2
									c-0.6-0.4-1.3-1-1.9-2c-2-3-3.4-5.1-4.1-6.4c0,2.4,0,5.7,0.2,9.8l-1.5,1.8C1181.5,760,1180.2,759.3,1178.9,757.4z"
						/>
						<path
							d="M1200.4,753c-0.9-2.5-2-5.1-3.2-7.9c-0.6-1.3-1.1-2.3-1.5-3c-0.4-0.7-0.8-1.2-1.1-1.5c-0.4-0.3-0.8-0.6-1.4-0.9
									c0.2-0.6,0.4-1.1,0.7-1.5s0.6-0.7,1-0.8c1.2-0.5,2.2-0.1,3,1.3c0.3-1,0.7-1.9,1.3-2.6c0.6-0.8,1.3-1.3,2.2-1.7
									c2.6-1.2,4.7-0.1,6.2,3.3l1,2.3c0.3,0.9,0.7,1.8,1,2.5c1.1,2.5,2.2,4.2,3.2,5.2c-0.6,0.4-1.1,0.8-1.7,1c-0.6,0.3-1.3,0.5-2.1,0.7
									c-1.2-3-2.5-6.2-4-9.7c-0.4-1-0.9-1.6-1.5-1.9c-0.5-0.3-1.2-0.2-2.1,0.2c-1.2,0.6-2.1,1.3-2.7,2.2l0.7,1.6
									c0.5,1.3,1.1,2.8,1.9,4.5c1.1,2.6,2.2,4.3,3.1,5.2c-0.6,0.4-1.2,0.8-1.8,1.1C1201.9,752.6,1201.3,752.8,1200.4,753z"
						/>
						<path
							d="M1213.1,746.1c-0.2-0.4-0.4-0.9-0.6-1.3c-0.1-0.4-0.2-1-0.2-1.5c0.7,0.4,1.5,0.5,2.4,0.5c0.9,0,1.7-0.3,2.4-0.7
									c2-1.1,2.7-2.3,2.1-3.5c-0.3-0.5-0.7-0.8-1.4-0.9c-0.7-0.1-1.6-0.1-2.8-0.1c-1.2,0-2.2,0-3,0c-0.8,0-1.6-0.3-2.3-0.7
									c-0.8-0.4-1.4-1.1-1.9-2c-0.6-1.1-0.6-2.2-0.1-3.4c0.5-1.2,1.5-2.1,3-2.9c1.9-1,3.7-1.6,5.4-1.8c0.2,0.4,0.4,0.9,0.5,1.7
									c0.1,0.7,0.2,1.4,0.2,2.1c-1.2-0.4-2.3-0.6-3.3-0.6c-1-0.1-1.7,0-2.2,0.3c-0.5,0.4-0.8,0.8-0.9,1.3c-0.2,0.5-0.1,0.9,0.1,1.3
									c0.4,0.7,0.9,1.1,1.6,1.2c0.7,0.1,1.7,0.2,3.1,0.2c1.1-0.1,2-0.1,2.8,0c0.8,0.1,1.5,0.3,2.2,0.7c0.7,0.4,1.3,1,1.8,1.8
									c0.7,1.3,0.8,2.6,0.2,3.8c-0.6,1.2-1.7,2.3-3.5,3.3C1216.7,745.8,1214.8,746.3,1213.1,746.1z"
						/>
					</g>
					<g className="level_one location" id="east_west_road">
						<rect x="2603.734" y="1491.546" width="169.065" height="32.814" transform="matrix(0.9823, -0.187317, 0.175486, 0.984556, -1704.55896, -311.599609)"></rect>
						<path
							d="M1118.4,680.5c0.1-0.9,0.2-2.1,0.3-3.7c0.2-2.8,0.2-4.7,0-5.8c0.8,0.2,2.1,0.3,3.8,0.5c2.1,0.2,3.7,0.2,4.8,0.2
									c0,0.5-0.1,0.9-0.3,1.5c-0.1,0.5-0.3,0.9-0.4,1.1c-0.4-0.2-0.8-0.5-1.4-0.6c-0.6-0.2-1.2-0.3-1.8-0.3c-0.8-0.1-1.6-0.1-2.3,0
									c-0.2,1.4-0.3,2.7-0.4,3.8l0.6,0.1c0.6,0.1,1,0.1,1.3,0.2c0.7,0.1,1.3,0.1,1.7,0.1c0.4,0,0.8,0,1.3-0.1c0,0.3,0,0.7,0,0.9
									c0,0.2-0.1,0.6-0.2,1c-1.7-0.3-3-0.4-4-0.5c-0.5,0-0.8-0.1-1,0c-0.1,1.8-0.2,3.2-0.2,3.9c0.7,0.2,1.5,0.4,2.4,0.4
									c0.7,0.1,1.4,0,2.1,0c0.7-0.1,1.3-0.2,1.7-0.4c0,0.5-0.1,1-0.3,1.5s-0.3,0.9-0.4,1.1c-1-0.2-2.4-0.4-4.3-0.5
									c-1.7-0.1-2.9-0.2-3.8-0.1C1118.1,682.8,1118.3,681.4,1118.4,680.5z"
						/>
						<path
							d="M1131.2,685c-0.4-0.5-0.5-1-0.5-1.7c0.1-2.1,2-3.1,5.7-3.3c0-1.1-0.1-1.9-0.4-2.4c-0.3-0.5-0.8-0.7-1.6-0.7
									c-0.5,0-1,0-1.5,0.2c-0.5,0.2-1,0.4-1.4,0.6l-0.2-0.5c0.5-0.7,1-1.3,1.8-1.8c0.7-0.4,1.5-0.6,2.2-0.6c1,0,1.8,0.4,2.3,1
									c0.5,0.6,0.8,1.5,0.7,2.6c0,0.6-0.1,1.3-0.2,2.1c-0.1,0.8-0.1,1.4-0.2,1.8c0,0.6,0,1.2,0.1,1.5c0.1,0.4,0.3,0.8,0.7,1.2
									c-0.6,0.5-1.2,0.8-1.9,0.9c-0.4-0.3-0.7-0.8-0.8-1.4c-0.6,0.4-1.2,0.7-1.8,1s-1.2,0.3-1.7,0.3
									C1132.1,685.7,1131.6,685.4,1131.2,685z M1134.9,684.2c0.4-0.2,0.8-0.4,1.2-0.7l0-0.1c0-0.5,0.1-1.1,0.1-1.6l0.1-1.1
									c-1.1,0-1.9,0.2-2.5,0.6c-0.6,0.4-0.9,0.9-0.9,1.6c0,0.3,0,0.6,0.2,0.9s0.3,0.5,0.5,0.7C1134,684.5,1134.4,684.4,1134.9,684.2z"
						/>
						<path
							d="M1142.9,685.1c0-0.3,0-0.6,0.1-0.9c0.1-0.3,0.2-0.6,0.3-0.9c0.3,0.4,0.7,0.8,1.2,1s1,0.4,1.6,0.4c1.5,0,2.2-0.4,2.2-1.3
									c0-0.4-0.2-0.7-0.5-0.9c-0.3-0.3-0.8-0.5-1.5-0.9c-0.7-0.3-1.2-0.6-1.7-0.9c-0.4-0.3-0.8-0.6-1.1-1.1c-0.3-0.5-0.5-1-0.5-1.7
									c0-0.8,0.3-1.4,1-1.9s1.5-0.7,2.5-0.7c1.4,0,2.6,0.2,3.6,0.6c0,0.3-0.1,0.6-0.2,1.1c-0.1,0.5-0.3,0.9-0.5,1.2
									c-0.6-0.6-1.1-1-1.6-1.3c-0.5-0.3-1-0.5-1.3-0.5c-0.4,0.1-0.7,0.2-0.9,0.5c-0.2,0.2-0.4,0.5-0.4,0.8c0,0.5,0.2,0.9,0.5,1.2
									c0.4,0.3,0.9,0.6,1.7,1c0.6,0.3,1.2,0.6,1.6,0.9c0.4,0.3,0.8,0.6,1.1,1.1c0.3,0.4,0.4,1,0.4,1.6c0,1-0.4,1.7-1.1,2.2
									c-0.7,0.5-1.7,0.8-3,0.8C1145,686,1143.8,685.7,1142.9,685.1z"
						/>
						<path
							d="M1155.8,685.5c-0.2-0.3-0.3-0.8-0.4-1.6c0-0.5,0-1.4,0-2.6c0-1.3,0-2.4,0-3.2c0-0.4,0-0.8-0.1-1.2c-0.6,0-1,0-1.3-0.1
									c-0.2-0.1-0.3-0.3-0.3-0.5c1-0.6,1.9-1.5,2.8-2.6l0.6,0.1c0.1,0.6,0.1,1.2,0.2,1.8c1,0,1.8-0.1,2.3-0.2c0,0.4,0,0.7-0.1,0.9
									c-0.1,0.3-0.2,0.6-0.3,0.8c-0.5-0.2-1.1-0.3-2-0.3c0,0.8,0,1.6,0,2.4c0,2.1,0.2,3.7,0.4,4.8c0.7-0.1,1.3-0.2,1.7-0.3l0.2,0.6
									c-0.3,0.5-0.7,0.9-1.2,1.2c-0.5,0.3-1,0.5-1.5,0.5C1156.4,685.9,1156,685.8,1155.8,685.5z"
						/>
						<path
							d="M1163.5,679.4c1.1,0,2.2,0,3.5-0.1c0.7,0,1.3-0.1,2-0.2c0.7-0.1,1.1-0.3,1.4-0.4c0.2,0.8,0.4,1.6,0.4,2.5
									c-1,0-2.2,0-3.5,0.1c-0.7,0-1.4,0.1-2,0.2c-0.7,0.1-1.1,0.3-1.4,0.4C1163.7,681.1,1163.6,680.3,1163.5,679.4z"
						/>
						<path
							d="M1179.1,683.9c-0.4-0.5-0.8-1.1-1.1-1.9c-0.3-0.8-0.8-1.9-1.4-3.3c-0.6-1.6-1.1-2.8-1.6-3.9s-0.9-2-1.5-3
									c0.8-0.5,1.8-0.7,2.8-0.9c0.2,0.7,0.4,1.7,0.9,3c0.4,1.3,0.8,2.5,1.3,3.7c0.7,1.7,1.3,3.1,1.9,4.1c0.8-4.5,1.3-7.7,1.5-9.4
									l-0.8-1.4c0.8-0.5,1.7-0.7,2.8-0.9c0.2,0.7,0.5,1.7,0.9,3c0.4,1.3,0.8,2.5,1.3,3.8c0.8,1.9,1.4,3.2,1.9,4.1c0.6-3.5,1.1-6,1.3-7.6
									c0.2-1.6,0.3-2.7,0.3-3.3c0-0.1,0-0.2-0.1-0.3c0.6-0.3,1.3-0.4,2-0.5c-0.4,1.6-0.8,3.7-1.3,6.4c-0.5,2.7-0.9,5-1.2,6.9l-0.9,1
									c-0.5,0.1-1-0.1-1.4-0.6s-0.8-1.1-1.2-1.9c-0.4-0.8-0.8-1.9-1.3-3.3c-0.4-1.1-0.8-2.2-1.2-3.1c-0.4,2-0.9,4.9-1.6,8.7l-0.9,1
									C1180,684.6,1179.6,684.4,1179.1,683.9z"
						/>
						<path
							d="M1197.4,681c-0.8-0.8-1.4-2-1.7-3.6c-0.2-1.1-0.2-2.1,0.1-3.1c0.2-0.9,0.7-1.7,1.3-2.3c0.6-0.6,1.4-1,2.3-1.2
									c1.2-0.2,2.2,0,3,0.8c0.8,0.8,1.4,2,1.7,3.7c-0.8,0.4-1.7,0.7-2.9,1.1c-1.1,0.4-2.2,0.7-3.4,0.9c0.3,1,0.7,1.7,1.2,2.1
									c0.5,0.4,1.2,0.5,2,0.4c0.6-0.1,1.2-0.3,1.7-0.6c0.5-0.3,1-0.6,1.3-0.9l0.2,0.4c-0.4,0.8-0.9,1.4-1.6,2c-0.7,0.6-1.5,0.9-2.3,1.1
									C1199.3,682.1,1198.3,681.8,1197.4,681z M1200,675.8c0.7-0.3,1.2-0.6,1.6-1c-0.2-0.9-0.5-1.6-1.1-2.1c-0.6-0.5-1.2-0.7-1.9-0.5
									c-0.4,0.4-0.7,1-0.9,1.8c-0.2,0.8-0.1,1.7,0,2.6C1198.5,676.4,1199.3,676.1,1200,675.8z"
						/>
						<path
							d="M1209.2,679.2c-0.1-0.3-0.1-0.6-0.1-0.9c0-0.3,0-0.6,0.1-1c0.4,0.3,0.8,0.6,1.4,0.7c0.5,0.1,1.1,0.1,1.6,0
									c1.4-0.3,2.1-0.9,1.9-1.8c-0.1-0.4-0.3-0.6-0.7-0.8c-0.4-0.2-1-0.3-1.7-0.5c-0.7-0.2-1.4-0.3-1.8-0.5s-0.9-0.4-1.3-0.8
									c-0.4-0.4-0.7-0.9-0.8-1.5c-0.2-0.8,0-1.5,0.5-2.1c0.5-0.6,1.3-1.1,2.3-1.3c1.4-0.3,2.6-0.4,3.6-0.2c0.1,0.3,0.1,0.6,0,1.1
									s-0.1,0.9-0.2,1.3c-0.7-0.4-1.3-0.7-1.9-0.9s-1.1-0.3-1.4-0.2c-0.3,0.2-0.6,0.4-0.8,0.7c-0.2,0.3-0.2,0.5-0.2,0.8
									c0.1,0.5,0.4,0.8,0.8,1s1,0.4,1.9,0.6c0.7,0.2,1.3,0.3,1.7,0.5c0.5,0.2,0.9,0.4,1.3,0.8c0.4,0.4,0.6,0.8,0.8,1.4
									c0.2,0.9,0,1.7-0.5,2.4s-1.5,1.2-2.7,1.5C1211.4,679.6,1210.2,679.6,1209.2,679.2z"
						/>
						<path
							d="M1222.1,676.4c-0.3-0.2-0.5-0.7-0.7-1.4c-0.1-0.5-0.4-1.3-0.6-2.5c-0.3-1.3-0.6-2.3-0.8-3.1c-0.1-0.4-0.2-0.8-0.4-1.1
									c-0.6,0.2-1,0.2-1.2,0.2c-0.2,0-0.4-0.2-0.4-0.4c0.8-0.9,1.5-2,2.1-3.2l0.6-0.1c0.2,0.5,0.4,1.1,0.6,1.7c1-0.3,1.7-0.6,2.2-0.8
									c0.1,0.3,0.1,0.7,0.2,0.9c0,0.3,0,0.6-0.1,0.9c-0.5-0.1-1.1,0-2,0.2c0.2,0.8,0.3,1.5,0.6,2.3c0.6,2,1.1,3.5,1.6,4.5
									c0.7-0.2,1.2-0.5,1.6-0.7l0.3,0.5c-0.1,0.5-0.4,1-0.8,1.4c-0.4,0.4-0.9,0.7-1.3,0.8C1222.8,676.7,1222.4,676.7,1222.1,676.4z"
						/>
						<path
							d="M1237.3,672.2c-0.2-0.8-0.5-2-0.9-3.5c-0.4-1.5-0.8-2.8-1.1-4c-0.8-2.7-1.5-4.3-1.9-4.8c0.4-0.3,1-0.7,1.7-1
									c0.7-0.4,1.5-0.6,2.2-0.9c1.5-0.5,2.8-0.4,3.8,0.1c1,0.5,1.8,1.4,2.2,2.8c0.3,0.8,0.3,1.6,0.1,2.4c-0.2,0.8-0.6,1.4-1.2,2
									c1.9,2.1,3.3,3.4,4.2,3.7c-0.3,0.3-0.7,0.6-1.1,0.8c-0.4,0.2-0.9,0.4-1.4,0.5c-0.6-0.8-1.8-2.1-3.5-3.9c-0.1,0-0.2,0.1-0.4,0.1
									c-0.3,0.1-0.6,0.2-1,0.2c-0.4,0.1-0.6,0.1-0.9,0.1c0.7,2.3,1.3,3.8,1.8,4.5c-0.4,0.2-0.8,0.4-1.2,0.5
									C1238.2,672.1,1237.8,672.2,1237.3,672.2z M1241.2,663.6c0.2-0.6,0.2-1.2,0-1.9c-0.6-1.9-1.9-2.6-3.9-2c-0.4,0.1-0.8,0.3-1.2,0.5
									c0.3,1.2,0.7,2.9,1.4,5c1.2,0.1,2,0,2.6-0.1C1240.7,664.8,1241,664.2,1241.2,663.6z"
						/>
						<path
							d="M1250.4,667c-1-0.7-1.8-1.7-2.3-3.2c-0.4-1.1-0.5-2.1-0.3-3.1c0.1-1,0.5-1.8,1.1-2.5c0.6-0.7,1.3-1.2,2.3-1.5
									c0.8-0.3,1.6-0.3,2.4-0.1c0.8,0.2,1.5,0.6,2.1,1.2c0.6,0.6,1.1,1.4,1.4,2.4c0.4,1.1,0.5,2.1,0.3,3.1c-0.1,1-0.5,1.8-1.1,2.5
									c-0.6,0.7-1.4,1.2-2.4,1.5C1252.6,667.7,1251.5,667.6,1250.4,667z M1255.5,664.6c0.3-0.8,0.2-1.7-0.2-2.9c-0.3-1-0.8-1.8-1.5-2.4
									c-0.7-0.6-1.4-0.9-2.3-0.9c-0.9,0.3-1.4,0.8-1.7,1.6c-0.3,0.8-0.3,1.8,0.1,2.8c0.4,1.3,1,2.2,1.7,2.8c0.7,0.6,1.5,0.7,2.2,0.5
									C1254.7,665.9,1255.3,665.4,1255.5,664.6z"
						/>
						<path
							d="M1262.6,663.6c-0.5-0.3-0.9-0.8-1.1-1.4c-0.7-1.9,0.6-3.7,4-5.1c-0.4-1.1-0.8-1.7-1.2-2.1c-0.4-0.3-1-0.3-1.8-0.1
									c-0.5,0.2-0.9,0.4-1.3,0.7c-0.4,0.3-0.8,0.7-1,1.1l-0.3-0.4c0.1-0.8,0.5-1.6,1-2.3c0.5-0.7,1.1-1.2,1.8-1.4c1-0.3,1.8-0.3,2.5,0
									c0.7,0.4,1.3,1.1,1.6,2.1c0.2,0.6,0.4,1.2,0.6,2c0.2,0.8,0.4,1.4,0.5,1.7c0.2,0.6,0.4,1.1,0.7,1.4c0.2,0.3,0.6,0.6,1.1,0.9
									c-0.4,0.7-0.8,1.2-1.5,1.6c-0.5-0.1-0.9-0.5-1.3-1c-0.4,0.6-0.8,1.1-1.3,1.6c-0.5,0.4-1,0.8-1.5,0.9
									C1263.7,664,1263.1,663.9,1262.6,663.6z M1265.7,661.5c0.3-0.3,0.6-0.7,0.9-1.1l0-0.1c-0.2-0.5-0.3-1-0.5-1.5l-0.3-1.1
									c-1,0.4-1.7,0.9-2.1,1.5c-0.4,0.6-0.5,1.2-0.2,1.9c0.1,0.3,0.3,0.5,0.5,0.7c0.2,0.2,0.5,0.4,0.8,0.4
									C1265,662.1,1265.4,661.9,1265.7,661.5z"
						/>
						<path
							d="M1274.1,658.7c-0.9-0.6-1.7-1.8-2.4-3.4c-0.7-1.7-0.9-3.1-0.6-4.2c0.3-1.1,1-1.9,2.3-2.4c1-0.4,2-0.4,3-0.1
									c-0.7-1.6-1.3-2.7-1.8-3.4c0.4-0.3,0.7-0.6,1-0.8c0.3-0.2,0.7-0.4,1.2-0.6c0.6,2.1,1.8,5.4,3.6,10.1c0.2,0.6,0.4,1,0.7,1.3
									c0.2,0.3,0.6,0.5,1.1,0.8c-0.3,0.7-0.8,1.2-1.4,1.5c-0.5-0.2-1-0.5-1.3-1c-0.2,0.6-0.5,1.1-0.9,1.5c-0.4,0.4-0.8,0.7-1.3,0.9
									C1276.1,659.4,1275,659.3,1274.1,658.7z M1278.3,656.8c0.3-0.3,0.6-0.7,0.8-1.1c-0.2-0.5-0.5-1.3-0.9-2.5
									c-0.5-1.3-0.9-2.3-1.2-3.1c-0.7-0.2-1.5-0.1-2.2,0.2c-0.8,0.3-1.2,0.8-1.4,1.6c-0.2,0.7-0.1,1.7,0.4,2.8c0.4,1.1,1,1.9,1.6,2.4
									c0.6,0.5,1.3,0.7,2,0.5C1277.7,657.4,1278,657.1,1278.3,656.8z"
						/>
					</g>
					<g className="level_one location" id="cardolan">
						<rect x="1127.951" y="1702.334" width="73.24" height="37.451" transform="matrix(0.734318, -0.678805, 0.913756, 0.517129, -1371.619995, 779.46698)"></rect>
						<rect x="1237.207" y="1702.335" width="80.334" height="37.451" transform="matrix(0.807871, -0.589359, 0.650435, 0.763315, -1022.652771, 252.079926)"></rect>
						<rect x="1237.207" y="1702.335" width="80.334" height="37.451" transform="matrix(0.911486, -0.411331, 0.48024, 0.880389, -798.155579, -209.447052)"></rect>
						<rect x="1746.383" y="1702.337" width="30" height="37.451" transform="matrix(0.750954, -0.660354, 0.717128, 0.70103, -1329.639648, 718.79718)"></rect>
						<text fontFamily="Arbutus Slab" fontSize="29px" fontWeight="500" x="1032.229" y="879.13" transform="matrix(0.797864, -0.602838, 0.602838, 0.797864, -309.372955, 811.019165)">C</text>
						<text fontFamily="Arbutus Slab" fontSize="29px" fontWeight="500" x="1025.69" y="876.265" transform="matrix(0.817767, -0.57555, 0.57555, 0.817767, -279.011292, 747.733276)">A</text>
						<text fontFamily="Arbutus Slab" fontSize="29px" fontWeight="500" x="1079.868" y="811.439" transform="matrix(0.761275, -0.648429, 0.648429, 0.761275, -245.187836, 925.86908)">R</text>
						<text fontFamily="Arbutus Slab" fontSize="29px" fontWeight="500" x="1103.371" y="798.411" transform="matrix(0.759319, -0.650719, 0.650719, 0.759319, -235.33963, 939.426697)">D</text>
						<text fontFamily="Arbutus Slab" fontSize="29px" fontWeight="500" x="1133.669" y="808.548" transform="matrix(0.901322, -0.433149, 0.433149, 0.901322, -232.988113, 575.176392)">O</text>
						<text fontFamily="Arbutus Slab" fontSize="29px" fontWeight="500" x="1159.409" y="795.586" transform="matrix(0.922742, -0.385418, 0.385418, 0.922742, -213.951447, 513.678101)">L</text>
						<text fontFamily="Arbutus Slab" fontSize="29px" fontWeight="500" x="1178.397" y="788.829" transform="matrix(0.935693, -0.352816, 0.352816, 0.935693, -198.233017, 469.795135)">A</text>
						<text fontFamily="Arbutus Slab" fontSize="29px" fontWeight="500" x="1206.282" y="775.985" transform="matrix(0.949321, -0.314309, 0.314309, 0.949321, -182.926178, 425.81134)">N</text>
					</g>
					<g className="level_two fade location" id="tharbad">
						<rect x="1849.739" y="1525.948" width="120.107" height="33.567" transform="matrix(0.999923, -0.012426, 0.000381, 1.000072, -816.070923, -558.350281)"></rect>
						<path
							d="M1044.2,970.7c0.1-1.2,0.2-3,0.4-5.2c0.1-2.2,0.2-4.4,0.2-6.5c0.1-2.3,0-4.2,0-5.8c-2.8,0-4.7,0.1-5.5,0.4
									c-0.1-0.6-0.2-1.1-0.1-1.6c0-0.4,0.1-1,0.2-1.8c0.9,0.1,2.1,0.2,3.7,0.3c1.6,0.1,3.1,0.2,4.5,0.2c3.3,0.1,5.4,0,6.2-0.4
									c0.1,0.6,0.1,1.2,0.1,1.7c0,0.4-0.1,1-0.2,1.8c-1.4-0.2-3.2-0.3-5.5-0.4c-0.2,3.3-0.3,6.2-0.4,8.6c-0.1,4.7,0,7.7,0.4,8.8
									c-0.7,0.1-1.3,0.2-1.9,0.2C1045.6,971,1044.9,970.9,1044.2,970.7z"
						/>
						<path
							d="M1056.7,970.9c0.2-3.3,0.3-6.6,0.3-9.9c0-4.5-0.1-8-0.3-10.6c1.1-0.5,2.4-0.8,3.9-0.8c-0.1,1.2-0.3,3.7-0.5,7.5
									c0.7-0.8,1.4-1.4,2.2-1.8c0.8-0.4,1.7-0.7,2.6-0.7c2.7,0,4.1,1.8,4.1,5.4l-0.1,2.4l-0.1,2.6c0,2.7,0.2,4.7,0.7,5.8
									c-0.7,0.2-1.3,0.2-1.9,0.2c-0.6,0-1.2-0.1-2.1-0.2c0.2-3.7,0.3-7.1,0.3-10.2c0-1-0.2-1.8-0.6-2.2c-0.4-0.5-1-0.7-1.9-0.7
									c-1.2,0-2.3,0.3-3.3,1c-0.1,0.8-0.1,1.8-0.1,3.2c0,4.8,0.2,7.8,0.7,8.9c-0.6,0.2-1.3,0.2-1.9,0.2
									C1058.2,971.2,1057.5,971.1,1056.7,970.9z"
						/>
						<path
							d="M1074.6,970.4c-0.6-0.7-0.9-1.6-0.9-2.6c0-3.1,2.7-4.9,8.2-5.4c0-1.7-0.3-2.9-0.7-3.6c-0.5-0.7-1.3-1-2.5-1
									c-0.7,0-1.5,0.1-2.2,0.4c-0.7,0.3-1.4,0.6-2,1.1l-0.3-0.7c0.6-1.1,1.5-2.1,2.5-2.8c1.1-0.7,2.2-1.1,3.3-1.1c1.5,0,2.7,0.4,3.5,1.3
									s1.3,2.2,1.3,3.9c0,0.9,0,2-0.1,3.1c-0.1,1.2-0.1,2.2-0.1,2.8c0,1,0.1,1.7,0.3,2.3c0.2,0.5,0.6,1.1,1.1,1.8
									c-0.9,0.8-1.8,1.3-2.8,1.5c-0.6-0.5-1.1-1.2-1.4-2.1c-0.8,0.7-1.7,1.2-2.6,1.6c-0.9,0.4-1.7,0.6-2.5,0.6
									C1075.9,971.4,1075.2,971,1074.6,970.4z M1080,968.9c0.6-0.3,1.2-0.7,1.7-1.1l0-0.2c0-0.8,0-1.6,0.1-2.4l0.1-1.7
									c-1.6,0.1-2.8,0.5-3.7,1.1c-0.9,0.6-1.3,1.5-1.3,2.6c0,0.4,0.1,0.9,0.3,1.3c0.2,0.4,0.5,0.8,0.9,1
									C1078.7,969.4,1079.3,969.2,1080,968.9z"
						/>
						<path
							d="M1091.5,970.7c0.1-2.6,0.1-5.4,0-8.3c0-1.4-0.1-2.5-0.2-3.3c-0.1-0.7-0.2-1.3-0.4-1.8c-0.2-0.4-0.5-0.9-0.9-1.3
									c0.4-0.5,0.8-0.8,1.2-1.1c0.4-0.2,0.8-0.4,1.2-0.4c1.3,0,2.1,0.9,2.2,2.8c0.6-0.8,1.2-1.5,1.9-2.1c0.7-0.5,1.5-1,2.3-1.2
									c0.2,0.4,0.3,1,0.5,1.8c0.1,0.8,0.2,1.5,0.2,2.1c-0.3,0-0.7-0.1-1.2,0c-0.7,0-1.5,0.1-2.1,0.3c-0.7,0.2-1.2,0.5-1.6,0.8l0,1.2
									c0,1.7,0,3.2,0,4.5c0.1,2.7,0.4,4.7,0.8,5.8c-0.7,0.2-1.3,0.3-1.9,0.3C1092.9,970.9,1092.3,970.8,1091.5,970.7z"
						/>
						<path
							d="M1106.6,970.1c-0.8-0.3-1.4-0.8-1.9-1.4l-0.8,1.7c-0.2,0-0.6,0-1.1-0.1c0-1.2,0-3.2,0-5.8c-0.1-2.7-0.1-5-0.2-6.9
									c-0.1-3.6-0.4-6.1-0.9-7.5c1.1-0.6,2.4-0.9,3.9-0.9c-0.1,1.6-0.1,4-0.1,7.1c1.3-1.2,2.8-1.9,4.3-2c1.7-0.1,3.1,0.6,4.2,1.9
									c1.1,1.3,1.7,3.2,1.8,5.5c0.1,2.8-0.4,4.9-1.5,6.4c-1.1,1.5-2.8,2.3-5,2.4C1108.2,970.5,1107.4,970.4,1106.6,970.1z M1111.6,967.1
									c0.6-0.9,0.9-2.2,0.9-4c-0.1-3.4-1.3-5.6-3.6-6.3c-1.2,0-2.4,0.4-3.5,1.1c0.1,2.5,0.1,4.2,0.1,5c0.1,2.1,0.2,3.6,0.4,4.5
									c0.9,0.8,1.8,1.2,2.7,1.2C1110,968.5,1111,968,1111.6,967.1z"
						/>
						<path
							d="M1120.3,969c-0.6-0.7-0.9-1.5-1-2.6c-0.2-3.1,2.5-5,7.9-5.8c-0.1-1.7-0.4-2.9-0.9-3.5c-0.5-0.6-1.3-0.9-2.5-0.9
									c-0.7,0-1.4,0.2-2.2,0.5s-1.4,0.7-1.9,1.2l-0.3-0.7c0.6-1.2,1.4-2.1,2.4-2.9c1-0.8,2.1-1.2,3.2-1.3c1.5-0.1,2.7,0.3,3.6,1.1
									c0.9,0.8,1.3,2.1,1.4,3.8c0.1,0.9,0.1,2,0,3.2c0,1.2,0,2.2,0,2.8c0.1,1,0.2,1.7,0.4,2.3c0.2,0.5,0.6,1.1,1.2,1.7
									c-0.8,0.9-1.7,1.4-2.7,1.6c-0.7-0.4-1.1-1.1-1.5-2.1c-0.8,0.7-1.6,1.3-2.5,1.7c-0.9,0.4-1.7,0.7-2.5,0.7
									C1121.7,970,1120.9,969.7,1120.3,969z M1125.6,967.3c0.6-0.3,1.2-0.8,1.7-1.2l0-0.2c0-0.8-0.1-1.6,0-2.4l0-1.7
									c-1.6,0.2-2.8,0.6-3.6,1.3c-0.8,0.7-1.2,1.6-1.1,2.6c0,0.4,0.2,0.9,0.4,1.3c0.2,0.4,0.5,0.7,0.9,0.9
									C1124.4,967.9,1125,967.7,1125.6,967.3z"
						/>
						<path
							d="M1136.8,967c-1.1-1.3-1.7-3.3-1.9-6c-0.2-2.8,0.1-4.9,1-6.4c0.9-1.5,2.3-2.3,4.3-2.5c1.5-0.1,3,0.3,4.3,1.2
									c-0.3-2.6-0.7-4.5-1.1-5.7c0.7-0.3,1.3-0.6,1.9-0.7c0.6-0.2,1.2-0.3,2-0.3c0,3.2,0.2,8.7,0.8,16.4c0.1,0.9,0.2,1.6,0.4,2.1
									c0.2,0.5,0.6,1.1,1.2,1.6c-0.7,0.8-1.6,1.4-2.7,1.6c-0.7-0.5-1.2-1.2-1.5-2.1c-0.6,0.7-1.2,1.3-2,1.8c-0.8,0.4-1.5,0.7-2.3,0.8
									C1139.4,968.9,1137.9,968.3,1136.8,967z M1143.6,966.1c0.6-0.4,1.1-0.8,1.6-1.3c-0.1-0.8-0.1-2.2-0.2-4.1c-0.1-2.1-0.2-3.8-0.3-5
									c-1-0.6-2.1-0.8-3.3-0.7c-1.2,0.1-2.1,0.6-2.7,1.6c-0.6,1-0.8,2.4-0.7,4.2c0.1,1.8,0.5,3.2,1.2,4.2c0.7,1,1.6,1.6,2.7,1.7
									C1142.5,966.7,1143.1,966.5,1143.6,966.1z"
						/>
					</g>
					<g className="level_two fade location " id="mitheithel">
						<rect x="1202.135" y="1434.203" width="78.057" height="31.552" transform="matrix(0.799034, -0.601286, 0.591661, 0.806277, -560.22522, 400.063812)"></rect>
						<rect x="1339.308" y="1434.203" width="86.964" height="31.552" transform="matrix(0.301556, -0.953448, 0.949816, 0.313041, -463.799713, 1631.163574)"></rect>
						<rect x="1920.574" y="1144.004" width="124.707" height="25.168" transform="matrix(0.753627, -0.657302, 0.648225, 0.761544, -828.072266, 944.625244)"></rect>
						<path
							d="M1268.3,852c-1.4-2.4-3.6-5.6-6.5-9.8c-1.5-2.2-2.7-3.8-3.5-4.8s-1.4-1.7-1.9-2.1c0.4-0.5,0.9-0.9,1.4-1.3
									c0.4-0.3,1-0.6,1.8-1c1.6,1.1,3.9,2.6,6.8,4.5c2.9,1.9,5.3,3.3,7.1,4.4c-0.4-2.1-1-4.8-1.7-8s-1.4-5.9-1.9-8.1
									c0.3-0.4,0.8-0.8,1.5-1.3c0.5-0.3,1.1-0.6,1.7-0.9c0.7,1.1,1.6,2.6,2.8,4.4c1.2,1.9,2.4,3.6,3.6,5.3c2.8,4,4.6,6.3,5.5,6.9
									c-0.4,0.5-0.9,0.9-1.4,1.3c-0.5,0.3-1.1,0.7-1.8,1c-0.6-1.1-1.6-2.6-2.8-4.5c-1.2-1.9-2.4-3.6-3.6-5.3c-0.7-1.1-1.6-2.3-2.6-3.6
									c0.8,4.3,2.1,10,3.8,17c-0.2,0.3-0.3,0.5-0.5,0.6c-0.2,0.1-0.4,0.2-0.7,0.2c-1.5-1-3.6-2.4-6.3-4.1c-2.7-1.7-5.2-3.3-7.5-4.6
									c1.5,2.2,2.7,3.9,3.5,5.2c2.7,3.8,4.5,6.1,5.4,7c-0.3,0.4-0.6,0.7-0.9,0.9C1269.3,851.6,1268.9,851.8,1268.3,852z"
						/>
						<path
							d="M1276.5,820.3c0-1.2,0.1-2.3,0.3-3.2c1,0.3,2.1,0.5,3.2,0.6c0,1.1-0.1,2.2-0.3,3.2
									C1278.6,820.7,1277.5,820.4,1276.5,820.3z M1289.7,836.3c-0.5-0.2-1.1-0.8-1.8-1.8c-0.6-0.8-1.4-1.9-2.4-3.3
									c-0.9-1.4-1.7-2.4-2.1-3c-0.6-0.9-1.3-1.6-1.9-2.3c-0.6-0.6-1.1-1.1-1.6-1.2c0.4-0.6,0.7-1.1,1.1-1.6c0.4-0.4,0.9-0.9,1.5-1.3
									c0.4,0.7,1,1.7,1.8,3c0.9,1.3,1.9,2.7,3,4.3c0.8,1.1,1.5,2,2,2.6c0.5,0.6,1,1,1.4,1.2c0.4,0.3,0.9,0.4,1.5,0.6
									c0,0.6-0.1,1.1-0.4,1.6c-0.2,0.5-0.5,0.8-0.8,1C1290.7,836.4,1290.2,836.5,1289.7,836.3z"
						/>
						<path
							d="M1297.8,830.3c-0.5-0.2-1.1-0.7-1.8-1.6c-0.5-0.6-1.3-1.7-2.3-3.2c-1.2-1.6-2.1-2.9-2.9-3.9c-0.4-0.5-0.8-1-1.1-1.4
									c-0.7,0.6-1.3,0.9-1.6,1c-0.3,0.1-0.6,0-0.8-0.3c0.6-1.7,1-3.6,1.1-5.7l0.7-0.4c0.6,0.6,1.2,1.3,1.8,2.1c1.2-1,2.1-1.7,2.5-2.3
									c0.3,0.4,0.6,0.8,0.8,1.2c0.2,0.4,0.3,0.8,0.4,1.3c-0.7,0.2-1.6,0.6-2.6,1.4c0.6,1,1.4,2,2.1,3c1.9,2.5,3.5,4.4,4.8,5.5
									c0.8-0.7,1.4-1.3,1.8-1.9l0.7,0.6c0.1,0.8,0,1.6-0.3,2.5c-0.3,0.8-0.8,1.4-1.4,1.9C1298.9,830.3,1298.3,830.5,1297.8,830.3z"
						/>
						<path
							d="M1306.2,824.3c-2-2.7-4-5.3-6-7.9c-2.8-3.5-5.1-6.2-6.9-8c0.6-1.1,1.4-2.1,2.5-3c0.7,1,2.1,3.1,4.4,6.2c0-1,0.2-2,0.6-2.8
									c0.4-0.9,0.9-1.6,1.6-2.1c2.1-1.7,4.3-1.2,6.6,1.6l1.5,1.9l1.6,2.1c1.7,2.1,3.1,3.5,4.2,4.1c-0.4,0.5-0.8,1-1.3,1.3
									c-0.4,0.4-1,0.7-1.8,1.1c-2.2-3-4.3-5.7-6.2-8.1c-0.7-0.8-1.3-1.3-1.9-1.4c-0.6-0.1-1.2,0.1-1.9,0.6c-1,0.8-1.6,1.7-1.9,2.9
									c0.4,0.6,1.1,1.5,1.9,2.5c3,3.8,5.1,5.9,6.2,6.5c-0.4,0.5-0.8,1-1.3,1.4C1307.5,823.6,1306.9,824,1306.2,824.3z"
						/>
						<path
							d="M1321.8,809.3c-1.7-0.1-3.5-1-5.4-2.6c-1.3-1.1-2.3-2.3-2.9-3.7c-0.6-1.3-0.9-2.6-0.7-4c0.1-1.3,0.7-2.5,1.6-3.6
									c1.2-1.4,2.6-2.1,4.3-1.9s3.5,1.1,5.5,2.8c-0.5,1.2-1.3,2.5-2.2,4c-0.9,1.5-2,2.9-3,4.2c1.3,0.9,2.4,1.4,3.4,1.3
									c1,0,1.8-0.5,2.6-1.5c0.6-0.7,1.1-1.5,1.5-2.3c0.4-0.8,0.6-1.6,0.6-2.3l0.7,0.3c0.3,1.2,0.3,2.5,0,3.8c-0.3,1.3-0.9,2.5-1.7,3.4
									C1324.9,808.8,1323.4,809.5,1321.8,809.3z M1319.6,800.8c0.5-1,0.8-1.9,1-2.7c-1.1-0.9-2.1-1.3-3.3-1.4c-1.1,0-2,0.3-2.7,1.2
									c-0.1,0.9,0.1,1.8,0.7,2.9c0.6,1.1,1.4,2.1,2.6,3C1318.5,802.9,1319.1,801.9,1319.6,800.8z"
						/>
						<path
							d="M1315.3,785.2c-0.6-1-1.1-2-1.4-2.9c1.1-0.3,2.1-0.6,3.1-1.1c0.6,0.9,1,1.9,1.4,2.9
									C1317.3,784.4,1316.3,784.7,1315.3,785.2z M1334.9,792.1c-0.5,0.1-1.4-0.1-2.5-0.6c-0.9-0.4-2.1-0.9-3.7-1.6
									c-1.5-0.7-2.6-1.2-3.3-1.5c-1-0.4-1.9-0.7-2.8-1c-0.9-0.2-1.5-0.3-2-0.3c0-0.7,0-1.4,0.2-2c0.1-0.6,0.3-1.2,0.6-1.9
									c0.6,0.4,1.7,0.9,3.1,1.6c1.4,0.7,3,1.4,4.8,2.1c1.3,0.5,2.3,0.9,3.1,1.1c0.7,0.2,1.3,0.3,1.8,0.3c0.5,0,1-0.1,1.6-0.3
									c0.3,0.5,0.5,1,0.5,1.5c0,0.5,0,0.9-0.1,1.2C1335.8,791.7,1335.4,792,1334.9,792.1z"
						/>
						<path
							d="M1338.8,780.5c-0.5,0.2-1.3,0.1-2.4-0.2c-0.8-0.2-2-0.6-3.8-1.1c-1.9-0.6-3.5-1-4.7-1.4c-0.6-0.2-1.2-0.3-1.7-0.4
									c-0.2,0.9-0.5,1.5-0.7,1.7c-0.2,0.3-0.5,0.4-0.9,0.3c-0.5-1.7-1.4-3.4-2.6-5.2l0.3-0.8c0.9,0.1,1.8,0.3,2.7,0.6
									c0.4-1.5,0.6-2.6,0.6-3.4c0.5,0.1,1,0.3,1.3,0.5c0.4,0.2,0.7,0.5,1.1,0.8c-0.5,0.6-0.9,1.5-1.2,2.7c1.1,0.4,2.3,0.7,3.5,1.1
									c3.1,0.8,5.5,1.3,7.1,1.4c0.2-1.1,0.3-1.9,0.3-2.6l0.9,0c0.6,0.6,1,1.3,1.2,2.2c0.2,0.8,0.2,1.6,0,2.3
									C1339.6,779.9,1339.3,780.4,1338.8,780.5z"
						/>
						<path
							d="M1341.8,770.1c-3.2-0.9-6.4-1.6-9.6-2.3c-4.4-1-7.9-1.6-10.4-1.9c-0.3-1.2-0.2-2.5,0.1-3.9c1.2,0.4,3.6,1.1,7.3,2.1
									c-0.6-0.8-1.1-1.7-1.3-2.6c-0.3-0.9-0.3-1.8-0.1-2.6c0.6-2.7,2.6-3.6,6.1-2.9l2.4,0.6l2.5,0.6c2.7,0.6,4.6,0.8,5.8,0.6
									c0,0.7,0,1.3-0.2,1.9c-0.1,0.5-0.3,1.2-0.7,2c-3.6-0.9-6.9-1.7-9.9-2.4c-1-0.2-1.8-0.2-2.3,0.1c-0.5,0.3-0.9,0.8-1.1,1.7
									c-0.3,1.2-0.2,2.4,0.3,3.4c0.7,0.2,1.8,0.5,3.1,0.7c4.7,1,7.7,1.4,8.9,1.2c0,0.6,0,1.3-0.2,1.9
									C1342.3,768.8,1342.1,769.4,1341.8,770.1z"
						/>
						<path
							d="M1344.1,750.8c-1.5,0.7-3.5,0.9-6,0.5c-1.7-0.3-3.1-0.9-4.3-1.7c-1.2-0.8-2.1-1.8-2.6-3s-0.7-2.5-0.5-3.9
									c0.3-1.8,1.2-3.1,2.7-3.8c1.5-0.7,3.6-0.8,6.2-0.4c0.1,1.3,0.1,2.8,0.1,4.6c-0.1,1.8-0.2,3.5-0.5,5.2c1.6,0.1,2.8,0,3.6-0.6
									c0.8-0.5,1.3-1.4,1.5-2.6c0.2-0.9,0.2-1.9,0.1-2.7c-0.1-0.9-0.3-1.7-0.6-2.3l0.8-0.1c0.9,0.9,1.5,2,2,3.3c0.4,1.3,0.5,2.6,0.3,3.8
									C1346.6,748.8,1345.6,750.1,1344.1,750.8z M1338,744.6c-0.1-1.2-0.3-2.1-0.5-2.8c-1.4-0.2-2.5-0.1-3.5,0.5c-1,0.5-1.6,1.3-1.8,2.4
									c0.3,0.8,1,1.5,2.1,2.1c1,0.6,2.3,1.1,3.8,1.3C1338.1,746.9,1338.1,745.7,1338,744.6z"
						/>
						<path
							d="M1348.3,733.8c-0.5,0.2-1.3,0.2-2.5,0c-0.8-0.1-2.1-0.3-3.8-0.6c-1.9-0.3-3.5-0.6-4.8-0.8c-4.1-0.6-7.1-0.8-8.9-0.7
									c-0.2-0.7-0.3-1.3-0.3-1.9c0-0.6,0-1.3,0.1-2c3.1,0.7,7.7,1.6,13.6,2.4c1.4,0.2,2.5,0.3,3.2,0.4c0.8,0,1.4,0,1.8-0.1
									c0.5-0.1,1-0.3,1.5-0.7c0.4,0.4,0.7,0.9,0.9,1.4c0.2,0.5,0.2,0.9,0.2,1.2C1349.1,733.1,1348.8,733.6,1348.3,733.8z"
						/>
						<path
							d="M1368.4,556.5l0.4,0c0.4,1.3,1,2.4,1.9,3.5c0.9,1.1,2,2,3.3,2.8s2.7,1.4,4,1.7c1.4,0.3,2.7,0.3,4,0.1l0.1,0.4
									c-1.5,0.6-3.1,0.9-4.7,0.8c-1.6-0.1-3-0.6-4.4-1.4c-1.3-0.8-2.4-1.9-3.2-3.3C1369,559.7,1368.6,558.2,1368.4,556.5z"
						/>
						<path
							d="M1384.8,559.4c-0.6-0.5-1.4-1.2-2.5-2c-1.1-0.8-2.1-1.6-3.1-2.3c-2.3-1.7-3.8-2.6-4.5-2.8c0.2-0.4,0.3-0.7,0.6-1
									c0.2-0.3,0.5-0.6,0.8-0.9c1,0.9,2.4,2,4.2,3.3c0.5-0.6,1.1-1.3,1.8-2.3c0.1-0.1,0.4-0.6,1-1.4c-2.3-1.7-3.9-2.7-4.6-2.9
									c0.1-0.4,0.3-0.7,0.6-1c0.2-0.3,0.5-0.6,0.8-0.9c0.6,0.5,1.4,1.2,2.5,2c1.1,0.8,2.1,1.6,3.1,2.3c2.3,1.7,3.8,2.6,4.4,2.8
									c-0.1,0.4-0.3,0.7-0.6,1c-0.2,0.3-0.5,0.6-0.8,0.9c-1-0.9-2.4-2-4.2-3.4c-0.5,0.7-1.1,1.4-1.7,2.1c-0.4,0.5-0.7,1-1.1,1.6
									c2.3,1.7,3.9,2.7,4.6,2.9c-0.1,0.4-0.3,0.7-0.6,1C1385.4,558.8,1385.1,559.1,1384.8,559.4z"
						/>
						<path
							d="M1393.7,546.1c-1.1,0-2.2-0.5-3.3-1.5c-0.8-0.7-1.3-1.4-1.7-2.2c-0.3-0.8-0.5-1.6-0.3-2.4c0.1-0.8,0.5-1.6,1.1-2.2
									c0.5-0.6,1.1-1,1.8-1.2s1.4-0.2,2.2,0.1s1.5,0.6,2.2,1.3c0.8,0.7,1.3,1.4,1.7,2.3c0.3,0.8,0.4,1.7,0.3,2.5s-0.5,1.6-1.1,2.3
									C1395.7,545.7,1394.8,546.1,1393.7,546.1z M1396.6,541.9c-0.2-0.7-0.6-1.5-1.5-2.2c-0.7-0.6-1.5-1-2.3-1.2
									c-0.8-0.2-1.5-0.1-2.2,0.3c-0.5,0.6-0.7,1.3-0.6,2.1c0.1,0.8,0.6,1.5,1.4,2.2c0.9,0.8,1.8,1.3,2.6,1.4c0.8,0.1,1.5-0.1,1.9-0.6
									C1396.5,543.3,1396.7,542.7,1396.6,541.9z"
						/>
						<path
							d="M1403.4,536.5c-0.5-0.1-1-0.3-1.5-0.8c-1.3-1.4-0.8-3.3,1.4-5.7c-0.7-0.7-1.3-1.2-1.8-1.3c-0.5-0.1-1,0.1-1.5,0.6
									c-0.3,0.3-0.6,0.7-0.8,1.1c-0.2,0.4-0.4,0.8-0.4,1.3l-0.4-0.2c-0.2-0.8-0.2-1.5,0-2.3s0.5-1.4,1-1.8c0.7-0.6,1.3-0.9,2.1-0.9
									c0.7,0,1.4,0.4,2.1,1.2c0.4,0.4,0.8,0.9,1.2,1.4c0.5,0.6,0.8,1,1.1,1.3c0.4,0.4,0.8,0.7,1.1,0.9s0.7,0.3,1.2,0.3
									c0,0.7-0.2,1.3-0.6,1.8c-0.5,0.1-1-0.1-1.5-0.4c-0.1,0.6-0.3,1.2-0.5,1.8c-0.2,0.5-0.5,1-0.9,1.3
									C1404.4,536.4,1404,536.6,1403.4,536.5z M1405.2,533.7c0.2-0.4,0.3-0.8,0.3-1.2l-0.1-0.1c-0.3-0.4-0.7-0.7-1-1.1l-0.6-0.8
									c-0.6,0.7-1,1.4-1.1,2c-0.1,0.6,0.1,1.2,0.5,1.6c0.2,0.2,0.4,0.3,0.7,0.4c0.3,0.1,0.5,0.1,0.8,0.1
									C1404.9,534.4,1405,534.1,1405.2,533.7z"
						/>
						<path
							d="M1413.8,527.6c-0.9-1.3-1.9-2.6-3.1-3.9c-0.5-0.7-1-1.1-1.3-1.5s-0.6-0.5-0.9-0.7c-0.2-0.1-0.6-0.2-0.9-0.3
									c0-0.4,0-0.7,0.2-1s0.3-0.5,0.4-0.6c0.6-0.5,1.3-0.3,2.1,0.5c0-0.6,0-1.2,0.1-1.7c0.1-0.5,0.3-1,0.6-1.5c0.2,0.1,0.5,0.3,0.9,0.7
									c0.4,0.3,0.7,0.6,0.9,0.9c-0.1,0.1-0.3,0.2-0.6,0.4c-0.3,0.3-0.6,0.6-0.9,1s-0.4,0.7-0.5,1l0.4,0.6c0.6,0.8,1.2,1.5,1.7,2.1
									c1,1.3,1.9,2.1,2.6,2.4c-0.3,0.3-0.5,0.6-0.8,0.8C1414.5,527.2,1414.2,527.4,1413.8,527.6z"
						/>
						<path
							d="M1421.7,519l-2.3-1.8c-0.5-0.4-1-0.8-1.4-1.2c-0.5-0.4-1.1-0.8-1.5-1c-0.5-0.3-0.8-0.4-1.1-0.5c0.2-0.4,0.4-0.7,0.7-1
									c0.3-0.3,0.6-0.6,1-0.9c0.5,0.5,1.6,1.4,3.3,2.8c1.7,1.4,2.8,2.2,3.2,2.5c-1.2-3.4-2-5.4-2.3-6.2c-0.6-0.3-1-0.5-1.3-0.6
									c0.4-0.7,0.9-1.3,1.7-1.8c0.5,0.5,1.6,1.5,3.4,2.9c1.8,1.5,2.8,2.3,3.1,2.4c-0.8-2.5-1.4-4.2-1.8-5.1s-0.7-1.6-0.9-2
									c-0.1-0.1-0.2-0.2-0.4-0.4c0.3-0.4,0.8-0.9,1.4-1.3c0.3,1,0.6,2.3,1.2,3.9s1.1,3.3,1.8,5.2l-0.5,1.3c-0.3,0.2-0.7,0.3-1.1,0.2
									s-0.9-0.3-1.4-0.7c-1.6-1.3-2.8-2.2-3.4-2.8c0.4,1.4,1.1,3.1,1.9,5.4l-0.5,1.3C1423.6,520,1422.8,519.9,1421.7,519z"
						/>
						<path
							d="M1436.3,509.7c-0.9-0.4-1.8-1.2-2.6-2.5c-0.5-0.9-0.9-1.7-1-2.6c-0.1-0.9,0-1.7,0.3-2.4s0.8-1.3,1.5-1.8
									c0.9-0.6,1.9-0.7,2.8-0.3c0.9,0.4,1.8,1.2,2.7,2.6c-0.5,0.6-1.2,1.2-2,1.9c-0.8,0.7-1.6,1.3-2.5,1.9c0.6,0.8,1.1,1.2,1.7,1.4
									s1.1,0,1.8-0.4c0.5-0.3,0.9-0.7,1.3-1.1s0.6-0.8,0.8-1.2l0.4,0.3c0,0.8-0.3,1.5-0.7,2.2c-0.4,0.7-0.9,1.3-1.6,1.7
									C1438.2,509.9,1437.3,510,1436.3,509.7z M1436.6,504.4c0.5-0.5,0.8-1,1-1.4c-0.5-0.7-1-1.1-1.6-1.4c-0.6-0.2-1.2-0.2-1.8,0.2
									c-0.2,0.5-0.3,1.1-0.1,1.8c0.1,0.7,0.5,1.4,0.9,2.2C1435.6,505.4,1436.1,504.9,1436.6,504.4z"
						/>
						<path
							d="M1448.6,502.7c-0.3-0.1-0.6-0.5-1.1-1.1c-0.3-0.4-0.7-1-1.3-1.9c-0.7-1-1.2-1.8-1.6-2.4c-1.4-2.1-2.6-3.4-3.4-4.2
									c0.2-0.4,0.5-0.7,0.7-0.9c0.3-0.3,0.6-0.5,0.9-0.8c1,1.7,2.5,4,4.5,6.9c0.5,0.7,0.9,1.2,1.2,1.6c0.3,0.3,0.6,0.6,0.8,0.8
									s0.5,0.3,0.9,0.4c0,0.4-0.1,0.7-0.2,0.9c-0.1,0.3-0.3,0.5-0.5,0.6C1449.2,502.8,1448.9,502.9,1448.6,502.7z"
						/>
						<path
							d="M1456.3,496.9c-0.3-0.1-0.7-0.4-1.1-1c-0.3-0.4-0.8-1-1.4-1.8c-0.7-0.9-1.3-1.7-1.8-2.3c-1.6-1.9-2.8-3.3-3.7-3.9
									c0.2-0.4,0.4-0.7,0.6-1c0.2-0.3,0.5-0.6,0.9-0.8c1.1,1.6,2.7,3.8,5,6.6c0.5,0.7,1,1.1,1.3,1.5c0.3,0.3,0.6,0.6,0.9,0.7
									c0.3,0.1,0.6,0.2,0.9,0.3c0,0.4-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.5-0.4,0.6C1456.9,497,1456.6,497,1456.3,496.9z"
						/>
						<path
							d="M1457.4,480.8c1.5,0.5,2.8,1.4,3.8,2.5c1.1,1.2,1.8,2.5,2.2,4c0.4,1.5,0.5,3.1,0.2,4.7l-0.4-0.1c0-1.3-0.3-2.6-0.9-3.9
									c-0.6-1.3-1.4-2.5-2.4-3.6c-1-1.1-2.2-2-3.4-2.7c-1.2-0.7-2.5-1.1-3.8-1.2l0-0.4C1454.3,480.1,1455.9,480.3,1457.4,480.8z"
						/>
					</g>
					<g className="level_zero location" id="fornost">
						<rect className="level_zero location" x="982.2" y="491.9" width="27" height="18.3" />
						<rect x="1939.556" y="1222.089" width="125.938" height="26.886" transform="matrix(0.999442, 0.033394, -0.04543, 0.99904, -905.023743, -798.106995)"></rect>
						<path
							d="M1016.7,513.4c0.1-1.1,0.1-2.6,0.1-4.5c0-1.9,0-3.7-0.1-5.4c-0.1-4.6-0.4-7.8-0.8-9.5c1.1,0.1,2.9,0.2,5.3,0.1
									c2.6-0.1,4.6-0.2,6.1-0.4c0,0.7,0,1.4-0.1,2.1c-0.1,0.8-0.3,1.3-0.5,1.6c-0.5-0.3-1.1-0.5-1.8-0.7s-1.4-0.2-2.2-0.2
									c-1.1,0-2.2,0.2-3.2,0.4c0,2.3,0,4.1,0,5.5l0.8,0c0.7,0,1.2,0,1.6,0c0.9,0,1.7-0.1,2.2-0.2c0.5-0.1,1.1-0.2,1.8-0.4
									c0.1,0.5,0.2,0.9,0.2,1.4c0,0.4,0,0.9-0.1,1.4l-1.5,0c-1.4-0.1-2.6-0.1-3.8-0.1c-0.5,0-0.9,0-1.1,0.1c0.1,4.7,0.4,7.5,0.8,8.6
									c-0.6,0.2-1.2,0.3-1.8,0.3C1018,513.6,1017.4,513.5,1016.7,513.4z"
						/>
						<path
							d="M1029.7,511.4c-1.1-1.4-1.7-3.1-1.7-5.4c0-1.6,0.3-3,0.9-4.3c0.6-1.3,1.4-2.2,2.5-3s2.3-1.1,3.7-1.1
									c1.2,0,2.3,0.3,3.3,0.9c1,0.6,1.7,1.5,2.2,2.6c0.5,1.1,0.8,2.4,0.8,3.9c0,1.6-0.3,3-0.9,4.3c-0.6,1.3-1.5,2.2-2.6,3
									s-2.4,1.1-3.8,1.1C1032.2,513.4,1030.8,512.8,1029.7,511.4z M1037.5,510.4c0.7-0.9,1-2.3,1-4c0-1.5-0.3-2.8-1-3.9
									c-0.6-1.1-1.5-1.8-2.7-2.2c-1.3,0-2.3,0.5-3,1.5c-0.8,1-1.1,2.3-1.1,3.9c0,1.9,0.4,3.4,1.1,4.5c0.7,1.1,1.6,1.7,2.7,1.6
									C1035.9,511.9,1036.8,511.4,1037.5,510.4z"
						/>
						<path
							d="M1044,513c0.2-2.4,0.3-5,0.3-7.8c0-1.3,0-2.4-0.1-3.1c-0.1-0.7-0.2-1.3-0.3-1.7c-0.2-0.4-0.4-0.8-0.8-1.3
									c0.4-0.4,0.7-0.8,1.2-1c0.4-0.2,0.8-0.3,1.1-0.3c1.3,0,1.9,0.9,2,2.7c0.6-0.8,1.2-1.4,1.8-1.9c0.7-0.5,1.4-0.9,2.2-1.1
									c0.2,0.4,0.3,1,0.4,1.7c0.1,0.7,0.2,1.4,0.2,2c-0.3-0.1-0.6-0.1-1.1-0.1c-0.7,0-1.4,0.1-2,0.3c-0.6,0.2-1.2,0.4-1.6,0.7l0,1.1
									c-0.1,1.6-0.1,3-0.1,4.3c0,2.6,0.2,4.4,0.6,5.5c-0.7,0.1-1.3,0.2-1.8,0.2C1045.3,513.2,1044.7,513.1,1044,513z"
						/>
						<path
							d="M1053.2,513.1c0.2-2.4,0.4-5,0.4-7.8c0-1.3,0-2.4,0-3.1c-0.1-0.7-0.2-1.3-0.3-1.7c-0.2-0.4-0.4-0.9-0.8-1.3
									c0.4-0.5,0.7-0.8,1.1-1c0.4-0.2,0.8-0.3,1.1-0.3c1.2,0,1.8,0.8,2,2.3c0.6-0.7,1.3-1.3,2.1-1.7c0.8-0.4,1.6-0.6,2.4-0.6
									c2.6,0.1,3.8,1.8,3.7,5.2l-0.1,2.3c-0.1,0.9-0.1,1.7-0.2,2.5c-0.1,2.5,0.1,4.4,0.5,5.5c-0.6,0.1-1.2,0.2-1.8,0.2
									c-0.6,0-1.2-0.1-2-0.3c0.2-2.9,0.4-6.1,0.5-9.6c0-1-0.1-1.7-0.5-2.1c-0.3-0.4-0.9-0.7-1.7-0.7c-1.2,0-2.2,0.2-3,0.8l-0.1,1.6
									c-0.1,1.2-0.2,2.7-0.2,4.4c-0.1,2.6,0.1,4.4,0.5,5.5c-0.7,0.1-1.3,0.2-1.9,0.2C1054.6,513.3,1053.9,513.2,1053.2,513.1z"
						/>
						<path
							d="M1068.4,511.9c-1-1.4-1.5-3.2-1.4-5.5c0.1-1.6,0.5-3,1.1-4.3c0.7-1.2,1.6-2.2,2.7-2.8c1.1-0.6,2.4-0.9,3.8-0.9
									c1.2,0.1,2.3,0.4,3.2,1.1c0.9,0.7,1.6,1.6,2.1,2.7c0.5,1.1,0.7,2.5,0.6,3.9c-0.1,1.6-0.5,3-1.2,4.3c-0.7,1.2-1.6,2.2-2.8,2.8
									s-2.4,0.9-3.9,0.8C1070.8,514.1,1069.4,513.3,1068.4,511.9z M1076.3,511.4c0.8-0.9,1.2-2.2,1.3-3.9c0.1-1.5-0.2-2.8-0.8-4
									s-1.4-1.9-2.5-2.4c-1.3-0.1-2.3,0.4-3.1,1.3c-0.8,0.9-1.3,2.2-1.3,3.8c-0.1,1.9,0.2,3.4,0.8,4.6c0.7,1.1,1.5,1.7,2.6,1.8
									C1074.6,512.7,1075.6,512.3,1076.3,511.4z"
						/>
						<path
							d="M1080.7,513.3c0-0.5,0.1-0.9,0.2-1.3c0.1-0.4,0.3-0.8,0.6-1.3c0.4,0.6,0.9,1.2,1.6,1.5c0.7,0.4,1.4,0.6,2.2,0.7
									c2.1,0.1,3.2-0.4,3.3-1.7c0-0.5-0.2-1-0.6-1.4c-0.4-0.4-1.1-0.9-2.1-1.4c-0.9-0.6-1.7-1-2.2-1.5c-0.6-0.4-1-1-1.4-1.7
									c-0.4-0.7-0.6-1.5-0.5-2.5c0.1-1.1,0.6-2,1.6-2.7c1-0.6,2.2-0.9,3.6-0.8c2,0.1,3.6,0.5,4.9,1.2c0,0.4-0.2,0.9-0.4,1.5
									c-0.2,0.6-0.5,1.2-0.8,1.7c-0.7-0.9-1.5-1.6-2.2-2.1c-0.7-0.5-1.3-0.8-1.8-0.8c-0.5,0.1-1,0.3-1.3,0.6c-0.4,0.3-0.5,0.7-0.6,1
									c0,0.7,0.2,1.2,0.6,1.7c0.5,0.5,1.2,1,2.3,1.6c0.9,0.5,1.6,1,2.1,1.4c0.6,0.4,1,1,1.4,1.6s0.5,1.4,0.5,2.3
									c-0.1,1.4-0.7,2.4-1.7,3.1c-1,0.7-2.4,1-4.2,0.8C1083.5,514.8,1081.9,514.3,1080.7,513.3z"
						/>
						<path
							d="M1094,514.9c-0.3-0.5-0.4-1.2-0.3-2.3c0.1-0.8,0.2-2,0.4-3.7c0.2-1.8,0.4-3.4,0.5-4.6c0-0.6,0.1-1.1,0.1-1.7
									c-0.9-0.1-1.5-0.2-1.7-0.4c-0.3-0.2-0.4-0.4-0.4-0.7c1.5-0.8,3-1.9,4.4-3.3l0.8,0.2c0,0.8,0,1.7-0.1,2.6c1.5,0.1,2.6,0.1,3.2,0
									c0,0.5-0.1,1-0.2,1.3c-0.1,0.4-0.3,0.8-0.5,1.2c-0.6-0.3-1.5-0.5-2.7-0.7c-0.2,1.1-0.3,2.2-0.4,3.4c-0.3,3-0.3,5.3-0.1,6.9
									c1,0,1.8-0.1,2.5-0.2l0.1,0.9c-0.5,0.6-1,1.1-1.8,1.5c-0.7,0.4-1.5,0.5-2.2,0.4C1094.8,515.6,1094.3,515.3,1094,514.9z"
						/>
					</g>
					<g className="level_two fade location " id="weather_hills">
						<rect x="1986.807" y="1491.547" width="129.007" height="32.814" transform="matrix(0.849697, 0.527271, -0.537505, 0.843346, 262.164032, -1815.519287)"></rect>
						<rect x="1161.271" y="1491.546" width="75.403" height="32.814" transform="matrix(0.849697, 0.527271, -0.537505, 0.843346, 966.537292, -1347.418091)"></rect>
						<path
							d="M1146.5,518.8c-0.2-0.9-0.3-1.9-0.2-3.1c0.1-1.2,0.3-2.9,0.5-5.1c0.3-2.4,0.5-4.4,0.7-5.9s0.2-3.2,0.1-4.7
									c1.4,0,2.7,0.2,4.1,0.8c-0.3,1-0.6,2.5-1,4.4c-0.3,1.9-0.6,3.8-0.9,5.6c-0.3,2.7-0.4,4.8-0.4,6.4c4.1-5.2,6.8-8.9,8.2-11l0-2.3
									c1.3,0,2.7,0.2,4.1,0.8c-0.3,1-0.6,2.5-0.9,4.4c-0.3,1.9-0.7,3.8-0.9,5.6c-0.3,2.9-0.4,5-0.4,6.4c3.1-4,5.4-6.9,6.7-8.8
									c1.4-1.9,2.2-3.2,2.5-4c0.1-0.2,0.1-0.3,0.1-0.5c0.9,0.1,1.9,0.3,2.9,0.7c-1.5,1.8-3.5,4.2-5.9,7.2c-2.4,3.1-4.5,5.7-6.2,7.9
									l-1.8,0.7c-0.7-0.3-1.1-0.8-1.3-1.7c-0.2-0.9-0.3-1.9-0.2-3.1c0.1-1.2,0.3-2.9,0.5-5.1c0.2-1.7,0.4-3.3,0.6-4.8
									c-1.8,2.2-4.4,5.6-7.8,10l-1.8,0.7C1147.2,520.2,1146.7,519.7,1146.5,518.8z"
						/>
						<path
							d="M1168.7,527.3c-0.3-1.6,0.1-3.4,1.2-5.5c0.7-1.4,1.6-2.6,2.7-3.5c1.1-0.9,2.2-1.4,3.5-1.6c1.2-0.2,2.4,0,3.6,0.6
									c1.6,0.8,2.5,2,2.7,3.5c0.2,1.6-0.2,3.4-1.3,5.7c-1.2-0.2-2.6-0.6-4.1-1.1c-1.6-0.5-3.1-1.1-4.6-1.8c-0.5,1.4-0.7,2.5-0.5,3.4
									c0.2,0.9,0.9,1.6,1.9,2.1c0.8,0.4,1.6,0.7,2.4,0.8c0.8,0.1,1.6,0.2,2.3,0l-0.1,0.7c-1,0.6-2.2,0.9-3.5,0.9c-1.3,0-2.5-0.2-3.5-0.7
									C1169.9,530,1169,528.9,1168.7,527.3z M1176,523.4c1.1,0.2,2,0.3,2.7,0.3c0.6-1.2,0.7-2.3,0.5-3.3c-0.2-1-0.8-1.8-1.7-2.2
									c-0.8,0.1-1.6,0.5-2.5,1.3c-0.8,0.8-1.6,1.8-2.2,3C1173.9,522.9,1175,523.2,1176,523.4z"
						/>
						<path
							d="M1182.3,535.3c-0.1-0.8,0-1.7,0.5-2.5c1.5-2.6,4.6-2.7,9.2-0.4c0.8-1.4,1.2-2.5,1.1-3.2c0-0.8-0.6-1.4-1.5-2
									c-0.6-0.3-1.2-0.6-2-0.7c-0.7-0.1-1.4-0.2-2.1-0.1l0.1-0.7c1.1-0.6,2.2-1,3.4-1.1c1.2-0.1,2.3,0.2,3.2,0.7c1.2,0.7,2,1.6,2.2,2.7
									c0.2,1.1,0,2.4-0.8,3.7c-0.4,0.8-1,1.6-1.6,2.5c-0.7,1-1.1,1.7-1.4,2.2c-0.5,0.8-0.7,1.5-0.9,2s-0.1,1.2,0,2
									c-1.1,0.3-2.1,0.2-3-0.1c-0.3-0.7-0.3-1.5-0.1-2.4c-1,0.1-2,0.1-2.9,0c-0.9-0.1-1.7-0.4-2.3-0.7
									C1182.9,536.8,1182.5,536.2,1182.3,535.3z M1187.4,536.7c0.7,0.1,1.3,0,2-0.1l0.1-0.2c0.4-0.7,0.8-1.3,1.2-1.9l0.9-1.3
									c-1.3-0.7-2.5-0.9-3.5-0.8c-1,0.1-1.8,0.6-2.3,1.5c-0.2,0.4-0.3,0.8-0.4,1.2c0,0.4,0,0.9,0.2,1.2
									C1186.1,536.5,1186.7,536.7,1187.4,536.7z"
						/>
						<path
							d="M1196.9,544.4c0-0.5,0.3-1.2,0.8-2.1c0.4-0.6,1.1-1.7,2.1-3.1c1.1-1.5,1.9-2.8,2.6-3.8c0.3-0.5,0.6-1,0.9-1.4
									c-0.7-0.5-1.2-0.9-1.4-1.1c-0.2-0.3-0.2-0.6,0-0.8c1.7,0,3.5-0.3,5.4-0.9l0.6,0.5c-0.4,0.7-0.8,1.5-1.3,2.2
									c1.2,0.8,2.2,1.3,2.9,1.5c-0.3,0.4-0.6,0.8-0.9,1.1c-0.3,0.3-0.6,0.5-1,0.8c-0.4-0.6-1.1-1.2-2.1-1.9c-0.7,0.9-1.3,1.8-2,2.8
									c-1.6,2.5-2.8,4.5-3.3,6c0.9,0.5,1.6,0.8,2.3,1l-0.3,0.8c-0.7,0.4-1.5,0.5-2.3,0.5c-0.8,0-1.5-0.2-2.1-0.6
									C1197.3,545.4,1196.9,544.9,1196.9,544.4z"
						/>
						<path
							d="M1204.9,550.1c2-2.4,3.9-4.9,5.7-7.4c2.5-3.4,4.4-6.2,5.6-8.2c1.2,0.2,2.3,0.8,3.4,1.6c-0.8,0.8-2.3,2.7-4.6,5.4
									c0.9-0.2,1.8-0.3,2.7-0.1c0.9,0.1,1.7,0.4,2.3,0.9c2.1,1.5,2.1,3.6,0.1,6.4l-1.4,1.8l-1.5,1.9c-1.5,2.1-2.4,3.7-2.7,4.8
									c-0.6-0.2-1.1-0.5-1.5-0.9c-0.4-0.3-0.9-0.8-1.4-1.3c2.2-2.7,4.1-5.2,5.9-7.6c0.6-0.8,0.8-1.5,0.8-2c0-0.6-0.4-1.1-1-1.6
									c-0.9-0.7-2-1-3.1-1.1c-0.5,0.5-1.1,1.3-1.8,2.4c-2.7,3.7-4.2,6.1-4.4,7.2c-0.6-0.2-1.1-0.5-1.6-0.9
									C1205.9,551.1,1205.4,550.6,1204.9,550.1z"
						/>
						<path
							d="M1220.3,559.8c0.1-1.6,0.9-3.3,2.5-5.1c1-1.2,2.2-2.1,3.4-2.7c1.2-0.6,2.5-0.8,3.7-0.7c1.2,0.1,2.4,0.6,3.4,1.5
									c1.3,1.1,2,2.5,1.8,4.1c-0.1,1.6-1,3.3-2.6,5.2c-1.1-0.5-2.4-1.2-3.8-2.1c-1.4-0.9-2.7-1.8-4-2.8c-0.9,1.2-1.3,2.3-1.2,3.2
									c0,0.9,0.5,1.7,1.4,2.5c0.7,0.6,1.4,1,2.2,1.4c0.8,0.3,1.5,0.5,2.2,0.6l-0.3,0.7c-1.2,0.3-2.4,0.3-3.6,0.1
									c-1.3-0.3-2.3-0.8-3.2-1.6C1220.8,562.8,1220.2,561.4,1220.3,559.8z M1228.3,557.8c1,0.5,1.8,0.8,2.5,0.9c0.8-1,1.2-2,1.3-3.1
									c0-1.1-0.3-1.9-1.1-2.6c-0.8-0.1-1.7,0.1-2.7,0.7c-1,0.6-2,1.4-2.9,2.4C1226.4,556.8,1227.3,557.3,1228.3,557.8z"
						/>
						<path
							d="M1231.9,572c1.8-1.7,3.6-3.5,5.5-5.5c0.9-1,1.6-1.8,2-2.3c0.4-0.6,0.7-1.1,0.9-1.5c0.2-0.4,0.3-0.9,0.3-1.5
									c0.6-0.1,1.1-0.1,1.5,0.1c0.5,0.1,0.8,0.3,1,0.5c0.9,0.9,0.8,2-0.4,3.3c1-0.2,1.8-0.2,2.7-0.1c0.8,0.1,1.6,0.3,2.4,0.7
									c-0.2,0.4-0.4,0.9-0.9,1.5c-0.4,0.6-0.8,1.1-1.2,1.6c-0.2-0.2-0.4-0.5-0.8-0.8c-0.5-0.5-1.1-0.9-1.7-1.2c-0.6-0.3-1.1-0.5-1.6-0.6
									l-0.8,0.8c-1.1,1.1-2.1,2.2-3,3.1c-1.8,1.9-2.9,3.4-3.3,4.5c-0.6-0.3-1.1-0.7-1.5-1.1S1232.4,572.6,1231.9,572z"
						/>
						<path
							d="M1145.3,548.8c0.6-1,1.4-2.5,2.3-4.4c0.9-1.9,1.8-3.7,2.6-5.5c1.9-4.1,2.8-6.6,2.9-7.7c0.6,0.1,1.2,0.3,1.7,0.5
									c0.5,0.2,1.1,0.6,1.7,1c-1,1.9-2.3,4.4-3.8,7.5c1,0.6,2.4,1.3,4.1,2.1c0.3,0.1,1.1,0.5,2.5,1c1.9-4.1,2.9-6.8,3-8
									c0.6,0.1,1.2,0.3,1.7,0.5c0.5,0.2,1.1,0.6,1.7,1c-0.6,1-1.4,2.5-2.3,4.4c-0.9,1.9-1.8,3.7-2.6,5.5c-1.8,4-2.8,6.6-2.9,7.7
									c-0.6-0.1-1.2-0.3-1.7-0.6c-0.5-0.2-1-0.6-1.6-1c1-1.8,2.3-4.3,3.8-7.6c-1.2-0.6-2.5-1.3-3.8-1.9c-0.9-0.4-1.8-0.8-2.8-1.2
									c-1.9,4.2-2.9,6.8-3.1,8c-0.6-0.1-1.2-0.3-1.8-0.6C1146.4,549.5,1145.9,549.2,1145.3,548.8z"
						/>
						<path
							d="M1164.3,557.5c0-0.5,0.2-1.3,0.8-2.3c0.4-0.8,1-1.9,1.9-3.4c0.8-1.4,1.3-2.4,1.7-3c0.5-0.9,0.9-1.7,1.2-2.5
									c0.3-0.8,0.4-1.4,0.4-1.8c0.7,0.1,1.3,0.2,1.8,0.3c0.5,0.2,1.1,0.4,1.8,0.7c-0.4,0.6-1,1.5-1.8,2.8c-0.8,1.3-1.6,2.7-2.4,4.3
									c-0.6,1.2-1.1,2.1-1.3,2.8c-0.3,0.7-0.4,1.2-0.5,1.7s0,0.9,0.1,1.5c-0.5,0.2-1,0.3-1.5,0.3s-0.9-0.1-1.1-0.2
									C1164.6,558.4,1164.3,558,1164.3,557.5z M1172.6,539.8c1-0.5,2-0.8,2.8-1.1c0.2,1,0.4,2,0.7,3c-0.9,0.4-1.9,0.8-2.9,1
									C1173.1,541.7,1172.9,540.8,1172.6,539.8z"
						/>
						<path
							d="M1173.2,562.5c0-0.5,0.3-1.2,0.9-2.2c0.4-0.7,1-1.7,1.9-3.1c1-1.6,1.8-2.9,2.4-3.9c2-3.4,3.2-5.9,3.7-7.6
									c0.7,0.1,1.3,0.2,1.8,0.4s1.1,0.5,1.7,0.8c-1.8,2.5-4.1,6.1-6.9,11c-0.7,1.1-1.2,2-1.5,2.7c-0.3,0.7-0.5,1.2-0.5,1.7
									c-0.1,0.4,0,1,0.1,1.5c-0.5,0.2-1,0.3-1.5,0.3s-0.8-0.1-1.1-0.3C1173.5,563.4,1173.2,563,1173.2,562.5z"
						/>
						<path
							d="M1181.9,567.8c0-0.5,0.3-1.2,1-2.2c0.4-0.6,1.1-1.6,2-3c1-1.5,1.9-2.8,2.5-3.8c2.1-3.3,3.5-5.8,4.1-7.4
									c0.7,0.1,1.3,0.3,1.8,0.5c0.5,0.2,1.1,0.5,1.7,0.9c-1.9,2.4-4.3,6-7.4,10.7c-0.7,1.1-1.2,2-1.6,2.6c-0.3,0.6-0.5,1.2-0.6,1.6
									c-0.1,0.4-0.1,1,0,1.5c-0.5,0.2-1,0.2-1.5,0.2c-0.5,0-0.8-0.2-1.1-0.3C1182.1,568.7,1181.9,568.3,1181.9,567.8z"
						/>
						<path
							d="M1189.2,571.9c0.3-0.4,0.6-0.7,0.9-1c0.3-0.3,0.7-0.6,1.2-0.8c0,0.7,0.1,1.5,0.5,2.1c0.4,0.7,0.9,1.3,1.5,1.7
									c1.7,1.2,2.9,1.3,3.7,0.3c0.3-0.4,0.4-0.9,0.2-1.5c-0.2-0.6-0.5-1.3-1-2.3c-0.5-1-0.9-1.8-1.1-2.4c-0.3-0.7-0.4-1.4-0.3-2.2
									s0.3-1.6,0.9-2.3c0.7-0.9,1.6-1.4,2.7-1.4c1.2,0,2.3,0.4,3.5,1.2c1.6,1.2,2.8,2.4,3.5,3.7c-0.2,0.3-0.6,0.7-1.2,1.1
									c-0.5,0.4-1.1,0.7-1.6,1c-0.2-1.1-0.4-2.1-0.7-2.9c-0.3-0.8-0.7-1.4-1.1-1.7c-0.5-0.2-1-0.3-1.4-0.2c-0.5,0.1-0.8,0.3-1,0.6
									c-0.4,0.6-0.5,1.1-0.4,1.8c0.2,0.6,0.5,1.5,1.1,2.6c0.5,0.9,0.8,1.7,1.1,2.3c0.2,0.6,0.4,1.3,0.3,2.1c0,0.7-0.3,1.5-0.8,2.2
									c-0.8,1.1-1.8,1.7-3.1,1.7c-1.2,0-2.6-0.5-4-1.5C1190.8,574.7,1189.7,573.3,1189.2,571.9z"
						/>
					</g>
					<g className="level_two fade location " id="midgewater">
						<rect x="2286.622" y="1345.841" width="148.474" height="29.609" transform="matrix(0.639353, 0.768913, -0.776614, 0.630092, 656.382019, -2070.865967)"></rect>
						<path
							d="M1058.8,552.8c1.9-1.3,4.5-3.2,7.8-5.8c1.7-1.3,3-2.4,3.8-3.1c0.8-0.7,1.4-1.3,1.7-1.7c0.4,0.3,0.8,0.7,1.1,1.1
									c0.3,0.3,0.6,0.8,0.9,1.4c-0.9,1.4-2,3.3-3.4,5.8c-1.4,2.5-2.6,4.5-3.4,6c1.8-0.4,3.9-1,6.6-1.7c2.6-0.7,4.8-1.4,6.6-1.9
									c0.3,0.2,0.7,0.6,1.1,1.2c0.3,0.4,0.6,0.9,0.8,1.4c-0.9,0.6-2.1,1.4-3.5,2.5c-1.5,1.1-2.9,2.1-4.3,3.2c-3.2,2.5-5,4.1-5.5,4.8
									c-0.4-0.3-0.8-0.7-1.1-1.1c-0.3-0.4-0.6-0.9-0.9-1.5c0.9-0.6,2.1-1.4,3.6-2.5c1.5-1.1,2.9-2.1,4.2-3.1c0.8-0.7,1.8-1.4,2.9-2.3
									c-3.5,0.9-8.2,2.1-13.9,3.8c-0.2-0.1-0.4-0.3-0.5-0.4c-0.1-0.1-0.2-0.3-0.2-0.6c0.8-1.3,1.8-3,3.2-5.4c1.3-2.3,2.5-4.4,3.6-6.3
									c-1.8,1.3-3.2,2.4-4.1,3.1c-3.1,2.4-4.9,3.9-5.6,4.7c-0.3-0.2-0.6-0.4-0.8-0.7C1059.1,553.6,1058.9,553.2,1058.8,552.8z"
						/>
						<path
							d="M1072.8,570.5c0.2-0.4,0.6-0.9,1.4-1.5c0.6-0.5,1.5-1.2,2.7-2c1.1-0.8,1.9-1.4,2.4-1.8c0.7-0.5,1.3-1.1,1.8-1.6
									c0.5-0.5,0.9-1,1-1.3c0.5,0.3,1,0.6,1.3,0.9s0.8,0.7,1.1,1.2c-0.6,0.3-1.4,0.8-2.4,1.6c-1.1,0.7-2.2,1.6-3.5,2.6
									c-0.9,0.7-1.6,1.3-2.1,1.7c-0.5,0.4-0.8,0.8-1,1.2c-0.2,0.3-0.4,0.8-0.5,1.3c-0.5,0-0.9-0.1-1.3-0.3c-0.4-0.2-0.7-0.4-0.8-0.6
									C1072.7,571.4,1072.7,570.9,1072.8,570.5z M1085.8,559.3c1,0,1.9,0,2.7,0.2c-0.2,0.9-0.4,1.8-0.5,2.6c-0.9,0-1.8,0-2.7-0.2
									C1085.6,561,1085.7,560.2,1085.8,559.3z"
						/>
						<path
							d="M1078.7,576.4c0.4-1.3,1.5-2.7,3.3-4.1c1.8-1.4,3.5-2.2,4.9-2.2c1.4-0.1,2.7,0.5,3.6,1.8c0.8,1,1.2,2.2,1.1,3.6
									c1.6-1.4,2.8-2.5,3.4-3.4c0.5,0.3,1,0.6,1.4,0.9s0.8,0.7,1.1,1.2c-2.3,1.5-5.9,4.1-10.9,8.1c-0.6,0.5-1,0.9-1.3,1.3
									c-0.3,0.4-0.4,0.9-0.6,1.6c-0.9-0.1-1.7-0.5-2.3-1.1c0-0.7,0.3-1.3,0.7-2c-0.8-0.1-1.5-0.2-2.1-0.6c-0.7-0.3-1.2-0.7-1.6-1.2
									C1078.6,579,1078.3,577.7,1078.7,576.4z M1082.5,580.7c0.5,0.2,1,0.4,1.6,0.5c0.5-0.4,1.4-1.1,2.7-2c1.4-1,2.5-1.9,3.3-2.5
									c0-0.9-0.4-1.8-1-2.6c-0.6-0.8-1.4-1.2-2.4-1.1c-1,0.1-2,0.6-3.2,1.5c-1.2,0.9-1.9,1.8-2.3,2.8c-0.4,0.9-0.4,1.8,0.1,2.6
									C1081.5,580.2,1082,580.4,1082.5,580.7z"
						/>
						<path
							d="M1082.9,591.2c0.6-0.5,1.3-0.8,2-1.1s1.5-0.4,2.3-0.3c-0.2-0.8,0-1.4,0.7-2c0.5-0.4,1-0.5,1.6-0.5c0.6,0,1.3,0.2,1.9,0.6
									c-0.1-0.8,0-1.5,0.3-2.2s0.9-1.4,1.6-1.9c1.2-0.9,2.4-1.3,3.8-1.1c1.3,0.2,2.4,0.8,3.4,2c0.6,0.8,0.9,1.6,1,2.5
									c0.3,0.2,0.8,0.5,1.3,0.6c0.6,0.2,1.2,0.3,1.9,0.4c-0.2,0.4-0.4,0.8-0.7,1.2s-0.6,0.7-0.8,0.9c-0.7-0.3-1.3-0.7-1.9-1.4
									c-0.4,0.8-0.9,1.5-1.6,2c-0.8,0.6-1.6,1-2.5,1.1s-1.7,0-2.6-0.4c-0.8-0.4-1.6-1-2.2-1.7c-0.5-0.7-1-1.1-1.6-1.3
									c-0.5-0.2-1-0.2-1.4,0.2l3.1,4.5c0.6,0.9,0.8,1.7,0.7,2.5s-0.5,1.4-1.2,2c-0.7,0.5-1.5,0.8-2.3,0.8c-0.9,0-1.7-0.2-2.6-0.7
									c-0.9-0.5-1.7-1.2-2.4-2.1C1083.5,594.2,1082.9,592.7,1082.9,591.2z M1088.8,596.7c0.7,0.3,1.2,0.3,1.6-0.1
									c0.3-0.3,0.5-0.6,0.5-0.9c0-0.3-0.2-0.8-0.6-1.3l-2.5-3.6c-0.7,0.1-1.3,0.3-1.7,0.6c-0.4,1.3-0.2,2.5,0.7,3.7
									C1087.5,595.9,1088.1,596.4,1088.8,596.7z M1095.4,590.5c0.7,0.1,1.3-0.2,2-0.7c0.6-0.5,1-1,1.2-1.7c0.2-0.7,0.1-1.3-0.2-2
									c-0.5-0.6-1.1-1-1.9-1c-0.8-0.1-1.5,0.2-2.2,0.7c-0.7,0.5-1.1,1.1-1.2,1.8s0,1.3,0.5,1.9C1094.2,590.1,1094.8,590.4,1095.4,590.5z
									"
						/>
						<path
							d="M1097,599.8c0.4-1.3,1.4-2.7,3-3.9c1.1-0.9,2.3-1.5,3.4-1.7c1.2-0.3,2.3-0.3,3.3,0c1,0.3,1.9,0.9,2.6,1.9
									c1,1.2,1.2,2.5,0.9,3.8c-0.4,1.3-1.4,2.7-3.2,4c-0.9-0.6-1.8-1.5-2.9-2.5c-1.1-1-2-2.1-2.9-3.1c-1,0.9-1.5,1.7-1.6,2.5
									c-0.1,0.8,0.1,1.6,0.7,2.4c0.5,0.6,1,1.1,1.6,1.6c0.6,0.4,1.2,0.7,1.8,0.9l-0.4,0.5c-1,0.1-2.1-0.1-3.1-0.6s-1.9-1.1-2.5-1.9
									C1096.9,602.4,1096.6,601.2,1097,599.8z M1104.2,599.5c0.8,0.6,1.4,1,2,1.2c0.9-0.7,1.4-1.5,1.7-2.4c0.2-0.9,0.1-1.7-0.5-2.4
									c-0.7-0.2-1.5-0.2-2.5,0.1c-1,0.3-1.9,0.8-2.9,1.6C1102.7,598.3,1103.5,598.9,1104.2,599.5z"
						/>
						<path
							d="M1107.3,611.3l2.4-3.3c0.6-0.8,1.1-1.5,1.5-2.1c0.5-0.8,1-1.5,1.3-2.2c0.4-0.7,0.6-1.2,0.6-1.6c0.5,0.2,1,0.5,1.4,0.9
									c0.4,0.3,0.8,0.8,1.2,1.3c-0.6,0.7-1.9,2.3-3.7,4.7s-2.9,4-3.2,4.5c4.6-1.8,7.4-3,8.4-3.6c0.4-0.8,0.7-1.4,0.7-1.8
									c0.9,0.5,1.8,1.2,2.6,2.2c-0.6,0.7-1.9,2.3-3.8,4.9c-1.9,2.6-3,4.1-3.1,4.4c3.3-1.3,5.7-2.3,7-2.8c1.3-0.6,2.2-1.1,2.7-1.4
									c0.1-0.1,0.3-0.3,0.5-0.6c0.6,0.4,1.3,1,1.9,1.8c-1.4,0.4-3.2,1-5.4,1.9s-4.5,1.8-7,2.9l-1.8-0.6c-0.4-0.5-0.5-1-0.4-1.5
									c0.1-0.6,0.4-1.2,0.9-2c1.7-2.3,2.9-4,3.6-4.9c-1.8,0.7-4.3,1.7-7.3,3l-1.8-0.6C1106,614,1106.2,612.8,1107.3,611.3z"
						/>
						<path
							d="M1118.1,627.4c0.1-0.7,0.5-1.4,1.2-2c2-1.7,4.6-0.9,7.8,2.3c1.1-0.9,1.7-1.7,1.9-2.4c0.2-0.6-0.1-1.3-0.7-2.1
									c-0.4-0.5-0.9-0.8-1.4-1.2c-0.6-0.3-1.1-0.5-1.8-0.7l0.3-0.6c1.1-0.2,2.1-0.2,3.1,0.1c1,0.3,1.8,0.8,2.5,1.5
									c0.8,0.9,1.2,1.9,1.1,2.9c-0.1,1-0.7,2-1.7,2.9c-0.6,0.5-1.3,1-2.1,1.6c-0.8,0.6-1.4,1.1-1.8,1.4c-0.6,0.5-1,1-1.3,1.4
									c-0.2,0.4-0.4,1-0.5,1.7c-1-0.1-1.8-0.4-2.5-1c-0.1-0.6,0.2-1.3,0.6-2c-0.9-0.2-1.7-0.4-2.4-0.8c-0.7-0.4-1.3-0.8-1.7-1.3
									C1118.2,628.8,1118,628.2,1118.1,627.4z M1121.9,630c0.5,0.2,1.1,0.4,1.7,0.5l0.1-0.1c0.5-0.5,1-0.9,1.6-1.2l1.1-0.8
									c-0.9-0.9-1.8-1.5-2.7-1.7c-0.9-0.2-1.6,0-2.3,0.6c-0.3,0.2-0.5,0.5-0.6,0.9c-0.2,0.4-0.2,0.7-0.2,1.1
									C1120.9,629.5,1121.4,629.8,1121.9,630z"
						/>
						<path
							d="M1128.4,639.3c0.1-0.5,0.5-1,1.2-1.7c0.5-0.5,1.3-1.2,2.4-2.2c1.2-1.1,2.2-2,3-2.8c0.4-0.4,0.7-0.7,1-1.1
									c-0.5-0.6-0.8-1-0.9-1.2c-0.1-0.3,0-0.5,0.2-0.7c1.4,0.4,3,0.5,4.8,0.3l0.4,0.6c-0.5,0.6-1,1.1-1.5,1.7c0.9,0.9,1.6,1.6,2.1,1.9
									c-0.3,0.3-0.6,0.6-0.9,0.7c-0.3,0.2-0.6,0.3-1,0.5c-0.2-0.6-0.7-1.2-1.4-2c-0.7,0.6-1.5,1.3-2.3,2c-1.9,1.8-3.3,3.3-4.1,4.5
									c0.7,0.6,1.2,1,1.7,1.3l-0.4,0.6c-0.7,0.2-1.4,0.1-2-0.1c-0.7-0.2-1.2-0.5-1.7-1C1128.6,640.2,1128.4,639.8,1128.4,639.3z"
						/>
						<path
							d="M1136.1,645.7c0.2-1.4,1-2.8,2.4-4.3c1-1,2-1.8,3.1-2.2c1.1-0.5,2.2-0.6,3.3-0.4c1.1,0.2,2,0.6,2.9,1.4
									c1.1,1.1,1.6,2.3,1.4,3.6c-0.2,1.4-1,2.9-2.5,4.4c-0.9-0.5-2-1.2-3.2-2c-1.2-0.8-2.3-1.7-3.4-2.7c-0.8,1-1.2,1.9-1.2,2.7
									c0,0.8,0.3,1.5,1.1,2.2c0.6,0.5,1.2,1,1.8,1.3c0.7,0.3,1.3,0.5,1.9,0.6l-0.3,0.6c-1,0.2-2.1,0.2-3.2-0.1c-1.1-0.3-2-0.8-2.8-1.5
									C1136.4,648.3,1136,647,1136.1,645.7z M1143.3,644.2c0.8,0.5,1.6,0.8,2.2,0.9c0.8-0.8,1.2-1.7,1.3-2.6c0.1-0.9-0.2-1.7-0.8-2.3
									c-0.7-0.1-1.5,0-2.4,0.5c-0.9,0.4-1.8,1.1-2.6,2C1141.6,643.3,1142.4,643.8,1143.3,644.2z"
						/>
						<path
							d="M1146.6,657c1.5-1.5,3-3.2,4.6-5.1c0.8-0.9,1.3-1.6,1.7-2.1c0.4-0.5,0.6-1,0.7-1.3c0.1-0.4,0.2-0.8,0.2-1.3
									c0.5-0.1,0.9-0.1,1.3,0c0.4,0.1,0.7,0.2,0.9,0.4c0.8,0.7,0.8,1.7-0.2,2.9c0.8-0.2,1.6-0.3,2.3-0.2c0.7,0,1.4,0.2,2.1,0.5
									c-0.1,0.3-0.3,0.8-0.7,1.4c-0.3,0.6-0.7,1-1,1.5c-0.1-0.2-0.4-0.4-0.7-0.7c-0.5-0.4-1-0.7-1.5-1c-0.5-0.2-1-0.4-1.4-0.4l-0.7,0.7
									c-0.9,1.1-1.8,2-2.5,2.8c-1.5,1.7-2.4,3.1-2.7,4.1c-0.5-0.3-1-0.6-1.3-0.9S1147,657.5,1146.6,657z"
						/>
					</g>
					<g className="level_two fade location " id="chetwood">
						<rect x="1939.555" y="1222.087" width="125.938" height="26.886" transform="matrix(0.397906, 0.917426, -0.922218, 0.386857, 1392.248535, -1739.79895)"></rect>
						<path
							d="M1020.6,524.5c0.8-1.6,2.3-3,4.7-4.2c1.6-0.8,3.2-1.2,4.8-1.3c1.6,0,3,0.3,4.2,1c1.2,0.7,2.2,1.7,2.8,3.1
									c0.7,1.5,1.1,2.9,1.1,4.1c-0.4,0.2-0.9,0.4-1.5,0.5c-0.6,0.1-1.1,0.2-1.5,0.1c0.2-1,0.3-2,0.3-3c0-1-0.2-1.8-0.4-2.3
									c-0.4-0.5-1.1-0.8-2-1c-0.9-0.2-1.8-0.2-2.9-0.1c-1,0.1-2,0.4-3,0.9c-2,1-3.2,2.1-3.8,3.3c-0.6,1.2-0.6,2.5,0,3.8
									c0.8,1.7,1.9,3,3.1,3.7l-0.6,0.5c-1.1-0.2-2-0.7-3-1.4c-0.9-0.7-1.6-1.6-2.1-2.5C1020,527.9,1019.9,526.1,1020.6,524.5z"
						/>
						<path
							d="M1025.4,538.2c2.5-1.1,5-2.2,7.5-3.3c3.4-1.6,6-2.9,7.8-4c0.8,0.7,1.4,1.5,2,2.6c-1,0.3-2.9,1.1-5.8,2.3
									c0.8,0.2,1.5,0.6,2.2,1c0.6,0.5,1.1,1,1.4,1.7c1,2,0.1,3.7-2.6,5l-1.9,0.8l-2,0.9c-2,1-3.4,1.8-4.1,2.6c-0.3-0.4-0.6-0.9-0.8-1.3
									c-0.2-0.4-0.4-1-0.6-1.6c2.8-1.2,5.4-2.3,7.7-3.4c0.8-0.4,1.3-0.8,1.5-1.2c0.2-0.4,0.1-1-0.2-1.6c-0.4-0.9-1.1-1.6-1.9-2.1
									c-0.6,0.2-1.4,0.6-2.4,1.1c-3.6,1.7-5.8,3-6.4,3.7c-0.3-0.4-0.6-0.9-0.9-1.4C1025.7,539.3,1025.5,538.8,1025.4,538.2z"
						/>
						<path
							d="M1033.5,552.1c0.7-1.2,2-2.3,3.8-3.1c1.3-0.6,2.5-0.9,3.7-0.9c1.2,0,2.3,0.3,3.2,0.8c0.9,0.6,1.7,1.4,2.1,2.4
									c0.7,1.4,0.6,2.7,0,3.9s-2,2.3-4,3.2c-0.7-0.8-1.4-1.8-2.2-3.1s-1.5-2.5-2.1-3.7c-1.1,0.7-1.9,1.3-2.2,2.1s-0.3,1.6,0.2,2.5
									c0.3,0.7,0.7,1.4,1.2,1.9c0.5,0.6,1,1,1.5,1.3l-0.5,0.4c-1-0.2-2-0.6-2.9-1.3c-0.9-0.7-1.6-1.5-2-2.4
									C1032.8,554.7,1032.8,553.4,1033.5,552.1z M1040.6,553.5c0.6,0.7,1.2,1.3,1.7,1.7c1-0.5,1.7-1.2,2.2-2c0.4-0.8,0.4-1.6,0.1-2.4
									c-0.6-0.4-1.4-0.5-2.4-0.5c-1,0.1-2.1,0.4-3.2,0.9C1039.4,551.9,1040,552.7,1040.6,553.5z"
						/>
						<path
							d="M1038.9,566.5c0.2-0.4,0.8-0.8,1.6-1.2c0.6-0.3,1.6-0.7,3-1.3c1.5-0.6,2.7-1.2,3.7-1.6c0.5-0.2,0.9-0.4,1.3-0.7
									c-0.3-0.7-0.5-1.2-0.5-1.5c0-0.3,0.1-0.5,0.4-0.6c1.2,0.8,2.7,1.4,4.4,1.9l0.2,0.7c-0.6,0.4-1.3,0.7-2,1.1c0.6,1.2,1,2,1.4,2.5
									c-0.4,0.2-0.8,0.3-1.1,0.4c-0.3,0.1-0.7,0.1-1.1,0.1c0-0.6-0.2-1.4-0.6-2.4c-0.9,0.3-1.8,0.7-2.8,1.2c-2.4,1.1-4.2,2.1-5.3,2.9
									c0.4,0.8,0.8,1.4,1.2,1.8l-0.6,0.5c-0.7-0.1-1.3-0.3-1.9-0.7c-0.6-0.4-1-0.9-1.3-1.5C1038.7,567.4,1038.7,566.9,1038.9,566.5z"
						/>
						<path
							d="M1045,575l3.2-2.5c0.8-0.6,1.4-1.1,2-1.6c0.7-0.6,1.3-1.2,1.8-1.8c0.5-0.6,0.9-1,1-1.3c0.4,0.4,0.8,0.8,1.1,1.2
									c0.3,0.4,0.6,0.9,0.9,1.6c-0.8,0.5-2.4,1.7-4.8,3.6c-2.4,1.9-3.8,3.1-4.3,3.6c4.9-0.6,7.9-1,9.1-1.2c0.6-0.6,1-1.2,1.2-1.6
									c0.8,0.7,1.5,1.6,2,2.8c-0.8,0.5-2.5,1.7-5,3.7c-2.5,2-3.9,3.2-4.1,3.5c3.6-0.4,6.1-0.7,7.5-0.9c1.4-0.2,2.4-0.4,2.9-0.7
									c0.2-0.1,0.4-0.2,0.6-0.4c0.5,0.6,1,1.3,1.4,2.2c-1.5,0-3.4,0.2-5.7,0.4c-2.3,0.2-4.8,0.5-7.5,0.9l-1.5-1c-0.2-0.5-0.2-1.1,0-1.6
									c0.2-0.5,0.7-1.1,1.4-1.7c2.2-1.8,3.8-3.1,4.8-3.8c-2,0.2-4.6,0.5-7.8,1l-1.5-1C1043.1,577.2,1043.6,576.2,1045,575z"
						/>
						<path
							d="M1052.3,593.6c0.7-1.4,2-2.4,3.8-3.2c1.3-0.6,2.6-0.8,3.8-0.8c1.2,0.1,2.3,0.4,3.2,1c0.9,0.6,1.7,1.5,2.1,2.6
									c0.4,1,0.6,2,0.4,3c-0.2,1-0.6,1.9-1.4,2.7c-0.7,0.8-1.7,1.5-2.9,2c-1.3,0.6-2.6,0.8-3.8,0.7c-1.2-0.1-2.3-0.4-3.3-1.1
									c-1-0.7-1.7-1.6-2.2-2.7C1051.5,596.4,1051.5,595,1052.3,593.6z M1055.7,599.7c1,0.2,2.2,0.1,3.6-0.5c1.2-0.5,2.2-1.2,2.8-2.1
									c0.7-0.9,1-1.9,0.9-2.9c-0.4-1-1.2-1.7-2.2-2c-1-0.3-2.2-0.1-3.5,0.4c-1.5,0.7-2.6,1.5-3.3,2.4c-0.6,1-0.8,1.9-0.4,2.8
									C1054,598.8,1054.7,599.4,1055.7,599.7z"
						/>
						<path
							d="M1058.3,607.9c0.7-1.4,2-2.4,3.8-3.2c1.3-0.5,2.6-0.8,3.8-0.7c1.2,0.1,2.3,0.4,3.2,1.1c0.9,0.6,1.6,1.5,2.1,2.7
									c0.4,1,0.5,2,0.4,3c-0.2,1-0.6,1.9-1.4,2.7c-0.7,0.8-1.7,1.4-2.9,1.9c-1.3,0.5-2.6,0.8-3.8,0.7c-1.2-0.1-2.3-0.5-3.3-1.1
									c-0.9-0.7-1.7-1.6-2.1-2.7C1057.5,610.6,1057.6,609.2,1058.3,607.9z M1061.7,613.9c1,0.3,2.2,0.1,3.6-0.5c1.2-0.5,2.2-1.2,2.9-2.1
									c0.7-0.9,1-1.9,0.9-2.9c-0.4-1-1.2-1.7-2.2-2c-1-0.3-2.2-0.2-3.5,0.4c-1.5,0.6-2.6,1.4-3.3,2.4c-0.7,1-0.8,1.9-0.4,2.8
									C1060,613.1,1060.7,613.7,1061.7,613.9z"
						/>
						<path
							d="M1064.1,622c0.8-1.2,2.2-2.2,4.3-3c2.1-0.9,3.9-1.2,5.3-0.8s2.4,1.2,3,2.7c0.5,1.2,0.5,2.4,0.1,3.7
									c1.9-0.9,3.4-1.7,4.2-2.3c0.4,0.4,0.8,0.9,1.1,1.3c0.3,0.4,0.5,0.9,0.8,1.5c-2.6,0.8-6.8,2.4-12.7,4.8c-0.7,0.3-1.2,0.6-1.6,0.9
									c-0.3,0.3-0.7,0.8-1,1.4c-0.8-0.4-1.5-0.9-1.9-1.7c0.2-0.6,0.6-1.2,1.3-1.7c-0.7-0.3-1.4-0.6-1.9-1.1c-0.5-0.5-0.9-1-1.2-1.6
									C1063.3,624.5,1063.4,623.2,1064.1,622z M1066.6,627.2c0.4,0.4,0.9,0.7,1.4,1c0.6-0.3,1.7-0.7,3.2-1.2c1.6-0.6,2.9-1.1,3.9-1.5
									c0.2-0.9,0.1-1.8-0.3-2.8c-0.4-0.9-1-1.5-2-1.7c-0.9-0.2-2.1,0-3.5,0.5c-1.4,0.6-2.4,1.2-3,2s-0.8,1.6-0.7,2.6
									C1065.8,626.4,1066.1,626.8,1066.6,627.2z"
						/>
					</g>
					<g className="level_two fade location" id="weathertop">
						<rect x="2203.216" y="1264.047" width="143.06" height="27.809" transform="matrix(0.870149, -0.492789, 0.482307, 0.876085, -1324.394165, 602.495483)"></rect>
						<rect x="2203.218" y="1264.047" width="143.06" height="27.809" transform="matrix(0.711101, -0.70309, 0.694524, 0.71957, -1128.62854, 1196.948364)"></rect>
						<path
							d="M1219.4,641.3c-0.7-0.4-1.4-0.9-2.2-1.7c-0.8-0.7-1.8-1.8-3.1-3.3c-1.4-1.6-2.6-2.9-3.6-3.9s-2-1.9-3.1-2.8
									c0.8-0.9,1.7-1.7,2.8-2.3c0.5,0.8,1.3,1.8,2.4,3.2c1.1,1.3,2.1,2.6,3.2,3.8c1.6,1.7,3,3,4,4c-1.1-5.7-2-9.6-2.6-11.7l-1.5-1.3
									c0.7-0.9,1.7-1.6,2.8-2.3c0.5,0.8,1.3,1.8,2.4,3.1s2.1,2.6,3.2,3.9c1.8,1.8,3.1,3.2,4,4c-0.8-4.4-1.5-7.6-2-9.5
									c-0.5-2-0.9-3.3-1.2-3.9c-0.1-0.1-0.2-0.2-0.2-0.3c0.6-0.6,1.3-1.1,2.1-1.5c0.3,2,0.8,4.7,1.4,8.1c0.6,3.4,1.2,6.3,1.7,8.7
									l-0.5,1.6c-0.5,0.3-1.2,0.2-1.9-0.1s-1.4-0.9-2.2-1.7c-0.8-0.8-1.8-1.8-3.1-3.3c-1-1.1-1.9-2.2-2.8-3.1c0.5,2.5,1.2,6.1,2.1,11
									l-0.5,1.6C1220.7,641.7,1220.1,641.6,1219.4,641.3z"
						/>
						<path
							d="M1237.6,630.9c-1.3-0.6-2.4-1.8-3.4-3.6c-0.7-1.2-1.1-2.5-1.2-3.7c-0.1-1.2,0.1-2.3,0.6-3.3c0.5-1,1.2-1.8,2.2-2.3
									c1.4-0.8,2.7-0.8,3.9-0.2c1.2,0.6,2.4,1.8,3.5,3.8c-0.8,0.7-1.7,1.6-2.9,2.4c-1.2,0.9-2.4,1.7-3.6,2.4c0.7,1.1,1.5,1.8,2.2,2
									c0.8,0.3,1.6,0.1,2.5-0.4c0.7-0.4,1.3-0.8,1.8-1.4c0.5-0.5,0.9-1.1,1.2-1.6l0.5,0.4c-0.1,1-0.5,2-1.1,3c-0.6,0.9-1.4,1.7-2.3,2.2
									C1240.2,631.4,1238.8,631.5,1237.6,630.9z M1238.4,623.7c0.7-0.7,1.2-1.3,1.5-1.8c-0.6-1-1.3-1.7-2.1-2c-0.9-0.4-1.7-0.3-2.4,0.1
									c-0.3,0.6-0.4,1.4-0.3,2.4c0.1,1,0.5,2,1.1,3.1C1237,624.9,1237.7,624.3,1238.4,623.7z"
						/>
						<path
							d="M1250.1,624.9c-0.7-0.3-1.3-0.8-1.7-1.5c-1.3-2.2-0.1-4.7,3.7-7.2c-0.7-1.2-1.4-2-2-2.3c-0.6-0.3-1.3-0.2-2.2,0.3
									c-0.5,0.3-1,0.7-1.4,1.2c-0.4,0.5-0.8,1-1,1.6l-0.5-0.4c0-1.1,0.2-2.1,0.7-3.1c0.5-1,1.1-1.7,1.9-2.1c1.1-0.6,2.1-0.8,3.1-0.5
									c1,0.3,1.8,1.1,2.5,2.3c0.4,0.7,0.8,1.4,1.2,2.3c0.4,0.9,0.8,1.6,1,2c0.4,0.7,0.8,1.2,1.2,1.5s0.9,0.6,1.5,0.8
									c-0.3,1-0.7,1.7-1.4,2.2c-0.6-0.1-1.3-0.4-1.8-1c-0.3,0.8-0.7,1.5-1.2,2.2s-1,1.1-1.6,1.5C1251.4,625.1,1250.8,625.1,1250.1,624.9
									z M1253.4,621.6c0.3-0.5,0.6-1,0.8-1.5l-0.1-0.1c-0.3-0.6-0.7-1.2-0.9-1.8l-0.6-1.2c-1.1,0.7-1.8,1.5-2.2,2.3
									c-0.4,0.8-0.3,1.6,0.1,2.4c0.2,0.3,0.4,0.6,0.8,0.8c0.3,0.2,0.7,0.3,1,0.4C1252.6,622.5,1253,622.1,1253.4,621.6z"
						/>
						<path
							d="M1263.2,617.4c-0.4-0.2-0.9-0.7-1.4-1.5c-0.3-0.6-0.9-1.5-1.6-2.8c-0.8-1.4-1.5-2.6-2-3.5c-0.3-0.4-0.5-0.9-0.8-1.2
									c-0.7,0.4-1.1,0.6-1.4,0.6c-0.3,0-0.5-0.1-0.7-0.3c0.7-1.3,1.1-2.9,1.4-4.6l0.6-0.3c0.4,0.6,0.9,1.2,1.3,1.9
									c1.1-0.7,1.9-1.2,2.3-1.7c0.2,0.4,0.4,0.7,0.5,1.1c0.1,0.3,0.2,0.7,0.2,1.1c-0.6,0.1-1.4,0.4-2.3,0.9c0.4,0.9,0.9,1.7,1.5,2.6
									c1.4,2.3,2.5,3.9,3.5,4.9c0.7-0.5,1.3-1,1.7-1.4l0.5,0.5c0,0.7-0.2,1.4-0.5,2c-0.3,0.6-0.8,1.1-1.3,1.4
									C1264.1,617.5,1263.6,617.6,1263.2,617.4z"
						/>
						<path
							d="M1270.8,613.2c-1.3-2.4-2.7-4.8-4.2-7.1c-2-3.2-3.6-5.6-4.9-7.3c0.6-0.9,1.4-1.6,2.4-2.2c0.4,0.9,1.4,2.8,2.9,5.5
									c0.1-0.8,0.4-1.6,0.8-2.3c0.4-0.7,0.9-1.2,1.5-1.6c1.9-1.2,3.7-0.5,5.2,2l1,1.8l1.1,1.9c1.2,1.9,2.2,3.2,3,3.8
									c-0.4,0.4-0.8,0.7-1.2,1c-0.4,0.2-0.9,0.5-1.6,0.7c-1.5-2.7-2.9-5.1-4.3-7.3c-0.5-0.7-0.9-1.2-1.4-1.3c-0.5-0.2-1,0-1.6,0.3
									c-0.9,0.5-1.5,1.3-1.9,2.1c0.3,0.5,0.7,1.3,1.3,2.3c2.1,3.4,3.6,5.4,4.4,5.9c-0.4,0.4-0.8,0.7-1.3,1
									C1271.9,612.7,1271.4,612.9,1270.8,613.2z"
						/>
						<path
							d="M1283.6,603.6c-1.3-0.5-2.5-1.6-3.6-3.4c-0.8-1.2-1.2-2.4-1.4-3.6c-0.2-1.2,0-2.3,0.4-3.3s1.1-1.8,2.1-2.5
									c1.3-0.8,2.6-1,3.9-0.5c1.3,0.5,2.5,1.7,3.7,3.5c-0.7,0.8-1.6,1.7-2.7,2.6s-2.2,1.8-3.4,2.6c0.8,1,1.6,1.7,2.4,1.9
									c0.8,0.2,1.6,0,2.4-0.5c0.7-0.4,1.2-0.9,1.7-1.5c0.5-0.6,0.8-1.1,1.1-1.7l0.5,0.4c0,1-0.3,2.1-0.9,3c-0.6,1-1.3,1.8-2.2,2.3
									C1286.2,603.9,1284.9,604.1,1283.6,603.6z M1284,596.3c0.6-0.7,1.1-1.3,1.4-1.9c-0.6-0.9-1.4-1.6-2.3-1.9
									c-0.9-0.3-1.7-0.2-2.4,0.3c-0.3,0.6-0.3,1.5-0.1,2.5c0.2,1,0.6,2,1.3,3C1282.6,597.7,1283.3,597,1284,596.3z"
						/>
						<path
							d="M1296.7,596.7c-1.1-1.9-2.3-3.8-3.6-5.8c-0.7-1-1.2-1.7-1.6-2.2c-0.4-0.5-0.8-0.8-1.1-1.1c-0.3-0.2-0.7-0.4-1.2-0.5
									c0-0.5,0.2-0.9,0.4-1.3c0.2-0.4,0.4-0.6,0.7-0.8c0.9-0.6,1.8-0.3,2.8,1c0-0.9,0.2-1.6,0.4-2.3s0.6-1.3,1.1-1.9
									c0.3,0.2,0.7,0.6,1.1,1c0.4,0.5,0.8,0.9,1.1,1.4c-0.2,0.1-0.5,0.3-0.9,0.5c-0.5,0.3-1,0.7-1.3,1.2c-0.4,0.4-0.6,0.9-0.8,1.3
									l0.5,0.8c0.8,1.2,1.4,2.3,2,3.2c1.3,1.9,2.3,3.1,3.2,3.7c-0.4,0.4-0.8,0.8-1.2,1C1297.8,596.2,1297.3,596.4,1296.7,596.7z"
						/>
						<path
							d="M1305.9,590.1c-0.4-0.2-0.9-0.6-1.5-1.4c-0.4-0.5-1-1.4-1.8-2.7c-0.9-1.4-1.7-2.5-2.3-3.4c-0.3-0.4-0.6-0.8-0.9-1.2
									c-0.6,0.4-1.1,0.7-1.4,0.7c-0.3,0.1-0.5,0-0.7-0.3c0.6-1.4,0.9-2.9,1.1-4.7l0.6-0.3c0.5,0.6,0.9,1.1,1.4,1.8
									c1.1-0.8,1.8-1.4,2.2-1.8c0.3,0.4,0.5,0.7,0.6,1c0.1,0.3,0.2,0.7,0.3,1.1c-0.6,0.1-1.3,0.5-2.2,1.1c0.5,0.8,1.1,1.7,1.7,2.5
									c1.5,2.2,2.8,3.7,3.8,4.7c0.7-0.6,1.2-1.1,1.6-1.5l0.6,0.5c0.1,0.7-0.1,1.4-0.4,2c-0.3,0.7-0.7,1.2-1.2,1.5
									C1306.9,590.1,1306.4,590.2,1305.9,590.1z"
						/>
						<path
							d="M1313,583.7c-1.5-0.4-2.8-1.4-4-3c-0.8-1.1-1.4-2.3-1.6-3.5c-0.2-1.2-0.1-2.3,0.3-3.4c0.4-1.1,1.1-1.9,2.1-2.7
									c0.9-0.6,1.8-1,2.8-1s2,0.2,2.9,0.7c0.9,0.5,1.8,1.3,2.6,2.4c0.8,1.1,1.4,2.3,1.6,3.5c0.2,1.2,0.1,2.4-0.3,3.4s-1.2,2-2.2,2.7
									C1315.9,583.8,1314.5,584.1,1313,583.7z M1318.1,579c0-1-0.4-2.2-1.3-3.4c-0.8-1.1-1.7-1.8-2.7-2.3s-2-0.5-3-0.2
									c-0.9,0.7-1.4,1.5-1.4,2.6s0.4,2.2,1.2,3.4c1,1.4,2,2.2,3.1,2.7c1.1,0.4,2,0.3,2.8-0.2C1317.7,580.8,1318.1,580,1318.1,579z"
						/>
						<path
							d="M1326.5,576.1c-1.4-1.9-2.8-3.8-4.4-5.9c-0.7-0.9-1.3-1.6-1.7-2.1c-0.4-0.4-0.8-0.8-1.2-1s-0.8-0.4-1.3-0.5
									c0-0.5,0.1-0.9,0.3-1.3c0.2-0.4,0.4-0.7,0.6-0.8c0.7-0.5,1.4-0.4,2.2,0.4c0.1-0.7,0.3-1.4,0.6-2s0.7-1.1,1.2-1.5
									c1.3-1,2.5-1.3,3.8-0.9s2.6,1.4,3.9,3.1c1.4,1.8,2,3.5,2,5.1c-0.1,1.6-0.8,3-2.3,4.2c-0.8,0.6-1.6,1-2.3,1c1.5,1.8,2.7,3.2,3.7,4
									c-0.5,0.9-1.2,1.7-2.1,2.5C1328.8,579.4,1327.8,578,1326.5,576.1z M1330.5,569.4c0-1-0.5-2-1.4-3.2c-0.8-1.1-1.7-1.8-2.7-2.3
									c-1-0.5-1.9-0.6-2.8-0.4c-0.4,0.3-0.7,0.7-1.1,1.1c-0.3,0.4-0.6,0.9-0.8,1.3c0.2,0.3,0.6,0.8,1.1,1.5c1.7,2.2,2.6,3.5,2.9,3.9
									l0.7,0.9c0.5,0.1,1,0.2,1.5,0.1c0.5-0.1,0.9-0.3,1.3-0.5C1330.1,571.2,1330.5,570.4,1330.5,569.4z"
						/>
						<path
							d="M1329,546.4l0.5,0.1c-0.1,1.8,0.2,3.6,0.9,5.4c0.7,1.8,1.7,3.5,3,5.2c1.3,1.6,2.8,3,4.5,4c1.6,1,3.4,1.7,5.2,2l0,0.5
									c-2.3,0.1-4.4-0.3-6.4-1.2c-2-0.9-3.7-2.1-5.1-3.8c-1.4-1.7-2.3-3.6-2.7-5.7S1328.5,548.6,1329,546.4z"
						/>
						<path
							d="M1352.9,553.9l-3.7-2.3c-0.7,0.5-1.6,1.2-2.7,2.1c-1.1,0.9-1.8,1.6-2.1,2c0.5,1.2,1,2.6,1.7,4c-0.2,0.3-0.4,0.6-0.6,0.8
									c-0.3,0.3-0.6,0.5-1,0.6c-0.3-1.2-1.2-3.7-2.8-7.6s-2.8-7-3.8-9.1c0.3-0.4,0.6-0.7,0.9-1c0.3-0.2,0.6-0.4,1-0.6
									c2,1.3,4.8,3,8.4,5.1s6,3.4,7.1,3.9c-0.3,0.4-0.6,0.8-1,1.2C1353.9,553.3,1353.4,553.6,1352.9,553.9z M1347.4,550.5
									c-2.6-1.6-4.8-2.8-6.6-3.8c0.6,1.8,1.5,4.1,2.8,7c0.8-0.6,1.7-1.3,2.6-2.1C1346.8,551.1,1347.2,550.8,1347.4,550.5z"
						/>
						<path
							d="M1358.4,549.2c-1.4-1.7-2.9-3.4-4.5-5.2c-0.8-0.9-1.4-1.5-1.9-1.9c-0.5-0.4-0.9-0.7-1.2-0.9c-0.4-0.2-0.8-0.3-1.3-0.3
									c0-0.5,0-0.9,0.1-1.3c0.1-0.4,0.3-0.7,0.5-0.8c0.7-0.7,1.6-0.6,2.6,0.2c-0.1-0.8,0-1.6,0.3-2.3c0.3-0.7,0.6-1.3,1.1-1.8
									c1.2-1.1,2.5-1.2,3.9-0.4c-0.1-0.9-0.1-1.7,0.2-2.5c0.2-0.8,0.6-1.4,1.2-1.9c1.7-1.5,3.5-1.2,5.5,1l1.3,1.5c0.5,0.6,1,1.2,1.4,1.6
									c1.5,1.6,2.7,2.7,3.7,3.1c-0.3,0.5-0.6,0.9-1,1.2c-0.4,0.3-0.8,0.7-1.4,1c-1.7-2-3.5-4.1-5.6-6.3c-0.6-0.6-1.1-1-1.6-1
									c-0.5-0.1-1,0.1-1.5,0.6c-0.7,0.7-1.2,1.4-1.4,2.3c0.6,0.6,1.3,1.4,2.4,2.6c0.3,0.3,0.7,0.8,1.2,1.3c1.5,1.6,2.7,2.7,3.7,3.2
									c-0.3,0.4-0.6,0.8-1,1.2s-0.8,0.7-1.4,1c-1.7-2-3.5-4.1-5.6-6.3c-0.6-0.6-1.1-1-1.6-1c-0.5-0.1-1,0.1-1.5,0.6
									c-0.7,0.7-1.2,1.4-1.3,2.2l0.9,1c0.7,0.8,1.6,1.8,2.6,2.9c1.5,1.7,2.8,2.7,3.7,3.2c-0.3,0.5-0.7,0.9-1.1,1.3
									C1359.5,548.5,1359,548.8,1358.4,549.2z"
						/>
						<path
							d="M1374.3,532.4c-1.5-0.2-3-1-4.4-2.4c-1-1-1.7-2.1-2.1-3.3c-0.4-1.2-0.5-2.3-0.2-3.4s0.8-2.1,1.7-2.9
									c0.8-0.8,1.6-1.2,2.6-1.4c1-0.2,2-0.1,3,0.3c1,0.4,2,1,2.9,2c1,1,1.7,2.1,2.1,3.3c0.4,1.2,0.4,2.3,0.2,3.5c-0.3,1.1-0.9,2.1-1.8,3
									C1377.1,532.1,1375.8,532.6,1374.3,532.4z M1378.7,527c-0.1-1-0.7-2.1-1.8-3.1c-0.9-0.9-1.9-1.6-3-1.9c-1.1-0.3-2.1-0.2-3,0.2
									c-0.8,0.8-1.1,1.7-1,2.8c0.1,1.1,0.7,2.1,1.7,3.1c1.2,1.2,2.3,1.9,3.5,2.2c1.1,0.3,2,0,2.7-0.6
									C1378.5,528.9,1378.8,528,1378.7,527z"
						/>
						<path
							d="M1386.2,522.6c-1.4-1.6-3-3.2-4.8-4.9c-0.8-0.8-1.5-1.4-2-1.8c-0.5-0.4-0.9-0.7-1.3-0.8c-0.4-0.2-0.8-0.2-1.3-0.3
									c-0.1-0.5-0.1-0.9,0.1-1.3c0.1-0.4,0.3-0.7,0.5-0.9c0.7-0.7,1.6-0.7,2.6,0.1c-0.1-0.8-0.1-1.6,0.2-2.3c0.2-0.7,0.6-1.4,1.1-1.9
									c1.6-1.6,3.4-1.4,5.5,0.7l1.4,1.5c0.5,0.6,1,1.1,1.5,1.6c1.6,1.5,2.9,2.5,3.9,2.9c-0.3,0.5-0.6,0.9-0.9,1.2
									c-0.4,0.4-0.8,0.7-1.4,1.1c-1.8-1.9-3.7-3.9-5.9-6c-0.6-0.6-1.2-0.9-1.6-1c-0.5,0-1,0.2-1.5,0.7c-0.7,0.8-1.2,1.6-1.3,2.4l1,1
									c0.7,0.8,1.7,1.7,2.7,2.8c1.6,1.6,2.9,2.6,3.9,3c-0.3,0.5-0.7,0.9-1,1.3C1387.2,521.8,1386.8,522.2,1386.2,522.6z"
						/>
						<path
							d="M1401.8,505.3c-0.3-0.3-0.6-0.6-0.8-0.9c-0.2-0.3-0.4-0.6-0.5-1c0.7,0,1.4-0.1,2.1-0.5s1.4-0.9,2-1.5
									c0.7-0.8,1.1-1.5,1.1-2.2c0-0.7-0.2-1.4-0.8-1.9c-0.5-0.5-1.1-0.7-1.8-0.6c-0.7,0.1-1.7,0.3-2.9,0.7c-1.1,0.3-2,0.6-2.7,0.7
									s-1.5,0.1-2.2,0c-0.8-0.2-1.5-0.5-2.2-1.2c-0.9-0.9-1.3-1.9-1.1-3.2c0.2-1.3,0.8-2.6,2.1-3.9c1.1-1.2,2.2-2,3.4-2.5
									c0.3,0.3,0.7,0.7,1,1.3s0.6,1,0.7,1.4c-1,0.2-2,0.4-2.9,0.7c-0.9,0.3-1.5,0.7-1.9,1.1c-0.2,0.4-0.3,0.8-0.2,1.4
									c0,0.6,0.3,1.1,0.7,1.5c0.6,0.5,1.2,0.8,2,0.7c0.7-0.1,1.7-0.3,3-0.7c1.1-0.3,1.9-0.6,2.6-0.7c0.7-0.1,1.4-0.1,2.2,0
									c0.8,0.1,1.5,0.5,2.1,1.1c1.1,1,1.6,2.2,1.6,3.5s-0.7,2.6-2,4C1404.7,503.8,1403.3,504.8,1401.8,505.3z"
						/>
						<path
							d="M1402.1,483.3c-0.3-1.7-0.7-3.2-1.1-4.5c0.3-0.7,0.7-1.5,1.4-2.2c1.1,0.3,2,0.5,2.6,0.6c0.6,0.1,1.3,0.2,2.2,0.2
									c-0.3,0.8-0.8,1.6-1.5,2.3l-3.2-0.6l1,3.1C1403,482.6,1402.6,483,1402.1,483.3z M1409.7,491.9l-1.5-1.4l-1.6-1.5
									c-1.6-1.4-2.8-2.3-3.7-2.6c0.3-1,0.9-1.9,1.7-2.8c1.9,1.8,4.1,3.9,6.6,6.2c0.6,0.6,1.2,0.9,1.7,0.9c0.5,0,1-0.2,1.5-0.8
									c0.6-0.7,1-1.5,1.2-2.4c-1.1-1-2-1.8-2.8-2.6l-1.3-1.2c-1.6-1.4-2.8-2.3-3.7-2.6c0.3-1,0.9-1.9,1.7-2.8c1,1,2.3,2.3,3.8,3.7
									s2.4,2.4,2.9,2.8c0.6,0.5,1.1,0.9,1.5,1.1s1,0.3,1.7,0.3c0,0.9-0.2,1.8-0.7,2.5c-0.7,0.1-1.5,0-2.2-0.5c0.1,0.8,0,1.6-0.2,2.3
									c-0.2,0.8-0.6,1.4-1.1,2C1413.8,494,1411.9,493.9,1409.7,491.9z"
						/>
						<path
							d="M1423.1,482.7c-0.4-0.1-1-0.4-1.8-1.1c-0.5-0.4-1.3-1.2-2.4-2.1c-1.2-1.1-2.2-2-3-2.7c-2.6-2.3-4.6-3.8-5.9-4.5
									c0.2-0.6,0.4-1.1,0.6-1.5s0.6-0.9,1-1.3c1.8,1.9,4.6,4.5,8.4,7.8c0.9,0.8,1.6,1.3,2.1,1.7c0.5,0.4,1,0.6,1.3,0.7
									c0.4,0.1,0.8,0.2,1.3,0.2c0.1,0.5,0.1,0.9,0,1.3c-0.1,0.4-0.2,0.7-0.4,0.9C1423.9,482.5,1423.5,482.7,1423.1,482.7z"
						/>
						<path
							d="M1418.3,463.9c2.1,0.5,4,1.4,5.7,2.8c1.6,1.4,2.9,3.1,3.7,5.2c0.8,2,1.1,4.2,1,6.5l-0.5,0c-0.2-1.8-0.8-3.5-1.8-5.2
									c-1-1.7-2.3-3.2-3.9-4.6s-3.3-2.4-5.1-3.2c-1.8-0.7-3.6-1.1-5.4-1l-0.1-0.5C1413.9,463.4,1416.1,463.4,1418.3,463.9z"
						/>
					</g>
					<g className="level_one location" id="rhudaur">
						<rect x="4007.971" y="2011.028" width="260.246" height="44.243" transform="matrix(0.906915, -0.421314, 0.41039, 0.91199, -3149.063232, 449.649658)"></rect>
						<text fontFamily="Arbutus Slab" fontSize="32px" letterSpacing="5.1px" transform="matrix(0.915115, -0.403194, 0.403194, 0.915115, -129.183075, 609.173523)" x="1336.011" y="602.006">Rh</text>
						<text fontFamily="Arbutus Slab" fontSize="32px" letterSpacing="5.1px" transform="matrix(0.908163, -0.418616, 0.418616, 0.908163, -57.650475, 600.813354)" x="1336.01" y="602.006">udaur</text>
					</g>
					<g className="regions level_two location" id="trollshaws">
						<rect x="923.04" y="1049.939" width="59.935" height="23.099" transform="matrix(0.937893, -0.346924, 0.335628, 0.942072, 185.85907, -71.463028)"></rect>
						<rect x="923.041" y="1049.94" width="59.935" height="23.099" transform="matrix(0.872634, 0.488375, -0.498885, 0.866752, 1179.85144, -784.599243)"></rect>
						<path
							d="M1414.6,609.4c-0.3-0.8-0.8-1.8-1.4-3.1c-0.6-1.3-1.3-2.6-1.9-3.8c-0.7-1.3-1.3-2.4-1.9-3.3c-1.7,0.9-2.7,1.5-3.1,2
									c-0.2-0.3-0.4-0.6-0.6-0.9c-0.1-0.3-0.3-0.6-0.5-1.1c0.6-0.2,1.3-0.5,2.2-1c0.9-0.4,1.8-0.9,2.7-1.3c1.9-1,3.1-1.7,3.5-2.2
									c0.2,0.3,0.5,0.6,0.6,0.9c0.1,0.2,0.3,0.6,0.4,1.1c-0.8,0.3-1.9,0.8-3.3,1.5c0.9,2,1.8,3.7,2.5,5.1c1.4,2.8,2.4,4.4,3,5
									c-0.4,0.3-0.7,0.5-1.1,0.7C1415.4,609.1,1415,609.3,1414.6,609.4z"
						/>
						<path
							d="M1423,605.4c-0.5-1.6-1.2-3.3-1.9-5.1c-0.4-0.9-0.6-1.5-0.9-2c-0.2-0.4-0.5-0.8-0.7-1s-0.5-0.4-0.9-0.6
									c0.1-0.4,0.3-0.7,0.5-0.9c0.2-0.3,0.4-0.4,0.6-0.5c0.8-0.3,1.5,0.1,2,1.2c0.2-0.7,0.4-1.2,0.7-1.7c0.3-0.5,0.7-0.9,1.1-1.3
									c0.2,0.2,0.4,0.5,0.7,1c0.3,0.4,0.5,0.9,0.6,1.3c-0.2,0-0.4,0.1-0.7,0.2c-0.5,0.2-0.9,0.4-1.2,0.7c-0.4,0.3-0.6,0.6-0.8,0.8
									l0.3,0.7c0.4,1.1,0.8,2,1.1,2.8c0.7,1.7,1.3,2.8,1.9,3.4c-0.4,0.3-0.8,0.5-1.1,0.6S1423.5,605.3,1423,605.4z"
						/>
						<path
							d="M1430.6,601.2c-1-0.7-1.7-1.8-2.2-3.3c-0.3-1.1-0.4-2.1-0.2-3.1c0.2-1,0.5-1.8,1.1-2.5c0.6-0.7,1.4-1.2,2.3-1.4
									c0.8-0.2,1.6-0.2,2.4,0c0.8,0.2,1.4,0.7,2,1.3s1,1.5,1.3,2.4c0.3,1.1,0.4,2.1,0.2,3.1c-0.2,1-0.6,1.8-1.2,2.5
									c-0.6,0.7-1.4,1.2-2.4,1.4C1432.7,602.1,1431.6,601.9,1430.6,601.2z M1435.7,599c0.3-0.8,0.3-1.7-0.1-2.9c-0.3-1-0.8-1.8-1.4-2.4
									c-0.6-0.6-1.4-0.9-2.2-1c-0.9,0.3-1.4,0.8-1.8,1.6c-0.3,0.8-0.3,1.7,0,2.8c0.4,1.3,0.9,2.2,1.6,2.8c0.7,0.6,1.4,0.8,2.1,0.6
									C1434.9,600.3,1435.4,599.8,1435.7,599z"
						/>
						<path
							d="M1442.6,599c-0.2-0.3-0.4-0.8-0.6-1.6c-0.1-0.5-0.3-1.3-0.5-2.5c-0.2-1.3-0.4-2.3-0.6-3.1c-0.5-2.7-1.1-4.6-1.6-5.7
									c0.4-0.3,0.8-0.5,1.1-0.6c0.4-0.2,0.8-0.3,1.3-0.4c0.2,2.1,0.7,5.1,1.5,8.9c0.2,0.9,0.3,1.6,0.5,2.1c0.2,0.5,0.3,0.9,0.5,1.1
									c0.2,0.3,0.4,0.5,0.7,0.8c-0.2,0.3-0.4,0.6-0.7,0.8s-0.5,0.3-0.7,0.4C1443.2,599.4,1442.8,599.3,1442.6,599z"
						/>
						<path
							d="M1449,597.8c-0.2-0.3-0.4-0.8-0.5-1.6c-0.1-0.5-0.1-1.4-0.2-2.5c-0.1-1.3-0.2-2.3-0.3-3.2c-0.3-2.7-0.7-4.7-1.1-5.8
									c0.4-0.2,0.8-0.4,1.2-0.5c0.4-0.1,0.8-0.2,1.3-0.3c0,2.1,0.3,5.1,0.7,9c0.1,0.9,0.2,1.6,0.3,2.1c0.1,0.5,0.2,0.9,0.4,1.2
									s0.4,0.5,0.7,0.8c-0.2,0.3-0.5,0.6-0.7,0.8c-0.3,0.2-0.5,0.3-0.8,0.3C1449.5,598.2,1449.2,598.1,1449,597.8z"
						/>
						<path
							d="M1454,596.9c0-0.3,0.1-0.6,0.1-0.9c0.1-0.3,0.2-0.6,0.4-0.9c0.3,0.4,0.6,0.8,1.1,1.1c0.5,0.3,1,0.4,1.5,0.4
									c1.5,0.1,2.2-0.3,2.2-1.2c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.8-0.6-1.5-0.9c-0.7-0.4-1.2-0.7-1.6-1s-0.7-0.7-1-1.1
									c-0.3-0.5-0.4-1-0.4-1.7c0-0.8,0.4-1.4,1-1.9c0.7-0.5,1.5-0.7,2.5-0.6c1.4,0.1,2.5,0.3,3.5,0.8c0,0.3-0.1,0.6-0.3,1.1
									c-0.2,0.4-0.3,0.8-0.6,1.2c-0.5-0.6-1-1.1-1.5-1.4c-0.5-0.4-0.9-0.5-1.3-0.6c-0.4,0.1-0.7,0.2-0.9,0.4c-0.2,0.2-0.4,0.5-0.4,0.7
									c0,0.5,0.1,0.9,0.5,1.2c0.3,0.3,0.9,0.7,1.6,1.1c0.6,0.3,1.1,0.6,1.5,0.9s0.7,0.6,1,1.1c0.3,0.4,0.4,1,0.3,1.6
									c0,1-0.4,1.7-1.1,2.2c-0.7,0.5-1.7,0.7-2.9,0.6C1456,597.9,1454.9,597.5,1454,596.9z"
						/>
						<path
							d="M1464,598.3c0.8-2,1.5-4.1,2.1-6.2c0.9-2.8,1.5-5.1,1.9-6.7c0.8-0.1,1.7,0,2.6,0.3c-0.3,0.7-0.9,2.3-1.8,4.6
									c0.6-0.4,1.2-0.6,1.8-0.7c0.6-0.1,1.2-0.1,1.7,0.1c1.7,0.5,2.2,1.9,1.5,4.2l-0.5,1.5l-0.6,1.6c-0.5,1.7-0.8,3-0.7,3.8
									c-0.4,0-0.8-0.1-1.2-0.2c-0.4-0.1-0.8-0.3-1.2-0.6c0.8-2.3,1.6-4.4,2.2-6.3c0.2-0.6,0.2-1.2,0.1-1.5c-0.2-0.4-0.5-0.6-1-0.8
									c-0.8-0.2-1.5-0.3-2.3,0c-0.2,0.5-0.4,1.1-0.7,2c-1,3-1.4,4.9-1.3,5.7c-0.4,0-0.8-0.1-1.3-0.2
									C1464.9,598.7,1464.5,598.6,1464,598.3z"
						/>
						<path
							d="M1475.4,602.2c-0.1-0.6,0-1.2,0.3-1.8c1-1.8,3.1-2,6.4-0.5c0.5-1,0.8-1.8,0.7-2.3c-0.1-0.5-0.4-1-1.1-1.3
									c-0.4-0.2-0.9-0.4-1.4-0.4c-0.5-0.1-1-0.1-1.5,0l0.1-0.5c0.7-0.5,1.5-0.7,2.3-0.8c0.8-0.1,1.6,0,2.2,0.4c0.9,0.5,1.4,1.1,1.6,1.9
									c0.2,0.8,0,1.6-0.5,2.6c-0.3,0.5-0.6,1.1-1.1,1.8c-0.4,0.7-0.7,1.2-0.9,1.6c-0.3,0.6-0.5,1-0.5,1.4c-0.1,0.4,0,0.8,0.1,1.4
									c-0.8,0.2-1.5,0.2-2.1,0c-0.2-0.5-0.3-1-0.1-1.6c-0.7,0.1-1.4,0.1-2,0.1s-1.2-0.2-1.6-0.4C1475.9,603.2,1475.6,602.7,1475.4,602.2
									z M1479,603c0.5,0,0.9,0,1.4-0.1l0.1-0.1c0.3-0.5,0.5-0.9,0.8-1.4l0.6-0.9c-1-0.4-1.8-0.6-2.5-0.5c-0.7,0.1-1.2,0.5-1.5,1.1
									c-0.1,0.3-0.2,0.5-0.2,0.9c0,0.3,0.1,0.6,0.2,0.8C1478.1,602.9,1478.5,603,1479,603z"
						/>
						<path
							d="M1486.9,606.8l0.9-3.1c0.2-0.7,0.4-1.4,0.6-2c0.2-0.7,0.3-1.4,0.4-2s0.1-1,0-1.3c0.4,0,0.9,0.1,1.2,0.3
									c0.4,0.1,0.8,0.3,1.3,0.6c-0.3,0.7-0.8,2.2-1.4,4.5c-0.7,2.3-1,3.8-1.1,4.3c2.9-2.6,4.7-4.3,5.3-5c0.1-0.7,0.1-1.2,0-1.6
									c0.8,0.1,1.7,0.4,2.6,0.9c-0.3,0.7-0.8,2.2-1.5,4.7c-0.7,2.5-1.1,3.8-1.1,4.2c2.1-1.9,3.6-3.2,4.4-4c0.8-0.8,1.3-1.4,1.6-1.8
									c0.1-0.1,0.2-0.3,0.2-0.6c0.6,0.1,1.2,0.4,1.9,0.8c-0.9,0.7-2.1,1.7-3.5,2.9s-2.9,2.6-4.4,4.1l-1.5,0.1c-0.4-0.2-0.6-0.6-0.7-1
									c-0.1-0.5,0-1,0.1-1.7c0.6-2.2,1.1-3.8,1.3-4.7c-1.2,1-2.7,2.4-4.6,4.3l-1.5,0.1C1486.7,609.1,1486.5,608.2,1486.9,606.8z"
						/>
						<path
							d="M1498.8,615.3c0.2-0.3,0.4-0.5,0.6-0.7s0.5-0.4,0.8-0.6c0,0.5,0.2,1,0.5,1.5c0.3,0.5,0.7,0.8,1.1,1.1
									c1.2,0.8,2.1,0.8,2.6,0c0.2-0.3,0.2-0.7,0.1-1.1c-0.1-0.4-0.4-0.9-0.8-1.5c-0.4-0.6-0.7-1.2-0.9-1.6s-0.3-0.9-0.3-1.5
									c0-0.6,0.2-1.1,0.5-1.7c0.4-0.7,1-1.1,1.8-1.1c0.8-0.1,1.6,0.2,2.5,0.7c1.2,0.7,2,1.5,2.6,2.4c-0.1,0.2-0.4,0.5-0.8,0.8
									c-0.4,0.3-0.7,0.6-1.1,0.8c-0.2-0.8-0.4-1.4-0.7-2c-0.3-0.6-0.5-0.9-0.8-1.1c-0.4-0.1-0.7-0.1-1-0.1c-0.3,0.1-0.5,0.2-0.7,0.5
									c-0.3,0.4-0.3,0.8-0.2,1.3c0.1,0.4,0.4,1,0.9,1.7c0.4,0.6,0.7,1.1,0.9,1.5c0.2,0.4,0.3,0.9,0.3,1.4c0,0.5-0.1,1-0.5,1.6
									c-0.5,0.8-1.2,1.3-2,1.3c-0.9,0.1-1.8-0.2-2.9-0.9C1500,617.2,1499.2,616.3,1498.8,615.3z"
						/>
					</g>
					<g className="level_two fade location " id="last_bridge">
						<rect x="1121.323" y="1144.003" width="72.81" height="25.168" transform="matrix(0.990984, 0.133984, -0.14592, 0.98937, 425.788452, -642.922119)"></rect>
						<rect x="1237.12" y="1144.003" width="80.329" height="25.168" transform="matrix(0.990984, 0.133984, -0.14592, 0.98937, 315.678894, -633.206177)"></rect>
						<path
							d="M1374.4,655.8c0.1-0.9,0.2-2.2,0.3-4c0.2-3.7,0.2-5.9-0.1-6.8c0.5-0.1,1-0.1,1.5-0.1c0.4,0,1,0.1,1.6,0.3
									c-0.2,1.1-0.3,2.5-0.5,4.2c-0.2,1.7-0.3,3.3-0.4,4.8c-0.1,2.1-0.2,3.5-0.1,4.3c0.8,0.2,1.7,0.4,2.8,0.4c0.6,0,1.3,0,1.9-0.1
									c0.6-0.1,1.2-0.3,1.6-0.5c0,0.6-0.1,1.2-0.3,1.8s-0.3,1-0.5,1.2c-1.2-0.2-2.6-0.4-4.1-0.5c-1.9-0.1-3.3-0.1-4.3,0
									C1374.2,658.4,1374.4,656.7,1374.4,655.8z"
						/>
						<path
							d="M1391.3,661.6c-0.4-0.6-0.5-1.3-0.4-2.1c0.3-2.4,2.5-3.5,6.8-3.3c0.1-1.3,0.1-2.2-0.2-2.8c-0.3-0.6-0.9-0.9-1.8-1
									c-0.6-0.1-1.1,0-1.7,0.1c-0.6,0.1-1.1,0.3-1.6,0.6l-0.2-0.6c0.6-0.8,1.3-1.5,2.2-1.9c0.9-0.5,1.8-0.6,2.6-0.5
									c1.2,0.1,2,0.6,2.6,1.3c0.5,0.8,0.8,1.8,0.6,3.1c-0.1,0.7-0.2,1.5-0.4,2.4c-0.2,0.9-0.3,1.6-0.3,2.1c-0.1,0.8-0.1,1.3,0,1.8
									c0.1,0.4,0.3,0.9,0.7,1.5c-0.7,0.6-1.5,0.9-2.3,0.9c-0.4-0.4-0.7-1-0.8-1.8c-0.7,0.4-1.4,0.7-2.1,1s-1.4,0.3-2,0.2
									C1392.2,662.5,1391.7,662.2,1391.3,661.6z M1395.6,661c0.5-0.2,1-0.4,1.4-0.7l0-0.1c0.1-0.6,0.2-1.2,0.3-1.8l0.2-1.3
									c-1.2-0.1-2.2,0.1-2.9,0.5c-0.7,0.4-1.1,1-1.2,1.8c0,0.3,0,0.7,0.1,1c0.1,0.3,0.3,0.6,0.6,0.8C1394.5,661.3,1395,661.2,1395.6,661
									z"
						/>
						<path
							d="M1408.5,663.5c0.1-0.4,0.2-0.7,0.3-1c0.1-0.3,0.3-0.7,0.6-1c0.3,0.5,0.6,1,1.2,1.4c0.5,0.4,1.1,0.6,1.7,0.7
									c1.7,0.3,2.6-0.1,2.8-1.2c0.1-0.4-0.1-0.8-0.4-1.2c-0.3-0.4-0.9-0.8-1.6-1.3c-0.7-0.5-1.3-1-1.7-1.4c-0.4-0.4-0.8-0.9-1-1.5
									s-0.3-1.3-0.2-2c0.2-0.9,0.6-1.6,1.5-2.1c0.8-0.5,1.8-0.6,3-0.4c1.6,0.3,2.9,0.7,3.9,1.4c-0.1,0.3-0.2,0.7-0.4,1.2
									c-0.2,0.5-0.5,0.9-0.8,1.3c-0.5-0.8-1.1-1.4-1.6-1.9c-0.5-0.5-1-0.8-1.4-0.8c-0.4,0-0.8,0.2-1.1,0.4c-0.3,0.2-0.5,0.5-0.5,0.8
									c-0.1,0.6,0,1,0.4,1.4c0.4,0.4,0.9,0.9,1.7,1.5c0.7,0.5,1.2,0.9,1.6,1.3c0.4,0.4,0.8,0.9,1,1.4c0.2,0.6,0.3,1.2,0.2,1.9
									c-0.2,1.1-0.7,1.9-1.6,2.4c-0.9,0.5-2.1,0.6-3.5,0.4C1410.7,665,1409.4,664.4,1408.5,663.5z"
						/>
						<path
							d="M1427.1,667.4c-0.2-0.4-0.2-1,0-1.9c0.1-0.6,0.4-1.6,0.7-2.9c0.4-1.5,0.6-2.7,0.8-3.7c0.1-0.5,0.2-0.9,0.2-1.4
									c-0.7-0.2-1.2-0.3-1.4-0.5c-0.2-0.2-0.3-0.4-0.2-0.6c1.3-0.5,2.6-1.2,3.9-2.3l0.6,0.2c-0.1,0.7-0.2,1.4-0.3,2.1
									c1.2,0.2,2.1,0.3,2.6,0.3c-0.1,0.4-0.2,0.8-0.3,1.1c-0.1,0.3-0.3,0.6-0.5,0.9c-0.5-0.3-1.2-0.6-2.1-0.8c-0.2,0.9-0.5,1.8-0.7,2.7
									c-0.5,2.4-0.8,4.3-0.8,5.6c0.8,0.1,1.5,0.1,2,0.1l0,0.7c-0.4,0.5-1,0.8-1.6,1c-0.6,0.2-1.2,0.3-1.8,0.1
									C1427.6,668,1427.2,667.8,1427.1,667.4z"
						/>
						<path
							d="M1377.8,685c-0.9-0.2-1.7-0.5-2.3-0.8c0.2-0.9,0.3-2.2,0.5-3.9s0.4-3.4,0.5-5c0.3-3.1,0.3-5.1,0.1-5.8
									c0.7-0.2,1.5-0.3,2.5-0.4c1-0.1,1.9,0,2.9,0c1.7,0.2,3,0.6,3.8,1.5c0.8,0.8,1.2,1.9,1.1,3.2c-0.1,0.7-0.3,1.4-0.8,1.9
									c-0.5,0.6-1.1,1-1.8,1.3c0.9,0.4,1.6,1,2,1.7c0.5,0.7,0.6,1.6,0.6,2.4c-0.1,1.5-0.8,2.6-1.8,3.3c-1.1,0.7-2.6,1-4.5,0.8
									C1379.6,685.4,1378.7,685.2,1377.8,685z M1384.3,681.1c0.1-0.7-0.1-1.4-0.4-2c-0.3-0.6-0.8-1.1-1.5-1.4c-0.8-0.1-2.1,0.1-3.8,0.5
									c-0.2,2.4-0.3,4.1-0.3,5.3c0.6,0.3,1.3,0.5,2.1,0.6C1382.8,684.3,1384.1,683.3,1384.3,681.1z M1381.3,676.4c0.5,0,0.9,0.1,1.1,0.2
									c1.2-0.7,1.8-1.6,1.9-2.5c0.2-1.7-0.8-2.7-2.8-2.9c-0.7-0.1-1.4,0-2,0.1c-0.2,1.5-0.4,3.2-0.7,5.2
									C1379.5,676.3,1380.3,676.3,1381.3,676.4z"
						/>
						<path
							d="M1391.9,686.2c0.3-2,0.6-4.1,0.9-6.3c0.1-1.1,0.2-1.9,0.2-2.5s0-1.1-0.1-1.4c-0.1-0.4-0.3-0.7-0.5-1.1
									c0.3-0.3,0.7-0.6,1-0.7c0.4-0.1,0.7-0.2,0.9-0.2c1,0.1,1.5,0.9,1.4,2.4c0.5-0.6,1.1-1.1,1.7-1.4c0.6-0.3,1.2-0.6,1.9-0.7
									c0.1,0.3,0.2,0.8,0.2,1.4c0,0.6,0,1.2,0,1.7c-0.2-0.1-0.5-0.1-0.9-0.2c-0.6-0.1-1.1,0-1.7,0c-0.5,0.1-1,0.2-1.3,0.4l-0.1,0.9
									c-0.2,1.3-0.3,2.5-0.4,3.5c-0.2,2.1-0.2,3.6,0,4.5c-0.6,0.1-1,0.1-1.5,0C1393,686.5,1392.5,686.4,1391.9,686.2z"
						/>
						<path
							d="M1402.7,687.2c-0.2-0.4-0.2-1-0.1-1.9c0.1-0.7,0.2-1.8,0.4-3.1c0.2-1.3,0.3-2.2,0.4-2.8c0.1-0.8,0.1-1.6,0.1-2.3
									c0-0.7-0.1-1.2-0.2-1.5c0.5-0.2,1-0.3,1.5-0.3c0.5,0,1,0,1.6,0.1c-0.2,0.6-0.3,1.4-0.6,2.6c-0.2,1.2-0.4,2.5-0.6,4
									c-0.1,1.1-0.2,1.9-0.2,2.5c0,0.6,0,1.1,0.1,1.4c0.1,0.4,0.3,0.7,0.5,1.1c-0.3,0.3-0.7,0.6-1,0.7s-0.7,0.2-0.9,0.2
									C1403.2,687.8,1402.8,687.6,1402.7,687.2z M1403.8,671.2c0.6-0.7,1.2-1.2,1.9-1.6c0.4,0.7,0.9,1.4,1.4,2c-0.6,0.6-1.2,1.2-1.9,1.6
									C1404.8,672.6,1404.3,671.9,1403.8,671.2z"
						/>
						<path
							d="M1411.1,687.3c-0.6-1.2-0.7-2.8-0.5-4.9c0.3-2.1,0.9-3.7,1.8-4.7c0.9-1,2.1-1.4,3.6-1.2c1.2,0.2,2.2,0.7,3.1,1.6
									c0.2-2,0.2-3.5,0.1-4.5c0.5-0.1,1.1-0.2,1.5-0.3c0.5,0,1,0,1.5,0.1c-0.6,2.4-1.2,6.6-2.1,12.5c-0.1,0.7-0.1,1.3,0,1.7
									c0.1,0.4,0.3,0.9,0.6,1.4c-0.7,0.5-1.5,0.8-2.3,0.8c-0.4-0.5-0.7-1.1-0.8-1.8c-0.6,0.5-1.2,0.8-1.8,1c-0.6,0.2-1.3,0.3-1.8,0.2
									C1412.7,689.1,1411.7,688.4,1411.1,687.3z M1416.4,687.7c0.5-0.2,1-0.4,1.4-0.7c0.1-0.6,0.2-1.6,0.5-3.1c0.3-1.6,0.5-2.9,0.6-3.8
									c-0.6-0.6-1.4-1-2.4-1.1c-0.9-0.1-1.7,0.1-2.3,0.8c-0.6,0.7-1,1.7-1.2,3.1c-0.2,1.4-0.1,2.5,0.2,3.4c0.4,0.9,0.9,1.5,1.8,1.7
									C1415.5,688,1415.9,687.9,1416.4,687.7z"
						/>
						<path
							d="M1424.1,694.5c0.1-0.7,0.3-1.4,0.7-2c0.4-0.6,0.8-1.2,1.3-1.7c-0.6-0.4-0.9-1-0.8-1.8c0.1-0.6,0.3-1,0.8-1.4
									c0.4-0.4,1-0.7,1.7-0.8c-0.6-0.4-1-1-1.2-1.7c-0.2-0.7-0.3-1.5-0.2-2.3c0.2-1.4,0.8-2.4,1.8-3.2c1-0.7,2.2-1,3.6-0.8
									c0.9,0.1,1.7,0.5,2.3,1c0.4,0,0.8-0.2,1.3-0.4c0.5-0.2,1-0.5,1.5-0.9c0.1,0.4,0.2,0.8,0.3,1.3c0.1,0.5,0.1,0.9,0,1.2
									c-0.6,0.2-1.4,0.3-2.2,0.3c0.2,0.8,0.3,1.6,0.2,2.4c-0.1,0.9-0.5,1.7-1,2.3c-0.5,0.6-1.2,1.1-2,1.4c-0.8,0.3-1.7,0.3-2.6,0.2
									c-0.8-0.1-1.4-0.1-1.9,0.1c-0.5,0.2-0.8,0.5-0.8,1l5,1.1c0.9,0.2,1.6,0.7,2.1,1.2c0.4,0.6,0.6,1.3,0.4,2.1c-0.1,0.8-0.5,1.5-1.1,2
									c-0.6,0.6-1.3,1-2.2,1.2c-0.9,0.2-1.9,0.3-2.9,0.1C1426.5,696.2,1425.1,695.5,1424.1,694.5z M1431.6,694.5c0.6-0.2,1-0.6,1.1-1.1
									c0.1-0.4,0-0.7-0.2-0.9c-0.2-0.2-0.6-0.4-1.2-0.5l-4-0.9c-0.4,0.5-0.6,1-0.8,1.5c0.5,1.1,1.5,1.8,2.8,2
									C1430.2,694.8,1431,694.8,1431.6,694.5z M1432.1,686.1c0.5-0.4,0.8-1,0.9-1.8c0.1-0.7,0-1.3-0.3-1.9s-0.8-1-1.4-1.3
									c-0.7-0.1-1.4,0.1-1.9,0.5c-0.6,0.4-0.9,1.1-1,1.9c-0.1,0.8,0,1.4,0.3,2c0.4,0.5,0.9,0.9,1.5,1
									C1431,686.6,1431.7,686.4,1432.1,686.1z"
						/>
						<path
							d="M1439.8,691.6c-0.6-1.2-0.7-2.7-0.4-4.6c0.2-1.3,0.7-2.4,1.3-3.3c0.6-0.9,1.4-1.6,2.3-2c0.9-0.4,1.9-0.5,3-0.4
									c1.4,0.2,2.4,1,3,2.1s0.7,2.7,0.3,4.8c-1,0.1-2.2,0.1-3.5,0.1c-1.4,0-2.7-0.2-4-0.4c-0.1,1.2,0,2.1,0.4,2.8c0.4,0.6,1.1,1,2,1.2
									c0.7,0.1,1.4,0.2,2.1,0.1c0.7-0.1,1.3-0.2,1.8-0.5l0.1,0.6c-0.7,0.7-1.5,1.2-2.5,1.5c-1,0.3-2,0.4-2.9,0.2
									C1441.4,693.5,1440.4,692.8,1439.8,691.6z M1444.7,686.9c0.9-0.1,1.6-0.2,2.2-0.4c0.2-1,0-1.9-0.4-2.7c-0.4-0.8-1-1.2-1.8-1.4
									c-0.6,0.3-1.2,0.8-1.6,1.6c-0.5,0.8-0.8,1.8-1,2.9C1442.9,687,1443.8,687,1444.7,686.9z"
						/>
					</g>
					<g className="level_two fade location " id="bruinen">
						<rect x="2017.056" y="2703.039" width="130.972" height="59.466" transform="matrix(0.758032, -0.652218, 0.643087, 0.765888, -1874.358032, -12.355774)"></rect>
						<path
							d="M1414.6,755.2c-0.8,0.6-1.6,1-2.2,1.3c-0.6-0.7-1.5-1.8-2.8-3.1s-2.5-2.7-3.7-3.9c-2.4-2.4-3.9-3.8-4.7-4.1
									c0.3-0.7,0.8-1.4,1.4-2.3c0.6-0.8,1.3-1.6,2-2.3c1.3-1.3,2.6-2,3.9-2.1c1.3-0.1,2.4,0.3,3.4,1.4c0.6,0.6,0.9,1.2,1,2
									c0.1,0.8,0.1,1.6-0.2,2.4c1-0.4,1.9-0.6,2.8-0.4c0.9,0.1,1.7,0.6,2.4,1.3c1.1,1.2,1.6,2.4,1.4,3.8s-1,2.8-2.5,4.2
									C1416.2,753.9,1415.4,754.6,1414.6,755.2z M1410,746.3c0.4-0.4,0.7-0.7,0.9-0.8c0.2-1.4,0-2.5-0.8-3.3c-1.3-1.3-2.7-1.2-4.3,0.3
									c-0.6,0.6-1,1.1-1.3,1.7c1.1,1.2,2.3,2.6,3.8,4.2C1408.7,747.7,1409.3,747,1410,746.3z M1416,747.2c-0.6-0.6-1.2-0.9-1.9-1.1
									c-0.7-0.2-1.4-0.1-2.2,0.2c-0.6,0.6-1.4,1.8-2.3,3.5c1.8,1.8,3.2,3.2,4.1,4c0.7-0.3,1.3-0.7,2-1.3
									C1417.5,750.6,1417.6,748.9,1416,747.2z"
						/>
						<path
							d="M1426.7,743.3c-1.4-1.7-2.9-3.4-4.5-5.1c-0.8-0.9-1.4-1.5-1.9-1.9c-0.5-0.4-0.9-0.7-1.2-0.9c-0.4-0.2-0.8-0.3-1.3-0.3
									c0-0.5,0-0.9,0.1-1.3c0.1-0.4,0.3-0.7,0.5-0.9c0.8-0.8,1.8-0.6,2.9,0.6c-0.1-0.8-0.1-1.6,0-2.3c0.1-0.7,0.4-1.4,0.8-2.1
									c0.3,0.2,0.8,0.4,1.3,0.8c0.5,0.4,1,0.8,1.3,1.2c-0.2,0.1-0.5,0.3-0.8,0.6c-0.5,0.4-0.8,0.9-1.1,1.4c-0.3,0.5-0.5,1-0.6,1.4
									l0.7,0.7c0.9,1.1,1.8,2,2.5,2.8c1.5,1.6,2.8,2.7,3.7,3.1c-0.3,0.5-0.7,0.9-1,1.2C1427.8,742.6,1427.3,743,1426.7,743.3z"
						/>
						<path
							d="M1433.5,731.4l-1.3-1.5l-1.4-1.6c-1.4-1.6-2.5-2.6-3.4-3c0.4-1,1.1-1.8,1.9-2.6c1.7,2,3.6,4.3,5.9,6.8
									c0.6,0.6,1.1,1,1.6,1.1c0.5,0.1,1-0.1,1.6-0.6c0.7-0.6,1.2-1.4,1.4-2.3c-1-1.1-1.8-2-2.5-2.9l-1.2-1.3c-1.4-1.6-2.5-2.6-3.4-3
									c0.4-1,1.1-1.8,2-2.6c0.9,1.2,2,2.5,3.4,4.1c1.3,1.6,2.2,2.6,2.6,3.1c0.5,0.6,1,1,1.4,1.2c0.4,0.2,1,0.4,1.6,0.5
									c-0.1,0.9-0.4,1.7-0.9,2.4c-0.7,0.1-1.4-0.2-2.1-0.7c0,0.8-0.1,1.6-0.4,2.3c-0.3,0.7-0.7,1.3-1.3,1.8
									C1437.3,734,1435.5,733.6,1433.5,731.4z"
						/>
						<path
							d="M1437.8,709.7c-0.1-1-0.1-1.9,0-2.7c0.9,0.2,1.8,0.3,2.7,0.3c0.1,0.9,0.1,1.8,0,2.7
									C1439.6,709.9,1438.7,709.8,1437.8,709.7z M1449.8,722.1c-0.4-0.1-1-0.6-1.6-1.3c-0.5-0.6-1.2-1.5-2.2-2.6
									c-0.9-1.1-1.5-1.8-1.9-2.3c-0.6-0.7-1.1-1.2-1.7-1.7c-0.6-0.5-1-0.8-1.4-0.9c0.3-0.5,0.5-1,0.8-1.4c0.3-0.4,0.7-0.8,1.2-1.2
									c0.3,0.5,0.9,1.3,1.7,2.3c0.8,1,1.7,2.1,2.8,3.4c0.8,0.9,1.4,1.5,1.9,2c0.5,0.4,0.9,0.7,1.2,0.9c0.3,0.2,0.8,0.3,1.3,0.4
									c0,0.5,0,0.9-0.2,1.3c-0.2,0.4-0.3,0.7-0.6,0.9C1450.7,722.1,1450.2,722.2,1449.8,722.1z"
						/>
						<path
							d="M1458.3,715.1c-1.3-1.7-2.8-3.5-4.3-5.3c-0.8-0.9-1.4-1.5-1.8-2c-0.5-0.4-0.9-0.7-1.2-0.9c-0.3-0.2-0.8-0.3-1.3-0.4
									c0-0.5,0-0.9,0.2-1.3c0.1-0.4,0.3-0.6,0.5-0.8c0.8-0.7,1.6-0.6,2.6,0.3c0-0.8,0.1-1.6,0.4-2.3c0.3-0.7,0.7-1.3,1.2-1.8
									c1.7-1.5,3.5-1.1,5.5,1.1l1.3,1.6c0.5,0.6,0.9,1.2,1.3,1.7c1.4,1.7,2.7,2.8,3.6,3.2c-0.3,0.5-0.7,0.8-1,1.2
									c-0.4,0.3-0.9,0.7-1.4,1c-1.6-2-3.4-4.2-5.4-6.5c-0.6-0.6-1.1-1-1.6-1.1c-0.5-0.1-1,0.1-1.5,0.6c-0.8,0.7-1.3,1.5-1.5,2.3l0.9,1.1
									c0.7,0.9,1.5,1.9,2.5,3c1.5,1.7,2.7,2.8,3.6,3.3c-0.4,0.5-0.7,0.9-1.1,1.2C1459.4,714.5,1458.9,714.8,1458.3,715.1z"
						/>
						<path
							d="M1471.3,702c-1.4-0.3-2.7-1.2-4.1-2.7c-1-1-1.6-2.2-2-3.3c-0.4-1.2-0.4-2.3-0.2-3.3c0.2-1.1,0.8-2,1.7-2.8
									c1.1-1,2.4-1.4,3.7-1.1c1.4,0.3,2.8,1.3,4.2,2.9c-0.6,0.9-1.3,1.9-2.3,3s-1.9,2.2-2.9,3.2c1,0.9,1.8,1.4,2.6,1.5
									c0.8,0.1,1.6-0.2,2.3-0.9c0.6-0.5,1.1-1.1,1.5-1.7c0.4-0.6,0.6-1.2,0.8-1.8l0.6,0.3c0.1,1,0,2.1-0.4,3.1s-1,1.9-1.7,2.6
									C1473.9,701.9,1472.6,702.2,1471.3,702z M1470.4,694.7c0.5-0.8,0.9-1.5,1.1-2.1c-0.8-0.8-1.6-1.3-2.5-1.5
									c-0.9-0.2-1.7,0.1-2.4,0.7c-0.2,0.7-0.1,1.5,0.3,2.4c0.4,0.9,1,1.9,1.8,2.8C1469.3,696.3,1469.9,695.5,1470.4,694.7z"
						/>
						<path
							d="M1484.8,691.4c-1.4-1.7-2.9-3.4-4.6-5.1c-0.8-0.9-1.5-1.5-1.9-1.9c-0.5-0.4-0.9-0.7-1.2-0.9s-0.8-0.3-1.3-0.3
									c-0.1-0.5,0-0.9,0.1-1.3c0.1-0.4,0.3-0.7,0.5-0.9c0.7-0.7,1.6-0.6,2.6,0.2c-0.1-0.8,0-1.6,0.3-2.3c0.2-0.7,0.6-1.4,1.2-1.8
									c1.6-1.6,3.5-1.3,5.5,0.9l1.3,1.5c0.5,0.6,1,1.2,1.4,1.6c1.5,1.6,2.8,2.6,3.8,3.1c-0.3,0.5-0.6,0.9-1,1.2
									c-0.4,0.3-0.8,0.7-1.4,1.1c-1.7-2-3.6-4.1-5.6-6.3c-0.6-0.6-1.1-1-1.6-1c-0.5-0.1-1,0.2-1.5,0.6c-0.8,0.7-1.2,1.5-1.4,2.3l0.9,1
									c0.7,0.8,1.6,1.8,2.6,2.9c1.5,1.6,2.8,2.7,3.7,3.1c-0.3,0.5-0.7,0.9-1.1,1.3C1485.9,690.7,1485.4,691,1484.8,691.4z"
						/>
						<path
							d="M1420.3,765.2l0.4,0.1c0,1.5,0.2,3,0.8,4.5c0.6,1.5,1.5,2.9,2.6,4.2c1.1,1.3,2.4,2.4,3.8,3.2c1.4,0.8,2.8,1.3,4.3,1.5
									l0,0.4c-1.9,0.1-3.7-0.2-5.4-0.9c-1.7-0.7-3.1-1.7-4.3-3.1c-1.2-1.4-1.9-2.9-2.3-4.7C1419.8,768.9,1419.9,767.1,1420.3,765.2z"
						/>
						<path
							d="M1432.3,772.1c-0.5-0.6-1.3-1.5-2.3-2.7c-2.1-2.5-3.5-3.9-4.2-4.3c0.3-0.4,0.6-0.7,0.9-1c0.3-0.3,0.7-0.5,1.2-0.8
									c0.6,0.8,1.3,1.8,2.3,3s1.9,2.3,2.7,3.3c1.2,1.4,2.1,2.4,2.6,2.9c0.7-0.3,1.3-0.8,2.1-1.4c0.4-0.4,0.8-0.8,1.2-1.3
									c0.3-0.5,0.6-0.9,0.7-1.3c0.3,0.4,0.7,0.9,0.9,1.3c0.3,0.5,0.4,0.9,0.5,1.1c-0.9,0.6-1.9,1.3-2.9,2.3c-1.3,1.1-2.2,2-2.8,2.6
									C1433.8,773.9,1432.8,772.7,1432.3,772.1z"
						/>
						<path
							d="M1443.7,766.6c-1.3-0.2-2.4-1-3.5-2.2c-0.8-0.9-1.3-1.8-1.6-2.8c-0.3-1-0.3-1.9,0-2.8c0.3-0.9,0.8-1.7,1.5-2.3
									c0.7-0.6,1.4-0.9,2.2-1c0.8-0.1,1.6,0,2.5,0.4c0.8,0.4,1.6,1,2.3,1.8c0.8,0.9,1.3,1.8,1.5,2.8c0.3,1,0.2,1.9,0,2.9
									c-0.3,0.9-0.8,1.7-1.6,2.4C1446.1,766.5,1445,766.9,1443.7,766.6z M1447.6,762.4c-0.1-0.9-0.5-1.7-1.3-2.7
									c-0.7-0.8-1.5-1.4-2.4-1.7c-0.9-0.3-1.7-0.3-2.5,0c-0.7,0.6-1,1.4-1,2.2c0,0.9,0.5,1.8,1.2,2.7c0.9,1,1.8,1.7,2.7,2
									c0.9,0.3,1.7,0.1,2.3-0.4C1447.4,764,1447.7,763.2,1447.6,762.4z"
						/>
						<path
							d="M1452.4,756.2l-1.1-1.3l-1.1-1.4c-1.1-1.3-2.1-2.1-2.8-2.5c0.4-0.8,0.9-1.5,1.6-2.1c1.4,1.7,3,3.6,4.8,5.7
									c0.5,0.5,0.9,0.8,1.3,0.9c0.4,0.1,0.9-0.1,1.3-0.5c0.6-0.5,1-1.1,1.2-1.9c-0.8-0.9-1.5-1.7-2-2.4l-0.9-1.1
									c-1.1-1.3-2.1-2.1-2.8-2.5c0.4-0.8,0.9-1.5,1.7-2.2c0.8,1,1.7,2.1,2.7,3.4s1.8,2.2,2.1,2.6c0.4,0.5,0.8,0.8,1.1,1
									c0.3,0.2,0.8,0.3,1.3,0.4c-0.1,0.8-0.4,1.4-0.8,2c-0.6,0-1.2-0.2-1.8-0.6c0,0.7-0.1,1.3-0.4,1.9c-0.3,0.6-0.6,1.1-1.1,1.5
									C1455.5,758.4,1454,758.1,1452.4,756.2z"
						/>
						<path
							d="M1464.4,748.7c-1.1-0.3-2.3-1.1-3.5-2.5c-1.3-1.4-2-2.7-2.1-3.9c-0.1-1.2,0.3-2.2,1.3-3.1c0.8-0.7,1.8-1.1,2.9-1.1
									c-1.2-1.3-2.2-2.2-3-2.7c0.2-0.5,0.5-0.8,0.7-1.2c0.3-0.3,0.6-0.7,0.9-1c1.3,1.8,3.7,4.7,7.2,8.6c0.4,0.5,0.8,0.8,1.1,1
									s0.8,0.3,1.3,0.4c-0.1,0.8-0.3,1.4-0.8,2c-0.6,0-1.1-0.2-1.7-0.5c0,0.6-0.1,1.2-0.3,1.8c-0.2,0.6-0.5,1-0.9,1.4
									C1466.6,748.7,1465.6,749,1464.4,748.7z M1467.8,745.4c0.2-0.4,0.3-0.9,0.4-1.3c-0.4-0.4-1-1.1-1.8-2.1c-0.9-1.1-1.7-2-2.2-2.6
									c-0.8,0.1-1.5,0.4-2.1,1c-0.6,0.6-0.9,1.2-0.8,2c0.1,0.8,0.6,1.7,1.4,2.6c0.8,0.9,1.6,1.5,2.4,1.8c0.8,0.3,1.5,0.2,2.2-0.2
									C1467.4,746.2,1467.6,745.8,1467.8,745.4z"
						/>
						<path
							d="M1474.8,738.4l-2.8-1.8c-0.7-0.4-1.3-0.8-1.8-1.2c-0.7-0.4-1.3-0.7-1.9-1c-0.6-0.3-1-0.4-1.3-0.4c0.2-0.4,0.4-0.8,0.6-1.2
									c0.3-0.4,0.6-0.7,1-1.1c0.6,0.5,2,1.4,4.1,2.8c2.1,1.4,3.4,2.2,3.9,2.4c-1.7-3.7-2.8-6-3.3-6.8c-0.7-0.3-1.2-0.5-1.6-0.5
									c0.3-0.8,0.9-1.6,1.7-2.3c0.6,0.5,2,1.5,4.2,3s3.5,2.3,3.8,2.4c-1.2-2.7-2.1-4.6-2.7-5.6c-0.5-1.1-1-1.8-1.3-2.1
									c-0.1-0.1-0.3-0.2-0.5-0.4c0.3-0.5,0.8-1.1,1.4-1.7c0.4,1.1,1,2.6,1.8,4.3c0.8,1.8,1.7,3.6,2.7,5.7l-0.4,1.5
									c-0.4,0.3-0.8,0.5-1.3,0.4c-0.5-0.1-1-0.3-1.7-0.7c-2-1.3-3.4-2.2-4.2-2.8c0.7,1.5,1.6,3.5,2.8,5.9l-0.4,1.5
									C1477.1,739.3,1476.1,739.2,1474.8,738.4z"
						/>
						<path
							d="M1488.1,728.3c-0.6-0.1-1.2-0.4-1.7-0.9c-1.5-1.6-1-3.7,1.6-6.5c-0.8-0.8-1.5-1.3-2-1.5c-0.5-0.1-1.1,0.1-1.7,0.7
									c-0.4,0.3-0.7,0.8-0.9,1.2s-0.4,1-0.5,1.5l-0.5-0.2c-0.2-0.9-0.2-1.7,0-2.6c0.2-0.9,0.6-1.6,1.1-2.1c0.8-0.7,1.5-1,2.4-1
									c0.8,0.1,1.6,0.5,2.4,1.3c0.4,0.5,0.9,1,1.4,1.6c0.5,0.7,1,1.1,1.2,1.4c0.5,0.5,0.9,0.8,1.2,1c0.4,0.2,0.8,0.3,1.4,0.4
									c0,0.8-0.3,1.5-0.7,2.1c-0.5,0.1-1.1-0.1-1.7-0.4c-0.1,0.7-0.3,1.4-0.6,2c-0.3,0.6-0.6,1.1-1,1.5
									C1489.2,728.2,1488.7,728.4,1488.1,728.3z M1490.1,725.1c0.2-0.5,0.3-0.9,0.3-1.4l-0.1-0.1c-0.4-0.4-0.8-0.8-1.1-1.2l-0.7-0.9
									c-0.7,0.8-1.2,1.6-1.3,2.3c-0.1,0.7,0.1,1.3,0.6,1.9c0.2,0.2,0.5,0.4,0.8,0.5c0.3,0.1,0.6,0.1,0.9,0.1
									C1489.7,725.9,1489.9,725.5,1490.1,725.1z"
						/>
						<path
							d="M1497.8,719.1c-0.4-0.1-0.8-0.4-1.4-0.9c-0.4-0.4-1-1-1.9-1.9c-0.9-1-1.7-1.8-2.3-2.4c-0.3-0.3-0.6-0.6-0.9-0.8
									c-0.5,0.5-0.8,0.7-1,0.8c-0.2,0.1-0.4,0-0.6-0.1c0.3-1.2,0.3-2.5,0.2-4l0.5-0.3c0.5,0.4,0.9,0.8,1.4,1.2c0.7-0.8,1.3-1.4,1.5-1.8
									c0.3,0.3,0.5,0.5,0.6,0.8s0.3,0.5,0.4,0.8c-0.5,0.2-1,0.6-1.6,1.2c0.5,0.6,1.1,1.2,1.7,1.8c1.5,1.5,2.8,2.6,3.8,3.3
									c0.5-0.6,0.8-1,1.1-1.5l0.5,0.3c0.1,0.6,0.1,1.1,0,1.7c-0.2,0.6-0.4,1-0.8,1.4C1498.6,719,1498.2,719.2,1497.8,719.1z"
						/>
						<path
							d="M1503.2,712.5c-1.1-0.2-2.3-0.8-3.6-2c-0.8-0.8-1.4-1.7-1.8-2.6c-0.4-0.9-0.5-1.8-0.3-2.7c0.1-0.9,0.6-1.7,1.2-2.4
									c0.9-0.9,1.9-1.3,3-1.1c1.1,0.2,2.3,0.9,3.6,2.1c-0.4,0.8-1,1.6-1.7,2.6c-0.7,1-1.5,1.9-2.3,2.8c0.8,0.7,1.6,1,2.3,1.1
									c0.7,0,1.3-0.3,1.9-0.9c0.5-0.5,0.8-1,1.1-1.5c0.3-0.5,0.5-1.1,0.5-1.6l0.5,0.2c0.2,0.9,0.1,1.7-0.1,2.6c-0.3,0.9-0.7,1.7-1.3,2.3
									C1505.4,712.3,1504.4,712.7,1503.2,712.5z M1502.1,706.6c0.4-0.7,0.6-1.3,0.8-1.8c-0.7-0.6-1.4-1-2.2-1.1
									c-0.8-0.1-1.4,0.1-1.9,0.7c-0.1,0.6,0,1.2,0.4,2c0.4,0.8,0.9,1.5,1.6,2.2C1501.3,707.9,1501.7,707.3,1502.1,706.6z"
						/>
						<path
							d="M1513,703.8c-1.2-1.3-2.6-2.6-4-4c-0.7-0.7-1.3-1.2-1.7-1.5c-0.4-0.3-0.8-0.5-1.1-0.6c-0.3-0.1-0.7-0.2-1.1-0.2
									c-0.1-0.4,0-0.8,0-1.1c0.1-0.3,0.2-0.6,0.4-0.8c0.6-0.7,1.4-0.6,2.4,0.3c-0.1-0.7-0.2-1.3-0.1-1.9c0.1-0.6,0.2-1.2,0.5-1.7
									c0.3,0.1,0.7,0.3,1.1,0.6c0.4,0.3,0.8,0.6,1.2,0.9c-0.2,0.1-0.4,0.3-0.6,0.6c-0.3,0.4-0.6,0.8-0.9,1.2c-0.2,0.4-0.4,0.8-0.4,1.2
									l0.6,0.6c0.8,0.8,1.6,1.5,2.2,2.2c1.4,1.3,2.4,2.1,3.3,2.4c-0.2,0.4-0.5,0.8-0.8,1.1C1513.8,703.2,1513.5,703.5,1513,703.8z"
						/>
						<path
							d="M1511.3,685.9c1.8,0.4,3.3,1.3,4.6,2.5c1.3,1.2,2.3,2.7,2.9,4.4c0.6,1.7,0.9,3.5,0.7,5.4l-0.4,0c-0.2-1.5-0.6-2.9-1.4-4.3
									c-0.8-1.4-1.8-2.7-3.1-3.9s-2.7-2.1-4.2-2.7c-1.5-0.6-3-1-4.5-1l-0.1-0.4C1507.7,685.4,1509.5,685.4,1511.3,685.9z"
						/>
					</g>
					{/* <g id="ford">
								<path d="M1518.5,674.7c-0.1-0.7-0.3-1.7-0.5-2.9c-0.3-1.2-0.5-2.4-0.8-3.5c-0.7-3-1.4-5-1.9-6.1c0.8-0.1,1.9-0.3,3.5-0.7
									c1.7-0.4,3-0.8,3.9-1.1c0.1,0.4,0.2,0.9,0.2,1.4s0,0.9-0.1,1.1c-0.4-0.1-0.8-0.2-1.3-0.2c-0.5,0-1,0.1-1.4,0.2
									c-0.7,0.2-1.4,0.4-2,0.7c0.3,1.5,0.6,2.7,0.8,3.6l0.5-0.1c0.5-0.1,0.8-0.1,1-0.2c0.6-0.2,1.1-0.3,1.4-0.4s0.7-0.3,1.1-0.5
									c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.3,0.1,0.6,0.1,0.9l-1,0.2c-0.9,0.1-1.7,0.3-2.5,0.5c-0.3,0.1-0.6,0.2-0.7,0.2
									c0.8,3,1.3,4.9,1.7,5.5c-0.4,0.2-0.7,0.3-1.2,0.4C1519.3,674.6,1518.9,674.7,1518.5,674.7z"/>
								<path d="M1529.6,670.8c-0.9-0.7-1.6-1.8-1.9-3.2c-0.2-1-0.3-2-0.1-2.9c0.2-0.9,0.6-1.7,1.2-2.3c0.6-0.6,1.3-1,2.2-1.3
									c0.8-0.2,1.6-0.2,2.3,0.1s1.3,0.7,1.8,1.4c0.5,0.6,0.9,1.4,1.1,2.4c0.3,1,0.3,2,0.1,2.9c-0.2,0.9-0.6,1.7-1.2,2.3
									c-0.6,0.6-1.4,1-2.3,1.3C1531.6,671.8,1530.6,671.5,1529.6,670.8z M1534.6,669c0.3-0.7,0.3-1.6,0.1-2.7c-0.2-1-0.6-1.8-1.2-2.4
									c-0.6-0.6-1.3-1-2.1-1c-0.8,0.2-1.4,0.7-1.8,1.4c-0.3,0.7-0.4,1.6-0.1,2.7c0.3,1.2,0.8,2.2,1.4,2.8c0.6,0.6,1.3,0.8,2,0.7
									C1533.7,670.2,1534.3,669.7,1534.6,669z"/>
								<path d="M1542.2,669c-0.3-1.6-0.6-3.3-1-5.1c-0.2-0.9-0.4-1.5-0.5-2c-0.2-0.4-0.3-0.8-0.5-1c-0.2-0.2-0.4-0.5-0.7-0.7
									c0.2-0.3,0.4-0.6,0.6-0.8c0.2-0.2,0.5-0.3,0.7-0.4c0.8-0.2,1.4,0.3,1.7,1.5c0.3-0.6,0.6-1.1,0.9-1.5c0.3-0.4,0.8-0.8,1.3-1.1
									c0.2,0.2,0.3,0.6,0.5,1c0.2,0.5,0.3,0.9,0.4,1.3c-0.2,0-0.4,0-0.7,0.1c-0.5,0.1-0.9,0.3-1.3,0.5c-0.4,0.2-0.7,0.4-0.9,0.7l0.1,0.7
									c0.2,1.1,0.4,2,0.6,2.8c0.4,1.7,0.8,2.8,1.3,3.5c-0.4,0.2-0.8,0.3-1.1,0.4C1543.1,668.9,1542.7,669,1542.2,669z"/>
								<path d="M1550.8,666c-0.8-0.7-1.4-1.9-1.7-3.6c-0.4-1.7-0.4-3.1,0-4.1c0.4-1,1.2-1.7,2.4-1.9c0.9-0.2,1.9-0.1,2.8,0.4
									c-0.4-1.6-0.8-2.7-1.2-3.5c0.4-0.3,0.7-0.5,1.1-0.6c0.3-0.2,0.7-0.3,1.2-0.4c0.3,2,0.9,5.4,2,10.1c0.1,0.6,0.3,1,0.5,1.3
									c0.2,0.3,0.5,0.6,0.9,0.9c-0.4,0.6-0.9,1-1.5,1.2c-0.5-0.2-0.8-0.6-1.1-1.2c-0.3,0.5-0.6,0.9-1.1,1.3c-0.4,0.3-0.9,0.6-1.3,0.7
									C1552.5,666.9,1551.5,666.7,1550.8,666z M1554.9,664.8c0.3-0.3,0.6-0.6,0.9-0.9c-0.1-0.5-0.3-1.3-0.5-2.5
									c-0.3-1.3-0.5-2.3-0.6-3.1c-0.6-0.3-1.4-0.3-2.1-0.2c-0.7,0.2-1.3,0.6-1.5,1.3c-0.3,0.7-0.3,1.6,0,2.7c0.2,1.1,0.6,1.9,1.1,2.5
									c0.5,0.6,1.1,0.8,1.8,0.8C1554.3,665.2,1554.6,665,1554.9,664.8z"/>
							</g> */}
					<g className="level_zero location" id="rivendell">
						<rect className="level_zero location" x="1539.2" y="628.5" width="17.5" height="13.2" />
						<rect x="1613.637" y="1240.754" width="104.777" height="27.296" transform="matrix(0.993102, 0.11725, -0.129211, 0.99169, 117.217972, -802.877441)"></rect>
						<rect x="539.999" y="1081.885" width="35.063" height="23.801" transform="matrix(0.998472, -0.055263, 0.043237, 0.999137, 944.85376, -428.235138)"></rect>
						<path
							d="M1559.9,638.7c0.2-0.9,0.3-2.3,0.6-4c0.2-1.7,0.4-3.3,0.5-4.6c0.3-3.2,0.3-5.1,0.1-5.9c0.6-0.2,1.3-0.3,2.2-0.4
									c0.9-0.1,1.8-0.1,2.7,0c1.7,0.2,3,0.7,3.9,1.7c0.9,1,1.2,2.2,1.1,3.9c-0.1,1-0.4,1.8-0.9,2.5c-0.5,0.7-1.2,1.3-2.1,1.6
									c1.1,3,2,4.9,2.7,5.7c-0.5,0.2-1,0.3-1.5,0.3c-0.5,0-1.1,0-1.7,0c-0.3-1.1-0.9-2.9-2-5.6c-0.1,0-0.2,0-0.5,0
									c-0.3,0-0.7-0.1-1.1-0.2c-0.4-0.1-0.7-0.2-0.9-0.3c-0.2,2.8-0.3,4.6-0.1,5.4c-0.5,0.1-1,0.1-1.5,0
									C1561,639,1560.5,638.9,1559.9,638.7z M1567.6,631.4c0.5-0.6,0.7-1.2,0.8-2c0.2-2.3-0.9-3.5-3.2-3.7c-0.5,0-1,0-1.4,0.1
									c-0.2,1.4-0.4,3.3-0.7,5.8c1.2,0.6,2.1,0.9,2.8,1C1566.5,632.3,1567.1,631.9,1567.6,631.4z"
						/>
						<path
							d="M1574.3,639.8c-0.2-0.4-0.2-1-0.1-1.9c0.1-0.7,0.2-1.7,0.4-3c0.2-1.2,0.3-2.1,0.3-2.7c0.1-0.8,0.1-1.5,0.1-2.2
									c0-0.7-0.1-1.2-0.2-1.5c0.5-0.2,1-0.3,1.4-0.3c0.4,0,0.9,0,1.5,0c-0.1,0.5-0.3,1.4-0.5,2.5c-0.2,1.2-0.3,2.4-0.5,3.9
									c-0.1,1.1-0.2,1.9-0.2,2.4c0,0.6,0.1,1,0.1,1.4s0.3,0.7,0.5,1.1c-0.3,0.3-0.6,0.5-1,0.7c-0.3,0.1-0.6,0.2-0.9,0.2
									C1574.8,640.4,1574.5,640.2,1574.3,639.8z M1575.1,624.4c0.6-0.7,1.2-1.2,1.8-1.6c0.4,0.7,0.9,1.4,1.4,1.9
									c-0.6,0.6-1.1,1.1-1.8,1.6C1576.1,625.7,1575.6,625.1,1575.1,624.4z"
						/>
						<path
							d="M1583.4,640.7c-0.3-0.5-0.5-1.1-0.7-1.8c-0.2-0.7-0.3-1.7-0.5-3c-0.2-1.6-0.5-2.8-0.7-3.8c-0.2-1-0.6-1.9-1-2.7
									c0.5-0.2,1-0.3,1.5-0.3c0.5,0,1,0,1.5,0.1c0,1.1,0.3,2.9,0.7,5.4c0.4,2.6,0.8,4.3,1.2,5.2c1.2-2.8,2-4.9,2.5-6.1
									c0.5-1.3,0.7-2.2,0.8-2.8c0-0.3,0-0.6,0-0.8c0.7-0.1,1.5-0.1,2.3,0c-1.3,2.5-2.8,6.1-4.5,10.7l-1.7,0.8
									C1584.1,641.4,1583.7,641.2,1583.4,640.7z"
						/>
						<path
							d="M1592.8,641c-0.6-1.1-0.8-2.6-0.5-4.4c0.2-1.3,0.6-2.3,1.1-3.3c0.6-0.9,1.3-1.6,2.2-2c0.9-0.4,1.8-0.6,2.9-0.4
									c1.4,0.2,2.3,0.8,2.9,1.9c0.6,1.1,0.7,2.6,0.5,4.6c-0.9,0.1-2.1,0.2-3.4,0.2c-1.3,0-2.6-0.1-3.9-0.2c-0.1,1.2,0.1,2.1,0.5,2.6
									c0.4,0.6,1.1,0.9,2,1.1c0.7,0.1,1.4,0.1,2,0c0.7-0.1,1.2-0.3,1.7-0.5l0.1,0.6c-0.6,0.7-1.4,1.2-2.4,1.6c-0.9,0.3-1.9,0.5-2.8,0.3
									C1594.4,642.8,1593.4,642.1,1592.8,641z M1597.3,636.3c0.9-0.1,1.5-0.3,2.1-0.5c0.1-1,0-1.9-0.4-2.6c-0.4-0.7-1-1.1-1.8-1.2
									c-0.6,0.3-1.1,0.8-1.5,1.6c-0.4,0.8-0.7,1.7-0.9,2.8C1595.6,636.5,1596.4,636.4,1597.3,636.3z"
						/>
						<path
							d="M1605.5,644.1c0.4-1.9,0.8-3.9,1.1-6c0.2-1,0.3-1.8,0.3-2.4c0-0.6,0-1-0.1-1.4c-0.1-0.3-0.2-0.7-0.5-1.1
									c0.3-0.3,0.7-0.5,1-0.7c0.3-0.1,0.6-0.2,0.9-0.1c0.9,0.1,1.3,0.8,1.3,2c0.5-0.5,1.1-0.9,1.8-1.1c0.7-0.2,1.3-0.3,1.9-0.2
									c2,0.3,2.8,1.8,2.4,4.4l-0.3,1.8c-0.2,0.7-0.3,1.3-0.4,1.9c-0.3,2-0.3,3.4-0.1,4.4c-0.5,0-1,0-1.4,0c-0.4-0.1-1-0.2-1.5-0.4
									c0.5-2.3,0.9-4.8,1.3-7.4c0.1-0.8,0.1-1.3-0.2-1.7s-0.7-0.6-1.3-0.7c-0.9-0.1-1.8,0-2.4,0.3l-0.2,1.2c-0.2,1-0.4,2.1-0.6,3.4
									c-0.3,2-0.4,3.4-0.1,4.4c-0.5,0-1,0-1.5-0.1C1606.5,644.4,1606,644.3,1605.5,644.1z"
						/>
						<path
							d="M1619.6,645c-0.6-1.1-0.7-2.7-0.5-4.7c0.3-2.1,0.9-3.6,1.7-4.5c0.9-1,2-1.3,3.5-1.1c1.1,0.2,2.1,0.7,3,1.6
									c0.2-1.9,0.2-3.4,0.1-4.3c0.5-0.1,1-0.2,1.5-0.3c0.5,0,0.9,0,1.5,0.1c-0.5,2.4-1.2,6.4-1.9,12c-0.1,0.7-0.1,1.2,0,1.6
									s0.3,0.9,0.6,1.4c-0.7,0.5-1.4,0.7-2.2,0.8c-0.4-0.4-0.7-1-0.8-1.7c-0.5,0.5-1.1,0.8-1.7,1c-0.6,0.2-1.2,0.3-1.8,0.2
									C1621.2,646.8,1620.2,646.1,1619.6,645z M1624.7,645.4c0.5-0.2,0.9-0.4,1.4-0.7c0.1-0.6,0.2-1.6,0.5-3c0.2-1.6,0.4-2.8,0.5-3.7
									c-0.6-0.6-1.4-0.9-2.3-1c-0.9-0.1-1.6,0.1-2.2,0.8c-0.6,0.6-1,1.6-1.1,3c-0.2,1.3-0.1,2.4,0.2,3.2c0.3,0.8,0.9,1.4,1.7,1.7
									C1623.8,645.7,1624.2,645.6,1624.7,645.4z"
						/>
						<path
							d="M1633.5,646.8c-0.6-1.1-0.8-2.6-0.6-4.4c0.1-1.3,0.5-2.4,1-3.3c0.6-0.9,1.3-1.6,2.1-2.1c0.9-0.5,1.8-0.6,2.8-0.5
									c1.4,0.1,2.4,0.8,3,1.8s0.8,2.6,0.6,4.5c-0.9,0.2-2.1,0.3-3.4,0.3c-1.3,0-2.6,0-3.9-0.1c0,1.2,0.2,2.1,0.6,2.6
									c0.4,0.6,1.1,0.9,2,1c0.7,0.1,1.4,0.1,2-0.1s1.2-0.3,1.7-0.6l0.1,0.6c-0.6,0.7-1.4,1.3-2.3,1.6s-1.9,0.5-2.8,0.4
									C1635.1,648.5,1634.1,647.9,1633.5,646.8z M1637.8,641.9c0.9-0.1,1.5-0.3,2-0.5c0.1-1-0.1-1.9-0.5-2.6c-0.4-0.7-1.1-1.1-1.8-1.2
									c-0.6,0.3-1.1,0.8-1.5,1.6c-0.4,0.8-0.7,1.8-0.8,2.8C1636.1,642.1,1637,642.1,1637.8,641.9z"
						/>
						<path
							d="M1646.3,649c-0.2-0.4-0.2-1-0.2-1.9c0.1-0.6,0.1-1.5,0.3-2.8c0.2-1.5,0.3-2.7,0.4-3.6c0.3-3.1,0.3-5.3,0.1-6.7
									c0.5-0.2,1-0.3,1.4-0.3c0.4,0,0.9,0,1.5,0c-0.4,2.4-0.8,5.8-1.2,10.2c-0.1,1-0.1,1.8-0.1,2.4c0,0.6,0.1,1,0.2,1.4
									c0.1,0.3,0.3,0.7,0.6,1.1c-0.3,0.3-0.6,0.6-1,0.7s-0.6,0.2-0.9,0.2C1646.8,649.6,1646.5,649.4,1646.3,649z"
						/>
						<path
							d="M1653.3,649.6c-0.2-0.4-0.2-1-0.2-1.9c0-0.6,0.1-1.5,0.3-2.8c0.1-1.5,0.2-2.7,0.3-3.6c0.2-3.1,0.2-5.3,0-6.7
									c0.5-0.2,1-0.3,1.4-0.3c0.4-0.1,0.9-0.1,1.5,0c-0.4,2.4-0.7,5.8-1,10.2c-0.1,1-0.1,1.8-0.1,2.4c0,0.6,0.1,1,0.2,1.4
									c0.1,0.3,0.3,0.7,0.6,1.1c-0.3,0.3-0.6,0.6-1,0.7c-0.3,0.1-0.6,0.2-0.9,0.2C1653.9,650.1,1653.5,649.9,1653.3,649.6z"
						/>
					</g>
					<g className="level_two fade location " id="high_pass">
						<rect x="1461.134" y="1876.014" width="94.875" height="41.271" transform="matrix(0.999169, -0.040758, 0.028723, 0.99966, 147.464722, -1250.16394)"></rect>
						<path
							d="M1670.5,594.1c-0.2-0.9-0.5-2.1-1-3.7c-0.4-1.6-0.9-3-1.3-4.4c-1-3.2-1.8-5.2-2.3-5.8c0.4-0.3,0.8-0.4,1.2-0.6
									c0.4-0.1,0.9-0.2,1.5-0.3c0.4,1.6,0.9,3.6,1.7,6.2c0.9-0.2,2-0.5,3.4-0.9c0.2-0.1,0.8-0.3,1.9-0.7c-1.1-3.3-1.8-5.3-2.4-6
									c0.4-0.2,0.8-0.4,1.2-0.6c0.4-0.1,0.9-0.2,1.4-0.3c0.2,0.9,0.5,2.1,1,3.7c0.5,1.6,0.9,3,1.3,4.4c1,3.2,1.8,5.1,2.3,5.8
									c-0.4,0.2-0.8,0.4-1.3,0.6c-0.4,0.1-0.9,0.2-1.4,0.3c-0.4-1.5-0.9-3.6-1.7-6.2c-1,0.3-2,0.6-3.1,0.9c-0.7,0.2-1.4,0.5-2.2,0.8
									c1.1,3.3,1.9,5.3,2.4,6c-0.4,0.2-0.8,0.4-1.3,0.6C1671.5,593.9,1671,594,1670.5,594.1z"
						/>
						<path
							d="M1682.4,574.4c0.3-0.8,0.6-1.5,0.9-2.1c0.6,0.5,1.3,0.8,2,1.1c-0.2,0.7-0.6,1.5-0.9,2.1
									C1683.8,575.1,1683.2,574.7,1682.4,574.4z M1687.9,588.2c-0.3-0.3-0.6-0.8-0.8-1.6c-0.2-0.6-0.5-1.6-0.9-2.8
									c-0.3-1.1-0.6-2-0.8-2.5c-0.2-0.7-0.5-1.4-0.8-1.9c-0.3-0.6-0.5-1-0.8-1.2c0.4-0.3,0.8-0.6,1.1-0.8c0.4-0.2,0.8-0.4,1.3-0.6
									c0.1,0.5,0.3,1.3,0.6,2.4c0.3,1.1,0.7,2.3,1.1,3.6c0.3,1,0.6,1.7,0.8,2.2c0.2,0.5,0.4,0.9,0.7,1.1c0.2,0.3,0.5,0.5,0.9,0.7
									c-0.1,0.4-0.3,0.7-0.6,1c-0.2,0.3-0.5,0.4-0.7,0.5C1688.5,588.5,1688.1,588.4,1687.9,588.2z"
						/>
						<path
							d="M1697.5,589.2c-0.2-0.6-0.3-1.3-0.3-2c0-0.7,0.1-1.3,0.4-1.9c-0.7,0-1.2-0.4-1.4-1.1c-0.2-0.5-0.2-1,0-1.5
									c0.2-0.5,0.5-1,1-1.4c-0.7-0.1-1.2-0.4-1.7-0.9c-0.5-0.5-0.9-1.1-1.1-1.8c-0.4-1.2-0.4-2.4,0.1-3.4c0.5-1,1.4-1.8,2.6-2.2
									c0.8-0.3,1.6-0.3,2.3-0.1c0.3-0.2,0.6-0.5,0.9-0.9c0.3-0.4,0.6-0.9,0.9-1.4c0.3,0.3,0.5,0.6,0.8,0.9c0.3,0.4,0.4,0.7,0.5,1
									c-0.4,0.5-1,0.9-1.7,1.2c0.5,0.5,0.9,1.2,1.2,1.9c0.3,0.8,0.3,1.6,0.2,2.3c-0.2,0.8-0.5,1.4-1,2c-0.5,0.6-1.2,1-2,1.3
									c-0.7,0.2-1.2,0.5-1.5,0.9c-0.3,0.4-0.4,0.8-0.3,1.2l4.5-1.2c0.9-0.2,1.6-0.2,2.2,0.1c0.6,0.3,1,0.8,1.3,1.5
									c0.2,0.7,0.2,1.4,0,2.1c-0.2,0.7-0.7,1.4-1.3,1.9c-0.6,0.6-1.4,1-2.4,1.3C1700.2,589.6,1698.7,589.7,1697.5,589.2z M1700.5,578.8
									c0.2-0.5,0.2-1.1,0-1.8c-0.2-0.6-0.6-1.1-1.1-1.4s-1.1-0.5-1.7-0.4c-0.6,0.2-1.1,0.6-1.4,1.2c-0.3,0.6-0.3,1.3,0,2
									c0.2,0.7,0.6,1.2,1.1,1.5c0.5,0.3,1.1,0.3,1.7,0.1C1699.8,579.7,1700.2,579.4,1700.5,578.8z M1703.6,586c0.4-0.5,0.6-0.9,0.4-1.4
									c-0.1-0.4-0.3-0.6-0.6-0.7s-0.7-0.1-1.2,0.1l-3.6,1c-0.1,0.6-0.1,1.1,0,1.6c0.9,0.7,2,0.9,3.2,0.4
									C1702.6,586.9,1703.2,586.5,1703.6,586z"
						/>
						<path
							d="M1711.2,580.6c-0.7-2.3-1.4-4.5-2.2-6.7c-1.1-3-2-5.4-2.7-7c0.7-0.6,1.5-1.1,2.4-1.4c0.2,0.9,0.7,2.6,1.4,5.2
									c0.3-0.7,0.6-1.3,1.1-1.8c0.5-0.5,1-0.9,1.6-1.1c1.8-0.6,3.2,0.2,4,2.7l0.5,1.7l0.6,1.8c0.6,1.8,1.2,3.1,1.8,3.8
									c-0.4,0.3-0.8,0.5-1.2,0.6c-0.4,0.1-0.9,0.2-1.4,0.3c-0.8-2.5-1.5-4.8-2.2-6.9c-0.2-0.7-0.5-1.2-0.9-1.4c-0.4-0.2-0.8-0.2-1.4,0
									c-0.8,0.3-1.5,0.8-2,1.5c0.1,0.5,0.4,1.2,0.7,2.2c1.1,3.3,2,5.2,2.6,5.8c-0.4,0.3-0.8,0.5-1.2,0.6
									C1712.3,580.4,1711.8,580.5,1711.2,580.6z"
						/>
						<path
							d="M1715.1,603.4c-0.3-1.1-0.8-2.5-1.5-4.3s-1.3-3.5-2-5.1c-1.4-3.4-2.4-5.4-3-6.1c0.5-0.5,1.1-1,2-1.5
									c0.9-0.5,1.8-1,2.8-1.4c1.8-0.8,3.5-0.9,4.8-0.3c1.4,0.5,2.4,1.7,3.1,3.4c0.5,1.2,0.7,2.3,0.5,3.4c-0.1,1.1-0.6,2.1-1.3,3
									c-0.7,0.9-1.7,1.6-2.8,2.1c-0.7,0.3-1.4,0.5-2.1,0.6c1.1,2.7,2,4.3,2.5,5c-0.4,0.3-0.9,0.6-1.5,0.8
									C1716.3,603.2,1715.7,603.3,1715.1,603.4z M1719.2,592.3c0.1-1,0-1.9-0.4-2.9c-1-2.4-2.7-3.2-5.2-2.1c-0.5,0.2-1,0.5-1.4,0.8
									c0.8,2.3,1.7,4.7,2.7,7.1c1.5-0.1,2.6-0.3,3.3-0.6C1718.7,594,1719,593.2,1719.2,592.3z"
						/>
						<path
							d="M1726.5,598.3c-0.6-0.4-1.1-1.1-1.4-1.9c-0.8-2.6,1-4.7,5.4-6.4c-0.4-1.4-0.9-2.3-1.4-2.8c-0.5-0.4-1.3-0.5-2.3-0.2
									c-0.6,0.2-1.2,0.5-1.7,0.9c-0.5,0.4-1,0.9-1.4,1.4l-0.4-0.5c0.2-1.1,0.7-2.1,1.4-2.9c0.7-0.9,1.5-1.4,2.4-1.7
									c1.2-0.4,2.3-0.3,3.2,0.2c0.9,0.5,1.6,1.5,2,2.9c0.2,0.8,0.4,1.6,0.6,2.6c0.2,1,0.4,1.8,0.6,2.3c0.2,0.8,0.5,1.4,0.8,1.8
									c0.3,0.4,0.7,0.8,1.3,1.2c-0.5,0.9-1.2,1.5-2,1.9c-0.6-0.2-1.2-0.7-1.6-1.4c-0.5,0.7-1.1,1.4-1.8,1.9c-0.6,0.5-1.3,0.9-1.9,1.1
									C1727.8,598.8,1727.2,598.7,1726.5,598.3z M1730.6,595.8c0.5-0.4,0.8-0.9,1.2-1.4l0-0.2c-0.2-0.7-0.4-1.3-0.5-2l-0.3-1.4
									c-1.3,0.5-2.2,1.1-2.7,1.8c-0.5,0.7-0.7,1.5-0.4,2.4c0.1,0.4,0.3,0.7,0.6,1s0.6,0.5,0.9,0.6
									C1729.7,596.6,1730.1,596.3,1730.6,595.8z"
						/>
						<path
							d="M1736.4,595c-0.1-0.4-0.1-0.8-0.1-1.2s0-0.8,0.1-1.3c0.5,0.5,1.1,0.8,1.8,0.9s1.4,0.2,2.1,0c1.9-0.4,2.7-1.2,2.4-2.3
									c-0.1-0.5-0.4-0.8-0.9-1.1c-0.5-0.2-1.2-0.5-2.2-0.7c-1-0.2-1.7-0.5-2.3-0.7c-0.6-0.2-1.2-0.6-1.7-1.1s-0.9-1.2-1.1-2
									c-0.2-1,0-1.9,0.7-2.7c0.7-0.8,1.7-1.4,3-1.6c1.7-0.4,3.3-0.4,4.6-0.2c0.1,0.3,0.1,0.8,0,1.4c0,0.6-0.2,1.2-0.3,1.7
									c-0.9-0.6-1.7-1-2.4-1.3c-0.8-0.3-1.4-0.4-1.8-0.3c-0.4,0.2-0.8,0.5-1,0.9c-0.2,0.4-0.3,0.7-0.2,1.1c0.1,0.6,0.5,1,1,1.3
									c0.5,0.3,1.3,0.5,2.4,0.8c0.9,0.2,1.6,0.4,2.2,0.7c0.6,0.2,1.1,0.6,1.6,1c0.5,0.5,0.8,1.1,1,1.9c0.3,1.2,0,2.3-0.7,3.1
									s-1.9,1.5-3.5,1.8C1739.2,595.6,1737.7,595.6,1736.4,595z"
						/>
						<path
							d="M1746,592.9c-0.1-0.4-0.1-0.8,0-1.2c0-0.4,0.1-0.8,0.2-1.3c0.5,0.5,1,0.9,1.7,1.1c0.7,0.2,1.4,0.3,2.1,0.2
									c1.9-0.3,2.7-1,2.6-2.1c-0.1-0.5-0.3-0.9-0.8-1.1c-0.5-0.3-1.2-0.6-2.1-0.8c-0.9-0.3-1.7-0.6-2.3-0.9c-0.6-0.3-1.1-0.7-1.6-1.2
									c-0.5-0.5-0.8-1.2-0.9-2.1c-0.1-1,0.1-1.9,0.9-2.7c0.7-0.8,1.8-1.2,3.1-1.4c1.8-0.2,3.3-0.2,4.6,0.2c0,0.4,0,0.8-0.1,1.4
									c-0.1,0.6-0.2,1.2-0.4,1.7c-0.8-0.6-1.6-1.1-2.3-1.4c-0.7-0.3-1.3-0.5-1.8-0.4c-0.5,0.2-0.8,0.4-1.1,0.8s-0.4,0.7-0.3,1
									c0.1,0.6,0.4,1.1,0.9,1.4c0.5,0.3,1.3,0.6,2.3,1c0.9,0.3,1.6,0.6,2.2,0.8c0.6,0.3,1.1,0.7,1.5,1.2c0.4,0.5,0.7,1.2,0.8,1.9
									c0.2,1.2-0.1,2.2-0.9,3.1c-0.8,0.8-2,1.3-3.6,1.6C1748.8,593.7,1747.3,593.6,1746,592.9z"
						/>
					</g>
					<g className="level_two fade location " id="carrock">
						<rect x="1385.395" y="1251.789" width="89.958" height="27.538" transform="matrix(0.999666, -0.025832, 0.01379, 0.999977, 359.909851, -667.483154)"></rect>
						<path
							d="M1768.1,567.8c-1.1-1.5-1.7-3.6-1.6-6.3c0.1-1.9,0.4-3.6,1.1-5c0.7-1.5,1.7-2.6,2.9-3.4c1.2-0.8,2.6-1.2,4.2-1.1
									c1.7,0,3.1,0.4,4.3,0.9c0,0.5-0.1,1-0.3,1.6c-0.2,0.6-0.4,1.1-0.6,1.5c-0.8-0.7-1.7-1.2-2.7-1.7c-0.9-0.5-1.7-0.7-2.3-0.7
									c-0.7,0.2-1.3,0.6-1.9,1.3c-0.6,0.7-1.1,1.6-1.4,2.6c-0.4,1-0.6,2.1-0.6,3.2c-0.1,2.3,0.3,4,1.2,5.1c0.9,1.1,2,1.7,3.5,1.8
									c2,0.1,3.6-0.3,4.9-1.1l0.2,0.8c-0.7,0.9-1.6,1.6-2.7,2.1s-2.2,0.8-3.3,0.7C1770.9,570,1769.3,569.3,1768.1,567.8z"
						/>
						<path
							d="M1780.9,569.4c-0.5-0.6-0.7-1.4-0.7-2.3c0.1-2.7,2.4-4.1,7.2-4.4c0-1.5-0.1-2.5-0.5-3.1c-0.4-0.6-1.1-0.9-2.1-0.9
									c-0.6,0-1.2,0.1-1.9,0.3c-0.6,0.2-1.2,0.5-1.7,0.9l-0.2-0.6c0.6-1,1.3-1.7,2.2-2.3c0.9-0.6,1.9-0.9,2.8-0.9c1.3,0,2.3,0.4,3,1.2
									c0.7,0.8,1,1.9,1,3.3c0,0.8-0.1,1.7-0.2,2.7c-0.1,1.1-0.2,1.9-0.2,2.4c0,0.8,0,1.5,0.2,2c0.1,0.5,0.4,1,0.9,1.6
									c-0.8,0.7-1.6,1.1-2.5,1.2c-0.5-0.4-0.9-1-1.1-1.9c-0.7,0.5-1.5,1-2.3,1.3c-0.8,0.3-1.5,0.5-2.2,0.5
									C1782,570.3,1781.4,570,1780.9,569.4z M1785.6,568.3c0.6-0.3,1.1-0.6,1.5-0.9l0-0.2c0-0.7,0.1-1.4,0.1-2.1l0.1-1.4
									c-1.4,0.1-2.4,0.4-3.2,0.9c-0.8,0.5-1.1,1.2-1.2,2.2c0,0.4,0.1,0.8,0.2,1.1c0.2,0.4,0.4,0.7,0.7,0.9
									C1784.5,568.7,1785,568.6,1785.6,568.3z"
						/>
						<path
							d="M1794.1,570.2c0.2-2.2,0.3-4.6,0.3-7.1c0-1.2,0-2.2-0.1-2.8c-0.1-0.6-0.2-1.2-0.3-1.5c-0.1-0.4-0.4-0.8-0.7-1.2
									c0.3-0.4,0.7-0.7,1.1-0.9s0.7-0.3,1-0.3c1.1,0,1.7,0.8,1.8,2.5c0.5-0.7,1.1-1.3,1.7-1.7c0.6-0.4,1.3-0.8,2-1
									c0.1,0.4,0.3,0.9,0.4,1.5c0.1,0.7,0.1,1.3,0.1,1.8c-0.2,0-0.6-0.1-1-0.1c-0.6,0-1.3,0.1-1.8,0.2c-0.6,0.2-1.1,0.4-1.4,0.6l0,1
									c-0.1,1.5-0.1,2.8-0.1,3.9c0,2.3,0.1,4,0.5,5c-0.6,0.1-1.2,0.2-1.7,0.2C1795.3,570.4,1794.8,570.3,1794.1,570.2z"
						/>
						<path
							d="M1803.5,570.3c0.2-2.2,0.3-4.6,0.3-7.1c0-1.2,0-2.2-0.1-2.8s-0.2-1.2-0.3-1.5c-0.1-0.4-0.4-0.8-0.7-1.2
									c0.3-0.4,0.7-0.7,1.1-0.9s0.7-0.3,1-0.3c1.1,0,1.7,0.8,1.8,2.5c0.5-0.7,1.1-1.3,1.7-1.7c0.6-0.4,1.3-0.8,2-1
									c0.1,0.4,0.3,0.9,0.4,1.5s0.2,1.3,0.1,1.8c-0.2,0-0.6-0.1-1-0.1c-0.6,0-1.3,0.1-1.8,0.2s-1.1,0.4-1.4,0.6l0,1
									c-0.1,1.5-0.1,2.8-0.1,3.9c0,2.3,0.2,4,0.5,5c-0.6,0.1-1.2,0.2-1.7,0.2S1804.2,570.5,1803.5,570.3z"
						/>
						<path
							d="M1812.8,568.9c-1-1.2-1.5-2.9-1.5-4.9c0-1.5,0.3-2.8,0.8-3.9c0.6-1.1,1.3-2,2.3-2.7c1-0.6,2.1-0.9,3.4-0.9
									c1.1,0,2.1,0.3,3,0.9c0.9,0.6,1.5,1.4,2,2.4c0.5,1,0.7,2.2,0.7,3.5c0,1.5-0.3,2.8-0.9,3.9c-0.6,1.1-1.4,2-2.4,2.7
									c-1,0.6-2.2,0.9-3.5,0.9C1815.1,570.8,1813.8,570.2,1812.8,568.9z M1820,568.1c0.6-0.9,1-2.1,1-3.6c0-1.4-0.3-2.6-0.9-3.6
									c-0.6-1-1.4-1.7-2.4-2c-1.2,0-2.1,0.4-2.8,1.3c-0.7,0.9-1,2.1-1.1,3.5c0,1.7,0.3,3.1,1,4.1c0.6,1,1.5,1.5,2.5,1.5
									C1818.4,569.4,1819.3,569,1820,568.1z"
						/>
						<path
							d="M1826.6,569.1c-0.9-1.2-1.3-2.8-1.3-5c0-1.5,0.3-2.8,0.8-3.9c0.5-1.1,1.3-2,2.2-2.7c1-0.6,2-1,3.3-1
									c1.4,0,2.6,0.3,3.6,0.8c0,0.4-0.1,0.9-0.3,1.4c-0.2,0.6-0.4,1.1-0.6,1.5c-0.8-0.8-1.6-1.3-2.3-1.7c-0.7-0.4-1.3-0.6-1.8-0.6
									c-0.6,0.4-1.1,1.1-1.5,2c-0.4,1-0.6,2-0.6,3.2c0,1.9,0.3,3.3,0.8,4.1c0.6,0.8,1.5,1.2,2.7,1.2c1.5,0,2.7-0.4,3.7-1.2l0.2,0.6
									c-0.6,0.9-1.3,1.6-2.3,2.2s-1.9,0.8-2.9,0.8C1828.7,570.8,1827.5,570.2,1826.6,569.1z"
						/>
						<path
							d="M1837.6,570.4c0.1-2.8,0.2-5.7,0.1-8.5c0-3.9-0.2-6.9-0.4-9.1c1-0.5,2.1-0.7,3.3-0.7c-0.1,1.1-0.2,2.7-0.3,4.8
									s-0.1,4-0.1,5.9l0,0.7c2-2.4,3.2-4,3.9-4.9s0.9-1.5,0.9-1.9c0.9-0.3,1.9-0.4,2.9-0.4c-1.2,1.3-2.7,3-4.5,5.1
									c2.5,4.5,4.3,7.3,5.5,8.3c-0.5,0.3-1.1,0.5-1.6,0.6c-0.5,0.1-1.2,0.2-1.9,0.2c-0.6-1.2-1.9-3.5-3.9-6.7l-1.4,1.7
									c0.1,2.5,0.3,4.1,0.7,4.8c-0.6,0.1-1.1,0.2-1.7,0.2C1838.8,570.6,1838.2,570.6,1837.6,570.4z"
						/>
					</g>
					<g className="level_two fade location " id="old_ford">
						<rect x="1767.335" y="1251.791" width="114.758" height="27.538" transform="matrix(0.991722, -0.128404, 0.116458, 0.993269, -175.283005, -400.930542)"></rect>
						<path
							d="M1738.2,638.5c-1.2-0.4-2.3-1.2-3.2-2.3c-0.9-1.1-1.5-2.5-2-4.1c-0.5-1.8-0.5-3.5-0.2-5.1c0.3-1.6,1-3,2.1-4.1
									c1-1.1,2.4-1.9,3.9-2.3c1.4-0.4,2.8-0.3,4,0.1s2.3,1.2,3.3,2.4c0.9,1.1,1.6,2.5,2,4.2c0.5,1.8,0.5,3.5,0.1,5
									c-0.4,1.6-1.1,2.9-2.2,4s-2.4,1.9-4.1,2.3C1740.7,638.9,1739.4,638.9,1738.2,638.5z M1745.5,634.1c0.6-1.4,0.6-3.1,0.1-5.1
									c-0.5-1.8-1.2-3.2-2.3-4.2c-1.1-1-2.4-1.4-3.9-1.4c-1,0.3-1.8,0.8-2.5,1.5c-0.7,0.8-1.1,1.7-1.3,2.8c-0.2,1.1-0.1,2.3,0.2,3.6
									c0.5,2,1.4,3.5,2.6,4.5s2.5,1.3,3.9,1C1743.9,636.4,1744.9,635.5,1745.5,634.1z"
						/>
						<path
							d="M1753.5,635.1c-0.3-0.3-0.6-1-0.8-2c-0.1-0.7-0.4-1.7-0.7-3.2c-0.3-1.7-0.6-3-0.8-4.1c-0.8-3.5-1.5-5.9-2.2-7.4
									c0.5-0.4,1-0.6,1.5-0.8s1-0.4,1.6-0.5c0.4,2.7,1.1,6.6,2.2,11.6c0.3,1.2,0.5,2.1,0.7,2.7c0.2,0.6,0.4,1.1,0.7,1.4
									c0.2,0.3,0.6,0.7,1,1c-0.2,0.5-0.5,0.8-0.8,1.1c-0.3,0.3-0.6,0.4-0.9,0.5C1754.2,635.6,1753.8,635.5,1753.5,635.1z"
						/>
						<path
							d="M1759.6,632.7c-1-1-1.8-2.7-2.2-4.9c-0.5-2.4-0.4-4.2,0.2-5.6c0.6-1.4,1.7-2.2,3.4-2.5c1.3-0.2,2.6,0,3.8,0.6
									c-0.5-2.2-1-3.7-1.5-4.8c0.5-0.3,1-0.6,1.5-0.8c0.5-0.2,1-0.4,1.6-0.5c0.3,2.8,1.1,7.4,2.3,13.9c0.1,0.8,0.3,1.4,0.6,1.8
									c0.2,0.4,0.6,0.8,1.2,1.3c-0.5,0.8-1.3,1.3-2.1,1.6c-0.6-0.3-1.1-0.9-1.5-1.6c-0.4,0.7-0.9,1.3-1.5,1.7c-0.6,0.5-1.2,0.7-1.9,0.9
									C1761.9,634,1760.6,633.7,1759.6,632.7z M1765.3,631.2c0.5-0.4,0.9-0.8,1.2-1.2c-0.1-0.7-0.3-1.8-0.6-3.4
									c-0.3-1.8-0.6-3.2-0.8-4.2c-0.9-0.4-1.8-0.5-2.9-0.3c-1,0.2-1.7,0.8-2.1,1.7c-0.4,0.9-0.4,2.1-0.2,3.7c0.3,1.5,0.8,2.7,1.5,3.4
									c0.7,0.8,1.5,1.2,2.5,1.2C1764.4,631.8,1764.8,631.6,1765.3,631.2z"
						/>
						<path
							d="M1787.2,629.4c0-1-0.1-2.3-0.2-4c-0.1-1.7-0.3-3.3-0.5-4.9c-0.5-4.2-1-7-1.5-8.5c1.1,0,2.7-0.1,4.8-0.3
									c2.3-0.3,4.2-0.6,5.5-0.9c0.1,0.6,0.1,1.2,0.1,1.9s-0.1,1.2-0.3,1.5c-0.5-0.2-1-0.4-1.7-0.5c-0.7-0.1-1.3-0.1-2,0
									c-1,0.1-2,0.3-2.9,0.6c0.2,2.1,0.3,3.7,0.4,5l0.7,0c0.6,0,1.1-0.1,1.4-0.1c0.9-0.1,1.5-0.2,1.9-0.3c0.4-0.1,1-0.3,1.6-0.5
									c0.1,0.4,0.2,0.8,0.3,1.2c0,0.4,0.1,0.8,0,1.3l-1.3,0.1c-1.2,0-2.4,0.1-3.4,0.2c-0.5,0.1-0.8,0.1-1,0.2c0.5,4.2,1,6.8,1.4,7.7
									c-0.5,0.2-1.1,0.3-1.6,0.4C1788.4,629.5,1787.8,629.5,1787.2,629.4z"
						/>
						<path
							d="M1799.8,626.5c-1.1-1.2-1.7-2.7-1.9-4.8c-0.1-1.5,0-2.8,0.5-4c0.5-1.2,1.1-2.1,2.1-2.9s2-1.1,3.3-1.2
									c1.1-0.1,2.1,0.1,3,0.6c0.9,0.5,1.6,1.2,2.2,2.2c0.6,1,0.9,2.1,1,3.5c0.1,1.5,0,2.8-0.5,4c-0.5,1.2-1.2,2.1-2.2,2.9
									c-1,0.7-2.1,1.1-3.4,1.2C1802.3,628.1,1800.9,627.6,1799.8,626.5z M1806.9,625.1c0.6-0.9,0.8-2.1,0.6-3.7
									c-0.1-1.4-0.5-2.5-1.2-3.5c-0.7-0.9-1.5-1.6-2.6-1.8c-1.2,0.1-2,0.6-2.7,1.5c-0.6,0.9-0.9,2.1-0.7,3.6c0.1,1.7,0.6,3.1,1.3,4
									c0.7,1,1.6,1.4,2.6,1.3C1805.5,626.5,1806.3,626,1806.9,625.1z"
						/>
						<path
							d="M1813.9,626.9c0-2.2,0-4.6-0.2-7.1c-0.1-1.2-0.2-2.1-0.3-2.8c-0.1-0.6-0.2-1.1-0.4-1.5c-0.2-0.4-0.4-0.7-0.8-1.1
									c0.3-0.4,0.6-0.7,1-1c0.4-0.2,0.7-0.3,1-0.4c1.1-0.1,1.8,0.7,1.9,2.4c0.5-0.7,1-1.4,1.6-1.8c0.6-0.5,1.2-0.9,2-1.1
									c0.2,0.3,0.3,0.9,0.5,1.5c0.1,0.7,0.2,1.3,0.3,1.8c-0.2,0-0.6,0-1,0c-0.6,0-1.2,0.2-1.8,0.3c-0.6,0.2-1,0.4-1.4,0.7l0,1
									c0,1.5,0.1,2.8,0.2,3.9c0.1,2.3,0.4,4,0.9,5c-0.6,0.2-1.1,0.3-1.6,0.3S1814.5,627,1813.9,626.9z"
						/>
						<path
							d="M1822.9,625c-0.9-1.2-1.3-2.9-1.4-5.2c-0.1-2.4,0.3-4.2,1.1-5.5c0.8-1.2,2.1-1.9,3.7-1.9c1.3,0,2.5,0.4,3.7,1.2
									c-0.2-2.2-0.4-3.9-0.7-4.9c0.6-0.3,1.1-0.4,1.6-0.6c0.5-0.1,1.1-0.2,1.7-0.2c-0.2,2.8-0.2,7.5,0,14.1c0,0.8,0.1,1.4,0.3,1.8
									c0.2,0.5,0.5,0.9,1,1.5c-0.7,0.7-1.5,1.1-2.4,1.3c-0.6-0.4-0.9-1-1.2-1.8c-0.5,0.6-1.1,1.1-1.8,1.4c-0.7,0.3-1.3,0.5-2,0.5
									C1825,626.8,1823.8,626.2,1822.9,625z M1828.8,624.6c0.5-0.3,1-0.6,1.4-1c0-0.7,0-1.9,0-3.5c0-1.8,0-3.3,0-4.3
									c-0.8-0.5-1.7-0.8-2.8-0.8c-1,0-1.8,0.5-2.4,1.3c-0.5,0.8-0.8,2-0.8,3.6c0,1.5,0.3,2.7,0.9,3.6c0.5,0.9,1.3,1.4,2.2,1.6
									C1827.8,625,1828.3,624.8,1828.8,624.6z"
						/>
					</g>
					<g className="level_two fade location " id="nin_in_eilph">
						<rect x="2491.055" y="1264.168" width="161.748" height="27.809" transform="matrix(0.999845, -0.017584, 0.00554, 1.000057, -1249.44104, -346.752563)"></rect>
						<rect x="2396.397" y="1264.168" width="155.602" height="27.809" transform="matrix(0.999845, -0.017584, 0.00554, 1.000057, -1185.917603, -323.304138)"></rect>
						<path
							d="M1253.8,895.6c0.1-2.4,0.1-5.7,0-10.1c-0.1-4-0.3-6.4-0.5-7.5c0.6-0.1,1.1-0.2,1.5-0.2c0.5,0,1,0,1.7,0.2
									c0.8,1.6,2,3.7,3.5,6.4c1.5,2.7,2.9,5.1,4,7.1c0-2.6-0.1-4.7-0.1-6.2c-0.1-4.1-0.3-6.6-0.5-7.5c0.4-0.1,0.8-0.2,1.2-0.2
									c0.4,0,0.8,0,1.2,0.2c0,1.2,0,2.7,0,4.6c0,1.9,0,3.7,0.1,5.4c0.1,4,0.3,6.4,0.5,7.5c-0.3,0.1-0.8,0.2-1.5,0.2c-0.4,0-1,0-1.8-0.2
									c-0.8-1.5-2-3.6-3.5-6.4c-1.5-2.7-2.9-5.1-4-7.1c0,2,0,4,0.1,6.2c0.1,4,0.3,6.5,0.5,7.5c-0.4,0.1-0.7,0.2-1.2,0.2
									C1254.7,895.7,1254.3,895.7,1253.8,895.6z"
						/>
						<path
							d="M1269.2,879.1c1-1.4,1.9-2.8,2.6-4.1c0.7-0.3,1.6-0.5,2.7-0.5c0.6,1.1,1,1.8,1.4,2.4c0.3,0.5,0.8,1.1,1.4,1.8
									c-0.8,0.3-1.7,0.5-2.7,0.5l-1.8-2.8l-1.6,2.9C1270.4,879.3,1269.8,879.3,1269.2,879.1z M1272.7,894.8c-0.2-0.4-0.4-1.1-0.4-2.1
									c0-0.8,0-2,0-3.5c0-1.4,0-2.5,0-3.1c0-0.9-0.1-1.7-0.2-2.5c-0.1-0.8-0.3-1.3-0.5-1.6c0.6-0.3,1.1-0.4,1.6-0.6
									c0.5-0.1,1.1-0.2,1.7-0.2c-0.1,0.6-0.1,1.6-0.2,3c0,1.3,0,2.8,0,4.5c0,1.2,0.1,2.2,0.2,2.8c0.1,0.6,0.2,1.1,0.4,1.5
									c0.2,0.4,0.4,0.8,0.8,1.1c-0.3,0.4-0.7,0.7-1,0.9c-0.4,0.2-0.7,0.3-1,0.3C1273.3,895.4,1272.9,895.2,1272.7,894.8z"
						/>
						<path
							d="M1282.1,894.9c0.1-2.2,0.1-4.6,0.1-7.1c0-1.2-0.1-2.2-0.2-2.8c-0.1-0.6-0.2-1.2-0.4-1.5c-0.2-0.4-0.4-0.8-0.8-1.2
									c0.3-0.4,0.6-0.7,1-0.9c0.4-0.2,0.7-0.3,1-0.3c1.1,0,1.7,0.6,1.9,2c0.5-0.7,1.1-1.2,1.8-1.6c0.7-0.4,1.4-0.6,2.2-0.6
									c2.3,0,3.5,1.5,3.6,4.5l0,2.1c0,0.8-0.1,1.6,0,2.2c0,2.3,0.3,3.9,0.7,5c-0.6,0.1-1.1,0.2-1.6,0.2c-0.5,0-1.1,0-1.8-0.2
									c0.1-2.7,0.1-5.6,0-8.7c0-0.9-0.2-1.5-0.5-1.9c-0.3-0.4-0.9-0.6-1.6-0.6c-1.1,0-2,0.3-2.7,0.8l0,1.4c0,1.1,0,2.5,0,4
									c0,2.3,0.3,4,0.7,5c-0.6,0.1-1.2,0.2-1.7,0.2C1283.3,895.1,1282.8,895,1282.1,894.9z"
						/>
						<path
							d="M1297.9,887.1c0.6-0.9,1.3-1.7,2.1-2.5c0.6,0.7,1.3,1.5,2.2,2.4c-0.7,1-1.4,1.8-2.1,2.4
									C1299.4,888.6,1298.7,887.8,1297.9,887.1z"
						/>
						<path
							d="M1306.9,876.4c0.6-0.8,1.2-1.5,1.8-2.1c0.6,0.7,1.2,1.4,1.9,2c-0.5,0.8-1.2,1.5-1.8,2.1
									C1308.3,877.7,1307.6,877,1306.9,876.4z M1308.2,894.1c-0.2-0.4-0.4-1.1-0.4-2.1c0-0.8,0-2,0-3.5c0-1.4,0-2.5,0-3.1
									c0-0.9-0.1-1.7-0.2-2.5s-0.3-1.3-0.5-1.6c0.6-0.3,1.1-0.4,1.6-0.5c0.5-0.1,1.1-0.2,1.7-0.2c-0.1,0.6-0.2,1.6-0.2,3
									c0,1.3-0.1,2.8,0,4.5c0,1.2,0.1,2.2,0.2,2.8c0.1,0.6,0.2,1.2,0.4,1.5c0.2,0.4,0.4,0.8,0.8,1.2c-0.3,0.4-0.7,0.7-1,0.9
									c-0.4,0.2-0.7,0.3-1,0.3C1308.9,894.7,1308.4,894.5,1308.2,894.1z"
						/>
						<path
							d="M1317.6,894.2c0.1-2.2,0.1-4.6,0.1-7.1c0-1.2-0.1-2.2-0.1-2.8c-0.1-0.6-0.2-1.2-0.4-1.5c-0.2-0.4-0.4-0.8-0.8-1.2
									c0.3-0.4,0.6-0.7,1-0.9c0.4-0.2,0.7-0.3,1-0.3c1.1,0,1.7,0.6,1.9,2c0.5-0.7,1.1-1.2,1.8-1.6c0.7-0.4,1.4-0.6,2.2-0.6
									c2.3,0,3.5,1.5,3.6,4.5l0,2.1c0,0.8-0.1,1.6-0.1,2.2c0,2.3,0.3,3.9,0.7,5c-0.6,0.1-1.1,0.2-1.6,0.2c-0.5,0-1.1-0.1-1.8-0.2
									c0.1-2.7,0.1-5.6,0.1-8.7c0-0.9-0.2-1.5-0.5-1.9c-0.3-0.4-0.9-0.6-1.6-0.6c-1.1,0-2,0.3-2.7,0.8l0,1.4c0,1.1-0.1,2.5,0,4
									c0,2.3,0.3,4,0.7,5c-0.6,0.1-1.2,0.2-1.7,0.2C1318.9,894.4,1318.3,894.4,1317.6,894.2z"
						/>
						<path
							d="M1333.5,886.5c0.6-0.9,1.3-1.7,2.1-2.5c0.5,0.7,1.3,1.5,2.2,2.4c-0.7,1-1.4,1.8-2.1,2.4
									C1335,888,1334.3,887.2,1333.5,886.5z"
						/>
						<path
							d="M1343.3,888.8c0-1.2,0-2.8,0-4.8c0-3.7-0.2-6.2-0.6-7.6c1.1,0.1,2.7,0.2,4.8,0.2c2.7,0,4.7-0.1,6.2-0.3
									c0,0.6,0,1.2-0.2,1.9c-0.1,0.7-0.3,1.2-0.4,1.4c-0.5-0.3-1.1-0.5-1.9-0.6c-0.8-0.1-1.5-0.2-2.3-0.2c-1.1,0-2,0.1-2.9,0.3
									c-0.1,1.9-0.1,3.5-0.1,5l0.8,0c0.8,0,1.3,0.1,1.7,0.1c1,0,1.7,0,2.2-0.1c0.5-0.1,1.1-0.2,1.7-0.3c0.1,0.4,0.1,0.9,0.1,1.2
									c0,0.3,0,0.7-0.1,1.3c-2.2-0.1-3.9-0.2-5.2-0.2c-0.6,0-1,0-1.2,0c0,2.4,0.1,4.1,0.2,5.1c1,0.2,2,0.3,3.2,0.3
									c0.9,0,1.8-0.1,2.6-0.3c0.9-0.2,1.6-0.4,2.2-0.7c0,0.6,0,1.2-0.2,1.9c-0.1,0.7-0.3,1.2-0.4,1.4c-1.3-0.1-3.1-0.2-5.5-0.2
									c-2.1,0-3.7,0.1-4.8,0.3C1343.2,891.7,1343.3,890,1343.3,888.8z"
						/>
						<path
							d="M1358.9,875.7c0.6-0.8,1.2-1.5,1.8-2.1c0.6,0.8,1.2,1.4,1.9,2.1c-0.6,0.8-1.2,1.5-1.8,2.1
									C1360.2,877.1,1359.6,876.4,1358.9,875.7z M1360,893.5c-0.2-0.4-0.4-1.1-0.4-2.1c0-0.8,0-2,0-3.5c0-1.4,0-2.5,0-3.1
									c0-0.9-0.1-1.7-0.2-2.5c-0.1-0.8-0.3-1.3-0.5-1.6c0.6-0.2,1.1-0.4,1.6-0.5s1.1-0.2,1.7-0.2c-0.1,0.6-0.2,1.6-0.2,3
									c-0.1,1.3-0.1,2.8-0.1,4.5c0,1.2,0,2.2,0.1,2.8c0.1,0.6,0.2,1.2,0.3,1.5c0.2,0.4,0.4,0.8,0.7,1.2c-0.3,0.4-0.7,0.7-1,0.9
									c-0.4,0.2-0.7,0.3-1,0.3C1360.6,894.1,1360.2,893.9,1360,893.5z"
						/>
						<path
							d="M1369.2,893.4c-0.2-0.4-0.4-1.1-0.4-2.1c0-0.7,0-1.8,0-3.3c0-1.7,0-3.1,0-4.2c0-3.6-0.2-6.1-0.6-7.7
									c0.6-0.2,1.1-0.4,1.6-0.5c0.5-0.1,1.1-0.2,1.7-0.2c-0.2,2.8-0.3,6.7-0.3,11.8c0,1.2,0,2.1,0.1,2.8c0.1,0.7,0.2,1.2,0.4,1.5
									c0.2,0.4,0.4,0.8,0.8,1.2c-0.3,0.4-0.7,0.7-1,0.9c-0.4,0.2-0.7,0.3-1,0.3C1369.9,894,1369.4,893.8,1369.2,893.4z"
						/>
						<path
							d="M1378.4,894.2c0.1-2.4,0.1-4.9,0.1-7.6c0-1.2,0-2.2-0.1-2.8c-0.1-0.6-0.2-1.2-0.3-1.5c-0.2-0.4-0.4-0.8-0.7-1.2
									c0.3-0.4,0.7-0.7,1-0.9c0.4-0.2,0.7-0.3,1-0.3c0.9,0,1.4,0.6,1.6,1.7c0.5-0.5,1.1-1,1.8-1.3s1.3-0.5,2-0.5c1.6,0,2.9,0.6,3.7,1.7
									c0.8,1.1,1.3,2.8,1.3,5c0,2.3-0.5,4.1-1.6,5.4c-1.1,1.3-2.6,2-4.6,2c-1,0-1.9-0.2-2.6-0.6c0.1,2.4,0.2,4.3,0.5,5.7
									c-1,0.4-2.1,0.7-3.3,0.7C1378.2,898.4,1378.3,896.5,1378.4,894.2z M1386,891.2c0.6-0.8,0.9-2,0.9-3.5c0-1.4-0.3-2.6-0.8-3.6
									c-0.5-1-1.2-1.7-2-2c-0.5,0-1,0.1-1.6,0.2c-0.5,0.2-1,0.4-1.5,0.6c0,0.4,0,1-0.1,2c-0.1,2.9-0.1,4.5-0.1,5l0,1.1
									c0.3,0.4,0.7,0.8,1.2,1c0.5,0.2,0.9,0.4,1.4,0.4C1384.5,892.5,1385.4,892,1386,891.2z"
						/>
						<path
							d="M1395.1,893.6c0.1-2.8,0.2-5.7,0.2-8.5c0-3.9-0.1-6.9-0.3-9.1c1-0.4,2.1-0.7,3.3-0.7c-0.1,1-0.3,3.2-0.4,6.5
									c0.6-0.7,1.2-1.2,1.9-1.6c0.7-0.4,1.4-0.6,2.2-0.6c2.3,0,3.5,1.5,3.5,4.6l-0.1,2.1l-0.1,2.2c0,2.3,0.2,4,0.6,5
									c-0.6,0.1-1.1,0.2-1.6,0.2c-0.5,0-1.1-0.1-1.8-0.2c0.1-3.2,0.2-6.1,0.2-8.7c0-0.9-0.2-1.5-0.5-1.9c-0.3-0.4-0.9-0.6-1.6-0.6
									c-1.1,0-2,0.3-2.8,0.9c0,0.6-0.1,1.6-0.1,2.7c0,4.2,0.2,6.7,0.6,7.6c-0.5,0.1-1.1,0.2-1.6,0.2
									C1396.3,893.8,1395.8,893.8,1395.1,893.6z"
						/>
						<path
							d="M1226.8,900.3l0.3,0.4c-1.2,1.4-2.2,3.1-2.8,5c-0.6,1.9-0.9,4-0.9,6.2c0,2.2,0.4,4.2,1.1,6.1c0.7,1.9,1.6,3.5,2.9,4.9
									l-0.3,0.4c-1.9-1.4-3.4-3.1-4.5-5.1c-1.1-2-1.7-4.1-1.7-6.4s0.5-4.4,1.5-6.4C1223.5,903.5,1224.9,901.8,1226.8,900.3z"
						/>
						<path
							d="M1231.7,921.3c0-0.5,0-0.9,0.1-1.2c0.1-0.4,0.2-0.7,0.4-1.1c0.5,0.5,1.1,0.9,1.9,1.2c0.8,0.3,1.7,0.4,2.6,0.4
									c1.1,0,1.9-0.3,2.5-0.7s0.8-1.1,0.8-2c0-0.7-0.3-1.3-0.9-1.8c-0.6-0.5-1.4-1-2.6-1.7c-1.1-0.6-1.9-1.1-2.5-1.5
									c-0.6-0.4-1.2-1-1.6-1.7c-0.5-0.7-0.7-1.5-0.7-2.5c0-1.3,0.5-2.3,1.6-3.2c1.1-0.8,2.5-1.2,4.4-1.3c1.7,0,3.1,0.2,4.2,0.7
									c0,0.5-0.1,1-0.2,1.7c-0.1,0.6-0.3,1.1-0.5,1.5c-0.9-0.7-1.7-1.2-2.6-1.6c-0.9-0.4-1.6-0.6-2.2-0.6c-0.4,0.1-0.8,0.4-1.2,0.8
									s-0.6,1-0.6,1.6c0,0.8,0.3,1.4,0.9,2c0.6,0.5,1.5,1.1,2.7,1.7c1,0.5,1.8,1,2.4,1.4c0.6,0.4,1.1,0.9,1.6,1.6
									c0.4,0.7,0.7,1.4,0.7,2.4c0,1.6-0.4,2.8-1.4,3.7s-2.5,1.3-4.4,1.4C1234.9,922.5,1233.2,922.1,1231.7,921.3z"
						/>
						<path
							d="M1249.7,919.4l-1.2-4c-0.3-1-0.5-1.8-0.7-2.6c-0.3-0.9-0.6-1.7-0.9-2.5c-0.3-0.7-0.6-1.2-0.9-1.5c0.5-0.3,1-0.4,1.6-0.6
									c0.5-0.1,1.1-0.2,1.8-0.2c0.2,1,0.7,3,1.5,6s1.4,4.9,1.7,5.5c1.4-4.9,2.2-8,2.5-9.1c-0.4-0.9-0.7-1.5-1-1.8c1-0.5,2.1-0.7,3.4-0.8
									c0.1,1,0.6,3,1.5,6.3c0.9,3.2,1.4,5,1.6,5.4c1.1-3.5,1.8-6,2.1-7.5c0.4-1.4,0.5-2.5,0.5-3.1c0-0.2,0-0.5-0.1-0.8
									c0.7-0.3,1.6-0.4,2.7-0.4c-0.6,1.4-1.2,3.3-1.9,5.6c-0.7,2.3-1.4,4.8-2.1,7.5l-1.6,1.1c-0.6,0-1.1-0.2-1.5-0.7
									c-0.4-0.4-0.8-1.1-1-2c-0.8-2.9-1.4-4.9-1.7-6.1c-0.6,1.9-1.3,4.6-2.2,7.9l-1.6,1.1C1251.1,922.1,1250.2,921.2,1249.7,919.4z"
						/>
						<path
							d="M1270.5,921c-0.5-0.6-0.8-1.3-0.8-2.2c0-2.7,2.3-4.2,7-4.7c0-1.5-0.2-2.5-0.6-3.1c-0.4-0.6-1.1-0.8-2.1-0.8
									c-0.6,0-1.2,0.1-1.9,0.4c-0.6,0.2-1.2,0.5-1.7,0.9l-0.3-0.6c0.5-1,1.2-1.8,2.1-2.4c0.9-0.6,1.8-0.9,2.8-1c1.3,0,2.3,0.3,3,1.1
									c0.7,0.8,1.1,1.9,1.1,3.3c0,0.8,0,1.7-0.1,2.7c-0.1,1.1-0.1,1.9-0.1,2.4c0,0.8,0.1,1.5,0.3,2c0.2,0.5,0.5,1,1,1.5
									c-0.7,0.7-1.5,1.2-2.4,1.3c-0.5-0.4-0.9-1-1.2-1.8c-0.7,0.6-1.5,1-2.2,1.4c-0.8,0.3-1.5,0.5-2.2,0.5
									C1271.6,921.8,1271,921.5,1270.5,921z M1275.1,919.7c0.6-0.3,1-0.6,1.5-1l0-0.2c0-0.7,0-1.4,0.1-2.1l0.1-1.4
									c-1.4,0.1-2.4,0.4-3.1,1c-0.7,0.6-1.1,1.3-1.1,2.2c0,0.4,0.1,0.7,0.3,1.1c0.2,0.4,0.4,0.6,0.7,0.8
									C1274,920.1,1274.5,920,1275.1,919.7z"
						/>
						<path
							d="M1286.6,921.2c0.1-2.2,0.1-4.6,0.1-7.1c0-1.2-0.1-2.2-0.2-2.8c-0.1-0.6-0.2-1.1-0.4-1.5c-0.2-0.4-0.4-0.8-0.8-1.2
									c0.3-0.4,0.6-0.7,1-0.9c0.4-0.2,0.7-0.3,1-0.3c1.1,0,1.7,0.6,1.9,2c0.5-0.7,1.1-1.2,1.8-1.6c0.7-0.4,1.4-0.6,2.2-0.6
									c2.3,0,3.5,1.5,3.6,4.5l0,2.1c0,0.8-0.1,1.6,0,2.2c0,2.3,0.3,3.9,0.7,5c-0.6,0.1-1.1,0.2-1.6,0.2c-0.5,0-1.1,0-1.8-0.2
									c0.1-2.7,0.1-5.6,0-8.7c0-0.9-0.2-1.5-0.5-1.9c-0.3-0.4-0.9-0.6-1.6-0.6c-1.1,0-2,0.3-2.7,0.8l0,1.4c0,1.1,0,2.5,0,4
									c0,2.3,0.3,4,0.7,5c-0.6,0.1-1.2,0.2-1.7,0.2C1287.9,921.4,1287.3,921.3,1286.6,921.2z"
						/>
						<path
							d="M1303.3,925.8c0.4-0.6,0.7-1.1,0.9-1.7c0.2-0.5,0.3-1.2,0.4-2.1c0.1-0.9,0.1-2.1,0.1-3.6c0-1.8-0.2-4.8-0.5-9.1
									c-0.7,0-1.1-0.1-1.4-0.2c-0.2-0.1-0.4-0.3-0.4-0.6c0.8-0.5,1.3-0.9,1.7-1.2c0.2-2,0.7-3.5,1.5-4.6c0.9-1.1,2.1-1.6,3.9-1.7
									c0.9,0,1.7,0.1,2.5,0.2c0.8,0.2,1.7,0.5,2.6,0.9c-0.2,2.4-0.2,6.3,0,11.8c0,1.2,0.1,2.2,0.2,2.8c0.1,0.6,0.2,1.1,0.4,1.5
									c0.2,0.4,0.4,0.8,0.8,1.1c-0.3,0.4-0.7,0.7-1,0.9c-0.4,0.2-0.7,0.3-1,0.3c-0.6,0-1-0.2-1.3-0.5c-0.2-0.4-0.4-1.1-0.4-2.1l-0.1-3.3
									c0-1.7,0-3.1-0.1-4.2c-0.1-3-0.3-5.3-0.5-6.8c-1.5-0.9-2.8-1.3-3.9-1.3c-0.3,0.3-0.6,0.8-0.7,1.5c-0.1,0.7-0.2,1.9-0.2,3.4
									c1.7-0.1,2.8-0.2,3.4-0.4c0,0.4,0,0.8-0.1,1.2c-0.1,0.4-0.2,0.7-0.4,1.1c-0.7-0.2-1.7-0.3-3-0.3l0.1,2.3c0,1.3,0.1,2.9,0.2,4.7
									c0.1,0.9,0.1,1.7,0.2,2.4c0,1.1-0.1,2-0.2,2.8s-0.5,1.5-1.1,2.2c-0.5,0.7-1.3,1.5-2.4,2.5L1303.3,925.8z"
						/>
						<path
							d="M1322,918.9c-0.9-1.1-1.4-2.8-1.5-4.9c-0.1-1.5,0.1-2.8,0.6-3.9c0.5-1.2,1.1-2.1,2-2.8s1.9-1.1,3.1-1.1
									c1.6-0.1,2.8,0.4,3.7,1.6c0.9,1.1,1.4,2.8,1.5,5.1c-1,0.3-2.3,0.7-3.8,0.9c-1.5,0.3-3,0.5-4.4,0.6c0.2,1.3,0.6,2.3,1.1,2.9
									c0.6,0.6,1.4,0.8,2.5,0.8c0.8,0,1.6-0.2,2.3-0.4c0.7-0.2,1.3-0.6,1.8-1l0.2,0.6c-0.6,0.9-1.4,1.7-2.4,2.3s-2,0.9-3.1,1
									C1324.1,920.6,1322.9,920,1322,918.9z M1326,912.6c0.9-0.3,1.7-0.6,2.2-1c-0.1-1.2-0.4-2.1-1.1-2.8c-0.6-0.7-1.4-1.1-2.3-1
									c-0.6,0.4-1.1,1.1-1.4,2.1c-0.3,1-0.5,2.1-0.4,3.4C1324.1,913.1,1325.1,912.9,1326,912.6z"
						/>
						<path
							d="M1337.8,918c-0.9-1.1-1.5-2.7-1.6-4.9c-0.1-1.5,0.1-2.8,0.5-4c0.4-1.2,1.1-2.1,2-2.8c0.9-0.7,1.9-1.1,3.1-1.2
									c1.6-0.1,2.9,0.4,3.8,1.5c0.9,1.1,1.5,2.8,1.6,5c-1,0.4-2.3,0.7-3.8,1c-1.5,0.3-2.9,0.6-4.4,0.7c0.2,1.3,0.6,2.3,1.2,2.9
									c0.6,0.6,1.4,0.8,2.5,0.7c0.8-0.1,1.6-0.2,2.3-0.5c0.7-0.3,1.3-0.6,1.8-1l0.3,0.6c-0.6,0.9-1.3,1.7-2.3,2.3c-1,0.6-2,1-3.1,1.1
									C1340,919.6,1338.8,919.1,1337.8,918z M1341.7,911.6c0.9-0.3,1.7-0.7,2.2-1c-0.1-1.2-0.5-2.1-1.1-2.8c-0.6-0.7-1.4-1-2.3-1
									c-0.6,0.4-1,1.2-1.3,2.2c-0.3,1-0.4,2.1-0.3,3.4C1339.8,912.2,1340.8,911.9,1341.7,911.6z"
						/>
						<path
							d="M1354.4,917.8c-0.3-0.4-0.5-1-0.6-2c-0.1-0.7-0.1-1.8-0.3-3.4c-0.1-1.7-0.2-3.1-0.3-4.2c0-0.5-0.1-1-0.2-1.5
									c-0.8,0.1-1.3,0.1-1.6,0s-0.4-0.3-0.5-0.6c1.2-0.9,2.3-2.2,3.4-3.7l0.7,0c0.1,0.7,0.3,1.5,0.4,2.3c1.3-0.1,2.3-0.3,2.9-0.5
									c0,0.5,0,0.9,0,1.2c0,0.4-0.1,0.7-0.3,1.1c-0.6-0.2-1.4-0.2-2.5-0.2c0,1,0.1,2,0.2,3.1c0.3,2.7,0.6,4.8,1,6.2
									c0.9-0.2,1.6-0.3,2.2-0.6l0.3,0.7c-0.3,0.7-0.8,1.2-1.4,1.6s-1.2,0.7-1.9,0.7C1355.2,918.3,1354.7,918.1,1354.4,917.8z"
						/>
						<path
							d="M1366.1,900c1.3,1.9,2,3.9,2.3,6.2c0.2,2.2-0.1,4.4-0.9,6.5c-0.8,2.1-2.1,4-3.8,5.6l-0.4-0.4c1.1-1.5,1.9-3.3,2.3-5.2
									c0.4-2,0.5-4,0.3-6.2c-0.2-2.2-0.8-4.2-1.6-6c-0.8-1.8-2-3.4-3.4-4.6l0.3-0.5C1363.1,896.6,1364.8,898.1,1366.1,900z"
						/>
					</g>
					<g className="level_one location " id="gap_of_rohan">
						<rect x="644.783" y="778.796" width="158.36" height="27.102" transform="matrix(0.487768, -0.872973, 0.693725, 0.808574, 536.783813, 1342.009644)"></rect>
						<path
							d="M1407.4,1420.8c-1.4,0.4-3.1,0.2-5.1-0.6c-1.4-0.6-2.5-1.4-3.4-2.3c-0.9-1-1.4-2-1.7-3.1c-0.2-1.1-0.1-2.3,0.4-3.4
									c0.6-1.3,1.2-2.4,2-3.2c0.3,0.1,0.7,0.4,1.1,0.7s0.7,0.6,0.9,0.9c-0.8,0.5-1.5,1-2.1,1.6c-0.6,0.6-1.1,1.1-1.2,1.6
									c-0.1,0.5,0.1,1.1,0.4,1.8s0.9,1.3,1.5,1.8c0.7,0.6,1.4,1,2.2,1.4c1.8,0.8,3.3,1,4.4,0.8c1.1-0.3,1.9-1.1,2.5-2.4
									c0.3-0.6,0.4-1.2,0.5-1.8c-2.7-1.1-4.4-1.7-5.1-1.7c0.1-0.4,0.2-0.8,0.3-1.2c0.2-0.4,0.4-0.8,0.7-1.2c1.6,0.8,3.3,1.6,5.1,2.4
									c0.3,0.7,0.4,1.5,0.4,2.5s-0.2,1.8-0.6,2.6C1409.9,1419.3,1408.8,1420.4,1407.4,1420.8z"
						/>
						<path
							d="M1414.1,1407.7c-0.6,0.2-1.2,0.1-1.9-0.2c-2-0.9-2.3-3.1-1-6.7c-1.1-0.5-1.9-0.7-2.4-0.6c-0.5,0.1-1,0.5-1.3,1.2
									c-0.2,0.5-0.3,0.9-0.4,1.5s0,1.1,0.1,1.6l-0.5,0c-0.5-0.7-0.9-1.5-1-2.4c-0.1-0.9-0.1-1.7,0.3-2.4c0.4-0.9,1-1.6,1.8-1.8
									c0.8-0.3,1.7-0.2,2.8,0.3c0.6,0.3,1.2,0.6,1.9,1c0.8,0.4,1.3,0.7,1.7,0.9c0.6,0.3,1.1,0.4,1.5,0.5c0.4,0,0.9,0,1.4-0.2
									c0.3,0.8,0.3,1.5,0.1,2.2c-0.5,0.3-1,0.3-1.7,0.2c0.2,0.7,0.2,1.4,0.2,2.1c0,0.7-0.1,1.3-0.3,1.7
									C1415.1,1407.2,1414.7,1407.6,1414.1,1407.7z M1414.8,1404c0-0.5-0.1-1-0.2-1.4l-0.1-0.1c-0.5-0.2-1-0.5-1.5-0.7l-1-0.5
									c-0.4,1-0.5,1.9-0.3,2.6c0.2,0.7,0.6,1.2,1.2,1.5c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,0.9-0.2
									C1414.7,1404.9,1414.8,1404.5,1414.8,1404z"
						/>
						<path
							d="M1420.5,1395.7c-1.7-0.9-3.5-1.8-5.4-2.7c-0.9-0.4-1.6-0.7-2.1-0.9c-0.5-0.2-0.9-0.3-1.2-0.3c-0.3,0-0.7,0-1.1,0.1
									c-0.2-0.4-0.3-0.7-0.3-1.1c0-0.3,0-0.6,0.1-0.8c0.3-0.6,0.9-0.8,1.8-0.5c-0.2-0.6-0.3-1.2-0.3-1.7c0-0.6,0.1-1.1,0.4-1.6
									c0.6-1.2,1.4-1.9,2.5-2.1s2.5,0.1,4,0.8c1.7,0.8,2.8,1.8,3.3,3.1c0.6,1.2,0.5,2.6-0.2,4c-0.4,0.7-0.8,1.3-1.4,1.6
									c1.8,0.8,3.2,1.3,4.3,1.6c0,0.9-0.2,1.7-0.7,2.6C1423.5,1397.3,1422.2,1396.6,1420.5,1395.7z M1421,1389.2
									c-0.4-0.7-1.1-1.3-2.2-1.9c-1-0.5-1.9-0.7-2.8-0.7c-0.9,0-1.6,0.3-2.2,0.8c-0.2,0.4-0.3,0.8-0.4,1.2c-0.1,0.4-0.1,0.9-0.1,1.3
									c0.3,0.1,0.7,0.4,1.4,0.7c2,1,3.2,1.6,3.6,1.8l0.8,0.4c0.4-0.1,0.8-0.2,1.1-0.5c0.3-0.2,0.6-0.5,0.7-0.9
									C1421.4,1390.7,1421.4,1389.9,1421,1389.2z"
						/>
						<path
							d="M1429.7,1373.5c-1.3,0.2-2.6,0-4-0.8c-1-0.5-1.9-1.2-2.5-2.1c-0.6-0.8-0.9-1.7-1-2.6c-0.1-0.9,0.1-1.9,0.6-2.7
									c0.4-0.8,1-1.4,1.7-1.8c0.7-0.4,1.5-0.6,2.4-0.5c0.9,0.1,1.8,0.3,2.8,0.8c1,0.5,1.8,1.2,2.4,2.1s0.9,1.7,1,2.7s-0.1,1.9-0.6,2.8
									C1431.9,1372.6,1431,1373.3,1429.7,1373.5z M1431.8,1368.1c-0.4-0.8-1.1-1.5-2.2-2c-1-0.5-1.9-0.8-2.8-0.7c-0.9,0-1.7,0.3-2.3,0.9
									c-0.4,0.8-0.5,1.6-0.1,2.5c0.4,0.8,1.1,1.5,2.1,2.1c1.2,0.7,2.3,0.9,3.3,0.9c1-0.1,1.6-0.5,2-1.2
									C1432.2,1369.7,1432.2,1368.9,1431.8,1368.1z"
						/>
						<path
							d="M1440.7,1364.2c-0.2-0.5-0.5-1-0.8-1.3c-0.3-0.4-0.7-0.7-1.2-1.1c-0.6-0.4-1.4-0.9-2.4-1.5c-1.2-0.7-3.4-1.9-6.4-3.5
									c-0.3,0.5-0.5,0.8-0.7,0.9c-0.2,0.1-0.4,0.1-0.6,0c0-0.7,0-1.3-0.1-1.6c-1.2-0.9-2-1.9-2.4-3c-0.4-1.1-0.3-2.1,0.2-3
									c0.4-0.7,0.9-1.3,1.4-1.8c0.2,0.1,0.5,0.4,0.9,0.7c0.3,0.4,0.6,0.7,0.9,1.1c-0.7,0.2-1.3,0.4-1.8,0.7c-0.5,0.3-0.9,0.6-1,0.9
									c0,0.3,0.3,0.7,0.7,1.1c0.4,0.4,1.2,0.9,2.3,1.5c0.6-1.1,0.9-1.8,1.1-2.3c0.3,0.2,0.6,0.4,0.8,0.6c0.2,0.2,0.4,0.4,0.6,0.7
									c-0.4,0.4-0.9,0.9-1.3,1.7l1.6,0.9c0.9,0.5,2,1.1,3.3,1.8c0.7,0.3,1.2,0.6,1.7,0.9c0.8,0.4,1.4,0.9,1.8,1.3s0.8,1,1.1,1.6
									c0.3,0.6,0.5,1.5,0.7,2.7L1440.7,1364.2z"
						/>
						<path
							d="M1447.2,1345.5c-0.7-0.5-1.7-1.3-3-2.2c-1.3-0.9-2.5-1.7-3.6-2.4c-2.5-1.6-4-2.5-4.8-2.6c0.1-0.5,0.3-1.2,0.6-1.9
									c0.3-0.8,0.7-1.5,1.2-2.2c0.9-1.3,1.9-2.2,3-2.4s2.4,0,3.6,0.8c0.8,0.5,1.3,1.1,1.7,1.8c0.4,0.7,0.5,1.5,0.4,2.4
									c2.9,0.4,4.9,0.5,5.8,0.2c-0.1,0.5-0.2,0.9-0.4,1.4s-0.4,0.9-0.7,1.3c-1-0.2-2.8-0.5-5.4-0.8c0,0.1-0.1,0.2-0.2,0.4
									c-0.2,0.3-0.4,0.6-0.6,0.8c-0.2,0.3-0.4,0.5-0.6,0.6c2.2,1.4,3.6,2.2,4.4,2.4c-0.1,0.4-0.3,0.8-0.6,1.2
									C1447.8,1344.8,1447.6,1345.2,1447.2,1345.5z M1444.5,1336.1c-0.3-0.6-0.7-1.1-1.3-1.5c-1.8-1.1-3.2-0.8-4.4,1
									c-0.3,0.4-0.4,0.8-0.5,1.2c1,0.8,2.5,1.8,4.5,3.1c1-0.7,1.6-1.3,2-1.8C1444.8,1337.4,1444.7,1336.8,1444.5,1336.1z"
						/>
						<path
							d="M1454.6,1332.1c-1.3,0.1-2.6-0.3-3.9-1.3c-1-0.7-1.7-1.5-2.2-2.3c-0.5-0.9-0.7-1.8-0.7-2.7c0-0.9,0.4-1.8,0.9-2.6
									c0.5-0.7,1.2-1.2,1.9-1.5c0.8-0.3,1.6-0.4,2.5-0.2c0.9,0.2,1.8,0.6,2.6,1.2c1,0.7,1.7,1.5,2.2,2.4c0.5,0.9,0.7,1.8,0.6,2.8
									c-0.1,1-0.4,1.9-1,2.7C1456.9,1331.4,1455.9,1332,1454.6,1332.1z M1457.4,1327c-0.3-0.8-0.9-1.6-1.9-2.3c-0.9-0.6-1.8-1-2.7-1.1
									c-0.9-0.1-1.7,0.1-2.4,0.6c-0.5,0.8-0.7,1.6-0.4,2.4c0.3,0.9,0.9,1.6,1.8,2.3c1.1,0.8,2.2,1.2,3.1,1.3s1.7-0.3,2.1-0.9
									C1457.5,1328.6,1457.7,1327.8,1457.4,1327z"
						/>
						<path
							d="M1463.7,1321.6c-1.7-1.5-3.5-2.9-5.3-4.3c-2.4-1.9-4.4-3.3-5.9-4.3c0.2-0.8,0.6-1.6,1.2-2.4c0.6,0.6,1.9,1.7,3.9,3.4
									c-0.1-0.7-0.2-1.3-0.1-2c0.1-0.6,0.3-1.2,0.7-1.7c1.1-1.5,2.7-1.5,4.6,0l1.3,1.1l1.4,1.1c1.5,1.1,2.6,1.8,3.4,2.1
									c-0.2,0.4-0.4,0.8-0.7,1.1c-0.2,0.3-0.6,0.6-1,1c-1.9-1.6-3.7-3.1-5.4-4.4c-0.6-0.4-1-0.6-1.5-0.6c-0.4,0-0.8,0.3-1.2,0.7
									c-0.5,0.7-0.8,1.4-0.8,2.2c0.4,0.3,1,0.8,1.7,1.4c2.6,2,4.3,3.2,5.1,3.3c-0.2,0.4-0.4,0.8-0.7,1.1
									C1464.4,1320.9,1464.1,1321.2,1463.7,1321.6z"
						/>
						<path
							d="M1472.3,1310c-0.6,0-1.2-0.2-1.8-0.7c-1.6-1.4-1.3-3.6,0.9-6.7c-0.9-0.8-1.6-1.2-2.2-1.2c-0.5-0.1-1.1,0.2-1.6,0.8
									c-0.3,0.4-0.6,0.8-0.8,1.3c-0.2,0.5-0.3,1-0.3,1.5l-0.5-0.2c-0.3-0.8-0.4-1.7-0.3-2.6c0.1-0.9,0.4-1.6,0.9-2.2
									c0.7-0.8,1.4-1.2,2.3-1.2c0.8,0,1.7,0.3,2.6,1.1c0.5,0.4,1,0.9,1.6,1.5c0.6,0.6,1.1,1,1.4,1.3c0.5,0.4,1,0.7,1.3,0.9
									c0.4,0.1,0.8,0.2,1.4,0.2c0.1,0.8-0.1,1.5-0.5,2.1c-0.5,0.1-1.1,0-1.7-0.3c0,0.7-0.2,1.4-0.4,2.1c-0.2,0.6-0.5,1.2-0.8,1.6
									C1473.5,1309.8,1473,1310,1472.3,1310z M1474,1306.6c0.1-0.5,0.2-1,0.2-1.4l-0.1-0.1c-0.4-0.4-0.8-0.7-1.2-1.1l-0.8-0.8
									c-0.6,0.9-1,1.7-1,2.4c-0.1,0.7,0.2,1.3,0.8,1.8c0.2,0.2,0.5,0.3,0.8,0.4c0.3,0.1,0.6,0.1,0.9,0
									C1473.7,1307.4,1473.9,1307,1474,1306.6z"
						/>
						<path
							d="M1481.7,1299.8c-1.2-1.3-2.6-2.6-4.1-4c-0.7-0.7-1.3-1.1-1.7-1.5c-0.4-0.3-0.8-0.5-1.1-0.6c-0.3-0.1-0.7-0.2-1.1-0.2
									c-0.1-0.4-0.1-0.8,0-1.1c0.1-0.3,0.2-0.6,0.4-0.7c0.6-0.6,1.3-0.6,2.2,0c-0.1-0.7-0.1-1.3,0.1-1.9c0.2-0.6,0.4-1.2,0.8-1.6
									c1.3-1.4,2.8-1.2,4.6,0.4l1.2,1.2c0.5,0.5,0.9,0.9,1.3,1.3c1.3,1.2,2.4,2,3.3,2.3c-0.2,0.4-0.5,0.7-0.7,1c-0.3,0.3-0.6,0.6-1.1,1
									c-1.5-1.5-3.2-3.2-5-4.9c-0.5-0.5-1-0.7-1.4-0.8c-0.4,0-0.8,0.2-1.2,0.6c-0.6,0.6-0.9,1.3-1,2l0.8,0.8c0.6,0.6,1.4,1.4,2.3,2.2
									c1.4,1.3,2.5,2.1,3.3,2.4c-0.2,0.4-0.5,0.8-0.8,1.1C1482.5,1299.2,1482.1,1299.5,1481.7,1299.8z"
						/>
					</g>
					<g className="level_two fade location" id="udun">
						<rect x="1050.489" y="1161.624" width="68.211" height="25.555" transform="matrix(0.955186, 0.296007, -0.307515, 0.951619, 1505.694336, 65.597748)"></rect>
						<path
							d="M2154.8,1504.6c-0.7-1.3-0.8-3-0.3-5.1c0.5-2.1,1-4.2,1.4-6.3c0.4-2,0.6-3.4,0.6-4.1c0.5,0,1,0.1,1.5,0.2
									c0.5,0.1,1,0.3,1.6,0.6c-0.9,3-1.7,6.4-2.7,10.3c-0.7,2.9,0.1,4.6,2.3,5.1c2.4,0.6,3.9-0.4,4.5-3c1.4-5.9,2.2-9.5,2.4-10.8
									c0.4,0,0.8,0,1.1,0.1c0.3,0.1,0.7,0.2,1.1,0.5c-0.3,1.2-0.8,2.8-1.3,4.7c-0.5,2-1,3.7-1.4,5.3c-0.5,1.9-1.3,3.4-2.5,4.4
									c-1.2,1-2.9,1.3-5,0.7C2156.7,1506.9,2155.5,1506,2154.8,1504.6z"
						/>
						<path
							d="M2168.5,1508.3c-0.5-1.3-0.4-3.1,0.1-5.2c0.6-2.2,1.4-3.8,2.5-4.8s2.4-1.2,4-0.8c1.2,0.3,2.3,1,3.1,2.1
									c0.4-2.1,0.7-3.7,0.7-4.8c0.6-0.1,1.2-0.1,1.7-0.1s1,0.1,1.6,0.3c-0.9,2.5-2.2,6.9-3.8,13c-0.2,0.7-0.3,1.3-0.2,1.8
									c0,0.5,0.2,1,0.5,1.6c-0.8,0.5-1.6,0.6-2.5,0.5c-0.4-0.5-0.6-1.2-0.6-2c-0.7,0.4-1.3,0.7-2,0.9c-0.7,0.1-1.4,0.1-2,0
									C2170,1510.4,2169,1509.6,2168.5,1508.3z M2174.1,1509.4c0.5-0.1,1.1-0.3,1.6-0.5c0.2-0.6,0.5-1.7,0.9-3.3c0.5-1.7,0.9-3,1.1-4
									c-0.6-0.7-1.4-1.2-2.4-1.5c-1-0.3-1.8-0.1-2.5,0.6c-0.7,0.6-1.3,1.7-1.7,3.1c-0.4,1.4-0.4,2.6-0.2,3.6c0.3,1,0.8,1.7,1.6,2.1
									C2173.1,1509.5,2173.6,1509.5,2174.1,1509.4z"
						/>
						<path
							d="M2182.8,1509.2l0.6-1.9l0.6-2.1c0.6-2,0.8-3.5,0.7-4.5c1-0.2,2.1-0.1,3.2,0.2c-0.8,2.5-1.7,5.4-2.6,8.6
									c-0.2,0.8-0.2,1.5,0,1.9c0.2,0.5,0.7,0.8,1.4,1c0.9,0.3,1.8,0.3,2.7,0c0.4-1.4,0.8-2.6,1.1-3.7l0.5-1.7c0.6-2,0.8-3.5,0.7-4.5
									c1-0.2,2.1-0.1,3.3,0.3c-0.5,1.4-1,3.1-1.7,5c-0.6,1.9-1,3.2-1.2,3.9c-0.2,0.7-0.3,1.4-0.3,1.8c0,0.5,0.2,1,0.5,1.6
									c-0.8,0.4-1.7,0.6-2.5,0.5c-0.4-0.6-0.6-1.3-0.6-2.2c-0.7,0.4-1.4,0.7-2.2,0.9c-0.8,0.1-1.5,0.1-2.2-0.1
									C2182.6,1513.8,2182,1512,2182.8,1509.2z M2187.1,1498.5c1.3-1,2.5-2.1,3.6-3c0.8-0.1,1.6,0,2.6,0.3c0.2,1.1,0.4,2,0.6,2.6
									c0.2,0.6,0.4,1.3,0.8,2.1c-0.9,0.1-1.8,0-2.7-0.3l-0.9-3.1l-2.3,2.2C2188.2,1499.1,2187.6,1498.8,2187.1,1498.5z"
						/>
						<path
							d="M2195.7,1517.1c0.8-2,1.5-4.2,2.2-6.5c0.3-1.1,0.6-2,0.7-2.6c0.1-0.6,0.2-1.1,0.1-1.5c0-0.4-0.2-0.8-0.4-1.3
									c0.4-0.3,0.8-0.5,1.2-0.6c0.4-0.1,0.7-0.1,1,0c1,0.3,1.4,1.1,1.1,2.4c0.7-0.5,1.4-0.8,2.1-0.9s1.5-0.1,2.2,0.1
									c2.2,0.7,2.8,2.4,2,5.2l-0.6,1.9c-0.3,0.7-0.5,1.4-0.7,2c-0.6,2.1-0.9,3.7-0.8,4.8c-0.6,0-1.1-0.1-1.5-0.3c-0.5-0.1-1-0.4-1.6-0.7
									c0.9-2.4,1.8-5.1,2.6-8c0.2-0.8,0.3-1.5,0.1-1.9c-0.2-0.4-0.6-0.8-1.3-1c-1-0.3-1.9-0.3-2.7-0.1l-0.4,1.3c-0.4,1-0.8,2.3-1.2,3.7
									c-0.7,2.2-0.9,3.8-0.8,4.8c-0.6,0-1.1-0.1-1.6-0.3C2196.8,1517.7,2196.3,1517.4,2195.7,1517.1z"
						/>
					</g>
					<g className="level_two fade location " id="isenmouthe">
						<rect x="2306.778" y="1161.624" width="149.785" height="25.555" transform="matrix(0.958198, 0.286107, -0.297651, 0.954751, 330.297302, -242.407837)"></rect>
						<path
							d="M2192,1547.7c0.4-1,0.9-2.4,1.5-4.3c0.6-1.8,1.2-3.6,1.7-5.3c1.2-3.9,1.7-6.3,1.7-7.3c0.6,0,1.1,0.1,1.6,0.3
									c0.5,0.1,1,0.4,1.6,0.7c-0.4,1-0.9,2.4-1.5,4.3c-0.6,1.8-1.2,3.6-1.7,5.2c-1.2,3.9-1.7,6.4-1.7,7.4c-0.6,0-1.1-0.1-1.7-0.3
									C2193.1,1548.2,2192.6,1548,2192,1547.7z"
						/>
						<path
							d="M2199.3,1549c0.1-0.4,0.3-0.8,0.5-1.1c0.2-0.3,0.4-0.7,0.8-1.1c0.2,0.6,0.6,1.2,1.1,1.7c0.5,0.5,1.1,0.8,1.8,1
									c1.8,0.5,2.9,0.2,3.2-0.9c0.1-0.5,0-0.9-0.3-1.4c-0.3-0.4-0.8-1-1.6-1.6c-0.7-0.7-1.3-1.3-1.7-1.7c-0.4-0.5-0.7-1.1-1-1.8
									s-0.2-1.4,0-2.3c0.3-1,0.9-1.7,1.9-2.1s2.1-0.4,3.4,0c1.7,0.5,3.1,1.2,4.1,2.1c-0.1,0.3-0.3,0.8-0.7,1.3c-0.3,0.5-0.7,1-1.1,1.3
									c-0.5-0.9-1-1.7-1.5-2.3c-0.5-0.6-1-1-1.5-1.1c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.6,0.5-0.7,0.8c-0.2,0.6-0.1,1.1,0.2,1.6
									c0.3,0.5,0.9,1.1,1.7,1.9c0.7,0.6,1.2,1.2,1.6,1.6c0.4,0.5,0.7,1,0.9,1.7c0.2,0.6,0.2,1.4,0,2.1c-0.4,1.2-1.1,2-2.1,2.4
									c-1,0.4-2.4,0.4-3.9-0.1C2201.5,1550.9,2200.2,1550.1,2199.3,1549z"
						/>
						<path
							d="M2213.2,1552.5c-0.5-1.4-0.4-3.1,0.2-5.1c0.4-1.4,1-2.6,1.8-3.5s1.8-1.6,2.8-2c1.1-0.4,2.2-0.4,3.3,0
									c1.5,0.4,2.5,1.3,3,2.7c0.5,1.4,0.4,3.1-0.2,5.3c-1.1,0-2.4-0.1-3.9-0.4c-1.5-0.2-3-0.5-4.4-0.9c-0.3,1.3-0.2,2.4,0.1,3.1
									c0.4,0.7,1,1.3,2.1,1.6c0.8,0.2,1.6,0.3,2.3,0.3c0.8,0,1.4-0.1,2-0.3l0,0.7c-0.8,0.7-1.8,1.1-3,1.4c-1.1,0.2-2.2,0.2-3.3-0.1
									C2214.7,1554.8,2213.6,1553.9,2213.2,1552.5z M2219.1,1547.8c1,0,1.8,0,2.4-0.2c0.3-1.1,0.3-2.1-0.1-3c-0.4-0.9-1-1.5-1.9-1.7
									c-0.7,0.2-1.4,0.7-2,1.6c-0.6,0.8-1.1,1.9-1.5,3.1C2217.1,1547.7,2218.1,1547.8,2219.1,1547.8z"
						/>
						<path
							d="M2227.3,1558.1c0.7-2.1,1.5-4.3,2.2-6.8c0.3-1.2,0.6-2.1,0.7-2.7c0.1-0.6,0.1-1.2,0.1-1.6s-0.2-0.8-0.4-1.3
									c0.4-0.3,0.8-0.5,1.2-0.6c0.4-0.1,0.7-0.1,1,0c1,0.3,1.4,1.1,1.2,2.4c0.7-0.5,1.4-0.8,2.2-1s1.5-0.1,2.2,0.1
									c2.2,0.6,3,2.4,2.1,5.4l-0.6,2c-0.3,0.8-0.5,1.5-0.7,2.1c-0.6,2.2-0.9,3.9-0.8,5c-0.6,0-1.1-0.1-1.6-0.2c-0.5-0.1-1.1-0.4-1.7-0.7
									c0.9-2.5,1.7-5.3,2.6-8.3c0.2-0.9,0.3-1.5,0.1-2c-0.2-0.5-0.7-0.8-1.4-1c-1.1-0.3-2-0.3-2.8,0l-0.4,1.4c-0.4,1.1-0.8,2.4-1.2,3.8
									c-0.6,2.2-0.9,3.9-0.8,5c-0.6,0-1.2-0.1-1.7-0.3C2228.5,1558.6,2228,1558.4,2227.3,1558.1z"
						/>
						<path
							d="M2243.5,1562.7c0.7-2.1,1.4-4.4,2.1-6.8c0.3-1.2,0.5-2.1,0.7-2.7c0.1-0.6,0.1-1.2,0.1-1.6c0-0.4-0.2-0.8-0.4-1.3
									c0.4-0.3,0.8-0.5,1.2-0.6c0.4-0.1,0.7-0.1,1,0c1,0.3,1.4,1.1,1.2,2.4c0.7-0.5,1.4-0.8,2.2-1c0.8-0.2,1.5-0.2,2.2,0
									c1.6,0.5,2.5,1.5,2.4,3.2c0.7-0.6,1.5-1,2.3-1.2c0.8-0.2,1.6-0.2,2.3,0c2.2,0.6,2.9,2.4,2.1,5.4l-0.6,2c-0.3,0.8-0.5,1.5-0.7,2.1
									c-0.6,2.2-0.9,3.9-0.7,5c-0.6,0-1.1-0.1-1.6-0.2c-0.5-0.1-1-0.4-1.7-0.7c0.9-2.5,1.7-5.3,2.6-8.3c0.2-0.9,0.3-1.5,0.1-2
									c-0.2-0.5-0.7-0.8-1.4-1c-1-0.3-1.9-0.3-2.7,0c-0.2,0.8-0.5,2-1,3.5c-0.1,0.4-0.3,1-0.5,1.7c-0.6,2.2-0.9,3.9-0.8,5
									c-0.6,0-1.1-0.1-1.6-0.2c-0.5-0.1-1-0.4-1.7-0.7c0.9-2.5,1.7-5.3,2.6-8.3c0.2-0.9,0.3-1.5,0.1-2c-0.2-0.5-0.7-0.8-1.4-1
									c-1-0.3-1.9-0.3-2.7,0l-0.4,1.4c-0.4,1.1-0.8,2.4-1.2,3.9c-0.6,2.2-0.9,3.9-0.8,5c-0.6,0-1.2-0.1-1.7-0.3
									C2244.7,1563.2,2244.1,1563,2243.5,1562.7z"
						/>
						<path
							d="M2266.9,1567.7c-0.6-1.5-0.7-3.2-0.2-5.1c0.4-1.4,1-2.6,1.8-3.6s1.8-1.6,2.9-2s2.3-0.4,3.5,0c1.1,0.3,2,0.8,2.6,1.6
									c0.7,0.8,1.1,1.7,1.3,2.8c0.2,1.1,0.1,2.3-0.3,3.6c-0.4,1.4-1,2.6-1.9,3.5s-1.9,1.6-3,1.9c-1.2,0.3-2.4,0.3-3.6,0
									C2268.7,1570.1,2267.5,1569.1,2266.9,1567.7z M2274,1568.8c0.8-0.7,1.5-1.7,1.9-3.2c0.4-1.3,0.4-2.5,0.1-3.7s-0.9-2-1.8-2.6
									c-1.1-0.3-2.1-0.1-3,0.5c-0.9,0.7-1.5,1.7-1.9,3.1c-0.5,1.7-0.5,3.1-0.1,4.2c0.4,1.1,1,1.9,2,2.1
									C2272.2,1569.6,2273.2,1569.4,2274,1568.8z"
						/>
						<path
							d="M2282.5,1569.1l0.6-2l0.6-2.1c0.6-2.1,0.8-3.7,0.7-4.6c1.1-0.2,2.2-0.1,3.4,0.2c-0.8,2.6-1.7,5.6-2.6,9
									c-0.2,0.9-0.2,1.5,0,2c0.2,0.5,0.7,0.8,1.4,1c0.9,0.3,1.9,0.2,2.8,0c0.4-1.4,0.8-2.7,1.1-3.8l0.5-1.8c0.6-2.1,0.8-3.7,0.7-4.7
									c1.1-0.2,2.2-0.1,3.4,0.2c-0.5,1.5-1,3.2-1.6,5.2c-0.6,2-1,3.3-1.2,4c-0.2,0.8-0.3,1.4-0.3,1.9c0,0.5,0.2,1.1,0.5,1.7
									c-0.9,0.5-1.7,0.6-2.6,0.5c-0.5-0.6-0.7-1.4-0.6-2.2c-0.7,0.5-1.4,0.8-2.2,0.9c-0.8,0.2-1.6,0.1-2.3-0.1
									C2282.4,1573.8,2281.7,1572,2282.5,1569.1z"
						/>
						<path
							d="M2297.8,1577.3c-0.2-0.4-0.1-1.1,0.1-2.1c0.2-0.7,0.5-1.7,0.9-3.2c0.5-1.6,0.9-3,1.1-4c0.1-0.5,0.2-1,0.3-1.5
									c-0.8-0.2-1.3-0.4-1.5-0.6c-0.2-0.2-0.3-0.4-0.2-0.7c1.5-0.5,2.9-1.2,4.4-2.3l0.7,0.3c-0.1,0.8-0.3,1.5-0.5,2.3
									c1.3,0.3,2.3,0.5,2.9,0.5c-0.1,0.5-0.3,0.8-0.4,1.2c-0.2,0.3-0.4,0.6-0.7,1c-0.5-0.4-1.3-0.7-2.3-1c-0.3,0.9-0.6,2-0.9,3
									c-0.7,2.6-1.1,4.7-1.2,6.1c0.9,0.2,1.7,0.2,2.2,0.2l0,0.8c-0.5,0.5-1.1,0.9-1.8,1.1s-1.4,0.2-2,0.1
									C2298.4,1578,2298,1577.7,2297.8,1577.3z"
						/>
						<path
							d="M2306.4,1579.8c0.9-2.7,1.6-5.4,2.4-8.2c1-3.7,1.6-6.7,2-8.8c1.1-0.2,2.2-0.1,3.4,0.2c-0.4,1-1.1,3-2,6.1
									c0.7-0.5,1.5-0.8,2.3-1c0.8-0.2,1.6-0.2,2.3,0c2.3,0.6,3,2.4,2.2,5.3l-0.6,2l-0.6,2.1c-0.6,2.3-0.8,3.9-0.7,5
									c-0.6,0-1.1-0.1-1.6-0.2c-0.5-0.1-1-0.3-1.7-0.6c0.9-3,1.7-5.8,2.4-8.4c0.2-0.9,0.2-1.5,0-2c-0.2-0.5-0.7-0.8-1.4-1
									c-1-0.3-2-0.2-2.9,0.1c-0.2,0.6-0.5,1.5-0.8,2.6c-1.1,4-1.5,6.5-1.3,7.5c-0.6,0-1.1-0.1-1.6-0.2
									C2307.5,1580.3,2307,1580.1,2306.4,1579.8z"
						/>
						<path
							d="M2322.7,1582.7c-0.5-1.3-0.5-3.1,0-5.1c0.4-1.4,0.9-2.6,1.7-3.6c0.8-1,1.7-1.7,2.8-2.1c1.1-0.4,2.2-0.4,3.3-0.1
									c1.5,0.4,2.6,1.3,3.1,2.6c0.5,1.3,0.5,3.1-0.1,5.3c-1.1,0-2.4-0.1-3.9-0.2c-1.5-0.2-3-0.4-4.4-0.7c-0.2,1.3-0.1,2.4,0.2,3.1
									c0.4,0.7,1.1,1.2,2.1,1.5c0.8,0.2,1.6,0.3,2.3,0.3c0.8,0,1.4-0.2,2-0.4l0,0.7c-0.8,0.7-1.8,1.2-2.9,1.5c-1.1,0.3-2.2,0.3-3.3,0
									C2324.2,1584.9,2323.2,1584,2322.7,1582.7z M2328.4,1577.8c1,0,1.8-0.1,2.4-0.3c0.3-1.1,0.2-2.2-0.2-3c-0.4-0.9-1-1.4-1.9-1.7
									c-0.7,0.2-1.4,0.8-2,1.6c-0.6,0.9-1.1,1.9-1.4,3.1C2326.4,1577.8,2327.4,1577.8,2328.4,1577.8z"
						/>
					</g>
					<g className="level_two fade location " id="morgai">
						<rect x="1493.617" y="1186.965" width="96.985" height="26.111" transform="matrix(-0.001843, -0.999998, 0.999998, -0.001843, 976.828979, 3130.63916)"></rect>
						<path
							d="M2180.3,1628.9c-2.3-0.3-5.7-0.7-10-1c-2.3-0.2-3.9-0.3-5.1-0.3s-1.9,0-2.4,0.1c-0.1-0.5-0.1-1.1-0.1-1.6
									c0-0.4,0.1-1,0.3-1.7c1.6-0.5,3.8-1.2,6.7-2.1c2.8-0.9,5.1-1.7,6.8-2.3c-1.6-0.9-3.7-2-6.2-3.2s-4.6-2.3-6.4-3.2
									c-0.1-0.4-0.1-1-0.1-1.7c0-0.5,0.2-1.1,0.3-1.7c1.1,0.2,2.6,0.4,4.4,0.6c1.9,0.2,3.7,0.4,5.5,0.5c4.1,0.3,6.7,0.4,7.6,0.1
									c0.1,0.5,0.1,1.1,0.1,1.6c0,0.5-0.2,1.1-0.3,1.7c-1.1-0.2-2.6-0.4-4.5-0.6c-1.9-0.2-3.7-0.4-5.4-0.5c-1.1-0.1-2.4-0.2-3.8-0.2
									c3.3,1.8,7.7,4.1,13.3,6.8c0.1,0.3,0.1,0.5,0.1,0.7c0,0.2-0.1,0.4-0.2,0.6c-1.5,0.4-3.5,1.1-6.2,1.9c-2.7,0.9-5,1.6-7.1,2.4
									c2.3,0.2,4.1,0.4,5.3,0.5c4,0.3,6.5,0.4,7.6,0.3c0.1,0.4,0.1,0.7,0.1,1.1C2180.6,1628,2180.5,1628.4,2180.3,1628.9z"
						/>
						<path
							d="M2180.5,1605c-1.3,1-2.9,1.4-4.9,1.3c-1.5-0.1-2.8-0.4-3.9-1c-1.1-0.6-2-1.4-2.6-2.4c-0.6-1-0.9-2.1-0.8-3.4
									c0-1.1,0.4-2.1,1-2.9s1.4-1.5,2.5-1.9c1-0.4,2.2-0.6,3.6-0.6c1.5,0.1,2.8,0.4,3.9,1s2,1.4,2.6,2.5c0.6,1,0.9,2.2,0.8,3.5
									C2182.4,1602.8,2181.8,1604.1,2180.5,1605z M2179.9,1597.8c-0.8-0.7-2-1-3.6-1.1c-1.4-0.1-2.6,0.2-3.6,0.7c-1,0.5-1.7,1.3-2.1,2.3
									c0,1.2,0.4,2.1,1.2,2.8c0.9,0.7,2,1.1,3.5,1.2c1.7,0.1,3.1-0.2,4.1-0.8c1-0.6,1.6-1.4,1.6-2.4
									C2181.2,1599.4,2180.8,1598.5,2179.9,1597.8z"
						/>
						<path
							d="M2182.5,1588.8c-2.2-0.2-4.6-0.2-7.1-0.3c-1.2,0-2.2,0-2.8,0.1c-0.6,0.1-1.2,0.2-1.5,0.3c-0.4,0.1-0.8,0.4-1.2,0.7
									c-0.4-0.3-0.7-0.7-0.9-1.1c-0.2-0.4-0.3-0.7-0.3-1c0-1.1,0.8-1.7,2.5-1.8c-0.7-0.5-1.3-1.1-1.7-1.7s-0.8-1.3-1-2
									c0.4-0.1,0.9-0.3,1.5-0.4c0.7-0.1,1.3-0.2,1.8-0.2c0,0.2-0.1,0.6-0.1,1c0,0.6,0.1,1.2,0.2,1.8c0.2,0.6,0.4,1.1,0.6,1.4l1,0
									c1.5,0.1,2.8,0.1,3.9,0.1c2.3,0,4-0.2,5-0.6c0.1,0.6,0.2,1.2,0.2,1.7S2182.6,1588.1,2182.5,1588.8z"
						/>
						<path
							d="M2187.1,1578.8c-0.8,0-1.6-0.1-2.4-0.4c-0.8-0.3-1.5-0.6-2.1-1.1c-0.3,0.8-0.9,1.2-1.8,1.2c-0.6,0-1.2-0.2-1.7-0.6
									s-0.9-1-1.2-1.7c-0.4,0.7-0.9,1.3-1.6,1.7c-0.7,0.4-1.5,0.6-2.5,0.6c-1.6,0-2.8-0.4-3.8-1.4s-1.5-2.2-1.5-3.7c0-1,0.2-1.9,0.7-2.7
									c-0.1-0.4-0.4-0.9-0.7-1.4c-0.4-0.5-0.8-1-1.3-1.5c0.4-0.2,0.9-0.4,1.4-0.6c0.5-0.2,0.9-0.2,1.3-0.3c0.4,0.6,0.6,1.4,0.7,2.4
									c0.8-0.4,1.7-0.6,2.6-0.7c1,0,1.9,0.2,2.7,0.6c0.8,0.4,1.4,1.1,1.9,1.9c0.5,0.8,0.7,1.8,0.7,2.8c0,0.9,0.2,1.5,0.5,2
									c0.3,0.5,0.7,0.7,1.2,0.7l0.2-5.6c0.1-1.1,0.4-1.9,0.9-2.5s1.3-0.9,2.2-0.9c0.9,0,1.7,0.2,2.4,0.8c0.7,0.5,1.3,1.3,1.7,2.2
									c0.4,0.9,0.7,2,0.7,3.2C2188.4,1575.9,2188,1577.6,2187.1,1578.8z M2176.2,1571.7c-0.5-0.5-1.2-0.7-2.1-0.7c-0.8,0-1.5,0.2-2,0.7
									c-0.6,0.4-0.9,1-1.1,1.8c0,0.8,0.3,1.5,0.9,2c0.6,0.5,1.4,0.8,2.3,0.7c0.9,0,1.6-0.3,2.1-0.8c0.5-0.5,0.8-1.1,0.8-1.9
									C2177,1572.8,2176.7,1572.1,2176.2,1571.7z M2185.6,1570.6c-0.4-0.6-0.8-1-1.4-0.9c-0.4,0-0.8,0.2-1,0.4c-0.2,0.3-0.3,0.8-0.3,1.4
									l-0.2,4.5c0.7,0.3,1.3,0.5,1.8,0.6c1.1-0.8,1.7-1.9,1.7-3.5C2186.2,1572.1,2186,1571.3,2185.6,1570.6z"
						/>
						<path
							d="M2181.6,1563.2c-0.6,0.5-1.3,0.8-2.2,0.9c-2.7,0.1-4.3-2.1-4.9-6.8c-1.5,0.1-2.5,0.3-3,0.7c-0.6,0.4-0.8,1.1-0.8,2.1
									c0,0.6,0.2,1.2,0.4,1.9c0.3,0.6,0.6,1.2,1,1.7l-0.6,0.3c-1-0.5-1.8-1.2-2.5-2.1c-0.7-0.9-1-1.8-1.1-2.8c-0.1-1.3,0.3-2.3,1-3.1
									c0.7-0.7,1.8-1.1,3.3-1.2c0.8,0,1.7,0,2.7,0c1.1,0,1.9,0,2.4,0c0.8,0,1.5-0.2,1.9-0.3c0.5-0.2,1-0.5,1.5-1
									c0.7,0.7,1.2,1.5,1.4,2.4c-0.4,0.6-1,1-1.8,1.2c0.6,0.7,1.1,1.4,1.4,2.2s0.6,1.5,0.6,2.1
									C2182.4,1562.1,2182.1,1562.7,2181.6,1563.2z M2180.2,1558.7c-0.3-0.5-0.6-1-1-1.4l-0.2,0c-0.7,0-1.4,0-2.1,0l-1.4,0
									c0.2,1.4,0.5,2.4,1.1,3.1c0.6,0.7,1.3,1,2.2,1c0.4,0,0.7-0.1,1.1-0.3c0.4-0.2,0.6-0.5,0.8-0.8
									C2180.6,1559.8,2180.5,1559.2,2180.2,1558.7z"
						/>
						<path
							d="M2163.3,1550.3c-0.9-0.5-1.6-1.1-2.2-1.7c0.7-0.6,1.3-1.3,1.9-2c0.8,0.5,1.5,1.1,2.2,1.7
									C2164.5,1549,2163.8,1549.6,2163.3,1550.3z M2180.9,1548c-0.4,0.3-1.1,0.4-2.1,0.5c-0.8,0.1-2,0.1-3.5,0.2
									c-1.4,0.1-2.5,0.1-3.1,0.2c-0.9,0.1-1.7,0.2-2.5,0.3s-1.3,0.3-1.6,0.6c-0.3-0.5-0.5-1.1-0.6-1.6s-0.2-1.1-0.3-1.7
									c0.6,0.1,1.6,0.1,3,0c1.3,0,2.8-0.1,4.5-0.2c1.2-0.1,2.1-0.2,2.8-0.3c0.6-0.1,1.1-0.3,1.5-0.4c0.4-0.2,0.7-0.5,1.1-0.8
									c0.4,0.3,0.7,0.6,1,1c0.2,0.4,0.4,0.7,0.4,1C2181.4,1547.3,2181.3,1547.8,2180.9,1548z"
						/>
					</g>
					<g className="level_zero location " id="orodruin">
						<rect x="750.357" y="1161.627" width="48.723" height="25.555" transform="matrix(0.880905, 0.473293, -0.483907, 0.875202, 2125.796143, 231.53511)"></rect>
						<rect x="750.357" y="1161.627" width="48.723" height="25.555" transform="matrix(0.999546, -0.030135, 0.018092, 0.999909, 1484.23938, 484.20517)"></rect>
						<rect x="750.358" y="1161.626" width="48.723" height="25.555" transform="matrix(0.822834, -0.568282, 0.558367, 0.829681, 1016.899841, 1091.955688)"></rect>
						<path
							d="M2220.9,1626.1c-0.5-1.2-0.7-2.5-0.5-3.9c0.2-1.4,0.8-2.8,1.7-4.2c1-1.6,2.2-2.8,3.6-3.6c1.4-0.8,2.9-1.3,4.4-1.2
									c1.5,0,3,0.5,4.3,1.3c1.2,0.8,2.1,1.8,2.6,3c0.5,1.2,0.7,2.5,0.5,4s-0.8,2.9-1.7,4.3c-1,1.5-2.2,2.7-3.6,3.5s-2.9,1.2-4.4,1.1
									c-1.5,0-3-0.5-4.4-1.4C2222.3,1628.3,2221.4,1627.3,2220.9,1626.1z M2229.1,1628.5c1.4-0.5,2.7-1.6,3.9-3.4c1-1.6,1.5-3.1,1.5-4.6
									c0-1.5-0.6-2.7-1.6-3.8c-0.9-0.6-1.8-0.8-2.8-0.8c-1,0-2,0.4-3,1c-1,0.6-1.8,1.5-2.5,2.6c-1.1,1.7-1.6,3.4-1.6,4.9s0.7,2.7,2,3.5
									C2226.3,1628.8,2227.7,1629,2229.1,1628.5z"
						/>
						<path
							d="M2238.2,1636.2c0.9-2,1.8-4.2,2.7-6.6c0.4-1.1,0.7-2,0.9-2.7c0.2-0.6,0.2-1.1,0.2-1.5s-0.1-0.9-0.3-1.3
									c0.4-0.3,0.9-0.4,1.3-0.5c0.4-0.1,0.8,0,1.1,0.1c1.1,0.4,1.3,1.4,0.8,2.9c0.7-0.5,1.5-0.8,2.2-1.1c0.7-0.2,1.5-0.3,2.3-0.2
									c0,0.4-0.1,0.9-0.2,1.6s-0.3,1.3-0.5,1.8c-0.2-0.1-0.5-0.3-0.9-0.4c-0.6-0.2-1.2-0.4-1.8-0.4s-1.1,0-1.5,0.1l-0.4,1
									c-0.5,1.4-1,2.6-1.4,3.6c-0.8,2.2-1.2,3.8-1.2,4.9c-0.6-0.1-1.1-0.2-1.6-0.4C2239.3,1636.9,2238.8,1636.6,2238.2,1636.2z"
						/>
						<path
							d="M2250.7,1638.7c-0.7-1.4-0.9-3.1-0.5-5.1c0.3-1.4,0.8-2.7,1.6-3.7s1.7-1.7,2.8-2.2c1.1-0.4,2.3-0.5,3.5-0.3
									c1.1,0.2,2,0.7,2.8,1.4c0.7,0.7,1.2,1.6,1.5,2.7s0.3,2.3,0,3.6c-0.3,1.4-0.8,2.7-1.6,3.7c-0.8,1-1.7,1.7-2.9,2.2
									c-1.1,0.4-2.3,0.5-3.6,0.3C2252.6,1641,2251.4,1640.2,2250.7,1638.7z M2257.9,1639.3c0.8-0.7,1.3-1.8,1.6-3.4
									c0.3-1.4,0.2-2.6-0.2-3.7c-0.4-1.1-1-1.9-2-2.5c-1.1-0.2-2.1,0-3,0.8c-0.8,0.7-1.4,1.8-1.7,3.3c-0.3,1.7-0.3,3.1,0.2,4.2
									c0.4,1.1,1.2,1.8,2.1,2C2256.1,1640.3,2257.1,1640,2257.9,1639.3z"
						/>
						<path
							d="M2267.8,1641.5c-0.8-1.2-1.2-3-1.2-5.3c0-2.4,0.5-4.2,1.4-5.4c0.9-1.2,2.1-1.8,3.8-1.8c1.3,0,2.5,0.5,3.6,1.4
									c-0.1-2.2-0.3-3.9-0.5-5c0.6-0.2,1.1-0.4,1.6-0.5c0.5-0.1,1.1-0.1,1.7-0.1c-0.3,2.8-0.5,7.5-0.6,14c0,0.8,0.1,1.4,0.2,1.9
									c0.2,0.5,0.5,1,0.9,1.5c-0.7,0.7-1.5,1-2.4,1.2c-0.5-0.5-0.9-1.1-1.1-1.9c-0.5,0.6-1.2,1-1.8,1.4s-1.3,0.5-2,0.5
									C2269.8,1643.4,2268.6,1642.7,2267.8,1641.5z M2273.7,1641.3c0.5-0.3,1-0.6,1.5-0.9c0-0.7,0-1.9,0.1-3.5c0.1-1.8,0.1-3.3,0.1-4.3
									c-0.8-0.6-1.7-0.9-2.7-0.9c-1,0-1.8,0.4-2.4,1.2c-0.6,0.8-0.9,2-0.9,3.6c0,1.5,0.2,2.8,0.7,3.7c0.5,0.9,1.2,1.5,2.2,1.7
									C2272.7,1641.7,2273.2,1641.5,2273.7,1641.3z"
						/>
						<path
							d="M2286.1,1642.1c-0.3-2.2-0.6-4.5-1.1-7c-0.2-1.2-0.4-2.1-0.6-2.7c-0.2-0.6-0.4-1.1-0.6-1.4s-0.5-0.7-0.9-1
									c0.2-0.5,0.5-0.8,0.9-1.1s0.7-0.4,1-0.5c1.1-0.2,1.9,0.5,2.2,2.1c0.4-0.8,0.8-1.5,1.3-2c0.5-0.6,1.1-1,1.8-1.4
									c0.2,0.3,0.4,0.8,0.6,1.4c0.2,0.6,0.4,1.2,0.5,1.8c-0.2,0-0.6,0-1,0.1c-0.6,0.1-1.2,0.3-1.8,0.6c-0.5,0.3-1,0.6-1.3,0.9l0.2,1
									c0.2,1.4,0.4,2.7,0.6,3.8c0.4,2.3,0.9,3.9,1.5,4.8c-0.6,0.2-1.1,0.4-1.6,0.5C2287.4,1642.1,2286.8,1642.1,2286.1,1642.1z"
						/>
						<path
							d="M2297.1,1635.4l-0.7-2l-0.7-2.1c-0.8-2-1.5-3.4-2.2-4.1c0.8-0.8,1.7-1.4,2.8-1.8c0.9,2.6,1.9,5.5,3.2,8.8
									c0.3,0.8,0.7,1.4,1.2,1.6c0.5,0.3,1.1,0.3,1.8,0c0.9-0.4,1.7-0.9,2.2-1.7c-0.5-1.4-1-2.6-1.3-3.7l-0.6-1.7c-0.8-2-1.5-3.4-2.2-4.1
									c0.8-0.8,1.7-1.4,2.9-1.8c0.5,1.5,1.1,3.2,1.7,5.2c0.7,2,1.2,3.3,1.4,4c0.3,0.7,0.6,1.3,0.9,1.7s0.8,0.7,1.4,1.1
									c-0.4,0.9-1,1.5-1.8,2c-0.7-0.2-1.3-0.7-1.8-1.4c-0.3,0.8-0.7,1.5-1.2,2.1c-0.6,0.6-1.2,1-1.9,1.3
									C2299.9,1639.3,2298.2,1638.2,2297.1,1635.4z"
						/>
						<path
							d="M2304.7,1616.5c0.1-1,0.2-1.9,0.5-2.7c0.9,0.3,1.8,0.6,2.7,0.8c-0.1,0.9-0.2,1.8-0.5,2.7
									C2306.5,1616.9,2305.6,1616.6,2304.7,1616.5z M2314.8,1631c-0.4-0.2-0.9-0.8-1.4-1.6c-0.4-0.7-1-1.7-1.8-3
									c-0.7-1.2-1.2-2.1-1.6-2.7c-0.5-0.8-1-1.5-1.5-2.1c-0.5-0.6-0.9-1-1.3-1.2c0.3-0.5,0.7-0.9,1.1-1.3c0.4-0.4,0.8-0.7,1.4-1
									c0.3,0.6,0.7,1.5,1.4,2.7c0.6,1.2,1.4,2.5,2.3,3.9c0.6,1,1.2,1.8,1.6,2.3c0.4,0.5,0.8,0.9,1.1,1.1c0.3,0.2,0.7,0.4,1.2,0.6
									c-0.1,0.5-0.2,0.9-0.4,1.3c-0.2,0.4-0.5,0.6-0.7,0.8C2315.7,1631.2,2315.3,1631.2,2314.8,1631z"
						/>
						<path
							d="M2322.6,1625.8c-1.2-1.9-2.6-3.8-4.1-5.8c-0.7-1-1.3-1.7-1.8-2.2c-0.4-0.5-0.8-0.8-1.2-1c-0.3-0.2-0.8-0.4-1.3-0.5
									c0-0.5,0.1-1,0.3-1.3c0.2-0.4,0.4-0.6,0.6-0.8c0.8-0.6,1.7-0.5,2.7,0.5c0-0.8,0.2-1.6,0.5-2.4s0.8-1.3,1.4-1.8
									c1.9-1.4,3.7-0.9,5.6,1.5l1.2,1.7c0.4,0.7,0.9,1.3,1.3,1.8c1.4,1.8,2.6,3,3.5,3.6c-0.4,0.4-0.7,0.8-1.1,1.1
									c-0.4,0.3-0.9,0.6-1.6,0.9c-1.5-2.2-3.2-4.6-5.1-7.1c-0.5-0.7-1.1-1.1-1.5-1.2c-0.5-0.1-1,0-1.6,0.5c-0.9,0.7-1.4,1.4-1.7,2.3
									l0.8,1.2c0.6,0.9,1.4,2,2.4,3.3c1.4,1.9,2.6,3.1,3.5,3.6c-0.4,0.5-0.8,0.9-1.2,1.2C2323.7,1625.2,2323.2,1625.5,2322.6,1625.8z"
						/>
					</g>
					<g className="level_two fade location" id="barad_dur">
						<rect x="1934.126" y="1161.625" width="125.588" height="25.555" transform="matrix(0.999992, -0.004086, -0.007962, 1.000041, 405.78598, 438.654114)"></rect>
						<path
							d="M2339.7,1615c-1.1-0.1-1.9-0.3-2.6-0.5c0-1,0-2.4-0.1-4.3c-0.1-1.9-0.2-3.8-0.3-5.6c-0.2-3.5-0.5-5.6-0.9-6.4
									c0.7-0.3,1.6-0.6,2.6-0.8s2.1-0.4,3.1-0.4c1.9-0.1,3.4,0.2,4.4,0.9c1.1,0.7,1.7,1.9,1.7,3.4c0.1,0.8-0.1,1.6-0.5,2.3
									c-0.4,0.7-1,1.3-1.8,1.7c1,0.3,1.9,0.8,2.5,1.6c0.6,0.7,1,1.6,1,2.6c0.1,1.7-0.4,3-1.5,3.9s-2.7,1.5-4.8,1.6
									C2341.8,1615.2,2340.7,1615.1,2339.7,1615z M2342.1,1605c0.6,0,1,0,1.3,0c1.1-1,1.7-2,1.6-3.1c-0.1-1.9-1.3-2.8-3.6-2.7
									c-0.8,0.1-1.6,0.2-2.2,0.5c0,1.6,0.1,3.6,0.2,5.8C2340.1,1605.2,2341,1605.1,2342.1,1605z M2346.2,1609.7
									c-0.1-0.8-0.3-1.5-0.7-2.1c-0.4-0.6-1.1-1-1.9-1.3c-0.9,0.1-2.3,0.5-4.1,1.2c0.2,2.6,0.3,4.6,0.5,5.9c0.7,0.2,1.5,0.3,2.4,0.3
									C2345.1,1613.4,2346.3,1612.1,2346.2,1609.7z"
						/>
						<path
							d="M2352.2,1613.6c-0.5-0.6-0.8-1.3-0.8-2.2c-0.1-2.7,2.2-4.3,6.9-4.8c-0.1-1.5-0.3-2.5-0.7-3c-0.4-0.6-1.1-0.8-2.1-0.8
									c-0.6,0-1.2,0.2-1.9,0.4c-0.6,0.2-1.2,0.6-1.7,1l-0.3-0.6c0.5-1,1.2-1.8,2.1-2.5c0.9-0.6,1.8-1,2.8-1c1.3,0,2.3,0.3,3,1
									c0.7,0.7,1.1,1.8,1.2,3.3c0,0.8,0,1.7,0,2.7c0,1.1-0.1,1.9,0,2.4c0,0.8,0.1,1.5,0.3,2c0.2,0.5,0.5,1,1,1.5
									c-0.7,0.7-1.5,1.2-2.4,1.4c-0.6-0.4-1-1-1.2-1.8c-0.7,0.6-1.4,1.1-2.2,1.4c-0.8,0.4-1.5,0.6-2.1,0.6
									C2353.3,1614.5,2352.7,1614.2,2352.2,1613.6z M2356.8,1612.3c0.5-0.3,1-0.6,1.5-1l0-0.2c0-0.7,0-1.4,0-2.1l0-1.4
									c-1.4,0.1-2.4,0.5-3.1,1.1c-0.7,0.6-1.1,1.3-1,2.2c0,0.4,0.1,0.7,0.3,1.1s0.4,0.6,0.8,0.8
									C2355.7,1612.8,2356.2,1612.6,2356.8,1612.3z"
						/>
						<path
							d="M2365.9,1613.7c0.1-2.2,0.1-4.6,0.1-7.1c0-1.2-0.1-2.2-0.2-2.8c-0.1-0.6-0.2-1.2-0.4-1.5s-0.4-0.8-0.8-1.2
									c0.3-0.4,0.7-0.7,1-0.9s0.7-0.3,1-0.3c1.1,0,1.8,0.8,1.8,2.4c0.5-0.7,1.1-1.3,1.6-1.8c0.6-0.5,1.3-0.8,2-1
									c0.1,0.4,0.3,0.9,0.4,1.5c0.1,0.7,0.2,1.3,0.2,1.8c-0.2,0-0.6-0.1-1,0c-0.6,0-1.2,0.1-1.8,0.3c-0.6,0.2-1,0.4-1.4,0.6l0,1
									c0,1.5,0,2.8,0,3.9c0,2.3,0.3,4,0.7,5c-0.6,0.1-1.1,0.2-1.6,0.2C2367.1,1613.9,2366.5,1613.9,2365.9,1613.7z"
						/>
						<path
							d="M2374.8,1613.1c-0.5-0.6-0.7-1.3-0.7-2.3c0-2.7,2.4-4.2,7.1-4.5c0-1.5-0.2-2.5-0.6-3.1s-1.1-0.9-2.1-0.9
									c-0.6,0-1.2,0.1-1.9,0.3c-0.6,0.2-1.2,0.5-1.7,0.9l-0.2-0.6c0.5-1,1.3-1.8,2.2-2.4c0.9-0.6,1.9-0.9,2.8-0.9c1.3,0,2.3,0.4,3,1.2
									s1,1.9,1,3.3c0,0.8-0.1,1.7-0.2,2.7c-0.1,1.1-0.1,1.9-0.1,2.4c0,0.8,0.1,1.5,0.2,2c0.2,0.5,0.5,1,0.9,1.5
									c-0.7,0.7-1.6,1.1-2.4,1.3c-0.5-0.4-0.9-1-1.1-1.8c-0.7,0.6-1.5,1-2.3,1.3c-0.8,0.3-1.5,0.5-2.2,0.5
									C2375.9,1614,2375.3,1613.7,2374.8,1613.1z M2379.4,1611.9c0.6-0.3,1.1-0.6,1.5-1l0-0.2c0-0.7,0-1.4,0.1-2.1l0.1-1.4
									c-1.4,0.1-2.4,0.4-3.2,0.9c-0.7,0.5-1.1,1.3-1.1,2.2c0,0.4,0.1,0.7,0.3,1.1c0.2,0.4,0.4,0.7,0.7,0.9
									C2378.3,1612.3,2378.8,1612.2,2379.4,1611.9z"
						/>
						<path
							d="M2388.2,1612.4c-0.8-1.2-1.2-3-1.1-5.3c0.1-2.4,0.5-4.2,1.4-5.4c0.9-1.2,2.2-1.8,3.8-1.7c1.3,0,2.5,0.5,3.6,1.4
									c-0.1-2.2-0.2-3.9-0.4-5c0.6-0.2,1.1-0.4,1.6-0.5s1.1-0.1,1.7-0.1c-0.3,2.8-0.6,7.4-0.8,14c0,0.8,0,1.4,0.2,1.9
									c0.1,0.5,0.4,1,0.9,1.5c-0.7,0.6-1.5,1-2.4,1.1c-0.5-0.5-0.9-1.1-1.1-1.9c-0.6,0.6-1.2,1-1.9,1.3c-0.7,0.3-1.3,0.5-2,0.4
									C2390.2,1614.2,2389,1613.6,2388.2,1612.4z M2394.1,1612.2c0.5-0.3,1-0.6,1.5-0.9c0-0.7,0.1-1.9,0.2-3.5c0.1-1.8,0.2-3.2,0.2-4.3
									c-0.8-0.6-1.7-0.9-2.7-0.9c-1,0-1.9,0.4-2.4,1.2c-0.6,0.8-0.9,2-0.9,3.5c0,1.5,0.2,2.8,0.7,3.7c0.5,0.9,1.2,1.5,2.1,1.7
									C2393.1,1612.6,2393.6,1612.4,2394.1,1612.2z"
						/>
						<path
							d="M2402.2,1606.2c1.4,0.2,2.9,0.3,4.5,0.3c0.8,0,1.7,0,2.5-0.1c0.9-0.1,1.5-0.2,1.9-0.4c0.2,1,0.3,2.1,0.3,3.3
									c-1.3-0.2-2.8-0.3-4.5-0.3c-0.9,0-1.7,0-2.6,0.1c-0.8,0.1-1.5,0.2-1.8,0.4C2402.3,1608.5,2402.2,1607.4,2402.2,1606.2z"
						/>
						<path
							d="M2415.3,1613.3c-0.8-1.2-1.2-3-1.2-5.3c0-2.4,0.5-4.2,1.4-5.4c0.9-1.2,2.1-1.8,3.8-1.8c1.3,0,2.5,0.5,3.6,1.4
									c-0.1-2.2-0.3-3.9-0.5-5c0.6-0.2,1.1-0.4,1.6-0.5c0.5-0.1,1.1-0.1,1.7-0.1c-0.3,2.8-0.5,7.5-0.6,14c0,0.8,0.1,1.4,0.2,1.9
									c0.2,0.5,0.5,1,0.9,1.5c-0.7,0.7-1.5,1-2.4,1.2c-0.5-0.5-0.9-1.1-1.1-1.9c-0.5,0.6-1.2,1-1.8,1.4s-1.3,0.5-2,0.5
									C2417.3,1615.1,2416.1,1614.5,2415.3,1613.3z M2421.2,1613c0.5-0.3,1-0.6,1.5-0.9c0-0.7,0-1.9,0.1-3.5c0.1-1.8,0.1-3.3,0.1-4.3
									c-0.8-0.6-1.7-0.9-2.7-0.9c-1,0-1.8,0.4-2.4,1.2c-0.6,0.8-0.9,2-0.9,3.6c0,1.5,0.2,2.8,0.7,3.7c0.5,0.9,1.2,1.5,2.2,1.7
									C2420.2,1613.4,2420.7,1613.3,2421.2,1613z"
						/>
						<path
							d="M2429.6,1610.6l0-2.1l0-2.2c0-2.2-0.2-3.7-0.6-4.7c1-0.5,2.1-0.7,3.3-0.7c-0.1,2.7-0.1,5.8-0.1,9.3c0,0.9,0.2,1.5,0.5,1.9
									c0.3,0.4,0.9,0.6,1.7,0.6c1,0,1.9-0.3,2.7-0.8c0-1.5,0-2.8,0.1-4l0-1.8c0-2.2-0.2-3.7-0.6-4.7c1-0.5,2.1-0.7,3.3-0.7
									c-0.1,1.5-0.1,3.3-0.2,5.4c-0.1,2.1-0.1,3.5-0.1,4.2c0,0.8,0.1,1.4,0.3,1.9c0.2,0.5,0.5,1,0.9,1.5c-0.7,0.7-1.5,1.1-2.4,1.2
									c-0.6-0.5-1-1.1-1.2-2c-0.5,0.6-1.2,1.1-1.9,1.5c-0.7,0.4-1.5,0.5-2.3,0.5C2430.8,1615.2,2429.6,1613.6,2429.6,1610.6z
									M2430.8,1598.7c1-1.4,1.9-2.8,2.7-4c0.7-0.3,1.6-0.5,2.7-0.5c0.5,1.1,1,1.9,1.3,2.4c0.3,0.5,0.8,1.1,1.3,1.9
									c-0.8,0.3-1.8,0.5-2.8,0.5l-1.8-2.9l-1.7,2.9C2432,1599,2431.4,1598.9,2430.8,1598.7z"
						/>
						<path
							d="M2444.6,1614.6c0.1-2.2,0.1-4.6,0-7.1c0-1.2-0.1-2.2-0.2-2.8c-0.1-0.6-0.2-1.1-0.4-1.5s-0.4-0.8-0.8-1.1
									c0.3-0.4,0.7-0.7,1-0.9c0.4-0.2,0.7-0.3,1-0.3c1.1,0,1.8,0.8,1.9,2.4c0.5-0.7,1-1.3,1.6-1.8c0.6-0.5,1.2-0.8,2-1.1
									c0.1,0.4,0.3,0.9,0.4,1.5c0.1,0.7,0.2,1.3,0.2,1.8c-0.2,0-0.6-0.1-1,0c-0.6,0-1.2,0.1-1.8,0.3c-0.6,0.2-1,0.4-1.4,0.6l0,1
									c0,1.5,0,2.8,0,3.9c0.1,2.3,0.3,4,0.7,5c-0.6,0.1-1.1,0.2-1.6,0.2S2445.3,1614.7,2444.6,1614.6z"
						/>
					</g>
					<g className="level_two fade location" id="plateau_of_gorgoroth">
						<rect x="1825.268" y="1221.589" width="118.521" height="26.874" transform="matrix(0.813757, 0.581205, -0.591011, 0.806754, 1433.869263, -437.199188)"></rect>
						<rect x="844.456" y="1420.182" width="54.833" height="31.243" transform="matrix(0.927838, 0.372984, -0.384164, 0.923344, 2050.513672, 46.056149)"></rect>
						<rect x="980.579" y="1420.181" width="63.672" height="31.243" transform="matrix(0.974328, 0.225134, -0.236874, 0.971615, 1715.017212, 90.281395)"></rect>
						<rect x="1670.538" y="1420.183" width="108.473" height="31.243" transform="matrix(0.989739, -0.142885, 0.130958, 0.991461, 549.132019, 535.741882)"></rect>
						<path
							d="M2186.9,1629.8c1-0.9,2.3-2.2,3.9-3.9c1.6-1.7,3.1-3.3,4.5-4.8c2.9-3.3,4.6-5.4,5-6.4c0.8,0.3,1.7,0.7,2.7,1.4
									c1,0.7,2,1.4,2.9,2.2c1.8,1.6,2.8,3.3,2.9,5.1s-0.5,3.5-2,5.2c-1,1.1-2.2,1.9-3.4,2.4c-1.3,0.4-2.6,0.5-3.9,0.2
									c-1.3-0.3-2.6-1-3.7-2c-0.7-0.6-1.3-1.3-1.7-1.9c-2.3,2.6-3.6,4.4-4.1,5.3c-0.6-0.3-1.1-0.7-1.7-1.2
									C2187.8,1631,2187.4,1630.5,2186.9,1629.8z M2201.1,1628.4c1.1-0.4,2.1-1,2.9-1.9c2.1-2.4,2-4.6-0.4-6.8c-0.5-0.5-1.1-0.8-1.6-1.1
									c-2.1,2.1-4.2,4.3-6.2,6.6c0.9,1.6,1.7,2.7,2.3,3.3C2199,1628.8,2200,1628.8,2201.1,1628.4z"
						/>
						<path
							d="M2202.5,1643.1c0.1-0.5,0.5-1.3,1.3-2.3c0.5-0.6,1.4-1.6,2.6-3c1.3-1.5,2.4-2.8,3.3-3.8c2.7-3.3,4.5-5.8,5.4-7.5
									c0.7,0.2,1.3,0.5,1.9,0.8c0.5,0.3,1.1,0.7,1.7,1.2c-2.3,2.4-5.5,5.9-9.4,10.6c-0.9,1.1-1.6,2-2,2.6c-0.4,0.7-0.7,1.2-0.9,1.7
									c-0.1,0.5-0.2,1-0.2,1.7c-0.6,0.1-1.2,0.1-1.7,0s-0.9-0.3-1.2-0.5C2202.7,1644.1,2202.5,1643.6,2202.5,1643.1z"
						/>
						<path
							d="M2210.6,1649.4c0-0.9,0.3-1.8,1-2.7c2-2.5,5.3-2.2,10,0.9c1.1-1.4,1.6-2.5,1.7-3.3c0.1-0.8-0.4-1.6-1.4-2.4
									c-0.6-0.5-1.3-0.8-2-1.1c-0.8-0.3-1.5-0.4-2.3-0.4l0.2-0.8c1.2-0.5,2.5-0.7,3.8-0.6c1.3,0.1,2.4,0.5,3.3,1.2c1.2,1,1.9,2.1,2,3.3
									s-0.4,2.5-1.4,3.9c-0.6,0.8-1.3,1.6-2.1,2.4c-0.9,1-1.5,1.7-1.9,2.2c-0.6,0.8-1,1.5-1.2,2c-0.2,0.6-0.3,1.3-0.2,2.1
									c-1.2,0.1-2.3-0.1-3.2-0.6c-0.2-0.8-0.1-1.6,0.3-2.6c-1.1,0-2.1-0.1-3.1-0.4c-1-0.3-1.8-0.6-2.4-1.1
									C2211,1651,2210.7,1650.3,2210.6,1649.4z M2215.9,1651.6c0.7,0.2,1.4,0.2,2.1,0.2l0.1-0.2c0.5-0.7,1.1-1.3,1.6-1.9l1.1-1.3
									c-1.4-0.9-2.6-1.4-3.7-1.4c-1.1,0-2,0.4-2.7,1.3c-0.3,0.4-0.5,0.8-0.6,1.3c-0.1,0.5-0.1,0.9,0.1,1.3
									C2214.5,1651.2,2215.1,1651.5,2215.9,1651.6z"
						/>
						<path
							d="M2226.1,1661.4c0-0.6,0.3-1.3,1-2.3c0.5-0.7,1.3-1.8,2.4-3.2c1.2-1.6,2.2-3,3-4.1c0.4-0.5,0.7-1,1-1.5
									c-0.8-0.6-1.3-1-1.4-1.3c-0.2-0.3-0.2-0.6,0.1-0.9c1.8,0.1,3.8-0.1,6-0.7l0.6,0.6c-0.4,0.8-0.9,1.6-1.5,2.4
									c1.3,0.9,2.3,1.5,3.1,1.8c-0.3,0.5-0.7,0.8-1,1.1c-0.3,0.3-0.7,0.6-1.1,0.8c-0.4-0.6-1.1-1.4-2.2-2.1c-0.8,0.9-1.5,1.9-2.3,3
									c-1.9,2.7-3.2,4.8-3.9,6.4c0.9,0.6,1.8,1,2.4,1.2l-0.4,0.9c-0.8,0.4-1.6,0.5-2.5,0.4s-1.6-0.3-2.3-0.8
									C2226.5,1662.5,2226.1,1662,2226.1,1661.4z"
						/>
						<path
							d="M2237,1667.4c-0.1-1.7,0.6-3.7,2-5.8c1-1.5,2.1-2.6,3.4-3.4c1.3-0.8,2.6-1.2,3.9-1.3c1.4,0,2.6,0.3,3.8,1.1
									c1.6,1.1,2.4,2.4,2.5,4.2c0.1,1.7-0.7,3.7-2.1,6c-1.3-0.4-2.7-1-4.3-1.8c-1.6-0.8-3.2-1.6-4.7-2.5c-0.8,1.4-1.1,2.6-0.9,3.6
									c0.1,1,0.8,1.8,1.8,2.5c0.8,0.5,1.7,0.9,2.5,1.2s1.7,0.4,2.5,0.4l-0.2,0.8c-1.2,0.5-2.5,0.7-3.9,0.5c-1.4-0.1-2.6-0.6-3.7-1.3
									C2237.9,1670.6,2237.1,1669.2,2237,1667.4z M2245.4,1664.2c1.1,0.4,2.1,0.6,2.8,0.7c0.8-1.2,1.1-2.4,1-3.5c-0.1-1.1-0.6-2-1.5-2.6
									c-0.9,0-1.8,0.4-2.8,1.1c-1,0.7-1.9,1.7-2.8,3C2243.2,1663.3,2244.3,1663.8,2245.4,1664.2z"
						/>
						<path
							d="M2251.8,1678.2c-0.1-0.9,0.1-1.8,0.6-2.8c1.6-2.8,5-2.9,10.1-0.3c0.9-1.5,1.3-2.7,1.3-3.5c0-0.8-0.6-1.6-1.6-2.2
									c-0.6-0.4-1.4-0.6-2.1-0.8c-0.8-0.2-1.6-0.2-2.3-0.1l0.1-0.8c1.2-0.7,2.4-1,3.7-1.1c1.3-0.1,2.5,0.2,3.5,0.8
									c1.3,0.8,2.1,1.8,2.4,3c0.3,1.2-0.1,2.6-0.9,4.1c-0.5,0.8-1.1,1.7-1.8,2.7c-0.7,1.1-1.3,1.8-1.6,2.4c-0.5,0.9-0.8,1.6-1,2.2
									c-0.1,0.6-0.1,1.3,0,2.1c-1.2,0.3-2.3,0.2-3.3-0.2c-0.3-0.7-0.3-1.6-0.1-2.6c-1.1,0.1-2.1,0.1-3.1,0c-1-0.1-1.9-0.4-2.5-0.8
									C2252.4,1679.8,2252,1679.1,2251.8,1678.2z M2257.3,1679.8c0.7,0.1,1.5,0,2.1-0.1l0.1-0.2c0.4-0.7,0.9-1.4,1.4-2.1l1-1.4
									c-1.5-0.7-2.7-1.1-3.8-0.9s-1.9,0.6-2.5,1.6c-0.2,0.4-0.4,0.8-0.4,1.3c0,0.5,0,0.9,0.2,1.3
									C2255.9,1679.6,2256.5,1679.7,2257.3,1679.8z"
						/>
						<path
							d="M2270,1683.6l1.2-2.2l1.3-2.3c1.2-2.3,1.9-4.1,2-5.3c1.3,0.1,2.6,0.5,3.9,1.1c-1.7,2.8-3.5,6.1-5.4,9.8
									c-0.5,0.9-0.7,1.7-0.6,2.4c0.1,0.6,0.6,1.2,1.4,1.6c1,0.6,2.1,0.8,3.3,0.7c0.8-1.6,1.6-3,2.3-4.2l1.1-1.9c1.2-2.3,1.9-4.1,2-5.3
									c1.3,0.1,2.6,0.5,3.9,1.2c-0.9,1.6-2.1,3.5-3.3,5.6s-2.1,3.6-2.5,4.4c-0.5,0.8-0.7,1.6-0.8,2.1c-0.1,0.6,0,1.3,0.1,2.1
									c-1.1,0.3-2.2,0.3-3.2-0.1c-0.4-0.8-0.4-1.8-0.1-2.8c-0.9,0.4-1.9,0.5-2.8,0.5c-1,0-1.9-0.3-2.7-0.7
									C2268.6,1689.1,2268.3,1686.9,2270,1683.6z"
						/>
						<path
							d="M2297.1,1700.8c-0.5-1.8-0.3-3.9,0.7-6.1c0.7-1.6,1.6-2.9,2.8-3.9s2.4-1.6,3.8-1.8c1.4-0.2,2.8,0,4.2,0.6
									c1.2,0.5,2.2,1.3,2.9,2.4c0.7,1,1,2.2,1.1,3.6c0,1.3-0.3,2.8-0.9,4.2c-0.7,1.6-1.6,2.9-2.8,3.9c-1.2,1-2.5,1.6-3.9,1.8
									c-1.4,0.2-2.9,0-4.3-0.6C2298.8,1704,2297.6,1702.7,2297.1,1700.8z M2305.4,1703.4c1.1-0.6,2-1.8,2.8-3.5c0.7-1.5,0.9-3,0.8-4.3
									c-0.2-1.4-0.7-2.5-1.7-3.4c-1.3-0.6-2.5-0.5-3.7,0.1c-1.2,0.6-2.1,1.8-2.8,3.4c-0.8,1.9-1.1,3.6-0.9,5c0.2,1.4,0.9,2.4,2,2.9
									C2303.1,1704,2304.3,1704,2305.4,1703.4z"
						/>
						<path
							d="M2311.5,1715.3c0.7-0.5,1.3-0.9,1.8-1.4c0.5-0.5,0.9-1.2,1.4-2.1s1.1-2.2,1.7-3.9c0.8-2,2-5.4,3.7-10.3
									c-0.8-0.3-1.2-0.6-1.4-0.8c-0.2-0.2-0.2-0.5-0.1-0.9c1.1-0.2,1.9-0.4,2.4-0.5c1.1-2,2.4-3.4,3.9-4.2c1.5-0.8,3-1,4.6-0.4
									c1.1,0.4,2.1,1,3,1.7c-0.1,0.4-0.4,0.9-0.9,1.4c-0.5,0.6-0.9,1.1-1.4,1.6c-0.4-0.9-0.9-1.8-1.4-2.5c-0.6-0.7-1.1-1.1-1.5-1.3
									c-0.5,0.2-1,0.6-1.5,1.3c-0.5,0.7-1.1,2-1.8,3.7c1.7,0.6,2.9,0.9,3.6,1c-0.2,0.5-0.4,0.9-0.7,1.3c-0.2,0.4-0.6,0.7-0.9,1.1
									c-0.6-0.6-1.6-1.1-2.8-1.6l-1,2.6c-0.6,1.5-1.2,3.2-1.9,5.3c-0.3,1.1-0.7,2-0.9,2.8c-0.5,1.2-1,2.2-1.6,3
									c-0.6,0.8-1.3,1.4-2.2,1.9c-0.9,0.5-2.2,1.1-3.8,1.7L2311.5,1715.3z"
						/>
						<path
							d="M2339.6,1716.9c-0.9-2.1-0.8-4.7,0.1-7.8c0.7-2.2,1.6-4,2.9-5.4c1.3-1.5,2.8-2.5,4.4-3c1.7-0.5,3.4-0.5,5.2,0
									c2.1,0.6,3.8,1.5,5.1,2.5c-0.2,0.5-0.4,1.1-0.8,1.8c-0.4,0.7-0.8,1.2-1.2,1.5c-0.8-1.1-1.7-2-2.7-2.9c-1-0.9-1.9-1.4-2.5-1.6
									c-0.8,0-1.7,0.3-2.6,0.9c-0.9,0.6-1.7,1.5-2.5,2.5c-0.7,1.1-1.3,2.2-1.7,3.5c-0.9,2.9-1,5.1-0.5,6.6s1.9,2.6,4,3.3
									c1,0.3,1.9,0.4,2.7,0.4c1.3-4.2,1.8-6.8,1.8-7.9c0.6,0,1.2,0.1,1.8,0.3c0.6,0.2,1.2,0.4,1.9,0.8c-1,2.5-1.9,5.2-2.7,7.9
									c-1,0.6-2.2,0.9-3.6,1s-2.7,0-4-0.4C2342.1,1720.4,2340.5,1719,2339.6,1716.9z"
						/>
						<path
							d="M2361.8,1723.1c-1-1.6-1.4-3.6-1.1-6c0.2-1.7,0.7-3.3,1.5-4.5c0.8-1.3,1.9-2.2,3.1-2.8c1.3-0.6,2.7-0.8,4.2-0.6
									c1.3,0.2,2.5,0.6,3.4,1.4c0.9,0.8,1.6,1.8,2,3.1s0.5,2.7,0.3,4.3c-0.2,1.7-0.7,3.3-1.6,4.5c-0.8,1.3-1.9,2.2-3.2,2.8
									c-1.3,0.6-2.7,0.8-4.3,0.6C2364.3,1725.6,2362.8,1724.7,2361.8,1723.1z M2370.4,1723.1c0.9-0.9,1.4-2.3,1.7-4.2
									c0.2-1.6,0-3.1-0.5-4.4c-0.5-1.3-1.4-2.2-2.6-2.8c-1.4-0.2-2.5,0.2-3.5,1.2s-1.5,2.3-1.7,4.1c-0.2,2.1-0.1,3.7,0.6,5
									c0.6,1.3,1.5,2,2.7,2.2C2368.4,1724.4,2369.6,1724.1,2370.4,1723.1z"
						/>
						<path
							d="M2382.7,1726.7c0.2-2.7,0.3-5.5,0.4-8.5c0-1.5,0-2.6-0.1-3.4c-0.1-0.8-0.2-1.4-0.4-1.8s-0.5-0.9-0.9-1.4
									c0.4-0.5,0.8-0.8,1.3-1.1s0.9-0.3,1.2-0.3c1.4,0,2.1,1,2.1,3c0.6-0.8,1.3-1.5,2-2.1c0.7-0.5,1.5-0.9,2.4-1.2
									c0.2,0.4,0.3,1,0.4,1.8c0.1,0.8,0.2,1.5,0.2,2.2c-0.3-0.1-0.7-0.1-1.2-0.1c-0.8,0-1.5,0.1-2.2,0.3c-0.7,0.2-1.3,0.4-1.7,0.7
									l-0.1,1.2c-0.1,1.8-0.1,3.3-0.1,4.7c0,2.8,0.2,4.8,0.6,6c-0.7,0.1-1.4,0.2-2,0.2C2384.2,1726.9,2383.5,1726.8,2382.7,1726.7z"
						/>
						<path
							d="M2395.9,1732.2c-0.1-1,0.1-1.9,0.4-2.8c0.3-0.9,0.7-1.8,1.3-2.5c-1-0.3-1.5-1-1.5-2.1c0-0.7,0.2-1.4,0.6-2.1
									s1.1-1.1,2-1.5c-0.9-0.4-1.5-1-2-1.9c-0.5-0.8-0.8-1.8-0.8-3c-0.1-1.9,0.4-3.4,1.5-4.6c1.1-1.2,2.6-1.9,4.4-2
									c1.2-0.1,2.3,0.2,3.2,0.7c0.5-0.2,1-0.5,1.6-0.9c0.6-0.4,1.2-1,1.8-1.6c0.3,0.5,0.5,1,0.8,1.6c0.2,0.6,0.3,1.1,0.4,1.5
									c-0.7,0.5-1.7,0.8-2.8,0.9c0.5,1,0.8,2,0.9,3c0.1,1.2-0.2,2.3-0.7,3.3c-0.5,1-1.2,1.8-2.2,2.4c-1,0.6-2.1,0.9-3.3,1
									c-1,0.1-1.8,0.3-2.4,0.7s-0.9,0.9-0.8,1.5l6.8,0c1.3,0.1,2.3,0.4,3,1c0.7,0.6,1.1,1.5,1.2,2.6c0.1,1.1-0.2,2-0.8,2.9
									c-0.6,0.9-1.5,1.6-2.6,2.2c-1.1,0.6-2.4,0.9-3.8,0.9C2399.4,1733.7,2397.4,1733.2,2395.9,1732.2z M2405.7,1730.2
									c0.8-0.5,1.1-1,1.1-1.7c0-0.5-0.2-0.9-0.6-1.2c-0.3-0.2-0.9-0.3-1.7-0.3l-5.4,0c-0.3,0.8-0.6,1.5-0.6,2.2c1,1.3,2.4,2,4.2,1.9
									C2403.9,1730.9,2404.9,1730.6,2405.7,1730.2z M2404.1,1718.9c0.5-0.6,0.8-1.5,0.7-2.5c-0.1-1-0.4-1.8-0.9-2.4s-1.3-1.1-2.2-1.3
									c-1,0.1-1.8,0.4-2.4,1.2c-0.6,0.7-0.9,1.7-0.8,2.7c0.1,1,0.4,1.9,1,2.5c0.6,0.6,1.4,0.9,2.3,0.8
									C2402.8,1719.9,2403.5,1719.6,2404.1,1718.9z"
						/>
						<path
							d="M2416,1723.6c-1.4-1.3-2.2-3.2-2.4-5.6c-0.2-1.7,0-3.3,0.5-4.8c0.5-1.4,1.3-2.6,2.4-3.5c1.1-0.9,2.4-1.4,3.9-1.6
									c1.3-0.2,2.6,0.1,3.7,0.6s2,1.4,2.7,2.6c0.7,1.2,1.1,2.5,1.3,4.1c0.2,1.7,0,3.3-0.5,4.8c-0.5,1.4-1.4,2.6-2.5,3.5
									c-1.1,0.9-2.5,1.4-4,1.6C2419,1725.5,2417.4,1724.9,2416,1723.6z M2424.4,1721.6c0.6-1.1,0.9-2.6,0.7-4.4c-0.2-1.6-0.7-3-1.5-4.1
									c-0.8-1.1-1.9-1.8-3.1-2.1c-1.4,0.2-2.4,0.8-3.1,1.9s-1,2.6-0.8,4.4c0.2,2.1,0.8,3.7,1.7,4.8c0.9,1.1,2,1.6,3.2,1.5
									C2422.8,1723.4,2423.8,1722.7,2424.4,1721.6z"
						/>
						<path
							d="M2436.2,1722.8c-0.2-2.7-0.6-5.5-1.1-8.4c-0.2-1.4-0.4-2.5-0.6-3.3c-0.2-0.8-0.4-1.3-0.7-1.8c-0.2-0.4-0.6-0.8-1.1-1.2
									c0.3-0.5,0.7-1,1.1-1.3c0.4-0.3,0.8-0.5,1.2-0.5c1.4-0.2,2.2,0.7,2.6,2.6c0.5-0.9,1-1.7,1.7-2.4c0.6-0.6,1.4-1.2,2.2-1.6
									c0.2,0.4,0.5,1,0.7,1.7c0.3,0.8,0.4,1.5,0.5,2.1c-0.3,0-0.7,0-1.2,0.1c-0.8,0.1-1.5,0.3-2.1,0.6c-0.7,0.3-1.2,0.6-1.6,1l0.2,1.2
									c0.2,1.7,0.4,3.3,0.6,4.6c0.4,2.8,1,4.7,1.6,5.8c-0.7,0.3-1.3,0.4-1.9,0.5C2437.7,1722.8,2437,1722.9,2436.2,1722.8z"
						/>
						<path
							d="M2450.1,1718.7c-1.5-1.2-2.4-3-2.9-5.4c-0.3-1.7-0.3-3.3,0.1-4.8c0.4-1.5,1.1-2.7,2.1-3.7c1-1,2.3-1.6,3.8-1.9
									c1.3-0.3,2.6-0.1,3.7,0.3c1.1,0.5,2.1,1.3,2.9,2.4c0.8,1.1,1.3,2.4,1.6,4c0.3,1.7,0.3,3.3-0.1,4.8c-0.4,1.5-1.2,2.7-2.2,3.7
									c-1.1,1-2.4,1.6-3.9,1.9C2453.2,1720.4,2451.5,1720,2450.1,1718.7z M2458.3,1716.2c0.6-1.2,0.7-2.6,0.3-4.5c-0.3-1.6-0.9-3-1.8-4
									c-0.9-1-2-1.7-3.3-1.9c-1.4,0.3-2.4,1-3,2.2s-0.8,2.7-0.4,4.4c0.4,2,1.1,3.6,2.1,4.6s2.1,1.5,3.3,1.2
									C2456.8,1718,2457.8,1717.3,2458.3,1716.2z"
						/>
						<path
							d="M2470.5,1716.1c-0.4-0.4-0.8-1.1-1-2.3c-0.2-0.8-0.4-2.1-0.8-4c-0.4-2-0.7-3.6-1-4.9c-0.1-0.6-0.3-1.2-0.5-1.8
									c-0.9,0.2-1.6,0.3-1.9,0.2c-0.3-0.1-0.6-0.3-0.6-0.7c1.3-1.3,2.5-2.9,3.4-4.9l0.9-0.1c0.3,0.9,0.6,1.8,0.8,2.7
									c1.6-0.4,2.7-0.7,3.4-1.1c0.1,0.5,0.2,1,0.2,1.5s-0.1,0.9-0.2,1.4c-0.7-0.1-1.7-0.1-3,0.2c0.2,1.2,0.4,2.4,0.7,3.7
									c0.7,3.2,1.4,5.6,2.1,7.2c1.1-0.3,1.9-0.7,2.5-1l0.4,0.9c-0.3,0.8-0.7,1.5-1.4,2.1c-0.7,0.6-1.4,1-2.1,1.2
									C2471.5,1716.6,2470.9,1716.5,2470.5,1716.1z"
						/>
						<path
							d="M2481.8,1713.9c-0.7-3.3-1.4-6.7-2.2-9.9c-1.1-4.5-2.1-8-3-10.5c1-0.8,2.2-1.4,3.6-1.7c0.2,1.2,0.6,3.8,1.4,7.6
									c0.5-0.9,1-1.7,1.8-2.4c0.7-0.7,1.5-1.1,2.4-1.3c2.7-0.7,4.5,0.8,5.4,4.3l0.5,2.5l0.6,2.6c0.7,2.7,1.4,4.6,2.2,5.6
									c-0.6,0.3-1.2,0.6-1.8,0.7c-0.6,0.1-1.3,0.2-2.1,0.3c-0.8-3.7-1.5-7.1-2.3-10.2c-0.3-1-0.6-1.7-1.1-2.1s-1.2-0.4-2-0.2
									c-1.2,0.3-2.2,0.9-3,1.8c0.1,0.8,0.4,1.8,0.7,3.2c1.2,4.8,2.2,7.7,3,8.7c-0.6,0.3-1.2,0.6-1.9,0.7
									C2483.3,1713.8,2482.6,1713.9,2481.8,1713.9z"
						/>
					</g>
					<g className="level_two fade location " id="ered_lithui">
						<rect x="4737.198" y="1666.765" width="307.601" height="36.667" transform="matrix(0.997455, -0.071292, 0.059276, 0.998314, -2395.385498, 132.059265)"></rect>
						<rect x="3775.851" y="2167.001" width="245.177" height="47.672" transform="matrix(0.994534, 0.104418, -0.116398, 0.993276, -762.596558, -1138.615845)"></rect>
						<path
							d="M2440.5,1481.4c0-1.7-0.2-4-0.3-6.9c-0.3-5.2-0.8-8.8-1.4-10.8c1.5,0.1,3.8,0.1,6.9-0.1c3.8-0.2,6.7-0.5,8.8-0.9
									c0.1,0.9,0,1.8-0.1,2.8c-0.1,1-0.3,1.7-0.5,2.1c-0.7-0.4-1.6-0.6-2.7-0.8c-1.1-0.2-2.2-0.2-3.4-0.1c-1.5,0.1-2.9,0.3-4.2,0.6
									c0.1,2.7,0.1,5.1,0.2,7.2l1.1,0c1.1,0,1.9,0,2.5-0.1c1.4-0.1,2.4-0.2,3.1-0.3s1.5-0.3,2.4-0.6c0.2,0.6,0.2,1.2,0.3,1.8
									c0,0.4,0,1.1-0.1,1.8c-3.2,0-5.6,0-7.4,0.1c-0.8,0.1-1.4,0.1-1.8,0.2c0.2,3.5,0.4,5.9,0.6,7.3c1.4,0.2,2.9,0.3,4.5,0.2
									c1.3-0.1,2.5-0.3,3.8-0.6c1.2-0.3,2.3-0.7,3-1.1c0.1,0.9,0,1.8-0.1,2.8c-0.1,1-0.3,1.7-0.5,2.1c-1.8-0.1-4.5-0.1-7.9,0.2
									c-3.1,0.2-5.3,0.4-6.8,0.8C2440.6,1485.6,2440.5,1483.1,2440.5,1481.4z"
						/>
						<path
							d="M2469.8,1486.9c0-1.6,0-3.8-0.1-6.7c-0.1-2.9-0.2-5.5-0.3-7.7c-0.3-5.2-0.8-8.4-1.3-9.6c0.9-0.4,2-0.8,3.4-1.1
									c1.4-0.3,2.9-0.5,4.4-0.6c2.9-0.2,5.1,0.4,6.7,1.8c1.6,1.3,2.5,3.4,2.7,6.1c0.1,1.6-0.2,3.1-0.9,4.4c-0.7,1.3-1.7,2.4-3.1,3.2
									c2.5,4.7,4.4,7.5,5.8,8.6c-0.7,0.4-1.5,0.7-2.3,0.9c-0.8,0.2-1.7,0.3-2.7,0.4c-0.7-1.7-2.2-4.6-4.6-8.7c-0.1,0-0.4,0.1-0.8,0.1
									c-0.6,0-1.2,0-1.9,0c-0.7,0-1.2-0.1-1.6-0.2c0.3,4.6,0.7,7.5,1.2,8.9c-0.8,0.2-1.6,0.4-2.4,0.4
									C2471.5,1487.1,2470.7,1487.1,2469.8,1486.9z M2480.4,1473c0.6-1,0.9-2.2,0.8-3.4c-0.2-3.8-2.3-5.5-6.1-5.3
									c-0.8,0.1-1.6,0.2-2.3,0.5c0,2.3,0.1,5.6,0.3,9.7c2.1,0.7,3.6,1,4.8,0.9C2478.9,1474.8,2479.8,1474,2480.4,1473z"
						/>
						<path
							d="M2500.1,1477.7c0-1.7-0.2-4-0.3-6.9c-0.3-5.2-0.8-8.8-1.4-10.8c1.5,0.1,3.8,0.1,6.9-0.1c3.8-0.2,6.7-0.5,8.8-0.9
									c0.1,0.9,0,1.8-0.1,2.8c-0.1,1-0.3,1.7-0.5,2.1c-0.7-0.4-1.6-0.6-2.7-0.8c-1.1-0.2-2.2-0.2-3.4-0.1c-1.5,0.1-2.9,0.3-4.2,0.6
									c0.1,2.7,0.1,5.1,0.2,7.2l1.1,0c1.1,0,1.9,0,2.5-0.1c1.4-0.1,2.4-0.2,3.1-0.3s1.5-0.3,2.4-0.6c0.2,0.6,0.2,1.2,0.3,1.8
									c0,0.4,0,1.1-0.1,1.8c-3.2,0-5.6,0-7.4,0.1c-0.8,0.1-1.4,0.1-1.8,0.2c0.2,3.5,0.4,5.9,0.6,7.3c1.4,0.2,2.9,0.3,4.5,0.2
									c1.3-0.1,2.5-0.3,3.8-0.6c1.2-0.3,2.3-0.7,3-1.1c0.1,0.9,0,1.8-0.1,2.8c-0.1,1-0.3,1.7-0.5,2.1c-1.8-0.1-4.5-0.1-7.9,0.2
									c-3.1,0.2-5.3,0.4-6.8,0.8C2500.2,1481.9,2500.1,1479.4,2500.1,1477.7z"
						/>
						<path
							d="M2532.8,1483.1c-1.3-0.1-2.5-0.2-3.4-0.3c0-1.5,0-3.6,0-6.3s-0.2-5.2-0.3-7.6c-0.3-5.3-0.8-8.5-1.3-9.7
									c0.9-0.4,2.1-0.8,3.6-1.2c1.5-0.3,3.1-0.5,4.6-0.6c3.8-0.2,6.8,0.7,9,2.7c2.2,2.1,3.4,5.1,3.6,9.2c0.2,2.5-0.2,4.8-1.2,6.8
									s-2.3,3.6-4.2,4.9c-1.8,1.2-4,1.9-6.5,2.1C2535.4,1483.1,2534.1,1483.1,2532.8,1483.1z M2541.7,1478.6c0.9-1,1.7-2.3,2.2-3.7
									c0.5-1.4,0.7-2.9,0.6-4.4c-0.4-7-3.5-10.3-9.3-9.9c-0.9,0.1-1.9,0.3-2.7,0.6c0,3.6,0.2,7.2,0.4,11c0.2,3.1,0.4,5.6,0.7,7.7
									c2.2,0.5,3.8,0.7,5,0.6C2539.7,1480.2,2540.8,1479.6,2541.7,1478.6z"
						/>
						<path
							d="M2582.4,1471.8c0-1.4-0.2-3.6-0.3-6.4c-0.4-5.8-0.8-9.4-1.4-10.7c0.8-0.2,1.5-0.4,2.3-0.4c0.7,0,1.6,0,2.5,0.1
									c0,1.7,0,4,0.1,6.7c0.1,2.8,0.2,5.3,0.4,7.6c0.2,3.3,0.4,5.6,0.6,6.9c1.3,0.2,2.8,0.3,4.5,0.1c1-0.1,2-0.3,3-0.6
									c1-0.3,1.8-0.7,2.5-1.1c0.1,1,0,1.9-0.1,2.9c-0.1,1-0.3,1.7-0.5,2c-1.9-0.1-4.1-0.1-6.6,0.1c-3,0.2-5.3,0.5-6.8,0.8
									C2582.5,1476,2582.5,1473.3,2582.4,1471.8z"
						/>
						<path
							d="M2609,1478.2c0-1.6,0-3.7-0.1-6.5c-0.1-2.8-0.2-5.4-0.4-7.9c-0.4-5.8-0.9-9.4-1.5-10.7c0.8-0.2,1.6-0.4,2.3-0.4
									c0.7-0.1,1.6,0,2.6,0.1c0,1.6,0,3.7,0.1,6.5s0.2,5.4,0.4,7.8c0.4,5.8,0.9,9.4,1.4,10.8c-0.8,0.2-1.6,0.4-2.4,0.4
									C2610.7,1478.3,2609.9,1478.3,2609,1478.2z"
						/>
						<path
							d="M2632.4,1476.5c0-1.5-0.1-3.7-0.2-6.4c-0.1-2.7-0.3-5.4-0.4-7.9c-0.2-2.8-0.4-5.2-0.7-7.1c-3.5,0.3-5.7,0.7-6.7,1.1
									c-0.2-0.7-0.3-1.3-0.4-2c0-0.5,0-1.3,0.1-2.3c1.1,0,2.6,0,4.6,0c1.9-0.1,3.8-0.2,5.5-0.3c4.1-0.3,6.6-0.7,7.6-1.2
									c0.2,0.7,0.3,1.4,0.4,2c0,0.5,0,1.2-0.1,2.2c-1.7,0-4,0-6.8,0.1c0.1,4.1,0.3,7.6,0.5,10.6c0.4,5.8,0.9,9.4,1.5,10.8
									c-0.8,0.3-1.6,0.4-2.3,0.5C2634.2,1476.7,2633.4,1476.6,2632.4,1476.5z"
						/>
						<path
							d="M2655.9,1474.8c0-1.6,0-3.7-0.2-6.5c-0.1-2.8-0.3-5.4-0.5-7.9c-0.5-5.8-1-9.4-1.6-10.6c0.8-0.3,1.6-0.4,2.3-0.5
									c0.7-0.1,1.6,0,2.6,0.1c0,2.8,0.1,6.4,0.4,11c1.6,0,3.6,0,6-0.2c0.4,0,1.5-0.2,3.5-0.4c-0.5-5.9-1-9.6-1.6-11
									c0.8-0.3,1.5-0.4,2.3-0.5c0.7-0.1,1.6,0,2.5,0.1c0,1.6,0,3.7,0.2,6.5c0.1,2.8,0.3,5.4,0.5,7.9c0.5,5.7,1,9.3,1.5,10.7
									c-0.8,0.3-1.5,0.4-2.3,0.5c-0.7,0.1-1.5,0-2.5-0.1c0-2.7-0.1-6.4-0.4-11.1c-1.8,0-3.6,0.1-5.5,0.3c-1.3,0.1-2.6,0.2-4,0.4
									c0.5,5.9,1,9.6,1.6,11c-0.8,0.3-1.5,0.4-2.4,0.5C2657.6,1474.9,2656.8,1474.9,2655.9,1474.8z"
						/>
						<path
							d="M2690.2,1469.7c-1.6-1.6-2.5-3.9-2.8-7c-0.3-3.3-0.6-6.4-1-9.5c-0.4-3.1-0.7-5.1-1-6c0.7-0.3,1.5-0.4,2.2-0.5
									c0.7-0.1,1.6,0,2.6,0.1c0.2,4.6,0.6,9.9,1.1,15.7c0.4,4.3,2.3,6.4,5.7,6.1c3.6-0.3,5.3-2.4,4.9-6.4c-0.8-9-1.3-14.4-1.7-16.3
									c0.5-0.2,1.1-0.4,1.6-0.4c0.5,0,1.1,0,1.8,0.1c0.1,1.8,0.2,4.2,0.4,7.3c0.2,3,0.4,5.7,0.6,8c0.2,2.9-0.2,5.4-1.4,7.4
									c-1.2,2-3.4,3.1-6.7,3.4C2693.9,1471.9,2691.8,1471.3,2690.2,1469.7z"
						/>
						<path
							d="M2719.6,1469.4c0-1.6-0.1-3.7-0.2-6.5c-0.2-2.8-0.4-5.4-0.6-7.9c-0.5-5.8-1.1-9.3-1.7-10.6c0.8-0.3,1.6-0.4,2.3-0.5
									c0.7-0.1,1.6,0,2.6,0.1c0,1.6,0.1,3.7,0.2,6.5c0.2,2.7,0.4,5.4,0.6,7.8c0.5,5.8,1.1,9.4,1.7,10.7c-0.8,0.3-1.5,0.4-2.4,0.5
									C2721.4,1469.6,2720.6,1469.6,2719.6,1469.4z"
						/>
						<path
							d="M2749.2,1434.3l0.5,0.3c-0.8,1.8-1.2,3.7-1.3,5.8c0,2.1,0.3,4.2,1,6.4c0.7,2.2,1.6,4.1,2.9,5.8c1.2,1.7,2.7,3,4.4,4
									l-0.2,0.5c-2.3-0.8-4.3-2-6-3.7c-1.7-1.6-2.9-3.6-3.6-5.8s-0.8-4.5-0.4-6.8C2746.8,1438.5,2747.7,1436.3,2749.2,1434.3z"
						/>
						<path
							d="M2771.3,1451.4l-2.6-3.9c-1,0.2-2.1,0.4-3.5,0.8c-1.5,0.4-2.5,0.7-3,1c-0.1,1.4-0.1,3-0.2,4.7c-0.3,0.2-0.6,0.4-0.9,0.5
									c-0.4,0.1-0.8,0.2-1.3,0.1c0.3-1.3,0.5-4.1,0.8-8.6c0.3-4.5,0.4-8,0.5-10.5c0.5-0.3,0.9-0.4,1.3-0.5c0.4-0.1,0.8-0.2,1.2-0.2
									c1.3,2.1,3.3,5.1,5.8,8.8c2.6,3.7,4.3,6.1,5.1,7c-0.5,0.3-1,0.5-1.5,0.7C2772.6,1451.4,2772,1451.4,2771.3,1451.4z M2767.5,1445.7
									c-1.8-2.7-3.3-4.9-4.6-6.7c-0.2,2-0.4,4.7-0.5,8c1-0.2,2.2-0.5,3.5-0.9C2766.7,1446,2767.2,1445.8,2767.5,1445.7z"
						/>
						<path
							d="M2778.1,1448.9c-0.1-0.4-0.2-0.8-0.2-1.2c0-0.4,0-0.8,0.1-1.3c0.5,0.5,1.1,0.8,1.8,1s1.4,0.2,2.1,0
									c1.9-0.4,2.7-1.3,2.5-2.4c-0.1-0.5-0.4-0.9-0.9-1.1c-0.5-0.2-1.3-0.5-2.2-0.7c-1-0.2-1.8-0.5-2.4-0.7c-0.6-0.2-1.2-0.6-1.8-1.1
									c-0.5-0.5-0.9-1.2-1.1-2.1c-0.2-1,0-2,0.7-2.8s1.7-1.4,3-1.7c1.8-0.4,3.4-0.5,4.8-0.3c0.1,0.4,0.1,0.9,0.1,1.5
									c0,0.6-0.1,1.2-0.3,1.7c-0.9-0.6-1.7-1-2.5-1.3c-0.8-0.3-1.4-0.4-1.9-0.3c-0.5,0.2-0.8,0.5-1,0.9s-0.3,0.7-0.2,1.1
									c0.1,0.6,0.5,1.1,1,1.3c0.6,0.3,1.4,0.5,2.5,0.8c0.9,0.2,1.7,0.4,2.3,0.6c0.6,0.2,1.2,0.6,1.7,1.1s0.9,1.1,1,1.9
									c0.3,1.3,0.1,2.3-0.7,3.2s-1.9,1.5-3.6,1.9C2781.1,1449.5,2779.5,1449.4,2778.1,1448.9z"
						/>
						<path
							d="M2793.6,1446.2c-0.4-2.9-1-5.8-1.5-8.6c-0.8-3.9-1.5-6.9-2.2-9.1c0.9-0.7,2-1.1,3.2-1.4c0.1,1.1,0.4,3.3,0.9,6.6
									c0.4-0.8,1-1.5,1.6-2c0.6-0.5,1.3-0.9,2.1-1c2.4-0.5,3.9,0.8,4.5,3.9l0.4,2.1l0.4,2.3c0.5,2.4,1,4,1.6,4.9
									c-0.5,0.3-1.1,0.4-1.6,0.5c-0.5,0.1-1.1,0.2-1.8,0.2c-0.5-3.2-1-6.2-1.6-8.9c-0.2-0.9-0.5-1.5-0.9-1.8c-0.4-0.3-1-0.4-1.7-0.3
									c-1.1,0.2-2,0.7-2.7,1.5c0.1,0.7,0.3,1.6,0.5,2.8c0.9,4.2,1.6,6.7,2.2,7.6c-0.5,0.2-1.1,0.4-1.6,0.5
									C2794.9,1446.2,2794.3,1446.2,2793.6,1446.2z"
						/>
						<path
							d="M2821.3,1441.1c0-2.4-0.2-5.9-0.5-10.4c-0.2-2.3-0.3-4.1-0.5-5.2c-0.2-1.1-0.3-2-0.5-2.5c0.5-0.2,1.1-0.3,1.7-0.3
									c0.5,0,1.1,0,1.8,0.1c0.7,1.6,1.8,3.7,3.2,6.5s2.5,4.9,3.4,6.5c0.6-1.8,1.4-4.1,2.3-6.9c0.9-2.8,1.6-5.1,2.2-7
									c0.4-0.2,1-0.3,1.7-0.3c0.5,0,1.1,0,1.7,0.1c0,1.1,0,2.7,0.1,4.6c0.1,2,0.2,3.9,0.3,5.7c0.3,4.3,0.7,6.9,1.1,7.8
									c-0.5,0.2-1.1,0.3-1.7,0.3c-0.5,0-1.1,0-1.8-0.1c0-1.2,0-2.7-0.1-4.7s-0.2-3.9-0.3-5.6c-0.1-1.1-0.2-2.4-0.4-3.9
									c-1.3,3.7-2.9,8.6-4.8,14.7c-0.3,0.1-0.5,0.2-0.7,0.2c-0.2,0-0.4,0-0.6-0.1c-0.7-1.4-1.7-3.4-2.9-6c-1.3-2.6-2.5-4.9-3.6-6.9
									c0.1,2.4,0.3,4.2,0.4,5.5c0.3,4.1,0.6,6.7,0.9,7.8c-0.4,0.2-0.7,0.3-1.1,0.3C2822.2,1441.2,2821.8,1441.2,2821.3,1441.1z"
						/>
						<path
							d="M2846.2,1438.4c-0.9-1.4-1.3-3.1-1.1-5.2c0.1-1.5,0.6-2.8,1.2-3.9c0.7-1.1,1.6-2,2.6-2.5c1.1-0.6,2.3-0.8,3.6-0.6
									c1.2,0.1,2.1,0.5,3,1.2s1.4,1.5,1.8,2.6c0.4,1.1,0.5,2.3,0.4,3.7c-0.1,1.5-0.6,2.8-1.3,3.9c-0.7,1.1-1.6,2-2.7,2.5
									c-1.1,0.6-2.3,0.8-3.7,0.6C2848.4,1440.6,2847.1,1439.8,2846.2,1438.4z M2853.7,1438.3c0.7-0.8,1.2-2,1.3-3.6
									c0.1-1.4,0-2.7-0.5-3.8s-1.3-1.9-2.3-2.3c-1.2-0.1-2.2,0.2-3,1.1c-0.8,0.8-1.3,2-1.4,3.5c-0.2,1.8,0,3.2,0.6,4.3
									c0.6,1.1,1.4,1.7,2.4,1.8C2852,1439.5,2853,1439.1,2853.7,1438.3z"
						/>
						<path
							d="M2863.9,1437.8l0.5-2.1l0.5-2.2c0.4-2.2,0.6-3.8,0.4-4.8c1.1-0.3,2.2-0.3,3.5,0c-0.7,2.7-1.3,5.9-2.1,9.4
									c-0.2,0.9-0.1,1.6,0.1,2.1c0.3,0.5,0.8,0.8,1.5,1c1,0.2,1.9,0.1,2.9-0.2c0.3-1.5,0.6-2.8,0.9-4l0.4-1.9c0.4-2.2,0.6-3.8,0.4-4.8
									c1.1-0.3,2.3-0.3,3.5,0c-0.4,1.5-0.8,3.4-1.3,5.5c-0.5,2.1-0.8,3.5-1,4.2c-0.2,0.8-0.2,1.5-0.1,2c0.1,0.5,0.3,1.1,0.6,1.7
									c-0.9,0.5-1.8,0.8-2.7,0.7c-0.5-0.6-0.8-1.3-0.8-2.3c-0.7,0.5-1.4,0.9-2.2,1.1c-0.8,0.2-1.6,0.2-2.4,0.1
									C2864.1,1442.7,2863.3,1440.9,2863.9,1437.8z"
						/>
						<path
							d="M2880.9,1445.9c0.7-2.2,1.4-4.5,2-7c0.3-1.2,0.5-2.2,0.6-2.8c0.1-0.7,0.1-1.2,0.1-1.6c-0.1-0.4-0.2-0.9-0.4-1.4
									c0.4-0.3,0.8-0.6,1.3-0.7c0.4-0.1,0.8-0.1,1.1,0c1.1,0.3,1.5,1.1,1.3,2.5c0.7-0.5,1.4-0.9,2.3-1.1c0.8-0.2,1.6-0.2,2.3,0
									c2.3,0.6,3.1,2.4,2.3,5.5l-0.6,2.1c-0.3,0.8-0.5,1.5-0.7,2.2c-0.6,2.3-0.8,4-0.7,5.2c-0.6,0-1.1-0.1-1.6-0.2
									c-0.5-0.1-1.1-0.4-1.7-0.7c0.8-2.7,1.7-5.5,2.5-8.7c0.2-0.9,0.2-1.6,0-2c-0.2-0.5-0.7-0.8-1.4-1c-1.1-0.3-2.1-0.3-2.9,0.1
									l-0.4,1.4c-0.4,1.1-0.7,2.5-1.1,4c-0.6,2.3-0.8,4-0.7,5.2c-0.6,0-1.2-0.1-1.8-0.2C2882.2,1446.4,2881.6,1446.2,2880.9,1445.9z"
						/>
						<path
							d="M2899.7,1450.7c-0.2-0.5-0.1-1.2,0.2-2.2c0.2-0.7,0.5-1.8,1-3.3c0.5-1.7,1-3,1.3-4.1c0.2-0.5,0.3-1,0.4-1.5
									c-0.8-0.2-1.3-0.4-1.5-0.6s-0.3-0.5-0.2-0.8c1.5-0.4,3.1-1.2,4.6-2.3l0.7,0.3c-0.1,0.8-0.3,1.6-0.5,2.4c1.3,0.4,2.3,0.6,3,0.6
									c-0.1,0.5-0.3,0.9-0.5,1.2c-0.2,0.3-0.4,0.7-0.7,1c-0.5-0.4-1.3-0.8-2.4-1.1c-0.4,1-0.7,2-1,3.1c-0.8,2.7-1.3,4.8-1.4,6.3
									c0.9,0.2,1.7,0.3,2.3,0.3l0,0.8c-0.5,0.5-1.2,0.9-1.9,1c-0.7,0.2-1.4,0.2-2.1,0C2900.3,1451.5,2899.8,1451.1,2899.7,1450.7z"
						/>
						<path
							d="M2910,1453.5c-0.3-0.7-0.3-1.5,0-2.4c0.8-2.6,3.6-3.4,8.4-2.2c0.5-1.4,0.6-2.5,0.4-3.2c-0.2-0.7-0.8-1.2-1.8-1.5
									c-0.6-0.2-1.3-0.3-2-0.3c-0.7,0-1.4,0.1-2,0.3l0-0.7c0.8-0.8,1.8-1.3,2.9-1.7c1.1-0.3,2.1-0.3,3.1,0c1.3,0.4,2.1,1.1,2.6,2.1
									c0.4,1,0.4,2.2,0,3.6c-0.3,0.8-0.6,1.7-1,2.6c-0.4,1-0.7,1.8-0.9,2.3c-0.3,0.8-0.4,1.5-0.4,2c0,0.5,0.2,1.1,0.4,1.8
									c-1,0.5-1.9,0.6-2.8,0.5c-0.4-0.6-0.6-1.3-0.5-2.2c-0.9,0.3-1.8,0.5-2.6,0.6c-0.9,0.1-1.6,0-2.3-0.2
									C2910.8,1454.8,2910.3,1454.3,2910,1453.5z M2914.9,1453.8c0.6-0.1,1.2-0.2,1.8-0.5l0.1-0.2c0.2-0.7,0.5-1.4,0.7-2l0.5-1.4
									c-1.4-0.3-2.5-0.4-3.4-0.1c-0.9,0.3-1.5,0.9-1.8,1.8c-0.1,0.4-0.1,0.8-0.1,1.2c0.1,0.4,0.2,0.8,0.4,1.1
									C2913.7,1453.9,2914.3,1453.9,2914.9,1453.8z"
						/>
						<path
							d="M2926,1459c-0.1-0.5,0-1.2,0.4-2.2c0.3-0.8,0.7-1.9,1.2-3.4c0.5-1.4,0.9-2.4,1.1-3c0.3-0.9,0.5-1.7,0.7-2.5
									c0.2-0.8,0.2-1.4,0.1-1.8c0.6-0.1,1.2,0,1.7,0s1.1,0.2,1.7,0.4c-0.3,0.6-0.7,1.5-1.2,2.8c-0.5,1.3-1.1,2.7-1.6,4.4
									c-0.4,1.2-0.7,2.1-0.8,2.8c-0.1,0.7-0.2,1.2-0.2,1.6c0,0.4,0.1,0.9,0.3,1.4c-0.4,0.3-0.9,0.5-1.3,0.5c-0.4,0.1-0.8,0.1-1.1,0
									C2926.5,1459.8,2926.1,1459.5,2926,1459z M2931,1441.3c0.9-0.6,1.7-1.1,2.5-1.4c0.3,0.9,0.7,1.8,1.1,2.6c-0.8,0.6-1.6,1-2.5,1.4
									C2931.9,1443.1,2931.5,1442.2,2931,1441.3z"
						/>
						<path
							d="M2935.7,1462.7c0.9-2.1,1.8-4.4,2.7-6.8c0.4-1.2,0.7-2.1,0.9-2.8c0.2-0.6,0.2-1.2,0.2-1.6c0-0.4-0.1-0.9-0.3-1.4
									c0.5-0.3,0.9-0.5,1.3-0.5c0.4-0.1,0.8,0,1.1,0.1c1,0.4,1.4,1.2,1.1,2.6c0.7-0.5,1.5-0.7,2.3-0.9s1.6,0,2.3,0.2
									c2.3,0.8,2.9,2.7,1.8,5.7l-0.8,2c-0.4,0.8-0.6,1.5-0.9,2.1c-0.8,2.2-1.2,3.9-1.2,5.1c-0.6-0.1-1.1-0.2-1.6-0.4
									c-0.5-0.2-1.1-0.5-1.7-0.8c1.1-2.6,2.2-5.4,3.3-8.4c0.3-0.9,0.4-1.5,0.2-2c-0.2-0.5-0.6-0.9-1.3-1.1c-1.1-0.4-2-0.5-2.9-0.2
									l-0.5,1.4c-0.5,1.1-1,2.4-1.5,3.9c-0.8,2.3-1.2,3.9-1.2,5.1c-0.6-0.1-1.2-0.2-1.7-0.4C2936.9,1463.4,2936.4,1463.1,2935.7,1462.7z
									"
						/>
						<path
							d="M2952,1467.9c0.2-0.4,0.4-0.8,0.6-1.1s0.5-0.7,0.9-1c0.2,0.7,0.5,1.3,1,1.8c0.5,0.5,1.1,0.9,1.8,1.2
									c1.8,0.7,3,0.5,3.4-0.6c0.2-0.5,0.1-0.9-0.1-1.4c-0.3-0.5-0.8-1.1-1.5-1.8c-0.7-0.8-1.2-1.4-1.6-1.9c-0.4-0.5-0.7-1.2-0.8-1.9
									s-0.1-1.5,0.3-2.3c0.4-1,1.1-1.7,2.2-2c1-0.3,2.2-0.2,3.5,0.3c1.7,0.7,3.1,1.5,4.1,2.5c-0.1,0.3-0.4,0.8-0.8,1.3s-0.8,0.9-1.2,1.3
									c-0.4-1-0.9-1.8-1.3-2.5c-0.5-0.7-1-1.1-1.4-1.3c-0.5-0.1-1,0-1.4,0.1c-0.4,0.2-0.7,0.4-0.8,0.8c-0.2,0.6-0.2,1.2,0.1,1.7
									c0.3,0.5,0.8,1.2,1.6,2.1c0.6,0.7,1.1,1.3,1.5,1.8c0.4,0.5,0.6,1.1,0.8,1.8c0.1,0.7,0.1,1.4-0.2,2.2c-0.5,1.2-1.3,2-2.4,2.3
									c-1.1,0.3-2.5,0.2-4-0.5C2954.1,1470,2952.8,1469.1,2952,1467.9z"
						/>
						<path
							d="M2976.4,1460c0.2,2.3-0.1,4.6-1,6.7c-0.9,2.1-2.3,3.9-4.1,5.4c-1.8,1.5-3.9,2.5-6.3,3.1l-0.1-0.5c1.8-0.8,3.3-2,4.7-3.6
									s2.5-3.4,3.4-5.5s1.4-4.2,1.6-6.2c0.2-2.1-0.1-4-0.7-5.9l0.5-0.3C2975.5,1455.5,2976.2,1457.7,2976.4,1460z"
						/>
					</g>
					<g className="level_one location" id="nurn">
						<rect x="1825.269" y="1551.712" width="118.521" height="34.136" transform="matrix(0.996835, -0.079499, 0.067487, 0.997793, 393.35257, 416.680237)"></rect>
						<path
							d="M2325.1,1846.7c0.2-2.7,0.3-6.5,0.3-11.5c0-4.5-0.1-7.4-0.4-8.6c0.8-0.1,1.4-0.2,1.9-0.2c0.6,0,1.3,0.1,2.2,0.2
									c1,1.8,2.4,4.3,4.2,7.5c1.8,3.2,3.4,5.9,4.8,8.3c0.1-3,0.1-5.3,0.1-7.1c0-4.7-0.1-7.6-0.4-8.6c0.5-0.2,1-0.2,1.5-0.2
									c0.5,0,1,0.1,1.5,0.2c-0.1,1.3-0.2,3.1-0.2,5.3c-0.1,2.2-0.1,4.3-0.1,6.2c0,4.5,0.1,7.4,0.4,8.6c-0.4,0.2-1.1,0.2-1.9,0.2
									c-0.6,0-1.3-0.1-2.2-0.2c-1-1.8-2.3-4.2-4.2-7.4c-1.8-3.2-3.4-5.9-4.8-8.3c-0.1,2.2-0.1,4.6-0.1,7c0,4.6,0.1,7.4,0.4,8.6
									c-0.5,0.2-1,0.2-1.5,0.2C2326.2,1846.9,2325.7,1846.8,2325.1,1846.7z"
						/>
						<path
							d="M2359.3,1844.3c-1.3-1.3-2.1-3.2-2.2-5.6c-0.2-2.6-0.4-5.1-0.6-7.6c-0.2-2.4-0.5-4-0.7-4.8c0.6-0.2,1.3-0.3,2-0.3
									c0.7,0,1.4,0,2.3,0.1c0.1,3.7,0.2,7.9,0.5,12.5c0.2,3.5,1.8,5.1,4.9,4.9c3.2-0.2,4.7-1.9,4.5-5c-0.4-7.2-0.8-11.5-1.1-13
									c0.5-0.2,0.9-0.3,1.4-0.3c0.4,0,1,0,1.6,0.1c0,1.4,0.1,3.4,0.1,5.8s0.2,4.6,0.3,6.4c0.1,2.3-0.4,4.3-1.5,5.8
									c-1.1,1.6-3.1,2.4-6,2.6C2362.4,1846.1,2360.6,1845.5,2359.3,1844.3z M2361.3,1822.2c0.9-0.5,1.7-1.1,2.4-1.8
									c0.7-0.8,1.1-1.5,1.2-2.2c0.9-0.1,1.7,0,2.3,0.2c0.6,0.2,1.1,0.6,1.4,1.1c-1.5,1.6-3.8,2.8-7,3.5L2361.3,1822.2z"
						/>
						<path
							d="M2389.1,1843.8c0-1.2-0.2-3-0.4-5.3c-0.2-2.3-0.4-4.3-0.6-6.1c-0.5-4.1-1-6.7-1.6-7.6c0.7-0.4,1.7-0.8,3-1.1
									c1.2-0.3,2.5-0.6,3.8-0.8c2.5-0.3,4.5,0,6,1c1.5,1,2.4,2.5,2.6,4.7c0.2,1.3,0,2.5-0.6,3.5c-0.5,1.1-1.4,2-2.6,2.7
									c2.4,3.6,4.2,5.7,5.5,6.5c-0.6,0.4-1.3,0.7-2,0.9s-1.5,0.4-2.4,0.5c-0.7-1.3-2.2-3.5-4.4-6.6c-0.1,0-0.3,0.1-0.7,0.1
									c-0.5,0.1-1.1,0.1-1.7,0.1c-0.6,0-1.1,0-1.4-0.1c0.5,3.6,1,6,1.5,7c-0.7,0.2-1.4,0.4-2.1,0.5
									C2390.6,1843.8,2389.9,1843.8,2389.1,1843.8z M2397.9,1832.1c0.5-0.9,0.7-1.8,0.6-2.8c-0.4-3-2.2-4.3-5.6-3.9
									c-0.7,0.1-1.4,0.3-2,0.5c0.1,1.9,0.3,4.4,0.7,7.7c1.8,0.4,3.2,0.6,4.2,0.4C2396.7,1833.6,2397.4,1832.9,2397.9,1832.1z"
						/>
						<path
							d="M2419.4,1839.6c-0.3-2.7-0.9-6.5-1.8-11.3c-0.8-4.4-1.5-7.2-2-8.3c0.7-0.3,1.4-0.5,1.9-0.6c0.6-0.1,1.3-0.2,2.2-0.2
									c1.3,1.6,3.1,3.8,5.5,6.6c2.4,2.8,4.5,5.2,6.3,7.2c-0.5-2.9-0.9-5.3-1.2-7c-0.9-4.6-1.5-7.4-2-8.4c0.5-0.2,0.9-0.4,1.4-0.5
									c0.4-0.1,1-0.1,1.5-0.1c0.2,1.3,0.4,3.1,0.8,5.3c0.3,2.2,0.7,4.2,1,6.1c0.8,4.4,1.5,7.2,2,8.3c-0.4,0.2-1,0.4-1.9,0.6
									c-0.5,0.1-1.3,0.2-2.2,0.2c-1.3-1.5-3.1-3.7-5.4-6.5s-4.5-5.2-6.3-7.3c0.3,2.2,0.7,4.5,1.2,6.9c0.8,4.5,1.5,7.3,2,8.4
									c-0.4,0.2-0.9,0.4-1.4,0.5C2420.5,1839.7,2420,1839.7,2419.4,1839.6z"
						/>
					</g>
					<g className="level_two fade location " id="ephel_duath">
						<rect x="1497.348" y="1433.65" width="97.225" height="31.539" transform="matrix(0.04953, -0.998773, 0.998176, 0.061563, 717.022522, 3316.259521)"></rect>
						<rect x="1753.77" y="1483.05" width="113.875" height="32.625" transform="matrix(0.228803, -0.973473, 0.89616, 0.557741, 503.673737, 2824.968994)"></rect>
						<rect x="2824.886" y="1193.291" width="183.424" height="26.251" transform="matrix(-0.315737, -0.948847, 0.917776, -0.409111, 2062.541992, 5032.687012)"></rect>
						<rect x="1548.392" y="1535.587" width="100.54" height="33.781" transform="matrix(-0.295591, -0.955315, 0.958876, -0.284082, 1192.657227, 3701.328613)"></rect>
						<path
							d="M2241.3,1903.6c-1.1-0.3-2.6-0.7-4.5-1.1c-3.4-0.8-5.8-1.1-7.2-1.1c0.4-1.1,0.8-2.7,1.4-5c0.6-2.8,1-4.9,1.2-6.5
									c0.6,0.1,1.2,0.3,1.8,0.6s1,0.5,1.2,0.8c-0.4,0.4-0.7,1-1.1,1.8c-0.3,0.8-0.6,1.6-0.8,2.4c-0.2,1.1-0.4,2.1-0.4,3.1
									c1.7,0.5,3.3,0.9,4.6,1.2l0.2-0.8c0.2-0.8,0.4-1.4,0.5-1.8c0.2-1,0.4-1.8,0.4-2.3c0.1-0.5,0.1-1.2,0.1-1.8c0.4,0,0.8,0.1,1.2,0.1
									c0.3,0.1,0.7,0.2,1.2,0.4c-0.7,2.3-1.1,4-1.4,5.3c-0.1,0.6-0.2,1-0.3,1.3c2.3,0.5,3.9,0.8,4.8,1c0.4-1,0.8-2,1-3.2
									c0.2-0.9,0.3-1.8,0.4-2.8c0.1-0.9,0-1.7-0.1-2.4c0.6,0.1,1.2,0.3,1.8,0.6c0.6,0.3,1,0.5,1.2,0.8c-0.4,1.3-0.9,3.2-1.5,5.7
									c-0.5,2.2-0.8,3.9-0.9,5C2244,1904.4,2242.4,1903.9,2241.3,1903.6z"
						/>
						<path
							d="M2249.8,1887.1c-1-0.3-2.5-0.5-4.3-0.9c-1.8-0.3-3.6-0.6-5.2-0.8c-3.5-0.5-5.7-0.6-6.5-0.4c-0.2-0.7-0.2-1.6-0.3-2.7
									c0-1.1,0.1-2.2,0.2-3.3c0.3-2.1,1-3.7,2.1-4.7c1.1-1,2.6-1.4,4.4-1.1c1.2,0.2,2.2,0.6,3.1,1.3c0.9,0.7,1.5,1.6,1.9,2.7
									c0.4,1.1,0.5,2.4,0.3,3.7c-0.1,0.8-0.3,1.6-0.5,2.2c2.8,0.3,4.5,0.4,5.4,0.3c0,0.6,0,1.2-0.1,1.8
									C2250.1,1885.9,2250,1886.4,2249.8,1887.1z M2242.6,1877.5c-0.7-0.6-1.6-1-2.6-1.1c-2.5-0.4-4,0.9-4.4,3.7c-0.1,0.6-0.1,1.2,0,1.7
									c2.3,0.5,4.7,0.9,7.2,1.2c0.7-1.5,1.1-2.6,1.2-3.4C2243.8,1878.8,2243.3,1878.1,2242.6,1877.5z"
						/>
						<path
							d="M2252.1,1868.7c-1-0.2-2.5-0.3-4.4-0.5c-1.9-0.1-3.6-0.3-5.3-0.3c-3.9-0.2-6.3-0.1-7.2,0.2c-0.1-0.6-0.1-1.2-0.1-1.7
									c0-0.6,0.1-1.2,0.3-1.9c1.8,0.3,4.3,0.5,7.3,0.6c0.2-1.1,0.3-2.6,0.4-4.5c0-0.3,0-1.2,0-2.6c-4-0.2-6.4-0.1-7.4,0.2
									c-0.1-0.6-0.1-1.2-0.1-1.7c0-0.5,0.1-1.1,0.3-1.8c1,0.2,2.5,0.3,4.4,0.5c1.9,0.1,3.6,0.3,5.3,0.3c3.9,0.2,6.3,0.1,7.2-0.2
									c0.1,0.6,0.1,1.2,0.1,1.8c0,0.5-0.1,1.1-0.3,1.8c-1.8-0.3-4.3-0.5-7.4-0.7c-0.1,1.3-0.2,2.7-0.3,4.1c0,1-0.1,2-0.1,3
									c4,0.2,6.5,0.1,7.5-0.2c0.1,0.6,0.1,1.2,0.1,1.8C2252.3,1867.4,2252.2,1868,2252.1,1868.7z"
						/>
						<path
							d="M2247.6,1847.8c-1.1,0-2.7,0.1-4.7,0.2c-3.5,0.2-5.9,0.5-7.2,0.9c0.1-1.1,0.1-2.9-0.1-5.2c-0.2-2.8-0.4-5-0.6-6.6
									c0.6,0,1.2,0,1.9,0.1c0.7,0.1,1.1,0.2,1.4,0.4c-0.2,0.5-0.4,1.2-0.5,2c-0.1,0.8-0.1,1.7-0.1,2.5c0.1,1.1,0.2,2.2,0.4,3.1
									c1.8,0,3.4,0,4.8-0.1l0-0.8c0-0.8,0-1.4,0-1.8c-0.1-1-0.1-1.8-0.2-2.3c-0.1-0.5-0.2-1.1-0.4-1.8c0.4-0.1,0.8-0.2,1.2-0.2
									c0.3,0,0.7,0,1.2,0.1c0,2.4,0,4.2,0.1,5.5c0,0.6,0.1,1.1,0.1,1.3c2.3-0.1,3.9-0.3,4.9-0.4c0.2-1,0.2-2.2,0.1-3.4
									c-0.1-0.9-0.2-1.9-0.4-2.8c-0.2-0.9-0.5-1.7-0.8-2.3c0.6,0,1.2,0,1.9,0.1c0.7,0.1,1.1,0.2,1.4,0.4c-0.1,1.4,0,3.3,0.1,5.9
									c0.1,2.3,0.3,4,0.5,5.1C2250.4,1847.7,2248.8,1847.7,2247.6,1847.8z"
						/>
						<path
							d="M2245.8,1829.9c-1,0.1-2.4,0.3-4.2,0.6c-3.9,0.6-6.2,1.1-7,1.6c-0.2-0.6-0.4-1.1-0.5-1.7c-0.1-0.5-0.1-1.1-0.1-1.9
									c1.2-0.1,2.7-0.2,4.5-0.4c1.8-0.2,3.5-0.4,5-0.7c2.2-0.3,3.7-0.6,4.6-0.8c0-1,0-2.1-0.2-3.3c-0.1-0.8-0.3-1.5-0.6-2.2
									c-0.3-0.7-0.6-1.3-0.9-1.8c0.6-0.1,1.3-0.1,2-0.1c0.7,0,1.1,0.1,1.4,0.3c0.1,1.4,0.2,3.1,0.5,4.9c0.3,2.2,0.7,3.9,1,5
									C2248.6,1829.6,2246.7,1829.8,2245.8,1829.9z"
						/>
						<path
							d="M2235.4,1770.2c0.3,0.9,0.5,1.8,0.6,2.5c-1,0.3-2.3,0.7-4,1.4s-3.2,1.2-4.7,1.8c-3.3,1.4-5.2,2.4-5.9,3
									c-0.5-0.5-1-1.3-1.6-2.3c-0.6-1-1.1-2-1.5-3.1c-1.1-2.6-1.2-4.9-0.5-6.9c0.8-2,2.4-3.5,5-4.5c1.6-0.7,3.1-0.9,4.6-0.6
									c1.5,0.2,2.9,0.9,4.1,1.9s2.2,2.4,2.9,4.1C2234.8,1768.4,2235.2,1769.3,2235.4,1770.2z M2230.4,1765c-0.9-0.4-1.9-0.7-2.9-0.7
									c-1,0-2,0.1-3,0.5c-4.3,1.8-5.7,4.7-4,8.6c0.3,0.7,0.6,1.2,1,1.8c2.3-0.8,4.6-1.6,6.9-2.6c1.9-0.8,3.5-1.5,4.7-2.1
									c-0.2-1.6-0.5-2.8-0.8-3.6C2231.9,1766,2231.3,1765.4,2230.4,1765z"
						/>
						<path
							d="M2207.6,1757.1c-0.7-0.5-1.4-0.9-2.3-1.2c-0.8-0.3-1.5-0.3-2.1-0.2c-0.4-0.7-0.6-1.3-0.6-1.8c0-0.5,0.1-1,0.4-1.5
									c1.8,0.5,3.5,1.9,5.2,4.1L2207.6,1757.1z M2225.2,1750.9c-0.5,1.5-1.7,2.7-3.5,3.7c-1.9,1-3.8,2.1-5.6,3.2
									c-1.8,1.1-2.9,1.8-3.4,2.3c-0.4-0.4-0.7-0.9-1-1.4c-0.3-0.5-0.5-1.1-0.7-1.8c2.8-1.4,5.9-3,9.4-4.8c2.6-1.4,3.2-3.2,2-5.5
									c-1.3-2.4-3.1-2.9-5.4-1.7c-5.3,2.9-8.5,4.7-9.5,5.4c-0.3-0.3-0.6-0.6-0.7-1c-0.2-0.3-0.3-0.7-0.5-1.2c1.1-0.5,2.6-1.2,4.4-2.2
									c1.8-0.9,3.4-1.8,4.8-2.5c1.7-0.9,3.4-1.2,5-0.9c1.6,0.3,3,1.5,4.1,3.7C2225.4,1747.8,2225.7,1749.4,2225.2,1750.9z"
						/>
						<path
							d="M2213,1724.9l-3.1,3.1c0.4,0.9,0.9,2.1,1.5,3.4c0.7,1.4,1.2,2.4,1.6,2.8c1.3-0.1,2.8-0.3,4.4-0.5c0.3,0.3,0.5,0.6,0.6,0.9
									c0.2,0.4,0.3,0.8,0.4,1.3c-1.2-0.1-3.9,0.1-8.2,0.4c-4.2,0.4-7.5,0.7-9.9,1c-0.3-0.5-0.6-0.9-0.8-1.2c-0.2-0.3-0.3-0.8-0.4-1.2
									c1.7-1.6,4-4,6.9-7.1c2.9-3.1,4.7-5.1,5.4-6.1c0.4,0.4,0.7,0.9,0.9,1.4C2212.6,1723.6,2212.8,1724.2,2213,1724.9z M2208.5,1729.5
									c-2.1,2.2-3.9,4.1-5.2,5.6c1.9-0.1,4.4-0.3,7.6-0.6c-0.4-1-0.9-2.1-1.5-3.4C2208.9,1730.3,2208.6,1729.8,2208.5,1729.5z"
						/>
						<path
							d="M2208.6,1713.9c-1,0.3-2.4,0.7-4.1,1.2c-1.7,0.6-3.4,1.1-5,1.7c-1.8,0.6-3.3,1.2-4.5,1.8c0.9,2.4,1.6,4,2.1,4.6
									c-0.4,0.3-0.8,0.5-1.2,0.6c-0.3,0.1-0.8,0.2-1.5,0.4c-0.2-0.8-0.5-1.9-1-3.3c-0.4-1.4-0.9-2.7-1.3-3.9c-1-2.9-1.8-4.6-2.3-5.2
									c0.4-0.3,0.8-0.5,1.2-0.6c0.3-0.1,0.8-0.2,1.4-0.3c0.3,1.2,0.8,2.8,1.5,4.8c2.6-0.8,4.9-1.5,6.8-2.2c3.7-1.3,5.9-2.3,6.7-2.9
									c0.3,0.6,0.6,1.1,0.8,1.6C2208.3,1712.6,2208.5,1713.2,2208.6,1713.9z"
						/>
						<path
							d="M2204.2,1700.8c-1,0.1-2.5,0.4-4.3,0.8c-1.8,0.4-3.6,0.8-5.2,1.2c-3.8,0.9-6.1,1.7-6.9,2.2c-0.3-0.6-0.5-1.1-0.6-1.6
									c-0.1-0.5-0.2-1.2-0.3-1.9c1.8-0.3,4.3-0.8,7.2-1.4c-0.2-1.1-0.5-2.6-0.9-4.4c-0.1-0.3-0.3-1.1-0.7-2.5c-3.9,0.9-6.2,1.7-7.1,2.3
									c-0.3-0.5-0.5-1.1-0.6-1.6c-0.1-0.5-0.2-1.1-0.3-1.8c1-0.1,2.5-0.4,4.3-0.8c1.8-0.4,3.6-0.8,5.2-1.2c3.8-0.9,6-1.6,6.9-2.2
									c0.3,0.5,0.5,1.1,0.6,1.7c0.1,0.5,0.2,1.1,0.2,1.8c-1.8,0.3-4.2,0.7-7.3,1.4c0.3,1.3,0.5,2.6,0.9,4c0.2,0.9,0.5,1.9,0.8,2.9
									c3.9-0.9,6.2-1.7,7.1-2.3c0.3,0.5,0.5,1.1,0.6,1.7C2204.1,1699.5,2204.2,1700.1,2204.2,1700.8z"
						/>
						<path
							d="M2241.5,1940.2l0.5,0c0.1,1.7,0.6,3.2,1.5,4.7c0.8,1.5,2,2.8,3.4,4c1.4,1.2,2.9,2.1,4.5,2.7c1.6,0.6,3.2,0.8,4.9,0.7
									l0.1,0.5c-2,0.6-4,0.7-6,0.3c-1.9-0.3-3.6-1.1-5.1-2.3c-1.4-1.2-2.5-2.7-3.1-4.6C2241.5,1944.3,2241.3,1942.3,2241.5,1940.2z"
						/>
						<path
							d="M2258.1,1949.3c-1.6-1.1-4-2.6-7.2-4.5c-1.6-1-2.9-1.7-3.7-2.1s-1.5-0.7-1.9-0.8c0.2-0.5,0.4-1,0.6-1.4
									c0.2-0.4,0.5-0.8,1-1.3c1.4,0.2,3.4,0.4,5.9,0.7c2.5,0.3,4.6,0.5,6.1,0.6c-0.9-1.3-2-3-3.4-5c-1.4-2-2.6-3.6-3.5-4.9
									c0.1-0.4,0.3-0.9,0.7-1.4c0.2-0.4,0.6-0.8,0.9-1.3c0.7,0.5,1.8,1.2,3.1,2.1c1.3,0.9,2.7,1.7,3.9,2.4c3,1.8,4.9,2.7,5.7,2.8
									c-0.2,0.5-0.4,1-0.6,1.4c-0.2,0.4-0.6,0.9-1,1.3c-0.8-0.6-1.8-1.3-3.2-2.1s-2.7-1.7-3.9-2.4c-0.8-0.5-1.7-1-2.8-1.6
									c1.8,2.7,4.2,6.2,7.3,10.5c0,0.3-0.1,0.5-0.2,0.6c-0.1,0.1-0.2,0.3-0.4,0.4c-1.3-0.2-3.1-0.4-5.5-0.6c-2.4-0.2-4.5-0.4-6.4-0.6
									c1.7,1,2.9,1.8,3.9,2.3c2.9,1.7,4.8,2.7,5.6,3c-0.1,0.3-0.2,0.7-0.4,1C2258.7,1948.7,2258.4,1949,2258.1,1949.3z"
						/>
						<path
							d="M2267.2,1929.6c-1.3,0.6-2.7,0.6-4.3,0c-1.2-0.4-2.1-1-2.9-1.8c-0.8-0.8-1.2-1.7-1.5-2.7s-0.1-2.1,0.2-3.2
									c0.3-1,0.8-1.8,1.5-2.4c0.7-0.6,1.5-1,2.5-1.1s2,0,3,0.3c1.2,0.4,2.1,1,2.9,1.8c0.7,0.8,1.2,1.7,1.4,2.8s0.1,2.2-0.3,3.3
									C2269.3,1928,2268.4,1929.1,2267.2,1929.6z M2268.6,1923c-0.5-0.8-1.4-1.4-2.6-1.8c-1.1-0.4-2.1-0.4-3.1-0.2s-1.7,0.8-2.3,1.6
									c-0.3,1-0.3,2,0.2,2.8s1.3,1.5,2.5,1.8c1.4,0.5,2.6,0.5,3.6,0.2s1.6-0.9,1.9-1.8C2269.2,1924.7,2269.1,1923.8,2268.6,1923z"
						/>
						<path
							d="M2268.7,1916.3l-1.7-0.4l-1.8-0.4c-1.8-0.3-3.1-0.4-4-0.2c-0.2-1-0.2-2,0-3.1c2.2,0.5,4.8,1,7.7,1.6
									c0.7,0.1,1.3,0.1,1.7-0.2c0.4-0.2,0.7-0.7,0.8-1.4c0.2-0.9,0.1-1.8-0.2-2.6c-1.2-0.2-2.3-0.5-3.3-0.7l-1.5-0.3
									c-1.8-0.3-3.1-0.4-4-0.2c-0.2-1-0.2-2,0-3.2c1.3,0.3,2.7,0.7,4.5,1c1.7,0.4,2.9,0.6,3.5,0.7c0.7,0.1,1.2,0.1,1.6,0.1
									c0.4-0.1,0.9-0.3,1.4-0.6c0.4,0.8,0.6,1.6,0.6,2.4c-0.5,0.5-1.1,0.7-1.8,0.8c0.4,0.6,0.7,1.2,0.9,2c0.2,0.7,0.2,1.4,0.1,2.2
									C2272.7,1915.9,2271.2,1916.7,2268.7,1916.3z"
						/>
						<path
							d="M2274.4,1903.6c-1.8-0.4-3.8-0.8-5.9-1.1c-1-0.1-1.8-0.2-2.3-0.2c-0.5,0-1,0-1.3,0.1c-0.3,0.1-0.7,0.3-1.1,0.5
									c-0.3-0.3-0.5-0.7-0.6-1.1s-0.1-0.7-0.1-0.9c0.1-1,0.8-1.5,1.9-1.4c-0.5-0.6-0.8-1.2-1.1-1.9c-0.2-0.7-0.3-1.4-0.2-2.1
									c0.3-2.2,1.7-3.1,4.3-2.7l1.7,0.3c0.7,0.2,1.3,0.3,1.8,0.4c1.9,0.3,3.3,0.3,4.2,0c0,0.5,0,1,0,1.5c-0.1,0.5-0.2,1-0.4,1.6
									c-2.2-0.5-4.6-0.9-7.2-1.3c-0.7-0.1-1.3,0-1.7,0.2c-0.4,0.2-0.6,0.7-0.7,1.4c-0.1,1-0.1,1.9,0.3,2.6l1.2,0.2
									c0.9,0.2,2,0.4,3.3,0.6c1.9,0.3,3.4,0.3,4.2,0.1c0,0.6,0,1.1-0.1,1.6C2274.7,1902.5,2274.6,1903,2274.4,1903.6z"
						/>
						<path
							d="M2276.1,1889.9c-0.4,0.2-0.9,0.3-1.8,0.2c-0.6-0.1-1.5-0.2-2.8-0.4c-1.4-0.2-2.6-0.4-3.5-0.5c-0.4-0.1-0.9-0.1-1.3-0.1
									c-0.1,0.7-0.2,1.2-0.3,1.5c-0.1,0.2-0.3,0.3-0.6,0.3c-0.5-1.3-1.3-2.6-2.4-3.8l0.2-0.7c0.6,0,1.3,0,2,0.1c0.1-1.2,0.2-2.2,0.1-2.8
									c0.4,0.1,0.7,0.1,1,0.2c0.3,0.1,0.6,0.3,0.9,0.5c-0.3,0.5-0.5,1.3-0.6,2.3c0.8,0.2,1.7,0.3,2.6,0.4c2.3,0.3,4,0.4,5.2,0.3
									c0.1-0.9,0-1.6,0-2.1l0.7-0.1c0.5,0.4,0.8,0.9,1.1,1.6c0.2,0.6,0.3,1.2,0.2,1.8C2276.7,1889.2,2276.5,1889.7,2276.1,1889.9z"
						/>
						<path
							d="M2276.8,1883.6c-0.5,0.4-1.2,0.6-2,0.5c-2.2-0.2-3.3-2.6-3.1-7c-1.2-0.1-2.1,0-2.6,0.3c-0.5,0.3-0.8,0.9-0.9,1.9
									c-0.1,0.6,0,1.2,0.1,1.8c0.1,0.6,0.3,1.2,0.6,1.7l-0.6,0.2c-0.8-0.6-1.4-1.3-1.8-2.2c-0.4-0.9-0.6-1.8-0.5-2.7
									c0.1-1.2,0.5-2.1,1.3-2.7c0.7-0.6,1.7-0.8,2.9-0.7c0.7,0.1,1.4,0.2,2.2,0.4c0.9,0.2,1.5,0.3,2,0.3c0.7,0.1,1.3,0.1,1.7-0.1
									c0.4-0.1,0.9-0.4,1.4-0.7c0.5,0.7,0.8,1.5,0.8,2.4c-0.4,0.5-0.9,0.8-1.6,0.9c0.4,0.7,0.7,1.5,0.9,2.2c0.2,0.8,0.3,1.4,0.2,2
									C2277.6,1882.7,2277.3,1883.2,2276.8,1883.6z M2276.2,1879.2c-0.2-0.5-0.4-1-0.7-1.5l-0.1,0c-0.6-0.1-1.2-0.1-1.7-0.3l-1.2-0.2
									c-0.1,1.3,0.1,2.3,0.5,3c0.4,0.7,1,1.1,1.7,1.2c0.3,0,0.6,0,1-0.2c0.3-0.1,0.6-0.3,0.8-0.6
									C2276.5,1880.3,2276.4,1879.8,2276.2,1879.2z"
						/>
						<path
							d="M2263.1,1871.5c-0.7-0.6-1.2-1.2-1.6-1.8c0.7-0.5,1.3-1,1.9-1.6c0.6,0.6,1.1,1.2,1.6,1.9
									C2264.3,1870.4,2263.7,1870.9,2263.1,1871.5z M2278.1,1871.6c-0.4,0.2-1,0.3-1.8,0.2c-0.7,0-1.7-0.1-2.9-0.3
									c-1.2-0.1-2.1-0.2-2.6-0.2c-0.8-0.1-1.5-0.1-2.1,0c-0.6,0-1.1,0.2-1.4,0.3c-0.2-0.5-0.3-1-0.3-1.5c-0.1-0.5-0.1-1,0-1.6
									c0.5,0.1,1.4,0.3,2.5,0.4c1.1,0.1,2.4,0.3,3.8,0.4c1,0.1,1.8,0.1,2.4,0.1s1-0.1,1.3-0.2c0.3-0.1,0.7-0.3,1-0.6
									c0.3,0.3,0.5,0.7,0.7,1s0.2,0.7,0.2,1C2278.6,1871.1,2278.4,1871.4,2278.1,1871.6z"
						/>
						<path
							d="M2278.7,1865.3c-1.9-0.2-3.9-0.3-6-0.3c-1,0-1.8,0-2.4,0.1c-0.5,0.1-1,0.2-1.3,0.3c-0.3,0.1-0.7,0.4-1,0.7
									c-0.3-0.3-0.6-0.6-0.8-1c-0.2-0.3-0.2-0.6-0.2-0.9c0-1,0.6-1.5,1.7-1.7c-0.6-0.5-1-1.1-1.3-1.7c-0.3-0.7-0.5-1.3-0.4-2
									c0-2.2,1.3-3.3,3.9-3.2l1.8,0.1c0.7,0.1,1.3,0.1,1.9,0.1c1.9,0,3.3-0.1,4.2-0.5c0.1,0.5,0.1,1,0.1,1.5c0,0.5-0.1,1-0.2,1.7
									c-2.3-0.2-4.7-0.3-7.3-0.3c-0.7,0-1.3,0.1-1.6,0.4c-0.3,0.3-0.5,0.8-0.5,1.5c0,1,0.2,1.9,0.6,2.6l1.2,0c1,0.1,2.1,0.1,3.4,0.1
									c2,0,3.4-0.1,4.2-0.5c0.1,0.6,0.1,1.1,0.1,1.6C2278.9,1864.2,2278.9,1864.7,2278.7,1865.3z"
						/>
						<path
							d="M2278.2,1852.9c-0.4,0-0.7,0-1-0.1s-0.7-0.2-1.1-0.3c0.5-0.4,0.8-0.9,1-1.5c0.2-0.6,0.3-1.2,0.3-1.9
									c-0.1-1.8-0.6-2.6-1.6-2.6c-0.4,0-0.7,0.2-1,0.7c-0.3,0.4-0.5,1-0.9,1.9c-0.3,0.8-0.6,1.5-0.9,2c-0.3,0.5-0.6,1-1.1,1.4
									c-0.5,0.4-1.1,0.6-1.8,0.6c-0.9,0-1.6-0.3-2.2-1.1c-0.6-0.7-0.9-1.7-1-3c-0.1-1.7,0.1-3.1,0.5-4.3c0.3,0,0.7,0.1,1.2,0.2
									c0.5,0.1,1,0.3,1.4,0.6c-0.6,0.7-1,1.4-1.4,2c-0.3,0.7-0.5,1.2-0.5,1.6c0.1,0.4,0.3,0.8,0.6,1.1c0.3,0.3,0.5,0.4,0.8,0.4
									c0.5,0,0.9-0.3,1.2-0.7s0.6-1.1,1-2.1c0.3-0.8,0.6-1.4,0.8-1.9c0.3-0.5,0.6-0.9,1.1-1.3c0.5-0.4,1-0.6,1.7-0.6
									c1,0,1.9,0.3,2.5,1.1s1,2,1,3.5C2279,1850.4,2278.8,1851.8,2278.2,1852.9z"
						/>
						<path
							d="M2275.3,1828.3c-0.8,1.1-2.1,1.9-3.7,2.2c-1.2,0.3-2.3,0.2-3.4-0.1s-1.9-0.8-2.6-1.6c-0.7-0.8-1.2-1.8-1.5-2.9
									c-0.2-1-0.2-2,0.1-2.9c0.3-0.9,0.8-1.6,1.6-2.2c0.7-0.6,1.7-1,2.8-1.3c1.2-0.3,2.3-0.2,3.4,0.1c1.1,0.3,1.9,0.9,2.7,1.7
									c0.7,0.8,1.2,1.8,1.5,3C2276.4,1825.9,2276.1,1827.2,2275.3,1828.3z M2273.2,1821.9c-0.8-0.4-1.9-0.5-3.2-0.2
									c-1.1,0.2-2,0.7-2.8,1.4c-0.7,0.7-1.1,1.6-1.2,2.6c0.2,1.1,0.8,1.8,1.6,2.3c0.9,0.5,1.9,0.6,3.1,0.3c1.4-0.3,2.5-0.9,3.2-1.6
									c0.7-0.8,1-1.6,0.8-2.5C2274.6,1823.1,2274,1822.4,2273.2,1821.9z"
						/>
						<path
							d="M2277.8,1815.1c-0.6-0.2-1.1-0.4-1.6-0.5c-0.5-0.1-1.1-0.1-1.8,0c-0.7,0.1-1.7,0.3-2.9,0.7c-1.4,0.4-3.9,1.2-7.3,2.3
									c0.2,0.6,0.2,1,0.2,1.3c0,0.2-0.2,0.4-0.4,0.5c-0.6-0.6-1-1-1.4-1.3c-1.6,0.2-2.9,0-4-0.5c-1.1-0.6-1.9-1.5-2.2-2.7
									c-0.2-0.9-0.4-1.7-0.4-2.6c0.3-0.1,0.7-0.1,1.2-0.1c0.5,0,1,0.1,1.5,0.2c-0.4,0.7-0.6,1.3-0.8,2c-0.1,0.7-0.2,1.2,0,1.5
									c0.3,0.2,0.8,0.4,1.4,0.3c0.6,0,1.6-0.2,2.8-0.6c-0.4-1.3-0.8-2.2-1-2.7c0.4-0.1,0.7-0.2,1-0.2c0.3,0,0.6,0,1,0.1
									c0,0.6,0.1,1.5,0.4,2.5l1.9-0.5c1.1-0.3,2.3-0.7,3.7-1.2c0.7-0.3,1.4-0.5,1.9-0.6c0.9-0.3,1.7-0.4,2.3-0.4c0.7,0,1.3,0.2,2,0.5
									c0.7,0.3,1.6,0.9,2.6,1.6L2277.8,1815.1z"
						/>
						<path
							d="M2257.4,1770.3c-0.3,0.1-0.7,0.2-1,0.2c-0.3,0-0.7,0-1.1,0c0.3-0.5,0.5-1.1,0.5-1.7c0-0.6-0.1-1.3-0.3-1.9
									c-0.6-1.7-1.4-2.3-2.3-2c-0.4,0.1-0.6,0.5-0.8,0.9s-0.2,1.2-0.3,2.1c-0.1,0.9-0.1,1.6-0.2,2.2c-0.1,0.6-0.3,1.1-0.7,1.6
									c-0.3,0.5-0.9,0.9-1.5,1.2c-0.8,0.3-1.6,0.2-2.4-0.4c-0.8-0.5-1.4-1.4-1.8-2.6c-0.6-1.6-0.9-3-0.8-4.2c0.3-0.1,0.7-0.2,1.2-0.2
									c0.5,0,1,0,1.5,0.1c-0.3,0.8-0.6,1.6-0.7,2.3s-0.1,1.3,0,1.7c0.2,0.4,0.5,0.7,0.9,0.8c0.3,0.2,0.6,0.2,0.9,0.1
									c0.5-0.2,0.8-0.5,1-1c0.1-0.5,0.3-1.3,0.4-2.3c0-0.8,0.1-1.5,0.2-2.1s0.3-1.1,0.6-1.6c0.3-0.5,0.8-0.9,1.4-1.1
									c1-0.4,1.9-0.3,2.7,0.3c0.8,0.6,1.5,1.6,2,3C2257.5,1767.6,2257.7,1769.1,2257.4,1770.3z"
						/>
						<path
							d="M2253.9,1758.8c-2.3,0.7-4.5,1.5-6.8,2.3c-3,1.1-5.4,2.1-7,2.9c-0.7-0.7-1.2-1.6-1.6-2.7c0.9-0.2,2.6-0.7,5.2-1.5
									c-0.7-0.3-1.3-0.7-1.9-1.2c-0.5-0.5-0.9-1.1-1.2-1.7c-0.8-2,0.1-3.5,2.5-4.4l1.7-0.6l1.8-0.6c1.8-0.7,3.1-1.3,3.7-2
									c0.3,0.5,0.5,0.9,0.7,1.3c0.2,0.4,0.3,1,0.4,1.6c-2.5,0.8-4.8,1.6-6.9,2.4c-0.7,0.3-1.1,0.6-1.3,1c-0.2,0.4-0.2,0.9,0.1,1.6
									c0.3,0.9,0.9,1.7,1.6,2.2c0.5-0.2,1.2-0.4,2.2-0.8c3.3-1.2,5.2-2.2,5.8-2.8c0.3,0.4,0.5,0.9,0.7,1.4
									C2253.7,1757.6,2253.8,1758.1,2253.9,1758.8z"
						/>
						<path
							d="M2248.6,1745.7c-0.3,0.6-0.8,1-1.5,1.3c-2.1,0.8-4.1-0.8-5.9-4.9c-1.2,0.4-1.9,0.9-2.2,1.4c-0.3,0.5-0.3,1.2,0,2.1
									c0.2,0.5,0.5,1.1,0.9,1.5s0.8,0.9,1.3,1.2l-0.4,0.4c-0.9-0.2-1.8-0.6-2.6-1.2c-0.8-0.6-1.3-1.4-1.6-2.2c-0.4-1.1-0.5-2.1-0.1-3
									c0.4-0.8,1.1-1.5,2.3-1.9c0.6-0.2,1.4-0.5,2.2-0.7c0.9-0.2,1.5-0.4,1.9-0.6c0.7-0.2,1.2-0.5,1.5-0.8c0.3-0.3,0.6-0.7,0.9-1.3
									c0.8,0.4,1.4,1,1.8,1.8c-0.1,0.6-0.5,1.1-1.1,1.5c0.7,0.5,1.3,1,1.8,1.6c0.5,0.6,0.9,1.2,1.1,1.7
									C2248.9,1744.4,2248.9,1745.1,2248.6,1745.7z M2246.1,1742c-0.4-0.4-0.8-0.8-1.3-1l-0.1,0c-0.6,0.2-1.1,0.4-1.7,0.5l-1.2,0.3
									c0.5,1.2,1.1,2,1.8,2.5c0.7,0.5,1.4,0.6,2.1,0.3c0.3-0.1,0.6-0.3,0.8-0.6c0.2-0.3,0.4-0.6,0.4-0.9
									C2246.8,1742.8,2246.5,1742.4,2246.1,1742z"
						/>
						<path
							d="M2243.3,1733.6c-0.7,1.1-1.9,2-3.7,2.6c-1.9,0.7-3.5,0.9-4.7,0.5c-1.2-0.4-2.1-1.3-2.7-2.8c-0.4-1.2-0.5-2.3-0.1-3.6
									c-1.7,0.8-2.9,1.4-3.7,2c-0.4-0.4-0.7-0.9-0.9-1.3c-0.2-0.4-0.5-0.9-0.7-1.4c2.3-0.6,6-1.9,11.2-3.8c0.6-0.2,1.1-0.5,1.4-0.8
									c0.3-0.3,0.6-0.7,0.9-1.2c0.7,0.4,1.3,1,1.7,1.7c-0.2,0.6-0.5,1.1-1.1,1.5c0.6,0.3,1.2,0.7,1.7,1.2s0.8,1,1,1.6
									C2244,1731.3,2243.9,1732.5,2243.3,1733.6z M2241.1,1728.6c-0.4-0.4-0.8-0.7-1.2-1c-0.5,0.2-1.5,0.5-2.8,1
									c-1.4,0.5-2.6,0.9-3.4,1.2c-0.2,0.8-0.1,1.7,0.2,2.7c0.3,0.9,0.9,1.5,1.8,1.7s1.9,0.1,3.1-0.3c1.2-0.5,2.1-1,2.6-1.8
									c0.5-0.7,0.7-1.5,0.6-2.4C2241.7,1729.3,2241.4,1729,2241.1,1728.6z"
						/>
						<path
							d="M2238.1,1720.1c-0.7,1.2-1.8,2.2-3.4,2.8c-1.2,0.4-2.3,0.6-3.4,0.4c-1.1-0.1-2-0.6-2.9-1.2c-0.8-0.7-1.4-1.6-1.9-2.7
									c-0.4-1-0.5-1.9-0.3-2.8c0.2-0.9,0.6-1.7,1.2-2.4c0.7-0.7,1.5-1.3,2.5-1.7c1.2-0.4,2.3-0.6,3.4-0.4c1.1,0.2,2,0.6,2.9,1.3
									c0.8,0.7,1.5,1.6,1.9,2.8C2238.8,1717.5,2238.8,1718.9,2238.1,1720.1z M2235.1,1714.1c-0.9-0.3-1.9-0.2-3.2,0.2
									c-1.1,0.4-1.9,1-2.5,1.8c-0.6,0.8-0.9,1.7-0.8,2.7c0.4,1,1,1.7,1.9,2c0.9,0.3,2,0.3,3.1-0.1c1.4-0.5,2.3-1.2,2.9-2.1
									c0.6-0.9,0.7-1.7,0.4-2.6C2236.6,1715.1,2236,1714.4,2235.1,1714.1z"
						/>
						<path
							d="M2232.2,1706.5l-2.8,2.1c-0.7,0.5-1.3,0.9-1.8,1.3c-0.6,0.5-1.2,1-1.7,1.5c-0.5,0.5-0.8,0.9-0.9,1.2
									c-0.4-0.4-0.7-0.8-0.9-1.2c-0.3-0.4-0.5-1-0.7-1.6c0.7-0.4,2.2-1.4,4.3-3c2.2-1.6,3.4-2.6,3.8-3c-4.3,0.1-7,0.3-8,0.4
									c-0.6,0.6-0.9,1-1.1,1.4c-0.7-0.7-1.2-1.7-1.7-2.8c0.7-0.4,2.2-1.4,4.5-3.1c2.3-1.7,3.5-2.7,3.7-2.9c-3.1,0.1-5.4,0.1-6.6,0.2
									c-1.3,0.1-2.1,0.2-2.6,0.4c-0.2,0.1-0.3,0.2-0.6,0.3c-0.4-0.6-0.8-1.3-1.2-2.3c1.3,0.1,3,0.1,5,0.1c2,0,4.3-0.1,6.6-0.2l1.3,1.1
									c0.2,0.5,0.2,1,0,1.5c-0.2,0.5-0.7,1-1.3,1.5c-2,1.5-3.5,2.6-4.3,3.2c1.7,0,4-0.1,6.9-0.3l1.3,1.1
									C2233.9,1704.6,2233.4,1705.6,2232.2,1706.5z"
						/>
						<path
							d="M2213.5,1691c1.2-1.5,2.7-2.6,4.5-3.3s3.6-0.8,5.5-0.5s3.8,1.1,5.5,2.3l-0.2,0.4c-1.5-0.7-3.1-1-4.8-1
									c-1.7,0-3.4,0.3-5.2,1c-1.7,0.7-3.2,1.5-4.5,2.7c-1.3,1.1-2.3,2.4-3,4l-0.4-0.2C2211.4,1694.4,2212.2,1692.6,2213.5,1691z"
						/>
					</g>
					<g className="level_two fade location " id="erui">
						<rect x="786.134" y="1030.287" width="51.046" height="22.666" transform="matrix(0.547213, 0.836993, -0.843587, 0.537128, 2459.645752, 539.409363)"></rect>
						<path
							d="M2039.4,1793.5c-0.7,0.5-1.7,1.3-2.9,2.3c-2.2,1.8-3.6,3.1-4.3,3.9c-0.4-0.7-1.2-1.8-2.2-3.1c-1.3-1.6-2.4-2.9-3.2-3.7
									c0.4-0.3,0.8-0.6,1.2-0.8c0.5-0.3,0.8-0.4,1.1-0.4c0.1,0.4,0.2,0.9,0.5,1.5c0.3,0.5,0.6,1.1,1,1.6c0.5,0.7,1.1,1.2,1.6,1.7
									c1.2-0.8,2.2-1.6,3.1-2.3l-0.4-0.5c-0.3-0.5-0.6-0.9-0.8-1.1c-0.5-0.6-0.9-1-1.1-1.3s-0.6-0.6-1-0.9c0.2-0.3,0.5-0.5,0.7-0.7
									c0.2-0.1,0.5-0.3,0.8-0.5c1,1.4,1.8,2.5,2.4,3.3c0.3,0.4,0.5,0.6,0.6,0.7c1.4-1.2,2.4-2,3-2.5c-0.3-0.7-0.8-1.4-1.4-2.1
									c-0.4-0.5-0.9-1-1.4-1.5c-0.5-0.5-1-0.8-1.5-1c0.4-0.3,0.8-0.6,1.3-0.8c0.5-0.3,0.8-0.4,1.1-0.4c0.5,0.9,1.4,2,2.6,3.5
									c1.1,1.3,1.9,2.3,2.5,2.8C2041.2,1792.2,2040.1,1793,2039.4,1793.5z"
						/>
						<path
							d="M2034,1780c-1.5,0.9-3,1.9-4.5,3c-0.8,0.5-1.3,1-1.7,1.3c-0.4,0.3-0.6,0.6-0.8,0.9s-0.3,0.6-0.4,1c-0.4,0-0.7-0.1-1-0.3
									c-0.3-0.2-0.5-0.3-0.7-0.5c-0.5-0.7-0.3-1.5,0.7-2.3c-0.7,0-1.3-0.1-1.9-0.3s-1.1-0.5-1.6-0.9c0.2-0.2,0.4-0.6,0.8-0.9
									c0.4-0.4,0.7-0.7,1.1-0.9c0.1,0.2,0.2,0.4,0.4,0.7c0.3,0.4,0.6,0.8,1,1.1c0.4,0.3,0.7,0.5,1,0.6l0.7-0.4c0.9-0.6,1.8-1.2,2.5-1.7
									c1.5-1,2.4-1.9,2.9-2.6c0.4,0.3,0.6,0.7,0.9,1C2033.5,1779.1,2033.7,1779.5,2034,1780z"
						/>
						<path
							d="M2025.6,1773.6l-1.4,0.8l-1.5,0.9c-1.4,0.9-2.4,1.6-2.8,2.3c-0.7-0.5-1.3-1.1-1.8-1.9c1.8-1,3.9-2.2,6.2-3.6
									c0.6-0.4,0.9-0.7,1.1-1.1c0.1-0.4,0-0.8-0.3-1.4c-0.4-0.7-0.9-1.2-1.6-1.5c-1,0.6-1.8,1.1-2.6,1.5l-1.2,0.7
									c-1.4,0.9-2.4,1.6-2.8,2.3c-0.7-0.5-1.3-1.1-1.8-2c1-0.6,2.2-1.2,3.7-2c1.4-0.8,2.3-1.3,2.8-1.6c0.5-0.3,0.9-0.6,1.1-0.9
									c0.2-0.3,0.4-0.7,0.6-1.2c0.7,0.2,1.3,0.6,1.8,1.1c-0.1,0.6-0.3,1.1-0.8,1.6c0.6,0.1,1.2,0.3,1.7,0.7s1,0.8,1.3,1.3
									C2028.1,1771,2027.6,1772.4,2025.6,1773.6z"
						/>
						<path
							d="M2009.3,1766.9c-0.8-0.1-1.5-0.3-2.1-0.5c0.3-0.7,0.5-1.3,0.7-2c0.7,0.1,1.4,0.3,2.1,0.5
									C2009.7,1765.5,2009.5,1766.2,2009.3,1766.9z M2020.9,1759.7c-0.2,0.3-0.6,0.7-1.3,1c-0.6,0.3-1.3,0.7-2.4,1.2
									c-1,0.5-1.7,0.9-2.1,1.1c-0.6,0.3-1.2,0.7-1.6,1c-0.5,0.3-0.8,0.7-0.9,0.9c-0.4-0.3-0.7-0.6-1-0.9c-0.3-0.3-0.5-0.7-0.8-1.1
									c0.5-0.2,1.2-0.5,2.1-0.9c0.9-0.4,2-1,3.1-1.6c0.8-0.4,1.4-0.8,1.8-1.1c0.4-0.3,0.7-0.5,0.9-0.8s0.4-0.6,0.5-0.9
									c0.4,0.1,0.7,0.2,1,0.4c0.3,0.2,0.5,0.4,0.6,0.6C2021.1,1759,2021.1,1759.4,2020.9,1759.7z"
						/>
					</g>
				</g>
			</svg>
		);
	}
}
