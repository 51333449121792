import React from "react";
import zoomAndPanning from "./logic/panZoomInitialize.js";
import SVG_map from "./component/SVG_map.js";
import Sidebar from "./component/sideBar.js";
import InfoBar from "./component/InfoBar.js";

import "./App.scss";

export default class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			location: "",
			index: "",
			barState: "infoBarCollapsed",
		}
	}

	componentDidMount() {
		zoomAndPanning();
	}

	handleClickLocation = (index, location) => {
		this.setState({
			location: location,
			index: index,
		})
		if (this.state.barState === "infoBarCollapsed") {
			this.setState({
				barState: "",
			});
		}
	}

	handleCloseClickInfoBAr = () => {
		if (this.state.barState === "") {
			this.setState({
				barState: "infoBarCollapsed",
			});
		}
	}

	render() {
		return (
			<div className="App">
				<div className="proSidebar">
					<Sidebar />
				</div>
				<div className="mapContainer">
					<SVG_map handleClickLocation={this.handleClickLocation} />
				</div>
				<div className={`${this.state.barState}`} id="infoWindowContainer">
					<InfoBar handleCloseClickInfoBAr={this.handleCloseClickInfoBAr} location={this.state.location} index={this.state.index} />
				</div>
			</div>
		);
	}
}
